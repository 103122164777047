import { AddressDisplay } from '@components/AddressDisplay';
import { PRIVATE_themeObj } from '@components/Theme';
import { useViewOnly } from '@components/ViewOnly';
import {
  CarrierItemForPickerV2Fragment,
  CarriersForCarrierPickerV2Query,
  CarriersForCarrierPickerV2QueryVariables,
  useCarriersForCarrierPickerV2LazyQuery,
} from '@generated/queries/carriersForCarrierPickerV2';
import { CarriersFilter, EntityTypeEnum } from '@generated/types';
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { pipeSeparator } from '@utils/htmlEntities';
import { STATUS_OPTIONS } from '@views/Carrier/CarrierGeneral/CarrierStandingCard';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  AutoComplete,
  Props as AutoCompleteProps,
  Shell,
} from '../../../components/AutoComplete';

export type CarrierPickerItemFragment =
  | Omit<CarrierItemForPickerV2Fragment, 'entityType'> &
      Pick<Partial<CarrierItemForPickerV2Fragment>, 'entityType'>;

const carrierTypeLabels = {
  carrier: 'Carrier',
  fleet: 'Fleet',
  factor: 'Factor',
  rail: 'Rail',
  vendor: 'Vendor',
};

export const CarrierPickerItemDisplay: FC<{
  carrier: Maybe<CarrierPickerItemFragment>;
  compact?: boolean;
}> = ({ carrier, compact }) => (
  <div>
    <strong>{carrier?.name}</strong>
    {compact ? <br /> : ' | '}
    <span>
      {carrier?.code}
      {carrier?.code && pipeSeparator}
      <AddressDisplay
        value={carrier?.mainAddress}
        city
        state
        street={!!carrier?.mainAddress?.street1}
      />
      {carrier?.mainAddress && pipeSeparator}
      {STATUS_OPTIONS[carrier?.status as keyof typeof STATUS_OPTIONS]?.text}
      {pipeSeparator}
      {carrierTypeLabels[carrier?.entityType as EntityTypeEnum] ??
        carrier?.entityType}
    </span>
  </div>
);

export const renderCarrierResult: AutoCompleteProps<CarrierPickerItemFragment>['renderItem'] =
  ({ item, key, defaultItemStyles, getItemProps, isHighlighted, index }) => {
    const { value: carrier } = item;
    return (
      <li
        key={key}
        css={{
          ...defaultItemStyles,
          ...{
            display: 'block',
            width: '100%',
            padding: '8px',
            textAlign: 'left',
          },
        }}
        {...getItemProps({
          item,
          index,
          key,
          style: {
            backgroundColor: isHighlighted
              ? PRIVATE_themeObj.colors.primary
              : PRIVATE_themeObj.card.background,
            color: isHighlighted ? PRIVATE_themeObj.card.background : 'inherit',
          },
        })}
        data-testid={carrier?.id ?? ''}
      >
        <CarrierPickerItemDisplay carrier={carrier} />
      </li>
    );
  };

export interface Props
  extends Omit<AutoCompleteProps<CarrierPickerItemFragment>, 'items'> {
  inputProps?: AutoCompleteProps<unknown>['inputProps'];
  carrierFilters?: Partial<CarriersFilter>;
  filterItems?: (
    items: Shell<CarrierPickerItemFragment>[]
  ) => Shell<CarrierPickerItemFragment>[];
  placeholder?: string;
}

const useSearchCarriersV2 = (): [
  (filter: CarriersForCarrierPickerV2QueryVariables['filter']) => void,
  { loading: boolean; data: CarrierItemForPickerV2Fragment[] }
] => {
  const [prevData, setPrevData] =
    useState<CarriersForCarrierPickerV2Query | null>(null);
  const [rawGet, { loading, data }] = useCarriersForCarrierPickerV2LazyQuery({
    fetchPolicy: 'no-cache',
  });
  const get = useCallback(
    (filter: CarriersForCarrierPickerV2QueryVariables['filter']) => {
      if (!filter?.text) {
        return;
      }
      return rawGet({ variables: { first: 15, filter } });
    },
    [rawGet]
  );
  useEffect(() => {
    if (data) {
      setPrevData(data);
    }
  }, [data]);
  return [
    get,
    {
      loading,
      // We use the previous data response while waiting for the new one to avoid a flash of no items in the dropdown, which is bad UX
      data: loading
        ? prevData?.carriersNullableV2?.edges.map((e) => e.node) || []
        : data?.carriersNullableV2?.edges.map((e) => e.node) || [],
    },
  ];
};

export const CarrierPicker: FC<Props> = ({
  inputProps,
  carrierFilters,
  filterItems,
  placeholder = 'Search',
  ...rest
}) => {
  const [searchCarriersV2, { data: carriersV2, loading: loadingV2 }] =
    useSearchCarriersV2();

  const debouncedSearchCarriers = useDebouncedFn(searchCarriersV2, 500, []);

  const { isViewOnly } = useViewOnly();

  const items = carriersV2.map((carrier) => ({
    value: carrier,
    label: carrier.name,
    id: carrier.id,
  }));

  return isViewOnly ? (
    <div data-testid={rest.name || 'carrier-search'}>
      {rest.selectedItem?.label}
    </div>
  ) : (
    <AutoComplete
      onInputValueChange={(inputValue): void => {
        debouncedSearchCarriers({
          text: inputValue,
          ...carrierFilters,
        });
      }}
      data-testid={rest.name || 'carrier-search'}
      name={rest.name || 'carrier-search'}
      loading={loadingV2}
      items={filterItems ? filterItems(items) : items}
      renderItem={renderCarrierResult}
      inputProps={{
        placeholder,
        'data-testid': 'carrier-search-input',
        name: 'carrier-search-input',
        css: {
          height: '100%',
          position: 'relative',
        },
        ...inputProps,
      }}
      {...rest}
    />
  );
};
