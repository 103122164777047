import { useHasPermissionFromContext } from '@components/PermissionsContext';
import {
  MassUnitTypeEnum,
  Maybe,
  TemperatureUnitTypeEnum,
  UnitOfCurrencyEnum,
  UnitOfLengthEnum,
  VolumeUnitTypeEnum,
} from '@generated/types';
import { isBoolean, isNaN } from 'lodash-es';
import {
  ChangeEvent,
  FC,
  FocusEvent,
  KeyboardEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ReadOnlyField } from '../Field/ReadOnlyField';
import { Input } from '../Input';
import { CurrencyEnum, getNumberValue } from '../NumberValue';
import { UnitOfDensityEnum } from '../NumberValue/enum';

export enum NumberUnits {
  Int = 'Int',
  Float = 'Float',
}

export type UnitType =
  | UnitOfLengthEnum
  | TemperatureUnitTypeEnum
  | UnitOfCurrencyEnum
  | MassUnitTypeEnum
  | VolumeUnitTypeEnum
  | NumberUnits
  | UnitOfDensityEnum
  | CurrencyEnum
  | string;

export type CurrencyDisplayType = 'code' | 'symbol' | 'narrowSymbol' | 'name';

export interface Props {
  allowEmpty?: boolean;
  allowFractional?: boolean;
  allowNegative?: boolean;
  blank?: boolean;
  disabled?: boolean;
  /** If the input cannot be empty, the number it should be coerced to if no input entered. Defaults to 0 */
  emptyDefault?: number;
  id?: string;
  name?: string;
  onChange: (adjustedDigit: number | null) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  unit?: Maybe<UnitType>;
  value: Maybe<number>;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  tabIndex?: number;
  autoFormat?: boolean;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  suffix?: string;
  currencyDisplay?: CurrencyDisplayType;
  fractionDigits?: number;
  showUnit?: boolean;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
}

export const Digit: FC<Props> = ({
  allowEmpty = false,
  allowFractional = false,
  fractionDigits = 2,
  blank = false,
  emptyDefault = 0,
  unit = NumberUnits.Int,
  allowNegative = Object.values(TemperatureUnitTypeEnum).includes(
    unit as TemperatureUnitTypeEnum
  )
    ? true
    : false,
  disabled,
  id,
  name,
  onChange,
  placeholder,
  required,
  value,
  autoFormat = true,
  onBlur,
  suffix,
  currencyDisplay,
  showUnit = true,
  onKeyUp,
  autoFocus,
  ...rest
}): ReactElement => {
  const [userHasPermision, permissionScope] = useHasPermissionFromContext();
  const readOnly = !userHasPermision || rest.readOnly;
  const formikValue = value?.toString() || '';

  const nullishValue = allowEmpty ? '' : emptyDefault.toString();

  const valToNumber = (value: string): number => {
    return allowFractional
      ? Number(parseFloat(value).toFixed(fractionDigits))
      : parseInt(value, 10);
  };

  const formatValue = useCallback(
    (rawValue: string): string => {
      if (allowEmpty && rawValue === '') {
        return '';
      }
      let parsed = parseFloat(rawValue);
      if (isNaN(parsed) && allowEmpty) {
        return '';
      } else if (isNaN(parsed)) {
        parsed = emptyDefault;
      }
      return getNumberValue({
        value: parsed,
        unit: unit as fixMe,
        fractionDigits: allowFractional ? fractionDigits : 0,
        suffix,
        currencyDisplay: currencyDisplay,
        showUnit,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allowEmpty, allowFractional, fractionDigits, unit]
  );

  const [input, setInput] = useState<{ focused: boolean; amount: string }>({
    focused: autoFocus ?? false,
    amount: formatValue(formikValue),
  });

  const [debounceCacheKey, setDebounceCacheKey] = useState(Date.now());

  useEffect(() => {
    if (input.focused) {
      if (valToNumber(formikValue) !== value) {
        setInput({
          focused: true,
          amount: formikValue,
        });
      }
    } else {
      setInput({
        focused: false,
        amount: autoFormat ? formatValue(formikValue) : formikValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, unit, autoFormat]);

  const updateValue = ({
    value: rawVal,
    useFormatted,
    focused,
  }: {
    value: string;
    useFormatted?: boolean;
    focused?: boolean;
  }): string => {
    const value = rawVal;

    if (value !== '') {
      const parsedValue = valToNumber(value);
      if (!isNaN(parsedValue)) {
        onChange(parsedValue);
      }
    } else {
      onChange(allowEmpty ? null : emptyDefault);
    }

    const newAmount = useFormatted ? formatValue(value) : value;

    setInput({
      focused: isBoolean(focused) ? focused : true,
      amount: useFormatted ? formatValue(value) : value,
    });

    return newAmount;
  };

  const digitHandleBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const { value: rawValue } = event.target;
    const value = rawValue === '' ? nullishValue : rawValue;
    setDebounceCacheKey(Date.now());
    updateValue({ value, useFormatted: autoFormat, focused: false });
    onBlur && onBlur(event);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void | string => {
    let { value } = event.target;
    value = value ?? '';
    // remove any chars that aren't numbers or a decimal or negative sign
    let replaceRegex = /[^0-9]/g;
    if (allowFractional && allowNegative) {
      replaceRegex = /[^0-9.-]/g;
    } else if (allowFractional) {
      replaceRegex = /[^0-9.]/g;
    } else if (allowNegative) {
      replaceRegex = /[^0-9-]/g;
    }
    value = value.replace(replaceRegex, '');

    return updateValue({ value });
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    const { currentTarget, key } = event;
    if (key === 'Tab') {
      currentTarget.select();
    }
    onKeyUp?.(event);
  };

  const memoizedPlaceholder = useMemo(
    () => (placeholder ? placeholder : formatValue('0')),
    [placeholder, formatValue]
  );

  if (readOnly) {
    return (
      <ReadOnlyField
        data-scope={permissionScope}
        blank={blank}
        data-testid={id}
      >
        {input.amount || ''}
      </ReadOnlyField>
    );
  }

  return (
    <Input
      disabled={disabled}
      id={id}
      name={name}
      debounceCacheKey={debounceCacheKey}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      placeholder={memoizedPlaceholder}
      required={required}
      type="text"
      autoFocus={autoFocus}
      {...rest}
      onFocus={(e): void => {
        setInput({
          amount: formikValue,
          focused: true,
        });
        rest.onFocus?.(e);
      }}
      value={input.amount}
      data-scope={permissionScope}
      onBlur={digitHandleBlur}
    />
  );
};
