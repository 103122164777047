import { ApolloError } from '@apollo/client';
import { useKeycloakAuthContext } from '@components/AuthContext';
import { Grid } from '@components/Grid';
import { Icon } from '@components/Icon';
import { LinkTo } from '@components/Link';
import { Loading } from '@components/Loading';
import { NavLink } from '@components/NavLink';
import { NewWindowLink } from '@components/NewWindowLink';
import { Tooltip } from '@components/Tooltip';
import { css } from '@emotion/react';
import { useFlagMe250088FeatCarrierTabEnhancementOnMainpageSidebar } from '@generated/flags/ME-250088-feat-carrier-tab-enhancement-on-mainpage-sidebar';
import { useFlagMe250099FeatCustomerTabEnhancementOnMainpageSidebar } from '@generated/flags/ME-250099-feat-customer-tab-enhancement-on-mainpage-sidebar';
import { useFlagMe250106ViewFacilityTabOnMainpageSidebar } from '@generated/flags/ME-250106-view-facility-tab-on-mainpage-sidebar';
import { EmployeeSimpleV2Fragment } from '@generated/fragments/employeeSimpleV2';
import { useFacilitiesForMasterfindV2Query } from '@generated/queries/facilitiesForMasterfindV2';
import {
  SearchCarriersV2Query,
  useSearchCarriersV2LazyQuery,
  useSearchCarriersV2Query,
} from '@generated/queries/searchCarriersV2';
import { useSearchCustomersV2Query } from '@generated/queries/searchCustomersV2';
import { useSeerMainPageSideBarSummaryCountsLazyQuery } from '@generated/queries/seerMainPageSideBarSummaryCounts';
import { MainPageSideBarSummaryCounts } from '@generated/types';
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { useTheme } from '@hooks/useTheme';
import { faTimes } from '@masterysystems/free-solid-svg-icons';
import { FontAwesomeIcon } from '@masterysystems/react-fontawesome';
import { SCROLLBAR_WIDTH } from '@utils/constants';
import { sortBy } from 'lodash-es';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { SearchType } from '../Search/types';

type EmployeeType = EmployeeSimpleV2Fragment;
interface ListProps {
  searchType: SearchType;
  employee: EmployeeType;
  resetRepFilter: () => void;
}

interface ListItem {
  id: string;
  name: string;
  url: LinkTo;
}

const getNameFromEmployee = (emp: Partial<EmployeeType>): string => {
  return `${emp.firstName || ''} ${emp.lastName || ''}`.trim();
};

export const List = ({
  searchType,
  employee,
  resetRepFilter,
}: //Had issues with return type ReactNode, so replaced with JSX Element
ListProps): JSX.Element => {
  const {
    sidebar: { link, active },
  } = useTheme();
  const listStyle = css({
    listStyle: 'none',
    overflowY: 'auto',
    overflowX: 'hidden',
    '::webkit-scrollbar': {
      height: SCROLLBAR_WIDTH,
      width: SCROLLBAR_WIDTH,
    },

    '& a': {
      ...link,

      '&.active': {
        ...active,
      },
    },
  });

  const { colors } = useTheme();

  const [summaryCountInfo, setSummaryCountInfo] =
    useState<MainPageSideBarSummaryCounts>();
  const [summaryCountError, setSummaryCountError] = useState(false);

  const [fireSEERSummaryCountQuery, { loading: seerLoading }] =
    useSeerMainPageSideBarSummaryCountsLazyQuery({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      onCompleted: (data) => {
        setSummaryCountInfo(data?.seerMainPageSideBarSummaryCounts);
        setSummaryCountError(false);
      },
      onError: () => {
        setSummaryCountError(true);
      },
    });

  const getSeerSummaryCounts = (
    payloadId: string,
    requestType: string
  ): void => {
    const filterPayload =
      requestType === 'Customers'
        ? { customerId: [payloadId] }
        : requestType === 'Facilities'
        ? { facilityId: [payloadId] }
        : { carrierId: [payloadId] };
    fireSEERSummaryCountQuery({
      variables: {
        filter: filterPayload,
      },
    });
  };

  const getSidebarRecordsTooltip = (
    dataObj: ListItem,
    listLabel: string
  ): ReactNode => {
    return (
      <Tooltip
        label={
          (listLabel === 'Customers' && showIconsOnCustomerTab) ||
          (listLabel === 'Carriers' && showCarrierIcons) ||
          (listLabel === 'Facilities' && showFacilityTab) ? (
            seerLoading ? (
              <Loading size="small" />
            ) : summaryCountError ? (
              <div style={{ color: colors.error }}>
                Error: Failed to load summary
              </div>
            ) : (
              <>
                {['Customers'].includes(listLabel) && (
                  <div>
                    <b>Pending Orders: </b>
                    {summaryCountInfo?.pendingCount || 0}
                  </div>
                )}
                {['Customers', 'Facilities'].includes(listLabel) && (
                  <div>
                    <b>Scheduled Records: </b>
                    {summaryCountInfo?.schedulingCount || 0}
                  </div>
                )}
                {['Customers', 'Carriers', 'Facilities'].includes(
                  listLabel
                ) && (
                  <div>
                    <b>Incidents: </b>
                    {summaryCountInfo?.incidentsCount || 0}
                  </div>
                )}
                {['Customers', 'Carriers', 'Facilities'].includes(
                  listLabel
                ) && (
                  <div>
                    <b>Tracking Routes: </b>
                    {summaryCountInfo?.trackingCount || 0}
                  </div>
                )}
                {['Customers'].includes(listLabel) && (
                  <div>
                    <b>Order List Records: </b>
                    {summaryCountInfo?.orderListCount || 0}
                  </div>
                )}
                {['Carriers', 'Facilities'].includes(listLabel) && (
                  <div>
                    <b>Route List Records: </b>
                    {summaryCountInfo?.routeListCount || 0}
                  </div>
                )}
              </>
            )
          ) : (
            ''
          )
        }
      >
        <li key={dataObj?.id} data-testid={dataObj?.id}>
          <span
            style={{ display: 'flex' }}
            onMouseEnter={(): void => {
              if (listLabel === 'Customers' && showIconsOnCustomerTab) {
                getSeerSummaryCounts(dataObj?.id, listLabel);
              }
              if (listLabel === 'Carriers' && showCarrierIcons) {
                getSeerSummaryCounts(dataObj?.id, listLabel);
              }
              if (listLabel === 'Facilities' && showFacilityTab) {
                getSeerSummaryCounts(dataObj?.id, listLabel);
              }
            }}
          >
            <NavLink
              fsId={'Sidebar ' + listLabel}
              to={dataObj?.url}
              replace
              isActive={(match, location): boolean =>
                location.pathname.includes(dataObj?.id)
              }
              data-testid={listLabel}
              style={{ flex: 1 }}
            >
              {dataObj?.name}
            </NavLink>
            {listLabel === 'Customers' && showIconsOnCustomerTab && (
              <>
                <span>
                  <NewWindowLink
                    to={`/customers/${dataObj?.id}/operations?topTab=scheduling&sidebar=-1`}
                    id={'Sidebar Customer Scheduling Link'}
                    title="Customer Scheduling"
                  >
                    <Icon i="clock" size={'sm'} color={'inherit'} />
                  </NewWindowLink>
                </span>
                <span>
                  <NewWindowLink
                    to={`/customers/${dataObj?.id}/operations?topTab=orderList&sidebar=-1`}
                    id={'Sidebar Customer Order List Link'}
                    title={'Customer Order List'}
                  >
                    <Icon i="list" size={'sm'} color={'inherit'} />
                  </NewWindowLink>
                </span>
              </>
            )}
            {listLabel === 'Carriers' && showCarrierIcons && (
              <div css={{ display: 'flex' }}>
                <span>
                  <NewWindowLink
                    id={'Sidebar Carrier Tracking Link'}
                    to={`/carriers/${dataObj?.id}/operations?operationsTab=carrier-tracking&sidebar=-1`}
                    title={'Carrier Tracking'}
                  >
                    <Icon i="tracking" size="sm" color="inherit" />
                  </NewWindowLink>
                </span>
                <span>
                  <NewWindowLink
                    id={'Sidebar Carrier Route List Link'}
                    to={`/carriers/${dataObj.id}/operations?operationsTab=carrier-route-list&sidebar=-1`}
                    title={`Carrier Route List`}
                  >
                    <Icon i="list" size="sm" color="inherit" />
                  </NewWindowLink>
                </span>
              </div>
            )}
            {listLabel === 'Facilities' && showFacilityTab && (
              <div css={{ display: 'flex' }}>
                <span>
                  <NewWindowLink
                    id={'Sidebar Facility Tracking Link'}
                    to={`/facilities/${dataObj?.id}/operations?operationsTab=tracking&sidebar=-1`}
                    title={'Facility Tracking'}
                  >
                    <Icon i="tracking" size="sm" color="inherit" />
                  </NewWindowLink>
                </span>
                <span>
                  <NewWindowLink
                    id={'Sidebar Facility Route List Link'}
                    to={`/facilities/${dataObj?.id}/operations?operationsTab=routeList&sidebar=-1`}
                    title={`Facility Route List`}
                  >
                    <Icon i="list" size="sm" color="inherit" />
                  </NewWindowLink>
                </span>
              </div>
            )}
          </span>
        </li>
      </Tooltip>
    );
  };
  const showCarrierIcons =
    useFlagMe250088FeatCarrierTabEnhancementOnMainpageSidebar();

  const searchCarriersQueryRes = useSearchCarriersV2Query({
    variables: {
      filter: { employeeId: employee?.id },
    },
    skip: !employee?.id,
  });

  const searchCustomersV2Query = useSearchCustomersV2Query({
    variables: {
      filter: { employeeId: employee?.id },
    },
    skip: !employee?.id,
  });

  const searchFacilityV2Query = useFacilitiesForMasterfindV2Query({
    variables: {
      filter: { repIds: [employee?.id] },
    },
    skip: !employee?.id,
  });

  const [paginationParams, setPaginationParams] = useState({
    hasNextPage: true,
    endCursor: '',
  });

  const showIconsOnCustomerTab =
    useFlagMe250099FeatCustomerTabEnhancementOnMainpageSidebar();
  const showFacilityTab = useFlagMe250106ViewFacilityTabOnMainpageSidebar();

  const [carriersV2ListItems, setCarriersV2ListItems] = useState<ListItem[]>();

  const updateCarriersV2List = (data: Maybe<SearchCarriersV2Query>): void => {
    if (data) {
      const listItems: ListItem[] = (
        carriersDataV2?.carriersNullableV2?.edges ?? []
      ).map(
        ({ node: { id, name } }): ListItem => ({
          id,
          name,
          url: `/carriers/${id}/capacity-manager`,
        })
      );
      setCarriersV2ListItems(listItems);
      const hasNextPage =
        data?.carriersNullableV2?.pageInfo?.hasNextPage ?? false;
      const endCursor = data?.carriersNullableV2?.pageInfo?.endCursor ?? '';
      setPaginationParams({ hasNextPage, endCursor });
    }
  };

  const [
    searchCarriersV2Query,
    { loading: loadingCarrierV2, data: carriersDataV2, fetchMore },
  ] = useSearchCarriersV2LazyQuery({
    fetchPolicy: 'cache-and-network',

    // workaround for pagination issues introduced with recent Apollo versions:
    // https://github.com/apollographql/apollo-client/issues/6327
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data): void => updateCarriersV2List(data),
  });

  const handleScrollToBottom = async (e: anyOk): Promise<void> => {
    const scrollHeight = e.target.scrollHeight - e.target.offsetHeight;
    const fromTop = e.target.scrollTop;
    const shouldFetchMore = scrollHeight - fromTop <= 50;

    if (!paginationParams.hasNextPage || loadingCarrierV2 || !shouldFetchMore) {
      return;
    }

    await debouncedFetchMore();

    setPaginationParams((previousParams) => ({
      ...previousParams,
      hasNextPage: false,
    }));
  };
  const getMoreCarriers = async (): Promise<void> => {
    await fetchMore?.({
      variables: {
        after: paginationParams.endCursor,
        first: 100,
      },
      updateQuery: (
        previousResult: SearchCarriersV2Query,
        { fetchMoreResult }
      ) => {
        /**
         * Previously used
         *
         * `import { updateQuery } from '../../../utils/graphqlUtils';`
         *
         * Switched to local implementation of updateQuery due to the non-nullable <i>errors</i> field being ignored
         * See ME-227138
         */
        if (!fetchMoreResult) {
          return previousResult;
        }

        const newEdges = fetchMoreResult.carriersNullableV2?.edges;
        const pageInfo = fetchMoreResult.carriersNullableV2?.pageInfo;
        const errors = fetchMoreResult.carriersNullableV2?.errors ?? [];

        if (!newEdges?.length) {
          return previousResult;
        }

        return {
          __typename: previousResult.__typename,
          carriersNullableV2: {
            __typename: previousResult.carriersNullableV2?.__typename,
            edges: [
              ...(previousResult?.carriersNullableV2?.edges ?? []),
              ...newEdges,
            ],
            pageInfo,
            errors,
          },
        } as SearchCarriersV2Query;
      },
    });
  };
  const debouncedFetchMore = useDebouncedFn(getMoreCarriers, 250, [
    paginationParams.endCursor,
    fetchMore,
  ]);

  useEffect(() => {
    if (employee?.id) {
      setCarriersV2ListItems([]);
      searchCarriersV2Query({
        variables: {
          filter: {
            employeeId: employee?.id,
            sort: 'name asc',
          },
          first: 100,
        },
      });
    }
  }, [employee?.id, searchCarriersV2Query]);

  const shouldUsePagination = useCallback((): boolean => {
    if (
      searchType === SearchType.customer ||
      searchType === SearchType.facility
    ) {
      return false;
    }
    return true;
  }, [searchType]);

  interface ListQueryResult {
    error?: ApolloError;
    loading: boolean;
    listItems: ListItem[];
  }

  let listLabel = '';
  let listQuery: ListQueryResult;
  let listItems: ListItem[];
  switch (searchType) {
    case SearchType.customer:
      listItems = (
        searchCustomersV2Query.data?.allCustomersV2?.edges ?? []
      ).map(
        ({ node: { id, name } }): ListItem => ({
          id,
          name,
          url: `/customers/${id}/operations`,
        })
      );
      listLabel = 'Customers';
      listQuery = { ...searchCustomersV2Query, listItems };
      break;
    case SearchType.facility:
      listItems = (
        searchFacilityV2Query?.data?.allFacilitiesV2?.edges ?? []
      ).map(
        ({ node: { id, name } }): ListItem => ({
          id,
          name,
          url: `/facilities/${id}/`,
        })
      );
      listLabel = 'Facilities';
      listQuery = { ...searchFacilityV2Query, listItems };
      break;
    case SearchType.carrier:
    default:
      listLabel = 'Carriers';
      listItems = (
        searchCarriersQueryRes.data?.carriersNullableV2?.edges ?? []
      ).map(
        ({ node: { id, name } }): ListItem => ({
          id,
          name,
          url: `/carriers/${id}/capacity-manager`,
        })
      );
      listQuery = { ...searchCarriersQueryRes, listItems };
      break;
  }

  const { loading, error, listItems: list } = listQuery;

  // TODO ME-10510: Use WhoAmI endpoint instead of token.
  const { user: currentUser } = useKeycloakAuthContext();

  const isLoggedInUser = currentUser?.email === employee.email;

  if (loading) {
    return <p>Loading ...</p>;
  }
  if (error) {
    return <p>Error!</p>;
  }

  const sortedList = sortBy(list, (obj) => obj.name);
  return (
    <Grid css={{ gridTemplateRows: 'min-content 1fr', height: '100%' }}>
      {employee && (
        <div css={{ fontWeight: 'bold', textAlign: 'center' }}>
          {listLabel} for {getNameFromEmployee(employee)}
          <button
            css={{
              padding: '0.5em',
              visibility: isLoggedInUser ? 'hidden' : 'visible',
            }}
            onClick={resetRepFilter}
            data-testid="reset-rep-filter"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      )}
      {shouldUsePagination() ? (
        <ul
          onScroll={handleScrollToBottom}
          css={listStyle}
          data-testid="sidebar-search-list"
        >
          {carriersV2ListItems?.map((sortedInfoObj) =>
            getSidebarRecordsTooltip(sortedInfoObj, listLabel)
          )}
          {loadingCarrierV2 && <p>Loading ...</p>}
        </ul>
      ) : (
        <ul css={listStyle} data-testid="sidebar-search-list">
          {sortedList.map((sortedInfoObj) =>
            getSidebarRecordsTooltip(sortedInfoObj, listLabel)
          )}
        </ul>
      )}
    </Grid>
  );
};
