export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: any;
  /**
   * A date string, such as 2007-12-03, compliant with the `full-date` format
   * outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for
   * representation of dates and times using the Gregorian calendar.
   */
  Date: any;
  DateOnly: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  DateTimeOffset: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  Double: any;
  ErrorDocument: any;
  /** JSON type for GraphQL objects */
  GraphQLJSON: any;
  Guid: any;
  IANATimeZone: any;
  /** An ISO 8601-encoded date */
  Iso8601Date: string;
  /** An ISO 8601-encoded date */
  ISO8601Date: string;
  /** An ISO-8601 encoded UTC date string. */
  ISO8601DateTime: string;
  /** JSON type for GraphQL objects */
  Json: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  JSONObject: any;
  /** The LocalTime scalar type is a local time string (i.e., with no associated timezone) in 24-hr HH:mm:ss. */
  LocalTime: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  /** The multiplier path scalar represents a valid GraphQL multiplier path string. */
  MultiplierPath: any;
  PaginationAmount: any;
  /** The `Short` scalar type represents non-fractional signed whole 16-bit numeric values. Short can represent values between -(2^15) and 2^15 - 1. */
  Short: any;
  TimeOnly: any;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
  Uuid: any;
  UUID: any;
};

export type AbacusCostLineItem = {
  readonly __typename?: 'AbacusCostLineItem';
  readonly laneId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly units?: Maybe<Scalars['Decimal']>;
  readonly costPer?: Maybe<Scalars['Decimal']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['ID'];
  readonly mastermindRatingSource?: Maybe<Scalars['String']>;
  readonly mastermindRatingSourceId?: Maybe<Scalars['ID']>;
  readonly mastermindRatingSourceCode?: Maybe<Scalars['String']>;
};

export type AbacusCostLineItemsInput = {
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly laneId?: InputMaybe<Scalars['ID']>;
  readonly routeId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
};

export type AbacusCostLineItemsPayload = {
  readonly __typename?: 'AbacusCostLineItemsPayload';
  readonly errorMessage?: Maybe<Scalars['String']>;
  readonly mastermindQuoteSource: Scalars['String'];
  readonly costLineItems?: Maybe<ReadonlyArray<Maybe<AbacusCostLineItemV2>>>;
};

export type AbacusCostLineItemV2 = {
  readonly __typename?: 'AbacusCostLineItemV2';
  readonly laneId?: Maybe<Scalars['ID']>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly units?: Maybe<Scalars['Decimal']>;
  readonly costPer?: Maybe<Scalars['Decimal']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly chargeTypeId?: Maybe<Scalars['ID']>;
  readonly mastermindQuoteSource?: Maybe<Scalars['String']>;
  readonly mastermindQuoteSourceId?: Maybe<Scalars['ID']>;
  readonly mastermindQuoteSourceCode?: Maybe<Scalars['String']>;
};

export type AbacusError = {
  readonly __typename?: 'AbacusError';
  readonly message: Scalars['String'];
  readonly code?: Maybe<Scalars['String']>;
};

export type AbacusRouteVendorReference = {
  readonly __typename?: 'AbacusRouteVendorReference';
  readonly routeVendorRefTypeId?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly number?: Maybe<Scalars['String']>;
};

export type AcceptedCarrierRoutes = {
  readonly __typename?: 'AcceptedCarrierRoutes';
  readonly id?: Maybe<Scalars['ID']>;
  readonly commitmentID?: Maybe<Scalars['ID']>;
  readonly costQuoteID?: Maybe<Scalars['ID']>;
  readonly commitmentVolume?: Maybe<Scalars['Int']>;
  readonly commitmentFrequency?: Maybe<Scalars['String']>;
  readonly acceptedCount?: Maybe<Scalars['Int']>;
};

export type AcceptedOrders = {
  readonly __typename?: 'AcceptedOrders';
  readonly id?: Maybe<Scalars['ID']>;
  readonly commitmentVolume?: Maybe<Scalars['Int']>;
  readonly commitmentFrequency?: Maybe<Scalars['String']>;
  readonly acceptedOrdersCount?: Maybe<Scalars['Int']>;
};

export type AcceptOfferResponseInput = {
  readonly offerId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly counterPrice?: InputMaybe<CurrencyInput>;
  readonly counterPriceV2?: InputMaybe<CurrencyInputV2>;
  readonly offersDriver?: InputMaybe<OffersDriverInput>;
  readonly operationSource?: InputMaybe<OfferOperationSource>;
  /** Will be overwritten with OfferResponseType.ACCEPT */
  readonly type?: InputMaybe<OfferResponseType>;
};

export type Accessorial = {
  readonly __typename?: 'Accessorial';
  readonly name?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly rate?: Maybe<Scalars['String']>;
};

export type AccessorialChargeTypeConfigurationCreateInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId: Scalars['Guid'];
  readonly chargeTypeId: Scalars['Guid'];
  readonly customerId?: InputMaybe<Scalars['Guid']>;
  readonly customerName?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly isDefault: Scalars['Boolean'];
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly payProgramDescription: Scalars['String'];
  readonly payProgramChargeCode: Scalars['String'];
  readonly payProgramName?: InputMaybe<Scalars['String']>;
  readonly payProgramId: Scalars['Guid'];
  readonly accessorialChargeTypeRegionConfigurations?: InputMaybe<ReadonlyArray<AccessorialChargeTypeRegionConfigurationInput>>;
};

export type AccessorialChargeTypeConfigurationResponse = {
  readonly __typename?: 'AccessorialChargeTypeConfigurationResponse';
  readonly isActive: Scalars['Boolean'];
  readonly isCredit: Scalars['Boolean'];
  readonly carrierId: Scalars['Guid'];
  readonly category?: Maybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['DateTime'];
  readonly customerId?: Maybe<Scalars['Guid']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly isDefault: Scalars['Boolean'];
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn: Scalars['DateTime'];
  readonly payProgramDescription?: Maybe<Scalars['String']>;
  readonly payProgramChargeCode?: Maybe<Scalars['String']>;
  readonly payProgramName?: Maybe<Scalars['String']>;
  readonly payProgramId?: Maybe<Scalars['Guid']>;
  readonly accessorialChargeTypeRegionConfigurations?: Maybe<ReadonlyArray<AccessorialChargeTypeRegionConfiguration>>;
};

export type AccessorialChargeTypeConfigurationUpdateInput = {
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['Guid'];
  readonly chargeTypeId: Scalars['Guid'];
  readonly customerId?: InputMaybe<Scalars['Guid']>;
  readonly customerName?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly payProgramDescription: Scalars['String'];
  readonly payProgramChargeCode: Scalars['String'];
  readonly payProgramName?: InputMaybe<Scalars['String']>;
  readonly payProgramId: Scalars['Guid'];
  readonly accessorialChargeTypeRegionConfigurations?: InputMaybe<ReadonlyArray<AccessorialChargeTypeRegionConfigurationInput>>;
};

export type AccessorialChargeTypeRegionConfiguration = {
  readonly __typename?: 'AccessorialChargeTypeRegionConfiguration';
  readonly id: Scalars['ID'];
  readonly cost?: Maybe<Scalars['Decimal']>;
  readonly regionId?: Maybe<Scalars['Guid']>;
  readonly perUnit?: Maybe<CtcPerUnitTypeEnum>;
  readonly maximumTotalCost?: Maybe<Scalars['Decimal']>;
  readonly minimumTotalCost?: Maybe<Scalars['Decimal']>;
  readonly isAllowedByCarrier: Scalars['Boolean'];
  readonly accessorialCostPerConfigurationChargeTypeIds?: Maybe<ReadonlyArray<Scalars['Guid']>>;
  readonly isTieredCostPerConfiguration?: Maybe<Scalars['Boolean']>;
  readonly accessorialTieredCostSchedules?: Maybe<ReadonlyArray<Maybe<AccessorialTieredCostSchedule>>>;
};

export type AccessorialChargeTypeRegionConfigurationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly cost?: InputMaybe<Scalars['Decimal']>;
  readonly regionId?: InputMaybe<Scalars['Guid']>;
  readonly perUnit?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly maximumTotalCost?: InputMaybe<Scalars['Decimal']>;
  readonly minimumTotalCost?: InputMaybe<Scalars['Decimal']>;
  readonly accessorialCostPerConfigurationChargeTypeIds?: InputMaybe<ReadonlyArray<Scalars['Guid']>>;
  readonly isTieredCostPerConfiguration?: InputMaybe<Scalars['Boolean']>;
  readonly isAllowedByCarrier?: InputMaybe<Scalars['Boolean']>;
  readonly accessorialTieredCostSchedules?: InputMaybe<ReadonlyArray<AccessorialTieredCostScheduleInput>>;
};

export type AccessorialCostDetail = {
  readonly __typename?: 'AccessorialCostDetail';
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly chargeTypeId: Scalars['ID'];
  readonly chargeTypeName: Scalars['String'];
  readonly chargeTypeIsApplicable: Scalars['Boolean'];
  readonly costPer?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  readonly perUnit?: Maybe<Scalars['String']>;
  readonly calculationInformation?: Maybe<AccessorialDetailCalculationInfo>;
};

export type AccessorialDetailCalculationInfo = {
  readonly __typename?: 'AccessorialDetailCalculationInfo';
  readonly message?: Maybe<Scalars['String']>;
  readonly usedMinimumTotal?: Maybe<Scalars['Boolean']>;
  readonly usedMaximumTotal?: Maybe<Scalars['Boolean']>;
};

export type AccessorialPayload = {
  readonly __typename?: 'AccessorialPayload';
  readonly rateDetails?: Maybe<ReadonlyArray<Maybe<AccessorialRateDetail>>>;
  readonly costDetails?: Maybe<ReadonlyArray<Maybe<AccessorialCostDetail>>>;
};

export type AccessorialRateDetail = {
  readonly __typename?: 'AccessorialRateDetail';
  readonly orderId?: Maybe<Scalars['ID']>;
  readonly chargeTypeId: Scalars['ID'];
  readonly chargeTypeName: Scalars['String'];
  readonly chargeTypeIsApplicable: Scalars['Boolean'];
  readonly ratePer?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly perUnit?: Maybe<Scalars['String']>;
  readonly calculationInformation?: Maybe<AccessorialDetailCalculationInfo>;
};

export type AccessorialSpotQuoteFilterInput = {
  readonly chargeTypeIsApplicable?: InputMaybe<Scalars['Boolean']>;
};

export type AccessorialSpotQuoteInput = {
  readonly filter?: InputMaybe<AccessorialSpotQuoteFilterInput>;
  readonly order?: InputMaybe<AccessorialSpotQuoteOrderInput>;
};

export type AccessorialSpotQuoteOrderInput = {
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly trailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly stops?: InputMaybe<ReadonlyArray<AccessorialSpotStopInput>>;
  readonly transportMode?: InputMaybe<Scalars['String']>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly serviceLevel?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly totalMileage?: InputMaybe<Scalars['Float']>;
  readonly chargeTypeId?: InputMaybe<Scalars['ID']>;
};

export type AccessorialSpotStopInput = {
  readonly country?: InputMaybe<Scalars['String']>;
  readonly region?: InputMaybe<Scalars['String']>;
  readonly unloadType?: InputMaybe<Scalars['String']>;
  readonly pickupDate?: InputMaybe<Scalars['String']>;
  readonly deliveryDate?: InputMaybe<Scalars['String']>;
  readonly scheduleType?: InputMaybe<Scalars['String']>;
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isOrderStop?: InputMaybe<Scalars['Boolean']>;
};

export type AccessorialTieredCostSchedule = {
  readonly __typename?: 'AccessorialTieredCostSchedule';
  readonly id?: Maybe<Scalars['ID']>;
  readonly beginRange?: Maybe<Scalars['Decimal']>;
  readonly endRange?: Maybe<Scalars['Decimal']>;
  readonly costPer?: Maybe<Scalars['Decimal']>;
};

export type AccessorialTieredCostScheduleInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly beginRange?: InputMaybe<Scalars['Decimal']>;
  readonly endRange?: InputMaybe<Scalars['Decimal']>;
  readonly costPer?: InputMaybe<Scalars['Decimal']>;
};

export enum AccountingAudienceEnum {
  /** Exceptions meant for developers. */
  Developer = 'Developer',
  /** Exceptions meant for users. */
  User = 'User'
}

export type AccountingCostdetail = {
  readonly __typename?: 'AccountingCostdetail';
  /** accountId */
  readonly id: Scalars['ID'];
  readonly routeId?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['String']>;
  readonly chargeType?: Maybe<Scalars['String']>;
  readonly costPerUnit?: Maybe<Scalars['String']>;
  readonly units?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  readonly fleet?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['String']>;
  readonly activationStatus?: Maybe<Scalars['String']>;
  readonly primaryDivision?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeTransportMode: Scalars['String'];
  readonly routeSize?: Maybe<Scalars['String']>;
  readonly stopCount: Scalars['String'];
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly executingDivision?: Maybe<Scalars['String']>;
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  readonly routeCarrierId?: Maybe<Scalars['String']>;
  readonly routeCarrierType?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly OST?: Maybe<Scalars['String']>;
  readonly DST?: Maybe<Scalars['String']>;
  readonly vendorFinancialStatus: Scalars['String'];
  readonly voucherNumber?: Maybe<Scalars['String']>;
  readonly voucherDate?: Maybe<Scalars['DateTime']>;
  readonly voucherStatus?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly approvalStatus?: Maybe<Scalars['String']>;
  readonly approvedBy?: Maybe<Scalars['String']>;
  readonly approvedOn?: Maybe<Scalars['DateTime']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly puDate?: Maybe<Scalars['DateTime']>;
  readonly orderReference?: Maybe<Scalars['String']>;
  readonly carrierAccountingStatus?: Maybe<Scalars['String']>;
  readonly costLineItemStatus?: Maybe<Scalars['String']>;
  readonly driverAccountingStatus?: Maybe<Scalars['String']>;
  readonly driverIdentifier?: Maybe<Scalars['String']>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly vendorCode?: Maybe<Scalars['String']>;
};

export type AccountingCostdetailConnection = {
  readonly __typename?: 'AccountingCostdetailConnection';
  readonly edges: ReadonlyArray<AccountingCostdetailEdge>;
  readonly pageInfo: PageInfo;
};

export type AccountingCostdetailEdge = {
  readonly __typename?: 'AccountingCostdetailEdge';
  readonly node: AccountingCostdetail;
  readonly cursor: Scalars['String'];
};

export type AccountingCostdetailFilterInput = {
  readonly routeId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly refId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly delDate?: InputMaybe<Scalars['DateTime']>;
  readonly customer?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLifeCycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly vendorFinancialStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly size?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly primaryDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly chargeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly minAmount?: InputMaybe<Scalars['String']>;
  readonly maxAmount?: InputMaybe<Scalars['String']>;
  readonly voucherNumber?: InputMaybe<Scalars['String']>;
  readonly voucherStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly executingDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly approvalStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly puStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly puEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly carrierAccountingStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly costLineItemStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverAccountingStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverIdentifier?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeCarrierCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderReference?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type AccountingException = {
  readonly __typename?: 'AccountingException';
  readonly id?: Maybe<Scalars['ID']>;
  readonly audience?: Maybe<AccountingAudienceEnum>;
  readonly process?: Maybe<AccountingProcessEnum>;
  readonly integration?: Maybe<AccountingIntegrationEnum>;
  readonly message?: Maybe<Scalars['String']>;
  readonly handledById?: Maybe<Scalars['ID']>;
  readonly isHandled?: Maybe<Scalars['Boolean']>;
  readonly dateHandled?: Maybe<Scalars['Date']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type AccountingExceptionConnection = {
  readonly __typename?: 'AccountingExceptionConnection';
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<AccountingExceptionEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<AccountingException>>>;
};

/** An edge in a connection. */
export type AccountingExceptionEdge = {
  readonly __typename?: 'AccountingExceptionEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<AccountingException>;
};

export type AccountingExceptionFilterInput = {
  readonly audience?: InputMaybe<AccountingAudienceEnum>;
  readonly process?: InputMaybe<AccountingProcessEnum>;
  readonly integration?: InputMaybe<AccountingIntegrationEnum>;
  readonly isHandled?: InputMaybe<Scalars['Boolean']>;
};

export type AccountingExceptionUpdateInput = {
  readonly id: Scalars['ID'];
  readonly isHandled: Scalars['Boolean'];
  readonly dateHandled?: InputMaybe<Scalars['DateTime']>;
  readonly handledById?: InputMaybe<Scalars['ID']>;
};

export enum AccountingIntegrationEnum {
  /** BusinessCentral */
  BusinessCentral = 'BusinessCentral',
  /** MasteryAPI */
  MasteryApi = 'MasteryAPI',
  /** Kafka */
  Kafka = 'Kafka',
  /** Comdata */
  Comdata = 'Comdata',
  /** TCheck */
  TCheck = 'TCheck',
  TrailerBridge = 'TRAILER_BRIDGE',
  MasteryApiIngress = 'MASTERY_API_INGRESS'
}

export enum AccountingProcessEnum {
  /** CustomerInvoiceBatchProcessing */
  CustomerInvoiceBatchProcessing = 'CustomerInvoiceBatchProcessing',
  /** VendorInvoiceBatchProcessing */
  VendorInvoiceBatchProcessing = 'VendorInvoiceBatchProcessing',
  /** CustomerSyncItemApplication */
  CustomerSyncItemApplication = 'CustomerSyncItemApplication',
  /** VendorSyncItemApplication */
  VendorSyncItemApplication = 'VendorSyncItemApplication',
  CustomerVendorSyncProcess = 'CUSTOMER_VENDOR_SYNC_PROCESS',
  CustomerCreditMemoProcessing = 'CUSTOMER_CREDIT_MEMO_PROCESSING',
  VendorCreditMemoProcessing = 'VENDOR_CREDIT_MEMO_PROCESSING',
  Undefined = 'UNDEFINED',
  RefreshAdvanceProcess = 'REFRESH_ADVANCE_PROCESS',
  VoucherIngressProcess = 'VOUCHER_INGRESS_PROCESS',
  InvoiceIngressProcess = 'INVOICE_INGRESS_PROCESS'
}

export type AccountingRateLineDetails = {
  readonly __typename?: 'AccountingRateLineDetails';
  readonly id: Scalars['ID'];
  readonly chargeType?: Maybe<Scalars['String']>;
  readonly ratePerUnit?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly facilityCityState?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly billToCode?: Maybe<Scalars['String']>;
  readonly billToCustomer?: Maybe<Scalars['String']>;
  readonly approvalStatus?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly orderCustomerName?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly OLC?: Maybe<Scalars['String']>;
  readonly OFS?: Maybe<Scalars['String']>;
  readonly OFSR?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['String']>;
  readonly orderMode?: Maybe<Scalars['String']>;
  readonly orderSize?: Maybe<Scalars['String']>;
  readonly orderDivision?: Maybe<Scalars['String']>;
  readonly invoiceNumber?: Maybe<Scalars['String']>;
  readonly invoiceDate?: Maybe<Scalars['DateTime']>;
  readonly invoiceStatus?: Maybe<Scalars['String']>;
  readonly invoiceMethod?: Maybe<Scalars['String']>;
  readonly approvedBy?: Maybe<Scalars['String']>;
  readonly approvedOn?: Maybe<Scalars['DateTime']>;
  readonly OST?: Maybe<Scalars['String']>;
  readonly bypassStatus?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly puDate?: Maybe<Scalars['DateTime']>;
  readonly orderReference?: Maybe<Scalars['String']>;
};

export type AccountingRateLineDetailsConnection = {
  readonly __typename?: 'AccountingRateLineDetailsConnection';
  readonly edges: ReadonlyArray<AccountingRateLineDetailsEdge>;
  readonly pageInfo: PageInfo;
};

export type AccountingRateLineDetailsEdge = {
  readonly __typename?: 'AccountingRateLineDetailsEdge';
  readonly node: AccountingRateLineDetails;
  readonly cursor: Scalars['String'];
};

export type AccountingRateLineDetailsFilterInput = {
  readonly loadId: ReadonlyArray<Scalars['ID']>;
  readonly orderCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly invoice?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderReference?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly invoiceStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly OLC?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly OFS?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly OFSR?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly billToCustomerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly customerRepId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly mode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly size?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly primaryDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly chargeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly approvalStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly totalRateMin?: InputMaybe<Scalars['Float']>;
  readonly totalRateMax?: InputMaybe<Scalars['Float']>;
  readonly createdBy?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly updatedBy?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly originStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly puStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly puEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly deliveryStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly deliveryEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly rateLineStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type AccountingUninvoicedQueue = {
  readonly __typename?: 'AccountingUninvoicedQueue';
  readonly orderId?: Maybe<Scalars['ID']>;
  readonly seerExceptionTypes: Scalars['String'];
  readonly seerExceptionReasons: Scalars['String'];
  /** @deprecated  Id field is deprecated. Use loadId instead. */
  readonly id: Scalars['ID'];
  readonly Customer?: Maybe<Scalars['String']>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly primaryDivision?: Maybe<Scalars['String']>;
  readonly orderType?: Maybe<Scalars['String']>;
  readonly orderRequirements?: Maybe<Scalars['String']>;
  /** orderActivationStatus */
  readonly OAS?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Float']>;
  readonly OLC?: Maybe<Scalars['String']>;
  readonly OFS?: Maybe<Scalars['String']>;
  readonly OAS_reasonCode?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  /** @deprecated invoiceAmount field is deprecated. Use VendorInvoiceAmount instead. */
  readonly invoiceAmount?: Maybe<Scalars['Float']>;
  readonly mainCustRep?: Maybe<Scalars['String']>;
  readonly IncidentCount?: Maybe<Scalars['Float']>;
  readonly accountingStatus?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly VRFS?: Maybe<Scalars['String']>;
  readonly invoiceMethod?: Maybe<Scalars['String']>;
  readonly billToCustomerName?: Maybe<Scalars['String']>;
  readonly billingCode?: Maybe<Scalars['String']>;
  readonly equipment?: Maybe<Scalars['String']>;
  readonly organization?: Maybe<Scalars['String']>;
  readonly orderReferences?: Maybe<Scalars['String']>;
  readonly bypass?: Maybe<Scalars['Boolean']>;
  readonly disableFinancials?: Maybe<Scalars['Boolean']>;
  readonly nonTaxable?: Maybe<Scalars['Boolean']>;
  readonly invoiceHoldReason?: Maybe<Scalars['String']>;
  readonly finalFuelStatus?: Maybe<Scalars['String']>;
  readonly approvedForInvoicing?: Maybe<Scalars['Boolean']>;
  readonly holdApprovedBy?: Maybe<Scalars['String']>;
  readonly vendorName?: Maybe<Scalars['String']>;
  readonly BaseCharge?: Maybe<Scalars['Float']>;
  readonly Other?: Maybe<Scalars['Float']>;
  readonly Fuel?: Maybe<Scalars['Float']>;
  readonly Accessorial?: Maybe<Scalars['Float']>;
  readonly invoiceHoldCloseDate?: Maybe<Scalars['Float']>;
  readonly invoiceApprovedDate?: Maybe<Scalars['Float']>;
  readonly enteredTotalRate?: Maybe<Scalars['Float']>;
  readonly enteredTotalCost?: Maybe<Scalars['Float']>;
  readonly invdAmt?: Maybe<Scalars['Float']>;
  readonly enteredInvdAmt?: Maybe<Scalars['Float']>;
  readonly canDisableFinancials?: Maybe<Scalars['Boolean']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly vendorid?: Maybe<Scalars['ID']>;
  readonly billToCustomerId?: Maybe<Scalars['ID']>;
  readonly employeeId?: Maybe<Scalars['String']>;
  /** @deprecated executingDivision field is deprecated as it is no longer needed. */
  readonly executingDivision?: Maybe<Scalars['String']>;
  readonly originStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly VendorInvoiceAmount?: Maybe<Scalars['Float']>;
  readonly enteredBaseCharge?: Maybe<Scalars['Float']>;
  readonly enteredOther?: Maybe<Scalars['Float']>;
  readonly enteredFuel?: Maybe<Scalars['Float']>;
  readonly enteredAccessorial?: Maybe<Scalars['Float']>;
};


export type AccountingUninvoicedQueueSeerExceptionTypesArgs = {
  seerExceptionFilters?: InputMaybe<SeerExceptionFilterInput>;
};


export type AccountingUninvoicedQueueSeerExceptionReasonsArgs = {
  seerExceptionFilters?: InputMaybe<SeerExceptionFilterInput>;
};

export type AccountingUninvoicedQueueConnection = {
  readonly __typename?: 'AccountingUninvoicedQueueConnection';
  readonly edges: ReadonlyArray<AccountingUninvoicedQueueEdge>;
  readonly pageInfo: PageInfo;
};

export type AccountingUninvoicedQueueEdge = {
  readonly __typename?: 'AccountingUninvoicedQueueEdge';
  readonly node: AccountingUninvoicedQueue;
  readonly cursor: Scalars['String'];
};

export type AccountingUninvoicedQueueFilterInput = {
  readonly loadCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly refCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly puDateStart?: InputMaybe<Scalars['DateTime']>;
  readonly puDateStop?: InputMaybe<Scalars['DateTime']>;
  readonly delDateStart?: InputMaybe<Scalars['DateTime']>;
  readonly delDateStop?: InputMaybe<Scalars['DateTime']>;
  readonly customer?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerRep?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly accountingStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly invoiceMethod?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly billingCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly vendorId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierRep?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly OLC?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly OFS?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly size?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly primaryDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly finalizedFuelStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly billToCustomerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly organization?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly OAS?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly OAS_reasonCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly invoiceHoldReason?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly holdApprovedBy?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly approvedForInvoicing?: InputMaybe<Scalars['Boolean']>;
  readonly invoiceHoldCloseStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly invoiceHoldCloseEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly orderId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** customerId filter is deprecated. Use customer instead */
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly billToCustomerId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** vendorid filter is deprecated. Use vendorId instead */
  readonly vendorid?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly employeeId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** executingDivision filter is deprecated, as it is no longer required. */
  readonly executingDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopEndDate?: InputMaybe<Scalars['DateTime']>;
};

export type AccountingUnvoucheredCarrierProcessingQueue = {
  readonly __typename?: 'AccountingUnvoucheredCarrierProcessingQueue';
  readonly routeId?: Maybe<Scalars['String']>;
  readonly seerExceptionTypes: Scalars['String'];
  readonly seerExceptionReasons: Scalars['String'];
  /** @deprecated Accounting Cost Details Id is deprecated. */
  readonly id: Scalars['ID'];
  readonly vendorType?: Maybe<Scalars['String']>;
  readonly routeVendorType?: Maybe<Scalars['String']>;
  readonly vendorId?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly vendor?: Maybe<Scalars['String']>;
  readonly executingDivision?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly primaryDivision?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly orderType?: Maybe<Scalars['String']>;
  readonly RLC?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Float']>;
  /** @deprecated Field renamed to pu_dateStart */
  readonly PU_Date?: Maybe<Scalars['DateTime']>;
  /** @deprecated Field renamed to del_datestart */
  readonly Del_Date?: Maybe<Scalars['DateTime']>;
  readonly pu_dateStart?: Maybe<Scalars['DateTime']>;
  readonly pu_dateEnd?: Maybe<Scalars['DateTime']>;
  readonly del_dateStart?: Maybe<Scalars['DateTime']>;
  readonly del_dateEnd?: Maybe<Scalars['DateTime']>;
  readonly originFacility?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly destFacility?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  /** @deprecated No longer needed this will be populated with the extended property from client-exception-queue */
  readonly exceptionReasons?: Maybe<Scalars['String']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  readonly advanceAmt?: Maybe<Scalars['Float']>;
  readonly voucherAmt?: Maybe<Scalars['Float']>;
  readonly vendorInvoiceNumber?: Maybe<Scalars['String']>;
  /** @deprecated Field renamed to remitToName */
  readonly remitTo?: Maybe<Scalars['String']>;
  readonly remitToName?: Maybe<Scalars['String']>;
  readonly quickPay?: Maybe<Scalars['String']>;
  readonly vendorInvAmt?: Maybe<Scalars['Float']>;
  readonly venderInvStatus?: Maybe<Scalars['String']>;
  readonly incidentCount?: Maybe<Scalars['Float']>;
  readonly mainCustRep?: Maybe<Scalars['String']>;
  readonly equipment?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly paymentTerm?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly accountingStatus?: Maybe<Scalars['String']>;
  readonly routeVendorRef?: Maybe<Scalars['String']>;
  readonly voucherReleaseDate?: Maybe<Scalars['String']>;
  readonly voucherHoldReason?: Maybe<Scalars['String']>;
  readonly voucherHoldApprovedBy?: Maybe<Scalars['String']>;
  readonly bypass?: Maybe<Scalars['Boolean']>;
  readonly approveForVouchering?: Maybe<Scalars['Boolean']>;
  readonly disableFinancial?: Maybe<Scalars['Boolean']>;
  readonly maxThreshold?: Maybe<Scalars['Float']>;
  readonly minThreshold?: Maybe<Scalars['Float']>;
  readonly advanceFee?: Maybe<Scalars['Float']>;
  readonly requirements?: Maybe<Scalars['String']>;
  readonly vendorRouteFinancialStatus?: Maybe<Scalars['String']>;
  readonly accessorial?: Maybe<Scalars['Float']>;
  readonly baseCharge?: Maybe<Scalars['Float']>;
  readonly fuel?: Maybe<Scalars['Float']>;
  readonly other?: Maybe<Scalars['Float']>;
  readonly accessorialEnteredCost?: Maybe<Scalars['Float']>;
  readonly baseChargeEnteredCost?: Maybe<Scalars['Float']>;
  readonly fuelEnteredCost?: Maybe<Scalars['Float']>;
  readonly otherEnteredCost?: Maybe<Scalars['Float']>;
  readonly vendorInvoiceCount?: Maybe<Scalars['Float']>;
  readonly IssuedAdvanceCreditTotal?: Maybe<Scalars['Float']>;
  readonly canDisableFinancials?: Maybe<Scalars['Boolean']>;
  readonly vendorIsAdvanceProvider?: Maybe<Scalars['Boolean']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly remitToCarrierId?: Maybe<Scalars['String']>;
  readonly enteredTotalCost?: Maybe<Scalars['Float']>;
  readonly enteredCostPer?: Maybe<Scalars['Float']>;
  readonly currencyDiscountAmount?: Maybe<Scalars['Float']>;
  readonly enteredVendorInvAmt?: Maybe<Scalars['Float']>;
  readonly enteredIssuedAdvanceCreditTotal?: Maybe<Scalars['Float']>;
};


export type AccountingUnvoucheredCarrierProcessingQueueSeerExceptionTypesArgs = {
  seerExceptionFilters?: InputMaybe<SeerExceptionFilterInput>;
};


export type AccountingUnvoucheredCarrierProcessingQueueSeerExceptionReasonsArgs = {
  seerExceptionFilters?: InputMaybe<SeerExceptionFilterInput>;
};

export type AccountingUnvoucheredCarrierProcessingQueueConnection = {
  readonly __typename?: 'AccountingUnvoucheredCarrierProcessingQueueConnection';
  readonly edges: ReadonlyArray<AccountingUnvoucheredCarrierProcessingQueueEdge>;
  readonly pageInfo: PageInfo;
};

export type AccountingUnvoucheredCarrierProcessingQueueEdge = {
  readonly __typename?: 'AccountingUnvoucheredCarrierProcessingQueueEdge';
  readonly node: AccountingUnvoucheredCarrierProcessingQueue;
  readonly cursor: Scalars['String'];
};

export type AccountingUnvoucheredCarrierProcessingQueueFilterInput = {
  readonly vendorInvId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly vendorId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly refCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customer?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly puDateStart?: InputMaybe<Scalars['DateTime']>;
  readonly puDateStop?: InputMaybe<Scalars['DateTime']>;
  readonly delDateStart?: InputMaybe<Scalars['DateTime']>;
  readonly delDateStop?: InputMaybe<Scalars['DateTime']>;
  readonly exceptionReasons?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly size?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly primaryDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly RLC?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly organization?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originFacility?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly vendor?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destFacility?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly paymentTerm?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly currency?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly accountingStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly quickPay?: InputMaybe<Scalars['String']>;
  readonly executingDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly remitTo?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerRep?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierRep?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly minimumAdvanceAmt?: InputMaybe<Scalars['Float']>;
  readonly maximumAdvanceAmt?: InputMaybe<Scalars['Float']>;
  readonly minimumTotalAmt?: InputMaybe<Scalars['Float']>;
  readonly maximumTotalAmt?: InputMaybe<Scalars['Float']>;
  readonly OLC?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly OFS?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly maxVoucherReleaseDate?: InputMaybe<Scalars['DateTime']>;
  readonly minVoucherReleaseDate?: InputMaybe<Scalars['DateTime']>;
  readonly voucherHoldReason?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly voucherHoldApprovedBy?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly bypass?: InputMaybe<Scalars['Boolean']>;
  readonly approveForVouchering?: InputMaybe<Scalars['Boolean']>;
  readonly disableFinancial?: InputMaybe<Scalars['Boolean']>;
  readonly vendorRouteFinancialStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerID?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainCustomerRepID?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainCarrierRepID?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly remitToCarrierId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly organizationID?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Use minimumEnteredTotalCost and maximumEnteredTotalCost instead */
  readonly enteredTotalCost?: InputMaybe<Scalars['Float']>;
  readonly minimumEnteredTotalCost?: InputMaybe<Scalars['Float']>;
  readonly maximumEnteredTotalCost?: InputMaybe<Scalars['Float']>;
  readonly accessorialenteredCost?: InputMaybe<Scalars['Float']>;
  readonly baseChargeenteredCost?: InputMaybe<Scalars['Float']>;
  readonly fuelenteredCost?: InputMaybe<Scalars['Float']>;
  readonly otherenteredCost?: InputMaybe<Scalars['Float']>;
};

export enum Action {
  Created = 'Created',
  Updated = 'Updated',
  Deleted = 'Deleted'
}

export type ActivateChannelLegError = ConflictingChannelLegError | ConflictingNetworkChannelError | PlannedNetworkError;

export type ActivateChannelLegInput = {
  readonly id: Scalars['ID'];
};

export type ActivateChannelLegPayload = {
  readonly __typename?: 'ActivateChannelLegPayload';
  readonly data?: Maybe<ChannelLeg>;
  readonly errors?: Maybe<ReadonlyArray<ActivateChannelLegError>>;
};

export type ActivateNetworkChannelError = ConflictingPlannedNetworkError | ConflictingChannelLegError | PlannedNetworkError;

export type ActivateNetworkChannelInput = {
  readonly id: Scalars['ID'];
};

export type ActivateNetworkChannelPayload = {
  readonly __typename?: 'ActivateNetworkChannelPayload';
  readonly data?: Maybe<NetworkChannel>;
  readonly errors?: Maybe<ReadonlyArray<ActivateNetworkChannelError>>;
};

export type ActivateOrDeactivateOrderProfileInput = {
  readonly customerId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly active: Scalars['Boolean'];
};

export type ActivatePlannedNetworkError = ConflictingPlannedNetworkError | ConflictingNetworkChannelError | PlannedNetworkError;

export type ActivatePlannedNetworkInput = {
  readonly id: Scalars['ID'];
};

export type ActivatePlannedNetworkPayload = {
  readonly __typename?: 'ActivatePlannedNetworkPayload';
  readonly data?: Maybe<PlannedNetwork>;
  readonly errors?: Maybe<ReadonlyArray<ActivatePlannedNetworkError>>;
};

export type ActiveClientExceptionRecordsInput = {
  readonly exceptionTypes?: InputMaybe<ReadonlyArray<ClientExceptionRecordType>>;
  readonly sourceID: Scalars['String'];
  readonly sourceType?: InputMaybe<ClientExceptionRecordSourceType>;
  readonly secondarySourceID?: InputMaybe<Scalars['String']>;
  readonly secondarySourceType?: InputMaybe<ClientExceptionRecordSourceType>;
};

export type ActiveCostQuote = {
  readonly __typename?: 'ActiveCostQuote';
  readonly id: Scalars['ID'];
  readonly carrierCapacityV2?: Maybe<CarrierCapacityV2>;
  readonly laneId?: Maybe<Scalars['ID']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly quoteTypeFilter?: Maybe<Scalars['String']>;
  readonly allTotalRate?: Maybe<CurrencyValue>;
  readonly allTotalRateV2?: Maybe<CurrencyOutputValue>;
  readonly code?: Maybe<Scalars['Int']>;
  readonly createdAtDateTime?: Maybe<Scalars['DateTime']>;
  readonly createdById?: Maybe<Scalars['ID']>;
  readonly effectiveDateTime?: Maybe<Scalars['DateTimeOffset']>;
  readonly expirationDateTime?: Maybe<Scalars['DateTimeOffset']>;
  readonly extrasTotalRate?: Maybe<CurrencyValue>;
  readonly extrasTotalRateV2?: Maybe<CurrencyOutputValue>;
  readonly fuelTotalRate?: Maybe<CurrencyValue>;
  readonly fuelTotalRateV2?: Maybe<CurrencyOutputValue>;
  readonly linehaulTotalRate?: Maybe<CurrencyValue>;
  readonly linehaulTotalRateV2?: Maybe<CurrencyOutputValue>;
  readonly multipleStops?: Maybe<Scalars['Boolean']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly quoteType: TenantConfiguration;
  /** @deprecated Use ScacNumber instead */
  readonly scac?: Maybe<TenantConfiguration>;
  readonly scacNumber?: Maybe<Scalars['String']>;
  readonly quotedById?: Maybe<Scalars['ID']>;
  readonly team?: Maybe<Scalars['Boolean']>;
  readonly minimum?: Maybe<Scalars['Decimal']>;
  readonly lineItems?: Maybe<ReadonlyArray<Maybe<CostQuoteLineItem>>>;
  readonly createdBy?: Maybe<User>;
  readonly quotedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly quotedByV2?: Maybe<UserV2>;
  readonly lane?: Maybe<LaneV2>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly carrierCapacity?: Maybe<CarrierCapacity>;
  readonly distanceType?: Maybe<Scalars['Boolean']>;
  readonly externalPricing?: Maybe<Scalars['Boolean']>;
  readonly routeType?: Maybe<TenantConfiguration>;
};

export type ActiveFleetSeachOutput = {
  readonly __typename?: 'ActiveFleetSeachOutput';
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly displayName: Scalars['String'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly statusTerm?: Maybe<Scalars['String']>;
  readonly fleets?: Maybe<ReadonlyArray<DriverFleet>>;
  readonly terminal?: Maybe<FacilityV2>;
  readonly partner?: Maybe<AssetDriver>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  readonly professionalTypeTerm?: Maybe<Scalars['String']>;
  readonly permanentPowerUnitCode?: Maybe<Scalars['String']>;
  readonly permanentTrailerCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentPower?: Maybe<AssetPowerOutput>;
  readonly permanentAssetTrailers?: Maybe<ReadonlyArray<AssetTrailers>>;
  readonly currentRoute?: Maybe<RouteOutput>;
  readonly nextRoute?: Maybe<RouteOutput>;
};

export type ActiveFleetSeachOutputEdge = {
  readonly __typename?: 'ActiveFleetSeachOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: ActiveFleetSeachOutput;
};

export type AddCustomerLaneInput = {
  readonly customerId: Scalars['ID'];
  readonly laneId: Scalars['ID'];
};

export type AddCustomerLaneResponse = {
  readonly __typename?: 'AddCustomerLaneResponse';
  readonly isSuccess: Scalars['Boolean'];
};

export type AddLoadsToBulkReratingRequestInput = {
  readonly requestId: Scalars['ID'];
  readonly loadIds: ReadonlyArray<Scalars['ID']>;
};

export type Address = {
  readonly __typename?: 'Address';
  readonly id: Scalars['ID'];
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: Maybe<Scalars['String']>;
  readonly geopoint: Geopoint;
  readonly addressType?: Maybe<KeyValue>;
  readonly addressTypeId?: Maybe<Scalars['ID']>;
  readonly addressableId: Scalars['ID'];
  readonly addressableType: Scalars['String'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly isVerified: Scalars['Boolean'];
  readonly main: Scalars['Boolean'];
  readonly province: Scalars['String'];
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
};

export type AddressCleanseStatus = {
  readonly __typename?: 'AddressCleanseStatus';
  readonly addressId?: Maybe<Scalars['ID']>;
  readonly addressStatusId: Scalars['ID'];
  readonly addressableType?: Maybe<Scalars['String']>;
  readonly cleanseStatus?: Maybe<Scalars['String']>;
  readonly cleanseSource?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
};

export type AddressCleanseStatusConnection = {
  readonly __typename?: 'AddressCleanseStatusConnection';
  readonly totalCount?: Maybe<Scalars['Int']>;
  readonly pageInfo?: Maybe<CursorPageInfo>;
  readonly edges?: Maybe<ReadonlyArray<AddressCleanseStatusEdge>>;
};

export type AddressCleanseStatusEdge = {
  readonly __typename?: 'AddressCleanseStatusEdge';
  readonly cursor: Scalars['String'];
  readonly node: AddressCleanseStatus;
};

export type AddressCleanseStatusFilter = {
  readonly addressId?: InputMaybe<Scalars['String']>;
  readonly first?: InputMaybe<Scalars['Int']>;
  readonly last?: InputMaybe<Scalars['Int']>;
  readonly before?: InputMaybe<Scalars['String']>;
  readonly after?: InputMaybe<Scalars['String']>;
};

export type AddressesFilter = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly radius?: InputMaybe<UnitOfDistanceInput>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly stateCollection?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly stateIdCollection?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type AddressesQueryInput = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly radius?: InputMaybe<UnitOfDistanceInput>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly street1?: InputMaybe<Scalars['String']>;
  readonly street2?: InputMaybe<Scalars['String']>;
};

export type AddressFilter = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly radius?: InputMaybe<UnitOfLengthInput>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly stateCollection?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly stateIdCollection?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type AddressInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly addressTypeId?: InputMaybe<Scalars['ID']>;
  readonly addressableId?: InputMaybe<Scalars['ID']>;
  readonly addressableType?: InputMaybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isVerified?: InputMaybe<Scalars['Boolean']>;
  readonly main: Scalars['Boolean'];
  readonly postalCode: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type AddressMatchStrength = {
  readonly __typename?: 'AddressMatchStrength';
  readonly strength?: Maybe<Scalars['Int']>;
};

export type AddressResult = {
  readonly __typename?: 'AddressResult';
  readonly id?: Maybe<Scalars['ID']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly street1?: Maybe<Scalars['String']>;
  readonly street2?: Maybe<Scalars['String']>;
  readonly geopoint?: Maybe<Geopoint>;
};

export type AddressResultCodes = {
  readonly __typename?: 'AddressResultCodes';
  readonly code?: Maybe<Scalars['String']>;
  readonly reason?: Maybe<Scalars['String']>;
};

export type AddressResults = {
  readonly __typename?: 'AddressResults';
  readonly VerifiedAddresses?: Maybe<ReadonlyArray<FormattedAddress>>;
  readonly InvalidAddresses?: Maybe<ReadonlyArray<InvalidAddress>>;
};

export type AddressStatusChangeRequest = {
  readonly addressId?: InputMaybe<Scalars['ID']>;
  readonly addressableType?: InputMaybe<Scalars['String']>;
  readonly cleanseSource?: InputMaybe<Scalars['String']>;
  readonly cleanseStatus?: InputMaybe<Scalars['String']>;
};

export type AddRouteToTripInput = {
  readonly routeId: Scalars['ID'];
  readonly tripId: Scalars['ID'];
};

export type AddRouteToTripOutput = {
  readonly __typename?: 'AddRouteToTripOutput';
  readonly updatedTrip?: Maybe<TripType>;
};

export type AddStopAndSplitRouteInput = {
  readonly routeId: Scalars['ID'];
  readonly routeStopId: Scalars['ID'];
  readonly stop: RouteStopInput;
  readonly preview?: InputMaybe<Scalars['Boolean']>;
};

export type AddStopAndSplitRouteInputV2 = {
  readonly routeId: Scalars['ID'];
  readonly routeStopId: Scalars['ID'];
  readonly stop: SplitRouteStopInput;
  readonly preview?: InputMaybe<Scalars['Boolean']>;
};

export type AddTrailerToDriverAssignmentInput = {
  readonly routeID: Scalars['String'];
};

export type AddTrailerToRouteResourcesInput = {
  readonly routeID: Scalars['ID'];
  /** Denotes whether trailer is of type Inbound, Loaded, or Outbound. This value is defaulted to Inbound when input is null */
  readonly trailerAssignmentDesignation?: InputMaybe<TrailerAssignmentDesignationEnum>;
};

export type AddVendorToRouteInput = {
  readonly bookedById: Scalars['ID'];
  readonly createdById: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
  readonly vendorType: RouteVendorTypeEnum;
};

/** Autogenerated return type of AddVendorToRoute */
export type AddVendorToRoutePayload = {
  readonly __typename?: 'AddVendorToRoutePayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendor: RouteVendor;
};

/** Represents the configuration for a charge type for a cost detail within the adjustment operations. */
export type AdjustmentInterface = {
  readonly __typename?: 'AdjustmentInterface';
  readonly id: Scalars['ID'];
  readonly adjustmentType: AdjustmentTypeEnum;
  readonly chargeTypeId?: Maybe<Scalars['ID']>;
};

export type AdjustmentInterfaceCreateInput = {
  readonly adjustmentType: AdjustmentTypeEnum;
  readonly chargeTypeId: Scalars['ID'];
};

export type AdjustmentInterfaceUpdateInput = {
  readonly adjustmentType: AdjustmentTypeEnum;
  readonly chargeTypeId: Scalars['ID'];
  readonly id: Scalars['ID'];
};

export enum AdjustmentTypeEnum {
  /** Indicates that this is an adjustment for a short amount. */
  AdjustmentShort = 'AdjustmentShort',
  /** Indicates that this is an adjustment for an over amount. */
  AdjustmentOver = 'AdjustmentOver'
}

export type AdministrativeAreaDetailsRequest = {
  readonly adminArea: Scalars['String'];
};

export type AdministrativeAreaResponse = {
  readonly __typename?: 'AdministrativeAreaResponse';
  readonly id?: Maybe<Scalars['Int']>;
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly abbreviation?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly abbreviations?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly nameAliases?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

/** Represents an advance to a carrier. */
export type Advance = {
  readonly __typename?: 'Advance';
  readonly id: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly paymentType: PaymentTypeEnum;
  readonly vendorId: Scalars['ID'];
  readonly descriptionId: Scalars['ID'];
  readonly comments?: Maybe<Scalars['String']>;
  readonly status: AdvanceStatusEnum;
  readonly amountRequested: Scalars['Decimal'];
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly neededBy?: Maybe<Scalars['DateTime']>;
  readonly approvalStatus: AdvanceApprovalStatusEnum;
  readonly approvedAmount: Scalars['Decimal'];
  readonly feePercent?: Maybe<Scalars['Decimal']>;
  readonly feeAmount?: Maybe<Scalars['Decimal']>;
  readonly netPayment: Scalars['Decimal'];
  readonly refNumber?: Maybe<Scalars['String']>;
  readonly cashedAmount: Scalars['Decimal'];
  readonly assignedToId: Scalars['ID'];
  readonly ppwReceived: Scalars['Boolean'];
  readonly isClosed?: Maybe<Scalars['Boolean']>;
  readonly closedDateTime?: Maybe<Scalars['DateTime']>;
  readonly requestedByEmployeeId?: Maybe<Scalars['ID']>;
  readonly requestedOn?: Maybe<Scalars['DateTime']>;
  readonly approvedByEmployeeId?: Maybe<Scalars['ID']>;
  readonly approvedOn?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly feeCalculation: FeeCalculationEnum;
  readonly feeMethod: FeeMethodEnum;
  readonly advanceDenialTypeId?: Maybe<Scalars['ID']>;
  readonly advanceNotificationUsers?: Maybe<ReadonlyArray<Maybe<AdvanceNotificationUser>>>;
  readonly assignedToEmployee?: Maybe<Employee>;
  readonly assignedToEmployeeV2?: Maybe<EmployeeV2>;
  readonly requestedByEmployee?: Maybe<Employee>;
  readonly requestedByEmployeeV2?: Maybe<EmployeeV2>;
  readonly approvedByEmployee?: Maybe<Employee>;
  readonly approvedByEmployeeV2?: Maybe<EmployeeV2>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly load?: Maybe<Load>;
  readonly loadV2?: Maybe<LoadV2>;
  readonly advanceProviderName?: Maybe<Scalars['String']>;
  readonly advanceStatusName?: Maybe<Scalars['String']>;
  readonly advanceApprovalStatusName?: Maybe<Scalars['String']>;
  readonly feeCalculationName?: Maybe<Scalars['String']>;
  readonly feeMethodName?: Maybe<Scalars['String']>;
};

export enum AdvanceApprovalStatusEnum {
  /** Indicates that this advance has been approved. */
  Approved = 'Approved',
  /** Indicates that this advance has been denied. */
  Denied = 'Denied',
  /** Indicates that this advance has been requested and is waiting to be approved or denied. */
  Pending = 'Pending'
}

export type AdvanceCreateInput = {
  readonly comments?: InputMaybe<Scalars['String']>;
  readonly amountRequested: Scalars['Decimal'];
  readonly paymentType: PaymentTypeEnum;
  readonly vendorId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly descriptionId: Scalars['ID'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly neededBy?: InputMaybe<Scalars['DateTime']>;
  readonly advanceNotificationUsers?: InputMaybe<ReadonlyArray<InputMaybe<AdvanceNotificationUserModelInput>>>;
  readonly advanceProviderName?: InputMaybe<Scalars['String']>;
};

export type AdvanceCreateInputTypeV2 = {
  readonly comments?: InputMaybe<Scalars['String']>;
  readonly amountRequested: Scalars['Decimal'];
  readonly advanceProvider: AdvanceProviderEnumV2;
  readonly vendorId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly descriptionId: Scalars['ID'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly neededBy?: InputMaybe<Scalars['DateTime']>;
  readonly advanceNotificationUsers?: InputMaybe<ReadonlyArray<InputMaybe<AdvanceNotificationUserModelInput>>>;
  readonly driverCode?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type AdvanceFilterInput = {
  readonly approvalStatus?: InputMaybe<AdvanceApprovalStatusEnum>;
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly startDate?: InputMaybe<Scalars['Date']>;
  readonly endDate?: InputMaybe<Scalars['Date']>;
  readonly advanceApprovalStatusName?: InputMaybe<Scalars['String']>;
  readonly isClosed?: InputMaybe<Scalars['Boolean']>;
  readonly requestedByEmployeeId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly assignedToId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly descriptionIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

/** Represents the configuration for a charge type for a cost detail within the advance operations. */
export type AdvanceInterface = {
  readonly __typename?: 'AdvanceInterface';
  readonly id: Scalars['ID'];
  readonly advanceProvider: AdvanceProviderEnum;
  readonly advanceInterfaceType: AdvanceInterfaceTypeEnum;
  readonly chargeTypeId?: Maybe<Scalars['ID']>;
  readonly advanceProviderName?: Maybe<Scalars['String']>;
  readonly advanceInterfaceTypeName?: Maybe<Scalars['String']>;
};

export type AdvanceInterfaceCreateInput = {
  readonly advanceProvider: AdvanceProviderEnum;
  readonly advanceInterfaceType: AdvanceInterfaceTypeEnum;
  readonly chargeTypeId: Scalars['ID'];
  readonly advanceProviderName?: InputMaybe<Scalars['String']>;
  readonly advanceInterfaceTypeName?: InputMaybe<Scalars['String']>;
};

export type AdvanceInterfaceCreateInputTypeV2 = {
  readonly advanceProvider: AdvanceProviderEnumV2;
  readonly advanceInterfaceType: AdvanceInterfaceTypeEnum;
  readonly chargeTypeId: Scalars['ID'];
};

export type AdvanceInterfaceFilterV2 = {
  readonly advanceProvider: AdvanceProviderEnumV2;
};

export enum AdvanceInterfaceTypeEnum {
  /** Represents the charge type for use with the cost detail associated with the Advance Payment */
  AdvancePayment = 'AdvancePayment',
  /** Represents the charge type for use with the cost detail associated with the Payable Offset */
  PayableOffset = 'PayableOffset',
  /** Represents the charge type for use with the cost detail associated with the specified Advance Provider Expense (Comdata, EFS, ...) */
  AdvanceProviderExpense = 'AdvanceProviderExpense',
  /** Represents the charge type for use with the cost detail associated with the Advance Payment Fee Income */
  AdvancePaymentFeeIncome = 'AdvancePaymentFeeIncome'
}

export type AdvanceInterfaceUpdateInput = {
  readonly advanceProvider: AdvanceProviderEnum;
  readonly advanceInterfaceType: AdvanceInterfaceTypeEnum;
  readonly chargeTypeId: Scalars['ID'];
  readonly advanceProviderName?: InputMaybe<Scalars['String']>;
  readonly advanceInterfaceTypeName?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
};

export type AdvanceInterfaceUpdateInputTypeV2 = {
  readonly advanceProvider: AdvanceProviderEnumV2;
  readonly advanceInterfaceType: AdvanceInterfaceTypeEnum;
  readonly chargeTypeId: Scalars['ID'];
  readonly id: Scalars['ID'];
};

/** Represents the configuration for a charge type for a cost detail within the advance operations. */
export type AdvanceInterfaceV2 = {
  readonly __typename?: 'AdvanceInterfaceV2';
  readonly id: Scalars['ID'];
  readonly advanceProvider: AdvanceProviderEnumV2;
  readonly advanceInterfaceType: AdvanceInterfaceTypeEnum;
  readonly chargeTypeId?: Maybe<Scalars['ID']>;
};

/** The GL configuration for a Advance Notification User */
export type AdvanceNotificationUser = {
  readonly __typename?: 'AdvanceNotificationUser';
  readonly userId?: Maybe<Scalars['ID']>;
  readonly advanceEid?: Maybe<Scalars['ID']>;
  readonly notifyEmployee?: Maybe<Employee>;
  readonly notifyEmployeeV2?: Maybe<EmployeeV2>;
};

export type AdvanceNotificationUserModelInput = {
  readonly userId: Scalars['ID'];
};

/** Represents the available Advance payment types. */
export enum AdvancePaymentTypeEnumV2 {
  NoOp = 'NoOp',
  /** Comcheck provided by Comdata. */
  Comchek = 'Comchek',
  /** TChek provided by EFS. */
  TChek = 'TChek',
  /** MoneyCode provided by EFS. */
  MoneyCode = 'MoneyCode'
}

export type AdvanceProviderConfigCreateInputType = {
  readonly advanceProviderVendorId: Scalars['ID'];
  readonly isDefault: Scalars['Boolean'];
  readonly providerExpense?: InputMaybe<Scalars['Decimal']>;
  readonly advanceProvider: AdvanceProviderEnum;
  readonly advanceProviderName?: InputMaybe<Scalars['String']>;
};

export type AdvanceProviderConfigCreateInputTypeV2 = {
  readonly advanceProviderVendorId: Scalars['ID'];
  readonly isDefault: Scalars['Boolean'];
  readonly providerExpense?: InputMaybe<Scalars['Decimal']>;
  readonly advanceProvider: AdvanceProviderEnumV2;
  readonly paymentType: Scalars['String'];
  readonly isEnabled: Scalars['Boolean'];
};

export type AdvanceProviderConfigCreateUpdateType = {
  readonly advanceProviderVendorId: Scalars['ID'];
  readonly isDefault: Scalars['Boolean'];
  readonly providerExpense?: InputMaybe<Scalars['Decimal']>;
  readonly advanceProvider: AdvanceProviderEnum;
  readonly advanceProviderName?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
};

/** Represents the vendor configuration for an Advance Provider */
export type AdvanceProviderConfigTypeV2 = {
  readonly __typename?: 'AdvanceProviderConfigTypeV2';
  readonly id: Scalars['ID'];
  readonly advanceProvider: AdvanceProviderEnumV2;
  readonly advanceProviderVendorId?: Maybe<Scalars['ID']>;
  readonly isDefault?: Maybe<Scalars['Boolean']>;
  readonly providerExpense?: Maybe<Scalars['Decimal']>;
  readonly paymentType?: Maybe<Scalars['String']>;
  readonly isEnabled?: Maybe<Scalars['Boolean']>;
};

export type AdvanceProviderConfigUpdateInputTypeV2 = {
  readonly advanceProviderVendorId: Scalars['ID'];
  readonly isDefault: Scalars['Boolean'];
  readonly providerExpense?: InputMaybe<Scalars['Decimal']>;
  readonly advanceProvider: AdvanceProviderEnumV2;
  readonly paymentType: Scalars['String'];
  readonly isEnabled: Scalars['Boolean'];
  readonly id: Scalars['ID'];
};

export enum AdvanceProviderEnum {
  /**
   * Represents the Comdata advance integration provider.
   * @deprecated Deprecated, do not use
   */
  Comdata = 'Comdata',
  /**
   * Represents the TCheck advance integration provider.
   * @deprecated Deprecated, do not use
   */
  TCheck = 'TCheck',
  /** @deprecated Deprecated, do not use */
  Noop = 'NOOP',
  EfsMoneyCode = 'EFS_MONEY_CODE'
}

/** Represents the available Advance providers. */
export enum AdvanceProviderEnumV2 {
  /** @deprecated NoOp is no longer a valid AdvanceProviderEnum value. */
  NoOp = 'NoOp',
  /** Represents the Comdata advance integration provider. */
  Comdata = 'Comdata',
  /** Represents the EFS TCheck advance integration provider. */
  EfsTChek = 'EfsTChek',
  /** Represents the EFS MoneyCode advance integration provider. */
  EfsMoneyCode = 'EfsMoneyCode'
}

export type AdvanceRefreshOverrideInputTypeV2 = {
  readonly id: Scalars['ID'];
  readonly cashedAmount: Scalars['Decimal'];
  readonly status?: InputMaybe<AdvanceStatusEnum>;
};

/** Type that represents taking a restricted action, (Issue Payment, Cancel Payment) */
export type AdvanceRestrictedActionInput = {
  readonly id: Scalars['ID'];
  readonly amountRequested: Scalars['Decimal'];
};

export enum AdvanceStatusEnum {
  /** Advance has been requested. */
  Requested = 'Requested',
  /** Advance has been blocked. */
  Blocked = 'Blocked',
  /** Advance has been canceled. */
  Canceled = 'Canceled',
  /** Advance has been cashed. */
  Cashed = 'Cashed',
  /** Advance has been denied from being issued. */
  Denied = 'Denied',
  /** Advance has been issued. */
  Issued = 'Issued',
  /** Advance has been partially cashed. */
  PartiallyCashed = 'PartiallyCashed',
  Approved = 'APPROVED'
}

/** Represents fields that a user may update on the advance */
export type AdvanceUpdateBasic = {
  readonly id: Scalars['ID'];
  readonly comments: Scalars['String'];
  readonly amountRequested: Scalars['Decimal'];
  readonly paymentType: PaymentTypeEnum;
  readonly vendorId: Scalars['ID'];
  readonly descriptionId: Scalars['ID'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly neededBy: Scalars['DateTime'];
  readonly advanceNotificationUsers?: InputMaybe<ReadonlyArray<InputMaybe<AdvanceNotificationUserModelInput>>>;
  readonly advanceProviderName?: InputMaybe<Scalars['String']>;
};

/** Represents fields that a user may update on the advance */
export type AdvanceUpdateBasicInputTypeV2 = {
  readonly id: Scalars['ID'];
  readonly comments: Scalars['String'];
  readonly amountRequested: Scalars['Decimal'];
  readonly advanceProvider: AdvanceProviderEnumV2;
  readonly vendorId: Scalars['ID'];
  readonly descriptionId: Scalars['ID'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly neededBy: Scalars['DateTime'];
  readonly advanceNotificationUsers?: InputMaybe<ReadonlyArray<InputMaybe<AdvanceNotificationUserModelInput>>>;
  readonly driverCode?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type AdvanceUpdateRestrictedInput = {
  readonly id: Scalars['ID'];
  readonly approvalStatus: AdvanceApprovalStatusEnum;
  readonly approvedAmount: Scalars['Decimal'];
  readonly feeCalculation: FeeCalculationEnum;
  readonly feePercent?: InputMaybe<Scalars['Decimal']>;
  readonly flatFeeAmount?: InputMaybe<Scalars['Decimal']>;
  readonly feeMethod?: InputMaybe<FeeMethodEnum>;
  readonly netPayment: Scalars['Decimal'];
  readonly assignedToId: Scalars['ID'];
  readonly ppwReceived: Scalars['Boolean'];
  readonly advanceDenialTypeId?: InputMaybe<Scalars['ID']>;
  readonly advanceApprovalStatusName?: InputMaybe<Scalars['String']>;
  readonly feeCalculationName?: InputMaybe<Scalars['String']>;
  readonly feeMethodName?: InputMaybe<Scalars['String']>;
};

export type AdvanceUpdateRestrictedInputTypeV2 = {
  readonly id: Scalars['ID'];
  readonly approvalStatus: AdvanceApprovalStatusEnum;
  readonly approvedAmount: Scalars['Decimal'];
  readonly feeCalculation: FeeCalculationEnum;
  readonly feePercent?: InputMaybe<Scalars['Decimal']>;
  readonly flatFeeAmount?: InputMaybe<Scalars['Decimal']>;
  readonly feeMethod?: InputMaybe<FeeMethodEnum>;
  readonly netPayment: Scalars['Decimal'];
  readonly assignedToId: Scalars['ID'];
  readonly ppwReceived: Scalars['Boolean'];
  readonly denialReasonTypeId?: InputMaybe<Scalars['ID']>;
};

/** Represents an advance to a carrier. */
export type AdvanceV2 = {
  readonly __typename?: 'AdvanceV2';
  readonly id: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly advanceProvider: AdvanceProviderEnumV2;
  readonly vendorId: Scalars['ID'];
  readonly descriptionId: Scalars['ID'];
  readonly comments?: Maybe<Scalars['String']>;
  readonly status: AdvanceStatusEnum;
  readonly amountRequested: Scalars['Decimal'];
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly neededBy?: Maybe<Scalars['DateTime']>;
  readonly approvalStatus: AdvanceApprovalStatusEnum;
  readonly approvedAmount: Scalars['Decimal'];
  readonly feePercent?: Maybe<Scalars['Decimal']>;
  readonly feeAmount?: Maybe<Scalars['Decimal']>;
  readonly netPayment: Scalars['Decimal'];
  readonly refNumber?: Maybe<Scalars['String']>;
  readonly cashedAmount: Scalars['Decimal'];
  readonly assignedToId: Scalars['ID'];
  readonly ppwReceived: Scalars['Boolean'];
  readonly isClosed?: Maybe<Scalars['Boolean']>;
  readonly closedDateTime?: Maybe<Scalars['DateTime']>;
  readonly requestedByEmployeeId?: Maybe<Scalars['ID']>;
  readonly requestedOn?: Maybe<Scalars['DateTime']>;
  readonly approvedByEmployeeId?: Maybe<Scalars['ID']>;
  readonly approvedOn?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly feeCalculation: FeeCalculationEnum;
  readonly feeMethod: FeeMethodEnum;
  readonly advanceDenialTypeId?: Maybe<Scalars['ID']>;
  readonly advanceNotificationUsers?: Maybe<ReadonlyArray<Maybe<AdvanceNotificationUser>>>;
  readonly driverId?: Maybe<Scalars['ID']>;
  readonly driverCode?: Maybe<Scalars['String']>;
  readonly integrationCode?: Maybe<Scalars['String']>;
  readonly assignedToEmployee?: Maybe<Employee>;
  readonly assignedToEmployeeV2?: Maybe<EmployeeV2>;
  readonly requestedByEmployee?: Maybe<Employee>;
  readonly requestedByEmployeeV2?: Maybe<EmployeeV2>;
  readonly approvedByEmployee?: Maybe<Employee>;
  readonly approvedByEmployeeV2?: Maybe<EmployeeV2>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly load?: Maybe<Load>;
  readonly loadV2?: Maybe<LoadV2>;
};

/** A connection to a list of items. */
export type AdvanceV2Connection = {
  readonly __typename?: 'AdvanceV2Connection';
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<AdvanceV2Edge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<AdvanceV2>>>;
};

/** An edge in a connection. */
export type AdvanceV2Edge = {
  readonly __typename?: 'AdvanceV2Edge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<AdvanceV2>;
};

/** Holds advertised Price Details. */
export type AdvertisedPriceConnection = {
  readonly __typename?: 'AdvertisedPriceConnection';
  readonly code?: Maybe<Scalars['String']>;
  readonly edges?: Maybe<ReadonlyArray<Maybe<PriceDetailEdge>>>;
  readonly message?: Maybe<Scalars['String']>;
  readonly pageInfo?: Maybe<PageInfo>;
};

export type AdvertisedPriceRequestFilter = {
  readonly loadBoardCode?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly orderCode?: InputMaybe<Scalars['String']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
};

export type AgendaSyncPayload = {
  readonly __typename?: 'AgendaSyncPayload';
  readonly realm?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly errors: ReadonlyArray<AgendaUserError>;
};

export type AgendaUserError = {
  readonly __typename?: 'AgendaUserError';
  readonly extensions?: Maybe<Scalars['Json']>;
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type AlchemyPricingApplied = {
  readonly __typename?: 'AlchemyPricingApplied';
  readonly appliedOn: Scalars['String'];
};

export type AlchemyRateQuoteApplied = {
  readonly __typename?: 'AlchemyRateQuoteApplied';
  readonly rateQuote?: Maybe<CustomerQuoteV4>;
  readonly appliedOn: Scalars['String'];
};

export type AlchemyRating = {
  readonly __typename?: 'AlchemyRating';
  readonly orderStampedWithRatingSourceId?: Maybe<Scalars['String']>;
  readonly appliedRatingSource?: Maybe<AppliedRatingSource>;
  readonly appliedRateQuote?: Maybe<AlchemyRateQuoteApplied>;
  readonly appliedSpotQuote?: Maybe<AlchemySpotQuoteApplied>;
  readonly appliedPricing?: Maybe<AlchemyPricingApplied>;
};

export type AlchemySpotQuoteApplied = {
  readonly __typename?: 'AlchemySpotQuoteApplied';
  readonly spotQuote?: Maybe<SpotQuote>;
  readonly appliedOn: Scalars['String'];
};

export type AllBlumeChargeTypesPayload = {
  readonly __typename?: 'AllBlumeChargeTypesPayload';
  readonly blumeCharges: ReadonlyArray<BlumeChargeType>;
};

/** A connection to a list of items. */
export type AllEdiManagementTransactionsConnection = {
  readonly __typename?: 'AllEdiManagementTransactionsConnection';
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<AllEdiManagementTransactionsEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<EdiManagementTransaction>>>;
};

/** An edge in a connection. */
export type AllEdiManagementTransactionsEdge = {
  readonly __typename?: 'AllEdiManagementTransactionsEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<EdiManagementTransaction>;
};

export type AllGroups = {
  readonly __typename?: 'allGroups';
  readonly edges?: Maybe<ReadonlyArray<GroupEdge>>;
  readonly pageInfo: PageInfoDetails;
  readonly totalCount: Scalars['Float'];
  readonly errors?: Maybe<ReadonlyArray<GroupUserError>>;
};

/** All Provider credential history response model */
export type AllProviderCredentialHistoryResponse = {
  readonly __typename?: 'AllProviderCredentialHistoryResponse';
  readonly _id: Scalars['String'];
  readonly providerId: Scalars['String'];
  readonly _ts: Scalars['String'];
  readonly result: Scalars['Boolean'];
};

/** All Provider History response model */
export type AllProviderHistoryResponse = {
  readonly __typename?: 'AllProviderHistoryResponse';
  readonly _id: Scalars['String'];
  readonly providerId: Scalars['String'];
  readonly _ts: Scalars['String'];
  readonly result: Scalars['Boolean'];
};

export type AllRegionsV2 = {
  readonly __typename?: 'AllRegionsV2';
  readonly superRegions?: Maybe<ReadonlyArray<Maybe<SuperRegionV2>>>;
  readonly regions?: Maybe<ReadonlyArray<Maybe<RegionV2>>>;
  readonly regionSets?: Maybe<ReadonlyArray<Maybe<RegionSetR2>>>;
};

export type AllRegionsV2Input = {
  readonly regionSetType: Scalars['String'];
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['String']>;
};

export type AllRegionsV2InputNew = {
  readonly regionSetType: Scalars['String'];
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly equipment?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly countryCode?: InputMaybe<Scalars['String']>;
};

export type AllRegionsV2New = {
  readonly __typename?: 'AllRegionsV2New';
  readonly superRegions?: Maybe<ReadonlyArray<Maybe<SuperRegionV2>>>;
  readonly regions?: Maybe<ReadonlyArray<Maybe<RegionV2>>>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type AlternativeMatchesFilter = {
  readonly routeId?: InputMaybe<Scalars['String']>;
  readonly capacityId?: InputMaybe<Scalars['String']>;
};

/** All Provider API response model */
export type ApiResponse = {
  readonly __typename?: 'ApiResponse';
  readonly active: Scalars['Boolean'];
  readonly api: Scalars['String'];
  readonly secretKey: Scalars['String'];
  readonly _id: Scalars['String'];
};

export type ApiResponseProvider = {
  readonly __typename?: 'APIResponseProvider';
  readonly status?: Maybe<Scalars['Float']>;
  readonly message?: Maybe<Scalars['String']>;
};

export type AppCreationals = {
  readonly __typename?: 'AppCreationals';
  readonly client_id?: Maybe<Scalars['String']>;
  readonly client_secret?: Maybe<Scalars['String']>;
  readonly verification_token?: Maybe<Scalars['String']>;
  readonly signing_secret?: Maybe<Scalars['String']>;
};

export type AppCreationInputObj = {
  readonly config_token: Scalars['String'];
};

export type AppCreationResponseObj = {
  readonly __typename?: 'AppCreationResponseObj';
  readonly success: Scalars['Boolean'];
  readonly app_id?: Maybe<Scalars['String']>;
  readonly credentials?: Maybe<AppCreationals>;
  readonly oauth_authorize_url?: Maybe<Scalars['String']>;
  readonly error?: Maybe<Scalars['String']>;
};

/** The applicable tolerance for a route vendor. */
export type ApplicableRouteVendorTolerance = {
  readonly __typename?: 'ApplicableRouteVendorTolerance';
  readonly id: Scalars['ID'];
  readonly vendorId?: Maybe<Scalars['ID']>;
  readonly loadSize?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly transportMode?: Maybe<Scalars['String']>;
  readonly lowerPercent?: Maybe<Scalars['Decimal']>;
  readonly upperPercent?: Maybe<Scalars['Decimal']>;
  readonly lowerMax: Scalars['Decimal'];
  readonly upperMax: Scalars['Decimal'];
};

export enum AppliedRatingSource {
  Unknown = 'UNKNOWN',
  None = 'NONE',
  RateQuote = 'RATE_QUOTE',
  SpotQuote = 'SPOT_QUOTE',
  Pricing = 'PRICING'
}

export type ApplyPatternAsyncInput = {
  readonly patternId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly userId: Scalars['ID'];
  readonly employeeId: Scalars['ID'];
};

export type ApplyPatternAsyncPayload = {
  readonly __typename?: 'ApplyPatternAsyncPayload';
  readonly data?: Maybe<PatternOrderApplicationPayload>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type ApplyPatternInputV2 = {
  readonly patternId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly userId: Scalars['ID'];
};

export type ApplyPatternOrderRoute = {
  readonly __typename?: 'ApplyPatternOrderRoute';
  readonly routeId: Scalars['ID'];
  readonly routeLaneId?: Maybe<Scalars['ID']>;
  readonly routeSequence: Scalars['Int'];
  readonly isSuccess?: Maybe<Scalars['Boolean']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly vendorId?: Maybe<Scalars['ID']>;
  readonly statuses?: Maybe<ReadonlyArray<ApplyPatternStatusItem>>;
};

export type ApplyPatternPayload = {
  readonly __typename?: 'ApplyPatternPayload';
  readonly message?: Maybe<Scalars['String']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly status?: Maybe<ApplyPatternStatusEnum>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type ApplyPatternPayloadV2 = {
  readonly __typename?: 'ApplyPatternPayloadV2';
  readonly data?: Maybe<ApplyPatternPayload>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export enum ApplyPatternStatusEnum {
  Success = 'success',
  PartialFailure = 'partialFailure',
  Failure = 'failure'
}

export type ApplyPatternStatusItem = {
  readonly __typename?: 'ApplyPatternStatusItem';
  readonly stepName: Scalars['String'];
  readonly stepDescription: Scalars['String'];
  readonly status: Scalars['String'];
  readonly statusMessage: Scalars['String'];
};

export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER',
  Validation = 'VALIDATION'
}

export type Appointment = {
  readonly __typename?: 'Appointment';
  readonly changedBy?: Maybe<Employee>;
  readonly confirmed: Scalars['Boolean'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly id: Scalars['ID'];
  readonly reasonForCancelation?: Maybe<Scalars['String']>;
  readonly requestedBy?: Maybe<Employee>;
  readonly requestedEnd?: Maybe<DatetimeWithTimezone>;
  readonly requestedStart?: Maybe<DatetimeWithTimezone>;
  readonly requestedTimeRange?: Maybe<ScheduleRange>;
  readonly requestedWith?: Maybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
};

export type AppointmentInput = {
  readonly confirmed?: InputMaybe<Scalars['Boolean']>;
  readonly reasonForCancelation?: InputMaybe<Scalars['String']>;
  readonly requestedEndDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly requestedStartDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly requestedTimeRange?: InputMaybe<ScheduleRangeInput>;
  readonly requestedWith?: InputMaybe<Scalars['String']>;
};

export type AppointmentInputV2 = {
  readonly confirmed?: InputMaybe<Scalars['Boolean']>;
  /** The date the appointment window ends.  Timezone is expected to be the facility timezone */
  readonly requestedEndDate?: InputMaybe<DatetimeWithTimezoneInput>;
  /** The date the appointment window starts.  Timezone is expected to be the facility timezone */
  readonly requestedStartDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly requestedTimeRange?: InputMaybe<ScheduleRangeInput>;
  readonly requestedWith?: InputMaybe<Scalars['String']>;
  readonly reasonForCancelation?: InputMaybe<Scalars['String']>;
  readonly autoSchedule?: InputMaybe<Scalars['Boolean']>;
};

export type AppointmentV2 = {
  readonly __typename?: 'AppointmentV2';
  readonly id: Scalars['ID'];
  readonly changedBy?: Maybe<Employee>;
  readonly changedByV2?: Maybe<EmployeeV2>;
  readonly confirmed: Scalars['Boolean'];
  readonly reasonForCancelation?: Maybe<Scalars['String']>;
  readonly requestedBy?: Maybe<Employee>;
  readonly requestedByV2?: Maybe<EmployeeV2>;
  readonly requestedEnd?: Maybe<DatetimeWithTimezone>;
  readonly requestedStart?: Maybe<DatetimeWithTimezone>;
  readonly requestedTimeRange?: Maybe<ScheduleRange>;
  /** @deprecated Marked as deprecated, refer to the RouteStopV2.apptReqWith field instead */
  readonly requestedWith?: Maybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly autoSchedule?: Maybe<Scalars['Boolean']>;
  readonly scheduleReason?: Maybe<Scalars['String']>;
  readonly serviceTolerance?: Maybe<StopServiceTolerance>;
};

export type AreaCount = {
  readonly __typename?: 'AreaCount';
  readonly area: Scalars['String'];
  readonly id: Scalars['String'];
  readonly latitude: Scalars['Float'];
  readonly loadCount: Scalars['Int'];
  readonly longitude: Scalars['Float'];
  readonly truckCount: Scalars['Int'];
};

export enum AreaCountBy {
  Destination = 'DESTINATION',
  Origin = 'ORIGIN'
}

export type ArrivalDepartureEvents = {
  readonly __typename?: 'ArrivalDepartureEvents';
  readonly arrivalEventType: Scalars['String'];
  readonly departureEventType: Scalars['String'];
};

export type AslGenericDateFilter = {
  readonly operator: AslGenericFilterOperator;
  /** required for all filter operators but EXISTS and NOT_EXISTS */
  readonly value1?: InputMaybe<Scalars['DateTime']>;
  /** required only for filter operator BETWEEN as end value */
  readonly value2?: InputMaybe<Scalars['DateTime']>;
};

/** generic filter operator for asl */
export enum AslGenericFilterOperator {
  Greater = 'GREATER',
  Less = 'LESS',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  LessOrEqual = 'LESS_OR_EQUAL',
  Equal = 'EQUAL',
  NotEqual = 'NOT_EQUAL',
  Between = 'BETWEEN',
  Exists = 'EXISTS',
  NotExists = 'NOT_EXISTS'
}

export type AslGenericFloatFilter = {
  readonly operator: AslGenericFilterOperator;
  /** required for all filter operators but EXISTS and NOT_EXISTS */
  readonly value1?: InputMaybe<Scalars['Float']>;
  /** required only for filter operator BETWEEN as end value */
  readonly value2?: InputMaybe<Scalars['Float']>;
};

export type AslGenericIntFilter = {
  readonly operator: AslGenericFilterOperator;
  /** required for all filter operators but EXISTS and NOT_EXISTS */
  readonly value1?: InputMaybe<Scalars['Int']>;
  /** required only for filter operator BETWEEN as end value */
  readonly value2?: InputMaybe<Scalars['Int']>;
};

/** Sort order for asl */
export enum AslGenericOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type AslGenericRouteViewFilter = {
  readonly customers_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly customers_name_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly customers_groups_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly customers_groups_name_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly customers_groups_kvtId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly customers_groups_kvtName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly activeCarrier_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly activeCarrier_name_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeVendors_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeVendors_vendorId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeVendors_name_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeVendors_active?: InputMaybe<Scalars['Boolean']>;
  readonly routeVendors_vendorType_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeVendors_utcExpectedEmptyDatetime?: InputMaybe<AslGenericDateFilter>;
  readonly routeVendors_expectedEmptyGeography_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeVendors_expectedEmptyGeography_name_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeVendors_expectedEmptyGeography_state_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeVendors_expectedEmptyGeography_timezone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly vendorReps_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly vendorReps_employeeId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly vendorReps_firstName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly vendorReps_lastName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly orderReps_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly orderReps_employeeId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly orderReps_firstName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly orderReps_lastName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly indexed?: InputMaybe<AslGenericDateFilter>;
  readonly created?: InputMaybe<AslGenericDateFilter>;
  readonly id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly previousRouteId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeCode_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly loadId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeLifeCycleStatus_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeActivationStatus_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeActivationStatus_name_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly orderActivationStatus_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly orderActivationStatus_name_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stopAmount?: InputMaybe<AslGenericIntFilter>;
  readonly stops_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_address_city_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_address_state_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_timeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_appointmentDate_start?: InputMaybe<AslGenericDateFilter>;
  readonly stops_appointmentDate_end?: InputMaybe<AslGenericDateFilter>;
  readonly stops_appointmentDate_timeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_appointmentTime_startOffset?: InputMaybe<AslGenericIntFilter>;
  readonly stops_appointmentTime_endOffset?: InputMaybe<AslGenericIntFilter>;
  readonly stops_status_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_superRegionUrn_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_regionUrn_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_subRegionUrn_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_stopType_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_scheduleAppointmentType_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_sequence?: InputMaybe<AslGenericFloatFilter>;
  readonly stops_carrierArrivalDate?: InputMaybe<AslGenericDateFilter>;
  readonly stops_carrierArrivalTimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_carrierDepartureDate?: InputMaybe<AslGenericDateFilter>;
  readonly stops_carrierDepartureTimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_address_city_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_address_state_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_timeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_appointmentDate_start?: InputMaybe<AslGenericDateFilter>;
  readonly firstStop_appointmentDate_end?: InputMaybe<AslGenericDateFilter>;
  readonly firstStop_appointmentDate_timeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_appointmentTime_startOffset?: InputMaybe<AslGenericIntFilter>;
  readonly firstStop_appointmentTime_endOffset?: InputMaybe<AslGenericIntFilter>;
  readonly firstStop_status_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_superRegionUrn_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_regionUrn_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_subRegionUrn_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_stopType_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_scheduleAppointmentType_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_sequence?: InputMaybe<AslGenericFloatFilter>;
  readonly firstStop_carrierArrivalDate?: InputMaybe<AslGenericDateFilter>;
  readonly firstStop_carrierArrivalTimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_carrierDepartureDate?: InputMaybe<AslGenericDateFilter>;
  readonly firstStop_carrierDepartureTimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_address_city_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_address_state_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_timeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_appointmentDate_start?: InputMaybe<AslGenericDateFilter>;
  readonly lastStop_appointmentDate_end?: InputMaybe<AslGenericDateFilter>;
  readonly lastStop_appointmentDate_timeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_appointmentTime_startOffset?: InputMaybe<AslGenericIntFilter>;
  readonly lastStop_appointmentTime_endOffset?: InputMaybe<AslGenericIntFilter>;
  readonly lastStop_status_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_superRegionUrn_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_regionUrn_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_subRegionUrn_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_stopType_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_scheduleAppointmentType_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_sequence?: InputMaybe<AslGenericFloatFilter>;
  readonly lastStop_carrierArrivalDate?: InputMaybe<AslGenericDateFilter>;
  readonly lastStop_carrierArrivalTimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_carrierDepartureDate?: InputMaybe<AslGenericDateFilter>;
  readonly lastStop_carrierDepartureTimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lock_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lock_objectID_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lock_flavor_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lock_expirationTimestamp?: InputMaybe<AslGenericDateFilter>;
  readonly lock_owner_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lock_owner_email_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lock_owner_employee_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lock_owner_employee_fullName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_lastUpdateId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_lastDriverAssignmentId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_driverName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_driverNumber_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_trackingNote_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_actualUpdateTime?: InputMaybe<AslGenericDateFilter>;
  readonly trackingDetails_createDate?: InputMaybe<AslGenericDateFilter>;
  readonly trackingDetails_createdById_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_createdByName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_createdBy_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_chkCallDate?: InputMaybe<AslGenericDateFilter>;
  readonly trackingDetails_nextRoute_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_previousRoute_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_trailerNumber_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_tractorNumber_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_etaDate?: InputMaybe<AslGenericDateFilter>;
  readonly trackingDetails_etaTimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_carrierId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_carrierName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trailerTypes_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trailerTypes_name_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly transportMode_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly transportMode_name_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly dimensions_height?: InputMaybe<AslGenericFloatFilter>;
  readonly dimensions_heightUnit_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly dimensions_width?: InputMaybe<AslGenericFloatFilter>;
  readonly dimensions_widthUnit_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly dimensions_length?: InputMaybe<AslGenericFloatFilter>;
  readonly dimensions_lengthUnit_keyword?: InputMaybe<AslGenericStringFilter>;
};

export type AslGenericRouteViewOrderBy = {
  readonly customers_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly customers_name_keyword?: InputMaybe<AslGenericOrder>;
  readonly customers_groups_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly customers_groups_name_keyword?: InputMaybe<AslGenericOrder>;
  readonly customers_groups_kvtId_keyword?: InputMaybe<AslGenericOrder>;
  readonly customers_groups_kvtName_keyword?: InputMaybe<AslGenericOrder>;
  readonly activeCarrier_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly activeCarrier_name_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_vendorId_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_name_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_active?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_vendorType_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_utcExpectedEmptyDatetime?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_expectedEmptyGeography_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_expectedEmptyGeography_name_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_expectedEmptyGeography_state_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_expectedEmptyGeography_timezone_keyword?: InputMaybe<AslGenericOrder>;
  readonly vendorReps_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly vendorReps_employeeId_keyword?: InputMaybe<AslGenericOrder>;
  readonly vendorReps_firstName_keyword?: InputMaybe<AslGenericOrder>;
  readonly vendorReps_lastName_keyword?: InputMaybe<AslGenericOrder>;
  readonly orderReps_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly orderReps_employeeId_keyword?: InputMaybe<AslGenericOrder>;
  readonly orderReps_firstName_keyword?: InputMaybe<AslGenericOrder>;
  readonly orderReps_lastName_keyword?: InputMaybe<AslGenericOrder>;
  readonly indexed?: InputMaybe<AslGenericOrder>;
  readonly created?: InputMaybe<AslGenericOrder>;
  readonly id_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeId_keyword?: InputMaybe<AslGenericOrder>;
  readonly previousRouteId_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeCode_keyword?: InputMaybe<AslGenericOrder>;
  readonly loadId_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeLifeCycleStatus_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeActivationStatus_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeActivationStatus_name_keyword?: InputMaybe<AslGenericOrder>;
  readonly orderActivationStatus_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly orderActivationStatus_name_keyword?: InputMaybe<AslGenericOrder>;
  readonly stopAmount?: InputMaybe<AslGenericOrder>;
  readonly stops_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_address_city_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_address_state_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_timeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_appointmentDate_start?: InputMaybe<AslGenericOrder>;
  readonly stops_appointmentDate_end?: InputMaybe<AslGenericOrder>;
  readonly stops_appointmentDate_timeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_appointmentTime_startOffset?: InputMaybe<AslGenericOrder>;
  readonly stops_appointmentTime_endOffset?: InputMaybe<AslGenericOrder>;
  readonly stops_status_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_superRegionUrn_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_regionUrn_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_subRegionUrn_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_stopType_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_scheduleAppointmentType_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_sequence?: InputMaybe<AslGenericOrder>;
  readonly stops_carrierArrivalDate?: InputMaybe<AslGenericOrder>;
  readonly stops_carrierArrivalTimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_carrierDepartureDate?: InputMaybe<AslGenericOrder>;
  readonly stops_carrierDepartureTimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_address_city_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_address_state_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_timeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_appointmentDate_start?: InputMaybe<AslGenericOrder>;
  readonly firstStop_appointmentDate_end?: InputMaybe<AslGenericOrder>;
  readonly firstStop_appointmentDate_timeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_appointmentTime_startOffset?: InputMaybe<AslGenericOrder>;
  readonly firstStop_appointmentTime_endOffset?: InputMaybe<AslGenericOrder>;
  readonly firstStop_status_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_superRegionUrn_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_regionUrn_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_subRegionUrn_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_stopType_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_scheduleAppointmentType_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_sequence?: InputMaybe<AslGenericOrder>;
  readonly firstStop_carrierArrivalDate?: InputMaybe<AslGenericOrder>;
  readonly firstStop_carrierArrivalTimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_carrierDepartureDate?: InputMaybe<AslGenericOrder>;
  readonly firstStop_carrierDepartureTimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_address_city_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_address_state_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_timeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_appointmentDate_start?: InputMaybe<AslGenericOrder>;
  readonly lastStop_appointmentDate_end?: InputMaybe<AslGenericOrder>;
  readonly lastStop_appointmentDate_timeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_appointmentTime_startOffset?: InputMaybe<AslGenericOrder>;
  readonly lastStop_appointmentTime_endOffset?: InputMaybe<AslGenericOrder>;
  readonly lastStop_status_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_superRegionUrn_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_regionUrn_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_subRegionUrn_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_stopType_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_scheduleAppointmentType_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_sequence?: InputMaybe<AslGenericOrder>;
  readonly lastStop_carrierArrivalDate?: InputMaybe<AslGenericOrder>;
  readonly lastStop_carrierArrivalTimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_carrierDepartureDate?: InputMaybe<AslGenericOrder>;
  readonly lastStop_carrierDepartureTimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly lock_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly lock_objectID_keyword?: InputMaybe<AslGenericOrder>;
  readonly lock_flavor_keyword?: InputMaybe<AslGenericOrder>;
  readonly lock_expirationTimestamp?: InputMaybe<AslGenericOrder>;
  readonly lock_owner_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly lock_owner_email_keyword?: InputMaybe<AslGenericOrder>;
  readonly lock_owner_employee_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly lock_owner_employee_fullName_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_lastUpdateId_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_lastDriverAssignmentId_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_driverName_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_driverNumber_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_trackingNote_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_actualUpdateTime?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_createDate?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_createdById_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_createdByName_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_createdBy_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_chkCallDate?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_nextRoute_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_previousRoute_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_trailerNumber_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_tractorNumber_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_etaDate?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_etaTimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_carrierId_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_carrierName_keyword?: InputMaybe<AslGenericOrder>;
  readonly trailerTypes_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly trailerTypes_name_keyword?: InputMaybe<AslGenericOrder>;
  readonly transportMode_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly transportMode_name_keyword?: InputMaybe<AslGenericOrder>;
  readonly dimensions_height?: InputMaybe<AslGenericOrder>;
  readonly dimensions_heightUnit_keyword?: InputMaybe<AslGenericOrder>;
  readonly dimensions_width?: InputMaybe<AslGenericOrder>;
  readonly dimensions_widthUnit_keyword?: InputMaybe<AslGenericOrder>;
  readonly dimensions_length?: InputMaybe<AslGenericOrder>;
  readonly dimensions_lengthUnit_keyword?: InputMaybe<AslGenericOrder>;
};

export type AslGenericStringFilter = {
  readonly operator: AslGenericStringOperator;
  /** required for all filter operators but EXISTS and NOT_EXISTS */
  readonly value?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** Filter operator for strings */
export enum AslGenericStringOperator {
  CombineAnd = 'COMBINE_AND',
  CombineOr = 'COMBINE_OR',
  Exists = 'EXISTS',
  NotExists = 'NOT_EXISTS'
}

export type AslGenericTrackingUpdatesViewFilter = {
  readonly indexed?: InputMaybe<AslGenericDateFilter>;
  readonly id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_processedDate?: InputMaybe<AslGenericDateFilter>;
  readonly updates_processed?: InputMaybe<Scalars['Boolean']>;
  readonly updates_routeId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_updateId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_carrierId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_action_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_customerId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_routeStopId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_currentLocation_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_currentLocationLatitude?: InputMaybe<AslGenericFloatFilter>;
  readonly updates_currentLocationLongitude?: InputMaybe<AslGenericFloatFilter>;
  readonly updates_currentLocationCity_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_currentLocationState_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_currentLocationCountry_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_currentLocationPostalCode_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_actualEventTime?: InputMaybe<AslGenericDateFilter>;
  readonly updates_actualEventTimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_infoFrom_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_nextLocation_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_nextLocationLatitude?: InputMaybe<AslGenericFloatFilter>;
  readonly updates_nextLocationLongitude?: InputMaybe<AslGenericFloatFilter>;
  readonly updates_nextCity_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_nextState_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_nextStopId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_notes_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_distanceToNext?: InputMaybe<AslGenericFloatFilter>;
  readonly updates_distanceToNextUnit_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_checkCallDatetime?: InputMaybe<AslGenericDateFilter>;
  readonly updates_checkCallDatetimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_etaDatetime?: InputMaybe<AslGenericDateFilter>;
  readonly updates_etaDatetimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_trailerNumber_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_tenant_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_created_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_created_displayName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_created_by_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_created_timestamp?: InputMaybe<AslGenericDateFilter>;
};

export type AslGenericTrackingUpdatesViewOrderBy = {
  readonly indexed?: InputMaybe<AslGenericOrder>;
  readonly id_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeId_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_processedDate?: InputMaybe<AslGenericOrder>;
  readonly updates_processed?: InputMaybe<AslGenericOrder>;
  readonly updates_routeId_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_updateId_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_carrierId_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_action_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_customerId_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_routeStopId_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_currentLocation_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_currentLocationLatitude?: InputMaybe<AslGenericOrder>;
  readonly updates_currentLocationLongitude?: InputMaybe<AslGenericOrder>;
  readonly updates_currentLocationCity_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_currentLocationState_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_currentLocationCountry_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_currentLocationPostalCode_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_actualEventTime?: InputMaybe<AslGenericOrder>;
  readonly updates_actualEventTimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_infoFrom_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_nextLocation_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_nextLocationLatitude?: InputMaybe<AslGenericOrder>;
  readonly updates_nextLocationLongitude?: InputMaybe<AslGenericOrder>;
  readonly updates_nextCity_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_nextState_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_nextStopId_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_notes_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_distanceToNext?: InputMaybe<AslGenericOrder>;
  readonly updates_distanceToNextUnit_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_checkCallDatetime?: InputMaybe<AslGenericOrder>;
  readonly updates_checkCallDatetimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_etaDatetime?: InputMaybe<AslGenericOrder>;
  readonly updates_etaDatetimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_trailerNumber_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_tenant_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_created_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_created_displayName_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_created_by_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_created_timestamp?: InputMaybe<AslGenericOrder>;
};

export type AslRouteViewCarrier = {
  readonly __typename?: 'AslRouteViewCarrier';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
};

export type AslRouteViewCustomer = {
  readonly __typename?: 'AslRouteViewCustomer';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly groups: ReadonlyArray<AslRouteViewCustomerGroup>;
};

export type AslRouteViewCustomerGroup = {
  readonly __typename?: 'AslRouteViewCustomerGroup';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly kvtId?: Maybe<Scalars['String']>;
  readonly kvtName?: Maybe<Scalars['String']>;
};

export type AslRouteViewDateRange = {
  readonly __typename?: 'AslRouteViewDateRange';
  readonly start?: Maybe<Scalars['DateTime']>;
  readonly end?: Maybe<Scalars['DateTime']>;
  readonly timeZone?: Maybe<Scalars['String']>;
};

export type AslRouteViewDimensions = {
  readonly __typename?: 'AslRouteViewDimensions';
  readonly height?: Maybe<Scalars['Float']>;
  readonly heightUnit?: Maybe<Scalars['String']>;
  readonly width?: Maybe<Scalars['Float']>;
  readonly widthUnit?: Maybe<Scalars['String']>;
  readonly length?: Maybe<Scalars['Float']>;
  readonly lengthUnit?: Maybe<Scalars['String']>;
};

export type AslRouteViewEdge = {
  readonly __typename?: 'AslRouteViewEdge';
  readonly cursor: Scalars['String'];
  readonly node: AslRouteViewNode;
};

export type AslRouteViewGeography = {
  readonly __typename?: 'AslRouteViewGeography';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly timezone?: Maybe<Scalars['String']>;
};

export type AslRouteViewInput = {
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  readonly customerIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly orderRepIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeRepIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly mustMatchBothRouteAndOrderReps?: InputMaybe<Scalars['Boolean']>;
  readonly inboundRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly employeeGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLifecycleStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly transportModes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hasTrackingDetails?: InputMaybe<Scalars['Boolean']>;
  /**
   * Will execute separate queries per given route.lifecycleStatus, this means you
   * will get count(lifecycleStatus)*pageSize results and the pageInfo cursors are
   * a map of status->cursor. hasNextPage is an or combination and might just be
   * respected for a single status
   */
  readonly separateQueryPerRouteLifecycleStatus?: InputMaybe<Scalars['Boolean']>;
  /**
   * Provides the possibility to give ordering per lifecycle status. Note that the
   * index of the order needs to match the index of the lifecyclestatus (in their
   * corresponding arrays). The contents of this array will be used instead of the
   * ordering given in the resolver.
   */
  readonly orderByPerLifeCycleStatus?: InputMaybe<ReadonlyArray<AslGenericRouteViewOrderBy>>;
  readonly genericFilter?: InputMaybe<AslGenericRouteViewFilter>;
};

export type AslRouteViewKvt = {
  readonly __typename?: 'AslRouteViewKVT';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type AslRouteViewLockInfo = {
  readonly __typename?: 'AslRouteViewLockInfo';
  readonly id: Scalars['ID'];
  readonly objectID: Scalars['String'];
  readonly flavor: Scalars['String'];
  readonly expirationTimestamp: Scalars['DateTime'];
  readonly owner: AslRouteViewLockOwner;
};

export type AslRouteViewLockInfoEmployee = {
  readonly __typename?: 'AslRouteViewLockInfoEmployee';
  readonly id: Scalars['ID'];
  readonly fullName: Scalars['String'];
};

export type AslRouteViewLockOwner = {
  readonly __typename?: 'AslRouteViewLockOwner';
  readonly id: Scalars['ID'];
  readonly email: Scalars['String'];
  readonly employee?: Maybe<AslRouteViewLockInfoEmployee>;
};

export type AslRouteViewNode = {
  readonly __typename?: 'AslRouteViewNode';
  readonly customers: ReadonlyArray<AslRouteViewCustomer>;
  readonly activeCarrier?: Maybe<AslRouteViewCarrier>;
  readonly routeVendors: ReadonlyArray<AslRouteViewRouteVendor>;
  readonly vendorReps: ReadonlyArray<AslRouteViewVendorRep>;
  readonly orderReps: ReadonlyArray<AslRouteViewOrderRep>;
  readonly indexed: Scalars['DateTime'];
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly previousRouteId?: Maybe<Scalars['ID']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<AslRouteViewKvt>;
  readonly orderActivationStatus?: Maybe<AslRouteViewKvt>;
  readonly stopAmount: Scalars['Int'];
  readonly stops: ReadonlyArray<AslRouteViewStop>;
  readonly firstStop?: Maybe<AslRouteViewStop>;
  readonly lastStop?: Maybe<AslRouteViewStop>;
  readonly lock?: Maybe<AslRouteViewLockInfo>;
  readonly trackingDetails?: Maybe<AslRouteViewTrackingDetails>;
  readonly trailerTypes?: Maybe<ReadonlyArray<AslRouteViewKvt>>;
  readonly transportMode?: Maybe<AslRouteViewKvt>;
  readonly dimensions?: Maybe<AslRouteViewDimensions>;
};

export type AslRouteViewOrderRep = {
  readonly __typename?: 'AslRouteViewOrderRep';
  readonly id: Scalars['ID'];
  readonly employeeId: Scalars['String'];
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
};

export type AslRouteViewResult = {
  readonly __typename?: 'AslRouteViewResult';
  readonly edges: ReadonlyArray<AslRouteViewEdge>;
  readonly pageInfo: PageInfo;
};

export type AslRouteViewRouteVendor = {
  readonly __typename?: 'AslRouteViewRouteVendor';
  readonly id: Scalars['ID'];
  readonly vendorId: Scalars['String'];
  readonly name?: Maybe<Scalars['String']>;
  readonly active: Scalars['Boolean'];
  readonly vendorType?: Maybe<Scalars['String']>;
  readonly utcExpectedEmptyDatetime?: Maybe<Scalars['DateTime']>;
  readonly expectedEmptyGeography?: Maybe<AslRouteViewGeography>;
};

export type AslRouteViewStop = {
  readonly __typename?: 'AslRouteViewStop';
  readonly id: Scalars['ID'];
  readonly address?: Maybe<AslRouteViewStopAddress>;
  readonly timeZone?: Maybe<Scalars['String']>;
  readonly appointmentDate?: Maybe<AslRouteViewDateRange>;
  readonly appointmentTime?: Maybe<AslRouteViewTimeRange>;
  readonly status?: Maybe<Scalars['String']>;
  readonly superRegionUrn?: Maybe<Scalars['String']>;
  readonly regionUrn?: Maybe<Scalars['String']>;
  readonly subRegionUrn?: Maybe<Scalars['String']>;
  readonly stopType?: Maybe<Scalars['String']>;
  readonly scheduleAppointmentType?: Maybe<Scalars['String']>;
  readonly sequence: Scalars['Float'];
  readonly carrierArrivalDate?: Maybe<Scalars['DateTime']>;
  readonly carrierArrivalTimeZone?: Maybe<Scalars['String']>;
  readonly carrierDepartureDate?: Maybe<Scalars['DateTime']>;
  readonly carrierDepartureTimeZone?: Maybe<Scalars['String']>;
};

export type AslRouteViewStopAddress = {
  readonly __typename?: 'AslRouteViewStopAddress';
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
};

export type AslRouteViewTimeRange = {
  readonly __typename?: 'AslRouteViewTimeRange';
  readonly startOffset?: Maybe<Scalars['Int']>;
  readonly endOffset?: Maybe<Scalars['Int']>;
};

export type AslRouteViewTrackingDetails = {
  readonly __typename?: 'AslRouteViewTrackingDetails';
  readonly lastUpdateId?: Maybe<Scalars['String']>;
  readonly lastDriverAssignmentId?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverNumber?: Maybe<Scalars['String']>;
  readonly trackingNote?: Maybe<Scalars['String']>;
  readonly actualUpdateTime?: Maybe<Scalars['DateTime']>;
  readonly createDate?: Maybe<Scalars['DateTime']>;
  readonly createdById?: Maybe<Scalars['String']>;
  readonly createdByName?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly chkCallDate?: Maybe<Scalars['DateTime']>;
  readonly nextRoute?: Maybe<Scalars['ID']>;
  readonly previousRoute?: Maybe<Scalars['ID']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
  readonly etaDate?: Maybe<Scalars['DateTime']>;
  readonly etaTimeZone?: Maybe<Scalars['String']>;
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly carrierName?: Maybe<Scalars['String']>;
};

export type AslRouteViewVendorRep = {
  readonly __typename?: 'AslRouteViewVendorRep';
  readonly id: Scalars['ID'];
  readonly employeeId: Scalars['String'];
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
};

export type AslTrackingUpdate = {
  readonly __typename?: 'AslTrackingUpdate';
  readonly processedDate?: Maybe<Scalars['DateTime']>;
  readonly processed: Scalars['Boolean'];
  readonly routeId: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly updateId: Scalars['ID'];
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly action?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly routeStopId?: Maybe<Scalars['String']>;
  readonly currentLocation?: Maybe<Scalars['String']>;
  readonly currentLocationLatitude?: Maybe<Scalars['Float']>;
  readonly currentLocationLongitude?: Maybe<Scalars['Float']>;
  readonly currentLocationCity?: Maybe<Scalars['String']>;
  readonly currentLocationState?: Maybe<Scalars['String']>;
  readonly currentLocationCountry?: Maybe<Scalars['String']>;
  readonly currentLocationPostalCode?: Maybe<Scalars['String']>;
  readonly actualEventTime?: Maybe<Scalars['DateTime']>;
  readonly actualEventTimeZone?: Maybe<Scalars['String']>;
  readonly infoFrom?: Maybe<Scalars['String']>;
  readonly nextLocation?: Maybe<Scalars['String']>;
  readonly nextLocationLatitude?: Maybe<Scalars['Float']>;
  readonly nextLocationLongitude?: Maybe<Scalars['Float']>;
  readonly nextCity?: Maybe<Scalars['String']>;
  readonly nextState?: Maybe<Scalars['String']>;
  readonly nextStopId?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly distanceToNext?: Maybe<Scalars['Float']>;
  readonly distanceToNextUnit?: Maybe<Scalars['String']>;
  readonly checkCallDatetime?: Maybe<Scalars['DateTime']>;
  readonly checkCallDatetimeZone?: Maybe<Scalars['String']>;
  readonly etaDatetime?: Maybe<Scalars['DateTime']>;
  readonly etaDatetimeZone?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly tenant?: Maybe<Scalars['String']>;
  readonly created?: Maybe<AslTrackingUpdateAuditInfo>;
};

export type AslTrackingUpdateAuditInfo = {
  readonly __typename?: 'AslTrackingUpdateAuditInfo';
  readonly id?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly by: Scalars['String'];
  readonly timestamp: Scalars['DateTime'];
};

export type AslTrackingUpdatesViewEdge = {
  readonly __typename?: 'AslTrackingUpdatesViewEdge';
  readonly cursor: Scalars['String'];
  readonly node: AslTrackingUpdatesViewNode;
};

export type AslTrackingUpdatesViewNode = {
  readonly __typename?: 'AslTrackingUpdatesViewNode';
  readonly indexed: Scalars['DateTime'];
  readonly id: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly updates: ReadonlyArray<AslTrackingUpdate>;
};

export type AslTrackingUpdatesViewResult = {
  readonly __typename?: 'AslTrackingUpdatesViewResult';
  readonly edges: ReadonlyArray<AslTrackingUpdatesViewEdge>;
  readonly pageInfo: PageInfo;
};

export type AssetAuditEdge = {
  readonly __typename?: 'AssetAuditEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node: AssetAuditOutput;
};

export type AssetAuditFilter = {
  readonly id: Scalars['ID'];
  readonly entityType: Scalars['String'];
  readonly startDate?: InputMaybe<Scalars['Date']>;
  readonly endDate?: InputMaybe<Scalars['Date']>;
  readonly selectedDates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly selectedUsers?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly selectedCategories?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly offSet?: InputMaybe<Scalars['Int']>;
  readonly limit?: InputMaybe<Scalars['Int']>;
};

export type AssetAuditOutput = {
  readonly __typename?: 'AssetAuditOutput';
  readonly id: Scalars['ID'];
  readonly entityId: Scalars['ID'];
  readonly entityType: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAtTxId: Scalars['ID'];
  readonly auditCategory: Scalars['String'];
  readonly employeeId: Scalars['ID'];
  readonly createdByEmployee?: Maybe<EmployeeV2>;
  readonly isSystemUser?: Maybe<Scalars['Boolean']>;
  readonly events: ReadonlyArray<AuditEventOutput>;
};

export type AssetAuditPayload = {
  readonly __typename?: 'AssetAuditPayload';
  readonly edges: ReadonlyArray<AssetAuditEdge>;
  readonly pageInfo: PageInfo;
};

export type AssetDriver = {
  readonly __typename?: 'AssetDriver';
  readonly id: Scalars['ID'];
  readonly assetDriverTenders?: Maybe<ReadonlyArray<Maybe<AssetDriverTender>>>;
  /** @deprecated driverIdentityId is deprecated. Use code instead. */
  readonly driverIdentityId?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly middleName?: Maybe<Scalars['String']>;
  readonly lastName: Scalars['String'];
  readonly displayName: Scalars['String'];
  /** @deprecated nameSuffix is deprecated. Use nameSuffixTerm instead. */
  readonly nameSuffix?: Maybe<Scalars['String']>;
  readonly nameSuffixTerm?: Maybe<Scalars['String']>;
  /** @deprecated nickName is deprecated. Use nickname instead. */
  readonly nickName?: Maybe<Scalars['String']>;
  readonly nickname?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly mobileEmailId?: Maybe<Scalars['String']>;
  readonly tenderEligibilityTerm?: Maybe<Scalars['String']>;
  /** @deprecated managerName is deprecated. */
  readonly managerName?: Maybe<Scalars['String']>;
  /** @deprecated primaryPhoneCountryCd is deprecated. */
  readonly primaryPhoneCountryCd?: Maybe<Scalars['String']>;
  /** @deprecated primaryPhoneCountry is deprecated. */
  readonly primaryPhoneCountry?: Maybe<Scalars['String']>;
  /** @deprecated primaryPhoneNumber is deprecated. Use phoneNumber instead. */
  readonly primaryPhoneNumber?: Maybe<Scalars['String']>;
  /** @deprecated primaryEmail is deprecated. Use emailAddress instead. */
  readonly primaryEmail?: Maybe<Scalars['String']>;
  /** @deprecated isSmsEnabled is deprecated. */
  readonly isSmsEnabled?: Maybe<Scalars['Boolean']>;
  /** @deprecated preferredGenderPronoun is deprecated. Use preferredGenderPronounTerm instead. */
  readonly preferredGenderPronoun?: Maybe<Scalars['String']>;
  /** @deprecated preferredGenderPronounTerm is deprecated. */
  readonly preferredGenderPronounTerm?: Maybe<Scalars['String']>;
  /** @deprecated maritalStatus is deprecated. Use maritalStatusTerm instead. */
  readonly maritalStatus?: Maybe<Scalars['String']>;
  /** @deprecated maritalStatusTerm is deprecated. */
  readonly maritalStatusTerm?: Maybe<Scalars['String']>;
  /** @deprecated spouseName is deprecated. */
  readonly spouseName?: Maybe<Scalars['String']>;
  /** @deprecated children is deprecated. Use childrenTerm instead. */
  readonly children?: Maybe<Scalars['String']>;
  /** @deprecated childrenTerm is deprecated. */
  readonly childrenTerm?: Maybe<Scalars['String']>;
  /** @deprecated militaryReserveObligations is deprecated. Use militaryReserveObligationsTerm instead. */
  readonly militaryReserveObligations?: Maybe<Scalars['String']>;
  /** @deprecated militaryReserveObligationsTerm is deprecated. */
  readonly militaryReserveObligationsTerm?: Maybe<Scalars['String']>;
  /** @deprecated personalParkingSpace is deprecated. */
  readonly personalParkingSpace?: Maybe<Scalars['String']>;
  /** @deprecated personalParkingSpaceTerm is deprecated. Use personalParkingSpace instead. */
  readonly personalParkingSpaceTerm?: Maybe<Scalars['String']>;
  /** @deprecated dateOfBirth is deprecated. */
  readonly dateOfBirth?: Maybe<Scalars['Date']>;
  /** @deprecated professionType is deprecated. Use professionalTypeTerm instead. */
  readonly professionType?: Maybe<Scalars['String']>;
  readonly professionalTypeTerm?: Maybe<Scalars['String']>;
  /** @deprecated partnerName is deprecated. Use partner instead. */
  readonly partnerName?: Maybe<Scalars['String']>;
  /** @deprecated partnerNumber is deprecated. Use partner instead. */
  readonly partnerNumber?: Maybe<Scalars['String']>;
  readonly trainer?: Maybe<AssetDriver>;
  readonly partner?: Maybe<AssetDriver>;
  /** @deprecated hireDate is deprecated. Use driverHireDate instead. */
  readonly hireDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated hireDateV2 is deprecated. Use driverHireDate instead. */
  readonly hireDateV2?: Maybe<Scalars['Date']>;
  readonly driverHireDate?: Maybe<Scalars['Date']>;
  /** @deprecated years_service is deprecated. */
  readonly years_service?: Maybe<Scalars['String']>;
  readonly yearsOfService?: Maybe<Scalars['Int']>;
  readonly companyId?: Maybe<Scalars['ID']>;
  readonly companyCode?: Maybe<Scalars['String']>;
  readonly terminationDate?: Maybe<Scalars['Date']>;
  readonly commercialDriverClassTerm?: Maybe<Scalars['String']>;
  /** @deprecated classTerm is deprecated. Use commercialDriverClassTerm  instead */
  readonly classTerm?: Maybe<Scalars['String']>;
  readonly tenureStartDate?: Maybe<Scalars['Date']>;
  /**
   * DEPRECATED: Fleet is deprecated. Use createOrUpdateDriverFleetRelationship Mutation instead
   * @deprecated Fleet is deprecated. Use createOrUpdateDriverFleetRelationship Mutation instead
   */
  readonly fleet?: Maybe<Scalars['String']>;
  /** @deprecated division is deprecated. Use divisionTerm instead. */
  readonly division?: Maybe<Scalars['String']>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  readonly employmentTypeTerm?: Maybe<Scalars['String']>;
  readonly operationalTypeTerms?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly project?: Maybe<Scalars['String']>;
  /** @deprecated projectTerm is deprecated. Use project instead. */
  readonly projectTerm?: Maybe<Scalars['String']>;
  readonly program?: Maybe<Scalars['String']>;
  /** @deprecated programTerm is deprecated. Use program instead. */
  readonly programTerm?: Maybe<Scalars['String']>;
  /** @deprecated region is deprecated. */
  readonly region?: Maybe<Scalars['String']>;
  /** @deprecated domicile is deprecated. */
  readonly domicile?: Maybe<Scalars['String']>;
  /** @deprecated segment is deprecated. Use businessUnitTerm instead. */
  readonly segment?: Maybe<Scalars['String']>;
  /** @deprecated businessUnitTerm is deprecated. */
  readonly businessUnitTerm?: Maybe<Scalars['String']>;
  /** @deprecated milesRun is deprecated. */
  readonly milesRun?: Maybe<Scalars['Float']>;
  /** @deprecated status is deprecated. Use statusTerm instead. */
  readonly status?: Maybe<Scalars['String']>;
  readonly statusTerm?: Maybe<Scalars['String']>;
  /** @deprecated hosRule is deprecated. Use hosRuleTerm instead. */
  readonly hosRule?: Maybe<Scalars['String']>;
  readonly hosRuleTerm?: Maybe<Scalars['String']>;
  /** @deprecated hosDefaultTimeZoneTerm is deprecated. Use hosDefaultTimeZone instead. */
  readonly hosDefaultTimeZoneTerm?: Maybe<Scalars['String']>;
  readonly hosDefaultTimeZone?: Maybe<Scalars['String']>;
  readonly vacationDays?: Maybe<LeaveOutput>;
  readonly sickDays?: Maybe<LeaveOutput>;
  /** @deprecated permanentTractor is deprecated. */
  readonly permanentTractor?: Maybe<Scalars['String']>;
  /** @deprecated tractorType is deprecated. */
  readonly tractorType?: Maybe<Scalars['String']>;
  /** @deprecated tractorTypeTerm is deprecated. */
  readonly tractorTypeTerm?: Maybe<Scalars['String']>;
  /** @deprecated trailerType is deprecated. */
  readonly trailerType?: Maybe<Scalars['String']>;
  /** @deprecated trailerTypeTerm is deprecated. */
  readonly trailerTypeTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  /** This field is an externally-defined unique facility identifier exposed on the UI that can be searched throughout the TMS. */
  readonly terminal?: Maybe<FacilityV2>;
  /** @deprecated allottedVacationDays is deprecated. Use vacationDays instead. */
  readonly allottedVacationDays?: Maybe<Scalars['Float']>;
  /** @deprecated vacationDaysRemaining is deprecated. Use vacationDays instead. */
  readonly vacationDaysRemaining?: Maybe<Scalars['Float']>;
  /** @deprecated vacationResetDate is deprecated. Use vacationDays instead. */
  readonly vacationResetDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated allottedSickDays is deprecated. Use sickDays instead. */
  readonly allottedSickDays?: Maybe<Scalars['Float']>;
  /** @deprecated sickDaysRemaining is deprecated. Use sickDays instead. */
  readonly sickDaysRemaining?: Maybe<Scalars['Float']>;
  /** @deprecated sickResetDate is deprecated. Use sickDays instead. */
  readonly sickResetDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated assignedTractor is deprecated. */
  readonly assignedTractor?: Maybe<Scalars['String']>;
  /** @deprecated dutyStatus is deprecated. */
  readonly dutyStatus?: Maybe<Scalars['String']>;
  /** @deprecated remainingHours is deprecated. Use remainingWorkingHours instead. */
  readonly remainingHours?: Maybe<Scalars['Float']>;
  /** @deprecated remainingHoursV2 is deprecated. Use remainingWorkingHours instead. */
  readonly remainingHoursV2?: Maybe<Scalars['Int']>;
  /** @deprecated remainingWorkingHours is deprecated. */
  readonly remainingWorkingHours?: Maybe<Scalars['Int']>;
  /** @deprecated nextReset is deprecated. Use nextResetDate instead. */
  readonly nextReset?: Maybe<Scalars['DateTime']>;
  /** @deprecated nextResetDate is deprecated. */
  readonly nextResetDate?: Maybe<Scalars['Date']>;
  /** @deprecated calculatedDateTime is deprecated. Use calculatedNextAvailable instead. */
  readonly calculatedDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated calculatedCity is deprecated. Use calculatedNextAvailable instead. */
  readonly calculatedCity?: Maybe<Scalars['String']>;
  /** @deprecated calculatedState is deprecated. Use calculatedNextAvailable instead. */
  readonly calculatedState?: Maybe<Scalars['String']>;
  /** @deprecated preferredDateTime is deprecated. Use preferredNextAvailable instead. */
  readonly preferredDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated preferredCity is deprecated. Use preferredNextAvailable instead. */
  readonly preferredCity?: Maybe<Scalars['String']>;
  /** @deprecated preferredState is deprecated. Use preferredNextAvailable instead. */
  readonly preferredState?: Maybe<Scalars['String']>;
  /** @deprecated preferredNextAvailable is deprecated. Use driverPreferredNextAvailable instead. */
  readonly preferredNextAvailable?: Maybe<NextAvailableOutput>;
  readonly driverPreferredNextAvailable?: Maybe<PreferredNextAvailableOutput>;
  readonly calculatedNextAvailable?: Maybe<NextAvailableOutput>;
  /** @deprecated assignedTrailers is deprecated. */
  readonly assignedTrailers?: Maybe<ReadonlyArray<DriverAssignedTrailers>>;
  /**
   * DEPRECATED: permanentTrailers is deprecated.
   * @deprecated permanentTrailers is deprecated. Use permanentTrailerCodes instead.
   */
  readonly permanentTrailers?: Maybe<ReadonlyArray<DriverPermanentTrailers>>;
  /**
   * DEPRECATED: driverTimeOff is deprecated. Use driverTimeOffV2 instead
   * @deprecated driverTimeOff is deprecated. Use driverTimeOffV2 instead.
   */
  readonly driverTimeOff?: Maybe<ReadonlyArray<DriverTimeOff>>;
  /**
   * DEPRECATED: driverTimeOffV2 is deprecated. Use timeOff instead
   * @deprecated driverTimeOffV2 is deprecated. Use timeOff instead.
   */
  readonly driverTimeOffV2?: Maybe<ReadonlyArray<DriverTimeOffV2>>;
  /**
   * DEPRECATED: driverIdentifiers is deprecated. Use identifiers instead
   * @deprecated driverIdentifiers is deprecated. Use identifiers instead.
   */
  readonly driverIdentifiers?: Maybe<ReadonlyArray<DriverIdentifiers>>;
  /**
   * DEPRECATED: driverAddress is deprecated. Use addresses instead
   * @deprecated driverAddress is deprecated. Use addresses instead.
   */
  readonly driverAddress?: Maybe<ReadonlyArray<DriverAddress>>;
  /**
   * DEPRECATED: driverContacts is deprecated. Use contacts instead
   * @deprecated driverContacts is deprecated. Use contacts instead.
   */
  readonly driverContacts?: Maybe<ReadonlyArray<DriverContact>>;
  /**
   * DEPRECATED: driverCertificationsorPermits is deprecated.
   * @deprecated driverCertificationsorPermits is deprecated.
   */
  readonly driverCertificationsorPermits?: Maybe<ReadonlyArray<DriverCertificationsOrPermits>>;
  /**
   * DEPRECATED: driverCertificationsorPermitsV2 is deprecated. Use certificationsOrPermits instead
   * @deprecated driverCertificationsorPermitsV2 is deprecated. Use certificationsOrPermits instead.
   */
  readonly driverCertificationsorPermitsV2?: Maybe<ReadonlyArray<DriverCertificationsOrPermitsV2>>;
  /**
   * DEPRECATED: driverSchedule is deprecated.
   * @deprecated driverSchedule is deprecated.
   */
  readonly driverSchedule?: Maybe<ReadonlyArray<DriverSchedule>>;
  /**
   * DEPRECATED: driverScheduleV2 is deprecated. Use schedules instead
   * @deprecated driverScheduleV2 is deprecated. Use schedules instead.
   */
  readonly driverScheduleV2?: Maybe<ReadonlyArray<DriverScheduleV2>>;
  /**
   * DEPRECATED: driverAwards is deprecated. Use awards instead
   * @deprecated driverAwards is deprecated. Use awards instead.
   */
  readonly driverAwards?: Maybe<ReadonlyArray<DriverAward>>;
  /**
   * DEPRECATED: driverEquipment is deprecated.
   * @deprecated driverEquipment is deprecated.
   */
  readonly driverEquipment?: Maybe<ReadonlyArray<DriverEquipment>>;
  /**
   * DEPRECATED: driverEquipmentV2 is deprecated. Use equipment instead
   * @deprecated driverEquipmentV2 is deprecated. Use equipment instead.
   */
  readonly driverEquipmentV2?: Maybe<ReadonlyArray<DriverEquipmentV2>>;
  readonly driverFacility?: Maybe<ReadonlyArray<DriverFacilityPreferences>>;
  readonly driverCommodity?: Maybe<ReadonlyArray<DriverCommodityPreferences>>;
  readonly driverCustomer?: Maybe<ReadonlyArray<DriverCustomerPreferences>>;
  readonly driverTeam?: Maybe<ReadonlyArray<DriverTeamPreferences>>;
  readonly driverOperational?: Maybe<ReadonlyArray<DriverOperationalPreferences>>;
  readonly assetDriverOperational?: Maybe<ReadonlyArray<DriverOperationalPreferenceOutput>>;
  /** @deprecated driverGeography is deprecated. Use assetDriverGeography instead. */
  readonly driverGeography?: Maybe<ReadonlyArray<DriverGeographyPreferences>>;
  readonly assetDriverGeography?: Maybe<ReadonlyArray<AssetDriverGeographyPreferencesOutput>>;
  readonly driverCareerGoals?: Maybe<ReadonlyArray<DriverCareerGoalsPreferences>>;
  readonly driverPreferredLanes?: Maybe<ReadonlyArray<DriverPreferredLanes>>;
  readonly assetDriverPreferredLanes?: Maybe<ReadonlyArray<DriverPreferredLanesOutput>>;
  /**
   * DEPRECATED: permanentPowers is deprecated.
   * @deprecated permanentPowers is deprecated.
   */
  readonly permanentPowers?: Maybe<ReadonlyArray<DriverPermanentPowers>>;
  /**
   * DEPRECATED: permanentPowerUnit is deprecated. Use permanentPowerUnitCode instead
   * @deprecated permanentPowerUnit is deprecated. Use permanentPowerUnitCode instead.
   */
  readonly permanentPowerUnit?: Maybe<ReadonlyArray<DriverPermanentPowers>>;
  readonly driverPreferredRoutes?: Maybe<ReadonlyArray<DriverPreferredRoutes>>;
  readonly driverWeeklyTargets?: Maybe<ReadonlyArray<DriverWeeklyTarget>>;
  readonly driverTrainings?: Maybe<ReadonlyArray<DriverTraining>>;
  readonly driverFleets?: Maybe<ReadonlyArray<DriverFleet>>;
  readonly driverRepresentatives?: Maybe<ReadonlyArray<DriverRepresentatives>>;
  readonly fleets?: Maybe<ReadonlyArray<DriverFleet>>;
  readonly permanentTrailerCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentPowerUnitCode?: Maybe<Scalars['String']>;
  readonly permanentPower?: Maybe<AssetPowerOutput>;
  readonly permanentAssetTrailers?: Maybe<ReadonlyArray<AssetTrailers>>;
  /** @deprecated addresses is deprecated. Use assetDriverAddresses instead. */
  readonly addresses?: Maybe<ReadonlyArray<DriverAddressV2>>;
  /** @deprecated contacts is deprecated. Use assetDriverContacts instead. */
  readonly contacts?: Maybe<ReadonlyArray<DriverContactV2>>;
  /** @deprecated awards is deprecated. Use assetDriverAwards instead. */
  readonly awards?: Maybe<ReadonlyArray<DriverAwardV2>>;
  /** @deprecated equipment is deprecated. Use assetDriverEquipment instead. */
  readonly equipment?: Maybe<ReadonlyArray<DriverEquipmentV3>>;
  /** @deprecated certificationsOrPermits is deprecated. Use assetDriverCertificationsOrPermits instead. */
  readonly certificationsOrPermits?: Maybe<ReadonlyArray<DriverCertificationOrPermitV3>>;
  /** @deprecated schedules is deprecated. Use assetDriverSchedules instead. */
  readonly schedules?: Maybe<ReadonlyArray<DriverScheduleV3>>;
  /** @deprecated timeOff is deprecated. Use assetDriverTimeOff instead. */
  readonly timeOff?: Maybe<ReadonlyArray<DriverTimeOffV3>>;
  /** @deprecated identifiers is deprecated. Use assetDriverIdentifiers instead. */
  readonly identifiers?: Maybe<ReadonlyArray<DriverIdentifierV2>>;
  readonly assetDriverAddresses?: Maybe<ReadonlyArray<DriverAddressOutput>>;
  readonly assetDriverContacts?: Maybe<ReadonlyArray<DriverContactOutput>>;
  readonly assetDriverAwards?: Maybe<ReadonlyArray<DriverAwardOutput>>;
  readonly assetDriverEquipment?: Maybe<ReadonlyArray<DriverEquipmentOutput>>;
  readonly assetDriverCertificationsOrPermits?: Maybe<ReadonlyArray<DriverCertificationOrPermitOutput>>;
  readonly assetDriverSchedules?: Maybe<ReadonlyArray<DriverScheduleOutput>>;
  readonly assetDriverTimeOff?: Maybe<ReadonlyArray<DriverTimeOffOutput>>;
  readonly assetDriverIdentifiers?: Maybe<ReadonlyArray<DriverIdentifierOutput>>;
  readonly hoursOfService?: Maybe<AssetDriverHos>;
  readonly currentRoute?: Maybe<RouteOutput>;
  readonly nextRoute?: Maybe<RouteOutput>;
  readonly currentPower?: Maybe<AssetPowerOutput>;
  readonly preplans?: Maybe<ReadonlyArray<PreplanDriver>>;
};

export type AssetDriverAddressInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
  readonly county?: InputMaybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type AssetDriverAddressPayload = {
  readonly __typename?: 'AssetDriverAddressPayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  /** A collection of driver addresses associated with the driver. */
  readonly driverAddress?: Maybe<DriverAddressOutput>;
};

export type AssetDriverAwardInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly awardDate?: InputMaybe<Scalars['Date']>;
  readonly dateAwarded?: InputMaybe<Scalars['Date']>;
  readonly eligibilityDate?: InputMaybe<Scalars['Date']>;
  readonly driverId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type AssetDriverAwardPayload = {
  readonly __typename?: 'AssetDriverAwardPayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  /** A collection of Awards associated with the driver. */
  readonly driverAward?: Maybe<DriverAwardOutput>;
};

export type AssetDriverCareerGoalsPreferenceInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly driverId: Scalars['ID'];
  readonly preferenceTerm?: InputMaybe<Scalars['String']>;
  readonly positionTerm: Scalars['String'];
  readonly fleet?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly customer?: InputMaybe<Scalars['String']>;
  readonly reasonTerm?: InputMaybe<Scalars['String']>;
  readonly withinTheTimeframeTerm?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type AssetDriverCareerGoalsPreferencePayload = {
  readonly __typename?: 'AssetDriverCareerGoalsPreferencePayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  /** A collection of driver careerGoals preferences associated with the driver. */
  readonly driverCareerGoalsPreference?: Maybe<DriverCareerGoalsPreferenceOutput>;
};

export type AssetDriverCertificationOrPermitInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly certificationOrPermitId?: InputMaybe<Scalars['String']>;
  readonly privateCertificationOrPermitId?: InputMaybe<Scalars['String']>;
  readonly expirationDate: Scalars['Date'];
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type AssetDriverCertificationOrPermitPayload = {
  readonly __typename?: 'AssetDriverCertificationOrPermitPayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  /** A collection of driver certificationsOrPermits associated with the driver. */
  readonly driverCertificationOrPermit?: Maybe<DriverCertificationOrPermitOutput>;
};

export type AssetDriverCommodityPreferenceInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly driverId: Scalars['ID'];
  readonly preferenceTerm: Scalars['String'];
  readonly commodityTerm: Scalars['String'];
  readonly reasonTerm?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type AssetDriverCommodityPreferencePayload = {
  readonly __typename?: 'AssetDriverCommodityPreferencePayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  /** A collection of driver commodity preferences associated with the driver. */
  readonly driverCommodityPreference?: Maybe<DriverCommodityPreferenceOutput>;
};

export type AssetDriverContactInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly name: Scalars['String'];
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly instantMessenger?: InputMaybe<InstantMessengerInput>;
  readonly isMain?: InputMaybe<Scalars['Boolean']>;
  readonly isPayContactAllowedParty?: InputMaybe<Scalars['Boolean']>;
  readonly driverId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type AssetDriverContactPayload = {
  readonly __typename?: 'AssetDriverContactPayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  /** A collection of driver contacts associated with the driver. */
  readonly driverContact?: Maybe<DriverContactOutput>;
};

export type AssetDriverCustomerPreferenceInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly driverId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<Scalars['String']>;
  readonly preferenceTerm: Scalars['String'];
  readonly reasonTerm?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type AssetDriverCustomerPreferencePayload = {
  readonly __typename?: 'AssetDriverCustomerPreferencePayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  /** A collection of driver customer preferences associated with the driver. */
  readonly driverCustomerPreference?: Maybe<DriverCustomerPreferenceOutput>;
};

export type AssetDriverCustomerPreferences = {
  readonly __typename?: 'AssetDriverCustomerPreferences';
  readonly id: Scalars['ID'];
  readonly reason: Scalars['String'];
  readonly preferenceTerm: Scalars['String'];
  readonly name: Scalars['String'];
  readonly customerId?: Maybe<Scalars['ID']>;
};

export type AssetDriverEdge = {
  readonly __typename?: 'AssetDriverEdge';
  readonly node: AssetDriver;
  readonly cursor: Scalars['String'];
};

export type AssetDriverEquipmentInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId: Scalars['String'];
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly recoveredDate?: InputMaybe<Scalars['Date']>;
  readonly driverId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type AssetDriverEquipmentPayload = {
  readonly __typename?: 'AssetDriverEquipmentPayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  /** A collection of driver equipments associated with the driver. */
  readonly driverEquipment?: Maybe<DriverEquipmentOutput>;
};

export type AssetDriverFacilityPreferenceInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly driverId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly preferenceTerm: Scalars['String'];
  readonly reasonTerm?: InputMaybe<Scalars['String']>;
  readonly facilityId?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type AssetDriverFacilityPreferencePayload = {
  readonly __typename?: 'AssetDriverFacilityPreferencePayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  /** A collection of driver facility preferences associated with the driver. */
  readonly driverFacilityPreference?: Maybe<DriverFacilityPreferenceOutput>;
};

export type AssetDriverFacilityPreferences = {
  readonly __typename?: 'AssetDriverFacilityPreferences';
  readonly id: Scalars['ID'];
  readonly reason: Scalars['String'];
  readonly preference: Scalars['String'];
  readonly name: Scalars['String'];
  readonly facilityId?: Maybe<Scalars['ID']>;
};

export type AssetDriverGeographyPreferenceInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state: Scalars['String'];
  readonly region?: InputMaybe<Scalars['String']>;
  readonly directionTerm?: InputMaybe<Scalars['String']>;
  readonly preferenceTerm: Scalars['String'];
  readonly reasonTerm?: InputMaybe<Scalars['String']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly driverId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type AssetDriverGeographyPreferencePayload = {
  readonly __typename?: 'AssetDriverGeographyPreferencePayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  /** A collection of driver geography preferences associated with the driver. */
  readonly driverGeographyPreference?: Maybe<DriverGeographyPreferenceOutput>;
};

export type AssetDriverGeographyPreferences = {
  readonly __typename?: 'AssetDriverGeographyPreferences';
  readonly id: Scalars['ID'];
  readonly reason: Scalars['String'];
  readonly preference: Scalars['String'];
  readonly direction?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly states?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly compositeRegionKey?: Maybe<Scalars['String']>;
  /** @deprecated Use 'states' */
  readonly state?: Maybe<Scalars['String']>;
};

export type AssetDriverGeographyPreferencesOutput = {
  readonly __typename?: 'AssetDriverGeographyPreferencesOutput';
  readonly id: Scalars['ID'];
  readonly city?: Maybe<Scalars['String']>;
  /** @deprecated states is deprecated. Use geographyPreferenceStates instead. */
  readonly states: ReadonlyArray<Scalars['String']>;
  /** @deprecated region of type RegionOutput is deprecated. Use geographyPreferenceRegion of type GeographyPreferenceRegionOutput instead. */
  readonly region: RegionOutput;
  readonly geographyPreferenceStates?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly geographyPreferenceRegion?: Maybe<GeographyPreferenceRegionOutput>;
  readonly directionTerm?: Maybe<Scalars['String']>;
  readonly preferenceTerm: Scalars['String'];
  readonly reasonTerm?: Maybe<Scalars['String']>;
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type AssetDriverGeographyPreferencesOutputEdge = {
  readonly __typename?: 'AssetDriverGeographyPreferencesOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: AssetDriverGeographyPreferencesOutput;
};

export type AssetDriverGeographyPreferencesPayload = {
  readonly __typename?: 'AssetDriverGeographyPreferencesPayload';
  readonly edges: ReadonlyArray<AssetDriverGeographyPreferencesOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type AssetDriverHos = {
  readonly __typename?: 'AssetDriverHos';
  readonly driverId: Scalars['ID'];
  readonly driveTime?: Maybe<DriverHosTime>;
  readonly shiftTime?: Maybe<DriverHosTime>;
  readonly cycleTime?: Maybe<DriverHosTime>;
  readonly breakTime?: Maybe<DriverHosTime>;
};

export type AssetDriverHoursOfService = {
  readonly __typename?: 'AssetDriverHoursOfService';
  readonly id: Scalars['String'];
  readonly dayOfWeek: Scalars['String'];
  readonly startTime: Scalars['Int'];
  readonly endTime: Scalars['Int'];
};

export type AssetDriverIdentifierInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly value: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type AssetDriverIdentifierPayload = {
  readonly __typename?: 'AssetDriverIdentifierPayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  /** A collection of driver identifiers associated with the driver. */
  readonly driverIdentifier?: Maybe<DriverIdentifierOutput>;
};

export type AssetDriverInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly driverIdentityId?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly middleName?: InputMaybe<Scalars['String']>;
  readonly lastName: Scalars['String'];
  readonly displayName: Scalars['String'];
  readonly nameSuffix?: InputMaybe<Scalars['String']>;
  readonly nameSuffixTerm?: InputMaybe<Scalars['String']>;
  readonly nickName?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly nickname?: InputMaybe<Scalars['String']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly mobileEmailId?: InputMaybe<Scalars['String']>;
  readonly tenderEligibilityTerm?: InputMaybe<Scalars['String']>;
  readonly managerName?: InputMaybe<Scalars['String']>;
  readonly primaryPhoneCountryCd?: InputMaybe<Scalars['String']>;
  readonly primaryPhoneCountry?: InputMaybe<Scalars['String']>;
  readonly primaryPhoneNumber?: InputMaybe<Scalars['String']>;
  readonly primaryEmail?: InputMaybe<Scalars['String']>;
  readonly isSmsEnabled?: InputMaybe<Scalars['Boolean']>;
  readonly preferredGenderPronoun?: InputMaybe<Scalars['String']>;
  readonly preferredGenderPronounTerm?: InputMaybe<Scalars['String']>;
  readonly maritalStatus?: InputMaybe<Scalars['String']>;
  readonly maritalStatusTerm?: InputMaybe<Scalars['String']>;
  readonly spouseName?: InputMaybe<Scalars['String']>;
  readonly children?: InputMaybe<Scalars['String']>;
  readonly childrenTerm?: InputMaybe<Scalars['String']>;
  readonly militaryReserveObligations?: InputMaybe<Scalars['String']>;
  readonly militaryReserveObligationsTerm?: InputMaybe<Scalars['String']>;
  readonly personalParkingSpace?: InputMaybe<Scalars['String']>;
  readonly personalParkingSpaceTerm?: InputMaybe<Scalars['String']>;
  readonly dateOfBirth?: InputMaybe<Scalars['Date']>;
  readonly professionType?: InputMaybe<Scalars['String']>;
  readonly professionalTypeTerm?: InputMaybe<Scalars['String']>;
  readonly trainerCode?: InputMaybe<Scalars['String']>;
  readonly partnerCode?: InputMaybe<Scalars['String']>;
  readonly partnerName?: InputMaybe<Scalars['String']>;
  readonly partnerNumber?: InputMaybe<Scalars['String']>;
  readonly hireDate?: InputMaybe<Scalars['DateTime']>;
  readonly hireDateV2?: InputMaybe<Scalars['Date']>;
  readonly driverHireDate?: InputMaybe<Scalars['Date']>;
  readonly companyCode?: InputMaybe<Scalars['String']>;
  readonly terminationDate?: InputMaybe<Scalars['Date']>;
  readonly commercialDriverClassTerm?: InputMaybe<Scalars['String']>;
  readonly years_service?: InputMaybe<Scalars['String']>;
  readonly classTerm?: InputMaybe<Scalars['String']>;
  readonly tenureStartDate?: InputMaybe<Scalars['Date']>;
  /** DEPRECATED: Fleet is deprecated. Use createOrUpdateDriverFleetRelationship Mutation instead */
  readonly fleet?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly employmentTypeTerm?: InputMaybe<Scalars['String']>;
  readonly operationalTypeTerms?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly projectTerm?: InputMaybe<Scalars['String']>;
  readonly program?: InputMaybe<Scalars['String']>;
  readonly programTerm?: InputMaybe<Scalars['String']>;
  readonly region?: InputMaybe<Scalars['String']>;
  readonly domicile?: InputMaybe<Scalars['String']>;
  readonly segment?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly milesRun?: InputMaybe<Scalars['Float']>;
  readonly status?: InputMaybe<Scalars['String']>;
  readonly statusTerm?: InputMaybe<Scalars['String']>;
  readonly hosRule?: InputMaybe<Scalars['String']>;
  readonly hosRuleTerm?: InputMaybe<Scalars['String']>;
  readonly hosDefaultTimeZoneTerm?: InputMaybe<Scalars['String']>;
  readonly hosDefaultTimeZone?: InputMaybe<Scalars['String']>;
  readonly vacationDays?: InputMaybe<LeaveInput>;
  readonly sickDays?: InputMaybe<LeaveInput>;
  readonly permanentTractor?: InputMaybe<Scalars['String']>;
  readonly tractorType?: InputMaybe<Scalars['String']>;
  readonly tractorTypeTerm?: InputMaybe<Scalars['String']>;
  readonly trailerType?: InputMaybe<Scalars['String']>;
  readonly trailerTypeTerm?: InputMaybe<Scalars['String']>;
  /** This field describes an internally-defined UUID referencing the facility. */
  readonly terminalFacilityId?: InputMaybe<Scalars['ID']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly allottedVacationDays?: InputMaybe<Scalars['Float']>;
  readonly vacationDaysRemaining?: InputMaybe<Scalars['Float']>;
  readonly vacationResetDate?: InputMaybe<Scalars['DateTime']>;
  readonly allottedSickDays?: InputMaybe<Scalars['Float']>;
  readonly sickDaysRemaining?: InputMaybe<Scalars['Float']>;
  readonly sickResetDate?: InputMaybe<Scalars['DateTime']>;
  readonly assignedTractor?: InputMaybe<Scalars['String']>;
  readonly dutyStatus?: InputMaybe<Scalars['String']>;
  readonly remainingHours?: InputMaybe<Scalars['Float']>;
  readonly remainingHoursV2?: InputMaybe<Scalars['Int']>;
  readonly remainingWorkingHours?: InputMaybe<Scalars['Int']>;
  readonly nextReset?: InputMaybe<Scalars['DateTime']>;
  readonly nextResetDate?: InputMaybe<Scalars['Date']>;
  readonly preferredNextAvailable?: InputMaybe<NextAvailableInput>;
  readonly driverPreferredNextAvailable?: InputMaybe<PreferredNextAvailableInput>;
  readonly calculatedNextAvailable?: InputMaybe<NextAvailableInput>;
  readonly calculatedDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly calculatedCity?: InputMaybe<Scalars['String']>;
  readonly calculatedState?: InputMaybe<Scalars['String']>;
  readonly preferredDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly preferredCity?: InputMaybe<Scalars['String']>;
  readonly preferredState?: InputMaybe<Scalars['String']>;
  readonly permanentTrailerCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentPowerUnitCode?: InputMaybe<Scalars['String']>;
  readonly assignedTrailers?: InputMaybe<ReadonlyArray<DriverAssignedTrailersInput>>;
  /** A collection of permanentTrailers associated with the driver. */
  readonly permanentTrailers?: InputMaybe<ReadonlyArray<DriverPermanentTrailersInput>>;
  /** DEPRECATED: driverTimeOff is deprecated. */
  readonly driverTimeOff?: InputMaybe<ReadonlyArray<DriverTimeOffInput>>;
  /** A collection of timeOff associated with the driver. */
  readonly driverTimeOffV2?: InputMaybe<ReadonlyArray<DriverTimeOffInputV2>>;
  readonly driverIdentifiers?: InputMaybe<ReadonlyArray<DriverIdentifiersInput>>;
  readonly driverAddress?: InputMaybe<ReadonlyArray<DriverAddressInput>>;
  readonly driverContacts?: InputMaybe<ReadonlyArray<DriverContactInput>>;
  /** DEPRECATED: driverCertificationsorPermits is deprecated. */
  readonly driverCertificationsorPermits?: InputMaybe<ReadonlyArray<DriverCertificationsOrPermitsInput>>;
  /** A collection of certificationsorPermits associated with the driver. */
  readonly driverCertificationsorPermitsV2?: InputMaybe<ReadonlyArray<DriverCertificationsOrPermitsInputV2>>;
  /** DEPRECATED: driverSchedule is deprecated. */
  readonly driverSchedule?: InputMaybe<ReadonlyArray<DriverScheduleInput>>;
  /** A collection of driver schedule associated with the driver. */
  readonly driverScheduleV2?: InputMaybe<ReadonlyArray<DriverScheduleInputV2>>;
  readonly driverAwards?: InputMaybe<ReadonlyArray<DriverAwardInput>>;
  /** DEPRECATED: driverEquipment is deprecated. */
  readonly driverEquipment?: InputMaybe<ReadonlyArray<DriverEquipmentInput>>;
  /** A collection of equipment associated with the driver. */
  readonly driverEquipmentV2?: InputMaybe<ReadonlyArray<DriverEquipmentInputV2>>;
  readonly driverFacility?: InputMaybe<ReadonlyArray<DriverFacilityPreferencesInput>>;
  readonly driverCommodity?: InputMaybe<ReadonlyArray<DriverCommodityPreferencesInput>>;
  readonly driverCustomer?: InputMaybe<ReadonlyArray<DriverCustomerPreferencesInput>>;
  readonly driverTeam?: InputMaybe<ReadonlyArray<DriverTeamPreferencesInput>>;
  readonly driverOperational?: InputMaybe<ReadonlyArray<DriverOperationalPreferencesInput>>;
  readonly driverGeography?: InputMaybe<ReadonlyArray<DriverGeographyPreferencesInput>>;
  readonly driverCareerGoals?: InputMaybe<ReadonlyArray<DriverCareerGoalsPreferencesInput>>;
  readonly driverPreferredLanes?: InputMaybe<ReadonlyArray<DriverPreferredLanesInput>>;
  /** DEPRECATED: permanentPowers is deprecated. */
  readonly permanentPowers?: InputMaybe<ReadonlyArray<DriverPermanentPowerInput>>;
  /** References to the power records that want to associate this driver too. */
  readonly permanentPowerUnit?: InputMaybe<ReadonlyArray<DriverPermanentPowerInput>>;
  readonly driverPreferredRoutes?: InputMaybe<ReadonlyArray<DriverPreferredRoutesInput>>;
  readonly driverWeeklyTargets?: InputMaybe<ReadonlyArray<DriverWeeklyTargetInput>>;
  readonly driverTrainings?: InputMaybe<ReadonlyArray<DriverTrainingInput>>;
  readonly addresses?: InputMaybe<ReadonlyArray<DriverAddressInputV2>>;
  readonly contacts?: InputMaybe<ReadonlyArray<DriverContactInputV2>>;
  readonly awards?: InputMaybe<ReadonlyArray<DriverAwardInputV2>>;
  readonly equipment?: InputMaybe<ReadonlyArray<DriverEquipmentInputV3>>;
  /** A collection of Certifications Or Permits associated with the driver. */
  readonly certificationsOrPermits?: InputMaybe<ReadonlyArray<DriverCertificationOrPermitInputV3>>;
  readonly schedules?: InputMaybe<ReadonlyArray<DriverScheduleInputV3>>;
  /** A collection of TimeOff associated with the driver. */
  readonly timeOff?: InputMaybe<ReadonlyArray<DriverTimeOffInputV3>>;
  /** A collection of Identifiers associated with the driver. */
  readonly identifiers?: InputMaybe<ReadonlyArray<DriverIdentifierInputV2>>;
  readonly fleets?: InputMaybe<ReadonlyArray<DriverFleetRelationInput>>;
  readonly assetDriverRepresentatives?: InputMaybe<ReadonlyArray<DriverRepresentativeObjectInput>>;
  /** A collection of Address associated with the driver. */
  readonly assetDriverAddresses?: InputMaybe<ReadonlyArray<DriverAddressObjectInput>>;
  /** A collection of Contacts associated with the driver. */
  readonly assetDriverContacts?: InputMaybe<ReadonlyArray<DriverContactObjectInput>>;
  /** A collection of Awards associated with the driver. */
  readonly assetDriverAwards?: InputMaybe<ReadonlyArray<DriverAwardObjectInput>>;
  /** A collection of Equipments associated with the driver. */
  readonly assetDriverEquipment?: InputMaybe<ReadonlyArray<DriverEquipmentObjectInput>>;
  /** A collection of Certifications Or Permits associated with the driver. */
  readonly assetDriverCertificationsOrPermits?: InputMaybe<ReadonlyArray<DriverCertificationOrPermitObjectInput>>;
  /** A collection of Schedules associated with the driver. */
  readonly assetDriverSchedules?: InputMaybe<ReadonlyArray<DriverScheduleObjectInput>>;
  /** A collection of TimeOff associated with the driver. */
  readonly assetDriverTimeOff?: InputMaybe<ReadonlyArray<DriverTimeOffObjectInput>>;
  /** A collection of Identifiers associated with the driver. */
  readonly assetDriverIdentifiers?: InputMaybe<ReadonlyArray<DriverIdentifierObjectInput>>;
};

export type AssetDriverInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly code: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly middleName?: InputMaybe<Scalars['String']>;
  readonly lastName: Scalars['String'];
  readonly displayName: Scalars['String'];
  readonly nameSuffixTerm?: InputMaybe<Scalars['String']>;
  readonly nickname?: InputMaybe<Scalars['String']>;
  readonly phoneCountryCode?: InputMaybe<Scalars['String']>;
  readonly phoneNumber: Scalars['String'];
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly isSmsEnabled?: InputMaybe<Scalars['Boolean']>;
  readonly preferredGenderPronounTerm?: InputMaybe<Scalars['String']>;
  readonly maritalStatusTerm?: InputMaybe<Scalars['String']>;
  readonly spouseName?: InputMaybe<Scalars['String']>;
  readonly childrenTerm?: InputMaybe<Scalars['String']>;
  readonly militaryReserveObligationsTerm?: InputMaybe<Scalars['String']>;
  readonly personalParkingSpace?: InputMaybe<Scalars['String']>;
  readonly dateOfBirth?: InputMaybe<Scalars['Date']>;
  readonly professionalTypeTerm?: InputMaybe<Scalars['String']>;
  readonly trainerCode?: InputMaybe<Scalars['String']>;
  readonly partnerCode?: InputMaybe<Scalars['String']>;
  readonly hireDate?: InputMaybe<Scalars['Date']>;
  readonly companyCode?: InputMaybe<Scalars['String']>;
  readonly terminationDate?: InputMaybe<Scalars['Date']>;
  readonly commercialDriverClassTerm?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly program?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly statusTerm?: InputMaybe<Scalars['String']>;
  readonly hosRuleTerm?: InputMaybe<Scalars['String']>;
  readonly vacationDays?: InputMaybe<LeaveInput>;
  readonly sickDays?: InputMaybe<LeaveInput>;
  readonly dutyStatus?: InputMaybe<Scalars['String']>;
  /** This field describes the remaining hours that the driver can drive. */
  readonly remainingHours?: InputMaybe<Scalars['Int']>;
  readonly nextResetDate?: InputMaybe<Scalars['Date']>;
  readonly preferredNextAvailable?: InputMaybe<NextAvailableInput>;
  readonly calculatedNextAvailable?: InputMaybe<NextAvailableInput>;
  readonly permanentTrailerCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentPowerUnitCode?: InputMaybe<Scalars['String']>;
  /** A collection of Address associated with the driver. */
  readonly addresses: ReadonlyArray<DriverAddressInputV2>;
  /** A collection of Contacts associated with the driver. */
  readonly contacts?: InputMaybe<ReadonlyArray<DriverContactInputV2>>;
  /** A collection of Awards associated with the driver. */
  readonly awards?: InputMaybe<ReadonlyArray<DriverAwardInputV2>>;
  /** A collection of Equipments associated with the driver. */
  readonly equipment?: InputMaybe<ReadonlyArray<DriverEquipmentInputV3>>;
  /** A collection of Certifications Or Permits associated with the driver. */
  readonly certificationsOrPermits?: InputMaybe<ReadonlyArray<DriverCertificationOrPermitInputV3>>;
  /** A collection of Schedules associated with the driver. */
  readonly schedules?: InputMaybe<ReadonlyArray<DriverScheduleInputV3>>;
  /** A collection of TimeOff associated with the driver. */
  readonly timeOff?: InputMaybe<ReadonlyArray<DriverTimeOffInputV3>>;
  /** A collection of Identifiers associated with the driver. */
  readonly identifiers?: InputMaybe<ReadonlyArray<DriverIdentifierInputV2>>;
};

export type AssetDriverOperationalPreferenceInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly subTypeTerms?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly preferenceTerm: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly qualifier?: InputMaybe<Scalars['String']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
};

export type AssetDriverOperationalPreferencePayload = {
  readonly __typename?: 'AssetDriverOperationalPreferencePayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  /** A collection of driver Operational preferences associated with the driver. */
  readonly driverOperationalPreference?: Maybe<DriverOperationalPreferenceOutput>;
};

export type AssetDriverPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly code: Scalars['String'];
  readonly firstName?: InputMaybe<Scalars['String']>;
  readonly middleName?: InputMaybe<Scalars['String']>;
  readonly lastName?: InputMaybe<Scalars['String']>;
  readonly displayName?: InputMaybe<Scalars['String']>;
  readonly nameSuffixTerm?: InputMaybe<Scalars['String']>;
  readonly nickname?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly professionalTypeTerm?: InputMaybe<Scalars['String']>;
  readonly trainerCode?: InputMaybe<Scalars['String']>;
  readonly partnerCode?: InputMaybe<Scalars['String']>;
  readonly driverHireDate?: InputMaybe<Scalars['Date']>;
  readonly companyCode?: InputMaybe<Scalars['String']>;
  readonly terminationDate?: InputMaybe<Scalars['Date']>;
  readonly commercialDriverClassTerm?: InputMaybe<Scalars['String']>;
  readonly tenureStartDate?: InputMaybe<Scalars['Date']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly employmentTypeTerm?: InputMaybe<Scalars['String']>;
  readonly operationalTypeTerms?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly program?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly statusTerm?: InputMaybe<Scalars['String']>;
  readonly hosRuleTerm?: InputMaybe<Scalars['String']>;
  readonly hosDefaultTimeZoneTerm?: InputMaybe<Scalars['String']>;
  readonly hosDefaultTimeZone?: InputMaybe<Scalars['String']>;
  /** This field describes an internally-defined UUID referencing the facility. */
  readonly terminalFacilityId?: InputMaybe<Scalars['ID']>;
  readonly vacationDays?: InputMaybe<LeaveInput>;
  readonly sickDays?: InputMaybe<LeaveInput>;
  readonly dutyStatus?: InputMaybe<Scalars['String']>;
  /** This field describes the remaining hours that the driver can drive. */
  readonly remainingWorkingHours?: InputMaybe<Scalars['Int']>;
  readonly nextResetDate?: InputMaybe<Scalars['Date']>;
  readonly tenderEligibilityTerm?: InputMaybe<Scalars['String']>;
  readonly preferredNextAvailable?: InputMaybe<NextAvailableInput>;
  readonly driverPreferredNextAvailable?: InputMaybe<PreferredNextAvailableInput>;
  readonly calculatedNextAvailable?: InputMaybe<NextAvailableInput>;
  readonly permanentPowerUnitCode?: InputMaybe<Scalars['String']>;
  readonly permanentTrailerCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A collection of Addresses associated with the driver. */
  readonly assetDriverPatchAddresses?: InputMaybe<ReadonlyArray<DriverAddressPatchInput>>;
  /** A collection of Contacts associated with the driver. */
  readonly assetDriverPatchContacts?: InputMaybe<ReadonlyArray<DriverContactPatchInput>>;
  /** A collection of Awards associated with the driver. */
  readonly assetDriverPatchAwards?: InputMaybe<ReadonlyArray<DriverAwardPatchInput>>;
  /** A collection of Equipments associated with the driver. */
  readonly assetDriverPatchEquipment?: InputMaybe<ReadonlyArray<DriverEquipmentPatchInput>>;
  /** A collection of Certifications Or Permits associated with the driver. */
  readonly assetDriverPatchCertificationsOrPermits?: InputMaybe<ReadonlyArray<DriverCertificationOrPermitPatchInput>>;
  /** A collection of Schedules associated with the driver. */
  readonly assetDriverPatchSchedules?: InputMaybe<ReadonlyArray<DriverSchedulePatchInput>>;
  /** A collection of TimeOff associated with the driver. */
  readonly assetDriverPatchTimeOff?: InputMaybe<ReadonlyArray<DriverTimeOffPatchInput>>;
  /** A collection of Identifiers associated with the driver. */
  readonly assetDriverPatchIdentifiers?: InputMaybe<ReadonlyArray<DriverIdentifierPatchInput>>;
  /** A collection of Fleets associated with the driver. */
  readonly assetDriverPatchFleets?: InputMaybe<ReadonlyArray<DriverFleetPatchInput>>;
  /** A collection of Reps associated with the driver. */
  readonly assetDriverPatchRepresentatives?: InputMaybe<ReadonlyArray<DriverRepresentativePatchInput>>;
};

export type AssetDriverPreferredLanes = {
  readonly __typename?: 'AssetDriverPreferredLanes';
  readonly id: Scalars['ID'];
  readonly reason: Scalars['String'];
  readonly preference: Scalars['String'];
  readonly originCityState?: Maybe<Scalars['String']>;
  readonly destinationCityState?: Maybe<Scalars['String']>;
  readonly originCompositeRegionKey?: Maybe<Scalars['String']>;
  readonly destinationCompositeRegionKey?: Maybe<Scalars['String']>;
};

export type AssetDriverPreferredLanesInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly originType?: InputMaybe<Scalars['String']>;
  readonly originCityState?: InputMaybe<Scalars['String']>;
  readonly preferenceOriginRegion?: InputMaybe<PreferredLanesRegionInput>;
  readonly destinationType?: InputMaybe<Scalars['String']>;
  readonly destinationCityState?: InputMaybe<Scalars['String']>;
  readonly preferenceDestinationRegion?: InputMaybe<PreferredLanesRegionInput>;
  readonly preference: Scalars['String'];
  readonly reason?: InputMaybe<Scalars['String']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AssetDriverRoutingRequestPayload = {
  readonly __typename?: 'AssetDriverRoutingRequestPayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  /** A collection of driver driverRoutingRequests associated with the driver. */
  readonly driverRoutingRequests?: Maybe<DriverRoutingRequestOutput>;
};

export type AssetDriverSchedule = {
  readonly __typename?: 'AssetDriverSchedule';
  readonly id: Scalars['ID'];
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly hours?: Maybe<AssetDriverHoursOfService>;
  /** @deprecated Procurement only ingests active schedules */
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type AssetDriverScheduleInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly hours: HoursInput;
  readonly driverId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type AssetDriverSchedulePayload = {
  readonly __typename?: 'AssetDriverSchedulePayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  /** A collection of driver schedule associated with the driver. */
  readonly driverSchedule?: Maybe<ReadonlyArray<DriverScheduleOutput>>;
};

export type AssetDriverTeamPreferenceInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly preferenceTerm: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly driverId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type AssetDriverTeamPreferencePayload = {
  readonly __typename?: 'AssetDriverTeamPreferencePayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  /** A collection of driver team preferences associated with the driver. */
  readonly driverTeamPreference?: Maybe<DriverTeamPreferenceOutput>;
};

export type AssetDriverTender = {
  readonly __typename?: 'AssetDriverTender';
  readonly id: Scalars['ID'];
  readonly driverId?: Maybe<Scalars['ID']>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly driverCapacityId?: Maybe<Scalars['ID']>;
  readonly expiresAt?: Maybe<Scalars['DateTime']>;
  readonly isSentToDriver?: Maybe<Scalars['Boolean']>;
  readonly driverTenderStatusId?: Maybe<Scalars['ID']>;
  readonly driverTenderStatus?: Maybe<Scalars['String']>;
  readonly response?: Maybe<Scalars['String']>;
  readonly respondedAt?: Maybe<Scalars['DateTime']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Scalars['ID']>;
  readonly lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['ID']>;
  readonly driver2Id?: Maybe<Scalars['ID']>;
  readonly validationMessages?: Maybe<ReadonlyArray<Maybe<AssetDriverTenderValidationMessage>>>;
  readonly assetDriver?: Maybe<AssetDriver>;
  readonly assetDriver2?: Maybe<AssetDriver>;
  readonly routeV2?: Maybe<LoadRouteV2>;
  readonly driverCapacity?: Maybe<TruckEntry>;
};

export type AssetDriverTenderResponseInput = {
  readonly driverTenderId: Scalars['ID'];
  readonly respondedByUserId: Scalars['ID'];
};

export type AssetDriverTenderValidationMessage = {
  readonly __typename?: 'AssetDriverTenderValidationMessage';
  readonly id: Scalars['ID'];
  readonly message?: Maybe<Scalars['String']>;
};

export type AssetDriverTimeOff = {
  readonly __typename?: 'AssetDriverTimeOff';
  readonly id: Scalars['ID'];
  readonly startDate: Scalars['DateTime'];
  readonly endDate: Scalars['DateTime'];
  /** @deprecated Procurement only ingests active time off */
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type AssetDriverTimeOffInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly location?: InputMaybe<TimeOffLocationInput>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type AssetDriverTimeOffPayload = {
  readonly __typename?: 'AssetDriverTimeOffPayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  /** A collection of driver timeOffs associated with the driver. */
  readonly driverTimeOff?: Maybe<DriverTimeOffOutput>;
};

export type AssetDriverV2 = {
  readonly __typename?: 'AssetDriverV2';
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly middleName?: Maybe<Scalars['String']>;
  readonly lastName: Scalars['String'];
  readonly displayName: Scalars['String'];
  readonly nameSuffixTerm?: Maybe<Scalars['String']>;
  readonly nickname?: Maybe<Scalars['String']>;
  readonly phoneCountryCode?: Maybe<Scalars['String']>;
  readonly phoneNumber: Scalars['String'];
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly isSmsEnabled?: Maybe<Scalars['Boolean']>;
  readonly preferredGenderPronounTerm?: Maybe<Scalars['String']>;
  readonly maritalStatusTerm?: Maybe<Scalars['String']>;
  readonly spouseName?: Maybe<Scalars['String']>;
  readonly childrenTerm?: Maybe<Scalars['String']>;
  readonly militaryReserveObligationsTerm?: Maybe<Scalars['String']>;
  readonly personalParkingSpace?: Maybe<Scalars['String']>;
  readonly dateOfBirth?: Maybe<Scalars['Date']>;
  readonly professionalTypeTerm?: Maybe<Scalars['String']>;
  /** The externally-defined code to reference the driver`s trainer object in Mastermind. */
  readonly trainer?: Maybe<AssetDriverV2>;
  /** The externally-defined code to reference the driver`s partner object in Mastermind. */
  readonly partner?: Maybe<AssetDriverV2>;
  readonly hireDate?: Maybe<Scalars['Date']>;
  readonly yearsOfService?: Maybe<Scalars['Int']>;
  readonly companyCode?: Maybe<Scalars['String']>;
  readonly terminationDate?: Maybe<Scalars['Date']>;
  readonly commercialDriverClassTerm?: Maybe<Scalars['String']>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  readonly program?: Maybe<Scalars['String']>;
  /** @deprecated businessUnitTerm is deprecated. */
  readonly businessUnitTerm?: Maybe<Scalars['String']>;
  readonly statusTerm?: Maybe<Scalars['String']>;
  readonly hosRuleTerm?: Maybe<Scalars['String']>;
  readonly vacationDays?: Maybe<LeaveOutput>;
  readonly sickDays?: Maybe<LeaveOutput>;
  /** @deprecated dutyStatus is deprecated. */
  readonly dutyStatus?: Maybe<Scalars['String']>;
  /**
   * This field describes the remaining hours that the driver can drive.
   * @deprecated remainingHours is deprecated.
   */
  readonly remainingHours?: Maybe<Scalars['Int']>;
  /** @deprecated nextResetDate is deprecated. */
  readonly nextResetDate?: Maybe<Scalars['Date']>;
  readonly preferredNextAvailable?: Maybe<NextAvailableOutput>;
  readonly calculatedNextAvailable?: Maybe<NextAvailableOutput>;
  readonly permanentTrailerCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentPowerUnitCode?: Maybe<Scalars['String']>;
  /** A collection of Address associated with the driver. */
  readonly addresses: ReadonlyArray<DriverAddressV2>;
  /** A collection of Contacts associated with the driver. */
  readonly contacts?: Maybe<ReadonlyArray<DriverContactV2>>;
  /** A collection of Awards associated with the driver. */
  readonly awards?: Maybe<ReadonlyArray<DriverAwardV2>>;
  /** A collection of Equipments associated with the driver. */
  readonly equipment?: Maybe<ReadonlyArray<DriverEquipmentV3>>;
  /** A collection of Certifications Or Permits associated with the driver. */
  readonly certificationsOrPermits?: Maybe<ReadonlyArray<DriverCertificationOrPermitV3>>;
  /** A collection of Schedules associated with the driver. */
  readonly schedules?: Maybe<ReadonlyArray<DriverScheduleV3>>;
  /** A collection of TimeOff associated with the driver. */
  readonly timeOff?: Maybe<ReadonlyArray<DriverTimeOffV3>>;
  /** A collection of Identifiers associated with the driver. */
  readonly identifiers?: Maybe<ReadonlyArray<DriverIdentifierV2>>;
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type AssetPowerConnection = {
  readonly __typename?: 'AssetPowerConnection';
  readonly edges: ReadonlyArray<AssetPowerOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type AssetPowerEquipmentOutput = {
  readonly __typename?: 'AssetPowerEquipmentOutput';
  readonly id: Scalars['ID'];
  /** @deprecated equipmentCd is deprecated. Use typeTerm instead. */
  readonly equipmentCd: Scalars['String'];
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count?: Maybe<Scalars['Int']>;
  readonly assetId: Scalars['String'];
  /** @deprecated condition is deprecated. Use conditionTerm instead. */
  readonly condition: Scalars['String'];
  readonly conditionTerm: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly powerId?: Maybe<Scalars['String']>;
  readonly issueDate?: Maybe<Scalars['DateTime']>;
  readonly returnDate?: Maybe<Scalars['DateTime']>;
};

export type AssetPowerEquipmentOutputV2 = {
  readonly __typename?: 'AssetPowerEquipmentOutputV2';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId: Scalars['String'];
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly returnDate?: Maybe<Scalars['Date']>;
  readonly powerId?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type AssetPowerEquipmentPayload = {
  readonly __typename?: 'AssetPowerEquipmentPayload';
  readonly errors?: Maybe<ReadonlyArray<PowerTypeError>>;
  /** A collection of equipments associated with the power. */
  readonly powerEquipment?: Maybe<PowerEquipmentOutput>;
};

export type AssetPowerFleetInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly carrierId: Scalars['ID'];
  readonly carrierName?: InputMaybe<Scalars['ID']>;
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly powerId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type AssetPowerFleetPayload = {
  readonly __typename?: 'AssetPowerFleetPayload';
  readonly errors?: Maybe<ReadonlyArray<PowerTypeError>>;
  /** A collection of fleets associated with the power. */
  readonly powerFleet?: Maybe<PowerFleet>;
};

export type AssetPowerIdentifierOutput = {
  readonly __typename?: 'AssetPowerIdentifierOutput';
  readonly id: Scalars['ID'];
  /** @deprecated identifierType is deprecated. Use typeTerm instead. */
  readonly identifierType: Scalars['String'];
  /** @deprecated identifierNumber is deprecated. Use value instead. */
  readonly identifierNumber: Scalars['String'];
  readonly state: Scalars['String'];
  /** @deprecated countryCd is deprecated. Use country instead. */
  readonly countryCd: Scalars['String'];
  readonly expirationDt: Scalars['DateTime'];
  readonly isActive: Scalars['Boolean'];
  readonly powerId?: Maybe<Scalars['String']>;
  /** @deprecated expirationDtV1 is deprecated. Use expirationDate instead. */
  readonly expirationDtV1: Scalars['String'];
};

export type AssetPowerIdentifierOutputV2 = {
  readonly __typename?: 'AssetPowerIdentifierOutputV2';
  readonly id: Scalars['ID'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly powerId?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
};

export type AssetPowerIdentifierOutputV3 = {
  readonly __typename?: 'AssetPowerIdentifierOutputV3';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly value: Scalars['String'];
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly powerId?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type AssetPowerIdentifierPayload = {
  readonly __typename?: 'AssetPowerIdentifierPayload';
  readonly errors?: Maybe<ReadonlyArray<PowerTypeError>>;
  /** A collection of identifiers associated with the power. */
  readonly powerIdentifier?: Maybe<PowerIdentifierOutput>;
};

export type AssetPowerLegalToRunOutPut = {
  readonly __typename?: 'AssetPowerLegalToRunOutPut';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly powerId?: Maybe<Scalars['String']>;
};

export type AssetPowerMaintenanceOutputV2 = {
  readonly __typename?: 'AssetPowerMaintenanceOutputV2';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly severityTerm: Scalars['String'];
  readonly isPlanned?: Maybe<Scalars['Boolean']>;
  /** @deprecated location is deprecated. Use maintenanceLocation instead. */
  readonly location?: Maybe<MaintenanceLocationOutput>;
  readonly maintenanceLocation?: Maybe<PowerMaintenanceLocationOutput>;
  readonly estimatedMaintenanceTime?: Maybe<Scalars['Int']>;
  readonly actual?: Maybe<DateTimeEntryOutput>;
  readonly expected?: Maybe<DateTimeEntryOutput>;
  readonly workPerformedDetail?: Maybe<Scalars['String']>;
  readonly powerId?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type AssetPowerMaintenancePayload = {
  readonly __typename?: 'AssetPowerMaintenancePayload';
  readonly errors?: Maybe<ReadonlyArray<PowerTypeError>>;
  /** A collection of maintenance associated with the power. */
  readonly powerMaintenance?: Maybe<PowerMaintenanceOutput>;
};

export type AssetPowerOutput = {
  readonly __typename?: 'AssetPowerOutput';
  readonly id: Scalars['ID'];
  readonly displayName?: Maybe<Scalars['String']>;
  /** @deprecated make is deprecated. Use makeTerm instead. */
  readonly make: Scalars['String'];
  readonly makeTerm?: Maybe<Scalars['String']>;
  /** @deprecated type is deprecated. Use typeTerm instead. */
  readonly type: Scalars['String'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly year?: Maybe<Scalars['Int']>;
  /** @deprecated yearV2 is deprecated. Use year instead. */
  readonly yearV2?: Maybe<Scalars['Int']>;
  /** @deprecated model is deprecated. Use modelTerm instead. */
  readonly model: Scalars['String'];
  readonly modelTerm?: Maybe<Scalars['String']>;
  /**
   * DEPRECATED: legalToRun is deprecated. Use legalToRunTerm instead.
   * @deprecated legalToRun is deprecated. Use legalToRunTerm instead.
   */
  readonly legalToRun?: Maybe<ReadonlyArray<AssetPowerLegalToRunOutPut>>;
  /**
   * This field describes the list of countries details where the trailer is legal to run(Ex. Dry Van , CAN)
   * @deprecated legalToRunTerm is deprecated.
   */
  readonly legalToRunTerm?: Maybe<ReadonlyArray<AssetPowerLegalToRunOutPut>>;
  /** @deprecated equipmentDetails is deprecated. Use equipment instead. */
  readonly equipmentDetails?: Maybe<ReadonlyArray<AssetPowerEquipmentOutput>>;
  /** @deprecated identifiers is deprecated. Use assetPowerIdentifiers instead. */
  readonly identifiers?: Maybe<ReadonlyArray<AssetPowerIdentifierOutput>>;
  /** @deprecated identifiersV2 is deprecated. Use identifiers instead. */
  readonly identifiersV2?: Maybe<ReadonlyArray<AssetPowerIdentifierOutputV2>>;
  /**
   * DEPRECATED: permanentDriver is deprecated. Use permanentDrivers instead.
   * @deprecated permanentDriver is deprecated. Use permanentDrivers instead.
   */
  readonly permanentDriver?: Maybe<ReadonlyArray<AssetPowerPermanentDriverOutput>>;
  /**
   * A collection of permanent driver associated with the power.
   * @deprecated permanentDrivers is deprecated. Use permanentDriverCodes instead.
   */
  readonly permanentDrivers?: Maybe<ReadonlyArray<AssetPowerPermanentDriverOutput>>;
  /**
   * DEPRECATED: permanentTrailer is deprecated. Use permanentTrailers instead.
   * @deprecated permanentTrailer is deprecated. Use permanentTrailers instead.
   */
  readonly permanentTrailer?: Maybe<ReadonlyArray<AssetPowerPermanentTrailerOutput>>;
  /**
   * A collection of permanent trailer associated with the power.
   * @deprecated permanentTrailers is deprecated. Use permanentTrailerCodes instead.
   */
  readonly permanentTrailers?: Maybe<ReadonlyArray<AssetPowerPermanentTrailerOutput>>;
  /** @deprecated maintenance is deprecated. Use assetPowerMaintenance instead. */
  readonly maintenance?: Maybe<ReadonlyArray<AssetPowerMaintenanceOutputV2>>;
  readonly isCARBCompliant?: Maybe<Scalars['Boolean']>;
  /** @deprecated isActive is deprecated. */
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  /** @deprecated powerDevice is deprecated. Use trackingDeviceTerm instead. */
  readonly powerDevice: Scalars['String'];
  /** @deprecated trackingDeviceTerm is deprecated. Use trackingDevice instead. */
  readonly trackingDeviceTerm?: Maybe<Scalars['String']>;
  readonly trackingDevice?: Maybe<TrackingDeviceOutput>;
  /** @deprecated powerModel is deprecated. Use trackingModelTerm instead. */
  readonly powerModel: Scalars['String'];
  /** @deprecated trackingModelTerm is deprecated. Use trackingDevice instead. */
  readonly trackingModelTerm?: Maybe<Scalars['String']>;
  /** @deprecated serialNumber is deprecated. Use trackingDevice instead. */
  readonly serialNumber?: Maybe<Scalars['String']>;
  /** @deprecated powerNumber is deprecated. Use code instead. */
  readonly powerNumber: Scalars['String'];
  readonly code: Scalars['String'];
  /** @deprecated owner is deprecated. Use ownerTerm instead. */
  readonly owner: Scalars['String'];
  /** @deprecated ownerTerm is deprecated. */
  readonly ownerTerm?: Maybe<Scalars['String']>;
  /** @deprecated class is deprecated. Use classTerm instead. */
  readonly class: Scalars['String'];
  readonly classTerm?: Maybe<Scalars['String']>;
  /** @deprecated fleetName is deprecated. */
  readonly fleetName?: Maybe<Scalars['String']>;
  /** @deprecated segment is deprecated. Use businessUnitTerm instead. */
  readonly segment: Scalars['String'];
  /** @deprecated businessUnitTerm is deprecated. */
  readonly businessUnitTerm?: Maybe<Scalars['String']>;
  /** @deprecated division is deprecated. Use divisionTerm instead. */
  readonly division: Scalars['String'];
  readonly divisionTerm?: Maybe<Scalars['String']>;
  /** @deprecated odomoter is deprecated. Use odometerMiles instead. */
  readonly odomoter?: Maybe<Scalars['Float']>;
  readonly odometerMiles?: Maybe<Scalars['Float']>;
  readonly exteriorColor?: Maybe<Scalars['String']>;
  readonly interiorColor?: Maybe<Scalars['String']>;
  /** @deprecated sleeperType is deprecated. Use sleeperTypeTerm instead. */
  readonly sleeperType: Scalars['String'];
  readonly sleeperTypeTerm?: Maybe<Scalars['String']>;
  /** @deprecated axleConfiguration is deprecated. Use axleConfigurationTerm instead. */
  readonly axleConfiguration: Scalars['String'];
  readonly axleConfigurationTerm?: Maybe<Scalars['String']>;
  /** @deprecated suspension is deprecated. Use suspensionTerm instead. */
  readonly suspension: Scalars['String'];
  readonly suspensionTerm?: Maybe<Scalars['String']>;
  /** @deprecated sleeperSize is deprecated. Use sleeperSizeDimensionsTerm instead. */
  readonly sleeperSize: Scalars['Float'];
  /** @deprecated sleeperSizeTerm is deprecated. Use sleeperSizeDimensionsTerm instead. */
  readonly sleeperSizeTerm: Scalars['Float'];
  readonly sleeperSizeDimensionsTerm?: Maybe<Scalars['String']>;
  /** @deprecated weight is deprecated. Use powerWeight instead. */
  readonly weight?: Maybe<Scalars['String']>;
  readonly powerWeight?: Maybe<UnitOfWeightOutputV2>;
  /** @deprecated engineMake is deprecated. Use engineMakeTerm instead. */
  readonly engineMake?: Maybe<Scalars['String']>;
  readonly engineMakeTerm?: Maybe<Scalars['String']>;
  /** @deprecated engineModel is deprecated. Use engineModelTerm instead. */
  readonly engineModel?: Maybe<Scalars['String']>;
  readonly engineModelTerm?: Maybe<Scalars['String']>;
  /** @deprecated engineHP is deprecated. Use powerEngineHP instead. */
  readonly engineHP?: Maybe<Scalars['Float']>;
  /** @deprecated engineHPV2 is deprecated. Use powerEngineHP instead. */
  readonly engineHPV2?: Maybe<Scalars['String']>;
  readonly powerEngineHP?: Maybe<Scalars['String']>;
  /** @deprecated engineBrake is deprecated. Use engineBrakeTerm instead. */
  readonly engineBrake: Scalars['String'];
  /** @deprecated engineBrakeTerm is deprecated. Use isEngineBrakeAvailable instead. */
  readonly engineBrakeTerm?: Maybe<Scalars['String']>;
  readonly isEngineBrakeAvailable?: Maybe<Scalars['Boolean']>;
  /** @deprecated transmissionMake is deprecated. Use transmissionMakeTerm instead. */
  readonly transmissionMake?: Maybe<Scalars['String']>;
  readonly transmissionMakeTerm?: Maybe<Scalars['String']>;
  /** @deprecated transmissionModel is deprecated. Use transmissionModelTerm instead. */
  readonly transmissionModel?: Maybe<Scalars['String']>;
  readonly transmissionModelTerm?: Maybe<Scalars['String']>;
  /** @deprecated transmissionSpeed is deprecated. Use powerTransmissionSpeed instead. */
  readonly transmissionSpeed?: Maybe<Scalars['Float']>;
  /** @deprecated transmissionSpeed is deprecated. Use powerTransmissionSpeed instead. */
  readonly transmissionSpeedV2?: Maybe<Scalars['Int']>;
  readonly powerTransmissionSpeed?: Maybe<Scalars['Int']>;
  /** @deprecated readEndRatio is deprecated. Use rearEndRatio instead. */
  readonly readEndRatio?: Maybe<Scalars['Float']>;
  readonly rearEndRatio?: Maybe<Scalars['Float']>;
  /** @deprecated wheelBase is deprecated. Use powerWheelBase instead. */
  readonly wheelBase?: Maybe<Scalars['Float']>;
  /** @deprecated wheelBaseV2 is deprecated. Use powerWheelBase instead. */
  readonly wheelBaseV2?: Maybe<UnitOfLengthOutputV3>;
  readonly powerWheelBase?: Maybe<UnitOfLengthOutputV3>;
  /** @deprecated tankCapacity1 is deprecated. Use powerTankCapacity1 instead. */
  readonly tankCapacity1?: Maybe<Scalars['Float']>;
  /** @deprecated tankCapacity1V2 is deprecated. Use powerTankCapacity1 */
  readonly tankCapacity1V2?: Maybe<UnitOfCapacityOutput>;
  readonly powerTankCapacity1?: Maybe<UnitOfCapacityOutput>;
  /** @deprecated tankCapacity2 is deprecated. Use powerTankCapacity2 instead. */
  readonly tankCapacity2?: Maybe<Scalars['Float']>;
  /** @deprecated tankCapacity2 is deprecated. Use powerTankCapacity2 instead. */
  readonly tankCapacity2V2?: Maybe<UnitOfCapacityOutput>;
  readonly powerTankCapacity2?: Maybe<UnitOfCapacityOutput>;
  /** @deprecated wheel5 is deprecated. Use fifthWheel instead. */
  readonly wheel5: Scalars['String'];
  /** @deprecated fifthWheel is deprecated. Use fifthWheelTerm instead. */
  readonly fifthWheel?: Maybe<Scalars['String']>;
  readonly fifthWheelTerm?: Maybe<Scalars['String']>;
  /** @deprecated note is deprecated. Use notes instead. */
  readonly note: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  /** @deprecated operatingStatus is deprecated. Use operatingStatusTerm instead. */
  readonly operatingStatus: Scalars['String'];
  readonly operatingStatusTerm?: Maybe<Scalars['String']>;
  /** @deprecated serviceStatus is deprecated. Use serviceStatusTerm instead. */
  readonly serviceStatus: Scalars['String'];
  readonly serviceStatusTerm?: Maybe<Scalars['String']>;
  /** @deprecated pool is deprecated. Use poolCarrierCode instead. */
  readonly pool?: Maybe<Scalars['String']>;
  readonly poolCarrierCode?: Maybe<Scalars['String']>;
  /** @deprecated lastPingLocation is deprecated. */
  readonly lastPingLocation?: Maybe<Scalars['String']>;
  /** @deprecated lastPingDateTm is deprecated. Use lastPingDateTime instead. */
  readonly lastPingDateTm: Scalars['String'];
  /** @deprecated lastPingDateTime is deprecated. */
  readonly lastPingDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated lastFacility is deprecated. */
  readonly lastFacility?: Maybe<Scalars['String']>;
  /** @deprecated facilityLocation is deprecated. */
  readonly facilityLocation?: Maybe<Scalars['String']>;
  /** @deprecated programTerm is deprecated. Use program instead. */
  readonly programTerm?: Maybe<Scalars['String']>;
  readonly program?: Maybe<Scalars['String']>;
  /** @deprecated projectTerm is deprecated. Use project instead. */
  readonly projectTerm?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  readonly measurementTerm?: Maybe<Scalars['String']>;
  /** @deprecated odometerUOMTerm is deprecated. Use odometerMiles instead. */
  readonly odometerUOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated weightUOMTerm is deprecated. Use powerWeight instead. */
  readonly weightUOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated wheelBaseUOMTerm is deprecated. Use powerWheelBase instead. */
  readonly wheelBaseUOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated tankCapacity1UOMTerm is deprecated. Use powerTankCapacity1 instead. */
  readonly tankCapacity1UOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated tankCapacity2UOMTerm is deprecated. Use powerTankCapacity2 instead. */
  readonly tankCapacity2UOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated equipment is deprecated. Use assetPowerEquipment instead. */
  readonly equipment?: Maybe<ReadonlyArray<AssetPowerEquipmentOutputV2>>;
  /** @deprecated identifiersV3 is deprecated. Use identifiers instead */
  readonly identifiersV3?: Maybe<ReadonlyArray<AssetPowerIdentifierOutputV3>>;
  /** @deprecated maintenanceV3 is deprecated. Use maintenance instead */
  readonly maintenanceV3?: Maybe<ReadonlyArray<AssetPowerMaintenanceOutputV2>>;
  readonly permanentTrailerCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentDriverCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** A collection of Equipments associated with the power. */
  readonly assetPowerEquipment?: Maybe<ReadonlyArray<PowerEquipmentOutput>>;
  /** A collection of identifiers associated with the power. */
  readonly assetPowerIdentifiers?: Maybe<ReadonlyArray<PowerIdentifierOutput>>;
  /** A collection of Maintenance associated with the power. */
  readonly assetPowerMaintenance?: Maybe<ReadonlyArray<PowerMaintenanceOutput>>;
  /** A collection of fleets associated with the power. */
  readonly powerFleets?: Maybe<ReadonlyArray<PowerFleet>>;
  readonly assetPermanentDrivers?: Maybe<ReadonlyArray<AssetDriver>>;
  readonly assetPermanentTrailers?: Maybe<ReadonlyArray<AssetTrailers>>;
  readonly currentRoute?: Maybe<RouteOutput>;
  readonly nextRoute?: Maybe<RouteOutput>;
  readonly lastPing?: Maybe<LastPingOutput>;
  readonly currentDrivers?: Maybe<ReadonlyArray<AssetDriver>>;
};

export type AssetPowerOutputEdge = {
  readonly __typename?: 'AssetPowerOutputEdge';
  readonly node: AssetPowerOutput;
  readonly cursor: Scalars['String'];
};

export type AssetPowerOutputV2 = {
  readonly __typename?: 'AssetPowerOutputV2';
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly displayName?: Maybe<Scalars['String']>;
  readonly makeTerm?: Maybe<Scalars['String']>;
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly year?: Maybe<Scalars['Int']>;
  readonly modelTerm?: Maybe<Scalars['String']>;
  readonly isCARBCompliant?: Maybe<Scalars['Boolean']>;
  readonly trackingDevice?: Maybe<TrackingDeviceOutput>;
  readonly classTerm?: Maybe<Scalars['String']>;
  /** @deprecated businessUnitTerm is deprecated. */
  readonly businessUnitTerm?: Maybe<Scalars['String']>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  readonly odometerMiles?: Maybe<Scalars['Float']>;
  readonly exteriorColor?: Maybe<Scalars['String']>;
  readonly interiorColor?: Maybe<Scalars['String']>;
  readonly sleeperTypeTerm?: Maybe<Scalars['String']>;
  readonly axleConfigurationTerm?: Maybe<Scalars['String']>;
  readonly suspensionTerm?: Maybe<Scalars['String']>;
  readonly sleeperSizeDimensionsTerm?: Maybe<Scalars['String']>;
  readonly powerWeight?: Maybe<UnitOfWeightOutputV2>;
  readonly engineMakeTerm?: Maybe<Scalars['String']>;
  readonly engineModelTerm?: Maybe<Scalars['String']>;
  readonly engineHP?: Maybe<Scalars['String']>;
  readonly isEngineBrakeAvailable?: Maybe<Scalars['Boolean']>;
  readonly transmissionMakeTerm?: Maybe<Scalars['String']>;
  readonly transmissionModelTerm?: Maybe<Scalars['String']>;
  readonly transmissionSpeed?: Maybe<Scalars['Int']>;
  readonly rearEndRatio?: Maybe<Scalars['Float']>;
  readonly wheelBase?: Maybe<UnitOfLengthOutputV3>;
  readonly tankCapacity1?: Maybe<UnitOfCapacityOutput>;
  readonly tankCapacity2?: Maybe<UnitOfCapacityOutput>;
  readonly fifthWheelTerm?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly operatingStatusTerm?: Maybe<Scalars['String']>;
  readonly serviceStatusTerm?: Maybe<Scalars['String']>;
  readonly poolCarrierCode?: Maybe<Scalars['String']>;
  /** @deprecated lastPingLocation is deprecated. */
  readonly lastPingLocation?: Maybe<Scalars['String']>;
  /** @deprecated lastPingDateTime is deprecated. */
  readonly lastPingDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated lastFacility is deprecated. */
  readonly lastFacility?: Maybe<Scalars['String']>;
  /** @deprecated facilityLocation is deprecated. */
  readonly facilityLocation?: Maybe<Scalars['String']>;
  readonly program?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  readonly measurementTerm?: Maybe<Scalars['String']>;
  readonly equipment?: Maybe<ReadonlyArray<AssetPowerEquipmentOutputV2>>;
  /** A collection of identifiers associated with the power. */
  readonly identifiers?: Maybe<ReadonlyArray<AssetPowerIdentifierOutputV3>>;
  readonly maintenance?: Maybe<ReadonlyArray<AssetPowerMaintenanceOutputV2>>;
  /** A collection of fleets associated with the power. */
  readonly powerFleets?: Maybe<ReadonlyArray<PowerFleet>>;
  readonly permanentTrailerCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentDriverCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type AssetPowerPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly displayName?: InputMaybe<Scalars['String']>;
  readonly makeTerm?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly year?: InputMaybe<Scalars['Int']>;
  readonly modelTerm?: InputMaybe<Scalars['String']>;
  readonly isCARBCompliant?: InputMaybe<Scalars['Boolean']>;
  readonly trackingDevice?: InputMaybe<TrackingDeviceInput>;
  readonly code: Scalars['String'];
  readonly classTerm?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly odometerMiles?: InputMaybe<Scalars['Float']>;
  readonly exteriorColor?: InputMaybe<Scalars['String']>;
  readonly interiorColor?: InputMaybe<Scalars['String']>;
  readonly sleeperTypeTerm?: InputMaybe<Scalars['String']>;
  readonly axleConfigurationTerm?: InputMaybe<Scalars['String']>;
  readonly suspensionTerm?: InputMaybe<Scalars['String']>;
  readonly sleeperSizeDimensionsTerm?: InputMaybe<Scalars['String']>;
  readonly powerWeight?: InputMaybe<UnitOfWeightInputV2>;
  readonly engineMakeTerm?: InputMaybe<Scalars['String']>;
  readonly engineModelTerm?: InputMaybe<Scalars['String']>;
  readonly powerEngineHP?: InputMaybe<Scalars['String']>;
  readonly isEngineBrakeAvailable?: InputMaybe<Scalars['Boolean']>;
  readonly transmissionMakeTerm?: InputMaybe<Scalars['String']>;
  readonly transmissionModelTerm?: InputMaybe<Scalars['String']>;
  readonly powerTransmissionSpeed?: InputMaybe<Scalars['Int']>;
  readonly rearEndRatio?: InputMaybe<Scalars['Float']>;
  readonly powerWheelBase?: InputMaybe<UnitOfLengthInputV4>;
  readonly powerTankCapacity1?: InputMaybe<UnitOfCapacityInput>;
  readonly powerTankCapacity2?: InputMaybe<UnitOfCapacityInput>;
  readonly fifthWheelTerm?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly operatingStatusTerm?: InputMaybe<Scalars['String']>;
  readonly serviceStatusTerm?: InputMaybe<Scalars['String']>;
  readonly poolCarrierCode?: InputMaybe<Scalars['String']>;
  readonly lastPingLocation?: InputMaybe<Scalars['String']>;
  readonly lastPingDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly lastFacility?: InputMaybe<Scalars['String']>;
  readonly facilityLocation?: InputMaybe<Scalars['String']>;
  readonly program?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly measurementTerm?: InputMaybe<Scalars['String']>;
  readonly permanentTrailerCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentDriverCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A collection of Equipments associated with the power. */
  readonly assetPowerPatchEquipment?: InputMaybe<ReadonlyArray<PowerEquipmentObjectPatchInput>>;
  /** A collection of identifiers associated with the power. */
  readonly assetPowerPatchIdentifiers?: InputMaybe<ReadonlyArray<PowerIdentifierObjectPatchInput>>;
  /** A collection of Maintenance associated with the power. */
  readonly assetPowerPatchMaintenance?: InputMaybe<ReadonlyArray<PowerMaintenanceObjectPatchInput>>;
  /** A collection of fleet associated with the power. */
  readonly assetPowerPatchFleet?: InputMaybe<ReadonlyArray<PowerFleetRelationshipObjectPatchInput>>;
};

export type AssetPowerPermanentDriverOutput = {
  readonly __typename?: 'AssetPowerPermanentDriverOutput';
  readonly id: Scalars['ID'];
  readonly permanentDriverId: Scalars['String'];
  readonly powerId?: Maybe<Scalars['String']>;
  readonly permanentDriverIdV1?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
};

export type AssetPowerPermanentTrailerOutput = {
  readonly __typename?: 'AssetPowerPermanentTrailerOutput';
  readonly id: Scalars['ID'];
  readonly permanentTrailerId: Scalars['String'];
  readonly powerId?: Maybe<Scalars['String']>;
  readonly permanentTrailerIdV1?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
};

export type AssetTrailerCustomerRelationshipPayload = {
  readonly __typename?: 'AssetTrailerCustomerRelationshipPayload';
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
  /** A collection of customers associated with the trailer. */
  readonly customer?: Maybe<TrailerCustomerRelationshipOutput>;
};

export type AssetTrailerEquipmentPayload = {
  readonly __typename?: 'AssetTrailerEquipmentPayload';
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
  /** A collection of equipments associated with the trailer. */
  readonly equipment?: Maybe<TrailerEquipmentOutput>;
};

export type AssetTrailerFleetInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly carrierId: Scalars['ID'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly trailerId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type AssetTrailerIdentifierPayload = {
  readonly __typename?: 'AssetTrailerIdentifierPayload';
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
  /** A collection of identifiers associated with the trailer. */
  readonly identifier?: Maybe<TrailerIdentifierOutput>;
};

export type AssetTrailerMaintenancePayload = {
  readonly __typename?: 'AssetTrailerMaintenancePayload';
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
  /** A collection of maintenance associated with the trailer. */
  readonly maintenance?: Maybe<TrailerMaintenanceOutput>;
};

export type AssetTrailerPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly code: Scalars['String'];
  readonly displayName?: InputMaybe<Scalars['String']>;
  readonly makeTerm?: InputMaybe<Scalars['String']>;
  readonly year?: InputMaybe<Scalars['Int']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly isCARBCompliant?: InputMaybe<Scalars['Boolean']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly color?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly modelTerm?: InputMaybe<Scalars['String']>;
  readonly prefixTerm?: InputMaybe<Scalars['String']>;
  readonly trackingDevice?: InputMaybe<TrackingDeviceInput>;
  readonly containerProgramTerm?: InputMaybe<Scalars['String']>;
  readonly floorTerm?: InputMaybe<Scalars['String']>;
  readonly roofTerm?: InputMaybe<Scalars['String']>;
  readonly doorsTerm?: InputMaybe<Scalars['String']>;
  readonly skinTerm?: InputMaybe<Scalars['String']>;
  readonly tire?: InputMaybe<TireInput>;
  readonly trailerLength?: InputMaybe<UnitOfLengthInputV4>;
  readonly trailerExteriorHeight?: InputMaybe<UnitOfLengthInputV3>;
  readonly trailerExteriorWidth?: InputMaybe<UnitOfLengthInputV3>;
  readonly grossVehicleWeightRating?: InputMaybe<UnitOfWeightInputV2>;
  readonly grossAxleWeightRating?: InputMaybe<UnitOfWeightInputV2>;
  readonly trailerWeight?: InputMaybe<UnitOfWeightInputV2>;
  readonly trailerFrontInteriorHeight?: InputMaybe<UnitOfLengthInputV3>;
  readonly trailerRearInteriorHeight?: InputMaybe<UnitOfLengthInputV3>;
  readonly trailerInteriorWidth?: InputMaybe<UnitOfLengthInputV4>;
  readonly trailerDoorWidth?: InputMaybe<UnitOfLengthInputV4>;
  readonly landingGearTerm?: InputMaybe<Scalars['String']>;
  readonly suspensionTerm?: InputMaybe<Scalars['String']>;
  readonly axleTypeTerm?: InputMaybe<Scalars['String']>;
  readonly axleCount?: InputMaybe<Scalars['Int']>;
  readonly liftgateTypeTerm?: InputMaybe<Scalars['String']>;
  readonly trailerLiftgateCapacity?: InputMaybe<UnitOfWeightInput>;
  readonly trailerLiftgateSize?: InputMaybe<UnitOfLengthInputV3>;
  readonly isETracks?: InputMaybe<Scalars['Boolean']>;
  readonly trailerLengthClosed?: InputMaybe<UnitOfLengthInputV3>;
  readonly trailerLengthExtended?: InputMaybe<UnitOfLengthInputV3>;
  readonly tempMonitoringTerm?: InputMaybe<Scalars['String']>;
  readonly isRemoteAccessAvailable?: InputMaybe<Scalars['Boolean']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly program?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly measurementTerm?: InputMaybe<Scalars['String']>;
  readonly operatingStatusTerm?: InputMaybe<Scalars['String']>;
  readonly serviceStatusTerm?: InputMaybe<Scalars['String']>;
  readonly serviceStatusDetails?: InputMaybe<ServiceStatusDetailsInput>;
  readonly poolCarrierCode?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly permanentPowerUnitCode?: InputMaybe<Scalars['String']>;
  readonly permanentDriverCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A collection of equipments associated with the trailer. */
  readonly patchEquipment?: InputMaybe<ReadonlyArray<TrailerEquipmentPatchInput>>;
  /** A collection of identifiers associated with the trailer. */
  readonly patchIdentifier?: InputMaybe<ReadonlyArray<TrailerIdentifierPatchInput>>;
  /** A collection of maintenance associated with the trailer. */
  readonly patchMaintenance?: InputMaybe<ReadonlyArray<TrailerMaintenancePatchInput>>;
  /** A collection of fleets associated with the trailer. */
  readonly patchFleet?: InputMaybe<ReadonlyArray<TrailerFleetRelationPatchInput>>;
  /** A collection of customers associated with the trailer. */
  readonly patchCustomer?: InputMaybe<ReadonlyArray<TrailerCustomerRelationshipPatchInput>>;
};

export type AssetTrailers = {
  readonly __typename?: 'AssetTrailers';
  readonly id: Scalars['ID'];
  readonly displayName?: Maybe<Scalars['String']>;
  /** @deprecated make is deprecated. Use makeTerm instead. */
  readonly make: Scalars['String'];
  readonly makeTerm?: Maybe<Scalars['String']>;
  /** @deprecated type is deprecated. Use typeTerm instead. */
  readonly type?: Maybe<Scalars['String']>;
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly year?: Maybe<Scalars['Int']>;
  /** @deprecated yearV2 is deprecated. Use year instead. */
  readonly yearV2?: Maybe<Scalars['Int']>;
  /** @deprecated model is deprecated. Use modelTerm instead. */
  readonly model?: Maybe<Scalars['String']>;
  readonly modelTerm?: Maybe<Scalars['String']>;
  readonly prefixTerm?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly trackingDevice?: Maybe<TrackingDeviceOutput>;
  /**
   * DEPRECATED: legalToRun is deprecated.
   * @deprecated legalToRun is deprecated.
   */
  readonly legalToRun?: Maybe<ReadonlyArray<AssetTrailersLegalToRun>>;
  /**
   * This field describes the list of countries details where the trailer is legal to run(Ex. Dry Van , CAN)
   * @deprecated legalToRun is deprecated.
   */
  readonly legalToRunTerm?: Maybe<ReadonlyArray<AssetTrailersLegalToRun>>;
  /** @deprecated equipmentDetails is deprecated. Use equipment instead. */
  readonly equipmentDetails?: Maybe<ReadonlyArray<AssetTrailersEquipmentOutput>>;
  /** @deprecated identifiers is deprecated. Use assetTrailerIdentifiers instead. */
  readonly identifiers?: Maybe<ReadonlyArray<AssetTrailersIdentifierOutputV3>>;
  /**
   * A collection of identifiers associated with the trailer.
   * @deprecated identifiersV2 is deprecated. Use identifiers instead.
   */
  readonly identifiersV2?: Maybe<ReadonlyArray<AssetTrailersIdentifierOutputV2>>;
  /**
   * DEPRECATED: permanentDriver is deprecated. Use permanentDriverCodes instead.
   * @deprecated permanentDriver is deprecated. Use permanentDriverCodes instead.
   */
  readonly permanentDriver?: Maybe<ReadonlyArray<AssetTrailersPermanentDriverOutput>>;
  /**
   * DEPRECATED: permanentDrivers is deprecated. Use permanentDriverCodes instead.
   * @deprecated permanentDrivers is deprecated. Use permanentDriverCodes instead.
   */
  readonly permanentDrivers?: Maybe<ReadonlyArray<AssetTrailersPermanentDriverOutput>>;
  /**
   * DEPRECATED: permanentTractor is deprecated. Use permanentPowerUnitCode instead.
   * @deprecated permanentTractor is deprecated. Use permanentPowerUnitCode instead.
   */
  readonly permanentTractor?: Maybe<ReadonlyArray<AssetTrailersPermanentTractorOutput>>;
  /**
   * DEPRECATED: permanentPowerUnit is deprecated. Use permanentPowerUnitCode instead.
   * @deprecated permanentPowerUnit is deprecated. Use permanentPowerUnitCode instead.
   */
  readonly permanentPowerUnit?: Maybe<ReadonlyArray<AssetTrailersPermanentTractorOutput>>;
  /** @deprecated maintenance is deprecated. Use assetTrailerMaintenance instead. */
  readonly maintenance?: Maybe<ReadonlyArray<AssetTrailersMaintenanceOutputV2>>;
  /** @deprecated trailerFleets is deprecated. Use fleets instead. */
  readonly trailerFleets?: Maybe<ReadonlyArray<TrailerFleet>>;
  readonly fleets?: Maybe<ReadonlyArray<TrailerFleet>>;
  readonly isCARBCompliant?: Maybe<Scalars['Boolean']>;
  readonly containerProgramTerm?: Maybe<Scalars['String']>;
  /** @deprecated trailerDevice is deprecated. Use trackingDevice.deviceTerm instead. */
  readonly trailerDevice?: Maybe<Scalars['String']>;
  /** @deprecated trailerDeviceTerm is deprecated. Use trackingDevice.deviceTerm instead. */
  readonly trailerDeviceTerm?: Maybe<Scalars['String']>;
  /** @deprecated trailerModel is deprecated. Use trackingDevice.modelTerm instead. */
  readonly trailerModel?: Maybe<Scalars['String']>;
  /** @deprecated trailerModel is deprecated. Use trackingDevice.modelTerm instead. */
  readonly trailerModelTerm?: Maybe<Scalars['String']>;
  /** @deprecated serialNumber is deprecated. Use trackingDevice.serialNumber instead. */
  readonly serialNumber?: Maybe<Scalars['String']>;
  readonly code: Scalars['String'];
  /** @deprecated owner is deprecated. */
  readonly owner?: Maybe<Scalars['String']>;
  /** @deprecated ownerTerm is deprecated. */
  readonly ownerTerm?: Maybe<Scalars['String']>;
  /** @deprecated odomoter is deprecated. */
  readonly odomoter?: Maybe<Scalars['Float']>;
  /** @deprecated note is deprecated. Use notes instead. */
  readonly note?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly color?: Maybe<Scalars['String']>;
  /** @deprecated floor is deprecated. Use floorTerm instead. */
  readonly floor?: Maybe<Scalars['String']>;
  readonly floorTerm?: Maybe<Scalars['String']>;
  /** @deprecated roof is deprecated. Use roofTerm instead. */
  readonly roof?: Maybe<Scalars['String']>;
  readonly roofTerm?: Maybe<Scalars['String']>;
  /** @deprecated doors is deprecated. Use doorsTerm instead. */
  readonly doors?: Maybe<Scalars['String']>;
  readonly doorsTerm?: Maybe<Scalars['String']>;
  /** @deprecated skin is deprecated. Use skinTerm instead. */
  readonly skin?: Maybe<Scalars['String']>;
  readonly skinTerm?: Maybe<Scalars['String']>;
  /** @deprecated rearTireSize is deprecated. */
  readonly rearTireSize?: Maybe<Scalars['String']>;
  readonly tire?: Maybe<TireOutput>;
  readonly trailerLength?: Maybe<UnitOfLengthOutputV3>;
  /** @deprecated length is deprecated. Use trailerLength.value instead. */
  readonly length?: Maybe<Scalars['String']>;
  /** @deprecated exteriorHeight is deprecated. Use trailerExteriorHeight.value instead. */
  readonly exteriorHeight?: Maybe<Scalars['String']>;
  /** @deprecated exteriorHeightV2 is deprecated. Use trailerExteriorHeight instead. */
  readonly exteriorHeightV2?: Maybe<UnitOfLengthOutputV2>;
  readonly trailerExteriorHeight?: Maybe<UnitOfLengthOutputV2>;
  /** @deprecated exteriorWidth is deprecated. Use trailerExteriorWidth.value instead. */
  readonly exteriorWidth?: Maybe<Scalars['String']>;
  /** @deprecated exteriorWidthV2 is deprecated. Use trailerExteriorWidth instead. */
  readonly exteriorWidthV2?: Maybe<UnitOfLengthOutputV2>;
  readonly trailerExteriorWidth?: Maybe<UnitOfLengthOutputV2>;
  /** @deprecated gvwr is deprecated. Use grossVehicleWeightRating.value instead. */
  readonly gvwr?: Maybe<Scalars['String']>;
  readonly grossVehicleWeightRating?: Maybe<UnitOfWeightOutputV2>;
  /** @deprecated gawr is deprecated. Use grossAxleWeightRating.value instead. */
  readonly gawr?: Maybe<Scalars['String']>;
  readonly grossAxleWeightRating?: Maybe<UnitOfWeightOutputV2>;
  /** @deprecated weight is deprecated. Use trailerWeight.value instead. */
  readonly weight?: Maybe<Scalars['String']>;
  readonly trailerWeight?: Maybe<UnitOfWeightOutputV2>;
  /** @deprecated frontInteriorHeight is deprecated. Use trailerFrontInteriorHeight.value instead. */
  readonly frontInteriorHeight?: Maybe<Scalars['String']>;
  /** @deprecated frontInteriorHeightV2 is deprecated. Use trailerFrontInteriorHeight instead. */
  readonly frontInteriorHeightV2?: Maybe<UnitOfLengthOutputV2>;
  readonly trailerFrontInteriorHeight?: Maybe<UnitOfLengthOutputV2>;
  /** @deprecated rearInteriorHeight is deprecated. Use trailerRearInteriorHeight.value instead. */
  readonly rearInteriorHeight?: Maybe<Scalars['String']>;
  /** @deprecated rearInteriorHeightV2 is deprecated. Use trailerRearInteriorHeight instead. */
  readonly rearInteriorHeightV2?: Maybe<UnitOfLengthOutputV2>;
  readonly trailerRearInteriorHeight?: Maybe<UnitOfLengthOutputV2>;
  /** @deprecated interiorWidth is deprecated. Use trailerInteriorWidth.value instead. */
  readonly interiorWidth?: Maybe<Scalars['String']>;
  /** @deprecated interiorWidthV2 is deprecated. Use trailerInteriorWidth instead. */
  readonly interiorWidthV2?: Maybe<UnitOfLengthOutputV3>;
  readonly trailerInteriorWidth?: Maybe<UnitOfLengthOutputV3>;
  /** @deprecated doorWidth is deprecated. Use trailerDoorWidth.value instead. */
  readonly doorWidth?: Maybe<Scalars['String']>;
  /** @deprecated doorWidthV2 is deprecated. Use trailerDoorWidth instead. */
  readonly doorWidthV2?: Maybe<UnitOfLengthOutputV3>;
  readonly trailerDoorWidth?: Maybe<UnitOfLengthOutputV3>;
  /** @deprecated landingGear is deprecated. Use landingGearTerm instead. */
  readonly landingGear?: Maybe<Scalars['String']>;
  readonly landingGearTerm?: Maybe<Scalars['String']>;
  /** @deprecated suspension is deprecated. Use suspensionTerm instead. */
  readonly suspension?: Maybe<Scalars['String']>;
  readonly suspensionTerm?: Maybe<Scalars['String']>;
  /** @deprecated liftgateType is deprecated. Use liftgateTypeTerm instead. */
  readonly liftgateType?: Maybe<Scalars['String']>;
  readonly liftgateTypeTerm?: Maybe<Scalars['String']>;
  /** @deprecated liftgateCapacity is deprecated. Use trailerLiftgateCapacity.value instead. */
  readonly liftgateCapacity?: Maybe<Scalars['String']>;
  /** @deprecated liftgateCapacityV2 is deprecated. Use trailerLiftgateCapacity instead. */
  readonly liftgateCapacityV2?: Maybe<UnitOfWeightOutput>;
  readonly trailerLiftgateCapacity?: Maybe<UnitOfWeightOutput>;
  /** @deprecated liftgateSize is deprecated. Use trailerLiftgateSize.value instead. */
  readonly liftgateSize?: Maybe<Scalars['String']>;
  /** @deprecated liftgateSizeV2 is deprecated. Use trailerLiftgateSize instead. */
  readonly liftgateSizeV2?: Maybe<UnitOfLengthOutputV2>;
  readonly trailerLiftgateSize?: Maybe<UnitOfLengthOutputV2>;
  /** @deprecated axleType is deprecated. Use axleTypeTerm instead. */
  readonly axleType?: Maybe<Scalars['String']>;
  readonly axleTypeTerm?: Maybe<Scalars['String']>;
  readonly axleCount?: Maybe<Scalars['Int']>;
  /** @deprecated etracks is deprecated. Use isETracks instead. */
  readonly etracks?: Maybe<Scalars['String']>;
  /** @deprecated eTracks is deprecated. Use isETracks instead. */
  readonly eTracks?: Maybe<Scalars['String']>;
  readonly isETracks?: Maybe<Scalars['Boolean']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  /** @deprecated lClosed is deprecated. Use trailerLengthClosed instead. */
  readonly lClosed?: Maybe<Scalars['String']>;
  /** @deprecated lengthClosed is deprecated. Use trailerLengthClosed instead. */
  readonly lengthClosed?: Maybe<Scalars['String']>;
  /** @deprecated lengthClosedV2 is deprecated. Use trailerLengthClosed instead. */
  readonly lengthClosedV2?: Maybe<UnitOfLengthOutputV2>;
  readonly trailerLengthClosed?: Maybe<UnitOfLengthOutputV2>;
  /** @deprecated lExtended is deprecated. Use trailerlengthExtended instead. */
  readonly lExtended?: Maybe<Scalars['String']>;
  /** @deprecated lengthExtended is deprecated. Use trailerLengthExtended instead. */
  readonly lengthExtended?: Maybe<Scalars['String']>;
  /** @deprecated lengthExtendedV2 is deprecated. Use trailerLengthExtended instead. */
  readonly lengthExtendedV2?: Maybe<UnitOfLengthOutputV2>;
  readonly trailerLengthExtended?: Maybe<UnitOfLengthOutputV2>;
  /** @deprecated operatingStatus is deprecated. Use operatingStatusTerm instead. */
  readonly operatingStatus?: Maybe<Scalars['String']>;
  readonly operatingStatusTerm?: Maybe<Scalars['String']>;
  /** @deprecated serviceStatus is deprecated. Use serviceStatusTerm instead. */
  readonly serviceStatus?: Maybe<Scalars['String']>;
  readonly serviceStatusTerm?: Maybe<Scalars['String']>;
  readonly serviceStatusDetails?: Maybe<ServiceStatusDetailsOutput>;
  /**
   * DEPRECATED: Fleet is deprecated. Use createOrUpdateTrailerFleet Mutation instead.
   * @deprecated Fleet is deprecated. Use createOrUpdateTrailerFleet Mutation instead.
   */
  readonly fleet?: Maybe<Scalars['String']>;
  /** @deprecated pool is deprecated. Use poolCarrierCode instead. */
  readonly pool?: Maybe<Scalars['String']>;
  readonly poolCarrierCode?: Maybe<Scalars['String']>;
  /** @deprecated tempMonitoring is deprecated. Use tempMonitoringTerm instead. */
  readonly tempMonitoring?: Maybe<Scalars['String']>;
  readonly tempMonitoringTerm?: Maybe<Scalars['String']>;
  /** @deprecated remoteAccess is deprecated. Use isRemoteAccessAvailable instead. */
  readonly remoteAccess?: Maybe<Scalars['String']>;
  readonly isRemoteAccessAvailable?: Maybe<Scalars['Boolean']>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  /** @deprecated projectTerm is deprecated. Use project instead. */
  readonly projectTerm?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  /** @deprecated programTerm is deprecated. Use program instead. */
  readonly programTerm?: Maybe<Scalars['String']>;
  readonly program?: Maybe<Scalars['String']>;
  /** @deprecated businessUnitTerm is deprecated. */
  readonly businessUnitTerm?: Maybe<Scalars['String']>;
  readonly measurementTerm?: Maybe<Scalars['String']>;
  /** @deprecated odometerUOMTerm is deprecated. */
  readonly odometerUOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated lengthUOMTerm is deprecated. Use trailerLength.unit instead. */
  readonly lengthUOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated exteriorHeightUOMTerm is deprecated. Use trailerExteriorHeight.unit instead. */
  readonly exteriorHeightUOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated exteriorWidthUOMTerm is deprecated. Use trailerExteriorWidth.unit instead. */
  readonly exteriorWidthUOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated gvwrUOMTerm is deprecated. Use grossVehicleWeightRating.unit instead. */
  readonly gvwrUOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated gawrUOMTerm is deprecated. Use grossAxleWeightRating.unit instead. */
  readonly gawrUOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated weightUOMTerm is deprecated. Use trailerWeight.unit instead. */
  readonly weightUOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated frontInteriorHeightUOMTerm is deprecated. Use trailerFrontInteriorHeight.unit instead. */
  readonly frontInteriorHeightUOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated rearInteriorHeightUOMTerm is deprecated. Use trailerRearInteriorHeight.unit instead. */
  readonly rearInteriorHeightUOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated interiorWidthUOMTerm is deprecated. Use trailerInteriorWidth.unit instead. */
  readonly interiorWidthUOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated doorWidthUOMTerm is deprecated. Use trailerDoorWidth.unit instead. */
  readonly doorWidthUOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated tireWidth is deprecated. Use tire.width instead. */
  readonly tireWidth?: Maybe<Scalars['Int']>;
  /** @deprecated tireAspectRatio is deprecated. Use tire.aspectRatio instead. */
  readonly tireAspectRatio?: Maybe<Scalars['Int']>;
  /** @deprecated tireRimSize is deprecated. Use tire.rimSize instead. */
  readonly tireRimSize?: Maybe<Scalars['Int']>;
  /** @deprecated liftgateCapacityUOMTerm is deprecated. Use trailerLiftgateCapacity.unit instead. */
  readonly liftgateCapacityUOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated liftgateSizeUOMTerm is deprecated. Use trailerLiftgateSize.unit instead. */
  readonly liftgateSizeUOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated lengthClosedUOMTerm is deprecated. Use trailerLengthClosed.unit instead. */
  readonly lengthClosedUOMTerm?: Maybe<Scalars['String']>;
  /** @deprecated lengthExtendedUOMTerm is deprecated. Use trailerLengthExtended.unit instead. */
  readonly lengthExtendedUOMTerm?: Maybe<Scalars['String']>;
  /**
   * A collection of Equipments associated with the trailer.
   * @deprecated equipment is deprecated. Use assetTrailerEquipment instead.
   */
  readonly equipment?: Maybe<ReadonlyArray<AssetTrailersEquipmentOutputV2>>;
  /**
   * DEPRECATED: identifiersV3 is deprecated. Use identifiers instead
   * @deprecated identifiersV3 is deprecated. Use identifiers instead.
   */
  readonly identifiersV3?: Maybe<ReadonlyArray<AssetTrailersIdentifierOutputV3>>;
  readonly permanentPowerUnitCode?: Maybe<Scalars['String']>;
  readonly permanentDriverCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  /**
   * DEPRECATED: maintenanceV2 is deprecated. Use maintenance instead
   * @deprecated maintenanceV2 is deprecated. Use maintenance instead.
   */
  readonly maintenanceV2?: Maybe<ReadonlyArray<AssetTrailersMaintenanceOutputV2>>;
  /** A collection of Equipments associated with the trailer. */
  readonly assetTrailerEquipment?: Maybe<ReadonlyArray<TrailerEquipmentOutput>>;
  /** A collection of identifiers associated with the trailer. */
  readonly assetTrailerIdentifiers?: Maybe<ReadonlyArray<TrailerIdentifierOutput>>;
  /** A collection of Maintenance associated with the trailer. */
  readonly assetTrailerMaintenance?: Maybe<ReadonlyArray<TrailerMaintenanceOutput>>;
  readonly power?: Maybe<AssetPowerOutput>;
  readonly drivers?: Maybe<ReadonlyArray<AssetDriver>>;
  /** A collection of customers associated with the trailer. */
  readonly assetTrailerCustomers?: Maybe<ReadonlyArray<TrailerCustomerRelationshipOutput>>;
};

export type AssetTrailersEdge = {
  readonly __typename?: 'AssetTrailersEdge';
  readonly cursor: Scalars['String'];
  readonly node: AssetTrailers;
};

export type AssetTrailersEquipmentOutput = {
  readonly __typename?: 'AssetTrailersEquipmentOutput';
  readonly id: Scalars['ID'];
  /** @deprecated equipmentCd is deprecated. Use typeTerm instead. */
  readonly equipmentCd: Scalars['String'];
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count?: Maybe<Scalars['Int']>;
  readonly assetId: Scalars['String'];
  /** @deprecated condition is deprecated. Use conditionTerm instead. */
  readonly condition: Scalars['String'];
  readonly conditionTerm: Scalars['String'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly issueDate?: Maybe<Scalars['DateTime']>;
  readonly returnDate?: Maybe<Scalars['DateTime']>;
};

export type AssetTrailersEquipmentOutputV2 = {
  readonly __typename?: 'AssetTrailersEquipmentOutputV2';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId: Scalars['String'];
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly returnDate?: Maybe<Scalars['Date']>;
  readonly trailerId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type AssetTrailerServiceStatusPayload = {
  readonly __typename?: 'AssetTrailerServiceStatusPayload';
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
  readonly serviceStatusDetails?: Maybe<TrailerServiceStatusDetailsOutput>;
};

export type AssetTrailersIdentifierOutputV2 = {
  readonly __typename?: 'AssetTrailersIdentifierOutputV2';
  readonly id: Scalars['ID'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AssetTrailersIdentifierOutputV3 = {
  readonly __typename?: 'AssetTrailersIdentifierOutputV3';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly value: Scalars['String'];
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly trailerId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type AssetTrailersLegalToRun = {
  readonly __typename?: 'AssetTrailersLegalToRun';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly trailerId?: Maybe<Scalars['String']>;
};

export type AssetTrailersMaintenanceOutputV2 = {
  readonly __typename?: 'AssetTrailersMaintenanceOutputV2';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly severityTerm: Scalars['String'];
  readonly isPlanned?: Maybe<Scalars['Boolean']>;
  /** @deprecated location is deprecated. Use maintenanceLocation instead */
  readonly location?: Maybe<MaintenanceLocationOutput>;
  readonly maintenanceLocation?: Maybe<TrailerMaintenanceLocationOutput>;
  readonly estimatedMaintenanceTime?: Maybe<Scalars['Int']>;
  readonly actual?: Maybe<DateTimeEntryOutput>;
  readonly expected?: Maybe<DateTimeEntryOutput>;
  readonly workPerformedDetail?: Maybe<Scalars['String']>;
  readonly trailerId: Scalars['ID'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type AssetTrailersPermanentDriverOutput = {
  readonly __typename?: 'AssetTrailersPermanentDriverOutput';
  readonly id: Scalars['ID'];
  readonly permanentDriverId: Scalars['String'];
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly permanentDriverIdV1: Scalars['String'];
  readonly code?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type AssetTrailersPermanentTractorOutput = {
  readonly __typename?: 'AssetTrailersPermanentTractorOutput';
  readonly id: Scalars['ID'];
  readonly permanentTractorId: Scalars['String'];
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly permanentTractorIdV1: Scalars['String'];
  readonly code?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type AssetTrailersV2 = {
  readonly __typename?: 'AssetTrailersV2';
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly displayName?: Maybe<Scalars['String']>;
  readonly makeTerm?: Maybe<Scalars['String']>;
  readonly year?: Maybe<Scalars['Int']>;
  readonly isCARBCompliant?: Maybe<Scalars['Boolean']>;
  readonly containerProgramTerm?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly color?: Maybe<Scalars['String']>;
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly modelTerm?: Maybe<Scalars['String']>;
  readonly trackingDevice?: Maybe<TrackingDeviceOutput>;
  readonly floorTerm?: Maybe<Scalars['String']>;
  readonly roofTerm?: Maybe<Scalars['String']>;
  readonly doorsTerm?: Maybe<Scalars['String']>;
  readonly skinTerm?: Maybe<Scalars['String']>;
  readonly tire?: Maybe<TireOutput>;
  readonly trailerLength?: Maybe<UnitOfLengthOutputV3>;
  readonly exteriorHeight?: Maybe<UnitOfLengthOutputV2>;
  readonly exteriorWidth?: Maybe<UnitOfLengthOutputV2>;
  readonly grossVehicleWeightRating?: Maybe<UnitOfWeightOutputV2>;
  readonly grossAxleWeightRating?: Maybe<UnitOfWeightOutputV2>;
  readonly trailerWeight?: Maybe<UnitOfWeightOutputV2>;
  readonly frontInteriorHeight?: Maybe<UnitOfLengthOutputV2>;
  readonly rearInteriorHeight?: Maybe<UnitOfLengthOutputV2>;
  readonly interiorWidth?: Maybe<UnitOfLengthOutputV3>;
  readonly doorWidth?: Maybe<UnitOfLengthOutputV3>;
  readonly landingGearTerm?: Maybe<Scalars['String']>;
  readonly suspensionTerm?: Maybe<Scalars['String']>;
  readonly axleTypeTerm?: Maybe<Scalars['String']>;
  readonly axleCount?: Maybe<Scalars['Int']>;
  readonly liftgateTypeTerm?: Maybe<Scalars['String']>;
  readonly liftgateCapacity?: Maybe<UnitOfWeightOutput>;
  readonly liftgateSize?: Maybe<UnitOfLengthOutputV2>;
  readonly isETracks?: Maybe<Scalars['Boolean']>;
  readonly lengthClosed?: Maybe<UnitOfLengthOutputV2>;
  readonly lengthExtended?: Maybe<UnitOfLengthOutputV2>;
  readonly operatingStatusTerm?: Maybe<Scalars['String']>;
  readonly serviceStatusTerm?: Maybe<Scalars['String']>;
  readonly poolCarrierCode?: Maybe<Scalars['String']>;
  readonly tempMonitoringTerm?: Maybe<Scalars['String']>;
  readonly isRemoteAccessAvailable?: Maybe<Scalars['Boolean']>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  readonly program?: Maybe<Scalars['String']>;
  /** @deprecated businessUnitTerm is deprecated. */
  readonly businessUnitTerm?: Maybe<Scalars['String']>;
  readonly measurementTerm?: Maybe<Scalars['String']>;
  /** A collection of Equipments associated with the trailer. */
  readonly equipment?: Maybe<ReadonlyArray<AssetTrailersEquipmentOutputV2>>;
  /** A collection of identifiers associated with the trailer. */
  readonly identifiers?: Maybe<ReadonlyArray<AssetTrailersIdentifierOutputV3>>;
  readonly permanentPowerUnitCode?: Maybe<Scalars['String']>;
  readonly permanentDriverCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** A collection of identifiers associated with the trailer. */
  readonly maintenance?: Maybe<ReadonlyArray<AssetTrailersMaintenanceOutputV2>>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type AssignAccountingChargeTypeToBlumeChargeTypeInput = {
  readonly blumeChargeTypeId: Scalars['ID'];
  readonly accountingChargeTypeId?: InputMaybe<Scalars['ID']>;
};

export type AssignAccountingChargeTypeToBlumeChargeTypePayload = {
  readonly __typename?: 'AssignAccountingChargeTypeToBlumeChargeTypePayload';
  readonly success: Scalars['Boolean'];
};

export type AssignedRouteStopShipment = {
  readonly __typename?: 'AssignedRouteStopShipment';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly stopValue: Scalars['String'];
  readonly flattenedCommodities?: Maybe<ReadonlyArray<AssignedRouteStopShipmentFlattenedCommodity>>;
};

export type AssignedRouteStopShipmentFlattenedCommodity = {
  readonly __typename?: 'AssignedRouteStopShipmentFlattenedCommodity';
  readonly id: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly description: Scalars['String'];
  readonly stccCode?: Maybe<Scalars['String']>;
  readonly customerCode?: Maybe<Scalars['String']>;
  readonly packagingCount?: Maybe<Scalars['Int']>;
  readonly packagingType?: Maybe<KeyValue>;
  readonly loadOnType?: Maybe<KeyValue>;
  readonly topLoadable?: Maybe<Scalars['Boolean']>;
  readonly bottomLoadable?: Maybe<Scalars['Boolean']>;
  readonly doNotStack?: Maybe<Scalars['Boolean']>;
  /** @deprecated Please use expectedWeightV2. It supports new uom values */
  readonly expectedWeight: Mass;
  /** @deprecated Please use actualWeightV2. It supports new uom values */
  readonly actualWeight?: Maybe<Mass>;
  readonly expectedWeightV2?: Maybe<MassV2>;
  readonly actualWeightV2?: Maybe<MassV2>;
  readonly pieceType?: Maybe<KeyValue>;
  readonly expectedPieces?: Maybe<Scalars['Int']>;
  readonly actualPieces?: Maybe<Scalars['Int']>;
  readonly temperatureControlled?: Maybe<Scalars['Boolean']>;
  /** @deprecated Please use preCoolToV2. It supports new uom values */
  readonly preCoolTo?: Maybe<Temperature>;
  /** @deprecated Please use minimumTemperatureV2. It supports new uom values */
  readonly minimumTemperature?: Maybe<Temperature>;
  /** @deprecated Please use maximumTemperatureV2. It supports new uom values */
  readonly maximumTemperature?: Maybe<Temperature>;
  readonly preCoolToV2?: Maybe<TemperatureV2>;
  readonly minimumTemperatureV2?: Maybe<TemperatureV2>;
  readonly maximumTemperatureV2?: Maybe<TemperatureV2>;
  readonly temperatureSetting?: Maybe<CommodityTemperatureSettingTypeEnum>;
  readonly commodityTagIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly hazmat?: Maybe<Scalars['Boolean']>;
  readonly hazmatClass?: Maybe<KeyValue>;
  readonly hazmatPackagingGroup?: Maybe<CommodityHazmatPackagingGroupTypeEnum>;
  readonly hazmatUnitedNationsNumber?: Maybe<Scalars['String']>;
  readonly hazmatPhoneNumber?: Maybe<Scalars['String']>;
  /** @deprecated Please use actualDimensionsV2. It supports new uom values */
  readonly actualDimensions?: Maybe<DimensionsV2>;
  /** @deprecated Please use expectedDimensionsV2. It supports new uom values */
  readonly expectedDimensions?: Maybe<DimensionsV2>;
  readonly actualDimensionsV2?: Maybe<CommodityDimensionsV2>;
  readonly displayActualDimensionsV2?: Maybe<DimensionDisplay>;
  readonly expectedDimensionsV2?: Maybe<CommodityDimensionsV2>;
  readonly displayExpectedDimensionsV2?: Maybe<DimensionDisplay>;
  /** @deprecated Please use expectedLinearV2. It supports new uom values */
  readonly expectedLinear?: Maybe<Length>;
  /** @deprecated Please use actualLinearV2. It supports new uom values */
  readonly actualLinear?: Maybe<Length>;
  readonly expectedLinearV2?: Maybe<LengthV2>;
  readonly displayExpectedLinearV2?: Maybe<Scalars['String']>;
  readonly actualLinearV2?: Maybe<LengthV2>;
  readonly displayActualLinearV2?: Maybe<Scalars['String']>;
  /** @deprecated Please use actualVolumeV2. It supports new uom values */
  readonly actualVolume?: Maybe<Volume>;
  /** @deprecated Please use expectedVolumeV2. It supports new uom values */
  readonly expectedVolume?: Maybe<Volume>;
  readonly actualVolumeV2?: Maybe<VolumeV2>;
  readonly expectedVolumeV2?: Maybe<VolumeV2>;
  /** @deprecated Please use actualDensityV2. It supports new uom values */
  readonly actualDensity?: Maybe<Density>;
  /** @deprecated Please use expectedDensityV2. It supports new uom values */
  readonly expectedDensity?: Maybe<Density>;
  readonly actualDensityV2?: Maybe<DensityV2>;
  readonly expectedDensityV2?: Maybe<DensityV2>;
  readonly actualClass?: Maybe<KeyValue>;
  readonly expectedClass?: Maybe<KeyValue>;
  readonly actualNMFCCode?: Maybe<Scalars['String']>;
  readonly expectedNMFCCode?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly overDimensional?: Maybe<Scalars['Boolean']>;
  readonly year?: Maybe<Scalars['Float']>;
  readonly make?: Maybe<Scalars['String']>;
  readonly model?: Maybe<Scalars['String']>;
  readonly serialNumbers?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** Shipments to which this commodity belongs */
  readonly shipments?: Maybe<ReadonlyArray<Shipment>>;
  /** Order Stops on commodities */
  readonly orderStops?: Maybe<ReadonlyArray<OrderStop>>;
  /** Shipment/Commodity tracking states as they relate to an order stop. */
  readonly stopTracking?: Maybe<ReadonlyArray<StopTracking>>;
  readonly splitDimensions?: Maybe<Scalars['Boolean']>;
  /** Shipment Commodities on Commodity */
  readonly shipmentCommodities?: Maybe<ReadonlyArray<ShipmentCommodity>>;
  readonly commodityValue: LoadCurrency;
  readonly hazmatContactName?: Maybe<Scalars['String']>;
  readonly placardRequired?: Maybe<Scalars['Boolean']>;
  readonly flashPointTemperature?: Maybe<TemperatureV2>;
  readonly stops?: Maybe<ReadonlyArray<StopCommodityFromCommodity>>;
  readonly isSplit?: Maybe<Scalars['Boolean']>;
  readonly assignedExpectedWeight: Mass;
  readonly commodityId: Scalars['ID'];
  readonly stopValue: Scalars['String'];
};


export type AssignedRouteStopShipmentFlattenedCommodityExpectedWeightArgs = {
  unit: MassUnitTypeEnum;
};


export type AssignedRouteStopShipmentFlattenedCommodityActualWeightArgs = {
  unit: MassUnitTypeEnum;
};


export type AssignedRouteStopShipmentFlattenedCommodityExpectedWeightV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type AssignedRouteStopShipmentFlattenedCommodityActualWeightV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type AssignedRouteStopShipmentFlattenedCommodityPreCoolToV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type AssignedRouteStopShipmentFlattenedCommodityMinimumTemperatureV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type AssignedRouteStopShipmentFlattenedCommodityMaximumTemperatureV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type AssignedRouteStopShipmentFlattenedCommodityExpectedLinearArgs = {
  unit: UnitOfLengthEnum;
};


export type AssignedRouteStopShipmentFlattenedCommodityActualLinearArgs = {
  unit: UnitOfLengthEnum;
};


export type AssignedRouteStopShipmentFlattenedCommodityExpectedLinearV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type AssignedRouteStopShipmentFlattenedCommodityActualLinearV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type AssignedRouteStopShipmentFlattenedCommodityActualVolumeArgs = {
  unit: VolumeUnitTypeEnum;
};


export type AssignedRouteStopShipmentFlattenedCommodityExpectedVolumeArgs = {
  unit: VolumeUnitTypeEnum;
};


export type AssignedRouteStopShipmentFlattenedCommodityActualVolumeV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type AssignedRouteStopShipmentFlattenedCommodityExpectedVolumeV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type AssignedRouteStopShipmentFlattenedCommodityActualDensityV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type AssignedRouteStopShipmentFlattenedCommodityExpectedDensityV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type AssignedRouteStopShipmentFlattenedCommodityCommodityValueArgs = {
  unit?: InputMaybe<Scalars['String']>;
};


export type AssignedRouteStopShipmentFlattenedCommodityAssignedExpectedWeightArgs = {
  unit?: InputMaybe<Scalars['String']>;
};

export type AssignFacilityToCityStateStopInput = {
  readonly routeStopId: Scalars['ID'];
  readonly facilityId: Scalars['ID'];
};

export type AssignRolesToUserInput = {
  readonly userID: Scalars['String'];
  readonly roles?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type AssignRolesToUserInputV2 = {
  readonly email: Scalars['String'];
  readonly roles?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type AssignUsersToBidLanesInput = {
  readonly bidLaneRoundIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly userIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly selectAll?: InputMaybe<Scalars['Boolean']>;
  readonly bidLaneFilters?: InputMaybe<BidLanesInputFilter>;
};

export type AssignUsersToBidLanesPayload = {
  readonly __typename?: 'AssignUsersToBidLanesPayload';
  readonly statusCode?: Maybe<Scalars['Int']>;
  readonly error?: Maybe<MasterBidError>;
};

export type AssignUserToRootOrganizationInput = {
  readonly userID: Scalars['ID'];
};

export type AssignVendorToRouteInput = {
  readonly bookedById: Scalars['ID'];
  readonly bookedWithId?: InputMaybe<Scalars['ID']>;
  readonly bookingNotes?: InputMaybe<Scalars['String']>;
  readonly bracingType?: InputMaybe<BracingTypeEnum>;
  readonly createdById: Scalars['ID'];
  readonly distance?: InputMaybe<UnitOfDistanceInput>;
  readonly expectedEmptyGeographySource?: InputMaybe<Scalars['String']>;
  readonly expectedEmptyGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly numberOfBraces?: InputMaybe<Scalars['Int']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  readonly numberOfTarps?: InputMaybe<Scalars['Int']>;
  readonly palletType?: InputMaybe<Scalars['String']>;
  readonly routeId: Scalars['ID'];
  readonly routeVendorReps?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorRepInput>>;
  readonly tarpType?: InputMaybe<Scalars['String']>;
  readonly trailerHeight?: InputMaybe<UnitOfDistanceInput>;
  readonly trailerLength?: InputMaybe<UnitOfDistanceInput>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly trailerWidth?: InputMaybe<UnitOfDistanceInput>;
  readonly utcExpectedEmptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly utcPickupEtaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly vendorId: Scalars['ID'];
  readonly vendorType: RouteVendorTypeEnum;
};

/** Autogenerated return type of AssignVendorToRoute */
export type AssignVendorToRoutePayload = {
  readonly __typename?: 'AssignVendorToRoutePayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendor?: Maybe<RouteVendor>;
};

/** Input for creating a Route Vendor */
export type AssignVendorToRouteV2Input = {
  /** The ID of the Route that this Carrier / Vendor will be added to */
  readonly routeId: Scalars['ID'];
  /** The ID of the Carrier / Vendor that this Route Vendor represents */
  readonly vendorId: Scalars['ID'];
  /** The ID of the Employee who is assigning this Carrier / Vendor to the Route */
  readonly createdById: Scalars['ID'];
  /** The Employee ID of the Booking User */
  readonly bookedById: Scalars['ID'];
  /** ⚠️ **Deprecated! Use bookingSourceId** ⚠️ */
  readonly bookingSource?: InputMaybe<Scalars['String']>;
  /** The Booking mechanism used to Book the Route -- *Requires 'Route Booking Source Type' DDT* */
  readonly bookingSourceId?: InputMaybe<Scalars['ID']>;
  /** The CarrierContact ID of the Contact the Route was Booked with */
  readonly bookedWithId?: InputMaybe<Scalars['ID']>;
  /** General Notes to include with the Booking */
  readonly bookingNotes?: InputMaybe<Scalars['String']>;
  /** The Distance between the Driver's Expected Ready Location and the First Stop Location on the Route. */
  readonly distance?: InputMaybe<UnitOfDistanceInput>;
  /** The Division requesting the Booking */
  readonly executingDivisionId?: InputMaybe<Scalars['ID']>;
  /** ⚠️ **Deprecated! Use expectedEmptyGeographySourceId** ⚠️ */
  readonly expectedEmptyGeographySource?: InputMaybe<Scalars['String']>;
  /** The source ID from the Geography service */
  readonly expectedEmptyGeographySourceId?: InputMaybe<Scalars['String']>;
  /** The Expected Ready Location Information for the Carrier */
  readonly expectedEmptyLocation?: InputMaybe<ExpectedEmptyLocationInput>;
  /** This is set to *true* for Loads Booked through automated processes such as Mastermind API or Tendering */
  readonly isAutomated?: InputMaybe<Scalars['Boolean']>;
  /** This is set to *true* when the Carrier is Pre-Assigned to the Route. Carriers cannot be marked as *booked* when they are Pre-Assigned */
  readonly isPreAssigned?: InputMaybe<Scalars['Boolean']>;
  /** Braces the carrier is expected to have.  Multi-Select DDT */
  readonly braces?: InputMaybe<BracesInput>;
  /** The type of Bracing Equipment the Carrier is expected to have */
  readonly bracingType?: InputMaybe<BracingTypeEnum>;
  /** The number of Braces the Carrier is expected to have */
  readonly numberOfBraces?: InputMaybe<Scalars['Int']>;
  /** ⚠️ **Deprecated! Use palletTypeV2** ⚠️ */
  readonly palletType?: InputMaybe<PalletTypeEnum>;
  /** The type of Pallets the Carrier is expected to have */
  readonly palletTypeV2?: InputMaybe<Scalars['String']>;
  /** The number of Pallets the Carrier is expected to have */
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  /** ⚠️ **Deprecated! Use tarpTypeV2** ⚠️ */
  readonly tarpType?: InputMaybe<TarpTypeEnum>;
  /** The type of Tarp the Carrier is expected to have */
  readonly tarpTypeV2?: InputMaybe<Scalars['String']>;
  /** The number of Tarps the Carrier is expected to have */
  readonly numberOfTarps?: InputMaybe<Scalars['Int']>;
  /** ⚠️ **Deprecated! Use routeVendorRepsV2** ⚠️ */
  readonly routeVendorReps?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorRepV2Input>>;
  /** The Array of Route Vendor Reps to be Created, Updated, or Removed */
  readonly routeVendorRepsV2?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorRepV3Input>>;
  /** The Array of Route References to be Created, Updated, or Removed */
  readonly routeVendorReferencesV2?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorReferenceInputV2>>;
  /** ⚠️ **Deprecated! Use trailerHeightV2** ⚠️ */
  readonly trailerHeight?: InputMaybe<UnitOfDistanceInput>;
  /** The expected Height of the Carrier's Trailer */
  readonly trailerHeightV2?: InputMaybe<LengthInput>;
  /** ⚠️ **Deprecated! Use trailerLengthV2** ⚠️ */
  readonly trailerLength?: InputMaybe<UnitOfDistanceInput>;
  /** The expected Length of the Carrier's Trailer */
  readonly trailerLengthV2?: InputMaybe<LengthInput>;
  /** ⚠️ **Deprecated! Use trailerWidthV2** ⚠️ */
  readonly trailerWidth?: InputMaybe<UnitOfDistanceInput>;
  /** The expected Width of the Carrier's Trailer */
  readonly trailerWidthV2?: InputMaybe<LengthInput>;
  /** The expected Trailer Type of the Carrier -- *Requires 'Trailer Type' DDT* */
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  /** UTC Timestamp representing the Date & Time the Driver is expected to be ready prior to this Route */
  readonly utcExpectedEmptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** UTC Timestamp representing the estimated Date & Time the Driver is expected to arrive at the first Stop for pickup */
  readonly utcPickupEtaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** The type of Vendor that this Route Vendor represents (ex. Carrier, TONU, etc) */
  readonly vendorType: RouteVendorTypeEnum;
  /** Booking capacity information */
  readonly capacities?: InputMaybe<ReadonlyArray<BookingCapacityInput>>;
};

export type AssignVendorToRouteV2Payload = {
  readonly __typename?: 'AssignVendorToRouteV2Payload';
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendor?: Maybe<RouteVendorV2>;
  /** Booking capacity information */
  readonly capacities?: Maybe<ReadonlyArray<BookingCapacityInfo>>;
};

export type AssociateBillableStops = {
  readonly __typename?: 'AssociateBillableStops';
  readonly routeStops: ReadonlyArray<AssociateLoadBillableStop>;
};

export type AssociateBillableStopsError = {
  readonly __typename?: 'AssociateBillableStopsError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly serviceError?: Maybe<Scalars['JSON']>;
};

export type AssociatedOrderStops = {
  readonly __typename?: 'AssociatedOrderStops';
  readonly routeStops: ReadonlyArray<RouteStop>;
  readonly routeStopsV2: ReadonlyArray<RouteStopV2>;
};

export type AssociatedOrderStopsError = {
  readonly __typename?: 'AssociatedOrderStopsError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly serviceError?: Maybe<Scalars['JSON']>;
};

export type AssociatedShipmentIdWithLoadIdInput = {
  readonly loadId: Scalars['ID'];
  readonly shipmentId: Scalars['String'];
  readonly customerCode: Scalars['String'];
};

export type AssociateLoadBillableStop = {
  readonly __typename?: 'AssociateLoadBillableStop';
  readonly routeStopId: Scalars['ID'];
  readonly isBillableStop?: Maybe<Scalars['Boolean']>;
};

export type AssociateLoadBillableStopsInput = {
  /** loadStopId is deprecated, use routeStopId instead */
  readonly loadStopId?: InputMaybe<Scalars['String']>;
  readonly routeStopId: Scalars['String'];
  readonly isBillableStop: Scalars['Boolean'];
};

export type AssociateLoadBillableStopsPayload = {
  readonly __typename?: 'AssociateLoadBillableStopsPayload';
  readonly errors: ReadonlyArray<AssociateBillableStopsError>;
  readonly associateBillableStops?: Maybe<AssociateBillableStops>;
};

export type AssociateLoadOrderStopInput = {
  readonly loadStopId: Scalars['String'];
  readonly isOrderStop: Scalars['Boolean'];
};

export type AssociateOrderStopInput = {
  readonly routeStopId: Scalars['String'];
  readonly isOrderStop: Scalars['Boolean'];
};

export type AssociateOrderStopsPayload = {
  readonly __typename?: 'AssociateOrderStopsPayload';
  readonly errors: ReadonlyArray<AssociatedOrderStopsError>;
  readonly associatedOrderStops?: Maybe<AssociatedOrderStops>;
};

export type AssociatePayableStop = {
  readonly routeStopId: Scalars['String'];
  readonly isPayableStop: Scalars['Boolean'];
};

export type AssociatePayableStopsInput = {
  readonly routId: Scalars['String'];
  readonly loadId: Scalars['String'];
  readonly associatePayableStops: ReadonlyArray<AssociatePayableStop>;
  readonly overriddenTotalLoadedDistance?: InputMaybe<UnitOfLengthInput>;
  readonly overriddenTotalDistance?: InputMaybe<UnitOfLengthInput>;
};

export type AssociatePayableStopsPayload = {
  readonly __typename?: 'AssociatePayableStopsPayload';
  readonly errors: ReadonlyArray<RoutePayableMileageError>;
  readonly routePayableMileage?: Maybe<RoutePayableMileage>;
};

export enum AttributeDataTypeEnum {
  Unknown = 'UNKNOWN',
  Number = 'NUMBER',
  String = 'STRING',
  Decimal = 'DECIMAL'
}

export type AttributeDefinitionModel = {
  readonly __typename?: 'AttributeDefinitionModel';
  readonly id: Scalars['ID'];
  readonly optionId: Scalars['String'];
  readonly dataType: AttributeDataTypeEnum;
  readonly minimum?: Maybe<Scalars['Decimal']>;
  readonly maximum?: Maybe<Scalars['Decimal']>;
  readonly size?: Maybe<Scalars['Int']>;
  readonly hasPickList: Scalars['Boolean'];
  readonly selections?: Maybe<ReadonlyArray<AttributeSelectionModel>>;
  readonly defaultValue?: Maybe<Scalars['String']>;
  readonly isDisabled: Scalars['Boolean'];
  readonly inactiveDate?: Maybe<Scalars['DateTime']>;
  readonly createdById: Scalars['ID'];
  readonly createdByName: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly updatedById: Scalars['ID'];
  readonly updatedByName: Scalars['String'];
  readonly updatedOn: Scalars['DateTime'];
};

export type Attributes = {
  readonly __typename?: 'Attributes';
  readonly mobile?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly customer_facing?: Maybe<ReadonlyArray<Maybe<Scalars['Boolean']>>>;
  readonly tenantIdNonProd?: Maybe<Scalars['String']>;
  readonly tenantIdProd?: Maybe<Scalars['String']>;
};

export type AttributeSelectionModel = {
  readonly __typename?: 'AttributeSelectionModel';
  readonly id: Scalars['ID'];
  readonly value: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly isDisabled: Scalars['Boolean'];
  readonly inactiveDate?: Maybe<Scalars['DateTime']>;
  readonly createdById: Scalars['ID'];
  readonly createdByName: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly updatedById: Scalars['ID'];
  readonly updatedByName: Scalars['String'];
  readonly updatedOn: Scalars['DateTime'];
};

export type AttributesInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly groupId?: InputMaybe<Scalars['ID']>;
  readonly attributeCategory?: InputMaybe<Scalars['String']>;
  readonly attributeName?: InputMaybe<Scalars['String']>;
  readonly attributeDataType?: InputMaybe<Scalars['String']>;
  /** A collection of values associated with the group-attribute. */
  readonly attributeValues?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type AttributeValues = {
  readonly __typename?: 'AttributeValues';
  readonly attributeId?: Maybe<Scalars['ID']>;
  readonly attributeValue?: Maybe<Scalars['String']>;
};

export enum AuditAction {
  FallbackAdded = 'FALLBACK_ADDED',
  FallbackRemoved = 'FALLBACK_REMOVED',
  MappedOptionAdded = 'MAPPED_OPTION_ADDED',
  MappedOptionDeleted = 'MAPPED_OPTION_DELETED',
  MappedOptionUpdated = 'MAPPED_OPTION_UPDATED',
  OptionDisabled = 'OPTION_DISABLED',
  OptionEnabled = 'OPTION_ENABLED'
}

/** An edge in audit connection. */
export type AuditEdgeV2 = {
  readonly __typename?: 'AuditEdgeV2';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: AuditV2;
};

export type AuditEmployeeV2 = {
  readonly __typename?: 'AuditEmployeeV2';
  readonly firstName: Scalars['String'];
  readonly firstNameDotLastName: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly lastName: Scalars['String'];
};

export type AuditEventOutput = {
  readonly __typename?: 'AuditEventOutput';
  readonly subEntityId: Scalars['ID'];
  readonly subEntityType: Scalars['String'];
  readonly entityFieldName: Scalars['String'];
  readonly displayFieldName: Scalars['String'];
  readonly eventAction: Scalars['String'];
  readonly previousValue: Scalars['String'];
  readonly updatedValue: Scalars['String'];
};

/** Audit History. */
export type AuditHistory = {
  readonly __typename?: 'AuditHistory';
  readonly modelType?: Maybe<Scalars['String']>;
  readonly changeType?: Maybe<Scalars['String']>;
  readonly recordId?: Maybe<Scalars['String']>;
  readonly recordName?: Maybe<Scalars['String']>;
  readonly timestamp?: Maybe<Scalars['String']>;
  readonly oldValue?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly identity?: Maybe<Scalars['String']>;
  readonly changes?: Maybe<ReadonlyArray<Change>>;
};

/** ProviderId query object model */
export type AuditHistoryQuery = {
  readonly __typename?: 'AuditHistoryQuery';
  readonly _id: Scalars['String'];
  readonly provider: Scalars['String'];
  readonly _ts: Scalars['String'];
  readonly result: Scalars['Boolean'];
};

export type AuditLogs = {
  readonly __typename?: 'AuditLogs';
  readonly id: Scalars['ID'];
  readonly tenantId: Scalars['ID'];
  readonly auditEvent: Scalars['String'];
  readonly objectReferenceId: Scalars['ID'];
  readonly auditDetails: Scalars['String'];
  readonly auditDate: Scalars['DateTime'];
  readonly initiatedBy: Scalars['String'];
};

export type AuditLogsInput = {
  readonly fromDate: Scalars['DateTime'];
  readonly toDate: Scalars['DateTime'];
  readonly tenantId: Scalars['String'];
};

/** The connection type for Audits. */
export type Audits = {
  readonly __typename?: 'Audits';
  /** A list of edges. */
  readonly edges: ReadonlyArray<AuditEdgeV2>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type AuditsFilterV2 = {
  readonly startDate: Scalars['String'];
  readonly endDate: Scalars['String'];
  readonly carrierId: Scalars['String'];
};

export type AuditTypeInterface = {
  readonly actionType: Scalars['String'];
  readonly auditType: Scalars['String'];
  readonly auditableId: Scalars['ID'];
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByEmployee?: Maybe<Employee>;
  readonly id: Scalars['ID'];
};

export type AuditTypeInterfaceV2 = {
  readonly actionType: Scalars['String'];
  readonly auditType: Scalars['String'];
  readonly auditableId: Scalars['ID'];
  readonly createdByEmployee?: Maybe<EmployeeV2>;
  readonly id: Scalars['ID'];
  readonly userId?: Maybe<Scalars['ID']>;
};

/** Objects which may be audited */
export type AuditTypeUnion = CarrierAudit | CarrierAuditsExternalStatus | CarrierRepAudit;

/** Objects which may be audited */
export type AuditTypeUnionV2 = CarrierAuditV2 | CarrierAuditsExternalStatusV2 | CarrierRepAuditV2 | CarrierIdentifierAuditV2;

export type AuditV2 = {
  readonly __typename?: 'AuditV2';
  readonly id: Scalars['ID'];
  readonly auditableId?: Maybe<Scalars['ID']>;
  readonly associated_id?: Maybe<Scalars['ID']>;
  readonly associatedType?: Maybe<Scalars['String']>;
  readonly userId?: Maybe<Scalars['ID']>;
  readonly userType?: Maybe<Scalars['String']>;
  readonly auditableTab?: Maybe<Scalars['String']>;
  readonly auditCategory?: Maybe<Scalars['String']>;
  readonly remoteAddress?: Maybe<Scalars['String']>;
  readonly requestUuid?: Maybe<Scalars['String']>;
  readonly version?: Maybe<Scalars['Int']>;
  readonly comment?: Maybe<Scalars['String']>;
  readonly auditDetails?: Maybe<ReadonlyArray<CarrierAuditDetails>>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly user?: Maybe<UserV2>;
  readonly actionWithFieldName?: Maybe<Scalars['String']>;
  readonly previousValues?: Maybe<Scalars['String']>;
  readonly updatedValues?: Maybe<Scalars['String']>;
};

export type AuthorizedDivision = {
  readonly __typename?: 'AuthorizedDivision';
  readonly id?: Maybe<Scalars['ID']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
};

export type AutoApplyEligiblePayload = {
  readonly __typename?: 'AutoApplyEligiblePayload';
  readonly isEligible: Scalars['Boolean'];
};

export type AutoAssociationUser = {
  readonly __typename?: 'AutoAssociationUser';
  readonly userId?: Maybe<Scalars['ID']>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly User?: Maybe<User>;
  readonly CreatedByUser?: Maybe<User>;
  readonly UserV2?: Maybe<UserV2>;
  readonly CreatedByUserV2?: Maybe<UserV2>;
};

export type AutoAssociationUtilityParamsInput = {
  readonly loadIds: ReadonlyArray<InputMaybe<Scalars['ID']>>;
  readonly documentTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly autoAssociateCustomerPaperwork: Scalars['Boolean'];
  readonly autoAssociateVendorPaperwork: Scalars['Boolean'];
  readonly batchSize?: InputMaybe<Scalars['Int']>;
};

export type AutocompletionRequest = {
  readonly address: Scalars['String'];
  readonly country?: InputMaybe<Scalars['String']>;
  readonly geocodeRequest?: InputMaybe<Scalars['Boolean']>;
};

export type AutoEstimate = {
  readonly __typename?: 'AutoEstimate';
  /** ID of the stop the stop event is tied to */
  readonly stopId: Scalars['ID'];
  /** Source of the auto estimate */
  readonly source: Scalars['ID'];
  /** ID of the route the stop/stop event is tied to */
  readonly routeId?: Maybe<Scalars['ID']>;
  /** ID of the load the route/stop/stop event is tied to */
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly arrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly departureDatetime?: Maybe<Scalars['DateTime']>;
  readonly projectedDriverHos?: Maybe<DriverProjectedHos>;
};

export type AutomationPayload = {
  readonly __typename?: 'AutomationPayload';
  readonly autoRateConStarted: Scalars['Boolean'];
};

export type AutoRateConBolInput = {
  readonly loadId: Scalars['ID'];
  readonly eventType: DocumentEventType;
  readonly documentType: DocumentType;
  readonly typeDDTId: Scalars['String'];
  readonly source: Scalars['String'];
};

export type AutoRateConCostLineInput = {
  readonly routeVendorId: Scalars['ID'];
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly eventType: CostLineEventType;
  readonly updatedOn?: InputMaybe<Scalars['DateTime']>;
};

export type AutoRateConStopAppointmentInput = {
  readonly availableStartDate?: InputMaybe<Scalars['Float']>;
  readonly availableStartTime?: InputMaybe<AutoRateConStopAppointmentTimeIntervalInput>;
  readonly availableStartTimezone?: InputMaybe<Scalars['String']>;
  readonly availableEndDate?: InputMaybe<Scalars['Float']>;
  readonly availableEndTime?: InputMaybe<AutoRateConStopAppointmentTimeIntervalInput>;
  readonly availableEndTimezone?: InputMaybe<Scalars['String']>;
  readonly apptConfBy?: InputMaybe<Scalars['ID']>;
  readonly apptReqWith?: InputMaybe<Scalars['ID']>;
};

export type AutoRateConStopAppointmentTimeIntervalInput = {
  readonly startInMilliseconds: Scalars['Int'];
  readonly endInMilliseconds: Scalars['Int'];
};

export enum AutoRateConStopEventType {
  StopCreated = 'STOP_CREATED',
  StopUpdated = 'STOP_UPDATED',
  StopDeleted = 'STOP_DELETED',
  FacilityUpdated = 'FACILITY_UPDATED',
  CityStateUpdated = 'CITY_STATE_UPDATED',
  FacilityAssigned = 'FACILITY_ASSIGNED'
}

export type AutoRateConStopInput = {
  readonly eventType: AutoRateConStopEventType;
  readonly stopId: Scalars['ID'];
  readonly stopType?: InputMaybe<Scalars['String']>;
  readonly routeId: Scalars['ID'];
  readonly references?: InputMaybe<ReadonlyArray<AutoRateConStopReferenceInput>>;
  readonly scheduleAppointmentType?: InputMaybe<Scalars['String']>;
  readonly appointment?: InputMaybe<AutoRateConStopAppointmentInput>;
};

export type AutoRateConStopReferenceInput = {
  readonly type: Scalars['String'];
  readonly value: Scalars['String'];
};

export type AutoRateConTaskInput = {
  readonly routeId: Scalars['ID'];
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly taskName: Scalars['String'];
  readonly taskStatus: Scalars['String'];
};

export type AutoRateConTrailerExecutionInput = {
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly stopId: Scalars['ID'];
  readonly eventType: TrailerExecutionEventType;
  readonly stopEventType: Scalars['String'];
  readonly loadStopType?: InputMaybe<Scalars['String']>;
  readonly isOverThreshold: Scalars['Boolean'];
  readonly distanceOutOfRoute: Scalars['Float'];
};

export type AutoVoucherBatchInput = {
  /** Should adjustments be created? */
  readonly createAdjustments: Scalars['Boolean'];
  /** Process with financials Enabled, Disabled, or Both */
  readonly processFinancials?: InputMaybe<ProcessFinancialsEnum>;
};

export type AutoVoucherBatchInputV2 = {
  /** Should adjustments be created? */
  readonly createAdjustments: Scalars['Boolean'];
};

export type AvailableCapacityConstraint = {
  readonly __typename?: 'AvailableCapacityConstraint';
  readonly capacityConstraint?: Maybe<Scalars['Int']>;
  readonly capacityUtilized?: Maybe<Scalars['Int']>;
  readonly customerCapacityAvailable?: Maybe<Scalars['Int']>;
  readonly orderId?: Maybe<Scalars['ID']>;
  readonly capacityConstraintFrequency?: Maybe<Scalars['String']>;
  readonly dayOfWeek?: Maybe<Scalars['String']>;
};

/** Available Container */
export type AvailableContainer = {
  readonly __typename?: 'AvailableContainer';
  readonly id: Scalars['ID'];
  readonly trailerId?: Maybe<Scalars['ID']>;
  readonly carrierId: Scalars['String'];
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly carrierServices?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierStatus?: Maybe<Scalars['String']>;
  readonly carrierTypeId?: Maybe<Scalars['String']>;
  readonly reservationNumber?: Maybe<Scalars['String']>;
  readonly reservationType?: Maybe<Scalars['String']>;
  readonly containerType: Scalars['String'];
  readonly containerNumber?: Maybe<Scalars['String']>;
  readonly containerLength: Length;
  readonly containerProgram?: Maybe<Scalars['String']>;
  readonly ownerId?: Maybe<Scalars['String']>;
  readonly originCity: Scalars['String'];
  readonly originState: Scalars['String'];
  readonly originRampFacilityId?: Maybe<Scalars['String']>;
  readonly originRampFacilityName?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly destinationRampFacilityId?: Maybe<Scalars['String']>;
  readonly destinationRampFacilityName?: Maybe<Scalars['String']>;
  readonly previousRouteId?: Maybe<Scalars['String']>;
  readonly previousRouteNumber?: Maybe<Scalars['String']>;
  readonly previousRailroadCarrierId?: Maybe<Scalars['String']>;
  readonly previousRailroadCarrierName?: Maybe<Scalars['String']>;
  readonly previousDraymanCarrierId?: Maybe<Scalars['String']>;
  readonly previousDraymanCarrierName?: Maybe<Scalars['String']>;
  readonly dateRange: AvailableDateRange;
  readonly startDay?: Maybe<Scalars['String']>;
  /** Date and timezone for container start date */
  readonly startDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `startDateZone` */
  readonly startDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `startDateZone` */
  readonly startTimeZone?: Maybe<Scalars['String']>;
  readonly endDay?: Maybe<Scalars['String']>;
  /** Date and timezone for container end date */
  readonly endDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `endDateZone` */
  readonly endDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `endDateZone` */
  readonly endTimeZone?: Maybe<Scalars['String']>;
  readonly sourceTypeId: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdByUserId: Scalars['String'];
  readonly createdByUserName?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedByUserId: Scalars['String'];
  readonly updatedByUserName?: Maybe<Scalars['String']>;
  readonly operatingStatusId?: Maybe<Scalars['String']>;
  readonly serviceStatusId?: Maybe<Scalars['String']>;
  readonly mainCarrierRepUserName?: Maybe<Scalars['String']>;
  readonly capacityUtilizationStatusId: Scalars['String'];
  readonly actualODH?: Maybe<Length>;
  readonly previousRouteV2?: Maybe<LoadRouteV2>;
  readonly assetTrailer?: Maybe<AssetTrailers>;
  readonly ownerV2?: Maybe<CarrierV2>;
};


/** Available Container */
export type AvailableContainerActualOdhArgs = {
  fromPoint?: InputMaybe<GeoPointInput>;
};

export type AvailableContainerConnection = {
  readonly __typename?: 'AvailableContainerConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<AvailableContainerEdge>;
  readonly totalCount: Scalars['Float'];
};

export type AvailableContainerEdge = {
  readonly __typename?: 'AvailableContainerEdge';
  readonly node: AvailableContainer;
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String'];
};

export type AvailableContainerFilter = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly containerId?: InputMaybe<Scalars['String']>;
  readonly containerIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trailerIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierServices?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly containerNumber?: InputMaybe<Scalars['String']>;
  readonly reservationNumber?: InputMaybe<Scalars['String']>;
  readonly containerProgram?: InputMaybe<Scalars['String']>;
  readonly ownerIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRampFacilityName?: InputMaybe<Scalars['String']>;
  readonly destinationRampFacilityName?: InputMaybe<Scalars['String']>;
  readonly originCity?: InputMaybe<Scalars['String']>;
  readonly originStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationCity?: InputMaybe<Scalars['String']>;
  readonly destinationStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly previousRailroadCarrierName?: InputMaybe<Scalars['String']>;
  readonly previousDraymanCarrierName?: InputMaybe<Scalars['String']>;
  readonly previousRouteNumber?: InputMaybe<Scalars['String']>;
  readonly startDay?: InputMaybe<Scalars['String']>;
  readonly endDay?: InputMaybe<Scalars['String']>;
  readonly containerPrograms?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly sourceTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly containerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly reservationTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly enteredByRepEmployeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainCarrierRepEmployeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly previousDraymanCarrierIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly previousRailroadCarrierIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly utcInclusiveDateRange?: InputMaybe<DateRangeInput>;
  readonly originRadius?: InputMaybe<WithinRadiusInput>;
  readonly originRampFacilityRadius?: InputMaybe<WithinRadiusInput>;
  readonly destinationRampFacilityRadius?: InputMaybe<WithinRadiusInput>;
  readonly includeNullDestination?: InputMaybe<Scalars['Boolean']>;
  readonly operatingStatusIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly serviceStatusIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly capacityUtilizationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly createdByUserName?: InputMaybe<Scalars['String']>;
};

export type AvailableDateRange = {
  readonly __typename?: 'AvailableDateRange';
  readonly gte: Scalars['Timestamp'];
  readonly lte: Scalars['Timestamp'];
};

export type AvailableDriverScheduleOutput = {
  readonly __typename?: 'AvailableDriverScheduleOutput';
  readonly id: Scalars['ID'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly hours?: Maybe<HoursOutput>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly date?: Maybe<Scalars['Date']>;
};

/** Available GateReservation */
export type AvailableGateReservation = {
  readonly __typename?: 'AvailableGateReservation';
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['String'];
  readonly carrierName: Scalars['String'];
  readonly carrierServices?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierStatus?: Maybe<Scalars['String']>;
  readonly carrierTypeId?: Maybe<Scalars['String']>;
  readonly containerType: Scalars['String'];
  readonly containerLength?: Maybe<Length>;
  readonly containerProgram?: Maybe<Scalars['String']>;
  readonly reservationNumber: Scalars['String'];
  readonly originRampFacilityId: Scalars['String'];
  readonly originRampFacilityName?: Maybe<Scalars['String']>;
  readonly originCity: Scalars['String'];
  readonly originState: Scalars['String'];
  readonly destinationRampFacilityId?: Maybe<Scalars['String']>;
  readonly destinationRampFacilityName?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly dateRange: AvailableDateRange;
  readonly startDay?: Maybe<Scalars['String']>;
  /** Date and timezone for gate reservation start date */
  readonly startDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `startDateZone` */
  readonly startDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `startDateZone` */
  readonly startTimeZone?: Maybe<Scalars['String']>;
  readonly endDay?: Maybe<Scalars['String']>;
  /** Date and timezone for gate reservation end date */
  readonly endDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `endDateZone` */
  readonly endDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `endDateZone` */
  readonly endTimeZone?: Maybe<Scalars['String']>;
  readonly sourceTypeId?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly createdByUserId: Scalars['String'];
  readonly createdByUserName: Scalars['String'];
  readonly updatedByUserId: Scalars['String'];
  readonly updatedByUserName: Scalars['String'];
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly mainCarrierRepUserName?: Maybe<Scalars['String']>;
  readonly capacityUtilizationStatusId: Scalars['String'];
};

export type AvailableGateReservationConnection = {
  readonly __typename?: 'AvailableGateReservationConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<AvailableGateReservationEdge>;
  readonly totalCount: Scalars['Float'];
};

export type AvailableGateReservationEdge = {
  readonly __typename?: 'AvailableGateReservationEdge';
  readonly node: AvailableGateReservation;
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String'];
};

export type AvailableGateReservationFilter = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly gateReservationIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly utcInclusiveDateRange?: InputMaybe<DateRangeInput>;
  readonly startDay?: InputMaybe<Scalars['String']>;
  readonly endDay?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierServices?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly reservationNumber?: InputMaybe<Scalars['String']>;
  readonly containerProgram?: InputMaybe<Scalars['String']>;
  readonly containerPrograms?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly sourceTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly containerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: InputMaybe<Scalars['String']>;
  readonly originStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly enteredByRepEmployeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainCarrierRepEmployeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRampFacilityRadius?: InputMaybe<WithinRadiusInput>;
  readonly destinationRampFacilityRadius?: InputMaybe<WithinRadiusInput>;
  readonly includeNullDestination?: InputMaybe<Scalars['Boolean']>;
  readonly destinationCity?: InputMaybe<Scalars['String']>;
  readonly destinationStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly capacityUtilizationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly createdByUserName?: InputMaybe<Scalars['String']>;
};

/** Available Route */
export type AvailableRoute = {
  readonly __typename?: 'AvailableRoute';
  readonly id: Scalars['ID'];
  readonly postings?: Maybe<ReadonlyArray<Maybe<Posting>>>;
  readonly routeNumber: Scalars['String'];
  readonly loadId: Scalars['String'];
  readonly loadNumber: Scalars['String'];
  /** MASTERY, EXTERNAL */
  readonly type: RouteType;
  /** OTR, IMDL LH, Dray, etc. */
  readonly routeTypeId?: Maybe<Scalars['String']>;
  /** Truck, Rail, IMDL, etc. */
  readonly transportModeId?: Maybe<Scalars['String']>;
  /** FTL, LTL, Partial */
  readonly shipmentSizeId?: Maybe<Scalars['String']>;
  readonly capacityNeed?: Maybe<ReadonlyArray<CapacityNeed>>;
  readonly customerName: Scalars['String'];
  readonly customerId?: Maybe<Scalars['String']>;
  readonly customerGroups?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly customer?: Maybe<Customer>;
  readonly commodities: Scalars['String'];
  readonly pickup: RouteStopInfo;
  readonly delivery: RouteStopInfo;
  readonly stops?: Maybe<ReadonlyArray<RouteStopInfo>>;
  readonly routeLength?: Maybe<Length>;
  readonly trailerSize?: Maybe<Dimensions>;
  readonly requiredTruckTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly status: Scalars['String'];
  readonly stopCount?: Maybe<Scalars['Int']>;
  readonly expectedMaxWeight?: Maybe<Mass>;
  readonly commodityMinTemperature?: Maybe<TemperatureV2>;
  readonly outboundRegionUrn?: Maybe<Scalars['String']>;
  readonly inboundRegionUrn?: Maybe<Scalars['String']>;
  readonly createdAtDate?: Maybe<Scalars['DateTime']>;
  readonly updatedAtDate?: Maybe<Scalars['DateTime']>;
  readonly segmentCode?: Maybe<Scalars['String']>;
  readonly requirements?: Maybe<ReadonlyArray<OrderRequirementInfo>>;
  readonly hotRoute?: Maybe<Scalars['Boolean']>;
  readonly team: Scalars['Boolean'];
  readonly hazmat: Scalars['Boolean'];
  readonly loadRouteSequence?: Maybe<Fraction>;
  readonly externalSourceUrl?: Maybe<Scalars['String']>;
  readonly externalRouteMaxCost?: Maybe<Currency>;
  readonly allStopsHaveRequestedTime?: Maybe<Scalars['Boolean']>;
  readonly routeLifecycleStatus?: Maybe<Scalars['String']>;
  readonly customerOrderReps?: Maybe<ReadonlyArray<AvailableRouteRep>>;
  readonly assignedReps?: Maybe<ReadonlyArray<AvailableRouteRep>>;
  readonly appointmentStatus?: Maybe<Scalars['String']>;
  readonly quoteTypeId?: Maybe<Scalars['String']>;
  readonly activeRouteVendorCarrierId?: Maybe<Scalars['String']>;
  readonly routeDivisions?: Maybe<ReadonlyArray<AvailableRouteDivision>>;
  readonly offers?: Maybe<ReadonlyArray<RouteOffer>>;
  readonly offerCount?: Maybe<Scalars['Int']>;
  readonly routePostings?: Maybe<ReadonlyArray<RoutePosting>>;
  readonly isPosted?: Maybe<Scalars['Boolean']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly loadMoneySummary?: Maybe<LoadMoneySummary>;
  readonly loadRouteV2?: Maybe<LoadRouteV2>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly maxCost?: Maybe<RouteMaxCostType>;
  readonly lock?: Maybe<Lock>;
  readonly routeReferences?: Maybe<ReadonlyArray<AvailableRouteReference>>;
  readonly orderReferences?: Maybe<ReadonlyArray<AvailableRouteReference>>;
  /** @deprecated Use `externalRouteMaxCost` */
  readonly maxCostValue?: Maybe<Scalars['Float']>;
  /** @deprecated Use 'routeDivisions' */
  readonly division?: Maybe<Scalars['String']>;
  /** @deprecated customerOrderReps */
  readonly customerRepName?: Maybe<Scalars['String']>;
  /** @deprecated Use Federation for notes */
  readonly notes?: Maybe<Scalars['String']>;
  /** @deprecated Use Federation for cargo information */
  readonly cargoValueCurrency?: Maybe<Currency>;
  /** @deprecated Use Federation for cargo information */
  readonly cargoWeight?: Maybe<Mass>;
  readonly distanceToNextRoute?: Maybe<Scalars['Int']>;
};

export type AvailableRouteConnection = {
  readonly __typename?: 'AvailableRouteConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<AvailableRouteEdge>;
  readonly totalCount: Scalars['Float'];
};

/** A semantic sub-group of route data to ingest */
export enum AvailableRouteDataViewInput {
  Order = 'ORDER',
  GeneralInfo = 'GENERAL_INFO',
  Stops = 'STOPS',
  TrackingInfo = 'TRACKING_INFO',
  BookingInfo = 'BOOKING_INFO',
  Offers = 'OFFERS',
  Postings = 'POSTINGS'
}

export type AvailableRouteDivision = {
  readonly __typename?: 'AvailableRouteDivision';
  readonly id?: Maybe<Scalars['String']>;
  readonly isPrimary: Scalars['Boolean'];
  readonly routeId?: Maybe<Scalars['String']>;
};

export type AvailableRouteEdge = {
  readonly __typename?: 'AvailableRouteEdge';
  readonly node: AvailableRoute;
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String'];
};

export type AvailableRouteLengthInput = {
  readonly unit: UnitOfLengthEnum;
  readonly value: Scalars['Float'];
};

export type AvailableRouteReference = {
  readonly __typename?: 'AvailableRouteReference';
  readonly id: Scalars['String'];
  readonly value: Scalars['String'];
  /** DDT Option ID */
  readonly typeId: Scalars['String'];
  /** Order | Route | Stop */
  readonly referenceType: Scalars['String'];
};

export type AvailableRouteRep = {
  readonly __typename?: 'AvailableRouteRep';
  readonly main: Scalars['Boolean'];
  readonly employeeName: Scalars['String'];
  readonly employeeId: Scalars['String'];
};

export type AvailableRoutesFilter = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly routeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadNumber?: InputMaybe<Scalars['String']>;
  readonly routeNumber?: InputMaybe<Scalars['String']>;
  readonly capacityNeed?: InputMaybe<ReadonlyArray<CapacityNeed>>;
  readonly customer?: InputMaybe<Scalars['String']>;
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerEmployeeGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerRep?: InputMaybe<Scalars['String']>;
  readonly customerRepIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedRepIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly pickupCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly pickupStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly pickupState?: InputMaybe<Scalars['String']>;
  readonly pickupCity?: InputMaybe<Scalars['String']>;
  readonly firstStopRegionCompositeKeys?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly lastStopRegionCompositeKeys?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly deliveryCity?: InputMaybe<Scalars['String']>;
  readonly deliveryState?: InputMaybe<Scalars['String']>;
  readonly deliveryStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly deliveryCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly firstStopRadius?: InputMaybe<WithinRadiusInput>;
  readonly lastStopRadius?: InputMaybe<WithinRadiusInput>;
  readonly requiredTruckTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly timeZone?: InputMaybe<Scalars['String']>;
  readonly stopCount?: InputMaybe<Scalars['Int']>;
  readonly routeActivationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly utcInclusivePickupDateRange?: InputMaybe<DateRangeInput>;
  readonly utcInclusiveDeliveryDateRange?: InputMaybe<DateRangeInput>;
  /** Returns routes with pickup and delivery procurementAppointmentDate within the date range */
  readonly allStopsWithinDateRange?: InputMaybe<DateRangeInput>;
  readonly firstStopLiveType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly lastStopLiveType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  /** Filter Requirements in OR statements with Hot Route (used for flags column filtering) */
  readonly flagsRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Filter just requirements */
  readonly routeRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
  readonly routeType?: InputMaybe<RouteType>;
  /** Returns routes where primary or additional divisions includes the input terms */
  readonly divisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns routes where primary division is null or includes the input terms */
  readonly divisionsIncludeNull?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns routes where additional divisions includes the input terms */
  readonly additionalDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns routes where the employee's primary divsion matches a route's primary or additional divisions */
  readonly primaryEmployeeDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns routes where the employee's additional divsions match a route's primary division */
  readonly additionalEmployeeDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly intrastateAdminAreas?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly firstStopFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly lastStopFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly transportModeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly shipmentSizeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLifecycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly appointmentStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly quoteTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLengthRange?: InputMaybe<LengthRangeInput>;
  readonly trailerLengthRange?: InputMaybe<LengthRangeInput>;
  readonly commodityMinTemperatureRange?: InputMaybe<TemperatureRangeInput>;
  readonly commodityMinTemperatureRangeIncludeNull?: InputMaybe<TemperatureRangeInput>;
  readonly expectedMaxWeightRange?: InputMaybe<MassRangeInput>;
  readonly stopCountRange?: InputMaybe<ScalarRangeInput>;
  readonly isPosted?: InputMaybe<Scalars['Boolean']>;
  readonly organizationHierarchyCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly m2mJwtHiearchyCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly activeRouteVendorCarrierIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly requirements?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly firstStopRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundSubRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly lastStopRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly inboundRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly inboundSubRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly pickupFreightAssignmentSuperRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly pickupFreightAssignmentRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly pickupFreightAssignmentSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly deliveryFreightAssignmentSuperRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly deliveryFreightAssignmentRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly deliveryFreightAssignmentSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly withinRadius?: InputMaybe<UnitOfLengthInput>;
  readonly radiusGeoPoint?: InputMaybe<GeoPointInput>;
  readonly deliveryRadius?: InputMaybe<UnitOfLengthInput>;
  readonly deliveryRadiusGeoPoint?: InputMaybe<GeoPointInput>;
  readonly commodityMinTemperature?: InputMaybe<TemperatureV2Input>;
  readonly trailerLength?: InputMaybe<AvailableRouteLengthInput>;
  readonly utcPickupStartDateTime?: InputMaybe<Scalars['Timestamp']>;
  readonly utcPickupEndDateTime?: InputMaybe<Scalars['Timestamp']>;
  readonly utcDeliveryStartDateTime?: InputMaybe<Scalars['Timestamp']>;
  readonly utcDeliveryEndDateTime?: InputMaybe<Scalars['Timestamp']>;
};

/** Available Truck */
export type AvailableTruck = {
  readonly __typename?: 'AvailableTruck';
  readonly truckPostingId: Scalars['ID'];
  readonly carrierId: Scalars['String'];
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly carrierTypeId?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly originCountry?: Maybe<Scalars['String']>;
  readonly originGeoPoint?: Maybe<Point>;
  readonly readyByDay?: Maybe<Scalars['String']>;
  /** Date and timezone for truck ready by date */
  readonly readyByDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `readyByDateZone` */
  readonly readyByDate: Scalars['DateTime'];
  /** @deprecated Use `readyByDateZone` */
  readonly readyByTimeZone?: Maybe<Scalars['String']>;
  readonly loadByDay?: Maybe<Scalars['String']>;
  /** Date and timezone for truck load by date */
  readonly loadByDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `loadByDateZone` */
  readonly loadByDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `loadByDateZone` */
  readonly loadByTimeZone?: Maybe<Scalars['String']>;
  readonly finalByDay?: Maybe<Scalars['String']>;
  /** Date and timezone for truck final by date */
  readonly finalByDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `finalByDateZone` */
  readonly finalByDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `finalByDateZone` */
  readonly finalByTimeZone?: Maybe<Scalars['String']>;
  readonly equipmentType?: Maybe<Scalars['String']>;
  readonly trailerLength?: Maybe<Length>;
  readonly ddh?: Maybe<Length>;
  readonly odh?: Maybe<Length>;
  readonly destinationStates?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationAdminAreas?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationCountries?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly destinationGeoPoint?: Maybe<Point>;
  readonly destinationSourceId?: Maybe<Scalars['String']>;
  readonly previousRouteNumber?: Maybe<Scalars['String']>;
  readonly previousRouteCode?: Maybe<Scalars['String']>;
  readonly ownerId?: Maybe<Scalars['String']>;
  readonly ownerName?: Maybe<Scalars['String']>;
  readonly carrierStatus?: Maybe<Scalars['String']>;
  readonly carrierServices?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly sourceType?: Maybe<Scalars['String']>;
  readonly isHazmat?: Maybe<Scalars['Boolean']>;
  readonly isTeam?: Maybe<Scalars['Boolean']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverPhoneNumber?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly driver2PhoneNumber?: Maybe<Scalars['String']>;
  readonly assetDriver?: Maybe<ProcurementAssetDriver>;
  readonly assetDriver2?: Maybe<ProcurementAssetDriver>;
  readonly driverOriginFacilityId?: Maybe<Scalars['String']>;
  readonly driverOriginFacilityName?: Maybe<Scalars['String']>;
  readonly driverDestinationFacilityId?: Maybe<Scalars['String']>;
  readonly driverDestinationFacilityName?: Maybe<Scalars['String']>;
  readonly tractorId?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly outboundSuperRegion?: Maybe<Scalars['String']>;
  readonly outboundRegion?: Maybe<Scalars['String']>;
  readonly outboundSubRegion?: Maybe<Scalars['String']>;
  readonly mainCarrierRepName?: Maybe<Scalars['String']>;
  readonly mainCarrierRepUserId?: Maybe<Scalars['String']>;
  readonly enteredByUserName?: Maybe<Scalars['String']>;
  readonly createdByEmployeeId?: Maybe<Scalars['String']>;
  readonly updatedByEmployeeId?: Maybe<Scalars['String']>;
  readonly createdAtDate?: Maybe<Scalars['DateTime']>;
  readonly updatedAtDate?: Maybe<Scalars['DateTime']>;
  /** remaining cycle time on HOS clock, in ms */
  readonly cycleTimeMs?: Maybe<Scalars['Float']>;
  /** remaining drive time on HOS clock, in ms */
  readonly driveTimeMs?: Maybe<Scalars['Float']>;
  /** remaining shift time on HOS clock, in ms */
  readonly shiftTimeMs?: Maybe<Scalars['Float']>;
  /** remaining break time on HOS clock, in ms */
  readonly breakTimeMs?: Maybe<Scalars['Float']>;
  /** Projected HOS source ID (DDT: capacity_phos_source) */
  readonly phosSourceId?: Maybe<Scalars['String']>;
  readonly capacityUtilizationStatusId: Scalars['String'];
  readonly assignedRouteId?: Maybe<Scalars['String']>;
  readonly assignedRouteNumber?: Maybe<Scalars['String']>;
  /** @deprecated Use createdByEmployeeId */
  readonly employeeId?: Maybe<Scalars['String']>;
  /** @deprecated Use assetDriver.id */
  readonly driverId?: Maybe<Scalars['String']>;
  /** @deprecated Unsupported */
  readonly destinationRegions?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** @deprecated Use createdByEmployeeId */
  readonly createdByUserId?: Maybe<Scalars['String']>;
  /** @deprecated Use updatedByEmployeeId */
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly ownerV2?: Maybe<CarrierV2>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly mainCarrierRepUserV2?: Maybe<UserV2>;
  readonly previousRouteV2?: Maybe<LoadRouteV2>;
  readonly assignedAvailableRoute?: Maybe<AvailableRoute>;
  readonly power?: Maybe<AssetPowerOutput>;
  readonly actualODH?: Maybe<Length>;
  readonly actualDDH?: Maybe<Length>;
  readonly procurementAssetDriverHos?: Maybe<ProcurementAssetDriverHos>;
  readonly procurementAssetDriver2Hos?: Maybe<ProcurementAssetDriverHos>;
};


/** Available Truck */
export type AvailableTruckActualOdhArgs = {
  fromPoint?: InputMaybe<GeoPointInput>;
};


/** Available Truck */
export type AvailableTruckActualDdhArgs = {
  toPoint?: InputMaybe<GeoPointInput>;
};

export type AvailableTruckConnection = {
  readonly __typename?: 'AvailableTruckConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<AvailableTruckEdge>;
  readonly totalCount: Scalars['Float'];
};

export type AvailableTruckEdge = {
  readonly __typename?: 'AvailableTruckEdge';
  readonly node: AvailableTruck;
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String'];
};

export type AvailableTrucksFilter = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly truckPostingIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly truckLifecycleStatus?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierName?: InputMaybe<Scalars['String']>;
  readonly carrierTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: InputMaybe<Scalars['String']>;
  readonly originState?: InputMaybe<Scalars['String']>;
  readonly originCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly readyByDay?: InputMaybe<Scalars['String']>;
  readonly readyByDate?: InputMaybe<Scalars['Timestamp']>;
  readonly readyByDateFilterBehavior?: InputMaybe<DateBoundaryBehavior>;
  readonly equipmentType?: InputMaybe<Scalars['String']>;
  readonly equipmentTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trailerLength?: InputMaybe<UnitOfLengthInput>;
  readonly ddh?: InputMaybe<UnitOfLengthInput>;
  readonly odh?: InputMaybe<UnitOfLengthInput>;
  readonly destinationCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationAdminAreas?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationCity?: InputMaybe<Scalars['String']>;
  readonly destinationState?: InputMaybe<Scalars['String']>;
  readonly originRadius?: InputMaybe<WithinRadiusInput>;
  readonly destinationRadius?: InputMaybe<WithinRadiusInput>;
  readonly loadByDay?: InputMaybe<Scalars['String']>;
  readonly loadByDate?: InputMaybe<Scalars['DateTime']>;
  readonly loadByDateFilterBehavior?: InputMaybe<DateBoundaryBehavior>;
  readonly finalByDay?: InputMaybe<Scalars['String']>;
  readonly finalByDate?: InputMaybe<Scalars['DateTime']>;
  readonly finalByDateFilterBehavior?: InputMaybe<DateBoundaryBehavior>;
  readonly previousRouteNumber?: InputMaybe<Scalars['String']>;
  readonly previousRouteCode?: InputMaybe<Scalars['String']>;
  readonly enteredByRepEmployeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainCarrierRepEmployeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly truckPickupDateRange?: InputMaybe<DateRangeInput>;
  /** Results have readyBy-loadBy date range that INTERSECTS the input */
  readonly utcInclusiveDateRange?: InputMaybe<DateRangeInput>;
  readonly originStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly ownerId?: InputMaybe<Scalars['String']>;
  readonly ownerIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly ownerName?: InputMaybe<Scalars['String']>;
  readonly carrierStatuses?: InputMaybe<Scalars['String']>;
  readonly carrierStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly carrierServices?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly sourceTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly tractorNumber?: InputMaybe<Scalars['String']>;
  readonly outboundRegionCompositeKeys?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverCode?: InputMaybe<Scalars['String']>;
  readonly driverOriginFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverDestinationFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly includeNullDestination?: InputMaybe<Scalars['Boolean']>;
  readonly capacityUtilizationStatusId?: InputMaybe<Scalars['String']>;
  readonly capacityUtilizationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedRouteNumber?: InputMaybe<Scalars['String']>;
  readonly sourceType?: InputMaybe<Scalars['String']>;
  readonly truckDestinationAdminAreas?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly withinRadius?: InputMaybe<UnitOfLengthInput>;
  readonly radiusGeoPoint?: InputMaybe<GeoPointInput>;
  readonly mainCarrierRep?: InputMaybe<Scalars['String']>;
  readonly mainCarrierReps?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainCarrierRepName?: InputMaybe<Scalars['String']>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly employeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly enteredByUserName?: InputMaybe<Scalars['String']>;
  readonly destinationRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum AwaitingRequirementsReason {
  AccountingIncident = 'AccountingIncident',
  AwaitingPaperwork = 'AwaitingPaperwork',
  CommodityRequirements = 'CommodityRequirements',
  FinalizedFuel = 'FinalizedFuel',
  /** @deprecated Field no longer supported */
  CustomerPaperwork = 'CustomerPaperwork',
  /** @deprecated Field no longer supported */
  FuelSurcharge = 'FuelSurcharge'
}

export type AwardQuotesInput = {
  readonly bidId: Scalars['ID'];
};

export type AwardQuotesPayload = {
  readonly __typename?: 'AwardQuotesPayload';
  readonly lanes?: Maybe<ReadonlyArray<Maybe<BidLaneInput>>>;
  readonly rateQuotes?: Maybe<ReadonlyArray<Maybe<BidRateDetailAddInput>>>;
};

export enum AzureLoadType {
  /** Explosives */
  UsHazmatClass1 = 'USHazmatClass1',
  /** Compressed gas */
  UsHazmatClass2 = 'USHazmatClass2',
  /** Flammable liquids */
  UsHazmatClass3 = 'USHazmatClass3',
  /** Flammable solids */
  UsHazmatClass4 = 'USHazmatClass4',
  /** Oxidizers */
  UsHazmatClass5 = 'USHazmatClass5',
  /** Poisons */
  UsHazmatClass6 = 'USHazmatClass6',
  /** Radioactive */
  UsHazmatClass7 = 'USHazmatClass7',
  /** Corrosives */
  UsHazmatClass8 = 'USHazmatClass8',
  /** Miscellaneous */
  UsHazmatClass9 = 'USHazmatClass9',
  /** Explosive (outside US) */
  OtherHazmatExplosive = 'otherHazmatExplosive',
  /** Miscellaneous (outside US) */
  OtherHazmatGeneral = 'otherHazmatGeneral',
  /** Harmful to water (outside US) */
  OtherHazmatHarmfulToWater = 'otherHazmatHarmfulToWater'
}

/** Custom options to use when calculating mileage via Azure, see: https://docs.microsoft.com/en-us/rest/api/maps/route/post-route-matrix */
export type AzureOptions = {
  readonly __typename?: 'AzureOptions';
  readonly AvoidTollRoads?: Maybe<Scalars['Boolean']>;
  readonly AvoidMotorways?: Maybe<Scalars['Boolean']>;
  readonly AvoidFerries?: Maybe<Scalars['Boolean']>;
  readonly AvoidUnpavedRoads?: Maybe<Scalars['Boolean']>;
  readonly AvoidBorderCrossings?: Maybe<Scalars['Boolean']>;
  /** See Azure Docs: https://docs.microsoft.com/en-us/rest/api/maps/route/post-route-matrix-sync#routetype */
  readonly RouteType?: Maybe<AzureRouteType>;
  readonly VehicleAxleWeight?: Maybe<Scalars['Int']>;
  readonly VehicleHeight?: Maybe<Scalars['Float']>;
  /** Acceptable values are between 1 and 100. */
  readonly VehicleLength?: Maybe<Scalars['Float']>;
  /** Hazardous Material designations. See Azure Docs: https://docs.microsoft.com/en-us/rest/api/maps/route/post-route-matrix-sync#vehicleloadtype */
  readonly VehicleLoadType?: Maybe<ReadonlyArray<Maybe<AzureLoadType>>>;
  /** Acceptable values are between 0 and 999. 0 allows for minimum speed to be the posted speed limit for the route. */
  readonly VehicleMaxSpeed?: Maybe<Scalars['Int']>;
  readonly VehicleWeight?: Maybe<Scalars['Int']>;
  readonly VehicleWidth?: Maybe<Scalars['Int']>;
};

export type AzureOptionsInput = {
  readonly AvoidTollRoads?: InputMaybe<Scalars['Boolean']>;
  readonly AvoidMotorways?: InputMaybe<Scalars['Boolean']>;
  readonly AvoidFerries?: InputMaybe<Scalars['Boolean']>;
  readonly AvoidUnpavedRoads?: InputMaybe<Scalars['Boolean']>;
  readonly AvoidBorderCrossings?: InputMaybe<Scalars['Boolean']>;
  /** See Azure Docs: https://docs.microsoft.com/en-us/rest/api/maps/route/post-route-matrix-sync#routetype */
  readonly RouteType?: InputMaybe<AzureRouteType>;
  readonly VehicleAxleWeight?: InputMaybe<Scalars['Int']>;
  readonly VehicleHeight?: InputMaybe<Scalars['Float']>;
  readonly VehicleLength?: InputMaybe<Scalars['Float']>;
  /** Hazardous Material designations. See Azure Docs: https://docs.microsoft.com/en-us/rest/api/maps/route/post-route-matrix-sync#vehicleloadtype */
  readonly VehicleLoadType?: InputMaybe<ReadonlyArray<InputMaybe<AzureLoadType>>>;
  readonly VehicleMaxSpeed?: InputMaybe<Scalars['Int']>;
  readonly VehicleWeight?: InputMaybe<Scalars['Int']>;
  readonly VehicleWidth?: InputMaybe<Scalars['Int']>;
};

export enum AzureRouteType {
  /** A route balanced by economy and speed. */
  Economical = 'Economical',
  /** The fastest route. */
  Fastest = 'Fastest',
  /** The shortest route by distance. */
  Shortest = 'Shortest'
}

export type Base = {
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdByUserID?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedByUserID?: Maybe<Scalars['String']>;
};

export type BaseExternalQuoteRequestFilterType = {
  readonly unitTerm?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['Decimal']>;
};

export type BaseSchedulesInput = {
  readonly serviceProfileId?: InputMaybe<Scalars['Float']>;
  readonly serviceId?: InputMaybe<Scalars['String']>;
  readonly gcMonTime?: InputMaybe<Scalars['DateTime']>;
  readonly gcTuesTime?: InputMaybe<Scalars['DateTime']>;
  readonly gcWedTime?: InputMaybe<Scalars['DateTime']>;
  readonly gcThursTime?: InputMaybe<Scalars['DateTime']>;
  readonly gcFriTime?: InputMaybe<Scalars['DateTime']>;
  readonly gcSatTime?: InputMaybe<Scalars['DateTime']>;
  readonly gcSunTime?: InputMaybe<Scalars['DateTime']>;
  readonly eaDayOfMon?: InputMaybe<Scalars['String']>;
  readonly eaMonTime?: InputMaybe<Scalars['DateTime']>;
  readonly monIsClosed?: InputMaybe<Scalars['Boolean']>;
  readonly eaDayOfTues?: InputMaybe<Scalars['String']>;
  readonly eaTuesTime?: InputMaybe<Scalars['DateTime']>;
  readonly tuesIsClosed?: InputMaybe<Scalars['Boolean']>;
  readonly eaDayOfWed?: InputMaybe<Scalars['String']>;
  readonly eaWedTime?: InputMaybe<Scalars['DateTime']>;
  readonly wedIsClosed?: InputMaybe<Scalars['Boolean']>;
  readonly eaDayOfThurs?: InputMaybe<Scalars['String']>;
  readonly eaThursTime?: InputMaybe<Scalars['DateTime']>;
  readonly thursIsClosed?: InputMaybe<Scalars['Boolean']>;
  readonly eaDayOfFri?: InputMaybe<Scalars['String']>;
  readonly eaFriTime?: InputMaybe<Scalars['DateTime']>;
  readonly friIsClosed?: InputMaybe<Scalars['Boolean']>;
  readonly eaDayOfSat?: InputMaybe<Scalars['String']>;
  readonly eaSatTime?: InputMaybe<Scalars['DateTime']>;
  readonly satIsClosed?: InputMaybe<Scalars['Boolean']>;
  readonly eaDayOfSun?: InputMaybe<Scalars['String']>;
  readonly eaSunTime?: InputMaybe<Scalars['DateTime']>;
  readonly sunIsClosed?: InputMaybe<Scalars['Boolean']>;
};

export type BaseSchedulesInputV2 = {
  readonly serviceProfileId?: InputMaybe<Scalars['Float']>;
  readonly serviceId?: InputMaybe<Scalars['String']>;
  readonly gcMonTime?: InputMaybe<Scalars['DateTime']>;
  readonly gcTuesTime?: InputMaybe<Scalars['DateTime']>;
  readonly gcWedTime?: InputMaybe<Scalars['DateTime']>;
  readonly gcThursTime?: InputMaybe<Scalars['DateTime']>;
  readonly gcFriTime?: InputMaybe<Scalars['DateTime']>;
  readonly gcSatTime?: InputMaybe<Scalars['DateTime']>;
  readonly gcSunTime?: InputMaybe<Scalars['DateTime']>;
  readonly eaDayOfMon?: InputMaybe<Scalars['String']>;
  readonly eaMonTime?: InputMaybe<Scalars['DateTime']>;
  readonly monIsClosed?: InputMaybe<Scalars['Boolean']>;
  readonly eaDayOfTues?: InputMaybe<Scalars['String']>;
  readonly eaTuesTime?: InputMaybe<Scalars['DateTime']>;
  readonly tuesIsClosed?: InputMaybe<Scalars['Boolean']>;
  readonly eaDayOfWed?: InputMaybe<Scalars['String']>;
  readonly eaWedTime?: InputMaybe<Scalars['DateTime']>;
  readonly wedIsClosed?: InputMaybe<Scalars['Boolean']>;
  readonly eaDayOfThurs?: InputMaybe<Scalars['String']>;
  readonly eaThursTime?: InputMaybe<Scalars['DateTime']>;
  readonly thursIsClosed?: InputMaybe<Scalars['Boolean']>;
  readonly eaDayOfFri?: InputMaybe<Scalars['String']>;
  readonly eaFriTime?: InputMaybe<Scalars['DateTime']>;
  readonly friIsClosed?: InputMaybe<Scalars['Boolean']>;
  readonly eaDayOfSat?: InputMaybe<Scalars['String']>;
  readonly eaSatTime?: InputMaybe<Scalars['DateTime']>;
  readonly satIsClosed?: InputMaybe<Scalars['Boolean']>;
  readonly eaDayOfSun?: InputMaybe<Scalars['String']>;
  readonly eaSunTime?: InputMaybe<Scalars['DateTime']>;
  readonly sunIsClosed?: InputMaybe<Scalars['Boolean']>;
};

export type BatchCoordinateInput = {
  readonly lat: Scalars['Float'];
  readonly lon: Scalars['Float'];
  readonly requestId: Scalars['ID'];
};

export type BatchFilter = {
  readonly status: StatusEnum;
};

export type BatchGeocodeError = {
  readonly __typename?: 'BatchGeocodeError';
  readonly code?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
};

export type BatchGeocodeErrorResponse = {
  readonly __typename?: 'BatchGeocodeErrorResponse';
  readonly error?: Maybe<BatchGeocodeError>;
  readonly request?: Maybe<BatchGeocodeRequest>;
};

export type BatchGeocodeRequest = {
  readonly __typename?: 'BatchGeocodeRequest';
  readonly addressLine1?: Maybe<Scalars['String']>;
  readonly locality?: Maybe<Scalars['String']>;
  readonly administrativeArea?: Maybe<Scalars['String']>;
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly countryCode: Scalars['String'];
  readonly requestId: Scalars['ID'];
};

export type BatchGeocodeResponse = {
  readonly __typename?: 'BatchGeocodeResponse';
  readonly successfulItems?: Maybe<ReadonlyArray<Maybe<GeocodeResponse>>>;
  readonly unsuccessfulItems?: Maybe<ReadonlyArray<Maybe<BatchGeocodeErrorResponse>>>;
};

export type BatchParametersInput = {
  readonly batchId: Scalars['ID'];
};

export type Bid = {
  readonly __typename?: 'Bid';
  readonly bidId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly externalBidId?: Maybe<Scalars['String']>;
  readonly bidUId?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly owner?: Maybe<Scalars['ID']>;
  readonly ownerDetails?: Maybe<OwnerDetails>;
  readonly bidStartDate?: Maybe<Scalars['DateTime']>;
  readonly bidEndDate?: Maybe<Scalars['DateTime']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly modeId?: Maybe<Scalars['String']>;
  readonly sizeId?: Maybe<Scalars['String']>;
  readonly bidStatusId?: Maybe<Scalars['ID']>;
  readonly bidStatus?: Maybe<BidStatus>;
  readonly distanceId?: Maybe<Scalars['String']>;
  readonly distanceType?: Maybe<Scalars['String']>;
  readonly bidCalculated?: Maybe<BidCalculated>;
};

export type BidCalculated = {
  readonly __typename?: 'BidCalculated';
  readonly bidCalculatedId?: Maybe<Scalars['ID']>;
  readonly totalLanes?: Maybe<Scalars['Int']>;
  readonly lanesPriced?: Maybe<Scalars['Int']>;
  readonly percentCompleted?: Maybe<Scalars['Float']>;
  readonly totalProjectedVolume?: Maybe<Scalars['Float']>;
  readonly totalProjectedRevenue?: Maybe<Scalars['Float']>;
};

export type BidConnection = {
  readonly __typename?: 'BidConnection';
  readonly edges: ReadonlyArray<Maybe<BidEdge>>;
  readonly totalCount: Scalars['Int'];
  readonly pageInfo: BidPageInfo;
};

export type BidEdge = {
  readonly __typename?: 'BidEdge';
  readonly cursor: Scalars['String'];
  readonly node: Bid;
};

export type BidInput = {
  readonly bidId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly bidStatusId?: InputMaybe<Scalars['ID']>;
  readonly externalBidId?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly modeId: Scalars['String'];
  readonly sizeId: Scalars['String'];
  readonly distanceId: Scalars['String'];
  readonly distanceType?: InputMaybe<Scalars['String']>;
  readonly owner: Scalars['ID'];
  readonly bidStartDate: Scalars['String'];
  readonly bidEndDate: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type BidInputFilter = {
  readonly documentId?: InputMaybe<Scalars['ID']>;
  readonly bidId?: InputMaybe<Scalars['ID']>;
  readonly externalBidId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly owner?: InputMaybe<Scalars['ID']>;
  readonly modeId?: InputMaybe<Scalars['String']>;
  readonly sizeId?: InputMaybe<Scalars['String']>;
  readonly distanceId?: InputMaybe<Scalars['String']>;
  readonly bidStartDate?: InputMaybe<Scalars['String']>;
  readonly bidEndDate?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type BidLane = {
  readonly __typename?: 'BidLane';
  readonly bidLaneId?: Maybe<Scalars['ID']>;
  readonly externalBidLaneId?: Maybe<Scalars['String']>;
  readonly bid?: Maybe<Bid>;
  readonly equipmentId?: Maybe<Scalars['String']>;
  readonly priority?: Maybe<Scalars['Boolean']>;
  readonly minLength?: Maybe<Scalars['Float']>;
  readonly minWidth?: Maybe<Scalars['Float']>;
  readonly minHeight?: Maybe<Scalars['Float']>;
  readonly temperatureRequirement?: Maybe<Scalars['Float']>;
  readonly temperatureUnit?: Maybe<Scalars['String']>;
  readonly projectedVolume?: Maybe<Scalars['Float']>;
  readonly customerMiles?: Maybe<Scalars['Float']>;
  readonly isCurrentLane?: Maybe<Scalars['Boolean']>;
  readonly isAwarded?: Maybe<Scalars['Boolean']>;
  readonly isAwardedV2?: Maybe<Scalars['Int']>;
  readonly assignedPricingRegion?: Maybe<Scalars['String']>;
  readonly assignedUser?: Maybe<Scalars['ID']>;
  readonly incumbent?: Maybe<Scalars['Boolean']>;
  readonly bidLaneStops?: Maybe<ReadonlyArray<Maybe<BidLaneStop>>>;
  readonly service?: Maybe<Scalars['String']>;
};

export type BidLaneAssignRegionInput = {
  readonly selectedLanes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly unSelectedLanes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly hasSelectedAll?: InputMaybe<Scalars['Boolean']>;
  readonly assignedPricingRegion: Scalars['String'];
  readonly bidLaneFilters?: InputMaybe<BidLanesInputFilter>;
};

export type BidLaneAssignRegionPayload = {
  readonly __typename?: 'BidLaneAssignRegionPayload';
  readonly statusCode?: Maybe<Scalars['Int']>;
  readonly msg?: Maybe<Scalars['String']>;
};

export type BidLaneAssignUsers = {
  readonly __typename?: 'BidLaneAssignUsers';
  readonly assignedUserId?: Maybe<Scalars['ID']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
};

export type BidLaneConnection = {
  readonly __typename?: 'BidLaneConnection';
  readonly edges: ReadonlyArray<Maybe<BidLaneEdge>>;
  readonly totalCount: Scalars['Int'];
  readonly pageInfo: BidPageInfo;
};

export type BidLaneEdge = {
  readonly __typename?: 'BidLaneEdge';
  readonly cursor: Scalars['String'];
  readonly node: BidLaneRound;
};

export type BidLaneInput = {
  readonly __typename?: 'BidLaneInput';
  readonly businessUnit?: Maybe<Scalars['String']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly equipment?: Maybe<Scalars['ID']>;
  readonly laneStops?: Maybe<ReadonlyArray<Maybe<LaneStops>>>;
  readonly loadSize?: Maybe<Scalars['String']>;
  readonly minHeight?: Maybe<MinHeight>;
  readonly minLength?: Maybe<MinLength>;
  readonly minWidth?: Maybe<MinWidth>;
  readonly project?: Maybe<Scalars['String']>;
  readonly service?: Maybe<Scalars['String']>;
  readonly transportMode?: Maybe<Scalars['String']>;
};

export type BidLaneRound = {
  readonly __typename?: 'BidLaneRound';
  readonly activeRound?: Maybe<Scalars['Int']>;
  readonly bidLaneRoundStatus?: Maybe<BidStatus>;
  readonly bidLane?: Maybe<BidLane>;
  readonly bidLaneAssignUsers?: Maybe<ReadonlyArray<Maybe<BidLaneAssignUsers>>>;
  readonly bidLaneRound: BidLaneRoundDetail;
};

export type BidLaneRoundDetail = {
  readonly __typename?: 'BidLaneRoundDetail';
  readonly bidLaneRoundId?: Maybe<Scalars['ID']>;
};

export type BidLanesInputFilter = {
  readonly bidId?: InputMaybe<Scalars['ID']>;
  readonly roundId?: InputMaybe<Scalars['ID']>;
  readonly externalBidLaneId?: InputMaybe<Scalars['String']>;
  readonly laneStatus?: InputMaybe<Scalars['ID']>;
  readonly activeRound?: InputMaybe<Scalars['Int']>;
  readonly activeQuote?: InputMaybe<Scalars['String']>;
  readonly originType?: InputMaybe<Scalars['String']>;
  readonly originFacility?: InputMaybe<Scalars['String']>;
  readonly originCity?: InputMaybe<Scalars['String']>;
  readonly originState?: InputMaybe<Scalars['String']>;
  readonly originPostal?: InputMaybe<Scalars['String']>;
  readonly originCountry?: InputMaybe<Scalars['String']>;
  readonly destType?: InputMaybe<Scalars['String']>;
  readonly destFacility?: InputMaybe<Scalars['String']>;
  readonly destCity?: InputMaybe<Scalars['String']>;
  readonly destState?: InputMaybe<Scalars['String']>;
  readonly destPostal?: InputMaybe<Scalars['String']>;
  readonly destCountry?: InputMaybe<Scalars['String']>;
  readonly equip?: InputMaybe<Scalars['ID']>;
  readonly length?: InputMaybe<Scalars['Float']>;
  readonly temp?: InputMaybe<Scalars['Float']>;
  readonly distance?: InputMaybe<Scalars['Int']>;
  readonly estimateVolume?: InputMaybe<Scalars['Int']>;
  readonly incumbent?: InputMaybe<Scalars['Boolean']>;
  readonly awarded?: InputMaybe<Scalars['Boolean']>;
  readonly assignedRegion?: InputMaybe<Scalars['String']>;
  readonly assignedUser?: InputMaybe<Scalars['ID']>;
};

export type BidLaneStatusPayload = {
  readonly __typename?: 'BidLaneStatusPayload';
  readonly msg?: Maybe<Scalars['String']>;
};

export type BidLaneStatusUpdateInput = {
  readonly selectedLanes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly hasSelectedAll?: InputMaybe<Scalars['Boolean']>;
  readonly bidLaneFilters?: InputMaybe<BidLanesInputFilter>;
};

export type BidLaneStop = {
  readonly __typename?: 'BidLaneStop';
  readonly geography?: Maybe<Scalars['String']>;
  readonly facilityCode?: Maybe<Scalars['String']>;
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly stateCode?: Maybe<Scalars['String']>;
  readonly geographyTypeId?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly lat?: Maybe<Scalars['Float']>;
  readonly lon?: Maybe<Scalars['Float']>;
  readonly long?: Maybe<Scalars['Float']>;
  readonly sourceId?: Maybe<Scalars['String']>;
};

export type BidPageInfo = {
  readonly __typename?: 'BidPageInfo';
  readonly startCursor?: Maybe<Scalars['String']>;
  readonly endCursor?: Maybe<Scalars['String']>;
  readonly hasNextPage: Scalars['Boolean'];
  readonly hasPreviousPage: Scalars['Boolean'];
};

export type BidPayload = {
  readonly __typename?: 'BidPayload';
  readonly bid?: Maybe<Bid>;
  readonly error?: Maybe<MasterBidError>;
};

export type BidQuote = {
  readonly __typename?: 'BidQuote';
  readonly bidQuoteId?: Maybe<Scalars['ID']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly commitmentValue?: Maybe<Scalars['Int']>;
  readonly divisionId?: Maybe<Scalars['String']>;
  readonly businessUnitId?: Maybe<Scalars['String']>;
  readonly scacId?: Maybe<Scalars['String']>;
  readonly modeId?: Maybe<Scalars['String']>;
  readonly priceId?: Maybe<Scalars['String']>;
  readonly stopEventId?: Maybe<Scalars['String']>;
  readonly commitmentId?: Maybe<Scalars['String']>;
  readonly commitmentIntervalId?: Maybe<Scalars['String']>;
  readonly commitmentFrequencyType?: Maybe<Scalars['String']>;
  readonly requirementId?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly bidQuoteDetails?: Maybe<ReadonlyArray<Maybe<BidQuoteDetailsPayload>>>;
  readonly createdByUser?: Maybe<UserDetails>;
  readonly createdDateTime?: Maybe<Scalars['DateTime']>;
};

export type BidQuoteDetails = {
  readonly ratePerUnit?: InputMaybe<Scalars['Float']>;
  readonly units?: InputMaybe<Scalars['Int']>;
  readonly chargeTypeId?: InputMaybe<Scalars['String']>;
  readonly currencyId?: InputMaybe<Scalars['String']>;
};

export type BidQuoteDetailsPayload = {
  readonly __typename?: 'BidQuoteDetailsPayload';
  readonly ratePerUnit?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Int']>;
  readonly chargeTypeId?: Maybe<Scalars['String']>;
  readonly currencyId?: Maybe<Scalars['String']>;
};

export type BidQuoteInput = {
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly commitmentValue?: InputMaybe<Scalars['Int']>;
  readonly divisionId?: InputMaybe<Scalars['String']>;
  readonly businessUnitId?: InputMaybe<Scalars['String']>;
  readonly externalBidLaneId?: InputMaybe<Scalars['String']>;
  readonly scacId?: InputMaybe<Scalars['String']>;
  readonly modeId?: InputMaybe<Scalars['String']>;
  readonly stopEventId?: InputMaybe<Scalars['String']>;
  readonly commitmentId?: InputMaybe<Scalars['String']>;
  readonly commitmentIntervalId?: InputMaybe<Scalars['String']>;
  readonly commitmentFrequencyType?: InputMaybe<Scalars['String']>;
  readonly priceId?: InputMaybe<Scalars['String']>;
  readonly requirementId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly bidQuoteDetails?: InputMaybe<ReadonlyArray<InputMaybe<BidQuoteDetails>>>;
  readonly capacityConstraints?: InputMaybe<CapacityConstraintsInput>;
};

export type BidQuotePayload = {
  readonly __typename?: 'BidQuotePayload';
  readonly bidQuoteWithCapacity?: Maybe<ReadonlyArray<Maybe<BidQuoteWithCapacity>>>;
  readonly error?: Maybe<MasterBidError>;
};

export type BidQuoteRecord = {
  readonly documentId?: InputMaybe<Scalars['ID']>;
  readonly encodedLaneIndex?: InputMaybe<Scalars['String']>;
  readonly encodedBidQuoteIndex?: InputMaybe<Scalars['String']>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<BidQuoteStops>>>;
  readonly equipmentType?: InputMaybe<Scalars['String']>;
  readonly trailerTypeMinionId?: InputMaybe<Scalars['ID']>;
  readonly length?: InputMaybe<Scalars['String']>;
  readonly width?: InputMaybe<Scalars['String']>;
  readonly height?: InputMaybe<Scalars['String']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly loadSizeMinionId?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly divisionMinionId?: InputMaybe<Scalars['String']>;
  readonly scac?: InputMaybe<Scalars['String']>;
  readonly scacMinionId?: InputMaybe<Scalars['String']>;
  readonly priceTier?: InputMaybe<Scalars['String']>;
  readonly priceTierMinionId?: InputMaybe<Scalars['String']>;
  readonly team?: InputMaybe<Scalars['String']>;
  readonly autoAccept?: InputMaybe<Scalars['String']>;
  readonly chargeType?: InputMaybe<ReadonlyArray<InputMaybe<Charge>>>;
  readonly chargePerUnit?: InputMaybe<ReadonlyArray<InputMaybe<ChargeUnit>>>;
  readonly units?: InputMaybe<ReadonlyArray<InputMaybe<Units>>>;
  readonly currency?: InputMaybe<Scalars['String']>;
  readonly currencyMinionId?: InputMaybe<Scalars['String']>;
  readonly weeklyCap?: InputMaybe<Scalars['String']>;
  readonly mondayCap?: InputMaybe<Scalars['String']>;
  readonly tuesdayCap?: InputMaybe<Scalars['String']>;
  readonly wednesdayCap?: InputMaybe<Scalars['String']>;
  readonly thursdayCap?: InputMaybe<Scalars['String']>;
  readonly fridayCap?: InputMaybe<Scalars['String']>;
  readonly saturdayCap?: InputMaybe<Scalars['String']>;
  readonly sundayCap?: InputMaybe<Scalars['String']>;
  readonly volume?: InputMaybe<Scalars['String']>;
  readonly frequency?: InputMaybe<Scalars['String']>;
  readonly frequencyMinionId?: InputMaybe<Scalars['String']>;
  readonly surgePercent?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly isLaneValid?: InputMaybe<Scalars['Boolean']>;
  readonly isBidQuoteValid?: InputMaybe<Scalars['Boolean']>;
  readonly service?: InputMaybe<Scalars['String']>;
  readonly laneServiceMinionId?: InputMaybe<Scalars['String']>;
  readonly transportModeMinionId?: InputMaybe<Scalars['String']>;
  readonly commitmentId?: InputMaybe<Scalars['String']>;
  readonly extBidLaneId?: InputMaybe<Scalars['String']>;
  readonly temp?: InputMaybe<Scalars['String']>;
  readonly estVol?: InputMaybe<Scalars['String']>;
  readonly round?: InputMaybe<Scalars['String']>;
  readonly customerCommitmentId?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<Scalars['String']>;
  readonly priority?: InputMaybe<Scalars['String']>;
};

export type BidQuoteStops = {
  readonly stopType?: InputMaybe<Scalars['String']>;
  readonly loadStopTypeMinionId?: InputMaybe<Scalars['String']>;
  readonly stopLiveDrop?: InputMaybe<Scalars['String']>;
  readonly stopGeography?: InputMaybe<Scalars['String']>;
  readonly geographyTypeMinionId?: InputMaybe<Scalars['String']>;
  readonly stopFacility?: InputMaybe<Scalars['String']>;
  readonly stopCity?: InputMaybe<Scalars['String']>;
  readonly stopState?: InputMaybe<Scalars['String']>;
  readonly stopPostalCode?: InputMaybe<Scalars['String']>;
  readonly stopPostalPrefix?: InputMaybe<Scalars['String']>;
  readonly stopCountry?: InputMaybe<Scalars['String']>;
  readonly countryMinionId?: InputMaybe<Scalars['String']>;
  readonly geoCountry?: InputMaybe<Scalars['String']>;
  readonly index?: InputMaybe<Scalars['Int']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly lat?: InputMaybe<Scalars['Float']>;
  readonly lon?: InputMaybe<Scalars['Float']>;
  readonly abbreviatedState?: InputMaybe<Scalars['String']>;
  readonly adminArea?: InputMaybe<Scalars['String']>;
};

export type BidQuoteWithCapacity = {
  readonly __typename?: 'BidQuoteWithCapacity';
  readonly bidQuote?: Maybe<BidQuote>;
  readonly capacityConstraints?: Maybe<CapacityConstraints>;
};

export type BidRateDetailAddInput = {
  readonly __typename?: 'BidRateDetailAddInput';
  readonly billToCodeId?: Maybe<Scalars['ID']>;
  readonly chargeTypeId?: Maybe<Scalars['ID']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly enteredRatePer?: Maybe<Scalars['Float']>;
  readonly exchangeRate?: Maybe<Scalars['Float']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly jurisdictionTaxesId?: Maybe<Scalars['ID']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly orderId?: Maybe<Scalars['ID']>;
  readonly ratePer?: Maybe<Scalars['Float']>;
  readonly stopId?: Maybe<Scalars['ID']>;
  readonly unitsDecimal?: Maybe<Scalars['Float']>;
};

export type BidRoundPayload = {
  readonly __typename?: 'BidRoundPayload';
  readonly rounds: ReadonlyArray<Maybe<RoundResponsePayload>>;
};

export type BidStagingInput = {
  readonly documentId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly bidStatusId?: InputMaybe<Scalars['ID']>;
  readonly externalBidId?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly modeId: Scalars['String'];
  readonly sizeId: Scalars['String'];
  readonly distanceId: Scalars['String'];
  readonly distanceType?: InputMaybe<Scalars['String']>;
  readonly owner: Scalars['ID'];
  readonly bidStartDate: Scalars['String'];
  readonly bidEndDate: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type BidStagingPayload = {
  readonly __typename?: 'BidStagingPayload';
  readonly bidStagingId?: Maybe<Scalars['ID']>;
  readonly error?: Maybe<MasterBidError>;
};

export type BidStatus = {
  readonly __typename?: 'BidStatus';
  readonly name?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['ID']>;
};

export type BillableRouteStop = {
  readonly __typename?: 'BillableRouteStop';
  readonly id: Scalars['ID'];
  readonly distanceToNext?: Maybe<LengthV2>;
  readonly associatedOrderDistanceToNext?: Maybe<LengthV2>;
};


export type BillableRouteStopDistanceToNextArgs = {
  unit: UnitOfLengthEnum;
};


export type BillableRouteStopAssociatedOrderDistanceToNextArgs = {
  unit: UnitOfLengthEnum;
};

/** The Bill To Code */
export type BillToCode = {
  readonly __typename?: 'BillToCode';
  readonly id: Scalars['ID'];
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly isDefault?: Maybe<Scalars['Boolean']>;
  readonly billingCode?: Maybe<Scalars['String']>;
  readonly billToCustomer?: Maybe<Scalars['ID']>;
  readonly billToCustomerDisplayName?: Maybe<Scalars['String']>;
  readonly billingAddress?: Maybe<Scalars['ID']>;
  readonly billingAddressDisplayName?: Maybe<Scalars['String']>;
  readonly billingEmail?: Maybe<Scalars['String']>;
  readonly paymentTerms?: Maybe<Scalars['String']>;
  readonly paymentMethod?: Maybe<Scalars['String']>;
  readonly invoiceMethod?: Maybe<Scalars['String']>;
  readonly invoiceTemplate?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly invoiceGrouping?: Maybe<Scalars['String']>;
  readonly distributionFrequency?: Maybe<Scalars['String']>;
  readonly frequency?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Boolean']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly invoiceType?: Maybe<Scalars['String']>;
  readonly invoiceGroupingMethod?: Maybe<Scalars['String']>;
};

/** The Bill To Code Audit History */
export type BillToCodeHistory = {
  readonly __typename?: 'BillToCodeHistory';
  readonly id: Scalars['ID'];
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly isDefault?: Maybe<Scalars['Boolean']>;
  readonly billingCode?: Maybe<Scalars['String']>;
  readonly billToCustomer?: Maybe<Scalars['ID']>;
  readonly billToCustomerDisplayName?: Maybe<Scalars['String']>;
  readonly billingAddress?: Maybe<Scalars['ID']>;
  readonly billingAddressDisplayName?: Maybe<Scalars['String']>;
  readonly billingEmail?: Maybe<Scalars['String']>;
  readonly paymentTerms?: Maybe<Scalars['String']>;
  readonly paymentMethod?: Maybe<Scalars['String']>;
  readonly invoiceMethod?: Maybe<Scalars['String']>;
  readonly invoiceTemplate?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly invoiceGrouping?: Maybe<Scalars['String']>;
  readonly distributionFrequency?: Maybe<Scalars['String']>;
  readonly frequency?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Boolean']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly changeType?: Maybe<Scalars['String']>;
};

/** The Bill To Code */
export type BillToCodeInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly isDefault?: InputMaybe<Scalars['Boolean']>;
  readonly billingCode?: InputMaybe<Scalars['String']>;
  readonly billToCustomer: Scalars['ID'];
  readonly billingAddress?: InputMaybe<Scalars['ID']>;
  readonly billingEmail?: InputMaybe<Scalars['String']>;
  readonly paymentTerms?: InputMaybe<Scalars['String']>;
  readonly paymentMethod?: InputMaybe<Scalars['String']>;
  readonly invoiceMethod?: InputMaybe<Scalars['String']>;
  readonly invoiceTemplate?: InputMaybe<Scalars['String']>;
  readonly currency?: InputMaybe<Scalars['String']>;
  readonly invoiceGrouping?: InputMaybe<Scalars['String']>;
  readonly distributionFrequency?: InputMaybe<Scalars['String']>;
  readonly frequency?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<Scalars['Boolean']>;
  readonly invoiceType?: InputMaybe<Scalars['String']>;
  readonly invoiceGroupingMethod?: InputMaybe<Scalars['String']>;
};

export type BinOverrideInput = {
  readonly currencyCode: Scalars['String'];
  readonly loadId: Scalars['Uuid'];
  readonly overrideAmount: Scalars['Decimal'];
  readonly previousOverrideAmount: Scalars['Decimal'];
  readonly routeCode: Scalars['String'];
  readonly routeId: Scalars['Uuid'];
};

export type BinOverrideResponse = {
  readonly __typename?: 'BinOverrideResponse';
  readonly code?: Maybe<Scalars['String']>;
  readonly currencyCode?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['Uuid']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly overrideAmount?: Maybe<Scalars['Decimal']>;
  readonly previousOverrideAmount?: Maybe<Scalars['Decimal']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeId?: Maybe<Scalars['Uuid']>;
};

export type BlumeCharge = {
  readonly __typename?: 'BlumeCharge';
  readonly id: Scalars['ID'];
  readonly isMatched: Scalars['Boolean'];
  readonly assetUsageId?: Maybe<Scalars['String']>;
  readonly trackingNumberId?: Maybe<Scalars['String']>;
  readonly billableItemId?: Maybe<Scalars['String']>;
  readonly invoiceLineDetailId?: Maybe<Scalars['String']>;
  readonly programCode?: Maybe<Scalars['String']>;
  readonly assetPrefix?: Maybe<Scalars['String']>;
  readonly assetNumber?: Maybe<Scalars['String']>;
  readonly trackingNumber?: Maybe<Scalars['String']>;
  readonly parentShipperReferenceNumber?: Maybe<Scalars['String']>;
  readonly parentShipperName?: Maybe<Scalars['String']>;
  readonly shipperReferenceNumber?: Maybe<Scalars['String']>;
  readonly shipperName?: Maybe<Scalars['String']>;
  readonly customerReferenceNumber?: Maybe<Scalars['String']>;
  readonly onBehalfOfUser?: Maybe<Scalars['String']>;
  readonly updateUser?: Maybe<Scalars['String']>;
  readonly billOfLading?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly currentStatus?: Maybe<Scalars['String']>;
  readonly reservationRailroadName?: Maybe<Scalars['String']>;
  readonly reservationRampName?: Maybe<Scalars['String']>;
  readonly originRailroadName?: Maybe<Scalars['String']>;
  readonly originRampName?: Maybe<Scalars['String']>;
  readonly destinationRailroadName?: Maybe<Scalars['String']>;
  readonly destinationRampName?: Maybe<Scalars['String']>;
  readonly returnRailroadName?: Maybe<Scalars['String']>;
  readonly returnRampName?: Maybe<Scalars['String']>;
  readonly billableChargeType?: Maybe<Scalars['String']>;
  readonly billableItemCreateDate?: Maybe<Scalars['DateTime']>;
  readonly billableItemStartDate?: Maybe<Scalars['DateTime']>;
  readonly billableItemEndDate?: Maybe<Scalars['DateTime']>;
  readonly rateType?: Maybe<Scalars['String']>;
  readonly billableDays?: Maybe<Scalars['Int']>;
  readonly billableRate?: Maybe<Scalars['Float']>;
  readonly invoiceLineDetailCharges?: Maybe<Scalars['Float']>;
  readonly invoiceLineCharges?: Maybe<Scalars['Float']>;
  readonly asOfDate?: Maybe<Scalars['DateTime']>;
  readonly invoiceNumber?: Maybe<Scalars['String']>;
  readonly postingDate?: Maybe<Scalars['DateTime']>;
  readonly blumeChargeType?: Maybe<BlumeChargeType>;
  readonly matchInfo?: Maybe<BlumeMatchInfo>;
  readonly isIgnored: Scalars['Boolean'];
};

export type BlumeChargeType = {
  readonly __typename?: 'BlumeChargeType';
  readonly id: Scalars['ID'];
  readonly originChargeAllowed: Scalars['Boolean'];
  readonly destinationChargeAllowed: Scalars['Boolean'];
  readonly accountingChargeType?: Maybe<ChargeType>;
  readonly blumeEnumeratedValue?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['DateTime'];
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedOn: Scalars['DateTime'];
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
};

export type BlumeDeletedCostDetail = {
  readonly __typename?: 'BlumeDeletedCostDetail';
  readonly id: Scalars['ID'];
};

export type BlumeInsertedCostDetail = {
  readonly __typename?: 'BlumeInsertedCostDetail';
  readonly id: Scalars['ID'];
};

export type BlumeMatchInfo = {
  readonly __typename?: 'BlumeMatchInfo';
  readonly matchingMethod: BlumeMatchMethod;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
};

export enum BlumeMatchMethod {
  BillOfLading = 'BillOfLading',
  TrackingNumber = 'TrackingNumber',
  BypassId = 'BypassId',
  NotMatched = 'NotMatched'
}

export type BolExceptionInput = {
  readonly routeVendorId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly bolDocumentId?: InputMaybe<Scalars['ID']>;
};

export type BolExceptionPayload = {
  readonly __typename?: 'BolExceptionPayload';
  readonly orderId: Scalars['ID'];
  readonly routeVendorId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  /** Undefined if an exception has not been previously opened for given order */
  readonly previousExceptionStatus?: Maybe<Scalars['String']>;
  readonly currentExceptionStatus: Scalars['String'];
  /** Undefined if an exception has not been previously opened for given order.  May not appear immediately since exceptions are processed asynchronously */
  readonly exceptionId?: Maybe<Scalars['ID']>;
};

/** Input for Booking / Rebooking a Carrier via Orchestration */
export type BookCarrierAsyncInput = {
  /** Employee who booked the load, will default to BIN System Employee if not defined.  Used for all auditing purposes for all mutation calls within booking orchestration pipeline. */
  readonly bookedByEmployeeId?: InputMaybe<Scalars['String']>;
  /** The CarrierContact ID of the Contact the Route was Booked with */
  readonly bookedWithId?: InputMaybe<Scalars['ID']>;
  /** General Notes to include with the Booking */
  readonly bookingNotes?: InputMaybe<Scalars['String']>;
  /** The Booking mechanism used to Book the Route -- *Requires 'Route Booking Source Type' DDT* */
  readonly bookingSourceTypeId: Scalars['String'];
  /** ⚠️ **Deprecated! Use costLineItemsV2** ⚠️ */
  readonly costLineItems?: InputMaybe<ReadonlyArray<InputMaybe<CostLineItemInput>>>;
  /** Input for creating Cost Line Items in Booking Orchestration */
  readonly costLineItemsV2?: InputMaybe<ReadonlyArray<CostLineItemInputV2>>;
  /** The information of the driver(s) being assigned to the Route */
  readonly driver?: InputMaybe<ReadonlyArray<BookCarrierDriverInput>>;
  /** The Division responsible for the Booking */
  readonly executingDivisionId?: InputMaybe<Scalars['ID']>;
  /** Input for setting Expected Ready Information for the Carrier in Booking Orchestration. Expected ready location should not be a required field as CANVAS settings can change and location won’t always be provided or required with a booking */
  readonly expectedEmptyLocation?: InputMaybe<ExpectedReadyLocationInput>;
  /** UTC Timestamp representing the Date & Time the Driver is expected to be ready prior to this Route */
  readonly expectedEmptyDateTimeUtc: Scalars['ISO8601DateTime'];
  /** UTC Timestamp representing the estimated Date & Time the Driver is expected to arrive at the first Stop for pickup */
  readonly pickupEtaDateTimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Braces the carrier is expected to have.  Multi-Select DDT */
  readonly braces?: InputMaybe<BracesInput>;
  /** The type of Bracing Equipment the Carrier is expected to have */
  readonly bracingType?: InputMaybe<BracingTypeEnum>;
  /** The number of Braces the Carrier is expected to have */
  readonly numberOfBraces?: InputMaybe<Scalars['Int']>;
  /** ⚠️ **Deprecated! Use palletTypeV2** ⚠️ */
  readonly palletType?: InputMaybe<PalletTypeEnum>;
  /** The type of Pallets the Carrier is expected to have */
  readonly palletTypeV2?: InputMaybe<Scalars['String']>;
  /** The number of Pallets the Carrier is expected to have */
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  /** ⚠️ **Deprecated! Use tarpTypeV2** ⚠️ */
  readonly tarpType?: InputMaybe<TarpTypeEnum>;
  /** The type of Tarp the Carrier is expected to have */
  readonly tarpTypeV2?: InputMaybe<Scalars['String']>;
  /** The number of Tarps the Carrier is expected to have */
  readonly numberOfTarps?: InputMaybe<Scalars['Int']>;
  /** The ID of the Route that this Carrier will be added to */
  readonly routeId: Scalars['ID'];
  /** The Route References to be Created, Updated, or Removed */
  readonly routeVendorReferencesV2?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorReferenceInputV2>>;
  /** Input for creating a Rate Confirmation in Booking Orchestration */
  readonly rateConfirmation?: InputMaybe<RateConfirmationRecordInput>;
  /** The expected Height of the Carrier's Trailer */
  readonly trailerHeight?: InputMaybe<LengthInput>;
  /** The expected Length of the Carrier's Trailer */
  readonly trailerLength?: InputMaybe<LengthInput>;
  /** The expected Width of the Carrier's Trailer */
  readonly trailerWidth?: InputMaybe<LengthInput>;
  /** The expected Trailer Type of the Carrier -- *Requires 'Trailer Type' DDT* */
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  /** The ID of the Carrier / Vendor that this Route Vendor represents */
  readonly vendorId: Scalars['ID'];
  /** Input is used for Booking Drivers onto the Route */
  readonly bypassValidationOptions?: InputMaybe<BypassValidationOptionsInput>;
  /** Booking options for skipping individual Booking steps */
  readonly bookingOptions?: InputMaybe<BookingOptionsInput>;
  /** Booking capacity information */
  readonly capacities?: InputMaybe<ReadonlyArray<BookingCapacityInput>>;
};

export type BookCarrierAsyncPayload = {
  readonly __typename?: 'BookCarrierAsyncPayload';
  /**
   * A unique ID that is generated to represent an individual Booking action.
   * Can be used to find details of the action on the reponse topic
   */
  readonly correlationId?: Maybe<Scalars['String']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Part of Input for the **bookCarrier** and **bookDrivers** Mutation which handles Booking a Driver */
export type BookCarrierDriverInput = {
  /** The ID of the Driver to be Booked */
  readonly driverId: Scalars['ID'];
};

/** Input for the **bookCarrier** Mutation */
export type BookCarrierInput = {
  /** Input is used for Booking a Carrier onto the Route */
  readonly routeVendor: BookCarrierRouteVendorInput;
  /** Input is used for Booking Drivers onto the Route */
  readonly driver?: InputMaybe<ReadonlyArray<BookCarrierDriverInput>>;
  /** Input is used for Booking Drivers onto the Route */
  readonly bypassValidationOptions?: InputMaybe<BypassValidationOptionsInput>;
  /** Booking options for skipping individual Booking steps */
  readonly bookingOptions?: InputMaybe<BookingOptionsInput>;
  /** Booking capacity information */
  readonly capacities?: InputMaybe<ReadonlyArray<BookingCapacityInput>>;
};

/** The response from the **bookCarrier** Mutation */
export type BookCarrierPayload = {
  readonly __typename?: 'BookCarrierPayload';
  /**
   * A unique ID that is generated to represent an individual Booking action.
   * Can be used to find details of the action on the response topic
   */
  readonly correlationId?: Maybe<Scalars['String']>;
  /** The Carrier which has been Booked */
  readonly routeVendor?: Maybe<RouteVendorV2>;
  /** An Array of any standard GraphQL errors that were encountered */
  readonly errors: ReadonlyArray<UserError>;
  /** An Array of any Validation or Booking Errors that were encountered */
  readonly bookingErrors?: Maybe<ReadonlyArray<Maybe<BookingError>>>;
  readonly bypassReasons?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  /** Booking capacity information */
  readonly capacities?: Maybe<ReadonlyArray<BookingCapacityInfo>>;
};

/** Information about the Vendor that would like to be Booked */
export type BookCarrierRouteVendorInput = {
  /** ⚠️ **Deprecated! Use bookedByEmployeeId instead** ⚠ */
  readonly bookedById?: InputMaybe<Scalars['ID']>;
  /** Employee who booked the load, will default to BIN System Employee if not defined. Used for all auditing purposes for all mutation calls within booking orchestration pipeline. */
  readonly bookedByEmployeeId?: InputMaybe<Scalars['String']>;
  /** The Booking mechanism used to Book the Route -- *Requires 'Route Booking Source Type' DDT* */
  readonly bookingSourceId: Scalars['ID'];
  /** The CarrierContact ID of the Contact the Route was Booked with */
  readonly bookedWithId?: InputMaybe<Scalars['ID']>;
  /** General Notes to include with the Booking */
  readonly bookingNotes?: InputMaybe<Scalars['String']>;
  /** ⚠️ **Deprecated! Use bookedByEmployeeId instead** ⚠ */
  readonly createdById?: InputMaybe<Scalars['ID']>;
  /** The Division responsible for the Booking */
  readonly executingDivisionId?: InputMaybe<Scalars['ID']>;
  /** Input for setting Expected Ready Information for the Carrier */
  readonly expectedReady?: InputMaybe<ExpectedReadyInput>;
  /** This is set to *true* for Loads Booked through automated processes such as Mastermind API or Tendering */
  readonly isAutomated?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the Route that this Carrier / Vendor will be added to */
  readonly routeId: Scalars['ID'];
  /** ⚠️ **Deprecated! Use routeVendorRepsV2** ⚠️ */
  readonly routeVendorReps?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorRepV2Input>>;
  /** The Array of Route Vendor Reps to be Created, Updated, or Removed */
  readonly routeVendorRepsV2?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorRepV3Input>>;
  /** The Array of Route References to be Created, Updated, or Removed */
  readonly routeVendorReferencesV2?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorReferenceInputV2>>;
  /** The ID of the Carrier / Vendor that this Route Vendor represents */
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  /** ⚠️ **Deprecated! vendorType will always be 'carrier' */
  readonly vendorType?: InputMaybe<RouteVendorTypeEnum>;
};

/** Input for the **bookDriver** Mutation */
export type BookDriverInput = {
  /** The ID of the Route that this Carrier / Vendor will be added to */
  readonly routeId: Scalars['ID'];
  /** Employee who booked the load, will default to BIN System Employee if not defined. Used for all auditing purposes for all mutation calls within booking orchestration pipeline. */
  readonly bookedByEmployeeId: Scalars['ID'];
  /** The Booking mechanism used to Book the Route -- *Requires 'Route Booking Source Type' DDT* */
  readonly bookingSourceId: Scalars['ID'];
  /** Input is used for Booking a Carrier onto the Route */
  readonly routeVendor?: InputMaybe<BookRouteVendorInput>;
  /** Input is used for Booking Drivers onto the Route */
  readonly driver: ReadonlyArray<BookCarrierDriverInput>;
  /** ⚠️ **Deprecated! Use bypassValidationOptionsV2 instead** ⚠ */
  readonly bypassValidationOptions?: InputMaybe<BypassValidationOptionsInput>;
  /** Input is used for Booking Drivers onto the Route */
  readonly bypassValidationOptionsV2?: InputMaybe<BypassValidationOptionsInputV2>;
  /** ⚠️ **Deprecated! Use bookingOptionsV2 instead** ⚠ */
  readonly bookingOptions?: InputMaybe<BookingOptionsInput>;
  /** Booking options for skipping individual Booking steps */
  readonly bookingOptionsV2?: InputMaybe<BookingOptionsInputV2>;
  /** Booking capacity information */
  readonly capacities?: InputMaybe<ReadonlyArray<BookingCapacityInput>>;
};

export type BookingCapacityInfo = {
  readonly __typename?: 'BookingCapacityInfo';
  readonly id: Scalars['String'];
  readonly type?: Maybe<Scalars['String']>;
};

export type BookingCapacityInput = {
  readonly id: Scalars['String'];
  readonly type?: InputMaybe<Scalars['String']>;
};

export type BookingCreateCostDetails = {
  readonly costDetails: ReadonlyArray<CostDetailAddInput>;
  readonly rerateableCostDetails: ReadonlyArray<CostDetailAddInput>;
};

export type BookingCreatedCostDetailsPayload = {
  readonly __typename?: 'BookingCreatedCostDetailsPayload';
  readonly adjustmentRequired?: Maybe<Scalars['String']>;
  readonly createdCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
};

export type BookingError = {
  readonly __typename?: 'BookingError';
  readonly code?: Maybe<Scalars['String']>;
  readonly validationName?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly bypass?: Maybe<Scalars['Boolean']>;
  readonly objectId?: Maybe<Scalars['String']>;
  readonly objectType?: Maybe<Scalars['String']>;
};

/** Booking options for skipping individual Booking steps */
export type BookingOptionsInput = {
  /** Boolean to skip the rate con step of booking orchestration - default is false */
  readonly skipRateConfirmation?: InputMaybe<Scalars['Boolean']>;
  /** Boolean to skip the route lock step of booking orchestration - default is false */
  readonly skipRouteLock?: InputMaybe<Scalars['Boolean']>;
  /** Boolean to skip the driver assignment booking orchestration - default is false */
  readonly skipDriverAssignment?: InputMaybe<Scalars['Boolean']>;
};

/** Booking options for skipping individual Booking steps */
export type BookingOptionsInputV2 = {
  /** Boolean to skip the rate con step of booking orchestration - default is false */
  readonly skipRateConfirmation?: InputMaybe<Scalars['Boolean']>;
  /** Boolean to skip the route lock step of booking orchestration - default is false */
  readonly skipRouteLock?: InputMaybe<Scalars['Boolean']>;
};

export type BookingsUserError = {
  readonly __typename?: 'BookingsUserError';
  readonly code?: Maybe<Scalars['String']>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type BookingValidationRule = {
  readonly __typename?: 'BookingValidationRule';
  readonly name: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly options: ReadonlyArray<BookingValidationRuleOption>;
};

export type BookingValidationRuleOption = {
  readonly __typename?: 'BookingValidationRuleOption';
  readonly key: Scalars['String'];
  readonly value: Scalars['String'];
};

/** This optional Input can be used to test Route Scopes against C.A.N.V.A.S. settings */
export type BookingValidationRulesInput = {
  readonly scope?: InputMaybe<BookingValidationRulesScope>;
};

/** The Response from the `bookingValidationRules` Query */
export type BookingValidationRulesPayload = {
  readonly __typename?: 'BookingValidationRulesPayload';
  /** The current settings of Validation Rules in the environment -- Include Scope input to test non-global configurations */
  readonly rules: ReadonlyArray<BookingValidationRule>;
};

/** Input for testing different Validation Rule settings in C.A.N.V.A.S. based on Route scope */
export type BookingValidationRulesScope = {
  /** The Primary Division on the Route */
  readonly division?: InputMaybe<Scalars['String']>;
  /** The Equipment Types selected on the ROute */
  readonly equipments?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** The Mode on the Route */
  readonly routeTransportMode?: InputMaybe<Scalars['String']>;
  /** The Size Type of the Load (ex. Full, LTL, etc) */
  readonly loadSize?: InputMaybe<Scalars['String']>;
  /** The Primary Business Unit on the Route */
  readonly businessUnit?: InputMaybe<Scalars['String']>;
};

/** The response from the **bookDriver** Mutation */
export type BookPayload = {
  readonly __typename?: 'BookPayload';
  /**
   * A unique ID that is generated to represent an individual Booking action.
   * Can be used to find details of the action on the response topic
   */
  readonly correlationId?: Maybe<Scalars['String']>;
  /** The Carrier which has been Booked */
  readonly routeVendor?: Maybe<RouteVendorV2>;
  /** An Array of any standard GraphQL errors that were encountered */
  readonly errors: ReadonlyArray<UserError>;
  /** An Array of any Validation or Booking Errors that were encountered */
  readonly bookingErrors?: Maybe<ReadonlyArray<Maybe<BookingError>>>;
  readonly bypassReasons?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  /** Booking capacity information */
  readonly capacities?: Maybe<ReadonlyArray<BookingCapacityInfo>>;
};

/** Input for the **bookRoutes** Mutation */
export type BookRouteAsyncInput = {
  /** Input is used for Booking a Carrier onto the Route */
  readonly routeVendor: BookCarrierRouteVendorInput;
  /** Input is used for adding cost line items */
  readonly costLineItems?: InputMaybe<ReadonlyArray<InputMaybe<CostLineItemInputV2>>>;
  /** Booking options for skipping individual Booking steps */
  readonly bookingOptions?: InputMaybe<BookingOptionsInput>;
};

/** Information about the Vendor that would like to be Booked */
export type BookRouteVendorInput = {
  /** The CarrierContact ID of the Contact the Route was Booked with */
  readonly bookedWithId?: InputMaybe<Scalars['ID']>;
  /** General Notes to include with the Booking */
  readonly bookingNotes?: InputMaybe<Scalars['String']>;
  /** The Division responsible for the Booking */
  readonly executingDivisionId?: InputMaybe<Scalars['ID']>;
  /** Input for setting Expected Ready Information for the Carrier */
  readonly expectedReady?: InputMaybe<ExpectedReadyInput>;
  /** This is set to *true* for Loads Booked through automated processes such as Mastermind API or Tendering */
  readonly isAutomated?: InputMaybe<Scalars['Boolean']>;
  /** ⚠️ **Deprecated! Use routeVendorRepsV2** ⚠️ */
  readonly routeVendorReps?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorRepV2Input>>;
  /** The Array of Route Vendor Reps to be Created, Updated, or Removed */
  readonly routeVendorRepsV2?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorRepV3Input>>;
  /** The Array of Route References to be Created, Updated, or Removed */
  readonly routeVendorReferencesV2?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorReferenceInputV2>>;
  /** The ID of the Carrier / Vendor that this Route Vendor represents */
  readonly vendorId: Scalars['ID'];
};

export type BooleanError = {
  readonly __typename?: 'BooleanError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly serviceError?: Maybe<Scalars['JSON']>;
};

export type BooleanPayload = {
  readonly __typename?: 'BooleanPayload';
  readonly errors: ReadonlyArray<BooleanError>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
};

export type BooleanType = {
  readonly __typename?: 'BooleanType';
  readonly value?: Maybe<Scalars['Boolean']>;
};

export type Bounce = {
  readonly __typename?: 'Bounce';
  readonly active: Scalars['Boolean'];
  readonly bounceReason: KeyValue;
  readonly bounceType: KeyValue;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser: User;
  readonly id: Scalars['ID'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly rebooked?: Maybe<Scalars['Boolean']>;
  readonly rebookedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly rebookedByUser?: Maybe<User>;
  readonly routeVendor?: Maybe<RouteVendor>;
  readonly updatedByUser: User;
};

/** Input for Bouncing a Carrier via Booking Orchestration */
export type BounceCarrierAsyncInput = {
  /** The ID of the Route that this Carrier / Vendor will be added to */
  readonly routeId?: InputMaybe<Scalars['ID']>;
  /** The ID of the Carrier that this Route Vendor represents */
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  /** The type of Bounce -- *Requires 'Bounce Type' DDT* */
  readonly bounceTypeId: Scalars['String'];
  /** The reason the Carrier / Vendor is being removed from teh Route -- *Requires 'Bounce Reason' DDT* */
  readonly bounceReasonId: Scalars['String'];
  /** Notes to add to the Bounce */
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type BounceCarrierAsyncPayload = {
  readonly __typename?: 'BounceCarrierAsyncPayload';
  readonly correlationId?: Maybe<Scalars['String']>;
  readonly errors: ReadonlyArray<UserError>;
};

/** The connection type for Bounce. */
export type BounceConnection = {
  readonly __typename?: 'BounceConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<BounceEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type BounceDriversFromRouteResourcesInput = {
  readonly routeID: Scalars['ID'];
  readonly assetDriverIDs: ReadonlyArray<Scalars['ID']>;
  /** Value corresponding to a Driver Bounce Reason DDT Option ID. */
  readonly bounceReason: Scalars['String'];
  /** Notes regarding the bounce driver event */
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type BounceDriversInput = {
  readonly resourceID: Scalars['ID'];
  readonly assetDriverIDs: ReadonlyArray<Scalars['ID']>;
  /** Value corresponding to a Driver Bounce Reason DDT Option ID. Defaults to `other` if omitted. */
  readonly bounceReason?: InputMaybe<Scalars['String']>;
  /** Notes regarding the bounce driver event */
  readonly notes?: InputMaybe<Scalars['String']>;
};

/** An edge in a connection. */
export type BounceEdge = {
  readonly __typename?: 'BounceEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: Bounce;
};

export type BounceRouteVendorInput = {
  readonly bounceReasonId: Scalars['ID'];
  readonly bounceTypeId: Scalars['ID'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly routeVendorId: Scalars['ID'];
};

/** Autogenerated return type of BounceRouteVendor */
export type BounceRouteVendorPayload = {
  readonly __typename?: 'BounceRouteVendorPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendor: RouteVendor;
};

/** Input for Bouncing a Carrier / Vendor from a Route */
export type BounceRouteVendorV2Input = {
  /** The reason the Carrier / Vendor is being removed from teh Route -- *Requires 'Bounce Reason' DDT* */
  readonly bounceReasonId: Scalars['ID'];
  /** The type of Bounce -- *Requires 'Bounce Type' DDT* */
  readonly bounceTypeId: Scalars['ID'];
  /** Notes to add to the Bounce */
  readonly notes?: InputMaybe<Scalars['String']>;
  /** The ID of the Route Vendor this Bounce belongs to */
  readonly routeVendorId: Scalars['ID'];
  /** The ID of the User who is Bouncing the Carrier / Vendor */
  readonly bouncedByUserId: Scalars['ID'];
};

export type BounceRouteVendorV2Payload = {
  readonly __typename?: 'BounceRouteVendorV2Payload';
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendor?: Maybe<RouteVendorV2>;
};

export type BouncesFilter = {
  readonly routeId?: InputMaybe<Scalars['ID']>;
};

export type BouncesV2Connection = {
  readonly __typename?: 'BouncesV2Connection';
  readonly edges: ReadonlyArray<BouncesV2Edge>;
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type BouncesV2Edge = {
  readonly __typename?: 'BouncesV2Edge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: BounceV2;
};

/** Filter for `bouncesV2` Mutation */
export type BouncesV2Filter = {
  /** Provide the ID of the Route to receive all Bounces associated with Route Vendors on a Route */
  readonly routeId?: InputMaybe<Scalars['ID']>;
};

export type BounceV2 = {
  readonly __typename?: 'BounceV2';
  readonly active: Scalars['Boolean'];
  readonly bounceReason: KeyValue;
  readonly bounceType: KeyValue;
  readonly createdAt: Scalars['ISO8601DateTime'];
  /** @deprecated createdByUser is deprecated. Use createdByUserV2 instead. */
  readonly createdByUser: User;
  readonly createdByUserV2: UserV2;
  readonly id: Scalars['ID'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly rebooked?: Maybe<Scalars['Boolean']>;
  readonly rebookedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated rebookedByUser is deprecated. Use rebookedByUserV2 instead. */
  readonly rebookedByUser?: Maybe<User>;
  readonly rebookedByUserV2?: Maybe<UserV2>;
  readonly routeVendor?: Maybe<RouteVendorV2>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  /** @deprecated updatedByUser is deprecated. Use updatedByUserV2 instead. */
  readonly updatedByUser: User;
  readonly updatedByUserV2: UserV2;
};

export type Braces = {
  readonly __typename?: 'Braces';
  /** The number of braces the carrier is expected to have */
  readonly count: Scalars['Int'];
  /** Multi-select Braces DDT */
  readonly types?: Maybe<ReadonlyArray<KeyValue>>;
};

/** Input for Multi-Select Braces DDT */
export type BracesInput = {
  /** The number of braces the carrier is expected to have */
  readonly count: Scalars['Int'];
  /** The DDT IDs of the braces */
  readonly ids: ReadonlyArray<Scalars['String']>;
};

export type BraceTypes = {
  readonly __typename?: 'BraceTypes';
  readonly option: KeyValue;
};

export enum BracingTypeEnum {
  /** Load bars */
  LoadBars = 'loadBars',
  /** Load locks */
  LoadLocks = 'loadLocks',
  /** None */
  None = 'none',
  /** Straps */
  Straps = 'straps'
}

/** DEPRECATED: Please use "loadBracing" DDT */
export enum BracingTypesEnum {
  LoadBars = 'loadBars',
  LoadLocks = 'loadLocks',
  Straps = 'straps'
}

export type BulkAssignVendorToRoutesOptionsInput = {
  readonly linkRoutes?: InputMaybe<Scalars['Boolean']>;
  readonly stopOnBookingFailure?: InputMaybe<Scalars['Boolean']>;
};

export type BulkAssignVendorToRoutesV2IndividualPayload = {
  readonly __typename?: 'BulkAssignVendorToRoutesV2IndividualPayload';
  readonly routeId: Scalars['String'];
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendor?: Maybe<RouteVendorV2>;
  /** Booking capacity information */
  readonly capacities?: Maybe<ReadonlyArray<BookingCapacityInfo>>;
};

export type BulkAssignVendorToRoutesV2Input = {
  readonly options: BulkAssignVendorToRoutesOptionsInput;
  readonly routeVendorAssignments: ReadonlyArray<AssignVendorToRouteV2Input>;
};

export type BulkAssignVendorToRoutesV2Payload = {
  readonly __typename?: 'BulkAssignVendorToRoutesV2Payload';
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendors: ReadonlyArray<BulkAssignVendorToRoutesV2IndividualPayload>;
};

export type BulkCarrierFuelRangeCreateInput = {
  readonly carrierFuelRateHeaderId: Scalars['ID'];
  readonly fuelRangeStart: Scalars['Decimal'];
  readonly fuelRangeEnd: Scalars['Decimal'];
  readonly fuelRangeIncrement: Scalars['Decimal'];
  readonly fuelSurchargeStart: Scalars['Decimal'];
  readonly fuelSurchargeIncrement: Scalars['Decimal'];
};

export type BulkCustomerFuelRangeCreateInput = {
  readonly customerFuelRateHeaderId: Scalars['ID'];
  readonly fuelRangeStart: Scalars['Decimal'];
  readonly fuelRangeEnd: Scalars['Decimal'];
  readonly fuelRangeIncrement: Scalars['Decimal'];
  readonly fuelSurchargeStart: Scalars['Decimal'];
  readonly fuelSurchargeIncrement: Scalars['Decimal'];
};

export type BulkGeographyResponse = {
  readonly __typename?: 'BulkGeographyResponse';
  readonly success: ReadonlyArray<SingleBulkGeographyResponse>;
  readonly failures: ReadonlyArray<Scalars['String']>;
  readonly errors: ReadonlyArray<BulkRequestFailure>;
};

export type BulkInsertCarrierFuelRangeResponse = {
  readonly __typename?: 'BulkInsertCarrierFuelRangeResponse';
  readonly isSuccessful?: Maybe<Scalars['Boolean']>;
};

export type BulkRequestFailure = {
  readonly __typename?: 'BulkRequestFailure';
  readonly requestId: Scalars['String'];
  readonly message: Scalars['String'];
};

export type BulkReratingRequest = {
  readonly __typename?: 'BulkReratingRequest';
  readonly requestId: Scalars['ID'];
  readonly notificationEmails?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly createdOnUtc: Scalars['String'];
  readonly startedOnUtc?: Maybe<Scalars['String']>;
  readonly finishedOnUtc?: Maybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly loadsProcessed?: Maybe<Scalars['Decimal']>;
  readonly loadsCancelled?: Maybe<Scalars['Decimal']>;
  readonly loadsPending?: Maybe<Scalars['Decimal']>;
};

export type BulkUpdateContainersInput = {
  readonly operatingStatusId?: InputMaybe<Scalars['String']>;
  readonly serviceStatusId?: InputMaybe<Scalars['String']>;
  readonly trailerId: Scalars['ID'];
  readonly containerNumber?: InputMaybe<Scalars['String']>;
};

/** The input type to bulk update Edi outbox messages. */
export type BulkUpdateEdiOutboxInput = {
  readonly ediTransactionIds: ReadonlyArray<Scalars['ID']>;
  readonly isRetryable: Scalars['Boolean'];
  readonly publishedTimestamp?: InputMaybe<Scalars['DateTime']>;
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type BulkUpdateTenderStatusInput = {
  readonly acceptedById?: InputMaybe<Scalars['ID']>;
  readonly activationStatusId?: InputMaybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly declinedById?: InputMaybe<Scalars['ID']>;
  readonly declinedReasonId?: InputMaybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly tenderStatusId: Scalars['ID'];
};

/** Autogenerated return type of BulkUpdateTenderStatus */
export type BulkUpdateTenderStatusPayload = {
  readonly __typename?: 'BulkUpdateTenderStatusPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly orders: ReadonlyArray<CustomerOrder>;
};

export type BusinessUnitR2 = {
  readonly __typename?: 'BusinessUnitR2';
  readonly id?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
};

/** Part of Input for the **bookCarrier** Mutation which handles Booking a Bypass Validation Options */
export type BypassValidationOptionsInput = {
  /** ⚠️ **Deprecated! We no longer use validationNames for bypassing validations** ⚠️ */
  readonly validationNames?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly bypassReason: Scalars['String'];
};

/** Part of Input for the **bookDrivers** Mutation which handles Booking a Bypass Validation Options */
export type BypassValidationOptionsInputV2 = {
  readonly bypassReason: Scalars['String'];
};

export type BypassValidationsInput = {
  /** ⚠️ **Deprecated! We no longer use validationNames for bypassing validations** ⚠️ */
  readonly validationNames?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly bypassReason: Scalars['String'];
};

export type CacheFlushResponse = {
  readonly __typename?: 'CacheFlushResponse';
  readonly response?: Maybe<Scalars['String']>;
};

export type CalculateCutOffsForStopsInOrderInput = {
  readonly orderId: Scalars['ID'];
  readonly jobId?: InputMaybe<Scalars['Int']>;
};

export type CalculateCutOffsForStopsInOrderPayload = {
  readonly __typename?: 'CalculateCutOffsForStopsInOrderPayload';
  readonly errors: ReadonlyArray<CalculateCutOffsForStopsInOrderResponseError>;
  readonly calculateCutOffsForStopsInOrderResponse?: Maybe<CalculateCutOffsForStopsInOrderResponse>;
};

export type CalculateCutOffsForStopsInOrderResponse = {
  readonly __typename?: 'CalculateCutOffsForStopsInOrderResponse';
  readonly orderId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly affectedStopIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly jobId?: Maybe<Scalars['Int']>;
};

export type CalculateCutOffsForStopsInOrderResponseError = {
  readonly __typename?: 'CalculateCutOffsForStopsInOrderResponseError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly serviceError?: Maybe<Scalars['JSON']>;
};

export type CalculateDriverAssignmentEtaInput = {
  readonly routeID: Scalars['ID'];
  readonly emptyLocation: LatLongPair;
  readonly emptyLocationDatetime: Scalars['DateTime'];
  /** Indicates whether or not there is more than one driver listed on the given driver assignment. Defaults to `false`. */
  readonly isTeamOfDrivers?: InputMaybe<Scalars['Boolean']>;
};

export type CalculateDriverPayInput = {
  readonly loadId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
};

export type CalculateDriverPayItemModel = {
  readonly __typename?: 'CalculateDriverPayItemModel';
  readonly assetDriver: AssetDriver;
  readonly payProgram: PayProgramModel;
  readonly payRateTable: PayRateTableHeaderModel;
  readonly chargeTypeId: Scalars['ID'];
  readonly costPer: Scalars['Decimal'];
  readonly units: Scalars['Decimal'];
  readonly totalCost: Scalars['Decimal'];
  readonly description: Scalars['String'];
  readonly currency: Scalars['String'];
};

export type CalculateDriverPayResult = {
  readonly __typename?: 'CalculateDriverPayResult';
  readonly status: CalculationStatusEnum;
  readonly payItems: ReadonlyArray<CalculateDriverPayItemModel>;
};

export type CalculateDriverProjectedPayDriverInput = {
  readonly driverId: Scalars['ID'];
};

export type CalculateDriverProjectedPayInput = {
  readonly drivers: ReadonlyArray<CalculateDriverProjectedPayDriverInput>;
  readonly routes: ReadonlyArray<CalculateDriverProjectedPayRouteInput>;
  readonly projectedPayMethod: DriverProjectedPayMethodEnum;
};

export type CalculateDriverProjectedPayItemModel = {
  readonly __typename?: 'CalculateDriverProjectedPayItemModel';
  readonly route: LoadRouteV2;
  readonly carrier?: Maybe<CarrierV2>;
  readonly driver: AssetDriver;
  readonly payProgramAssociation: PayProgramDriverAssociationModel;
  readonly payItems: ReadonlyArray<CalculateDriverPayItemModel>;
  readonly totalPay: Scalars['Decimal'];
};

export type CalculateDriverProjectedPayResult = {
  readonly __typename?: 'CalculateDriverProjectedPayResult';
  readonly status: CalculationStatusEnum;
  readonly results: ReadonlyArray<CalculateDriverProjectedPayItemModel>;
};

export type CalculateDriverProjectedPayRouteInput = {
  readonly routeId: Scalars['ID'];
  readonly carrierId?: InputMaybe<Scalars['ID']>;
};

export type CalculatedTimers = {
  readonly __typename?: 'CalculatedTimers';
  readonly calculationTimestamp: Scalars['Float'];
  readonly driveClock: DriverHosClock;
  readonly shiftClock: DriverHosClock;
  readonly cycleClock: DriverHosClock;
  readonly breakClock: DriverHosClock;
};

export type CalculatePayableStop = {
  readonly __typename?: 'CalculatePayableStop';
  /** routeStopId */
  readonly id: Scalars['ID'];
  readonly payableDistanceToNext?: Maybe<LengthV2>;
  readonly loadedPayableDistanceToNext?: Maybe<LengthV2>;
  readonly isPayableStop?: Maybe<Scalars['Boolean']>;
};

export type CalculatePayableStopsMileage = {
  readonly __typename?: 'CalculatePayableStopsMileage';
  /** routeId */
  readonly id: Scalars['ID'];
  readonly totalLoadedPayableDistance?: Maybe<LengthV2>;
  readonly totalPayableDistance?: Maybe<LengthV2>;
  readonly payableStops: ReadonlyArray<CalculatePayableStop>;
};

export type CalculateVatCheckedModel = {
  readonly __typename?: 'calculateVatCheckedModel';
  readonly calculateVatOption?: Maybe<CalculateVatCheckedOptionEnumOutputType>;
};

export enum CalculateVatCheckedOptionEnumOutputType {
  Unchecked = 'UNCHECKED',
  Checked = 'CHECKED'
}

export enum CalculationStatusEnum {
  Unknown = 'UNKNOWN',
  Success = 'SUCCESS',
  Skippedcarriernonfleet = 'SKIPPEDCARRIERNONFLEET',
  Skippeddrivernotfound = 'SKIPPEDDRIVERNOTFOUND',
  Skippednocarrier = 'SKIPPEDNOCARRIER',
  Skippednodrivers = 'SKIPPEDNODRIVERS',
  Skippednoeffectivedate = 'SKIPPEDNOEFFECTIVEDATE',
  Skippednoeffectivedatesetting = 'SKIPPEDNOEFFECTIVEDATESETTING',
  Skippednoeligiblepayprograms = 'SKIPPEDNOELIGIBLEPAYPROGRAMS',
  Skippednopayprogramsassigned = 'SKIPPEDNOPAYPROGRAMSASSIGNED',
  Skippednostops = 'SKIPPEDNOSTOPS',
  Skippedpayprogramnotfound = 'SKIPPEDPAYPROGRAMNOTFOUND',
  Skippedroutenotfound = 'SKIPPEDROUTENOTFOUND'
}

export type CancelCustomerOrderInput = {
  readonly id: Scalars['ID'];
  readonly cancelationReason: Scalars['String'];
};

export type CancelPreplanInput = {
  readonly preplanId: Scalars['ID'];
};

export type CancelPreplanInputV2 = {
  readonly routeId: Scalars['ID'];
  readonly preplanId?: InputMaybe<Scalars['ID']>;
  readonly isDispatch?: InputMaybe<Scalars['Boolean']>;
};

export type CancelPreplanResponse = {
  readonly __typename?: 'CancelPreplanResponse';
  readonly id?: Maybe<Scalars['ID']>;
  readonly hasErrors?: Maybe<Scalars['Boolean']>;
  readonly errors?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type CanvasConfiguration = {
  readonly __typename?: 'CanvasConfiguration';
  /** Unique identifier generated by Canvas.  Used for CRUD and audit operations on the UI. */
  readonly uuid: Scalars['ID'];
  /** If a customer defined validation configuration is not met, this is the expected behavior */
  readonly failedValidationBehavior: Scalars['String'];
  /** GlobalDefault indicator, flags whether this validation configuration is considered a fallback option applicable for this entity and action */
  readonly globalDefault: Scalars['Boolean'];
  /** List of rules that apply for this validation configuration */
  readonly requirements: ReadonlyArray<Requirement>;
  /** Object that contains audit fields such as createdBy and createdAt */
  readonly validatorMetadata?: Maybe<ValidatorMetadata>;
  /** Scope to which this validator belongs to such as a tenant, carrier or specific customer. Corresponds to the scopedEntityUuid */
  readonly configScope: ConfigScope;
  /** Division associated with the entity, must match a division DDT */
  readonly division?: Maybe<CanvasKeyOptions>;
  /** Order Transport Mode associated with the entity, must match a transportMode DDT */
  readonly orderTransportMode?: Maybe<CanvasKeyOptions>;
  /** Unique Identifier of the entity this validator belongs to. Expected to be null when configScope is tenant level */
  readonly scopedEntityUuid?: Maybe<Scalars['ID']>;
  /** Load Size associated with the entity, must match a loadSize DDT */
  readonly loadSize?: Maybe<CanvasKeyOptions>;
  /** Trailer Type equipment associated with the entity, must match a trailerType DDT option ID in UUID format */
  readonly equipment?: Maybe<CanvasKeyOptions>;
  /** Type of entity this validator applies to, such as 'order' or 'customer' */
  readonly entity: Scalars['String'];
  /** Action of the entity taking place when this validation is checked, such as invoice generation or entity creation */
  readonly action: Scalars['String'];
  /** Route type associated with the entity, must match a routeType DDT */
  readonly routeType?: Maybe<CanvasKeyOptions>;
  /** Order type associated with the entity, must match a orderType DDT */
  readonly orderType?: Maybe<CanvasKeyOptions>;
  /** Route Transport Mode associated with the entity, must match a routeTransportMode DDT */
  readonly routeTransportMode?: Maybe<CanvasKeyOptions>;
  /** Bill to Code associated with the entity */
  readonly billToCode?: Maybe<CanvasKeyOptions>;
  /** Vendor Type associated with the entity and action */
  readonly vendorType?: Maybe<CanvasKeyOptions>;
  /** Priority Rank of the given configuration. */
  readonly priority?: Maybe<Scalars['Int']>;
};

export type CanvasConfigurationAuditHistory = {
  readonly __typename?: 'CanvasConfigurationAuditHistory';
  readonly uuid: Scalars['ID'];
  readonly canvasConfigurationUuid: Scalars['ID'];
  readonly version: Scalars['Int'];
  readonly field?: Maybe<Scalars['String']>;
  readonly action: Action;
  readonly previousConfiguration?: Maybe<Scalars['String']>;
  readonly newConfiguration?: Maybe<Scalars['String']>;
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['String'];
};

export type CanvasKeyOptions = {
  readonly __typename?: 'CanvasKeyOptions';
  readonly allowAny: Scalars['Boolean'];
  readonly allowBlank: Scalars['Boolean'];
  readonly exactMatch: Scalars['Boolean'];
  readonly explicitValues: ReadonlyArray<Maybe<Scalars['String']>>;
};

export type CanvasKeyOptionsInput = {
  readonly allowAny: Scalars['Boolean'];
  readonly allowBlank: Scalars['Boolean'];
  readonly exactMatch: Scalars['Boolean'];
  readonly explicitValues: ReadonlyArray<InputMaybe<Scalars['String']>>;
};

export type CapacityAssetDriver = {
  readonly __typename?: 'CapacityAssetDriver';
  readonly id: Scalars['ID'];
  readonly displayName?: Maybe<Scalars['String']>;
  readonly fleets?: Maybe<ReadonlyArray<CapacityAssetDriverFleet>>;
  readonly schedules?: Maybe<ReadonlyArray<CapacityAssetDriverSchedule>>;
  readonly statusTerm?: Maybe<Scalars['String']>;
  readonly timesOff?: Maybe<ReadonlyArray<CapacityAssetDriverTimeOff>>;
};

export type CapacityAssetDriverFleet = {
  readonly __typename?: 'CapacityAssetDriverFleet';
  readonly carrierId: Scalars['ID'];
  readonly effectiveDate?: Maybe<Scalars['Timestamp']>;
  readonly expirationDate?: Maybe<Scalars['Timestamp']>;
  readonly id: Scalars['ID'];
  readonly type: Scalars['String'];
};

export type CapacityAssetDriverSchedule = {
  readonly __typename?: 'CapacityAssetDriverSchedule';
  readonly dayOfWeek?: Maybe<Scalars['String']>;
  readonly effectiveDate?: Maybe<Scalars['Timestamp']>;
  readonly endTime?: Maybe<Scalars['Float']>;
  readonly expirationDate?: Maybe<Scalars['Timestamp']>;
  readonly id: Scalars['ID'];
  readonly startTime?: Maybe<Scalars['Float']>;
};

export type CapacityAssetDriverTimeOff = {
  readonly __typename?: 'CapacityAssetDriverTimeOff';
  readonly endDate?: Maybe<Scalars['Timestamp']>;
  readonly endLocationCity?: Maybe<Scalars['String']>;
  readonly endLocationState?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly startDate?: Maybe<Scalars['Timestamp']>;
  readonly startLocationCity?: Maybe<Scalars['String']>;
  readonly startLocationState?: Maybe<Scalars['String']>;
  readonly typeTerm: Scalars['String'];
};

export type CapacityConstraint = {
  readonly __typename?: 'CapacityConstraint';
  readonly id?: Maybe<Scalars['ID']>;
  readonly rateQuoteId?: Maybe<Scalars['ID']>;
  readonly sundayConstraint?: Maybe<Scalars['Int']>;
  readonly mondayConstraint?: Maybe<Scalars['Int']>;
  readonly tuesdayConstraint?: Maybe<Scalars['Int']>;
  readonly wednesdayConstraint?: Maybe<Scalars['Int']>;
  readonly thursdayConstraint?: Maybe<Scalars['Int']>;
  readonly fridayConstraint?: Maybe<Scalars['Int']>;
  readonly saturdayConstraint?: Maybe<Scalars['Int']>;
  readonly weeklyCapacityConstraint?: Maybe<Scalars['Int']>;
  readonly autoAccept?: Maybe<Scalars['Boolean']>;
};

export type CapacityConstraints = {
  readonly __typename?: 'CapacityConstraints';
  readonly weeklyCap?: Maybe<Scalars['Int']>;
  readonly weeklyCapacity?: Maybe<Scalars['Int']>;
  readonly sundayConstraint?: Maybe<Scalars['Int']>;
  readonly mondayConstraint?: Maybe<Scalars['Int']>;
  readonly tuesdayConstraint?: Maybe<Scalars['Int']>;
  readonly wednesdayConstraint?: Maybe<Scalars['Int']>;
  readonly thursdayConstraint?: Maybe<Scalars['Int']>;
  readonly fridayConstraint?: Maybe<Scalars['Int']>;
  readonly saturdayConstraint?: Maybe<Scalars['Int']>;
};

export type CapacityConstraintsInput = {
  readonly weeklyCap?: InputMaybe<Scalars['Int']>;
  readonly weeklyCapacity?: InputMaybe<Scalars['Int']>;
  readonly sundayConstraint?: InputMaybe<Scalars['Int']>;
  readonly mondayConstraint?: InputMaybe<Scalars['Int']>;
  readonly tuesdayConstraint?: InputMaybe<Scalars['Int']>;
  readonly wednesdayConstraint?: InputMaybe<Scalars['Int']>;
  readonly thursdayConstraint?: InputMaybe<Scalars['Int']>;
  readonly fridayConstraint?: InputMaybe<Scalars['Int']>;
  readonly saturdayConstraint?: InputMaybe<Scalars['Int']>;
};

export type CapacityLoadRouteV2 = {
  readonly __typename?: 'CapacityLoadRouteV2';
  readonly id: Scalars['ID'];
};

export type CapacityLoadRouteV2Connection = {
  readonly __typename?: 'CapacityLoadRouteV2Connection';
  readonly edges?: Maybe<ReadonlyArray<Maybe<CapacityLoadRouteV2Edge>>>;
  readonly pageInfo?: Maybe<PageInfo>;
};

export type CapacityLoadRouteV2Edge = {
  readonly __typename?: 'CapacityLoadRouteV2Edge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<CapacityLoadRouteV2>;
};

export type CapacityLocation = {
  readonly __typename?: 'CapacityLocation';
  /** Full city name, ex: Chicago */
  readonly city: Scalars['String'];
  /** The state abbreviated to 2 letters, ex: IL */
  readonly stateAlpha2Code: Scalars['String'];
  /** The country abbreviated to 2 letters, ex: US */
  readonly countryAlpha2Code: Scalars['String'];
  /** Returns Geopoint with shape { lat: _, lon: _ } */
  readonly point: Geopoint;
};

export type CapacityLocationInput = {
  /** Full city name, ex: Chicago */
  readonly city: Scalars['String'];
  /** The state, ex: IL, MEX, Ontario, Florida */
  readonly stateAlpha2Code: Scalars['String'];
  /** The country, ex: US, Mexico */
  readonly countryAlpha2Code: Scalars['String'];
  /** Optional postal code, ex: 60654 */
  readonly postalCode?: InputMaybe<Scalars['String']>;
};

export enum CapacityMatchType {
  Truck = 'TRUCK',
  Container = 'CONTAINER',
  GateReservation = 'GATE_RESERVATION'
}

/** The types of capacity the route needs */
export enum CapacityNeed {
  Container = 'CONTAINER',
  GateReservation = 'GATE_RESERVATION',
  DriverPowerAndTrailer = 'DRIVER_POWER_AND_TRAILER',
  DriverPowerOnly = 'DRIVER_POWER_ONLY',
  DriverRecord = 'DRIVER_RECORD',
  Trailer = 'TRAILER'
}

export enum CapacityWarningCodesEnum {
  PtoOverlap = 'PTO_OVERLAP',
  RoutingRequestCritical = 'ROUTING_REQUEST_CRITICAL',
  RoutingRequestNonCritical = 'ROUTING_REQUEST_NON_CRITICAL'
}

export type Carrier = {
  readonly __typename?: 'Carrier';
  readonly id: Scalars['ID'];
  readonly accountingStatus?: Maybe<Scalars['String']>;
  readonly hasPropertyMetadata: Scalars['Boolean'];
  readonly eid?: Maybe<Scalars['Long']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly propertyMetadata?: Maybe<ReadonlyArray<KeyValuePairOfStringAndPropertyMetadata>>;
  readonly carrierFacilities?: Maybe<ReadonlyArray<CarrierFacility>>;
  readonly code: Scalars['String'];
  readonly accountingNotes?: Maybe<ReadonlyArray<Note>>;
  readonly addresses?: Maybe<ReadonlyArray<Address>>;
  readonly allNotes?: Maybe<ReadonlyArray<Note>>;
  readonly ancestorIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly audits: ReadonlyArray<AuditTypeUnion>;
  readonly binQualified?: Maybe<Scalars['Boolean']>;
  readonly carrierAuditsExternalStatus?: Maybe<ReadonlyArray<CarrierAuditsExternalStatus>>;
  readonly carrierComplianceEmail?: Maybe<Scalars['String']>;
  readonly carrierIdentifiers?: Maybe<ReadonlyArray<CarrierIdentifier>>;
  readonly carrierPaymentTerm?: Maybe<KeyValue>;
  readonly carrierPaymentTermId?: Maybe<Scalars['ID']>;
  readonly carrierRepAudit?: Maybe<ReadonlyArray<CarrierRepAudit>>;
  readonly carrierSalesProgressType?: Maybe<KeyValue>;
  readonly carrierServices?: Maybe<ReadonlyArray<CarrierService>>;
  readonly certifications?: Maybe<ReadonlyArray<CarrierCertification>>;
  readonly children?: Maybe<ReadonlyArray<Carrier>>;
  readonly confirmationNotes?: Maybe<ReadonlyArray<Note>>;
  readonly contacts?: Maybe<ReadonlyArray<Contact>>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly currency?: Maybe<KeyValue>;
  readonly currencyId?: Maybe<Scalars['ID']>;
  readonly customerRelationships?: Maybe<ReadonlyArray<CarrierCustomerRelationship>>;
  readonly dbaName?: Maybe<Scalars['String']>;
  readonly descendantIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly dotNumber?: Maybe<Scalars['String']>;
  readonly dunsNumber?: Maybe<Scalars['String']>;
  readonly electronicTrackingMethod?: Maybe<KeyValue>;
  readonly electronicTrackingType?: Maybe<KeyValue>;
  readonly entityType: EntityTypeEnum;
  /** @deprecated use `standing` field instead. */
  readonly externalStatus?: Maybe<StatusTypeEnum>;
  /** @deprecated Use `geographiesNullable` instead (`geographies` is not under access control) */
  readonly geographies: ReadonlyArray<CarrierGeography>;
  readonly geographiesNullable?: Maybe<ReadonlyArray<CarrierGeography>>;
  readonly hazmat?: Maybe<Scalars['Boolean']>;
  readonly inactiveContacts?: Maybe<ReadonlyArray<Contact>>;
  readonly insurances?: Maybe<ReadonlyArray<CarrierInsurance>>;
  readonly interactions?: Maybe<ReadonlyArray<Interaction>>;
  readonly intrastatePresent?: Maybe<Scalars['Boolean']>;
  readonly intrastateStates?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly invoiceDistributionMethod?: Maybe<KeyValue>;
  readonly invoiceDistributionMethodId?: Maybe<Scalars['ID']>;
  readonly isAutoTruckEnabled: Scalars['Boolean'];
  /** @deprecated Use carrierSettings instead */
  readonly level?: Maybe<KeyValue>;
  readonly levelId?: Maybe<Scalars['ID']>;
  /** @deprecated Use level */
  readonly levelName?: Maybe<LevelTypeEnum>;
  readonly mainAddress?: Maybe<Address>;
  readonly mainContact?: Maybe<Contact>;
  readonly manualTrackingInformation?: Maybe<Scalars['String']>;
  readonly manualTrackingMethod?: Maybe<ManualTrackingMethodEnum>;
  readonly mcNumber?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly nonCertifiedReasons?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly operatingStatus?: Maybe<Scalars['String']>;
  /** @deprecated Use carrierSettings instead */
  readonly opportunity?: Maybe<KeyValue>;
  readonly opportunityId?: Maybe<Scalars['ID']>;
  /** @deprecated Use opportunity */
  readonly opportunityName?: Maybe<OpportunityTypeEnum>;
  readonly parent?: Maybe<Carrier>;
  readonly paymentMethod?: Maybe<KeyValue>;
  readonly paymentMethodId?: Maybe<Scalars['ID']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly releaseMethods?: Maybe<ReadonlyArray<CarrierReleaseMethodV1>>;
  readonly remitToCarrier?: Maybe<Carrier>;
  readonly remittanceAddress?: Maybe<Address>;
  readonly remittanceEmail?: Maybe<Scalars['String']>;
  /** @deprecated Use temp_remittance_grouping for now */
  readonly remittanceGrouping?: Maybe<RemittanceGroupingEnum>;
  readonly reps?: Maybe<ReadonlyArray<Rep>>;
  /** @deprecated will be removed soon */
  readonly revenovaId?: Maybe<Scalars['String']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly roeStatus?: Maybe<KeyValue>;
  readonly safetyRating?: Maybe<SafetyRatingTypeEnum>;
  readonly scacNumber?: Maybe<Scalars['String']>;
  readonly setting?: Maybe<CarrierSetting>;
  readonly siblings?: Maybe<ReadonlyArray<Carrier>>;
  readonly standing?: Maybe<ReadonlyArray<CarrierStanding>>;
  readonly status?: Maybe<StatusTypeEnum>;
  readonly tempRemittanceGrouping?: Maybe<TempRemittanceGroupingEnum>;
  readonly tenderOptions?: Maybe<TenderOptions>;
  /** @deprecated Service has reached EOL. Use TruckEntry subgraph. Data will soon be stale or null only */
  readonly truckPostings?: Maybe<ReadonlyArray<TruckPosting>>;
  readonly trucks?: Maybe<ReadonlyArray<CarrierTruck>>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
  /** @deprecated Use w9_certified_nullable instead. */
  readonly w9Certified: Scalars['Boolean'];
  readonly w9CertifiedNullable?: Maybe<Scalars['Boolean']>;
};


export type CarrierInteractionsArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  interactionType?: InputMaybe<InteractionTypeEnum>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type CarrierAccessorialRuleSet = {
  readonly __typename?: 'CarrierAccessorialRuleSet';
  readonly id: Scalars['ID'];
  readonly rulesetId: Scalars['ID'];
  readonly accessorialEnabled?: Maybe<Scalars['Boolean']>;
  readonly trigger?: Maybe<Scalars['String']>;
  readonly chargeTypeName?: Maybe<Scalars['String']>;
  readonly chargeTypeId?: Maybe<Scalars['ID']>;
};

export type CarrierAccessorialSettingsPayload = {
  readonly __typename?: 'CarrierAccessorialSettingsPayload';
  readonly accessorialRuleSets?: Maybe<ReadonlyArray<Maybe<CarrierAccessorialRuleSet>>>;
};

export type CarrierAddress = {
  readonly __typename?: 'CarrierAddress';
  readonly addressType?: Maybe<KeyValue>;
  readonly addressTypeId?: Maybe<Scalars['ID']>;
  readonly carrierId: Scalars['ID'];
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly county?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  /** @deprecated Use geopointNullable */
  readonly geopoint: Geopoint;
  readonly geopointNullable?: Maybe<Geopoint>;
  readonly id: Scalars['ID'];
  readonly isVerified: Scalars['Boolean'];
  readonly main: Scalars['Boolean'];
  readonly postalCode: Scalars['String'];
  readonly province: Scalars['String'];
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: Maybe<Scalars['String']>;
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
};

export type CarrierAddressesFilter = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly stateCollection?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly stateIdCollection?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly radius?: InputMaybe<UnitOfDistanceInput>;
};

export type CarrierAddressInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly addressTypeId?: InputMaybe<Scalars['ID']>;
  readonly vendorCode?: InputMaybe<Scalars['String']>;
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly country: Scalars['String'];
  readonly county?: InputMaybe<Scalars['String']>;
  readonly postalCode: Scalars['String'];
  readonly main: Scalars['Boolean'];
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly isVerified?: InputMaybe<Scalars['Boolean']>;
};

export type CarrierArgumentInput = {
  readonly code?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type CarrierArgumentInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly code?: InputMaybe<Scalars['String']>;
};

export type CarrierAudit = AuditTypeInterface & {
  readonly __typename?: 'CarrierAudit';
  readonly actionType: Scalars['String'];
  readonly auditType: Scalars['String'];
  readonly auditableId: Scalars['ID'];
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByEmployee?: Maybe<Employee>;
  readonly currentValue?: Maybe<CarrierAuditedChanges>;
  readonly id: Scalars['ID'];
  readonly previousValue?: Maybe<CarrierAuditedChanges>;
  readonly userId?: Maybe<Scalars['ID']>;
};

export type CarrierAuditDetails = {
  readonly __typename?: 'CarrierAuditDetails';
  readonly auditDetailId?: Maybe<Scalars['ID']>;
  readonly auditLogId?: Maybe<Scalars['ID']>;
  readonly action?: Maybe<Scalars['String']>;
  readonly fieldName?: Maybe<Scalars['String']>;
  readonly previousValue?: Maybe<Scalars['String']>;
  readonly updatedValue?: Maybe<Scalars['String']>;
};

export type CarrierAuditedChanges = {
  readonly __typename?: 'CarrierAuditedChanges';
  readonly externalStatus?: Maybe<StatusTypeEnum>;
  readonly nonCertifiedReasons?: Maybe<Scalars['String']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly status?: Maybe<StatusTypeEnum>;
};

export type CarrierAuditedChangesV2 = {
  readonly __typename?: 'CarrierAuditedChangesV2';
  /**  The field `externalStatus` is deprecated. Use `standing` field instead.  */
  readonly externalStatus?: Maybe<StatusTypeEnum>;
  readonly nonCertifiedReasons?: Maybe<Scalars['String']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly status?: Maybe<StatusTypeEnum>;
};

export type CarrierAuditsExternalStatus = AuditTypeInterface & {
  readonly __typename?: 'CarrierAuditsExternalStatus';
  readonly actionType: Scalars['String'];
  readonly auditType: Scalars['String'];
  readonly auditableId: Scalars['ID'];
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByEmployee?: Maybe<Employee>;
  readonly currentValue?: Maybe<CarrierAuditedChanges>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly previousValue?: Maybe<CarrierAuditedChanges>;
};

export type CarrierAuditsExternalStatusV2 = AuditTypeInterfaceV2 & {
  readonly __typename?: 'CarrierAuditsExternalStatusV2';
  readonly actionType: Scalars['String'];
  readonly auditType: Scalars['String'];
  readonly auditableId: Scalars['ID'];
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByEmployee?: Maybe<EmployeeV2>;
  readonly currentValue?: Maybe<CarrierAuditedChangesV2>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly previousValue?: Maybe<CarrierAuditedChangesV2>;
  readonly userId?: Maybe<Scalars['ID']>;
};

export type CarrierAuditV2 = AuditTypeInterfaceV2 & {
  readonly __typename?: 'CarrierAuditV2';
  readonly actionType: Scalars['String'];
  readonly auditType: Scalars['String'];
  readonly auditableId: Scalars['ID'];
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByEmployee?: Maybe<EmployeeV2>;
  readonly currentValue?: Maybe<CarrierAuditedChangesV2>;
  readonly id: Scalars['ID'];
  readonly previousValue?: Maybe<CarrierAuditedChangesV2>;
  readonly userId?: Maybe<Scalars['ID']>;
};

export type CarrierAuthorizedDivisionInputV2 = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly division?: InputMaybe<Scalars['String']>;
};

export type CarrierBaseInfo = {
  readonly __typename?: 'CarrierBaseInfo';
  readonly code: Scalars['String'];
  readonly id: Scalars['String'];
};

export type CarrierBusinessQuestionInputV2 = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly minorityOwned?: InputMaybe<Scalars['Boolean']>;
  readonly womanOwned?: InputMaybe<Scalars['Boolean']>;
  readonly disabledOwned?: InputMaybe<Scalars['Boolean']>;
  readonly veteranOwned?: InputMaybe<Scalars['Boolean']>;
  readonly lgbtqOwned?: InputMaybe<Scalars['Boolean']>;
  readonly smallBusiness?: InputMaybe<Scalars['Boolean']>;
};

export type CarrierCapacity = {
  readonly __typename?: 'CarrierCapacity';
  readonly id: Scalars['ID'];
  readonly costQuoteId: Scalars['ID'];
  readonly weeklyCapacity?: Maybe<Scalars['Int']>;
  readonly mondayCapacity?: Maybe<Scalars['Int']>;
  readonly tuesdayCapacity?: Maybe<Scalars['Int']>;
  readonly wednesdayCapacity?: Maybe<Scalars['Int']>;
  readonly thursdayCapacity?: Maybe<Scalars['Int']>;
  readonly fridayCapacity?: Maybe<Scalars['Int']>;
  readonly saturdayCapacity?: Maybe<Scalars['Int']>;
  readonly sundayCapacity?: Maybe<Scalars['Int']>;
  readonly createdBy: Scalars['ID'];
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedBy: Scalars['ID'];
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarrierCapacityConstraintInput = {
  readonly costQuoteId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly laneId: Scalars['ID'];
  readonly weeklyCapacity?: InputMaybe<Scalars['Int']>;
  readonly mondayCapacity?: InputMaybe<Scalars['Int']>;
  readonly tuesdayCapacity?: InputMaybe<Scalars['Int']>;
  readonly wednesdayCapacity?: InputMaybe<Scalars['Int']>;
  readonly thursdayCapacity?: InputMaybe<Scalars['Int']>;
  readonly fridayCapacity?: InputMaybe<Scalars['Int']>;
  readonly saturdayCapacity?: InputMaybe<Scalars['Int']>;
  readonly sundayCapacity?: InputMaybe<Scalars['Int']>;
  readonly requestedById: Scalars['ID'];
};

export type CarrierCapacityRemaining = {
  readonly __typename?: 'CarrierCapacityRemaining';
  readonly hasWeeklyCapacity?: Maybe<Scalars['Boolean']>;
  readonly hasDailyCapacity?: Maybe<Scalars['Boolean']>;
  readonly weeklyCapacityRemaining?: Maybe<Scalars['Int']>;
  readonly dailyCapacityRemaining?: Maybe<Scalars['Int']>;
  readonly totalWeeklyCapacity?: Maybe<Scalars['Int']>;
  readonly totalDailyCapacity?: Maybe<Scalars['Int']>;
};

export type CarrierCapacityRoute = {
  readonly __typename?: 'CarrierCapacityRoute';
  readonly id: Scalars['ID'];
  readonly countsAgainstCapacityDate?: Maybe<Scalars['DateTime']>;
  readonly countsAgainstCapacityDayOfWeek?: Maybe<Scalars['String']>;
  readonly countsAgainstCapacity?: Maybe<Scalars['Boolean']>;
};

export type CarrierCapacityV2 = {
  readonly __typename?: 'CarrierCapacityV2';
  readonly id: Scalars['ID'];
  readonly costQuoteId: Scalars['ID'];
  readonly weeklyCapacity?: Maybe<Scalars['Int']>;
  readonly mondayCapacity?: Maybe<Scalars['Int']>;
  readonly tuesdayCapacity?: Maybe<Scalars['Int']>;
  readonly wednesdayCapacity?: Maybe<Scalars['Int']>;
  readonly thursdayCapacity?: Maybe<Scalars['Int']>;
  readonly fridayCapacity?: Maybe<Scalars['Int']>;
  readonly saturdayCapacity?: Maybe<Scalars['Int']>;
  readonly sundayCapacity?: Maybe<Scalars['Int']>;
  readonly createdBy: Scalars['ID'];
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedBy: Scalars['ID'];
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarrierCapacityWeek = {
  readonly __typename?: 'CarrierCapacityWeek';
  readonly id: Scalars['ID'];
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly weeklyCapacityConsumed?: Maybe<Scalars['Int']>;
  readonly mondayCapacityConsumed?: Maybe<Scalars['Int']>;
  readonly tuesdayCapacityConsumed?: Maybe<Scalars['Int']>;
  readonly wednesdayCapacityConsumed?: Maybe<Scalars['Int']>;
  readonly thursdayCapacityConsumed?: Maybe<Scalars['Int']>;
  readonly fridayCapacityConsumed?: Maybe<Scalars['Int']>;
  readonly saturdayCapacityConsumed?: Maybe<Scalars['Int']>;
  readonly sundayCapacityConsumed?: Maybe<Scalars['Int']>;
};

export type CarrierCarrierRelationship = {
  readonly __typename?: 'CarrierCarrierRelationship';
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly relatedCarrierId: Scalars['ID'];
  readonly relatedCarrier?: Maybe<CarrierV2>;
  readonly ultimateParentId?: Maybe<Scalars['ID']>;
  readonly createdAt?: Maybe<Scalars['Iso8601Date']>;
  readonly updatedAt?: Maybe<Scalars['Iso8601Date']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly relationshipTypeId: Scalars['ID'];
};

export type CarrierCertification = {
  readonly __typename?: 'CarrierCertification';
  readonly carrierCertificationType: KeyValue;
  readonly carrierCertificationTypeId: Scalars['ID'];
  readonly carrierInfo?: Maybe<CarrierBaseInfo>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly expirationDate?: Maybe<Scalars['Iso8601Date']>;
  readonly id: Scalars['ID'];
  readonly note?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
};

export type CarrierCertificationInput = {
  readonly carrier: CarrierArgumentInput;
  readonly carrierCertificationTypeId: Scalars['ID'];
  readonly expirationDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly note?: InputMaybe<Scalars['String']>;
};

export type CarrierCertificationInputV2 = {
  readonly carrier: CarrierArgumentInputV2;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly expirationDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly carrierCertificationTypeId: Scalars['ID'];
  /**  The field `carbTruckAndBusId` is deprecated. */
  readonly carbTruckAndBusId?: InputMaybe<Scalars['Int']>;
  /**  The field `carbTruckAndBusIndsdId` is deprecated. */
  readonly carbTruckAndBusIndsdId?: InputMaybe<Scalars['Int']>;
  readonly carbTruckAndBusComplianceType?: InputMaybe<Scalars['String']>;
  readonly carbTruckAndBusCertDateValidUntil?: InputMaybe<Scalars['ISO8601Date']>;
  readonly carbTruckAndBusDeclaredNonCompliant?: InputMaybe<Scalars['String']>;
  readonly carbTruckAndBusRmisAuditOk?: InputMaybe<Scalars['Boolean']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly certificationId?: InputMaybe<Scalars['String']>;
};

export type CarrierCertificationQueryInput = {
  readonly carrierCertificationTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type CarrierCertificationQueryInputV2 = {
  readonly carrierCertificationTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type CarrierCertificationV2 = {
  readonly __typename?: 'CarrierCertificationV2';
  readonly carrierCertificationType: KeyValue;
  readonly carrierCertificationTypeId: Scalars['ID'];
  readonly carrierInfo?: Maybe<CarrierBaseInfo>;
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['Iso8601Date']>;
  readonly id: Scalars['ID'];
  readonly note?: Maybe<Scalars['String']>;
  /** @deprecated The field `carbTruckAndBusId` is deprecated. */
  readonly carbTruckAndBusId?: Maybe<Scalars['Int']>;
  /** @deprecated The field `carbTruckAndBusIndsdId` is deprecated. */
  readonly carbTruckAndBusIndsdId?: Maybe<Scalars['Int']>;
  readonly carbTruckAndBusComplianceType?: Maybe<Scalars['String']>;
  readonly carbTruckAndBusCertDateValidUntil?: Maybe<Scalars['Iso8601Date']>;
  readonly carbTruckAndBusDeclaredNonCompliant?: Maybe<Scalars['String']>;
  readonly carbTruckAndBusRmisAuditOk?: Maybe<Scalars['Boolean']>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly certificationId?: Maybe<Scalars['String']>;
};

export type CarrierChargesModel = {
  readonly __typename?: 'CarrierChargesModel';
  readonly chargeDescription?: Maybe<Scalars['String']>;
  readonly per?: Maybe<Scalars['Float']>;
  readonly qualifier?: Maybe<Scalars['String']>;
  readonly quantity?: Maybe<Scalars['Int']>;
  readonly rate?: Maybe<Scalars['Float']>;
};

export type CarrierChargeTypeConfigurationCreateInput = {
  readonly carrierChargeCode?: InputMaybe<Scalars['String']>;
  readonly carrierDescription?: InputMaybe<Scalars['String']>;
  readonly carrierId: Scalars['Guid'];
  readonly category?: InputMaybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly cost?: InputMaybe<Scalars['Decimal']>;
  readonly customerId?: InputMaybe<Scalars['Guid']>;
  readonly customerName?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly isAllowedByCarrier: Scalars['Boolean'];
  readonly isDefault: Scalars['Boolean'];
  readonly maximumTotalCost?: InputMaybe<Scalars['Decimal']>;
  readonly minimumTotalCost?: InputMaybe<Scalars['Decimal']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly perUnit?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly carrierApprovalRequired?: InputMaybe<Scalars['Boolean']>;
  readonly costPerConfigurationChargeTypeIds?: InputMaybe<ReadonlyArray<Scalars['Guid']>>;
  readonly isTieredCostPerConfiguration?: InputMaybe<Scalars['Boolean']>;
  readonly carrierTieredCostSchedules?: InputMaybe<ReadonlyArray<CarrierTieredCostScheduleInput>>;
};

export type CarrierChargeTypeConfigurationCreateInputV2 = {
  readonly carrierChargeCode?: InputMaybe<Scalars['String']>;
  readonly carrierDescription?: InputMaybe<Scalars['String']>;
  readonly carrierId: Scalars['Guid'];
  readonly category?: InputMaybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly customerId?: InputMaybe<Scalars['Guid']>;
  readonly customerName?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly isDefault: Scalars['Boolean'];
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly carrierRegionConfigurations?: InputMaybe<ReadonlyArray<CarrierRegionConfigurationInput>>;
};

export type CarrierChargeTypeConfigurationResponse = {
  readonly __typename?: 'CarrierChargeTypeConfigurationResponse';
  readonly isActive: Scalars['Boolean'];
  readonly isCredit: Scalars['Boolean'];
  readonly carrierChargeCode?: Maybe<Scalars['String']>;
  readonly carrierDescription?: Maybe<Scalars['String']>;
  readonly carrierId: Scalars['Guid'];
  readonly category?: Maybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: Maybe<Scalars['String']>;
  readonly cost?: Maybe<Scalars['Decimal']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['DateTime'];
  readonly customerId?: Maybe<Scalars['Guid']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly isAllowedByCarrier: Scalars['Boolean'];
  readonly isDefault: Scalars['Boolean'];
  readonly maximumTotalCost?: Maybe<Scalars['Decimal']>;
  readonly minimumTotalCost?: Maybe<Scalars['Decimal']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly perUnit?: Maybe<CtcPerUnitTypeEnum>;
  readonly size?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn: Scalars['DateTime'];
  readonly carrierApprovalRequired: Scalars['Boolean'];
  readonly costPerConfigurationChargeTypeIds?: Maybe<ReadonlyArray<Scalars['Guid']>>;
  readonly isTieredCostPerConfiguration?: Maybe<Scalars['Boolean']>;
  readonly carrierTieredCostSchedules?: Maybe<ReadonlyArray<Maybe<CarrierTieredCostSchedule>>>;
};

export type CarrierChargeTypeConfigurationUpdateInput = {
  readonly carrierChargeCode?: InputMaybe<Scalars['String']>;
  readonly carrierDescription?: InputMaybe<Scalars['String']>;
  readonly carrierId: Scalars['Guid'];
  readonly category?: InputMaybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly cost?: InputMaybe<Scalars['Decimal']>;
  readonly customerId?: InputMaybe<Scalars['Guid']>;
  readonly customerName?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly isAllowedByCarrier: Scalars['Boolean'];
  readonly maximumTotalCost?: InputMaybe<Scalars['Decimal']>;
  readonly minimumTotalCost?: InputMaybe<Scalars['Decimal']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly perUnit?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly carrierApprovalRequired?: InputMaybe<Scalars['Boolean']>;
  readonly costPerConfigurationChargeTypeIds?: InputMaybe<ReadonlyArray<Scalars['Guid']>>;
  readonly isTieredCostPerConfiguration?: InputMaybe<Scalars['Boolean']>;
  readonly carrierTieredCostSchedules?: InputMaybe<ReadonlyArray<CarrierTieredCostScheduleInput>>;
};

export type CarrierChargeTypeConfigurationUpdateInputV2 = {
  readonly id: Scalars['ID'];
  readonly carrierChargeCode?: InputMaybe<Scalars['String']>;
  readonly carrierDescription?: InputMaybe<Scalars['String']>;
  readonly carrierId: Scalars['Guid'];
  readonly category?: InputMaybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly customerId?: InputMaybe<Scalars['Guid']>;
  readonly customerName?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly carrierRegionConfigurations: ReadonlyArray<CarrierRegionConfigurationInput>;
};

export type CarrierChargeTypeConfigurationV2Response = {
  readonly __typename?: 'CarrierChargeTypeConfigurationV2Response';
  readonly isActive: Scalars['Boolean'];
  readonly isCredit: Scalars['Boolean'];
  readonly carrierChargeCode?: Maybe<Scalars['String']>;
  readonly carrierDescription?: Maybe<Scalars['String']>;
  readonly carrierId: Scalars['Guid'];
  readonly category?: Maybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['DateTime'];
  readonly customerId?: Maybe<Scalars['Guid']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly isDefault: Scalars['Boolean'];
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn: Scalars['DateTime'];
  readonly carrierRegionConfigurations?: Maybe<ReadonlyArray<CarrierRegionConfiguration>>;
};

export type CarrierChildrenHierarchy = {
  readonly __typename?: 'CarrierChildrenHierarchy';
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly children?: Maybe<Scalars['Json']>;
};

export type CarrierChildrenHierarchyInputV2 = {
  readonly carrierId: Scalars['String'];
  readonly level?: InputMaybe<Scalars['Int']>;
};

export type CarrierCommitment = {
  readonly __typename?: 'CarrierCommitment';
  readonly carrierCommitmentID: Scalars['ID'];
  readonly carrierCommitmentCode?: Maybe<Scalars['Int']>;
  readonly externalBidID?: Maybe<Scalars['String']>;
  readonly volume?: Maybe<Scalars['Int']>;
  readonly otherVolumes?: Maybe<ReadonlyArray<Maybe<CommitmentVolume>>>;
  readonly volumeEffectiveDateTime?: Maybe<Scalars['DateTimeOffset']>;
  readonly commitmentFrequencyTerm?: Maybe<Scalars['String']>;
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly carrierID?: Maybe<Scalars['ID']>;
  readonly costQuoteID?: Maybe<Scalars['ID']>;
  readonly laneID?: Maybe<Scalars['ID']>;
  readonly createdbyUserID?: Maybe<Scalars['ID']>;
  readonly lastUpdatedbyUserID?: Maybe<Scalars['ID']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  readonly routingGuide?: Maybe<RoutingGuide>;
  readonly costQuote?: Maybe<CarrierQuoteResponse>;
};

export type CarrierCommitmentConnection = {
  readonly __typename?: 'CarrierCommitmentConnection';
  readonly edges: ReadonlyArray<Maybe<CarrierCommitmentEdge>>;
  readonly totalCount: Scalars['Int'];
  readonly pageInfo: PageInfo;
};

export type CarrierCommitmentEdge = {
  readonly __typename?: 'CarrierCommitmentEdge';
  readonly cursor: Scalars['String'];
  readonly node: CarrierCommitment;
};

export type CarrierCommitmentFilter = {
  readonly carrierID?: InputMaybe<Scalars['ID']>;
  readonly laneID?: InputMaybe<Scalars['ID']>;
  readonly costQuoteID?: InputMaybe<Scalars['ID']>;
  readonly routeID?: InputMaybe<Scalars['ID']>;
  readonly routingGuideID?: InputMaybe<Scalars['ID']>;
  readonly includeActive?: InputMaybe<Scalars['Boolean']>;
};

export type CarrierCommitmentInputType = {
  readonly carrierCommitmentID?: InputMaybe<Scalars['ID']>;
  readonly carrierCommitmentCode?: InputMaybe<Scalars['Int']>;
  readonly externalBidID?: InputMaybe<Scalars['String']>;
  readonly volume?: InputMaybe<Scalars['Int']>;
  readonly volumeEffectiveDateTime?: InputMaybe<Scalars['DateTimeOffset']>;
  readonly commitmentFrequencyTerm?: InputMaybe<Scalars['String']>;
  readonly effectiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly carrierID?: InputMaybe<Scalars['ID']>;
  readonly costQuoteID?: InputMaybe<Scalars['ID']>;
  readonly laneID?: InputMaybe<Scalars['ID']>;
  readonly createdbyUserID?: InputMaybe<Scalars['ID']>;
  readonly lastUpdatedbyUserID?: InputMaybe<Scalars['ID']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly lastUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly quoteType?: InputMaybe<Scalars['String']>;
};

export type CarrierCommitmentRoutes = {
  readonly __typename?: 'CarrierCommitmentRoutes';
  readonly id?: Maybe<Scalars['ID']>;
  readonly frequencyStartDate?: Maybe<Scalars['DateTime']>;
  readonly frequencyEndDate?: Maybe<Scalars['DateTime']>;
  readonly carrierRoutes?: Maybe<ReadonlyArray<Maybe<LoadRouteV2>>>;
  readonly routesInfo?: Maybe<ReadonlyArray<Maybe<RoutesInfo>>>;
};

export type CarrierCommodityInputV2 = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly vendorCode?: InputMaybe<Scalars['String']>;
  readonly commodity: Scalars['String'];
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly dateLastUpdated?: InputMaybe<Scalars['ISO8601Date']>;
};

export type CarrierCommodityV2 = {
  readonly __typename?: 'CarrierCommodityV2';
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly commodity?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
};

/** The connection type for Carrier. */
export type CarrierConnection = {
  readonly __typename?: 'CarrierConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<CarrierEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** The connection type for Carrier. */
export type CarrierConnectionV2 = {
  readonly __typename?: 'CarrierConnectionV2';
  /** A list of edges. */
  readonly edges: ReadonlyArray<CarrierEdgeV2>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Information to aid in pagination. */
  readonly errors: ReadonlyArray<UserError>;
};

export type CarrierContact = {
  readonly __typename?: 'CarrierContact';
  readonly id: Scalars['ID'];
  readonly active: Scalars['Boolean'];
  readonly chatType?: Maybe<KeyValue>;
  readonly chatTypeId?: Maybe<Scalars['ID']>;
  readonly chatUsername?: Maybe<Scalars['String']>;
  readonly contactType: KeyValue;
  readonly contactTypeId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly extension?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly main: Scalars['Boolean'];
  readonly name?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
};

export type CarrierContactInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly vendorCode?: InputMaybe<Scalars['String']>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly chatTypeId?: InputMaybe<Scalars['ID']>;
  readonly chatUsername?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly contactTypeId?: InputMaybe<Scalars['ID']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
  readonly function?: InputMaybe<Scalars['String']>;
  readonly department?: InputMaybe<Scalars['String']>;
  readonly title?: InputMaybe<Scalars['String']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
};

export type CarrierContactValidation = {
  readonly __typename?: 'CarrierContactValidation';
  readonly id?: Maybe<Scalars['ID']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly isContactValid: Scalars['Boolean'];
  readonly rowId: Scalars['String'];
};

export type CarrierContactValidationInput = {
  readonly name: Scalars['String'];
  readonly emailAddress: Scalars['String'];
  readonly rowId: Scalars['String'];
};

export type CarrierCostQuotesInputType = {
  readonly costQuoteID?: InputMaybe<Scalars['ID']>;
  readonly carrierID?: InputMaybe<Scalars['ID']>;
};

/** Holds carrier costs. */
export type CarrierCostsByRouteConnection = {
  readonly __typename?: 'CarrierCostsByRouteConnection';
  readonly code?: Maybe<Scalars['String']>;
  readonly edges?: Maybe<ReadonlyArray<Maybe<RateDetailsEdgeV2>>>;
  readonly message?: Maybe<Scalars['String']>;
};

export type CarrierCostsByRouteFilter = {
  readonly applyRouteVendorReferences?: InputMaybe<Scalars['Boolean']>;
  readonly carrierId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
};

export type CarrierCrmDocument = {
  readonly __typename?: 'CarrierCrmDocument';
  readonly contacts?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  /** @deprecated Use createdByV2 instead */
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly createdDate?: Maybe<Scalars['DateTime']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly direction?: Maybe<Scalars['String']>;
  readonly documentId?: Maybe<Scalars['ID']>;
  readonly documentName?: Maybe<Scalars['String']>;
  readonly documentType?: Maybe<Scalars['String']>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly opportunities?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly opportunityId?: Maybe<Scalars['ID']>;
  readonly opportunityName?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
  /** @deprecated Use updatedByV2 instead */
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly updatedDate?: Maybe<Scalars['DateTime']>;
  readonly vendorId?: Maybe<Scalars['ID']>;
};

/** A connection to a list of items. */
export type CarrierCrmDocumentConnection = {
  readonly __typename?: 'CarrierCrmDocumentConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CarrierCrmDocumentEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CarrierCrmDocument>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CarrierCrmDocumentEdge = {
  readonly __typename?: 'CarrierCrmDocumentEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CarrierCrmDocument>;
};

export type CarrierCrmNotes = {
  readonly __typename?: 'CarrierCRMNotes';
  readonly contacts?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  /** @deprecated Use createdByV2 instead */
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly createdDate?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly opportunities?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly opportunityId?: Maybe<Scalars['ID']>;
  readonly opportunityName?: Maybe<Scalars['String']>;
  readonly reps?: Maybe<ReadonlyArray<Maybe<CarrierCrmNotesRep>>>;
  readonly type?: Maybe<Scalars['String']>;
  /** @deprecated Use updatedByV2 instead */
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly updatedDate?: Maybe<Scalars['DateTime']>;
  readonly vendorId?: Maybe<Scalars['ID']>;
};

/** A connection to a list of items. */
export type CarrierCrmNotesConnection = {
  readonly __typename?: 'CarrierCRMNotesConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CarrierCrmNotesEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CarrierCrmNotes>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CarrierCrmNotesEdge = {
  readonly __typename?: 'CarrierCRMNotesEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CarrierCrmNotes>;
};

export type CarrierCrmNotesFilter = {
  readonly vendorId: Scalars['ID'];
};

export type CarrierCrmNotesRep = {
  readonly __typename?: 'CarrierCRMNotesRep';
  /** @deprecated Use EmployeeV2 instead */
  readonly Employee?: Maybe<Employee>;
  readonly EmployeeV2?: Maybe<EmployeeV2>;
};

export type CarrierCrmOpportunity = {
  readonly __typename?: 'CarrierCRMOpportunity';
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly isDefault?: Maybe<Scalars['Boolean']>;
  readonly LastUpdatedByUser?: Maybe<User>;
  readonly lastUpdatedDate?: Maybe<Scalars['DateTime']>;
  readonly name: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly opportunityStatusId?: Maybe<Scalars['ID']>;
  readonly opportunityTypeId?: Maybe<Scalars['ID']>;
  readonly owningContactId?: Maybe<Scalars['ID']>;
  readonly OwningRep?: Maybe<Employee>;
  readonly vendorId: Scalars['ID'];
};

/** A connection to a list of items. */
export type CarrierCrmOpportunityConnection = {
  readonly __typename?: 'CarrierCRMOpportunityConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CarrierCrmOpportunityEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CarrierCrmOpportunity>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CarrierCrmOpportunityEdge = {
  readonly __typename?: 'CarrierCRMOpportunityEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CarrierCrmOpportunity>;
};

export type CarrierCrmOpportunityFilter = {
  readonly checkForDefaultOpportunity?: InputMaybe<Scalars['Boolean']>;
  readonly showInactive?: InputMaybe<Scalars['Boolean']>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
};

export type CarrierCustomerRelationship = {
  readonly __typename?: 'CarrierCustomerRelationship';
  readonly active: Scalars['Boolean'];
  readonly bypassCompliance: Scalars['Boolean'];
  readonly canLoad: Scalars['Boolean'];
  readonly carrier: Carrier;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdBy?: Maybe<User>;
  readonly customer: Customer;
  readonly id: Scalars['ID'];
  readonly notes?: Maybe<Note>;
  readonly relationshipType?: Maybe<CarrierCustomerRelationshipTypeEnum>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedBy?: Maybe<User>;
};

export type CarrierCustomerRelationshipInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly active: Scalars['Boolean'];
  readonly bypassCompliance?: InputMaybe<Scalars['Boolean']>;
  readonly canLoad: Scalars['Boolean'];
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly notes?: InputMaybe<ReadonlyArray<NoteInput>>;
  readonly relationshipType?: InputMaybe<CarrierCustomerRelationshipTypeEnum>;
};

export type CarrierCustomerRelationshipInputV2 = {
  readonly active: Scalars['Boolean'];
  readonly canLoad: Scalars['Boolean'];
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  /** relationshipType is deprecated. Use customerCarrierRelationshipType instead */
  readonly relationshipType?: InputMaybe<CarrierCustomerRelationshipTypeEnumV2>;
  readonly customerCarrierRelationshipType?: InputMaybe<Scalars['ID']>;
  /** notes is deprecated. Use customerCarrierRelationshipNotes instead */
  readonly notes?: InputMaybe<ReadonlyArray<NoteInputV2>>;
  readonly customerCarrierRelationshipNotes?: InputMaybe<Scalars['String']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly bypassCompliance?: InputMaybe<Scalars['Boolean']>;
};

export type CarrierCustomerRelationshipNote = {
  readonly __typename?: 'CarrierCustomerRelationshipNote';
  readonly carrierCustomerRelationshipId: Scalars['ID'];
  readonly createdAt: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly noteType: CarrierCustomerRelationshipNoteTypeEnum;
  readonly text?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['Iso8601Date'];
};

export enum CarrierCustomerRelationshipNoteTypeEnum {
  /** Carrier Customer Notes */
  CarrierCustomer = 'carrierCustomer'
}

export enum CarrierCustomerRelationshipTypeEnum {
  /** Core Carrier */
  CoreCarrier = 'coreCarrier',
  /** Dedicated Fleet */
  DedicatedFleet = 'dedicatedFleet',
  /** Owned Fleet */
  OwnedFleet = 'ownedFleet',
  /** Preferred */
  Preferred = 'preferred'
}

export enum CarrierCustomerRelationshipTypeEnumV2 {
  /** Owned Fleet */
  OwnedFleet = 'ownedFleet',
  /** Dedicated Fleet */
  DedicatedFleet = 'dedicatedFleet',
  /** Preferred */
  Preferred = 'preferred',
  /** Core Carrier */
  CoreCarrier = 'coreCarrier'
}

export type CarrierCustomerRelationshipV2 = {
  readonly __typename?: 'CarrierCustomerRelationshipV2';
  readonly active: Scalars['Boolean'];
  readonly bypassCompliance: Scalars['Boolean'];
  readonly canLoad: Scalars['Boolean'];
  readonly carrier: CarrierV2;
  readonly carrierId: Scalars['ID'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdBy?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly customer: CustomerV2;
  readonly customerId: Scalars['ID'];
  readonly id: Scalars['ID'];
  /** @deprecated Use customerCarrierRelationshipNotes instead */
  readonly notes?: Maybe<CarrierCustomerRelationshipNote>;
  readonly customerCarrierRelationshipNotes?: Maybe<Scalars['String']>;
  /** @deprecated Use customerCarrierRelationshipType instead */
  readonly relationshipType?: Maybe<CarrierCustomerRelationshipTypeEnumV2>;
  readonly customerCarrierRelationshipType?: Maybe<Scalars['ID']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedBy?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type CarrierDocuemntFilter = {
  readonly vendorId: Scalars['ID'];
};

export type CarrierDocument = {
  readonly __typename?: 'CarrierDocument';
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['String'];
  /** The name of the file to be displayed to the user, not necessarily the filename */
  readonly name: Scalars['String'];
  /** The name of the file provided during upload */
  readonly filename: Scalars['String'];
  readonly uploadedByUserId?: Maybe<Scalars['String']>;
  readonly uploadDate: Scalars['DateTime'];
  /** carrierDocumentSourceType KVT ID */
  readonly sourceId: Scalars['ID'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
  /** employeeDepartment KVT ID */
  readonly departmentTypeId: Scalars['ID'];
  /** carrierDocumentType KVT ID */
  readonly documentTypeId: Scalars['ID'];
  /** carrierThirdPartyDocument ID */
  readonly thirdPartyDocumentId?: Maybe<Scalars['ID']>;
  /** uri to access the document */
  readonly uri: Scalars['String'];
  /** uri to access the signed document */
  readonly signedUri?: Maybe<Scalars['String']>;
  /** MasterSign Reference Id */
  readonly masterSignReferenceId?: Maybe<Scalars['String']>;
  /** Identification for MasterSigner */
  readonly signedBy?: Maybe<Scalars['String']>;
  /** Timestamp for when document was signed */
  readonly signedAt?: Maybe<Scalars['DateTime']>;
  /**
   * Document Status
   * @deprecated Depcrecated in favor of documentStatus
   */
  readonly status?: Maybe<Scalars['String']>;
  /** Document Status */
  readonly documentStatus?: Maybe<DocumentStatusEnum>;
  /** Previous Document Status */
  readonly previousDocumentStatus?: Maybe<DocumentStatusEnum>;
  /** Identification for External Uploader */
  readonly externalUser?: Maybe<Scalars['String']>;
  /**
   * DEPRECATED: Use uploadedByUserV2 instead
   * @deprecated Use uploadedByUserV2 instead
   */
  readonly uploadedByUser?: Maybe<User>;
  readonly uploadedByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  /**
   * DEPRECATED: Use carrierV2 instead
   * @deprecated Use carrierV2 instead
   */
  readonly carrier: Carrier;
  readonly carrierV2: CarrierV2;
};

export type CarrierDocumentConnection = {
  readonly __typename?: 'CarrierDocumentConnection';
  readonly edges: ReadonlyArray<CarrierDocumentGqlTypeEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Float'];
};

export type CarrierDocumentFilter = {
  readonly carrierId: Scalars['ID'];
};

export type CarrierDocumentGqlTypeEdge = {
  readonly __typename?: 'CarrierDocumentGQLTypeEdge';
  readonly cursor: Scalars['String'];
  readonly node: CarrierDocument;
};

/** An edge in a connection. */
export type CarrierEdge = {
  readonly __typename?: 'CarrierEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: Carrier;
};

/** An edge in a connection. */
export type CarrierEdgeV2 = {
  readonly __typename?: 'CarrierEdgeV2';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: CarrierV2;
};

export type CarrierEdiTransactionConfiguration = {
  readonly __typename?: 'CarrierEdiTransactionConfiguration';
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['UUID'];
  readonly isEnabled: Scalars['Boolean'];
  readonly ediTransactionType: Scalars['Short'];
};

export type CarrierEdiTransactionConfigurationFilter = {
  readonly carrierId: Scalars['UUID'];
  readonly ediTransactionType?: InputMaybe<Scalars['Short']>;
};

export type CarrierEdiTransactionConfigurationInput = {
  readonly carrierId: Scalars['UUID'];
  readonly isEnabled: Scalars['Boolean'];
  readonly ediTransactionType: Scalars['Short'];
};

export type CarrierEdiTransactionsByRouteCodeFilter = {
  readonly routeCode?: InputMaybe<Scalars['String']>;
};

export type CarrierElectronicTracking = {
  readonly __typename?: 'CarrierElectronicTracking';
  readonly carrierId: Scalars['ID'];
  readonly electronicTrackingMethod?: Maybe<KeyValue>;
  readonly electronicTrackingMethodId: Scalars['ID'];
  readonly electronicTrackingType?: Maybe<KeyValue>;
  readonly electronicTrackingTypeId: Scalars['ID'];
  readonly priority: Scalars['Int'];
  readonly division?: Maybe<KeyValue>;
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type CarrierElectronicTrackingInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly electronicTrackingMethodId: Scalars['ID'];
  readonly electronicTrackingTypeId: Scalars['ID'];
  readonly priority: Scalars['Int'];
  readonly division?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly vendorCode?: InputMaybe<Scalars['String']>;
};

export type CarrierEquipmentInputV2 = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly vendorCode?: InputMaybe<Scalars['String']>;
  readonly equipmentAddedDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly equipmentCount?: InputMaybe<Scalars['Int']>;
  readonly equipmentDescription?: InputMaybe<Scalars['String']>;
  readonly equipmentCode?: InputMaybe<Scalars['String']>;
  readonly dateLastUpdated?: InputMaybe<Scalars['ISO8601Date']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
};

export type CarrierEquipmentV2 = {
  readonly __typename?: 'CarrierEquipmentV2';
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly equipmentAddedDate?: Maybe<Scalars['Iso8601Date']>;
  readonly equipmentCount?: Maybe<Scalars['Int']>;
  readonly equipmentDescription?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
};

export type CarrierExportNoteResponse = {
  readonly __typename?: 'CarrierExportNoteResponse';
  readonly id: Scalars['Uuid'];
  readonly vendorId: Scalars['Uuid'];
};

export type CarrierFacility = {
  readonly __typename?: 'CarrierFacility';
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['String'];
  readonly facilityId: Scalars['String'];
  readonly relationshipType?: Maybe<Scalars['String']>;
  readonly canLoad: Scalars['Boolean'];
  readonly isActive: Scalars['Boolean'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly updatedByUser?: Maybe<User>;
  readonly updatedByUserV2?: Maybe<UserV2>;
};

export type CarrierFinalizedFuelStatus = {
  readonly __typename?: 'CarrierFinalizedFuelStatus';
  readonly routeId: Scalars['ID'];
  readonly carrierFinalizedFuelStatus?: Maybe<Scalars['String']>;
};

export type CarrierFuelRangeCreateInput = {
  readonly carrierFuelRateHeaderId?: InputMaybe<Scalars['Guid']>;
  readonly minPrice?: InputMaybe<Scalars['Decimal']>;
  readonly maxPrice?: InputMaybe<Scalars['Decimal']>;
  readonly priceDecimal?: InputMaybe<Scalars['Decimal']>;
  readonly pricePercentage?: InputMaybe<Scalars['Decimal']>;
};

export type CarrierFuelRangeModel = {
  readonly __typename?: 'CarrierFuelRangeModel';
  readonly carrierFuelRangeId: Scalars['ID'];
  readonly carrierFuelRateHeaderId?: Maybe<Scalars['Guid']>;
  readonly createdByUserId?: Maybe<Scalars['Guid']>;
  readonly createdByDesc?: Maybe<Scalars['String']>;
  readonly createdOnUtc?: Maybe<Scalars['DateTime']>;
  readonly updatedByUserId?: Maybe<Scalars['Guid']>;
  readonly updatedByDesc?: Maybe<Scalars['String']>;
  readonly updatedOnUtc?: Maybe<Scalars['DateTime']>;
  readonly priceDecimal?: Maybe<Scalars['Decimal']>;
  readonly pricePercentage?: Maybe<Scalars['Decimal']>;
  readonly minPrice?: Maybe<Scalars['Decimal']>;
  readonly maxPrice?: Maybe<Scalars['Decimal']>;
};

export type CarrierFuelRangeResponse = {
  readonly __typename?: 'CarrierFuelRangeResponse';
  readonly carrierFuelRangeId: Scalars['ID'];
  readonly minPrice?: Maybe<Scalars['Decimal']>;
  readonly maxPrice?: Maybe<Scalars['Decimal']>;
  readonly priceDecimal?: Maybe<Scalars['Decimal']>;
  readonly pricePercentage?: Maybe<Scalars['Decimal']>;
};

export type CarrierFuelRangeUpdateInput = {
  readonly carrierFuelRangeId: Scalars['ID'];
  readonly carrierFuelRateHeaderId?: InputMaybe<Scalars['Guid']>;
  readonly minPrice?: InputMaybe<Scalars['Decimal']>;
  readonly maxPrice?: InputMaybe<Scalars['Decimal']>;
  readonly priceDecimal?: InputMaybe<Scalars['Decimal']>;
  readonly pricePercentage?: InputMaybe<Scalars['Decimal']>;
};

export type CarrierFuelRateHeaderCreateInput = {
  readonly fuelProgramCarrierConfigurationId: Scalars['ID'];
  readonly fuelApplicationMethod?: InputMaybe<Scalars['String']>;
  readonly fuelScale: Scalars['String'];
  readonly fuelWeekday?: InputMaybe<Scalars['String']>;
  readonly fuelSurchargeDate?: InputMaybe<Scalars['String']>;
  readonly fuelIndexType: Scalars['String'];
  readonly fuelSurchargeFrequency?: InputMaybe<Scalars['String']>;
};

export type CarrierFuelRateHeaderModel = {
  readonly __typename?: 'CarrierFuelRateHeaderModel';
  readonly carrierFuelRateHeaderId: Scalars['ID'];
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly createdByDesc?: Maybe<Scalars['String']>;
  readonly createdOnUtc?: Maybe<Scalars['DateTime']>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly updatedByDesc?: Maybe<Scalars['String']>;
  readonly updatedOnUtc?: Maybe<Scalars['DateTime']>;
  readonly fuelProgramCarrierConfigurationId: Scalars['ID'];
  readonly fuelApplicationMethod?: Maybe<Scalars['String']>;
  readonly fuelScale?: Maybe<Scalars['String']>;
  readonly fuelWeekday?: Maybe<Scalars['String']>;
  readonly fuelSurchargeDate?: Maybe<Scalars['String']>;
  readonly fuelIndexType?: Maybe<Scalars['String']>;
  readonly fuelSurchargeFrequency?: Maybe<Scalars['String']>;
};

export type CarrierFuelRateHeaderResponse = {
  readonly __typename?: 'CarrierFuelRateHeaderResponse';
  readonly carrierFuelRateHeaderId: Scalars['Guid'];
  readonly fuelProgramCarrierConfigurationId: Scalars['Guid'];
  readonly fuelApplicationMethod: Scalars['String'];
  readonly fuelScale: Scalars['String'];
  readonly fuelWeekday: Scalars['String'];
  readonly fuelSurchargeDate?: Maybe<Scalars['String']>;
  readonly fuelSurchargeFrequency: Scalars['String'];
  readonly fuelIndexType: Scalars['String'];
  readonly fuelRanges: ReadonlyArray<CarrierFuelRangeResponse>;
  readonly surcharges: ReadonlyArray<CarrierFuelSurchargeResponse>;
};

export type CarrierFuelSurchargeForExternalServices = {
  readonly __typename?: 'CarrierFuelSurchargeForExternalServices';
  readonly vendorId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly fuelSurcharge?: Maybe<Scalars['Decimal']>;
  readonly fuelProgramCarrierConfigurationModel: FuelProgramCarrierConfigurationModel;
  readonly units?: Maybe<Scalars['Decimal']>;
  readonly costPerUnit?: Maybe<Scalars['Decimal']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly totalCost?: Maybe<Scalars['Decimal']>;
  readonly enteredTotalCost?: Maybe<Scalars['Decimal']>;
  readonly chargeTypeId?: Maybe<Scalars['ID']>;
};

export type CarrierFuelSurchargeResponse = {
  readonly __typename?: 'CarrierFuelSurchargeResponse';
  readonly beginDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly averageDieselPrice?: Maybe<Scalars['Decimal']>;
  readonly fuelSurcharge?: Maybe<Scalars['Decimal']>;
};

export type CarrierGeography = {
  readonly __typename?: 'CarrierGeography';
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<User>;
  readonly geography: Geography;
  readonly id: Scalars['ID'];
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<User>;
};

export type CarrierGeographyInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly geographyId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type CarrierGeographyInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly geographyId: Scalars['ID'];
  readonly _destroy?: InputMaybe<Scalars['String']>;
};

export type CarrierGeographyV2 = {
  readonly __typename?: 'CarrierGeographyV2';
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly geography: GeographyV2;
  readonly geographyId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type CarrierGroup = {
  readonly __typename?: 'CarrierGroup';
  readonly carrierId: Scalars['String'];
  readonly employeeDivision?: Maybe<KeyValue>;
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly groupType?: Maybe<KeyValue>;
  readonly employeeDivisionId: Scalars['ID'];
  readonly employeeGroupId: Scalars['ID'];
  readonly groupTypeId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type CarrierGroupInput = {
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly carrierId: Scalars['ID'];
  readonly employeeDivisionId: Scalars['ID'];
  readonly employeeGroupId: Scalars['ID'];
  readonly groupTypeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type CarrierIdentifier = {
  readonly __typename?: 'CarrierIdentifier';
  readonly carrierId: Scalars['ID'];
  readonly carrierIdentifierType: KeyValue;
  readonly carrierIdentifierTypeId: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly link?: Maybe<Scalars['String']>;
};

export type CarrierIdentifierAuditedChanges = {
  readonly __typename?: 'CarrierIdentifierAuditedChanges';
  readonly id?: Maybe<Scalars['ID']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly link?: Maybe<Scalars['String']>;
  readonly carrierIdentifierType?: Maybe<Scalars['String']>;
};

export type CarrierIdentifierAuditV2 = AuditTypeInterfaceV2 & {
  readonly __typename?: 'CarrierIdentifierAuditV2';
  readonly actionType: Scalars['String'];
  readonly auditType: Scalars['String'];
  readonly auditableId: Scalars['ID'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByEmployee?: Maybe<EmployeeV2>;
  readonly currentValue?: Maybe<CarrierIdentifierAuditedChanges>;
  readonly id: Scalars['ID'];
  readonly previousValue?: Maybe<CarrierIdentifierAuditedChanges>;
  readonly userId?: Maybe<Scalars['ID']>;
};

export type CarrierIdentifierInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly carrierIdentifierTypeId: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly link?: InputMaybe<Scalars['String']>;
};

export type CarrierIdentifierInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierIdentifierTypeId: Scalars['ID'];
  readonly vendorCode?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly link?: InputMaybe<Scalars['String']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId?: InputMaybe<Scalars['ID']>;
};

export type CarrierIdentifierV2 = {
  readonly __typename?: 'CarrierIdentifierV2';
  readonly carrierId: Scalars['ID'];
  readonly vendorCode?: Maybe<Scalars['String']>;
  readonly carrierIdentifierType: KeyValue;
  readonly carrierIdentifierTypeId: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly link?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type CarrierInput = {
  readonly accountingNotes?: InputMaybe<ReadonlyArray<NoteInput>>;
  readonly addresses?: InputMaybe<ReadonlyArray<AddressInput>>;
  readonly allNotes?: InputMaybe<ReadonlyArray<NoteInput>>;
  readonly binQualified?: InputMaybe<Scalars['Boolean']>;
  readonly carrierComplianceEmail?: InputMaybe<Scalars['String']>;
  readonly carrierIdentifiers?: InputMaybe<ReadonlyArray<CarrierIdentifierInput>>;
  readonly carrierPaymentTermId?: InputMaybe<Scalars['ID']>;
  readonly carrierSalesProgressTypeId?: InputMaybe<Scalars['ID']>;
  readonly carrierServices?: InputMaybe<ReadonlyArray<CarrierServiceInput>>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly contacts?: InputMaybe<ReadonlyArray<ContactInput>>;
  readonly currencyId?: InputMaybe<Scalars['ID']>;
  readonly dbaName?: InputMaybe<Scalars['String']>;
  readonly dotNumber?: InputMaybe<Scalars['String']>;
  readonly dunsNumber?: InputMaybe<Scalars['String']>;
  readonly electronicTrackingMethodId?: InputMaybe<Scalars['ID']>;
  readonly electronicTrackingTypeId?: InputMaybe<Scalars['ID']>;
  readonly entityType?: InputMaybe<EntityTypeEnum>;
  readonly externalStatus?: InputMaybe<StatusTypeEnum>;
  readonly geographies?: InputMaybe<ReadonlyArray<CarrierGeographyInput>>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly insurances?: InputMaybe<ReadonlyArray<CarrierInsuranceInput>>;
  readonly interactions?: InputMaybe<ReadonlyArray<InteractionInput>>;
  readonly invoiceDistributionMethodId?: InputMaybe<Scalars['ID']>;
  readonly isAutoTruckEnabled?: InputMaybe<Scalars['Boolean']>;
  readonly levelId?: InputMaybe<Scalars['ID']>;
  readonly levelName?: InputMaybe<LevelTypeEnum>;
  readonly mainContactId?: InputMaybe<Scalars['ID']>;
  readonly manualTrackingInformation?: InputMaybe<Scalars['String']>;
  readonly manualTrackingMethod?: InputMaybe<ManualTrackingMethodEnum>;
  readonly mcNumber?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly nonCertifiedReasons?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly operatingStatus?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly opportunityName?: InputMaybe<OpportunityTypeEnum>;
  readonly parentId?: InputMaybe<Scalars['ID']>;
  readonly paymentMethodId?: InputMaybe<Scalars['ID']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly remitToCarrierId?: InputMaybe<Scalars['ID']>;
  readonly remittanceAddressId?: InputMaybe<Scalars['ID']>;
  readonly remittanceEmail?: InputMaybe<Scalars['String']>;
  readonly remittanceGrouping?: InputMaybe<RemittanceGroupingEnum>;
  readonly reps?: InputMaybe<ReadonlyArray<RepInput>>;
  readonly revenovaId?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly roeStatus?: InputMaybe<RoeStatusEnum>;
  readonly safetyRating?: InputMaybe<SafetyRatingTypeEnum>;
  readonly scacNumber?: InputMaybe<Scalars['String']>;
  readonly setting?: InputMaybe<NestedCarrierSettingInput>;
  readonly standing?: InputMaybe<Scalars['Json']>;
  readonly status?: InputMaybe<StatusTypeEnum>;
  readonly tempRemittanceGrouping?: InputMaybe<TempRemittanceGroupingEnum>;
  readonly updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Use w9_certified_nullable instead. */
  readonly w9Certified?: InputMaybe<Scalars['Boolean']>;
  readonly w9CertifiedNullable?: InputMaybe<Scalars['Boolean']>;
};

export type CarrierInputV2 = {
  readonly addresses?: InputMaybe<ReadonlyArray<CarrierAddressInput>>;
  readonly accountingNotes?: InputMaybe<ReadonlyArray<CarrierNoteInput>>;
  readonly allNotes?: InputMaybe<ReadonlyArray<CarrierNoteInput>>;
  readonly carrierIdentifiers?: InputMaybe<ReadonlyArray<CarrierIdentifierInputV2>>;
  readonly carrierServices?: InputMaybe<ReadonlyArray<CarrierServiceInputV2>>;
  readonly carrierPaymentTermId?: InputMaybe<Scalars['ID']>;
  /**  The field `carrierTypeId` is deprecated. Use `entityType` instead.  */
  readonly carrierTypeId?: InputMaybe<Scalars['ID']>;
  readonly carrierRatingBasedOnId?: InputMaybe<Scalars['ID']>;
  readonly certifications?: InputMaybe<ReadonlyArray<CreateCarrierCertificationInputV2>>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly vendorCode?: InputMaybe<Scalars['String']>;
  readonly contacts?: InputMaybe<ReadonlyArray<CarrierContactInput>>;
  readonly currencyId?: InputMaybe<Scalars['ID']>;
  readonly dbaName?: InputMaybe<Scalars['String']>;
  readonly complianceEmail?: InputMaybe<Scalars['String']>;
  readonly complianceEmailSource?: InputMaybe<Scalars['String']>;
  readonly dotNumber?: InputMaybe<Scalars['String']>;
  readonly dunsNumber?: InputMaybe<Scalars['String']>;
  readonly entityType?: InputMaybe<EntityTypeEnum>;
  readonly electronicTrackings?: InputMaybe<ReadonlyArray<CarrierElectronicTrackingInput>>;
  readonly electronicTrackingMethodId?: InputMaybe<Scalars['ID']>;
  readonly electronicTrackingTypeId?: InputMaybe<Scalars['ID']>;
  readonly equipments?: InputMaybe<ReadonlyArray<CarrierEquipmentInputV2>>;
  readonly businessQuestion?: InputMaybe<CarrierBusinessQuestionInputV2>;
  readonly modes?: InputMaybe<ReadonlyArray<CarrierModeInputV2>>;
  readonly commodities?: InputMaybe<ReadonlyArray<CarrierCommodityInputV2>>;
  readonly authorizedDivisions?: InputMaybe<ReadonlyArray<CarrierAuthorizedDivisionInputV2>>;
  /**  The field `externalStatus` is deprecated. Use `standing` field instead.  */
  readonly externalStatus?: InputMaybe<StatusTypeEnum>;
  readonly geographies?: InputMaybe<ReadonlyArray<CarrierGeographyInputV2>>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly insurances?: InputMaybe<ReadonlyArray<CarrierInsuranceInputV2>>;
  readonly interactions?: InputMaybe<ReadonlyArray<InteractionInputV2>>;
  readonly invoiceDistributionMethodId?: InputMaybe<Scalars['ID']>;
  readonly isAutoTruckEnabled?: InputMaybe<Scalars['Boolean']>;
  readonly levelId?: InputMaybe<Scalars['ID']>;
  readonly levelName?: InputMaybe<LevelTypeEnum>;
  readonly mainContactId?: InputMaybe<Scalars['ID']>;
  readonly manualTrackingInformation?: InputMaybe<Scalars['String']>;
  readonly manualTrackingMethod?: InputMaybe<ManualTrackingMethodEnum>;
  readonly mcNumber?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly nonCertifiedReasons?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly operatingStatus?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly opportunityName?: InputMaybe<OpportunityTypeEnum>;
  readonly organizationId?: InputMaybe<Scalars['ID']>;
  /**  The field `parentId` is deprecated. To Create Or Update parent, use `createOrUpdateCarrierCarrierRelationship` Mutation instead.  */
  readonly parentId?: InputMaybe<Scalars['ID']>;
  readonly paymentMethodId?: InputMaybe<Scalars['ID']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly roeStatus?: InputMaybe<Scalars['String']>;
  readonly roeStatusId?: InputMaybe<Scalars['ID']>;
  readonly remitToCarrierId?: InputMaybe<Scalars['ID']>;
  readonly remittanceAddressId?: InputMaybe<Scalars['ID']>;
  readonly remittanceEmail?: InputMaybe<Scalars['String']>;
  readonly remittanceGrouping?: InputMaybe<RemittanceGroupingEnum>;
  readonly reps?: InputMaybe<ReadonlyArray<RepInputV2>>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly externalSource?: InputMaybe<Scalars['String']>;
  readonly revenovaId?: InputMaybe<Scalars['String']>;
  readonly safetyRating?: InputMaybe<SafetyRatingTypeEnum>;
  readonly safetyRatingDate?: InputMaybe<Scalars['Iso8601Date']>;
  readonly scacNumber?: InputMaybe<Scalars['String']>;
  readonly setting?: InputMaybe<NestedCarrierSettingInputV2>;
  readonly status?: InputMaybe<StatusTypeEnum>;
  readonly tempRemittanceGrouping?: InputMaybe<TempRemittanceGroupingEnum>;
  readonly updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly standing?: InputMaybe<Scalars['Json']>;
  readonly intrastateStates?: InputMaybe<Scalars['Json']>;
  /**  The field `tXIntraStateAuthorityCertificate` is deprecated. Use `txIntrastateAuthority` instead.  */
  readonly tXIntraStateAuthorityCertificate?: InputMaybe<Scalars['String']>;
  /**  The field `tXIntraStateAuthorityStatus` is deprecated. Use `txIntrastateAuthority` instead.  */
  readonly tXIntraStateAuthorityStatus?: InputMaybe<Scalars['String']>;
  /**  The field `intrastateAuthorityNumber` is deprecated. Use `intrastateAuthority` instead.  */
  readonly intrastateAuthorityNumber?: InputMaybe<Scalars['Int']>;
  /**  The field `intraStateStatus` is deprecated. Use `intrastateAuthority` instead.  */
  readonly intraStateStatus?: InputMaybe<Scalars['String']>;
  readonly txIntrastateAuthority?: InputMaybe<ReadonlyArray<TxIntrastateAuthorityInput>>;
  readonly intrastateAuthority?: InputMaybe<ReadonlyArray<IntrastateAuthorityInput>>;
  readonly carrierSalesProgressTypeId?: InputMaybe<Scalars['ID']>;
  /** Use w9_certified_nullable instead. */
  readonly w9Certified?: InputMaybe<Scalars['Boolean']>;
  readonly w9CertifiedNullable?: InputMaybe<Scalars['Boolean']>;
  readonly binQualified?: InputMaybe<Scalars['Boolean']>;
  readonly website?: InputMaybe<Scalars['String']>;
};

export type CarrierInsurance = {
  readonly __typename?: 'CarrierInsurance';
  readonly cancelDate?: Maybe<Scalars['Iso8601Date']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly carrierInsuranceType?: Maybe<KeyValue>;
  readonly carrierInsuranceTypeId?: Maybe<Scalars['ID']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly effectiveDate?: Maybe<Scalars['Iso8601Date']>;
  readonly exemptions?: Maybe<Scalars['String']>;
  readonly expirationDate: Scalars['Iso8601Date'];
  readonly id: Scalars['ID'];
  readonly identification: Scalars['String'];
  /** @deprecated use carrierInsuranceTypeId or carrierInsuranceType */
  readonly insuranceType?: Maybe<CarrierInsuranceTypeEnum>;
  readonly insurer: Scalars['String'];
  readonly limit: Scalars['Int'];
  readonly producer: Scalars['String'];
  readonly rmisCovgId?: Maybe<Scalars['Int']>;
  readonly rmisDocumentId?: Maybe<Scalars['String']>;
  readonly underwriterRating?: Maybe<UnderwriterRatingTypeEnum>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
};

export type CarrierInsuranceInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly cancelDate?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly carrierInsuranceTypeId?: InputMaybe<Scalars['ID']>;
  readonly effectiveDate?: InputMaybe<Scalars['String']>;
  readonly exemptions?: InputMaybe<Scalars['String']>;
  readonly expirationDate: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly identification: Scalars['String'];
  readonly insuranceType?: InputMaybe<CarrierInsuranceTypeEnum>;
  readonly insurer: Scalars['String'];
  readonly limit: Scalars['Int'];
  readonly producer: Scalars['String'];
  readonly rmisCovgId?: InputMaybe<Scalars['String']>;
  readonly underwriterRating?: InputMaybe<UnderwriterRatingTypeEnum>;
};

export type CarrierInsuranceInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly carrierInsuranceTypeId?: InputMaybe<Scalars['ID']>;
  readonly insuranceType?: InputMaybe<CarrierInsuranceTypeEnum>;
  readonly vendorCode?: InputMaybe<Scalars['String']>;
  readonly producer: Scalars['String'];
  readonly insurer: Scalars['String'];
  readonly identification: Scalars['String'];
  readonly limit: Scalars['Int'];
  readonly expirationDate: Scalars['String'];
  readonly effectiveDate?: InputMaybe<Scalars['String']>;
  readonly cancelDate?: InputMaybe<Scalars['String']>;
  readonly rmisCovgId?: InputMaybe<Scalars['String']>;
  readonly exemptions?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<Scalars['String']>;
  readonly producerPhone?: InputMaybe<Scalars['String']>;
  readonly producerFax?: InputMaybe<Scalars['String']>;
  readonly producerEmail?: InputMaybe<Scalars['String']>;
  readonly producerCountry?: InputMaybe<Scalars['String']>;
  readonly confidence?: InputMaybe<Scalars['String']>;
  readonly confidenceMessage?: InputMaybe<Scalars['String']>;
  readonly limitDescription?: InputMaybe<Scalars['String']>;
  readonly naicCompanyNumber?: InputMaybe<Scalars['String']>;
  readonly amBestCompanyNumber?: InputMaybe<Scalars['String']>;
  readonly producerAddress?: InputMaybe<Scalars['String']>;
  readonly producerCity?: InputMaybe<Scalars['String']>;
  readonly producerState?: InputMaybe<Scalars['String']>;
  readonly producerZip?: InputMaybe<Scalars['String']>;
  readonly limitOfCoverageCurrency?: InputMaybe<Scalars['String']>;
  readonly deductible?: InputMaybe<Scalars['Int']>;
  readonly deductibleCurrency?: InputMaybe<Scalars['String']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly underwriterRating?: InputMaybe<UnderwriterRatingTypeEnum>;
  readonly underwriterRatingId?: InputMaybe<Scalars['String']>;
};

export type CarrierInsuranceRiskInformationInput = {
  readonly amount: Scalars['Int'];
  readonly cancelDate?: InputMaybe<Scalars['String']>;
  readonly effectiveDate?: InputMaybe<Scalars['String']>;
  readonly exemptions?: InputMaybe<Scalars['String']>;
  readonly expirationDate: Scalars['String'];
  readonly insuranceType?: InputMaybe<CarrierInsuranceTypeEnum>;
  readonly insurer: Scalars['String'];
  readonly policyNumber?: InputMaybe<Scalars['String']>;
  readonly producer: Scalars['String'];
  readonly rmisCovgId: Scalars['String'];
  readonly rmisDocumentId?: InputMaybe<Scalars['String']>;
  readonly underwriterRating?: InputMaybe<UnderwriterRatingTypeEnum>;
};

export enum CarrierInsuranceTypeEnum {
  /** Cargo */
  Cargo = 'Cargo',
  /** Liability */
  Liability = 'Liability',
  /** General */
  General = 'General',
  /** Other */
  Other = 'Other',
  /** BIPD */
  Bipd = 'BIPD',
  /** Auto */
  Auto = 'Auto'
}

export type CarrierInsuranceV2 = {
  readonly __typename?: 'CarrierInsuranceV2';
  readonly cancelDate?: Maybe<Scalars['Iso8601Date']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly carrierInsuranceType?: Maybe<KeyValue>;
  readonly carrierInsuranceTypeId?: Maybe<Scalars['ID']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly effectiveDate?: Maybe<Scalars['Iso8601Date']>;
  readonly exemptions?: Maybe<Scalars['String']>;
  readonly expirationDate: Scalars['Iso8601Date'];
  readonly id: Scalars['ID'];
  readonly identification: Scalars['String'];
  /** @deprecated use carrierInsuranceTypeId or carrierInsuranceType */
  readonly insuranceType?: Maybe<CarrierInsuranceTypeEnum>;
  readonly insurer: Scalars['String'];
  readonly limit: Scalars['Int'];
  readonly producer: Scalars['String'];
  readonly rmisCovgId?: Maybe<Scalars['Int']>;
  readonly rmisDocumentId?: Maybe<Scalars['String']>;
  readonly underwriterRating?: Maybe<UnderwriterRatingTypeEnum>;
  readonly underwriterRatingType?: Maybe<KeyValue>;
  readonly status?: Maybe<Scalars['String']>;
  readonly producerPhone?: Maybe<Scalars['String']>;
  readonly producerFax?: Maybe<Scalars['String']>;
  readonly producerEmail?: Maybe<Scalars['String']>;
  readonly confidence?: Maybe<Scalars['String']>;
  readonly confidenceMessage?: Maybe<Scalars['String']>;
  readonly limitDescription?: Maybe<Scalars['String']>;
  readonly naicCompanyNumber?: Maybe<Scalars['String']>;
  readonly amBestCompanyNumber?: Maybe<Scalars['String']>;
  readonly producerAddress?: Maybe<Scalars['String']>;
  readonly producerCity?: Maybe<Scalars['String']>;
  readonly producerState?: Maybe<Scalars['String']>;
  readonly producerZip?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly limitOfCoverageCurrency?: Maybe<Scalars['String']>;
  readonly deductible?: Maybe<Scalars['Int']>;
  readonly deductibleCurrency?: Maybe<Scalars['String']>;
};

export type CarrierInteraction = {
  readonly __typename?: 'CarrierInteraction';
  readonly CarrierV2?: Maybe<CarrierV2>;
  readonly contactId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly interactionDate: Scalars['DateTime'];
  readonly interactionOutcomeTypeId?: Maybe<Scalars['ID']>;
  readonly interactionTypeId?: Maybe<Scalars['ID']>;
  readonly interactionViaTypeId?: Maybe<Scalars['ID']>;
  readonly nextInteractionDate?: Maybe<Scalars['DateTime']>;
  readonly NextInteractionRep?: Maybe<Employee>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly opportunityId?: Maybe<Scalars['ID']>;
  readonly opportunityName?: Maybe<Scalars['String']>;
  readonly Rep?: Maybe<Employee>;
  readonly vendor?: Maybe<Carrier>;
};

/** A connection to a list of items. */
export type CarrierInteractionConnection = {
  readonly __typename?: 'CarrierInteractionConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CarrierInteractionEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CarrierInteraction>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CarrierInteractionEdge = {
  readonly __typename?: 'CarrierInteractionEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CarrierInteraction>;
};

export type CarrierInteractionFilter = {
  readonly vendorId?: InputMaybe<Scalars['ID']>;
};

export enum CarrierInteractionFrequencyEnum {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Bimonthly = 'BIMONTHLY'
}

export type CarrierIntrastateInput = {
  readonly carrier: CarrierArgumentInput;
  readonly intrastateStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type CarrierIntrastateInputV2 = {
  readonly carrier: CarrierArgumentInputV2;
  readonly intrastateStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /**  The field `tXIntraStateAuthorityCertificate` is deprecated. Use `txIntrastateAuthority` instead.  */
  readonly tXIntraStateAuthorityCertificate?: InputMaybe<Scalars['String']>;
  /**  The field `tXIntraStateAuthorityStatus` is deprecated. Use `txIntrastateAuthority` instead.  */
  readonly tXIntraStateAuthorityStatus?: InputMaybe<Scalars['String']>;
  /**  The field `intrastateAuthorityNumber` is deprecated. Use `intrastateAuthority` instead.  */
  readonly intrastateAuthorityNumber?: InputMaybe<Scalars['Int']>;
  /**  The field `intraStateStatus` is deprecated. Use `intrastateAuthority` instead.  */
  readonly intraStateStatus?: InputMaybe<Scalars['String']>;
  /**  The field `txIntrastateAuthority` is deprecated. Use `txIntrastateStateAuthority` instead.  */
  readonly txIntrastateAuthority?: InputMaybe<ReadonlyArray<TxIntrastateAuthorityInput>>;
  /**  The field `intrastateAuthority` is deprecated. Use `intrastateStateAuthority` instead.  */
  readonly intrastateAuthority?: InputMaybe<ReadonlyArray<IntrastateAuthorityInput>>;
  readonly txIntrastateStateAuthority?: InputMaybe<TxIntrastateAuthorityInput>;
  readonly intrastateStateAuthority?: InputMaybe<IntrastateAuthorityInput>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
};

export type CarrierIntrastateV2 = {
  readonly __typename?: 'CarrierIntrastateV2';
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly states?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** @deprecated The field `tXIntraStateAuthorityCertificate` is deprecated. */
  readonly tXIntraStateAuthorityCertificate?: Maybe<Scalars['String']>;
  /** @deprecated The field `tXIntraStateAuthorityStatus` is deprecated. */
  readonly tXIntraStateAuthorityStatus?: Maybe<Scalars['String']>;
  /** @deprecated The field `intrastateAuthorityNumber` is deprecated. */
  readonly intrastateAuthorityNumber?: Maybe<Scalars['Int']>;
  /** @deprecated The field `intraStateStatus` is deprecated. */
  readonly intraStateStatus?: Maybe<Scalars['String']>;
  /** @deprecated The field `txIntrastateAuthority` is deprecated. */
  readonly txIntrastateAuthority?: Maybe<ReadonlyArray<TxIntrastateAuthority>>;
  /** @deprecated The field `intrastateAuthority` is deprecated. */
  readonly intrastateAuthority?: Maybe<ReadonlyArray<IntrastateAuthority>>;
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
};

export type CarrierList = {
  readonly __typename?: 'CarrierList';
  readonly id: Scalars['ID'];
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly mainAddressCity?: Maybe<Scalars['String']>;
  readonly mainAddressState?: Maybe<Scalars['String']>;
  readonly mainRepName?: Maybe<Scalars['String']>;
  readonly mainRepId?: Maybe<Scalars['String']>;
  readonly complianceInternalStatus?: Maybe<Scalars['String']>;
  readonly carrierSalesProgress?: Maybe<Scalars['String']>;
  readonly carrierLevel?: Maybe<Scalars['String']>;
  readonly carrierOpportunity?: Maybe<Scalars['String']>;
  readonly interactionFrequency?: Maybe<Scalars['String']>;
  readonly lastInteractionDatetime?: Maybe<Scalars['DateTime']>;
  readonly lastInteractionCompletedByRepId?: Maybe<Scalars['String']>;
  readonly lastInteractionCompletedByRepName?: Maybe<Scalars['String']>;
  readonly lastInteractionDetails?: Maybe<Scalars['String']>;
  readonly nextInteractionDatetime?: Maybe<Scalars['DateTime']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
};

export type CarrierListConnection = {
  readonly __typename?: 'CarrierListConnection';
  readonly edges: ReadonlyArray<CarrierListEdge>;
  readonly pageInfo: PageInfo;
};

export type CarrierListEdge = {
  readonly __typename?: 'CarrierListEdge';
  readonly node: CarrierList;
  readonly cursor: Scalars['String'];
};

export type CarrierListFilterInput = {
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly groupId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly organizationId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
};

export type CarrierModeInputV2 = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly vendorCode?: InputMaybe<Scalars['String']>;
  readonly modeDescription?: InputMaybe<Scalars['String']>;
  readonly dateLastUpdated?: InputMaybe<Scalars['ISO8601Date']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
};

export type CarrierModeV2 = {
  readonly __typename?: 'CarrierModeV2';
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly modeDescription?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
};

export type CarrierNote = {
  readonly __typename?: 'CarrierNote';
  readonly carrierId: Scalars['ID'];
  readonly createdAt: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly noteType: CarrierNoteTypeEnum;
  readonly text?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['Iso8601Date'];
};

export type CarrierNoteInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly noteType?: InputMaybe<CarrierNoteTypeEnum>;
  readonly text?: InputMaybe<Scalars['String']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
};

export enum CarrierNoteTypeEnum {
  /** Carrier Accounting */
  CarrierAccounting = 'carrierAccounting',
  /** Carrier Confirmation */
  CarrierConfirmation = 'carrierConfirmation'
}

/** Carrier Payment Term */
export type CarrierPaymentTerm = {
  readonly __typename?: 'CarrierPaymentTerm';
  readonly id: Scalars['ID'];
  readonly carrierPaymentTermId: Scalars['ID'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly discountDateCalculation?: Maybe<Scalars['Int']>;
  readonly discountPercent?: Maybe<Scalars['Decimal']>;
  readonly dueDateCalculation?: Maybe<Scalars['Int']>;
  /** @deprecated Use carrierPaymentTermId */
  readonly integratedSystemCode?: Maybe<Scalars['String']>;
};

export type CarrierPaymentTermAddInput = {
  readonly carrierPaymentTermId: Scalars['ID'];
  readonly discountDateCalculation?: InputMaybe<Scalars['Int']>;
  readonly discountPercent?: InputMaybe<Scalars['Decimal']>;
  readonly dueDateCalculation?: InputMaybe<Scalars['Decimal']>;
};

export type CarrierPaymentTermUpdateInput = {
  readonly id: Scalars['ID'];
  readonly discountDateCalculation?: InputMaybe<Scalars['Int']>;
  readonly discountPercent?: InputMaybe<Scalars['Decimal']>;
  readonly dueDateCalculation?: InputMaybe<Scalars['Decimal']>;
};

export type CarrierPortfolio = {
  readonly __typename?: 'CarrierPortfolio';
  readonly edges?: Maybe<ReadonlyArray<Maybe<CarrierPortfolioEdge>>>;
  readonly pageInfo?: Maybe<PortfolioPageInfo>;
};

export type CarrierPortfolioEdge = {
  readonly __typename?: 'CarrierPortfolioEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<CarrierPortfolioEntity>;
};

export type CarrierPortfolioEntity = {
  readonly __typename?: 'carrierPortfolioEntity';
  readonly carrier?: Maybe<Carrier>;
  readonly CarrierV2?: Maybe<CarrierV2>;
  readonly lastInteraction?: Maybe<CarrierPortfolioInteraction>;
};

export type CarrierPortfolioInputType = {
  readonly filter?: InputMaybe<CarriersWithInteractionFilterInputType>;
};

export type CarrierPortfolioInteraction = {
  readonly __typename?: 'CarrierPortfolioInteraction';
  readonly carrierInteractionFrequency?: Maybe<Scalars['ID']>;
  readonly contactId?: Maybe<Scalars['ID']>;
  readonly interactionDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Enum values are not used anymore usnig KVT values. New field of string type 'CarrierInteractionFrequency' is added  */
  readonly interactionFrequency?: Maybe<CarrierInteractionFrequencyEnum>;
  readonly nextInteractionDate?: Maybe<Scalars['DateTime']>;
  readonly notes?: Maybe<Scalars['String']>;
};

export type CarrierPreferenceInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly allowInterlining?: InputMaybe<Scalars['Boolean']>;
  readonly interliningType?: InputMaybe<Scalars['String']>;
  readonly carrierDefaultSelection?: InputMaybe<Scalars['String']>;
  readonly customerCode?: InputMaybe<Scalars['String']>;
};

export type CarrierPreferenceResponse = {
  readonly __typename?: 'CarrierPreferenceResponse';
  readonly id?: Maybe<Scalars['String']>;
  readonly allowInterlining?: Maybe<Scalars['Boolean']>;
  readonly interliningType?: Maybe<Scalars['String']>;
  readonly carrierDefaultSelection?: Maybe<Scalars['String']>;
  readonly customerCode?: Maybe<Scalars['String']>;
};

export type CarrierPreferenceResponsePayloads = {
  readonly __typename?: 'CarrierPreferenceResponsePayloads';
  readonly carrierPreferenceResponses?: Maybe<ReadonlyArray<CarrierPreferenceResponse>>;
};

export type CarrierQuoteInputType = {
  readonly costQuoteId?: InputMaybe<Scalars['ID']>;
  readonly quoteTypeId: Scalars['String'];
  readonly createdById?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly effectiveDateTime?: InputMaybe<Scalars['DateTimeOffset']>;
  readonly minimum?: InputMaybe<Scalars['Decimal']>;
  readonly expirationDateTime?: InputMaybe<Scalars['DateTimeOffset']>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly scac?: InputMaybe<Scalars['String']>;
  readonly multipleStops?: InputMaybe<Scalars['Boolean']>;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly quotedById?: InputMaybe<Scalars['ID']>;
  readonly minHeight?: InputMaybe<CostQuoteUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<CostQuoteUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<CostQuoteUnitOfDistanceInput>;
  readonly lineItems?: InputMaybe<ReadonlyArray<InputMaybe<CostQuoteLineItemInput>>>;
  readonly routeVendorReferences?: InputMaybe<ReadonlyArray<RouteVendorReferenceInput>>;
  readonly costQuoteRouteVendorReference?: InputMaybe<ReadonlyArray<CostQuoteRouteVendorReferenceInput>>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly services?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<CostQuoteLaneStopsInput>>>;
  readonly stopsV2?: InputMaybe<ReadonlyArray<InputMaybe<CostQuoteLaneStopsInputV2>>>;
  readonly transportMode?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly laneType?: InputMaybe<Scalars['String']>;
  readonly distanceType?: InputMaybe<Scalars['Boolean']>;
  readonly isContinue?: InputMaybe<Scalars['Boolean']>;
  readonly externalPricing?: InputMaybe<Scalars['Boolean']>;
  readonly routeType?: InputMaybe<Scalars['String']>;
};

export type CarrierQuoteResponse = {
  readonly __typename?: 'CarrierQuoteResponse';
  readonly id: Scalars['ID'];
  readonly carrierCapacityV2?: Maybe<CarrierCapacityV2>;
  readonly laneId?: Maybe<Scalars['ID']>;
  readonly allTotalRate?: Maybe<CurrencyValue>;
  readonly allTotalRateV2?: Maybe<CurrencyOutputValue>;
  readonly code?: Maybe<Scalars['Int']>;
  readonly createdAtDateTime?: Maybe<Scalars['DateTime']>;
  readonly createdById?: Maybe<Scalars['ID']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly effectiveDateTime?: Maybe<Scalars['DateTimeOffset']>;
  readonly expirationDateTime?: Maybe<Scalars['DateTimeOffset']>;
  readonly extrasTotalRate?: Maybe<CurrencyValue>;
  readonly extrasTotalRateV2?: Maybe<CurrencyOutputValue>;
  readonly fuelTotalRate?: Maybe<CurrencyValue>;
  readonly fuelTotalRateV2?: Maybe<CurrencyOutputValue>;
  readonly linehaulTotalRate?: Maybe<CurrencyValue>;
  readonly linehaulTotalRateV2?: Maybe<CurrencyOutputValue>;
  readonly multipleStops?: Maybe<Scalars['Boolean']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly quoteType: TenantConfiguration;
  /** @deprecated Use ScacNumber instead */
  readonly scac?: Maybe<TenantConfiguration>;
  readonly scacNumber?: Maybe<Scalars['String']>;
  readonly quotedById?: Maybe<Scalars['ID']>;
  readonly team?: Maybe<Scalars['Boolean']>;
  readonly minimum?: Maybe<Scalars['Decimal']>;
  readonly lineItems?: Maybe<ReadonlyArray<Maybe<CostQuoteLineItem>>>;
  /** @deprecated  It is no longer used. Use CostQuoteRouteVendorReferences instead */
  readonly routeVendorReferences?: Maybe<ReadonlyArray<RouteVendorReferences>>;
  readonly costQuoteRouteVendorReferences?: Maybe<ReadonlyArray<CostQuoteRouteVendorReferences>>;
  readonly createdBy?: Maybe<User>;
  readonly quotedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly quotedByV2?: Maybe<UserV2>;
  readonly lane?: Maybe<LaneV2>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly carrierCapacity?: Maybe<CarrierCapacity>;
  readonly distanceType?: Maybe<Scalars['Boolean']>;
  readonly externalPricing?: Maybe<Scalars['Boolean']>;
  readonly routeType?: Maybe<TenantConfiguration>;
};

export type CarrierRegionConfiguration = {
  readonly __typename?: 'CarrierRegionConfiguration';
  readonly id: Scalars['ID'];
  readonly cost?: Maybe<Scalars['Decimal']>;
  readonly regionId?: Maybe<Scalars['Guid']>;
  readonly perUnit?: Maybe<CtcPerUnitTypeEnum>;
  readonly maximumTotalCost?: Maybe<Scalars['Decimal']>;
  readonly minimumTotalCost?: Maybe<Scalars['Decimal']>;
  readonly isAllowedByCarrier: Scalars['Boolean'];
  readonly carrierApprovalRequired: Scalars['Boolean'];
  readonly costPerConfigurationChargeTypeIds?: Maybe<ReadonlyArray<Scalars['Guid']>>;
  readonly isTieredCostPerConfiguration?: Maybe<Scalars['Boolean']>;
  readonly carrierTieredCostSchedules?: Maybe<ReadonlyArray<Maybe<CarrierTieredCostSchedule>>>;
};

export type CarrierRegionConfigurationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly regionId?: InputMaybe<Scalars['Guid']>;
  readonly cost?: InputMaybe<Scalars['Decimal']>;
  readonly maximumTotalCost?: InputMaybe<Scalars['Decimal']>;
  readonly minimumTotalCost?: InputMaybe<Scalars['Decimal']>;
  readonly isAllowedByCarrier: Scalars['Boolean'];
  readonly carrierApprovalRequired?: InputMaybe<Scalars['Boolean']>;
  readonly perUnit?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly costPerConfigurationChargeTypeIds?: InputMaybe<ReadonlyArray<Scalars['Guid']>>;
  readonly isTieredCostPerConfiguration?: InputMaybe<Scalars['Boolean']>;
  readonly carrierTieredCostSchedules?: InputMaybe<ReadonlyArray<CarrierTieredCostScheduleInput>>;
};

export type CarrierReleaseMethod = {
  readonly __typename?: 'CarrierReleaseMethod';
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly releaseMethod?: Maybe<KeyValue>;
  readonly releaseMethodKey: Scalars['String'];
  readonly isReleaseNeeded?: Maybe<Scalars['Boolean']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type CarrierReleaseMethodInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly releaseMethodKey: Scalars['String'];
  readonly isReleaseNeeded?: InputMaybe<Scalars['Boolean']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
};

export type CarrierReleaseMethodInputV1 = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isReleaseNeeded?: InputMaybe<Scalars['Boolean']>;
  readonly releaseMethodKey: Scalars['String'];
};

export type CarrierReleaseMethodV1 = {
  readonly __typename?: 'CarrierReleaseMethodV1';
  readonly carrierId: Scalars['ID'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly isReleaseNeeded?: Maybe<Scalars['Boolean']>;
  readonly releaseMethod?: Maybe<KeyValue>;
  readonly releaseMethodKey: Scalars['String'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type CarrierRepAudit = AuditTypeInterface & {
  readonly __typename?: 'CarrierRepAudit';
  readonly actionType: Scalars['String'];
  readonly auditType: Scalars['String'];
  readonly auditableId: Scalars['ID'];
  readonly auditableRep?: Maybe<Rep>;
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByEmployee?: Maybe<Employee>;
  readonly currentValue?: Maybe<CarrierRepAuditedChanges>;
  readonly id: Scalars['ID'];
  readonly previousValue?: Maybe<CarrierRepAuditedChanges>;
};

export type CarrierRepAuditedChanges = {
  readonly __typename?: 'CarrierRepAuditedChanges';
  readonly employeeId?: Maybe<Scalars['ID']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly main?: Maybe<Scalars['Boolean']>;
  readonly repType?: Maybe<Scalars['ID']>;
  readonly active?: Maybe<Scalars['Boolean']>;
};

export type CarrierRepAuditV2 = AuditTypeInterfaceV2 & {
  readonly __typename?: 'CarrierRepAuditV2';
  readonly actionType: Scalars['String'];
  readonly auditType: Scalars['String'];
  readonly auditableId: Scalars['ID'];
  readonly auditableRep?: Maybe<RepV2>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByEmployee?: Maybe<EmployeeV2>;
  readonly currentValue?: Maybe<CarrierRepAuditedChanges>;
  readonly id: Scalars['ID'];
  readonly previousValue?: Maybe<CarrierRepAuditedChanges>;
  readonly userId?: Maybe<Scalars['ID']>;
};

export enum CarrierRepOrder {
  Default = 'DEFAULT'
}

export enum CarrierRepOrderV2 {
  Default = 'DEFAULT'
}

export type CarrierRiskInformationInput = {
  readonly dotNumber?: InputMaybe<Scalars['String']>;
  readonly externalStatus?: InputMaybe<StatusTypeEnum>;
  readonly insurances?: InputMaybe<ReadonlyArray<CarrierInsuranceRiskInformationInput>>;
  readonly isCertified?: InputMaybe<Scalars['Boolean']>;
  readonly mcNumber?: InputMaybe<Scalars['String']>;
  readonly nonCertifiedReasons?: InputMaybe<Scalars['String']>;
  readonly operatingStatus?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly safetyRating?: InputMaybe<SafetyRatingTypeEnum>;
  readonly standing?: InputMaybe<Scalars['Json']>;
};

export type CarrierRoute = {
  readonly __typename?: 'CarrierRoute';
  readonly id: Scalars['ID'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Float']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly routeCount?: Maybe<Scalars['Float']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly primaryDivision?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['String']>;
  readonly orderMode?: Maybe<Scalars['String']>;
  readonly orderSize?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly routeDistance?: Maybe<Scalars['Float']>;
  readonly routeDistanceUnit?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly mainOrderRep?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly routeMaxCost?: Maybe<Scalars['Float']>;
  readonly routeOverMax?: Maybe<Scalars['Float']>;
  readonly routeTotalRate?: Maybe<Scalars['Float']>;
  readonly routeTotalCost?: Maybe<Scalars['Float']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverPhone?: Maybe<Scalars['String']>;
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  readonly routeCarrierId?: Maybe<Scalars['String']>;
  readonly executingDivision?: Maybe<Scalars['String']>;
  readonly mainRouteRep?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly preplanSent?: Maybe<Scalars['DateTime']>;
};

export type CarrierRouteConnection = {
  readonly __typename?: 'CarrierRouteConnection';
  readonly edges: ReadonlyArray<CarrierRouteEdge>;
  readonly pageInfo: PageInfo;
};

export type CarrierRouteEdge = {
  readonly __typename?: 'CarrierRouteEdge';
  readonly node: CarrierRoute;
  readonly cursor: Scalars['String'];
};

export type CarrierRouteFilterInput = {
  readonly carrierId: ReadonlyArray<Scalars['ID']>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly primaryDivision?: InputMaybe<Scalars['String']>;
  readonly executingDivision?: InputMaybe<Scalars['String']>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
};

export type CarrierSearch = {
  readonly __typename?: 'CarrierSearch';
  readonly id: Scalars['ID'];
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly carrierCode?: Maybe<Scalars['String']>;
  readonly carrierMainCity?: Maybe<Scalars['String']>;
  readonly carrierMainState?: Maybe<Scalars['String']>;
  readonly carrierMainRepName?: Maybe<Scalars['String']>;
  readonly carrierMainRepId?: Maybe<Scalars['String']>;
  readonly mcNumber?: Maybe<Scalars['String']>;
  readonly dotNumber?: Maybe<Scalars['String']>;
  readonly scacNumber?: Maybe<Scalars['String']>;
  readonly standingStatus?: Maybe<Scalars['String']>;
  readonly carrierLevel?: Maybe<Scalars['String']>;
  readonly carrierOpportunity?: Maybe<Scalars['String']>;
  readonly carrierType?: Maybe<Scalars['String']>;
  readonly carrierAddressCity?: Maybe<Scalars['String']>;
  readonly carrierAddressState?: Maybe<Scalars['String']>;
  readonly carrierAddressCountry?: Maybe<Scalars['String']>;
  readonly carrierAddressZipcode?: Maybe<Scalars['String']>;
  readonly carrierAddressDistance?: Maybe<Scalars['Float']>;
  readonly carrierAddressId: Scalars['ID'];
  readonly carrierStreet1?: Maybe<Scalars['String']>;
  readonly carrierServices?: Maybe<Scalars['String']>;
  readonly carrierCertifications?: Maybe<Scalars['String']>;
  readonly accountingStatus?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
};

export type CarrierSearchConnection = {
  readonly __typename?: 'CarrierSearchConnection';
  readonly edges: ReadonlyArray<CarrierSearchEdge>;
  readonly pageInfo: PageInfo;
};

export type CarrierSearchEdge = {
  readonly __typename?: 'CarrierSearchEdge';
  readonly node: CarrierSearch;
  readonly cursor: Scalars['String'];
};

export type CarrierSearchFilterInput = {
  readonly carrierName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierPhone?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly system?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly systemId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mcNumber?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly dotNumber?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly scacNumber?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierStandingStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierLevel?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierOpportunity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly city?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly state?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly country?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly zipCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isMainAddress?: InputMaybe<Scalars['Boolean']>;
  readonly carrierRadius?: InputMaybe<RadiusFilterOptional>;
  readonly mainRepId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly groupId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly service?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly certification?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly facility?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly accountingStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly organizationId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
};

export type CarrierService = {
  readonly __typename?: 'CarrierService';
  readonly carrierId: Scalars['ID'];
  readonly carrierServiceType: KeyValue;
  readonly carrierServiceTypeId: Scalars['ID'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly id: Scalars['ID'];
  readonly note?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
};

export type CarrierServiceInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly carrierServiceTypeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly note?: InputMaybe<Scalars['String']>;
};

export type CarrierServiceInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly carrierServiceTypeId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
};

export type CarrierServiceQueryInput = {
  readonly carrierServiceTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type CarrierServiceQueryInputV2 = {
  readonly carrierServiceTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type CarrierServiceStandaloneInput = {
  readonly carrier: CarrierArgumentInput;
  readonly carrierServiceTypeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly note?: InputMaybe<Scalars['String']>;
};

export type CarrierServiceStandaloneInputV2 = {
  readonly carrier: CarrierArgumentInputV2;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierServiceTypeId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
};

export type CarrierServiceV2 = {
  readonly __typename?: 'CarrierServiceV2';
  readonly carrierId: Scalars['ID'];
  readonly carrierServiceType: KeyValue;
  readonly carrierServiceTypeId: Scalars['ID'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly note?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type CarrierSetting = {
  readonly __typename?: 'CarrierSetting';
  readonly carrierId: Scalars['ID'];
  readonly defaultDestinationDeadhead: UnitOfDistance;
  readonly defaultDestinationGeographyId?: Maybe<Scalars['ID']>;
  readonly defaultDestinationSourceId?: Maybe<Scalars['Int']>;
  readonly defaultDestinationText?: Maybe<Scalars['String']>;
  readonly defaultOriginDeadhead: UnitOfDistance;
  readonly defaultTrailerHeight?: Maybe<Scalars['Float']>;
  readonly defaultTrailerHeightUnit: Scalars['String'];
  readonly defaultTrailerLength?: Maybe<Scalars['Float']>;
  readonly defaultTrailerLengthUnit: Scalars['String'];
  readonly defaultTrailerType?: Maybe<KeyValue>;
  readonly defaultTrailerTypeId?: Maybe<Scalars['ID']>;
  readonly defaultTrailerWidth?: Maybe<Scalars['Float']>;
  readonly defaultTrailerWidthUnit: Scalars['String'];
  /** @deprecated Transitioned to default_trailer_type */
  readonly defaultTruckType?: Maybe<CarrierTruckTypeEnum>;
  /** @deprecated Moved to certifications can also use carrier hazmat */
  readonly hazmat: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly intrastate: Scalars['Boolean'];
};

export type CarrierSettingInput = {
  readonly carrierId: Scalars['ID'];
  readonly defaultDestinationDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly defaultDestinationGeographyId?: InputMaybe<Scalars['ID']>;
  readonly defaultDestinationGeographySource?: InputMaybe<Scalars['String']>;
  readonly defaultDestinationGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly defaultDestinationText?: InputMaybe<Scalars['String']>;
  readonly defaultOriginDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly defaultTrailerHeight?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerHeightUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerLength?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerLengthUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly defaultTrailerWidth?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerWidthUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTruckType?: InputMaybe<CarrierTruckTypeEnum>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly intrastate?: InputMaybe<Scalars['Boolean']>;
};

export type CarrierSettingInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId: Scalars['ID'];
  readonly defaultTrailerLength?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerWidth?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerHeight?: InputMaybe<Scalars['Float']>;
  readonly defaultDestinationText?: InputMaybe<Scalars['String']>;
  readonly defaultDestinationGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly defaultDestinationGeographySource?: InputMaybe<Scalars['String']>;
  readonly defaultDestinationGeographyId?: InputMaybe<Scalars['ID']>;
  readonly defaultTrailerLengthUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerWidthUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerHeightUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly defaultOriginDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly defaultDestinationDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly intrastate?: InputMaybe<Scalars['Boolean']>;
  readonly defaultTruckType?: InputMaybe<CarrierTruckTypeEnum>;
  readonly reloadIntentDefaultId?: InputMaybe<Scalars['ID']>;
  readonly destinationFacilityId?: InputMaybe<Scalars['ID']>;
};

export type CarrierSettingV2 = {
  readonly __typename?: 'CarrierSettingV2';
  readonly carrierId: Scalars['ID'];
  readonly defaultDestinationDeadhead: UnitOfDistance;
  readonly defaultDestinationGeographyId?: Maybe<Scalars['ID']>;
  readonly defaultDestinationSourceId?: Maybe<Scalars['Int']>;
  readonly defaultDestinationText?: Maybe<Scalars['String']>;
  readonly defaultOriginDeadhead: UnitOfDistance;
  readonly defaultTrailerHeight?: Maybe<Scalars['Float']>;
  readonly defaultTrailerHeightUnit: Scalars['String'];
  readonly defaultTrailerLength?: Maybe<Scalars['Float']>;
  readonly defaultTrailerLengthUnit: Scalars['String'];
  readonly defaultTrailerType?: Maybe<KeyValue>;
  readonly defaultTrailerTypeId?: Maybe<Scalars['ID']>;
  readonly defaultTrailerWidth?: Maybe<Scalars['Float']>;
  readonly defaultTrailerWidthUnit: Scalars['String'];
  /** @deprecated Transitioned to default_trailer_type */
  readonly defaultTruckType?: Maybe<CarrierTruckTypeEnum>;
  /** @deprecated Moved to certifications can also use carrier hazmat */
  readonly hazmat: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly intrastate: Scalars['Boolean'];
  readonly reloadIntentDefaultId?: Maybe<Scalars['ID']>;
  readonly reloadIntentDefault?: Maybe<KeyValue>;
  readonly destinationFacilityId?: Maybe<Scalars['ID']>;
  readonly destinationFacility?: Maybe<FacilityV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type CarriersFilter = {
  readonly address?: InputMaybe<AddressesFilter>;
  readonly carrierCertification?: InputMaybe<CarrierCertificationQueryInput>;
  readonly carrierIdentifier?: InputMaybe<IdentifierQueryInput>;
  readonly carrierService?: InputMaybe<CarrierServiceQueryInput>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly dotNumber?: InputMaybe<Scalars['String']>;
  readonly employeeId?: InputMaybe<Scalars['ID']>;
  readonly entityType?: InputMaybe<EntityTypeEnum>;
  readonly exactDotNumber?: InputMaybe<Scalars['String']>;
  readonly exactMcNumber?: InputMaybe<Scalars['String']>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly levelId?: InputMaybe<Scalars['ID']>;
  readonly mcDotNumber?: InputMaybe<Scalars['String']>;
  readonly mcNumber?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly opportunity?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly revenovaId?: InputMaybe<Scalars['String']>;
  readonly scacNumber?: InputMaybe<Scalars['String']>;
  readonly sort?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<StatusTypeEnum>;
  readonly text?: InputMaybe<Scalars['String']>;
};

export type CarriersFilterV2 = {
  readonly address?: InputMaybe<CarrierAddressesFilter>;
  readonly carrierIdentifier?: InputMaybe<IdentifierQueryInputV2>;
  readonly carrierService?: InputMaybe<CarrierServiceQueryInputV2>;
  /**  The field `carrierTypeId` is deprecated. Use `entityType` instead.  */
  readonly carrierTypeId?: InputMaybe<Scalars['ID']>;
  readonly carrierCertification?: InputMaybe<CarrierCertificationQueryInputV2>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly dotNumber?: InputMaybe<Scalars['String']>;
  readonly employeeId?: InputMaybe<Scalars['ID']>;
  readonly levelId?: InputMaybe<Scalars['ID']>;
  readonly mcDotNumber?: InputMaybe<Scalars['String']>;
  readonly mcNumber?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly opportunity?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly revenovaId?: InputMaybe<Scalars['String']>;
  readonly scacNumber?: InputMaybe<Scalars['String']>;
  readonly sort?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<StatusTypeEnum>;
  readonly entityType?: InputMaybe<EntityTypeEnum>;
  readonly text?: InputMaybe<Scalars['String']>;
  readonly exactMcNumber?: InputMaybe<Scalars['String']>;
  readonly exactDotNumber?: InputMaybe<Scalars['String']>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly intraCode?: InputMaybe<Scalars['String']>;
};

export type CarrierStanding = {
  readonly __typename?: 'CarrierStanding';
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<User>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly reasonCodeId?: Maybe<Scalars['String']>;
  readonly statusTypeId?: Maybe<Scalars['String']>;
  readonly typeId?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<User>;
};

export type CarrierStandingInput = {
  readonly carrier: CarrierArgumentInput;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly reasonCodeId?: InputMaybe<Scalars['String']>;
  readonly statusTypeId?: InputMaybe<Scalars['String']>;
  readonly typeId?: InputMaybe<Scalars['String']>;
};

export type CarrierStandingInputV2 = {
  readonly carrier: CarrierArgumentInputV2;
  readonly typeId?: InputMaybe<Scalars['String']>;
  readonly statusTypeId?: InputMaybe<Scalars['String']>;
  readonly statusTypeTrigger?: InputMaybe<Scalars['String']>;
  readonly reasonCodeId?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly dateModified?: InputMaybe<Scalars['String']>;
};

export type CarrierStandingV2 = {
  readonly __typename?: 'CarrierStandingV2';
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly reasonCodeId?: Maybe<Scalars['String']>;
  readonly statusTypeId?: Maybe<Scalars['String']>;
  readonly statusTypeTrigger?: Maybe<Scalars['String']>;
  readonly typeId?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly dateModified?: Maybe<Scalars['String']>;
};

export type CarriersWithInteractionFilterInputType = {
  readonly repId: Scalars['ID'];
};

export type CarrierTieredCostSchedule = {
  readonly __typename?: 'CarrierTieredCostSchedule';
  readonly id?: Maybe<Scalars['ID']>;
  readonly beginRange?: Maybe<Scalars['Decimal']>;
  readonly endRange?: Maybe<Scalars['Decimal']>;
  readonly costPer?: Maybe<Scalars['Decimal']>;
};

export type CarrierTieredCostScheduleInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly beginRange?: InputMaybe<Scalars['Decimal']>;
  readonly endRange?: InputMaybe<Scalars['Decimal']>;
  readonly costPer?: InputMaybe<Scalars['Decimal']>;
};

export type CarrierTruck = {
  readonly __typename?: 'CarrierTruck';
  readonly height?: Maybe<Scalars['Float']>;
  readonly heightUnit?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly length: Scalars['Float'];
  readonly lengthUnit: Scalars['String'];
  readonly quantity: Scalars['Int'];
  readonly truckType: CarrierTruckTypeEnum;
  readonly width?: Maybe<Scalars['Float']>;
  readonly widthUnit?: Maybe<Scalars['String']>;
};

export enum CarrierTruckTypeEnum {
  /** bulk_liquid */
  BulkLiquid = 'bulkLiquid',
  /** chassis */
  Chassis = 'chassis',
  /** flatbed */
  Flatbed = 'flatbed',
  /** none_defined */
  NoneDefined = 'noneDefined',
  /** other_specialized_equipment */
  OtherSpecializedEquipment = 'otherSpecializedEquipment',
  /** power_only */
  PowerOnly = 'powerOnly',
  /** reefer */
  Reefer = 'reefer',
  /** tcu */
  Tcu = 'tcu',
  /** van */
  Van = 'van',
  /** container */
  Container = 'container',
  /** high_cube_container */
  HighCubeContainer = 'highCubeContainer',
  /** flat_rack */
  FlatRack = 'flatRack',
  /** adjustable_chassis */
  AdjustableChassis = 'adjustableChassis',
  /** autorack */
  Autorack = 'autorack',
  /** boxcar */
  Boxcar = 'boxcar',
  /** centerbeam */
  Centerbeam = 'centerbeam',
  /** coil_car */
  CoilCar = 'coilCar',
  /** conestoga */
  Conestoga = 'conestoga',
  /** covered_hopper */
  CoveredHopper = 'coveredHopper',
  /** double_drop */
  DoubleDrop = 'doubleDrop',
  /** flat_car */
  FlatCar = 'flatCar',
  /** high_cube_reefer_container */
  HighCubeReeferContainer = 'highCubeReeferContainer',
  /** insulated_container */
  InsulatedContainer = 'insulatedContainer',
  /** open_top_container */
  OpenTopContainer = 'openTopContainer',
  /** open_top_hopper */
  OpenTopHopper = 'openTopHopper',
  /** reefer_container */
  ReeferContainer = 'reeferContainer',
  /** step_deck */
  StepDeck = 'stepDeck',
  /** tank_car */
  TankCar = 'tankCar',
  /** tank_container */
  TankContainer = 'tankContainer',
  /** well_car */
  WellCar = 'wellCar',
  /** refrigerated_boxcar */
  RefrigeratedBoxcar = 'refrigeratedBoxcar',
  /** rgn */
  Rgn = 'rgn',
  /** cargo_van */
  CargoVan = 'cargoVan',
  /** heated_van */
  HeatedVan = 'heatedVan',
  /** insulated_van */
  InsulatedVan = 'insulatedVan',
  /** pup_trailer */
  PupTrailer = 'pupTrailer',
  /** straight_truck */
  StraightTruck = 'straightTruck',
  /** tri_axle_vans */
  TriAxleVans = 'triAxleVans',
  /** van_open_top */
  VanOpenTop = 'vanOpenTop',
  /** walking_floor */
  WalkingFloor = 'walkingFloor',
  /** curtainside_dry_van */
  CurtainsideDryVan = 'curtainsideDryVan',
  /** e_track_dry_van */
  ETrackDryVan = 'eTrackDryVan',
  /** smoothside_dry_van */
  SmoothsideDryVan = 'smoothsideDryVan',
  /** plated_dry_van */
  PlatedDryVan = 'platedDryVan',
  /** van_with_curtains */
  VanWithCurtains = 'vanWithCurtains',
  /** van_with_liftgate */
  VanWithLiftgate = 'vanWithLiftgate',
  /** vented_van */
  VentedVan = 'ventedVan',
  /** boat_trailer */
  BoatTrailer = 'boatTrailer',
  /** end_dump */
  EndDump = 'endDump',
  /** flatbed_conestoga */
  FlatbedConestoga = 'flatbedConestoga',
  /** step_deck_conestoga */
  StepDeckConestoga = 'stepDeckConestoga',
  /** hopper_bottom */
  HopperBottom = 'hopperBottom',
  /** hot_shot_trailer */
  HotShotTrailer = 'hotShotTrailer',
  /** quad_axle */
  QuadAxle = 'quadAxle',
  /** dump_truck */
  DumpTruck = 'dumpTruck',
  /** b_train_van */
  BTrainVan = 'bTrainVan',
  /** b_train_end_dump */
  BTrainEndDump = 'bTrainEndDump',
  /** side_dump */
  SideDump = 'sideDump',
  /** belt_trailer */
  BeltTrailer = 'beltTrailer',
  /** sprinter_van */
  SprinterVan = 'sprinterVan',
  /** box_truck */
  BoxTruck = 'boxTruck',
  /** aluminum_tanker */
  AluminumTanker = 'aluminumTanker',
  /** belly_dump */
  BellyDump = 'bellyDump',
  /** gondola */
  Gondola = 'gondola',
  /** maxi */
  Maxi = 'maxi',
  /** pneumatic */
  Pneumatic = 'pneumatic',
  /** stainless_steel_tanker */
  StainlessSteelTanker = 'stainlessSteelTanker',
  /** tanker */
  Tanker = 'tanker',
  /** b_train_flatbed */
  BTrainFlatbed = 'bTrainFlatbed',
  /** double_drop_extendable */
  DoubleDropExtendable = 'doubleDropExtendable',
  /** flatbed_with_forklift */
  FlatbedWithForklift = 'flatbedWithForklift',
  /** flatbed_with_sides */
  FlatbedWithSides = 'flatbedWithSides',
  /** rgn_extendable */
  RgnExtendable = 'rgnExtendable',
  /** roll_top_conestoga */
  RollTopConestoga = 'rollTopConestoga',
  /** step_deck_extendable */
  StepDeckExtendable = 'stepDeckExtendable',
  /** step_deck_low */
  StepDeckLow = 'stepDeckLow',
  /** step_deck_with_loading_ramps */
  StepDeckWithLoadingRamps = 'stepDeckWithLoadingRamps',
  /** six_to_seven_axle_rgn_heavy_haul_trailer */
  SixToSevenAxleRgnHeavyHaulTrailer = 'sixToSevenAxleRgnHeavyHaulTrailer',
  /** seven_to_eight_axle_rgn_heavy_haul_trailer */
  SevenToEightAxleRgnHeavyHaulTrailer = 'sevenToEightAxleRgnHeavyHaulTrailer',
  /** nine_axle_plus_rgn_heavy_haul_trailer */
  NineAxlePlusRgnHeavyHaulTrailer = 'nineAxlePlusRgnHeavyHaulTrailer',
  /** beam */
  Beam = 'beam',
  /** lowboy */
  Lowboy = 'lowboy',
  /** perimeter */
  Perimeter = 'perimeter',
  /** stretch_open_deck */
  StretchOpenDeck = 'stretchOpenDeck',
  /** stretch_trailer */
  StretchTrailer = 'stretchTrailer',
  /** auto_hauler */
  AutoHauler = 'autoHauler',
  /** dump_trailer */
  DumpTrailer = 'dumpTrailer',
  /** garment_van */
  GarmentVan = 'garmentVan',
  /** livestock_trailer */
  LivestockTrailer = 'livestockTrailer',
  /** vacuum_or_pump */
  VacuumOrPump = 'vacuumOrPump',
  /** wheel_well */
  WheelWell = 'wheelWell'
}

export type CarrierTruckV2 = {
  readonly __typename?: 'CarrierTruckV2';
  readonly height?: Maybe<Scalars['Float']>;
  readonly heightUnit?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly length: Scalars['Float'];
  readonly lengthUnit: Scalars['String'];
  readonly quantity: Scalars['Int'];
  readonly truckType: CarrierTruckTypeEnum;
  readonly width?: Maybe<Scalars['Float']>;
  readonly widthUnit?: Maybe<Scalars['String']>;
};

export type CarrierV2 = {
  readonly __typename?: 'CarrierV2';
  readonly id: Scalars['ID'];
  readonly accountingStatus?: Maybe<Scalars['String']>;
  readonly hasPropertyMetadata: Scalars['Boolean'];
  readonly eid?: Maybe<Scalars['Long']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly propertyMetadata?: Maybe<ReadonlyArray<KeyValuePairOfStringAndPropertyMetadata>>;
  readonly accountingNotes?: Maybe<ReadonlyArray<CarrierNote>>;
  readonly addresses?: Maybe<ReadonlyArray<CarrierAddress>>;
  readonly allNotes?: Maybe<ReadonlyArray<CarrierNote>>;
  /** @deprecated Use CarrierCarrierRelationship instead */
  readonly ancestorIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly audits: ReadonlyArray<AuditTypeUnionV2>;
  readonly binQualified?: Maybe<Scalars['Boolean']>;
  readonly carrierAuditsExternalStatus?: Maybe<ReadonlyArray<CarrierAuditsExternalStatusV2>>;
  readonly carrierIdentifiers?: Maybe<ReadonlyArray<CarrierIdentifierV2>>;
  readonly carrierPaymentTerm?: Maybe<KeyValue>;
  readonly carrierPaymentTermId?: Maybe<Scalars['ID']>;
  readonly carrierSalesProgressType?: Maybe<KeyValue>;
  readonly carrierSalesProgressTypeId?: Maybe<Scalars['ID']>;
  readonly carrierServices?: Maybe<ReadonlyArray<CarrierServiceV2>>;
  readonly carrierType?: Maybe<KeyValue>;
  /** @deprecated The field `carrierTypeId` is deprecated. Use `entityType` instead. */
  readonly carrierTypeId?: Maybe<Scalars['ID']>;
  readonly carrierRatingBasedOnId?: Maybe<Scalars['ID']>;
  readonly carrierRatingBasedOn?: Maybe<KeyValue>;
  readonly certifications?: Maybe<ReadonlyArray<CarrierCertificationV2>>;
  /** @deprecated Use CarrierCarrierRelationship instead */
  readonly children?: Maybe<ReadonlyArray<CarrierV2>>;
  readonly code: Scalars['String'];
  readonly complianceEmail?: Maybe<Scalars['String']>;
  readonly complianceEmailSource?: Maybe<Scalars['String']>;
  readonly confirmationNotes?: Maybe<ReadonlyArray<CarrierNote>>;
  readonly contacts?: Maybe<ReadonlyArray<CarrierContact>>;
  readonly allContacts?: Maybe<ReadonlyArray<CarrierContact>>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<KeyValue>;
  readonly currencyId?: Maybe<Scalars['ID']>;
  readonly customerRelationships?: Maybe<ReadonlyArray<CarrierCustomerRelationshipV2>>;
  readonly dbaName?: Maybe<Scalars['String']>;
  /** @deprecated Use CarrierCarrierRelationship instead */
  readonly descendantIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly dotNumber?: Maybe<Scalars['String']>;
  readonly dunsNumber?: Maybe<Scalars['String']>;
  readonly electronicTrackings?: Maybe<ReadonlyArray<CarrierElectronicTracking>>;
  /** @deprecated Use electronicTrackings instead */
  readonly electronicTrackingMethod?: Maybe<KeyValue>;
  /** @deprecated Use electronicTrackings instead */
  readonly electronicTrackingMethodId?: Maybe<Scalars['ID']>;
  /** @deprecated Use electronicTrackings instead */
  readonly electronicTrackingType?: Maybe<KeyValue>;
  /** @deprecated Use electronicTrackings instead */
  readonly electronicTrackingTypeId?: Maybe<Scalars['ID']>;
  readonly entityType: EntityTypeEnum;
  /** @deprecated use `standing` field instead. */
  readonly externalStatus?: Maybe<StatusTypeEnum>;
  readonly equipments?: Maybe<ReadonlyArray<CarrierEquipmentV2>>;
  /** @deprecated Use `geographiesNullable` instead (`geographies` is not under access control) */
  readonly geographies: ReadonlyArray<CarrierGeographyV2>;
  readonly modes?: Maybe<ReadonlyArray<CarrierModeV2>>;
  readonly commodities?: Maybe<ReadonlyArray<CarrierCommodityV2>>;
  readonly geographiesNullable?: Maybe<ReadonlyArray<CarrierGeographyV2>>;
  readonly groups?: Maybe<ReadonlyArray<CarrierGroup>>;
  readonly hazmat?: Maybe<Scalars['Boolean']>;
  readonly insurances?: Maybe<ReadonlyArray<CarrierInsuranceV2>>;
  readonly interactions?: Maybe<ReadonlyArray<InteractionV2>>;
  readonly intrastatePresent?: Maybe<Scalars['Boolean']>;
  readonly intraState?: Maybe<CarrierIntrastateV2>;
  readonly intrastateStateAuthorities?: Maybe<ReadonlyArray<IntrastateAuthority>>;
  /** @deprecated Use intraState instead */
  readonly intrastateStates?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly authorizedDivisions?: Maybe<ReadonlyArray<AuthorizedDivision>>;
  readonly invoiceDistributionMethod?: Maybe<KeyValue>;
  readonly invoiceDistributionMethodId?: Maybe<Scalars['ID']>;
  readonly isAutoTruckEnabled: Scalars['Boolean'];
  /** @deprecated Use carrierSettings instead */
  readonly level?: Maybe<KeyValue>;
  readonly levelId?: Maybe<Scalars['ID']>;
  /** @deprecated Use level */
  readonly levelName?: Maybe<LevelTypeEnum>;
  readonly mainAddress?: Maybe<CarrierAddress>;
  readonly mainAddressId?: Maybe<Scalars['String']>;
  readonly mainContact?: Maybe<CarrierContact>;
  readonly mainContactId?: Maybe<Scalars['String']>;
  readonly manualTrackingInformation?: Maybe<Scalars['String']>;
  readonly manualTrackingMethod?: Maybe<ManualTrackingMethodEnum>;
  readonly mcNumber?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly nonCertifiedReasons?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly operatingStatus?: Maybe<Scalars['String']>;
  /** @deprecated Use carrierSettings instead */
  readonly opportunity?: Maybe<KeyValue>;
  readonly opportunityId?: Maybe<Scalars['ID']>;
  /** @deprecated Use opportunity */
  readonly opportunityName?: Maybe<OpportunityTypeEnum>;
  readonly organization?: Maybe<Organization>;
  readonly organizationId?: Maybe<Scalars['ID']>;
  /** @deprecated Use CarrierCarrierRelationship instead */
  readonly parentId?: Maybe<Scalars['ID']>;
  /** @deprecated Use CarrierCarrierRelationship instead */
  readonly parent?: Maybe<CarrierV2>;
  readonly parentRelationship?: Maybe<ReadonlyArray<ParentRelationship>>;
  readonly carrierCarrierRelationship?: Maybe<ReadonlyArray<CarrierCarrierRelationship>>;
  readonly paymentMethod?: Maybe<KeyValue>;
  readonly paymentMethodId?: Maybe<Scalars['ID']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly roeStatus?: Maybe<Scalars['String']>;
  readonly releaseMethods?: Maybe<ReadonlyArray<CarrierReleaseMethod>>;
  readonly remitToCarrier?: Maybe<CarrierV2>;
  readonly remitToCarrierId?: Maybe<Scalars['ID']>;
  readonly remittanceAddress?: Maybe<CarrierAddress>;
  readonly remittanceAddressId?: Maybe<Scalars['String']>;
  readonly remittanceEmail?: Maybe<Scalars['String']>;
  /** @deprecated Use temp_remittance_grouping for now */
  readonly remittanceGrouping?: Maybe<RemittanceGroupingEnum>;
  readonly reps?: Maybe<ReadonlyArray<RepV2>>;
  readonly allReps?: Maybe<ReadonlyArray<RepV2>>;
  /** @deprecated will be removed soon */
  readonly revenovaId?: Maybe<Scalars['String']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly externalSource?: Maybe<Scalars['String']>;
  readonly safetyRating?: Maybe<SafetyRatingTypeEnum>;
  readonly scacNumber?: Maybe<Scalars['String']>;
  readonly setting?: Maybe<CarrierSettingV2>;
  /** @deprecated Use CarrierCarrierRelationship instead */
  readonly siblings?: Maybe<ReadonlyArray<CarrierV2>>;
  readonly standing?: Maybe<ReadonlyArray<CarrierStandingV2>>;
  readonly status?: Maybe<StatusTypeEnum>;
  readonly tempRemittanceGrouping?: Maybe<TempRemittanceGroupingEnum>;
  readonly tenderOptions?: Maybe<TenderOptions>;
  readonly trucks?: Maybe<ReadonlyArray<CarrierTruckV2>>;
  readonly truckEntry?: Maybe<TruckEntry>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly isUltimateParent?: Maybe<Scalars['Boolean']>;
  readonly ultimateParentId?: Maybe<Scalars['String']>;
  /** @deprecated Use w9_certified_nullable instead. */
  readonly w9Certified: Scalars['Boolean'];
  readonly w9CertifiedNullable?: Maybe<Scalars['Boolean']>;
  readonly website?: Maybe<Scalars['String']>;
  readonly carrierFacilities?: Maybe<ReadonlyArray<CarrierFacility>>;
};


export type CarrierV2InteractionsArgs = {
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  interactionType?: InputMaybe<InteractionTypeEnum>;
};

export type CarrierValidation = {
  readonly __typename?: 'CarrierValidation';
  readonly id?: Maybe<Scalars['ID']>;
  readonly code: Scalars['String'];
  readonly scacNumber?: Maybe<Scalars['String']>;
  readonly contacts?: Maybe<ReadonlyArray<CarrierContactValidation>>;
  readonly error?: Maybe<Scalars['String']>;
};

export type CarrierValidationInput = {
  readonly code: Scalars['String'];
  readonly contacts?: InputMaybe<ReadonlyArray<CarrierContactValidationInput>>;
};

export type Change = {
  readonly __typename?: 'Change';
  readonly type?: Maybe<Scalars['String']>;
  readonly key?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly oldValue?: Maybe<Scalars['String']>;
};

export type ChangeCreditCustomerOnOrderInput = {
  readonly orderId: Scalars['ID'];
  readonly newCustomerId: Scalars['ID'];
};

export type ChangeCreditCustomerOnOrderPayload = {
  readonly __typename?: 'ChangeCreditCustomerOnOrderPayload';
  readonly approved: Scalars['Boolean'];
  readonly message: Scalars['String'];
  readonly details?: Maybe<Scalars['String']>;
};

export type ChangeCustomerOrderInput = {
  readonly orderId: Scalars['ID'];
  readonly customerId: Scalars['ID'];
};

export type ChangeFacilityOnStopInput = {
  readonly loadId: Scalars['ID'];
  readonly oldFacilityId: Scalars['ID'];
  readonly newFacilityId: Scalars['ID'];
  readonly doKeepAppointment: Scalars['Boolean'];
  readonly routeStopId?: InputMaybe<Scalars['ID']>;
  readonly doKeepDriverTargetDateTime?: InputMaybe<Scalars['Boolean']>;
};

export type ChangeFacilityOnStopPayload = {
  readonly __typename?: 'ChangeFacilityOnStopPayload';
  readonly errors: ReadonlyArray<RouteStopsPayloadError>;
  readonly changeFacilityOnStop?: Maybe<RouteStopsPayload>;
};

export type ChangeInput = {
  readonly type?: InputMaybe<Scalars['String']>;
  readonly key?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly oldValue?: InputMaybe<Scalars['String']>;
};

/** Entity Configuration Audit Object */
export type ChangeObject = {
  readonly __typename?: 'ChangeObject';
  readonly action: AuditAction;
  readonly mappedOptionId?: Maybe<Scalars['String']>;
  readonly oldMappedOptionId?: Maybe<Scalars['String']>;
  readonly optionId?: Maybe<Scalars['String']>;
  readonly optionName?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Boolean']>;
};

export enum ChangeType {
  Add = 'ADD',
  Remove = 'REMOVE',
  Update = 'UPDATE'
}

export type ChannelLeg = {
  readonly __typename?: 'ChannelLeg';
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly carrier: CarrierV2;
  readonly isBidirectional: Scalars['Boolean'];
  readonly turnaroundTimeHours?: Maybe<Scalars['Decimal']>;
  readonly assignedReps: ReadonlyArray<EmployeeV2>;
  readonly divisions: ReadonlyArray<LegDivision>;
  readonly stops: ReadonlyArray<LegStop>;
  readonly scheduleUnits: ReadonlyArray<LegScheduleUnit>;
  readonly channel?: Maybe<NetworkChannel>;
};

export type Charge = {
  readonly chargeType?: InputMaybe<Scalars['String']>;
  readonly index?: InputMaybe<Scalars['Int']>;
  readonly chargeTypeId?: InputMaybe<Scalars['String']>;
};

export type ChargeDetails = {
  readonly __typename?: 'ChargeDetails';
  readonly type: Scalars['String'];
  readonly amount: Scalars['Float'];
  readonly currencyCodeTerm: Scalars['String'];
};

export type ChargesModel = {
  readonly __typename?: 'ChargesModel';
  readonly carrierCharges?: Maybe<ReadonlyArray<Maybe<CarrierChargesModel>>>;
  readonly carrierTotalRate: Scalars['Int'];
  readonly customerCharges?: Maybe<ReadonlyArray<Maybe<CustomerChargesModel>>>;
  readonly customerTotalRate: Scalars['Int'];
};

/** The GL configuration for a Charge Type */
export type ChargeType = {
  readonly __typename?: 'ChargeType';
  /** @deprecated No longer necessary with negatives in rate/cost per. */
  readonly isCredit?: Maybe<Scalars['Boolean']>;
  readonly chargeTypeId: Scalars['ID'];
  readonly customerGlCode?: Maybe<Scalars['String']>;
  readonly vendorGlCode?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly isEligibleForFuel?: Maybe<Scalars['Boolean']>;
};

export type ChargeTypeV2 = {
  readonly __typename?: 'ChargeTypeV2';
  readonly id: Scalars['ID'];
  readonly carrierGlCode?: Maybe<Scalars['String']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly customerGlCode?: Maybe<Scalars['String']>;
  readonly eligibleForFuel: Scalars['Boolean'];
  readonly excludeFromMargins: Scalars['Boolean'];
  readonly isActive: Scalars['Boolean'];
  readonly code?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly isPreTax?: Maybe<Scalars['Boolean']>;
};

export type ChargeUnit = {
  readonly chargePerUnit?: InputMaybe<Scalars['String']>;
  readonly index?: InputMaybe<Scalars['Int']>;
};

export type ChatType = {
  readonly __typename?: 'ChatType';
  readonly role?: Maybe<Scalars['String']>;
  readonly content?: Maybe<Scalars['String']>;
};

export type ChatTypeInput = {
  readonly role?: InputMaybe<Scalars['String']>;
  readonly content?: InputMaybe<Scalars['String']>;
};

export type CheckAndAutopostInput = {
  readonly routeId: Scalars['ID'];
  readonly ruleIsOrderSizeFtl?: InputMaybe<Scalars['Boolean']>;
  readonly ruleIsRasActive?: InputMaybe<Scalars['Boolean']>;
  readonly ruleIsRlcsOpen?: InputMaybe<Scalars['Boolean']>;
  readonly ruleStopHasAppointmentTimes?: InputMaybe<Scalars['Boolean']>;
};

export type CheckAndSavePreplanUpdateEventInOutboxInput = {
  readonly routeId: Scalars['ID'];
  readonly driverIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly preplanId?: InputMaybe<Scalars['ID']>;
  readonly changeType?: InputMaybe<Scalars['String']>;
  readonly firstStopCcaStartDatetime?: InputMaybe<Scalars['String']>;
  readonly firstStopCcaEndDatetime?: InputMaybe<Scalars['String']>;
  readonly lastStopCcaStartDatetime?: InputMaybe<Scalars['String']>;
  readonly lastStopCcaEndDatetime?: InputMaybe<Scalars['String']>;
  readonly firstStopCity?: InputMaybe<Scalars['String']>;
  readonly firstStopState?: InputMaybe<Scalars['String']>;
  readonly lastStopCity?: InputMaybe<Scalars['String']>;
  readonly lastStopState?: InputMaybe<Scalars['String']>;
  readonly linehaulMiles?: InputMaybe<Scalars['String']>;
  readonly deadheadMiles?: InputMaybe<Scalars['String']>;
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly tractorNumber?: InputMaybe<Scalars['String']>;
  readonly expectedWeight?: InputMaybe<Scalars['String']>;
};

export type CheckIfLaneExistInputV3 = {
  readonly transportMode?: InputMaybe<Scalars['String']>;
  readonly minLength?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minHeight?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly equipment: ReadonlyArray<InputMaybe<Scalars['ID']>>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly service?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly laneStops: ReadonlyArray<InputMaybe<CheckIfLaneExistStopInputV3>>;
  readonly laneType?: InputMaybe<Scalars['String']>;
};

export type CheckIfLaneExistStopInputV3 = {
  readonly geographyId: Scalars['String'];
  readonly geographyType: Scalars['String'];
  readonly stopType: Scalars['String'];
  readonly sequence: Scalars['Int'];
  readonly postalCodeCountry?: InputMaybe<Scalars['String']>;
};

export type ChildernCustomerInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly parentCustomerId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly code: Scalars['String'];
  readonly customerStatusTypeId: Scalars['ID'];
  readonly customerTypeId?: InputMaybe<Scalars['ID']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly website?: InputMaybe<Scalars['String']>;
  readonly organizationId?: InputMaybe<Scalars['ID']>;
  readonly addresses?: InputMaybe<ReadonlyArray<CustomerAddressInput>>;
  readonly insuranceCargo?: InputMaybe<Scalars['Int']>;
  readonly insuranceGeneral?: InputMaybe<Scalars['Int']>;
  readonly insuranceLiability?: InputMaybe<Scalars['Int']>;
  readonly minimumInsuranceRating?: InputMaybe<Scalars['String']>;
  readonly contacts?: InputMaybe<ReadonlyArray<CustomerContactInput>>;
  readonly serviceTolerance?: InputMaybe<ReadonlyArray<ChildrenCustomerServiceToleranceInput>>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly doNotPostLoads?: InputMaybe<Scalars['Boolean']>;
  readonly disableAutoPost?: InputMaybe<Scalars['Boolean']>;
  readonly rateConfirmNote?: InputMaybe<Scalars['String']>;
};

export type ChildrenCarrierInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly parentCarrierId: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly name: Scalars['String'];
  readonly organizationId?: InputMaybe<Scalars['ID']>;
  readonly dbaName?: InputMaybe<Scalars['String']>;
  readonly website?: InputMaybe<Scalars['String']>;
  readonly entityType?: InputMaybe<EntityTypeEnum>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly allNotes?: InputMaybe<ReadonlyArray<CarrierNoteInput>>;
  readonly addresses?: InputMaybe<ReadonlyArray<CarrierAddressInput>>;
  readonly releaseMethod?: InputMaybe<ReadonlyArray<CarrierReleaseMethodInput>>;
  readonly contacts?: InputMaybe<ReadonlyArray<CarrierContactInput>>;
  readonly certifications?: InputMaybe<ReadonlyArray<CreateCarrierCertificationInputV2>>;
};

export type ChildrenCustomerServiceToleranceInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly stopType: Scalars['String'];
  readonly units: Scalars['String'];
  readonly earlyPickup?: InputMaybe<Scalars['Int']>;
  readonly latePickup?: InputMaybe<Scalars['Int']>;
  readonly earlyDelivery?: InputMaybe<Scalars['Int']>;
  readonly lateDelivery?: InputMaybe<Scalars['Int']>;
};

export type ChildSegmentConfigurationInputType = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly order?: InputMaybe<Scalars['Int']>;
  readonly minimumLoopCount?: InputMaybe<Scalars['Int']>;
  readonly maximumLoopCount?: InputMaybe<Scalars['Int']>;
  readonly isEditable?: InputMaybe<Scalars['Boolean']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly fieldConfigurations?: InputMaybe<ReadonlyArray<InputMaybe<FieldConfigurationInputType>>>;
};

export type ChildSegmentConfigurationOutputType = {
  readonly __typename?: 'ChildSegmentConfigurationOutputType';
  readonly id?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly order?: Maybe<Scalars['Int']>;
  readonly minimumLoopCount?: Maybe<Scalars['Int']>;
  readonly maximumLoopCount?: Maybe<Scalars['Int']>;
  readonly isEditable?: Maybe<Scalars['Boolean']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly fieldConfigurations?: Maybe<ReadonlyArray<Maybe<FieldConfigurationOutputType>>>;
  readonly childSegmentConfigurations?: Maybe<ReadonlyArray<SegmentConfigurationOutputType>>;
};

export type CityStateInput = {
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
};

export type CityStateOutput = {
  readonly __typename?: 'CityStateOutput';
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
};

export type CityStateStopInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly routeId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly address: CityStateStopLocationInput;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
  readonly isOrderStop?: InputMaybe<Scalars['Boolean']>;
  readonly type?: InputMaybe<StopTypeEnum>;
  readonly loadStopType?: InputMaybe<Scalars['String']>;
  readonly loadFromId?: InputMaybe<Scalars['ID']>;
  readonly unloadFromId?: InputMaybe<Scalars['ID']>;
  readonly requirements?: InputMaybe<ReadonlyArray<StopRequirementInput>>;
  readonly commodities?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly commoditiesV2?: InputMaybe<ReadonlyArray<LoadStopCommodityInput>>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInputV2>>;
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly reqDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly bolNumber?: InputMaybe<Scalars['String']>;
  readonly sealNumber?: InputMaybe<Scalars['String']>;
  readonly apptConfBy?: InputMaybe<Scalars['String']>;
  readonly apptReqWith?: InputMaybe<Scalars['String']>;
  /** stop unique identifier for external system */
  readonly externalRef?: InputMaybe<Scalars['String']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  /** flag for stops created from FE */
  readonly isCreatedManually?: InputMaybe<Scalars['Boolean']>;
  readonly splc?: InputMaybe<Scalars['String']>;
  readonly driverTargetStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly driverTargetEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly driverTargetStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly driverTargetEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly sequence?: InputMaybe<Scalars['Int']>;
  readonly routeStopSequence?: InputMaybe<Scalars['Int']>;
  readonly orderStopDesignation?: InputMaybe<Scalars['String']>;
};

export type CityStateStopLocationInput = {
  readonly geopoint?: InputMaybe<GeoPointInput>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly postalCode?: InputMaybe<Scalars['String']>;
  /** countryAlpha2Code */
  readonly country?: InputMaybe<Scalars['String']>;
  readonly timezone?: InputMaybe<Scalars['String']>;
  readonly county?: InputMaybe<Scalars['String']>;
};

export type ClientExceptionRecord = {
  readonly __typename?: 'ClientExceptionRecord';
  readonly id: Scalars['ID'];
  readonly type: ClientExceptionRecordTypeDetails;
  readonly subcategory?: Maybe<ClientExceptionRecordSubcategoryModel>;
  readonly status: ClientExceptionRecordStatusDetails;
  readonly sourceID: Scalars['String'];
  readonly sourceCode?: Maybe<Scalars['String']>;
  readonly sourceType: ClientExceptionRecordSourceType;
  readonly secondarySourceID?: Maybe<Scalars['String']>;
  readonly secondarySourceCode?: Maybe<Scalars['String']>;
  readonly secondarySourceType?: Maybe<ClientExceptionRecordSourceType>;
  readonly reasons: ReadonlyArray<ClientExceptionRecordReason>;
  readonly metadata: ReadonlyArray<ClientExceptionRecordMetadata>;
  readonly followUpNotes?: Maybe<Scalars['String']>;
  readonly followUpDateTime?: Maybe<Scalars['DateTime']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly closedAt?: Maybe<Scalars['DateTime']>;
  readonly sourceNullable?: Maybe<ExceptionSourceUnion>;
  readonly metadataNullable?: Maybe<ReadonlyArray<ExceptionMetadataUnion>>;
  readonly updatedBy?: Maybe<EmployeeV2>;
};

export type ClientExceptionRecordConnection = {
  readonly __typename?: 'ClientExceptionRecordConnection';
  readonly edges: ReadonlyArray<ClientExceptionRecordEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type ClientExceptionRecordDefaultMetadata = {
  readonly __typename?: 'ClientExceptionRecordDefaultMetadata';
  readonly id: Scalars['ID'];
  readonly entityType: ClientExceptionRecordMetadataType;
  readonly entityIdentifier: Scalars['String'];
};

export type ClientExceptionRecordDefaultSource = {
  readonly __typename?: 'ClientExceptionRecordDefaultSource';
  readonly id: Scalars['ID'];
  readonly sourceType: ClientExceptionRecordSourceType;
  readonly sourceID: Scalars['String'];
  readonly sourceCode?: Maybe<Scalars['String']>;
};

export type ClientExceptionRecordEdge = {
  readonly __typename?: 'ClientExceptionRecordEdge';
  readonly node: ClientExceptionRecord;
  readonly cursor: Scalars['String'];
};

export type ClientExceptionRecordFilterInput = {
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly sourceID?: InputMaybe<Scalars['String']>;
  readonly secondarySourceID?: InputMaybe<Scalars['String']>;
  readonly types?: InputMaybe<ReadonlyArray<ClientExceptionRecordType>>;
  readonly subcategories?: InputMaybe<ReadonlyArray<ClientExceptionRecordSubcategory>>;
  readonly statuses?: InputMaybe<ReadonlyArray<ClientExceptionRecordStatus>>;
  readonly sources?: InputMaybe<ReadonlyArray<ClientExceptionRecordSourceType>>;
  readonly reasons?: InputMaybe<ClientExceptionRecordReasonFilterInput>;
  readonly metadata?: InputMaybe<ClientExceptionRecordMetadataFilterInput>;
  readonly createdAt?: InputMaybe<GetClientExceptionByDateRangeInput>;
  readonly updatedAt?: InputMaybe<GetClientExceptionByDateRangeInput>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly repID?: InputMaybe<Scalars['ID']>;
  readonly sourceCode?: InputMaybe<Scalars['String']>;
  readonly repIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly repGroupIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly closedAt?: InputMaybe<GetClientExceptionByDateRangeInput>;
  readonly pickUpDate?: InputMaybe<GetClientExceptionByDateRangeInput>;
  readonly deliveryDate?: InputMaybe<GetClientExceptionByDateRangeInput>;
  readonly carrierIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly customerIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Do Not Use this Filter */
  readonly driverIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Do Not Use this Filter */
  readonly routeLifecycleStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Do Not Use this Filter */
  readonly orderLifecycleStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Do Not Use this Filter */
  readonly activationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Do Not Use this Filter */
  readonly division?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Do Not Use this Filter */
  readonly mode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Do Not Use this Filter */
  readonly followUpDate?: InputMaybe<GetClientExceptionByDateRangeInput>;
};

export type ClientExceptionRecordMetadata = {
  readonly __typename?: 'ClientExceptionRecordMetadata';
  readonly id: Scalars['ID'];
  readonly entityType: ClientExceptionRecordMetadataType;
  readonly entityIdentifier: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type ClientExceptionRecordMetadataFilterInput = {
  readonly entityIdentifiers?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly entityTypes?: InputMaybe<ReadonlyArray<ClientExceptionRecordMetadataType>>;
};

export enum ClientExceptionRecordMetadataType {
  Load = 'LOAD',
  Customer = 'CUSTOMER',
  Order = 'ORDER',
  Route = 'ROUTE',
  UnmatchedChargesBillOfLading = 'UNMATCHED_CHARGES_BILL_OF_LADING',
  Rep = 'REP',
  RepGroup = 'REP_GROUP',
  RouteVendor = 'ROUTE_VENDOR',
  Vendor = 'VENDOR',
  Carrier = 'CARRIER',
  Stop = 'STOP'
}

export type ClientExceptionRecordReason = {
  readonly __typename?: 'ClientExceptionRecordReason';
  readonly id: Scalars['ID'];
  /** @deprecated changed to use DDTs */
  readonly code: ClientExceptionRecordReasonCodeDetails;
  readonly reasonCode: Scalars['String'];
  readonly status: ClientExceptionRecordReasonStatusDetails;
  readonly bypassed: Scalars['Boolean'];
  readonly bypassedReason?: Maybe<Scalars['String']>;
  readonly assignedToGroup?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly assignedBy?: Maybe<EmployeeV2>;
  readonly assignedTo?: Maybe<EmployeeV2>;
  readonly updatedByID?: Maybe<EmployeeV2>;
};

export enum ClientExceptionRecordReasonCode {
  MissingActualWeight = 'MISSING_ACTUAL_WEIGHT',
  MissingCommodityPieces = 'MISSING_COMMODITY_PIECES',
  MissingRate = 'MISSING_RATE',
  MissingOrderReference = 'MISSING_ORDER_REFERENCE',
  UnmatchedCharges = 'UNMATCHED_CHARGES',
  FuelSurcharge = 'FUEL_SURCHARGE',
  TrailerValidation = 'TRAILER_VALIDATION',
  VendorInvoiceTolerance = 'VENDOR_INVOICE_TOLERANCE',
  PaperworkRequired = 'PAPERWORK_REQUIRED',
  Incident = 'INCIDENT',
  PatternValidation = 'PATTERN_VALIDATION'
}

export type ClientExceptionRecordReasonCodeDetails = {
  readonly __typename?: 'ClientExceptionRecordReasonCodeDetails';
  readonly name: ClientExceptionRecordReasonCode;
  readonly description?: Maybe<Scalars['String']>;
};

export type ClientExceptionRecordReasonFilterInput = {
  readonly statuses?: InputMaybe<ReadonlyArray<ClientExceptionRecordReasonStatus>>;
  readonly codes?: InputMaybe<ReadonlyArray<ClientExceptionRecordReasonCode>>;
  readonly reasonCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly bypassed?: InputMaybe<Scalars['Boolean']>;
  readonly bypassedReasons?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedToIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedToGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedByIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum ClientExceptionRecordReasonStatus {
  Unresolved = 'UNRESOLVED',
  Resolved = 'RESOLVED',
  Bypassed = 'BYPASSED'
}

export type ClientExceptionRecordReasonStatusDetails = {
  readonly __typename?: 'ClientExceptionRecordReasonStatusDetails';
  readonly name: ClientExceptionRecordReasonStatus;
  readonly description?: Maybe<Scalars['String']>;
};

export enum ClientExceptionRecordSourceType {
  Order = 'ORDER',
  Route = 'ROUTE',
  Blume = 'BLUME',
  RouteVendor = 'ROUTE_VENDOR',
  Customer = 'CUSTOMER',
  Vendor = 'VENDOR',
  Carrier = 'CARRIER',
  VendorInvoice = 'VENDOR_INVOICE',
  Stop = 'STOP'
}

export enum ClientExceptionRecordStatus {
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export type ClientExceptionRecordStatusDetails = {
  readonly __typename?: 'ClientExceptionRecordStatusDetails';
  readonly name: ClientExceptionRecordStatus;
  readonly description?: Maybe<Scalars['String']>;
};

export enum ClientExceptionRecordSubcategory {
  Accounting = 'ACCOUNTING',
  Operations = 'OPERATIONS',
  Process = 'PROCESS'
}

export type ClientExceptionRecordSubcategoryModel = {
  readonly __typename?: 'ClientExceptionRecordSubcategoryModel';
  readonly name: ClientExceptionRecordSubcategory;
  readonly description?: Maybe<Scalars['String']>;
};

export enum ClientExceptionRecordType {
  CommodityReq = 'COMMODITY_REQ',
  UnmatchedCharges = 'UNMATCHED_CHARGES',
  CustomerPaperwork = 'CUSTOMER_PAPERWORK',
  FinalizedFuel = 'FINALIZED_FUEL',
  VendorPaperwork = 'VENDOR_PAPERWORK',
  VendorInvoice = 'VENDOR_INVOICE',
  AccountingIncident = 'ACCOUNTING_INCIDENT',
  TrailerValidation = 'TRAILER_VALIDATION',
  MissingInvoice = 'MISSING_INVOICE',
  VendorInvoiceTolerance = 'VENDOR_INVOICE_TOLERANCE',
  MissingVendorInvoice = 'MISSING_VENDOR_INVOICE',
  MismatchedBillToCurrency = 'MISMATCHED_BILL_TO_CURRENCY',
  LastFreeDay = 'LAST_FREE_DAY',
  InvoiceVoucherHold = 'INVOICE_VOUCHER_HOLD',
  ManualRateConfirmation = 'MANUAL_RATE_CONFIRMATION',
  AutoRateConfirmation = 'AUTO_RATE_CONFIRMATION',
  OutOfTolerance = 'OUT_OF_TOLERANCE',
  PatternApplication = 'PATTERN_APPLICATION',
  MissingPayProgram = 'MISSING_PAY_PROGRAM'
}

export type ClientExceptionRecordTypeDetails = {
  readonly __typename?: 'ClientExceptionRecordTypeDetails';
  readonly name: ClientExceptionRecordType;
  readonly description?: Maybe<Scalars['String']>;
};

export type CloneLoadCommodityInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly orderId?: InputMaybe<Scalars['ID']>;
  readonly description: Scalars['String'];
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly packagingCount?: InputMaybe<Scalars['Int']>;
  readonly packagingType?: InputMaybe<CommodityPackagingTypeEnum>;
  readonly packagingTypeId?: InputMaybe<Scalars['ID']>;
  readonly loadOnType?: InputMaybe<CommodityLoadOnTypeEnum>;
  readonly loadOnTypeId?: InputMaybe<Scalars['ID']>;
  readonly topLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly bottomLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly doNotStack?: InputMaybe<Scalars['Boolean']>;
  readonly expectedWeightV2?: InputMaybe<UnitOfMassInputV2>;
  readonly actualWeightV2?: InputMaybe<UnitOfMassInputV2>;
  readonly pieceTypeId?: InputMaybe<Scalars['ID']>;
  readonly expectedPieces?: InputMaybe<Scalars['Int']>;
  readonly actualPieces?: InputMaybe<Scalars['Int']>;
  readonly temperatureControlled?: InputMaybe<Scalars['Boolean']>;
  readonly preCoolToV2?: InputMaybe<UnitOfTemperatureInputV2>;
  readonly minimumTemperatureV2?: InputMaybe<UnitOfTemperatureInputV2>;
  readonly maximumTemperatureV2?: InputMaybe<UnitOfTemperatureInputV2>;
  readonly temperatureSetting?: InputMaybe<CommodityTemperatureSettingTypeEnum>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly hazmatClass?: InputMaybe<CommodityHazmatClassTypeEnum>;
  readonly hazmatClassId?: InputMaybe<Scalars['ID']>;
  readonly hazmatPackagingGroup?: InputMaybe<CommodityHazmatPackagingGroupTypeEnum>;
  readonly hazmatUnitedNationsNumber?: InputMaybe<Scalars['String']>;
  readonly hazmatPhoneNumber?: InputMaybe<Scalars['String']>;
  readonly actualDimensionsV2?: InputMaybe<DimensionsInputV2>;
  readonly expectedDimensionsV2?: InputMaybe<DimensionsInputV2>;
  readonly expectedLinearV2?: InputMaybe<UnitOfLengthInputV2>;
  readonly actualLinearV2?: InputMaybe<UnitOfLengthInputV2>;
  readonly actualVolumeV2?: InputMaybe<UnitOfVolumeInputV2>;
  readonly expectedVolumeV2?: InputMaybe<UnitOfVolumeInputV2>;
  readonly actualDensityV2?: InputMaybe<UnitOfDensityInputV2>;
  readonly expectedDensityV2?: InputMaybe<UnitOfDensityInputV2>;
  readonly actualClass?: InputMaybe<CommodityFreightClassTypeEnum>;
  readonly actualClassId?: InputMaybe<Scalars['ID']>;
  readonly expectedClass?: InputMaybe<CommodityFreightClassTypeEnum>;
  readonly expectedClassId?: InputMaybe<Scalars['ID']>;
  readonly actualNMFCCode?: InputMaybe<Scalars['String']>;
  readonly expectedNMFCCode?: InputMaybe<Scalars['String']>;
  readonly associatedStopIndices?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly overDimensional?: InputMaybe<Scalars['Boolean']>;
  readonly year?: InputMaybe<Scalars['Float']>;
  readonly make?: InputMaybe<Scalars['String']>;
  readonly model?: InputMaybe<Scalars['String']>;
  readonly serialNumbers?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly shipments?: InputMaybe<ReadonlyArray<ShipmentInput>>;
  readonly splitDimensions?: InputMaybe<Scalars['Boolean']>;
  readonly shipmentCommodities?: InputMaybe<ReadonlyArray<ShipmentCommodityInput>>;
  readonly commodityValue?: InputMaybe<LoadCurrencyInput>;
};

export type CloneLoadInput = {
  readonly loadId: Scalars['ID'];
  readonly orderDetails: CloneLoadOrderInput;
  readonly routeDetails: ReadonlyArray<CloneLoadRouteInput>;
  readonly rateDetails: ReadonlyArray<CloneLoadRateDetailsInput>;
  readonly rerateableRateDetails?: InputMaybe<ReadonlyArray<CloneLoadRateDetailsInput>>;
  readonly commodities?: InputMaybe<ReadonlyArray<CloneLoadCommodityInput>>;
};

export type CloneLoadOrderInput = {
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInputV2>>;
  readonly activationStatusId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly orderNote?: InputMaybe<Scalars['String']>;
  readonly activationStatusReason?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
};

export type CloneLoadRateDetailsInput = {
  readonly chargeTypeId: Scalars['ID'];
  readonly ratePer: Scalars['Float'];
  readonly unitsDecimal: Scalars['Float'];
  readonly currency: Scalars['String'];
  readonly enteredRatePer?: InputMaybe<Scalars['Float']>;
  readonly exchangeRate?: InputMaybe<Scalars['Float']>;
  readonly exchangeRateDate?: InputMaybe<Scalars['DateTime']>;
  readonly billToCodeId?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly stopId?: InputMaybe<Scalars['ID']>;
};

export type CloneLoadRouteInput = {
  readonly id: Scalars['ID'];
  readonly maxCost?: InputMaybe<Scalars['Float']>;
  readonly rebookCarrier?: InputMaybe<Scalars['Boolean']>;
  readonly copyCarrierCost?: InputMaybe<Scalars['Boolean']>;
  readonly emptyLocation?: InputMaybe<Scalars['String']>;
  readonly emptyDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly emptyTrailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly emptyTrailerLength?: InputMaybe<UnitOfLengthInput>;
  readonly stopDetails: ReadonlyArray<CloneLoadStopInput>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type CloneLoadStopInput = {
  readonly note?: InputMaybe<Scalars['String']>;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInputV2>>;
  readonly reqDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly appointment?: InputMaybe<AppointmentInputV2>;
  readonly apptConfBy?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly availableStart: DatetimeWithTimezoneInput;
  readonly availableEnd: DatetimeWithTimezoneInput;
};

export type CloseMultipleIncidentV2 = {
  readonly __typename?: 'CloseMultipleIncidentV2';
  readonly errors: ReadonlyArray<UpdateError>;
  readonly incidentResponses: ReadonlyArray<IncidentSuccessResponse>;
};

export type CloseRoundInput = {
  readonly roundId?: InputMaybe<Scalars['ID']>;
};

export type CloseRoundPayload = {
  readonly __typename?: 'CloseRoundPayload';
  readonly message?: Maybe<Scalars['String']>;
  readonly isRoundClosed?: Maybe<Scalars['Boolean']>;
};

export type CommissionEvent = {
  readonly __typename?: 'CommissionEvent';
  readonly description?: Maybe<Scalars['String']>;
  readonly commissionEventType?: Maybe<CommissionEventTypeEnum>;
  readonly events?: Maybe<ReadonlyArray<Maybe<CommissionEvent>>>;
};

export enum CommissionEventTypeEnum {
  Step = 'STEP',
  Calculation = 'CALCULATION',
  Configuration = 'CONFIGURATION',
  Information = 'INFORMATION',
  Override = 'OVERRIDE'
}

export type CommissionsCustomerOverheadConfiguration = {
  readonly __typename?: 'CommissionsCustomerOverheadConfiguration';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly transportModeId?: Maybe<Scalars['String']>;
  readonly loadSizeId?: Maybe<Scalars['String']>;
  readonly divisionId?: Maybe<Scalars['String']>;
  readonly customerOverheadTypeId: Scalars['String'];
  readonly customerOverheadFlatAmount?: Maybe<Scalars['Decimal']>;
  readonly customerOverheadPercent?: Maybe<Scalars['Decimal']>;
  readonly customerOverheadPercentMinAmount?: Maybe<Scalars['Decimal']>;
  readonly customerOverheadPercentMaxAmount?: Maybe<Scalars['Decimal']>;
  /** @deprecated Field no longer supported */
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type CommissionsCustomerOverheadConfigurationFilterInput = {
  readonly customerId: Scalars['ID'];
  readonly sizeModeDivisionFilter?: InputMaybe<CommissionsSizeModeDivisionFilterInput>;
};

export type CommissionsCustomerOverheadConfigurationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly transportModeId?: InputMaybe<Scalars['String']>;
  readonly loadSizeId?: InputMaybe<Scalars['String']>;
  readonly divisionId?: InputMaybe<Scalars['String']>;
  readonly customerOverheadTypeId: Scalars['String'];
  readonly customerOverheadFlatAmount?: InputMaybe<Scalars['Decimal']>;
  readonly customerOverheadPercent?: InputMaybe<Scalars['Decimal']>;
  readonly customerOverheadPercentMinAmount?: InputMaybe<Scalars['Decimal']>;
  readonly customerOverheadPercentMaxAmount?: InputMaybe<Scalars['Decimal']>;
};

export type CommissionsGlobalConfiguration = {
  readonly __typename?: 'CommissionsGlobalConfiguration';
  readonly id: Scalars['ID'];
  readonly transportModeId?: Maybe<Scalars['String']>;
  readonly loadSizeId?: Maybe<Scalars['String']>;
  readonly divisionId?: Maybe<Scalars['String']>;
  readonly customerAllocationSplit: Scalars['Decimal'];
  readonly carrierAllocationSplit: Scalars['Decimal'];
  readonly companyOverheadTypeId: Scalars['String'];
  readonly companyOverheadFlatAmount?: Maybe<Scalars['Decimal']>;
  readonly companyOverheadPercent?: Maybe<Scalars['Decimal']>;
  readonly companyOverheadPercentMinAmount?: Maybe<Scalars['Decimal']>;
  readonly companyOverheadPercentMaxAmount?: Maybe<Scalars['Decimal']>;
  readonly routeRateAllocationMethodId?: Maybe<Scalars['String']>;
  readonly orderCostAllocationMethodId?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type CommissionsGlobalConfigurationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly transportModeId?: InputMaybe<Scalars['String']>;
  readonly loadSizeId?: InputMaybe<Scalars['String']>;
  readonly divisionId?: InputMaybe<Scalars['String']>;
  readonly customerAllocationSplit: Scalars['Decimal'];
  readonly carrierAllocationSplit: Scalars['Decimal'];
  readonly companyOverheadTypeId: Scalars['String'];
  readonly companyOverheadFlatAmount?: InputMaybe<Scalars['Decimal']>;
  readonly companyOverheadPercent?: InputMaybe<Scalars['Decimal']>;
  readonly companyOverheadPercentMinAmount?: InputMaybe<Scalars['Decimal']>;
  readonly companyOverheadPercentMaxAmount?: InputMaybe<Scalars['Decimal']>;
  readonly routeRateAllocationMethodId?: InputMaybe<Scalars['String']>;
  readonly orderCostAllocationMethodId?: InputMaybe<Scalars['String']>;
};

export type CommissionsOverhead = {
  readonly __typename?: 'CommissionsOverhead';
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly companyOverhead?: Maybe<Scalars['Decimal']>;
  readonly customerOverhead?: Maybe<Scalars['Decimal']>;
  readonly customerOverheadDetails?: Maybe<ReadonlyArray<Maybe<CustomerOverheadDetail>>>;
};

export type CommissionsSizeModeDivisionFilterInput = {
  readonly transportModeId?: InputMaybe<Scalars['String']>;
  readonly loadSizeId?: InputMaybe<Scalars['String']>;
  readonly divisionId?: InputMaybe<Scalars['String']>;
};

export enum CommitmentDayOfWeekEnum {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  None = 'NONE'
}

export type CommitmentVolume = {
  readonly __typename?: 'CommitmentVolume';
  readonly id: Scalars['ID'];
  readonly volume?: Maybe<Scalars['Int']>;
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
};

export type Commodities = {
  readonly code?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly expectedWeight?: InputMaybe<SniQuoteDimensions>;
  readonly actualWeight?: InputMaybe<SniQuoteDimensions>;
  readonly expectedPieces?: InputMaybe<Scalars['String']>;
  readonly actualPieces?: InputMaybe<Scalars['String']>;
  readonly number?: InputMaybe<Scalars['String']>;
  readonly packagingUnit?: InputMaybe<Scalars['String']>;
  readonly loadOnTerm?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTempControlled?: InputMaybe<Scalars['Boolean']>;
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly isTopLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly isBottomLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly preCoolTo?: InputMaybe<SniQuoteDimensions>;
  readonly minimumTemperature?: InputMaybe<SniQuoteDimensions>;
  readonly maximumTemperature?: InputMaybe<SniQuoteDimensions>;
  readonly temperatureSettingTerm?: InputMaybe<Scalars['String']>;
  readonly hazmatClassTerm?: InputMaybe<Scalars['String']>;
  readonly hazmatPackagingGroupTerm?: InputMaybe<Scalars['String']>;
  readonly expectedDimensions?: InputMaybe<TrailerDimensions>;
  readonly expectedLinear?: InputMaybe<SniQuoteDimensions>;
  readonly actualLinear?: InputMaybe<SniQuoteDimensions>;
  readonly expectedCube?: InputMaybe<SniQuoteDimensions>;
  readonly actualCube?: InputMaybe<SniQuoteDimensions>;
  readonly expectedDensity?: InputMaybe<SniQuoteDimensions>;
  readonly actualDensity?: InputMaybe<SniQuoteDimensions>;
  readonly expectedClassTerm?: InputMaybe<Scalars['String']>;
  readonly actualClassTerm?: InputMaybe<Scalars['String']>;
  readonly expectedNMFC?: InputMaybe<Scalars['String']>;
  readonly actualNMFC?: InputMaybe<Scalars['String']>;
};

export type CommoditiesFilter = {
  readonly orderId?: InputMaybe<Scalars['ID']>;
};

export type CommoditiesItemRequestFilterType = {
  readonly actualPieces?: InputMaybe<Scalars['Int']>;
  readonly actualWeight?: InputMaybe<BaseExternalQuoteRequestFilterType>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly expectedPieces?: InputMaybe<Scalars['Int']>;
  readonly expectedWeight?: InputMaybe<BaseExternalQuoteRequestFilterType>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTempControlled?: InputMaybe<Scalars['Boolean']>;
  readonly packagingQuantity?: InputMaybe<Scalars['Int']>;
  readonly packagingUnitTerm?: InputMaybe<Scalars['String']>;
};

/** A commodity */
export type Commodity = {
  readonly __typename?: 'Commodity';
  readonly actualClass?: Maybe<KeyValue>;
  /** @deprecated Use actual_volume */
  readonly actualCube?: Maybe<Length>;
  readonly actualDensity?: Maybe<Scalars['String']>;
  readonly actualDimensions?: Maybe<Dimensions>;
  readonly actualLinear?: Maybe<Length>;
  readonly actualNMFCCode?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated due to typo, use actual NMFC Code */
  readonly actualNfmcCode?: Maybe<Scalars['String']>;
  readonly actualPieces?: Maybe<Scalars['Int']>;
  readonly actualVolume?: Maybe<Volume>;
  readonly actualWeight?: Maybe<Mass>;
  readonly bottomLoadable?: Maybe<Scalars['Boolean']>;
  readonly customerCode?: Maybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly expectedClass?: Maybe<KeyValue>;
  /** @deprecated Use expected_volume */
  readonly expectedCube?: Maybe<Length>;
  readonly expectedDensity?: Maybe<Scalars['String']>;
  readonly expectedDimensions?: Maybe<Dimensions>;
  readonly expectedLinear?: Maybe<Length>;
  readonly expectedNMFCCode?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated due to typo, use expected NMFC Code */
  readonly expectedNfmcCode?: Maybe<Scalars['String']>;
  readonly expectedPieces?: Maybe<Scalars['Int']>;
  readonly expectedVolume?: Maybe<Volume>;
  readonly expectedWeight: Mass;
  readonly hazmat?: Maybe<Scalars['Boolean']>;
  readonly hazmatClass?: Maybe<KeyValue>;
  readonly hazmatPackagingGroup?: Maybe<CommodityHazmatPackagingGroupTypeEnum>;
  readonly hazmatPhoneNumber?: Maybe<Scalars['String']>;
  readonly hazmatUnitedNationsNumber?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly loadOnType?: Maybe<KeyValue>;
  readonly maximumTemperature?: Maybe<Temperature>;
  readonly minimumTemperature?: Maybe<Temperature>;
  readonly orderId: Scalars['ID'];
  readonly packagingCount?: Maybe<Scalars['Int']>;
  readonly packagingType?: Maybe<KeyValue>;
  readonly preCoolTo?: Maybe<Temperature>;
  readonly stccCode?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<ReadonlyArray<Stop>>;
  readonly temperatureControlled?: Maybe<Scalars['Boolean']>;
  readonly temperatureSetting?: Maybe<CommodityTemperatureSettingTypeEnum>;
  readonly topLoadable?: Maybe<Scalars['Boolean']>;
};


/** A commodity */
export type CommodityActualCubeArgs = {
  unit: UnitOfLengthEnum;
};


/** A commodity */
export type CommodityActualDimensionsArgs = {
  unit: UnitOfLengthEnum;
};


/** A commodity */
export type CommodityActualLinearArgs = {
  unit: UnitOfLengthEnum;
};


/** A commodity */
export type CommodityActualVolumeArgs = {
  unit: VolumeUnitTypeEnum;
};


/** A commodity */
export type CommodityActualWeightArgs = {
  unit: MassUnitTypeEnum;
};


/** A commodity */
export type CommodityExpectedCubeArgs = {
  unit: UnitOfLengthEnum;
};


/** A commodity */
export type CommodityExpectedDimensionsArgs = {
  unit: UnitOfLengthEnum;
};


/** A commodity */
export type CommodityExpectedLinearArgs = {
  unit: UnitOfLengthEnum;
};


/** A commodity */
export type CommodityExpectedVolumeArgs = {
  unit: VolumeUnitTypeEnum;
};


/** A commodity */
export type CommodityExpectedWeightArgs = {
  unit: MassUnitTypeEnum;
};


/** A commodity */
export type CommodityMaximumTemperatureArgs = {
  unit: TemperatureUnitTypeEnum;
};


/** A commodity */
export type CommodityMinimumTemperatureArgs = {
  unit: TemperatureUnitTypeEnum;
};


/** A commodity */
export type CommodityPreCoolToArgs = {
  unit: TemperatureUnitTypeEnum;
};

export enum CommodityActionEnum {
  Loading = 'Loading',
  Unloading = 'Unloading'
}

/** The connection type for Commodity. */
export type CommodityConnection = {
  readonly __typename?: 'CommodityConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<CommodityEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type CommodityDimensionInput = {
  readonly length: DimensionInput;
  readonly width: DimensionInput;
  readonly height: DimensionInput;
};

export type CommodityDimensionInputV2 = {
  readonly length?: InputMaybe<DimensionInput>;
  readonly width?: InputMaybe<DimensionInput>;
  readonly height?: InputMaybe<DimensionInput>;
};

export type CommodityDimensionsV2 = {
  readonly __typename?: 'CommodityDimensionsV2';
  readonly height: LengthV2;
  readonly length: LengthV2;
  readonly width: LengthV2;
};


export type CommodityDimensionsV2HeightArgs = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityDimensionsV2LengthArgs = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityDimensionsV2WidthArgs = {
  unit?: InputMaybe<Scalars['String']>;
};

/** An edge in a connection. */
export type CommodityEdge = {
  readonly __typename?: 'CommodityEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: Commodity;
};

export enum CommodityFreightClassTypeEnum {
  /** Class_100 */
  Class_100 = 'Class_100',
  /** Class_110 */
  Class_110 = 'Class_110',
  /** Class_125 */
  Class_125 = 'Class_125',
  /** Class_150 */
  Class_150 = 'Class_150',
  /** Class_175 */
  Class_175 = 'Class_175',
  /** Class_200 */
  Class_200 = 'Class_200',
  /** Class_250 */
  Class_250 = 'Class_250',
  /** Class_300 */
  Class_300 = 'Class_300',
  /** Class_400 */
  Class_400 = 'Class_400',
  /** Class_50 */
  Class_50 = 'Class_50',
  /** Class_500 */
  Class_500 = 'Class_500',
  /** Class_55 */
  Class_55 = 'Class_55',
  /** Class_60 */
  Class_60 = 'Class_60',
  /** Class_65 */
  Class_65 = 'Class_65',
  /** Class_70 */
  Class_70 = 'Class_70',
  /** Class_77_5 */
  Class_77_5 = 'Class_77_5',
  /** Class_85 */
  Class_85 = 'Class_85',
  /** Class_92_5 */
  Class_92_5 = 'Class_92_5',
  /** No_Freight_Class */
  NoFreightClass = 'No_Freight_Class'
}

export enum CommodityHazmatClassTypeEnum {
  /** Division_1_1 */
  Division_1_1 = 'Division_1_1',
  /** Division_1_2 */
  Division_1_2 = 'Division_1_2',
  /** Division_1_3 */
  Division_1_3 = 'Division_1_3',
  /** Division_1_4 */
  Division_1_4 = 'Division_1_4',
  /** Division_1_5 */
  Division_1_5 = 'Division_1_5',
  /** Division_1_6 */
  Division_1_6 = 'Division_1_6',
  /** Division_2_1 */
  Division_2_1 = 'Division_2_1',
  /** Division_2_2 */
  Division_2_2 = 'Division_2_2',
  /** Division_2_3 */
  Division_2_3 = 'Division_2_3',
  /** Division_3 */
  Division_3 = 'Division_3',
  /** Division_4_1 */
  Division_4_1 = 'Division_4_1',
  /** Division_4_2 */
  Division_4_2 = 'Division_4_2',
  /** Division_4_3 */
  Division_4_3 = 'Division_4_3',
  /** Division_5_1 */
  Division_5_1 = 'Division_5_1',
  /** Division_5_2 */
  Division_5_2 = 'Division_5_2',
  /** Division_6_1 */
  Division_6_1 = 'Division_6_1',
  /** Division_6_2 */
  Division_6_2 = 'Division_6_2',
  /** Division_7 */
  Division_7 = 'Division_7',
  /** Division_8 */
  Division_8 = 'Division_8',
  /** Division_9 */
  Division_9 = 'Division_9'
}

export enum CommodityHazmatPackagingGroupTypeEnum {
  /** I */
  I = 'I',
  /** II */
  Ii = 'II',
  /** III */
  Iii = 'III'
}

export type CommodityInfoInput = {
  readonly routeId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly commodities: ReadonlyArray<DriverCommodityInput>;
};

export type CommodityInfoInputV2 = {
  readonly routeId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly commodities: ReadonlyArray<DriverCommodityInputV2>;
};

export type CommodityInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly orderId?: InputMaybe<Scalars['ID']>;
  readonly description: Scalars['String'];
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly packagingCount?: InputMaybe<Scalars['Int']>;
  readonly packagingType?: InputMaybe<CommodityPackagingTypeEnum>;
  readonly packagingTypeId?: InputMaybe<Scalars['ID']>;
  readonly loadOnType?: InputMaybe<CommodityLoadOnTypeEnum>;
  readonly loadOnTypeId?: InputMaybe<Scalars['ID']>;
  readonly topLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly bottomLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly doNotStack?: InputMaybe<Scalars['Boolean']>;
  readonly expectedWeight?: InputMaybe<UnitOfMassInput>;
  readonly actualWeight?: InputMaybe<UnitOfMassInput>;
  /** either expectedWeight or expectedWeightV2 is required */
  readonly expectedWeightV2?: InputMaybe<UnitOfMassInputV2>;
  readonly actualWeightV2?: InputMaybe<UnitOfMassInputV2>;
  readonly pieceTypeId?: InputMaybe<Scalars['ID']>;
  readonly expectedPieces?: InputMaybe<Scalars['Int']>;
  readonly actualPieces?: InputMaybe<Scalars['Int']>;
  readonly temperatureControlled?: InputMaybe<Scalars['Boolean']>;
  readonly preCoolTo?: InputMaybe<UnitOfTemperatureInput>;
  readonly minimumTemperature?: InputMaybe<UnitOfTemperatureInput>;
  readonly maximumTemperature?: InputMaybe<UnitOfTemperatureInput>;
  readonly preCoolToV2?: InputMaybe<UnitOfTemperatureInputV2>;
  readonly minimumTemperatureV2?: InputMaybe<UnitOfTemperatureInputV2>;
  readonly maximumTemperatureV2?: InputMaybe<UnitOfTemperatureInputV2>;
  readonly temperatureSetting?: InputMaybe<CommodityTemperatureSettingTypeEnum>;
  readonly commodityTagIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly hazmatClass?: InputMaybe<CommodityHazmatClassTypeEnum>;
  readonly hazmatClassId?: InputMaybe<Scalars['ID']>;
  readonly hazmatPackagingGroup?: InputMaybe<CommodityHazmatPackagingGroupTypeEnum>;
  readonly hazmatUnitedNationsNumber?: InputMaybe<Scalars['String']>;
  readonly hazmatPhoneNumber?: InputMaybe<Scalars['String']>;
  readonly actualDimensions?: InputMaybe<DimensionsInput>;
  readonly expectedDimensions?: InputMaybe<DimensionsInput>;
  readonly actualDimensionsV2?: InputMaybe<DimensionsInputV2>;
  readonly expectedDimensionsV2?: InputMaybe<DimensionsInputV2>;
  readonly expectedLinear?: InputMaybe<UnitOfLengthInput>;
  readonly actualLinear?: InputMaybe<UnitOfLengthInput>;
  readonly expectedLinearV2?: InputMaybe<UnitOfLengthInputV2>;
  readonly actualLinearV2?: InputMaybe<UnitOfLengthInputV2>;
  readonly actualVolume?: InputMaybe<UnitOfVolumeInput>;
  readonly expectedVolume?: InputMaybe<UnitOfVolumeInput>;
  readonly actualVolumeV2?: InputMaybe<UnitOfVolumeInputV2>;
  readonly expectedVolumeV2?: InputMaybe<UnitOfVolumeInputV2>;
  readonly actualDensity?: InputMaybe<Scalars['String']>;
  readonly actualDensityInput?: InputMaybe<UnitOfDensityInput>;
  readonly actualDensityV2?: InputMaybe<UnitOfDensityInputV2>;
  readonly expectedDensity?: InputMaybe<Scalars['String']>;
  readonly expectedDensityInput?: InputMaybe<UnitOfDensityInput>;
  readonly expectedDensityV2?: InputMaybe<UnitOfDensityInputV2>;
  readonly actualClass?: InputMaybe<CommodityFreightClassTypeEnum>;
  readonly actualClassId?: InputMaybe<Scalars['ID']>;
  readonly expectedClass?: InputMaybe<CommodityFreightClassTypeEnum>;
  readonly expectedClassId?: InputMaybe<Scalars['ID']>;
  readonly actualNMFCCode?: InputMaybe<Scalars['String']>;
  readonly expectedNMFCCode?: InputMaybe<Scalars['String']>;
  /** 0 based integer and references order of stops passed */
  readonly associatedStopIndices?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly associatedStopIndicesV2?: InputMaybe<ReadonlyArray<CommodityStopAssociationInput>>;
  readonly overDimensional?: InputMaybe<Scalars['Boolean']>;
  /** YYYY format */
  readonly year?: InputMaybe<Scalars['Float']>;
  readonly make?: InputMaybe<Scalars['String']>;
  readonly model?: InputMaybe<Scalars['String']>;
  readonly serialNumbers?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly shipments?: InputMaybe<ReadonlyArray<ShipmentInput>>;
  readonly splitDimensions?: InputMaybe<Scalars['Boolean']>;
  readonly shipmentCommodities?: InputMaybe<ReadonlyArray<ShipmentCommodityInput>>;
  readonly commodityValue?: InputMaybe<LoadCurrencyInput>;
  readonly hazmatContactName?: InputMaybe<Scalars['String']>;
  readonly placardRequired?: InputMaybe<Scalars['Boolean']>;
  readonly flashPointTemperature?: InputMaybe<UnitOfTemperatureInputV2>;
};

export enum CommodityLoadOnTypeEnum {
  /** Floor */
  Floor = 'Floor',
  /** Floot */
  Floot = 'Floot',
  /** Pallet */
  Pallet = 'Pallet',
  /** Skid */
  Skid = 'Skid',
  /** ccc */
  Ccc = 'ccc',
  /** ddd */
  Ddd = 'ddd'
}

export enum CommodityPackagingTypeEnum {
  /** Ani_Drum_Test1 */
  AniDrumTest1 = 'Ani_Drum_Test1',
  /** Bag */
  Bag = 'Bag',
  /** Bale */
  Bale = 'Bale',
  /** Box */
  Box = 'Box',
  /** Bundle */
  Bundle = 'Bundle',
  /** Carton */
  Carton = 'Carton',
  /** Cases */
  Cases = 'Cases',
  /** Crate */
  Crate = 'Crate',
  /** Drum */
  Drum = 'Drum',
  /** Pallet */
  Pallet = 'Pallet',
  /** Pieces */
  Pieces = 'Pieces',
  /** Roll */
  Roll = 'Roll',
  /** Skid */
  Skid = 'Skid',
  /** ToteBin */
  ToteBin = 'ToteBin',
  /** Tube */
  Tube = 'Tube',
  /** aaaaaaaaaddd */
  Aaaaaaaaaddd = 'aaaaaaaaaddd'
}

export enum CommodityPhaseEnum {
  FirstLoading = 'FirstLoading',
  LastUnloading = 'LastUnloading'
}

export type CommodityStopAssociationInput = {
  readonly stopIndex: Scalars['Float'];
  readonly expectedWeight?: InputMaybe<Scalars['Float']>;
  readonly expectedHandlingUnits?: InputMaybe<Scalars['Float']>;
  readonly expectedPieces?: InputMaybe<Scalars['Float']>;
  readonly actualWeight?: InputMaybe<Scalars['Float']>;
  readonly actualPieces?: InputMaybe<Scalars['Float']>;
  readonly orderStopDesignation?: InputMaybe<Scalars['String']>;
};

export enum CommodityTemperatureSettingTypeEnum {
  /** continuous */
  Continuous = 'continuous',
  /** cycle */
  Cycle = 'cycle'
}

/** Agregate information of commodities */
export type CommodityTotals = {
  readonly __typename?: 'CommodityTotals';
  readonly actualWeight: Mass;
  readonly expectedWeight: Mass;
};


/** Agregate information of commodities */
export type CommodityTotalsActualWeightArgs = {
  unit: MassUnitTypeEnum;
};


/** Agregate information of commodities */
export type CommodityTotalsExpectedWeightArgs = {
  unit: MassUnitTypeEnum;
};

export type CommodityV2 = {
  readonly __typename?: 'CommodityV2';
  readonly id: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly description: Scalars['String'];
  readonly stccCode?: Maybe<Scalars['String']>;
  readonly customerCode?: Maybe<Scalars['String']>;
  readonly packagingCount?: Maybe<Scalars['Int']>;
  readonly packagingType?: Maybe<KeyValue>;
  readonly loadOnType?: Maybe<KeyValue>;
  readonly topLoadable?: Maybe<Scalars['Boolean']>;
  readonly bottomLoadable?: Maybe<Scalars['Boolean']>;
  readonly doNotStack?: Maybe<Scalars['Boolean']>;
  /** @deprecated Please use expectedWeightV2. It supports new uom values */
  readonly expectedWeight: Mass;
  /** @deprecated Please use actualWeightV2. It supports new uom values */
  readonly actualWeight?: Maybe<Mass>;
  readonly expectedWeightV2?: Maybe<MassV2>;
  readonly actualWeightV2?: Maybe<MassV2>;
  readonly pieceType?: Maybe<KeyValue>;
  readonly expectedPieces?: Maybe<Scalars['Int']>;
  readonly actualPieces?: Maybe<Scalars['Int']>;
  readonly temperatureControlled?: Maybe<Scalars['Boolean']>;
  /** @deprecated Please use preCoolToV2. It supports new uom values */
  readonly preCoolTo?: Maybe<Temperature>;
  /** @deprecated Please use minimumTemperatureV2. It supports new uom values */
  readonly minimumTemperature?: Maybe<Temperature>;
  /** @deprecated Please use maximumTemperatureV2. It supports new uom values */
  readonly maximumTemperature?: Maybe<Temperature>;
  readonly preCoolToV2?: Maybe<TemperatureV2>;
  readonly minimumTemperatureV2?: Maybe<TemperatureV2>;
  readonly maximumTemperatureV2?: Maybe<TemperatureV2>;
  readonly temperatureSetting?: Maybe<CommodityTemperatureSettingTypeEnum>;
  readonly commodityTagIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly hazmat?: Maybe<Scalars['Boolean']>;
  readonly hazmatClass?: Maybe<KeyValue>;
  readonly hazmatPackagingGroup?: Maybe<CommodityHazmatPackagingGroupTypeEnum>;
  readonly hazmatUnitedNationsNumber?: Maybe<Scalars['String']>;
  readonly hazmatPhoneNumber?: Maybe<Scalars['String']>;
  /** @deprecated Please use actualDimensionsV2. It supports new uom values */
  readonly actualDimensions?: Maybe<DimensionsV2>;
  /** @deprecated Please use expectedDimensionsV2. It supports new uom values */
  readonly expectedDimensions?: Maybe<DimensionsV2>;
  readonly actualDimensionsV2?: Maybe<CommodityDimensionsV2>;
  readonly displayActualDimensionsV2?: Maybe<DimensionDisplay>;
  readonly expectedDimensionsV2?: Maybe<CommodityDimensionsV2>;
  readonly displayExpectedDimensionsV2?: Maybe<DimensionDisplay>;
  /** @deprecated Please use expectedLinearV2. It supports new uom values */
  readonly expectedLinear?: Maybe<Length>;
  /** @deprecated Please use actualLinearV2. It supports new uom values */
  readonly actualLinear?: Maybe<Length>;
  readonly expectedLinearV2?: Maybe<LengthV2>;
  readonly displayExpectedLinearV2?: Maybe<Scalars['String']>;
  readonly actualLinearV2?: Maybe<LengthV2>;
  readonly displayActualLinearV2?: Maybe<Scalars['String']>;
  /** @deprecated Please use actualVolumeV2. It supports new uom values */
  readonly actualVolume?: Maybe<Volume>;
  /** @deprecated Please use expectedVolumeV2. It supports new uom values */
  readonly expectedVolume?: Maybe<Volume>;
  readonly actualVolumeV2?: Maybe<VolumeV2>;
  readonly expectedVolumeV2?: Maybe<VolumeV2>;
  /** @deprecated Please use actualDensityV2. It supports new uom values */
  readonly actualDensity?: Maybe<Density>;
  /** @deprecated Please use expectedDensityV2. It supports new uom values */
  readonly expectedDensity?: Maybe<Density>;
  readonly actualDensityV2?: Maybe<DensityV2>;
  readonly expectedDensityV2?: Maybe<DensityV2>;
  readonly actualClass?: Maybe<KeyValue>;
  readonly expectedClass?: Maybe<KeyValue>;
  readonly actualNMFCCode?: Maybe<Scalars['String']>;
  readonly expectedNMFCCode?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly overDimensional?: Maybe<Scalars['Boolean']>;
  readonly year?: Maybe<Scalars['Float']>;
  readonly make?: Maybe<Scalars['String']>;
  readonly model?: Maybe<Scalars['String']>;
  readonly serialNumbers?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** Shipments to which this commodity belongs */
  readonly shipments?: Maybe<ReadonlyArray<Shipment>>;
  /** Order Stops on commodities */
  readonly orderStops?: Maybe<ReadonlyArray<OrderStop>>;
  /** Shipment/Commodity tracking states as they relate to an order stop. */
  readonly stopTracking?: Maybe<ReadonlyArray<StopTracking>>;
  readonly splitDimensions?: Maybe<Scalars['Boolean']>;
  /** Shipment Commodities on Commodity */
  readonly shipmentCommodities?: Maybe<ReadonlyArray<ShipmentCommodity>>;
  readonly commodityValue: LoadCurrency;
  readonly hazmatContactName?: Maybe<Scalars['String']>;
  readonly placardRequired?: Maybe<Scalars['Boolean']>;
  readonly flashPointTemperature?: Maybe<TemperatureV2>;
  readonly stops?: Maybe<ReadonlyArray<StopCommodityFromCommodity>>;
  readonly isSplit?: Maybe<Scalars['Boolean']>;
  readonly assignedExpectedWeight: Mass;
};


export type CommodityV2ExpectedWeightArgs = {
  unit: MassUnitTypeEnum;
};


export type CommodityV2ActualWeightArgs = {
  unit: MassUnitTypeEnum;
};


export type CommodityV2ExpectedWeightV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2ActualWeightV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2PreCoolToV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2MinimumTemperatureV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2MaximumTemperatureV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2ExpectedLinearArgs = {
  unit: UnitOfLengthEnum;
};


export type CommodityV2ActualLinearArgs = {
  unit: UnitOfLengthEnum;
};


export type CommodityV2ExpectedLinearV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2ActualLinearV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2ActualVolumeArgs = {
  unit: VolumeUnitTypeEnum;
};


export type CommodityV2ExpectedVolumeArgs = {
  unit: VolumeUnitTypeEnum;
};


export type CommodityV2ActualVolumeV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2ExpectedVolumeV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2ActualDensityV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2ExpectedDensityV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2CommodityValueArgs = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2AssignedExpectedWeightArgs = {
  unit?: InputMaybe<Scalars['String']>;
};

export type CompleteStopInfoInput = {
  readonly bolNumber: Scalars['String'];
  readonly sealNumber: Scalars['String'];
  readonly facilityId: Scalars['ID'];
  readonly stopEvent: StopEventInfoInput;
  readonly refs: ReadonlyArray<StopRefInput>;
};

export type CompleteStopInfoInputV2 = {
  readonly bolNumber?: InputMaybe<Scalars['String']>;
  readonly sealNumber?: InputMaybe<Scalars['String']>;
  readonly facilityId: Scalars['ID'];
  readonly stopEvent: StopEventInfoInputV2;
  readonly refs: ReadonlyArray<InputMaybe<StopRefInput>>;
};

export type CompleteStopInput = {
  readonly driverId: Scalars['ID'];
  readonly completeStopInfo: CompleteStopInfoInput;
};

export type CompleteStopInputV2 = {
  readonly driverId: Scalars['ID'];
  readonly completeStopInfo: CompleteStopInfoInputV2;
};

export type CompleteStopPayload = {
  readonly __typename?: 'CompleteStopPayload';
  readonly realm: Scalars['String'];
  readonly driverRoute: DriverRoute;
  readonly errors: ReadonlyArray<UserError>;
};

export type CompleteStopPayloadV2 = {
  readonly __typename?: 'CompleteStopPayloadV2';
  readonly realm?: Maybe<Scalars['String']>;
  readonly driverRoute?: Maybe<DriverRouteV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CompleteUploadInput = {
  readonly documentId: Scalars['String'];
  readonly documentType: Scalars['String'];
  readonly successCount: Scalars['Int'];
  readonly failedCount: Scalars['Int'];
  readonly processedCount: Scalars['Int'];
  readonly uploadedBy?: InputMaybe<Scalars['ID']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly docError: Scalars['String'];
};

export type CompleteUploadInputV2 = {
  readonly documentId: Scalars['String'];
  readonly documentType: Scalars['String'];
  readonly successCount: Scalars['Int'];
  readonly failedCount: Scalars['Int'];
  readonly processedCount: Scalars['Int'];
  readonly uploadedBy?: InputMaybe<Scalars['ID']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly errors?: InputMaybe<ReadonlyArray<RowErrorInput>>;
};

export type CompleteUploadPayload = {
  readonly __typename?: 'CompleteUploadPayload';
  readonly message?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Boolean']>;
};

export type ComputedCurrentHosClocks = {
  readonly __typename?: 'ComputedCurrentHosClocks';
  readonly id: Scalars['ID'];
  readonly breakTime?: Maybe<DriverHosTime>;
  readonly driverTime?: Maybe<DriverHosTime>;
  readonly shiftTime?: Maybe<DriverHosTime>;
  readonly cycleTime?: Maybe<DriverHosTime>;
};

export type ConditionProperties = {
  readonly __typename?: 'ConditionProperties';
  readonly fact?: Maybe<Scalars['String']>;
  readonly operator?: Maybe<OperatorType>;
  readonly value?: Maybe<DataValue>;
  readonly immutable?: Maybe<Scalars['Boolean']>;
  readonly any?: Maybe<ReadonlyArray<Maybe<ConditionProperties>>>;
  readonly all?: Maybe<ReadonlyArray<Maybe<ConditionProperties>>>;
};

export type ConditionPropertiesInput = {
  readonly fact?: InputMaybe<Scalars['String']>;
  readonly operator?: InputMaybe<OperatorType>;
  readonly value?: InputMaybe<DataValueInput>;
  readonly immutable?: InputMaybe<Scalars['Boolean']>;
  readonly any?: InputMaybe<ReadonlyArray<InputMaybe<ConditionPropertiesInput>>>;
  readonly all?: InputMaybe<ReadonlyArray<InputMaybe<ConditionPropertiesInput>>>;
};

export type Conditions = {
  readonly __typename?: 'Conditions';
  readonly scacs?: Maybe<ReadonlyArray<OrderProfileKeyValue>>;
  readonly transportModes?: Maybe<ReadonlyArray<OrderProfileKeyValue>>;
  readonly sizes?: Maybe<ReadonlyArray<OrderProfileKeyValue>>;
  readonly trailerTypes?: Maybe<ReadonlyArray<OrderProfileKeyValue>>;
  readonly paymentTerms?: Maybe<ReadonlyArray<OrderProfileKeyValue>>;
  readonly tenderFrom?: Maybe<ReadonlyArray<CustomerV2>>;
};

export enum ConfigScope {
  Carrier = 'Carrier',
  Customer = 'Customer',
  Tenant = 'Tenant'
}

export enum ConfigTypeEnum {
  /** activationStatusCancelReason */
  ActivationStatusCancelReason = 'activationStatusCancelReason',
  /** activationStatusHoldReason */
  ActivationStatusHoldReason = 'activationStatusHoldReason',
  /** activationStatusType */
  ActivationStatusType = 'activationStatusType',
  /**
   * addressType
   * @deprecated AddressType is deprecated as it is now a base class for other entity-based address types.  Use the CustomerAddressType or  CarrierAddressType for Customer, Carrier respectively
   */
  AddressType = 'addressType',
  /** advanceDenialType */
  AdvanceDenialType = 'advanceDenialType',
  /** advanceType */
  AdvanceType = 'advanceType',
  /** bounceReason */
  BounceReason = 'bounceReason',
  /** bounceType */
  BounceType = 'bounceType',
  /** carrierAddressType */
  CarrierAddressType = 'carrierAddressType',
  /** carrierCertificationType */
  CarrierCertificationType = 'carrierCertificationType',
  /** carrierContactType */
  CarrierContactType = 'carrierContactType',
  /** carrierCrmOpportunityStatus */
  CarrierCrmOpportunityStatus = 'carrierCrmOpportunityStatus',
  /** carrierCrmOpportunityType */
  CarrierCrmOpportunityType = 'carrierCrmOpportunityType',
  /** carrierDocumentSourceType */
  CarrierDocumentSourceType = 'carrierDocumentSourceType',
  /** carrierDocumentType */
  CarrierDocumentType = 'carrierDocumentType',
  /** carrierIdentifierType */
  CarrierIdentifierType = 'carrierIdentifierType',
  /** carrierInsuranceType */
  CarrierInsuranceType = 'carrierInsuranceType',
  /** carrierInteractionFrequency */
  CarrierInteractionFrequency = 'carrierInteractionFrequency',
  /** carrierInteractionOutcome */
  CarrierInteractionOutcome = 'carrierInteractionOutcome',
  /** carrierInteractionType */
  CarrierInteractionType = 'carrierInteractionType',
  /** carrierInteractionVia */
  CarrierInteractionVia = 'carrierInteractionVia',
  /** carrierLeadSource */
  CarrierLeadSource = 'carrierLeadSource',
  /** carrierLevelType */
  CarrierLevelType = 'carrierLevelType',
  /** carrierOpportunityType */
  CarrierOpportunityType = 'carrierOpportunityType',
  /** carrierPaymentTerm */
  CarrierPaymentTerm = 'carrierPaymentTerm',
  /** carrierReleaseMethod */
  CarrierReleaseMethod = 'carrierReleaseMethod',
  /** carrierSalesProgressType */
  CarrierSalesProgressType = 'carrierSalesProgressType',
  /** carrierServiceType */
  CarrierServiceType = 'carrierServiceType',
  /** carrierStandingReasonCode */
  CarrierStandingReasonCode = 'carrierStandingReasonCode',
  /** carrierStandingStatusType */
  CarrierStandingStatusType = 'carrierStandingStatusType',
  /** carrierStandingType */
  CarrierStandingType = 'carrierStandingType',
  /** chargeType */
  ChargeType = 'chargeType',
  /** chatType */
  ChatType = 'chatType',
  /** commissionBasis */
  CommissionBasis = 'commissionBasis',
  /** commissionMarginBasis */
  CommissionMarginBasis = 'commissionMarginBasis',
  /** commissionPayTrigger */
  CommissionPayTrigger = 'commissionPayTrigger',
  /** commissionPlanAppliesTo */
  CommissionPlanAppliesTo = 'commissionPlanAppliesTo',
  /** commissionType */
  CommissionType = 'commissionType',
  /** commodityFreightClassType */
  CommodityFreightClassType = 'commodityFreightClassType',
  /** commodityHazmatClassType */
  CommodityHazmatClassType = 'commodityHazmatClassType',
  /** commodityLoadOnType */
  CommodityLoadOnType = 'commodityLoadOnType',
  /** commodityPackagingType */
  CommodityPackagingType = 'commodityPackagingType',
  /** companyOverheadType */
  CompanyOverheadType = 'companyOverheadType',
  /** compensationPeriod */
  CompensationPeriod = 'compensationPeriod',
  /** configureInvoiceLanguage */
  ConfigureInvoiceLanguage = 'configureInvoiceLanguage',
  /**
   * contactType
   * @deprecated ContactType is deprecated as it is now a base class for other entity-based address types.  Use the CustomerContactType or CarrierContactType for Customer, Carrier, and Facility respectively
   */
  ContactType = 'contactType',
  /** costQuoteType */
  CostQuoteType = 'costQuoteType',
  /** country */
  Country = 'country',
  /** creditProvider */
  CreditProvider = 'creditProvider',
  /** currency */
  Currency = 'currency',
  /** customerAddressType */
  CustomerAddressType = 'customerAddressType',
  /** customerContactType */
  CustomerContactType = 'customerContactType',
  /** customerCreditStatus */
  CustomerCreditStatus = 'customerCreditStatus',
  /** customerCrmOpportunityStatus */
  CustomerCrmOpportunityStatus = 'customerCrmOpportunityStatus',
  /** customerCrmOpportunityType */
  CustomerCrmOpportunityType = 'customerCrmOpportunityType',
  /** customerDocumentProvider */
  CustomerDocumentProvider = 'customerDocumentProvider',
  /** customerDocumentType */
  CustomerDocumentType = 'customerDocumentType',
  /** customerGroupType */
  CustomerGroupType = 'customerGroupType',
  /** customerLeadSource */
  CustomerLeadSource = 'customerLeadSource',
  /** customerLevelType */
  CustomerLevelType = 'customerLevelType',
  /** customerOpportunityType */
  CustomerOpportunityType = 'customerOpportunityType',
  /** customerOverheadType */
  CustomerOverheadType = 'customerOverheadType',
  /** customerPaymentTerm */
  CustomerPaymentTerm = 'customerPaymentTerm',
  /** customerRepType */
  CustomerRepType = 'customerRepType',
  /** customerSalesProgressType */
  CustomerSalesProgressType = 'customerSalesProgressType',
  /** customerStatusType */
  CustomerStatusType = 'customerStatusType',
  /** declinedReason */
  DeclinedReason = 'declinedReason',
  /** division */
  Division = 'division',
  /** driverReloadIntent */
  DriverReloadIntent = 'driverReloadIntent',
  /** electronicTrackingMethod */
  ElectronicTrackingMethod = 'electronicTrackingMethod',
  /** electronicTrackingType */
  ElectronicTrackingType = 'electronicTrackingType',
  /** employeeCompanyLevel */
  EmployeeCompanyLevel = 'employeeCompanyLevel',
  /** employeeDepartment */
  EmployeeDepartment = 'employeeDepartment',
  /** employeeDepartmentLevel */
  EmployeeDepartmentLevel = 'employeeDepartmentLevel',
  /**
   * employeeDivision
   * @deprecated EmployeeDivision is deprecated as it is has been renamed to Division
   */
  EmployeeDivision = 'employeeDivision',
  /** employeeGender */
  EmployeeGender = 'employeeGender',
  /** employeeGroup */
  EmployeeGroup = 'employeeGroup',
  /** employeeOffice */
  EmployeeOffice = 'employeeOffice',
  /** employeeRole */
  EmployeeRole = 'employeeRole',
  /** employeeShirtSize */
  EmployeeShirtSize = 'employeeShirtSize',
  /** employeeStatus */
  EmployeeStatus = 'employeeStatus',
  /** employeeSuffix */
  EmployeeSuffix = 'employeeSuffix',
  /** employeeTime */
  EmployeeTime = 'employeeTime',
  /** employeeType */
  EmployeeType = 'employeeType',
  /** facilityAddressType */
  FacilityAddressType = 'facilityAddressType',
  /** facilityContactType */
  FacilityContactType = 'facilityContactType',
  /** facilityIdentifierType */
  FacilityIdentifierType = 'facilityIdentifierType',
  /** facilityType */
  FacilityType = 'facilityType',
  /** geographyType */
  GeographyType = 'geographyType',
  /** identifierType */
  IdentifierType = 'identifierType',
  /** interactionFrequency */
  InteractionFrequency = 'interactionFrequency',
  /** interactionOutcome */
  InteractionOutcome = 'interactionOutcome',
  /** interactionType */
  InteractionType = 'interactionType',
  /** interactionVia */
  InteractionVia = 'interactionVia',
  /** invoiceDistributionMethod */
  InvoiceDistributionMethod = 'invoiceDistributionMethod',
  /** lateArrivalReasonCode */
  LateArrivalReasonCode = 'lateArrivalReasonCode',
  /** lateArrivalWindow */
  LateArrivalWindow = 'lateArrivalWindow',
  /** loadDirectionType */
  LoadDirectionType = 'loadDirectionType',
  /** loadDocumentSource */
  LoadDocumentSource = 'loadDocumentSource',
  /** loadDocumentType */
  LoadDocumentType = 'loadDocumentType',
  /** loadReferenceType */
  LoadReferenceType = 'loadReferenceType',
  /** loadSize */
  LoadSize = 'loadSize',
  /** loadTrackingActionType */
  LoadTrackingActionType = 'loadTrackingActionType',
  /** loadTrackingInformationFrom */
  LoadTrackingInformationFrom = 'loadTrackingInformationFrom',
  /** marginBasis */
  MarginBasis = 'marginBasis',
  /** orderCostAllocationMethod */
  OrderCostAllocationMethod = 'orderCostAllocationMethod',
  /** orderDivisionType */
  OrderDivisionType = 'orderDivisionType',
  /** orderFinancialStatusType */
  OrderFinancialStatusType = 'orderFinancialStatusType',
  /** orderRelationType */
  OrderRelationType = 'orderRelationType',
  /** orderRequirement */
  OrderRequirement = 'orderRequirement',
  /** payTrigger */
  PayTrigger = 'payTrigger',
  /** paymentMethod */
  PaymentMethod = 'paymentMethod',
  /** quotePriceTier */
  QuotePriceTier = 'quotePriceTier',
  /** quoteTenderType */
  QuoteTenderType = 'quoteTenderType',
  /** quoteType */
  QuoteType = 'quoteType',
  /** routeActivationStatus */
  RouteActivationStatus = 'routeActivationStatus',
  /** routeFinancialStatusType */
  RouteFinancialStatusType = 'routeFinancialStatusType',
  /** routeRateAllocationMethod */
  RouteRateAllocationMethod = 'routeRateAllocationMethod',
  /** routeTransportMode */
  RouteTransportMode = 'routeTransportMode',
  /** schedulingSystemType */
  SchedulingSystemType = 'schedulingSystemType',
  /** service */
  Service = 'service',
  /** serviceLevel */
  ServiceLevel = 'serviceLevel',
  /** stopEventType */
  StopEventType = 'stopEventType',
  /** stopRequirement */
  StopRequirement = 'stopRequirement',
  /** tenantScac */
  TenantScac = 'tenantScac',
  /** tenderStatusType */
  TenderStatusType = 'tenderStatusType',
  /** timelineEventEntry */
  TimelineEventEntry = 'timelineEventEntry',
  /** timelineEventType */
  TimelineEventType = 'timelineEventType',
  /** timelineJournalEntryType */
  TimelineJournalEntryType = 'timelineJournalEntryType',
  /** trackingSystem */
  TrackingSystem = 'trackingSystem',
  /** trackingUpdateSources */
  TrackingUpdateSources = 'trackingUpdateSources',
  /** trailerType */
  TrailerType = 'trailerType',
  /** transportMode */
  TransportMode = 'transportMode',
  /** vendorInvoiceStatus */
  VendorInvoiceStatus = 'vendorInvoiceStatus'
}

export type Configuration = {
  readonly entityType?: InputMaybe<EntityType>;
  readonly entityId?: InputMaybe<Scalars['ID']>;
  readonly useDefaults?: InputMaybe<Scalars['Boolean']>;
  readonly transactionDate?: InputMaybe<Scalars['String']>;
};

export type ConfigurationDistanceRequest = {
  readonly stops: ReadonlyArray<DistanceRequestStop>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly config?: InputMaybe<Configuration>;
};

export type ConfigurationMileageRequest = {
  readonly stops: ReadonlyArray<LatLongPair>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly config?: InputMaybe<Configuration>;
};

export type ConflictingChannelLegError = IPlannedNetworkError & {
  readonly __typename?: 'ConflictingChannelLegError';
  readonly message: Scalars['String'];
  readonly code: Scalars['String'];
  readonly legs: ReadonlyArray<ChannelLeg>;
};

export type ConflictingNetworkChannelError = IPlannedNetworkError & {
  readonly __typename?: 'ConflictingNetworkChannelError';
  readonly message: Scalars['String'];
  readonly code: Scalars['String'];
  readonly channels: ReadonlyArray<NetworkChannel>;
};

export type ConflictingPlannedNetworkError = IPlannedNetworkError & {
  readonly __typename?: 'ConflictingPlannedNetworkError';
  readonly message: Scalars['String'];
  readonly code: Scalars['String'];
  readonly networks: ReadonlyArray<PlannedNetwork>;
};

export type Contact = {
  readonly __typename?: 'Contact';
  readonly id: Scalars['ID'];
  readonly active: Scalars['Boolean'];
  readonly chatType?: Maybe<KeyValue>;
  readonly chatTypeId?: Maybe<Scalars['ID']>;
  readonly chatUsername?: Maybe<Scalars['String']>;
  readonly contactType: KeyValue;
  readonly contactTypeId: Scalars['ID'];
  readonly contactableId: Scalars['ID'];
  readonly contactableType: Scalars['String'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly extension?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly main: Scalars['Boolean'];
  readonly name?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
};

export type ContactDetail = {
  readonly email: Scalars['String'];
  readonly contactName: Scalars['String'];
  readonly contactPhone: Scalars['String'];
};

export type ContactError = {
  readonly __typename?: 'ContactError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly code?: Maybe<Scalars['String']>;
};

export type ContactInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly chatTypeId?: InputMaybe<Scalars['ID']>;
  readonly chatUsername?: InputMaybe<Scalars['String']>;
  readonly contactTypeId?: InputMaybe<Scalars['ID']>;
  readonly contactableId?: InputMaybe<Scalars['ID']>;
  readonly contactableType?: InputMaybe<Scalars['String']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type ContactTenderingUsage = {
  readonly __typename?: 'ContactTenderingUsage';
  readonly tenderOptionsId?: Maybe<Scalars['ID']>;
  readonly parentObjectType?: Maybe<Scalars['String']>;
  readonly parentObjectId?: Maybe<Scalars['ID']>;
};

export type ContactTenderingUsageQueryResult = {
  readonly __typename?: 'ContactTenderingUsageQueryResult';
  readonly isTenderContactUsedInTendering?: Maybe<Scalars['Boolean']>;
  readonly tenderContactUsages?: Maybe<ReadonlyArray<Maybe<ContactTenderingUsage>>>;
};

export enum ContactTypeEnum {
  Cc = 'Cc',
  Bcc = 'Bcc'
}

export type ContainerConnection = {
  readonly __typename?: 'ContainerConnection';
  readonly edges?: Maybe<ReadonlyArray<Maybe<ContainerGraphQlEdge>>>;
  readonly pageInfo?: Maybe<PageInfo>;
  readonly totalCount?: Maybe<Scalars['Int']>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<TrucksUserError>>>;
};

export type ContainerFilter = {
  readonly carrierId: Scalars['ID'];
  readonly containerProgramId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly containerTypeId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly containerNumber?: InputMaybe<Scalars['String']>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly destinationRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly endDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** A partial or full city name */
  readonly originCity?: InputMaybe<Scalars['String']>;
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly originRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly reservationNumber?: InputMaybe<Scalars['String']>;
  readonly reservationTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly sourceTypeId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly startDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly operatingStatusIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly serviceStatusIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trailerId?: InputMaybe<Scalars['ID']>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  readonly capacityUtilizationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type ContainerGraphQl = {
  readonly __typename?: 'ContainerGraphQL';
  /** The utilization status of the capacity. Correlates to DDT id "capacity_utilization_status" */
  readonly capacityUtilizationStatusId: Scalars['String'];
  readonly carrierId: Scalars['ID'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId: Scalars['ID'];
  readonly containerNumber?: Maybe<Scalars['String']>;
  readonly containerTypeId: Scalars['ID'];
  readonly containerLength: UnitOfDistance;
  readonly containerProgramId?: Maybe<Scalars['String']>;
  readonly destinationRampFacilityId?: Maybe<Scalars['ID']>;
  readonly deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly endDatetimeUtc?: Maybe<Scalars['ISO8601DateTime']>;
  readonly endTimezone?: Maybe<Scalars['String']>;
  /** Identifier from external sources such as DAT, TruckerTools, etc. Not to be confused with the creation source (i.e. sourceTypeId) */
  readonly externalSourceId?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly operatingStatusId?: Maybe<Scalars['String']>;
  /** @deprecated DO NOT USE. Field is no longer supported. */
  readonly originDeadhead: UnitOfDistance;
  readonly originLocation: CapacityLocation;
  readonly originRampFacilityId?: Maybe<Scalars['ID']>;
  readonly previousDraymanId?: Maybe<Scalars['ID']>;
  readonly previousRailroadId?: Maybe<Scalars['ID']>;
  readonly previousRouteId?: Maybe<Scalars['ID']>;
  readonly reservationNumber?: Maybe<Scalars['String']>;
  readonly reservationTypeId?: Maybe<Scalars['ID']>;
  readonly serviceStatusId?: Maybe<Scalars['String']>;
  readonly startDatetimeUtc: Scalars['ISO8601DateTime'];
  readonly startTimezone: Scalars['String'];
  /** DDT ID for the source of the capacity (e.g. manual, api, autotruck, dat, reload intent, etc.) */
  readonly sourceTypeId: Scalars['String'];
  readonly tenantInternalId?: Maybe<Scalars['String']>;
  readonly trailerId?: Maybe<Scalars['ID']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUserId: Scalars['ID'];
  readonly ownerId?: Maybe<Scalars['ID']>;
  /** @deprecated Deprecated. Please use capacityUtilizationStatusId */
  readonly utilizationStatus: UtilizationStatusEnum;
  readonly originRampFacility?: Maybe<FacilityV2>;
  readonly destinationRampFacility?: Maybe<FacilityV2>;
  readonly carrier?: Maybe<CarrierV2>;
  readonly previousDrayman?: Maybe<CarrierV2>;
  readonly previousRailroad?: Maybe<CarrierV2>;
  readonly previousRoute?: Maybe<LoadRouteV2>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly assetTrailer?: Maybe<AssetTrailers>;
  readonly ownerV2?: Maybe<CarrierV2>;
  readonly containerProgram?: Maybe<KeyValueType>;
  readonly containerType?: Maybe<KeyValueType>;
  readonly reservationType?: Maybe<KeyValueType>;
  readonly capacityUtilizationStatus?: Maybe<KeyValueType>;
};

export type ContainerGraphQlEdge = {
  readonly __typename?: 'ContainerGraphQLEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<ContainerGraphQl>;
};

/** Container Match Result from ElasticSearch (v2) */
export type ContainerMatch = {
  readonly __typename?: 'ContainerMatch';
  readonly id: Scalars['ID'];
  readonly routeId: Scalars['String'];
  readonly loadId: Scalars['String'];
  readonly loadNumber: Scalars['String'];
  readonly capacityNeed?: Maybe<ReadonlyArray<CapacityNeed>>;
  readonly actedUpon?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly originDeadheadMileage?: Maybe<Scalars['Float']>;
  readonly destinationDeadheadMileage?: Maybe<Scalars['Float']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly routePickupCity?: Maybe<Scalars['String']>;
  readonly routePickupState?: Maybe<Scalars['String']>;
  readonly routePickupCountry?: Maybe<Scalars['String']>;
  readonly routePickupStartDay?: Maybe<Scalars['String']>;
  /** Date and timezone for route pickup start date */
  readonly routePickupStartDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `routePickupStartDateZone` */
  readonly routePickupStartDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `routePickupStartDateZone` and `routePickupEndDateZone` */
  readonly routePickupTimeZone?: Maybe<Scalars['String']>;
  readonly routePickupEndDay?: Maybe<Scalars['String']>;
  /** Date and timezone for route pickup end date */
  readonly routePickupEndDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `routePickupEndDateZone` */
  readonly routePickupEndDate?: Maybe<Scalars['DateTime']>;
  readonly routeFirstStopDateRange?: Maybe<AvailableDateRange>;
  readonly routeFirstStopLiveType?: Maybe<ScheduleDropTypeEnum>;
  readonly routeDeliveryCity?: Maybe<Scalars['String']>;
  readonly routeDeliveryState?: Maybe<Scalars['String']>;
  readonly routeDeliveryCountry?: Maybe<Scalars['String']>;
  readonly routeDeliveryStartDay?: Maybe<Scalars['String']>;
  /** Date and timezone for route delivery start date */
  readonly routeDeliveryStartDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `routeDeliveryStartDateZone` */
  readonly routeDeliveryStartDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `routeDeliveryStartDateZone` and `routeDeliveryEndDateZone` */
  readonly routeDeliveryTimeZone?: Maybe<Scalars['String']>;
  readonly routeDeliveryEndDay?: Maybe<Scalars['String']>;
  /** Date and timezone for route delivery end date */
  readonly routeDeliveryEndDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `routeDeliveryEndDateZone` */
  readonly routeDeliveryEndDate?: Maybe<Scalars['DateTime']>;
  readonly routeLastStopDateRange?: Maybe<AvailableDateRange>;
  readonly routeLastStopLiveType?: Maybe<ScheduleDropTypeEnum>;
  readonly routeLength?: Maybe<Scalars['Int']>;
  readonly routeTrailerLength?: Maybe<Length>;
  readonly routeCommodityMinTemperature?: Maybe<TemperatureV2>;
  readonly routeAssignedReps?: Maybe<ReadonlyArray<AvailableRouteRep>>;
  readonly routeCustomerOrderReps?: Maybe<ReadonlyArray<AvailableRouteRep>>;
  readonly stopCount?: Maybe<Scalars['Int']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly routeTrailerTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly segmentCode?: Maybe<Scalars['String']>;
  readonly routeDivisions?: Maybe<ReadonlyArray<AvailableRouteDivision>>;
  readonly requirements?: Maybe<ReadonlyArray<OrderRequirementInfo>>;
  readonly hotRoute?: Maybe<Scalars['Boolean']>;
  readonly tmsType?: Maybe<Scalars['String']>;
  readonly mainCustomerRepEmployeeId?: Maybe<Scalars['String']>;
  readonly expectedMaxWeight?: Maybe<Scalars['Float']>;
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly equipment?: Maybe<Scalars['String']>;
  readonly routeLoadRouteSequence?: Maybe<Fraction>;
  readonly routeAppointmentStatus?: Maybe<Scalars['String']>;
  readonly routeLifecycleStatus?: Maybe<Scalars['String']>;
  readonly routeOrganizationName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'routeCustomerReps' */
  readonly customerRepName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'routeCustomerReps' */
  readonly customerRepId?: Maybe<Scalars['String']>;
  /** @deprecated Use 'routeDivisions' */
  readonly division?: Maybe<Scalars['String']>;
  readonly containerId: Scalars['String'];
  readonly trailerId: Scalars['String'];
  readonly containerOriginCity?: Maybe<Scalars['String']>;
  readonly containerOriginState?: Maybe<Scalars['String']>;
  readonly containerOriginRampFacilityId?: Maybe<Scalars['String']>;
  readonly containerOriginRampFacilityName?: Maybe<Scalars['String']>;
  readonly containerDestCity?: Maybe<Scalars['String']>;
  readonly containerDestState?: Maybe<Scalars['String']>;
  readonly containerDestRampFacilityId?: Maybe<Scalars['String']>;
  readonly containerDestRampFacilityName?: Maybe<Scalars['String']>;
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly capacityUtilizationStatusId: Scalars['String'];
  readonly route?: Maybe<AvailableRoute>;
  readonly container?: Maybe<AvailableContainer>;
};

export type ContainerMatchConnection = {
  readonly __typename?: 'ContainerMatchConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<ContainerMatchEdge>;
  readonly totalCount: Scalars['Float'];
};

export type ContainerMatchEdge = {
  readonly __typename?: 'ContainerMatchEdge';
  readonly node: ContainerMatch;
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String'];
};

/** Container Match Filter */
export type ContainerMatchFilter = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly matchId?: InputMaybe<Scalars['String']>;
  readonly routeId?: InputMaybe<Scalars['String']>;
  readonly routeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadId?: InputMaybe<Scalars['String']>;
  readonly capacityNeed?: InputMaybe<ReadonlyArray<CapacityNeed>>;
  readonly excludeActedUpon?: InputMaybe<Scalars['Boolean']>;
  readonly actedUpon?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeFirstStopDateRange?: InputMaybe<DateRangeInput>;
  readonly routeLastStopDateRange?: InputMaybe<DateRangeInput>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierName?: InputMaybe<Scalars['String']>;
  readonly routeActivationStatus?: InputMaybe<Scalars['String']>;
  readonly routeActivationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly originDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  readonly destinationDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  readonly includeDeleted?: InputMaybe<Scalars['Boolean']>;
  readonly routeNumber?: InputMaybe<Scalars['String']>;
  readonly routePickupCity?: InputMaybe<Scalars['String']>;
  readonly routePickupState?: InputMaybe<Scalars['String']>;
  readonly routePickupStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routePickupCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routePickupDate?: InputMaybe<Scalars['DateTime']>;
  readonly routePickupTimeZone?: InputMaybe<Scalars['String']>;
  readonly routeDeliveryCity?: InputMaybe<Scalars['String']>;
  readonly routeDeliveryState?: InputMaybe<Scalars['String']>;
  readonly routeDeliveryStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDeliveryCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeFirstStopRadius?: InputMaybe<WithinRadiusInput>;
  readonly routeLastStopRadius?: InputMaybe<WithinRadiusInput>;
  readonly routeDeliveryDate?: InputMaybe<Scalars['DateTime']>;
  readonly routeDeliveryTimeZone?: InputMaybe<Scalars['String']>;
  readonly routeCommodityMinTemperature?: InputMaybe<TemperatureV2Input>;
  readonly routeLength?: InputMaybe<Scalars['Int']>;
  readonly stopCount?: InputMaybe<Scalars['Int']>;
  readonly maxCost?: InputMaybe<Scalars['Float']>;
  readonly routeFirstStopLiveType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLastStopLiveType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerName?: InputMaybe<Scalars['String']>;
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerRepName?: InputMaybe<Scalars['String']>;
  readonly customerRepIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equipment?: InputMaybe<Scalars['String']>;
  readonly routeTrailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns matches where the route primary or additional divisions includes the input terms */
  readonly routeDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns matches where the route primary division is null or includes the input terms */
  readonly routeDivisionsIncludeNull?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns matches where the route additional divisions includes the input terms */
  readonly additionalRouteDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns matches where the employee's primary divsion matches a route's primary or additional divisions */
  readonly primaryEmployeeDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns matches where the employee's additional divsions match a route's primary division */
  readonly additionalEmployeeDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly routeRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
  readonly routeTrailerLength?: InputMaybe<UnitOfLengthInput>;
  readonly routeAssignedRepIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeAppointmentStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLifecycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly capacityUtilizationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeOrganizationHierarchyCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly requirements?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly dateRangeFilterTarget?: InputMaybe<MatchesDateRangeFilterTarget>;
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  readonly containerId?: InputMaybe<Scalars['String']>;
  readonly containerIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly timeZone?: InputMaybe<Scalars['String']>;
  readonly containerOriginCity?: InputMaybe<Scalars['String']>;
  readonly containerOriginStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly containerOriginRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly containerOriginRampFacilityName?: InputMaybe<Scalars['String']>;
  readonly containerDestCity?: InputMaybe<Scalars['String']>;
  readonly containerDestStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly containerDestRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly containerDestRampFacilityName?: InputMaybe<Scalars['String']>;
};

export type ContainerPayloadWithErrorsAndContainer = {
  readonly __typename?: 'ContainerPayloadWithErrorsAndContainer';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly container?: Maybe<ContainerGraphQl>;
};

export type ContainerPayloadWithErrorsAndContainers = {
  readonly __typename?: 'ContainerPayloadWithErrorsAndContainers';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly containers?: Maybe<ReadonlyArray<ContainerGraphQl>>;
};

export type ContainerSort = {
  readonly key: ContainerSortKeysEnum;
  readonly direction: TruckEntrySortDirectionsEnum;
};

export enum ContainerSortKeysEnum {
  ContainerNumber = 'containerNumber',
  ReservationNumber = 'reservationNumber',
  StartDatetimeUtc = 'startDatetimeUtc',
  EndDatetimeUtc = 'endDatetimeUtc',
  SourceTypeId = 'sourceTypeId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ContainerProgramId = 'containerProgramId',
  ContainerLength = 'containerLength',
  ReservationTypeId = 'reservationTypeId',
  OriginCity = 'originCity',
  OriginState = 'originState',
  OriginDeadhead = 'originDeadhead',
  CapacityUtilizationStatus = 'capacityUtilizationStatus'
}

export type ContentTemplate = {
  readonly __typename?: 'ContentTemplate';
  readonly templateId?: Maybe<Scalars['String']>;
  readonly eventId?: Maybe<Scalars['String']>;
  readonly eventName?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly renderType?: Maybe<ContentTemplateRenderType>;
  readonly contentType: ContentTemplateContentType;
  readonly components: ReadonlyArray<ContentTemplateComponentsItem>;
  readonly sampleData?: Maybe<Scalars['String']>;
  readonly renderedTemplate?: Maybe<RenderedTemplate>;
};

export type ContentTemplateComponentsItem = {
  readonly __typename?: 'ContentTemplateComponentsItem';
  readonly name: ContentTemplateComponentsItemName;
  readonly value: Scalars['String'];
  readonly schema: TemplateSchema;
};

export type ContentTemplateComponentsItemInput = {
  readonly name: ContentTemplateComponentsItemName;
  readonly value: Scalars['String'];
  readonly schema: TemplateSchemaInput;
};

export enum ContentTemplateComponentsItemName {
  Subject = 'Subject',
  Body = 'Body'
}

export enum ContentTemplateContentType {
  Email = 'Email',
  MasterSign = 'MasterSign'
}

export type ContentTemplateInput = {
  readonly templateId?: InputMaybe<Scalars['String']>;
  readonly eventId?: InputMaybe<Scalars['String']>;
  readonly eventName?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly renderType?: InputMaybe<ContentTemplateRenderType>;
  readonly contentType: ContentTemplateContentType;
  readonly components: ReadonlyArray<ContentTemplateComponentsItemInput>;
  readonly sampleData?: InputMaybe<Scalars['String']>;
};

export enum ContentTemplateRenderType {
  Json = 'JSON',
  Handlebars = 'Handlebars',
  Nunjucks = 'Nunjucks'
}

export type ConversationInfo = {
  readonly classification: Scalars['String'];
  readonly value?: InputMaybe<Scalars['String']>;
};

export type ConvertSpotQuoteToLoadDetail = {
  readonly __typename?: 'ConvertSpotQuoteToLoadDetail';
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly loadCode?: Maybe<Scalars['String']>;
};

export type ConvertSpotQuoteToLoadInput = {
  readonly spotQuoteId: Scalars['ID'];
};

export type ConvertSpotQuoteToLoadPayload = {
  readonly __typename?: 'ConvertSpotQuoteToLoadPayload';
  readonly data?: Maybe<ConvertSpotQuoteToLoadDetail>;
  readonly errors?: Maybe<ReadonlyArray<SpotQuoteError>>;
};

export type CoordinateInput = {
  readonly lat: Scalars['Float'];
  readonly lon: Scalars['Float'];
};

/** Input for checking if a geopoint is in range of a facility's geofence. */
export type CoordinateIsInRangeOfGeofenceInput = {
  /** Current geopoint location. */
  readonly currentLocation: GeoPointInput;
  /** Customer record ID to check if geofencing is enabled. */
  readonly customerId?: InputMaybe<Scalars['String']>;
  /** Facility record ID to check if geofencing is enabled. */
  readonly facilityId: Scalars['String'];
  /** Current facility transit status (arriving, departing). */
  readonly facilityTransitStatus: FacilityTransitStatus;
  /** Location (sublocation of a facility) record ID to check if geofencing is enabled. */
  readonly locationId: Scalars['String'];
  /** Tenant ID of the environment we are in (mm100-dev, mm100-test, etc.). */
  readonly tenantId: Scalars['String'];
  /** Whether or not to look up facility's geopoint if undefined in geofences. */
  readonly useLocationGeopointFallback?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not fallback to minion for geofence defaults (radius). */
  readonly useMinionFallback?: InputMaybe<Scalars['Boolean']>;
};

/** Response of whether or not a coordinate is in range of a facility's geofence */
export type CoordinateIsInRangeOfGeofenceResponse = {
  readonly __typename?: 'CoordinateIsInRangeOfGeofenceResponse';
  readonly distanceInKm?: Maybe<Scalars['Float']>;
  readonly isInRange?: Maybe<Scalars['Boolean']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
};

export type CoordinatesOutput = {
  readonly __typename?: 'CoordinatesOutput';
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type CoordinateStop = {
  readonly __typename?: 'CoordinateStop';
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type CopyFromGeonamesRequest = {
  readonly sourceId: Scalars['Int'];
  readonly geographyType: GeographyServiceTypeEnum;
};

export type CopyFromGeonamesRequestNameState = {
  readonly countryCode: Scalars['String'];
  readonly locality: Scalars['String'];
  readonly adminArea: Scalars['String'];
  readonly geographyType: GeographyServiceTypeEnum;
};

/** The Cost Detail */
export type CostDetail = {
  readonly __typename?: 'CostDetail';
  readonly id?: Maybe<Scalars['ID']>;
  readonly routeId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
  readonly unitsDecimal?: Maybe<Scalars['Decimal']>;
  readonly costPer?: Maybe<Scalars['Decimal']>;
  readonly chargeTypeId?: Maybe<Scalars['ID']>;
  readonly totalCost?: Maybe<Scalars['Decimal']>;
  /** @deprecated Use total cost. */
  readonly signedTotalCost?: Maybe<Scalars['Decimal']>;
  /** @deprecated Use cost per. */
  readonly signedCostPer?: Maybe<Scalars['Decimal']>;
  readonly statusId: StatusEnum;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<RouteLifeCycleStatusEnum>;
  readonly vendorRouteFinancialStatus?: Maybe<VendorFinancialStatusEnum>;
  readonly routeVendorType?: Maybe<RouteVendorTypeEnum>;
  readonly isAdjustment: Scalars['Boolean'];
  readonly enteredCostPer?: Maybe<Scalars['Decimal']>;
  readonly enteredTotalCost?: Maybe<Scalars['Decimal']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: Maybe<Scalars['Date']>;
  readonly currencyDiscountAmount?: Maybe<Scalars['Decimal']>;
  /** @deprecated No longer necessary with negatives in cost per. */
  readonly isCredit?: Maybe<Scalars['Boolean']>;
  readonly advanceEid?: Maybe<Scalars['Long']>;
  readonly description: Scalars['String'];
  readonly vendorGlCode?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly segment1?: Maybe<Scalars['String']>;
  readonly segment2?: Maybe<Scalars['String']>;
  readonly segment3?: Maybe<Scalars['String']>;
  readonly segment4?: Maybe<Scalars['String']>;
  readonly segment5?: Maybe<Scalars['String']>;
  readonly segment6?: Maybe<Scalars['String']>;
  readonly segment7?: Maybe<Scalars['String']>;
  readonly segment8?: Maybe<Scalars['String']>;
  readonly segment9?: Maybe<Scalars['String']>;
  readonly segment10?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly voucherHeader?: Maybe<VoucherHeader>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly loadV2?: Maybe<LoadV2>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdById?: Maybe<Scalars['String']>;
  readonly updatedById?: Maybe<Scalars['String']>;
  readonly glAccountCode?: Maybe<Scalars['String']>;
  readonly allowRerating?: Maybe<Scalars['Boolean']>;
  readonly stopId?: Maybe<Scalars['ID']>;
  readonly isBlumeCharge?: Maybe<Scalars['Boolean']>;
  readonly blumeInvoiceNumber?: Maybe<Scalars['String']>;
  readonly voucherId?: Maybe<Scalars['ID']>;
  readonly payProgramId?: Maybe<Scalars['ID']>;
  readonly payRateTableId?: Maybe<Scalars['ID']>;
  readonly driverId?: Maybe<Scalars['ID']>;
  readonly driverVoucherId?: Maybe<Scalars['ID']>;
  readonly approvalStatus?: Maybe<Scalars['String']>;
  readonly approvedBy?: Maybe<Scalars['String']>;
  readonly approvedOn?: Maybe<Scalars['DateTime']>;
  readonly preTax: Scalars['Boolean'];
  readonly DriverPayProgram?: Maybe<DriverPayProgram>;
};

/** The input type for new Cost Detail. */
export type CostDetailAddInput = {
  readonly routeId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
  readonly unitsDecimal?: InputMaybe<Scalars['Decimal']>;
  /** Deprecated - Use EnteredCostPer - This will be recalculated as ExchangeRate * EnteredCostPer. */
  readonly costPer?: InputMaybe<Scalars['Decimal']>;
  readonly chargeTypeId?: InputMaybe<Scalars['ID']>;
  readonly routeVendorType?: InputMaybe<RouteVendorTypeEnum>;
  readonly enteredCostPer?: InputMaybe<Scalars['Decimal']>;
  readonly currency?: InputMaybe<Scalars['String']>;
  /** Deprecated - Do not use, this will be ignored and it will get set automatically from Currency. */
  readonly exchangeRate?: InputMaybe<Scalars['Decimal']>;
  /** Deprecated - Do not use, this will be ignored and it will get set automatically from Load Created Date. */
  readonly exchangeRateDate?: InputMaybe<Scalars['Date']>;
  readonly stopId?: InputMaybe<Scalars['ID']>;
  readonly blumeInvoiceNumber?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly payProgramId?: InputMaybe<Scalars['ID']>;
  readonly payRateTableId?: InputMaybe<Scalars['ID']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  /** Deprecated - Do not use, this will be ignored and should only be set by accounting-voucher service. */
  readonly driverVoucherId?: InputMaybe<Scalars['ID']>;
  readonly approvalStatus?: InputMaybe<Scalars['String']>;
  readonly approvedBy?: InputMaybe<Scalars['String']>;
  readonly approvedOn?: InputMaybe<Scalars['DateTime']>;
  readonly isUserInitiated?: InputMaybe<Scalars['Boolean']>;
};

export type CostDetailChargeTypeConfigurationResponse = {
  readonly __typename?: 'CostDetailChargeTypeConfigurationResponse';
  readonly carrierId?: Maybe<Scalars['Guid']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: Maybe<Scalars['String']>;
  readonly cost?: Maybe<Scalars['Decimal']>;
  readonly customerId?: Maybe<Scalars['Guid']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly isCredit: Scalars['Boolean'];
  readonly isDefault: Scalars['Boolean'];
  readonly maximumTotalCost?: Maybe<Scalars['Decimal']>;
  readonly minimumTotalCost?: Maybe<Scalars['Decimal']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly perUnit?: Maybe<CtcPerUnitTypeEnum>;
  readonly size?: Maybe<Scalars['String']>;
  readonly carrierApprovalRequired: Scalars['Boolean'];
  readonly isPreTax?: Maybe<Scalars['Boolean']>;
  readonly percentOfChargeTypeIdsList?: Maybe<ReadonlyArray<Maybe<Scalars['Guid']>>>;
  readonly isTieredCostPerConfiguration?: Maybe<Scalars['Boolean']>;
  readonly tieredCostSchedules?: Maybe<ReadonlyArray<Maybe<CostDetailChargeTypeConfigurationTieredSchedule>>>;
};

export type CostDetailChargeTypeConfigurationTieredSchedule = {
  readonly __typename?: 'CostDetailChargeTypeConfigurationTieredSchedule';
  readonly id?: Maybe<Scalars['ID']>;
  readonly beginRange?: Maybe<Scalars['Decimal']>;
  readonly endrange?: Maybe<Scalars['Decimal']>;
  readonly costPer?: Maybe<Scalars['Decimal']>;
};

/** Represents the cost detail object when processing a Voucher. */
export type CostDetailProcessingInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type CostDetailsFilter = {
  readonly loadId: Scalars['ID'];
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly includeAdvanceCostDetails?: InputMaybe<Scalars['Boolean']>;
  readonly applyCreditChargeTypeModifier?: InputMaybe<Scalars['Boolean']>;
};

/** The input type for updating Cost Detail. */
export type CostDetailUpdateInput = {
  readonly routeId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
  readonly unitsDecimal?: InputMaybe<Scalars['Decimal']>;
  /** Deprecated - Use EnteredCostPer - This will be recalculated as ExchangeRate * EnteredCostPer. */
  readonly costPer?: InputMaybe<Scalars['Decimal']>;
  readonly chargeTypeId?: InputMaybe<Scalars['ID']>;
  readonly routeVendorType?: InputMaybe<RouteVendorTypeEnum>;
  readonly enteredCostPer?: InputMaybe<Scalars['Decimal']>;
  readonly currency?: InputMaybe<Scalars['String']>;
  /** Deprecated - Do not use, this will be ignored and it will get set automatically from Currency. */
  readonly exchangeRate?: InputMaybe<Scalars['Decimal']>;
  /** Deprecated - Do not use, this will be ignored and it will get set automatically from Load Created Date. */
  readonly exchangeRateDate?: InputMaybe<Scalars['Date']>;
  readonly stopId?: InputMaybe<Scalars['ID']>;
  readonly blumeInvoiceNumber?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly payProgramId?: InputMaybe<Scalars['ID']>;
  readonly payRateTableId?: InputMaybe<Scalars['ID']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  /** Deprecated - Do not use, this will be ignored and should only be set by accounting-voucher service. */
  readonly driverVoucherId?: InputMaybe<Scalars['ID']>;
  readonly approvalStatus?: InputMaybe<Scalars['String']>;
  readonly approvedBy?: InputMaybe<Scalars['String']>;
  readonly approvedOn?: InputMaybe<Scalars['DateTime']>;
  readonly isUserInitiated?: InputMaybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
};

export enum CostLineEventType {
  Insert = 'Insert',
  Update = 'Update',
  Delete = 'Delete'
}

/** ⚠️ **Deprecated! Use CostLineItemInputV2** ⚠️ */
export type CostLineItemInput = {
  /** The Charge Type of this Line Item -- *Requires 'Charge Type' DDT* */
  readonly costTypeTerm?: InputMaybe<Scalars['String']>;
  /** The cost of each individual Item or Unit in this Line Item */
  readonly costPerUnit?: InputMaybe<MoneyInput>;
  /** The number of Items or Units in this Line Item */
  readonly units?: InputMaybe<Scalars['Int']>;
};

/** Input for creating Cost Line Items in Booking Orchestration */
export type CostLineItemInputV2 = {
  /** The Charge Type of this Line Item -- *Requires 'Charge Type' DDT* */
  readonly costTypeTerm: Scalars['ID'];
  /** The cost of each individual Item or Unit in this Line Item */
  readonly costPerUnit: MoneyInputV2;
  /** The number of Items or Units in this Line Item */
  readonly units: Scalars['Decimal'];
};

export type CostQuoteConnection = {
  readonly __typename?: 'CostQuoteConnection';
  readonly edges: ReadonlyArray<Maybe<CostQuoteEdge>>;
  readonly totalCount: Scalars['Int'];
  readonly pageInfo: PageInfo;
};

export enum CostQuoteDistanceUnit {
  Feet = 'Feet',
  Inches = 'Inches',
  Kilometers = 'Kilometers',
  Meters = 'Meters',
  Miles = 'Miles'
}

export type CostQuoteEdge = {
  readonly __typename?: 'CostQuoteEdge';
  readonly cursor: Scalars['String'];
  readonly node: CarrierQuoteResponse;
};

export type CostQuoteError = {
  readonly __typename?: 'CostQuoteError';
  readonly message: Scalars['String'];
  readonly code: Scalars['String'];
};

export type CostQuoteGeographyRadiusSearchInput = {
  readonly distance?: InputMaybe<CostQuoteUnitOfDistanceInput>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
};

export enum CostQuoteGeographyTypeEnum {
  City = 'City',
  Country = 'Country',
  Facility = 'Facility',
  State = 'State'
}

export type CostQuoteLaneStopsInput = {
  readonly geographyId?: InputMaybe<Scalars['String']>;
  readonly geographyType?: InputMaybe<CostQuoteGeographyTypeEnum>;
  readonly stopType?: InputMaybe<StopTypeEnum>;
  readonly sequence?: InputMaybe<Scalars['Int']>;
};

export type CostQuoteLaneStopsInputV2 = {
  readonly geographyId?: InputMaybe<Scalars['String']>;
  readonly geographyType?: InputMaybe<Scalars['String']>;
  readonly stopType?: InputMaybe<Scalars['String']>;
  readonly sequence?: InputMaybe<Scalars['Int']>;
  readonly postalCodeCountry?: InputMaybe<Scalars['String']>;
};

export type CostQuoteLineItem = {
  readonly __typename?: 'CostQuoteLineItem';
  readonly id?: Maybe<Scalars['ID']>;
  readonly chargeType?: Maybe<Scalars['String']>;
  readonly chargeTypeId?: Maybe<Scalars['ID']>;
  readonly quoteId?: Maybe<Scalars['ID']>;
  readonly unit?: Maybe<Scalars['Decimal']>;
  readonly exchangeCurrency?: Maybe<CurrencyValue>;
  readonly exchangeCurrencyV2?: Maybe<CurrencyOutputValue>;
  readonly rateCurrency?: Maybe<CurrencyValue>;
  readonly rateCurrencyV2?: Maybe<CurrencyOutputValue>;
  readonly homeCurrency?: Maybe<CurrencyValue>;
  readonly homeCurrencyV2?: Maybe<CurrencyOutputValue>;
  readonly totalRateCurrency?: Maybe<CurrencyValue>;
  readonly totalRateCurrencyV2?: Maybe<CurrencyOutputValue>;
};

export type CostQuoteLineItemInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly chargeType?: InputMaybe<Scalars['String']>;
  readonly unit?: InputMaybe<Scalars['Decimal']>;
  readonly exchangeCurrency?: InputMaybe<CurrencyInputType>;
  readonly rateCurrency?: InputMaybe<CurrencyInputType>;
  readonly homeCurrency?: InputMaybe<CurrencyInputType>;
  readonly totalRateCurrency?: InputMaybe<CurrencyInputType>;
  readonly isDelete?: InputMaybe<Scalars['Boolean']>;
};

export type CostQuotePaginatedFilter = {
  readonly costQuoteType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly effectiveDateTime?: InputMaybe<Scalars['DateTimeOffset']>;
  readonly minimum?: InputMaybe<Scalars['Decimal']>;
  readonly expirationDateTime?: InputMaybe<Scalars['DateTimeOffset']>;
  readonly loadSize?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly scac?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly multipleStops?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Boolean']>>>;
  readonly quotedById?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly minHeight?: InputMaybe<CostQuoteUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<CostQuoteUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<CostQuoteUnitOfDistanceInput>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly services?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly transportModeId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly team?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Boolean']>>>;
  readonly destinationGeographyType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly destinationStopTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly destinationGeographySearch?: InputMaybe<ReadonlyArray<InputMaybe<CostQuoteGeographyRadiusSearchInput>>>;
  readonly originGeographyType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly originStopTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly originGeographySearch?: InputMaybe<ReadonlyArray<InputMaybe<CostQuoteGeographyRadiusSearchInput>>>;
  readonly excludeExpired?: InputMaybe<Scalars['Boolean']>;
  readonly laneIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly laneType?: InputMaybe<Scalars['String']>;
  readonly distanceType?: InputMaybe<Scalars['Boolean']>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type CostQuoteRouteVendorReferenceInput = {
  readonly routeRefType: Scalars['String'];
  readonly refNumber: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isDelete?: InputMaybe<Scalars['Boolean']>;
};

export type CostQuoteRouteVendorReferences = {
  readonly __typename?: 'CostQuoteRouteVendorReferences';
  readonly id?: Maybe<Scalars['ID']>;
  readonly costQuoteId: Scalars['ID'];
  readonly routeRefType?: Maybe<TenantConfiguration>;
  readonly refNumber?: Maybe<Scalars['String']>;
};

export enum CostQuoteUnitOfCurrency {
  Usd = 'USD'
}

export type CostQuoteUnitOfDistanceInput = {
  readonly unit?: InputMaybe<CostQuoteDistanceUnit>;
  readonly value?: InputMaybe<Scalars['Decimal']>;
};

export type CounterOfferResponseInput = {
  readonly offerId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly counterPrice?: InputMaybe<CurrencyInput>;
  readonly counterPriceV2?: InputMaybe<CurrencyInputV2>;
  readonly offersDriver?: InputMaybe<OffersDriverInput>;
  readonly operationSource?: InputMaybe<OfferOperationSource>;
  /** Will be overwritten with OfferResponseType.COUNTER */
  readonly type?: InputMaybe<OfferResponseType>;
};

export type CountriesV2 = {
  readonly __typename?: 'countriesV2';
  readonly countriesList?: Maybe<ReadonlyArray<Maybe<CountryType>>>;
};

export enum Country {
  Usa = 'USA',
  Mex = 'MEX',
  Can = 'CAN'
}

export type CountryDetails = {
  readonly __typename?: 'CountryDetails';
  readonly id: Scalars['ID'];
  readonly iso?: Maybe<Scalars['String']>;
  readonly iso3?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
};

export type CountryType = {
  readonly __typename?: 'CountryType';
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly countryName?: Maybe<Scalars['String']>;
};

export type Create_Crm_Interaction_Entity_Lookup_Input = {
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Entity_Name?: InputMaybe<Scalars['String']>;
  readonly Entity_Type?: InputMaybe<Scalars['String']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
};

export type Create_Crm_Interaction_Entity_Lookup_Output = {
  readonly __typename?: 'Create_CRM_Interaction_Entity_Lookup_Output';
  readonly Interaction_Entity_Lookup_Id: Scalars['ID'];
  readonly Entity_Type?: Maybe<Scalars['String']>;
  readonly Entity_Name?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CreateAddressInput = {
  readonly addressTypeId: Scalars['ID'];
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly entity: EntityInput;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isVerified?: InputMaybe<Scalars['Boolean']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly postalCode: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CreateAdjustmentItemsChecked = {
  readonly __typename?: 'CreateAdjustmentItemsChecked';
  readonly createAdjustmentItemsCheckedOption?: Maybe<CreateAdjustmentItemsCheckedOptionEnum>;
};

export enum CreateAdjustmentItemsCheckedOptionEnum {
  Unchecked = 'UNCHECKED',
  Checked = 'CHECKED'
}

export type CreateAndProcessDriverVouchersInput = {
  readonly cutoffDate: Scalars['DateTime'];
  readonly driverIds: ReadonlyArray<Scalars['ID']>;
  readonly createdByTimezone?: InputMaybe<Scalars['String']>;
};

export type CreateAndProcessDriverVouchersOutput = {
  readonly __typename?: 'CreateAndProcessDriverVouchersOutput';
  readonly driverVoucherHeaders: ReadonlyArray<DriverVoucherHeaderType>;
};

export type CreateAssetDriverTenderInput = {
  readonly driverId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly driverCapacityId: Scalars['ID'];
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly driver2Id?: InputMaybe<Scalars['ID']>;
};

export type CreateAssetPermanentDriverInput = {
  readonly permanentDriverId?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly permanentDriverIdV1?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetPermanentTractorInput = {
  readonly permanentTractorId?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly permanentTractorIdV1?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetPermanentTrailerInput = {
  readonly permanentTrailerId?: InputMaybe<Scalars['String']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly permanentTrailerIdV1?: InputMaybe<Scalars['String']>;
};

export type CreateAssetPowerEquipmentInput = {
  readonly equipmentCd?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly count?: InputMaybe<Scalars['Int']>;
  readonly assetId?: InputMaybe<Scalars['String']>;
  readonly condition?: InputMaybe<Scalars['String']>;
  readonly conditionTerm?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly issueDate?: InputMaybe<Scalars['DateTime']>;
  readonly returnDate?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetPowerEquipmentInputV2 = {
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId?: InputMaybe<Scalars['String']>;
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly returnDate?: InputMaybe<Scalars['Date']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type CreateAssetPowerIdentifierInput = {
  readonly identifierType?: InputMaybe<Scalars['String']>;
  readonly identifierNumber?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly countryCd?: InputMaybe<Scalars['String']>;
  readonly expirationDt?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly expirationDtV1?: InputMaybe<Scalars['String']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetPowerIdentifierInputV2 = {
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetPowerIdentifierInputV3 = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly typeTerm: Scalars['String'];
  readonly value: Scalars['String'];
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type CreateAssetPowerInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly displayName?: InputMaybe<Scalars['String']>;
  readonly make?: InputMaybe<Scalars['String']>;
  readonly makeTerm?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly year?: InputMaybe<Scalars['Int']>;
  readonly yearV2?: InputMaybe<Scalars['Int']>;
  readonly model?: InputMaybe<Scalars['String']>;
  readonly modelTerm?: InputMaybe<Scalars['String']>;
  /** DEPRECATED: legalToRun is deprecated. Use legalToRunTerm instead. */
  readonly legalToRun?: InputMaybe<ReadonlyArray<CreateAssetPowerLegalToRunInput>>;
  /** This field describes the list of countries details where the trailer is legal to run(Ex. Dry Van , CAN) */
  readonly legalToRunTerm?: InputMaybe<ReadonlyArray<CreateAssetPowerLegalToRunInput>>;
  readonly equipmentDetails?: InputMaybe<ReadonlyArray<CreateAssetPowerEquipmentInput>>;
  readonly identifiers?: InputMaybe<ReadonlyArray<CreateAssetPowerIdentifierInputV3>>;
  readonly identifiersV2?: InputMaybe<ReadonlyArray<CreateAssetPowerIdentifierInputV2>>;
  /** DEPRECATED: permanentDriver is deprecated. Use permanentDrivers instead. */
  readonly permanentDriver?: InputMaybe<ReadonlyArray<CreateAssetPowerPermanentDriverInput>>;
  /** A collection of permanent driver associated with the power. */
  readonly permanentDrivers?: InputMaybe<ReadonlyArray<CreateAssetPowerPermanentDriverInput>>;
  /** DEPRECATED: permanentTrailer is deprecated. Use permanentTrailers instead. */
  readonly permanentTrailer?: InputMaybe<ReadonlyArray<CreateAssetPermanentTrailerInput>>;
  /** A collection of permanent trailer associated with the power. */
  readonly permanentTrailers?: InputMaybe<ReadonlyArray<CreateAssetPermanentTrailerInput>>;
  readonly maintenance?: InputMaybe<ReadonlyArray<CreateAssetPowerMaintenanceInputV2>>;
  readonly isCARBCompliant?: InputMaybe<Scalars['Boolean']>;
  readonly trackingDevice?: InputMaybe<TrackingDeviceInput>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly powerDevice?: InputMaybe<Scalars['String']>;
  readonly trackingDeviceTerm?: InputMaybe<Scalars['String']>;
  readonly powerModel?: InputMaybe<Scalars['String']>;
  readonly trackingModelTerm?: InputMaybe<Scalars['String']>;
  readonly serialNumber?: InputMaybe<Scalars['String']>;
  readonly powerNumber?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly owner?: InputMaybe<Scalars['String']>;
  readonly ownerTerm?: InputMaybe<Scalars['String']>;
  readonly class?: InputMaybe<Scalars['String']>;
  readonly classTerm?: InputMaybe<Scalars['String']>;
  readonly fleetName?: InputMaybe<Scalars['String']>;
  readonly segment?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly odomoter?: InputMaybe<Scalars['Float']>;
  readonly odometerMiles?: InputMaybe<Scalars['Float']>;
  readonly exteriorColor?: InputMaybe<Scalars['String']>;
  readonly interiorColor?: InputMaybe<Scalars['String']>;
  readonly sleeperType?: InputMaybe<Scalars['String']>;
  readonly sleeperTypeTerm?: InputMaybe<Scalars['String']>;
  readonly axleConfiguration?: InputMaybe<Scalars['String']>;
  readonly axleConfigurationTerm?: InputMaybe<Scalars['String']>;
  readonly suspension?: InputMaybe<Scalars['String']>;
  readonly suspensionTerm?: InputMaybe<Scalars['String']>;
  readonly sleeperSize?: InputMaybe<Scalars['Float']>;
  readonly sleeperSizeTerm?: InputMaybe<Scalars['Float']>;
  readonly sleeperSizeDimensionsTerm?: InputMaybe<Scalars['String']>;
  readonly weight?: InputMaybe<Scalars['String']>;
  readonly powerWeight?: InputMaybe<UnitOfWeightInputV2>;
  readonly engineMake?: InputMaybe<Scalars['String']>;
  readonly engineMakeTerm?: InputMaybe<Scalars['String']>;
  readonly engineModel?: InputMaybe<Scalars['String']>;
  readonly engineModelTerm?: InputMaybe<Scalars['String']>;
  readonly engineHP?: InputMaybe<Scalars['Float']>;
  readonly engineHPV2?: InputMaybe<Scalars['String']>;
  readonly powerEngineHP?: InputMaybe<Scalars['String']>;
  readonly engineBrake?: InputMaybe<Scalars['String']>;
  readonly engineBrakeTerm?: InputMaybe<Scalars['String']>;
  readonly isEngineBrakeAvailable?: InputMaybe<Scalars['Boolean']>;
  readonly transmissionMake?: InputMaybe<Scalars['String']>;
  readonly transmissionMakeTerm?: InputMaybe<Scalars['String']>;
  readonly transmissionModel?: InputMaybe<Scalars['String']>;
  readonly transmissionModelTerm?: InputMaybe<Scalars['String']>;
  readonly transmissionSpeed?: InputMaybe<Scalars['Float']>;
  readonly transmissionSpeedV2?: InputMaybe<Scalars['Int']>;
  readonly powerTransmissionSpeed?: InputMaybe<Scalars['Int']>;
  readonly readEndRatio?: InputMaybe<Scalars['Float']>;
  readonly rearEndRatio?: InputMaybe<Scalars['Float']>;
  readonly wheelBase?: InputMaybe<Scalars['Float']>;
  readonly wheelBaseV2?: InputMaybe<UnitOfLengthInputV4>;
  readonly powerWheelBase?: InputMaybe<UnitOfLengthInputV4>;
  readonly tankCapacity1?: InputMaybe<Scalars['Float']>;
  readonly tankCapacity1V2?: InputMaybe<UnitOfCapacityInput>;
  readonly powerTankCapacity1?: InputMaybe<UnitOfCapacityInput>;
  readonly tankCapacity2?: InputMaybe<Scalars['Float']>;
  readonly tankCapacity2V2?: InputMaybe<UnitOfCapacityInput>;
  readonly powerTankCapacity2?: InputMaybe<UnitOfCapacityInput>;
  readonly wheel5?: InputMaybe<Scalars['String']>;
  readonly fifthWheel?: InputMaybe<Scalars['String']>;
  readonly fifthWheelTerm?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly operatingStatus?: InputMaybe<Scalars['String']>;
  readonly operatingStatusTerm?: InputMaybe<Scalars['String']>;
  readonly serviceStatus?: InputMaybe<Scalars['String']>;
  readonly serviceStatusTerm?: InputMaybe<Scalars['String']>;
  readonly pool?: InputMaybe<Scalars['String']>;
  readonly poolCarrierCode?: InputMaybe<Scalars['String']>;
  readonly lastPingLocation?: InputMaybe<Scalars['String']>;
  readonly lastPingDateTm?: InputMaybe<Scalars['String']>;
  readonly lastPingDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly lastFacility?: InputMaybe<Scalars['String']>;
  readonly facilityLocation?: InputMaybe<Scalars['String']>;
  readonly programTerm?: InputMaybe<Scalars['String']>;
  readonly program?: InputMaybe<Scalars['String']>;
  readonly projectTerm?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly measurementTerm?: InputMaybe<Scalars['String']>;
  readonly odometerUOMTerm?: InputMaybe<Scalars['String']>;
  readonly weightUOMTerm?: InputMaybe<Scalars['String']>;
  readonly wheelBaseUOMTerm?: InputMaybe<Scalars['String']>;
  readonly tankCapacity1UOMTerm?: InputMaybe<Scalars['String']>;
  readonly tankCapacity2UOMTerm?: InputMaybe<Scalars['String']>;
  readonly equipment?: InputMaybe<ReadonlyArray<CreateAssetPowerEquipmentInputV2>>;
  readonly identifiersV3?: InputMaybe<ReadonlyArray<CreateAssetPowerIdentifierInputV3>>;
  readonly maintenanceV3?: InputMaybe<ReadonlyArray<CreateAssetPowerMaintenanceInputV2>>;
  readonly permanentTrailerCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentDriverCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A collection of Equipments associated with the power. */
  readonly assetPowerEquipment?: InputMaybe<ReadonlyArray<PowerEquipmentObjectInput>>;
  /** A collection of identifiers associated with the power. */
  readonly assetPowerIdentifiers?: InputMaybe<ReadonlyArray<PowerIdentifierObjectInput>>;
  /** A collection of Maintenance associated with the power. */
  readonly assetPowerMaintenance?: InputMaybe<ReadonlyArray<PowerMaintenanceObjectInput>>;
  /** A collection of Fleets associated with the power. */
  readonly assetPowerFleets?: InputMaybe<ReadonlyArray<PowerFleetRelationInput>>;
};

export type CreateAssetPowerInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly code: Scalars['String'];
  readonly displayName?: InputMaybe<Scalars['String']>;
  readonly makeTerm?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly year?: InputMaybe<Scalars['Int']>;
  readonly modelTerm?: InputMaybe<Scalars['String']>;
  readonly isCARBCompliant?: InputMaybe<Scalars['Boolean']>;
  readonly trackingDevice?: InputMaybe<TrackingDeviceInput>;
  readonly classTerm?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly odometerMiles?: InputMaybe<Scalars['Float']>;
  readonly exteriorColor?: InputMaybe<Scalars['String']>;
  readonly interiorColor?: InputMaybe<Scalars['String']>;
  readonly sleeperTypeTerm?: InputMaybe<Scalars['String']>;
  readonly axleConfigurationTerm?: InputMaybe<Scalars['String']>;
  readonly suspensionTerm?: InputMaybe<Scalars['String']>;
  readonly sleeperSizeDimensionsTerm?: InputMaybe<Scalars['String']>;
  readonly powerWeight?: InputMaybe<UnitOfWeightInputV2>;
  readonly engineMakeTerm?: InputMaybe<Scalars['String']>;
  readonly engineModelTerm?: InputMaybe<Scalars['String']>;
  readonly engineHP?: InputMaybe<Scalars['String']>;
  readonly isEngineBrakeAvailable?: InputMaybe<Scalars['Boolean']>;
  readonly transmissionMakeTerm?: InputMaybe<Scalars['String']>;
  readonly transmissionModelTerm?: InputMaybe<Scalars['String']>;
  readonly transmissionSpeed?: InputMaybe<Scalars['Int']>;
  readonly rearEndRatio?: InputMaybe<Scalars['Float']>;
  readonly wheelBase?: InputMaybe<UnitOfLengthInputV4>;
  readonly tankCapacity1?: InputMaybe<UnitOfCapacityInput>;
  readonly tankCapacity2?: InputMaybe<UnitOfCapacityInput>;
  readonly fifthWheelTerm?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly operatingStatusTerm?: InputMaybe<Scalars['String']>;
  readonly serviceStatusTerm?: InputMaybe<Scalars['String']>;
  readonly poolCarrierCode?: InputMaybe<Scalars['String']>;
  readonly lastPingLocation?: InputMaybe<Scalars['String']>;
  readonly lastPingDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly lastFacility?: InputMaybe<Scalars['String']>;
  readonly facilityLocation?: InputMaybe<Scalars['String']>;
  readonly program?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly measurementTerm?: InputMaybe<Scalars['String']>;
  readonly equipment?: InputMaybe<ReadonlyArray<CreateAssetPowerEquipmentInputV2>>;
  readonly identifiers?: InputMaybe<ReadonlyArray<CreateAssetPowerIdentifierInputV3>>;
  readonly maintenance?: InputMaybe<ReadonlyArray<CreateAssetPowerMaintenanceInputV2>>;
  readonly permanentTrailerCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentDriverCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type CreateAssetPowerLegalToRunInput = {
  readonly name?: InputMaybe<Scalars['String']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetPowerMaintenanceInput = {
  readonly type?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly planned?: InputMaybe<Scalars['Boolean']>;
  readonly severity?: InputMaybe<Scalars['String']>;
  readonly severityTerm?: InputMaybe<Scalars['String']>;
  readonly maintenanceFacility?: InputMaybe<Scalars['String']>;
  readonly location?: InputMaybe<Scalars['String']>;
  readonly expStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly expStartTime?: InputMaybe<Scalars['String']>;
  readonly actStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly actStartTime?: InputMaybe<Scalars['String']>;
  readonly estMaintTm?: InputMaybe<Scalars['String']>;
  readonly espEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly espEndTime?: InputMaybe<Scalars['String']>;
  readonly actEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly actEndTime?: InputMaybe<Scalars['String']>;
  readonly workPerformedDetail?: InputMaybe<Scalars['String']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetPowerMaintenanceInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly severityTerm: Scalars['String'];
  readonly isPlanned?: InputMaybe<Scalars['Boolean']>;
  readonly location?: InputMaybe<MaintenanceLocationInput>;
  readonly estimatedMaintenanceTime?: InputMaybe<Scalars['Int']>;
  readonly actual?: InputMaybe<DateTimeEntryInput>;
  readonly expected?: InputMaybe<DateTimeEntryInput>;
  readonly workPerformedDetail?: InputMaybe<Scalars['String']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type CreateAssetPowerPermanentDriverInput = {
  readonly permanentDriverId?: InputMaybe<Scalars['String']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly permanentDriverIdV1?: InputMaybe<Scalars['String']>;
};

export type CreateAssetTrailerMaintenanceInput = {
  readonly type?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly planned?: InputMaybe<Scalars['Boolean']>;
  readonly deadline?: InputMaybe<Scalars['DateTime']>;
  readonly appointment?: InputMaybe<Scalars['String']>;
  readonly mainFacility?: InputMaybe<Scalars['String']>;
  readonly location?: InputMaybe<Scalars['String']>;
  readonly severity?: InputMaybe<Scalars['String']>;
  readonly severityTerm?: InputMaybe<Scalars['String']>;
  readonly dateIn?: InputMaybe<Scalars['DateTime']>;
  readonly out?: InputMaybe<Scalars['String']>;
  readonly dateOut?: InputMaybe<Scalars['DateTime']>;
  readonly duration?: InputMaybe<Scalars['String']>;
  readonly workPerformedDetail?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetTrailerMaintenanceInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly severityTerm: Scalars['String'];
  readonly isPlanned?: InputMaybe<Scalars['Boolean']>;
  readonly location?: InputMaybe<MaintenanceLocationInput>;
  readonly estimatedMaintenanceTime?: InputMaybe<Scalars['Int']>;
  readonly actual?: InputMaybe<DateTimeEntryInput>;
  readonly expected?: InputMaybe<DateTimeEntryInput>;
  readonly workPerformedDetail?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type CreateAssetTrailersEquipmentInput = {
  readonly equipmentCd?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly count?: InputMaybe<Scalars['Int']>;
  readonly assetId: Scalars['String'];
  readonly condition?: InputMaybe<Scalars['String']>;
  readonly conditionTerm?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly issueDate?: InputMaybe<Scalars['DateTime']>;
  readonly returnDate?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetTrailersEquipmentInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId: Scalars['String'];
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly returnDate?: InputMaybe<Scalars['Date']>;
  readonly trailerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type CreateAssetTrailersIdentifierInput = {
  readonly identifierType: Scalars['String'];
  readonly identifierNumber: Scalars['String'];
  readonly state: Scalars['String'];
  readonly countryCd: Scalars['String'];
  readonly expirationDt?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly expirationDtV1?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetTrailersIdentifierInputV2 = {
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetTrailersIdentifierInputV3 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly value: Scalars['String'];
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly trailerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type CreateAssetTrailersInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly displayName?: InputMaybe<Scalars['String']>;
  readonly make?: InputMaybe<Scalars['String']>;
  readonly makeTerm?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly year?: InputMaybe<Scalars['Int']>;
  readonly yearV2?: InputMaybe<Scalars['Int']>;
  readonly containerProgramTerm?: InputMaybe<Scalars['String']>;
  readonly model?: InputMaybe<Scalars['String']>;
  readonly modelTerm?: InputMaybe<Scalars['String']>;
  readonly prefixTerm?: InputMaybe<Scalars['String']>;
  readonly trackingDevice?: InputMaybe<TrackingDeviceInput>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  /** DEPRECATED: legalToRun is deprecated. Use legalToRunTerm instead */
  readonly legalToRun?: InputMaybe<ReadonlyArray<CreateAssetTrailersLegalToRunInput>>;
  /** This field describes the list of countries details where the trailer is legal to run(Ex. Dry Van , CAN) */
  readonly legalToRunTerm?: InputMaybe<ReadonlyArray<CreateAssetTrailersLegalToRunInput>>;
  /** DEPRECATED: equipmentDetails is deprecated. Use equipment instead */
  readonly equipmentDetails?: InputMaybe<ReadonlyArray<CreateAssetTrailersEquipmentInput>>;
  readonly identifiers?: InputMaybe<ReadonlyArray<CreateAssetTrailersIdentifierInputV3>>;
  /** A collection of identifiers associated with the trailer. */
  readonly identifiersV2?: InputMaybe<ReadonlyArray<CreateAssetTrailersIdentifierInputV2>>;
  /** DEPRECATED: permanentDriver is deprecated. Use permanentDriverCodes instead. */
  readonly permanentDriver?: InputMaybe<ReadonlyArray<CreateAssetPermanentDriverInput>>;
  /** DEPRECATED: permanentDrivers is deprecated. Use permanentDriverCodes instead. */
  readonly permanentDrivers?: InputMaybe<ReadonlyArray<CreateAssetPermanentDriverInput>>;
  /** DEPRECATED: permanentTractor is deprecated. Use permanentPowerUnitCode instead. */
  readonly permanentTractor?: InputMaybe<ReadonlyArray<CreateAssetPermanentTractorInput>>;
  /** DEPRECATED: permanentPowerUnit is deprecated. Use permanentPowerUnitCode instead. */
  readonly permanentPowerUnit?: InputMaybe<ReadonlyArray<CreateAssetPermanentTractorInput>>;
  /** A collection of Maintenance associated with the trailer. */
  readonly maintenance?: InputMaybe<ReadonlyArray<CreateAssetTrailerMaintenanceInputV2>>;
  readonly isCARBCompliant?: InputMaybe<Scalars['Boolean']>;
  readonly trailerDevice?: InputMaybe<Scalars['String']>;
  readonly trailerDeviceTerm?: InputMaybe<Scalars['String']>;
  readonly trailerModel?: InputMaybe<Scalars['String']>;
  readonly trailerModelTerm?: InputMaybe<Scalars['String']>;
  readonly serialNumber?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly owner?: InputMaybe<Scalars['String']>;
  readonly ownerTerm?: InputMaybe<Scalars['String']>;
  readonly odomoter?: InputMaybe<Scalars['Float']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly color?: InputMaybe<Scalars['String']>;
  readonly floor?: InputMaybe<Scalars['String']>;
  readonly floorTerm?: InputMaybe<Scalars['String']>;
  readonly roof?: InputMaybe<Scalars['String']>;
  readonly roofTerm?: InputMaybe<Scalars['String']>;
  readonly doors?: InputMaybe<Scalars['String']>;
  readonly doorsTerm?: InputMaybe<Scalars['String']>;
  readonly skin?: InputMaybe<Scalars['String']>;
  readonly skinTerm?: InputMaybe<Scalars['String']>;
  readonly tire?: InputMaybe<TireInput>;
  readonly trailerLength?: InputMaybe<UnitOfLengthInputV4>;
  readonly exteriorHeightV2?: InputMaybe<UnitOfLengthInputV3>;
  readonly trailerExteriorHeight?: InputMaybe<UnitOfLengthInputV3>;
  readonly rearTireSize?: InputMaybe<Scalars['String']>;
  readonly length?: InputMaybe<Scalars['String']>;
  readonly exteriorHeight?: InputMaybe<Scalars['String']>;
  readonly exteriorWidth?: InputMaybe<Scalars['String']>;
  readonly exteriorWidthV2?: InputMaybe<UnitOfLengthInputV3>;
  readonly trailerExteriorWidth?: InputMaybe<UnitOfLengthInputV3>;
  readonly gvwr?: InputMaybe<Scalars['String']>;
  readonly grossVehicleWeightRating?: InputMaybe<UnitOfWeightInputV2>;
  readonly gawr?: InputMaybe<Scalars['String']>;
  readonly grossAxleWeightRating?: InputMaybe<UnitOfWeightInputV2>;
  readonly weight?: InputMaybe<Scalars['String']>;
  readonly trailerWeight?: InputMaybe<UnitOfWeightInputV2>;
  readonly frontInteriorHeight?: InputMaybe<Scalars['String']>;
  readonly frontInteriorHeightV2?: InputMaybe<UnitOfLengthInputV3>;
  readonly trailerFrontInteriorHeight?: InputMaybe<UnitOfLengthInputV3>;
  readonly rearInteriorHeight?: InputMaybe<Scalars['String']>;
  readonly rearInteriorHeightV2?: InputMaybe<UnitOfLengthInputV3>;
  readonly trailerRearInteriorHeight?: InputMaybe<UnitOfLengthInputV3>;
  readonly interiorWidth?: InputMaybe<Scalars['String']>;
  readonly interiorWidthV2?: InputMaybe<UnitOfLengthInputV4>;
  readonly trailerInteriorWidth?: InputMaybe<UnitOfLengthInputV4>;
  readonly doorWidth?: InputMaybe<Scalars['String']>;
  readonly doorWidthV2?: InputMaybe<UnitOfLengthInputV4>;
  readonly trailerDoorWidth?: InputMaybe<UnitOfLengthInputV4>;
  readonly landingGear?: InputMaybe<Scalars['String']>;
  readonly landingGearTerm?: InputMaybe<Scalars['String']>;
  readonly suspension?: InputMaybe<Scalars['String']>;
  readonly suspensionTerm?: InputMaybe<Scalars['String']>;
  readonly liftgateType?: InputMaybe<Scalars['String']>;
  readonly liftgateTypeTerm?: InputMaybe<Scalars['String']>;
  readonly liftgateCapacity?: InputMaybe<Scalars['String']>;
  readonly liftgateCapacityV2?: InputMaybe<UnitOfWeightInput>;
  readonly trailerLiftgateCapacity?: InputMaybe<UnitOfWeightInput>;
  readonly liftgateSize?: InputMaybe<Scalars['String']>;
  readonly liftgateSizeV2?: InputMaybe<UnitOfLengthInputV3>;
  readonly trailerLiftgateSize?: InputMaybe<UnitOfLengthInputV3>;
  readonly axleType?: InputMaybe<Scalars['String']>;
  readonly axleTypeTerm?: InputMaybe<Scalars['String']>;
  readonly axleCount?: InputMaybe<Scalars['Int']>;
  readonly etracks?: InputMaybe<Scalars['String']>;
  readonly eTracks?: InputMaybe<Scalars['String']>;
  readonly isETracks?: InputMaybe<Scalars['Boolean']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly lClosed?: InputMaybe<Scalars['String']>;
  readonly lengthClosed?: InputMaybe<Scalars['String']>;
  readonly lengthClosedV2?: InputMaybe<UnitOfLengthInputV3>;
  readonly trailerLengthClosed?: InputMaybe<UnitOfLengthInputV3>;
  readonly lExtended?: InputMaybe<Scalars['String']>;
  readonly lengthExtended?: InputMaybe<Scalars['String']>;
  readonly lengthExtendedV2?: InputMaybe<UnitOfLengthInputV3>;
  readonly trailerLengthExtended?: InputMaybe<UnitOfLengthInputV3>;
  readonly operatingStatus?: InputMaybe<Scalars['String']>;
  readonly operatingStatusTerm?: InputMaybe<Scalars['String']>;
  readonly serviceStatus?: InputMaybe<Scalars['String']>;
  readonly serviceStatusTerm?: InputMaybe<Scalars['String']>;
  readonly serviceStatusDetails?: InputMaybe<ServiceStatusDetailsInput>;
  /** DEPRECATED: Fleet is deprecated. Use createOrUpdateTrailerFleet Mutation instead */
  readonly fleet?: InputMaybe<Scalars['String']>;
  readonly pool?: InputMaybe<Scalars['String']>;
  readonly poolCarrierCode?: InputMaybe<Scalars['String']>;
  readonly tempMonitoring?: InputMaybe<Scalars['String']>;
  readonly tempMonitoringTerm?: InputMaybe<Scalars['String']>;
  readonly remoteAccess?: InputMaybe<Scalars['String']>;
  readonly isRemoteAccessAvailable?: InputMaybe<Scalars['Boolean']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly projectTerm?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly programTerm?: InputMaybe<Scalars['String']>;
  readonly program?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly measurementTerm?: InputMaybe<Scalars['String']>;
  readonly odometerUOMTerm?: InputMaybe<Scalars['String']>;
  readonly lengthUOMTerm?: InputMaybe<Scalars['String']>;
  readonly exteriorHeightUOMTerm?: InputMaybe<Scalars['String']>;
  readonly exteriorWidthUOMTerm?: InputMaybe<Scalars['String']>;
  readonly gvwrUOMTerm?: InputMaybe<Scalars['String']>;
  readonly gawrUOMTerm?: InputMaybe<Scalars['String']>;
  readonly weightUOMTerm?: InputMaybe<Scalars['String']>;
  readonly frontInteriorHeightUOMTerm?: InputMaybe<Scalars['String']>;
  readonly rearInteriorHeightUOMTerm?: InputMaybe<Scalars['String']>;
  readonly interiorWidthUOMTerm?: InputMaybe<Scalars['String']>;
  readonly doorWidthUOMTerm?: InputMaybe<Scalars['String']>;
  readonly tireWidth?: InputMaybe<Scalars['Int']>;
  readonly tireAspectRatio?: InputMaybe<Scalars['Int']>;
  readonly tireRimSize?: InputMaybe<Scalars['Int']>;
  readonly liftgateCapacityUOMTerm?: InputMaybe<Scalars['String']>;
  readonly liftgateSizeUOMTerm?: InputMaybe<Scalars['String']>;
  readonly lengthClosedUOMTerm?: InputMaybe<Scalars['String']>;
  readonly lengthExtendedUOMTerm?: InputMaybe<Scalars['String']>;
  /** A collection of Equipments associated with the trailer. */
  readonly equipment?: InputMaybe<ReadonlyArray<CreateAssetTrailersEquipmentInputV2>>;
  /** DEPRECATED: identifiersV3 is deprecated. Use identifiers instead */
  readonly identifiersV3?: InputMaybe<ReadonlyArray<CreateAssetTrailersIdentifierInputV3>>;
  readonly permanentPowerUnitCode?: InputMaybe<Scalars['String']>;
  readonly permanentDriverCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** DEPRECATED: maintenanceV2 is deprecated. Use maintenance instead */
  readonly maintenanceV2?: InputMaybe<ReadonlyArray<CreateAssetTrailerMaintenanceInputV2>>;
  readonly fleets?: InputMaybe<ReadonlyArray<TrailerFleetRelationInput>>;
  /** A collection of Equipments associated with the trailer. */
  readonly assetTrailerEquipment?: InputMaybe<ReadonlyArray<TrailerEquipmentObjectInput>>;
  /** A collection of identifiers associated with the trailer. */
  readonly assetTrailerIdentifiers?: InputMaybe<ReadonlyArray<TrailerIdentifierObjectInput>>;
  /** A collection of Maintenance associated with the trailer. */
  readonly assetTrailerMaintenance?: InputMaybe<ReadonlyArray<TrailerMaintenanceObjectInput>>;
  /** A collection of customers associated with the trailer. */
  readonly assetTrailerCustomers?: InputMaybe<ReadonlyArray<TrailerCustomerRelationshipObjectInput>>;
};

export type CreateAssetTrailersInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly code: Scalars['String'];
  readonly displayName?: InputMaybe<Scalars['String']>;
  readonly makeTerm?: InputMaybe<Scalars['String']>;
  readonly year?: InputMaybe<Scalars['Int']>;
  readonly isCARBCompliant?: InputMaybe<Scalars['Boolean']>;
  readonly containerProgramTerm?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly color?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly modelTerm?: InputMaybe<Scalars['String']>;
  readonly trackingDevice?: InputMaybe<TrackingDeviceInput>;
  readonly floorTerm?: InputMaybe<Scalars['String']>;
  readonly roofTerm?: InputMaybe<Scalars['String']>;
  readonly doorsTerm?: InputMaybe<Scalars['String']>;
  readonly skinTerm?: InputMaybe<Scalars['String']>;
  readonly tire?: InputMaybe<TireInput>;
  readonly trailerLength?: InputMaybe<UnitOfLengthInputV4>;
  readonly exteriorHeight?: InputMaybe<UnitOfLengthInputV3>;
  readonly exteriorWidth?: InputMaybe<UnitOfLengthInputV3>;
  readonly grossVehicleWeightRating?: InputMaybe<UnitOfWeightInputV2>;
  readonly grossAxleWeightRating?: InputMaybe<UnitOfWeightInputV2>;
  readonly trailerWeight?: InputMaybe<UnitOfWeightInputV2>;
  readonly frontInteriorHeight?: InputMaybe<UnitOfLengthInputV3>;
  readonly rearInteriorHeight?: InputMaybe<UnitOfLengthInputV3>;
  readonly interiorWidth?: InputMaybe<UnitOfLengthInputV4>;
  readonly doorWidth?: InputMaybe<UnitOfLengthInputV4>;
  readonly landingGearTerm?: InputMaybe<Scalars['String']>;
  readonly suspensionTerm?: InputMaybe<Scalars['String']>;
  readonly axleTypeTerm?: InputMaybe<Scalars['String']>;
  readonly axleCount?: InputMaybe<Scalars['Int']>;
  readonly liftgateTypeTerm?: InputMaybe<Scalars['String']>;
  readonly liftgateCapacity?: InputMaybe<UnitOfWeightInput>;
  readonly liftgateSize?: InputMaybe<UnitOfLengthInputV3>;
  readonly isETracks?: InputMaybe<Scalars['Boolean']>;
  readonly lengthClosed?: InputMaybe<UnitOfLengthInputV3>;
  readonly lengthExtended?: InputMaybe<UnitOfLengthInputV3>;
  readonly operatingStatusTerm?: InputMaybe<Scalars['String']>;
  readonly serviceStatusTerm?: InputMaybe<Scalars['String']>;
  readonly poolCarrierCode?: InputMaybe<Scalars['String']>;
  readonly tempMonitoringTerm?: InputMaybe<Scalars['String']>;
  readonly isRemoteAccessAvailable?: InputMaybe<Scalars['Boolean']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly program?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly measurementTerm?: InputMaybe<Scalars['String']>;
  /** A collection of Equipments associated with the trailer. */
  readonly equipment?: InputMaybe<ReadonlyArray<CreateAssetTrailersEquipmentInputV2>>;
  /** A collection of identifiers associated with the trailer. */
  readonly identifiers?: InputMaybe<ReadonlyArray<CreateAssetTrailersIdentifierInputV3>>;
  readonly permanentPowerUnitCode?: InputMaybe<Scalars['String']>;
  readonly permanentDriverCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A collection of Maintenance associated with the trailer. */
  readonly maintenance?: InputMaybe<ReadonlyArray<CreateAssetTrailerMaintenanceInputV2>>;
};

export type CreateAssetTrailersLegalToRunInput = {
  readonly name: Scalars['String'];
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAuditHistoryInput = {
  readonly modelType?: InputMaybe<Scalars['String']>;
  readonly changeType?: InputMaybe<Scalars['String']>;
  readonly recordId?: InputMaybe<Scalars['String']>;
  readonly recordName?: InputMaybe<Scalars['String']>;
  readonly timestamp?: InputMaybe<Scalars['String']>;
  readonly oldValue?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly identity?: InputMaybe<Scalars['String']>;
  readonly changes?: InputMaybe<ReadonlyArray<ChangeInput>>;
};

export type CreateBulkReratingRequestInput = {
  readonly notificationEmails: ReadonlyArray<Scalars['String']>;
};

export type CreateCanvasConfigurationInput = {
  /** If a customer defined validation configuration is not met, this is the expected behavior */
  readonly failedValidationBehavior: Scalars['String'];
  /** GlobalDefault indicator, flags whether this validation configuration is considered a fallback option applicable for this entity and action */
  readonly globalDefault: Scalars['Boolean'];
  /** List of rules that apply for this validation configuration */
  readonly requirements: ReadonlyArray<RequirementInput>;
  readonly validatorMetadata?: InputMaybe<ValidatorMetadataInput>;
  /** Scope to which this validator belongs to such as a tenant, carrier or specific customer. Corresponds to the scopedEntityUuid */
  readonly configScope: ConfigScope;
  /** Division associated with the entity, must match a division DDT */
  readonly division?: InputMaybe<CanvasKeyOptionsInput>;
  /** Order Transport Mode associated with the entity, must match a transportMode DDT */
  readonly orderTransportMode?: InputMaybe<CanvasKeyOptionsInput>;
  /** Unique Identifier of the entity this validator belongs to. Expected to be null when configScope is tenant level */
  readonly scopedEntityUuid?: InputMaybe<Scalars['String']>;
  /** Load Size associated with the entity, must match a loadSize DDT */
  readonly loadSize?: InputMaybe<CanvasKeyOptionsInput>;
  /** Trailer Type equipment associated with the entity, must match a trailerType DDT option ID in UUID format */
  readonly equipment?: InputMaybe<CanvasKeyOptionsInput>;
  /** Type of entity this validator applies to, such as 'order' or 'customer' */
  readonly entity: Scalars['String'];
  /** Action change taking place when this validation is checked, such as invoice generation or entity creation */
  readonly action: Scalars['String'];
  /** Route type associated with the entity, must match a routeType DDT */
  readonly routeType?: InputMaybe<CanvasKeyOptionsInput>;
  /** Order type associated with the entity, must match a orderType DDT */
  readonly orderType?: InputMaybe<CanvasKeyOptionsInput>;
  /** Route Transport Mode associated with the entity, must match a routeTransportMode DDT */
  readonly routeTransportMode?: InputMaybe<CanvasKeyOptionsInput>;
  /** Bill to Code associated with the entity */
  readonly billToCode?: InputMaybe<CanvasKeyOptionsInput>;
  /** Vendor Type associated with the entity and action */
  readonly vendorType?: InputMaybe<CanvasKeyOptionsInput>;
};

export type CreateCarrierAddressInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly carrierCode?: InputMaybe<Scalars['String']>;
  readonly addressTypeId: Scalars['ID'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly country: Scalars['String'];
  readonly county?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly postalCode: Scalars['String'];
  readonly isVerified?: InputMaybe<Scalars['Boolean']>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CreateCarrierCertificationInputV2 = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly expirationDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly vendorCode?: InputMaybe<Scalars['String']>;
  readonly carrierCertificationTypeId: Scalars['ID'];
  readonly carbTruckAndBusId?: InputMaybe<Scalars['Int']>;
  readonly carbTruckAndBusIndsdId?: InputMaybe<Scalars['Int']>;
  readonly carbTruckAndBusComplianceType?: InputMaybe<Scalars['String']>;
  readonly carbTruckAndBusCertDateValidUntil?: InputMaybe<Scalars['ISO8601Date']>;
  readonly carbTruckAndBusDeclaredNonCompliant?: InputMaybe<Scalars['String']>;
  readonly carbTruckAndBusRmisAuditOk?: InputMaybe<Scalars['Boolean']>;
  readonly certificationTypeValue?: InputMaybe<Scalars['Boolean']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly certificationId?: InputMaybe<Scalars['String']>;
};

export type CreateCarrierContactInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly carrierCode?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly chatTypeId?: InputMaybe<Scalars['ID']>;
  readonly chatUsername?: InputMaybe<Scalars['String']>;
  readonly contactTypeId?: InputMaybe<Scalars['ID']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CreateCarrierCrmNotesInput = {
  readonly contacts?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly notes: Scalars['String'];
  readonly opportunities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly reps: ReadonlyArray<Scalars['ID']>;
  readonly type?: InputMaybe<Scalars['String']>;
  readonly vendorId: Scalars['ID'];
};

export type CreateCarrierCrmOpportunityInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly opportunityStatusId?: InputMaybe<Scalars['ID']>;
  readonly opportunityTypeId?: InputMaybe<Scalars['ID']>;
  readonly owningContactId?: InputMaybe<Scalars['ID']>;
  readonly owningRepId: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
};

export type CreateCarrierExportNoteInput = {
  readonly createdBy: Scalars['Uuid'];
  readonly vendorId: Scalars['ID'];
};

export type CreateCarrierFacilityInput = {
  readonly relationshipType?: InputMaybe<Scalars['String']>;
  readonly canLoad?: InputMaybe<Scalars['Boolean']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly carrierId: Scalars['String'];
  readonly facilityId: Scalars['String'];
};

export type CreateCarrierIdentifierInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId: Scalars['ID'];
  readonly carrierIdentifierTypeId: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly link?: InputMaybe<Scalars['String']>;
};

export type CreateCarrierInteractionInput = {
  readonly contactId?: InputMaybe<Scalars['ID']>;
  readonly interactionOutcomeTypeId?: InputMaybe<Scalars['ID']>;
  readonly interactionTypeId: Scalars['ID'];
  readonly interactionViaTypeId?: InputMaybe<Scalars['ID']>;
  readonly nextInteractionDate?: InputMaybe<Scalars['DateTime']>;
  readonly nextInteractionRepId?: InputMaybe<Scalars['ID']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly vendorId: Scalars['ID'];
};

export type CreateCarrierRepInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId: Scalars['ID'];
  readonly repType?: InputMaybe<RepTypeEnum>;
  readonly repTypeKeyId?: InputMaybe<Scalars['ID']>;
  readonly employeeId: Scalars['ID'];
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly isLoadRep?: InputMaybe<Scalars['Boolean']>;
  /**  The field `divisionId` is deprecated. It is not editable from reps and pulled from the employee record.  */
  readonly divisionId?: InputMaybe<Scalars['ID']>;
  readonly modeId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  /**  The field `businessUnitId` is deprecated. It is not editable from reps and pulled from the employee record.  */
  readonly businessUnitId?: InputMaybe<Scalars['ID']>;
  readonly directionId?: InputMaybe<Scalars['ID']>;
};

export type CreateChannelLegError = ConflictingChannelLegError | ConflictingNetworkChannelError | PlannedNetworkError;

export type CreateChannelLegInput = {
  readonly networkChannelId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  /** A valid Carrier ID for the leg. */
  readonly carrierId: Scalars['ID'];
  /** Bidirectionality affects the leg's uniqueness. If true, this leg counts as two legs, including one with the origin and destination reversed. */
  readonly isBidirectional: Scalars['Boolean'];
  /** The time in hours it takes to complete the leg. Allows up to a two-decimal precision. */
  readonly turnaroundTimeHours?: InputMaybe<Scalars['Decimal']>;
  /** Valid Employee IDs for the leg. The order does not matter. */
  readonly assignedRepIds: ReadonlyArray<Scalars['ID']>;
  /** Minion division. */
  readonly divisions: ReadonlyArray<Scalars['String']>;
  /** Schedule units for the leg. The order does not matter. */
  readonly scheduleUnits: ReadonlyArray<SaveChannelLegScheduleUnitInput>;
  /** Stops with valid Facility IDs for the leg. The first stop will be the origin. Legs must be distinct by their origin and destination. */
  readonly stops: ReadonlyArray<SaveChannelLegStopInput>;
};

export type CreateChannelLegPayload = {
  readonly __typename?: 'CreateChannelLegPayload';
  readonly data?: Maybe<ChannelLeg>;
  readonly errors?: Maybe<ReadonlyArray<CreateChannelLegError>>;
};

/** Autogenerated return type of CreateChildrenCarrier */
export type CreateChildrenCarrierPayload = {
  readonly __typename?: 'CreateChildrenCarrierPayload';
  readonly carrier?: Maybe<CarrierV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateChildrenCustomerPayload = {
  readonly __typename?: 'CreateChildrenCustomerPayload';
  readonly customer?: Maybe<CustomerV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateClientExceptionRecordInput = {
  readonly type: ClientExceptionRecordType;
  readonly sourceType: ClientExceptionRecordSourceType;
  readonly secondarySourceType?: InputMaybe<ClientExceptionRecordSourceType>;
  readonly sourceID: Scalars['String'];
  readonly secondarySourceID?: InputMaybe<Scalars['String']>;
  readonly sourceCode?: InputMaybe<Scalars['String']>;
  readonly secondarySourceCode?: InputMaybe<Scalars['String']>;
  readonly reasons: ReadonlyArray<CreateClientExceptionRecordReasonInput>;
  readonly metadata: ReadonlyArray<UpsertClientExceptionRecordMetadataInput>;
};

export type CreateClientExceptionRecordReasonInput = {
  readonly status: ClientExceptionRecordReasonStatus;
  readonly code: ClientExceptionRecordReasonCode;
  readonly reasonCode: Scalars['String'];
  readonly bypassed?: InputMaybe<Scalars['Boolean']>;
  readonly assignedTo?: InputMaybe<Scalars['String']>;
  readonly assignedToGroup?: InputMaybe<Scalars['String']>;
  readonly assignedBy?: InputMaybe<Scalars['String']>;
};

export type CreateContactInput = {
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly chatTypeId?: InputMaybe<Scalars['ID']>;
  readonly chatUsername?: InputMaybe<Scalars['String']>;
  readonly contactTypeId?: InputMaybe<Scalars['ID']>;
  readonly contactableId?: InputMaybe<Scalars['ID']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly entity: EntityInput;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CreateContainerInput = {
  readonly carrierId: Scalars['ID'];
  readonly containerLengthUnit: UnitOfLengthEnum;
  readonly containerLengthValue: Scalars['Float'];
  /** Must be shorter than 50 characters; required if reservationNumber is not present */
  readonly containerNumber?: InputMaybe<Scalars['String']>;
  readonly containerProgramId?: InputMaybe<Scalars['String']>;
  readonly containerTypeId: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly endDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly endTimezone?: InputMaybe<Scalars['String']>;
  readonly externalSourceId?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly originLocation: CapacityLocationInput;
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  /** Must be alphanumeric and shorter than 50 characters; required if containerNumber is not present */
  readonly reservationNumber?: InputMaybe<Scalars['String']>;
  readonly reservationTypeId?: InputMaybe<Scalars['String']>;
  readonly startDatetimeUtc: Scalars['ISO8601DateTime'];
  readonly startTimezone: Scalars['String'];
  readonly sourceTypeId: Scalars['String'];
  readonly tenantInternalId?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId: Scalars['ID'];
  readonly ownerId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateCreditHistory */
export type CreateCreditHistoryPayload = {
  readonly __typename?: 'CreateCreditHistoryPayload';
  readonly creditHistory?: Maybe<CreditHistory>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateCreditHistoryPayloadV2 = {
  readonly __typename?: 'CreateCreditHistoryPayloadV2';
  readonly creditHistory?: Maybe<CreditHistoryV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateCrmCustomerDetailInputType = {
  readonly customerId: Scalars['ID'];
  /** DEPRECATED. Do not use this. This will be removed. Use InteractionFrequencyTypeId. For values refer InteractionFrequency Type in minion  */
  readonly interactionFrequency?: InputMaybe<CustomerInteractionFrequencyEnum>;
  readonly interactionFrequencyTypeId?: InputMaybe<Scalars['ID']>;
  readonly leadNotes?: InputMaybe<Scalars['String']>;
  /** DEPRECATED. Do not use this. This will be removed. Use LeadSourceTypeId. For values refer CustomerLeadSource Type in minion  */
  readonly leadSource?: InputMaybe<LeadSourceEnum>;
  readonly leadSourceTypeId?: InputMaybe<Scalars['ID']>;
};

export type CreateCustomerAddressInput = {
  readonly addressTypeId: Scalars['ID'];
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly county?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isVerified?: InputMaybe<Scalars['Boolean']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly postalCode: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CreateCustomerCommodityInput = {
  readonly customerFacilityId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly facilityId?: InputMaybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly packagingUnit?: InputMaybe<Scalars['String']>;
  readonly loadOn?: InputMaybe<Scalars['String']>;
  readonly expWt: Scalars['Float'];
  readonly expPieces?: InputMaybe<Scalars['String']>;
  readonly uom_temperature?: InputMaybe<Scalars['String']>;
  readonly uom_dimension?: InputMaybe<Scalars['String']>;
  readonly uom_linear?: InputMaybe<Scalars['String']>;
  readonly uom_cube?: InputMaybe<Scalars['String']>;
  readonly uom_density?: InputMaybe<Scalars['String']>;
  readonly temp?: InputMaybe<Scalars['Boolean']>;
  readonly precoolTo?: InputMaybe<Scalars['Float']>;
  readonly minTemp?: InputMaybe<Scalars['Float']>;
  readonly maxTemp?: InputMaybe<Scalars['Float']>;
  readonly tempSetting?: InputMaybe<Scalars['String']>;
  readonly wtUnit?: InputMaybe<Scalars['String']>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly hazmatClass?: InputMaybe<Scalars['String']>;
  readonly packagingGroup?: InputMaybe<Scalars['String']>;
  readonly unNaNumber?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly expDimensions?: InputMaybe<Scalars['String']>;
  readonly expDimensionsW?: InputMaybe<Scalars['Float']>;
  readonly expDimensionsH?: InputMaybe<Scalars['Float']>;
  readonly expDimensionsL?: InputMaybe<Scalars['Float']>;
  readonly expLinear?: InputMaybe<Scalars['Float']>;
  readonly expCube?: InputMaybe<Scalars['Float']>;
  readonly expDensity?: InputMaybe<Scalars['Float']>;
  readonly expClass?: InputMaybe<Scalars['String']>;
  readonly expNmfc?: InputMaybe<Scalars['String']>;
  readonly nmfcSubcode?: InputMaybe<Scalars['String']>;
  readonly unitSystem?: InputMaybe<Scalars['String']>;
  readonly canTopLoad?: InputMaybe<Scalars['Boolean']>;
  readonly canBottomLoad?: InputMaybe<Scalars['Boolean']>;
  readonly overDimensional?: InputMaybe<Scalars['Boolean']>;
  /** YYYY format */
  readonly year?: InputMaybe<Scalars['Int']>;
  readonly make?: InputMaybe<Scalars['String']>;
  readonly model?: InputMaybe<Scalars['String']>;
  readonly splitDimensions?: InputMaybe<Scalars['Boolean']>;
};

export type CreateCustomerContactInput = {
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly chatTypeId?: InputMaybe<Scalars['ID']>;
  readonly chatUsername?: InputMaybe<Scalars['String']>;
  readonly contactTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateCustomerCreditProvider */
export type CreateCustomerCreditProviderPayload = {
  readonly __typename?: 'CreateCustomerCreditProviderPayload';
  readonly customerCreditProvider: CustomerCreditProvider;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateCustomerCreditProviderPayloadV2 = {
  readonly __typename?: 'CreateCustomerCreditProviderPayloadV2';
  readonly customerCreditProvider?: Maybe<CustomerCreditProviderV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateCustomerCrmNotesRequest = {
  readonly contacts?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly customerId: Scalars['ID'];
  readonly notes: Scalars['String'];
  readonly opportunities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly reps: ReadonlyArray<Scalars['ID']>;
  readonly type?: InputMaybe<Scalars['String']>;
};

export type CreateCustomerCrmOpportunityInput = {
  readonly customerId: Scalars['ID'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly opportunityStatusId?: InputMaybe<Scalars['ID']>;
  readonly opportunityTypeId?: InputMaybe<Scalars['ID']>;
  readonly owningContactId?: InputMaybe<Scalars['ID']>;
  readonly owningRepId: Scalars['ID'];
};

export type CreateCustomerCustomerRelationshipInput = {
  readonly relationshipType?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly customerId: Scalars['String'];
  readonly relatedCustomerId: Scalars['String'];
};

export type CreateCustomerExportNoteInput = {
  readonly createdBy: Scalars['Uuid'];
  readonly customerId: Scalars['ID'];
};

export type CreateCustomerFacilityInput = {
  readonly schedulingSystem?: InputMaybe<Scalars['String']>;
  readonly schedulingURL?: InputMaybe<Scalars['String']>;
  readonly username?: InputMaybe<Scalars['String']>;
  readonly password?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly isAutoScheduleEligible?: InputMaybe<Scalars['Boolean']>;
  readonly isAutoScheduleDefault?: InputMaybe<Scalars['Boolean']>;
  readonly isPickup?: InputMaybe<Scalars['Boolean']>;
  readonly pickupCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isDelivery?: InputMaybe<Scalars['Boolean']>;
  readonly deliveryCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trailPool?: InputMaybe<Scalars['String']>;
  readonly minTrailers?: InputMaybe<Scalars['Int']>;
  readonly maxTrailers?: InputMaybe<Scalars['Int']>;
  readonly nowTrailers?: InputMaybe<Scalars['Int']>;
  readonly specialRequirementsAndEquipment?: InputMaybe<Scalars['String']>;
  readonly pickupAllowableFreeTimeValue?: InputMaybe<Scalars['Int']>;
  readonly pickupAllowableFreeTimeUnit?: InputMaybe<Scalars['String']>;
  readonly deliveryAllowableFreeTimeValue?: InputMaybe<Scalars['Int']>;
  readonly deliveryAllowableFreeTimeUnit?: InputMaybe<Scalars['String']>;
  readonly drop?: InputMaybe<Scalars['String']>;
  readonly customFullAddressPoint?: InputMaybe<GeopointInput>;
  readonly customerCustomerId?: InputMaybe<Scalars['String']>;
  readonly customerId: Scalars['String'];
  readonly facilityId: Scalars['String'];
  readonly stopDetails?: InputMaybe<ReadonlyArray<CustomerFacilityStopDetailsInput>>;
};

export type CreateCustomerIdentifierInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly identifierTypeId: Scalars['ID'];
  readonly code: Scalars['String'];
};

export type CreateCustomerInteractionInput = {
  readonly contactId?: InputMaybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly interactionOutcomeTypeId?: InputMaybe<Scalars['ID']>;
  readonly interactionTypeId?: InputMaybe<Scalars['ID']>;
  readonly interactionViaTypeId?: InputMaybe<Scalars['ID']>;
  readonly nextInteractionDate?: InputMaybe<Scalars['DateTime']>;
  readonly nextInteractionRepId?: InputMaybe<Scalars['ID']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  /** DEPRECATED. Do not use this. This will be removed. Use InteractionOutcomeTypeId. For values refer InteractionOutcome Type in minion  */
  readonly outcome?: InputMaybe<CustomerInteractionOutcomeEnum>;
  /** DEPRECATED. Do not use this. This will be removed. Use InteractionTypeId. For values refer InteractionType Type in minion  */
  readonly type?: InputMaybe<CustomerInteractionTypeEnum>;
  /** DEPRECATED. Do not use this. This will be removed. Use InteractionViaTypeId. For values refer InteractionVia Type in minion  */
  readonly via?: InputMaybe<CustomerInteractionViaEnum>;
};

/** Autogenerated return type of CreateCustomerQuote */
export type CreateCustomerQuotePayload = {
  readonly __typename?: 'CreateCustomerQuotePayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly quote: CustomerQuote;
};

export type CreateCustomerQuotePayloadV2 = {
  readonly __typename?: 'CreateCustomerQuotePayloadV2';
  readonly quote: CustomerQuotePaginatedV2;
};

export type CreateCustomerRegionInput = {
  readonly customerId: Scalars['String'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly region?: InputMaybe<Scalars['String']>;
};

export type CreateCustomerRepInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly repTypeId: Scalars['ID'];
  /** The field `divisionId` is deprecated. It is not editable from reps and pulled from the employee record. */
  readonly divisionId?: InputMaybe<Scalars['ID']>;
  readonly modeId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly directionId?: InputMaybe<Scalars['ID']>;
  /** The field `businessUnitId` is deprecated. It is not editable from reps and pulled from the employee record. */
  readonly businessUnitId?: InputMaybe<Scalars['ID']>;
  readonly employeeId: Scalars['ID'];
  readonly main: Scalars['Boolean'];
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly isLoadRep?: InputMaybe<Scalars['Boolean']>;
};

export type CreateCustomerStructureInput = {
  readonly customerId: Scalars['String'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
};

export type CreatedBy = {
  readonly __typename?: 'CreatedBy';
  readonly id: Scalars['String'];
  readonly code?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly role: DriverConversationCreatedByRole;
};

export type CreateDriverAssignmentInput = {
  readonly checkCallDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly dispatched?: InputMaybe<Scalars['Boolean']>;
  /** temporary field until we can resolve user info from the gateway context */
  readonly dispatchedBy?: InputMaybe<TrackingUserMetaInput>;
  readonly driver1?: InputMaybe<DriverInput>;
  readonly driver2?: InputMaybe<DriverInput>;
  readonly eldVehicleID?: InputMaybe<Scalars['String']>;
  readonly emptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly emptyLocation?: InputMaybe<LocationInput>;
  readonly emptyTimezone?: InputMaybe<Scalars['String']>;
  readonly equipmentID?: InputMaybe<Scalars['ID']>;
  readonly etaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly etaTimezone?: InputMaybe<Scalars['String']>;
  readonly externalInfo?: InputMaybe<DriverAssignmentExternalInfoInput>;
  readonly facilityID?: InputMaybe<Scalars['ID']>;
  readonly loadID?: InputMaybe<Scalars['ID']>;
  /** DEPRECATED - DO NOT USE, VALUE IS THROWN AWAY */
  readonly nextRouteID?: InputMaybe<Scalars['ID']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  /** DEPRECATED - DO NOT USE, VALUE IS THROWN AWAY */
  readonly previousRouteID?: InputMaybe<Scalars['ID']>;
  readonly reloadIntentID?: InputMaybe<Scalars['String']>;
  readonly routeID: Scalars['ID'];
  readonly trackingEnabled?: InputMaybe<Scalars['Boolean']>;
  /** temporary field until we can resolve user info from the gateway context */
  readonly trackingEnabledBy?: InputMaybe<TrackingUserMetaInput>;
  readonly trackingSystemID?: InputMaybe<Scalars['ID']>;
  readonly tractorIdentifier?: InputMaybe<Scalars['String']>;
  readonly trailerHeight?: InputMaybe<Scalars['Float']>;
  readonly trailerIdentifier?: InputMaybe<Scalars['String']>;
  readonly trailerLength?: InputMaybe<Scalars['Float']>;
  readonly trailerLocation?: InputMaybe<LocationInput>;
  readonly trailerWidth?: InputMaybe<Scalars['Float']>;
  readonly userID?: InputMaybe<Scalars['ID']>;
};

export type CreateDriverHosPayload = {
  readonly __typename?: 'CreateDriverHosPayload';
  readonly errors?: Maybe<ReadonlyArray<UserError>>;
  readonly hosEvent?: Maybe<DriverHos>;
};

export type CreateDriverPayAttributeInput = {
  readonly driverId: Scalars['ID'];
  readonly optionId: Scalars['String'];
  readonly value: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly effectiveDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type CreateDriverPayProgramCarrierAssociationInput = {
  readonly carrierId: Scalars['ID'];
  readonly payProgramId: Scalars['ID'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly rank: PayProgramRankEnum;
  readonly effectiveDate: Scalars['Date'];
  readonly effectiveDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type CreateDriverPayProgramDriverAssociationInput = {
  readonly driverId: Scalars['ID'];
  readonly payProgramId: Scalars['ID'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly rank: PayProgramRankEnum;
  readonly effectiveDate: Scalars['Date'];
  readonly effectiveDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type CreateDriverPayProgramInput = {
  readonly carrierId: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly name: Scalars['String'];
  readonly description?: InputMaybe<Scalars['String']>;
};

export type CreateDriverPayRateTableHeaderInput = {
  readonly payProgramId: Scalars['ID'];
  readonly description: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly effectiveDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type CreateDummyProjectNameExampleError = ProjectNameError;

export type CreateDummyProjectNameExampleInput = {
  readonly name: Scalars['String'];
};

export type CreateDummyProjectNameExamplePayload = {
  readonly __typename?: 'CreateDummyProjectNameExamplePayload';
  readonly data?: Maybe<Example>;
  readonly errors?: Maybe<ReadonlyArray<CreateDummyProjectNameExampleError>>;
};

export type CreateFacilityAddressInput = {
  readonly addressTypeId: Scalars['ID'];
  readonly addressTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly county?: InputMaybe<Scalars['String']>;
  readonly customFullAddressPoint?: InputMaybe<GeopointInput>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly facilityCode?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isVerified?: InputMaybe<Scalars['Boolean']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly postalCode: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CreateFacilityContactInput = {
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly chatTypeId?: InputMaybe<Scalars['ID']>;
  readonly chatUsername?: InputMaybe<Scalars['String']>;
  readonly contactTypeId?: InputMaybe<Scalars['ID']>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly facilityCode?: InputMaybe<Scalars['String']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CreateFacilityDirectionInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly facilityId: Scalars['ID'];
  readonly source?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly averageTime?: InputMaybe<Scalars['Int']>;
  readonly directions: Scalars['String'];
};

export type CreateFacilityHolidayInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly facilityId: Scalars['ID'];
  readonly holiday: Scalars['String'];
  readonly facilityOpenState: Scalars['String'];
  readonly holidayDate: Scalars['ISO8601DateTime'];
  readonly weekday?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly startOffsetMs?: InputMaybe<Scalars['Int']>;
  readonly endOffsetMs?: InputMaybe<Scalars['Int']>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
};

export type CreateFacilityLoadDefaultsInput = {
  readonly defaultEquipments?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly length?: InputMaybe<Scalars['Float']>;
  readonly width?: InputMaybe<Scalars['Float']>;
  readonly height?: InputMaybe<Scalars['Float']>;
  readonly schedSystem?: InputMaybe<Scalars['String']>;
  readonly schedURL?: InputMaybe<Scalars['String']>;
  readonly specialRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly autoSchedEligible?: InputMaybe<Scalars['Boolean']>;
  readonly autoSchedDefault?: InputMaybe<Scalars['Boolean']>;
  readonly facilityId: Scalars['String'];
  readonly facilityLoadDefaultsDivision?: InputMaybe<ReadonlyArray<CreateOrUpdateFacilityLoadDefaultsDivisionInput>>;
  readonly facilityLoadDefaultsBusinessUnit?: InputMaybe<ReadonlyArray<CreateOrUpdateFacilityLoadDefaultsBusinessUnitInput>>;
};

export type CreateFacilityNoteDefaultsInput = {
  readonly facilityId: Scalars['String'];
  readonly rateConfirmNote?: InputMaybe<Scalars['String']>;
  readonly externalFacilityNote?: InputMaybe<Scalars['String']>;
  readonly facilityLoadNote?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly noteType?: InputMaybe<Scalars['String']>;
  readonly facilityNoteDefaultsDivision?: InputMaybe<ReadonlyArray<CreateOrUpdateFacilityNoteDefaultsDivisionInput>>;
  readonly facilityNoteDefaultsBusinessUnits?: InputMaybe<ReadonlyArray<CreateOrUpdateFacilityNoteDefaultsBusinessUnitInput>>;
};

export type CreateFacilityRepInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly facilityId: Scalars['ID'];
  readonly repTypeId: Scalars['ID'];
  readonly employeeId: Scalars['ID'];
  readonly active?: InputMaybe<Scalars['Boolean']>;
};

export type CreateFacilitySublocationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly customFullAddressPoint?: InputMaybe<GeopointInput>;
  readonly facilityId: Scalars['ID'];
  readonly sublocation: Scalars['String'];
  readonly description: Scalars['String'];
  readonly type: Scalars['String'];
  readonly locked?: InputMaybe<Scalars['String']>;
  readonly guarded?: InputMaybe<Scalars['String']>;
  readonly checkInMethod?: InputMaybe<Scalars['String']>;
  readonly directions?: InputMaybe<Scalars['String']>;
};

export type CreateFacilityToImportPayload = {
  readonly __typename?: 'CreateFacilityToImportPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly facility?: Maybe<FacilityV2>;
};

export type CreateFirstTruckEntriesInput = {
  readonly carrierId: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly driver2Name?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originLocation: TruckLocationInput;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  /** The Power (Tractor) unit ID associated with the capacity */
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly powerNumber?: InputMaybe<Scalars['String']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerTypeId: Scalars['ID'];
  readonly updatedByUserId: Scalars['ID'];
  readonly fridayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly mondayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly saturdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly status?: InputMaybe<TruckEntryTemplateStatus>;
  readonly sourceType?: InputMaybe<TruckEntryTemplateSourceTypesEnum>;
  readonly sundayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly thursdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly tuesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly wednesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  /** Should be the ID of an AssetDriver record. */
  readonly driverId?: InputMaybe<Scalars['ID']>;
  /** Should be the ID of an AssetDriver record. */
  readonly driver2Id?: InputMaybe<Scalars['ID']>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  /** Represents truckEntryTemplateId from which FirstTrucks will be created */
  readonly truckEntryTemplateId: Scalars['ID'];
};

export type CreateFollowUpTimeInput = {
  readonly conversationId?: InputMaybe<Scalars['String']>;
  readonly userId: Scalars['String'];
  readonly followUpDateTime: Scalars['String'];
};

export type CreateFollowUpTimeResponse = {
  readonly __typename?: 'CreateFollowUpTimeResponse';
  readonly followUpTime: FollowUpTime;
};

export type CreateFollowUpTimeV2Input = {
  readonly conversation: ConversationInfo;
  readonly drivers: ReadonlyArray<Scalars['String']>;
  readonly userId: Scalars['String'];
  readonly followUpDateTime: Scalars['String'];
  readonly conversationSubject: Scalars['String'];
};

export type CreateFollowUpTimeV2Response = {
  readonly __typename?: 'CreateFollowUpTimeV2Response';
  readonly followUpTime: FollowUpTimeV2;
};

export type CreateGateReservationInput = {
  readonly carrierId: Scalars['ID'];
  readonly originRampFacilityId: Scalars['ID'];
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  /** DEPRECATED: Use containerProgramId instead */
  readonly containerProgramCarrierId?: InputMaybe<Scalars['ID']>;
  readonly containerProgramId?: InputMaybe<Scalars['String']>;
  readonly endDatetimeUtc: Scalars['ISO8601DateTime'];
  readonly startDatetimeUtc: Scalars['ISO8601DateTime'];
  readonly startTimezone: Scalars['String'];
  readonly endTimezone: Scalars['String'];
  readonly containerTypeId: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly updatedByUserId: Scalars['ID'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly containerLengthUnit: UnitOfLengthEnum;
  readonly containerLengthValue: Scalars['Float'];
  readonly reservationNumber: Scalars['String'];
  readonly sourceTypeId?: InputMaybe<Scalars['String']>;
};

export type CreateHaykEventInput = {
  readonly eventName: Scalars['String'];
  readonly eventType: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly group: Scalars['String'];
  readonly displayName?: InputMaybe<Scalars['String']>;
  readonly displayGroup?: InputMaybe<Scalars['String']>;
  readonly isVisible?: InputMaybe<Scalars['Boolean']>;
};

export type CreateHaykEventResult = {
  readonly __typename?: 'CreateHaykEventResult';
  readonly eventId?: Maybe<Scalars['String']>;
  readonly eventName?: Maybe<Scalars['String']>;
  readonly eventType?: Maybe<Scalars['String']>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly group?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly displayGroup?: Maybe<Scalars['String']>;
  readonly isVisible?: Maybe<Scalars['Boolean']>;
};

export type CreateInteractionRepGroupLookupInput = {
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Interaction_Rep_Group_KeyTerm?: InputMaybe<Scalars['String']>;
  readonly Interaction_Rep_Group_Type?: InputMaybe<Scalars['String']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly crm_Interaction_Rep_Group?: InputMaybe<ReadonlyArray<CreateInteractionRepGroupLookupInput>>;
};

export type CreateInteractionRepGroupLookupOutput = {
  readonly __typename?: 'CreateInteractionRepGroupLookupOutput';
  readonly Interaction_Rep_Group_Lookup_Id: Scalars['ID'];
  readonly Interaction_Rep_Group_Type?: Maybe<Scalars['String']>;
  readonly Interaction_Rep_Group_KeyTerm?: Maybe<Scalars['String']>;
  readonly crm_Interaction_Rep_Group?: Maybe<ReadonlyArray<CrmInteractionRepGroupOutput>>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CreateLinesOfBusinessInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly code?: InputMaybe<Scalars['String']>;
  readonly lineOfBusiness: Scalars['String'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type CreateLinkedRouteInputV2 = {
  readonly userID?: InputMaybe<Scalars['ID']>;
  readonly routeID: Scalars['ID'];
  readonly nextRouteID: Scalars['ID'];
  readonly initiatingRoute?: InputMaybe<LinkedRouteInitiatingRouteEnum>;
};

export type CreateLinkedRouteInputV3 = {
  readonly routeID: Scalars['ID'];
  readonly nextRouteID: Scalars['ID'];
  readonly linkType: LinkedRouteLinkTypeEnum;
  readonly initiatingRoute?: InputMaybe<LinkedRouteInitiatingRouteEnum>;
  readonly userID?: InputMaybe<Scalars['ID']>;
};

export type CreateLinkedRoutePayload = {
  readonly __typename?: 'CreateLinkedRoutePayload';
  readonly linkedRoute?: Maybe<LinkedRouteV3>;
  readonly errors?: Maybe<ReadonlyArray<LinkedRouteError>>;
};

export type CreateLoadDefaultsInput = {
  readonly size?: InputMaybe<SizeTypesEnum>;
  readonly sizeV2?: InputMaybe<Scalars['String']>;
  readonly minLength?: InputMaybe<Scalars['Float']>;
  readonly width?: InputMaybe<Scalars['Float']>;
  readonly height?: InputMaybe<Scalars['Float']>;
  readonly cargoValue?: InputMaybe<Scalars['Float']>;
  readonly cargoUnit?: InputMaybe<Scalars['String']>;
  readonly doNotPostLoads?: InputMaybe<Scalars['Boolean']>;
  readonly disableAutoPost?: InputMaybe<Scalars['Boolean']>;
  readonly equipment?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly specialRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId: Scalars['String'];
  readonly mode: Scalars['String'];
  readonly divisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type CreateLoadDefaultsInputV2 = {
  readonly size?: InputMaybe<SizeTypesEnum>;
  readonly sizeV2?: InputMaybe<Scalars['String']>;
  readonly minLength?: InputMaybe<Scalars['Float']>;
  readonly width?: InputMaybe<Scalars['Float']>;
  readonly height?: InputMaybe<Scalars['Float']>;
  readonly cargoValue?: InputMaybe<Scalars['Float']>;
  readonly cargoUnit?: InputMaybe<Scalars['String']>;
  readonly doNotPostLoads?: InputMaybe<Scalars['Boolean']>;
  readonly disableAutoPost?: InputMaybe<Scalars['Boolean']>;
  readonly equipment?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly specialRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId: Scalars['String'];
  readonly mode: Scalars['String'];
  readonly divisions: ReadonlyArray<Scalars['String']>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** Autogenerated return type of CreateLoad */
export type CreateLoadPayload = {
  readonly __typename?: 'CreateLoadPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly load: Load;
};

export type CreateLoadRouteInput = {
  readonly orderId: Scalars['ID'];
  readonly activationStatusId: Scalars['String'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
  readonly reps?: InputMaybe<ReadonlyArray<RouteAssignedRepInput>>;
  readonly refs?: InputMaybe<ReadonlyArray<RouteReferenceInput>>;
};

export type CreateLoadStopInput = {
  readonly facilityId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
  readonly isOrderStop?: InputMaybe<Scalars['Boolean']>;
  readonly type?: InputMaybe<StopTypeEnum>;
  readonly loadStopType?: InputMaybe<Scalars['String']>;
  readonly loadFromId?: InputMaybe<Scalars['ID']>;
  readonly unloadFromId?: InputMaybe<Scalars['ID']>;
  readonly requirements?: InputMaybe<ReadonlyArray<StopRequirementInput>>;
  readonly commoditiesV2?: InputMaybe<ReadonlyArray<LoadStopCommodityInput>>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInputV2>>;
  readonly reqDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly bolNumber?: InputMaybe<Scalars['String']>;
  /** Appointment fields for this stop/route */
  readonly appointment?: InputMaybe<AppointmentInputV2>;
  readonly sealNumber?: InputMaybe<Scalars['String']>;
  /** Name of the person who confirmed the appointment */
  readonly apptConfBy?: InputMaybe<Scalars['String']>;
  /** Name of the person who was requested to confirm the appointment */
  readonly apptReqWith?: InputMaybe<Scalars['String']>;
  /** stop unique identifier for external system */
  readonly externalRef?: InputMaybe<Scalars['String']>;
  /** validated against DDT stopScheduleChangeReason */
  readonly appointmentChangeReason?: InputMaybe<Scalars['String']>;
  /** Mapped to Total Handling Units on UI  */
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  /** flag for stops created from FE */
  readonly isCreatedManually?: InputMaybe<Scalars['Boolean']>;
  readonly splc?: InputMaybe<Scalars['String']>;
  /** PU/Del code(s) from customer-facility relationship */
  readonly externalFacilityId?: InputMaybe<Scalars['String']>;
  readonly assignedShipments?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverTargetStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly driverTargetEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly driverTargetStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly driverTargetEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly sequence?: InputMaybe<Scalars['Int']>;
  readonly routeStopSequence?: InputMaybe<Scalars['Int']>;
  readonly orderStopDesignation?: InputMaybe<Scalars['String']>;
};

export type CreateLoadStopInputV2 = {
  readonly note?: InputMaybe<Scalars['String']>;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
  readonly isOrderStop?: InputMaybe<Scalars['Boolean']>;
  readonly type?: InputMaybe<StopTypeEnum>;
  readonly loadStopType?: InputMaybe<Scalars['String']>;
  readonly loadFromId?: InputMaybe<Scalars['ID']>;
  readonly unloadFromId?: InputMaybe<Scalars['ID']>;
  readonly requirements?: InputMaybe<ReadonlyArray<StopRequirementInput>>;
  readonly commoditiesV2?: InputMaybe<ReadonlyArray<LoadStopCommodityInput>>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInputV2>>;
  readonly reqDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly bolNumber?: InputMaybe<Scalars['String']>;
  /** Appointment fields for this stop/route */
  readonly appointment?: InputMaybe<AppointmentInputV2>;
  readonly sealNumber?: InputMaybe<Scalars['String']>;
  /** Name of the person who confirmed the appointment */
  readonly apptConfBy?: InputMaybe<Scalars['String']>;
  /** Name of the person who was requested to confirm the appointment */
  readonly apptReqWith?: InputMaybe<Scalars['String']>;
  /** stop unique identifier for external system */
  readonly externalRef?: InputMaybe<Scalars['String']>;
  /** validated against DDT stopScheduleChangeReason */
  readonly appointmentChangeReason?: InputMaybe<Scalars['String']>;
  /** Mapped to Total Handling Units on UI  */
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  /** flag for stops created from FE */
  readonly isCreatedManually?: InputMaybe<Scalars['Boolean']>;
  readonly splc?: InputMaybe<Scalars['String']>;
  /** PU/Del code(s) from customer-facility relationship */
  readonly externalFacilityId?: InputMaybe<Scalars['String']>;
  readonly assignedShipments?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverTargetStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly driverTargetEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly driverTargetStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly driverTargetEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly sequence?: InputMaybe<Scalars['Int']>;
  readonly routeStopSequence?: InputMaybe<Scalars['Int']>;
  readonly orderStopDesignation?: InputMaybe<Scalars['String']>;
  /** Must provide either facilityId or address */
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  /** Must provide either facilityId or address */
  readonly address?: InputMaybe<CityStateStopLocationInput>;
};

export type CreateMembershipInput = {
  readonly userID: Scalars['ID'];
  readonly organizationID: Scalars['ID'];
  readonly editAccess: Scalars['Boolean'];
  readonly requestorUserID?: InputMaybe<Scalars['ID']>;
};

export type CreateMembershipsForUserInput = {
  readonly userID: Scalars['ID'];
  readonly organizationEditAccesses: ReadonlyArray<OrgIdEditAccessTuple>;
  readonly requestorUserID?: InputMaybe<Scalars['ID']>;
};

export type CreateMultipleChildrenCarriersPayload = {
  readonly __typename?: 'CreateMultipleChildrenCarriersPayload';
  readonly success: Scalars['Boolean'];
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateMultipleChildrenCustomersPayload = {
  readonly __typename?: 'CreateMultipleChildrenCustomersPayload';
  readonly success: Scalars['Boolean'];
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateMultipleTruckPostings */
export type CreateMultipleTruckPostingsPayload = {
  readonly __typename?: 'CreateMultipleTruckPostingsPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly truckPostings?: Maybe<ReadonlyArray<TruckPosting>>;
};

export type CreateNetworkChannelError = ConflictingPlannedNetworkError | ConflictingChannelLegError | PlannedNetworkError;

export type CreateNetworkChannelInput = {
  readonly plannedNetworkId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
};

export type CreateNetworkChannelPayload = {
  readonly __typename?: 'CreateNetworkChannelPayload';
  readonly data?: Maybe<NetworkChannel>;
  readonly errors?: Maybe<ReadonlyArray<CreateNetworkChannelError>>;
};

export type CreateNoteDefaultInput = {
  readonly customerId: Scalars['String'];
  readonly customerLoadNote?: InputMaybe<Scalars['String']>;
  readonly rateConfirmNote?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly noteType?: InputMaybe<Scalars['String']>;
  readonly invoiceNote?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type CreateOfferInput = {
  readonly routeId: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
  readonly code?: InputMaybe<Scalars['String']>;
  readonly trailerOwnerId?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly trailerDimensions?: InputMaybe<DimensionsInput>;
  readonly trailerLength?: InputMaybe<UnitOfLengthInput>;
  readonly trailerWidth?: InputMaybe<UnitOfLengthInput>;
  readonly trailerHeight?: InputMaybe<UnitOfLengthInput>;
  readonly emptyLocationId?: InputMaybe<Scalars['ID']>;
  readonly emptyLocationGeographySourceId?: InputMaybe<Scalars['Float']>;
  readonly emptyLocationGeographySource?: InputMaybe<Scalars['String']>;
  readonly emptyDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly type: OfferType;
  readonly reason?: InputMaybe<OfferReason>;
  readonly offerReason?: InputMaybe<Scalars['String']>;
  readonly price?: InputMaybe<CurrencyInput>;
  readonly offerPrice?: InputMaybe<CurrencyInputV2>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly askPrice?: InputMaybe<CurrencyInput>;
  readonly askPriceV2?: InputMaybe<CurrencyInputV2>;
  readonly vendorValidationErrors?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly offersDriver?: InputMaybe<OffersDriverInput>;
  readonly trailerOne?: InputMaybe<Scalars['String']>;
  readonly trailerTwo?: InputMaybe<Scalars['String']>;
  readonly trailerOwnerName?: InputMaybe<Scalars['String']>;
  readonly isAssetType?: InputMaybe<Scalars['Boolean']>;
  readonly driverOneId?: InputMaybe<Scalars['String']>;
  readonly source?: InputMaybe<Scalars['String']>;
  readonly operationSource?: InputMaybe<OfferOperationSource>;
};

export type CreateOrderProfileInput = {
  readonly customerId: Scalars['ID'];
  readonly profileName: Scalars['String'];
  readonly active?: Scalars['Boolean'];
  readonly conditions?: InputMaybe<LoadDefaultConditionsInput>;
  readonly orderDefaults: OrderDefaultsInput;
  readonly routeDefaults?: InputMaybe<RouteDefaultsInput>;
};

export type CreateOrderProfileInputV2 = {
  readonly customerId: Scalars['ID'];
  readonly profileName: Scalars['String'];
  readonly active?: Scalars['Boolean'];
  readonly conditions?: InputMaybe<LoadDefaultConditionsInput>;
  readonly orderDefaults: OrderDefaultsInputV2;
  readonly routeDefaults?: InputMaybe<RouteDefaultsInput>;
};

export type CreateOrganizationInput = {
  readonly name: Scalars['String'];
  readonly hierarchyCode?: InputMaybe<Scalars['String']>;
  readonly parentID?: InputMaybe<Scalars['ID']>;
  readonly requestorUserID?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateOrUpdateAddress */
export type CreateOrUpdateAddressPayload = {
  readonly __typename?: 'CreateOrUpdateAddressPayload';
  readonly address?: Maybe<Address>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateAddress */
export type CreateOrUpdateCarrierAddressPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierAddressPayload';
  readonly address?: Maybe<CarrierAddress>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCarrierCarrierRelationshipInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId: Scalars['ID'];
  readonly relatedCarrierId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly relationshipTypeId: Scalars['ID'];
};

export type CreateOrUpdateCarrierCarrierRelationshipPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierCarrierRelationshipPayload';
  readonly carrierCarrierRelationship?: Maybe<CarrierCarrierRelationship>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierCertification */
export type CreateOrUpdateCarrierCertificationPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierCertificationPayload';
  readonly carrierCertification?: Maybe<CarrierCertification>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierCertification */
export type CreateOrUpdateCarrierCertificationPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateCarrierCertificationPayloadV2';
  readonly carrierCertification?: Maybe<CarrierCertificationV2>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateContact */
export type CreateOrUpdateCarrierContactPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierContactPayload';
  readonly contact?: Maybe<CarrierContact>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierCustomerRelationship */
export type CreateOrUpdateCarrierCustomerRelationshipPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierCustomerRelationshipPayload';
  readonly carrierCustomerRelationship?: Maybe<CarrierCustomerRelationship>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierCustomerRelationship */
export type CreateOrUpdateCarrierCustomerRelationshipPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateCarrierCustomerRelationshipPayloadV2';
  readonly carrierCustomerRelationship?: Maybe<CarrierCustomerRelationshipV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCarrierElectronicTrackingInput = {
  readonly carrierId: Scalars['ID'];
  readonly electronicTrackingMethodId: Scalars['ID'];
  readonly electronicTrackingTypeId: Scalars['ID'];
  readonly priority: Scalars['Int'];
  readonly division?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type CreateOrUpdateCarrierElectronicTrackingPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierElectronicTrackingPayload';
  readonly carrierElectronicTracking?: Maybe<CarrierElectronicTracking>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCarrierGroupPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierGroupPayload';
  readonly carrierGroup?: Maybe<CarrierGroup>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCarrierIdentifierPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierIdentifierPayload';
  readonly carrierIdentifier?: Maybe<CarrierIdentifierV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCarrierInsurancePayload = {
  readonly __typename?: 'CreateOrUpdateCarrierInsurancePayload';
  readonly carrierInsurance?: Maybe<CarrierInsuranceV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCarrierParentInput = {
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId: Scalars['ID'];
  readonly carrierParentId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly relationshipType?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdateCarrierParentPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierParentPayload';
  readonly carrierParent?: Maybe<ParentRelationship>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrier */
export type CreateOrUpdateCarrierPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierPayload';
  readonly carrier?: Maybe<Carrier>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrier */
export type CreateOrUpdateCarrierPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateCarrierPayloadV2';
  readonly carrier?: Maybe<CarrierV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCarrierReleaseMethodPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierReleaseMethodPayload';
  readonly releaseMethod?: Maybe<CarrierReleaseMethod>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierReleaseMethodV1 */
export type CreateOrUpdateCarrierReleaseMethodV1Payload = {
  readonly __typename?: 'CreateOrUpdateCarrierReleaseMethodV1Payload';
  readonly errors: ReadonlyArray<UserError>;
  readonly releaseMethod?: Maybe<CarrierReleaseMethodV1>;
};

export type CreateOrUpdateCarrierRepPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierRepPayload';
  readonly carrierRep?: Maybe<RepV2>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierRiskInformation */
export type CreateOrUpdateCarrierRiskInformationPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierRiskInformationPayload';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierService */
export type CreateOrUpdateCarrierServicePayload = {
  readonly __typename?: 'CreateOrUpdateCarrierServicePayload';
  readonly carrierService?: Maybe<CarrierService>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierService */
export type CreateOrUpdateCarrierServicePayloadV2 = {
  readonly __typename?: 'CreateOrUpdateCarrierServicePayloadV2';
  readonly carrierService?: Maybe<CarrierServiceV2>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierSetting */
export type CreateOrUpdateCarrierSettingPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierSettingPayload';
  readonly carrierSetting: CarrierSetting;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierSetting */
export type CreateOrUpdateCarrierSettingPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateCarrierSettingPayloadV2';
  /** @deprecated Deprecated: Use carrierSettingNullable field instead */
  readonly carrierSetting: CarrierSettingV2;
  readonly carrierSettingNullable?: Maybe<CarrierSettingV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCommodityInput = {
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly actualClass?: InputMaybe<CommodityFreightClassTypeEnum>;
  readonly actualClassId?: InputMaybe<Scalars['ID']>;
  /** Deprecated, use actualVolume */
  readonly actualCube?: InputMaybe<UnitOfLengthInput>;
  readonly actualDensity?: InputMaybe<Scalars['String']>;
  readonly actualDimensions?: InputMaybe<DimensionsInput>;
  readonly actualLinear?: InputMaybe<UnitOfLengthInput>;
  /** Deprecated due to typo, use actualNMFCCode */
  readonly actualNFMCCode?: InputMaybe<Scalars['String']>;
  readonly actualNMFCCode?: InputMaybe<Scalars['String']>;
  readonly actualPieces?: InputMaybe<Scalars['Int']>;
  readonly actualVolume?: InputMaybe<UnitOfVolumeInput>;
  readonly actualWeight?: InputMaybe<UnitOfMassInput>;
  readonly bottomLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly expectedClass?: InputMaybe<CommodityFreightClassTypeEnum>;
  readonly expectedClassId?: InputMaybe<Scalars['ID']>;
  /** Deprecated, use expectedVolume */
  readonly expectedCube?: InputMaybe<UnitOfLengthInput>;
  readonly expectedDensity?: InputMaybe<Scalars['String']>;
  readonly expectedDimensions?: InputMaybe<DimensionsInput>;
  readonly expectedLinear?: InputMaybe<UnitOfLengthInput>;
  /** Deprecated due to typo, use expectedNMFCCode */
  readonly expectedNFMCCode?: InputMaybe<Scalars['String']>;
  readonly expectedNMFCCode?: InputMaybe<Scalars['String']>;
  readonly expectedPieces?: InputMaybe<Scalars['Int']>;
  readonly expectedVolume?: InputMaybe<UnitOfVolumeInput>;
  readonly expectedWeight: UnitOfMassInput;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly hazmatClass?: InputMaybe<CommodityHazmatClassTypeEnum>;
  readonly hazmatClassId?: InputMaybe<Scalars['ID']>;
  readonly hazmatPackagingGroup?: InputMaybe<CommodityHazmatPackagingGroupTypeEnum>;
  readonly hazmatPhoneNumber?: InputMaybe<Scalars['String']>;
  readonly hazmatUnitedNationsNumber?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly loadOnType?: InputMaybe<CommodityLoadOnTypeEnum>;
  readonly loadOnTypeId?: InputMaybe<Scalars['ID']>;
  readonly maximumTemperature?: InputMaybe<UnitOfTemperatureInput>;
  readonly minimumTemperature?: InputMaybe<UnitOfTemperatureInput>;
  readonly orderId?: InputMaybe<Scalars['ID']>;
  readonly packagingCount?: InputMaybe<Scalars['Int']>;
  readonly packagingType?: InputMaybe<CommodityPackagingTypeEnum>;
  readonly packagingTypeId?: InputMaybe<Scalars['ID']>;
  readonly preCoolTo?: InputMaybe<UnitOfTemperatureInput>;
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly temperatureControlled?: InputMaybe<Scalars['Boolean']>;
  readonly temperatureSetting?: InputMaybe<CommodityTemperatureSettingTypeEnum>;
  readonly topLoadable?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreateOrUpdateContact */
export type CreateOrUpdateContactPayload = {
  readonly __typename?: 'CreateOrUpdateContactPayload';
  readonly contact?: Maybe<Contact>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateContainerByRouteIdInput = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly containerLengthUnit?: InputMaybe<UnitOfLengthEnum>;
  readonly containerLengthValue?: InputMaybe<Scalars['Float']>;
  /** Must be shorter than 50 characters; required if reservationNumber is not present */
  readonly containerNumber?: InputMaybe<Scalars['String']>;
  readonly containerProgramId?: InputMaybe<Scalars['String']>;
  readonly containerTypeId?: InputMaybe<Scalars['ID']>;
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly endDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly endTimezone?: InputMaybe<Scalars['String']>;
  readonly externalSourceId?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly originLocation?: InputMaybe<CapacityLocationInput>;
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  /** Must be alphanumeric and shorter than 50 characters; required if containerNumber is not present */
  readonly reservationNumber?: InputMaybe<Scalars['String']>;
  readonly reservationTypeId?: InputMaybe<Scalars['String']>;
  readonly startDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly startTimezone?: InputMaybe<Scalars['String']>;
  readonly tenantInternalId?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['ID']>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId: Scalars['ID'];
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
};

export type CreateOrUpdateCustomerAddressPayload = {
  readonly __typename?: 'CreateOrUpdateCustomerAddressPayload';
  readonly customeraddress?: Maybe<CustomerAddress>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCustomerContactPayload = {
  readonly __typename?: 'CreateOrUpdateCustomerContactPayload';
  readonly contact?: Maybe<CustomerContact>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCustomerFacilityRelationship */
export type CreateOrUpdateCustomerFacilityRelationshipPayload = {
  readonly __typename?: 'CreateOrUpdateCustomerFacilityRelationshipPayload';
  readonly customerFacilityRelationship?: Maybe<CustomerFacilityRelationship>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCustomerFacilityRelationshipPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateCustomerFacilityRelationshipPayloadV2';
  readonly customerFacilityRelationship?: Maybe<CustomerFacilityRelationshipV2>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCustomerGroup */
export type CreateOrUpdateCustomerGroupPayload = {
  readonly __typename?: 'CreateOrUpdateCustomerGroupPayload';
  readonly customerGroup?: Maybe<CustomerGroup>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCustomerGroupPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateCustomerGroupPayloadV2';
  readonly customerGroup?: Maybe<CustomerGroupV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCustomerIdentifierPayload = {
  readonly __typename?: 'CreateOrUpdateCustomerIdentifierPayload';
  readonly customerIdentifier?: Maybe<IdentifierV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCustomerOrderInput = {
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly acceptedById?: InputMaybe<Scalars['ID']>;
  readonly activationStatusId?: InputMaybe<Scalars['ID']>;
  readonly activationStatusReason?: InputMaybe<Scalars['ID']>;
  readonly billToCustomerId?: InputMaybe<Scalars['ID']>;
  readonly cargoInsurance?: InputMaybe<CurrencyInput>;
  readonly commodities?: InputMaybe<ReadonlyArray<CreateOrUpdateCommodityInput>>;
  readonly customerId: Scalars['ID'];
  readonly customerOrderNumber?: InputMaybe<Scalars['String']>;
  readonly declinedById?: InputMaybe<Scalars['ID']>;
  readonly declinedReasonId?: InputMaybe<Scalars['ID']>;
  readonly dimensions?: InputMaybe<DimensionsInput>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly invoiceNote?: InputMaybe<Scalars['String']>;
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly orderNote?: InputMaybe<Scalars['String']>;
  readonly orderedById?: InputMaybe<Scalars['ID']>;
  readonly priceTierId?: InputMaybe<Scalars['ID']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly quoteTypeId?: InputMaybe<Scalars['ID']>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInput>>;
  readonly relationId?: InputMaybe<Scalars['ID']>;
  /** Deprecated, field is moved to load v2. */
  readonly reps?: InputMaybe<ReadonlyArray<CustomerOrderRepInput>>;
  readonly requirements?: InputMaybe<ReadonlyArray<OrderRequirementInput>>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly serviceLevelId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly tenderFromId?: InputMaybe<Scalars['ID']>;
  readonly tenderStatusId?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
};

export type CreateOrUpdateCustomerOrderInputV2 = {
  readonly customerId: Scalars['ID'];
  readonly requirements?: InputMaybe<ReadonlyArray<OrderRequirementInput>>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly commodities?: InputMaybe<ReadonlyArray<CommodityInput>>;
  readonly shipments?: InputMaybe<ReadonlyArray<ShipmentInput>>;
  /** Please use orderTypeId. It supports the Order Type DDT value. */
  readonly orderType?: InputMaybe<Scalars['String']>;
  readonly orderTypeId?: InputMaybe<Scalars['ID']>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInputV2>>;
  readonly dimensions?: InputMaybe<DimensionsInput>;
  readonly reps?: InputMaybe<ReadonlyArray<CustomerOrderRepInput>>;
  readonly activationStatusId?: InputMaybe<Scalars['ID']>;
  readonly tenderStatusId?: InputMaybe<Scalars['ID']>;
  /** Please use cargoInsuranceV2. It supports new currency values */
  readonly cargoInsurance?: InputMaybe<CurrencyInput>;
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly relationId?: InputMaybe<Scalars['ID']>;
  readonly quoteTypeId?: InputMaybe<Scalars['ID']>;
  readonly priceTierId?: InputMaybe<Scalars['ID']>;
  readonly invoiceNote?: InputMaybe<Scalars['String']>;
  readonly orderNote?: InputMaybe<Scalars['String']>;
  readonly acceptedById?: InputMaybe<Scalars['ID']>;
  readonly declinedById?: InputMaybe<Scalars['ID']>;
  readonly declineReason?: InputMaybe<Scalars['String']>;
  readonly declinedReasonId?: InputMaybe<Scalars['String']>;
  readonly orderedById?: InputMaybe<Scalars['ID']>;
  readonly divisionV2?: InputMaybe<Scalars['ID']>;
  /** businessUnit is deprecated */
  readonly businessUnit?: InputMaybe<Scalars['ID']>;
  readonly projectV2?: InputMaybe<Scalars['ID']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly serviceLevelId?: InputMaybe<Scalars['String']>;
  readonly billToCustomerId?: InputMaybe<Scalars['ID']>;
  readonly billToCodeId?: InputMaybe<Scalars['ID']>;
  readonly tenderFromId?: InputMaybe<Scalars['ID']>;
  readonly activationStatusReason?: InputMaybe<Scalars['String']>;
  readonly scac?: InputMaybe<Scalars['String']>;
  readonly tenderedAt?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly respondBy?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly customerOrderNumber?: InputMaybe<Scalars['String']>;
  readonly source?: InputMaybe<Scalars['String']>;
  /** Mapped to Total Handling Units on UI  */
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  readonly paymentTermsId?: InputMaybe<Scalars['ID']>;
  readonly tarpType?: InputMaybe<Scalars['ID']>;
  readonly tarpCount?: InputMaybe<Scalars['Int']>;
  readonly braceTypes?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly braceCount?: InputMaybe<Scalars['Int']>;
  readonly cargoInsuranceV2?: InputMaybe<LoadCurrencyInput>;
  readonly masteredSystem?: InputMaybe<Scalars['Boolean']>;
  readonly externalDistance?: InputMaybe<UnitOfLengthInput>;
  readonly id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateOrUpdateCustomerOrder */
export type CreateOrUpdateCustomerOrderPayload = {
  readonly __typename?: 'CreateOrUpdateCustomerOrderPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly order: CustomerOrder;
};

/** Autogenerated return type of CreateOrUpdateCustomer */
export type CreateOrUpdateCustomerPayload = {
  readonly __typename?: 'CreateOrUpdateCustomerPayload';
  readonly customer?: Maybe<Customer>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCustomerPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateCustomerPayloadV2';
  readonly customer?: Maybe<CustomerV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCustomerRepPayload = {
  readonly __typename?: 'CreateOrUpdateCustomerRepPayload';
  readonly customerRep?: Maybe<CustomerRepV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCustomerRouteDefaultsInput = {
  readonly customerId: Scalars['String'];
  readonly rateConfirmNote?: InputMaybe<Scalars['String']>;
  readonly doNotPostLoads: Scalars['Boolean'];
  readonly disableAutoPost: Scalars['Boolean'];
};

/** Autogenerated return type of CreateOrUpdateCustomerSetting */
export type CreateOrUpdateCustomerSettingPayload = {
  readonly __typename?: 'CreateOrUpdateCustomerSettingPayload';
  readonly customerSetting?: Maybe<CustomerSetting>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCustomerSettingPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateCustomerSettingPayloadV2';
  readonly customerSetting?: Maybe<CustomerSettingV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateDriverFleetPayload = {
  readonly __typename?: 'CreateOrUpdateDriverFleetPayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  readonly fleet?: Maybe<DriverFleet>;
};

export type CreateOrUpdateDriverFleetRelationshipInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  /** This field describes the fleet to which the driver belongs to. */
  readonly carrierId: Scalars['String'];
  /**
   * This field describes the effective date of the driver's fleet assignment.
   * Format is from RFC 3339, section 5.6 which is YYYY-MM-DD.
   */
  readonly effectiveDate: Scalars['Date'];
  /**
   * This field describes the expiration date of driver’s fleet assignment. Format
   * is from RFC 3339, section 5.6 which is YYYY-MM-DD.
   */
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  /** This field describes the type of assignment the driver undertakes within the organization. Ex: Primary, Secondary */
  readonly typeTerm: Scalars['String'];
  readonly driverId: Scalars['ID'];
  readonly isInheritPayProgram?: InputMaybe<Scalars['Boolean']>;
};

export type CreateOrUpdateDriverPayload = {
  readonly __typename?: 'CreateOrUpdateDriverPayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  readonly driver?: Maybe<AssetDriver>;
};

export type CreateOrUpdateDriverPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateDriverPayloadV2';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  readonly driver?: Maybe<AssetDriverV2>;
};

export type CreateOrUpdateDriverPreferredLanesPayload = {
  readonly __typename?: 'CreateOrUpdateDriverPreferredLanesPayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  readonly preferredLanesData?: Maybe<ReadonlyArray<DriverPreferredLanesOutput>>;
};

export type CreateOrUpdateDriverRepresentativePayload = {
  readonly __typename?: 'CreateOrUpdateDriverRepresentativePayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  readonly representative?: Maybe<DriverRepresentatives>;
};

export type CreateOrUpdateDriverTraningPayload = {
  readonly __typename?: 'CreateOrUpdateDriverTraningPayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  readonly training?: Maybe<DriverTraining>;
};

export type CreateOrUpdateDriverWeeklyTargetPayload = {
  readonly __typename?: 'CreateOrUpdateDriverWeeklyTargetPayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  readonly weeklyTarget?: Maybe<ReadonlyArray<DriverWeeklyTarget>>;
};

/** Autogenerated return type of CreateOrUpdateEmployeeExternal */
export type CreateOrUpdateEmployeeExternalPayload = {
  readonly __typename?: 'CreateOrUpdateEmployeeExternalPayload';
  readonly employee?: Maybe<Employee>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateEmployeeExternal */
export type CreateOrUpdateEmployeeExternalPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateEmployeeExternalPayloadV2';
  readonly employee?: Maybe<EmployeeV2>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateEmployee */
export type CreateOrUpdateEmployeePayload = {
  readonly __typename?: 'CreateOrUpdateEmployeePayload';
  readonly employee?: Maybe<Employee>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateEmployee */
export type CreateOrUpdateEmployeePayloadV2 = {
  readonly __typename?: 'CreateOrUpdateEmployeePayloadV2';
  readonly employee?: Maybe<EmployeeV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateFacilityAddressPayload = {
  readonly __typename?: 'CreateOrUpdateFacilityAddressPayload';
  readonly address?: Maybe<FacilityAddress>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateFacilityContactPayload = {
  readonly __typename?: 'CreateOrUpdateFacilityContactPayload';
  readonly contact?: Maybe<FacilityContact>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateFacilityDirectionPayload = {
  readonly __typename?: 'CreateOrUpdateFacilityDirectionPayload';
  readonly direction?: Maybe<FacilityDirection>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateFacilityFacilityRelationshipInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly facilityId: Scalars['String'];
  readonly relatedFacilityId: Scalars['String'];
  readonly relationshipType: Scalars['String'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
};

export type CreateOrUpdateFacilityHolidayPayload = {
  readonly __typename?: 'CreateOrUpdateFacilityHolidayPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly holiday?: Maybe<FacilityHoliday>;
};

/** Autogenerated return type of CreateOrUpdateFacilityIdentifier */
export type CreateOrUpdateFacilityIdentifierPayload = {
  readonly __typename?: 'CreateOrUpdateFacilityIdentifierPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly facilityIdentifier?: Maybe<FacilityIdentifier>;
};

export type CreateOrUpdateFacilityIdentifierPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateFacilityIdentifierPayloadV2';
  readonly errors: ReadonlyArray<UserError>;
  readonly facilityIdentifier?: Maybe<FacilityIdentifierV2>;
};

export type CreateOrUpdateFacilityLoadDefaultsBusinessUnitInput = {
  readonly businessUnit: Scalars['String'];
};

export type CreateOrUpdateFacilityLoadDefaultsDivisionInput = {
  readonly division: Scalars['String'];
};

export type CreateOrUpdateFacilityNoteDefaultsBusinessUnitInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly noteDefaultId?: InputMaybe<Scalars['String']>;
  readonly businessUnit: Scalars['String'];
};

export type CreateOrUpdateFacilityNoteDefaultsDivisionInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly noteDefaultId?: InputMaybe<Scalars['String']>;
  readonly division: Scalars['String'];
};

/** Autogenerated return type of CreateOrUpdateFacility */
export type CreateOrUpdateFacilityPayload = {
  readonly __typename?: 'CreateOrUpdateFacilityPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly facility?: Maybe<Facility>;
};

export type CreateOrUpdateFacilityPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateFacilityPayloadV2';
  readonly errors: ReadonlyArray<UserError>;
  readonly facility?: Maybe<FacilityV2>;
};

export type CreateOrUpdateFacilityRepPayload = {
  readonly __typename?: 'CreateOrUpdateFacilityRepPayload';
  readonly facilityRep?: Maybe<FacilityRepV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateFacilitySchedulePayload = {
  readonly __typename?: 'CreateOrUpdateFacilitySchedulePayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly schedule?: Maybe<ScheduleV2>;
};

export type CreateOrUpdateFacilitySublocationAccommodationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly sublocationId: Scalars['ID'];
  readonly accommodation: Scalars['String'];
  readonly rating?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdateFacilitySublocationAccommodationPayload = {
  readonly __typename?: 'CreateOrUpdateFacilitySublocationAccommodationPayload';
  readonly sublocationAccommodation?: Maybe<FacilitySublocationAccommodation>;
  readonly errors: ReadonlyArray<SublocationUserError>;
};

export type CreateOrUpdateFacilitySublocationActivityInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly sublocationId: Scalars['ID'];
  readonly activity: Scalars['String'];
  readonly averageTime?: InputMaybe<Scalars['Int']>;
  readonly averageTime100Loads?: InputMaybe<Scalars['Int']>;
  readonly variability?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdateFacilitySublocationActivityPayload = {
  readonly __typename?: 'CreateOrUpdateFacilitySublocationActivityPayload';
  readonly sublocationActivity?: Maybe<FacilitySublocationActivity>;
  readonly errors: ReadonlyArray<SublocationUserError>;
};

export type CreateOrUpdateFacilitySublocationGateInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly sublocationId: Scalars['ID'];
  readonly gate: Scalars['String'];
  readonly guardType?: InputMaybe<Scalars['String']>;
  readonly documentationNeeded?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly idChecked?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly guardContactInfo?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdateFacilitySublocationGatePayload = {
  readonly __typename?: 'CreateOrUpdateFacilitySublocationGatePayload';
  readonly sublocationGate?: Maybe<FacilitySublocationGate>;
  readonly errors: ReadonlyArray<SublocationUserError>;
};

export type CreateOrUpdateFacilitySublocationPayload = {
  readonly __typename?: 'CreateOrUpdateFacilitySublocationPayload';
  readonly sublocation?: Maybe<FacilitySublocation>;
  readonly errors: ReadonlyArray<SublocationUserError>;
};

export type CreateOrUpdateGroupInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly type: Scalars['String'];
  readonly purpose: Scalars['String'];
  readonly direction?: InputMaybe<Scalars['String']>;
  readonly distributionListEmail?: InputMaybe<Scalars['String']>;
  readonly owner?: InputMaybe<Scalars['ID']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<Scalars['Int']>;
  /** A collection of Employees associated with the group. */
  readonly employees?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A collection of Attributes associated with the group. */
  readonly attributesInput?: InputMaybe<ReadonlyArray<AttributesInput>>;
};

export type CreateOrUpdateGroupPayload = {
  readonly __typename?: 'CreateOrUpdateGroupPayload';
  readonly group?: Maybe<Group>;
  readonly errors: ReadonlyArray<GroupUserError>;
};

export type CreateOrUpdateInteractionPayload = {
  readonly __typename?: 'CreateOrUpdateInteractionPayload';
  readonly errors?: Maybe<ReadonlyArray<InteractionTypeError>>;
  readonly interaction?: Maybe<CrmInteractionOutputV2>;
};

export type CreateOrUpdateLinesOfBusinessPayload = {
  readonly __typename?: 'CreateOrUpdateLinesOfBusinessPayload';
  readonly linesOfBusiness?: Maybe<LinesOfBusiness>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateOrDeleteTruckByRouteIdInput = {
  readonly carrierId: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly driver2Name?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originLocation?: InputMaybe<TruckLocationInput>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  /** The Power (Tractor) unit ID associated with the capacity */
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly powerNumber?: InputMaybe<Scalars['String']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  readonly bracingType?: InputMaybe<BracingTypesEnum>;
  readonly bracingCount?: InputMaybe<Scalars['Float']>;
  readonly bracingTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Provide time in milliseconds. */
  readonly breakTimeMs?: InputMaybe<Scalars['Float']>;
  /** Provide time in milliseconds. Cannot exceed 70 hours */
  readonly cycleTimeMs?: InputMaybe<Scalars['Float']>;
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly driverDomicile?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly driverPhone?: InputMaybe<Scalars['String']>;
  readonly driver2Domicile?: InputMaybe<Scalars['String']>;
  readonly driver2Id?: InputMaybe<Scalars['ID']>;
  readonly driver2Phone?: InputMaybe<Scalars['String']>;
  /** Provide time in milliseconds. Cannot exceed 14 hours */
  readonly driveTimeMs?: InputMaybe<Scalars['Float']>;
  readonly externalSourceId?: InputMaybe<Scalars['String']>;
  /** Truck's finalBy datetime. Value is nullified if the finalByDatetime value is less than the loadByDatetime value. */
  readonly finalByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly finalByTimezone?: InputMaybe<Scalars['String']>;
  /** Truck's loadBy datetime, defaults to the readyDatetimeUtc (EOD) if a value is not provided or if the loadByDatetimeUtc value is less than the readyDatetimeUtc value */
  readonly loadByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly loadByTimezone?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly palletsType?: InputMaybe<PalletTypesEnum>;
  readonly palletsCount?: InputMaybe<Scalars['Float']>;
  readonly palletTypeId?: InputMaybe<Scalars['String']>;
  /** DEPRECATED: Use previousRouteIdV2 instead */
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  /** Provide time in milliseconds. Cannot exceed 16 hours */
  readonly shiftTimeMs?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED: Use sourceTypeId instead */
  readonly sourceType?: InputMaybe<SourceTypesEnum>;
  readonly tarpsType?: InputMaybe<TarpTypesEnum>;
  readonly tarpsCount?: InputMaybe<Scalars['Float']>;
  readonly tarpTypeId?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly truckEntryTemplateId?: InputMaybe<Scalars['ID']>;
  /** DDT ID for the capacity source of the projected hours of service */
  readonly phosSourceId?: InputMaybe<Scalars['String']>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly previousRouteIdV2: Scalars['ID'];
  readonly readyDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly reloadIntentId: Scalars['String'];
  readonly sourceTypeId?: InputMaybe<Scalars['String']>;
  readonly trailerTypeId: Scalars['ID'];
  readonly updatedByUserId?: InputMaybe<Scalars['ID']>;
};

/** This is a Power Fleet Input which we are using while creating Power Fleet relation */
export type CreateOrUpdatePowerFleetRelationshipInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly powerId: Scalars['ID'];
  /** This field describes the type of assignment used on the power unit, within the organization. Ex: Primary, Secondary */
  readonly typeTerm: Scalars['String'];
  /**
   * This field describes the effective date of the power's fleet assignment.
   * Format is from RFC 3339, section 5.6 which is YYYY-MM-DD.
   */
  readonly effectiveDate: Scalars['Date'];
  /**
   * This field describes the expiration date of power's fleet assignment. Format
   * is from RFC 3339, section 5.6 which is YYYY-MM-DD.
   */
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  /** This field describes the fleet to which the power unit belongs to. */
  readonly carrierId: Scalars['ID'];
};

export type CreateOrUpdatePowerFleetRelationshipPayload = {
  readonly __typename?: 'CreateOrUpdatePowerFleetRelationshipPayload';
  readonly errors?: Maybe<ReadonlyArray<PowerTypeError>>;
  readonly powerFleet?: Maybe<PowerFleet>;
};

export type CreateOrUpdatePowerPayload = {
  readonly __typename?: 'CreateOrUpdatePowerPayload';
  readonly errors?: Maybe<ReadonlyArray<PowerTypeError>>;
  readonly power?: Maybe<AssetPowerOutput>;
};

export type CreateOrUpdatePowerPayloadV2 = {
  readonly __typename?: 'CreateOrUpdatePowerPayloadV2';
  readonly errors?: Maybe<ReadonlyArray<PowerTypeError>>;
  readonly power?: Maybe<AssetPowerOutputV2>;
};

export type CreateOrUpdateProjectInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly name: Scalars['String'];
};

export type CreateOrUpdateProjectPayload = {
  readonly __typename?: 'CreateOrUpdateProjectPayload';
  readonly project?: Maybe<Project>;
  readonly errors: ReadonlyArray<ProjectUserError>;
};

export type CreateOrUpdateRouteAssignedRepInput = {
  readonly routeId: Scalars['ID'];
  readonly reps: ReadonlyArray<RouteAssignedRepInput>;
};

export type CreateOrUpdateRouteAssignedRepsInput = {
  readonly routeId: Scalars['ID'];
  readonly reps: ReadonlyArray<RouteAssignedRepInput>;
};

export type CreateOrUpdateRouteReferencesInput = {
  readonly routeId: Scalars['ID'];
  readonly refs: ReadonlyArray<RouteReferenceInput>;
};

export type CreateOrUpdateRouteVendorReferenceInput = {
  /** Include a Route Reference ID to update an existing Reference */
  readonly id?: InputMaybe<Scalars['ID']>;
  /** The value of the Route Reference */
  readonly value: Scalars['String'];
  /** The type of the Route Reference */
  readonly routeVendorReferenceType: RouteVendorReferenceTypeEnum;
  readonly ediQualifier?: InputMaybe<Scalars['String']>;
  readonly createdById?: InputMaybe<Scalars['String']>;
  readonly updatedById?: InputMaybe<Scalars['String']>;
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
};

export type CreateOrUpdateRouteVendorReferenceInputV2 = {
  /** Include a Route Reference ID to update an existing Reference. */
  readonly id?: InputMaybe<Scalars['ID']>;
  /** Route Reference type allows any string to be passed in. */
  readonly value: Scalars['String'];
  /** This accepts any string. A Validation is run to ensure it is a valid Route Reference Type. */
  readonly routeVendorReferenceTypeV2: Scalars['String'];
  /** EDI Qualifier for the Route Reference - EDI ID qualifiers and their corresponding values identify the sender and receiver of the EDI document. */
  readonly ediQualifier?: InputMaybe<Scalars['String']>;
  /** ID of the User who created the Route Reference. */
  readonly createdByUserId?: InputMaybe<Scalars['String']>;
  /** Employee ID of the user who created the Route Reference */
  readonly createdById?: InputMaybe<Scalars['String']>;
  /** ID of the user who updated the Route Reference */
  readonly updatedByUserId?: InputMaybe<Scalars['String']>;
  /** Employee ID of the user who updated the Route Reference */
  readonly updatedById?: InputMaybe<Scalars['String']>;
  /** Vendor ID is for the uncovered Route Reference table that core load will maintain */
  readonly vendorId?: InputMaybe<Scalars['String']>;
  /** booked maybe used to determine if the route vendor is booked or not if the route vendor is not booked then the Route Reference is not created and instead sent to core load */
  readonly booked?: InputMaybe<Scalars['Boolean']>;
  /** _destroy is used to determine if the Route Reference should be deleted or not */
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
};

export type CreateOrUpdateRouteVendorRepInput = {
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly employeeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly main: Scalars['Boolean'];
  readonly repType: Scalars['ID'];
};

/** Input for Creating or Updating Route Vendor Reps when Assigning or Updating a Carrier / Vendor */
export type CreateOrUpdateRouteVendorRepV2Input = {
  /** Set this to true to remove a Route Vendor Rep */
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  /** The employee ID of the Route Vendor Rep */
  readonly employeeId: Scalars['ID'];
  /** Include an ID to perform an update on an existing Rep */
  readonly id?: InputMaybe<Scalars['ID']>;
  /** Whether the Rep is the Main Rep. Only one Main Rep is allowed per Route Vendor */
  readonly main: Scalars['Boolean'];
  /** ⚠️ **Deprecated! Use repTypeId** ⚠️ */
  readonly repType?: InputMaybe<RouteVendorRepTypeEnum>;
  /** The type of Route Vendor Rep. (Ex. Carrier Ops, Carrier Sales, etc.) */
  readonly repTypeId?: InputMaybe<Scalars['String']>;
  /** ⚠️ **Deprecated! Use businessUnitId** ⚠️ */
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  /** The Business Unit of the Rep */
  readonly businessUnitId?: InputMaybe<Scalars['String']>;
  /** ⚠️ **Deprecated! Use divisionId** ⚠️ */
  readonly division?: InputMaybe<Scalars['String']>;
  /** The Division of the Rep */
  readonly divisionId?: InputMaybe<Scalars['String']>;
};

/** Input for Creating or Updating Route Vendor Reps when Assigning or Updating a Carrier / Vendor */
export type CreateOrUpdateRouteVendorRepV3Input = {
  /** Set this to true to remove a Route Vendor Rep */
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  /** The employee ID of the Route Vendor Rep */
  readonly employeeId: Scalars['ID'];
  /** Include an ID to perform an update on an existing Rep */
  readonly id?: InputMaybe<Scalars['ID']>;
  /** Whether the Rep is the Main Rep. Only one Main Rep is allowed per Route Vendor */
  readonly main: Scalars['Boolean'];
  /** The type of Route Vendor Rep. (Ex. Carrier Ops, Carrier Sales, etc.) */
  readonly repTypeId: Scalars['String'];
  /** The Business Unit of the Rep */
  readonly businessUnitId?: InputMaybe<Scalars['String']>;
  /** The Division of the Rep */
  readonly divisionId?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdateServiceToleranceInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly customerId: Scalars['String'];
  readonly stopType: Scalars['String'];
  readonly units: Scalars['String'];
  readonly earlyPickup?: InputMaybe<Scalars['Int']>;
  readonly latePickup?: InputMaybe<Scalars['Int']>;
  readonly earlyDelivery?: InputMaybe<Scalars['Int']>;
  readonly lateDelivery?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateOrUpdateSingleTruckPosting */
export type CreateOrUpdateSingleTruckPostingPayload = {
  readonly __typename?: 'CreateOrUpdateSingleTruckPostingPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly truckPosting: TruckPosting;
};

export type CreateOrUpdateStopInput = {
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly addressId?: InputMaybe<Scalars['ID']>;
  readonly appointment?: InputMaybe<AppointmentInput>;
  readonly apptConfBy?: InputMaybe<Scalars['String']>;
  readonly apptReqWith?: InputMaybe<Scalars['String']>;
  readonly availableEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly bolNumber?: InputMaybe<Scalars['String']>;
  readonly carrierArrival?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly carrierDeparture?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly commodities?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly externalRef?: InputMaybe<Scalars['String']>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly lateArrivalReasonCodeId?: InputMaybe<Scalars['ID']>;
  readonly liveType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly loadFromId?: InputMaybe<Scalars['ID']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInput>>;
  readonly reqDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly requirements?: InputMaybe<ReadonlyArray<StopRequirementInput>>;
  readonly routes?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly sealNumber?: InputMaybe<Scalars['String']>;
  readonly signature?: InputMaybe<Scalars['String']>;
  readonly trailerNumberIn?: InputMaybe<Scalars['String']>;
  readonly trailerNumberOut?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<StopTypeEnum>;
  readonly unloadFromId?: InputMaybe<Scalars['ID']>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
};

/** Autogenerated return type of CreateOrUpdateStop */
export type CreateOrUpdateStopPayload = {
  readonly __typename?: 'CreateOrUpdateStopPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly stop: Stop;
};

export type CreateOrUpdateTrailerFleetPayload = {
  readonly __typename?: 'CreateOrUpdateTrailerFleetPayload';
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
  readonly fleet?: Maybe<TrailerFleet>;
};

export type CreateOrUpdateTrailerPayload = {
  readonly __typename?: 'CreateOrUpdateTrailerPayload';
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
  readonly trailer?: Maybe<AssetTrailers>;
};

export type CreateOrUpdateTrailerPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateTrailerPayloadV2';
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
  readonly trailer?: Maybe<AssetTrailersV2>;
};

export type CreateOrUpdateTruckByExternalSourceIdInput = {
  readonly carrierId: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly driver2Name?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originLocation?: InputMaybe<TruckLocationInput>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  /** The Power (Tractor) unit ID associated with the capacity */
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly powerNumber?: InputMaybe<Scalars['String']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId: Scalars['ID'];
  readonly bracingType?: InputMaybe<BracingTypesEnum>;
  readonly bracingCount?: InputMaybe<Scalars['Float']>;
  readonly bracingTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Provide time in milliseconds. */
  readonly breakTimeMs?: InputMaybe<Scalars['Float']>;
  /** Provide time in milliseconds. Cannot exceed 70 hours */
  readonly cycleTimeMs?: InputMaybe<Scalars['Float']>;
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly driverDomicile?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly driverPhone?: InputMaybe<Scalars['String']>;
  readonly driver2Domicile?: InputMaybe<Scalars['String']>;
  readonly driver2Id?: InputMaybe<Scalars['ID']>;
  readonly driver2Phone?: InputMaybe<Scalars['String']>;
  /** Provide time in milliseconds. Cannot exceed 14 hours */
  readonly driveTimeMs?: InputMaybe<Scalars['Float']>;
  readonly externalSourceId: Scalars['String'];
  /** Truck's finalBy datetime. Value is nullified if the finalByDatetime value is less than the loadByDatetime value. */
  readonly finalByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly finalByTimezone?: InputMaybe<Scalars['String']>;
  /** Truck's loadBy datetime, defaults to the readyDatetimeUtc (EOD) if a value is not provided or if the loadByDatetimeUtc value is less than the readyDatetimeUtc value */
  readonly loadByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly loadByTimezone?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly palletsType?: InputMaybe<PalletTypesEnum>;
  readonly palletsCount?: InputMaybe<Scalars['Float']>;
  readonly palletTypeId?: InputMaybe<Scalars['String']>;
  /** DEPRECATED: Use previousRouteIdV2 instead */
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  readonly previousRouteIdV2?: InputMaybe<Scalars['ID']>;
  readonly readyDatetimeUtc: Scalars['ISO8601DateTime'];
  /** Provide time in milliseconds. Cannot exceed 16 hours */
  readonly shiftTimeMs?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED: Use sourceTypeId instead */
  readonly sourceType?: InputMaybe<SourceTypesEnum>;
  readonly sourceTypeId?: InputMaybe<Scalars['String']>;
  readonly tarpsType?: InputMaybe<TarpTypesEnum>;
  readonly tarpsCount?: InputMaybe<Scalars['Float']>;
  readonly tarpTypeId?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly truckEntryTemplateId?: InputMaybe<Scalars['ID']>;
  /** DDT ID for the capacity source of the projected hours of service */
  readonly phosSourceId?: InputMaybe<Scalars['String']>;
  /** An ISO timestamp, that should be provided in the America/Chicago timezone, used to set a time when the truck entry will expire */
  readonly expireAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly externalUpdatedAt: Scalars['ISO8601DateTime'];
  readonly externalUpdatedByUserId: Scalars['ID'];
};

export type CreatePatternInput = {
  readonly laneId: Scalars['ID'];
  readonly patternName: Scalars['String'];
  readonly eligibleDays?: InputMaybe<ReadonlyArray<DayOfWeekEnum>>;
  readonly eligibleDaysV2?: InputMaybe<ReadonlyArray<DayEnum>>;
  readonly patternRoutes: ReadonlyArray<CreatePatternRouteInput>;
  readonly minimumPickupLeadTime?: InputMaybe<Scalars['Int']>;
  readonly userId: Scalars['ID'];
  readonly effectiveDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly autoApply?: InputMaybe<Scalars['Boolean']>;
};

export type CreatePatternPayload = {
  readonly __typename?: 'CreatePatternPayload';
  readonly data?: Maybe<Pattern>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type CreatePatternRouteInput = {
  readonly routeSequence: Scalars['Int'];
  readonly routeTypeId: Scalars['String'];
  readonly transportModeId: Scalars['String'];
  readonly loadSizeId: Scalars['String'];
  readonly trailerTypeId: Scalars['String'];
  readonly divisionId?: InputMaybe<Scalars['String']>;
  readonly additionalDivisionIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly maxCost?: InputMaybe<Scalars['Float']>;
  readonly routingGuideId?: InputMaybe<Scalars['ID']>;
  readonly lastRoutingGuideCheckDate?: InputMaybe<Scalars['Date']>;
  readonly routeLaneId?: InputMaybe<Scalars['ID']>;
  readonly patternStops: ReadonlyArray<CreatePatternStopInput>;
  readonly serviceProfileId?: InputMaybe<Scalars['ID']>;
};

export type CreatePatternStopInput = {
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly useOrderStopFacility?: InputMaybe<Scalars['Boolean']>;
  readonly stopSequence: Scalars['Int'];
  readonly stopType: Scalars['String'];
  readonly dropType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
  readonly requirements?: InputMaybe<ReadonlyArray<PatternStopRequirementInput>>;
  readonly daysFromPreviousStop?: InputMaybe<Scalars['Int']>;
  readonly laneStopSequence?: InputMaybe<Scalars['Int']>;
  readonly appointment?: InputMaybe<PatternStopAppointmentInput>;
};

export type CreatePlannedNetworkError = ConflictingPlannedNetworkError | ConflictingNetworkChannelError | PlannedNetworkError;

export type CreatePlannedNetworkInput = {
  readonly name: Scalars['String'];
  /** Minion network_type. */
  readonly networkType: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
};

export type CreatePlannedNetworkPayload = {
  readonly __typename?: 'CreatePlannedNetworkPayload';
  readonly data?: Maybe<PlannedNetwork>;
  readonly errors?: Maybe<ReadonlyArray<CreatePlannedNetworkError>>;
};

export type CreatePlatformMessageInput = {
  readonly notificationChannels: ReadonlyArray<InputMaybe<PlatformNotificationChannels>>;
  readonly from: Scalars['String'];
  readonly to: ReadonlyArray<Scalars['String']>;
  readonly message: Scalars['String'];
  readonly messageId: Scalars['String'];
  readonly createdByUserId: Scalars['String'];
  readonly workspaceId?: InputMaybe<Scalars['String']>;
};

export type CreatePostingInput = {
  readonly createdByUserId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly postedRate: CurrencyInput;
  readonly bookItNowRate?: InputMaybe<CurrencyInput>;
  readonly size: UnitOfLengthInput;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly loadBoards?: InputMaybe<ReadonlyArray<PostingSource>>;
  readonly autoPosted?: InputMaybe<Scalars['Boolean']>;
  readonly operationSource?: InputMaybe<PostingsOperationSource>;
};

export type CreatePostingPayload = {
  readonly __typename?: 'CreatePostingPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly posting?: Maybe<Posting>;
};

export type CreatePostingsInput = {
  readonly postings: ReadonlyArray<InputMaybe<CreatePostingInput>>;
};

export type CreatePostingsPayload = {
  readonly __typename?: 'CreatePostingsPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly postings?: Maybe<ReadonlyArray<Maybe<Posting>>>;
};

export type CreatePreplanInput = {
  readonly routeId: Scalars['ID'];
  readonly drivers: ReadonlyArray<PreplansDriverRecordInput>;
  readonly routeNumber: Scalars['String'];
  readonly lastAction?: InputMaybe<Scalars['String']>;
  readonly deadheadMiles?: InputMaybe<Scalars['String']>;
  readonly lineHaulMiles?: InputMaybe<Scalars['String']>;
  readonly firstStopCity?: InputMaybe<Scalars['String']>;
  readonly firstStopState?: InputMaybe<Scalars['String']>;
  readonly firstStopCcaStartDatetime?: InputMaybe<Scalars['String']>;
  readonly firstStopCcaEndDatetime?: InputMaybe<Scalars['String']>;
  readonly lastStopCity?: InputMaybe<Scalars['String']>;
  readonly lastStopState?: InputMaybe<Scalars['String']>;
  readonly lastStopCcaStartDatetime?: InputMaybe<Scalars['String']>;
  readonly lastStopCcaEndDatetime?: InputMaybe<Scalars['String']>;
  readonly pickUpDateTime?: InputMaybe<Scalars['String']>;
  readonly deliveryDateTime?: InputMaybe<Scalars['String']>;
  readonly pickupStartDateTime?: InputMaybe<Scalars['String']>;
  readonly pickupEndDateTime?: InputMaybe<Scalars['String']>;
  readonly deliveryStartDateTime?: InputMaybe<Scalars['String']>;
  readonly deliveryEndDateTime?: InputMaybe<Scalars['String']>;
  readonly isDriverTargetDatetime?: InputMaybe<Scalars['Boolean']>;
  readonly scheduleType?: InputMaybe<Scalars['String']>;
  readonly isAppointmentConfirmed?: InputMaybe<Scalars['Boolean']>;
  readonly isDriverTargetDateTimePickup?: InputMaybe<Scalars['Boolean']>;
  readonly scheduleTypePickup?: InputMaybe<Scalars['String']>;
  readonly isAppointmentConfirmedPickup?: InputMaybe<Scalars['Boolean']>;
  readonly isDriverTargetDateTimeDelivery?: InputMaybe<Scalars['Boolean']>;
  readonly scheduleTypeDelivery?: InputMaybe<Scalars['String']>;
  readonly isAppointmentConfirmedDelivery?: InputMaybe<Scalars['Boolean']>;
  readonly orderId: Scalars['ID'];
  readonly orderNumber?: InputMaybe<Scalars['String']>;
  readonly orderCode: Scalars['String'];
  readonly expectedWeight?: InputMaybe<Scalars['String']>;
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly powerCode?: InputMaybe<Scalars['String']>;
  readonly carrierId: Scalars['ID'];
  readonly carrierCode: Scalars['String'];
  readonly tractorNumber?: InputMaybe<Scalars['String']>;
};

export type CreatePreplanResponse = {
  readonly __typename?: 'CreatePreplanResponse';
  readonly preplan?: Maybe<Preplan>;
  readonly hasErrors?: Maybe<Scalars['Boolean']>;
  readonly errors?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type CreateRateConfirmationPayload = {
  readonly __typename?: 'CreateRateConfirmationPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly rateConfirmation: RateConfirmation;
};

export type CreateRoutingGuideInputV2 = {
  readonly routingGuideTypeTerm: Scalars['String'];
  readonly laneId: Scalars['ID'];
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly duration?: InputMaybe<Scalars['Int']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive: Scalars['Boolean'];
  readonly createdBy: Scalars['ID'];
};

export type CreateRoutingGuideSequenceItemInput = {
  readonly routingGuideId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly scac: Scalars['String'];
  readonly createdBy: Scalars['ID'];
  readonly tenderOptions?: InputMaybe<SequenceItemTenderOptionsInput>;
};

export type CreateScheduleInput = {
  /** The field `appointmentType` is deprecated. Use `appointmentTypes` instead. */
  readonly appointmentType: ScheduleAppointmentTypeEnum;
  readonly appointmentTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly serviceType?: InputMaybe<Scalars['String']>;
  /** The field `dropType` is deprecated. Use `dropTypes` instead. */
  readonly dropType: ScheduleDropTypeEnum;
  readonly dropTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equipmentTypeId?: InputMaybe<Scalars['ID']>;
  readonly friday: ScheduleEntryInputV2;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly facilityId: Scalars['ID'];
  /** The field `modeType` is deprecated. Use `routeTransportModeId` instead. */
  readonly modeType: ScheduleModeTypeEnum;
  readonly monday: ScheduleEntryInputV2;
  readonly saturday: ScheduleEntryInputV2;
  readonly scheduleType: ScheduleTypeEnum;
  readonly sunday: ScheduleEntryInputV2;
  readonly thursday: ScheduleEntryInputV2;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly tuesday: ScheduleEntryInputV2;
  readonly wednesday: ScheduleEntryInputV2;
  /** The field `workType` is deprecated. Use `workTypes` instead. */
  readonly workType: ScheduleWorkTypeEnum;
  readonly workTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** The field `loadSizeId` is deprecated. Use `loadSizes` instead. */
  readonly loadSizeId?: InputMaybe<Scalars['ID']>;
  readonly routeTransportModeId?: InputMaybe<Scalars['ID']>;
  /** The field `temperature` is deprecated. Use `temperatures` instead. */
  readonly temperature?: InputMaybe<Scalars['String']>;
  readonly temperatures?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** The field `scheduleTypeNullable` is deprecated. Use `scheduleTypes` instead. */
  readonly scheduleTypeNullable?: InputMaybe<ScheduleTypeEnum>;
  readonly scheduleTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** The field `trailerTypeIdNullable` is deprecated. Use `trailerTypes` instead. */
  readonly trailerTypeIdNullable?: InputMaybe<Scalars['ID']>;
  readonly trailerTypes?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly loadSizes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** An input type for the createDriversLicenseToken mutation, requires a list of driver's license entities. */
export type CreateSecureDataDriversLicenseTokensInput = {
  /** Transaction ID to associate with the request. If none provided, one will be returned that is generated from Basis Theory. */
  readonly correlationId?: InputMaybe<Scalars['ID']>;
  /** List of tuples with driver's license ID, driver code, and optional driver's license state. */
  readonly driversLicenseEntities: ReadonlyArray<SecureDataDriversLicenseEntityInput>;
};

export type CreateServiceProfileInput = {
  readonly carrierId: Scalars['String'];
  readonly originRamp: Scalars['String'];
  readonly destinationRamp: Scalars['String'];
  readonly train?: InputMaybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly isCOFC?: InputMaybe<Scalars['Boolean']>;
  readonly isTOFC?: InputMaybe<Scalars['Boolean']>;
  readonly publishedTransitDays: Scalars['Float'];
  readonly reqOrigin?: InputMaybe<Scalars['Boolean']>;
  readonly reqLane?: InputMaybe<Scalars['Boolean']>;
  readonly boxType?: InputMaybe<Scalars['String']>;
  readonly laneStartDate: Scalars['DateTime'];
  readonly laneExpirationDate: Scalars['DateTime'];
  readonly laneExpirationDateV2?: InputMaybe<Scalars['DateTime']>;
  readonly schedules: BaseSchedulesInput;
};

export type CreateServiceProfileInputV2 = {
  readonly carrierId: Scalars['String'];
  readonly originRamp: Scalars['String'];
  readonly destinationRamp: Scalars['String'];
  readonly train?: InputMaybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly isCOFC?: InputMaybe<Scalars['Boolean']>;
  readonly isTOFC?: InputMaybe<Scalars['Boolean']>;
  readonly publishedTransitDays: Scalars['Float'];
  readonly reqOrigin?: InputMaybe<Scalars['Boolean']>;
  readonly reqLane?: InputMaybe<Scalars['Boolean']>;
  readonly boxType?: InputMaybe<Scalars['String']>;
  readonly laneStartDate: Scalars['DateTime'];
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly laneExpirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly schedules?: InputMaybe<BaseSchedulesInputV2>;
  readonly facilityTypeForOriginRamp: Scalars['String'];
  readonly facilityTypeForDestinationRamp: Scalars['String'];
};

export type CreateSpotQuoteInput = {
  readonly customerId: Scalars['ID'];
  /** The Minion transportMode's OptionId */
  readonly transportMode: Scalars['String'];
  /** The Minion loadSize's OptionId */
  readonly loadSize: Scalars['String'];
  readonly stops: ReadonlyArray<SpotQuoteStopInput>;
  readonly loadCount: Scalars['Int'];
  /** The Minion trailerType's OptionIds */
  readonly equipments: ReadonlyArray<Scalars['String']>;
  /** Length in feet */
  readonly length?: InputMaybe<Scalars['Int']>;
  /** Width in inches */
  readonly width?: InputMaybe<Scalars['Int']>;
  /** Height in inches */
  readonly height?: InputMaybe<Scalars['Int']>;
  readonly effectiveDate: Scalars['Date'];
  readonly effectiveDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate: Scalars['Date'];
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly shipStartDate: Scalars['Date'];
  readonly shipEndDate: Scalars['Date'];
  readonly desiredDeliveryDate?: InputMaybe<Scalars['Date']>;
  /** The Minion division's OptionId */
  readonly division?: InputMaybe<Scalars['String']>;
  /** The Minion project's OptionId */
  readonly project?: InputMaybe<Scalars['String']>;
  /** The Minion businessUnit's OptionId */
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  /** The Minion serviceLevel's OptionId */
  readonly serviceLevel: Scalars['String'];
  readonly quotedBy: Scalars['ID'];
  /** Optional Customer Contact */
  readonly quotedTo?: InputMaybe<Scalars['ID']>;
  /** The Minion tenantScac's OptionId */
  readonly scac?: InputMaybe<Scalars['String']>;
  readonly externalQuoteId?: InputMaybe<Scalars['String']>;
  readonly multiStop?: InputMaybe<Scalars['Boolean']>;
  /** The Minion orderRequirement's OptionIds */
  readonly requirements: ReadonlyArray<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly rateLineItems?: InputMaybe<ReadonlyArray<SpotQuoteRateLineItemInput>>;
  /** The user saving the spot quote */
  readonly savedBy: Scalars['ID'];
  /** The IANA timezone for the effective and expiration dates */
  readonly timezone: Scalars['String'];
  readonly laneType?: InputMaybe<Scalars['String']>;
  readonly quoteDetails?: InputMaybe<ReadonlyArray<SpotQuoteQuoteDetailInput>>;
  readonly commodities?: InputMaybe<ReadonlyArray<SpotQuoteCommodityInput>>;
  readonly isVariableDistanceType?: InputMaybe<Scalars['Boolean']>;
  readonly customDistance?: InputMaybe<Scalars['Decimal']>;
  /** The minion distanceType's OptionId */
  readonly customDistanceUnit?: InputMaybe<Scalars['String']>;
  readonly quoteFuelConfiguration?: InputMaybe<Scalars['String']>;
};

export type CreateSpotQuotePayload = {
  readonly __typename?: 'CreateSpotQuotePayload';
  readonly data?: Maybe<SpotQuote>;
  readonly errors?: Maybe<ReadonlyArray<SpotQuoteError>>;
};

export type CreateTaskInputV2 = {
  readonly name: Scalars['String'];
  readonly type: Scalars['String'];
  readonly status: Scalars['String'];
  readonly routeId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly requestedBy: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly neededBy?: InputMaybe<Scalars['DateTime']>;
  readonly assignedTo?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly noteName?: InputMaybe<Scalars['String']>;
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly groupId?: InputMaybe<Scalars['String']>;
  readonly assignedToGroupId?: InputMaybe<Scalars['String']>;
  readonly externalId?: InputMaybe<Scalars['String']>;
  readonly comment?: InputMaybe<Scalars['String']>;
};

export type CreateTrackingUpdateInput = {
  readonly trackingUpdates: ReadonlyArray<TrackingUpdatesInput>;
};

export type CreateTrackingUpdatePayload = {
  readonly __typename?: 'CreateTrackingUpdatePayload';
  readonly trackingUpdateStatus: Scalars['Boolean'];
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateTrackingUpdateV2Input = {
  readonly driverId: Scalars['ID'];
  readonly trackingUpdates: ReadonlyArray<TrackingUpdatesInput>;
};

export type CreateTrackingUpdateV2Payload = {
  readonly __typename?: 'CreateTrackingUpdateV2Payload';
  readonly trackingUpdateSuccess: Scalars['Boolean'];
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateTripInput = {
  readonly driverIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routes?: InputMaybe<ReadonlyArray<RouteInput>>;
};

export type CreateTripOutput = {
  readonly __typename?: 'CreateTripOutput';
  readonly createdTrip?: Maybe<TripType>;
};

export type CreateTruckEntryFromDriverAssignmentInput = {
  readonly carrierId: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly driver2Name?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originLocation?: InputMaybe<TruckLocationInput>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  /** The Power (Tractor) unit ID associated with the capacity */
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly powerNumber?: InputMaybe<Scalars['String']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  readonly bracingType?: InputMaybe<BracingTypesEnum>;
  readonly bracingCount?: InputMaybe<Scalars['Float']>;
  readonly bracingTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Provide time in milliseconds. */
  readonly breakTimeMs?: InputMaybe<Scalars['Float']>;
  /** Provide time in milliseconds. Cannot exceed 70 hours */
  readonly cycleTimeMs?: InputMaybe<Scalars['Float']>;
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly driverDomicile?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly driverPhone?: InputMaybe<Scalars['String']>;
  readonly driver2Domicile?: InputMaybe<Scalars['String']>;
  readonly driver2Id?: InputMaybe<Scalars['ID']>;
  readonly driver2Phone?: InputMaybe<Scalars['String']>;
  /** Provide time in milliseconds. Cannot exceed 14 hours */
  readonly driveTimeMs?: InputMaybe<Scalars['Float']>;
  readonly externalSourceId?: InputMaybe<Scalars['String']>;
  /** Truck's finalBy datetime. Value is nullified if the finalByDatetime value is less than the loadByDatetime value. */
  readonly finalByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly finalByTimezone?: InputMaybe<Scalars['String']>;
  /** Truck's loadBy datetime, defaults to the readyDatetimeUtc (EOD) if a value is not provided or if the loadByDatetimeUtc value is less than the readyDatetimeUtc value */
  readonly loadByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly loadByTimezone?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly palletsType?: InputMaybe<PalletTypesEnum>;
  readonly palletsCount?: InputMaybe<Scalars['Float']>;
  readonly palletTypeId?: InputMaybe<Scalars['String']>;
  /** DEPRECATED: Use previousRouteIdV2 instead */
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  /** Provide time in milliseconds. Cannot exceed 16 hours */
  readonly shiftTimeMs?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED: Use sourceTypeId instead */
  readonly sourceType?: InputMaybe<SourceTypesEnum>;
  readonly tarpsType?: InputMaybe<TarpTypesEnum>;
  readonly tarpsCount?: InputMaybe<Scalars['Float']>;
  readonly tarpTypeId?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly truckEntryTemplateId?: InputMaybe<Scalars['ID']>;
  /** DDT ID for the capacity source of the projected hours of service */
  readonly phosSourceId?: InputMaybe<Scalars['String']>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly previousRouteIdV2: Scalars['ID'];
  readonly readyDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly reloadIntentId: Scalars['String'];
  readonly sourceTypeId?: InputMaybe<Scalars['String']>;
  readonly trailerTypeId: Scalars['ID'];
  readonly updatedByUserId?: InputMaybe<Scalars['ID']>;
};

export type CreateTruckEntryInput = {
  readonly carrierId: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly driver2Name?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originLocation?: InputMaybe<TruckLocationInput>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  /** The Power (Tractor) unit ID associated with the capacity */
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly powerNumber?: InputMaybe<Scalars['String']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId: Scalars['ID'];
  readonly bracingType?: InputMaybe<BracingTypesEnum>;
  readonly bracingCount?: InputMaybe<Scalars['Float']>;
  readonly bracingTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Provide time in milliseconds. */
  readonly breakTimeMs?: InputMaybe<Scalars['Float']>;
  /** Provide time in milliseconds. Cannot exceed 70 hours */
  readonly cycleTimeMs?: InputMaybe<Scalars['Float']>;
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly driverDomicile?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly driverPhone?: InputMaybe<Scalars['String']>;
  readonly driver2Domicile?: InputMaybe<Scalars['String']>;
  readonly driver2Id?: InputMaybe<Scalars['ID']>;
  readonly driver2Phone?: InputMaybe<Scalars['String']>;
  /** Provide time in milliseconds. Cannot exceed 14 hours */
  readonly driveTimeMs?: InputMaybe<Scalars['Float']>;
  readonly externalSourceId?: InputMaybe<Scalars['String']>;
  /** Truck's finalBy datetime. Value is nullified if the finalByDatetime value is less than the loadByDatetime value. */
  readonly finalByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly finalByTimezone?: InputMaybe<Scalars['String']>;
  /** Truck's loadBy datetime, defaults to the readyDatetimeUtc (EOD) if a value is not provided or if the loadByDatetimeUtc value is less than the readyDatetimeUtc value */
  readonly loadByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly loadByTimezone?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly palletsType?: InputMaybe<PalletTypesEnum>;
  readonly palletsCount?: InputMaybe<Scalars['Float']>;
  readonly palletTypeId?: InputMaybe<Scalars['String']>;
  /** DEPRECATED: Use previousRouteIdV2 instead */
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  readonly previousRouteIdV2?: InputMaybe<Scalars['ID']>;
  readonly readyDatetimeUtc: Scalars['ISO8601DateTime'];
  /** Provide time in milliseconds. Cannot exceed 16 hours */
  readonly shiftTimeMs?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED: Use sourceTypeId instead */
  readonly sourceType?: InputMaybe<SourceTypesEnum>;
  readonly sourceTypeId?: InputMaybe<Scalars['String']>;
  readonly tarpsType?: InputMaybe<TarpTypesEnum>;
  readonly tarpsCount?: InputMaybe<Scalars['Float']>;
  readonly tarpTypeId?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly truckEntryTemplateId?: InputMaybe<Scalars['ID']>;
  /** DDT ID for the capacity source of the projected hours of service */
  readonly phosSourceId?: InputMaybe<Scalars['String']>;
};

export type CreateTruckEntryTemplateInput = {
  readonly carrierId: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly driver2Name?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originLocation: TruckLocationInput;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  /** The Power (Tractor) unit ID associated with the capacity */
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly powerNumber?: InputMaybe<Scalars['String']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId: Scalars['ID'];
  readonly fridayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly mondayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly saturdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly status?: InputMaybe<TruckEntryTemplateStatus>;
  readonly sourceType?: InputMaybe<TruckEntryTemplateSourceTypesEnum>;
  readonly sundayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly thursdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly tuesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly wednesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  /** Should be the ID of an AssetDriver record. */
  readonly driverId?: InputMaybe<Scalars['ID']>;
  /** Should be the ID of an AssetDriver record. */
  readonly driver2Id?: InputMaybe<Scalars['ID']>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
};

export type CreateTruckEntryTemplateInputV2 = {
  readonly carrierId: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly driver2Name?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originLocation: TruckLocationInput;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  /** The Power (Tractor) unit ID associated with the capacity */
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly powerNumber?: InputMaybe<Scalars['String']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerTypeId: Scalars['ID'];
  readonly updatedByUserId: Scalars['ID'];
  readonly fridayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly mondayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly saturdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly status?: InputMaybe<TruckEntryTemplateStatus>;
  readonly sourceType?: InputMaybe<TruckEntryTemplateSourceTypesEnum>;
  readonly sundayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly thursdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly tuesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly wednesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  /** Should be the ID of an AssetDriver record. */
  readonly driverId?: InputMaybe<Scalars['ID']>;
  /** Should be the ID of an AssetDriver record. */
  readonly driver2Id?: InputMaybe<Scalars['ID']>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
};

export type CreateUserInput = {
  readonly userID: Scalars['ID'];
  readonly userName?: InputMaybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
};

export type CreateWorkflowError = {
  readonly __typename?: 'CreateWorkflowError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type CreateWorkflowJob = {
  readonly __typename?: 'CreateWorkflowJob';
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  /** JSON value of mapped RouteDetails */
  readonly routeDetails: Scalars['JSON'];
};

export type CreateWorkflowJobPayLoad = {
  readonly __typename?: 'CreateWorkflowJobPayLoad';
  readonly errors?: Maybe<ReadonlyArray<CreateWorkflowError>>;
  readonly createWorkFlowEvent?: Maybe<CreateWorkflowJob>;
};

/** Credentials model for provider */
export type Credential = {
  readonly __typename?: 'Credential';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  /** @deprecated Do not use this property as it would be removed in future and currently it returns only null value */
  readonly value?: Maybe<Scalars['String']>;
  readonly retrievedValueFromDatabase?: Maybe<Scalars['String']>;
  readonly isReadFromDatabase: Scalars['Boolean'];
};

export type CredentialAuthenticationItemObject = {
  readonly __typename?: 'CredentialAuthenticationItemObject';
  readonly id?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly authType?: Maybe<Scalars['String']>;
  readonly layoutPriority?: Maybe<Scalars['Float']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly isOptional?: Maybe<Scalars['Boolean']>;
  readonly readOnly?: Maybe<Scalars['Boolean']>;
  readonly canCopy?: Maybe<Scalars['Boolean']>;
  readonly hide?: Maybe<Scalars['Boolean']>;
  readonly tabName?: Maybe<Scalars['String']>;
  readonly toolTipText?: Maybe<Scalars['String']>;
  readonly values?: Maybe<ReadonlyArray<ValuesItemObject>>;
  readonly uiElement?: Maybe<Scalars['String']>;
  readonly attributeType?: Maybe<Scalars['String']>;
  readonly validationRegex?: Maybe<Scalars['String']>;
};

export type CreditAvailability = {
  readonly __typename?: 'CreditAvailability';
  readonly approved: Scalars['Boolean'];
  readonly message: Scalars['String'];
  readonly details?: Maybe<Scalars['String']>;
};

export type CreditHistory = {
  readonly __typename?: 'CreditHistory';
  readonly createdAt: Scalars['Iso8601Date'];
  readonly createdBy: User;
  readonly creditLimit?: Maybe<Scalars['Float']>;
  readonly currency?: Maybe<KeyValue>;
  readonly customerId: Scalars['ID'];
  readonly expiration?: Maybe<Scalars['Iso8601Date']>;
  readonly expirationDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly id: Scalars['ID'];
  readonly note?: Maybe<Scalars['String']>;
  readonly status: CreditStatusEnum;
};

export type CreditHistoryInput = {
  readonly creditLimit?: InputMaybe<Scalars['Float']>;
  readonly currencyId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly expiration?: InputMaybe<Scalars['ISO8601Date']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly limit?: InputMaybe<Scalars['Float']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly status: Scalars['String'];
};

export type CreditHistoryInputV2 = {
  readonly creditLimit?: InputMaybe<Scalars['Float']>;
  readonly currencyId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly expiration?: InputMaybe<Scalars['ISO8601Date']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly limit?: InputMaybe<Scalars['Float']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly canUseParentCredit?: InputMaybe<Scalars['Boolean']>;
  readonly parentCustomerId?: InputMaybe<Scalars['String']>;
};

export type CreditHistoryV2 = {
  readonly __typename?: 'CreditHistoryV2';
  readonly createdAt: Scalars['Iso8601Date'];
  readonly createdById?: Maybe<Scalars['String']>;
  readonly createdBy: UserV2;
  readonly creditLimit?: Maybe<Scalars['Float']>;
  readonly currency?: Maybe<KeyValue>;
  readonly currencyId?: Maybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly expiration?: Maybe<Scalars['Iso8601Date']>;
  readonly expirationDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly id: Scalars['ID'];
  readonly note?: Maybe<Scalars['String']>;
  readonly status: CreditStatusEnum;
  readonly canUseParentCredit?: Maybe<Scalars['Boolean']>;
};

export type CreditInvoiceHeader = {
  readonly id: Scalars['ID'];
  readonly statusId: StatusEnum;
  readonly currentAmountDue: Scalars['Float'];
};

export type CreditRateDetails = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly totalRate: Scalars['Float'];
  readonly statusId: StatusEnum;
};

export type CreditResponse = {
  readonly __typename?: 'CreditResponse';
  readonly approved: Scalars['Boolean'];
  readonly message: Scalars['String'];
  readonly details?: Maybe<Scalars['String']>;
};

export enum CreditStatusEnum {
  /** Approved */
  Approved = 'Approved',
  /** Hold */
  Hold = 'Hold',
  /** Denied */
  Denied = 'Denied',
  /** Pending */
  Pending = 'Pending'
}

export type CrmCarrierDetail = {
  readonly __typename?: 'CRMCarrierDetail';
  readonly id?: Maybe<Scalars['ID']>;
  readonly interactionFrequencyTypeId?: Maybe<Scalars['ID']>;
  readonly leadNotes?: Maybe<Scalars['String']>;
  readonly leadSourceTypeId?: Maybe<Scalars['ID']>;
  readonly vendorId?: Maybe<Scalars['ID']>;
};

export type CrmCarrierDetailInputType = {
  readonly interactionFrequencyTypeId?: InputMaybe<Scalars['ID']>;
  readonly leadNotes?: InputMaybe<Scalars['String']>;
  readonly leadSourceTypeId?: InputMaybe<Scalars['ID']>;
  readonly vendorId: Scalars['ID'];
};

export type CrmCarrierDocumentsInput = {
  readonly vendorId?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly documentId?: InputMaybe<Scalars['String']>;
  readonly documentName?: InputMaybe<Scalars['String']>;
  readonly documentType?: InputMaybe<Scalars['String']>;
  readonly fileName?: InputMaybe<Scalars['String']>;
  readonly source?: InputMaybe<Scalars['String']>;
  readonly direction?: InputMaybe<Scalars['String']>;
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly crmEntityCarrierContactInput?: InputMaybe<ReadonlyArray<CrmEntityCarrierContactInput>>;
  readonly crmEntityCarrierOpportunityInput?: InputMaybe<ReadonlyArray<CrmEntityCarrierOpportunityInput>>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmCarrierDocumentsOutput = {
  readonly __typename?: 'CrmCarrierDocumentsOutput';
  readonly id: Scalars['ID'];
  readonly vendorId?: Maybe<Scalars['String']>;
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly documentId?: Maybe<Scalars['String']>;
  readonly documentName?: Maybe<Scalars['String']>;
  readonly documentType?: Maybe<Scalars['String']>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly direction?: Maybe<Scalars['String']>;
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly crmEntityCarrierContactOutput?: Maybe<ReadonlyArray<CrmEntityCarrierContactOutput>>;
  readonly crmEntityCarrierOpportunityOutput?: Maybe<ReadonlyArray<CrmEntityCarrierOpportunityOutput>>;
  readonly createdBy?: Maybe<UserV2>;
  readonly updatedBy?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmCarrierExportNoteOutput = {
  readonly __typename?: 'CrmCarrierExportNoteOutput';
  readonly id: Scalars['ID'];
  readonly entityId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<UserV2>;
  readonly updatedBy?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmCarrierNotesInput = {
  readonly vendorId?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Scalars['String']>;
  readonly crmEntityCarrierContactInput?: InputMaybe<ReadonlyArray<CrmEntityCarrierContactInput>>;
  readonly crmEntityCarrierRepInput?: InputMaybe<ReadonlyArray<CrmEntityCarrierRepInput>>;
  readonly crmEntityCarrierOpportunityInput?: InputMaybe<ReadonlyArray<CrmEntityCarrierOpportunityInput>>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmCarrierNotesOutput = {
  readonly __typename?: 'CrmCarrierNotesOutput';
  readonly id: Scalars['ID'];
  readonly vendorId?: Maybe<Scalars['String']>;
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly crmEntityCarrierContactOutput?: Maybe<ReadonlyArray<CrmEntityCarrierContactOutput>>;
  readonly crmEntityCarrierRepOutput?: Maybe<ReadonlyArray<CrmEntityCarrierRepOutput>>;
  readonly crmEntityCarrierOpportunityOutput?: Maybe<ReadonlyArray<CrmEntityCarrierOpportunityOutput>>;
  readonly createdBy?: Maybe<UserV2>;
  readonly updatedBy?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmCompanyDescriptionInput = {
  readonly companyDescriptionText?: InputMaybe<Scalars['String']>;
  readonly crmDetailsEntityLookupId?: InputMaybe<Scalars['String']>;
  readonly entityId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmCompanyDescriptionOutput = {
  readonly __typename?: 'CrmCompanyDescriptionOutput';
  readonly companyDescriptionId: Scalars['ID'];
  readonly companyDescriptionText?: Maybe<Scalars['String']>;
  readonly crmDetailsEntityLookupId?: Maybe<Scalars['String']>;
  readonly entityId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAdditionalInfoInput = {
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly riskInfoSource?: InputMaybe<Scalars['String']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly newContactId?: InputMaybe<Scalars['String']>;
  readonly rmContactId?: InputMaybe<Scalars['String']>;
  readonly decisionCase?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAdditionalInfoOutput = {
  readonly __typename?: 'CrmContactAdditionalInfoOutput';
  readonly contactAdditionalInfoId: Scalars['ID'];
  readonly riskInfoUpdatedAt?: Maybe<Scalars['DateTime']>;
  readonly riskInfoSource?: Maybe<Scalars['String']>;
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly businessUnit?: Maybe<Scalars['String']>;
  readonly newContactId?: Maybe<Scalars['String']>;
  readonly rmContactId?: Maybe<Scalars['String']>;
  readonly decisionCase?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedEntityFunctionInput = {
  readonly associatedEntityId?: InputMaybe<Scalars['String']>;
  readonly functionValueTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedEntityFunctionInputV2 = {
  readonly Contact_Associated_Entity_Id?: InputMaybe<Scalars['String']>;
  readonly Function_ValueTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedEntityFunctionOutput = {
  readonly __typename?: 'CrmContactAssociatedEntityFunctionOutput';
  readonly associatedEntityFunctionId: Scalars['ID'];
  readonly associatedEntityId?: Maybe<Scalars['String']>;
  readonly functionValueTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedEntityFunctionOutputV2 = {
  readonly __typename?: 'CrmContactAssociatedEntityFunctionOutputV2';
  readonly Contact_Associated_Entity_Function_Id: Scalars['ID'];
  readonly Contact_Associated_Entity_Id?: Maybe<Scalars['String']>;
  readonly Function_ValueTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedEntityInput = {
  readonly contactId?: InputMaybe<Scalars['String']>;
  readonly entityId?: InputMaybe<Scalars['String']>;
  readonly entityTypeTerm?: InputMaybe<Scalars['String']>;
  readonly entityLookup?: InputMaybe<Scalars['String']>;
  readonly entityName?: InputMaybe<Scalars['String']>;
  readonly mainPhone?: InputMaybe<Scalars['String']>;
  readonly mainEmail?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly statusTerm?: InputMaybe<Scalars['String']>;
  readonly statusReasonTerm?: InputMaybe<Scalars['String']>;
  readonly isMain?: InputMaybe<Scalars['Boolean']>;
  readonly isExtUser?: InputMaybe<Scalars['Boolean']>;
  readonly location?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly externalSystemUserTerm?: InputMaybe<Scalars['String']>;
  readonly functions?: InputMaybe<ReadonlyArray<CrmContactAssociatedEntityFunctionInput>>;
  readonly responsibilityAssociatedEntities?: InputMaybe<ReadonlyArray<CrmContactResponsibilityAssociatedEntityInput>>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedEntityInputV2 = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Entity_Id?: InputMaybe<Scalars['String']>;
  readonly Entity_TypeTerm?: InputMaybe<Scalars['String']>;
  readonly Entity_Lookup?: InputMaybe<Scalars['String']>;
  readonly Entity_Name?: InputMaybe<Scalars['String']>;
  readonly Main_Phone?: InputMaybe<Scalars['String']>;
  readonly Main_Email?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly StatusTerm?: InputMaybe<Scalars['String']>;
  readonly Status_ReasonTerm?: InputMaybe<Scalars['String']>;
  readonly Is_Main?: InputMaybe<Scalars['Boolean']>;
  readonly Is_Ext_User?: InputMaybe<Scalars['Boolean']>;
  readonly Location?: InputMaybe<Scalars['String']>;
  readonly Is_Active?: InputMaybe<Scalars['Boolean']>;
  readonly crm_Contact_Associated_Entity_Function?: InputMaybe<ReadonlyArray<CrmContactAssociatedEntityFunctionInputV2>>;
  readonly crm_Contact_Responsibility_Associated_Entity?: InputMaybe<ReadonlyArray<CrmContactResponsibilityAssociatedEntityInputV2>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedEntityOutput = {
  readonly __typename?: 'CrmContactAssociatedEntityOutput';
  readonly associatedEntityId: Scalars['ID'];
  readonly contactId?: Maybe<Scalars['String']>;
  readonly entityId?: Maybe<Scalars['String']>;
  readonly entityTypeTerm?: Maybe<Scalars['String']>;
  readonly entityLookup?: Maybe<Scalars['String']>;
  readonly entityName?: Maybe<Scalars['String']>;
  readonly mainPhone?: Maybe<Scalars['String']>;
  readonly mainEmail?: Maybe<Scalars['String']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly statusTerm?: Maybe<Scalars['String']>;
  readonly statusReasonTerm?: Maybe<Scalars['String']>;
  readonly isMain?: Maybe<Scalars['Boolean']>;
  readonly isExtUser?: Maybe<Scalars['Boolean']>;
  readonly location?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly externalSystemUserTerm?: Maybe<Scalars['String']>;
  readonly functions?: Maybe<ReadonlyArray<CrmContactAssociatedEntityFunctionOutput>>;
  readonly responsibilityAssociatedEntities?: Maybe<ReadonlyArray<CrmContactResponsibilityAssociatedEntityOutput>>;
  readonly phone?: Maybe<CrmContactInformationOutput>;
  readonly email?: Maybe<CrmContactInformationOutput>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedEntityOutputV2 = {
  readonly __typename?: 'CrmContactAssociatedEntityOutputV2';
  readonly Contact_Associated_Entity_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Entity_Id?: Maybe<Scalars['String']>;
  readonly Entity_TypeTerm?: Maybe<Scalars['String']>;
  readonly Entity_Lookup?: Maybe<Scalars['String']>;
  readonly Entity_Name?: Maybe<Scalars['String']>;
  readonly Main_Phone?: Maybe<Scalars['String']>;
  readonly Main_Email?: Maybe<Scalars['String']>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly StatusTerm?: Maybe<Scalars['String']>;
  readonly Status_ReasonTerm?: Maybe<Scalars['String']>;
  readonly Is_Main?: Maybe<Scalars['Boolean']>;
  readonly Is_Ext_User?: Maybe<Scalars['Boolean']>;
  readonly Location?: Maybe<Scalars['String']>;
  readonly Is_Active?: Maybe<Scalars['Boolean']>;
  readonly crm_Contact_Associated_Entity_Function?: Maybe<ReadonlyArray<CrmContactAssociatedEntityFunctionOutputV2>>;
  readonly crm_Contact_Responsibility_Associated_Entity?: Maybe<ReadonlyArray<CrmContactResponsibilityAssociatedEntityOutputV2>>;
  readonly crm_Contact_Associated_Entity_Phone?: Maybe<CrmContactInformationOutputV2>;
  readonly crm_Contact_Associated_Entity_Email?: Maybe<CrmContactInformationOutputV2>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepInput = {
  readonly contactId?: InputMaybe<Scalars['String']>;
  readonly rep?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly repId?: InputMaybe<Scalars['String']>;
  readonly repRole?: InputMaybe<Scalars['String']>;
  readonly relationships?: InputMaybe<ReadonlyArray<CrmContactAssociatedRepRelationshipInput>>;
  readonly responsibilities?: InputMaybe<ReadonlyArray<CrmContactAssociatedRepResponsibilityInput>>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepInputV2 = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Rep?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly Is_Active?: InputMaybe<Scalars['Boolean']>;
  readonly Rep_Id?: InputMaybe<Scalars['String']>;
  readonly Rep_Role?: InputMaybe<Scalars['String']>;
  readonly crm_Contact_Associated_Rep_Relationship?: InputMaybe<ReadonlyArray<CrmContactAssociatedRepRelationshipInputV2>>;
  readonly crm_Contact_Associated_Rep_Responsibility?: InputMaybe<ReadonlyArray<CrmContactAssociatedRepResponsibilityInputV2>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepOutput = {
  readonly __typename?: 'CrmContactAssociatedRepOutput';
  readonly associatedRepId: Scalars['ID'];
  readonly contactId?: Maybe<Scalars['String']>;
  readonly rep?: Maybe<Scalars['String']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly repId?: Maybe<Scalars['String']>;
  readonly repRole?: Maybe<Scalars['String']>;
  readonly relationships?: Maybe<ReadonlyArray<CrmContactAssociatedRepRelationshipOutput>>;
  readonly responsibilities?: Maybe<ReadonlyArray<CrmContactAssociatedRepResponsibilityOutput>>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepOutputV2 = {
  readonly __typename?: 'CrmContactAssociatedRepOutputV2';
  readonly Contact_Associated_Rep_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Rep?: Maybe<Scalars['String']>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly Is_Active?: Maybe<Scalars['Boolean']>;
  readonly Rep_Id?: Maybe<Scalars['String']>;
  readonly Rep_Role?: Maybe<Scalars['String']>;
  readonly crm_Contact_Associated_Rep_Relationship?: Maybe<ReadonlyArray<CrmContactAssociatedRepRelationshipOutputV2>>;
  readonly crm_Contact_Associated_Rep_Responsibility?: Maybe<ReadonlyArray<CrmContactAssociatedRepResponsibilityOutputV2>>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepRelationshipInput = {
  readonly associatedRepId?: InputMaybe<Scalars['String']>;
  readonly relationshipValueTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepRelationshipInputV2 = {
  readonly Contact_Associated_Rep_Id?: InputMaybe<Scalars['String']>;
  readonly Relationship_ValueTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepRelationshipOutput = {
  readonly __typename?: 'CrmContactAssociatedRepRelationshipOutput';
  readonly associatedRepRelationshipId: Scalars['ID'];
  readonly associatedRepId?: Maybe<Scalars['String']>;
  readonly relationshipValueTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepRelationshipOutputV2 = {
  readonly __typename?: 'CrmContactAssociatedRepRelationshipOutputV2';
  readonly Contact_Associated_Rep_Relationship_Id: Scalars['ID'];
  readonly Contact_Associated_Rep_Id?: Maybe<Scalars['String']>;
  readonly Relationship_ValueTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepResponsibilityInput = {
  readonly associatedRepId?: InputMaybe<Scalars['String']>;
  readonly responsibilityId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepResponsibilityInputV2 = {
  readonly Contact_Associated_Rep_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepResponsibilityOutput = {
  readonly __typename?: 'CrmContactAssociatedRepResponsibilityOutput';
  readonly associatedRepResponsibilityId: Scalars['ID'];
  readonly associatedRepId?: Maybe<Scalars['String']>;
  readonly responsibilityId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepResponsibilityOutputV2 = {
  readonly __typename?: 'CrmContactAssociatedRepResponsibilityOutputV2';
  readonly Contact_Associated_Rep_Responsibility_Id: Scalars['ID'];
  readonly Contact_Associated_Rep_Id?: Maybe<Scalars['String']>;
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactDedupeExceptionListMappingInput = {
  readonly contactExceptionId?: InputMaybe<Scalars['String']>;
  readonly contactId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactDedupeExceptionListMappingOutput = {
  readonly __typename?: 'CrmContactDedupeExceptionListMappingOutput';
  readonly contactDedupeExceptionListMappingId: Scalars['ID'];
  readonly contactExceptionId?: Maybe<Scalars['String']>;
  readonly contactId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactDedupeExceptionListOutput = {
  readonly __typename?: 'CrmContactDedupeExceptionListOutput';
  readonly contactDedupeExceptionListId: Scalars['ID'];
  readonly contactObject?: Maybe<Scalars['String']>;
  readonly exceptionMapping?: Maybe<ReadonlyArray<CrmContactDedupeExceptionListMappingOutput>>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactEntityLookupOutput = {
  readonly __typename?: 'CrmContactEntityLookupOutput';
  readonly entityLookupId: Scalars['ID'];
  readonly entityType?: Maybe<Scalars['Float']>;
  readonly entityName?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactEntityLookupOutputV2 = {
  readonly __typename?: 'CrmContactEntityLookupOutputV2';
  readonly Contact_Entity_Lookup_Id: Scalars['ID'];
  readonly Entity_Type?: Maybe<Scalars['Float']>;
  readonly Entity_Name?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactIdPayload = {
  readonly __typename?: 'CrmContactIdPayload';
  readonly errors?: Maybe<ReadonlyArray<ContactError>>;
  readonly contactId?: Maybe<Scalars['String']>;
};

export type CrmContactInformationInput = {
  readonly contactId?: InputMaybe<Scalars['String']>;
  readonly typeId?: InputMaybe<Scalars['String']>;
  readonly labelTerm?: InputMaybe<Scalars['String']>;
  readonly countryCode?: InputMaybe<Scalars['String']>;
  readonly contactValue?: InputMaybe<Scalars['String']>;
  readonly ext?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly isPersonal?: InputMaybe<Scalars['Boolean']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly types?: InputMaybe<ReadonlyArray<CrmContactInformationTypeInput>>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactInformationInputV2 = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Information_Type_Id?: InputMaybe<Scalars['String']>;
  readonly LabelTerm?: InputMaybe<Scalars['String']>;
  readonly Country_Code?: InputMaybe<Scalars['String']>;
  readonly Contact_Value?: InputMaybe<Scalars['String']>;
  readonly Ext?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly Is_Personal?: InputMaybe<Scalars['Boolean']>;
  readonly Is_Active?: InputMaybe<Scalars['Boolean']>;
  readonly crm_Contact_Information_Type?: InputMaybe<ReadonlyArray<CrmContactInformationTypeInputV2>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactInformationOutput = {
  readonly __typename?: 'CrmContactInformationOutput';
  readonly informationId: Scalars['ID'];
  readonly contactId?: Maybe<Scalars['String']>;
  readonly informationTypeId?: Maybe<Scalars['String']>;
  readonly labelTerm?: Maybe<Scalars['String']>;
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly contactValue?: Maybe<Scalars['String']>;
  readonly ext?: Maybe<Scalars['String']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly isPersonal?: Maybe<Scalars['Boolean']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly types?: Maybe<ReadonlyArray<CrmContactInformationTypeOutput>>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactInformationOutputV2 = {
  readonly __typename?: 'CrmContactInformationOutputV2';
  readonly Contact_Information_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Contact_Information_Type_Id?: Maybe<Scalars['String']>;
  readonly LabelTerm?: Maybe<Scalars['String']>;
  readonly Country_Code?: Maybe<Scalars['String']>;
  readonly Contact_Value?: Maybe<Scalars['String']>;
  readonly Ext?: Maybe<Scalars['String']>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly Is_Personal?: Maybe<Scalars['Boolean']>;
  readonly Is_Active?: Maybe<Scalars['Boolean']>;
  readonly crm_Contact_Information_Type?: Maybe<ReadonlyArray<CrmContactInformationTypeOutputV2>>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactInformationTypeInput = {
  readonly informationTypeId?: InputMaybe<Scalars['Float']>;
  readonly Type?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactInformationTypeInputV2 = {
  readonly Contact_Type_Id?: InputMaybe<Scalars['Float']>;
  readonly Contact_Type?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactInformationTypeOutput = {
  readonly __typename?: 'CrmContactInformationTypeOutput';
  readonly informationTypeId: Scalars['ID'];
  readonly typeId?: Maybe<Scalars['Float']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactInformationTypeOutputV2 = {
  readonly __typename?: 'CrmContactInformationTypeOutputV2';
  readonly Contact_Information_Type_Id: Scalars['ID'];
  readonly Contact_Type_Id?: Maybe<Scalars['Float']>;
  readonly Contact_Type?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactInput = {
  readonly name?: InputMaybe<Scalars['String']>;
  readonly suffixTerm?: InputMaybe<Scalars['String']>;
  readonly pronounsTerm?: InputMaybe<Scalars['String']>;
  readonly preferredName?: InputMaybe<Scalars['String']>;
  readonly pronunciation?: InputMaybe<Scalars['String']>;
  readonly zoomInfoURL?: InputMaybe<Scalars['String']>;
  readonly zoomInfoNumber?: InputMaybe<Scalars['String']>;
  readonly linkedInURL?: InputMaybe<Scalars['String']>;
  readonly twitterURL?: InputMaybe<Scalars['String']>;
  readonly departmentTerm?: InputMaybe<Scalars['String']>;
  readonly title?: InputMaybe<Scalars['String']>;
  readonly levelTerm?: InputMaybe<Scalars['String']>;
  readonly primaryWorkLocation?: InputMaybe<Scalars['String']>;
  readonly workEnvironmentTerm?: InputMaybe<Scalars['String']>;
  readonly communicationPreferenceTerm?: InputMaybe<Scalars['String']>;
  readonly pricingBehaviorTerm?: InputMaybe<Scalars['String']>;
  readonly manager?: InputMaybe<Scalars['String']>;
  readonly hometown?: InputMaybe<Scalars['String']>;
  readonly currentLocation?: InputMaybe<Scalars['String']>;
  readonly primaryLanguageTerm?: InputMaybe<Scalars['String']>;
  readonly secondaryLanguageTerm?: InputMaybe<Scalars['String']>;
  readonly birthday?: InputMaybe<Scalars['DateTime']>;
  readonly personalNotes?: InputMaybe<Scalars['String']>;
  readonly externalUser?: InputMaybe<Scalars['String']>;
  readonly entityId?: InputMaybe<Scalars['String']>;
  readonly entityLookupId?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdSourceType?: InputMaybe<Scalars['Float']>;
  readonly isExceptionAvailable?: InputMaybe<Scalars['Boolean']>;
  readonly source?: InputMaybe<Scalars['String']>;
  readonly sourceTerm?: InputMaybe<Scalars['String']>;
  readonly information?: InputMaybe<ReadonlyArray<CrmContactInformationInput>>;
  readonly associatedEntities?: InputMaybe<ReadonlyArray<CrmContactAssociatedEntityInput>>;
  readonly associatedReps?: InputMaybe<ReadonlyArray<CrmContactAssociatedRepInput>>;
  readonly responsibilities?: InputMaybe<ReadonlyArray<CrmContactResponsibilityInput>>;
  readonly slider?: InputMaybe<ReadonlyArray<CrmContactSliderInput>>;
  readonly pricingBehaviors?: InputMaybe<ReadonlyArray<CrmContactPricingBehaviorInput>>;
  readonly interestHobbies?: InputMaybe<ReadonlyArray<CrmContactInterestHobbyInput>>;
  readonly additionalInfo?: InputMaybe<ReadonlyArray<CrmContactAdditionalInfoInput>>;
  readonly exceptionMapping?: InputMaybe<ReadonlyArray<CrmContactDedupeExceptionListMappingInput>>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactInputV2 = {
  readonly Name?: InputMaybe<Scalars['String']>;
  readonly SuffixTerm?: InputMaybe<Scalars['String']>;
  readonly PronounsTerm?: InputMaybe<Scalars['String']>;
  readonly Preferred_Name?: InputMaybe<Scalars['String']>;
  readonly Pronunciation?: InputMaybe<Scalars['String']>;
  readonly Zoom_Info_URL?: InputMaybe<Scalars['String']>;
  readonly Zoom_Info_Number?: InputMaybe<Scalars['String']>;
  readonly LinkedIn_URL?: InputMaybe<Scalars['String']>;
  readonly Twitter_URL?: InputMaybe<Scalars['String']>;
  readonly DepartmentTerm?: InputMaybe<Scalars['String']>;
  readonly Title?: InputMaybe<Scalars['String']>;
  readonly LevelTerm?: InputMaybe<Scalars['String']>;
  readonly Primary_Work_Location?: InputMaybe<Scalars['String']>;
  readonly Work_EnvironmentTerm?: InputMaybe<Scalars['String']>;
  readonly Communication_PreferenceTerm?: InputMaybe<Scalars['String']>;
  readonly Pricing_BehaviorTerm?: InputMaybe<Scalars['String']>;
  readonly Manager?: InputMaybe<Scalars['String']>;
  readonly Hometown?: InputMaybe<Scalars['String']>;
  readonly Current_Location?: InputMaybe<Scalars['String']>;
  readonly Primary_LanguageTerm?: InputMaybe<Scalars['String']>;
  readonly Secondary_LanguageTerm?: InputMaybe<Scalars['String']>;
  readonly Birthday?: InputMaybe<Scalars['DateTime']>;
  readonly Personal_Notes?: InputMaybe<Scalars['String']>;
  readonly External_User?: InputMaybe<Scalars['String']>;
  readonly Entity_Id?: InputMaybe<Scalars['String']>;
  readonly Phone_Value?: InputMaybe<Scalars['String']>;
  readonly Phone_Extension?: InputMaybe<Scalars['String']>;
  readonly Phone_Label?: InputMaybe<Scalars['String']>;
  readonly Phone_Notes?: InputMaybe<Scalars['String']>;
  readonly Phone_Is_Personal?: InputMaybe<Scalars['Boolean']>;
  readonly Email_Value?: InputMaybe<Scalars['String']>;
  readonly Email_Label?: InputMaybe<Scalars['String']>;
  readonly Email_Notes?: InputMaybe<Scalars['String']>;
  readonly Email_Is_Personal?: InputMaybe<Scalars['Boolean']>;
  readonly Entity_Name?: InputMaybe<Scalars['String']>;
  readonly Entity_Type?: InputMaybe<Scalars['String']>;
  readonly Entity_Status?: InputMaybe<Scalars['Boolean']>;
  readonly Price?: InputMaybe<Scalars['Float']>;
  readonly Service?: InputMaybe<Scalars['Float']>;
  readonly Rookie?: InputMaybe<Scalars['Float']>;
  readonly Expert?: InputMaybe<Scalars['Float']>;
  readonly Human?: InputMaybe<Scalars['Float']>;
  readonly Technology?: InputMaybe<Scalars['Float']>;
  readonly Handson?: InputMaybe<Scalars['Float']>;
  readonly Handsoff?: InputMaybe<Scalars['Float']>;
  readonly Supporter?: InputMaybe<Scalars['Float']>;
  readonly Detractor?: InputMaybe<Scalars['Float']>;
  readonly Contact_Entity_Lookup_Id?: InputMaybe<Scalars['String']>;
  readonly crm_Contact_Information?: InputMaybe<ReadonlyArray<CrmContactInformationInputV2>>;
  readonly crm_Contact_Associated_Entity?: InputMaybe<ReadonlyArray<CrmContactAssociatedEntityInputV2>>;
  readonly crm_Contact_Associated_Rep?: InputMaybe<ReadonlyArray<CrmContactAssociatedRepInputV2>>;
  readonly crm_Contact_Responsibility?: InputMaybe<ReadonlyArray<CrmContactResponsibilityInputV2>>;
  readonly crm_Contact_Slider?: InputMaybe<ReadonlyArray<CrmContactSliderInputV2>>;
  readonly crm_Contact_Pricing_Behaviour?: InputMaybe<ReadonlyArray<CrmContactPricingBehaviourInput>>;
  readonly crm_Contact_Interest_Hobby?: InputMaybe<ReadonlyArray<CrmContactInterestHobbyInputV2>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly InterestsAndHobbies?: InputMaybe<Scalars['String']>;
};

export type CrmContactInterestHobbyInput = {
  readonly contactId?: InputMaybe<Scalars['String']>;
  readonly interestHobbyValue?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactInterestHobbyInputV2 = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Interest_Hobby_Value?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactInterestHobbyOutput = {
  readonly __typename?: 'CrmContactInterestHobbyOutput';
  readonly interestHobbyId: Scalars['ID'];
  readonly contactId?: Maybe<Scalars['String']>;
  readonly interestHobbyValue?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactInterestHobbyOutputV2 = {
  readonly __typename?: 'CrmContactInterestHobbyOutputV2';
  readonly Contact_Interest_Hobby_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Contact_Interest_Hobby_Value?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactOutput = {
  readonly __typename?: 'CrmContactOutput';
  readonly contactId: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly suffixTerm?: Maybe<Scalars['String']>;
  readonly pronounsTerm?: Maybe<Scalars['String']>;
  readonly preferredName?: Maybe<Scalars['String']>;
  readonly pronunciation?: Maybe<Scalars['String']>;
  readonly zoomInfoURL?: Maybe<Scalars['String']>;
  readonly zoomInfoNumber?: Maybe<Scalars['String']>;
  readonly linkedInURL?: Maybe<Scalars['String']>;
  readonly twitterURL?: Maybe<Scalars['String']>;
  readonly departmentTerm?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly levelTerm?: Maybe<Scalars['String']>;
  readonly primaryWorkLocation?: Maybe<Scalars['String']>;
  readonly workEnvironmentTerm?: Maybe<Scalars['String']>;
  readonly communicationPreferenceTerm?: Maybe<Scalars['String']>;
  readonly pricingBehaviorTerm?: Maybe<Scalars['String']>;
  readonly manager?: Maybe<Scalars['String']>;
  readonly managerEntity?: Maybe<CrmContactOutput>;
  readonly hometown?: Maybe<Scalars['String']>;
  readonly currentLocation?: Maybe<Scalars['String']>;
  readonly primaryLanguageTerm?: Maybe<Scalars['String']>;
  readonly secondaryLanguageTerm?: Maybe<Scalars['String']>;
  readonly birthday?: Maybe<Scalars['DateTime']>;
  readonly personalNotes?: Maybe<Scalars['String']>;
  readonly externalUser?: Maybe<Scalars['String']>;
  readonly entityId?: Maybe<Scalars['String']>;
  readonly contactEntityLookupId?: Maybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly createdSourceType?: Maybe<Scalars['Float']>;
  readonly isExceptionAvailable?: Maybe<Scalars['Boolean']>;
  /** @deprecated Source is deprecated, Use SourceTerm instead */
  readonly source?: Maybe<Scalars['String']>;
  readonly sourceTerm?: Maybe<Scalars['String']>;
  readonly information?: Maybe<ReadonlyArray<CrmContactInformationOutput>>;
  readonly associatedEntities?: Maybe<ReadonlyArray<CrmContactAssociatedEntityOutput>>;
  readonly associatedReps?: Maybe<ReadonlyArray<CrmContactAssociatedRepOutput>>;
  readonly responsibilities?: Maybe<ReadonlyArray<CrmContactResponsibilityOutput>>;
  readonly slider?: Maybe<ReadonlyArray<CrmContactSliderOutput>>;
  readonly pricingBehaviors?: Maybe<ReadonlyArray<CrmContactPricingBehaviourOutput>>;
  readonly interestHobbies?: Maybe<ReadonlyArray<CrmContactInterestHobbyOutput>>;
  readonly additionalInfo?: Maybe<ReadonlyArray<CrmContactAdditionalInfoOutput>>;
  readonly exceptionMapping?: Maybe<ReadonlyArray<CrmContactDedupeExceptionListMappingOutput>>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactOutputV2 = {
  readonly __typename?: 'CrmContactOutputV2';
  readonly Contact_Id: Scalars['ID'];
  readonly Name?: Maybe<Scalars['String']>;
  readonly SuffixTerm?: Maybe<Scalars['String']>;
  readonly PronounsTerm?: Maybe<Scalars['String']>;
  readonly Preferred_Name?: Maybe<Scalars['String']>;
  readonly Pronunciation?: Maybe<Scalars['String']>;
  readonly Zoom_Info_URL?: Maybe<Scalars['String']>;
  readonly Zoom_Info_Number?: Maybe<Scalars['String']>;
  readonly LinkedIn_URL?: Maybe<Scalars['String']>;
  readonly Twitter_URL?: Maybe<Scalars['String']>;
  readonly DepartmentTerm?: Maybe<Scalars['String']>;
  readonly Title?: Maybe<Scalars['String']>;
  readonly LevelTerm?: Maybe<Scalars['String']>;
  readonly Primary_Work_Location?: Maybe<Scalars['String']>;
  readonly Work_EnvironmentTerm?: Maybe<Scalars['String']>;
  readonly Communication_PreferenceTerm?: Maybe<Scalars['String']>;
  readonly Pricing_BehaviorTerm?: Maybe<Scalars['String']>;
  readonly Manager?: Maybe<Scalars['String']>;
  readonly ManagerEntity?: Maybe<CrmContactOutputV2>;
  readonly Hometown?: Maybe<Scalars['String']>;
  readonly Current_Location?: Maybe<Scalars['String']>;
  readonly Primary_LanguageTerm?: Maybe<Scalars['String']>;
  readonly Secondary_LanguageTerm?: Maybe<Scalars['String']>;
  readonly Birthday?: Maybe<Scalars['DateTime']>;
  readonly Personal_Notes?: Maybe<Scalars['String']>;
  readonly External_User?: Maybe<Scalars['String']>;
  readonly Entity_Id?: Maybe<Scalars['String']>;
  readonly Phone_Value?: Maybe<Scalars['String']>;
  readonly Phone_Extension?: Maybe<Scalars['String']>;
  readonly Phone_Label?: Maybe<Scalars['String']>;
  readonly Phone_Notes?: Maybe<Scalars['String']>;
  readonly Phone_Is_Personal?: Maybe<Scalars['Boolean']>;
  readonly Email_Value?: Maybe<Scalars['String']>;
  readonly Email_Label?: Maybe<Scalars['String']>;
  readonly Email_Notes?: Maybe<Scalars['String']>;
  readonly Email_Is_Personal?: Maybe<Scalars['Boolean']>;
  readonly Entity_Name?: Maybe<Scalars['String']>;
  readonly Entity_Type?: Maybe<Scalars['String']>;
  readonly Entity_Status?: Maybe<Scalars['Boolean']>;
  readonly Price?: Maybe<Scalars['Float']>;
  readonly Service?: Maybe<Scalars['Float']>;
  readonly Rookie?: Maybe<Scalars['Float']>;
  readonly Expert?: Maybe<Scalars['Float']>;
  readonly Human?: Maybe<Scalars['Float']>;
  readonly Technology?: Maybe<Scalars['Float']>;
  readonly Handson?: Maybe<Scalars['Float']>;
  readonly Handsoff?: Maybe<Scalars['Float']>;
  readonly Supporter?: Maybe<Scalars['Float']>;
  readonly Detractor?: Maybe<Scalars['Float']>;
  readonly Contact_Entity_Lookup_Id?: Maybe<Scalars['String']>;
  readonly crm_Contact_Information?: Maybe<ReadonlyArray<CrmContactInformationOutputV2>>;
  readonly crm_Contact_Associated_Entity?: Maybe<ReadonlyArray<CrmContactAssociatedEntityOutputV2>>;
  readonly crm_Contact_Associated_Rep?: Maybe<ReadonlyArray<CrmContactAssociatedRepOutputV2>>;
  readonly crm_Contact_Responsibility?: Maybe<ReadonlyArray<CrmContactResponsibilityOutputV2>>;
  readonly crm_Contact_Slider?: Maybe<ReadonlyArray<CrmContactSliderOutputV2>>;
  readonly crm_Contact_Pricing_Behaviour?: Maybe<ReadonlyArray<CrmContactPricingBehaviourOutputv2>>;
  readonly crm_Contact_Interest_Hobby?: Maybe<ReadonlyArray<CrmContactInterestHobbyOutputV2>>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly InterestsAndHobbies?: Maybe<Scalars['String']>;
};

export type CrmContactPricingBehaviorInput = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Pricing_Behaviour_Key?: InputMaybe<Scalars['String']>;
  readonly Pricing_Behaviour_ValueTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly contactId?: InputMaybe<Scalars['String']>;
  readonly pricingBehaviourKey?: InputMaybe<Scalars['String']>;
  readonly pricingBehaviourValueTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactPricingBehaviourInput = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Pricing_Behaviour_Key?: InputMaybe<Scalars['String']>;
  readonly Pricing_Behaviour_ValueTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly contactId?: InputMaybe<Scalars['String']>;
  readonly pricingBehaviourKey?: InputMaybe<Scalars['String']>;
  readonly pricingBehaviourValueTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactPricingBehaviourOutput = {
  readonly __typename?: 'CrmContactPricingBehaviourOutput';
  readonly pricingBehaviorId: Scalars['ID'];
  readonly contactId?: Maybe<Scalars['String']>;
  readonly pricingBehaviorKey?: Maybe<Scalars['String']>;
  readonly pricingBehaviorValueTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactPricingBehaviourOutputv2 = {
  readonly __typename?: 'CrmContactPricingBehaviourOutputv2';
  readonly Contact_Pricing_Behaviour_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Pricing_Behaviour_Key?: Maybe<Scalars['String']>;
  readonly Pricing_Behaviour_ValueTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityAssociatedEntityInput = {
  readonly responsibilityId?: InputMaybe<Scalars['String']>;
  readonly associatedEntityId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityAssociatedEntityInputV2 = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Associated_Entity_Id?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityAssociatedEntityOutput = {
  readonly __typename?: 'CrmContactResponsibilityAssociatedEntityOutput';
  readonly responsibilityAssociatedEntityId: Scalars['ID'];
  readonly responsibilityId?: Maybe<Scalars['String']>;
  readonly associatedEntityId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityAssociatedEntityOutputV2 = {
  readonly __typename?: 'CrmContactResponsibilityAssociatedEntityOutputV2';
  readonly Contact_Responsibility_Associated_Entity_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  readonly Contact_Associated_Entity_Id?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityBusinessUnitInput = {
  readonly responsibilityId?: InputMaybe<Scalars['String']>;
  readonly responsibilityBusinessUnitValueTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityBusinessUnitInputV2 = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Responsibility_Business_Unit_Value?: InputMaybe<Scalars['String']>;
  readonly Responsibility_Business_Unit_ValueTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityBusinessUnitOutput = {
  readonly __typename?: 'CrmContactResponsibilityBusinessUnitOutput';
  readonly responsbilityBusinessUnitId: Scalars['ID'];
  readonly responsibilityId?: Maybe<Scalars['String']>;
  readonly responsibilityBusinessUnitValueTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityBusinessUnitOutputV2 = {
  readonly __typename?: 'CrmContactResponsibilityBusinessUnitOutputV2';
  readonly Contact_Responsibility_Business_Unit_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  /** @deprecated Responsibility_Business_Unit_Value is deprecated, Use Responsibility_Business_Unit_ValueTerm instead */
  readonly Responsibility_Business_Unit_Value?: Maybe<Scalars['String']>;
  readonly Responsibility_Business_Unit_ValueTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityEquipmentInput = {
  readonly responsibilityId?: InputMaybe<Scalars['String']>;
  readonly responsibilityEquipmentValue?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityEquipmentInputV2 = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Responsibility_Equipment_Value?: InputMaybe<Scalars['String']>;
  readonly Responsibility_Equipment_ValueTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityEquipmentOutput = {
  readonly __typename?: 'CrmContactResponsibilityEquipmentOutput';
  readonly responsibilityEquipmentId: Scalars['ID'];
  readonly responsibilityId?: Maybe<Scalars['String']>;
  readonly responsibilityEquipmentValueTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityEquipmentOutputV2 = {
  readonly __typename?: 'CrmContactResponsibilityEquipmentOutputV2';
  readonly Contact_Responsibility_Equipment_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  /** @deprecated Responsibility_Equipment_Value is deprecated, Use Responsibility_Equipment_ValueTerm instead */
  readonly Responsibility_Equipment_Value?: Maybe<Scalars['String']>;
  readonly Responsibility_Equipment_ValueTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityIbRegionsInput = {
  readonly responsibilityId?: InputMaybe<Scalars['String']>;
  readonly inboundRegionsData?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityIbRegionsInputV2 = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Ib_Regions_Data?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityIbRegionsOutput = {
  readonly __typename?: 'CrmContactResponsibilityIBRegionsOutput';
  readonly responsibilityInboundRegionsId: Scalars['ID'];
  readonly responsibilityId?: Maybe<Scalars['String']>;
  readonly inboundRegionsData?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityIbRegionsOutputV2 = {
  readonly __typename?: 'CrmContactResponsibilityIBRegionsOutputV2';
  readonly Contact_Responsibility_Ib_Regions_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  readonly Ib_Regions_Data?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityInput = {
  readonly contactId?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly businessUnits?: InputMaybe<ReadonlyArray<CrmContactResponsibilityBusinessUnitInput>>;
  readonly responsibilityModes?: InputMaybe<ReadonlyArray<CrmContactResponsibilityModeInput>>;
  readonly associatedEntities?: InputMaybe<ReadonlyArray<CrmContactResponsibilityAssociatedEntityInput>>;
  readonly equipment?: InputMaybe<ReadonlyArray<CrmContactResponsibilityEquipmentInput>>;
  readonly associatedRepResponsibilities?: InputMaybe<ReadonlyArray<CrmContactAssociatedRepResponsibilityInput>>;
  readonly sizes?: InputMaybe<ReadonlyArray<CrmContactResponsibilitySizeInput>>;
  readonly inboundRegions?: InputMaybe<ReadonlyArray<CrmContactResponsibilityIbRegionsInput>>;
  readonly outboundRegions?: InputMaybe<ReadonlyArray<CrmContactResponsibilityObRegionsInput>>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type CrmContactResponsibilityInputV2 = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Division?: InputMaybe<Scalars['String']>;
  readonly DivisionTerm?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly crm_Contact_Responsibility_Business_Unit?: InputMaybe<ReadonlyArray<CrmContactResponsibilityBusinessUnitInputV2>>;
  readonly crm_Contact_Responsibility_Mode?: InputMaybe<ReadonlyArray<CrmContactResponsibilityModeInputV2>>;
  readonly crm_Contact_Responsibility_Associated_Entity?: InputMaybe<ReadonlyArray<CrmContactResponsibilityAssociatedEntityInputV2>>;
  readonly crm_Contact_Responsibility_Equipment?: InputMaybe<ReadonlyArray<CrmContactResponsibilityEquipmentInputV2>>;
  readonly crm_Contact_Responsibility_Region?: InputMaybe<ReadonlyArray<CrmContactResponsibilityRegionInputV2>>;
  readonly crm_Contact_Associated_Rep_Responsibility?: InputMaybe<ReadonlyArray<CrmContactAssociatedRepResponsibilityInputV2>>;
  readonly crm_Contact_Responsibility_Size?: InputMaybe<ReadonlyArray<CrmContactResponsibilitySizeInputV2>>;
  readonly crm_Contact_Responsibility_Ib_Regions?: InputMaybe<ReadonlyArray<CrmContactResponsibilityIbRegionsInputV2>>;
  readonly crm_Contact_Responsibility_Ob_Regions?: InputMaybe<ReadonlyArray<CrmContactResponsibilityObRegionsInputV2>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly Is_Active?: InputMaybe<Scalars['Boolean']>;
};

export type CrmContactResponsibilityModeInput = {
  readonly responsibilityId?: InputMaybe<Scalars['String']>;
  readonly responsibilityModeValueTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityModeInputV2 = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Responsibility_Mode_Value?: InputMaybe<Scalars['String']>;
  readonly Responsibility_Mode_ValueTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityModeOutput = {
  readonly __typename?: 'CrmContactResponsibilityModeOutput';
  readonly responsibilityModeId: Scalars['ID'];
  readonly responsibilityId?: Maybe<Scalars['String']>;
  readonly responsibilityModeValueTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityModeOutputV2 = {
  readonly __typename?: 'CrmContactResponsibilityModeOutputV2';
  readonly Contact_Responsibility_Mode_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  /** @deprecated Responsibility_Mode_Value is deprecated, Use Responsibility_Mode_ValueTerm instead */
  readonly Responsibility_Mode_Value?: Maybe<Scalars['String']>;
  readonly Responsibility_Mode_ValueTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityObRegionsInput = {
  readonly responsibilityId?: InputMaybe<Scalars['String']>;
  readonly outboundRegionsData?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityObRegionsInputV2 = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Ob_Regions_Data?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityObRegionsOutput = {
  readonly __typename?: 'CrmContactResponsibilityOBRegionsOutput';
  readonly responsibilityOutboundRegionsId: Scalars['ID'];
  readonly responsibilityId?: Maybe<Scalars['String']>;
  readonly outboundRegionsData?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityObRegionsOutputV2 = {
  readonly __typename?: 'CrmContactResponsibilityOBRegionsOutputV2';
  readonly Contact_Responsibility_Ob_Regions_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  readonly Ob_Regions_Data?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityOutput = {
  readonly __typename?: 'CrmContactResponsibilityOutput';
  readonly responsibilityId: Scalars['ID'];
  readonly contactId?: Maybe<Scalars['String']>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly businessUnits?: Maybe<ReadonlyArray<CrmContactResponsibilityBusinessUnitOutput>>;
  readonly modes?: Maybe<ReadonlyArray<CrmContactResponsibilityModeOutput>>;
  readonly associatedEntities?: Maybe<ReadonlyArray<CrmContactResponsibilityAssociatedEntityOutput>>;
  readonly equipment?: Maybe<ReadonlyArray<CrmContactResponsibilityEquipmentOutput>>;
  readonly responsibilities?: Maybe<ReadonlyArray<CrmContactAssociatedRepResponsibilityOutput>>;
  readonly sizes?: Maybe<ReadonlyArray<CrmContactResponsibilitySizeOutput>>;
  readonly inboundRegions?: Maybe<ReadonlyArray<CrmContactResponsibilityIbRegionsOutput>>;
  readonly outboundRegions?: Maybe<ReadonlyArray<CrmContactResponsibilityObRegionsOutput>>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type CrmContactResponsibilityOutputV2 = {
  readonly __typename?: 'CrmContactResponsibilityOutputV2';
  readonly Contact_Responsibility_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  /** @deprecated Division is deprecated, Use DivisionTerm instead */
  readonly Division?: Maybe<Scalars['String']>;
  readonly DivisionTerm?: Maybe<Scalars['String']>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly crm_Contact_Responsibility_Business_Unit?: Maybe<ReadonlyArray<CrmContactResponsibilityBusinessUnitOutputV2>>;
  readonly crm_Contact_Responsibility_Mode?: Maybe<ReadonlyArray<CrmContactResponsibilityModeOutputV2>>;
  readonly crm_Contact_Responsibility_Associated_Entity?: Maybe<ReadonlyArray<CrmContactResponsibilityAssociatedEntityOutputV2>>;
  readonly crm_Contact_Responsibility_Equipment?: Maybe<ReadonlyArray<CrmContactResponsibilityEquipmentOutputV2>>;
  readonly crm_Contact_Responsibility_Region?: Maybe<ReadonlyArray<CrmContactResponsibilityRegionOutputV2>>;
  readonly crm_Contact_Associated_Rep_Responsibility?: Maybe<ReadonlyArray<CrmContactAssociatedRepResponsibilityOutputV2>>;
  readonly crm_Contact_Responsibility_Size?: Maybe<ReadonlyArray<CrmContactResponsibilitySizeOutputV2>>;
  readonly crm_Contact_Responsibility_Ib_Regions?: Maybe<ReadonlyArray<CrmContactResponsibilityIbRegionsOutputV2>>;
  readonly crm_Contact_Responsibility_Ob_Regions?: Maybe<ReadonlyArray<CrmContactResponsibilityObRegionsOutputV2>>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly Is_Active?: Maybe<Scalars['Boolean']>;
};

export type CrmContactResponsibilityRegionInputV2 = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Region_Type?: InputMaybe<Scalars['String']>;
  readonly Region_Value?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityRegionOutputV2 = {
  readonly __typename?: 'CrmContactResponsibilityRegionOutputV2';
  readonly Contact_Responsibility_Region_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  readonly Region_Type?: Maybe<Scalars['String']>;
  readonly Region_Value?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilitySizeInput = {
  readonly responsibilityId?: InputMaybe<Scalars['String']>;
  readonly responsibilitySizeValueTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilitySizeInputV2 = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Responsibility_Size_ValueTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilitySizeOutput = {
  readonly __typename?: 'CrmContactResponsibilitySizeOutput';
  readonly responsibilitySizeId: Scalars['ID'];
  readonly responsibilityId?: Maybe<Scalars['String']>;
  readonly responsibilitySizeValueTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilitySizeOutputV2 = {
  readonly __typename?: 'CrmContactResponsibilitySizeOutputV2';
  readonly Contact_Responsibility_Size_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  readonly Responsibility_Size_ValueTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactSliderInput = {
  readonly contactId?: InputMaybe<Scalars['String']>;
  readonly price?: InputMaybe<Scalars['String']>;
  readonly service?: InputMaybe<Scalars['String']>;
  readonly rookie?: InputMaybe<Scalars['String']>;
  readonly expert?: InputMaybe<Scalars['String']>;
  readonly human?: InputMaybe<Scalars['String']>;
  readonly technology?: InputMaybe<Scalars['String']>;
  readonly handsOn?: InputMaybe<Scalars['String']>;
  readonly handsOff?: InputMaybe<Scalars['String']>;
  readonly supporter?: InputMaybe<Scalars['String']>;
  readonly detractor?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactSliderInputV2 = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Price?: InputMaybe<Scalars['String']>;
  readonly Service?: InputMaybe<Scalars['String']>;
  readonly Rookie?: InputMaybe<Scalars['String']>;
  readonly Expert?: InputMaybe<Scalars['String']>;
  readonly Human?: InputMaybe<Scalars['String']>;
  readonly Technology?: InputMaybe<Scalars['String']>;
  readonly Handson?: InputMaybe<Scalars['String']>;
  readonly Handsoff?: InputMaybe<Scalars['String']>;
  readonly Supporter?: InputMaybe<Scalars['String']>;
  readonly Detractor?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactSliderOutput = {
  readonly __typename?: 'CrmContactSliderOutput';
  readonly sliderId: Scalars['ID'];
  readonly contactId?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['String']>;
  readonly service?: Maybe<Scalars['String']>;
  readonly rookie?: Maybe<Scalars['String']>;
  readonly expert?: Maybe<Scalars['String']>;
  readonly human?: Maybe<Scalars['String']>;
  readonly technology?: Maybe<Scalars['String']>;
  readonly handsOn?: Maybe<Scalars['String']>;
  readonly handsOff?: Maybe<Scalars['String']>;
  readonly supporter?: Maybe<Scalars['String']>;
  readonly detractor?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactSliderOutputV2 = {
  readonly __typename?: 'CrmContactSliderOutputV2';
  readonly Contact_Slider_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Price?: Maybe<Scalars['String']>;
  readonly Service?: Maybe<Scalars['String']>;
  readonly Rookie?: Maybe<Scalars['String']>;
  readonly Expert?: Maybe<Scalars['String']>;
  readonly Human?: Maybe<Scalars['String']>;
  readonly Technology?: Maybe<Scalars['String']>;
  readonly Handson?: Maybe<Scalars['String']>;
  readonly Handsoff?: Maybe<Scalars['String']>;
  readonly Supporter?: Maybe<Scalars['String']>;
  readonly Detractor?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactWorkHourAssociatedEntityInput = {
  readonly Contact_Work_Hour_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Associated_Entity_Id?: InputMaybe<Scalars['String']>;
  readonly Associated_Entity_Name?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly contactWorkHourId?: InputMaybe<Scalars['String']>;
  readonly contactAssociatedEntityId?: InputMaybe<Scalars['String']>;
  readonly associatedEntityName?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactWorkHourAssociatedEntityOutput = {
  readonly __typename?: 'CrmContactWorkHourAssociatedEntityOutput';
  readonly workHourAssociatedEntityId: Scalars['ID'];
  readonly workHourId?: Maybe<Scalars['String']>;
  readonly associatedEntityId?: Maybe<Scalars['String']>;
  readonly associatedEntityName?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactWorkHourAssociatedEntityOutputv2 = {
  readonly __typename?: 'CrmContactWorkHourAssociatedEntityOutputv2';
  readonly Contact_Work_Hour_Associated_Entity_Id: Scalars['ID'];
  readonly Contact_Work_Hour_Id?: Maybe<Scalars['String']>;
  readonly Contact_Associated_Entity_Id?: Maybe<Scalars['String']>;
  readonly Associated_Entity_Name?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactWorkHoursInput = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Day?: InputMaybe<Scalars['String']>;
  readonly Hours_Start?: InputMaybe<Scalars['String']>;
  readonly Hours_End?: InputMaybe<Scalars['String']>;
  readonly Notes?: InputMaybe<Scalars['String']>;
  readonly Is_Day_Off?: InputMaybe<Scalars['Boolean']>;
  readonly crm_Contact_Work_Hour_Associated_Entity?: InputMaybe<ReadonlyArray<CrmContactWorkHourAssociatedEntityInput>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly contactId?: InputMaybe<Scalars['String']>;
  readonly day?: InputMaybe<Scalars['String']>;
  readonly hoursStart?: InputMaybe<Scalars['String']>;
  readonly hoursEnd?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly isDayOff?: InputMaybe<Scalars['Boolean']>;
  readonly associatedEntities?: InputMaybe<ReadonlyArray<CrmContactWorkHourAssociatedEntityInput>>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactWorkHoursOutput = {
  readonly __typename?: 'CrmContactWorkHoursOutput';
  readonly workHourId: Scalars['ID'];
  readonly contactId?: Maybe<Scalars['String']>;
  readonly day?: Maybe<Scalars['String']>;
  readonly hoursStart?: Maybe<Scalars['String']>;
  readonly hoursEnd?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly isDayOff?: Maybe<Scalars['Boolean']>;
  readonly associatedEntities?: Maybe<ReadonlyArray<CrmContactWorkHourAssociatedEntityOutput>>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactWorkHoursOutputv2 = {
  readonly __typename?: 'CrmContactWorkHoursOutputv2';
  readonly Contact_Work_Hour_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Day?: Maybe<Scalars['String']>;
  readonly Hours_Start?: Maybe<Scalars['String']>;
  readonly Hours_End?: Maybe<Scalars['String']>;
  readonly Notes?: Maybe<Scalars['String']>;
  readonly Is_Day_Off?: Maybe<Scalars['Boolean']>;
  readonly crm_Contact_Work_Hour_Associated_Entity?: Maybe<ReadonlyArray<CrmContactWorkHourAssociatedEntityOutputv2>>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmCustomerDetail = {
  readonly __typename?: 'CRMCustomerDetail';
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly id?: Maybe<Scalars['ID']>;
  /** @deprecated This property will be removed. Use InteractionFrequencyTypeId instead  */
  readonly interactionFrequency?: Maybe<CustomerInteractionFrequencyEnum>;
  readonly interactionFrequencyTypeId?: Maybe<Scalars['ID']>;
  readonly leadNotes?: Maybe<Scalars['String']>;
  /** @deprecated This property will be removed. Use LeadSourceTypeId instead  */
  readonly leadSource?: Maybe<LeadSourceEnum>;
  readonly leadSourceTypeId?: Maybe<Scalars['ID']>;
};

export type CrmCustomerDocumentsInput = {
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly documentId?: InputMaybe<Scalars['String']>;
  readonly documentName?: InputMaybe<Scalars['String']>;
  readonly documentType?: InputMaybe<Scalars['String']>;
  readonly fileName?: InputMaybe<Scalars['String']>;
  readonly source?: InputMaybe<Scalars['String']>;
  readonly direction?: InputMaybe<Scalars['String']>;
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly crmEntityCustomerContactInput?: InputMaybe<ReadonlyArray<CrmEntityCustomerContactInput>>;
  readonly crmEntityCustomerOpportunityInput?: InputMaybe<ReadonlyArray<CrmEntityCustomerOpportunityInput>>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmCustomerDocumentsOutput = {
  readonly __typename?: 'CrmCustomerDocumentsOutput';
  readonly id: Scalars['ID'];
  readonly customerId?: Maybe<Scalars['String']>;
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly documentId?: Maybe<Scalars['String']>;
  readonly documentName?: Maybe<Scalars['String']>;
  readonly documentType?: Maybe<Scalars['String']>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly direction?: Maybe<Scalars['String']>;
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly crmEntityCustomerContactOutput?: Maybe<ReadonlyArray<CrmEntityCustomerContactOutput>>;
  readonly crmEntityCustomerOpportunityOutput?: Maybe<ReadonlyArray<CrmEntityCustomerOpportunityOutput>>;
  readonly createdBy?: Maybe<UserV2>;
  readonly updatedBy?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmCustomerExportNotesOutput = {
  readonly __typename?: 'CrmCustomerExportNotesOutput';
  readonly id: Scalars['ID'];
  readonly entityId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<UserV2>;
  readonly updatedBy?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmCustomerNotesInput = {
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Scalars['String']>;
  readonly crmEntityCustomerContactInput?: InputMaybe<ReadonlyArray<CrmEntityCustomerContactInput>>;
  readonly crmEntityCustomerRepInput?: InputMaybe<ReadonlyArray<CrmEntityCustomerRepInput>>;
  readonly crmEntityCustomerOpportunityInput?: InputMaybe<ReadonlyArray<CrmEntityCustomerOpportunityInput>>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmCustomerNotesOutput = {
  readonly __typename?: 'CrmCustomerNotesOutput';
  readonly id: Scalars['ID'];
  readonly customerId?: Maybe<Scalars['String']>;
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly crmEntityCustomerContactOutput?: Maybe<ReadonlyArray<CrmEntityCustomerContactOutput>>;
  readonly crmEntityCustomerRepOutput?: Maybe<ReadonlyArray<CrmEntityCustomerRepOutput>>;
  readonly crmEntityCustomerOpportunityOutput?: Maybe<ReadonlyArray<CrmEntityCustomerOpportunityOutput>>;
  readonly createdBy?: Maybe<UserV2>;
  readonly updatedBy?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmDetailedDescriptionInput = {
  readonly detailedDescriptionText?: InputMaybe<Scalars['String']>;
  readonly crmDetailsEntityLookupId?: InputMaybe<Scalars['String']>;
  readonly entityId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmDetailedDescriptionOutput = {
  readonly __typename?: 'CrmDetailedDescriptionOutput';
  readonly detailedDescriptionId: Scalars['ID'];
  readonly detailedDescriptionText?: Maybe<Scalars['String']>;
  readonly crmDetailsEntityLookupId?: Maybe<Scalars['String']>;
  readonly entityId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmDetailsEntityLookupOutput = {
  readonly __typename?: 'CrmDetailsEntityLookupOutput';
  readonly crmDetailsEntityLookupId: Scalars['ID'];
  readonly entityType?: Maybe<Scalars['Float']>;
  readonly entityName?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmDetailsStockSymbolConfigOutput = {
  readonly __typename?: 'CrmDetailsStockSymbolConfigOutput';
  readonly stocksApiUrl?: Maybe<Scalars['String']>;
  readonly stocksApiToken?: Maybe<Scalars['String']>;
  readonly stocksFinancePageUrl?: Maybe<Scalars['String']>;
};

export type CrmDocument = {
  readonly __typename?: 'CRMDocument';
  readonly id: Scalars['ID'];
  /** The name of the file to be displayed to the user, not necessarily the filename */
  readonly name: Scalars['String'];
  /** The name of the file provided during upload */
  readonly filename: Scalars['String'];
  /**
   * DEPRECATED: Use uploadedByUserV2 instead
   * @deprecated Use uploadedByUserV2 instead
   */
  readonly uploadedByUser?: Maybe<User>;
  readonly uploadDate: Scalars['DateTime'];
  /** entityType KVT ID */
  readonly entityType: Scalars['String'];
  /** entityId KVT ID */
  readonly entityId: Scalars['ID'];
  /** uri to access the document */
  readonly uri: Scalars['String'];
  /** uri to access the signed document */
  readonly signedUri?: Maybe<Scalars['String']>;
  /** MasterSign Reference Id */
  readonly masterSignReferenceId?: Maybe<Scalars['String']>;
  /** Identification for MasterSigner */
  readonly signedBy?: Maybe<Scalars['String']>;
  /** Timestamp for when document was signed */
  readonly signedAt?: Maybe<Scalars['DateTime']>;
  /**
   * Document Status
   * @deprecated Depcrecated in favor of documentStatus
   */
  readonly status?: Maybe<Scalars['String']>;
  /** Document Status */
  readonly documentStatus?: Maybe<DocumentStatusEnum>;
  /** Previous Document Status */
  readonly previousDocumentStatus?: Maybe<DocumentStatusEnum>;
  /** Identification for External Uploader */
  readonly externalUser?: Maybe<Scalars['String']>;
  readonly uploadedByUserV2?: Maybe<UserV2>;
};

export type CrmDocumentConnection = {
  readonly __typename?: 'CrmDocumentConnection';
  readonly edges: ReadonlyArray<CrmDocumentGqlTypeEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Float'];
};

export type CrmDocumentFilter = {
  readonly entityId?: InputMaybe<Scalars['ID']>;
  readonly entityType?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
};

export type CrmDocumentGqlTypeEdge = {
  readonly __typename?: 'CrmDocumentGQLTypeEdge';
  readonly cursor: Scalars['String'];
  readonly node: CrmDocument;
};

export type CrmDocumentSort = {
  readonly key: CrmDocumentSortEnum;
  readonly direction: CrmDocumentSortDirectionsEnum;
};

export enum CrmDocumentSortDirectionsEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum CrmDocumentSortEnum {
  Filename = 'filename',
  CreatedAt = 'createdAt'
}

export type CrmEntityCarrierContactInput = {
  readonly crmEntityId?: InputMaybe<Scalars['String']>;
  readonly crmEntityType?: InputMaybe<Scalars['String']>;
  readonly contactId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmEntityCarrierContactOutput = {
  readonly __typename?: 'CrmEntityCarrierContactOutput';
  readonly id: Scalars['ID'];
  readonly crmEntityId?: Maybe<Scalars['String']>;
  readonly crmEntityType?: Maybe<Scalars['String']>;
  readonly contactId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<UserV2>;
  readonly updatedBy?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmEntityCarrierOpportunityInput = {
  readonly crmEntityId?: InputMaybe<Scalars['String']>;
  readonly crmEntityType?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmEntityCarrierOpportunityOutput = {
  readonly __typename?: 'CrmEntityCarrierOpportunityOutput';
  readonly id: Scalars['ID'];
  readonly crmEntityId?: Maybe<Scalars['String']>;
  readonly crmEntityType?: Maybe<Scalars['String']>;
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<UserV2>;
  readonly updatedBy?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmEntityCarrierRepInput = {
  readonly crmEntityId?: InputMaybe<Scalars['String']>;
  readonly crmEntityType?: InputMaybe<Scalars['String']>;
  readonly repId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmEntityCarrierRepOutput = {
  readonly __typename?: 'CrmEntityCarrierRepOutput';
  readonly id: Scalars['ID'];
  readonly crmEntityId?: Maybe<Scalars['String']>;
  readonly crmEntityType?: Maybe<Scalars['String']>;
  readonly repId?: Maybe<EmployeeV2>;
  readonly createdBy?: Maybe<UserV2>;
  readonly updatedBy?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmEntityCustomerContactInput = {
  readonly crmEntityId?: InputMaybe<Scalars['String']>;
  readonly crmEntityType?: InputMaybe<Scalars['String']>;
  readonly contactId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmEntityCustomerContactOutput = {
  readonly __typename?: 'CrmEntityCustomerContactOutput';
  readonly id: Scalars['ID'];
  readonly crmEntityId?: Maybe<Scalars['String']>;
  readonly crmEntityType?: Maybe<Scalars['String']>;
  readonly contactId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<UserV2>;
  readonly updatedBy?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmEntityCustomerOpportunityInput = {
  readonly crmEntityId?: InputMaybe<Scalars['String']>;
  readonly crmEntityType?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmEntityCustomerOpportunityOutput = {
  readonly __typename?: 'CrmEntityCustomerOpportunityOutput';
  readonly id: Scalars['ID'];
  readonly crmEntityId?: Maybe<Scalars['String']>;
  readonly crmEntityType?: Maybe<Scalars['String']>;
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<UserV2>;
  readonly updatedBy?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmEntityCustomerRepInput = {
  readonly crmEntityId?: InputMaybe<Scalars['String']>;
  readonly crmEntityType?: InputMaybe<Scalars['String']>;
  readonly repId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmEntityCustomerRepOutput = {
  readonly __typename?: 'CrmEntityCustomerRepOutput';
  readonly id: Scalars['ID'];
  readonly crmEntityId?: Maybe<Scalars['String']>;
  readonly crmEntityType?: Maybe<Scalars['String']>;
  readonly repId?: Maybe<EmployeeV2>;
  readonly createdBy?: Maybe<UserV2>;
  readonly updatedBy?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmFirmographicsInput = {
  readonly annualRevenue?: InputMaybe<Scalars['Float']>;
  readonly netIncome?: InputMaybe<Scalars['Float']>;
  readonly grossProfit?: InputMaybe<Scalars['Float']>;
  readonly employeesAllSites?: InputMaybe<Scalars['Float']>;
  readonly employeesThisSite?: InputMaybe<Scalars['Float']>;
  readonly assets?: InputMaybe<Scalars['Float']>;
  readonly threeYearSalesGrowth?: InputMaybe<Scalars['Float']>;
  readonly threeYearNetIncomeGrowth?: InputMaybe<Scalars['Float']>;
  readonly yearFounded?: InputMaybe<Scalars['DateTime']>;
  readonly incorporated?: InputMaybe<Scalars['DateTime']>;
  readonly fiscalYearEnd?: InputMaybe<Scalars['DateTime']>;
  readonly yearFoundedValue?: InputMaybe<Scalars['Float']>;
  readonly incorporatedYear?: InputMaybe<Scalars['Float']>;
  readonly fiscalYearEndValue?: InputMaybe<Scalars['String']>;
  readonly keyPrincipal?: InputMaybe<Scalars['String']>;
  readonly fortune1000Rank?: InputMaybe<Scalars['Float']>;
  readonly femaleOwned?: InputMaybe<Scalars['String']>;
  readonly minorityOwned?: InputMaybe<Scalars['String']>;
  readonly veteranOwned?: InputMaybe<Scalars['String']>;
  readonly stockSymbolId?: InputMaybe<Scalars['String']>;
  readonly keyPrincipalIsContact?: InputMaybe<Scalars['Boolean']>;
  readonly crmDetailsEntityLookupId?: InputMaybe<Scalars['String']>;
  readonly industryClassificationLookupId?: InputMaybe<Scalars['String']>;
  readonly entityId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmFirmographicsOutput = {
  readonly __typename?: 'CrmFirmographicsOutput';
  readonly firmographicsId: Scalars['ID'];
  readonly annualRevenue?: Maybe<Scalars['Float']>;
  readonly netIncome?: Maybe<Scalars['Float']>;
  readonly grossProfit?: Maybe<Scalars['Float']>;
  readonly employeesAllSites?: Maybe<Scalars['Float']>;
  readonly employeesThisSite?: Maybe<Scalars['Float']>;
  readonly assets?: Maybe<Scalars['Float']>;
  readonly threeYearSalesGrowth?: Maybe<Scalars['Float']>;
  readonly threeYearNetIncomeGrowth?: Maybe<Scalars['Float']>;
  /** @deprecated yearFounded is deprecated, Use yearFoundedValue instead */
  readonly yearFounded?: Maybe<Scalars['DateTime']>;
  /** @deprecated incorporated is deprecated, Use incorporatedYear instead */
  readonly incorporated?: Maybe<Scalars['DateTime']>;
  /** @deprecated fiscalYearEnd is deprecated, Use fiscalYearEndValue instead */
  readonly fiscalYearEnd?: Maybe<Scalars['DateTime']>;
  readonly yearFoundedValue?: Maybe<Scalars['Float']>;
  readonly incorporatedYear?: Maybe<Scalars['Float']>;
  readonly fiscalYearEndValue?: Maybe<Scalars['String']>;
  readonly keyPrincipal?: Maybe<Scalars['String']>;
  readonly fortune1000Rank?: Maybe<Scalars['Float']>;
  readonly femaleOwned?: Maybe<Scalars['String']>;
  readonly minorityOwned?: Maybe<Scalars['String']>;
  readonly veteranOwned?: Maybe<Scalars['String']>;
  readonly crmDetailsEntityLookupId?: Maybe<Scalars['String']>;
  readonly entityId?: Maybe<Scalars['String']>;
  readonly stockSymbolId?: Maybe<Scalars['String']>;
  readonly keyPrincipalIsContact?: Maybe<Scalars['Boolean']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmIndustriesInput = {
  readonly industryClassificationLookupId?: InputMaybe<Scalars['String']>;
  readonly industryCode?: InputMaybe<Scalars['String']>;
  readonly primary?: InputMaybe<Scalars['Boolean']>;
  readonly crmDetailsEntityLookupId?: InputMaybe<Scalars['String']>;
  readonly entityId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type CrmIndustriesOutput = {
  readonly __typename?: 'CrmIndustriesOutput';
  readonly industriesId: Scalars['ID'];
  readonly industryDivision?: Maybe<Scalars['String']>;
  readonly industryMajorGroup?: Maybe<Scalars['String']>;
  readonly industryGroup?: Maybe<Scalars['String']>;
  readonly industry?: Maybe<Scalars['String']>;
  readonly extendedIndustry?: Maybe<Scalars['String']>;
  readonly industryClassificationSystem?: Maybe<Scalars['String']>;
  readonly industryCode?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<Scalars['Boolean']>;
  readonly crmDetailsEntityLookupId?: Maybe<Scalars['String']>;
  readonly industryClassificationLookupId?: Maybe<Scalars['String']>;
  readonly entityId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmIndustryClassificationLookupOutput = {
  readonly __typename?: 'CrmIndustryClassificationLookupOutput';
  readonly industryClassificationLookupId: Scalars['ID'];
  readonly industryClassificationSystem: Scalars['String'];
  readonly classificationJSON?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmInteractionConnection = {
  readonly __typename?: 'CrmInteractionConnection';
  readonly edges: ReadonlyArray<CrmInteractionOutputV2Edge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
  readonly totalInteractionsCount: Scalars['Int'];
};

export type CrmInteractionContactInput = {
  readonly Contact_Id: Scalars['String'];
  readonly Interaction_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Name?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmInteractionContactInputV2 = {
  readonly contactId: Scalars['String'];
  readonly interactionId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmInteractionContactOutput = {
  readonly __typename?: 'CrmInteractionContactOutput';
  readonly Interaction_Contact_Id: Scalars['ID'];
  readonly Contact_Id: Scalars['String'];
  readonly Interaction_Id?: Maybe<Scalars['String']>;
  readonly Contact_Name?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmInteractionContactOutputV2 = {
  readonly __typename?: 'CrmInteractionContactOutputV2';
  readonly id: Scalars['ID'];
  readonly contactId: Scalars['String'];
  readonly interactionId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmInteractionDocumentInput = {
  readonly Document_Name?: InputMaybe<Scalars['String']>;
  readonly DocumentId?: InputMaybe<Scalars['String']>;
  readonly TypeTerm?: InputMaybe<Scalars['String']>;
  readonly SourceTerm?: InputMaybe<Scalars['String']>;
  readonly DirectionTerm?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly Document_Path?: InputMaybe<Scalars['String']>;
  readonly Interaction_Id?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmInteractionDocumentInputV2 = {
  readonly documentName?: InputMaybe<Scalars['String']>;
  readonly documentId?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly sourceTerm?: InputMaybe<Scalars['String']>;
  readonly directionTerm?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly documentPath?: InputMaybe<Scalars['String']>;
  readonly interactionId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmInteractionDocumentOutput = {
  readonly __typename?: 'CrmInteractionDocumentOutput';
  readonly Interaction_Document_Id: Scalars['ID'];
  readonly Document_Name?: Maybe<Scalars['String']>;
  readonly DocumentId?: Maybe<Scalars['String']>;
  readonly TypeTerm?: Maybe<Scalars['String']>;
  readonly SourceTerm?: Maybe<Scalars['String']>;
  readonly DirectionTerm?: Maybe<Scalars['String']>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly Document_Path?: Maybe<Scalars['String']>;
  readonly Interaction_Id?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmInteractionDocumentOutputV2 = {
  readonly __typename?: 'CrmInteractionDocumentOutputV2';
  readonly id: Scalars['ID'];
  readonly documentName?: Maybe<Scalars['String']>;
  readonly documentId?: Maybe<Scalars['String']>;
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly sourceTerm?: Maybe<Scalars['String']>;
  readonly directionTerm?: Maybe<Scalars['String']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly documentPath?: Maybe<Scalars['String']>;
  readonly interactionId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmInteractionEntityLookupInput = {
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Entity_Name?: InputMaybe<Scalars['String']>;
  readonly Entity_Type?: InputMaybe<Scalars['String']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
};

export type CrmInteractionEntityLookupInputV2 = {
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly entityName?: InputMaybe<Scalars['String']>;
  readonly entityType?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

export type CrmInteractionEntityLookupOutputV2 = {
  readonly __typename?: 'CrmInteractionEntityLookupOutputV2';
  readonly id: Scalars['ID'];
  readonly entityType?: Maybe<Scalars['Float']>;
  readonly entityName?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmInteractionFilter = {
  readonly entityId?: InputMaybe<Scalars['String']>;
  readonly interactionId?: InputMaybe<Scalars['String']>;
};

export type CrmInteractionInput = {
  readonly Rescheduled?: InputMaybe<Scalars['Float']>;
  readonly Strategy?: InputMaybe<Scalars['String']>;
  readonly Status?: InputMaybe<Scalars['String']>;
  readonly ObjectiveTerm?: InputMaybe<Scalars['String']>;
  readonly DirectionTerm?: InputMaybe<Scalars['String']>;
  readonly ViaTerm?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly OutcomeTerm?: InputMaybe<Scalars['String']>;
  readonly Interaction_Type?: InputMaybe<Scalars['String']>;
  readonly Original_Date_Time?: InputMaybe<Scalars['DateTime']>;
  readonly Scheduled_Date_Time?: InputMaybe<Scalars['DateTime']>;
  readonly Completed_Date_Time?: InputMaybe<Scalars['DateTime']>;
  readonly Completed_By?: InputMaybe<Scalars['String']>;
  readonly CompletedBy?: InputMaybe<Scalars['String']>;
  readonly Interaction_Schedule_Time?: InputMaybe<Scalars['String']>;
  readonly Interaction_Entity_Lookup_Id?: InputMaybe<Scalars['String']>;
  readonly Entity_Id?: InputMaybe<Scalars['String']>;
  readonly Interaction_Rep_Group_KeyTerm?: InputMaybe<Scalars['Float']>;
  readonly RepGroupField1?: InputMaybe<Scalars['String']>;
  readonly crm_Interaction_Opportunity?: InputMaybe<ReadonlyArray<CrmInteractionOpportunityInput>>;
  readonly crm_Interaction_Contact?: InputMaybe<ReadonlyArray<CrmInteractionContactInput>>;
  readonly crm_Interaction_Reschedule_Audit?: InputMaybe<ReadonlyArray<CrmInteractionRescheduleInput>>;
  readonly crm_Interaction_Document?: InputMaybe<ReadonlyArray<CrmInteractionDocumentInput>>;
  readonly crm_Interaction_Rep_Group?: InputMaybe<ReadonlyArray<CrmInteractionRepGroupInput>>;
  readonly crm_Interaction_Objective?: InputMaybe<ReadonlyArray<CrmInteractionObjectiveInput>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmInteractionInputV2 = {
  readonly interactionId?: InputMaybe<Scalars['String']>;
  readonly rescheduled?: InputMaybe<Scalars['Float']>;
  readonly strategy?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<Scalars['String']>;
  readonly objectiveTerm?: InputMaybe<Scalars['String']>;
  readonly directionTerm?: InputMaybe<Scalars['String']>;
  readonly viaTerm?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly outcomeTerm?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Scalars['String']>;
  readonly originalDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly scheduledDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly endDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly location?: InputMaybe<Scalars['String']>;
  readonly completedDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly completedBy?: InputMaybe<Scalars['String']>;
  readonly scheduleTime?: InputMaybe<Scalars['String']>;
  readonly entityLookupId?: InputMaybe<Scalars['String']>;
  readonly entityId?: InputMaybe<Scalars['String']>;
  readonly repGroupKeyTerm?: InputMaybe<Scalars['Float']>;
  readonly repGroupLabel?: InputMaybe<Scalars['String']>;
  readonly duration?: InputMaybe<Scalars['Float']>;
  readonly opportunities?: InputMaybe<ReadonlyArray<CrmInteractionOpportunityInputV2>>;
  readonly contacts?: InputMaybe<ReadonlyArray<CrmInteractionContactInputV2>>;
  readonly rescheduleAudit?: InputMaybe<ReadonlyArray<CrmInteractionRescheduleAuditInputV2>>;
  readonly documents?: InputMaybe<ReadonlyArray<CrmInteractionDocumentInputV2>>;
  readonly repGroups?: InputMaybe<ReadonlyArray<CrmInteractionRepGroupInputV2>>;
  readonly objectives?: InputMaybe<ReadonlyArray<CrmInteractionObjectiveInputV2>>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmInteractionObjectiveInput = {
  readonly Interaction_Objective_KeyTerm?: InputMaybe<Scalars['Float']>;
  readonly Interaction_Objective_KeyTermV2?: InputMaybe<Scalars['String']>;
  readonly Interaction_Objective_Value?: InputMaybe<Scalars['String']>;
  readonly Interaction_DDT_Lookup_Id?: InputMaybe<Scalars['String']>;
  readonly Interaction_Id?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmInteractionObjectiveInputV2 = {
  readonly objectiveValue?: InputMaybe<Scalars['String']>;
  readonly lookupId?: InputMaybe<Scalars['String']>;
  readonly interactionId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmInteractionObjectiveOutput = {
  readonly __typename?: 'CrmInteractionObjectiveOutput';
  readonly Interaction_Objective_Id: Scalars['String'];
  /** @deprecated Interaction_Objective_KeyTerm is deprecated. Use Interaction_Objective_KeyTermV2 instead. */
  readonly Interaction_Objective_KeyTerm: Scalars['Float'];
  readonly Interaction_Objective_KeyTermV2?: Maybe<Scalars['String']>;
  readonly Interaction_Objective_Value?: Maybe<Scalars['String']>;
  readonly Interaction_DDT_Lookup_Id?: Maybe<Scalars['String']>;
  readonly Interaction_Id?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmInteractionObjectiveOutputV2 = {
  readonly __typename?: 'CrmInteractionObjectiveOutputV2';
  readonly id: Scalars['String'];
  readonly objectiveKeyTerm?: Maybe<Scalars['String']>;
  readonly objectiveValue?: Maybe<Scalars['String']>;
  readonly lookupId?: Maybe<Scalars['String']>;
  readonly interactionId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmInteractionOpportunityInput = {
  readonly Opportunity_Id: Scalars['String'];
  readonly Interaction_Id?: InputMaybe<Scalars['String']>;
  readonly Opportunity_Name?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmInteractionOpportunityInputV2 = {
  readonly opportunityId: Scalars['String'];
  readonly interactionId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmInteractionOpportunityOutput = {
  readonly __typename?: 'CrmInteractionOpportunityOutput';
  readonly Interaction_Opportunity_Id: Scalars['ID'];
  readonly Opportunity_Id: Scalars['String'];
  readonly Interaction_Id?: Maybe<Scalars['String']>;
  readonly Opportunity_Name?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmInteractionOpportunityOutputV2 = {
  readonly __typename?: 'CrmInteractionOpportunityOutputV2';
  readonly id: Scalars['ID'];
  readonly opportunityId: Scalars['String'];
  readonly interactionId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmInteractionOutput = {
  readonly __typename?: 'CrmInteractionOutput';
  readonly Interaction_Id: Scalars['ID'];
  readonly Rescheduled?: Maybe<Scalars['Float']>;
  readonly Strategy?: Maybe<Scalars['String']>;
  readonly Status?: Maybe<Scalars['String']>;
  readonly ObjectiveTerm?: Maybe<Scalars['String']>;
  readonly DirectionTerm?: Maybe<Scalars['String']>;
  readonly ViaTerm?: Maybe<Scalars['String']>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly OutcomeTerm?: Maybe<Scalars['String']>;
  readonly Interaction_Type?: Maybe<Scalars['String']>;
  readonly Original_Date_Time?: Maybe<Scalars['DateTime']>;
  readonly Scheduled_Date_Time?: Maybe<Scalars['DateTime']>;
  readonly Completed_Date_Time?: Maybe<Scalars['DateTime']>;
  /** @deprecated Completed_By is deprecated. Use CompletedBy instead. */
  readonly Completed_By?: Maybe<Scalars['String']>;
  readonly Interaction_Schedule_Time?: Maybe<Scalars['String']>;
  readonly Interaction_Entity_Lookup_Id?: Maybe<Scalars['String']>;
  readonly Entity_Id?: Maybe<Scalars['String']>;
  readonly crm_Interaction_Opportunity?: Maybe<ReadonlyArray<CrmInteractionOpportunityOutput>>;
  readonly crm_Interaction_Contact?: Maybe<ReadonlyArray<CrmInteractionContactOutput>>;
  readonly crm_Interaction_Reschedule_Audit?: Maybe<ReadonlyArray<CrmInteractionRescheduleOutput>>;
  readonly crm_Interaction_Document?: Maybe<ReadonlyArray<CrmInteractionDocumentOutput>>;
  readonly crm_Interaction_Rep_Group?: Maybe<ReadonlyArray<CrmInteractionRepGroupOutput>>;
  readonly crm_Interaction_Objective?: Maybe<ReadonlyArray<CrmInteractionObjectiveOutput>>;
  readonly CompletedBy?: Maybe<User>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly Interaction_Rep_Group_KeyTerm?: Maybe<Scalars['Float']>;
  readonly RepGroupField1?: Maybe<Scalars['String']>;
};

export type CrmInteractionOutputV2 = {
  readonly __typename?: 'CrmInteractionOutputV2';
  readonly id: Scalars['ID'];
  readonly rescheduled?: Maybe<Scalars['Float']>;
  readonly strategy?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly objectiveTerm?: Maybe<Scalars['String']>;
  readonly directionTerm?: Maybe<Scalars['String']>;
  readonly viaTerm?: Maybe<Scalars['String']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly outcomeTerm?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly originalDateTime?: Maybe<Scalars['DateTime']>;
  readonly scheduledDateTime?: Maybe<Scalars['DateTime']>;
  readonly endDateTime?: Maybe<Scalars['DateTime']>;
  readonly location?: Maybe<Scalars['String']>;
  readonly completedDateTime?: Maybe<Scalars['DateTime']>;
  readonly scheduleTime?: Maybe<Scalars['String']>;
  readonly entityLookupId?: Maybe<Scalars['String']>;
  readonly entityId?: Maybe<Scalars['String']>;
  readonly duration?: Maybe<Scalars['Float']>;
  readonly opportunities?: Maybe<ReadonlyArray<CrmInteractionOpportunityOutputV2>>;
  readonly contacts?: Maybe<ReadonlyArray<CrmInteractionContactOutputV2>>;
  readonly rescheduleAudit?: Maybe<ReadonlyArray<CrmInteractionRescheduleAuditOutputV2>>;
  readonly documents?: Maybe<ReadonlyArray<CrmInteractionDocumentOutputV2>>;
  readonly repGroups?: Maybe<ReadonlyArray<CrmInteractionRepGroupOutputV2>>;
  readonly objectives?: Maybe<ReadonlyArray<CrmInteractionObjectiveOutputV2>>;
  readonly completedBy?: Maybe<User>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly completedByV2?: Maybe<UserV2>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly repGroupKeyTerm?: Maybe<Scalars['Float']>;
  readonly repGroupLabel?: Maybe<Scalars['String']>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly interactionStatus?: Maybe<Scalars['String']>;
  readonly interactionId?: Maybe<Scalars['String']>;
};

export type CrmInteractionOutputV2Edge = {
  readonly __typename?: 'CrmInteractionOutputV2Edge';
  readonly cursor: Scalars['String'];
  readonly node: CrmInteractionOutputV2;
};

export type CrmInteractionRepGroupInput = {
  readonly Assigned_Rep_Group_Lookup_Id?: InputMaybe<Scalars['String']>;
  readonly Assigned_Rep_Group_Id?: InputMaybe<Scalars['String']>;
  readonly Rep_Id?: InputMaybe<Scalars['String']>;
  readonly RepId?: InputMaybe<Scalars['String']>;
  readonly Interaction_Id?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly Active?: InputMaybe<Scalars['Float']>;
  readonly Rep_Group_Name?: InputMaybe<Scalars['String']>;
};

export type CrmInteractionRepGroupInputV2 = {
  readonly repGroupLookupId?: InputMaybe<Scalars['String']>;
  readonly repGroupId?: InputMaybe<Scalars['String']>;
  readonly repId?: InputMaybe<Scalars['String']>;
  readonly interactionId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly active?: InputMaybe<Scalars['Float']>;
  readonly repGroupName?: InputMaybe<Scalars['String']>;
};

export type CrmInteractionRepGroupLookupInputV2 = {
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly keyTerm?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

export type CrmInteractionRepGroupLookupOutputV2 = {
  readonly __typename?: 'CrmInteractionRepGroupLookupOutputV2';
  readonly id: Scalars['ID'];
  readonly type?: Maybe<Scalars['String']>;
  readonly keyTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmInteractionRepGroupOutput = {
  readonly __typename?: 'CrmInteractionRepGroupOutput';
  readonly Interaction_Rep_Group_Id: Scalars['ID'];
  readonly Assigned_Rep_Group_Lookup_Id?: Maybe<Scalars['String']>;
  readonly Assigned_Rep_Group_Id?: Maybe<Scalars['String']>;
  /** @deprecated Rep_Id is deprecated. Use RepId instead. */
  readonly Rep_Id?: Maybe<User>;
  readonly RepId?: Maybe<Employee>;
  readonly Interaction_Id?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly Active?: Maybe<Scalars['Float']>;
  readonly Rep_Group_Name?: Maybe<Scalars['String']>;
};

export type CrmInteractionRepGroupOutputV2 = {
  readonly __typename?: 'CrmInteractionRepGroupOutputV2';
  readonly id: Scalars['ID'];
  readonly repGroupLookupId?: Maybe<Scalars['String']>;
  readonly repGroupId?: Maybe<Scalars['String']>;
  readonly repId?: Maybe<Employee>;
  readonly repIdV2?: Maybe<EmployeeV2>;
  readonly interactionId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly active?: Maybe<Scalars['Float']>;
  readonly repGroupName?: Maybe<Scalars['String']>;
};

export type CrmInteractionRescheduleAuditInputV2 = {
  readonly scheduledDateTime: Scalars['DateTime'];
  readonly endDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly location?: InputMaybe<Scalars['String']>;
  readonly reasonTerm?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly interactionId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly duration?: InputMaybe<Scalars['Float']>;
};

export type CrmInteractionRescheduleAuditOutputV2 = {
  readonly __typename?: 'CrmInteractionRescheduleAuditOutputV2';
  readonly id: Scalars['ID'];
  readonly scheduledDateTime: Scalars['DateTime'];
  /** @deprecated endDateTime is deprecated, Use duration instead */
  readonly endDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated location is deprecated, not required */
  readonly location?: Maybe<Scalars['String']>;
  readonly reasonTerm?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly interactionId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly duration?: Maybe<Scalars['Float']>;
};

export type CrmInteractionRescheduleInput = {
  readonly Scheduled_Date_Time: Scalars['DateTime'];
  readonly ReasonTerm?: InputMaybe<Scalars['String']>;
  readonly Notes?: InputMaybe<Scalars['String']>;
  readonly Interaction_Id?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmInteractionRescheduleOutput = {
  readonly __typename?: 'CrmInteractionRescheduleOutput';
  readonly Interaction_Reschedule_Audit_Id: Scalars['ID'];
  readonly Scheduled_Date_Time: Scalars['DateTime'];
  readonly ReasonTerm?: Maybe<Scalars['String']>;
  readonly Notes?: Maybe<Scalars['String']>;
  readonly Interaction_Id?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmMatchingLogicContactPayload = {
  readonly __typename?: 'CrmMatchingLogicContactPayload';
  readonly errors?: Maybe<ReadonlyArray<ContactError>>;
  readonly contact?: Maybe<CrmContactOutput>;
  readonly contactDuplicate?: Maybe<ReadonlyArray<CrmContactOutput>>;
  readonly actionCode?: Maybe<Scalars['String']>;
  readonly exceptionList?: Maybe<CrmContactDedupeExceptionListOutput>;
};

export type CrmOpportunitiesNamesOutput = {
  readonly __typename?: 'CrmOpportunitiesNamesOutput';
  readonly opportunityId: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly entityId: Scalars['String'];
  readonly entityLookupId?: Maybe<Scalars['String']>;
};

export type CrmOpportunityBusinessUnitInput = {
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly Business_UnitTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityBusinessUnitInputV2 = {
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityBusinessUnitOutput = {
  readonly __typename?: 'crmOpportunityBusinessUnitOutput';
  readonly Opportunity_Business_Unit_Id: Scalars['ID'];
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly Business_UnitTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityBusinessUnitOutputV2 = {
  readonly __typename?: 'CrmOpportunityBusinessUnitOutputV2';
  readonly opportunityBusinessUnitId: Scalars['ID'];
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly businessUnitTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityConnection = {
  readonly __typename?: 'CrmOpportunityConnection';
  readonly edges: ReadonlyArray<CrmOpportunityOutputV2Edge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
  readonly totalOpportunitiesCount: Scalars['Int'];
};

export type CrmOpportunityContactInput = {
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Name?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityContactInputV2 = {
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly contactId?: InputMaybe<Scalars['String']>;
  readonly contactName?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityContactOutput = {
  readonly __typename?: 'crmOpportunityContactOutput';
  readonly Opportunity_Contact_Id: Scalars['ID'];
  readonly Contact_Id: Scalars['String'];
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly Contact_Name?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityContactOutputV2 = {
  readonly __typename?: 'CrmOpportunityContactOutputV2';
  readonly opportunityContactId: Scalars['ID'];
  readonly contactId: Scalars['String'];
  readonly opportunityId?: Maybe<Scalars['String']>;
  /** @deprecated contactName is deprecated as we are not using */
  readonly contactName?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityDocumentInputV2 = {
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly directionTerm?: InputMaybe<Scalars['String']>;
  readonly documentId?: InputMaybe<Scalars['String']>;
  readonly documentName?: InputMaybe<Scalars['String']>;
  readonly documentPath?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly sourceTerm?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

export type CrmOpportunityDocumentOutputV2 = {
  readonly __typename?: 'CrmOpportunityDocumentOutputV2';
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly details?: Maybe<Scalars['String']>;
  readonly directionTerm?: Maybe<Scalars['String']>;
  readonly documentId?: Maybe<Scalars['String']>;
  readonly documentName?: Maybe<Scalars['String']>;
  readonly documentPath?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly sourceTerm?: Maybe<Scalars['String']>;
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
};

export type CrmOpportunityEntityLookupOutputV2 = {
  readonly __typename?: 'CrmOpportunityEntityLookupOutputV2';
  readonly entityLookupId: Scalars['ID'];
  readonly entityType?: Maybe<Scalars['Float']>;
  readonly entityName?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityEquipmentInput = {
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly EquipmentTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityEquipmentInputV2 = {
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly equipmentTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityEquipmentOutput = {
  readonly __typename?: 'crmOpportunityEquipmentOutput';
  readonly Opportunity_Equipment_Id: Scalars['ID'];
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly EquipmentTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityEquipmentOutputV2 = {
  readonly __typename?: 'CrmOpportunityEquipmentOutputV2';
  readonly opportunityEquipmentId: Scalars['ID'];
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly equipmentTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityFilter = {
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly projectedVolume?: InputMaybe<Scalars['Float']>;
  readonly projectedRevenue?: InputMaybe<Scalars['Float']>;
  readonly projectedMargin?: InputMaybe<Scalars['Float']>;
  readonly totalVolume?: InputMaybe<Scalars['Float']>;
  readonly totalRevenue?: InputMaybe<Scalars['Float']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly source?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly type?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly stage?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly closeReason?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly division?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly size?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equipment?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly solutionType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly solution?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly pricingStrategy?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly dueDate?: InputMaybe<Scalars['DateTime']>;
  readonly awardDate?: InputMaybe<Scalars['DateTime']>;
  readonly goLiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly revisitDate?: InputMaybe<Scalars['DateTime']>;
  readonly contacts?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdBy?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly updatedBy?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly entityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly entityType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly filterRepId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly filterStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly mainType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainStage?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainEquipment?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainSolutionType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainSolution?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainEntityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainPricingStrategy?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainCloseReason?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainDueDate?: InputMaybe<Scalars['DateTime']>;
  readonly mainAwardDate?: InputMaybe<Scalars['DateTime']>;
  readonly mainGoLiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly mainRevisitDate?: InputMaybe<Scalars['DateTime']>;
  readonly mainDueDateEnd?: InputMaybe<Scalars['DateTime']>;
  readonly mainAwardDateEnd?: InputMaybe<Scalars['DateTime']>;
  readonly mainGoLiveDateEnd?: InputMaybe<Scalars['DateTime']>;
  readonly mainRevisitDateEnd?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityIbRegionInput = {
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly Region?: InputMaybe<Scalars['String']>;
  readonly IB_Regions_Data?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityIbRegionInputV2 = {
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly ibRegionsData?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityIbRegionOutput = {
  readonly __typename?: 'crmOpportunityIBRegionOutput';
  readonly Opportunity_IBRegion_Id: Scalars['ID'];
  /** @deprecated Region is deprecated, Use IB_Regions_Data instead */
  readonly Region?: Maybe<Scalars['String']>;
  readonly IB_Regions_Data?: Maybe<Scalars['String']>;
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityIbRegionOutputV2 = {
  readonly __typename?: 'CrmOpportunityIBRegionOutputV2';
  readonly opportunityIBRegionId: Scalars['ID'];
  readonly ibRegionsData?: Maybe<Scalars['String']>;
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityInput = {
  readonly Name?: InputMaybe<Scalars['String']>;
  readonly StageTerm?: InputMaybe<Scalars['String']>;
  readonly TypeTerm?: InputMaybe<Scalars['String']>;
  readonly DivisionTerm?: InputMaybe<Scalars['String']>;
  readonly Solution_TypeTerm?: InputMaybe<Scalars['String']>;
  readonly Total_Opportunity_Volume?: InputMaybe<Scalars['Float']>;
  readonly Total_Opportunity_Revenue?: InputMaybe<Scalars['Float']>;
  readonly Projected_Volume?: InputMaybe<Scalars['Float']>;
  readonly Projected_Revenue?: InputMaybe<Scalars['Float']>;
  readonly Projected_Margin?: InputMaybe<Scalars['Float']>;
  readonly Pricing_StrategyTerm?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly Due_Date?: InputMaybe<Scalars['DateTime']>;
  readonly Award_Date?: InputMaybe<Scalars['DateTime']>;
  readonly Golive_Date?: InputMaybe<Scalars['DateTime']>;
  readonly StatusTerm?: InputMaybe<Scalars['String']>;
  readonly Revisit_Date?: InputMaybe<Scalars['DateTime']>;
  readonly Last_Closed_Date?: InputMaybe<Scalars['DateTime']>;
  readonly Entity_Id?: InputMaybe<Scalars['String']>;
  readonly crm_Opportunity_Business_Unit?: InputMaybe<ReadonlyArray<CrmOpportunityBusinessUnitInput>>;
  readonly crm_Opportunity_Contact?: InputMaybe<ReadonlyArray<CrmOpportunityContactInput>>;
  readonly crm_Opportunity_Equipment?: InputMaybe<ReadonlyArray<CrmOpportunityEquipmentInput>>;
  readonly crm_Opportunity_IBRegion?: InputMaybe<ReadonlyArray<CrmOpportunityIbRegionInput>>;
  readonly crm_Opportunity_Mode?: InputMaybe<ReadonlyArray<CrmOpportunityModeInput>>;
  readonly crm_Opportunity_OBRegion?: InputMaybe<ReadonlyArray<CrmOpportunityObRegionInput>>;
  readonly crm_Opportunity_Reps?: InputMaybe<ReadonlyArray<CrmOpportunityRepsInput>>;
  readonly crm_Opportunity_Size?: InputMaybe<ReadonlyArray<CrmOpportunitySizeInput>>;
  readonly crm_Opportunity_Solution?: InputMaybe<ReadonlyArray<CrmOpportunitySolutionInput>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityInputV2 = {
  readonly name?: InputMaybe<Scalars['String']>;
  readonly sourceTerm?: InputMaybe<Scalars['String']>;
  readonly closeReasonTerm?: InputMaybe<Scalars['String']>;
  readonly stageTerm?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly solutionTypeTerm?: InputMaybe<Scalars['String']>;
  readonly totalOpportunityVolume?: InputMaybe<Scalars['Float']>;
  readonly totalOpportunityRevenue?: InputMaybe<Scalars['Float']>;
  readonly projectedVolume?: InputMaybe<Scalars['Float']>;
  readonly projectedRevenue?: InputMaybe<Scalars['Float']>;
  readonly projectedMargin?: InputMaybe<Scalars['Float']>;
  readonly pricingStrategyTerm?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly dueDate?: InputMaybe<Scalars['DateTime']>;
  readonly awardDate?: InputMaybe<Scalars['DateTime']>;
  readonly goLiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly statusTerm?: InputMaybe<Scalars['String']>;
  readonly revisitDate?: InputMaybe<Scalars['DateTime']>;
  readonly lastClosedDate?: InputMaybe<Scalars['DateTime']>;
  readonly entityId?: InputMaybe<Scalars['String']>;
  readonly entityLookupId?: InputMaybe<Scalars['String']>;
  readonly crmOpportunityBusinessUnit?: InputMaybe<ReadonlyArray<CrmOpportunityBusinessUnitInputV2>>;
  readonly crmOpportunityContact?: InputMaybe<ReadonlyArray<CrmOpportunityContactInputV2>>;
  readonly crmOpportunityEquipment?: InputMaybe<ReadonlyArray<CrmOpportunityEquipmentInputV2>>;
  readonly crmOpportunityIbRegion?: InputMaybe<ReadonlyArray<CrmOpportunityIbRegionInputV2>>;
  readonly crmOpportunityMode?: InputMaybe<ReadonlyArray<CrmOpportunityModeInputV2>>;
  readonly crmOpportunityObRegion?: InputMaybe<ReadonlyArray<CrmOpportunityObRegionInputV2>>;
  readonly crmOpportunityReps?: InputMaybe<ReadonlyArray<CrmOpportunityRepsInputV2>>;
  readonly crmOpportunitySize?: InputMaybe<ReadonlyArray<CrmOpportunitySizeInputV2>>;
  readonly crmOpportunitySolution?: InputMaybe<ReadonlyArray<CrmOpportunitySolutionInputV2>>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityModeInput = {
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly ModeTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityModeInputV2 = {
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly modeTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityModeOutput = {
  readonly __typename?: 'crmOpportunityModeOutput';
  readonly Opportunity_Mode_Id: Scalars['ID'];
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly ModeTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityModeOutputV2 = {
  readonly __typename?: 'CrmOpportunityModeOutputV2';
  readonly opportunityModeId: Scalars['ID'];
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly modeTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityObRegionInput = {
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly Region?: InputMaybe<Scalars['String']>;
  readonly OB_Regions_Data?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityObRegionInputV2 = {
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly obRegionsData?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityObRegionOutput = {
  readonly __typename?: 'crmOpportunityOBRegionOutput';
  readonly Opportunity_OBRegion_Id: Scalars['ID'];
  /** @deprecated Region is deprecated, Use OB_Regions_Data instead */
  readonly Region?: Maybe<Scalars['String']>;
  readonly OB_Regions_Data?: Maybe<Scalars['String']>;
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityObRegionOutputV2 = {
  readonly __typename?: 'CrmOpportunityOBRegionOutputV2';
  readonly opportunityOBRegionId: Scalars['ID'];
  readonly obRegionsData?: Maybe<Scalars['String']>;
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityOutput = {
  readonly __typename?: 'CrmOpportunityOutput';
  readonly Opportunity_Id: Scalars['ID'];
  readonly Name?: Maybe<Scalars['String']>;
  readonly StageTerm?: Maybe<Scalars['String']>;
  readonly TypeTerm?: Maybe<Scalars['String']>;
  readonly DivisionTerm?: Maybe<Scalars['String']>;
  readonly Solution_TypeTerm?: Maybe<Scalars['String']>;
  readonly Total_Opportunity_Volume?: Maybe<Scalars['Float']>;
  readonly Total_Opportunity_Revenue?: Maybe<Scalars['Float']>;
  readonly Projected_Volume?: Maybe<Scalars['Float']>;
  readonly Projected_Revenue?: Maybe<Scalars['Float']>;
  readonly Projected_Margin?: Maybe<Scalars['Float']>;
  readonly Pricing_StrategyTerm?: Maybe<Scalars['String']>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly Due_Date?: Maybe<Scalars['DateTime']>;
  readonly Award_Date?: Maybe<Scalars['DateTime']>;
  readonly Golive_Date?: Maybe<Scalars['DateTime']>;
  readonly StatusTerm?: Maybe<Scalars['String']>;
  readonly Revisit_Date?: Maybe<Scalars['DateTime']>;
  readonly Last_Closed_Date?: Maybe<Scalars['DateTime']>;
  readonly Entity_Id: Scalars['String'];
  readonly crm_Opportunity_Business_Unit?: Maybe<ReadonlyArray<CrmOpportunityBusinessUnitOutput>>;
  readonly crm_Opportunity_Contact?: Maybe<ReadonlyArray<CrmOpportunityContactOutput>>;
  readonly crm_Opportunity_Equipment?: Maybe<ReadonlyArray<CrmOpportunityEquipmentOutput>>;
  readonly crm_Opportunity_IBRegion?: Maybe<ReadonlyArray<CrmOpportunityIbRegionOutput>>;
  readonly crm_Opportunity_Mode?: Maybe<ReadonlyArray<CrmOpportunityModeOutput>>;
  readonly crm_Opportunity_OBRegion?: Maybe<ReadonlyArray<CrmOpportunityObRegionOutput>>;
  readonly crm_Opportunity_Reps?: Maybe<ReadonlyArray<CrmOpportunityRepsOutput>>;
  readonly crm_Opportunity_Size?: Maybe<ReadonlyArray<CrmOpportunitySizeOutput>>;
  readonly crm_Opportunity_Solution?: Maybe<ReadonlyArray<CrmOpportunitySolutionOutput>>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityOutputV2 = {
  readonly __typename?: 'CrmOpportunityOutputV2';
  readonly opportunityId: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly sourceTerm?: Maybe<Scalars['String']>;
  readonly closeReasonTerm?: Maybe<Scalars['String']>;
  readonly stageTerm?: Maybe<Scalars['String']>;
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  readonly solutionTypeTerm?: Maybe<Scalars['String']>;
  readonly totalOpportunityVolume?: Maybe<Scalars['Float']>;
  readonly totalOpportunityRevenue?: Maybe<Scalars['Float']>;
  readonly projectedVolume?: Maybe<Scalars['Float']>;
  readonly projectedRevenue?: Maybe<Scalars['Float']>;
  readonly projectedMargin?: Maybe<Scalars['Float']>;
  readonly pricingStrategyTerm?: Maybe<Scalars['String']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly dueDate?: Maybe<Scalars['DateTime']>;
  readonly awardDate?: Maybe<Scalars['DateTime']>;
  readonly goLiveDate?: Maybe<Scalars['DateTime']>;
  readonly statusTerm?: Maybe<Scalars['String']>;
  readonly revisitDate?: Maybe<Scalars['DateTime']>;
  readonly lastClosedDate?: Maybe<Scalars['DateTime']>;
  readonly entityId: Scalars['String'];
  readonly entityLookupId?: Maybe<Scalars['String']>;
  readonly crmOpportunityBusinessUnit?: Maybe<ReadonlyArray<CrmOpportunityBusinessUnitOutputV2>>;
  readonly crmOpportunityContact?: Maybe<ReadonlyArray<CrmOpportunityContactOutputV2>>;
  readonly crmOpportunityEquipment?: Maybe<ReadonlyArray<CrmOpportunityEquipmentOutputV2>>;
  readonly crmOpportunityIbRegion?: Maybe<ReadonlyArray<CrmOpportunityIbRegionOutputV2>>;
  readonly crmOpportunityMode?: Maybe<ReadonlyArray<CrmOpportunityModeOutputV2>>;
  readonly crmOpportunityObRegion?: Maybe<ReadonlyArray<CrmOpportunityObRegionOutputV2>>;
  readonly crmOpportunityReps?: Maybe<ReadonlyArray<CrmOpportunityRepsOutputV2>>;
  readonly crmOpportunitySize?: Maybe<ReadonlyArray<CrmOpportunitySizeOutputV2>>;
  readonly crmOpportunitySolution?: Maybe<ReadonlyArray<CrmOpportunitySolutionOutputV2>>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityOutputV2Edge = {
  readonly __typename?: 'CrmOpportunityOutputV2Edge';
  readonly cursor: Scalars['String'];
  readonly node: CrmOpportunityOutputV2;
};

export type CrmOpportunityRepsInput = {
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly Rep_Id?: InputMaybe<Scalars['String']>;
  readonly Rep_Name?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityRepsInputV2 = {
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly repId?: InputMaybe<Scalars['String']>;
  readonly rep?: InputMaybe<Scalars['String']>;
  readonly repName?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityRepsOutput = {
  readonly __typename?: 'crmOpportunityRepsOutput';
  readonly Opportunity_Rep_Id: Scalars['ID'];
  readonly Rep_Id: Scalars['String'];
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly Rep_Name?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityRepsOutputV2 = {
  readonly __typename?: 'CrmOpportunityRepsOutputV2';
  readonly opportunityRepId: Scalars['ID'];
  /** @deprecated repId is deprecated, Use rep instead */
  readonly repId: Scalars['String'];
  readonly rep?: Maybe<Employee>;
  readonly repV2?: Maybe<EmployeeV2>;
  readonly opportunityId?: Maybe<Scalars['String']>;
  /** @deprecated repName is deprecated as we are not using */
  readonly repName?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunitySizeInput = {
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly SizeTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunitySizeInputV2 = {
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly sizeTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunitySizeOutput = {
  readonly __typename?: 'crmOpportunitySizeOutput';
  readonly Opportunity_Size_Id: Scalars['ID'];
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly SizeTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunitySizeOutputV2 = {
  readonly __typename?: 'CrmOpportunitySizeOutputV2';
  readonly opportunitySizeId: Scalars['ID'];
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly sizeTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunitySolutionInput = {
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly SolutionTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunitySolutionInputV2 = {
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly solutionTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunitySolutionOutput = {
  readonly __typename?: 'crmOpportunitySolutionOutput';
  readonly Opportunity_Solution_Id: Scalars['ID'];
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly SolutionTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunitySolutionOutputV2 = {
  readonly __typename?: 'CrmOpportunitySolutionOutputV2';
  readonly opportunitySolutionId: Scalars['ID'];
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly solutionTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrossLoadStop = {
  readonly __typename?: 'CrossLoadStop';
  readonly id: Scalars['ID'];
  readonly originRouteStop?: Maybe<RouteStopV2>;
  readonly destinationRouteStop?: Maybe<RouteStopV2>;
};

export type CsvHeader = {
  readonly __typename?: 'CsvHeader';
  readonly id?: Maybe<Scalars['ID']>;
  readonly version: Scalars['Int'];
  readonly documentType: Scalars['String'];
  readonly columns?: Maybe<ReadonlyArray<Maybe<CsvHeaderColumns>>>;
};

export type CsvHeaderColumns = {
  readonly __typename?: 'CsvHeaderColumns';
  readonly fieldName: Scalars['String'];
  readonly required: Scalars['Boolean'];
};

export enum CtcPassThroughTypeEnum {
  UseCustomerSetup = 'UseCustomerSetup',
  PassThrough = 'PassThrough',
  PassThroughPlusAmount = 'PassThroughPlusAmount',
  PassThroughPlusPercent = 'PassThroughPlusPercent',
  DoNotPassThrough = 'DoNotPassThrough'
}

export enum CtcPerUnitTypeEnum {
  Unit = 'Unit',
  Mile = 'Mile',
  Hour = 'Hour',
  Occurence = 'Occurence',
  Occurrence = 'Occurrence',
  Stop = 'Stop',
  Pound = 'Pound',
  Kilogram = 'Kilogram',
  Percent = 'Percent',
  Inch = 'Inch',
  Foot = 'Foot',
  Minute = 'Minute',
  Day = 'Day',
  HundredWeight = 'HundredWeight',
  Shipment = 'Shipment'
}

export enum CtcUsedOnEnum {
  Both = 'Both',
  Order = 'Order',
  Route = 'Route'
}

export enum CtcUsedOnEnumV2 {
  Order = 'Order',
  Route = 'Route',
  OtherPay = 'OtherPay'
}

export type Currency = {
  readonly __typename?: 'Currency';
  readonly unit: UnitOfCurrencyEnum;
  readonly value: Scalars['Float'];
};

export type CurrencyInput = {
  readonly value: Scalars['Float'];
  readonly unit?: InputMaybe<UnitOfCurrencyEnum>;
};

export type CurrencyInputType = {
  readonly unit?: InputMaybe<CostQuoteUnitOfCurrency>;
  readonly currencyunit?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['Decimal']>;
};

export type CurrencyInputV2 = {
  readonly value: Scalars['Float'];
  readonly unit?: InputMaybe<UnitOfCurrencyEnumV2>;
};

export type CurrencyOutputValue = {
  readonly __typename?: 'CurrencyOutputValue';
  readonly currencyunit?: Maybe<TenantConfiguration>;
  readonly value?: Maybe<Scalars['Decimal']>;
};

export type CurrencyV2 = {
  readonly __typename?: 'CurrencyV2';
  readonly value: Scalars['Float'];
  readonly unit: UnitOfCurrencyEnumV2;
};

export type CurrencyValue = {
  readonly __typename?: 'CurrencyValue';
  readonly unit?: Maybe<CostQuoteUnitOfCurrency>;
  readonly value?: Maybe<Scalars['Decimal']>;
};

/** The latest intraday exchange rate used to convert from one currency to another */
export type CurrentRate = {
  readonly __typename?: 'CurrentRate';
  readonly date: Scalars['DateTime'];
  readonly fromCurrency: Scalars['String'];
  readonly toCurrency: Scalars['String'];
  readonly rate: Scalars['Float'];
};

export type CursorPageInfo = {
  readonly __typename?: 'CursorPageInfo';
  readonly lastCursor?: Maybe<Scalars['String']>;
  readonly hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type CustomDistanceInput = {
  readonly distance?: InputMaybe<Scalars['Decimal']>;
  readonly unit?: InputMaybe<Scalars['String']>;
};

export type CustomDistanceResponse = {
  readonly __typename?: 'CustomDistanceResponse';
  readonly distance?: Maybe<Scalars['Decimal']>;
  readonly unit?: Maybe<RateQuoteKeyValue>;
};

export type Customer = {
  readonly __typename?: 'Customer';
  readonly id: Scalars['ID'];
  readonly accountsReceivableOverdue?: Maybe<Scalars['Decimal']>;
  readonly isTaxExempt?: Maybe<Scalars['Boolean']>;
  readonly autoInvoiceEnabled?: Maybe<Scalars['Boolean']>;
  readonly accountingStatus?: Maybe<Scalars['String']>;
  readonly hasPropertyMetadata: Scalars['Boolean'];
  readonly eid?: Maybe<Scalars['Long']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly propertyMetadata?: Maybe<ReadonlyArray<KeyValuePairOfStringAndPropertyMetadata>>;
  readonly ratingSourceId?: Maybe<Scalars['String']>;
  readonly priorityQuoteType?: Maybe<Scalars['String']>;
  readonly availableCredit?: Maybe<Scalars['Float']>;
  readonly customerStructures?: Maybe<ReadonlyArray<CustomerStructure>>;
  readonly noteDefaults?: Maybe<ReadonlyArray<NoteDefault>>;
  readonly customerFacilities?: Maybe<ReadonlyArray<CustomerFacility>>;
  readonly customerRegions?: Maybe<ReadonlyArray<CustomerRegion>>;
  readonly customerCommodities?: Maybe<ReadonlyArray<CustomerCommodity>>;
  readonly loadDefaults?: Maybe<LoadDefaults>;
  readonly customerCustomerRelationship?: Maybe<ReadonlyArray<CustomerCustomerRelationship>>;
  readonly serviceTolerances?: Maybe<ReadonlyArray<ServiceTolerance>>;
  readonly accountingNotes?: Maybe<ReadonlyArray<Note>>;
  readonly addresses?: Maybe<ReadonlyArray<Address>>;
  readonly allNotes?: Maybe<ReadonlyArray<Note>>;
  readonly ancestorIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly billToCustomer?: Maybe<Customer>;
  readonly billToCustomerId?: Maybe<Scalars['ID']>;
  readonly billingAddress?: Maybe<Address>;
  readonly billingEmail?: Maybe<Scalars['String']>;
  readonly carrierRelationships?: Maybe<ReadonlyArray<CarrierCustomerRelationship>>;
  readonly children?: Maybe<ReadonlyArray<Customer>>;
  readonly code: Scalars['String'];
  readonly contacts: ReadonlyArray<Contact>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly creditHistories?: Maybe<ReadonlyArray<CreditHistory>>;
  readonly creditLimit?: Maybe<Scalars['Float']>;
  readonly creditProviders?: Maybe<ReadonlyArray<CustomerCreditProvider>>;
  readonly creditStatus?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<KeyValue>;
  readonly currencyId?: Maybe<Scalars['ID']>;
  /** @deprecated Use credit status */
  readonly customerCreditStatus?: Maybe<KeyValue>;
  /** @deprecated Use credit status */
  readonly customerCreditStatusId?: Maybe<Scalars['ID']>;
  readonly customerLevelType?: Maybe<KeyValue>;
  readonly customerLevelTypeId?: Maybe<Scalars['ID']>;
  readonly customerOpportunityType?: Maybe<KeyValue>;
  readonly customerOpportunityTypeId?: Maybe<Scalars['ID']>;
  readonly customerPaymentTerm?: Maybe<KeyValue>;
  readonly customerPaymentTermId?: Maybe<Scalars['ID']>;
  readonly customerSalesProgressType?: Maybe<KeyValue>;
  readonly customerStatusType?: Maybe<KeyValue>;
  readonly customerStatusTypeId?: Maybe<Scalars['ID']>;
  readonly descendantIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly electronicTracking?: Maybe<Scalars['Boolean']>;
  readonly electronicTrackingNumber?: Maybe<Scalars['String']>;
  readonly electronicTrackingType?: Maybe<KeyValue>;
  readonly facilityRelationships?: Maybe<ReadonlyArray<CustomerFacilityRelationship>>;
  readonly groups?: Maybe<ReadonlyArray<CustomerGroup>>;
  readonly identifiers: ReadonlyArray<Identifier>;
  readonly insuranceCargo: Scalars['Int'];
  readonly insuranceGeneral: Scalars['Int'];
  readonly insuranceLiability: Scalars['Int'];
  readonly invoiceDistributionMethod?: Maybe<KeyValue>;
  readonly invoiceDistributionMethodId?: Maybe<Scalars['ID']>;
  readonly invoiceGrouping?: Maybe<InvoiceGroupingEnum>;
  readonly mainAddress?: Maybe<Address>;
  readonly mainContact?: Maybe<Contact>;
  readonly mainRep?: Maybe<CustomerRep>;
  readonly name: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly parent?: Maybe<Customer>;
  readonly paymentMethod?: Maybe<KeyValue>;
  readonly paymentMethodId?: Maybe<Scalars['ID']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly reps?: Maybe<ReadonlyArray<CustomerRep>>;
  readonly roeStatus?: Maybe<KeyValue>;
  readonly settings?: Maybe<CustomerSetting>;
  readonly siblings?: Maybe<ReadonlyArray<Customer>>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
  readonly useParentCredit?: Maybe<Scalars['Boolean']>;
  readonly website?: Maybe<Scalars['String']>;
};


export type CustomerCustomerFacilitiesArgs = {
  facilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type CustomerAccessorialRuleSet = {
  readonly __typename?: 'CustomerAccessorialRuleSet';
  readonly id: Scalars['ID'];
  readonly rulesetId: Scalars['ID'];
  readonly accessorialEnabled?: Maybe<Scalars['Boolean']>;
  readonly trigger?: Maybe<Scalars['String']>;
  readonly chargeTypeName?: Maybe<Scalars['String']>;
  readonly chargeTypeId?: Maybe<Scalars['ID']>;
};

export type CustomerAccessorialSettingsPayload = {
  readonly __typename?: 'CustomerAccessorialSettingsPayload';
  readonly accessorialRuleSets?: Maybe<ReadonlyArray<Maybe<CustomerAccessorialRuleSet>>>;
};

export type CustomerAddress = {
  readonly __typename?: 'CustomerAddress';
  readonly addressType?: Maybe<KeyValue>;
  readonly addressTypeId?: Maybe<Scalars['ID']>;
  readonly addressableId: Scalars['ID'];
  readonly addressableType: Scalars['String'];
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly county?: Maybe<Scalars['String']>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly geopoint: Geopoint;
  readonly geopointNullable?: Maybe<Geopoint>;
  readonly id: Scalars['ID'];
  readonly isVerified: Scalars['Boolean'];
  readonly main: Scalars['Boolean'];
  readonly postalCode: Scalars['String'];
  readonly province: Scalars['String'];
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: Maybe<Scalars['String']>;
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
};

export type CustomerAddressesQueryInput = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly radius?: InputMaybe<UnitOfDistanceInput>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly street1?: InputMaybe<Scalars['String']>;
  readonly street2?: InputMaybe<Scalars['String']>;
};

export type CustomerAddressInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly addressTypeId?: InputMaybe<Scalars['ID']>;
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly county?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isVerified?: InputMaybe<Scalars['Boolean']>;
  readonly main: Scalars['Boolean'];
  readonly postalCode: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CustomerAudit = {
  readonly __typename?: 'CustomerAudit';
  readonly id: Scalars['ID'];
  readonly auditableId?: Maybe<Scalars['ID']>;
  readonly associatedId?: Maybe<Scalars['ID']>;
  readonly associatedType?: Maybe<Scalars['String']>;
  readonly userId?: Maybe<Scalars['ID']>;
  readonly userType?: Maybe<Scalars['String']>;
  readonly auditableTab?: Maybe<Scalars['String']>;
  readonly auditCategory?: Maybe<Scalars['String']>;
  readonly remoteAddress?: Maybe<Scalars['String']>;
  readonly requestUuid?: Maybe<Scalars['String']>;
  readonly version?: Maybe<Scalars['Int']>;
  readonly comment?: Maybe<Scalars['String']>;
  readonly auditDetails?: Maybe<ReadonlyArray<CustomerAuditDetails>>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly user?: Maybe<UserV2>;
  readonly actionWithFieldName?: Maybe<Scalars['String']>;
  readonly previousValues?: Maybe<Scalars['String']>;
  readonly updatedValues?: Maybe<Scalars['String']>;
};

export type CustomerAuditDetails = {
  readonly __typename?: 'CustomerAuditDetails';
  readonly auditDetailId?: Maybe<Scalars['ID']>;
  readonly auditLogId?: Maybe<Scalars['ID']>;
  readonly action?: Maybe<Scalars['String']>;
  readonly fieldName?: Maybe<Scalars['String']>;
  readonly previousValue?: Maybe<Scalars['String']>;
  readonly updatedValue?: Maybe<Scalars['String']>;
};

/** An edge in audit connection. */
export type CustomerAuditEdge = {
  readonly __typename?: 'CustomerAuditEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: CustomerAudit;
};

/** The connection type for Audits. */
export type CustomerAudits = {
  readonly __typename?: 'CustomerAudits';
  /** A list of edges. */
  readonly edges: ReadonlyArray<CustomerAuditEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type CustomerAuditsFilter = {
  readonly startDate: Scalars['String'];
  readonly endDate: Scalars['String'];
  readonly customerId: Scalars['String'];
};

export type CustomerChargesModel = {
  readonly __typename?: 'CustomerChargesModel';
  readonly chargeDescription?: Maybe<Scalars['String']>;
  readonly per?: Maybe<Scalars['Float']>;
  readonly qualifier?: Maybe<Scalars['String']>;
  readonly quantity?: Maybe<Scalars['Int']>;
  readonly rate?: Maybe<Scalars['Float']>;
};

export type CustomerChargeTypeConfigurationCreateInput = {
  readonly billToId?: InputMaybe<Scalars['Guid']>;
  readonly carrierId?: InputMaybe<Scalars['Guid']>;
  readonly carrierName?: InputMaybe<Scalars['String']>;
  readonly category?: InputMaybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly customerChargeCode?: InputMaybe<Scalars['String']>;
  readonly customerDescription?: InputMaybe<Scalars['String']>;
  readonly customerId: Scalars['Guid'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly invoiceGroupingCode?: InputMaybe<Scalars['String']>;
  readonly invoiceMethodId?: InputMaybe<Scalars['String']>;
  readonly isAllowedByCustomer: Scalars['Boolean'];
  readonly isDefault: Scalars['Boolean'];
  readonly maximumTotalRate?: InputMaybe<Scalars['Decimal']>;
  readonly minimumTotalRate?: InputMaybe<Scalars['Decimal']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly perUnit?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly rate?: InputMaybe<Scalars['Decimal']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly passThroughType?: InputMaybe<Scalars['String']>;
  readonly passThroughAmount?: InputMaybe<Scalars['Decimal']>;
  readonly customerApprovalRequired?: InputMaybe<Scalars['Boolean']>;
  readonly customerChargeTypeConfigurationPercentOfChargeTypeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Guid']>>>;
  readonly isTieredRatePerConfiguration?: InputMaybe<Scalars['Boolean']>;
  readonly customerTieredRateSchedules?: InputMaybe<ReadonlyArray<CustomerTieredRateScheduleInput>>;
};

export type CustomerChargeTypeConfigurationCreateInputV2 = {
  readonly billToId?: InputMaybe<Scalars['Guid']>;
  readonly carrierId?: InputMaybe<Scalars['Guid']>;
  readonly carrierName?: InputMaybe<Scalars['String']>;
  readonly category?: InputMaybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly customerChargeCode?: InputMaybe<Scalars['String']>;
  readonly customerDescription?: InputMaybe<Scalars['String']>;
  readonly customerId: Scalars['Guid'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly invoiceGroupingCode?: InputMaybe<Scalars['String']>;
  readonly invoiceMethodId?: InputMaybe<Scalars['String']>;
  readonly isDefault: Scalars['Boolean'];
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly customerChargeTypeRegionConfigurations?: InputMaybe<ReadonlyArray<CustomerChargeTypeRegionConfigurationInput>>;
};

export type CustomerChargeTypeConfigurationResponse = {
  readonly __typename?: 'CustomerChargeTypeConfigurationResponse';
  readonly billToId?: Maybe<Scalars['Guid']>;
  readonly carrierId?: Maybe<Scalars['Guid']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['DateTime'];
  readonly customerChargeCode?: Maybe<Scalars['String']>;
  readonly customerDescription?: Maybe<Scalars['String']>;
  readonly customerId: Scalars['Guid'];
  readonly description?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly invoiceGroupingCode?: Maybe<Scalars['String']>;
  readonly invoiceMethodId?: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly isAllowedByCustomer: Scalars['Boolean'];
  readonly isCredit: Scalars['Boolean'];
  readonly isDefault: Scalars['Boolean'];
  readonly maximumTotalRate?: Maybe<Scalars['Decimal']>;
  readonly minimumTotalRate?: Maybe<Scalars['Decimal']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly perUnit?: Maybe<CtcPerUnitTypeEnum>;
  readonly rate?: Maybe<Scalars['Decimal']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn: Scalars['DateTime'];
  readonly passThroughType?: Maybe<Scalars['String']>;
  readonly passThroughAmount?: Maybe<Scalars['Decimal']>;
  readonly customerApprovalRequired: Scalars['Boolean'];
  readonly customerChargeTypeConfigurationPercentOfChargeTypeIds?: Maybe<ReadonlyArray<Maybe<Scalars['Guid']>>>;
  readonly isTieredRatePerConfiguration?: Maybe<Scalars['Boolean']>;
  readonly customerTieredRateSchedules?: Maybe<ReadonlyArray<Maybe<CustomerTieredRateSchedule>>>;
};

export type CustomerChargeTypeConfigurationUpdateInput = {
  readonly billToId?: InputMaybe<Scalars['Guid']>;
  readonly carrierId?: InputMaybe<Scalars['Guid']>;
  readonly carrierName?: InputMaybe<Scalars['String']>;
  readonly category?: InputMaybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly customerChargeCode: Scalars['String'];
  readonly customerDescription: Scalars['String'];
  readonly customerId: Scalars['Guid'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly invoiceGroupingCode?: InputMaybe<Scalars['String']>;
  readonly invoiceMethodId?: InputMaybe<Scalars['String']>;
  readonly isAllowedByCustomer: Scalars['Boolean'];
  readonly maximumTotalRate?: InputMaybe<Scalars['Decimal']>;
  readonly minimumTotalRate?: InputMaybe<Scalars['Decimal']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly perUnit?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly rate?: InputMaybe<Scalars['Decimal']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly passThroughType?: InputMaybe<Scalars['String']>;
  readonly passThroughAmount?: InputMaybe<Scalars['Decimal']>;
  readonly customerApprovalRequired?: InputMaybe<Scalars['Boolean']>;
  readonly customerChargeTypeConfigurationPercentOfChargeTypeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Guid']>>>;
  readonly isTieredRatePerConfiguration?: InputMaybe<Scalars['Boolean']>;
  readonly customerTieredRateSchedules?: InputMaybe<ReadonlyArray<CustomerTieredRateScheduleInput>>;
};

export type CustomerChargeTypeConfigurationUpdateInputV2 = {
  readonly id: Scalars['ID'];
  readonly billToId?: InputMaybe<Scalars['Guid']>;
  readonly carrierId?: InputMaybe<Scalars['Guid']>;
  readonly carrierName?: InputMaybe<Scalars['String']>;
  readonly category?: InputMaybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly customerChargeCode?: InputMaybe<Scalars['String']>;
  readonly customerDescription?: InputMaybe<Scalars['String']>;
  readonly customerId: Scalars['Guid'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly invoiceGroupingCode?: InputMaybe<Scalars['String']>;
  readonly invoiceMethodId?: InputMaybe<Scalars['String']>;
  readonly isDefault: Scalars['Boolean'];
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly customerChargeTypeRegionConfigurations?: InputMaybe<ReadonlyArray<CustomerChargeTypeRegionConfigurationInput>>;
};

export type CustomerChargeTypeConfigurationV2Response = {
  readonly __typename?: 'CustomerChargeTypeConfigurationV2Response';
  readonly billToId?: Maybe<Scalars['Guid']>;
  readonly carrierId?: Maybe<Scalars['Guid']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['DateTime'];
  readonly customerChargeCode?: Maybe<Scalars['String']>;
  readonly customerDescription?: Maybe<Scalars['String']>;
  readonly customerId: Scalars['Guid'];
  readonly description?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly invoiceGroupingCode?: Maybe<Scalars['String']>;
  readonly invoiceMethodId?: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly isCredit: Scalars['Boolean'];
  readonly isDefault: Scalars['Boolean'];
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn: Scalars['DateTime'];
  readonly customerChargeTypeRegionConfigurations?: Maybe<ReadonlyArray<CustomerChargeTypeRegionConfiguration>>;
};

export type CustomerChargeTypeRegionConfiguration = {
  readonly __typename?: 'CustomerChargeTypeRegionConfiguration';
  readonly id: Scalars['ID'];
  readonly regionId?: Maybe<Scalars['Guid']>;
  readonly perUnit?: Maybe<CtcPerUnitTypeEnum>;
  readonly rate?: Maybe<Scalars['Decimal']>;
  readonly maximumTotalRate?: Maybe<Scalars['Decimal']>;
  readonly minimumTotalRate?: Maybe<Scalars['Decimal']>;
  readonly passThroughType?: Maybe<Scalars['String']>;
  readonly passThroughAmount?: Maybe<Scalars['Decimal']>;
  readonly isAllowedByCustomer: Scalars['Boolean'];
  readonly customerApprovalRequired: Scalars['Boolean'];
  readonly customerChargeTypeConfigurationPercentOfChargeTypeIds?: Maybe<ReadonlyArray<Maybe<Scalars['Guid']>>>;
  readonly isTieredRatePerConfiguration?: Maybe<Scalars['Boolean']>;
  readonly customerTieredRateSchedules?: Maybe<ReadonlyArray<Maybe<CustomerTieredRateSchedule>>>;
};

export type CustomerChargeTypeRegionConfigurationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly regionId?: InputMaybe<Scalars['Guid']>;
  readonly isAllowedByCustomer: Scalars['Boolean'];
  readonly maximumTotalRate?: InputMaybe<Scalars['Decimal']>;
  readonly minimumTotalRate?: InputMaybe<Scalars['Decimal']>;
  readonly perUnit?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly rate?: InputMaybe<Scalars['Decimal']>;
  readonly passThroughType?: InputMaybe<Scalars['String']>;
  readonly passThroughAmount?: InputMaybe<Scalars['Decimal']>;
  readonly customerApprovalRequired?: InputMaybe<Scalars['Boolean']>;
  readonly customerChargeTypeConfigurationPercentOfChargeTypeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Guid']>>>;
  readonly isTieredRatePerConfiguration?: InputMaybe<Scalars['Boolean']>;
  readonly customerTieredRateSchedules?: InputMaybe<ReadonlyArray<CustomerTieredRateScheduleInput>>;
};

export type CustomerChildren = {
  readonly __typename?: 'CustomerChildren';
  readonly id: Scalars['ID'];
  readonly customerV2?: Maybe<CustomerV2>;
  readonly children?: Maybe<ReadonlyArray<CustomerChildren>>;
  readonly isParent?: Maybe<Scalars['Boolean']>;
};

export type CustomerCommitment = {
  readonly __typename?: 'CustomerCommitment';
  readonly customerCommitmentID: Scalars['ID'];
  readonly customerCommitmentCode?: Maybe<Scalars['Int']>;
  readonly externalCustomerCommitmentID?: Maybe<Scalars['String']>;
  readonly externalBidID?: Maybe<Scalars['String']>;
  readonly volume?: Maybe<Scalars['Int']>;
  readonly otherVolumes?: Maybe<ReadonlyArray<Maybe<CommitmentVolume>>>;
  readonly frequency?: Maybe<Scalars['String']>;
  readonly customerWeekStarts?: Maybe<CommitmentDayOfWeekEnum>;
  readonly surge?: Maybe<Scalars['Decimal']>;
  readonly surgeV2?: Maybe<Scalars['Int']>;
  readonly acceptanceRate?: Maybe<Scalars['Int']>;
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly volumeEffectiveDate?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly customerID?: Maybe<Scalars['ID']>;
  readonly customerRateQuoteID?: Maybe<Scalars['ID']>;
  readonly laneID?: Maybe<Scalars['ID']>;
  readonly createdbyUserID?: Maybe<Scalars['ID']>;
  readonly lastUpdatedbyUserID?: Maybe<Scalars['ID']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  readonly customerRateQuote?: Maybe<CustomerQuoteV4>;
  readonly capacityConstraintResponse?: Maybe<CapacityConstraint>;
};

export type CustomerCommitmentCapacityConstraintInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly autoAccept?: InputMaybe<Scalars['Boolean']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly effectiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly rateQuoteId?: InputMaybe<Scalars['ID']>;
  readonly sundayConstraint?: InputMaybe<Scalars['Int']>;
  readonly mondayConstraint?: InputMaybe<Scalars['Int']>;
  readonly tuesdayConstraint?: InputMaybe<Scalars['Int']>;
  readonly wednesdayConstraint?: InputMaybe<Scalars['Int']>;
  readonly thursdayConstraint?: InputMaybe<Scalars['Int']>;
  readonly fridayConstraint?: InputMaybe<Scalars['Int']>;
  readonly saturdayConstraint?: InputMaybe<Scalars['Int']>;
  readonly weeklyCapacityConstraint?: InputMaybe<Scalars['Int']>;
};

export type CustomerCommitmentConnection = {
  readonly __typename?: 'CustomerCommitmentConnection';
  readonly edges: ReadonlyArray<Maybe<CustomerCommitmentEdge>>;
  readonly totalCount: Scalars['Int'];
  readonly pageInfo: PageInfo;
};

export type CustomerCommitmentEdge = {
  readonly __typename?: 'CustomerCommitmentEdge';
  readonly cursor: Scalars['String'];
  readonly node: CustomerCommitment;
};

export type CustomerCommitmentFilter = {
  readonly customerID?: InputMaybe<Scalars['ID']>;
  readonly laneID?: InputMaybe<Scalars['ID']>;
  readonly laneIDs?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly rateQuoteID?: InputMaybe<Scalars['ID']>;
  readonly zeroVolume?: InputMaybe<Scalars['Int']>;
  readonly orderID?: InputMaybe<Scalars['ID']>;
  readonly includeActive?: InputMaybe<Scalars['Boolean']>;
  readonly timeZone?: InputMaybe<Scalars['Float']>;
};

export type CustomerCommitmentInputType = {
  readonly customerCommitmentID?: InputMaybe<Scalars['ID']>;
  readonly externalCustomerCommitmentID?: InputMaybe<Scalars['String']>;
  readonly externalBidID?: InputMaybe<Scalars['String']>;
  readonly volume?: InputMaybe<Scalars['Int']>;
  readonly frequency?: InputMaybe<Scalars['String']>;
  readonly customerWeekStarts?: InputMaybe<CommitmentDayOfWeekEnum>;
  readonly surge?: InputMaybe<Scalars['Decimal']>;
  readonly surgeV2?: InputMaybe<Scalars['Int']>;
  readonly acceptanceRate?: InputMaybe<Scalars['Int']>;
  readonly effectiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly volumeEffectiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly volumeEffectiveDateTime?: InputMaybe<Scalars['DateTimeOffset']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly customerID?: InputMaybe<Scalars['ID']>;
  readonly customerRateQuoteID?: InputMaybe<Scalars['ID']>;
  readonly laneID?: InputMaybe<Scalars['ID']>;
  readonly capacityConstraintInput?: InputMaybe<CustomerCommitmentCapacityConstraintInput>;
};

export type CustomerCommitmentManagerEdge = {
  readonly __typename?: 'CustomerCommitmentManagerEdge';
  readonly cursor: Scalars['String'];
  readonly node: CustomerCommitmentMetric;
};

export type CustomerCommitmentManagerMetricsInputType = {
  readonly commitmentId: Scalars['ID'];
};

export type CustomerCommitmentManagerMetricsResponse = {
  readonly __typename?: 'CustomerCommitmentManagerMetricsResponse';
  readonly success?: Maybe<Scalars['String']>;
  readonly count?: Maybe<Scalars['Int']>;
};

export type CustomerCommitmentMetric = {
  readonly __typename?: 'CustomerCommitmentMetric';
  readonly customerCommitmentID: Scalars['ID'];
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly customerCommitmentReceived?: Maybe<Scalars['Float']>;
  readonly companyCommitmentReceived?: Maybe<Scalars['Float']>;
  readonly overallCommitmentReceived?: Maybe<Scalars['Float']>;
  readonly frequency?: Maybe<Scalars['String']>;
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly acceptanceRate?: Maybe<Scalars['Int']>;
  readonly surgeV2?: Maybe<Scalars['Int']>;
  readonly customerCommitmentCode?: Maybe<Scalars['Int']>;
  readonly laneID?: Maybe<Scalars['ID']>;
  readonly rateQuoteId?: Maybe<Scalars['ID']>;
  readonly customerWeekStarts?: Maybe<CommitmentDayOfWeekEnum>;
  readonly volume?: Maybe<Scalars['Int']>;
  readonly externalBidID?: Maybe<Scalars['String']>;
  readonly externalCustomerCommitmentId?: Maybe<Scalars['String']>;
  readonly length?: Maybe<Scalars['String']>;
  readonly width?: Maybe<Scalars['String']>;
  readonly height?: Maybe<Scalars['String']>;
  readonly minLength?: Maybe<Scalars['Decimal']>;
  readonly minWidth?: Maybe<Scalars['Decimal']>;
  readonly minHeight?: Maybe<Scalars['Decimal']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly fuelTotalRate?: Maybe<Scalars['String']>;
  readonly extrasTotalRate?: Maybe<Scalars['String']>;
  readonly devision?: Maybe<Scalars['String']>;
  readonly totalRate?: Maybe<Scalars['String']>;
  readonly baseRate?: Maybe<Scalars['String']>;
  readonly fuelTotalRateV2?: Maybe<Scalars['Decimal']>;
  readonly extrasTotalRateV2?: Maybe<Scalars['Decimal']>;
  readonly totalRateV2?: Maybe<Scalars['Decimal']>;
  readonly baseRateV2?: Maybe<Scalars['Decimal']>;
  readonly equipment?: Maybe<Scalars['String']>;
  readonly scac?: Maybe<Scalars['String']>;
  readonly rateCode?: Maybe<Scalars['Int']>;
  readonly laneCode?: Maybe<Scalars['Int']>;
  readonly service?: Maybe<Scalars['String']>;
  readonly originStopType?: Maybe<Scalars['String']>;
  readonly destStoptype?: Maybe<Scalars['String']>;
  readonly priceTier?: Maybe<Scalars['String']>;
  readonly team?: Maybe<Scalars['Boolean']>;
  readonly minimum?: Maybe<Scalars['Decimal']>;
  readonly autoAccept?: Maybe<Scalars['Boolean']>;
  readonly externalPricing?: Maybe<Scalars['Boolean']>;
  readonly weeklyCapacityConstraint?: Maybe<Scalars['Int']>;
  readonly mondayConstraint?: Maybe<Scalars['Int']>;
  readonly tuesdayConstraint?: Maybe<Scalars['Int']>;
  readonly wednesdayConstraint?: Maybe<Scalars['Int']>;
  readonly thursdayConstraint?: Maybe<Scalars['Int']>;
  readonly fridayConstraint?: Maybe<Scalars['Int']>;
  readonly saturdayConstraint?: Maybe<Scalars['Int']>;
  readonly sundayConstraint?: Maybe<Scalars['Int']>;
  readonly originGeoType?: Maybe<Scalars['String']>;
  readonly originFacility?: Maybe<Scalars['String']>;
  readonly originPc?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly originCountry?: Maybe<Scalars['String']>;
  readonly originGeoTypeRegion?: Maybe<Scalars['String']>;
  readonly destGeoTypeRegion?: Maybe<Scalars['String']>;
  readonly destinationGeoType?: Maybe<Scalars['String']>;
  readonly destinationFacility?: Maybe<Scalars['String']>;
  readonly destinationPc?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly destinationCountry?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly originGeoTypeFacilityId?: Maybe<Scalars['ID']>;
  readonly destinationGeoTypeFacilityId?: Maybe<Scalars['ID']>;
  readonly distanceType?: Maybe<Scalars['Boolean']>;
  readonly laneType?: Maybe<Scalars['String']>;
  readonly stopsInfo?: Maybe<ReadonlyArray<Maybe<StopInfo>>>;
};

export type CustomerCommitmentMetricsConnection = {
  readonly __typename?: 'CustomerCommitmentMetricsConnection';
  readonly edges: ReadonlyArray<Maybe<CustomerCommitmentManagerEdge>>;
  readonly totalCount: Scalars['Int'];
  readonly pageInfo: PageInfo;
};

export type CustomerCommitmentMetricsFilter = {
  readonly includeActive?: InputMaybe<Scalars['Boolean']>;
  readonly customerID?: InputMaybe<Scalars['ID']>;
  readonly originFacilityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly originGeoType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly originCityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
  readonly originStateIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
  readonly originStateLabels?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly originCountry?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly originPc?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly destinationGeoType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly destinationCityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
  readonly destinationStateLabels?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly destinationCountry?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly destinationPc?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly destinationFacility?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly scac?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly equipment?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly frequency?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly effectiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly volume?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
  readonly externalBidID?: InputMaybe<Scalars['String']>;
  readonly externalCommitmentID?: InputMaybe<Scalars['String']>;
};

export type CustomerCommitmentOrders = {
  readonly __typename?: 'CustomerCommitmentOrders';
  readonly id?: Maybe<Scalars['ID']>;
  readonly frequencyStartDate?: Maybe<Scalars['DateTime']>;
  readonly frequencyEndDate?: Maybe<Scalars['DateTime']>;
  readonly customerOrders?: Maybe<ReadonlyArray<Maybe<CustomerOrderV2>>>;
};

export type CustomerCommitmentsSettings = {
  readonly __typename?: 'CustomerCommitmentsSettings';
  readonly id?: Maybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly commitmentPerformanceBasis?: Maybe<Scalars['String']>;
  readonly weekStarting?: Maybe<CommitmentDayOfWeekEnum>;
  readonly surgePercentage?: Maybe<Scalars['Decimal']>;
  readonly surgeV2?: Maybe<Scalars['Int']>;
  readonly acceptanceRate?: Maybe<Scalars['Int']>;
  readonly orderStatus?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly autoAcceptSettings?: Maybe<LeadTime>;
};

export type CustomerCommitmentsSettingsInputType = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly commitmentPerformanceBasis?: InputMaybe<Scalars['String']>;
  readonly weekStarting: CommitmentDayOfWeekEnum;
  readonly surgePercentage?: InputMaybe<Scalars['Decimal']>;
  readonly surgeV2?: InputMaybe<Scalars['Int']>;
  readonly acceptanceRate?: InputMaybe<Scalars['Int']>;
  readonly orderStatus: ReadonlyArray<InputMaybe<Scalars['String']>>;
  readonly autoAcceptSettings?: InputMaybe<LeadTimeInput>;
};

export type CustomerCommodity = {
  readonly __typename?: 'CustomerCommodity';
  readonly id: Scalars['ID'];
  /** @deprecated Use customerIdV2, field became nullable */
  readonly customerId: Scalars['String'];
  readonly customerIdV2?: Maybe<Scalars['String']>;
  readonly facilityId?: Maybe<Scalars['String']>;
  readonly customerFacilityId?: Maybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly customerCode?: Maybe<Scalars['String']>;
  readonly stccCode?: Maybe<Scalars['String']>;
  readonly packagingUnit?: Maybe<Scalars['String']>;
  readonly loadOn?: Maybe<Scalars['String']>;
  readonly expWt: Scalars['Float'];
  readonly expPieces?: Maybe<Scalars['String']>;
  readonly uom_temperature?: Maybe<Scalars['String']>;
  readonly uom_dimension?: Maybe<Scalars['String']>;
  readonly uom_linear?: Maybe<Scalars['String']>;
  readonly uom_cube?: Maybe<Scalars['String']>;
  readonly uom_density?: Maybe<Scalars['String']>;
  readonly temp?: Maybe<Scalars['Boolean']>;
  readonly precoolTo?: Maybe<Scalars['Float']>;
  readonly minTemp?: Maybe<Scalars['Float']>;
  readonly maxTemp?: Maybe<Scalars['Float']>;
  readonly tempSetting?: Maybe<Scalars['String']>;
  readonly wtUnit?: Maybe<Scalars['String']>;
  readonly hazmat?: Maybe<Scalars['Boolean']>;
  readonly hazmatClass?: Maybe<Scalars['String']>;
  readonly packagingGroup?: Maybe<Scalars['String']>;
  readonly unNaNumber?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly expDimensions?: Maybe<Scalars['String']>;
  readonly expDimensionsW?: Maybe<Scalars['Float']>;
  readonly expDimensionsH?: Maybe<Scalars['Float']>;
  readonly expDimensionsL?: Maybe<Scalars['Float']>;
  readonly expLinear?: Maybe<Scalars['Float']>;
  readonly expCube?: Maybe<Scalars['Float']>;
  readonly expDensity?: Maybe<Scalars['Float']>;
  readonly expClass?: Maybe<Scalars['String']>;
  readonly expNmfc?: Maybe<Scalars['String']>;
  readonly nmfcSubcode?: Maybe<Scalars['String']>;
  readonly unitSystem?: Maybe<Scalars['String']>;
  readonly canTopLoad?: Maybe<Scalars['Boolean']>;
  readonly canBottomLoad?: Maybe<Scalars['Boolean']>;
  readonly overDimensional?: Maybe<Scalars['Boolean']>;
  /** YYYY format */
  readonly year?: Maybe<Scalars['Int']>;
  readonly make?: Maybe<Scalars['String']>;
  readonly model?: Maybe<Scalars['String']>;
  readonly customerFacility?: Maybe<CustomerFacility>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly splitDimensions?: Maybe<Scalars['Boolean']>;
};

export type CustomerCommodityConnection = {
  readonly __typename?: 'CustomerCommodityConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<CustomerCommodityEdge>;
};

export type CustomerCommodityEdge = {
  readonly __typename?: 'CustomerCommodityEdge';
  readonly node: CustomerCommodity;
  readonly cursor: Scalars['String'];
};

export type CustomerCommodityFilter = {
  /** customerId to filter by */
  readonly customerId?: InputMaybe<Scalars['ID']>;
  /** faciltyId to filter by */
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  /** description to filter by */
  readonly description?: InputMaybe<Scalars['String']>;
};

/** The connection type for Customer. */
export type CustomerConnection = {
  readonly __typename?: 'CustomerConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<CustomerEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type CustomerConnectionV2 = {
  readonly __typename?: 'CustomerConnectionV2';
  readonly edges: ReadonlyArray<CustomerEdgeV2>;
  readonly pageInfo: PageInfo;
};

export type CustomerContact = {
  readonly __typename?: 'CustomerContact';
  readonly chatType?: Maybe<KeyValue>;
  readonly chatTypeId?: Maybe<Scalars['ID']>;
  readonly chatUsername?: Maybe<Scalars['String']>;
  readonly contactType: KeyValue;
  readonly contactTypeId: Scalars['ID'];
  readonly active: Scalars['Boolean'];
  readonly contactableId: Scalars['ID'];
  readonly contactableType: Scalars['String'];
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly extension?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly id: Scalars['ID'];
  readonly main: Scalars['Boolean'];
  readonly name?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
};

export type CustomerContactInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly chatTypeId?: InputMaybe<Scalars['ID']>;
  readonly chatUsername?: InputMaybe<Scalars['String']>;
  readonly contactTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CustomerCreditMemo = {
  readonly __typename?: 'CustomerCreditMemo';
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Decimal'];
  readonly creditMemoDate?: Maybe<Scalars['DateTime']>;
  readonly invoiceHeader: InvoiceHeader;
  readonly currency?: Maybe<Scalars['String']>;
  readonly invoiceCurrencyAmount?: Maybe<Scalars['Decimal']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
};

export type CustomerCreditMemoInput = {
  readonly id: Scalars['ID'];
  readonly creditMemoDate: Scalars['DateTime'];
};

export type CustomerCreditProvider = {
  readonly __typename?: 'CustomerCreditProvider';
  readonly coverageAmount: Scalars['Float'];
  readonly createdAt?: Maybe<Scalars['ISO8601Date']>;
  readonly createdAtDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly createdByUser: User;
  readonly creditProvider: KeyValue;
  readonly creditScore: Scalars['String'];
  readonly currency: KeyValue;
  readonly effectiveDate: Scalars['ISO8601Date'];
  readonly effectiveDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly expirationDate: Scalars['ISO8601Date'];
  readonly expirationDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly id: Scalars['ID'];
  readonly notes?: Maybe<Scalars['String']>;
};

export type CustomerCreditProviderInput = {
  readonly coverageAmount: Scalars['Float'];
  readonly creditProviderId: Scalars['ID'];
  readonly creditScore: Scalars['String'];
  readonly currencyId: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly effectiveDate: Scalars['ISO8601Date'];
  readonly expirationDate: Scalars['ISO8601Date'];
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type CustomerCreditProviderInputV2 = {
  readonly coverageAmount: Scalars['Float'];
  readonly creditProviderId: Scalars['ID'];
  readonly creditScore: Scalars['String'];
  readonly currencyId: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly effectiveDate: Scalars['ISO8601Date'];
  readonly expirationDate: Scalars['ISO8601Date'];
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type CustomerCreditProviderV2 = {
  readonly __typename?: 'CustomerCreditProviderV2';
  readonly coverageAmount: Scalars['Float'];
  readonly createdAt?: Maybe<Scalars['ISO8601Date']>;
  readonly createdAtDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser: UserV2;
  readonly creditProvider: KeyValue;
  readonly creditProviderId?: Maybe<Scalars['ID']>;
  readonly creditScore: Scalars['String'];
  readonly currency: KeyValue;
  readonly currencyId?: Maybe<Scalars['ID']>;
  readonly effectiveDate: Scalars['ISO8601Date'];
  readonly effectiveDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly expirationDate: Scalars['ISO8601Date'];
  readonly expirationDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly id: Scalars['ID'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
};

export type CustomerCrmDocuemntFilter = {
  readonly customerId: Scalars['ID'];
};

export type CustomerCrmDocument = {
  readonly __typename?: 'CustomerCrmDocument';
  readonly contacts?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  /** @deprecated Use createdByV2 instead */
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly createdDate?: Maybe<Scalars['DateTime']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly direction?: Maybe<Scalars['String']>;
  readonly documentId?: Maybe<Scalars['ID']>;
  readonly documentName?: Maybe<Scalars['String']>;
  readonly documentType?: Maybe<Scalars['String']>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly opportunities?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly opportunityId?: Maybe<Scalars['ID']>;
  readonly opportunityName?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
  /** @deprecated Use updatedByV2 instead */
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly updatedDate?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type CustomerCrmDocumentConnection = {
  readonly __typename?: 'CustomerCrmDocumentConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CustomerCrmDocumentEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CustomerCrmDocument>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerCrmDocumentEdge = {
  readonly __typename?: 'CustomerCrmDocumentEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CustomerCrmDocument>;
};

export type CustomerCrmNote = {
  readonly __typename?: 'CustomerCrmNote';
  readonly contacts?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  /** @deprecated Use createdByV2 instead */
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly createdDate?: Maybe<Scalars['DateTime']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly opportunities?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly opportunityId?: Maybe<Scalars['ID']>;
  readonly opportunityName?: Maybe<Scalars['String']>;
  readonly reps?: Maybe<ReadonlyArray<Maybe<CustomerCrmNotesRep>>>;
  readonly type?: Maybe<Scalars['String']>;
  /** @deprecated Use updatedByV2 instead */
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly updatedDate?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type CustomerCrmNoteConnection = {
  readonly __typename?: 'CustomerCrmNoteConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CustomerCrmNoteEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CustomerCrmNote>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerCrmNoteEdge = {
  readonly __typename?: 'CustomerCrmNoteEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CustomerCrmNote>;
};

export type CustomerCrmNotesFilter = {
  readonly customerId: Scalars['ID'];
};

export type CustomerCrmNotesRep = {
  readonly __typename?: 'CustomerCRMNotesRep';
  /** @deprecated Use EmployeeV2 instead */
  readonly Employee?: Maybe<Employee>;
  readonly EmployeeV2?: Maybe<EmployeeV2>;
};

export type CustomerCrmOpportunity = {
  readonly __typename?: 'CustomerCRMOpportunity';
  readonly customerId: Scalars['ID'];
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly isDefault?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated. Use LastUpdatedByUser instead */
  readonly LastUpdatedBy?: Maybe<Employee>;
  readonly LastUpdatedByUser?: Maybe<User>;
  readonly lastUpdatedDate?: Maybe<Scalars['DateTime']>;
  readonly name: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly opportunityStatusId?: Maybe<Scalars['ID']>;
  readonly opportunityTypeId?: Maybe<Scalars['ID']>;
  readonly owningContactId?: Maybe<Scalars['ID']>;
  readonly OwningRep?: Maybe<Employee>;
};

/** A connection to a list of items. */
export type CustomerCrmOpportunityConnection = {
  readonly __typename?: 'CustomerCRMOpportunityConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CustomerCrmOpportunityEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CustomerCrmOpportunity>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerCrmOpportunityEdge = {
  readonly __typename?: 'CustomerCRMOpportunityEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CustomerCrmOpportunity>;
};

export type CustomerCustomerRelationship = {
  readonly __typename?: 'CustomerCustomerRelationship';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['String'];
  readonly relatedCustomerId: Scalars['String'];
  readonly relationshipType?: Maybe<Scalars['String']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly originalCustomerId?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Customer>;
  readonly ultimateParentId?: Maybe<Scalars['String']>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly relatedCustomer?: Maybe<Customer>;
  readonly relatedCustomerV2?: Maybe<CustomerV2>;
  readonly updatedByUser?: Maybe<User>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly isCascadeCreated?: Maybe<Scalars['Boolean']>;
};

/** An edge in a connection. */
export type CustomerEdge = {
  readonly __typename?: 'CustomerEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: Customer;
};

export type CustomerEdgeV2 = {
  readonly __typename?: 'CustomerEdgeV2';
  readonly cursor: Scalars['String'];
  readonly node: CustomerV2;
};

export type CustomerEdiTransactionConfiguration = {
  readonly __typename?: 'CustomerEdiTransactionConfiguration';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['UUID'];
  readonly isEnabled: Scalars['Boolean'];
  readonly ediTransactionType: Scalars['Short'];
};

export type CustomerEdiTransactionConfigurationFilter = {
  readonly customerId: Scalars['UUID'];
  readonly ediTransactionType?: InputMaybe<Scalars['Short']>;
};

export type CustomerEdiTransactionConfigurationInput = {
  readonly customerId: Scalars['UUID'];
  readonly isEnabled: Scalars['Boolean'];
  readonly ediTransactionType: Scalars['Short'];
};

export type CustomerExportNoteResponse = {
  readonly __typename?: 'CustomerExportNoteResponse';
  readonly customerId: Scalars['Uuid'];
  readonly id: Scalars['Uuid'];
};

export type CustomerFacility = {
  readonly __typename?: 'CustomerFacility';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['String'];
  readonly facilityId: Scalars['String'];
  readonly customerCustomerId?: Maybe<Scalars['String']>;
  readonly schedulingSystem?: Maybe<Scalars['String']>;
  readonly schedulingURL?: Maybe<Scalars['String']>;
  readonly username?: Maybe<Scalars['String']>;
  readonly password?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly isAutoScheduleEligible?: Maybe<Scalars['Boolean']>;
  readonly isAutoScheduleDefault?: Maybe<Scalars['Boolean']>;
  readonly isPickup?: Maybe<Scalars['Boolean']>;
  readonly pickupCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly isDelivery?: Maybe<Scalars['Boolean']>;
  readonly deliveryCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly trailPool?: Maybe<Scalars['String']>;
  readonly minTrailers?: Maybe<Scalars['Int']>;
  readonly maxTrailers?: Maybe<Scalars['Int']>;
  readonly nowTrailers?: Maybe<Scalars['Int']>;
  readonly specialRequirementsAndEquipment?: Maybe<Scalars['String']>;
  readonly pickupAllowableFreeTimeValue?: Maybe<Scalars['Int']>;
  readonly pickupAllowableFreeTimeUnit?: Maybe<Scalars['String']>;
  readonly deliveryAllowableFreeTimeValue?: Maybe<Scalars['Int']>;
  readonly deliveryAllowableFreeTimeUnit?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly customFullAddressPoint?: Maybe<Geopoint>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly customerCustomer?: Maybe<Customer>;
  readonly customerCustomerV2?: Maybe<CustomerV2>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly drop?: Maybe<Scalars['String']>;
  readonly customerCommodities?: Maybe<ReadonlyArray<CustomerCommodity>>;
  /** @deprecated CustomerFacilityPickupCode is deprecated. Use pickupCodes instead. */
  readonly customerFacilityPickupCode?: Maybe<ReadonlyArray<CustomerFacilityPickupCode>>;
  /** @deprecated CustomerFacilityDeliveryCode is deprecated. Use deliveryCodes instead. */
  readonly customerFacilityDeliveryCode?: Maybe<ReadonlyArray<CustomerFacilityDeliveryCode>>;
  readonly customerFacilityStopDetails?: Maybe<ReadonlyArray<CustomerFacilityStopDetails>>;
};

export type CustomerFacilityConnection = {
  readonly __typename?: 'CustomerFacilityConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<CustomerFacilityEdge>;
};

export type CustomerFacilityDeliveryCode = {
  readonly __typename?: 'CustomerFacilityDeliveryCode';
  readonly id: Scalars['ID'];
  readonly customerFacilityId: Scalars['String'];
  readonly deliveryCode: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly customerFacility?: Maybe<CustomerFacility>;
};

export type CustomerFacilityEdge = {
  readonly __typename?: 'CustomerFacilityEdge';
  readonly node: CustomerFacility;
  readonly cursor: Scalars['String'];
};

export type CustomerFacilityFilter = {
  /** customerId to filter by */
  readonly customerId?: InputMaybe<Scalars['ID']>;
  /** facilityId to filter by */
  readonly facilityId?: InputMaybe<Scalars['ID']>;
};

export type CustomerFacilityPickupCode = {
  readonly __typename?: 'CustomerFacilityPickupCode';
  readonly id: Scalars['ID'];
  readonly customerFacilityId: Scalars['String'];
  readonly pickupCode: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly customerFacility?: Maybe<CustomerFacility>;
};

export type CustomerFacilityRelationship = {
  readonly __typename?: 'CustomerFacilityRelationship';
  readonly customer: Customer;
  readonly delivery: Scalars['Boolean'];
  readonly deliveryAvgDwellMinutes?: Maybe<Scalars['Int']>;
  readonly deliveryCode?: Maybe<Scalars['String']>;
  readonly facility: Facility;
  readonly id: Scalars['ID'];
  readonly pickup: Scalars['Boolean'];
  readonly pickupAvgDwellMinutes?: Maybe<Scalars['Int']>;
  readonly pickupCode?: Maybe<Scalars['String']>;
  readonly schedulingSystemType?: Maybe<KeyValue>;
};

export type CustomerFacilityRelationshipInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly delivery: Scalars['Boolean'];
  readonly deliveryAvgDwellMinutes?: InputMaybe<Scalars['Int']>;
  readonly deliveryCode?: InputMaybe<Scalars['String']>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly pickup: Scalars['Boolean'];
  readonly pickupAvgDwellMinutes?: InputMaybe<Scalars['Int']>;
  readonly pickupCode?: InputMaybe<Scalars['String']>;
  readonly schedulingSystemTypeId?: InputMaybe<Scalars['ID']>;
};

export type CustomerFacilityRelationshipInputV2 = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly delivery: Scalars['Boolean'];
  readonly deliveryAvgDwellMinutes?: InputMaybe<Scalars['Int']>;
  readonly deliveryCode?: InputMaybe<Scalars['String']>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly pickup: Scalars['Boolean'];
  readonly pickupAvgDwellMinutes?: InputMaybe<Scalars['Int']>;
  readonly pickupCode?: InputMaybe<Scalars['String']>;
  readonly schedulingSystemTypeId?: InputMaybe<Scalars['ID']>;
};

export type CustomerFacilityRelationshipV2 = {
  readonly __typename?: 'CustomerFacilityRelationshipV2';
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly customer: CustomerV2;
  readonly delivery: Scalars['Boolean'];
  readonly deliveryAvgDwellMinutes?: Maybe<Scalars['Int']>;
  readonly deliveryCode?: Maybe<Scalars['String']>;
  readonly facilityId?: Maybe<Scalars['ID']>;
  readonly facility: FacilityV2;
  readonly id: Scalars['ID'];
  readonly pickup: Scalars['Boolean'];
  readonly pickupAvgDwellMinutes?: Maybe<Scalars['Int']>;
  readonly pickupCode?: Maybe<Scalars['String']>;
  readonly schedulingSystemType?: Maybe<KeyValue>;
  readonly schedulingSystemTypeId?: Maybe<Scalars['ID']>;
};

export type CustomerFacilityStopDetails = {
  readonly __typename?: 'CustomerFacilityStopDetails';
  readonly id: Scalars['ID'];
  readonly customerFacilityId: Scalars['String'];
  readonly locationCode?: Maybe<Scalars['String']>;
  readonly stopType: Scalars['String'];
  readonly qualifier?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId: Scalars['String'];
};

export type CustomerFacilityStopDetailsInput = {
  readonly stopType: Scalars['String'];
  readonly locationCode?: InputMaybe<Scalars['String']>;
  readonly qualifier?: InputMaybe<Scalars['String']>;
};

export type CustomerFuelRangeCreateInput = {
  readonly customerFuelRateHeaderId?: InputMaybe<Scalars['Guid']>;
  readonly minPrice?: InputMaybe<Scalars['Decimal']>;
  readonly maxPrice?: InputMaybe<Scalars['Decimal']>;
  readonly priceDecimal?: InputMaybe<Scalars['Decimal']>;
  readonly pricePercentage?: InputMaybe<Scalars['Decimal']>;
};

export type CustomerFuelRangeModel = {
  readonly __typename?: 'CustomerFuelRangeModel';
  readonly customerFuelRangeId: Scalars['ID'];
  readonly customerFuelRateHeaderId?: Maybe<Scalars['Guid']>;
  readonly createdByUserId?: Maybe<Scalars['Guid']>;
  readonly createdByDesc?: Maybe<Scalars['String']>;
  readonly createdOnUtc?: Maybe<Scalars['DateTime']>;
  readonly updatedByUserId?: Maybe<Scalars['Guid']>;
  readonly updatedByDesc?: Maybe<Scalars['String']>;
  readonly updatedOnUtc?: Maybe<Scalars['DateTime']>;
  readonly priceDecimal?: Maybe<Scalars['Decimal']>;
  readonly pricePercentage?: Maybe<Scalars['Decimal']>;
  readonly minPrice?: Maybe<Scalars['Decimal']>;
  readonly maxPrice?: Maybe<Scalars['Decimal']>;
};

export type CustomerFuelRangeResponse = {
  readonly __typename?: 'CustomerFuelRangeResponse';
  readonly customerFuelRangeId: Scalars['ID'];
  readonly minPrice?: Maybe<Scalars['Decimal']>;
  readonly maxPrice?: Maybe<Scalars['Decimal']>;
  readonly priceDecimal?: Maybe<Scalars['Decimal']>;
  readonly pricePercentage?: Maybe<Scalars['Decimal']>;
};

export type CustomerFuelRangeUpdateInput = {
  readonly customerFuelRangeId: Scalars['ID'];
  readonly customerFuelRateHeaderId?: InputMaybe<Scalars['Guid']>;
  readonly minPrice?: InputMaybe<Scalars['Decimal']>;
  readonly maxPrice?: InputMaybe<Scalars['Decimal']>;
  readonly priceDecimal?: InputMaybe<Scalars['Decimal']>;
  readonly pricePercentage?: InputMaybe<Scalars['Decimal']>;
};

export type CustomerFuelRateHeaderCreateInput = {
  readonly fuelProgramCustomerConfigurationId: Scalars['ID'];
  readonly fuelApplicationMethod?: InputMaybe<Scalars['String']>;
  readonly fuelScale: Scalars['String'];
  readonly fuelWeekday?: InputMaybe<Scalars['String']>;
  readonly fuelSurchargeDate?: InputMaybe<Scalars['String']>;
  /** DEPRECATED - `fuelInclusivity` is no longer used - value will be ignored */
  readonly fuelInclusivity?: InputMaybe<Scalars['String']>;
  readonly fuelIndexType: Scalars['String'];
  readonly fuelSurchargeFrequency?: InputMaybe<Scalars['String']>;
};

export type CustomerFuelRateHeaderModel = {
  readonly __typename?: 'CustomerFuelRateHeaderModel';
  readonly customerFuelRateHeaderId: Scalars['ID'];
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly createdByDesc?: Maybe<Scalars['String']>;
  readonly createdOnUtc?: Maybe<Scalars['DateTime']>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly updatedByDesc?: Maybe<Scalars['String']>;
  readonly updatedOnUtc?: Maybe<Scalars['DateTime']>;
  readonly fuelProgramCustomerConfigurationId: Scalars['ID'];
  readonly fuelApplicationMethod?: Maybe<Scalars['String']>;
  readonly fuelScale?: Maybe<Scalars['String']>;
  readonly fuelWeekday?: Maybe<Scalars['String']>;
  readonly fuelSurchargeDate?: Maybe<Scalars['String']>;
  /** @deprecated `fuelInclusivity` is no longer used - value will always return `Yes` */
  readonly fuelInclusivity?: Maybe<Scalars['String']>;
  readonly fuelIndexType?: Maybe<Scalars['String']>;
  readonly fuelSurchargeFrequency?: Maybe<Scalars['String']>;
};

export type CustomerFuelRateHeaderResponse = {
  readonly __typename?: 'CustomerFuelRateHeaderResponse';
  readonly customerFuelRateHeaderId: Scalars['Guid'];
  readonly fuelProgramCustomerConfigurationId: Scalars['Guid'];
  readonly fuelApplicationMethod: Scalars['String'];
  readonly fuelScale: Scalars['String'];
  readonly fuelWeekday: Scalars['String'];
  readonly fuelSurchargeDate?: Maybe<Scalars['String']>;
  readonly fuelSurchargeFrequency: Scalars['String'];
  /** @deprecated `fuelInclusivity` is no longer used - value will always return `Yes` */
  readonly fuelInclusivity: Scalars['String'];
  readonly fuelIndexType: Scalars['String'];
  readonly fuelRanges: ReadonlyArray<CustomerFuelRangeResponse>;
  /** @deprecated Use `surcharges` */
  readonly weeklySurcharges: ReadonlyArray<CustomerFuelWeeklySurchargeResponse>;
  readonly surcharges: ReadonlyArray<CustomerFuelSurchargeResponse>;
};

export type CustomerFuelSurchargeResponse = {
  readonly __typename?: 'CustomerFuelSurchargeResponse';
  readonly beginDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly averageDieselPrice?: Maybe<Scalars['Decimal']>;
  readonly fuelSurcharge?: Maybe<Scalars['Decimal']>;
};

export type CustomerFuelWeeklySurchargeResponse = {
  readonly __typename?: 'CustomerFuelWeeklySurchargeResponse';
  readonly beginDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly averageDieselPrice?: Maybe<Scalars['Decimal']>;
  readonly fuelSurcharge?: Maybe<Scalars['Decimal']>;
};

export type CustomerGroup = {
  readonly __typename?: 'CustomerGroup';
  readonly customer?: Maybe<Customer>;
  readonly employeeDivision?: Maybe<KeyValue>;
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly groupType?: Maybe<KeyValue>;
  readonly id: Scalars['ID'];
};

export type CustomerGroupInput = {
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly customerId: Scalars['ID'];
  readonly employeeDivisionId: Scalars['ID'];
  readonly employeeGroupId: Scalars['ID'];
  readonly groupTypeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type CustomerGroupInputV2 = {
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly customerId: Scalars['ID'];
  readonly employeeDivisionId: Scalars['ID'];
  readonly employeeGroupId: Scalars['ID'];
  readonly groupTypeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type CustomerGroupV2 = {
  readonly __typename?: 'CustomerGroupV2';
  readonly customerId?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<CustomerV2>;
  readonly employeeDivision?: Maybe<KeyValue>;
  readonly employeeDivisionId?: Maybe<Scalars['ID']>;
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly employeeGroupId?: Maybe<Scalars['ID']>;
  readonly groupType?: Maybe<KeyValue>;
  readonly groupTypeId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type CustomerInput = {
  readonly accountingNotes?: InputMaybe<ReadonlyArray<NoteInput>>;
  readonly addresses?: InputMaybe<ReadonlyArray<AddressInput>>;
  readonly allNotes?: InputMaybe<ReadonlyArray<NoteInput>>;
  readonly billToCustomerId?: InputMaybe<Scalars['ID']>;
  readonly billingAddressId?: InputMaybe<Scalars['ID']>;
  readonly billingEmail?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly contacts?: InputMaybe<ReadonlyArray<ContactInput>>;
  readonly creditHistories?: InputMaybe<ReadonlyArray<CreditHistoryInput>>;
  readonly currencyId?: InputMaybe<Scalars['ID']>;
  readonly customerCreditStatusId?: InputMaybe<Scalars['ID']>;
  readonly customerLevelTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerOpportunityTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerPaymentTermId?: InputMaybe<Scalars['ID']>;
  readonly customerSalesProgressTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerStatusTypeId?: InputMaybe<Scalars['ID']>;
  readonly electronicTracking?: InputMaybe<Scalars['Boolean']>;
  readonly electronicTrackingNumber?: InputMaybe<Scalars['String']>;
  readonly electronicTrackingTypeId?: InputMaybe<Scalars['ID']>;
  readonly facilityRelationships?: InputMaybe<ReadonlyArray<CustomerFacilityRelationshipInput>>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly identifiers?: InputMaybe<ReadonlyArray<IdentifierInput>>;
  readonly insuranceCargo?: InputMaybe<Scalars['Int']>;
  readonly insuranceGeneral?: InputMaybe<Scalars['Int']>;
  readonly insuranceLiability?: InputMaybe<Scalars['Int']>;
  readonly invoiceDistributionMethodId?: InputMaybe<Scalars['ID']>;
  readonly invoiceGrouping?: InputMaybe<InvoiceGroupingEnum>;
  readonly mainContactId?: InputMaybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly parentId?: InputMaybe<Scalars['ID']>;
  readonly paymentMethodId?: InputMaybe<Scalars['ID']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly reps?: InputMaybe<ReadonlyArray<CustomerRepInput>>;
  readonly roeStatus?: InputMaybe<RoeStatusEnum>;
  readonly settings?: InputMaybe<CustomerSettingInput>;
  readonly useParentCredit?: InputMaybe<Scalars['Boolean']>;
  readonly website?: InputMaybe<Scalars['String']>;
};

export type CustomerInputV2 = {
  readonly accountingNotes?: InputMaybe<ReadonlyArray<CustomerNoteInput>>;
  readonly addresses?: InputMaybe<ReadonlyArray<CustomerAddressInput>>;
  readonly allNotes?: InputMaybe<ReadonlyArray<CustomerNoteInput>>;
  readonly billToCustomerId?: InputMaybe<Scalars['ID']>;
  readonly billingAddressId?: InputMaybe<Scalars['ID']>;
  readonly billingEmail?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly contacts?: InputMaybe<ReadonlyArray<CustomerContactInput>>;
  readonly creditHistories?: InputMaybe<ReadonlyArray<CreditHistoryInputV2>>;
  readonly currencyId?: InputMaybe<Scalars['ID']>;
  readonly customerCreditStatusId?: InputMaybe<Scalars['ID']>;
  readonly customerLevelTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerOpportunityTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerPaymentTermId?: InputMaybe<Scalars['ID']>;
  readonly customerSalesProgressTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerStatusTypeId?: InputMaybe<Scalars['ID']>;
  readonly electronicTracking?: InputMaybe<Scalars['Boolean']>;
  readonly electronicTrackingNumber?: InputMaybe<Scalars['String']>;
  readonly electronicTrackingTypeId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly identifiers?: InputMaybe<ReadonlyArray<IdentifierInputV2>>;
  readonly insuranceCargo?: InputMaybe<Scalars['Int']>;
  readonly insuranceGeneral?: InputMaybe<Scalars['Int']>;
  readonly insuranceLiability?: InputMaybe<Scalars['Int']>;
  readonly minimumInsuranceRating?: InputMaybe<Scalars['String']>;
  readonly invoiceDistributionMethodId?: InputMaybe<Scalars['ID']>;
  readonly invoiceGrouping?: InputMaybe<InvoiceGroupingEnum>;
  readonly mainContactId?: InputMaybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly organizationId?: InputMaybe<Scalars['ID']>;
  /** The field `parentId` is deprecated. Use `customerId/relatedCustomerId` from customerCustomerRelationship instead. */
  readonly parentId?: InputMaybe<Scalars['ID']>;
  readonly paymentMethodId?: InputMaybe<Scalars['ID']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly reps?: InputMaybe<ReadonlyArray<CustomerRepInputV2>>;
  readonly roeStatus?: InputMaybe<Scalars['String']>;
  readonly settings?: InputMaybe<CustomerSettingInputV2>;
  readonly loadReferenceTypeId?: InputMaybe<Scalars['ID']>;
  /** The field `useParentCredit` is deprecated. Use `canUseParentCredit` from CreditHistoryV2 instead. */
  readonly useParentCredit?: InputMaybe<Scalars['Boolean']>;
  readonly website?: InputMaybe<Scalars['String']>;
  readonly customerTypeId?: InputMaybe<Scalars['ID']>;
  readonly linesOfBusiness?: InputMaybe<ReadonlyArray<LinesOfBusinessInput>>;
  readonly customerRatingBasedOnId?: InputMaybe<Scalars['ID']>;
};

export type CustomerInteraction = {
  readonly __typename?: 'CustomerInteraction';
  readonly contactId?: Maybe<Scalars['ID']>;
  readonly customer?: Maybe<Customer>;
  readonly CustomerV2?: Maybe<CustomerV2>;
  readonly id: Scalars['ID'];
  readonly interactionDate: Scalars['DateTime'];
  readonly interactionOutcomeTypeId?: Maybe<Scalars['ID']>;
  readonly interactionTypeId?: Maybe<Scalars['ID']>;
  readonly interactionViaTypeId?: Maybe<Scalars['ID']>;
  readonly nextInteractionDate?: Maybe<Scalars['DateTime']>;
  readonly NextInteractionRep?: Maybe<Employee>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly opportunityId?: Maybe<Scalars['ID']>;
  readonly opportunityName?: Maybe<Scalars['String']>;
  /** @deprecated This property will be removed. Use InteractionOutcomeTypeId instead  */
  readonly outcome?: Maybe<CustomerInteractionOutcomeEnum>;
  readonly Rep?: Maybe<Employee>;
  /** @deprecated This property will be removed. Use InteractionTypeId instead  */
  readonly type?: Maybe<CustomerInteractionTypeEnum>;
  /** @deprecated This property will be removed. Use InteractionViaTypeId instead  */
  readonly via?: Maybe<CustomerInteractionViaEnum>;
};

/** A connection to a list of items. */
export type CustomerInteractionConnection = {
  readonly __typename?: 'CustomerInteractionConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CustomerInteractionEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CustomerInteraction>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerInteractionEdge = {
  readonly __typename?: 'CustomerInteractionEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CustomerInteraction>;
};

export type CustomerInteractionFilter = {
  readonly customerId?: InputMaybe<Scalars['ID']>;
};

export enum CustomerInteractionFrequencyEnum {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Bimonthly = 'BIMONTHLY'
}

export enum CustomerInteractionOutcomeEnum {
  Noanswer = 'NOANSWER',
  Newopportunity = 'NEWOPPORTUNITY',
  Newcustomerlocation = 'NEWCUSTOMERLOCATION',
  Closedopportunity = 'CLOSEDOPPORTUNITY',
  Lostopportunity = 'LOSTOPPORTUNITY',
  Opportunityforotherbusinessunit = 'OPPORTUNITYFOROTHERBUSINESSUNIT'
}

export enum CustomerInteractionTypeEnum {
  Coldcall = 'COLDCALL',
  Warmlead = 'WARMLEAD',
  Followup = 'FOLLOWUP',
  Contractdiscussions = 'CONTRACTDISCUSSIONS',
  Pricingcall = 'PRICINGCALL',
  Accountchange = 'ACCOUNTCHANGE'
}

export enum CustomerInteractionViaEnum {
  Email = 'EMAIL',
  Phonecall = 'PHONECALL',
  Text = 'TEXT',
  Webconference = 'WEBCONFERENCE',
  Inperson = 'INPERSON',
  Socialmedia = 'SOCIALMEDIA'
}

export type CustomerInvoiceApplication = {
  readonly __typename?: 'CustomerInvoiceApplication';
  readonly invoiceHeaderId?: Maybe<Scalars['ID']>;
  readonly invoiceNumber?: Maybe<Scalars['String']>;
  readonly invoiceDate?: Maybe<Scalars['DateTime']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly billToName?: Maybe<Scalars['String']>;
  readonly originalAmountDue?: Maybe<Scalars['Decimal']>;
  readonly currentAmountDue?: Maybe<Scalars['Decimal']>;
  readonly originalAmountDueUSD?: Maybe<Scalars['Decimal']>;
  readonly currentAmountDueUSD?: Maybe<Scalars['Decimal']>;
  readonly dueDate?: Maybe<Scalars['DateTime']>;
  readonly customerPayments?: Maybe<ReadonlyArray<Maybe<CustomerInvoicePayment>>>;
};

export type CustomerInvoiceFilter = {
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly status?: InputMaybe<StatusEnum>;
  readonly dueDateRange?: InputMaybe<DateRange>;
  readonly refCode?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly project?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly customerReceiptNumber?: InputMaybe<Scalars['String']>;
  readonly creditMemoNumber?: InputMaybe<Scalars['String']>;
  readonly orderId?: InputMaybe<Scalars['Uuid']>;
  readonly billToCustomerId?: InputMaybe<Scalars['Uuid']>;
  readonly invoiceNumber?: InputMaybe<Scalars['String']>;
  readonly statementNumber?: InputMaybe<Scalars['String']>;
  readonly minimumAmount?: InputMaybe<Scalars['Decimal']>;
  readonly maximumAmount?: InputMaybe<Scalars['Decimal']>;
  readonly minimumBalance?: InputMaybe<Scalars['Decimal']>;
  readonly maximumBalance?: InputMaybe<Scalars['Decimal']>;
  readonly statuses?: InputMaybe<ReadonlyArray<StatusEnum>>;
  readonly orderLifeCycles?: InputMaybe<ReadonlyArray<OrderLifeCycleStatusEnum>>;
  readonly orderFinancialStatuses?: InputMaybe<ReadonlyArray<OrderFinancialStatusEnum>>;
  readonly invoiceDateRange?: InputMaybe<DateRange>;
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly orderCode?: InputMaybe<Scalars['String']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly carrierRepId?: InputMaybe<Scalars['Uuid']>;
  readonly customerRepId?: InputMaybe<Scalars['Uuid']>;
  readonly railsLoadIds?: InputMaybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly pageSize?: InputMaybe<Scalars['Long']>;
  readonly skipRecordCount?: InputMaybe<Scalars['Long']>;
  readonly deliveryDateRange?: InputMaybe<DateRange>;
};

/** Represents Customer Payment Information */
export type CustomerInvoicePayment = {
  readonly __typename?: 'CustomerInvoicePayment';
  readonly postDate?: Maybe<Scalars['DateTime']>;
  readonly applicationType?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly paymentType?: Maybe<Scalars['String']>;
  readonly refNumber?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly amount?: Maybe<Scalars['Decimal']>;
  readonly amountUSD?: Maybe<Scalars['Decimal']>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly paymentId?: Maybe<Scalars['ID']>;
};

export type CustomerList = {
  readonly __typename?: 'CustomerList';
  readonly id?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly mainAddressCity?: Maybe<Scalars['String']>;
  readonly mainAddressState?: Maybe<Scalars['String']>;
  readonly mainRepId?: Maybe<Scalars['String']>;
  readonly mainRepName?: Maybe<Scalars['String']>;
  readonly customerStatus?: Maybe<Scalars['String']>;
  readonly customerSalesProgess?: Maybe<Scalars['String']>;
  readonly customerLevel?: Maybe<Scalars['String']>;
  readonly customerOpportunity?: Maybe<Scalars['String']>;
  readonly interactionFrequency?: Maybe<Scalars['String']>;
  readonly lastInteractionDatetime?: Maybe<Scalars['DateTime']>;
  readonly lastInteractionCompletedByRepId?: Maybe<Scalars['String']>;
  readonly lastInteractionCompletedByRepName?: Maybe<Scalars['String']>;
  readonly lastInteractionDetails?: Maybe<Scalars['String']>;
  readonly nextInteractionDatetime?: Maybe<Scalars['DateTime']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
};

export type CustomerListConnection = {
  readonly __typename?: 'CustomerListConnection';
  readonly edges: ReadonlyArray<CustomerListEdge>;
  readonly pageInfo: PageInfo;
};

export type CustomerListEdge = {
  readonly __typename?: 'CustomerListEdge';
  readonly node: CustomerList;
  readonly cursor: Scalars['String'];
};

export type CustomerListFilterInput = {
  readonly id?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerGroupId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly organizationId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerMarkupInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly active: Scalars['Boolean'];
  readonly carrierName?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly types: Scalars['String'];
  readonly currencyValue: Scalars['String'];
  readonly currencyType?: InputMaybe<Scalars['String']>;
  readonly effDate: Scalars['DateTime'];
  readonly expDate: Scalars['DateTime'];
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>;
  readonly updatedDate?: InputMaybe<Scalars['DateTime']>;
  readonly createdDate?: InputMaybe<Scalars['DateTime']>;
};

export type CustomerMarkupResponse = {
  readonly __typename?: 'CustomerMarkupResponse';
  readonly id?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly types?: Maybe<Scalars['String']>;
  readonly currencyValue?: Maybe<Scalars['String']>;
  readonly currencyType?: Maybe<Scalars['String']>;
  readonly effDate?: Maybe<Scalars['DateTime']>;
  readonly expDate?: Maybe<Scalars['DateTime']>;
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly updatedDate?: Maybe<Scalars['DateTime']>;
  readonly createdDate?: Maybe<Scalars['DateTime']>;
};

export type CustomerMarkupResponsePayloads = {
  readonly __typename?: 'CustomerMarkupResponsePayloads';
  readonly customerMarkupResponses?: Maybe<ReadonlyArray<CustomerMarkupResponse>>;
};

export type CustomerNote = {
  readonly __typename?: 'CustomerNote';
  readonly createdAt: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly noteType: Scalars['String'];
  readonly noteableId: Scalars['ID'];
  readonly noteableType: Scalars['String'];
  readonly text?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['Iso8601Date'];
};

export type CustomerNoteInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly noteType?: InputMaybe<Scalars['String']>;
  readonly noteableId?: InputMaybe<Scalars['ID']>;
  readonly text?: InputMaybe<Scalars['String']>;
};

export type CustomerOpportunityFilter = {
  readonly checkForDefaultOpportunity?: InputMaybe<Scalars['Boolean']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly showInactive?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerOrder = {
  readonly __typename?: 'CustomerOrder';
  readonly id: Scalars['ID'];
  readonly orderCode: Scalars['String'];
  readonly orderLoadId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly orderCustomerId: Scalars['Uuid'];
  readonly orderFinancialStatus?: Maybe<Scalars['Int']>;
  readonly orderLifeCycle?: Maybe<Scalars['Int']>;
  readonly eid?: Maybe<Scalars['Long']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly propertyMetadata?: Maybe<ReadonlyArray<KeyValuePairOfStringAndPropertyMetadata>>;
  readonly acceptedBy?: Maybe<Employee>;
  readonly accountingOrder?: Maybe<Order>;
  readonly activationStatus?: Maybe<KeyValue>;
  readonly activationStatusReason?: Maybe<Scalars['String']>;
  readonly billToCustomer?: Maybe<Customer>;
  readonly cargoInsurance?: Maybe<Currency>;
  readonly code: Scalars['String'];
  readonly commodities?: Maybe<ReadonlyArray<Commodity>>;
  readonly commodityTotals?: Maybe<CommodityTotals>;
  readonly customer: Customer;
  /** Customer-specific id for this order */
  readonly customerOrderNumber?: Maybe<Scalars['String']>;
  readonly declinedBy?: Maybe<Employee>;
  readonly declinedReason?: Maybe<KeyValue>;
  readonly dimensions?: Maybe<CustomerOrderDimensions>;
  readonly division?: Maybe<Scalars['String']>;
  readonly invoiceNote?: Maybe<Scalars['String']>;
  readonly lifeCycleStatus?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly orderNote?: Maybe<Scalars['String']>;
  readonly orderedBy?: Maybe<Contact>;
  readonly priceTier?: Maybe<KeyValue>;
  readonly project?: Maybe<Scalars['String']>;
  readonly quoteType?: Maybe<KeyValue>;
  readonly refs?: Maybe<ReadonlyArray<Ref>>;
  readonly relation?: Maybe<KeyValue>;
  readonly reps?: Maybe<ReadonlyArray<CustomerOrderRep>>;
  readonly requirements?: Maybe<ReadonlyArray<OrderRequirement>>;
  /** Timestamp by when the tendering system expects a response */
  readonly respondBy?: Maybe<DatetimeWithTimezone>;
  /** The SCAC to which this order was tendered */
  readonly scac?: Maybe<Scalars['String']>;
  readonly segmentCode?: Maybe<Scalars['String']>;
  readonly serviceLevel?: Maybe<KeyValue>;
  readonly size?: Maybe<KeyValue>;
  readonly tenderFrom?: Maybe<Customer>;
  readonly tenderStatus?: Maybe<KeyValue>;
  /** Timestamp when this shipment was tendered */
  readonly tenderedAt?: Maybe<DatetimeWithTimezone>;
  readonly totalDistance?: Maybe<Length>;
  readonly trailerTypes?: Maybe<ReadonlyArray<KeyValue>>;
  readonly transportMode?: Maybe<KeyValue>;
};


export type CustomerOrderCargoInsuranceArgs = {
  unit?: InputMaybe<UnitOfCurrencyEnum>;
};


export type CustomerOrderTotalDistanceArgs = {
  unit: UnitOfLengthEnum;
};

export type CustomerOrderDimensions = {
  readonly __typename?: 'CustomerOrderDimensions';
  readonly height?: Maybe<Length>;
  readonly length: Length;
  readonly width?: Maybe<Length>;
};


export type CustomerOrderDimensionsHeightArgs = {
  unit: UnitOfLengthEnum;
};


export type CustomerOrderDimensionsLengthArgs = {
  unit: UnitOfLengthEnum;
};


export type CustomerOrderDimensionsWidthArgs = {
  unit: UnitOfLengthEnum;
};

export type CustomerOrderEdge = {
  readonly __typename?: 'CustomerOrderEdge';
  readonly node: CustomerOrderV2;
  readonly cursor: Scalars['String'];
};

export type CustomerOrderError = {
  readonly __typename?: 'CustomerOrderError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly serviceError?: Maybe<Scalars['JSON']>;
};

export type CustomerOrderInput = {
  readonly customerId: Scalars['ID'];
  readonly requirements?: InputMaybe<ReadonlyArray<OrderRequirementInput>>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly commodities?: InputMaybe<ReadonlyArray<CommodityInput>>;
  readonly shipments?: InputMaybe<ReadonlyArray<ShipmentInput>>;
  /** Please use orderTypeId. It supports the Order Type DDT value. */
  readonly orderType?: InputMaybe<Scalars['String']>;
  readonly orderTypeId?: InputMaybe<Scalars['ID']>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInputV2>>;
  readonly dimensions?: InputMaybe<DimensionsInput>;
  readonly reps?: InputMaybe<ReadonlyArray<CustomerOrderRepInput>>;
  readonly activationStatusId?: InputMaybe<Scalars['ID']>;
  readonly tenderStatusId?: InputMaybe<Scalars['ID']>;
  /** Please use cargoInsuranceV2. It supports new currency values */
  readonly cargoInsurance?: InputMaybe<CurrencyInput>;
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly relationId?: InputMaybe<Scalars['ID']>;
  readonly quoteTypeId?: InputMaybe<Scalars['ID']>;
  readonly priceTierId?: InputMaybe<Scalars['ID']>;
  readonly invoiceNote?: InputMaybe<Scalars['String']>;
  readonly orderNote?: InputMaybe<Scalars['String']>;
  readonly acceptedById?: InputMaybe<Scalars['ID']>;
  readonly declinedById?: InputMaybe<Scalars['ID']>;
  readonly declineReason?: InputMaybe<Scalars['String']>;
  readonly declinedReasonId?: InputMaybe<Scalars['String']>;
  readonly orderedById?: InputMaybe<Scalars['ID']>;
  readonly divisionV2?: InputMaybe<Scalars['ID']>;
  /** businessUnit is deprecated */
  readonly businessUnit?: InputMaybe<Scalars['ID']>;
  readonly projectV2?: InputMaybe<Scalars['ID']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly serviceLevelId?: InputMaybe<Scalars['String']>;
  readonly billToCustomerId?: InputMaybe<Scalars['ID']>;
  readonly billToCodeId?: InputMaybe<Scalars['ID']>;
  readonly tenderFromId?: InputMaybe<Scalars['ID']>;
  readonly activationStatusReason?: InputMaybe<Scalars['String']>;
  readonly scac?: InputMaybe<Scalars['String']>;
  readonly tenderedAt?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly respondBy?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly customerOrderNumber?: InputMaybe<Scalars['String']>;
  readonly source?: InputMaybe<Scalars['String']>;
  /** Mapped to Total Handling Units on UI  */
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  readonly paymentTermsId?: InputMaybe<Scalars['ID']>;
  readonly tarpType?: InputMaybe<Scalars['ID']>;
  readonly tarpCount?: InputMaybe<Scalars['Int']>;
  readonly braceTypes?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly braceCount?: InputMaybe<Scalars['Int']>;
  readonly cargoInsuranceV2?: InputMaybe<LoadCurrencyInput>;
  readonly masteredSystem?: InputMaybe<Scalars['Boolean']>;
  readonly externalDistance?: InputMaybe<UnitOfLengthInput>;
};

export type CustomerOrderList = {
  readonly __typename?: 'CustomerOrderList';
  readonly id: Scalars['ID'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly orderTenderStatus?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Float']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['String']>;
  readonly orderMode?: Maybe<Scalars['String']>;
  readonly orderSize?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly mainOrderRep?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly routeDistance?: Maybe<Scalars['Float']>;
  readonly routeDistanceUnit?: Maybe<Scalars['String']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  readonly routeCarrierId?: Maybe<Scalars['String']>;
  readonly mainRouteRep?: Maybe<Scalars['String']>;
  readonly routeMaxCost?: Maybe<Scalars['Float']>;
  readonly routeOverMax?: Maybe<Scalars['Float']>;
  readonly routeTotalRate?: Maybe<Scalars['Float']>;
  readonly routeTotalCost?: Maybe<Scalars['Float']>;
  readonly loadMaxCost?: Maybe<Scalars['Float']>;
  readonly loadOverMax?: Maybe<Scalars['Float']>;
  readonly loadTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalCost?: Maybe<Scalars['Float']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly orderType?: Maybe<Scalars['String']>;
  readonly orderReferences?: Maybe<Scalars['String']>;
};

export type CustomerOrderListConnection = {
  readonly __typename?: 'CustomerOrderListConnection';
  readonly edges: ReadonlyArray<CustomerOrderListEdge>;
  readonly pageInfo: PageInfo;
};

export type CustomerOrderListEdge = {
  readonly __typename?: 'CustomerOrderListEdge';
  readonly node: CustomerOrderList;
  readonly cursor: Scalars['String'];
};

export type CustomerOrderListFilterInput = {
  readonly customerId: ReadonlyArray<Scalars['ID']>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly orderType?: InputMaybe<Scalars['String']>;
};

export type CustomerOrderNumberValidation = {
  readonly __typename?: 'CustomerOrderNumberValidation';
  readonly isValid: Scalars['Boolean'];
  readonly validationMessage?: Maybe<Scalars['String']>;
};

export type CustomerOrderPayload = {
  readonly __typename?: 'CustomerOrderPayload';
  readonly errors: ReadonlyArray<CustomerOrderError>;
  readonly order?: Maybe<CustomerOrderV2>;
};

/** Holds customer order rates. */
export type CustomerOrderRatesByCarrierConnection = {
  readonly __typename?: 'CustomerOrderRatesByCarrierConnection';
  readonly code?: Maybe<Scalars['String']>;
  readonly edges?: Maybe<ReadonlyArray<Maybe<RateDetailsEdgeV2>>>;
  readonly message?: Maybe<Scalars['String']>;
};

export type CustomerOrderRatesByCarrierInputType = {
  readonly filter?: InputMaybe<CustomerOrderRatesByCarrierRequestFilter>;
};

export type CustomerOrderRatesByCarrierRequestFilter = {
  readonly applyRouteVendorReferences?: InputMaybe<Scalars['Boolean']>;
  readonly carrierId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
};

/** Holds customer order rates. */
export type CustomerOrderRatesConnectionV2 = {
  readonly __typename?: 'CustomerOrderRatesConnectionV2';
  readonly code?: Maybe<Scalars['String']>;
  readonly edges?: Maybe<ReadonlyArray<Maybe<RateDetailsEdgeV2>>>;
  readonly message?: Maybe<Scalars['String']>;
};

export type CustomerOrderRatesInputTypeV2 = {
  readonly filter?: InputMaybe<CustomerOrderRatesRequestFilterV2>;
};

export type CustomerOrderRatesRequestFilterV2 = {
  readonly orderId: Scalars['ID'];
};

/** Deprecated, use CustomerOrderRepV2 */
export type CustomerOrderRep = {
  readonly __typename?: 'CustomerOrderRep';
  readonly employee?: Maybe<Employee>;
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly employeeId: Scalars['ID'];
  readonly employeeOffice?: Maybe<KeyValue>;
  readonly fullName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly main: Scalars['Boolean'];
  readonly type: KeyValue;
};

export type CustomerOrderRepInput = {
  readonly employeeGroupId?: InputMaybe<Scalars['ID']>;
  readonly employeeId: Scalars['ID'];
  readonly employeeOfficeId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly main: Scalars['Boolean'];
  readonly typeId: Scalars['ID'];
};

export type CustomerOrderRepV2 = {
  readonly __typename?: 'CustomerOrderRepV2';
  readonly id: Scalars['ID'];
  readonly employeeId: Scalars['ID'];
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly employeeOffice?: Maybe<KeyValue>;
  readonly main: Scalars['Boolean'];
  readonly type: KeyValue;
  readonly fullName: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly employeeGroups?: Maybe<ReadonlyArray<KeyValue>>;
  readonly defaultCustomerRepId?: Maybe<Scalars['ID']>;
  readonly employee?: Maybe<Employee>;
  readonly employeeV2?: Maybe<EmployeeV2>;
};

export type CustomerOrderRequiredDocument = {
  readonly __typename?: 'CustomerOrderRequiredDocument';
  readonly id?: Maybe<Scalars['ID']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly orderId?: Maybe<Scalars['ID']>;
  readonly loadDocumentId?: Maybe<Scalars['ID']>;
  readonly isWaived?: Maybe<Scalars['Boolean']>;
  readonly loadDocumentType?: Maybe<Scalars['String']>;
  readonly stopId?: Maybe<Scalars['ID']>;
  readonly stopNumber?: Maybe<Scalars['Int']>;
  readonly stopFacilityName?: Maybe<Scalars['String']>;
  readonly stopAddress?: Maybe<Scalars['String']>;
  readonly isMergedPodDocument?: Maybe<Scalars['Boolean']>;
  readonly mergedPodStopNumbers?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly isManualRequirement?: Maybe<Scalars['Boolean']>;
  readonly loadDocument?: Maybe<LoadDocument>;
};

export type CustomerOrderRequiredDocumentAssociateInput = {
  readonly id: Scalars['ID'];
  readonly loadDocumentId: Scalars['ID'];
};

export type CustomerOrderRequiredDocumentCreateInputType = {
  readonly loadId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly loadDocumentId?: InputMaybe<Scalars['ID']>;
  readonly loadDocumentType: Scalars['String'];
  readonly isIncludeWithInvoice?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerOrderRequiredDocumentDisassociateInput = {
  readonly id: Scalars['ID'];
};

export type CustomerOrderRequiredDocumentUpdateInput = {
  readonly id: Scalars['ID'];
  readonly isWaived: Scalars['Boolean'];
  readonly loadDocumentId?: InputMaybe<Scalars['ID']>;
};

export type CustomerOrderRequiredDocumentWaiveInput = {
  readonly id: Scalars['ID'];
  readonly isWaived: Scalars['Boolean'];
};

/** Represents Customer Order Requirement Type */
export type CustomerOrderRequirement = {
  readonly __typename?: 'CustomerOrderRequirement';
  readonly createdById?: Maybe<Scalars['ID']>;
  readonly createdByName?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedById?: Maybe<Scalars['ID']>;
  readonly updatedByName?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly requirementType?: Maybe<Scalars['String']>;
  readonly requirementStatus?: Maybe<Scalars['String']>;
  readonly bypassRequirement?: Maybe<Scalars['Boolean']>;
  readonly bypassedById?: Maybe<Scalars['ID']>;
  readonly bypassedByName?: Maybe<Scalars['String']>;
  readonly bypassedOn?: Maybe<Scalars['DateTime']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly customerOrderRequirementReasons?: Maybe<ReadonlyArray<Maybe<CustomerOrderRequirementReason>>>;
};

/** Represents Customer Order Requirement Reason Type. */
export type CustomerOrderRequirementReason = {
  readonly __typename?: 'CustomerOrderRequirementReason';
  readonly createdById?: Maybe<Scalars['ID']>;
  readonly createdByName?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedById?: Maybe<Scalars['ID']>;
  readonly updatedByName?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly reasonCode?: Maybe<Scalars['String']>;
};

/** Represents input type to update customer order requirement. */
export type CustomerOrderRequirementUpdateInputType = {
  readonly id: Scalars['ID'];
  readonly bypassRequirement: Scalars['Boolean'];
};

export type CustomerOrdersConnection = {
  readonly __typename?: 'CustomerOrdersConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<CustomerOrderEdge>;
};

export type CustomerOrdersFilter = {
  /** A list of orders to return */
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** A list of order activation statuses to filter by */
  readonly activationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of order tender statuses to filter by */
  readonly tenderStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of customer identifiers to filter by */
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** A list of customer groups to filter by */
  readonly customerGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of order rep ids to filter by */
  readonly repIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** A list of pickup states to filter by */
  readonly pickupStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of delivery states to filter by */
  readonly deliveryStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Customer Order Number(s) to filter by */
  readonly customerOrderNumbers?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** The source of the orders */
  readonly source?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** The name of the field to order the results with.  Note: this can only be a field directly on the order */
  readonly sortBy?: InputMaybe<Scalars['String']>;
  /** The direction to order the results in */
  readonly sortDirection?: InputMaybe<LoadSortDirectionEnum>;
};

export type CustomerOrdersInputType = {
  readonly orderID: Scalars['ID'];
  readonly customerID: Scalars['ID'];
  readonly capacityConstraint?: InputMaybe<Scalars['String']>;
};

export type CustomerOrderV2 = {
  readonly __typename?: 'CustomerOrderV2';
  readonly id: Scalars['ID'];
  readonly accountingOrder?: Maybe<Order>;
  readonly orderFuelConfigurationOverride?: Maybe<Scalars['String']>;
  readonly alchemyRating?: Maybe<AlchemyRating>;
  readonly patterns?: Maybe<ReadonlyArray<Pattern>>;
  readonly code: Scalars['String'];
  readonly customerId: Scalars['String'];
  readonly customer: Customer;
  readonly customerV2: CustomerV2;
  readonly deleted: Scalars['Boolean'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly activationStatus?: Maybe<KeyValue>;
  readonly tenderStatus?: Maybe<KeyValue>;
  readonly transportMode?: Maybe<KeyValue>;
  readonly size?: Maybe<KeyValue>;
  readonly relation?: Maybe<KeyValue>;
  readonly quoteType?: Maybe<KeyValue>;
  readonly priceTier?: Maybe<KeyValue>;
  /**
   * Please use cargoInsuranceV2. It supports new currency values
   * @deprecated Please use cargoInsuranceV2. It supports new currency values
   */
  readonly cargoInsurance: Currency;
  readonly requirements?: Maybe<ReadonlyArray<OrderRequirement>>;
  readonly trailerTypes: ReadonlyArray<KeyValue>;
  readonly commodities?: Maybe<ReadonlyArray<CommodityV2>>;
  readonly shipments?: Maybe<ReadonlyArray<Shipment>>;
  readonly dimensions?: Maybe<CustomerOrderDimensions>;
  readonly orderNote?: Maybe<Scalars['String']>;
  readonly invoiceNote?: Maybe<Scalars['String']>;
  readonly refs?: Maybe<ReadonlyArray<RefV2>>;
  readonly reps: ReadonlyArray<CustomerOrderRep>;
  readonly acceptedBy?: Maybe<Employee>;
  readonly orderedBy?: Maybe<Contact>;
  readonly createdByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<User>;
  readonly declinedBy?: Maybe<Employee>;
  readonly acceptedByV2?: Maybe<EmployeeV2>;
  readonly orderedByV2?: Maybe<CustomerContact>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly declinedByV2?: Maybe<EmployeeV2>;
  readonly declinedReason?: Maybe<KeyValue>;
  readonly declinedReasonId?: Maybe<Scalars['String']>;
  readonly version: Scalars['Int'];
  readonly division?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  readonly segmentCode?: Maybe<Scalars['String']>;
  readonly serviceLevel?: Maybe<KeyValue>;
  readonly billToCustomer?: Maybe<Customer>;
  readonly billToCodeId?: Maybe<Scalars['ID']>;
  readonly tenderFrom?: Maybe<Customer>;
  readonly billToCustomerV2?: Maybe<CustomerV2>;
  readonly tenderFromV2?: Maybe<CustomerV2>;
  readonly activationStatusReason?: Maybe<Scalars['String']>;
  readonly lifeCycleStatus?: Maybe<Scalars['String']>;
  readonly scac?: Maybe<Scalars['String']>;
  readonly tenderedAt?: Maybe<DatetimeWithTimezone>;
  readonly respondBy?: Maybe<DatetimeWithTimezone>;
  readonly customerOrderNumber?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly repsV2?: Maybe<ReadonlyArray<CustomerOrderRepV2>>;
  /** Mapped to Total Handling Units on UI  */
  readonly numberOfPallets?: Maybe<Scalars['Int']>;
  readonly paymentTerms?: Maybe<KeyValue>;
  readonly divisionV2?: Maybe<Scalars['String']>;
  readonly projectV2?: Maybe<Scalars['String']>;
  /**
   * businessUnit is deprecated
   * @deprecated businessUnit is deprecated
   */
  readonly businessUnit?: Maybe<Scalars['String']>;
  readonly tarpType?: Maybe<Scalars['ID']>;
  readonly tarpCount?: Maybe<Scalars['Int']>;
  readonly braceTypes?: Maybe<ReadonlyArray<OrderBraceTypes>>;
  readonly braceCount?: Maybe<Scalars['Int']>;
  readonly cargoInsuranceV2: LoadCurrency;
  readonly masteredSystem?: Maybe<Scalars['Boolean']>;
  readonly incompleteStatusReasons?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly features?: Maybe<ReadonlyArray<Scalars['String']>>;
  /**
   * Please use orderTypeV2. It supports the Order Type DDT value.
   * @deprecated Please use orderTypeV2. It supports the Order Type DDT value.
   */
  readonly orderType?: Maybe<Scalars['String']>;
  readonly orderTypeV2?: Maybe<KeyValue>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly hierarchyCode?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly scacV2?: Maybe<KeyValue>;
  /** @deprecated order.stops does not support City/State Stops, please use order.stopsV2 instead */
  readonly stops: ReadonlyArray<OrderStop>;
  readonly stopsV2: ReadonlyArray<OrderStopV2>;
  readonly totalDistance?: Maybe<Length>;
  readonly externalDistance?: Maybe<Length>;
  readonly mastermindDistance?: Maybe<Length>;
  readonly load?: Maybe<LoadV2>;
};


export type CustomerOrderV2CargoInsuranceArgs = {
  unit?: InputMaybe<UnitOfCurrencyEnum>;
};


export type CustomerOrderV2CargoInsuranceV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CustomerOrderV2TotalDistanceArgs = {
  unit: UnitOfLengthEnum;
};


export type CustomerOrderV2ExternalDistanceArgs = {
  unit?: InputMaybe<UnitOfLengthEnum>;
};


export type CustomerOrderV2MastermindDistanceArgs = {
  unit?: InputMaybe<UnitOfLengthEnum>;
};

export type CustomerOutbound214Frequency = {
  readonly __typename?: 'CustomerOutbound214Frequency';
  readonly id?: Maybe<Scalars['UUID']>;
  readonly customerId?: Maybe<Scalars['UUID']>;
  readonly frequencyType?: Maybe<FrequencyTypes>;
  readonly frequencyValue?: Maybe<Scalars['String']>;
  readonly statusCode?: Maybe<Scalars['String']>;
  readonly output?: Maybe<Scalars['String']>;
};

export type CustomerOverheadDetail = {
  readonly __typename?: 'CustomerOverheadDetail';
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly customerOverhead?: Maybe<Scalars['Decimal']>;
};

export type CustomerPayment = {
  readonly __typename?: 'CustomerPayment';
  readonly checkNumber?: Maybe<Scalars['String']>;
  readonly issueDate?: Maybe<Scalars['Date']>;
  readonly issueDateV2?: Maybe<Scalars['DateTime']>;
  readonly amount?: Maybe<Scalars['Decimal']>;
  readonly paymentType?: Maybe<Scalars['String']>;
  readonly paymentId?: Maybe<Scalars['ID']>;
  readonly paymentCurrencyAmount?: Maybe<Scalars['Decimal']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly exchangeRateDate?: Maybe<Scalars['Date']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly paymentCurrencyUnappliedAmount?: Maybe<Scalars['Decimal']>;
  readonly unappliedCashAmount?: Maybe<Scalars['Decimal']>;
  readonly currencyDiscountAmount?: Maybe<Scalars['Decimal']>;
  readonly paymentNumber?: Maybe<Scalars['String']>;
};

export type CustomerPaymentApplication = {
  readonly __typename?: 'CustomerPaymentApplication';
  readonly postDate?: Maybe<Scalars['DateTime']>;
  readonly applicationType?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly refNumber?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly amount?: Maybe<Scalars['Decimal']>;
  readonly amountUSD?: Maybe<Scalars['Decimal']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly invoiceHeaderId?: Maybe<Scalars['ID']>;
};

export type CustomerPaymentDetails = {
  readonly __typename?: 'CustomerPaymentDetails';
  readonly id?: Maybe<Scalars['ID']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly paymentMethod?: Maybe<Scalars['String']>;
  readonly paymentAmount?: Maybe<Scalars['Decimal']>;
  readonly unappliedAmount?: Maybe<Scalars['Decimal']>;
  readonly discountAmount?: Maybe<Scalars['Decimal']>;
  readonly paymentAmountUSD?: Maybe<Scalars['Decimal']>;
  readonly unappliedAmountUSD?: Maybe<Scalars['Decimal']>;
  readonly discountAmountUSD?: Maybe<Scalars['Decimal']>;
  readonly checkNumber?: Maybe<Scalars['String']>;
  readonly paymentDetailsModels?: Maybe<ReadonlyArray<Maybe<CustomerPaymentApplication>>>;
};

export type CustomerPaymentInput = {
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly customerOrderId?: InputMaybe<Scalars['ID']>;
  readonly invoiceId?: InputMaybe<Scalars['ID']>;
};

/** Customer Payment Term */
export type CustomerPaymentTerm = {
  readonly __typename?: 'CustomerPaymentTerm';
  readonly id: Scalars['ID'];
  readonly customerPaymentTermId: Scalars['ID'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly discountDateCalculation?: Maybe<Scalars['Int']>;
  readonly discountPercent?: Maybe<Scalars['Decimal']>;
  readonly dueDateCalculation?: Maybe<Scalars['Int']>;
};

export type CustomerPaymentTermAddInput = {
  readonly customerPaymentTermId?: InputMaybe<Scalars['ID']>;
  readonly discountDateCalculation?: InputMaybe<Scalars['Int']>;
  readonly discountPercent?: InputMaybe<Scalars['Decimal']>;
  readonly dueDateCalculation?: InputMaybe<Scalars['Decimal']>;
};

export type CustomerPaymentTermUpdateInput = {
  readonly id: Scalars['ID'];
  readonly discountDateCalculation?: InputMaybe<Scalars['Int']>;
  readonly discountPercent?: InputMaybe<Scalars['Decimal']>;
  readonly dueDateCalculation?: InputMaybe<Scalars['Decimal']>;
};

export type CustomerPortfolio = {
  readonly __typename?: 'CustomerPortfolio';
  readonly edges?: Maybe<ReadonlyArray<Maybe<PortfolioEdge>>>;
  readonly pageInfo?: Maybe<PortfolioPageInfo>;
};

export type CustomerPortfolioEntity = {
  readonly __typename?: 'customerPortfolioEntity';
  readonly customer?: Maybe<Customer>;
  readonly CustomerV2?: Maybe<CustomerV2>;
  readonly lastInteraction?: Maybe<PortfolioInteraction>;
};

export type CustomerPortfolioInputType = {
  readonly filter?: InputMaybe<CustomersWithInteractionFilterInputType>;
};

export type CustomerPriorityQuoteTypeInput = {
  readonly customerId: Scalars['ID'];
  readonly quoteType: Scalars['String'];
};

export type CustomerQuote = {
  readonly __typename?: 'CustomerQuote';
  readonly allTotalRate?: Maybe<Scalars['Float']>;
  readonly code?: Maybe<Scalars['Int']>;
  /** @deprecated use createdAtDateTime for timestamp */
  readonly createdAt: Scalars['ISO8601Date'];
  readonly createdAtDateTime: Scalars['ISO8601DateTime'];
  readonly createdBy: Employee;
  readonly createdById: Scalars['ID'];
  readonly customer: Customer;
  readonly customerId: Scalars['ID'];
  readonly destinationGeographySource?: Maybe<Scalars['String']>;
  readonly destinationGeographySourceId?: Maybe<Scalars['Int']>;
  readonly destinationText: Scalars['String'];
  readonly distance: UnitOfDistance;
  /** @deprecated use effectiveDateTime for timestamp */
  readonly effectiveDate: Scalars['ISO8601Date'];
  readonly effectiveDateTime: Scalars['ISO8601DateTime'];
  /** @deprecated use expirationDateTime for timestamp */
  readonly expirationDate?: Maybe<Scalars['ISO8601Date']>;
  readonly expirationDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly extrasTotalRate?: Maybe<Scalars['Float']>;
  readonly fuelTotalRate?: Maybe<Scalars['Float']>;
  readonly id: Scalars['ID'];
  readonly lineItems?: Maybe<ReadonlyArray<QuoteLineItem>>;
  readonly linehaulTotalRate?: Maybe<Scalars['Float']>;
  readonly loadSize: KeyValue;
  readonly minHeight?: Maybe<UnitOfDistance>;
  readonly minLength?: Maybe<UnitOfDistance>;
  readonly minWidth?: Maybe<UnitOfDistance>;
  readonly multipleStops: Scalars['Boolean'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly originGeographySource?: Maybe<Scalars['String']>;
  readonly originGeographySourceId?: Maybe<Scalars['Int']>;
  readonly originText: Scalars['String'];
  readonly quoteGroup?: Maybe<QuoteGroup>;
  readonly quoteGroupId?: Maybe<Scalars['ID']>;
  readonly quotePriceTier?: Maybe<KeyValue>;
  readonly quoteType: KeyValue;
  readonly quoteTypeId: Scalars['ID'];
  readonly quotedBy: Employee;
  readonly quotedById: Scalars['ID'];
  readonly quotedTo?: Maybe<Contact>;
  readonly routingType: Scalars['String'];
  /** @deprecated Use scacV2 */
  readonly scac?: Maybe<Scalars['String']>;
  readonly scacV2: KeyValue;
  readonly team: Scalars['Boolean'];
  readonly trailerTypes?: Maybe<ReadonlyArray<KeyValue>>;
  readonly transportMode: KeyValue;
  readonly transportModeId: Scalars['ID'];
};

/** The connection type for CustomerQuote. */
export type CustomerQuoteConnection = {
  readonly __typename?: 'CustomerQuoteConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<CustomerQuoteEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CustomerQuoteEdge = {
  readonly __typename?: 'CustomerQuoteEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: CustomerQuote;
};

/** customerQuotePaginatedV2 is deprecated. Use CustomerQuoteV3. */
export type CustomerQuotePaginatedV2 = {
  readonly __typename?: 'customerQuotePaginatedV2';
  readonly allTotalRate?: Maybe<RateQuoteCurrencyOutput>;
  readonly code?: Maybe<Scalars['Int']>;
  readonly createdAtDateTime?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Employee>;
  readonly createdById: Scalars['ID'];
  readonly customer?: Maybe<Customer>;
  readonly customerId: Scalars['ID'];
  readonly destinationGeographySource?: Maybe<Scalars['String']>;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly destinationGeographySourceId?: Maybe<Scalars['Int']>;
  /**
   * DEPRECATED. Do not use this.
   * @deprecated Field no longer supported
   */
  readonly destinationText: Scalars['String'];
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly distance?: Maybe<RateQuoteUnitOfDistance>;
  readonly effectiveDateTime: Scalars['DateTime'];
  readonly expirationDateTime?: Maybe<Scalars['DateTime']>;
  readonly extrasTotalRate?: Maybe<RateQuoteCurrencyOutput>;
  readonly fuelTotalRate?: Maybe<RateQuoteCurrencyOutput>;
  readonly id: Scalars['ID'];
  readonly lane?: Maybe<Lane>;
  readonly laneId: Scalars['Uuid'];
  readonly linehaulTotalRate?: Maybe<RateQuoteCurrencyOutput>;
  readonly lineItems?: Maybe<ReadonlyArray<RateQuoteLineItemOutput>>;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly loadSize: RateQuoteKeyValue;
  readonly minHeight?: Maybe<RateQuoteUnitOfDistance>;
  readonly minLength?: Maybe<RateQuoteUnitOfDistance>;
  readonly minWidth?: Maybe<RateQuoteUnitOfDistance>;
  readonly multipleStops: Scalars['Boolean'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly originGeographySource?: Maybe<Scalars['String']>;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly originGeographySourceId?: Maybe<Scalars['Int']>;
  /**
   * DEPRECATED. Do not use this.
   * @deprecated Field no longer supported
   */
  readonly originText: Scalars['String'];
  readonly quotedBy?: Maybe<Employee>;
  readonly quotedById: Scalars['ID'];
  readonly quotedToId?: Maybe<Scalars['ID']>;
  readonly quoteGroup?: Maybe<RateQuoteQuoteGroup>;
  readonly quotePriceTier?: Maybe<RateQuoteKeyValue>;
  readonly quoteType: RateQuoteKeyValue;
  readonly quoteTypeId?: Maybe<Scalars['ID']>;
  readonly routingType: Scalars['String'];
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly scac?: Maybe<Scalars['String']>;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly scacV2: RateQuoteKeyValue;
  readonly team: Scalars['Boolean'];
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly trailerTypes?: Maybe<ReadonlyArray<RateQuoteKeyValue>>;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly transportMode: RateQuoteKeyValue;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly transportModeId: Scalars['String'];
};

/** A connection to a list of items. */
export type CustomerQuotePaginatedV2Connection = {
  readonly __typename?: 'customerQuotePaginatedV2Connection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CustomerQuotePaginatedV2Edge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CustomerQuotePaginatedV2>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerQuotePaginatedV2Edge = {
  readonly __typename?: 'customerQuotePaginatedV2Edge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CustomerQuotePaginatedV2>;
};

/** DEPRECATED. use CustomerQuoteV4 */
export type CustomerQuoteV3 = {
  readonly __typename?: 'CustomerQuoteV3';
  readonly allTotalRate?: Maybe<RateQuoteCurrencyOutput>;
  readonly autoAccept: Scalars['Boolean'];
  readonly code?: Maybe<Scalars['Int']>;
  readonly createdAtDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated DEPRECATED. Use userCreatedBy */
  readonly createdBy?: Maybe<Employee>;
  readonly createdById: Scalars['ID'];
  readonly createdByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly customer?: Maybe<Customer>;
  readonly customerId: Scalars['ID'];
  readonly customerV2?: Maybe<CustomerV2>;
  readonly effectiveDateTime: Scalars['DateTime'];
  readonly expirationDateTime?: Maybe<Scalars['DateTime']>;
  readonly extrasTotalRate?: Maybe<RateQuoteCurrencyOutput>;
  readonly fuelTotalRate?: Maybe<RateQuoteCurrencyOutput>;
  readonly id: Scalars['ID'];
  /** @deprecated DEPRECATED. Use LaneV2 */
  readonly lane?: Maybe<Lane>;
  readonly laneId?: Maybe<Scalars['ID']>;
  readonly laneV2?: Maybe<LaneV2>;
  readonly linehaulTotalRate?: Maybe<RateQuoteCurrencyOutput>;
  readonly lineItems?: Maybe<ReadonlyArray<RateQuoteLineItemOutput>>;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly loadSize?: Maybe<RateQuoteKeyValue>;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly minHeight?: Maybe<RateQuoteUnitOfDistance>;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly minLength?: Maybe<RateQuoteUnitOfDistance>;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly minWidth?: Maybe<RateQuoteUnitOfDistance>;
  readonly multipleStops: Scalars['Boolean'];
  readonly notes?: Maybe<Scalars['String']>;
  /** @deprecated DEPRECATED. use userQuotedBy */
  readonly quotedBy?: Maybe<Employee>;
  readonly quotedById: Scalars['ID'];
  readonly quotedByUser?: Maybe<User>;
  readonly quotedByUserV2?: Maybe<UserV2>;
  readonly quotedToId?: Maybe<Scalars['ID']>;
  readonly quoteGroup?: Maybe<RateQuoteQuoteGroup>;
  readonly quotePriceTier?: Maybe<RateQuoteKeyValue>;
  readonly quoteType: RateQuoteKeyValue;
  readonly quoteTypeId?: Maybe<Scalars['ID']>;
  readonly routingType: Scalars['String'];
  readonly scac?: Maybe<RateQuoteKeyValue>;
  readonly team: Scalars['Boolean'];
  /** @deprecated This property is Depericated use createByUser */
  readonly userCreatedBy?: Maybe<User>;
  /** @deprecated This property is Depericated use quotedByUser */
  readonly userQuotedBy?: Maybe<User>;
};

/** A connection to a list of items. */
export type CustomerQuoteV3Connection = {
  readonly __typename?: 'CustomerQuoteV3Connection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CustomerQuoteV3Edge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CustomerQuoteV3>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerQuoteV3Edge = {
  readonly __typename?: 'CustomerQuoteV3Edge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CustomerQuoteV3>;
};

export type CustomerQuoteV4 = {
  readonly __typename?: 'CustomerQuoteV4';
  readonly id: Scalars['ID'];
  readonly allTotalRate?: Maybe<RateQuoteCurrencyOutputV2>;
  readonly autoAccept: Scalars['Boolean'];
  readonly businessUnit?: Maybe<RateQuoteKeyValue>;
  readonly code?: Maybe<Scalars['Int']>;
  readonly createdAtDateTime?: Maybe<Scalars['DateTime']>;
  readonly createdById: Scalars['ID'];
  readonly createdByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly customDistance?: Maybe<CustomDistanceResponse>;
  readonly customer?: Maybe<Customer>;
  readonly customerId: Scalars['ID'];
  readonly customerV2?: Maybe<CustomerV2>;
  readonly distanceType: Scalars['Boolean'];
  readonly effectiveDateTime: Scalars['DateTime'];
  readonly expirationDateTime?: Maybe<Scalars['DateTime']>;
  readonly externalPricing: Scalars['Boolean'];
  readonly extrasTotalRate?: Maybe<RateQuoteCurrencyOutputV2>;
  readonly fuelTotalRate?: Maybe<RateQuoteCurrencyOutputV2>;
  readonly laneId?: Maybe<Scalars['ID']>;
  readonly laneV2?: Maybe<LaneV2>;
  readonly linehaulTotalRate?: Maybe<RateQuoteCurrencyOutputV2>;
  readonly lineItems?: Maybe<ReadonlyArray<RateQuoteLineItemOutputV2>>;
  readonly multipleStops: Scalars['Boolean'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly orderRequirements?: Maybe<ReadonlyArray<OrderRequirementResponse>>;
  readonly project?: Maybe<RateQuoteKeyValue>;
  readonly quotedById: Scalars['ID'];
  readonly quotedByUser?: Maybe<User>;
  readonly quotedByUserV2?: Maybe<UserV2>;
  readonly quotedToId?: Maybe<Scalars['ID']>;
  readonly quoteFuelConfiguration: RateQuoteKeyValue;
  readonly quoteGroup?: Maybe<RateQuoteQuoteGroup>;
  readonly quoteGroupId?: Maybe<Scalars['ID']>;
  readonly quotePriceTier?: Maybe<RateQuoteKeyValue>;
  readonly quoteType: RateQuoteKeyValue;
  readonly quoteTypeId?: Maybe<Scalars['ID']>;
  readonly routingType: Scalars['String'];
  readonly scac?: Maybe<RateQuoteKeyValue>;
  readonly team: Scalars['Boolean'];
};

/** A connection to a list of items. */
export type CustomerQuoteV4Connection = {
  readonly __typename?: 'CustomerQuoteV4Connection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CustomerQuoteV4Edge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CustomerQuoteV4>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerQuoteV4Edge = {
  readonly __typename?: 'CustomerQuoteV4Edge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CustomerQuoteV4>;
};

export type CustomerRegion = {
  readonly __typename?: 'CustomerRegion';
  readonly id: Scalars['ID'];
  readonly division?: Maybe<Scalars['String']>;
  readonly region?: Maybe<Scalars['String']>;
  readonly customerId: Scalars['ID'];
};

export type CustomerRep = {
  readonly __typename?: 'CustomerRep';
  readonly employee: Employee;
  readonly id: Scalars['ID'];
  readonly isLoadRep: Scalars['Boolean'];
  readonly main: Scalars['Boolean'];
  readonly repType: KeyValue;
};

export type CustomerRepInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly employeeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isLoadRep?: InputMaybe<Scalars['Boolean']>;
  readonly main: Scalars['Boolean'];
  readonly repTypeId: Scalars['ID'];
};

export type CustomerRepInputV2 = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly employeeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isLoadRep?: InputMaybe<Scalars['Boolean']>;
  readonly main: Scalars['Boolean'];
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly repTypeId: Scalars['ID'];
  /** The field `divisionId` is deprecated. It is not editable from reps and pulled from the employee record. */
  readonly divisionId?: InputMaybe<Scalars['ID']>;
  readonly modeId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly directionId?: InputMaybe<Scalars['ID']>;
  /** The field `businessUnitId` is deprecated. It is not editable from reps and pulled from the employee record. */
  readonly businessUnitId?: InputMaybe<Scalars['ID']>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId?: InputMaybe<Scalars['ID']>;
};

export type CustomerRepV2 = {
  readonly __typename?: 'CustomerRepV2';
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly employee: EmployeeV2;
  readonly id: Scalars['ID'];
  readonly isLoadRep: Scalars['Boolean'];
  readonly main: Scalars['Boolean'];
  readonly active: Scalars['Boolean'];
  readonly repType: KeyValue;
  readonly repTypeId?: Maybe<Scalars['String']>;
  /** @deprecated division is not editable from reps and pulled from the employee record. */
  readonly division?: Maybe<KeyValue>;
  /** @deprecated divisionId is not editable from reps and pulled from the employee record.  */
  readonly divisionId?: Maybe<Scalars['ID']>;
  readonly mode?: Maybe<KeyValue>;
  readonly modeId?: Maybe<Scalars['ID']>;
  readonly size?: Maybe<KeyValue>;
  readonly sizeId?: Maybe<Scalars['ID']>;
  readonly direction?: Maybe<KeyValue>;
  readonly directionId?: Maybe<Scalars['ID']>;
  /** @deprecated businessUnit is not editable from reps and pulled from the employee record.  */
  readonly businessUnit?: Maybe<KeyValue>;
  /** @deprecated businessUnitId is not editable from reps and pulled from the employee record.  */
  readonly businessUnitId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type CustomerRequestFilterType = {
  readonly code?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
};

export type CustomerRequiredDocument = {
  readonly __typename?: 'CustomerRequiredDocument';
  readonly id: Scalars['ID'];
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
  readonly chargeType?: Maybe<Scalars['ID']>;
  readonly isMergePod?: Maybe<Scalars['Boolean']>;
  readonly isRecalculateRequirements?: Maybe<Scalars['Boolean']>;
  readonly isDeliveryDateAfter?: Maybe<Scalars['Boolean']>;
  readonly isFinancialStatus?: Maybe<Scalars['Boolean']>;
  readonly isLifeCycleStatus?: Maybe<Scalars['Boolean']>;
  readonly deliveryDateAfter?: Maybe<Scalars['Date']>;
  readonly financialStatus?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly lifeCycleStatus?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly isIncludeWithInvoice: Scalars['Boolean'];
  readonly customerId: Scalars['ID'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly billToCodeId?: Maybe<Scalars['ID']>;
  readonly billToCodeCode?: Maybe<Scalars['String']>;
};

/** The input type for a new customer required document. */
export type CustomerRequiredDocumentCreateInput = {
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
  readonly chargeType?: InputMaybe<Scalars['ID']>;
  readonly isMergePod?: InputMaybe<Scalars['Boolean']>;
  readonly recalcOptions?: InputMaybe<RequiredDocumentRecalcOptionsInputType>;
  readonly isRecalculateRequirements?: InputMaybe<Scalars['Boolean']>;
  readonly isDeliveryDateAfter?: InputMaybe<Scalars['Boolean']>;
  readonly isFinancialStatus?: InputMaybe<Scalars['Boolean']>;
  readonly isLifeCycleStatus?: InputMaybe<Scalars['Boolean']>;
  readonly deliveryDateAfter?: InputMaybe<Scalars['Date']>;
  readonly financialStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly lifeCycleStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly isIncludeWithInvoice: Scalars['Boolean'];
  readonly customerId: Scalars['ID'];
  readonly billToCodeId?: InputMaybe<Scalars['ID']>;
};

/** The input type for creating and updating customer required document global configuration. */
export type CustomerRequiredDocumentGlobalConfigurationUpsertInput = {
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
  readonly chargeType?: InputMaybe<Scalars['ID']>;
  readonly isMergePod?: InputMaybe<Scalars['Boolean']>;
  readonly recalcOptions?: InputMaybe<RequiredDocumentRecalcOptionsInputType>;
  readonly isRecalculateRequirements?: InputMaybe<Scalars['Boolean']>;
  readonly isDeliveryDateAfter?: InputMaybe<Scalars['Boolean']>;
  readonly isFinancialStatus?: InputMaybe<Scalars['Boolean']>;
  readonly isLifeCycleStatus?: InputMaybe<Scalars['Boolean']>;
  readonly deliveryDateAfter?: InputMaybe<Scalars['Date']>;
  readonly financialStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly lifeCycleStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isIncludeWithInvoice: Scalars['Boolean'];
};

export type CustomerRequiredDocumentsGlobalConfiguration = {
  readonly __typename?: 'CustomerRequiredDocumentsGlobalConfiguration';
  readonly id: Scalars['ID'];
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
  readonly chargeType?: Maybe<Scalars['ID']>;
  readonly isMergePod?: Maybe<Scalars['Boolean']>;
  readonly isRecalculateRequirements?: Maybe<Scalars['Boolean']>;
  readonly isDeliveryDateAfter?: Maybe<Scalars['Boolean']>;
  readonly isFinancialStatus?: Maybe<Scalars['Boolean']>;
  readonly isLifeCycleStatus?: Maybe<Scalars['Boolean']>;
  readonly deliveryDateAfter?: Maybe<Scalars['Date']>;
  readonly financialStatus?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly lifeCycleStatus?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly isIncludeWithInvoice: Scalars['Boolean'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

/** The input type for updating customer required document. */
export type CustomerRequiredDocumentUpdateInput = {
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
  readonly chargeType?: InputMaybe<Scalars['ID']>;
  readonly isMergePod?: InputMaybe<Scalars['Boolean']>;
  readonly recalcOptions?: InputMaybe<RequiredDocumentRecalcOptionsInputType>;
  readonly isRecalculateRequirements?: InputMaybe<Scalars['Boolean']>;
  readonly isDeliveryDateAfter?: InputMaybe<Scalars['Boolean']>;
  readonly isFinancialStatus?: InputMaybe<Scalars['Boolean']>;
  readonly isLifeCycleStatus?: InputMaybe<Scalars['Boolean']>;
  readonly deliveryDateAfter?: InputMaybe<Scalars['Date']>;
  readonly financialStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly lifeCycleStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly isIncludeWithInvoice: Scalars['Boolean'];
  readonly customerId: Scalars['ID'];
  readonly billToCodeId?: InputMaybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
};

export type CustomerRouteDefault = {
  readonly __typename?: 'CustomerRouteDefault';
  readonly customerId: Scalars['String'];
  readonly rateConfirmNote?: Maybe<Scalars['String']>;
  readonly doNotPostLoads?: Maybe<Scalars['Boolean']>;
  readonly disableAutoPost?: Maybe<Scalars['Boolean']>;
};

export type CustomerSearch = {
  readonly __typename?: 'CustomerSearch';
  readonly id: Scalars['ID'];
  readonly customerCode?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerMainCity?: Maybe<Scalars['String']>;
  readonly customerMainState?: Maybe<Scalars['String']>;
  readonly customerMainRepName?: Maybe<Scalars['String']>;
  readonly customerStatus?: Maybe<Scalars['String']>;
  readonly customerLevel?: Maybe<Scalars['String']>;
  readonly customerOpportunity?: Maybe<Scalars['String']>;
  readonly customerPhone?: Maybe<Scalars['String']>;
  readonly customerWebsite?: Maybe<Scalars['String']>;
  readonly customerAddressId?: Maybe<Scalars['String']>;
  readonly customerAddressCity?: Maybe<Scalars['String']>;
  readonly customerAddressState?: Maybe<Scalars['String']>;
  readonly customerAddressCountry?: Maybe<Scalars['String']>;
  readonly customerAddressZipCode?: Maybe<Scalars['String']>;
  readonly customerAddressIsMain?: Maybe<Scalars['Boolean']>;
  readonly customerType?: Maybe<Scalars['String']>;
  readonly customerAddressDistance?: Maybe<Scalars['Float']>;
  readonly customerOrganizationName?: Maybe<Scalars['String']>;
  readonly customerOrganizationId?: Maybe<Scalars['String']>;
  readonly customerCreditStatus?: Maybe<Scalars['String']>;
  readonly customerAccountingStatus?: Maybe<Scalars['String']>;
  readonly mainRepId?: Maybe<Scalars['String']>;
  readonly customerStreetAddress?: Maybe<Scalars['String']>;
};

export type CustomerSearchConnection = {
  readonly __typename?: 'CustomerSearchConnection';
  readonly edges: ReadonlyArray<CustomerSearchEdge>;
  readonly pageInfo: PageInfo;
};

export type CustomerSearchEdge = {
  readonly __typename?: 'CustomerSearchEdge';
  readonly node: CustomerSearch;
  readonly cursor: Scalars['String'];
};

export type CustomerSearchFilterInput = {
  readonly customerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerPhone?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly system?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly systemId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerStandingStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerLevel?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerOpportunity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly city?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly state?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly country?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly zipCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isMainAddress?: InputMaybe<Scalars['Boolean']>;
  readonly customerRadius?: InputMaybe<RadiusFilterOptional>;
  readonly mainRepId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly groupId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly facilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly website?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly organizationId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly creditStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly accountingStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerSetting = {
  readonly __typename?: 'CustomerSetting';
  readonly customer: Customer;
  readonly defaultTrailerLength: UnitOfDistance;
  readonly doNotPostLoads?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly trailerTypes?: Maybe<ReadonlyArray<KeyValue>>;
};

export type CustomerSettingInput = {
  readonly customerId: Scalars['ID'];
  readonly defaultTrailerLength?: InputMaybe<UnitOfDistanceInput>;
  readonly doNotPostLoads?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type CustomerSettingInputV2 = {
  readonly customerId: Scalars['ID'];
  readonly defaultTrailerLength?: InputMaybe<UnitOfDistanceInput>;
  readonly doNotPostLoads?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type CustomerSettingV2 = {
  readonly __typename?: 'CustomerSettingV2';
  readonly customerId?: Maybe<Scalars['String']>;
  readonly customer: CustomerV2;
  readonly defaultTrailerLength: UnitOfDistance;
  readonly doNotPostLoads?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly trailerTypes?: Maybe<ReadonlyArray<KeyValue>>;
};

export type CustomersFilter = {
  readonly address?: InputMaybe<AddressesQueryInput>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly currencyId?: InputMaybe<Scalars['ID']>;
  readonly customerCreditStatusId?: InputMaybe<Scalars['ID']>;
  readonly customerLevelTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerOpportunityTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerPaymentTermId?: InputMaybe<Scalars['ID']>;
  readonly customerStatusTypeId?: InputMaybe<Scalars['ID']>;
  readonly employeeId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly identifier?: InputMaybe<IdentifierQueryInput>;
  readonly insuranceCargo?: InputMaybe<Scalars['Int']>;
  readonly insuranceGeneral?: InputMaybe<Scalars['Int']>;
  readonly insuranceLiability?: InputMaybe<Scalars['Int']>;
  readonly invoiceDistributionMethodId?: InputMaybe<Scalars['ID']>;
  readonly isMainRep?: InputMaybe<Scalars['Boolean']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly paymentMethodId?: InputMaybe<Scalars['ID']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly sort?: InputMaybe<Scalars['String']>;
  readonly text?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
};

export type CustomersFilterV2 = {
  readonly address?: InputMaybe<CustomerAddressesQueryInput>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly currencyId?: InputMaybe<Scalars['ID']>;
  readonly customerCreditStatusId?: InputMaybe<Scalars['ID']>;
  readonly customerLevelTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerOpportunityTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerPaymentTermId?: InputMaybe<Scalars['ID']>;
  readonly customerStatusTypeId?: InputMaybe<Scalars['ID']>;
  readonly employeeId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly identifier?: InputMaybe<IdentifierQueryInputV2>;
  readonly insuranceCargo?: InputMaybe<Scalars['Int']>;
  readonly insuranceGeneral?: InputMaybe<Scalars['Int']>;
  readonly insuranceLiability?: InputMaybe<Scalars['Int']>;
  readonly invoiceDistributionMethodId?: InputMaybe<Scalars['ID']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly organizationId?: InputMaybe<Scalars['ID']>;
  readonly paymentMethodId?: InputMaybe<Scalars['ID']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly sort?: InputMaybe<Scalars['String']>;
  readonly text?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly isMainRep?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerStructure = {
  readonly __typename?: 'CustomerStructure';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
};

export type CustomersWithInteractionFilterInputType = {
  readonly repId: Scalars['ID'];
};

export type CustomerTieredRateSchedule = {
  readonly __typename?: 'CustomerTieredRateSchedule';
  readonly id?: Maybe<Scalars['ID']>;
  readonly beginRange?: Maybe<Scalars['Decimal']>;
  readonly endRange?: Maybe<Scalars['Decimal']>;
  readonly ratePer?: Maybe<Scalars['Decimal']>;
};

export type CustomerTieredRateScheduleInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly beginRange?: InputMaybe<Scalars['Decimal']>;
  readonly endRange?: InputMaybe<Scalars['Decimal']>;
  readonly ratePer?: InputMaybe<Scalars['Decimal']>;
};

export type CustomerTrackingUpdatesConfigurationFilter = {
  readonly customerCode?: InputMaybe<Scalars['String']>;
};

/** Represents the Use_Global value of particular Vendor */
export type CustomerUseGlobalType = {
  readonly __typename?: 'CustomerUseGlobalType';
  readonly customerId: Scalars['ID'];
  readonly useGlobal: Scalars['Boolean'];
};

export type CustomerV2 = {
  readonly __typename?: 'CustomerV2';
  readonly id: Scalars['ID'];
  readonly accountsReceivableOverdue?: Maybe<Scalars['Decimal']>;
  readonly isTaxExempt?: Maybe<Scalars['Boolean']>;
  readonly autoInvoiceEnabled?: Maybe<Scalars['Boolean']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Date']>;
  readonly updatedOn?: Maybe<Scalars['Date']>;
  readonly accountingStatus?: Maybe<Scalars['String']>;
  readonly ratingSourceId?: Maybe<Scalars['String']>;
  readonly priorityQuoteType?: Maybe<Scalars['String']>;
  readonly carrierRelationships?: Maybe<ReadonlyArray<CarrierCustomerRelationshipV2>>;
  readonly availableCredit?: Maybe<Scalars['Float']>;
  readonly customerStructures?: Maybe<ReadonlyArray<CustomerStructure>>;
  readonly noteDefaults?: Maybe<ReadonlyArray<NoteDefault>>;
  readonly customerFacilities?: Maybe<ReadonlyArray<CustomerFacility>>;
  readonly customerRegions?: Maybe<ReadonlyArray<CustomerRegion>>;
  readonly customerCommodities?: Maybe<ReadonlyArray<CustomerCommodity>>;
  readonly loadDefaults?: Maybe<LoadDefaults>;
  readonly customerCustomerRelationship?: Maybe<ReadonlyArray<CustomerCustomerRelationship>>;
  readonly serviceTolerances?: Maybe<ReadonlyArray<ServiceTolerance>>;
  readonly ultimateParentId?: Maybe<Scalars['String']>;
  readonly isUltimateParent?: Maybe<Scalars['Boolean']>;
  readonly customerChildren?: Maybe<ReadonlyArray<CustomerChildren>>;
  readonly level?: Maybe<Scalars['Int']>;
  readonly isParent?: Maybe<Scalars['Boolean']>;
  readonly accountingNotes?: Maybe<ReadonlyArray<CustomerNote>>;
  readonly addresses?: Maybe<ReadonlyArray<CustomerAddress>>;
  readonly allNotes?: Maybe<ReadonlyArray<CustomerNote>>;
  readonly ancestorIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly billToCustomer?: Maybe<CustomerV2>;
  readonly billToCustomerId?: Maybe<Scalars['ID']>;
  /** @deprecated Moved to Bill to Codes */
  readonly billingAddressId?: Maybe<Scalars['String']>;
  /** @deprecated Moved to Bill to Codes */
  readonly billingAddress?: Maybe<CustomerAddress>;
  readonly billingEmail?: Maybe<Scalars['String']>;
  /** @deprecated Use customerChildren */
  readonly children?: Maybe<ReadonlyArray<CustomerV2>>;
  readonly code: Scalars['String'];
  readonly contacts: ReadonlyArray<CustomerContact>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly creditHistories?: Maybe<ReadonlyArray<CreditHistoryV2>>;
  readonly creditLimit?: Maybe<Scalars['Float']>;
  readonly creditProviders?: Maybe<ReadonlyArray<CustomerCreditProviderV2>>;
  readonly creditStatus?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<KeyValue>;
  readonly currencyId?: Maybe<Scalars['ID']>;
  /** @deprecated Use credit status */
  readonly customerCreditStatus?: Maybe<KeyValue>;
  /** @deprecated Use credit status */
  readonly customerCreditStatusId?: Maybe<Scalars['ID']>;
  readonly customerLevelType?: Maybe<KeyValue>;
  readonly customerLevelTypeId?: Maybe<Scalars['ID']>;
  readonly customerOpportunityType?: Maybe<KeyValue>;
  readonly customerOpportunityTypeId?: Maybe<Scalars['ID']>;
  readonly customerPaymentTerm?: Maybe<KeyValue>;
  readonly customerPaymentTermId?: Maybe<Scalars['ID']>;
  readonly customerSalesProgressType?: Maybe<KeyValue>;
  readonly customerSalesProgressTypeId?: Maybe<Scalars['ID']>;
  readonly customerStatusType?: Maybe<KeyValue>;
  readonly customerStatusTypeId?: Maybe<Scalars['ID']>;
  readonly descendantIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly electronicTracking?: Maybe<Scalars['Boolean']>;
  readonly electronicTrackingNumber?: Maybe<Scalars['String']>;
  readonly electronicTrackingType?: Maybe<KeyValue>;
  readonly electronicTrackingTypeId?: Maybe<Scalars['ID']>;
  readonly groups?: Maybe<ReadonlyArray<CustomerGroupV2>>;
  readonly identifiers: ReadonlyArray<IdentifierV2>;
  readonly insuranceCargo: Scalars['Int'];
  readonly insuranceGeneral: Scalars['Int'];
  readonly insuranceLiability: Scalars['Int'];
  readonly minimumInsuranceRating?: Maybe<KeyValue>;
  readonly minimumInsuranceRatingId?: Maybe<Scalars['String']>;
  readonly invoiceDistributionMethod?: Maybe<KeyValue>;
  readonly invoiceDistributionMethodId?: Maybe<Scalars['ID']>;
  readonly invoiceGrouping?: Maybe<InvoiceGroupingEnum>;
  readonly mainAddressId?: Maybe<Scalars['String']>;
  readonly mainAddress?: Maybe<CustomerAddress>;
  readonly mainContactId?: Maybe<Scalars['String']>;
  readonly mainContact?: Maybe<CustomerContact>;
  readonly mainRep?: Maybe<CustomerRepV2>;
  readonly name: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly organization?: Maybe<Organization>;
  readonly organizationId?: Maybe<Scalars['ID']>;
  /** @deprecated Use relatedCustomerId/customerId from customerCustomerRelationship */
  readonly parentCustomerId?: Maybe<Scalars['String']>;
  /** @deprecated Use parentOfRelatedCustomerByCustomerId Query instead */
  readonly parent?: Maybe<CustomerV2>;
  readonly paymentMethod?: Maybe<KeyValue>;
  readonly paymentMethodId?: Maybe<Scalars['ID']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly reps?: Maybe<ReadonlyArray<CustomerRepV2>>;
  readonly allReps?: Maybe<ReadonlyArray<CustomerRepV2>>;
  readonly roeStatus?: Maybe<Scalars['String']>;
  readonly settings?: Maybe<CustomerSettingV2>;
  /** @deprecated This field is deperacted */
  readonly siblings?: Maybe<ReadonlyArray<CustomerV2>>;
  readonly loadReferenceType?: Maybe<KeyValue>;
  readonly loadReferenceTypeId?: Maybe<Scalars['ID']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  /** @deprecated Use canUseParentCredit from CreditHistoryV2 */
  readonly useParentCredit?: Maybe<Scalars['Boolean']>;
  readonly website?: Maybe<Scalars['String']>;
  readonly canUseParentCredit?: Maybe<Scalars['Boolean']>;
  readonly customerType?: Maybe<KeyValue>;
  readonly customerTypeId?: Maybe<Scalars['ID']>;
  readonly linesOfBusiness?: Maybe<ReadonlyArray<LinesOfBusiness>>;
  readonly customerRatingBasedOn?: Maybe<KeyValue>;
  readonly customerRatingBasedOnId?: Maybe<Scalars['ID']>;
  readonly facilityRelationships?: Maybe<ReadonlyArray<CustomerFacilityRelationshipV2>>;
  readonly carrierPreferences: ReadonlyArray<CarrierPreferenceResponse>;
};


export type CustomerV2CustomerFacilitiesArgs = {
  facilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


export type CustomerV2FacilityRelationshipsArgs = {
  facilityRelationshipsLoaderWithLimit?: InputMaybe<Scalars['Int']>;
};

export type CustomerValidation = {
  readonly __typename?: 'CustomerValidation';
  readonly id?: Maybe<Scalars['ID']>;
  readonly code: Scalars['String'];
  readonly error?: Maybe<Scalars['String']>;
};

export type CustomerValidationInput = {
  readonly code: Scalars['String'];
};

export type CustomFacilitySublocation = {
  readonly __typename?: 'CustomFacilitySublocation';
  readonly id?: Maybe<Scalars['ID']>;
  readonly facilityId: Scalars['ID'];
  readonly sublocation: Scalars['String'];
  readonly description: Scalars['String'];
  readonly type: KeyValue;
  readonly typeId: Scalars['String'];
  readonly locked?: Maybe<KeyValue>;
  readonly lockedId?: Maybe<Scalars['String']>;
  readonly guarded?: Maybe<KeyValue>;
  readonly guardedId?: Maybe<Scalars['String']>;
  readonly checkInMethod?: Maybe<Scalars['String']>;
  readonly directions?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly customFullAddressPoint?: Maybe<Geopoint>;
  readonly accommodations?: Maybe<ReadonlyArray<FacilitySublocationAccommodation>>;
  readonly activities?: Maybe<ReadonlyArray<FacilitySublocationActivity>>;
  readonly gates?: Maybe<ReadonlyArray<FacilitySublocationGate>>;
};

export type CustomObjectiveFilter = {
  readonly includeDeletedObjectives?: InputMaybe<Scalars['Boolean']>;
};

export type CustomObjectiveHistory = {
  readonly __typename?: 'CustomObjectiveHistory';
  readonly userEmail?: Maybe<Scalars['String']>;
  readonly action?: Maybe<Scalars['String']>;
  readonly actionDateTime?: Maybe<Scalars['DateTime']>;
};

export type DailyContainersCountPayload = {
  readonly __typename?: 'DailyContainersCountPayload';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly counts: ReadonlyArray<Scalars['Float']>;
};

export type DailyGateReservationsCountPayload = {
  readonly __typename?: 'DailyGateReservationsCountPayload';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly counts: ReadonlyArray<Scalars['Float']>;
};

export type DailyRecapSummary = {
  readonly __typename?: 'DailyRecapSummary';
  readonly recapDate: Scalars['Date'];
  readonly totalDriveTime?: Maybe<Scalars['Int']>;
  readonly totalOnDutyTime?: Maybe<Scalars['Int']>;
  readonly totalOffDutyTime?: Maybe<Scalars['Int']>;
  readonly totalBreakTime?: Maybe<Scalars['Int']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
};

export type DailyTruckCountPayload = {
  readonly __typename?: 'DailyTruckCountPayload';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly counts: ReadonlyArray<Scalars['Float']>;
};

/** Data Dictionary - old KVT values to be mapped to new Dictionary Terms */
export type DataDictionary = {
  readonly __typename?: 'DataDictionary';
  readonly types: ReadonlyArray<DataDictionaryTypes>;
};

/** Data Dictionary Option */
export type DataDictionaryOptions = {
  readonly __typename?: 'DataDictionaryOptions';
  readonly active: Scalars['Boolean'];
  readonly description?: Maybe<Scalars['String']>;
  readonly externalRefIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['ID'];
  readonly metadataJson?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly parentTermOptionId?: Maybe<Scalars['String']>;
  readonly shortDisplayName?: Maybe<Scalars['String']>;
  readonly sort: Scalars['Float'];
  readonly systemDefined: Scalars['Boolean'];
};

/** Data Dictionary Term */
export type DataDictionaryTypes = {
  readonly __typename?: 'DataDictionaryTypes';
  readonly id: Scalars['ID'];
  readonly lastUpdatedTimestamp?: Maybe<Scalars['Timestamp']>;
  readonly mmUiLocations: ReadonlyArray<DictionaryTermOptionsLocation>;
  readonly name: Scalars['String'];
  readonly options: ReadonlyArray<DataDictionaryOptions>;
  readonly parentTermDisplayName?: Maybe<Scalars['String']>;
  readonly parentTermId?: Maybe<Scalars['String']>;
  readonly sortOrder?: Maybe<Scalars['String']>;
  readonly termType?: Maybe<Scalars['String']>;
};

export enum DataType {
  Boolean = 'Boolean',
  Date = 'Date',
  Ddt = 'Ddt',
  MultiSelect = 'MultiSelect',
  Number = 'Number',
  Rule = 'Rule',
  String = 'String'
}

export type DataValue = {
  readonly __typename?: 'DataValue';
  readonly stringValue?: Maybe<Scalars['String']>;
  readonly floatValue?: Maybe<Scalars['Float']>;
  readonly booleanValue?: Maybe<Scalars['Boolean']>;
};

/** Only one of these values is expected to be set. The existence of a value will be checked in the following order: stringValue, floatValue, booleanValue */
export type DataValueInput = {
  readonly stringValue?: InputMaybe<Scalars['String']>;
  readonly floatValue?: InputMaybe<Scalars['Float']>;
  readonly booleanValue?: InputMaybe<Scalars['Boolean']>;
};

export type DatContractAddressInputType = {
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly stateOrProvince: Scalars['String'];
};

/** Holds DAT rates origin and destination info. */
export type DatContractAddressType = {
  readonly __typename?: 'DatContractAddressType';
  readonly areaName?: Maybe<Scalars['String']>;
  readonly areaType?: Maybe<Scalars['String']>;
};

/** Holds DAT rates escalation info. */
export type DatContractEscalationType = {
  readonly __typename?: 'DatContractEscalationType';
  readonly destination?: Maybe<DatContractAddressType>;
  readonly origin?: Maybe<DatContractAddressType>;
  readonly timeFrame?: Maybe<Scalars['String']>;
};

export type DatContractGeographyInput = {
  readonly destination: DatContractAddressInputType;
  readonly origin: DatContractAddressInputType;
};

/** Holds DAT contract rates info. */
export type DatContractRatesConnection = {
  readonly __typename?: 'DatContractRatesConnection';
  readonly code?: Maybe<Scalars['String']>;
  readonly edges?: Maybe<ReadonlyArray<Maybe<DatContractRatesEdge>>>;
  readonly message?: Maybe<Scalars['String']>;
};

/** Holds DAT rates edge info. */
export type DatContractRatesEdge = {
  readonly __typename?: 'DatContractRatesEdge';
  readonly node?: Maybe<DatContractResponseType>;
};

export type DatContractRatesFilterType = {
  readonly equipments?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly geography?: InputMaybe<DatContractGeographyInput>;
  readonly isForceRefresh: Scalars['Boolean'];
  readonly routes?: InputMaybe<ReadonlyArray<InputMaybe<DatContractRatesRouteInput>>>;
};

export type DatContractRatesRouteInput = {
  readonly id: Scalars['Uuid'];
};

/** Holds DAT contract rates info. */
export type DatContractRatesType = {
  readonly __typename?: 'DatContractRatesType';
  readonly avgFuelSurchargePerMileUSD?: Maybe<Scalars['Decimal']>;
  readonly avgFuelSurchargePerTripUSD?: Maybe<Scalars['Decimal']>;
  readonly companies?: Maybe<Scalars['Int']>;
  readonly high?: Maybe<DatContractRateType>;
  readonly low?: Maybe<DatContractRateType>;
  readonly mileage?: Maybe<Scalars['Decimal']>;
  readonly rate?: Maybe<DatContractRateType>;
  readonly reports?: Maybe<Scalars['Decimal']>;
  readonly standardDeviation?: Maybe<Scalars['Decimal']>;
};

/** Holds DAT rate data info. */
export type DatContractRateType = {
  readonly __typename?: 'DatContractRateType';
  readonly perMile?: Maybe<Scalars['Decimal']>;
  readonly perTrip?: Maybe<Scalars['Decimal']>;
};

/** Holds DAT rates response. */
export type DatContractResponseType = {
  readonly __typename?: 'DatContractResponseType';
  readonly escalation?: Maybe<DatContractEscalationType>;
  readonly rate?: Maybe<DatContractRatesType>;
  readonly routeId?: Maybe<Scalars['Uuid']>;
};

export type DateAndTimeType = {
  readonly __typename?: 'DateAndTimeType';
  readonly date: Scalars['DateOnly'];
  readonly time: Scalars['TimeOnly'];
};

/** Semantic descriptor for procurement-api Date filter fields e.g. `OnOrAfterDate` */
export enum DateBoundaryBehavior {
  OnOrAfterDate = 'OnOrAfterDate',
  OnOrBeforeDate = 'OnOrBeforeDate',
  MatchDate = 'MatchDate'
}

export type DatedPathEntryInput = {
  readonly datetime: Scalars['String'];
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type DateRange = {
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
};

export type DateRangeFilter = {
  readonly endDate?: InputMaybe<Scalars['Iso8601Date']>;
  readonly startDate?: InputMaybe<Scalars['Iso8601Date']>;
};

export type DateRangeInput = {
  readonly first?: InputMaybe<Scalars['Timestamp']>;
  readonly last?: InputMaybe<Scalars['Timestamp']>;
};

export type DateTimeEntryInput = {
  readonly startDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly endDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeEntryOutput = {
  readonly __typename?: 'DateTimeEntryOutput';
  readonly startDateTime?: Maybe<Scalars['DateTime']>;
  readonly endDateTime?: Maybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  readonly value: Scalars['DateTime'];
  readonly operator: Scalars['String'];
  readonly field: Scalars['String'];
};

export type DateTimeInput = {
  readonly value: Scalars['ISO8601DateTime'];
  readonly timezone: Scalars['String'];
};

export type DateTimeRangeWithTimeZone = {
  readonly __typename?: 'DateTimeRangeWithTimeZone';
  readonly startDateTime?: Maybe<Scalars['DateTime']>;
  readonly endDateTime?: Maybe<Scalars['DateTime']>;
  readonly timeZone?: Maybe<Scalars['String']>;
};

export type DatetimeWithTimezone = {
  readonly __typename?: 'DatetimeWithTimezone';
  readonly value: Scalars['ISO8601DateTime'];
  readonly timezone: Scalars['String'];
};

export type DatetimeWithTimezoneInput = {
  readonly timezone: Scalars['String'];
  readonly value: Scalars['ISO8601DateTime'];
};

export type DatSpotAddressInputType = {
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly stateOrProvince: Scalars['String'];
};

/** Holds DAT rates origin and destination info. */
export type DatSpotAddressType = {
  readonly __typename?: 'DatSpotAddressType';
  readonly areaName?: Maybe<Scalars['String']>;
  readonly areaType?: Maybe<Scalars['String']>;
};

/** Holds DAT rates escalation info. */
export type DatSpotEscalationType = {
  readonly __typename?: 'DatSpotEscalationType';
  readonly destination?: Maybe<DatSpotAddressType>;
  readonly origin?: Maybe<DatSpotAddressType>;
  readonly timeFrame?: Maybe<Scalars['String']>;
};

export type DatSpotGeographyInput = {
  readonly destination: DatSpotAddressInputType;
  readonly origin: DatSpotAddressInputType;
};

/** Holds DAT spot rates. */
export type DatSpotRatesConnection = {
  readonly __typename?: 'DatSpotRatesConnection';
  readonly code?: Maybe<Scalars['String']>;
  readonly edges?: Maybe<ReadonlyArray<Maybe<DatSpotRatesEdge>>>;
  readonly message?: Maybe<Scalars['String']>;
};

/** Holds DAT spot rates edge Info. */
export type DatSpotRatesEdge = {
  readonly __typename?: 'DatSpotRatesEdge';
  readonly node?: Maybe<DatSpotResponseType>;
};

export type DatSpotRatesFilterType = {
  readonly equipments?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly geography?: InputMaybe<DatSpotGeographyInput>;
  readonly isForceRefresh?: InputMaybe<Scalars['Boolean']>;
  readonly routes?: InputMaybe<ReadonlyArray<InputMaybe<DatSpotRatesRouteInput>>>;
};

export type DatSpotRatesRouteInput = {
  readonly id: Scalars['Uuid'];
};

/** Holds DAT spot rates info. */
export type DatSpotRatesType = {
  readonly __typename?: 'DatSpotRatesType';
  readonly avgFuelSurchargePerMileUSD?: Maybe<Scalars['Decimal']>;
  readonly avgFuelSurchargePerTripUSD?: Maybe<Scalars['Decimal']>;
  readonly companies?: Maybe<Scalars['Int']>;
  readonly high?: Maybe<DatSpotRateType>;
  readonly low?: Maybe<DatSpotRateType>;
  readonly mileage?: Maybe<Scalars['Decimal']>;
  readonly rate?: Maybe<DatSpotRateType>;
  readonly reports?: Maybe<Scalars['Decimal']>;
  readonly standardDeviation?: Maybe<Scalars['Decimal']>;
};

/** Holds DAT sopt rate data info. */
export type DatSpotRateType = {
  readonly __typename?: 'DatSpotRateType';
  readonly perMile?: Maybe<Scalars['Decimal']>;
  readonly perTrip?: Maybe<Scalars['Decimal']>;
};

/** Holds DAT spot rates response. */
export type DatSpotResponseType = {
  readonly __typename?: 'DatSpotResponseType';
  readonly escalation?: Maybe<DatSpotEscalationType>;
  readonly rate?: Maybe<DatSpotRatesType>;
  readonly ratesReceivedAt?: Maybe<Scalars['DateTime']>;
  readonly routeId?: Maybe<Scalars['Uuid']>;
};

export enum DayEnum {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday'
}

/** Deprecated - Use DayEnum */
export enum DayOfWeekEnum {
  /** @deprecated Use DayEnum */
  Sunday = 'SUNDAY',
  /** @deprecated Use DayEnum */
  Monday = 'MONDAY',
  /** @deprecated Use DayEnum */
  Tuesday = 'TUESDAY',
  /** @deprecated Use DayEnum */
  Wednesday = 'WEDNESDAY',
  /** @deprecated Use DayEnum */
  Thursday = 'THURSDAY',
  /** @deprecated Use DayEnum */
  Friday = 'FRIDAY',
  /** @deprecated Use DayEnum */
  Saturday = 'SATURDAY'
}

/** DDT entity configuration option */
export type DdtEntityConfigurationOptions = {
  readonly __typename?: 'DDTEntityConfigurationOptions';
  readonly optionId: Scalars['ID'];
};

export enum DdtEntityType {
  Customer = 'Customer'
}

export type DeactivateCarrierLaneInput = {
  readonly carrierId: Scalars['ID'];
  readonly laneId: Scalars['ID'];
  readonly updatedById: Scalars['ID'];
};

export type DeactivateCarrierLaneResponse = {
  readonly __typename?: 'DeactivateCarrierLaneResponse';
  /** @deprecated The returned value is no longer accurate. Deactivation can now occur regardless of whether there are active route matches. */
  readonly activeRoute: Scalars['Boolean'];
  readonly isSuccess: Scalars['Boolean'];
};

export type DeactivateChannelLegError = ConflictingChannelLegError | ConflictingNetworkChannelError | PlannedNetworkError;

export type DeactivateChannelLegInput = {
  readonly id: Scalars['ID'];
};

export type DeactivateChannelLegPayload = {
  readonly __typename?: 'DeactivateChannelLegPayload';
  readonly data?: Maybe<ChannelLeg>;
  readonly errors?: Maybe<ReadonlyArray<DeactivateChannelLegError>>;
};

export type DeactivateCustomerLaneInput = {
  readonly customerId: Scalars['ID'];
  readonly laneId: Scalars['ID'];
  readonly updatedById: Scalars['ID'];
};

export type DeactivateCustomerLaneResponse = {
  readonly __typename?: 'DeactivateCustomerLaneResponse';
  /** @deprecated The returned value is no longer accurate. Deactivation can now occur regardless of whether there are active order matches. */
  readonly activeOrder: Scalars['Boolean'];
  readonly isSuccess: Scalars['Boolean'];
};

export type DeactivateNetworkChannelError = ConflictingPlannedNetworkError | ConflictingChannelLegError | PlannedNetworkError;

export type DeactivateNetworkChannelInput = {
  readonly id: Scalars['ID'];
};

export type DeactivateNetworkChannelPayload = {
  readonly __typename?: 'DeactivateNetworkChannelPayload';
  readonly data?: Maybe<NetworkChannel>;
  readonly errors?: Maybe<ReadonlyArray<DeactivateNetworkChannelError>>;
};

export type DeactivatePlannedNetworkError = ConflictingPlannedNetworkError | ConflictingNetworkChannelError | PlannedNetworkError;

export type DeactivatePlannedNetworkInput = {
  readonly id: Scalars['ID'];
};

export type DeactivatePlannedNetworkPayload = {
  readonly __typename?: 'DeactivatePlannedNetworkPayload';
  readonly data?: Maybe<PlannedNetwork>;
  readonly errors?: Maybe<ReadonlyArray<DeactivatePlannedNetworkError>>;
};

export type DeactivateTruckEntryTemplateInput = {
  readonly id: Scalars['ID'];
};

export type DecimalRange = {
  readonly from?: InputMaybe<Scalars['Decimal']>;
  readonly to?: InputMaybe<Scalars['Decimal']>;
};

export type DeclineOfferResponseInput = {
  readonly offerId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly counterPrice?: InputMaybe<CurrencyInput>;
  readonly counterPriceV2?: InputMaybe<CurrencyInputV2>;
  readonly offersDriver?: InputMaybe<OffersDriverInput>;
  readonly operationSource?: InputMaybe<OfferOperationSource>;
  /** Will be overwritten with OfferResponseType.DECLINE */
  readonly type?: InputMaybe<OfferResponseType>;
};

export type DefaultConfigurationResult = {
  readonly __typename?: 'DefaultConfigurationResult';
  readonly domain: EntityConfigurationDomain;
  readonly settings: EntityConfigurationSettings;
  readonly entityType: EntityConfigurationEntityType;
  readonly lastUpdatedTimestamp?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
};

export enum DefaultVoucherDateOptionEnum {
  Todaysdate = 'TODAYSDATE',
  Datereceived = 'DATERECEIVED',
  Vendorinvoicedate = 'VENDORINVOICEDATE'
}

export type DeleteAvailableRoutesByFilterResult = {
  readonly __typename?: 'DeleteAvailableRoutesByFilterResult';
  readonly routeIds: ReadonlyArray<Scalars['String']>;
};

export type DeleteBidQuotePayload = {
  readonly __typename?: 'DeleteBidQuotePayload';
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly error?: Maybe<MasterBidError>;
};

export type DeleteCarrierCarrierRelationshipPayload = {
  readonly __typename?: 'DeleteCarrierCarrierRelationshipPayload';
  readonly carrierCarrierRelationshipDelete?: Maybe<Scalars['Boolean']>;
  readonly errors: ReadonlyArray<UserError>;
};

export type DeleteCarrierCrmDocumentInput = {
  readonly id: Scalars['ID'];
  readonly updatedBy: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
};

export type DeleteCarrierDocumentPayload = {
  readonly __typename?: 'DeleteCarrierDocumentPayload';
  readonly carrierDocument?: Maybe<CarrierDocument>;
};

export type DeleteContainerByRouteIdInput = {
  readonly previousRouteId: Scalars['ID'];
  readonly updatedByUserId: Scalars['ID'];
};

export type DeleteCustomerCrmDocumentInput = {
  readonly customerId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly updatedBy: Scalars['ID'];
};

export type DeleteCustomerDocumentPayload = {
  readonly __typename?: 'DeleteCustomerDocumentPayload';
  readonly customerDocument?: Maybe<Document>;
};

export type DeleteDriverAssignmentInput = {
  readonly userID?: InputMaybe<Scalars['ID']>;
};

export type DeleteDriverPayProgramCarrierAssociationResult = {
  readonly __typename?: 'DeleteDriverPayProgramCarrierAssociationResult';
  readonly id: Scalars['ID'];
  readonly success: Scalars['Boolean'];
  readonly message?: Maybe<Scalars['String']>;
  readonly errorCode?: Maybe<Scalars['String']>;
  readonly usedOnRoutes?: Maybe<ReadonlyArray<LoadRouteV2>>;
};

export type DeleteDriverPayProgramDriverAssociationResult = {
  readonly __typename?: 'DeleteDriverPayProgramDriverAssociationResult';
  readonly id: Scalars['ID'];
  readonly success: Scalars['Boolean'];
  readonly message?: Maybe<Scalars['String']>;
  readonly errorCode?: Maybe<Scalars['String']>;
  readonly usedOnRoutes?: Maybe<ReadonlyArray<LoadRouteV2>>;
};

export type DeleteDriverPayRateTableResult = {
  readonly __typename?: 'DeleteDriverPayRateTableResult';
  readonly id: Scalars['ID'];
  readonly success: Scalars['Boolean'];
  readonly message?: Maybe<Scalars['String']>;
  readonly errorCode?: Maybe<Scalars['String']>;
  readonly usedOnRoutes?: Maybe<ReadonlyArray<LoadRouteV2>>;
};

/** The input type to Delete Edi Outbox Message. */
export type DeleteEdiOutboxInput = {
  readonly id: Scalars['ID'];
};

export type DeleteFacilityDocumentPayload = {
  readonly __typename?: 'DeleteFacilityDocumentPayload';
  readonly facilityDocument?: Maybe<FacilityDocument>;
};

export type DeleteFilterByIdInputType = {
  readonly filterId: Scalars['String'];
  readonly objectId: Scalars['String'];
};

export type DeleteFilterResponseType = {
  readonly __typename?: 'DeleteFilterResponseType';
  readonly status: Scalars['String'];
  readonly errors: ReadonlyArray<Scalars['JSON']>;
};

export type DeleteFleetResourceBounceDriversInput = {
  readonly driverBounceReason: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type DeleteFleetResourceInput = {
  readonly resourceID: Scalars['ID'];
  readonly userID?: InputMaybe<Scalars['ID']>;
  /** Object containing various fields for bouncing drivers. Only required when there are drivers saved to the resource when the resource is deleted. */
  readonly bounceDriversInput?: InputMaybe<DeleteFleetResourceBounceDriversInput>;
};

export type DeleteFollowUpTimeInput = {
  readonly conversationId?: InputMaybe<Scalars['String']>;
  readonly userId: Scalars['String'];
};

export type DeleteFollowUpTimeInputV2 = {
  readonly conversation: ConversationInfo;
  readonly drivers: ReadonlyArray<Scalars['String']>;
  readonly userId: Scalars['String'];
};

export type DeleteFollowUpTimeResponse = {
  readonly __typename?: 'DeleteFollowUpTimeResponse';
  readonly deleted: Scalars['Boolean'];
};

export type DeleteHaykRedirectInput = {
  readonly redirectId: Scalars['String'];
};

export type DeleteLinesOfBusinessPayload = {
  readonly __typename?: 'DeleteLinesOfBusinessPayload';
  readonly lineOfBusinessDelete?: Maybe<Scalars['Boolean']>;
  readonly errors: ReadonlyArray<UserError>;
};

export type DeleteLinkedRouteInput = {
  readonly id: Scalars['ID'];
  readonly userID?: InputMaybe<Scalars['ID']>;
  readonly initiatingRoute?: InputMaybe<LinkedRouteInitiatingRouteEnum>;
};

export type DeleteLinkedRoutePayload = {
  readonly __typename?: 'DeleteLinkedRoutePayload';
  readonly errors?: Maybe<ReadonlyArray<LinkedRouteError>>;
  readonly isDeleteSuccessful: Scalars['Boolean'];
};

export type DeleteMembershipInput = {
  readonly _id?: InputMaybe<Scalars['ID']>;
  readonly userID?: InputMaybe<Scalars['ID']>;
  readonly organizationID?: InputMaybe<Scalars['ID']>;
};

export type DeleteMembershipsInput = {
  readonly userID: Scalars['ID'];
  readonly organizationIDs?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};

export type DeleteOrderStopInput = {
  readonly orderId: Scalars['ID'];
  readonly orderStopId: Scalars['ID'];
};

export type DeletePatternInput = {
  readonly patternId: Scalars['ID'];
  readonly userId: Scalars['ID'];
};

export type DeletePatternPayload = {
  readonly __typename?: 'DeletePatternPayload';
  readonly message?: Maybe<Scalars['String']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly currentLanePatterns?: Maybe<ReadonlyArray<Maybe<Pattern>>>;
};

export type DeletePatternPayloadV2 = {
  readonly __typename?: 'DeletePatternPayloadV2';
  readonly data?: Maybe<DeletePatternPayload>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type DeletePricingProgramFilter = {
  readonly id: Scalars['ID'];
};

export type DeleteRouteInput = {
  readonly routeId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
};

/** Input type for bouncing drivers */
export type DeleteRouteResourcesBounceDriversInput = {
  /** Value corresponding to a Driver Bounce Reason DDT Option ID */
  readonly bounceReason: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
};

/** Input type for Deleting Route Resources */
export type DeleteRouteResourcesInput = {
  readonly routeID: Scalars['ID'];
  readonly userID?: InputMaybe<Scalars['ID']>;
  /** Object containing various fields for bouncing drivers. Only applicable to drivers assigned to fleet type resource */
  readonly bounceDriversInput?: InputMaybe<DeleteRouteResourcesBounceDriversInput>;
};

export type DeleteRouteStopInput = {
  readonly routeId: Scalars['String'];
  readonly routeStopId: Scalars['String'];
};

export type DeleteRuleSetInput = {
  readonly eventName: Scalars['String'];
  readonly ruleSetId: Scalars['String'];
};

export type DeleteRuleSetResult = {
  readonly __typename?: 'DeleteRuleSetResult';
  readonly correlationId: Scalars['String'];
  readonly success: Scalars['Boolean'];
  readonly message?: Maybe<Scalars['String']>;
};

export type DeleteStopInput = {
  readonly routeStopId: Scalars['String'];
};

export type DeleteTablePreferenceByIdInput = {
  readonly objectId: Scalars['String'];
};

export type DeleteTablePreferenceResponseType = {
  readonly __typename?: 'DeleteTablePreferenceResponseType';
  readonly status: Scalars['String'];
  readonly errors: ReadonlyArray<Scalars['JSON']>;
};

export type DeleteTrailerFromDriverAssignmentInput = {
  readonly routeID: Scalars['ID'];
  readonly sequenceNumber: Scalars['Float'];
};

/** Input type for deleting a trailer from a given route resource */
export type DeleteTrailerFromRouteResourcesInput = {
  readonly routeID: Scalars['ID'];
  readonly sequenceNumber: Scalars['Int'];
  /** Denotes whether trailer is of type Inbound, Loaded, or Outbound. This value is defaulted to Inbound when input is null */
  readonly trailerAssignmentDesignation?: InputMaybe<TrailerAssignmentDesignationEnum>;
};

export type DeleteTripInput = {
  readonly id: Scalars['ID'];
};

export type DeleteTripOutput = {
  readonly __typename?: 'DeleteTripOutput';
  readonly deletedTrip?: Maybe<TripType>;
};

export type DeleteTruckEntryTemplateInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly updatedByUserId?: InputMaybe<Scalars['ID']>;
};

export type Density = {
  readonly __typename?: 'Density';
  readonly unit: DensityUnitTypeEnum;
  readonly value: Scalars['Float'];
};

export enum DensityUnitTypeEnum {
  Lbft3 = 'lbft3',
  Kgm3 = 'kgm3'
}

export type DensityV2 = {
  readonly __typename?: 'DensityV2';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type DeprecatedStopEventType = {
  readonly __typename?: 'DeprecatedStopEventType';
  readonly deprecatedType: Scalars['String'];
  readonly replacementType?: Maybe<Scalars['String']>;
};

export type DestinationFilter = {
  /** A partial or full city name */
  readonly city?: InputMaybe<Scalars['String']>;
  /** An array of facility IDs */
  readonly facilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** An array of abbreviated state names */
  readonly states?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** Autogenerated return type of DestroyAddress */
export type DestroyAddressPayload = {
  readonly __typename?: 'DestroyAddressPayload';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyAddress */
export type DestroyCarrierAddressPayload = {
  readonly __typename?: 'DestroyCarrierAddressPayload';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyCarrierCertification */
export type DestroyCarrierCertificationPayload = {
  readonly __typename?: 'DestroyCarrierCertificationPayload';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyCarrierCertificationV2 */
export type DestroyCarrierCertificationPayloadV2 = {
  readonly __typename?: 'DestroyCarrierCertificationPayloadV2';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyContact */
export type DestroyCarrierContactPayload = {
  readonly __typename?: 'DestroyCarrierContactPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyCarrierElectronicTrackingPayload = {
  readonly __typename?: 'DestroyCarrierElectronicTrackingPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyCarrierIdentifierPayload = {
  readonly __typename?: 'DestroyCarrierIdentifierPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyCarrierInsurancePayload = {
  readonly __typename?: 'DestroyCarrierInsurancePayload';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyCarrierIntrastateStateV2 */
export type DestroyCarrierIntrastateStatePayloadV2 = {
  readonly __typename?: 'DestroyCarrierIntrastateStatePayloadV2';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyCarrierReleaseMethodPayload = {
  readonly __typename?: 'DestroyCarrierReleaseMethodPayload';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyCarrierReleaseMethodV1 */
export type DestroyCarrierReleaseMethodV1Payload = {
  readonly __typename?: 'DestroyCarrierReleaseMethodV1Payload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyCarrierRepPayload = {
  readonly __typename?: 'DestroyCarrierRepPayload';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyCarrierService */
export type DestroyCarrierServicePayload = {
  readonly __typename?: 'DestroyCarrierServicePayload';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyCarrierService */
export type DestroyCarrierServicePayloadV2 = {
  readonly __typename?: 'DestroyCarrierServicePayloadV2';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyContact */
export type DestroyContactPayload = {
  readonly __typename?: 'DestroyContactPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyCustomerAddressPayload = {
  readonly __typename?: 'DestroyCustomerAddressPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyCustomerContactPayload = {
  readonly __typename?: 'DestroyCustomerContactPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyCustomerIdentifierPayload = {
  readonly __typename?: 'DestroyCustomerIdentifierPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyCustomerRepPayload = {
  readonly __typename?: 'DestroyCustomerRepPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyFacilityAddressPayload = {
  readonly __typename?: 'DestroyFacilityAddressPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyFacilityContactPayload = {
  readonly __typename?: 'DestroyFacilityContactPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyFacilityDirectionPayload = {
  readonly __typename?: 'DestroyFacilityDirectionPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyFacilityHolidayPayload = {
  readonly __typename?: 'DestroyFacilityHolidayPayload';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyFacilityIdentifier */
export type DestroyFacilityIdentifierPayload = {
  readonly __typename?: 'DestroyFacilityIdentifierPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyFacilityIdentifierPayloadV2 = {
  readonly __typename?: 'DestroyFacilityIdentifierPayloadV2';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyFacilityRepPayload = {
  readonly __typename?: 'DestroyFacilityRepPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyFacilitySchedulePayload = {
  readonly __typename?: 'DestroyFacilitySchedulePayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyFacilitySublocationAccommodationPayload = {
  readonly __typename?: 'DestroyFacilitySublocationAccommodationPayload';
  readonly errors: ReadonlyArray<SublocationUserError>;
};

export type DestroyFacilitySublocationActivityPayload = {
  readonly __typename?: 'DestroyFacilitySublocationActivityPayload';
  readonly errors: ReadonlyArray<SublocationUserError>;
};

export type DestroyFacilitySublocationGatePayload = {
  readonly __typename?: 'DestroyFacilitySublocationGatePayload';
  readonly errors: ReadonlyArray<SublocationUserError>;
};

export type DestroyFacilitySublocationPayload = {
  readonly __typename?: 'DestroyFacilitySublocationPayload';
  readonly errors: ReadonlyArray<SublocationUserError>;
};

/** Autogenerated return type of DestroyTruckPosting */
export type DestroyTruckPostingPayload = {
  readonly __typename?: 'DestroyTruckPostingPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DetailChargeTypeConfigurationInput = {
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['Guid']>;
  readonly carrierId?: InputMaybe<Scalars['Guid']>;
};

export type DetailChargeTypeConfigurationV2Input = {
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['Guid']>;
  readonly carrierId?: InputMaybe<Scalars['Guid']>;
};

export type DevFleetManagerDrivers = {
  readonly __typename?: 'DevFleetManagerDrivers';
  readonly id: Scalars['ID'];
  readonly businessUnit?: Maybe<Scalars['String']>;
  readonly currentRoute?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly domicile?: Maybe<Scalars['String']>;
  readonly driverCode?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverType?: Maybe<Scalars['String']>;
  readonly dutyStatus?: Maybe<Scalars['String']>;
  readonly fleet?: Maybe<Scalars['String']>;
  readonly lastPingDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastPingLocation?: Maybe<Scalars['String']>;
  readonly manager?: Maybe<Scalars['String']>;
  readonly nextAvailableLocation?: Maybe<Scalars['String']>;
  readonly nextRoute?: Maybe<Scalars['String']>;
  readonly nextTimeOffRequestDate?: Maybe<Scalars['DateTime']>;
  readonly nickname?: Maybe<Scalars['String']>;
  readonly operatingStatus?: Maybe<Scalars['String']>;
  readonly partner?: Maybe<Scalars['String']>;
  readonly partnerID?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
};

export type DevFleetManagerDriversConnection = {
  readonly __typename?: 'DevFleetManagerDriversConnection';
  readonly edges: ReadonlyArray<DevFleetManagerDriversEdge>;
  readonly pageInfo: PageInfo;
};

export type DevFleetManagerDriversEdge = {
  readonly __typename?: 'DevFleetManagerDriversEdge';
  readonly node: DevFleetManagerDrivers;
  readonly cursor: Scalars['Float'];
};

export type DevFleetManagerDriversFilterInput = {
  readonly fleetManagerIds: ReadonlyArray<Scalars['ID']>;
};

/** Entity Configuration Response Object */
export type DictionaryEntityConfig = {
  readonly __typename?: 'DictionaryEntityConfig';
  readonly customFallbackMapping: ReadonlyArray<FallbackMapping>;
  readonly entityOptions: ReadonlyArray<DataDictionaryOptions>;
  readonly fallbackOption?: Maybe<FallbackOption>;
  readonly id: Scalars['ID'];
  readonly lastUpdatedTimestamp?: Maybe<Scalars['Timestamp']>;
  readonly mmUiLocations?: Maybe<ReadonlyArray<DictionaryTermOptionsLocation>>;
  readonly name: Scalars['String'];
  readonly parentTermDisplayName?: Maybe<Scalars['String']>;
  readonly parentTermId?: Maybe<Scalars['String']>;
  readonly sortOrder?: Maybe<Scalars['String']>;
  readonly termType?: Maybe<Scalars['String']>;
};

/** Entity Configuration Audit Response Object */
export type DictionaryEntityConfigAudit = {
  readonly __typename?: 'DictionaryEntityConfigAudit';
  readonly audits: ReadonlyArray<EntityAudit>;
  readonly ddtId: Scalars['String'];
  readonly entityId: Scalars['String'];
  readonly entityType: DdtEntityType;
};

export type DictionaryEntityConfigInput = {
  readonly ddtId: Scalars['String'];
  readonly entityId: Scalars['String'];
  readonly entityType: DdtEntityType;
};

/** Entity Configuration Option Response Data */
export type DictionaryEntityConfigOption = {
  readonly __typename?: 'DictionaryEntityConfigOption';
  readonly mappedOption?: Maybe<DataDictionaryOptions>;
  readonly originalOption?: Maybe<DataDictionaryOptions>;
};

export type DictionaryEntityConfigOptionInput = {
  readonly ddtId: Scalars['String'];
  readonly entityId: Scalars['String'];
  readonly entityType: DdtEntityType;
  readonly optionId: Scalars['String'];
};

/** Entity configurations details */
export type DictionaryEntityConfigs = {
  readonly __typename?: 'DictionaryEntityConfigs';
  readonly entityConfigs: ReadonlyArray<EntityConfiguration>;
};

export type DictionaryResponse = {
  readonly __typename?: 'DictionaryResponse';
  readonly statusCode?: Maybe<Scalars['Float']>;
};

/** A Dictionary Term - formerly Customer defined values */
export type DictionaryTerm = {
  readonly __typename?: 'DictionaryTerm';
  readonly createdAt?: Maybe<Scalars['Timestamp']>;
  readonly createdByDomain?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly displayName: Scalars['String'];
  readonly editableType: Scalars['String'];
  readonly id: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly lastUpdatedTimestamp?: Maybe<Scalars['Timestamp']>;
  readonly minionDisplayName?: Maybe<Scalars['String']>;
  readonly minionHidden?: Maybe<Scalars['Boolean']>;
  readonly mmUiLocations?: Maybe<ReadonlyArray<DictionaryTermOptionsLocation>>;
  readonly options: ReadonlyArray<DictionaryTermOptions>;
  readonly parentTermDisplayName?: Maybe<Scalars['String']>;
  readonly parentTermId?: Maybe<Scalars['String']>;
  readonly sortOrder?: Maybe<Scalars['String']>;
  readonly termType?: Maybe<Scalars['String']>;
};

/** Dictionary Term Option */
export type DictionaryTermOptions = {
  readonly __typename?: 'DictionaryTermOptions';
  readonly description?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly editableType: Scalars['String'];
  readonly externalRefIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['ID'];
  readonly isActive: Scalars['Boolean'];
  readonly metaDataJson?: Maybe<Scalars['String']>;
  readonly minionHidden?: Maybe<Scalars['Boolean']>;
  readonly parentTermOptionId?: Maybe<Scalars['String']>;
  readonly shortDisplayName?: Maybe<Scalars['String']>;
  readonly sort: Scalars['Float'];
};

/** Dictionary Term Option MM Location */
export type DictionaryTermOptionsLocation = {
  readonly __typename?: 'DictionaryTermOptionsLocation';
  readonly category: Scalars['String'];
  readonly subCategory: Scalars['String'];
  readonly uiPath: Scalars['String'];
};

/** DDT history list */
export type DictionaryTermsHistory = {
  readonly __typename?: 'DictionaryTermsHistory';
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByDomain?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly displayName: Scalars['String'];
  readonly editableType: Scalars['String'];
  readonly id: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly lastUpdatedTimestamp: Scalars['String'];
  readonly minionDisplayName: Scalars['String'];
  readonly minionHidden?: Maybe<Scalars['Boolean']>;
  readonly mmUiLocations?: Maybe<ReadonlyArray<DictionaryTermOptionsLocation>>;
  readonly options: ReadonlyArray<DictionaryTermOptions>;
  readonly parentTermDisplayName?: Maybe<Scalars['String']>;
  readonly parentTermId?: Maybe<Scalars['String']>;
  readonly sortOrder?: Maybe<Scalars['String']>;
  readonly termType?: Maybe<Scalars['String']>;
  readonly updatedByName?: Maybe<Scalars['String']>;
  readonly version: Scalars['Int'];
};

/** Multiple Term Select Results */
export type DictionaryTermsQueryResult = {
  readonly __typename?: 'DictionaryTermsQueryResult';
  readonly idsNotFound: ReadonlyArray<Scalars['String']>;
  readonly terms: ReadonlyArray<DictionaryTerm>;
};

export type DictionaryUpdated = {
  readonly __typename?: 'DictionaryUpdated';
  readonly id?: Maybe<Scalars['String']>;
  readonly response?: Maybe<DictionaryResponse>;
};

export type Dimension = {
  readonly __typename?: 'Dimension';
  readonly length: Measurement;
  readonly width: Measurement;
  readonly height: Measurement;
};

export type DimensionDisplay = {
  readonly __typename?: 'DimensionDisplay';
  readonly length: Scalars['String'];
  readonly width: Scalars['String'];
  readonly height: Scalars['String'];
};

export type DimensionInput = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type Dimensions = {
  readonly __typename?: 'Dimensions';
  readonly length: Length;
  readonly width: Length;
  readonly height: Length;
};

export type DimensionsInput = {
  readonly height?: InputMaybe<UnitOfLengthInput>;
  readonly length?: InputMaybe<UnitOfLengthInput>;
  readonly width?: InputMaybe<UnitOfLengthInput>;
};

export type DimensionsInputV2 = {
  readonly height?: InputMaybe<UnitOfLengthInputV2>;
  readonly length?: InputMaybe<UnitOfLengthInputV2>;
  readonly width?: InputMaybe<UnitOfLengthInputV2>;
};

export type DimensionsRequestFilterType = {
  readonly height?: InputMaybe<BaseExternalQuoteRequestFilterType>;
  readonly minimumLength?: InputMaybe<BaseExternalQuoteRequestFilterType>;
  readonly width?: InputMaybe<BaseExternalQuoteRequestFilterType>;
};

export type DimensionsV2 = {
  readonly __typename?: 'DimensionsV2';
  readonly height: Length;
  readonly length: Length;
  readonly width: Length;
};


export type DimensionsV2HeightArgs = {
  unit?: InputMaybe<UnitOfLengthEnum>;
};


export type DimensionsV2LengthArgs = {
  unit?: InputMaybe<UnitOfLengthEnum>;
};


export type DimensionsV2WidthArgs = {
  unit?: InputMaybe<UnitOfLengthEnum>;
};

export type DimensionV2 = {
  readonly __typename?: 'DimensionV2';
  readonly length?: Maybe<Measurement>;
  readonly width?: Maybe<Measurement>;
  readonly height?: Maybe<Measurement>;
};

export type DispatchDestinationInput = {
  readonly destinationLocationType: Scalars['String'];
  readonly facilityId?: InputMaybe<Scalars['String']>;
  readonly cityState?: InputMaybe<CityStateStopLocationInput>;
  readonly routeId?: InputMaybe<Scalars['String']>;
};

export type DispatchDriver = {
  readonly __typename?: 'DispatchDriver';
  readonly fromLocation?: Maybe<DispatchDriverLocation>;
  readonly toLocation?: Maybe<DispatchDriverLocation>;
  readonly emptyMovementLoad?: Maybe<LoadV2>;
  /** Messages to communicate any information about the dispatch of the driver */
  readonly messages?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type DispatchDriverError = {
  readonly __typename?: 'DispatchDriverError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly serviceError?: Maybe<Scalars['JSON']>;
};

export type DispatchDriverInput = {
  readonly driverActivity: Scalars['String'];
  readonly destination: DispatchDestinationInput;
  readonly startLocation: DispatchStartLocationInput;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
};

export type DispatchDriverLocation = {
  readonly __typename?: 'DispatchDriverLocation';
  /** Facility ID */
  readonly id?: Maybe<Scalars['ID']>;
  readonly geopoint?: Maybe<Geopoint>;
  readonly street1?: Maybe<Scalars['String']>;
  readonly street2?: Maybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly county?: Maybe<Scalars['String']>;
};

export type DispatchDriverMobileInput = {
  readonly driverId: Scalars['ID'];
  readonly dispatchInfo: DispatchInfoInput;
};

export type DispatchDriverMobileInputV2 = {
  readonly driverId: Scalars['ID'];
  readonly dispatchInfo: DispatchInfoInputV2;
};

export type DispatchDriverMobilePayload = {
  readonly __typename?: 'DispatchDriverMobilePayload';
  readonly realm: Scalars['String'];
  readonly driverRoute: DriverRoute;
  readonly errors: ReadonlyArray<UserError>;
};

export type DispatchDriverMobilePayloadV2 = {
  readonly __typename?: 'DispatchDriverMobilePayloadV2';
  readonly realm?: Maybe<Scalars['String']>;
  readonly driverRoute?: Maybe<DriverRouteV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type DispatchDriverPayload = {
  readonly __typename?: 'DispatchDriverPayload';
  readonly errors: ReadonlyArray<DispatchDriverError>;
  readonly dispatchDriverPayload?: Maybe<DispatchDriver>;
};

export type DispatchDriverReview = {
  readonly __typename?: 'DispatchDriverReview';
  readonly fromLocation?: Maybe<DispatchDriverLocation>;
  readonly toLocation?: Maybe<DispatchDriverLocation>;
  readonly emptyMovementLoad?: Maybe<LoadV2>;
  /** Messages to communicate any information about the dispatch of the driver */
  readonly messages?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** Warnings that will appear for dispatch driver review */
  readonly warnings?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type DispatchDriverReviewError = {
  readonly __typename?: 'DispatchDriverReviewError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly serviceError?: Maybe<Scalars['JSON']>;
};

export type DispatchDriverReviewPayload = {
  readonly __typename?: 'DispatchDriverReviewPayload';
  readonly errors: ReadonlyArray<DispatchDriverReviewError>;
  readonly dispatchDriverReviewPayload?: Maybe<DispatchDriverReview>;
};

export type DispatchInfoInput = {
  readonly routeId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly equipment: Scalars['ID'];
  readonly equipmentName: Scalars['String'];
  readonly truckNumber: Scalars['String'];
  readonly etaDatetime: DateTimeInput;
  readonly readyDatetime: DateTimeInput;
  readonly readyLocation: ReadyLocationInput;
  readonly trailerNumber: Scalars['String'];
  readonly trailerDimensions: TrailerDimensionInput;
  readonly trailers: ReadonlyArray<DispatchTrailerInput>;
};

export type DispatchInfoInputV2 = {
  readonly routeId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly equipment: Scalars['ID'];
  readonly equipmentName?: InputMaybe<Scalars['String']>;
  readonly truckNumber?: InputMaybe<Scalars['String']>;
  readonly etaDatetime: DateTimeInput;
  readonly readyDatetime: DateTimeInput;
  readonly readyLocation: ReadyLocationInput;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly trailerDimensions: TrailerDimensionInputV2;
  readonly trailers: ReadonlyArray<DispatchTrailerInput>;
};

export type DispatchStartLocationInput = {
  readonly facilityId?: InputMaybe<Scalars['String']>;
  readonly cityState?: InputMaybe<CityStateStopLocationInput>;
  readonly powerUnitLocation?: InputMaybe<Scalars['Boolean']>;
};

export type DispatchTrailerInput = {
  readonly type: Scalars['String'];
  readonly lengthInFeet?: InputMaybe<Scalars['Float']>;
  readonly widthInInches?: InputMaybe<Scalars['Float']>;
  readonly heightInInches?: InputMaybe<Scalars['Float']>;
  readonly palletType?: InputMaybe<Scalars['String']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  readonly tarpType?: InputMaybe<Scalars['String']>;
  readonly numberOfTarps?: InputMaybe<Scalars['Int']>;
  readonly numberOfBracings?: InputMaybe<Scalars['Int']>;
  readonly bracings?: InputMaybe<ReadonlyArray<DriverTrailerbracingTypeInput>>;
  readonly identifier?: InputMaybe<Scalars['String']>;
  readonly areAccessoriesVisible?: InputMaybe<Scalars['Boolean']>;
  readonly sequenceNumber?: InputMaybe<Scalars['Float']>;
};

export type DistanceRequest = {
  readonly stops: ReadonlyArray<DistanceRequestStop>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
};

export type DistanceRequestStop = {
  readonly latitude?: InputMaybe<Scalars['Float']>;
  readonly longitude?: InputMaybe<Scalars['Float']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly locality?: InputMaybe<Scalars['String']>;
  readonly administrativeArea?: InputMaybe<Scalars['String']>;
  readonly countryCode?: InputMaybe<Scalars['String']>;
  readonly county?: InputMaybe<Scalars['String']>;
};

/** The preferred unit to use when calculating distance, if supported */
export enum DistanceSearchPreferenceOption {
  Postal = 'postal',
  Geopoint = 'geopoint',
  Locality = 'locality'
}

export type DistanceToNext = {
  readonly __typename?: 'DistanceToNext';
  readonly unit?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['Float']>;
};

export enum DistanceUnitEnum {
  Feet = 'feet',
  Inches = 'inches',
  Kilometers = 'kilometers',
  Meters = 'meters',
  Miles = 'miles'
}

export type DistinctCarrierIdsPayloadsWithErrors = {
  readonly __typename?: 'DistinctCarrierIdsPayloadsWithErrors';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly distinctCarrierIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type DivisionR2 = {
  readonly __typename?: 'DivisionR2';
  readonly id?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
};

export type DocsChatResponse = {
  readonly __typename?: 'DocsChatResponse';
  readonly id?: Maybe<Scalars['ID']>;
  readonly responseText?: Maybe<Scalars['String']>;
  readonly source?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type Document = {
  readonly __typename?: 'Document';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  /** The name of the file to be displayed to the user, not necessarily the filename */
  readonly name: Scalars['String'];
  /** The name of the file provided during upload */
  readonly filename: Scalars['String'];
  readonly uploadedBy: DocumentUserMeta;
  readonly uploadDate: Scalars['DateTime'];
  readonly sourceId: Scalars['ID'];
  readonly departmentTypeId: Scalars['ID'];
  readonly documentTypeId: Scalars['ID'];
  /** uri to access the customer document */
  readonly uri: Scalars['String'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
  /** uri to access the signed document */
  readonly signedUri?: Maybe<Scalars['String']>;
  /** MasterSign Reference Id */
  readonly masterSignReferenceId?: Maybe<Scalars['String']>;
  /** Identification for MasterSigner */
  readonly signedBy?: Maybe<Scalars['String']>;
  /** Timestamp for when document was signed */
  readonly signedAt?: Maybe<Scalars['DateTime']>;
  /**
   * Document Status
   * @deprecated Depcrecated in favor of documentStatus
   */
  readonly status?: Maybe<Scalars['String']>;
  /** Document Status */
  readonly documentStatus?: Maybe<DocumentStatusEnum>;
  /** Previous Document Status */
  readonly previousDocumentStatus?: Maybe<DocumentStatusEnum>;
  /** Identification for External Uploader */
  readonly externalUser?: Maybe<Scalars['String']>;
  readonly uploadedByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly customerV2: CustomerV2;
};

export enum DocumentEventType {
  Created = 'CREATED',
  Deleted = 'DELETED'
}

export enum DocumentStatusEnum {
  Signed = 'SIGNED',
  Voided = 'VOIDED',
  Generated = 'GENERATED',
  Uploaded = 'UPLOADED',
  Archived = 'ARCHIVED',
  Updated = 'UPDATED'
}

export enum DocumentType {
  LoadDocument = 'LOAD_DOCUMENT',
  CarrierDocument = 'CARRIER_DOCUMENT'
}

/** temporary type for including user id and name information - will be deprecated once we can query across the graph in federation */
export type DocumentUserMeta = {
  readonly __typename?: 'DocumentUserMeta';
  /** note - this is the user_id, NOT the employee_id */
  readonly userId: Scalars['ID'];
  readonly displayName: Scalars['String'];
};

export type DomainKeyValuePairInput = {
  readonly domain: Scalars['String'];
  readonly subDomain?: InputMaybe<Scalars['String']>;
};

/** lachesis-api: Status Object */
export type DomainStatusRecord = {
  readonly __typename?: 'DomainStatusRecord';
  /** lachesis-api: status label associated with domain status. */
  readonly status_label: Scalars['String'];
  /** lachesis-api: status value associated with domain status value. */
  readonly status_value: Scalars['Int'];
};

export type DrayNotification = LoadTender & {
  readonly __typename?: 'DrayNotification';
  readonly id: Scalars['ID'];
  readonly options: LoadTenderOptions;
  readonly routeVendor: RouteVendorV2;
  readonly sentBy: UserV2;
  readonly sentOn: Scalars['DateTime'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly document: Document;
  readonly documentV2?: Maybe<Document>;
  readonly notification: LoadTenderNotification;
  readonly status: LoadTenderStatus;
  readonly statusMessage?: Maybe<Scalars['String']>;
};

/** type for driver information */
export type Driver = {
  readonly __typename?: 'Driver';
  readonly assetDriver?: Maybe<AssetDriver>;
  /** @deprecated Deprecated. Will not resolve. Use assetDriver instead. */
  readonly assetDriverV2?: Maybe<AssetDriverV2>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
};

export type DriverActiveFleetFilter = {
  readonly carrierIds: ReadonlyArray<Scalars['String']>;
  readonly date?: InputMaybe<Scalars['Date']>;
  readonly status?: InputMaybe<Scalars['String']>;
};

export type DriverActiveFleetPayload = {
  readonly __typename?: 'DriverActiveFleetPayload';
  readonly edges: ReadonlyArray<ActiveFleetSeachOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverAddress = {
  readonly __typename?: 'DriverAddress';
  readonly id: Scalars['ID'];
  readonly addressTypeId: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
  readonly main?: Maybe<Scalars['Boolean']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverAddressInput = {
  readonly addressTypeId: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverAddressInputV2 = {
  readonly typeTerm: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverAddressObjectInput = {
  readonly typeTerm: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
  readonly county?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverAddressOutput = {
  readonly __typename?: 'DriverAddressOutput';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: Maybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
  readonly county?: Maybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type DriverAddressOutputEdge = {
  readonly __typename?: 'DriverAddressOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverAddressOutput;
};

export type DriverAddressPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly street1?: InputMaybe<Scalars['String']>;
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly county?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type DriverAddressPayload = {
  readonly __typename?: 'DriverAddressPayload';
  readonly edges: ReadonlyArray<DriverAddressOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverAddressV2 = {
  readonly __typename?: 'DriverAddressV2';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: Maybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverAssignedTrailers = {
  readonly __typename?: 'DriverAssignedTrailers';
  readonly id: Scalars['ID'];
  readonly assignedTrailerNumber: Scalars['String'];
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverAssignedTrailersInput = {
  readonly assignedTrailerNumber: Scalars['String'];
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverAssignment = {
  readonly __typename?: 'DriverAssignment';
  readonly id: Scalars['ID'];
  readonly assetPower?: Maybe<AssetPowerOutput>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly checkCallDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Removed in Rails decommission -- use createdByV2 */
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly dispatched: Scalars['Boolean'];
  readonly dispatchedBy?: Maybe<TrackingUserMeta>;
  readonly driver1?: Maybe<Driver>;
  readonly driver2?: Maybe<Driver>;
  readonly eldVehicleID?: Maybe<Scalars['String']>;
  readonly emptyDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly emptyLocation?: Maybe<Location>;
  readonly emptyTimezone?: Maybe<Scalars['String']>;
  /** @deprecated replaced by type inside inboundTrailers array */
  readonly equipmentID?: Maybe<Scalars['ID']>;
  readonly externalInfo?: Maybe<DriverAssignmentExternalInfo>;
  /** @deprecated Removed in Rails decommission -- use etaCreatedByV2 */
  readonly etaCreatedBy?: Maybe<User>;
  readonly etaCreatedByV2?: Maybe<UserV2>;
  readonly etaDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly etaTimezone?: Maybe<Scalars['String']>;
  readonly hasTrackingRequestFailed?: Maybe<Scalars['Boolean']>;
  /** Indicates whether or not `assetPower` was autofilled as a result of adding an `assetDriver` with a permanent power record */
  readonly isAssetPowerAutofilled: Scalars['Boolean'];
  /** Indicates whether or not the driver is assigned based on driver 1 name and dispatched */
  readonly isDriverAssigned: Scalars['Boolean'];
  /** Indicates whether a driver is required to dispatch with the booked carrier. */
  readonly isDriverRequired: Scalars['Boolean'];
  /** Whether the ETA, if Auto, was calculated using the Data Science formula */
  readonly isETADataScienceFormula?: Maybe<Scalars['Boolean']>;
  /** @deprecated Always `null`. Use `nextRoute` on `LoadRouteV2` as source of truth for linked route data */
  readonly nextRouteID?: Maybe<Scalars['ID']>;
  readonly notes?: Maybe<Scalars['String']>;
  /**
   * The carrier who owns the truck, if any
   * @deprecated replaced by ownerV2 inside inboundTrailers array
   */
  readonly owner?: Maybe<Carrier>;
  /**
   * The carrier who owns the truck, if any
   * @deprecated replaced by ownerV2 inside inboundTrailers array
   */
  readonly ownerV2?: Maybe<CarrierV2>;
  /** @deprecated Always `null`. Use `previousRoute` on `LoadRouteV2` as source of truth for linked route data */
  readonly previousRouteID?: Maybe<Scalars['ID']>;
  readonly reloadIntent?: Maybe<KeyValue>;
  readonly resourceType: ResourceType;
  readonly routeID: Scalars['ID'];
  readonly inboundTrailers: ReadonlyArray<RouteResourcesTrailer>;
  readonly trackingEnabled: Scalars['Boolean'];
  readonly trackingEnabledBy?: Maybe<TrackingUserMeta>;
  readonly trackingStatusMessage?: Maybe<Scalars['String']>;
  readonly trackingSystem?: Maybe<TrackingSystem>;
  readonly tractorIdentifier?: Maybe<Scalars['String']>;
  /** @deprecated replaced by heightInInches inside inboundTrailers array */
  readonly trailerHeight?: Maybe<Scalars['Float']>;
  /** @deprecated replaced by identifier inside inboundTrailers array */
  readonly trailerIdentifier?: Maybe<Scalars['String']>;
  /** @deprecated replaced by lengthInFeet inside inboundTrailers array */
  readonly trailerLength?: Maybe<Scalars['Float']>;
  readonly trailerLocation?: Maybe<Location>;
  readonly trailerLocationFacility?: Maybe<Facility>;
  readonly trailerLocationFacilityV2?: Maybe<FacilityV2>;
  /** @deprecated replaced by widthInInches inside inboundTrailers array */
  readonly trailerWidth?: Maybe<Scalars['Float']>;
  /** @deprecated Removed in Rails decommission -- use updatedByV2 */
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
};

/** info received from external source on driver assignment create/update */
export type DriverAssignmentExternalInfo = {
  readonly __typename?: 'DriverAssignmentExternalInfo';
  readonly dispatcherEmail?: Maybe<Scalars['String']>;
  readonly dispatcherName?: Maybe<Scalars['String']>;
  readonly dispatcherPhone?: Maybe<Scalars['String']>;
  readonly sourceSystem?: Maybe<Scalars['String']>;
};

/** input for external info on driver assignment create/update */
export type DriverAssignmentExternalInfoInput = {
  readonly sourceSystem?: InputMaybe<Scalars['String']>;
  readonly dispatcherName?: InputMaybe<Scalars['String']>;
  readonly dispatcherPhone?: InputMaybe<Scalars['String']>;
  readonly dispatcherEmail?: InputMaybe<Scalars['String']>;
};

/** filter inputs to get driver assignments */
export type DriverAssignmentFilter = {
  readonly routeIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly assetDriverIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly phoneNumbers?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** Input type for creating or updating a DriverAssignment of resource type `carrier` */
export type DriverAssignmentInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  /** DEPRECATED - DO NOT USE, VALUE IS THROWN AWAY */
  readonly carrierID?: InputMaybe<Scalars['ID']>;
  readonly checkCallDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly dispatched: Scalars['Boolean'];
  /** temporary field until we can resolve user info from the gateway context */
  readonly dispatchedBy?: InputMaybe<TrackingUserMetaInput>;
  readonly driver1?: InputMaybe<DriverInput>;
  readonly driver2?: InputMaybe<DriverInput>;
  readonly eldVehicleID?: InputMaybe<Scalars['String']>;
  readonly emptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly emptyLocation?: InputMaybe<LocationInput>;
  readonly emptyTimezone?: InputMaybe<Scalars['String']>;
  /** DEPRECATED - replaced by type inside inboundTrailers array */
  readonly equipmentID?: InputMaybe<Scalars['ID']>;
  readonly etaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly etaTimezone?: InputMaybe<Scalars['String']>;
  readonly externalInfo?: InputMaybe<DriverAssignmentExternalInfoInput>;
  readonly facilityID?: InputMaybe<Scalars['ID']>;
  readonly inboundTrailers?: InputMaybe<ReadonlyArray<RouteResourcesTrailerInput>>;
  readonly loadID?: InputMaybe<Scalars['ID']>;
  /** DEPRECATED - DO NOT USE, VALUE IS THROWN AWAY */
  readonly nextRouteID?: InputMaybe<Scalars['ID']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  /** DEPRECATED - replaced by ownerID inside inboundTrailers array, ID of the Carrier who owns the truck, if any */
  readonly ownerID?: InputMaybe<Scalars['String']>;
  /** DEPRECATED - DO NOT USE, VALUE IS THROWN AWAY */
  readonly previousRouteID?: InputMaybe<Scalars['ID']>;
  readonly reloadIntentID?: InputMaybe<Scalars['String']>;
  readonly routeID: Scalars['ID'];
  readonly trackingEnabled: Scalars['Boolean'];
  /** temporary field until we can resolve user info from the gateway context */
  readonly trackingEnabledBy?: InputMaybe<TrackingUserMetaInput>;
  readonly trackingSystemID?: InputMaybe<Scalars['ID']>;
  readonly tractorIdentifier?: InputMaybe<Scalars['String']>;
  /** DEPRECATED - replaced by heightInInches inside inboundTrailers array */
  readonly trailerHeight?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED - replaced by identifier inside inboundTrailers array */
  readonly trailerIdentifier?: InputMaybe<Scalars['String']>;
  /** DEPRECATED - replaced by lengthInFeet inside inboundTrailers array */
  readonly trailerLength?: InputMaybe<Scalars['Float']>;
  readonly trailerLocation?: InputMaybe<LocationInput>;
  /** DEPRECATED - replaced by widthInInches inside inboundTrailers array */
  readonly trailerWidth?: InputMaybe<Scalars['Float']>;
  readonly userID?: InputMaybe<Scalars['ID']>;
};

export type DriverAttributeModel = {
  readonly __typename?: 'DriverAttributeModel';
  readonly id: Scalars['ID'];
  readonly driverId: Scalars['ID'];
  readonly optionId: Scalars['String'];
  readonly value: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly effectiveDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use expirationDateTime instead. It includes time offsets. */
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly expirationDateTime?: Maybe<Scalars['DateTime']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly createdBy: UserV2;
  readonly createdById: Scalars['ID'];
  readonly createdByName: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly updatedBy: UserV2;
  readonly updatedById: Scalars['ID'];
  readonly updatedByName: Scalars['String'];
  readonly updatedOn: Scalars['DateTime'];
};

export type DriverAutocompleteFilter = {
  readonly searchTerm?: InputMaybe<Scalars['String']>;
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly queryDate?: InputMaybe<Scalars['Date']>;
  readonly limit?: InputMaybe<Scalars['Int']>;
};

export type DriverAutocompleteOutput = {
  readonly __typename?: 'DriverAutocompleteOutput';
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly displayName?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly middleName?: Maybe<Scalars['String']>;
  readonly nickName?: Maybe<Scalars['String']>;
  /** This field describes the active fleet to which the trailer belongs to. */
  readonly carrier?: Maybe<CarrierV2>;
  /** This field describes the relevance score of the driver autocomplete results. */
  readonly score: Scalars['Float'];
};

export type DriverAvailability = {
  readonly __typename?: 'DriverAvailability';
  readonly driverId: Scalars['ID'];
  readonly driverCode?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Date']>;
  readonly endDate?: Maybe<Scalars['Date']>;
  readonly driverSchedules?: Maybe<ReadonlyArray<AvailableDriverScheduleOutput>>;
  readonly driverTimeOffs?: Maybe<ReadonlyArray<DriverTimeOffOutput>>;
  readonly driverRoutingRequests?: Maybe<ReadonlyArray<DriverRoutingRequestOutput>>;
  readonly operationalStatus?: Maybe<Scalars['String']>;
};

export type DriverAvailabilityInput = {
  readonly driverCode: Scalars['String'];
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly startTime?: InputMaybe<Scalars['String']>;
  readonly endTime?: InputMaybe<Scalars['String']>;
  readonly routingRequestStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routingRequestType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly paidTimeOffType?: InputMaybe<Scalars['String']>;
};

export type DriverAvailabilityPayload = {
  readonly __typename?: 'DriverAvailabilityPayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  readonly driverAvailability?: Maybe<DriverAvailability>;
};

export type DriverAward = {
  readonly __typename?: 'DriverAward';
  readonly id: Scalars['ID'];
  /** @deprecated awardType is deprecated. Use typeTerm instead. */
  readonly awardType: Scalars['String'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly awardDate?: Maybe<Scalars['Date']>;
  readonly eligibilityDate?: Maybe<Scalars['Date']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverAwardInput = {
  readonly awardType?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly awardDate?: InputMaybe<Scalars['DateTime']>;
  readonly dateAwarded?: InputMaybe<Scalars['Date']>;
  readonly eligibilityDate?: InputMaybe<Scalars['Date']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverAwardInputV2 = {
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly awardDate: Scalars['Date'];
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverAwardObjectInput = {
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly awardDate?: InputMaybe<Scalars['Date']>;
  readonly dateAwarded?: InputMaybe<Scalars['Date']>;
  readonly eligibilityDate?: InputMaybe<Scalars['Date']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverAwardOutput = {
  readonly __typename?: 'DriverAwardOutput';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  /** @deprecated Use dateAwarded instead */
  readonly awardDate: Scalars['Date'];
  readonly dateAwarded?: Maybe<Scalars['Date']>;
  readonly eligibilityDate?: Maybe<Scalars['Date']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type DriverAwardOutputEdge = {
  readonly __typename?: 'DriverAwardOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverAwardOutput;
};

export type DriverAwardPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly awardDate?: InputMaybe<Scalars['Date']>;
  readonly dateAwarded?: InputMaybe<Scalars['Date']>;
  readonly eligibilityDate?: InputMaybe<Scalars['Date']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type DriverAwardPayload = {
  readonly __typename?: 'DriverAwardPayload';
  readonly edges: ReadonlyArray<DriverAwardOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverAwardV2 = {
  readonly __typename?: 'DriverAwardV2';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly awardDate: Scalars['Date'];
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverBounce = {
  readonly __typename?: 'DriverBounce';
  readonly id: Scalars['ID'];
  readonly routeID: Scalars['ID'];
  readonly routeResourcesID: Scalars['ID'];
  readonly notes?: Maybe<Scalars['String']>;
  /** Refers to Driver Bounce Reason DDT option ID */
  readonly reason: Scalars['String'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly bouncedDrivers: ReadonlyArray<DriverBounceDriverRecord>;
  readonly remainingDrivers: ReadonlyArray<DriverBounceDriverRecord>;
};

export type DriverBounceDriverRecord = {
  readonly __typename?: 'DriverBounceDriverRecord';
  readonly assetDriver: AssetDriver;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly sequenceNumber?: Maybe<Scalars['Int']>;
};

export type DriverBouncesInput = {
  readonly routeID: Scalars['ID'];
};

export type DriverCareerGoalsPreferenceOutput = {
  readonly __typename?: 'DriverCareerGoalsPreferenceOutput';
  readonly id: Scalars['ID'];
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  /** @deprecated preferenceTerm is deprecated */
  readonly preferenceTerm?: Maybe<Scalars['String']>;
  readonly positionTerm: Scalars['String'];
  readonly fleet?: Maybe<Scalars['String']>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  /** @deprecated reasonTerm is deprecated */
  readonly reasonTerm?: Maybe<Scalars['String']>;
  readonly withinTheTimeframeTerm?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type DriverCareerGoalsPreferenceOutputEdge = {
  readonly __typename?: 'DriverCareerGoalsPreferenceOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverCareerGoalsPreferenceOutput;
};

export type DriverCareerGoalsPreferences = {
  readonly __typename?: 'DriverCareerGoalsPreferences';
  readonly id: Scalars['ID'];
  readonly position?: Maybe<Scalars['String']>;
  readonly fleet?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly businessUnit?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  /** @deprecated preference is deprecated */
  readonly preference: Scalars['String'];
  /** @deprecated reason is deprecated */
  readonly reason?: Maybe<Scalars['String']>;
  readonly withinTheTimeframeTerm?: Maybe<Scalars['String']>;
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverCareerGoalsPreferencesInput = {
  readonly position?: InputMaybe<Scalars['String']>;
  readonly fleet?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly customer?: InputMaybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly reason?: InputMaybe<Scalars['String']>;
  readonly withinTheTimeframeTerm?: InputMaybe<Scalars['String']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverCareerGoalsPreferencesPayload = {
  readonly __typename?: 'DriverCareerGoalsPreferencesPayload';
  readonly edges: ReadonlyArray<DriverCareerGoalsPreferenceOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverCertificationOrPermitInputV3 = {
  readonly typeTerm: Scalars['String'];
  readonly certificationOrPermitId: Scalars['String'];
  readonly expirationDate: Scalars['Date'];
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverCertificationOrPermitObjectInput = {
  readonly typeTerm: Scalars['String'];
  readonly certificationOrPermitId?: InputMaybe<Scalars['String']>;
  readonly privateCertificationOrPermitId?: InputMaybe<Scalars['String']>;
  readonly expirationDate: Scalars['Date'];
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverCertificationOrPermitOutput = {
  readonly __typename?: 'DriverCertificationOrPermitOutput';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  /** @deprecated certificationOrPermitId is deprecated */
  readonly certificationOrPermitId: Scalars['String'];
  readonly privateCertificationOrPermitId?: Maybe<Scalars['String']>;
  readonly expirationDate: Scalars['Date'];
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type DriverCertificationOrPermitOutputEdge = {
  readonly __typename?: 'DriverCertificationOrPermitOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverCertificationOrPermitOutput;
};

export type DriverCertificationOrPermitPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly certificationOrPermitId?: InputMaybe<Scalars['String']>;
  readonly privateCertificationOrPermitId?: InputMaybe<Scalars['String']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type DriverCertificationOrPermitPayload = {
  readonly __typename?: 'DriverCertificationOrPermitPayload';
  readonly edges: ReadonlyArray<DriverCertificationOrPermitOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverCertificationOrPermitV3 = {
  readonly __typename?: 'DriverCertificationOrPermitV3';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly certificationOrPermitId: Scalars['String'];
  readonly expirationDate: Scalars['Date'];
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverCertificationsOrPermits = {
  readonly __typename?: 'DriverCertificationsOrPermits';
  readonly id: Scalars['ID'];
  /** @deprecated certificationOrPermitType is deprecated. Use typeTerm instead. */
  readonly certificationOrPermitType: Scalars['String'];
  /** @deprecated certificationOrPermitId is deprecated. Use value instead. */
  readonly certificationOrPermitId?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  /** @deprecated countryCd is deprecated. Use country instead. */
  readonly countryCd?: Maybe<Scalars['String']>;
  /** @deprecated expirationDt is deprecated. Use expirationDate instead. */
  readonly expirationDt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverCertificationsOrPermitsInput = {
  readonly certificationOrPermitType: Scalars['String'];
  readonly certificationOrPermitId: Scalars['String'];
  readonly state?: InputMaybe<Scalars['String']>;
  readonly countryCd?: InputMaybe<Scalars['String']>;
  readonly expirationDt: Scalars['DateTime'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverCertificationsOrPermitsInputV2 = {
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverCertificationsOrPermitsV2 = {
  readonly __typename?: 'DriverCertificationsOrPermitsV2';
  readonly id: Scalars['ID'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverCommodityInput = {
  readonly id: Scalars['ID'];
  readonly actualClass: Scalars['String'];
  readonly actualNMFCCode: Scalars['String'];
  readonly actualPieces: Scalars['Int'];
  readonly actualDensity: DimensionInput;
  readonly actualLinear: DimensionInput;
  readonly actualVolume: DimensionInput;
  readonly actualWeight: DimensionInput;
  readonly actualDimensions?: InputMaybe<CommodityDimensionInput>;
};

export type DriverCommodityInputV2 = {
  readonly id: Scalars['ID'];
  readonly actualClass?: InputMaybe<Scalars['String']>;
  readonly actualNMFCCode?: InputMaybe<Scalars['String']>;
  readonly actualPieces?: InputMaybe<Scalars['Int']>;
  readonly actualDensity?: InputMaybe<DimensionInput>;
  readonly actualLinear?: InputMaybe<DimensionInput>;
  readonly actualVolume?: InputMaybe<DimensionInput>;
  readonly actualWeight?: InputMaybe<DimensionInput>;
  readonly actualDimensions?: InputMaybe<CommodityDimensionInputV2>;
};

export type DriverCommodityPreferenceOutput = {
  readonly __typename?: 'DriverCommodityPreferenceOutput';
  readonly id: Scalars['ID'];
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly preferenceTerm: Scalars['String'];
  readonly commodityTerm: Scalars['String'];
  readonly reasonTerm?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type DriverCommodityPreferenceOutputEdge = {
  readonly __typename?: 'DriverCommodityPreferenceOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverCommodityPreferenceOutput;
};

export type DriverCommodityPreferences = {
  readonly __typename?: 'DriverCommodityPreferences';
  readonly id: Scalars['ID'];
  readonly commodity?: Maybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverCommodityPreferencesInput = {
  readonly commodity?: InputMaybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverCommodityPreferencesPayload = {
  readonly __typename?: 'DriverCommodityPreferencesPayload';
  readonly edges: ReadonlyArray<DriverCommodityPreferenceOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverContact = {
  readonly __typename?: 'DriverContact';
  readonly id: Scalars['ID'];
  readonly contactTypeId: Scalars['String'];
  readonly name: Scalars['String'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly extension?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly chatTypeId?: Maybe<Scalars['String']>;
  readonly chatUsername?: Maybe<Scalars['String']>;
  readonly main?: Maybe<Scalars['Boolean']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverContactInput = {
  readonly contactTypeId: Scalars['String'];
  readonly name: Scalars['String'];
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly chatTypeId?: InputMaybe<Scalars['String']>;
  readonly chatUsername?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverContactInputV2 = {
  readonly typeTerm: Scalars['String'];
  readonly name: Scalars['String'];
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly instantMessenger?: InputMaybe<InstantMessengerInput>;
  readonly isMain?: InputMaybe<Scalars['Boolean']>;
  readonly isPayContactAllowedParty?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverContactObjectInput = {
  readonly typeTerm: Scalars['String'];
  readonly name: Scalars['String'];
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly instantMessenger?: InputMaybe<InstantMessengerInput>;
  readonly isMain?: InputMaybe<Scalars['Boolean']>;
  readonly isPayContactAllowedParty?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverContactOutput = {
  readonly __typename?: 'DriverContactOutput';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly name: Scalars['String'];
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly extension?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly instantMessenger?: Maybe<InstantMessengerOutput>;
  readonly isMain?: Maybe<Scalars['Boolean']>;
  readonly isPayContactAllowedParty?: Maybe<Scalars['Boolean']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type DriverContactOutputEdge = {
  readonly __typename?: 'DriverContactOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverContactOutput;
};

export type DriverContactPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly instantMessenger?: InputMaybe<InstantMessengerInput>;
  readonly isMain?: InputMaybe<Scalars['Boolean']>;
  readonly isPayContactAllowedParty?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type DriverContactPayload = {
  readonly __typename?: 'DriverContactPayload';
  readonly edges: ReadonlyArray<DriverContactOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverContactV2 = {
  readonly __typename?: 'DriverContactV2';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly name: Scalars['String'];
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly extension?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly instantMessenger?: Maybe<InstantMessengerOutput>;
  readonly isMain?: Maybe<Scalars['Boolean']>;
  readonly isPayContactAllowedParty?: Maybe<Scalars['Boolean']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverConversation = {
  readonly __typename?: 'DriverConversation';
  readonly id: Scalars['String'];
  readonly type: DriverConversationType;
  readonly drivers: ReadonlyArray<DriverConversationDriver>;
  readonly subject?: Maybe<Scalars['String']>;
  readonly messages?: Maybe<ReadonlyArray<DriverMessage>>;
  readonly flag?: Maybe<Scalars['Boolean']>;
  readonly sentAtDateTime: Scalars['String'];
  readonly followUpTime?: Maybe<FollowUpTime>;
  readonly isReadOnly?: Maybe<Scalars['Boolean']>;
  readonly hasUnreadMessages?: Maybe<Scalars['Boolean']>;
};

export enum DriverConversationCreatedByRole {
  Driver = 'Driver',
  Employee = 'Employee'
}

export type DriverConversationDriver = {
  readonly __typename?: 'DriverConversationDriver';
  readonly id: Scalars['String'];
  readonly code?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
};

export type DriverConversationInput = {
  readonly classification: Scalars['String'];
  readonly value?: InputMaybe<Scalars['String']>;
};

export type DriverConversationObject = {
  readonly __typename?: 'DriverConversationObject';
  readonly id: Scalars['String'];
  readonly type: DriverConversationType;
  readonly drivers: ReadonlyArray<DriverConversationDriver>;
  readonly subject?: Maybe<Scalars['String']>;
  readonly flag?: Maybe<Scalars['Boolean']>;
  readonly sentAtDateTime: Scalars['String'];
  readonly updatedAtDateTime?: Maybe<Scalars['String']>;
  /** @deprecated Use FollowUpTimeV2 instead */
  readonly followUpTime?: Maybe<FollowUpTime>;
  readonly isReadOnly?: Maybe<Scalars['Boolean']>;
  readonly hasUnreadMessages?: Maybe<Scalars['Boolean']>;
};

export type DriverConversationObjectV2 = {
  readonly __typename?: 'DriverConversationObjectV2';
  readonly conversation: DriverConversationType;
  readonly drivers: ReadonlyArray<DriverConversationDriver>;
  readonly subject?: Maybe<Scalars['String']>;
  readonly updatedAtDateTime: Scalars['String'];
  readonly hasUnreadMessages: Scalars['Boolean'];
  readonly followUpTime?: Maybe<Scalars['String']>;
  readonly isReadOnly: Scalars['Boolean'];
  readonly unreadMessagesCount: Scalars['Int'];
  readonly hasExceededFollowUpTime?: Maybe<Scalars['Boolean']>;
};

export enum DriverConversationParticipantRole {
  Driver = 'DRIVER',
  MmUser = 'MM_USER'
}

export type DriverConversationReadByInput = {
  readonly id: Scalars['String'];
};

export enum DriverConversationReadByRole {
  Driver = 'Driver',
  Employee = 'Employee'
}

export type DriverConversationsInput = {
  readonly filter?: InputMaybe<DriverConversationsInputFilter>;
};

export type DriverConversationsInputFilter = {
  readonly conversationId?: InputMaybe<Scalars['String']>;
  readonly driverIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly userId: Scalars['String'];
};

export type DriverConversationsInputV2Filter = {
  readonly driverIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly userId: Scalars['String'];
  readonly queryOptions?: InputMaybe<QueryOptions>;
};

export type DriverConversationsResponse = {
  readonly __typename?: 'DriverConversationsResponse';
  /** @deprecated Use DriverConversationObjectV2 instead */
  readonly conversations: ReadonlyArray<Maybe<DriverConversationObject>>;
};

export type DriverConversationsV2Connection = {
  readonly __typename?: 'DriverConversationsV2Connection';
  readonly edges?: Maybe<ReadonlyArray<DriverConversationV2Edge>>;
  readonly pageInfo: PageInfo;
};

export type DriverConversationsV2Input = {
  readonly filter: DriverConversationsInputV2Filter;
};

export type DriverConversationType = {
  readonly __typename?: 'DriverConversationType';
  readonly classification: Scalars['String'];
  readonly value?: Maybe<Scalars['String']>;
};

export type DriverConversationV2Edge = {
  readonly __typename?: 'DriverConversationV2Edge';
  readonly cursor: Scalars['String'];
  readonly node: DriverConversationObjectV2;
};

export type DriverCurrentNextRoutes = {
  readonly __typename?: 'DriverCurrentNextRoutes';
  readonly driverAssetId: Scalars['ID'];
  readonly currentRoute?: Maybe<LoadRouteV2>;
  readonly nextRoutes?: Maybe<ReadonlyArray<LoadRouteV2>>;
};

export type DriverCurrentNextRoutesQueryByIdsInput = {
  readonly driverAssetIds: ReadonlyArray<Scalars['ID']>;
};

export type DriverCustomerPreferenceOutput = {
  readonly __typename?: 'DriverCustomerPreferenceOutput';
  readonly id: Scalars['ID'];
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly name: Scalars['String'];
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly preferenceTerm: Scalars['String'];
  readonly reasonTerm?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type DriverCustomerPreferenceOutputEdge = {
  readonly __typename?: 'DriverCustomerPreferenceOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverCustomerPreferenceOutput;
};

export type DriverCustomerPreferences = {
  readonly __typename?: 'DriverCustomerPreferences';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly preferenceTerm?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
};

export type DriverCustomerPreferencesInput = {
  readonly name: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<Scalars['String']>;
  readonly preferenceTerm?: InputMaybe<Scalars['String']>;
};

export type DriverCustomerPreferencesPayload = {
  readonly __typename?: 'DriverCustomerPreferencesPayload';
  readonly edges: ReadonlyArray<DriverCustomerPreferenceOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverDailyActivity = {
  readonly __typename?: 'DriverDailyActivity';
  readonly id: Scalars['ID'];
  /** This field describes the day of the week on which the driver will work */
  readonly weekDay: Scalars['String'];
  /** This field describes the number of hours a driver will work. This can be a decimal value. */
  readonly hours: Scalars['Float'];
  /** This field describes the miles driver covered with the empty truck. This can be a decimal value. */
  readonly emptyMiles?: Maybe<Scalars['Float']>;
  /** This field describes the miles driver covered with the loaded truck. This can be a decimal value. */
  readonly loadedMiles?: Maybe<Scalars['Float']>;
  /** This field describes the total miles (MT + LD) driver covered. This can be a decimal value. */
  readonly totalMiles?: Maybe<Scalars['Float']>;
  /** This field describes the actual revenue earned by the driver. This can be a decimal value. */
  readonly revenue?: Maybe<Scalars['Float']>;
  readonly weeklyTargetId?: Maybe<Scalars['ID']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverEquipment = {
  readonly __typename?: 'DriverEquipment';
  readonly id: Scalars['ID'];
  /** @deprecated equipmentCd is deprecated. Use typeTerm instead. */
  readonly equipmentCd: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count?: Maybe<Scalars['Float']>;
  readonly assetId?: Maybe<Scalars['String']>;
  /** @deprecated issueDt is deprecated. Use issueDate instead. */
  readonly issueDt?: Maybe<Scalars['DateTime']>;
  /** @deprecated returnDt is deprecated. Use returnDate instead. */
  readonly returnDt?: Maybe<Scalars['DateTime']>;
  /** @deprecated condition is deprecated. Use conditionTerm instead. */
  readonly condition?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverEquipmentInput = {
  readonly equipmentCd: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Float'];
  readonly assetId?: InputMaybe<Scalars['String']>;
  readonly issueDt: Scalars['DateTime'];
  readonly returnDt?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly condition?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverEquipmentInputV2 = {
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly count?: InputMaybe<Scalars['Float']>;
  readonly assetId?: InputMaybe<Scalars['String']>;
  readonly issueDate?: InputMaybe<Scalars['DateTime']>;
  readonly returnDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly conditionTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverEquipmentInputV3 = {
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId: Scalars['String'];
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly recoveredDate?: InputMaybe<Scalars['Date']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverEquipmentObjectInput = {
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId: Scalars['String'];
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly recoveredDate?: InputMaybe<Scalars['Date']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverEquipmentOutput = {
  readonly __typename?: 'DriverEquipmentOutput';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId: Scalars['String'];
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly recoveredDate?: Maybe<Scalars['Date']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type DriverEquipmentOutputEdge = {
  readonly __typename?: 'DriverEquipmentOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverEquipmentOutput;
};

export type DriverEquipmentPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly count?: InputMaybe<Scalars['Int']>;
  readonly assetId?: InputMaybe<Scalars['String']>;
  readonly conditionTerm?: InputMaybe<Scalars['String']>;
  readonly issueDate?: InputMaybe<Scalars['Date']>;
  readonly recoveredDate?: InputMaybe<Scalars['Date']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type DriverEquipmentPayload = {
  readonly __typename?: 'DriverEquipmentPayload';
  readonly edges: ReadonlyArray<DriverEquipmentOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverEquipmentV2 = {
  readonly __typename?: 'DriverEquipmentV2';
  readonly id: Scalars['ID'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly count?: Maybe<Scalars['Float']>;
  readonly assetId?: Maybe<Scalars['String']>;
  readonly issueDate?: Maybe<Scalars['DateTime']>;
  readonly returnDate?: Maybe<Scalars['DateTime']>;
  readonly conditionTerm?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverEquipmentV3 = {
  readonly __typename?: 'DriverEquipmentV3';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId: Scalars['String'];
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly recoveredDate?: Maybe<Scalars['Date']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverFacilityPreferenceOutput = {
  readonly __typename?: 'DriverFacilityPreferenceOutput';
  readonly id: Scalars['ID'];
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly name: Scalars['String'];
  readonly type?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly preferenceTerm: Scalars['String'];
  readonly reasonTerm?: Maybe<Scalars['String']>;
  readonly facilityId?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type DriverFacilityPreferenceOutputEdge = {
  readonly __typename?: 'DriverFacilityPreferenceOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverFacilityPreferenceOutput;
};

export type DriverFacilityPreferences = {
  readonly __typename?: 'DriverFacilityPreferences';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly facilityId?: Maybe<Scalars['String']>;
};

export type DriverFacilityPreferencesInput = {
  readonly name: Scalars['String'];
  readonly type?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverFacilityPreferencesPayload = {
  readonly __typename?: 'DriverFacilityPreferencesPayload';
  readonly edges: ReadonlyArray<DriverFacilityPreferenceOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverFleet = {
  readonly __typename?: 'DriverFleet';
  readonly id: Scalars['ID'];
  /**
   * This field describes the type of relation between driver and carrier
   * @deprecated type is deprecated. Use typeTerm instead.
   */
  readonly type: Scalars['String'];
  /** This field describes the type of relation between driver and carrier */
  readonly typeTerm: Scalars['String'];
  /**
   * This field describes the effective date of the driver's rep assignment. Format
   * is from RFC 3339, section 5.6 which is YYYY-MM-DD.
   */
  readonly effectiveDate: Scalars['Date'];
  /**
   * This field describes the expiration date of driver’s rep assignment. Format is
   * from RFC 3339, section 5.6 which is YYYY-MM-DD.
   */
  readonly expirationDate?: Maybe<Scalars['Date']>;
  /** This field describes the carrier by which relationship is established. */
  readonly carrier: CarrierV2;
  readonly driverId: Scalars['ID'];
  readonly isInheritPayProgram?: Maybe<Scalars['Boolean']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type DriverFleetConnection = {
  readonly __typename?: 'DriverFleetConnection';
  readonly edges: ReadonlyArray<DriverFleetEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverFleetEdge = {
  readonly __typename?: 'DriverFleetEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverFleet;
};

export type DriverFleetFilter = {
  readonly driverId: Scalars['String'];
  readonly isExpired?: InputMaybe<Scalars['Boolean']>;
};

export type DriverFleetPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly effectiveDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly isInheritPayProgram?: InputMaybe<Scalars['Boolean']>;
};

export type DriverFleetRelationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly carrierId: Scalars['ID'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly isInheritPayProgram?: InputMaybe<Scalars['Boolean']>;
};

export type DriverFleetRelationshipInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  /** This field describes the fleet to which the driver belongs to. */
  readonly carrierId: Scalars['String'];
  readonly driverId: Scalars['ID'];
  /** This field describes the type of assignment the driver undertakes within the organization. Ex: Primary, Secondary */
  readonly typeTerm: Scalars['String'];
  /**
   * This field describes the effective date of the driver's fleet assignment.
   * Format is from RFC 3339, section 5.6 which is YYYY-MM-DD.
   */
  readonly effectiveDate: Scalars['DateTime'];
  /**
   * This field describes the expiration date of driver’s fleet assignment. Format
   * is from RFC 3339, section 5.6 which is YYYY-MM-DD.
   */
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly isInheritPayProgram?: InputMaybe<Scalars['Boolean']>;
};

export type DriverFlipInput = {
  /** An array of unique identifiers associated with the route or load recalculation is being requested for. All ids must be of the same type. */
  readonly entityIds: ReadonlyArray<Scalars['ID']>;
  /** The type of identifier being passed in : 'routeId' | 'loadId' cannot be a combination of types. */
  readonly identifierType: Scalars['String'];
};

export type DriverGeographyPreferenceInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly city?: InputMaybe<Scalars['String']>;
  /** DEPRECATED: states is deprecated. Use geographyPreferenceStates instead. */
  readonly states?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** DEPRECATED: region is deprecated. Use geographyPreferenceRegion instead. */
  readonly region?: InputMaybe<RegionInput>;
  readonly geographyPreferenceStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly geographyPreferenceRegion?: InputMaybe<RegionInput>;
  readonly directionTerm?: InputMaybe<Scalars['String']>;
  readonly preferenceTerm: Scalars['String'];
  readonly reasonTerm?: InputMaybe<Scalars['String']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly driverId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type DriverGeographyPreferenceOutput = {
  readonly __typename?: 'DriverGeographyPreferenceOutput';
  readonly id: Scalars['ID'];
  readonly city?: Maybe<Scalars['String']>;
  readonly state: Scalars['String'];
  readonly region?: Maybe<Scalars['String']>;
  readonly directionTerm?: Maybe<Scalars['String']>;
  readonly preferenceTerm: Scalars['String'];
  readonly reasonTerm?: Maybe<Scalars['String']>;
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type DriverGeographyPreferenceOutputEdge = {
  readonly __typename?: 'DriverGeographyPreferenceOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverGeographyPreferenceOutput;
};

export type DriverGeographyPreferencePayload = {
  readonly __typename?: 'DriverGeographyPreferencePayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  /** A collection of driver geography preferences associated with the driver. */
  readonly driverGeographyPreferenceOutput?: Maybe<AssetDriverGeographyPreferencesOutput>;
};

export type DriverGeographyPreferences = {
  readonly __typename?: 'DriverGeographyPreferences';
  readonly id: Scalars['ID'];
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly direction?: Maybe<Scalars['String']>;
  readonly reason?: Maybe<Scalars['String']>;
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverGeographyPreferencesInput = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly direction?: InputMaybe<Scalars['String']>;
  readonly reason?: InputMaybe<Scalars['String']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverGeographyPreferencesPayload = {
  readonly __typename?: 'DriverGeographyPreferencesPayload';
  readonly edges: ReadonlyArray<DriverGeographyPreferenceOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverHos = {
  readonly __typename?: 'DriverHos';
  readonly id: Scalars['ID'];
  readonly driverCode: Scalars['String'];
  readonly hosRuleTerm?: Maybe<Scalars['String']>;
  readonly dutyStatusTerm: Scalars['String'];
  readonly sourceSystem?: Maybe<Scalars['String']>;
  readonly eventCode: Scalars['String'];
  readonly eventDateTime: Scalars['DateTime'];
  readonly powerUnitCode?: Maybe<Scalars['String']>;
  readonly remark?: Maybe<Scalars['String']>;
  readonly driveClock: DriverHosClock;
  readonly shiftClock: DriverHosClock;
  readonly cycleClock: DriverHosClock;
  readonly breakClock: DriverHosClock;
  readonly eventSentDateTime?: Maybe<Scalars['DateTime']>;
  readonly eventReceivedDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated hosDefaultTimeZoneTerm is deprecated. Use hosDefaultTimeZone instead. */
  readonly hosDefaultTimeZoneTerm?: Maybe<Scalars['String']>;
  readonly hosDefaultTimeZone?: Maybe<Scalars['String']>;
  readonly dutyStatusPerDay?: Maybe<ReadonlyArray<DriverHosDailyStatus>>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverHosAuditFilter = {
  readonly driverId: Scalars['ID'];
  readonly startDate?: InputMaybe<Scalars['Date']>;
  readonly endDate?: InputMaybe<Scalars['Date']>;
  readonly hosDefaultTimeZone?: InputMaybe<Scalars['String']>;
};

export type DriverHosAuditHistoryPayload = {
  readonly __typename?: 'DriverHosAuditHistoryPayload';
  readonly edges: ReadonlyArray<DriverHosEdge>;
  readonly pageInfo: PageInfo;
};

export type DriverHosClock = {
  readonly __typename?: 'DriverHosClock';
  readonly timeRemaining: Scalars['Int'];
  readonly nextResetDateTime?: Maybe<Scalars['DateTime']>;
};

export type DriverHosClockInput = {
  readonly timeRemaining: Scalars['Int'];
  readonly nextResetDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly lastResetDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type DriverHosCorrection = {
  readonly __typename?: 'DriverHosCorrection';
  readonly id: Scalars['ID'];
  readonly driverCode: Scalars['String'];
  readonly hosRuleTerm?: Maybe<Scalars['String']>;
  readonly dutyStatusTerm?: Maybe<Scalars['String']>;
  readonly sourceSystem?: Maybe<Scalars['String']>;
  readonly eventType?: Maybe<Scalars['String']>;
  readonly eventCode: Scalars['String'];
  readonly eventDateTime: Scalars['DateTime'];
  readonly externalId?: Maybe<Scalars['String']>;
  readonly originalExternalId?: Maybe<Scalars['String']>;
  readonly remark?: Maybe<Scalars['String']>;
  readonly driveClock?: Maybe<DriverHosClock>;
  readonly shiftClock?: Maybe<DriverHosClock>;
  readonly cycleClock?: Maybe<DriverHosClock>;
  readonly breakClock?: Maybe<DriverHosClock>;
  readonly eventSentDateTime?: Maybe<Scalars['DateTime']>;
  readonly eventReceivedDateTime?: Maybe<Scalars['DateTime']>;
  readonly hosDefaultTimeZone?: Maybe<Scalars['String']>;
  readonly dutyStatusPerDay?: Maybe<ReadonlyArray<DriverHosDailyStatus>>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverHosCorrectionInput = {
  readonly driverCode: Scalars['String'];
  readonly hosRuleTerm?: InputMaybe<Scalars['String']>;
  readonly dutyStatusTerm?: InputMaybe<Scalars['String']>;
  readonly sourceSystem?: InputMaybe<Scalars['String']>;
  readonly sourceIntegration?: InputMaybe<Scalars['String']>;
  readonly eventType?: InputMaybe<Scalars['String']>;
  readonly eventCode: Scalars['String'];
  readonly eventDateTime: Scalars['DateTime'];
  readonly externalId?: InputMaybe<Scalars['String']>;
  readonly originalExternalId?: InputMaybe<Scalars['String']>;
  readonly remark?: InputMaybe<Scalars['String']>;
  readonly driveClock?: InputMaybe<DriverHosClockInput>;
  readonly shiftClock?: InputMaybe<DriverHosClockInput>;
  readonly cycleClock?: InputMaybe<DriverHosClockInput>;
  readonly breakClock?: InputMaybe<DriverHosClockInput>;
  readonly eventSentDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly eventReceivedDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly dutyStatusPerDay?: InputMaybe<ReadonlyArray<DriverHosDutyStatusInput>>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverHosCorrectionPayload = {
  readonly __typename?: 'DriverHosCorrectionPayload';
  readonly errors?: Maybe<ReadonlyArray<UserError>>;
  readonly hosEvent?: Maybe<DriverHosCorrection>;
};

export type DriverHosDailyStatus = {
  readonly __typename?: 'DriverHOSDailyStatus';
  readonly date: Scalars['Date'];
  readonly totalDriveTime: Scalars['Int'];
  readonly totalOnDutyTime: Scalars['Int'];
  readonly totalSleeperTime: Scalars['Int'];
  readonly totalOffDutyTime: Scalars['Int'];
};

export type DriverHosDailySummary = {
  readonly __typename?: 'DriverHosDailySummary';
  readonly driverId: Scalars['ID'];
  readonly dailySummary?: Maybe<ReadonlyArray<DriverHosDailyStatus>>;
};

export type DriverHosDutyStatusInput = {
  readonly date: Scalars['Date'];
  readonly totalDriveTime: Scalars['Int'];
  readonly totalOnDutyTime: Scalars['Int'];
  readonly totalSleeperTime: Scalars['Int'];
  readonly totalOffDutyTime: Scalars['Int'];
};

export type DriverHosEdge = {
  readonly __typename?: 'DriverHosEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverHos;
};

export type DriverHosEventSummary = {
  readonly __typename?: 'DriverHosEventSummary';
  readonly term: Scalars['String'];
  readonly hosRuleTerm?: Maybe<Scalars['String']>;
  readonly dutyStatusTerm: Scalars['String'];
  readonly eventCode: Scalars['String'];
  readonly eventDateTime: Scalars['DateTime'];
  readonly sourceSystem?: Maybe<Scalars['String']>;
  readonly driveClock: DriverHosClock;
  readonly shiftClock: DriverHosClock;
  readonly cycleClock: DriverHosClock;
  readonly breakClock: DriverHosClock;
  readonly eventSentDateTime?: Maybe<Scalars['DateTime']>;
  readonly eventReceivedDateTime?: Maybe<Scalars['DateTime']>;
  readonly nextResetDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastResetDateTime?: Maybe<Scalars['DateTime']>;
};

export type DriverHosInput = {
  readonly driverCode: Scalars['String'];
  readonly hosRuleTerm?: InputMaybe<Scalars['String']>;
  readonly dutyStatusTerm: Scalars['String'];
  readonly sourceSystem?: InputMaybe<Scalars['String']>;
  readonly sourceIntegration?: InputMaybe<Scalars['String']>;
  readonly eventCode: Scalars['String'];
  readonly eventDateTime: Scalars['DateTime'];
  readonly powerUnitCode?: InputMaybe<Scalars['String']>;
  readonly guid?: InputMaybe<Scalars['String']>;
  readonly remark?: InputMaybe<Scalars['String']>;
  readonly driveClock: DriverHosClockInput;
  readonly shiftClock: DriverHosClockInput;
  readonly cycleClock: DriverHosClockInput;
  readonly breakClock: DriverHosClockInput;
  readonly eventSentDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly eventReceivedDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly dutyStatusPerDay?: InputMaybe<ReadonlyArray<DriverHosDutyStatusInput>>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverHosLastEventSummary = {
  readonly __typename?: 'DriverHosLastEventSummary';
  readonly hosRuleTerm?: Maybe<Scalars['String']>;
  readonly driveTime?: Maybe<DriverHosTime>;
  readonly shiftTime?: Maybe<DriverHosTime>;
  readonly cycleTime?: Maybe<DriverHosTime>;
  readonly breakTime?: Maybe<DriverHosTime>;
  readonly previousEvent?: Maybe<DriverHosEventSummary>;
  readonly currentEvent?: Maybe<DriverHosEventSummary>;
};

export type DriverHosRecapHistory = {
  readonly __typename?: 'DriverHosRecapHistory';
  readonly id: Scalars['ID'];
  readonly driverId: Scalars['ID'];
  /** @deprecated hosDefaultTimeZoneTerm is deprecated. Use hosDefaultTimeZone instead. */
  readonly hosDefaultTimeZoneTerm?: Maybe<Scalars['String']>;
  readonly hosDefaultTimeZone?: Maybe<Scalars['String']>;
  readonly recapDate: Scalars['Date'];
  readonly totalDriveTime?: Maybe<Scalars['Int']>;
  readonly totalOnDutyTime?: Maybe<Scalars['Int']>;
  readonly totalOffDutyTime?: Maybe<Scalars['Int']>;
  readonly totalBreakTime?: Maybe<Scalars['Int']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverHosRecapHistoryEdge = {
  readonly __typename?: 'DriverHosRecapHistoryEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverHosRecapHistory;
};

export type DriverHosRecapHistoryFilter = {
  readonly driverId: Scalars['ID'];
  readonly startDate?: InputMaybe<Scalars['Date']>;
  readonly endDate?: InputMaybe<Scalars['Date']>;
  readonly hosDefaultTimeZoneTerm?: InputMaybe<Scalars['String']>;
  readonly hosDefaultTimeZone?: InputMaybe<Scalars['String']>;
};

export type DriverHosRecapHistoryPayload = {
  readonly __typename?: 'DriverHosRecapHistoryPayload';
  readonly edges: ReadonlyArray<DriverHosRecapHistoryEdge>;
  readonly pageInfo: PageInfo;
};

export type DriverHosSummary = {
  readonly __typename?: 'DriverHosSummary';
  readonly hosRuleTerm?: Maybe<Scalars['String']>;
  readonly driveTime: DriverHosTime;
  readonly shiftTime: DriverHosTime;
  readonly cycleTime: DriverHosTime;
  readonly breakTime: DriverHosTime;
  readonly previousEvent?: Maybe<DriverHosEventSummary>;
  readonly currentEvent: DriverHosEventSummary;
};

export type DriverHosTime = {
  readonly __typename?: 'DriverHosTime';
  readonly remaining: Scalars['Int'];
  readonly utilized: Scalars['Int'];
};

export type DriverIdentifierInputV2 = {
  readonly typeTerm: Scalars['String'];
  readonly value: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverIdentifierObjectInput = {
  readonly typeTerm: Scalars['String'];
  readonly value: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverIdentifierOutput = {
  readonly __typename?: 'DriverIdentifierOutput';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly value: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type DriverIdentifierOutputEdge = {
  readonly __typename?: 'DriverIdentifierOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverIdentifierOutput;
};

export type DriverIdentifierPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type DriverIdentifierPayload = {
  readonly __typename?: 'DriverIdentifierPayload';
  readonly edges: ReadonlyArray<DriverIdentifierOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverIdentifiers = {
  readonly __typename?: 'DriverIdentifiers';
  readonly id: Scalars['ID'];
  /** @deprecated identifierType is deprecated. Use typeTerm instead. */
  readonly identifierType: Scalars['String'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  /** @deprecated identifierId is deprecated. Use value instead. */
  readonly identifierId: Scalars['String'];
  readonly value?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverIdentifiersInput = {
  readonly identifierType?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly identifierId?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverIdentifierV2 = {
  readonly __typename?: 'DriverIdentifierV2';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly value: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

/** type for carrier driver input */
export type DriverInput = {
  readonly displayName?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
};

export type DriverKeyValue = {
  readonly __typename?: 'DriverKeyValue';
  readonly key: Scalars['String'];
  readonly value: Scalars['String'];
};

export type DriverKeyValuePairOfString = {
  readonly __typename?: 'DriverKeyValuePairOfString';
  readonly key?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
};

export type DriverList = {
  readonly __typename?: 'DriverList';
  readonly driverId?: Maybe<Scalars['String']>;
  readonly driverCode?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverNickname?: Maybe<Scalars['String']>;
  readonly driverNextAvailableLocationCity?: Maybe<Scalars['String']>;
  readonly driverNextAvailableLocationState?: Maybe<Scalars['String']>;
  readonly driverNextAvailableDatetime?: Maybe<Scalars['DateTime']>;
  readonly driverPhone?: Maybe<Scalars['String']>;
  readonly driverType?: Maybe<Scalars['String']>;
  readonly driverDivision?: Maybe<Scalars['String']>;
  readonly driverActiveFleetId?: Maybe<Scalars['String']>;
  readonly driverActiveFleetName?: Maybe<Scalars['String']>;
  readonly driverDutyStatus?: Maybe<Scalars['String']>;
  readonly driverOperatingStatus?: Maybe<Scalars['String']>;
  readonly driverPartnerId?: Maybe<Scalars['String']>;
  readonly driverPartnerName?: Maybe<Scalars['String']>;
  readonly driverNextTimeOffRequestStartDate?: Maybe<Scalars['DateTime']>;
  readonly driverNextTimeOffRequestEndDate?: Maybe<Scalars['DateTime']>;
  readonly driverClockTimeRemaining?: Maybe<Scalars['Float']>;
  readonly driverShiftClockTimeRemaining?: Maybe<Scalars['Float']>;
  readonly driverCycleClockTimeRemaining?: Maybe<Scalars['Float']>;
  readonly driverBreakClockTimeRemaining?: Maybe<Scalars['Float']>;
  readonly driverEventDateTime?: Maybe<Scalars['Float']>;
  readonly preplanSent?: Maybe<Scalars['String']>;
  readonly assignedPowerId?: Maybe<Scalars['String']>;
  readonly assignedPowerCode?: Maybe<Scalars['String']>;
  readonly assignedTrailerId?: Maybe<Scalars['String']>;
  readonly assignedTrailersCode?: Maybe<Scalars['String']>;
  readonly currentRouteRLCs?: Maybe<Scalars['String']>;
  readonly nextRouteRLCs?: Maybe<Scalars['String']>;
  /** @deprecated Use driverNextAvailableDatetime instead */
  readonly calcNextAvailableDateTime?: Maybe<Scalars['Float']>;
  /** @deprecated Use driverNextAvailableLocationState instead */
  readonly calcNextAvailableState?: Maybe<Scalars['String']>;
  /** @deprecated Use driverNextAvailableLocationCity instead */
  readonly calcNextAvailableCity?: Maybe<Scalars['String']>;
  readonly prefNextAvailableDateTime?: Maybe<Scalars['DateTime']>;
  readonly prefNextAvailableCity?: Maybe<Scalars['String']>;
  readonly prefNextAvailableState?: Maybe<Scalars['String']>;
  readonly prefNextAvailableReason?: Maybe<Scalars['String']>;
  readonly nextRoutingRequestEarliestArrivalDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use nextRoutingRequestEarliestArrivalDateTime instead */
  readonly nextRoutingRequestEarliestArrivalTime?: Maybe<Scalars['Float']>;
  readonly nextRoutingRequestLatestArrivalDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use nextRoutingRequestLatestArrivalDateTime instead */
  readonly nextRoutingRequestLatestArrivalTime?: Maybe<Scalars['Float']>;
  readonly nextRoutingRequestLocationCity?: Maybe<Scalars['String']>;
  readonly nextRoutingRequestLocationState?: Maybe<Scalars['String']>;
  readonly nextRoutingRequestLocationCountry?: Maybe<Scalars['String']>;
  readonly nextRoutingRequestFacilityName?: Maybe<Scalars['String']>;
  readonly nextRoutingRequestFacilityCity?: Maybe<Scalars['String']>;
  readonly nextRoutingRequestFacilityState?: Maybe<Scalars['String']>;
  /** @deprecated Use assignedTrailerId  assignedTrailerCode instead */
  readonly assignedTrailers?: Maybe<Scalars['String']>;
  /** @deprecated Use nextRoutingRequestLocationCity, nextRoutingRequestLocationState, nextRoutingRequestLocationCountry instead */
  readonly locationList?: Maybe<Scalars['String']>;
  /** @deprecated Use facilitylocationList  nextRoutingRequestFacilityName, nextRoutingRequestFacilityCity, nextRoutingRequestFacilityState instead */
  readonly facilitylocationList?: Maybe<Scalars['String']>;
  /** @deprecated Use nextRoutingRequestEarliestArrivalDate instead */
  readonly nextRoutingRequestEarliestArrivalDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use nextRoutingRequestLatestArrivalDate instead */
  readonly nextRoutingRequestLatestArrivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly currentRouteId?: Maybe<Scalars['ID']>;
  readonly nextRouteId?: Maybe<Scalars['ID']>;
  readonly currentRouteNumber?: Maybe<Scalars['String']>;
  readonly nextRouteNumber?: Maybe<Scalars['String']>;
  readonly dutyStatusTerm?: Maybe<Scalars['String']>;
  readonly hosRuleTerm?: Maybe<Scalars['String']>;
  readonly currentRouteRlc?: Maybe<Scalars['String']>;
  readonly nextRouteRlc?: Maybe<Scalars['String']>;
  readonly prePlanDetails?: Maybe<Scalars['String']>;
};

export type DriverListConnection = {
  readonly __typename?: 'DriverListConnection';
  readonly edges: ReadonlyArray<DriverListEdge>;
  readonly pageInfo: PageInfo;
};

export type DriverListEdge = {
  readonly __typename?: 'DriverListEdge';
  readonly node: DriverList;
  readonly cursor: Scalars['String'];
};

export type DriverListFilterInput = {
  readonly driverFleetManagerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverFleetCarrierId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedPower?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedTrailer?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type DriverLocationFrequency = {
  readonly __typename?: 'DriverLocationFrequency';
  readonly id: Scalars['ID'];
  readonly frequencyType: Scalars['String'];
  readonly frequencyValue: Scalars['String'];
};

export type DriverLocationFrequencyInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly customerCode: Scalars['String'];
  readonly frequencyValue: Scalars['String'];
  readonly frequencyType: Scalars['String'];
};

export type DriverMessage = {
  readonly __typename?: 'DriverMessage';
  readonly id: Scalars['String'];
  readonly code?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  readonly conversationId?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly priority?: Maybe<Scalars['String']>;
  readonly content: Scalars['String'];
  readonly sentBy: DriverMessageSentBy;
  readonly readBy?: Maybe<ReadonlyArray<DriverMessageReadBy>>;
  readonly sentAtDateTime: Scalars['String'];
};

export type DriverMessageEdge = {
  readonly __typename?: 'DriverMessageEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverMessageV2;
};

export type DriverMessageReadBy = {
  readonly __typename?: 'DriverMessageReadBy';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly readAtDateTime: Scalars['String'];
};

export type DriverMessageReadByV2 = {
  readonly __typename?: 'DriverMessageReadByV2';
  readonly id: Scalars['String'];
  readonly readAtDateTime: Scalars['String'];
  readonly code?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly role: DriverConversationReadByRole;
};

export type DriverMessageSentBy = {
  readonly __typename?: 'DriverMessageSentBy';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type DriverMessageV2 = {
  readonly __typename?: 'DriverMessageV2';
  readonly id: Scalars['String'];
  readonly subject: Scalars['String'];
  readonly drivers: ReadonlyArray<DriverConversationDriver>;
  readonly createdBy: CreatedBy;
  readonly createdAtDateTime: Scalars['String'];
  readonly content: Scalars['String'];
  readonly source: Scalars['String'];
  readonly priority?: Maybe<Scalars['String']>;
  readonly tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly readBy?: Maybe<ReadonlyArray<DriverMessageReadByV2>>;
};

export type DriverMobileInvitationAudit = {
  readonly __typename?: 'DriverMobileInvitationAudit';
  readonly id: Scalars['String'];
  readonly driverId: Scalars['String'];
  readonly driverEmailAddress: Scalars['String'];
  readonly driverRole?: Maybe<Scalars['String']>;
  readonly driverStatus?: Maybe<Scalars['String']>;
  readonly driverInvitationStatus?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
};

export type DriverMobileScheduledJob = {
  readonly __typename?: 'DriverMobileScheduledJob';
  readonly name: Scalars['String'];
  readonly data: StartTrackingNotificationV2;
  readonly type: Scalars['String'];
  readonly priority: Scalars['Int'];
  readonly nextRunAt?: Maybe<Scalars['String']>;
  readonly repeatInterval?: Maybe<Scalars['String']>;
  readonly repeatTimezone?: Maybe<Scalars['String']>;
  readonly lastModifiedBy?: Maybe<Scalars['String']>;
  readonly lastRunAt?: Maybe<Scalars['String']>;
  readonly progress?: Maybe<Scalars['Int']>;
  readonly failReason?: Maybe<Scalars['String']>;
  readonly failCount?: Maybe<Scalars['Int']>;
  readonly failedAt?: Maybe<Scalars['String']>;
  readonly lastFinishedAt?: Maybe<Scalars['String']>;
  readonly lockedAt?: Maybe<Scalars['String']>;
};

export type DriverMobileScheduledJobPayload = {
  readonly __typename?: 'DriverMobileScheduledJobPayload';
  readonly data?: Maybe<ReadonlyArray<Maybe<DriverMobileScheduledJob>>>;
  readonly errors: ReadonlyArray<AgendaUserError>;
};

export type DriverOperationalPreferenceOutput = {
  readonly __typename?: 'DriverOperationalPreferenceOutput';
  readonly id: Scalars['ID'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly subTypeTerms?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly preferenceTerm: Scalars['String'];
  /** @deprecated notes is deprecated. */
  readonly notes?: Maybe<Scalars['String']>;
  readonly qualifier?: Maybe<Scalars['String']>;
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isActive: Scalars['Boolean'];
};

export type DriverOperationalPreferenceOutputEdge = {
  readonly __typename?: 'DriverOperationalPreferenceOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverOperationalPreferenceOutput;
};

export type DriverOperationalPreferences = {
  readonly __typename?: 'DriverOperationalPreferences';
  readonly id: Scalars['ID'];
  readonly type?: Maybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly qualifier?: Maybe<Scalars['String']>;
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverOperationalPreferencesInput = {
  readonly type?: InputMaybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly qualifier?: InputMaybe<Scalars['String']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverOperationalPreferencesPayload = {
  readonly __typename?: 'DriverOperationalPreferencesPayload';
  readonly edges: ReadonlyArray<DriverOperationalPreferenceOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverPaginationFilters = {
  readonly driverId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type DriverPayProgram = {
  readonly __typename?: 'DriverPayProgram';
  readonly id: Scalars['ID'];
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly code: Scalars['String'];
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly isDisabled: Scalars['Boolean'];
  readonly inactiveDate?: Maybe<Scalars['DateTime']>;
  readonly createdById: Scalars['ID'];
  readonly createdByName: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly updatedById: Scalars['ID'];
  readonly updatedByName: Scalars['String'];
  readonly updatedOn: Scalars['DateTime'];
};

export type DriverPayProgramRouteAssignmentInput = {
  readonly driverId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly isOverridden: Scalars['Boolean'];
  readonly overridePayProgramId?: InputMaybe<Scalars['ID']>;
};

export type DriverPayProgramRouteAssignmentModel = {
  readonly __typename?: 'DriverPayProgramRouteAssignmentModel';
  /** @deprecated Use assetDriver instead */
  readonly driverId: Scalars['ID'];
  readonly assetDriver: AssetDriver;
  /** @deprecated Use route instead */
  readonly routeId: Scalars['ID'];
  readonly route: LoadRouteV2;
  readonly isOverridden: Scalars['Boolean'];
  /** @deprecated Use payProgram instead */
  readonly payProgramId?: Maybe<Scalars['ID']>;
  readonly payProgram?: Maybe<PayProgramModel>;
  readonly createdById: Scalars['ID'];
  readonly createdByName: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly updatedById: Scalars['ID'];
  readonly updatedByName: Scalars['String'];
  readonly updatedOn: Scalars['DateTime'];
};

export type DriverPaySettingInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly driverId: Scalars['ID'];
  readonly driverAccountingStatus: Scalars['String'];
  readonly perDiem?: InputMaybe<Scalars['String']>;
  readonly currency: Scalars['String'];
  readonly driverPayNotes?: InputMaybe<Scalars['String']>;
  readonly driverMin?: InputMaybe<Scalars['Decimal']>;
};

export type DriverPaySettingsFilter = {
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly driverAccountingStatus?: InputMaybe<Scalars['String']>;
  readonly perDiem?: InputMaybe<Scalars['String']>;
  readonly currency?: InputMaybe<Scalars['String']>;
};

export type DriverPaySettingType = {
  readonly __typename?: 'DriverPaySettingType';
  readonly id: Scalars['ID'];
  readonly driverId: Scalars['ID'];
  readonly driverAccountingStatus: Scalars['String'];
  /** @deprecated PerDiem will live as a driver pay attribute only going forward. */
  readonly perDiem: Scalars['String'];
  readonly currency: Scalars['String'];
  readonly driverPayNotes?: Maybe<Scalars['String']>;
  readonly driverMin?: Maybe<Scalars['Decimal']>;
  readonly createdById?: Maybe<Scalars['ID']>;
  readonly createdByName?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedById?: Maybe<Scalars['ID']>;
  readonly updatedByName?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

export type DriverPermanentPowerInput = {
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverPermanentPowers = {
  readonly __typename?: 'DriverPermanentPowers';
  readonly id: Scalars['ID'];
  readonly powerId?: Maybe<Scalars['String']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverPermanentTrailers = {
  readonly __typename?: 'DriverPermanentTrailers';
  readonly id: Scalars['ID'];
  readonly permanentTrailerNumber: Scalars['String'];
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
};

export type DriverPermanentTrailersInput = {
  readonly permanentTrailerNumber: Scalars['String'];
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverPreferredLanes = {
  readonly __typename?: 'DriverPreferredLanes';
  readonly id: Scalars['ID'];
  readonly originType?: Maybe<Scalars['String']>;
  readonly originCityState?: Maybe<Scalars['String']>;
  /** @deprecated originRegion is deprecated. Use preferenceOriginRegion instead. */
  readonly originRegion?: Maybe<Scalars['String']>;
  readonly preferenceOriginRegion?: Maybe<PreferredLanesRegionOutput>;
  readonly destinationType?: Maybe<Scalars['String']>;
  readonly destinationCityState?: Maybe<Scalars['String']>;
  /** @deprecated destinationRegion is deprecated. Use preferenceDestinationRegion instead. */
  readonly destinationRegion?: Maybe<Scalars['String']>;
  readonly preferenceDestinationRegion?: Maybe<PreferredLanesRegionOutput>;
  readonly preference: Scalars['String'];
  readonly reason?: Maybe<Scalars['String']>;
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated isDeleted is deprecated. Use isActive instead. */
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
};

export type DriverPreferredLanesInput = {
  readonly originType?: InputMaybe<Scalars['String']>;
  readonly originCityState?: InputMaybe<Scalars['String']>;
  readonly originRegion?: InputMaybe<Scalars['String']>;
  readonly preferenceOriginRegion?: InputMaybe<PreferredLanesRegionInput>;
  readonly destinationType?: InputMaybe<Scalars['String']>;
  readonly destinationCityState?: InputMaybe<Scalars['String']>;
  readonly destinationRegion?: InputMaybe<Scalars['String']>;
  readonly preferenceDestinationRegion?: InputMaybe<PreferredLanesRegionInput>;
  readonly preference: Scalars['String'];
  readonly reason?: InputMaybe<Scalars['String']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DriverPreferredLanesOutput = {
  readonly __typename?: 'DriverPreferredLanesOutput';
  readonly id: Scalars['ID'];
  readonly originType?: Maybe<Scalars['String']>;
  readonly originCityState?: Maybe<Scalars['String']>;
  readonly preferenceOriginRegion?: Maybe<PreferredLanesRegionOutput>;
  readonly destinationType?: Maybe<Scalars['String']>;
  readonly destinationCityState?: Maybe<Scalars['String']>;
  readonly preferenceDestinationRegion?: Maybe<PreferredLanesRegionOutput>;
  readonly preference: Scalars['String'];
  readonly reason?: Maybe<Scalars['String']>;
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverPreferredRoutes = {
  readonly __typename?: 'DriverPreferredRoutes';
  readonly id: Scalars['ID'];
  readonly shipper?: Maybe<Scalars['String']>;
  readonly originCityState?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly consignee?: Maybe<Scalars['String']>;
  readonly destinationCityState?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['String']>;
  readonly additionalStops?: Maybe<Scalars['Boolean']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly reason?: Maybe<Scalars['String']>;
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated isDeleted is deprecated. Use isActive instead. */
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly preferredRouteStops?: Maybe<ReadonlyArray<DriverPreferredRouteStops>>;
};

export type DriverPreferredRoutesInput = {
  readonly shipper?: InputMaybe<Scalars['String']>;
  readonly originCityState?: InputMaybe<Scalars['String']>;
  readonly originState?: InputMaybe<Scalars['String']>;
  readonly consignee?: InputMaybe<Scalars['String']>;
  readonly destinationCityState?: InputMaybe<Scalars['String']>;
  readonly destinationState?: InputMaybe<Scalars['String']>;
  readonly stops?: InputMaybe<Scalars['String']>;
  readonly additionalStops?: InputMaybe<Scalars['Boolean']>;
  readonly customer?: InputMaybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly reason?: InputMaybe<Scalars['String']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>;
  readonly preferredRouteStops?: InputMaybe<ReadonlyArray<DriverPreferredRouteStopsInput>>;
};

export type DriverPreferredRouteStops = {
  readonly __typename?: 'DriverPreferredRouteStops';
  readonly id: Scalars['ID'];
  readonly type?: Maybe<Scalars['String']>;
  readonly cityState?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly facility?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverPreferredRouteStopsInput = {
  readonly type?: InputMaybe<Scalars['String']>;
  readonly cityState?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly facility?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly routeId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverProjectedHos = {
  readonly __typename?: 'DriverProjectedHos';
  /** ID of the first driver */
  readonly driver1Id?: Maybe<Scalars['ID']>;
  /** ID of the second driver */
  readonly driver2Id?: Maybe<Scalars['ID']>;
  /** Arrival Hos clocks for first driver */
  readonly driver1ArrivalProjectedHosClocks?: Maybe<ProjectedHosClocks>;
  /** Departure Hos clocks for first driver */
  readonly driver1DepartureProjectedHosClocks?: Maybe<ProjectedHosClocks>;
  /** Arrival Hos clocks for second driver */
  readonly driver2ArrivalProjectedHosClocks?: Maybe<ProjectedHosClocks>;
  /** Departure Hos clocks for second driver */
  readonly driver2DepartureProjectedHosClocks?: Maybe<ProjectedHosClocks>;
};

export enum DriverProjectedPayMethodEnum {
  /** Calculates projected pay for a single route and driver across all eligible pay programs for the driver, taking into account all primary and secondary fleet relationships. */
  RouteDriverAcrossEligiblePayPrograms = 'ROUTE_DRIVER_ACROSS_ELIGIBLE_PAY_PROGRAMS',
  /** Calculates projected pay for a driver across one or more routes, using the preferred pay program for each driver/route combination.  A carrier must be specified along with each route. */
  DriverAcrossRoutes = 'DRIVER_ACROSS_ROUTES',
  /** Calculates projected pay for a route across one or more drivers, using the preferred pay program for each driver/route combination.  A carrier must be specified along with the route. */
  RouteAcrossDrivers = 'ROUTE_ACROSS_DRIVERS'
}

export type DriverRecord = {
  readonly __typename?: 'DriverRecord';
  readonly driverId: Scalars['ID'];
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverPlatformScienceId?: Maybe<Scalars['String']>;
  readonly driverCode?: Maybe<Scalars['String']>;
  readonly driverTypeTerm?: Maybe<Scalars['String']>;
  readonly driverCompany?: Maybe<Scalars['String']>;
  readonly fleetManager?: Maybe<Scalars['String']>;
};

export type DriverRelationshipFilter = {
  readonly driverId: Scalars['String'];
  readonly isExpired?: InputMaybe<Scalars['Boolean']>;
};

/** This is a Driver Fleer Input which we are using while creating Driver Fleet relation */
export type DriverRepresentativeInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  /** This field describes the name of the Driver's associated representative */
  readonly employeeId: Scalars['ID'];
  /**
   * This field describes the effective date of the driver's rep assignment. Format
   * is from RFC 3339, section 5.6 which is YYYY-MM-DD.
   */
  readonly effectiveDate: Scalars['Date'];
  /**
   * This field describes the expiration date of driver’s rep assignment. Format is
   * from RFC 3339, section 5.6 which is YYYY-MM-DD.
   */
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  /** This field describes the type of representative associated with the driver */
  readonly typeTerm: Scalars['String'];
  readonly driverId: Scalars['ID'];
  /** This field describes if this representative is the main person associated with the driver */
  readonly isMain?: InputMaybe<Scalars['Boolean']>;
  readonly isLoad?: InputMaybe<Scalars['Boolean']>;
};

export type DriverRepresentativeObjectInput = {
  readonly typeTerm: Scalars['String'];
  readonly employeeId: Scalars['ID'];
  readonly isMain?: InputMaybe<Scalars['Boolean']>;
  readonly isLoad?: InputMaybe<Scalars['Boolean']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverRepresentativePatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly employeeId?: InputMaybe<Scalars['ID']>;
  readonly isMain?: InputMaybe<Scalars['Boolean']>;
  readonly isLoad?: InputMaybe<Scalars['Boolean']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly effectiveDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverRepresentativePayload = {
  readonly __typename?: 'DriverRepresentativePayload';
  readonly edges: ReadonlyArray<DriverRepresentativesEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverRepresentatives = {
  readonly __typename?: 'DriverRepresentatives';
  readonly id: Scalars['ID'];
  /** This field describes the type of representative associated with the driver */
  readonly typeTerm?: Maybe<Scalars['String']>;
  /**
   * This field describes the effective date of the driver's rep assignment. Format
   * is from RFC 3339, section 5.6 which is YYYY-MM-DD.
   */
  readonly effectiveDate: Scalars['Date'];
  /**
   * This field describes the expiration date of driver’s rep assignment. Format is
   * from RFC 3339, section 5.6 which is YYYY-MM-DD.
   */
  readonly expirationDate?: Maybe<Scalars['Date']>;
  /** This field describes the employee by which relationship is established. */
  readonly employeeId?: Maybe<EmployeeV2>;
  readonly driverId: Scalars['ID'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
  /** This field describes if this representative is the main person associated with the driver */
  readonly isMain?: Maybe<Scalars['Boolean']>;
  readonly isLoad?: Maybe<Scalars['Boolean']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverRepresentativesEdge = {
  readonly __typename?: 'DriverRepresentativesEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverRepresentatives;
};

export type DriverRoute = {
  readonly __typename?: 'DriverRoute';
  readonly id: Scalars['ID'];
  readonly driverStatus?: Maybe<Scalars['String']>;
  readonly isCurrent?: Maybe<Scalars['Boolean']>;
  readonly trackingEnabled?: Maybe<Scalars['Boolean']>;
  readonly trackingMethodId?: Maybe<Scalars['String']>;
  readonly braceTypes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly braceCount?: Maybe<Scalars['Int']>;
  readonly tarpType?: Maybe<Scalars['String']>;
  readonly tarpCount?: Maybe<Scalars['Int']>;
  readonly expectedMaxWeight?: Maybe<Measurement>;
  readonly commodities: ReadonlyArray<DriverRouteCommodity>;
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly carrierCode?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly requirements?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly trailerDimensions?: Maybe<Dimension>;
  readonly selectedTrailerType?: Maybe<Scalars['String']>;
  readonly trailers?: Maybe<ReadonlyArray<Maybe<DriverRouteTrailer>>>;
  readonly summary: ReadonlyArray<Maybe<DriverKeyValue>>;
  readonly loadDetails?: Maybe<DriverRouteLoadDetails>;
};

export type DriverRouteCommodity = {
  readonly __typename?: 'DriverRouteCommodity';
  readonly customerCode: Scalars['String'];
  readonly description: Scalars['String'];
  readonly expectedPieces?: Maybe<Scalars['Int']>;
  readonly expectedClass: Scalars['String'];
  readonly expectedNMFCCode: Scalars['String'];
  readonly expectedWeight?: Maybe<Measurement>;
  readonly expectedLinear?: Maybe<Measurement>;
  readonly expectedVolume?: Maybe<Measurement>;
  readonly expectedDensity?: Maybe<Measurement>;
  readonly actualPieces?: Maybe<Scalars['Int']>;
  readonly actualClass: Scalars['String'];
  readonly actualNMFCCode: Scalars['String'];
  readonly actualWeight?: Maybe<Measurement>;
  readonly actualLinear?: Maybe<Measurement>;
  readonly actualVolume?: Maybe<Measurement>;
  readonly actualDensity?: Maybe<Measurement>;
  readonly bottomLoadable?: Maybe<Scalars['Boolean']>;
  readonly hazmat?: Maybe<Scalars['Boolean']>;
  readonly hazmatClass: Scalars['String'];
  readonly hazmatPackagingGroup: Scalars['String'];
  readonly hazmatPhoneNumber: Scalars['String'];
  readonly hazmatUnitedNationsNumber: Scalars['String'];
  readonly id: Scalars['String'];
  readonly loadOnType: Scalars['String'];
  readonly maximumTemperature: Scalars['String'];
  readonly minimumTemperature: Scalars['String'];
  readonly orderId: Scalars['String'];
  readonly packagingCount?: Maybe<Scalars['Int']>;
  readonly packagingType: Scalars['String'];
  readonly pieceType: Scalars['String'];
  readonly preCoolTo: Scalars['String'];
  readonly stccCode: Scalars['String'];
  readonly temperatureControlled?: Maybe<Scalars['Boolean']>;
  readonly temperatureSetting: Scalars['String'];
  readonly topLoadable?: Maybe<Scalars['Boolean']>;
  readonly expectedDimensions?: Maybe<Dimension>;
  readonly actualDimensions?: Maybe<Dimension>;
};

export type DriverRouteCommodityV2 = {
  readonly __typename?: 'DriverRouteCommodityV2';
  readonly customerCode?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly expectedPieces?: Maybe<Scalars['Int']>;
  readonly expectedClass?: Maybe<Scalars['String']>;
  readonly expectedNMFCCode?: Maybe<Scalars['String']>;
  readonly expectedWeight?: Maybe<Measurement>;
  readonly expectedLinear?: Maybe<Measurement>;
  readonly expectedVolume?: Maybe<Measurement>;
  readonly expectedDensity?: Maybe<Measurement>;
  readonly actualPieces?: Maybe<Scalars['Int']>;
  readonly actualClass?: Maybe<Scalars['String']>;
  readonly actualNMFCCode?: Maybe<Scalars['String']>;
  readonly actualWeight?: Maybe<Measurement>;
  readonly actualLinear?: Maybe<Measurement>;
  readonly actualVolume?: Maybe<Measurement>;
  readonly actualDensity?: Maybe<Measurement>;
  readonly bottomLoadable?: Maybe<Scalars['Boolean']>;
  readonly hazmat?: Maybe<Scalars['Boolean']>;
  readonly hazmatClass?: Maybe<Scalars['String']>;
  readonly hazmatPackagingGroup?: Maybe<Scalars['String']>;
  readonly hazmatPhoneNumber?: Maybe<Scalars['String']>;
  readonly hazmatUnitedNationsNumber?: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly loadOnType?: Maybe<Scalars['String']>;
  readonly maximumTemperature?: Maybe<Scalars['String']>;
  readonly minimumTemperature?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['String']>;
  readonly packagingCount?: Maybe<Scalars['Int']>;
  readonly packagingType?: Maybe<Scalars['String']>;
  readonly pieceType?: Maybe<Scalars['String']>;
  readonly preCoolTo?: Maybe<Scalars['String']>;
  readonly stccCode?: Maybe<Scalars['String']>;
  readonly temperatureControlled?: Maybe<Scalars['Boolean']>;
  readonly temperatureSetting?: Maybe<Scalars['String']>;
  readonly topLoadable?: Maybe<Scalars['Boolean']>;
  readonly expectedDimensions?: Maybe<DimensionV2>;
  readonly actualDimensions?: Maybe<DimensionV2>;
};

export type DriverRouteList = {
  readonly __typename?: 'DriverRouteList';
  /** Route ID, says id for frontend caching purposes, used to hyperlink routeCode */
  readonly id: Scalars['ID'];
  readonly routeCode: Scalars['ID'];
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['String']>;
  readonly loadId: Scalars['ID'];
  readonly customerName?: Maybe<Scalars['String']>;
  /** For hyperlinking customerName */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  /** For hyperlinking originStopFacilityName */
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly routeDistance?: Maybe<Scalars['Float']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  /** For hyperlinking destStopFacilityName */
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  /** For hyperlinking routeCarrierName */
  readonly routeCarrierId?: Maybe<Scalars['String']>;
  readonly mainRouteRep?: Maybe<Scalars['String']>;
  /** For hyperlinking mainRouteRep */
  readonly mainRouteRepId?: Maybe<Scalars['String']>;
  /** @deprecated Not in requirements for this view */
  readonly driver1Name?: Maybe<Scalars['String']>;
  /**
   * For hyperlinking driver1Name
   * @deprecated Not in requirements for this view
   */
  readonly driver1Id?: Maybe<Scalars['String']>;
  /** @deprecated Not in requirements for this view */
  readonly driver2Name?: Maybe<Scalars['String']>;
  /**
   * For hyperlinking driver2Name
   * @deprecated Not in requirements for this view
   */
  readonly driver2Id?: Maybe<Scalars['String']>;
  readonly powerName?: Maybe<Scalars['String']>;
  /** For hyperlinking powerName */
  readonly powerId?: Maybe<Scalars['String']>;
  /**
   * Not currently available, domain team working on providing
   * @deprecated Use TrailerIdentifiers1/2/3 as trailerName instead
   */
  readonly trailerName?: Maybe<Scalars['String']>;
  /**
   * For hyperlinking trailerName. Not currently available, domain team working on providing
   * @deprecated Use trailerRecordIds1/2/3 as trailerId instead
   */
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly routeTotalCost?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly primaryDivision?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly additionalDivision?: Maybe<Scalars['String']>;
  readonly executingDivision?: Maybe<Scalars['String']>;
  readonly inboundTrailerIdentifier1?: Maybe<Scalars['String']>;
  readonly inboundTrailerRecordId1?: Maybe<Scalars['String']>;
  readonly inboundTrailerResourceType1?: Maybe<Scalars['String']>;
  readonly inboundTrailerIdentifier2?: Maybe<Scalars['String']>;
  readonly inboundTrailerRecordId2?: Maybe<Scalars['String']>;
  readonly inboundTrailerResourceType2?: Maybe<Scalars['String']>;
  readonly inboundTrailerIdentifier3?: Maybe<Scalars['String']>;
  readonly inboundTrailerRecordId3?: Maybe<Scalars['String']>;
  readonly inboundTrailerResourceType3?: Maybe<Scalars['String']>;
  readonly outboundTrailerIdentifier1?: Maybe<Scalars['String']>;
  readonly outboundTrailerRecordId1?: Maybe<Scalars['String']>;
  readonly outboundTrailerResourceType1?: Maybe<Scalars['String']>;
  readonly outboundTrailerIdentifier2?: Maybe<Scalars['String']>;
  readonly outboundTrailerRecordId2?: Maybe<Scalars['String']>;
  readonly outboundTrailerResourceType2?: Maybe<Scalars['String']>;
  readonly outboundTrailerIdentifier3?: Maybe<Scalars['String']>;
  readonly outboundTrailerRecordId3?: Maybe<Scalars['String']>;
  readonly outboundTrailerResourceType3?: Maybe<Scalars['String']>;
  readonly loadedTrailerIdentifier1?: Maybe<Scalars['String']>;
  readonly loadedTrailerRecordId1?: Maybe<Scalars['String']>;
  readonly loadedTrailerResourceType1?: Maybe<Scalars['String']>;
  readonly loadedTrailerIdentifier2?: Maybe<Scalars['String']>;
  readonly loadedTrailerRecordId2?: Maybe<Scalars['String']>;
  readonly loadedTrailerResourceType2?: Maybe<Scalars['String']>;
  readonly loadedTrailerIdentifier3?: Maybe<Scalars['String']>;
  readonly loadedTrailerRecordId3?: Maybe<Scalars['String']>;
  readonly loadedTrailerResourceType3?: Maybe<Scalars['String']>;
};

export type DriverRouteListConnection = {
  readonly __typename?: 'DriverRouteListConnection';
  readonly edges: ReadonlyArray<DriverRouteListEdge>;
  readonly pageInfo: PageInfo;
};

export type DriverRouteListEdge = {
  readonly __typename?: 'DriverRouteListEdge';
  readonly node: DriverRouteList;
  readonly cursor: Scalars['String'];
};

export type DriverRouteListFilterInput = {
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly primaryDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly executingDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type DriverRouteLoadDetails = {
  readonly __typename?: 'DriverRouteLoadDetails';
  readonly refNumbers: ReadonlyArray<Maybe<DriverKeyValue>>;
};

export type DriverRoutePaginationFilters = {
  readonly driverId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly isExpired?: InputMaybe<Scalars['Boolean']>;
};

export type DriverRouteSubList = {
  readonly __typename?: 'DriverRouteSubList';
  /** Route ID, says id for frontend caching purposes, used to hyperlink routeCode */
  readonly id: Scalars['ID'];
  readonly routeCode: Scalars['ID'];
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['String']>;
  readonly loadId: Scalars['ID'];
  readonly customerName?: Maybe<Scalars['String']>;
  /** For hyperlinking customerName */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  /** For hyperlinking originStopFacilityName */
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly routeDistance?: Maybe<Scalars['Float']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  /** For hyperlinking destStopFacilityName */
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  /** For hyperlinking routeCarrierName */
  readonly routeCarrierId?: Maybe<Scalars['String']>;
  readonly mainRouteRep?: Maybe<Scalars['String']>;
  /** For hyperlinking mainRouteRep */
  readonly mainRouteRepId?: Maybe<Scalars['String']>;
  /** @deprecated Not in requirements for this view */
  readonly driver1Name?: Maybe<Scalars['String']>;
  /**
   * For hyperlinking driver1Name
   * @deprecated Not in requirements for this view
   */
  readonly driver1Id?: Maybe<Scalars['String']>;
  /** @deprecated Not in requirements for this view */
  readonly driver2Name?: Maybe<Scalars['String']>;
  /**
   * For hyperlinking driver2Name
   * @deprecated Not in requirements for this view
   */
  readonly driver2Id?: Maybe<Scalars['String']>;
  readonly powerName?: Maybe<Scalars['String']>;
  /** For hyperlinking powerName */
  readonly powerId?: Maybe<Scalars['String']>;
  /** Not currently available, domain team working on providing */
  readonly trailerName?: Maybe<Scalars['String']>;
  /** For hyperlinking trailerName. Not currently available, domain team working on providing */
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly routeTotalCost?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly routeSize?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly mainOrderRep?: Maybe<Scalars['String']>;
  /** For hyperlinking mainOrderRep */
  readonly mainOrderRepId?: Maybe<Scalars['String']>;
  readonly primaryDivision?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly additionalDivision?: Maybe<Scalars['String']>;
  readonly executingDivision?: Maybe<Scalars['String']>;
};

export type DriverRouteSubListConnection = {
  readonly __typename?: 'DriverRouteSubListConnection';
  readonly edges: ReadonlyArray<DriverRouteSubListEdge>;
  readonly pageInfo: PageInfo;
};

export type DriverRouteSubListEdge = {
  readonly __typename?: 'DriverRouteSubListEdge';
  readonly node: DriverRouteSubList;
  readonly cursor: Scalars['String'];
};

export type DriverRouteSubListFilterInput = {
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly primaryDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly executingDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type DriverRouteTrailer = {
  readonly __typename?: 'DriverRouteTrailer';
  readonly type?: Maybe<Scalars['String']>;
  readonly areAccessoriesVisible?: Maybe<Scalars['Boolean']>;
  readonly sequenceNumber?: Maybe<Scalars['Int']>;
  readonly widthInInches?: Maybe<Scalars['Float']>;
  readonly lengthInFeet?: Maybe<Scalars['Float']>;
  readonly heightInInches?: Maybe<Scalars['Float']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly numberOfBracings?: Maybe<Scalars['Int']>;
  readonly numberOfPallets?: Maybe<Scalars['Int']>;
  readonly numberOfTarps?: Maybe<Scalars['Int']>;
  readonly palletType?: Maybe<Scalars['String']>;
  readonly tarpType?: Maybe<Scalars['String']>;
  readonly bracings?: Maybe<ReadonlyArray<Maybe<DriverTrailerbracingType>>>;
};

export type DriverRouteV2 = {
  readonly __typename?: 'DriverRouteV2';
  readonly id: Scalars['ID'];
  readonly driverStatus?: Maybe<Scalars['String']>;
  readonly isCurrent?: Maybe<Scalars['Boolean']>;
  readonly trackingEnabled?: Maybe<Scalars['Boolean']>;
  readonly trackingMethodId?: Maybe<Scalars['String']>;
  readonly braceTypes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly braceCount?: Maybe<Scalars['Int']>;
  readonly tarpType?: Maybe<Scalars['String']>;
  readonly tarpCount?: Maybe<Scalars['Int']>;
  readonly expectedMaxWeight?: Maybe<Measurement>;
  readonly commodities: ReadonlyArray<DriverRouteCommodityV2>;
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly carrierCode?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly requirements?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly trailerDimensions?: Maybe<DimensionV2>;
  readonly selectedTrailerType?: Maybe<Scalars['String']>;
  readonly trailers?: Maybe<ReadonlyArray<Maybe<DriverRouteTrailer>>>;
  readonly summary: ReadonlyArray<Maybe<DriverKeyValue>>;
  readonly loadDetails?: Maybe<DriverRouteLoadDetails>;
  readonly stops?: Maybe<ReadonlyArray<Maybe<MobileRouteStop>>>;
  readonly availableTrailerTypes?: Maybe<ReadonlyArray<Maybe<MobileTrailerType>>>;
};

export type DriverRoutingRequestAddressInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly country: Scalars['String'];
};

export type DriverRoutingRequestAddressOutput = {
  readonly __typename?: 'DriverRoutingRequestAddressOutput';
  readonly id?: Maybe<Scalars['ID']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly country: Scalars['String'];
};

export type DriverRoutingRequestFacilityInput = {
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly facilityCode?: InputMaybe<Scalars['String']>;
};

export type DriverRoutingRequestInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly statusTerm: Scalars['String'];
  readonly typeTerm: Scalars['String'];
  readonly earliestArrivalDateTime: Scalars['DateTime'];
  readonly latestArrivalDateTime: Scalars['DateTime'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly isCritical?: InputMaybe<Scalars['Boolean']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId: Scalars['ID'];
  readonly timeZone?: InputMaybe<Scalars['String']>;
  readonly locations?: InputMaybe<DriverRoutingRequestLocationInput>;
};

export type DriverRoutingRequestLocationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly addresses?: InputMaybe<ReadonlyArray<DriverRoutingRequestAddressInput>>;
  readonly facilities?: InputMaybe<ReadonlyArray<DriverRoutingRequestFacilityInput>>;
  readonly driverRoutingRequestId?: InputMaybe<Scalars['ID']>;
};

export type DriverRoutingRequestLocationOutput = {
  readonly __typename?: 'DriverRoutingRequestLocationOutput';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly addresses?: Maybe<ReadonlyArray<DriverRoutingRequestAddressOutput>>;
  readonly facilities?: Maybe<ReadonlyArray<FacilityV2>>;
  readonly driverRoutingRequestId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverRoutingRequestOutput = {
  readonly __typename?: 'DriverRoutingRequestOutput';
  readonly id: Scalars['ID'];
  readonly statusTerm: Scalars['String'];
  readonly typeTerm: Scalars['String'];
  readonly earliestArrivalDateTime: Scalars['DateTime'];
  readonly latestArrivalDateTime: Scalars['DateTime'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly isCritical?: Maybe<Scalars['Boolean']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly driver: AssetDriver;
  readonly timeZone?: Maybe<Scalars['String']>;
  readonly locations?: Maybe<DriverRoutingRequestLocationOutput>;
};

export type DriverRoutingRequestOutputEdge = {
  readonly __typename?: 'DriverRoutingRequestOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverRoutingRequestOutput;
};

export type DriverRoutingRequestPatchInput = {
  readonly id: Scalars['ID'];
  readonly statusTerm?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly earliestArrivalDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly latestArrivalDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly isCritical?: InputMaybe<Scalars['Boolean']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId: Scalars['ID'];
  readonly timeZone?: InputMaybe<Scalars['String']>;
  readonly locations?: InputMaybe<DriverRoutingRequestLocationInput>;
};

export type DriverRoutingRequestPayload = {
  readonly __typename?: 'DriverRoutingRequestPayload';
  readonly edges: ReadonlyArray<DriverRoutingRequestOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverSchedule = {
  readonly __typename?: 'DriverSchedule';
  readonly id: Scalars['ID'];
  /** @deprecated scheduleEffectiveDt is deprecated. Use effectiveDate instead. */
  readonly scheduleEffectiveDt: Scalars['DateTime'];
  /** @deprecated scheduleExpirationDt is deprecated. Use expirationDate instead. */
  readonly scheduleExpirationDt: Scalars['DateTime'];
  readonly dayOfWeek: Scalars['String'];
  /** @deprecated startTm is deprecated. Use startTime instead. */
  readonly startTm: Scalars['DateTime'];
  /** @deprecated endTm is deprecated. Use endTime instead. */
  readonly endTm: Scalars['DateTime'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverScheduleInput = {
  readonly scheduleEffectiveDt: Scalars['DateTime'];
  readonly scheduleExpirationDt: Scalars['DateTime'];
  readonly dayOfWeek: Scalars['String'];
  readonly startTm: Scalars['DateTime'];
  readonly endTm: Scalars['DateTime'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverScheduleInputV2 = {
  readonly effectiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly dayOfWeek?: InputMaybe<Scalars['String']>;
  readonly startTime?: InputMaybe<Scalars['DateTime']>;
  readonly endTime?: InputMaybe<Scalars['DateTime']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverScheduleInputV3 = {
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly hours?: InputMaybe<HoursInput>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverScheduleObjectInput = {
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly hours?: InputMaybe<HoursInput>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverScheduleOutput = {
  readonly __typename?: 'DriverScheduleOutput';
  readonly id: Scalars['ID'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly hours?: Maybe<HoursOutput>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type DriverScheduleOutputEdge = {
  readonly __typename?: 'DriverScheduleOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverScheduleOutput;
};

export type DriverSchedulePatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly effectiveDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly hours?: InputMaybe<HoursInput>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type DriverSchedulePayload = {
  readonly __typename?: 'DriverSchedulePayload';
  readonly edges: ReadonlyArray<DriverScheduleOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverScheduleV2 = {
  readonly __typename?: 'DriverScheduleV2';
  readonly id: Scalars['ID'];
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly dayOfWeek?: Maybe<Scalars['String']>;
  readonly startTime?: Maybe<Scalars['DateTime']>;
  readonly endTime?: Maybe<Scalars['DateTime']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverScheduleV3 = {
  readonly __typename?: 'DriverScheduleV3';
  readonly id: Scalars['ID'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly hours?: Maybe<HoursOutput>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverSearchResult = {
  readonly __typename?: 'DriverSearchResult';
  readonly id?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly middleName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  /** @deprecated nickName is deprecated. Use nickname instead. */
  readonly nickName?: Maybe<Scalars['String']>;
  /** @deprecated primaryEmail is deprecated. Use emailAddress instead. */
  readonly primaryEmail?: Maybe<Scalars['String']>;
  /** @deprecated primaryPhoneNumber is deprecated. Use phoneNumber instead. */
  readonly primaryPhoneNumber?: Maybe<Scalars['String']>;
  /** @deprecated managerName is deprecated. */
  readonly managerName?: Maybe<Scalars['String']>;
  /** @deprecated professionType is deprecated. Use professionalTypeTerm instead. */
  readonly professionType?: Maybe<Scalars['String']>;
  readonly professionalTypeTerm?: Maybe<Scalars['String']>;
  /** @deprecated status is deprecated. Use statusTerm instead. */
  readonly status?: Maybe<Scalars['String']>;
  readonly statusTerm?: Maybe<Scalars['String']>;
  /** @deprecated division is deprecated. Use divisionTerm instead. */
  readonly division?: Maybe<Scalars['String']>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  readonly fleet?: Maybe<Scalars['String']>;
  /** @deprecated region is deprecated. */
  readonly region?: Maybe<Scalars['String']>;
  readonly geography?: Maybe<Scalars['String']>;
  /** @deprecated segment is deprecated. Use businessUnitTerm instead. */
  readonly segment?: Maybe<Scalars['String']>;
  /** @deprecated businessUnitTerm is deprecated. */
  readonly businessUnitTerm?: Maybe<Scalars['String']>;
  /** @deprecated domicile is deprecated. */
  readonly domicile?: Maybe<Scalars['String']>;
  /** @deprecated partnerName is deprecated. Use partner instead. */
  readonly partnerName?: Maybe<Scalars['String']>;
  /** @deprecated driverIdentityId is deprecated. Use code instead. */
  readonly driverIdentityId?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly location?: Maybe<Scalars['String']>;
  readonly fleets?: Maybe<ReadonlyArray<DriverFleet>>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverSortingOptions = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly orderBy?: InputMaybe<Scalars['String']>;
};

export type DriverTargetAppointment = {
  readonly __typename?: 'DriverTargetAppointment';
  readonly driverTargetStart?: Maybe<Time>;
  readonly driverTargetEnd?: Maybe<Time>;
  readonly driverTargetStartTimes?: Maybe<DriverTargetStartEndTimes>;
  readonly driverTargetEndTimes?: Maybe<DriverTargetStartEndTimes>;
};

export type DriverTargetStartEndTimes = {
  readonly __typename?: 'DriverTargetStartEndTimes';
  readonly startOffsetMs?: Maybe<Scalars['Int']>;
  readonly endOffsetMs?: Maybe<Scalars['Int']>;
};

export type DriverTeamPreferenceOutput = {
  readonly __typename?: 'DriverTeamPreferenceOutput';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly preferenceTerm: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type DriverTeamPreferenceOutputEdge = {
  readonly __typename?: 'DriverTeamPreferenceOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverTeamPreferenceOutput;
};

export type DriverTeamPreferences = {
  readonly __typename?: 'DriverTeamPreferences';
  readonly id: Scalars['ID'];
  readonly type: Scalars['String'];
  readonly preference: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverTeamPreferencesInput = {
  readonly type: Scalars['String'];
  readonly preference: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverTeamPreferencesPayload = {
  readonly __typename?: 'DriverTeamPreferencesPayload';
  readonly edges: ReadonlyArray<DriverTeamPreferenceOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverTimeOff = {
  readonly __typename?: 'DriverTimeOff';
  readonly id: Scalars['ID'];
  /** @deprecated timeOffType is deprecated. Use typeTerm instead. */
  readonly timeOffType: Scalars['String'];
  /** @deprecated startDt is deprecated. Use startDate instead. */
  readonly startDt: Scalars['DateTime'];
  /** @deprecated endDt is deprecated. Use endDate instead. */
  readonly endDt: Scalars['DateTime'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverTimeOffInput = {
  readonly timeOffType: Scalars['String'];
  readonly startDt: Scalars['DateTime'];
  readonly endDt: Scalars['DateTime'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverTimeOffInputV2 = {
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly startLocation?: InputMaybe<Scalars['String']>;
  readonly endLocation?: InputMaybe<Scalars['String']>;
};

export type DriverTimeOffInputV3 = {
  readonly typeTerm: Scalars['String'];
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly location?: InputMaybe<TimeOffLocationInput>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverTimeOffObjectInput = {
  readonly typeTerm: Scalars['String'];
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly location?: InputMaybe<TimeOffLocationInput>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
};

export type DriverTimeOffOutput = {
  readonly __typename?: 'DriverTimeOffOutput';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly location?: Maybe<TimeOffLocationOutput>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type DriverTimeOffOutputEdge = {
  readonly __typename?: 'DriverTimeOffOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: DriverTimeOffOutput;
};

export type DriverTimeOffPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly startDate?: InputMaybe<Scalars['Date']>;
  readonly endDate?: InputMaybe<Scalars['Date']>;
  readonly location?: InputMaybe<TimeOffLocationInput>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type DriverTimeOffPayload = {
  readonly __typename?: 'DriverTimeOffPayload';
  readonly edges: ReadonlyArray<DriverTimeOffOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type DriverTimeOffV2 = {
  readonly __typename?: 'DriverTimeOffV2';
  readonly id: Scalars['ID'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly startLocation?: Maybe<Scalars['String']>;
  readonly endLocation?: Maybe<Scalars['String']>;
};

export type DriverTimeOffV3 = {
  readonly __typename?: 'DriverTimeOffV3';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly location?: Maybe<TimeOffLocationOutput>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverTrailerbracingType = {
  readonly __typename?: 'DriverTrailerbracingType';
  readonly type: Scalars['String'];
};

export type DriverTrailerbracingTypeInput = {
  readonly type: Scalars['String'];
};

export type DriverTrailerInput = {
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly permanentTrailers?: InputMaybe<ReadonlyArray<DriverPermanentTrailersInput>>;
};

export type DriverTraining = {
  readonly __typename?: 'DriverTraining';
  readonly id: Scalars['ID'];
  /** This field describes the type of training the driver will undergo or has already completed. */
  readonly typeTerm: Scalars['String'];
  /** This field describes the date on which the driver is expected to complete the training as assigned. */
  readonly dueDate?: Maybe<Scalars['Date']>;
  /** This field describes the date on which the driver completed the training. */
  readonly completedDate?: Maybe<Scalars['Date']>;
  /** This field describes the training result for the driver. */
  readonly outcomeTerm?: Maybe<Scalars['String']>;
  /** This field describes the facility where the training is conducted. */
  readonly facility?: Maybe<FacilityV2>;
  /** This field provides information about the trainer who will conduct or has conducted the session. */
  readonly trainerName?: Maybe<Scalars['String']>;
  /** This field describes the information about the training that the driver has been assigned or completed. */
  readonly description?: Maybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverTrainingInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  /** This field describes the type of training the driver will undergo or has already completed. */
  readonly typeTerm: Scalars['String'];
  /** This field describes the date on which the driver is expected to complete the training as assigned. */
  readonly dueDate?: InputMaybe<Scalars['Date']>;
  /** This field describes the date on which the driver completed the training. */
  readonly completedDate?: InputMaybe<Scalars['Date']>;
  /** This field describes the training result for the driver. */
  readonly outcomeTerm?: InputMaybe<Scalars['String']>;
  /** This field describes the unique facility ID where the training is conducted. */
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  /** This field provides information about the trainer who will conduct or has conducted the session. */
  readonly trainerName?: InputMaybe<Scalars['String']>;
  /** This field describes the information about the training that the driver has been assigned or completed. */
  readonly description?: InputMaybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
};

export type DriverTypeError = {
  readonly __typename?: 'DriverTypeError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly code?: Maybe<Scalars['String']>;
};

export type DriverValidation = {
  readonly __typename?: 'DriverValidation';
  readonly hasDriverIdentityId?: Maybe<Scalars['Boolean']>;
  readonly hasPrimaryEmail?: Maybe<Scalars['Boolean']>;
  readonly hasPrimaryPhoneNumber?: Maybe<Scalars['Boolean']>;
};

export type DriverValidationError = {
  readonly __typename?: 'DriverValidationError';
  readonly message: Scalars['String'];
  readonly code: Scalars['String'];
  readonly name: Scalars['String'];
  readonly isWarning: Scalars['Boolean'];
  readonly objectId: Scalars['String'];
  readonly objectType: Scalars['String'];
};

export type DriverValidationInput = {
  readonly id: Scalars['ID'];
};

export type DriverValidationsInput = {
  readonly routeId: Scalars['ID'];
  readonly employeeId?: InputMaybe<Scalars['ID']>;
  readonly drivers: ReadonlyArray<DriverValidationInput>;
  readonly bypassValidations?: InputMaybe<BypassValidationsInput>;
};

export type DriverValidationsPayload = {
  readonly __typename?: 'DriverValidationsPayload';
  readonly drivers: ReadonlyArray<DriverValidationsResult>;
  readonly allDriversValid: Scalars['Boolean'];
  readonly errors: ReadonlyArray<UserError>;
};

export type DriverValidationsResult = {
  readonly __typename?: 'DriverValidationsResult';
  readonly driverId: Scalars['ID'];
  readonly driver: AssetDriver;
  readonly isValid: Scalars['Boolean'];
  readonly validationErrors: ReadonlyArray<DriverValidationError>;
};

export type DriverVoucherExtractQueue = {
  readonly __typename?: 'DriverVoucherExtractQueue';
  readonly amountPer?: Maybe<Scalars['Float']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly date?: Maybe<Scalars['DateTime']>;
  readonly detailDescription?: Maybe<Scalars['String']>;
  readonly vendorGLCode?: Maybe<Scalars['String']>;
  readonly segmentGLCode?: Maybe<Scalars['String']>;
  readonly totalAmount?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly loadNumber?: Maybe<Scalars['String']>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly preTax?: Maybe<Scalars['String']>;
  readonly divison?: Maybe<Scalars['String']>;
  readonly dueDate?: Maybe<Scalars['DateTime']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly carrierPaymentTerm?: Maybe<Scalars['String']>;
  readonly voucherDate?: Maybe<Scalars['DateTime']>;
  readonly voucherNumber?: Maybe<Scalars['String']>;
  readonly paymentMethod?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly carrierIdentifier?: Maybe<Scalars['String']>;
  readonly orderCommodity?: Maybe<Scalars['String']>;
  readonly orderReference?: Maybe<Scalars['String']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly carrierCode?: Maybe<Scalars['String']>;
  readonly driverCode?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverAccountingStatus?: Maybe<Scalars['String']>;
  readonly driverIdentifierValue?: Maybe<Scalars['String']>;
  readonly netAmount?: Maybe<Scalars['Float']>;
  readonly grossAmount?: Maybe<Scalars['Float']>;
  readonly firstPickUpLocation?: Maybe<Scalars['String']>;
  /** @deprecated This field is deprecated. Use pickupStopCity instead. */
  readonly pickupStop?: Maybe<Scalars['String']>;
  readonly pickupStopCity?: Maybe<Scalars['String']>;
  readonly pickupStopState?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly routeReference?: Maybe<Scalars['String']>;
  readonly remitToCarrierIdentifier?: Maybe<Scalars['String']>;
  readonly remitToCarrierName?: Maybe<Scalars['String']>;
  readonly remitTocarrierCode?: Maybe<Scalars['String']>;
  readonly companyCarrierCode?: Maybe<Scalars['String']>;
  readonly companyCarrierName?: Maybe<Scalars['String']>;
  readonly segment1?: Maybe<Scalars['String']>;
  readonly segment2?: Maybe<Scalars['String']>;
  readonly segment3?: Maybe<Scalars['String']>;
  readonly segment4?: Maybe<Scalars['String']>;
  readonly segment5?: Maybe<Scalars['String']>;
  readonly segment6?: Maybe<Scalars['String']>;
  readonly segment7?: Maybe<Scalars['String']>;
  readonly segment8?: Maybe<Scalars['String']>;
  readonly segment9?: Maybe<Scalars['String']>;
  readonly segment10?: Maybe<Scalars['String']>;
  readonly driverVoucherHeaderEid?: Maybe<Scalars['Float']>;
};

export type DriverVoucherExtractQueueConnection = {
  readonly __typename?: 'DriverVoucherExtractQueueConnection';
  readonly edges: ReadonlyArray<DriverVoucherExtractQueueEdge>;
  readonly pageInfo: PageInfo;
};

export type DriverVoucherExtractQueueEdge = {
  readonly __typename?: 'DriverVoucherExtractQueueEdge';
  readonly node: DriverVoucherExtractQueue;
  readonly cursor: Scalars['String'];
};

export type DriverVoucherExtractQueueFilterInput = {
  readonly carrierType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly updatedOnStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly updatedOnEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly companyCarrierCode?: InputMaybe<OperatorFilterWithNullNotNull>;
};

export type DriverVoucherHeaderStatusChanges = {
  readonly __typename?: 'DriverVoucherHeaderStatusChanges';
  readonly oldStatus?: Maybe<Scalars['String']>;
  readonly newStatus?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  /** @deprecated Use reasons instead. there can be multiple reasons. */
  readonly reason?: Maybe<Scalars['String']>;
  readonly reasons?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly initiatedBy?: Maybe<Scalars['String']>;
  readonly amountDue?: Maybe<Scalars['Decimal']>;
  readonly createdOn: Scalars['DateTime'];
};

export type DriverVoucherHeadersUpdateInput = {
  readonly driverVoucherHeaderIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly status: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type DriverVoucherHeaderType = {
  readonly __typename?: 'DriverVoucherHeaderType';
  readonly id: Scalars['ID'];
  readonly costDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  readonly status: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  /** @deprecated Use reasons instead. there can be multiple reasons. */
  readonly reason?: Maybe<Scalars['String']>;
  readonly reasons?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly voucherNumber?: Maybe<Scalars['String']>;
  /** @deprecated Use voucherDateV2 instead. voucherDate will be the date according to CST. */
  readonly voucherDate?: Maybe<Scalars['DateTime']>;
  readonly voucherDateV2?: Maybe<Scalars['DateTimeOffset']>;
  readonly voucherDateTimezone?: Maybe<Scalars['String']>;
  /** @deprecated Use dueDateV2 instead. dueDate will be the date according to CST. */
  readonly dueDate?: Maybe<Scalars['DateTime']>;
  readonly dueDateV2?: Maybe<Scalars['DateTimeOffset']>;
  readonly dueDateTimezone?: Maybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
  readonly driverMin?: Maybe<Scalars['Decimal']>;
  readonly companyId?: Maybe<Scalars['ID']>;
  readonly paymentMethodId?: Maybe<Scalars['String']>;
  readonly carrierPaymentTermId?: Maybe<Scalars['String']>;
  readonly dueDateCalculation?: Maybe<Scalars['Int']>;
  readonly invoiceProcessedDateTime?: Maybe<Scalars['DateTime']>;
  readonly amountDue?: Maybe<Scalars['Decimal']>;
  readonly taxableAmount?: Maybe<Scalars['Decimal']>;
  /** @deprecated Use cutoffDateV2 instead. cutoffDate will be the date according to CST. */
  readonly cutoffDate?: Maybe<Scalars['DateTime']>;
  readonly cutoffDateV2?: Maybe<Scalars['DateTimeOffset']>;
  readonly remitToAddressId?: Maybe<Scalars['ID']>;
  readonly remittanceEmail?: Maybe<Scalars['String']>;
  readonly discountAmount?: Maybe<Scalars['Decimal']>;
  readonly discountDateCalculation?: Maybe<Scalars['Int']>;
  readonly discountPercent?: Maybe<Scalars['Decimal']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly currencyAmountDue?: Maybe<Scalars['Decimal']>;
  readonly currencyDiscountAmount?: Maybe<Scalars['Decimal']>;
  readonly createdById: Scalars['ID'];
  readonly createdByName: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly updatedById: Scalars['ID'];
  readonly updatedByName: Scalars['String'];
  readonly updatedOn: Scalars['DateTime'];
  readonly statusChanges?: Maybe<ReadonlyArray<Maybe<DriverVoucherHeaderStatusChanges>>>;
  readonly nonRouteCostDetails?: Maybe<ReadonlyArray<Maybe<NonRouteCostDetail>>>;
};

export type DriverVoucherHeaderUpdateInput = {
  readonly driverVoucherHeaderId: Scalars['ID'];
  readonly status: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly reasons?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type DriverVoucherProcessingRequestedCreateInput = {
  readonly driverPayCutoffDate?: InputMaybe<Scalars['DateOnly']>;
  readonly driverPayCutoffTime?: InputMaybe<Scalars['TimeOnly']>;
  readonly driverPayCutoffDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly createdByTimezone?: InputMaybe<Scalars['String']>;
};

export type DriverWarning = {
  readonly __typename?: 'DriverWarning';
  readonly code: CapacityWarningCodesEnum;
  readonly replicaDriverDisplayName?: Maybe<Scalars['String']>;
};

export type DriverWeeklyTarget = {
  readonly __typename?: 'DriverWeeklyTarget';
  readonly id: Scalars['ID'];
  readonly weekStartDate: Scalars['DateTime'];
  readonly target: Target;
  readonly driverId: Scalars['String'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly driverDailyActivities?: Maybe<ReadonlyArray<DriverDailyActivity>>;
};

export type DriverWeeklyTargetInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly weekStartDate: Scalars['DateTime'];
  readonly recurringWeeks: Scalars['Float'];
  readonly target: TargetInput;
  readonly driverId?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type DummyProjectNameExamplesConnection = {
  readonly __typename?: 'DummyProjectNameExamplesConnection';
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<DummyProjectNameExamplesEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Example>>;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DummyProjectNameExamplesEdge = {
  readonly __typename?: 'DummyProjectNameExamplesEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: Example;
};

export type DutyStatusPerDay = {
  readonly __typename?: 'DutyStatusPerDay';
  readonly date: Scalars['Date'];
  readonly totalDriveTime: Scalars['Int'];
  readonly totalOnDutyTime: Scalars['Int'];
  readonly totalSleeperTime: Scalars['Int'];
  readonly totalOffDutyTime: Scalars['Int'];
};

export enum EdgesSortDirection {
  Asc = 'Asc',
  Desc = 'Desc'
}

export enum EdiDirection {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND'
}

export type EdiDocument = {
  readonly __typename?: 'EDIDocument';
  readonly id: Scalars['ID'];
  readonly shipmentId: Scalars['String'];
  readonly providerId: Scalars['String'];
  /** ediDocumentType KVT ID */
  readonly documentTypeId: Scalars['ID'];
  /** uri to access the document */
  readonly uri: Scalars['String'];
  /** The name of the file provided during upload */
  readonly filename: Scalars['String'];
  /**
   * DEPRECATED: Use uploadedByUserV2 instead
   * @deprecated Use uploadedByUserV2 instead
   */
  readonly uploadedByUser?: Maybe<User>;
  readonly uploadDate: Scalars['DateTime'];
  /** uri to access the signed document */
  readonly signedUri?: Maybe<Scalars['String']>;
  /** MasterSign Reference Id */
  readonly masterSignReferenceId?: Maybe<Scalars['String']>;
  /** Identification for MasterSigner */
  readonly signedBy?: Maybe<Scalars['String']>;
  /** Timestamp for when document was signed */
  readonly signedAt?: Maybe<Scalars['DateTime']>;
  /**
   * Document Status
   * @deprecated Depcrecated in favor of documentStatus
   */
  readonly status?: Maybe<Scalars['String']>;
  /** Document Status */
  readonly documentStatus?: Maybe<DocumentStatusEnum>;
  /** Previous Document Status */
  readonly previousDocumentStatus?: Maybe<DocumentStatusEnum>;
  /** Identification for External Uploader */
  readonly externalUser?: Maybe<Scalars['String']>;
  readonly uploadedByUserV2?: Maybe<UserV2>;
};

export type EdiDocumentConnection = {
  readonly __typename?: 'EDIDocumentConnection';
  readonly edges: ReadonlyArray<EdiDocumentGqlTypeEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Float'];
};

export type EdiDocumentFilter = {
  readonly shipmentId: Scalars['ID'];
  readonly documentTypeId: Scalars['ID'];
};

export type EdiDocumentGqlTypeEdge = {
  readonly __typename?: 'EdiDocumentGQLTypeEdge';
  readonly cursor: Scalars['String'];
  readonly node: EdiDocument;
};

export enum EdiManagementStatus {
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export type EdiManagementTransaction = {
  readonly __typename?: 'EdiManagementTransaction';
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  readonly transactionType: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly direction: EdiDirection;
  readonly processingStatus: EdiProcessingStatus;
  readonly managementStatus: EdiManagementStatus;
  readonly shipmentId?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly errorMessages?: Maybe<Scalars['String']>;
  readonly customerEdiReferenceId?: Maybe<Scalars['String']>;
  readonly output?: Maybe<EdiOutput>;
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly vendor?: Maybe<Scalars['String']>;
  readonly documentId?: Maybe<Scalars['Uuid']>;
  readonly loadV2?: Maybe<LoadV2>;
};

export type EdiManagementTransactionFilterInput = {
  readonly createdAtEarliest: Scalars['DateTime'];
  readonly createdAtLatest?: InputMaybe<Scalars['DateTime']>;
  readonly shipmentId?: InputMaybe<Scalars['String']>;
  readonly routeNumber?: InputMaybe<Scalars['String']>;
  readonly errorMessage?: InputMaybe<Scalars['String']>;
  readonly transactionTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly directions?: InputMaybe<ReadonlyArray<EdiDirection>>;
  readonly descriptions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly ediReferenceIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainCustomerRepresentativeEmployeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly processingStatuses?: InputMaybe<ReadonlyArray<EdiProcessingStatus>>;
};

export type EdiOutbox = {
  readonly __typename?: 'EdiOutbox';
  readonly id?: Maybe<Scalars['Uuid']>;
  readonly eventKey?: Maybe<Scalars['String']>;
  readonly eventValue?: Maybe<Scalars['String']>;
  readonly kafkaTopic?: Maybe<Scalars['String']>;
  readonly eventType?: Maybe<Scalars['String']>;
  readonly hashedEvent?: Maybe<Scalars['String']>;
  readonly isRetryable?: Maybe<Scalars['Boolean']>;
  readonly publishedTimestamp?: Maybe<Scalars['DateTime']>;
  readonly retryCount?: Maybe<Scalars['Int']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly ediTransactionId?: Maybe<Scalars['Uuid']>;
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  /** The ProcessingStatus will not always be set; it's value will be resolved only through the GetEdiOutboxMessagesForRetry operation. */
  readonly processingStatus?: Maybe<Scalars['String']>;
  readonly eventHeaders?: Maybe<ReadonlyArray<Scalars['Byte']>>;
};

/** The input type for new Edi Outbox Message. */
export type EdiOutboxInput = {
  readonly eventKey: Scalars['String'];
  readonly eventValue: Scalars['String'];
  readonly eventHeaders?: InputMaybe<ReadonlyArray<Scalars['Byte']>>;
  readonly kafkaTopic?: InputMaybe<Scalars['String']>;
  readonly eventType: Scalars['String'];
  readonly hashedEvent: Scalars['String'];
  readonly isRetryable: Scalars['Boolean'];
  readonly publishedTimestamp?: InputMaybe<Scalars['DateTime']>;
  readonly retryCount: Scalars['Int'];
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly ediTransactionId?: InputMaybe<Scalars['ID']>;
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type EdiOutboxMessageByHashedEventFilter = {
  readonly hashed_event: Scalars['String'];
};

export type EdiOutboxMessageByIdFilter = {
  readonly id: Scalars['ID'];
};

export type EdiOutboxMessagesForRetryFilter = {
  readonly maxRecordsToProcess: Scalars['Int'];
};

export enum EdiOutput {
  Edi = 'EDI',
  Email = 'EMAIL'
}

export type EdiPreviewData = {
  readonly __typename?: 'EDIPreviewData';
  readonly uri?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly previewData?: Maybe<Scalars['String']>;
};

export type EdiPreviewFilter = {
  readonly eDIDocumentId: Scalars['ID'];
};

export enum EdiProcessingStatus {
  InProcess = 'IN_PROCESS',
  Retrying = 'RETRYING',
  Completed = 'COMPLETED',
  Blocked = 'BLOCKED',
  Failed = 'FAILED',
  ValidationFailed = 'VALIDATION_FAILED'
}

export type EditDriverVoucherHeaderInput = {
  readonly updatedDriverVoucherHeader: DriverVoucherHeaderUpdateInput;
};

export type EditDriverVoucherHeaderOutput = {
  readonly __typename?: 'EditDriverVoucherHeaderOutput';
  readonly driverVoucherHeader: DriverVoucherHeaderType;
};

export type EdiTender = {
  readonly __typename?: 'EDITender';
  readonly id?: Maybe<Scalars['String']>;
  readonly edi_transaction_id?: Maybe<Scalars['String']>;
  readonly shipment_identification_number?: Maybe<Scalars['String']>;
  readonly scac?: Maybe<Scalars['String']>;
  readonly tender_id?: Maybe<Scalars['String']>;
  readonly created_by?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['DateTime']>;
  readonly updated_by?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['DateTime']>;
  readonly is_alchemy_tender_request?: Maybe<Scalars['Boolean']>;
  readonly tender_response?: Maybe<Scalars['String']>;
  readonly declined_reason?: Maybe<Scalars['String']>;
  readonly processingStatus?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
};

export type EdiTenderByShipmentIdAndScacFilter = {
  readonly shipmentId: Scalars['ID'];
  readonly scac: Scalars['ID'];
};

export type EdiTenderWithTransactionByShipmentIdAndScacFilter = {
  readonly shipmentId: Scalars['ID'];
  readonly scac: Scalars['ID'];
  readonly ediMessageType?: InputMaybe<Scalars['String']>;
};

export type EdiTransaction = {
  readonly __typename?: 'EDITransaction';
  readonly status?: Maybe<Scalars['String']>;
  readonly direction?: Maybe<Scalars['String']>;
  readonly vendor?: Maybe<Scalars['String']>;
  readonly edi_message_type?: Maybe<Scalars['String']>;
  readonly shipment_id?: Maybe<Scalars['String']>;
  readonly load_id?: Maybe<Scalars['String']>;
  readonly document_id?: Maybe<Scalars['String']>;
  readonly raw_message?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['DateTime']>;
  readonly created_by: Scalars['String'];
  readonly updated_at?: Maybe<Scalars['DateTime']>;
  readonly updated_by?: Maybe<Scalars['String']>;
  readonly selective_data?: Maybe<Scalars['String']>;
  readonly request_id?: Maybe<Scalars['String']>;
  readonly tracking_update_status_datetime?: Maybe<Scalars['DateTime']>;
  readonly purpose_Type?: Maybe<Scalars['String']>;
  readonly route_code?: Maybe<Scalars['String']>;
  readonly transaction_id?: Maybe<Scalars['String']>;
  readonly is_tender_request?: Maybe<Scalars['Boolean']>;
  readonly is_alert_sent?: Maybe<Scalars['Boolean']>;
  readonly is_carrier_edi_tender_request: Scalars['Boolean'];
  readonly ext_customer_identifier?: Maybe<Scalars['String']>;
  readonly output_format?: Maybe<Scalars['String']>;
  readonly status_update_datetime?: Maybe<Scalars['DateTime']>;
  readonly id?: Maybe<Scalars['Uuid']>;
  readonly remark?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  /** @deprecated Please use status instead. */
  readonly processingStatus?: Maybe<Scalars['String']>;
  /** @deprecated Please use status instead. */
  readonly statusV2?: Maybe<Scalars['String']>;
};

/** The input type for new EDI Transaction. */
export type EdiTransactionAddInput = {
  readonly edi_message_type: Scalars['String'];
  readonly shipment_id?: InputMaybe<Scalars['String']>;
  readonly route_code?: InputMaybe<Scalars['String']>;
  readonly document_id?: InputMaybe<Scalars['ID']>;
  readonly request_id?: InputMaybe<Scalars['ID']>;
  readonly load_id?: InputMaybe<Scalars['String']>;
  readonly direction?: InputMaybe<Scalars['String']>;
  readonly selective_data?: InputMaybe<Scalars['String']>;
  readonly transaction_id?: InputMaybe<Scalars['String']>;
  readonly vendor?: InputMaybe<Scalars['String']>;
  readonly tracking_update_status_datetime?: InputMaybe<Scalars['DateTime']>;
  readonly is_tender_request?: InputMaybe<Scalars['Boolean']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly created_at?: InputMaybe<Scalars['DateTime']>;
  readonly output_format?: InputMaybe<Scalars['String']>;
  readonly remark?: InputMaybe<Scalars['String']>;
  readonly is_alert_sent?: InputMaybe<Scalars['Boolean']>;
  readonly ext_customer_identifier?: InputMaybe<Scalars['String']>;
  /** processing_status is deprecated, please use statusV2 */
  readonly processing_status?: InputMaybe<Scalars['String']>;
  readonly statusV2?: InputMaybe<StatusInput>;
};

export type EdiTransactionByIdFilter = {
  readonly id: Scalars['ID'];
};

export type EdiTransactionByShipmentIdFilter = {
  readonly shipmentId: Scalars['ID'];
  readonly customerId?: InputMaybe<Scalars['String']>;
};

/** Sets the column that will be used for ordering. */
export enum EdiTransactionColumn {
  Id = 'id',
  EdiMessageType = 'edi_message_type',
  ShipmentId = 'shipment_id',
  LoadId = 'load_id',
  DocumentId = 'document_id',
  Direction = 'direction',
  SelectiveData = 'selective_data',
  CreatedBy = 'created_by',
  CreatedAt = 'created_at',
  UpdatedBy = 'updated_by',
  UpdatedAt = 'updated_at',
  Remark = 'remark',
  TrackingUpdateStatusDatetime = 'tracking_update_status_datetime',
  RequestId = 'request_id',
  IsTenderRequest = 'is_tender_request',
  IsAlertSent = 'is_alert_sent',
  RouteCode = 'route_code',
  TransactionId = 'transaction_id',
  Vendor = 'vendor',
  IsCarrierEdiTenderRequest = 'is_carrier_edi_tender_request',
  ExtCustomerIdentifier = 'ext_customer_identifier',
  Description = 'description',
  OutputFormat = 'output_format',
  ProcessingStatus = 'processing_status',
  StatusUpdateDatetime = 'status_update_datetime'
}

export enum EdiTransactionDirection {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND'
}

export type EdiTransactionFilter = {
  readonly loadId: Scalars['ID'];
  readonly ediMessageType?: InputMaybe<Scalars['String']>;
  readonly processingStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export enum EdiTransactionMessageType {
  Message204 = 'MESSAGE204',
  Message210 = 'MESSAGE210',
  Message214 = 'MESSAGE214',
  Message404 = 'MESSAGE404',
  Message990 = 'MESSAGE990',
  Message998 = 'MESSAGE998'
}

/** Sets the order of the results returned based on the column selected for ordering. */
export enum EdiTransactionOrderBy {
  Asc = 'Asc',
  Desc = 'Desc'
}

export enum EdiTransactionProcessingStatus {
  Inprocess = 'INPROCESS',
  Blocked = 'BLOCKED',
  Completed = 'COMPLETED',
  Failed = 'FAILED'
}

export type EligibleFuelProgramResponse = {
  readonly __typename?: 'EligibleFuelProgramResponse';
  readonly fuelProgram?: Maybe<Scalars['String']>;
  readonly isEligible?: Maybe<Scalars['Boolean']>;
  readonly fuelScale?: Maybe<Scalars['String']>;
};

export type EmbedParams = {
  readonly __typename?: 'EmbedParams';
  readonly embedReport?: Maybe<ReadonlyArray<Maybe<EmbedReport>>>;
  readonly dataSet?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly embedToken?: Maybe<EmbedToken>;
  readonly type: Scalars['String'];
};

/** The EmbedParams model */
export type EmbedParamsV2 = {
  readonly __typename?: 'EmbedParamsV2';
  readonly embedReport?: Maybe<ReadonlyArray<Maybe<EmbedReportV2>>>;
  readonly dataSet?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly embedToken?: Maybe<EmbedTokenV2>;
  readonly type: Scalars['String'];
};

export type EmbedReport = {
  readonly __typename?: 'EmbedReport';
  readonly embedUrl: Scalars['String'];
  readonly reportId: Scalars['ID'];
  readonly reportName: Scalars['String'];
};

/** The EmbedReportFilterTarget model */
export type EmbedReportFilterTargetV2 = {
  readonly __typename?: 'EmbedReportFilterTargetV2';
  /** The targetted table for the filter */
  readonly table: Scalars['String'];
  /** The targetted column within the targetted table */
  readonly column: Scalars['String'];
};

/**
 * The EmbedReportFilter model.
 *
 * For more information, please see: https://learn.microsoft.com/en-us/javascript/api/overview/powerbi/control-report-filters
 */
export type EmbedReportFilterV2 = {
  readonly __typename?: 'EmbedReportFilterV2';
  /**
   * This is the $schema for the PowerBi Client. It should be adjusted on the frontend to $schema.
   *
   * This should match the value set for filterType
   */
  readonly schema: Scalars['String'];
  /** Filter operator, possible values change depending on the filter type. Examples include: In, NotIn, All */
  readonly operator: Scalars['String'];
  /** Used to target a table and column for the filter */
  readonly target: EmbedReportFilterTargetV2;
  /** List of values to be used with the filter. Should be appended to by the frontend. */
  readonly values: ReadonlyArray<Scalars['String']>;
  /** The value of the FilterType enum from powerbi-models on npm. Basic is 1 */
  readonly filterType: Scalars['Float'];
  /** If the filter should be a basic, single option selection filter. */
  readonly requireSingleSelection: Scalars['Boolean'];
};

/** The EmbedReport model */
export type EmbedReportV2 = {
  readonly __typename?: 'EmbedReportV2';
  readonly embedUrl: Scalars['String'];
  readonly reportId: Scalars['ID'];
  readonly reportName: Scalars['String'];
  /** The base filter for the report. Should be combined with data appropriately by the frontend/clients */
  readonly filter: EmbedReportFilterV2;
};

export type EmbedToken = {
  readonly __typename?: 'EmbedToken';
  readonly token: Scalars['String'];
  readonly tokenId: Scalars['ID'];
  readonly expiration: Scalars['DateTime'];
};

/** The EmbedToken model */
export type EmbedTokenV2 = {
  readonly __typename?: 'EmbedTokenV2';
  readonly token: Scalars['String'];
  readonly tokenId: Scalars['ID'];
  readonly expiration: Scalars['Timestamp'];
};

export type Employee = {
  readonly __typename?: 'Employee';
  readonly id: Scalars['ID'];
  /** @deprecated temporary field, to toggle display of 'admin' tab */
  readonly adminVisible?: Maybe<Scalars['Boolean']>;
  readonly children?: Maybe<ReadonlyArray<Employee>>;
  readonly commissionBasis?: Maybe<KeyValue>;
  readonly commissionBasisId?: Maybe<Scalars['ID']>;
  readonly commissionType?: Maybe<KeyValue>;
  readonly commissionTypeId?: Maybe<Scalars['ID']>;
  readonly companyEmail?: Maybe<Scalars['String']>;
  readonly compensationPeriod?: Maybe<KeyValue>;
  readonly compensationPeriodId?: Maybe<Scalars['ID']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly division?: Maybe<KeyValue>;
  readonly divisionId?: Maybe<Scalars['ID']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly emergencyContact?: Maybe<Scalars['String']>;
  readonly emergencyPhone?: Maybe<Scalars['String']>;
  readonly employeeCompanyLevel?: Maybe<KeyValue>;
  readonly employeeCompanyLevelId?: Maybe<Scalars['ID']>;
  readonly employeeDepartment?: Maybe<KeyValue>;
  readonly employeeDepartmentId?: Maybe<Scalars['ID']>;
  readonly employeeDepartmentLevel?: Maybe<KeyValue>;
  readonly employeeDepartmentLevelId?: Maybe<Scalars['ID']>;
  readonly employeeDisplayName: Scalars['String'];
  readonly employeeGender?: Maybe<KeyValue>;
  readonly employeeGenderId?: Maybe<Scalars['ID']>;
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly employeeGroupId?: Maybe<Scalars['ID']>;
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly employeeOffice?: Maybe<KeyValue>;
  readonly employeeOfficeId?: Maybe<Scalars['ID']>;
  readonly employeeRole?: Maybe<KeyValue>;
  readonly employeeRoleId?: Maybe<Scalars['ID']>;
  readonly employeeShirtSize?: Maybe<KeyValue>;
  readonly employeeShirtSizeId?: Maybe<Scalars['ID']>;
  readonly employeeStatus: KeyValue;
  readonly employeeStatusId: Scalars['ID'];
  readonly employeeSuffix?: Maybe<KeyValue>;
  readonly employeeSuffixId?: Maybe<Scalars['ID']>;
  readonly employeeTime?: Maybe<KeyValue>;
  readonly employeeTimeId?: Maybe<Scalars['ID']>;
  readonly employeeType?: Maybe<KeyValue>;
  readonly employeeTypeId?: Maybe<Scalars['ID']>;
  /** @deprecated temporary field, to toggle display of 'employee' tab */
  readonly employeeVisible?: Maybe<Scalars['Boolean']>;
  readonly ext?: Maybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly firstNameDotLastName: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly hireDate?: Maybe<Scalars['ISO8601Date']>;
  readonly lastName: Scalars['String'];
  readonly manager?: Maybe<Employee>;
  readonly managerId?: Maybe<Scalars['ID']>;
  readonly middleName?: Maybe<Scalars['String']>;
  readonly mobilePhone?: Maybe<Scalars['String']>;
  readonly nickname?: Maybe<Scalars['String']>;
  readonly parent?: Maybe<Employee>;
  readonly payTrigger?: Maybe<KeyValue>;
  readonly payTriggerId?: Maybe<Scalars['ID']>;
  readonly personalEmail?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly relationship?: Maybe<Scalars['String']>;
  readonly siblings?: Maybe<ReadonlyArray<Employee>>;
  readonly slackName?: Maybe<Scalars['String']>;
  readonly subRegion?: Maybe<Scalars['String']>;
  readonly termDate?: Maybe<Scalars['ISO8601Date']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
  readonly userId?: Maybe<Scalars['ID']>;
};

export type EmployeeAdditionalDivisions = {
  readonly __typename?: 'EmployeeAdditionalDivisions';
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly employeeId: Scalars['String'];
  readonly additionalDivisionId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type EmployeeAudit = {
  readonly __typename?: 'EmployeeAudit';
  readonly id: Scalars['ID'];
  readonly auditableId?: Maybe<Scalars['ID']>;
  readonly associated_id?: Maybe<Scalars['ID']>;
  readonly associatedType?: Maybe<Scalars['String']>;
  readonly userId?: Maybe<Scalars['ID']>;
  readonly userType?: Maybe<Scalars['String']>;
  readonly auditableTab?: Maybe<Scalars['String']>;
  readonly auditCategory?: Maybe<Scalars['String']>;
  readonly remoteAddress?: Maybe<Scalars['String']>;
  readonly requestUuid?: Maybe<Scalars['String']>;
  readonly version?: Maybe<Scalars['Int']>;
  readonly comment?: Maybe<Scalars['String']>;
  readonly auditDetails?: Maybe<ReadonlyArray<EmployeeAuditDetails>>;
  readonly createdAt: Scalars['ISO8601Date'];
  readonly updatedAt: Scalars['ISO8601Date'];
  /** user deprecated as per ME-201394 */
  readonly user?: Maybe<UserV2>;
  readonly employee?: Maybe<AuditEmployeeV2>;
  readonly actionWithFieldName?: Maybe<Scalars['String']>;
  readonly previousValues?: Maybe<Scalars['String']>;
  readonly updatedValues?: Maybe<Scalars['String']>;
};

export type EmployeeAuditDetails = {
  readonly __typename?: 'EmployeeAuditDetails';
  readonly auditDetailId?: Maybe<Scalars['ID']>;
  readonly auditLogId?: Maybe<Scalars['ID']>;
  readonly action?: Maybe<Scalars['String']>;
  readonly fieldName?: Maybe<Scalars['String']>;
  readonly previousValue?: Maybe<Scalars['String']>;
  readonly updatedValue?: Maybe<Scalars['String']>;
};

/** An edge in audit connection. */
export type EmployeeAuditEdge = {
  readonly __typename?: 'EmployeeAuditEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: EmployeeAudit;
};

/** The connection type for Audits. */
export type EmployeeAudits = {
  readonly __typename?: 'EmployeeAudits';
  /** A list of edges. */
  readonly edges: ReadonlyArray<EmployeeAuditEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type EmployeeAuditsFilter = {
  readonly startDate: Scalars['String'];
  readonly endDate: Scalars['String'];
  readonly employeeId: Scalars['String'];
};

export type EmployeeCommissionConfigurationAssignmentInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly employeeId?: InputMaybe<Scalars['ID']>;
  readonly employeeCommissionPlanConfigurationHeaderId?: InputMaybe<Scalars['ID']>;
  readonly effectiveStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly effectiveEndDate?: InputMaybe<Scalars['DateTime']>;
};

export type EmployeeCommissionPlan = {
  readonly __typename?: 'EmployeeCommissionPlan';
  /** @deprecated This field is not a valid field at this time.  There is no id associated with this. */
  readonly id: Scalars['ID'];
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly employeeCommissionPlanConfigurationAssignments?: Maybe<ReadonlyArray<Maybe<EmployeeCommissionPlanConfigurationAssignment>>>;
};

export type EmployeeCommissionPlanAssignmentsInput = {
  readonly planHeaderId: Scalars['ID'];
  readonly employeeIds: ReadonlyArray<InputMaybe<Scalars['ID']>>;
};

export type EmployeeCommissionPlanConfiguration = {
  readonly __typename?: 'EmployeeCommissionPlanConfiguration';
  readonly id: Scalars['ID'];
  readonly commissionPlanName?: Maybe<Scalars['String']>;
  readonly marginBasisId?: Maybe<Scalars['String']>;
  readonly effectiveStartDate?: Maybe<Scalars['DateTime']>;
  readonly effectiveEndDate?: Maybe<Scalars['DateTime']>;
  readonly employeeId?: Maybe<Scalars['ID']>;
  readonly isGlobal: Scalars['Boolean'];
  readonly planAppliesToId?: Maybe<Scalars['String']>;
  readonly customerRepTypeId?: Maybe<Scalars['String']>;
  readonly carrierRepTypeId?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly employeeCommissionPlanConfigurationDetails?: Maybe<ReadonlyArray<Maybe<EmployeeCommissionPlanConfigurationDetail>>>;
};

export type EmployeeCommissionPlanConfigurationAssignment = {
  readonly __typename?: 'EmployeeCommissionPlanConfigurationAssignment';
  readonly id: Scalars['ID'];
  readonly employeeId?: Maybe<Scalars['ID']>;
  readonly effectiveStartDate?: Maybe<Scalars['DateTime']>;
  readonly effectiveEndDate?: Maybe<Scalars['DateTime']>;
  readonly employeeCommissionPlanConfigurationHeader?: Maybe<EmployeeCommissionPlanConfiguration>;
};

export type EmployeeCommissionPlanConfigurationDetail = {
  readonly __typename?: 'EmployeeCommissionPlanConfigurationDetail';
  readonly id: Scalars['ID'];
  readonly loadSizeId?: Maybe<Scalars['String']>;
  readonly transportModeId?: Maybe<Scalars['String']>;
  readonly divisionId?: Maybe<Scalars['String']>;
  readonly commissionBasisId?: Maybe<Scalars['String']>;
  readonly marginLowerBoundPercent?: Maybe<Scalars['Decimal']>;
  readonly marginUpperBoundPercent?: Maybe<Scalars['Decimal']>;
  readonly commissionPercent?: Maybe<Scalars['Decimal']>;
  readonly payTriggerId?: Maybe<Scalars['String']>;
  readonly flatAmount?: Maybe<Scalars['Decimal']>;
  readonly commissionPercentMinAmount?: Maybe<Scalars['Decimal']>;
  readonly commissionPercentMaxAmount?: Maybe<Scalars['Decimal']>;
};

export type EmployeeCommissionPlanConfigurationDetailInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly loadSizeId?: InputMaybe<Scalars['String']>;
  readonly transportModeId?: InputMaybe<Scalars['String']>;
  readonly divisionId?: InputMaybe<Scalars['String']>;
  readonly commissionBasisId?: InputMaybe<Scalars['String']>;
  readonly marginLowerBoundPercent?: InputMaybe<Scalars['Decimal']>;
  readonly marginUpperBoundPercent?: InputMaybe<Scalars['Decimal']>;
  readonly commissionPercent?: InputMaybe<Scalars['Decimal']>;
  readonly payTriggerId?: InputMaybe<Scalars['String']>;
  readonly flatAmount?: InputMaybe<Scalars['Decimal']>;
  readonly commissionPercentMinAmount?: InputMaybe<Scalars['Decimal']>;
  readonly commissionPercentMaxAmount?: InputMaybe<Scalars['Decimal']>;
};

export type EmployeeCommissionPlanConfigurationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly commissionPlanName?: InputMaybe<Scalars['String']>;
  readonly carrierRepTypeId?: InputMaybe<Scalars['String']>;
  readonly customerRepTypeId?: InputMaybe<Scalars['String']>;
  readonly planAppliesToId?: InputMaybe<Scalars['String']>;
  readonly marginBasisId?: InputMaybe<Scalars['String']>;
  readonly effectiveStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly effectiveEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly employeeId?: InputMaybe<Scalars['ID']>;
  readonly isGlobal: Scalars['Boolean'];
  readonly employeeCommissionPlanConfigurationDetails?: InputMaybe<ReadonlyArray<InputMaybe<EmployeeCommissionPlanConfigurationDetailInput>>>;
};

export type EmployeeCommissionPlanFilterInput = {
  readonly employeeId: Scalars['ID'];
};

export type EmployeeCommissionPlanInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly employeeCommissionPlanConfigurationAssignments?: InputMaybe<ReadonlyArray<InputMaybe<EmployeeCommissionConfigurationAssignmentInput>>>;
};

/** The connection type for Employee. */
export type EmployeeConnection = {
  readonly __typename?: 'EmployeeConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<EmployeeEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** The connection type for Employee. */
export type EmployeeConnectionV2 = {
  readonly __typename?: 'EmployeeConnectionV2';
  /** A list of edges. */
  readonly edges: ReadonlyArray<EmployeeEdgeV2>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Float'];
};

/** An edge in a connection. */
export type EmployeeEdge = {
  readonly __typename?: 'EmployeeEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: Employee;
};

/** An edge in a connection. */
export type EmployeeEdgeV2 = {
  readonly __typename?: 'EmployeeEdgeV2';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: EmployeeV2;
};

export type EmployeeFilter = {
  readonly companyEmail?: InputMaybe<Scalars['String']>;
  readonly employeeDisplayName?: InputMaybe<Scalars['String']>;
  readonly employeeStatus?: InputMaybe<Scalars['String']>;
  readonly externalEmployeeId?: InputMaybe<Scalars['String']>;
  readonly firstNameLastNameOrEmail?: InputMaybe<Scalars['String']>;
  readonly user?: InputMaybe<UserFilter>;
};

export type EmployeeFilterV2 = {
  readonly allDivisions?: InputMaybe<Scalars['Boolean']>;
  readonly companyEmail?: InputMaybe<Scalars['String']>;
  readonly employeeDisplayName?: InputMaybe<Scalars['String']>;
  readonly employeeStatus?: InputMaybe<Scalars['String']>;
  readonly externalEmployeeId?: InputMaybe<Scalars['String']>;
  readonly firstNameLastNameOrEmail?: InputMaybe<Scalars['String']>;
  readonly user?: InputMaybe<UserFilterV2>;
  readonly sort?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  /** The field `employeeDivisions` is deprecated */
  readonly employeeDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** The field `employeeOrganizations` is deprecated replacement flow is not yet defined */
  readonly employeeOrganizations?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly employeeAdditionalDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** employeeBusinessUnits deprecated as per ME-148123 */
  readonly employeeBusinessUnits?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly employeeGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Use instead employeeDepartments */
  readonly employeeDepartment?: InputMaybe<Scalars['String']>;
  /** Use instead employeeOffices */
  readonly employeeOffice?: InputMaybe<Scalars['String']>;
  readonly employeeDepartments?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly employeeOffices?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly managerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type EmployeeInput = {
  readonly commissionBasisId?: InputMaybe<Scalars['ID']>;
  readonly commissionTypeId?: InputMaybe<Scalars['ID']>;
  readonly companyEmail?: InputMaybe<Scalars['String']>;
  readonly compensationPeriodId?: InputMaybe<Scalars['ID']>;
  readonly divisionId?: InputMaybe<Scalars['ID']>;
  readonly email?: InputMaybe<Scalars['String']>;
  readonly emergencyContact?: InputMaybe<Scalars['String']>;
  readonly emergencyPhone?: InputMaybe<Scalars['String']>;
  readonly employeeCompanyLevelId?: InputMaybe<Scalars['ID']>;
  readonly employeeDepartmentId?: InputMaybe<Scalars['ID']>;
  readonly employeeDepartmentLevelId?: InputMaybe<Scalars['ID']>;
  readonly employeeDisplayName?: InputMaybe<Scalars['String']>;
  readonly employeeGenderId?: InputMaybe<Scalars['ID']>;
  readonly employeeGroupId?: InputMaybe<Scalars['ID']>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly employeeOfficeId?: InputMaybe<Scalars['ID']>;
  readonly employeeRoleId?: InputMaybe<Scalars['ID']>;
  readonly employeeShirtSizeId?: InputMaybe<Scalars['ID']>;
  readonly employeeStatusId?: InputMaybe<Scalars['ID']>;
  readonly employeeSuffixId?: InputMaybe<Scalars['ID']>;
  readonly employeeTimeId?: InputMaybe<Scalars['ID']>;
  readonly employeeTypeId?: InputMaybe<Scalars['ID']>;
  readonly ext?: InputMaybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly hireDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly lastName: Scalars['String'];
  readonly managerId?: InputMaybe<Scalars['ID']>;
  readonly middleName?: InputMaybe<Scalars['String']>;
  readonly mobilePhone?: InputMaybe<Scalars['String']>;
  readonly nickname?: InputMaybe<Scalars['String']>;
  readonly payTriggerId?: InputMaybe<Scalars['ID']>;
  readonly personalEmail?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly relationship?: InputMaybe<Scalars['String']>;
  readonly slackName?: InputMaybe<Scalars['String']>;
  readonly subRegion?: InputMaybe<Scalars['String']>;
  readonly termDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type EmployeeInputTypeExternal = {
  readonly companyEmail: Scalars['String'];
  readonly employeeDepartmentName?: InputMaybe<Scalars['String']>;
  readonly employeeDisplayName?: InputMaybe<Scalars['String']>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly employeeOfficeName?: InputMaybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly hireDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly isUserActive?: InputMaybe<Scalars['Boolean']>;
  readonly lastName: Scalars['String'];
  readonly managerUserEmail?: InputMaybe<Scalars['String']>;
  readonly mobilePhone?: InputMaybe<Scalars['String']>;
  readonly nickname?: InputMaybe<Scalars['String']>;
  readonly personalEmail?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly title?: InputMaybe<Scalars['String']>;
  readonly userEmail: Scalars['String'];
};

export type EmployeeInputTypeExternalV2 = {
  readonly companyEmail: Scalars['String'];
  readonly employeeDepartmentName?: InputMaybe<Scalars['String']>;
  readonly employeeDisplayName?: InputMaybe<Scalars['String']>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly employeeOfficeName?: InputMaybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly hireDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly isUserActive?: InputMaybe<Scalars['Boolean']>;
  readonly lastName: Scalars['String'];
  readonly managerUserEmail?: InputMaybe<Scalars['String']>;
  readonly mobilePhone?: InputMaybe<Scalars['String']>;
  readonly nickname?: InputMaybe<Scalars['String']>;
  readonly personalEmail?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly title?: InputMaybe<Scalars['String']>;
  readonly userEmail: Scalars['String'];
};

export type EmployeeInputV2 = {
  readonly allDivisions?: InputMaybe<Scalars['Boolean']>;
  /** The field `businessUnitId` is deprecated */
  readonly businessUnitId?: InputMaybe<Scalars['ID']>;
  readonly commissionBasisId?: InputMaybe<Scalars['ID']>;
  readonly commissionTypeId?: InputMaybe<Scalars['ID']>;
  readonly companyEmail?: InputMaybe<Scalars['String']>;
  readonly compensationPeriodId?: InputMaybe<Scalars['ID']>;
  readonly divisionId?: InputMaybe<Scalars['ID']>;
  readonly email?: InputMaybe<Scalars['String']>;
  readonly emergencyContact?: InputMaybe<Scalars['String']>;
  readonly emergencyPhone?: InputMaybe<Scalars['String']>;
  readonly employeeAdditionalDivisionIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly employeeCompanyLevelId?: InputMaybe<Scalars['ID']>;
  readonly employeeDepartmentId?: InputMaybe<Scalars['ID']>;
  readonly employeeDepartmentLevelId?: InputMaybe<Scalars['ID']>;
  readonly employeeDisplayName?: InputMaybe<Scalars['String']>;
  readonly employeeGenderId?: InputMaybe<Scalars['ID']>;
  readonly employeeGroupId?: InputMaybe<Scalars['ID']>;
  readonly employeeGroupIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly employeeDivisionIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** The field `employeeBusinessUnitIds` is deprecated */
  readonly employeeBusinessUnitIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly employeeProjectIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly employeeOfficeId?: InputMaybe<Scalars['ID']>;
  readonly employeeRoleId?: InputMaybe<Scalars['ID']>;
  readonly employeeShirtSizeId?: InputMaybe<Scalars['ID']>;
  readonly employeeStatusId?: InputMaybe<Scalars['ID']>;
  readonly employeeSuffixId?: InputMaybe<Scalars['ID']>;
  readonly employeeTimeId?: InputMaybe<Scalars['ID']>;
  readonly employeeTypeId?: InputMaybe<Scalars['ID']>;
  readonly ext?: InputMaybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly hireDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly lastName: Scalars['String'];
  readonly managerId?: InputMaybe<Scalars['ID']>;
  readonly middleName?: InputMaybe<Scalars['String']>;
  readonly mobilePhone?: InputMaybe<Scalars['String']>;
  readonly nickname?: InputMaybe<Scalars['String']>;
  readonly payTriggerId?: InputMaybe<Scalars['ID']>;
  /** The field `projectId` is deprecated. Use `employeeProjectIds` instead. */
  readonly projectId?: InputMaybe<Scalars['ID']>;
  readonly personalEmail?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly relationship?: InputMaybe<Scalars['String']>;
  readonly slackName?: InputMaybe<Scalars['String']>;
  readonly subRegion?: InputMaybe<Scalars['String']>;
  readonly termDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly title?: InputMaybe<Scalars['String']>;
  readonly messagingUserIdentities?: InputMaybe<ReadonlyArray<MessagingV2Input>>;
};

export enum EmployeeMessagingTypeEnum {
  /** Slack */
  Slack = 'Slack',
  /** MSTeams */
  MsTeams = 'MSTeams'
}

export type EmployeePostingCredentials = {
  readonly __typename?: 'EmployeePostingCredentials';
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly employeeId: Scalars['String'];
  readonly credentialProvider?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly userName: Scalars['String'];
  readonly password?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type EmployeePostingCredentialsInput = {
  readonly createdAt?: InputMaybe<Scalars['String']>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly employeeId: Scalars['String'];
  readonly credentialProvider: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly userName: Scalars['String'];
  readonly password?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['String']>;
  readonly updatedByUserId?: InputMaybe<Scalars['ID']>;
};

export type EmployeeProject = {
  readonly __typename?: 'EmployeeProject';
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly employeeId: Scalars['String'];
  readonly employeeProject?: Maybe<Project>;
  readonly employeeProjectId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type Employees = {
  readonly __typename?: 'Employees';
  readonly id: Scalars['ID'];
  readonly groupId?: Maybe<Scalars['ID']>;
  readonly employeeId?: Maybe<Scalars['ID']>;
};

export type EmployeeV2 = {
  readonly __typename?: 'EmployeeV2';
  readonly id: Scalars['ID'];
  readonly allDivisions: Scalars['Boolean'];
  /** @deprecated temporary field, to toggle display of "admin" tab */
  readonly adminVisible?: Maybe<Scalars['Boolean']>;
  readonly children?: Maybe<ReadonlyArray<EmployeeV2>>;
  /** @deprecated removing business unit from all places */
  readonly businessUnit?: Maybe<KeyValue>;
  /** @deprecated removing business unit from all places */
  readonly businessUnitId?: Maybe<Scalars['ID']>;
  readonly commissionBasis?: Maybe<KeyValue>;
  readonly commissionBasisId?: Maybe<Scalars['ID']>;
  readonly commissionType?: Maybe<KeyValue>;
  readonly commissionTypeId?: Maybe<Scalars['ID']>;
  readonly companyEmail?: Maybe<Scalars['String']>;
  readonly compensationPeriod?: Maybe<KeyValue>;
  readonly compensationPeriodId?: Maybe<Scalars['ID']>;
  readonly division?: Maybe<KeyValue>;
  readonly divisionId?: Maybe<Scalars['ID']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly emergencyContact?: Maybe<Scalars['String']>;
  readonly emergencyPhone?: Maybe<Scalars['String']>;
  readonly employeeAdditionalDivisions?: Maybe<ReadonlyArray<Maybe<EmployeeAdditionalDivisions>>>;
  readonly employeeCompanyLevel?: Maybe<KeyValue>;
  readonly employeeCompanyLevelId?: Maybe<Scalars['ID']>;
  readonly employeeDepartment?: Maybe<KeyValue>;
  readonly employeeDepartmentId?: Maybe<Scalars['ID']>;
  readonly employeeDepartmentLevel?: Maybe<KeyValue>;
  readonly employeeDepartmentLevelId?: Maybe<Scalars['ID']>;
  readonly employeeDisplayName: Scalars['String'];
  readonly employeeGender?: Maybe<KeyValue>;
  readonly employeeGenderId?: Maybe<Scalars['ID']>;
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly employeeGroupId?: Maybe<Scalars['ID']>;
  readonly employeeGroups?: Maybe<ReadonlyArray<KeyValue>>;
  readonly employeeDivisions?: Maybe<ReadonlyArray<KeyValue>>;
  /** @deprecated removing business unit from all places */
  readonly employeeBusinessUnits?: Maybe<ReadonlyArray<KeyValue>>;
  readonly employeeProjects?: Maybe<ReadonlyArray<Maybe<EmployeeProject>>>;
  readonly employeeUserDetail?: Maybe<UserV2>;
  readonly messagingUserIdentities?: Maybe<ReadonlyArray<MessagingV2>>;
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly employeeOffice?: Maybe<KeyValue>;
  readonly employeeOfficeId?: Maybe<Scalars['ID']>;
  readonly employeeRole?: Maybe<KeyValue>;
  readonly employeeRoleId?: Maybe<Scalars['ID']>;
  readonly employeeShirtSize?: Maybe<KeyValue>;
  readonly employeeShirtSizeId?: Maybe<Scalars['ID']>;
  readonly employeeStatus: KeyValue;
  readonly employeeStatusId: Scalars['ID'];
  readonly employeeSuffix?: Maybe<KeyValue>;
  readonly employeeSuffixId?: Maybe<Scalars['ID']>;
  readonly employeeTime?: Maybe<KeyValue>;
  readonly employeeTimeId?: Maybe<Scalars['ID']>;
  readonly employeeType?: Maybe<KeyValue>;
  readonly employeeTypeId?: Maybe<Scalars['ID']>;
  /** @deprecated temporary field, to toggle display of "employee" tab */
  readonly employeeVisible?: Maybe<Scalars['Boolean']>;
  readonly ext?: Maybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly firstNameDotLastName: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly hireDate?: Maybe<Scalars['ISO8601Date']>;
  readonly lastName: Scalars['String'];
  readonly manager?: Maybe<EmployeeV2>;
  readonly managerId?: Maybe<Scalars['ID']>;
  readonly middleName?: Maybe<Scalars['String']>;
  readonly mobilePhone?: Maybe<Scalars['String']>;
  readonly nickname?: Maybe<Scalars['String']>;
  readonly parent?: Maybe<EmployeeV2>;
  readonly payTrigger?: Maybe<KeyValue>;
  readonly payTriggerId?: Maybe<Scalars['ID']>;
  /** @deprecated Use `employeeProjects` instead. */
  readonly project?: Maybe<KeyValue>;
  /** @deprecated Use `employeeProjects` instead. */
  readonly projectId?: Maybe<Scalars['ID']>;
  readonly personalEmail?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly relationship?: Maybe<Scalars['String']>;
  readonly siblings?: Maybe<ReadonlyArray<EmployeeV2>>;
  readonly slackName?: Maybe<Scalars['String']>;
  readonly subRegion?: Maybe<Scalars['String']>;
  readonly termDate?: Maybe<Scalars['ISO8601Date']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly userId?: Maybe<Scalars['ID']>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdAt: Scalars['ISO8601Date'];
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedAt: Scalars['ISO8601Date'];
};

export type EmptyLocation = {
  readonly __typename?: 'EmptyLocation';
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly timezone?: Maybe<Scalars['String']>;
};

export type EntityArgumentInput = {
  readonly code?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type EntityArrivalGeofencing = {
  readonly __typename?: 'EntityArrivalGeofencing';
  readonly facilityEnabled?: Maybe<Scalars['Boolean']>;
};

export type EntityArrivalGeofencingInput = {
  readonly facilityEnabled?: InputMaybe<Scalars['Boolean']>;
};

/** Entity Configuration Audit Object */
export type EntityAudit = {
  readonly __typename?: 'EntityAudit';
  readonly changes?: Maybe<ReadonlyArray<ChangeObject>>;
  readonly type: ChangeType;
  readonly updatedAt: Scalars['String'];
  readonly updatedBy: Scalars['String'];
};

/** The Key Component of an entity key value pair */
export type EntityConfigKeyInput = {
  /** Tenant attribute for an entity key value pair */
  readonly tenant: Scalars['String'];
  /** Domain attribute for an entity key value pair */
  readonly domain: Scalars['String'];
  /** SubDomain attribute for an entity key value pair */
  readonly subDomain: Scalars['String'];
  /** Key attribute for an entity key value pair */
  readonly key: Scalars['String'];
};

/** Entity Configuration Details Object */
export type EntityConfiguration = {
  readonly __typename?: 'EntityConfiguration';
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly customFallbackMapping: ReadonlyArray<FallbackMapping>;
  readonly ddtId: Scalars['String'];
  readonly entityId: Scalars['String'];
  readonly entityOptions: ReadonlyArray<DdtEntityConfigurationOptions>;
  readonly entityType: DdtEntityType;
  readonly fallbackOption?: Maybe<FallbackOption>;
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
};

export type EntityConfigurationApplied = {
  readonly __typename?: 'EntityConfigurationApplied';
  readonly provider?: Maybe<MileageProvider>;
  readonly distanceSearchPreference?: Maybe<ReadonlyArray<Maybe<DistanceSearchPreferenceOption>>>;
  readonly options?: Maybe<MileageProviderOptions>;
};

/** The domain this configuration is relevant for (Mileage, Data Cleansing, etc) */
export enum EntityConfigurationDomain {
  Mileage = 'mileage'
}

/** The type of entity this configuration is for (Customer, Carrier) */
export enum EntityConfigurationEntityType {
  Customer = 'customer',
  Carrier = 'carrier'
}

export type EntityConfigurationResult = {
  readonly __typename?: 'EntityConfigurationResult';
  readonly uuid?: Maybe<Scalars['ID']>;
  readonly entityType: EntityConfigurationEntityType;
  /** The id of the entity this belongs to, validated against Records Api to ensure exists */
  readonly entityId: Scalars['ID'];
  readonly domain: EntityConfigurationDomain;
  /** The value of the configuration itself, shape is determined by domain */
  readonly settings: EntityConfigurationSettings;
  readonly effectiveDate: Scalars['String'];
  readonly expirationDate?: Maybe<Scalars['String']>;
  /** The last updated timestamp when the configuration was modified */
  readonly lastUpdatedTimestamp?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
};

export type EntityConfigurationSettings = MileageSettings;

export type EntityDepartureGeofencing = {
  readonly __typename?: 'EntityDepartureGeofencing';
  readonly facilityEnabled?: Maybe<Scalars['Boolean']>;
};

export type EntityDepartureGeofencingInput = {
  readonly facilityEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type EntityDocument = {
  readonly __typename?: 'EntityDocument';
  readonly id: Scalars['ID'];
  /** The name of the file to be displayed to the user, not necessarily the filename */
  readonly name: Scalars['String'];
  /** The name of the file provided during upload */
  readonly filename: Scalars['String'];
  readonly externalDocumentId: Scalars['String'];
  readonly uploadedByUserId: Scalars['String'];
  readonly documentTypeId: Scalars['String'];
  readonly updatedByUserId: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  /** uri to access the document */
  readonly uri: Scalars['String'];
  /** uri to access the signed document */
  readonly signedUri?: Maybe<Scalars['String']>;
  /** MasterSign Reference Id */
  readonly masterSignReferenceId?: Maybe<Scalars['String']>;
  /** Identification for MasterSigner */
  readonly signedBy?: Maybe<Scalars['String']>;
  /** Timestamp for when document was signed */
  readonly signedAt?: Maybe<Scalars['DateTime']>;
  /**
   * Document Status
   * @deprecated Depcrecated in favor of documentStatus
   */
  readonly status?: Maybe<Scalars['String']>;
  /** Document Status */
  readonly documentStatus?: Maybe<DocumentStatusEnum>;
  /** Previous Document Status */
  readonly previousDocumentStatus?: Maybe<DocumentStatusEnum>;
  /** Identification for External Uploader */
  readonly externalUser?: Maybe<Scalars['String']>;
  readonly uploadedByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
};

export type EntityGeofenceDetails = {
  readonly __typename?: 'EntityGeofenceDetails';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly perspective: EntityGeofencePerspective;
  readonly method: EntityGeofencingMethods;
  readonly geoPoint?: Maybe<GeographyServicePoint>;
  readonly unit?: Maybe<EntityGeofenceDistanceUnit>;
  readonly radius?: Maybe<Scalars['Float']>;
  readonly polygons?: Maybe<ReadonlyArray<Maybe<GeographyServicePoint>>>;
};

export type EntityGeofenceDetailsInput = {
  readonly id: Scalars['ID'];
  readonly name?: InputMaybe<Scalars['String']>;
  readonly perspective: EntityGeofencePerspective;
  readonly method: EntityGeofencingMethods;
  readonly geoPoint?: InputMaybe<GeoPointInput>;
  readonly unit?: InputMaybe<EntityGeofenceDistanceUnit>;
  readonly radius?: InputMaybe<Scalars['Float']>;
  readonly polygons?: InputMaybe<ReadonlyArray<InputMaybe<GeoPointInput>>>;
};

export enum EntityGeofenceDistanceUnit {
  Mi = 'mi',
  Km = 'km'
}

export type EntityGeofenceLocation = {
  readonly __typename?: 'EntityGeofenceLocation';
  readonly name: Scalars['String'];
  readonly locationPerspective?: Maybe<EntityGeofencingLocationPerspectives>;
  readonly radius?: Maybe<Scalars['Float']>;
  readonly distanceType?: Maybe<EntityGeofencingLocationDistanceTypes>;
  readonly geoPoint?: Maybe<GeographyServicePoint>;
  readonly polygons?: Maybe<ReadonlyArray<Maybe<GeographyServicePoint>>>;
};

export type EntityGeofenceLocationInput = {
  readonly name: Scalars['String'];
  readonly locationPerspective?: InputMaybe<EntityGeofencingLocationPerspectives>;
  readonly radius?: InputMaybe<Scalars['Float']>;
  readonly distanceType?: InputMaybe<EntityGeofencingLocationDistanceTypes>;
  readonly geoPoint?: InputMaybe<GeoPointInput>;
  readonly polygons?: InputMaybe<ReadonlyArray<InputMaybe<GeoPointInput>>>;
};

export enum EntityGeofencePerspective {
  Arrival = 'arrival',
  Departure = 'departure'
}

export type EntityGeofencingCustomerRecord = {
  readonly __typename?: 'EntityGeofencingCustomerRecord';
  readonly tenantId: Scalars['String'];
  readonly customerId: Scalars['ID'];
  readonly arrivalGeofencing?: Maybe<EntityArrivalGeofencing>;
  readonly departureGeofencing?: Maybe<EntityDepartureGeofencing>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdBy: Scalars['String'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly recordExists?: Maybe<Scalars['Boolean']>;
  readonly globalSettings?: Maybe<MinionGlobalGeofencingSettings>;
};

export type EntityGeofencingCustomerRecordDeleteInput = {
  readonly tenantId: Scalars['String'];
  readonly customerId: Scalars['ID'];
};

export type EntityGeofencingCustomerRecordInput = {
  readonly tenantId: Scalars['String'];
  readonly customerId: Scalars['ID'];
  readonly arrivalGeofencing?: InputMaybe<EntityArrivalGeofencingInput>;
  readonly departureGeofencing?: InputMaybe<EntityDepartureGeofencingInput>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

/** Input object for a customer geofence search */
export type EntityGeofencingCustomerSearchInput = {
  /** The tenantId of the customer geofence record */
  readonly tenantId: Scalars['String'];
  /** The customerId of the customer geofence record */
  readonly customerId: Scalars['ID'];
  /** Use minion fallback when defaulting to a customer geofence record */
  readonly useMinionFallback?: InputMaybe<Scalars['Boolean']>;
};

export type EntityGeofencingLocation = {
  readonly __typename?: 'EntityGeofencingLocation';
  readonly locationId: Scalars['String'];
  readonly customerId?: Maybe<Scalars['String']>;
  readonly geofencingMethod?: Maybe<EntityGeofencingMethods>;
  readonly locationPerspective?: Maybe<EntityGeofencingLocationPerspectives>;
  readonly geofences: ReadonlyArray<Maybe<EntityGeofenceLocation>>;
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  /** @deprecated Use arrivalGeofencing and departureGeofencing instead */
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly arrivalGeofencing?: Maybe<EntityArrivalGeofencing>;
  readonly departureGeofencing?: Maybe<EntityDepartureGeofencing>;
};

export enum EntityGeofencingLocationDistanceTypes {
  Mi = 'mi',
  Km = 'km'
}

export type EntityGeofencingLocationInput = {
  readonly recordId?: InputMaybe<Scalars['String']>;
  readonly tenantId?: InputMaybe<Scalars['String']>;
  readonly locationId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly locationPerspective?: InputMaybe<EntityGeofencingLocationPerspectives>;
  readonly geofencingMethod?: InputMaybe<EntityGeofencingMethods>;
  readonly distanceType?: InputMaybe<EntityGeofencingLocationDistanceTypes>;
  readonly createdAt?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly geofences?: InputMaybe<ReadonlyArray<InputMaybe<EntityGeofenceLocationInput>>>;
  readonly enabled?: InputMaybe<Scalars['Boolean']>;
  readonly arrivalGeofencing?: InputMaybe<EntityArrivalGeofencingInput>;
  readonly departureGeofencing?: InputMaybe<EntityDepartureGeofencingInput>;
};

export enum EntityGeofencingLocationPerspectives {
  Arrival = 'arrival',
  Departure = 'departure'
}

export type EntityGeofencingLocationRecord = {
  readonly __typename?: 'EntityGeofencingLocationRecord';
  readonly tenantId: Scalars['String'];
  readonly facilityId: Scalars['ID'];
  readonly locationId: Scalars['ID'];
  readonly geofences: ReadonlyArray<Maybe<EntityGeofenceDetails>>;
  readonly arrivalGeofencing?: Maybe<EntityArrivalGeofencing>;
  readonly departureGeofencing?: Maybe<EntityDepartureGeofencing>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdBy: Scalars['String'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly recordExists?: Maybe<Scalars['Boolean']>;
  readonly globalSettings?: Maybe<MinionGlobalGeofencingSettings>;
};

export type EntityGeofencingLocationRecordDeleteInput = {
  readonly tenantId: Scalars['String'];
  readonly locationId: Scalars['ID'];
};

export type EntityGeofencingLocationRecordInput = {
  readonly tenantId: Scalars['String'];
  readonly facilityId: Scalars['ID'];
  readonly locationId: Scalars['ID'];
  readonly arrivalGeofencing?: InputMaybe<EntityArrivalGeofencingInput>;
  readonly departureGeofencing?: InputMaybe<EntityDepartureGeofencingInput>;
  readonly geofences?: InputMaybe<ReadonlyArray<InputMaybe<EntityGeofenceDetailsInput>>>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

/** Input object for a location geofence search */
export type EntityGeofencingLocationSearchInput = {
  /** The tenantId of the location geofence record */
  readonly tenantId: Scalars['String'];
  /** The locationId of the location geofence record */
  readonly locationId?: InputMaybe<Scalars['ID']>;
  /** The facilityId of the location geofence record */
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  /** Use minion fallback when defaulting to a location geofence record */
  readonly useMinionFallback?: InputMaybe<Scalars['Boolean']>;
  /** Use mastermind geopoint address fallback when finding default geopoint values */
  readonly useLocationGeopointFallback?: InputMaybe<Scalars['Boolean']>;
};

export type EntityGeofencingLocationV2 = {
  readonly __typename?: 'EntityGeofencingLocationV2';
  readonly recordId: Scalars['String'];
  readonly tenantId: Scalars['String'];
  readonly locationId?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly geofencingMethod?: Maybe<EntityGeofencingMethods>;
  readonly locationPerspective?: Maybe<EntityGeofencingLocationPerspectives>;
  readonly geofences: ReadonlyArray<Maybe<EntityGeofenceLocation>>;
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  /** @deprecated Use arrivalGeofencing and departureGeofencing instead */
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly arrivalGeofencing?: Maybe<EntityArrivalGeofencing>;
  readonly departureGeofencing?: Maybe<EntityDepartureGeofencing>;
};

export enum EntityGeofencingMethods {
  Circle = 'circle',
  Polygon = 'polygon'
}

export type EntityInput = {
  readonly carrier?: InputMaybe<EntityArgumentInput>;
  readonly customer?: InputMaybe<EntityArgumentInput>;
  readonly facility?: InputMaybe<EntityArgumentInput>;
};

/** String key value pair for entity configuration */
export type EntityStringKeyValue = {
  readonly __typename?: 'EntityStringKeyValue';
  /** The Tenant Component of an entity string key value pair */
  readonly tenant: Scalars['String'];
  /** The Domain Component of an entity string key value pair */
  readonly domain: Scalars['String'];
  /** The SubDomain Component of an entity string key value pair */
  readonly subDomain: Scalars['String'];
  /** The Key Component of an entity string key value pair */
  readonly key: Scalars['String'];
  /** The Value Component of an entity string key value pair */
  readonly value: Scalars['String'];
};

export enum EntityType {
  Customer = 'customer',
  Carrier = 'carrier'
}

export enum EntityTypeEnum {
  Factor = 'factor',
  Fleet = 'fleet',
  Rail = 'rail',
  Carrier = 'carrier',
  Vendor = 'vendor'
}

export type Equipment = {
  readonly trailerTypeTerms?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly trailerDimensions?: InputMaybe<TrailerDimensions>;
  readonly commodities?: InputMaybe<ReadonlyArray<InputMaybe<Commodities>>>;
};

export type EquipmentAccessoriesInput = {
  /** Braces the carrier is expected to have.  Multi-Select DDT */
  readonly braces?: InputMaybe<BracesInput>;
  /**
   * ⚠️ **Deprecated! Use 'braces'** ⚠️
   * The type of Bracing Equipment the Carrier is expected to have
   */
  readonly bracingType?: InputMaybe<BracingTypeEnum>;
  /**
   * ⚠️ **Deprecated! Use 'braces'** ⚠️
   * The number of Braces the Carrier is expected to have
   */
  readonly numberOfBraces?: InputMaybe<Scalars['Int']>;
  /** ⚠️ **Deprecated! Use palletTypeV2** ⚠️ */
  readonly palletType?: InputMaybe<PalletTypeEnum>;
  /** The type of Pallets the Carrier is expected to have */
  readonly palletTypeV2?: InputMaybe<Scalars['String']>;
  /** The number of Pallets the Carrier is expected to have */
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  /** ⚠️ **Deprecated! Use tarpTypeV2** ⚠️ */
  readonly tarpType?: InputMaybe<TarpTypeEnum>;
  /** The type of Tarp the Carrier is expected to have */
  readonly tarpTypeV2?: InputMaybe<Scalars['String']>;
  /** The number of Tarps the Carrier is expected to have */
  readonly numberOfTarps?: InputMaybe<Scalars['Int']>;
};

export type EquipmentDimensionsInput = {
  /** The Height of the Carrier's Equipment */
  readonly height?: InputMaybe<UnitOfDistanceInput>;
  /** The Length of the Carrier's Equipment */
  readonly length?: InputMaybe<UnitOfDistanceInput>;
  /** The Width of the Carrier's Equipment */
  readonly width?: InputMaybe<UnitOfDistanceInput>;
};

export type EquipmentDimensionsInputV2 = {
  readonly height?: InputMaybe<LengthInput>;
  readonly length?: InputMaybe<LengthInput>;
  readonly width?: InputMaybe<LengthInput>;
};

export type EquipmentFilterType = {
  readonly dimensions?: InputMaybe<DimensionsRequestFilterType>;
  readonly trailerTypeTerms?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type EquipmentInput = {
  /** Equipment Accessories: Braces, Tarps, and Pallets */
  readonly accessories?: InputMaybe<EquipmentAccessoriesInput>;
  /** The Length, Width, & Height of the Carrier's Equipment */
  readonly dimensions?: InputMaybe<EquipmentDimensionsInput>;
  /** The expected Trailer Type of the Carrier -- *Requires 'Trailer Type' DDT* */
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
};

export type EquipmentInputV2 = {
  readonly dimensions?: InputMaybe<EquipmentDimensionsInputV2>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
};

export type EquipmentR2 = {
  readonly __typename?: 'EquipmentR2';
  readonly id?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
};

export type Error = {
  readonly __typename?: 'Error';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['String']>;
};

export type ErrorDetailsInput = {
  readonly errorCode?: InputMaybe<Scalars['String']>;
  readonly errorDescription?: InputMaybe<Scalars['String']>;
  readonly errorTimestamp?: InputMaybe<Scalars['DateTime']>;
};

export type EstimatedFuelSurcharge = {
  readonly __typename?: 'EstimatedFuelSurcharge';
  readonly customerId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly fuelSurcharge?: Maybe<Scalars['Decimal']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly units?: Maybe<Scalars['Decimal']>;
  readonly ratePerUnit?: Maybe<Scalars['Decimal']>;
};

export type EtaCalculatorIntegrityCheck = {
  readonly __typename?: 'ETACalculatorIntegrityCheck';
  readonly errors: ReadonlyArray<Maybe<Scalars['String']>>;
};

export type EtaCalculatorResponse = EtaCalculatorResult | EtaCalculatorIntegrityCheck;

export type EtaCalculatorResult = {
  readonly __typename?: 'ETACalculatorResult';
  readonly paths: ReadonlyArray<Maybe<PathDat>>;
  readonly reportSchedules: ReadonlyArray<Maybe<ReportScheduleDat>>;
  readonly hosFeasible: Scalars['Boolean'];
};

export type EtaCalculatorV2DriverHosInput = {
  readonly hosTimer?: InputMaybe<EtaCalculatorV2HosTimerInput>;
  readonly calendarType?: InputMaybe<Scalars['String']>;
  readonly historicalActions?: InputMaybe<ReadonlyArray<HosDriverActionInput>>;
  readonly onDutyDayStart?: InputMaybe<Scalars['Int']>;
  readonly driverPreference?: InputMaybe<HosDriverPreferenceInput>;
};

export type EtaCalculatorV2HosInput = {
  readonly driver1HosInput?: InputMaybe<EtaCalculatorV2DriverHosInput>;
  readonly driver2HosInput?: InputMaybe<EtaCalculatorV2DriverHosInput>;
};

export type EtaCalculatorV2HosTimer = {
  readonly __typename?: 'ETACalculatorV2HOSTimer';
  readonly remainingDriveMinutesUntilBreak?: Maybe<Scalars['Int']>;
  readonly remainingDriveMinutesUntilRest?: Maybe<Scalars['Int']>;
  readonly remainingWorkingMinutes?: Maybe<Scalars['Int']>;
  readonly remainingCycleMinutes?: Maybe<Scalars['Int']>;
};

export type EtaCalculatorV2HosTimerInput = {
  readonly remainingDriveMinutesUntilBreak?: InputMaybe<Scalars['Int']>;
  readonly remainingDriveMinutesUntilRest?: InputMaybe<Scalars['Int']>;
  readonly remainingWorkingMinutes?: InputMaybe<Scalars['Int']>;
  readonly remainingCycleMinutes?: InputMaybe<Scalars['Int']>;
};

export type EtaCalculatorV2RequestDriver = {
  readonly driverId: Scalars['String'];
  readonly hosTimer?: InputMaybe<EtaCalculatorV2RequestHosTimer>;
  readonly calendarType?: InputMaybe<Scalars['String']>;
  readonly cycleStartTime?: InputMaybe<Scalars['DateTime']>;
  readonly driverPreference?: InputMaybe<HosDriverPreference>;
};

export type EtaCalculatorV2RequestHosTimer = {
  readonly breakClock: Scalars['Int'];
  readonly driveClock: Scalars['Int'];
  readonly shiftClock: Scalars['Int'];
  readonly cycleClock: Scalars['Int'];
};

export type EtaCalculatorV2RequestInput = {
  readonly routeId: Scalars['String'];
  readonly availableDateTime: Scalars['DateTime'];
  readonly availableLocation: EtaCalculatorV2RequestLocation;
  readonly driver1: EtaCalculatorV2RequestDriver;
  readonly driver2?: InputMaybe<EtaCalculatorV2RequestDriver>;
};

export type EtaCalculatorV2RequestLocation = {
  readonly location?: InputMaybe<Scalars['String']>;
  readonly lat: Scalars['Float'];
  readonly lon: Scalars['Float'];
};

export type EtaCalculatorV2RouteDataInput = {
  readonly availableTime?: InputMaybe<Scalars['DateTime']>;
  readonly hosInput?: InputMaybe<EtaCalculatorV2HosInput>;
  readonly haversineOverride?: InputMaybe<Scalars['Float']>;
  readonly defaultMph?: InputMaybe<Scalars['Float']>;
  readonly defaultMphThreshold?: InputMaybe<Scalars['Float']>;
  readonly rdCalcInformedMph?: InputMaybe<Scalars['Boolean']>;
  readonly drivingPathGiven?: InputMaybe<Scalars['Boolean']>;
  readonly drivingMinutesGiven?: InputMaybe<Scalars['Boolean']>;
};

export type EtaCalculatorV2RouteLegsDataInput = {
  readonly location?: InputMaybe<Scalars['String']>;
  readonly lowerWindow?: InputMaybe<Scalars['DateTime']>;
  readonly upperWindow?: InputMaybe<Scalars['DateTime']>;
  readonly serviceMinutes?: InputMaybe<Scalars['Int']>;
  readonly offDutyMinutes?: InputMaybe<Scalars['Int']>;
  readonly milesToNext?: InputMaybe<Scalars['Float']>;
  readonly minutesToNext?: InputMaybe<Scalars['Float']>;
  readonly lat: Scalars['Float'];
  readonly lon: Scalars['Float'];
};

export type EtaCalculatorV2Solution = {
  readonly __typename?: 'ETACalculatorV2Solution';
  readonly isFeasible: Scalars['Boolean'];
  readonly stops: ReadonlyArray<AutoEstimate>;
};

export type EtaCalculatorV2StopSolution = {
  readonly __typename?: 'ETACalculatorV2StopSolution';
  readonly stopId: Scalars['String'];
  readonly arrivalTime: Scalars['DateTime'];
  readonly departureTime: Scalars['DateTime'];
  readonly actionDriver1?: Maybe<Scalars['String']>;
  readonly actionDriver2?: Maybe<Scalars['String']>;
  readonly lat?: Maybe<Scalars['Float']>;
  readonly lon?: Maybe<Scalars['Float']>;
  readonly driver1ArrivalHosTimer?: Maybe<EtaCalculatorV2HosTimer>;
  readonly driver2ArrivalHosTimer?: Maybe<EtaCalculatorV2HosTimer>;
  readonly driver1DepartureHosTimer?: Maybe<EtaCalculatorV2HosTimer>;
  readonly driver2DepartureHosTimer?: Maybe<EtaCalculatorV2HosTimer>;
};

export type EtaDataScienceCalculatorV2Solution = {
  readonly __typename?: 'ETADataScienceCalculatorV2Solution';
  readonly isFeasible: Scalars['Boolean'];
  readonly stops: ReadonlyArray<EtaCalculatorV2StopSolution>;
  readonly mphSyncViolations: Scalars['Int'];
  readonly minuteSyncViolations: Scalars['Float'];
  readonly infHosMphCoefficient: Scalars['Float'];
  readonly infHosForcedFreshDriver: Scalars['Boolean'];
  readonly infHosReducedShiftMinutes: Scalars['Int'];
};

export type EvaluateDriverVouchersInput = {
  readonly driverVoucherIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type EvaluateDriverVouchersOutput = {
  readonly __typename?: 'EvaluateDriverVouchersOutput';
  readonly driverVouchers: ReadonlyArray<DriverVoucherHeaderType>;
};

export type EventDetail = {
  readonly __typename?: 'EventDetail';
  readonly id: Scalars['ID'];
  /** EDI specific reason code ID */
  readonly ediReasonCodeID?: Maybe<Scalars['String']>;
  readonly lateArrivalReasonCodeID?: Maybe<Scalars['String']>;
  /** DEPRECATED - DO NOT USE, Use Trailers field instead, to support multi-trailer */
  readonly trailer?: Maybe<Trailer>;
  /** Returns a SORTED array of trailers. Includes a sort index in case you mess with order */
  readonly trailers: ReadonlyArray<Trailer>;
  readonly note?: Maybe<Scalars['String']>;
  readonly createdDatetime: Scalars['DateTime'];
  readonly updatedDatetime: Scalars['DateTime'];
  readonly createdBy: EmployeeV2;
  readonly lastUpdatedBy: EmployeeV2;
  readonly trailerID?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
  readonly stopArrivalTimingState?: Maybe<Scalars['String']>;
};

export type EventDetailInput = {
  readonly trailers?: InputMaybe<ReadonlyArray<TrailerInput>>;
  readonly lateArrivalReasonCodeID?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly tractorNumber?: InputMaybe<Scalars['String']>;
  /** DEPRECATED - DO NOT USE, Use trailers Array Instead */
  readonly trailer?: InputMaybe<TrailerInput>;
  /** DEPRECATED - DO NOT USE, Use trailers Array Instead */
  readonly trailerID?: InputMaybe<Scalars['String']>;
};

export type ExactEmployeeManyFilterV2 = {
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly userIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly userEmails?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type Example = {
  readonly __typename?: 'Example';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly createdBy?: Maybe<Scalars['ID']>;
  readonly createdOn: Scalars['DateTime'];
  readonly updatedBy?: Maybe<Scalars['ID']>;
  readonly updatedOn: Scalars['DateTime'];
};

export type ExceptionMetadataUnion = LoadV2 | CustomerOrderV2 | LoadRouteV2 | CustomerV2 | ClientExceptionRecordDefaultMetadata;

export enum ExceptionReason {
  AccountingIncident = 'AccountingIncident',
  TrailerValidation = 'TrailerValidation',
  VendorInvoiceTolerance = 'VendorInvoiceTolerance',
  AwaitingPaperwork = 'AwaitingPaperwork',
  MissingVendorInvoice = 'MissingVendorInvoice'
}

export type ExceptionSourceUnion = LoadV2 | CustomerOrderV2 | LoadRouteV2 | CustomerV2 | ClientExceptionRecordDefaultSource;

export type ExchangeRateConfiguration = {
  readonly __typename?: 'ExchangeRateConfiguration';
  readonly id: Scalars['ID'];
  readonly costDetailConversionDate: VoucherExchangeRateConversionDateEnumType;
  readonly rateDetailConversionDate: InvoiceExchangeRateConversionDateEnum;
  readonly useInvoiceVoucherDateAsFallbackDate?: Maybe<Scalars['Boolean']>;
};

/** The input type for ExchangeRateConfiguration */
export type ExchangeRateConfigurationCreateInput = {
  readonly costDetailConversionDate: VoucherExchangeRateConversionDateEnumType;
  readonly rateDetailConversionDate: InvoiceExchangeRateConversionDateEnum;
  readonly useInvoiceVoucherDateAsFallbackDate?: InputMaybe<Scalars['Boolean']>;
};

/** The input type for ExchangeRateConfiguration */
export type ExchangeRateConfigurationUpdateInputType = {
  readonly costDetailConversionDate: VoucherExchangeRateConversionDateEnumType;
  readonly rateDetailConversionDate: InvoiceExchangeRateConversionDateEnum;
  readonly useInvoiceVoucherDateAsFallbackDate?: InputMaybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
};

export enum ExecutionEstimateTypeEnum {
  Eta = 'ETA',
  Etd = 'ETD'
}

export type ExistingQuotesDetail = {
  readonly __typename?: 'ExistingQuotesDetail';
  readonly existingRateQuoteCode?: Maybe<Scalars['String']>;
  readonly existingSpotQuoteCode?: Maybe<Scalars['String']>;
};

export type ExistingQuotesFilterInput = {
  readonly spotQuoteFilter: SpotQuoteFilterInput;
  readonly customerId: Scalars['ID'];
};

export type ExistingQuotesInput = {
  readonly filter: ExistingQuotesFilterInput;
};

export type ExistingQuotesPayload = {
  readonly __typename?: 'ExistingQuotesPayload';
  readonly data?: Maybe<ExistingQuotesDetail>;
  readonly errors?: Maybe<ReadonlyArray<SpotQuoteError>>;
};

/** Holds Expected Cost Detail. */
export type ExpectedCost = {
  readonly __typename?: 'ExpectedCost';
  readonly code?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly dynamicCost?: Maybe<Scalars['Float']>;
  readonly message?: Maybe<Scalars['String']>;
};

export type ExpectedCostRequestFilter = {
  readonly destinationCity?: InputMaybe<Scalars['String']>;
  readonly destinationLatitude?: InputMaybe<Scalars['Float']>;
  readonly destinationLongitude?: InputMaybe<Scalars['Float']>;
  readonly destinationPostalCode?: InputMaybe<Scalars['String']>;
  readonly destinationRegion?: InputMaybe<Scalars['String']>;
  readonly destinationState?: InputMaybe<Scalars['String']>;
  readonly isCustomerLiveLoad?: InputMaybe<Scalars['Boolean']>;
  readonly isCustomerLiveUnload?: InputMaybe<Scalars['Boolean']>;
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly orderCode?: InputMaybe<Scalars['String']>;
  readonly originCity?: InputMaybe<Scalars['String']>;
  readonly originLatitude?: InputMaybe<Scalars['Float']>;
  readonly originLongitude?: InputMaybe<Scalars['Float']>;
  readonly originPostalCode?: InputMaybe<Scalars['String']>;
  readonly originRegion?: InputMaybe<Scalars['String']>;
  readonly originState?: InputMaybe<Scalars['String']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
};

export type ExpectedEmptyInformationSaveCarrierDetailsInput = {
  /** Division associated with the entity, must match a division DDT */
  readonly division?: InputMaybe<Scalars['String']>;
  /** Load Size associated with the entity, must match a loadSize DDT */
  readonly loadSize?: InputMaybe<Scalars['String']>;
  /** Trailer Type equipment associated with the entity, must match a trailerType DDT option ID in UUID format */
  readonly equipment?: ReadonlyArray<Scalars['String']>;
  /** Route Transport Mode associated with the entity, must match a routeTransportMode DDT */
  readonly routeTransportMode?: InputMaybe<Scalars['String']>;
};

export type ExpectedEmptyLocation = {
  readonly __typename?: 'ExpectedEmptyLocation';
  readonly point?: Maybe<Geopoint>;
  readonly countryAlpha2Code?: Maybe<Scalars['String']>;
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly stateAlpha2Code?: Maybe<Scalars['String']>;
};

/** Input for setting Expected Ready Information for the Carrier */
export type ExpectedEmptyLocationInput = {
  /** The latitude where the driver is expected to be ready prior to the route */
  readonly latitude: Scalars['Double'];
  /** The longitude where the driver is expected to be ready prior to the route */
  readonly longitude: Scalars['Double'];
};

/** Input for setting Expected Ready Information for the Carrier */
export type ExpectedReadyInput = {
  /** Used for setting Expected Ready Information for the Carrier */
  readonly expectedReadyLocation?: InputMaybe<ExpectedEmptyLocationInput>;
  /** Sets Equipment Type / Dimensions on the Carrier */
  readonly equipment?: InputMaybe<EquipmentInput>;
  /** The Distance between the Driver's Expected Ready Location and the First Stop Location on the Route. */
  readonly distance?: InputMaybe<UnitOfDistanceInput>;
  /** UTC Timestamp representing the Date & Time the Driver is expected to be ready prior to this Route */
  readonly utcExpectedEmptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** UTC Timestamp representing the estimated Date & Time the Driver is expected to arrive at the first Stop for pickup */
  readonly utcPickupEtaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
};

export type ExpectedReadyInputV2 = {
  readonly expectedReadyLocation?: InputMaybe<ExpectedReadyLocationInputV2>;
  readonly equipment?: InputMaybe<EquipmentInputV2>;
  readonly distance?: InputMaybe<UnitOfDistanceInput>;
  readonly utcExpectedReadyDatetime?: InputMaybe<Scalars['Timestamp']>;
  readonly utcPickupEtaDatetime?: InputMaybe<Scalars['Timestamp']>;
};

/** Input for setting Expected Ready Information for the Carrier in Booking Orchestration */
export type ExpectedReadyLocationInput = {
  /** The city the driver is expected to be ready in prior to the route */
  readonly city?: InputMaybe<Scalars['String']>;
  /** The state the driver is expected to be ready in prior to the route */
  readonly state?: InputMaybe<Scalars['String']>;
  /** The latitude where the driver is expected to be ready prior to the route */
  readonly latitude?: InputMaybe<Scalars['Double']>;
  /** The longitude where the driver is expected to be ready prior to the route */
  readonly longitude?: InputMaybe<Scalars['Double']>;
};

export type ExpectedReadyLocationInputV2 = {
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

/** Input for `expectedReadyValidations` Query */
export type ExpectedReadyValidationsInput = {
  /** The ID of the Route. Validation requirements can vary depending on the Scope of the Route */
  readonly routeId: Scalars['ID'];
};

/** Input for `expectedReadyValidationsV2` Query */
export type ExpectedReadyValidationsInputV2 = {
  /** The ID of the Route */
  readonly routeId: Scalars['ID'];
  readonly scope: ScopeInput;
};

export type ExpectedReadyValidationsResponse = {
  readonly __typename?: 'ExpectedReadyValidationsResponse';
  readonly locationRequired: Scalars['Boolean'];
  readonly dateRequired: Scalars['Boolean'];
  readonly timeRequired: Scalars['Boolean'];
  readonly equipmentRequired: Scalars['Boolean'];
  readonly lengthRequired: Scalars['Boolean'];
  readonly costLineItemsRequired: Scalars['Boolean'];
  readonly equipmentMatchRequired: Scalars['Boolean'];
};

/** expectedReadyValidationsV2 Response Payload */
export type ExpectedReadyValidationsResponseV2 = {
  readonly __typename?: 'ExpectedReadyValidationsResponseV2';
  readonly routeId: Scalars['ID'];
  readonly expectedReadyValidations: Validations;
};

export enum ExpiredCacheBehavior {
  Fail = 'Fail',
  UseCached = 'UseCached'
}

export type ExportCarrierCommitmentFilter = {
  readonly carrierID?: InputMaybe<Scalars['ID']>;
  readonly laneIDs?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly costQuoteID?: InputMaybe<Scalars['ID']>;
  readonly routeID?: InputMaybe<Scalars['ID']>;
  readonly routingGuideID?: InputMaybe<Scalars['ID']>;
  readonly includeActive?: InputMaybe<Scalars['Boolean']>;
};

export type ExportCustomerCommitmentFilter = {
  readonly customerID?: InputMaybe<Scalars['ID']>;
  readonly laneIDs?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly rateQuoteID?: InputMaybe<Scalars['ID']>;
  readonly zeroVolume?: InputMaybe<Scalars['Int']>;
  readonly orderID?: InputMaybe<Scalars['ID']>;
  readonly includeActive?: InputMaybe<Scalars['Boolean']>;
};

export type ExportRoutingGuidesByLanesFilter = {
  readonly laneIds: ReadonlyArray<Scalars['ID']>;
  readonly onlyActive?: InputMaybe<Scalars['Boolean']>;
  readonly onlyEffective?: InputMaybe<RoutingGuideOnlyEffectiveFilter>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
};

export type ExternalAvailableRouteCustomerInput = {
  readonly customerName: Scalars['String'];
  readonly customerEmployeeGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type ExternalAvailableRouteCustomerRepInput = {
  readonly main: Scalars['Boolean'];
  readonly typeId?: InputMaybe<Scalars['String']>;
  readonly typeName?: InputMaybe<Scalars['String']>;
  readonly employeeName: Scalars['String'];
};

export type ExternalAvailableRouteStopInput = {
  readonly startDate: Scalars['DateTime'];
  readonly endDate: Scalars['DateTime'];
  readonly timeZone?: InputMaybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  /** Two character country code. (e.g. US, MX, CA) */
  readonly country?: InputMaybe<Scalars['String']>;
  readonly geoPoint?: InputMaybe<GeoPointInput>;
};

export type ExternalDataGeofenceCenterInput = {
  readonly latitude?: InputMaybe<Scalars['Float']>;
  readonly longitude?: InputMaybe<Scalars['Float']>;
};

export type ExternalDataGeofenceCircleInput = {
  readonly center?: InputMaybe<ExternalDataGeofenceCenterInput>;
  readonly radius?: InputMaybe<Scalars['Float']>;
};

export type ExternalDataGeofenceInput = {
  readonly delay?: InputMaybe<Scalars['Float']>;
  readonly auto_complete?: InputMaybe<Scalars['Boolean']>;
  readonly trigger_by?: InputMaybe<Scalars['String']>;
  readonly message?: InputMaybe<Scalars['String']>;
  readonly circle?: InputMaybe<ExternalDataGeofenceCircleInput>;
};

export type ExternalDataInput = {
  readonly is_prompt_repeats?: InputMaybe<Scalars['Boolean']>;
  readonly is_allow_repeats?: InputMaybe<Scalars['Boolean']>;
  readonly is_required?: InputMaybe<Scalars['Boolean']>;
  readonly geofence?: InputMaybe<ExternalDataGeofenceInput>;
};

export type ExternalGeography = {
  readonly __typename?: 'ExternalGeography';
  readonly adminCode1?: Maybe<Scalars['String']>;
  readonly lng?: Maybe<Scalars['Float']>;
  readonly geonameId?: Maybe<Scalars['Int']>;
  readonly toponymName?: Maybe<Scalars['String']>;
  readonly countryId?: Maybe<Scalars['String']>;
  readonly fcl?: Maybe<Scalars['String']>;
  readonly population?: Maybe<Scalars['Int']>;
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly fclName?: Maybe<Scalars['String']>;
  readonly adminCodes1?: Maybe<ExternalGeographyAdminCode>;
  readonly countryName?: Maybe<Scalars['String']>;
  readonly fcodeName?: Maybe<Scalars['String']>;
  readonly adminName1?: Maybe<Scalars['String']>;
  readonly lat?: Maybe<Scalars['Float']>;
  readonly fcode?: Maybe<Scalars['String']>;
};

export type ExternalGeographyAdminCode = {
  readonly __typename?: 'ExternalGeographyAdminCode';
  readonly ISO3166_2?: Maybe<Scalars['String']>;
};

export type ExternalGeographyInput = {
  readonly sourceId?: InputMaybe<Scalars['Int']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly locality?: InputMaybe<Scalars['String']>;
  readonly administrativeArea?: InputMaybe<Scalars['String']>;
  readonly lat?: InputMaybe<Scalars['Float']>;
  readonly lon?: InputMaybe<Scalars['Float']>;
};

export type ExternalQuotePricingDetailsRequestFilter = {
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly commodities?: InputMaybe<ReadonlyArray<InputMaybe<CommoditiesItemRequestFilterType>>>;
  readonly createDate?: InputMaybe<Scalars['Long']>;
  readonly customer?: InputMaybe<CustomerRequestFilterType>;
  readonly distance?: InputMaybe<BaseExternalQuoteRequestFilterType>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly equipment?: InputMaybe<EquipmentFilterType>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly quoteId?: InputMaybe<Scalars['String']>;
  readonly quoteRequirementTerms?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly requesterEmployeeCode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<StopsItemRequestFilterType>>>;
};

/** ExternalQuoteTotalChargeResponseType */
export type ExternalQuoteTotalChargeResponseType = {
  readonly __typename?: 'ExternalQuoteTotalChargeResponseType';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly currencyCodeTerm?: Maybe<Scalars['String']>;
};

export type ExtractAvailableField = {
  readonly __typename?: 'ExtractAvailableField';
  readonly id: Scalars['ID'];
  readonly exportColumnName?: Maybe<Scalars['String']>;
  readonly exportColumnDescription?: Maybe<Scalars['String']>;
  readonly fileType?: Maybe<Scalars['String']>;
  readonly parentExportColumnName?: Maybe<Scalars['String']>;
  readonly associatedDDTName?: Maybe<Scalars['String']>;
  readonly category?: Maybe<Scalars['String']>;
};

export type ExtractConfigurationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly fileName: Scalars['String'];
  readonly fileType: Scalars['String'];
  readonly extractTimes: ReadonlyArray<InputMaybe<Scalars['String']>>;
  readonly frequency: Scalars['String'];
  readonly daysOfWeek?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly daysOfMonth?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Decimal']>>>;
  readonly extractMappings: ReadonlyArray<ExtractMappingInput>;
  readonly category?: InputMaybe<Scalars['String']>;
};

/** Represents all Extract configuration values */
export type ExtractConfigurationType = {
  readonly __typename?: 'ExtractConfigurationType';
  readonly id?: Maybe<Scalars['ID']>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly fileType?: Maybe<Scalars['String']>;
  readonly extractTimes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly frequency?: Maybe<Scalars['String']>;
  readonly daysOfWeek?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly daysOfMonth?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly extractMappings?: Maybe<ReadonlyArray<Maybe<ExtractMapping>>>;
  readonly category?: Maybe<Scalars['String']>;
};

export type ExtractMapping = {
  readonly __typename?: 'ExtractMapping';
  readonly id: Scalars['ID'];
  readonly extractConfigurationEid?: Maybe<Scalars['Long']>;
  readonly exportFieldName?: Maybe<Scalars['String']>;
  readonly exportFieldDescription?: Maybe<Scalars['String']>;
  readonly alias?: Maybe<Scalars['String']>;
  readonly truncate?: Maybe<Scalars['Long']>;
  readonly sequence?: Maybe<Scalars['Long']>;
  readonly additionalFieldArgument?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

export type ExtractMappingInput = {
  readonly exportFieldName: Scalars['String'];
  readonly exportFieldDescription?: InputMaybe<Scalars['String']>;
  readonly alias?: InputMaybe<Scalars['String']>;
  readonly truncate?: InputMaybe<Scalars['Long']>;
  readonly sequence: Scalars['Long'];
  readonly additionalFieldArgument?: InputMaybe<Scalars['String']>;
};

export type FacilitiesFilter = {
  readonly address?: InputMaybe<AddressesQueryInput>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly customer?: InputMaybe<CustomersFilter>;
  readonly dba?: InputMaybe<Scalars['String']>;
  readonly facilityIdentifier?: InputMaybe<IdentifierQueryInput>;
  readonly facilityNote?: InputMaybe<Scalars['String']>;
  readonly facilityType?: InputMaybe<Scalars['String']>;
  readonly facilityTypeId?: InputMaybe<Scalars['ID']>;
  readonly loadFromTypeId?: InputMaybe<Scalars['ID']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly scaleNote?: InputMaybe<Scalars['String']>;
  readonly schedulingContact?: InputMaybe<Scalars['String']>;
  readonly schedulingSystemTypeId?: InputMaybe<Scalars['ID']>;
  readonly sort?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['ID']>;
  readonly sourceType?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<FacilityStatusTypeEnum>;
  readonly text?: InputMaybe<Scalars['String']>;
  readonly unloadFromTypeId?: InputMaybe<Scalars['ID']>;
  readonly updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
};

export type FacilitiesFilterV2 = {
  readonly address?: InputMaybe<FacilityAddressesQueryInput>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly dba?: InputMaybe<Scalars['String']>;
  readonly facilityIdentifier?: InputMaybe<FacilityIdentifierQueryInput>;
  readonly facilityNote?: InputMaybe<Scalars['String']>;
  readonly facilityType?: InputMaybe<Scalars['String']>;
  /** The field `facilityTypeId` is deprecated. Use `facilityType` instead. */
  readonly facilityTypeId?: InputMaybe<Scalars['ID']>;
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly loadFromTypeId?: InputMaybe<Scalars['ID']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly scaleNote?: InputMaybe<Scalars['String']>;
  readonly schedulingContact?: InputMaybe<Scalars['String']>;
  readonly schedulingSystemTypeId?: InputMaybe<Scalars['ID']>;
  readonly sort?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['ID']>;
  readonly sourceType?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<FacilityStatusTypeEnum>;
  readonly unloadFromTypeId?: InputMaybe<Scalars['ID']>;
  readonly updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly text?: InputMaybe<Scalars['String']>;
  readonly useFacilityPickerFuzzy?: InputMaybe<Scalars['Boolean']>;
  readonly repIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type FacilitiesQueryInput = {
  readonly limit?: InputMaybe<Scalars['Int']>;
  readonly text?: InputMaybe<Scalars['String']>;
};

export type FacilitiesQueryInputTypeV2 = {
  readonly text?: InputMaybe<Scalars['String']>;
  readonly limit?: InputMaybe<Scalars['Int']>;
};

export type Facility = {
  readonly __typename?: 'Facility';
  readonly id: Scalars['ID'];
  readonly facilityNoteDefaults?: Maybe<FacilityNoteDefaults>;
  readonly facilityLoadDefaults?: Maybe<FacilityLoadDefaults>;
  readonly customerCommodities?: Maybe<ReadonlyArray<CustomerCommodity>>;
  readonly customerFacilities?: Maybe<ReadonlyArray<CustomerFacility>>;
  readonly addresses: ReadonlyArray<Address>;
  readonly code: Scalars['String'];
  readonly contacts: ReadonlyArray<Contact>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly customerRelationships?: Maybe<ReadonlyArray<CustomerFacilityRelationship>>;
  readonly dba?: Maybe<Scalars['String']>;
  /** @deprecated Use external_notes */
  readonly externalFacilityNotes?: Maybe<ReadonlyArray<Note>>;
  readonly externalNotes?: Maybe<ReadonlyArray<Note>>;
  readonly facilityIdentifiers?: Maybe<ReadonlyArray<FacilityIdentifier>>;
  readonly facilityNote?: Maybe<Scalars['String']>;
  readonly facilityType?: Maybe<KeyValue>;
  readonly facilityTypeId?: Maybe<Scalars['ID']>;
  readonly inactiveContacts?: Maybe<ReadonlyArray<Contact>>;
  readonly loadFromType?: Maybe<KeyValue>;
  readonly loadFromTypeId?: Maybe<Scalars['ID']>;
  readonly mainAddress?: Maybe<Address>;
  readonly mainContact?: Maybe<Contact>;
  readonly name: Scalars['String'];
  readonly notes?: Maybe<ReadonlyArray<Note>>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly scaleNote?: Maybe<Scalars['String']>;
  readonly schedules: ReadonlyArray<Schedule>;
  readonly schedulingContact?: Maybe<Scalars['String']>;
  readonly schedulingSystemType?: Maybe<KeyValue>;
  readonly schedulingSystemTypeId?: Maybe<Scalars['ID']>;
  /** @deprecated facility_identifiers table to be used */
  readonly sourceId?: Maybe<Scalars['String']>;
  readonly sourceType?: Maybe<Scalars['String']>;
  readonly status?: Maybe<FacilityStatusTypeEnum>;
  readonly taxExempt?: Maybe<Scalars['Boolean']>;
  readonly timezone?: Maybe<Scalars['String']>;
  readonly unloadFromType?: Maybe<KeyValue>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
};


export type FacilityCustomerFacilitiesArgs = {
  customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type FacilityAddress = {
  readonly __typename?: 'FacilityAddress';
  readonly addressTypes?: Maybe<ReadonlyArray<KeyValue>>;
  readonly addressType?: Maybe<KeyValue>;
  readonly addressTypeId?: Maybe<Scalars['ID']>;
  readonly facililityId: Scalars['ID'];
  readonly city: Scalars['String'];
  readonly county?: Maybe<Scalars['String']>;
  readonly country: Scalars['String'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly customGeopoint?: Maybe<Geopoint>;
  /** @deprecated Use geopointNullable */
  readonly geopoint: Geopoint;
  readonly geopointNullable?: Maybe<Geopoint>;
  readonly id: Scalars['ID'];
  readonly isVerified: Scalars['Boolean'];
  readonly main: Scalars['Boolean'];
  readonly postalCode: Scalars['String'];
  readonly province: Scalars['String'];
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: Maybe<Scalars['String']>;
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
};

export type FacilityAddressesQueryInput = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly radius?: InputMaybe<UnitOfDistanceInput>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly street1?: InputMaybe<Scalars['String']>;
  readonly street2?: InputMaybe<Scalars['String']>;
};

export type FacilityAddressGeoPointValidation = {
  readonly __typename?: 'FacilityAddressGeoPointValidation';
  readonly lat: Scalars['Float'];
  readonly lon: Scalars['Float'];
};

export type FacilityAddressInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly addressTypeId?: InputMaybe<Scalars['ID']>;
  readonly addressTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly county?: InputMaybe<Scalars['String']>;
  readonly customFullAddressPoint?: InputMaybe<GeopointInput>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isVerified?: InputMaybe<Scalars['Boolean']>;
  readonly main: Scalars['Boolean'];
  readonly postalCode: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type FacilityAddressResult = {
  readonly __typename?: 'FacilityAddressResult';
  readonly id: Scalars['ID'];
  readonly city?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly street1?: Maybe<Scalars['String']>;
  readonly street2?: Maybe<Scalars['String']>;
  readonly geopoint?: Maybe<GeopointNullable>;
};

export type FacilityAddressValidation = {
  readonly __typename?: 'FacilityAddressValidation';
  readonly id: Scalars['ID'];
  readonly geopoint?: Maybe<FacilityAddressGeoPointValidation>;
};

export type FacilityArgumentInput = {
  readonly code?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type FacilityArgumentInputV2 = {
  readonly code?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type FacilityAudit = {
  readonly __typename?: 'FacilityAudit';
  readonly id: Scalars['ID'];
  readonly auditableId?: Maybe<Scalars['ID']>;
  readonly associatedId?: Maybe<Scalars['ID']>;
  readonly associatedType?: Maybe<Scalars['String']>;
  readonly userId?: Maybe<Scalars['ID']>;
  readonly userType?: Maybe<Scalars['String']>;
  readonly auditableTab?: Maybe<Scalars['String']>;
  readonly auditCategory?: Maybe<Scalars['String']>;
  readonly remoteAddress?: Maybe<Scalars['String']>;
  readonly requestUuid?: Maybe<Scalars['String']>;
  readonly version?: Maybe<Scalars['Int']>;
  readonly comment?: Maybe<Scalars['String']>;
  readonly auditDetails?: Maybe<ReadonlyArray<FacilityAuditDetails>>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly user?: Maybe<UserV2>;
  readonly actionWithFieldName?: Maybe<Scalars['String']>;
  readonly previousValues?: Maybe<Scalars['String']>;
  readonly updatedValues?: Maybe<Scalars['String']>;
};

export type FacilityAuditDetails = {
  readonly __typename?: 'FacilityAuditDetails';
  readonly auditDetailId?: Maybe<Scalars['ID']>;
  readonly auditLogId?: Maybe<Scalars['ID']>;
  readonly action?: Maybe<Scalars['String']>;
  readonly fieldName?: Maybe<Scalars['String']>;
  readonly previousValue?: Maybe<Scalars['String']>;
  readonly updatedValue?: Maybe<Scalars['String']>;
};

/** An edge in audit connection. */
export type FacilityAuditEdge = {
  readonly __typename?: 'FacilityAuditEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: FacilityAudit;
};

/** The connection type for Audits. */
export type FacilityAudits = {
  readonly __typename?: 'FacilityAudits';
  /** A list of edges. */
  readonly edges: ReadonlyArray<FacilityAuditEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type FacilityAuditsFilter = {
  readonly startDate: Scalars['String'];
  readonly endDate: Scalars['String'];
  readonly facilityId: Scalars['String'];
};

/** The connection type for Facility. */
export type FacilityConnection = {
  readonly __typename?: 'FacilityConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<FacilityEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type FacilityConnectionV2 = {
  readonly __typename?: 'FacilityConnectionV2';
  readonly edges: ReadonlyArray<FacilityEdgeV2>;
  readonly pageInfo: PageInfo;
};

export type FacilityContact = {
  readonly __typename?: 'FacilityContact';
  readonly chatType?: Maybe<KeyValue>;
  readonly chatTypeId?: Maybe<Scalars['ID']>;
  readonly chatUsername?: Maybe<Scalars['String']>;
  readonly contactType: KeyValue;
  readonly contactTypeId: Scalars['ID'];
  readonly active: Scalars['Boolean'];
  readonly facilityId: Scalars['ID'];
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly extension?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly id: Scalars['ID'];
  readonly main: Scalars['Boolean'];
  readonly name?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
};

export type FacilityContactInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly chatTypeId?: InputMaybe<Scalars['ID']>;
  readonly chatUsername?: InputMaybe<Scalars['String']>;
  readonly contactTypeId?: InputMaybe<Scalars['ID']>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type FacilityDetails = {
  readonly __typename?: 'FacilityDetails';
  readonly timezone?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly facilityName?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly address?: Maybe<FacilityAddressResult>;
  readonly notes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly schedules?: Maybe<ReadonlyArray<Maybe<FacilityScheduleResult>>>;
};

export type FacilityDirection = {
  readonly __typename?: 'FacilityDirection';
  readonly id?: Maybe<Scalars['ID']>;
  readonly facilityId: Scalars['ID'];
  readonly source?: Maybe<KeyValue>;
  readonly sourceId?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly averageTime?: Maybe<Scalars['Int']>;
  readonly directions: Scalars['String'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
};

export type FacilityDirectionInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly source?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly averageTime?: InputMaybe<Scalars['Int']>;
  readonly directions: Scalars['String'];
};

export type FacilityDocument = {
  readonly __typename?: 'FacilityDocument';
  readonly id: Scalars['ID'];
  readonly facilityId: Scalars['String'];
  /** The name of the file to be displayed to the user, not necessarily the filename */
  readonly name: Scalars['String'];
  /** The name of the file provided during upload */
  readonly filename: Scalars['String'];
  readonly uploadedByUserId: Scalars['String'];
  readonly updatedByUserId: Scalars['String'];
  readonly uploadDate: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  /** facilityDocumentSourceType KVT ID */
  readonly sourceId: Scalars['ID'];
  /** employeeDepartment KVT ID */
  readonly departmentTypeId: Scalars['ID'];
  /** facilityDocumentType KVT ID */
  readonly documentTypeId: Scalars['ID'];
  /** facilityThirdPartyDocument ID */
  readonly thirdPartyDocumentId?: Maybe<Scalars['ID']>;
  /** uri to access the document */
  readonly uri: Scalars['String'];
  /** uri to access the signed document */
  readonly signedUri?: Maybe<Scalars['String']>;
  /** MasterSign Reference Id */
  readonly masterSignReferenceId?: Maybe<Scalars['String']>;
  /** Identification for MasterSigner */
  readonly signedBy?: Maybe<Scalars['String']>;
  /** Timestamp for when document was signed */
  readonly signedAt?: Maybe<Scalars['DateTime']>;
  /**
   * Document Status
   * @deprecated Depcrecated in favor of documentStatus
   */
  readonly status?: Maybe<Scalars['String']>;
  /** Document Status */
  readonly documentStatus?: Maybe<DocumentStatusEnum>;
  /** Previous Document Status */
  readonly previousDocumentStatus?: Maybe<DocumentStatusEnum>;
  /** Identification for External Uploader */
  readonly externalUser?: Maybe<Scalars['String']>;
  readonly uploadedByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly facilityV2?: Maybe<FacilityV2>;
};

export type FacilityDocumentConnection = {
  readonly __typename?: 'FacilityDocumentConnection';
  readonly edges: ReadonlyArray<FacilityDocumentEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Float'];
};

export type FacilityDocumentEdge = {
  readonly __typename?: 'FacilityDocumentEdge';
  readonly cursor: Scalars['String'];
  readonly node: FacilityDocument;
};

export type FacilityDocumentFilter = {
  readonly facilityId: Scalars['ID'];
};

/** An edge in a connection. */
export type FacilityEdge = {
  readonly __typename?: 'FacilityEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: Facility;
};

export type FacilityEdgeV2 = {
  readonly __typename?: 'FacilityEdgeV2';
  readonly cursor: Scalars['String'];
  readonly node: FacilityV2;
};

export type FacilityFacility = {
  readonly __typename?: 'FacilityFacility';
  readonly id: Scalars['ID'];
  readonly facilityId: Scalars['String'];
  readonly relatedFacilityId: Scalars['String'];
  readonly relationshipType: Scalars['String'];
  readonly note?: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly facilityV2: FacilityV2;
  readonly relatedFacilityV2: FacilityV2;
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUserV2?: Maybe<UserV2>;
};

export type FacilityHoliday = {
  readonly __typename?: 'FacilityHoliday';
  readonly id?: Maybe<Scalars['ID']>;
  readonly facilityId: Scalars['ID'];
  readonly holiday: KeyValue;
  readonly holidayId?: Maybe<Scalars['String']>;
  readonly facilityOpenState: KeyValue;
  readonly facilityOpenStateId?: Maybe<Scalars['String']>;
  readonly holidayDate: Scalars['ISO8601DateTime'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly weekday?: Maybe<Scalars['String']>;
  readonly startOffsetMs?: Maybe<Scalars['Int']>;
  readonly endOffsetMs?: Maybe<Scalars['Int']>;
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly active?: Maybe<Scalars['Boolean']>;
};

export type FacilityIdentifier = {
  readonly __typename?: 'FacilityIdentifier';
  readonly code: Scalars['String'];
  readonly facilityId: Scalars['ID'];
  readonly facilityIdentifierType: KeyValue;
  readonly facilityIdentifierTypeId: Scalars['ID'];
  readonly id: Scalars['ID'];
};

export type FacilityIdentifierInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly facilityIdentifierTypeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type FacilityIdentifierInputV2 = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly facilityIdentifierTypeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type FacilityIdentifierQueryInput = {
  readonly code: Scalars['String'];
  readonly identifierType: Scalars['String'];
};

export type FacilityIdentifierStandaloneInput = {
  readonly code: Scalars['String'];
  readonly facility: FacilityArgumentInput;
  readonly facilityIdentifierTypeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type FacilityIdentifierStandaloneInputV2 = {
  readonly code: Scalars['String'];
  readonly facility: FacilityArgumentInputV2;
  readonly facilityIdentifierTypeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type FacilityIdentifierV2 = {
  readonly __typename?: 'FacilityIdentifierV2';
  readonly code: Scalars['String'];
  readonly facilityId: Scalars['ID'];
  readonly facilityIdentifierType: KeyValue;
  readonly facilityIdentifierTypeId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
};

export type FacilityImporterInput = {
  readonly addresses?: InputMaybe<ReadonlyArray<FacilityAddressInput>>;
  readonly code: Scalars['String'];
  readonly dba?: InputMaybe<Scalars['String']>;
  readonly facilityIdentifiers?: InputMaybe<ReadonlyArray<FacilityIdentifierInputV2>>;
  readonly facilityNote?: InputMaybe<Scalars['String']>;
  readonly facilityTypeId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly loadFromTypeId?: InputMaybe<Scalars['ID']>;
  readonly mainContactId?: InputMaybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly scaleNote?: InputMaybe<Scalars['String']>;
  readonly schedules?: InputMaybe<ReadonlyArray<ScheduleInputV2>>;
  readonly schedulingContact?: InputMaybe<Scalars['String']>;
  readonly schedulingSystemTypeId?: InputMaybe<Scalars['ID']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly sourceType?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<FacilityStatusTypeEnum>;
  readonly taxExempt?: InputMaybe<Scalars['Boolean']>;
  readonly unloadFromTypeId?: InputMaybe<Scalars['ID']>;
};

export type FacilityInput = {
  readonly addresses?: InputMaybe<ReadonlyArray<AddressInput>>;
  readonly code: Scalars['String'];
  readonly contacts?: InputMaybe<ReadonlyArray<ContactInput>>;
  readonly dba?: InputMaybe<Scalars['String']>;
  readonly facilityIdentifiers?: InputMaybe<ReadonlyArray<FacilityIdentifierInput>>;
  readonly facilityNote?: InputMaybe<Scalars['String']>;
  readonly facilityTypeId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly loadFromTypeId?: InputMaybe<Scalars['ID']>;
  readonly mainContactId?: InputMaybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly notes?: InputMaybe<ReadonlyArray<NoteInput>>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly scaleNote?: InputMaybe<Scalars['String']>;
  readonly schedules?: InputMaybe<ReadonlyArray<ScheduleInput>>;
  readonly schedulingContact?: InputMaybe<Scalars['String']>;
  readonly schedulingSystemTypeId?: InputMaybe<Scalars['ID']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly sourceType?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<FacilityStatusTypeEnum>;
  readonly taxExempt?: InputMaybe<Scalars['Boolean']>;
  readonly unloadFromTypeId?: InputMaybe<Scalars['ID']>;
};

export type FacilityInputV2 = {
  readonly addresses?: InputMaybe<ReadonlyArray<FacilityAddressInput>>;
  readonly code: Scalars['String'];
  readonly contacts?: InputMaybe<ReadonlyArray<FacilityContactInput>>;
  readonly dba?: InputMaybe<Scalars['String']>;
  readonly directions?: InputMaybe<ReadonlyArray<FacilityDirectionInput>>;
  readonly facilityIdentifiers?: InputMaybe<ReadonlyArray<FacilityIdentifierInputV2>>;
  readonly facilityNote?: InputMaybe<Scalars['String']>;
  readonly facilityTypeId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly loadFromTypeId?: InputMaybe<Scalars['ID']>;
  readonly mainContactId?: InputMaybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly notes?: InputMaybe<ReadonlyArray<FacilityNoteInput>>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly reps?: InputMaybe<ReadonlyArray<FacilityRepInputV2>>;
  readonly allReps?: InputMaybe<ReadonlyArray<FacilityRepInputV2>>;
  readonly scaleNote?: InputMaybe<Scalars['String']>;
  readonly schedules?: InputMaybe<ReadonlyArray<ScheduleInputV2>>;
  readonly schedulingContact?: InputMaybe<Scalars['String']>;
  readonly schedulingSystemTypeId?: InputMaybe<Scalars['ID']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly sourceType?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<FacilityStatusTypeEnum>;
  readonly taxExempt?: InputMaybe<Scalars['Boolean']>;
  readonly unloadFromTypeId?: InputMaybe<Scalars['ID']>;
  readonly maximumTrailerAge?: InputMaybe<Scalars['Int']>;
  readonly schedulingSystemUrl?: InputMaybe<Scalars['String']>;
};

export type FacilityLoadDefaults = {
  readonly __typename?: 'FacilityLoadDefaults';
  readonly id: Scalars['ID'];
  readonly facilityId: Scalars['String'];
  readonly defaultEquipments?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly length?: Maybe<Scalars['Float']>;
  readonly width?: Maybe<Scalars['Float']>;
  readonly height?: Maybe<Scalars['Float']>;
  readonly schedSystem?: Maybe<Scalars['String']>;
  readonly schedURL?: Maybe<Scalars['String']>;
  readonly specialRequirements?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly autoSchedEligible?: Maybe<Scalars['Boolean']>;
  readonly autoSchedDefault?: Maybe<Scalars['Boolean']>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly facilityLoadDefaultsEquipment?: Maybe<ReadonlyArray<FacilityLoadDefaultsEquipment>>;
  readonly facilityLoadDefaultsSpecialRequirement?: Maybe<ReadonlyArray<FacilityLoadDefaultsSpecialRequirement>>;
  readonly facilityLoadDefaultsDivision?: Maybe<ReadonlyArray<FacilityLoadDefaultsDivision>>;
  readonly facilityLoadDefaultsBusinessUnit?: Maybe<ReadonlyArray<FacilityLoadDefaultsBusinessUnit>>;
};

export type FacilityLoadDefaultsBusinessUnit = {
  readonly __typename?: 'FacilityLoadDefaultsBusinessUnit';
  readonly id: Scalars['ID'];
  readonly facilityLoadDefaultsId?: Maybe<Scalars['String']>;
  readonly businessUnit: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly facilityLoadDefaults?: Maybe<FacilityLoadDefaults>;
};

export type FacilityLoadDefaultsConnection = {
  readonly __typename?: 'FacilityLoadDefaultsConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<FacilityLoadDefaultsEdge>;
};

export type FacilityLoadDefaultsDivision = {
  readonly __typename?: 'FacilityLoadDefaultsDivision';
  readonly id?: Maybe<Scalars['ID']>;
  readonly facilityLoadDefaultsId?: Maybe<Scalars['String']>;
  readonly division: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly facilityLoadDefaults?: Maybe<FacilityLoadDefaults>;
};

export type FacilityLoadDefaultsEdge = {
  readonly __typename?: 'FacilityLoadDefaultsEdge';
  readonly node: FacilityLoadDefaults;
  readonly cursor: Scalars['String'];
};

export type FacilityLoadDefaultsEquipment = {
  readonly __typename?: 'FacilityLoadDefaultsEquipment';
  readonly id: Scalars['ID'];
  readonly facilityLoadDefaultsId: Scalars['String'];
  readonly defaultEquipment: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly facilityLoadDefaults?: Maybe<FacilityLoadDefaults>;
};

export type FacilityLoadDefaultsFilter = {
  /** facilityId to filter by */
  readonly facilityId?: InputMaybe<Scalars['ID']>;
};

export type FacilityLoadDefaultsSpecialRequirement = {
  readonly __typename?: 'FacilityLoadDefaultsSpecialRequirement';
  readonly id: Scalars['ID'];
  readonly facilityLoadDefaultsId: Scalars['String'];
  readonly specialRequirement: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly facilityLoadDefaults?: Maybe<FacilityLoadDefaults>;
};

export type FacilityNote = {
  readonly __typename?: 'FacilityNote';
  readonly createdAt: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly noteType: Scalars['String'];
  readonly noteableId: Scalars['ID'];
  readonly noteableType: Scalars['String'];
  readonly text?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['Iso8601Date'];
};

export type FacilityNoteDefaults = {
  readonly __typename?: 'FacilityNoteDefaults';
  readonly id: Scalars['ID'];
  readonly facilityId: Scalars['String'];
  readonly facilityLoadNote?: Maybe<Scalars['String']>;
  readonly externalFacilityNote?: Maybe<Scalars['String']>;
  readonly rateConfirmNote?: Maybe<Scalars['String']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly noteType?: Maybe<Scalars['String']>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly facilityNoteDefaultsDivision?: Maybe<ReadonlyArray<FacilityNoteDefaultsDivision>>;
  readonly facilityNoteDefaultsBusinessUnits?: Maybe<ReadonlyArray<FacilityNoteDefaultsBusinessUnit>>;
};

export type FacilityNoteDefaultsBusinessUnit = {
  readonly __typename?: 'FacilityNoteDefaultsBusinessUnit';
  readonly id: Scalars['ID'];
  readonly noteDefaultId?: Maybe<Scalars['String']>;
  readonly businessUnit: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly facilityNoteDefaults?: Maybe<FacilityNoteDefaults>;
};

export type FacilityNoteDefaultsDivision = {
  readonly __typename?: 'FacilityNoteDefaultsDivision';
  readonly id?: Maybe<Scalars['ID']>;
  readonly noteDefaultId?: Maybe<Scalars['String']>;
  readonly division: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly facilityNoteDefaults?: Maybe<FacilityNoteDefaults>;
};

export type FacilityNoteInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly noteType?: InputMaybe<NoteTypeEnum>;
  readonly noteableId?: InputMaybe<Scalars['ID']>;
  readonly text?: InputMaybe<Scalars['String']>;
};

export type FacilityRepInputV2 = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly employeeId: Scalars['ID'];
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly repTypeId: Scalars['ID'];
  readonly active?: InputMaybe<Scalars['Boolean']>;
};

export type FacilityRepV2 = {
  readonly __typename?: 'FacilityRepV2';
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly employee: EmployeeV2;
  readonly facilityId?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly repType: KeyValue;
  readonly repTypeId?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly active?: Maybe<Scalars['Boolean']>;
};

export type FacilityRoute = {
  readonly __typename?: 'FacilityRoute';
  readonly id: Scalars['ID'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Float']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly routeCount?: Maybe<Scalars['Float']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly primaryDivision?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['String']>;
  readonly orderMode?: Maybe<Scalars['String']>;
  readonly orderSize?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly routeDistance?: Maybe<Scalars['Float']>;
  readonly routeDistanceUnit?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly mainOrderRep?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly routeTotalRate?: Maybe<Scalars['Float']>;
  readonly routeTotalCost?: Maybe<Scalars['Float']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverPhone?: Maybe<Scalars['String']>;
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  readonly routeCarrierId?: Maybe<Scalars['String']>;
  readonly executingDivision?: Maybe<Scalars['String']>;
  readonly mainRouteRep?: Maybe<Scalars['String']>;
};

export type FacilityRouteConnection = {
  readonly __typename?: 'FacilityRouteConnection';
  readonly edges: ReadonlyArray<FacilityRouteEdge>;
  readonly pageInfo: PageInfo;
};

export type FacilityRouteEdge = {
  readonly __typename?: 'FacilityRouteEdge';
  readonly node: FacilityRoute;
  readonly cursor: Scalars['String'];
};

export type FacilityRouteFilterInput = {
  readonly originStopFacilityId: ReadonlyArray<Scalars['ID']>;
  readonly destStopFacilityId: ReadonlyArray<Scalars['ID']>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly primaryDivision?: InputMaybe<Scalars['String']>;
  readonly executingDivision?: InputMaybe<Scalars['String']>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
};

export type FacilityScheduleResult = {
  readonly __typename?: 'FacilityScheduleResult';
  readonly appointmentType?: Maybe<Scalars['String']>;
  readonly dropType?: Maybe<Scalars['String']>;
  readonly equipmentTypeId?: Maybe<Scalars['String']>;
  readonly friday?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly loadSizeId?: Maybe<Scalars['String']>;
  readonly modeType?: Maybe<Scalars['String']>;
  readonly monday?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly routeTransportModeId?: Maybe<Scalars['String']>;
  readonly saturday?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly scheduleType?: Maybe<Scalars['String']>;
  readonly sunday?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly thursday?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly trailerTypeId?: Maybe<Scalars['String']>;
  readonly trailerType?: Maybe<FacilityTrailerType>;
  readonly tuesday?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly wednesday?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly workType?: Maybe<Scalars['String']>;
};

export type FacilitySearch = {
  readonly __typename?: 'FacilitySearch';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly address?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly zipcode?: Maybe<Scalars['String']>;
  readonly isMain?: Maybe<Scalars['Boolean']>;
  readonly distance?: Maybe<Scalars['Float']>;
};

export type FacilitySearchConnection = {
  readonly __typename?: 'FacilitySearchConnection';
  readonly edges: ReadonlyArray<FacilitySearchEdge>;
  readonly pageInfo: PageInfo;
};

export type FacilitySearchEdge = {
  readonly __typename?: 'FacilitySearchEdge';
  readonly node: FacilitySearch;
  readonly cursor: Scalars['String'];
};

export type FacilitySearchFilterInput = {
  readonly name?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly code?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly phone?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly system?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly systemId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly type?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly status?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly address?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly city?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** facilityRadius is deprecated. Use facilityRadiusV2 instead. */
  readonly facilityRadius?: InputMaybe<RadiusFilter>;
  readonly facilityRadiusV2?: InputMaybe<RadiusFilterOptional>;
  readonly state?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly country?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly zipCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrier?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly customer?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly isMain?: InputMaybe<Scalars['Boolean']>;
};

export enum FacilityStatusTypeEnum {
  /** Active */
  Active = 'active',
  /** Duplicate */
  Duplicate = 'duplicate',
  /** Inactive */
  Inactive = 'inactive',
  /** New */
  New = 'New',
  /** System Generated */
  SystemGenerated = 'systemGenerated'
}

export type FacilitySublocation = {
  readonly __typename?: 'FacilitySublocation';
  readonly id?: Maybe<Scalars['ID']>;
  readonly facilityId: Scalars['ID'];
  readonly sublocation: Scalars['String'];
  readonly description: Scalars['String'];
  readonly type: KeyValue;
  readonly typeId: Scalars['String'];
  readonly locked?: Maybe<KeyValue>;
  readonly lockedId?: Maybe<Scalars['String']>;
  readonly guarded?: Maybe<KeyValue>;
  readonly guardedId?: Maybe<Scalars['String']>;
  readonly checkInMethod?: Maybe<Scalars['String']>;
  readonly directions?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly customFullAddressPoint?: Maybe<Geopoint>;
  readonly facility: FacilityV2;
};

export type FacilitySublocationAccommodation = {
  readonly __typename?: 'FacilitySublocationAccommodation';
  readonly id?: Maybe<Scalars['ID']>;
  readonly sublocationId: Scalars['ID'];
  readonly sublocation: Scalars['String'];
  readonly accommodation: Scalars['String'];
  readonly rating?: Maybe<KeyValue>;
  readonly ratingId?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
};

export type FacilitySublocationAccommodationConnection = {
  readonly __typename?: 'FacilitySublocationAccommodationConnection';
  readonly edges: ReadonlyArray<FacilitySublocationAccommodationEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Float'];
};

export type FacilitySublocationAccommodationEdge = {
  readonly __typename?: 'FacilitySublocationAccommodationEdge';
  readonly cursor: Scalars['String'];
  readonly node: FacilitySublocationAccommodation;
};

export type FacilitySublocationAccommodationFilter = {
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly sublocationId?: InputMaybe<Scalars['ID']>;
};

export type FacilitySublocationActivity = {
  readonly __typename?: 'FacilitySublocationActivity';
  readonly id?: Maybe<Scalars['ID']>;
  readonly sublocationId: Scalars['ID'];
  readonly activity: Scalars['String'];
  readonly averageTime?: Maybe<Scalars['Int']>;
  readonly averageTime100Loads?: Maybe<Scalars['Int']>;
  readonly variability?: Maybe<KeyValue>;
  readonly variabilityId?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly sublocation: Scalars['String'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
};

export type FacilitySublocationActivityConnection = {
  readonly __typename?: 'FacilitySublocationActivityConnection';
  readonly edges: ReadonlyArray<FacilitySublocationActivityEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Float'];
};

export type FacilitySublocationActivityEdge = {
  readonly __typename?: 'FacilitySublocationActivityEdge';
  readonly cursor: Scalars['String'];
  readonly node: FacilitySublocationActivity;
};

export type FacilitySublocationActivityFilter = {
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly sublocationId?: InputMaybe<Scalars['ID']>;
};

export type FacilitySublocationGate = {
  readonly __typename?: 'FacilitySublocationGate';
  readonly id?: Maybe<Scalars['ID']>;
  readonly sublocationId: Scalars['ID'];
  readonly sublocation: Scalars['String'];
  readonly gate: Scalars['String'];
  readonly guardType?: Maybe<KeyValue>;
  readonly guardTypeId?: Maybe<Scalars['String']>;
  readonly documentationNeeded?: Maybe<ReadonlyArray<KeyValue>>;
  readonly documentationNeededId?: Maybe<Scalars['String']>;
  readonly idChecked?: Maybe<ReadonlyArray<KeyValue>>;
  readonly idCheckedId?: Maybe<Scalars['String']>;
  readonly guardContactInfo?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
};

export type FacilitySublocationGateConnection = {
  readonly __typename?: 'FacilitySublocationGateConnection';
  readonly edges: ReadonlyArray<FacilitySublocationGateEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Float'];
};

export type FacilitySublocationGateEdge = {
  readonly __typename?: 'FacilitySublocationGateEdge';
  readonly cursor: Scalars['String'];
  readonly node: FacilitySublocationGate;
};

export type FacilitySublocationGateFilter = {
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly sublocationId?: InputMaybe<Scalars['ID']>;
};

export type FacilityTracking = {
  readonly __typename?: 'FacilityTracking';
  readonly id: Scalars['ID'];
  readonly customerName?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeBookedByName?: Maybe<Scalars['String']>;
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly nextStopAutoEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly nextStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextStopEndTime?: Maybe<Scalars['Float']>;
  readonly nextStopManualEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly nextStopStartTimezone?: Maybe<Scalars['String']>;
  readonly nextStopType?: Maybe<Scalars['String']>;
  readonly driverElectronicTrackingType?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverPhone?: Maybe<Scalars['String']>;
  readonly emptyDatetime?: Maybe<Scalars['DateTime']>;
  readonly integrationName?: Maybe<Scalars['String']>;
  readonly lastTrackingNote?: Maybe<Scalars['String']>;
  readonly latestElectronicLocationUpdateDatetime?: Maybe<Scalars['DateTime']>;
  readonly latestTrackingRequestDatetime?: Maybe<Scalars['DateTime']>;
  readonly nextCallBackDatetime?: Maybe<Scalars['DateTime']>;
  readonly trackingAgCreateDate?: Maybe<Scalars['DateTime']>;
  readonly trackingAgCreatedBy?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly orderRequirements?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Float']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly nextStopFacilityName?: Maybe<Scalars['String']>;
  readonly nextStopCity?: Maybe<Scalars['String']>;
  readonly nextStopState?: Maybe<Scalars['String']>;
  readonly carrierExecutingDivision?: Maybe<Scalars['String']>;
  readonly trackingLocationCity?: Maybe<Scalars['String']>;
  readonly trackingLocationState?: Maybe<Scalars['String']>;
  readonly isHotRoute?: Maybe<Scalars['Boolean']>;
  readonly routeDivision?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Float']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly orderReferences?: Maybe<Scalars['String']>;
  readonly bookingSourceId?: Maybe<Scalars['String']>;
  readonly waybillStatus?: Maybe<Scalars['String']>;
  readonly waybillDatetime?: Maybe<Scalars['DateTime']>;
  readonly masterSignStatus?: Maybe<Scalars['String']>;
  readonly powerId?: Maybe<Scalars['String']>;
  readonly powerCode?: Maybe<Scalars['String']>;
  readonly trackingUpdateUserName?: Maybe<Scalars['String']>;
  readonly distanceToNext?: Maybe<Scalars['Float']>;
  readonly distanceToNextUnit?: Maybe<Scalars['String']>;
  readonly routeCapacityNeed?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly locationDescription?: Maybe<Scalars['String']>;
  readonly speed?: Maybe<Scalars['String']>;
  readonly fuelLevelPercent?: Maybe<Scalars['Float']>;
  readonly locationRelativePositionDirection?: Maybe<Scalars['String']>;
  readonly originStopRequirements?: Maybe<Scalars['String']>;
  readonly nextStopRequirements?: Maybe<Scalars['String']>;
  readonly destStopRequirements?: Maybe<Scalars['String']>;
  readonly currentStopRequirements?: Maybe<Scalars['String']>;
  readonly nextStopFacilityId?: Maybe<Scalars['ID']>;
  readonly currentStopFacilityName?: Maybe<Scalars['String']>;
  readonly currentStopFacilityId?: Maybe<Scalars['ID']>;
  readonly serviceLevel?: Maybe<Scalars['String']>;
  readonly driver1Id?: Maybe<Scalars['ID']>;
  readonly resourceType?: Maybe<Scalars['String']>;
  readonly inboundTrailerIdentifier1?: Maybe<Scalars['String']>;
  readonly inboundTrailerTypeId1?: Maybe<Scalars['String']>;
  readonly inboundTrailerRecordId1?: Maybe<Scalars['String']>;
  readonly inboundTrailerIdentifier2?: Maybe<Scalars['String']>;
  readonly inboundTrailerTypeId2?: Maybe<Scalars['String']>;
  readonly inboundTrailerRecordId2?: Maybe<Scalars['String']>;
  readonly inboundTrailerIdentifier3?: Maybe<Scalars['String']>;
  readonly inboundTrailerTypeId3?: Maybe<Scalars['String']>;
  readonly inboundTrailerRecordId3?: Maybe<Scalars['String']>;
  readonly outboundTrailerIdentifier1?: Maybe<Scalars['String']>;
  readonly outboundTrailerTypeId1?: Maybe<Scalars['String']>;
  readonly outboundTrailerRecordId1?: Maybe<Scalars['String']>;
  readonly outboundTrailerIdentifier2?: Maybe<Scalars['String']>;
  readonly outboundTrailerTypeId2?: Maybe<Scalars['String']>;
  readonly outboundTrailerRecordId2?: Maybe<Scalars['String']>;
  readonly outboundTrailerIdentifier3?: Maybe<Scalars['String']>;
  readonly outboundTrailerTypeId3?: Maybe<Scalars['String']>;
  readonly outboundTrailerRecordId3?: Maybe<Scalars['String']>;
  readonly loadedTrailerIdentifier1?: Maybe<Scalars['String']>;
  readonly loadedTrailerTypeId1?: Maybe<Scalars['String']>;
  readonly loadedTrailerRecordId1?: Maybe<Scalars['String']>;
  readonly loadedTrailerIdentifier2?: Maybe<Scalars['String']>;
  readonly loadedTrailerTypeId2?: Maybe<Scalars['String']>;
  readonly loadedTrailerRecordId2?: Maybe<Scalars['String']>;
  readonly loadedTrailerIdentifier3?: Maybe<Scalars['String']>;
  readonly loadedTrailerTypeId3?: Maybe<Scalars['String']>;
  readonly loadedTrailerRecordId3?: Maybe<Scalars['String']>;
  readonly routeBookedById?: Maybe<Scalars['String']>;
  readonly previousStopFacilityName?: Maybe<Scalars['String']>;
  readonly previousStopFacilityId?: Maybe<Scalars['ID']>;
  readonly previousStopState?: Maybe<Scalars['String']>;
  readonly previousStopCity?: Maybe<Scalars['String']>;
  readonly calculatedDirectionHeading?: Maybe<Scalars['String']>;
  readonly reqArriveDate?: Maybe<Scalars['DateTime']>;
  readonly providerETA?: Maybe<Scalars['DateTime']>;
  readonly orderReferencesType?: Maybe<Scalars['String']>;
  readonly orderReferencesValue?: Maybe<Scalars['String']>;
  readonly routeReferencesType?: Maybe<Scalars['String']>;
  readonly routeReferencesValue?: Maybe<Scalars['String']>;
  readonly carrierRouteReferencesType?: Maybe<Scalars['String']>;
  readonly carrierRouteReferencesValue?: Maybe<Scalars['String']>;
  readonly stopReferencesType?: Maybe<Scalars['String']>;
  readonly stopReferencesValue?: Maybe<Scalars['String']>;
  readonly routeStopRequirements?: Maybe<Scalars['String']>;
};

export type FacilityTrackingConnection = {
  readonly __typename?: 'FacilityTrackingConnection';
  readonly edges: ReadonlyArray<FacilityTrackingEdge>;
  readonly pageInfo: PageInfo;
};

export type FacilityTrackingEdge = {
  readonly __typename?: 'FacilityTrackingEdge';
  readonly node: FacilityTracking;
  readonly cursor: Scalars['String'];
};

export type FacilityTrackingFilterInput = {
  readonly nextCallBackDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly originStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly destStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverElectronicTrackingType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trackingStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Previously infoFrom */
  readonly integrationName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly capacityNeed?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLifeCycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** previously puStartDate value1 */
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  /** Previously puStartDate value2 */
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  /** previously delStartDate value1 */
  readonly destStopStartDate?: InputMaybe<Scalars['DateTime']>;
  /** Previously delEndDate value2 */
  readonly destStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Previously groupId */
  readonly repGroup?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerGroup?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly bookingSourceId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderRepId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly orderRepGroup?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Created for Route RepId */
  readonly routeRepId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Created for Route GroupId */
  readonly routeRepGroup?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly powerId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly powerCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly distanceToNext?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly distanceToNextUnit?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly stopRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly nextStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly currentStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly currentStopFacilityName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly serviceLevel?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driver1Id?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly resourceType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly inboundTrailerIdentifier1?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly inboundTrailerIdentifier2?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly inboundTrailerIdentifier3?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundTrailerIdentifier1?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundTrailerIdentifier2?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundTrailerIdentifier3?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadedTrailerIdentifier1?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadedTrailerIdentifier2?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadedTrailerIdentifier3?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverPhone?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeStopCount?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly nextStopAutoEtaDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly nextStopManualEtaDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly lastTrackingNote?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Tracking Agcreated Date */
  readonly trackingAgCreateDate?: InputMaybe<Scalars['DateTime']>;
  readonly routeBookedById?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderReferences?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly locationDescription?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly masterSignStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly speed?: InputMaybe<Scalars['Float']>;
  readonly fuelLevelPercent?: InputMaybe<Scalars['Float']>;
  readonly locationRelativePositionDirection?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trackingLocationCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trackingLocationState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly nextStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly nextStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly nextStopFacilityName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly previousStopFacilityName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly previousStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly previousStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly previousStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly waybillStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly waybillDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly isHotRoute?: InputMaybe<Scalars['Boolean']>;
  readonly reqArriveDate?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly nextStopType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly calculatedDirectionHeading?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly providerETA?: InputMaybe<Scalars['DateTime']>;
  readonly orderReference?: InputMaybe<Scalars['String']>;
  readonly routeReference?: InputMaybe<Scalars['String']>;
  readonly carrierRouteReference?: InputMaybe<Scalars['String']>;
  readonly stopReference?: InputMaybe<Scalars['String']>;
  readonly routeStopRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type FacilityTrailerType = {
  readonly __typename?: 'FacilityTrailerType';
  readonly name: Scalars['String'];
};

export enum FacilityTransitStatus {
  Arriving = 'arriving',
  Departing = 'departing'
}

export type FacilityV2 = {
  readonly __typename?: 'FacilityV2';
  readonly id: Scalars['ID'];
  readonly facilityNoteDefaults?: Maybe<FacilityNoteDefaults>;
  readonly facilityLoadDefaults?: Maybe<FacilityLoadDefaults>;
  readonly customerCommodities?: Maybe<ReadonlyArray<CustomerCommodity>>;
  readonly customerFacilities?: Maybe<ReadonlyArray<CustomerFacility>>;
  readonly facilityFacilityRelationship?: Maybe<ReadonlyArray<FacilityFacility>>;
  readonly addresses: ReadonlyArray<FacilityAddress>;
  readonly addressTypes?: Maybe<ReadonlyArray<KeyValue>>;
  readonly code: Scalars['String'];
  readonly contacts: ReadonlyArray<FacilityContact>;
  readonly inactiveContacts?: Maybe<ReadonlyArray<FacilityContact>>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly customerRelationships?: Maybe<ReadonlyArray<CustomerFacilityRelationshipV2>>;
  readonly dba?: Maybe<Scalars['String']>;
  readonly externalNotes?: Maybe<ReadonlyArray<FacilityNote>>;
  readonly facilityIdentifiers?: Maybe<ReadonlyArray<FacilityIdentifierV2>>;
  readonly facilityNote?: Maybe<Scalars['String']>;
  readonly facilityType?: Maybe<KeyValue>;
  readonly facilityTypeId?: Maybe<Scalars['ID']>;
  readonly directions?: Maybe<ReadonlyArray<FacilityDirection>>;
  readonly holidays?: Maybe<ReadonlyArray<FacilityHoliday>>;
  readonly loadFromType?: Maybe<KeyValue>;
  readonly loadFromTypeId?: Maybe<Scalars['ID']>;
  readonly mainAddress?: Maybe<FacilityAddress>;
  readonly mainContact?: Maybe<FacilityContact>;
  readonly name: Scalars['String'];
  readonly notes?: Maybe<ReadonlyArray<FacilityNote>>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly reps?: Maybe<ReadonlyArray<FacilityRepV2>>;
  readonly allReps?: Maybe<ReadonlyArray<FacilityRepV2>>;
  readonly scaleNote?: Maybe<Scalars['String']>;
  readonly schedules: ReadonlyArray<ScheduleV2>;
  readonly schedulingContact?: Maybe<Scalars['String']>;
  readonly schedulingSystemType?: Maybe<KeyValue>;
  readonly schedulingSystemTypeId?: Maybe<Scalars['ID']>;
  readonly sourceType?: Maybe<Scalars['String']>;
  readonly status?: Maybe<FacilityStatusTypeEnum>;
  readonly taxExempt?: Maybe<Scalars['Boolean']>;
  readonly timezone?: Maybe<Scalars['String']>;
  readonly unloadFromType?: Maybe<KeyValue>;
  readonly unloadFromTypeId?: Maybe<Scalars['ID']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly externalFacilityNotes?: Maybe<ReadonlyArray<FacilityNote>>;
  readonly sourceId?: Maybe<Scalars['String']>;
  readonly mainContactId?: Maybe<Scalars['String']>;
  readonly mainAddressId?: Maybe<Scalars['String']>;
  readonly maximumTrailerAge?: Maybe<Scalars['Int']>;
  readonly schedulingSystemUrl?: Maybe<Scalars['String']>;
};


export type FacilityV2CustomerFacilitiesArgs = {
  customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


export type FacilityV2CustomerRelationshipsArgs = {
  customerFacilityRelationshipLoaderWithLimit?: InputMaybe<Scalars['Int']>;
};

export type FacilityValidation = {
  readonly __typename?: 'FacilityValidation';
  readonly id?: Maybe<Scalars['ID']>;
  readonly code: Scalars['String'];
  readonly mainAddress?: Maybe<FacilityAddressValidation>;
  readonly error?: Maybe<Scalars['String']>;
};

export type FacilityValidationInput = {
  readonly code: Scalars['String'];
};

/** Entity configuration custom fallback - This is used to store custom fallback details */
export type FallbackMapping = {
  readonly __typename?: 'FallbackMapping';
  readonly fallbackOptionId: Scalars['String'];
  readonly optionId: Scalars['ID'];
};

/** Fallback Option */
export type FallbackOption = {
  readonly __typename?: 'FallbackOption';
  readonly id: Scalars['ID'];
};

export type FeatureFlagContentTypeConfig = {
  readonly __typename?: 'FeatureFlagContentTypeConfig';
  readonly featureFlagKey?: Maybe<Scalars['String']>;
  readonly displayMessage?: Maybe<Scalars['String']>;
  readonly contentType: ContentTemplateContentType;
};

/** Indicates how the fee should be calculated for an advance. */
export enum FeeCalculationEnum {
  /** Indicates that the flatFeeAmount will be used for the fee. */
  Flat = 'Flat',
  /** Indicates that the feePercent will be used along with the approved amount to calculate the fee. */
  Percent = 'Percent'
}

/** Indicates how the net payment should be calculated for an advance. */
export enum FeeMethodEnum {
  /** Indicates that the net payment will be calculated using the Net fee method. */
  Net = 'Net',
  /** Indicates that the net payment will be calculated using the Gross fee method. */
  Gross = 'Gross'
}

export type FieldConfigurationInputType = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly fieldName: Scalars['String'];
  readonly fullyQualifiedName?: InputMaybe<Scalars['String']>;
  readonly order?: InputMaybe<Scalars['Int']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly mappingOption?: InputMaybe<MappingOptionInputType>;
  readonly fieldConstraintValues: ReadonlyArray<FieldConstraintValueInputType>;
};

export type FieldConfigurationOutputType = {
  readonly __typename?: 'FieldConfigurationOutputType';
  readonly id?: Maybe<Scalars['String']>;
  readonly fieldName?: Maybe<Scalars['String']>;
  readonly fullyQualifiedName?: Maybe<Scalars['String']>;
  readonly order?: Maybe<Scalars['Int']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly mappingOption?: Maybe<MappingOptionOutputType>;
  readonly fieldConstraintValues?: Maybe<ReadonlyArray<Maybe<FieldConstraintValueOutputType>>>;
};

export type FieldConstraintInputType = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Scalars['String']>;
  readonly order: Scalars['Int'];
};

export type FieldConstraintOutputType = {
  readonly __typename?: 'FieldConstraintOutputType';
  readonly id?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly order?: Maybe<Scalars['Int']>;
};

export type FieldConstraintValueInputType = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly isEditable?: InputMaybe<Scalars['Boolean']>;
  readonly fieldConstraintId: Scalars['String'];
};

export type FieldConstraintValueOutputType = {
  readonly __typename?: 'FieldConstraintValueOutputType';
  readonly id?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly isEditable?: Maybe<Scalars['Boolean']>;
  readonly fieldConstraintId?: Maybe<Scalars['String']>;
};

export type FieldMappingConfigurationInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly referenceId: Scalars['String'];
  readonly transactionType: Scalars['String'];
  readonly direction: Scalars['String'];
  readonly segmentConfigurations: ReadonlyArray<SegmentConfigurationInputType>;
  readonly fieldConstraints?: InputMaybe<ReadonlyArray<InputMaybe<FieldConstraintInputType>>>;
};

export type FieldMappingConfigurationOutputType = {
  readonly __typename?: 'FieldMappingConfigurationOutputType';
  readonly id?: Maybe<Scalars['String']>;
  readonly referenceId?: Maybe<Scalars['String']>;
  readonly transactionType?: Maybe<Scalars['String']>;
  readonly direction?: Maybe<Scalars['String']>;
  readonly fieldConstraints?: Maybe<ReadonlyArray<Maybe<FieldConstraintOutputType>>>;
  readonly segmentConfigurations?: Maybe<ReadonlyArray<Maybe<SegmentConfigurationOutputType>>>;
};

export type FieldMappingFilterInput = {
  readonly referenceId: Scalars['UUID'];
  readonly transactionType?: InputMaybe<Scalars['String']>;
  readonly direction?: InputMaybe<Scalars['String']>;
};

export type FileExtractConfiguration = {
  readonly __typename?: 'FileExtractConfiguration';
  readonly extractAvailableFields?: Maybe<ReadonlyArray<Maybe<ExtractAvailableField>>>;
  readonly extractConfigurations?: Maybe<ReadonlyArray<Maybe<ExtractConfigurationType>>>;
};

export type FilteredPlannedNetworksInput = {
  /** Excludes networks, channels, and legs which are inactive when true. Does not filter when false. */
  readonly onlyActive: Scalars['Boolean'];
  /** Excludes networks, channels, and legs that are not effective on this date. Does not filter when null. */
  readonly effectiveOn?: InputMaybe<Scalars['Date']>;
  /** The planned network IDs to filter by. Does not filter when null. */
  readonly plannedNetworkIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** The network channel IDs to filter by. Does not filter when null. */
  readonly networkChannelIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** The channel leg IDs to filter by. Does not filter when null. */
  readonly channelLegIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** The origin facility IDs to filter by. Does not filter when null. */
  readonly originFacilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** The destination facility IDs to filter by. Does not filter when null. */
  readonly destinationFacilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** The assigned rep employee IDs to filter by. Does not filter when null. */
  readonly assignedRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type FilterInputs = {
  readonly ddtId?: InputMaybe<Scalars['String']>;
  readonly entityId?: InputMaybe<Scalars['String']>;
  readonly entityType?: InputMaybe<DdtEntityType>;
};

export type FilterServiceProfile = {
  readonly boxType: Scalars['String'];
  readonly originRamp?: InputMaybe<Scalars['String']>;
  readonly originRampArr?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRamp?: InputMaybe<Scalars['String']>;
  readonly destinationRampArr?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type FilterServiceProfileV2 = {
  readonly boxType: Scalars['String'];
  readonly originRamps?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRamps?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierId: Scalars['String'];
};

export type FinalizedFuelStatus = {
  readonly __typename?: 'FinalizedFuelStatus';
  readonly orderId: Scalars['ID'];
  readonly finalizedFuelStatus?: Maybe<Scalars['String']>;
};

export type FinalizedFuelSurcharge = {
  readonly __typename?: 'FinalizedFuelSurcharge';
  readonly customerId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly fuelProgram?: Maybe<Scalars['String']>;
  readonly fuelSurcharge?: Maybe<Scalars['Decimal']>;
  readonly units?: Maybe<Scalars['Decimal']>;
  readonly ratePerUnit?: Maybe<Scalars['Decimal']>;
  readonly currency?: Maybe<Scalars['String']>;
};

export type FindByRouteIdQueryInput = {
  readonly routeId: Scalars['ID'];
  readonly allowCancelled?: InputMaybe<Scalars['Boolean']>;
};

export type FindMatchingLanesForRoutesInput = {
  readonly loadId: Scalars['ID'];
  readonly loadCode: Scalars['String'];
  readonly matchInputs: ReadonlyArray<RouteLaneMatchInput>;
};

export type FindMatchingLanesForRoutesResult = {
  readonly __typename?: 'FindMatchingLanesForRoutesResult';
  readonly loadId: Scalars['ID'];
  readonly loadCode: Scalars['String'];
  readonly results: ReadonlyArray<RouteLaneMatch>;
};

export type FindOrCreateFacilityInput = {
  readonly address: StopAddressInput;
  readonly customerId: Scalars['String'];
  readonly locationCode: Scalars['String'];
  readonly stopType: StopTypeEnum;
};

export type FindOrCreateFacilityInputV2 = {
  readonly address: StopAddressInputV2;
  readonly customerId: Scalars['String'];
  readonly facilityId?: InputMaybe<Scalars['String']>;
  readonly locationCode: Scalars['String'];
  readonly stopType: StopTypeEnum;
  readonly status?: InputMaybe<ReadonlyArray<FacilityStatusTypeEnum>>;
};

export type FindOrCreateFacilityInputV3 = {
  readonly address: StopAddressInputV2;
  readonly customerId: Scalars['String'];
  readonly facilityId?: InputMaybe<Scalars['String']>;
  readonly locationCode?: InputMaybe<Scalars['String']>;
  readonly stopType: Scalars['String'];
  readonly qualifier?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<ReadonlyArray<FacilityStatusTypeEnum>>;
};

/** Autogenerated return type of FindOrCreateFacility */
export type FindOrCreateFacilityPayload = {
  readonly __typename?: 'FindOrCreateFacilityPayload';
  readonly errors: ReadonlyArray<UserError>;
  /** @deprecated use facilityNullable */
  readonly facility: Facility;
  readonly facilityNullable?: Maybe<Facility>;
};

export type FindOrCreateFacilityPayloadV2 = {
  readonly __typename?: 'FindOrCreateFacilityPayloadV2';
  readonly errors: ReadonlyArray<UserError>;
  readonly facility?: Maybe<FacilityV2>;
};

export type FirstTruckEntriesPayloadWithErrorsAndTruckEntries = {
  readonly __typename?: 'FirstTruckEntriesPayloadWithErrorsAndTruckEntries';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly truckEntry?: Maybe<ReadonlyArray<TruckEntry>>;
};

/** type for fleet driver input */
export type FleetDriverInput = {
  /** supports driver numbers 1 or 2 */
  readonly number: Scalars['Int'];
  readonly displayName?: InputMaybe<Scalars['String']>;
  readonly assetDriverID?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
};

export enum FleetRelationshipBehaviorEnum {
  None = 'NONE',
  PrimaryOnly = 'PRIMARY_ONLY',
  PrimaryAndSecondaryInherited = 'PRIMARY_AND_SECONDARY_INHERITED',
  PrimaryAndSecondary = 'PRIMARY_AND_SECONDARY'
}

export type FlipInput = {
  /** An array of route ids recalculation is being requested for. */
  readonly entityIds: ReadonlyArray<Scalars['ID']>;
  /** Whether to treat the stop event or route resource trailers as truth: 'stopEvent' | 'routeResource' cannot be a combination of types. */
  readonly sourceOfTruth: Scalars['String'];
};

export type FloatType = {
  readonly __typename?: 'FloatType';
  readonly value?: Maybe<Scalars['Float']>;
};

export type FollowUpTime = {
  readonly __typename?: 'FollowUpTime';
  /** @deprecated Field no longer supported */
  readonly conversationId: Scalars['String'];
  readonly userId: Scalars['String'];
  readonly followUpDateTime: Scalars['String'];
};

export type FollowUpTimesInput = {
  readonly filter: FollowUpTimesInputFilter;
};

export type FollowUpTimesInputFilter = {
  readonly conversationId?: InputMaybe<Scalars['String']>;
  readonly userId?: InputMaybe<Scalars['String']>;
};

export type FollowUpTimesResponse = {
  readonly __typename?: 'FollowUpTimesResponse';
  /** @deprecated Use FollowUpTimeV2 instead */
  readonly followUpTimes: ReadonlyArray<Maybe<FollowUpTime>>;
};

export type FollowUpTimeV2 = {
  readonly __typename?: 'FollowUpTimeV2';
  readonly conversation: DriverConversationType;
  readonly drivers: ReadonlyArray<Scalars['String']>;
  readonly userId: Scalars['String'];
  readonly followUpDateTime: Scalars['String'];
  readonly id: Scalars['String'];
};

export type FormattedAddress = {
  readonly __typename?: 'FormattedAddress';
  readonly requestId?: Maybe<Scalars['ID']>;
  readonly addressLine1: Scalars['String'];
  readonly addressLine2?: Maybe<Scalars['String']>;
  readonly addressLine3?: Maybe<Scalars['String']>;
  readonly addressLine4?: Maybe<Scalars['String']>;
  readonly locality: Scalars['String'];
  readonly dependentLocality?: Maybe<Scalars['String']>;
  readonly administrativeArea: Scalars['String'];
  readonly administrativeAreaCode?: Maybe<Scalars['String']>;
  readonly administrativeArea2?: Maybe<Scalars['String']>;
  readonly countryAlpha2: Scalars['String'];
  readonly countryAlpha3: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
  readonly UTC: Scalars['String'];
  readonly DST: Scalars['String'];
  readonly lat?: Maybe<Scalars['Float']>;
  readonly lon?: Maybe<Scalars['Float']>;
  readonly geocodeMatchStrength?: Maybe<Scalars['Int']>;
};

export type Fraction = {
  readonly __typename?: 'Fraction';
  readonly numerator: Scalars['Float'];
  readonly denominator: Scalars['Float'];
};

/** for RegionV2 fields */
export type FreightAssignmentRegion = {
  readonly __typename?: 'FreightAssignmentRegion';
  readonly superRegion?: Maybe<Scalars['String']>;
  readonly region?: Maybe<Scalars['String']>;
  readonly subRegion?: Maybe<Scalars['String']>;
};

/** Sets the order of the results returned based on the column selected for ordering. */
export enum FrequencyTypes {
  Off = 'Off',
  Fixed = 'Fixed',
  Every = 'Every'
}

export type FtlPricesByQuoteFacilityStopInputType = {
  readonly facilityId?: InputMaybe<Scalars['ID']>;
};

export type FtlPricesByQuoteGeographyStopInputType = {
  readonly geographySourceId?: InputMaybe<Scalars['Int']>;
};

export type FtlPricesByQuoteInputType = {
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly commodities: ReadonlyArray<InputMaybe<PriceFtlQuoteCommodityType>>;
  readonly customerId: Scalars['ID'];
  readonly desiredDeliverDate?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly equipments?: InputMaybe<PriceFtlQuoteEquipmentType>;
  readonly loadSize: Scalars['String'];
  readonly orderSCAC?: InputMaybe<Scalars['String']>;
  readonly quoteEffectiveDate?: InputMaybe<Scalars['String']>;
  readonly quoteExpirationDate?: InputMaybe<Scalars['String']>;
  readonly quoteProjectTerm?: InputMaybe<Scalars['String']>;
  readonly requirements: ReadonlyArray<Scalars['String']>;
  readonly shipEndDate?: InputMaybe<Scalars['String']>;
  readonly shipStartDate?: InputMaybe<Scalars['String']>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<PriceFtlQuoteStopType>>>;
  readonly transportMode: Scalars['String'];
};

export type FtlPricesByQuotePostalCodeStopInputType = {
  readonly countryCode?: InputMaybe<Scalars['String']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
};

export type FtlPricesByQuoteType = {
  readonly __typename?: 'FtlPricesByQuoteType';
  readonly customer?: Maybe<FtlPricingCustomerV2Type>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<FtlPricingErrorDetailsType>>>;
  readonly quoteCode?: Maybe<Scalars['String']>;
  readonly quoteCorrelationId?: Maybe<Scalars['String']>;
  readonly quoteId?: Maybe<Scalars['String']>;
  readonly rateLineItems?: Maybe<ReadonlyArray<Maybe<FtlPricingRateLineItemType>>>;
  readonly totalCharge?: Maybe<ExternalQuoteTotalChargeResponseType>;
};

export type FtlPricingCustomerV2Type = {
  readonly __typename?: 'FtlPricingCustomerV2Type';
  readonly customer: CustomerV2;
  readonly id?: Maybe<Scalars['Uuid']>;
};

/** Holds Price Ftl errors. */
export type FtlPricingErrorDetailsType = {
  readonly __typename?: 'FtlPricingErrorDetailsType';
  readonly code?: Maybe<Scalars['String']>;
  readonly details?: Maybe<FtlPricingErrorType>;
  readonly message?: Maybe<Scalars['String']>;
};

/** Holds Price Ftl errors. */
export type FtlPricingErrorType = {
  readonly __typename?: 'FtlPricingErrorType';
  readonly additionalProp1?: Maybe<Scalars['String']>;
  readonly additionalProp2?: Maybe<Scalars['String']>;
  readonly additionalProp3?: Maybe<Scalars['String']>;
};

export type FtlPricingRateLineItemType = {
  readonly __typename?: 'FtlPricingRateLineItemType';
  readonly chargeTypeId: Scalars['ID'];
  readonly currency?: Maybe<Scalars['String']>;
  readonly ratePer: Scalars['Decimal'];
  readonly units: Scalars['Decimal'];
};

export type FuelProgramCarrierConfigurationCreateInput = {
  readonly defaultConfiguration: Scalars['Boolean'];
  readonly transportMode?: InputMaybe<Scalars['String']>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly trailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** For Regional Relationship Type Implementation, this Region field is considering as Outbound Region. */
  readonly region?: InputMaybe<Scalars['String']>;
  readonly fuelProgram: Scalars['String'];
  readonly isEstimate: Scalars['Boolean'];
  readonly vendorId: Scalars['Guid'];
  readonly references?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** For Regional Relationship Type Implementation, this CountryCode field is considering as Outbound Country. */
  readonly countryCode?: InputMaybe<Scalars['String']>;
  readonly effectiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly datesBasedOn?: InputMaybe<Scalars['String']>;
  readonly fuelProgramName?: InputMaybe<Scalars['String']>;
  readonly regionalRelationshipType?: InputMaybe<Scalars['String']>;
  readonly inboundCountry?: InputMaybe<Scalars['String']>;
  readonly inboundRegion?: InputMaybe<Scalars['String']>;
  readonly onlyApplyForCostQuote?: InputMaybe<Scalars['Boolean']>;
  readonly priority?: InputMaybe<Scalars['Int']>;
};

export type FuelProgramCarrierConfigurationModel = {
  readonly __typename?: 'FuelProgramCarrierConfigurationModel';
  readonly fuelProgramCarrierConfigurationId: Scalars['ID'];
  readonly createdByUserId?: Maybe<Scalars['Guid']>;
  readonly createdByDesc?: Maybe<Scalars['String']>;
  readonly createdOnUtc?: Maybe<Scalars['DateTime']>;
  readonly updatedByUserId?: Maybe<Scalars['Guid']>;
  readonly updatedByDesc?: Maybe<Scalars['String']>;
  readonly updatedOnUtc?: Maybe<Scalars['DateTime']>;
  readonly defaultConfiguration?: Maybe<Scalars['Boolean']>;
  readonly transportMode?: Maybe<Scalars['String']>;
  readonly loadSize?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly trailerTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** For Regional Relationship Type Implementation, this Region field is considering as Outbound Region. */
  readonly region?: Maybe<Scalars['String']>;
  readonly fuelProgram?: Maybe<Scalars['String']>;
  readonly isEstimate?: Maybe<Scalars['Boolean']>;
  readonly vendorId: Scalars['Guid'];
  readonly references?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** For Regional Relationship Type Implementation, this CountryCode field is considering as Outbound Country. */
  readonly countryCode: Scalars['String'];
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly datesBasedOn?: Maybe<Scalars['String']>;
  readonly fuelProgramName?: Maybe<Scalars['String']>;
  readonly regionalRelationshipType?: Maybe<Scalars['String']>;
  readonly inboundCountry?: Maybe<Scalars['String']>;
  readonly inboundRegion?: Maybe<Scalars['String']>;
  readonly onlyApplyForCostQuote?: Maybe<Scalars['Boolean']>;
  readonly priority?: Maybe<Scalars['Int']>;
};

export type FuelProgramCarrierConfigurationUpdateInput = {
  readonly fuelProgramCarrierConfigurationId: Scalars['ID'];
  readonly defaultConfiguration: Scalars['Boolean'];
  readonly transportMode?: InputMaybe<Scalars['String']>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly trailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** For Regional Relationship Type Implementation, this Region field is considering as Outbound Region. */
  readonly region?: InputMaybe<Scalars['String']>;
  readonly fuelProgram: Scalars['String'];
  readonly isEstimate: Scalars['Boolean'];
  readonly vendorId: Scalars['Guid'];
  readonly references?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** For Regional Relationship Type Implementation, this CountryCode field is considering as Outbound Country. */
  readonly countryCode?: InputMaybe<Scalars['String']>;
  readonly effectiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly datesBasedOn?: InputMaybe<Scalars['String']>;
  readonly fuelProgramName?: InputMaybe<Scalars['String']>;
  readonly regionalRelationshipType?: InputMaybe<Scalars['String']>;
  readonly inboundCountry?: InputMaybe<Scalars['String']>;
  readonly inboundRegion?: InputMaybe<Scalars['String']>;
  readonly onlyApplyForCostQuote?: InputMaybe<Scalars['Boolean']>;
  readonly priority?: InputMaybe<Scalars['Int']>;
};

export type FuelProgramCustomerConfigurationCreateInput = {
  readonly defaultConfiguration: Scalars['Boolean'];
  readonly transportMode?: InputMaybe<Scalars['String']>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly trailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** For Regional Relationship Type Implementation, this Region field is considering as Outbound Region. */
  readonly region?: InputMaybe<Scalars['String']>;
  readonly fuelProgram: Scalars['String'];
  readonly isEstimate: Scalars['Boolean'];
  readonly customerId: Scalars['Guid'];
  readonly shipper?: InputMaybe<Scalars['String']>;
  readonly references?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** For Regional Relationship Type Implementation, this CountryCode field is considering as Outbound Country. */
  readonly countryCode?: InputMaybe<Scalars['String']>;
  readonly effectiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly datesBasedOn?: InputMaybe<Scalars['String']>;
  readonly fuelProgramName?: InputMaybe<Scalars['String']>;
  readonly regionalRelationshipType?: InputMaybe<Scalars['String']>;
  readonly inboundCountry?: InputMaybe<Scalars['String']>;
  readonly inboundRegion?: InputMaybe<Scalars['String']>;
  readonly onlyApplyForRateQuote?: InputMaybe<Scalars['Boolean']>;
  readonly priority?: InputMaybe<Scalars['Int']>;
};

export type FuelProgramCustomerConfigurationModel = {
  readonly __typename?: 'FuelProgramCustomerConfigurationModel';
  readonly fuelProgramCustomerConfigurationId: Scalars['ID'];
  readonly createdByUserId?: Maybe<Scalars['Guid']>;
  readonly createdByDesc?: Maybe<Scalars['String']>;
  readonly createdOnUtc?: Maybe<Scalars['DateTime']>;
  readonly updatedByUserId?: Maybe<Scalars['Guid']>;
  readonly updatedByDesc?: Maybe<Scalars['String']>;
  readonly updatedOnUtc?: Maybe<Scalars['DateTime']>;
  readonly defaultConfiguration?: Maybe<Scalars['Boolean']>;
  readonly transportMode?: Maybe<Scalars['String']>;
  readonly loadSize?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly trailerTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly region?: Maybe<Scalars['String']>;
  readonly fuelProgram?: Maybe<Scalars['String']>;
  readonly isEstimate?: Maybe<Scalars['Boolean']>;
  readonly customerId: Scalars['Guid'];
  readonly shipper?: Maybe<Scalars['String']>;
  readonly references?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly countryCode: Scalars['String'];
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly datesBasedOn?: Maybe<Scalars['String']>;
  readonly fuelProgramName?: Maybe<Scalars['String']>;
  readonly regionalRelationshipType?: Maybe<Scalars['String']>;
  readonly inboundCountry?: Maybe<Scalars['String']>;
  readonly inboundRegion?: Maybe<Scalars['String']>;
  readonly onlyApplyForRateQuote?: Maybe<Scalars['Boolean']>;
  readonly priority?: Maybe<Scalars['Int']>;
};

export type FuelProgramCustomerConfigurationUpdateInput = {
  readonly fuelProgramCustomerConfigurationId: Scalars['ID'];
  readonly defaultConfiguration: Scalars['Boolean'];
  readonly transportMode?: InputMaybe<Scalars['String']>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly trailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** For Regional Relationship Type Implementation, this Region field is considering as Outbound Region. */
  readonly region?: InputMaybe<Scalars['String']>;
  readonly fuelProgram: Scalars['String'];
  readonly isEstimate: Scalars['Boolean'];
  readonly customerId: Scalars['Guid'];
  readonly shipper?: InputMaybe<Scalars['String']>;
  readonly references?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** For Regional Relationship Type Implementation, this CountryCode field is considering as Outbound Country. */
  readonly countryCode?: InputMaybe<Scalars['String']>;
  readonly effectiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly datesBasedOn?: InputMaybe<Scalars['String']>;
  readonly fuelProgramName?: InputMaybe<Scalars['String']>;
  readonly regionalRelationshipType?: InputMaybe<Scalars['String']>;
  readonly inboundCountry?: InputMaybe<Scalars['String']>;
  readonly inboundRegion?: InputMaybe<Scalars['String']>;
  readonly onlyApplyForRateQuote?: InputMaybe<Scalars['Boolean']>;
  readonly priority?: InputMaybe<Scalars['Int']>;
};

export type FuelSurchargeForExternalServices = {
  readonly __typename?: 'FuelSurchargeForExternalServices';
  readonly customerId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly fuelSurcharge?: Maybe<Scalars['Decimal']>;
  readonly fuelProgramCustomerConfigurationModel: FuelProgramCustomerConfigurationModel;
  readonly units?: Maybe<Scalars['Decimal']>;
  readonly ratePerUnit?: Maybe<Scalars['Decimal']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly totalRate?: Maybe<Scalars['Decimal']>;
  readonly enteredTotalRate?: Maybe<Scalars['Decimal']>;
  readonly chargeTypeId: Scalars['ID'];
};

export type GateReservationConnection = {
  readonly __typename?: 'GateReservationConnection';
  readonly edges?: Maybe<ReadonlyArray<Maybe<GateReservationGraphQlEdge>>>;
  readonly pageInfo?: Maybe<PageInfo>;
  readonly totalCount?: Maybe<Scalars['Int']>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<TrucksUserError>>>;
};

export type GateReservationFilter = {
  readonly carrierId: Scalars['ID'];
  /** DEPRECATED: Use containerProgramId instead */
  readonly containerProgramCarrierId?: InputMaybe<Scalars['ID']>;
  readonly containerProgramId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly endDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly startDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly containerTypeId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly reservationNumber?: InputMaybe<Scalars['String']>;
  readonly sourceTypeId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly originRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly capacityUtilizationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type GateReservationGraphQl = {
  readonly __typename?: 'GateReservationGraphQL';
  /** The utilization status of the capacity. Correlates to DDT id "capacity_utilization_status" */
  readonly capacityUtilizationStatusId: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly originRampFacilityId: Scalars['ID'];
  readonly destinationRampFacilityId?: Maybe<Scalars['ID']>;
  readonly reservationNumber: Scalars['String'];
  readonly containerTypeId: Scalars['ID'];
  readonly containerLength?: Maybe<UnitOfDistance>;
  readonly startDatetimeUtc: Scalars['ISO8601DateTime'];
  readonly startTimezone?: Maybe<Scalars['String']>;
  readonly endDatetimeUtc: Scalars['ISO8601DateTime'];
  readonly endTimezone: Scalars['String'];
  /**
   * DEPRECATED: Use containerProgramId instead
   * @deprecated Use containerProgramId instead
   */
  readonly containerProgramCarrierId?: Maybe<Scalars['ID']>;
  readonly containerProgramId?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly createdByUserId: Scalars['ID'];
  readonly updatedByUserId: Scalars['ID'];
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** DDT ID for the source of the capacity (e.g. manual, api, autotruck, dat, reload intent, etc.) */
  readonly sourceTypeId?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated. Please use capacityUtilizationStatusId */
  readonly utilizationStatus: UtilizationStatusEnum;
  readonly originRampFacility: FacilityV2;
  readonly destinationRampFacility?: Maybe<FacilityV2>;
  readonly containerProgram?: Maybe<KeyValueType>;
  readonly containerType?: Maybe<KeyValueType>;
  readonly carrier: CarrierV2;
  readonly createdByUser: UserV2;
  readonly updatedByUser: UserV2;
  readonly capacityUtilizationStatus?: Maybe<KeyValueType>;
};

export type GateReservationGraphQlEdge = {
  readonly __typename?: 'GateReservationGraphQLEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<GateReservationGraphQl>;
};

/** Gate Reservation Match Result from ElasticSearch (v2) */
export type GateReservationMatch = {
  readonly __typename?: 'GateReservationMatch';
  readonly id: Scalars['ID'];
  readonly routeId: Scalars['String'];
  readonly loadId: Scalars['String'];
  readonly loadNumber: Scalars['String'];
  readonly capacityNeed?: Maybe<ReadonlyArray<CapacityNeed>>;
  readonly actedUpon?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly originDeadheadMileage?: Maybe<Scalars['Float']>;
  readonly destinationDeadheadMileage?: Maybe<Scalars['Float']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly routePickupCity?: Maybe<Scalars['String']>;
  readonly routePickupState?: Maybe<Scalars['String']>;
  readonly routePickupCountry?: Maybe<Scalars['String']>;
  readonly routePickupStartDay?: Maybe<Scalars['String']>;
  /** Date and timezone for route pickup start date */
  readonly routePickupStartDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `routePickupStartDateZone` */
  readonly routePickupStartDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `routePickupStartDateZone` and `routePickupEndDateZone` */
  readonly routePickupTimeZone?: Maybe<Scalars['String']>;
  readonly routePickupEndDay?: Maybe<Scalars['String']>;
  /** Date and timezone for route pickup end date */
  readonly routePickupEndDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `routePickupEndDateZone` */
  readonly routePickupEndDate?: Maybe<Scalars['DateTime']>;
  readonly routeFirstStopDateRange?: Maybe<AvailableDateRange>;
  readonly routeFirstStopLiveType?: Maybe<ScheduleDropTypeEnum>;
  readonly routeDeliveryCity?: Maybe<Scalars['String']>;
  readonly routeDeliveryState?: Maybe<Scalars['String']>;
  readonly routeDeliveryCountry?: Maybe<Scalars['String']>;
  readonly routeDeliveryStartDay?: Maybe<Scalars['String']>;
  /** Date and timezone for route delivery start date */
  readonly routeDeliveryStartDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `routeDeliveryStartDateZone` */
  readonly routeDeliveryStartDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `routeDeliveryStartDateZone` and `routeDeliveryEndDateZone` */
  readonly routeDeliveryTimeZone?: Maybe<Scalars['String']>;
  readonly routeDeliveryEndDay?: Maybe<Scalars['String']>;
  /** Date and timezone for route delivery end date */
  readonly routeDeliveryEndDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `routeDeliveryEndDateZone` */
  readonly routeDeliveryEndDate?: Maybe<Scalars['DateTime']>;
  readonly routeLastStopDateRange?: Maybe<AvailableDateRange>;
  readonly routeLastStopLiveType?: Maybe<ScheduleDropTypeEnum>;
  readonly routeLength?: Maybe<Scalars['Int']>;
  readonly routeTrailerLength?: Maybe<Length>;
  readonly routeCommodityMinTemperature?: Maybe<TemperatureV2>;
  readonly routeAssignedReps?: Maybe<ReadonlyArray<AvailableRouteRep>>;
  readonly routeCustomerOrderReps?: Maybe<ReadonlyArray<AvailableRouteRep>>;
  readonly stopCount?: Maybe<Scalars['Int']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly routeTrailerTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly segmentCode?: Maybe<Scalars['String']>;
  readonly routeDivisions?: Maybe<ReadonlyArray<AvailableRouteDivision>>;
  readonly requirements?: Maybe<ReadonlyArray<OrderRequirementInfo>>;
  readonly hotRoute?: Maybe<Scalars['Boolean']>;
  readonly tmsType?: Maybe<Scalars['String']>;
  readonly mainCustomerRepEmployeeId?: Maybe<Scalars['String']>;
  readonly expectedMaxWeight?: Maybe<Scalars['Float']>;
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly equipment?: Maybe<Scalars['String']>;
  readonly routeLoadRouteSequence?: Maybe<Fraction>;
  readonly routeAppointmentStatus?: Maybe<Scalars['String']>;
  readonly routeLifecycleStatus?: Maybe<Scalars['String']>;
  readonly routeOrganizationName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'routeCustomerReps' */
  readonly customerRepName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'routeCustomerReps' */
  readonly customerRepId?: Maybe<Scalars['String']>;
  /** @deprecated Use 'routeDivisions' */
  readonly division?: Maybe<Scalars['String']>;
  readonly gateReservationId: Scalars['String'];
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly originRampFacilityId?: Maybe<Scalars['String']>;
  readonly originRampFacilityName?: Maybe<Scalars['String']>;
  readonly originRampCity?: Maybe<Scalars['String']>;
  readonly originRampState?: Maybe<Scalars['String']>;
  readonly destinationRampFacilityId?: Maybe<Scalars['String']>;
  readonly destinationRampFacilityName?: Maybe<Scalars['String']>;
  readonly destinationRampCity?: Maybe<Scalars['String']>;
  readonly destinationRampState?: Maybe<Scalars['String']>;
  readonly routeOriginRamp?: Maybe<Scalars['String']>;
  readonly routeDestinationRamp?: Maybe<Scalars['String']>;
  readonly capacityUtilizationStatusId: Scalars['String'];
  readonly route?: Maybe<AvailableRoute>;
  readonly gateReservation?: Maybe<AvailableGateReservation>;
};

export type GateReservationMatchConnection = {
  readonly __typename?: 'GateReservationMatchConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<GateReservationMatchEdge>;
  readonly totalCount: Scalars['Float'];
};

export type GateReservationMatchEdge = {
  readonly __typename?: 'GateReservationMatchEdge';
  readonly node: GateReservationMatch;
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String'];
};

/** Gate Reservation Match Filter */
export type GateReservationMatchFilter = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly matchId?: InputMaybe<Scalars['String']>;
  readonly routeId?: InputMaybe<Scalars['String']>;
  readonly routeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadId?: InputMaybe<Scalars['String']>;
  readonly capacityNeed?: InputMaybe<ReadonlyArray<CapacityNeed>>;
  readonly excludeActedUpon?: InputMaybe<Scalars['Boolean']>;
  readonly actedUpon?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeFirstStopDateRange?: InputMaybe<DateRangeInput>;
  readonly routeLastStopDateRange?: InputMaybe<DateRangeInput>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierName?: InputMaybe<Scalars['String']>;
  readonly routeActivationStatus?: InputMaybe<Scalars['String']>;
  readonly routeActivationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly originDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  readonly destinationDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  readonly includeDeleted?: InputMaybe<Scalars['Boolean']>;
  readonly routeNumber?: InputMaybe<Scalars['String']>;
  readonly routePickupCity?: InputMaybe<Scalars['String']>;
  readonly routePickupState?: InputMaybe<Scalars['String']>;
  readonly routePickupStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routePickupCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routePickupDate?: InputMaybe<Scalars['DateTime']>;
  readonly routePickupTimeZone?: InputMaybe<Scalars['String']>;
  readonly routeDeliveryCity?: InputMaybe<Scalars['String']>;
  readonly routeDeliveryState?: InputMaybe<Scalars['String']>;
  readonly routeDeliveryStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDeliveryCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeFirstStopRadius?: InputMaybe<WithinRadiusInput>;
  readonly routeLastStopRadius?: InputMaybe<WithinRadiusInput>;
  readonly routeDeliveryDate?: InputMaybe<Scalars['DateTime']>;
  readonly routeDeliveryTimeZone?: InputMaybe<Scalars['String']>;
  readonly routeCommodityMinTemperature?: InputMaybe<TemperatureV2Input>;
  readonly routeLength?: InputMaybe<Scalars['Int']>;
  readonly stopCount?: InputMaybe<Scalars['Int']>;
  readonly maxCost?: InputMaybe<Scalars['Float']>;
  readonly routeFirstStopLiveType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLastStopLiveType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerName?: InputMaybe<Scalars['String']>;
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerRepName?: InputMaybe<Scalars['String']>;
  readonly customerRepIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equipment?: InputMaybe<Scalars['String']>;
  readonly routeTrailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns matches where the route primary or additional divisions includes the input terms */
  readonly routeDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns matches where the route primary division is null or includes the input terms */
  readonly routeDivisionsIncludeNull?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns matches where the route additional divisions includes the input terms */
  readonly additionalRouteDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns matches where the employee's primary divsion matches a route's primary or additional divisions */
  readonly primaryEmployeeDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns matches where the employee's additional divsions match a route's primary division */
  readonly additionalEmployeeDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly routeRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
  readonly routeTrailerLength?: InputMaybe<UnitOfLengthInput>;
  readonly routeAssignedRepIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeAppointmentStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLifecycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly capacityUtilizationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeOrganizationHierarchyCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly requirements?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly dateRangeFilterTarget?: InputMaybe<MatchesDateRangeFilterTarget>;
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  readonly gateReservationId?: InputMaybe<Scalars['String']>;
  readonly gateReservationIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly timeZone?: InputMaybe<Scalars['String']>;
  readonly originRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRampFacilityName?: InputMaybe<Scalars['String']>;
  readonly originRampCity?: InputMaybe<Scalars['String']>;
  readonly originRampStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRampFacilityName?: InputMaybe<Scalars['String']>;
  readonly destinationRampCity?: InputMaybe<Scalars['String']>;
  readonly destinationRampStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeOriginRamp?: InputMaybe<Scalars['String']>;
  readonly routeDestinationRamp?: InputMaybe<Scalars['String']>;
};

export type GateReservationPayloadWithErrorsAndGateReservation = {
  readonly __typename?: 'GateReservationPayloadWithErrorsAndGateReservation';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly gateReservation?: Maybe<GateReservationGraphQl>;
};

export type GateReservationSort = {
  readonly key: GateReservationSortKeysEnum;
  readonly direction: TruckEntrySortDirectionsEnum;
};

export enum GateReservationSortKeysEnum {
  ReservationNumber = 'reservationNumber',
  StartDatetimeUtc = 'startDatetimeUtc',
  EndDatetimeUtc = 'endDatetimeUtc',
  SourceTypeId = 'sourceTypeId',
  UpdatedAt = 'updatedAt',
  ContainerProgramId = 'containerProgramId',
  ContainerTypeId = 'containerTypeId',
  CapacityUtilizationStatus = 'capacityUtilizationStatus'
}

export type GeneralAttributeInput = {
  readonly credentials?: InputMaybe<Scalars['String']>;
};

export type GenerateInvitationInput = {
  readonly firstName?: InputMaybe<Scalars['String']>;
  readonly lastName?: InputMaybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly roleId?: InputMaybe<Scalars['ID']>;
  readonly sourceId: Scalars['String'];
  readonly tenantId: Scalars['String'];
  readonly initiatedBy: Scalars['String'];
};

export type GeocodeInput = {
  readonly addressLine1?: InputMaybe<Scalars['String']>;
  readonly locality?: InputMaybe<Scalars['String']>;
  readonly administrativeArea?: InputMaybe<Scalars['String']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly countryCode?: InputMaybe<Scalars['String']>;
  readonly requestId?: InputMaybe<Scalars['ID']>;
  readonly freeText?: InputMaybe<Scalars['String']>;
  readonly fuzzyLocalityConversion?: InputMaybe<Scalars['Boolean']>;
  readonly maxFuzzyLevel?: InputMaybe<Scalars['Int']>;
  readonly minimumMatchStrength?: InputMaybe<Scalars['Int']>;
};

export type GeocodeInputV2 = {
  readonly addressLine1?: InputMaybe<Scalars['String']>;
  readonly locality?: InputMaybe<Scalars['String']>;
  readonly administrativeArea?: InputMaybe<Scalars['String']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly countryCode?: InputMaybe<Scalars['String']>;
  readonly requestId?: InputMaybe<Scalars['ID']>;
  readonly freeText?: InputMaybe<Scalars['String']>;
  readonly fuzzyLocalityConversion?: InputMaybe<Scalars['Boolean']>;
  readonly maxFuzzyLevel?: InputMaybe<Scalars['Int']>;
  readonly minimumMatchStrength?: InputMaybe<Scalars['Int']>;
};

export type GeocodeResponse = {
  readonly __typename?: 'GeocodeResponse';
  readonly lat?: Maybe<Scalars['Float']>;
  readonly lon?: Maybe<Scalars['Float']>;
  readonly addressLine1?: Maybe<Scalars['String']>;
  readonly locality?: Maybe<Scalars['String']>;
  readonly municipality?: Maybe<Scalars['String']>;
  readonly administrativeArea?: Maybe<Scalars['String']>;
  readonly administrativeArea2?: Maybe<Scalars['String']>;
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly countryCodeISO3?: Maybe<Scalars['String']>;
  readonly countryName?: Maybe<Scalars['String']>;
  readonly resultType: GeocodeResultType;
  readonly requestId?: Maybe<Scalars['ID']>;
  readonly matchType?: Maybe<Scalars['String']>;
  readonly matchStrength?: Maybe<Scalars['Int']>;
  readonly isLowConfidenceMatch?: Maybe<Scalars['Boolean']>;
};

export enum GeocodeResultType {
  Approximate = 'Approximate',
  Exact = 'Exact'
}

export type GeofencingLocationAuditHistory = {
  readonly __typename?: 'GeofencingLocationAuditHistory';
  readonly recordId: Scalars['String'];
  readonly tenantId?: Maybe<Scalars['String']>;
  readonly locationId?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly auditDate?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly changeType?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly oldValue?: Maybe<Scalars['String']>;
};

export type GeofencingLocationAuditHistoryFilter = {
  readonly recordId?: InputMaybe<Scalars['String']>;
  readonly toDate?: InputMaybe<Scalars['String']>;
  readonly fromDate?: InputMaybe<Scalars['String']>;
};

export type Geography = {
  readonly __typename?: 'Geography';
  readonly sourceId?: Maybe<Scalars['Int']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly abbreviatedState?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly county?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly geoPoint?: Maybe<Geopoint>;
  readonly geographyType: GeographyTypeEnum;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly state?: Maybe<Scalars['String']>;
  readonly timezone?: Maybe<Scalars['String']>;
  readonly township?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
  readonly updatedByUserV2?: Maybe<UserV2>;
};

export type GeographyAdminArea = {
  readonly __typename?: 'GeographyAdminArea';
  readonly id: Scalars['ID'];
  readonly twoCharacter?: Maybe<Scalars['String']>;
  readonly threeCharacter?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
};

export type GeographyAdminArea2 = {
  readonly __typename?: 'GeographyAdminArea2';
  readonly id: Scalars['ID'];
  readonly admin1_code?: Maybe<Scalars['String']>;
  readonly admin2_code?: Maybe<Scalars['String']>;
  readonly countryIso?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly plainName?: Maybe<Scalars['String']>;
  readonly sourceId?: Maybe<Scalars['Int']>;
};

export type GeographyAdminAreaInput = {
  readonly adminArea: Scalars['String'];
};

export type GeographyAuditHistory = {
  readonly __typename?: 'GeographyAuditHistory';
  readonly recordId: Scalars['String'];
  readonly auditDate?: Maybe<Scalars['ISO8601DateTime']>;
  readonly sourceId?: Maybe<Scalars['Int']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly changeType?: Maybe<Scalars['String']>;
  readonly changes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type GeographyAuditHistoryFilter = {
  readonly recordId?: InputMaybe<Scalars['String']>;
  readonly toDate?: InputMaybe<Scalars['String']>;
  readonly fromDate?: InputMaybe<Scalars['String']>;
};

export type GeographyCursorPageInfo = {
  readonly __typename?: 'GeographyCursorPageInfo';
  readonly lastCursor?: Maybe<Scalars['Int']>;
  readonly hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type GeographyFilter = {
  readonly geographyIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly countries?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly county?: InputMaybe<Scalars['String']>;
  readonly geographyType?: InputMaybe<GeographyServiceTypeEnum>;
  readonly geographyTypes?: InputMaybe<ReadonlyArray<InputMaybe<GeographyServiceTypeEnum>>>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly states?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly geographyId?: InputMaybe<Scalars['Int']>;
  readonly modifiedAfter?: InputMaybe<Scalars['String']>;
  readonly includeDeleted?: InputMaybe<Scalars['Boolean']>;
  readonly legacyId?: InputMaybe<Scalars['String']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly geoPoint?: InputMaybe<GeoPointInput>;
  readonly bypassCache?: InputMaybe<Scalars['Boolean']>;
  readonly modifiedWithinHours?: InputMaybe<Scalars['Int']>;
  readonly radius?: InputMaybe<Scalars['Int']>;
  readonly radiusUnit?: InputMaybe<EntityGeofenceDistanceUnit>;
  readonly freeText?: InputMaybe<Scalars['String']>;
};

export type GeographyLookupError = {
  readonly __typename?: 'GeographyLookupError';
  readonly message: Scalars['String'];
  readonly filter: Scalars['String'];
};

export type GeographyPreferenceRegionOutput = {
  readonly __typename?: 'GeographyPreferenceRegionOutput';
  readonly id: Scalars['ID'];
  readonly regionSetName: Scalars['String'];
  readonly regionName: Scalars['String'];
  readonly subRegionName?: Maybe<Scalars['String']>;
  readonly superRegionName?: Maybe<Scalars['String']>;
};

export type GeographyPreferredNameInput = {
  readonly sourceId: Scalars['Int'];
  readonly preferredName: Scalars['String'];
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

export type GeographyRadiusSearchInput = {
  readonly distance: UnitOfDistanceInput;
  readonly source?: InputMaybe<Scalars['String']>;
  readonly sourceId: Scalars['Int'];
};

export type GeographyRecordConnection = {
  readonly __typename?: 'GeographyRecordConnection';
  readonly totalCount: Scalars['Int'];
  readonly pageInfo?: Maybe<GeographyCursorPageInfo>;
  readonly edges?: Maybe<ReadonlyArray<GeographyRecordEdge>>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<GeographyLookupError>>>;
};

export type GeographyRecordEdge = {
  readonly __typename?: 'GeographyRecordEdge';
  readonly cursor: Scalars['Int'];
  readonly node: GeographyServiceRecord;
};

export type GeographyServiceInput = {
  readonly sourceId?: InputMaybe<Scalars['Int']>;
};

export type GeographyServicePoint = {
  readonly __typename?: 'GeographyServicePoint';
  readonly lat: Scalars['Float'];
  readonly lon: Scalars['Float'];
};

export type GeographyServiceRecord = {
  readonly __typename?: 'GeographyServiceRecord';
  readonly abbreviatedState?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly countryDetails?: Maybe<CountryDetails>;
  readonly county?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly geoPoint?: Maybe<GeographyServicePoint>;
  readonly geographyType: GeographyServiceTypeEnum;
  readonly population?: Maybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly originalName?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly sourceId?: Maybe<Scalars['Int']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly timezone?: Maybe<Scalars['String']>;
  readonly township?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly legacyId?: Maybe<Scalars['String']>;
  readonly postalCodes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly postalCodesDetails?: Maybe<ReadonlyArray<Maybe<PostalCodeDetails>>>;
  readonly featureCode?: Maybe<Scalars['String']>;
  readonly preferredName?: Maybe<Scalars['String']>;
  readonly alternativeNames?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly adminArea?: Maybe<GeographyAdminArea>;
  readonly adminArea2?: Maybe<GeographyAdminArea2>;
  readonly geocode?: Maybe<LocalityDetails>;
  readonly reverseGeocode?: Maybe<LocalityDetails>;
};

export enum GeographyServiceTypeEnum {
  /** a primary administrative division of a country, such as a state in the United States */
  AdministrativeArea1 = 'AdministrativeArea1',
  /** a subdivision of a first-order administrative division, such as a county in the United States */
  AdministrativeArea2 = 'AdministrativeArea2',
  /** a subdivision of a second-order administrative division, such as a village in the United States */
  AdministrativeArea3 = 'AdministrativeArea3',
  /** Airport */
  Airport = 'Airport',
  /** Locality */
  Locality = 'Locality',
  /** Other */
  Other = 'Other',
  /** Country */
  Country = 'Country',
  /** Three Character Postal Code */
  ThreeCharacterPostal = 'ThreeCharacterPostal',
  /** None */
  None = 'None'
}

export enum GeographyTypeEnum {
  /** Other */
  Other = 'other',
  /** Locality */
  Locality = 'locality',
  /** a primary administrative division of a country, such as a state in the United States */
  AdministrativeArea1 = 'administrativeArea1',
  /** a subdivision of a first-order administrative division, such as a county in the United States */
  AdministrativeArea2 = 'administrativeArea2',
  /** a subdivision of a second-order administrative division, such as a village in the United States */
  AdministrativeArea3 = 'administrativeArea3',
  /** Airport */
  Airport = 'airport'
}

export type GeographyUpdateInput = {
  readonly sourceId: Scalars['Int'];
  readonly alternativeNames?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly preferredName?: InputMaybe<Scalars['String']>;
  readonly geographyType?: InputMaybe<GeographyServiceTypeEnum>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly postalCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type GeographyV2 = {
  readonly __typename?: 'GeographyV2';
  readonly sourceId?: Maybe<Scalars['Int']>;
  readonly abbreviatedState?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly county?: Maybe<Scalars['String']>;
  readonly geoPoint?: Maybe<Geopoint>;
  readonly geographyType: GeographyTypeEnum;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly source?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly timezone?: Maybe<Scalars['String']>;
  readonly township?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly updatedByUser?: Maybe<UserV2>;
};

export type Geopoint = {
  readonly __typename?: 'Geopoint';
  readonly lat: Scalars['Float'];
  readonly lon: Scalars['Float'];
};

export type GeopointInput = {
  readonly lat: Scalars['Float'];
  readonly lon: Scalars['Float'];
};

export type GeoPointInput = {
  readonly lat: Scalars['Float'];
  readonly lon: Scalars['Float'];
};

export type GeopointNullable = {
  readonly __typename?: 'GeopointNullable';
  readonly lat: Scalars['Float'];
  readonly lon: Scalars['Float'];
};

export type GetAbacusPricingInput = {
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly laneId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
};

export type GetAbacusPricingPayload = {
  readonly __typename?: 'GetAbacusPricingPayload';
  readonly costLineItems?: Maybe<ReadonlyArray<Maybe<AbacusCostLineItem>>>;
  readonly routeVendorReferences?: Maybe<ReadonlyArray<Maybe<AbacusRouteVendorReference>>>;
};

export type GetAbacusPricingV3Input = {
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly laneIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
};

export type GetClientExceptionByDateRangeInput = {
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
};

export type GetContainerFilter = {
  readonly capacityUtilizationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly containerNumber?: InputMaybe<Scalars['String']>;
  readonly containerProgramId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly containerTypeId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly destinationRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly endDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly operatingStatusIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A partial or full city name */
  readonly originCity?: InputMaybe<Scalars['String']>;
  readonly originRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  readonly previousDraymanIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly previousRailroadIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly reservationNumber?: InputMaybe<Scalars['String']>;
  readonly reservationTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly serviceStatusIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly sourceTypeId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly startDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly trailerId?: InputMaybe<Scalars['ID']>;
};

export type GetDriverMobileInvitationAuditsPayload = {
  readonly __typename?: 'GetDriverMobileInvitationAuditsPayload';
  readonly invitationAudits: ReadonlyArray<DriverMobileInvitationAudit>;
  readonly errors: ReadonlyArray<UserError>;
};

export type GetDriverPayAttributeDefinitionsFilter = {
  readonly optionIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type GetDriverPayAttributesFilter = {
  readonly includeExpired?: InputMaybe<Scalars['Boolean']>;
  readonly includeActive?: InputMaybe<Scalars['Boolean']>;
  readonly includePending?: InputMaybe<Scalars['Boolean']>;
};

export type GetDriverPayProgramCarrierAssociationsFilter = {
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly payProgramIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly asOfDate?: InputMaybe<Scalars['DateTime']>;
  readonly includeExpired?: InputMaybe<Scalars['Boolean']>;
  readonly includeActive?: InputMaybe<Scalars['Boolean']>;
  readonly includePending?: InputMaybe<Scalars['Boolean']>;
};

export type GetDriverPayProgramDriverAssociationsFilter = {
  readonly driverIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly payProgramIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly asOfDate?: InputMaybe<Scalars['DateTime']>;
  readonly includeExpired?: InputMaybe<Scalars['Boolean']>;
  readonly includeActive?: InputMaybe<Scalars['Boolean']>;
  readonly includePending?: InputMaybe<Scalars['Boolean']>;
  readonly includeInherited?: InputMaybe<Scalars['Boolean']>;
  readonly fleetRelationshipBehavior?: InputMaybe<FleetRelationshipBehaviorEnum>;
};

export type GetDriverPayProgramRouteAssignmentsFilter = {
  readonly driverIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type GetDriverPayProgramsFilter = {
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly payProgramIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type GetDriverPayRateTableDetailsFilterV2 = {
  readonly payRateTableIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly payProgramIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly includeExpired?: InputMaybe<Scalars['Boolean']>;
  readonly includeActive?: InputMaybe<Scalars['Boolean']>;
  readonly includePending?: InputMaybe<Scalars['Boolean']>;
};

export type GetDriverPayRateTableHeadersFilter = {
  readonly includeExpired?: InputMaybe<Scalars['Boolean']>;
  readonly includeActive?: InputMaybe<Scalars['Boolean']>;
  readonly includePending?: InputMaybe<Scalars['Boolean']>;
};

export type GetDriverPayVoucherCutOffDateAndTimeV2Output = {
  readonly __typename?: 'GetDriverPayVoucherCutOffDateAndTimeV2Output';
  readonly cutoffDate: Scalars['DateTime'];
};

export type GetDriverRouteInput = {
  readonly driverId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
};

export type GetDriverRoutePayload = {
  readonly __typename?: 'GetDriverRoutePayload';
  readonly driverRoute: DriverRoute;
  readonly realm: Scalars['String'];
  readonly errors: ReadonlyArray<UserError>;
};

export type GetDriverRoutePayloadV2 = {
  readonly __typename?: 'GetDriverRoutePayloadV2';
  readonly realm?: Maybe<Scalars['String']>;
  readonly driverRoute: DriverRouteV2;
  readonly errors: ReadonlyArray<UserError>;
};

export type GetDriverRoutePayloadV3 = {
  readonly __typename?: 'GetDriverRoutePayloadV3';
  readonly realm?: Maybe<Scalars['String']>;
  readonly driverRoute?: Maybe<DriverRouteV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type GetDriverRoutesInput = {
  readonly driverId: Scalars['ID'];
  readonly after?: InputMaybe<Scalars['String']>;
  readonly upcoming: Scalars['Boolean'];
};

export type GetDriverRoutesPayLoad = {
  readonly __typename?: 'GetDriverRoutesPayLoad';
  readonly realm: Scalars['String'];
  readonly curser?: Maybe<Scalars['String']>;
  readonly allDriverRoutes: ReadonlyArray<Maybe<DriverRoute>>;
  readonly errors: ReadonlyArray<UserError>;
};

export type GetDriverRoutesPayLoadV2 = {
  readonly __typename?: 'GetDriverRoutesPayLoadV2';
  readonly realm?: Maybe<Scalars['String']>;
  /** @deprecated Use cursor instead */
  readonly curser?: Maybe<Scalars['String']>;
  readonly cursor?: Maybe<Scalars['String']>;
  readonly allDriverRoutes: ReadonlyArray<Maybe<DriverRouteV2>>;
  readonly errors: ReadonlyArray<UserError>;
};

export type GetDriversAssignedRoutesFilter = {
  /** An Asset Driver ID */
  readonly driverId: Scalars['ID'];
  readonly endDatetime: Scalars['String'];
  readonly startDatetime: Scalars['String'];
};

export type GetDriversAssignedRoutesInput = {
  readonly after?: InputMaybe<Scalars['String']>;
  readonly before?: InputMaybe<Scalars['String']>;
  readonly first?: InputMaybe<Scalars['Int']>;
  readonly last?: InputMaybe<Scalars['Int']>;
  readonly filters: GetDriversAssignedRoutesFilter;
};

export type GetDriverVoucherHeadersInput = {
  readonly driverVoucherHeaderIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type GetDriverVoucherHeadersOutput = {
  readonly __typename?: 'GetDriverVoucherHeadersOutput';
  readonly driverVoucherHeaders: ReadonlyArray<DriverVoucherHeaderType>;
};

export type GetEdiTransactionFilter = {
  readonly id?: InputMaybe<Scalars['Uuid']>;
  readonly shipmentId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly ediMessageType?: InputMaybe<EdiTransactionMessageType>;
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly direction?: InputMaybe<EdiTransactionDirection>;
  /** ProcessingStatus will be deprecated soon, please use ProcessingStatuses array instead. */
  readonly processingStatus?: InputMaybe<EdiTransactionProcessingStatus>;
  readonly numberRecordsReturned?: InputMaybe<Scalars['Int']>;
  readonly sortedColumnName?: InputMaybe<EdiTransactionColumn>;
  readonly sortOrder?: InputMaybe<EdiTransactionOrderBy>;
  readonly outputFormat?: InputMaybe<Scalars['String']>;
  readonly hasTrackingUpdateStatusDatetime?: InputMaybe<Scalars['Boolean']>;
  readonly processingStatuses?: InputMaybe<ReadonlyArray<InputMaybe<EdiTransactionProcessingStatus>>>;
};

export type GetEmailDomainsInput = {
  readonly domain?: InputMaybe<Scalars['String']>;
};

export type GetEmailDomainsResult = {
  readonly __typename?: 'GetEmailDomainsResult';
  readonly domains?: Maybe<ReadonlyArray<Maybe<HaykEmailDomain>>>;
};

export type GetHigherPriorityDriverCapacitiesInput = {
  readonly driverId: Scalars['ID'];
  readonly loadByDatetimeUtc: Scalars['String'];
  readonly readyDatetimeUtc: Scalars['String'];
  readonly sourceTypeId: Scalars['String'];
};

export type GetLoadRouteOffersArgsInput = {
  readonly createdByUserIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly types?: InputMaybe<ReadonlyArray<OfferType>>;
  readonly reasons?: InputMaybe<ReadonlyArray<OfferReason>>;
  readonly sources?: InputMaybe<ReadonlyArray<OfferSource>>;
  readonly statuses?: InputMaybe<ReadonlyArray<OfferStatus>>;
  readonly vendorIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly first?: InputMaybe<Scalars['Float']>;
  readonly last?: InputMaybe<Scalars['Float']>;
  readonly before?: InputMaybe<Scalars['String']>;
  readonly after?: InputMaybe<Scalars['String']>;
};

export type GetMobileRolesFilter = {
  readonly name?: InputMaybe<Scalars['String']>;
  readonly tenantID?: InputMaybe<Scalars['String']>;
};

export type GetMobileScopesFilter = {
  readonly name?: InputMaybe<Scalars['String']>;
};

export type GetMobileUsersFilter = {
  readonly email?: InputMaybe<Scalars['String']>;
};

export type GetNonRouteCostDetailHistoryResult = {
  readonly __typename?: 'GetNonRouteCostDetailHistoryResult';
  readonly nonRouteCostDetailHistory: ReadonlyArray<NonRouteCostDetailHistory>;
};

export type GetNonRouteCostDetailsInput = {
  readonly driverIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly nonRouteCostDetailIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly maxRecordCount?: InputMaybe<Scalars['Int']>;
};

export type GetNonRouteCostDetailsOutput = {
  readonly __typename?: 'GetNonRouteCostDetailsOutput';
  readonly nonRouteCostDetails: ReadonlyArray<NonRouteCostDetail>;
};

export type GetOffersArgsInput = {
  readonly createdByUserIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly types?: InputMaybe<ReadonlyArray<OfferType>>;
  readonly reasons?: InputMaybe<ReadonlyArray<OfferReason>>;
  readonly sources?: InputMaybe<ReadonlyArray<OfferSource>>;
  readonly statuses?: InputMaybe<ReadonlyArray<OfferStatus>>;
  readonly vendorIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly first?: InputMaybe<Scalars['Float']>;
  readonly last?: InputMaybe<Scalars['Float']>;
  readonly before?: InputMaybe<Scalars['String']>;
  readonly after?: InputMaybe<Scalars['String']>;
};

export type GetPatternOrderApplicationPayload = {
  readonly __typename?: 'GetPatternOrderApplicationPayload';
  readonly data?: Maybe<PatternOrderApplicationPayload>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type GetPatternPayload = {
  readonly __typename?: 'GetPatternPayload';
  readonly data?: Maybe<Pattern>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type GetPatternsByLanePayload = {
  readonly __typename?: 'GetPatternsByLanePayload';
  readonly patterns: ReadonlyArray<Maybe<Pattern>>;
};

export type GetPatternsByLanePayloadV2 = {
  readonly __typename?: 'GetPatternsByLanePayloadV2';
  readonly data?: Maybe<GetPatternsByLanePayload>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type GetPatternsByOrderPayload = {
  readonly __typename?: 'GetPatternsByOrderPayload';
  readonly laneMatchScores?: Maybe<ReadonlyArray<LaneMatchScore>>;
  readonly patterns: ReadonlyArray<Maybe<Pattern>>;
};

export type GetPatternsByOrderPayloadV1 = {
  readonly __typename?: 'GetPatternsByOrderPayloadV1';
  readonly data?: Maybe<GetPatternsByOrderPayload>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type GetTaskByDateRangeInput = {
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
};

export type GetTaskInputV2 = {
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly type?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly neededBy?: InputMaybe<GetTaskByDateRangeInput>;
  readonly closedAt?: InputMaybe<GetTaskByDateRangeInput>;
  readonly requestedAt?: InputMaybe<GetTaskByDateRangeInput>;
  readonly loadId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly requestedBy?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly assignedTo?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly status?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly showClosed?: InputMaybe<Scalars['Boolean']>;
  readonly showRateCostLineItem?: InputMaybe<Scalars['Boolean']>;
  readonly groupId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedToGroupId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly externalId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type GetTripsInput = {
  readonly tripIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type GetTruckEntriesFilter = {
  readonly assignedRouteId?: InputMaybe<Scalars['ID']>;
  readonly capacityUtilizationStatusIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly dateRange?: InputMaybe<TruckEntryDateRangeFilter>;
  readonly destination?: InputMaybe<DestinationFilter>;
  /** An array of Asset Driver IDs. Will match against driver1 and driver2 */
  readonly driverIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** An array of truck Ids */
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** An array of outbound region hierarchies, each flattened to a string with elements separated by tildes: ~super~region~subregion. All region types are optional but all three tildes (including the leading one) are required: ~super~~, ~super~region~, ~~region~, ~~~subregion, ~super~~subregion, ~~region~subregion */
  readonly outboundRegionNamesFlatArray?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly origin?: InputMaybe<OriginFilter>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  /** An array of Asset Power IDs */
  readonly powerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** An array of sourceType IDs */
  readonly sourceTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** An array of trailerType IDs */
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type GetTruckEntryByDriverIdAndReadyDateInput = {
  readonly driverId: Scalars['ID'];
  readonly readyDatetimeUtc: Scalars['DateTime'];
};

export type GetTruckEntryTemplateByDriverIdAndDaysInput = {
  /** The ID of an Asset Driver record. */
  readonly driverId: Scalars['ID'];
  readonly days: ReadonlyArray<TruckAvailableDayOffSetEnum>;
  readonly templateId?: InputMaybe<Scalars['ID']>;
};

export type GetWorkflowJobPayLoad = {
  readonly __typename?: 'GetWorkflowJobPayLoad';
  readonly errors?: Maybe<ReadonlyArray<WorkflowError>>;
  readonly workflowJob?: Maybe<WorkflowJob>;
};

export type GlobalConfigurationOverride = {
  readonly __typename?: 'GlobalConfigurationOverride';
  readonly carrierAllocationSplit?: Maybe<Scalars['Decimal']>;
  readonly customerAllocationSplit?: Maybe<Scalars['Decimal']>;
  readonly orderCostAllocationMethodId?: Maybe<Scalars['String']>;
  readonly routeRateAllocationMethodId?: Maybe<Scalars['String']>;
};

export type GlobalConfigurationOverrideInput = {
  readonly carrierAllocationSplit?: InputMaybe<Scalars['Decimal']>;
  readonly customerAllocationSplit?: InputMaybe<Scalars['Decimal']>;
  readonly orderCostAllocationMethodId?: InputMaybe<Scalars['String']>;
  readonly routeRateAllocationMethodId?: InputMaybe<Scalars['String']>;
};

export type Group = {
  readonly __typename?: 'Group';
  readonly id?: Maybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly type: KeyValue;
  readonly purpose: KeyValue;
  readonly direction?: Maybe<KeyValue>;
  readonly distributionListEmail?: Maybe<Scalars['String']>;
  readonly owner: Scalars['ID'];
  readonly description?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly employees?: Maybe<ReadonlyArray<Employees>>;
  readonly groupAttributes?: Maybe<ReadonlyArray<GroupAttributes>>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
};

export type GroupAttributes = {
  readonly __typename?: 'GroupAttributes';
  readonly id: Scalars['ID'];
  readonly groupId?: Maybe<Scalars['ID']>;
  readonly attributeCategory?: Maybe<Scalars['String']>;
  readonly attributeName?: Maybe<Scalars['String']>;
  readonly attributeDataType?: Maybe<Scalars['String']>;
  readonly values?: Maybe<ReadonlyArray<AttributeValues>>;
};

export type GroupEdge = {
  readonly __typename?: 'GroupEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<Group>;
};

export type GroupsFilterArgs = {
  readonly names?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly type?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly purpose?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly direction?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly distributionEmail?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly employees?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly attributeNames?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly attributeValues?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type GroupUserError = {
  readonly __typename?: 'GroupUserError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type HaykEmailConfig = {
  readonly from?: InputMaybe<Scalars['String']>;
  readonly recipients?: InputMaybe<HaykEmailConfigRecipients>;
};

export type HaykEmailConfigRecipients = {
  readonly to?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly cc?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type HaykEmailConfigRecipientsResponse = {
  readonly __typename?: 'HaykEmailConfigRecipientsResponse';
  readonly to?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly cc?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type HaykEmailConfigResponse = {
  readonly __typename?: 'HaykEmailConfigResponse';
  readonly from?: Maybe<Scalars['String']>;
  readonly recipients?: Maybe<HaykEmailConfigRecipientsResponse>;
};

export type HaykEmailDomain = {
  readonly __typename?: 'HaykEmailDomain';
  readonly domain: Scalars['String'];
  readonly secretKeyName?: Maybe<Scalars['String']>;
};

export type HaykEvent = {
  readonly __typename?: 'HaykEvent';
  readonly name?: Maybe<Scalars['String']>;
  readonly eventId?: Maybe<Scalars['String']>;
  readonly eventType?: Maybe<Scalars['String']>;
  readonly eventMode?: Maybe<Scalars['String']>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly isVisible?: Maybe<Scalars['Boolean']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly group?: Maybe<Scalars['String']>;
  readonly displayGroup?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly ruleSets?: Maybe<ReadonlyArray<Maybe<RuleSet>>>;
  readonly kafkaTopic?: Maybe<Scalars['String']>;
  readonly serviceSource: Scalars['String'];
  readonly templates?: Maybe<ReadonlyArray<ContentTemplate>>;
  readonly emailConfig?: Maybe<HaykEmailConfigResponse>;
  readonly featureFlagContentTypeConfig?: Maybe<FeatureFlagContentTypeConfig>;
};

export type HaykFieldMapping = {
  readonly __typename?: 'HaykFieldMapping';
  readonly fieldMappingId?: Maybe<Scalars['String']>;
  readonly mappings?: Maybe<ReadonlyArray<Maybe<HaykFieldMappingItem>>>;
  readonly recordId?: Maybe<Scalars['String']>;
  readonly recordName?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
};

/** Field Mappings */
export enum HaykFieldMappingDefaultType {
  CustomerDefault = 'CustomerDefault',
  MasteryDefault = 'MasteryDefault'
}

export type HaykFieldMappingInput = {
  readonly fieldMappingId?: InputMaybe<Scalars['String']>;
  readonly recordId?: InputMaybe<Scalars['String']>;
  readonly recordName?: InputMaybe<Scalars['String']>;
  readonly mappings?: InputMaybe<ReadonlyArray<InputMaybe<HaykFieldMappingItemInput>>>;
};

export type HaykFieldMappingItem = {
  readonly __typename?: 'HaykFieldMappingItem';
  readonly mappingId?: Maybe<Scalars['String']>;
  readonly field?: Maybe<Scalars['String']>;
  readonly defaultType?: Maybe<HaykFieldMappingDefaultType>;
};

export type HaykFieldMappingItemInput = {
  readonly mappingId?: InputMaybe<Scalars['String']>;
  readonly field?: InputMaybe<Scalars['String']>;
  readonly defaultType?: InputMaybe<HaykFieldMappingDefaultType>;
};

export type HaykFieldMappingsResult = {
  readonly __typename?: 'HaykFieldMappingsResult';
  readonly fieldmappings?: Maybe<ReadonlyArray<Maybe<HaykFieldMapping>>>;
};

export type HaykNotificationResponse = {
  readonly __typename?: 'HaykNotificationResponse';
  readonly correlationId?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly offset?: Maybe<Scalars['Int']>;
};

export type HaykNotificationResponses = {
  readonly __typename?: 'HaykNotificationResponses';
  readonly responses?: Maybe<ReadonlyArray<Maybe<HaykNotificationResponse>>>;
};

export type HaykNotificationsInput = {
  readonly eventName?: InputMaybe<Scalars['String']>;
  readonly notificationChannels?: InputMaybe<ReadonlyArray<InputMaybe<NotificationChannels>>>;
  readonly from?: InputMaybe<Scalars['String']>;
  readonly to?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly cc?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly bcc?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly templateData?: InputMaybe<Scalars['JSONObject']>;
  readonly attachments?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly referenceId?: InputMaybe<Scalars['String']>;
};

export type HaykRedirectInput = {
  readonly division?: InputMaybe<Scalars['String']>;
  readonly redirects: HaykRedirectRedirectsInput;
};

export type HaykRedirectRedirects = {
  readonly __typename?: 'HaykRedirectRedirects';
  readonly toAddresses: Scalars['String'];
  readonly ccAddresses: Scalars['String'];
  readonly bccAddresses: Scalars['String'];
};

export type HaykRedirectRedirectsInput = {
  readonly toAddresses: Scalars['String'];
  readonly ccAddresses: Scalars['String'];
  readonly bccAddresses: Scalars['String'];
};

/** Redirects */
export type HaykRedirectResult = {
  readonly __typename?: 'HaykRedirectResult';
  readonly redirectId: Scalars['String'];
  readonly defaultType?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly redirects?: Maybe<HaykRedirectRedirects>;
};

export type HistoricalFuelIndexPriceCreateInput = {
  readonly fuelIndexType: Scalars['String'];
  readonly fuelPriceDate: Scalars['DateTime'];
  readonly averagePrice?: InputMaybe<Scalars['Decimal']>;
  readonly averagePriceOverride?: InputMaybe<Scalars['Decimal']>;
};

export type HistoricalFuelIndexPriceModel = {
  readonly __typename?: 'HistoricalFuelIndexPriceModel';
  readonly historicalFuelIndexPriceId: Scalars['ID'];
  readonly createdByUserId?: Maybe<Scalars['Guid']>;
  readonly createdByDesc?: Maybe<Scalars['String']>;
  readonly createdOnUtc?: Maybe<Scalars['DateTime']>;
  readonly updatedByUserId?: Maybe<Scalars['Guid']>;
  readonly updatedByDesc?: Maybe<Scalars['String']>;
  readonly updatedOnUtc?: Maybe<Scalars['DateTime']>;
  readonly fuelIndexType: Scalars['String'];
  readonly fuelPriceDate: Scalars['DateTime'];
  readonly averagePrice?: Maybe<Scalars['Decimal']>;
  readonly averagePriceOverride?: Maybe<Scalars['Decimal']>;
};

export type HistoricalFuelIndexPriceResponse = {
  readonly __typename?: 'HistoricalFuelIndexPriceResponse';
  readonly historicalFuelIndexPriceId?: Maybe<Scalars['Guid']>;
  readonly fuelIndexType: Scalars['String'];
  readonly fuelPriceDate?: Maybe<Scalars['DateTime']>;
  readonly averageDieselPrice?: Maybe<Scalars['Decimal']>;
};

export type HistoricalFuelIndexPriceUpdateInput = {
  readonly historicalFuelIndexPriceId: Scalars['Guid'];
  readonly fuelIndexType: Scalars['String'];
  readonly fuelPriceDate: Scalars['DateTime'];
  readonly averagePrice?: InputMaybe<Scalars['Decimal']>;
  readonly averagePriceOverride?: InputMaybe<Scalars['Decimal']>;
};

export type HistoricalSpotQuotesFilterInput = {
  readonly filter?: InputMaybe<Scalars['String']>;
  readonly spotQuoteFilter?: InputMaybe<SpotQuoteCommonFilterInput>;
  readonly laneFilter?: InputMaybe<SpotQuoteCommonLaneFilterInput>;
  readonly includeAwarded?: InputMaybe<Scalars['Boolean']>;
  readonly includeUnAwarded?: InputMaybe<Scalars['Boolean']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
};

export type HistoricalSpotQuotesInput = {
  readonly filter: HistoricalSpotQuotesFilterInput;
  readonly after?: InputMaybe<Scalars['String']>;
  readonly first?: InputMaybe<Scalars['Int']>;
};

/** The final exchange rate used to convert from one currency to another on a given date */
export type HistoricRate = {
  readonly __typename?: 'HistoricRate';
  readonly date: Scalars['DateTime'];
  readonly fromCurrency: Scalars['String'];
  readonly toCurrency: Scalars['String'];
  readonly rate: Scalars['Float'];
};

export type HistoryFilterInputs = {
  readonly noOfPastDays?: InputMaybe<Scalars['Float']>;
  readonly termId?: InputMaybe<Scalars['String']>;
  readonly termType?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly updatedDateRangeEndDate?: InputMaybe<Scalars['String']>;
  readonly updatedDateRangeStartDate?: InputMaybe<Scalars['String']>;
};

export type HosCycleRecapSummary = {
  readonly __typename?: 'HosCycleRecapSummary';
  readonly driverId: Scalars['ID'];
  readonly hosRuleTerm: Scalars['String'];
  /** @deprecated hosDefaultTimeZoneTerm is deprecated. Use hosDefaultTimeZone instead. */
  readonly hosDefaultTimeZoneTerm?: Maybe<Scalars['String']>;
  readonly hosDefaultTimeZone?: Maybe<Scalars['String']>;
  readonly totalOnDutyOnCurrentCycle: Scalars['Int'];
  readonly lastUpdatedDateTime: Scalars['DateTime'];
  readonly lastCycleResetDateTime?: Maybe<Scalars['DateTime']>;
  readonly dailySummary: ReadonlyArray<DailyRecapSummary>;
};

export type HosDriverActionInput = {
  readonly date: Scalars['String'];
  readonly actionType: Scalars['String'];
};

export type HosDriverPreference = {
  readonly maxDailyDrive: Scalars['Int'];
  readonly canDriveBeyond: Scalars['Int'];
};

export type HosDriverPreferenceInput = {
  readonly maxDailyDriveIn: Scalars['Int'];
  readonly canDriveBeyondIn: Scalars['Int'];
};

export type HosSummaryAndRecap = {
  readonly __typename?: 'HosSummaryAndRecap';
  readonly id: Scalars['ID'];
  readonly driver: AssetDriver;
  readonly hosRuleTerm: Scalars['String'];
  readonly dutyStatusTerm: Scalars['String'];
  readonly sourceSystem?: Maybe<Scalars['String']>;
  readonly eventCode: Scalars['String'];
  readonly eventDateTime: Scalars['DateTime'];
  readonly powerUnit?: Maybe<AssetPowerOutput>;
  readonly remark?: Maybe<Scalars['String']>;
  readonly eventSentDateTime?: Maybe<Scalars['DateTime']>;
  readonly eventReceivedDateTime?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedDateTime?: Maybe<Scalars['DateTime']>;
  readonly calculatedTimers?: Maybe<CalculatedTimers>;
  readonly submittedTimers: SubmittedTimers;
  readonly calculatedDutyStatusPerDay?: Maybe<ReadonlyArray<DutyStatusPerDay>>;
  readonly submittedDutyStatusPerDay?: Maybe<ReadonlyArray<DutyStatusPerDay>>;
};

export type HoursInput = {
  readonly startTime: Scalars['Int'];
  readonly endTime: Scalars['Int'];
  readonly dayOfWeek: Scalars['String'];
};

export type HoursOfServiceClock = {
  readonly __typename?: 'HoursOfServiceClock';
  readonly timeRemainingSeconds: Scalars['Int'];
  readonly nextResetDateTime?: Maybe<Scalars['DateTime']>;
};

export type HoursOfServiceClockInput = {
  readonly timeRemainingSeconds: Scalars['Int'];
  readonly nextResetDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type HoursOutput = {
  readonly __typename?: 'HoursOutput';
  readonly startTime: Scalars['Int'];
  readonly endTime: Scalars['Int'];
  readonly dayOfWeek: Scalars['String'];
};

export type Identifier = {
  readonly __typename?: 'Identifier';
  readonly code: Scalars['String'];
  readonly customerId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly identifierType: KeyValue;
  readonly identifierTypeId: Scalars['ID'];
};

export type IdentifierInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly identifierTypeId: Scalars['ID'];
};

export type IdentifierInputV2 = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly identifierTypeId: Scalars['ID'];
};

export type IdentifierQueryInput = {
  readonly code: Scalars['String'];
  readonly identifierType: Scalars['String'];
};

export type IdentifierQueryInputV2 = {
  readonly code: Scalars['String'];
  readonly identifierType: Scalars['String'];
};

export type IdentifierV2 = {
  readonly __typename?: 'IdentifierV2';
  readonly code: Scalars['String'];
  readonly customerId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly identifierType: KeyValue;
  readonly identifierTypeId: Scalars['ID'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ImdlGenericResponse = {
  readonly __typename?: 'ImdlGenericResponse';
  readonly provider: Scalars['String'];
  readonly statusCode: Scalars['Int'];
  readonly message: Scalars['String'];
  readonly prices?: Maybe<ReadonlyArray<Maybe<PricingInfo>>>;
};

export type ImdlInput = {
  readonly provider: ReadonlyArray<InputMaybe<Scalars['String']>>;
  readonly hazmatShipment: Scalars['Boolean'];
  readonly shipFromLocation: ShipLocation;
  readonly shipToLocation: ShipLocation;
  readonly pickUpDate?: InputMaybe<Scalars['DateTime']>;
};

export type ImdlOutput = {
  readonly __typename?: 'ImdlOutput';
  readonly request?: Maybe<RequestOutput>;
  readonly response?: Maybe<ReadonlyArray<Maybe<ImdlGenericResponse>>>;
  readonly correlationId: Scalars['String'];
};

export type ImdlPricesByQuoteFacilityStopInputType = {
  readonly facilityId: Scalars['ID'];
};

export type ImdlPricesByQuoteGeographyStopInputType = {
  readonly geographySourceId?: InputMaybe<Scalars['Int']>;
};

export type ImdlPricesByQuoteInput = {
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly commodities: ReadonlyArray<InputMaybe<PriceImdlQuoteCommodityType>>;
  readonly correlationId: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly equipment?: InputMaybe<PriceImdlQuoteEquipmentType>;
  readonly loadSize: Scalars['String'];
  readonly project?: InputMaybe<Scalars['String']>;
  readonly requirements?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly scac?: InputMaybe<Scalars['String']>;
  readonly serviceLevel?: InputMaybe<Scalars['String']>;
  readonly shipEndDate?: InputMaybe<Scalars['String']>;
  readonly shipStartDate?: InputMaybe<Scalars['String']>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<PriceImdlQuoteStopType>>>;
  readonly transportMode: Scalars['String'];
};

/** Holds Imdl Prices by Quote Payload. */
export type ImdlPricesByQuotePayload = {
  readonly __typename?: 'ImdlPricesByQuotePayload';
  readonly customer: CustomerV2;
  readonly errors?: Maybe<ReadonlyArray<ImdlPricingErrorType>>;
  readonly quotes: ReadonlyArray<ImdlPricingQuoteType>;
};

export type ImdlPricesByQuotePostalCodeStopInputType = {
  readonly countryCode: Scalars['String'];
  readonly postalCode: Scalars['String'];
};

export type ImdlPricesByQuoteRegionStopInputType = {
  readonly regionId: Scalars['ID'];
};

/** Holds Price Imdl carrier line item. */
export type ImdlPricingCarrierLineItemType = {
  readonly __typename?: 'ImdlPricingCarrierLineItemType';
  readonly chargeType?: Maybe<ImdlPricingChargeTypeV2Type>;
  readonly costPer: Scalars['Decimal'];
  readonly currency?: Maybe<Scalars['String']>;
  readonly units: Scalars['Decimal'];
};

/** Holds Price Imdl carrier quote. */
export type ImdlPricingCarrierQuoteType = {
  readonly __typename?: 'ImdlPricingCarrierQuoteType';
  readonly carrierCode?: Maybe<Scalars['String']>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly deliveryDate?: Maybe<Scalars['DateTime']>;
  readonly errors?: Maybe<ReadonlyArray<ImdlPricingErrorType>>;
  readonly lineItems?: Maybe<ReadonlyArray<Maybe<ImdlPricingCarrierLineItemType>>>;
  readonly pickupDate?: Maybe<Scalars['DateTime']>;
  readonly quoteId?: Maybe<Scalars['String']>;
  readonly transitDays?: Maybe<Scalars['Decimal']>;
};

/** Holds Price Imdl charge type. */
export type ImdlPricingChargeTypeV2Type = {
  readonly __typename?: 'ImdlPricingChargeTypeV2Type';
  readonly id: Scalars['Uuid'];
};

/** Holds Price Imdl line item. */
export type ImdlPricingCustomerLineItemType = {
  readonly __typename?: 'ImdlPricingCustomerLineItemType';
  readonly chargeType?: Maybe<ImdlPricingChargeTypeV2Type>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly ratePer: Scalars['Decimal'];
  readonly units: Scalars['Decimal'];
};

/** Holds Price Imdl errors. */
export type ImdlPricingErrorType = {
  readonly __typename?: 'ImdlPricingErrorType';
  readonly code?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly providerResponse?: Maybe<Scalars['String']>;
};

/** Holds Price Imdl Quote Type. */
export type ImdlPricingQuoteType = {
  readonly __typename?: 'ImdlPricingQuoteType';
  readonly carrierQuotes?: Maybe<ImdlPricingCarrierQuoteType>;
  readonly customerLineItems?: Maybe<ReadonlyArray<Maybe<ImdlPricingCustomerLineItemType>>>;
  readonly routeId?: Maybe<Scalars['Uuid']>;
};

export type ImportAward = {
  readonly __typename?: 'ImportAward';
  readonly documentId?: Maybe<Scalars['ID']>;
  readonly recordId?: Maybe<Scalars['String']>;
  readonly error?: Maybe<MasterBidError>;
};

export type ImportAwardPayload = {
  readonly __typename?: 'ImportAwardPayload';
  readonly importAward?: Maybe<ReadonlyArray<Maybe<ImportAward>>>;
};

export type ImportAwardsInput = {
  readonly bidQuoteRecords?: InputMaybe<ReadonlyArray<InputMaybe<BidQuoteRecord>>>;
};

export type ImportAwardsStaging = {
  readonly __typename?: 'ImportAwardsStaging';
  readonly documentId?: Maybe<Scalars['ID']>;
  readonly bidId?: Maybe<Scalars['ID']>;
  readonly roundId?: Maybe<Scalars['ID']>;
  readonly frequency?: Maybe<Scalars['String']>;
  readonly weekStarting?: Maybe<Scalars['String']>;
  readonly acceptanceRate?: Maybe<Scalars['Int']>;
  readonly surge?: Maybe<Scalars['Int']>;
  readonly autoAccept?: Maybe<Scalars['Boolean']>;
  readonly effectiveDate?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly externalBidId?: Maybe<Scalars['String']>;
  readonly bid?: Maybe<Bid>;
  readonly bidOwnerDetails?: Maybe<OwnerDetails>;
};

export type ImportAwardsStagingInput = {
  readonly documentId?: InputMaybe<Scalars['ID']>;
  readonly bidId?: InputMaybe<Scalars['ID']>;
  readonly roundId?: InputMaybe<Scalars['ID']>;
  readonly frequency?: InputMaybe<Scalars['String']>;
  readonly weekStarting?: InputMaybe<Scalars['String']>;
  readonly acceptanceRate?: InputMaybe<Scalars['Int']>;
  readonly surgePercentage?: InputMaybe<Scalars['Int']>;
  readonly autoAccept?: InputMaybe<Scalars['Boolean']>;
  readonly effectiveDate?: InputMaybe<Scalars['String']>;
  readonly expirationDate?: InputMaybe<Scalars['String']>;
};

export type ImportAwardsStagingPayload = {
  readonly __typename?: 'ImportAwardsStagingPayload';
  readonly importAwardsId?: Maybe<Scalars['ID']>;
  readonly error?: Maybe<MasterBidError>;
};

export type ImportBlumeChargesInput = {
  readonly billOfLading: Scalars['String'];
  readonly trackingNumber: Scalars['String'];
  readonly bypassId?: InputMaybe<Scalars['ID']>;
  readonly bypassIdType?: InputMaybe<Scalars['String']>;
  readonly bypassCode?: InputMaybe<Scalars['String']>;
  readonly bypassCodeType?: InputMaybe<Scalars['String']>;
  readonly persistImport: Scalars['Boolean'];
};

export type ImportBlumeChargesPayload = {
  readonly __typename?: 'ImportBlumeChargesPayload';
  readonly blumeCharges: ReadonlyArray<BlumeCharge>;
  readonly accountingCostDetailsInserted: ReadonlyArray<BlumeInsertedCostDetail>;
  readonly accountingCostDetailsDeleted: ReadonlyArray<BlumeDeletedCostDetail>;
  readonly adjustmentMethodsUsed: ReadonlyArray<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
};

export type ImportCarrierFuelRangeInput = {
  readonly minPrice?: InputMaybe<Scalars['Decimal']>;
  readonly maxPrice?: InputMaybe<Scalars['Decimal']>;
  readonly priceDecimal?: InputMaybe<Scalars['Decimal']>;
  readonly pricePercentage?: InputMaybe<Scalars['Decimal']>;
};

export type ImportCarrierFuelRangesCreateInput = {
  readonly carrierId: Scalars['Guid'];
  readonly carrierFuelRateHeaderId: Scalars['ID'];
  readonly carrierFuelRanges: ReadonlyArray<InputMaybe<ImportCarrierFuelRangeInput>>;
};

export type ImportCustomerFuelRangeInput = {
  readonly minPrice?: InputMaybe<Scalars['Decimal']>;
  readonly maxPrice?: InputMaybe<Scalars['Decimal']>;
  readonly priceDecimal?: InputMaybe<Scalars['Decimal']>;
  readonly pricePercentage?: InputMaybe<Scalars['Decimal']>;
};

export type ImportCustomerFuelRangesCreateInput = {
  readonly customerId: Scalars['Guid'];
  readonly customerFuelRateHeaderId: Scalars['ID'];
  readonly customerFuelRanges: ReadonlyArray<InputMaybe<ImportCustomerFuelRangeInput>>;
};

export enum ImportStatusEnum {
  Unknown = 'UNKNOWN',
  New = 'NEW',
  Uploaded = 'UPLOADED',
  Retrieving = 'RETRIEVING',
  Validating = 'VALIDATING',
  Processing = 'PROCESSING',
  Processed = 'PROCESSED',
  Error = 'ERROR'
}

export type Inbound204IsaIdReceiver = {
  readonly __typename?: 'Inbound204IsaIdReceiver';
  readonly id: Scalars['ID'];
  readonly ediTransactionId?: Maybe<Scalars['ID']>;
  readonly isaReceiverId?: Maybe<Scalars['String']>;
  readonly applicationReceiversCode?: Maybe<Scalars['String']>;
};

export type IncidentConnectionV2 = {
  readonly __typename?: 'IncidentConnectionV2';
  readonly edges: ReadonlyArray<IncidentV2Edge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type IncidentCreateInputV2 = {
  readonly employeeID: Scalars['ID'];
  readonly loadID: Scalars['ID'];
  readonly orderIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly customerID?: InputMaybe<Scalars['ID']>;
  readonly vendorID?: InputMaybe<Scalars['ID']>;
  readonly carrierID?: InputMaybe<Scalars['ID']>;
  readonly facilityID?: InputMaybe<Scalars['ID']>;
  readonly typeID: Scalars['ID'];
  readonly gravityID?: InputMaybe<Scalars['ID']>;
  readonly faultPartyID?: InputMaybe<Scalars['ID']>;
  readonly location?: InputMaybe<LocationInput>;
  readonly followUpDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly shutdown: Scalars['Boolean'];
  readonly closed: Scalars['Boolean'];
  readonly accounting: Scalars['Boolean'];
  readonly claims: Scalars['Boolean'];
  readonly summary: Scalars['String'];
  readonly description?: InputMaybe<Scalars['String']>;
  /** Currently does nothing */
  readonly notifyUserIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type IncidentFilterV2 = {
  readonly startDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly endDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly showClosed?: InputMaybe<Scalars['Boolean']>;
  readonly routeID?: InputMaybe<Scalars['ID']>;
  readonly loadID?: InputMaybe<Scalars['ID']>;
  readonly repIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly vendorIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly facilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly incidentTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly incidentStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type IncidentSuccessResponse = {
  readonly __typename?: 'IncidentSuccessResponse';
  readonly id: Scalars['ID'];
  readonly latestUpdateDescription: Scalars['String'];
};

export type IncidentUpdate = {
  readonly __typename?: 'IncidentUpdate';
  readonly id: Scalars['ID'];
  readonly type: IncidentUpdateType;
  readonly createdDatetime: Scalars['ISO8601DateTime'];
  readonly followUpDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly shutdown: Scalars['Boolean'];
  readonly closed: Scalars['Boolean'];
  readonly accounting: Scalars['Boolean'];
  readonly claims: Scalars['Boolean'];
  readonly description: Scalars['String'];
  readonly lastUpdatedDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly createdBy: Employee;
  readonly createdByV2: EmployeeV2;
  readonly claimOpenedBy?: Maybe<Employee>;
  readonly claimOpenedByV2?: Maybe<EmployeeV2>;
};

export type IncidentUpdateInputV2 = {
  readonly id: Scalars['ID'];
  readonly employeeID: Scalars['ID'];
  readonly followUpDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly shutdown: Scalars['Boolean'];
  readonly closed: Scalars['Boolean'];
  readonly accounting: Scalars['Boolean'];
  readonly claims: Scalars['Boolean'];
  readonly updateDescription: Scalars['String'];
  /** Employee IDs of the users to notify */
  readonly notifyUserIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export enum IncidentUpdateType {
  Close = 'Close',
  Create = 'Create',
  Reopen = 'Reopen',
  Update = 'Update'
}

export type IncidentV2 = {
  readonly __typename?: 'IncidentV2';
  readonly id: Scalars['ID'];
  readonly type: KeyValue;
  readonly gravity?: Maybe<KeyValue>;
  readonly faultParty?: Maybe<KeyValue>;
  readonly location?: Maybe<Location>;
  readonly createdDatetime: Scalars['ISO8601DateTime'];
  readonly lastUpdatedDatetime: Scalars['ISO8601DateTime'];
  readonly followUpDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly shutdown: Scalars['Boolean'];
  readonly closed: Scalars['Boolean'];
  readonly accounting: Scalars['Boolean'];
  readonly claims: Scalars['Boolean'];
  readonly claimOpenedDatetime?: Maybe<Scalars['DateTime']>;
  readonly summary: Scalars['String'];
  readonly description: Scalars['String'];
  readonly latestUpdateType: IncidentUpdateType;
  readonly latestUpdateDescription: Scalars['String'];
  readonly updates: ReadonlyArray<IncidentUpdate>;
  readonly load: LoadV2;
  readonly orders: ReadonlyArray<CustomerOrderV2>;
  readonly routes: ReadonlyArray<LoadRouteV2>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly vendor?: Maybe<RouteVendorV2>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly createdBy: Employee;
  readonly createdByV2: EmployeeV2;
  readonly lastUpdatedBy: Employee;
  readonly lastUpdatedByV2: EmployeeV2;
  readonly claimOpenedBy?: Maybe<Employee>;
  readonly claimOpenedByV2?: Maybe<EmployeeV2>;
  readonly notifyUsers: ReadonlyArray<Employee>;
  readonly notifyUsersV2: ReadonlyArray<EmployeeV2>;
};

export type IncidentV2CloseUpdateInput = {
  readonly id: Scalars['ID'];
  readonly updateDescription: Scalars['String'];
};

export type IncidentV2Edge = {
  readonly __typename?: 'IncidentV2Edge';
  readonly node: IncidentV2;
  readonly cursor: Scalars['String'];
};

export type InferredHoursOfServiceInput = {
  readonly readyLocation: DatedPathEntryInput;
  readonly currentLocation: DatedPathEntryInput;
  readonly nextStop: PathEntryInput;
  readonly isTeamOfDrivers?: InputMaybe<Scalars['Boolean']>;
};

export type IngressStopEventWithEtasInput = {
  readonly userID?: InputMaybe<Scalars['ID']>;
  readonly loadID: Scalars['ID'];
  readonly loadStopID?: InputMaybe<Scalars['ID']>;
  readonly routeID: Scalars['ID'];
  readonly stopID: Scalars['ID'];
  readonly eventTypeID: Scalars['String'];
  readonly actualDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly actualTimezone?: InputMaybe<Scalars['String']>;
  readonly expectedDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly expectedTimezone?: InputMaybe<Scalars['String']>;
  readonly autoExpectedDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly autoExpectedSource?: InputMaybe<Scalars['String']>;
  readonly stopEventLastUpdatedSource?: InputMaybe<Scalars['String']>;
  readonly eventDetail?: InputMaybe<EventDetailInput>;
};

export type InputSchema = {
  readonly locationDats?: InputMaybe<ReadonlyArray<InputMaybe<LocationDat>>>;
  readonly routeDats?: InputMaybe<ReadonlyArray<InputMaybe<RouteDat>>>;
  readonly routePathDats?: InputMaybe<ReadonlyArray<InputMaybe<RoutePathDat>>>;
  readonly speedMapDats?: InputMaybe<ReadonlyArray<InputMaybe<SpeedMapDat>>>;
  readonly parametersDat?: InputMaybe<ParametersDat>;
  readonly useInferredHoS?: InputMaybe<Scalars['Boolean']>;
};

export type InsertMultipleTenderOptionsInput = {
  readonly tenderOptions?: InputMaybe<ReadonlyArray<InputMaybe<InsertTenderOptionsInput>>>;
  readonly createdByUserId: Scalars['ID'];
};

export type InsertTenderOptionsInput = {
  readonly tenderModeTerm?: InputMaybe<Scalars['String']>;
  readonly tenderContacts?: InputMaybe<ReadonlyArray<InputMaybe<TenderContactInput>>>;
  readonly businessTimeZoneTerm?: InputMaybe<Scalars['String']>;
  readonly businessDays?: InputMaybe<ReadonlyArray<InputMaybe<TenderBusinessDaysInput>>>;
  readonly tenderExpirationTimeSpan?: InputMaybe<TenderTimeSpanInput>;
  readonly tenderLeadTimeSpan?: InputMaybe<TenderTimeSpanInput>;
  readonly parentObjectType: Scalars['String'];
  readonly parentObjectId: Scalars['ID'];
  readonly autoTender?: InputMaybe<Scalars['Boolean']>;
};

export type InstantMessengerInput = {
  readonly typeTerm: Scalars['String'];
  readonly userName: Scalars['String'];
};

export type InstantMessengerOutput = {
  readonly __typename?: 'InstantMessengerOutput';
  readonly typeTerm: Scalars['String'];
  readonly userName: Scalars['String'];
};

export type IntegrationMapping = {
  readonly __typename?: 'IntegrationMapping';
  readonly id?: Maybe<Scalars['ID']>;
  readonly groupName?: Maybe<Scalars['String']>;
  readonly fromMapping?: Maybe<Scalars['String']>;
  readonly toMapping?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

export type IntegrationMappingInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly groupName: Scalars['String'];
  readonly fromMapping: Scalars['String'];
  readonly toMapping: Scalars['String'];
};

export type IntegrationUpdateResponse = {
  readonly __typename?: 'IntegrationUpdateResponse';
  readonly status?: Maybe<Scalars['String']>;
  readonly meessage?: Maybe<Scalars['String']>;
  readonly dictionariesUpdated?: Maybe<ReadonlyArray<DictionaryUpdated>>;
};

export type Interaction = {
  readonly __typename?: 'Interaction';
  readonly employee?: Maybe<Employee>;
  readonly id: Scalars['ID'];
  readonly interactableId: Scalars['ID'];
  readonly interactionType: InteractionTypeEnum;
  readonly utcDatetime: Scalars['ISO8601DateTime'];
};

export type InteractionInput = {
  readonly employeeId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly interactableId?: InputMaybe<Scalars['ID']>;
  readonly interactableType?: InputMaybe<Scalars['String']>;
  readonly interactionType: InteractionTypeEnum;
  readonly utcDatetime: Scalars['String'];
};

export type InteractionInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly employeeId?: InputMaybe<Scalars['ID']>;
  readonly interactionType: InteractionTypeEnum;
  readonly utcDatetime: Scalars['String'];
};

export enum InteractionTypeEnum {
  /** Zero Trucks Confirmed This Date */
  MarkNoTrucks = 'markNoTrucks',
  /** markNoContainers */
  MarkNoContainers = 'markNoContainers',
  /** markNoGateReservations */
  MarkNoGateReservations = 'markNoGateReservations'
}

export type InteractionTypeError = {
  readonly __typename?: 'InteractionTypeError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type InteractionV2 = {
  readonly __typename?: 'InteractionV2';
  readonly employee?: Maybe<EmployeeV2>;
  readonly employeeId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly interactableId: Scalars['ID'];
  readonly interactionType: InteractionTypeEnum;
  readonly utcDatetime: Scalars['ISO8601DateTime'];
};

export type IntermediateStop = {
  readonly __typename?: 'IntermediateStop';
  readonly stopId?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly facilityId?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly facilityName?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly stopCity?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly stopState?: Maybe<Scalars['String']>;
  readonly stopType?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly stopEndDate?: Maybe<Scalars['DateTime']>;
  readonly stopEndTime?: Maybe<Scalars['Float']>;
  readonly stopEndTimezone?: Maybe<Scalars['String']>;
  readonly stopStartDate?: Maybe<Scalars['DateTime']>;
  readonly stopStartTime?: Maybe<Scalars['Float']>;
  readonly stopStartTimezone?: Maybe<Scalars['String']>;
};

export type InternalCreateTruckEntryInput = {
  readonly carrierId: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly driver2Name?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originLocation?: InputMaybe<TruckLocationInput>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  /** The Power (Tractor) unit ID associated with the capacity */
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly powerNumber?: InputMaybe<Scalars['String']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId: Scalars['ID'];
  readonly bracingType?: InputMaybe<BracingTypesEnum>;
  readonly bracingCount?: InputMaybe<Scalars['Float']>;
  readonly bracingTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Provide time in milliseconds. */
  readonly breakTimeMs?: InputMaybe<Scalars['Float']>;
  /** Provide time in milliseconds. Cannot exceed 70 hours */
  readonly cycleTimeMs?: InputMaybe<Scalars['Float']>;
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly driverDomicile?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly driverPhone?: InputMaybe<Scalars['String']>;
  readonly driver2Domicile?: InputMaybe<Scalars['String']>;
  readonly driver2Id?: InputMaybe<Scalars['ID']>;
  readonly driver2Phone?: InputMaybe<Scalars['String']>;
  /** Provide time in milliseconds. Cannot exceed 14 hours */
  readonly driveTimeMs?: InputMaybe<Scalars['Float']>;
  readonly externalSourceId?: InputMaybe<Scalars['String']>;
  /** Truck's finalBy datetime. Value is nullified if the finalByDatetime value is less than the loadByDatetime value. */
  readonly finalByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly finalByTimezone?: InputMaybe<Scalars['String']>;
  /** Truck's loadBy datetime, defaults to the readyDatetimeUtc (EOD) if a value is not provided or if the loadByDatetimeUtc value is less than the readyDatetimeUtc value */
  readonly loadByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly loadByTimezone?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly palletsType?: InputMaybe<PalletTypesEnum>;
  readonly palletsCount?: InputMaybe<Scalars['Float']>;
  readonly palletTypeId?: InputMaybe<Scalars['String']>;
  /** DEPRECATED: Use previousRouteIdV2 instead */
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  readonly previousRouteIdV2?: InputMaybe<Scalars['ID']>;
  readonly readyDatetimeUtc: Scalars['ISO8601DateTime'];
  /** Provide time in milliseconds. Cannot exceed 16 hours */
  readonly shiftTimeMs?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED: Use sourceTypeId instead */
  readonly sourceType?: InputMaybe<SourceTypesEnum>;
  readonly sourceTypeId?: InputMaybe<Scalars['String']>;
  readonly tarpsType?: InputMaybe<TarpTypesEnum>;
  readonly tarpsCount?: InputMaybe<Scalars['Float']>;
  readonly tarpTypeId?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly truckEntryTemplateId?: InputMaybe<Scalars['ID']>;
  /** DDT ID for the capacity source of the projected hours of service */
  readonly phosSourceId?: InputMaybe<Scalars['String']>;
  readonly assignedRouteId?: InputMaybe<Scalars['ID']>;
  /** Corresponds to "capacity_utilization_status" DDT id */
  readonly capacityUtilizationStatusId?: InputMaybe<Scalars['ID']>;
};

export type IntrastateAuthority = {
  readonly __typename?: 'IntrastateAuthority';
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly intraStateAuthorityNumber?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['ID']>;
  /** @deprecated The field `intrastateAuthorityState` is deprecated. */
  readonly intrastateAuthorityState?: Maybe<Scalars['String']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly intrastateAuthorityStateId?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['ISO8601Date']>;
  /** @deprecated The field `intrastateId` is deprecated. */
  readonly intrastateId?: Maybe<Scalars['ID']>;
  readonly tXIntraStateAuthorityCertificate?: Maybe<Scalars['String']>;
  /** @deprecated use intraStateAuthorityNumber */
  readonly intrastateAuthorityNumber?: Maybe<Scalars['Int']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly intrastateNumber?: Maybe<Scalars['String']>;
  readonly codeType?: Maybe<Scalars['String']>;
  readonly authorityType?: Maybe<Scalars['String']>;
};

export type IntrastateAuthorityInput = {
  /**  The field `intrastateAuthorityNumber` is deprecated. Use `intraStateAuthorityNumber` instead.  */
  readonly intrastateAuthorityNumber?: InputMaybe<Scalars['Int']>;
  readonly intraStateAuthorityNumber?: InputMaybe<Scalars['String']>;
  readonly intrastateNumber?: InputMaybe<Scalars['String']>;
  readonly vendorCode?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  /**  The field `intrastateAuthorityState` is deprecated. */
  readonly intrastateAuthorityState?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly intrastateAuthorityStateId?: InputMaybe<Scalars['String']>;
  readonly destroy?: InputMaybe<Scalars['Boolean']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly expirationDate?: InputMaybe<Scalars['ISO8601Date']>;
  /**  The field `intrastateId` is deprecated.  */
  readonly intrastateId?: InputMaybe<Scalars['ID']>;
  readonly tXIntraStateAuthorityCertificate?: InputMaybe<Scalars['String']>;
  readonly codeType?: InputMaybe<Scalars['String']>;
  readonly authorityType?: InputMaybe<Scalars['String']>;
};

export type InvalidAddress = {
  readonly __typename?: 'InvalidAddress';
  readonly requestId?: Maybe<Scalars['ID']>;
  readonly formattedAddress?: Maybe<FormattedAddress>;
  readonly resultCodes?: Maybe<ReadonlyArray<Maybe<AddressResultCodes>>>;
};

export type Invitation = {
  readonly __typename?: 'Invitation';
  readonly id: Scalars['ID'];
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly createDate: Scalars['DateTime'];
  readonly updateDate: Scalars['DateTime'];
  readonly expirationDate: Scalars['DateTime'];
};

export type InvitationStatus = {
  readonly __typename?: 'InvitationStatus';
  readonly email?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly timestamp?: Maybe<Scalars['DateTime']>;
};

export type InvitationStatusInput = {
  readonly email: Scalars['String'];
  readonly tenantId: Scalars['String'];
};

export type InviteDriverInput = {
  readonly driverId: Scalars['ID'];
  readonly phoneNumber: Scalars['String'];
};

export type InviteDriverPayload = {
  readonly __typename?: 'InviteDriverPayload';
  readonly driverInviteSuccess: Scalars['Boolean'];
  readonly errors: ReadonlyArray<UserError>;
};

export enum InvoiceAuditTypeEnum {
  Initial = 'INITIAL',
  Resend = 'RESEND',
  Recreate = 'RECREATE'
}

/** The Invoice Batch.  The Invoice Batch will contain 1-n Invoice Headers. */
export type InvoiceBatch = {
  readonly __typename?: 'InvoiceBatch';
  readonly id?: Maybe<Scalars['ID']>;
  readonly description: Scalars['String'];
  readonly invoiceHeaders: ReadonlyArray<InvoiceHeader>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly status: StatusEnum;
};

export type InvoiceBatchCreateInput = {
  /** The order ids in the batch */
  readonly orderIds: ReadonlyArray<Scalars['ID']>;
  /** Description of the batch */
  readonly description: Scalars['String'];
};

export type InvoiceBatchDistributionInput = {
  readonly invoiceId: Scalars['ID'];
  readonly externalEmail?: InputMaybe<Scalars['String']>;
  readonly invoiceDistributionMethod?: InputMaybe<Scalars['String']>;
};

/** The Invoice Batch Processing Input Type.  Used to Process Invoice Batches */
export type InvoiceBatchProcessingInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly invoiceHeadersForProcessing: ReadonlyArray<InvoiceHeaderProcessingInput>;
  readonly invoiceOverrides?: InputMaybe<InvoiceOverridesInput>;
};

export type InvoiceDistributionHistory = {
  readonly __typename?: 'InvoiceDistributionHistory';
  readonly order_Code: Scalars['String'];
  readonly invoice_Number?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly eid?: Maybe<Scalars['Long']>;
  readonly customer_Name?: Maybe<Scalars['String']>;
  readonly source?: Maybe<InvoiceAuditTypeEnum>;
  readonly invoiceDistributionMethod?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Date']>;
  readonly createdOnDateTime?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['Date']>;
};

export enum InvoiceExchangeRateConversionDateEnum {
  Pickupdate = 'PICKUPDATE',
  Deliverydate = 'DELIVERYDATE',
  Invoicedate = 'INVOICEDATE'
}

export enum InvoiceGroupingEnum {
  /**
   * Invoice totals are grouped by customer
   * @deprecated Not Implemented
   */
  Combined = 'Combined',
  /**
   * Invoice totals are grouped by order
   * @deprecated Not Implemented
   */
  Order = 'Order'
}

/** The Invoice Header Type */
export type InvoiceHeader = {
  readonly __typename?: 'InvoiceHeader';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly status: StatusEnum;
  readonly invoiceDate?: Maybe<Scalars['DateTime']>;
  readonly dueDate?: Maybe<Scalars['DateTime']>;
  readonly dateSettled?: Maybe<Scalars['DateTime']>;
  readonly originalAmountDue?: Maybe<Scalars['Decimal']>;
  readonly currentAmountDue?: Maybe<Scalars['Decimal']>;
  /** @deprecated Deprecated, do not use */
  readonly invoiceGrouping: InvoiceGroupingEnum;
  readonly invoiceNumber: Scalars['String'];
  readonly discountAmount?: Maybe<Scalars['Decimal']>;
  readonly invoiceDistributionMethod?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['ID']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly billToId?: Maybe<Scalars['ID']>;
  readonly billToCodeId?: Maybe<Scalars['ID']>;
  readonly billToAddress?: Maybe<Scalars['String']>;
  readonly billToEmail?: Maybe<Scalars['String']>;
  readonly currencyId?: Maybe<Scalars['String']>;
  readonly orderFinancialStatus?: Maybe<OrderFinancialStatusEnum>;
  readonly orderLifeCycleStatus?: Maybe<OrderLifeCycleStatusEnum>;
  readonly paymentMethod?: Maybe<Scalars['String']>;
  readonly customerPaymentTermId?: Maybe<Scalars['String']>;
  readonly paymentMethodId?: Maybe<Scalars['String']>;
  readonly rateDetails: ReadonlyArray<RateDetail>;
  readonly invoiceCurrencyOriginalAmountDue?: Maybe<Scalars['Decimal']>;
  readonly invoiceCurrencyCurrentAmountDue?: Maybe<Scalars['Decimal']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly currencyDiscountAmount?: Maybe<Scalars['Decimal']>;
  readonly invoiceProcessedDateTime?: Maybe<Scalars['DateTime']>;
  readonly creditMemoProcessedDateTime?: Maybe<Scalars['DateTime']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly batchId?: Maybe<Scalars['ID']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly hasSeerSearchLimitReached?: Maybe<Scalars['Boolean']>;
  readonly businessUnit?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  readonly billToCode?: Maybe<BillToCode>;
  readonly billTo?: Maybe<Customer>;
  readonly billToV2?: Maybe<CustomerV2>;
  readonly statementNumber?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly distributionDate?: Maybe<Scalars['DateTime']>;
  readonly billToInvoiceGrouping?: Maybe<Scalars['String']>;
  readonly invoiceType?: Maybe<Scalars['String']>;
  readonly invoiceGroupingMethod?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type InvoiceHeaderConnection = {
  readonly __typename?: 'InvoiceHeaderConnection';
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<InvoiceHeaderEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<InvoiceHeader>>>;
};

/** An edge in a connection. */
export type InvoiceHeaderEdge = {
  readonly __typename?: 'InvoiceHeaderEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<InvoiceHeader>;
};

/** Invoice details (Rate Details) object when processing a invoice. */
export type InvoiceHeaderProcessingInput = {
  readonly id: Scalars['ID'];
  readonly rateDetailsForProcessing: ReadonlyArray<RateDetailProcessingInput>;
};

/** Overrides for invoices at the time of invoice processing */
export type InvoiceOverridesInput = {
  readonly invoiceDate?: InputMaybe<Scalars['DateTime']>;
  readonly paymentTerms?: InputMaybe<Scalars['String']>;
  readonly invoiceDistributionMethod?: InputMaybe<Scalars['String']>;
};

/** Represents Invoicing Requirement Configuration */
export type InvoicingRequirementConfiguration = {
  readonly __typename?: 'InvoicingRequirementConfiguration';
  readonly id: Scalars['ID'];
  readonly bypassRequirement?: Maybe<Scalars['Boolean']>;
  readonly requirementType?: Maybe<Scalars['String']>;
  readonly createdById?: Maybe<Scalars['ID']>;
  readonly createdByName?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedById?: Maybe<Scalars['ID']>;
  readonly updatedByName?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

/** Represents input type to update invoicing requirement configuration. */
export type InvoicingRequirementConfigurationUpdateInput = {
  readonly id: Scalars['ID'];
  readonly bypassRequirement: Scalars['Boolean'];
};

export type IPlannedNetworkError = {
  readonly message: Scalars['String'];
  readonly code: Scalars['String'];
};

export type IProjectNameError = {
  readonly message: Scalars['String'];
  readonly code: Scalars['String'];
};

export type IsaIdReceiverFilterInput = {
  readonly ediTransactionId: Scalars['Uuid'];
};

/** The input type for saving inbound 204 ISA receiver IDs and application codes. */
export type IsaReceiverIdApplicationCodeInput = {
  readonly ediTransactionId: Scalars['Uuid'];
  readonly isaReceiverId: Scalars['String'];
  readonly applicationReceiversCode: Scalars['String'];
};

/** Response Object from IsSNIShipmentServiceAvailable */
export type IsSniShipmentServiceAvailable = {
  readonly __typename?: 'IsSNIShipmentServiceAvailable';
  readonly code?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
};

export type JobConfigurationInput = {
  readonly jobName: Scalars['String'];
  readonly scheduledTime: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
};

export type JobConfigurationType = {
  readonly __typename?: 'JobConfigurationType';
  readonly id?: Maybe<Scalars['ID']>;
  readonly jobName: Scalars['String'];
  readonly scheduledTime: Scalars['String'];
  readonly lastRun: Scalars['DateTime'];
  readonly enabled: Scalars['Boolean'];
  readonly createdById?: Maybe<Scalars['ID']>;
  readonly createdByName?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedById?: Maybe<Scalars['ID']>;
  readonly updatedByName?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

export type JobLoadInput = {
  readonly jobId?: InputMaybe<Scalars['ID']>;
  readonly jobType?: InputMaybe<Scalars['String']>;
};

export type JournalEntryCreateInput = {
  readonly userID: Scalars['ID'];
  readonly loadID: Scalars['ID'];
  readonly entryTermID?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly notifyUserIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type JournalEntryCreateInputV2 = {
  readonly userID: Scalars['ID'];
  readonly loadID: Scalars['ID'];
  readonly entryTermID?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly notifyUserIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type JournalEntryUpdateInput = {
  readonly userID: Scalars['ID'];
  readonly loadID: Scalars['ID'];
  readonly entryTermID?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly notifyUserIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['ID'];
};

export type JournalEntryUpdateInputV2 = {
  readonly userID: Scalars['ID'];
  readonly loadID: Scalars['ID'];
  readonly entryTermID?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly notifyUserIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['ID'];
};

/** The Jurisdiction Taxes. */
export type JurisdictionTax = {
  readonly __typename?: 'JurisdictionTax';
  readonly id: Scalars['ID'];
  readonly country: Scalars['String'];
  readonly state: Scalars['String'];
  readonly glCode: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly taxRate: Scalars['Decimal'];
  readonly harmonized: Scalars['Boolean'];
  readonly expirationDate?: Maybe<Scalars['Date']>;
};

/** The Jurisdiction Taxes. */
export type JurisdictionTaxInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly country: Scalars['String'];
  readonly state: Scalars['String'];
  readonly glCode: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly taxRate: Scalars['Decimal'];
  readonly harmonized: Scalars['Boolean'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
};

/** This is the value portion of the KeyValueCollectionType. It is a generic representation of all configurable types, such as AddressType, or ContactType. */
export type KeyValue = {
  readonly __typename?: 'KeyValue';
  readonly active: Scalars['Boolean'];
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly metadataJson: Scalars['String'];
  readonly name: Scalars['String'];
  readonly sort: Scalars['Float'];
  readonly systemDefined: Scalars['Boolean'];
};

/**
 * This is a collection of options, where keyValueTypes are grouped by name.  These
 * are generally tenant-configurable options, such as ContactType or AddressType.
 */
export type KeyValueCollection = {
  readonly __typename?: 'KeyValueCollection';
  readonly name: ConfigTypeEnum;
  readonly options: ReadonlyArray<KeyValue>;
};

export type KeyValuePairOfStringAndPropertyMetadata = {
  readonly __typename?: 'KeyValuePairOfStringAndPropertyMetadata';
  readonly key: Scalars['String'];
  readonly value: PropertyMetadata;
};

export type KeyValueType = {
  readonly __typename?: 'KeyValueType';
  readonly active: Scalars['Boolean'];
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly metadataJson: Scalars['String'];
  readonly name: Scalars['String'];
  readonly shortDisplayName?: Maybe<Scalars['String']>;
  readonly sort: Scalars['Float'];
  readonly systemDefined: Scalars['Boolean'];
};

/** Deprecated. Use LaneV2. */
export type Lane = {
  readonly __typename?: 'Lane';
  readonly laneId: Scalars['ID'];
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly originGeographyType: LaneKeyValue;
  readonly originId?: Maybe<Scalars['String']>;
  readonly originFacilityId?: Maybe<Scalars['ID']>;
  readonly destinationGeographyType: LaneKeyValue;
  readonly destinationId?: Maybe<Scalars['String']>;
  readonly destinationFacilityId?: Maybe<Scalars['ID']>;
  readonly transportMode?: Maybe<LaneKeyValue>;
  readonly loadSize?: Maybe<LaneKeyValue>;
  readonly equipment?: Maybe<ReadonlyArray<LaneKeyValue>>;
  readonly scac?: Maybe<LaneKeyValue>;
  readonly minLength?: Maybe<LaneUnitOfDistance>;
  readonly distance: LaneUnitOfDistance;
  readonly customer?: Maybe<Customer>;
  readonly employee?: Maybe<Employee>;
  readonly originFacility?: Maybe<Facility>;
  readonly destinationFacility?: Maybe<Facility>;
  readonly originCityGeography?: Maybe<Geography>;
  readonly destinationCityGeography?: Maybe<Geography>;
  readonly originStateGeography?: Maybe<Geography>;
  readonly destinationStateGeography?: Maybe<Geography>;
  readonly originCountry?: Maybe<LaneKeyValue>;
  readonly destinationCountry?: Maybe<LaneKeyValue>;
  readonly active: Scalars['Boolean'];
  readonly createdById?: Maybe<Scalars['Uuid']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
};

export enum LaneDistanceUnitEnum {
  Feet = 'feet',
  Inches = 'inches',
  Kilometers = 'kilometers',
  Meters = 'meters',
  Miles = 'miles'
}

export type LaneGeographyRadiusSearchInput = {
  readonly distance?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly postalCodeCountry?: InputMaybe<Scalars['String']>;
};

/** DO NOT USE. Please use DDT */
export enum LaneGeographyTypeEnum {
  City = 'city',
  Country = 'country',
  Facility = 'facility',
  State = 'state',
  Postalcode = 'postalcode',
  /** @deprecated DO NOT USE. Please use postalcode */
  Zip = 'zip',
  PostalPrefix = 'POSTAL_PREFIX',
  Region = 'REGION'
}

export type LaneInput = {
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly distance?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly minLength?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly originId: Scalars['String'];
  readonly originGeographyType: Scalars['String'];
  readonly destinationGeographyType: Scalars['String'];
  readonly destinationId: Scalars['String'];
  readonly equipment: ReadonlyArray<InputMaybe<Scalars['ID']>>;
  readonly transportMode?: InputMaybe<Scalars['String']>;
  readonly scac?: InputMaybe<Scalars['String']>;
};

export type LaneInputV2 = {
  readonly transportMode?: InputMaybe<Scalars['String']>;
  readonly minLength?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minHeight?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly equipment: ReadonlyArray<InputMaybe<Scalars['ID']>>;
  readonly service?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly laneStops: ReadonlyArray<InputMaybe<LaneStopInputV2>>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['Uuid']>;
  readonly laneType?: InputMaybe<Scalars['String']>;
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['Uuid']>>;
};

export type LaneInputV3 = {
  readonly transportMode?: InputMaybe<Scalars['String']>;
  readonly minLength?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minHeight?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly equipment: ReadonlyArray<InputMaybe<Scalars['ID']>>;
  readonly service?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly laneStops: ReadonlyArray<InputMaybe<LaneStopInputV3>>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly laneType?: InputMaybe<Scalars['String']>;
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['Uuid']>>;
};

export type LaneInputV4 = {
  readonly transportMode?: InputMaybe<Scalars['String']>;
  readonly minLength?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minHeight?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly equipment: ReadonlyArray<InputMaybe<Scalars['ID']>>;
  readonly service?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly laneType?: InputMaybe<Scalars['String']>;
  readonly laneStops: ReadonlyArray<InputMaybe<LaneStopInputV4>>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['Uuid']>>;
};

export type LaneKeyValue = {
  readonly __typename?: 'LaneKeyValue';
  readonly id: Scalars['String'];
  readonly name?: Maybe<Scalars['String']>;
  readonly metaDataJson?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly sort?: Maybe<Scalars['Int']>;
  /** @deprecated No longer returned. Always false. */
  readonly systemDefined?: Maybe<Scalars['Boolean']>;
};

export type LaneMatchScore = {
  readonly __typename?: 'LaneMatchScore';
  readonly lane?: Maybe<LaneV2>;
  readonly matchScore?: Maybe<Scalars['Int']>;
};

export type LaneOrderMatch = {
  readonly __typename?: 'LaneOrderMatch';
  readonly order: CustomerOrderV2;
};

/** A connection to a list of items. */
export type LaneOrderMatchConnection = {
  readonly __typename?: 'LaneOrderMatchConnection';
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<LaneOrderMatchEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<LaneOrderMatch>>>;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LaneOrderMatchEdge = {
  readonly __typename?: 'LaneOrderMatchEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<LaneOrderMatch>;
};

export type LaneOrdersInput = {
  readonly laneId: Scalars['ID'];
  readonly customerId?: InputMaybe<Scalars['ID']>;
};

export type LaneOutput = {
  readonly __typename?: 'LaneOutput';
  readonly laneId: Scalars['ID'];
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly originGeographyType: LaneKeyValue;
  readonly originId?: Maybe<Scalars['String']>;
  readonly originFacilityId?: Maybe<Scalars['ID']>;
  readonly destinationGeographyType: LaneKeyValue;
  readonly destinationId?: Maybe<Scalars['String']>;
  readonly destinationFacilityId?: Maybe<Scalars['ID']>;
  readonly transportMode?: Maybe<LaneKeyValue>;
  readonly loadSize?: Maybe<LaneKeyValue>;
  readonly equipment?: Maybe<ReadonlyArray<LaneKeyValue>>;
  readonly scac?: Maybe<LaneKeyValue>;
  readonly minLength?: Maybe<LaneUnitOfDistance>;
  readonly distance: LaneUnitOfDistance;
  readonly customer?: Maybe<Customer>;
  readonly employee?: Maybe<Employee>;
  readonly originFacility?: Maybe<Facility>;
  readonly destinationFacility?: Maybe<Facility>;
  readonly originCityGeography?: Maybe<Geography>;
  readonly destinationCityGeography?: Maybe<Geography>;
  readonly originStateGeography?: Maybe<Geography>;
  readonly destinationStateGeography?: Maybe<Geography>;
  readonly originCountry?: Maybe<LaneKeyValue>;
  readonly destinationCountry?: Maybe<LaneKeyValue>;
  readonly active: Scalars['Boolean'];
  readonly createdById?: Maybe<Scalars['Uuid']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
};

export type LanePaginatedFilter = {
  readonly originGeographyType?: InputMaybe<Scalars['String']>;
  readonly originGeographySearch?: InputMaybe<LaneGeographyRadiusSearchInput>;
  readonly destinationGeographyType?: InputMaybe<Scalars['String']>;
  readonly destinationGeographySearch?: InputMaybe<LaneGeographyRadiusSearchInput>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly serviceIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly transportModeId?: InputMaybe<Scalars['String']>;
  readonly transportModeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly originStopTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly originStopType?: InputMaybe<Scalars['String']>;
  readonly destinationStopTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly destinationStopType?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly divisions?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly minLength?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minHeight?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly customerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly loadSizeId?: InputMaybe<Scalars['String']>;
  readonly loadSizeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly originGeographyTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly originGeographySearches?: InputMaybe<ReadonlyArray<InputMaybe<LaneGeographyRadiusSearchInput>>>;
  readonly destinationGeographyTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly destinationGeographySearches?: InputMaybe<ReadonlyArray<InputMaybe<LaneGeographyRadiusSearchInput>>>;
  readonly laneId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly code?: InputMaybe<Scalars['Int']>;
  readonly carrierIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly sortByColumn?: InputMaybe<LaneSortByColumnEnum>;
  readonly sortDirection?: InputMaybe<LaneSortDirectionEnum>;
  /** Deprecated. DO NOT USE. */
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  /** Deprecated. DO NOT USE. */
  readonly scacId?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly laneType?: InputMaybe<Scalars['String']>;
  readonly excludeRegionLane?: InputMaybe<Scalars['Boolean']>;
  readonly laneTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type LaneRouteMatch = {
  readonly __typename?: 'LaneRouteMatch';
  readonly route: LoadRouteV2;
};

/** A connection to a list of items. */
export type LaneRouteMatchConnection = {
  readonly __typename?: 'LaneRouteMatchConnection';
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<LaneRouteMatchEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<LaneRouteMatch>>>;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LaneRouteMatchEdge = {
  readonly __typename?: 'LaneRouteMatchEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<LaneRouteMatch>;
};

export type LaneRoutesInput = {
  readonly laneId: Scalars['ID'];
  readonly carrierId?: InputMaybe<Scalars['ID']>;
};

export enum LaneSortByColumnEnum {
  LaneCode = 'laneCode',
  OriginType = 'originType',
  OriginStopType = 'originStopType',
  OriginFacility = 'originFacility',
  OriginCity = 'originCity',
  OriginState = 'originState',
  OriginCountry = 'originCountry',
  OriginZip = 'originZip',
  DestinationType = 'destinationType',
  DestinationStopType = 'destinationStopType',
  DestinationFacility = 'destinationFacility',
  DestinationCity = 'destinationCity',
  DestinationState = 'destinationState',
  DestinationCountry = 'destinationCountry',
  DestinationZip = 'destinationZip',
  StopCount = 'stopCount',
  Equipment = 'equipment',
  Mode = 'mode',
  Division = 'division',
  Size = 'size',
  Length = 'length',
  Width = 'width',
  Height = 'height',
  Service = 'service',
  Project = 'project',
  BusinessUnit = 'businessUnit',
  LaneType = 'laneType'
}

export enum LaneSortDirectionEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export type LaneStop = {
  readonly __typename?: 'LaneStop';
  readonly id: Scalars['ID'];
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly sequence: Scalars['Int'];
  readonly stopType: Scalars['String'];
  readonly stopCountry?: Maybe<LaneKeyValue>;
  readonly stopPostalCode?: Maybe<PostalCode>;
  readonly stopPostalPrefix?: Maybe<PostalPrefix>;
  readonly latitude?: Maybe<Scalars['Decimal']>;
  readonly longitude?: Maybe<Scalars['Decimal']>;
  readonly geographyType: LaneKeyValue;
  readonly createdBy?: Maybe<User>;
  /** @deprecated Use stopFacilityTypeV2 instead. */
  readonly stopFacilityType?: Maybe<Facility>;
  readonly stopFacilityTypeV2?: Maybe<FacilityV2>;
  /** @deprecated Use stopCityGeographyV2 instead. */
  readonly stopCityGeography?: Maybe<Geography>;
  readonly stopCityGeographyV2?: Maybe<GeographyServiceRecord>;
  /** @deprecated Use stopStateGeographyV2 instead. */
  readonly stopStateGeography?: Maybe<Geography>;
  readonly stopStateGeographyV2?: Maybe<GeographyServiceRecord>;
  readonly stopRegion?: Maybe<RegionV2>;
};

export type LaneStopInputV2 = {
  readonly geographyId: Scalars['String'];
  readonly geographyType: LaneGeographyTypeEnum;
  readonly stopType: LaneStopTypeEnum;
  readonly sequence: Scalars['Int'];
  readonly postalCodeCountry?: InputMaybe<Scalars['String']>;
  readonly abbreviatedState?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly geoPoint?: InputMaybe<UploadsLaneGeographyPointInput>;
  readonly country?: InputMaybe<Scalars['String']>;
};

export type LaneStopInputV3 = {
  readonly geographyId: Scalars['String'];
  readonly geographyType: LaneGeographyTypeEnum;
  readonly stopType: Scalars['String'];
  readonly sequence: Scalars['Int'];
  readonly postalCodeCountry?: InputMaybe<Scalars['String']>;
  readonly abbreviatedState?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly geoPoint?: InputMaybe<UploadsLaneGeographyPointInput>;
  readonly country?: InputMaybe<Scalars['String']>;
};

export type LaneStopInputV4 = {
  readonly geographyId: Scalars['String'];
  readonly geographyType: Scalars['String'];
  readonly stopType: Scalars['String'];
  readonly sequence: Scalars['Int'];
  readonly postalCodeCountry?: InputMaybe<Scalars['String']>;
};

export type LaneStops = {
  readonly __typename?: 'LaneStops';
  readonly geographyId?: Maybe<Scalars['String']>;
  readonly geographyType?: Maybe<Scalars['String']>;
  readonly postalCodeCountry?: Maybe<Scalars['String']>;
  readonly sequence?: Maybe<Scalars['Int']>;
  readonly stopType?: Maybe<Scalars['String']>;
};

export enum LaneStopTypeEnum {
  Pu = 'PU',
  Del = 'Del',
  Ta = 'TA',
  Td = 'TD'
}

export type LaneUnitOfDistance = {
  readonly __typename?: 'LaneUnitOfDistance';
  readonly asFeet?: Maybe<Scalars['Decimal']>;
  readonly asInches?: Maybe<Scalars['Decimal']>;
  readonly asKilometers?: Maybe<Scalars['Decimal']>;
  readonly asMeters?: Maybe<Scalars['Decimal']>;
  readonly asMiles?: Maybe<Scalars['Decimal']>;
  readonly asRoundedFeet?: Maybe<Scalars['Decimal']>;
  readonly asRoundedInches?: Maybe<Scalars['Decimal']>;
  readonly asRoundedKilometers?: Maybe<Scalars['Decimal']>;
  readonly asRoundedMeters?: Maybe<Scalars['Decimal']>;
  readonly asRoundedMiles?: Maybe<Scalars['Decimal']>;
};

export type LaneUnitOfDistanceInput = {
  readonly unit?: InputMaybe<LaneDistanceUnitEnum>;
  readonly value?: InputMaybe<Scalars['Decimal']>;
};

export type LaneV2 = {
  readonly __typename?: 'LaneV2';
  readonly id: Scalars['ID'];
  readonly code: Scalars['Int'];
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly transportMode?: Maybe<LaneKeyValue>;
  readonly minLength?: Maybe<LaneUnitOfDistance>;
  readonly minHeight?: Maybe<LaneUnitOfDistance>;
  readonly minWidth?: Maybe<LaneUnitOfDistance>;
  readonly division?: Maybe<LaneKeyValue>;
  readonly project?: Maybe<LaneKeyValue>;
  readonly businessUnit?: Maybe<LaneKeyValue>;
  readonly loadSize?: Maybe<LaneKeyValue>;
  readonly services?: Maybe<ReadonlyArray<Maybe<LaneKeyValue>>>;
  readonly equipments?: Maybe<ReadonlyArray<LaneKeyValue>>;
  readonly stops?: Maybe<ReadonlyArray<LaneStop>>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly laneType?: Maybe<LaneKeyValue>;
  /** @deprecated Use createdByV2 instead */
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
};

/** A connection to a list of items. */
export type LaneV2Connection = {
  readonly __typename?: 'LaneV2Connection';
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<LaneV2Edge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<LaneV2>>>;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LaneV2Edge = {
  readonly __typename?: 'LaneV2Edge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<LaneV2>;
};

export type LastPingOutput = {
  readonly __typename?: 'LastPingOutput';
  readonly id: Scalars['ID'];
  readonly powerId: Scalars['ID'];
  /** @deprecated cityState is deprecated. Use city and state instead. */
  readonly cityState: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly locationDescription?: Maybe<Scalars['String']>;
  readonly coordinates: CoordinatesOutput;
  readonly relativePosition: RelativePositionOutput;
  readonly source: Scalars['String'];
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly pingEventDateTime: Scalars['DateTime'];
  /** @deprecated createdAt is deprecated. */
  readonly createdAt: Scalars['DateTime'];
};

export enum LastUpdate {
  Created = 'Created',
  Deleted = 'Deleted',
  Updated = 'Updated'
}

export type LatLongPair = {
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export enum LeadSourceEnum {
  Phone = 'PHONE',
  Referral = 'REFERRAL',
  Web = 'WEB'
}

export type LeadTime = {
  readonly __typename?: 'LeadTime';
  readonly leadTime?: Maybe<Scalars['Int']>;
  readonly leadTimeUnit?: Maybe<LeadTimeSpanUnitEnum>;
};

export type LeadTimeInput = {
  readonly leadTime?: InputMaybe<Scalars['Int']>;
  readonly leadTimeUnit?: InputMaybe<LeadTimeSpanUnitEnum>;
};

export enum LeadTimeSpanUnitEnum {
  Hours = 'HOURS',
  Minutes = 'MINUTES',
  Seconds = 'SECONDS'
}

export type LeaveInput = {
  readonly allotted?: InputMaybe<Scalars['Int']>;
  readonly resetDate?: InputMaybe<Scalars['Date']>;
};

export type LeaveOutput = {
  readonly __typename?: 'LeaveOutput';
  readonly allotted?: Maybe<Scalars['Int']>;
  readonly remaining?: Maybe<Scalars['Int']>;
  readonly resetDate?: Maybe<Scalars['Date']>;
};

export type LegDivision = {
  readonly __typename?: 'LegDivision';
  /** Minion division. */
  readonly division: PlannedNetworkDdt;
  readonly isPrimary: Scalars['Boolean'];
};

export enum LegScheduleDay {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY'
}

export type LegScheduleUnit = {
  readonly __typename?: 'LegScheduleUnit';
  readonly day: LegScheduleDay;
  readonly time: Scalars['LocalTime'];
};

export type LegStop = {
  readonly __typename?: 'LegStop';
  readonly facility: FacilityV2;
  readonly isOrigin: Scalars['Boolean'];
  readonly canDropTrailer: Scalars['Boolean'];
  readonly networkEntryExit: Scalars['Boolean'];
};

export type Length = {
  readonly __typename?: 'Length';
  readonly unit: UnitOfLengthEnum;
  readonly value: Scalars['Float'];
};

export type LengthInput = {
  readonly unit: UnitOfLengthEnum;
  readonly value: Scalars['Float'];
};

export type LengthRangeInput = {
  readonly min?: InputMaybe<UnitOfLengthInput>;
  readonly max?: InputMaybe<UnitOfLengthInput>;
};

export type LengthV2 = {
  readonly __typename?: 'LengthV2';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export enum LevelTypeEnum {
  /** noneDefined */
  NoneDefined = 'noneDefined',
  /** Bronze */
  Bronze = 'Bronze',
  /** Silver */
  Silver = 'Silver',
  /** Gold */
  Gold = 'Gold',
  /** Platinum */
  Platinum = 'Platinum'
}

export type LinesOfBusiness = {
  readonly __typename?: 'LinesOfBusiness';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly code?: Maybe<Scalars['String']>;
  readonly lineOfBusiness: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly updatedByUser?: Maybe<UserV2>;
};

export type LinesOfBusinessInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly lineOfBusiness: Scalars['String'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
};

export type LinkedRouteError = {
  readonly __typename?: 'LinkedRouteError';
  readonly message: Scalars['String'];
};

/** The initiating route for the linked route API */
export enum LinkedRouteInitiatingRouteEnum {
  Current = 'current',
  Next = 'next'
}

/** The link type for the linked route API */
export enum LinkedRouteLinkTypeEnum {
  EmptyMovementToLoaded = 'EmptyMovementToLoaded',
  LoadedToEmptyMovement = 'LoadedToEmptyMovement',
  LoadedToLoaded = 'LoadedToLoaded'
}

export type LinkedRoutes = {
  readonly __typename?: 'LinkedRoutes';
  readonly routeID: Scalars['ID'];
  readonly linkedRoutes: ReadonlyArray<LinkedRouteV3>;
};

export type LinkedRoutesInput = {
  readonly routeID: Scalars['ID'];
};

export type LinkedRouteV2 = {
  readonly __typename?: 'LinkedRouteV2';
  readonly id: Scalars['ID'];
  readonly createdDatetime: Scalars['DateTime'];
  readonly updatedDatetime: Scalars['DateTime'];
  readonly initiatingRoute?: Maybe<LinkedRouteInitiatingRouteEnum>;
  readonly route: LoadRouteV2;
  readonly previousRoute?: Maybe<LoadRouteV2>;
  readonly nextRoute?: Maybe<LoadRouteV2>;
  /** @deprecated Removed in Rails decommission -- use createdByV2 */
  readonly createdBy: User;
  /** @deprecated Removed in Rails decommission -- use updatedByV2 */
  readonly updatedBy: User;
  readonly createdByV2: UserV2;
  readonly updatedByV2: UserV2;
};

export type LinkedRouteV3 = {
  readonly __typename?: 'LinkedRouteV3';
  readonly id: Scalars['ID'];
  readonly createdDatetime: Scalars['DateTime'];
  readonly updatedDatetime: Scalars['DateTime'];
  readonly initiatingRoute?: Maybe<LinkedRouteInitiatingRouteEnum>;
  readonly linkType: LinkedRouteLinkTypeEnum;
  readonly currentRoute: LoadRouteV2;
  readonly nextRoute: LoadRouteV2;
  readonly createdByV2: UserV2;
  readonly updatedByV2: UserV2;
};

/** Base class that all Load responses should implement */
export type Load = {
  readonly __typename?: 'Load';
  readonly id: Scalars['ID'];
  readonly code?: Maybe<Scalars['Int']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdBy: User;
  readonly note?: Maybe<Scalars['String']>;
  readonly orders: ReadonlyArray<CustomerOrder>;
  readonly routes: ReadonlyArray<LoadRoute>;
  readonly status: Scalars['String'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
};

export type LoadAllocatedMargins = {
  readonly __typename?: 'LoadAllocatedMargins';
  readonly loadId: Scalars['ID'];
  readonly loadCost?: Maybe<Scalars['Decimal']>;
  readonly loadRate?: Maybe<Scalars['Decimal']>;
  readonly companyOverheadAmount?: Maybe<Scalars['Decimal']>;
  readonly loadRateNetCompanyOverhead?: Maybe<Scalars['Decimal']>;
  readonly totalAllocatedOrderRate?: Maybe<Scalars['Decimal']>;
  readonly totalAllocatedOrderCost?: Maybe<Scalars['Decimal']>;
  readonly totalAllocatedRouteRate?: Maybe<Scalars['Decimal']>;
  readonly totalAllocatedRouteCost?: Maybe<Scalars['Decimal']>;
  readonly orderAllocatedMargins?: Maybe<ReadonlyArray<Maybe<OrderAllocatedMargin>>>;
  readonly routeAllocatedMargins?: Maybe<ReadonlyArray<Maybe<RouteAllocatedMargin>>>;
  /** @deprecated This field is intended to help diagnose commissions calculation issues */
  readonly commissionEventReport?: Maybe<CommissionEvent>;
  /** @deprecated Please use loadV2. */
  readonly load?: Maybe<Load>;
  readonly loadV2?: Maybe<LoadV2>;
};

export type LoadAuditResponse = {
  readonly __typename?: 'LoadAuditResponse';
  readonly customer?: Maybe<Customer>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly customerOrder?: Maybe<CustomerOrder>;
  readonly customerOrderV2?: Maybe<CustomerOrderV2>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly eventData?: Maybe<Scalars['String']>;
  readonly eventType?: Maybe<Scalars['String']>;
  readonly eventTypeCategory?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly newValue?: Maybe<Scalars['String']>;
  readonly objectType?: Maybe<Scalars['String']>;
  readonly oldValue?: Maybe<Scalars['String']>;
  readonly reasonCode?: Maybe<Scalars['String']>;
  readonly route?: Maybe<LoadRoute>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly routeV2?: Maybe<LoadRouteV2>;
  readonly triggeredBySystem?: Maybe<Scalars['String']>;
  readonly triggeredByUser?: Maybe<User>;
  readonly triggeredByUserId?: Maybe<Scalars['ID']>;
  readonly triggeredByUserV2?: Maybe<UserV2>;
  readonly triggeredOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use newly added property triggeredByUser to get the user information. */
  readonly user?: Maybe<Employee>;
  readonly vendor?: Maybe<Carrier>;
  readonly vendorId?: Maybe<Scalars['ID']>;
  readonly vendorV2?: Maybe<CarrierV2>;
};

/** A connection to a list of items. */
export type LoadAuditResponseConnection = {
  readonly __typename?: 'LoadAuditResponseConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<LoadAuditResponseEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<LoadAuditResponse>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadAuditResponseEdge = {
  readonly __typename?: 'LoadAuditResponseEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<LoadAuditResponse>;
};

export type LoadBoard = {
  readonly __typename?: 'LoadBoard';
  readonly name: Scalars['String'];
  readonly code: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
};

export type LoadCalculatePayableDistanceInput = {
  readonly routeId: Scalars['String'];
  readonly loadId: Scalars['String'];
  readonly payableRouteStopIds: ReadonlyArray<Scalars['String']>;
};

export type LoadCommissionsOverrideConfiguration = {
  readonly __typename?: 'LoadCommissionsOverrideConfiguration';
  readonly id: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly customerAllocationSplit?: Maybe<Scalars['Decimal']>;
  readonly carrierAllocationSplit?: Maybe<Scalars['Decimal']>;
  readonly globalCustomerAllocationSplit?: Maybe<Scalars['Decimal']>;
  readonly globalCarrierAllocationSplit?: Maybe<Scalars['Decimal']>;
};

export type LoadCommissionsOverrideConfigurationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly loadId: Scalars['ID'];
  readonly customerAllocationSplit: Scalars['Decimal'];
  readonly carrierAllocationSplit: Scalars['Decimal'];
};

/** The connection type for Load. */
export type LoadConnection = {
  readonly __typename?: 'LoadConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<LoadEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type LoadCurrency = {
  readonly __typename?: 'LoadCurrency';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type LoadCurrencyInput = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type LoadDefaultConditionsInput = {
  readonly scacIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly transportModeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly sizeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly paymentTermsIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly tenderFromIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type LoadDefaults = {
  readonly __typename?: 'LoadDefaults';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['String'];
  readonly mode: Scalars['String'];
  /** @deprecated SizeTypesEnum is deprecated. Use sizeV2 instead. */
  readonly size?: Maybe<SizeTypesEnum>;
  readonly sizeV2?: Maybe<Scalars['String']>;
  readonly minLength?: Maybe<Scalars['Float']>;
  readonly width?: Maybe<Scalars['Float']>;
  readonly height?: Maybe<Scalars['Float']>;
  readonly equipment?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly specialRequirements?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly cargoValue?: Maybe<Scalars['Float']>;
  readonly cargoUnit?: Maybe<Scalars['String']>;
  readonly doNotPostLoads?: Maybe<Scalars['Boolean']>;
  readonly disableAutoPost: Scalars['Boolean'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly loadDefaultsDivisions?: Maybe<ReadonlyArray<LoadDefaultsDivision>>;
  readonly loadDefaultsBusinessUnits?: Maybe<ReadonlyArray<LoadDefaultsBusinessUnit>>;
  readonly loadDefaultsEquipment?: Maybe<ReadonlyArray<LoadDefaultsEquipment>>;
  readonly loadDefaultsSpecialRequirement?: Maybe<ReadonlyArray<LoadDefaultsSpecialRequirement>>;
};

export type LoadDefaultsBusinessUnit = {
  readonly __typename?: 'LoadDefaultsBusinessUnit';
  readonly id: Scalars['ID'];
  readonly loadDefaultsId: Scalars['String'];
  readonly businessUnit: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly loadDefaults?: Maybe<LoadDefaults>;
};

export type LoadDefaultsConnection = {
  readonly __typename?: 'LoadDefaultsConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<LoadDefaultsEdge>;
};

export type LoadDefaultsDivision = {
  readonly __typename?: 'LoadDefaultsDivision';
  readonly id: Scalars['ID'];
  readonly loadDefaultsId: Scalars['String'];
  readonly division: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly loadDefaults?: Maybe<LoadDefaults>;
};

export type LoadDefaultsEdge = {
  readonly __typename?: 'LoadDefaultsEdge';
  readonly node: LoadDefaults;
  readonly cursor: Scalars['String'];
};

export type LoadDefaultsEquipment = {
  readonly __typename?: 'LoadDefaultsEquipment';
  readonly id: Scalars['ID'];
  readonly loadDefaultsId: Scalars['String'];
  readonly equipment: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly loadDefaults?: Maybe<LoadDefaults>;
};

export type LoadDefaultsFilter = {
  /** customerId to filter by */
  readonly customerId?: InputMaybe<Scalars['ID']>;
};

export type LoadDefaultsSpecialRequirement = {
  readonly __typename?: 'LoadDefaultsSpecialRequirement';
  readonly id: Scalars['ID'];
  readonly loadDefaultsId: Scalars['String'];
  readonly specialRequirement: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly loadDefaults?: Maybe<LoadDefaults>;
};

export type LoadDetail = {
  readonly __typename?: 'LoadDetail';
  readonly error?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['ID']>;
};

export type LoadDocument = {
  readonly __typename?: 'LoadDocument';
  readonly id: Scalars['ID'];
  /**
   * DEPRECATED: Use loadV2 instead
   * @deprecated Use loadV2 instead
   */
  readonly load: Load;
  /** V2 version of load */
  readonly loadV2?: Maybe<LoadV2>;
  /**
   * DEPRECATED: Use customerOrderV2 instead
   * @deprecated Use customerOrderV2 instead
   */
  readonly customerOrder?: Maybe<CustomerOrder>;
  /** V2 version of customerOrder */
  readonly customerOrderV2?: Maybe<CustomerOrderV2>;
  /**
   * DEPRECATED: Use routes instead
   * @deprecated Use routes instead
   */
  readonly route?: Maybe<LoadRoute>;
  /**
   * DEPRECATED: Use routes instead
   * @deprecated Use routes instead
   */
  readonly routeV2?: Maybe<LoadRouteV2>;
  readonly routes?: Maybe<ReadonlyArray<LoadRouteV2>>;
  readonly vendorId?: Maybe<Scalars['ID']>;
  readonly stopIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  /** The name of the file to be displayed to the user, not necessarily the filename */
  readonly name: Scalars['String'];
  /** The name of the file provided during upload */
  readonly filename: Scalars['String'];
  /**
   * DEPRECATED: Use uploadedByUserV2 instead
   * @deprecated Use uploadedByUserV2 field instead
   */
  readonly uploadedBy: User;
  /**
   * DEPRECATED: Use uploadedByUserV2 instead
   * @deprecated Use uploadedByUserV2 instead
   */
  readonly uploadedByUser?: Maybe<User>;
  /**
   * DEPRECATED: Use updatedByUserV2 instead
   * @deprecated Use updatedByUserV2 instead
   */
  readonly updatedByUser?: Maybe<User>;
  /** Upload date timestamp of document */
  readonly uploadDate: Scalars['DateTime'];
  readonly sourceId: Scalars['ID'];
  readonly documentTypeId: Scalars['ID'];
  /** uri to access the document */
  readonly uri: Scalars['String'];
  readonly thirdPartyDocumentId?: Maybe<Scalars['ID']>;
  /** V2 version of customer */
  readonly customerV2?: Maybe<CustomerV2>;
  /** uri to access the signed document */
  readonly signedUri?: Maybe<Scalars['String']>;
  /** MasterSign Reference Id */
  readonly masterSignReferenceId?: Maybe<Scalars['String']>;
  /** Identification for MasterSigner */
  readonly signedBy?: Maybe<Scalars['String']>;
  /** Timestamp for when document was signed */
  readonly signedAt?: Maybe<Scalars['DateTime']>;
  /**
   * Document Status
   * @deprecated Depcrecated in favor of documentStatus
   */
  readonly status?: Maybe<Scalars['String']>;
  /** Document Status */
  readonly documentStatus?: Maybe<DocumentStatusEnum>;
  /** Previous Document Status */
  readonly previousDocumentStatus?: Maybe<DocumentStatusEnum>;
  /** Identification for External Uploader */
  readonly externalUser?: Maybe<Scalars['String']>;
  /**
   * DEPRECATED: Use uploadedByUserV2 instead
   * @deprecated Use uploadedByUserV2 field instead
   */
  readonly uploadedByV2: UserV2;
  readonly uploadedByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
};

export type LoadDocumentConnection = {
  readonly __typename?: 'LoadDocumentConnection';
  readonly edges: ReadonlyArray<LoadDocumentGqlTypeEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Float'];
};

export type LoadDocumentFilter = {
  readonly loadId: Scalars['ID'];
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly orderId?: InputMaybe<Scalars['ID']>;
};

export type LoadDocumentGqlTypeEdge = {
  readonly __typename?: 'LoadDocumentGQLTypeEdge';
  readonly cursor: Scalars['String'];
  readonly node: LoadDocument;
};

export type LoadDocumentSort = {
  readonly key: LoadDocumentSortEnum;
  readonly direction: LoadDocumentSortDirectionsEnum;
};

export enum LoadDocumentSortDirectionsEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum LoadDocumentSortEnum {
  CreatedAt = 'createdAt'
}

/** An edge in a connection. */
export type LoadEdge = {
  readonly __typename?: 'LoadEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: Load;
};

/** Load flip input */
export type LoadFlipInput = {
  /** Load ID for the load */
  readonly loadId: Scalars['ID'];
  /** Route ID associated with the load */
  readonly routeId?: InputMaybe<Scalars['String']>;
  /** Order ID associated with the load */
  readonly orderId?: InputMaybe<Scalars['String']>;
};

/** Load flip reponse record */
export type LoadFlipResponse = {
  readonly __typename?: 'LoadFlipResponse';
  /** Load ID for the load */
  readonly loadId: Scalars['ID'];
  /** Route ID associated with the load */
  readonly routeId?: Maybe<Scalars['String']>;
  /** Order ID associated with the load */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Request status for this load and route */
  readonly status: Scalars['String'];
  /** Associated error details for failed requests */
  readonly error?: Maybe<Scalars['String']>;
};

/** Load flips input */
export type LoadFlipsInput = {
  /** A list of Load IDs that will be validated and re-run */
  readonly loadId: ReadonlyArray<Scalars['ID']>;
};

export type LoadInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly orders?: InputMaybe<ReadonlyArray<CreateOrUpdateCustomerOrderInput>>;
};

export type LoadInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly massUpload?: InputMaybe<MassUploadLoadInput>;
  readonly jobInput?: InputMaybe<JobLoadInput>;
  readonly orders?: InputMaybe<ReadonlyArray<CustomerOrderInput>>;
  readonly stops?: InputMaybe<ReadonlyArray<CreateLoadStopInput>>;
  readonly stopsV2?: InputMaybe<ReadonlyArray<CreateLoadStopInputV2>>;
};

export type LoadMoneySummary = {
  readonly __typename?: 'LoadMoneySummary';
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly totalCost?: Maybe<Scalars['Decimal']>;
  readonly totalGrossRate?: Maybe<Scalars['Decimal']>;
  readonly totalNetMargin?: Maybe<Scalars['Decimal']>;
  readonly totalNetRate?: Maybe<Scalars['Decimal']>;
  readonly customerOverhead?: Maybe<Scalars['Decimal']>;
  readonly companyOverhead?: Maybe<Scalars['Decimal']>;
  readonly costDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  readonly rateDetails?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
  readonly customerOverheadDetails?: Maybe<ReadonlyArray<Maybe<LoadMoneySummaryCustomerOverheadDetail>>>;
};

export type LoadMoneySummaryCustomerOverheadDetail = {
  readonly __typename?: 'LoadMoneySummaryCustomerOverheadDetail';
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly customerOverhead?: Maybe<Scalars['Decimal']>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
};

export type LoadPayload = {
  readonly __typename?: 'LoadPayload';
  readonly errors: ReadonlyArray<LoadTypeError>;
  readonly load?: Maybe<LoadV2>;
};

export type LoadRepeatCreateCostDetails = {
  readonly costDetails: ReadonlyArray<CostDetailAddInput>;
  readonly rerateableCostDetails: ReadonlyArray<CostDetailAddInput>;
};

export type LoadRepeatCreatedCostDetailsPayload = {
  readonly __typename?: 'LoadRepeatCreatedCostDetailsPayload';
  readonly adjustmentRequired?: Maybe<Scalars['String']>;
  readonly createdCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
};

export type LoadRepeatCreatedRateDetailsPayload = {
  readonly __typename?: 'LoadRepeatCreatedRateDetailsPayload';
  readonly adjustmentRequired?: Maybe<Scalars['String']>;
  readonly createdRateDetails?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
};

export type LoadRepeatCreateRateDetails = {
  readonly rateDetails: ReadonlyArray<RateDetailAddInput>;
  readonly rerateableRateDetails: ReadonlyArray<RateDetailAddInput>;
};

export type LoadRequiredDocumentsSummary = {
  readonly __typename?: 'LoadRequiredDocumentsSummary';
  readonly loadId: Scalars['ID'];
  readonly orderId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly vendorId?: Maybe<Scalars['ID']>;
  readonly entityName?: Maybe<Scalars['String']>;
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly isPaperworkComplete?: Maybe<Scalars['Boolean']>;
};

export type LoadRoute = {
  readonly __typename?: 'LoadRoute';
  readonly id: Scalars['ID'];
  readonly postings?: Maybe<ReadonlyArray<Maybe<Posting>>>;
  readonly routeVendorsV2?: Maybe<ReadonlyArray<RouteVendorV2>>;
  readonly lock?: Maybe<Lock>;
  readonly activationStatus?: Maybe<KeyValue>;
  readonly activeRouteVendors?: Maybe<ReadonlyArray<RouteVendor>>;
  readonly actualMaxWeight?: Maybe<Mass>;
  readonly cargoInsurance?: Maybe<Currency>;
  readonly code?: Maybe<Scalars['String']>;
  readonly dimensions?: Maybe<CustomerOrderDimensions>;
  readonly division?: Maybe<Scalars['String']>;
  readonly expectedMaxWeight?: Maybe<Mass>;
  readonly firstStop?: Maybe<Stop>;
  readonly hotRoute?: Maybe<Scalars['Boolean']>;
  readonly inboundRegion?: Maybe<Region>;
  readonly lastStop?: Maybe<Stop>;
  readonly lifeCycleStatus?: Maybe<Scalars['String']>;
  readonly load?: Maybe<Load>;
  readonly maxCost?: Maybe<RouteMaxCostType>;
  readonly outboundRegion?: Maybe<Region>;
  readonly requirements?: Maybe<ReadonlyArray<OrderRequirement>>;
  readonly routeVendors?: Maybe<ReadonlyArray<RouteVendor>>;
  readonly segmentCode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<KeyValue>;
  readonly stops: ReadonlyArray<Stop>;
  readonly stopsCount?: Maybe<Scalars['Int']>;
  readonly totalDistance?: Maybe<Length>;
  readonly trailerTypes?: Maybe<ReadonlyArray<KeyValue>>;
  readonly transportMode?: Maybe<KeyValue>;
};


export type LoadRouteLockArgs = {
  flavor: LockFlavor;
};


export type LoadRouteActualMaxWeightArgs = {
  unit?: InputMaybe<MassUnitTypeEnum>;
};


export type LoadRouteCargoInsuranceArgs = {
  unit?: InputMaybe<UnitOfCurrencyEnum>;
};


export type LoadRouteExpectedMaxWeightArgs = {
  unit?: InputMaybe<MassUnitTypeEnum>;
};


export type LoadRouteTotalDistanceArgs = {
  unit: UnitOfLengthEnum;
};

/** The connection type for LoadRoute. */
export type LoadRouteConnection = {
  readonly __typename?: 'LoadRouteConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<LoadRouteEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LoadRouteEdge = {
  readonly __typename?: 'LoadRouteEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: LoadRoute;
};

export type LoadRouteEdgeV2 = {
  readonly __typename?: 'LoadRouteEdgeV2';
  readonly node: LoadRouteV2;
  readonly cursor: Scalars['String'];
};

export type LoadRouteError = {
  readonly __typename?: 'LoadRouteError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly serviceError?: Maybe<Scalars['JSON']>;
};

export type LoadRoutePayload = {
  readonly __typename?: 'LoadRoutePayload';
  readonly errors: ReadonlyArray<LoadRouteError>;
  readonly route?: Maybe<LoadRouteV2>;
};

export type LoadRoutesConnection = {
  readonly __typename?: 'LoadRoutesConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<LoadRouteEdgeV2>;
};

export type LoadRoutesFilter = {
  /** A list of life cycle statuses to filter by */
  readonly lifecycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of activation statuses to filter by */
  readonly activationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of order rep IDs to filter by */
  readonly orderRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Minimum available start date */
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  /** Maximum available start date */
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  /** A list of inbound sub-region URNs to filter by */
  readonly inboundSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of outbound sub-region URNs to filter by */
  readonly outboundSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of inbound region URNs to filter by */
  readonly inboundRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of inbound super-region URNs to filter by */
  readonly inboundSuperRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of outbound region URNs to filter by */
  readonly outboundRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of outbound super-region URNs to filter by */
  readonly outboundSuperRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of inbound FreightAssignmentRegions to filter by */
  readonly inboundFreightAssignmentRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of outbound FreightAssignmentRegions to filter by */
  readonly outboundFreightAssignmentRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of inbound FreightAssignmentSuperRegions to filter by */
  readonly inboundFreightAssignmentSuperRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of outbound FreightAssignmentSuperRegions to filter by */
  readonly outboundFreightAssignmentSuperRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of inbound FreightAssignmentSubRegions to filter by */
  readonly inboundFreightAssignmentSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of outbound FreightAssignmentSubRegions to filter by */
  readonly outboundFreightAssignmentSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A route code to filter by */
  readonly code?: InputMaybe<Scalars['String']>;
  /** A list of customer IDs to filter by */
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of customer groups to filter by */
  readonly customerGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of equipments to filter by */
  readonly trailerType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of transport modes to filter by */
  readonly transportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Radius and geopoint to filter against first stop address */
  readonly firstStopRadius?: InputMaybe<WithinRadiusInput>;
  /** Radius and geopoint to filter against last stop address */
  readonly lastStopRadius?: InputMaybe<WithinRadiusInput>;
  /** A list of route IDs to filter by */
  readonly routeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of route IDs to ignore from the filtered results */
  readonly ignoreRouteIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** The name of the field to sort the results with. Note: This can only be a field directly on the route */
  readonly sortBy?: InputMaybe<Scalars['String']>;
  /** The direction to sort the results in */
  readonly sortDirection?: InputMaybe<LoadSortDirectionEnum>;
};

/** GraphQL access to LoadRoutesOrders database view */
export type LoadRoutesOrders = {
  readonly __typename?: 'LoadRoutesOrders';
  readonly accountingOrder?: Maybe<Order>;
  readonly firstRouteStop?: Maybe<Stop>;
  readonly firstRouteStopId?: Maybe<Scalars['ID']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly lastRouteStop?: Maybe<Stop>;
  readonly lastRouteStopId?: Maybe<Scalars['ID']>;
  readonly load?: Maybe<Load>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly mainCustomerRepEmployee?: Maybe<Employee>;
  /** @deprecated Field disabled, use main_customer_rep_employee */
  readonly mainCustomerSalesRep?: Maybe<Rep>;
  readonly mainRouteVendorRep?: Maybe<RouteVendorRep>;
  readonly maxCost?: Maybe<RouteMaxCostType>;
  readonly order?: Maybe<CustomerOrder>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly orderCustomer?: Maybe<Customer>;
  readonly orderCustomerId?: Maybe<Scalars['ID']>;
  readonly orderFinancialStatus?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['ID']>;
  readonly orderLifecycleStatus?: Maybe<Scalars['String']>;
  readonly route?: Maybe<LoadRoute>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly routeVendorCost?: Maybe<RouteVendorCost>;
  readonly size?: Maybe<Scalars['String']>;
  /** @deprecated Field disabled, use main_customer_rep_employee */
  readonly tempMainCustomerSalesRep?: Maybe<OrderRep>;
  readonly trailerTypes?: Maybe<ReadonlyArray<KeyValue>>;
  readonly transportMode?: Maybe<Scalars['String']>;
};

/** The connection type for LoadRoutesOrders. */
export type LoadRoutesOrdersConnection = {
  readonly __typename?: 'LoadRoutesOrdersConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<LoadRoutesOrdersEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LoadRoutesOrdersEdge = {
  readonly __typename?: 'LoadRoutesOrdersEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: LoadRoutesOrders;
};

export type LoadRouteV2 = {
  readonly __typename?: 'LoadRouteV2';
  readonly id: Scalars['ID'];
  readonly maxCost?: Maybe<RouteMaxCostType>;
  readonly routeFuelConfigurationOverride?: Maybe<Scalars['String']>;
  readonly assetDriverTenders?: Maybe<ReadonlyArray<Maybe<AssetDriverTender>>>;
  /** @deprecated Use matchedRoutingGuide instead */
  readonly routingGuide?: Maybe<RoutingGuide>;
  readonly matchedRoutingGuide?: Maybe<RoutingGuide>;
  /** All Route Vendors on the Route which are not currently Bounced. */
  readonly activeRouteVendors?: Maybe<ReadonlyArray<RouteVendorV2>>;
  /** All Route Vendors on the Route. */
  readonly routeVendors?: Maybe<ReadonlyArray<RouteVendorV2>>;
  readonly previousRoute?: Maybe<LoadRouteV2>;
  readonly nextRoute?: Maybe<LoadRouteV2>;
  readonly offers?: Maybe<ReadonlyArray<Offer>>;
  readonly postings?: Maybe<ReadonlyArray<Maybe<Posting>>>;
  readonly preplans?: Maybe<ReadonlyArray<Preplan>>;
  readonly resources?: Maybe<RouteResources>;
  readonly currentStop?: Maybe<RouteStop>;
  readonly nextStop?: Maybe<RouteStop>;
  readonly currentStopV2?: Maybe<RouteStopV2>;
  readonly nextStopV2?: Maybe<RouteStopV2>;
  readonly trackingDetails: TrackingDetailsV2;
  /** Most recent Tracking Update with a current location */
  readonly currentLocationUpdate?: Maybe<TrackingUpdateV4>;
  readonly code: Scalars['String'];
  readonly activationStatus?: Maybe<KeyValue>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly size?: Maybe<KeyValue>;
  readonly dimensions?: Maybe<RouteDimensions>;
  /**
   * Please use cargoInsuranceV2. It supports new currency values
   * @deprecated Please use cargoInsuranceV2. It supports new currency values
   */
  readonly cargoInsurance: Currency;
  readonly lifeCycleStatus: Scalars['String'];
  readonly transportMode?: Maybe<KeyValue>;
  readonly trailerTypes: ReadonlyArray<KeyValue>;
  readonly requirements?: Maybe<ReadonlyArray<RouteRequirement>>;
  readonly version: Scalars['Int'];
  /** @deprecated Does not support city/state stops, use stopsV2 instead */
  readonly stops: ReadonlyArray<RouteStop>;
  readonly stopsV2?: Maybe<ReadonlyArray<RouteStopV2>>;
  readonly stopsCount: Scalars['Int'];
  /** @deprecated Does not support city/state stops, use firstStopV2 instead */
  readonly firstStop?: Maybe<RouteStop>;
  readonly firstStopV2?: Maybe<RouteStopV2>;
  /** @deprecated Does not support city/state stops, use lastStopV2 instead */
  readonly lastStop?: Maybe<RouteStop>;
  readonly lastStopV2?: Maybe<RouteStopV2>;
  /** @deprecated Region V1 is deprecated, please use freightAssignmentOutboundRegion instead */
  readonly outboundRegion?: Maybe<Region>;
  /** @deprecated Region V1 is deprecated, please use freightAssignmentInboundRegion instead */
  readonly inboundRegion?: Maybe<Region>;
  readonly lock?: Maybe<Lock>;
  readonly division?: Maybe<Scalars['String']>;
  readonly segmentCode?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly expectedMaxWeight?: Maybe<Mass>;
  readonly expectedMinTemperature?: Maybe<TemperatureV2>;
  readonly hotRoute?: Maybe<Scalars['Boolean']>;
  readonly sequence?: Maybe<Scalars['Float']>;
  readonly freightAssignmentInboundRegion?: Maybe<Scalars['String']>;
  readonly freightAssignmentOutboundRegion?: Maybe<Scalars['String']>;
  readonly activationStatusReason?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['ID']>;
  readonly divisionV2?: Maybe<Scalars['String']>;
  /**
   * businessUnit is deprecated
   * @deprecated businessUnit is deprecated
   */
  readonly businessUnit?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  readonly projectV2?: Maybe<Scalars['String']>;
  readonly tarpType?: Maybe<Scalars['ID']>;
  readonly tarpCount?: Maybe<Scalars['Int']>;
  readonly braceTypes?: Maybe<ReadonlyArray<BraceTypes>>;
  readonly braceCount?: Maybe<Scalars['Int']>;
  readonly cargoInsuranceV2: LoadCurrency;
  readonly additionalDivision?: Maybe<ReadonlyArray<Scalars['String']>>;
  /**
   * additionalBusinessUnit is deprecated
   * @deprecated additionalBusinessUnit is deprecated
   */
  readonly additionalBusinessUnit?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly reps?: Maybe<ReadonlyArray<RouteAssignedRep>>;
  readonly refs?: Maybe<ReadonlyArray<RouteReference>>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly hierarchyCode?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly freightAssignmentAdditionalInboundRegion?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly freightAssignmentAdditionalOutboundRegion?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly commodityTagSummaryIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** encapsulates all fields related to billable mileage. */
  readonly billableMileage?: Maybe<RouteBillableMileage>;
  readonly payableMileage?: Maybe<RoutePayableMileage>;
  readonly load?: Maybe<LoadV2>;
  readonly totalDistance?: Maybe<Length>;
  readonly totalLoadedDistance?: Maybe<Length>;
};


export type LoadRouteV2OffersArgs = {
  filter?: InputMaybe<GetLoadRouteOffersArgsInput>;
};


export type LoadRouteV2CargoInsuranceArgs = {
  unit?: InputMaybe<UnitOfCurrencyEnum>;
};


export type LoadRouteV2ExpectedMaxWeightArgs = {
  unit: MassUnitTypeEnum;
};


export type LoadRouteV2ExpectedMinTemperatureArgs = {
  unit: TemperatureUnitTypeEnumV2;
};


export type LoadRouteV2CargoInsuranceV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type LoadRouteV2TotalDistanceArgs = {
  unit: UnitOfLengthEnum;
};


export type LoadRouteV2TotalLoadedDistanceArgs = {
  unit: UnitOfLengthEnum;
};

export type LoadSearch = {
  readonly __typename?: 'LoadSearch';
  readonly id: Scalars['ID'];
  /** Carrier */
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  /** CarrierId */
  readonly routeCarrierId?: Maybe<Scalars['String']>;
  /** Carrier Rep Names */
  readonly routeCarrierRepNames?: Maybe<Scalars['String']>;
  /** Carrier Rep Ids */
  readonly routeCarrierRepIds?: Maybe<Scalars['String']>;
  readonly routeRepId?: Maybe<Scalars['String']>;
  /** Consignee */
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  /** ConsigneeId */
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  /** Order Customer Name */
  readonly customerName?: Maybe<Scalars['String']>;
  /** Order Customer Id */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly orderRepId?: Maybe<Scalars['String']>;
  /** delApptStatus */
  readonly destStopStatus?: Maybe<Scalars['String']>;
  /** delEndDate */
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  /** delEndTime */
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  /** delEndTimezone */
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  /** delStartDate */
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  /** delStartTime */
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  /** delStartTimezone */
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  /** Destination City */
  readonly destStopCity?: Maybe<Scalars['String']>;
  /** DST - DestinationState */
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly routeDistance?: Maybe<Scalars['Float']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly refNumbers?: Maybe<Scalars['String']>;
  readonly loadCode?: Maybe<Scalars['String']>;
  /** carrierRepNames */
  readonly mainRouteRep?: Maybe<Scalars['String']>;
  readonly mainOrderRep?: Maybe<Scalars['String']>;
  readonly routeMaxCost?: Maybe<Scalars['Float']>;
  /** Mode */
  readonly orderMode?: Maybe<Scalars['String']>;
  /** OAS */
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  /** OLS */
  readonly orderLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['String']>;
  /** OrderNumber */
  readonly orderCode?: Maybe<Scalars['String']>;
  /** Origin City */
  readonly originStopCity?: Maybe<Scalars['String']>;
  /** OST - Origin State */
  readonly originStopState?: Maybe<Scalars['String']>;
  /** OTS */
  readonly orderTenderStatus?: Maybe<Scalars['String']>;
  readonly routeOverMax?: Maybe<Scalars['Float']>;
  /** puApptStatus */
  readonly originStopStatus?: Maybe<Scalars['String']>;
  /** puEndDate */
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  /** puEndTime */
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  /** puEndTimezone */
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  /** puStartDate */
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  /** puStartTime */
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  /** puStartTimezone */
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  /** RAS */
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  /** RLS */
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Float']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Float']>;
  /** Shipper */
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  /** ShipperId */
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  /** Size */
  readonly orderSize?: Maybe<Scalars['String']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly routeTotalCost?: Maybe<Scalars['Float']>;
  readonly routeTotalRate?: Maybe<Scalars['Float']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly orderDivision?: Maybe<Scalars['String']>;
  readonly distanceUnit?: Maybe<Scalars['String']>;
  readonly originFirstLoadStop?: Maybe<Scalars['Boolean']>;
  readonly originLastLoadStop?: Maybe<Scalars['Boolean']>;
  readonly originloadStopSequence?: Maybe<Scalars['Float']>;
  readonly destinationFirstLoadStop?: Maybe<Scalars['Boolean']>;
  readonly destinationLastLoadStop?: Maybe<Scalars['Boolean']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Float']>;
  readonly orderType?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly mainCarrierRepId?: Maybe<Scalars['String']>;
  readonly carrierRepId?: Maybe<Scalars['String']>;
  readonly originRadius?: Maybe<Scalars['String']>;
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly mainCustomerRep?: Maybe<Scalars['String']>;
  readonly destinationRadius?: Maybe<Scalars['String']>;
  readonly carrierStatus?: Maybe<Scalars['String']>;
  readonly booked?: Maybe<Scalars['String']>;
  readonly repType?: Maybe<Scalars['String']>;
  /** Carrier Type */
  readonly routeCarrierType?: Maybe<Scalars['String']>;
};

export type LoadSearchConnection = {
  readonly __typename?: 'LoadSearchConnection';
  readonly edges: ReadonlyArray<LoadSearchEdge>;
  readonly pageInfo: PageInfo;
};

export type LoadSearchEdge = {
  readonly __typename?: 'LoadSearchEdge';
  readonly node: LoadSearch;
  readonly cursor: Scalars['String'];
};

export type LoadSearchFilterInput = {
  readonly loadCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Load Id */
  readonly loadId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Order Number */
  readonly orderCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** RefNumber */
  readonly orderReferences?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** ShipperId */
  readonly originStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** puStartDate */
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  /** puEndDate */
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  /** OriginCity */
  readonly originStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** OriginState - OST */
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopRadius?: InputMaybe<RadiusFilter>;
  /** ConsigneeId */
  readonly destStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** destStartDate */
  readonly destStopStartDate?: InputMaybe<Scalars['DateTime']>;
  /** destEndDate */
  readonly destStopEndDate?: InputMaybe<Scalars['DateTime']>;
  /** DestinationCity */
  readonly destStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** DestinationState - DST */
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopRadius?: InputMaybe<RadiusFilter>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Carrier Id */
  readonly routeCarrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierRepId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly customerRepId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Customer Name */
  readonly customer?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerRep?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierRep?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLifeCycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderLifeCycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Not in used */
  readonly orderRepId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Not in used */
  readonly routeRepId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type LoadsFilter = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly carrierRepId?: InputMaybe<Scalars['ID']>;
  readonly consigneeId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by customer group IDs */
  readonly customerEmployeeGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by customer IDs */
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly customerRepId?: InputMaybe<Scalars['ID']>;
  /** Search/Filter by delivery_address */
  readonly deliveryAddress?: InputMaybe<AddressesFilter>;
  readonly deliveryDateRange?: InputMaybe<DateRangeFilter>;
  /** Search/Filter by equipments */
  readonly equipmentIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly loadRouteOrOrderCode?: InputMaybe<Scalars['String']>;
  readonly orderActivationStatus?: InputMaybe<Scalars['ID']>;
  /** Search/Filter by Order Activation status */
  readonly orderActivationStatusWithNone?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly orderCode?: InputMaybe<Scalars['String']>;
  readonly orderRepEmployeeId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by order rep IDs */
  readonly orderRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly orderTenderStatus?: InputMaybe<Scalars['ID']>;
  /** Search/Filter by pick_up_address */
  readonly pickUpAddress?: InputMaybe<AddressesFilter>;
  readonly pickUpDateRange?: InputMaybe<DateRangeFilter>;
  readonly ref?: InputMaybe<Scalars['String']>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly routeLifecycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by route rep OR order rep ids */
  readonly routeRepOrOrderRepIds?: InputMaybe<RepIdsFilter>;
  readonly routeVendorRepEmployeeId?: InputMaybe<Scalars['ID']>;
  readonly shipperPickUpFacilityId?: InputMaybe<Scalars['ID']>;
  readonly sort?: InputMaybe<Scalars['String']>;
  /** Use delivery_address or pick_up_address for state collection filtering */
  readonly stateCollection?: InputMaybe<StateFilter>;
  /** Search/filter by transport modes */
  readonly transportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum LoadSortDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Lachesis Load Status Record */
export type LoadStatusRecord = {
  readonly __typename?: 'LoadStatusRecord';
  readonly load_id: Scalars['ID'];
  readonly created_at_timestamp: Scalars['Timestamp'];
  readonly event_timestamp: Scalars['Timestamp'];
  readonly order_financial_status?: Maybe<Scalars['String']>;
  readonly vendor_financial_status?: Maybe<Scalars['String']>;
  readonly order_tender_status?: Maybe<Scalars['String']>;
  readonly route_tender_status?: Maybe<Scalars['String']>;
  readonly order_activation_status?: Maybe<Scalars['String']>;
  readonly route_activation_status?: Maybe<Scalars['String']>;
  readonly order_lifecycle_status?: Maybe<Scalars['String']>;
  readonly route_lifecycle_status?: Maybe<Scalars['String']>;
  readonly route_stop_number?: Maybe<Scalars['Int']>;
  readonly initiator?: Maybe<Scalars['String']>;
};

/** lachesis-api: Load Status Record V3 - Features Multi-Route Functionality */
export type LoadStatusRecordV3 = {
  readonly __typename?: 'LoadStatusRecordV3';
  /** lachesis-api: ID associated with a Load. */
  readonly load_id: Scalars['ID'];
  /** lachesis-api: Timestamp when event occured */
  readonly event_timestamp: Scalars['Timestamp'];
  /** lachesis-api: ID of the user or process that initiated the event for status calculation */
  readonly initiator?: Maybe<Scalars['String']>;
  /** lachesis-api: Array of Orders. */
  readonly orders: ReadonlyArray<OrderStatusRecordV3>;
  /** lachesis-api: Array of Routes. */
  readonly routes: ReadonlyArray<RouteStatusRecordV3>;
};

export type LoadStop = {
  readonly __typename?: 'LoadStop';
  readonly id: Scalars['ID'];
  readonly loadId: Scalars['String'];
  readonly routeStopIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly orderStopIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly sequence: Scalars['Int'];
};

export type LoadStopCommodityInput = {
  readonly commodityId: Scalars['ID'];
  readonly expectedWeight?: InputMaybe<Scalars['Float']>;
  readonly expectedHandlingUnits?: InputMaybe<Scalars['Float']>;
  readonly expectedPieces?: InputMaybe<Scalars['Float']>;
  readonly actualWeight?: InputMaybe<Scalars['Float']>;
  readonly actualPieces?: InputMaybe<Scalars['Float']>;
  readonly orderStopDesignation?: InputMaybe<Scalars['String']>;
};

export type LoadStopInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly routeId: Scalars['ID'];
  readonly facilityId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
  readonly isOrderStop?: InputMaybe<Scalars['Boolean']>;
  readonly type?: InputMaybe<StopTypeEnum>;
  readonly loadStopType?: InputMaybe<Scalars['String']>;
  readonly loadFromId?: InputMaybe<Scalars['ID']>;
  readonly unloadFromId?: InputMaybe<Scalars['ID']>;
  readonly requirements?: InputMaybe<ReadonlyArray<StopRequirementInput>>;
  readonly commodities?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly commoditiesV2?: InputMaybe<ReadonlyArray<LoadStopCommodityInput>>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInputV2>>;
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly reqDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly bolNumber?: InputMaybe<Scalars['String']>;
  /** Appointment fields for this stop/route */
  readonly appointment?: InputMaybe<AppointmentInputV2>;
  readonly sealNumber?: InputMaybe<Scalars['String']>;
  /** Name of the person who confirmed the appointment */
  readonly apptConfBy?: InputMaybe<Scalars['String']>;
  /** Name of the person who was requested to confirm the appointment */
  readonly apptReqWith?: InputMaybe<Scalars['String']>;
  /** stop unique identifier for external system */
  readonly externalRef?: InputMaybe<Scalars['String']>;
  /** validated against DDT stopScheduleChangeReason */
  readonly appointmentChangeReason?: InputMaybe<Scalars['String']>;
  /** Mapped to Total Handling Units on UI  */
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  /** flag for stops created from FE */
  readonly isCreatedManually?: InputMaybe<Scalars['Boolean']>;
  readonly splc?: InputMaybe<Scalars['String']>;
  /** PU/Del code(s) from customer-facility relationship */
  readonly externalFacilityId?: InputMaybe<Scalars['String']>;
  readonly assignedShipments?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverTargetStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly driverTargetEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly driverTargetStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly driverTargetEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly sequence?: InputMaybe<Scalars['Int']>;
  readonly routeStopSequence?: InputMaybe<Scalars['Int']>;
  readonly orderStopDesignation?: InputMaybe<Scalars['String']>;
};

export type LoadStopPayload = {
  readonly __typename?: 'LoadStopPayload';
  readonly errors: ReadonlyArray<LoadStopReturnError>;
  readonly stops?: Maybe<LoadStopReturn>;
};

export type LoadStopReturn = {
  readonly __typename?: 'LoadStopReturn';
  readonly loadStops?: Maybe<ReadonlyArray<LoadStop>>;
};

export type LoadStopReturnError = {
  readonly __typename?: 'LoadStopReturnError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly serviceError?: Maybe<Scalars['JSON']>;
};

export type LoadTender = {
  readonly id: Scalars['ID'];
  readonly options: LoadTenderOptions;
  readonly routeVendor: RouteVendorV2;
  readonly sentBy: UserV2;
  readonly sentOn: Scalars['DateTime'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly document: Document;
  readonly documentV2?: Maybe<Document>;
  readonly notification: LoadTenderNotification;
  readonly status: LoadTenderStatus;
  readonly statusMessage?: Maybe<Scalars['String']>;
};

export type LoadTenderEdi = {
  readonly __typename?: 'LoadTenderEdi';
  readonly acceptedBy: UserV2;
  readonly acceptedOn: Scalars['DateTime'];
};

export type LoadTenderEmail = {
  readonly __typename?: 'LoadTenderEmail';
  readonly to: LoadTenderEmailContact;
  readonly cc?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly bcc?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type LoadTenderEmailContact = {
  readonly __typename?: 'LoadTenderEmailContact';
  readonly name?: Maybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly phone?: Maybe<Scalars['String']>;
};

export type LoadTenderEmailContactInput = {
  readonly name?: InputMaybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly phone?: InputMaybe<Scalars['String']>;
};

export type LoadTenderEmailInput = {
  readonly to: LoadTenderEmailContactInput;
  readonly cc: ReadonlyArray<Scalars['String']>;
  readonly bcc: ReadonlyArray<Scalars['String']>;
};

export type LoadTenderInput = {
  readonly routeVendorId: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type LoadTenderNotification = LoadTenderEdi | LoadTenderEmail;

export type LoadTenderOptions = {
  readonly __typename?: 'LoadTenderOptions';
  readonly hideCarrierCosts: Scalars['Boolean'];
  readonly hideAppointmentTimes: Scalars['Boolean'];
  readonly hideReferenceNumbers: Scalars['Boolean'];
  readonly hideStopDetails?: Maybe<Scalars['Boolean']>;
  readonly isTrailerAcquisitionStopPending: Scalars['Boolean'];
};

export type LoadTenderOptionsInput = {
  readonly hideAppointmentTimes?: InputMaybe<Scalars['Boolean']>;
  readonly hideCarrierCosts?: InputMaybe<Scalars['Boolean']>;
  readonly hideReferenceNumbers?: InputMaybe<Scalars['Boolean']>;
  readonly trailerAcquisitionStopPending?: InputMaybe<Scalars['Boolean']>;
  readonly hideStopDetails?: InputMaybe<Scalars['Boolean']>;
};

export enum LoadTenderStatus {
  Pending = 'Pending',
  Success = 'Success',
  Failure = 'Failure'
}

export type LoadTypeError = {
  readonly __typename?: 'LoadTypeError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly serviceError?: Maybe<Scalars['JSON']>;
};

export type LoadV2 = {
  readonly __typename?: 'LoadV2';
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly status: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly version: Scalars['Int'];
  readonly note?: Maybe<Scalars['String']>;
  readonly createdBy: User;
  readonly createdByV2: UserV2;
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly orders: ReadonlyArray<CustomerOrderV2>;
  readonly routes: ReadonlyArray<LoadRouteV2>;
  readonly stops: ReadonlyArray<LoadStop>;
};

export type LoadV2Error = {
  readonly __typename?: 'LoadV2Error';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type LocalityDetails = {
  readonly __typename?: 'LocalityDetails';
  readonly lat: Scalars['Float'];
  readonly lon: Scalars['Float'];
  readonly locality: Scalars['String'];
  readonly administrativeArea: Scalars['String'];
  readonly administrativeArea2?: Maybe<Scalars['String']>;
};

export type LocalityLookupRequest = {
  readonly countryCode?: InputMaybe<Scalars['String']>;
  readonly locality?: InputMaybe<Scalars['String']>;
  readonly administrativeArea?: InputMaybe<Scalars['String']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly geoPoint?: InputMaybe<GeoPointInput>;
  readonly sourceId?: InputMaybe<Scalars['Int']>;
  readonly localityId?: InputMaybe<Scalars['ID']>;
  readonly geographyId?: InputMaybe<Scalars['ID']>;
};

export type LocalityLookupResponse = {
  readonly __typename?: 'LocalityLookupResponse';
  readonly localityId: Scalars['ID'];
  readonly locality?: Maybe<Scalars['String']>;
  readonly administrativeAreaCode?: Maybe<Scalars['String']>;
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly lat?: Maybe<Scalars['Float']>;
  readonly lon?: Maybe<Scalars['Float']>;
  readonly postalCodes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly sourceId?: Maybe<Scalars['Int']>;
  readonly geographyId?: Maybe<Scalars['ID']>;
  readonly timezone?: Maybe<Scalars['String']>;
  readonly administrativeArea2?: Maybe<Scalars['String']>;
};

export type LocalityStop = {
  readonly __typename?: 'LocalityStop';
  readonly locality: Scalars['String'];
  readonly administrativeArea: Scalars['String'];
  readonly countryCode: Scalars['String'];
  readonly county?: Maybe<Scalars['String']>;
};

/** this is the type for a City, ST location */
export type Location = {
  readonly __typename?: 'Location';
  readonly displayString: Scalars['String'];
  readonly lat: Scalars['Float'];
  readonly lng: Scalars['Float'];
};

export type LocationDat = {
  readonly location: Scalars['String'];
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

/** this is the input type for a City, ST location */
export type LocationInput = {
  readonly displayString?: InputMaybe<Scalars['String']>;
  readonly lat?: InputMaybe<Scalars['Float']>;
  readonly lng?: InputMaybe<Scalars['Float']>;
};

/** Union type for multiple location pings */
export type LocationPing = PowerLocationPing;

export type LocationTrackingInput = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

/** Caen Lock */
export type Lock = {
  readonly __typename?: 'Lock';
  readonly id: Scalars['ID'];
  readonly flavor: LockFlavor;
  readonly objectID: Scalars['ID'];
  /** @deprecated Use the "ownerV2" field instead. */
  readonly owner: User;
  readonly ownerV2: UserV2;
  readonly expirationTimestamp: Scalars['Timestamp'];
};

/** The flavor of the Lock represents the particular service which the lock enables, e.g. restricting adding a carrier on a route to only the user who owns the lock */
export enum LockFlavor {
  Route = 'ROUTE'
}

/** State information about a Lock */
export type LockState = {
  readonly __typename?: 'LockState';
  readonly isLocked: Scalars['Boolean'];
  readonly wasLocked: Scalars['Boolean'];
};

export type LogicalCheck = {
  readonly __typename?: 'LogicalCheck';
  readonly value?: Maybe<Scalars['String']>;
  readonly operator?: Maybe<Scalars['String']>;
  readonly dataName?: Maybe<Scalars['String']>;
  /** @deprecated This property has been replaced by value and will be removed in the future */
  readonly comparator?: Maybe<Scalars['String']>;
};

export type LogicalCheckInput = {
  readonly value?: InputMaybe<Scalars['String']>;
  readonly operator?: InputMaybe<Scalars['String']>;
  readonly dataName?: InputMaybe<Scalars['String']>;
  readonly comparator?: InputMaybe<Scalars['String']>;
};

export enum LogicGate {
  And = 'And',
  Or = 'Or'
}

export type LtlPricesByOrderInput = {
  readonly correlationId: Scalars['String'];
  readonly orderId: Scalars['ID'];
};

/** Holds Ltl Prices By Order Payload */
export type LtlPricesByOrderPayload = {
  readonly __typename?: 'LtlPricesByOrderPayload';
  readonly customer?: Maybe<LtlPricingCustomerV2Type>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<LtlPricingErrorType>>>;
  readonly quotes: ReadonlyArray<LtlPricingQuoteType>;
};

export type LtlPricesByQuoteFacilityStopInputType = {
  readonly facilityId: Scalars['ID'];
};

export type LtlPricesByQuoteGeographyStopInputType = {
  readonly geographySourceId?: InputMaybe<Scalars['Int']>;
};

export type LtlPricesByQuoteInput = {
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly commodities: ReadonlyArray<InputMaybe<PriceLtlQuoteCommodityType>>;
  readonly correlationId: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly equipment?: InputMaybe<PriceLtlQuoteEquipmentType>;
  readonly loadSize: Scalars['String'];
  readonly project?: InputMaybe<Scalars['String']>;
  readonly requirements: ReadonlyArray<Scalars['String']>;
  readonly scac?: InputMaybe<Scalars['String']>;
  readonly serviceLevel?: InputMaybe<Scalars['String']>;
  readonly shipEndDate?: InputMaybe<Scalars['String']>;
  readonly shipStartDate?: InputMaybe<Scalars['String']>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<PriceLtlQuoteStopType>>>;
  readonly transportMode: Scalars['String'];
};

/** Holds Ltl Prices by Quote Payload. */
export type LtlPricesByQuotePayload = {
  readonly __typename?: 'LtlPricesByQuotePayload';
  readonly customer?: Maybe<LtlPricingCustomerV2Type>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<LtlPricingErrorType>>>;
  readonly quotes: ReadonlyArray<LtlPricingQuoteType>;
};

export type LtlPricesByQuotePostalCodeStopInputType = {
  readonly countryCode?: InputMaybe<Scalars['String']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
};

/** Holds Price Ltl carrier line item. */
export type LtlPricingCarrierLineItemType = {
  readonly __typename?: 'LtlPricingCarrierLineItemType';
  readonly chargeType?: Maybe<LtlPricingChargeTypeV2Type>;
  readonly costPer: Scalars['Decimal'];
  readonly currency?: Maybe<Scalars['String']>;
  readonly units: Scalars['Decimal'];
};

/** Holds Price Ltl carrier quote. */
export type LtlPricingCarrierQuoteType = {
  readonly __typename?: 'LtlPricingCarrierQuoteType';
  readonly carrier?: Maybe<LtlPricingCarrierV2Type>;
  readonly interlineStatus?: Maybe<Scalars['String']>;
  readonly lineItems?: Maybe<ReadonlyArray<Maybe<LtlPricingCarrierLineItemType>>>;
  readonly transitDays?: Maybe<Scalars['Int']>;
};

/** Holds Price Ltl carrierV2. */
export type LtlPricingCarrierV2Type = {
  readonly __typename?: 'LtlPricingCarrierV2Type';
  readonly code?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['Uuid']>;
};

/** Holds Price Ltl charge type. */
export type LtlPricingChargeTypeV2Type = {
  readonly __typename?: 'LtlPricingChargeTypeV2Type';
  readonly id: Scalars['Uuid'];
};

/** Holds Price Ltl line item. */
export type LtlPricingCustomerLineItemType = {
  readonly __typename?: 'LtlPricingCustomerLineItemType';
  readonly chargeType?: Maybe<LtlPricingChargeTypeV2Type>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly ratePer: Scalars['Decimal'];
  readonly units: Scalars['Decimal'];
};

/** Holds Price Ltl Quote. */
export type LtlPricingCustomerV2Type = {
  readonly __typename?: 'LtlPricingCustomerV2Type';
  readonly customer: CustomerV2;
  readonly id?: Maybe<Scalars['Uuid']>;
};

/** Holds Price Ltl errors. */
export type LtlPricingErrorType = {
  readonly __typename?: 'LtlPricingErrorType';
  readonly code?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
};

/** Holds Price Ltl Quote Type. */
export type LtlPricingQuoteType = {
  readonly __typename?: 'LtlPricingQuoteType';
  readonly carrierQuotes?: Maybe<LtlPricingCarrierQuoteType>;
  readonly customerLineItems?: Maybe<ReadonlyArray<Maybe<LtlPricingCustomerLineItemType>>>;
  readonly routeId?: Maybe<Scalars['Uuid']>;
};

/** Holds Ltl Rating Data Payload. */
export type LtlRatingDataPayload = {
  readonly __typename?: 'LtlRatingDataPayload';
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly chargeDescription?: Maybe<Scalars['String']>;
  readonly charges?: Maybe<ChargesModel>;
  readonly contractName?: Maybe<Scalars['String']>;
  readonly freightClass?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly service?: Maybe<Scalars['String']>;
  readonly serviceDays?: Maybe<Scalars['Int']>;
  readonly weightInKg?: Maybe<Scalars['Decimal']>;
  readonly weightInLbs?: Maybe<Scalars['Float']>;
};

export type MainPageOrderBoardAssociatedRoutes = {
  readonly __typename?: 'MainPageOrderBoardAssociatedRoutes';
  /** routeId */
  readonly id: Scalars['ID'];
  readonly loadId?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['DateTime']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimeZone?: Maybe<Scalars['String']>;
  readonly distance?: Maybe<Scalars['Float']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly destEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTimeZone?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly driver1?: Maybe<Scalars['String']>;
  readonly driver2?: Maybe<Scalars['String']>;
  readonly trailer?: Maybe<Scalars['String']>;
  readonly lastStopEvent?: Maybe<Scalars['String']>;
};

export type MainPageOrderBoardAssociatedRoutesConnection = {
  readonly __typename?: 'MainPageOrderBoardAssociatedRoutesConnection';
  readonly edges: ReadonlyArray<MainPageOrderBoardAssociatedRoutesEdge>;
  readonly pageInfo: PageInfo;
};

export type MainPageOrderBoardAssociatedRoutesEdge = {
  readonly __typename?: 'MainPageOrderBoardAssociatedRoutesEdge';
  readonly node: MainPageOrderBoardAssociatedRoutes;
  readonly cursor: Scalars['String'];
};

export type MainPageOrderBoardAssociatedRoutesFilterInput = {
  readonly orderId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type MainPageOrderBoardComplete = {
  readonly __typename?: 'MainPageOrderBoardComplete';
  readonly id: Scalars['ID'];
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly orderRequirements?: Maybe<Scalars['String']>;
  readonly orderTenderStatus?: Maybe<Scalars['String']>;
  readonly mainRepName?: Maybe<Scalars['String']>;
  readonly mainRepId?: Maybe<Scalars['String']>;
  readonly loadRelation?: Maybe<Scalars['String']>;
  readonly loadPriceTier?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly totalRouteDistance?: Maybe<Scalars['Float']>;
  readonly totalRouteDistanceUnit?: Maybe<Scalars['String']>;
  readonly stopCount?: Maybe<Scalars['Float']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly orderMode?: Maybe<Scalars['String']>;
  readonly orderSize?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly cargoInsuranceAmount?: Maybe<Scalars['Float']>;
  readonly cargoInsuranceAmountUnit?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly orderLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly loadTenderFromId?: Maybe<Scalars['String']>;
  readonly loadTenderFromCode?: Maybe<Scalars['String']>;
  readonly loadBillToId?: Maybe<Scalars['String']>;
  /** @deprecated Use loadBillToId instead */
  readonly loadBillToCodeId?: Maybe<Scalars['String']>;
  readonly loadBillToCode?: Maybe<Scalars['String']>;
  readonly orderSource?: Maybe<Scalars['String']>;
  readonly totalOrderDistance?: Maybe<Scalars['Float']>;
  readonly totalOrderDistanceUnit?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
};

export type MainPageOrderBoardCompleteConnection = {
  readonly __typename?: 'MainPageOrderBoardCompleteConnection';
  readonly edges: ReadonlyArray<MainPageOrderBoardCompleteEdge>;
  readonly pageInfo: PageInfo;
};

export type MainPageOrderBoardCompleteEdge = {
  readonly __typename?: 'MainPageOrderBoardCompleteEdge';
  readonly node: MainPageOrderBoardComplete;
  readonly cursor: Scalars['String'];
};

export type MainPageOrderBoardCompleteFilterInput = {
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly customerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopRadius?: InputMaybe<RadiusFilter>;
  readonly destStopRadius?: InputMaybe<RadiusFilter>;
  readonly originStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type MainPageOrderBoardCounts = {
  readonly __typename?: 'MainPageOrderBoardCounts';
  readonly completeCount?: Maybe<Scalars['Float']>;
  readonly deliveredCount?: Maybe<Scalars['Float']>;
  readonly inTransitCount?: Maybe<Scalars['Float']>;
  readonly planningCount?: Maybe<Scalars['Float']>;
  readonly id: Scalars['ID'];
};

export type MainPageOrderBoardCountsFilterInput = {
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly customerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopRadius?: InputMaybe<RadiusFilter>;
  readonly destStopRadius?: InputMaybe<RadiusFilter>;
  readonly originStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderLifeCycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type MainPageOrderBoardDelivered = {
  readonly __typename?: 'MainPageOrderBoardDelivered';
  readonly id: Scalars['ID'];
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly orderRequirements?: Maybe<Scalars['String']>;
  readonly orderTenderStatus?: Maybe<Scalars['String']>;
  readonly mainRepName?: Maybe<Scalars['String']>;
  readonly mainRepId?: Maybe<Scalars['String']>;
  readonly loadRelation?: Maybe<Scalars['String']>;
  readonly loadPriceTier?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly totalRouteDistance?: Maybe<Scalars['Float']>;
  readonly totalRouteDistanceUnit?: Maybe<Scalars['String']>;
  readonly stopCount?: Maybe<Scalars['Float']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly orderMode?: Maybe<Scalars['String']>;
  readonly orderSize?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly cargoInsuranceAmount?: Maybe<Scalars['Float']>;
  readonly cargoInsuranceAmountUnit?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly orderLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly loadTenderFromId?: Maybe<Scalars['String']>;
  readonly loadTenderFromCode?: Maybe<Scalars['String']>;
  /** @deprecated Use loadBillToId instead */
  readonly loadBillToCodeId?: Maybe<Scalars['String']>;
  readonly loadBillToId?: Maybe<Scalars['String']>;
  readonly loadBillToCode?: Maybe<Scalars['String']>;
  readonly orderSource?: Maybe<Scalars['String']>;
  readonly totalOrderDistance?: Maybe<Scalars['Float']>;
  readonly totalOrderDistanceUnit?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
};

export type MainPageOrderBoardDeliveredConnection = {
  readonly __typename?: 'MainPageOrderBoardDeliveredConnection';
  readonly edges: ReadonlyArray<MainPageOrderBoardDeliveredEdge>;
  readonly pageInfo: PageInfo;
};

export type MainPageOrderBoardDeliveredEdge = {
  readonly __typename?: 'MainPageOrderBoardDeliveredEdge';
  readonly node: MainPageOrderBoardDelivered;
  readonly cursor: Scalars['String'];
};

export type MainPageOrderBoardDeliveredFilterInput = {
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly customerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopRadius?: InputMaybe<RadiusFilter>;
  readonly destStopRadius?: InputMaybe<RadiusFilter>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type MainPageOrderBoardInTransit = {
  readonly __typename?: 'MainPageOrderBoardInTransit';
  readonly id: Scalars['ID'];
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly orderRequirements?: Maybe<Scalars['String']>;
  readonly orderTenderStatus?: Maybe<Scalars['String']>;
  readonly mainRepName?: Maybe<Scalars['String']>;
  readonly mainRepId?: Maybe<Scalars['String']>;
  readonly loadRelation?: Maybe<Scalars['String']>;
  readonly loadPriceTier?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly totalRouteDistance?: Maybe<Scalars['Float']>;
  readonly totalRouteDistanceUnit?: Maybe<Scalars['String']>;
  readonly stopCount?: Maybe<Scalars['Float']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly orderMode?: Maybe<Scalars['String']>;
  readonly orderSize?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly cargoInsuranceAmount?: Maybe<Scalars['Float']>;
  readonly cargoInsuranceAmountUnit?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly orderLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly loadTenderFromId?: Maybe<Scalars['String']>;
  readonly loadTenderFromCode?: Maybe<Scalars['String']>;
  /** @deprecated Use loadBillToId instead */
  readonly loadBillToCodeId?: Maybe<Scalars['String']>;
  readonly loadBillToId?: Maybe<Scalars['String']>;
  readonly loadBillToCode?: Maybe<Scalars['String']>;
  readonly orderSource?: Maybe<Scalars['String']>;
  readonly totalOrderDistance?: Maybe<Scalars['Float']>;
  readonly totalOrderDistanceUnit?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
};

export type MainPageOrderBoardInTransitConnection = {
  readonly __typename?: 'MainPageOrderBoardInTransitConnection';
  readonly edges: ReadonlyArray<MainPageOrderBoardInTransitEdge>;
  readonly pageInfo: PageInfo;
};

export type MainPageOrderBoardInTransitEdge = {
  readonly __typename?: 'MainPageOrderBoardInTransitEdge';
  readonly node: MainPageOrderBoardInTransit;
  readonly cursor: Scalars['String'];
};

export type MainPageOrderBoardInTransitFilterInput = {
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly customerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopRadius?: InputMaybe<RadiusFilter>;
  readonly destStopRadius?: InputMaybe<RadiusFilter>;
  readonly originStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type MainPageOrderBoardPlanning = {
  readonly __typename?: 'MainPageOrderBoardPlanning';
  readonly id: Scalars['ID'];
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly orderRequirements?: Maybe<Scalars['String']>;
  readonly orderTenderStatus?: Maybe<Scalars['String']>;
  readonly mainRepName?: Maybe<Scalars['String']>;
  readonly mainRepId?: Maybe<Scalars['String']>;
  readonly loadRelation?: Maybe<Scalars['String']>;
  readonly loadPriceTier?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly totalRouteDistance?: Maybe<Scalars['Float']>;
  readonly totalRouteDistanceUnit?: Maybe<Scalars['String']>;
  readonly stopCount?: Maybe<Scalars['Float']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly orderMode?: Maybe<Scalars['String']>;
  readonly orderSize?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly cargoInsuranceAmount?: Maybe<Scalars['Float']>;
  readonly cargoInsuranceAmountUnit?: Maybe<Scalars['String']>;
  readonly orderLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly loadTenderFromId?: Maybe<Scalars['String']>;
  readonly loadTenderFromCode?: Maybe<Scalars['String']>;
  /** @deprecated Use loadBillToId instead */
  readonly loadBillToCodeId?: Maybe<Scalars['String']>;
  readonly loadBillToId?: Maybe<Scalars['String']>;
  readonly loadBillToCode?: Maybe<Scalars['String']>;
  readonly orderSource?: Maybe<Scalars['String']>;
  readonly totalOrderDistance?: Maybe<Scalars['Float']>;
  readonly totalOrderDistanceUnit?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  /** Hold Reasons for the Order */
  readonly activationStatusReasons?: Maybe<Scalars['String']>;
};

export type MainPageOrderBoardPlanningConnection = {
  readonly __typename?: 'MainPageOrderBoardPlanningConnection';
  readonly edges: ReadonlyArray<MainPageOrderBoardPlanningEdge>;
  readonly pageInfo: PageInfo;
};

export type MainPageOrderBoardPlanningEdge = {
  readonly __typename?: 'MainPageOrderBoardPlanningEdge';
  readonly node: MainPageOrderBoardPlanning;
  readonly cursor: Scalars['String'];
};

export type MainPageOrderBoardPlanningFilterInput = {
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly customerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopRadius?: InputMaybe<RadiusFilter>;
  readonly destStopRadius?: InputMaybe<RadiusFilter>;
  readonly originStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderLifeCycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type MainPageRouteBoardArrivedAtDestination = {
  readonly __typename?: 'MainPageRouteBoardArrivedAtDestination';
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  readonly currentStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopDriverDepartureTimezone?: Maybe<Scalars['String']>;
  readonly currentStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly currentStopSequence?: Maybe<Scalars['Float']>;
  readonly customerGroups?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly expectedEmptyTimezone?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly etaDatetime?: Maybe<Scalars['DateTime']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly originStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly originStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly originStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly orderHeight?: Maybe<Scalars['Float']>;
  readonly id: Scalars['ID'];
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly destStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly destStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly destStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly orderLength?: Maybe<Scalars['Float']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly lockId?: Maybe<Scalars['String']>;
  readonly mainRouteRep?: Maybe<Scalars['String']>;
  readonly expectedEmptyDatetime?: Maybe<Scalars['String']>;
  readonly nextStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextStopEndTime?: Maybe<Scalars['Float']>;
  readonly nextStopEndTimezone?: Maybe<Scalars['String']>;
  readonly nextStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextStopStartTime?: Maybe<Scalars['Float']>;
  readonly nextStopStartTimezone?: Maybe<Scalars['String']>;
  readonly nextStopCity?: Maybe<Scalars['String']>;
  readonly nextStopState?: Maybe<Scalars['String']>;
  readonly nextCallBackDatetime?: Maybe<Scalars['DateTime']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopType?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly orderCommitFrequencyTerm?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Float']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Float']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly waybillDatetime?: Maybe<Scalars['DateTime']>;
  readonly waybillStatus?: Maybe<Scalars['String']>;
  readonly orderWidth?: Maybe<Scalars['Float']>;
  readonly routeCapacityNeed?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly routeDivision?: Maybe<Scalars['String']>;
  readonly routeSize?: Maybe<Scalars['String']>;
  readonly routeCreateDate?: Maybe<Scalars['DateTime']>;
  readonly orderRequirements?: Maybe<Scalars['String']>;
  readonly loadTotalWeight?: Maybe<Scalars['Float']>;
  readonly routeTotalCost?: Maybe<Scalars['Float']>;
  readonly routeTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalCost?: Maybe<Scalars['Float']>;
  readonly previousRouteId?: Maybe<Scalars['String']>;
  readonly nextRouteId?: Maybe<Scalars['String']>;
  readonly reloadIntent?: Maybe<Scalars['String']>;
  readonly isHotRoute?: Maybe<Scalars['Boolean']>;
  readonly driverHosClockTime?: Maybe<Scalars['Float']>;
  readonly shiftHosClockTime?: Maybe<Scalars['Float']>;
  readonly cycleHosClockTime?: Maybe<Scalars['Float']>;
  readonly breakHosClockTime?: Maybe<Scalars['Float']>;
  readonly additionalDivision?: Maybe<Scalars['String']>;
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly dutyStatusTerm?: Maybe<Scalars['String']>;
  readonly hosRuleTerm?: Maybe<Scalars['String']>;
};

export type MainPageRouteBoardArrivedAtDestinationConnection = {
  readonly __typename?: 'MainPageRouteBoardArrivedAtDestinationConnection';
  readonly edges: ReadonlyArray<MainPageRouteBoardArrivedAtDestinationEdge>;
  readonly pageInfo: PageInfo;
};

export type MainPageRouteBoardArrivedAtDestinationEdge = {
  readonly __typename?: 'MainPageRouteBoardArrivedAtDestinationEdge';
  readonly node: MainPageRouteBoardArrivedAtDestination;
  readonly cursor: Scalars['String'];
};

export type MainPageRouteBoardArrivedAtDestinationFilterInput = {
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRadius?: InputMaybe<RadiusFilter>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRadius?: InputMaybe<RadiusFilter>;
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type MainPageRouteBoardArrivedAtOrigin = {
  readonly __typename?: 'MainPageRouteBoardArrivedAtOrigin';
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  readonly currentStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopDriverDepartureTimezone?: Maybe<Scalars['String']>;
  readonly currentStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly currentStopSequence?: Maybe<Scalars['Float']>;
  readonly customerGroups?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly expectedEmptyTimezone?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly etaDatetime?: Maybe<Scalars['DateTime']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly originStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly originStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly originStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly orderHeight?: Maybe<Scalars['Float']>;
  readonly id: Scalars['ID'];
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly destStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly destStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly destStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly orderLength?: Maybe<Scalars['Float']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly lockId?: Maybe<Scalars['String']>;
  readonly mainRouteRep?: Maybe<Scalars['String']>;
  readonly expectedEmptyDatetime?: Maybe<Scalars['String']>;
  readonly nextStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextStopEndTime?: Maybe<Scalars['Float']>;
  readonly nextStopEndTimezone?: Maybe<Scalars['String']>;
  readonly nextStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextStopStartTime?: Maybe<Scalars['Float']>;
  readonly nextStopStartTimezone?: Maybe<Scalars['String']>;
  readonly nextStopCity?: Maybe<Scalars['String']>;
  readonly nextStopState?: Maybe<Scalars['String']>;
  readonly nextCallBackDatetime?: Maybe<Scalars['DateTime']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopType?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Float']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Float']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly waybillDatetime?: Maybe<Scalars['DateTime']>;
  readonly waybillStatus?: Maybe<Scalars['String']>;
  readonly orderWidth?: Maybe<Scalars['Float']>;
  readonly routeCapacityNeed?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly routeDivision?: Maybe<Scalars['String']>;
  readonly routeSize?: Maybe<Scalars['String']>;
  readonly routeCreateDate?: Maybe<Scalars['DateTime']>;
  readonly orderRequirements?: Maybe<Scalars['String']>;
  readonly loadTotalWeight?: Maybe<Scalars['Float']>;
  readonly routeTotalCost?: Maybe<Scalars['Float']>;
  readonly routeTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalCost?: Maybe<Scalars['Float']>;
  readonly previousRouteId?: Maybe<Scalars['String']>;
  readonly nextRouteId?: Maybe<Scalars['String']>;
  readonly reloadIntent?: Maybe<Scalars['String']>;
  readonly isHotRoute?: Maybe<Scalars['Boolean']>;
  readonly driverHosClockTime?: Maybe<Scalars['Float']>;
  readonly shiftHosClockTime?: Maybe<Scalars['Float']>;
  readonly cycleHosClockTime?: Maybe<Scalars['Float']>;
  readonly breakHosClockTime?: Maybe<Scalars['Float']>;
  readonly additionalDivision?: Maybe<Scalars['String']>;
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly dutyStatusTerm?: Maybe<Scalars['String']>;
  readonly hosRuleTerm?: Maybe<Scalars['String']>;
  readonly activationStatusReasons?: Maybe<Scalars['String']>;
};

export type MainPageRouteBoardArrivedAtOriginConnection = {
  readonly __typename?: 'MainPageRouteBoardArrivedAtOriginConnection';
  readonly edges: ReadonlyArray<MainPageRouteBoardArrivedAtOriginEdge>;
  readonly pageInfo: PageInfo;
};

export type MainPageRouteBoardArrivedAtOriginEdge = {
  readonly __typename?: 'MainPageRouteBoardArrivedAtOriginEdge';
  readonly node: MainPageRouteBoardArrivedAtOrigin;
  readonly cursor: Scalars['String'];
};

export type MainPageRouteBoardArrivedAtOriginFilterInput = {
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRadius?: InputMaybe<RadiusFilter>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRadius?: InputMaybe<RadiusFilter>;
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type MainPageRouteBoardCompleted = {
  readonly __typename?: 'MainPageRouteBoardCompleted';
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  readonly currentStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopDriverDepartureTimezone?: Maybe<Scalars['String']>;
  readonly currentStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly currentStopSequence?: Maybe<Scalars['Float']>;
  readonly customerGroups?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly expectedEmptyTimezone?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly etaDatetime?: Maybe<Scalars['DateTime']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly originStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly originStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly originStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly orderHeight?: Maybe<Scalars['Float']>;
  readonly id: Scalars['ID'];
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly destStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly destStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly destStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly orderLength?: Maybe<Scalars['Float']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly lockId?: Maybe<Scalars['String']>;
  readonly mainRouteRep?: Maybe<Scalars['String']>;
  readonly expectedEmptyDatetime?: Maybe<Scalars['String']>;
  readonly nextStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextStopEndTime?: Maybe<Scalars['Float']>;
  readonly nextStopEndTimezone?: Maybe<Scalars['String']>;
  readonly nextStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextStopStartTime?: Maybe<Scalars['Float']>;
  readonly nextStopStartTimezone?: Maybe<Scalars['String']>;
  readonly nextStopCity?: Maybe<Scalars['String']>;
  readonly nextStopState?: Maybe<Scalars['String']>;
  readonly nextCallBackDatetime?: Maybe<Scalars['DateTime']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopType?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Float']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Float']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly waybillDatetime?: Maybe<Scalars['DateTime']>;
  readonly waybillStatus?: Maybe<Scalars['String']>;
  readonly orderWidth?: Maybe<Scalars['Float']>;
  readonly routeCapacityNeed?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly routeDivision?: Maybe<Scalars['String']>;
  readonly routeSize?: Maybe<Scalars['String']>;
  readonly routeCreateDate?: Maybe<Scalars['DateTime']>;
  readonly orderRequirements?: Maybe<Scalars['String']>;
  readonly loadTotalWeight?: Maybe<Scalars['Float']>;
  readonly routeTotalCost?: Maybe<Scalars['Float']>;
  readonly routeTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalCost?: Maybe<Scalars['Float']>;
  readonly previousRouteId?: Maybe<Scalars['String']>;
  readonly nextRouteId?: Maybe<Scalars['String']>;
  readonly reloadIntent?: Maybe<Scalars['String']>;
  readonly isHotRoute?: Maybe<Scalars['Boolean']>;
  readonly driverHosClockTime?: Maybe<Scalars['Float']>;
  readonly shiftHosClockTime?: Maybe<Scalars['Float']>;
  readonly cycleHosClockTime?: Maybe<Scalars['Float']>;
  readonly breakHosClockTime?: Maybe<Scalars['Float']>;
  readonly additionalDivision?: Maybe<Scalars['String']>;
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly dutyStatusTerm?: Maybe<Scalars['String']>;
  readonly hosRuleTerm?: Maybe<Scalars['String']>;
};

export type MainPageRouteBoardCompletedConnection = {
  readonly __typename?: 'MainPageRouteBoardCompletedConnection';
  readonly edges: ReadonlyArray<MainPageRouteBoardCompletedEdge>;
  readonly pageInfo: PageInfo;
};

export type MainPageRouteBoardCompletedEdge = {
  readonly __typename?: 'MainPageRouteBoardCompletedEdge';
  readonly node: MainPageRouteBoardCompleted;
  readonly cursor: Scalars['String'];
};

export type MainPageRouteBoardCompletedFilterInput = {
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRadius?: InputMaybe<RadiusFilter>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRadius?: InputMaybe<RadiusFilter>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type MainPageRouteBoardCounts = {
  readonly __typename?: 'MainPageRouteBoardCounts';
  readonly arrivedAtDestCount?: Maybe<Scalars['Float']>;
  readonly arrivedAtOriginCount?: Maybe<Scalars['Float']>;
  readonly completeCount?: Maybe<Scalars['Float']>;
  readonly coveredCount?: Maybe<Scalars['Float']>;
  readonly dispatchedCount?: Maybe<Scalars['Float']>;
  readonly incompleteCount?: Maybe<Scalars['Float']>;
  readonly inTransitCount?: Maybe<Scalars['Float']>;
  readonly openCount?: Maybe<Scalars['Float']>;
  readonly pendingCount?: Maybe<Scalars['Float']>;
  readonly preTenderCount?: Maybe<Scalars['Float']>;
  readonly tenderedCount?: Maybe<Scalars['Float']>;
  /**
   * additionalDivisionCount not in used
   * @deprecated additionalDivisionCount not in used
   */
  readonly additionalDivisionCount?: Maybe<Scalars['Float']>;
  readonly id: Scalars['ID'];
};

export type MainPageRouteBoardCountsConnection = {
  readonly __typename?: 'MainPageRouteBoardCountsConnection';
  readonly edges: ReadonlyArray<MainPageRouteBoardCountsEdge>;
  readonly pageInfo: PageInfo;
};

export type MainPageRouteBoardCountsEdge = {
  readonly __typename?: 'MainPageRouteBoardCountsEdge';
  readonly node: MainPageRouteBoardCounts;
  readonly cursor: Scalars['String'];
};

export type MainPageRouteBoardCountsFilterInput = {
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeCapacityNeed?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly assignedToRepEmployeeId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly division?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly size?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRadius?: InputMaybe<RadiusFilter>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRadius?: InputMaybe<RadiusFilter>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** orderRepId not in used */
  readonly orderRepId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type MainPageRouteBoardCovered = {
  readonly __typename?: 'MainPageRouteBoardCovered';
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  readonly currentStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopDriverDepartureTimezone?: Maybe<Scalars['String']>;
  readonly currentStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly currentStopSequence?: Maybe<Scalars['Float']>;
  readonly customerGroups?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly expectedEmptyTimezone?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly etaDatetime?: Maybe<Scalars['DateTime']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly originStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly originStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly originStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly orderHeight?: Maybe<Scalars['Float']>;
  readonly id: Scalars['ID'];
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly destStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly destStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly destStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly orderLength?: Maybe<Scalars['Float']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly lockId?: Maybe<Scalars['String']>;
  readonly mainRouteRep?: Maybe<Scalars['String']>;
  readonly expectedEmptyDatetime?: Maybe<Scalars['String']>;
  readonly nextStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextStopEndTime?: Maybe<Scalars['Float']>;
  readonly nextStopEndTimezone?: Maybe<Scalars['String']>;
  readonly nextStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextStopStartTime?: Maybe<Scalars['Float']>;
  readonly nextStopStartTimezone?: Maybe<Scalars['String']>;
  readonly nextStopCity?: Maybe<Scalars['String']>;
  readonly nextStopState?: Maybe<Scalars['String']>;
  readonly nextCallBackDatetime?: Maybe<Scalars['DateTime']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopType?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Float']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Float']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly routeRateConSentDateTime?: Maybe<Scalars['DateTime']>;
  readonly waybillDatetime?: Maybe<Scalars['DateTime']>;
  readonly waybillStatus?: Maybe<Scalars['String']>;
  readonly orderWidth?: Maybe<Scalars['Float']>;
  readonly routeCapacityNeed?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly routeDivision?: Maybe<Scalars['String']>;
  readonly routeSize?: Maybe<Scalars['String']>;
  readonly routeCreateDate?: Maybe<Scalars['DateTime']>;
  readonly orderRequirements?: Maybe<Scalars['String']>;
  readonly loadTotalWeight?: Maybe<Scalars['Float']>;
  readonly routeTotalCost?: Maybe<Scalars['Float']>;
  readonly routeTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalCost?: Maybe<Scalars['Float']>;
  readonly previousRouteId?: Maybe<Scalars['String']>;
  readonly nextRouteId?: Maybe<Scalars['String']>;
  readonly reloadIntent?: Maybe<Scalars['String']>;
  readonly isHotRoute?: Maybe<Scalars['Boolean']>;
  readonly driverHosClockTime?: Maybe<Scalars['Float']>;
  readonly shiftHosClockTime?: Maybe<Scalars['Float']>;
  readonly cycleHosClockTime?: Maybe<Scalars['Float']>;
  readonly breakHosClockTime?: Maybe<Scalars['Float']>;
  readonly additionalDivision?: Maybe<Scalars['String']>;
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly preplanSent?: Maybe<Scalars['DateTime']>;
  readonly prePlanEventType?: Maybe<Scalars['String']>;
  readonly prePlanCreatedDatetime?: Maybe<Scalars['DateTime']>;
  readonly prePlanUpdatedDatetime?: Maybe<Scalars['DateTime']>;
  /** Hold Reasons for the Order */
  readonly activationStatusReasons?: Maybe<Scalars['String']>;
  readonly dutyStatusTerm?: Maybe<Scalars['String']>;
  readonly hosRuleTerm?: Maybe<Scalars['String']>;
};

export type MainPageRouteBoardCoveredConnection = {
  readonly __typename?: 'MainPageRouteBoardCoveredConnection';
  readonly edges: ReadonlyArray<MainPageRouteBoardCoveredEdge>;
  readonly pageInfo: PageInfo;
};

export type MainPageRouteBoardCoveredEdge = {
  readonly __typename?: 'MainPageRouteBoardCoveredEdge';
  readonly node: MainPageRouteBoardCovered;
  readonly cursor: Scalars['String'];
};

export type MainPageRouteBoardCoveredFilterInput = {
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeCapacityNeed?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRadius?: InputMaybe<RadiusFilter>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRadius?: InputMaybe<RadiusFilter>;
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type MainPageRouteBoardDispatched = {
  readonly __typename?: 'MainPageRouteBoardDispatched';
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  readonly currentStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopDriverDepartureTimezone?: Maybe<Scalars['String']>;
  readonly currentStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly currentStopSequence?: Maybe<Scalars['Float']>;
  readonly customerGroups?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly expectedEmptyTimezone?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly etaDatetime?: Maybe<Scalars['DateTime']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly originStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly originStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly orderHeight?: Maybe<Scalars['Float']>;
  readonly id: Scalars['ID'];
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly destStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly destStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly destStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly orderLength?: Maybe<Scalars['Float']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly lockId?: Maybe<Scalars['String']>;
  readonly mainRouteRep?: Maybe<Scalars['String']>;
  readonly expectedEmptyDatetime?: Maybe<Scalars['String']>;
  readonly nextStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextStopEndTime?: Maybe<Scalars['Float']>;
  readonly nextStopEndTimezone?: Maybe<Scalars['String']>;
  readonly nextStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextStopStartTime?: Maybe<Scalars['Float']>;
  readonly nextStopStartTimezone?: Maybe<Scalars['String']>;
  readonly nextStopCity?: Maybe<Scalars['String']>;
  readonly nextStopState?: Maybe<Scalars['String']>;
  readonly nextCallBackDatetime?: Maybe<Scalars['DateTime']>;
  readonly orderRequirements?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopType?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Float']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Float']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly waybillDatetime?: Maybe<Scalars['DateTime']>;
  readonly waybillStatus?: Maybe<Scalars['String']>;
  readonly orderWidth?: Maybe<Scalars['Float']>;
  readonly routeCapacityNeed?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly routeDivision?: Maybe<Scalars['String']>;
  readonly routeSize?: Maybe<Scalars['String']>;
  readonly routeCreateDate?: Maybe<Scalars['DateTime']>;
  readonly loadTotalWeight?: Maybe<Scalars['Float']>;
  readonly routeTotalCost?: Maybe<Scalars['Float']>;
  readonly routeTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalCost?: Maybe<Scalars['Float']>;
  readonly previousRouteId?: Maybe<Scalars['String']>;
  readonly nextRouteId?: Maybe<Scalars['String']>;
  readonly reloadIntent?: Maybe<Scalars['String']>;
  readonly isHotRoute?: Maybe<Scalars['Boolean']>;
  readonly driverHosClockTime?: Maybe<Scalars['Float']>;
  readonly shiftHosClockTime?: Maybe<Scalars['Float']>;
  readonly cycleHosClockTime?: Maybe<Scalars['Float']>;
  readonly breakHosClockTime?: Maybe<Scalars['Float']>;
  readonly additionalDivision?: Maybe<Scalars['String']>;
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly dutyStatusTerm?: Maybe<Scalars['String']>;
  readonly hosRuleTerm?: Maybe<Scalars['String']>;
  readonly activationStatusReasons?: Maybe<Scalars['String']>;
};

export type MainPageRouteBoardDispatchedConnection = {
  readonly __typename?: 'MainPageRouteBoardDispatchedConnection';
  readonly edges: ReadonlyArray<MainPageRouteBoardDispatchedEdge>;
  readonly pageInfo: PageInfo;
};

export type MainPageRouteBoardDispatchedEdge = {
  readonly __typename?: 'MainPageRouteBoardDispatchedEdge';
  readonly node: MainPageRouteBoardDispatched;
  readonly cursor: Scalars['String'];
};

export type MainPageRouteBoardDispatchedFilterInput = {
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRadius?: InputMaybe<RadiusFilter>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRadius?: InputMaybe<RadiusFilter>;
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type MainPageRouteBoardIncomplete = {
  readonly __typename?: 'MainPageRouteBoardIncomplete';
  readonly id: Scalars['ID'];
  readonly routeId?: Maybe<Scalars['String']>;
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly tenderedAtDatetime?: Maybe<Scalars['DateTime']>;
  readonly tenderedAtTimezone?: Maybe<Scalars['String']>;
  readonly expectedAtDatetime?: Maybe<Scalars['DateTime']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly originStopRequestedShipDate?: Maybe<Scalars['DateTime']>;
  readonly originStopRequestedShipTimezone?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly routeDistance?: Maybe<Scalars['Float']>;
  readonly routeDistanceUnit?: Maybe<Scalars['String']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly routeRequiredArrivalDate?: Maybe<Scalars['DateTime']>;
  readonly routeRequiredArrivalTimezone?: Maybe<Scalars['String']>;
  readonly orderMode?: Maybe<Scalars['String']>;
  readonly orderSize?: Maybe<Scalars['String']>;
  readonly loadScac?: Maybe<Scalars['String']>;
  readonly loadQuoteType?: Maybe<Scalars['String']>;
  readonly loadRelation?: Maybe<Scalars['String']>;
  readonly loadPriceTier?: Maybe<Scalars['String']>;
  readonly orderWeight?: Maybe<Scalars['Float']>;
  readonly orderWeightUnit?: Maybe<Scalars['String']>;
  readonly loadCargoInsurance?: Maybe<Scalars['Float']>;
  readonly loadCargoInsuranceUnit?: Maybe<Scalars['String']>;
  readonly loadRequirements?: Maybe<Scalars['String']>;
  readonly customerGroups?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly orderRequirements?: Maybe<Scalars['String']>;
  readonly orderHeight?: Maybe<Scalars['Float']>;
  readonly orderWidth?: Maybe<Scalars['Float']>;
  readonly orderLength?: Maybe<Scalars['Float']>;
  readonly orderHeightUnit?: Maybe<Scalars['String']>;
  readonly orderWidthUnit?: Maybe<Scalars['String']>;
  readonly orderLengthUnit?: Maybe<Scalars['String']>;
  readonly orderCommitFrequencyTerm?: Maybe<Scalars['String']>;
  readonly orderCommitVolume?: Maybe<Scalars['Float']>;
  readonly orderCountAccepted?: Maybe<Scalars['Float']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly routeTotalRate?: Maybe<Scalars['Float']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly orderRepId?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly routeDivision?: Maybe<Scalars['String']>;
  readonly routeSize?: Maybe<Scalars['String']>;
  readonly routeCreateDate?: Maybe<Scalars['DateTime']>;
  readonly loadTotalWeight?: Maybe<Scalars['Float']>;
  readonly routeTotalCost?: Maybe<Scalars['Float']>;
  readonly loadTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalCost?: Maybe<Scalars['Float']>;
  readonly orderDivision?: Maybe<Scalars['String']>;
  readonly isHotRoute?: Maybe<Scalars['Boolean']>;
  readonly additionalDivision?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
};

export type MainPageRouteBoardIncompleteConnection = {
  readonly __typename?: 'MainPageRouteBoardIncompleteConnection';
  readonly edges: ReadonlyArray<MainPageRouteBoardIncompleteEdge>;
  readonly pageInfo: PageInfo;
};

export type MainPageRouteBoardIncompleteEdge = {
  readonly __typename?: 'MainPageRouteBoardIncompleteEdge';
  readonly node: MainPageRouteBoardIncomplete;
  readonly cursor: Scalars['String'];
};

export type MainPageRouteBoardIncompleteFilterInput = {
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly orderRepId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRadius?: InputMaybe<RadiusFilter>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRadius?: InputMaybe<RadiusFilter>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type MainPageRouteBoardInTransit = {
  readonly __typename?: 'MainPageRouteBoardInTransit';
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  readonly currentStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopDriverDepartureTimezone?: Maybe<Scalars['String']>;
  readonly currentStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly currentStopSequence?: Maybe<Scalars['Float']>;
  readonly customerGroups?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly expectedEmptyTimezone?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly etaDatetime?: Maybe<Scalars['DateTime']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly originStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly originStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly originStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly orderHeight?: Maybe<Scalars['Float']>;
  readonly id: Scalars['ID'];
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly destStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly destStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly destStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly orderLength?: Maybe<Scalars['Float']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly lockId?: Maybe<Scalars['String']>;
  readonly mainRouteRep?: Maybe<Scalars['String']>;
  readonly expectedEmptyDatetime?: Maybe<Scalars['String']>;
  readonly nextStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextStopEndTime?: Maybe<Scalars['Float']>;
  readonly nextStopEndTimezone?: Maybe<Scalars['String']>;
  readonly nextStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextStopStartTime?: Maybe<Scalars['Float']>;
  readonly nextStopStartTimezone?: Maybe<Scalars['String']>;
  readonly nextStopCity?: Maybe<Scalars['String']>;
  readonly nextStopState?: Maybe<Scalars['String']>;
  readonly nextCallBackDatetime?: Maybe<Scalars['DateTime']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopType?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Float']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Float']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly waybillDatetime?: Maybe<Scalars['DateTime']>;
  readonly waybillStatus?: Maybe<Scalars['String']>;
  readonly orderWidth?: Maybe<Scalars['Float']>;
  readonly routeCapacityNeed?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly routeDivision?: Maybe<Scalars['String']>;
  readonly routeSize?: Maybe<Scalars['String']>;
  readonly routeCreateDate?: Maybe<Scalars['DateTime']>;
  readonly orderRequirements?: Maybe<Scalars['String']>;
  readonly loadTotalWeight?: Maybe<Scalars['Float']>;
  readonly routeTotalCost?: Maybe<Scalars['Float']>;
  readonly routeTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalCost?: Maybe<Scalars['Float']>;
  readonly previousRouteId?: Maybe<Scalars['String']>;
  readonly nextRouteId?: Maybe<Scalars['String']>;
  readonly reloadIntent?: Maybe<Scalars['String']>;
  readonly isHotRoute?: Maybe<Scalars['Boolean']>;
  readonly driverHosClockTime?: Maybe<Scalars['Float']>;
  readonly shiftHosClockTime?: Maybe<Scalars['Float']>;
  readonly cycleHosClockTime?: Maybe<Scalars['Float']>;
  readonly breakHosClockTime?: Maybe<Scalars['Float']>;
  readonly additionalDivision?: Maybe<Scalars['String']>;
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly dutyStatusTerm?: Maybe<Scalars['String']>;
  readonly hosRuleTerm?: Maybe<Scalars['String']>;
  readonly activationStatusReasons?: Maybe<Scalars['String']>;
};

export type MainPageRouteBoardInTransitConnection = {
  readonly __typename?: 'MainPageRouteBoardInTransitConnection';
  readonly edges: ReadonlyArray<MainPageRouteBoardInTransitEdge>;
  readonly pageInfo: PageInfo;
};

export type MainPageRouteBoardInTransitEdge = {
  readonly __typename?: 'MainPageRouteBoardInTransitEdge';
  readonly node: MainPageRouteBoardInTransit;
  readonly cursor: Scalars['String'];
};

export type MainPageRouteBoardInTransitFilterInput = {
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRadius?: InputMaybe<RadiusFilter>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRadius?: InputMaybe<RadiusFilter>;
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type MainPageRouteBoardLinkedRoutes = {
  readonly __typename?: 'MainPageRouteBoardLinkedRoutes';
  readonly id: Scalars['ID'];
  readonly previousRouteId?: Maybe<Scalars['String']>;
  readonly previousRouteNumber?: Maybe<Scalars['String']>;
  readonly previousRouteLifecycleStatus?: Maybe<Scalars['String']>;
  readonly previousRouteTotalDistance?: Maybe<Scalars['Float']>;
  readonly previousRouteTotalDistanceUnit?: Maybe<Scalars['String']>;
  readonly previousRouteType?: Maybe<Scalars['String']>;
  readonly previousRouteLoadId?: Maybe<Scalars['String']>;
  readonly previousRouteOriginStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly previousRouteOriginStopCity?: Maybe<Scalars['String']>;
  readonly previousRouteOriginStopState?: Maybe<Scalars['String']>;
  readonly previousRouteOriginStopLastStopEvent?: Maybe<Scalars['String']>;
  readonly previousRouteDestStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly previousRouteDestStopCity?: Maybe<Scalars['String']>;
  readonly previousRouteDestStopState?: Maybe<Scalars['String']>;
  readonly previousRouteDestStopLastStopEvent?: Maybe<Scalars['String']>;
  readonly previousRouteCurrentStopCity?: Maybe<Scalars['String']>;
  readonly previousRouteCurrentStopState?: Maybe<Scalars['String']>;
  readonly previousRouteCurrentStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly previousRouteCurrentStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly previousRouteDriverName?: Maybe<Scalars['String']>;
  readonly previousRouteTrailerNumber?: Maybe<Scalars['String']>;
  readonly nextRouteId?: Maybe<Scalars['String']>;
  readonly nextRouteNumber?: Maybe<Scalars['String']>;
  readonly nextRouteLifecycleStatus?: Maybe<Scalars['String']>;
  readonly nextRouteTotalDistance?: Maybe<Scalars['Float']>;
  readonly nextRouteTotalDistanceUnit?: Maybe<Scalars['String']>;
  readonly nextRouteType?: Maybe<Scalars['String']>;
  readonly nextRouteLoadId?: Maybe<Scalars['String']>;
  readonly nextRouteOriginStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextRouteOriginStopCity?: Maybe<Scalars['String']>;
  readonly nextRouteOriginStopState?: Maybe<Scalars['String']>;
  readonly nextRouteOriginStopLastStopEvent?: Maybe<Scalars['String']>;
  readonly nextRouteDestStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextRouteDestStopCity?: Maybe<Scalars['String']>;
  readonly nextRouteDestStopState?: Maybe<Scalars['String']>;
  readonly nextRouteDestStopLastStopEvent?: Maybe<Scalars['String']>;
  readonly nextRouteCurrentStopCity?: Maybe<Scalars['String']>;
  readonly nextRouteCurrentStopState?: Maybe<Scalars['String']>;
  readonly nextRouteCurrentStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly nextRouteCurrentStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly nextRouteDriverName?: Maybe<Scalars['String']>;
  readonly nextRouteTrailerNumber?: Maybe<Scalars['String']>;
};

export type MainPageRouteBoardLinkedRoutesFilterInput = {
  readonly routeId: Scalars['ID'];
  readonly originCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRadius?: InputMaybe<RadiusFilter>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRadius?: InputMaybe<RadiusFilter>;
};

export type MainPageRouteBoardOpen = {
  readonly __typename?: 'MainPageRouteBoardOpen';
  readonly currentStopDriverDepartureTimezone?: Maybe<Scalars['String']>;
  readonly currentStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  readonly currentStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopSequence?: Maybe<Scalars['Float']>;
  readonly customerGroups?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly expectedEmptyTimezone?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly etaDatetime?: Maybe<Scalars['DateTime']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly originStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly originStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly originStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly orderHeight?: Maybe<Scalars['Float']>;
  readonly id: Scalars['ID'];
  readonly orderId?: Maybe<Scalars['String']>;
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly destStopDriverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly destStopDriverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly destStopDriverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly orderLength?: Maybe<Scalars['Float']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly lockId?: Maybe<Scalars['String']>;
  readonly mainRouteRep?: Maybe<Scalars['String']>;
  readonly expectedEmptyDatetime?: Maybe<Scalars['String']>;
  readonly nextStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextStopEndTime?: Maybe<Scalars['Float']>;
  readonly nextStopEndTimezone?: Maybe<Scalars['String']>;
  readonly nextStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextStopStartTime?: Maybe<Scalars['Float']>;
  readonly nextStopStartTimezone?: Maybe<Scalars['String']>;
  readonly nextStopCity?: Maybe<Scalars['String']>;
  readonly nextStopState?: Maybe<Scalars['String']>;
  readonly nextCallBackDatetime?: Maybe<Scalars['DateTime']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopType?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Float']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Float']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly waybillDatetime?: Maybe<Scalars['DateTime']>;
  readonly waybillStatus?: Maybe<Scalars['String']>;
  readonly orderWidth?: Maybe<Scalars['Float']>;
  readonly assignedToRepNames?: Maybe<Scalars['String']>;
  readonly routeCapacityNeed?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly orderRequirements?: Maybe<Scalars['String']>;
  readonly routeDivision?: Maybe<Scalars['String']>;
  readonly routeSize?: Maybe<Scalars['String']>;
  readonly routeCreateDate?: Maybe<Scalars['DateTime']>;
  readonly loadTotalWeight?: Maybe<Scalars['Float']>;
  readonly routeTotalCost?: Maybe<Scalars['Float']>;
  readonly routeTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalCost?: Maybe<Scalars['Float']>;
  readonly isHotRoute?: Maybe<Scalars['Boolean']>;
  readonly additionalDivision?: Maybe<Scalars['String']>;
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  /** Order, Stop and Route References Values */
  readonly orderReferences?: Maybe<Scalars['String']>;
  /** @deprecated USE orderReferences */
  readonly routeReferences?: Maybe<Scalars['String']>;
  /** @deprecated USE orderReferences */
  readonly stopReferences?: Maybe<Scalars['String']>;
  readonly intermediaryStops?: Maybe<Scalars['String']>;
  /** Hold Reasons for the Order */
  readonly activationStatusReasons?: Maybe<Scalars['String']>;
  /**
   * Intermediary Stops
   * @deprecated This field is deprecated. Use intermediaryStops instead.
   */
  readonly intermediateStops?: Maybe<ReadonlyArray<IntermediateStop>>;
};

export type MainPageRouteBoardOpenConnection = {
  readonly __typename?: 'MainPageRouteBoardOpenConnection';
  readonly edges: ReadonlyArray<MainPageRouteBoardOpenEdge>;
  readonly pageInfo: PageInfo;
};

export type MainPageRouteBoardOpenEdge = {
  readonly __typename?: 'MainPageRouteBoardOpenEdge';
  readonly node: MainPageRouteBoardOpen;
  readonly cursor: Scalars['String'];
};

export type MainPageRouteBoardOpenFilterInput = {
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeCapacityNeed?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedToRepEmployeeId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRadius?: InputMaybe<RadiusFilter>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRadius?: InputMaybe<RadiusFilter>;
  readonly originStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly orderRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** This references filter will apply or condition for orderReferences, routeReferences, stopReferences */
  readonly orderReferences?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeReferences?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly stopReferences?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Intermediary Stops */
  readonly intermediateStops?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly intermediateStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly intermediateStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type MainPageRouteBoardPending = {
  readonly __typename?: 'MainPageRouteBoardPending';
  readonly id: Scalars['ID'];
  readonly routeId?: Maybe<Scalars['String']>;
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly tenderedAtDatetime?: Maybe<Scalars['DateTime']>;
  readonly tenderedAtTimezone?: Maybe<Scalars['String']>;
  readonly expectedAtDatetime?: Maybe<Scalars['DateTime']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly originStopRequestedShipDate?: Maybe<Scalars['DateTime']>;
  readonly originStopRequestedShipTimezone?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly routeDistance?: Maybe<Scalars['Float']>;
  readonly routeDistanceUnit?: Maybe<Scalars['String']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly routeRequiredArrivalDate?: Maybe<Scalars['DateTime']>;
  readonly routeRequiredArrivalTimezone?: Maybe<Scalars['String']>;
  readonly orderMode?: Maybe<Scalars['String']>;
  readonly orderSize?: Maybe<Scalars['String']>;
  readonly loadScac?: Maybe<Scalars['String']>;
  readonly loadQuoteType?: Maybe<Scalars['String']>;
  readonly loadRelation?: Maybe<Scalars['String']>;
  readonly loadPriceTier?: Maybe<Scalars['String']>;
  readonly orderWeight?: Maybe<Scalars['Float']>;
  readonly orderWeightUnit?: Maybe<Scalars['String']>;
  readonly loadCargoInsurance?: Maybe<Scalars['Float']>;
  readonly loadCargoInsuranceUnit?: Maybe<Scalars['String']>;
  readonly loadRequirements?: Maybe<Scalars['String']>;
  readonly customerGroups?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly orderHeight?: Maybe<Scalars['Float']>;
  readonly orderWidth?: Maybe<Scalars['Float']>;
  readonly orderLength?: Maybe<Scalars['Float']>;
  readonly orderHeightUnit?: Maybe<Scalars['String']>;
  readonly orderWidthUnit?: Maybe<Scalars['String']>;
  readonly orderLengthUnit?: Maybe<Scalars['String']>;
  readonly orderCommitFrequencyTerm?: Maybe<Scalars['String']>;
  readonly orderCommitVolume?: Maybe<Scalars['Float']>;
  readonly orderCountAccepted?: Maybe<Scalars['Float']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly routeTotalRate?: Maybe<Scalars['Float']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly orderRepId?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly routeDivision?: Maybe<Scalars['String']>;
  readonly routeSize?: Maybe<Scalars['String']>;
  readonly routeCreateDate?: Maybe<Scalars['DateTime']>;
  readonly loadTotalWeight?: Maybe<Scalars['Float']>;
  readonly routeTotalCost?: Maybe<Scalars['Float']>;
  readonly loadTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalCost?: Maybe<Scalars['Float']>;
  readonly capacityConstraintStartDate?: Maybe<Scalars['DateTime']>;
  readonly capacitySundayConstraint?: Maybe<Scalars['Float']>;
  readonly capacitySundayUtilized?: Maybe<Scalars['Float']>;
  readonly capacitySundayAvailable?: Maybe<Scalars['Float']>;
  readonly capacityMondayConstraint?: Maybe<Scalars['Float']>;
  readonly capacityMondayUtilized?: Maybe<Scalars['Float']>;
  readonly capacityMondayAvailable?: Maybe<Scalars['Float']>;
  readonly capacityTuesdayConstraint?: Maybe<Scalars['Float']>;
  readonly capacityTuesdayUtilized?: Maybe<Scalars['Float']>;
  readonly capacityTuesdayAvailable?: Maybe<Scalars['Float']>;
  readonly capacityWednesdayConstraint?: Maybe<Scalars['Float']>;
  readonly capacityWednesdayUtilized?: Maybe<Scalars['Float']>;
  readonly capacityWednesdayAvailable?: Maybe<Scalars['Float']>;
  readonly capacityThursdayConstraint?: Maybe<Scalars['Float']>;
  readonly capacityThursdayUtilized?: Maybe<Scalars['Float']>;
  readonly capacityThursdayAvailable?: Maybe<Scalars['Float']>;
  readonly capacityFridayConstraint?: Maybe<Scalars['Float']>;
  readonly capacityFridayUtilized?: Maybe<Scalars['Float']>;
  readonly capacityFridayAvailable?: Maybe<Scalars['Float']>;
  readonly capacitySaturdayConstraint?: Maybe<Scalars['Float']>;
  readonly capacitySaturdayUtilized?: Maybe<Scalars['Float']>;
  readonly capacitySaturdayAvailable?: Maybe<Scalars['Float']>;
  readonly capacityWeeklyConstraint?: Maybe<Scalars['Float']>;
  readonly capacityWeeklyUtilized?: Maybe<Scalars['Float']>;
  readonly capacityWeeklyAvailable?: Maybe<Scalars['Float']>;
  readonly orderDivision?: Maybe<Scalars['String']>;
  readonly shipId?: Maybe<Scalars['String']>;
  readonly additionalDivision?: Maybe<Scalars['String']>;
  readonly orderRequirements?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly intermediaryStops?: Maybe<Scalars['String']>;
  /**
   * Intermediary Stops
   * @deprecated This field is deprecated. Use intermediaryStops instead.
   */
  readonly intermediateStops?: Maybe<ReadonlyArray<IntermediateStop>>;
};

export type MainPageRouteBoardPendingConnection = {
  readonly __typename?: 'MainPageRouteBoardPendingConnection';
  readonly edges: ReadonlyArray<MainPageRouteBoardPendingEdge>;
  readonly pageInfo: PageInfo;
};

export type MainPageRouteBoardPendingEdge = {
  readonly __typename?: 'MainPageRouteBoardPendingEdge';
  readonly node: MainPageRouteBoardPending;
  readonly cursor: Scalars['String'];
};

export type MainPageRouteBoardPendingFilterInput = {
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly orderRepId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRadius?: InputMaybe<RadiusFilter>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRadius?: InputMaybe<RadiusFilter>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Intermediary Stops */
  readonly intermediateStops?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly intermediateStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly intermediateStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type MainPageRouteBoardPreTender = {
  readonly __typename?: 'MainPageRouteBoardPreTender';
  readonly id: Scalars['ID'];
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly customerGroups?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly tenderPlanRoutingGuideId?: Maybe<Scalars['String']>;
  readonly tenderPlanRoutingGuideCode?: Maybe<Scalars['String']>;
  readonly tenderPlanId?: Maybe<Scalars['String']>;
  readonly tenderPlanStatusId?: Maybe<Scalars['String']>;
  readonly tenderPlanType?: Maybe<Scalars['String']>;
  readonly tenderPlanNumberOfCarriers?: Maybe<Scalars['Float']>;
  readonly routeCapacityNeed?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly routeDivision?: Maybe<Scalars['String']>;
  readonly routeSize?: Maybe<Scalars['String']>;
  readonly routeCreateDate?: Maybe<Scalars['DateTime']>;
  readonly loadTotalWeight?: Maybe<Scalars['Float']>;
  readonly routeTotalCost?: Maybe<Scalars['Float']>;
  readonly routeTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalCost?: Maybe<Scalars['Float']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly tenderPlanStatus?: Maybe<Scalars['String']>;
  readonly additionalDivision?: Maybe<Scalars['String']>;
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
};

export type MainPageRouteBoardPreTenderConnection = {
  readonly __typename?: 'MainPageRouteBoardPreTenderConnection';
  readonly edges: ReadonlyArray<MainPageRouteBoardPreTenderEdge>;
  readonly pageInfo: PageInfo;
};

export type MainPageRouteBoardPreTenderEdge = {
  readonly __typename?: 'MainPageRouteBoardPreTenderEdge';
  readonly node: MainPageRouteBoardPreTender;
  readonly cursor: Scalars['String'];
};

export type MainPageRouteBoardPreTenderFilterInput = {
  readonly customerGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly routeDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRadius?: InputMaybe<RadiusFilter>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRadius?: InputMaybe<RadiusFilter>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type MainPageRouteBoardTender = {
  readonly __typename?: 'MainPageRouteBoardTender';
  readonly id: Scalars['ID'];
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly customerGroups?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly tenderPlanId?: Maybe<Scalars['String']>;
  readonly tenderPlanType?: Maybe<Scalars['String']>;
  readonly routeTenderSequence?: Maybe<Scalars['String']>;
  readonly tenderCurrentTender?: Maybe<Scalars['String']>;
  readonly tenderStartDatetime?: Maybe<Scalars['DateTime']>;
  readonly tenderExpirationTime?: Maybe<Scalars['Float']>;
  readonly tenderExpirationTimeUnit?: Maybe<Scalars['String']>;
  readonly routeCapacityNeed?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly routeDivision?: Maybe<Scalars['String']>;
  readonly routeSize?: Maybe<Scalars['String']>;
  readonly routeCreateDate?: Maybe<Scalars['DateTime']>;
  readonly loadTotalWeight?: Maybe<Scalars['Float']>;
  readonly routeTotalCost?: Maybe<Scalars['Float']>;
  readonly routeTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalCost?: Maybe<Scalars['Float']>;
  readonly additionalDivision?: Maybe<Scalars['String']>;
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
};

export type MainPageRouteBoardTenderConnection = {
  readonly __typename?: 'MainPageRouteBoardTenderConnection';
  readonly edges: ReadonlyArray<MainPageRouteBoardTenderEdge>;
  readonly pageInfo: PageInfo;
};

export type MainPageRouteBoardTenderEdge = {
  readonly __typename?: 'MainPageRouteBoardTenderEdge';
  readonly node: MainPageRouteBoardTender;
  readonly cursor: Scalars['String'];
};

export type MainPageRouteBoardTenderFilterInput = {
  readonly customerGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly routeDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRadius?: InputMaybe<RadiusFilter>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRadius?: InputMaybe<RadiusFilter>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type MainPageSideBarSummaryCounts = {
  readonly __typename?: 'MainPageSideBarSummaryCounts';
  readonly id: Scalars['ID'];
  readonly pendingCount?: Maybe<Scalars['Float']>;
  readonly schedulingCount?: Maybe<Scalars['Float']>;
  readonly incidentsCount?: Maybe<Scalars['Float']>;
  readonly trackingCount?: Maybe<Scalars['Float']>;
  readonly orderListCount?: Maybe<Scalars['Float']>;
  readonly routeListCount?: Maybe<Scalars['Float']>;
};

export type MainPageSideBarSummaryCountsFilterInput = {
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly facilityId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type MainPageTrackingBoard = {
  readonly __typename?: 'MainPageTrackingBoard';
  readonly id: Scalars['ID'];
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Float']>;
  readonly routeCount?: Maybe<Scalars['Float']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly mainRouteRep?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly nextCallBackDatetime?: Maybe<Scalars['DateTime']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverPhone?: Maybe<Scalars['String']>;
  readonly integrationName?: Maybe<Scalars['String']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly emptyDatetime?: Maybe<Scalars['DateTime']>;
  readonly lastTrackingNote?: Maybe<Scalars['String']>;
  readonly trackingAgCreateDate?: Maybe<Scalars['DateTime']>;
  readonly trackingCreatedByName?: Maybe<Scalars['String']>;
  readonly latestElectronicLocationUpdateDatetime?: Maybe<Scalars['DateTime']>;
  readonly latestTrackingRequestDatetime?: Maybe<Scalars['DateTime']>;
  readonly nextStopAutoEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly nextStopManualEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly nextStopType?: Maybe<Scalars['String']>;
  readonly driverElectronicTrackingType?: Maybe<Scalars['String']>;
  readonly nextStopEndTime?: Maybe<Scalars['Float']>;
  readonly nextStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextStopStartTimezone?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly routeBookedByName?: Maybe<Scalars['String']>;
  readonly orderRequirements?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly nextStopFacilityName?: Maybe<Scalars['String']>;
  readonly nextStopCity?: Maybe<Scalars['String']>;
  readonly nextStopState?: Maybe<Scalars['String']>;
  readonly carrierExecutingDivision?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly trackingLocationCity?: Maybe<Scalars['String']>;
  readonly trackingLocationState?: Maybe<Scalars['String']>;
  readonly isHotRoute?: Maybe<Scalars['Boolean']>;
  readonly routeDivision?: Maybe<Scalars['String']>;
  readonly repsFromBookings?: Maybe<Scalars['String']>;
  readonly orderReferences?: Maybe<Scalars['String']>;
  readonly bookingSourceId?: Maybe<Scalars['String']>;
  readonly waybillStatus?: Maybe<Scalars['String']>;
  readonly waybillDatetime?: Maybe<Scalars['DateTime']>;
  readonly masterSignStatus?: Maybe<Scalars['String']>;
  readonly powerId?: Maybe<Scalars['String']>;
  readonly powerCode?: Maybe<Scalars['String']>;
  readonly routeCapacityNeed?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly trackingUpdateUserName?: Maybe<Scalars['String']>;
  readonly preplanSent?: Maybe<Scalars['DateTime']>;
  readonly locationDescription?: Maybe<Scalars['String']>;
  readonly speed?: Maybe<Scalars['String']>;
  readonly fuelLevelPercent?: Maybe<Scalars['Float']>;
  readonly locationRelativePositionDirection?: Maybe<Scalars['String']>;
  readonly distanceToNext?: Maybe<Scalars['Float']>;
  readonly distanceToNextUnit?: Maybe<Scalars['String']>;
  readonly originStopRequirements?: Maybe<Scalars['String']>;
  readonly nextStopRequirements?: Maybe<Scalars['String']>;
  readonly destStopRequirements?: Maybe<Scalars['String']>;
  readonly currentStopRequirements?: Maybe<Scalars['String']>;
  readonly nextStopFacilityId?: Maybe<Scalars['ID']>;
  readonly currentStopFacilityName?: Maybe<Scalars['String']>;
  readonly currentStopFacilityId?: Maybe<Scalars['ID']>;
  readonly serviceLevel?: Maybe<Scalars['String']>;
  readonly inboundTrailerIdentifier1?: Maybe<Scalars['String']>;
  readonly inboundTrailerTypeId1?: Maybe<Scalars['String']>;
  readonly inboundTrailerRecordId1?: Maybe<Scalars['String']>;
  readonly inboundTrailerIdentifier2?: Maybe<Scalars['String']>;
  readonly inboundTrailerTypeId2?: Maybe<Scalars['String']>;
  readonly inboundTrailerRecordId2?: Maybe<Scalars['String']>;
  readonly inboundTrailerIdentifier3?: Maybe<Scalars['String']>;
  readonly inboundTrailerTypeId3?: Maybe<Scalars['String']>;
  readonly inboundTrailerRecordId3?: Maybe<Scalars['String']>;
  readonly outboundTrailerIdentifier1?: Maybe<Scalars['String']>;
  readonly outboundTrailerTypeId1?: Maybe<Scalars['String']>;
  readonly outboundTrailerRecordId1?: Maybe<Scalars['String']>;
  readonly outboundTrailerIdentifier2?: Maybe<Scalars['String']>;
  readonly outboundTrailerTypeId2?: Maybe<Scalars['String']>;
  readonly outboundTrailerRecordId2?: Maybe<Scalars['String']>;
  readonly outboundTrailerIdentifier3?: Maybe<Scalars['String']>;
  readonly outboundTrailerTypeId3?: Maybe<Scalars['String']>;
  readonly outboundTrailerRecordId3?: Maybe<Scalars['String']>;
  readonly loadedTrailerIdentifier1?: Maybe<Scalars['String']>;
  readonly loadedTrailerTypeId1?: Maybe<Scalars['String']>;
  readonly loadedTrailerRecordId1?: Maybe<Scalars['String']>;
  readonly loadedTrailerIdentifier2?: Maybe<Scalars['String']>;
  readonly loadedTrailerTypeId2?: Maybe<Scalars['String']>;
  readonly loadedTrailerRecordId2?: Maybe<Scalars['String']>;
  readonly loadedTrailerIdentifier3?: Maybe<Scalars['String']>;
  readonly loadedTrailerTypeId3?: Maybe<Scalars['String']>;
  readonly loadedTrailerRecordId3?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly routeBookedById?: Maybe<Scalars['String']>;
  readonly previousStopFacilityName?: Maybe<Scalars['String']>;
  readonly previousStopFacilityId?: Maybe<Scalars['ID']>;
  readonly previousStopState?: Maybe<Scalars['String']>;
  readonly previousStopCity?: Maybe<Scalars['String']>;
  readonly reqArriveDate?: Maybe<Scalars['DateTime']>;
  readonly providerETA?: Maybe<Scalars['DateTime']>;
  readonly calculatedDirectionHeading?: Maybe<Scalars['String']>;
  readonly orderReferencesType?: Maybe<Scalars['String']>;
  readonly orderReferencesValue?: Maybe<Scalars['String']>;
  readonly routeReferencesType?: Maybe<Scalars['String']>;
  readonly routeReferencesValue?: Maybe<Scalars['String']>;
  readonly carrierRouteReferencesType?: Maybe<Scalars['String']>;
  readonly carrierRouteReferencesValue?: Maybe<Scalars['String']>;
  readonly stopReferencesType?: Maybe<Scalars['String']>;
  readonly stopReferencesValue?: Maybe<Scalars['String']>;
  readonly routeStopRequirements?: Maybe<Scalars['String']>;
  readonly driver1Id?: Maybe<Scalars['ID']>;
  readonly resourceType?: Maybe<Scalars['String']>;
};

export type MainPageTrackingBoardConnection = {
  readonly __typename?: 'MainPageTrackingBoardConnection';
  readonly edges: ReadonlyArray<MainPageTrackingBoardEdge>;
  readonly pageInfo: PageInfo;
};

export type MainPageTrackingBoardEdge = {
  readonly __typename?: 'MainPageTrackingBoardEdge';
  readonly node: MainPageTrackingBoard;
  readonly cursor: Scalars['String'];
};

export type MainPageTrackingBoardFilterInput = {
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverElectronicTrackingType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trackingStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Previously infoFrom */
  readonly integrationName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly nextCallBackDatetime?: InputMaybe<Scalars['DateTime']>;
  /** previously puStartDate value1 */
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  /** Previously puStartDate value2 */
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  /** previously delStartDate value1 */
  readonly destStopStartDate?: InputMaybe<Scalars['DateTime']>;
  /** Previously delEndDate value2 */
  readonly destStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Previously groupId */
  readonly repGroup?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerGroup?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLifeCycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly bookingSourceId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderRepId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** order rep groupID */
  readonly orderRepGroup?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Created for Route RepId */
  readonly routeRepId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Created for Route GroupId */
  readonly routeRepGroup?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly capacityNeed?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly powerId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly powerCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly distanceToNext?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly distanceToNextUnit?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly stopRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly nextStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly currentStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly currentStopFacilityName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly serviceLevel?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly inboundTrailerIdentifier1?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly inboundTrailerIdentifier2?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly inboundTrailerIdentifier3?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundTrailerIdentifier1?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundTrailerIdentifier2?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundTrailerIdentifier3?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadedTrailerIdentifier1?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadedTrailerIdentifier2?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadedTrailerIdentifier3?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isFilterByOrganization?: InputMaybe<Scalars['Boolean']>;
  readonly originStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverPhone?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeStopCount?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly nextStopAutoEtaDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly nextStopManualEtaDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly lastTrackingNote?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Tracking Agcreated Date */
  readonly trackingAgCreateDate?: InputMaybe<Scalars['DateTime']>;
  readonly routeBookedById?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderReferences?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly masterSignStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly locationDescription?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly speed?: InputMaybe<Scalars['Float']>;
  readonly fuelLevelPercent?: InputMaybe<Scalars['Float']>;
  readonly locationRelativePositionDirection?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trackingLocationCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trackingLocationState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly nextStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly nextStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly nextStopFacilityName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly previousStopFacilityName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly previousStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly previousStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly previousStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly waybillStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly waybillDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly isHotRoute?: InputMaybe<Scalars['Boolean']>;
  readonly reqArriveDate?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly nextStopType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly calculatedDirectionHeading?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly providerETA?: InputMaybe<Scalars['DateTime']>;
  readonly orderReference?: InputMaybe<Scalars['String']>;
  readonly routeReference?: InputMaybe<Scalars['String']>;
  readonly carrierRouteReference?: InputMaybe<Scalars['String']>;
  readonly stopReference?: InputMaybe<Scalars['String']>;
  readonly routeStopRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type MaintenanceLocationInput = {
  readonly facilityCode?: InputMaybe<Scalars['String']>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly cityState?: InputMaybe<CityStateInput>;
};

export type MaintenanceLocationOutput = {
  readonly __typename?: 'MaintenanceLocationOutput';
  readonly facilityCode?: Maybe<Scalars['String']>;
  readonly cityState?: Maybe<CityStateOutput>;
};

export type ManualEstimate = {
  readonly __typename?: 'ManualEstimate';
  /** ID of the stop the stop event is tied to */
  readonly stopId: Scalars['ID'];
  /** Type of estimate (ETA or ETD) */
  readonly estimateType: ExecutionEstimateTypeEnum;
  /** ID of the route the stop/stop event is tied to */
  readonly routeId: Scalars['ID'];
  /** ID of the load the route/stop/stop event is tied to */
  readonly loadId: Scalars['ID'];
  /** Expected datetime of the stop event */
  readonly expectedDatetime?: Maybe<Scalars['DateTime']>;
};

export enum ManualTrackingMethodEnum {
  /** Phone */
  Phone = 'phone',
  /** Email */
  Email = 'email',
  /** Web */
  Web = 'web'
}

export type MapIb204ChargeCodeInput = {
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['Guid']>;
  readonly carrierId?: InputMaybe<Scalars['Guid']>;
  readonly chargeCodes: ReadonlyArray<Scalars['String']>;
};

export type MapIb204ChargeCodesResponse = {
  readonly __typename?: 'MapIB204ChargeCodesResponse';
  readonly code?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
};

export type MapIb210ChargeCodeInput = {
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['Guid']>;
  readonly carrierId?: InputMaybe<Scalars['Guid']>;
  readonly chargeCodes: ReadonlyArray<Scalars['String']>;
};

export type MapIb210ChargeCodesResponse = {
  readonly __typename?: 'MapIB210ChargeCodesResponse';
  readonly code?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
};

export type MappingOptionInputType = {
  readonly id: Scalars['String'];
  readonly fieldConstraintId: Scalars['String'];
  readonly options: ReadonlyArray<InputMaybe<OptionInputType>>;
};

export type MappingOptionOutputType = {
  readonly __typename?: 'MappingOptionOutputType';
  readonly id?: Maybe<Scalars['String']>;
  readonly fieldConstraintId?: Maybe<Scalars['String']>;
  readonly options?: Maybe<ReadonlyArray<Maybe<OptionOutputType>>>;
};

export type MarkupPagedResponse = {
  readonly __typename?: 'MarkupPagedResponse';
  readonly pageNumber?: Maybe<Scalars['Int']>;
  readonly pageSize?: Maybe<Scalars['Int']>;
  readonly totalRecords?: Maybe<Scalars['Long']>;
  readonly hasNextPage?: Maybe<Scalars['Boolean']>;
  readonly hasPreviousPage?: Maybe<Scalars['Boolean']>;
  readonly data?: Maybe<CustomerMarkupResponsePayloads>;
};

export type Mass = {
  readonly __typename?: 'Mass';
  readonly unit: MassUnitTypeEnum;
  readonly value: Scalars['Float'];
};

/** Caen Mass Lock input */
export type MassAcquireLockInput = {
  readonly flavor: LockFlavor;
  readonly objectIDs: ReadonlyArray<Scalars['ID']>;
  readonly userID: Scalars['ID'];
};

/** Caen Mass Lock */
export type MassAcquireLockPayload = {
  readonly __typename?: 'MassAcquireLockPayload';
  readonly lock?: Maybe<Lock>;
  readonly objectID: Scalars['ID'];
  readonly errors: ReadonlyArray<Scalars['String']>;
};

export type MassRangeInput = {
  readonly min?: InputMaybe<UnitOfMassInput>;
  readonly max?: InputMaybe<UnitOfMassInput>;
};

/** Mass refresh lock input */
export type MassRefreshLockInput = {
  readonly flavor: LockFlavor;
  readonly objectIDs: ReadonlyArray<Scalars['ID']>;
  readonly userID: Scalars['ID'];
};

/** Mass refresh lock payload */
export type MassRefreshLockPayload = {
  readonly __typename?: 'MassRefreshLockPayload';
  readonly lock?: Maybe<Lock>;
  readonly objectID: Scalars['ID'];
  readonly errors: ReadonlyArray<Scalars['String']>;
};

/** Mass release lock input */
export type MassReleaseLockInput = {
  readonly flavor: LockFlavor;
  readonly objectIDs: ReadonlyArray<Scalars['ID']>;
  readonly userID: Scalars['ID'];
};

/** Mass release lock payload */
export type MassReleaseLockPayload = {
  readonly __typename?: 'MassReleaseLockPayload';
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly objectID: Scalars['ID'];
  readonly errors: ReadonlyArray<Scalars['String']>;
};

export enum MassUnitTypeEnum {
  G = 'g',
  Kg = 'kg',
  Lbs = 'lbs',
  St = 'st',
  Tonnes = 'tonnes',
  Tons = 'tons'
}

export type MassUploadLoadInput = {
  readonly uploadedByUserId?: InputMaybe<Scalars['ID']>;
  readonly documentId: Scalars['String'];
  readonly rowNumber: Scalars['Float'];
};

export type MassV2 = {
  readonly __typename?: 'MassV2';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type MasterBidError = {
  readonly __typename?: 'MasterBidError';
  readonly message?: Maybe<Scalars['String']>;
};

export type MasterBidUploadEventPayload = {
  readonly __typename?: 'MasterBidUploadEventPayload';
  readonly status?: Maybe<Scalars['String']>;
  readonly workbookId?: Maybe<Scalars['String']>;
};

export type MasterfindLoadSearch = {
  readonly __typename?: 'MasterfindLoadSearch';
  readonly id: Scalars['ID'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  readonly orderReferences?: Maybe<Scalars['String']>;
};

export type MasterfindLoadSearchConnection = {
  readonly __typename?: 'MasterfindLoadSearchConnection';
  readonly edges: ReadonlyArray<MasterfindLoadSearchEdge>;
  readonly pageInfo: PageInfo;
};

export type MasterfindLoadSearchEdge = {
  readonly __typename?: 'MasterfindLoadSearchEdge';
  readonly node: MasterfindLoadSearch;
  readonly cursor: Scalars['String'];
};

export type MasterfindLoadSearchFilterInput = {
  readonly loadCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderReferences?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** Truck Match Result from ElasticSearch (v2) */
export type Match = {
  readonly __typename?: 'Match';
  readonly id: Scalars['ID'];
  readonly routeId: Scalars['String'];
  readonly loadId: Scalars['String'];
  readonly loadNumber: Scalars['String'];
  readonly capacityNeed?: Maybe<ReadonlyArray<CapacityNeed>>;
  readonly actedUpon?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly originDeadheadMileage?: Maybe<Scalars['Float']>;
  readonly destinationDeadheadMileage?: Maybe<Scalars['Float']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly routePickupCity?: Maybe<Scalars['String']>;
  readonly routePickupState?: Maybe<Scalars['String']>;
  readonly routePickupCountry?: Maybe<Scalars['String']>;
  readonly routePickupStartDay?: Maybe<Scalars['String']>;
  /** Date and timezone for route pickup start date */
  readonly routePickupStartDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `routePickupStartDateZone` */
  readonly routePickupStartDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `routePickupStartDateZone` and `routePickupEndDateZone` */
  readonly routePickupTimeZone?: Maybe<Scalars['String']>;
  readonly routePickupEndDay?: Maybe<Scalars['String']>;
  /** Date and timezone for route pickup end date */
  readonly routePickupEndDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `routePickupEndDateZone` */
  readonly routePickupEndDate?: Maybe<Scalars['DateTime']>;
  readonly routeFirstStopDateRange?: Maybe<AvailableDateRange>;
  readonly routeFirstStopLiveType?: Maybe<ScheduleDropTypeEnum>;
  readonly routeDeliveryCity?: Maybe<Scalars['String']>;
  readonly routeDeliveryState?: Maybe<Scalars['String']>;
  readonly routeDeliveryCountry?: Maybe<Scalars['String']>;
  readonly routeDeliveryStartDay?: Maybe<Scalars['String']>;
  /** Date and timezone for route delivery start date */
  readonly routeDeliveryStartDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `routeDeliveryStartDateZone` */
  readonly routeDeliveryStartDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `routeDeliveryStartDateZone` and `routeDeliveryEndDateZone` */
  readonly routeDeliveryTimeZone?: Maybe<Scalars['String']>;
  readonly routeDeliveryEndDay?: Maybe<Scalars['String']>;
  /** Date and timezone for route delivery end date */
  readonly routeDeliveryEndDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `routeDeliveryEndDateZone` */
  readonly routeDeliveryEndDate?: Maybe<Scalars['DateTime']>;
  readonly routeLastStopDateRange?: Maybe<AvailableDateRange>;
  readonly routeLastStopLiveType?: Maybe<ScheduleDropTypeEnum>;
  readonly routeLength?: Maybe<Scalars['Int']>;
  readonly routeTrailerLength?: Maybe<Length>;
  readonly routeCommodityMinTemperature?: Maybe<TemperatureV2>;
  readonly routeAssignedReps?: Maybe<ReadonlyArray<AvailableRouteRep>>;
  readonly routeCustomerOrderReps?: Maybe<ReadonlyArray<AvailableRouteRep>>;
  readonly stopCount?: Maybe<Scalars['Int']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly routeTrailerTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly segmentCode?: Maybe<Scalars['String']>;
  readonly routeDivisions?: Maybe<ReadonlyArray<AvailableRouteDivision>>;
  readonly requirements?: Maybe<ReadonlyArray<OrderRequirementInfo>>;
  readonly hotRoute?: Maybe<Scalars['Boolean']>;
  readonly tmsType?: Maybe<Scalars['String']>;
  readonly mainCustomerRepEmployeeId?: Maybe<Scalars['String']>;
  readonly expectedMaxWeight?: Maybe<Scalars['Float']>;
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly equipment?: Maybe<Scalars['String']>;
  readonly routeLoadRouteSequence?: Maybe<Fraction>;
  readonly routeAppointmentStatus?: Maybe<Scalars['String']>;
  readonly routeLifecycleStatus?: Maybe<Scalars['String']>;
  readonly routeOrganizationName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'routeCustomerReps' */
  readonly customerRepName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'routeCustomerReps' */
  readonly customerRepId?: Maybe<Scalars['String']>;
  /** @deprecated Use 'routeDivisions' */
  readonly division?: Maybe<Scalars['String']>;
  readonly truckPostingId: Scalars['String'];
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly carrierTypeId?: Maybe<Scalars['String']>;
  readonly readyByDate: Scalars['DateTime'];
  readonly truckReadyByDay?: Maybe<Scalars['String']>;
  readonly truckLoadByDate: Scalars['DateTime'];
  readonly truckLoadByDay?: Maybe<Scalars['String']>;
  readonly truckReadyByLoadByDateRange?: Maybe<AvailableDateRange>;
  readonly truckOriginCity?: Maybe<Scalars['String']>;
  readonly truckOriginState?: Maybe<Scalars['String']>;
  readonly truckOriginCountry?: Maybe<Scalars['String']>;
  readonly truckDestinationCity?: Maybe<Scalars['String']>;
  readonly truckDestinationState?: Maybe<Scalars['String']>;
  readonly truckDestinationStates?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly truckDestinationCountryList?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly truckCreatedByEmployeeId?: Maybe<Scalars['String']>;
  readonly truckEnteredByUserName?: Maybe<Scalars['String']>;
  readonly truckUpdatedByEmployeeId?: Maybe<Scalars['String']>;
  readonly truckSourceType?: Maybe<Scalars['String']>;
  readonly mainCarrierRepEmployeeId?: Maybe<Scalars['String']>;
  readonly route?: Maybe<AvailableRoute>;
  readonly truckPosting?: Maybe<AvailableTruck>;
  readonly bestMatch?: Maybe<Scalars['Boolean']>;
  readonly matchFlags?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly driverOriginFacilityId?: Maybe<Scalars['String']>;
  readonly driverOriginFacilityName?: Maybe<Scalars['String']>;
  readonly driverDestinationFacilityId?: Maybe<Scalars['String']>;
  readonly driverDestinationFacilityName?: Maybe<Scalars['String']>;
  readonly driverDivision?: Maybe<Scalars['String']>;
  readonly driverProject?: Maybe<Scalars['String']>;
  readonly driverProgram?: Maybe<Scalars['String']>;
  readonly tractorId?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly ownerName?: Maybe<Scalars['String']>;
  readonly truckOutboundSuperRegion?: Maybe<Scalars['String']>;
  readonly truckOutboundRegion?: Maybe<Scalars['String']>;
  readonly truckOutboundSubRegion?: Maybe<Scalars['String']>;
  /** remaining cycle time on HOS clock, in ms */
  readonly cycleTimeMs?: Maybe<Scalars['Float']>;
  /** remaining drive time on HOS clock, in ms */
  readonly driveTimeMs?: Maybe<Scalars['Float']>;
  /** remaining shift time on HOS clock, in ms */
  readonly shiftTimeMs?: Maybe<Scalars['Float']>;
  readonly capacityUtilizationStatusId: Scalars['String'];
  readonly assignedRouteId?: Maybe<Scalars['String']>;
  readonly assignedRouteNumber?: Maybe<Scalars['String']>;
  /** Mathematically defined “quality score” for each match, relative to a sample of other existing matches in a given client environment */
  readonly matchScore?: Maybe<Scalars['Float']>;
  /** @deprecated Use `truckPosting.assetDriver.id` */
  readonly driverId?: Maybe<Scalars['String']>;
  /** @deprecated Use `truckPosting.assetDriver.code` */
  readonly driverCode?: Maybe<Scalars['String']>;
  /** @deprecated Use `truckPosting.assetDriver2.id` */
  readonly driver2Id?: Maybe<Scalars['String']>;
  /** @deprecated Use `truckPosting.assetDriver2.code` */
  readonly driver2Code?: Maybe<Scalars['String']>;
  /** @deprecated Use `truckPosting.driverName` */
  readonly driverName?: Maybe<Scalars['String']>;
  /** @deprecated Use `truckPosting.driverPhoneNumber` */
  readonly driverPhoneNumber?: Maybe<Scalars['String']>;
  /** @deprecated Use `truckPosting.driver2Name` */
  readonly driver2Name?: Maybe<Scalars['String']>;
  /** @deprecated Use `truckPosting.driver2PhoneNumber` */
  readonly driver2PhoneNumber?: Maybe<Scalars['String']>;
  /** @deprecated Use `truckCreatedByEmployeeId` */
  readonly truckEnteredByRepEmployeeId?: Maybe<Scalars['String']>;
  /** @deprecated Driver Business Unit has been “killed off” */
  readonly driverBusinessUnit?: Maybe<Scalars['String']>;
  /** @deprecated Use `truckCreatedByEmployeeId` */
  readonly employeeId?: Maybe<Scalars['String']>;
};

export type MatchConnection = {
  readonly __typename?: 'MatchConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<MatchEdge>;
  readonly totalCount: Scalars['Float'];
};

export type MatchEdge = {
  readonly __typename?: 'MatchEdge';
  readonly node: Match;
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String'];
};

/** Indicates which matches date data to evaluate against filter e.g. `RoutePickupDateRange` */
export enum MatchesDateRangeFilterTarget {
  RoutePickupDateRange = 'RoutePickupDateRange',
  TruckPostingReadyDateRange = 'TruckPostingReadyDateRange'
}

/** Truck Match Filter */
export type MatchFilter = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly matchId?: InputMaybe<Scalars['String']>;
  readonly routeId?: InputMaybe<Scalars['String']>;
  readonly routeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadId?: InputMaybe<Scalars['String']>;
  readonly capacityNeed?: InputMaybe<ReadonlyArray<CapacityNeed>>;
  readonly excludeActedUpon?: InputMaybe<Scalars['Boolean']>;
  readonly actedUpon?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeFirstStopDateRange?: InputMaybe<DateRangeInput>;
  readonly routeLastStopDateRange?: InputMaybe<DateRangeInput>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierName?: InputMaybe<Scalars['String']>;
  readonly routeActivationStatus?: InputMaybe<Scalars['String']>;
  readonly routeActivationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly originDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  readonly destinationDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  readonly includeDeleted?: InputMaybe<Scalars['Boolean']>;
  readonly routeNumber?: InputMaybe<Scalars['String']>;
  readonly routePickupCity?: InputMaybe<Scalars['String']>;
  readonly routePickupState?: InputMaybe<Scalars['String']>;
  readonly routePickupStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routePickupCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routePickupDate?: InputMaybe<Scalars['DateTime']>;
  readonly routePickupTimeZone?: InputMaybe<Scalars['String']>;
  readonly routeDeliveryCity?: InputMaybe<Scalars['String']>;
  readonly routeDeliveryState?: InputMaybe<Scalars['String']>;
  readonly routeDeliveryStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDeliveryCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeFirstStopRadius?: InputMaybe<WithinRadiusInput>;
  readonly routeLastStopRadius?: InputMaybe<WithinRadiusInput>;
  readonly routeDeliveryDate?: InputMaybe<Scalars['DateTime']>;
  readonly routeDeliveryTimeZone?: InputMaybe<Scalars['String']>;
  readonly routeCommodityMinTemperature?: InputMaybe<TemperatureV2Input>;
  readonly routeLength?: InputMaybe<Scalars['Int']>;
  readonly stopCount?: InputMaybe<Scalars['Int']>;
  readonly maxCost?: InputMaybe<Scalars['Float']>;
  readonly routeFirstStopLiveType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLastStopLiveType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerName?: InputMaybe<Scalars['String']>;
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerRepName?: InputMaybe<Scalars['String']>;
  readonly customerRepIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equipment?: InputMaybe<Scalars['String']>;
  readonly routeTrailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns matches where the route primary or additional divisions includes the input terms */
  readonly routeDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns matches where the route primary division is null or includes the input terms */
  readonly routeDivisionsIncludeNull?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns matches where the route additional divisions includes the input terms */
  readonly additionalRouteDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns matches where the employee's primary divsion matches a route's primary or additional divisions */
  readonly primaryEmployeeDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns matches where the employee's additional divsions match a route's primary division */
  readonly additionalEmployeeDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly routeRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
  readonly routeTrailerLength?: InputMaybe<UnitOfLengthInput>;
  readonly routeAssignedRepIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeAppointmentStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLifecycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly capacityUtilizationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeOrganizationHierarchyCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly requirements?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly dateRangeFilterTarget?: InputMaybe<MatchesDateRangeFilterTarget>;
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  readonly truckPostingId?: InputMaybe<Scalars['String']>;
  readonly truckPostingIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly gateReservationId?: InputMaybe<Scalars['String']>;
  readonly containerId?: InputMaybe<Scalars['String']>;
  readonly timeZone?: InputMaybe<Scalars['String']>;
  readonly truckOriginCity?: InputMaybe<Scalars['String']>;
  readonly truckOriginState?: InputMaybe<Scalars['String']>;
  readonly truckOriginStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly truckOriginCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly truckDestinationCity?: InputMaybe<Scalars['String']>;
  readonly truckDestinationStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly truckOriginRadius?: InputMaybe<WithinRadiusInput>;
  readonly truckDestinationRadius?: InputMaybe<WithinRadiusInput>;
  readonly truckDestinationCountryList?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly truckSourceTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly truckEnteredByRepEmployeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly myMatchesBaseFilter?: InputMaybe<MyMatchesBaseFilter>;
  readonly bestMatch?: InputMaybe<Scalars['Boolean']>;
  readonly matchFlags?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverCode?: InputMaybe<Scalars['String']>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly driverOriginFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverDestinationFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverDivision?: InputMaybe<Scalars['String']>;
  readonly driverProject?: InputMaybe<Scalars['String']>;
  readonly driverProgram?: InputMaybe<Scalars['String']>;
  readonly tractorNumber?: InputMaybe<Scalars['String']>;
  readonly carrierTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly ownerName?: InputMaybe<Scalars['String']>;
  readonly truckOutboundRegionCompositeKeys?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly truckCreatedByUserId?: InputMaybe<Scalars['String']>;
  readonly truckUpdatedByUserId?: InputMaybe<Scalars['String']>;
  readonly capacityUtilizationStatusId?: InputMaybe<Scalars['String']>;
  readonly assignedRouteNumber?: InputMaybe<Scalars['String']>;
  readonly minimumMatchScore?: InputMaybe<Scalars['Float']>;
  readonly driverBusinessUnit?: InputMaybe<Scalars['String']>;
  readonly routePickupStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly routePickupEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly routeDeliveryStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly routeDeliveryEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly truckDestinationState?: InputMaybe<Scalars['String']>;
  readonly truckDestinationCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverOriginFacilityId?: InputMaybe<Scalars['String']>;
  readonly driverOriginFacilityName?: InputMaybe<Scalars['String']>;
  readonly driverDestinationFacilityId?: InputMaybe<Scalars['String']>;
  readonly driverDestinationFacilityName?: InputMaybe<Scalars['String']>;
  readonly carrierTypeId?: InputMaybe<Scalars['String']>;
  readonly driverDomicile?: InputMaybe<Scalars['String']>;
};

export type MatchingCostQuote = {
  readonly __typename?: 'MatchingCostQuote';
  readonly id?: Maybe<Scalars['ID']>;
  readonly effectiveDate?: Maybe<Scalars['Date']>;
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly code?: Maybe<Scalars['Int']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly laneId?: Maybe<Scalars['ID']>;
  readonly lineItems?: Maybe<ReadonlyArray<MatchingCostQuoteLineItem>>;
  /** @deprecated  It is no longer used. Use CostQuoteRouteVendorReferences instead */
  readonly routeVendorReferences?: Maybe<ReadonlyArray<RouteVendorReferences>>;
  readonly costQuoteRouteVendorReferences?: Maybe<ReadonlyArray<CostQuoteRouteVendorReferences>>;
};

export type MatchingCostQuoteInput = {
  readonly laneId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly customerID?: InputMaybe<Scalars['ID']>;
};

export type MatchingCostQuoteInputV2 = {
  readonly laneIds: ReadonlyArray<Scalars['ID']>;
  readonly carrierId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly customerID?: InputMaybe<Scalars['ID']>;
};

export type MatchingCostQuoteLineItem = {
  readonly __typename?: 'MatchingCostQuoteLineItem';
  readonly id?: Maybe<Scalars['ID']>;
  readonly chargeTypeId?: Maybe<Scalars['ID']>;
  readonly unit?: Maybe<Scalars['Decimal']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly costPer?: Maybe<Scalars['Decimal']>;
};

export type MatchingCostQuotePayload = {
  readonly __typename?: 'MatchingCostQuotePayload';
  readonly data?: Maybe<MatchingCostQuote>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<CostQuoteError>>>;
};

export type MatchingRateQuoteInput = {
  readonly customerId: Scalars['ID'];
  readonly laneId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
};

export type MatchingRateQuoteInputV2 = {
  readonly customerId: Scalars['ID'];
  readonly laneIds: ReadonlyArray<Scalars['ID']>;
  readonly orderId: Scalars['ID'];
};

export type MatchingRateQuoteLineItem = {
  readonly __typename?: 'MatchingRateQuoteLineItem';
  readonly chargeTypeId: Scalars['ID'];
  readonly currency: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly ratePerUnit: Scalars['Decimal'];
  readonly unit: Scalars['Decimal'];
};

export type MatchingRateQuotePayload = {
  readonly __typename?: 'MatchingRateQuotePayload';
  readonly data: MatchingRateQuoteResponse;
};

export type MatchingRateQuotePayloadV2 = {
  readonly __typename?: 'MatchingRateQuotePayloadV2';
  readonly data?: Maybe<MatchingRateQuoteResponse>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<RateQoteError>>>;
};

export type MatchingRateQuoteResponse = {
  readonly __typename?: 'MatchingRateQuoteResponse';
  readonly code: Scalars['Int'];
  readonly customerId: Scalars['ID'];
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly laneId: Scalars['Uuid'];
  readonly lineItems?: Maybe<ReadonlyArray<MatchingRateQuoteLineItem>>;
  readonly quoteCustomDistance?: Maybe<Scalars['Decimal']>;
  readonly quoteCustomDistanceUnit?: Maybe<RateQuoteKeyValue>;
  readonly quoteFuelConfiguration?: Maybe<RateQuoteKeyValue>;
  readonly requirements?: Maybe<ReadonlyArray<Maybe<OrderRequirementResponse>>>;
};

export type MatchingRoutingGuideInput = {
  readonly laneId: Scalars['ID'];
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly startDate: Scalars['Date'];
  readonly endDate?: InputMaybe<Scalars['Date']>;
};

export type MatchingRoutingGuidesInput = {
  readonly laneIds: ReadonlyArray<InputMaybe<Scalars['ID']>>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly startDate: Scalars['Date'];
  readonly endDate?: InputMaybe<Scalars['Date']>;
};

export type MatchingSpotQuoteInput = {
  readonly orderId: Scalars['ID'];
  readonly laneId: Scalars['ID'];
  /** Allows event correlation across services */
  readonly correlationId?: InputMaybe<Scalars['String']>;
};

export type MatchingSpotQuotePayload = {
  readonly __typename?: 'MatchingSpotQuotePayload';
  readonly data?: Maybe<SpotQuote>;
  readonly errors?: Maybe<ReadonlyArray<SpotQuoteError>>;
};

export type MatchingSpotQuoteV2Input = {
  readonly orderId: Scalars['ID'];
  readonly laneIds: ReadonlyArray<Scalars['ID']>;
  /** Allows event correlation across services */
  readonly correlationId?: InputMaybe<Scalars['String']>;
};

export type MatchingSpotQuoteV2Payload = {
  readonly __typename?: 'MatchingSpotQuoteV2Payload';
  readonly data?: Maybe<SpotQuote>;
  readonly errors?: Maybe<ReadonlyArray<SpotQuoteError>>;
};

export type MatchQuery = {
  readonly __typename?: 'MatchQuery';
  readonly createdBy: User;
  readonly id: Scalars['ID'];
  readonly results: ReadonlyArray<MatchResult>;
  readonly resultsConnection: MatchResultConnection;
};


export type MatchQueryResultsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<MatchResultConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type MatchQueryCombined = {
  readonly __typename?: 'MatchQueryCombined';
  readonly createdBy: User;
  readonly id: Scalars['ID'];
  readonly results: ReadonlyArray<MatchResultCombined>;
  readonly resultsConnection: MatchResultCombinedConnection;
};


export type MatchQueryCombinedResultsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<MatchResultConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type MatchQueryInput = {
  readonly destinationDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly endDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly matchable: MatchQueryMatchableInput;
  readonly originDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly startDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
};

export enum MatchQueryMatchableEnum {
  Carrier = 'Carrier',
  Employee = 'Employee',
  TruckPosting = 'TruckPosting'
}

export type MatchQueryMatchableInput = {
  readonly id: Scalars['ID'];
  readonly type: MatchQueryMatchableEnum;
};

export type MatchResult = {
  readonly __typename?: 'MatchResult';
  readonly destinationDeadheadMileage?: Maybe<UnitOfDistance>;
  readonly destinationDeadheadStraightaway?: Maybe<UnitOfDistance>;
  readonly id: Scalars['ID'];
  readonly originDeadheadMileage?: Maybe<UnitOfDistance>;
  readonly originDeadheadStraightaway?: Maybe<UnitOfDistance>;
  readonly tempLoad: TempLoad;
  /** @deprecated Service has reached EOL. Use TruckEntry subgraph. Will return null only soon */
  readonly truckPosting: TruckPosting;
};

export type MatchResultCombined = {
  readonly __typename?: 'MatchResultCombined';
  readonly destinationDeadheadMileage?: Maybe<UnitOfDistance>;
  readonly destinationDeadheadStraightaway?: Maybe<UnitOfDistance>;
  readonly id: Scalars['ID'];
  readonly originDeadheadMileage?: Maybe<UnitOfDistance>;
  readonly originDeadheadStraightaway?: Maybe<UnitOfDistance>;
  readonly route?: Maybe<LoadRoute>;
  readonly tempLoad?: Maybe<TempLoad>;
  /** @deprecated Service has reached EOL. Use TruckEntry subgraph. Will return null only soon */
  readonly truckPosting: TruckPosting;
};

/** The connection type for MatchResultCombined. */
export type MatchResultCombinedConnection = {
  readonly __typename?: 'MatchResultCombinedConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<MatchResultCombinedEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MatchResultCombinedEdge = {
  readonly __typename?: 'MatchResultCombinedEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: MatchResultCombined;
};

/** The connection type for MatchResult. */
export type MatchResultConnection = {
  readonly __typename?: 'MatchResultConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<MatchResultEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type MatchResultConnectionFilter = {
  /** Search/Filter carrier name */
  readonly carrierName?: InputMaybe<Scalars['String']>;
  /** Search/filter customer name */
  readonly customer?: InputMaybe<Scalars['String']>;
  /** Search/filter customer name */
  readonly customerRepName?: InputMaybe<Scalars['String']>;
  /** Search/filter load destination location (matches city & state) */
  readonly loadDestinationText?: InputMaybe<Scalars['String']>;
  /** Search/filter load origin location (matches city & state) */
  readonly loadOriginText?: InputMaybe<Scalars['String']>;
  /** Search/filter load numbers */
  readonly revenovaLoadNumber?: InputMaybe<Scalars['String']>;
  readonly sort?: InputMaybe<Scalars['String']>;
  readonly timezone?: InputMaybe<Scalars['String']>;
  /** Search/filter truck destination location (matches city & state) */
  readonly truckDestinationText?: InputMaybe<Scalars['String']>;
  /** Search/filter truck origin location (matches city & state) */
  readonly truckOriginText?: InputMaybe<Scalars['String']>;
  readonly truckType?: InputMaybe<Scalars['String']>;
  /** Delivery start range beginning */
  readonly utcDeliveryStartDatetimeEnd?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Delivery start range beginning */
  readonly utcDeliveryStartDatetimeStart?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Pickup start range beginning */
  readonly utcPickupStartDatetimeEnd?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Pickup start range beginning */
  readonly utcPickupStartDatetimeStart?: InputMaybe<Scalars['ISO8601DateTime']>;
};

/** An edge in a connection. */
export type MatchResultEdge = {
  readonly __typename?: 'MatchResultEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: MatchResult;
};

export type Measurement = {
  readonly __typename?: 'Measurement';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type Membership = Base & {
  readonly __typename?: 'Membership';
  readonly editAccess: Scalars['Boolean'];
  readonly organizationID: Scalars['ID'];
  readonly organization?: Maybe<Organization>;
  readonly userID: Scalars['ID'];
  readonly explicit?: Maybe<Scalars['Boolean']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserID: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserID: Scalars['String'];
  readonly environment: Scalars['String'];
};

export type MergeBlumeCostDetails = {
  readonly costDetails: ReadonlyArray<CostDetailAddInput>;
  readonly blumeFileId?: InputMaybe<Scalars['ID']>;
  readonly blumeImportKey?: InputMaybe<Scalars['String']>;
};

export type MergeBlumeCostDetailsPayload = {
  readonly __typename?: 'MergeBlumeCostDetailsPayload';
  readonly adjustmentRequired?: Maybe<Scalars['String']>;
  readonly createdCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  readonly deletedCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
};

/** The input type used for merging cost details from abacus */
export type MergeCostDetailsInput = {
  readonly loadId: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly costDetails: ReadonlyArray<CostDetailAddInput>;
  readonly userIdsToDeleteRecordsFor?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly chargeTypesToOverrideDefaultBehavior?: InputMaybe<ReadonlyArray<InputMaybe<OverrideDefaultCostDetailRerateBehaviorByChargeTypeInput>>>;
};

export type MergedCostDetailsModel = {
  readonly __typename?: 'MergedCostDetailsModel';
  readonly adjustmentRequired?: Maybe<Scalars['String']>;
  readonly createdCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  readonly updatedCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  readonly deletedCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  readonly notMergedDueToProtectedCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
};

export type MergedRateDetailsModel = {
  readonly __typename?: 'MergedRateDetailsModel';
  readonly adjustmentRequired?: Maybe<Scalars['String']>;
  readonly createdRateDetails?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
  readonly updatedRateDetails?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
  readonly deletedRateDetails?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
  readonly notMergedDueToProtectedRateDetails?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
};

/** The input type used for merging LTL rate and cost details from abacus */
export type MergeLessThanLoadDetailsInput = {
  readonly mergeCostDetailsInputModel: MergeCostDetailsInput;
  readonly mergeRateDetailsInputModel: MergeRateDetailsInput;
};

export type MergeLessThanLoadDetailsPayload = {
  readonly __typename?: 'MergeLessThanLoadDetailsPayload';
  readonly mergedRateDetailsModel?: Maybe<MergedRateDetailsModel>;
  readonly mergedCostDetailsModel?: Maybe<MergedCostDetailsModel>;
};

/** The input type used for merging rate details from abacus */
export type MergeRateDetailsInput = {
  readonly loadId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly updateCreditWhenCheckFails?: InputMaybe<Scalars['Boolean']>;
  readonly rateDetails: ReadonlyArray<RateDetailAddInput>;
  readonly userIdsToDeleteRecordsFor?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly chargeTypesToOverrideDefaultBehavior?: InputMaybe<ReadonlyArray<InputMaybe<OverrideDefaultRerateBehaviorByChargeTypeInput>>>;
};

export type Message = {
  readonly __typename?: 'Message';
  readonly code?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly text?: Maybe<Scalars['String']>;
};

export type MessagingV2 = {
  readonly __typename?: 'MessagingV2';
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly serverId?: Maybe<Scalars['ID']>;
  readonly type: EmployeeMessagingTypeEnum;
  readonly userId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdAt: Scalars['ISO8601Date'];
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedAt: Scalars['ISO8601Date'];
};

export type MessagingV2Input = {
  readonly serverId: Scalars['ID'];
  readonly type: EmployeeMessagingTypeEnum;
  readonly userId?: InputMaybe<Scalars['ID']>;
};

export type Mileage = {
  readonly __typename?: 'Mileage';
  readonly distance: UnitOfDistance;
  readonly routeId: Scalars['String'];
};

export enum MileageBaseEnum {
  Unknown = 'UNKNOWN',
  All = 'ALL',
  Loaded = 'LOADED',
  Empty = 'EMPTY'
}

export type MileageCalculation = {
  readonly __typename?: 'MileageCalculation';
  readonly routeId: Scalars['ID'];
  readonly distance: Scalars['Float'];
  readonly unit: Scalars['String'];
  readonly config?: Maybe<EntityConfigurationApplied>;
};

export type MileageCalculationLength = {
  readonly __typename?: 'MileageCalculationLength';
  readonly routeId: Scalars['ID'];
  readonly distance: Length;
  readonly config?: Maybe<EntityConfigurationApplied>;
};

export type MileageFilter = {
  readonly routeId?: InputMaybe<Scalars['String']>;
  readonly sourceIds: ReadonlyArray<Scalars['Int']>;
};

export type MileageFlushResponse = {
  readonly __typename?: 'MileageFlushResponse';
  readonly response?: Maybe<Scalars['String']>;
};

export type MileageForPointsFilter = {
  readonly points: ReadonlyArray<PointInput>;
  readonly routeId?: InputMaybe<Scalars['String']>;
};

export type MileageOutput = {
  readonly __typename?: 'MileageOutput';
  readonly outputField?: Maybe<Scalars['String']>;
};

/** Mileage provider options used for distance calculations */
export enum MileageProvider {
  /** PCMiler (Trimble) */
  Pcmiler = 'pcmiler',
  /** MileMaker (Rand McNally) */
  Milemaker = 'milemaker',
  /** Azure */
  Azure = 'azure'
}

export type MileageProviderOptions = PcMilerOptions | MileMakerOptions | AzureOptions;

export type MileageRequest = {
  readonly stops: ReadonlyArray<LatLongPair>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
};

export type MileageSettings = {
  readonly __typename?: 'MileageSettings';
  readonly provider: MileageProvider;
  readonly distanceSearchPreference: ReadonlyArray<DistanceSearchPreferenceOption>;
  readonly options?: Maybe<MileageProviderOptions>;
};

export type MileageSettingsInput = {
  readonly provider: MileageProvider;
  readonly distanceSearchPreference?: InputMaybe<DistanceSearchPreferenceOption>;
  readonly pcMilerOptions?: InputMaybe<PcMilerOptionsInput>;
  readonly mileMakerOptions?: InputMaybe<MileMakerOptionsInput>;
  readonly azureOptions?: InputMaybe<AzureOptionsInput>;
};

export enum MileageSourceEnum {
  Unknown = 'UNKNOWN',
  Tenant = 'TENANT',
  Carrier = 'CARRIER',
  Customer = 'CUSTOMER',
  Odometer = 'ODOMETER'
}

/** Custom options to use when calculating mileage via MileMaker (Rand McNally) */
export type MileMakerOptions = {
  readonly __typename?: 'MileMakerOptions';
  /** MileMaker API V2 - Guide 20 - Avoid preferences array */
  readonly avoid?: Maybe<ReadonlyArray<Maybe<MileMakerV2AvoidPreferences>>>;
  /** MileMaker API V1 - Guide 19 - Avoid tolls boolean */
  readonly avoidtolls?: Maybe<Scalars['Boolean']>;
  /** MileMaker API V1 - Guide 19 - Hazmat boolean */
  readonly hazmat?: Maybe<Scalars['Boolean']>;
  /** MileMaker API V2 - Guide 20 - Hazmat type array */
  readonly hazmatType?: Maybe<ReadonlyArray<Maybe<MileMakerV2HazmatType>>>;
  /** MileMaker API V2 - Guide 20 - Vehicle parameter - length string */
  readonly length?: Maybe<MileMakerV2Length>;
  /** MileMaker API V1 - Guide 19 - Route type string */
  readonly routetype?: Maybe<MileMakerRouteType>;
  /** MileMaker API V2 - Guide 20 - Routing type string */
  readonly routingType?: Maybe<MileMakerV2RoutingType>;
  /** MileMaker API V1 - Guide 19 - Truck length integer, only 48 or 53 supported */
  readonly trucklength?: Maybe<Scalars['Int']>;
  /** MileMaker API V2 - Guide 20 - Vehicle type string */
  readonly vehicleType?: Maybe<MileMakerV2VehicleType>;
  /** MileMaker API V2 - Guide 20 - x-map-key used to determine Guide version */
  readonly xMapKey?: Maybe<MileMakerV2XMapKey>;
};

export type MileMakerOptionsInput = {
  /** MileMaker API V2 - Guide 20 - Avoid preferences array */
  readonly avoid?: InputMaybe<ReadonlyArray<InputMaybe<MileMakerV2AvoidPreferences>>>;
  /** MileMaker API V1 - Guide 19 - Avoid tolls boolean */
  readonly avoidtolls?: InputMaybe<Scalars['Boolean']>;
  /** MileMaker API V1 - Guide 19 - Hazmat boolean */
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  /** MileMaker API V2 - Guide 20 - Hazmat type array */
  readonly hazmatType?: InputMaybe<ReadonlyArray<InputMaybe<MileMakerV2HazmatType>>>;
  /** MileMaker API V2 - Guide 20 - Vehicle parameter - length string */
  readonly length?: InputMaybe<MileMakerV2Length>;
  /** MileMaker API V1 - Guide 19 - Route type string */
  readonly routetype?: InputMaybe<MileMakerRouteType>;
  /** MileMaker API V2 - Guide 20 - Routing type string */
  readonly routingType?: InputMaybe<MileMakerV2RoutingType>;
  /** MileMaker API V1 - Guide 19 - Truck length integer, only 48 or 53 supported */
  readonly trucklength?: InputMaybe<Scalars['Int']>;
  /** MileMaker API V2 - Guide 20 - Vehicle type string */
  readonly vehicleType?: InputMaybe<MileMakerV2VehicleType>;
  /** MileMaker API V2 - Guide 20 - x-map-key used to determine Guide version */
  readonly xMapKey?: InputMaybe<MileMakerV2XMapKey>;
};

/** h - Household Goods, p - Practical */
export enum MileMakerRouteType {
  H = 'h',
  P = 'p'
}

/** Available avoid preference strings */
export enum MileMakerV2AvoidPreferences {
  /** Avoid tolls */
  Tolls = 'tolls',
  /** Avoid highways */
  Highways = 'highways',
  /** Avoid dirt roads */
  DirtRoads = 'dirtRoads',
  /** Avoid ferries */
  Ferries = 'ferries',
  /** Avoid tunnels */
  Tunnels = 'tunnels',
  /** Avoid U-Turns */
  UTurns = 'uTurns',
  /** Avoid border crossings */
  BorderCrossings = 'borderCrossings'
}

/** Available hazmat type strings */
export enum MileMakerV2HazmatType {
  /** Explosive hazmat type */
  Explosive = 'explosive',
  /** Gas hazmat type */
  Gas = 'gas',
  /** Flammable hazmat type */
  Flammable = 'flammable',
  /** Combustible hazmat type */
  Combustible = 'combustible',
  /** Organic hazmat type */
  Organic = 'organic',
  /** Poison hazmat type */
  Poison = 'poison',
  /** Radioactive hazmat type */
  Radioactive = 'radioactive',
  /** Corrosives hazmat type */
  Corrosives = 'corrosives',
  /** Poisonous inhalation hazmat type */
  PoisonousInhalation = 'poisonousInhalation',
  /** Other unlisted hazmat type */
  Other = 'other'
}

/** Vehicle preference for length string */
export enum MileMakerV2Length {
  /** Straight trailer */
  Straight = 'straight',
  /** 48-foot trailer */
  Fortyeightfoot = 'fortyeightfoot',
  /** 53-foot trailer */
  Fiftythreefoot = 'fiftythreefoot',
  /** Double trailer */
  Double = 'double',
  /** Triple trailer */
  Triple = 'triple'
}

/** Routing type string */
export enum MileMakerV2RoutingType {
  /** Shortest routing */
  Shortest = 'shortest',
  /** Practical routing */
  Practical = 'practical',
  /** Fastest routing */
  Fastest = 'fastest'
}

/** Vehicle type string */
export enum MileMakerV2VehicleType {
  /** Car vehicle type */
  Car = 'car',
  /** Truck vehicle type */
  Truck = 'truck'
}

/** x-map-key value to determine which Guide value to use */
export enum MileMakerV2XMapKey {
  /** Guide 19 map data and routing algorithms, released in 2009 */
  Guide19 = 'guide19',
  /** Guide 20 map data and routing algorithms, released in 2023 */
  Guide20 = 'guide20'
}

export type MileMakerValidationRecord = {
  readonly __typename?: 'MileMakerValidationRecord';
  readonly locality?: Maybe<Scalars['String']>;
  readonly countyQualifier?: Maybe<Scalars['String']>;
  readonly administrativeArea?: Maybe<Scalars['String']>;
  readonly latitude?: Maybe<Scalars['Float']>;
  readonly longitude?: Maybe<Scalars['Float']>;
  readonly fullCounty?: Maybe<Scalars['String']>;
};

export type MinHeight = {
  readonly __typename?: 'MinHeight';
  readonly unit?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['Float']>;
};

export type MinionGlobalGeofencingSettings = {
  readonly __typename?: 'MinionGlobalGeofencingSettings';
  readonly arrivalsEnabled?: Maybe<Scalars['Boolean']>;
  readonly arrivalsRadius?: Maybe<Scalars['Float']>;
  readonly departuresEnabled?: Maybe<Scalars['Boolean']>;
  readonly departuresRadius?: Maybe<Scalars['Float']>;
};

export type MinLength = {
  readonly __typename?: 'MinLength';
  readonly unit?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['Float']>;
};

export type MinWidth = {
  readonly __typename?: 'MinWidth';
  readonly unit?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['Float']>;
};

export type MisoRole = {
  readonly __typename?: 'MisoRole';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Scalars['String'];
  readonly composite: Scalars['Boolean'];
  readonly clientRole: Scalars['String'];
  readonly containerId: Scalars['String'];
  readonly attributes?: Maybe<Attributes>;
};

export type MisoRoleV2 = {
  readonly __typename?: 'MisoRoleV2';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Scalars['String'];
  readonly composite: Scalars['Boolean'];
  readonly clientRole: Scalars['String'];
  readonly containerId: Scalars['String'];
  readonly scopes?: Maybe<ReadonlyArray<Maybe<MisoScopeV2>>>;
  readonly displayName?: Maybe<Scalars['String']>;
};

export type MisoScope = {
  readonly __typename?: 'MisoScope';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: Scalars['String'];
  readonly logic: Scalars['String'];
  readonly decisionStrategy: Scalars['String'];
  readonly config: Scalars['String'];
};

export type MisoScopeV2 = {
  readonly __typename?: 'MisoScopeV2';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: Scalars['String'];
  readonly logic: Scalars['String'];
  readonly description: Scalars['String'];
  readonly decisionStrategy: Scalars['String'];
};

export type MisoUserRep = {
  readonly __typename?: 'MisoUserRep';
  readonly representation: Scalars['JSON'];
};

export type MMaiDocsChatResponseInputV1 = {
  readonly text: Scalars['String'];
  readonly history?: InputMaybe<ReadonlyArray<InputMaybe<ChatTypeInput>>>;
};

export type MMaiDocsSearchInput = {
  readonly text: Scalars['String'];
  readonly history?: InputMaybe<ReadonlyArray<InputMaybe<ChatTypeInput>>>;
};

export type MMaiDocsSearchResponse = {
  readonly __typename?: 'MMaiDocsSearchResponse';
  readonly id: Scalars['ID'];
  readonly choices: ReadonlyArray<Maybe<Scalars['String']>>;
  readonly source: ReadonlyArray<Maybe<Scalars['String']>>;
};

export type MMaiGetSeerLoadSearchVarsRequestV1 = {
  readonly text: Scalars['String'];
  readonly schema: Scalars['String'];
};

export type MMaiGetSeerLoadSearchVarsResponseV1 = MMaiResponseBaseTypeV1 & {
  readonly __typename?: 'MMaiGetSeerLoadSearchVarsResponseV1';
  readonly id: Scalars['ID'];
  readonly vars?: Maybe<Scalars['JSON']>;
};

export type MMaiParseCapacityEmailInputV1 = {
  readonly text: Scalars['String'];
  readonly userTimeZone: Scalars['IANATimeZone'];
  readonly carrierMainLocation?: InputMaybe<Scalars['String']>;
};

export type MMaiParseCapacityEmailResponseV1 = {
  readonly __typename?: 'MMaiParseCapacityEmailResponseV1';
  readonly json?: Maybe<Scalars['JSON']>;
};

export type MMaiResponseBaseTypeV1 = {
  readonly id: Scalars['ID'];
};

export type MobileDriverDetails = {
  readonly __typename?: 'MobileDriverDetails';
  readonly driverId?: Maybe<Scalars['String']>;
  readonly driverMobileEmailAddress?: Maybe<Scalars['String']>;
  readonly driverStatus?: Maybe<Scalars['String']>;
};

export type MobileDriverDetailsInput = {
  readonly driverId: Scalars['String'];
  readonly driverMobileEmailAddress: Scalars['String'];
  readonly driverStatus: Scalars['String'];
};

export type MobileDriverDetailsInputV2 = {
  readonly driverId: Scalars['String'];
  readonly driverMobileEmailAddress: Scalars['String'];
  readonly driverStatus: Scalars['String'];
  readonly driverRole: Scalars['String'];
  readonly deviceType?: InputMaybe<Scalars['String']>;
  readonly deviceToken?: InputMaybe<Scalars['String']>;
};

export type MobileDriverDetailsPayload = {
  readonly __typename?: 'MobileDriverDetailsPayload';
  readonly driverDetails?: Maybe<MobileDriverDetails>;
  readonly errors: ReadonlyArray<UserError>;
};

export type MobileDriverDetailsPayloadV2 = {
  readonly __typename?: 'MobileDriverDetailsPayloadV2';
  readonly driverDetails?: Maybe<MobileDriverDetailsV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type MobileDriverDetailsV2 = {
  readonly __typename?: 'MobileDriverDetailsV2';
  readonly driverId?: Maybe<Scalars['String']>;
  readonly driverMobileEmailAddress?: Maybe<Scalars['String']>;
  readonly driverStatus?: Maybe<Scalars['String']>;
  readonly driverRole?: Maybe<Scalars['String']>;
  readonly deviceType?: Maybe<Scalars['String']>;
  readonly deviceToken?: Maybe<Scalars['String']>;
  readonly timestamp?: Maybe<Scalars['String']>;
};

export type MobileRouteStop = {
  readonly __typename?: 'MobileRouteStop';
  readonly id?: Maybe<Scalars['String']>;
  readonly liveType?: Maybe<Scalars['String']>;
  readonly facilityDetails?: Maybe<FacilityDetails>;
  readonly stopDetails?: Maybe<RouteStopDetails>;
  readonly completed?: Maybe<Scalars['Boolean']>;
  readonly commodities?: Maybe<ReadonlyArray<Maybe<DriverRouteCommodityV2>>>;
  readonly address?: Maybe<AddressResult>;
  readonly timeIn?: Maybe<StopTime>;
  readonly timeOut?: Maybe<StopTime>;
};

export type MobileTrailerType = {
  readonly __typename?: 'MobileTrailerType';
  readonly id?: Maybe<Scalars['ID']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly name?: Maybe<Scalars['String']>;
};

export enum Mode {
  Default = 'Default',
  Truck = 'Truck'
}

export type ModeR2 = {
  readonly __typename?: 'ModeR2';
  readonly id?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
};

export type ModifyRouteResourcesBracingEntityInput = {
  /** Currently bracing type is the only field available for a bracing entity, but that may not always be true. This placeholder (which is thrown away), allows us to add additional bracing fields in the future without breaking the existing schema for modifying bracings. */
  readonly placeholder?: InputMaybe<Scalars['String']>;
};

export type ModifyRouteResourcesBracingInput = {
  readonly type: Scalars['String'];
  readonly bracing?: InputMaybe<ModifyRouteResourcesBracingEntityInput>;
};

export type ModifyRouteResourcesDriverEntityInput = {
  readonly assetDriverID?: InputMaybe<Scalars['ID']>;
  readonly displayName?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
};

export type ModifyRouteResourcesDriverInput = {
  /** Supports driver numbers 1 or 2 */
  readonly sequenceNumber: Scalars['Int'];
  readonly driver?: InputMaybe<ModifyRouteResourcesDriverEntityInput>;
};

/** Input type for creating or partially updating a RouteResources entity */
export type ModifyRouteResourcesInput = {
  /** Required when mutating an existing RouteResources entity for the given routeID. Cannot be deleted by providing a null value. */
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly assetPowerID?: InputMaybe<Scalars['ID']>;
  readonly checkCallDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Cannot be deleted by providing a null value. */
  readonly dispatched?: InputMaybe<Scalars['Boolean']>;
  /** Temporary field until we can resolve user info from the gateway context. */
  readonly dispatchedBy?: InputMaybe<RouteResourcesEmployeeMetaInput>;
  /** Supports up to 2 drivers. */
  readonly drivers?: InputMaybe<ReadonlyArray<ModifyRouteResourcesDriverInput>>;
  readonly eldVehicleID?: InputMaybe<Scalars['String']>;
  readonly emptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly emptyLocation?: InputMaybe<LocationInput>;
  readonly emptyTimezone?: InputMaybe<Scalars['String']>;
  readonly dispatchedEmptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly dispatchedEmptyLocation?: InputMaybe<LocationInput>;
  readonly dispatchedEmptyTimezone?: InputMaybe<Scalars['String']>;
  readonly etaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly etaTimezone?: InputMaybe<Scalars['String']>;
  readonly externalInformation?: InputMaybe<RouteResourcesExternalInformationInput>;
  /** Supports up to 3 trailers. */
  readonly inboundTrailers?: InputMaybe<ReadonlyArray<ModifyRouteResourcesTrailerInput>>;
  /** Cannot be deleted by providing a null value. */
  readonly isAssetPowerIDAutofilled?: InputMaybe<Scalars['Boolean']>;
  /** Supports up to 3 trailers. */
  readonly loadedTrailers?: InputMaybe<ReadonlyArray<ModifyRouteResourcesTrailerInput>>;
  readonly notes?: InputMaybe<Scalars['String']>;
  /** Supports up to 3 trailers. */
  readonly outboundTrailers?: InputMaybe<ReadonlyArray<ModifyRouteResourcesTrailerInput>>;
  readonly reloadIntentID?: InputMaybe<Scalars['String']>;
  /** Required when creating a new RouteResources entity for the given routeID. Cannot be deleted by providing a null value. */
  readonly resourceType?: InputMaybe<RouteResourceTypeEnum>;
  readonly routeID: Scalars['ID'];
  /** Cannot be deleted by providing a null value. */
  readonly trackingEnabled?: InputMaybe<Scalars['Boolean']>;
  /** Temporary field until we can resolve user info from the gateway context, */
  readonly trackingEnabledBy?: InputMaybe<RouteResourcesEmployeeMetaInput>;
  readonly trackingSystemID?: InputMaybe<Scalars['ID']>;
  readonly tractorIdentifier?: InputMaybe<Scalars['String']>;
  readonly trailerLocation?: InputMaybe<LocationInput>;
  readonly trailerLocationFacilityID?: InputMaybe<Scalars['ID']>;
  readonly userID: Scalars['ID'];
};

export type ModifyRouteResourcesTrailerEntityInput = {
  /** Cannot be deleted by providing a null value. */
  readonly areAccessoriesVisible?: InputMaybe<Scalars['Boolean']>;
  readonly assetTrailersID?: InputMaybe<Scalars['ID']>;
  readonly bracings?: InputMaybe<ReadonlyArray<ModifyRouteResourcesBracingInput>>;
  readonly heightInInches?: InputMaybe<Scalars['Float']>;
  readonly identifier?: InputMaybe<Scalars['String']>;
  readonly lengthInFeet?: InputMaybe<Scalars['Float']>;
  readonly numberOfBracings?: InputMaybe<Scalars['Int']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  readonly numberOfTarps?: InputMaybe<Scalars['Int']>;
  /** ID of the Carrier who owns the trailer, if any. */
  readonly ownerID?: InputMaybe<Scalars['String']>;
  readonly palletType?: InputMaybe<Scalars['String']>;
  /** Required when creating a new RouteResourcesTrailerV2 object for the given sequenceNumber. Cannot be deleted by providing a null value. */
  readonly resourceType?: InputMaybe<RouteResourceTypeEnum>;
  readonly tarpType?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Scalars['ID']>;
  readonly widthInInches?: InputMaybe<Scalars['Float']>;
};

export type ModifyRouteResourcesTrailerInput = {
  /** Supports trailer numbers 1, 2, or 3 */
  readonly sequenceNumber: Scalars['Int'];
  readonly trailer?: InputMaybe<ModifyRouteResourcesTrailerEntityInput>;
};

/** ⚠️ **Deprecated! Use MoneyInputV2** ⚠️ */
export type MoneyInput = {
  /** The cost of each item or unit */
  readonly amount?: InputMaybe<Scalars['Int']>;
  /** The Currency code -- *Requires 'Currency' DDT* */
  readonly currencyCodeTerm?: InputMaybe<Scalars['String']>;
};

/** The cost of each individual item or unit */
export type MoneyInputV2 = {
  /** The cost of each item or unit */
  readonly amount: Scalars['Decimal'];
  /** The Currency code -- *Requires 'Currency' DDT* */
  readonly currencyCodeTerm: Scalars['String'];
};

export type MostRecentLtlRatingData = {
  readonly orderId?: InputMaybe<Scalars['ID']>;
};

/** MultilineAddressResults @deprecated, please switch to cleanseAddress with AddressResults */
export type MultilineAddressResults = {
  readonly __typename?: 'MultilineAddressResults';
  readonly VerifiedAddresses?: Maybe<ReadonlyArray<FormattedAddress>>;
  readonly InvalidAddresses?: Maybe<ReadonlyArray<InvalidAddress>>;
};

export type MultiLineRequest = {
  readonly requestId: Scalars['ID'];
  readonly addressLine1: Scalars['String'];
  readonly addressLine2?: InputMaybe<Scalars['String']>;
  readonly addressLine3?: InputMaybe<Scalars['String']>;
  readonly addressLine4?: InputMaybe<Scalars['String']>;
  readonly locality: Scalars['String'];
  readonly administrativeArea: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
  readonly geocodeRequest?: InputMaybe<Scalars['Boolean']>;
};

export type MultipleIncidentCloseUpdateInputV2 = {
  readonly employeeID: Scalars['ID'];
  readonly incidents: ReadonlyArray<IncidentV2CloseUpdateInput>;
};

export type MultiplePartialVoucherBatchProcessingInput = {
  /** Pairs of Route Id and Vendor Id to voucher. */
  readonly RouteVendorIdPairs: ReadonlyArray<InputMaybe<RouteVendorIdPairInput>>;
  /** Process with financials Enabled, Disabled, or Both */
  readonly processFinancials?: InputMaybe<ProcessFinancialsEnum>;
  readonly voucherOverrides?: InputMaybe<VoucherOverridesInput>;
  readonly voucherDateOption?: InputMaybe<DefaultVoucherDateOptionEnum>;
};

export type MultiplePartialVoucherBatchProcessingInputV2 = {
  /** Pairs of Route Id and Vendor Id to voucher. */
  readonly RouteVendorIdPairs: ReadonlyArray<InputMaybe<RouteVendorIdPairInput>>;
  readonly voucherOverrides?: InputMaybe<VoucherOverridesInput>;
  readonly voucherDateOption?: InputMaybe<DefaultVoucherDateOptionEnum>;
};

export type MultiUserInputObj = {
  readonly userList: Scalars['String'];
  readonly userMessage: Scalars['String'];
};

/** The root type for all write operations. */
export type Mutation = {
  readonly __typename?: 'Mutation';
  /** mastery-accounting: mergeLessThanLoadDetails - Merges LTL for rate and cost details */
  readonly mergeLessThanLoadDetails?: Maybe<MergeLessThanLoadDetailsPayload>;
  /** mastery-accounting: createRateDetails - Creates a list of rate details. */
  readonly createRateDetails?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
  /** mastery-accounting: loadRepeatCreateRateDetails - Load repeat create rate details. */
  readonly loadRepeatCreateRateDetails?: Maybe<LoadRepeatCreatedRateDetailsPayload>;
  /** mastery-accounting: loadRepeatCreateCostDetails - Load repeat create cost details. */
  readonly loadRepeatCreateCostDetails?: Maybe<LoadRepeatCreatedCostDetailsPayload>;
  /** mastery-accounting: bookingCreateCostDetails - Booking create cost details. */
  readonly bookingCreateCostDetails?: Maybe<BookingCreatedCostDetailsPayload>;
  /** mastery-accounting: mergeBlumeCostDetails - Merge Blume cost details. */
  readonly mergeBlumeCostDetails?: Maybe<MergeBlumeCostDetailsPayload>;
  /**
   * mastery-accounting: forceCreateRateDetails - Creates a list of rate details. Will set order to incomplete if credit fails
   * @deprecated Deprecated, this method is likely no longer in use
   */
  readonly forceCreateRateDetails?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
  /** mastery-accounting: updateRateDetails - Updates a list of rate details. */
  readonly updateRateDetails?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
  /** mastery-accounting: mergeRateDetailsV3 - Merges a list of rate details with the existing rate details. */
  readonly mergeRateDetailsV3?: Maybe<MergedRateDetailsModel>;
  /** mastery-accounting: deleteRateDetail - Delete a rate detail. */
  readonly deleteRateDetail?: Maybe<RateDetail>;
  /** mastery-accounting: autoRateInvoiceBatchAsync - executes the automated customer invoice batch process. */
  readonly autoInvoiceBatchAsync: Scalars['Boolean'];
  /** mastery-accounting: createInvoiceBatch - Creates a batch of invoices from the order ids passed in (if orders are open and haven't been previously invoiced). */
  readonly createInvoiceBatch?: Maybe<InvoiceBatch>;
  /** mastery-accounting: processInvoiceBatch - Prepares an Invoice batch for the GL system.  The batch will be set to the 'Invoicing' status */
  readonly processInvoiceBatch?: Maybe<InvoiceBatch>;
  /** mastery-accounting: cancelInvoiceBatch - Cancels (deletes) a pending batch of invoices, based on the batch id passed in. Also cancels the related invoice header(s) and resets the related rate details to 'Saved' status. */
  readonly cancelInvoiceBatch: Scalars['Boolean'];
  /** mastery-accounting: createCostDetails - Creates a list of cost details. */
  readonly createCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  /** mastery-accounting: deleteCostDetail - Delete a rate detail. */
  readonly deleteCostDetail?: Maybe<CostDetail>;
  /** mastery-accounting : deleteCostDetails - Deletes cost details by route vendor. */
  readonly deleteCostDetails: Scalars['Boolean'];
  /** mastery-accounting: voucheringUpdateCostDetails - Update vouchering related fields on a list of cost details. Only should be used by vouchering service or other system callers. */
  readonly voucheringUpdateCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  /** mastery-accounting: disassociateCostDetailsFromDriverVoucher - Updates cost details associated to a driver voucher. Removes the driver voucher ID from associated cost details, then updates the cost details statuses back to saved. */
  readonly disassociateCostDetailsFromDriverVoucher?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  /** mastery-accounting: VoucheringApplySegmentationAndGLCodeToCostDetails - Calculate segmentation on cost details during the vouchering process. Only should be used by vouchering service or other system callers. */
  readonly voucheringApplySegmentationAndGlCodeCostDetails: Scalars['Boolean'];
  /** mastery-accounting: VoucheringFinalizeCostDetails - Finalize cost details during the vouchering process. Only should be used by vouchering service or other system callers. */
  readonly voucheringFinalizeCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  /** mastery-accounting: updateCostDetails - Updates a list of cost details. */
  readonly updateCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  /** mastery-accounting: mergeCostDetailsV2 - Merges a list of cost details with the existing cost details. */
  readonly mergeCostDetailsV2?: Maybe<MergedCostDetailsModel>;
  /** mastery-accounting: cancelVoucherBatch - Cancels (deletes) a pending batch of vouchers, based on the batch id passed in.  Also cancels the related voucher header(s) and resets the related cost details to 'Saved' status. */
  readonly cancelVoucherBatch: Scalars['Boolean'];
  /** mastery-accounting: processPartialVoucherBatch - Prepares a Partial Voucher batch for the GL system.  The batch will be set to the 'Invoicing' status */
  readonly processPartialVoucherBatch?: Maybe<VoucherBatch>;
  /** mastery-accounting: processMultiplePartialVoucherBatch - Prepares a Partial Voucher batch with multiple routeVendors for the GL system.  The batch will be set to the 'Invoicing' status */
  readonly processMultiplePartialVoucherBatch?: Maybe<VoucherBatch>;
  /** mastery-accounting: processMultiplePartialVoucherBatchV2 - Prepares a Partial Voucher batch with multiple routeVendors for the GL system.  The batch will be set to the 'Invoicing' status */
  readonly processMultiplePartialVoucherBatchV2Async?: Maybe<VoucherBatch>;
  /** mastery-accounting: createAutoVoucherBatchV2 - Creates and processes a batch of vouchers for routeVendors with RLC and VRFS complete statuses.  The batch will be set to the 'Invoicing' status */
  readonly createAutoVoucherBatchV2Async: Scalars['Boolean'];
  /** mastery-accounting: createAdvanceAsync - Creates a new advance */
  readonly createAdvanceAsync?: Maybe<AdvanceV2>;
  /** mastery-accounting: updateAdvanceBasicAsync - The update mutation for updates by a basic user. */
  readonly updateAdvanceBasicAsync?: Maybe<AdvanceV2>;
  /** mastery-accounting: updateAdvanceRestrictedAsync - The update mutation for updates by a user with elevated permissions. */
  readonly updateAdvanceRestrictedAsync?: Maybe<AdvanceV2>;
  /** mastery-accounting: updateLatestAdvanceDetailsFromProviderAsync - Mutation to update advance with the latest details from the advance provider. */
  readonly updateLatestAdvanceDetailsFromProviderAsync?: Maybe<AdvanceV2>;
  /** mastery-accounting: issueAdvanceAsync - Issues an advance, this is a restricted action. */
  readonly issueAdvanceAsync?: Maybe<AdvanceV2>;
  /** mastery-accounting: cancelAdvanceAsync - Cancels an advance, this is a restricted action. */
  readonly cancelAdvanceAsync?: Maybe<AdvanceV2>;
  /** mastery-accounting: closeAdvanceFromQueueAsync - Sets an advance to closed, this is a restricted action. */
  readonly closeAdvanceFromQueueAsync?: Maybe<AdvanceV2>;
  /** mastery-accounting: blockAdvanceAsync - Blocks payment on an advance, this is a restricted action. */
  readonly blockAdvanceAsync?: Maybe<AdvanceV2>;
  /** mastery-accounting: activateAdvanceAsync - Reactivates payment on a blocked advance, this is a restricted action. */
  readonly activateAdvanceAsync?: Maybe<AdvanceV2>;
  /** mastery-accounting: createAdvanceInterfacesAsync - Creates a collection of Advance Interfaces */
  readonly createAdvanceInterfacesAsync?: Maybe<ReadonlyArray<Maybe<AdvanceInterfaceV2>>>;
  /** mastery-accounting: updateAdvanceInterfacesAsync - Update a collection of Advance Interfaces */
  readonly updateAdvanceInterfacesAsync?: Maybe<ReadonlyArray<Maybe<AdvanceInterfaceV2>>>;
  /** mastery-accounting: refreshApprovedAndIssuedAdvancesAsync - Refresh all approved and issued Advance only */
  readonly refreshApprovedAndIssuedAdvancesAsync?: Maybe<ReadonlyArray<Maybe<AdvanceV2>>>;
  /** mastery-accounting: advanceRefreshOverrideAsync - Manually overrides the cashed amount and status for Advance refreshes (dev and test environments only) */
  readonly advanceRefreshOverrideAsync?: Maybe<ReadonlyArray<Maybe<AdvanceV2>>>;
  /** mastery-accounting: updateRouteMaxCost - Update the max cost on a route */
  readonly updateRouteMaxCost?: Maybe<RouteMaxCostType>;
  /** mastery-accounting: createCustomerCreditMemo - Create customer credit memo */
  readonly createCustomerCreditMemo?: Maybe<CustomerCreditMemo>;
  /** mastery-accounting: processCustomerCreditMemo - Processes a customer credit memo */
  readonly processCustomerCreditMemo?: Maybe<CustomerCreditMemo>;
  /** mastery-accounting: updateCustomerCreditMemo - Updates a customer credit memo */
  readonly updateCustomerCreditMemo?: Maybe<CustomerCreditMemo>;
  /** mastery-accounting: createVendorCreditMemo - Create vendor credit memo */
  readonly createVendorCreditMemo?: Maybe<VendorCreditMemo>;
  /** mastery-accounting: processVendorCreditMemo - Processes a vendor credit memo */
  readonly processVendorCreditMemo?: Maybe<VendorCreditMemo>;
  /** mastery-accounting: updateVendorCreditMemo - Updates a vendor credit memo */
  readonly updateVendorCreditMemo?: Maybe<VendorCreditMemo>;
  /** mastery-accounting: CreateVoucherTolerance - Creates a new voucher tolerance */
  readonly createVoucherTolerance?: Maybe<VoucherTolerance>;
  /** mastery-accounting: updateVoucherTolerance - Updates a voucher tolerance */
  readonly updateVoucherTolerance?: Maybe<VoucherTolerance>;
  /** mastery-accounting: deleteVoucherTolerance - Delete a voucher tolerance */
  readonly deleteVoucherTolerance?: Maybe<VoucherTolerance>;
  /** mastery-accounting: createVendorRequiredDocument - Creates a new vendor required document */
  readonly createVendorRequiredDocument?: Maybe<VendorRequiredDocument>;
  /** mastery-accounting: updateVendorRequiredDocument - Updates a vendor required document */
  readonly updateVendorRequiredDocument?: Maybe<VendorRequiredDocument>;
  /** mastery-accounting: createOrupdateVendorRequiredDocuments - Create or Updates a list of vendor required documents */
  readonly createOrupdateVendorRequiredDocuments?: Maybe<ReadonlyArray<Maybe<VendorRequiredDocument>>>;
  /** mastery-accounting: deleteVendorRequiredDocument - Delete a vendor required document */
  readonly deleteVendorRequiredDocument?: Maybe<VendorRequiredDocument>;
  /** mastery-accounting: deleteCustomerRequiredDocumentGlobalConfiguration - Delete global level customer required document */
  readonly deleteCustomerRequiredDocumentGlobalConfiguration?: Maybe<CustomerRequiredDocumentsGlobalConfiguration>;
  /** mastery-accounting: createVendorAssociatedRequiredDocument - Creates a new vendor associated required document */
  readonly createVendorAssociatedRequiredDocument?: Maybe<VendorAssociatedRequiredDocument>;
  /** mastery-accounting: createVendorAssociatedRequiredDocuments - Creates a new vendor associated required documents */
  readonly createVendorAssociatedRequiredDocuments?: Maybe<ReadonlyArray<Maybe<VendorAssociatedRequiredDocument>>>;
  /** mastery-accounting: updateVendorAssociatedRequiredDocument - Updates a vendor associated required document */
  readonly updateVendorAssociatedRequiredDocument?: Maybe<VendorAssociatedRequiredDocument>;
  /** mastery-accounting: deleteVendorAssociatedRequiredDocument - Delete a vendor associated required document */
  readonly deleteVendorAssociatedRequiredDocument?: Maybe<VendorAssociatedRequiredDocument>;
  /** mastery-accounting: copyGlobalRequiredDocumentForCarrier - Copies a list of Global Vendor Required document for Vendor Type carrier */
  readonly copyGlobalVendorRequiredDocumentForCarrier?: Maybe<ReadonlyArray<Maybe<VendorAssociatedRequiredDocument>>>;
  /** mastery-accounting: createCustomerRequiredDocument - Creates a new customer required document */
  readonly createCustomerRequiredDocument?: Maybe<CustomerRequiredDocument>;
  /** mastery-accounting: createOrUpdateCustomerRequiredDocumentsGlobalConfiguration - Creates a Global configuration for customer required document */
  readonly createOrUpdateCustomerRequiredDocumentsGlobalConfiguration?: Maybe<ReadonlyArray<Maybe<CustomerRequiredDocumentsGlobalConfiguration>>>;
  /** mastery-accounting: createCustomerRequiredDocuments - Creates a new customer required documents */
  readonly createCustomerRequiredDocuments?: Maybe<ReadonlyArray<Maybe<CustomerRequiredDocument>>>;
  /** mastery-accounting: updateCustomerRequiredDocument - Updates a customer required document */
  readonly updateCustomerRequiredDocument?: Maybe<CustomerRequiredDocument>;
  /** mastery-accounting: deleteCustomerRequiredDocument - Delete a customer required document */
  readonly deleteCustomerRequiredDocument?: Maybe<CustomerRequiredDocument>;
  /** mastery-accounting: synchronizeCustomersWithMasteryAccounting */
  readonly synchronizeCustomersWithMasteryAccounting?: Maybe<ReadonlyArray<Maybe<SynchronizedCustomerType>>>;
  /** mastery-accounting: synchronizeVendorsWithMasteryAccounting */
  readonly synchronizeVendorsWithMasteryAccounting?: Maybe<ReadonlyArray<Maybe<SynchronizedVendorType>>>;
  /** mastery-accounting: createAdvanceProviderConfigAsync - Creates an advance provider config */
  readonly createAdvanceProviderConfigAsync?: Maybe<AdvanceProviderConfigTypeV2>;
  /** mastery-accounting: updateAdvanceProviderConfigAsync - Updates an advance provider config */
  readonly updateAdvanceProviderConfigAsync?: Maybe<AdvanceProviderConfigTypeV2>;
  /** mastery-accounting: createSegment - Create a segment */
  readonly createSegment?: Maybe<Segment>;
  /** mastery-accounting: updateSegment - Update a segment */
  readonly updateSegment?: Maybe<Segment>;
  /** mastery-accounting: deleteSegment - Delete a segment. */
  readonly deleteSegment: Scalars['Boolean'];
  /** mastery-accounting: deleteSegmentationConfiguration - Delete a segmentation configuration. */
  readonly deleteSegmentationConfiguration: Scalars['Boolean'];
  /** mastery-accounting: createSegment - Create a segmentation configuration. */
  readonly createSegmentationConfiguration?: Maybe<SegmentationConfiguration>;
  /** mastery-accounting: createOrUpdateSegmentationConfigurations - Create or update multiple segmentation configurations. */
  readonly createOrUpdateSegmentationConfigurations?: Maybe<ReadonlyArray<Maybe<SegmentationConfiguration>>>;
  /** mastery-accounting: updateSegment - Update a segmentation configuration. */
  readonly updateSegmentationConfiguration?: Maybe<SegmentationConfiguration>;
  /** mastery-accounting: deleteVendorInvoice - Delete a vendor invoice. */
  readonly deleteVendorInvoice?: Maybe<VendorInvoice>;
  /** mastery-accounting: createVendorInvoice - Create a vendor invoice */
  readonly createVendorInvoice?: Maybe<VendorInvoice>;
  /** mastery-accounting: updateVendorInvoice - Update a vendor invoice */
  readonly updateVendorInvoice?: Maybe<VendorInvoice>;
  /** mastery-accounting: createOrUpdateVendorInvoice - Create or update a vendor invoice */
  readonly createOrUpdateVendorInvoice?: Maybe<VendorInvoice>;
  /**
   * mastery-accounting: deleteOrphanedEntities - This query is only intended to be used during the interim until Kafka is fully implemented
   * @deprecated This query should not be used; it is only intended for removing orphaned records while Kafka is being built out.
   */
  readonly deleteOrphanedEntities?: Maybe<OrphanedEntitiesModel>;
  /**
   * mastery-accounting: updateCustomerOrderRequiredDocuments - Updates a list of customer order required documents.
   * @deprecated This is the old way of updating a customer order required documents. Use disassociateCustomerOrderRequiredDocuments, waiveCustomerOrderRequiredDocuments and associateCustomerOrderRequiredDocuments mutations instead
   */
  readonly updateCustomerOrderRequiredDocuments?: Maybe<ReadonlyArray<Maybe<CustomerOrderRequiredDocument>>>;
  /** mastery-accounting: disassociateCustomerOrderRequiredDocuments - Disassociate a list of customer order required documents. */
  readonly disassociateCustomerOrderRequiredDocuments?: Maybe<ReadonlyArray<Maybe<CustomerOrderRequiredDocument>>>;
  /** mastery-accounting: waiveCustomerOrderRequiredDocuments - Waive a list of customer order required documents. */
  readonly waiveCustomerOrderRequiredDocuments?: Maybe<ReadonlyArray<Maybe<CustomerOrderRequiredDocument>>>;
  /** mastery-accounting: associateCustomerOrderRequiredDocuments - Associate a list of customer order required documents. */
  readonly associateCustomerOrderRequiredDocuments?: Maybe<ReadonlyArray<Maybe<CustomerOrderRequiredDocument>>>;
  /**
   * mastery-accounting: updateVendorRouteRequiredDocuments - Updates a list of vendor route required documents.
   * @deprecated This is the old way of updating a vendor route required documents. Use associateVendorRouteRequiredDocuments, disassociateVendorRouteRequiredDocuments and waiveVendorRouteRequiredDocuments mutations instead
   */
  readonly updateVendorRouteRequiredDocuments?: Maybe<ReadonlyArray<Maybe<VendorRouteRequiredDocument>>>;
  /** mastery-accounting: associateVendorRouteRequiredDocuments - Associate a list of vendor route required documents. */
  readonly associateVendorRouteRequiredDocuments?: Maybe<ReadonlyArray<Maybe<VendorRouteRequiredDocument>>>;
  /** mastery-accounting: disassociateVendorRouteRequiredDocuments - Disassociate a list of vendor route required documents. */
  readonly disassociateVendorRouteRequiredDocuments?: Maybe<ReadonlyArray<Maybe<VendorRouteRequiredDocument>>>;
  /** mastery-accounting: waiveVendorRouteRequiredDocuments - Waive a list of vendor route required documents. */
  readonly waiveVendorRouteRequiredDocuments?: Maybe<ReadonlyArray<Maybe<VendorRouteRequiredDocument>>>;
  /** mastery-accounting: updateAccountingExceptions - Updates a list of accounting exceptions. */
  readonly updateAccountingExceptions?: Maybe<ReadonlyArray<Maybe<AccountingException>>>;
  /** mastery-accounting: updateDefaultVoucherDate - Updates a default voucher date preference. */
  readonly updateVoucherDateDefault?: Maybe<VoucherDateDefault>;
  /** mastery-accounting: updateCreateAdjustmentItemsChecked - Updates a default for create adjustment items unchecked / checked. */
  readonly updateCreateAdjustmentItemsChecked?: Maybe<CreateAdjustmentItemsChecked>;
  /** mastery-accounting: createVoucherVendorTolerance - Creates a new voucher vendor tolerance */
  readonly createVoucherVendorTolerance?: Maybe<VoucherVendorTolerance>;
  /** mastery-accounting: updateVoucherVendorTolerance - Updates a voucher vendor tolerance */
  readonly updateVoucherVendorTolerance?: Maybe<VoucherVendorTolerance>;
  /** mastery-accounting: deleteVoucherVendorTolerance - Delete a voucher vendor tolerance */
  readonly deleteVoucherVendorTolerance?: Maybe<VoucherVendorTolerance>;
  /** mastery-accounting: resendInvoiceToCustomerByInvoiceHeaderId - Resend invoice to customer as per distribution method. */
  readonly resendInvoiceToCustomerByInvoiceHeaderId?: Maybe<InvoiceHeader>;
  /** mastery-accounting: resendInvoiceToCustomerByInvoiceHeaderId - Resend invoice to customer as per distribution method. */
  readonly BatchResendInvoicesByInvoiceHeaderIds?: Maybe<ReadonlyArray<Maybe<InvoiceHeader>>>;
  /** mastery-accounting: recreateInvoiceToCustomerByInvoiceHeaderId - Recreate invoice to customer as per distribution method. */
  readonly recreateInvoiceToCustomerByInvoiceHeaderId?: Maybe<InvoiceHeader>;
  /** mastery-accounting: recreateInvoiceToCustomerByInvoiceHeaderId - Recreate invoice to customer as per distribution method. */
  readonly BatchRecreateInvoicesByInvoiceHeaderIds?: Maybe<ReadonlyArray<Maybe<InvoiceHeader>>>;
  /** mastery-accounting: updateRouteVendorThresholds - Update route vendor with the latest calculated thresholds */
  readonly updateRouteVendorThresholds?: Maybe<RouteVendorCost>;
  /** mastery-accounting: createAdjustmentInterfaces - Creates a collection of Adjustment Interfaces */
  readonly createAdjustmentInterfaces?: Maybe<ReadonlyArray<Maybe<AdjustmentInterface>>>;
  /** mastery-accounting: updateAdjustmentInterfaces - Update a collection of Adjustment Interfaces */
  readonly updateAdjustmentInterfaces?: Maybe<ReadonlyArray<Maybe<AdjustmentInterface>>>;
  /** mastery-accounting: createTaxInterfaces - Creates a collection of Tax Interfaces */
  readonly createTaxInterfaces?: Maybe<ReadonlyArray<Maybe<TaxInterfaceModel>>>;
  /** mastery-accounting: updateTaxInterfaces - Update a collection of Tax Interfaces */
  readonly updateTaxInterfaces?: Maybe<ReadonlyArray<Maybe<TaxInterfaceModel>>>;
  /** mastery-accounting: createVendorInvoiceVouchers - Creates a record linking vendor invoices to vouchers */
  readonly createVendorInvoiceVouchers?: Maybe<ReadonlyArray<Maybe<VendorInvoiceVoucher>>>;
  /** mastery-accounting: updateVendorInvoiceVoucher - Updates a record linking vendor invoice to voucher */
  readonly updateVendorInvoiceVoucher?: Maybe<VendorInvoiceVoucher>;
  /** mastery-accounting: deleteVendorInvoiceVoucher - Delete a vendor invoice / voucher link */
  readonly deleteVendorInvoiceVoucher?: Maybe<VendorInvoiceVoucher>;
  /** mastery-accounting: performAutoVoucherBatch - Perform an auto voucher batch - FOR TESTING ONLY! */
  readonly performAutoVoucherBatch?: Maybe<VoucherBatch>;
  /** mastery-accounting: updateOrderInvoiceRequirement - Update bypass for order invoice financial status complete validation */
  readonly updateOrderInvoiceRequirement: Scalars['Boolean'];
  /** mastery-accounting: updateOrderTaxRequirements - Update tax exemptions */
  readonly updateOrderTaxRequirements: Scalars['Boolean'];
  /** mastery-accounting: updateRouteVendorVoucherRequirement - Update bypass for route vendor financial status complete validation */
  readonly updateRouteVendorVoucherRequirement: Scalars['Boolean'];
  /** mastery-accounting: updateRouteVendorVoucherDisableFinancials - Update disable financials for auto voucher route vendor exempt from AP validations */
  readonly UpdateRouteVendorVoucherDisableFinancials: Scalars['Boolean'];
  /** mastery-accounting: CreateExchangeRateConfiguration- Creates a new Exchange Rate Configuration */
  readonly createExchangeRateConfiguration?: Maybe<ExchangeRateConfiguration>;
  /** mastery-accounting: updateExchangeRateConfiguration - Updates a Exchange Rate Configuration */
  readonly updateExchangeRateConfiguration?: Maybe<ExchangeRateConfiguration>;
  /** mastery-accounting: deleteExchangeRateConfiguration - Delete a Exchange Rate Configuration */
  readonly deleteExchangeRateConfiguration?: Maybe<ExchangeRateConfiguration>;
  /** mastery-accounting: addAutoAssociationUsers - add list of users for auto association */
  readonly addAutoAssociationUsers?: Maybe<ReadonlyArray<Maybe<AutoAssociationUser>>>;
  /** mastery-accounting: removeAutoAssociationUser - Delete user from auto association user list  */
  readonly removeAutoAssociationUser?: Maybe<AutoAssociationUser>;
  /** mastery-accounting: autoAssociateDocuments - Utility method to auto associate documents  */
  readonly autoAssociateDocuments: Scalars['Uuid'];
  /** mastery-accounting: createCustomerOrderRequiredDocument - create custom customer order required document */
  readonly createCustomerOrderRequiredDocument?: Maybe<CustomerOrderRequiredDocument>;
  /** mastery-accounting: addCustomerOrderRequiredDocuments - create custom customer order required documents */
  readonly addCustomerOrderRequiredDocuments?: Maybe<ReadonlyArray<Maybe<CustomerOrderRequiredDocument>>>;
  /** mastery-accounting: removeCustomerOrderRequiredDocument - remove custom customer order required document */
  readonly removeCustomerOrderRequiredDocument?: Maybe<CustomerOrderRequiredDocument>;
  /** Updates an existing Jurisdiction Tax */
  readonly updateJurisdictionTax?: Maybe<JurisdictionTax>;
  /** Creates a new Jurisdiction Tax */
  readonly createJurisdictionTax?: Maybe<JurisdictionTax>;
  /** Deletes an existing Jurisdiction Tax */
  readonly deleteJurisdictionTax?: Maybe<JurisdictionTax>;
  /** Updates an existing Taxability Charge Type */
  readonly updateTaxabilityChargeType?: Maybe<TaxabilityChargeTypeOutput>;
  /** Creates a new Bill To Code */
  readonly createBillToCode?: Maybe<BillToCode>;
  /** Creates new Bill To Codes */
  readonly createBillToCodes?: Maybe<ReadonlyArray<Maybe<BillToCode>>>;
  /** Updates an existing Bill To Code */
  readonly updateBillToCode?: Maybe<BillToCode>;
  /** Sets new default Bill To Code */
  readonly setDefaultBillToCodeAsync?: Maybe<BillToCode>;
  /** Updates an existing Bill To Codes */
  readonly updateBillToCodes?: Maybe<ReadonlyArray<Maybe<BillToCode>>>;
  /** Deletes an existing Bill To Code */
  readonly deleteBillToCode?: Maybe<BillToCode>;
  /** Creates a new Taxability Charge Tyoe */
  readonly createTaxabilityChargeType?: Maybe<TaxabilityChargeTypeOutput>;
  /** Deletes an existing Taxability Charge Type */
  readonly deleteTaxabilityChargeType?: Maybe<TaxabilityChargeTypeOutput>;
  /**
   * mastery-accounting: sets default values for charge types related to a given country
   * @deprecated Created field no longer required, use setDefaultChargeTypeForGivenCountry
   */
  readonly setDefaultChargeTypeForCountry: Scalars['Boolean'];
  /**
   * mastery-accounting: sets default values for charge types related to a given country
   * @deprecated defaultTaxability field will be removed in a follow up story
   */
  readonly setDefaultChargeTypeForGivenCountry: Scalars['Boolean'];
  /**
   * mastery-accounting: Updates a Customers Tax Exemption Status
   * @deprecated Use mutation updateCustomerAccountingDetails instead.
   */
  readonly updateCustomerTaxExemption: Scalars['Boolean'];
  /** mastery-accounting: Updates a Customers Tax Exemption Status and Accounting Status */
  readonly updateCustomerAccountingDetails: Scalars['Boolean'];
  /** mastery-accounting: Updates a Carrier's Accounting Status */
  readonly updateCarrierAccountingDetails?: Maybe<SynchronizedVendorType>;
  /** mastery-accounting: updateCalculateVatChecked - Updates calculate VAT settings */
  readonly updateCalculateVatChecked?: Maybe<CalculateVatCheckedModel>;
  /** mastery-accounting: setSegmentValues - Set values to be used for a DDT in segmentation */
  readonly setSegmentValues: Scalars['Boolean'];
  /**
   * mastery-accounting: setAdvanceStatusCashed - Set all approved advances in a load as cashed status.
   * @deprecated This query should not be used; it is only intended for testing purposes.
   */
  readonly setAdvanceStatusCashed?: Maybe<ReadonlyArray<Maybe<Advance>>>;
  /** mastery-accounting: update vendor autovouchering - Enable or disable the auto vouchering of a vendor/carrier */
  readonly updateVendorAutoVoucher: Scalars['Boolean'];
  /** mastery-accounting: update vendor use_global - Checked or unchecked */
  readonly updateVendorUseGlobalToggle: Scalars['Boolean'];
  /** mastery-accounting: update Customer use_global - Checked or unchecked */
  readonly updateCustomerUseGlobalToggle: Scalars['Boolean'];
  /** mastery-accounting: update customer autoinvoicing - Enable or disable the auto invoicing of a customer */
  readonly updateCustomerAutoInvoice: Scalars['Boolean'];
  /** mastery-accounting: set IsFinalized value of Segmentation Configuration. */
  readonly setSegmentationConfigurationIsFinalized?: Maybe<SegmentationConfiguration>;
  /** mastery-accounting: createOrUpdateExtractConfiguration - Create or update extract configuration */
  readonly createOrUpdateExtractConfiguration?: Maybe<ExtractConfigurationType>;
  /** mastery-accounting: deleteExtractConfiguration detail - Delete extract Configuration Details. */
  readonly deleteExtractConfiguration?: Maybe<ExtractConfigurationType>;
  /** mastery-accounting: createOrUpdateIntegrationMapping - Create or update Integration Mapping */
  readonly createOrUpdateIntegrationMapping?: Maybe<IntegrationMapping>;
  /** mastery-accounting: deleteIntegrationMapping - Delete Integration Mapping */
  readonly deleteIntegrationMapping?: Maybe<IntegrationMapping>;
  /** mastery-accounting: Set configuration value by key */
  readonly setAccountingConfigurationValue?: Maybe<Scalars['String']>;
  /** mastery-accounting: Distribute consolidated invoices that occurred before given date. */
  readonly distributeConsolidatedInvoices: Scalars['Int'];
  /** mastery-accounting: updateDisableFinancialsForCustomerOrders - Update disable financials for orders */
  readonly updateDisableFinancialsForCustomerOrders?: Maybe<ReadonlyArray<Maybe<OrderDisableFinancialsModel>>>;
  /** mastery-accounting: updateDisableFinancialsForRouteVendors - Update disable financials for RouteVendors */
  readonly updateDisableFinancialsForRouteVendors?: Maybe<ReadonlyArray<Maybe<RouteVendorDisableFinancialsModel>>>;
  /** mastery-accounting: updateRouteVendorIsApprovedForVouchering - Update hold column isApprovedForVouchering for RouteVendors */
  readonly updateRouteVendorIsApprovedForVouchering?: Maybe<ReadonlyArray<Maybe<RouteVendorHoldApprovedForVoucheringModel>>>;
  /** mastery-accounting: updateOrderIsApprovedForInvoicing - Update isApprovedForInvoicing for Orders */
  readonly updateOrderIsApprovedForInvoicing?: Maybe<ReadonlyArray<Maybe<OrderHoldApprovedForInvoicingModel>>>;
  /** mastery-accounting: UpdateOrderFuelStatus - Update fuel_included in Order Finalized Fuel Status for Orders */
  readonly updateOrderFuelStatus: Scalars['Boolean'];
  /** mastery-accounting: createCustomerPaymentTerm - Create customer payment term */
  readonly createCustomerPaymentTerm?: Maybe<CustomerPaymentTerm>;
  /** mastery-accounting: updateCustomerPaymentTerm - Update customer payment term */
  readonly updateCustomerPaymentTerm?: Maybe<CustomerPaymentTerm>;
  /** mastery-accounting: deleteCustomerPaymentTerm - Delete customer payment term id guid */
  readonly deleteCustomerPaymentTerm?: Maybe<CustomerPaymentTerm>;
  /** mastery-accounting: createCarrierPaymentTerm - Create carrier payment term */
  readonly createCarrierPaymentTerm?: Maybe<CarrierPaymentTerm>;
  /** mastery-accounting: updateCarrierPaymentTerm - Update carrier payment term */
  readonly updateCarrierPaymentTerm?: Maybe<CarrierPaymentTerm>;
  /** mastery-accounting: deleteCarrierPaymentTerm - Delete carrier payment term id guid */
  readonly deleteCarrierPaymentTerm?: Maybe<CarrierPaymentTerm>;
  /** mastery-accounting: updateRouteBypassFuelStatus - Update bypass_fuel in Route Finalized Fuel Status for Routes */
  readonly updateRouteBypassFuelStatus: Scalars['Boolean'];
  readonly createTrip?: Maybe<CreateTripOutput>;
  readonly deleteTrip?: Maybe<DeleteTripOutput>;
  readonly addRouteToTrip?: Maybe<AddRouteToTripOutput>;
  readonly removeRouteFromTrip?: Maybe<RemoveRouteFromTripOutput>;
  readonly createDummyProjectNameExample: CreateDummyProjectNameExamplePayload;
  /** updateVoucheringRequirementConfiguration - updates a voucheringRequirementConfiguration record based on input received. */
  readonly updateVoucheringRequirementConfiguration?: Maybe<VoucheringRequirementConfiguration>;
  /** updateInvoicingRequirementConfiguration - updates an invoicingRequirementConfiguration record based on input received. */
  readonly updateInvoicingRequirementConfiguration?: Maybe<InvoicingRequirementConfiguration>;
  /** updateRouteVendorRequirement - updates a routeVendorRequirement record based on input received. */
  readonly updateRouteVendorRequirement?: Maybe<RouteVendorRequirement>;
  /** updateCustomerOrderRequirement - updates a customerOrderRequirement record based on input received. */
  readonly updateCustomerOrderRequirement?: Maybe<CustomerOrderRequirement>;
  readonly createOrUpdateDriverPaySettings?: Maybe<DriverPaySettingType>;
  readonly deleteDriverPaySetting?: Maybe<Scalars['Boolean']>;
  readonly createNonRouteCostDetail?: Maybe<NonRouteCostDetail>;
  readonly updateNonRouteCostDetail?: Maybe<NonRouteCostDetail>;
  readonly deleteNonRouteCostDetail?: Maybe<NonRouteCostDetail>;
  readonly deleteDriverVoucherHeader?: Maybe<Scalars['Boolean']>;
  readonly createPublishDriverVoucherProcessingRequest?: Maybe<Scalars['Boolean']>;
  readonly createAndProcessDriverVouchers?: Maybe<CreateAndProcessDriverVouchersOutput>;
  readonly setJobConfiguration?: Maybe<JobConfigurationType>;
  readonly evaluateDriverVouchers?: Maybe<EvaluateDriverVouchersOutput>;
  readonly editDriverVoucherHeaders: ReadonlyArray<DriverVoucherHeaderType>;
  readonly editDriverVoucherHeader?: Maybe<EditDriverVoucherHeaderOutput>;
  readonly finalizeDriverVoucherHeaders?: Maybe<Scalars['Boolean']>;
  readonly saveCustomerRatingSource?: Maybe<SaveCustomerRatingSourcePayload>;
  readonly createBulkReratingRequest?: Maybe<BulkReratingRequest>;
  readonly startBulkReratingRequest?: Maybe<BulkReratingRequest>;
  readonly cancelBulkReratingRequest?: Maybe<BulkReratingRequest>;
  readonly addLoadsToBulkReratingRequest?: Maybe<BulkReratingRequest>;
  readonly saveCustomerPriorityQuoteType?: Maybe<SavePriorityQuoteTypePayload>;
  readonly saveCustomerAccessorialSettings?: Maybe<SaveCustomerAccessorialSettingsPayload>;
  readonly saveCarrierAccessorialSettings?: Maybe<SaveCarrierAccessorialSettingsPayload>;
  readonly createCarrierCapacityConstraint?: Maybe<CarrierCapacity>;
  readonly updateCarrierCapacityConstraint?: Maybe<CarrierCapacity>;
  readonly softAllocateCarrierCapacityByCostQuoteAndDateConstraint?: Maybe<SoftAllocateCarrierCapacityType>;
  readonly createAssetDriverTender?: Maybe<AssetDriverTender>;
  readonly createAssetDriverTenderAndSendToDriver?: Maybe<AssetDriverTender>;
  readonly sendAssetDriverTenderToDriver?: Maybe<AssetDriverTender>;
  readonly acceptAssetDriverTender?: Maybe<AssetDriverTender>;
  readonly declineAssetDriverTender?: Maybe<AssetDriverTender>;
  readonly sendToOpenBoard: Scalars['Boolean'];
  /** @deprecated Use saveRoutingGuide instead */
  readonly createRoutingGuideV2?: Maybe<RoutingGuide>;
  /** @deprecated Use saveRoutingGuide instead */
  readonly updateRoutingGuide?: Maybe<RoutingGuide>;
  /** @deprecated Use saveRoutingGuide instead */
  readonly createRoutingGuideSequenceItem?: Maybe<RoutingGuideSequenceItem>;
  /** @deprecated Use saveRoutingGuide instead */
  readonly updateRoutingGuideSequenceItem?: Maybe<RoutingGuideSequenceItem>;
  /** @deprecated Use saveRoutingGuide instead */
  readonly deleteRoutingGuideSequenceItem: Scalars['Boolean'];
  /** @deprecated Use saveRoutingGuide instead */
  readonly reorderRoutingGuideSequenceItems: Scalars['Boolean'];
  readonly saveRoutingGuide?: Maybe<RoutingGuide>;
  /** @deprecated Use uploadRoutingGuidesV2 instead */
  readonly uploadRoutingGuides?: Maybe<UploadRoutingGuidePayload>;
  readonly uploadRoutingGuidesV2?: Maybe<UploadRoutingGuidesV2Payload>;
  readonly upsertTenderOptions?: Maybe<TenderOptions>;
  readonly reorderTenderPlanTenderSequence?: Maybe<TenderPlan>;
  readonly toggleTenderSkipStatus?: Maybe<TenderPlan>;
  readonly createTenderPlan?: Maybe<TenderPlan>;
  readonly startTenderPlan?: Maybe<TenderPlan>;
  readonly stopTenderPlan?: Maybe<TenderPlan>;
  readonly recreateTenderPlan?: Maybe<TenderPlan>;
  readonly createOutboundTenderSettings?: Maybe<OutboundTenderSettings>;
  readonly updateOutboundTenderSettings?: Maybe<OutboundTenderSettings>;
  readonly updateMultipleTenderOptions?: Maybe<ReadonlyArray<Maybe<TenderOptions>>>;
  readonly insertMultipleTenderOptions?: Maybe<ReadonlyArray<Maybe<TenderOptions>>>;
  readonly sendToOpenBoardV2?: Maybe<Scalars['Boolean']>;
  /** Creates a flip request for the routeId(s) or loadId(s) provided. This will refresh the GQL enriched data for the provided entities and publish update requests for the associated assets and routes */
  readonly flipDriverCurrentAndNextRoutes: Scalars['Boolean'];
  /** Creates a flip request for the routeId(s) or loadId(s) provided. This will refresh the GQL enriched data for the provided entities and publish update requests for the associated assets and routes */
  readonly flipPowerUnitCurrentAndNextRoutes: Scalars['Boolean'];
  readonly updateWorkflowJob: UpdateWorkflowJobPayLoad;
  /** Send a new payload for the workflow job */
  readonly sendWorkflowJob: SendWorkflowJobPayLoad;
  /** Creates a new payload for the workflow job */
  readonly createWorkflowJob: CreateWorkflowJobPayLoad;
  /** Creates a payload to update the existing workflow job */
  readonly updateWorkFlowJobRecord: UpdateWorkflowJobRecordPayLoad;
  /** Creates a payload to update the existing workflow job */
  readonly updatWFJRecord: UpdateWorkflowJobRecordPayLoad;
  /** Update drivers for the existing workflow job */
  readonly updateDriversForWorkFlowJobRecord: UpdateWorkflowJobRecordPayLoad;
  /** Creates a payload to update the existing workflow job */
  readonly deleteWorkflowJob: UpdateWorkflowJobRecordPayLoad;
  readonly checkAndAutoPost: Scalars['String'];
  readonly assignAccountingChargeTypeToBlumeChargeType: AssignAccountingChargeTypeToBlumeChargeTypePayload;
  readonly importBlumeCharges: ImportBlumeChargesPayload;
  /** Mutation to Rebook a Carrier / Vendor after it has been Bounced */
  readonly rebookRouteVendorV2: RebookRouteVendorV2Payload;
  /** Mutation to create a Route Vendor, which represents a Carrier / Vendor on a Route */
  readonly assignVendorToRouteV2: AssignVendorToRouteV2Payload;
  /** Mutation for assigning multiple Route Vendors at once */
  readonly bulkAssignVendorToRoutesV2: BulkAssignVendorToRoutesV2Payload;
  /** Mutation to update a Carrier / Vendor on a Route */
  readonly updateRouteVendorV2: UpdateRouteVendorV2Payload;
  /** Mutation to update an individual Bounce */
  readonly updateBounceV2: UpdateBounceV2Payload;
  /** Mutation for Bouncing a Carrier / Vendor from a Route */
  readonly bounceRouteVendorV2: BounceRouteVendorV2Payload;
  /** Mutation for Validating a Carrier / Vendor before it is Assigned to a Route, Updated, or Rebooked */
  readonly validateBooking: ValidateBookingPayload;
  /** Mutation for Validating a Carrier / Vendor for multiple routes */
  readonly validateMassBooking: ReadonlyArray<ValidateMassBookingPayload>;
  /** Mutation to Book a Carrier via Booking Orchestration */
  readonly bookCarrierAsync: BookCarrierAsyncPayload;
  /** Mutation to Bounce a Carrier via Booking Orchestration */
  readonly bounceCarrierAsync: BounceCarrierAsyncPayload;
  /** Mutation to update a list of Route References */
  readonly updateRouteVendorReferences: RouteVendorReferencesPayload;
  /** Mutation to replace all Route Vendor References that were created through an automated process. */
  readonly replaceRouteVendorReferences: RouteVendorReferencesPayload;
  /** Mutation to assign a Carrier or Driver to a Route */
  readonly bookCarrier: BookCarrierPayload;
  /** Mutation to assign a Carrier and Driver to a Route */
  readonly bookDrivers: BookPayload;
  /** Mutation to assign a Carrier on multiple routes at once */
  readonly bookRoutesAsync: ReadonlyArray<BookCarrierAsyncPayload>;
  /** mastery-crm: createCarrierCrmNotes - Creates a Carrier Crm Notes record. */
  readonly createCarrierCrmNotes?: Maybe<CarrierCrmNotes>;
  /** mastery-crm: createCarrierCrmOpportunity - Creates a Carrier Crm Opportunity record. */
  readonly createCarrierCrmOpportunity?: Maybe<CarrierCrmOpportunity>;
  /** mastery-crm: createCarrierExportNotes - Creates a Carrier Export Notes record. */
  readonly createCarrierExportNotes?: Maybe<CarrierExportNoteResponse>;
  /** mastery-crm: createCarrierInteraction - Creates a carrier interaction record. */
  readonly createCarrierInteraction?: Maybe<CarrierInteraction>;
  /** mastery-crm: createCustomerNotes - creates a customer crm notes record. */
  readonly createCustomerCrmNotes?: Maybe<CustomerCrmNote>;
  /** mastery-crm: createCustomerCrmOpportunity - Creates a Customer Crm Opportunity record. */
  readonly createCustomerCrmOpportunity?: Maybe<CustomerCrmOpportunity>;
  /** mastery-crm: createCustomerExportNotes - creates a customer export notes record. */
  readonly createCustomerExportNotes?: Maybe<CustomerExportNoteResponse>;
  /** mastery-crm: createCustomerInteraction - Creates a customer interaction record. */
  readonly createCustomerInteraction?: Maybe<CustomerInteraction>;
  /** mastery-crm: createCarrierDetail - Creates a carrier Detail record. */
  readonly createOrUpdateCRMCarrierDetail?: Maybe<CrmCarrierDetail>;
  /** mastery-crm: createCustomerDetail - Creates a customer Detail record. */
  readonly createOrUpdateCRMCustomerDetail?: Maybe<CrmCustomerDetail>;
  /** mastery-crm: deleteCarrierCrmDocuments - delete a carrier crm document record. */
  readonly deleteCarrierCrmDocuments: Scalars['Boolean'];
  /** mastery-crm: deleteCustomerCrmDocuments - delete a customer crm document record. */
  readonly deleteCustomerCrmDocuments: Scalars['Boolean'];
  /** mastery-crm: createCarrierDocuments - updates a carrier crm document record. */
  readonly updateCarrierCrmDocuments?: Maybe<CarrierCrmDocument>;
  /** mastery-crm: updateCarrierCrmNotes - Update a Carrier Crm Notes record. */
  readonly updateCarrierCrmNotes?: Maybe<CarrierCrmNotes>;
  /** mastery-crm: updateCarrierCrmOpportunity - Update a Carrier Crm Opportunity record. */
  readonly updateCarrierCrmOpportunity?: Maybe<CarrierCrmOpportunity>;
  /** mastery-crm: updateCustomerNotes - updates a customer crm document record. */
  readonly updateCustomerCrmDocuments?: Maybe<CustomerCrmDocument>;
  /** mastery-crm: updateCustomerNotes - updates a customer crm notes record. */
  readonly updateCustomerCrmNotes?: Maybe<CustomerCrmNote>;
  /** mastery-crm: updateCustomerCrmOpportunity - Update a Customer Crm Opportunity record. */
  readonly updateCustomerCrmOpportunity?: Maybe<CustomerCrmOpportunity>;
  /** mastery-crm: updateKvtValuesToCrmTable - updates KVT values to crm table. */
  readonly updateKvtValuesToCrmTable?: Maybe<UpdateKvtValuesResponse>;
  /** @deprecated saveContact mutation deprecated */
  readonly saveContact: CrmContactOutput;
  /** @deprecated updateContactAssociatedEntity mutation deprecated */
  readonly updateContactAssociatedEntity: ReadonlyArray<CrmContactAssociatedEntityOutput>;
  /** @deprecated updateContactNameInformationData mutation deprecated */
  readonly updateContactNameInformationData: ReadonlyArray<CrmContactOutput>;
  /** @deprecated updateContactGeneralData mutation deprecated */
  readonly updateContactGeneralData: Scalars['Boolean'];
  /** @deprecated updateContactBusinessInformationData mutation deprecated */
  readonly updateContactBusinessInformationData: Scalars['Boolean'];
  /** @deprecated saveAssociatedReps mutation deprecated */
  readonly saveAssociatedReps: CrmContactAssociatedRepOutput;
  /** @deprecated inactivateContactAssociatedRepById mutation deprecated */
  readonly inactivateContactAssociatedRepById: Scalars['Boolean'];
  /** @deprecated inactivateContactInformationById mutation deprecated */
  readonly inactivateContactInformationById: Scalars['Boolean'];
  /** @deprecated modifyAssociatedRepById mutation deprecated */
  readonly modifyAssociatedRepById: Scalars['Boolean'];
  /** @deprecated saveContactInformation mutation deprecated */
  readonly saveContactInformation: CrmContactInformationOutput;
  /** @deprecated modifyContactInformation mutation deprecated */
  readonly modifyContactInformation: ReadonlyArray<CrmContactInformationOutput>;
  /** @deprecated saveContactWorkHours mutation deprecated */
  readonly saveContactWorkHours: ReadonlyArray<CrmContactWorkHoursOutput>;
  /** @deprecated modifyContactWorkHour mutation deprecated */
  readonly modifyContactWorkHour: Scalars['Boolean'];
  /** @deprecated modifyAssociatedEntityByIdV2 mutation deprecated */
  readonly modifyAssociatedEntityByIdV2: Scalars['Boolean'];
  /** @deprecated deleteAssociatedEntityByIdV2 mutation deprecated */
  readonly deleteAssociatedEntityByIdV2: Scalars['Boolean'];
  /** @deprecated saveAssociatedEntityV2 mutation deprecated */
  readonly saveAssociatedEntityV2: CrmContactAssociatedEntityOutput;
  /** @deprecated saveContactv2 mutation deprecated */
  readonly saveContactv2: CrmContactOutputV2;
  /** @deprecated modifyContactAssociatedEntityv2 mutation deprecated */
  readonly modifyContactAssociatedEntityv2: ReadonlyArray<CrmContactAssociatedEntityOutputV2>;
  /** @deprecated modifyContactNameInformationDatav2 mutation deprecated */
  readonly modifyContactNameInformationDatav2: ReadonlyArray<CrmContactOutputV2>;
  /** @deprecated modifyContactGeneralDatav2 mutation deprecated */
  readonly modifyContactGeneralDatav2: Scalars['Boolean'];
  /** @deprecated modifyContactBusinessInformationDatav2 mutation deprecated */
  readonly modifyContactBusinessInformationDatav2: Scalars['Boolean'];
  /** @deprecated saveAssociatedRepsv2 mutation deprecated */
  readonly saveAssociatedRepsv2: CrmContactAssociatedRepOutputV2;
  /** @deprecated inactivateContactAssociatedRepByIdv2 mutation deprecated */
  readonly inactivateContactAssociatedRepByIdv2: Scalars['Boolean'];
  /** @deprecated inactivateContactInformationByIdv2 mutation deprecated */
  readonly inactivateContactInformationByIdv2: Scalars['Boolean'];
  /** @deprecated modifyAssociatedRepByIdv2 mutation deprecated */
  readonly modifyAssociatedRepByIdv2: Scalars['Boolean'];
  /** @deprecated saveContactInformationv2 mutation deprecated */
  readonly saveContactInformationv2: CrmContactInformationOutputV2;
  /** @deprecated modifyContactInformationv2 mutation deprecated */
  readonly modifyContactInformationv2: ReadonlyArray<CrmContactInformationOutputV2>;
  /** @deprecated saveContactWorkHoursv2 mutation deprecated */
  readonly saveContactWorkHoursv2: ReadonlyArray<CrmContactWorkHoursOutputv2>;
  /** @deprecated modifyContactWorkHourv2 mutation deprecated */
  readonly modifyContactWorkHourv2: Scalars['Boolean'];
  /** @deprecated saveAssociatedEntityv2 mutation deprecated */
  readonly saveAssociatedEntityv2: CrmContactAssociatedEntityOutputV2;
  /** @deprecated deleteAssociatedEntityByIdv2 mutation deprecated */
  readonly deleteAssociatedEntityByIdv2: Scalars['Boolean'];
  /** @deprecated modifyAssociatedEntityByIdv2 mutation deprecated */
  readonly modifyAssociatedEntityByIdv2: Scalars['Boolean'];
  /** @deprecated saveResponsibilityv2 mutation deprecated */
  readonly saveResponsibilityv2: CrmContactResponsibilityOutputV2;
  /** @deprecated deleteResponsibilityByIdv2 mutation deprecated */
  readonly deleteResponsibilityByIdv2: CrmContactResponsibilityOutputV2;
  /** @deprecated modifyResponsibilityByIdv2 mutation deprecated */
  readonly modifyResponsibilityByIdv2: CrmContactResponsibilityOutputV2;
  /** @deprecated saveContactSliderv2 mutation deprecated */
  readonly saveContactSliderv2: CrmContactSliderOutputV2;
  /** @deprecated modifyContactSliderv2 mutation deprecated */
  readonly modifyContactSliderv2: CrmContactSliderOutputV2;
  /** @deprecated saveContactInterestHobbyv2 mutation deprecated */
  readonly saveContactInterestHobbyv2: ReadonlyArray<CrmContactInterestHobbyOutputV2>;
  /** @deprecated modifyContactInterestHobbyv2 mutation deprecated */
  readonly modifyContactInterestHobbyv2: ReadonlyArray<CrmContactInterestHobbyOutputV2>;
  readonly crmSaveContact: CrmContactOutput;
  readonly crmModifyContactAssociatedEntity: ReadonlyArray<CrmContactAssociatedEntityOutput>;
  readonly crmModifyContactNameInformationData: ReadonlyArray<CrmContactOutput>;
  readonly crmModifyContactGeneralData: Scalars['Boolean'];
  readonly crmModifyContactBusinessInformationData: Scalars['Boolean'];
  readonly crmSaveAssociatedReps: CrmContactAssociatedRepOutput;
  readonly crmInactivateContactAssociatedRepById: Scalars['Boolean'];
  readonly crmInactivateContactInformationById: Scalars['Boolean'];
  readonly crmModifyAssociatedRepById: Scalars['Boolean'];
  readonly crmSaveContactInformation: CrmContactInformationOutput;
  readonly crmModifyContactInformation: ReadonlyArray<CrmContactInformationOutput>;
  readonly crmSaveContactWorkHours: ReadonlyArray<CrmContactWorkHoursOutput>;
  readonly crmModifyContactWorkHours: Scalars['Boolean'];
  readonly crmModifyContactWorkHour: Scalars['Boolean'];
  readonly crmSaveAssociatedEntity: CrmContactAssociatedEntityOutput;
  readonly crmInactivateAssociatedEntityById: Scalars['Boolean'];
  readonly crmModifyAssociatedEntityById: Scalars['Boolean'];
  readonly crmSaveResponsibility: CrmContactResponsibilityOutput;
  readonly crmInactivateResponsibilityById: CrmContactResponsibilityOutput;
  readonly crmModifyResponsibilityById: CrmContactResponsibilityOutput;
  readonly crmSaveContactSlider: CrmContactSliderOutput;
  readonly crmModifyContactSlider: CrmContactSliderOutput;
  readonly crmSaveContactInterestHobby: ReadonlyArray<CrmContactInterestHobbyOutput>;
  readonly crmModifyContactInterestsHobbies: Scalars['Boolean'];
  readonly crmModifyContactInterestHobby: ReadonlyArray<CrmContactInterestHobbyOutput>;
  readonly crmSaveModifyMatchingLogicContact: CrmMatchingLogicContactPayload;
  readonly crmCompanyDescriptionSave: CrmCompanyDescriptionOutput;
  readonly crmCompanyDescriptionEdit: Scalars['Boolean'];
  readonly crmDetailedDescriptionSave: CrmDetailedDescriptionOutput;
  readonly crmDetailedDescriptionEdit: Scalars['Boolean'];
  readonly crmDetailedDescriptionDelete: Scalars['Boolean'];
  readonly crmFirmographicsSave: CrmFirmographicsOutput;
  readonly crmFirmographicsEdit: Scalars['Boolean'];
  readonly crmFirmographicsDelete: Scalars['Boolean'];
  readonly crmIndustriesSave: CrmIndustriesOutput;
  readonly crmIndustriesEdit: Scalars['Boolean'];
  readonly crmIndustriesDelete: Scalars['Boolean'];
  /** @deprecated crmInteractionDelete query deprecated */
  readonly crmInteractionDelete: Scalars['Boolean'];
  /** @deprecated crmInteractionSave query deprecated */
  readonly crmInteractionSave: CrmInteractionOutput;
  /** @deprecated crmInteractionObjectiveSave query deprecated */
  readonly crmInteractionObjectiveSave: CrmInteractionObjectiveOutput;
  /** @deprecated crmInteractionRescheduleSave query deprecated */
  readonly crmInteractionRescheduleSave: CrmInteractionRescheduleOutput;
  /** @deprecated crmInteractionAppend query deprecated */
  readonly crmInteractionAppend: Scalars['Boolean'];
  /** @deprecated crmInteractionAssignRepGroupReassign query deprecated */
  readonly crmInteractionAssignRepGroupReassign: Scalars['Boolean'];
  /** @deprecated crmInteractionDocumentSave query deprecated */
  readonly crmInteractionDocumentSave: CrmInteractionDocumentOutput;
  /** @deprecated crmInteractionDocumentDelete query deprecated */
  readonly crmInteractionDocumentDelete: Scalars['Boolean'];
  /** @deprecated crmInteractionDocumentRemove query deprecated */
  readonly crmInteractionDocumentRemove: Scalars['Boolean'];
  readonly crmInteractionDeleteV2: Scalars['Boolean'];
  readonly crmInteractionSaveV2: CrmInteractionOutputV2;
  readonly crmInteractionObjectiveSaveV2: CrmInteractionObjectiveOutputV2;
  readonly crmInteractionRescheduleSaveV2: CrmInteractionRescheduleAuditOutputV2;
  readonly crmInteractionAppendV2: Scalars['Boolean'];
  readonly crmInteractionAssignRepGroupReassignV2: Scalars['Boolean'];
  readonly crmInteractionDocumentSaveV2: CrmInteractionDocumentOutputV2;
  readonly crmInteractionDocumentRemoveV2: Scalars['Boolean'];
  /** Create or Update Interaction  */
  readonly createOrUpdateInteraction: CreateOrUpdateInteractionPayload;
  readonly crmOpportunitySave: CrmOpportunityOutput;
  readonly crmOpportunitySaveV2: CrmOpportunityOutputV2;
  readonly crmOpportunityAppendV2: Scalars['Boolean'];
  readonly acquireLock?: Maybe<Lock>;
  readonly massAcquireLock?: Maybe<ReadonlyArray<MassAcquireLockPayload>>;
  readonly releaseLock: Scalars['Boolean'];
  readonly massReleaseLock?: Maybe<ReadonlyArray<MassReleaseLockPayload>>;
  readonly sendLock?: Maybe<Lock>;
  readonly adminReleaseLock: LockState;
  readonly adminBypassLock?: Maybe<Lock>;
  readonly refreshLock?: Maybe<Lock>;
  readonly massRefreshLock: ReadonlyArray<MassRefreshLockPayload>;
  readonly createOrUpdateCarrierCommitment?: Maybe<CarrierCommitment>;
  readonly createOrUpdateCarrierCarrierRelationship: CreateOrUpdateCarrierCarrierRelationshipPayload;
  readonly deleteCarrierCarrierRelationship: DeleteCarrierCarrierRelationshipPayload;
  readonly createOrUpdateCarrierV2: CreateOrUpdateCarrierPayloadV2;
  readonly createChildrenCarrier: CreateChildrenCarrierPayload;
  readonly createOrUpdateCarrierCertificationV2: CreateOrUpdateCarrierCertificationPayloadV2;
  readonly createOrUpdateCarrierCustomerRelationshipV2: CreateOrUpdateCarrierCustomerRelationshipPayloadV2;
  readonly createOrUpdateCarrierServiceV2: CreateOrUpdateCarrierServicePayloadV2;
  readonly createOrUpdateCarrierSettingV2: CreateOrUpdateCarrierSettingPayloadV2;
  readonly createOrUpdateCarrierReleaseMethod: CreateOrUpdateCarrierReleaseMethodPayload;
  readonly destroyCarrierCertificationV2: DestroyCarrierCertificationPayloadV2;
  readonly destroyCarrierIntrastateStateV2: DestroyCarrierIntrastateStatePayloadV2;
  readonly destroyCarrierIdentifier: DestroyCarrierIdentifierPayload;
  readonly destroyCarrierServiceV2: DestroyCarrierServicePayloadV2;
  readonly destroyCarrierRep: DestroyCarrierRepPayload;
  readonly destroyCarrierReleaseMethod: DestroyCarrierReleaseMethodPayload;
  readonly updateCarrierIntrastateV2: UpdateCarrierIntrastatePayloadV2;
  readonly updateCarrierStandingV2: UpdateCarrierStandingPayloadV2;
  readonly createOrUpdateCarrierAddress: CreateOrUpdateCarrierAddressPayload;
  readonly createOrUpdateCarrierRep: CreateOrUpdateCarrierRepPayload;
  readonly createOrUpdateCarrierIdentifier: CreateOrUpdateCarrierIdentifierPayload;
  readonly createOrUpdateCarrierContact: CreateOrUpdateCarrierContactPayload;
  readonly createOrUpdateCarrierParent: CreateOrUpdateCarrierParentPayload;
  readonly destroyCarrierAddress: DestroyCarrierAddressPayload;
  readonly destroyCarrierContact: DestroyCarrierContactPayload;
  readonly createOrUpdateCarrierGroup: CreateOrUpdateCarrierGroupPayload;
  readonly createOrUpdateCarrierElectronicTracking: CreateOrUpdateCarrierElectronicTrackingPayload;
  readonly destroyCarrierElectronicTracking: DestroyCarrierElectronicTrackingPayload;
  readonly createOrUpdateCarrierInsurance: CreateOrUpdateCarrierInsurancePayload;
  readonly destroyCarrierInsurance: DestroyCarrierInsurancePayload;
  readonly createCarrierMultipleChildren: CreateMultipleChildrenCarriersPayload;
  readonly createTenantChargeTypeConfiguration?: Maybe<TenantChargeTypeConfigurationResponse>;
  readonly createTenantChargeTypeConfigurationV2?: Maybe<TenantChargeTypeConfigurationV2Response>;
  readonly updateTenantChargeTypeConfiguration?: Maybe<TenantChargeTypeConfigurationResponse>;
  readonly updateTenantChargeTypeConfigurationV2?: Maybe<TenantChargeTypeConfigurationV2Response>;
  readonly deleteTenantChargeTypeConfiguration?: Maybe<TenantChargeTypeConfigurationResponse>;
  readonly deleteTenantChargeTypeConfigurationV2?: Maybe<TenantChargeTypeConfigurationV2Response>;
  readonly createPriorityConfiguration?: Maybe<PriorityConfigurationResponse>;
  readonly updatePriorityConfiguration?: Maybe<PriorityConfigurationResponse>;
  readonly deletePriorityConfiguration?: Maybe<PriorityConfigurationResponse>;
  readonly createCarrierChargeTypeConfiguration?: Maybe<CarrierChargeTypeConfigurationResponse>;
  readonly createCarrierChargeTypeConfigurationV2?: Maybe<CarrierChargeTypeConfigurationV2Response>;
  readonly updateCarrierChargeTypeConfiguration?: Maybe<CarrierChargeTypeConfigurationResponse>;
  readonly updateCarrierChargeTypeConfigurationV2?: Maybe<CarrierChargeTypeConfigurationV2Response>;
  readonly deleteCarrierChargeTypeConfiguration?: Maybe<CarrierChargeTypeConfigurationResponse>;
  readonly deleteCarrierChargeTypeConfigurationV2?: Maybe<CarrierChargeTypeConfigurationV2Response>;
  readonly createCustomerChargeTypeConfiguration?: Maybe<CustomerChargeTypeConfigurationResponse>;
  readonly createCustomerChargeTypeConfigurationV2?: Maybe<CustomerChargeTypeConfigurationV2Response>;
  readonly updateCustomerChargeTypeConfiguration?: Maybe<CustomerChargeTypeConfigurationResponse>;
  readonly updateCustomerChargeTypeConfigurationV2?: Maybe<CustomerChargeTypeConfigurationV2Response>;
  readonly deleteCustomerChargeTypeConfiguration?: Maybe<CustomerChargeTypeConfigurationResponse>;
  readonly deleteCustomerChargeTypeConfigurationV2?: Maybe<CustomerChargeTypeConfigurationV2Response>;
  readonly createAccessorialChargeTypeConfiguration?: Maybe<AccessorialChargeTypeConfigurationResponse>;
  readonly updateAccessorialChargeTypeConfiguration?: Maybe<AccessorialChargeTypeConfigurationResponse>;
  readonly deleteAccessorialChargeTypeConfiguration?: Maybe<AccessorialChargeTypeConfigurationResponse>;
  readonly createClientExceptionRecord: ClientExceptionRecord;
  /** @deprecated Use updateClientExceptionRecordV2, input was changed because we're moving from enums to ddts and therefore the type of 'code' changed */
  readonly updateClientExceptionRecord: ClientExceptionRecord;
  readonly updateClientExceptionRecordV2: ClientExceptionRecord;
  readonly upsertClientExceptionRecord: Scalars['Boolean'];
  /** mastery-commissions: createCommissionsGlobalConfiguration - Creates a new global configuration type. */
  readonly createCommissionsGlobalConfiguration?: Maybe<CommissionsGlobalConfiguration>;
  /** mastery-commissions: updateCommissionsGlobalConfiguration - Updates an existing global configuration type. */
  readonly updateCommissionsGlobalConfiguration?: Maybe<CommissionsGlobalConfiguration>;
  /** mastery-commissions: deleteCommissionsGlobalConfiguration - Marks a Global Configuration as Inactive. */
  readonly deleteCommissionsGlobalConfiguration?: Maybe<CommissionsGlobalConfiguration>;
  /** mastery-commissions: createCustomerOverheadConfiguration - Creates a Customer Overhead Configuration. */
  readonly createCommissionsCustomerOverheadConfiguration?: Maybe<CommissionsCustomerOverheadConfiguration>;
  /** mastery-commissions: updateCustomerOverheadConfiguration - Updates a Customer Overhead Configuration. */
  readonly updateCommissionsCustomerOverheadConfiguration?: Maybe<CommissionsCustomerOverheadConfiguration>;
  /** mastery-commissions: deleteCustomerOverheadConfiguration - Marks a Customer Overhead Configuration as Inactive. */
  readonly deleteCommissionsCustomerOverheadConfiguration?: Maybe<CommissionsCustomerOverheadConfiguration>;
  /** mastery-commissions: calculateCommissionsLoadAllocatedMargins - Calculates the Load Allocated Margins for the load id passed in. */
  readonly calculateCommissionsLoadAllocatedMargins?: Maybe<LoadAllocatedMargins>;
  /**
   * mastery-commissions: createEmployeeCommissionConfigurationPlan - Creates a Employee Commission Configuration.
   * @deprecated Use createOrUpdateEmployeeCommissionConfigurationPlan
   */
  readonly createEmployeeCommissionPlan?: Maybe<EmployeeCommissionPlan>;
  /**
   * mastery-commissions: createOrUpdateEmployeeCommissionConfigurationPlan - Updates a Employee Commission Configuration.
   * @deprecated Use createOrUpdateEmployeeCommissionConfigurationPlan
   */
  readonly createOrUpdateEmployeeCommissionConfigurationPlan?: Maybe<EmployeeCommissionPlan>;
  /** mastery-commissions: createEmployeeCommissionPlan - Creates a Employee Commission Plan. */
  readonly createEmployeeCommissionPlanConfiguration?: Maybe<EmployeeCommissionPlanConfiguration>;
  /** mastery-commissions: updateEmployeeCommissionPlan - Updates a Employee Commission Plan. */
  readonly updateEmployeeCommissionPlanConfiguration?: Maybe<EmployeeCommissionPlanConfiguration>;
  /** mastery-commissions: deleteEmployeeCommissionPlan - Marks an Employee Commission Plan as Inactive. */
  readonly deleteEmployeeCommissionPlanConfiguration?: Maybe<EmployeeCommissionPlanConfiguration>;
  /** mastery-commissions: updateEmployeeCommissionPlanHeader - Updates a Employee Commission Plan Header. */
  readonly updateEmployeeCommissionPlanConfigurationHeader?: Maybe<EmployeeCommissionPlanConfiguration>;
  /** mastery-commissions: createLoadCommissionsOverrideConfiguration - Creates a Load Commissions Override Configuration. */
  readonly createLoadCommissionsOverrideConfiguration?: Maybe<LoadCommissionsOverrideConfiguration>;
  /** mastery-commissions: updateLoadCommissionsOverrideConfiguration - Updates a Load Commissions Override Configuration. */
  readonly updateLoadCommissionsOverrideConfiguration?: Maybe<LoadCommissionsOverrideConfiguration>;
  /**
   * mastery-commissions: recalculateSaveCommissions - Recalculates commissions for a load, and saves the results of the calculation.
   * @deprecated We want to remove this eventually - adding while we work on getting kafka triggers set up for automatic calculation.
   */
  readonly recalculateSaveCommissions?: Maybe<Scalars['Boolean']>;
  /** mastery-commissions: setEmployeeAssignmentsForCommissionPlan - Given a commission plan header id, assigns all employees provided to that plan (unless already existing). Removes any assignments to that plan for employees not passed in the list. */
  readonly setEmployeeAssignmentsForCommissionPlan?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly createOrUpdateCostQuote: CarrierQuoteResponse;
  readonly updateCostQuote: CarrierQuoteResponse;
  readonly reserveCreditNoTotalRate: CreditResponse;
  readonly reserveCreditWithTotalRate: CreditResponse;
  readonly reserveCreditWithRateDetails: CreditResponse;
  readonly reserveCreditWithDetails: CreditResponse;
  readonly reserveCreditWithAutomation: CreditResponse;
  readonly releaseCredit: CreditResponse;
  readonly changeCreditCustomerOnOrder?: Maybe<ChangeCreditCustomerOnOrderPayload>;
  readonly applyCreditBypassToOrder: OrderCreditBypassPayload;
  readonly createCreditOrderLevelBypass: OrderCreditBypassPayload;
  /** @deprecated salesInteractionAppend query deprecated */
  readonly salesInteractionAppend: Scalars['Boolean'];
  readonly salesInteractionAppendV2: Scalars['Boolean'];
  /** @deprecated salesInteractionAssignRepGroupReassign query deprecated */
  readonly salesInteractionAssignRepGroupReassign: Scalars['Boolean'];
  readonly salesInteractionAssignRepGroupReassignV2: Scalars['Boolean'];
  /** @deprecated salesInteractionDelete query deprecated */
  readonly salesInteractionDelete: Scalars['Boolean'];
  readonly salesInteractionDeleteV2: Scalars['Boolean'];
  /** @deprecated salesInteractionDocumentDelete query deprecated */
  readonly salesInteractionDocumentDelete: Scalars['Boolean'];
  /** @deprecated salesInteractionDocumentRemove query deprecated */
  readonly salesInteractionDocumentRemove: Scalars['Boolean'];
  readonly salesInteractionDocumentRemoveV2: Scalars['Boolean'];
  /** @deprecated salesInteractionDocumentSave query deprecated */
  readonly salesInteractionDocumentSave: CrmInteractionDocumentOutput;
  readonly salesInteractionDocumentSaveV2: CrmInteractionDocumentOutputV2;
  /** @deprecated salesInteractionObjectiveSave query deprecated */
  readonly salesInteractionObjectiveSave: CrmInteractionObjectiveOutput;
  readonly salesInteractionObjectiveSaveV2: CrmInteractionObjectiveOutputV2;
  /** @deprecated salesInteractionRescheduleSave query deprecated */
  readonly salesInteractionRescheduleSave: CrmInteractionRescheduleOutput;
  readonly salesInteractionRescheduleSaveV2: CrmInteractionRescheduleAuditOutputV2;
  /** @deprecated salesInteractionSave query deprecated */
  readonly salesInteractionSave: CrmInteractionOutput;
  readonly salesInteractionSaveV2: CrmInteractionOutputV2;
  /** Create or Update Interaction  */
  readonly salescreateOrUpdateInteraction: CreateOrUpdateInteractionPayload;
  readonly crmSaveCarrierNote: CrmCarrierNotesOutput;
  readonly crmSaveCustomerNote: CrmCustomerNotesOutput;
  readonly crmModifyCarrierNote: CrmCarrierNotesOutput;
  readonly crmModifyCustomerNote: CrmCustomerNotesOutput;
  readonly crmSaveCarrierDocument: CrmCarrierDocumentsOutput;
  readonly crmSaveCustomerDocument: CrmCustomerDocumentsOutput;
  readonly crmModifyCarrierDocument: CrmCarrierDocumentsOutput;
  readonly crmModifyCustomerDocument: CrmCustomerDocumentsOutput;
  readonly crmCustomerExportNotes: CrmCustomerExportNotesOutput;
  readonly crmCarrierExportNotes: CrmCarrierExportNoteOutput;
  readonly crmCustomerDeleteDocument: Scalars['Boolean'];
  readonly crmCarrierDeleteDocument: Scalars['Boolean'];
  readonly salesOpportunityAppendV2: Scalars['Boolean'];
  readonly salesOpportunityAppendV3: Scalars['Boolean'];
  readonly salesOpportunityDocumentRemoveV2: Scalars['Boolean'];
  readonly salesOpportunityDocumentRemoveV3: Scalars['Boolean'];
  readonly salesOpportunityDocumentSaveV2: CrmOpportunityDocumentOutputV2;
  readonly salesOpportunityDocumentSaveV3: SalesOpportunityDocumentOutputV2;
  readonly salesOpportunitySave: CrmOpportunityOutput;
  readonly salesOpportunitySaveV2: CrmOpportunityOutputV2;
  readonly salesOpportunitySaveV3: SalesOpportunityOutputV2;
  readonly createOrUpdateCustomerCommitment?: Maybe<CustomerCommitment>;
  readonly createOrUpdateCapacityConstraint?: Maybe<CapacityConstraint>;
  readonly createOrUpdateCustomerCommitmentsSettings?: Maybe<CustomerCommitmentsSettings>;
  readonly createOrUpdateManagerMetricsAsync?: Maybe<CustomerCommitmentManagerMetricsResponse>;
  readonly createCustomerStructure: CustomerStructure;
  readonly updateCustomerStructure: CustomerStructure;
  readonly deleteCustomerStructure: Scalars['Boolean'];
  readonly createNoteDefault: NoteDefault;
  readonly deleteNoteDefault: Scalars['Boolean'];
  readonly updateNoteDefault: NoteDefault;
  readonly createCustomerRegion: CustomerRegion;
  readonly updateCustomerRegion: CustomerRegion;
  readonly deleteCustomerRegion: Scalars['Boolean'];
  readonly createCustomerFacility: CustomerFacility;
  readonly updateCustomerFacility: CustomerFacility;
  readonly deleteCustomerFacility: Scalars['Boolean'];
  readonly deleteCustomerFacilityStopDetail: Scalars['Boolean'];
  readonly createCustomerCommodity: CustomerCommodity;
  readonly updateCustomerCommodity: CustomerCommodity;
  readonly deleteCustomerCommodity: Scalars['Boolean'];
  readonly createLoadDefaults: LoadDefaults;
  readonly createLoadDefaultsV2: LoadDefaults;
  readonly updateLoadDefaults: LoadDefaults;
  readonly updateLoadDefaultsV2: LoadDefaults;
  readonly deleteLoadDefaults: Scalars['Boolean'];
  readonly createOrUpdateCustomerRouteDefaults: CustomerRouteDefault;
  readonly createCustomerCustomerRelationship: CustomerCustomerRelationship;
  readonly updateCustomerCustomerRelationship: CustomerCustomerRelationship;
  readonly deleteCustomerCustomerRelationship: Scalars['Boolean'];
  readonly createOrUpdateServiceTolerance: ServiceTolerance;
  readonly deleteServiceTolerance: Scalars['Boolean'];
  readonly createCarrierFacility: CarrierFacility;
  readonly updateCarrierFacility: CarrierFacility;
  readonly deleteCarrierFacility: Scalars['Boolean'];
  readonly createFacilityNoteDefaults: FacilityNoteDefaults;
  readonly updateFacilityNoteDefaults: FacilityNoteDefaults;
  readonly deleteFacilityNoteDefaults: Scalars['Boolean'];
  readonly createFacilityLoadDefaults: FacilityLoadDefaults;
  readonly updateFacilityLoadDefaults: FacilityLoadDefaults;
  readonly deleteFacilityLoadDefaults: Scalars['Boolean'];
  readonly createOrUpdateFacilityFacilityRelationship: FacilityFacility;
  readonly deleteFacilityFacilityRelationship: Scalars['Boolean'];
  readonly createOrUpdateCustomerGroupV2: CreateOrUpdateCustomerGroupPayloadV2;
  readonly createOrUpdateCustomerAddress: CreateOrUpdateCustomerAddressPayload;
  readonly createOrUpdateCustomerV2: CreateOrUpdateCustomerPayloadV2;
  readonly createChildrenCustomer: CreateChildrenCustomerPayload;
  readonly createCustomerMultipleChildren: CreateMultipleChildrenCustomersPayload;
  readonly createOrUpdateCustomerSettingV2?: Maybe<CreateOrUpdateCustomerSettingPayloadV2>;
  readonly createCreditHistoryV2: CreateCreditHistoryPayloadV2;
  readonly createOrUpdateCustomerContact: CreateOrUpdateCustomerContactPayload;
  readonly createCustomerCreditProviderV2?: Maybe<CreateCustomerCreditProviderPayloadV2>;
  readonly createOrUpdateCustomerRep: CreateOrUpdateCustomerRepPayload;
  readonly createOrUpdateCustomerIdentifier: CreateOrUpdateCustomerIdentifierPayload;
  readonly createOrUpdateCustomerLinesOfBusiness: CreateOrUpdateLinesOfBusinessPayload;
  readonly destroyCustomerAddress: DestroyCustomerAddressPayload;
  readonly destroyCustomerContact: DestroyCustomerContactPayload;
  readonly destroyCustomerRep: DestroyCustomerRepPayload;
  readonly destroyCustomerIdentifier: DestroyCustomerIdentifierPayload;
  readonly deleteCustomerLinesOfBusiness: DeleteLinesOfBusinessPayload;
  /** updateCustomerDriverLocationFrequencyConfiguration: create or update driver location frequency configuration, per customer in Mastermind. */
  readonly updateCustomerDriverLocationFrequencyConfiguration?: Maybe<DriverLocationFrequency>;
  /** updateCustomerOutbound214Frequency: create or update driver location frequency configuration, per customer in Mastermind. */
  readonly updateCustomerOutbound214Frequency?: Maybe<CustomerOutbound214Frequency>;
  /** createOutbound214EmailAddress: adds email addresses to a customer's Outbound 214 list in Data Exchange by their customer id and ruleset id. */
  readonly createOutbound214EmailAddresses?: Maybe<ReadonlyArray<Maybe<Outbound214EmailAddress>>>;
  /** deleteOutbound214EmailAddress: removes an email address from a customer's Outbound 214 list in Data Exchange by their customer id and ruleset id. */
  readonly deleteOutbound214EmailAddress?: Maybe<ReadonlyArray<Maybe<Outbound214EmailAddress>>>;
  /** updateCarrierEdiTransactionConfiguration: create or update a carrier's edi transaction configuration. */
  readonly updateCarrierEdiTransactionConfiguration: CarrierEdiTransactionConfiguration;
  /** updateCustomerEdiTransactionConfiguration: create or update a customer's edi transaction configuration. */
  readonly updateCustomerEdiTransactionConfiguration: CustomerEdiTransactionConfiguration;
  /** createOrUpdateFieldMapping: create or update field mapping configuration. */
  readonly createOrUpdateFieldMapping: FieldMappingConfigurationOutputType;
  /** @deprecated completeUpload mutation is deprecated. */
  readonly completeUpload?: Maybe<CompleteUploadPayload>;
  /** @deprecated completeUploadV2 mutation is deprecated. */
  readonly completeUploadV2?: Maybe<CompleteUploadPayload>;
  readonly deleteFacilityDocument: DeleteFacilityDocumentPayload;
  readonly deleteCarrierDocument: DeleteCarrierDocumentPayload;
  readonly updateLoadDocumentDetails: UpdateLoadDocDetailsPayload;
  readonly updateEDIDocumentName: UpdateEdiDocumentNamePayload;
  readonly deleteCustomerDocument: DeleteCustomerDocumentPayload;
  readonly updateCRMDocumentName: UpdateCrmNamePayload;
  /** Creates a new HOS event for a driver */
  readonly createHosEvent: CreateDriverHosPayload;
  /** Creates a new HOS event for a driver */
  readonly hosEventCorrection: DriverHosCorrectionPayload;
  /**
   * Sends a driver message
   * @deprecated Use sendDriverMessageV2 instead
   */
  readonly sendDriverMessage?: Maybe<SendDriverConversationInputResponse>;
  /** Sends a driver message V2 */
  readonly sendDriverMessageV2?: Maybe<SendDriverMessageV2Response>;
  /**
   * Returns a driver conversation and marks all messages as read by a MM User
   * @deprecated Use readDriverConversationMessages instead
   */
  readonly readDriverConversation?: Maybe<ReadDriverConversationResponse>;
  /**
   * Returns a paginated list of driver messages from a conversation and
   * optionally marks all messages as read by a MM User.
   * provided, the query will return ...
   */
  readonly readDriverConversationMessages?: Maybe<ReadDriverMessagesConnection>;
  /**
   * Creates a follow up time for a conversation
   * @deprecated Use setFollowUpTimeV2 instead
   */
  readonly setFollowUpTime?: Maybe<CreateFollowUpTimeResponse>;
  /** Creates a follow up time for a conversation V2 */
  readonly setFollowUpTimeV2?: Maybe<CreateFollowUpTimeV2Response>;
  /**
   * Deletes a follow up time for a conversation
   * @deprecated Use removeFollowUpTimeV2 instead
   */
  readonly removeFollowUpTime?: Maybe<DeleteFollowUpTimeResponse>;
  /** Deletes a follow up time for a conversation V2 */
  readonly removeFollowUpTimeV2?: Maybe<DeleteFollowUpTimeResponse>;
  /** Sends a NRBF Driver Message */
  readonly sendNRBFDriverMessage?: Maybe<SendDriverConversationInputResponse>;
  /** Create or Update or Delete Driver Schedule */
  readonly createOrUpdateDriverSchedule: AssetDriverSchedulePayload;
  readonly deleteDriverSchedule: Scalars['Boolean'];
  /** Create or Update or Delete Driver Address */
  readonly createOrUpdateDriverAddress: AssetDriverAddressPayload;
  readonly deleteDriverAddress: Scalars['Boolean'];
  /** Create or Update or Delete Driver TimeOff */
  readonly createOrUpdateDriverTimeOff: AssetDriverTimeOffPayload;
  readonly deleteDriverTimeOff: Scalars['Boolean'];
  /** Create or Update or Delete driver contact */
  readonly createOrUpdateDriverContact: AssetDriverContactPayload;
  readonly deleteDriverContact: Scalars['Boolean'];
  /** Create or Update or Delete Driver Award */
  readonly createOrUpdateDriverAward: AssetDriverAwardPayload;
  readonly deleteDriverAward: Scalars['Boolean'];
  /** Create or Update or Delete Driver certificationOrPermit */
  readonly createOrUpdateDriverCertificationOrPermit: AssetDriverCertificationOrPermitPayload;
  readonly deleteCertificationOrPermit: Scalars['Boolean'];
  /** Create or Update or Delete Driver Identifier */
  readonly createOrUpdateDriverIdentifier: AssetDriverIdentifierPayload;
  readonly deleteDriverIdentifier: Scalars['Boolean'];
  /** Create or Update or Delete Driver Equipment */
  readonly createOrUpdateDriverEquipment: AssetDriverEquipmentPayload;
  readonly deleteDriverEquipment: Scalars['Boolean'];
  /** Create Routing Request */
  readonly createOrUpdateDriverRoutingRequest: AssetDriverRoutingRequestPayload;
  /** Patch an existing routing request */
  readonly patchDriverRoutingRequest: AssetDriverRoutingRequestPayload;
  readonly createDriver: AssetDriver;
  readonly deleteDriver: Scalars['Boolean'];
  readonly updateDriver: Scalars['Boolean'];
  readonly updateDriverPower: Scalars['Boolean'];
  readonly updateDriverTrailer: Scalars['Boolean'];
  readonly updateDriverOperations: Scalars['Boolean'];
  readonly updateDriverPreferences: Scalars['Boolean'];
  readonly deleteDriverFacilityPreferences: Scalars['Boolean'];
  readonly deleteDriverCustomerPreferences: Scalars['Boolean'];
  readonly saveDriverFacilityPreferences: DriverFacilityPreferences;
  readonly saveDriverCustomerPreferences: DriverCustomerPreferences;
  readonly updateDriverFacilityPreferences: Scalars['Boolean'];
  readonly updateDriverCustomerPreferences: Scalars['Boolean'];
  readonly saveDriverPreferredLanes: DriverPreferredLanes;
  readonly updateDriverPreferredLanes: Scalars['Boolean'];
  readonly saveDriverPreferredRoutes: DriverPreferredRoutes;
  readonly updateDriverPreferredRoutesById: Scalars['Boolean'];
  readonly deleteDriverPreferredLanes: Scalars['Boolean'];
  readonly deleteDriverPreferredRoutes: Scalars['Boolean'];
  /** Create or Update Driver  */
  readonly createOrUpdateDriver: CreateOrUpdateDriverPayload;
  readonly deleteDriverWeeklyTarget: Scalars['Boolean'];
  /** Create or Update Driver Weekly Target */
  readonly createOrUpdateDriverWeeklyTarget: CreateOrUpdateDriverWeeklyTargetPayload;
  /** Create or Update Driver Training */
  readonly createOrUpdateDriverTraining: CreateOrUpdateDriverTraningPayload;
  readonly deleteTraining: Scalars['Boolean'];
  readonly createOrUpdateDriverFleetRelationship: CreateOrUpdateDriverFleetPayload;
  readonly deleteDriverFleet: Scalars['Boolean'];
  readonly deleteDriverRepresentative: Scalars['Boolean'];
  /** Create or Update Driver Representative */
  readonly createOrUpdateDriverRepresentative: CreateOrUpdateDriverRepresentativePayload;
  readonly createOrUpdateDriverFleetsRelationship: CreateOrUpdateDriverFleetPayload;
  /** Full Patch Driver  */
  readonly driverPatch: CreateOrUpdateDriverPayload;
  /** Create or Update Driver V2 */
  readonly createOrUpdateDriverV2: CreateOrUpdateDriverPayloadV2;
  /** Create or Update Driver TeamPreferences */
  readonly createOrUpdateDriverTeamPreferences: AssetDriverTeamPreferencePayload;
  readonly deleteDriverTeamPreference: Scalars['Boolean'];
  /** Create or Update Driver CareerGoalsPreference */
  readonly createOrUpdateDriverCareerGoalsPreference: AssetDriverCareerGoalsPreferencePayload;
  readonly deleteDriverCareerGoalsPreference: Scalars['Boolean'];
  /** Create or Update Driver GeographyPreference */
  readonly createOrUpdateDriverGeographyPreference: AssetDriverGeographyPreferencePayload;
  readonly deleteDriverGeographyPreference: Scalars['Boolean'];
  /** Create or Update Driver GeographyPreference */
  readonly createOrUpdateAssetDriverGeographyPreference: DriverGeographyPreferencePayload;
  /** Create or Update Driver Operational Preferences */
  readonly createOrUpdateDriverOperationalPreference: AssetDriverOperationalPreferencePayload;
  readonly deleteDriverOperationalPreference: Scalars['Boolean'];
  /** Create or Update Driver CommodityPreference */
  readonly createOrUpdateDriverCommodityPreference: AssetDriverCommodityPreferencePayload;
  readonly deleteDriverCommodityPreference: Scalars['Boolean'];
  /** Create or Update Driver CustomerPreference */
  readonly createOrUpdateDriverCustomerPreference: AssetDriverCustomerPreferencePayload;
  readonly deleteDriverCustomerPreference: Scalars['Boolean'];
  /** Create or Update Driver FacilityPreference */
  readonly createOrUpdateDriverFacilityPreference: AssetDriverFacilityPreferencePayload;
  readonly deleteDriverFacilityPreference: Scalars['Boolean'];
  /** Create or Update list of Driver Preferred Lanes */
  readonly createOrUpdateDriverPreferredLanes: CreateOrUpdateDriverPreferredLanesPayload;
  readonly deleteDriverPayAttribute: Scalars['Boolean'];
  readonly disableDriverPayAttributeDefinition: AttributeDefinitionModel;
  readonly enableDriverPayAttributeDefinition: AttributeDefinitionModel;
  readonly createDriverPayAttributes: ReadonlyArray<DriverAttributeModel>;
  readonly updateDriverPayAttributes: ReadonlyArray<DriverAttributeModel>;
  readonly saveDriverPayAttributeDefinitions: ReadonlyArray<AttributeDefinitionModel>;
  readonly createDriverPayProgramCarrierAssociations: ReadonlyArray<PayProgramCarrierAssociationModel>;
  readonly updateDriverPayProgramCarrierAssociations: ReadonlyArray<PayProgramCarrierAssociationModel>;
  readonly deleteDriverPayProgramCarrierAssociation: DeleteDriverPayProgramCarrierAssociationResult;
  readonly createDriverPayProgramDriverAssociations: ReadonlyArray<PayProgramDriverAssociationModel>;
  readonly updateDriverPayProgramDriverAssociations: ReadonlyArray<PayProgramDriverAssociationModel>;
  readonly deleteDriverPayProgramDriverAssociation: DeleteDriverPayProgramDriverAssociationResult;
  readonly createDriverPayPrograms: ReadonlyArray<PayProgramModel>;
  readonly updateDriverPayPrograms: ReadonlyArray<PayProgramModel>;
  readonly enableDriverPayProgram: PayProgramModel;
  readonly disableDriverPayProgram: PayProgramModel;
  readonly deleteDriverPayRateTable: DeleteDriverPayRateTableResult;
  readonly createDriverPayRateTableHeaders: ReadonlyArray<PayRateTableHeaderModel>;
  readonly updateDriverPayRateTableHeaders: ReadonlyArray<UpdateDriverPayRateTableHeaderResult>;
  readonly notifyDriverPayRateTableUploaded: PayRateTableHeaderModel;
  readonly setDriverPayProgramRouteAssignments: ReadonlyArray<DriverPayProgramRouteAssignmentModel>;
  readonly createOrUpdateEmployeeV2: CreateOrUpdateEmployeePayloadV2;
  readonly createOrUpdateEmployeeExternalV2: CreateOrUpdateEmployeeExternalPayloadV2;
  readonly removeEmployeeMessagingUserIdentities: RemoveEmployeeMessagingUserIdentitiesPayload;
  /** Do not use it, ONLY to be used by development team for deleting cache if required. */
  readonly deleteEmployeeCache?: Maybe<Scalars['Boolean']>;
  readonly createOrUpdatePostingCredentials: EmployeePostingCredentials;
  readonly removePostingCredentials: Scalars['Boolean'];
  readonly updateMileageTenantConfiguration?: Maybe<TenantConfigurationResult>;
  readonly updateMileageDefaultConfiguration?: Maybe<DefaultConfigurationResult>;
  readonly createMileageEntityConfiguration?: Maybe<EntityConfigurationResult>;
  readonly updateMileageEntityConfiguration?: Maybe<EntityConfigurationResult>;
  readonly deleteMileageEntityConfiguration?: Maybe<Scalars['Boolean']>;
  /** Sets a single string value for a given key */
  readonly setEntityString?: Maybe<Scalars['Boolean']>;
  /**
   * Sets a geofencing location provided location data
   * @deprecated Use setEntityGeofencingCustomerRecord or setEntityGeofencingLocationRecord instead
   */
  readonly setGeofencingLocation?: Maybe<EntityGeofencingLocation>;
  /**
   * Sets a geofencing location provided location data
   * @deprecated Use setEntityGeofencingCustomerRecord or setEntityGeofencingLocationRecord instead
   */
  readonly setGeofencingLocationV2?: Maybe<EntityGeofencingLocationV2>;
  /**
   * Deletes a geofencing location provided location data
   * @deprecated Use deleteEntityGeofencingCustomerRecord or deleteEntityGeofencingLocationRecord instead
   */
  readonly deleteGeofencingLocation?: Maybe<Scalars['String']>;
  /** Sets attributes on a customer geofence record */
  readonly setEntityGeofencingCustomerRecord?: Maybe<EntityGeofencingCustomerRecord>;
  /** Sets attributes on a location geofence record */
  readonly setEntityGeofencingLocationRecord?: Maybe<EntityGeofencingLocationRecord>;
  /** Deletes a geofencing customer record */
  readonly deleteEntityGeofencingCustomerRecord?: Maybe<Scalars['String']>;
  /** Deletes a geofencing location record */
  readonly deleteEntityGeofencingLocationRecord?: Maybe<Scalars['String']>;
  readonly createOrUpdateFacilityV2: CreateOrUpdateFacilityPayloadV2;
  readonly createOrUpdateFacilitySchedule: CreateOrUpdateFacilitySchedulePayload;
  readonly findOrCreateFacilityV2: FindOrCreateFacilityPayloadV2;
  readonly findOrCreateFacilityV3: FindOrCreateFacilityPayloadV2;
  readonly createFacilityToImport: CreateFacilityToImportPayload;
  readonly createOrUpdateFacilityIdentifierV2: CreateOrUpdateFacilityIdentifierPayloadV2;
  readonly destroyFacilityIdentifierV2: DestroyFacilityIdentifierPayloadV2;
  readonly destroyFacilityContact: DestroyFacilityContactPayload;
  readonly createOrUpdateFacilityContact: CreateOrUpdateFacilityContactPayload;
  readonly createOrUpdateFacilityDirection: CreateOrUpdateFacilityDirectionPayload;
  readonly createOrUpdateFacilityHoliday: CreateOrUpdateFacilityHolidayPayload;
  readonly createOrUpdateFacilityAddress: CreateOrUpdateFacilityAddressPayload;
  readonly destroyFacilityAddress: DestroyFacilityAddressPayload;
  readonly destroyFacilitySchedule: DestroyFacilitySchedulePayload;
  readonly destroyFacilityDirection: DestroyFacilityDirectionPayload;
  readonly destroyFacilityHoliday: DestroyFacilityHolidayPayload;
  readonly destroyFacilityRep: DestroyFacilityRepPayload;
  readonly createOrUpdateCustomerFacilityRelationshipV2: CreateOrUpdateCustomerFacilityRelationshipPayloadV2;
  readonly createOrUpdateFacilityRep: CreateOrUpdateFacilityRepPayload;
  readonly resetSublocationApiDataDictionaryCache?: Maybe<ResetSublocationApiDdtCachePayload>;
  /** Create or Update Facility Sublocation Activity */
  readonly createOrUpdateFacilitySublocationActivity: CreateOrUpdateFacilitySublocationActivityPayload;
  /** Destroy Facility Sublocation Activity */
  readonly destroyFacilitySublocationActivity: DestroyFacilitySublocationActivityPayload;
  /** Create or Update Facility Sublocation Accommodation */
  readonly createOrUpdateFacilitySublocationAccommodation: CreateOrUpdateFacilitySublocationAccommodationPayload;
  /** Destroy Facility Sublocation Accommodation */
  readonly destroyFacilitySublocationAccommodation: DestroyFacilitySublocationAccommodationPayload;
  /** Create or Update Facility Sublocation */
  readonly createOrUpdateFacilitySublocation: CreateOrUpdateFacilitySublocationPayload;
  /** Destroy Facility Sublocation */
  readonly destroyFacilitySublocation: DestroyFacilitySublocationPayload;
  /** Create or Update Facility Sublocation Gate */
  readonly createOrUpdateFacilitySublocationGate: CreateOrUpdateFacilitySublocationGatePayload;
  /** Destroy Facility Sublocation Gate */
  readonly destroyFacilitySublocationGate: DestroyFacilitySublocationGatePayload;
  readonly saveMasterbidUploadEvent: MasterBidUploadEventPayload;
  readonly savePricingProgramUploadEvent: PricingProgramUploadEventPayload;
  readonly saveRateQuoteUploadEvent: RateQuoteUploadEventPayload;
  readonly saveRoutingGuideUploadEvent: RoutingGuideUploadEventPayload;
  readonly saveServiceProfileUploadEvent: ServiceProfileUploadEventPayload;
  readonly saveDriverPayRateUploadEvent: UploadEventPayload;
  readonly saveUploadEvent: UploadEventPayload;
  readonly insertFuelProgramCustomerConfiguration?: Maybe<FuelProgramCustomerConfigurationModel>;
  readonly updateFuelProgramCustomerConfiguration?: Maybe<FuelProgramCustomerConfigurationModel>;
  readonly deleteFuelProgramCustomerConfiguration?: Maybe<FuelProgramCustomerConfigurationModel>;
  readonly insertHistoricalFuelIndexPrice?: Maybe<HistoricalFuelIndexPriceModel>;
  readonly updateHistoricalFuelIndexPrice?: Maybe<HistoricalFuelIndexPriceModel>;
  readonly updateHistoricalFuelIndexAveragePriceOverride?: Maybe<HistoricalFuelIndexPriceModel>;
  readonly deleteHistoricalFuelIndexPrice?: Maybe<HistoricalFuelIndexPriceModel>;
  readonly insertCustomerFuelRateHeader?: Maybe<CustomerFuelRateHeaderModel>;
  readonly insertCustomerFuelRange?: Maybe<CustomerFuelRangeModel>;
  readonly updateCustomerFuelRange?: Maybe<CustomerFuelRangeModel>;
  readonly deleteCustomerFuelRange?: Maybe<CustomerFuelRangeModel>;
  readonly bulkInsertCustomerFuelRange?: Maybe<Scalars['Boolean']>;
  readonly importCustomerFuelRanges?: Maybe<Scalars['Boolean']>;
  readonly insertFuelProgramCarrierConfiguration?: Maybe<FuelProgramCarrierConfigurationModel>;
  readonly updateFuelProgramCarrierConfiguration?: Maybe<FuelProgramCarrierConfigurationModel>;
  readonly insertCarrierFuelRateHeader?: Maybe<CarrierFuelRateHeaderModel>;
  readonly deleteFuelProgramCarrierConfiguration?: Maybe<FuelProgramCarrierConfigurationModel>;
  readonly resequenceFuelProgramCarrierConfiguration?: Maybe<ResequenceCarrierFuelProgramConfigurationOutput>;
  readonly resequenceFuelProgramCustomerConfiguration?: Maybe<ResequenceCustomerFuelProgramConfigurationResponse>;
  readonly insertCarrierFuelRange?: Maybe<CarrierFuelRangeModel>;
  readonly updateCarrierFuelRange?: Maybe<CarrierFuelRangeModel>;
  readonly deleteCarrierFuelRange?: Maybe<CarrierFuelRangeModel>;
  readonly bulkInsertCarrierFuelRange?: Maybe<BulkInsertCarrierFuelRangeResponse>;
  readonly importCarrierFuelRanges?: Maybe<Scalars['Boolean']>;
  readonly addPreferredName?: Maybe<GeographyServiceRecord>;
  readonly flushGeographiesCache?: Maybe<CacheFlushResponse>;
  readonly updateGeographyRecord?: Maybe<GeographyServiceRecord>;
  readonly copyFromGeonames: CacheFlushResponse;
  readonly importLocalityFromGeonames: CacheFlushResponse;
  /** Creates a test event. For Testing Purposes Only. */
  readonly produceTestEvent: TestEventMetadata;
  /** Create or Update group */
  readonly createOrUpdateGroup: CreateOrUpdateGroupPayload;
  /** Rule Sets will be updated by matching the name with existing rule sets. */
  readonly updateRuleSets?: Maybe<UpdateRuleSetsResult>;
  readonly updateTemplates?: Maybe<UpdateTemplatesResult>;
  readonly updateHaykEvent?: Maybe<UpdateHaykEventResult>;
  readonly createHaykEvent?: Maybe<CreateHaykEventResult>;
  readonly createHaykRedirect?: Maybe<HaykRedirectResult>;
  readonly updateHaykRedirect?: Maybe<HaykRedirectResult>;
  readonly deleteHaykRedirect?: Maybe<HaykRedirectResult>;
  readonly createHaykNotifications?: Maybe<HaykNotificationResponses>;
  readonly createPlatformMessage?: Maybe<HaykNotificationResponses>;
  readonly createHaykAuditHistory?: Maybe<AuditHistory>;
  readonly updateHaykFieldMappings?: Maybe<HaykFieldMappingsResult>;
  readonly deleteRuleSet?: Maybe<DeleteRuleSetResult>;
  readonly toggleRuleSetIsActive?: Maybe<ToggleRuleSetIsActiveResult>;
  readonly toggleEventEnabled?: Maybe<ToggleEventEnabledResult>;
  readonly syncHdsTemplate?: Maybe<SyncHdsTemplateResult>;
  readonly syncHdsRuleSet?: Maybe<SyncHdsRuleSetResult>;
  readonly updateServiceProfile: ServiceProfile;
  readonly createServiceProfile: ServiceProfile;
  readonly deleteServiceProfile: Scalars['Boolean'];
  readonly updateServiceProfileV2: ServiceProfileV2;
  readonly createServiceProfileV2: ServiceProfileV2;
  readonly createIncidentV2: IncidentV2;
  readonly updateIncidentV2: IncidentV2;
  readonly closeMultipleIncidentsV2: CloseMultipleIncidentV2;
  /** Creates a load flip request for the provided Load ID. A load flip reruns a load through LSE with fresh GQL data. */
  readonly flipLoad: LoadFlipResponse;
  /** Creates a load flip request for every route in a load for all provided loads. A load flip reruns a load through LSE with fresh GQL data. */
  readonly flipLoads: ReadonlyArray<LoadFlipResponse>;
  /** mastery-lane: deactivateCustomerLane - Deactivate a lane by customer id. */
  readonly deactivateCustomerLane?: Maybe<DeactivateCustomerLaneResponse>;
  /** mastery-lane: reactivateCustomerLane - Reactivate a lane by customer id. */
  readonly reactivateCustomerLane?: Maybe<ReactivateCustomerLaneResponse>;
  /** mastery-lane: reactivateCarrierLane - Reactivate a lane by carrier id. */
  readonly reactivateCarrierLane?: Maybe<ReactivateCarrierLaneResponse>;
  /** mastery-lane: deactivateCarrierLane - Deactivate a lane by carrier id. */
  readonly deactivateCarrierLane?: Maybe<DeactivateCarrierLaneResponse>;
  /** mastery-lane: addCustomerLane - Add an association from customer to lane. */
  readonly addCustomerLane?: Maybe<AddCustomerLaneResponse>;
  /** mastery-lane: createLaneV4 - Create a lane. */
  readonly createLaneV4?: Maybe<LaneV2>;
  /** Create linked route */
  readonly createLinkedRouteV3: CreateLinkedRoutePayload;
  /** Delete linked route */
  readonly deleteLinkedRouteV3: DeleteLinkedRoutePayload;
  /** Create linked route */
  readonly createLinkedRouteV2: LinkedRouteV2;
  /** Delete linked route */
  readonly deleteLinkedRouteV2?: Maybe<LinkedRouteV2>;
  readonly addressStatusUpdate?: Maybe<ReadonlyArray<Maybe<AddressCleanseStatus>>>;
  readonly flushLocationServicesCache?: Maybe<CacheFlushResponse>;
  readonly insertCustomerMarkup: Scalars['String'];
  readonly updateCustomerMarkup: Scalars['String'];
  readonly insertCarrierPreference: Scalars['String'];
  readonly updateCarrierPreference: Scalars['String'];
  readonly deleteCarrierPricingProgram?: Maybe<PricingProgramV2>;
  readonly deleteCustomerPricingProgram?: Maybe<PricingProgramV2>;
  readonly addBid?: Maybe<BidPayload>;
  readonly addBidStaging?: Maybe<BidStagingPayload>;
  readonly cancelBid?: Maybe<BidPayload>;
  readonly updateBid?: Maybe<BidPayload>;
  readonly acceptBid?: Maybe<BidPayload>;
  readonly rejectBid?: Maybe<BidPayload>;
  readonly updateRound?: Maybe<RoundPayload>;
  readonly assignRegionToBidLanes?: Maybe<BidLaneAssignRegionPayload>;
  readonly addBidQuote?: Maybe<BidQuotePayload>;
  readonly deleteBidQuote?: Maybe<DeleteBidQuotePayload>;
  readonly assignUsersToBidLanes?: Maybe<AssignUsersToBidLanesPayload>;
  readonly rejectBidLanes?: Maybe<BidLaneStatusPayload>;
  readonly submitRound?: Maybe<RoundSubmitStatusPayload>;
  readonly closeRound?: Maybe<CloseRoundPayload>;
  readonly validateImportAward?: Maybe<ImportAwardPayload>;
  readonly addImportAwardsStaging?: Maybe<ImportAwardsStagingPayload>;
  readonly flushMileageCache?: Maybe<MileageFlushResponse>;
  /**
   * Mutation to sync db
   * @deprecated Use driverMobileAgendaSync instead
   */
  readonly agendaSync: AgendaSyncPayload;
  readonly driverMobileAgendaSync: AgendaSyncPayload;
  /**
   * Mutation to dispatch the driver
   * @deprecated Use dispatchDriverMobileV2 instead
   */
  readonly dispatchDriverMobile: DispatchDriverMobilePayload;
  readonly dispatchDriverMobileV2: DispatchDriverMobilePayloadV2;
  /**
   * Mutation to update stop event like time-in or time-out
   * @deprecated Use updateDriverStopEventV2 instead
   */
  readonly updateDriverStopEvent: UpdateDriverStopEventPayload;
  /** @deprecated Use updateDriverMobileStopEvent instead */
  readonly updateDriverStopEventV2: UpdateDriverStopEventPayloadV2;
  readonly updateDriverMobileStopEvent: UpdateDriverStopEventPayloadV2;
  /**
   * Mutation to update driver commodities
   * @deprecated Use updateDriverCommoditiesV2 instead
   */
  readonly updateDriverCommodities: UpdateDriverCommoditiesPayload;
  /** @deprecated Use updateDriverMobileCommodities instead */
  readonly updateDriverCommoditiesV2: UpdateDriverCommoditiesPayloadV2;
  readonly updateDriverMobileCommodities: UpdateDriverCommoditiesPayloadV2;
  /**
   * Mutation to complete stop
   * @deprecated Use completeStopV2 instead
   */
  readonly completeStop: CompleteStopPayload;
  /** @deprecated Use completeDriverMobileStop instead */
  readonly completeStopV2: CompleteStopPayloadV2;
  readonly completeDriverMobileStop: CompleteStopPayloadV2;
  /**
   * Mutation to register device
   * @deprecated Use registerDriverMobileDevice instead
   */
  readonly registerDevice: RegisterDevicePayload;
  readonly registerDriverMobileDevice: RegisterDevicePayload;
  /**
   * Mutation to remove device
   * @deprecated Use removeDriverMobileDevice instead
   */
  readonly removeDevice: RemoveDevicePayload;
  readonly removeDriverMobileDevice: RemoveDevicePayload;
  /**
   * Mutation to update driver details
   * @deprecated Use updateDriverMobileDetailsV2 instead
   */
  readonly updateDriverMobileDetails: MobileDriverDetailsPayload;
  readonly updateDriverMobileDetailsV2: MobileDriverDetailsPayloadV2;
  /** Mutation to update driver invitation audit */
  readonly updateDriverMobileInvitationAudit: UpdateDriverMobileInvitationAuditPayload;
  /**
   * Mutation to send ExternalAccess Driver Audit
   * @deprecated Use sendExternalAccessDriverMobileAuditV2 instead
   */
  readonly sendExternalAccessDriverMobileAudit: SendExternalAccessDriverMobileAuditPayload;
  readonly sendExternalAccessDriverMobileAuditV2: SendExternalAccessDriverMobileAuditPayloadV2;
  /**
   * Mutation to create tracking update
   * @deprecated Please update to createTrackingUpdateV2!
   */
  readonly createTrackingUpdate: CreateTrackingUpdatePayload;
  /** @deprecated Please update to createDriverMobileTrackingUpdate! */
  readonly createTrackingUpdateV2: CreateTrackingUpdateV2Payload;
  readonly createDriverMobileTrackingUpdate: CreateTrackingUpdateV2Payload;
  readonly createOffer: Offer;
  readonly updateOffer: Offer;
  readonly deleteOffer: Scalars['Boolean'];
  readonly updateOffersCarrier: UpdateOffersCarrierResponse;
  readonly updateOffersRoute: UpdateOffersRouteResponse;
  readonly updateOffersDriver: UpdateOffersDriverResponse;
  readonly acceptOffer: OfferResponse;
  readonly counterOffer: OfferResponse;
  readonly declineOffer: OfferResponse;
  readonly updateMultipleOfferResponse: UpdateMultipleOfferResponsePayload;
  /** Create or update an OptiMatch session */
  readonly updateOptiMatchSession?: Maybe<OptiMatchSession>;
  /** Synchronously runs OptiMatch algorithm and returns suggested matches */
  readonly generateOptiMatchSolution?: Maybe<OptiMatchSolution>;
  /** Assign drivers to routes, "locking in" planned pairs for solutions to be generated in the future */
  readonly updateOptiMatchPlannedMatches?: Maybe<OptiMatchSession>;
  /** Reject pairs of drivers and routes, excluding those pairs from solutions to be generated in the future */
  readonly updateOptiMatchRejectedMatches?: Maybe<OptiMatchSession>;
  /**
   * Set some drivers or routes available/unavailable for matching due to an external event, e.g. capacity
   * has been utilized or routes have been cancelled.
   */
  readonly setOptiMatchInputAvailability?: Maybe<OptiMatchSession>;
  /** Creates a custom objective which can be utilized for calculating optimal matches */
  readonly createOrUpdateObjective?: Maybe<OptiMatchCustomObjective>;
  /** Deletes a custom objective */
  readonly deleteObjective?: Maybe<Scalars['Boolean']>;
  readonly createPlannedNetwork: CreatePlannedNetworkPayload;
  readonly updatePlannedNetwork: UpdatePlannedNetworkPayload;
  readonly createNetworkChannel: CreateNetworkChannelPayload;
  readonly updateNetworkChannel: UpdateNetworkChannelPayload;
  readonly createChannelLeg: CreateChannelLegPayload;
  readonly updateChannelLeg: UpdateChannelLegPayload;
  /** Deactivates a planned network and all of its channels and legs. */
  readonly deactivatePlannedNetwork: DeactivatePlannedNetworkPayload;
  /** Activates a planned network. Does not activate channels or legs. */
  readonly activatePlannedNetwork: ActivatePlannedNetworkPayload;
  /** Deactivates a network channel and all of its legs. */
  readonly deactivateNetworkChannel: DeactivateNetworkChannelPayload;
  /** Activates a network channel. Does not activate legs. */
  readonly activateNetworkChannel: ActivateNetworkChannelPayload;
  /** Deactivates a channel leg. */
  readonly deactivateChannelLeg: DeactivateChannelLegPayload;
  /** Activates a channel leg. */
  readonly activateChannelLeg: ActivateChannelLegPayload;
  readonly createPosting: CreatePostingPayload;
  readonly updatePosting: UpdatePostingPayload;
  readonly removePosting: RemovePostingPayload;
  readonly createPostings: CreatePostingsPayload;
  readonly removePostings: RemovePostingsPayload;
  readonly removePostingsByRouteIds: RemovePostingsPayload;
  /** Create or Update or Delete Identifier */
  readonly assetPowerIdentifier: AssetPowerIdentifierPayload;
  readonly deletePowerIdentifier: Scalars['Boolean'];
  /** Create or Update or Delete Fleet */
  readonly assetPowerFleet: AssetPowerFleetPayload;
  readonly deletePowerFleets: Scalars['Boolean'];
  /** Create or Update or Delete Maintenance */
  readonly assetPowerMaintenance: AssetPowerMaintenancePayload;
  readonly deletePowerMaintenance: Scalars['Boolean'];
  /** Create or Update or Delete Equipment */
  readonly assetPowerEquipment: AssetPowerEquipmentPayload;
  readonly deletePowerEquipment: Scalars['Boolean'];
  readonly saveAssetPower: AssetPowerOutput;
  /** @deprecated deleteAssetPower mutation deprecated. */
  readonly deleteAssetPower: Scalars['Boolean'];
  readonly updateAssetPower: Scalars['Boolean'];
  /** @deprecated createUpdatePowerPermanentDrivers mutation deprecated. */
  readonly createUpdatePowerPermanentDrivers: Scalars['Boolean'];
  /** @deprecated createUpdatePowerPermanentTrailers mutation deprecated. */
  readonly createUpdatePowerPermanentTrailers: Scalars['Boolean'];
  readonly updateAssetPowersByOperations: Scalars['Boolean'];
  /** Create or Update Power */
  readonly createOrUpdatePower: CreateOrUpdatePowerPayload;
  /** Create or Update Power V2 */
  readonly createOrUpdatePowerV2: CreateOrUpdatePowerPayloadV2;
  readonly createOrUpdatePowerFleetRelationship: CreateOrUpdatePowerFleetRelationshipPayload;
  readonly deletePowerFleet: Scalars['Boolean'];
  /** Power Patch */
  readonly powerPatch: CreateOrUpdatePowerPayload;
  readonly createPreplan: CreatePreplanResponse;
  readonly cancelPreplan: CancelPreplanResponse;
  readonly updatePreplan: UpdatePreplanResponse;
  readonly cancelPreplanAutomated: CancelPreplanResponse;
  readonly updatePreplanAutomated: UpdatePreplanResponse;
  readonly checkAndSavePreplanAutomationOutboxEvent: Scalars['Boolean'];
  /** mastery-pricing: binOverride - override bin(book it now) cost. */
  readonly binOverride: BinOverrideResponse;
  /** mastery-pricing: updateMaxCost - update max cost. */
  readonly updateMaxCost?: Maybe<UpdateMaxCostResponse>;
  /** Creates/Updates an External Available Route */
  readonly processExternalAvailableRoute?: Maybe<AvailableRoute>;
  /** Batch delete Available Routes matching filter criteria */
  readonly deleteAvailableRoutesByFilter?: Maybe<DeleteAvailableRoutesByFilterResult>;
  /** Updates the actedUpon states for a given match. */
  readonly markActedUpon?: Maybe<Scalars['Boolean']>;
  /** Queries the load API and creates/updates routes in ES */
  readonly importLoadV2?: Maybe<Scalars['Boolean']>;
  /** Queries the load API and creates/updates a route in ES */
  readonly importRoute?: Maybe<Scalars['Boolean']>;
  /** Queries the Truck Entry API and creates/updates a truck in ES */
  readonly importTruckEntryV2?: Maybe<Scalars['Boolean']>;
  /** Delete an Available Truck and tombstone related matches */
  readonly deleteAvailableTruckV2?: Maybe<Scalars['Boolean']>;
  /** Queries the Capacity API and creates/updates a gate reservation in ES */
  readonly importGateReservation?: Maybe<Scalars['Boolean']>;
  /** Delete an Available Gate Reservation and tombstone related matches */
  readonly deleteAvailableGateReservation?: Maybe<Scalars['Boolean']>;
  /** Queries the Capacity API and creates/updates a container in ES */
  readonly importContainer?: Maybe<Scalars['Boolean']>;
  /** Delete an Available Container and tombstone related matches */
  readonly deleteAvailableContainer?: Maybe<Scalars['Boolean']>;
  /** Updates carrier fields in procurement data for all capacity owned by that carrier */
  readonly updateCapacityForCarrier?: Maybe<Scalars['Boolean']>;
  /** Updates driver fields in procurement data for trucks that the driver is assigned to */
  readonly updateDriverAssignedToTrucks?: Maybe<Scalars['Boolean']>;
  /** Persists asset driver HOS events in ES */
  readonly importDriverHosEvent?: Maybe<Scalars['Boolean']>;
  readonly resetProjectApiDataDictionaryCache?: Maybe<ProjectBooleanPayload>;
  /** Create or Update Project */
  readonly createOrUpdateProject: CreateOrUpdateProjectPayload;
  /** @deprecated Use rate confirmations V2. */
  readonly createRateConfirmation: CreateRateConfirmationPayload;
  /** @deprecated Use rate confirmations V2. */
  readonly startAutoRateConfirmation: StartAutoRateConfirmationPayload;
  readonly sendRateConfirmationViaEdi: RateConfirmationV2;
  readonly sendRateConfirmationViaEmail: RateConfirmationV2;
  readonly sendDrayNotificationViaEdi: DrayNotification;
  readonly sendDrayNotificationViaEmail: DrayNotification;
  readonly sendRailBillingViaEdi: RailBilling;
  readonly updateTrailerAcquisitionStopPending: LoadTender;
  readonly startAutoRateConCostLine?: Maybe<AutomationPayload>;
  /**
   * Handle events from kafka topic pvt.loadex.tasks.v0 for triggering automated Rate Confirmations.
   *
   * Mutation should only be called by the Pro Domain kafka consumer.
   */
  readonly startAutoRateConTask?: Maybe<AutomationPayload>;
  /**
   * Handle events from kafka topic pvt.documents.fct.evt.v0 for triggering automated Rate Confirmations.
   *
   * Mutation should only be called by the Pro Domain kafka consumer.
   */
  readonly startAutoRateConBOL?: Maybe<AutomationPayload>;
  /**
   * Handle events from kafka topic net.mastery.trailer_execution_out_of_route.v2 for triggering automated Rate Confirmations.
   *
   * Mutation should only be called by the Pro Domain kafka consumer.
   */
  readonly startAutoRateConTrailerExecution?: Maybe<AutomationPayload>;
  /**
   * Handle events from kafka topic load.routes.stops.v3 for triggering automated Rate Confirmations.
   *
   * Mutation should only be called by the Pro Domain kafka consumer.
   */
  readonly startAutoRateConStop?: Maybe<AutomationPayload>;
  readonly handleRateConBolException?: Maybe<BolExceptionPayload>;
  /** mastery-rateQuote: ConvertQuoteToLoad - Create a load from customer quote. */
  readonly ConvertQuoteToLoad?: Maybe<LoadDetail>;
  /**
   * DO NOT USE. This service is depricated. Please use createCustomerQuoteV3.
   * @deprecated This service is depricated. Please use createCustomerQuoteV3.
   */
  readonly createCustomerQuoteV2?: Maybe<CustomerQuotePaginatedV2>;
  /**
   * mastery-rateQuote: createCustomerQuote - Create a customer quote.
   * @deprecated DO NOT USE. This service is depricated. Please use createCustomerQuoteV4.
   */
  readonly createCustomerQuoteV3?: Maybe<CustomerQuoteV3>;
  /**
   * mastery-rateQuote: createCustomerQuote - Create a customer quote.
   * @deprecated This service is depricated. Please use createCustomerQuoteV5.
   */
  readonly createCustomerQuoteV4?: Maybe<CustomerQuoteV3>;
  /**
   * mastery-rateQuote: createCustomerQuote - Create a customer quote.
   * @deprecated This service is depricated. Please use createCustomerQuoteV6.
   */
  readonly createCustomerQuoteV5?: Maybe<CustomerQuoteV4>;
  /** mastery-rateQuote: createCustomerQuoteV6 - Create a customer quote. */
  readonly createCustomerQuoteV6?: Maybe<CustomerQuoteV4>;
  /** mastery-rateQuote: createCustomerQuoteV7 - Create a customer quote. */
  readonly createCustomerQuoteV7?: Maybe<CustomerQuoteV4>;
  /** mastery-rateQuote: createCustomerQuoteV8 - Create a customer quote. */
  readonly createCustomerQuoteV8?: Maybe<CustomerQuoteV4>;
  /**
   * DO NOT USE. This service is depricated. Please use removeCustomerQuoteFromGroupV3.
   * @deprecated This service is depricated. Please use removeCustomerQuoteFromGroupV3.
   */
  readonly removeCustomerQuoteFromGroupV2?: Maybe<RemoveCustomerQuoteFromGroupPayloadV2>;
  /**
   * mastery-rateQuote: ConvertQuoteToLoad - Removes quote from quote group .
   * @deprecated DO NOT USE. This service is depricated. Please use removeCustomerQuoteFromGroupV4.
   */
  readonly removeCustomerQuoteFromGroupV3?: Maybe<RemoveCustomerQuoteFromGroupPayloadV3>;
  /**
   * mastery-rateQuote: Removes quote from quote group .
   * @deprecated DO NOT USE. This service is depricated. Please use removeCustomerQuoteFromGroupV5.
   */
  readonly removeCustomerQuoteFromGroupV4?: Maybe<RemoveCustomerQuoteFromGroupPayloadV4>;
  /**
   * DO NOT USE. This mutation is deprecated.
   * @deprecated This service mutation is deprecated.
   */
  readonly removeCustomerQuoteFromGroupV5?: Maybe<RemoveCustomerQuoteFromGroupPayloadV5>;
  /** mastery-rateQuote: update quote . */
  readonly updateCustomerQuote?: Maybe<CustomerQuoteV4>;
  /** mastery-rateQuote: update quote . */
  readonly updateCustomerQuoteV1?: Maybe<CustomerQuoteV4>;
  /**
   * Add an existing vendor to an existing route
   * @deprecated Moved to AssignVendorToRoute
   */
  readonly addVendorToRoute: AddVendorToRoutePayload;
  /** Assign an existing vendor to an existing route */
  readonly assignVendorToRoute: AssignVendorToRoutePayload;
  /** Bounces a Route Vendor */
  readonly bounceRouteVendor: BounceRouteVendorPayload;
  /** Updates the order with tender_status or activation_status */
  readonly bulkUpdateTenderStatus: BulkUpdateTenderStatusPayload;
  /** Create Credit History entry */
  readonly createCreditHistory: CreateCreditHistoryPayload;
  /**
   * Create customer credit provider
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly createCustomerCreditProvider: CreateCustomerCreditProviderPayload;
  /** Create a customer quote */
  readonly createCustomerQuote: CreateCustomerQuotePayload;
  /** Create or update a load */
  readonly createLoad: CreateLoadPayload;
  /**
   * Accepts quantity and truck_posting. Clones truck_posting quantity times.
   * @deprecated Service has reached EOL. Use TruckEntry subgraph. Mutation will be removed soon
   */
  readonly createMultipleTruckPostings: CreateMultipleTruckPostingsPayload;
  /** Create or update address for carrier, facility or customer */
  readonly createOrUpdateAddress: CreateOrUpdateAddressPayload;
  /**
   * Create/Update/Delete Carriers including insurances, reps etc...exceptions are truck postings and carrier settings
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly createOrUpdateCarrier: CreateOrUpdateCarrierPayload;
  /**
   * Create, update or delete a carrier certification
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly createOrUpdateCarrierCertification: CreateOrUpdateCarrierCertificationPayload;
  /**
   * Create/Update/Delete Carrier-Customer Relationship
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly createOrUpdateCarrierCustomerRelationship: CreateOrUpdateCarrierCustomerRelationshipPayload;
  /**
   * Create or update a single Carrier Release Method
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly createOrUpdateCarrierReleaseMethodV1: CreateOrUpdateCarrierReleaseMethodV1Payload;
  /**
   * Create or Update Carrier RMIS
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly createOrUpdateCarrierRiskInformation: CreateOrUpdateCarrierRiskInformationPayload;
  /**
   * Create/Update Carrier service
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly createOrUpdateCarrierService: CreateOrUpdateCarrierServicePayload;
  /**
   * Create/Update/Delete Carrier settings
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly createOrUpdateCarrierSetting: CreateOrUpdateCarrierSettingPayload;
  /** Create or update contact for carrier, facility or customer */
  readonly createOrUpdateContact: CreateOrUpdateContactPayload;
  /**
   * Create/Update/Delete Customers
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly createOrUpdateCustomer: CreateOrUpdateCustomerPayload;
  /**
   * Create/Update/Delete Customer-Facility Relationship
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly createOrUpdateCustomerFacilityRelationship: CreateOrUpdateCustomerFacilityRelationshipPayload;
  /**
   * Create or Update Customer groups
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly createOrUpdateCustomerGroup: CreateOrUpdateCustomerGroupPayload;
  /**
   * Create or update a order
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly createOrUpdateCustomerOrder: CreateOrUpdateCustomerOrderPayload;
  /**
   * Create or Update Customer settings
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly createOrUpdateCustomerSetting: CreateOrUpdateCustomerSettingPayload;
  /**
   * Create or update a single employee
   * @deprecated Service has been deprecated. Mutation will be removed soon
   */
  readonly createOrUpdateEmployee: CreateOrUpdateEmployeePayload;
  /**
   * Create or update a single employee. Input does not require IDs for various fields
   * @deprecated Service has been deprecated. Use TruckEntry subgraph. Mutation will be removed soon
   */
  readonly createOrUpdateEmployeeExternal: CreateOrUpdateEmployeeExternalPayload;
  /**
   * Create/Update/Delete Facility
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly createOrUpdateFacility: CreateOrUpdateFacilityPayload;
  /**
   * Create/Update Facility Identifier
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly createOrUpdateFacilityIdentifier: CreateOrUpdateFacilityIdentifierPayload;
  /**
   * Create or update a single truck posting
   * @deprecated Service has reached EOL. Use TruckEntry subgraph. Mutation will be removed soon
   */
  readonly createOrUpdateSingleTruckPosting: CreateOrUpdateSingleTruckPostingPayload;
  /** Create or update a stop */
  readonly createOrUpdateStop: CreateOrUpdateStopPayload;
  /** Destroys one address by id */
  readonly destroyAddress: DestroyAddressPayload;
  /**
   * Destroys one carrier_certification by id
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly destroyCarrierCertification: DestroyCarrierCertificationPayload;
  /**
   * Delete a carrier release method by id
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly destroyCarrierReleaseMethodV1: DestroyCarrierReleaseMethodV1Payload;
  /**
   * Destroys one carrier service by id
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly destroyCarrierService: DestroyCarrierServicePayload;
  /** Destroys one contact by id */
  readonly destroyContact: DestroyContactPayload;
  /**
   * Destroys one Facility Identifier by id
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly destroyFacilityIdentifier: DestroyFacilityIdentifierPayload;
  /**
   * Destroys one truck_posting by id
   * @deprecated Service has reached EOL. Use TruckEntry subgraph. Mutation will be removed soon
   */
  readonly destroyTruckPosting: DestroyTruckPostingPayload;
  /**
   * Find/Create Facility
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly findOrCreateFacility: FindOrCreateFacilityPayload;
  /** Rebook a previously bounced Route Vendor */
  readonly rebookRouteVendor: RebookRouteVendorPayload;
  /** Remove a customer quote from a quote group */
  readonly removeCustomerQuoteFromGroup: RemoveCustomerQuoteFromGroupPayload;
  /** Updates an existing bounce */
  readonly updateBounce: UpdateBouncePayload;
  /**
   * Updates the carrier with intrastate states
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly updateCarrierIntrastate: UpdateCarrierIntrastatePayload;
  /**
   * Update Carrier standing
   * @deprecated Service has been deprecated. Use V2 service. Mutation will be removed soon
   */
  readonly updateCarrierStanding: UpdateCarrierStandingPayload;
  /** Updates a load */
  readonly updateLoad: UpdateLoadPayload;
  /** Updates a route */
  readonly updateRoute: UpdateRoutePayload;
  /** Updates the stops and their sequence associated to a route */
  readonly updateRouteStops: UpdateRouteStopsPayload;
  /** Updates a route vendor */
  readonly updateRouteVendor: UpdateRouteVendorPayload;
  /** Request to book carrier by provided route number, order code, carrier code, and bypassBooking flag */
  readonly requestToBookV2: RequestToBookPayloadV2;
  /** Adds trailer to existing route resource and saves new state. */
  readonly addTrailerToRouteResources: RouteResources;
  /** Delete a trailer for a given route resource */
  readonly deleteTrailerFromRouteResources: RouteResources;
  /** Toggle the visibility of trailer accessories for a given route resource */
  readonly toggleRouteResourcesTrailerAccessories: RouteResources;
  /** Delete resources for a given route */
  readonly deleteRouteResources: Scalars['Boolean'];
  /** Bounces selected drivers from the given fleet resource. */
  readonly bounceDriversFromRouteResource: RouteResources;
  /** Mutation for creating or updating RouteResources by only providing partial input. Detailed documentation can be found [here](https://masterysys.atlassian.net/wiki/spaces/EN/pages/2962325515/Route+Resources+GraphQL+API+Documentation#modifyRouteResources). */
  readonly modifyRouteResources: RouteResources;
  /** Creates or updates complete resources entity for a given route. */
  readonly upsertRouteResources: RouteResources;
  /** Mutation for creating a driver assignment of resource type "carrier" via Ingress API. A mutation for creating a driver assignment of resource type "fleet" via Ingress API is not yet supported. */
  readonly createDriverAssignment: DriverAssignment;
  /** Mutation for updating a driver assignment of resource type "carrier" via Ingress API. A mutation for updating a driver assignment of resource type "fleet" via Ingress API is not yet supported. */
  readonly updateDriverAssignment: DriverAssignment;
  /** Upserts a driver resource for a brokered carrier. For fleet carriers, use the `upsertFleetResource` mutation. */
  readonly upsertDriverAssignment: DriverAssignment;
  /** Upserts a driver resource for a fleet carrier. For brokered carriers, use the `upsertDriverAssignment` mutation. */
  readonly upsertFleetResource: DriverAssignment;
  /** Bounces selected drivers from the given fleet resource. */
  readonly bounceDriversFromFleetResource: DriverAssignment;
  readonly deleteDriverAssignment: Scalars['Boolean'];
  /** Deletes a driver resource for a fleet carrier. For brokered carriers, use the `deleteDriverAssignment` mutation. */
  readonly deleteFleetResource: Scalars['Boolean'];
  /** toggles the display of trailer accessories on the resource tab. */
  readonly toggleDriverAssignmentTrailerAccessories: DriverAssignment;
  /** Adds trailer to existing driver assignment and saves new state. */
  readonly addTrailerToDriverAssignment: DriverAssignment;
  /** Deletes a Trailer from Route Resources. */
  readonly deleteTrailerFromDriverAssignment: DriverAssignment;
  readonly createOrUpdateFilter: SavedFilter;
  readonly deleteFilterById: DeleteFilterResponseType;
  readonly createOrUpdateTablePreference: TablePreferenceResponseType;
  readonly bulkUpdateTablePreference: ReadonlyArray<TablePreferenceResponseType>;
  readonly deleteTablePreferencesById: DeleteTablePreferenceResponseType;
  /** Creates tokens to store in MasterMind from a list of driver's licenses with asset driver ID and/or driver code and (optional) states. */
  readonly createSecureDataDriversLicenseTokens: ReadonlyArray<SecureDataTokenModel>;
  /** Creates tokens asynchronously to store in MasterMind from a list of driver's licenses with asset driver ID and/or driver code and (optional) states. Token will be published to a secure-data topic. */
  readonly createSecureDataDriversLicenseTokensAsync: SecureDataAsyncResponse;
  readonly sendDirectMessage: SendMessageResponseObj;
  readonly sendMsgToMultipleUsers: SendMessageResponseObj;
  readonly sendMessageToSlack: SendMessageResponseObj;
  readonly syncSlackUsersToEmployeeRecord: SendMessageResponseObj;
  readonly appCreation: AppCreationResponseObj;
  readonly sendSlackMessage: SendMessageResponseObj;
  readonly createSpotQuote?: Maybe<CreateSpotQuotePayload>;
  readonly updateSpotQuote?: Maybe<UpdateSpotQuotePayload>;
  readonly updateSpotQuoteV2?: Maybe<UpdateSpotQuotePayload>;
  readonly convertSpotQuoteToLoad?: Maybe<ConvertSpotQuoteToLoadPayload>;
  readonly ingressStopEventV2: StopEventV2;
  readonly ingressStopEventWithEtas: ReadonlyArray<StopEventV2>;
  readonly upsertStopEventsV2: ReadonlyArray<StopEventV2>;
  readonly upsertStopEventV2: ReadonlyArray<StopEventV2>;
  readonly deleteStopEventV2: Scalars['Boolean'];
  readonly updateStopEventV2LateReason: ReadonlyArray<StopEventV2>;
  /** Create a list of task details. */
  readonly createTaskV2: TaskV2;
  /** Delete a list of task details. */
  readonly deleteTaskV2: Scalars['Boolean'];
  /** Update a list of task details. */
  readonly updateTaskV2: TaskV2;
  /** Close a task based on id and status. */
  readonly closeTaskV2: TaskV2;
  readonly createTimelineJournalEntry: TimelineEvent;
  readonly updateTimelineJournalEntry: TimelineEvent;
  readonly createTimelineJournalEntryV2: TimelineEventV2;
  readonly updateTimelineJournalEntryV2: TimelineEventV2;
  readonly upsertAutoETA?: Maybe<TrackingEtaForStop>;
  readonly createTrackingUpdateV4: TrackingUpdateV4;
  /** Create or Update or Delete Fleet */
  readonly assetTrailerFleet: CreateOrUpdateTrailerFleetPayload;
  /** Create or Update Fleet Relationship */
  readonly createOrUpdateTrailerFleetRelationship: CreateOrUpdateTrailerFleetPayload;
  readonly deleteTrailerFleet: Scalars['Boolean'];
  /** Create or Update or Delete Identifier */
  readonly assetTrailerIdentifier: AssetTrailerIdentifierPayload;
  readonly deleteTrailerIdentifier: Scalars['Boolean'];
  /** Create or Update or Delete Equipment */
  readonly assetTrailerEquipment: AssetTrailerEquipmentPayload;
  readonly deleteTrailerEquipment: Scalars['Boolean'];
  /** Create, update or delete maintenance */
  readonly assetTrailerMaintenance: AssetTrailerMaintenancePayload;
  readonly deleteTrailerMaintenance: Scalars['Boolean'];
  /** Create or Update or Delete Customer */
  readonly assetTrailerCustomer: AssetTrailerCustomerRelationshipPayload;
  readonly deleteTrailerCustomer: Scalars['Boolean'];
  /** @deprecated save_asset_trailers mutation is deprecated, use createOrUpdateTrailer mutation instead. */
  readonly save_asset_trailers: AssetTrailers;
  /** @deprecated updateAsset_Trailers mutation is deprecated, use createOrUpdateTrailer mutation instead. */
  readonly updateAsset_Trailers: Scalars['Boolean'];
  /** @deprecated updateAssetTrailersByOperations mutation is deprecated, use createOrUpdateTrailer mutation instead. */
  readonly updateAssetTrailersByOperations: Scalars['Boolean'];
  /** Create or Update Trailer */
  readonly createOrUpdateTrailer: CreateOrUpdateTrailerPayload;
  /**
   * Create or Update Trailer V3
   * @deprecated createOrUpdateTrailerV3 mutation is deprecated, use createOrUpdateTrailer mutation instead.
   */
  readonly createOrUpdateTrailerV3: CreateOrUpdateTrailerPayloadV2;
  /** Mutation for asset trailer patch */
  readonly assetTrailerPatch: CreateOrUpdateTrailerPayload;
  /** Mutation to update the service status details of a trailer. */
  readonly assetTrailerServiceStatus: AssetTrailerServiceStatusPayload;
  /** Creates a flip request for the routeId(s) provided. This will refresh the GQL enriched data for the provided entities and publish update requests for the associated routes */
  readonly flipTrailerOrchestratorRoutes: Scalars['Boolean'];
  /**
   * Creates new container reservation.
   *
   * Requires (one of) these scope(s): [containerReservations:admin].
   *
   * (Repo: truck-entry-service)
   */
  readonly createContainer: ContainerPayloadWithErrorsAndContainer;
  /**
   * Update the container reservation.
   *
   * Requires (one of) these scope(s): [containerReservations:admin].
   *
   * (Repo: truck-entry-service)
   */
  readonly updateContainer: ContainerPayloadWithErrorsAndContainer;
  /**
   * Deletes the container reservation.
   *
   * Requires (one of) these scope(s): [containerReservations:admin].
   *
   * (Repo: truck-entry-service)
   */
  readonly deleteContainer: ContainerPayloadWithErrorsAndContainer;
  /**
   * Updates multiple containers based on the changes in asset trailer fields
   *
   * (Repo: truck-entry-service)
   */
  readonly bulkUpdateContainers?: Maybe<ContainerPayloadWithErrorsAndContainers>;
  /**
   * Creates or Updates a TruckEntry by external source ID.
   *
   * Requires (one of) these scope(s): [truckEntry:create, truckEntry:edit].
   *
   * (Repo: truck-entry-service)
   */
  readonly createOrUpdateTruckByExternalSourceId?: Maybe<TruckEntryPayloadWithErrorsAndTruckEntry>;
  /**
   * Creates a TruckEntry.
   *
   * Requires (one of) these scope(s): [truckEntry:create].
   *
   * (Repo: truck-entry-service)
   */
  readonly createTruckEntry?: Maybe<TruckEntryPayloadWithErrorsAndTruckEntry>;
  /**
   * Internal mutation for creating a new truck entry record. Intended for internal system use only. Exposes input fields not available in the CreateTruckEntry mutation.
   *
   * Requires (one of) these scope(s): [truckEntry:create].
   *
   * (Repo: truck-entry-service)
   */
  readonly internalCreateTruckEntry?: Maybe<TruckEntryPayloadWithErrorsAndTruckEntry>;
  /**
   * Updates a TruckEntry.
   *
   * Requires (one of) these scope(s): [truckEntry:edit].
   *
   * (Repo: truck-entry-service)
   */
  readonly updateTruckEntry?: Maybe<TruckEntryPayloadWithErrorsAndTruckEntry>;
  /**
   * Updates the utilization status for a TruckEntry. Should only be used by the consumers in the Capacity Domain
   *
   * Requires (one of) these scope(s): [truckEntry:edit].
   *
   * (Repo: truck-entry-service)
   */
  readonly updateTruckEntryUtilizationStatus?: Maybe<TruckEntryPayloadWithErrorsAndTruckEntry>;
  /**
   * Deletes a TruckEntry.
   *
   * Requires (one of) these scope(s): [truckEntry:delete].
   *
   * (Repo: truck-entry-service)
   */
  readonly deleteTruckEntry?: Maybe<TruckEntryPayloadWithOnlyErrors>;
  /**
   * Updates a TruckEntryTemplate.
   *
   * Requires (one of) these scope(s): [truckEntryTemplate:edit].
   *
   * (Repo: truck-entry-service)
   */
  readonly updateTruckEntryTemplate?: Maybe<TruckEntryTemplatePayloadWithErrorsAndTruckEntryTemplate>;
  /**
   * Deletes a TruckEntryTemplate.
   *
   * Requires (one of) these scope(s): [truckEntryTemplate:delete].
   *
   * (Repo: truck-entry-service)
   */
  readonly deleteTruckEntryTemplate?: Maybe<TruckEntryTemplatePayloadWithErrorsAndTruckEntryTemplate>;
  /**
   * Creates a truckEntryTemplate.
   *
   * Requires (one of) these scope(s): [truckEntryTemplate:create].
   *
   * (Repo: truck-entry-service)
   */
  readonly createTruckEntryTemplate?: Maybe<TruckEntryTemplatePayloadWithErrorsAndTruckEntryTemplate>;
  /**
   * Deactivate a TruckEntryTemplate.
   *
   * Requires (one of) these scope(s): [truckEntryTemplate:edit].
   *
   * (Repo: truck-entry-service)
   */
  readonly deactivateTruckEntryTemplate?: Maybe<TruckEntryTemplatePayloadWithErrorsAndTruckEntryTemplate>;
  /**
   * Creates a FirstTruckEntries.
   *
   * Requires (one of) these scope(s): [firstTruckEntries:create].
   *
   * (Repo: truck-entry-service)
   */
  readonly createFirstTruckEntries?: Maybe<FirstTruckEntriesPayloadWithErrorsAndTruckEntries>;
  /**
   * Updates a FirstTruckEntries.
   *
   * Requires (one of) these scope(s): [firstTruckEntries:edit].
   *
   * (Repo: truck-entry-service)
   */
  readonly updateFirstTruckEntries?: Maybe<FirstTruckEntriesPayloadWithErrorsAndTruckEntries>;
  /**
   * Creates new gate reservation.
   *
   * Requires (one of) these scope(s): [gateReservations:admin].
   *
   * (Repo: truck-entry-service)
   */
  readonly createGateReservation: GateReservationPayloadWithErrorsAndGateReservation;
  /**
   * Updates gate reservation.
   *
   * Requires (one of) these scope(s): [gateReservations:admin].
   *
   * (Repo: truck-entry-service)
   */
  readonly updateGateReservation: GateReservationPayloadWithErrorsAndGateReservation;
  /**
   * Deletes gate reservation.
   *
   * Requires (one of) these scope(s): [gateReservations:admin].
   *
   * (Repo: truck-entry-service)
   */
  readonly deleteGateReservation: GateReservationPayloadWithErrorsAndGateReservation;
  /**
   * Creates, updates, or deletes a TruckEntry by route ID if it was created via reload intent.
   *
   * Requires (one of) these scope(s): [truckEntry:create, truckEntry:edit, truckEntry:delete].
   *
   * (Repo: truck-entry-service)
   */
  readonly createOrUpdateOrDeleteTruckByRouteId?: Maybe<TruckEntryPayloadWithErrorsAndTruckEntry>;
  /**
   * Creates a TruckEntry based on Driver Assignment information
   *
   * Requires (one of) these scope(s): [truckEntry:create].
   *
   * (Repo: truck-entry-service)
   */
  readonly createTruckEntryFromDriverAssignment?: Maybe<TruckEntryPayloadWithErrorsAndTruckEntry>;
  /**
   * Deletes a TruckEntry by route ID if it was created via reload intent. Will return null and log a warning if a TruckEntry is not found.
   *
   * Requires (one of) these scope(s): [truckEntry:delete].
   *
   * (Repo: truck-entry-service)
   */
  readonly deleteTruckByRouteId?: Maybe<TruckEntryPayloadWithOnlyErrors>;
  /**
   * Finds and Updates a TruckEntry by route ID if it was created via reload intent. Will return null and log a warning if a TruckEntry is not found.
   *
   * Requires (one of) these scope(s): [truckEntry:edit].
   *
   * (Repo: truck-entry-service)
   */
  readonly updateTruckEntryByRouteId?: Maybe<TruckEntryPayloadWithErrorsAndTruckEntry>;
  /**
   * Finds a TruckEntry by route ID if it is created via reload intent and Updates the ready by date time. Will return null and log a warning if a TruckEntry is not found.
   *
   * Requires (one of) these scope(s): [truckEntry:edit].
   *
   * (Repo: truck-entry-service)
   */
  readonly updateReloadIntentCapacityReadyByDateTime?: Maybe<TruckEntryPayloadWithErrorsAndTruckEntry>;
  /**
   * Creates or updates a Container by route ID if it was created via Street Turns.
   *
   * Requires (one of) these scope(s): [containerReservations:admin].
   *
   * (Repo: truck-entry-service)
   */
  readonly createOrUpdateContainerByRouteId?: Maybe<ContainerPayloadWithErrorsAndContainer>;
  /**
   * Deletes a Container by route ID if it was created via Street Turns.
   *
   * Requires (one of) these scope(s): [containerReservations:admin].
   *
   * (Repo: truck-entry-service)
   */
  readonly deleteContainerByRouteId?: Maybe<ContainerPayloadWithErrorsAndContainer>;
  /**
   * Creates the truck entry template and corresponding FirstTruckEntries.
   *
   * Requires (one of) these scope(s): [truckEntry:create, truckEntryTemplate:create].
   *
   * (Repo: truck-entry-service)
   */
  readonly createTruckEntryTemplateAndFirstTrucks?: Maybe<TruckEntryTemplateAndFirstTrucksPayloadWithErrorsAndTruckEntries>;
  /**
   * Updates the template and corresponding FirstTruckEntries.
   *
   * Requires (one of) these scope(s): [truckEntry:edit, truckEntryTemplate:edit].
   *
   * (Repo: truck-entry-service)
   */
  readonly updateTruckEntryTemplateAndFirstTrucks?: Maybe<TruckEntryTemplateAndFirstTrucksPayloadWithErrorsAndTruckEntries>;
  readonly createPatternV2: CreatePatternPayload;
  readonly deletePatternV2: DeletePatternPayloadV2;
  readonly reorderLanePatternsByPriorityV2: ReorderLanePatternsByPriorityPayloadV2;
  readonly reorderAutoApplyPriority?: Maybe<ReorderAutoApplyPriorityPayload>;
  readonly updatePatternV2: UpdatePatternPayload;
  readonly applyPatternV3?: Maybe<ApplyPatternPayloadV2>;
  readonly applyPatternAsync?: Maybe<ApplyPatternAsyncPayload>;
  /** Creates a Canvas Configuration from the provided canvas keys */
  readonly createCanvasConfiguration: CanvasConfiguration;
  /** Updates the Canvas Configuration for the provided UUID with the UpdateCanvasConfigurationInput values */
  readonly updateCanvasConfiguration: CanvasConfiguration;
  /** Deletes a Canvas Configuration for the provided UUID */
  readonly deleteCanvasConfiguration: CanvasConfiguration;
  /** Updates the priority of the Canvas Configurations for the provided UUIDs based on the order of the UUIDs in the array. */
  readonly updateCanvasConfigurationPriorities: ReadonlyArray<CanvasConfiguration>;
  /** Creates a Validation Configuration from the provided validator keys */
  readonly createValidationConfiguration: ValidationConfiguration;
  /** @deprecated Please use updateValidationConfigurationByUuid instead */
  readonly updateValidationConfiguration: ValidationConfiguration;
  /** Updates the Validation Configuration for the provided UUID with the UpdateValidationConfigurationInput values */
  readonly updateValidationConfigurationByUuid: ValidationConfiguration;
  /** @deprecated Please use deleteValidationConfigurationByUuid instead */
  readonly deleteValidationConfiguration: ValidationConfiguration;
  /** Deletes a Validation Configuration for the provided UUID */
  readonly deleteValidationConfigurationByUuid: ValidationConfiguration;
  readonly validateCredentials?: Maybe<ValidateCredentialsResponse>;
  readonly updateProviderConfig: UpdateProviderConfigResponse;
  readonly putProviderConfig: UpdateProviderIdConfigResponse;
  readonly toggleProvider: ApiResponseProvider;
  readonly patchIntegrationStatus: IntegrationUpdateResponse;
  /** Saves EDI transaction to EDI Database */
  readonly saveEDITransactionAsync?: Maybe<EdiTransaction>;
  /**
   * Saves Edi transaction to Database, and JSON to Azure Blob.
   * @deprecated No longer used
   */
  readonly saveEDITransactionAndJsonDataToBlob?: Maybe<EdiTransaction>;
  /** Associates the corresponding mastermind load record id with a shipmentId unique per customerCode */
  readonly associateShipmentIdWithLoadId?: Maybe<ReadonlyArray<Maybe<EdiTransaction>>>;
  /** Saves Edi Outbox Message to Edi Database */
  readonly saveEdiOutboxMessage?: Maybe<EdiOutbox>;
  /** Update Edi Outbox Message */
  readonly updateEdiOutboxMessage?: Maybe<EdiOutbox>;
  /** Update Edi Transaction By Id */
  readonly updateEDITransactionById?: Maybe<EdiTransaction>;
  /** Insert record into Outbound214StopAppointment when stopId and hash doesn't exist */
  readonly createOutbound214StopAppointment: Outbound214StopAppointment;
  /** Update unprocessed Edi 204 transactions */
  readonly updateUnprocessedEdi204Transactions?: Maybe<UnprocessedEdi204Transaction>;
  /** Update Tender Response in EdiTender. */
  readonly updateTenderResponseInEdiTender?: Maybe<UpdateTenderResponseInEdiTender>;
  /** Update Carrier EdiTransaction By Id. */
  readonly updateCarrierEdiTransactionById?: Maybe<EdiTransaction>;
  /** Save Edi Tender Message in EdiLogs Database */
  readonly saveEdiTender?: Maybe<EdiTender>;
  /** Delete Edi Outbox Message */
  readonly deleteEdiOutboxMessage?: Maybe<EdiOutbox>;
  /** Update the outbox table to indicate whether the records have been softly deleted from the outbox table and whether or not they are eligible for reprocessing. */
  readonly bulkUpdateEdiOutboxMessages?: Maybe<Scalars['Boolean']>;
  /** Save the ISA Receiver ID and Application Code to be used for sending later. */
  readonly saveInbound204IsaReceiverId?: Maybe<Inbound204IsaIdReceiver>;
  readonly createCustomerOrder: CustomerOrderPayload;
  readonly updateCustomerOrder: CustomerOrderPayload;
  readonly cancelCustomerOrderV2: CustomerOrderPayload;
  readonly createOrUpdateCustomerOrderV2: CustomerOrderPayload;
  readonly updateOrderExternalDistance: CustomerOrderPayload;
  readonly updateCustomerOrderNumber: CustomerOrderPayload;
  readonly createOrderStop: OrderStopPayload;
  readonly deleteOrderStop: BooleanPayload;
  readonly changeCustomerOrder: CustomerOrderPayload;
  readonly createLoadV2: LoadPayload;
  readonly updateLoadV2: LoadPayload;
  readonly planLoad: LoadPayload;
  readonly resequenceStops: LoadRoutePayload;
  readonly resequenceLoadStops: LoadStopPayload;
  readonly createOrUpdateStopV2: RouteStopPayload;
  readonly createOrUpdateCityStateStopV2: RouteStopPayloadV2;
  readonly deleteStop: BooleanPayload;
  /** @deprecated This mutation will be removed in a future release, please use associateLoadOrderStops instead. */
  readonly associateOrderStops: AssociateOrderStopsPayload;
  readonly associateLoadOrderStops: AssociateOrderStopsPayload;
  readonly associatePayableStops: AssociatePayableStopsPayload;
  /** Used to mark stops as billable or non-billable based on routeStopId. */
  readonly associateLoadBillableStops: AssociateLoadBillableStopsPayload;
  readonly assignFacilityToCityStateStop: RouteStopPayload;
  readonly changeFacilityOnStop: ChangeFacilityOnStopPayload;
  readonly updateCityStateOnStop: UpdateCityStateOnStopPayload;
  readonly planLoadV2: LoadPayload;
  readonly dispatchDriverReview: DispatchDriverReviewPayload;
  readonly dispatchDriver: DispatchDriverPayload;
  readonly createLoadRoute: LoadRoutePayload;
  readonly updateLoadRoute: LoadRoutePayload;
  readonly resetRouteStopRegions: LoadRoutePayload;
  readonly deleteRoute: BooleanPayload;
  /** Used to modify the backhaul status on route and associate order to backhaul route. */
  readonly updateBackhaulLoadRoute: LoadRoutePayload;
  readonly createRouteStop: RouteStopPayload;
  readonly deleteRouteStop: BooleanPayload;
  readonly calculateCutOffsForStopsInOrder?: Maybe<CalculateCutOffsForStopsInOrderPayload>;
  readonly createOrUpdateRouteReferences: RouteReferencesPayload;
  readonly deleteRouteReference: BooleanPayload;
  /**
   * Deprecated, use createOrUpdateRouteAssignedReps Mutation
   * @deprecated Deprecated, use createOrUpdateRouteAssignedReps Mutation
   */
  readonly createOrUpdateRouteAssignedRep: RouteAssignedRepsPayload;
  readonly createOrUpdateRouteAssignedReps: RouteAssignedRepsPayload;
  readonly deleteRouteAssignedRep: BooleanPayload;
  readonly splitRouteAtStop: SplitRouteStopPayload;
  /** @deprecated Use 'addStopAndSplitRouteV2' instead */
  readonly addStopAndSplitRoute: SplitRouteStopPayload;
  readonly addStopAndSplitRouteV2: SplitRouteStopPayload;
  readonly resetLoadApiDataDictionaryCache?: Maybe<BooleanPayload>;
  /** @deprecated Please use createOrderProfileV2 mutation instead of createOrderProfile. */
  readonly createOrderProfile: OrderProfilePayload;
  /** @deprecated Please use activateOrDeactivateOrderProfileV2 mutation instead of activateOrDeactivateOrderProfile. */
  readonly activateOrDeactivateOrderProfile: OrderProfilePayload;
  /** @deprecated Please use updateOrderProfileV2 mutation instead of updateOrderProfile. */
  readonly updateOrderProfile: OrderProfilePayload;
  readonly resequenceOrderProfilePriority: ResequenceOrderProfilePayload;
  readonly createOrderProfileV2: OrderProfilePayloadV2;
  readonly activateOrDeactivateOrderProfileV2: OrderProfilePayloadV2;
  readonly updateOrderProfileV2: OrderProfilePayloadV2;
  /** @deprecated Please use repeatLoad mutation instead to repeat loads. */
  readonly cloneLoad: RepeatLoadPayload;
  readonly repeatLoad: RepeatLoadRequestPayload;
  readonly createOrganization?: Maybe<Organization>;
  readonly updateOrganizationName?: Maybe<Organization>;
  readonly updateOrganizationActivationStatus?: Maybe<Organization>;
  readonly updateOrganizationParent?: Maybe<Organization>;
  readonly createMembership?: Maybe<Membership>;
  readonly createManyMembershipsForUser?: Maybe<ReadonlyArray<Maybe<Membership>>>;
  readonly updateMembershipAccessLevel?: Maybe<Membership>;
  readonly deleteMembership?: Maybe<Membership>;
  readonly deleteMemberships?: Maybe<ReadonlyArray<Maybe<Membership>>>;
  readonly assignRolesToUser?: Maybe<Scalars['Boolean']>;
  readonly assignRolesToUserV2?: Maybe<Scalars['Boolean']>;
  readonly removeRolesFromUser?: Maybe<Scalars['Boolean']>;
  readonly removeRolesFromUserV2?: Maybe<Scalars['Boolean']>;
  readonly assignUserToRootOrganization?: Maybe<Membership>;
  readonly createUser?: Maybe<UserV2>;
  readonly generateInvitation: Invitation;
  readonly revokeAccess: Scalars['Boolean'];
};


/** The root type for all write operations. */
export type MutationMergeLessThanLoadDetailsArgs = {
  mergeLessThanLoadDetailsInput: MergeLessThanLoadDetailsInput;
};


/** The root type for all write operations. */
export type MutationCreateRateDetailsArgs = {
  rateDetails: ReadonlyArray<RateDetailAddInput>;
};


/** The root type for all write operations. */
export type MutationLoadRepeatCreateRateDetailsArgs = {
  loadRepeatCreateRateDetailsInput: LoadRepeatCreateRateDetails;
};


/** The root type for all write operations. */
export type MutationLoadRepeatCreateCostDetailsArgs = {
  loadRepeatCreateCostDetailsInput: LoadRepeatCreateCostDetails;
};


/** The root type for all write operations. */
export type MutationBookingCreateCostDetailsArgs = {
  bookingCreateCostDetailsInput: BookingCreateCostDetails;
};


/** The root type for all write operations. */
export type MutationMergeBlumeCostDetailsArgs = {
  mergeBlumeCostDetailsInput: MergeBlumeCostDetails;
};


/** The root type for all write operations. */
export type MutationForceCreateRateDetailsArgs = {
  rateDetails: ReadonlyArray<RateDetailAddInput>;
};


/** The root type for all write operations. */
export type MutationUpdateRateDetailsArgs = {
  rateDetails: ReadonlyArray<RateDetailUpdateInput>;
};


/** The root type for all write operations. */
export type MutationMergeRateDetailsV3Args = {
  mergeRateDetailsInput: MergeRateDetailsInput;
};


/** The root type for all write operations. */
export type MutationDeleteRateDetailArgs = {
  rateDetailId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateInvoiceBatchArgs = {
  batch: InvoiceBatchCreateInput;
};


/** The root type for all write operations. */
export type MutationProcessInvoiceBatchArgs = {
  batch: InvoiceBatchProcessingInput;
};


/** The root type for all write operations. */
export type MutationCancelInvoiceBatchArgs = {
  batchParameters: BatchParametersInput;
};


/** The root type for all write operations. */
export type MutationCreateCostDetailsArgs = {
  costDetails: ReadonlyArray<CostDetailAddInput>;
};


/** The root type for all write operations. */
export type MutationDeleteCostDetailArgs = {
  costDetailId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDeleteCostDetailsArgs = {
  routeId: Scalars['ID'];
  vendorId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationVoucheringUpdateCostDetailsArgs = {
  costDetails: ReadonlyArray<VoucheringCostDetailUpdateInputType>;
};


/** The root type for all write operations. */
export type MutationDisassociateCostDetailsFromDriverVoucherArgs = {
  driverVoucherId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationVoucheringApplySegmentationAndGlCodeCostDetailsArgs = {
  costDetailIds: ReadonlyArray<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationVoucheringFinalizeCostDetailsArgs = {
  driverVoucherIds: ReadonlyArray<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationUpdateCostDetailsArgs = {
  costDetails: ReadonlyArray<CostDetailUpdateInput>;
};


/** The root type for all write operations. */
export type MutationMergeCostDetailsV2Args = {
  mergeCostDetailsInput: MergeCostDetailsInput;
};


/** The root type for all write operations. */
export type MutationCancelVoucherBatchArgs = {
  batchParameters: BatchParametersInput;
};


/** The root type for all write operations. */
export type MutationProcessPartialVoucherBatchArgs = {
  batch: PartialVoucherBatchProcessingInput;
};


/** The root type for all write operations. */
export type MutationProcessMultiplePartialVoucherBatchArgs = {
  batch: MultiplePartialVoucherBatchProcessingInput;
};


/** The root type for all write operations. */
export type MutationProcessMultiplePartialVoucherBatchV2AsyncArgs = {
  batch: MultiplePartialVoucherBatchProcessingInputV2;
};


/** The root type for all write operations. */
export type MutationCreateAutoVoucherBatchV2AsyncArgs = {
  batch: AutoVoucherBatchInputV2;
};


/** The root type for all write operations. */
export type MutationCreateAdvanceAsyncArgs = {
  advance: AdvanceCreateInputTypeV2;
};


/** The root type for all write operations. */
export type MutationUpdateAdvanceBasicAsyncArgs = {
  advance: AdvanceUpdateBasicInputTypeV2;
};


/** The root type for all write operations. */
export type MutationUpdateAdvanceRestrictedAsyncArgs = {
  advance: AdvanceUpdateRestrictedInputTypeV2;
};


/** The root type for all write operations. */
export type MutationUpdateLatestAdvanceDetailsFromProviderAsyncArgs = {
  advanceId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationIssueAdvanceAsyncArgs = {
  advance: AdvanceRestrictedActionInput;
};


/** The root type for all write operations. */
export type MutationCancelAdvanceAsyncArgs = {
  advance: AdvanceRestrictedActionInput;
};


/** The root type for all write operations. */
export type MutationCloseAdvanceFromQueueAsyncArgs = {
  advance: AdvanceRestrictedActionInput;
};


/** The root type for all write operations. */
export type MutationBlockAdvanceAsyncArgs = {
  advance: AdvanceRestrictedActionInput;
};


/** The root type for all write operations. */
export type MutationActivateAdvanceAsyncArgs = {
  advance: AdvanceRestrictedActionInput;
};


/** The root type for all write operations. */
export type MutationCreateAdvanceInterfacesAsyncArgs = {
  advanceInterfaces: ReadonlyArray<AdvanceInterfaceCreateInputTypeV2>;
};


/** The root type for all write operations. */
export type MutationUpdateAdvanceInterfacesAsyncArgs = {
  advanceInterfaces: ReadonlyArray<AdvanceInterfaceUpdateInputTypeV2>;
};


/** The root type for all write operations. */
export type MutationAdvanceRefreshOverrideAsyncArgs = {
  advancesToOverride: ReadonlyArray<AdvanceRefreshOverrideInputTypeV2>;
};


/** The root type for all write operations. */
export type MutationUpdateRouteMaxCostArgs = {
  routeMaxCost: RouteMaxCostInputType;
};


/** The root type for all write operations. */
export type MutationCreateCustomerCreditMemoArgs = {
  invoiceId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationProcessCustomerCreditMemoArgs = {
  creditMemo: CustomerCreditMemoInput;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerCreditMemoArgs = {
  creditMemo: CustomerCreditMemoInput;
};


/** The root type for all write operations. */
export type MutationCreateVendorCreditMemoArgs = {
  voucherId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationProcessVendorCreditMemoArgs = {
  creditMemo: VendorCreditMemoInput;
};


/** The root type for all write operations. */
export type MutationUpdateVendorCreditMemoArgs = {
  creditMemo: VendorCreditMemoInput;
};


/** The root type for all write operations. */
export type MutationCreateVoucherToleranceArgs = {
  voucherTolerance: VoucherToleranceCreateInput;
};


/** The root type for all write operations. */
export type MutationUpdateVoucherToleranceArgs = {
  voucherTolerance: VoucherToleranceUpdateInput;
};


/** The root type for all write operations. */
export type MutationDeleteVoucherToleranceArgs = {
  voucherToleranceId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateVendorRequiredDocumentArgs = {
  vendorRequiredDocument: VendorRequiredDocumentCreateInput;
};


/** The root type for all write operations. */
export type MutationUpdateVendorRequiredDocumentArgs = {
  vendorRequiredDocument: VendorRequiredDocumentUpdateInput;
};


/** The root type for all write operations. */
export type MutationCreateOrupdateVendorRequiredDocumentsArgs = {
  vendorRequiredDocuments: ReadonlyArray<InputMaybe<VendorRequiredDocumentUpsertInput>>;
};


/** The root type for all write operations. */
export type MutationDeleteVendorRequiredDocumentArgs = {
  vendorRequiredDocumentId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDeleteCustomerRequiredDocumentGlobalConfigurationArgs = {
  customerRequiredDocumentGlobalConfigurationId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateVendorAssociatedRequiredDocumentArgs = {
  vendorAssociatedRequiredDocument: VendorAssociatedRequiredDocumentCreateInput;
};


/** The root type for all write operations. */
export type MutationCreateVendorAssociatedRequiredDocumentsArgs = {
  vendorAssociatedRequiredDocuments: ReadonlyArray<VendorAssociatedRequiredDocumentCreateInput>;
};


/** The root type for all write operations. */
export type MutationUpdateVendorAssociatedRequiredDocumentArgs = {
  vendorAssociatedRequiredDocument: VendorAssociatedRequiredDocumentUpdateInput;
};


/** The root type for all write operations. */
export type MutationDeleteVendorAssociatedRequiredDocumentArgs = {
  vendorAssociatedRequiredDocumentId: Scalars['ID'];
  recalcOptions?: InputMaybe<RequiredDocumentRecalcOptionsInputType>;
};


/** The root type for all write operations. */
export type MutationCopyGlobalVendorRequiredDocumentForCarrierArgs = {
  vendorId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateCustomerRequiredDocumentArgs = {
  customerRequiredDocument: CustomerRequiredDocumentCreateInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerRequiredDocumentsGlobalConfigurationArgs = {
  customerRequiredDocuments: ReadonlyArray<InputMaybe<CustomerRequiredDocumentGlobalConfigurationUpsertInput>>;
};


/** The root type for all write operations. */
export type MutationCreateCustomerRequiredDocumentsArgs = {
  customerRequiredDocuments: ReadonlyArray<InputMaybe<CustomerRequiredDocumentCreateInput>>;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerRequiredDocumentArgs = {
  customerRequiredDocument: CustomerRequiredDocumentUpdateInput;
};


/** The root type for all write operations. */
export type MutationDeleteCustomerRequiredDocumentArgs = {
  customerRequiredDocumentId: Scalars['ID'];
  recalcOptions?: InputMaybe<RequiredDocumentRecalcOptionsInputType>;
};


/** The root type for all write operations. */
export type MutationSynchronizeCustomersWithMasteryAccountingArgs = {
  customerIds: ReadonlyArray<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationSynchronizeVendorsWithMasteryAccountingArgs = {
  vendorIds: ReadonlyArray<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationCreateAdvanceProviderConfigAsyncArgs = {
  advanceProviderConfig: AdvanceProviderConfigCreateInputTypeV2;
};


/** The root type for all write operations. */
export type MutationUpdateAdvanceProviderConfigAsyncArgs = {
  advanceProviderConfig: AdvanceProviderConfigUpdateInputTypeV2;
};


/** The root type for all write operations. */
export type MutationCreateSegmentArgs = {
  segment: SegmentInput;
};


/** The root type for all write operations. */
export type MutationUpdateSegmentArgs = {
  segment: SegmentInput;
};


/** The root type for all write operations. */
export type MutationDeleteSegmentArgs = {
  segmentId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDeleteSegmentationConfigurationArgs = {
  segmentationConfigurationId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateSegmentationConfigurationArgs = {
  segmentationConfiguration: SegmentationConfigurationInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateSegmentationConfigurationsArgs = {
  segmentationConfigurations: ReadonlyArray<SegmentationConfigurationInput>;
};


/** The root type for all write operations. */
export type MutationUpdateSegmentationConfigurationArgs = {
  segmentationConfiguration: SegmentationConfigurationInput;
};


/** The root type for all write operations. */
export type MutationDeleteVendorInvoiceArgs = {
  vendorInvoiceId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateVendorInvoiceArgs = {
  vendorInvoice: VendorInvoiceInput;
};


/** The root type for all write operations. */
export type MutationUpdateVendorInvoiceArgs = {
  vendorInvoice: VendorInvoiceInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateVendorInvoiceArgs = {
  vendorInvoice: VendorInvoiceUpsertInput;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerOrderRequiredDocumentsArgs = {
  customerOrderRequiredDocuments: ReadonlyArray<CustomerOrderRequiredDocumentUpdateInput>;
};


/** The root type for all write operations. */
export type MutationDisassociateCustomerOrderRequiredDocumentsArgs = {
  disassociateCustomerOrderRequiredDocuments: ReadonlyArray<CustomerOrderRequiredDocumentDisassociateInput>;
};


/** The root type for all write operations. */
export type MutationWaiveCustomerOrderRequiredDocumentsArgs = {
  waiveCustomerOrderRequiredDocuments: ReadonlyArray<CustomerOrderRequiredDocumentWaiveInput>;
};


/** The root type for all write operations. */
export type MutationAssociateCustomerOrderRequiredDocumentsArgs = {
  associateCustomerOrderRequiredDocuments: ReadonlyArray<CustomerOrderRequiredDocumentAssociateInput>;
};


/** The root type for all write operations. */
export type MutationUpdateVendorRouteRequiredDocumentsArgs = {
  vendorRouteRequiredDocuments: ReadonlyArray<VendorRouteRequiredDocumentUpdateInput>;
};


/** The root type for all write operations. */
export type MutationAssociateVendorRouteRequiredDocumentsArgs = {
  associateVendorRouteRequiredDocuments: ReadonlyArray<VendorRouteRequiredDocumentAssociateInput>;
};


/** The root type for all write operations. */
export type MutationDisassociateVendorRouteRequiredDocumentsArgs = {
  disassociateVendorRouteRequiredDocuments: ReadonlyArray<VendorRouteRequiredDocumentDisassociateInputType>;
};


/** The root type for all write operations. */
export type MutationWaiveVendorRouteRequiredDocumentsArgs = {
  waiveVendorRouteRequiredDocuments: ReadonlyArray<VendorRouteRequiredDocumentWaiveInput>;
};


/** The root type for all write operations. */
export type MutationUpdateAccountingExceptionsArgs = {
  accountingExceptions: ReadonlyArray<AccountingExceptionUpdateInput>;
};


/** The root type for all write operations. */
export type MutationUpdateVoucherDateDefaultArgs = {
  id?: InputMaybe<Scalars['ID']>;
  defaultVoucherDateOption: DefaultVoucherDateOptionEnum;
};


/** The root type for all write operations. */
export type MutationUpdateCreateAdjustmentItemsCheckedArgs = {
  createAdjustmentItemsCheckedOption: CreateAdjustmentItemsCheckedOptionEnum;
};


/** The root type for all write operations. */
export type MutationCreateVoucherVendorToleranceArgs = {
  voucherVendorTolerance: VoucherVendorToleranceCreateInput;
};


/** The root type for all write operations. */
export type MutationUpdateVoucherVendorToleranceArgs = {
  voucherVendorTolerance: VoucherVendorToleranceUpdateInput;
};


/** The root type for all write operations. */
export type MutationDeleteVoucherVendorToleranceArgs = {
  voucherVendorToleranceId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationResendInvoiceToCustomerByInvoiceHeaderIdArgs = {
  invoiceId: Scalars['ID'];
  externalEmail: Scalars['String'];
  invoiceDistributionMethod: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationBatchResendInvoicesByInvoiceHeaderIdsArgs = {
  invoices: ReadonlyArray<InvoiceBatchDistributionInput>;
};


/** The root type for all write operations. */
export type MutationRecreateInvoiceToCustomerByInvoiceHeaderIdArgs = {
  invoiceId: Scalars['ID'];
  externalEmail?: InputMaybe<Scalars['String']>;
  invoiceDistributionMethod: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationBatchRecreateInvoicesByInvoiceHeaderIdsArgs = {
  invoices: ReadonlyArray<InvoiceBatchDistributionInput>;
};


/** The root type for all write operations. */
export type MutationUpdateRouteVendorThresholdsArgs = {
  routeId: Scalars['ID'];
  vendorId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateAdjustmentInterfacesArgs = {
  adjustmentInterfaces: ReadonlyArray<AdjustmentInterfaceCreateInput>;
};


/** The root type for all write operations. */
export type MutationUpdateAdjustmentInterfacesArgs = {
  adjustmentInterfaces: ReadonlyArray<AdjustmentInterfaceUpdateInput>;
};


/** The root type for all write operations. */
export type MutationCreateTaxInterfacesArgs = {
  taxInterfaces: ReadonlyArray<TaxInterfaceCreateInput>;
};


/** The root type for all write operations. */
export type MutationUpdateTaxInterfacesArgs = {
  taxInterfaces: ReadonlyArray<TaxInterfaceUpdateInput>;
};


/** The root type for all write operations. */
export type MutationCreateVendorInvoiceVouchersArgs = {
  vendorInvoiceVouchers: ReadonlyArray<VendorInvoiceVoucherInput>;
};


/** The root type for all write operations. */
export type MutationUpdateVendorInvoiceVoucherArgs = {
  vendorInvoiceVoucher: VendorInvoiceVoucherInput;
};


/** The root type for all write operations. */
export type MutationDeleteVendorInvoiceVoucherArgs = {
  vendorInvoiceVoucherId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationPerformAutoVoucherBatchArgs = {
  batch: AutoVoucherBatchInput;
};


/** The root type for all write operations. */
export type MutationUpdateOrderInvoiceRequirementArgs = {
  orderId: Scalars['ID'];
  bypass: Scalars['Boolean'];
};


/** The root type for all write operations. */
export type MutationUpdateOrderTaxRequirementsArgs = {
  orderId: Scalars['ID'];
  taxExempt: Scalars['Boolean'];
};


/** The root type for all write operations. */
export type MutationUpdateRouteVendorVoucherRequirementArgs = {
  vendorId: Scalars['ID'];
  routeId: Scalars['ID'];
  bypass: Scalars['Boolean'];
};


/** The root type for all write operations. */
export type MutationUpdateRouteVendorVoucherDisableFinancialsArgs = {
  vendorId: Scalars['ID'];
  routeId: Scalars['ID'];
  disableFinancials: Scalars['Boolean'];
};


/** The root type for all write operations. */
export type MutationCreateExchangeRateConfigurationArgs = {
  exchangeRateConfigurationModel: ExchangeRateConfigurationCreateInput;
};


/** The root type for all write operations. */
export type MutationUpdateExchangeRateConfigurationArgs = {
  exchangeRateConfigurationModel: ExchangeRateConfigurationUpdateInputType;
};


/** The root type for all write operations. */
export type MutationDeleteExchangeRateConfigurationArgs = {
  exchangeRateConfigurationId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationAddAutoAssociationUsersArgs = {
  userIds: ReadonlyArray<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationRemoveAutoAssociationUserArgs = {
  userId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationAutoAssociateDocumentsArgs = {
  autoAssociationUtilityInput: AutoAssociationUtilityParamsInput;
};


/** The root type for all write operations. */
export type MutationCreateCustomerOrderRequiredDocumentArgs = {
  customerOrderRequiredDocumentModel: CustomerOrderRequiredDocumentCreateInputType;
};


/** The root type for all write operations. */
export type MutationAddCustomerOrderRequiredDocumentsArgs = {
  customerOrderRequiredDocumentModels: ReadonlyArray<CustomerOrderRequiredDocumentCreateInputType>;
};


/** The root type for all write operations. */
export type MutationRemoveCustomerOrderRequiredDocumentArgs = {
  requiredDocumentId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationUpdateJurisdictionTaxArgs = {
  jurisdictionTaxModel: JurisdictionTaxInput;
};


/** The root type for all write operations. */
export type MutationCreateJurisdictionTaxArgs = {
  jurisdictionTaxModel: JurisdictionTaxInput;
};


/** The root type for all write operations. */
export type MutationDeleteJurisdictionTaxArgs = {
  jurisdictionTaxId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationUpdateTaxabilityChargeTypeArgs = {
  taxabilityChargeTypeModel: TaxabilityChargeType;
};


/** The root type for all write operations. */
export type MutationCreateBillToCodeArgs = {
  billToCodeModel: BillToCodeInput;
};


/** The root type for all write operations. */
export type MutationCreateBillToCodesArgs = {
  billToCodeModels: ReadonlyArray<InputMaybe<BillToCodeInput>>;
};


/** The root type for all write operations. */
export type MutationUpdateBillToCodeArgs = {
  billToCodeModel: BillToCodeInput;
};


/** The root type for all write operations. */
export type MutationSetDefaultBillToCodeAsyncArgs = {
  billToCodeId: Scalars['ID'];
  customerId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationUpdateBillToCodesArgs = {
  billToCodeModels: ReadonlyArray<InputMaybe<BillToCodeInput>>;
};


/** The root type for all write operations. */
export type MutationDeleteBillToCodeArgs = {
  billToCodeId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateTaxabilityChargeTypeArgs = {
  taxabilityChargeTypeModel: TaxabilityChargeType;
};


/** The root type for all write operations. */
export type MutationDeleteTaxabilityChargeTypeArgs = {
  taxabilityChargeTypeId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationSetDefaultChargeTypeForCountryArgs = {
  country: Scalars['String'];
  createdBy?: InputMaybe<Scalars['String']>;
};


/** The root type for all write operations. */
export type MutationSetDefaultChargeTypeForGivenCountryArgs = {
  country: Scalars['String'];
  taxability?: InputMaybe<Scalars['String']>;
  defaultTaxability?: InputMaybe<Scalars['Boolean']>;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerTaxExemptionArgs = {
  customerId: Scalars['ID'];
  isTaxExempt: Scalars['Boolean'];
};


/** The root type for all write operations. */
export type MutationUpdateCustomerAccountingDetailsArgs = {
  customerId: Scalars['ID'];
  isTaxExempt: Scalars['Boolean'];
  accountingStatus: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationUpdateCarrierAccountingDetailsArgs = {
  carrierId: Scalars['ID'];
  accountingStatus: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationUpdateCalculateVatCheckedArgs = {
  checkedOption: CalculateVatCheckedOptionEnumOutputType;
};


/** The root type for all write operations. */
export type MutationSetSegmentValuesArgs = {
  segmentValues: SegmentValues;
};


/** The root type for all write operations. */
export type MutationSetAdvanceStatusCashedArgs = {
  loadId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationUpdateVendorAutoVoucherArgs = {
  vendorId: Scalars['ID'];
  isAutoVoucherDisabled: Scalars['Boolean'];
};


/** The root type for all write operations. */
export type MutationUpdateVendorUseGlobalToggleArgs = {
  vendorId: Scalars['ID'];
  isUseGlobal: Scalars['Boolean'];
  recalcOptions?: InputMaybe<RequiredDocumentRecalcOptionsInputType>;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerUseGlobalToggleArgs = {
  customerId: Scalars['ID'];
  isUseGlobal: Scalars['Boolean'];
  recalcOptions?: InputMaybe<RequiredDocumentRecalcOptionsInputType>;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerAutoInvoiceArgs = {
  customerId: Scalars['ID'];
  isAutoInvoiceEnabled: Scalars['Boolean'];
};


/** The root type for all write operations. */
export type MutationSetSegmentationConfigurationIsFinalizedArgs = {
  segmentationConfigurationId: Scalars['ID'];
  isFinalized: Scalars['Boolean'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateExtractConfigurationArgs = {
  extractConfiguration: ExtractConfigurationInput;
};


/** The root type for all write operations. */
export type MutationDeleteExtractConfigurationArgs = {
  extractConfigurationId: Scalars['Uuid'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateIntegrationMappingArgs = {
  integrationMapping: IntegrationMappingInput;
};


/** The root type for all write operations. */
export type MutationDeleteIntegrationMappingArgs = {
  integrationMappingId: Scalars['Uuid'];
};


/** The root type for all write operations. */
export type MutationSetAccountingConfigurationValueArgs = {
  key: Scalars['String'];
  value: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationDistributeConsolidatedInvoicesArgs = {
  distributionDate: Scalars['DateTime'];
};


/** The root type for all write operations. */
export type MutationUpdateDisableFinancialsForCustomerOrdersArgs = {
  orderDisableFinancialsInput: ReadonlyArray<InputMaybe<OrderDisableFinancialsInput>>;
};


/** The root type for all write operations. */
export type MutationUpdateDisableFinancialsForRouteVendorsArgs = {
  routeVendorDisableFinancialsInput: ReadonlyArray<RouteVendorDisableFinancialsInput>;
};


/** The root type for all write operations. */
export type MutationUpdateRouteVendorIsApprovedForVoucheringArgs = {
  routeVendorApprovedForVoucheringInput: ReadonlyArray<RouteVendorHoldApprovedForVoucheringInput>;
};


/** The root type for all write operations. */
export type MutationUpdateOrderIsApprovedForInvoicingArgs = {
  orderHoldApprovedForInvoicingInput: ReadonlyArray<OrderHoldApprovedForInvoicingInput>;
};


/** The root type for all write operations. */
export type MutationUpdateOrderFuelStatusArgs = {
  updateOrderFuelStatusInput: UpdateOrderFuelIncludedStatusType;
};


/** The root type for all write operations. */
export type MutationCreateCustomerPaymentTermArgs = {
  createCustomerPaymentTermInput: CustomerPaymentTermAddInput;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerPaymentTermArgs = {
  updateCustomerPaymentTermInput: CustomerPaymentTermUpdateInput;
};


/** The root type for all write operations. */
export type MutationDeleteCustomerPaymentTermArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateCarrierPaymentTermArgs = {
  createCarrierPaymentTermInput: CarrierPaymentTermAddInput;
};


/** The root type for all write operations. */
export type MutationUpdateCarrierPaymentTermArgs = {
  updateCarrierPaymentTermInput: CarrierPaymentTermUpdateInput;
};


/** The root type for all write operations. */
export type MutationDeleteCarrierPaymentTermArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationUpdateRouteBypassFuelStatusArgs = {
  updateRouteBypassFuelStatusInput: UpdateRouteBypassFuelStatusInput;
};


/** The root type for all write operations. */
export type MutationCreateTripArgs = {
  input: CreateTripInput;
};


/** The root type for all write operations. */
export type MutationDeleteTripArgs = {
  input: DeleteTripInput;
};


/** The root type for all write operations. */
export type MutationAddRouteToTripArgs = {
  input: AddRouteToTripInput;
};


/** The root type for all write operations. */
export type MutationRemoveRouteFromTripArgs = {
  input: RemoveRouteFromTripInput;
};


/** The root type for all write operations. */
export type MutationCreateDummyProjectNameExampleArgs = {
  input: CreateDummyProjectNameExampleInput;
};


/** The root type for all write operations. */
export type MutationUpdateVoucheringRequirementConfigurationArgs = {
  input: VoucheringRequirementConfigurationUpdateInput;
};


/** The root type for all write operations. */
export type MutationUpdateInvoicingRequirementConfigurationArgs = {
  input: InvoicingRequirementConfigurationUpdateInput;
};


/** The root type for all write operations. */
export type MutationUpdateRouteVendorRequirementArgs = {
  input: RouteVendorRequirementUpdateInputType;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerOrderRequirementArgs = {
  input: CustomerOrderRequirementUpdateInputType;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverPaySettingsArgs = {
  driverPaySettingInput?: InputMaybe<DriverPaySettingInput>;
};


/** The root type for all write operations. */
export type MutationDeleteDriverPaySettingArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateNonRouteCostDetailArgs = {
  input?: InputMaybe<NonRouteCostDetailCreateInput>;
};


/** The root type for all write operations. */
export type MutationUpdateNonRouteCostDetailArgs = {
  input?: InputMaybe<NonRouteCostDetailUpdateInput>;
};


/** The root type for all write operations. */
export type MutationDeleteNonRouteCostDetailArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDeleteDriverVoucherHeaderArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreatePublishDriverVoucherProcessingRequestArgs = {
  input?: InputMaybe<DriverVoucherProcessingRequestedCreateInput>;
};


/** The root type for all write operations. */
export type MutationCreateAndProcessDriverVouchersArgs = {
  input?: InputMaybe<CreateAndProcessDriverVouchersInput>;
};


/** The root type for all write operations. */
export type MutationSetJobConfigurationArgs = {
  input?: InputMaybe<JobConfigurationInput>;
};


/** The root type for all write operations. */
export type MutationEvaluateDriverVouchersArgs = {
  input?: InputMaybe<EvaluateDriverVouchersInput>;
};


/** The root type for all write operations. */
export type MutationEditDriverVoucherHeadersArgs = {
  input?: InputMaybe<DriverVoucherHeadersUpdateInput>;
};


/** The root type for all write operations. */
export type MutationEditDriverVoucherHeaderArgs = {
  input?: InputMaybe<EditDriverVoucherHeaderInput>;
};


/** The root type for all write operations. */
export type MutationSaveCustomerRatingSourceArgs = {
  customerRating: SaveCustomerRatingSourceInput;
};


/** The root type for all write operations. */
export type MutationCreateBulkReratingRequestArgs = {
  request: CreateBulkReratingRequestInput;
};


/** The root type for all write operations. */
export type MutationStartBulkReratingRequestArgs = {
  requestId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCancelBulkReratingRequestArgs = {
  requestId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationAddLoadsToBulkReratingRequestArgs = {
  request: AddLoadsToBulkReratingRequestInput;
};


/** The root type for all write operations. */
export type MutationSaveCustomerPriorityQuoteTypeArgs = {
  priorityQuoteType: CustomerPriorityQuoteTypeInput;
};


/** The root type for all write operations. */
export type MutationSaveCustomerAccessorialSettingsArgs = {
  input: SaveCustomerAccessorialSettingsInput;
};


/** The root type for all write operations. */
export type MutationSaveCarrierAccessorialSettingsArgs = {
  input: SaveCarrierAccessorialSettingsInput;
};


/** The root type for all write operations. */
export type MutationCreateCarrierCapacityConstraintArgs = {
  carrierCapacityConstraintInput?: InputMaybe<CarrierCapacityConstraintInput>;
};


/** The root type for all write operations. */
export type MutationUpdateCarrierCapacityConstraintArgs = {
  updateCarrierCapacityConstraintInput?: InputMaybe<UpdateCarrierCapacityConstraintInput>;
};


/** The root type for all write operations. */
export type MutationSoftAllocateCarrierCapacityByCostQuoteAndDateConstraintArgs = {
  softAllocateCarrierCapacityInput?: InputMaybe<SoftAllocateCarrierCapacityInput>;
};


/** The root type for all write operations. */
export type MutationCreateAssetDriverTenderArgs = {
  createAssetDriverTenderInput: CreateAssetDriverTenderInput;
};


/** The root type for all write operations. */
export type MutationCreateAssetDriverTenderAndSendToDriverArgs = {
  createAssetDriverTenderInput: CreateAssetDriverTenderInput;
};


/** The root type for all write operations. */
export type MutationSendAssetDriverTenderToDriverArgs = {
  sendAssetDriverTenderInput: SendAssetDriverTenderInput;
};


/** The root type for all write operations. */
export type MutationAcceptAssetDriverTenderArgs = {
  acceptDriverTenderInput: AssetDriverTenderResponseInput;
};


/** The root type for all write operations. */
export type MutationDeclineAssetDriverTenderArgs = {
  declineDriverTenderInput: AssetDriverTenderResponseInput;
};


/** The root type for all write operations. */
export type MutationSendToOpenBoardArgs = {
  routeId: Scalars['ID'];
  loadId?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationCreateRoutingGuideV2Args = {
  routingGuide: CreateRoutingGuideInputV2;
};


/** The root type for all write operations. */
export type MutationUpdateRoutingGuideArgs = {
  routingGuide: UpdateRoutingGuideInput;
};


/** The root type for all write operations. */
export type MutationCreateRoutingGuideSequenceItemArgs = {
  routingGuideSequenceItem: CreateRoutingGuideSequenceItemInput;
};


/** The root type for all write operations. */
export type MutationUpdateRoutingGuideSequenceItemArgs = {
  routingGuideSequenceItem: UpdateRoutingGuideSequenceItemInput;
};


/** The root type for all write operations. */
export type MutationDeleteRoutingGuideSequenceItemArgs = {
  sequenceItemId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationReorderRoutingGuideSequenceItemsArgs = {
  routingGuideId: Scalars['ID'];
  routingGuideSequenceItems: ReadonlyArray<ReorderRoutingGuideSequenceItemsInput>;
};


/** The root type for all write operations. */
export type MutationSaveRoutingGuideArgs = {
  routingGuide: SaveRoutingGuideInput;
};


/** The root type for all write operations. */
export type MutationUploadRoutingGuidesArgs = {
  uploadRoutingGuidesInput: UploadRoutingGuidesInput;
};


/** The root type for all write operations. */
export type MutationUploadRoutingGuidesV2Args = {
  input: UploadRoutingGuidesV2Input;
};


/** The root type for all write operations. */
export type MutationUpsertTenderOptionsArgs = {
  upsertTenderOptionsInput?: InputMaybe<UpsertTenderOptionsInput>;
};


/** The root type for all write operations. */
export type MutationReorderTenderPlanTenderSequenceArgs = {
  tenderPlanId: Scalars['ID'];
  tenderSequences: ReadonlyArray<ReorderTenderPlanTenderSequenceInput>;
};


/** The root type for all write operations. */
export type MutationToggleTenderSkipStatusArgs = {
  tenderPlanId: Scalars['ID'];
  tenderId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateTenderPlanArgs = {
  routeId: Scalars['ID'];
  createdByUserId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationStartTenderPlanArgs = {
  tenderPlanId: Scalars['ID'];
  startedByUserId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationStopTenderPlanArgs = {
  tenderPlanId: Scalars['ID'];
  stoppedByUserId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationRecreateTenderPlanArgs = {
  routeId: Scalars['ID'];
  recreatedByUserId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateOutboundTenderSettingsArgs = {
  outboundTenderSettingsInput?: InputMaybe<OutboundTenderSettingsInput>;
};


/** The root type for all write operations. */
export type MutationUpdateOutboundTenderSettingsArgs = {
  updateOutboundTenderSettingsInput?: InputMaybe<UpdateOutboundTenderSettingsInput>;
};


/** The root type for all write operations. */
export type MutationUpdateMultipleTenderOptionsArgs = {
  updateMultipleTenderOptionsInput?: InputMaybe<UpdateMultipleTenderOptionsInput>;
};


/** The root type for all write operations. */
export type MutationInsertMultipleTenderOptionsArgs = {
  insertMultipleTenderOptionsInput?: InputMaybe<InsertMultipleTenderOptionsInput>;
};


/** The root type for all write operations. */
export type MutationSendToOpenBoardV2Args = {
  routeId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationFlipDriverCurrentAndNextRoutesArgs = {
  input: DriverFlipInput;
};


/** The root type for all write operations. */
export type MutationFlipPowerUnitCurrentAndNextRoutesArgs = {
  input: PowerUnitFlipInput;
};


/** The root type for all write operations. */
export type MutationUpdateWorkflowJobArgs = {
  updateWorkflowJobInput: UpdateWorkflowJobInput;
};


/** The root type for all write operations. */
export type MutationSendWorkflowJobArgs = {
  SendWorkflowJobInput: SendWorkflowJobInput;
};


/** The root type for all write operations. */
export type MutationCreateWorkflowJobArgs = {
  workflowJobInput: WorkflowJobInput;
};


/** The root type for all write operations. */
export type MutationUpdateWorkFlowJobRecordArgs = {
  updateWorkflowJobInput: UpdateWorkflowJobRecordInput;
};


/** The root type for all write operations. */
export type MutationUpdatWfjRecordArgs = {
  updateWorkflowJobInput: UpdateWfjRecordInput;
};


/** The root type for all write operations. */
export type MutationUpdateDriversForWorkFlowJobRecordArgs = {
  updateDriversForWorkflowJobRecordInput: UpdateDriversForWorkflowJobRecordInput;
};


/** The root type for all write operations. */
export type MutationDeleteWorkflowJobArgs = {
  routeId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCheckAndAutoPostArgs = {
  input: CheckAndAutopostInput;
};


/** The root type for all write operations. */
export type MutationAssignAccountingChargeTypeToBlumeChargeTypeArgs = {
  input: AssignAccountingChargeTypeToBlumeChargeTypeInput;
};


/** The root type for all write operations. */
export type MutationImportBlumeChargesArgs = {
  input: ImportBlumeChargesInput;
};


/** The root type for all write operations. */
export type MutationRebookRouteVendorV2Args = {
  input: RebookRouteVendorV2Input;
};


/** The root type for all write operations. */
export type MutationAssignVendorToRouteV2Args = {
  input: AssignVendorToRouteV2Input;
};


/** The root type for all write operations. */
export type MutationBulkAssignVendorToRoutesV2Args = {
  input: BulkAssignVendorToRoutesV2Input;
};


/** The root type for all write operations. */
export type MutationUpdateRouteVendorV2Args = {
  input: UpdateRouteVendorV2Input;
};


/** The root type for all write operations. */
export type MutationUpdateBounceV2Args = {
  input: UpdateBounceV2Input;
};


/** The root type for all write operations. */
export type MutationBounceRouteVendorV2Args = {
  input: BounceRouteVendorV2Input;
};


/** The root type for all write operations. */
export type MutationValidateBookingArgs = {
  input: ValidateBookingInput;
};


/** The root type for all write operations. */
export type MutationValidateMassBookingArgs = {
  input: ValidateMassBookingInput;
};


/** The root type for all write operations. */
export type MutationBookCarrierAsyncArgs = {
  input: BookCarrierAsyncInput;
};


/** The root type for all write operations. */
export type MutationBounceCarrierAsyncArgs = {
  input: BounceCarrierAsyncInput;
};


/** The root type for all write operations. */
export type MutationUpdateRouteVendorReferencesArgs = {
  input: RouteVendorReferencesInput;
};


/** The root type for all write operations. */
export type MutationReplaceRouteVendorReferencesArgs = {
  input: ReplaceRouteVendorReferencesInput;
};


/** The root type for all write operations. */
export type MutationBookCarrierArgs = {
  input: BookCarrierInput;
};


/** The root type for all write operations. */
export type MutationBookDriversArgs = {
  input: BookDriverInput;
};


/** The root type for all write operations. */
export type MutationBookRoutesAsyncArgs = {
  input: ReadonlyArray<BookRouteAsyncInput>;
};


/** The root type for all write operations. */
export type MutationCreateCarrierCrmNotesArgs = {
  createCarrierCrmNotesInput: CreateCarrierCrmNotesInput;
};


/** The root type for all write operations. */
export type MutationCreateCarrierCrmOpportunityArgs = {
  createCarrierCrmOpportunityInput: CreateCarrierCrmOpportunityInput;
};


/** The root type for all write operations. */
export type MutationCreateCarrierExportNotesArgs = {
  createCarrierExportNoteRequest: CreateCarrierExportNoteInput;
};


/** The root type for all write operations. */
export type MutationCreateCarrierInteractionArgs = {
  carrierInteractionRequest: CreateCarrierInteractionInput;
};


/** The root type for all write operations. */
export type MutationCreateCustomerCrmNotesArgs = {
  createCustomerNotesRequest: CreateCustomerCrmNotesRequest;
};


/** The root type for all write operations. */
export type MutationCreateCustomerCrmOpportunityArgs = {
  createCustomerCrmOpportunityInput: CreateCustomerCrmOpportunityInput;
};


/** The root type for all write operations. */
export type MutationCreateCustomerExportNotesArgs = {
  createCustomerExportNoteRequest: CreateCustomerExportNoteInput;
};


/** The root type for all write operations. */
export type MutationCreateCustomerInteractionArgs = {
  customerInteractionRequest: CreateCustomerInteractionInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCrmCarrierDetailArgs = {
  carrierDetailRequest: CrmCarrierDetailInputType;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCrmCustomerDetailArgs = {
  customerDetailRequest: CreateCrmCustomerDetailInputType;
};


/** The root type for all write operations. */
export type MutationDeleteCarrierCrmDocumentsArgs = {
  deleteCarrierCrmDocumentInput: DeleteCarrierCrmDocumentInput;
};


/** The root type for all write operations. */
export type MutationDeleteCustomerCrmDocumentsArgs = {
  deleteCustomerCrmDocumentInput: DeleteCustomerCrmDocumentInput;
};


/** The root type for all write operations. */
export type MutationUpdateCarrierCrmDocumentsArgs = {
  updateCarrierCrmDocumentInput: UpdateCarrierCrmDocumentInput;
};


/** The root type for all write operations. */
export type MutationUpdateCarrierCrmNotesArgs = {
  updateCarrierCrmNotesInput: UpdateCarrierCrmNotesInput;
};


/** The root type for all write operations. */
export type MutationUpdateCarrierCrmOpportunityArgs = {
  updateCarrierCrmOpportunityInput: UpdateCarrierCrmOpportunityInput;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerCrmDocumentsArgs = {
  updateCustomerCrmDocumentInput: UpdateCustomerCrmDocumentInput;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerCrmNotesArgs = {
  updateCustomerNotesRequest: UpdateCustomerCrmNotesRequest;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerCrmOpportunityArgs = {
  updateCustomerCrmOpportunityInput: UpdateCustomerCrmOpportunityInput;
};


/** The root type for all write operations. */
export type MutationSaveContactArgs = {
  obj_CRM_Contact: CrmContactInput;
};


/** The root type for all write operations. */
export type MutationUpdateContactAssociatedEntityArgs = {
  obj_Main_Email_Id: Scalars['String'];
  obj_Main_Phone_Id: Scalars['String'];
  obj_Contact_Associated_Entity_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationUpdateContactNameInformationDataArgs = {
  obj_CRM_Contact: CrmContactInput;
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationUpdateContactGeneralDataArgs = {
  obj_CRM_Contact_Pricing_Behaviour: ReadonlyArray<CrmContactPricingBehaviourInput>;
  obj_CRM_Contact: CrmContactInput;
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationUpdateContactBusinessInformationDataArgs = {
  obj_CRM_Contact: CrmContactInput;
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveAssociatedRepsArgs = {
  obj_CRM_Contact_Associated_Rep: CrmContactAssociatedRepInput;
};


/** The root type for all write operations. */
export type MutationInactivateContactAssociatedRepByIdArgs = {
  obj_Is_Active: Scalars['Boolean'];
  obj_Contact_Associated_Rep_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationInactivateContactInformationByIdArgs = {
  obj_Is_Active: Scalars['Boolean'];
  obj_Contact_Information_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationModifyAssociatedRepByIdArgs = {
  obj_CRM_Contact_Associated_Rep: CrmContactAssociatedRepInput;
  obj_Contact_Associated_Rep_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveContactInformationArgs = {
  obj_CRM_Contact_Information: CrmContactInformationInput;
};


/** The root type for all write operations. */
export type MutationModifyContactInformationArgs = {
  obj_CRM_Contact_Information: CrmContactInformationInput;
  obj_Contact_Information_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveContactWorkHoursArgs = {
  obj_CRM_Contact_Work_Hours: ReadonlyArray<CrmContactWorkHoursInput>;
};


/** The root type for all write operations. */
export type MutationModifyContactWorkHourArgs = {
  obj_Contact_Work_Hour: CrmContactWorkHoursInput;
  obj_Contact_Work_Hour_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationModifyAssociatedEntityByIdV2Args = {
  obj_CRM_Contact_Associated_Entity: CrmContactAssociatedEntityInput;
  obj_Contact_Associated_Entity_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationDeleteAssociatedEntityByIdV2Args = {
  obj_Contact_Associated_Entity_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveAssociatedEntityV2Args = {
  obj_Associated_Entity: CrmContactAssociatedEntityInput;
};


/** The root type for all write operations. */
export type MutationSaveContactv2Args = {
  obj_CRM_Contact: CrmContactInputV2;
};


/** The root type for all write operations. */
export type MutationModifyContactAssociatedEntityv2Args = {
  obj_Main_Email_Id: Scalars['String'];
  obj_Main_Phone_Id: Scalars['String'];
  obj_Contact_Associated_Entity_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationModifyContactNameInformationDatav2Args = {
  obj_CRM_Contact: CrmContactInputV2;
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationModifyContactGeneralDatav2Args = {
  obj_CRM_Contact_Pricing_Behaviour: ReadonlyArray<CrmContactPricingBehaviourInput>;
  obj_CRM_Contact: CrmContactInputV2;
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationModifyContactBusinessInformationDatav2Args = {
  obj_CRM_Contact: CrmContactInputV2;
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveAssociatedRepsv2Args = {
  obj_CRM_Contact_Associated_Rep: CrmContactAssociatedRepInputV2;
};


/** The root type for all write operations. */
export type MutationInactivateContactAssociatedRepByIdv2Args = {
  obj_Is_Active: Scalars['Boolean'];
  obj_Contact_Associated_Rep_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationInactivateContactInformationByIdv2Args = {
  obj_Is_Active: Scalars['Boolean'];
  obj_Contact_Information_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationModifyAssociatedRepByIdv2Args = {
  obj_CRM_Contact_Associated_Rep: CrmContactAssociatedRepInputV2;
  obj_Contact_Associated_Rep_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveContactInformationv2Args = {
  obj_CRM_Contact_Information: CrmContactInformationInputV2;
};


/** The root type for all write operations. */
export type MutationModifyContactInformationv2Args = {
  obj_CRM_Contact_Information: CrmContactInformationInputV2;
  obj_Contact_Information_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveContactWorkHoursv2Args = {
  obj_CRM_Contact_Work_Hours: ReadonlyArray<CrmContactWorkHoursInput>;
};


/** The root type for all write operations. */
export type MutationModifyContactWorkHourv2Args = {
  obj_Contact_Work_Hour: CrmContactWorkHoursInput;
  obj_Contact_Work_Hour_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveAssociatedEntityv2Args = {
  obj_Associated_Entity: CrmContactAssociatedEntityInputV2;
};


/** The root type for all write operations. */
export type MutationDeleteAssociatedEntityByIdv2Args = {
  obj_Contact_Associated_Entity_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationModifyAssociatedEntityByIdv2Args = {
  obj_CRM_Contact_Associated_Entity: CrmContactAssociatedEntityInputV2;
  obj_Contact_Associated_Entity_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveResponsibilityv2Args = {
  obj_CRM_Contact_Responsibility: CrmContactResponsibilityInputV2;
};


/** The root type for all write operations. */
export type MutationDeleteResponsibilityByIdv2Args = {
  obj_Contact_Responsibility_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationModifyResponsibilityByIdv2Args = {
  obj_CRM_Contact_Responsibility: CrmContactResponsibilityInputV2;
  obj_Contact_Responsibility_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveContactSliderv2Args = {
  obj_CRM_Contact_Slider: CrmContactSliderInputV2;
};


/** The root type for all write operations. */
export type MutationModifyContactSliderv2Args = {
  obj_CRM_Contact_Slider: CrmContactSliderInputV2;
  obj_CRM_Contact_Slider_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveContactInterestHobbyv2Args = {
  obj_CRM_Contact_Interest_Hobby: ReadonlyArray<CrmContactInterestHobbyInputV2>;
};


/** The root type for all write operations. */
export type MutationModifyContactInterestHobbyv2Args = {
  obj_CRM_Contact_Interest_Hobby: ReadonlyArray<CrmContactInterestHobbyInputV2>;
  obj_CRM_Contact_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmSaveContactArgs = {
  contact: CrmContactInput;
};


/** The root type for all write operations. */
export type MutationCrmModifyContactAssociatedEntityArgs = {
  mainEmailId: Scalars['String'];
  mainPhoneId: Scalars['String'];
  associatedEntityId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmModifyContactNameInformationDataArgs = {
  contact: CrmContactInput;
  contactId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmModifyContactGeneralDataArgs = {
  pricingBehaviors: ReadonlyArray<CrmContactPricingBehaviourInput>;
  contact: CrmContactInput;
  contactId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmModifyContactBusinessInformationDataArgs = {
  contact: CrmContactInput;
  contactId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmSaveAssociatedRepsArgs = {
  associatedRep: CrmContactAssociatedRepInput;
};


/** The root type for all write operations. */
export type MutationCrmInactivateContactAssociatedRepByIdArgs = {
  associatedRepId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmInactivateContactInformationByIdArgs = {
  isActive: Scalars['Boolean'];
  informationId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmModifyAssociatedRepByIdArgs = {
  associatedRep: CrmContactAssociatedRepInput;
  associatedRepId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmSaveContactInformationArgs = {
  information: CrmContactInformationInput;
};


/** The root type for all write operations. */
export type MutationCrmModifyContactInformationArgs = {
  information: CrmContactInformationInput;
  informationId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmSaveContactWorkHoursArgs = {
  workHours: ReadonlyArray<CrmContactWorkHoursInput>;
};


/** The root type for all write operations. */
export type MutationCrmModifyContactWorkHoursArgs = {
  workHour: ReadonlyArray<CrmContactWorkHoursInput>;
  contactId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmModifyContactWorkHourArgs = {
  workHour: CrmContactWorkHoursInput;
  workHourId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmSaveAssociatedEntityArgs = {
  associatedEntity: CrmContactAssociatedEntityInput;
};


/** The root type for all write operations. */
export type MutationCrmInactivateAssociatedEntityByIdArgs = {
  associatedEntityId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmModifyAssociatedEntityByIdArgs = {
  associatedEntity: CrmContactAssociatedEntityInput;
  associatedEntityId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmSaveResponsibilityArgs = {
  responsibility: CrmContactResponsibilityInput;
};


/** The root type for all write operations. */
export type MutationCrmInactivateResponsibilityByIdArgs = {
  responsibilityId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmModifyResponsibilityByIdArgs = {
  responsibility: CrmContactResponsibilityInput;
  responsibilityId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmSaveContactSliderArgs = {
  slider: CrmContactSliderInput;
};


/** The root type for all write operations. */
export type MutationCrmModifyContactSliderArgs = {
  slider: CrmContactSliderInput;
  sliderId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmSaveContactInterestHobbyArgs = {
  interestHobbies: ReadonlyArray<CrmContactInterestHobbyInput>;
};


/** The root type for all write operations. */
export type MutationCrmModifyContactInterestsHobbiesArgs = {
  interestHobbies: ReadonlyArray<CrmContactInterestHobbyInput>;
  contactId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmModifyContactInterestHobbyArgs = {
  interestHobbies: ReadonlyArray<CrmContactInterestHobbyInput>;
  contactId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmSaveModifyMatchingLogicContactArgs = {
  contact: CrmContactInput;
};


/** The root type for all write operations. */
export type MutationCrmCompanyDescriptionSaveArgs = {
  objCrmCompanyDescription: CrmCompanyDescriptionInput;
};


/** The root type for all write operations. */
export type MutationCrmCompanyDescriptionEditArgs = {
  objCrmCompanyDescription: CrmCompanyDescriptionInput;
  objCompanyDescriptionId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmDetailedDescriptionSaveArgs = {
  objDetailedDescription: CrmDetailedDescriptionInput;
};


/** The root type for all write operations. */
export type MutationCrmDetailedDescriptionEditArgs = {
  objCrmDetailedDescription: CrmDetailedDescriptionInput;
  objDetailedDescriptionId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmDetailedDescriptionDeleteArgs = {
  objDetailedDescriptionId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmFirmographicsSaveArgs = {
  objFirmographics: CrmFirmographicsInput;
};


/** The root type for all write operations. */
export type MutationCrmFirmographicsEditArgs = {
  objFirmographics: CrmFirmographicsInput;
  objFirmographicsId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmFirmographicsDeleteArgs = {
  objFirmographicsId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmIndustriesSaveArgs = {
  objIndustries: CrmIndustriesInput;
};


/** The root type for all write operations. */
export type MutationCrmIndustriesEditArgs = {
  objIndustries: CrmIndustriesInput;
  objIndustriesId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmIndustriesDeleteArgs = {
  objIndustriesId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmInteractionDeleteArgs = {
  obj_CRM_ID: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmInteractionSaveArgs = {
  obj_CRM_Interaction: CrmInteractionInput;
};


/** The root type for all write operations. */
export type MutationCrmInteractionObjectiveSaveArgs = {
  obj_CRM_Interaction_Objective: CrmInteractionObjectiveInput;
};


/** The root type for all write operations. */
export type MutationCrmInteractionRescheduleSaveArgs = {
  obj_CRM_Interaction_Reschedule: CrmInteractionRescheduleInput;
};


/** The root type for all write operations. */
export type MutationCrmInteractionAppendArgs = {
  interaction_RepGroup_Input: ReadonlyArray<CrmInteractionRepGroupInput>;
  interaction_Objective_Input: ReadonlyArray<CrmInteractionObjectiveInput>;
  interaction_Contact_Input: ReadonlyArray<CrmInteractionContactInput>;
  interaction_Opportunity_Input: ReadonlyArray<CrmInteractionOpportunityInput>;
  obj_CRM_Interaction: CrmInteractionInput;
  obj_Interaction_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmInteractionAssignRepGroupReassignArgs = {
  interaction_RepGroup_Input: ReadonlyArray<CrmInteractionRepGroupInput>;
  obj_CRM_Interaction: CrmInteractionInput;
  obj_Interaction_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmInteractionDocumentSaveArgs = {
  obj_CRM_Interaction_Document: CrmInteractionDocumentInput;
};


/** The root type for all write operations. */
export type MutationCrmInteractionDocumentDeleteArgs = {
  obj_CRM_Interaction_Document_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmInteractionDocumentRemoveArgs = {
  DocumentId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmInteractionDeleteV2Args = {
  interactionId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmInteractionSaveV2Args = {
  interaction: CrmInteractionInputV2;
};


/** The root type for all write operations. */
export type MutationCrmInteractionObjectiveSaveV2Args = {
  interactionObjective: CrmInteractionObjectiveInputV2;
};


/** The root type for all write operations. */
export type MutationCrmInteractionRescheduleSaveV2Args = {
  interactionReschedule: CrmInteractionRescheduleAuditInputV2;
};


/** The root type for all write operations. */
export type MutationCrmInteractionAppendV2Args = {
  repGroups: ReadonlyArray<CrmInteractionRepGroupInputV2>;
  objectives: ReadonlyArray<CrmInteractionObjectiveInputV2>;
  contacts: ReadonlyArray<CrmInteractionContactInputV2>;
  opportunities: ReadonlyArray<CrmInteractionOpportunityInputV2>;
  interaction: CrmInteractionInputV2;
  interactionId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmInteractionAssignRepGroupReassignV2Args = {
  repGroups: ReadonlyArray<CrmInteractionRepGroupInputV2>;
  interaction: CrmInteractionInputV2;
  interactionId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmInteractionDocumentSaveV2Args = {
  documents: CrmInteractionDocumentInputV2;
};


/** The root type for all write operations. */
export type MutationCrmInteractionDocumentRemoveV2Args = {
  documentId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateInteractionArgs = {
  interaction: CrmInteractionInputV2;
};


/** The root type for all write operations. */
export type MutationCrmOpportunitySaveArgs = {
  obj_CRM_Opportunity: CrmOpportunityInput;
};


/** The root type for all write operations. */
export type MutationCrmOpportunitySaveV2Args = {
  objCrmOpportunity: CrmOpportunityInputV2;
};


/** The root type for all write operations. */
export type MutationCrmOpportunityAppendV2Args = {
  objCrmOpportunity: CrmOpportunityInputV2;
  objOpportunityId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationAcquireLockArgs = {
  userID?: InputMaybe<Scalars['String']>;
  flavor: LockFlavor;
  objectID: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationMassAcquireLockArgs = {
  input: MassAcquireLockInput;
};


/** The root type for all write operations. */
export type MutationReleaseLockArgs = {
  userID?: InputMaybe<Scalars['String']>;
  flavor: LockFlavor;
  objectID: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationMassReleaseLockArgs = {
  input: MassReleaseLockInput;
};


/** The root type for all write operations. */
export type MutationSendLockArgs = {
  receiverID?: InputMaybe<Scalars['String']>;
  userID?: InputMaybe<Scalars['String']>;
  flavor: LockFlavor;
  objectID: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationAdminReleaseLockArgs = {
  flavor: LockFlavor;
  objectID: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationAdminBypassLockArgs = {
  flavor: LockFlavor;
  objectID: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationRefreshLockArgs = {
  userID?: InputMaybe<Scalars['String']>;
  flavor: LockFlavor;
  objectID: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationMassRefreshLockArgs = {
  input: MassRefreshLockInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierCommitmentArgs = {
  input: CarrierCommitmentInputType;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierCarrierRelationshipArgs = {
  input: CreateOrUpdateCarrierCarrierRelationshipInput;
};


/** The root type for all write operations. */
export type MutationDeleteCarrierCarrierRelationshipArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierV2Args = {
  input: CarrierInputV2;
};


/** The root type for all write operations. */
export type MutationCreateChildrenCarrierArgs = {
  input: ChildrenCarrierInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierCertificationV2Args = {
  input: CarrierCertificationInputV2;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierCustomerRelationshipV2Args = {
  input: CarrierCustomerRelationshipInputV2;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierServiceV2Args = {
  input: CarrierServiceStandaloneInputV2;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierSettingV2Args = {
  input: CarrierSettingInputV2;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierReleaseMethodArgs = {
  input: CarrierReleaseMethodInput;
};


/** The root type for all write operations. */
export type MutationDestroyCarrierCertificationV2Args = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationDestroyCarrierIntrastateStateV2Args = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationDestroyCarrierIdentifierArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationDestroyCarrierServiceV2Args = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDestroyCarrierRepArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDestroyCarrierReleaseMethodArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationUpdateCarrierIntrastateV2Args = {
  input: CarrierIntrastateInputV2;
};


/** The root type for all write operations. */
export type MutationUpdateCarrierStandingV2Args = {
  input: CarrierStandingInputV2;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierAddressArgs = {
  input: CreateCarrierAddressInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierRepArgs = {
  input: CreateCarrierRepInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierIdentifierArgs = {
  input: CreateCarrierIdentifierInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierContactArgs = {
  input: CreateCarrierContactInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierParentArgs = {
  input: CreateOrUpdateCarrierParentInput;
};


/** The root type for all write operations. */
export type MutationDestroyCarrierAddressArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationDestroyCarrierContactArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierGroupArgs = {
  input: CarrierGroupInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierElectronicTrackingArgs = {
  input: CreateOrUpdateCarrierElectronicTrackingInput;
};


/** The root type for all write operations. */
export type MutationDestroyCarrierElectronicTrackingArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierInsuranceArgs = {
  input: CarrierInsuranceInputV2;
};


/** The root type for all write operations. */
export type MutationDestroyCarrierInsuranceArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationCreateCarrierMultipleChildrenArgs = {
  input: ReadonlyArray<InputMaybe<ChildrenCarrierInput>>;
};


/** The root type for all write operations. */
export type MutationCreateTenantChargeTypeConfigurationArgs = {
  input?: InputMaybe<TenantChargeTypeConfigurationCreateInput>;
};


/** The root type for all write operations. */
export type MutationCreateTenantChargeTypeConfigurationV2Args = {
  input?: InputMaybe<TenantChargeTypeConfigurationCreateInputV2>;
};


/** The root type for all write operations. */
export type MutationUpdateTenantChargeTypeConfigurationArgs = {
  input?: InputMaybe<TenantChargeTypeConfigurationUpdateInput>;
};


/** The root type for all write operations. */
export type MutationUpdateTenantChargeTypeConfigurationV2Args = {
  input?: InputMaybe<TenantChargeTypeConfigurationUpdateInputV2>;
};


/** The root type for all write operations. */
export type MutationDeleteTenantChargeTypeConfigurationArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDeleteTenantChargeTypeConfigurationV2Args = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreatePriorityConfigurationArgs = {
  input?: InputMaybe<PriorityConfigurationCreateInput>;
};


/** The root type for all write operations. */
export type MutationUpdatePriorityConfigurationArgs = {
  input?: InputMaybe<PriorityConfigurationUpdateInput>;
};


/** The root type for all write operations. */
export type MutationDeletePriorityConfigurationArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateCarrierChargeTypeConfigurationArgs = {
  input?: InputMaybe<CarrierChargeTypeConfigurationCreateInput>;
};


/** The root type for all write operations. */
export type MutationCreateCarrierChargeTypeConfigurationV2Args = {
  input?: InputMaybe<CarrierChargeTypeConfigurationCreateInputV2>;
};


/** The root type for all write operations. */
export type MutationUpdateCarrierChargeTypeConfigurationArgs = {
  input?: InputMaybe<CarrierChargeTypeConfigurationUpdateInput>;
};


/** The root type for all write operations. */
export type MutationUpdateCarrierChargeTypeConfigurationV2Args = {
  input?: InputMaybe<CarrierChargeTypeConfigurationUpdateInputV2>;
};


/** The root type for all write operations. */
export type MutationDeleteCarrierChargeTypeConfigurationArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDeleteCarrierChargeTypeConfigurationV2Args = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateCustomerChargeTypeConfigurationArgs = {
  input?: InputMaybe<CustomerChargeTypeConfigurationCreateInput>;
};


/** The root type for all write operations. */
export type MutationCreateCustomerChargeTypeConfigurationV2Args = {
  input?: InputMaybe<CustomerChargeTypeConfigurationCreateInputV2>;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerChargeTypeConfigurationArgs = {
  input?: InputMaybe<CustomerChargeTypeConfigurationUpdateInput>;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerChargeTypeConfigurationV2Args = {
  input?: InputMaybe<CustomerChargeTypeConfigurationUpdateInputV2>;
};


/** The root type for all write operations. */
export type MutationDeleteCustomerChargeTypeConfigurationArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDeleteCustomerChargeTypeConfigurationV2Args = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateAccessorialChargeTypeConfigurationArgs = {
  input?: InputMaybe<AccessorialChargeTypeConfigurationCreateInput>;
};


/** The root type for all write operations. */
export type MutationUpdateAccessorialChargeTypeConfigurationArgs = {
  input?: InputMaybe<AccessorialChargeTypeConfigurationUpdateInput>;
};


/** The root type for all write operations. */
export type MutationDeleteAccessorialChargeTypeConfigurationArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateClientExceptionRecordArgs = {
  create: CreateClientExceptionRecordInput;
};


/** The root type for all write operations. */
export type MutationUpdateClientExceptionRecordArgs = {
  update: UpdateClientExceptionRecordInput;
};


/** The root type for all write operations. */
export type MutationUpdateClientExceptionRecordV2Args = {
  update: UpdateClientExceptionRecordInputV2;
};


/** The root type for all write operations. */
export type MutationUpsertClientExceptionRecordArgs = {
  exceptions: UpsertClientExceptionInput;
};


/** The root type for all write operations. */
export type MutationCreateCommissionsGlobalConfigurationArgs = {
  globalConfiguration: CommissionsGlobalConfigurationInput;
};


/** The root type for all write operations. */
export type MutationUpdateCommissionsGlobalConfigurationArgs = {
  globalConfiguration: CommissionsGlobalConfigurationInput;
};


/** The root type for all write operations. */
export type MutationDeleteCommissionsGlobalConfigurationArgs = {
  globalConfigurationId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateCommissionsCustomerOverheadConfigurationArgs = {
  customerOverheadConfiguration: CommissionsCustomerOverheadConfigurationInput;
};


/** The root type for all write operations. */
export type MutationUpdateCommissionsCustomerOverheadConfigurationArgs = {
  customerOverheadConfiguration: CommissionsCustomerOverheadConfigurationInput;
};


/** The root type for all write operations. */
export type MutationDeleteCommissionsCustomerOverheadConfigurationArgs = {
  customerOverheadConfigurationId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCalculateCommissionsLoadAllocatedMarginsArgs = {
  loadId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateEmployeeCommissionPlanArgs = {
  employeeCommissionPlan: EmployeeCommissionPlanInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateEmployeeCommissionConfigurationPlanArgs = {
  employeeCommissionPlan: EmployeeCommissionPlanInput;
};


/** The root type for all write operations. */
export type MutationCreateEmployeeCommissionPlanConfigurationArgs = {
  employeeCommissionPlanConfiguration: EmployeeCommissionPlanConfigurationInput;
};


/** The root type for all write operations. */
export type MutationUpdateEmployeeCommissionPlanConfigurationArgs = {
  employeeCommissionPlanConfiguration: EmployeeCommissionPlanConfigurationInput;
};


/** The root type for all write operations. */
export type MutationDeleteEmployeeCommissionPlanConfigurationArgs = {
  employeeCommissionPlanConfigurationId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationUpdateEmployeeCommissionPlanConfigurationHeaderArgs = {
  employeeCommissionPlanConfiguration: EmployeeCommissionPlanConfigurationInput;
};


/** The root type for all write operations. */
export type MutationCreateLoadCommissionsOverrideConfigurationArgs = {
  loadCommissionsOverrideConfiguration: LoadCommissionsOverrideConfigurationInput;
};


/** The root type for all write operations. */
export type MutationUpdateLoadCommissionsOverrideConfigurationArgs = {
  loadCommissionsOverrideConfiguration: LoadCommissionsOverrideConfigurationInput;
};


/** The root type for all write operations. */
export type MutationRecalculateSaveCommissionsArgs = {
  loadId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationSetEmployeeAssignmentsForCommissionPlanArgs = {
  input: EmployeeCommissionPlanAssignmentsInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCostQuoteArgs = {
  input: CarrierQuoteInputType;
};


/** The root type for all write operations. */
export type MutationUpdateCostQuoteArgs = {
  input: UpdateCarrierQuoteInputType;
};


/** The root type for all write operations. */
export type MutationReserveCreditNoTotalRateArgs = {
  creditRequest: ReserveCreditNoTotalRate;
};


/** The root type for all write operations. */
export type MutationReserveCreditWithTotalRateArgs = {
  creditRequest: ReserveCreditWithTotalRate;
};


/** The root type for all write operations. */
export type MutationReserveCreditWithRateDetailsArgs = {
  creditRequest: ReserveCreditWithRateDetails;
};


/** The root type for all write operations. */
export type MutationReserveCreditWithDetailsArgs = {
  creditRequest: ReserveCreditWithDetails;
};


/** The root type for all write operations. */
export type MutationReserveCreditWithAutomationArgs = {
  creditRequest: ReserveCreditWithDetails;
};


/** The root type for all write operations. */
export type MutationReleaseCreditArgs = {
  creditRequest: ReleaseCredit;
};


/** The root type for all write operations. */
export type MutationChangeCreditCustomerOnOrderArgs = {
  creditRequest: ChangeCreditCustomerOnOrderInput;
};


/** The root type for all write operations. */
export type MutationApplyCreditBypassToOrderArgs = {
  orderId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateCreditOrderLevelBypassArgs = {
  orderId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationSalesInteractionAppendArgs = {
  interaction_Contact_Input: ReadonlyArray<CrmInteractionContactInput>;
  interaction_Objective_Input: ReadonlyArray<CrmInteractionObjectiveInput>;
  interaction_Opportunity_Input: ReadonlyArray<CrmInteractionOpportunityInput>;
  interaction_RepGroup_Input: ReadonlyArray<CrmInteractionRepGroupInput>;
  obj_CRM_Interaction: CrmInteractionInput;
  obj_Interaction_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSalesInteractionAppendV2Args = {
  contacts: ReadonlyArray<CrmInteractionContactInputV2>;
  interaction: CrmInteractionInputV2;
  interactionId: Scalars['String'];
  objectives: ReadonlyArray<CrmInteractionObjectiveInputV2>;
  opportunities: ReadonlyArray<CrmInteractionOpportunityInputV2>;
  repGroups: ReadonlyArray<CrmInteractionRepGroupInputV2>;
};


/** The root type for all write operations. */
export type MutationSalesInteractionAssignRepGroupReassignArgs = {
  interaction_RepGroup_Input: ReadonlyArray<CrmInteractionRepGroupInput>;
  obj_CRM_Interaction: CrmInteractionInput;
  obj_Interaction_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSalesInteractionAssignRepGroupReassignV2Args = {
  interaction: CrmInteractionInputV2;
  interactionId: Scalars['String'];
  repGroups: ReadonlyArray<CrmInteractionRepGroupInputV2>;
};


/** The root type for all write operations. */
export type MutationSalesInteractionDeleteArgs = {
  obj_CRM_ID: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSalesInteractionDeleteV2Args = {
  interactionId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSalesInteractionDocumentDeleteArgs = {
  obj_CRM_Interaction_Document_Id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSalesInteractionDocumentRemoveArgs = {
  DocumentId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSalesInteractionDocumentRemoveV2Args = {
  documentId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSalesInteractionDocumentSaveArgs = {
  obj_CRM_Interaction_Document: CrmInteractionDocumentInput;
};


/** The root type for all write operations. */
export type MutationSalesInteractionDocumentSaveV2Args = {
  documents: CrmInteractionDocumentInputV2;
};


/** The root type for all write operations. */
export type MutationSalesInteractionObjectiveSaveArgs = {
  obj_CRM_Interaction_Objective: CrmInteractionObjectiveInput;
};


/** The root type for all write operations. */
export type MutationSalesInteractionObjectiveSaveV2Args = {
  interactionObjective: CrmInteractionObjectiveInputV2;
};


/** The root type for all write operations. */
export type MutationSalesInteractionRescheduleSaveArgs = {
  obj_CRM_Interaction_Reschedule: CrmInteractionRescheduleInput;
};


/** The root type for all write operations. */
export type MutationSalesInteractionRescheduleSaveV2Args = {
  interactionReschedule: CrmInteractionRescheduleAuditInputV2;
};


/** The root type for all write operations. */
export type MutationSalesInteractionSaveArgs = {
  obj_CRM_Interaction: CrmInteractionInput;
};


/** The root type for all write operations. */
export type MutationSalesInteractionSaveV2Args = {
  interaction: CrmInteractionInputV2;
};


/** The root type for all write operations. */
export type MutationSalescreateOrUpdateInteractionArgs = {
  interaction: CrmInteractionInputV2;
};


/** The root type for all write operations. */
export type MutationCrmSaveCarrierNoteArgs = {
  note: CrmCarrierNotesInput;
};


/** The root type for all write operations. */
export type MutationCrmSaveCustomerNoteArgs = {
  note: CrmCustomerNotesInput;
};


/** The root type for all write operations. */
export type MutationCrmModifyCarrierNoteArgs = {
  id: Scalars['String'];
  note: CrmCarrierNotesInput;
};


/** The root type for all write operations. */
export type MutationCrmModifyCustomerNoteArgs = {
  id: Scalars['String'];
  note: CrmCustomerNotesInput;
};


/** The root type for all write operations. */
export type MutationCrmSaveCarrierDocumentArgs = {
  document: CrmCarrierDocumentsInput;
};


/** The root type for all write operations. */
export type MutationCrmSaveCustomerDocumentArgs = {
  document: CrmCustomerDocumentsInput;
};


/** The root type for all write operations. */
export type MutationCrmModifyCarrierDocumentArgs = {
  id: Scalars['String'];
  document: CrmCarrierDocumentsInput;
};


/** The root type for all write operations. */
export type MutationCrmModifyCustomerDocumentArgs = {
  id: Scalars['String'];
  document: CrmCustomerDocumentsInput;
};


/** The root type for all write operations. */
export type MutationCrmCustomerExportNotesArgs = {
  entityId: Scalars['String'];
  createdBy: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmCarrierExportNotesArgs = {
  entityId: Scalars['String'];
  createdBy: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmCustomerDeleteDocumentArgs = {
  id: Scalars['String'];
  customerId: Scalars['String'];
  updatedBy: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCrmCarrierDeleteDocumentArgs = {
  id: Scalars['String'];
  vendorId: Scalars['String'];
  updatedBy: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSalesOpportunityAppendV2Args = {
  objCrmOpportunity: CrmOpportunityInputV2;
  objOpportunityId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSalesOpportunityAppendV3Args = {
  objCrmOpportunity: SalesOpportunityInputV2;
  objOpportunityId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSalesOpportunityDocumentRemoveV2Args = {
  documentId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSalesOpportunityDocumentRemoveV3Args = {
  documentId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSalesOpportunityDocumentSaveV2Args = {
  documents: CrmOpportunityDocumentInputV2;
};


/** The root type for all write operations. */
export type MutationSalesOpportunityDocumentSaveV3Args = {
  documents: SalesOpportunityDocumentInputV2;
};


/** The root type for all write operations. */
export type MutationSalesOpportunitySaveArgs = {
  obj_CRM_Opportunity: CrmOpportunityInput;
};


/** The root type for all write operations. */
export type MutationSalesOpportunitySaveV2Args = {
  objCrmOpportunity: CrmOpportunityInputV2;
};


/** The root type for all write operations. */
export type MutationSalesOpportunitySaveV3Args = {
  objCrmOpportunity: SalesOpportunityInputV2;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerCommitmentArgs = {
  input: CustomerCommitmentInputType;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCapacityConstraintArgs = {
  input: CustomerCommitmentCapacityConstraintInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerCommitmentsSettingsArgs = {
  input: CustomerCommitmentsSettingsInputType;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateManagerMetricsAsyncArgs = {
  input: CustomerCommitmentManagerMetricsInputType;
};


/** The root type for all write operations. */
export type MutationCreateCustomerStructureArgs = {
  customerStructureInputDto: CreateCustomerStructureInput;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerStructureArgs = {
  id: Scalars['String'];
  customerStructureInputDto: UpdateCustomerStructureInput;
};


/** The root type for all write operations. */
export type MutationDeleteCustomerStructureArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateNoteDefaultArgs = {
  noteDefaultInputDto: CreateNoteDefaultInput;
};


/** The root type for all write operations. */
export type MutationDeleteNoteDefaultArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationUpdateNoteDefaultArgs = {
  id: Scalars['String'];
  noteDefaultInputDto: UpdateNoteDefaultInput;
};


/** The root type for all write operations. */
export type MutationCreateCustomerRegionArgs = {
  input: CreateCustomerRegionInput;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerRegionArgs = {
  input: UpdateCustomerRegionInput;
};


/** The root type for all write operations. */
export type MutationDeleteCustomerRegionArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateCustomerFacilityArgs = {
  input: CreateCustomerFacilityInput;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerFacilityArgs = {
  input: UpdateCustomerFacilityInput;
};


/** The root type for all write operations. */
export type MutationDeleteCustomerFacilityArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationDeleteCustomerFacilityStopDetailArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateCustomerCommodityArgs = {
  createCustomerCommodityInput: CreateCustomerCommodityInput;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerCommodityArgs = {
  id: Scalars['String'];
  updateCustomerCommodityInput: UpdateCustomerCommodityInput;
};


/** The root type for all write operations. */
export type MutationDeleteCustomerCommodityArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateLoadDefaultsArgs = {
  input: CreateLoadDefaultsInput;
};


/** The root type for all write operations. */
export type MutationCreateLoadDefaultsV2Args = {
  input: CreateLoadDefaultsInputV2;
};


/** The root type for all write operations. */
export type MutationUpdateLoadDefaultsArgs = {
  id: Scalars['String'];
  input: UpdateLoadDefaultsInput;
};


/** The root type for all write operations. */
export type MutationUpdateLoadDefaultsV2Args = {
  id: Scalars['String'];
  input: UpdateLoadDefaultsInputV2;
};


/** The root type for all write operations. */
export type MutationDeleteLoadDefaultsArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerRouteDefaultsArgs = {
  input: CreateOrUpdateCustomerRouteDefaultsInput;
};


/** The root type for all write operations. */
export type MutationCreateCustomerCustomerRelationshipArgs = {
  createCustomerCustomerRelationshipInput: CreateCustomerCustomerRelationshipInput;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerCustomerRelationshipArgs = {
  id: Scalars['String'];
  updateCustomerCustomerRelationshipInput: UpdateCustomerCustomerRelationshipInput;
};


/** The root type for all write operations. */
export type MutationDeleteCustomerCustomerRelationshipArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateServiceToleranceArgs = {
  input: CreateOrUpdateServiceToleranceInput;
};


/** The root type for all write operations. */
export type MutationDeleteServiceToleranceArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateCarrierFacilityArgs = {
  CreateCarrierFacilityInput: CreateCarrierFacilityInput;
};


/** The root type for all write operations. */
export type MutationUpdateCarrierFacilityArgs = {
  id: Scalars['String'];
  UpdateCarrierFacilityInput: UpdateCarrierFacilityInput;
};


/** The root type for all write operations. */
export type MutationDeleteCarrierFacilityArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateFacilityNoteDefaultsArgs = {
  input: CreateFacilityNoteDefaultsInput;
};


/** The root type for all write operations. */
export type MutationUpdateFacilityNoteDefaultsArgs = {
  id: Scalars['String'];
  input: UpdateFacilityNoteDefaultsInput;
};


/** The root type for all write operations. */
export type MutationDeleteFacilityNoteDefaultsArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateFacilityLoadDefaultsArgs = {
  input: CreateFacilityLoadDefaultsInput;
};


/** The root type for all write operations. */
export type MutationUpdateFacilityLoadDefaultsArgs = {
  id: Scalars['String'];
  input: UpdateFacilityLoadDefaultsInput;
};


/** The root type for all write operations. */
export type MutationDeleteFacilityLoadDefaultsArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateFacilityFacilityRelationshipArgs = {
  facilityFacilityRelationshipInput: CreateOrUpdateFacilityFacilityRelationshipInput;
};


/** The root type for all write operations. */
export type MutationDeleteFacilityFacilityRelationshipArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerGroupV2Args = {
  input: CustomerGroupInputV2;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerAddressArgs = {
  input: CreateCustomerAddressInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerV2Args = {
  input: CustomerInputV2;
};


/** The root type for all write operations. */
export type MutationCreateChildrenCustomerArgs = {
  input: ChildernCustomerInput;
};


/** The root type for all write operations. */
export type MutationCreateCustomerMultipleChildrenArgs = {
  input: ReadonlyArray<InputMaybe<ChildernCustomerInput>>;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerSettingV2Args = {
  input: CustomerSettingInputV2;
};


/** The root type for all write operations. */
export type MutationCreateCreditHistoryV2Args = {
  input: CreditHistoryInputV2;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerContactArgs = {
  input: CreateCustomerContactInput;
};


/** The root type for all write operations. */
export type MutationCreateCustomerCreditProviderV2Args = {
  input: CustomerCreditProviderInputV2;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerRepArgs = {
  input: CreateCustomerRepInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerIdentifierArgs = {
  input: CreateCustomerIdentifierInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerLinesOfBusinessArgs = {
  input: CreateLinesOfBusinessInput;
};


/** The root type for all write operations. */
export type MutationDestroyCustomerAddressArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationDestroyCustomerContactArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationDestroyCustomerRepArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDestroyCustomerIdentifierArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationDeleteCustomerLinesOfBusinessArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerDriverLocationFrequencyConfigurationArgs = {
  input: DriverLocationFrequencyInput;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerOutbound214FrequencyArgs = {
  input: Outbound214FrequencyInput;
};


/** The root type for all write operations. */
export type MutationCreateOutbound214EmailAddressesArgs = {
  input: Outbound214EmailAddressInput;
};


/** The root type for all write operations. */
export type MutationDeleteOutbound214EmailAddressArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationUpdateCarrierEdiTransactionConfigurationArgs = {
  input: CarrierEdiTransactionConfigurationInput;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerEdiTransactionConfigurationArgs = {
  input: CustomerEdiTransactionConfigurationInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateFieldMappingArgs = {
  input: FieldMappingConfigurationInput;
};


/** The root type for all write operations. */
export type MutationCompleteUploadArgs = {
  completeUpload: CompleteUploadInput;
};


/** The root type for all write operations. */
export type MutationCompleteUploadV2Args = {
  completeUpload: CompleteUploadInputV2;
};


/** The root type for all write operations. */
export type MutationDeleteFacilityDocumentArgs = {
  documentId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDeleteCarrierDocumentArgs = {
  documentId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationUpdateLoadDocumentDetailsArgs = {
  input: UpdateLoadDocDetailsInput;
};


/** The root type for all write operations. */
export type MutationUpdateEdiDocumentNameArgs = {
  input: UpdateEdiDocumentNameInput;
};


/** The root type for all write operations. */
export type MutationDeleteCustomerDocumentArgs = {
  documentId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationUpdateCrmDocumentNameArgs = {
  input: UpdateCrmDocumentNameInput;
};


/** The root type for all write operations. */
export type MutationCreateHosEventArgs = {
  input: DriverHosInput;
};


/** The root type for all write operations. */
export type MutationHosEventCorrectionArgs = {
  input: DriverHosCorrectionInput;
};


/** The root type for all write operations. */
export type MutationSendDriverMessageArgs = {
  message: SendDriverConversationInput;
};


/** The root type for all write operations. */
export type MutationSendDriverMessageV2Args = {
  message: SendDriverMessageV2Input;
};


/** The root type for all write operations. */
export type MutationReadDriverConversationArgs = {
  filter: ReadDriverConversationInput;
};


/** The root type for all write operations. */
export type MutationReadDriverConversationMessagesArgs = {
  filter: ReadDriverConversationMessagesInput;
};


/** The root type for all write operations. */
export type MutationSetFollowUpTimeArgs = {
  filter: CreateFollowUpTimeInput;
};


/** The root type for all write operations. */
export type MutationSetFollowUpTimeV2Args = {
  filter: CreateFollowUpTimeV2Input;
};


/** The root type for all write operations. */
export type MutationRemoveFollowUpTimeArgs = {
  filter: DeleteFollowUpTimeInput;
};


/** The root type for all write operations. */
export type MutationRemoveFollowUpTimeV2Args = {
  filter: DeleteFollowUpTimeInputV2;
};


/** The root type for all write operations. */
export type MutationSendNrbfDriverMessageArgs = {
  message: SendNrbfDriverConversationInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverScheduleArgs = {
  schedules: ReadonlyArray<AssetDriverScheduleInput>;
};


/** The root type for all write operations. */
export type MutationDeleteDriverScheduleArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverAddressArgs = {
  address: AssetDriverAddressInput;
};


/** The root type for all write operations. */
export type MutationDeleteDriverAddressArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverTimeOffArgs = {
  timeOff: AssetDriverTimeOffInput;
};


/** The root type for all write operations. */
export type MutationDeleteDriverTimeOffArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverContactArgs = {
  contact: AssetDriverContactInput;
};


/** The root type for all write operations. */
export type MutationDeleteDriverContactArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverAwardArgs = {
  award: AssetDriverAwardInput;
};


/** The root type for all write operations. */
export type MutationDeleteDriverAwardArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverCertificationOrPermitArgs = {
  certificationOrPermit: AssetDriverCertificationOrPermitInput;
};


/** The root type for all write operations. */
export type MutationDeleteCertificationOrPermitArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverIdentifierArgs = {
  identifier: AssetDriverIdentifierInput;
};


/** The root type for all write operations. */
export type MutationDeleteDriverIdentifierArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverEquipmentArgs = {
  equipment: AssetDriverEquipmentInput;
};


/** The root type for all write operations. */
export type MutationDeleteDriverEquipmentArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverRoutingRequestArgs = {
  routingRequest: DriverRoutingRequestInput;
};


/** The root type for all write operations. */
export type MutationPatchDriverRoutingRequestArgs = {
  routingRequest: DriverRoutingRequestPatchInput;
};


/** The root type for all write operations. */
export type MutationCreateDriverArgs = {
  driver: AssetDriverInput;
};


/** The root type for all write operations. */
export type MutationDeleteDriverArgs = {
  driverId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationUpdateDriverArgs = {
  permanentPowerInput?: InputMaybe<ReadonlyArray<DriverPermanentPowerInput>>;
  equipmentInput?: InputMaybe<ReadonlyArray<DriverEquipmentInput>>;
  awardInput?: InputMaybe<ReadonlyArray<DriverAwardInput>>;
  scheduleInput?: InputMaybe<ReadonlyArray<DriverScheduleInput>>;
  certificationsInput?: InputMaybe<ReadonlyArray<DriverCertificationsOrPermitsInput>>;
  contactInput?: InputMaybe<ReadonlyArray<DriverContactInput>>;
  addressInput?: InputMaybe<ReadonlyArray<DriverAddressInput>>;
  identifierInput?: InputMaybe<ReadonlyArray<DriverIdentifiersInput>>;
  timeOffInput?: InputMaybe<ReadonlyArray<DriverTimeOffInput>>;
  permanentTrailerInput?: InputMaybe<ReadonlyArray<DriverPermanentTrailersInput>>;
  driver: AssetDriverInput;
  driverId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationUpdateDriverPowerArgs = {
  power: PermanentTractorInput;
};


/** The root type for all write operations. */
export type MutationUpdateDriverTrailerArgs = {
  trailer: DriverTrailerInput;
};


/** The root type for all write operations. */
export type MutationUpdateDriverOperationsArgs = {
  driver: AssetDriverInput;
};


/** The root type for all write operations. */
export type MutationUpdateDriverPreferencesArgs = {
  driver: AssetDriverInput;
};


/** The root type for all write operations. */
export type MutationDeleteDriverFacilityPreferencesArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationDeleteDriverCustomerPreferencesArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveDriverFacilityPreferencesArgs = {
  driverFacility: DriverFacilityPreferencesInput;
};


/** The root type for all write operations. */
export type MutationSaveDriverCustomerPreferencesArgs = {
  driverCustomer: DriverCustomerPreferencesInput;
};


/** The root type for all write operations. */
export type MutationUpdateDriverFacilityPreferencesArgs = {
  driverFacility?: InputMaybe<ReadonlyArray<DriverFacilityPreferencesInput>>;
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationUpdateDriverCustomerPreferencesArgs = {
  driverCustomer?: InputMaybe<ReadonlyArray<DriverCustomerPreferencesInput>>;
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveDriverPreferredLanesArgs = {
  driverPreferredLanes: DriverPreferredLanesInput;
};


/** The root type for all write operations. */
export type MutationUpdateDriverPreferredLanesArgs = {
  driverPreferredLanes?: InputMaybe<ReadonlyArray<DriverPreferredLanesInput>>;
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveDriverPreferredRoutesArgs = {
  driverPreferredRoutes: DriverPreferredRoutesInput;
};


/** The root type for all write operations. */
export type MutationUpdateDriverPreferredRoutesByIdArgs = {
  driverPreferredRouteStops?: InputMaybe<ReadonlyArray<DriverPreferredRouteStopsInput>>;
  driverPreferredRoutes?: InputMaybe<DriverPreferredRoutesInput>;
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationDeleteDriverPreferredLanesArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationDeleteDriverPreferredRoutesArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverArgs = {
  input: AssetDriverInput;
};


/** The root type for all write operations. */
export type MutationDeleteDriverWeeklyTargetArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverWeeklyTargetArgs = {
  weeklyTarget: DriverWeeklyTargetInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverTrainingArgs = {
  training: DriverTrainingInput;
};


/** The root type for all write operations. */
export type MutationDeleteTrainingArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverFleetRelationshipArgs = {
  driverFleet: DriverFleetRelationshipInput;
};


/** The root type for all write operations. */
export type MutationDeleteDriverFleetArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationDeleteDriverRepresentativeArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverRepresentativeArgs = {
  driverRepresentative: DriverRepresentativeInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverFleetsRelationshipArgs = {
  driverFleet: CreateOrUpdateDriverFleetRelationshipInput;
};


/** The root type for all write operations. */
export type MutationDriverPatchArgs = {
  input: AssetDriverPatchInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverV2Args = {
  input: AssetDriverInputV2;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverTeamPreferencesArgs = {
  teamPreference: AssetDriverTeamPreferenceInput;
};


/** The root type for all write operations. */
export type MutationDeleteDriverTeamPreferenceArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverCareerGoalsPreferenceArgs = {
  driverCareerGoalsPreference: AssetDriverCareerGoalsPreferenceInput;
};


/** The root type for all write operations. */
export type MutationDeleteDriverCareerGoalsPreferenceArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverGeographyPreferenceArgs = {
  driverGeographyPreference: AssetDriverGeographyPreferenceInput;
};


/** The root type for all write operations. */
export type MutationDeleteDriverGeographyPreferenceArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateAssetDriverGeographyPreferenceArgs = {
  driverGeographyPreferenceInput: DriverGeographyPreferenceInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverOperationalPreferenceArgs = {
  driverOperationalPreference: AssetDriverOperationalPreferenceInput;
};


/** The root type for all write operations. */
export type MutationDeleteDriverOperationalPreferenceArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverCommodityPreferenceArgs = {
  driverCommodityPreference: AssetDriverCommodityPreferenceInput;
};


/** The root type for all write operations. */
export type MutationDeleteDriverCommodityPreferenceArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverCustomerPreferenceArgs = {
  driverCustomerPreference: AssetDriverCustomerPreferenceInput;
};


/** The root type for all write operations. */
export type MutationDeleteDriverCustomerPreferenceArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverFacilityPreferenceArgs = {
  driverFacilityPreference: AssetDriverFacilityPreferenceInput;
};


/** The root type for all write operations. */
export type MutationDeleteDriverFacilityPreferenceArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateDriverPreferredLanesArgs = {
  driverPreferredLanes: ReadonlyArray<AssetDriverPreferredLanesInput>;
};


/** The root type for all write operations. */
export type MutationDeleteDriverPayAttributeArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDisableDriverPayAttributeDefinitionArgs = {
  optionId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationEnableDriverPayAttributeDefinitionArgs = {
  optionId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateDriverPayAttributesArgs = {
  attributes: ReadonlyArray<CreateDriverPayAttributeInput>;
};


/** The root type for all write operations. */
export type MutationUpdateDriverPayAttributesArgs = {
  attributes: ReadonlyArray<UpdateDriverPayAttributeInput>;
};


/** The root type for all write operations. */
export type MutationSaveDriverPayAttributeDefinitionsArgs = {
  definitions: ReadonlyArray<SaveDriverPayAttributeDefinitionInput>;
};


/** The root type for all write operations. */
export type MutationCreateDriverPayProgramCarrierAssociationsArgs = {
  associations: ReadonlyArray<CreateDriverPayProgramCarrierAssociationInput>;
  confirmUpdate?: InputMaybe<Scalars['Boolean']>;
};


/** The root type for all write operations. */
export type MutationUpdateDriverPayProgramCarrierAssociationsArgs = {
  associations: ReadonlyArray<UpdateDriverPayProgramCarrierAssociationInput>;
  confirmUpdate?: InputMaybe<Scalars['Boolean']>;
};


/** The root type for all write operations. */
export type MutationDeleteDriverPayProgramCarrierAssociationArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateDriverPayProgramDriverAssociationsArgs = {
  associations: ReadonlyArray<CreateDriverPayProgramDriverAssociationInput>;
  confirmUpdate?: InputMaybe<Scalars['Boolean']>;
};


/** The root type for all write operations. */
export type MutationUpdateDriverPayProgramDriverAssociationsArgs = {
  associations: ReadonlyArray<UpdateDriverPayProgramDriverAssociationInput>;
  confirmUpdate?: InputMaybe<Scalars['Boolean']>;
};


/** The root type for all write operations. */
export type MutationDeleteDriverPayProgramDriverAssociationArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateDriverPayProgramsArgs = {
  programs: ReadonlyArray<CreateDriverPayProgramInput>;
};


/** The root type for all write operations. */
export type MutationUpdateDriverPayProgramsArgs = {
  programs: ReadonlyArray<UpdateDriverPayProgramInput>;
};


/** The root type for all write operations. */
export type MutationEnableDriverPayProgramArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDisableDriverPayProgramArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDeleteDriverPayRateTableArgs = {
  id: Scalars['ID'];
  confirmDelete?: InputMaybe<Scalars['Boolean']>;
};


/** The root type for all write operations. */
export type MutationCreateDriverPayRateTableHeadersArgs = {
  headers: ReadonlyArray<CreateDriverPayRateTableHeaderInput>;
};


/** The root type for all write operations. */
export type MutationUpdateDriverPayRateTableHeadersArgs = {
  payRateTableHeaders: ReadonlyArray<UpdateDriverPayRateTableHeaderInput>;
};


/** The root type for all write operations. */
export type MutationNotifyDriverPayRateTableUploadedArgs = {
  payRateTableId: Scalars['ID'];
  documentId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationSetDriverPayProgramRouteAssignmentsArgs = {
  assignments: ReadonlyArray<DriverPayProgramRouteAssignmentInput>;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateEmployeeV2Args = {
  input: EmployeeInputV2;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateEmployeeExternalV2Args = {
  input: EmployeeInputTypeExternalV2;
};


/** The root type for all write operations. */
export type MutationRemoveEmployeeMessagingUserIdentitiesArgs = {
  input: RemoveEmployeeMessagingUserIdentitiesInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdatePostingCredentialsArgs = {
  input: EmployeePostingCredentialsInput;
};


/** The root type for all write operations. */
export type MutationRemovePostingCredentialsArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationUpdateMileageTenantConfigurationArgs = {
  mileageSettings: MileageSettingsInput;
};


/** The root type for all write operations. */
export type MutationUpdateMileageDefaultConfigurationArgs = {
  entityType: EntityConfigurationEntityType;
  mileageSettings: MileageSettingsInput;
};


/** The root type for all write operations. */
export type MutationCreateMileageEntityConfigurationArgs = {
  entityType: EntityConfigurationEntityType;
  entityId: Scalars['ID'];
  effectiveDate: Scalars['String'];
  expirationDate?: InputMaybe<Scalars['String']>;
  mileageSettings: MileageSettingsInput;
  createdBy?: InputMaybe<Scalars['String']>;
};


/** The root type for all write operations. */
export type MutationUpdateMileageEntityConfigurationArgs = {
  entityType: EntityConfigurationEntityType;
  entityId: Scalars['ID'];
  effectiveDate: Scalars['String'];
  expirationDate?: InputMaybe<Scalars['String']>;
  uuid: Scalars['ID'];
  mileageSettings: MileageSettingsInput;
  updatedBy?: InputMaybe<Scalars['String']>;
};


/** The root type for all write operations. */
export type MutationDeleteMileageEntityConfigurationArgs = {
  uuid: Scalars['ID'];
  updatedBy: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSetEntityStringArgs = {
  key?: InputMaybe<EntityConfigKeyInput>;
  value?: InputMaybe<Scalars['String']>;
  avoidOverwrite?: InputMaybe<Scalars['Boolean']>;
};


/** The root type for all write operations. */
export type MutationSetGeofencingLocationArgs = {
  input: EntityGeofencingLocationInput;
};


/** The root type for all write operations. */
export type MutationSetGeofencingLocationV2Args = {
  input: EntityGeofencingLocationInput;
};


/** The root type for all write operations. */
export type MutationDeleteGeofencingLocationArgs = {
  input: EntityGeofencingLocationInput;
};


/** The root type for all write operations. */
export type MutationSetEntityGeofencingCustomerRecordArgs = {
  input: EntityGeofencingCustomerRecordInput;
};


/** The root type for all write operations. */
export type MutationSetEntityGeofencingLocationRecordArgs = {
  input: EntityGeofencingLocationRecordInput;
};


/** The root type for all write operations. */
export type MutationDeleteEntityGeofencingCustomerRecordArgs = {
  input: EntityGeofencingCustomerRecordDeleteInput;
};


/** The root type for all write operations. */
export type MutationDeleteEntityGeofencingLocationRecordArgs = {
  input: EntityGeofencingLocationRecordDeleteInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateFacilityV2Args = {
  input: FacilityInputV2;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateFacilityScheduleArgs = {
  input: CreateScheduleInput;
};


/** The root type for all write operations. */
export type MutationFindOrCreateFacilityV2Args = {
  input: FindOrCreateFacilityInputV2;
};


/** The root type for all write operations. */
export type MutationFindOrCreateFacilityV3Args = {
  input: FindOrCreateFacilityInputV3;
};


/** The root type for all write operations. */
export type MutationCreateFacilityToImportArgs = {
  input: FacilityImporterInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateFacilityIdentifierV2Args = {
  input: FacilityIdentifierStandaloneInputV2;
};


/** The root type for all write operations. */
export type MutationDestroyFacilityIdentifierV2Args = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDestroyFacilityContactArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateFacilityContactArgs = {
  input: CreateFacilityContactInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateFacilityDirectionArgs = {
  input: CreateFacilityDirectionInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateFacilityHolidayArgs = {
  input: CreateFacilityHolidayInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateFacilityAddressArgs = {
  input: CreateFacilityAddressInput;
};


/** The root type for all write operations. */
export type MutationDestroyFacilityAddressArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationDestroyFacilityScheduleArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDestroyFacilityDirectionArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDestroyFacilityHolidayArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDestroyFacilityRepArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerFacilityRelationshipV2Args = {
  input: CustomerFacilityRelationshipInputV2;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateFacilityRepArgs = {
  input: CreateFacilityRepInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateFacilitySublocationActivityArgs = {
  input: CreateOrUpdateFacilitySublocationActivityInput;
};


/** The root type for all write operations. */
export type MutationDestroyFacilitySublocationActivityArgs = {
  activityId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateFacilitySublocationAccommodationArgs = {
  input: CreateOrUpdateFacilitySublocationAccommodationInput;
};


/** The root type for all write operations. */
export type MutationDestroyFacilitySublocationAccommodationArgs = {
  accommodationId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateFacilitySublocationArgs = {
  input: CreateFacilitySublocationInput;
};


/** The root type for all write operations. */
export type MutationDestroyFacilitySublocationArgs = {
  sublocationId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateFacilitySublocationGateArgs = {
  input: CreateOrUpdateFacilitySublocationGateInput;
};


/** The root type for all write operations. */
export type MutationDestroyFacilitySublocationGateArgs = {
  gateId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationSaveMasterbidUploadEventArgs = {
  documentType: Scalars['String'];
  envId: Scalars['String'];
  jobId: Scalars['String'];
  workbookId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSavePricingProgramUploadEventArgs = {
  envId: Scalars['String'];
  jobId: Scalars['String'];
  workbookId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveRateQuoteUploadEventArgs = {
  envId: Scalars['String'];
  jobId: Scalars['String'];
  workbookId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveRoutingGuideUploadEventArgs = {
  envId: Scalars['String'];
  jobId: Scalars['String'];
  workbookId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveServiceProfileUploadEventArgs = {
  envId: Scalars['String'];
  jobId: Scalars['String'];
  workbookId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveDriverPayRateUploadEventArgs = {
  documentType: Scalars['String'];
  envId: Scalars['String'];
  jobId: Scalars['String'];
  payRateTableId: Scalars['String'];
  workbookId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveUploadEventArgs = {
  documentType: Scalars['String'];
  envId: Scalars['String'];
  jobId: Scalars['String'];
  workbookId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationInsertFuelProgramCustomerConfigurationArgs = {
  input: FuelProgramCustomerConfigurationCreateInput;
};


/** The root type for all write operations. */
export type MutationUpdateFuelProgramCustomerConfigurationArgs = {
  input: FuelProgramCustomerConfigurationUpdateInput;
};


/** The root type for all write operations. */
export type MutationDeleteFuelProgramCustomerConfigurationArgs = {
  fuelProgramCustomerConfigurationId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationInsertHistoricalFuelIndexPriceArgs = {
  input: HistoricalFuelIndexPriceCreateInput;
};


/** The root type for all write operations. */
export type MutationUpdateHistoricalFuelIndexPriceArgs = {
  input: HistoricalFuelIndexPriceUpdateInput;
};


/** The root type for all write operations. */
export type MutationUpdateHistoricalFuelIndexAveragePriceOverrideArgs = {
  historicalFuelIndexPriceId: Scalars['ID'];
  averagePriceOverride: Scalars['Decimal'];
};


/** The root type for all write operations. */
export type MutationDeleteHistoricalFuelIndexPriceArgs = {
  historicalFuelIndexPriceId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationInsertCustomerFuelRateHeaderArgs = {
  input: CustomerFuelRateHeaderCreateInput;
};


/** The root type for all write operations. */
export type MutationInsertCustomerFuelRangeArgs = {
  input: CustomerFuelRangeCreateInput;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerFuelRangeArgs = {
  input: CustomerFuelRangeUpdateInput;
};


/** The root type for all write operations. */
export type MutationDeleteCustomerFuelRangeArgs = {
  customerFuelRangeId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationBulkInsertCustomerFuelRangeArgs = {
  input: BulkCustomerFuelRangeCreateInput;
};


/** The root type for all write operations. */
export type MutationImportCustomerFuelRangesArgs = {
  input: ImportCustomerFuelRangesCreateInput;
};


/** The root type for all write operations. */
export type MutationInsertFuelProgramCarrierConfigurationArgs = {
  input: FuelProgramCarrierConfigurationCreateInput;
};


/** The root type for all write operations. */
export type MutationUpdateFuelProgramCarrierConfigurationArgs = {
  input: FuelProgramCarrierConfigurationUpdateInput;
};


/** The root type for all write operations. */
export type MutationInsertCarrierFuelRateHeaderArgs = {
  input: CarrierFuelRateHeaderCreateInput;
};


/** The root type for all write operations. */
export type MutationDeleteFuelProgramCarrierConfigurationArgs = {
  fuelProgramCarrierConfigurationId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationResequenceFuelProgramCarrierConfigurationArgs = {
  input?: InputMaybe<ResequenceCarrierFuelProgramConfigurationInput>;
};


/** The root type for all write operations. */
export type MutationResequenceFuelProgramCustomerConfigurationArgs = {
  input?: InputMaybe<ResequenceCustomerFuelProgramConfigurationInput>;
};


/** The root type for all write operations. */
export type MutationInsertCarrierFuelRangeArgs = {
  input: CarrierFuelRangeCreateInput;
};


/** The root type for all write operations. */
export type MutationUpdateCarrierFuelRangeArgs = {
  input: CarrierFuelRangeUpdateInput;
};


/** The root type for all write operations. */
export type MutationDeleteCarrierFuelRangeArgs = {
  carrierFuelRangeId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationBulkInsertCarrierFuelRangeArgs = {
  input: BulkCarrierFuelRangeCreateInput;
};


/** The root type for all write operations. */
export type MutationImportCarrierFuelRangesArgs = {
  input: ImportCarrierFuelRangesCreateInput;
};


/** The root type for all write operations. */
export type MutationAddPreferredNameArgs = {
  input: GeographyPreferredNameInput;
};


/** The root type for all write operations. */
export type MutationUpdateGeographyRecordArgs = {
  input: GeographyUpdateInput;
};


/** The root type for all write operations. */
export type MutationCopyFromGeonamesArgs = {
  input: CopyFromGeonamesRequest;
};


/** The root type for all write operations. */
export type MutationImportLocalityFromGeonamesArgs = {
  input: CopyFromGeonamesRequestNameState;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateGroupArgs = {
  input: CreateOrUpdateGroupInput;
};


/** The root type for all write operations. */
export type MutationUpdateRuleSetsArgs = {
  update: UpdateRuleSetsInput;
};


/** The root type for all write operations. */
export type MutationUpdateTemplatesArgs = {
  update: UpdateTemplatesInput;
};


/** The root type for all write operations. */
export type MutationUpdateHaykEventArgs = {
  update: UpdateHaykEventInput;
};


/** The root type for all write operations. */
export type MutationCreateHaykEventArgs = {
  create: CreateHaykEventInput;
};


/** The root type for all write operations. */
export type MutationCreateHaykRedirectArgs = {
  create: HaykRedirectInput;
};


/** The root type for all write operations. */
export type MutationUpdateHaykRedirectArgs = {
  update: UpdateHaykRedirectInput;
};


/** The root type for all write operations. */
export type MutationDeleteHaykRedirectArgs = {
  delete: DeleteHaykRedirectInput;
};


/** The root type for all write operations. */
export type MutationCreateHaykNotificationsArgs = {
  create: HaykNotificationsInput;
};


/** The root type for all write operations. */
export type MutationCreatePlatformMessageArgs = {
  create: CreatePlatformMessageInput;
};


/** The root type for all write operations. */
export type MutationCreateHaykAuditHistoryArgs = {
  create: CreateAuditHistoryInput;
};


/** The root type for all write operations. */
export type MutationUpdateHaykFieldMappingsArgs = {
  update: HaykFieldMappingInput;
};


/** The root type for all write operations. */
export type MutationDeleteRuleSetArgs = {
  delete: DeleteRuleSetInput;
};


/** The root type for all write operations. */
export type MutationToggleRuleSetIsActiveArgs = {
  toggle: ToggleRuleSetIsActiveInput;
};


/** The root type for all write operations. */
export type MutationToggleEventEnabledArgs = {
  toggle?: InputMaybe<ToggleEventEnabledInput>;
};


/** The root type for all write operations. */
export type MutationSyncHdsTemplateArgs = {
  sync: SyncHdsTemplateInput;
};


/** The root type for all write operations. */
export type MutationSyncHdsRuleSetArgs = {
  sync: SyncHdsRuleSetInput;
};


/** The root type for all write operations. */
export type MutationUpdateServiceProfileArgs = {
  serviceProfileId: Scalars['Float'];
  UpdateServiceProfileInput: UpdateServiceProfileInput;
};


/** The root type for all write operations. */
export type MutationCreateServiceProfileArgs = {
  CreateServiceProfileInput: CreateServiceProfileInput;
};


/** The root type for all write operations. */
export type MutationDeleteServiceProfileArgs = {
  serviceProfileId: Scalars['Float'];
};


/** The root type for all write operations. */
export type MutationUpdateServiceProfileV2Args = {
  UpdateServiceProfileInput: UpdateServiceProfileInputV2;
};


/** The root type for all write operations. */
export type MutationCreateServiceProfileV2Args = {
  CreateServiceProfileInput: CreateServiceProfileInputV2;
};


/** The root type for all write operations. */
export type MutationCreateIncidentV2Args = {
  input: IncidentCreateInputV2;
};


/** The root type for all write operations. */
export type MutationUpdateIncidentV2Args = {
  input: IncidentUpdateInputV2;
};


/** The root type for all write operations. */
export type MutationCloseMultipleIncidentsV2Args = {
  input: MultipleIncidentCloseUpdateInputV2;
};


/** The root type for all write operations. */
export type MutationFlipLoadArgs = {
  input: LoadFlipInput;
};


/** The root type for all write operations. */
export type MutationFlipLoadsArgs = {
  input: LoadFlipsInput;
};


/** The root type for all write operations. */
export type MutationDeactivateCustomerLaneArgs = {
  input: DeactivateCustomerLaneInput;
};


/** The root type for all write operations. */
export type MutationReactivateCustomerLaneArgs = {
  input: ReactivateCustomerLaneInput;
};


/** The root type for all write operations. */
export type MutationReactivateCarrierLaneArgs = {
  input: ReactivateCarrierLaneInput;
};


/** The root type for all write operations. */
export type MutationDeactivateCarrierLaneArgs = {
  input: DeactivateCarrierLaneInput;
};


/** The root type for all write operations. */
export type MutationAddCustomerLaneArgs = {
  input: AddCustomerLaneInput;
};


/** The root type for all write operations. */
export type MutationCreateLaneV4Args = {
  input: LaneInputV4;
};


/** The root type for all write operations. */
export type MutationCreateLinkedRouteV3Args = {
  input: CreateLinkedRouteInputV3;
};


/** The root type for all write operations. */
export type MutationDeleteLinkedRouteV3Args = {
  input: DeleteLinkedRouteInput;
};


/** The root type for all write operations. */
export type MutationCreateLinkedRouteV2Args = {
  input: CreateLinkedRouteInputV2;
};


/** The root type for all write operations. */
export type MutationDeleteLinkedRouteV2Args = {
  routeID: Scalars['ID'];
  userID?: InputMaybe<Scalars['ID']>;
  initiatingRoute?: InputMaybe<LinkedRouteInitiatingRouteEnum>;
};


/** The root type for all write operations. */
export type MutationAddressStatusUpdateArgs = {
  request: ReadonlyArray<AddressStatusChangeRequest>;
};


/** The root type for all write operations. */
export type MutationInsertCustomerMarkupArgs = {
  saveCustomerMarkupInput?: InputMaybe<CustomerMarkupInput>;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerMarkupArgs = {
  customerMarkupInput?: InputMaybe<CustomerMarkupInput>;
};


/** The root type for all write operations. */
export type MutationInsertCarrierPreferenceArgs = {
  saveCarrierPreferenceInput?: InputMaybe<CarrierPreferenceInput>;
};


/** The root type for all write operations. */
export type MutationUpdateCarrierPreferenceArgs = {
  carrierPreferenceInput?: InputMaybe<CarrierPreferenceInput>;
};


/** The root type for all write operations. */
export type MutationDeleteCarrierPricingProgramArgs = {
  filter?: InputMaybe<DeletePricingProgramFilter>;
};


/** The root type for all write operations. */
export type MutationDeleteCustomerPricingProgramArgs = {
  filter?: InputMaybe<DeletePricingProgramFilter>;
};


/** The root type for all write operations. */
export type MutationAddBidArgs = {
  bidData: BidInput;
};


/** The root type for all write operations. */
export type MutationAddBidStagingArgs = {
  bidStagingData: BidStagingInput;
};


/** The root type for all write operations. */
export type MutationCancelBidArgs = {
  bidId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationUpdateBidArgs = {
  bidData: BidInput;
};


/** The root type for all write operations. */
export type MutationAcceptBidArgs = {
  bidId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationRejectBidArgs = {
  bidId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationUpdateRoundArgs = {
  roundData: RoundInput;
};


/** The root type for all write operations. */
export type MutationAssignRegionToBidLanesArgs = {
  bidLaneAssignRegionInput: BidLaneAssignRegionInput;
};


/** The root type for all write operations. */
export type MutationAddBidQuoteArgs = {
  bidQuoteData: BidQuoteInput;
};


/** The root type for all write operations. */
export type MutationDeleteBidQuoteArgs = {
  bidQuoteId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationAssignUsersToBidLanesArgs = {
  assignUsersToBidLanesInput?: InputMaybe<AssignUsersToBidLanesInput>;
};


/** The root type for all write operations. */
export type MutationRejectBidLanesArgs = {
  bidLaneStatusUpdateInput: BidLaneStatusUpdateInput;
};


/** The root type for all write operations. */
export type MutationSubmitRoundArgs = {
  submitRoundInput?: InputMaybe<SubmitRoundInput>;
};


/** The root type for all write operations. */
export type MutationCloseRoundArgs = {
  closeRoundInput?: InputMaybe<CloseRoundInput>;
};


/** The root type for all write operations. */
export type MutationValidateImportAwardArgs = {
  importAwardsInput?: InputMaybe<ImportAwardsInput>;
};


/** The root type for all write operations. */
export type MutationAddImportAwardsStagingArgs = {
  importAwardsStagingInput?: InputMaybe<ImportAwardsStagingInput>;
};


/** The root type for all write operations. */
export type MutationDispatchDriverMobileArgs = {
  input: DispatchDriverMobileInput;
};


/** The root type for all write operations. */
export type MutationDispatchDriverMobileV2Args = {
  input: DispatchDriverMobileInputV2;
};


/** The root type for all write operations. */
export type MutationUpdateDriverStopEventArgs = {
  input: UpdateDriverStopEventInput;
};


/** The root type for all write operations. */
export type MutationUpdateDriverStopEventV2Args = {
  input: UpdateDriverStopEventInputV2;
};


/** The root type for all write operations. */
export type MutationUpdateDriverMobileStopEventArgs = {
  input: UpdateDriverStopEventInputV2;
};


/** The root type for all write operations. */
export type MutationUpdateDriverCommoditiesArgs = {
  input: UpdateDriverCommoditiesInput;
};


/** The root type for all write operations. */
export type MutationUpdateDriverCommoditiesV2Args = {
  input: UpdateDriverCommoditiesInputV2;
};


/** The root type for all write operations. */
export type MutationUpdateDriverMobileCommoditiesArgs = {
  input: UpdateDriverCommoditiesInputV2;
};


/** The root type for all write operations. */
export type MutationCompleteStopArgs = {
  input: CompleteStopInput;
};


/** The root type for all write operations. */
export type MutationCompleteStopV2Args = {
  input: CompleteStopInputV2;
};


/** The root type for all write operations. */
export type MutationCompleteDriverMobileStopArgs = {
  input: CompleteStopInputV2;
};


/** The root type for all write operations. */
export type MutationRegisterDeviceArgs = {
  input: RegisterDeviceInput;
};


/** The root type for all write operations. */
export type MutationRegisterDriverMobileDeviceArgs = {
  input: RegisterDeviceInput;
};


/** The root type for all write operations. */
export type MutationRemoveDeviceArgs = {
  driverId: Scalars['String'];
  deviceToken: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationRemoveDriverMobileDeviceArgs = {
  driverId: Scalars['String'];
  deviceToken: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationUpdateDriverMobileDetailsArgs = {
  input: MobileDriverDetailsInput;
};


/** The root type for all write operations. */
export type MutationUpdateDriverMobileDetailsV2Args = {
  input: MobileDriverDetailsInputV2;
};


/** The root type for all write operations. */
export type MutationUpdateDriverMobileInvitationAuditArgs = {
  input: UpdateDriverMobileInvitationAuditInput;
};


/** The root type for all write operations. */
export type MutationSendExternalAccessDriverMobileAuditArgs = {
  input: SendExternalAccessDriverMobileAuditInput;
};


/** The root type for all write operations. */
export type MutationSendExternalAccessDriverMobileAuditV2Args = {
  input: SendExternalAccessDriverMobileAuditInputV2;
};


/** The root type for all write operations. */
export type MutationCreateTrackingUpdateArgs = {
  input: CreateTrackingUpdateInput;
};


/** The root type for all write operations. */
export type MutationCreateTrackingUpdateV2Args = {
  input: CreateTrackingUpdateV2Input;
};


/** The root type for all write operations. */
export type MutationCreateDriverMobileTrackingUpdateArgs = {
  input: CreateTrackingUpdateV2Input;
};


/** The root type for all write operations. */
export type MutationCreateOfferArgs = {
  input: CreateOfferInput;
};


/** The root type for all write operations. */
export type MutationUpdateOfferArgs = {
  input: UpdateOfferInput;
};


/** The root type for all write operations. */
export type MutationDeleteOfferArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationUpdateOffersCarrierArgs = {
  input: UpdateOffersCarrierInput;
};


/** The root type for all write operations. */
export type MutationUpdateOffersRouteArgs = {
  input: UpdateOffersRouteConsumersInput;
};


/** The root type for all write operations. */
export type MutationUpdateOffersDriverArgs = {
  input: UpdateOffersDriverInput;
};


/** The root type for all write operations. */
export type MutationAcceptOfferArgs = {
  input: AcceptOfferResponseInput;
};


/** The root type for all write operations. */
export type MutationCounterOfferArgs = {
  input: CounterOfferResponseInput;
};


/** The root type for all write operations. */
export type MutationDeclineOfferArgs = {
  input: DeclineOfferResponseInput;
};


/** The root type for all write operations. */
export type MutationUpdateMultipleOfferResponseArgs = {
  input: ReadonlyArray<UpdateMultipleOfferResponseInput>;
};


/** The root type for all write operations. */
export type MutationUpdateOptiMatchSessionArgs = {
  session: OptiMatchSessionArgs;
};


/** The root type for all write operations. */
export type MutationGenerateOptiMatchSolutionArgs = {
  sessionId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationUpdateOptiMatchPlannedMatchesArgs = {
  sessionId: Scalars['ID'];
  addPlannedMatches?: InputMaybe<ReadonlyArray<OptiMatchAssignmentInput>>;
};


/** The root type for all write operations. */
export type MutationUpdateOptiMatchRejectedMatchesArgs = {
  sessionId: Scalars['ID'];
  addRejectedMatches?: InputMaybe<ReadonlyArray<OptiMatchAssignmentInput>>;
};


/** The root type for all write operations. */
export type MutationSetOptiMatchInputAvailabilityArgs = {
  sessionId: Scalars['ID'];
  routeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  capacityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  availability?: InputMaybe<Scalars['String']>;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateObjectiveArgs = {
  objective: OptiMatchCustomObjectiveInput;
};


/** The root type for all write operations. */
export type MutationDeleteObjectiveArgs = {
  objectiveId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreatePlannedNetworkArgs = {
  input: CreatePlannedNetworkInput;
};


/** The root type for all write operations. */
export type MutationUpdatePlannedNetworkArgs = {
  input: UpdatePlannedNetworkInput;
};


/** The root type for all write operations. */
export type MutationCreateNetworkChannelArgs = {
  input: CreateNetworkChannelInput;
};


/** The root type for all write operations. */
export type MutationUpdateNetworkChannelArgs = {
  input: UpdateNetworkChannelInput;
};


/** The root type for all write operations. */
export type MutationCreateChannelLegArgs = {
  input: CreateChannelLegInput;
};


/** The root type for all write operations. */
export type MutationUpdateChannelLegArgs = {
  input: UpdateChannelLegInput;
};


/** The root type for all write operations. */
export type MutationDeactivatePlannedNetworkArgs = {
  input: DeactivatePlannedNetworkInput;
};


/** The root type for all write operations. */
export type MutationActivatePlannedNetworkArgs = {
  input: ActivatePlannedNetworkInput;
};


/** The root type for all write operations. */
export type MutationDeactivateNetworkChannelArgs = {
  input: DeactivateNetworkChannelInput;
};


/** The root type for all write operations. */
export type MutationActivateNetworkChannelArgs = {
  input: ActivateNetworkChannelInput;
};


/** The root type for all write operations. */
export type MutationDeactivateChannelLegArgs = {
  input: DeactivateChannelLegInput;
};


/** The root type for all write operations. */
export type MutationActivateChannelLegArgs = {
  input: ActivateChannelLegInput;
};


/** The root type for all write operations. */
export type MutationCreatePostingArgs = {
  input: CreatePostingInput;
};


/** The root type for all write operations. */
export type MutationUpdatePostingArgs = {
  input: UpdatePostingInput;
};


/** The root type for all write operations. */
export type MutationRemovePostingArgs = {
  input?: InputMaybe<RemovePostingInput>;
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationCreatePostingsArgs = {
  input: CreatePostingsInput;
};


/** The root type for all write operations. */
export type MutationRemovePostingsArgs = {
  input: RemovePostingsInput;
};


/** The root type for all write operations. */
export type MutationRemovePostingsByRouteIdsArgs = {
  input: RemovePostingsByRouteIdsInput;
};


/** The root type for all write operations. */
export type MutationAssetPowerIdentifierArgs = {
  powerIdentifier: PowerIdentifierInput;
};


/** The root type for all write operations. */
export type MutationDeletePowerIdentifierArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationAssetPowerFleetArgs = {
  fleet: AssetPowerFleetInput;
};


/** The root type for all write operations. */
export type MutationDeletePowerFleetsArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationAssetPowerMaintenanceArgs = {
  powerMaintenance: PowerMaintenanceInput;
};


/** The root type for all write operations. */
export type MutationDeletePowerMaintenanceArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationAssetPowerEquipmentArgs = {
  powerEquipmentInput: PowerEquipmentInput;
};


/** The root type for all write operations. */
export type MutationDeletePowerEquipmentArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveAssetPowerArgs = {
  objAssetPower: CreateAssetPowerInput;
};


/** The root type for all write operations. */
export type MutationDeleteAssetPowerArgs = {
  objAssetID: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationUpdateAssetPowerArgs = {
  equipmentInput: ReadonlyArray<CreateAssetPowerEquipmentInput>;
  identifierInput: ReadonlyArray<CreateAssetPowerIdentifierInput>;
  permanentTrailerInput: ReadonlyArray<CreateAssetPermanentTrailerInput>;
  permanentDriverInput: ReadonlyArray<CreateAssetPowerPermanentDriverInput>;
  input: ReadonlyArray<CreateAssetPowerLegalToRunInput>;
  objAssetPower: CreateAssetPowerInput;
  objAssetID: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateUpdatePowerPermanentDriversArgs = {
  objAssetDriverID: Scalars['String'];
  objPowerDriverinput: ReadonlyArray<CreateAssetPowerPermanentDriverInput>;
};


/** The root type for all write operations. */
export type MutationCreateUpdatePowerPermanentTrailersArgs = {
  objAssetTrailerID: Scalars['String'];
  objPowerTrailerinput: ReadonlyArray<CreateAssetPermanentTrailerInput>;
};


/** The root type for all write operations. */
export type MutationUpdateAssetPowersByOperationsArgs = {
  objMaintenanceInput?: InputMaybe<ReadonlyArray<CreateAssetPowerMaintenanceInput>>;
  objFacilityLocation?: InputMaybe<Scalars['String']>;
  objLastFacility?: InputMaybe<Scalars['String']>;
  objLastPingDateTm?: InputMaybe<Scalars['String']>;
  objLastPingLocation?: InputMaybe<Scalars['String']>;
  objUpdatedBy?: InputMaybe<Scalars['String']>;
  objPool?: InputMaybe<Scalars['String']>;
  objFleet?: InputMaybe<Scalars['String']>;
  objServiceStatus?: InputMaybe<Scalars['String']>;
  objOperatingStatus?: InputMaybe<Scalars['String']>;
  objAssetPowerID: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdatePowerArgs = {
  input: CreateAssetPowerInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdatePowerV2Args = {
  input: CreateAssetPowerInputV2;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdatePowerFleetRelationshipArgs = {
  powerFleet: CreateOrUpdatePowerFleetRelationshipInput;
};


/** The root type for all write operations. */
export type MutationDeletePowerFleetArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationPowerPatchArgs = {
  input: AssetPowerPatchInput;
};


/** The root type for all write operations. */
export type MutationCreatePreplanArgs = {
  input: CreatePreplanInput;
};


/** The root type for all write operations. */
export type MutationCancelPreplanArgs = {
  input: CancelPreplanInput;
};


/** The root type for all write operations. */
export type MutationUpdatePreplanArgs = {
  input: UpdatePreplanInput;
};


/** The root type for all write operations. */
export type MutationCancelPreplanAutomatedArgs = {
  input: CancelPreplanInputV2;
};


/** The root type for all write operations. */
export type MutationUpdatePreplanAutomatedArgs = {
  input: UpdatePreplanInput;
};


/** The root type for all write operations. */
export type MutationCheckAndSavePreplanAutomationOutboxEventArgs = {
  input: CheckAndSavePreplanUpdateEventInOutboxInput;
};


/** The root type for all write operations. */
export type MutationBinOverrideArgs = {
  binOverrideInput: BinOverrideInput;
};


/** The root type for all write operations. */
export type MutationUpdateMaxCostArgs = {
  updateMaxCostInput: UpdateMaxCostInput;
};


/** The root type for all write operations. */
export type MutationProcessExternalAvailableRouteArgs = {
  externalAvailableRoute: SaveExternalAvailableRouteInput;
};


/** The root type for all write operations. */
export type MutationDeleteAvailableRoutesByFilterArgs = {
  filter: AvailableRoutesFilter;
  limit?: InputMaybe<Scalars['Float']>;
};


/** The root type for all write operations. */
export type MutationMarkActedUponArgs = {
  capacityMatchType: CapacityMatchType;
  capacityId?: InputMaybe<Scalars['String']>;
  matchId: Scalars['String'];
  actions: ReadonlyArray<Scalars['String']>;
};


/** The root type for all write operations. */
export type MutationImportLoadV2Args = {
  loadId: Scalars['String'];
  routeActivationStatus?: InputMaybe<Scalars['String']>;
  routeLifecycleStatus?: InputMaybe<Scalars['String']>;
};


/** The root type for all write operations. */
export type MutationImportRouteArgs = {
  routeId: Scalars['String'];
  routeActivationStatus?: InputMaybe<Scalars['String']>;
  routeLifecycleStatus?: InputMaybe<Scalars['String']>;
  dataView?: InputMaybe<AvailableRouteDataViewInput>;
};


/** The root type for all write operations. */
export type MutationImportTruckEntryV2Args = {
  truckEntryId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationDeleteAvailableTruckV2Args = {
  truckPostingId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationImportGateReservationArgs = {
  gateReservationId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationDeleteAvailableGateReservationArgs = {
  gateReservationId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationImportContainerArgs = {
  containerId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationDeleteAvailableContainerArgs = {
  containerId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationUpdateCapacityForCarrierArgs = {
  carrierId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationUpdateDriverAssignedToTrucksArgs = {
  driverId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationImportDriverHosEventArgs = {
  driverId: Scalars['String'];
  driverHosEvent: ProcurementAssetDriverHosEventInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateProjectArgs = {
  input: CreateOrUpdateProjectInput;
};


/** The root type for all write operations. */
export type MutationCreateRateConfirmationArgs = {
  input: RateConfirmationInput;
};


/** The root type for all write operations. */
export type MutationStartAutoRateConfirmationArgs = {
  input: StartAutoRateConfirmationInput;
};


/** The root type for all write operations. */
export type MutationSendRateConfirmationViaEdiArgs = {
  input: SendRateConfirmationViaEdiInput;
};


/** The root type for all write operations. */
export type MutationSendRateConfirmationViaEmailArgs = {
  input: SendRateConfirmationViaEmailInput;
};


/** The root type for all write operations. */
export type MutationSendDrayNotificationViaEdiArgs = {
  input: SendDrayNotificationViaEdiInput;
};


/** The root type for all write operations. */
export type MutationSendDrayNotificationViaEmailArgs = {
  input: SendDrayNotificationViaEmailInput;
};


/** The root type for all write operations. */
export type MutationSendRailBillingViaEdiArgs = {
  input: SendRailBillingViaEdiInput;
};


/** The root type for all write operations. */
export type MutationUpdateTrailerAcquisitionStopPendingArgs = {
  routeVendorId: Scalars['ID'];
  isTrailerAcquisitionStopPending: Scalars['Boolean'];
};


/** The root type for all write operations. */
export type MutationStartAutoRateConCostLineArgs = {
  input: AutoRateConCostLineInput;
};


/** The root type for all write operations. */
export type MutationStartAutoRateConTaskArgs = {
  input: AutoRateConTaskInput;
};


/** The root type for all write operations. */
export type MutationStartAutoRateConBolArgs = {
  input: AutoRateConBolInput;
};


/** The root type for all write operations. */
export type MutationStartAutoRateConTrailerExecutionArgs = {
  input: AutoRateConTrailerExecutionInput;
};


/** The root type for all write operations. */
export type MutationStartAutoRateConStopArgs = {
  input: AutoRateConStopInput;
};


/** The root type for all write operations. */
export type MutationHandleRateConBolExceptionArgs = {
  input: BolExceptionInput;
};


/** The root type for all write operations. */
export type MutationConvertQuoteToLoadArgs = {
  input: QuoteToLoadInput;
};


/** The root type for all write operations. */
export type MutationCreateCustomerQuoteV2Args = {
  input: RateQuoteInput;
};


/** The root type for all write operations. */
export type MutationCreateCustomerQuoteV3Args = {
  input: RateQuoteInputV3;
};


/** The root type for all write operations. */
export type MutationCreateCustomerQuoteV4Args = {
  input: RateQuoteInputV4;
};


/** The root type for all write operations. */
export type MutationCreateCustomerQuoteV5Args = {
  input: RateQuoteInputV4;
};


/** The root type for all write operations. */
export type MutationCreateCustomerQuoteV6Args = {
  input: RateQuoteInputV5;
};


/** The root type for all write operations. */
export type MutationCreateCustomerQuoteV7Args = {
  input: RateQuoteInputV6;
};


/** The root type for all write operations. */
export type MutationCreateCustomerQuoteV8Args = {
  input: RateQuoteInputV7;
};


/** The root type for all write operations. */
export type MutationRemoveCustomerQuoteFromGroupV2Args = {
  input: RemoveRateQuoteFromQuoteGroupInput;
};


/** The root type for all write operations. */
export type MutationRemoveCustomerQuoteFromGroupV3Args = {
  input: RemoveRateQuoteFromQuoteGroupInput;
};


/** The root type for all write operations. */
export type MutationRemoveCustomerQuoteFromGroupV4Args = {
  input: RemoveRateQuoteFromQuoteGroupInput;
};


/** The root type for all write operations. */
export type MutationRemoveCustomerQuoteFromGroupV5Args = {
  input: RemoveRateQuoteFromQuoteGroupInput;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerQuoteArgs = {
  input: UpdateRateQuoteInput;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerQuoteV1Args = {
  input: UpdateRateQuoteInputV1;
};


/** The root type for all write operations. */
export type MutationAddVendorToRouteArgs = {
  input: AddVendorToRouteInput;
};


/** The root type for all write operations. */
export type MutationAssignVendorToRouteArgs = {
  input: AssignVendorToRouteInput;
};


/** The root type for all write operations. */
export type MutationBounceRouteVendorArgs = {
  input: BounceRouteVendorInput;
};


/** The root type for all write operations. */
export type MutationBulkUpdateTenderStatusArgs = {
  input: ReadonlyArray<BulkUpdateTenderStatusInput>;
};


/** The root type for all write operations. */
export type MutationCreateCreditHistoryArgs = {
  input: CreditHistoryInput;
};


/** The root type for all write operations. */
export type MutationCreateCustomerCreditProviderArgs = {
  input: CustomerCreditProviderInput;
};


/** The root type for all write operations. */
export type MutationCreateCustomerQuoteArgs = {
  input: QuoteInput;
};


/** The root type for all write operations. */
export type MutationCreateLoadArgs = {
  input: LoadInput;
};


/** The root type for all write operations. */
export type MutationCreateMultipleTruckPostingsArgs = {
  input: TruckPostingInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateAddressArgs = {
  input: CreateAddressInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierArgs = {
  input: CarrierInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierCertificationArgs = {
  input: CarrierCertificationInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierCustomerRelationshipArgs = {
  input: CarrierCustomerRelationshipInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierReleaseMethodV1Args = {
  input: CarrierReleaseMethodInputV1;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierRiskInformationArgs = {
  input: CarrierRiskInformationInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierServiceArgs = {
  input: CarrierServiceStandaloneInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCarrierSettingArgs = {
  input: CarrierSettingInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateContactArgs = {
  input: CreateContactInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerArgs = {
  input: CustomerInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerFacilityRelationshipArgs = {
  input: CustomerFacilityRelationshipInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerGroupArgs = {
  input: CustomerGroupInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerOrderArgs = {
  input: CreateOrUpdateCustomerOrderInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerSettingArgs = {
  input: CustomerSettingInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateEmployeeArgs = {
  input: EmployeeInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateEmployeeExternalArgs = {
  input: EmployeeInputTypeExternal;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateFacilityArgs = {
  input: FacilityInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateFacilityIdentifierArgs = {
  input: FacilityIdentifierStandaloneInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateSingleTruckPostingArgs = {
  input: TruckPostingInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateStopArgs = {
  input: CreateOrUpdateStopInput;
};


/** The root type for all write operations. */
export type MutationDestroyAddressArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationDestroyCarrierCertificationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationDestroyCarrierReleaseMethodV1Args = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationDestroyCarrierServiceArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDestroyContactArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all write operations. */
export type MutationDestroyFacilityIdentifierArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDestroyTruckPostingArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationFindOrCreateFacilityArgs = {
  input: FindOrCreateFacilityInput;
};


/** The root type for all write operations. */
export type MutationRebookRouteVendorArgs = {
  input: RebookRouteVendorInput;
};


/** The root type for all write operations. */
export type MutationRemoveCustomerQuoteFromGroupArgs = {
  input: RemoveQuoteFromGroupInput;
};


/** The root type for all write operations. */
export type MutationUpdateBounceArgs = {
  input: UpdateBounceInput;
};


/** The root type for all write operations. */
export type MutationUpdateCarrierIntrastateArgs = {
  input: CarrierIntrastateInput;
};


/** The root type for all write operations. */
export type MutationUpdateCarrierStandingArgs = {
  input: CarrierStandingInput;
};


/** The root type for all write operations. */
export type MutationUpdateLoadArgs = {
  input: UpdateLoadInput;
};


/** The root type for all write operations. */
export type MutationUpdateRouteArgs = {
  input: UpdateRouteInput;
};


/** The root type for all write operations. */
export type MutationUpdateRouteStopsArgs = {
  input: UpdateRouteStopsInput;
};


/** The root type for all write operations. */
export type MutationUpdateRouteVendorArgs = {
  input: UpdateRouteVendorInput;
};


/** The root type for all write operations. */
export type MutationRequestToBookV2Args = {
  bypassBooking?: InputMaybe<Scalars['Boolean']>;
  carrierId?: InputMaybe<Scalars['ID']>;
  orderId: Scalars['ID'];
  routeId: Scalars['ID'];
  expectedReady?: InputMaybe<ExpectedReadyInputV2>;
};


/** The root type for all write operations. */
export type MutationAddTrailerToRouteResourcesArgs = {
  input: AddTrailerToRouteResourcesInput;
};


/** The root type for all write operations. */
export type MutationDeleteTrailerFromRouteResourcesArgs = {
  input: DeleteTrailerFromRouteResourcesInput;
};


/** The root type for all write operations. */
export type MutationToggleRouteResourcesTrailerAccessoriesArgs = {
  input: ToggleRouteResourcesTrailerAccessoriesInput;
};


/** The root type for all write operations. */
export type MutationDeleteRouteResourcesArgs = {
  input: DeleteRouteResourcesInput;
};


/** The root type for all write operations. */
export type MutationBounceDriversFromRouteResourceArgs = {
  input: BounceDriversFromRouteResourcesInput;
};


/** The root type for all write operations. */
export type MutationModifyRouteResourcesArgs = {
  input: ModifyRouteResourcesInput;
};


/** The root type for all write operations. */
export type MutationUpsertRouteResourcesArgs = {
  input: UpsertRouteResourcesInput;
};


/** The root type for all write operations. */
export type MutationCreateDriverAssignmentArgs = {
  createDriverAssignmentInput: CreateDriverAssignmentInput;
};


/** The root type for all write operations. */
export type MutationUpdateDriverAssignmentArgs = {
  updateDriverAssignmentInput: UpdateDriverAssignmentInput;
};


/** The root type for all write operations. */
export type MutationUpsertDriverAssignmentArgs = {
  driverAssignment: DriverAssignmentInput;
};


/** The root type for all write operations. */
export type MutationUpsertFleetResourceArgs = {
  resource: UpsertFleetResourceInput;
};


/** The root type for all write operations. */
export type MutationBounceDriversFromFleetResourceArgs = {
  bounceDriversInput: BounceDriversInput;
};


/** The root type for all write operations. */
export type MutationDeleteDriverAssignmentArgs = {
  deleteDriverAssignmentInput?: InputMaybe<DeleteDriverAssignmentInput>;
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationDeleteFleetResourceArgs = {
  deleteFleetResourceInput: DeleteFleetResourceInput;
};


/** The root type for all write operations. */
export type MutationToggleDriverAssignmentTrailerAccessoriesArgs = {
  toggleDriverAssignmentTrailerAccessoriesInput: ToggleDriverAssignmentTrailerAccessoriesInput;
};


/** The root type for all write operations. */
export type MutationAddTrailerToDriverAssignmentArgs = {
  addTrailerToDriverAssignmentInput: AddTrailerToDriverAssignmentInput;
};


/** The root type for all write operations. */
export type MutationDeleteTrailerFromDriverAssignmentArgs = {
  deleteTrailerFromDriverAssignmentInput: DeleteTrailerFromDriverAssignmentInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateFilterArgs = {
  input: SavedFilterInputType;
};


/** The root type for all write operations. */
export type MutationDeleteFilterByIdArgs = {
  input: DeleteFilterByIdInputType;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateTablePreferenceArgs = {
  input: TablePreferenceInput;
};


/** The root type for all write operations. */
export type MutationBulkUpdateTablePreferenceArgs = {
  input: ReadonlyArray<TablePreferenceInput>;
};


/** The root type for all write operations. */
export type MutationDeleteTablePreferencesByIdArgs = {
  input: DeleteTablePreferenceByIdInput;
};


/** The root type for all write operations. */
export type MutationCreateSecureDataDriversLicenseTokensArgs = {
  input: CreateSecureDataDriversLicenseTokensInput;
};


/** The root type for all write operations. */
export type MutationCreateSecureDataDriversLicenseTokensAsyncArgs = {
  input: CreateSecureDataDriversLicenseTokensInput;
};


/** The root type for all write operations. */
export type MutationSendDirectMessageArgs = {
  singleUserInput: SingleUserInputObj;
};


/** The root type for all write operations. */
export type MutationSendMsgToMultipleUsersArgs = {
  multiUserInput: MultiUserInputObj;
};


/** The root type for all write operations. */
export type MutationSendMessageToSlackArgs = {
  sendMsgToSlackInput: SendMsgToSlackInputObj;
};


/** The root type for all write operations. */
export type MutationAppCreationArgs = {
  appCreationInput: AppCreationInputObj;
};


/** The root type for all write operations. */
export type MutationSendSlackMessageArgs = {
  userInput: SendMsgInputObj;
};


/** The root type for all write operations. */
export type MutationCreateSpotQuoteArgs = {
  input: CreateSpotQuoteInput;
};


/** The root type for all write operations. */
export type MutationUpdateSpotQuoteArgs = {
  input: UpdateSpotQuoteInput;
};


/** The root type for all write operations. */
export type MutationUpdateSpotQuoteV2Args = {
  input: UpdateSpotQuoteV2Input;
};


/** The root type for all write operations. */
export type MutationConvertSpotQuoteToLoadArgs = {
  input: ConvertSpotQuoteToLoadInput;
};


/** The root type for all write operations. */
export type MutationIngressStopEventV2Args = {
  input: StopEventV2IngressInput;
};


/** The root type for all write operations. */
export type MutationIngressStopEventWithEtasArgs = {
  input: IngressStopEventWithEtasInput;
};


/** The root type for all write operations. */
export type MutationUpsertStopEventsV2Args = {
  input: ReadonlyArray<StopEventV2UpsertInput>;
};


/** The root type for all write operations. */
export type MutationUpsertStopEventV2Args = {
  input: StopEventV2UpsertInput;
};


/** The root type for all write operations. */
export type MutationDeleteStopEventV2Args = {
  input: StopEventV2DeleteInput;
};


/** The root type for all write operations. */
export type MutationUpdateStopEventV2LateReasonArgs = {
  input: StopEventV2LateReasonUpdateInput;
};


/** The root type for all write operations. */
export type MutationCreateTaskV2Args = {
  create: CreateTaskInputV2;
};


/** The root type for all write operations. */
export type MutationDeleteTaskV2Args = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationUpdateTaskV2Args = {
  update: UpdateTaskInputV2;
};


/** The root type for all write operations. */
export type MutationCloseTaskV2Args = {
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Scalars['String']>;
};


/** The root type for all write operations. */
export type MutationCreateTimelineJournalEntryArgs = {
  input: JournalEntryCreateInput;
};


/** The root type for all write operations. */
export type MutationUpdateTimelineJournalEntryArgs = {
  input: JournalEntryUpdateInput;
};


/** The root type for all write operations. */
export type MutationCreateTimelineJournalEntryV2Args = {
  input: JournalEntryCreateInputV2;
};


/** The root type for all write operations. */
export type MutationUpdateTimelineJournalEntryV2Args = {
  input: JournalEntryUpdateInputV2;
};


/** The root type for all write operations. */
export type MutationUpsertAutoEtaArgs = {
  input: UpsertAutoEtaInput;
};


/** The root type for all write operations. */
export type MutationCreateTrackingUpdateV4Args = {
  update: TrackingUpdateInputV4;
};


/** The root type for all write operations. */
export type MutationAssetTrailerFleetArgs = {
  fleet: AssetTrailerFleetInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateTrailerFleetRelationshipArgs = {
  trailerFleet: TrailerFleetInput;
};


/** The root type for all write operations. */
export type MutationDeleteTrailerFleetArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationAssetTrailerIdentifierArgs = {
  identifier: TrailerIdentifierInput;
};


/** The root type for all write operations. */
export type MutationDeleteTrailerIdentifierArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationAssetTrailerEquipmentArgs = {
  equipment: TrailerEquipmentInput;
};


/** The root type for all write operations. */
export type MutationDeleteTrailerEquipmentArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationAssetTrailerMaintenanceArgs = {
  maintenance: TrailerMaintenanceInput;
};


/** The root type for all write operations. */
export type MutationDeleteTrailerMaintenanceArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationAssetTrailerCustomerArgs = {
  customer: TrailerCustomerRelationshipInput;
};


/** The root type for all write operations. */
export type MutationDeleteTrailerCustomerArgs = {
  id: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSave_Asset_TrailersArgs = {
  objAsset_Trailers: CreateAssetTrailersInput;
};


/** The root type for all write operations. */
export type MutationUpdateAsset_TrailersArgs = {
  equipmentInput: ReadonlyArray<CreateAssetTrailersEquipmentInput>;
  identifierInput: ReadonlyArray<CreateAssetTrailersIdentifierInput>;
  permanentTractorinput: ReadonlyArray<CreateAssetPermanentTractorInput>;
  permanentDriverinput: ReadonlyArray<CreateAssetPermanentDriverInput>;
  input: ReadonlyArray<CreateAssetTrailersLegalToRunInput>;
  objAsset_Trailers: CreateAssetTrailersInput;
  objAssetID: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationUpdateAssetTrailersByOperationsArgs = {
  maintenanceInput: ReadonlyArray<CreateAssetTrailerMaintenanceInput>;
  objUpdatedBy?: InputMaybe<Scalars['String']>;
  objPool?: InputMaybe<Scalars['String']>;
  objFleet?: InputMaybe<Scalars['String']>;
  objServiceStatus?: InputMaybe<Scalars['String']>;
  objOperatingStatus?: InputMaybe<Scalars['String']>;
  objAssetTrailerID: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateTrailerArgs = {
  input: CreateAssetTrailersInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateTrailerV3Args = {
  input: CreateAssetTrailersInputV2;
};


/** The root type for all write operations. */
export type MutationAssetTrailerPatchArgs = {
  input: AssetTrailerPatchInput;
};


/** The root type for all write operations. */
export type MutationAssetTrailerServiceStatusArgs = {
  serviceStatusDetails: TrailerServiceStatusDetailsInput;
};


/** The root type for all write operations. */
export type MutationFlipTrailerOrchestratorRoutesArgs = {
  input: FlipInput;
};


/** The root type for all write operations. */
export type MutationCreateContainerArgs = {
  input: CreateContainerInput;
};


/** The root type for all write operations. */
export type MutationUpdateContainerArgs = {
  input: UpdateContainerInput;
};


/** The root type for all write operations. */
export type MutationDeleteContainerArgs = {
  id: Scalars['ID'];
  updatedByUserId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationBulkUpdateContainersArgs = {
  input: BulkUpdateContainersInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateTruckByExternalSourceIdArgs = {
  input?: InputMaybe<CreateOrUpdateTruckByExternalSourceIdInput>;
};


/** The root type for all write operations. */
export type MutationCreateTruckEntryArgs = {
  input?: InputMaybe<CreateTruckEntryInput>;
};


/** The root type for all write operations. */
export type MutationInternalCreateTruckEntryArgs = {
  input?: InputMaybe<InternalCreateTruckEntryInput>;
};


/** The root type for all write operations. */
export type MutationUpdateTruckEntryArgs = {
  input?: InputMaybe<UpdateTruckEntryInput>;
};


/** The root type for all write operations. */
export type MutationUpdateTruckEntryUtilizationStatusArgs = {
  input?: InputMaybe<UpdateTruckEntryUtilizationStatusInput>;
};


/** The root type for all write operations. */
export type MutationDeleteTruckEntryArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** The root type for all write operations. */
export type MutationUpdateTruckEntryTemplateArgs = {
  input?: InputMaybe<UpdateTruckEntryTemplateInput>;
};


/** The root type for all write operations. */
export type MutationDeleteTruckEntryTemplateArgs = {
  input?: InputMaybe<DeleteTruckEntryTemplateInput>;
};


/** The root type for all write operations. */
export type MutationCreateTruckEntryTemplateArgs = {
  input?: InputMaybe<CreateTruckEntryTemplateInput>;
};


/** The root type for all write operations. */
export type MutationDeactivateTruckEntryTemplateArgs = {
  input?: InputMaybe<DeactivateTruckEntryTemplateInput>;
};


/** The root type for all write operations. */
export type MutationCreateFirstTruckEntriesArgs = {
  input?: InputMaybe<CreateFirstTruckEntriesInput>;
};


/** The root type for all write operations. */
export type MutationUpdateFirstTruckEntriesArgs = {
  input?: InputMaybe<UpdateFirstTruckEntriesInput>;
};


/** The root type for all write operations. */
export type MutationCreateGateReservationArgs = {
  input: CreateGateReservationInput;
};


/** The root type for all write operations. */
export type MutationUpdateGateReservationArgs = {
  input: UpdateGateReservationInput;
};


/** The root type for all write operations. */
export type MutationDeleteGateReservationArgs = {
  id: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateOrDeleteTruckByRouteIdArgs = {
  input?: InputMaybe<CreateOrUpdateOrDeleteTruckByRouteIdInput>;
};


/** The root type for all write operations. */
export type MutationCreateTruckEntryFromDriverAssignmentArgs = {
  input?: InputMaybe<CreateTruckEntryFromDriverAssignmentInput>;
};


/** The root type for all write operations. */
export type MutationDeleteTruckByRouteIdArgs = {
  routeId?: InputMaybe<Scalars['String']>;
};


/** The root type for all write operations. */
export type MutationUpdateTruckEntryByRouteIdArgs = {
  input?: InputMaybe<UpdateTruckEntryByRouteIdInput>;
};


/** The root type for all write operations. */
export type MutationUpdateReloadIntentCapacityReadyByDateTimeArgs = {
  input: UpdateReloadIntentCapacityReadyByDateTimeInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateContainerByRouteIdArgs = {
  input?: InputMaybe<CreateOrUpdateContainerByRouteIdInput>;
};


/** The root type for all write operations. */
export type MutationDeleteContainerByRouteIdArgs = {
  input?: InputMaybe<DeleteContainerByRouteIdInput>;
};


/** The root type for all write operations. */
export type MutationCreateTruckEntryTemplateAndFirstTrucksArgs = {
  input?: InputMaybe<CreateTruckEntryTemplateInputV2>;
};


/** The root type for all write operations. */
export type MutationUpdateTruckEntryTemplateAndFirstTrucksArgs = {
  input?: InputMaybe<UpdateTruckEntryTemplateInput>;
};


/** The root type for all write operations. */
export type MutationCreatePatternV2Args = {
  patternInput: CreatePatternInput;
};


/** The root type for all write operations. */
export type MutationDeletePatternV2Args = {
  deletePatternInput: DeletePatternInput;
};


/** The root type for all write operations. */
export type MutationReorderLanePatternsByPriorityV2Args = {
  reorderLanePatternsByPriorityInput: ReorderLanePatternsByPriorityInput;
};


/** The root type for all write operations. */
export type MutationReorderAutoApplyPriorityArgs = {
  reorderAutoApplyPriorityInput: ReorderAutoApplyPriorityInput;
};


/** The root type for all write operations. */
export type MutationUpdatePatternV2Args = {
  patternInput: UpdatePatternInput;
};


/** The root type for all write operations. */
export type MutationApplyPatternV3Args = {
  applyPatternInputV2: ApplyPatternInputV2;
};


/** The root type for all write operations. */
export type MutationApplyPatternAsyncArgs = {
  applyPatternAsyncInput: ApplyPatternAsyncInput;
};


/** The root type for all write operations. */
export type MutationCreateCanvasConfigurationArgs = {
  createCanvasConfigurationInput: CreateCanvasConfigurationInput;
};


/** The root type for all write operations. */
export type MutationUpdateCanvasConfigurationArgs = {
  updateCanvasConfigurationInput: UpdateCanvasConfigurationInput;
};


/** The root type for all write operations. */
export type MutationDeleteCanvasConfigurationArgs = {
  uuid: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationUpdateCanvasConfigurationPrioritiesArgs = {
  updateCanvasConfigurationPrioritiesInput: UpdateCanvasConfigurationPrioritiesInput;
};


/** The root type for all write operations. */
export type MutationCreateValidationConfigurationArgs = {
  validationConfigurationInput: ValidationConfigurationInput;
};


/** The root type for all write operations. */
export type MutationUpdateValidationConfigurationArgs = {
  validationConfigurationInput: ValidationConfigurationInput;
};


/** The root type for all write operations. */
export type MutationUpdateValidationConfigurationByUuidArgs = {
  updateValidationConfigurationInput: UpdateValidationConfigurationInput;
};


/** The root type for all write operations. */
export type MutationDeleteValidationConfigurationArgs = {
  validationConfigurationKeysInput: ValidationConfigurationKeysInput;
};


/** The root type for all write operations. */
export type MutationDeleteValidationConfigurationByUuidArgs = {
  uuid: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationValidateCredentialsArgs = {
  credentials: GeneralAttributeInput;
  providerId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationUpdateProviderConfigArgs = {
  isAuthenticated?: InputMaybe<Scalars['Boolean']>;
  statusOn?: InputMaybe<Scalars['Boolean']>;
  providerId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationPutProviderConfigArgs = {
  credentials: GeneralAttributeInput;
  credentialsCleared?: InputMaybe<Scalars['Boolean']>;
  flagValue?: InputMaybe<Scalars['Boolean']>;
  providerId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationToggleProviderArgs = {
  name: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  secretKey: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationPatchIntegrationStatusArgs = {
  statusOn: Scalars['Boolean'];
  providerId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSaveEdiTransactionAsyncArgs = {
  ediTransaction: EdiTransactionAddInput;
};


/** The root type for all write operations. */
export type MutationSaveEdiTransactionAndJsonDataToBlobArgs = {
  saveEdiTransactionAndJsonDataToBlobInput: SaveEdiTransactionAndJsonDataToBlobInput;
};


/** The root type for all write operations. */
export type MutationAssociateShipmentIdWithLoadIdArgs = {
  input: AssociatedShipmentIdWithLoadIdInput;
};


/** The root type for all write operations. */
export type MutationSaveEdiOutboxMessageArgs = {
  input: EdiOutboxInput;
};


/** The root type for all write operations. */
export type MutationUpdateEdiOutboxMessageArgs = {
  input: UpdateEdiOutboxInput;
};


/** The root type for all write operations. */
export type MutationUpdateEdiTransactionByIdArgs = {
  input: UpdateEdiTransactionInput;
};


/** The root type for all write operations. */
export type MutationCreateOutbound214StopAppointmentArgs = {
  input: Outbound214StopAppointmentInput;
};


/** The root type for all write operations. */
export type MutationUpdateUnprocessedEdi204TransactionsArgs = {
  input: UpdateUnprocessedEdi204TransactionsInput;
};


/** The root type for all write operations. */
export type MutationUpdateTenderResponseInEdiTenderArgs = {
  input: UpdateTenderResponseInEdiTenderInput;
};


/** The root type for all write operations. */
export type MutationUpdateCarrierEdiTransactionByIdArgs = {
  input: UpdateCarrierEdiTransactionByIdInput;
};


/** The root type for all write operations. */
export type MutationSaveEdiTenderArgs = {
  input: SaveEdiTenderInput;
};


/** The root type for all write operations. */
export type MutationDeleteEdiOutboxMessageArgs = {
  input: DeleteEdiOutboxInput;
};


/** The root type for all write operations. */
export type MutationBulkUpdateEdiOutboxMessagesArgs = {
  input: BulkUpdateEdiOutboxInput;
};


/** The root type for all write operations. */
export type MutationSaveInbound204IsaReceiverIdArgs = {
  input: IsaReceiverIdApplicationCodeInput;
};


/** The root type for all write operations. */
export type MutationCreateCustomerOrderArgs = {
  orderInput: CustomerOrderInput;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerOrderArgs = {
  orderId: Scalars['ID'];
  orderInput: CustomerOrderInput;
  version?: InputMaybe<Scalars['Int']>;
};


/** The root type for all write operations. */
export type MutationCancelCustomerOrderV2Args = {
  input: CancelCustomerOrderInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCustomerOrderV2Args = {
  orderInput: CreateOrUpdateCustomerOrderInputV2;
};


/** The root type for all write operations. */
export type MutationUpdateOrderExternalDistanceArgs = {
  updateOrderExternalDistanceInput: UpdateOrderExternalDistanceInput;
};


/** The root type for all write operations. */
export type MutationUpdateCustomerOrderNumberArgs = {
  updateCustomerOrderNumberInput: UpdateCustomerOrderNumberInput;
};


/** The root type for all write operations. */
export type MutationCreateOrderStopArgs = {
  orderStopInput: OrderStopInput;
};


/** The root type for all write operations. */
export type MutationDeleteOrderStopArgs = {
  deleteOrderStopInput: DeleteOrderStopInput;
};


/** The root type for all write operations. */
export type MutationChangeCustomerOrderArgs = {
  changeCustomerOrderInput: ChangeCustomerOrderInput;
};


/** The root type for all write operations. */
export type MutationCreateLoadV2Args = {
  loadInput: LoadInputV2;
};


/** The root type for all write operations. */
export type MutationUpdateLoadV2Args = {
  loadInput: UpdateLoadInputV2;
};


/** The root type for all write operations. */
export type MutationPlanLoadArgs = {
  planLoadInput: PlanLoadInput;
};


/** The root type for all write operations. */
export type MutationResequenceStopsArgs = {
  resequenceStopsInput: ResequenceStopsInput;
};


/** The root type for all write operations. */
export type MutationResequenceLoadStopsArgs = {
  resequenceLoadStopsInput: ResequenceLoadStopsInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateStopV2Args = {
  loadStopInput: LoadStopInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateCityStateStopV2Args = {
  cityStateStopInput: CityStateStopInput;
};


/** The root type for all write operations. */
export type MutationDeleteStopArgs = {
  deleteStopInput: DeleteStopInput;
};


/** The root type for all write operations. */
export type MutationAssociateOrderStopsArgs = {
  associateOrderStopsInput: ReadonlyArray<AssociateOrderStopInput>;
};


/** The root type for all write operations. */
export type MutationAssociateLoadOrderStopsArgs = {
  associateLoadOrderStopsInput: ReadonlyArray<AssociateLoadOrderStopInput>;
};


/** The root type for all write operations. */
export type MutationAssociatePayableStopsArgs = {
  associatePayableStopsInput: AssociatePayableStopsInput;
};


/** The root type for all write operations. */
export type MutationAssociateLoadBillableStopsArgs = {
  associateLoadBillableStopsInput: ReadonlyArray<AssociateLoadBillableStopsInput>;
};


/** The root type for all write operations. */
export type MutationAssignFacilityToCityStateStopArgs = {
  assignFacilityToStopInput: AssignFacilityToCityStateStopInput;
};


/** The root type for all write operations. */
export type MutationChangeFacilityOnStopArgs = {
  changeFacilityOnStopInput: ChangeFacilityOnStopInput;
};


/** The root type for all write operations. */
export type MutationUpdateCityStateOnStopArgs = {
  updateCityStateOnStopInput: UpdateCityStateOnStopInput;
};


/** The root type for all write operations. */
export type MutationPlanLoadV2Args = {
  planLoadV2Input: PlanLoadV2Input;
};


/** The root type for all write operations. */
export type MutationDispatchDriverReviewArgs = {
  dispatchDriverInput: DispatchDriverInput;
};


/** The root type for all write operations. */
export type MutationDispatchDriverArgs = {
  dispatchDriverInput: DispatchDriverInput;
};


/** The root type for all write operations. */
export type MutationCreateLoadRouteArgs = {
  route: CreateLoadRouteInput;
  loadId: Scalars['ID'];
};


/** The root type for all write operations. */
export type MutationUpdateLoadRouteArgs = {
  routeInput: UpdateLoadRouteInput;
};


/** The root type for all write operations. */
export type MutationResetRouteStopRegionsArgs = {
  ResetRouteStopRegionsInput: ResetRouteStopRegionsInput;
};


/** The root type for all write operations. */
export type MutationDeleteRouteArgs = {
  deleteRouteInput: DeleteRouteInput;
};


/** The root type for all write operations. */
export type MutationUpdateBackhaulLoadRouteArgs = {
  updateBackhaulLoadRouteInput: UpdateBackhaulLoadRouteInput;
};


/** The root type for all write operations. */
export type MutationCreateRouteStopArgs = {
  routeStopInput: RouteStopInput;
};


/** The root type for all write operations. */
export type MutationDeleteRouteStopArgs = {
  deleteRouteStopInput: DeleteRouteStopInput;
};


/** The root type for all write operations. */
export type MutationCalculateCutOffsForStopsInOrderArgs = {
  calculateCutOffsForStopsInOrderInput: CalculateCutOffsForStopsInOrderInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateRouteReferencesArgs = {
  routeReferencesInput: CreateOrUpdateRouteReferencesInput;
};


/** The root type for all write operations. */
export type MutationDeleteRouteReferenceArgs = {
  refId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateRouteAssignedRepArgs = {
  routeAssignedRepInput: CreateOrUpdateRouteAssignedRepInput;
};


/** The root type for all write operations. */
export type MutationCreateOrUpdateRouteAssignedRepsArgs = {
  routeAssignedRepsInput: CreateOrUpdateRouteAssignedRepsInput;
};


/** The root type for all write operations. */
export type MutationDeleteRouteAssignedRepArgs = {
  repId: Scalars['String'];
};


/** The root type for all write operations. */
export type MutationSplitRouteAtStopArgs = {
  input: SplitRouteAtStopInput;
};


/** The root type for all write operations. */
export type MutationAddStopAndSplitRouteArgs = {
  input: AddStopAndSplitRouteInput;
};


/** The root type for all write operations. */
export type MutationAddStopAndSplitRouteV2Args = {
  input: AddStopAndSplitRouteInputV2;
};


/** The root type for all write operations. */
export type MutationCreateOrderProfileArgs = {
  orderProfileInput: CreateOrderProfileInput;
};


/** The root type for all write operations. */
export type MutationActivateOrDeactivateOrderProfileArgs = {
  activateOrDeactivateOrderProfileInput: ActivateOrDeactivateOrderProfileInput;
};


/** The root type for all write operations. */
export type MutationUpdateOrderProfileArgs = {
  updateOrderProfileInput: UpdateOrderProfileInput;
};


/** The root type for all write operations. */
export type MutationResequenceOrderProfilePriorityArgs = {
  resequenceOrderProfilePriorityInput: ResequenceOrderProfilePriorityInput;
};


/** The root type for all write operations. */
export type MutationCreateOrderProfileV2Args = {
  orderProfileInputV2: CreateOrderProfileInputV2;
};


/** The root type for all write operations. */
export type MutationActivateOrDeactivateOrderProfileV2Args = {
  activateOrDeactivateOrderProfileInput: ActivateOrDeactivateOrderProfileInput;
};


/** The root type for all write operations. */
export type MutationUpdateOrderProfileV2Args = {
  updateOrderProfileInput: UpdateOrderProfileInput;
};


/** The root type for all write operations. */
export type MutationCloneLoadArgs = {
  cloneLoadInput: CloneLoadInput;
};


/** The root type for all write operations. */
export type MutationRepeatLoadArgs = {
  repeatLoadInput: RepeatLoadInput;
};


/** The root type for all write operations. */
export type MutationCreateOrganizationArgs = {
  args: CreateOrganizationInput;
};


/** The root type for all write operations. */
export type MutationUpdateOrganizationNameArgs = {
  args: UpdateOrganizationNameInput;
};


/** The root type for all write operations. */
export type MutationUpdateOrganizationActivationStatusArgs = {
  args: UpdateOrganizationActivationStatus;
};


/** The root type for all write operations. */
export type MutationUpdateOrganizationParentArgs = {
  args: UpdateOrganizationParent;
};


/** The root type for all write operations. */
export type MutationCreateMembershipArgs = {
  args: CreateMembershipInput;
};


/** The root type for all write operations. */
export type MutationCreateManyMembershipsForUserArgs = {
  args: CreateMembershipsForUserInput;
};


/** The root type for all write operations. */
export type MutationUpdateMembershipAccessLevelArgs = {
  args: UpdateMembershipAccessLevel;
};


/** The root type for all write operations. */
export type MutationDeleteMembershipArgs = {
  args: DeleteMembershipInput;
};


/** The root type for all write operations. */
export type MutationDeleteMembershipsArgs = {
  args: DeleteMembershipsInput;
};


/** The root type for all write operations. */
export type MutationAssignRolesToUserArgs = {
  args: AssignRolesToUserInput;
};


/** The root type for all write operations. */
export type MutationAssignRolesToUserV2Args = {
  args: AssignRolesToUserInputV2;
};


/** The root type for all write operations. */
export type MutationRemoveRolesFromUserArgs = {
  args: AssignRolesToUserInput;
};


/** The root type for all write operations. */
export type MutationRemoveRolesFromUserV2Args = {
  args: AssignRolesToUserInputV2;
};


/** The root type for all write operations. */
export type MutationAssignUserToRootOrganizationArgs = {
  args: AssignUserToRootOrganizationInput;
};


/** The root type for all write operations. */
export type MutationCreateUserArgs = {
  args: CreateUserInput;
};


/** The root type for all write operations. */
export type MutationGenerateInvitationArgs = {
  generateInvitationInput: GenerateInvitationInput;
};


/** The root type for all write operations. */
export type MutationRevokeAccessArgs = {
  revokeAccessInput?: InputMaybe<RevokeAccessInput>;
};

/** Conditions are handled as a logical OR sub-expression */
export type MyMatchesBaseFilter = {
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly truckEnteredByRepEmployeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type NestedCarrierSettingInput = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly defaultDestinationDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly defaultDestinationGeographyId?: InputMaybe<Scalars['ID']>;
  readonly defaultDestinationGeographySource?: InputMaybe<Scalars['String']>;
  readonly defaultDestinationGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly defaultDestinationText?: InputMaybe<Scalars['String']>;
  readonly defaultOriginDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly defaultTrailerHeight?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerHeightUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerLength?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerLengthUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly defaultTrailerWidth?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerWidthUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTruckType?: InputMaybe<CarrierTruckTypeEnum>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly intrastate?: InputMaybe<Scalars['Boolean']>;
};

export type NestedCarrierSettingInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly defaultTrailerLength?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerWidth?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerHeight?: InputMaybe<Scalars['Float']>;
  readonly defaultDestinationText?: InputMaybe<Scalars['String']>;
  readonly defaultDestinationGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly defaultDestinationGeographySource?: InputMaybe<Scalars['String']>;
  readonly defaultDestinationGeographyId?: InputMaybe<Scalars['ID']>;
  readonly defaultTrailerLengthUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerWidthUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerHeightUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly defaultOriginDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly defaultDestinationDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly intrastate?: InputMaybe<Scalars['Boolean']>;
  readonly defaultTruckType?: InputMaybe<CarrierTruckTypeEnum>;
  readonly reloadIntentDefaultId?: InputMaybe<Scalars['ID']>;
  readonly destinationFacilityId?: InputMaybe<Scalars['ID']>;
};

export type NetworkChannel = {
  readonly __typename?: 'NetworkChannel';
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly legs: ReadonlyArray<ChannelLeg>;
  readonly network?: Maybe<PlannedNetwork>;
};

export type NewDriverRoutingRequestFilters = {
  readonly driverCode: Scalars['String'];
  readonly startDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly endDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly statusTerm?: InputMaybe<Scalars['String']>;
};

export type NextAvailableInput = {
  readonly dateTime: Scalars['DateTime'];
  readonly location: CityStateInput;
  readonly timezone?: InputMaybe<Scalars['String']>;
};

export type NextAvailableOutput = {
  readonly __typename?: 'NextAvailableOutput';
  readonly dateTime: Scalars['DateTime'];
  readonly location: CityStateOutput;
  readonly timezone?: Maybe<Scalars['String']>;
};

export type NextCarrierInteractionDate = {
  readonly __typename?: 'nextCarrierInteractionDate';
  /** @deprecated Deprecated. Use NextInteractionDateNullable instead */
  readonly nextInteractionDate: Scalars['DateTime'];
  readonly nextInteractionDateNullable?: Maybe<Scalars['DateTime']>;
};

export type NextCustomerInteractionDate = {
  readonly __typename?: 'nextCustomerInteractionDate';
  /** @deprecated Deprecated. Use NextInteractionDateNullable instead */
  readonly nextInteractionDate: Scalars['DateTime'];
  readonly nextInteractionDateNullable?: Maybe<Scalars['DateTime']>;
};

export type NonRouteCostDetail = {
  readonly __typename?: 'NonRouteCostDetail';
  readonly approvalStatus?: Maybe<Scalars['String']>;
  readonly approvedBy?: Maybe<Scalars['ID']>;
  readonly approvedByEmployeeV2?: Maybe<EmployeeV2>;
  /** @deprecated Use approvedOnV2 instead */
  readonly approvedOn?: Maybe<Scalars['DateTime']>;
  readonly approvedOnV2?: Maybe<Scalars['DateTimeOffset']>;
  readonly assignedTo?: Maybe<Scalars['ID']>;
  readonly assignedToEmployeeV2?: Maybe<EmployeeV2>;
  readonly assignedToGroup?: Maybe<Scalars['ID']>;
  readonly chargeTypeId: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly comment?: Maybe<Scalars['String']>;
  readonly createdById?: Maybe<Scalars['ID']>;
  readonly createdByName?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['DateTime'];
  readonly currency: Scalars['String'];
  readonly description: Scalars['String'];
  readonly driver: AssetDriver;
  readonly driverId: Scalars['ID'];
  /** @deprecated Use effectiveDateV2 instead. effectiveDate will be the date according to CST. */
  readonly effectiveDate?: Maybe<Scalars['Date']>;
  readonly effectiveDateV2?: Maybe<Scalars['DateTimeOffset']>;
  readonly enteredTotalCost: Scalars['Decimal'];
  readonly exchangeRate: Scalars['Decimal'];
  readonly exchangeRateDate: Scalars['Date'];
  readonly id: Scalars['ID'];
  readonly isPreTax: Scalars['Boolean'];
  readonly status: Scalars['String'];
  readonly totalCost: Scalars['Decimal'];
  readonly updatedById?: Maybe<Scalars['ID']>;
  readonly updatedByName?: Maybe<Scalars['String']>;
  readonly updatedOn: Scalars['DateTime'];
  readonly voucherNumber?: Maybe<Scalars['String']>;
};

export type NonRouteCostDetailCreateInput = {
  readonly assignedTo?: InputMaybe<Scalars['ID']>;
  readonly assignedToGroup?: InputMaybe<Scalars['ID']>;
  readonly chargeTypeId: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly comment?: InputMaybe<Scalars['String']>;
  readonly currency: Scalars['String'];
  readonly description: Scalars['String'];
  readonly driverId: Scalars['ID'];
  readonly effectiveDate?: InputMaybe<Scalars['Date']>;
  readonly effectiveDateV2?: InputMaybe<Scalars['DateTimeOffset']>;
  readonly enteredTotalCost: Scalars['Decimal'];
  readonly exchangeRate?: InputMaybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: InputMaybe<Scalars['Date']>;
  readonly isPreTax?: InputMaybe<Scalars['Boolean']>;
  readonly totalCost?: InputMaybe<Scalars['Decimal']>;
  readonly approvalStatus?: InputMaybe<Scalars['String']>;
  readonly approvedBy?: InputMaybe<Scalars['ID']>;
  readonly approvedOn?: InputMaybe<Scalars['DateTimeOffset']>;
};

export type NonRouteCostDetailHistory = {
  readonly __typename?: 'NonRouteCostDetailHistory';
  readonly id: Scalars['ID'];
  readonly approvalStatus?: Maybe<Scalars['String']>;
  readonly approvedBy?: Maybe<Scalars['ID']>;
  readonly approvedByEmployeeV2?: Maybe<EmployeeV2>;
  /** @deprecated Use approvedOnV2 instead */
  readonly approvedOn?: Maybe<Scalars['DateTime']>;
  readonly approvedOnV2?: Maybe<Scalars['DateTimeOffset']>;
  readonly assignedTo?: Maybe<Scalars['ID']>;
  readonly assignedToEmployeeV2?: Maybe<EmployeeV2>;
  readonly assignedToGroup?: Maybe<Scalars['ID']>;
  readonly chargeTypeId: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly comment?: Maybe<Scalars['String']>;
  readonly createdByName?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['DateTime'];
  readonly currency: Scalars['String'];
  readonly description: Scalars['String'];
  readonly driverId: Scalars['ID'];
  /** @deprecated Use effectiveDateV2 instead. effectiveDate will be the date according to CST. */
  readonly effectiveDate?: Maybe<Scalars['Date']>;
  readonly effectiveDateV2?: Maybe<Scalars['DateTimeOffset']>;
  readonly enteredTotalCost: Scalars['Decimal'];
  readonly exchangeRate: Scalars['Decimal'];
  readonly exchangeRateDate: Scalars['Date'];
  readonly isPreTax: Scalars['Boolean'];
  readonly status: Scalars['String'];
  readonly totalCost: Scalars['Decimal'];
  readonly updatedByName?: Maybe<Scalars['String']>;
  readonly updatedOn: Scalars['DateTime'];
  readonly voucherNumber?: Maybe<Scalars['String']>;
  readonly changeType?: Maybe<Scalars['String']>;
};

export type NonRouteCostDetailHistoryFilter = {
  readonly driverIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly nonRouteCostDetailId?: InputMaybe<Scalars['ID']>;
  readonly maxRecordCount?: InputMaybe<Scalars['Int']>;
};

export type NonRouteCostDetailUpdateInput = {
  readonly assignedTo?: InputMaybe<Scalars['ID']>;
  readonly assignedToGroup?: InputMaybe<Scalars['ID']>;
  readonly chargeTypeId?: InputMaybe<Scalars['ID']>;
  readonly code: Scalars['String'];
  readonly comment?: InputMaybe<Scalars['String']>;
  readonly currency: Scalars['String'];
  readonly description: Scalars['String'];
  readonly driverId: Scalars['ID'];
  readonly effectiveDate?: InputMaybe<Scalars['Date']>;
  readonly effectiveDateV2?: InputMaybe<Scalars['DateTimeOffset']>;
  readonly enteredTotalCost: Scalars['Decimal'];
  readonly exchangeRate?: InputMaybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: InputMaybe<Scalars['Date']>;
  readonly id: Scalars['ID'];
  readonly isPreTax?: InputMaybe<Scalars['Boolean']>;
  readonly totalCost?: InputMaybe<Scalars['Decimal']>;
  readonly approvalStatus?: InputMaybe<Scalars['String']>;
  readonly approvedBy?: InputMaybe<Scalars['ID']>;
  readonly approvedOn?: InputMaybe<Scalars['DateTimeOffset']>;
};

export type Note = {
  readonly __typename?: 'Note';
  readonly createdAt: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly noteType: NoteTypeEnum;
  readonly noteableId: Scalars['ID'];
  readonly noteableType: Scalars['String'];
  readonly text?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['Iso8601Date'];
};

export type NoteDefault = {
  readonly __typename?: 'NoteDefault';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['String'];
  readonly customerLoadNote?: Maybe<Scalars['String']>;
  readonly rateConfirmNote?: Maybe<Scalars['String']>;
  readonly invoiceNote?: Maybe<Scalars['String']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly noteType?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly noteDefaultDivision?: Maybe<ReadonlyArray<NoteDefaultDivision>>;
  readonly noteDefaultBusinessUnit?: Maybe<ReadonlyArray<NoteDefaultBusinessUnit>>;
};

export type NoteDefaultBusinessUnit = {
  readonly __typename?: 'NoteDefaultBusinessUnit';
  readonly id: Scalars['ID'];
  readonly noteDefaultId: Scalars['String'];
  readonly businessUnit: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly noteDefault?: Maybe<NoteDefault>;
};

export type NoteDefaultDivision = {
  readonly __typename?: 'NoteDefaultDivision';
  readonly id: Scalars['ID'];
  readonly noteDefaultId: Scalars['String'];
  readonly division: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly noteDefault?: Maybe<NoteDefault>;
};

export type NoteInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly noteType?: InputMaybe<NoteTypeEnum>;
  readonly noteableId?: InputMaybe<Scalars['ID']>;
  readonly text?: InputMaybe<Scalars['String']>;
};

export type NoteInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly noteableId?: InputMaybe<Scalars['ID']>;
  readonly noteType?: InputMaybe<NoteTypeEnumV2>;
  readonly text?: InputMaybe<Scalars['String']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
};

export enum NoteTypeEnum {
  /** Carrier Accounting */
  CarrierAccounting = 'carrierAccounting',
  /** Carrier Confirmation */
  CarrierConfirmation = 'carrierConfirmation',
  /** Carrier Customer Notes */
  CarrierCustomer = 'carrierCustomer',
  /** Customer Accounting */
  CustomerAccounting = 'customerAccounting',
  /** External Facility */
  ExternalFacility = 'externalFacility'
}

export enum NoteTypeEnumV2 {
  /** External Facility */
  ExternalFacility = 'externalFacility',
  /** Carrier Customer Notes */
  CarrierCustomer = 'carrierCustomer',
  /** Carrier Accounting */
  CarrierAccounting = 'carrierAccounting',
  /** Customer Accounting */
  CustomerAccounting = 'customerAccounting',
  /** Carrier Confirmation */
  CarrierConfirmation = 'carrierConfirmation'
}

/** Notifications */
export enum NotificationChannels {
  Email = 'EMAIL',
  Slack = 'SLACK',
  Msteams = 'MSTEAMS'
}

export type Offer = {
  readonly __typename?: 'Offer';
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  readonly createdByV2: UserV2;
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByV2: UserV2;
  readonly code?: Maybe<Scalars['String']>;
  readonly status: OfferStatus;
  readonly source: OfferSource;
  readonly type: OfferType;
  readonly reason?: Maybe<OfferReason>;
  readonly offerReason?: Maybe<Scalars['String']>;
  readonly routeId: Scalars['ID'];
  readonly routeV2: LoadRouteV2;
  readonly vendorV2?: Maybe<CarrierV2>;
  readonly trailerTypeId?: Maybe<Scalars['ID']>;
  readonly trailerLength?: Maybe<Length>;
  readonly trailerWidth?: Maybe<Length>;
  readonly trailerHeight?: Maybe<Length>;
  /** @deprecated Use `trailerLength`, `trailerWidth`, and `trailerHeight`. */
  readonly trailerDimensions?: Maybe<Dimensions>;
  readonly trailerOwnerV2?: Maybe<CarrierV2>;
  /** @deprecated Use originDeadheadV2. */
  readonly originDeadhead: Length;
  readonly originDeadheadV2?: Maybe<Length>;
  /** @deprecated Use priceV2. */
  readonly price: Currency;
  readonly priceV2?: Maybe<Currency>;
  readonly offerPrice?: Maybe<CurrencyV2>;
  readonly askPrice?: Maybe<Currency>;
  readonly askPriceV2?: Maybe<CurrencyV2>;
  readonly note?: Maybe<Scalars['String']>;
  readonly emptyDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use emptyLocationV2 */
  readonly emptyLocation?: Maybe<Address>;
  readonly emptyLocationData?: Maybe<EmptyLocation>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly timezone?: Maybe<Scalars['String']>;
  /** @deprecated Use emptyLocationGeographyService for geographyV2 */
  readonly emptyLocationV2?: Maybe<Geography>;
  readonly emptyLocationGeographyService?: Maybe<GeographyServiceRecord>;
  readonly response?: Maybe<OfferResponse>;
  readonly errors?: Maybe<VendorValidation>;
  readonly deletedAt?: Maybe<Scalars['DateTime']>;
  readonly offersRoute?: Maybe<OffersRoute>;
  readonly offersCarrier?: Maybe<OffersCarrier>;
  readonly driverOneId?: Maybe<Scalars['ID']>;
  readonly offersDriver?: Maybe<OffersDriver>;
  readonly trailerOwnerName?: Maybe<Scalars['String']>;
  readonly lock?: Maybe<Lock>;
  readonly trailerOne?: Maybe<Scalars['String']>;
  readonly trailerTwo?: Maybe<Scalars['String']>;
  readonly isAssetType?: Maybe<Scalars['Boolean']>;
  readonly vendorValidationErrors?: Maybe<ReadonlyArray<Scalars['String']>>;
};


export type OfferTrailerLengthArgs = {
  unit: UnitOfLengthEnum;
};


export type OfferTrailerWidthArgs = {
  unit: UnitOfLengthEnum;
};


export type OfferTrailerHeightArgs = {
  unit: UnitOfLengthEnum;
};


export type OfferTrailerDimensionsArgs = {
  unit: UnitOfLengthEnum;
};


export type OfferOriginDeadheadArgs = {
  unit: UnitOfLengthEnum;
};


export type OfferOriginDeadheadV2Args = {
  unit: UnitOfLengthEnum;
};


export type OfferPriceArgs = {
  unit?: InputMaybe<UnitOfCurrencyEnum>;
};


export type OfferPriceV2Args = {
  unit?: InputMaybe<UnitOfCurrencyEnum>;
};


export type OfferOfferPriceArgs = {
  unit?: InputMaybe<UnitOfCurrencyEnumV2>;
};


export type OfferAskPriceArgs = {
  unit?: InputMaybe<UnitOfCurrencyEnum>;
};


export type OfferAskPriceV2Args = {
  unit?: InputMaybe<UnitOfCurrencyEnumV2>;
};

export type OfferConnection = {
  readonly __typename?: 'OfferConnection';
  readonly edges: ReadonlyArray<OfferEdges>;
  readonly pageInfo: PageInfo;
};

export type OfferEdges = {
  readonly __typename?: 'OfferEdges';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<Offer>;
};

export enum OfferOperationSource {
  LoadRoute = 'LoadRoute',
  AvailableRoutes = 'AvailableRoutes'
}

export type OfferPrice = {
  readonly __typename?: 'OfferPrice';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export enum OfferReason {
  OverMaxCost = 'OVER_MAX_COST',
  SchedulingConflict = 'SCHEDULING_CONFLICT',
  EquipmentType = 'EQUIPMENT_TYPE',
  WeightIssue = 'WEIGHT_ISSUE',
  Other = 'OTHER'
}

export type OfferResponse = {
  readonly __typename?: 'OfferResponse';
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  readonly createdByV2: UserV2;
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByV2: UserV2;
  readonly type: Scalars['String'];
  /** @deprecated Use `counterPriceV2`. */
  readonly counterPrice?: Maybe<Currency>;
  readonly counterPriceV2?: Maybe<CurrencyV2>;
  readonly note?: Maybe<Scalars['String']>;
  readonly offer: Offer;
};


export type OfferResponseCounterPriceArgs = {
  unit?: InputMaybe<UnitOfCurrencyEnum>;
};


export type OfferResponseCounterPriceV2Args = {
  unit?: InputMaybe<UnitOfCurrencyEnumV2>;
};

export enum OfferResponseType {
  Decline = 'DECLINE',
  Counter = 'COUNTER',
  Accept = 'ACCEPT'
}

export type OffersCarrier = {
  readonly __typename?: 'OffersCarrier';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly mainRepName?: Maybe<Scalars['String']>;
};

export type OffersDriver = {
  readonly __typename?: 'OffersDriver';
  readonly driverOneId: Scalars['ID'];
  readonly driverOneName?: Maybe<Scalars['String']>;
  readonly driverTwoId?: Maybe<Scalars['ID']>;
  readonly driverTwoName?: Maybe<Scalars['String']>;
  readonly fleetId: Scalars['ID'];
  readonly fleetManagerName?: Maybe<Scalars['String']>;
  readonly nextOffDateTime?: Maybe<Scalars['String']>;
  readonly nextOffLocation?: Maybe<Scalars['Json']>;
  readonly accessories?: Maybe<Scalars['String']>;
  readonly outboundRegion?: Maybe<Scalars['String']>;
};

export type OffersDriverInput = {
  readonly driverOneId: Scalars['String'];
  readonly driverTwoId?: InputMaybe<Scalars['String']>;
  readonly driverOneName?: InputMaybe<Scalars['String']>;
  readonly driverTwoName?: InputMaybe<Scalars['String']>;
  readonly fleetId: Scalars['String'];
  readonly fleetManagerName?: InputMaybe<Scalars['String']>;
  readonly nextOffDateTime?: InputMaybe<Scalars['String']>;
  readonly nextOffLocation?: InputMaybe<Scalars['Json']>;
  readonly trailerOwnerName?: InputMaybe<Scalars['String']>;
  readonly accessories?: InputMaybe<Scalars['String']>;
  readonly outboundRegion?: InputMaybe<Scalars['String']>;
};

export enum OfferSource {
  Rep = 'REP',
  Mobile = 'MOBILE',
  Web = 'WEB',
  Api = 'API'
}

export type OffersPaginatedQueryFilters = {
  readonly createdByUserIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly types?: InputMaybe<ReadonlyArray<OfferType>>;
  readonly statuses?: InputMaybe<ReadonlyArray<OfferStatus>>;
  readonly vendorIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly activationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly startDate?: InputMaybe<Scalars['String']>;
  readonly endDate?: InputMaybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly inboundFreightAssignmentSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly inboundFreightAssignmentSuperRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundFreightAssignmentRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundFreightAssignmentSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundFreightAssignmentSuperRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly lifeCycleStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly customerGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderReps?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly sources?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly limit?: InputMaybe<Scalars['Float']>;
  readonly beforeCursor?: InputMaybe<Scalars['String']>;
  readonly afterCursor?: InputMaybe<Scalars['String']>;
};

export type OffersRoute = {
  readonly __typename?: 'OffersRoute';
  readonly routeId: Scalars['ID'];
  readonly hotRoute?: Maybe<Scalars['Boolean']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Float']>;
  readonly totalRoutes?: Maybe<Scalars['Float']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly destination?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly totalDistance?: Maybe<Scalars['Json']>;
  readonly pickupScheduleType?: Maybe<ScheduleAppointmentTypeEnum>;
  readonly deliveryScheduleType?: Maybe<ScheduleAppointmentTypeEnum>;
  readonly pickupAvailableStart?: Maybe<Scalars['DateTime']>;
  readonly pickupAvailableEnd?: Maybe<Scalars['DateTime']>;
  readonly deliveryAvailableStart?: Maybe<Scalars['DateTime']>;
  readonly deliveryAvailableEnd?: Maybe<Scalars['DateTime']>;
  readonly pickupComputedAppointment?: Maybe<Scalars['Json']>;
  readonly deliveryComputedAppointment?: Maybe<Scalars['Json']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly inboundRegion?: Maybe<Scalars['String']>;
  readonly inboundSubRegion?: Maybe<Scalars['String']>;
  readonly inboundSuperRegion?: Maybe<Scalars['String']>;
  readonly outboundRegion?: Maybe<Scalars['String']>;
  readonly outboundSubRegion?: Maybe<Scalars['String']>;
  readonly outboundSuperRegion?: Maybe<Scalars['String']>;
  readonly lifeCycleStatus?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly customerGroup?: Maybe<Scalars['String']>;
  readonly orderReps?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['ID']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly routeRequirements?: Maybe<Scalars['String']>;
  readonly stopsRequirements?: Maybe<Scalars['String']>;
};

export enum OfferStatus {
  Pending = 'PENDING',
  Countered = 'COUNTERED',
  Declined = 'DECLINED',
  Accepted = 'ACCEPTED',
  Inactive = 'INACTIVE'
}

export enum OfferType {
  InactiveOffer = 'INACTIVE_OFFER',
  NoCapacity = 'NO_CAPACITY',
  NoAnswer = 'NO_ANSWER',
  ActiveOffer = 'ACTIVE_OFFER'
}

export type OperatorFilterWithEqLike = {
  readonly operator: OperatorTypeEqLikeEnum;
  readonly value1: Scalars['String'];
};

export type OperatorFilterWithEqNotEq = {
  readonly operator: OperatorTypeEqNotEqEnum;
  readonly value1: Scalars['String'];
};

export type OperatorFilterWithEqNu = {
  readonly operator: OperatorTypeEqNuEnum;
  readonly value1: Scalars['String'];
};

export type OperatorFilterWithNullNotNull = {
  readonly operator: OperatorTypeNullNotNullEnum;
};

export enum OperatorType {
  /** == */
  Equals = 'EQUALS',
  /** != */
  DoesNotEqual = 'DOES_NOT_EQUAL',
  /** < */
  LessThan = 'LESS_THAN',
  /** <= */
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  /** > */
  GreaterThan = 'GREATER_THAN',
  /** >= */
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  /** gtDaysAgo */
  GreaterThanDaysAgo = 'GREATER_THAN_DAYS_AGO',
  /** gteDaysAgo */
  GreaterThanOrEqualToDaysAgo = 'GREATER_THAN_OR_EQUAL_TO_DAYS_AGO',
  /** ltDaysAgo */
  LessThanDaysAgo = 'LESS_THAN_DAYS_AGO',
  /** lteDaysAgo */
  LessThanOrEqualToDaysAgo = 'LESS_THAN_OR_EQUAL_TO_DAYS_AGO',
  /** in */
  In = 'IN',
  /** notIn */
  NotIn = 'NOT_IN',
  /** isNull */
  IsNull = 'IS_NULL',
  /** notNull */
  NotNull = 'NOT_NULL',
  /** keyOf */
  KeyOf = 'KEY_OF',
  /** valueOf */
  ValueOf = 'VALUE_OF',
  /** intersects */
  Intersects = 'INTERSECTS',
  /** different */
  Different = 'DIFFERENT',
  /** notDifferent */
  NotDifferent = 'NOT_DIFFERENT'
}

export enum OperatorTypeDateEnum {
  Equals = 'EQUALS',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  LessOrEqual = 'LESS_OR_EQUAL'
}

export enum OperatorTypeDateEnumAndNull {
  Equals = 'EQUALS',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  LessOrEqual = 'LESS_OR_EQUAL',
  Null = 'NULL'
}

export enum OperatorTypeEqLikeEnum {
  Equals = 'EQUALS',
  Like = 'LIKE'
}

export enum OperatorTypeEqNotEqEnum {
  Equals = 'EQUALS',
  NotEqual = 'NOT_EQUAL',
  Null = 'NULL'
}

export enum OperatorTypeEqNuEnum {
  Equals = 'EQUALS',
  Null = 'NULL'
}

export enum OperatorTypeNullNotNullEnum {
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL'
}

export type OperatorWithDatesFilter = {
  readonly operator: OperatorTypeDateEnum;
  readonly value1: Scalars['DateTime'];
};

export type OperatorWithDatesFilterAndNull = {
  readonly operator: OperatorTypeDateEnumAndNull;
  readonly value: Scalars['DateTime'];
};

export enum OpportunityTypeEnum {
  /** none_defined */
  NoneDefined = 'none_defined',
  /** Bronze */
  Bronze = 'Bronze',
  /** Silver */
  Silver = 'Silver',
  /** Gold */
  Gold = 'Gold',
  /** Platinum */
  Platinum = 'Platinum'
}

export type OptimalMatch = {
  readonly __typename?: 'OptimalMatch';
  readonly id: Scalars['String'];
  readonly route: OptiMatchRoute;
  readonly driver: OptiMatchDriver;
  readonly originDeadheadInMiles: Scalars['Int'];
  readonly destinationDeadheadInMiles?: Maybe<Scalars['Int']>;
  readonly assignmentActionTaken?: Maybe<Scalars['String']>;
  readonly matchedDriverPreferences?: Maybe<ReadonlyArray<Maybe<OptimalMatchesMatchedDriverPreferences>>>;
  readonly driverWaitingHours: Scalars['Float'];
  readonly loadedDistanceInMiles?: Maybe<Scalars['Int']>;
  readonly revenue: Currency;
};

export type OptimalMatchesMatchedDriverPreferences = {
  readonly __typename?: 'OptimalMatchesMatchedDriverPreferences';
  readonly id: Scalars['String'];
  readonly driverName?: Maybe<Scalars['String']>;
  readonly reason?: Maybe<Scalars['String']>;
  readonly preferenceType?: Maybe<Scalars['String']>;
  readonly preference?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
};

export type OptimalSolutionKpIs = {
  readonly __typename?: 'OptimalSolutionKPIs';
  readonly averageODH?: Maybe<Scalars['Float']>;
  readonly averageDDH?: Maybe<Scalars['Float']>;
  readonly averageDriverWaitingHours?: Maybe<Scalars['Float']>;
  readonly totalRevenue?: Maybe<Scalars['Float']>;
  readonly percentLoadedMiles?: Maybe<Scalars['Float']>;
};

export type OptimatchAssetDriver = {
  readonly __typename?: 'OptimatchAssetDriver';
  readonly id: Scalars['String'];
  readonly name?: Maybe<Scalars['String']>;
  readonly facilityPreferences?: Maybe<ReadonlyArray<Maybe<OptimatchDriverFacilityPreference>>>;
  readonly customerPreferences?: Maybe<ReadonlyArray<Maybe<OptimatchDriverCustomerPreference>>>;
  readonly geographyPreferences?: Maybe<ReadonlyArray<Maybe<OptimatchDriverGeographyPreference>>>;
  readonly lanePreferences?: Maybe<ReadonlyArray<Maybe<OptimatchDriverLanePreference>>>;
};

export type OptiMatchAssignment = {
  readonly __typename?: 'OptiMatchAssignment';
  readonly routeId: Scalars['String'];
  readonly capacityId: Scalars['String'];
  readonly rejectionReason?: Maybe<OptiMatchRejectionReason>;
  readonly assignmentType?: Maybe<Scalars['String']>;
  readonly swappedWith?: Maybe<Scalars['String']>;
  /** @deprecated use assignmentType */
  readonly isBooked?: Maybe<Scalars['Boolean']>;
};

export type OptiMatchAssignmentInput = {
  readonly routeId: Scalars['String'];
  readonly capacityId: Scalars['String'];
  readonly rejectionReason?: InputMaybe<OptiMatchRejectionReason>;
  readonly assignmentType?: InputMaybe<Scalars['String']>;
  readonly swappedWith?: InputMaybe<Scalars['String']>;
  readonly isBooked?: InputMaybe<Scalars['Boolean']>;
};

export type OptiMatchCapacityFilter = {
  readonly __typename?: 'OptiMatchCapacityFilter';
  readonly utcInclusiveDateRange?: Maybe<OptiMatchDateRange>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly originRadius?: Maybe<OptiMatchWithinRadius>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly destinationRadius?: Maybe<OptiMatchWithinRadius>;
  readonly sourceTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly equipmentTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierServices?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly mainCarrierRepEmployeeIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly enteredByRepEmployeeIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierTypeIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundRegionCompositeKeys?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type OptiMatchCapacityFilterInput = {
  readonly utcInclusiveDateRange?: InputMaybe<OptiMatchDateRangeInput>;
  readonly originCity?: InputMaybe<Scalars['String']>;
  readonly originState?: InputMaybe<Scalars['String']>;
  readonly originRadius?: InputMaybe<WithinRadiusInput>;
  readonly destinationCity?: InputMaybe<Scalars['String']>;
  readonly destinationState?: InputMaybe<Scalars['String']>;
  readonly destinationRadius?: InputMaybe<WithinRadiusInput>;
  readonly sourceTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equipmentTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierServices?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainCarrierRepEmployeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly enteredByRepEmployeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundRegionCompositeKeys?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type OptiMatchCustomObjective = {
  readonly __typename?: 'OptiMatchCustomObjective';
  readonly objectiveId: Scalars['ID'];
  readonly objectiveName: Scalars['String'];
  readonly originDeadheadWeight?: Maybe<Scalars['Int']>;
  readonly revenueWeight?: Maybe<Scalars['Int']>;
  readonly waitingTimeWeight?: Maybe<Scalars['Int']>;
  readonly destinationDeadheadWeight?: Maybe<Scalars['Int']>;
  readonly profitWeight?: Maybe<Scalars['Int']>;
  readonly costWeight?: Maybe<Scalars['Int']>;
  readonly truckSpeed?: Maybe<Scalars['Int']>;
  readonly maxRoutesPerDriver?: Maybe<Scalars['Int']>;
  readonly maxDeadheadBetweenRoutes?: Maybe<Scalars['Int']>;
  readonly daysLeftHomeboundThreshold?: Maybe<Scalars['Int']>;
  readonly maximizeRoutesCovered?: Maybe<Scalars['Int']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly objectiveHistory?: Maybe<ReadonlyArray<Maybe<CustomObjectiveHistory>>>;
  readonly isDefault?: Maybe<Scalars['Boolean']>;
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
};

export type OptiMatchCustomObjectiveInput = {
  readonly objectiveId?: InputMaybe<Scalars['ID']>;
  readonly objectiveName: Scalars['String'];
  readonly originDeadheadWeight?: InputMaybe<Scalars['Int']>;
  readonly revenueWeight?: InputMaybe<Scalars['Int']>;
  readonly waitingTimeWeight?: InputMaybe<Scalars['Int']>;
  readonly destinationDeadheadWeight?: InputMaybe<Scalars['Int']>;
  readonly profitWeight?: InputMaybe<Scalars['Int']>;
  readonly costWeight?: InputMaybe<Scalars['Int']>;
  readonly truckSpeed?: InputMaybe<Scalars['Int']>;
  readonly maxRoutesPerDriver?: InputMaybe<Scalars['Int']>;
  readonly maxDeadheadBetweenRoutes?: InputMaybe<Scalars['Int']>;
  readonly daysLeftHomeboundThreshold?: InputMaybe<Scalars['Int']>;
  readonly maximizeRoutesCovered?: InputMaybe<Scalars['Int']>;
  readonly isDefault?: InputMaybe<Scalars['Boolean']>;
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type OptiMatchDateRange = {
  readonly __typename?: 'OptiMatchDateRange';
  readonly first?: Maybe<Scalars['DateTime']>;
  readonly last?: Maybe<Scalars['DateTime']>;
};

export type OptiMatchDateRangeInput = {
  readonly first?: InputMaybe<Scalars['DateTime']>;
  readonly last?: InputMaybe<Scalars['DateTime']>;
};

export type OptiMatchDriver = {
  readonly __typename?: 'OptiMatchDriver';
  readonly capacityId: Scalars['String'];
  readonly readyByDate: Scalars['DateTime'];
  readonly loadByDate: Scalars['DateTime'];
  readonly originGeoPoint: Point;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly destinationGeoPoint?: Maybe<Point>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly isHazmat?: Maybe<Scalars['Boolean']>;
  readonly isTeam?: Maybe<Scalars['Boolean']>;
  readonly outboundRegion?: Maybe<Scalars['String']>;
  readonly assetDriver?: Maybe<OptimatchAssetDriver>;
  readonly assetDriver2?: Maybe<OptimatchAssetDriver>;
  readonly availableTruck?: Maybe<AvailableTruck>;
};

export type OptimatchDriverCustomerPreference = {
  readonly __typename?: 'OptimatchDriverCustomerPreference';
  readonly id: Scalars['String'];
  readonly preferenceTerm?: Maybe<Scalars['String']>;
  readonly reason?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
};

export type OptimatchDriverFacilityPreference = {
  readonly __typename?: 'OptimatchDriverFacilityPreference';
  readonly id: Scalars['String'];
  readonly preference?: Maybe<Scalars['String']>;
  readonly reason?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly facilityId?: Maybe<Scalars['String']>;
};

export type OptimatchDriverGeographyPreference = {
  readonly __typename?: 'OptimatchDriverGeographyPreference';
  readonly id: Scalars['String'];
  readonly preference?: Maybe<Scalars['String']>;
  readonly reason?: Maybe<Scalars['String']>;
  readonly direction?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
};

export type OptimatchDriverLanePreference = {
  readonly __typename?: 'OptimatchDriverLanePreference';
  readonly id: Scalars['String'];
  readonly preference?: Maybe<Scalars['String']>;
  readonly reason?: Maybe<Scalars['String']>;
  readonly originCityState?: Maybe<Scalars['String']>;
  readonly destinationCityState?: Maybe<Scalars['String']>;
};

export type OptiMatchFeasiblePairs = {
  readonly __typename?: 'OptiMatchFeasiblePairs';
  readonly id: Scalars['String'];
  readonly matches?: Maybe<ReadonlyArray<OptimalMatch>>;
};

export type OptiMatchGeoPoint = {
  readonly __typename?: 'OptiMatchGeoPoint';
  readonly lat: Scalars['Float'];
  readonly lon: Scalars['Float'];
};

export enum OptiMatchOptimization {
  MinimizeDeadhead = 'MinimizeDeadhead',
  MinimizeWaitingTimeAtPickup = 'MinimizeWaitingTimeAtPickup',
  /** @deprecated No longer supported by spec */
  ReturnDriverToDomicile = 'ReturnDriverToDomicile'
}

export enum OptiMatchRejectionReason {
  InsufficientHos = 'InsufficientHos',
  DriverPreferenceConflict = 'DriverPreferenceConflict',
  TooMuchOdh = 'TooMuchOdh',
  TooMuchDdh = 'TooMuchDdh',
  ExpectedWaitTimeTooLong = 'ExpectedWaitTimeTooLong',
  PriorityConflict = 'PriorityConflict',
  DriverScheduleConflict = 'DriverScheduleConflict',
  Other = 'Other',
  /** @deprecated alternative matches can no longer be booked */
  AlternateMatchBooked = 'AlternateMatchBooked'
}

export type OptiMatchRoute = {
  readonly __typename?: 'OptiMatchRoute';
  readonly id: Scalars['String'];
  readonly firstStop: OptiMatchStopInfo;
  readonly lastStop: OptiMatchStopInfo;
  readonly requirements?: Maybe<ReadonlyArray<OrderRequirementInfo>>;
  readonly availableRoute?: Maybe<AvailableRoute>;
};

export type OptiMatchRouteFilter = {
  readonly __typename?: 'OptiMatchRouteFilter';
  readonly capacityNeed?: Maybe<ReadonlyArray<CapacityNeed>>;
  readonly transportModeIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly routeActivationStatuses?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly originRadius?: Maybe<OptiMatchWithinRadius>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly destinationRadius?: Maybe<OptiMatchWithinRadius>;
  readonly customerIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly customerRepIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedRepIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly utcInclusivePickupDateRange?: Maybe<OptiMatchDateRange>;
  readonly utcPickupStartDateTime?: Maybe<Scalars['DateTime']>;
  readonly utcPickupEndDateTime?: Maybe<Scalars['DateTime']>;
  readonly shipmentSizeIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly requiredTruckTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly originFacilityIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationFacilityIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly firstStopRegionCompositeKeys?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly lastStopRegionCompositeKeys?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly divisionsIncludeNull?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly quoteTypeIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTypeIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly organizationHierarchyCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly activeRouteVendorCarrierIds?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type OptiMatchRouteFilterInput = {
  readonly capacityNeed?: InputMaybe<ReadonlyArray<CapacityNeed>>;
  readonly transportModeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeActivationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: InputMaybe<Scalars['String']>;
  readonly originState?: InputMaybe<Scalars['String']>;
  readonly originRadius?: InputMaybe<WithinRadiusInput>;
  readonly destinationCity?: InputMaybe<Scalars['String']>;
  readonly destinationState?: InputMaybe<Scalars['String']>;
  readonly destinationRadius?: InputMaybe<WithinRadiusInput>;
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerRepIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedRepIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly utcInclusivePickupDateRange?: InputMaybe<OptiMatchDateRangeInput>;
  readonly utcPickupStartDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly utcPickupEndDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly shipmentSizeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly requiredTruckTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly firstStopRegionCompositeKeys?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly lastStopRegionCompositeKeys?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly divisionsIncludeNull?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly quoteTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly organizationHierarchyCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly activeRouteVendorCarrierIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type OptiMatchSession = {
  readonly __typename?: 'OptiMatchSession';
  readonly sessionId?: Maybe<Scalars['ID']>;
  readonly solutionId?: Maybe<Scalars['Int']>;
  readonly optimization?: Maybe<OptiMatchOptimization>;
  readonly respectDriverPreferences?: Maybe<Scalars['Boolean']>;
  /** Snapshot of sidebar Route Criteria chip filters for restoring chip values */
  readonly routeChipConfigJson?: Maybe<Scalars['String']>;
  /** Snapshot of sidebar Capacity Criteria chip filters for restoring chip values */
  readonly capacityChipConfigJson?: Maybe<Scalars['String']>;
  readonly routeCriteria?: Maybe<OptiMatchRouteFilter>;
  readonly capacityCriteria?: Maybe<OptiMatchCapacityFilter>;
  readonly totalCountForRouteCriteria?: Maybe<Scalars['Int']>;
  readonly routesConsidered?: Maybe<ReadonlyArray<OptiMatchRoute>>;
  readonly totalCountForDriverCriteria?: Maybe<Scalars['Int']>;
  readonly driversConsidered?: Maybe<ReadonlyArray<OptiMatchDriver>>;
  readonly plannedMatches?: Maybe<ReadonlyArray<OptiMatchAssignment>>;
  readonly rejectedMatches?: Maybe<ReadonlyArray<OptiMatchAssignment>>;
  readonly optimalMatches?: Maybe<ReadonlyArray<OptimalMatch>>;
  readonly optimalSolutionKPIs?: Maybe<OptimalSolutionKpIs>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OptiMatchSessionArgs = {
  readonly sessionId?: InputMaybe<Scalars['ID']>;
  readonly optimization?: InputMaybe<OptiMatchOptimization>;
  readonly respectDriverPreferences?: InputMaybe<Scalars['Boolean']>;
  /** Snapshot of sidebar Route Criteria chip filters for restoring chip values */
  readonly routeChipConfigJson?: InputMaybe<Scalars['String']>;
  /** Snapshot of sidebar Capacity Criteria chip filters for restoring chip values */
  readonly capacityChipConfigJson?: InputMaybe<Scalars['String']>;
  readonly routeCriteria?: InputMaybe<OptiMatchRouteFilterInput>;
  readonly capacityCriteria?: InputMaybe<OptiMatchCapacityFilterInput>;
  readonly forceRefreshDrivers?: InputMaybe<Scalars['Boolean']>;
  readonly forceRefreshRoutes?: InputMaybe<Scalars['Boolean']>;
};

export type OptiMatchSolution = {
  readonly __typename?: 'OptiMatchSolution';
  readonly sessionId: Scalars['ID'];
  readonly optiMatchSession: OptiMatchSession;
  readonly optimalMatches?: Maybe<ReadonlyArray<OptimalMatch>>;
};

export type OptiMatchStopInfo = {
  readonly __typename?: 'OptiMatchStopInfo';
  readonly id: Scalars['String'];
  readonly point: Point;
  readonly startDate: Scalars['DateTime'];
  readonly endDate: Scalars['DateTime'];
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly freightAssignmentRegion?: Maybe<Scalars['String']>;
};

export type OptiMatchWithinRadius = {
  readonly __typename?: 'OptiMatchWithinRadius';
  readonly withinRadius?: Maybe<Length>;
  readonly radiusGeoPoint?: Maybe<OptiMatchGeoPoint>;
};

export type OptionInputType = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type OptionOutputType = {
  readonly __typename?: 'OptionOutputType';
  readonly id?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
};

/** Represents an order that contains un-invoiced and invoiced items. */
export type Order = {
  readonly __typename?: 'Order';
  readonly id: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly totalAmount?: Maybe<Scalars['Decimal']>;
  readonly invoicedAmount?: Maybe<Scalars['Decimal']>;
  readonly uninvoicedAmount?: Maybe<Scalars['Decimal']>;
  readonly currencyTotalAmount?: Maybe<Scalars['Decimal']>;
  readonly currencyInvoicedAmount?: Maybe<Scalars['Decimal']>;
  readonly currencyUninvoicedAmount?: Maybe<Scalars['Decimal']>;
  readonly orderLifeCycle?: Maybe<OrderLifeCycleStatusEnum>;
  readonly orderFinancialStatus?: Maybe<OrderFinancialStatusEnum>;
  readonly orderFinancialStatusReasons?: Maybe<ReadonlyArray<Maybe<AwaitingRequirementsReason>>>;
  /**
   * List of all RateDetails having statuses
   * 'Saved', 'Pending', 'Processing', 'Processed', 'Error', 'CreditMemoing', 'CreditMemoed'.
   */
  readonly allRateDetails: ReadonlyArray<RateDetail>;
  /** List of all RateDetails having only 'Saved' status. */
  readonly rateDetails: ReadonlyArray<RateDetail>;
  readonly paperWorkStatus?: Maybe<Scalars['String']>;
  readonly hasSeerSearchLimitReached?: Maybe<Scalars['Boolean']>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly load?: Maybe<Load>;
  readonly loadV2?: Maybe<LoadV2>;
  readonly totalCost?: Maybe<Scalars['Decimal']>;
  /** @deprecated Use RouteVendorStatuses instead */
  readonly vendorRouteFinancialStatus?: Maybe<VendorFinancialStatusEnum>;
  readonly routeVendorStatuses?: Maybe<ReadonlyArray<RouteVendorStatus>>;
  readonly isInvoiceRequirementBypassed?: Maybe<Scalars['Boolean']>;
  readonly taxExempt?: Maybe<Scalars['Boolean']>;
  readonly fuelAmount?: Maybe<Scalars['Decimal']>;
  readonly finalizedFuelStatus?: Maybe<Scalars['String']>;
  readonly fuelIncluded?: Maybe<Scalars['Boolean']>;
  readonly baseChargeAmount?: Maybe<Scalars['Decimal']>;
  readonly accessorialChargeAmount?: Maybe<Scalars['Decimal']>;
  readonly otherChargeAmount?: Maybe<Scalars['Decimal']>;
  readonly businessUnit?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  readonly billToCodeId?: Maybe<Scalars['ID']>;
  readonly billToCode?: Maybe<BillToCode>;
  readonly disableFinancials: Scalars['Boolean'];
  readonly hasHoldEvent?: Maybe<Scalars['Boolean']>;
  readonly holdCloseDate?: Maybe<Scalars['DateTime']>;
  readonly holdEventReason?: Maybe<Scalars['String']>;
  readonly holdEventDays?: Maybe<Scalars['Int']>;
  readonly isApprovedForInvoicing?: Maybe<Scalars['Boolean']>;
  readonly holdApprovedBy?: Maybe<Scalars['String']>;
  readonly holdApprovedOn?: Maybe<Scalars['DateTime']>;
  readonly canDisableFinancials?: Maybe<Scalars['Boolean']>;
  readonly accountingIncidentsV2: ReadonlyArray<IncidentV2>;
};

export type OrderAllocatedMargin = {
  readonly __typename?: 'OrderAllocatedMargin';
  readonly orderId: Scalars['ID'];
  readonly allocatedMargin?: Maybe<Scalars['Decimal']>;
  readonly grossAllocatedMargin?: Maybe<Scalars['Decimal']>;
  readonly customerOverheadAmount?: Maybe<Scalars['Decimal']>;
  readonly allocatedCompanyOverheadAmount?: Maybe<Scalars['Decimal']>;
  readonly orderRateAllocationPercent?: Maybe<Scalars['Decimal']>;
  readonly orderCostAllocationPercent?: Maybe<Scalars['Decimal']>;
  readonly grossOrderAllocatedRate?: Maybe<Scalars['Decimal']>;
  readonly grossOrderAllocatedCost?: Maybe<Scalars['Decimal']>;
  readonly distance?: Maybe<Scalars['Decimal']>;
  readonly distancePercent?: Maybe<Scalars['Decimal']>;
  readonly length?: Maybe<Scalars['Decimal']>;
  readonly lengthPercent?: Maybe<Scalars['Decimal']>;
  readonly weight?: Maybe<Scalars['Decimal']>;
  readonly weightPercent?: Maybe<Scalars['Decimal']>;
  readonly positions?: Maybe<Scalars['Decimal']>;
  readonly positionsPercent?: Maybe<Scalars['Decimal']>;
  readonly orderRateTotal?: Maybe<Scalars['Decimal']>;
  readonly orderCommissions?: Maybe<ReadonlyArray<Maybe<OrderCommission>>>;
  /** @deprecated Use the Order object */
  readonly orderCode: Scalars['String'];
  readonly customerId?: Maybe<Scalars['ID']>;
  /** @deprecated Use the Customer object */
  readonly customerName?: Maybe<Scalars['String']>;
  readonly grossAllocatedPreSplitCost?: Maybe<Scalars['Decimal']>;
  readonly totalGrossMargin?: Maybe<Scalars['Decimal']>;
  readonly totalNetMargin?: Maybe<Scalars['Decimal']>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly order?: Maybe<CustomerOrder>;
};

export type OrderBraceTypes = {
  readonly __typename?: 'OrderBraceTypes';
  readonly option: KeyValue;
};

export type OrderCommission = {
  readonly __typename?: 'OrderCommission';
  readonly commissionAmount?: Maybe<Scalars['Decimal']>;
  readonly employeeId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly customerRepTypeId?: Maybe<Scalars['String']>;
  readonly commissionPercent?: Maybe<Scalars['Decimal']>;
  readonly commissionBasisId?: Maybe<Scalars['String']>;
  readonly commissionBasisAmount?: Maybe<Scalars['Decimal']>;
  readonly marginBasisId?: Maybe<Scalars['String']>;
  readonly marginBasisPercent?: Maybe<Scalars['Decimal']>;
  readonly displayCommissionType?: Maybe<Scalars['String']>;
  readonly commissionBasisFlatAmount?: Maybe<Scalars['Decimal']>;
  readonly commissionBasisPercentAmount?: Maybe<Scalars['Decimal']>;
  readonly employee?: Maybe<Employee>;
  readonly employeeV2?: Maybe<EmployeeV2>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
};

/** A connection to a list of items. */
export type OrderConnection = {
  readonly __typename?: 'OrderConnection';
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<OrderEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Order>>>;
};

export type OrderCreditBypassPayload = {
  readonly __typename?: 'OrderCreditBypassPayload';
  readonly message: Scalars['String'];
  readonly success: Scalars['Boolean'];
  readonly appliedBy?: Maybe<UserV2>;
  readonly appliedOn?: Maybe<Scalars['String']>;
};

export type OrderCreditInfoPayload = {
  readonly __typename?: 'OrderCreditInfoPayload';
  readonly creditStatus?: Maybe<Scalars['String']>;
  readonly availableCreditAmount?: Maybe<Scalars['Float']>;
  readonly overriddenBy?: Maybe<UserV2>;
  readonly overriddenOn?: Maybe<Scalars['String']>;
  readonly overridden?: Maybe<Scalars['Boolean']>;
};

export type OrderDefaults = {
  readonly __typename?: 'OrderDefaults';
  readonly billToCodeId: Scalars['ID'];
  readonly scac?: Maybe<OrderProfileKeyValue>;
  readonly division: OrderProfileKeyValue;
  readonly transportMode?: Maybe<OrderProfileKeyValue>;
  readonly size?: Maybe<OrderProfileKeyValue>;
  readonly trailerTypes?: Maybe<ReadonlyArray<OrderProfileKeyValue>>;
  readonly dimensions?: Maybe<OrderProfileDimensions>;
  readonly cargoInsuranceV2?: Maybe<OrderProfileLoadCurrency>;
  readonly requirements?: Maybe<ReadonlyArray<OrderProfileKeyValue>>;
  readonly orderNote?: Maybe<Scalars['String']>;
  readonly invoiceNote?: Maybe<Scalars['String']>;
};


export type OrderDefaultsCargoInsuranceV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};

export type OrderDefaultsInput = {
  readonly billToCodeId: Scalars['ID'];
  readonly scacId?: InputMaybe<Scalars['ID']>;
  readonly divisionId: Scalars['ID'];
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly dimensions?: InputMaybe<OrderProfileDimensionsInput>;
  readonly cargoInsuranceV2?: InputMaybe<OrderProfileCurrencyInput>;
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly orderNote?: InputMaybe<Scalars['String']>;
  readonly invoiceNote?: InputMaybe<Scalars['String']>;
};

export type OrderDefaultsInputV2 = {
  readonly billToCodeId?: InputMaybe<Scalars['ID']>;
  readonly scacId?: InputMaybe<Scalars['ID']>;
  readonly divisionId: Scalars['ID'];
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly dimensions?: InputMaybe<OrderProfileDimensionsInput>;
  readonly cargoInsuranceV2?: InputMaybe<OrderProfileCurrencyInput>;
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly orderNote?: InputMaybe<Scalars['String']>;
  readonly invoiceNote?: InputMaybe<Scalars['String']>;
};

export type OrderDefaultsV2 = {
  readonly __typename?: 'OrderDefaultsV2';
  readonly billToCodeId?: Maybe<Scalars['ID']>;
  readonly scac?: Maybe<OrderProfileKeyValue>;
  readonly division: OrderProfileKeyValue;
  readonly transportMode?: Maybe<OrderProfileKeyValue>;
  readonly size?: Maybe<OrderProfileKeyValue>;
  readonly trailerTypes?: Maybe<ReadonlyArray<OrderProfileKeyValue>>;
  readonly dimensions?: Maybe<OrderProfileDimensions>;
  readonly cargoInsuranceV2?: Maybe<OrderProfileLoadCurrency>;
  readonly requirements?: Maybe<ReadonlyArray<OrderProfileKeyValue>>;
  readonly orderNote?: Maybe<Scalars['String']>;
  readonly invoiceNote?: Maybe<Scalars['String']>;
};


export type OrderDefaultsV2CargoInsuranceV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};

/** The input type for Disable Financials */
export type OrderDisableFinancialsInput = {
  readonly customerId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly disableFinancials: Scalars['Boolean'];
};

export type OrderDisableFinancialsModel = {
  readonly __typename?: 'OrderDisableFinancialsModel';
  readonly customerId: Scalars['Uuid'];
  readonly orderId: Scalars['Uuid'];
  readonly disableFinancials: Scalars['Boolean'];
  readonly hasPropertyMetadata: Scalars['Boolean'];
  readonly eid?: Maybe<Scalars['Long']>;
  readonly id?: Maybe<Scalars['Uuid']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly propertyMetadata?: Maybe<ReadonlyArray<KeyValuePairOfStringAndPropertyMetadata>>;
};

/** An edge in a connection. */
export type OrderEdge = {
  readonly __typename?: 'OrderEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Order>;
};

export enum OrderFinancialStatusEnum {
  Complete = 'COMPLETE',
  Exception = 'EXCEPTION',
  Incomplete = 'INCOMPLETE',
  Invoiced = 'INVOICED',
  None = 'NONE',
  Settled = 'SETTLED',
  /** @deprecated Field no longer supported */
  Unmapped = 'UNMAPPED',
  Vouchered = 'VOUCHERED',
  Awaitingpaperwork = 'AWAITINGPAPERWORK',
  Awaitingrequirements = 'AWAITINGREQUIREMENTS',
  External = 'EXTERNAL'
}

/** The input type for IsApprovedForInvoicing for Order */
export type OrderHoldApprovedForInvoicingInput = {
  readonly orderId: Scalars['ID'];
  readonly isApprovedForInvoicing: Scalars['Boolean'];
};

export type OrderHoldApprovedForInvoicingModel = {
  readonly __typename?: 'OrderHoldApprovedForInvoicingModel';
  readonly orderId: Scalars['Uuid'];
  readonly isApprovedForInvoicing: Scalars['Boolean'];
  readonly hasPropertyMetadata: Scalars['Boolean'];
  readonly eid?: Maybe<Scalars['Long']>;
  readonly id?: Maybe<Scalars['Uuid']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly propertyMetadata?: Maybe<ReadonlyArray<KeyValuePairOfStringAndPropertyMetadata>>;
};

/** The input type for Order */
export type OrderInput = {
  readonly orderId: Scalars['ID'];
  readonly customerId: Scalars['ID'];
};

export type OrderInvoiceGenerationInput = {
  /** Division associated with the entity, must match a division DDT */
  readonly division?: InputMaybe<Scalars['String']>;
  /** Load Size associated with the entity, must match a loadSize DDT */
  readonly loadSize?: InputMaybe<Scalars['String']>;
  /** Trailer Type equipment associated with the entity, must match a trailerType DDT option ID in UUID format */
  readonly equipment?: ReadonlyArray<Scalars['String']>;
  /** Route Transport Mode associated with the entity, must match a routeTransportMode DDT */
  readonly routeTransportMode?: InputMaybe<Scalars['String']>;
  /** Order Transport Mode associated with the entity, must match a transportMode DDT */
  readonly orderTransportMode?: InputMaybe<Scalars['String']>;
  /** Bill to Code associated with the entity, bill to code must be in UUID format */
  readonly billToCode?: InputMaybe<Scalars['String']>;
  /** Order Type associated with the entity, must match a orderType DDT */
  readonly orderType?: InputMaybe<Scalars['String']>;
};

export type OrderInvoiceHoldInput = {
  /** Division associated with the entity, must match a division DDT */
  readonly division?: InputMaybe<Scalars['String']>;
  /** Load Size associated with the entity, must match a loadSize DDT */
  readonly loadSize?: InputMaybe<Scalars['String']>;
  /** Trailer Type equipment associated with the entity, must match a trailerType DDT option ID in UUID format */
  readonly equipment?: ReadonlyArray<Scalars['String']>;
  /** Route Transport Mode associated with the entity, must match a routeTransportMode DDT */
  readonly routeTransportMode?: InputMaybe<Scalars['String']>;
  /** Order Transport Mode associated with the entity, must match a transportMode DDT */
  readonly orderTransportMode?: InputMaybe<Scalars['String']>;
  /** Bill to Code associated with the entity, bill to code must be in UUID format */
  readonly billToCode?: InputMaybe<Scalars['String']>;
  /** Order Type associated with the entity, must match a orderType DDT */
  readonly orderType?: InputMaybe<Scalars['String']>;
};

export enum OrderLifeCycleStatusEnum {
  Assigned = 'ASSIGNED',
  Delivered = 'DELIVERED',
  Dispatched = 'DISPATCHED',
  None = 'NONE',
  Open = 'OPEN',
  /** @deprecated Field no longer supported */
  Unmapped = 'UNMAPPED',
  Arrivedstop = 'ARRIVEDSTOP',
  Departedstop = 'DEPARTEDSTOP',
  Driverassigned = 'DRIVERASSIGNED',
  InTransit = 'IN_TRANSIT'
}

export type OrderMatchedLane = {
  readonly __typename?: 'OrderMatchedLane';
  readonly laneId: Scalars['ID'];
  /** The sequence of the lane in the order match run result. Lower sequence means more granular. */
  readonly sequence: Scalars['Int'];
  readonly laneType?: Maybe<Scalars['String']>;
};

export type OrderMatchedLanesInput = {
  readonly orderId: Scalars['ID'];
};

export type OrderOverride = {
  readonly __typename?: 'OrderOverride';
  readonly orderId: Scalars['ID'];
  readonly totalRate: Scalars['Decimal'];
};

export type OrderOverrideInput = {
  readonly orderId: Scalars['ID'];
  readonly totalRate: Scalars['Decimal'];
};

export type OrderProfile = {
  readonly __typename?: 'OrderProfile';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly sequence?: Maybe<Scalars['Int']>;
  readonly profileName: Scalars['String'];
  readonly active: Scalars['Boolean'];
  readonly conditions?: Maybe<Conditions>;
  readonly orderDefaults: OrderDefaults;
  readonly routeDefaults?: Maybe<RouteDefaults>;
  readonly createdAt: Scalars['Date'];
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdBy: UserV2;
  readonly updatedBy?: Maybe<UserV2>;
  readonly createdByUserId: Scalars['ID'];
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type OrderProfileCurrencyInput = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type OrderProfileDimensions = {
  readonly __typename?: 'OrderProfileDimensions';
  readonly height?: Maybe<OrderProfileLength>;
  readonly length?: Maybe<OrderProfileLength>;
  readonly width?: Maybe<OrderProfileLength>;
};


export type OrderProfileDimensionsHeightArgs = {
  unit?: InputMaybe<OrderProfileUnitOfLengthEnum>;
};


export type OrderProfileDimensionsLengthArgs = {
  unit?: InputMaybe<OrderProfileUnitOfLengthEnum>;
};


export type OrderProfileDimensionsWidthArgs = {
  unit?: InputMaybe<OrderProfileUnitOfLengthEnum>;
};

export type OrderProfileDimensionsInput = {
  readonly height?: InputMaybe<OrderProfileUnitOfLengthInput>;
  readonly length?: InputMaybe<OrderProfileUnitOfLengthInput>;
  readonly width?: InputMaybe<OrderProfileUnitOfLengthInput>;
};

export type OrderProfileKeyValue = {
  readonly __typename?: 'OrderProfileKeyValue';
  readonly id: Scalars['ID'];
  readonly active: Scalars['Boolean'];
  readonly description?: Maybe<Scalars['String']>;
  readonly metadataJson: Scalars['String'];
  readonly name: Scalars['String'];
  readonly sort: Scalars['Float'];
  readonly systemDefined: Scalars['Boolean'];
};

export type OrderProfileLength = {
  readonly __typename?: 'OrderProfileLength';
  readonly unit: OrderProfileUnitOfLengthEnum;
  readonly value: Scalars['Float'];
};

export type OrderProfileLoadCurrency = {
  readonly __typename?: 'OrderProfileLoadCurrency';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type OrderProfilePayload = {
  readonly __typename?: 'OrderProfilePayload';
  readonly errors: ReadonlyArray<OrderProfileTypeError>;
  readonly orderProfile?: Maybe<OrderProfile>;
};

export type OrderProfilePayloadV2 = {
  readonly __typename?: 'OrderProfilePayloadV2';
  readonly errors: ReadonlyArray<OrderProfileTypeError>;
  readonly orderProfile?: Maybe<OrderProfileV2>;
};

export type OrderProfileTypeError = {
  readonly __typename?: 'OrderProfileTypeError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly serviceError?: Maybe<Scalars['JSON']>;
};

export enum OrderProfileUnitOfLengthEnum {
  Ft = 'ft',
  In = 'in',
  Km = 'km',
  M = 'm',
  Mi = 'mi'
}

export type OrderProfileUnitOfLengthInput = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type OrderProfileV2 = {
  readonly __typename?: 'OrderProfileV2';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly sequence?: Maybe<Scalars['Int']>;
  readonly profileName: Scalars['String'];
  readonly active: Scalars['Boolean'];
  readonly conditions?: Maybe<Conditions>;
  readonly orderDefaults: OrderDefaultsV2;
  readonly routeDefaults?: Maybe<RouteDefaults>;
  readonly createdAt: Scalars['Date'];
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdBy: UserV2;
  readonly updatedBy?: Maybe<UserV2>;
  readonly createdByUserId: Scalars['ID'];
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type OrderRep = {
  readonly __typename?: 'OrderRep';
  readonly employee: Employee;
  readonly id: Scalars['ID'];
  readonly isLoadRep: Scalars['Boolean'];
  readonly main: Scalars['Boolean'];
};

export type OrderRequiredScheduling = {
  readonly __typename?: 'OrderRequiredScheduling';
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerGroups?: Maybe<ReadonlyArray<KeyValue>>;
  readonly mainCustomerRepFullName?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly destination?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly orderCreatedAt: Scalars['DateTime'];
  readonly reps?: Maybe<Scalars['String']>;
  readonly requestedEndDate?: Maybe<Scalars['String']>;
  readonly endOffsetMs?: Maybe<Scalars['Float']>;
};

export type OrderRequiredSchedulingEdge = {
  readonly __typename?: 'OrderRequiredSchedulingEdge';
  readonly node: OrderRequiredScheduling;
  readonly cursor: Scalars['String'];
};

/** A requirement for an order */
export type OrderRequirement = {
  readonly __typename?: 'OrderRequirement';
  readonly option: KeyValue;
};

export type OrderRequirementInfo = {
  readonly __typename?: 'OrderRequirementInfo';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly metadataJson: Scalars['String'];
};

export type OrderRequirementInput = {
  readonly optionId: Scalars['ID'];
};

export type OrderRequirementResponse = {
  readonly __typename?: 'OrderRequirementResponse';
  readonly id: Scalars['ID'];
  readonly orderRequirement: RateQuoteKeyValue;
};

export type OrdersFilter = {
  readonly customerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly orderCode?: InputMaybe<Scalars['String']>;
  readonly refCode?: InputMaybe<Scalars['String']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly project?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly remitToVendorId?: InputMaybe<Scalars['ID']>;
  readonly carrierRepId?: InputMaybe<Scalars['ID']>;
  readonly customerRepId?: InputMaybe<Scalars['ID']>;
  readonly deliveryDateRange?: InputMaybe<DateRange>;
  readonly accountingStatuses?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly excludeVendorInvoiceMissing?: InputMaybe<Scalars['Boolean']>;
  /** Don't use this field, it has been deprecated */
  readonly customerName?: InputMaybe<Scalars['String']>;
  readonly billToCustomerId?: InputMaybe<Scalars['ID']>;
  readonly orderFinancialStatuses?: InputMaybe<ReadonlyArray<OrderFinancialStatusEnum>>;
  readonly orderLifeCycles?: InputMaybe<ReadonlyArray<OrderLifeCycleStatusEnum>>;
  readonly routeFinancialStatuses?: InputMaybe<ReadonlyArray<VendorFinancialStatusEnum>>;
  readonly invoiceDistributionMethods?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly finalizedFuelStatuses?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly orderFinancialStatusReasons?: InputMaybe<ReadonlyArray<AwaitingRequirementsReason>>;
  readonly invoiceHoldDateRange?: InputMaybe<DateRange>;
  /** Deprecated: Don't use this field */
  readonly invoiceHoldEventType?: InputMaybe<Scalars['String']>;
  readonly invoiceHoldEventTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type OrdersRequiredSchedulingCommonFilter = {
  /** appointmentStatus filter is deprecated  */
  readonly appointmentStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** scheduleAppointmentType filter is deprecated */
  readonly scheduleAppointmentType?: InputMaybe<ReadonlyArray<ScheduleAppointmentTypeEnum>>;
  /** routeLifeCyclestatus filter is deprecated */
  readonly routeLifeCyclestatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** routeActivationStatus filter is deprecated */
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** orderTenderStatus filter is deprecated */
  readonly orderTenderStatus?: InputMaybe<Scalars['String']>;
  /** Search by order Rep Employee identifier. This input field Deprecated please use orderRepIds */
  readonly orderRepId?: InputMaybe<Scalars['ID']>;
  /** Search by order Rep Employee identifiers */
  readonly orderRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/Filter by Customer identifiers */
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/Filter by facility id */
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  /** Search/Filter by start range beginning */
  readonly startDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Search/Filter by start range ending */
  readonly endDate?: InputMaybe<Scalars['ISO8601DateTime']>;
};

export type OrdersRequiredSchedulingConnection = {
  readonly __typename?: 'OrdersRequiredSchedulingConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<OrderRequiredSchedulingEdge>;
};

export type OrdersRequiredSchedulingFilter = {
  /** appointmentStatus filter is deprecated  */
  readonly appointmentStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** scheduleAppointmentType filter is deprecated */
  readonly scheduleAppointmentType?: InputMaybe<ReadonlyArray<ScheduleAppointmentTypeEnum>>;
  /** routeLifeCyclestatus filter is deprecated */
  readonly routeLifeCyclestatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** routeActivationStatus filter is deprecated */
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** orderTenderStatus filter is deprecated */
  readonly orderTenderStatus?: InputMaybe<Scalars['String']>;
  /** Search by order Rep Employee identifier. This input field Deprecated please use orderRepIds */
  readonly orderRepId?: InputMaybe<Scalars['ID']>;
  /** Search by order Rep Employee identifiers */
  readonly orderRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/Filter by Customer identifiers */
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/Filter by facility id */
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  /** Search/Filter by start range beginning */
  readonly startDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Search/Filter by start range ending */
  readonly endDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Search/Filter by array of Customer Employee groups */
  readonly customerEmployeeGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by first route stop address */
  readonly originRouteAddress?: InputMaybe<SchedulingAddressFilter>;
  /** Search/Filter by last route stop address */
  readonly destinationRouteAddress?: InputMaybe<SchedulingAddressFilter>;
  /** Search/Filter by Main Customer Rep */
  readonly mainCustomerRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Filter by order code */
  readonly orderCode?: InputMaybe<Scalars['Float']>;
  /** Search/Filter by order createdAt date time */
  readonly orderCreatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
};

/** lachesis-api: Order Status Record V3 - Features Multi-Route Functionality */
export type OrderStatusRecordV3 = {
  readonly __typename?: 'OrderStatusRecordV3';
  /** lachesis-api: ID associated with a Load. */
  readonly load_id: Scalars['ID'];
  /** lachesis-api: Timestamp when event occured */
  readonly event_timestamp: Scalars['Timestamp'];
  /** lachesis-api: ID of the user or process that initiated the event for status calculation */
  readonly initiator?: Maybe<Scalars['String']>;
  /** lachesis-api: ID of the order associated with the load */
  readonly order_id: Scalars['ID'];
  /** lachesis-api: ID of the client or process that initiated the event for status calculation */
  readonly tenant: Scalars['String'];
  /** lachesis-api: Timestamp when the status record was created */
  readonly created_at_timestamp: Scalars['Timestamp'];
  /** lachesis-api: Array of strings that details any supported reasons for current OAS. */
  readonly order_activation_status_reasons: ReadonlyArray<Scalars['String']>;
  /** lachesis-api: Array of strings that describes any supported reasons for the current order financial status. */
  readonly order_financial_status_reasons: ReadonlyArray<Scalars['String']>;
  /** lachesis-api: The current financial status of order. */
  readonly order_financial_status?: Maybe<Scalars['String']>;
  /** lachesis-api: The current status of the order tender. */
  readonly order_tender_status?: Maybe<Scalars['String']>;
  /** lachesis-api: The current order activation status. */
  readonly order_activation_status?: Maybe<Scalars['String']>;
  /** lachesis-api: The current state of the order lifecycle. Can change depending on current load state and events. */
  readonly order_lifecycle_status?: Maybe<Scalars['String']>;
};

export type OrderStop = {
  readonly __typename?: 'OrderStop';
  readonly id: Scalars['ID'];
  readonly facilityId: Scalars['ID'];
  readonly facility: Facility;
  readonly facilityV2: FacilityV2;
  readonly note?: Maybe<Scalars['String']>;
  readonly scheduleType?: Maybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: Maybe<ScheduleDropTypeEnum>;
  readonly workType?: Maybe<ScheduleWorkTypeEnum>;
  /** @deprecated use loadStopType as it accounts for multi-route stop types, if loadStopType is present and its value is not a StopTypeEnum, this value defaults to 'Pu' */
  readonly type: StopTypeEnum;
  readonly loadStopType?: Maybe<Scalars['String']>;
  readonly loadFrom?: Maybe<KeyValue>;
  readonly unloadFrom?: Maybe<KeyValue>;
  readonly reqDate?: Maybe<DatetimeWithTimezone>;
  readonly availableStart?: Maybe<DatetimeWithTimezone>;
  readonly availableStartTimes?: Maybe<ScheduleRange>;
  readonly availableEnd?: Maybe<DatetimeWithTimezone>;
  readonly availableEndTimes?: Maybe<ScheduleRange>;
  readonly requirements?: Maybe<ReadonlyArray<StopRequirement>>;
  readonly address?: Maybe<StopAddress>;
  readonly commodities: ReadonlyArray<CommodityV2>;
  readonly refs?: Maybe<ReadonlyArray<RefV2>>;
  readonly bolNumber?: Maybe<Scalars['String']>;
  readonly sealNumber?: Maybe<Scalars['String']>;
  readonly schedulingContactId?: Maybe<Scalars['ID']>;
  readonly schedulingContact?: Maybe<Contact>;
  readonly schedulingContactV2?: Maybe<CustomerContact>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly externalRef?: Maybe<Scalars['ID']>;
  readonly numberOfPallets?: Maybe<Scalars['Int']>;
  readonly distanceToNext?: Maybe<Length>;
};


export type OrderStopDistanceToNextArgs = {
  unit: UnitOfLengthEnum;
};

export type OrderStopError = {
  readonly __typename?: 'OrderStopError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly serviceError?: Maybe<Scalars['JSON']>;
};

export type OrderStopInput = {
  readonly orderId: Scalars['ID'];
  readonly facilityId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
  readonly type?: InputMaybe<StopTypeEnum>;
  readonly loadStopType?: InputMaybe<Scalars['String']>;
  readonly loadFromId?: InputMaybe<Scalars['ID']>;
  readonly unloadFromId?: InputMaybe<Scalars['ID']>;
  readonly requirements?: InputMaybe<ReadonlyArray<StopRequirementInput>>;
  readonly commodities?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly shipmentIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInputV2>>;
  readonly reqDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly bolNumber?: InputMaybe<Scalars['String']>;
  readonly sealNumber?: InputMaybe<Scalars['String']>;
  readonly schedulingContactId?: InputMaybe<Scalars['ID']>;
  readonly externalRef?: InputMaybe<Scalars['String']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  readonly orderStopDesignation?: InputMaybe<Scalars['String']>;
};

export type OrderStopPayload = {
  readonly __typename?: 'OrderStopPayload';
  readonly errors: ReadonlyArray<OrderStopError>;
  readonly orderStop?: Maybe<OrderStop>;
};

export type OrderStopV2 = {
  readonly __typename?: 'OrderStopV2';
  readonly id: Scalars['ID'];
  readonly facilityId?: Maybe<Scalars['ID']>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly note?: Maybe<Scalars['String']>;
  readonly scheduleType?: Maybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: Maybe<ScheduleDropTypeEnum>;
  readonly workType?: Maybe<ScheduleWorkTypeEnum>;
  /** @deprecated use loadStopType as it accounts for multi-route stop types, if loadStopType is present and its value is not a StopTypeEnum, this value defaults to 'Pu' */
  readonly type: StopTypeEnum;
  readonly loadStopType?: Maybe<Scalars['String']>;
  readonly loadFrom?: Maybe<KeyValue>;
  readonly unloadFrom?: Maybe<KeyValue>;
  readonly reqDate?: Maybe<DatetimeWithTimezone>;
  readonly availableStart?: Maybe<DatetimeWithTimezone>;
  readonly availableStartTimes?: Maybe<ScheduleRange>;
  readonly availableEnd?: Maybe<DatetimeWithTimezone>;
  readonly availableEndTimes?: Maybe<ScheduleRange>;
  readonly requirements?: Maybe<ReadonlyArray<StopRequirement>>;
  readonly address?: Maybe<StopAddressV2>;
  /** @deprecated use commoditiesV2 instead */
  readonly commodities: ReadonlyArray<CommodityV2>;
  readonly commoditiesV2?: Maybe<ReadonlyArray<StopCommodityFromOrderStop>>;
  readonly refs?: Maybe<ReadonlyArray<RefV2>>;
  readonly bolNumber?: Maybe<Scalars['String']>;
  readonly sealNumber?: Maybe<Scalars['String']>;
  readonly schedulingContactId?: Maybe<Scalars['ID']>;
  readonly schedulingContact?: Maybe<Contact>;
  readonly schedulingContactV2?: Maybe<CustomerContact>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly externalRef?: Maybe<Scalars['ID']>;
  readonly numberOfPallets?: Maybe<Scalars['Int']>;
  readonly orderStopDesignation?: Maybe<Scalars['String']>;
  readonly distanceToNext?: Maybe<Length>;
};


export type OrderStopV2DistanceToNextArgs = {
  unit: UnitOfLengthEnum;
};

export type Organization = Base & {
  readonly __typename?: 'Organization';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly hierarchyCode: Scalars['String'];
  readonly parentID?: Maybe<Scalars['ID']>;
  readonly active: Scalars['Boolean'];
  readonly environment: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserID: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserID: Scalars['String'];
};

export type OrgIdEditAccessTuple = {
  readonly organizationID: Scalars['ID'];
  readonly editAccess: Scalars['Boolean'];
};

export type OriginFilter = {
  /** A partial or full city name */
  readonly city?: InputMaybe<Scalars['String']>;
  /** An array of facility IDs */
  readonly facilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** An array of abbreviated state names */
  readonly states?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** This type is only a stop gap until Kafka is fully implemented. */
export type OrphanedEntitiesModel = {
  readonly __typename?: 'OrphanedEntitiesModel';
  readonly orphanedCustomerIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly orphanedVendorIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly orphanedLoadIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly orphanedRouteIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly orphanedOrderIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly costDetailsToBeRemoved?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  readonly advancesToBeRemoved?: Maybe<ReadonlyArray<Maybe<Advance>>>;
  readonly voucherBatchesToBeRemoved?: Maybe<ReadonlyArray<Maybe<VoucherBatch>>>;
  readonly voucherHeadersToBeRemoved?: Maybe<ReadonlyArray<Maybe<VoucherHeader>>>;
  readonly rateDetailsToBeRemoved?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
  readonly invoiceBatchesToBeRemoved?: Maybe<ReadonlyArray<Maybe<InvoiceBatch>>>;
  readonly invoiceHeadersToBeRemoved?: Maybe<ReadonlyArray<Maybe<InvoiceHeader>>>;
};

export type Outbound214EmailAddress = {
  readonly __typename?: 'Outbound214EmailAddress';
  readonly id?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly dataExchangeRulesetId?: Maybe<Scalars['String']>;
  readonly emailAddress?: Maybe<Scalars['String']>;
};

export type Outbound214EmailAddressFilter = {
  readonly customerId: Scalars['ID'];
  readonly dataExchangeRulesetId: Scalars['ID'];
};

export type Outbound214EmailAddressInput = {
  readonly customerId: Scalars['String'];
  readonly dataExchangeRulesetId: Scalars['String'];
  readonly emailAddresses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type Outbound214FrequencyInput = {
  readonly customerId: Scalars['UUID'];
  readonly frequencyType?: InputMaybe<FrequencyTypes>;
  readonly frequencyValue: Scalars['String'];
  readonly statusCode: Scalars['String'];
  readonly output: Scalars['String'];
};

export type Outbound214StopAppointment = {
  readonly __typename?: 'Outbound214StopAppointment';
  readonly id: Scalars['Uuid'];
  readonly stopId: Scalars['Uuid'];
  readonly hashId: Scalars['Uuid'];
  readonly isVariance: Scalars['Boolean'];
};

export type Outbound214StopAppointmentInput = {
  readonly stopId: Scalars['Uuid'];
  readonly hashId: Scalars['Uuid'];
};

export type OutboundTenderSettings = {
  readonly __typename?: 'OutboundTenderSettings';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly tenderAppointmentStatusTerm?: Maybe<Scalars['String']>;
  readonly automatedTendering?: Maybe<Scalars['Boolean']>;
  readonly rolloverTenderTime?: Maybe<Scalars['Boolean']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly lastUpdatedByUser?: Maybe<UserV2>;
  readonly customer?: Maybe<CustomerV2>;
  readonly maximumRolloverTenderTimeInHours?: Maybe<Scalars['Int']>;
  readonly maximumRolloverTenderTimeInHoursV2?: Maybe<Scalars['Decimal']>;
};

export type OutboundTenderSettingsInput = {
  readonly customerId: Scalars['ID'];
  readonly tenderAppointmentStatusTerm: Scalars['String'];
  readonly automatedTendering: Scalars['Boolean'];
  readonly rolloverTenderTime: Scalars['Boolean'];
  readonly requestedById: Scalars['ID'];
  readonly maximumRolloverTenderTimeInHours?: InputMaybe<Scalars['Int']>;
  readonly maximumRolloverTenderTimeInHoursV2?: InputMaybe<Scalars['Decimal']>;
};

export type OutboxEventsEntity = {
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly entityId?: InputMaybe<Scalars['String']>;
  readonly kafkaMessageId?: InputMaybe<Scalars['String']>;
  readonly key?: InputMaybe<Scalars['String']>;
  readonly message?: InputMaybe<Scalars['String']>;
  readonly partitionKey?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<Scalars['String']>;
  readonly topic?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum OutdatedFallbackBehavior {
  BestEffort = 'BestEffort',
  Fail = 'Fail'
}

export type OverrideDefaultCostDetailRerateBehaviorByChargeTypeInput = {
  readonly alwaysPreserveExistingCostDetails?: InputMaybe<Scalars['Boolean']>;
  readonly alwaysOverwriteExistingCostDetails?: InputMaybe<Scalars['Boolean']>;
  readonly chargeTypeId: Scalars['Uuid'];
  readonly chargeTypeName?: InputMaybe<Scalars['String']>;
};

export type OverrideDefaultRerateBehaviorByChargeTypeInput = {
  readonly alwaysPreserveExistingRateDetails?: InputMaybe<Scalars['Boolean']>;
  readonly alwaysOverwriteExistingRateDetails?: InputMaybe<Scalars['Boolean']>;
  readonly chargeTypeId: Scalars['Uuid'];
  readonly chargeTypeName?: InputMaybe<Scalars['String']>;
};

export type OwnerDetails = {
  readonly __typename?: 'OwnerDetails';
  readonly id?: Maybe<Scalars['ID']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  readonly __typename?: 'PageInfo';
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  readonly hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  readonly hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  readonly startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  readonly endCursor?: Maybe<Scalars['String']>;
};

export type PageInfoDetails = {
  readonly __typename?: 'PageInfoDetails';
  readonly startCursor?: Maybe<Scalars['String']>;
  readonly endCursor?: Maybe<Scalars['String']>;
  readonly hasNextPage: Scalars['Boolean'];
  readonly hasPreviousPage: Scalars['Boolean'];
};

export type PaginationFilter = {
  readonly first?: InputMaybe<Scalars['Int']>;
  readonly last?: InputMaybe<Scalars['Int']>;
  readonly before?: InputMaybe<GeographyServiceInput>;
  readonly after?: InputMaybe<GeographyServiceInput>;
};

export type PaginationFilters = {
  readonly powerId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type PaginationInput = {
  readonly first?: InputMaybe<Scalars['Int']>;
  readonly last?: InputMaybe<Scalars['Int']>;
  readonly before?: InputMaybe<Scalars['String']>;
  readonly after?: InputMaybe<Scalars['String']>;
};

export type PaginationQuery = {
  /** The number of validation configurations in the query to skip */
  readonly skip?: InputMaybe<Scalars['Int']>;
  /** The maximum amount of validation configurations to return */
  readonly limit?: InputMaybe<Scalars['Int']>;
};

export type PagingParameters = {
  readonly pageNumber?: InputMaybe<Scalars['Int']>;
  readonly pageSize?: InputMaybe<Scalars['Int']>;
};

export enum PalletTypeEnum {
  /** Chep */
  Chep = 'chep',
  /** Disposable */
  Disposable = 'disposable',
  /** None */
  None = 'none',
  /** Peco */
  Peco = 'peco',
  /** Pico */
  Pico = 'pico',
  /** Standard */
  Standard = 'standard'
}

export enum PalletTypesEnum {
  Standard = 'standard',
  Chep = 'chep',
  Peco = 'peco',
  Disposable = 'disposable'
}

export type ParametersDat = {
  readonly haversineCoefficient: Scalars['Float'];
};

export type ParentRelationship = {
  readonly __typename?: 'ParentRelationship';
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly carrierParentId: Scalars['ID'];
  readonly carrierParent?: Maybe<CarrierV2>;
  readonly createdAt?: Maybe<Scalars['Iso8601Date']>;
  readonly updatedAt?: Maybe<Scalars['Iso8601Date']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly relationshipType?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
};

/** The Partial Voucher batch processing input type.  Used to process single partial Voucher */
export type PartialVoucherBatchProcessingInput = {
  readonly batchId?: InputMaybe<Scalars['ID']>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly addAdjustments?: InputMaybe<Scalars['Boolean']>;
  readonly costDetailsForProcessing: ReadonlyArray<CostDetailProcessingInput>;
  readonly vendorInvoiceForProcessing: ReadonlyArray<VendorInvoiceProcessingInput>;
  readonly voucherOverrides?: InputMaybe<VoucherOverridesInput>;
  readonly voucherDateOption?: InputMaybe<DefaultVoucherDateOptionEnum>;
};

export type PartialVoucherModel = {
  readonly __typename?: 'PartialVoucherModel';
  readonly vendorInvoices: ReadonlyArray<VendorInvoice>;
  readonly routeVendorModel: RouteVendorCost;
};

export type PathDat = {
  readonly __typename?: 'PathDat';
  readonly location?: Maybe<Scalars['String']>;
  readonly serviceMinutes?: Maybe<Scalars['Int']>;
  readonly lowerWindow?: Maybe<Scalars['String']>;
  readonly upperWindow?: Maybe<Scalars['String']>;
  readonly latitude?: Maybe<Scalars['Float']>;
  readonly longitude?: Maybe<Scalars['Float']>;
  readonly milesToNext?: Maybe<Scalars['Float']>;
  readonly minutesToNext?: Maybe<Scalars['Float']>;
  readonly mph?: Maybe<Scalars['Float']>;
  readonly leg?: Maybe<Scalars['Int']>;
};

export type PathEntryInput = {
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type Pattern = {
  readonly __typename?: 'Pattern';
  readonly id: Scalars['ID'];
  readonly laneId?: Maybe<Scalars['ID']>;
  readonly patternPriority?: Maybe<Scalars['Int']>;
  readonly patternName?: Maybe<Scalars['String']>;
  readonly numberOfRoutes?: Maybe<Scalars['Int']>;
  readonly numberOfStops?: Maybe<Scalars['Int']>;
  readonly patternRoutes?: Maybe<ReadonlyArray<Maybe<PatternRoute>>>;
  readonly minimumPickupLeadTime?: Maybe<Scalars['Int']>;
  /** @deprecated Use eligibleDaysV2 */
  readonly eligibleDays?: Maybe<ReadonlyArray<DayOfWeekEnum>>;
  readonly eligibleDaysV2?: Maybe<ReadonlyArray<DayEnum>>;
  readonly effectiveDate?: Maybe<Scalars['Date']>;
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly autoApply: Scalars['Boolean'];
  readonly autoApplyPriority?: Maybe<Scalars['Int']>;
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

export type PatternDataProblem = {
  readonly __typename?: 'PatternDataProblem';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type PatternOrderApplicationPayload = {
  readonly __typename?: 'PatternOrderApplicationPayload';
  readonly id: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly patternId: Scalars['ID'];
  readonly userId: Scalars['ID'];
  readonly employeeId: Scalars['ID'];
  readonly isSuccess?: Maybe<Scalars['Boolean']>;
  readonly statuses?: Maybe<ReadonlyArray<ApplyPatternStatusItem>>;
  readonly routes?: Maybe<ReadonlyArray<ApplyPatternOrderRoute>>;
};

export type PatternRoute = {
  readonly __typename?: 'PatternRoute';
  readonly id: Scalars['ID'];
  readonly patternId?: Maybe<Scalars['ID']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeTypeId?: Maybe<Scalars['String']>;
  readonly transportModeId?: Maybe<Scalars['String']>;
  readonly loadSizeId?: Maybe<Scalars['String']>;
  readonly trailerTypeId?: Maybe<Scalars['String']>;
  readonly divisionId?: Maybe<Scalars['String']>;
  readonly additionalDivisionIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly vendor?: Maybe<Carrier>;
  readonly vendorV2?: Maybe<CarrierV2>;
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly lastRoutingGuideCheckDate?: Maybe<Scalars['Date']>;
  readonly routeLaneId?: Maybe<Scalars['ID']>;
  readonly patternStops?: Maybe<ReadonlyArray<Maybe<PatternStop>>>;
  readonly serviceProfile?: Maybe<ServiceProfile>;
  readonly routingGuide?: Maybe<RoutingGuide>;
  readonly laneV2?: Maybe<LaneV2>;
};

export type PatternStop = {
  readonly __typename?: 'PatternStop';
  readonly id: Scalars['ID'];
  readonly patternRouteId?: Maybe<Scalars['ID']>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly stopSequence?: Maybe<Scalars['Int']>;
  readonly stopType?: Maybe<Scalars['String']>;
  readonly dropType?: Maybe<ScheduleDropTypeEnum>;
  readonly workType?: Maybe<ScheduleWorkTypeEnum>;
  readonly requirements?: Maybe<ReadonlyArray<Maybe<PatternStopRequirement>>>;
  readonly useOrderStopFacility?: Maybe<Scalars['Boolean']>;
  readonly daysFromPreviousStop?: Maybe<Scalars['Int']>;
  readonly laneStopSequence?: Maybe<Scalars['Int']>;
  readonly appointment?: Maybe<PatternStopAppointment>;
};

export type PatternStopAppointment = {
  readonly __typename?: 'PatternStopAppointment';
  readonly startTime?: Maybe<Scalars['Int']>;
  readonly stopTime?: Maybe<Scalars['Int']>;
  readonly appointmentType?: Maybe<ScheduleAppointmentTypeEnum>;
};

export type PatternStopAppointmentInput = {
  readonly startTime?: InputMaybe<Scalars['Int']>;
  readonly stopTime?: InputMaybe<Scalars['Int']>;
  readonly appointmentType: ScheduleAppointmentTypeEnum;
};

export type PatternStopRequirement = {
  readonly __typename?: 'PatternStopRequirement';
  readonly patternStopId?: Maybe<Scalars['ID']>;
  readonly requirement?: Maybe<Scalars['String']>;
};

export type PatternStopRequirementInput = {
  readonly requirement: Scalars['String'];
};

export type PayableRouteStop = {
  readonly __typename?: 'PayableRouteStop';
  /** routeStopId */
  readonly id: Scalars['ID'];
  readonly payableDistanceToNext?: Maybe<LengthV2>;
  readonly loadedPayableDistanceToNext?: Maybe<LengthV2>;
};

/** Represents the available Advance providers. */
export enum PaymentTypeEnum {
  /**
   * Comcheck provided by Comdata.
   * @deprecated Deprecated, do not use
   */
  Comchek = 'Comchek',
  /** @deprecated Deprecated, do not use */
  Noop = 'NOOP',
  /** @deprecated Deprecated, do not use */
  Tcheck = 'TCHECK'
}

export type PayProgramCarrierAssociationModel = {
  readonly __typename?: 'PayProgramCarrierAssociationModel';
  readonly id: Scalars['ID'];
  readonly payProgramId: Scalars['ID'];
  readonly payProgram?: Maybe<PayProgramModel>;
  readonly carrierId: Scalars['ID'];
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly description?: Maybe<Scalars['String']>;
  readonly rank?: Maybe<PayProgramRankEnum>;
  readonly effectiveDate: Scalars['Date'];
  readonly effectiveDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use expirationDateTime instead. It includes time offsets. */
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly expirationDateTime?: Maybe<Scalars['DateTime']>;
  readonly createdById: Scalars['ID'];
  readonly createdByName: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly updatedById: Scalars['ID'];
  readonly updatedByName: Scalars['String'];
  readonly updatedOn: Scalars['DateTime'];
  readonly success: Scalars['Boolean'];
  readonly message?: Maybe<Scalars['String']>;
  readonly errorCode?: Maybe<Scalars['String']>;
  readonly usedOnRoutes?: Maybe<ReadonlyArray<LoadRouteV2>>;
};

export type PayProgramCarrierAssociationSummaryModel = {
  readonly __typename?: 'PayProgramCarrierAssociationSummaryModel';
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly rank?: Maybe<PayProgramRankEnum>;
  readonly effectiveDate: Scalars['Date'];
  readonly effectiveDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use expirationDateTime instead. It includes time offsets. */
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly expirationDateTime?: Maybe<Scalars['DateTime']>;
};

export type PayProgramDetailChargeTypeConfigurationInput = {
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly payProgramId?: InputMaybe<Scalars['ID']>;
};

export type PayProgramDetailChargeTypeConfigurationResponse = {
  readonly __typename?: 'PayProgramDetailChargeTypeConfigurationResponse';
  readonly isActive: Scalars['Boolean'];
  readonly isCredit: Scalars['Boolean'];
  readonly carrierId: Scalars['Guid'];
  readonly category?: Maybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['Guid']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly isDefault: Scalars['Boolean'];
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly payProgramDescription?: Maybe<Scalars['String']>;
  readonly payProgramChargeCode?: Maybe<Scalars['String']>;
  readonly payProgramName?: Maybe<Scalars['String']>;
  readonly payProgramId: Scalars['Guid'];
  readonly cost?: Maybe<Scalars['Decimal']>;
  readonly perUnit?: Maybe<CtcPerUnitTypeEnum>;
  readonly maximumTotalCost?: Maybe<Scalars['Decimal']>;
  readonly minimumTotalCost?: Maybe<Scalars['Decimal']>;
  readonly isPreTax?: Maybe<Scalars['Boolean']>;
  readonly percentOfChargeTypeIdsList?: Maybe<ReadonlyArray<Scalars['Guid']>>;
  readonly isTieredCostPerConfiguration?: Maybe<Scalars['Boolean']>;
  readonly tieredCostSchedules?: Maybe<ReadonlyArray<PayProgramDetailChargeTypeConfigurationTieredCostSchedule>>;
};

export type PayProgramDetailChargeTypeConfigurationTieredCostSchedule = {
  readonly __typename?: 'PayProgramDetailChargeTypeConfigurationTieredCostSchedule';
  readonly id?: Maybe<Scalars['ID']>;
  readonly beginRange?: Maybe<Scalars['Decimal']>;
  readonly endRange?: Maybe<Scalars['Decimal']>;
  readonly costPer?: Maybe<Scalars['Decimal']>;
};

export type PayProgramDriverAssociationModel = {
  readonly __typename?: 'PayProgramDriverAssociationModel';
  readonly id: Scalars['ID'];
  readonly payProgramId: Scalars['ID'];
  readonly payProgram?: Maybe<PayProgramModel>;
  readonly driverId: Scalars['ID'];
  readonly assetDriver?: Maybe<AssetDriver>;
  readonly description?: Maybe<Scalars['String']>;
  readonly rank?: Maybe<PayProgramRankEnum>;
  readonly effectiveDate: Scalars['Date'];
  readonly effectiveDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use expirationDateTime instead. It includes time offsets. */
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly expirationDateTime?: Maybe<Scalars['DateTime']>;
  readonly driverLevel: Scalars['Boolean'];
  readonly fleet?: Maybe<CarrierV2>;
  readonly createdById: Scalars['ID'];
  readonly createdByName: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly updatedById: Scalars['ID'];
  readonly updatedByName: Scalars['String'];
  readonly updatedOn: Scalars['DateTime'];
  readonly success: Scalars['Boolean'];
  readonly message?: Maybe<Scalars['String']>;
  readonly errorCode?: Maybe<Scalars['String']>;
  readonly usedOnRoutes?: Maybe<ReadonlyArray<LoadRouteV2>>;
};

export type PayProgramDriverAssociationSummaryModel = {
  readonly __typename?: 'PayProgramDriverAssociationSummaryModel';
  readonly id: Scalars['ID'];
  readonly driverId: Scalars['ID'];
  readonly assetDriver?: Maybe<AssetDriver>;
  readonly rank?: Maybe<PayProgramRankEnum>;
  readonly effectiveDate: Scalars['Date'];
  readonly effectiveDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use expirationDateTime instead. It includes time offsets. */
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly expirationDateTime?: Maybe<Scalars['DateTime']>;
};

export type PayProgramModel = {
  readonly __typename?: 'PayProgramModel';
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly code: Scalars['String'];
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly isDisabled: Scalars['Boolean'];
  readonly inactiveDate?: Maybe<Scalars['DateTime']>;
  readonly createdById: Scalars['ID'];
  readonly createdByName: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly updatedById: Scalars['ID'];
  readonly updatedByName: Scalars['String'];
  readonly updatedOn: Scalars['DateTime'];
  readonly carrierAssociations: ReadonlyArray<PayProgramCarrierAssociationSummaryModel>;
  readonly driverAssociations: ReadonlyArray<PayProgramDriverAssociationSummaryModel>;
};

export enum PayProgramRankEnum {
  Unknown = 'UNKNOWN',
  Primary = 'PRIMARY',
  Temporary = 'TEMPORARY'
}

export type PayRateConditionModelV2 = {
  readonly __typename?: 'PayRateConditionModelV2';
  readonly id: Scalars['ID'];
  readonly valuesJson: Scalars['String'];
  readonly payRates: ReadonlyArray<PayRateModelV2>;
};

export type PayRateModelV2 = {
  readonly __typename?: 'PayRateModelV2';
  readonly id: Scalars['ID'];
  readonly chargeType: Scalars['String'];
  readonly chargeTypeId: Scalars['ID'];
  readonly ratePer: Scalars['Decimal'];
  readonly rateType: RateTypeEnum;
  readonly quantity?: Maybe<Scalars['Decimal']>;
  readonly minimum?: Maybe<Scalars['Decimal']>;
  readonly maximum?: Maybe<Scalars['Decimal']>;
  readonly flatPlus?: Maybe<Scalars['Decimal']>;
  readonly calculateFrom?: Maybe<Scalars['Decimal']>;
  readonly mileageBase?: Maybe<MileageBaseEnum>;
  readonly mileageSource?: Maybe<MileageSourceEnum>;
};

export type PayRateTableHeaderModel = {
  readonly __typename?: 'PayRateTableHeaderModel';
  readonly id: Scalars['ID'];
  readonly payProgramId: Scalars['ID'];
  readonly description: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly effectiveDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use expirationDateTime instead. It includes time offsets. */
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly expirationDateTime?: Maybe<Scalars['DateTime']>;
  readonly importStatus: ImportStatusEnum;
  readonly createdById: Scalars['ID'];
  readonly createdByName: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly updatedById: Scalars['ID'];
  readonly updatedByName: Scalars['String'];
  readonly updatedOn: Scalars['DateTime'];
};

export type PayRateTableHistoryModel = {
  readonly __typename?: 'PayRateTableHistoryModel';
  readonly payProgramId: Scalars['ID'];
  readonly changeType: Scalars['String'];
  readonly description: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly effectiveDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use expirationDateTime instead. It includes time offsets. */
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly expirationDateTime?: Maybe<Scalars['DateTime']>;
  readonly importStatus: ImportStatusEnum;
  readonly createdById: Scalars['ID'];
  readonly createdByName: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly updatedById: Scalars['ID'];
  readonly updatedByName: Scalars['String'];
  readonly updatedOn: Scalars['DateTime'];
};

export type PayRateTableModelV2 = {
  readonly __typename?: 'PayRateTableModelV2';
  readonly id: Scalars['ID'];
  readonly payProgramId: Scalars['ID'];
  readonly description: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly effectiveDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use expirationDateTime instead. It includes time offsets. */
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly expirationDateTime?: Maybe<Scalars['DateTime']>;
  readonly defaultPayRateConditionId?: Maybe<Scalars['ID']>;
  readonly conditions: ReadonlyArray<PayRateConditionModelV2>;
};

/** Custom options to use when calculating mileage via PCMiler (Trimble), see: https://developer.trimblemaps.com/restful-apis/routing/route-reports/overview/ */
export type PcMilerOptions = {
  readonly __typename?: 'PCMilerOptions';
  readonly dataVersion?: Maybe<Scalars['String']>;
  /** 1 - Always Avoid, 2 - Avoid if Possible, 3 - Use */
  readonly TollRoads?: Maybe<Scalars['Int']>;
  readonly TollDiscourage?: Maybe<Scalars['Boolean']>;
  readonly BordersOpen?: Maybe<Scalars['Boolean']>;
  readonly FerryDiscourage?: Maybe<Scalars['Boolean']>;
  /** 0 - Truck (Default), 1 - LightTruck, 2 - Auto */
  readonly VehicleType?: Maybe<Scalars['Int']>;
  /** 0 - None (Default, which is equivalent to the 48-foot semitrailer profile), 1 - TwentyEightDoubleTrailer, 2 - FortyStraightTruck, 3 - FortyEightSemiTrailer, 4 - FiftyThreeSemiTrailer, 5 - FullSizeVan, 6 - TwentySixStraightTruck, 7 - ConventionalSchoolBus (North America only), 8 - SmallSchoolBus (North America only) */
  readonly VehProfile?: Maybe<Scalars['Int']>;
  readonly VehLength?: Maybe<Scalars['String']>;
  readonly VehHeight?: Maybe<Scalars['String']>;
  readonly VehWidth?: Maybe<Scalars['String']>;
  readonly VehWeight?: Maybe<Scalars['String']>;
  /** Specify 2 - 5 */
  readonly Axles?: Maybe<Scalars['Int']>;
  /** 0 - Practical (Default), 1 - Shortest, 2 - Fastest */
  readonly RoutingType?: Maybe<Scalars['Int']>;
  /** 0 - None(Default), 1 - FiftyThreeFoot, 2 - NationalNetwork, 3 - NationalNetwork and FiftyThreeFoot */
  readonly ClassOverrides?: Maybe<Scalars['Int']>;
  readonly HighwayOnly?: Maybe<Scalars['Boolean']>;
  readonly OverrideRestrict?: Maybe<Scalars['Boolean']>;
  readonly UseCustomRoadSpeeds?: Maybe<Scalars['Boolean']>;
  readonly HubRouting?: Maybe<Scalars['Boolean']>;
  /** Acceptable values are between 1 and 100 mph / kph. */
  readonly GovernorSpeedLimit?: Maybe<Scalars['Int']>;
  readonly ElevLimit?: Maybe<Scalars['Int']>;
  readonly UseSites?: Maybe<Scalars['Boolean']>;
  /** 0 - None (Default), 1 - General, 2 - Caustic, 3 - Explosives, 4 - Flammable, 5 - Inhalants, 6 - Radioactive, 7 - HarmfulToWater, 8 - Tunnel */
  readonly HazMatTypes?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
};

export type PcMilerOptionsInput = {
  readonly dataVersion?: InputMaybe<Scalars['String']>;
  /** 1 - Always Avoid, 2 - Avoid if Possible, 3 - Use */
  readonly TollRoads?: InputMaybe<Scalars['Int']>;
  readonly TollDiscourage?: InputMaybe<Scalars['Boolean']>;
  readonly BordersOpen?: InputMaybe<Scalars['Boolean']>;
  readonly FerryDiscourage?: InputMaybe<Scalars['Boolean']>;
  /** 0 - Truck (Default), 1 - LightTruck, 2 - Auto */
  readonly VehicleType?: InputMaybe<Scalars['Int']>;
  /** 0 - None (Default, which is equivalent to the 48-foot semitrailer profile), 1 - TwentyEightDoubleTrailer, 2 - FortyStraightTruck, 3 - FortyEightSemiTrailer, 4 - FiftyThreeSemiTrailer, 5 - FullSizeVan, 6 - TwentySixStraightTruck, 7 - ConventionalSchoolBus (North America only), 8 - SmallSchoolBus (North America only) */
  readonly VehProfile?: InputMaybe<Scalars['Int']>;
  readonly VehLength?: InputMaybe<Scalars['String']>;
  readonly VehHeight?: InputMaybe<Scalars['String']>;
  readonly VehWidth?: InputMaybe<Scalars['String']>;
  readonly VehWeight?: InputMaybe<Scalars['String']>;
  /** Specify 2 - 5 */
  readonly Axles?: InputMaybe<Scalars['Int']>;
  /** 0 - Practical (Default), 1 - Shortest, 2 - Fastest */
  readonly RoutingType?: InputMaybe<Scalars['Int']>;
  /** 0 - None(Default), 1 - FiftyThreeFoot, 2 - NationalNetwork, 3 - NationalNetwork and FiftyThreeFoot */
  readonly ClassOverrides?: InputMaybe<Scalars['Int']>;
  readonly HighwayOnly?: InputMaybe<Scalars['Boolean']>;
  readonly OverrideRestrict?: InputMaybe<Scalars['Boolean']>;
  readonly UseCustomRoadSpeeds?: InputMaybe<Scalars['Boolean']>;
  readonly HubRouting?: InputMaybe<Scalars['Boolean']>;
  readonly GovernorSpeedLimit?: InputMaybe<Scalars['Int']>;
  readonly ElevLimit?: InputMaybe<Scalars['Int']>;
  readonly UseSites?: InputMaybe<Scalars['Boolean']>;
  /** 0 - None (Default), 1 - General, 2 - Caustic, 3 - Explosives, 4 - Flammable, 5 - Inhalants, 6 - Radioactive, 7 - HarmfulToWater, 8 - Tunnel */
  readonly HazMatTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
};

export type PermanentTractorInput = {
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly permanentTractor?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

export type PickupEta = {
  readonly __typename?: 'PickupEta';
  readonly timezone?: Maybe<Scalars['String']>;
};

export type PingHistoryFilters = {
  readonly powerId: Scalars['ID'];
  readonly routeId?: InputMaybe<Scalars['String']>;
  readonly pingEventStartDate?: InputMaybe<Scalars['Date']>;
  readonly pingEventEndDate?: InputMaybe<Scalars['Date']>;
  /** Timezone for the ping event date time. Eg: America/Phoenix, Asia/Calcutta etc. */
  readonly timeZone?: InputMaybe<Scalars['String']>;
};

export type PingSortingOptions = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly orderBy?: InputMaybe<Scalars['String']>;
};

export type PingSortOptions = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly orderBy?: InputMaybe<Scalars['String']>;
};

export type PlanLoadInput = {
  readonly id: Scalars['ID'];
  readonly totalRouteCount: Scalars['Int'];
  /** creates/deletes route based on routes passed, associates stop based on either routeId or routeSequence */
  readonly routeStopAssociation?: InputMaybe<ReadonlyArray<PlanLoadStopInput>>;
};

export type PlanLoadReferenceV2Input = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeId: Scalars['ID'];
  readonly value: Scalars['String'];
  readonly externalRef?: InputMaybe<Scalars['String']>;
  readonly source?: InputMaybe<Scalars['String']>;
};

export type PlanLoadRouteIdentifierV2Input = {
  /** the route's UUID */
  readonly routeId?: InputMaybe<Scalars['String']>;
  /** the route's unique identifier as defined by the caller within this PlanLoad request */
  readonly newRouteKey?: InputMaybe<Scalars['String']>;
};

export type PlanLoadRouteStopIdentifierV2Input = {
  /** when present, the routeStop is defined by the receiver */
  readonly routeStopId?: InputMaybe<Scalars['String']>;
  /** when present, the routeStop is defined by the caller within this routeStop's routeStopInfo */
  readonly newRouteStopKey?: InputMaybe<Scalars['String']>;
  /** when present, the routeStop is defined by another routeStop's routeStopInfo in which the two be matched by this sourceRouteStopKey and the other stop's newRouteStopKey */
  readonly sourceRouteStopKey?: InputMaybe<Scalars['String']>;
  /** when present, the routeStop is defined by another routeStop's routeStopInfo in which the two be matched by this sourceRouteStopId and the other stop's routeStopId */
  readonly sourceRouteStopId?: InputMaybe<Scalars['String']>;
};

export type PlanLoadRouteStopInfoV2Input = {
  readonly facilityId?: InputMaybe<Scalars['String']>;
  readonly type: Scalars['String'];
  readonly address?: InputMaybe<CityStateStopLocationInput>;
  readonly liveType?: InputMaybe<Scalars['String']>;
  readonly workType?: InputMaybe<Scalars['String']>;
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly refs?: InputMaybe<ReadonlyArray<PlanLoadReferenceV2Input>>;
  readonly commodities?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly availableStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly loadStopType?: InputMaybe<Scalars['ID']>;
  readonly loadFromId?: InputMaybe<Scalars['ID']>;
  readonly unloadFromId?: InputMaybe<Scalars['ID']>;
  readonly appointment?: InputMaybe<AppointmentInputV2>;
};

export type PlanLoadRouteStopV2Input = {
  /** identifier that determines how to match the routeStop between the caller and receiver */
  readonly routeStopIdentifier: PlanLoadRouteStopIdentifierV2Input;
  readonly routeStopInfo?: InputMaybe<PlanLoadRouteStopInfoV2Input>;
  readonly deleted?: InputMaybe<Scalars['Boolean']>;
};

export type PlanLoadRouteV2Input = {
  /** identifier for a route that will update an existing route if given a routeId or create a new route if given a newRouteKey */
  readonly routeIdentifier: PlanLoadRouteIdentifierV2Input;
  readonly divisionId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly stops: ReadonlyArray<PlanLoadRouteStopV2Input>;
  readonly deleted?: InputMaybe<Scalars['Boolean']>;
  readonly routeTypeId?: InputMaybe<Scalars['ID']>;
  /** Route Activation Status */
  readonly activationStatusId?: InputMaybe<Scalars['ID']>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly dimensions?: InputMaybe<DimensionsInput>;
  /** Please use cargoInsuranceV2. It supports new currency values */
  readonly cargoInsurance?: InputMaybe<CurrencyInput>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
  readonly tarpType?: InputMaybe<Scalars['ID']>;
  readonly tarpCount?: InputMaybe<Scalars['Int']>;
  readonly braceTypes?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly braceCount?: InputMaybe<Scalars['Int']>;
  readonly cargoInsuranceV2?: InputMaybe<LoadCurrencyInput>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** additionalBusinessUnit is deprecated */
  readonly additionalBusinessUnit?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** businessUnit is deprecated */
  readonly businessUnit?: InputMaybe<Scalars['ID']>;
};

export type PlanLoadStopDescriptionInput = {
  /** the stopId of an existing stop on a planLoad. null if we have to duplicate the stop from another record */
  readonly stopId?: InputMaybe<Scalars['ID']>;
  /** the stopId of an existing stop on a planLoad that needs to be duplicated since it will go on multiple routes. null if we dont need it. */
  readonly duplicateStopId?: InputMaybe<Scalars['ID']>;
  /** the loadStop id that this stop is associated with */
  readonly loadStopId?: InputMaybe<Scalars['ID']>;
  /** indication that stop needs to be removed */
  readonly deleteStop?: InputMaybe<Scalars['Boolean']>;
};

export type PlanLoadStopInput = {
  readonly routeId?: InputMaybe<Scalars['ID']>;
  /** 0 based integer, references route that will be created from existing and totalRouteCount difference */
  readonly routeSequence?: InputMaybe<Scalars['Int']>;
  readonly stopIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** adds context to determine if it needs to be duplicated or associated to multiple routes */
  readonly stops?: InputMaybe<ReadonlyArray<PlanLoadStopDescriptionInput>>;
};

export type PlanLoadV2Input = {
  readonly routes: ReadonlyArray<PlanLoadRouteV2Input>;
  readonly loadId: Scalars['ID'];
};

export type PlannedNetwork = {
  readonly __typename?: 'PlannedNetwork';
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly name: Scalars['String'];
  /** Minion network_type. */
  readonly networkType: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly channels: ReadonlyArray<NetworkChannel>;
};

export type PlannedNetworkDdt = {
  readonly __typename?: 'PlannedNetworkDdt';
  /** The Minion OptionId. */
  readonly id: Scalars['String'];
  /** The Minion OptionName. */
  readonly name?: Maybe<Scalars['String']>;
};

export type PlannedNetworkError = IPlannedNetworkError & {
  readonly __typename?: 'PlannedNetworkError';
  readonly message: Scalars['String'];
  readonly code: Scalars['String'];
};

export enum PlatformNotificationChannels {
  Slack = 'Slack',
  MsTeams = 'MSTeams'
}

export type Point = {
  readonly __typename?: 'Point';
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type PointInput = {
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type PortfolioEdge = {
  readonly __typename?: 'PortfolioEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<CustomerPortfolioEntity>;
};

export type PortfolioInteraction = {
  readonly __typename?: 'PortfolioInteraction';
  readonly contactId?: Maybe<Scalars['ID']>;
  readonly customerInteractionFrequency?: Maybe<Scalars['ID']>;
  readonly interactionDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated This is Deprecated. Use CustomerInteractionFrequency instead. */
  readonly interactionFrequency?: Maybe<CustomerInteractionFrequencyEnum>;
  readonly nextInteractionDate?: Maybe<Scalars['DateTime']>;
  readonly notes?: Maybe<Scalars['String']>;
};

export type PortfolioInteractionColumnFilter = {
  readonly fromDate?: InputMaybe<Scalars['DateTime']>;
  readonly toDate?: InputMaybe<Scalars['DateTime']>;
  readonly overdue?: InputMaybe<Scalars['Boolean']>;
  readonly entityId?: InputMaybe<Scalars['String']>;
  readonly entityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly entityType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly scheduledDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly endDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly location?: InputMaybe<Scalars['String']>;
  readonly objectiveTerm?: InputMaybe<Scalars['String']>;
  readonly objectiveTermV2?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly type?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly viaTerm?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly outcomeTerm?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly opportunity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly contacts?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly rescheduled?: InputMaybe<Scalars['Float']>;
  readonly status?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly originalDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly completedDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly completedBy?: InputMaybe<Scalars['String']>;
  readonly completedByIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly createdByIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly updatedByIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly duration?: InputMaybe<Scalars['Float']>;
};

export type PortfolioInteractionHeaderFilter = {
  readonly fromDate?: InputMaybe<Scalars['DateTime']>;
  readonly toDate?: InputMaybe<Scalars['DateTime']>;
  readonly overdue?: InputMaybe<Scalars['Boolean']>;
};

export type PortfolioInteractionMainFilter = {
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly groupIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainEntityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainObjective?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainVia?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainOutcome?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isCalenderView?: InputMaybe<Scalars['Boolean']>;
};

export type PortfolioPageInfo = {
  readonly __typename?: 'PortfolioPageInfo';
  readonly endCursor?: Maybe<Scalars['String']>;
  readonly hasNextPage?: Maybe<Scalars['Boolean']>;
  readonly hasPreviousPage?: Maybe<Scalars['Boolean']>;
  readonly startCursor?: Maybe<Scalars['String']>;
};

export type PostalCode = {
  readonly __typename?: 'PostalCode';
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly countryCode?: Maybe<Scalars['String']>;
};

export type PostalCodeCountry = {
  readonly __typename?: 'PostalCodeCountry';
  readonly postalCode: Scalars['String'];
  readonly country?: Maybe<Scalars['String']>;
  readonly geoPoint?: Maybe<GeographyServicePoint>;
  readonly sourceId?: Maybe<Scalars['Int']>;
};

export type PostalCodeDetails = {
  readonly __typename?: 'PostalCodeDetails';
  readonly custom?: Maybe<Scalars['Boolean']>;
  readonly lat: Scalars['Float'];
  readonly lon: Scalars['Float'];
  readonly postalCode: Scalars['String'];
  readonly sourceId?: Maybe<Scalars['Int']>;
};

export type PostalCodeGeoPoint = {
  readonly __typename?: 'PostalCodeGeoPoint';
  readonly postalCode: Scalars['String'];
  readonly geoPoint: GeographyServicePoint;
  readonly sourceId?: Maybe<Scalars['Int']>;
};

export type PostalCodeState = {
  readonly __typename?: 'PostalCodeState';
  readonly postalCodeGeoPoints: ReadonlyArray<PostalCodeGeoPoint>;
  readonly state?: Maybe<Scalars['String']>;
  readonly abbreviatedState?: Maybe<Scalars['String']>;
  readonly countryDetails?: Maybe<CountryDetails>;
};

export type PostalCodeStop = {
  readonly __typename?: 'PostalCodeStop';
  readonly postalCode: Scalars['String'];
  readonly countryCode: Scalars['String'];
};

export type PostalPrefix = {
  readonly __typename?: 'PostalPrefix';
  readonly postalPrefix?: Maybe<Scalars['String']>;
  readonly countryCode?: Maybe<Scalars['String']>;
};

export type Posting = {
  readonly __typename?: 'Posting';
  readonly id: Scalars['ID'];
  readonly createdByUser: User;
  readonly createdByUserV2: UserV2;
  readonly createdDate: Scalars['DateTime'];
  readonly updatedByUser?: Maybe<User>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly updatedDate?: Maybe<Scalars['DateTime']>;
  readonly route?: Maybe<LoadRoute>;
  readonly postedRate?: Maybe<Currency>;
  readonly bookItNowRate?: Maybe<Currency>;
  readonly size: UnitOfLengthOutput;
  readonly notes?: Maybe<Scalars['String']>;
  readonly references: ReadonlyArray<Reference>;
  readonly active: Scalars['Boolean'];
  readonly inboundRegionUrn?: Maybe<Scalars['String']>;
  readonly lastAction?: Maybe<Scalars['String']>;
  readonly lastActionBy?: Maybe<User>;
  readonly lastActionByV2?: Maybe<UserV2>;
  readonly lastActionByDate?: Maybe<Scalars['DateTime']>;
  readonly load?: Maybe<Scalars['ID']>;
  readonly hasErrors?: Maybe<Scalars['Boolean']>;
  readonly autoPosted?: Maybe<Scalars['Boolean']>;
};

export type PostingError = {
  readonly __typename?: 'PostingError';
  readonly error: Scalars['String'];
  readonly severity: PostingErrorSeverity;
};

export enum PostingErrorSeverity {
  Error = 'ERROR',
  Warning = 'WARNING'
}

export type PostingsByRouteIdsInput = {
  readonly routeIds: ReadonlyArray<Scalars['ID']>;
};

export type PostingsCsConfigs = {
  readonly __typename?: 'PostingsCSConfigs';
  readonly isBinEnabled: Scalars['Boolean'];
  readonly isTruckstopEnabled: Scalars['Boolean'];
  readonly isTruckertoolsEnabled: Scalars['Boolean'];
  readonly isDatSoapEnabled: Scalars['Boolean'];
};

export type PostingsFilter = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId?: InputMaybe<Scalars['ID']>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly status?: InputMaybe<PostingStatus>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
};

export enum PostingsOperationSource {
  LoadRoute = 'LoadRoute',
  AvailableRoutes = 'AvailableRoutes'
}

export enum PostingSource {
  Dat = 'DAT',
  TruckerTools = 'TruckerTools',
  Truckstop = 'Truckstop'
}

export enum PostingStatus {
  Accepted = 'ACCEPTED',
  Covered = 'COVERED',
  RejectCovered = 'REJECT_COVERED',
  RejectRemoved = 'REJECT_REMOVED',
  Rejected = 'REJECTED',
  Removed = 'REMOVED'
}

export type PowerActiveFleetFilter = {
  readonly carrierId: ReadonlyArray<Scalars['ID']>;
  readonly date?: InputMaybe<Scalars['Date']>;
  readonly serviceStatusTerm?: InputMaybe<Scalars['String']>;
};

export type PowerAutoCompleteFilter = {
  readonly searchTerm?: InputMaybe<Scalars['String']>;
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly date?: InputMaybe<Scalars['Date']>;
  readonly limit?: InputMaybe<Scalars['Int']>;
};

export type PowerAutoCompleteOutput = {
  readonly __typename?: 'PowerAutoCompleteOutput';
  readonly score: Scalars['Float'];
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly displayName?: Maybe<Scalars['String']>;
  /** @deprecated carrierId is deprecated. Use carrier.id instead. */
  readonly carrierId?: Maybe<Scalars['ID']>;
  /** @deprecated carrierName is deprecated. Use carrier.name instead. */
  readonly carrierName?: Maybe<Scalars['String']>;
  /** This field describes the active fleet to which the power belongs to. */
  readonly carrier?: Maybe<CarrierV2>;
};

export type PowerCarrierSearchConnection = {
  readonly __typename?: 'PowerCarrierSearchConnection';
  readonly edges: ReadonlyArray<PowerCarrierSearchOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type PowerCarrierSearchOutput = {
  readonly __typename?: 'PowerCarrierSearchOutput';
  readonly code?: Maybe<Scalars['String']>;
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly powerFleets?: Maybe<ReadonlyArray<PowerFleet>>;
  readonly permanentTrailerCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentDriverCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly serviceStatusTerm?: Maybe<Scalars['String']>;
  readonly isCARBCompliant?: Maybe<Scalars['Boolean']>;
  readonly odometerMiles?: Maybe<Scalars['Float']>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  readonly assetPermanentDrivers?: Maybe<ReadonlyArray<AssetDriver>>;
  readonly assetPermanentTrailers?: Maybe<ReadonlyArray<AssetTrailers>>;
  readonly currentRoute?: Maybe<RouteOutput>;
  readonly nextRoute?: Maybe<RouteOutput>;
};

export type PowerCarrierSearchOutputEdge = {
  readonly __typename?: 'PowerCarrierSearchOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: PowerCarrierSearchOutput;
};

export type PowerDriverOutput = {
  readonly __typename?: 'PowerDriverOutput';
  readonly driverId: Scalars['ID'];
  /** @deprecated driverIdentityId is deprecated. Use code instead. */
  readonly driverIdentityId: Scalars['String'];
  readonly code?: Maybe<Scalars['String']>;
  readonly powerId?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly powerDriverMapping?: Maybe<ReadonlyArray<AssetPowerPermanentDriverOutput>>;
};

export type PowerEquipmentInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId?: InputMaybe<Scalars['String']>;
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly returnDate?: InputMaybe<Scalars['Date']>;
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type PowerEquipmentObjectInput = {
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId?: InputMaybe<Scalars['String']>;
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly returnDate?: InputMaybe<Scalars['Date']>;
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type PowerEquipmentObjectPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly count?: InputMaybe<Scalars['Int']>;
  readonly assetId?: InputMaybe<Scalars['String']>;
  readonly conditionTerm?: InputMaybe<Scalars['String']>;
  readonly issueDate?: InputMaybe<Scalars['Date']>;
  readonly returnDate?: InputMaybe<Scalars['Date']>;
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type PowerEquipmentOutput = {
  readonly __typename?: 'PowerEquipmentOutput';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId?: Maybe<Scalars['String']>;
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly returnDate?: Maybe<Scalars['Date']>;
  readonly powerId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type PowerEquipmentOutputEdge = {
  readonly __typename?: 'PowerEquipmentOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: PowerEquipmentOutput;
};

export type PowerEquipmentPayload = {
  readonly __typename?: 'PowerEquipmentPayload';
  readonly edges: ReadonlyArray<PowerEquipmentOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type PowerFilters = {
  readonly powerId: Scalars['ID'];
  readonly isExpired?: InputMaybe<Scalars['Boolean']>;
};

export type PowerFleet = {
  readonly __typename?: 'PowerFleet';
  readonly id: Scalars['ID'];
  readonly powerId: Scalars['ID'];
  /** This field describes the type of assignment used on the power unit, within the organization. Ex: Primary, Secondary */
  readonly typeTerm: Scalars['String'];
  /**
   * This field describes the effective date of the power's fleet assignment.
   * Format is from RFC 3339, section 5.6 which is YYYY-MM-DD.
   */
  readonly effectiveDate?: Maybe<Scalars['Date']>;
  /**
   * This field describes the expiration date of power's fleet assignment. Format
   * is from RFC 3339, section 5.6 which is YYYY-MM-DD.
   */
  readonly expirationDate?: Maybe<Scalars['Date']>;
  /** This field describes the carrier by which relationship is established. */
  readonly carrier: CarrierV2;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type PowerFleetEdge = {
  readonly __typename?: 'PowerFleetEdge';
  readonly cursor: Scalars['String'];
  readonly node: PowerFleet;
};

export type PowerFleetRelationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  /** This field describes the power unit to which the fleet belongs to. */
  readonly powerId?: InputMaybe<Scalars['ID']>;
  /** This field describes the type of assignment used on the power unit, within the organization. Ex: Primary, Secondary */
  readonly typeTerm: Scalars['String'];
  /**
   * This field describes the effective date of the power's fleet assignment.
   * Format is from RFC 3339, section 5.6 which is YYYY-MM-DD.
   */
  readonly effectiveDate: Scalars['Date'];
  /**
   * This field describes the expiration date of power's fleet assignment. Format
   * is from RFC 3339, section 5.6 which is YYYY-MM-DD.
   */
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  /** This field describes the fleet to which the power unit belongs to. */
  readonly carrierId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

/** This is a Power Fleet Input which we are using while patch Power Fleet relation */
export type PowerFleetRelationshipObjectPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  /** This field describes the type of assignment used on the power unit, within the organization. Ex: Primary, Secondary */
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  /**
   * This field describes the effective date of the power's fleet assignment.
   * Format is from RFC 3339, section 5.6 which is YYYY-MM-DD.
   */
  readonly effectiveDate?: InputMaybe<Scalars['Date']>;
  /**
   * This field describes the expiration date of power's fleet assignment. Format
   * is from RFC 3339, section 5.6 which is YYYY-MM-DD.
   */
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  /** This field describes the fleet to which the power unit belongs to. */
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly carrierName?: InputMaybe<Scalars['ID']>;
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type PowerFleetRelationshipPayload = {
  readonly __typename?: 'PowerFleetRelationshipPayload';
  readonly edges: ReadonlyArray<PowerFleetEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type PowerIdentifierFilters = {
  readonly powerId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type PowerIdentifierInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly value: Scalars['String'];
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly powerId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type PowerIdentifierObjectInput = {
  readonly typeTerm: Scalars['String'];
  readonly value: Scalars['String'];
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly powerId?: InputMaybe<Scalars['ID']>;
};

export type PowerIdentifierObjectPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type PowerIdentifierOutput = {
  readonly __typename?: 'PowerIdentifierOutput';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly value: Scalars['String'];
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly powerId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type PowerIdentifierOutputEdge = {
  readonly __typename?: 'PowerIdentifierOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: PowerIdentifierOutput;
};

export type PowerIdentifierPayload = {
  readonly __typename?: 'PowerIdentifierPayload';
  readonly edges: ReadonlyArray<PowerIdentifierOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** Type for a power location ping including fuel level, heading, speed details, location info, driver details, and tractor ID. */
export type PowerLocationPing = {
  readonly __typename?: 'PowerLocationPing';
  readonly relativeLocationDescription?: Maybe<Scalars['String']>;
  readonly speedValue?: Maybe<Scalars['Float']>;
  readonly speedUnitTerm?: Maybe<Scalars['String']>;
  readonly fuelLevel?: Maybe<Scalars['Float']>;
  readonly heading?: Maybe<Scalars['Float']>;
  readonly tractorID?: Maybe<Scalars['String']>;
  readonly driverID?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverCode?: Maybe<Scalars['String']>;
  readonly driverID2?: Maybe<Scalars['String']>;
  readonly driverName2?: Maybe<Scalars['String']>;
  readonly driverCode2?: Maybe<Scalars['String']>;
};

export type PowerMaintenanceFilters = {
  readonly powerId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type PowerMaintenanceInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly severityTerm: Scalars['String'];
  readonly isPlanned?: InputMaybe<Scalars['Boolean']>;
  readonly location?: InputMaybe<MaintenanceLocationInput>;
  readonly estimatedMaintenanceTime?: InputMaybe<Scalars['Int']>;
  readonly actual?: InputMaybe<DateTimeEntryInput>;
  readonly expected?: InputMaybe<DateTimeEntryInput>;
  readonly workPerformedDetail?: InputMaybe<Scalars['String']>;
  readonly powerId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type PowerMaintenanceLocationOutput = {
  readonly __typename?: 'PowerMaintenanceLocationOutput';
  readonly facilityCode?: Maybe<Scalars['String']>;
  readonly facility?: Maybe<FacilityV2>;
  readonly cityState?: Maybe<CityStateOutput>;
};

export type PowerMaintenanceObjectInput = {
  readonly typeTerm: Scalars['String'];
  readonly severityTerm: Scalars['String'];
  readonly isPlanned?: InputMaybe<Scalars['Boolean']>;
  readonly location?: InputMaybe<MaintenanceLocationInput>;
  readonly estimatedMaintenanceTime?: InputMaybe<Scalars['Int']>;
  readonly actual?: InputMaybe<DateTimeEntryInput>;
  readonly expected?: InputMaybe<DateTimeEntryInput>;
  readonly workPerformedDetail?: InputMaybe<Scalars['String']>;
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type PowerMaintenanceObjectPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly severityTerm?: InputMaybe<Scalars['String']>;
  readonly isPlanned?: InputMaybe<Scalars['Boolean']>;
  readonly location?: InputMaybe<MaintenanceLocationInput>;
  readonly estimatedMaintenanceTime?: InputMaybe<Scalars['Int']>;
  readonly actual?: InputMaybe<DateTimeEntryInput>;
  readonly expected?: InputMaybe<DateTimeEntryInput>;
  readonly workPerformedDetail?: InputMaybe<Scalars['String']>;
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type PowerMaintenanceOutput = {
  readonly __typename?: 'PowerMaintenanceOutput';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly severityTerm: Scalars['String'];
  readonly isPlanned?: Maybe<Scalars['Boolean']>;
  /** @deprecated location is deprecated. Use maintenanceLocation instead. */
  readonly location?: Maybe<MaintenanceLocationOutput>;
  readonly maintenanceLocation?: Maybe<PowerMaintenanceLocationOutput>;
  readonly estimatedMaintenanceTime?: Maybe<Scalars['Int']>;
  readonly actual?: Maybe<DateTimeEntryOutput>;
  readonly expected?: Maybe<DateTimeEntryOutput>;
  readonly workPerformedDetail?: Maybe<Scalars['String']>;
  readonly powerId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type PowerMaintenanceOutputEdge = {
  readonly __typename?: 'PowerMaintenanceOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: PowerMaintenanceOutput;
};

export type PowerMaintenancePayload = {
  readonly __typename?: 'PowerMaintenancePayload';
  readonly edges: ReadonlyArray<PowerMaintenanceOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type PowerPingHistoryOutput = {
  readonly __typename?: 'PowerPingHistoryOutput';
  readonly id: Scalars['ID'];
  /** @deprecated pingEventDateTime is deprecated. Use pingHistoryEventDateTime instead. */
  readonly pingEventDateTime: Scalars['Date'];
  /** @deprecated location is deprecated. Use city and state instead */
  readonly location?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly source: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly pingHistoryEventDateTime: Scalars['DateTime'];
  readonly route?: Maybe<LoadRouteV2>;
};

export type PowerPingHistoryOutputEdge = {
  readonly __typename?: 'PowerPingHistoryOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: PowerPingHistoryOutput;
};

export type PowerPingHistoryPayload = {
  readonly __typename?: 'PowerPingHistoryPayload';
  readonly edges: ReadonlyArray<PowerPingHistoryOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type PowerRouteList = {
  readonly __typename?: 'PowerRouteList';
  /** Route ID, says id for frontend caching purposes, used to hyperlink routeCode */
  readonly id: Scalars['ID'];
  readonly routeCode: Scalars['ID'];
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['String']>;
  readonly loadId: Scalars['ID'];
  readonly customerName?: Maybe<Scalars['String']>;
  /** For hyperlinking customerName */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  /** For hyperlinking originStopFacilityName */
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly routeDistance?: Maybe<Scalars['Float']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  /** For hyperlinking destStopFacilityName */
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  /** For hyperlinking routeCarrierName */
  readonly routeCarrierId?: Maybe<Scalars['String']>;
  readonly mainRouteRep?: Maybe<Scalars['String']>;
  /** For hyperlinking mainRouteRep */
  readonly mainRouteRepId?: Maybe<Scalars['String']>;
  readonly driver1Name?: Maybe<Scalars['String']>;
  /** For hyperlinking driver1Name */
  readonly driver1Id?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  /** For hyperlinking driver2Name */
  readonly driver2Id?: Maybe<Scalars['String']>;
  /** @deprecated Not in requirements for this view */
  readonly powerName?: Maybe<Scalars['String']>;
  /**
   * For hyperlinking powerName
   * @deprecated Not in requirements for this view
   */
  readonly powerId?: Maybe<Scalars['String']>;
  /**
   * Not currently available, domain team working on providing
   * @deprecated Use TrailerIdentifiers1/2/3 as trailerName instead
   */
  readonly trailerName?: Maybe<Scalars['String']>;
  /**
   * For hyperlinking trailerName, Not currently available, domain team working on providing
   * @deprecated Use trailerRecordIds1/2/3 as trailerId instead
   */
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly routeTotalCost?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly routeDistanceUnit?: Maybe<Scalars['String']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly primaryDivision?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly additionalDivision?: Maybe<Scalars['String']>;
  readonly executingDivision?: Maybe<Scalars['String']>;
  readonly inboundTrailerIdentifier1?: Maybe<Scalars['String']>;
  readonly inboundTrailerRecordId1?: Maybe<Scalars['String']>;
  readonly inboundTrailerResourceType1?: Maybe<Scalars['String']>;
  readonly inboundTrailerIdentifier2?: Maybe<Scalars['String']>;
  readonly inboundTrailerRecordId2?: Maybe<Scalars['String']>;
  readonly inboundTrailerResourceType2?: Maybe<Scalars['String']>;
  readonly inboundTrailerIdentifier3?: Maybe<Scalars['String']>;
  readonly inboundTrailerRecordId3?: Maybe<Scalars['String']>;
  readonly inboundTrailerResourceType3?: Maybe<Scalars['String']>;
  readonly outboundTrailerIdentifier1?: Maybe<Scalars['String']>;
  readonly outboundTrailerRecordId1?: Maybe<Scalars['String']>;
  readonly outboundTrailerResourceType1?: Maybe<Scalars['String']>;
  readonly outboundTrailerIdentifier2?: Maybe<Scalars['String']>;
  readonly outboundTrailerRecordId2?: Maybe<Scalars['String']>;
  readonly outboundTrailerResourceType2?: Maybe<Scalars['String']>;
  readonly outboundTrailerIdentifier3?: Maybe<Scalars['String']>;
  readonly outboundTrailerRecordId3?: Maybe<Scalars['String']>;
  readonly outboundTrailerResourceType3?: Maybe<Scalars['String']>;
  readonly loadedTrailerIdentifier1?: Maybe<Scalars['String']>;
  readonly loadedTrailerRecordId1?: Maybe<Scalars['String']>;
  readonly loadedTrailerResourceType1?: Maybe<Scalars['String']>;
  readonly loadedTrailerIdentifier2?: Maybe<Scalars['String']>;
  readonly loadedTrailerRecordId2?: Maybe<Scalars['String']>;
  readonly loadedTrailerResourceType2?: Maybe<Scalars['String']>;
  readonly loadedTrailerIdentifier3?: Maybe<Scalars['String']>;
  readonly loadedTrailerRecordId3?: Maybe<Scalars['String']>;
  readonly loadedTrailerResourceType3?: Maybe<Scalars['String']>;
};

export type PowerRouteListConnection = {
  readonly __typename?: 'PowerRouteListConnection';
  readonly edges: ReadonlyArray<PowerRouteListEdge>;
  readonly pageInfo: PageInfo;
};

export type PowerRouteListEdge = {
  readonly __typename?: 'PowerRouteListEdge';
  readonly node: PowerRouteList;
  readonly cursor: Scalars['String'];
};

export type PowerRouteListFilterInput = {
  readonly powerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly primaryDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly executingDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type PowerRouteSubList = {
  readonly __typename?: 'PowerRouteSubList';
  /** Route ID, says id for frontend caching purposes, used to hyperlink routeCode */
  readonly id: Scalars['ID'];
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['String']>;
  readonly loadId: Scalars['ID'];
  readonly customerName?: Maybe<Scalars['String']>;
  /** For hyperlinking customerName */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  /** For hyperlinking originStopFacilityName */
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly routeDistance?: Maybe<Scalars['Float']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  /** For hyperlinking destStopFacilityName */
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  /** For hyperlinking routeCarrierName */
  readonly routeCarrierId?: Maybe<Scalars['String']>;
  readonly mainRouteRep?: Maybe<Scalars['String']>;
  /** For hyperlinking mainRouteRep */
  readonly mainRouteRepId?: Maybe<Scalars['String']>;
  readonly driver1Name?: Maybe<Scalars['String']>;
  /** For hyperlinking driver1Name */
  readonly driver1Id?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  /** For hyperlinking driver2Name */
  readonly driver2Id?: Maybe<Scalars['String']>;
  /** Not currently available, domain team working on providing */
  readonly trailerName?: Maybe<Scalars['String']>;
  /** For hyperlinking trailerName, Not currently available, domain team working on providing */
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly routeTotalCost?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly routeDistanceUnit?: Maybe<Scalars['String']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly routeSize?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly mainOrderRep?: Maybe<Scalars['String']>;
  /** For hyperlinking mainOrderRep */
  readonly mainOrderRepId?: Maybe<Scalars['String']>;
  readonly powerName?: Maybe<Scalars['String']>;
  /** For hyperlinking powerName */
  readonly powerId?: Maybe<Scalars['String']>;
  readonly primaryDivision?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly additionalDivision?: Maybe<Scalars['String']>;
  readonly executingDivision?: Maybe<Scalars['String']>;
};

export type PowerRouteSubListConnection = {
  readonly __typename?: 'PowerRouteSubListConnection';
  readonly edges: ReadonlyArray<PowerRouteSubListEdge>;
  readonly pageInfo: PageInfo;
};

export type PowerRouteSubListEdge = {
  readonly __typename?: 'PowerRouteSubListEdge';
  readonly node: PowerRouteSubList;
  readonly cursor: Scalars['String'];
};

export type PowerRouteSubListFilterInput = {
  readonly powerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly primaryDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly executingDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type PowerSortingOptions = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly orderBy?: InputMaybe<Scalars['String']>;
};

export type PowerTrailerOutput = {
  readonly __typename?: 'PowerTrailerOutput';
  readonly trailerId: Scalars['ID'];
  /** @deprecated trailerNumber is deprecated. Use code instead. */
  readonly trailerNumber: Scalars['String'];
  readonly code?: Maybe<Scalars['String']>;
  /** @deprecated type is deprecated. Use typeTerm instead. */
  readonly type: Scalars['String'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly powerId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly powerTrailerMapping?: Maybe<ReadonlyArray<AssetPowerPermanentTrailerOutput>>;
};

export type PowerTypeError = {
  readonly __typename?: 'PowerTypeError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly code?: Maybe<Scalars['String']>;
};

export type PowerUnitCurrentNextRoutes = {
  readonly __typename?: 'PowerUnitCurrentNextRoutes';
  readonly powerUnitAssetId: Scalars['ID'];
  readonly currentRoute?: Maybe<LoadRouteV2>;
  readonly nextRoutes?: Maybe<ReadonlyArray<LoadRouteV2>>;
};

export type PowerUnitCurrentNextRoutesQueryByIdsInput = {
  readonly powerUnitAssetIds: ReadonlyArray<Scalars['ID']>;
};

export type PowerUnitFlipInput = {
  /** An array of unique identifiers associated with the route or load recalculation is being requested for. All ids must be of the same type. */
  readonly entityIds: ReadonlyArray<Scalars['ID']>;
  /** The type of identifier being passed in : 'routeId' | 'loadId' cannot be a combination of types. */
  readonly identifierType: Scalars['String'];
};

export type PowerValidation = {
  readonly __typename?: 'PowerValidation';
  readonly hasPowerIdentityId?: Maybe<Scalars['Boolean']>;
};

export type PreferredLanesRegionInput = {
  readonly regionId?: InputMaybe<Scalars['String']>;
  readonly region?: InputMaybe<Scalars['String']>;
  readonly superRegion?: InputMaybe<Scalars['String']>;
  readonly subRegion?: InputMaybe<Scalars['String']>;
  readonly regionSetName?: InputMaybe<Scalars['String']>;
};

export type PreferredLanesRegionOutput = {
  readonly __typename?: 'PreferredLanesRegionOutput';
  readonly regionId?: Maybe<Scalars['String']>;
  readonly region?: Maybe<Scalars['String']>;
  readonly superRegion?: Maybe<Scalars['String']>;
  readonly subRegion?: Maybe<Scalars['String']>;
  readonly regionSetName?: Maybe<Scalars['String']>;
};

export type PreferredNextAvailableInput = {
  readonly dateTime: Scalars['DateTime'];
  readonly location: CityStateInput;
  readonly timezone?: InputMaybe<Scalars['String']>;
  readonly preferenceReasonTerm?: InputMaybe<Scalars['String']>;
};

export type PreferredNextAvailableOutput = {
  readonly __typename?: 'PreferredNextAvailableOutput';
  readonly dateTime: Scalars['DateTime'];
  readonly location: CityStateOutput;
  readonly timezone?: Maybe<Scalars['String']>;
  readonly preferenceReasonTerm?: Maybe<Scalars['String']>;
  readonly nextAvailableLastUpdatedAt?: Maybe<Scalars['DateTime']>;
  readonly nextAvailableLastUpdatedBy?: Maybe<Scalars['String']>;
};

export type Preplan = {
  readonly __typename?: 'Preplan';
  readonly id: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly routeNumber: Scalars['String'];
  readonly deadheadMiles?: Maybe<Scalars['String']>;
  readonly linehaulMiles?: Maybe<Scalars['String']>;
  readonly lastAction: Scalars['String'];
  readonly firstStopCity?: Maybe<Scalars['String']>;
  readonly firstStopState?: Maybe<Scalars['String']>;
  readonly firstStopCcaStartDatetime?: Maybe<Scalars['String']>;
  readonly firstStopCcaEndDatetime?: Maybe<Scalars['String']>;
  readonly lastStopCity?: Maybe<Scalars['String']>;
  readonly lastStopState?: Maybe<Scalars['String']>;
  readonly lastStopCcaStartDatetime?: Maybe<Scalars['String']>;
  readonly lastStopCcaEndDatetime?: Maybe<Scalars['String']>;
  readonly pickupStartDateTime?: Maybe<Scalars['String']>;
  readonly pickupEndDateTime?: Maybe<Scalars['String']>;
  readonly deliveryStartDateTime?: Maybe<Scalars['String']>;
  readonly deliveryEndDateTime?: Maybe<Scalars['String']>;
  readonly orderId: Scalars['ID'];
  readonly orderCode: Scalars['String'];
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly expectedWeight?: Maybe<Scalars['String']>;
  readonly powerId?: Maybe<Scalars['ID']>;
  readonly powerCode?: Maybe<Scalars['String']>;
  readonly carrierId: Scalars['ID'];
  readonly carrierCode: Scalars['String'];
  readonly tractorNumber?: Maybe<Scalars['String']>;
  readonly isRouteCompleted?: Maybe<Scalars['Boolean']>;
  readonly createdByUserId: Scalars['ID'];
  readonly updatedByUserId: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedByV2?: Maybe<UserV2>;
};

export type PreplanDriver = {
  readonly __typename?: 'PreplanDriver';
  readonly preplanId: Scalars['ID'];
  readonly driverId: Scalars['ID'];
  readonly isPrimary: Scalars['Boolean'];
  readonly preplan?: Maybe<Preplan>;
  readonly driver?: Maybe<DriverRecord>;
};

export type PreplansDriverRecordInput = {
  readonly driverId: Scalars['ID'];
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly platformScienceId?: InputMaybe<Scalars['String']>;
  readonly isPrimary?: InputMaybe<Scalars['Boolean']>;
  readonly driverCode?: InputMaybe<Scalars['String']>;
  readonly driverTypeTerm?: InputMaybe<Scalars['String']>;
  readonly driverCompany?: InputMaybe<Scalars['String']>;
  readonly fleetManager?: InputMaybe<Scalars['String']>;
};

/** Holds Price Rate Detail Node Data Info. */
export type PriceDetail = {
  readonly __typename?: 'PriceDetail';
  readonly accessorialTotal?: Maybe<Scalars['Float']>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly fuelSurcharge?: Maybe<Scalars['Float']>;
  readonly fuelSurchargeQuantity?: Maybe<Scalars['Float']>;
  readonly fuelSurchargeRateQualifier?: Maybe<Scalars['String']>;
  readonly fuelSurchargeUnitCharge?: Maybe<Scalars['Float']>;
  readonly highTotalCharge?: Maybe<Scalars['Float']>;
  readonly linehaulCharge?: Maybe<Scalars['Float']>;
  readonly linehaulQuantity?: Maybe<Scalars['Float']>;
  readonly linehaulRateQualifier?: Maybe<Scalars['String']>;
  readonly linehaulUnitCharge?: Maybe<Scalars['Float']>;
  readonly lowTotalCharge?: Maybe<Scalars['Float']>;
  readonly provider?: Maybe<Scalars['String']>;
  readonly smartRate?: Maybe<Scalars['Float']>;
  readonly totalCharge?: Maybe<Scalars['Float']>;
};

/** Holds Price Details Edge Data Info. */
export type PriceDetailEdge = {
  readonly __typename?: 'PriceDetailEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<PriceRateDetail>;
};

/** Holds Werner price details or SNI bin price details. */
export type PriceDetailsConnection = {
  readonly __typename?: 'PriceDetailsConnection';
  readonly code?: Maybe<Scalars['String']>;
  readonly edges?: Maybe<ReadonlyArray<Maybe<PriceDetailsEdge>>>;
  readonly message?: Maybe<Scalars['String']>;
  readonly pageInfo?: Maybe<PageInfo>;
};

/** Holds Price Details Edge Data Info. */
export type PriceDetailsEdge = {
  readonly __typename?: 'PriceDetailsEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<PriceDetail>;
};

export type PriceFtlQuoteCommodityType = {
  readonly code?: InputMaybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly expectedClass?: InputMaybe<Scalars['String']>;
  readonly expectedCube?: InputMaybe<PriceFtlQuoteUnitValueType>;
  readonly expectedDensity?: InputMaybe<PriceFtlQuoteUnitValueType>;
  readonly expectedDimension?: InputMaybe<PricingQuoteDimensionType>;
  readonly expectedLinear?: InputMaybe<PriceFtlQuoteUnitValueType>;
  readonly expectedNMFC?: InputMaybe<Scalars['String']>;
  readonly expectedPieces?: InputMaybe<Scalars['Int']>;
  readonly expectedWeight: PriceFtlQuoteUnitValueType;
  readonly handlingUnit?: InputMaybe<Scalars['String']>;
  readonly hazmat: Scalars['Boolean'];
  readonly hazmatClass?: InputMaybe<Scalars['String']>;
  readonly hazmatPackagingGroup?: InputMaybe<Scalars['String']>;
  readonly isBottomLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly isTopLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly loadOnTerm?: InputMaybe<Scalars['String']>;
  readonly maximumTemperature?: InputMaybe<PriceFtlQuoteUnitValueType>;
  readonly minimumTemperature?: InputMaybe<PriceFtlQuoteUnitValueType>;
  readonly number?: InputMaybe<Scalars['Int']>;
  readonly pieceTypeTerm?: InputMaybe<Scalars['String']>;
  readonly preCoolTo?: InputMaybe<PriceFtlQuoteUnitValueType>;
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly tempControlled: Scalars['Boolean'];
  readonly temperatureSetting?: InputMaybe<Scalars['String']>;
};

export type PriceFtlQuoteEquipmentType = {
  readonly height?: InputMaybe<PriceFtlQuoteUnitValueType>;
  readonly length?: InputMaybe<PriceFtlQuoteUnitValueType>;
  readonly trailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly width?: InputMaybe<PriceFtlQuoteUnitValueType>;
};

export type PriceFtlQuoteInputType = {
  readonly filter: FtlPricesByQuoteInputType;
};

export type PriceFtlQuoteStopType = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly geographyType?: InputMaybe<Scalars['String']>;
  readonly liveType: Scalars['String'];
  readonly region?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly stopCity?: InputMaybe<FtlPricesByQuoteGeographyStopInputType>;
  readonly stopFacility?: InputMaybe<FtlPricesByQuoteFacilityStopInputType>;
  readonly stopPostalCode?: InputMaybe<FtlPricesByQuotePostalCodeStopInputType>;
  readonly stopRequirements?: InputMaybe<Scalars['String']>;
  readonly stopState?: InputMaybe<FtlPricesByQuoteGeographyStopInputType>;
  readonly stopType?: InputMaybe<Scalars['String']>;
  readonly stopWorkTypeTerm?: InputMaybe<Scalars['String']>;
};

export type PriceFtlQuoteUnitValueType = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Decimal'];
};

export type PriceImdlQuoteCommodityType = {
  readonly bottomLoadable: Scalars['Boolean'];
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly doNotStack: Scalars['Boolean'];
  readonly expectedClass?: InputMaybe<Scalars['String']>;
  readonly expectedCube?: InputMaybe<PriceImdlQuoteUnitValueType>;
  readonly expectedDensity?: InputMaybe<PriceImdlQuoteUnitValueType>;
  readonly expectedDimension?: InputMaybe<PriceImdlQuoteDimensionType>;
  readonly expectedLinear?: InputMaybe<PriceImdlQuoteUnitValueType>;
  readonly expectedNMFC?: InputMaybe<Scalars['String']>;
  readonly expectedPieces?: InputMaybe<Scalars['Int']>;
  readonly expectedWeight: PriceImdlQuoteUnitValueType;
  readonly handlingUnit?: InputMaybe<Scalars['String']>;
  readonly hazmat: Scalars['Boolean'];
  readonly hazmatClass?: InputMaybe<Scalars['String']>;
  readonly hazmatPackagingGroup?: InputMaybe<Scalars['String']>;
  readonly loadOn?: InputMaybe<Scalars['String']>;
  readonly maximumTemperature?: InputMaybe<PriceImdlQuoteUnitValueType>;
  readonly minimumTemperature?: InputMaybe<PriceImdlQuoteUnitValueType>;
  readonly number?: InputMaybe<Scalars['Int']>;
  readonly overDimensional: Scalars['Boolean'];
  readonly pieceType?: InputMaybe<Scalars['String']>;
  readonly preCoolTo?: InputMaybe<PriceImdlQuoteUnitValueType>;
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly tempControlled: Scalars['Boolean'];
  readonly temperatureSetting?: InputMaybe<Scalars['String']>;
  readonly topLoadable: Scalars['Boolean'];
};

export type PriceImdlQuoteDimensionType = {
  readonly height?: InputMaybe<PriceImdlQuoteUnitValueType>;
  readonly length?: InputMaybe<PriceImdlQuoteUnitValueType>;
  readonly width?: InputMaybe<PriceImdlQuoteUnitValueType>;
};

export type PriceImdlQuoteEquipmentType = {
  readonly height?: InputMaybe<PriceImdlQuoteUnitValueType>;
  readonly length?: InputMaybe<PriceImdlQuoteUnitValueType>;
  readonly trailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly width?: InputMaybe<PriceImdlQuoteUnitValueType>;
};

export type PriceImdlQuoteStopType = {
  readonly geographyType: Scalars['String'];
  readonly liveType: Scalars['String'];
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly stopCity?: InputMaybe<ImdlPricesByQuoteGeographyStopInputType>;
  readonly stopCountry?: InputMaybe<Scalars['String']>;
  readonly stopFacility?: InputMaybe<ImdlPricesByQuoteFacilityStopInputType>;
  readonly stopPostalCode?: InputMaybe<ImdlPricesByQuotePostalCodeStopInputType>;
  readonly stopRegion?: InputMaybe<ImdlPricesByQuoteRegionStopInputType>;
  readonly stopState?: InputMaybe<ImdlPricesByQuoteGeographyStopInputType>;
  readonly stopType: Scalars['String'];
};

export type PriceImdlQuoteUnitValueType = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Decimal'];
};

export type PriceLtlQuoteCommodityType = {
  readonly bottomLoadable: Scalars['Boolean'];
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly doNotStack: Scalars['Boolean'];
  readonly expectedClass?: InputMaybe<Scalars['String']>;
  readonly expectedCube?: InputMaybe<PriceLtlQuoteUnitValueType>;
  readonly expectedDensity?: InputMaybe<PriceLtlQuoteUnitValueType>;
  readonly expectedDimension?: InputMaybe<PriceLtlQuoteDimensionType>;
  readonly expectedLinear?: InputMaybe<PriceLtlQuoteUnitValueType>;
  readonly expectedNMFC?: InputMaybe<Scalars['String']>;
  readonly expectedPieces?: InputMaybe<Scalars['Int']>;
  readonly expectedWeight: PriceLtlQuoteUnitValueType;
  readonly handlingUnit?: InputMaybe<Scalars['String']>;
  readonly hazmat: Scalars['Boolean'];
  readonly hazmatClass?: InputMaybe<Scalars['String']>;
  readonly hazmatPackagingGroup?: InputMaybe<Scalars['String']>;
  readonly hazmatUnitedNationsNumber?: InputMaybe<Scalars['String']>;
  readonly loadOn?: InputMaybe<Scalars['String']>;
  readonly maximumTemperature?: InputMaybe<PriceLtlQuoteUnitValueType>;
  readonly minimumTemperature?: InputMaybe<PriceLtlQuoteUnitValueType>;
  readonly number?: InputMaybe<Scalars['Int']>;
  readonly overDimensional: Scalars['Boolean'];
  readonly pieceType?: InputMaybe<Scalars['String']>;
  readonly preCoolTo?: InputMaybe<PriceLtlQuoteUnitValueType>;
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly tempControlled: Scalars['Boolean'];
  readonly temperatureSetting?: InputMaybe<Scalars['String']>;
  readonly topLoadable: Scalars['Boolean'];
};

export type PriceLtlQuoteDimensionType = {
  readonly height?: InputMaybe<PriceLtlQuoteUnitValueType>;
  readonly length?: InputMaybe<PriceLtlQuoteUnitValueType>;
  readonly width?: InputMaybe<PriceLtlQuoteUnitValueType>;
};

export type PriceLtlQuoteEquipmentType = {
  readonly height?: InputMaybe<PriceLtlQuoteUnitValueType>;
  readonly length?: InputMaybe<PriceLtlQuoteUnitValueType>;
  readonly trailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly width?: InputMaybe<PriceLtlQuoteUnitValueType>;
};

export type PriceLtlQuoteInputType = {
  readonly filter: LtlPricesByQuoteInput;
};

export type PriceLtlQuoteStopType = {
  readonly geographyType: Scalars['String'];
  readonly liveType: Scalars['String'];
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly stopCity?: InputMaybe<LtlPricesByQuoteGeographyStopInputType>;
  readonly stopCountry?: InputMaybe<Scalars['String']>;
  readonly stopFacility?: InputMaybe<LtlPricesByQuoteFacilityStopInputType>;
  readonly stopPostalCode?: InputMaybe<LtlPricesByQuotePostalCodeStopInputType>;
  readonly stopState?: InputMaybe<LtlPricesByQuoteGeographyStopInputType>;
  readonly stopType?: InputMaybe<Scalars['String']>;
};

export type PriceLtlQuoteUnitValueType = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Decimal'];
};

/** Holds Price Rate Detail Node Data Info. */
export type PriceRateDetail = {
  readonly __typename?: 'PriceRateDetail';
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly fuelSurcharge?: Maybe<Scalars['Float']>;
  readonly fuelSurchargeQuantity?: Maybe<Scalars['Float']>;
  readonly fuelSurchargeRateQualifier?: Maybe<Scalars['String']>;
  readonly fuelSurchargeUnitCharge?: Maybe<Scalars['Float']>;
  readonly linehaulCharge?: Maybe<Scalars['Float']>;
  readonly linehaulQuantity?: Maybe<Scalars['Float']>;
  readonly linehaulRateQualifier?: Maybe<Scalars['String']>;
  readonly linehaulUnitCharge?: Maybe<Scalars['Float']>;
  readonly loadBoardCode?: Maybe<Scalars['String']>;
  readonly loadBoardDescription?: Maybe<Scalars['String']>;
  readonly rank?: Maybe<Scalars['Int']>;
  readonly rankingFactors?: Maybe<ReadonlyArray<Maybe<RankingFactor>>>;
  readonly totalCharge?: Maybe<Scalars['Float']>;
};

export type PriceRequestFilter = {
  readonly accessorials?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly callDatHistoric?: InputMaybe<Scalars['Boolean']>;
  readonly carrierCode?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly destinationCity?: InputMaybe<Scalars['String']>;
  readonly destinationCountry?: InputMaybe<Scalars['String']>;
  readonly destinationLatitude?: InputMaybe<Scalars['Float']>;
  readonly destinationLongitude?: InputMaybe<Scalars['Float']>;
  readonly destinationState?: InputMaybe<Scalars['String']>;
  readonly destinationZip?: InputMaybe<Scalars['String']>;
  readonly equipType?: InputMaybe<Scalars['String']>;
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly originCity?: InputMaybe<Scalars['String']>;
  readonly originCountry?: InputMaybe<Scalars['String']>;
  readonly originLatitude?: InputMaybe<Scalars['Float']>;
  readonly originLongitude?: InputMaybe<Scalars['Float']>;
  readonly originState?: InputMaybe<Scalars['String']>;
  readonly originZip?: InputMaybe<Scalars['String']>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
};

/** Pricing errors. */
export type PricingErrorType = {
  readonly __typename?: 'PricingErrorType';
  readonly code?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
};

export type PricingInfo = {
  readonly __typename?: 'PricingInfo';
  readonly pickupDate?: Maybe<Scalars['DateTime']>;
  readonly deliveryDate?: Maybe<Scalars['DateTime']>;
  readonly totalPrice?: Maybe<Scalars['Decimal']>;
  readonly availability?: Maybe<Scalars['Boolean']>;
  readonly transitDays?: Maybe<Scalars['Int']>;
  readonly quoteId?: Maybe<Scalars['String']>;
};

export type PricingProgramConnection = {
  readonly __typename?: 'PricingProgramConnection';
  readonly edges: ReadonlyArray<PricingProgramEdge>;
  readonly nodes: ReadonlyArray<PricingProgramV2>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type PricingProgramEdge = {
  readonly __typename?: 'PricingProgramEdge';
  readonly node: PricingProgramV2;
  readonly cursor: Scalars['String'];
};

export type PricingProgramFakV2 = {
  readonly __typename?: 'PricingProgramFakV2';
  readonly startClass?: Maybe<Scalars['String']>;
  readonly endClass?: Maybe<Scalars['String']>;
  readonly ratedAsClass?: Maybe<Scalars['String']>;
};

export type PricingProgramFilter = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly carrierCode?: InputMaybe<Scalars['String']>;
  readonly contractName?: InputMaybe<Scalars['String']>;
  readonly rateType?: InputMaybe<Scalars['String']>;
  readonly serviceLevel?: InputMaybe<Scalars['String']>;
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>;
  readonly activeUtcDate?: InputMaybe<Scalars['DateTime']>;
};

export type PricingProgramFilterV2 = {
  readonly activeFilter?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly carrierCode?: InputMaybe<Scalars['String']>;
  readonly contractName?: InputMaybe<Scalars['String']>;
  readonly rateType?: InputMaybe<Scalars['String']>;
  readonly serviceLevel?: InputMaybe<Scalars['String']>;
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>;
  readonly activeUtcDate?: InputMaybe<Scalars['DateTime']>;
};

export type PricingProgramLocation = {
  readonly __typename?: 'PricingProgramLocation';
  readonly facilityId?: Maybe<Scalars['ID']>;
  readonly facilityCode?: Maybe<Scalars['String']>;
  readonly postal?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly region?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
};

export type PricingProgramPerUnit = {
  readonly __typename?: 'PricingProgramPerUnit';
  readonly rateUnitOfMeasure?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly minimum?: Maybe<Scalars['Int']>;
  readonly breakStart?: Maybe<Scalars['Int']>;
  readonly breakEnd?: Maybe<Scalars['Int']>;
  readonly rate?: Maybe<Scalars['Decimal']>;
  readonly freightClassStart?: Maybe<Scalars['Decimal']>;
  readonly freightClassEnd?: Maybe<Scalars['Decimal']>;
};

export type PricingProgramTariff = {
  readonly __typename?: 'PricingProgramTariff';
  readonly currency?: Maybe<Scalars['String']>;
  readonly faks: ReadonlyArray<Maybe<PricingProgramFakV2>>;
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly discount?: Maybe<Scalars['Decimal']>;
  readonly minimum?: Maybe<Scalars['Decimal']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly minimumDiscount?: Maybe<Scalars['Decimal']>;
  readonly weightBreakStart?: Maybe<Scalars['Int']>;
  readonly weightBreakEnd?: Maybe<Scalars['Int']>;
};

export type PricingProgramUploadEventPayload = {
  readonly __typename?: 'PricingProgramUploadEventPayload';
  readonly status?: Maybe<Scalars['String']>;
  readonly workbookId?: Maybe<Scalars['String']>;
};

export type PricingProgramV2 = {
  readonly __typename?: 'PricingProgramV2';
  readonly id: Scalars['ID'];
  readonly contractName?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly customerCode?: Maybe<Scalars['String']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly carrierCode?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly rateType?: Maybe<Scalars['String']>;
  readonly serviceLevel?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly origin?: Maybe<PricingProgramLocation>;
  readonly destination?: Maybe<PricingProgramLocation>;
  readonly tariffData?: Maybe<ReadonlyArray<Maybe<PricingProgramTariff>>>;
  readonly perUnitData?: Maybe<ReadonlyArray<Maybe<PricingProgramPerUnit>>>;
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
};

export type PricingQuoteCommodityType = {
  readonly code?: InputMaybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly expectedClass?: InputMaybe<Scalars['String']>;
  readonly expectedCube?: InputMaybe<PricingQuoteUnitValueType>;
  readonly expectedDensity?: InputMaybe<PricingQuoteUnitValueType>;
  readonly expectedDimension?: InputMaybe<PricingQuoteDimensionType>;
  readonly expectedLinear?: InputMaybe<PricingQuoteUnitValueType>;
  readonly expectedNMFC?: InputMaybe<Scalars['String']>;
  readonly expectedPieces?: InputMaybe<Scalars['Int']>;
  readonly expectedWeight: PricingQuoteUnitValueType;
  readonly hazmatClass?: InputMaybe<Scalars['String']>;
  readonly hazmatPackagingGroup?: InputMaybe<Scalars['String']>;
  readonly isBottomLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTemperatureControlled?: InputMaybe<Scalars['Boolean']>;
  readonly isTopLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly loadOnType?: InputMaybe<Scalars['String']>;
  readonly maximumTemperature?: InputMaybe<PricingQuoteUnitValueType>;
  readonly minimumTemperature?: InputMaybe<PricingQuoteUnitValueType>;
  readonly packagingCount?: InputMaybe<Scalars['Int']>;
  readonly packagingType?: InputMaybe<Scalars['String']>;
  readonly preCoolTo?: InputMaybe<PricingQuoteUnitValueType>;
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly temperatureSetting?: InputMaybe<Scalars['String']>;
};

/** Holds Generic Pricing info. */
export type PricingQuoteConnection = {
  readonly __typename?: 'PricingQuoteConnection';
  readonly code?: Maybe<Scalars['String']>;
  readonly edges?: Maybe<ReadonlyArray<Maybe<PricingQuoteEdgeType>>>;
  readonly message?: Maybe<Scalars['String']>;
};

export type PricingQuoteDimensionType = {
  readonly height?: InputMaybe<PricingQuoteUnitValueType>;
  readonly length?: InputMaybe<PricingQuoteUnitValueType>;
  readonly width?: InputMaybe<PricingQuoteUnitValueType>;
};

/** Holds Generic Pricing info. */
export type PricingQuoteEdgeType = {
  readonly __typename?: 'PricingQuoteEdgeType';
  readonly node?: Maybe<PricingQuoteNodeType>;
};

export type PricingQuoteFilterType = {
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly commodities?: InputMaybe<ReadonlyArray<PricingQuoteCommodityType>>;
  readonly destination: PricingQuoteStopType;
  readonly dimension?: InputMaybe<PricingQuoteDimensionType>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly equipments: ReadonlyArray<Scalars['String']>;
  readonly mode: Scalars['String'];
  readonly origin: PricingQuoteStopType;
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly SCAC?: InputMaybe<Scalars['String']>;
  readonly size: Scalars['String'];
};

export type PricingQuoteInputType = {
  readonly filter: PricingQuoteFilterType;
};

/** Holds Generic Pricing info. */
export type PricingQuoteNodeType = {
  readonly __typename?: 'PricingQuoteNodeType';
  readonly displayDescription?: Maybe<Scalars['String']>;
  readonly displayOrder?: Maybe<Scalars['String']>;
  readonly displayValue?: Maybe<Scalars['String']>;
};

export type PricingQuoteStopType = {
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly region?: InputMaybe<Scalars['String']>;
  readonly state: Scalars['String'];
};

export type PricingQuoteUnitValueType = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Decimal'];
};

/** Holds Generic Pricing info. */
export type PricingRouteConnection = {
  readonly __typename?: 'PricingRouteConnection';
  readonly code?: Maybe<Scalars['String']>;
  readonly edges?: Maybe<ReadonlyArray<Maybe<PricingRouteEdgeType>>>;
  readonly message?: Maybe<Scalars['String']>;
};

/** Holds Generic Pricing info. */
export type PricingRouteEdgeType = {
  readonly __typename?: 'PricingRouteEdgeType';
  readonly node?: Maybe<PricingRouteNodeType>;
};

export type PricingRouteFilterType = {
  readonly carrierCode?: InputMaybe<Scalars['String']>;
  readonly routeId: Scalars['Uuid'];
};

export type PricingRouteInputType = {
  readonly filter: PricingRouteFilterType;
};

/** Holds Generic Pricing info. */
export type PricingRouteNodeType = {
  readonly __typename?: 'PricingRouteNodeType';
  readonly displayDescription?: Maybe<Scalars['String']>;
  readonly displayOrder?: Maybe<Scalars['String']>;
  readonly displayValue?: Maybe<Scalars['String']>;
};

export type PriorityConfigurationCreateInput = {
  readonly chargeTypeId: Scalars['Guid'];
  readonly customerCarrier: Scalars['Int'];
  readonly division: Scalars['Int'];
  readonly mode: Scalars['Int'];
  readonly size: Scalars['Int'];
};

export type PriorityConfigurationResponse = {
  readonly __typename?: 'PriorityConfigurationResponse';
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['DateTime'];
  readonly isActive: Scalars['Boolean'];
  readonly isCredit: Scalars['Boolean'];
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn: Scalars['DateTime'];
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: Maybe<Scalars['String']>;
  readonly customerCarrier: Scalars['Int'];
  readonly description?: Maybe<Scalars['String']>;
  readonly division: Scalars['Int'];
  readonly id: Scalars['ID'];
  readonly mode: Scalars['Int'];
  readonly size: Scalars['Int'];
};

export type PriorityConfigurationUpdateInput = {
  readonly chargeTypeId: Scalars['Guid'];
  readonly customerCarrier: Scalars['Int'];
  readonly division: Scalars['Int'];
  readonly id: Scalars['ID'];
  readonly mode: Scalars['Int'];
  readonly size: Scalars['Int'];
};

export enum ProcessFinancialsEnum {
  /** Process Route Vendors with financials Enabled to GL Integration. */
  Enabled = 'Enabled',
  /** Process Route Vendors with financials Disabled without sending to GL Integration */
  Disabled = 'Disabled',
  /** Process Route Vendors with financials Enabled and Disabled. */
  Both = 'Both'
}

export type ProcurementAssetDriver = {
  readonly __typename?: 'ProcurementAssetDriver';
  readonly id: Scalars['ID'];
  readonly assetDriverTenders?: Maybe<ReadonlyArray<Maybe<AssetDriverTender>>>;
  readonly code?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  readonly program?: Maybe<Scalars['String']>;
  readonly facilityPreferences?: Maybe<ReadonlyArray<AssetDriverFacilityPreferences>>;
  readonly customerPreferences?: Maybe<ReadonlyArray<AssetDriverCustomerPreferences>>;
  readonly geographyPreferences?: Maybe<ReadonlyArray<AssetDriverGeographyPreferences>>;
  readonly lanePreferences?: Maybe<ReadonlyArray<AssetDriverPreferredLanes>>;
  readonly assetDriverSchedules?: Maybe<ReadonlyArray<AssetDriverSchedule>>;
  readonly assetDriverTimeOff?: Maybe<ReadonlyArray<AssetDriverTimeOff>>;
  readonly procurementAssetDriverTenders?: Maybe<ReadonlyArray<ProcurementAssetDriverTender>>;
  readonly hosDefaultTimeZone?: Maybe<Scalars['String']>;
  readonly hosRuleTerm?: Maybe<Scalars['String']>;
};

export type ProcurementAssetDriverHos = {
  readonly __typename?: 'ProcurementAssetDriverHos';
  readonly id: Scalars['ID'];
  readonly driverId: Scalars['String'];
  readonly latestHosEvent: ProcurementAssetDriverHosEvent;
  readonly computedCurrentHosClocks: ComputedCurrentHosClocks;
  readonly createdAtDate: Scalars['DateTime'];
  readonly updatedAtDate: Scalars['DateTime'];
};

export type ProcurementAssetDriverHosEvent = {
  readonly __typename?: 'ProcurementAssetDriverHosEvent';
  readonly id: Scalars['ID'];
  readonly driveClock: HoursOfServiceClock;
  readonly shiftClock: HoursOfServiceClock;
  readonly cycleClock: HoursOfServiceClock;
  readonly breakClock: HoursOfServiceClock;
  readonly latestDutyStatusTerm: Scalars['String'];
  readonly latestDutyStatusChangeDate: Scalars['DateTime'];
  readonly hosRuleTerm: Scalars['String'];
};

export type ProcurementAssetDriverHosEventInput = {
  readonly id: Scalars['ID'];
  readonly driveClock: HoursOfServiceClockInput;
  readonly shiftClock: HoursOfServiceClockInput;
  readonly cycleClock: HoursOfServiceClockInput;
  readonly breakClock: HoursOfServiceClockInput;
  readonly latestDutyStatusTerm: Scalars['String'];
  readonly latestDutyStatusChangeDate: Scalars['DateTime'];
  readonly hosRuleTerm: Scalars['String'];
};

export type ProcurementAssetDriverTender = {
  readonly __typename?: 'ProcurementAssetDriverTender';
  readonly id: Scalars['ID'];
  readonly driverTenderStatus?: Maybe<Scalars['String']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['String']>;
};

export enum ProcurementCountBy {
  Pickup = 'PICKUP',
  Delivery = 'DELIVERY'
}

export type ProcurementCountFilter = {
  readonly by?: ProcurementCountBy;
  readonly routeActivationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly ownerId?: InputMaybe<Scalars['String']>;
  readonly containerProgram?: InputMaybe<Scalars['String']>;
  readonly capacityNeed?: InputMaybe<ReadonlyArray<CapacityNeed>>;
  readonly dateRange: DateRangeInput;
  readonly divisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns results where the employee's primary divsion matches a route's primary or additional divisions */
  readonly primaryEmployeeDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Returns results where the employee's additional divsions match a route's primary division */
  readonly additionalEmployeeDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeOrganizationHierarchyCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type ProcurementCounts = {
  readonly __typename?: 'ProcurementCounts';
  readonly area: Scalars['String'];
  readonly point: Point;
  readonly routeCount: Scalars['Int'];
  readonly truckCount: Scalars['Int'];
  readonly containerCount?: Maybe<Scalars['Int']>;
  readonly gateReservationCount?: Maybe<Scalars['Int']>;
};

export type Project = {
  readonly __typename?: 'Project';
  readonly id?: Maybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
};

export type ProjectBooleanError = {
  readonly __typename?: 'ProjectBooleanError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type ProjectBooleanPayload = {
  readonly __typename?: 'ProjectBooleanPayload';
  readonly errors: ReadonlyArray<ProjectBooleanError>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
};

export type ProjectEdge = {
  readonly __typename?: 'ProjectEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<Project>;
};

export type ProjectedHosClocks = {
  readonly __typename?: 'ProjectedHosClocks';
  /** Drive minutes remaining until rest is required */
  readonly driveClock?: Maybe<Scalars['Int']>;
  /** Drive minutes remaining until break is required */
  readonly breakClock?: Maybe<Scalars['Int']>;
  /** Work minutes remaining until rest is required */
  readonly shiftClock?: Maybe<Scalars['Int']>;
  /** Cycle minutes remaining */
  readonly cycleClock?: Maybe<Scalars['Int']>;
};

export type ProjectNameError = IProjectNameError & {
  readonly __typename?: 'ProjectNameError';
  readonly message: Scalars['String'];
  readonly code: Scalars['String'];
};

export type ProjectsFilterArgs = {
  readonly name: Scalars['String'];
};

export type ProjectsList = {
  readonly __typename?: 'ProjectsList';
  readonly edges?: Maybe<ReadonlyArray<ProjectEdge>>;
  readonly pageInfo: PageInfoDetails;
  readonly totalCount: Scalars['Float'];
  readonly errors?: Maybe<ReadonlyArray<ProjectUserError>>;
};

export type ProjectUserError = {
  readonly __typename?: 'ProjectUserError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type PropertyMetadata = {
  readonly __typename?: 'PropertyMetadata';
  readonly name?: Maybe<Scalars['String']>;
  readonly isValid: Scalars['Boolean'];
  readonly isVisible: Scalars['Boolean'];
  readonly isEnabled: Scalars['Boolean'];
  readonly isReadOnly: Scalars['Boolean'];
  readonly errors?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type ProviderGroupResponse = {
  readonly __typename?: 'ProviderGroupResponse';
  readonly groupName?: Maybe<Scalars['String']>;
  readonly providers?: Maybe<ReadonlyArray<ProvidersItemObject>>;
};

/** Provider history object type */
export type ProviderHistory = {
  readonly __typename?: 'ProviderHistory';
  readonly _id: Scalars['String'];
  readonly user_name?: Maybe<Scalars['String']>;
  readonly provider: Scalars['String'];
  readonly action: Scalars['String'];
  readonly _ts: Scalars['String'];
};

export type ProviderIntegrationResponse = {
  readonly __typename?: 'ProviderIntegrationResponse';
  readonly providerId?: Maybe<Scalars['String']>;
  readonly isAuthenticated?: Maybe<Scalars['Boolean']>;
  readonly statusOn?: Maybe<Scalars['Boolean']>;
  readonly providerName?: Maybe<Scalars['String']>;
  readonly isTestDisabled?: Maybe<Scalars['Boolean']>;
  readonly skipAuthentication?: Maybe<Scalars['Boolean']>;
  readonly linkedProviderId?: Maybe<Scalars['String']>;
  readonly webhookURLs?: Maybe<ReadonlyArray<WeebhookUrlObject>>;
  readonly credentials?: Maybe<ReadonlyArray<CredentialAuthenticationItemObject>>;
};

/** Provider response model */
export type ProviderResponse = {
  readonly __typename?: 'ProviderResponse';
  readonly providerId: Scalars['String'];
  readonly providerName: Scalars['String'];
  readonly isAuthenticated: Scalars['Boolean'];
  readonly statusOn: Scalars['Boolean'];
  readonly credentials?: Maybe<ReadonlyArray<Maybe<Credential>>>;
};

export type ProvidersItemObject = {
  readonly __typename?: 'ProvidersItemObject';
  readonly authenticationMethod?: Maybe<Scalars['String']>;
  readonly providerId?: Maybe<Scalars['String']>;
  readonly providerName?: Maybe<Scalars['String']>;
  readonly statusOn?: Maybe<Scalars['Boolean']>;
  readonly validationCurl?: Maybe<Scalars['String']>;
  readonly credentials?: Maybe<ReadonlyArray<CredentialAuthenticationItemObject>>;
  readonly isAuthenticated?: Maybe<Scalars['String']>;
};

/** The root type for all read token operations. */
export type Query = {
  readonly __typename?: 'Query';
  /** mastery-accounting: rateDetails - Retrieves a list of rate details. */
  readonly rateDetails: ReadonlyArray<Maybe<RateDetail>>;
  /** mastery-accounting: costDetails - Retrieves a list of cost details. */
  readonly costDetails: ReadonlyArray<Maybe<CostDetail>>;
  /** mastery-accounting: customerSettlementQueue - Retrieves a list of open orders. */
  readonly customerSettlementQueue?: Maybe<OrderConnection>;
  /** mastery-accounting: vendorInvoiceQueue - Retrieves Vendor Invoices. */
  readonly vendorInvoiceQueue?: Maybe<VendorInvoiceConnection>;
  /** mastery-accounting: voucherBatches - Retrieves pending voucher batches. */
  readonly voucherBatches: ReadonlyArray<Maybe<VoucherBatch>>;
  /** mastery-accounting: invoiceBatches - Retrieves pending invoice batches. */
  readonly invoiceBatches: ReadonlyArray<Maybe<InvoiceBatch>>;
  /**
   * mastery-accounting: getChargeTypeById - Retrieves chargeType by Id -- This is required for Apollo Federation (chargeType is an exposed type)
   * @deprecated This should not be used as we are transition to Charge Type Configuration service: Use getGlobalTenantChargeTypeConfiguration
   */
  readonly getChargeTypeById: ChargeType;
  /** mastery-accounting: vendorSettlementQueue - Retrieves a list of open costs. */
  readonly vendorSettlementQueue?: Maybe<RouteVendorCostConnection>;
  /** mastery-accounting: advance - Retrieves a specific Advance based on the id. */
  readonly getAdvanceByIdV2Async: AdvanceV2;
  /** mastery-accounting: advances - Retrieves a list of advances based on the filter parameter. */
  readonly getFilteredAdvancesAsync?: Maybe<AdvanceV2Connection>;
  /** mastery-accounting: advanceInterfaces - Retrieves the configured Advance Interfaces for an Advance Provider */
  readonly getAdvanceInterfacesByProviderAsync: ReadonlyArray<AdvanceInterfaceV2>;
  /**
   * mastery-accounting: routeMaxCost - Retrieves the max cost for the specified route/load
   * @deprecated This query can potentially return null; Use routeMaxCostNullable instead.
   */
  readonly routeMaxCost: RouteMaxCostType;
  /** mastery-accounting: routeMaxCost - Retrieves the max cost for the specified route/load */
  readonly routeMaxCostNullable?: Maybe<RouteMaxCostType>;
  /** mastery-accounting: customerCreditMemo - Retrieves a customer credit memo by invoice id. */
  readonly customerCreditMemo: CustomerCreditMemo;
  /** mastery-accounting: vendorCreditMemo - Retrieves a vendor credit memo by voucher id. */
  readonly vendorCreditMemo: VendorCreditMemo;
  /** mastery-accounting: voucherTolerances - Retrieves a list of voucher tolerance settings. */
  readonly voucherTolerances?: Maybe<ReadonlyArray<Maybe<VoucherTolerance>>>;
  /** mastery-accounting: vendorRequiredDocuments - Retrieves a list of vendor required document settings. */
  readonly vendorRequiredDocuments?: Maybe<ReadonlyArray<Maybe<VendorRequiredDocument>>>;
  /** mastery-accounting: vendorAssociatedRequiredDocuments - Retrieves a list of vendor associated required document settings. */
  readonly vendorAssociatedRequiredDocument?: Maybe<ReadonlyArray<Maybe<VendorAssociatedRequiredDocument>>>;
  /** mastery-accounting: routeVendorMaxCostValidation - Retrieves validation errors for re-adding debounced vendor. */
  readonly routeVendorMaxCostValidation?: Maybe<RouteVendorMaxCostValidation>;
  /** mastery-accounting: customerRequiredDocuments - Retrieves a list of customer required document settings by a customer id. */
  readonly customerRequiredDocuments?: Maybe<ReadonlyArray<Maybe<CustomerRequiredDocument>>>;
  /** mastery-accounting: customerRequiredDocumentsGlobalConfiguration - Retrieves a list of global configuration customer required document settings . */
  readonly customerRequiredDocumentsGlobalConfiguration?: Maybe<ReadonlyArray<Maybe<CustomerRequiredDocumentsGlobalConfiguration>>>;
  /**
   * mastery-accounting: getOrphanedEntities - This query is only intended to be used during the interim until Kafka is fully implemented
   * @deprecated This query should not be used; it is only intended for diagnosing orphaned records while Kafka is being built out.
   */
  readonly getOrphanedEntities?: Maybe<OrphanedEntitiesModel>;
  /**
   * mastery-accounting: customerInvoices - Retrieves a filtered list of customer invoices.
   * @deprecated Use customerInvoicesQueue instead
   */
  readonly customerInvoices?: Maybe<ReadonlyArray<Maybe<InvoiceHeader>>>;
  /** mastery-accounting: customerInvoicesQueue - Retrieves a paginated filtered list of customer invoices. */
  readonly customerInvoicesQueue?: Maybe<InvoiceHeaderConnection>;
  /** mastery-accounting: getAdvanceProviderConfigAsync - retrieves an advance provider config. */
  readonly getAdvanceProviderConfigAsync?: Maybe<AdvanceProviderConfigTypeV2>;
  /** mastery-accounting: getAllAdvanceProviderConfigsAsync - retrieves all advance provider configs for the tenant. */
  readonly getAllAdvanceProviderConfigsAsync?: Maybe<ReadonlyArray<Maybe<AdvanceProviderConfigTypeV2>>>;
  /**
   * mastery-accounting: vendorVouchers - Retrieves a filtered list of vendor vouchers.
   * @deprecated Use vendorVouchersQueue instead
   */
  readonly vendorVouchers?: Maybe<ReadonlyArray<Maybe<VoucherHeader>>>;
  /** mastery-accounting: vendorVouchersQueue - Retrieves a paginated filtered list of vendor vouchers. */
  readonly vendorVouchersQueue?: Maybe<VoucherHeaderConnection>;
  /** mastery-accounting: customerPayments - Retrieves a filtered list of customer payments */
  readonly customerPayments?: Maybe<ReadonlyArray<Maybe<CustomerPayment>>>;
  /** mastery-accounting: vendorPayments - Retrieves a filtered list of vendor payments */
  readonly vendorPayments?: Maybe<ReadonlyArray<Maybe<VendorPayment>>>;
  /** mastery-accounting: loadMoneySummary - Retrieves a financial summary of the load */
  readonly loadMoneySummary?: Maybe<LoadMoneySummary>;
  /** mastery-accounting: loadRequiredDocumentsSummary - Retrieves the required documents summary for all customer orders and vendor routes associated with the load */
  readonly loadRequiredDocumentsSummary?: Maybe<ReadonlyArray<Maybe<LoadRequiredDocumentsSummary>>>;
  /** mastery-accounting: customerOrderRequiredDocuments - Retrieves the required documents for a customer order */
  readonly customerOrderRequiredDocuments?: Maybe<ReadonlyArray<Maybe<CustomerOrderRequiredDocument>>>;
  /** mastery-accounting: vendorRouteRequiredDocuments - Retrieves the required documents for a vendor route */
  readonly vendorRouteRequiredDocuments?: Maybe<ReadonlyArray<Maybe<VendorRouteRequiredDocument>>>;
  /** mastery-accounting: vendorRouteRequiredDocumentsByLoadId - Retrieves the required documents for all vendor routes within a load */
  readonly vendorRouteRequiredDocumentsByLoadId?: Maybe<ReadonlyArray<Maybe<VendorRouteRequiredDocument>>>;
  /** mastery-accounting: vendorInvoices - Retrieves vendor invoices based on provided filters */
  readonly vendorInvoices?: Maybe<ReadonlyArray<Maybe<VendorInvoice>>>;
  /** mastery-accounting: segmentationConfiguration - Retrieves segmentation configuration based on provided ID */
  readonly segmentationConfiguration?: Maybe<SegmentationConfiguration>;
  /** mastery-accounting: segmentationConfiguration - Retrieves segmentation configurations */
  readonly segmentationConfigurations?: Maybe<ReadonlyArray<Maybe<SegmentationConfiguration>>>;
  /** mastery-accounting: segment - Retrieves segmentation segment based on provided ID */
  readonly segment?: Maybe<Segment>;
  /** mastery-accounting: segmentValues - Retrieves all segment value mappings */
  readonly segmentValues?: Maybe<ReadonlyArray<Maybe<SegmentValueOutput>>>;
  /** mastery-accounting: segmentValuesForSegment - Retrieves all segment value mappings associated to provided segment ID */
  readonly segmentValuesForSegment?: Maybe<ReadonlyArray<Maybe<SegmentValueOutput>>>;
  /** mastery-accounting: accountingExceptions - Retrieves accounting exceptions based on provided filters */
  readonly accountingExceptions?: Maybe<AccountingExceptionConnection>;
  /** mastery-accounting: routeVendorCost - Retrieves a single RouteVendorCost by routeId and vendorId */
  readonly getRouteVendorCost?: Maybe<RouteVendorCost>;
  /** mastery-accounting: order - Retrieves a single Order by orderId */
  readonly getOrder?: Maybe<Order>;
  /** mastery-accounting: Accounting Internal Configurations  - Retrieves list of configurations used only in accounting. */
  readonly getAccountingTenantConfiguration?: Maybe<TenantConfigurationsModel>;
  /** mastery-accounting: default voucher date  - Retrieves list of date option for setting default voucher date */
  readonly getdefaultvoucherdate?: Maybe<VoucherDateDefault>;
  /** mastery-accounting: Create Adjustment Items Checked  - Retrieves the default for create adjustment items to be unchecked / checked */
  readonly getCreateAdjustmentItemsCheckedOption?: Maybe<CreateAdjustmentItemsChecked>;
  /** mastery-accounting: getCustomerInvoiceForResend - Retrieves a customer invoice and rate details. */
  readonly getCustomerInvoiceForResend?: Maybe<InvoiceHeader>;
  /** mastery-accounting: getCustomerInvoicesForResend - Retrieves a customer invoice and rate details. */
  readonly getCustomerInvoicesForResend?: Maybe<ReadonlyArray<Maybe<InvoiceHeader>>>;
  /** mastery-accounting: voucherVendorTolerances - Retrieves a list of voucher vendor tolerances. */
  readonly voucherVendorTolerance?: Maybe<ReadonlyArray<Maybe<VoucherVendorTolerance>>>;
  /** mastery-accounting: routeVendorCost - Retrieves a single RouteVendorCost by routeId and vendorId with the latest threshold calculations. */
  readonly getCalculatedRouteVendorThresholds?: Maybe<RouteVendorCost>;
  /** mastery-accounting: applicableTolerance - Retrieves a single combined tolerance which is the applicable tolerance for the given routeId and vendorId. */
  readonly getApplicableTolerance?: Maybe<ApplicableRouteVendorTolerance>;
  /** mastery-accounting: adjustmentInterfaces - Retrieves the configured Adjustment Interfaces */
  readonly adjustmentInterfaces: ReadonlyArray<AdjustmentInterface>;
  /** mastery-accounting: vendor invoice voucher - Retrieves the reference between vendor invoice and vouchers */
  readonly getVendorInvoiceVouchers: ReadonlyArray<VendorInvoiceVoucher>;
  /** mastery-accounting: partial voucher information - Retrieves the information needed to create a partial voucher */
  readonly getPartialVoucherInformation: PartialVoucherModel;
  /** mastery-accounting: partial voucher information - Retrieves the information needed to update a partial voucher */
  readonly getPartialVoucherInformationByBatchId: PartialVoucherModel;
  /** mastery-accounting: Invoice Distribution history - Retrieves the invoice distribution history created by audit model */
  readonly getInvoiceDistributionhistoryById: ReadonlyArray<InvoiceDistributionHistory>;
  /** mastery-accounting: Get the Exchange Rate Configurations - Retrieves the configuration for multi-currency exchange rate dates */
  readonly getExchangeRateConfigurations: ReadonlyArray<ExchangeRateConfiguration>;
  /** mastery-accounting: Get All AutoAssociation approved users */
  readonly getAllAutoAssociationUsers: ReadonlyArray<AutoAssociationUser>;
  /** mastery-accounting: Get All Jurisdiction Taxes */
  readonly getAllJurisdictionTaxes?: Maybe<ReadonlyArray<Maybe<JurisdictionTax>>>;
  /** mastery-accounting: Finalized Fuel Status - Retrieves the finalized fuel status for an order */
  readonly getFinalizedFuelStatus?: Maybe<FinalizedFuelStatus>;
  /** mastery-accounting: Get All Taxability Charge Types */
  readonly getTaxabilityChargeTypes?: Maybe<ReadonlyArray<Maybe<TaxabilityChargeTypeOutput>>>;
  /** mastery-accounting: Calculate VAT Configuration Checked - Retrieves the default for calculate VAT to be unchecked / checked */
  readonly getCalculateVatCheckedConfiguration?: Maybe<CalculateVatCheckedModel>;
  /** mastery-accounting: Get 'Bill To Codes' for a specific customer */
  readonly getBillToCodesByCustomerId?: Maybe<ReadonlyArray<Maybe<BillToCode>>>;
  /** mastery-accounting: Get 'Bill To Code Audit History' for a specific customer */
  readonly getBillToCodeHistoryByCustomerId?: Maybe<ReadonlyArray<Maybe<BillToCodeHistory>>>;
  /** mastery-accounting: Get 'Auto Voucher' for a specific vendor */
  readonly getVendorAutoVoucher?: Maybe<VendorAutoVoucherType>;
  /** mastery-accounting: Get 'Use_Global' Toggle for a specific vendor */
  readonly getVendorUseGlobalToggle?: Maybe<VendorUseGlobalType>;
  /** mastery-accounting: Get 'Use_Global' Toggle for a specific customer */
  readonly getUseGlobalToggleByCustomerId?: Maybe<CustomerUseGlobalType>;
  /** mastery-accounting: Get all Extract Configurations */
  readonly getAllExtractConfigurations?: Maybe<FileExtractConfiguration>;
  /** mastery-accounting: Get Integration Mappings by Group Name */
  readonly getIntegrationMappings?: Maybe<ReadonlyArray<Maybe<IntegrationMapping>>>;
  /** mastery-accounting: Get Customer Payment Details by Invoice */
  readonly getCustomerInvoicePayments?: Maybe<CustomerInvoiceApplication>;
  /** mastery-accounting: Get customer payment details by payment ID */
  readonly getCustomerPaymentDetails?: Maybe<CustomerPaymentDetails>;
  /** mastery-accounting: Get Vendor Voucher Payment Details by Voucher */
  readonly getVendorVoucherPayments?: Maybe<VendorVoucherApplication>;
  /** mastery-accounting: Get vendor payment details by payment Id */
  readonly getVendorPaymentDetails?: Maybe<VendorPaymentDetails>;
  /** mastery-accounting: Get routes by pay program or pay rate table ID */
  readonly getRoutesByDriverPayInfo?: Maybe<ReadonlyArray<Maybe<LoadRouteV2>>>;
  /** mastery-accounting: Get routes with vouchered driver pay items */
  readonly getRoutesWithVoucheredDriverPayItems?: Maybe<ReadonlyArray<Maybe<LoadRouteV2>>>;
  /** mastery-accounting: Get cost details that are eligible for driver vouchering */
  readonly getCostDetailsForDriverVouchering?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  /** mastery-accounting: Get cost details that are associated to a driver voucher */
  readonly getCostDetailsByDriverVoucherIds?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  /** mastery-accounting: Get carrier payment terms */
  readonly getCarrierPaymentTerms?: Maybe<ReadonlyArray<Maybe<CarrierPaymentTerm>>>;
  /** mastery-accounting: Get customer payment terms */
  readonly getCustomerPaymentTerms?: Maybe<ReadonlyArray<Maybe<CustomerPaymentTerm>>>;
  /** mastery-accounting: Get configuration value by key */
  readonly getAccountingConfigurationValue?: Maybe<Scalars['String']>;
  /** mastery-accounting: Carrier Finalized Fuel Status - Retrieves the carrier finalized fuel status for an route */
  readonly getCarrierFinalizedFuelStatus?: Maybe<CarrierFinalizedFuelStatus>;
  readonly getTrips?: Maybe<ReadonlyArray<Maybe<TripType>>>;
  readonly activeAccountingIntegrations: ReadonlyArray<Scalars['String']>;
  readonly dummyProjectNameExample: Example;
  readonly dummyProjectNameExamples?: Maybe<DummyProjectNameExamplesConnection>;
  /** getAllInvoicingRequirementConfigurations - Retrieves a list of Invoicing requirement configurations */
  readonly getAllInvoicingRequirementConfigurations?: Maybe<ReadonlyArray<Maybe<InvoicingRequirementConfiguration>>>;
  /** getAllVoucheringRequirementConfigurations - Retrieves a list of Vouchering requirement configurations */
  readonly getAllVoucheringRequirementConfigurations?: Maybe<ReadonlyArray<Maybe<VoucheringRequirementConfiguration>>>;
  /** getAllRouteVendorRequirements - Retrieves a list of Vouchering requirements for a route vendor pair. */
  readonly getAllRouteVendorRequirements?: Maybe<ReadonlyArray<Maybe<RouteVendorRequirement>>>;
  /** getAllCustomerOrderRequirements - Retrieves a list of Invoicing requirements for an order. */
  readonly getAllCustomerOrderRequirements?: Maybe<ReadonlyArray<Maybe<CustomerOrderRequirement>>>;
  /** @deprecated Use getNonRouteCostDetails instead */
  readonly getNonRouteCostDetailsByDriverIds?: Maybe<ReadonlyArray<Maybe<NonRouteCostDetail>>>;
  readonly getNonRouteCostDetails?: Maybe<GetNonRouteCostDetailsOutput>;
  readonly getDriverPaySettings?: Maybe<ReadonlyArray<Maybe<DriverPaySettingType>>>;
  readonly getJobConfigurationByJobName?: Maybe<JobConfigurationType>;
  /** @deprecated Use getDriverPayVoucherCutOffDateAndTimeV2 instead */
  readonly getDriverPayVoucherCutOffDateAndTime?: Maybe<DateAndTimeType>;
  readonly getDriverPayVoucherCutOffDateAndTimeV2?: Maybe<GetDriverPayVoucherCutOffDateAndTimeV2Output>;
  /** @deprecated Use getNonRouteCostDetailHistoryV2 instead */
  readonly getNonRouteCostDetailHistory?: Maybe<ReadonlyArray<Maybe<NonRouteCostDetailHistory>>>;
  readonly getNonRouteCostDetailHistoryV2?: Maybe<GetNonRouteCostDetailHistoryResult>;
  readonly getDriverVoucherHeaders?: Maybe<GetDriverVoucherHeadersOutput>;
  readonly getRatingSourceIdByCustomer?: Maybe<RatingSourceIdByCustomerResponse>;
  readonly getAbacusPricing?: Maybe<GetAbacusPricingPayload>;
  readonly getAbacusPricingV2?: Maybe<GetAbacusPricingPayload>;
  readonly getAbacusPricingV3?: Maybe<GetAbacusPricingPayload>;
  readonly abacusCostLineItems?: Maybe<AbacusCostLineItemsPayload>;
  readonly statusBulkReratingRequest?: Maybe<BulkReratingRequest>;
  readonly accessorialSpotQuote?: Maybe<AccessorialPayload>;
  readonly accessorialLineItems?: Maybe<AccessorialPayload>;
  readonly customerAccessorialSettings?: Maybe<CustomerAccessorialSettingsPayload>;
  readonly carrierAccessorialSettings?: Maybe<CarrierAccessorialSettingsPayload>;
  readonly carrierCapacityConstraintByCostQuote?: Maybe<CarrierCapacity>;
  readonly carrierCapacityConstraintByCarrierLaneDate?: Maybe<CarrierCapacityRemaining>;
  readonly carrierCapacityRemainingByCostQuoteAndDate?: Maybe<CarrierCapacityRemaining>;
  readonly carrierCapacityConstraintByCostQuoteIDs?: Maybe<ReadonlyArray<Maybe<CarrierCapacity>>>;
  readonly assetDriverTenderById?: Maybe<AssetDriverTender>;
  readonly assetDriverTendersByRoute?: Maybe<ReadonlyArray<Maybe<AssetDriverTender>>>;
  readonly assetDriverTendersByDriver?: Maybe<ReadonlyArray<Maybe<AssetDriverTender>>>;
  readonly assetDriverTendersByRouteAndDriver?: Maybe<ReadonlyArray<Maybe<AssetDriverTender>>>;
  readonly assetDriverTendersByDriverCapacity?: Maybe<ReadonlyArray<Maybe<AssetDriverTender>>>;
  readonly assetDriverTendersByRouteAndDriverCapacity?: Maybe<ReadonlyArray<Maybe<AssetDriverTender>>>;
  readonly routingGuideV2?: Maybe<RoutingGuide>;
  readonly routingGuideV3?: Maybe<RoutingGuide>;
  readonly laneRoutingGuides: RoutingGuidesConnection;
  readonly exportRoutingGuides: RoutingGuidesConnection;
  readonly routingGuideSequenceItems?: Maybe<ReadonlyArray<RoutingGuideSequenceItem>>;
  readonly matchingRoutingGuide?: Maybe<RoutingGuide>;
  readonly matchingRoutingGuides?: Maybe<ReadonlyArray<RoutingGuide>>;
  readonly tenderOptions?: Maybe<TenderOptions>;
  readonly tenderOptionsByParentObject?: Maybe<TenderOptions>;
  readonly tenderOptionsByParentObjectV2?: Maybe<TenderOptionsV2>;
  readonly defaultTenderOptions?: Maybe<TenderOptions>;
  readonly activeTenderPlan?: Maybe<TenderPlan>;
  readonly tenderPlan?: Maybe<TenderPlan>;
  readonly outboundTenderSettings?: Maybe<OutboundTenderSettings>;
  readonly contactUsageInTendering?: Maybe<ContactTenderingUsageQueryResult>;
  readonly getAssetAudit: AssetAuditPayload;
  readonly driverCurrentNextRoutes: DriverCurrentNextRoutes;
  readonly driversCurrentNextRoutes: ReadonlyArray<DriverCurrentNextRoutes>;
  readonly powerUnitCurrentNextRoutes: PowerUnitCurrentNextRoutes;
  readonly powerUnitsCurrentNextRoutes: ReadonlyArray<PowerUnitCurrentNextRoutes>;
  /** @deprecated baseWorkFlowFormByCode query deprecated */
  readonly baseWorkFlowFormByCode: WorkflowFormOutput;
  /** @deprecated mobileCommWorkflowFormByCode query deprecated */
  readonly mobileCommWorkflowFormByCode: WorkflowFormOutput;
  readonly getWorkflowForms?: Maybe<ReadonlyArray<WorkflowFormOutput>>;
  readonly getMMWorkflowJobById: GetWorkflowJobPayLoad;
  /** Gets the Sent Status records for the workflow job */
  readonly fetchSendWorkflowJob: SendWorkflowJobPayLoad;
  /** Gets the Created Status records for the workflow job */
  readonly fetchWorkflowJob: CreateWorkflowJobPayLoad;
  readonly getLoadStatusRecordsAudit: ReadonlyArray<LoadStatusRecord>;
  readonly allBlumeChargeTypes?: Maybe<AllBlumeChargeTypesPayload>;
  /** Validates that the drivers can be assigned to the route. */
  readonly validateDrivers: DriverValidationsPayload;
  /**
   * This query can be used to show which validation rules are currently active in an environment.
   * The optional `BookingValidationRulesInput` can be used to test various C.A.N.V.A.S. settings.
   *
   * Repo: [bookings-api](https://github.com/masterysystems/bookings-api)
   */
  readonly bookingValidationRules: BookingValidationRulesPayload;
  /**
   * This query powers which fields are required in Bookings' Expected Ready form.
   * Required fields can vary based on the scope of the route and can be configured in C.A.N.V.A.S.
   *
   * Repo: [bookings-api](https://github.com/masterysystems/bookings-api)
   */
  readonly expectedReadyValidations: ExpectedReadyValidationsResponse;
  /**
   * This query powers which fields are required in bookings' Expected Ready form with scope input.
   * Required fields can vary based on the scope of the route and can be configured in C.A.N.V.A.S.
   *
   * Repo: [bookings-api](https://github.com/masterysystems/bookings-api)
   */
  readonly expectedReadyValidationsV2: ReadonlyArray<ExpectedReadyValidationsResponseV2>;
  /**
   * Returns Route Vendor info for a given Route Vendor ID
   *
   * Repo: [bookings-api](https://github.com/masterysystems/bookings-api)
   */
  readonly routeVendorV2?: Maybe<RouteVendorV2>;
  /**
   * Returns an array of Route Vendors based on RouteVendorsV2Filter criteria
   * Max result set: 100
   *
   * Repo: [bookings-api](https://github.com/masterysystems/bookings-api)
   */
  readonly routeVendorsV2: RouteVendorV2Connection;
  /**
   * Returns Bounce info for a given Bounce ID
   *
   * Repo: [bookings-api](https://github.com/masterysystems/bookings-api)
   */
  readonly bounceV2?: Maybe<BounceV2>;
  /**
   * Returns an array of Bounces based on `BouncesV2Filter` criteria
   * Max result set: 100 Route Vendors' worth of Bounces
   *
   * Repo: [bookings-api](https://github.com/masterysystems/bookings-api)
   */
  readonly bouncesV2: BouncesV2Connection;
  /** mastery-crm: calculateNextCarrierInteractionDate - Returns calculated next carrier interaction date. */
  readonly calculateNextCarrierInteractionDate?: Maybe<NextCarrierInteractionDate>;
  /** mastery-crm: calculateNextCustomerInteractionDate - Returns calculated next customer interaction date. */
  readonly calculateNextCustomerInteractionDate?: Maybe<NextCustomerInteractionDate>;
  /** mastery-crm: carrierNotes - Retrieves a list of carrier Notes. */
  readonly carrierCRMNotes?: Maybe<CarrierCrmNotesConnection>;
  /** mastery-crm: carrierOpportunities - Retrieves a list of carrier Opportunities. */
  readonly carrierCRMOpportunities?: Maybe<CarrierCrmOpportunityConnection>;
  /** mastery-crm: carrierInteractions - Retrieves a list of carrier interactions. */
  readonly carrierInteractions?: Maybe<CarrierInteractionConnection>;
  /** mastery-crm: CarrierDetails - Retrieves Carrier Details. */
  readonly crmCarrierDetails?: Maybe<CrmCarrierDetail>;
  /** mastery-crm: crmCustomerDetails - Retrieves customer Details. */
  readonly crmCustomerDetails?: Maybe<CrmCustomerDetail>;
  /** mastery-crm: customerOpportunities - Retrieves a list of customer Opportunities. */
  readonly customerCRMOpportunities?: Maybe<CustomerCrmOpportunityConnection>;
  /** mastery-crm: customerInteractions - Retrieves a list of customer interactions. */
  readonly customerInteractions?: Maybe<CustomerInteractionConnection>;
  /** mastery-crm: getCarrierCrmDocuments - Retrieves a list of carrier documents. */
  readonly getCarrierCrmDocuments?: Maybe<CarrierCrmDocumentConnection>;
  /** mastery-crm: getCarrierPortfolio - Retrieves a list of carriers and their last interaction if available. */
  readonly getCarrierPortfolio?: Maybe<CarrierPortfolio>;
  /** mastery-crm: getCustomerCrmDocuments - Retrieves a list of customer documents. */
  readonly getCustomerCrmDocuments?: Maybe<CustomerCrmDocumentConnection>;
  /** mastery-crm: getCustomerCrmNotes - Retrieves a list of customer notes. */
  readonly getCustomerCrmNotes?: Maybe<CustomerCrmNoteConnection>;
  /** mastery-crm: getCustomerPortfolio - Retrieves a list of customers and their last interaction if available. */
  readonly getCustomerPortfolio?: Maybe<CustomerPortfolio>;
  /** mastery-crm: validateCarrierOpportunityName - Checks whether the opportunity name for Carrier is unique or not for particular carrier. */
  readonly validateCarrierCRMOpportunityNameAvailablity?: Maybe<Scalars['Boolean']>;
  /** mastery-crm: validateCustomerOpportunityName - Checks whether the opportunity name for Customer is unique or not for particular customer. */
  readonly validateCustomerCRMOpportunityNameAvailablity?: Maybe<Scalars['Boolean']>;
  /** @deprecated getContactById query deprecated */
  readonly getContactById: ReadonlyArray<CrmContactOutput>;
  /** @deprecated getContactsByName query deprecated */
  readonly getContactsByName: ReadonlyArray<CrmContactOutput>;
  /** @deprecated getContactsByEntityId query deprecated */
  readonly getContactsByEntityId: ReadonlyArray<CrmContactOutput>;
  /** @deprecated getContactInformationTypes query deprecated */
  readonly getContactInformationTypes: ReadonlyArray<CrmContactInformationTypeOutput>;
  /** @deprecated getResponsibilitiesByContactId query deprecated */
  readonly getResponsibilitiesByContactId: ReadonlyArray<CrmContactResponsibilityOutput>;
  /** @deprecated associatedRepsByContactId query deprecated */
  readonly associatedRepsByContactId: ReadonlyArray<CrmContactAssociatedRepOutput>;
  /** @deprecated associatedRepsByContactIdandStatus query deprecated */
  readonly associatedRepsByContactIdandStatus: ReadonlyArray<CrmContactAssociatedRepOutput>;
  /** @deprecated associatedEntitiesByContactId query deprecated */
  readonly associatedEntitiesByContactId: ReadonlyArray<CrmContactAssociatedEntityOutput>;
  /** @deprecated workHoursByContactId mutation deprecated */
  readonly workHoursByContactId: ReadonlyArray<CrmContactWorkHoursOutput>;
  /** @deprecated contactInformationByContactInformationTypeId query deprecated */
  readonly contactInformationByContactInformationTypeId: ReadonlyArray<CrmContactInformationOutput>;
  /** @deprecated contactInformationById query deprecated */
  readonly contactInformationById: ReadonlyArray<CrmContactInformationOutput>;
  /** @deprecated contactInformationTypes query deprecated */
  readonly contactInformationTypes: ReadonlyArray<CrmContactInformationTypeOutput>;
  /** @deprecated contactInformationByContactId query deprecated */
  readonly contactInformationByContactId: ReadonlyArray<CrmContactInformationOutput>;
  /** @deprecated duplicateContactsIfExists mutation deprecated */
  readonly duplicateContactsIfExists: ReadonlyArray<CrmContactOutputV2>;
  /** @deprecated allContactsv2 query deprecated */
  readonly allContactsv2: ReadonlyArray<CrmContactOutputV2>;
  /** @deprecated contactByIdv2 query deprecated */
  readonly contactByIdv2: ReadonlyArray<CrmContactOutputV2>;
  /** @deprecated contactsByNamev2 query deprecated */
  readonly contactsByNamev2: ReadonlyArray<CrmContactOutputV2>;
  /** @deprecated contactsByEntityIdv2 query deprecated */
  readonly contactsByEntityIdv2: ReadonlyArray<CrmContactOutputV2>;
  /** @deprecated contactEntityLookupv2 query deprecated */
  readonly contactEntityLookupv2: ReadonlyArray<CrmContactEntityLookupOutputV2>;
  /** @deprecated associatedRepsByContactIdv2 query deprecated */
  readonly associatedRepsByContactIdv2: ReadonlyArray<CrmContactAssociatedRepOutputV2>;
  /** @deprecated associatedRepsByContactIdandStatusv2 query deprecated */
  readonly associatedRepsByContactIdandStatusv2: ReadonlyArray<CrmContactAssociatedRepOutputV2>;
  /** @deprecated associatedEntitiesByContactIdv2 query deprecated */
  readonly associatedEntitiesByContactIdv2: ReadonlyArray<CrmContactAssociatedEntityOutputV2>;
  /** @deprecated workHoursByContactIdv2 query deprecated */
  readonly workHoursByContactIdv2: ReadonlyArray<CrmContactWorkHoursOutputv2>;
  /** @deprecated contactInformationByContactInformationTypeIdv2 query deprecated */
  readonly contactInformationByContactInformationTypeIdv2: ReadonlyArray<CrmContactInformationOutputV2>;
  /** @deprecated contactInformationByIdv2 query deprecated */
  readonly contactInformationByIdv2: ReadonlyArray<CrmContactInformationOutputV2>;
  /** @deprecated contactInformationTypesv2 query deprecated */
  readonly contactInformationTypesv2: ReadonlyArray<CrmContactInformationTypeOutputV2>;
  /** @deprecated contactInformationByContactIdv2 query deprecated */
  readonly contactInformationByContactIdv2: ReadonlyArray<CrmContactInformationOutputV2>;
  /** @deprecated duplicateContactsIfExistsv2 query deprecated */
  readonly duplicateContactsIfExistsv2: ReadonlyArray<CrmContactOutputV2>;
  /** @deprecated responsibilitiesByContactIdv2 query deprecated */
  readonly responsibilitiesByContactIdv2: ReadonlyArray<CrmContactResponsibilityOutputV2>;
  readonly crmAllContacts: ReadonlyArray<CrmContactOutput>;
  readonly crmContactById: ReadonlyArray<CrmContactOutput>;
  readonly crmContactsByName: ReadonlyArray<CrmContactOutput>;
  readonly crmContactsByEntityId: ReadonlyArray<CrmContactOutput>;
  readonly crmContactEntityLookup: ReadonlyArray<CrmContactEntityLookupOutput>;
  readonly crmAssociatedRepsByContactId: ReadonlyArray<CrmContactAssociatedRepOutput>;
  readonly crmAssociatedRepsByContactIdandStatus: ReadonlyArray<CrmContactAssociatedRepOutput>;
  readonly crmAssociatedEntitiesByContactId: ReadonlyArray<CrmContactAssociatedEntityOutput>;
  readonly crmWorkHoursByContactId: ReadonlyArray<CrmContactWorkHoursOutput>;
  readonly crmContactInformationByContactInformationTypeId: ReadonlyArray<CrmContactInformationOutput>;
  readonly crmContactInformationById: ReadonlyArray<CrmContactInformationOutput>;
  readonly crmContactInformationTypes: ReadonlyArray<CrmContactInformationTypeOutput>;
  readonly crmContactInformationByContactId: ReadonlyArray<CrmContactInformationOutput>;
  readonly crmDuplicateContactsIfExists: ReadonlyArray<CrmContactOutput>;
  readonly crmResponsibilitiesByContactId: ReadonlyArray<CrmContactResponsibilityOutput>;
  readonly crmDuplicateContacts: ReadonlyArray<CrmContactOutput>;
  readonly crmContactIdByCode: CrmContactIdPayload;
  readonly crmCompanyDescription: ReadonlyArray<CrmCompanyDescriptionOutput>;
  readonly crmCompanyDescriptionByEntityId: ReadonlyArray<CrmCompanyDescriptionOutput>;
  readonly crmDetailedDescription: ReadonlyArray<CrmDetailedDescriptionOutput>;
  readonly crmDetailedDescriptionByEntityId: ReadonlyArray<CrmDetailedDescriptionOutput>;
  readonly crmFirmographics: ReadonlyArray<CrmFirmographicsOutput>;
  readonly crmFirmographicsByEntityId: ReadonlyArray<CrmFirmographicsOutput>;
  readonly crmIndustries: ReadonlyArray<CrmIndustriesOutput>;
  readonly crmIndustriesByEntityId: ReadonlyArray<CrmIndustriesOutput>;
  readonly crmIndustriesByIndustryId: ReadonlyArray<CrmIndustriesOutput>;
  readonly crmIndustryClassificationLookup: ReadonlyArray<CrmIndustryClassificationLookupOutput>;
  readonly crmIndustryClassificationSystems: ReadonlyArray<CrmIndustryClassificationLookupOutput>;
  readonly crmIndustryClassificationByLookupId: ReadonlyArray<CrmIndustryClassificationLookupOutput>;
  readonly crmDetailsEntityLookup: ReadonlyArray<CrmDetailsEntityLookupOutput>;
  readonly crmDetailsStockConfigDetails: CrmDetailsStockSymbolConfigOutput;
  /** @deprecated crmInteraction query deprecated */
  readonly crmInteraction: ReadonlyArray<CrmInteractionOutput>;
  /** @deprecated crmInteractionById query deprecated */
  readonly crmInteractionById: ReadonlyArray<CrmInteractionOutput>;
  /** @deprecated crmInteractionByEntityId query deprecated */
  readonly crmInteractionByEntityId: ReadonlyArray<CrmInteractionOutput>;
  /** @deprecated crmInteractionByStatus query deprecated */
  readonly crmInteractionByStatus: ReadonlyArray<CrmInteractionOutput>;
  /** @deprecated crmInteractionContact query deprecated */
  readonly crmInteractionContact: ReadonlyArray<CrmInteractionContactOutput>;
  /** @deprecated crmInteractionRepGroupLookup query deprecated */
  readonly crmInteractionRepGroupLookup: ReadonlyArray<CreateInteractionRepGroupLookupOutput>;
  /** @deprecated crmInteractionObjective query deprecated */
  readonly crmInteractionObjective: ReadonlyArray<CrmInteractionObjectiveOutput>;
  /** @deprecated crmInteractionRepGroupAuditHistory query deprecated */
  readonly crmInteractionRepGroupAuditHistory: ReadonlyArray<CrmInteractionRepGroupOutput>;
  /** @deprecated crmInteractionEntityLookup query deprecated */
  readonly crmInteractionEntityLookup: ReadonlyArray<Create_Crm_Interaction_Entity_Lookup_Output>;
  /** @deprecated crmInteractionRescheduleAuditHistory query deprecated */
  readonly crmInteractionRescheduleAuditHistory: ReadonlyArray<CrmInteractionRescheduleOutput>;
  readonly crmInteractionV2: ReadonlyArray<CrmInteractionOutputV2>;
  readonly crmInteractionByIdV2: ReadonlyArray<CrmInteractionOutputV2>;
  readonly crmInteractionByEntityIdV2: ReadonlyArray<CrmInteractionOutputV2>;
  readonly crmInteractionByStatusV2: ReadonlyArray<CrmInteractionOutputV2>;
  readonly crmInteractionContactV2: ReadonlyArray<CrmInteractionContactOutputV2>;
  readonly crmInteractionRepGroupLookupV2: ReadonlyArray<CrmInteractionRepGroupLookupOutputV2>;
  readonly crmInteractionObjectiveV2: ReadonlyArray<CrmInteractionObjectiveOutputV2>;
  readonly crmInteractionRepGroupAuditHistoryV2: ReadonlyArray<CrmInteractionRepGroupOutputV2>;
  readonly crmInteractionEntityLookupV2: ReadonlyArray<CrmInteractionEntityLookupOutputV2>;
  readonly crmInteractionRescheduleAuditHistoryV2: ReadonlyArray<CrmInteractionRescheduleAuditOutputV2>;
  readonly getPaginatedInteractions: CrmInteractionConnection;
  readonly portfolioInteractionCount: Scalars['Float'];
  readonly portfolioInteractionsByReps: CrmInteractionConnection;
  readonly crmInteractionsByReasonTermV2: ReadonlyArray<CrmInteractionOutputV2>;
  readonly crmOpportunity: ReadonlyArray<CrmOpportunityOutput>;
  readonly crmOpportunityByEntityId: ReadonlyArray<CrmOpportunityOutput>;
  readonly crmOpportunityByOpportunityId: ReadonlyArray<CrmOpportunityOutput>;
  readonly validateCrmOpportunityName: Scalars['Boolean'];
  readonly crmOpportunityV2: ReadonlyArray<CrmOpportunityOutputV2>;
  readonly crmOpportunityByEntityIdV2: ReadonlyArray<CrmOpportunityOutputV2>;
  readonly crmOpportunityByOpportunityIdV2: ReadonlyArray<CrmOpportunityOutputV2>;
  readonly crmRepsByOpportunityIdV2: ReadonlyArray<CrmOpportunityRepsOutputV2>;
  readonly validateCrmOpportunityNameV2: Scalars['Boolean'];
  readonly validateCrmOpportunityNameByEntityIdV2: Scalars['Boolean'];
  readonly crmOpportunityEntityLookupV2: ReadonlyArray<CrmOpportunityEntityLookupOutputV2>;
  readonly crmOpportunitiesNames: ReadonlyArray<CrmOpportunitiesNamesOutput>;
  readonly portfolioOpportunitiesByReps: CrmOpportunityConnection;
  readonly lock?: Maybe<Lock>;
  readonly carrierCommitments?: Maybe<CarrierCommitmentConnection>;
  readonly carrierCommitmentsById?: Maybe<CarrierCommitment>;
  readonly carrierCommitmentRoutesByCommitmentId?: Maybe<CarrierCommitmentRoutes>;
  readonly carrierCommitmentAcceptedRoutesCount?: Maybe<ReadonlyArray<Maybe<AcceptedCarrierRoutes>>>;
  readonly exportCarrierCommitments?: Maybe<CarrierCommitmentConnection>;
  readonly geographies?: Maybe<ReadonlyArray<Geography>>;
  /** Returns a single Geography based on source_id */
  readonly geography: Geography;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly carrierNullableV2?: Maybe<CarrierV2>;
  readonly carrierRepsV2: ReadonlyArray<RepV2>;
  /** @deprecated Moved to CarrierType */
  readonly carrierSettingV2?: Maybe<CarrierSettingV2>;
  /**
   * Deprecated: use geographies query instead
   * @deprecated Deprecated: Use geographies query instead
   */
  readonly geographiesRD?: Maybe<ReadonlyArray<GeographyV2>>;
  /**
   * Deprecated: use geography query instead
   * @deprecated Deprecated: Use geography query instead
   */
  readonly geographyRD: GeographyV2;
  readonly auditsNullableV2?: Maybe<Audits>;
  readonly carriersNullableV2?: Maybe<CarrierConnectionV2>;
  readonly carrierParentsByCarrierId: ReadonlyArray<ParentRelationship>;
  readonly carrierCarrierRelationshipsByCarrierId: ReadonlyArray<CarrierCarrierRelationship>;
  readonly parentOfRelatedCarrierByCarrierId?: Maybe<CarrierCarrierRelationship>;
  readonly carrierChildrenHierarchy?: Maybe<CarrierChildrenHierarchy>;
  readonly carrierCustomerRelationshipByCarrierId?: Maybe<ReadonlyArray<Maybe<CarrierCustomerRelationshipV2>>>;
  readonly findCarrierCustomerInternalCustomerRelationshipByCarrierId?: Maybe<CarrierCustomerRelationshipV2>;
  readonly validateCarrierCode: Scalars['Boolean'];
  readonly getGeneratedCarrierCodeByParentCarrierId: ReadonlyArray<Scalars['String']>;
  readonly getGlobalTenantChargeTypeConfiguration?: Maybe<TenantChargeTypeConfigurationResponse>;
  readonly getGlobalTenantChargeTypeConfigurationV2?: Maybe<TenantChargeTypeConfigurationV2Response>;
  readonly getAllGlobalTenantChargeTypeConfigurations?: Maybe<ReadonlyArray<Maybe<TenantChargeTypeConfigurationResponse>>>;
  readonly getAllGlobalTenantChargeTypeConfigurationsV2?: Maybe<ReadonlyArray<Maybe<TenantChargeTypeConfigurationV2Response>>>;
  readonly getAllTenantChargeTypeConfigurations?: Maybe<ReadonlyArray<Maybe<TenantChargeTypeConfigurationResponse>>>;
  readonly getAllTenantChargeTypeConfigurationsV2?: Maybe<ReadonlyArray<Maybe<TenantChargeTypeConfigurationV2Response>>>;
  readonly getTenantChargeTypeConfiguration?: Maybe<TenantChargeTypeConfigurationResponse>;
  readonly getTenantChargeTypeConfigurationV2?: Maybe<TenantChargeTypeConfigurationV2Response>;
  readonly getAllPriorityConfiguration?: Maybe<ReadonlyArray<Maybe<PriorityConfigurationResponse>>>;
  readonly getPriorityConfiguration?: Maybe<PriorityConfigurationResponse>;
  readonly getCarrierChargeTypeConfiguration?: Maybe<CarrierChargeTypeConfigurationResponse>;
  readonly getCarrierChargeTypeConfigurationV2?: Maybe<CarrierChargeTypeConfigurationV2Response>;
  readonly getAllCarrierChargeTypeConfigurations?: Maybe<ReadonlyArray<Maybe<CarrierChargeTypeConfigurationResponse>>>;
  readonly getAllCarrierChargeTypeConfigurationsV2?: Maybe<ReadonlyArray<Maybe<CarrierChargeTypeConfigurationV2Response>>>;
  readonly getAllAccessorialChargeTypeConfigurations?: Maybe<ReadonlyArray<Maybe<AccessorialChargeTypeConfigurationResponse>>>;
  readonly getAccessorialChargeTypeConfiguration?: Maybe<AccessorialChargeTypeConfigurationResponse>;
  readonly getCustomerChargeTypeConfiguration?: Maybe<CustomerChargeTypeConfigurationResponse>;
  readonly getCustomerChargeTypeConfigurationV2?: Maybe<CustomerChargeTypeConfigurationV2Response>;
  readonly getAllCustomerChargeTypeConfigurations?: Maybe<ReadonlyArray<Maybe<CustomerChargeTypeConfigurationResponse>>>;
  readonly getAllCustomerChargeTypeConfigurationsV2?: Maybe<ReadonlyArray<Maybe<CustomerChargeTypeConfigurationV2Response>>>;
  readonly getRateDetailChargeTypeConfigurations?: Maybe<ReadonlyArray<Maybe<RateDetailChargeTypeConfigurationResponse>>>;
  readonly getRateDetailChargeTypeConfigurationsv2?: Maybe<ReadonlyArray<Maybe<RateDetailChargeTypeConfigurationResponse>>>;
  readonly getCostDetailChargeTypeConfigurations?: Maybe<ReadonlyArray<Maybe<CostDetailChargeTypeConfigurationResponse>>>;
  readonly getCostDetailChargeTypeConfigurationsV2?: Maybe<ReadonlyArray<Maybe<CostDetailChargeTypeConfigurationResponse>>>;
  readonly getOtherPayChargeTypeConfigurations?: Maybe<ReadonlyArray<Maybe<TenantChargeTypeConfigurationResponse>>>;
  readonly getOtherPayChargeTypeConfigurationsV2?: Maybe<ReadonlyArray<Maybe<TenantChargeTypeConfigurationV2Response>>>;
  readonly getEligiblePayProgramDetailChargeTypeConfigurations?: Maybe<ReadonlyArray<Maybe<PayProgramDetailChargeTypeConfigurationResponse>>>;
  readonly getRouteGlobalChargeTypeConfigurations?: Maybe<ReadonlyArray<Maybe<TenantChargeTypeConfigurationV2Response>>>;
  readonly getChargeCodeDetailsForIB210Mapping?: Maybe<ReadonlyArray<Maybe<MapIb210ChargeCodesResponse>>>;
  readonly getChargeCodeDetailsForIB204Mapping?: Maybe<ReadonlyArray<Maybe<MapIb204ChargeCodesResponse>>>;
  readonly activeClientExceptionRecords: ReadonlyArray<ClientExceptionRecord>;
  readonly clientExceptionRecord: ClientExceptionRecord;
  readonly clientExceptionRecords: ClientExceptionRecordConnection;
  /** mastery-commissions: Retrieves a Global Configuration by mode, size, division. */
  readonly getCommissionsGlobalConfiguration?: Maybe<CommissionsGlobalConfiguration>;
  /** mastery-commissions: Retrieves a collection of Customer Overhead Configurations by filter.CustomerId. */
  readonly getCommissionsCustomerOverheadConfigurations?: Maybe<ReadonlyArray<Maybe<CommissionsCustomerOverheadConfiguration>>>;
  /** mastery-commissions: Retrieves all active Global Configurations. */
  readonly getAllActiveCommissionsGlobalConfigurations?: Maybe<ReadonlyArray<Maybe<CommissionsGlobalConfiguration>>>;
  /** mastery-commissions: Retrieves the active load allocated margin (along with order/route margin info). */
  readonly getActiveCommissionsLoadAllocatedMargins?: Maybe<LoadAllocatedMargins>;
  /** mastery-commissions: Retrieves the active load allocated margin (along with employee commissions and order/route margin info). */
  readonly getActiveCommissionsByLoadId?: Maybe<LoadAllocatedMargins>;
  /** mastery-commissions: Retrieves the active assignments for a particular employee. */
  readonly getEmployeeCommissionPlanByEmployeeId?: Maybe<EmployeeCommissionPlan>;
  /** mastery-commissions: Retrieves the employee IDs actively assigned to a particular commission plan. */
  readonly getEmployeesAssignedToCommissionPlanByPlanId?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  /** mastery-commissions: Retrieves all assignable commission plans. */
  readonly getAllAssignableCommissionPlanConfigurations?: Maybe<ReadonlyArray<Maybe<EmployeeCommissionPlanConfiguration>>>;
  /** mastery-commissions: Retrieves the employee commission plan. */
  readonly getEmployeeCommissionPlanConfiguration?: Maybe<EmployeeCommissionPlanConfiguration>;
  /** mastery-commissions: Calculates the commissions for a specific load with the specified what if parameters sent in WhatIf argument. */
  readonly getWhatIfLoadCommissionsCalculation?: Maybe<WhatIfScenario>;
  /** mastery-commissions: Calculates the allocated margins for a specific load with the specified what if parameters sent in WhatIf argument. */
  readonly getWhatIfLoadAllocatedMargins?: Maybe<WhatIfScenario>;
  /** mastery-commissions: Retrieves the load commission override configuration for a specific load with the specified load id sent in as argument. */
  readonly getLoadCommissionsOverrideConfigurationByLoadId?: Maybe<LoadCommissionsOverrideConfiguration>;
  /** mastery-commissions: Retrieves the overhead on a commission for a load (used for Money Summary). */
  readonly getLoadCommissionsMoneySummaryOverhead?: Maybe<CommissionsOverhead>;
  /** mastery-commissions: Retrieves the overheads on a commissions for a list of loads (used for Money Summaries). */
  readonly getLoadCommissionsMoneySummaryOverheads?: Maybe<ReadonlyArray<Maybe<CommissionsOverhead>>>;
  readonly costQuotePaginated: CostQuoteConnection;
  readonly getCostQuoteById?: Maybe<CarrierQuoteResponse>;
  readonly getActiveCostQuotes?: Maybe<ReadonlyArray<Maybe<ActiveCostQuote>>>;
  readonly matchingCostQuote?: Maybe<MatchingCostQuotePayload>;
  readonly matchingCostQuoteV2?: Maybe<MatchingCostQuotePayload>;
  readonly getCreditAvailability: CreditAvailability;
  readonly getOrderCreditInfo: OrderCreditInfoPayload;
  /** @deprecated salesInteraction query deprecated */
  readonly salesInteraction: ReadonlyArray<CrmInteractionOutput>;
  /** @deprecated salesInteractionByEntityId query deprecated */
  readonly salesInteractionByEntityId: ReadonlyArray<CrmInteractionOutput>;
  readonly salesInteractionByEntityIdV2: ReadonlyArray<CrmInteractionOutputV2>;
  /** @deprecated salesInteractionById query deprecated */
  readonly salesInteractionById: ReadonlyArray<CrmInteractionOutput>;
  readonly salesInteractionByIdV2: ReadonlyArray<CrmInteractionOutputV2>;
  /** @deprecated salesInteractionByStatus query deprecated */
  readonly salesInteractionByStatus: ReadonlyArray<CrmInteractionOutput>;
  readonly salesInteractionByStatusV2: ReadonlyArray<CrmInteractionOutputV2>;
  /** @deprecated salesInteractionContact query deprecated */
  readonly salesInteractionContact: ReadonlyArray<CrmInteractionContactOutput>;
  readonly salesInteractionContactV2: ReadonlyArray<CrmInteractionContactOutputV2>;
  /** @deprecated salesInteractionEntityLookup query deprecated */
  readonly salesInteractionEntityLookup: ReadonlyArray<Create_Crm_Interaction_Entity_Lookup_Output>;
  readonly salesInteractionEntityLookupV2: ReadonlyArray<CrmInteractionEntityLookupOutputV2>;
  /** @deprecated salesInteractionObjective query deprecated */
  readonly salesInteractionObjective: ReadonlyArray<CrmInteractionObjectiveOutput>;
  readonly salesInteractionObjectiveV2: ReadonlyArray<CrmInteractionObjectiveOutputV2>;
  /** @deprecated salesInteractionRepGroupAuditHistory query deprecated */
  readonly salesInteractionRepGroupAuditHistory: ReadonlyArray<CrmInteractionRepGroupOutput>;
  readonly salesInteractionRepGroupAuditHistoryV2: ReadonlyArray<CrmInteractionRepGroupOutputV2>;
  /** @deprecated salesInteractionRepGroupLookup query deprecated */
  readonly salesInteractionRepGroupLookup: ReadonlyArray<CreateInteractionRepGroupLookupOutput>;
  readonly salesInteractionRepGroupLookupV2: ReadonlyArray<CrmInteractionRepGroupLookupOutputV2>;
  /** @deprecated salesInteractionRescheduleAuditHistory query deprecated */
  readonly salesInteractionRescheduleAuditHistory: ReadonlyArray<CrmInteractionRescheduleOutput>;
  readonly salesInteractionRescheduleAuditHistoryV2: ReadonlyArray<CrmInteractionRescheduleAuditOutputV2>;
  readonly salesInteractionV2: ReadonlyArray<CrmInteractionOutputV2>;
  readonly salesInteractionsByReasonTermV2: ReadonlyArray<CrmInteractionOutputV2>;
  readonly salesgetPaginatedInteractions: CrmInteractionConnection;
  readonly salesportfolioInteractionCount: Scalars['Float'];
  readonly salesportfolioInteractionsByReps: CrmInteractionConnection;
  readonly crmCarrierNotesByEntityId: ReadonlyArray<CrmCarrierNotesOutput>;
  readonly crmCustomerNotesByEntityId: ReadonlyArray<CrmCustomerNotesOutput>;
  readonly crmCarrierDocumentsByEntityId: ReadonlyArray<CrmCarrierDocumentsOutput>;
  readonly crmCustomerDocumentsByEntityId: ReadonlyArray<CrmCustomerDocumentsOutput>;
  readonly salesIBandOBregionByEntityIdV3: ReadonlyArray<SalesOpportunityOutputV2>;
  readonly salesIBandOBregionByOpportunityIdV3: ReadonlyArray<SalesOpportunityOutputV2>;
  readonly salesOpportunitiesNames: ReadonlyArray<CrmOpportunitiesNamesOutput>;
  readonly salesOpportunitiesNamesByRep: ReadonlyArray<CrmOpportunitiesNamesOutput>;
  readonly salesOpportunitiesNamesByRepV3: ReadonlyArray<SalesOpportunitiesNamesOutput>;
  readonly salesOpportunitiesNamesV3: ReadonlyArray<SalesOpportunitiesNamesOutput>;
  readonly salesOpportunity: ReadonlyArray<CrmOpportunityOutput>;
  readonly salesOpportunityByEntityId: ReadonlyArray<CrmOpportunityOutput>;
  readonly salesOpportunityByEntityIdV2: ReadonlyArray<CrmOpportunityOutputV2>;
  readonly salesOpportunityByEntityIdV3: ReadonlyArray<SalesOpportunityOutputV2>;
  readonly salesOpportunityByOpportunityId: ReadonlyArray<CrmOpportunityOutput>;
  readonly salesOpportunityByOpportunityIdV2: ReadonlyArray<CrmOpportunityOutputV2>;
  readonly salesOpportunityByOpportunityIdV3: ReadonlyArray<SalesOpportunityOutputV2>;
  readonly salesOpportunityEntityIdV3: ReadonlyArray<SalesOpportunityOutputV2>;
  readonly salesOpportunityEntityLookupV2: ReadonlyArray<CrmOpportunityEntityLookupOutputV2>;
  readonly salesOpportunityEntityLookupV3: ReadonlyArray<SalesOpportunityEntityLookupOutputV2>;
  readonly salesOpportunityIdV3: ReadonlyArray<SalesOpportunityOutputV2>;
  readonly salesOpportunityV2: ReadonlyArray<CrmOpportunityOutputV2>;
  readonly salesOpportunityV3: ReadonlyArray<SalesOpportunityOutputV2>;
  readonly salesPortfolioOpportunitiesByReps: CrmOpportunityConnection;
  readonly salesPortfolioOpportunitiesByRepsV3: SalesOpportunityConnection;
  readonly salesRepsByOpportunityIdV2: ReadonlyArray<CrmOpportunityRepsOutputV2>;
  readonly salesRepsByOpportunityIdV3: ReadonlyArray<SalesOpportunityRepsOutputV2>;
  readonly validateSalesOpportunityName: Scalars['Boolean'];
  readonly validateSalesOpportunityNameByEntityIdV2: Scalars['Boolean'];
  readonly validateSalesOpportunityNameByEntityIdV3: Scalars['Boolean'];
  readonly validateSalesOpportunityNameV2: Scalars['Boolean'];
  readonly validateSalesOpportunityNameV3: Scalars['Boolean'];
  readonly customerCommitments?: Maybe<CustomerCommitmentConnection>;
  readonly customerCommitmentsSettings?: Maybe<CustomerCommitmentsSettings>;
  readonly customerCommitmentsById?: Maybe<CustomerCommitment>;
  readonly customerCommitmentOrdersById?: Maybe<CustomerCommitmentOrders>;
  readonly customerCapacityConstraintById?: Maybe<CapacityConstraint>;
  readonly customerCommitmentAcceptedOrders?: Maybe<ReadonlyArray<Maybe<AcceptedOrders>>>;
  readonly customerCapacityConstraints?: Maybe<ReadonlyArray<Maybe<CapacityConstraint>>>;
  readonly exportCustomerCommitments?: Maybe<CustomerCommitmentConnection>;
  readonly getAvailableCapacityConstraints?: Maybe<ReadonlyArray<Maybe<AvailableCapacityConstraint>>>;
  readonly customerCommitmentMetrics?: Maybe<CustomerCommitmentMetricsConnection>;
  readonly customerStructures: ReadonlyArray<CustomerStructure>;
  readonly customerStructuresByCustomerId: ReadonlyArray<CustomerStructure>;
  readonly customerDefaultNotes: ReadonlyArray<NoteDefault>;
  readonly notesByCustomerId: ReadonlyArray<NoteDefault>;
  readonly customerRegionsByCustomerId: ReadonlyArray<CustomerRegion>;
  readonly customerRegions: ReadonlyArray<CustomerRegion>;
  readonly customerFacilitiesByCustomerId: ReadonlyArray<CustomerFacility>;
  readonly customerFacilities: CustomerFacilityConnection;
  readonly customerCommoditiesByCustomerId: ReadonlyArray<CustomerCommodity>;
  readonly customerCommoditiesByFacilityId: ReadonlyArray<CustomerCommodity>;
  readonly customerCommodities: CustomerCommodityConnection;
  readonly customerCommoditiesByCustomerFacilityId: ReadonlyArray<CustomerCommodity>;
  readonly loadDefaults: LoadDefaultsConnection;
  readonly loadDefaultsByCustomerId?: Maybe<LoadDefaults>;
  readonly loadDefaultsByCustomerIdV2?: Maybe<ReadonlyArray<LoadDefaults>>;
  readonly customerCustomerRelationshipByCustomerId: ReadonlyArray<CustomerCustomerRelationship>;
  readonly parentOfRelatedCustomerByCustomerId?: Maybe<CustomerCustomerRelationship>;
  readonly customerCustomerRelationships: ReadonlyArray<CustomerCustomerRelationship>;
  readonly immediateChildrenOfCustomerByCustomerId?: Maybe<ReadonlyArray<CustomerChildren>>;
  readonly getCustomerUpperHierarchyByCustomerId?: Maybe<CustomerChildren>;
  readonly serviceTolerances: ServiceToleranceConnection;
  readonly carrierFacilitiesByCarrierId: ReadonlyArray<CarrierFacility>;
  readonly carrierFacilitiesByFacilityId: ReadonlyArray<CarrierFacility>;
  readonly carrierFacilities: ReadonlyArray<CarrierFacility>;
  readonly facilityDefaultNotes: ReadonlyArray<FacilityNoteDefaults>;
  readonly facilityNotesByFacilityId?: Maybe<FacilityNoteDefaults>;
  readonly facilityNotesByFacilityIdV2?: Maybe<ReadonlyArray<FacilityNoteDefaults>>;
  readonly facilityLoadDefaults: FacilityLoadDefaultsConnection;
  readonly facilityLoadDefaultsByFacilityId?: Maybe<FacilityLoadDefaults>;
  readonly facilityLoadDefaultsByFacilityIdV2?: Maybe<ReadonlyArray<FacilityLoadDefaults>>;
  readonly facilityFacilityRelationshipsByFacilityId: ReadonlyArray<FacilityFacility>;
  readonly facilityFacilityRelationships: ReadonlyArray<FacilityFacility>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly customerByCodeV2?: Maybe<CustomerV2>;
  readonly allCustomersV2?: Maybe<CustomerConnectionV2>;
  readonly customerAuditsNullable?: Maybe<CustomerAudits>;
  readonly getCustomerLinesOfBusinessByCustomerId: ReadonlyArray<LinesOfBusiness>;
  readonly getGeneratedCustomerCodeByParentCustomerId: ReadonlyArray<Scalars['String']>;
  readonly validateCustomerCode: Scalars['Boolean'];
  /** getCustomerDriverLocationFrequencyConfiguration: retrieve customer's driver location frequency configuration in Mastermind. */
  readonly getCustomerDriverLocationFrequencyConfiguration?: Maybe<DriverLocationFrequency>;
  /** getCustomerOutbound214Frequency: retrieve customer's driver location frequency configuration in Mastermind. */
  readonly getCustomerOutbound214Frequency?: Maybe<ReadonlyArray<Maybe<CustomerOutbound214Frequency>>>;
  /** getCustomerOutbound214EmailAddresses: retrieve a list of email addresses that are correlated to a specific customer and rule set. */
  readonly getOutbound214CustomerEmailAddresses?: Maybe<ReadonlyArray<Maybe<Outbound214EmailAddress>>>;
  /** getCarrierEdiTransactionConfigurations: retrieves a list of EDI transaction configurations from Data Exchange. */
  readonly getCarrierEdiTransactionConfigurations?: Maybe<ReadonlyArray<Maybe<CarrierEdiTransactionConfiguration>>>;
  /** getCustomerEdiTransactionConfigurations: retrieves a list of EDI transaction configurations from Data Exchange. */
  readonly getCustomerEdiTransactionConfigurations?: Maybe<ReadonlyArray<Maybe<CustomerEdiTransactionConfiguration>>>;
  /** returns json field mapping data */
  readonly getFieldMappingConfiguration?: Maybe<FieldMappingConfigurationOutputType>;
  readonly csvHeaders?: Maybe<CsvHeader>;
  readonly getDocumentById: EntityDocument;
  readonly facilityDocuments: FacilityDocumentConnection;
  readonly getFacilityDocumentById: FacilityDocument;
  readonly carrierDocuments: CarrierDocumentConnection;
  readonly getCarrierDocumentById: CarrierDocument;
  readonly loadDocuments: LoadDocumentConnection;
  readonly getLoadDocumentById?: Maybe<LoadDocument>;
  readonly ediDocuments: EdiDocumentConnection;
  readonly getEDIDocumentById: EdiDocument;
  readonly documents: ReadonlyArray<Document>;
  readonly crmDocuments: CrmDocumentConnection;
  readonly getCRMDocumentById: CrmDocument;
  /**
   * Gets the summary of HOS events for a driver
   * @deprecated getHosDriverSummary is Deprecated, use getDriverHosSummary instead
   */
  readonly getHosDriverSummary: DriverHosSummary;
  /** Gets the driver hos summary */
  readonly getDriverHosSummary: DriverHosLastEventSummary;
  /** Gets the daily summary of HOS events for a driver */
  readonly getHosDriverDailySummary: DriverHosDailySummary;
  /** Gets the audit history of HOS events for a driver */
  readonly getHosDriverAuditHistory: DriverHosAuditHistoryPayload;
  /** Gets the cycle recap summary of HOS events for a driver */
  readonly getHosCycleRecapSummary: HosCycleRecapSummary;
  /** Gets the cycle recap history of HOS events for a driver */
  readonly getHosCycleRecapHistory: DriverHosRecapHistoryPayload;
  /** Exports the audit history of HOS events for a driver */
  readonly exportHosAuditHistory: ReadonlyArray<DriverHos>;
  /** Exports the cycle recap history of HOS events for a driver */
  readonly exportHosRecapHistory: ReadonlyArray<DriverHosRecapHistory>;
  /** Gets the daily summary and cycle recap of HOS events for a driver */
  readonly getDriverHosSummaryAndRecap: HosSummaryAndRecap;
  /**
   * Returns a list of driver conversations
   * @deprecated Use driverConversationsV2 instead
   */
  readonly driverConversations?: Maybe<DriverConversationsResponse>;
  /** @deprecated Field no longer supported */
  readonly readFollowUpTimes?: Maybe<FollowUpTimesResponse>;
  readonly driverConversationsV2?: Maybe<DriverConversationsV2Connection>;
  readonly getSchedulesByDriverId: DriverSchedulePayload;
  readonly getAddressesByDriverId: DriverAddressPayload;
  readonly getTimeOffsByDriverId: DriverTimeOffPayload;
  readonly getContactsByDriverId: DriverContactPayload;
  readonly getAwardsByDriverId: DriverAwardPayload;
  readonly getCertificationsOrPermitsByDriverId: DriverCertificationOrPermitPayload;
  readonly getIdentifiersByDriverId: DriverIdentifierPayload;
  readonly getDriversByIdentifier: ReadonlyArray<AssetDriver>;
  readonly getEquipmentsByDriverId: DriverEquipmentPayload;
  readonly getRoutingRequestsByDriverId: DriverRoutingRequestPayload;
  readonly getNextDriverRoutingRequest: DriverRoutingRequestOutput;
  readonly getRoutingRequestsByDriverCode: DriverRoutingRequestPayload;
  readonly searchAssetDriver: SearchDriverPayload;
  readonly searchAssetDriverByNameOrCode: SearchDriverPayload;
  readonly getDriver: ReadonlyArray<AssetDriver>;
  readonly getDriverById: AssetDriver;
  readonly searchDriver: ReadonlyArray<DriverSearchResult>;
  readonly checkDriverIdentity: DriverValidation;
  readonly getDriverOperations: AssetDriver;
  readonly searchDriverByName: ReadonlyArray<DriverSearchResult>;
  readonly searchDriverById: ReadonlyArray<DriverSearchResult>;
  readonly getDriverFacilityPreferences: ReadonlyArray<DriverFacilityPreferences>;
  readonly getDriverCustomerPreferences: ReadonlyArray<DriverCustomerPreferences>;
  readonly getTrailers: ReadonlyArray<DriverKeyValuePairOfString>;
  readonly getPowers: ReadonlyArray<DriverKeyValuePairOfString>;
  readonly getDriverPreferredLanes: ReadonlyArray<DriverPreferredLanes>;
  readonly getDriverPreferredRoutes: ReadonlyArray<DriverPreferredRoutes>;
  readonly getActiveDriverPreferredLanes: ReadonlyArray<DriverPreferredLanes>;
  readonly getActiveDriverPreferredRoutes: ReadonlyArray<DriverPreferredRoutes>;
  readonly getDriverWeeklyTargets: ReadonlyArray<DriverWeeklyTarget>;
  readonly getDailyActivitiesByWeeklyTargetId: ReadonlyArray<DriverDailyActivity>;
  readonly getDriverTrainings: ReadonlyArray<DriverTraining>;
  readonly getDriverFleetsByDriverId: DriverFleetConnection;
  readonly getDriverRepsByDriverId: DriverRepresentativePayload;
  readonly getDriverByCode: AssetDriver;
  readonly getDriversByFleets: DriverActiveFleetPayload;
  readonly driverAutocomplete: ReadonlyArray<DriverAutocompleteOutput>;
  readonly getDriverAvailability: DriverAvailabilityPayload;
  readonly getDriverByCodeV2: AssetDriverV2;
  readonly getTeamPreferencesByDriverId: DriverTeamPreferencesPayload;
  readonly getCareerGoalsPreferencesByDriverId: DriverCareerGoalsPreferencesPayload;
  readonly getGeographyPreferencesByDriverId: DriverGeographyPreferencesPayload;
  readonly getAssetGeographyPreferencesByDriverId: AssetDriverGeographyPreferencesPayload;
  readonly getOperationalPreferencesByDriverId: DriverOperationalPreferencesPayload;
  readonly getCommodityPreferencesByDriverId: DriverCommodityPreferencesPayload;
  readonly getCustomerPreferencesByDriverId: DriverCustomerPreferencesPayload;
  readonly getFacilityPreferencesByDriverId: DriverFacilityPreferencesPayload;
  /** For testing features before they are fully exposed */
  readonly testDriverPayPrereleaseFeature: Scalars['String'];
  readonly getDriverPayAttributeDefinitions: ReadonlyArray<AttributeDefinitionModel>;
  readonly getDriverPayAttributes: ReadonlyArray<DriverAttributeModel>;
  readonly getDriverPayAttributesById: ReadonlyArray<DriverAttributeModel>;
  readonly calculateDriverPay: CalculateDriverPayResult;
  readonly calculateDriverProjectedPay: CalculateDriverProjectedPayResult;
  readonly driverPayProgram: DriverPayProgram;
  readonly getDriverPayPrograms: ReadonlyArray<PayProgramModel>;
  readonly getDriverPayProgramCarrierAssociations: ReadonlyArray<PayProgramCarrierAssociationModel>;
  readonly getDriverPayProgramDriverAssociations: ReadonlyArray<PayProgramDriverAssociationModel>;
  readonly getDriverPayRateTableHeaders: ReadonlyArray<PayRateTableHeaderModel>;
  readonly getDriverPayRateTableDetailsV2: ReadonlyArray<PayRateTableModelV2>;
  readonly getDriverPayProgramRouteAssignments: ReadonlyArray<DriverPayProgramRouteAssignmentModel>;
  readonly getDriverPayRateTableHistory: ReadonlyArray<PayRateTableHistoryModel>;
  readonly allEmployeesV2?: Maybe<EmployeeConnectionV2>;
  readonly employeeAuditsNullable?: Maybe<EmployeeAudits>;
  readonly postingCredentialsByEmployeeId?: Maybe<ReadonlyArray<Maybe<EmployeePostingCredentials>>>;
  readonly employeeV2?: Maybe<EmployeeV2>;
  /** @deprecated Moved to AllEmployees */
  readonly employeesV2?: Maybe<ReadonlyArray<EmployeeV2>>;
  readonly exactEmployeeV2?: Maybe<EmployeeV2>;
  readonly exactEmployeeManyV2?: Maybe<ReadonlyArray<EmployeeV2>>;
  readonly usersV2: UserConnectionV2;
  readonly exactUserV2?: Maybe<UserV2>;
  readonly whoAmIV2?: Maybe<WhoAmIv2>;
  /** Get tenant level default configuration for a given domain */
  readonly getTenantConfiguration?: Maybe<TenantConfigurationResult>;
  /** Get default configuration for a given entity type */
  readonly getDefaultConfiguration?: Maybe<DefaultConfigurationResult>;
  /** Get configuration by uuid, or active configuration if no uuid supplied */
  readonly getEntityConfiguration?: Maybe<EntityConfigurationResult>;
  /** Gets all configurations for a given entity */
  readonly getAllEntityConfigurations: ReadonlyArray<Maybe<EntityConfigurationResult>>;
  readonly getCurrentEntityConfigurationVersion?: Maybe<Scalars['String']>;
  /** Gets a single string value for a given key */
  readonly entityStringLookup: ReadonlyArray<EntityStringKeyValue>;
  /**
   * Gets a geolocation by locationId
   * @deprecated Use getEntityGeofencingCustomerRecord or getEntityGeofencingLocationRecord instead
   */
  readonly getGeofencingLocation?: Maybe<EntityGeofencingLocation>;
  /**
   * Gets a geolocation by locationId
   * @deprecated Use getEntityGeofencingCustomerRecord or getEntityGeofencingLocationRecord instead
   */
  readonly getGeofencingLocationV2?: Maybe<EntityGeofencingLocationV2>;
  /** Gets audit history information for Geofencing locations */
  readonly getGeofencingLocationAuditHistory: ReadonlyArray<GeofencingLocationAuditHistory>;
  readonly domainKeyValuePairs: ReadonlyArray<EntityStringKeyValue>;
  /** Gets configured customer geofencing record */
  readonly getEntityGeofencingCustomerRecord?: Maybe<EntityGeofencingCustomerRecord>;
  /** Gets configured location geofencing record */
  readonly getEntityGeofencingLocationRecord?: Maybe<EntityGeofencingLocationRecord>;
  readonly GenerateETA?: Maybe<EtaCalculatorResponse>;
  readonly generateHoSInferredETA?: Maybe<Scalars['String']>;
  readonly getDataScienceETASolutionV2?: Maybe<EtaDataScienceCalculatorV2Solution>;
  readonly manualEtaByStopId?: Maybe<ManualEstimate>;
  readonly manualEtdByStopId?: Maybe<ManualEstimate>;
  readonly autoEstimateByStopId?: Maybe<AutoEstimate>;
  readonly getAdHocETACalculatorSolutionV2?: Maybe<EtaCalculatorV2Solution>;
  readonly allFacilitiesV2: FacilityConnectionV2;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly facilityByCodeV2?: Maybe<FacilityV2>;
  readonly facilitiesV2: ReadonlyArray<FacilityV2>;
  readonly facilityAuditsNullable?: Maybe<FacilityAudits>;
  /** return facility sublocation activity by its id */
  readonly facilitySublocationActivityById: FacilitySublocationActivity;
  /** return facility sublocation activities by sublocation id */
  readonly facilitySublocationActivities: FacilitySublocationActivityConnection;
  /** return facility sublocation accommodation by its id */
  readonly facilitySublocationAccommodationById: FacilitySublocationAccommodation;
  /** return facility sublocation accommodations by sublocation id */
  readonly facilitySublocationAccommodations: FacilitySublocationAccommodationConnection;
  /** return facility sublocation by its id */
  readonly facilitySublocationById: FacilitySublocation;
  /** return all the sublocations by facility Id */
  readonly sublocationsByFacilityId: ReadonlyArray<FacilitySublocation>;
  /** Return all the sublocations by facility Id */
  readonly sublocationsByFacilityIdV2: ReadonlyArray<CustomFacilitySublocation>;
  /** return facility sublocation gate by its id */
  readonly facilitySublocationGateById: FacilitySublocationGate;
  /** return facility sublocation gates by sublocation id */
  readonly facilitySublocationGates: FacilitySublocationGateConnection;
  /** Get the latest intraday exchange rate. */
  readonly getCurrentRate?: Maybe<CurrentRate>;
  /** Get Mastery's fixed historical rate or the world's actual historical rate */
  readonly getHistoricRate?: Maybe<HistoricRate>;
  readonly getFuelProgramCustomerConfigurationSummary?: Maybe<ReadonlyArray<FuelProgramCustomerConfigurationModel>>;
  readonly getFuelProgramCustomerConfiguration?: Maybe<FuelProgramCustomerConfigurationModel>;
  readonly getEstimatedFuel?: Maybe<EstimatedFuelSurcharge>;
  readonly hasQualifiedFuelProgram?: Maybe<EligibleFuelProgramResponse>;
  readonly getFinalizedFuel?: Maybe<FinalizedFuelSurcharge>;
  readonly getHistoricalFuelIndexPricesByFuelIndexType?: Maybe<ReadonlyArray<HistoricalFuelIndexPriceResponse>>;
  readonly getCustomerFuelRateHeaderResponseByCustomerAndFuelProgram?: Maybe<CustomerFuelRateHeaderResponse>;
  readonly getCustomerFuelRangesByCustomerFuelRateHeaderId?: Maybe<ReadonlyArray<CustomerFuelRangeModel>>;
  readonly hasQualifiedCarrierFuelProgram?: Maybe<EligibleFuelProgramResponse>;
  /** DEPRECATED - `getQuotingFuelSurcharge` is no longer used - due to changed of pickupDate/deliveryDate/tenderDate datatype from DateTime to Date. */
  readonly getQuotingFuelSurcharge?: Maybe<QuotingFuelSurcharge>;
  readonly getQuotingFuelSurchargeV2?: Maybe<QuotingFuelSurcharge>;
  readonly getFuelProgramCarrierConfigurationSummary?: Maybe<ReadonlyArray<FuelProgramCarrierConfigurationModel>>;
  readonly getCarrierFuelRangesByCarrierFuelRateHeaderId?: Maybe<ReadonlyArray<CarrierFuelRangeModel>>;
  readonly getCarrierFuelRateHeaderResponseByCarrierAndFuelProgram?: Maybe<CarrierFuelRateHeaderResponse>;
  readonly getFuelForExternalServices?: Maybe<FuelSurchargeForExternalServices>;
  readonly getCarrierFuelForExternalServices?: Maybe<CarrierFuelSurchargeForExternalServices>;
  /** Searches for a specific geography record */
  readonly geographyV2: GeographyServiceRecord;
  /** Searches for a collection of geography records based on search criteria */
  readonly geographiesV2: GeographyRecordConnection;
  readonly getCurrentGeographiesVersion?: Maybe<Scalars['String']>;
  /** Searches for a collection of countries based on a postal code */
  readonly getPostalCodeCountries: ReadonlyArray<Maybe<PostalCodeCountry>>;
  /** Searches for a collection of states based on a full/partial postal code */
  readonly getPostalCodeStates: ReadonlyArray<Maybe<PostalCodeState>>;
  /** Searches for an admin area based on a variety of input */
  readonly getAdminArea?: Maybe<ReadonlyArray<GeographyAdminArea>>;
  readonly bulkGeographyRequest: BulkGeographyResponse;
  readonly getGeographyAuditHistory: ReadonlyArray<GeographyAuditHistory>;
  readonly externalGeography: ReadonlyArray<ExternalGeography>;
  /** A list of all the Widgets. For Testing Purposes Only. */
  readonly widgets?: Maybe<ReadonlyArray<Widget>>;
  /** return group by its id */
  readonly groupById?: Maybe<Group>;
  /** return all the Project */
  readonly allGroups: AllGroups;
  readonly ruleSetEvaluation?: Maybe<RuleSetEvaluationResult>;
  readonly searchRuleSets?: Maybe<SearchRuleSetsResult>;
  readonly getEmailDomains?: Maybe<GetEmailDomainsResult>;
  readonly searchTemplates?: Maybe<SearchTemplatesResult>;
  readonly searchHdsTemplates?: Maybe<SearchTemplatesResult>;
  readonly searchHaykEvents?: Maybe<SearchHaykEventsResult>;
  readonly searchAuditHistory?: Maybe<SearchAuditHistoryResult>;
  readonly searchHaykRedirects?: Maybe<SearchHaykRedirectsResult>;
  readonly searchHaykOperators?: Maybe<SearchHaykOperatorsResult>;
  readonly searchHaykFieldMappings?: Maybe<HaykFieldMappingsResult>;
  readonly getPricingData?: Maybe<ImdlOutput>;
  readonly getSniPricingData?: Maybe<SniQuoteOutput>;
  readonly allServiceProfile: ReadonlyArray<ServiceProfile>;
  readonly serviceProfileById: ServiceProfile;
  readonly allServiceProfileV2: ReadonlyArray<ServiceProfileV2>;
  readonly serviceProfileByIdV2: ServiceProfileV2;
  readonly allSchedules: ReadonlyArray<Schedules>;
  readonly allSchedulesV2: ReadonlyArray<SchedulesV2>;
  readonly incidentsV2: IncidentConnectionV2;
  readonly incidentByIDV2?: Maybe<IncidentV2>;
  /** Get Audits Per loadId */
  readonly GetLoadAuditDetails?: Maybe<LoadAuditResponseConnection>;
  /** lachesis-api: Returns the most recent route status calculated for <route_id> */
  readonly getLseRouteVendorStatusRecordV3: ReadonlyArray<RouteVendorFinancialStatusV3>;
  /** lachesis-api: Returns the most recent route status calculated for <route_id> */
  readonly getLseRouteStatusRecordV3: RouteStatusRecordV3;
  /** lachesis-api: Returns the most recent order status calculated for <order_id> */
  readonly getLseOrderStatusRecordV3: OrderStatusRecordV3;
  /** lachesis-api: Returns up to most recent <limit> load status records for load <load_id>. <limit> defaults to 1000 if omitted. */
  readonly getLoadStatusRecordsAuditV3: ReadonlyArray<LoadStatusRecordV3>;
  /** lachesis-api: Returns all possible status values related to Order Lifecycle Status */
  readonly getOrderLifecycleDomainStatusRecords: ReadonlyArray<DomainStatusRecord>;
  /** lachesis-api: Returns all possible status values related to Route Lifecycle Status */
  readonly getRouteLifecycleDomainStatusRecords: ReadonlyArray<DomainStatusRecord>;
  /** lachesis-api: Returns all possible status values related to Order Tender Status */
  readonly getOrderTenderDomainStatusRecords: ReadonlyArray<DomainStatusRecord>;
  /** lachesis-api: Returns all possible status values related to Route Tender Status */
  readonly getRouteTenderDomainStatusRecords: ReadonlyArray<DomainStatusRecord>;
  /** lachesis-api: Returns all possible status values related to Order Activation Status */
  readonly getOrderActivationDomainStatusRecords: ReadonlyArray<DomainStatusRecord>;
  /** lachesis-api: Returns all possible status values related to Route Activation Status */
  readonly getRouteActivationDomainStatusRecords: ReadonlyArray<DomainStatusRecord>;
  /** lachesis-api: Returns all possible status values related to Order Financial Status */
  readonly getOrderFinancialDomainStatusRecords: ReadonlyArray<DomainStatusRecord>;
  /** lachesis-api: Returns all possible status values related to Route Vendor Financial Status */
  readonly getRouteVendorFinancialDomainStatusRecords: ReadonlyArray<DomainStatusRecord>;
  /** Lane: Verify lane exists and returns details. */
  readonly CheckIfLaneExistV3?: Maybe<LaneV2>;
  /** LaneV2: Retrieves lane Details. */
  readonly laneV2?: Maybe<LaneV2>;
  /** Lane: Filter and retrieves list of lane Details. */
  readonly lanePaginatedV2?: Maybe<LaneV2Connection>;
  /** Lane: Retrieves all allowed Stop Types for Lanes. */
  readonly laneStopTypes?: Maybe<ReadonlyArray<Maybe<LaneKeyValue>>>;
  /** Lane: Retrieves all allowed Stop Types for RQ. */
  readonly laneStopTypesForRQ?: Maybe<ReadonlyArray<Maybe<LaneKeyValue>>>;
  /** Lane: Retrieves all allowed Stop Types for CQ. */
  readonly laneStopTypesForCQ?: Maybe<ReadonlyArray<Maybe<LaneKeyValue>>>;
  /** Lane: Finds lanes matching the input routes. */
  readonly findMatchingLanesForRoutes?: Maybe<FindMatchingLanesForRoutesResult>;
  /** Lane: Returns orders matching the lane. */
  readonly laneOrders?: Maybe<LaneOrderMatchConnection>;
  /** Lane: Returns routes matching the lane. */
  readonly laneRoutes?: Maybe<LaneRouteMatchConnection>;
  /** Lane: Get lanes matched to the order from the most recent LLM order match run, sorted most to least granular. */
  readonly orderMatchedLanes: ReadonlyArray<OrderMatchedLane>;
  /** Lane: Get lanes matched to the route from the most recent LLM route match run, sorted most to least granular. */
  readonly routeMatchedLanes: ReadonlyArray<RouteMatchedLane>;
  /** Get linked route */
  readonly linkedRouteV2?: Maybe<LinkedRouteV2>;
  /** Get linked routes */
  readonly linkedRoutes?: Maybe<LinkedRoutes>;
  /** Cleanse address input, multilineAddresses OR singlelineAddresses required */
  readonly cleanseAddresses?: Maybe<AddressResults>;
  /**
   * Cleanse singleline address input, eg: '123 1st Ave, New York, NY'
   * @deprecated use cleanseAddresses query with singlelineAddresses filter
   */
  readonly cleanseSingleLineAddress?: Maybe<SingleLineResult>;
  /**
   * Cleanse Multiline addresses, @deprecated in favor of cleanseAddress
   * @deprecated use cleanseAddresses query with multilineAddresses filter
   */
  readonly cleanseMultiLineAddress?: Maybe<MultilineAddressResults>;
  /** Address autocomplete for UI interactivity */
  readonly autocompleteAddress?: Maybe<ReadonlyArray<Maybe<FormattedAddress>>>;
  readonly zipCodeAddress?: Maybe<ZipCodeFormatResult>;
  readonly addressCleanseStatus?: Maybe<AddressCleanseStatusConnection>;
  readonly geocode?: Maybe<GeocodeResponse>;
  readonly batchGeocode: BatchGeocodeResponse;
  readonly reverseGeocode?: Maybe<GeocodeResponse>;
  readonly batchReverseGeocode?: Maybe<BatchGeocodeResponse>;
  readonly lookupTimezone?: Maybe<TimezoneResponse>;
  readonly getCurrentLocationServicesVersion?: Maybe<Scalars['String']>;
  readonly geocodeV2: ReadonlyArray<Maybe<GeocodeResponse>>;
  readonly addressMatchStrength?: Maybe<AddressMatchStrength>;
  readonly lookupLocality: LocalityLookupResponse;
  readonly getAdministrativeAreaDetails?: Maybe<AdministrativeAreaResponse>;
  readonly getAllTimezones?: Maybe<ReadonlyArray<TimezoneComponent>>;
  /** Determines whether or not a coordinate is in range of a facility's geofence */
  readonly coordinateIsInRangeOfGeofence?: Maybe<CoordinateIsInRangeOfGeofenceResponse>;
  readonly customerPricingProgramsV2: PricingProgramConnection;
  readonly customerPricingProgramsV3: PricingProgramConnection;
  readonly carrierPricingProgramsV2: PricingProgramConnection;
  readonly getAllCustomerMarkupByCustomerCode?: Maybe<CustomerMarkupResponsePayloads>;
  readonly getAllCustomerMarkupByCustomerCodeV2?: Maybe<MarkupPagedResponse>;
  readonly getAllCarrierPreferenceByCustomerCode?: Maybe<CarrierPreferenceResponsePayloads>;
  readonly getBidsPaginated?: Maybe<BidConnection>;
  readonly getBidLanesPaginated?: Maybe<BidLaneConnection>;
  readonly getRoundsByBidId?: Maybe<BidRoundPayload>;
  readonly getAwardedQuotesByBidId?: Maybe<AwardQuotesPayload>;
  readonly getBidQuotesByBidLaneId?: Maybe<ReadonlyArray<Maybe<BidQuoteWithCapacity>>>;
  readonly getImportAwardStaging?: Maybe<ImportAwardsStaging>;
  /**
   * Calculate total distance for lat/long stops.  Returns results in kilometers
   * @deprecated Please update to calculateDistanceLength for consistent behavior across unit conversions and stops consisting of lat/long pairs or postal codes
   */
  readonly calculateMileage: ReadonlyArray<MileageCalculation>;
  /**
   * Calculate total distance for lat/long stops for a carrier.  Returns results in kilometers
   * @deprecated Please update to calculateCarrierDistanceLength for consistent behavior across unit conversions and stops consisting of lat/long pairs or postal codes
   */
  readonly calculateCarrierMileage: ReadonlyArray<MileageCalculation>;
  /**
   * Calculate total distance for lat/long stops for a customer.  Returns results in kilometers
   * @deprecated Please update to calculateCustomerDistanceLength for consistent behavior across unit conversions and stops consisting of lat/long pairs or postal codes
   */
  readonly calculateCustomerMileage: ReadonlyArray<MileageCalculation>;
  /**
   * Calculate total distance for lat/long stops.  Returns results in kilometers unless unit is specified
   * @deprecated Please update to calculateDistanceLength for stops consisting of lat/long pairs or postal codes
   */
  readonly calculateMileageLength: ReadonlyArray<MileageCalculationLength>;
  /** Calculate total distance for stops consisting of coordinates, postal codes, or localities with admin areas.  Returns results in kilometers unless unit is specified */
  readonly calculateDistanceLength: ReadonlyArray<MileageCalculationLength>;
  /**
   * Calculate total distance for lat/long stops for a carrier.  Returns results in kilometers unless unit is specified
   * @deprecated Please update to calculateCarrierDistanceLength for stops consisting of lat/long pairs or postal codes
   */
  readonly calculateCarrierMileageLength: ReadonlyArray<MileageCalculationLength>;
  /** Calculate total distance for stops consisting of coordinates, postal codes, or localities with admin areas for a carrier.  Returns results in kilometers unless unit is specified */
  readonly calculateCarrierDistanceLength: ReadonlyArray<MileageCalculationLength>;
  /**
   * Calculate total distance for lat/long stops for a customer.  Returns results in kilometers unless unit is specified
   * @deprecated Please update to calculateCustomerDistanceLength for stops consisting of lat/long pairs or postal codes
   */
  readonly calculateCustomerMileageLength: ReadonlyArray<MileageCalculationLength>;
  /** Calculate total distance for stops consisting of coordinates, postal codes, or localities with admin areas for a customer.  Returns results in kilometers unless unit is specified */
  readonly calculateCustomerDistanceLength: ReadonlyArray<MileageCalculationLength>;
  readonly getCurrentMileageProviderVersion?: Maybe<Scalars['String']>;
  readonly getMileageOutput?: Maybe<MileageOutput>;
  /** @deprecated Please update to lookupCountyQualifier for Milemaker county lookups */
  readonly lookupCountyQualifer?: Maybe<ReadonlyArray<MileMakerValidationRecord>>;
  readonly lookupCountyQualifier?: Maybe<ReadonlyArray<MileMakerValidationRecord>>;
  /** @deprecated Use findDriverMobileScheduledJobs instead */
  readonly findScheduledJobs: ReadonlyArray<ScheduledJob>;
  /** @deprecated Use findDriverMobileScheduledJobs instead */
  readonly findScheduledJobsV2: ScheduledJobPayload;
  readonly findDriverMobileScheduledJobs: DriverMobileScheduledJobPayload;
  /** @deprecated Use getDriverRoutesV2 instead */
  readonly getDriverRoutes: GetDriverRoutesPayLoad;
  /** @deprecated Use getDriverMobileRoutes instead */
  readonly getDriverRoutesV2: GetDriverRoutesPayLoadV2;
  readonly getDriverMobileRoutes: GetDriverRoutesPayLoadV2;
  /** @deprecated Use getDriverRouteV3 instead */
  readonly getDriverRoute: GetDriverRoutePayload;
  /** @deprecated Use getDriverRouteV3 instead */
  readonly getDriverRouteV2: GetDriverRoutePayloadV2;
  /** @deprecated Use getDriverMobileRoute instead */
  readonly getDriverRouteV3: GetDriverRoutePayloadV3;
  readonly getDriverMobileRoute: GetDriverRoutePayloadV3;
  /** @deprecated Use getAllDevicesByDriverMobileID instead */
  readonly getAllDevicesByDriverID: UserDevicePayload;
  readonly getAllDevicesByDriverMobileID: UserDevicePayload;
  /** @deprecated Use getDriverMobileDetailsByEmailV2 instead */
  readonly getDriverMobileDetailsByEmail: MobileDriverDetailsPayload;
  readonly getDriverMobileDetailsByEmailV2: MobileDriverDetailsPayloadV2;
  readonly getDriverMobileInvitationAudits: GetDriverMobileInvitationAuditsPayload;
  readonly offer: Offer;
  readonly offerByCode: Offer;
  readonly offerByCodeV2?: Maybe<Offer>;
  readonly offers: ReadonlyArray<Offer>;
  readonly getOffersPaginated: OfferConnection;
  readonly offersCount: Scalars['Float'];
  readonly optiMatchSession?: Maybe<OptiMatchSession>;
  readonly getAlternativeMatches?: Maybe<OptiMatchFeasiblePairs>;
  /** Lists all custom objectives */
  readonly listObjectives?: Maybe<ReadonlyArray<Maybe<OptiMatchCustomObjective>>>;
  readonly filteredPlannedNetworks?: Maybe<ReadonlyArray<PlannedNetwork>>;
  readonly plannedNetwork?: Maybe<PlannedNetwork>;
  readonly networkChannel?: Maybe<NetworkChannel>;
  readonly channelLeg?: Maybe<ChannelLeg>;
  readonly posting: Posting;
  readonly postings?: Maybe<ReadonlyArray<Posting>>;
  readonly postingsCsConfigs: PostingsCsConfigs;
  readonly postingsByRouteIds?: Maybe<ReadonlyArray<Posting>>;
  readonly loadboards: ReadonlyArray<LoadBoard>;
  readonly embedParams?: Maybe<EmbedParams>;
  readonly getMaintenanceByPowerId: PowerMaintenancePayload;
  readonly searchPowerEquipment: PowerEquipmentPayload;
  readonly getAssetPower: ReadonlyArray<AssetPowerOutput>;
  readonly getAssetPowerbyId: ReadonlyArray<AssetPowerOutput>;
  /** @deprecated getassetPowerStatusDetails query deprecated. */
  readonly getassetPowerStatusDetails: ReadonlyArray<AssetPowerOutput>;
  readonly getAssetPowerSearch: ReadonlyArray<AssetPowerOutput>;
  readonly searchAssetPower: AssetPowerConnection;
  readonly checkPowerIdentity: PowerValidation;
  readonly getPowerDrivers: ReadonlyArray<PowerDriverOutput>;
  readonly getPowerTractors: ReadonlyArray<PowerTrailerOutput>;
  readonly searchPowerDriverByName: ReadonlyArray<PowerDriverOutput>;
  readonly searchPowerDriverById: ReadonlyArray<PowerDriverOutput>;
  readonly getPowerByCodeV2: AssetPowerOutputV2;
  readonly getPowerByCode: AssetPowerOutput;
  readonly getPowerPatchByCode: AssetPowerOutput;
  readonly getPowerFleetsByPowerId: PowerFleetRelationshipPayload;
  readonly getIdentifiersByPowerId: PowerIdentifierPayload;
  readonly getPowersByFleets: PowerCarrierSearchConnection;
  readonly powerAutoComplete: ReadonlyArray<PowerAutoCompleteOutput>;
  /** @deprecated getAssetPowerPingHistory query deprecated */
  readonly getAssetPowerPingHistory: PowerPingHistoryPayload;
  readonly getPowerPingHistory: PowerPingHistoryPayload;
  readonly preplanById?: Maybe<Preplan>;
  readonly preplanByRouteId?: Maybe<Preplan>;
  readonly preplanByRouteIdV2?: Maybe<Preplan>;
  /** mastery-pricing-service: Retrieves advertised price details for given loads and loadBoard codes. */
  readonly advertisedPriceDetails: AdvertisedPriceConnection;
  /** mastery-pricing-service: Retrieves carrier costs from external rate API. */
  readonly carrierCostsByRoute?: Maybe<CarrierCostsByRouteConnection>;
  /** mastery-pricing-service: Retrieves customer order rates from external rate API. */
  readonly customerOrderRatesByCarrier?: Maybe<CustomerOrderRatesByCarrierConnection>;
  /** mastery-pricing-service: Retrieves customer order rates from external rate API. */
  readonly customerOrderRatesV2?: Maybe<CustomerOrderRatesConnectionV2>;
  /** mastery-pricing-service: Retrieves DAT contract rates by routes or other inputs from external rate API. */
  readonly datContractRates?: Maybe<DatContractRatesConnection>;
  /** mastery-pricing-service: Retrieves DAT spot rates by routes or other inputs from external rate API. */
  readonly datSpotRates?: Maybe<DatSpotRatesConnection>;
  /** mastery-pricing-service: Retrieves Expected cost detail for given route code and order code. */
  readonly expectedCostDetail: ExpectedCost;
  /** mastery-pricing-service: Retrieves ftl prices by quote. */
  readonly FtlPricesByQuote: FtlPricesByQuoteType;
  /** mastery-pricing-service: Retrieves IMDL Prices by Quote */
  readonly imdlPricesByQuote?: Maybe<ImdlPricesByQuotePayload>;
  /** mastery-pricing-service: Retrieves LTL Prices by Order */
  readonly ltlPricesByOrder?: Maybe<LtlPricesByOrderPayload>;
  /** mastery-pricing-service: Retrieves LTL Prices by Quote */
  readonly ltlPricesByQuote?: Maybe<LtlPricesByQuotePayload>;
  /** mastery-pricing-service: Retrieves Most Recent LTL rating data */
  readonly mostRecentLTLRatingData?: Maybe<LtlRatingDataPayload>;
  /** mastery-pricing-service: Retrieves SNI bin price details or Werner price details. */
  readonly PriceDetails: PriceDetailsConnection;
  /** mastery-pricing-service: Retrieves Generic Pricing by quote details from external Generic pricing API. */
  readonly pricingQuote?: Maybe<PricingQuoteConnection>;
  /** mastery-pricing-service: Retrieves Generic Pricing by routes from external Generic pricing API. */
  readonly pricingRoute?: Maybe<PricingRouteConnection>;
  /** mastery-pricing-service: Retrieves quote pricing details. */
  readonly quotePricingDetails: QuotePricingDetailsConnection;
  /** Returns a single Available Route */
  readonly availableRoute?: Maybe<AvailableRoute>;
  /**
   * Returns all available routes that are available at any given time and combines both Mastermind Loads/Routes with external Loads.
   *         This powers the routes that appear on the State Map and available Routes screens.
   */
  readonly allAvailableRoutes: AvailableRouteConnection;
  /** Retrieve a single Available Truck */
  readonly availableTruck?: Maybe<AvailableTruck>;
  /** Retrieve all available trucks from ElasticSearch */
  readonly allAvailableTrucks: AvailableTruckConnection;
  /** Returns the available routes and trucks by state (current) to power the state map within MasterMind */
  readonly procurementCountsByArea: ReadonlyArray<ProcurementCounts>;
  /** Retrieve a single available gate reservations */
  readonly availableGateReservation?: Maybe<AvailableGateReservation>;
  /** Retrieve all available gate reservations from ElasticSearch */
  readonly allAvailableGateReservations: AvailableGateReservationConnection;
  /** Retrieve a single available container */
  readonly availableContainer?: Maybe<AvailableContainer>;
  /** Retrieve all available container from ElasticSearch */
  readonly allAvailableContainers: AvailableContainerConnection;
  /** Returns route matches from ElasticSearch given a truck, carrier, or employee. */
  readonly matches: MatchConnection;
  /** On demand (ad hoc) search to find all available routes that match the provided truck posting and filter criteria. */
  readonly searchMatchingRoutesForTruck: MatchConnection;
  /** On demand (ad hoc) search to find all available trucks that match the given route and filter criteria. */
  readonly searchMatchingTrucksForRoute: MatchConnection;
  /** Returns route matches from ElasticSearch given a gate reservation, carrier, or employee. */
  readonly gateReservationMatches: GateReservationMatchConnection;
  /** On demand (ad hoc) search to find all available gate reservations that match the given route and filter criteria. */
  readonly searchMatchingGateReservationsForRoute: GateReservationMatchConnection;
  /** Returns precomputed route + container matches from ElasticSearch */
  readonly containerMatches: ContainerMatchConnection;
  /** On demand (ad hoc) search to find available routes that match the provided container and filter criteria. */
  readonly searchMatchingRoutesForContainer: ContainerMatchConnection;
  /** On demand (ad hoc) search to find all available containers that match the given route and filter criteria. */
  readonly searchMatchingContainersForRoute: ContainerMatchConnection;
  /** return project by its id */
  readonly projectById?: Maybe<Project>;
  /** return all the Project */
  readonly projects: ProjectsList;
  readonly routeVendorLoadTenderFirstSent?: Maybe<LoadTender>;
  readonly routeVendorLoadTenderLastSent?: Maybe<LoadTender>;
  /** @deprecated Use rate confirmations V2. */
  readonly rateConfirmations?: Maybe<ReadonlyArray<RateConfirmation>>;
  readonly rateConExceptionsHelloWorld: Scalars['String'];
  /**
   * DO NOT USE. This service is depricated. Please use customerQuotePaginatedV3.
   * @deprecated This service is depricated. Please use customerQuotePaginatedV3.
   */
  readonly customerQuotePaginatedV2?: Maybe<CustomerQuotePaginatedV2Connection>;
  /**
   * mastery-ratequote: customerQuotePaginatedV3 - Retrieves a list of customer quotes.
   * @deprecated This service is depricated. Please use customerQuotePaginatedV4.
   */
  readonly customerQuotePaginatedV3?: Maybe<CustomerQuoteV3Connection>;
  /**
   * mastery-ratequote: customerQuotePaginatedV4 - Retrieves a list of customer quotes.
   * @deprecated This service is depricated. Please use customerQuotePaginatedV5.
   */
  readonly customerQuotePaginatedV4?: Maybe<CustomerQuoteV3Connection>;
  /** mastery-ratequote: customerQuotePaginatedV5 - Retrieves a list of customer quotes. */
  readonly customerQuotePaginatedV5?: Maybe<CustomerQuoteV4Connection>;
  /**
   * CustomerQuote: Retrieves quote details.
   * @deprecated This service is depricated. Please use getCustomerQuoteByIdV2.
   */
  readonly getCustomerQuoteById?: Maybe<CustomerQuoteV3>;
  /**
   * CustomerQuote: Retrieves quote details.
   * @deprecated This service is depricated. Please use getCustomerQuoteByIdV3.
   */
  readonly getCustomerQuoteByIdV2?: Maybe<CustomerQuoteV3>;
  /** CustomerQuote: Retrieves quote details. */
  readonly getCustomerQuoteByIdV3?: Maybe<CustomerQuoteV4>;
  /**
   * mastery-ratequote: MatchingRateQuote - Retrirves a single rate quote.
   * @deprecated This service is depricated. Please use MatchingRateQuoteV2.
   */
  readonly matchingRateQuote?: Maybe<MatchingRateQuotePayload>;
  /** mastery-ratequote: MatchingRateQuoteV2 - Retrirves a single rate quote. */
  readonly matchingRateQuoteV2?: Maybe<MatchingRateQuotePayloadV2>;
  /** MatchingRateQuoteV3 - Retrirves a single matching rate quote. */
  readonly matchingRateQuoteV3?: Maybe<MatchingRateQuotePayloadV2>;
  /**
   * Retrieves all customers
   * @deprecated User AllCustomersNullable instead
   */
  readonly allCustomers: CustomerConnection;
  /**
   * Retrieves all customers
   * @deprecated Removed this query. Use V2 query. Query will be removed soon
   */
  readonly allCustomersNullable?: Maybe<CustomerConnection>;
  /**
   * gets all employees
   * @deprecated Removed this query. Use V2 query. Query will be removed soon
   */
  readonly allEmployees?: Maybe<EmployeeConnection>;
  /**
   * Retrieves all facilities
   * @deprecated Removed this query. Use V2 query. Query will be removed soon
   */
  readonly allFacilities: FacilityConnection;
  /** All Temp Loads */
  readonly allTempLoads: TempLoadConnection;
  /** Returns bounces by search criteria */
  readonly bounces: BounceConnection;
  /**
   * Returns a single carrier based on the ID
   * @deprecated Removed this query. Use V2 query. Query will be removed soon
   */
  readonly carrier?: Maybe<Carrier>;
  /**
   * Returns a single carrier based on the ID or CODE
   * @deprecated Removed this query. Use V2 query. Query will be removed soon
   */
  readonly carrierNullable?: Maybe<Carrier>;
  /**
   * Returns all carrier reps
   * @deprecated Removed this query. Use V2 query. Query will be removed soon
   */
  readonly carrierReps: ReadonlyArray<Rep>;
  /**
   * Gets a single Carrier Settings by Carrier's ID
   * @deprecated Moved to CarrierType
   */
  readonly carrierSetting?: Maybe<CarrierSetting>;
  /**
   * Retrieves all carriers or search carriers
   * @deprecated Use CarriersNullable instead
   */
  readonly carriers: CarrierConnection;
  /**
   * Retrieves all carriers or search carriers
   * @deprecated Removed this query. Use V2 query. Query will be removed soon
   */
  readonly carriersNullable?: Maybe<CarrierConnection>;
  /** Retrieves all commodities */
  readonly commodities: CommodityConnection;
  /** Returns a count of loads/trucks by administrative area */
  readonly countsByArea?: Maybe<ReadonlyArray<AreaCount>>;
  /**
   * Returns a single customer based on the ID
   * @deprecated Removed this query. Use V2 query. Query will be removed soon
   */
  readonly customer?: Maybe<Customer>;
  /**
   * Returns a single customer by its code
   * @deprecated Removed this query. Use V2 query. Query will be removed soon
   */
  readonly customerByCode?: Maybe<Customer>;
  /**
   * Returns quotes based on filters
   * @deprecated Moved to QuotesPaginated
   */
  readonly customerQuotes: ReadonlyArray<CustomerQuote>;
  /** Returns quotes based on filters */
  readonly customerQuotesPaginated?: Maybe<CustomerQuoteConnection>;
  /**
   * Returns employee based upon id
   * @deprecated Removed this query. Use V2 query. Query will be removed soon
   */
  readonly employee?: Maybe<Employee>;
  /**
   * gets all employees
   * @deprecated Moved to AllEmployees
   */
  readonly employees?: Maybe<ReadonlyArray<Employee>>;
  /**
   * Returns employee by external_employee_id
   * @deprecated Removed this query. Use V2 query. Query will be removed soon
   */
  readonly exactEmployee?: Maybe<Employee>;
  /** Returns a single User along with associated employees based on user email */
  readonly exactUser?: Maybe<User>;
  /**
   * Retrieves all facilities
   * @deprecated Moved to AllFacilities
   */
  readonly facilities: ReadonlyArray<Facility>;
  /**
   * Returns a single facility based on the ID
   * @deprecated Removed this query. Use V2 query. Query will be removed soon
   */
  readonly facility?: Maybe<Facility>;
  /**
   * Returns a single facility based on code
   * @deprecated Removed this query. Use V2 query. Query will be removed soon
   */
  readonly facilityByCode?: Maybe<Facility>;
  /**
   * Gets all truck posts for carrier by carrier id
   * @deprecated Service has reached EOL. Use TruckEntry subgraph. Query will be removed soon
   */
  readonly getTruckPostingsForCarrier?: Maybe<ReadonlyArray<TruckPosting>>;
  /** Returns a single load based on the ID */
  readonly load?: Maybe<Load>;
  /**
   * Returns loads, based on the loads_routes_orders view
   * @deprecated This query has been deprecated as part of mastery-api, and now lives in the loads domain.
   */
  readonly loadRoutesOrders?: Maybe<LoadRoutesOrdersConnection>;
  /** Returns a list of loads */
  readonly loads?: Maybe<LoadConnection>;
  /** Returns match results based on carrier or truck_posting */
  readonly matchQuery: MatchQuery;
  /** Returns match results with both TempLoads and Routes based on carrier or truck_posting */
  readonly matchQueryCombined: MatchQueryCombined;
  /** Returns distance between provided locations */
  readonly mileage?: Maybe<Mileage>;
  /** Returns distance between provided PointInputs */
  readonly mileageForPoints?: Maybe<Mileage>;
  /** Returns a single order by ID */
  readonly order?: Maybe<CustomerOrder>;
  /** Returns a single order by ID */
  readonly orderByCode?: Maybe<CustomerOrder>;
  /** Returns a single order by Customer Order Number */
  readonly orderByCustomerOrderNumber?: Maybe<CustomerOrder>;
  /** Returns a single quote based on the ID */
  readonly quote?: Maybe<CustomerQuote>;
  /** Returns a single Route based on the ID */
  readonly route?: Maybe<LoadRoute>;
  /** Returns a Route Vendor by Id */
  readonly routeVendor?: Maybe<RouteVendor>;
  /** Returns an array of RouteVendors based on search criteria */
  readonly routeVendors: RouteVendorConnection;
  /** Searches Routes */
  readonly routes?: Maybe<LoadRouteConnection>;
  /** Returns Stops based on filters */
  readonly stops?: Maybe<StopConnection>;
  /** Retrieves all temp loads based on criteria */
  readonly tempLoads: ReadonlyArray<TempLoad>;
  /**
   * Returns tenant configured data, including values for dropdowns
   * @deprecated Deprecating endpoint, transition to call Minion API instead. Contact team-purple-rain if more information is needed.
   */
  readonly tenantConfiguration?: Maybe<TenantConfig>;
  /**
   * Returns truck postings based on filters
   * @deprecated Service has reached EOL. Use TruckEntry subgraph. Query will be removed soon
   */
  readonly truckPostings?: Maybe<ReadonlyArray<TruckPosting>>;
  /**
   * Returns truck postings based on filters
   * @deprecated Service has reached EOL. Use TruckEntry subgraph. Query will be removed soon
   */
  readonly truckPostingsExternal?: Maybe<TruckPosting>;
  /** Returns all Users along with associated employees */
  readonly users: UserConnection;
  /** Returns a user/employee association based on current user */
  readonly whoAmI?: Maybe<WhoAmI>;
  /** Retrieves active and expired superregions, regions and subregions. */
  readonly allActiveAndExpiredRegions?: Maybe<RegionGraphResponse>;
  /** Retrieves all superregions, regions and subregions. */
  readonly allRegionsInfo?: Maybe<RegionGraphResponse>;
  /** Retrieves region data for given zipcode and country. */
  readonly regionInfo: RegionGraphResponse;
  /** Retrieves region data for given id. */
  readonly regionInfoById: RegionByIdGraphResponse;
  /** Retrieves region data for given urn. */
  readonly regionInfoByUrn?: Maybe<Region>;
  readonly regionInfoV2?: Maybe<RegionInfoV2Response>;
  readonly allRegionsV2?: Maybe<AllRegionsV2>;
  readonly allRegionsV2New?: Maybe<AllRegionsV2New>;
  readonly regionSet?: Maybe<ReadonlyArray<Maybe<RegionSetR2>>>;
  readonly countriesV2?: Maybe<ReadonlyArray<Maybe<CountryType>>>;
  readonly getRegionSetDetails?: Maybe<AllRegionsV2>;
  readonly getRegionInfoByRegionId?: Maybe<RegionInfoByRegionIdResponse>;
  /** Checks whether the SNI Shipment Service is available */
  readonly IsSNIShipmentServiceAvailable: IsSniShipmentServiceAvailable;
  /** Get the Suggested Carriers */
  readonly getSuggestedCarriers: SuggestedCarriersPayload;
  /** Facility bulk validation */
  readonly facilityBulkValidation?: Maybe<ReadonlyArray<FacilityValidation>>;
  /** Customer bulk validation */
  readonly customerBulkValidation?: Maybe<ReadonlyArray<CustomerValidation>>;
  /** Carrier bulk validation */
  readonly carrierBulkValidation?: Maybe<ReadonlyArray<CarrierValidation>>;
  /** Fetch resources assigned to a given route */
  readonly routeResources?: Maybe<RouteResources>;
  /** Fetch resources whose properties match the given filters */
  readonly routeResourcesByFilter: ReadonlyArray<RouteResources>;
  /** DEPRECATED, DO NOT USE - Get driver assignment for a route */
  readonly driverAssignment: DriverAssignment;
  readonly driverAssignments?: Maybe<ReadonlyArray<DriverAssignment>>;
  /** Get driver assignment for a route */
  readonly driverAssignmentForRoute?: Maybe<DriverAssignment>;
  readonly driverBounces: ReadonlyArray<DriverBounce>;
  readonly getSavedFilters: SavedFilterResult;
  readonly getBulkTablePreference: ReadonlyArray<TablePreferenceResponseType>;
  readonly getTablePreference: ReadonlyArray<TablePreferenceResponseType>;
  /** @deprecated Please update to searchFacilitiesByAddressV2! */
  readonly searchFacilitiesByAddress?: Maybe<SearchFacilityResult>;
  readonly searchFacilitiesByAddressV2?: Maybe<SearchFacilityResultV2>;
  readonly getCurrentSearchFacilityVersion?: Maybe<Scalars['String']>;
  /** For testing features before they are fully exposed. */
  readonly testSecureDataPrereleaseFeature: Scalars['String'];
  /** Returns a token configuration for a given token type. */
  readonly secureDataTokenizationConfiguration: SecureDataTokenizationConfigurationModel;
  /** Returns a list of sensitive data for a given list of token IDs. If Token ID is not found, result is omitted. */
  readonly secureDataTokenData: SecureDataIdsModel;
  readonly seerSearchAccountingAdvanceProviderConfig: SeerAccountingAdvanceProviderConfigResult;
  readonly seerSearchAccountingAdvances: SeerAccountingAdvancesResult;
  readonly seerSearchAccountingAdvsStatusNotification: SeerAccountingAdvsStatusNotificationResult;
  readonly seerSearchAccountingCarrierFinalizedFuel: SeerAccountingCarrierFinalizedFuelResult;
  readonly seerSearchAccountingCostDetails: SeerAccountingCostDetailsResult;
  readonly seerSearchAccountingCustomerOrder: SeerAccountingCustomerOrderResult;
  readonly seerSearchAccountingExceptions: SeerAccountingExceptionsResult;
  readonly seerSearchAccountingFinalisedFuel: SeerAccountingFinalisedFuelResult;
  readonly seerSearchAccountingNonRouteCostDetails: SeerAccountingNonRouteCostDetailsResult;
  readonly seerSearchAccountingRateLineDetail: SeerAccountingRateLineDetailResult;
  readonly seerSearchAccountingUnvoucheredVendorInvoice: SeerAccountingUnvoucheredVendorInvoiceResult;
  readonly seerSearchAccountingVendorInvoice: SeerAccountingVendorInvoiceResult;
  readonly seerSearchAccountingVendorRouteReqDocCompleteStatus: SeerAccountingVendorRouteReqDocCompleteStatusResult;
  readonly seerSearchAccountingVendorRouteRequiredDocumentCompletionStatus: SeerAccountingVendorRouteRequiredDocumentCompletionStatusResult;
  readonly seerSearchAccountingVendorVoucher: SeerAccountingVendorVoucherResult;
  readonly seerSearchAssignedRep: SeerAssignedRepResult;
  readonly seerSearchAssignedRepGroup: SeerAssignedRepGroupResult;
  readonly seerSearchBillToCode: SeerBillToCodeResult;
  readonly seerSearchCarrier: SeerCarrierResult;
  readonly seerSearchCarrierShipment: SeerCarrierShipmentResult;
  readonly seerSearchCarrierAccountingStatus: SeerCarrierAccountingStatusResult;
  readonly seerSearchCarrierAddress: SeerCarrierAddressResult;
  readonly seerSearchCarrierCertification: SeerCarrierCertificationResult;
  readonly seerSearchCarrierCustomerRelationship: SeerCarrierCustomerRelationshipResult;
  readonly seerSearchCarrierFacilityRelationship: SeerCarrierFacilityRelationshipResult;
  readonly seerSearchCarrierGroup: SeerCarrierGroupResult;
  readonly seerSearchCarrierIdentifier: SeerCarrierIdentifierResult;
  readonly seerSearchCarrierRep: SeerCarrierRepResult;
  readonly seerSearchCarrierRoute: SeerCarrierRouteResult;
  readonly seerSearchCarrierService: SeerCarrierServiceResult;
  readonly seerSearchCdcaudit: SeerCdcauditResult;
  readonly seerSearchCommandLog: SeerCommandLogResult;
  readonly seerSearchCrmInteraction: SeerCrmInteractionResult;
  readonly seerSearchCurrentAndNextStop: SeerCurrentAndNextStopResult;
  readonly seerSearchCurrentStop: SeerCurrentStopResult;
  readonly seerSearchCustomerGroup: SeerCustomerGroupResult;
  readonly seerSearchCustomerRep: SeerCustomerRepResult;
  readonly seerSearchCustomerAddress: SeerCustomerAddressResult;
  readonly seerSearchCustomerCapacityConstraintOrder: SeerCustomerCapacityConstraintOrderResult;
  readonly seerSearchCustomerCapacityConstraintTimePeriod: SeerCustomerCapacityConstraintTimePeriodResult;
  readonly seerSearchCustomerCommitment: SeerCustomerCommitmentResult;
  readonly seerSearchCustomerCommitmentOrder: SeerCustomerCommitmentOrderResult;
  readonly seerSearchCustomerCommitmentSettingOrderStatus: SeerCustomerCommitmentSettingOrderStatusResult;
  readonly seerSearchCustomerCommitmentTimePeriod: SeerCustomerCommitmentTimePeriodResult;
  readonly seerSearchCustomerIdentifier: SeerCustomerIdentifierResult;
  readonly seerSearchCustomerOrder: SeerCustomerOrderResult;
  readonly seerSearchEmployee: SeerEmployeeResult;
  readonly seerSearchFacility: SeerFacilityResult;
  readonly seerSearchFacilityAddress: SeerFacilityAddressResult;
  readonly seerSearchFacilityCarrier: SeerFacilityCarrierResult;
  readonly seerSearchFacilityCustomer: SeerFacilityCustomerResult;
  readonly seerSearchFacilityCustomerRelationship: SeerFacilityCustomerRelationshipResult;
  readonly seerSearchFacilityIdentifier: SeerFacilityIdentifierResult;
  readonly seerSearchFacilityRoute: SeerFacilityRouteResult;
  readonly seerSearchFacilityTrackingBoard: SeerFacilityTrackingBoardResult;
  readonly seerSearchGlobalChargeTypeConfig: SeerGlobalChargeTypeConfigResult;
  readonly seerSearchIncidents: SeerIncidentsResult;
  readonly seerSearchIncidentsRoutes: SeerIncidentsRoutesResult;
  readonly seerSearchInvoice: SeerInvoiceResult;
  readonly seerSearchLinkedRoutes: SeerLinkedRoutesResult;
  readonly seerSearchLoad: SeerLoadResult;
  readonly seerSearchLoadSearch: SeerLoadSearchResult;
  readonly seerSearchLoadSearchDataAggregation: SeerLoadSearchDataAggregationResult;
  readonly seerSearchLoadSearchSideBar: SeerLoadSearchSideBarResult;
  readonly seerSearchLoadSearchV2: SeerLoadSearchV2Result;
  readonly seerSearchLoadSummaryDataAggregation: SeerLoadSummaryDataAggregationResult;
  readonly seerSearchLocks: SeerLocksResult;
  readonly seerSearchLseToEqStaging: SeerLseToEqStagingResult;
  readonly seerSearchMainPageRouteBoard: SeerMainPageRouteBoardResult;
  readonly seerSearchMainPageRouteBoardCountUnified: SeerMainPageRouteBoardCountUnifiedResult;
  readonly seerSearchMainPageRouteBoardDetails: SeerMainPageRouteBoardDetailsResult;
  readonly seerSearchMainPageRouteBoardPending: SeerMainPageRouteBoardPendingResult;
  readonly seerSearchMainPageRouteBoardPreTender: SeerMainPageRouteBoardPreTenderResult;
  readonly seerSearchMainPageRouteBoardTender: SeerMainPageRouteBoardTenderResult;
  readonly seerSearchMainPageTrackingBoard: SeerMainPageTrackingBoardResult;
  readonly seerSearchMainPageTrackingBoardV2: SeerMainPageTrackingBoardV2Result;
  readonly seerSearchMainPageTrackingBoardV2Count: SeerMainPageTrackingBoardV2CountResult;
  readonly seerSearchMasterFindLoadSearch: SeerMasterFindLoadSearchResult;
  readonly seerSearchMasterSignStatus: SeerMasterSignStatusResult;
  readonly seerSearchMe_287848Customer: SeerMe_287848CustomerResult;
  readonly seerSearchOrderReference: SeerOrderReferenceResult;
  readonly seerSearchOrderRep: SeerOrderRepResult;
  readonly seerSearchOrderRepGroup: SeerOrderRepGroupResult;
  readonly seerSearchOrderCommodity: SeerOrderCommodityResult;
  readonly seerSearchOrderStatus: SeerOrderStatusResult;
  readonly seerSearchOrderStop: SeerOrderStopResult;
  readonly seerSearchOrganization: SeerOrganizationResult;
  readonly seerSearchPower: SeerPowerResult;
  readonly seerSearchPowerRouteAssignment: SeerPowerRouteAssignmentResult;
  readonly seerSearchPowerRoutePing: SeerPowerRoutePingResult;
  readonly seerSearchRoute: SeerRouteResult;
  readonly seerSearchRouteCapacityNeed: SeerRouteCapacityNeedResult;
  readonly seerSearchRouteIdsToBackfill: SeerRouteIdsToBackfillResult;
  readonly seerSearchRoutePreplan: SeerRoutePreplanResult;
  readonly seerSearchRouteRateCon: SeerRouteRateConResult;
  readonly seerSearchRouteStatus: SeerRouteStatusResult;
  readonly seerSearchRouteStop: SeerRouteStopResult;
  readonly seerSearchRouteVendor: SeerRouteVendorResult;
  readonly seerSearchRouteVendorReference: SeerRouteVendorReferenceResult;
  readonly seerSearchRouteVendorStatus: SeerRouteVendorStatusResult;
  readonly seerSearchSchemaversion: SeerSchemaversionResult;
  readonly seerSearchSequencedTrailerRoute: SeerSequencedTrailerRouteResult;
  readonly seerSearchStgIncidents: SeerStgIncidentsResult;
  readonly seerSearchStop: SeerStopResult;
  readonly seerSearchStopEventsAggregations: SeerStopEventsAggregationsResult;
  readonly seerSearchTender: SeerTenderResult;
  readonly seerSearchTenderPlan: SeerTenderPlanResult;
  readonly seerSearchTenderPlanStatus: SeerTenderPlanStatusResult;
  readonly seerSearchTenderStatus: SeerTenderStatusResult;
  readonly seerSearchTrackingAggregations: SeerTrackingAggregationsResult;
  readonly seerSearchTrackingAggregationsUpdate: SeerTrackingAggregationsUpdateResult;
  readonly seerSearchTrackingEtas: SeerTrackingEtasResult;
  readonly seerSearchTrackingUpdateAggregations: SeerTrackingUpdateAggregationsResult;
  readonly seerSearchTrackingPage: SeerTrackingPageResult;
  readonly seerSearchTrackingPageV2: SeerTrackingPageV2Result;
  readonly seerSearchTrackingStopExecution: SeerTrackingStopExecutionResult;
  readonly seerSearchTrailer: SeerTrailerResult;
  readonly seerSearchTrailerType: SeerTrailerTypeResult;
  readonly seerSearchTrailerRouteAssignment: SeerTrailerRouteAssignmentResult;
  readonly seerSearchUser: SeerUserResult;
  readonly seerSearchVendorRouteFinancialStatusReason: SeerVendorRouteFinancialStatusReasonResult;
  readonly seerCustomerSearch: CustomerSearchConnection;
  readonly seerCustomerSearchCount: Scalars['Float'];
  readonly seerDriverRouteList: DriverRouteListConnection;
  readonly seerDriverRouteSubList: DriverRouteSubListConnection;
  readonly seerDriverList: DriverListConnection;
  readonly devSeerFleetManagerDrivers: DevFleetManagerDriversConnection;
  readonly seerLoadSearch: LoadSearchConnection;
  readonly seerRerateLoadSearch: RerateLoadSearchConnection;
  readonly seerRerateLoadSearchDownload: RerateLoadSearchDownloadConnection;
  readonly seerRerateLoadSearchCount: Scalars['Float'];
  readonly seerMainPageOrderBoardComplete: MainPageOrderBoardCompleteConnection;
  readonly seerMainPageOrderBoardCounts: MainPageOrderBoardCounts;
  readonly seerMainPageOrderBoardInTransit: MainPageOrderBoardInTransitConnection;
  readonly seerMainPageOrderBoardPlanning: MainPageOrderBoardPlanningConnection;
  readonly seerMainPageOrderBoardDelivered: MainPageOrderBoardDeliveredConnection;
  readonly seerMainPageRouteBoardIncomplete: MainPageRouteBoardIncompleteConnection;
  readonly seerMainPageRouteBoardLinkedRoutes?: Maybe<MainPageRouteBoardLinkedRoutes>;
  readonly seerMainPageRouteBoardOpen: MainPageRouteBoardOpenConnection;
  readonly seerMainPageRouteBoardPreTender: MainPageRouteBoardPreTenderConnection;
  readonly seerMainPageRouteBoardPending: MainPageRouteBoardPendingConnection;
  readonly seerMainPageRouteBoardTender: MainPageRouteBoardTenderConnection;
  readonly seerMainPageRouteBoardArrivedAtDestination: MainPageRouteBoardArrivedAtDestinationConnection;
  readonly seerMainPageRouteBoardArrivedAtOrigin: MainPageRouteBoardArrivedAtOriginConnection;
  readonly seerMainPageRouteBoardCompleted: MainPageRouteBoardCompletedConnection;
  readonly seerMainPageRouteBoardCovered: MainPageRouteBoardCoveredConnection;
  readonly seerMainPageRouteBoardDispatched: MainPageRouteBoardDispatchedConnection;
  readonly seerMainPageRouteBoardInTransit: MainPageRouteBoardInTransitConnection;
  readonly seerMainPageTrackingBoard: MainPageTrackingBoardConnection;
  readonly seerMainPageTrackingBoardCount: Scalars['Float'];
  readonly seerMainPageRouteBoardCounts: MainPageRouteBoardCountsConnection;
  readonly seerMasterfindLoadSearch: MasterfindLoadSearchConnection;
  readonly seerCarrierSearch: CarrierSearchConnection;
  readonly seerCarrierSearchCount: Scalars['Float'];
  readonly seerCarrierRoute: CarrierRouteConnection;
  readonly seerCarrierList: CarrierListConnection;
  readonly seerCustomerOrderList: CustomerOrderListConnection;
  readonly seerCustomerList: CustomerListConnection;
  readonly seerFacilityTracking: FacilityTrackingConnection;
  readonly seerFacilityRoute: FacilityRouteConnection;
  readonly seerPowerRouteList: PowerRouteListConnection;
  readonly seerPowerRouteSubList: PowerRouteSubListConnection;
  readonly seerTrackingPage: TrackingPageConnection;
  readonly seerTrailerRouteList: TrailerRouteListConnection;
  readonly seerUnvoucheredDrivers: UnvoucheredDriversConnection;
  readonly seerVoucheredDrivers: VoucheredDriversConnection;
  readonly seerFacilitySearch: FacilitySearchConnection;
  readonly seerFacilitySearchCount: Scalars['Float'];
  readonly seerMainPageOrderBoardAssociatedRoutes: MainPageOrderBoardAssociatedRoutesConnection;
  readonly seerSidebarLoadSearch: SidebarLoadSearchConnection;
  readonly seerAccountingCostdetail: AccountingCostdetailConnection;
  readonly seerAccountingUninvoicedQueue: AccountingUninvoicedQueueConnection;
  readonly seerAccountingUnvoucheredCarrierProcessingQueue: AccountingUnvoucheredCarrierProcessingQueueConnection;
  readonly seerAccountingRateLineDetails: AccountingRateLineDetailsConnection;
  readonly seerSeerSearchForLinkedRoutes: SeerSearchForLinkedRoutesConnection;
  readonly seerDriverVoucherExtractQueue: DriverVoucherExtractQueueConnection;
  readonly seerMainPageSideBarSummaryCounts: MainPageSideBarSummaryCounts;
  /** @deprecated no longer needed */
  readonly message?: Maybe<Scalars['String']>;
  /** Returns Paginated SpotQuotes by Lane Filter */
  readonly spotQuotes?: Maybe<SpotQuotesPaginatedPayload>;
  /** Returns Paginated SpotQuotes by Optional Lane/Customer Ids */
  readonly spotQuotesPaged?: Maybe<SpotQuotesPaginatedPayload>;
  readonly existingQuotes?: Maybe<ExistingQuotesPayload>;
  readonly matchingSpotQuote?: Maybe<MatchingSpotQuotePayload>;
  readonly matchingSpotQuoteV2?: Maybe<MatchingSpotQuoteV2Payload>;
  /** Returns all requested Spot Quotes by Id, if they exist */
  readonly spotQuotesByIds: ReadonlyArray<SpotQuote>;
  readonly spotQuoteHitRate?: Maybe<SpotQuoteHitRatePayload>;
  readonly spotQuotePricing?: Maybe<SpotQuotePricingPayload>;
  /** Returns all requested Spot Quotes by Code, if they exist */
  readonly spotQuotesByCodes: ReadonlyArray<SpotQuote>;
  readonly historicalSpotQuotes?: Maybe<SpotQuotesPaginatedPayload>;
  readonly StopEventEngine: StopEventEngineOutput;
  readonly StopEventV2: StopEventV2;
  readonly StopEventsV2: StopEventV2Connection;
  readonly StopEventsV2ByRouteStopWithSharedStatus: ReadonlyArray<StopEventsV2WithSharedStatus>;
  /** Retrieves a list of task details. */
  readonly tasksV2: ReadonlyArray<TaskV2>;
  /** Retrieves Number of task based on filters */
  readonly taskCountV2: Scalars['Float'];
  /** Retrieves tasks based on filters */
  readonly tasksPaginatedV2: TaskConnectionV2;
  /** Retrieves a task based on id. */
  readonly taskV2: TaskV2;
  readonly timelineEvent: TimelineEvent;
  readonly timelineEvents: TimelineEventConnection;
  readonly timelineEventV2: TimelineEventV2;
  readonly timelineEventsV2: TimelineEventConnectionV2;
  readonly tours?: Maybe<ReadonlyArray<Maybe<Tour>>>;
  readonly fetchTrackingETAForStop?: Maybe<TrackingEtaForStop>;
  /** Calculate Estimated Time of Arrival to first stop using driver assignment data */
  readonly calculateDriverAssignmentETA: TrackingEtaCalculation;
  readonly trackingUpdatesV4: TrackingUpdateV4Connection;
  /** Get Tracking Update By ID */
  readonly trackingUpdateByIDV4?: Maybe<TrackingUpdateV4>;
  readonly getFleetsByTrailerId: TrailerFleetConnection;
  readonly getTrailersByFleets: TrailerActiveFleetPayload;
  readonly getIdentifiersByTrailerId: TrailerIdentifierPayload;
  readonly getEquipmentsByTrailerId: TrailerEquipmentPayload;
  readonly getMaintenanceByTrailerId: TrailerMaintenancePayload;
  readonly getTrailerAvailability: TrailerAvailabilityPayload;
  readonly getCustomersByTrailerId: TrailerCustomerPayload;
  readonly getTrailerDrivers: ReadonlyArray<TrailerDriverOutput>;
  readonly getasset_trailersbyid: ReadonlyArray<AssetTrailers>;
  readonly getassetTrailerSearch: ReadonlyArray<AssetTrailers>;
  readonly searchMatchingTrailers: SearchMatchingTrailersOutputPayload;
  readonly searchAssetTrailer: TrailerConnection;
  readonly checkTrailerNumber: TrailerValidation;
  readonly getTrailerByCode: AssetTrailers;
  readonly trailerAutocomplete: ReadonlyArray<TrailerAutocompleteOutput>;
  readonly validateTrailer: TrailerValidationResponse;
  readonly helloTrailerValidation: Scalars['String'];
  /**
   * Gets a single container by it's ID
   *
   * (Repo: truck-entry-service)
   */
  readonly getContainerById: ContainerPayloadWithErrorsAndContainer;
  /**
   * Returns containers counts for the given carrier id for every day for seven (7) days beginning with the given start date based on the container's local date/time.
   *
   * (Repo: truck-entry-service)
   */
  readonly getDailyContainerCount: DailyContainersCountPayload;
  /**
   * Returns containers on the given carrier id. Paginated (max 50 per page).
   *
   * (Repo: truck-entry-service)
   */
  readonly getContainersForCarrier: ContainerConnection;
  /**
   * Returns containers based on provided Filters. Paginated (max 50 per page).
   *
   * (Repo: truck-entry-service)
   */
  readonly getContainers: ContainerConnection;
  /**
   * Returns truck entries for the given carrier ID. Paginated (max 50 per page).
   *
   * Requires (one of) these scope(s): [truckEntry:view].
   *
   * (Repo: truck-entry-service)
   */
  readonly getTruckEntriesForCarrier?: Maybe<TruckEntryConnection>;
  /**
   * Returns truck counts for the given carrier ID for every day for seven (7) days beginning with the given start date based on the truck's local date/time.
   *
   * Requires (one of) these scope(s): [truckEntry:view].
   *
   * (Repo: truck-entry-service)
   */
  readonly getDailyTruckCountForCarrier?: Maybe<DailyTruckCountPayload>;
  /**
   * Query to retrieve a single TruckEntry based on either the Id or the SourceId. Note: if both Id and SourceId are provided, only the Id will be considered.
   *
   * Requires (one of) these scope(s): [truckEntry:view].
   *
   * (Repo: truck-entry-service)
   */
  readonly getTruckEntry?: Maybe<TruckEntryPayloadWithErrorsAndTruckEntry>;
  /**
   * Retrieves a Driver Capacity (formerly known as Truck Entry) by driverId/driver2Id and readyDatetimeUtc.
   *
   * Requires (one of) these scope(s): [truckEntry:view].
   *
   * (Repo: truck-entry-service)
   */
  readonly getTruckEntryByDriverIdAndReadyDate: TruckEntryPayloadWithErrorsAndTruckEntry;
  readonly getHigherPriorityDriverCapacities: ReadonlyArray<TruckEntry>;
  /**
   * Returns truck entries based on filters provided. Paginated (max 50 per page).
   *
   * Requires (one of) these scope(s): [truckEntry:view].
   *
   * (Repo: truck-entry-service)
   */
  readonly getTruckEntries?: Maybe<TruckEntryConnection>;
  /**
   * Returns routes a driver is assigned to for a given date range
   *
   * Requires (one of) these scope(s): [truckEntry:view].
   *
   * (Repo: truck-entry-service)
   */
  readonly getDriversAssignedRoutes?: Maybe<CapacityLoadRouteV2Connection>;
  /**
   * distinct carrier IDs are retrieved for only TruckEntryTemplateStatus.ACTIVE;
   *
   * Requires (one of) these scope(s): [truckEntryTemplate:view].
   *
   * (Repo: truck-entry-service)
   */
  readonly getTruckEntryTemplateDistinctCarrierIds?: Maybe<DistinctCarrierIdsPayloadsWithErrors>;
  /**
   * Retrieves truck entry template by driverId and days.
   *
   * Requires (one of) these scope(s): [truckEntryTemplate:view].
   *
   * (Repo: truck-entry-service)
   */
  readonly getTruckEntryTemplateByDriverIdAndDays: TruckEntryTemplateByDriverIdAndDaysPayloadWithErrors;
  /**
   * Returns TruckEntryTemplates for the given carrier ID. Paginated (max 50 per page).
   *
   * Requires (one of) these scope(s): [truckEntryTemplate:view].
   *
   * (Repo: truck-entry-service)
   */
  readonly getTruckEntryTemplatesForCarrier?: Maybe<TruckEntryTemplateConnection>;
  /**
   * Query to retrieve a single TruckEntryTemplate based on either the Id or the SourceId. Note: if both Id and SourceId are provided, only the Id will be considered.
   *
   * Requires (one of) these scope(s): [truckEntryTemplate:view].
   *
   * (Repo: truck-entry-service)
   */
  readonly getTruckEntryTemplate?: Maybe<TruckEntryTemplatePayloadWithErrorsAndTruckEntryTemplate>;
  /**
   * Gets a single gate reservation by it's ID
   *
   * (Repo: truck-entry-service)
   */
  readonly getGateReservationById: GateReservationPayloadWithErrorsAndGateReservation;
  /**
   * Returns gate reservations counts for the given carrier id for every day for seven (7) days beginning with the given start date based on the gate reservation's local date/time.
   *
   * (Repo: truck-entry-service)
   */
  readonly getDailyGateReservationCount: DailyGateReservationsCountPayload;
  /**
   * Returns gate reservations on the given carrier id. Paginated (max 50 per page).
   *
   * (Repo: truck-entry-service)
   */
  readonly getGateReservationsForCarrier: GateReservationConnection;
  readonly getPatternV3: GetPatternPayload;
  readonly getPatternsByLaneV2: GetPatternsByLanePayloadV2;
  readonly getPatternsByOrderV1: GetPatternsByOrderPayloadV1;
  readonly getPatternOrderApplication: GetPatternOrderApplicationPayload;
  readonly isEligibleForAutoApply?: Maybe<AutoApplyEligiblePayload>;
  readonly aslSearchRoutes: AslRouteViewResult;
  readonly aslSearchTrackingUpdates: AslTrackingUpdatesViewResult;
  /** Returns one configuration based on its keys.  Used when querying Canvas for a matching configuration */
  readonly canvasConfiguration?: Maybe<CanvasConfiguration>;
  /** Returns a list of configurations based on the provided keys.  Used when querying Canvas for matching configurations and sorting by priority */
  readonly searchCanvasConfigurationsByKeys?: Maybe<ReadonlyArray<CanvasConfiguration>>;
  /** Returns one configuration based on its UUID.  Used when editing configurations on the UI */
  readonly canvasConfigurationByUuid?: Maybe<CanvasConfiguration>;
  /** Returns an array of CanvasConfigurationAuditHistory items for the given UUID. Canvas Configuration Audit History contains information about a changed configuration, including when it was updated, who it was updated by, as well as what the previous configuration details were before the update. This is intended for internal CANVAS usage. */
  readonly searchCanvasConfigurationAuditHistoryByUuid?: Maybe<ReadonlyArray<CanvasConfigurationAuditHistory>>;
  /** Returns every Validation Configuration */
  readonly validationConfigurations: ReadonlyArray<ValidationConfiguration>;
  /** Please read the CANVAS Integration Guide: https://masterysys.atlassian.net/wiki/spaces/EN/pages/2123628623/Integration+Guide+-+CANVAS#validationConfiguration-Query */
  readonly validationConfiguration?: Maybe<ValidationConfiguration>;
  /** Returns one configuration based on its UUID.  Used when editing configurations on the UI */
  readonly validationConfigurationByUuid: ValidationConfiguration;
  /** Returns Validation Configurations that contain the provided keys. Performs partial matches, configs don't need an exact match, which is how multiple configs are returned. */
  readonly searchValidationConfigurations: ReadonlyArray<ValidationConfiguration>;
  /** @deprecated Please use searchValidationAuditHistoryByUuid instead */
  readonly searchValidationAuditHistory: ReadonlyArray<ValidationAuditHistory>;
  /** @deprecated Please use searchValidationAuditHistoryByUuid instead */
  readonly searchValidationAuditHistoryByHashId: ReadonlyArray<ValidationAuditHistory>;
  /** Returns an array of ValidationAuditHistory for the given UUID. Validation Audit History contains information about a changed validation, included who updated it and when, as well as what the previous validation details were before the update. This is intended for CANVAS usage. */
  readonly searchValidationAuditHistoryByUuid: ReadonlyArray<ValidationAuditHistory>;
  readonly csaGetProviderDetails: ProviderResponse;
  readonly ProviderHistoryData?: Maybe<ReadonlyArray<ProviderHistory>>;
  readonly providers?: Maybe<ReadonlyArray<ProviderGroupResponse>>;
  readonly provider?: Maybe<ProviderIntegrationResponse>;
  readonly getAllProviderCredentialHistoryData?: Maybe<ReadonlyArray<AllProviderCredentialHistoryResponse>>;
  readonly getAllApiData?: Maybe<ReadonlyArray<ApiResponse>>;
  readonly getAllProviderHistoryData?: Maybe<ReadonlyArray<AllProviderHistoryResponse>>;
  readonly getHistorybyProviderId?: Maybe<ReadonlyArray<AuditHistoryQuery>>;
  /** Retrieves a list of all EDI Transaction for given load ID */
  readonly EDITransactions?: Maybe<ReadonlyArray<Maybe<EdiTransaction>>>;
  /** Retrieves preview data of EDI message for given EDI DocumentId */
  readonly EDIMessagePreview?: Maybe<EdiPreviewData>;
  /** Retrieves a list of all EDI Transaction for given shipment ID */
  readonly EDITransactionsByShipmentId?: Maybe<ReadonlyArray<Maybe<EdiTransaction>>>;
  /** Retrieves a list of all EDI Transactions for given parameters */
  readonly GetEdiTransactions?: Maybe<ReadonlyArray<Maybe<EdiTransaction>>>;
  /** Retrieves a list of all EDI Tenders based on given Shipment Id and Scac code */
  readonly EDITenderByShipmentIdAndScac?: Maybe<ReadonlyArray<Maybe<EdiTender>>>;
  /** Retrieves a list of all EDI Tenders based on given Shipment Id and Scac code */
  readonly EdiTenderWithTransactionByShipmentIdAndScac?: Maybe<ReadonlyArray<Maybe<EdiTender>>>;
  /** Retrieves a list of all EDI Outbox messages eligible for retry */
  readonly EdiOutboxMessagesForRetry?: Maybe<ReadonlyArray<Maybe<EdiOutbox>>>;
  /** Retrieves EDI Transaction for given ID */
  readonly EDITransactionsById?: Maybe<EdiTransaction>;
  /** Retrieves a list of all unporcessed Edi 204 transaction */
  readonly GetUnprocessedEdi204Transactions?: Maybe<ReadonlyArray<Maybe<UnprocessedEdi204Transaction>>>;
  /** Retrieves a TrackingUpdate configuration for given customer. */
  readonly GetCustomerTrackingUpdatesConfiguration?: Maybe<TrackingUpdateConfiguration>;
  /** Retrieves carrier Edi transactions for a given route code. */
  readonly GetCarrierEdiTransactionsByRouteCode?: Maybe<ReadonlyArray<Maybe<EdiTransaction>>>;
  /** Get EDI Outbox message by Id */
  readonly EdiOutboxMessageById?: Maybe<EdiOutbox>;
  /** Get EDI Outbox message by HashedEvent */
  readonly GetEdiOutboxMessageByHashedEvent?: Maybe<EdiOutbox>;
  readonly allEdiTransactionDescriptions?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly allEdiManagementTransactions?: Maybe<AllEdiManagementTransactionsConnection>;
  /** Gets ISA Receiver by EDI Transaction Id. */
  readonly getInbound204IsaReceiverId?: Maybe<ReadonlyArray<Maybe<Inbound204IsaIdReceiver>>>;
  readonly customerOrder?: Maybe<CustomerOrderV2>;
  readonly customerOrderByCode?: Maybe<CustomerOrderV2>;
  readonly customerOrderByNumber?: Maybe<CustomerOrderV2>;
  readonly orders: CustomerOrdersConnection;
  readonly validateCustomerOrderNumberUpdate?: Maybe<CustomerOrderNumberValidation>;
  readonly getOrderStopCommodities?: Maybe<ReadonlyArray<CommodityV2>>;
  readonly orderStop?: Maybe<OrderStop>;
  readonly loadV2?: Maybe<LoadV2>;
  /** Fetches a load by its code */
  readonly loadByCode?: Maybe<LoadV2>;
  readonly loadCalculatePayableDistance?: Maybe<CalculatePayableStopsMileage>;
  /** Returns the route with the given id, if it exists */
  readonly routeV2?: Maybe<LoadRouteV2>;
  /** Returns the route with the given code, if it exists */
  readonly routeByCode?: Maybe<LoadRouteV2>;
  readonly routesV2?: Maybe<LoadRoutesConnection>;
  /** Deprecated, use RouteStopV2 Query */
  readonly routeStop?: Maybe<RouteStop>;
  /**
   * Deprecated, use stopsV3 Query
   * @deprecated Deprecated, use stopsV3 Query
   */
  readonly stopsV2?: Maybe<StopsConnection>;
  readonly routeStopV2?: Maybe<RouteStopV2>;
  readonly stopsV3?: Maybe<StopsV2Connection>;
  readonly crossLoadStop?: Maybe<CrossLoadStop>;
  readonly ordersRequiredScheduling?: Maybe<OrdersRequiredSchedulingConnection>;
  readonly ordersRequiredSchedulingCount?: Maybe<SchedulingOrderCount>;
  /** @deprecated Please use orderProfilesV2 query instead of orderProfiles. */
  readonly orderProfiles?: Maybe<ReadonlyArray<OrderProfile>>;
  /** @deprecated Please use orderProfileV2 query instead of orderProfile. */
  readonly orderProfile?: Maybe<OrderProfile>;
  readonly validateOrderProfile?: Maybe<ValidateOrderProfilePayload>;
  readonly orderProfilesV2?: Maybe<ReadonlyArray<OrderProfileV2>>;
  readonly orderProfileV2?: Maybe<OrderProfileV2>;
  readonly repeatLoadByRequestId: RepeatLoadPayloads;
  readonly mmaiGetDocsChatResponseV1?: Maybe<DocsChatResponse>;
  readonly mmaiDocsSearch?: Maybe<MMaiDocsSearchResponse>;
  readonly mmaiGetSeerLoadSearchVarsV1?: Maybe<MMaiGetSeerLoadSearchVarsResponseV1>;
  readonly mmaiParseCapacityEmailV1?: Maybe<MMaiParseCapacityEmailResponseV1>;
  readonly dataDictionary?: Maybe<DataDictionary>;
  /** Query to retrieve a single data dictionary type by Id. */
  readonly dataDictionaryType?: Maybe<DataDictionaryTypes>;
  readonly dictionaryEntityConfig?: Maybe<DictionaryEntityConfig>;
  readonly dictionaryEntityConfigAudit?: Maybe<DictionaryEntityConfigAudit>;
  readonly dictionaryEntityConfigOption: DictionaryEntityConfigOption;
  readonly dictionaryEntityConfigs?: Maybe<DictionaryEntityConfigs>;
  readonly dictionaryTerms?: Maybe<ReadonlyArray<DictionaryTerm>>;
  readonly dictionaryTermsByIds?: Maybe<DictionaryTermsQueryResult>;
  readonly dictionaryTermsHistory?: Maybe<ReadonlyArray<DictionaryTermsHistory>>;
  /** Deprecated, use getPowerBiReportDetailsV2 Query */
  readonly getPowerBiReportDetails: EmbedParamsV2;
  readonly getPowerBiReportDetailsV2: EmbedParamsV2;
  readonly validateMembership?: Maybe<ValidateMembershipOutput>;
  readonly defaultOrganization?: Maybe<Organization>;
  readonly organization?: Maybe<Organization>;
  readonly organizations: ReadonlyArray<Maybe<Organization>>;
  readonly memberships: ReadonlyArray<Maybe<Membership>>;
  readonly getMobileRoles: ReadonlyArray<Maybe<MisoRole>>;
  readonly getMobileScopes: ReadonlyArray<Maybe<MisoScope>>;
  readonly getMobileUsers: ReadonlyArray<Maybe<MisoUserRep>>;
  readonly getMobileRolesV2: ReadonlyArray<Maybe<MisoRoleV2>>;
  readonly getInvitationStatus: InvitationStatus;
  readonly getAuditEvents: ReadonlyArray<AuditLogs>;
};


/** The root type for all read token operations. */
export type QueryRateDetailsArgs = {
  filter: RateDetailsFilter;
};


/** The root type for all read token operations. */
export type QueryCostDetailsArgs = {
  filter: CostDetailsFilter;
};


/** The root type for all read token operations. */
export type QueryCustomerSettlementQueueArgs = {
  filter?: InputMaybe<OrdersFilter>;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
  before?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryVendorInvoiceQueueArgs = {
  filter: VendorInvoiceFilterInput;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
  before?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryVoucherBatchesArgs = {
  filter: BatchFilter;
};


/** The root type for all read token operations. */
export type QueryInvoiceBatchesArgs = {
  filter: BatchFilter;
};


/** The root type for all read token operations. */
export type QueryGetChargeTypeByIdArgs = {
  chargeTypeId?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryVendorSettlementQueueArgs = {
  filter?: InputMaybe<VendorAccountingQueueFilter>;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
  before?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetAdvanceByIdV2AsyncArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryGetFilteredAdvancesAsyncArgs = {
  filter?: InputMaybe<AdvanceFilterInput>;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
  before?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetAdvanceInterfacesByProviderAsyncArgs = {
  filter: AdvanceInterfaceFilterV2;
};


/** The root type for all read token operations. */
export type QueryRouteMaxCostArgs = {
  routeId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryRouteMaxCostNullableArgs = {
  routeId?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryCustomerCreditMemoArgs = {
  invoiceId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryVendorCreditMemoArgs = {
  voucherId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryVendorAssociatedRequiredDocumentArgs = {
  vendorId?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryRouteVendorMaxCostValidationArgs = {
  routeId: Scalars['ID'];
  vendorId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCustomerRequiredDocumentsArgs = {
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCustomerInvoicesArgs = {
  filter: CustomerInvoiceFilter;
};


/** The root type for all read token operations. */
export type QueryCustomerInvoicesQueueArgs = {
  filter: CustomerInvoiceFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
  before?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetAdvanceProviderConfigAsyncArgs = {
  provider: AdvanceProviderEnumV2;
};


/** The root type for all read token operations. */
export type QueryVendorVouchersArgs = {
  filter: VendorVoucherFilter;
};


/** The root type for all read token operations. */
export type QueryVendorVouchersQueueArgs = {
  filter: VendorVoucherFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
  before?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryCustomerPaymentsArgs = {
  filter: CustomerPaymentInput;
};


/** The root type for all read token operations. */
export type QueryVendorPaymentsArgs = {
  filter: VendorPaymentInput;
};


/** The root type for all read token operations. */
export type QueryLoadMoneySummaryArgs = {
  loadId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryLoadRequiredDocumentsSummaryArgs = {
  loadId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCustomerOrderRequiredDocumentsArgs = {
  customerId: Scalars['ID'];
  orderId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryVendorRouteRequiredDocumentsArgs = {
  vendorId: Scalars['ID'];
  routeId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryVendorRouteRequiredDocumentsByLoadIdArgs = {
  loadId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryVendorInvoicesArgs = {
  filter?: InputMaybe<VendorInvoiceFilterInput>;
  filterV2?: InputMaybe<VendorInvoiceFilterInputV2>;
};


/** The root type for all read token operations. */
export type QuerySegmentationConfigurationArgs = {
  segmentationConfigurationId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QuerySegmentArgs = {
  segmentId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QuerySegmentValuesForSegmentArgs = {
  segmentId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryAccountingExceptionsArgs = {
  filter: AccountingExceptionFilterInput;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
  before?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetRouteVendorCostArgs = {
  routeId?: InputMaybe<Scalars['ID']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryGetOrderArgs = {
  orderId?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryGetCustomerInvoiceForResendArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryGetCustomerInvoicesForResendArgs = {
  invoiceIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};


/** The root type for all read token operations. */
export type QueryVoucherVendorToleranceArgs = {
  vendorId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetCalculatedRouteVendorThresholdsArgs = {
  routeId?: InputMaybe<Scalars['ID']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryGetApplicableToleranceArgs = {
  routeId?: InputMaybe<Scalars['ID']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryGetVendorInvoiceVouchersArgs = {
  voucherHeaderId: Scalars['Uuid'];
};


/** The root type for all read token operations. */
export type QueryGetPartialVoucherInformationArgs = {
  routeId: Scalars['Uuid'];
  vendorId: Scalars['Uuid'];
};


/** The root type for all read token operations. */
export type QueryGetPartialVoucherInformationByBatchIdArgs = {
  batchId: Scalars['Uuid'];
};


/** The root type for all read token operations. */
export type QueryGetInvoiceDistributionhistoryByIdArgs = {
  loadId: Scalars['Uuid'];
};


/** The root type for all read token operations. */
export type QueryGetFinalizedFuelStatusArgs = {
  orderId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetBillToCodesByCustomerIdArgs = {
  customerId: Scalars['ID'];
  showInactive?: InputMaybe<Scalars['Boolean']>;
  billToCodeCode?: InputMaybe<Scalars['String']>;
  excludeBillToDisplayValues?: InputMaybe<Scalars['Boolean']>;
};


/** The root type for all read token operations. */
export type QueryGetBillToCodeHistoryByCustomerIdArgs = {
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetVendorAutoVoucherArgs = {
  vendorId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetVendorUseGlobalToggleArgs = {
  vendorId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetUseGlobalToggleByCustomerIdArgs = {
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetIntegrationMappingsArgs = {
  groupName: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetCustomerInvoicePaymentsArgs = {
  invoiceHeaderId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetCustomerPaymentDetailsArgs = {
  paymentId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetVendorVoucherPaymentsArgs = {
  voucherHeaderId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetVendorPaymentDetailsArgs = {
  paymentId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetRoutesByDriverPayInfoArgs = {
  filter: RoutesByDriverPayInfoFilterInput;
};


/** The root type for all read token operations. */
export type QueryGetRoutesWithVoucheredDriverPayItemsArgs = {
  routeIds: ReadonlyArray<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryGetCostDetailsByDriverVoucherIdsArgs = {
  driverVoucherIds: ReadonlyArray<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryGetAccountingConfigurationValueArgs = {
  key: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetCarrierFinalizedFuelStatusArgs = {
  routeId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetTripsArgs = {
  input: GetTripsInput;
};


/** The root type for all read token operations. */
export type QueryDummyProjectNameExampleArgs = {
  id: Scalars['UUID'];
};


/** The root type for all read token operations. */
export type QueryDummyProjectNameExamplesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  ids: ReadonlyArray<Scalars['UUID']>;
};


/** The root type for all read token operations. */
export type QueryGetAllRouteVendorRequirementsArgs = {
  routeVendor: RouteVendorInput;
};


/** The root type for all read token operations. */
export type QueryGetAllCustomerOrderRequirementsArgs = {
  orderId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetNonRouteCostDetailsByDriverIdsArgs = {
  driverIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


/** The root type for all read token operations. */
export type QueryGetNonRouteCostDetailsArgs = {
  input?: InputMaybe<GetNonRouteCostDetailsInput>;
};


/** The root type for all read token operations. */
export type QueryGetDriverPaySettingsArgs = {
  filter?: InputMaybe<DriverPaySettingsFilter>;
};


/** The root type for all read token operations. */
export type QueryGetJobConfigurationByJobNameArgs = {
  jobName: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetNonRouteCostDetailHistoryArgs = {
  driverIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


/** The root type for all read token operations. */
export type QueryGetNonRouteCostDetailHistoryV2Args = {
  nonRouteCostDetailHistoryFilter?: InputMaybe<NonRouteCostDetailHistoryFilter>;
};


/** The root type for all read token operations. */
export type QueryGetDriverVoucherHeadersArgs = {
  input?: InputMaybe<GetDriverVoucherHeadersInput>;
};


/** The root type for all read token operations. */
export type QueryGetRatingSourceIdByCustomerArgs = {
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetAbacusPricingArgs = {
  laneId: Scalars['ID'];
  routeId: Scalars['ID'];
  carrierId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetAbacusPricingV2Args = {
  abacusPricingFilter: GetAbacusPricingInput;
};


/** The root type for all read token operations. */
export type QueryGetAbacusPricingV3Args = {
  abacusPricingV3Filter: GetAbacusPricingV3Input;
};


/** The root type for all read token operations. */
export type QueryAbacusCostLineItemsArgs = {
  input: AbacusCostLineItemsInput;
};


/** The root type for all read token operations. */
export type QueryStatusBulkReratingRequestArgs = {
  requestId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryAccessorialSpotQuoteArgs = {
  input: AccessorialSpotQuoteInput;
};


/** The root type for all read token operations. */
export type QueryAccessorialLineItemsArgs = {
  loadId: Scalars['ID'];
  chargeTypeIsApplicable?: InputMaybe<Scalars['Boolean']>;
};


/** The root type for all read token operations. */
export type QueryCustomerAccessorialSettingsArgs = {
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCarrierAccessorialSettingsArgs = {
  carrierId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCarrierCapacityConstraintByCostQuoteArgs = {
  costQuoteId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCarrierCapacityConstraintByCarrierLaneDateArgs = {
  carrierId: Scalars['ID'];
  laneId: Scalars['ID'];
  date: Scalars['DateTime'];
};


/** The root type for all read token operations. */
export type QueryCarrierCapacityRemainingByCostQuoteAndDateArgs = {
  costQuoteId: Scalars['ID'];
  date: Scalars['DateTime'];
};


/** The root type for all read token operations. */
export type QueryCarrierCapacityConstraintByCostQuoteIDsArgs = {
  costQuoteIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};


/** The root type for all read token operations. */
export type QueryAssetDriverTenderByIdArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryAssetDriverTendersByRouteArgs = {
  routeId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryAssetDriverTendersByDriverArgs = {
  driverId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryAssetDriverTendersByRouteAndDriverArgs = {
  routeId: Scalars['ID'];
  driverId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryAssetDriverTendersByDriverCapacityArgs = {
  driverCapacityId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryAssetDriverTendersByRouteAndDriverCapacityArgs = {
  routeId: Scalars['ID'];
  driverCapacityId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryRoutingGuideV2Args = {
  laneId: Scalars['ID'];
  customerId?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryRoutingGuideV3Args = {
  routingGuideInput: RoutingGuideInput;
};


/** The root type for all read token operations. */
export type QueryLaneRoutingGuidesArgs = {
  filter: RoutingGuidesByLaneFilter;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryExportRoutingGuidesArgs = {
  filter: ExportRoutingGuidesByLanesFilter;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryRoutingGuideSequenceItemsArgs = {
  input: RoutingGuideSequenceItemsInput;
};


/** The root type for all read token operations. */
export type QueryMatchingRoutingGuideArgs = {
  input: MatchingRoutingGuideInput;
};


/** The root type for all read token operations. */
export type QueryMatchingRoutingGuidesArgs = {
  input: MatchingRoutingGuidesInput;
};


/** The root type for all read token operations. */
export type QueryTenderOptionsArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryTenderOptionsByParentObjectArgs = {
  parentObjectType: Scalars['String'];
  parentObjectId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryTenderOptionsByParentObjectV2Args = {
  parentObjectType: Scalars['String'];
  parentObjectId: Scalars['ID'];
  coalesce?: InputMaybe<Scalars['Boolean']>;
};


/** The root type for all read token operations. */
export type QueryDefaultTenderOptionsArgs = {
  carrierId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryActiveTenderPlanArgs = {
  routeId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryTenderPlanArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryOutboundTenderSettingsArgs = {
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryContactUsageInTenderingArgs = {
  contactId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetAssetAuditArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters: AssetAuditFilter;
};


/** The root type for all read token operations. */
export type QueryDriverCurrentNextRoutesArgs = {
  driverAssetId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryDriversCurrentNextRoutesArgs = {
  input: DriverCurrentNextRoutesQueryByIdsInput;
};


/** The root type for all read token operations. */
export type QueryPowerUnitCurrentNextRoutesArgs = {
  powerUnitAssetId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryPowerUnitsCurrentNextRoutesArgs = {
  input: PowerUnitCurrentNextRoutesQueryByIdsInput;
};


/** The root type for all read token operations. */
export type QueryBaseWorkFlowFormByCodeArgs = {
  code?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryMobileCommWorkflowFormByCodeArgs = {
  code?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetWorkflowFormsArgs = {
  input: WorkflowFormInput;
};


/** The root type for all read token operations. */
export type QueryGetMmWorkflowJobByIdArgs = {
  routeId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetLoadStatusRecordsAuditArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  load_id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryValidateDriversArgs = {
  input: DriverValidationsInput;
};


/** The root type for all read token operations. */
export type QueryBookingValidationRulesArgs = {
  input?: InputMaybe<BookingValidationRulesInput>;
};


/** The root type for all read token operations. */
export type QueryExpectedReadyValidationsArgs = {
  input: ExpectedReadyValidationsInput;
};


/** The root type for all read token operations. */
export type QueryExpectedReadyValidationsV2Args = {
  input: ReadonlyArray<ExpectedReadyValidationsInputV2>;
};


/** The root type for all read token operations. */
export type QueryRouteVendorV2Args = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryRouteVendorsV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RouteVendorsV2Filter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryBounceV2Args = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryBouncesV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BouncesV2Filter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryCalculateNextCarrierInteractionDateArgs = {
  vendorId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCalculateNextCustomerInteractionDateArgs = {
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCarrierCrmNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CarrierCrmNotesFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


/** The root type for all read token operations. */
export type QueryCarrierCrmOpportunitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CarrierCrmOpportunityFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


/** The root type for all read token operations. */
export type QueryCarrierInteractionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CarrierInteractionFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


/** The root type for all read token operations. */
export type QueryCrmCarrierDetailsArgs = {
  vendorId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCrmCustomerDetailsArgs = {
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCustomerCrmOpportunitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CustomerOpportunityFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


/** The root type for all read token operations. */
export type QueryCustomerInteractionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CustomerInteractionFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


/** The root type for all read token operations. */
export type QueryGetCarrierCrmDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CarrierDocuemntFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


/** The root type for all read token operations. */
export type QueryGetCarrierPortfolioArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CarriersWithInteractionFilterInputType;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryGetCustomerCrmDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CustomerCrmDocuemntFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


/** The root type for all read token operations. */
export type QueryGetCustomerCrmNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CustomerCrmNotesFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


/** The root type for all read token operations. */
export type QueryGetCustomerPortfolioArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CustomersWithInteractionFilterInputType;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryValidateCarrierCrmOpportunityNameAvailablityArgs = {
  validateCarrierCRMOpportunityNameRequest: ValidateCarrierCrmOpportunityNameRequest;
};


/** The root type for all read token operations. */
export type QueryValidateCustomerCrmOpportunityNameAvailablityArgs = {
  validateCustomerCRMOpportunityNameRequest: ValidateCustomerCrmOpportunityNameRequest;
};


/** The root type for all read token operations. */
export type QueryGetContactByIdArgs = {
  objId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetContactsByNameArgs = {
  contactName: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetContactsByEntityIdArgs = {
  obj_Entity_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetResponsibilitiesByContactIdArgs = {
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryAssociatedRepsByContactIdArgs = {
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryAssociatedRepsByContactIdandStatusArgs = {
  objStatus: Scalars['Boolean'];
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryAssociatedEntitiesByContactIdArgs = {
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryWorkHoursByContactIdArgs = {
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryContactInformationByContactInformationTypeIdArgs = {
  obj_Contact_Information_Type_Id: Scalars['String'];
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryContactInformationByIdArgs = {
  obj_Contact_Information_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryContactInformationByContactIdArgs = {
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryDuplicateContactsIfExistsArgs = {
  obj_Twitter_URL: Scalars['String'];
  obj_Email_Value: Scalars['String'];
  obj_Phone_Value: Scalars['String'];
  obj_Name: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryContactByIdv2Args = {
  objId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryContactsByNamev2Args = {
  contactName: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryContactsByEntityIdv2Args = {
  obj_Entity_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryAssociatedRepsByContactIdv2Args = {
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryAssociatedRepsByContactIdandStatusv2Args = {
  objStatus: Scalars['Boolean'];
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryAssociatedEntitiesByContactIdv2Args = {
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryWorkHoursByContactIdv2Args = {
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryContactInformationByContactInformationTypeIdv2Args = {
  obj_Contact_Information_Type_Id: Scalars['String'];
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryContactInformationByIdv2Args = {
  obj_Contact_Information_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryContactInformationByContactIdv2Args = {
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryDuplicateContactsIfExistsv2Args = {
  obj_Twitter_URL: Scalars['String'];
  obj_Email_Value: Scalars['String'];
  obj_Phone_Value: Scalars['String'];
  obj_Name: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryResponsibilitiesByContactIdv2Args = {
  obj_Contact_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmContactByIdArgs = {
  contactId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmContactsByNameArgs = {
  contactName: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmContactsByEntityIdArgs = {
  entityId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmAssociatedRepsByContactIdArgs = {
  contactId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmAssociatedRepsByContactIdandStatusArgs = {
  status: Scalars['Boolean'];
  contactId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmAssociatedEntitiesByContactIdArgs = {
  contactId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmWorkHoursByContactIdArgs = {
  contactId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmContactInformationByContactInformationTypeIdArgs = {
  typeId: Scalars['String'];
  contactId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmContactInformationByIdArgs = {
  informationId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmContactInformationByContactIdArgs = {
  contactId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmDuplicateContactsIfExistsArgs = {
  twitterURL: Scalars['String'];
  emailValue: Scalars['String'];
  phoneValue: Scalars['String'];
  name: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmResponsibilitiesByContactIdArgs = {
  contactId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmDuplicateContactsArgs = {
  emailValue: Scalars['String'];
  phoneValue: Scalars['String'];
  linkedInURL: Scalars['String'];
  name: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmContactIdByCodeArgs = {
  code: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmCompanyDescriptionByEntityIdArgs = {
  entityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryCrmDetailedDescriptionByEntityIdArgs = {
  entityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryCrmFirmographicsByEntityIdArgs = {
  entityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryCrmIndustriesByEntityIdArgs = {
  entityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryCrmIndustriesByIndustryIdArgs = {
  industriesId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmIndustryClassificationByLookupIdArgs = {
  industryClassificationLookupId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmInteractionByIdArgs = {
  interaction_Id?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryCrmInteractionByEntityIdArgs = {
  entity_Id?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryCrmInteractionByStatusArgs = {
  entity_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryCrmInteractionRepGroupAuditHistoryArgs = {
  interaction_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmInteractionRescheduleAuditHistoryArgs = {
  interaction_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmInteractionByIdV2Args = {
  interactionId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryCrmInteractionByEntityIdV2Args = {
  entityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryCrmInteractionByStatusV2Args = {
  entityId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryCrmInteractionRepGroupAuditHistoryV2Args = {
  interactionId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmInteractionRescheduleAuditHistoryV2Args = {
  interactionId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetPaginatedInteractionsArgs = {
  filters: CrmInteractionFilter;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryPortfolioInteractionCountArgs = {
  PortfolioInteractionHeaderFilter?: InputMaybe<PortfolioInteractionHeaderFilter>;
  portfolioInteractionMainFilter?: InputMaybe<PortfolioInteractionMainFilter>;
};


/** The root type for all read token operations. */
export type QueryPortfolioInteractionsByRepsArgs = {
  portfolioInteractionMainFilter: PortfolioInteractionMainFilter;
  filters?: InputMaybe<PortfolioInteractionColumnFilter>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryCrmInteractionsByReasonTermV2Args = {
  filters?: InputMaybe<PortfolioInteractionColumnFilter>;
  portfolioInteractionMainFilter: PortfolioInteractionMainFilter;
};


/** The root type for all read token operations. */
export type QueryCrmOpportunityByEntityIdArgs = {
  entity_Id?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryCrmOpportunityByOpportunityIdArgs = {
  opportunity_Id?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryValidateCrmOpportunityNameArgs = {
  Name?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryCrmOpportunityByEntityIdV2Args = {
  entityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryCrmOpportunityByOpportunityIdV2Args = {
  opportunityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryCrmRepsByOpportunityIdV2Args = {
  opportunityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryValidateCrmOpportunityNameV2Args = {
  name?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryValidateCrmOpportunityNameByEntityIdV2Args = {
  entityId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryPortfolioOpportunitiesByRepsArgs = {
  filters: CrmOpportunityFilter;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryLockArgs = {
  flavor: LockFlavor;
  objectID: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCarrierCommitmentsArgs = {
  filter?: InputMaybe<CarrierCommitmentFilter>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryCarrierCommitmentsByIdArgs = {
  commitmentID: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCarrierCommitmentRoutesByCommitmentIdArgs = {
  commitmentID: Scalars['ID'];
  routeID?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryCarrierCommitmentAcceptedRoutesCountArgs = {
  carrierCostQuotes: ReadonlyArray<InputMaybe<CarrierCostQuotesInputType>>;
  routeID: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryExportCarrierCommitmentsArgs = {
  filter?: InputMaybe<ExportCarrierCommitmentFilter>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryGeographiesArgs = {
  geographyType?: InputMaybe<GeographyTypeEnum>;
};


/** The root type for all read token operations. */
export type QueryGeographyArgs = {
  sourceId: Scalars['Int'];
};


/** The root type for all read token operations. */
export type QueryCarrierV2Args = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCarrierNullableV2Args = {
  carrier: CarrierArgumentInputV2;
};


/** The root type for all read token operations. */
export type QueryCarrierRepsV2Args = {
  order?: InputMaybe<CarrierRepOrderV2>;
};


/** The root type for all read token operations. */
export type QueryCarrierSettingV2Args = {
  carrierId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGeographiesRdArgs = {
  geographyType?: InputMaybe<GeographyTypeEnum>;
};


/** The root type for all read token operations. */
export type QueryGeographyRdArgs = {
  sourceId: Scalars['Int'];
};


/** The root type for all read token operations. */
export type QueryAuditsNullableV2Args = {
  filter?: InputMaybe<AuditsFilterV2>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryCarriersNullableV2Args = {
  filter?: InputMaybe<CarriersFilterV2>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryCarrierParentsByCarrierIdArgs = {
  carrierId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCarrierCarrierRelationshipsByCarrierIdArgs = {
  carrierId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryParentOfRelatedCarrierByCarrierIdArgs = {
  carrierId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCarrierChildrenHierarchyArgs = {
  input: CarrierChildrenHierarchyInputV2;
};


/** The root type for all read token operations. */
export type QueryCarrierCustomerRelationshipByCarrierIdArgs = {
  carrierId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryFindCarrierCustomerInternalCustomerRelationshipByCarrierIdArgs = {
  carrierId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryValidateCarrierCodeArgs = {
  carrierCode: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetGeneratedCarrierCodeByParentCarrierIdArgs = {
  parentCarrierId: Scalars['ID'];
  codeSuggestionCount?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryGetGlobalTenantChargeTypeConfigurationArgs = {
  chargeTypeId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetGlobalTenantChargeTypeConfigurationV2Args = {
  chargeTypeId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetAllGlobalTenantChargeTypeConfigurationsArgs = {
  chargeTypeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};


/** The root type for all read token operations. */
export type QueryGetAllGlobalTenantChargeTypeConfigurationsV2Args = {
  chargeTypeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};


/** The root type for all read token operations. */
export type QueryGetAllTenantChargeTypeConfigurationsArgs = {
  showInActive: Scalars['Boolean'];
};


/** The root type for all read token operations. */
export type QueryGetAllTenantChargeTypeConfigurationsV2Args = {
  showInActive: Scalars['Boolean'];
};


/** The root type for all read token operations. */
export type QueryGetTenantChargeTypeConfigurationArgs = {
  tenantChargeTypeConfigurationId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetTenantChargeTypeConfigurationV2Args = {
  tenantChargeTypeConfigurationId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetPriorityConfigurationArgs = {
  priorityConfigurationId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetCarrierChargeTypeConfigurationArgs = {
  carrierChargeTypeConfigurationId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetCarrierChargeTypeConfigurationV2Args = {
  carrierChargeTypeConfigurationId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetAllCarrierChargeTypeConfigurationsArgs = {
  carrierId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetAllCarrierChargeTypeConfigurationsV2Args = {
  carrierId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetAllAccessorialChargeTypeConfigurationsArgs = {
  carrierId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetAccessorialChargeTypeConfigurationArgs = {
  accessorialChargeTypeId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetCustomerChargeTypeConfigurationArgs = {
  customerChargeTypeConfigurationId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetCustomerChargeTypeConfigurationV2Args = {
  customerChargeTypeConfigurationId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetAllCustomerChargeTypeConfigurationsArgs = {
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetAllCustomerChargeTypeConfigurationsV2Args = {
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetRateDetailChargeTypeConfigurationsArgs = {
  input?: InputMaybe<DetailChargeTypeConfigurationInput>;
};


/** The root type for all read token operations. */
export type QueryGetRateDetailChargeTypeConfigurationsv2Args = {
  input?: InputMaybe<DetailChargeTypeConfigurationV2Input>;
};


/** The root type for all read token operations. */
export type QueryGetCostDetailChargeTypeConfigurationsArgs = {
  input?: InputMaybe<DetailChargeTypeConfigurationInput>;
};


/** The root type for all read token operations. */
export type QueryGetCostDetailChargeTypeConfigurationsV2Args = {
  input?: InputMaybe<DetailChargeTypeConfigurationV2Input>;
};


/** The root type for all read token operations. */
export type QueryGetEligiblePayProgramDetailChargeTypeConfigurationsArgs = {
  input?: InputMaybe<PayProgramDetailChargeTypeConfigurationInput>;
};


/** The root type for all read token operations. */
export type QueryGetChargeCodeDetailsForIb210MappingArgs = {
  input?: InputMaybe<MapIb210ChargeCodeInput>;
};


/** The root type for all read token operations. */
export type QueryGetChargeCodeDetailsForIb204MappingArgs = {
  input?: InputMaybe<MapIb204ChargeCodeInput>;
};


/** The root type for all read token operations. */
export type QueryActiveClientExceptionRecordsArgs = {
  filter: ActiveClientExceptionRecordsInput;
};


/** The root type for all read token operations. */
export type QueryClientExceptionRecordArgs = {
  id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryClientExceptionRecordsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ClientExceptionRecordFilterInput>;
};


/** The root type for all read token operations. */
export type QueryGetCommissionsGlobalConfigurationArgs = {
  filter?: InputMaybe<CommissionsSizeModeDivisionFilterInput>;
};


/** The root type for all read token operations. */
export type QueryGetCommissionsCustomerOverheadConfigurationsArgs = {
  filter?: InputMaybe<CommissionsCustomerOverheadConfigurationFilterInput>;
};


/** The root type for all read token operations. */
export type QueryGetActiveCommissionsLoadAllocatedMarginsArgs = {
  loadId: Scalars['Uuid'];
};


/** The root type for all read token operations. */
export type QueryGetActiveCommissionsByLoadIdArgs = {
  loadId: Scalars['Uuid'];
};


/** The root type for all read token operations. */
export type QueryGetEmployeeCommissionPlanByEmployeeIdArgs = {
  filter?: InputMaybe<EmployeeCommissionPlanFilterInput>;
};


/** The root type for all read token operations. */
export type QueryGetEmployeesAssignedToCommissionPlanByPlanIdArgs = {
  planHeaderId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetEmployeeCommissionPlanConfigurationArgs = {
  employeeCommissionPlanConfigurationId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetWhatIfLoadCommissionsCalculationArgs = {
  whatIf: WhatIfInput;
};


/** The root type for all read token operations. */
export type QueryGetWhatIfLoadAllocatedMarginsArgs = {
  whatIf: WhatIfInput;
};


/** The root type for all read token operations. */
export type QueryGetLoadCommissionsOverrideConfigurationByLoadIdArgs = {
  loadId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetLoadCommissionsMoneySummaryOverheadArgs = {
  loadId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetLoadCommissionsMoneySummaryOverheadsArgs = {
  loadIds: ReadonlyArray<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryCostQuotePaginatedArgs = {
  filter: CostQuotePaginatedFilter;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryGetCostQuoteByIdArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetActiveCostQuotesArgs = {
  laneId?: InputMaybe<Scalars['ID']>;
  carrierId?: InputMaybe<Scalars['ID']>;
  customerId?: InputMaybe<Scalars['ID']>;
  quoteType?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryMatchingCostQuoteArgs = {
  input?: InputMaybe<MatchingCostQuoteInput>;
};


/** The root type for all read token operations. */
export type QueryMatchingCostQuoteV2Args = {
  input?: InputMaybe<MatchingCostQuoteInputV2>;
};


/** The root type for all read token operations. */
export type QueryGetCreditAvailabilityArgs = {
  customerId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetOrderCreditInfoArgs = {
  orderId: Scalars['ID'];
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QuerySalesInteractionByEntityIdArgs = {
  entity_Id?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesInteractionByEntityIdV2Args = {
  entityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesInteractionByIdArgs = {
  interaction_Id?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesInteractionByIdV2Args = {
  interactionId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesInteractionByStatusArgs = {
  entity_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesInteractionByStatusV2Args = {
  entityId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesInteractionRepGroupAuditHistoryArgs = {
  interaction_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QuerySalesInteractionRepGroupAuditHistoryV2Args = {
  interactionId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QuerySalesInteractionRescheduleAuditHistoryArgs = {
  interaction_Id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QuerySalesInteractionRescheduleAuditHistoryV2Args = {
  interactionId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QuerySalesInteractionsByReasonTermV2Args = {
  filters?: InputMaybe<PortfolioInteractionColumnFilter>;
  portfolioInteractionMainFilter: PortfolioInteractionMainFilter;
};


/** The root type for all read token operations. */
export type QuerySalesgetPaginatedInteractionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters: CrmInteractionFilter;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QuerySalesportfolioInteractionCountArgs = {
  PortfolioInteractionHeaderFilter?: InputMaybe<PortfolioInteractionHeaderFilter>;
  portfolioInteractionMainFilter?: InputMaybe<PortfolioInteractionMainFilter>;
};


/** The root type for all read token operations. */
export type QuerySalesportfolioInteractionsByRepsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<PortfolioInteractionColumnFilter>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  portfolioInteractionMainFilter: PortfolioInteractionMainFilter;
};


/** The root type for all read token operations. */
export type QueryCrmCarrierNotesByEntityIdArgs = {
  vendorId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmCustomerNotesByEntityIdArgs = {
  customerId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmCarrierDocumentsByEntityIdArgs = {
  vendorId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCrmCustomerDocumentsByEntityIdArgs = {
  customerId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QuerySalesIBandOBregionByEntityIdV3Args = {
  entityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesIBandOBregionByOpportunityIdV3Args = {
  opportunityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesOpportunitiesNamesByRepArgs = {
  Rep_Id?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesOpportunitiesNamesByRepV3Args = {
  Rep_Id?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesOpportunityByEntityIdArgs = {
  entity_Id?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesOpportunityByEntityIdV2Args = {
  entityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesOpportunityByEntityIdV3Args = {
  entityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesOpportunityByOpportunityIdArgs = {
  opportunity_Id?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesOpportunityByOpportunityIdV2Args = {
  opportunityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesOpportunityByOpportunityIdV3Args = {
  opportunityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesOpportunityEntityIdV3Args = {
  entityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesOpportunityIdV3Args = {
  opportunityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesPortfolioOpportunitiesByRepsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters: CrmOpportunityFilter;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QuerySalesPortfolioOpportunitiesByRepsV3Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters: SalesOpportunityFilter;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QuerySalesRepsByOpportunityIdV2Args = {
  opportunityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySalesRepsByOpportunityIdV3Args = {
  opportunityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryValidateSalesOpportunityNameArgs = {
  Name?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryValidateSalesOpportunityNameByEntityIdV2Args = {
  entityId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryValidateSalesOpportunityNameByEntityIdV3Args = {
  entityId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryValidateSalesOpportunityNameV2Args = {
  name?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryValidateSalesOpportunityNameV3Args = {
  name?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryCustomerCommitmentsArgs = {
  filter?: InputMaybe<CustomerCommitmentFilter>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryCustomerCommitmentsSettingsArgs = {
  customerID: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCustomerCommitmentsByIdArgs = {
  commitmentID: Scalars['ID'];
  timeZone?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryCustomerCommitmentOrdersByIdArgs = {
  commitmentID: Scalars['ID'];
  orderID?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
};


/** The root type for all read token operations. */
export type QueryCustomerCapacityConstraintByIdArgs = {
  rateQuoteId: Scalars['ID'];
  capacityConstraintId?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryCustomerCommitmentAcceptedOrdersArgs = {
  orderIDs?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  timeZone?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryCustomerCapacityConstraintsArgs = {
  rateQuoteIDs?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};


/** The root type for all read token operations. */
export type QueryExportCustomerCommitmentsArgs = {
  filter?: InputMaybe<ExportCustomerCommitmentFilter>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryGetAvailableCapacityConstraintsArgs = {
  customerOrders: ReadonlyArray<InputMaybe<CustomerOrdersInputType>>;
};


/** The root type for all read token operations. */
export type QueryCustomerCommitmentMetricsArgs = {
  filter?: InputMaybe<CustomerCommitmentMetricsFilter>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryCustomerStructuresByCustomerIdArgs = {
  customerId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryNotesByCustomerIdArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCustomerRegionsByCustomerIdArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCustomerFacilitiesByCustomerIdArgs = {
  id: Scalars['ID'];
  ifIncludeFacility?: InputMaybe<Scalars['Boolean']>;
};


/** The root type for all read token operations. */
export type QueryCustomerFacilitiesArgs = {
  ifIncludeFacility?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<CustomerFacilityFilter>;
};


/** The root type for all read token operations. */
export type QueryCustomerCommoditiesByCustomerIdArgs = {
  id: Scalars['ID'];
  ifIncludeFacility?: InputMaybe<Scalars['Boolean']>;
};


/** The root type for all read token operations. */
export type QueryCustomerCommoditiesByFacilityIdArgs = {
  id: Scalars['ID'];
  ifIncludeFacility?: InputMaybe<Scalars['Boolean']>;
};


/** The root type for all read token operations. */
export type QueryCustomerCommoditiesArgs = {
  ifIncludeFacility?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<CustomerCommodityFilter>;
};


/** The root type for all read token operations. */
export type QueryCustomerCommoditiesByCustomerFacilityIdArgs = {
  customerFacilityId: Scalars['ID'];
  ifIncludeFacility?: InputMaybe<Scalars['Boolean']>;
};


/** The root type for all read token operations. */
export type QueryLoadDefaultsArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<LoadDefaultsFilter>;
};


/** The root type for all read token operations. */
export type QueryLoadDefaultsByCustomerIdArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryLoadDefaultsByCustomerIdV2Args = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCustomerCustomerRelationshipByCustomerIdArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryParentOfRelatedCustomerByCustomerIdArgs = {
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryImmediateChildrenOfCustomerByCustomerIdArgs = {
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetCustomerUpperHierarchyByCustomerIdArgs = {
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryServiceTolerancesArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<ServiceToleranceFilter>;
};


/** The root type for all read token operations. */
export type QueryCarrierFacilitiesByCarrierIdArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCarrierFacilitiesByFacilityIdArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryFacilityNotesByFacilityIdArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryFacilityNotesByFacilityIdV2Args = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryFacilityLoadDefaultsArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<FacilityLoadDefaultsFilter>;
};


/** The root type for all read token operations. */
export type QueryFacilityLoadDefaultsByFacilityIdArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryFacilityLoadDefaultsByFacilityIdV2Args = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryFacilityFacilityRelationshipsByFacilityIdArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCustomerV2Args = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCustomerByCodeV2Args = {
  code: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryAllCustomersV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CustomersFilterV2>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryCustomerAuditsNullableArgs = {
  filter?: InputMaybe<CustomerAuditsFilter>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryGetCustomerLinesOfBusinessByCustomerIdArgs = {
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetGeneratedCustomerCodeByParentCustomerIdArgs = {
  parentCustomerId: Scalars['ID'];
  codeSuggestionCount?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryValidateCustomerCodeArgs = {
  customerCode: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetCustomerDriverLocationFrequencyConfigurationArgs = {
  customerCode: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetCustomerOutbound214FrequencyArgs = {
  customerId: Scalars['UUID'];
};


/** The root type for all read token operations. */
export type QueryGetOutbound214CustomerEmailAddressesArgs = {
  filter: Outbound214EmailAddressFilter;
};


/** The root type for all read token operations. */
export type QueryGetCarrierEdiTransactionConfigurationsArgs = {
  filter: CarrierEdiTransactionConfigurationFilter;
};


/** The root type for all read token operations. */
export type QueryGetCustomerEdiTransactionConfigurationsArgs = {
  filter: CustomerEdiTransactionConfigurationFilter;
};


/** The root type for all read token operations. */
export type QueryGetFieldMappingConfigurationArgs = {
  filter?: InputMaybe<FieldMappingFilterInput>;
};


/** The root type for all read token operations. */
export type QueryCsvHeadersArgs = {
  documentType: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryGetDocumentByIdArgs = {
  documentId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryFacilityDocumentsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filters: FacilityDocumentFilter;
};


/** The root type for all read token operations. */
export type QueryGetFacilityDocumentByIdArgs = {
  documentId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCarrierDocumentsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filters: CarrierDocumentFilter;
};


/** The root type for all read token operations. */
export type QueryGetCarrierDocumentByIdArgs = {
  carrierDocumentId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryLoadDocumentsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filters: LoadDocumentFilter;
  sort?: InputMaybe<LoadDocumentSort>;
};


/** The root type for all read token operations. */
export type QueryGetLoadDocumentByIdArgs = {
  loadDocumentId?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryEdiDocumentsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filters: EdiDocumentFilter;
};


/** The root type for all read token operations. */
export type QueryGetEdiDocumentByIdArgs = {
  ediDocumentId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryDocumentsArgs = {
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCrmDocumentsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filters: CrmDocumentFilter;
  sort?: InputMaybe<CrmDocumentSort>;
};


/** The root type for all read token operations. */
export type QueryGetCrmDocumentByIdArgs = {
  crmDocumentId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetHosDriverSummaryArgs = {
  driverId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetDriverHosSummaryArgs = {
  driverId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetHosDriverDailySummaryArgs = {
  driverId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetHosDriverAuditHistoryArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters: DriverHosAuditFilter;
};


/** The root type for all read token operations. */
export type QueryGetHosCycleRecapSummaryArgs = {
  driverId: Scalars['String'];
  hosDefaultTimeZoneTerm?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetHosCycleRecapHistoryArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters: DriverHosRecapHistoryFilter;
};


/** The root type for all read token operations. */
export type QueryExportHosAuditHistoryArgs = {
  filters: DriverHosAuditFilter;
};


/** The root type for all read token operations. */
export type QueryExportHosRecapHistoryArgs = {
  filters: DriverHosRecapHistoryFilter;
};


/** The root type for all read token operations. */
export type QueryGetDriverHosSummaryAndRecapArgs = {
  code: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryDriverConversationsArgs = {
  filter?: InputMaybe<DriverConversationsInput>;
};


/** The root type for all read token operations. */
export type QueryReadFollowUpTimesArgs = {
  filter: FollowUpTimesInput;
};


/** The root type for all read token operations. */
export type QueryDriverConversationsV2Args = {
  filter: DriverConversationsV2Input;
};


/** The root type for all read token operations. */
export type QueryGetSchedulesByDriverIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  driverSortingOptions?: InputMaybe<DriverSortingOptions>;
  filters: DriverPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetAddressesByDriverIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  driverSortingOptions?: InputMaybe<DriverSortingOptions>;
  filters: DriverPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetTimeOffsByDriverIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  driverSortingOptions?: InputMaybe<DriverSortingOptions>;
  filters: DriverPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetContactsByDriverIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  driverSortingOptions?: InputMaybe<DriverSortingOptions>;
  filters: DriverPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetAwardsByDriverIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  driverSortingOptions?: InputMaybe<DriverSortingOptions>;
  filters: DriverPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetCertificationsOrPermitsByDriverIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  driverSortingOptions?: InputMaybe<DriverSortingOptions>;
  filters: DriverPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetIdentifiersByDriverIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  driverSortingOptions?: InputMaybe<DriverSortingOptions>;
  filters: DriverPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetDriversByIdentifierArgs = {
  value: Scalars['String'];
  typeTerm: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetEquipmentsByDriverIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  driverSortingOptions?: InputMaybe<DriverSortingOptions>;
  filters: DriverPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetRoutingRequestsByDriverIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  driverSortingOptions?: InputMaybe<DriverSortingOptions>;
  filters: DriverRoutePaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetNextDriverRoutingRequestArgs = {
  driverId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetRoutingRequestsByDriverCodeArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  driverSortingOptions?: InputMaybe<DriverSortingOptions>;
  filters: NewDriverRoutingRequestFilters;
};


/** The root type for all read token operations. */
export type QuerySearchAssetDriverArgs = {
  driverSearch: SearchAssetDriverInput;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QuerySearchAssetDriverByNameOrCodeArgs = {
  query: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryGetDriverByIdArgs = {
  driverId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QuerySearchDriverArgs = {
  driver: SearchDriverInput;
};


/** The root type for all read token operations. */
export type QueryCheckDriverIdentityArgs = {
  driverId?: InputMaybe<Scalars['String']>;
  primaryPhoneNumber?: InputMaybe<Scalars['String']>;
  primaryEmail?: InputMaybe<Scalars['String']>;
  driverIdentityId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetDriverOperationsArgs = {
  driverId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QuerySearchDriverByNameArgs = {
  objName: Scalars['String'];
};


/** The root type for all read token operations. */
export type QuerySearchDriverByIdArgs = {
  objId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetDriverFacilityPreferencesArgs = {
  objId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetDriverCustomerPreferencesArgs = {
  objId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetDriverPreferredLanesArgs = {
  objId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetDriverPreferredRoutesArgs = {
  objId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetActiveDriverPreferredLanesArgs = {
  objId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetActiveDriverPreferredRoutesArgs = {
  objId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetDriverWeeklyTargetsArgs = {
  driverId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetDailyActivitiesByWeeklyTargetIdArgs = {
  weeklyTargetId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetDriverTrainingsArgs = {
  driverId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetDriverFleetsByDriverIdArgs = {
  filters: DriverFleetFilter;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryGetDriverRepsByDriverIdArgs = {
  filters: DriverRelationshipFilter;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryGetDriverByCodeArgs = {
  code: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetDriversByFleetsArgs = {
  filters: DriverActiveFleetFilter;
  sortingOptions?: InputMaybe<DriverSortingOptions>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryDriverAutocompleteArgs = {
  queryFilter: DriverAutocompleteFilter;
};


/** The root type for all read token operations. */
export type QueryGetDriverAvailabilityArgs = {
  input: DriverAvailabilityInput;
};


/** The root type for all read token operations. */
export type QueryGetDriverByCodeV2Args = {
  code: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetTeamPreferencesByDriverIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  driverSortingOptions?: InputMaybe<DriverSortingOptions>;
  filters: DriverPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetCareerGoalsPreferencesByDriverIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  driverSortingOptions?: InputMaybe<DriverSortingOptions>;
  filters: DriverPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetGeographyPreferencesByDriverIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  driverSortingOptions?: InputMaybe<DriverSortingOptions>;
  filters: DriverPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetAssetGeographyPreferencesByDriverIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  driverSortingOptions?: InputMaybe<DriverSortingOptions>;
  filters: DriverPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetOperationalPreferencesByDriverIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  driverSortingOptions?: InputMaybe<DriverSortingOptions>;
  filters: DriverPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetCommodityPreferencesByDriverIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  driverSortingOptions?: InputMaybe<DriverSortingOptions>;
  filters: DriverPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetCustomerPreferencesByDriverIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  driverSortingOptions?: InputMaybe<DriverSortingOptions>;
  filters: DriverPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetFacilityPreferencesByDriverIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  driverSortingOptions?: InputMaybe<DriverSortingOptions>;
  filters: DriverPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryTestDriverPayPrereleaseFeatureArgs = {
  feature: Scalars['String'];
  queryString: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetDriverPayAttributeDefinitionsArgs = {
  filter?: InputMaybe<GetDriverPayAttributeDefinitionsFilter>;
};


/** The root type for all read token operations. */
export type QueryGetDriverPayAttributesArgs = {
  driverId: Scalars['ID'];
  filter?: InputMaybe<GetDriverPayAttributesFilter>;
};


/** The root type for all read token operations. */
export type QueryGetDriverPayAttributesByIdArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
  filter?: InputMaybe<GetDriverPayAttributesFilter>;
};


/** The root type for all read token operations. */
export type QueryCalculateDriverPayArgs = {
  calculateDriverPayInput: CalculateDriverPayInput;
};


/** The root type for all read token operations. */
export type QueryCalculateDriverProjectedPayArgs = {
  calculateDriverProjectedPayInput: CalculateDriverProjectedPayInput;
};


/** The root type for all read token operations. */
export type QueryDriverPayProgramArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetDriverPayProgramsArgs = {
  filter: GetDriverPayProgramsFilter;
};


/** The root type for all read token operations. */
export type QueryGetDriverPayProgramCarrierAssociationsArgs = {
  filter: GetDriverPayProgramCarrierAssociationsFilter;
};


/** The root type for all read token operations. */
export type QueryGetDriverPayProgramDriverAssociationsArgs = {
  filter: GetDriverPayProgramDriverAssociationsFilter;
};


/** The root type for all read token operations. */
export type QueryGetDriverPayRateTableHeadersArgs = {
  payProgramId: Scalars['ID'];
  filter?: InputMaybe<GetDriverPayRateTableHeadersFilter>;
};


/** The root type for all read token operations. */
export type QueryGetDriverPayRateTableDetailsV2Args = {
  filter: GetDriverPayRateTableDetailsFilterV2;
};


/** The root type for all read token operations. */
export type QueryGetDriverPayProgramRouteAssignmentsArgs = {
  filter: GetDriverPayProgramRouteAssignmentsFilter;
};


/** The root type for all read token operations. */
export type QueryGetDriverPayRateTableHistoryArgs = {
  payProgramId: Scalars['ID'];
  payRateTableHeaderId?: InputMaybe<Scalars['ID']>;
  maxRecordCount?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryAllEmployeesV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EmployeeFilterV2>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryEmployeeAuditsNullableArgs = {
  filter?: InputMaybe<EmployeeAuditsFilter>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryPostingCredentialsByEmployeeIdArgs = {
  employeeId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryEmployeeV2Args = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryEmployeesV2Args = {
  text?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryExactEmployeeV2Args = {
  filter?: EmployeeFilterV2;
};


/** The root type for all read token operations. */
export type QueryExactEmployeeManyV2Args = {
  filter: ExactEmployeeManyFilterV2;
};


/** The root type for all read token operations. */
export type QueryUsersV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryExactUserV2Args = {
  email?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetTenantConfigurationArgs = {
  domain: EntityConfigurationDomain;
};


/** The root type for all read token operations. */
export type QueryGetDefaultConfigurationArgs = {
  domain: EntityConfigurationDomain;
  entityType?: InputMaybe<EntityConfigurationEntityType>;
};


/** The root type for all read token operations. */
export type QueryGetEntityConfigurationArgs = {
  domain: EntityConfigurationDomain;
  entityType: EntityConfigurationEntityType;
  entityId: Scalars['ID'];
  uuid?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryGetAllEntityConfigurationsArgs = {
  domain: EntityConfigurationDomain;
  entityType: EntityConfigurationEntityType;
  entityId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryEntityStringLookupArgs = {
  input?: InputMaybe<EntityConfigKeyInput>;
};


/** The root type for all read token operations. */
export type QueryGetGeofencingLocationArgs = {
  input: EntityGeofencingLocationInput;
};


/** The root type for all read token operations. */
export type QueryGetGeofencingLocationV2Args = {
  input: EntityGeofencingLocationInput;
};


/** The root type for all read token operations. */
export type QueryGetGeofencingLocationAuditHistoryArgs = {
  filter: GeofencingLocationAuditHistoryFilter;
};


/** The root type for all read token operations. */
export type QueryDomainKeyValuePairsArgs = {
  input: DomainKeyValuePairInput;
};


/** The root type for all read token operations. */
export type QueryGetEntityGeofencingCustomerRecordArgs = {
  input: EntityGeofencingCustomerSearchInput;
};


/** The root type for all read token operations. */
export type QueryGetEntityGeofencingLocationRecordArgs = {
  input: EntityGeofencingLocationSearchInput;
};


/** The root type for all read token operations. */
export type QueryGenerateEtaArgs = {
  locationDats?: InputMaybe<ReadonlyArray<InputMaybe<LocationDat>>>;
  routeDats?: InputMaybe<ReadonlyArray<InputMaybe<RouteDat>>>;
  routePathDats?: InputMaybe<ReadonlyArray<InputMaybe<RoutePathDat>>>;
  speedMapDats?: InputMaybe<ReadonlyArray<InputMaybe<SpeedMapDat>>>;
  parametersDat?: InputMaybe<ParametersDat>;
  routeID: Scalars['String'];
  useInferredHoS?: InputMaybe<Scalars['Boolean']>;
};


/** The root type for all read token operations. */
export type QueryGenerateHoSInferredEtaArgs = {
  hoursOfServiceInputModel: InferredHoursOfServiceInput;
};


/** The root type for all read token operations. */
export type QueryGetDataScienceEtaSolutionV2Args = {
  route: EtaCalculatorV2RouteDataInput;
  routeLegs: ReadonlyArray<EtaCalculatorV2RouteLegsDataInput>;
};


/** The root type for all read token operations. */
export type QueryManualEtaByStopIdArgs = {
  stopId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryManualEtdByStopIdArgs = {
  stopId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryAutoEstimateByStopIdArgs = {
  stopId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetAdHocEtaCalculatorSolutionV2Args = {
  input: EtaCalculatorV2RequestInput;
};


/** The root type for all read token operations. */
export type QueryAllFacilitiesV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FacilitiesFilterV2>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryFacilityV2Args = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryFacilityByCodeV2Args = {
  code: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryFacilitiesV2Args = {
  input?: InputMaybe<FacilitiesQueryInputTypeV2>;
};


/** The root type for all read token operations. */
export type QueryFacilityAuditsNullableArgs = {
  filter?: InputMaybe<FacilityAuditsFilter>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryFacilitySublocationActivityByIdArgs = {
  activityId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryFacilitySublocationActivitiesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<FacilitySublocationActivityFilter>;
};


/** The root type for all read token operations. */
export type QueryFacilitySublocationAccommodationByIdArgs = {
  accommodationId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryFacilitySublocationAccommodationsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<FacilitySublocationAccommodationFilter>;
};


/** The root type for all read token operations. */
export type QueryFacilitySublocationByIdArgs = {
  sublocationId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QuerySublocationsByFacilityIdArgs = {
  facilityId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QuerySublocationsByFacilityIdV2Args = {
  facilityId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryFacilitySublocationGateByIdArgs = {
  gateId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryFacilitySublocationGatesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<FacilitySublocationGateFilter>;
};


/** The root type for all read token operations. */
export type QueryGetCurrentRateArgs = {
  fromCurrency: Scalars['String'];
  toCurrency: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetHistoricRateArgs = {
  fromCurrency: Scalars['String'];
  toCurrency: Scalars['String'];
  date: Scalars['DateTime'];
  bypassFixedRate?: InputMaybe<Scalars['Boolean']>;
};


/** The root type for all read token operations. */
export type QueryGetFuelProgramCustomerConfigurationSummaryArgs = {
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetFuelProgramCustomerConfigurationArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetEstimatedFuelArgs = {
  loadId: Scalars['ID'];
  customerId: Scalars['ID'];
  orderId: Scalars['ID'];
  currency?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryHasQualifiedFuelProgramArgs = {
  loadId: Scalars['ID'];
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetFinalizedFuelArgs = {
  loadId: Scalars['ID'];
  customerId: Scalars['ID'];
  orderId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetHistoricalFuelIndexPricesByFuelIndexTypeArgs = {
  fuelIndexType: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetCustomerFuelRateHeaderResponseByCustomerAndFuelProgramArgs = {
  customerId: Scalars['ID'];
  fuelProgramCustomerConfigurationId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetCustomerFuelRangesByCustomerFuelRateHeaderIdArgs = {
  customerFuelRateHeaderId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryHasQualifiedCarrierFuelProgramArgs = {
  loadId: Scalars['ID'];
  carrierId: Scalars['ID'];
  routeId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetQuotingFuelSurchargeArgs = {
  input?: InputMaybe<QuotingFuelSurchargeInput>;
};


/** The root type for all read token operations. */
export type QueryGetQuotingFuelSurchargeV2Args = {
  input?: InputMaybe<QuotingFuelSurchargeInputV2>;
};


/** The root type for all read token operations. */
export type QueryGetFuelProgramCarrierConfigurationSummaryArgs = {
  vendorId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetCarrierFuelRangesByCarrierFuelRateHeaderIdArgs = {
  carrierFuelRateHeaderId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetCarrierFuelRateHeaderResponseByCarrierAndFuelProgramArgs = {
  vendorId: Scalars['ID'];
  fuelProgramCarrierConfigurationId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetFuelForExternalServicesArgs = {
  loadId: Scalars['ID'];
  customerId: Scalars['ID'];
  orderId: Scalars['ID'];
  orderHasQuote?: InputMaybe<Scalars['Boolean']>;
  quoteFuelConfiguration?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetCarrierFuelForExternalServicesArgs = {
  loadId: Scalars['ID'];
  vendorId: Scalars['ID'];
  routeId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGeographyV2Args = {
  sourceId: Scalars['Int'];
};


/** The root type for all read token operations. */
export type QueryGeographiesV2Args = {
  filter: GeographyFilter;
  paginationInput?: InputMaybe<PaginationFilter>;
};


/** The root type for all read token operations. */
export type QueryGetPostalCodeCountriesArgs = {
  postalCode: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetPostalCodeStatesArgs = {
  postalCode: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetAdminAreaArgs = {
  input: GeographyAdminAreaInput;
};


/** The root type for all read token operations. */
export type QueryBulkGeographyRequestArgs = {
  input: ReadonlyArray<SingleBulkGeographyRequest>;
};


/** The root type for all read token operations. */
export type QueryGetGeographyAuditHistoryArgs = {
  filter: GeographyAuditHistoryFilter;
};


/** The root type for all read token operations. */
export type QueryExternalGeographyArgs = {
  input: ExternalGeographyInput;
};


/** The root type for all read token operations. */
export type QueryGroupByIdArgs = {
  groupId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryAllGroupsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupsFilterArgs>;
};


/** The root type for all read token operations. */
export type QueryRuleSetEvaluationArgs = {
  filter: RuleSetEvaluationRequestInput;
};


/** The root type for all read token operations. */
export type QuerySearchRuleSetsArgs = {
  filter: SearchRuleSetsInput;
};


/** The root type for all read token operations. */
export type QueryGetEmailDomainsArgs = {
  filter?: InputMaybe<GetEmailDomainsInput>;
};


/** The root type for all read token operations. */
export type QuerySearchTemplatesArgs = {
  filter: SearchTemplatesInput;
};


/** The root type for all read token operations. */
export type QuerySearchHdsTemplatesArgs = {
  filter: SearchHdsTemplatesInput;
};


/** The root type for all read token operations. */
export type QuerySearchHaykEventsArgs = {
  filter: SearchHaykEventsInput;
};


/** The root type for all read token operations. */
export type QuerySearchAuditHistoryArgs = {
  filter: SearchAuditHistoryInput;
};


/** The root type for all read token operations. */
export type QuerySearchHaykRedirectsArgs = {
  filter: SearchHaykRedirectsInput;
};


/** The root type for all read token operations. */
export type QuerySearchHaykOperatorsArgs = {
  filter: SearchHaykOperatorsInput;
};


/** The root type for all read token operations. */
export type QuerySearchHaykFieldMappingsArgs = {
  filter: HaykFieldMappingInput;
};


/** The root type for all read token operations. */
export type QueryGetPricingDataArgs = {
  input?: InputMaybe<ImdlInput>;
};


/** The root type for all read token operations. */
export type QueryGetSniPricingDataArgs = {
  input?: InputMaybe<SniGenericInput>;
};


/** The root type for all read token operations. */
export type QueryAllServiceProfileArgs = {
  carrierId: Scalars['String'];
  filter: FilterServiceProfile;
};


/** The root type for all read token operations. */
export type QueryServiceProfileByIdArgs = {
  id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryAllServiceProfileV2Args = {
  filter: FilterServiceProfileV2;
};


/** The root type for all read token operations. */
export type QueryServiceProfileByIdV2Args = {
  id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryAllSchedulesArgs = {
  serviceProfileId: Scalars['Float'];
};


/** The root type for all read token operations. */
export type QueryAllSchedulesV2Args = {
  serviceProfileId: Scalars['Float'];
};


/** The root type for all read token operations. */
export type QueryIncidentsV2Args = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  sortByColumn?: InputMaybe<Scalars['String']>;
  sortByDirection?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<IncidentFilterV2>;
};


/** The root type for all read token operations. */
export type QueryIncidentByIdv2Args = {
  incidentID: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetLoadAuditDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
  loadId?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryGetLseRouteVendorStatusRecordV3Args = {
  route_id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetLseRouteStatusRecordV3Args = {
  route_id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetLseOrderStatusRecordV3Args = {
  order_id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetLoadStatusRecordsAuditV3Args = {
  record_ct?: InputMaybe<Scalars['Int']>;
  load_id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCheckIfLaneExistV3Args = {
  request: CheckIfLaneExistInputV3;
};


/** The root type for all read token operations. */
export type QueryLaneV2Args = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryLanePaginatedV2Args = {
  filter: LanePaginatedFilter;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


/** The root type for all read token operations. */
export type QueryFindMatchingLanesForRoutesArgs = {
  input: FindMatchingLanesForRoutesInput;
};


/** The root type for all read token operations. */
export type QueryLaneOrdersArgs = {
  input: LaneOrdersInput;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
  before?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryLaneRoutesArgs = {
  input: LaneRoutesInput;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
  before?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryOrderMatchedLanesArgs = {
  input: OrderMatchedLanesInput;
};


/** The root type for all read token operations. */
export type QueryRouteMatchedLanesArgs = {
  input: RouteMatchedLanesInput;
};


/** The root type for all read token operations. */
export type QueryLinkedRouteV2Args = {
  routeID: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryLinkedRoutesArgs = {
  input: LinkedRoutesInput;
};


/** The root type for all read token operations. */
export type QueryCleanseAddressesArgs = {
  multilineAddresses?: InputMaybe<ReadonlyArray<MultiLineRequest>>;
  singlelineAddresses?: InputMaybe<ReadonlyArray<SingleLineBulkRequestInput>>;
};


/** The root type for all read token operations. */
export type QueryCleanseSingleLineAddressArgs = {
  request: SingleLineRequest;
};


/** The root type for all read token operations. */
export type QueryCleanseMultiLineAddressArgs = {
  requests: ReadonlyArray<MultiLineRequest>;
};


/** The root type for all read token operations. */
export type QueryAutocompleteAddressArgs = {
  request?: InputMaybe<AutocompletionRequest>;
};


/** The root type for all read token operations. */
export type QueryZipCodeAddressArgs = {
  request?: InputMaybe<ZipCodeRequest>;
};


/** The root type for all read token operations. */
export type QueryAddressCleanseStatusArgs = {
  filter?: InputMaybe<AddressCleanseStatusFilter>;
};


/** The root type for all read token operations. */
export type QueryGeocodeArgs = {
  request: GeocodeInput;
};


/** The root type for all read token operations. */
export type QueryBatchGeocodeArgs = {
  requests: ReadonlyArray<GeocodeInput>;
};


/** The root type for all read token operations. */
export type QueryReverseGeocodeArgs = {
  request: CoordinateInput;
  precision?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryBatchReverseGeocodeArgs = {
  requests: ReadonlyArray<BatchCoordinateInput>;
};


/** The root type for all read token operations. */
export type QueryLookupTimezoneArgs = {
  request: CoordinateInput;
};


/** The root type for all read token operations. */
export type QueryGeocodeV2Args = {
  request: GeocodeInputV2;
};


/** The root type for all read token operations. */
export type QueryAddressMatchStrengthArgs = {
  address1: GeocodeInputV2;
  address2: GeocodeInputV2;
};


/** The root type for all read token operations. */
export type QueryLookupLocalityArgs = {
  request: LocalityLookupRequest;
};


/** The root type for all read token operations. */
export type QueryGetAdministrativeAreaDetailsArgs = {
  request?: InputMaybe<AdministrativeAreaDetailsRequest>;
};


/** The root type for all read token operations. */
export type QueryCoordinateIsInRangeOfGeofenceArgs = {
  input: CoordinateIsInRangeOfGeofenceInput;
};


/** The root type for all read token operations. */
export type QueryCustomerPricingProgramsV2Args = {
  customerPricingProgramFilter?: InputMaybe<PricingProgramFilter>;
  first?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryCustomerPricingProgramsV3Args = {
  customerPricingProgramFilter?: InputMaybe<PricingProgramFilterV2>;
  first?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryCarrierPricingProgramsV2Args = {
  carrierPricingProgramFilter?: InputMaybe<PricingProgramFilter>;
  first?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetAllCustomerMarkupByCustomerCodeArgs = {
  customerCode?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryGetAllCustomerMarkupByCustomerCodeV2Args = {
  customerCode?: InputMaybe<Scalars['ID']>;
  pagingParameters?: InputMaybe<PagingParameters>;
};


/** The root type for all read token operations. */
export type QueryGetAllCarrierPreferenceByCustomerCodeArgs = {
  customerCode?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryGetBidsPaginatedArgs = {
  filter?: InputMaybe<BidInputFilter>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryGetBidLanesPaginatedArgs = {
  filter?: InputMaybe<BidLanesInputFilter>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryGetRoundsByBidIdArgs = {
  bidId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetAwardedQuotesByBidIdArgs = {
  awardQuotesInput: AwardQuotesInput;
};


/** The root type for all read token operations. */
export type QueryGetBidQuotesByBidLaneIdArgs = {
  bidLaneId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetImportAwardStagingArgs = {
  documentId?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryCalculateMileageArgs = {
  requests: ReadonlyArray<ConfigurationMileageRequest>;
};


/** The root type for all read token operations. */
export type QueryCalculateCarrierMileageArgs = {
  entityId: Scalars['String'];
  useDefaults: Scalars['Boolean'];
  requests: ReadonlyArray<MileageRequest>;
};


/** The root type for all read token operations. */
export type QueryCalculateCustomerMileageArgs = {
  entityId: Scalars['String'];
  useDefaults: Scalars['Boolean'];
  requests: ReadonlyArray<MileageRequest>;
};


/** The root type for all read token operations. */
export type QueryCalculateMileageLengthArgs = {
  requests: ReadonlyArray<ConfigurationMileageRequest>;
  unit?: InputMaybe<UnitOfLengthEnum>;
};


/** The root type for all read token operations. */
export type QueryCalculateDistanceLengthArgs = {
  requests: ReadonlyArray<ConfigurationDistanceRequest>;
  unit?: InputMaybe<UnitOfLengthEnum>;
};


/** The root type for all read token operations. */
export type QueryCalculateCarrierMileageLengthArgs = {
  entityId: Scalars['String'];
  useDefaults: Scalars['Boolean'];
  requests: ReadonlyArray<MileageRequest>;
  unit?: InputMaybe<UnitOfLengthEnum>;
};


/** The root type for all read token operations. */
export type QueryCalculateCarrierDistanceLengthArgs = {
  entityId: Scalars['String'];
  useDefaults: Scalars['Boolean'];
  requests: ReadonlyArray<DistanceRequest>;
  unit?: InputMaybe<UnitOfLengthEnum>;
};


/** The root type for all read token operations. */
export type QueryCalculateCustomerMileageLengthArgs = {
  entityId: Scalars['String'];
  useDefaults: Scalars['Boolean'];
  requests: ReadonlyArray<MileageRequest>;
  unit?: InputMaybe<UnitOfLengthEnum>;
};


/** The root type for all read token operations. */
export type QueryCalculateCustomerDistanceLengthArgs = {
  entityId: Scalars['String'];
  useDefaults: Scalars['Boolean'];
  requests: ReadonlyArray<DistanceRequest>;
  unit?: InputMaybe<UnitOfLengthEnum>;
};


/** The root type for all read token operations. */
export type QueryLookupCountyQualiferArgs = {
  locality: Scalars['String'];
  administrativeArea: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryLookupCountyQualifierArgs = {
  locality: Scalars['String'];
  administrativeArea: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryFindScheduledJobsArgs = {
  driverId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryFindScheduledJobsV2Args = {
  driverId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryFindDriverMobileScheduledJobsArgs = {
  driverId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetDriverRoutesArgs = {
  input: GetDriverRoutesInput;
};


/** The root type for all read token operations. */
export type QueryGetDriverRoutesV2Args = {
  input: GetDriverRoutesInput;
};


/** The root type for all read token operations. */
export type QueryGetDriverMobileRoutesArgs = {
  input: GetDriverRoutesInput;
};


/** The root type for all read token operations. */
export type QueryGetDriverRouteArgs = {
  input: GetDriverRouteInput;
};


/** The root type for all read token operations. */
export type QueryGetDriverRouteV2Args = {
  input: GetDriverRouteInput;
};


/** The root type for all read token operations. */
export type QueryGetDriverRouteV3Args = {
  input: GetDriverRouteInput;
};


/** The root type for all read token operations. */
export type QueryGetDriverMobileRouteArgs = {
  input: GetDriverRouteInput;
};


/** The root type for all read token operations. */
export type QueryGetAllDevicesByDriverIdArgs = {
  driverId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetAllDevicesByDriverMobileIdArgs = {
  driverId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetDriverMobileDetailsByEmailArgs = {
  driverMobileEmailAddress: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetDriverMobileDetailsByEmailV2Args = {
  driverMobileEmailAddress: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetDriverMobileInvitationAuditsArgs = {
  driverId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryOfferArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryOfferByCodeArgs = {
  code: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryOfferByCodeV2Args = {
  code: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryOffersArgs = {
  filter?: InputMaybe<GetOffersArgsInput>;
};


/** The root type for all read token operations. */
export type QueryGetOffersPaginatedArgs = {
  filter?: InputMaybe<OffersPaginatedQueryFilters>;
};


/** The root type for all read token operations. */
export type QueryOffersCountArgs = {
  filter?: InputMaybe<GetOffersArgsInput>;
};


/** The root type for all read token operations. */
export type QueryOptiMatchSessionArgs = {
  sessionId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetAlternativeMatchesArgs = {
  sessionId: Scalars['ID'];
  filter: AlternativeMatchesFilter;
};


/** The root type for all read token operations. */
export type QueryListObjectivesArgs = {
  filter?: InputMaybe<CustomObjectiveFilter>;
};


/** The root type for all read token operations. */
export type QueryFilteredPlannedNetworksArgs = {
  input: FilteredPlannedNetworksInput;
};


/** The root type for all read token operations. */
export type QueryPlannedNetworkArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryNetworkChannelArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryChannelLegArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryPostingArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryPostingsArgs = {
  filter: PostingsFilter;
};


/** The root type for all read token operations. */
export type QueryPostingsByRouteIdsArgs = {
  input: PostingsByRouteIdsInput;
};


/** The root type for all read token operations. */
export type QueryEmbedParamsArgs = {
  workspaceId?: InputMaybe<Scalars['ID']>;
  reportId?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryGetMaintenanceByPowerIdArgs = {
  filters: PowerMaintenanceFilters;
  sortingOptions?: InputMaybe<SortingOptions>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QuerySearchPowerEquipmentArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  sortingOptions?: InputMaybe<SortingOptions>;
  filters: PaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetAssetPowerbyIdArgs = {
  objId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetassetPowerStatusDetailsArgs = {
  objId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetAssetPowerSearchArgs = {
  objPool?: InputMaybe<Scalars['String']>;
  objDriverId?: InputMaybe<Scalars['String']>;
  objFleet?: InputMaybe<Scalars['String']>;
  objSegment?: InputMaybe<Scalars['String']>;
  objDivision?: InputMaybe<Scalars['String']>;
  objType?: InputMaybe<Scalars['String']>;
  objNumber?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySearchAssetPowerArgs = {
  filters: SearchInputFilters;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryCheckPowerIdentityArgs = {
  powerId?: InputMaybe<Scalars['String']>;
  powerNumber?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySearchPowerDriverByNameArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  objName: Scalars['String'];
};


/** The root type for all read token operations. */
export type QuerySearchPowerDriverByIdArgs = {
  objId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetPowerByCodeV2Args = {
  code: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetPowerByCodeArgs = {
  code: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetPowerPatchByCodeArgs = {
  code: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetPowerFleetsByPowerIdArgs = {
  filters: PowerFilters;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryGetIdentifiersByPowerIdArgs = {
  filters: PowerIdentifierFilters;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryGetPowersByFleetsArgs = {
  queryFilter: PowerActiveFleetFilter;
  sortingOptions?: InputMaybe<PowerSortingOptions>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryPowerAutoCompleteArgs = {
  queryFilter: PowerAutoCompleteFilter;
};


/** The root type for all read token operations. */
export type QueryGetAssetPowerPingHistoryArgs = {
  filter: PingHistoryFilters;
  sortingOptions?: InputMaybe<PingSortingOptions>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryGetPowerPingHistoryArgs = {
  filter: PingHistoryFilters;
  sortingOptions?: InputMaybe<PingSortOptions>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryPreplanByIdArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryPreplanByRouteIdArgs = {
  routeId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryPreplanByRouteIdV2Args = {
  input: FindByRouteIdQueryInput;
};


/** The root type for all read token operations. */
export type QueryAdvertisedPriceDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: AdvertisedPriceRequestFilter;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryCarrierCostsByRouteArgs = {
  filter?: InputMaybe<CarrierCostsByRouteFilter>;
};


/** The root type for all read token operations. */
export type QueryCustomerOrderRatesByCarrierArgs = {
  filter?: InputMaybe<CustomerOrderRatesByCarrierRequestFilter>;
};


/** The root type for all read token operations. */
export type QueryCustomerOrderRatesV2Args = {
  filter?: InputMaybe<CustomerOrderRatesRequestFilterV2>;
};


/** The root type for all read token operations. */
export type QueryDatContractRatesArgs = {
  filter?: InputMaybe<DatContractRatesFilterType>;
};


/** The root type for all read token operations. */
export type QueryDatSpotRatesArgs = {
  filter?: InputMaybe<DatSpotRatesFilterType>;
};


/** The root type for all read token operations. */
export type QueryExpectedCostDetailArgs = {
  filter: ExpectedCostRequestFilter;
};


/** The root type for all read token operations. */
export type QueryFtlPricesByQuoteArgs = {
  input: FtlPricesByQuoteInputType;
};


/** The root type for all read token operations. */
export type QueryImdlPricesByQuoteArgs = {
  input?: InputMaybe<ImdlPricesByQuoteInput>;
};


/** The root type for all read token operations. */
export type QueryLtlPricesByOrderArgs = {
  input: LtlPricesByOrderInput;
};


/** The root type for all read token operations. */
export type QueryLtlPricesByQuoteArgs = {
  input?: InputMaybe<LtlPricesByQuoteInput>;
};


/** The root type for all read token operations. */
export type QueryMostRecentLtlRatingDataArgs = {
  input?: InputMaybe<MostRecentLtlRatingData>;
};


/** The root type for all read token operations. */
export type QueryPriceDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PriceRequestFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryPricingQuoteArgs = {
  filter: PricingQuoteFilterType;
};


/** The root type for all read token operations. */
export type QueryPricingRouteArgs = {
  filter: PricingRouteFilterType;
};


/** The root type for all read token operations. */
export type QueryQuotePricingDetailsArgs = {
  filter: ExternalQuotePricingDetailsRequestFilter;
};


/** The root type for all read token operations. */
export type QueryAvailableRouteArgs = {
  id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryAllAvailableRoutesArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AvailableRoutesFilter>;
};


/** The root type for all read token operations. */
export type QueryAvailableTruckArgs = {
  truckPostingId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryAllAvailableTrucksArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AvailableTrucksFilter>;
};


/** The root type for all read token operations. */
export type QueryProcurementCountsByAreaArgs = {
  filter?: InputMaybe<ProcurementCountFilter>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  timeZone?: InputMaybe<Scalars['String']>;
  routeActivationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  ownerId?: InputMaybe<Scalars['String']>;
  containerProgram?: InputMaybe<Scalars['String']>;
  capacityNeed?: InputMaybe<ReadonlyArray<CapacityNeed>>;
  by?: InputMaybe<ProcurementCountBy>;
};


/** The root type for all read token operations. */
export type QueryAvailableGateReservationArgs = {
  gateReservationId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryAllAvailableGateReservationsArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AvailableGateReservationFilter>;
};


/** The root type for all read token operations. */
export type QueryAvailableContainerArgs = {
  containerId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryAllAvailableContainersArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AvailableContainerFilter>;
};


/** The root type for all read token operations. */
export type QueryMatchesArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter: MatchFilter;
};


/** The root type for all read token operations. */
export type QuerySearchMatchingRoutesForTruckArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter: MatchFilter;
  truckPostingId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QuerySearchMatchingTrucksForRouteArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  routeId: Scalars['String'];
  availableTrucksFilter?: InputMaybe<AvailableTrucksFilter>;
  filter?: InputMaybe<MatchFilter>;
};


/** The root type for all read token operations. */
export type QueryGateReservationMatchesArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter: GateReservationMatchFilter;
};


/** The root type for all read token operations. */
export type QuerySearchMatchingGateReservationsForRouteArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  routeId: Scalars['String'];
  availableGateReservationFilter?: InputMaybe<AvailableGateReservationFilter>;
  filter?: InputMaybe<GateReservationMatchFilter>;
};


/** The root type for all read token operations. */
export type QueryContainerMatchesArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter: ContainerMatchFilter;
};


/** The root type for all read token operations. */
export type QuerySearchMatchingRoutesForContainerArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter: ContainerMatchFilter;
  containerId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QuerySearchMatchingContainersForRouteArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  routeId: Scalars['String'];
  availableContainerFilter?: InputMaybe<AvailableContainerFilter>;
  filter?: InputMaybe<ContainerMatchFilter>;
};


/** The root type for all read token operations. */
export type QueryProjectByIdArgs = {
  projectId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryProjectsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProjectsFilterArgs>;
};


/** The root type for all read token operations. */
export type QueryRouteVendorLoadTenderFirstSentArgs = {
  routeVendorId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryRouteVendorLoadTenderLastSentArgs = {
  routeVendorId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryRateConfirmationsArgs = {
  filter: RateConfirmationsFilter;
};


/** The root type for all read token operations. */
export type QueryCustomerQuotePaginatedV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: RateQuotesPaginatedFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


/** The root type for all read token operations. */
export type QueryCustomerQuotePaginatedV3Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: RateQuotesPaginatedFilterV3;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


/** The root type for all read token operations. */
export type QueryCustomerQuotePaginatedV4Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: RateQuotesPaginatedFilterV3;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


/** The root type for all read token operations. */
export type QueryCustomerQuotePaginatedV5Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: RateQuotesPaginatedFilterV3;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


/** The root type for all read token operations. */
export type QueryGetCustomerQuoteByIdArgs = {
  quoteId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetCustomerQuoteByIdV2Args = {
  quoteId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetCustomerQuoteByIdV3Args = {
  quoteId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryMatchingRateQuoteArgs = {
  input?: InputMaybe<MatchingRateQuoteInput>;
};


/** The root type for all read token operations. */
export type QueryMatchingRateQuoteV2Args = {
  input?: InputMaybe<MatchingRateQuoteInput>;
};


/** The root type for all read token operations. */
export type QueryMatchingRateQuoteV3Args = {
  input?: InputMaybe<MatchingRateQuoteInputV2>;
};


/** The root type for all read token operations. */
export type QueryAllCustomersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CustomersFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryAllCustomersNullableArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CustomersFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryAllEmployeesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EmployeeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryAllFacilitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FacilitiesFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryAllTempLoadsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TempLoadsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryBouncesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BouncesFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryCarrierArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCarrierNullableArgs = {
  carrier: CarrierArgumentInput;
};


/** The root type for all read token operations. */
export type QueryCarrierRepsArgs = {
  order?: InputMaybe<CarrierRepOrder>;
};


/** The root type for all read token operations. */
export type QueryCarrierSettingArgs = {
  carrierId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCarriersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CarriersFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryCarriersNullableArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CarriersFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryCommoditiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CommoditiesFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryCountsByAreaArgs = {
  by?: InputMaybe<AreaCountBy>;
  endDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  startDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
};


/** The root type for all read token operations. */
export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCustomerByCodeArgs = {
  code: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryCustomerQuotesArgs = {
  where?: InputMaybe<QuotesQueryInput>;
};


/** The root type for all read token operations. */
export type QueryCustomerQuotesPaginatedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<QuotesPaginatedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryEmployeeArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryEmployeesArgs = {
  text?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryExactEmployeeArgs = {
  filter?: EmployeeFilter;
};


/** The root type for all read token operations. */
export type QueryExactUserArgs = {
  email?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryFacilitiesArgs = {
  input?: InputMaybe<FacilitiesQueryInput>;
};


/** The root type for all read token operations. */
export type QueryFacilityArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryFacilityByCodeArgs = {
  code: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetTruckPostingsForCarrierArgs = {
  carrierId: Scalars['ID'];
  endDate?: InputMaybe<Scalars['Iso8601Date']>;
  endDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  startDate?: InputMaybe<Scalars['Iso8601Date']>;
  startDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
};


/** The root type for all read token operations. */
export type QueryLoadArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryLoadRoutesOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LoadsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryLoadsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LoadsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryMatchQueryArgs = {
  carrierId?: InputMaybe<Scalars['ID']>;
  destinationDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  employeeId?: InputMaybe<Scalars['ID']>;
  endDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  input?: InputMaybe<MatchQueryInput>;
  originDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  startDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  truckPostingId?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryMatchQueryCombinedArgs = {
  carrierId?: InputMaybe<Scalars['ID']>;
  destinationDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  employeeId?: InputMaybe<Scalars['ID']>;
  endDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  input?: InputMaybe<MatchQueryInput>;
  originDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  startDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  truckPostingId?: InputMaybe<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryMileageArgs = {
  filter: MileageFilter;
};


/** The root type for all read token operations. */
export type QueryMileageForPointsArgs = {
  filter: MileageForPointsFilter;
};


/** The root type for all read token operations. */
export type QueryOrderArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryOrderByCodeArgs = {
  code: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryOrderByCustomerOrderNumberArgs = {
  customerOrderNumber: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryQuoteArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryRouteArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryRouteVendorArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryRouteVendorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RouteVendorsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryRoutesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RoutesQueryInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryStopsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<StopsQueryInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryTempLoadsArgs = {
  input: TempLoadsInput;
};


/** The root type for all read token operations. */
export type QueryTenantConfigurationArgs = {
  configType?: InputMaybe<ConfigTypeEnum>;
};


/** The root type for all read token operations. */
export type QueryTruckPostingsArgs = {
  input: TruckPostingsInput;
};


/** The root type for all read token operations. */
export type QueryTruckPostingsExternalArgs = {
  input: TruckPostingsExternalInput;
};


/** The root type for all read token operations. */
export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryRegionInfoArgs = {
  request: RegionInfoInput;
};


/** The root type for all read token operations. */
export type QueryRegionInfoByIdArgs = {
  uuid: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryRegionInfoByUrnArgs = {
  urn?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryRegionInfoV2Args = {
  regionsV2Input?: InputMaybe<RegionsV2Input>;
};


/** The root type for all read token operations. */
export type QueryAllRegionsV2Args = {
  allRegionsV2Input?: InputMaybe<AllRegionsV2Input>;
};


/** The root type for all read token operations. */
export type QueryAllRegionsV2NewArgs = {
  allRegionsV2InputNew?: InputMaybe<AllRegionsV2InputNew>;
};


/** The root type for all read token operations. */
export type QueryRegionSetArgs = {
  regionSetV2Input?: InputMaybe<RegionSetV2Input>;
};


/** The root type for all read token operations. */
export type QueryGetRegionSetDetailsArgs = {
  regionSetID: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetRegionInfoByRegionIdArgs = {
  regionId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetSuggestedCarriersArgs = {
  input: SuggestedCarriersInput;
};


/** The root type for all read token operations. */
export type QueryFacilityBulkValidationArgs = {
  input: ReadonlyArray<FacilityValidationInput>;
};


/** The root type for all read token operations. */
export type QueryCustomerBulkValidationArgs = {
  input: ReadonlyArray<CustomerValidationInput>;
};


/** The root type for all read token operations. */
export type QueryCarrierBulkValidationArgs = {
  input: ReadonlyArray<CarrierValidationInput>;
};


/** The root type for all read token operations. */
export type QueryRouteResourcesArgs = {
  routeID: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryRouteResourcesByFilterArgs = {
  input: RouteResourcesFilterInput;
};


/** The root type for all read token operations. */
export type QueryDriverAssignmentArgs = {
  routeID: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryDriverAssignmentsArgs = {
  filters: DriverAssignmentFilter;
};


/** The root type for all read token operations. */
export type QueryDriverAssignmentForRouteArgs = {
  routeID: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryDriverBouncesArgs = {
  input: DriverBouncesInput;
};


/** The root type for all read token operations. */
export type QueryGetSavedFiltersArgs = {
  filter: SavedFilterGroupInputType;
  orderBy: SavedFilterGroupSortBy;
  pagination: SavedFilterPaginationInput;
};


/** The root type for all read token operations. */
export type QueryGetTablePreferenceArgs = {
  table: TablePreferenceGroupInput;
};


/** The root type for all read token operations. */
export type QuerySearchFacilitiesByAddressArgs = {
  requestId?: InputMaybe<Scalars['String']>;
  filter: SearchFacilityAddressInput;
};


/** The root type for all read token operations. */
export type QuerySearchFacilitiesByAddressV2Args = {
  requestId?: InputMaybe<Scalars['String']>;
  filter: SearchFacilityAddressInput;
};


/** The root type for all read token operations. */
export type QueryTestSecureDataPrereleaseFeatureArgs = {
  feature: Scalars['String'];
  queryString: Scalars['String'];
};


/** The root type for all read token operations. */
export type QuerySecureDataTokenizationConfigurationArgs = {
  input: SecureDataTokenizationConfigurationInput;
};


/** The root type for all read token operations. */
export type QuerySecureDataTokenDataArgs = {
  input: SecureDataTokenDataInput;
};


/** The root type for all read token operations. */
export type QuerySeerSearchAccountingAdvanceProviderConfigArgs = {
  filter?: InputMaybe<SeerAccountingAdvanceProviderConfigFilter>;
  orderBy?: InputMaybe<SeerAccountingAdvanceProviderConfigOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchAccountingAdvancesArgs = {
  filter?: InputMaybe<SeerAccountingAdvancesFilter>;
  orderBy?: InputMaybe<SeerAccountingAdvancesOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchAccountingAdvsStatusNotificationArgs = {
  filter?: InputMaybe<SeerAccountingAdvsStatusNotificationFilter>;
  orderBy?: InputMaybe<SeerAccountingAdvsStatusNotificationOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchAccountingCarrierFinalizedFuelArgs = {
  filter?: InputMaybe<SeerAccountingCarrierFinalizedFuelFilter>;
  orderBy?: InputMaybe<SeerAccountingCarrierFinalizedFuelOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchAccountingCostDetailsArgs = {
  filter?: InputMaybe<SeerAccountingCostDetailsFilter>;
  orderBy?: InputMaybe<SeerAccountingCostDetailsOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchAccountingCustomerOrderArgs = {
  filter?: InputMaybe<SeerAccountingCustomerOrderFilter>;
  orderBy?: InputMaybe<SeerAccountingCustomerOrderOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchAccountingExceptionsArgs = {
  filter?: InputMaybe<SeerAccountingExceptionsFilter>;
  orderBy?: InputMaybe<SeerAccountingExceptionsOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchAccountingFinalisedFuelArgs = {
  filter?: InputMaybe<SeerAccountingFinalisedFuelFilter>;
  orderBy?: InputMaybe<SeerAccountingFinalisedFuelOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchAccountingNonRouteCostDetailsArgs = {
  filter?: InputMaybe<SeerAccountingNonRouteCostDetailsFilter>;
  orderBy?: InputMaybe<SeerAccountingNonRouteCostDetailsOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchAccountingRateLineDetailArgs = {
  filter?: InputMaybe<SeerAccountingRateLineDetailFilter>;
  orderBy?: InputMaybe<SeerAccountingRateLineDetailOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchAccountingUnvoucheredVendorInvoiceArgs = {
  filter?: InputMaybe<SeerAccountingUnvoucheredVendorInvoiceFilter>;
  orderBy?: InputMaybe<SeerAccountingUnvoucheredVendorInvoiceOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchAccountingVendorInvoiceArgs = {
  filter?: InputMaybe<SeerAccountingVendorInvoiceFilter>;
  orderBy?: InputMaybe<SeerAccountingVendorInvoiceOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchAccountingVendorRouteReqDocCompleteStatusArgs = {
  filter?: InputMaybe<SeerAccountingVendorRouteReqDocCompleteStatusFilter>;
  orderBy?: InputMaybe<SeerAccountingVendorRouteReqDocCompleteStatusOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchAccountingVendorRouteRequiredDocumentCompletionStatusArgs = {
  filter?: InputMaybe<SeerAccountingVendorRouteRequiredDocumentCompletionStatusFilter>;
  orderBy?: InputMaybe<SeerAccountingVendorRouteRequiredDocumentCompletionStatusOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchAccountingVendorVoucherArgs = {
  filter?: InputMaybe<SeerAccountingVendorVoucherFilter>;
  orderBy?: InputMaybe<SeerAccountingVendorVoucherOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchAssignedRepArgs = {
  filter?: InputMaybe<SeerAssignedRepFilter>;
  orderBy?: InputMaybe<SeerAssignedRepOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchAssignedRepGroupArgs = {
  filter?: InputMaybe<SeerAssignedRepGroupFilter>;
  orderBy?: InputMaybe<SeerAssignedRepGroupOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchBillToCodeArgs = {
  filter?: InputMaybe<SeerBillToCodeFilter>;
  orderBy?: InputMaybe<SeerBillToCodeOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCarrierArgs = {
  filter?: InputMaybe<SeerCarrierFilter>;
  orderBy?: InputMaybe<SeerCarrierOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCarrierShipmentArgs = {
  filter?: InputMaybe<SeerCarrierShipmentFilter>;
  orderBy?: InputMaybe<SeerCarrierShipmentOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCarrierAccountingStatusArgs = {
  filter?: InputMaybe<SeerCarrierAccountingStatusFilter>;
  orderBy?: InputMaybe<SeerCarrierAccountingStatusOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCarrierAddressArgs = {
  filter?: InputMaybe<SeerCarrierAddressFilter>;
  orderBy?: InputMaybe<SeerCarrierAddressOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCarrierCertificationArgs = {
  filter?: InputMaybe<SeerCarrierCertificationFilter>;
  orderBy?: InputMaybe<SeerCarrierCertificationOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCarrierCustomerRelationshipArgs = {
  filter?: InputMaybe<SeerCarrierCustomerRelationshipFilter>;
  orderBy?: InputMaybe<SeerCarrierCustomerRelationshipOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCarrierFacilityRelationshipArgs = {
  filter?: InputMaybe<SeerCarrierFacilityRelationshipFilter>;
  orderBy?: InputMaybe<SeerCarrierFacilityRelationshipOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCarrierGroupArgs = {
  filter?: InputMaybe<SeerCarrierGroupFilter>;
  orderBy?: InputMaybe<SeerCarrierGroupOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCarrierIdentifierArgs = {
  filter?: InputMaybe<SeerCarrierIdentifierFilter>;
  orderBy?: InputMaybe<SeerCarrierIdentifierOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCarrierRepArgs = {
  filter?: InputMaybe<SeerCarrierRepFilter>;
  orderBy?: InputMaybe<SeerCarrierRepOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCarrierRouteArgs = {
  filter?: InputMaybe<SeerCarrierRouteFilter>;
  orderBy?: InputMaybe<SeerCarrierRouteOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCarrierServiceArgs = {
  filter?: InputMaybe<SeerCarrierServiceFilter>;
  orderBy?: InputMaybe<SeerCarrierServiceOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCdcauditArgs = {
  filter?: InputMaybe<SeerCdcauditFilter>;
  orderBy?: InputMaybe<SeerCdcauditOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCommandLogArgs = {
  filter?: InputMaybe<SeerCommandLogFilter>;
  orderBy?: InputMaybe<SeerCommandLogOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCrmInteractionArgs = {
  filter?: InputMaybe<SeerCrmInteractionFilter>;
  orderBy?: InputMaybe<SeerCrmInteractionOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCurrentAndNextStopArgs = {
  filter?: InputMaybe<SeerCurrentAndNextStopFilter>;
  orderBy?: InputMaybe<SeerCurrentAndNextStopOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCurrentStopArgs = {
  filter?: InputMaybe<SeerCurrentStopFilter>;
  orderBy?: InputMaybe<SeerCurrentStopOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCustomerGroupArgs = {
  filter?: InputMaybe<SeerCustomerGroupFilter>;
  orderBy?: InputMaybe<SeerCustomerGroupOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCustomerRepArgs = {
  filter?: InputMaybe<SeerCustomerRepFilter>;
  orderBy?: InputMaybe<SeerCustomerRepOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCustomerAddressArgs = {
  filter?: InputMaybe<SeerCustomerAddressFilter>;
  orderBy?: InputMaybe<SeerCustomerAddressOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCustomerCapacityConstraintOrderArgs = {
  filter?: InputMaybe<SeerCustomerCapacityConstraintOrderFilter>;
  orderBy?: InputMaybe<SeerCustomerCapacityConstraintOrderOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCustomerCapacityConstraintTimePeriodArgs = {
  filter?: InputMaybe<SeerCustomerCapacityConstraintTimePeriodFilter>;
  orderBy?: InputMaybe<SeerCustomerCapacityConstraintTimePeriodOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCustomerCommitmentArgs = {
  filter?: InputMaybe<SeerCustomerCommitmentFilter>;
  orderBy?: InputMaybe<SeerCustomerCommitmentOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCustomerCommitmentOrderArgs = {
  filter?: InputMaybe<SeerCustomerCommitmentOrderFilter>;
  orderBy?: InputMaybe<SeerCustomerCommitmentOrderOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCustomerCommitmentSettingOrderStatusArgs = {
  filter?: InputMaybe<SeerCustomerCommitmentSettingOrderStatusFilter>;
  orderBy?: InputMaybe<SeerCustomerCommitmentSettingOrderStatusOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCustomerCommitmentTimePeriodArgs = {
  filter?: InputMaybe<SeerCustomerCommitmentTimePeriodFilter>;
  orderBy?: InputMaybe<SeerCustomerCommitmentTimePeriodOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCustomerIdentifierArgs = {
  filter?: InputMaybe<SeerCustomerIdentifierFilter>;
  orderBy?: InputMaybe<SeerCustomerIdentifierOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchCustomerOrderArgs = {
  filter?: InputMaybe<SeerCustomerOrderFilter>;
  orderBy?: InputMaybe<SeerCustomerOrderOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchEmployeeArgs = {
  filter?: InputMaybe<SeerEmployeeFilter>;
  orderBy?: InputMaybe<SeerEmployeeOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchFacilityArgs = {
  filter?: InputMaybe<SeerFacilityFilter>;
  orderBy?: InputMaybe<SeerFacilityOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchFacilityAddressArgs = {
  filter?: InputMaybe<SeerFacilityAddressFilter>;
  orderBy?: InputMaybe<SeerFacilityAddressOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchFacilityCarrierArgs = {
  filter?: InputMaybe<SeerFacilityCarrierFilter>;
  orderBy?: InputMaybe<SeerFacilityCarrierOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchFacilityCustomerArgs = {
  filter?: InputMaybe<SeerFacilityCustomerFilter>;
  orderBy?: InputMaybe<SeerFacilityCustomerOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchFacilityCustomerRelationshipArgs = {
  filter?: InputMaybe<SeerFacilityCustomerRelationshipFilter>;
  orderBy?: InputMaybe<SeerFacilityCustomerRelationshipOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchFacilityIdentifierArgs = {
  filter?: InputMaybe<SeerFacilityIdentifierFilter>;
  orderBy?: InputMaybe<SeerFacilityIdentifierOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchFacilityRouteArgs = {
  filter?: InputMaybe<SeerFacilityRouteFilter>;
  orderBy?: InputMaybe<SeerFacilityRouteOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchFacilityTrackingBoardArgs = {
  filter?: InputMaybe<SeerFacilityTrackingBoardFilter>;
  orderBy?: InputMaybe<SeerFacilityTrackingBoardOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchGlobalChargeTypeConfigArgs = {
  filter?: InputMaybe<SeerGlobalChargeTypeConfigFilter>;
  orderBy?: InputMaybe<SeerGlobalChargeTypeConfigOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchIncidentsArgs = {
  filter?: InputMaybe<SeerIncidentsFilter>;
  orderBy?: InputMaybe<SeerIncidentsOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchIncidentsRoutesArgs = {
  filter?: InputMaybe<SeerIncidentsRoutesFilter>;
  orderBy?: InputMaybe<SeerIncidentsRoutesOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchInvoiceArgs = {
  filter?: InputMaybe<SeerInvoiceFilter>;
  orderBy?: InputMaybe<SeerInvoiceOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchLinkedRoutesArgs = {
  filter?: InputMaybe<SeerLinkedRoutesFilter>;
  orderBy?: InputMaybe<SeerLinkedRoutesOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchLoadArgs = {
  filter?: InputMaybe<SeerLoadFilter>;
  orderBy?: InputMaybe<SeerLoadOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchLoadSearchArgs = {
  filter?: InputMaybe<SeerLoadSearchFilter>;
  orderBy?: InputMaybe<SeerLoadSearchOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchLoadSearchDataAggregationArgs = {
  filter?: InputMaybe<SeerLoadSearchDataAggregationFilter>;
  orderBy?: InputMaybe<SeerLoadSearchDataAggregationOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchLoadSearchSideBarArgs = {
  filter?: InputMaybe<SeerLoadSearchSideBarFilter>;
  orderBy?: InputMaybe<SeerLoadSearchSideBarOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchLoadSearchV2Args = {
  filter?: InputMaybe<SeerLoadSearchV2Filter>;
  orderBy?: InputMaybe<SeerLoadSearchV2OrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchLoadSummaryDataAggregationArgs = {
  filter?: InputMaybe<SeerLoadSummaryDataAggregationFilter>;
  orderBy?: InputMaybe<SeerLoadSummaryDataAggregationOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchLocksArgs = {
  filter?: InputMaybe<SeerLocksFilter>;
  orderBy?: InputMaybe<SeerLocksOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchLseToEqStagingArgs = {
  filter?: InputMaybe<SeerLseToEqStagingFilter>;
  orderBy?: InputMaybe<SeerLseToEqStagingOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchMainPageRouteBoardArgs = {
  filter?: InputMaybe<SeerMainPageRouteBoardFilter>;
  orderBy?: InputMaybe<SeerMainPageRouteBoardOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchMainPageRouteBoardCountUnifiedArgs = {
  filter?: InputMaybe<SeerMainPageRouteBoardCountUnifiedFilter>;
  orderBy?: InputMaybe<SeerMainPageRouteBoardCountUnifiedOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchMainPageRouteBoardDetailsArgs = {
  filter?: InputMaybe<SeerMainPageRouteBoardDetailsFilter>;
  orderBy?: InputMaybe<SeerMainPageRouteBoardDetailsOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchMainPageRouteBoardPendingArgs = {
  filter?: InputMaybe<SeerMainPageRouteBoardPendingFilter>;
  orderBy?: InputMaybe<SeerMainPageRouteBoardPendingOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchMainPageRouteBoardPreTenderArgs = {
  filter?: InputMaybe<SeerMainPageRouteBoardPreTenderFilter>;
  orderBy?: InputMaybe<SeerMainPageRouteBoardPreTenderOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchMainPageRouteBoardTenderArgs = {
  filter?: InputMaybe<SeerMainPageRouteBoardTenderFilter>;
  orderBy?: InputMaybe<SeerMainPageRouteBoardTenderOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchMainPageTrackingBoardArgs = {
  filter?: InputMaybe<SeerMainPageTrackingBoardFilter>;
  orderBy?: InputMaybe<SeerMainPageTrackingBoardOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchMainPageTrackingBoardV2Args = {
  filter?: InputMaybe<SeerMainPageTrackingBoardV2Filter>;
  orderBy?: InputMaybe<SeerMainPageTrackingBoardV2OrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchMainPageTrackingBoardV2CountArgs = {
  filter?: InputMaybe<SeerMainPageTrackingBoardV2CountFilter>;
  orderBy?: InputMaybe<SeerMainPageTrackingBoardV2CountOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchMasterFindLoadSearchArgs = {
  filter?: InputMaybe<SeerMasterFindLoadSearchFilter>;
  orderBy?: InputMaybe<SeerMasterFindLoadSearchOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchMasterSignStatusArgs = {
  filter?: InputMaybe<SeerMasterSignStatusFilter>;
  orderBy?: InputMaybe<SeerMasterSignStatusOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchMe_287848CustomerArgs = {
  filter?: InputMaybe<SeerMe_287848CustomerFilter>;
  orderBy?: InputMaybe<SeerMe_287848CustomerOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchOrderReferenceArgs = {
  filter?: InputMaybe<SeerOrderReferenceFilter>;
  orderBy?: InputMaybe<SeerOrderReferenceOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchOrderRepArgs = {
  filter?: InputMaybe<SeerOrderRepFilter>;
  orderBy?: InputMaybe<SeerOrderRepOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchOrderRepGroupArgs = {
  filter?: InputMaybe<SeerOrderRepGroupFilter>;
  orderBy?: InputMaybe<SeerOrderRepGroupOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchOrderCommodityArgs = {
  filter?: InputMaybe<SeerOrderCommodityFilter>;
  orderBy?: InputMaybe<SeerOrderCommodityOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchOrderStatusArgs = {
  filter?: InputMaybe<SeerOrderStatusFilter>;
  orderBy?: InputMaybe<SeerOrderStatusOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchOrderStopArgs = {
  filter?: InputMaybe<SeerOrderStopFilter>;
  orderBy?: InputMaybe<SeerOrderStopOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchOrganizationArgs = {
  filter?: InputMaybe<SeerOrganizationFilter>;
  orderBy?: InputMaybe<SeerOrganizationOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchPowerArgs = {
  filter?: InputMaybe<SeerPowerFilter>;
  orderBy?: InputMaybe<SeerPowerOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchPowerRouteAssignmentArgs = {
  filter?: InputMaybe<SeerPowerRouteAssignmentFilter>;
  orderBy?: InputMaybe<SeerPowerRouteAssignmentOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchPowerRoutePingArgs = {
  filter?: InputMaybe<SeerPowerRoutePingFilter>;
  orderBy?: InputMaybe<SeerPowerRoutePingOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchRouteArgs = {
  filter?: InputMaybe<SeerRouteFilter>;
  orderBy?: InputMaybe<SeerRouteOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchRouteCapacityNeedArgs = {
  filter?: InputMaybe<SeerRouteCapacityNeedFilter>;
  orderBy?: InputMaybe<SeerRouteCapacityNeedOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchRouteIdsToBackfillArgs = {
  filter?: InputMaybe<SeerRouteIdsToBackfillFilter>;
  orderBy?: InputMaybe<SeerRouteIdsToBackfillOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchRoutePreplanArgs = {
  filter?: InputMaybe<SeerRoutePreplanFilter>;
  orderBy?: InputMaybe<SeerRoutePreplanOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchRouteRateConArgs = {
  filter?: InputMaybe<SeerRouteRateConFilter>;
  orderBy?: InputMaybe<SeerRouteRateConOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchRouteStatusArgs = {
  filter?: InputMaybe<SeerRouteStatusFilter>;
  orderBy?: InputMaybe<SeerRouteStatusOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchRouteStopArgs = {
  filter?: InputMaybe<SeerRouteStopFilter>;
  orderBy?: InputMaybe<SeerRouteStopOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchRouteVendorArgs = {
  filter?: InputMaybe<SeerRouteVendorFilter>;
  orderBy?: InputMaybe<SeerRouteVendorOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchRouteVendorReferenceArgs = {
  filter?: InputMaybe<SeerRouteVendorReferenceFilter>;
  orderBy?: InputMaybe<SeerRouteVendorReferenceOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchRouteVendorStatusArgs = {
  filter?: InputMaybe<SeerRouteVendorStatusFilter>;
  orderBy?: InputMaybe<SeerRouteVendorStatusOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchSchemaversionArgs = {
  filter?: InputMaybe<SeerSchemaversionFilter>;
  orderBy?: InputMaybe<SeerSchemaversionOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchSequencedTrailerRouteArgs = {
  filter?: InputMaybe<SeerSequencedTrailerRouteFilter>;
  orderBy?: InputMaybe<SeerSequencedTrailerRouteOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchStgIncidentsArgs = {
  filter?: InputMaybe<SeerStgIncidentsFilter>;
  orderBy?: InputMaybe<SeerStgIncidentsOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchStopArgs = {
  filter?: InputMaybe<SeerStopFilter>;
  orderBy?: InputMaybe<SeerStopOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchStopEventsAggregationsArgs = {
  filter?: InputMaybe<SeerStopEventsAggregationsFilter>;
  orderBy?: InputMaybe<SeerStopEventsAggregationsOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchTenderArgs = {
  filter?: InputMaybe<SeerTenderFilter>;
  orderBy?: InputMaybe<SeerTenderOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchTenderPlanArgs = {
  filter?: InputMaybe<SeerTenderPlanFilter>;
  orderBy?: InputMaybe<SeerTenderPlanOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchTenderPlanStatusArgs = {
  filter?: InputMaybe<SeerTenderPlanStatusFilter>;
  orderBy?: InputMaybe<SeerTenderPlanStatusOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchTenderStatusArgs = {
  filter?: InputMaybe<SeerTenderStatusFilter>;
  orderBy?: InputMaybe<SeerTenderStatusOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchTrackingAggregationsArgs = {
  filter?: InputMaybe<SeerTrackingAggregationsFilter>;
  orderBy?: InputMaybe<SeerTrackingAggregationsOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchTrackingAggregationsUpdateArgs = {
  filter?: InputMaybe<SeerTrackingAggregationsUpdateFilter>;
  orderBy?: InputMaybe<SeerTrackingAggregationsUpdateOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchTrackingEtasArgs = {
  filter?: InputMaybe<SeerTrackingEtasFilter>;
  orderBy?: InputMaybe<SeerTrackingEtasOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchTrackingUpdateAggregationsArgs = {
  filter?: InputMaybe<SeerTrackingUpdateAggregationsFilter>;
  orderBy?: InputMaybe<SeerTrackingUpdateAggregationsOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchTrackingPageArgs = {
  filter?: InputMaybe<SeerTrackingPageFilter>;
  orderBy?: InputMaybe<SeerTrackingPageOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchTrackingPageV2Args = {
  filter?: InputMaybe<SeerTrackingPageV2Filter>;
  orderBy?: InputMaybe<SeerTrackingPageV2OrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchTrackingStopExecutionArgs = {
  filter?: InputMaybe<SeerTrackingStopExecutionFilter>;
  orderBy?: InputMaybe<SeerTrackingStopExecutionOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchTrailerArgs = {
  filter?: InputMaybe<SeerTrailerFilter>;
  orderBy?: InputMaybe<SeerTrailerOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchTrailerTypeArgs = {
  filter?: InputMaybe<SeerTrailerTypeFilter>;
  orderBy?: InputMaybe<SeerTrailerTypeOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchTrailerRouteAssignmentArgs = {
  filter?: InputMaybe<SeerTrailerRouteAssignmentFilter>;
  orderBy?: InputMaybe<SeerTrailerRouteAssignmentOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchUserArgs = {
  filter?: InputMaybe<SeerUserFilter>;
  orderBy?: InputMaybe<SeerUserOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerSearchVendorRouteFinancialStatusReasonArgs = {
  filter?: InputMaybe<SeerVendorRouteFinancialStatusReasonFilter>;
  orderBy?: InputMaybe<SeerVendorRouteFinancialStatusReasonOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


/** The root type for all read token operations. */
export type QuerySeerCustomerSearchArgs = {
  filter?: InputMaybe<CustomerSearchFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerCustomerSearchCountArgs = {
  filter?: InputMaybe<CustomerSearchFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerDriverRouteListArgs = {
  filter?: InputMaybe<DriverRouteListFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerDriverRouteSubListArgs = {
  filter?: InputMaybe<DriverRouteSubListFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerDriverListArgs = {
  filter?: InputMaybe<DriverListFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryDevSeerFleetManagerDriversArgs = {
  filter: DevFleetManagerDriversFilterInput;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerLoadSearchArgs = {
  filter?: InputMaybe<LoadSearchFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerRerateLoadSearchArgs = {
  filter?: InputMaybe<RerateLoadSearchFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerRerateLoadSearchDownloadArgs = {
  filter?: InputMaybe<RerateLoadSearchFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerRerateLoadSearchCountArgs = {
  filter?: InputMaybe<RerateLoadSearchFilterInput>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageOrderBoardCompleteArgs = {
  filter?: InputMaybe<MainPageOrderBoardCompleteFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageOrderBoardCountsArgs = {
  filter?: InputMaybe<MainPageOrderBoardCountsFilterInput>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageOrderBoardInTransitArgs = {
  filter?: InputMaybe<MainPageOrderBoardInTransitFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageOrderBoardPlanningArgs = {
  filter?: InputMaybe<MainPageOrderBoardPlanningFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageOrderBoardDeliveredArgs = {
  filter?: InputMaybe<MainPageOrderBoardDeliveredFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageRouteBoardIncompleteArgs = {
  filter?: InputMaybe<MainPageRouteBoardIncompleteFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageRouteBoardLinkedRoutesArgs = {
  filter: MainPageRouteBoardLinkedRoutesFilterInput;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageRouteBoardOpenArgs = {
  filter?: InputMaybe<MainPageRouteBoardOpenFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageRouteBoardPreTenderArgs = {
  filter?: InputMaybe<MainPageRouteBoardPreTenderFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageRouteBoardPendingArgs = {
  filter?: InputMaybe<MainPageRouteBoardPendingFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageRouteBoardTenderArgs = {
  filter?: InputMaybe<MainPageRouteBoardTenderFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageRouteBoardArrivedAtDestinationArgs = {
  filter?: InputMaybe<MainPageRouteBoardArrivedAtDestinationFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageRouteBoardArrivedAtOriginArgs = {
  filter?: InputMaybe<MainPageRouteBoardArrivedAtOriginFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageRouteBoardCompletedArgs = {
  filter?: InputMaybe<MainPageRouteBoardCompletedFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageRouteBoardCoveredArgs = {
  filter?: InputMaybe<MainPageRouteBoardCoveredFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageRouteBoardDispatchedArgs = {
  filter?: InputMaybe<MainPageRouteBoardDispatchedFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageRouteBoardInTransitArgs = {
  filter?: InputMaybe<MainPageRouteBoardInTransitFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageTrackingBoardArgs = {
  filter?: InputMaybe<MainPageTrackingBoardFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageTrackingBoardCountArgs = {
  filter?: InputMaybe<MainPageTrackingBoardFilterInput>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageRouteBoardCountsArgs = {
  filter?: InputMaybe<MainPageRouteBoardCountsFilterInput>;
};


/** The root type for all read token operations. */
export type QuerySeerMasterfindLoadSearchArgs = {
  filter?: InputMaybe<MasterfindLoadSearchFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerCarrierSearchArgs = {
  filter?: InputMaybe<CarrierSearchFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerCarrierSearchCountArgs = {
  filter?: InputMaybe<CarrierSearchFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerCarrierRouteArgs = {
  filter?: InputMaybe<CarrierRouteFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerCarrierListArgs = {
  filter?: InputMaybe<CarrierListFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerCustomerOrderListArgs = {
  filter: CustomerOrderListFilterInput;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerCustomerListArgs = {
  filter?: InputMaybe<CustomerListFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerFacilityTrackingArgs = {
  filter?: InputMaybe<FacilityTrackingFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerFacilityRouteArgs = {
  filter?: InputMaybe<FacilityRouteFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerPowerRouteListArgs = {
  filter?: InputMaybe<PowerRouteListFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerPowerRouteSubListArgs = {
  filter?: InputMaybe<PowerRouteSubListFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerTrackingPageArgs = {
  filter?: InputMaybe<TrackingPageFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerTrailerRouteListArgs = {
  filter?: InputMaybe<TrailerRouteListFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerUnvoucheredDriversArgs = {
  filter?: InputMaybe<UnvoucheredDriversFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerVoucheredDriversArgs = {
  filter?: InputMaybe<VoucheredDriversFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerFacilitySearchArgs = {
  filter?: InputMaybe<FacilitySearchFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerFacilitySearchCountArgs = {
  filter?: InputMaybe<FacilitySearchFilterInput>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageOrderBoardAssociatedRoutesArgs = {
  filter?: InputMaybe<MainPageOrderBoardAssociatedRoutesFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerSidebarLoadSearchArgs = {
  filter?: InputMaybe<SidebarLoadSearchFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerAccountingCostdetailArgs = {
  filter?: InputMaybe<AccountingCostdetailFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerAccountingUninvoicedQueueArgs = {
  filter?: InputMaybe<AccountingUninvoicedQueueFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerAccountingUnvoucheredCarrierProcessingQueueArgs = {
  filter?: InputMaybe<AccountingUnvoucheredCarrierProcessingQueueFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerAccountingRateLineDetailsArgs = {
  filter?: InputMaybe<AccountingRateLineDetailsFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerSeerSearchForLinkedRoutesArgs = {
  filter?: InputMaybe<SeerSearchForLinkedRoutesFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerDriverVoucherExtractQueueArgs = {
  filter?: InputMaybe<DriverVoucherExtractQueueFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  orderByColumn?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySeerMainPageSideBarSummaryCountsArgs = {
  filter?: InputMaybe<MainPageSideBarSummaryCountsFilterInput>;
};


/** The root type for all read token operations. */
export type QuerySpotQuotesArgs = {
  input: SpotQuotePaginatedInput;
};


/** The root type for all read token operations. */
export type QuerySpotQuotesPagedArgs = {
  input: SpotQuotesPagedInput;
};


/** The root type for all read token operations. */
export type QueryExistingQuotesArgs = {
  input: ExistingQuotesInput;
};


/** The root type for all read token operations. */
export type QueryMatchingSpotQuoteArgs = {
  input: MatchingSpotQuoteInput;
};


/** The root type for all read token operations. */
export type QueryMatchingSpotQuoteV2Args = {
  input: MatchingSpotQuoteV2Input;
};


/** The root type for all read token operations. */
export type QuerySpotQuotesByIdsArgs = {
  input: SpotQuotesByIdsInput;
};


/** The root type for all read token operations. */
export type QuerySpotQuoteHitRateArgs = {
  input: SpotQuoteHitRateInput;
};


/** The root type for all read token operations. */
export type QuerySpotQuotePricingArgs = {
  input: SpotQuotePricingInput;
};


/** The root type for all read token operations. */
export type QuerySpotQuotesByCodesArgs = {
  input: SpotQuotesByCodesInput;
};


/** The root type for all read token operations. */
export type QueryHistoricalSpotQuotesArgs = {
  input: HistoricalSpotQuotesInput;
};


/** The root type for all read token operations. */
export type QueryStopEventEngineArgs = {
  input: StopEventEngineInput;
};


/** The root type for all read token operations. */
export type QueryStopEventV2Args = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryStopEventsV2Args = {
  filters?: InputMaybe<StopEventsV2Filter>;
  sort?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryStopEventsV2ByRouteStopWithSharedStatusArgs = {
  routeStopID: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryTasksV2Args = {
  filter?: InputMaybe<GetTaskInputV2>;
};


/** The root type for all read token operations. */
export type QueryTaskCountV2Args = {
  filter?: InputMaybe<GetTaskInputV2>;
};


/** The root type for all read token operations. */
export type QueryTasksPaginatedV2Args = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GetTaskInputV2>;
};


/** The root type for all read token operations. */
export type QueryTaskV2Args = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryTimelineEventArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryTimelineEventsArgs = {
  filters?: InputMaybe<TimelineEventFilter>;
};


/** The root type for all read token operations. */
export type QueryTimelineEventV2Args = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryTimelineEventsV2Args = {
  filters?: InputMaybe<TimelineEventFilterV2>;
};


/** The root type for all read token operations. */
export type QueryToursArgs = {
  tourSearchInput: TourSearchInput;
};


/** The root type for all read token operations. */
export type QueryFetchTrackingEtaForStopArgs = {
  nextStopID: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCalculateDriverAssignmentEtaArgs = {
  input: CalculateDriverAssignmentEtaInput;
};


/** The root type for all read token operations. */
export type QueryTrackingUpdatesV4Args = {
  filters?: InputMaybe<TrackingUpdateV4Filter>;
  sortOrder?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryTrackingUpdateByIdv4Args = {
  updateID: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetFleetsByTrailerIdArgs = {
  filters: TrailerFleetFilters;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryGetTrailersByFleetsArgs = {
  queryFilter: TrailerActiveFleetFilter;
  sortingOptions?: InputMaybe<TrailerSortingOptions>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryGetIdentifiersByTrailerIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  trailerSortingOptions?: InputMaybe<TrailerSortingOptions>;
  filters: TrailerPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetEquipmentsByTrailerIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  sortingOptions?: InputMaybe<TrailerSortingOptions>;
  filters: TrailerPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetMaintenanceByTrailerIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
  trailerSortingOptions?: InputMaybe<TrailerSortingOptions>;
  filters: TrailerPaginationFilters;
};


/** The root type for all read token operations. */
export type QueryGetTrailerAvailabilityArgs = {
  filters: TrailerAvailabilityFilters;
};


/** The root type for all read token operations. */
export type QueryGetCustomersByTrailerIdArgs = {
  filters: TrailerCustomerFilters;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryGetasset_TrailersbyidArgs = {
  objId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetassetTrailerSearchArgs = {
  objPool?: InputMaybe<Scalars['String']>;
  objFleet?: InputMaybe<Scalars['String']>;
  objType?: InputMaybe<Scalars['String']>;
  objNumber?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QuerySearchMatchingTrailersArgs = {
  searchMatchingTrailersInput: SearchMatchingTrailersInput;
};


/** The root type for all read token operations. */
export type QuerySearchAssetTrailerArgs = {
  trailerSearch: TrailerSearchInput;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryCheckTrailerNumberArgs = {
  trailerId?: InputMaybe<Scalars['String']>;
  trailerNumber?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetTrailerByCodeArgs = {
  code: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryTrailerAutocompleteArgs = {
  queryFilter: TrailerAutocompleteFilter;
};


/** The root type for all read token operations. */
export type QueryValidateTrailerArgs = {
  request: TrailerValidationArgumentInput;
};


/** The root type for all read token operations. */
export type QueryHelloTrailerValidationArgs = {
  flag: Scalars['Boolean'];
};


/** The root type for all read token operations. */
export type QueryGetContainerByIdArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetDailyContainerCountArgs = {
  carrierId: Scalars['ID'];
  startDatetime: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetContainersForCarrierArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<ContainerFilter>;
  sort?: InputMaybe<ContainerSort>;
};


/** The root type for all read token operations. */
export type QueryGetContainersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<GetContainerFilter>;
  sort?: InputMaybe<ContainerSort>;
};


/** The root type for all read token operations. */
export type QueryGetTruckEntriesForCarrierArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<TruckEntryFilter>;
  sort?: InputMaybe<TruckEntrySort>;
};


/** The root type for all read token operations. */
export type QueryGetDailyTruckCountForCarrierArgs = {
  carrierId: Scalars['ID'];
  startDatetime: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetTruckEntryArgs = {
  id?: InputMaybe<Scalars['ID']>;
  sourceId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetTruckEntryByDriverIdAndReadyDateArgs = {
  input: GetTruckEntryByDriverIdAndReadyDateInput;
};


/** The root type for all read token operations. */
export type QueryGetHigherPriorityDriverCapacitiesArgs = {
  input: GetHigherPriorityDriverCapacitiesInput;
};


/** The root type for all read token operations. */
export type QueryGetTruckEntriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<GetTruckEntriesFilter>;
  sort?: InputMaybe<TruckEntrySort>;
};


/** The root type for all read token operations. */
export type QueryGetDriversAssignedRoutesArgs = {
  input: GetDriversAssignedRoutesInput;
};


/** The root type for all read token operations. */
export type QueryGetTruckEntryTemplateByDriverIdAndDaysArgs = {
  input: GetTruckEntryTemplateByDriverIdAndDaysInput;
};


/** The root type for all read token operations. */
export type QueryGetTruckEntryTemplatesForCarrierArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<TruckEntryTemplateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryGetTruckEntryTemplateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  sourceId?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetGateReservationByIdArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetDailyGateReservationCountArgs = {
  carrierId: Scalars['ID'];
  startDatetime: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetGateReservationsForCarrierArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<GateReservationFilter>;
  sort?: InputMaybe<GateReservationSort>;
};


/** The root type for all read token operations. */
export type QueryGetPatternV3Args = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetPatternsByLaneV2Args = {
  laneId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetPatternsByOrderV1Args = {
  orderId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryGetPatternOrderApplicationArgs = {
  patternOrderApplicationId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryIsEligibleForAutoApplyArgs = {
  orderId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryAslSearchRoutesArgs = {
  orderBy?: InputMaybe<AslGenericRouteViewOrderBy>;
  filters?: InputMaybe<AslRouteViewInput>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryAslSearchTrackingUpdatesArgs = {
  orderBy?: InputMaybe<ReadonlyArray<AslGenericTrackingUpdatesViewOrderBy>>;
  filters?: InputMaybe<ReadonlyArray<AslGenericTrackingUpdatesViewFilter>>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


/** The root type for all read token operations. */
export type QueryCanvasConfigurationArgs = {
  searchCanvasConfigurationInput: SearchCanvasConfigurationInput;
};


/** The root type for all read token operations. */
export type QuerySearchCanvasConfigurationsByKeysArgs = {
  searchKeys: SearchCanvasConfigurationsByKeysInput;
};


/** The root type for all read token operations. */
export type QueryCanvasConfigurationByUuidArgs = {
  uuid: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QuerySearchCanvasConfigurationAuditHistoryByUuidArgs = {
  uuid: Scalars['ID'];
  paginationQuery?: InputMaybe<PaginationQuery>;
};


/** The root type for all read token operations. */
export type QueryValidationConfigurationsArgs = {
  paginationQuery?: InputMaybe<PaginationQuery>;
};


/** The root type for all read token operations. */
export type QueryValidationConfigurationArgs = {
  validationConfigurationKeysInput: ValidationConfigurationKeysInput;
  fallbackToGlobalDefault?: InputMaybe<Scalars['Boolean']>;
  fallBacktoTenantConfig?: InputMaybe<Scalars['Boolean']>;
  paginationQuery?: InputMaybe<PaginationQuery>;
};


/** The root type for all read token operations. */
export type QueryValidationConfigurationByUuidArgs = {
  uuid: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QuerySearchValidationConfigurationsArgs = {
  validationConfigurationKeysInput: ValidationConfigurationKeysInput;
  paginationQuery?: InputMaybe<PaginationQuery>;
};


/** The root type for all read token operations. */
export type QuerySearchValidationAuditHistoryArgs = {
  validationConfigurationKeysInput: ValidationConfigurationKeysInput;
  paginationQuery?: InputMaybe<PaginationQuery>;
};


/** The root type for all read token operations. */
export type QuerySearchValidationAuditHistoryByHashIdArgs = {
  hashId: Scalars['String'];
  paginationQuery?: InputMaybe<PaginationQuery>;
};


/** The root type for all read token operations. */
export type QuerySearchValidationAuditHistoryByUuidArgs = {
  uuid: Scalars['ID'];
  paginationQuery?: InputMaybe<PaginationQuery>;
};


/** The root type for all read token operations. */
export type QueryCsaGetProviderDetailsArgs = {
  providerId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryProvidersArgs = {
  groupBy?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryProviderArgs = {
  providerId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetAllProviderCredentialHistoryDataArgs = {
  toDate: Scalars['String'];
  fromDate: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetHistorybyProviderIdArgs = {
  providerId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryEdiTransactionsArgs = {
  filter: EdiTransactionFilter;
};


/** The root type for all read token operations. */
export type QueryEdiMessagePreviewArgs = {
  filter: EdiPreviewFilter;
};


/** The root type for all read token operations. */
export type QueryEdiTransactionsByShipmentIdArgs = {
  filter: EdiTransactionByShipmentIdFilter;
};


/** The root type for all read token operations. */
export type QueryGetEdiTransactionsArgs = {
  filter: GetEdiTransactionFilter;
};


/** The root type for all read token operations. */
export type QueryEdiTenderByShipmentIdAndScacArgs = {
  filter: EdiTenderByShipmentIdAndScacFilter;
};


/** The root type for all read token operations. */
export type QueryEdiTenderWithTransactionByShipmentIdAndScacArgs = {
  filter: EdiTenderWithTransactionByShipmentIdAndScacFilter;
};


/** The root type for all read token operations. */
export type QueryEdiOutboxMessagesForRetryArgs = {
  filter: EdiOutboxMessagesForRetryFilter;
};


/** The root type for all read token operations. */
export type QueryEdiTransactionsByIdArgs = {
  filter: EdiTransactionByIdFilter;
};


/** The root type for all read token operations. */
export type QueryGetCustomerTrackingUpdatesConfigurationArgs = {
  filter: CustomerTrackingUpdatesConfigurationFilter;
};


/** The root type for all read token operations. */
export type QueryGetCarrierEdiTransactionsByRouteCodeArgs = {
  filter: CarrierEdiTransactionsByRouteCodeFilter;
};


/** The root type for all read token operations. */
export type QueryEdiOutboxMessageByIdArgs = {
  filter: EdiOutboxMessageByIdFilter;
};


/** The root type for all read token operations. */
export type QueryGetEdiOutboxMessageByHashedEventArgs = {
  filter: EdiOutboxMessageByHashedEventFilter;
};


/** The root type for all read token operations. */
export type QueryAllEdiManagementTransactionsArgs = {
  filter?: InputMaybe<EdiManagementTransactionFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
};


/** The root type for all read token operations. */
export type QueryGetInbound204IsaReceiverIdArgs = {
  filter?: InputMaybe<IsaIdReceiverFilterInput>;
};


/** The root type for all read token operations. */
export type QueryCustomerOrderArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCustomerOrderByCodeArgs = {
  code: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryCustomerOrderByNumberArgs = {
  customerOrderNumber: Scalars['String'];
  customerId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryOrdersArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  filter?: InputMaybe<CustomerOrdersFilter>;
};


/** The root type for all read token operations. */
export type QueryValidateCustomerOrderNumberUpdateArgs = {
  orderId: Scalars['ID'];
  customerOrderNumber: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetOrderStopCommoditiesArgs = {
  orderId: Scalars['ID'];
  orderStopId: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryOrderStopArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryLoadV2Args = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryLoadByCodeArgs = {
  code: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryLoadCalculatePayableDistanceArgs = {
  loadCalculatePayableDistanceInput: LoadCalculatePayableDistanceInput;
};


/** The root type for all read token operations. */
export type QueryRouteV2Args = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryRouteByCodeArgs = {
  code: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryRoutesV2Args = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  filter?: InputMaybe<LoadRoutesFilter>;
};


/** The root type for all read token operations. */
export type QueryRouteStopArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryStopsV2Args = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  filter?: InputMaybe<StopsFilter>;
};


/** The root type for all read token operations. */
export type QueryRouteStopV2Args = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryStopsV3Args = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  filter?: InputMaybe<StopsFilter>;
};


/** The root type for all read token operations. */
export type QueryCrossLoadStopArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryOrdersRequiredSchedulingArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  filter?: InputMaybe<OrdersRequiredSchedulingFilter>;
};


/** The root type for all read token operations. */
export type QueryOrdersRequiredSchedulingCountArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  filter?: InputMaybe<OrdersRequiredSchedulingCommonFilter>;
};


/** The root type for all read token operations. */
export type QueryOrderProfilesArgs = {
  customerId: Scalars['String'];
  conditions?: InputMaybe<LoadDefaultConditionsInput>;
};


/** The root type for all read token operations. */
export type QueryOrderProfileArgs = {
  id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryValidateOrderProfileArgs = {
  validateOrderProfileInput: ValidateOrderProfileInput;
};


/** The root type for all read token operations. */
export type QueryOrderProfilesV2Args = {
  customerId: Scalars['String'];
  conditions?: InputMaybe<LoadDefaultConditionsInput>;
};


/** The root type for all read token operations. */
export type QueryOrderProfileV2Args = {
  id: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryRepeatLoadByRequestIdArgs = {
  requestId: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryMmaiGetDocsChatResponseV1Args = {
  request: MMaiDocsChatResponseInputV1;
};


/** The root type for all read token operations. */
export type QueryMmaiDocsSearchArgs = {
  request: MMaiDocsSearchInput;
};


/** The root type for all read token operations. */
export type QueryMmaiGetSeerLoadSearchVarsV1Args = {
  input: MMaiGetSeerLoadSearchVarsRequestV1;
};


/** The root type for all read token operations. */
export type QueryMmaiParseCapacityEmailV1Args = {
  input: MMaiParseCapacityEmailInputV1;
};


/** The root type for all read token operations. */
export type QueryDataDictionaryTypeArgs = {
  id: Scalars['ID'];
};


/** The root type for all read token operations. */
export type QueryDictionaryEntityConfigArgs = {
  dictionaryEntityConfigInput: DictionaryEntityConfigInput;
  fallbackToTenantConfig?: InputMaybe<Scalars['Boolean']>;
};


/** The root type for all read token operations. */
export type QueryDictionaryEntityConfigAuditArgs = {
  dictionaryEntityConfigInput: DictionaryEntityConfigInput;
};


/** The root type for all read token operations. */
export type QueryDictionaryEntityConfigOptionArgs = {
  dictionaryEntityConfigOptionInput: DictionaryEntityConfigOptionInput;
};


/** The root type for all read token operations. */
export type QueryDictionaryEntityConfigsArgs = {
  filters?: InputMaybe<FilterInputs>;
};


/** The root type for all read token operations. */
export type QueryDictionaryTermsByIdsArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


/** The root type for all read token operations. */
export type QueryDictionaryTermsHistoryArgs = {
  historyFilterInputs?: InputMaybe<HistoryFilterInputs>;
  pageOffset?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
};


/** The root type for all read token operations. */
export type QueryGetPowerBiReportDetailsArgs = {
  workspaceId?: InputMaybe<Scalars['ID']>;
  reportId?: InputMaybe<Scalars['ID']>;
  empRole: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryGetPowerBiReportDetailsV2Args = {
  workspaceId?: InputMaybe<Scalars['ID']>;
  reportId?: InputMaybe<Scalars['ID']>;
  dashboardName: Scalars['String'];
};


/** The root type for all read token operations. */
export type QueryValidateMembershipArgs = {
  args: ValidateMembershipInput;
};


/** The root type for all read token operations. */
export type QueryOrganizationArgs = {
  args: SearchOrganizationFilter;
};


/** The root type for all read token operations. */
export type QueryOrganizationsArgs = {
  args?: InputMaybe<SearchOrganizationFilter>;
};


/** The root type for all read token operations. */
export type QueryMembershipsArgs = {
  args?: InputMaybe<SearchMembershipsFilter>;
};


/** The root type for all read token operations. */
export type QueryGetMobileRolesArgs = {
  args: GetMobileRolesFilter;
};


/** The root type for all read token operations. */
export type QueryGetMobileScopesArgs = {
  args?: InputMaybe<GetMobileScopesFilter>;
};


/** The root type for all read token operations. */
export type QueryGetMobileUsersArgs = {
  args?: InputMaybe<GetMobileUsersFilter>;
};


/** The root type for all read token operations. */
export type QueryGetMobileRolesV2Args = {
  args: GetMobileRolesFilter;
};


/** The root type for all read token operations. */
export type QueryGetInvitationStatusArgs = {
  invitationStatusInput: InvitationStatusInput;
};


/** The root type for all read token operations. */
export type QueryGetAuditEventsArgs = {
  auditLogsInput: AuditLogsInput;
};

export type QueryOptions = {
  readonly after?: InputMaybe<Scalars['String']>;
  readonly before?: InputMaybe<Scalars['String']>;
  readonly first?: InputMaybe<Scalars['Int']>;
  readonly last?: InputMaybe<Scalars['Int']>;
  readonly cursor?: InputMaybe<Scalars['String']>;
};

export type QuoteCarrier = {
  readonly __typename?: 'QuoteCarrier';
  readonly carrier?: Maybe<CarrierV2>;
  readonly costLineItems?: Maybe<ReadonlyArray<SpotQuoteCostLineItem>>;
  /** Populated when returning a matched IMDL or LTL SpotQuote, otherwise null */
  readonly route?: Maybe<LoadRouteV2>;
  readonly interlineStatus?: Maybe<Scalars['String']>;
  readonly transitDays?: Maybe<Scalars['Int']>;
  readonly pickupDate?: Maybe<Scalars['DateTime']>;
  readonly deliveryDate?: Maybe<Scalars['DateTime']>;
  readonly externalCarrierQuoteId?: Maybe<Scalars['String']>;
  readonly carrierMarkedDoNotUse: Scalars['Boolean'];
  readonly interlineStatusDoesNotMatch: Scalars['Boolean'];
  readonly errors?: Maybe<ReadonlyArray<SpotQuoteError>>;
  readonly baseCost: Scalars['Decimal'];
  readonly fuel: Scalars['Decimal'];
  readonly extras: Scalars['Decimal'];
  readonly totalCost: Scalars['Decimal'];
  readonly sequence: Scalars['Int'];
};

export type QuoteGroup = {
  readonly __typename?: 'QuoteGroup';
  readonly id: Scalars['ID'];
};

export type QuoteInput = {
  readonly createdById: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly destinationGeographySource?: InputMaybe<Scalars['String']>;
  readonly destinationGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly destinationText: Scalars['String'];
  readonly distance: UnitOfDistanceInput;
  /** use effectiveDateTime for timestamp */
  readonly effectiveDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly effectiveDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** use expirationDateTime for timestamp */
  readonly expirationDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly expirationDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly lineItems: ReadonlyArray<QuoteLineItemInput>;
  readonly loadSizeId: Scalars['ID'];
  readonly minHeight?: InputMaybe<UnitOfDistanceInput>;
  readonly minLength?: InputMaybe<UnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<UnitOfDistanceInput>;
  readonly multipleStops?: InputMaybe<Scalars['Boolean']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly originGeographySource?: InputMaybe<Scalars['String']>;
  readonly originGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly originText: Scalars['String'];
  readonly quoteGroupId?: InputMaybe<Scalars['ID']>;
  readonly quotePriceTierId?: InputMaybe<Scalars['ID']>;
  readonly quoteTypeId: Scalars['ID'];
  readonly quotedById: Scalars['ID'];
  readonly quotedToId?: InputMaybe<Scalars['ID']>;
  readonly routingType: Scalars['String'];
  readonly scac?: InputMaybe<Scalars['String']>;
  readonly scacId?: InputMaybe<Scalars['ID']>;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly transportModeId: Scalars['ID'];
};

export type QuoteLineItem = {
  readonly __typename?: 'QuoteLineItem';
  readonly chargeTypeId: Scalars['ID'];
  readonly currency: Scalars['String'];
  readonly exchangeRate: Scalars['Float'];
  readonly homeCurrency: Scalars['String'];
  readonly homeRate: Scalars['Float'];
  readonly id: Scalars['ID'];
  readonly quote: CustomerQuote;
  readonly quoteId: Scalars['ID'];
  readonly rate: Scalars['Float'];
  readonly totalRate: Scalars['Float'];
  readonly units: Scalars['Float'];
};

export type QuoteLineItemInput = {
  readonly chargeTypeId: Scalars['ID'];
  readonly currency: Scalars['String'];
  readonly exchangeRate: Scalars['Float'];
  readonly homeCurrency: Scalars['String'];
  readonly homeRate: Scalars['Float'];
  readonly rate: Scalars['Float'];
  readonly totalRate: Scalars['Float'];
  readonly units: Scalars['Float'];
};

/** Holds Quote Pricing Details. */
export type QuotePricingDetailsConnection = {
  readonly __typename?: 'QuotePricingDetailsConnection';
  readonly displayDetails?: Maybe<ReadonlyArray<Maybe<QuotePricingDetailsDisplayDetails>>>;
  readonly error?: Maybe<Scalars['String']>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<PricingErrorType>>>;
  readonly message?: Maybe<Scalars['String']>;
  readonly path?: Maybe<Scalars['String']>;
  readonly quoteCode?: Maybe<Scalars['String']>;
  readonly quoteCorrelationId?: Maybe<Scalars['String']>;
  readonly quoteId?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly statusCode?: Maybe<Scalars['String']>;
  readonly timestamp?: Maybe<Scalars['Int']>;
};

/** Holds Quote Pricing Details Display Details Data Info. */
export type QuotePricingDetailsDisplayDetails = {
  readonly __typename?: 'QuotePricingDetailsDisplayDetails';
  readonly displayDescription?: Maybe<Scalars['String']>;
  readonly displayOrder?: Maybe<Scalars['Int']>;
  readonly displayValue?: Maybe<Scalars['String']>;
};

export type QuotesPaginatedFilter = {
  /** Search/filter by Customer ID */
  readonly customerId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Destination Deadhead Radius */
  readonly destinationGeographySearch?: InputMaybe<GeographyRadiusSearchInput>;
  /** Search/filter by Destination Text */
  readonly destinationText?: InputMaybe<Scalars['String']>;
  /** Exclude Expired Quotes in Results */
  readonly excludeExpired?: InputMaybe<Scalars['Boolean']>;
  /** Search/filter by Load Size */
  readonly loadSizeId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Minimum Height */
  readonly minHeight?: InputMaybe<UnitOfDistanceInput>;
  /** Search/filter by Minimum Length */
  readonly minLength?: InputMaybe<UnitOfDistanceInput>;
  /** Search/filter by Minimum Width */
  readonly minWidth?: InputMaybe<UnitOfDistanceInput>;
  /** Search/filter by Origin Deadhead Radius */
  readonly originGeographySearch?: InputMaybe<GeographyRadiusSearchInput>;
  /** Search/filter by Origin Text */
  readonly originText?: InputMaybe<Scalars['String']>;
  /** Search/filter By Quote Group ID */
  readonly quoteGroupId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Quote Price Tier */
  readonly quotePriceTierId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Quote Type */
  readonly quoteTypeId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by SCAC */
  readonly scacId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Team */
  readonly team?: InputMaybe<Scalars['Boolean']>;
  /** Search/filter by Trailer Types */
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/filter by Transportation Mode */
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
};

export type QuotesQueryInput = {
  /** Search/filter by Customer ID */
  readonly customerId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Destination Deadhead Radius */
  readonly destinationGeographySearch?: InputMaybe<GeographyRadiusSearchInput>;
  /** Search/filter by Destination Text */
  readonly destinationText?: InputMaybe<Scalars['String']>;
  /** Exclude Expired Quotes in Results */
  readonly excludeExpired?: InputMaybe<Scalars['Boolean']>;
  /** Search/filter by Load Size */
  readonly loadSizeId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Minimum Height */
  readonly minHeight?: InputMaybe<UnitOfDistanceInput>;
  /** Search/filter by Minimum Length */
  readonly minLength?: InputMaybe<UnitOfDistanceInput>;
  /** Search/filter by Minimum Width */
  readonly minWidth?: InputMaybe<UnitOfDistanceInput>;
  /** Search/filter by Origin Deadhead Radius */
  readonly originGeographySearch?: InputMaybe<GeographyRadiusSearchInput>;
  /** Search/filter by Origin Text */
  readonly originText?: InputMaybe<Scalars['String']>;
  /** Search/filter By Quote Group ID */
  readonly quoteGroupId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Quote Price Tier */
  readonly quotePriceTierId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Quote Type */
  readonly quoteTypeId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by SCAC */
  readonly scacId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Team */
  readonly team?: InputMaybe<Scalars['Boolean']>;
  /** Search/filter by Trailer Types */
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/filter by Transportation Mode */
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
};

export type QuoteToLoadInput = {
  readonly quoteId: Scalars['ID'];
};

export type QuotingFuelSurcharge = {
  readonly __typename?: 'QuotingFuelSurcharge';
  readonly programType?: Maybe<Scalars['String']>;
  readonly fuelScale?: Maybe<Scalars['String']>;
  readonly fuelSurcharge?: Maybe<Scalars['Decimal']>;
};

/** DEPRECATED - `QuotingFuelSurchargeInput` is no longer used - due to changed of pickupDate/deliveryDate/tenderDate datatype from DateTime to Date. */
export type QuotingFuelSurchargeInput = {
  readonly customerId: Scalars['ID'];
  readonly mode: Scalars['String'];
  readonly size: Scalars['String'];
  readonly equipment?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly countryCode?: InputMaybe<Scalars['String']>;
  readonly region?: InputMaybe<Scalars['String']>;
  readonly pickupDate?: InputMaybe<Scalars['DateTime']>;
  readonly deliveryDate?: InputMaybe<Scalars['DateTime']>;
  readonly tenderDate?: InputMaybe<Scalars['DateTime']>;
};

export type QuotingFuelSurchargeInputV2 = {
  readonly customerId: Scalars['ID'];
  readonly mode: Scalars['String'];
  readonly size: Scalars['String'];
  readonly equipment?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly division?: InputMaybe<Scalars['String']>;
  /** For Regional Relationship Type Implementation, this CountryCode field is considering as Outbound Country. */
  readonly countryCode?: InputMaybe<Scalars['String']>;
  /** For Regional Relationship Type Implementation, this Region field is considering as Outbound Region. */
  readonly region?: InputMaybe<Scalars['String']>;
  readonly pickupDate?: InputMaybe<Scalars['DateOnly']>;
  readonly deliveryDate?: InputMaybe<Scalars['DateOnly']>;
  readonly tenderDate?: InputMaybe<Scalars['DateOnly']>;
  readonly inboundCountryCode?: InputMaybe<Scalars['String']>;
  readonly inboundRegion?: InputMaybe<Scalars['String']>;
  readonly regionalRelationshipType: Scalars['String'];
};

export type RadiusFilter = {
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
  readonly unitOfMeasurement: Scalars['String'];
  readonly radius: Scalars['Float'];
};

export type RadiusFilterOptional = {
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
  readonly unitOfMeasurement?: InputMaybe<Scalars['String']>;
  readonly radius?: InputMaybe<Scalars['Float']>;
};

export type RailBilling = LoadTender & {
  readonly __typename?: 'RailBilling';
  readonly id: Scalars['ID'];
  readonly options: LoadTenderOptions;
  readonly routeVendor: RouteVendorV2;
  readonly sentBy: UserV2;
  readonly sentOn: Scalars['DateTime'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly document: Document;
  readonly documentV2?: Maybe<Document>;
  readonly notification: LoadTenderNotification;
  readonly paymentTerms: Scalars['String'];
  readonly serviceCode: Scalars['String'];
  readonly routingCode: Scalars['String'];
  /** @deprecated Use priceAuthorityV2 as string instead. */
  readonly priceAuthority: RailBillingPriceAuthority;
  readonly priceAuthorityV2: Scalars['String'];
  readonly notifyParty: RailBillingAccount;
  readonly shipper: RailBillingAccount;
  readonly consignee: RailBillingAccount;
  readonly partyToReceive: RailBillingAccount;
  readonly externalWayBill: Scalars['Boolean'];
  readonly status: LoadTenderStatus;
  readonly statusMessage?: Maybe<Scalars['String']>;
};

export type RailBillingAccount = {
  readonly __typename?: 'RailBillingAccount';
  readonly name: Scalars['String'];
  readonly address: RailBillingAccountAddress;
  readonly faxNumber: Scalars['String'];
};

export type RailBillingAccountAddress = {
  readonly __typename?: 'RailBillingAccountAddress';
  readonly street1: Scalars['String'];
  readonly street2?: Maybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly stateCode: Scalars['String'];
  readonly countryCode: Scalars['String'];
  readonly zipCode: Scalars['String'];
};

export type RailBillingAccountAddressInput = {
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly zipCode: Scalars['String'];
  readonly country?: InputMaybe<Scalars['String']>;
};

export type RailBillingAccountInput = {
  readonly name: Scalars['String'];
  readonly address: RailBillingAccountAddressInput;
  readonly faxNumber: Scalars['String'];
};

export type RailBillingPriceAuthority = {
  readonly __typename?: 'RailBillingPriceAuthority';
  readonly prefix: Scalars['String'];
  readonly number: Scalars['Int'];
};

export type RailBillingPriceAuthorityInput = {
  readonly prefix: Scalars['String'];
  readonly number: Scalars['Int'];
};

/** Holds Ranking Factor Info. */
export type RankingFactor = {
  readonly __typename?: 'RankingFactor';
  readonly rankingFactorCode?: Maybe<Scalars['String']>;
  readonly rankingFactorDescription?: Maybe<Scalars['String']>;
};

export type RankingFactors = {
  readonly __typename?: 'RankingFactors';
  readonly rankingFactorCode: Scalars['String'];
  readonly rankingFactorDescription: Scalars['String'];
};

export type RateConContacts = {
  readonly __typename?: 'RateConContacts';
  readonly id: Scalars['ID'];
  readonly contactType?: Maybe<ContactTypeEnum>;
  readonly email?: Maybe<Scalars['String']>;
  readonly contactName?: Maybe<Scalars['String']>;
  readonly contactPhone?: Maybe<Scalars['String']>;
  readonly rateConfirmationId: Scalars['ID'];
  readonly rateConfirmation?: Maybe<RateConfirmation>;
};

export type RateConfirmation = {
  readonly __typename?: 'RateConfirmation';
  readonly id: Scalars['ID'];
  readonly sentByUserId: Scalars['ID'];
  readonly document?: Maybe<Document>;
  /** @deprecated Will be nullable after migrating to DocGen V2. */
  readonly documentId: Scalars['ID'];
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly routeVendorId?: Maybe<Scalars['ID']>;
  readonly notificationId?: Maybe<Scalars['ID']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly sendToEmail: Scalars['String'];
  readonly hideCostDetails: Scalars['Boolean'];
  readonly hideReference?: Maybe<Scalars['Boolean']>;
  readonly hideAppointmentTimes: Scalars['Boolean'];
  readonly hideStopDetails?: Maybe<Scalars['Boolean']>;
  readonly completed?: Maybe<Scalars['Boolean']>;
  readonly sentDate: Scalars['DateTime'];
  readonly contactName?: Maybe<Scalars['String']>;
  readonly contactPhone?: Maybe<Scalars['String']>;
  readonly contactMethod?: Maybe<Scalars['String']>;
  /** @deprecated Unused. Use contactType on rateConContacts. */
  readonly ccEmails?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly sentByUser: User;
  readonly sentByUserV2: UserV2;
  readonly taStopPending?: Maybe<Scalars['Boolean']>;
  readonly rateConContacts?: Maybe<ReadonlyArray<Maybe<RateConContacts>>>;
  readonly status: LoadTenderStatus;
  readonly statusMessage?: Maybe<Scalars['String']>;
};

export type RateConfirmationInput = {
  /** Internal to #team-proton, only be populated by Automated Rate Con logic */
  readonly automated?: InputMaybe<Scalars['Boolean']>;
  readonly sentByUserId: Scalars['ID'];
  readonly sendToEmail: Scalars['String'];
  readonly ccEmails?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly routeVendorId: Scalars['ID'];
  readonly vendorContactId: Scalars['ID'];
  readonly hideCostDetails: Scalars['Boolean'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly contactName?: InputMaybe<Scalars['String']>;
  readonly contactPhone?: InputMaybe<Scalars['String']>;
  readonly contactMethod?: InputMaybe<Scalars['String']>;
  readonly hideReference?: InputMaybe<Scalars['Boolean']>;
  readonly completed?: InputMaybe<Scalars['Boolean']>;
  readonly taStopPending?: InputMaybe<Scalars['Boolean']>;
  readonly hideStopDetails?: InputMaybe<Scalars['Boolean']>;
  readonly hideAppointmentTimes?: InputMaybe<Scalars['Boolean']>;
  readonly rateConCcEmails?: InputMaybe<ReadonlyArray<InputMaybe<ContactDetail>>>;
  readonly rateConBccEmails?: InputMaybe<ReadonlyArray<InputMaybe<ContactDetail>>>;
};

/** Input for creating a Rate Confirmation in Booking Orchestration */
export type RateConfirmationRecordInput = {
  /**
   * Additional emails to include with the Rate Confirmation.
   * This can serve as a backup if a preferred *Rate Confirmation Contact* and a *Main Carrier Contact* are not found.
   */
  readonly toCC?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  /** Notes to include on the Rate Confirmation */
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type RateConfirmationsFilter = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly sentByUserId?: InputMaybe<Scalars['ID']>;
  readonly sendToEmail?: InputMaybe<Scalars['String']>;
  readonly ccEmails?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeId?: InputMaybe<Scalars['String']>;
  readonly routeVendorId?: InputMaybe<Scalars['ID']>;
  readonly vendorContactId?: InputMaybe<Scalars['ID']>;
  readonly documentId?: InputMaybe<Scalars['ID']>;
  readonly hideCostDetails?: InputMaybe<Scalars['Boolean']>;
  readonly sentDate?: InputMaybe<Scalars['DateTime']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly contactName?: InputMaybe<Scalars['String']>;
  readonly contactPhone?: InputMaybe<Scalars['String']>;
  readonly taStopPending?: InputMaybe<Scalars['Boolean']>;
  /** By default, only Rate Confirmation types are included.  Set to true to include Dray Notification and Rail Billing types */
  readonly includeAllLoadTenderTypes?: InputMaybe<Scalars['Boolean']>;
};

export type RateConfirmationV2 = LoadTender & {
  readonly __typename?: 'RateConfirmationV2';
  readonly id: Scalars['ID'];
  readonly options: LoadTenderOptions;
  readonly routeVendor: RouteVendorV2;
  readonly sentBy: UserV2;
  readonly sentOn: Scalars['DateTime'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly document: Document;
  readonly documentV2?: Maybe<Document>;
  readonly notification: LoadTenderNotification;
  readonly status: LoadTenderStatus;
  readonly statusMessage?: Maybe<Scalars['String']>;
};

/** The Rate Detail */
export type RateDetail = {
  readonly __typename?: 'RateDetail';
  readonly id?: Maybe<Scalars['ID']>;
  readonly description: Scalars['String'];
  readonly customerId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly code?: Maybe<Scalars['String']>;
  readonly unitsDecimal?: Maybe<Scalars['Decimal']>;
  readonly ratePer?: Maybe<Scalars['Decimal']>;
  readonly chargeTypeId?: Maybe<Scalars['ID']>;
  /** @deprecated This should not be used anymore as we are transitioning to Charge Type Service. We will be adding a new federated type soon. */
  readonly chargeType?: Maybe<ChargeType>;
  readonly totalRate?: Maybe<Scalars['Decimal']>;
  readonly statusId: StatusEnum;
  readonly enteredRatePer?: Maybe<Scalars['Decimal']>;
  readonly enteredTotalRate?: Maybe<Scalars['Decimal']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: Maybe<Scalars['Date']>;
  readonly currencyDiscountAmount?: Maybe<Scalars['Decimal']>;
  readonly jurisdictionTaxesId?: Maybe<Scalars['ID']>;
  readonly glAccountCode?: Maybe<Scalars['String']>;
  readonly allowRerating?: Maybe<Scalars['Boolean']>;
  readonly segment1?: Maybe<Scalars['String']>;
  readonly segment2?: Maybe<Scalars['String']>;
  readonly segment3?: Maybe<Scalars['String']>;
  readonly segment4?: Maybe<Scalars['String']>;
  readonly segment5?: Maybe<Scalars['String']>;
  readonly segment6?: Maybe<Scalars['String']>;
  readonly segment7?: Maybe<Scalars['String']>;
  readonly segment8?: Maybe<Scalars['String']>;
  readonly segment9?: Maybe<Scalars['String']>;
  readonly segment10?: Maybe<Scalars['String']>;
  readonly jurisdictionTax?: Maybe<JurisdictionTax>;
  readonly invoiceHeader?: Maybe<InvoiceHeader>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly load?: Maybe<Load>;
  readonly loadV2?: Maybe<LoadV2>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdById?: Maybe<Scalars['String']>;
  readonly updatedById?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly stopId?: Maybe<Scalars['ID']>;
  readonly billToCodeId?: Maybe<Scalars['ID']>;
  readonly billToCodeCode?: Maybe<Scalars['String']>;
  readonly statementNumber?: Maybe<Scalars['String']>;
  readonly billToCode?: Maybe<BillToCode>;
  readonly approvalStatus?: Maybe<Scalars['String']>;
  readonly approvedBy?: Maybe<Scalars['String']>;
  readonly approvedOn?: Maybe<Scalars['DateTime']>;
};

/** The input type for new Rate Detail. */
export type RateDetailAddInput = {
  readonly customerId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly unitsDecimal?: InputMaybe<Scalars['Decimal']>;
  /** Deprecated - Use EnteredRatePer - This will be recalculated as ExchangeRate * EnteredRatePer */
  readonly ratePer?: InputMaybe<Scalars['Decimal']>;
  readonly chargeTypeId?: InputMaybe<Scalars['ID']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly enteredRatePer?: InputMaybe<Scalars['Decimal']>;
  readonly currency?: InputMaybe<Scalars['String']>;
  /** Deprecated - Do not use, this will be ignored and it will get set automatically from Currency */
  readonly exchangeRate?: InputMaybe<Scalars['Decimal']>;
  /** Deprecated - Do not use, this will be ignored and it will get set automatically from Load Created Date */
  readonly exchangeRateDate?: InputMaybe<Scalars['Date']>;
  readonly jurisdictionTaxesId?: InputMaybe<Scalars['ID']>;
  readonly stopId?: InputMaybe<Scalars['ID']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly billToCodeId?: InputMaybe<Scalars['ID']>;
  readonly approvalStatus?: InputMaybe<Scalars['String']>;
  readonly approvedBy?: InputMaybe<Scalars['String']>;
  readonly approvedOn?: InputMaybe<Scalars['DateTime']>;
  readonly isUserInitiated?: InputMaybe<Scalars['Boolean']>;
};

export type RateDetailChargeTypeConfigurationResponse = {
  readonly __typename?: 'RateDetailChargeTypeConfigurationResponse';
  readonly billToId?: Maybe<Scalars['Guid']>;
  readonly carrierId?: Maybe<Scalars['Guid']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['Guid']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly invoiceGroupingCode?: Maybe<Scalars['String']>;
  readonly invoiceMethodId?: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly isCredit: Scalars['Boolean'];
  readonly isDefault: Scalars['Boolean'];
  readonly maximumTotalRate?: Maybe<Scalars['Decimal']>;
  readonly minimumTotalRate?: Maybe<Scalars['Decimal']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly perUnit?: Maybe<CtcPerUnitTypeEnum>;
  readonly rate?: Maybe<Scalars['Decimal']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly passThroughType?: Maybe<Scalars['String']>;
  readonly passThroughAmount?: Maybe<Scalars['Decimal']>;
  readonly customerApprovalRequired: Scalars['Boolean'];
  readonly isPreTax?: Maybe<Scalars['Boolean']>;
  readonly percentOfChargeTypeIdsList?: Maybe<ReadonlyArray<Maybe<Scalars['Guid']>>>;
  readonly isTieredRatePerConfiguration?: Maybe<Scalars['Boolean']>;
  readonly rateDetailTieredSchedules?: Maybe<ReadonlyArray<RateDetailChargeTypeConfigurationTieredSchedule>>;
};

export type RateDetailChargeTypeConfigurationTieredSchedule = {
  readonly __typename?: 'RateDetailChargeTypeConfigurationTieredSchedule';
  readonly id?: Maybe<Scalars['ID']>;
  readonly beginRange?: Maybe<Scalars['Decimal']>;
  readonly endRange?: Maybe<Scalars['Decimal']>;
  readonly ratePer?: Maybe<Scalars['Decimal']>;
};

/** Represents the rate detail object when processing a invoice. */
export type RateDetailProcessingInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
};

/** Holds Rate Details Edge Data Info. */
export type RateDetailsEdgeV2 = {
  readonly __typename?: 'RateDetailsEdgeV2';
  readonly node?: Maybe<RateDetailsV2>;
};

export type RateDetailsFilter = {
  readonly loadId: Scalars['ID'];
};

/** Holds Rate Detail Node Data Info. */
export type RateDetailsV2 = {
  readonly __typename?: 'RateDetailsV2';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly chargeType?: Maybe<Scalars['String']>;
  readonly chargeTypeDescription?: Maybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Uuid'];
  readonly commodityClass?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly rate?: Maybe<Scalars['Float']>;
  readonly rateQualifier?: Maybe<Scalars['String']>;
  readonly unit?: Maybe<Scalars['Float']>;
};

/** The input type for updating Rate Detail. */
export type RateDetailUpdateInput = {
  readonly customerId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly unitsDecimal?: InputMaybe<Scalars['Decimal']>;
  /** Deprecated - Use EnteredRatePer - This will be recalculated as ExchangeRate * EnteredRatePer */
  readonly ratePer?: InputMaybe<Scalars['Decimal']>;
  readonly chargeTypeId?: InputMaybe<Scalars['ID']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly enteredRatePer?: InputMaybe<Scalars['Decimal']>;
  readonly currency?: InputMaybe<Scalars['String']>;
  /** Deprecated - Do not use, this will be ignored and it will get set automatically from Currency */
  readonly exchangeRate?: InputMaybe<Scalars['Decimal']>;
  /** Deprecated - Do not use, this will be ignored and it will get set automatically from Load Created Date */
  readonly exchangeRateDate?: InputMaybe<Scalars['Date']>;
  readonly jurisdictionTaxesId?: InputMaybe<Scalars['ID']>;
  readonly stopId?: InputMaybe<Scalars['ID']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly billToCodeId?: InputMaybe<Scalars['ID']>;
  readonly approvalStatus?: InputMaybe<Scalars['String']>;
  readonly approvedBy?: InputMaybe<Scalars['String']>;
  readonly approvedOn?: InputMaybe<Scalars['DateTime']>;
  readonly isUserInitiated?: InputMaybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
};

export type RateQoteError = {
  readonly __typename?: 'RateQoteError';
  readonly code: Scalars['Int'];
  readonly message: Scalars['String'];
};

export type RateQuoteCurrency = {
  readonly currencyUnit?: InputMaybe<Scalars['String']>;
  readonly unit?: InputMaybe<RateQuoteUnitOfCurrencyEnum>;
  readonly value?: InputMaybe<Scalars['Decimal']>;
};

export type RateQuoteCurrencyOutput = {
  readonly __typename?: 'RateQuoteCurrencyOutput';
  readonly unit?: Maybe<RateQuoteUnitOfCurrencyEnum>;
  readonly value?: Maybe<Scalars['Decimal']>;
};

export type RateQuoteCurrencyOutputV2 = {
  readonly __typename?: 'RateQuoteCurrencyOutputV2';
  readonly unit?: Maybe<RateQuoteTenantConfig>;
  readonly value?: Maybe<Scalars['Decimal']>;
};

export enum RateQuoteDistanceUnitEnumType {
  Feet = 'feet',
  Inches = 'inches',
  Kilometers = 'kilometers',
  Meters = 'meters',
  Miles = 'miles'
}

/** RateQuoteGeographyRadiusSearchInput is deprecated. Use RateQuoteGeographyRadiusSearchInputV3. */
export type RateQuoteGeographyRadiusSearchInput = {
  readonly distance?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly sourceId?: InputMaybe<Scalars['Int']>;
};

export type RateQuoteGeographyRadiusSearchInputV3 = {
  readonly distance?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly postalCodeCountry?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
};

export enum RateQuoteGeographyTypeEnum {
  City = 'City',
  Country = 'Country',
  Facility = 'Facility',
  State = 'State'
}

/** DEPRECATED.Do not use this.Use RateQuoteInputV3 */
export type RateQuoteInput = {
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly createdById: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  /** DEPRECATED. Do not use this. This will be removed. Use DestinationId. */
  readonly destinationGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly destinationId?: InputMaybe<Scalars['String']>;
  /** DEPRECATED. Do not use this. */
  readonly destinationText: Scalars['String'];
  readonly distance: RateQuoteUnitOfDistanceInput;
  readonly effectiveDateTime: Scalars['DateTime'];
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly lineItems: ReadonlyArray<RateQuoteLineItemInput>;
  readonly loadSize: Scalars['String'];
  readonly minHeight?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly multipleStops?: InputMaybe<Scalars['Boolean']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  /** DEPRECATED. Do not use this. This will be removed. Use OriginId. */
  readonly originGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly originId?: InputMaybe<Scalars['String']>;
  /** DEPRECATED. Do not use this. */
  readonly originText: Scalars['String'];
  readonly project?: InputMaybe<Scalars['String']>;
  readonly quotedById: Scalars['ID'];
  readonly quotedToId?: InputMaybe<Scalars['ID']>;
  readonly quotePriceTier?: InputMaybe<Scalars['String']>;
  readonly quoteType: Scalars['String'];
  readonly routingType: Scalars['String'];
  readonly scac: Scalars['String'];
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<RateQuoteLaneStopInputV2>>>;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly trailerTypeIds: ReadonlyArray<Scalars['ID']>;
  readonly transportMode: Scalars['String'];
};

/** DEPRECATED.Do not use this.Use RateQuoteInputV4 */
export type RateQuoteInputV3 = {
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly createdById: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly destinationId: Scalars['String'];
  readonly destinationType: RateQuoteGeographyTypeEnum;
  readonly distance: RateQuoteUnitOfDistanceInput;
  readonly effectiveDateTime: Scalars['DateTime'];
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly lineItems: ReadonlyArray<RateQuoteLineItemInput>;
  readonly loadSize: Scalars['String'];
  readonly minHeight?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly multipleStops?: InputMaybe<Scalars['Boolean']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly originId: Scalars['String'];
  readonly originType: RateQuoteGeographyTypeEnum;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly quotedById: Scalars['ID'];
  readonly quotedToId?: InputMaybe<Scalars['ID']>;
  readonly quotePriceTier?: InputMaybe<Scalars['String']>;
  readonly quoteType: Scalars['String'];
  readonly routingType: Scalars['String'];
  readonly scac: Scalars['String'];
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<RateQuoteLaneStopInputV2>>>;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly trailerTypeIds: ReadonlyArray<Scalars['ID']>;
  readonly transportMode: Scalars['String'];
};

/** DEPRECATED.Do not use this.Use RateQuoteInputV5 */
export type RateQuoteInputV4 = {
  readonly autoAccept?: InputMaybe<Scalars['Boolean']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly createdById: Scalars['ID'];
  readonly customDistanceRequest?: InputMaybe<CustomDistanceInput>;
  readonly customerId: Scalars['ID'];
  readonly distanceType: Scalars['Boolean'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDateTime: Scalars['DateTime'];
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly externalPricing: Scalars['Boolean'];
  readonly isContinue: Scalars['Boolean'];
  readonly laneId: Scalars['Uuid'];
  readonly laneType?: InputMaybe<Scalars['String']>;
  readonly lineItems: ReadonlyArray<RateQuoteLineItemInput>;
  readonly loadSize: Scalars['String'];
  readonly minHeight?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly multipleStops?: InputMaybe<Scalars['Boolean']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly orderRequirements?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly quotedById: Scalars['ID'];
  readonly quotedToId?: InputMaybe<Scalars['ID']>;
  readonly quoteFuelConfiguration?: InputMaybe<Scalars['String']>;
  readonly quoteGroupId?: InputMaybe<Scalars['Uuid']>;
  readonly quotePriceTier?: InputMaybe<Scalars['String']>;
  readonly quoteType: Scalars['String'];
  readonly routingType: Scalars['String'];
  readonly scac: Scalars['String'];
  readonly services?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly stops: ReadonlyArray<InputMaybe<RateQuoteLaneStopInputV2>>;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly trailerTypeIds: ReadonlyArray<Scalars['ID']>;
  readonly transportMode: Scalars['String'];
  readonly uploadedByUserId: Scalars['Uuid'];
};

export type RateQuoteInputV5 = {
  readonly autoAccept?: InputMaybe<Scalars['Boolean']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly createdById: Scalars['ID'];
  readonly customDistanceRequest?: InputMaybe<CustomDistanceInput>;
  readonly customerId: Scalars['ID'];
  readonly distanceType?: InputMaybe<Scalars['Boolean']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDateTime: Scalars['DateTime'];
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly isContinue?: InputMaybe<Scalars['Boolean']>;
  readonly laneType?: InputMaybe<Scalars['String']>;
  readonly lineItems: ReadonlyArray<RateQuoteLineItemInput>;
  readonly loadSize: Scalars['String'];
  readonly minHeight?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly multipleStops?: InputMaybe<Scalars['Boolean']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly orderRequirements?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly quotedById: Scalars['ID'];
  readonly quotedToId?: InputMaybe<Scalars['ID']>;
  readonly quoteFuelConfiguration?: InputMaybe<Scalars['String']>;
  readonly quoteGroupId?: InputMaybe<Scalars['ID']>;
  readonly quotePriceTier?: InputMaybe<Scalars['String']>;
  readonly quoteType: Scalars['String'];
  readonly routingType: Scalars['String'];
  readonly scac: Scalars['String'];
  readonly services?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly stops: ReadonlyArray<InputMaybe<RateQuoteLaneStopInputV3>>;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly trailerTypeIds: ReadonlyArray<Scalars['ID']>;
  readonly transportMode: Scalars['String'];
};

export type RateQuoteInputV6 = {
  readonly autoAccept?: InputMaybe<Scalars['Boolean']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly createdById: Scalars['ID'];
  readonly customDistanceRequest?: InputMaybe<CustomDistanceInput>;
  readonly customerId: Scalars['ID'];
  readonly distanceType?: InputMaybe<Scalars['Boolean']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDateTime: Scalars['DateTime'];
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly externalPricing?: InputMaybe<Scalars['Boolean']>;
  readonly isContinue?: InputMaybe<Scalars['Boolean']>;
  readonly laneType?: InputMaybe<Scalars['String']>;
  readonly lineItems?: InputMaybe<ReadonlyArray<InputMaybe<RateQuoteLineItemInput>>>;
  readonly loadSize: Scalars['String'];
  readonly minHeight?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly multipleStops?: InputMaybe<Scalars['Boolean']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly orderRequirements?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly quotedById: Scalars['ID'];
  readonly quotedToId?: InputMaybe<Scalars['ID']>;
  readonly quoteFuelConfiguration?: InputMaybe<Scalars['String']>;
  readonly quoteGroupId?: InputMaybe<Scalars['ID']>;
  readonly quotePriceTier?: InputMaybe<Scalars['String']>;
  readonly quoteType: Scalars['String'];
  readonly routingType: Scalars['String'];
  readonly scac: Scalars['String'];
  readonly services?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly stops: ReadonlyArray<InputMaybe<RateQuoteLaneStopInputV3>>;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly trailerTypeIds: ReadonlyArray<Scalars['ID']>;
  readonly transportMode: Scalars['String'];
};

export type RateQuoteInputV7 = {
  readonly autoAccept?: InputMaybe<Scalars['Boolean']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly createdById: Scalars['ID'];
  readonly customDistanceRequest?: InputMaybe<CustomDistanceInput>;
  readonly customerId: Scalars['ID'];
  readonly distanceType?: InputMaybe<Scalars['Boolean']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDateTime: Scalars['DateTime'];
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly externalPricing?: InputMaybe<Scalars['Boolean']>;
  readonly isContinue?: InputMaybe<Scalars['Boolean']>;
  readonly laneType?: InputMaybe<Scalars['String']>;
  readonly lineItems?: InputMaybe<ReadonlyArray<InputMaybe<RateQuoteLineItemInput>>>;
  readonly loadSize: Scalars['String'];
  readonly minHeight?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly multipleStops?: InputMaybe<Scalars['Boolean']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly orderRequirements?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly quotedById: Scalars['ID'];
  readonly quotedToId?: InputMaybe<Scalars['ID']>;
  readonly quoteFuelConfiguration?: InputMaybe<Scalars['String']>;
  readonly quoteGroupId?: InputMaybe<Scalars['ID']>;
  readonly quotePriceTier?: InputMaybe<Scalars['String']>;
  readonly quoteType: Scalars['String'];
  readonly routingType: Scalars['String'];
  readonly scac?: InputMaybe<Scalars['String']>;
  readonly services?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly stops: ReadonlyArray<InputMaybe<RateQuoteLaneStopInputV3>>;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly trailerTypeIds: ReadonlyArray<Scalars['ID']>;
  readonly transportMode: Scalars['String'];
};

export type RateQuoteKeyValue = {
  readonly __typename?: 'RateQuoteKeyValue';
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly metaDataJson?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly sort?: Maybe<Scalars['Int']>;
  readonly systemDefined?: Maybe<Scalars['Boolean']>;
};

export type RateQuoteLaneStopInputV2 = {
  readonly geographyId: Scalars['String'];
  readonly geographyType: RateQuoteGeographyTypeEnum;
  readonly postalCodeCountry?: InputMaybe<Scalars['String']>;
  readonly sequence: Scalars['Int'];
  readonly stopType: RateQuoteLaneStopTypeEnum;
};

export type RateQuoteLaneStopInputV3 = {
  readonly geographyId: Scalars['String'];
  readonly geographyType: Scalars['String'];
  readonly postalCodeCountry?: InputMaybe<Scalars['String']>;
  readonly sequence: Scalars['Int'];
  readonly stopType: Scalars['String'];
};

export enum RateQuoteLaneStopTypeEnum {
  Pu = 'PU',
  Del = 'Del',
  TrailerAquisition = 'TRAILER_AQUISITION',
  TrailerDeposition = 'TRAILER_DEPOSITION'
}

export type RateQuoteLineItemInput = {
  readonly chargeTypeId: Scalars['ID'];
  readonly exchangeCurrency: RateQuoteCurrency;
  readonly homeCurrency: RateQuoteCurrency;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isDelete?: InputMaybe<Scalars['Boolean']>;
  readonly rateCurrency: RateQuoteCurrency;
  readonly totalRateCurrency: RateQuoteCurrency;
  readonly units: Scalars['Decimal'];
};

export type RateQuoteLineItemOutput = {
  readonly __typename?: 'RateQuoteLineItemOutput';
  readonly chargeType: Scalars['String'];
  readonly chargeTypeId: Scalars['ID'];
  readonly exchangeCurrency: RateQuoteCurrencyOutput;
  readonly homeCurrency: RateQuoteCurrencyOutput;
  readonly id: Scalars['ID'];
  readonly quoteId: Scalars['ID'];
  readonly rateCurrency: RateQuoteCurrencyOutput;
  readonly totalRateCurrency: RateQuoteCurrencyOutput;
  readonly units: Scalars['Decimal'];
};

export type RateQuoteLineItemOutputV2 = {
  readonly __typename?: 'RateQuoteLineItemOutputV2';
  readonly chargeType: Scalars['String'];
  readonly chargeTypeId: Scalars['ID'];
  readonly exchangeCurrency: RateQuoteCurrencyOutputV2;
  readonly homeCurrency: RateQuoteCurrencyOutputV2;
  readonly id: Scalars['ID'];
  readonly quoteId: Scalars['ID'];
  readonly rateCurrency: RateQuoteCurrencyOutputV2;
  readonly totalRateCurrency: RateQuoteCurrencyOutputV2;
  readonly units: Scalars['Decimal'];
};

export type RateQuoteQuoteGroup = {
  readonly __typename?: 'RateQuoteQuoteGroup';
  readonly id?: Maybe<Scalars['ID']>;
};

/** RateQuotesPaginatedFilter is deprecated. Use RateQuotesPaginatedFilterV3. */
export type RateQuotesPaginatedFilter = {
  readonly autoAccept?: InputMaybe<Scalars['Boolean']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly destinationGeographySearch?: InputMaybe<RateQuoteGeographyRadiusSearchInput>;
  readonly destinationGeographyType?: InputMaybe<Scalars['String']>;
  /** DEPRECATED. Do not use this. This will be removed. Use DestinationGeographySearch. */
  readonly destinationText?: InputMaybe<Scalars['String']>;
  readonly distanceType?: InputMaybe<Scalars['Boolean']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly excludeExpired?: InputMaybe<Scalars['Boolean']>;
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly laneIds?: InputMaybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly laneType?: InputMaybe<Scalars['String']>;
  readonly loadSizeId?: InputMaybe<Scalars['ID']>;
  readonly minHeight?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly orderRequirements?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly originGeographySearch?: InputMaybe<RateQuoteGeographyRadiusSearchInput>;
  readonly originGeographyType?: InputMaybe<Scalars['String']>;
  /** DEPRECATED. Do not use this. This will be removed. Use OriginGeographySearch. */
  readonly originText?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly quoteGroupId?: InputMaybe<Scalars['ID']>;
  readonly quotePriceTierId?: InputMaybe<Scalars['ID']>;
  readonly quoteTypeId?: InputMaybe<Scalars['ID']>;
  readonly scacId?: InputMaybe<Scalars['ID']>;
  readonly serviceId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
};

export type RateQuotesPaginatedFilterV3 = {
  readonly autoAccept?: InputMaybe<Scalars['Boolean']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly destinationGeographySearch?: InputMaybe<RateQuoteGeographyRadiusSearchInputV3>;
  readonly destinationGeographyType?: InputMaybe<Scalars['String']>;
  /** Deprecated. DO NOT USE. */
  readonly destinationText?: InputMaybe<Scalars['String']>;
  readonly distanceType?: InputMaybe<Scalars['Boolean']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly excludeExpired?: InputMaybe<Scalars['Boolean']>;
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly laneIds?: InputMaybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly laneType?: InputMaybe<Scalars['String']>;
  readonly loadSizeId?: InputMaybe<Scalars['ID']>;
  readonly minHeight?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly orderRequirements?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly originGeographySearch?: InputMaybe<RateQuoteGeographyRadiusSearchInputV3>;
  readonly originGeographyType?: InputMaybe<Scalars['String']>;
  /** Deprecated. DO NOT USE. */
  readonly originText?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly quoteGroupId?: InputMaybe<Scalars['ID']>;
  readonly quotePriceTierId?: InputMaybe<Scalars['ID']>;
  readonly quoteTypeId?: InputMaybe<Scalars['ID']>;
  readonly scacId?: InputMaybe<Scalars['ID']>;
  readonly serviceIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
};

export type RateQuoteTenantConfig = {
  readonly __typename?: 'RateQuoteTenantConfig';
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly metaDataJson?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly sort?: Maybe<Scalars['Int']>;
  readonly systemDefined?: Maybe<Scalars['Boolean']>;
};

export enum RateQuoteUnitOfCurrencyEnum {
  Usd = 'USD'
}

export type RateQuoteUnitOfDistance = {
  readonly __typename?: 'RateQuoteUnitOfDistance';
  readonly asFeet?: Maybe<Scalars['Decimal']>;
  readonly asInches?: Maybe<Scalars['Decimal']>;
  readonly asKilometers?: Maybe<Scalars['Decimal']>;
  readonly asMeters?: Maybe<Scalars['Decimal']>;
  readonly asMiles?: Maybe<Scalars['Decimal']>;
  readonly asRoundedFeet?: Maybe<Scalars['Decimal']>;
  readonly asRoundedInches?: Maybe<Scalars['Decimal']>;
  readonly asRoundedKilometers?: Maybe<Scalars['Decimal']>;
  readonly asRoundedMeters?: Maybe<Scalars['Decimal']>;
  readonly asRoundedMiles?: Maybe<Scalars['Decimal']>;
};

export type RateQuoteUnitOfDistanceInput = {
  readonly unit?: InputMaybe<RateQuoteDistanceUnitEnumType>;
  readonly value?: InputMaybe<Scalars['Decimal']>;
};

export type RateQuoteUploadEventPayload = {
  readonly __typename?: 'RateQuoteUploadEventPayload';
  readonly status?: Maybe<Scalars['String']>;
  readonly workbookId?: Maybe<Scalars['String']>;
};

export enum RateTypeEnum {
  Unknown = 'UNKNOWN',
  Unit = 'UNIT',
  Flatrate = 'FLATRATE',
  Mileage = 'MILEAGE',
  Revenue = 'REVENUE'
}

export type RatingSourceIdByCustomerResponse = {
  readonly __typename?: 'RatingSourceIdByCustomerResponse';
  readonly ratingSourceId?: Maybe<Scalars['String']>;
};

export type ReactivateCarrierLaneInput = {
  readonly carrierId: Scalars['ID'];
  readonly laneId: Scalars['ID'];
  readonly updatedById: Scalars['ID'];
};

export type ReactivateCarrierLaneResponse = {
  readonly __typename?: 'ReactivateCarrierLaneResponse';
  readonly isSuccess: Scalars['Boolean'];
  readonly expiredRegionLaneStops?: Maybe<ReadonlyArray<Maybe<RegionLaneStopSequence>>>;
};

export type ReactivateCustomerLaneInput = {
  readonly customerId: Scalars['ID'];
  readonly laneId: Scalars['ID'];
  readonly updatedById: Scalars['ID'];
};

export type ReactivateCustomerLaneResponse = {
  readonly __typename?: 'ReactivateCustomerLaneResponse';
  readonly isSuccess: Scalars['Boolean'];
  readonly expiredRegionLaneStops?: Maybe<ReadonlyArray<Maybe<RegionLaneStopSequence>>>;
};

export type ReadDriverConversationInput = {
  readonly conversationId?: InputMaybe<Scalars['String']>;
  readonly userData: DriverConversationReadByInput;
  readonly markMessagesAsRead?: InputMaybe<Scalars['Boolean']>;
  readonly page?: InputMaybe<Scalars['Int']>;
  readonly pageSize?: InputMaybe<Scalars['Int']>;
};

export type ReadDriverConversationMessagesInput = {
  readonly conversation?: InputMaybe<ConversationInfo>;
  readonly markMessagesAsRead?: InputMaybe<Scalars['Boolean']>;
  readonly driverIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly userId?: InputMaybe<Scalars['String']>;
  readonly queryOptions?: InputMaybe<QueryOptions>;
  readonly edgesSortDirection?: InputMaybe<EdgesSortDirection>;
};

export type ReadDriverConversationReadReport = {
  readonly __typename?: 'ReadDriverConversationReadReport';
  readonly numberUpdated?: Maybe<Scalars['Int']>;
  readonly id: Scalars['String'];
  readonly code?: Maybe<Scalars['String']>;
  readonly role: DriverConversationReadByRole;
  readonly readAtDateTime: Scalars['String'];
  readonly displayName: Scalars['String'];
};

export type ReadDriverConversationResponse = {
  readonly __typename?: 'ReadDriverConversationResponse';
  /** @deprecated Field no longer supported */
  readonly conversationId: Scalars['String'];
  readonly messages: ReadonlyArray<DriverMessage>;
  readonly readBy?: Maybe<ReadDriverConversationReadReport>;
};

export type ReadDriverMessagesConnection = {
  readonly __typename?: 'ReadDriverMessagesConnection';
  readonly edges?: Maybe<ReadonlyArray<DriverMessageEdge>>;
  readonly pageInfo: PageInfo;
};

export type ReadyLocationInput = {
  readonly display: Scalars['String'];
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type RebookRouteVendorInput = {
  readonly bounceId: Scalars['ID'];
  readonly rebookAsVendorType?: InputMaybe<RouteVendorTypeEnum>;
  readonly routeVendorId: Scalars['ID'];
};

/** Autogenerated return type of RebookRouteVendor */
export type RebookRouteVendorPayload = {
  readonly __typename?: 'RebookRouteVendorPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendor: RouteVendor;
};

/** Input for Rebooking a Route Vendor after it has been Bounced */
export type RebookRouteVendorV2Input = {
  /** The ID of the Bounce to be Rebooked */
  readonly bounceId: Scalars['ID'];
  /** The type of Vendor the Route Vendor should be Rebooked as */
  readonly rebookAsVendorType?: InputMaybe<RouteVendorTypeEnum>;
  /** The ID of the Route Vendor */
  readonly routeVendorId: Scalars['ID'];
  /** This is set to *true* for Loads Booked through automated processes such as Mastermind API or Tendering */
  readonly isAutomated?: InputMaybe<Scalars['Boolean']>;
  /** This is set to *true* when the Carrier is Pre-Assigned to the Route. Carriers cannot be marked as *booked* when they are Pre-Assigned */
  readonly isPreAssigned?: InputMaybe<Scalars['Boolean']>;
  /** The Employee ID of the Rebooking user */
  readonly rebookedById?: InputMaybe<Scalars['ID']>;
  /** The Booking mechanism used to Book the Route -- *Requires 'Route Booking Source Type' DDT* */
  readonly bookingSourceId?: InputMaybe<Scalars['String']>;
  /** The CarrierContact ID of the Contact the Route was Booked with */
  readonly bookedWithId?: InputMaybe<Scalars['ID']>;
  /** General Notes to include with the Booking */
  readonly bookingNotes?: InputMaybe<Scalars['String']>;
  /** The Distance between the Driver's Expected Ready Location and the First Stop Location on the Route. */
  readonly distance?: InputMaybe<UnitOfDistanceInput>;
  /** The Division responsible for the Booking. Include this if the Executing Division needs to change from the original Booking */
  readonly executingDivisionId?: InputMaybe<Scalars['ID']>;
  /** The source of the Geography data's ID. This is likely '*geonames*' */
  readonly expectedEmptyGeographySource?: InputMaybe<Scalars['String']>;
  /** The source ID from the Geography service */
  readonly expectedEmptyGeographySourceId?: InputMaybe<Scalars['String']>;
  /** The Expected Ready Location Information for the Carrier */
  readonly expectedEmptyLocation?: InputMaybe<ExpectedEmptyLocationInput>;
  /** Braces the carrier is expected to have.  Multi-Select DDT */
  readonly braces?: InputMaybe<BracesInput>;
  /** The type of Bracing Equipment the Carrier is expected to have */
  readonly bracingType?: InputMaybe<BracingTypeEnum>;
  /** The number of Braces the Carrier is expected to have */
  readonly numberOfBraces?: InputMaybe<Scalars['Int']>;
  /** ⚠️ **Deprecated! Use palletTypeV2** ⚠️ */
  readonly palletType?: InputMaybe<PalletTypeEnum>;
  /** The type of Pallets the Carrier is expected to have */
  readonly palletTypeV2?: InputMaybe<Scalars['String']>;
  /** The number of Pallets the Carrier is expected to have */
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  /** ⚠️ **Deprecated! Use tarpTypeV2** ⚠️ */
  readonly tarpType?: InputMaybe<TarpTypeEnum>;
  /** The type of Tarp the Carrier is expected to have */
  readonly tarpTypeV2?: InputMaybe<Scalars['String']>;
  /** The number of Tarps the Carrier is expected to have */
  readonly numberOfTarps?: InputMaybe<Scalars['Int']>;
  /** ⚠️ **Deprecated! Use routeVendorRepsV2** ⚠️ */
  readonly routeVendorReps?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorRepV2Input>>;
  /** The Array of Route Vendor Reps to be Created, Updated, or Removed */
  readonly routeVendorRepsV2?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorRepV3Input>>;
  /** The expected Height of the Carrier's Trailer */
  readonly trailerHeightV2?: InputMaybe<LengthInput>;
  /** The expected Length of the Carrier's Trailer */
  readonly trailerLengthV2?: InputMaybe<LengthInput>;
  /** The expected Width of the Carrier's Trailer */
  readonly trailerWidthV2?: InputMaybe<LengthInput>;
  /** The expected Trailer Type of the Carrier -- *Requires 'Trailer Type' DDT* */
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  /** UTC Timestamp representing the Date & Time the Driver is expected to be ready prior to this Route */
  readonly utcExpectedEmptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** UTC Timestamp representing the estimated Date & Time the Driver is expected to arrive at the first Stop for pickup */
  readonly utcPickupEtaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
};

/** The response from the **rebookRouteVendorV2** Mutation */
export type RebookRouteVendorV2Payload = {
  readonly __typename?: 'RebookRouteVendorV2Payload';
  /** The Route Vendor which has been Rebooked. Returns *NULL* when Rebooking fails */
  readonly routeVendor?: Maybe<RouteVendorV2>;
  /** An Array of any Validation or Rebooking Errors that were encountered */
  readonly errors: ReadonlyArray<Maybe<UserError>>;
};

export type Ref = {
  readonly __typename?: 'Ref';
  readonly ediQualifier?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly type: KeyValue;
  readonly value: Scalars['String'];
};

export type Reference = {
  readonly __typename?: 'Reference';
  readonly id: Scalars['ID'];
  readonly postingId: Scalars['ID'];
  /** @deprecated Use `thirdPartyId`. */
  readonly referenceId: Scalars['String'];
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly createdDate: Scalars['DateTime'];
  readonly status?: Maybe<PostingStatus>;
  readonly source?: Maybe<PostingSource>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<PostingError>>>;
};

export type RefInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeId: Scalars['ID'];
  readonly value: Scalars['String'];
};

export type RefInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeId: Scalars['ID'];
  readonly value: Scalars['String'];
  readonly externalRef?: InputMaybe<Scalars['String']>;
  readonly source?: InputMaybe<Scalars['String']>;
};

export type RefV2 = {
  readonly __typename?: 'RefV2';
  readonly id: Scalars['ID'];
  readonly externalRef?: Maybe<Scalars['String']>;
  readonly type: KeyValue;
  readonly value: Scalars['String'];
  readonly source?: Maybe<Scalars['String']>;
};

/** Holds Region Data Info. */
export type Region = {
  readonly __typename?: 'Region';
  readonly urn?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly parenturn?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
};

/** Holds Region Data Info. */
export type RegionByIdGraphResponse = {
  readonly __typename?: 'RegionByIdGraphResponse';
  readonly code?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly region?: Maybe<Region>;
  readonly status?: Maybe<Scalars['String']>;
};

export type RegionData = {
  readonly __typename?: 'RegionData';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly superRegions?: Maybe<ReadonlyArray<Maybe<SuperRegionV2>>>;
  readonly subRegions?: Maybe<ReadonlyArray<Maybe<SubRegionV2>>>;
  readonly regionInfos?: Maybe<ReadonlyArray<Maybe<RegionInfoV2>>>;
  readonly regionSetName?: Maybe<Scalars['String']>;
  readonly isRegionSetActive?: Maybe<Scalars['Boolean']>;
  readonly customerID?: Maybe<Scalars['ID']>;
  readonly carrierID?: Maybe<Scalars['ID']>;
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
};

export type RegionFilter = {
  /** Search/filter By array of region IDs */
  readonly regions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/filter By array of sub region IDs */
  readonly subRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/filter By array of super region IDs */
  readonly superRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** Holds Region Data Info. */
export type RegionGraphResponse = {
  readonly __typename?: 'RegionGraphResponse';
  readonly code?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly regions?: Maybe<ReadonlyArray<Maybe<Region>>>;
  readonly status?: Maybe<Scalars['String']>;
};

export type RegionInfoByRegionIdResponse = {
  readonly __typename?: 'RegionInfoByRegionIdResponse';
  readonly regionId?: Maybe<Scalars['ID']>;
  readonly regionName?: Maybe<Scalars['String']>;
  readonly regionSetName?: Maybe<Scalars['String']>;
  readonly superRegions?: Maybe<ReadonlyArray<Maybe<SuperRegionV2>>>;
  readonly subRegions?: Maybe<ReadonlyArray<Maybe<SubRegionV2>>>;
  readonly regionInfos?: Maybe<ReadonlyArray<Maybe<RegionInfoV2>>>;
  readonly customerID?: Maybe<Scalars['String']>;
  readonly carrierID?: Maybe<Scalars['String']>;
};

/** The input type for Region Info. */
export type RegionInfoInput = {
  readonly country?: InputMaybe<Scalars['String']>;
  readonly zipcode?: InputMaybe<Scalars['String']>;
};

export type RegionInfoV2 = {
  readonly __typename?: 'RegionInfoV2';
  readonly postalCodePrefix?: Maybe<Scalars['String']>;
  readonly postalCodeLower?: Maybe<Scalars['String']>;
  readonly postalCodeUpper?: Maybe<Scalars['String']>;
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly region?: Maybe<RegionV2>;
  readonly superRegion?: Maybe<SuperRegionV2>;
  readonly subRegion?: Maybe<SubRegionV2>;
  readonly stateProvinceName?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly notYetActive?: Maybe<Scalars['Boolean']>;
  readonly expired?: Maybe<Scalars['Boolean']>;
  readonly regionsetname?: Maybe<Scalars['String']>;
  readonly regionsetid: Scalars['ID'];
};

export type RegionInfoV2Response = {
  readonly __typename?: 'RegionInfoV2Response';
  readonly message?: Maybe<Scalars['String']>;
  readonly regionInfo?: Maybe<RegionInfoV2>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type RegionInput = {
  readonly id: Scalars['ID'];
  readonly regionSetName: Scalars['String'];
  readonly regionName: Scalars['String'];
  readonly subRegionName?: InputMaybe<Scalars['String']>;
  readonly superRegionName?: InputMaybe<Scalars['String']>;
};

export type RegionLaneStopSequence = {
  readonly __typename?: 'RegionLaneStopSequence';
  readonly stopSequence: Scalars['Int'];
  readonly stopRegion?: Maybe<RegionV2>;
};

export type RegionOutput = {
  readonly __typename?: 'RegionOutput';
  readonly id: Scalars['ID'];
  readonly regionSetName: Scalars['String'];
  readonly regionName: Scalars['String'];
  readonly subRegionName: Scalars['String'];
  readonly superRegionName: Scalars['String'];
};

export type RegionSetR2 = {
  readonly __typename?: 'RegionSetR2';
  readonly id: Scalars['ID'];
  readonly regionSetName?: Maybe<Scalars['String']>;
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly modifiedBy?: Maybe<Scalars['String']>;
  readonly modifiedDateTime?: Maybe<Scalars['DateTime']>;
  readonly division?: Maybe<ReadonlyArray<Maybe<DivisionR2>>>;
  readonly equipment?: Maybe<ReadonlyArray<Maybe<EquipmentR2>>>;
  readonly size?: Maybe<ReadonlyArray<Maybe<SizeR2>>>;
  readonly mode?: Maybe<ReadonlyArray<Maybe<ModeR2>>>;
  readonly businessunit?: Maybe<ReadonlyArray<Maybe<BusinessUnitR2>>>;
  readonly regionSetType?: Maybe<ReadonlyArray<Maybe<RegionSetTypeR2>>>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type RegionSetTypeR2 = {
  readonly __typename?: 'RegionSetTypeR2';
  readonly id?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
};

export type RegionSetV2Input = {
  readonly searchText?: InputMaybe<Scalars['String']>;
  readonly showExpired?: InputMaybe<Scalars['Boolean']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
};

export type RegionsV2Input = {
  readonly postalCode: Scalars['String'];
  readonly countryCode: Scalars['String'];
  readonly date: Scalars['DateTime'];
  readonly regionSetType: Scalars['String'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly equipment?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
};

export type RegionsV2InputNew = {
  readonly postalCode: Scalars['String'];
  readonly countryCode: Scalars['String'];
  readonly date: Scalars['DateTime'];
  readonly regionSetType: Scalars['String'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly equipment?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
};

export type RegionV2 = {
  readonly __typename?: 'RegionV2';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly subRegions?: Maybe<ReadonlyArray<Maybe<SubRegionV2>>>;
  readonly regionsetname?: Maybe<Scalars['String']>;
  readonly isregionsetactive?: Maybe<Scalars['Boolean']>;
  readonly subRegionNames?: Maybe<Scalars['String']>;
  readonly regionData?: Maybe<RegionData>;
};

export type RegisterDeviceInput = {
  readonly driverId: Scalars['String'];
  readonly deviceToken: Scalars['String'];
  readonly deviceType: Scalars['String'];
};

export type RegisterDevicePayload = {
  readonly __typename?: 'RegisterDevicePayload';
  readonly registerDeviceSuccess: Scalars['Boolean'];
  readonly errors: ReadonlyArray<UserError>;
};

export type RelativePositionOutput = {
  readonly __typename?: 'RelativePositionOutput';
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
};

export type ReleaseCredit = {
  readonly orderCode: Scalars['ID'];
};

export enum RemittanceGroupingEnum {
  /** Route */
  Route = 'Route',
  /** Combined */
  Combined = 'Combined',
  /**
   * Order
   * @deprecated field deprecated, will be removed 08/07/2020
   */
  Order = 'Order'
}

/** Autogenerated return type of RemoveCustomerQuoteFromGroup */
export type RemoveCustomerQuoteFromGroupPayload = {
  readonly __typename?: 'RemoveCustomerQuoteFromGroupPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly quote: CustomerQuote;
};

/** RemoveCustomerQuoteFromGroupPayloadV2 is deprecated. Use RemoveCustomerQuoteFromGroupPayloadV3. */
export type RemoveCustomerQuoteFromGroupPayloadV2 = {
  readonly __typename?: 'RemoveCustomerQuoteFromGroupPayloadV2';
  readonly error: Scalars['String'];
  readonly quote: CustomerQuotePaginatedV2;
};

export type RemoveCustomerQuoteFromGroupPayloadV3 = {
  readonly __typename?: 'RemoveCustomerQuoteFromGroupPayloadV3';
  readonly error?: Maybe<Scalars['String']>;
  readonly quote: CustomerQuoteV3;
};

export type RemoveCustomerQuoteFromGroupPayloadV4 = {
  readonly __typename?: 'RemoveCustomerQuoteFromGroupPayloadV4';
  readonly error?: Maybe<Scalars['String']>;
  readonly quote?: Maybe<CustomerQuoteV3>;
};

export type RemoveCustomerQuoteFromGroupPayloadV5 = {
  readonly __typename?: 'RemoveCustomerQuoteFromGroupPayloadV5';
  readonly error?: Maybe<Scalars['String']>;
  readonly quote?: Maybe<CustomerQuoteV4>;
};

export type RemoveDevicePayload = {
  readonly __typename?: 'RemoveDevicePayload';
  readonly removeDeviceSuccess: Scalars['Boolean'];
  readonly errors: ReadonlyArray<UserError>;
};

export type RemoveEmployeeMessagingUserIdentitiesInput = {
  readonly messagingUserIdentityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type RemoveEmployeeMessagingUserIdentitiesPayload = {
  readonly __typename?: 'RemoveEmployeeMessagingUserIdentitiesPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type RemovePostingInput = {
  readonly id: Scalars['ID'];
  readonly removedByUserId: Scalars['ID'];
  readonly loadBoards?: InputMaybe<ReadonlyArray<PostingSource>>;
  readonly operationSource?: InputMaybe<PostingsOperationSource>;
  readonly removedManually?: InputMaybe<Scalars['Boolean']>;
};

export type RemovePostingPayload = {
  readonly __typename?: 'RemovePostingPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly success?: Maybe<Scalars['Boolean']>;
};

export type RemovePostingsByRouteIdsInput = {
  readonly routeIds: ReadonlyArray<Scalars['ID']>;
  readonly removedByUserId: Scalars['ID'];
  readonly loadBoards?: InputMaybe<ReadonlyArray<PostingSource>>;
  readonly operationSource?: InputMaybe<PostingsOperationSource>;
};

export type RemovePostingsInput = {
  readonly postingIds: ReadonlyArray<Scalars['ID']>;
  readonly removedByUserId: Scalars['ID'];
  readonly loadBoards?: InputMaybe<ReadonlyArray<PostingSource>>;
};

export type RemovePostingsPayload = {
  readonly __typename?: 'RemovePostingsPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly success?: Maybe<Scalars['Boolean']>;
};

export type RemoveQuoteFromGroupInput = {
  readonly quoteId: Scalars['ID'];
};

export type RemoveRateQuoteFromQuoteGroupInput = {
  readonly rateQuoteId: Scalars['ID'];
  readonly user?: InputMaybe<UserDetailInput>;
};

export type RemoveRouteFromTripInput = {
  readonly routeId: Scalars['ID'];
  readonly tripId: Scalars['ID'];
};

export type RemoveRouteFromTripOutput = {
  readonly __typename?: 'RemoveRouteFromTripOutput';
  readonly updatedTrip?: Maybe<TripType>;
};

export type RenderedTemplate = {
  readonly __typename?: 'RenderedTemplate';
  readonly subject?: Maybe<Scalars['String']>;
  readonly body?: Maybe<Scalars['String']>;
};

export type ReorderAutoApplyPriorityInput = {
  readonly userId: Scalars['ID'];
  readonly laneId: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly autoApplyPriorities: ReadonlyArray<ReorderAutoApplyPriortiesInput>;
};

export type ReorderAutoApplyPriorityPayload = {
  readonly __typename?: 'ReorderAutoApplyPriorityPayload';
  readonly data?: Maybe<SubReorderAutoApplyPriorityPayload>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type ReorderAutoApplyPriortiesInput = {
  readonly priority: Scalars['Int'];
  readonly patternId: Scalars['ID'];
};

export type ReorderLanePatternsByPriorityInput = {
  readonly userId: Scalars['ID'];
  readonly laneId: Scalars['ID'];
  readonly patternPriorities: ReadonlyArray<ReorderPatternInput>;
};

export type ReorderLanePatternsByPriorityPayload = {
  readonly __typename?: 'ReorderLanePatternsByPriorityPayload';
  readonly message?: Maybe<Scalars['String']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly currentLanePatterns?: Maybe<ReadonlyArray<Maybe<Pattern>>>;
};

export type ReorderLanePatternsByPriorityPayloadV2 = {
  readonly __typename?: 'ReorderLanePatternsByPriorityPayloadV2';
  readonly data?: Maybe<ReorderLanePatternsByPriorityPayload>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type ReorderPatternInput = {
  readonly patternId: Scalars['ID'];
  readonly priority: Scalars['Int'];
};

export type ReorderRoutingGuideSequenceItemsInput = {
  readonly routingGuideSequenceItemId: Scalars['ID'];
  readonly ordinal: Scalars['Int'];
};

export type ReorderTenderPlanTenderSequenceInput = {
  readonly tenderId: Scalars['ID'];
  readonly ordinal: Scalars['Int'];
};

export type Rep = {
  readonly __typename?: 'Rep';
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly employee: Employee;
  readonly id: Scalars['ID'];
  readonly isLoadRep: Scalars['Boolean'];
  readonly main: Scalars['Boolean'];
  readonly repType: Scalars['ID'];
  readonly repTypeKeyId?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
};

export type RepeatLoadDetailsInput = {
  readonly rowUniqueId: Scalars['String'];
  readonly orderDetails: RepeatLoadOrderInput;
  readonly routeDetails: ReadonlyArray<CloneLoadRouteInput>;
  readonly commodities?: InputMaybe<ReadonlyArray<CloneLoadCommodityInput>>;
};

export type RepeatLoadInput = {
  readonly loadId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly rateDetails: ReadonlyArray<CloneLoadRateDetailsInput>;
  readonly rerateableRateDetails?: InputMaybe<ReadonlyArray<CloneLoadRateDetailsInput>>;
  readonly repeatLoadDetails: ReadonlyArray<RepeatLoadDetailsInput>;
};

export type RepeatLoadOrderInput = {
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInputV2>>;
  readonly activationStatusId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly orderNote?: InputMaybe<Scalars['String']>;
  readonly activationStatusReason?: InputMaybe<Scalars['String']>;
};

export type RepeatLoadPayload = {
  readonly __typename?: 'RepeatLoadPayload';
  readonly errors: ReadonlyArray<LoadV2Error>;
  readonly load?: Maybe<LoadV2>;
};

export type RepeatLoadPayloads = {
  readonly __typename?: 'RepeatLoadPayloads';
  readonly errors: ReadonlyArray<LoadV2Error>;
  readonly load?: Maybe<ReadonlyArray<Maybe<RepeatLoads>>>;
};

export type RepeatLoadRequestPayload = {
  readonly __typename?: 'RepeatLoadRequestPayload';
  readonly errors: ReadonlyArray<LoadV2Error>;
  readonly requestId?: Maybe<Scalars['String']>;
};

export type RepeatLoads = {
  readonly __typename?: 'RepeatLoads';
  readonly repeatedLoad?: Maybe<LoadV2>;
  readonly rowUniqueId?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly errorMessage?: Maybe<Scalars['String']>;
};

export type RepIdFilter = {
  readonly orderRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type RepIdsFilter = {
  /** Search/filter by order rep IDs */
  readonly orderRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/filter By route rep IDs */
  readonly routeRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type RepInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly employeeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isLoadRep?: InputMaybe<Scalars['Boolean']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly repType?: InputMaybe<Scalars['ID']>;
};

export type RepInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly repType?: InputMaybe<RepTypeEnum>;
  readonly employeeId: Scalars['ID'];
  readonly repTypeKeyId?: InputMaybe<Scalars['ID']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly isLoadRep?: InputMaybe<Scalars['Boolean']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
  /**  The field `divisionId` is deprecated. It is not editable from reps and pulled from the employee record.  */
  readonly divisionId?: InputMaybe<Scalars['ID']>;
  readonly modeId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  /**  The field `businessUnitId` is deprecated. It is not editable from reps and pulled from the employee record.  */
  readonly businessUnitId?: InputMaybe<Scalars['ID']>;
  readonly directionId?: InputMaybe<Scalars['ID']>;
};

export type ReplaceRouteVendorReferenceInput = {
  /** Route Reference type allows any string to be passed in. */
  readonly value: Scalars['String'];
  /** This accepts any string. A Validation is run to ensure it is a valid Route Reference Type. */
  readonly routeVendorReferenceTypeV2: Scalars['String'];
  /** EDI Qualifier for the Route Reference - EDI ID qualifiers and their corresponding values identify the sender and receiver of the EDI document. */
  readonly ediQualifier?: InputMaybe<Scalars['String']>;
};

/** Input for replacing Route Vendor References */
export type ReplaceRouteVendorReferencesInput = {
  /** The ID of the Route Vendor which Route References are associated with */
  readonly routeVendorId: Scalars['ID'];
  /** The ID of the User performing the action */
  readonly createdByUserId: Scalars['ID'];
  /** The Array of Route References to replace the existing.  An empty array will delete all existing Route References */
  readonly routeVendorReferences: ReadonlyArray<ReplaceRouteVendorReferenceInput>;
};

export type ReportScheduleDat = {
  readonly __typename?: 'ReportScheduleDat';
  readonly route?: Maybe<Scalars['String']>;
  readonly actionString?: Maybe<Scalars['String']>;
  readonly leg?: Maybe<Scalars['Int']>;
  readonly location?: Maybe<Scalars['String']>;
  readonly time?: Maybe<Scalars['String']>;
  readonly action: Scalars['String'];
  readonly latitude?: Maybe<Scalars['Float']>;
  readonly longitude?: Maybe<Scalars['Float']>;
  readonly milesToNext?: Maybe<Scalars['Float']>;
  readonly hoursToNext?: Maybe<Scalars['Float']>;
};

export enum RepTypeEnum {
  /** Accounts Payable */
  AccountsPayable = 'accountsPayable',
  /** Carrier Manager */
  CarrierManager = 'carrierManager',
  /** Carrier Ops */
  CarrierOps = 'carrierOps',
  /** Carrier Sales */
  CarrierSales = 'carrierSales',
  /** Issue Resolution Rep */
  IssueResolutionRep = 'issueResolutionRep',
  /** Tracking Specialist */
  TrackingSpecialist = 'trackingSpecialist',
  /** Other */
  Other = 'other',
  /** Sponsored Prospect */
  SponsoredProspect = 'sponsoredProspect',
  /** Fleet Manager */
  FleetManager = 'fleetManager',
  /** Fleet Supervisor */
  FleetSupervisor = 'fleetSupervisor',
  /** Load Planner */
  LoadPlanner = 'loadPlanner',
  /** Driver Manager */
  DriverManager = 'driverManager'
}

export type RepV2 = {
  readonly __typename?: 'RepV2';
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly employee: EmployeeV2;
  readonly employeeId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly isLoadRep: Scalars['Boolean'];
  readonly main: Scalars['Boolean'];
  readonly active: Scalars['Boolean'];
  /** @deprecated Please use repTypeKeyId instead */
  readonly repType: RepTypeEnum;
  readonly repTypeKey?: Maybe<KeyValue>;
  readonly repTypeKeyId: Scalars['ID'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  /** @deprecated division is not editable from reps and pulled from the employee record. */
  readonly division?: Maybe<KeyValue>;
  /** @deprecated divisionId is not editable from reps and pulled from the employee record.  */
  readonly divisionId?: Maybe<Scalars['ID']>;
  readonly mode?: Maybe<KeyValue>;
  readonly modeId?: Maybe<Scalars['ID']>;
  readonly size?: Maybe<KeyValue>;
  readonly sizeId?: Maybe<Scalars['ID']>;
  /** @deprecated businessUnit is not editable from reps and pulled from the employee record.  */
  readonly businessUnit?: Maybe<KeyValue>;
  /** @deprecated businessUnitId is not editable from reps and pulled from the employee record.  */
  readonly businessUnitId?: Maybe<Scalars['ID']>;
  readonly direction?: Maybe<KeyValue>;
  readonly directionId?: Maybe<Scalars['ID']>;
};

export type RequestedTimeRange = {
  readonly __typename?: 'RequestedTimeRange';
  readonly startOffsetMs: Scalars['Int'];
  readonly endOffsetMs: Scalars['Int'];
};

export type RequestOutput = {
  readonly __typename?: 'RequestOutput';
  readonly provider: ReadonlyArray<Maybe<Scalars['String']>>;
  readonly hazmatShipment: Scalars['Boolean'];
  readonly shipFromLocation: RequestShipLocation;
  readonly shipToLocation: RequestShipLocation;
  readonly pickUpDate?: Maybe<Scalars['DateTime']>;
};

export type RequestShipLocation = {
  readonly __typename?: 'RequestShipLocation';
  readonly city?: Maybe<Scalars['String']>;
  readonly stateAbbreviation?: Maybe<Scalars['String']>;
  readonly zipCode: Scalars['String'];
  readonly country?: Maybe<Scalars['String']>;
};

/** Response Payload Object from requestToBookV2 mutation */
export type RequestToBookPayloadV2 = {
  readonly __typename?: 'RequestToBookPayloadV2';
  readonly code?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly validationCode?: Maybe<Scalars['String']>;
  readonly validationMessage?: Maybe<Scalars['String']>;
  readonly routeVendorId?: Maybe<Scalars['ID']>;
  readonly routeVendor?: Maybe<RouteVendorV2>;
};

/** The input type used for determining required documents recalculation filtering. */
export type RequiredDocumentRecalcOptionsInputType = {
  readonly recalculate: Scalars['Boolean'];
  readonly deliveryDateAfter?: InputMaybe<Scalars['DateTime']>;
  readonly financialStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly lifeCycleStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type Requirement = {
  readonly __typename?: 'Requirement';
  /** DataType of the requirement */
  readonly dataType: DataType;
  /** The type of validation that should be applied to this requirement, such as warning or hard stop */
  readonly validationType?: Maybe<Scalars['String']>;
  /** An array of logical checks required for the requirement to be valid */
  readonly dataValidations: ReadonlyArray<LogicalCheck>;
  /** Display name for this requirement rule */
  readonly displayName: Scalars['String'];
  /** Identifier for this requirement, used to distinguish what rule it's associated with */
  readonly requirementName?: Maybe<Scalars['String']>;
  /** Flag that shows if this particular requirement is active or not for the validationConfiguration */
  readonly isRequired: Scalars['Boolean'];
  /** @deprecated This field is not currently being used by canvas, and will be removed once we can confirm it is no longer being called */
  readonly logicGate?: Maybe<LogicGate>;
  /** Name of the DDT ID associated with the value options for this requirement */
  readonly dataDictionaryTermID?: Maybe<Scalars['String']>;
  /** @deprecated This property has been replaced by requirementName and will be removed in the future */
  readonly fieldName?: Maybe<Scalars['String']>;
};

export type RequirementInput = {
  /** DataType of the requirement */
  readonly dataType: DataType;
  /** The type of validation that should be applied to this requirement, such as warning or hard stop */
  readonly validationType?: InputMaybe<Scalars['String']>;
  /** An array of logical checks required for the requirement to be valid */
  readonly dataValidations: ReadonlyArray<LogicalCheckInput>;
  /** Display name for this requirement rule */
  readonly displayName: Scalars['String'];
  /** Identifier for this requirement, used to distinguish what rule it's associated with */
  readonly requirementName?: InputMaybe<Scalars['String']>;
  /** Flag that shows if this particular requirement is active or not for the validationConfiguration */
  readonly isRequired: Scalars['Boolean'];
  /** Determines how multiple data validations should be chained together */
  readonly logicGate?: InputMaybe<LogicGate>;
  /** Name of the DDT ID associated with the value options for this requirement */
  readonly dataDictionaryTermID?: InputMaybe<Scalars['String']>;
  readonly fieldName?: InputMaybe<Scalars['String']>;
};

export type RerateLoadSearch = {
  readonly __typename?: 'RerateLoadSearch';
  readonly id: Scalars['ID'];
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  readonly routeCarrierId?: Maybe<Scalars['String']>;
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly orderRepId?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly routeDistance?: Maybe<Scalars['Float']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly mainRouteRep?: Maybe<Scalars['String']>;
  readonly mainOrderRep?: Maybe<Scalars['String']>;
  readonly routeMaxCost?: Maybe<Scalars['Float']>;
  readonly orderMode?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly orderLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['String']>;
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly orderTenderStatus?: Maybe<Scalars['String']>;
  readonly routeOverMax?: Maybe<Scalars['Float']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Float']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Float']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly orderSize?: Maybe<Scalars['String']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly routeTotalCost?: Maybe<Scalars['Float']>;
  readonly routeTotalRate?: Maybe<Scalars['Float']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
};

export type RerateLoadSearchConnection = {
  readonly __typename?: 'RerateLoadSearchConnection';
  readonly edges: ReadonlyArray<RerateLoadSearchEdge>;
  readonly pageInfo: PageInfo;
};

export type RerateLoadSearchDownload = {
  readonly __typename?: 'RerateLoadSearchDownload';
  readonly id: Scalars['ID'];
};

export type RerateLoadSearchDownloadConnection = {
  readonly __typename?: 'RerateLoadSearchDownloadConnection';
  readonly edges: ReadonlyArray<RerateLoadSearchDownloadEdge>;
  readonly pageInfo: PageInfo;
};

export type RerateLoadSearchDownloadEdge = {
  readonly __typename?: 'RerateLoadSearchDownloadEdge';
  readonly node: RerateLoadSearchDownload;
  readonly cursor: Scalars['String'];
};

export type RerateLoadSearchEdge = {
  readonly __typename?: 'RerateLoadSearchEdge';
  readonly node: RerateLoadSearch;
  readonly cursor: Scalars['String'];
};

export type RerateLoadSearchFilterInput = {
  readonly loadCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderReferences?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** known as shipper on the FE */
  readonly originStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopRadius?: InputMaybe<RadiusFilter>;
  /** known as consignee on the FE */
  readonly destStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly destStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopRadius?: InputMaybe<RadiusFilter>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly orderRepId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeCarrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeRepId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLifeCycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderLifeCycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type ResequenceCarrierFuelProgramConfigurationInput = {
  readonly vendorId: Scalars['ID'];
  readonly fuelProgramCarrierConfigurationIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type ResequenceCarrierFuelProgramConfigurationOutput = {
  readonly __typename?: 'ResequenceCarrierFuelProgramConfigurationOutput';
  readonly isPriorityValid?: Maybe<Scalars['Boolean']>;
  readonly message?: Maybe<Scalars['String']>;
};

export type ResequenceCustomerFuelProgramConfigurationInput = {
  readonly customerId: Scalars['ID'];
  readonly fuelProgramCustomerConfigurationIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type ResequenceCustomerFuelProgramConfigurationResponse = {
  readonly __typename?: 'ResequenceCustomerFuelProgramConfigurationResponse';
  readonly isPriorityValid: Scalars['Boolean'];
  readonly message?: Maybe<Scalars['String']>;
};

export type ResequenceLoadStopsInput = {
  readonly loadStopIds: ReadonlyArray<Scalars['ID']>;
  readonly loadId: Scalars['ID'];
};

export type ResequenceOrderProfile = {
  readonly __typename?: 'ResequenceOrderProfile';
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly orderProfileId?: Maybe<Scalars['ID']>;
  readonly sequence?: Maybe<Scalars['Int']>;
};

export type ResequenceOrderProfilePayload = {
  readonly __typename?: 'ResequenceOrderProfilePayload';
  readonly errors: ReadonlyArray<OrderProfileTypeError>;
  readonly resequenceOrderProfile?: Maybe<ReadonlyArray<ResequenceOrderProfile>>;
};

export type ResequenceOrderProfilePriorityInput = {
  readonly orderProfileIds: ReadonlyArray<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
};

export type ResequenceStopsInput = {
  readonly routeStopIds: ReadonlyArray<Scalars['ID']>;
  readonly routeId: Scalars['ID'];
};

export type ReserveCreditNoTotalRate = {
  readonly orderCode: Scalars['ID'];
};

export type ReserveCreditWithDetails = {
  readonly customerId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly rateDetails: ReadonlyArray<InputMaybe<CreditRateDetails>>;
  readonly invoiceHeaders: ReadonlyArray<InputMaybe<CreditInvoiceHeader>>;
};

export type ReserveCreditWithRateDetails = {
  readonly orderId: Scalars['ID'];
  readonly rateDetails?: InputMaybe<ReadonlyArray<InputMaybe<CreditRateDetails>>>;
};

export type ReserveCreditWithTotalRate = {
  readonly orderId: Scalars['ID'];
  readonly totalRate: Scalars['Float'];
};

export type ResetRouteStopRegionsInput = {
  readonly routeId: Scalars['ID'];
};

export type ResetSublocationApiDdtCacheError = {
  readonly __typename?: 'ResetSublocationApiDdtCacheError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly code?: Maybe<Scalars['String']>;
};

export type ResetSublocationApiDdtCachePayload = {
  readonly __typename?: 'ResetSublocationApiDdtCachePayload';
  readonly errors: ReadonlyArray<ResetSublocationApiDdtCacheError>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
};

/** Corresponds to the type of Carrier assigned to the driver resource on initial save. Asset carriers are of type `fleet`, and the remaining carriers are of type `carrier`. */
export enum ResourceType {
  Carrier = 'Carrier',
  Fleet = 'Fleet'
}

export type ResultAddress = {
  readonly __typename?: 'ResultAddress';
  readonly requestId?: Maybe<Scalars['ID']>;
  readonly addressLine1: Scalars['String'];
  readonly addressLine2?: Maybe<Scalars['String']>;
  readonly addressLine3?: Maybe<Scalars['String']>;
  readonly addressLine4?: Maybe<Scalars['String']>;
  readonly locality?: Maybe<Scalars['String']>;
  readonly administrativeArea?: Maybe<Scalars['String']>;
  readonly countryAlpha2?: Maybe<Scalars['String']>;
  readonly countryAlpha3?: Maybe<Scalars['String']>;
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly isVerified?: Maybe<Scalars['Boolean']>;
};

export type RevokeAccessInput = {
  readonly email: Scalars['String'];
  readonly roleId: Scalars['String'];
  readonly tenantId: Scalars['String'];
};

/** enums */
export enum RiskInformationSourceTypeEnum {
  /** source not specified. */
  UnKnown = 'unKnown',
  /** updated by RMIS API */
  RmisApi = 'rmisApi',
  /** updated by RMIS FTP */
  RmisFtp = 'rmisFtp',
  /** updated by USER INPUT */
  UserInput = 'userInput'
}

export enum RoeStatusEnum {
  Green = 'Green',
  Red = 'Red',
  Yellow = 'Yellow'
}

export type Round = {
  readonly __typename?: 'Round';
  readonly roundId: Scalars['ID'];
  readonly bidUId?: Maybe<Scalars['String']>;
  readonly externalRoundId?: Maybe<Scalars['String']>;
  readonly roundNumber?: Maybe<Scalars['Int']>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly roundStatusId?: Maybe<Scalars['ID']>;
  readonly bidEid?: Maybe<Scalars['Int']>;
  readonly roundStatus?: Maybe<BidStatus>;
  readonly createdBy?: Maybe<Scalars['ID']>;
};

export type RoundInput = {
  readonly roundId: Scalars['ID'];
  readonly startDate: Scalars['String'];
  readonly endDate: Scalars['String'];
};

export type RoundPayload = {
  readonly __typename?: 'RoundPayload';
  readonly round?: Maybe<Round>;
};

export type RoundResponsePayload = {
  readonly __typename?: 'RoundResponsePayload';
  readonly roundId: Scalars['ID'];
  readonly bidUId?: Maybe<Scalars['String']>;
  readonly externalRoundId?: Maybe<Scalars['String']>;
  readonly roundNumber?: Maybe<Scalars['Int']>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly roundStatusId?: Maybe<Scalars['ID']>;
  readonly bidEid?: Maybe<Scalars['Int']>;
  readonly roundStatus?: Maybe<BidStatus>;
  readonly isAllBidLanesPricedOrRejected?: Maybe<Scalars['Boolean']>;
  readonly isAnyBidLanePriced?: Maybe<Scalars['Boolean']>;
  readonly createdBy?: Maybe<Scalars['ID']>;
};

export type RoundSubmitStatusPayload = {
  readonly __typename?: 'RoundSubmitStatusPayload';
  readonly message?: Maybe<Scalars['String']>;
  readonly isSubmitted?: Maybe<Scalars['Boolean']>;
};

export type RouteAllocatedMargin = {
  readonly __typename?: 'RouteAllocatedMargin';
  readonly routeId: Scalars['ID'];
  /** @deprecated Use the Route object */
  readonly routeCode: Scalars['String'];
  readonly allocatedMargin?: Maybe<Scalars['Decimal']>;
  readonly grossAllocatedMargin?: Maybe<Scalars['Decimal']>;
  readonly allocatedCompanyOverheadAmount?: Maybe<Scalars['Decimal']>;
  readonly routeRateAllocationPercent?: Maybe<Scalars['Decimal']>;
  readonly routeCostAllocationPercent?: Maybe<Scalars['Decimal']>;
  readonly routeCostTotal?: Maybe<Scalars['Decimal']>;
  readonly grossRouteAllocatedRate?: Maybe<Scalars['Decimal']>;
  readonly grossRouteAllocatedCost?: Maybe<Scalars['Decimal']>;
  readonly distance?: Maybe<Scalars['Decimal']>;
  readonly distancePercent?: Maybe<Scalars['Decimal']>;
  readonly routeCommissions?: Maybe<ReadonlyArray<Maybe<RouteCommissionModel>>>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  /** @deprecated Use the Carrier object */
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly grossAllocatedPreSplitRate?: Maybe<Scalars['Decimal']>;
  readonly totalGrossMargin?: Maybe<Scalars['Decimal']>;
  readonly totalNetMargin?: Maybe<Scalars['Decimal']>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly route?: Maybe<LoadRoute>;
  readonly routeV2?: Maybe<LoadRouteV2>;
};

export type RouteAndVendorFilter = {
  /** ID of the route */
  readonly routeId: Scalars['ID'];
  /** ID of the vendor */
  readonly vendorId: Scalars['ID'];
};

export type RouteAssignDriverInput = {
  /** Division associated with the entity, must match a division DDT */
  readonly division?: InputMaybe<Scalars['String']>;
  /** Load Size associated with the entity, must match a loadSize DDT */
  readonly loadSize?: InputMaybe<Scalars['String']>;
  /** Trailer Type equipment associated with the entity, must match a trailerType DDT option ID in UUID format */
  readonly equipment?: ReadonlyArray<Scalars['String']>;
  /** Route Transport Mode associated with the entity, must match a routeTransportMode DDT */
  readonly routeTransportMode?: InputMaybe<Scalars['String']>;
};

export type RouteAssignedRep = {
  readonly __typename?: 'RouteAssignedRep';
  readonly id: Scalars['ID'];
  readonly employeeId: Scalars['ID'];
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly employeeOffice?: Maybe<KeyValue>;
  readonly main: Scalars['Boolean'];
  readonly routeId: Scalars['ID'];
  readonly type: KeyValue;
  readonly fullName: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly employeeGroups?: Maybe<ReadonlyArray<KeyValue>>;
};

export type RouteAssignedRepInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly employeeId: Scalars['ID'];
  readonly employeeGroupId?: InputMaybe<Scalars['ID']>;
  readonly employeeOfficeId?: InputMaybe<Scalars['ID']>;
  readonly main: Scalars['Boolean'];
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly typeId: Scalars['ID'];
};

export type RouteAssignedRepsError = {
  readonly __typename?: 'RouteAssignedRepsError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type RouteAssignedRepsPayload = {
  readonly __typename?: 'RouteAssignedRepsPayload';
  readonly errors: ReadonlyArray<RouteAssignedRepsError>;
  readonly reps?: Maybe<ReadonlyArray<RouteAssignedRep>>;
};

export type RouteBillableMileage = {
  readonly __typename?: 'RouteBillableMileage';
  readonly billableStops?: Maybe<ReadonlyArray<BillableRouteStop>>;
  readonly isBackhaul?: Maybe<Scalars['Boolean']>;
  readonly backhaulOrderId?: Maybe<Scalars['String']>;
};

/** RouteCommission */
export type RouteCommissionModel = {
  readonly __typename?: 'RouteCommissionModel';
  readonly commissionAmount?: Maybe<Scalars['Decimal']>;
  readonly employeeId?: Maybe<Scalars['ID']>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly carrierRepTypeId?: Maybe<Scalars['String']>;
  readonly commissionPercent?: Maybe<Scalars['Decimal']>;
  readonly commissionBasisId?: Maybe<Scalars['String']>;
  readonly commissionBasisAmount?: Maybe<Scalars['Decimal']>;
  readonly marginBasisId?: Maybe<Scalars['String']>;
  readonly marginBasisPercent?: Maybe<Scalars['Decimal']>;
  readonly displayCommissionType?: Maybe<Scalars['String']>;
  readonly commissionBasisFlatAmount?: Maybe<Scalars['Decimal']>;
  readonly commissionBasisPercentAmount?: Maybe<Scalars['Decimal']>;
  readonly employee?: Maybe<Employee>;
  readonly employeeV2?: Maybe<EmployeeV2>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly route?: Maybe<LoadRoute>;
  readonly routeV2?: Maybe<LoadRouteV2>;
};

export type RouteDat = {
  readonly route: Scalars['String'];
  readonly availableTime: Scalars['String'];
  readonly remainingOnDutyMinutes: Scalars['Int'];
  readonly remainingShiftDriveMinutes: Scalars['Int'];
  readonly breakTaken: Scalars['Boolean'];
  readonly calendarType: Scalars['String'];
  readonly onDutyDayStart: Scalars['Int'];
  readonly haversineOverride: Scalars['Float'];
  readonly defaultMph: Scalars['Float'];
  readonly defaultMphMileThreshold: Scalars['Float'];
  readonly ignoreHos: Scalars['Boolean'];
  readonly haversineMesh: Scalars['Float'];
  readonly driveMinuteMesh: Scalars['Int'];
};

export type RouteDefaults = {
  readonly __typename?: 'RouteDefaults';
  readonly primaryDivision?: Maybe<OrderProfileKeyValue>;
  readonly additionalDivisions?: Maybe<ReadonlyArray<OrderProfileKeyValue>>;
};

export type RouteDefaultsInput = {
  readonly primaryDivisionId?: InputMaybe<Scalars['ID']>;
  readonly additionalDivisionIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type RouteDimensions = {
  readonly __typename?: 'RouteDimensions';
  readonly length: Length;
  readonly height?: Maybe<Length>;
  readonly width?: Maybe<Length>;
};


export type RouteDimensionsLengthArgs = {
  unit: UnitOfLengthEnum;
};


export type RouteDimensionsHeightArgs = {
  unit: UnitOfLengthEnum;
};


export type RouteDimensionsWidthArgs = {
  unit: UnitOfLengthEnum;
};

export type RouteInput = {
  readonly routeId: Scalars['ID'];
  readonly routeCode: Scalars['String'];
  readonly loadId: Scalars['ID'];
};

export type RouteLaneMatch = {
  readonly __typename?: 'RouteLaneMatch';
  readonly routeId: Scalars['ID'];
  readonly routeCode: Scalars['String'];
  readonly laneId?: Maybe<Scalars['ID']>;
  readonly laneCode?: Maybe<Scalars['String']>;
  readonly matchSequence?: Maybe<Scalars['Int']>;
};

/** In order to match a lane with Region stops, the CustomerId or CarrierId of the Region's RegionSet must be supplied. */
export type RouteLaneMatchInput = {
  readonly routeId: Scalars['ID'];
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly routeCode: Scalars['String'];
  readonly trailerTypeIds: ReadonlyArray<Scalars['ID']>;
  readonly minLength?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minHeight?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly transportMode?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly serviceLevel?: InputMaybe<Scalars['String']>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly stops: ReadonlyArray<RouteLaneMatchStopInput>;
};

export type RouteLaneMatchStopInput = {
  readonly stopType: Scalars['String'];
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
};

export enum RouteLifeCycleStatusEnum {
  Complete = 'COMPLETE',
  Covered = 'COVERED',
  Dispatched = 'DISPATCHED',
  None = 'NONE',
  Open = 'OPEN',
  /** @deprecated Field no longer supported */
  Unmapped = 'UNMAPPED',
  Arrivedstop = 'ARRIVEDSTOP',
  Departedstop = 'DEPARTEDSTOP',
  Pending = 'PENDING',
  Pretender = 'PRETENDER',
  Driverassigned = 'DRIVERASSIGNED',
  Tendered = 'TENDERED'
}

export type RouteMatch = {
  readonly __typename?: 'RouteMatch';
  readonly id: Scalars['ID'];
  readonly routeId: Scalars['String'];
  readonly loadId: Scalars['String'];
  readonly loadNumber: Scalars['String'];
  readonly capacityNeed?: Maybe<ReadonlyArray<CapacityNeed>>;
  readonly actedUpon?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly originDeadheadMileage?: Maybe<Scalars['Float']>;
  readonly destinationDeadheadMileage?: Maybe<Scalars['Float']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly routePickupCity?: Maybe<Scalars['String']>;
  readonly routePickupState?: Maybe<Scalars['String']>;
  readonly routePickupCountry?: Maybe<Scalars['String']>;
  readonly routePickupStartDay?: Maybe<Scalars['String']>;
  /** Date and timezone for route pickup start date */
  readonly routePickupStartDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `routePickupStartDateZone` */
  readonly routePickupStartDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `routePickupStartDateZone` and `routePickupEndDateZone` */
  readonly routePickupTimeZone?: Maybe<Scalars['String']>;
  readonly routePickupEndDay?: Maybe<Scalars['String']>;
  /** Date and timezone for route pickup end date */
  readonly routePickupEndDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `routePickupEndDateZone` */
  readonly routePickupEndDate?: Maybe<Scalars['DateTime']>;
  readonly routeFirstStopDateRange?: Maybe<AvailableDateRange>;
  readonly routeFirstStopLiveType?: Maybe<ScheduleDropTypeEnum>;
  readonly routeDeliveryCity?: Maybe<Scalars['String']>;
  readonly routeDeliveryState?: Maybe<Scalars['String']>;
  readonly routeDeliveryCountry?: Maybe<Scalars['String']>;
  readonly routeDeliveryStartDay?: Maybe<Scalars['String']>;
  /** Date and timezone for route delivery start date */
  readonly routeDeliveryStartDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `routeDeliveryStartDateZone` */
  readonly routeDeliveryStartDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `routeDeliveryStartDateZone` and `routeDeliveryEndDateZone` */
  readonly routeDeliveryTimeZone?: Maybe<Scalars['String']>;
  readonly routeDeliveryEndDay?: Maybe<Scalars['String']>;
  /** Date and timezone for route delivery end date */
  readonly routeDeliveryEndDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `routeDeliveryEndDateZone` */
  readonly routeDeliveryEndDate?: Maybe<Scalars['DateTime']>;
  readonly routeLastStopDateRange?: Maybe<AvailableDateRange>;
  readonly routeLastStopLiveType?: Maybe<ScheduleDropTypeEnum>;
  readonly routeLength?: Maybe<Scalars['Int']>;
  readonly routeTrailerLength?: Maybe<Length>;
  readonly routeCommodityMinTemperature?: Maybe<TemperatureV2>;
  readonly routeAssignedReps?: Maybe<ReadonlyArray<AvailableRouteRep>>;
  readonly routeCustomerOrderReps?: Maybe<ReadonlyArray<AvailableRouteRep>>;
  readonly stopCount?: Maybe<Scalars['Int']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly routeTrailerTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly segmentCode?: Maybe<Scalars['String']>;
  readonly routeDivisions?: Maybe<ReadonlyArray<AvailableRouteDivision>>;
  readonly requirements?: Maybe<ReadonlyArray<OrderRequirementInfo>>;
  readonly hotRoute?: Maybe<Scalars['Boolean']>;
  readonly tmsType?: Maybe<Scalars['String']>;
  readonly mainCustomerRepEmployeeId?: Maybe<Scalars['String']>;
  readonly expectedMaxWeight?: Maybe<Scalars['Float']>;
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly equipment?: Maybe<Scalars['String']>;
  readonly routeLoadRouteSequence?: Maybe<Fraction>;
  readonly routeAppointmentStatus?: Maybe<Scalars['String']>;
  readonly routeLifecycleStatus?: Maybe<Scalars['String']>;
  readonly routeOrganizationName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'routeCustomerReps' */
  readonly customerRepName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'routeCustomerReps' */
  readonly customerRepId?: Maybe<Scalars['String']>;
  /** @deprecated Use 'routeDivisions' */
  readonly division?: Maybe<Scalars['String']>;
};

export type RouteMatchedLane = {
  readonly __typename?: 'RouteMatchedLane';
  readonly laneId: Scalars['ID'];
  /** The sequence of the lane in the route match run result. Lower sequence means more granular. */
  readonly sequence: Scalars['Int'];
  readonly laneType?: Maybe<Scalars['String']>;
};

export type RouteMatchedLanesInput = {
  readonly routeId: Scalars['ID'];
};

export type RouteMaxCostInputType = {
  readonly routeId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly maxCost?: InputMaybe<Scalars['Decimal']>;
  readonly overMax?: InputMaybe<Scalars['Decimal']>;
  readonly isUserOverride?: InputMaybe<Scalars['Boolean']>;
};

export type RouteMaxCostType = {
  readonly __typename?: 'RouteMaxCostType';
  readonly routeId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly maxCost?: Maybe<Scalars['Decimal']>;
  readonly overMax?: Maybe<Scalars['Decimal']>;
  readonly code?: Maybe<Scalars['String']>;
};

export type RouteOffer = {
  readonly __typename?: 'RouteOffer';
  readonly id: Scalars['String'];
  readonly carrierId: Scalars['String'];
  readonly type: Scalars['String'];
  readonly status: Scalars['String'];
  readonly offerPrice?: Maybe<OfferPrice>;
  readonly counterPrice?: Maybe<OfferPrice>;
  readonly updatedAt: Scalars['Timestamp'];
};

export type RouteOutput = {
  readonly __typename?: 'RouteOutput';
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly loadId?: Maybe<Scalars['ID']>;
};

export type RouteOverride = {
  readonly __typename?: 'RouteOverride';
  readonly routeId: Scalars['ID'];
  readonly totalCost: Scalars['Decimal'];
};

export type RouteOverrideInput = {
  readonly routeId: Scalars['ID'];
  readonly totalCost: Scalars['Decimal'];
};

export type RoutePathDat = {
  readonly route: Scalars['String'];
  readonly location: Scalars['String'];
  readonly leg: Scalars['Int'];
  readonly lowerWindow?: InputMaybe<Scalars['String']>;
  readonly upperWindow?: InputMaybe<Scalars['String']>;
  readonly serviceMinutes?: InputMaybe<Scalars['Int']>;
  readonly offDutyMinutes?: InputMaybe<Scalars['Int']>;
};

export type RoutePayableMileage = {
  readonly __typename?: 'RoutePayableMileage';
  /** routeId */
  readonly id: Scalars['ID'];
  readonly totalLoadedPayableDistance?: Maybe<LengthV2>;
  readonly totalPayableDistance?: Maybe<LengthV2>;
  readonly overriddenTotalDistance?: Maybe<LengthV2>;
  readonly overriddenTotalLoadedDistance?: Maybe<LengthV2>;
  readonly payableStops: ReadonlyArray<PayableRouteStop>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
};

export type RoutePayableMileageError = {
  readonly __typename?: 'RoutePayableMileageError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly serviceError?: Maybe<Scalars['JSON']>;
};

export type RoutePosting = {
  readonly __typename?: 'RoutePosting';
  readonly id: Scalars['String'];
  readonly active: Scalars['Boolean'];
  readonly postedRate?: Maybe<Currency>;
  readonly references: ReadonlyArray<RoutePostingReference>;
};

export type RoutePostingReference = {
  readonly __typename?: 'RoutePostingReference';
  readonly id: Scalars['String'];
  readonly status?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
};

export type RoutePreviewType = {
  readonly __typename?: 'RoutePreviewType';
  readonly id: Scalars['String'];
  readonly code?: Maybe<Scalars['Float']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly routeStops: ReadonlyArray<RouteStopPreviewType>;
  readonly loadId: Scalars['String'];
  readonly sequence: Scalars['Float'];
  readonly firstStop?: Maybe<RouteStopPreviewType>;
  readonly lastStop?: Maybe<RouteStopPreviewType>;
};

export type RouteReference = {
  readonly __typename?: 'RouteReference';
  readonly id?: Maybe<Scalars['ID']>;
  readonly type: KeyValue;
  readonly refNumber: Scalars['String'];
  readonly routeId: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type RouteReferenceInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeId: Scalars['ID'];
  readonly refNumber: Scalars['String'];
  readonly routeId?: InputMaybe<Scalars['ID']>;
};

export type RouteReferencesError = {
  readonly __typename?: 'RouteReferencesError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type RouteReferencesPayload = {
  readonly __typename?: 'RouteReferencesPayload';
  readonly errors: ReadonlyArray<RouteReferencesError>;
  readonly refs?: Maybe<ReadonlyArray<RouteReference>>;
};

export type RouteRequirement = {
  readonly __typename?: 'RouteRequirement';
  readonly option: KeyValue;
};

export type RouteRequirementInput = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly metadataJson: Scalars['String'];
};

export type RouteResources = {
  readonly __typename?: 'RouteResources';
  /** Power record corresponding to the `tractorIdentifier` of RouteResources with `ResourceType` of `fleet` */
  readonly assetPower?: Maybe<AssetPowerOutput>;
  /** Carrier booked to the route */
  readonly carrierV2?: Maybe<CarrierV2>;
  /** Also referred to as `Next Call Back Time` */
  readonly checkCallDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly dispatched: Scalars['Boolean'];
  readonly dispatchedBy?: Maybe<RouteResourcesEmployeeMeta>;
  readonly dispatchedByEmployeeV2?: Maybe<EmployeeV2>;
  readonly drivers: ReadonlyArray<RouteResourcesDriver>;
  /** Electronic Logging Device identifier used for 3rd party tracking */
  readonly eldVehicleID?: Maybe<Scalars['String']>;
  readonly dispatchedEmptyDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly dispatchedEmptyLocation?: Maybe<Location>;
  readonly dispatchedEmptyTimezone?: Maybe<Scalars['String']>;
  readonly emptyDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly emptyLocation?: Maybe<Location>;
  readonly emptyTimezone?: Maybe<Scalars['String']>;
  readonly etaCreatedByV2?: Maybe<UserV2>;
  /** Estimated Time of Arrival for driver at the first stop of the route */
  readonly etaDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly etaTimezone?: Maybe<Scalars['String']>;
  readonly externalInformation?: Maybe<RouteResourcesExternalInformation>;
  readonly hasTrackingRequestFailed: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly inboundTrailers: ReadonlyArray<RouteResourcesTrailerV2>;
  readonly loadedTrailers: ReadonlyArray<RouteResourcesTrailerV2>;
  readonly outboundTrailers: ReadonlyArray<RouteResourcesTrailerV2>;
  /** Indicates if the `assetPower` was autofilled by the permanent power record of driver 1 */
  readonly isAssetPowerAutofilled: Scalars['Boolean'];
  readonly isDriverAssigned: Scalars['Boolean'];
  /** Indicates if a driver is required to dispatch the given route */
  readonly isDriverRequired: Scalars['Boolean'];
  readonly isETADataScienceFormula: Scalars['Boolean'];
  readonly notes?: Maybe<Scalars['String']>;
  /** Corresponds to a `Driver Reload Intent` Minion DDT Option ID */
  readonly reloadIntent?: Maybe<KeyValue>;
  /** RouteResources with `resourceType` of `fleet` may contain `assetDriver` and `assetPower` records */
  readonly resourceType: RouteResourceTypeEnum;
  readonly routeID: Scalars['ID'];
  readonly trackingEnabled: Scalars['Boolean'];
  readonly trackingEnabledBy?: Maybe<RouteResourcesEmployeeMeta>;
  readonly trackingStatusMessage?: Maybe<Scalars['String']>;
  readonly trackingSystem?: Maybe<TrackingSystem>;
  readonly tractorIdentifier?: Maybe<Scalars['String']>;
  readonly trailerLocation?: Maybe<Location>;
  readonly trailerLocationFacilityV2?: Maybe<FacilityV2>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
};

/** type for driver information */
export type RouteResourcesDriver = {
  readonly __typename?: 'RouteResourcesDriver';
  /** Driver record corresponding to the `displayName` of RouteResources drivers with `ResourceType` of `fleet` */
  readonly assetDriver?: Maybe<AssetDriver>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  /** Begins with `1`. Maximum of 2 */
  readonly sequenceNumber: Scalars['Int'];
};

/** type for driver input */
export type RouteResourcesDriverInput = {
  /** supports driver numbers 1 or 2 */
  readonly sequenceNumber: Scalars['Int'];
  readonly displayName?: InputMaybe<Scalars['String']>;
  readonly assetDriverID?: InputMaybe<Scalars['ID']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
};

/** This is a temporary type until we complete work to resolve employees across the graph */
export type RouteResourcesEmployeeMeta = {
  readonly __typename?: 'RouteResourcesEmployeeMeta';
  readonly displayName: Scalars['String'];
  readonly employeeID: Scalars['ID'];
};

/** this is a temporary type until we can reference users across the graph */
export type RouteResourcesEmployeeMetaInput = {
  readonly employeeID: Scalars['ID'];
  readonly displayName?: InputMaybe<Scalars['String']>;
};

/** Information received from external source on create/update */
export type RouteResourcesExternalInformation = {
  readonly __typename?: 'RouteResourcesExternalInformation';
  readonly dispatcherEmail?: Maybe<Scalars['String']>;
  readonly dispatcherName?: Maybe<Scalars['String']>;
  readonly dispatcherPhone?: Maybe<Scalars['String']>;
  readonly sourceSystem?: Maybe<Scalars['String']>;
};

/** input for external info on route resources create/update */
export type RouteResourcesExternalInformationInput = {
  readonly sourceSystem?: InputMaybe<Scalars['String']>;
  readonly dispatcherName?: InputMaybe<Scalars['String']>;
  readonly dispatcherPhone?: InputMaybe<Scalars['String']>;
  readonly dispatcherEmail?: InputMaybe<Scalars['String']>;
};

export type RouteResourcesFilterInput = {
  readonly assetDriverIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly phoneNumbers?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type RouteResourcesTrailer = {
  readonly __typename?: 'RouteResourcesTrailer';
  readonly type?: Maybe<Scalars['ID']>;
  readonly ownerV2?: Maybe<CarrierV2>;
  readonly heightInInches?: Maybe<Scalars['Float']>;
  readonly lengthInFeet?: Maybe<Scalars['Float']>;
  readonly numberOfPallets?: Maybe<Scalars['Float']>;
  readonly palletType?: Maybe<Scalars['String']>;
  readonly sequenceNumber: Scalars['Float'];
  readonly areAccessoriesVisible: Scalars['Boolean'];
  readonly identifier?: Maybe<Scalars['String']>;
  readonly widthInInches?: Maybe<Scalars['Float']>;
  readonly tarpType?: Maybe<Scalars['String']>;
  readonly numberOfTarps?: Maybe<Scalars['Float']>;
  readonly bracings: ReadonlyArray<RouteResourcesTrailerBracing>;
  readonly numberOfBracings?: Maybe<Scalars['Float']>;
};

export type RouteResourcesTrailerBracing = {
  readonly __typename?: 'RouteResourcesTrailerBracing';
  readonly type: Scalars['String'];
};

export type RouteResourcesTrailerBracingInput = {
  readonly type: Scalars['String'];
};

export type RouteResourcesTrailerBracingV2 = {
  readonly __typename?: 'RouteResourcesTrailerBracingV2';
  readonly type: Scalars['String'];
};

export type RouteResourcesTrailerBracingV2Input = {
  readonly type: Scalars['String'];
};

export type RouteResourcesTrailerInput = {
  readonly type?: InputMaybe<Scalars['ID']>;
  readonly heightInInches?: InputMaybe<Scalars['Float']>;
  readonly lengthInFeet?: InputMaybe<Scalars['Float']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Float']>;
  /** ID of the Carrier who owns the trailer, if any */
  readonly ownerID?: InputMaybe<Scalars['String']>;
  readonly palletType?: InputMaybe<Scalars['String']>;
  readonly sequenceNumber: Scalars['Float'];
  readonly areAccessoriesVisible: Scalars['Boolean'];
  readonly identifier?: InputMaybe<Scalars['String']>;
  readonly tarpType?: InputMaybe<Scalars['String']>;
  readonly numberOfTarps?: InputMaybe<Scalars['Float']>;
  readonly widthInInches?: InputMaybe<Scalars['Float']>;
  readonly numberOfBracings?: InputMaybe<Scalars['Float']>;
  readonly bracings?: InputMaybe<ReadonlyArray<RouteResourcesTrailerBracingInput>>;
};

export type RouteResourcesTrailerV2 = {
  readonly __typename?: 'RouteResourcesTrailerV2';
  /** Indicates whether accessories fields (bracings, pallets, tarps) are visible in the UI */
  readonly areAccessoriesVisible: Scalars['Boolean'];
  readonly assetTrailer?: Maybe<AssetTrailers>;
  readonly bracings: ReadonlyArray<RouteResourcesTrailerBracingV2>;
  readonly heightInInches?: Maybe<Scalars['Float']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly lengthInFeet?: Maybe<Scalars['Float']>;
  readonly numberOfBracings?: Maybe<Scalars['Float']>;
  readonly numberOfPallets?: Maybe<Scalars['Float']>;
  readonly numberOfTarps?: Maybe<Scalars['Float']>;
  readonly ownerV2?: Maybe<CarrierV2>;
  readonly palletType?: Maybe<Scalars['String']>;
  readonly resourceType: RouteResourceTypeEnum;
  /** Begins with `1`. Maximum of 3 */
  readonly sequenceNumber: Scalars['Float'];
  readonly tarpType?: Maybe<Scalars['String']>;
  /** Corresponds to a `Trailer Type` Minion DDT Option ID */
  readonly type?: Maybe<Scalars['ID']>;
  readonly widthInInches?: Maybe<Scalars['Float']>;
};

export type RouteResourcesTrailerV2Input = {
  readonly areAccessoriesVisible: Scalars['Boolean'];
  readonly assetTrailersID?: InputMaybe<Scalars['ID']>;
  readonly bracings?: InputMaybe<ReadonlyArray<RouteResourcesTrailerBracingV2Input>>;
  readonly heightInInches?: InputMaybe<Scalars['Float']>;
  readonly identifier?: InputMaybe<Scalars['String']>;
  readonly lengthInFeet?: InputMaybe<Scalars['Float']>;
  readonly numberOfBracings?: InputMaybe<Scalars['Int']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  readonly numberOfTarps?: InputMaybe<Scalars['Int']>;
  /** ID of the Carrier who owns the trailer, if any */
  readonly ownerID?: InputMaybe<Scalars['String']>;
  readonly palletType?: InputMaybe<Scalars['String']>;
  /** Defaults to `carrier` */
  readonly resourceType?: InputMaybe<RouteResourceTypeEnum>;
  readonly sequenceNumber: Scalars['Int'];
  readonly tarpType?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Scalars['ID']>;
  readonly widthInInches?: InputMaybe<Scalars['Float']>;
};

/** Denotes whether driver, power, and trailers are identified by freeform identifiers (`carrier`) or record objects (`fleet`). `resourceType` on `RouteResources` indicates the resource type of the route's drivers and power unit. `resourceType` on `RouteResourcesTrailerV2` indicates the resource type of the given trailer. All of these `resourcesType` fields operate independently of each other. */
export enum RouteResourceTypeEnum {
  Carrier = 'Carrier',
  Fleet = 'Fleet'
}

export type RoutesByDriverPayInfoFilterInput = {
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly payProgramId?: InputMaybe<Scalars['ID']>;
  readonly payRateTableId?: InputMaybe<Scalars['ID']>;
  readonly isVouchered?: InputMaybe<Scalars['Boolean']>;
};

export type RoutesInfo = {
  readonly __typename?: 'RoutesInfo';
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly routeTenderStatus?: Maybe<Scalars['String']>;
};

export type RoutesQueryInput = {
  /** Search/Filter by ComputedCurrentAppointment status */
  readonly appointmentStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by appt_conf_by */
  readonly apptConfBy?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by appt_req_with */
  readonly apptReqWith?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter array of group ids */
  readonly byCustomerEmployeeGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Filter by order reps */
  readonly byOrderRepIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Filter by route reps */
  readonly byRouteRepIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter array of route_vendor ids */
  readonly carrierRouteVendorIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/Filter by code or partial code */
  readonly code?: InputMaybe<Scalars['String']>;
  /** Search/Filter by customer ids */
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search by delivery address */
  readonly deliveryAddress?: InputMaybe<AddressesFilter>;
  /** Start range ending */
  readonly endDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Search/Filter by available_start_date or req_date range ending */
  readonly endDateReqDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Search/Filter by facility id */
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  /** Search/Filter by first stop address */
  readonly firstStopAddress?: InputMaybe<AddressesFilter>;
  /** Search by inbound regions (DEPRECATED) */
  readonly inboundRegion?: InputMaybe<RegionFilter>;
  /** Search by Inbound (destination) region */
  readonly inboundRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search by Inbound (destination) sub region */
  readonly inboundSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search by Inbound (destination) super region */
  readonly inboundSuperRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by last stop address */
  readonly lastStopAddress?: InputMaybe<AddressesFilter>;
  /** Search/Filter by Main Customer Rep */
  readonly mainCustomerRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/Filter by Order Activation Status */
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by Order Tender Status */
  readonly orderTenderStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search by inbound regions (DEPRECATED) */
  readonly outboundRegion?: InputMaybe<RegionFilter>;
  /** Search by outbound(origin) regions */
  readonly outboundRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search by outbound(origin) sub regions */
  readonly outboundSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search by outbound(origin) super regions */
  readonly outboundSuperRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search by pick_up address */
  readonly pickUpAddress?: InputMaybe<AddressesFilter>;
  /** Search/Filter by req_ship_date */
  readonly reqDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Search/Filter by Route Activation Status */
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/filter route rep AND order rep ids */
  readonly routeRepAndOrderRepIds?: InputMaybe<RepIdsFilter>;
  /** Search/Filter route rep OR order rep ids */
  readonly routeRepOrOrderRepIds?: InputMaybe<RepIdsFilter>;
  /** Search/Filter by schedule_drop_type */
  readonly scheduleDropType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by facility scheduling system type */
  readonly schedulingSystemType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Start range beginning */
  readonly startDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Search/Filter by available_start_date or req_date range beginning */
  readonly startDateReqDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Route Life Cycle Status */
  readonly status?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search by stop address */
  readonly stopAddress?: InputMaybe<AddressesFilter>;
  /** Search/Filter by available_start_date or available_end_date */
  readonly stopDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Filter by stops.schedule_appointment_type with null time interval */
  readonly stopScheduleAppointment?: InputMaybe<Scalars['String']>;
  /** Filter by stops.schedule_appointment_type with null time interval ['appt', 'notice'] */
  readonly stopScheduleAppointments?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by stop_type */
  readonly stopType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by Trailer Type */
  readonly trailerType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by Transport Mode */
  readonly transportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** lachesis-api: Route Status Record V3 - Features Multi-Route Functionality */
export type RouteStatusRecordV3 = {
  readonly __typename?: 'RouteStatusRecordV3';
  /** lachesis-api: ID associated with a Load. */
  readonly load_id: Scalars['ID'];
  /** lachesis-api: Timestamp when event occured */
  readonly event_timestamp: Scalars['Timestamp'];
  /** lachesis-api: ID of the user or process that initiated the event for status calculation */
  readonly initiator?: Maybe<Scalars['String']>;
  /** lachesis-api: ID of the route associated with the load. */
  readonly route_id: Scalars['ID'];
  /** lachesis-api: Timestamp when the status record was created */
  readonly created_at_timestamp: Scalars['Timestamp'];
  /** lachesis-api: ID of carrier associated to the route */
  readonly carrier_id?: Maybe<Scalars['ID']>;
  /** lachesis-api: Current route tender status for the associated route */
  readonly route_tender_status?: Maybe<Scalars['String']>;
  /** lachesis-api: Current route activation status for the associated route */
  readonly route_activation_status?: Maybe<Scalars['String']>;
  /** lachesis-api: Current route lifecycle status for the associated route */
  readonly route_lifecycle_status?: Maybe<Scalars['String']>;
  /** lachesis-api: Current stop number that contributed to status calculation. */
  readonly route_stop_number?: Maybe<Scalars['Int']>;
  /** lachesis-api: Array of Vendor Financial Statuses. */
  readonly route_vendor_financial_statuses: ReadonlyArray<RouteVendorFinancialStatusV3>;
};

export type RouteStop = {
  readonly __typename?: 'RouteStop';
  readonly id: Scalars['ID'];
  readonly loadStopType?: Maybe<Scalars['String']>;
  readonly liveType?: Maybe<ScheduleDropTypeEnum>;
  readonly route: LoadRouteV2;
  readonly stopEventsV2: ReadonlyArray<StopEventV2>;
  /** @deprecated INCORRECT TYPE, USE stopEventsV2 */
  readonly stopSubEvents: ReadonlyArray<StopEvent>;
  readonly usableStopEvents: StopEventEngineOutput;
  /** DEPRECATED - DO NOT USE */
  readonly trackingETA?: Maybe<TrackingEta>;
  readonly trackingAutoETADatetime?: Maybe<Scalars['DateTime']>;
  readonly trackingManualETADatetime?: Maybe<Scalars['DateTime']>;
  readonly trackingAutoETAUsesDataScienceFormula?: Maybe<Scalars['Boolean']>;
  readonly facilityId: Scalars['ID'];
  readonly facility: Facility;
  readonly facilityV2: FacilityV2;
  readonly note?: Maybe<Scalars['String']>;
  readonly scheduleType?: Maybe<ScheduleAppointmentTypeEnum>;
  readonly workType?: Maybe<ScheduleWorkTypeEnum>;
  readonly isOrderStop?: Maybe<Scalars['Boolean']>;
  /** @deprecated use loadStopType as it accounts for multi-route stop types, if loadStopType is present and its value is not a StopTypeEnum, this value defaults to 'Pu' */
  readonly type: StopTypeEnum;
  readonly loadFrom?: Maybe<KeyValue>;
  readonly unloadFrom?: Maybe<KeyValue>;
  readonly reqDate?: Maybe<DatetimeWithTimezone>;
  readonly availableStart?: Maybe<DatetimeWithTimezone>;
  readonly availableStartTimes?: Maybe<ScheduleRange>;
  readonly availableEnd?: Maybe<DatetimeWithTimezone>;
  readonly availableEndTimes?: Maybe<ScheduleRange>;
  readonly requirements?: Maybe<ReadonlyArray<StopRequirement>>;
  readonly commodities?: Maybe<ReadonlyArray<CommodityV2>>;
  readonly commoditiesV2?: Maybe<ReadonlyArray<StopCommodityFromRouteStop>>;
  /** @deprecated RegionV1 is deprecated, please use freightAssignmentRegion instead */
  readonly superRegion?: Maybe<Region>;
  /** @deprecated RegionV1 is deprecated, please use freightAssignmentRegion instead */
  readonly region?: Maybe<Region>;
  /** @deprecated RegionV1 is deprecated, please use freightAssignmentRegion instead */
  readonly subRegion?: Maybe<Region>;
  readonly address?: Maybe<StopAddress>;
  readonly orderStopId?: Maybe<Scalars['ID']>;
  readonly refs?: Maybe<ReadonlyArray<RefV2>>;
  readonly bolNumber?: Maybe<Scalars['String']>;
  readonly appointment?: Maybe<AppointmentV2>;
  /** @deprecated Use new field schedulingLog instead */
  readonly appointments?: Maybe<ReadonlyArray<AppointmentV2>>;
  readonly schedulingLog?: Maybe<ReadonlyArray<SchedulingLog>>;
  readonly sealNumber?: Maybe<Scalars['String']>;
  readonly computedCurrentAppointment?: Maybe<AppointmentV2>;
  readonly schedulingContactId?: Maybe<Scalars['ID']>;
  readonly schedulingContact?: Maybe<Contact>;
  readonly schedulingContactV2?: Maybe<FacilityContact>;
  readonly apptConfBy?: Maybe<Scalars['String']>;
  readonly apptReqWith?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  /** Stop Unique Identifier for external system */
  readonly externalRef?: Maybe<Scalars['ID']>;
  readonly appointmentChangeReason?: Maybe<Scalars['ID']>;
  readonly freightAssignmentRegion?: Maybe<FreightAssignmentRegion>;
  readonly numberOfPallets?: Maybe<Scalars['Int']>;
  readonly isCreatedManually?: Maybe<Scalars['Boolean']>;
  readonly splc?: Maybe<Scalars['String']>;
  /** PU/Del Codes from Customer-Facility Relationship */
  readonly externalFacilityId?: Maybe<Scalars['String']>;
  readonly assignedRouteStopShipments?: Maybe<ReadonlyArray<AssignedRouteStopShipment>>;
  readonly driverTargetStart?: Maybe<DatetimeWithTimezone>;
  readonly driverTargetStartTimes?: Maybe<ScheduleRange>;
  readonly driverTargetEnd?: Maybe<DatetimeWithTimezone>;
  readonly driverTargetEndTimes?: Maybe<ScheduleRange>;
  readonly driverTargetAddedBy?: Maybe<UserV2>;
  readonly driverTargetAddedAt?: Maybe<Scalars['DateTime']>;
  readonly driverTargetLastUpdatedBy?: Maybe<UserV2>;
  readonly driverTargetLastUpdatedAt?: Maybe<Scalars['DateTime']>;
  readonly commodityAction?: Maybe<CommodityActionEnum>;
  readonly commodityPhase?: Maybe<CommodityPhaseEnum>;
  readonly isOutgoingLoaded?: Maybe<Scalars['Boolean']>;
  readonly freightAssignmentAdditionalRegion?: Maybe<ReadonlyArray<FreightAssignmentRegion>>;
  readonly orderStopDesignation?: Maybe<Scalars['String']>;
  readonly distanceToNext?: Maybe<Length>;
};


export type RouteStopUsableStopEventsArgs = {
  routeType: Scalars['String'];
};


export type RouteStopDistanceToNextArgs = {
  unit: UnitOfLengthEnum;
};

export type RouteStopDetails = {
  readonly __typename?: 'RouteStopDetails';
  readonly appointment?: Maybe<StopAppointment>;
  readonly driverTargetAppointment?: Maybe<DriverTargetAppointment>;
  readonly driverWork?: Maybe<Scalars['String']>;
  readonly liveLoadType?: Maybe<Scalars['String']>;
  readonly loadUnloadFrom?: Maybe<Scalars['String']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly refs?: Maybe<ReadonlyArray<Maybe<StopRef>>>;
  readonly requirements?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly stopType?: Maybe<Scalars['String']>;
  readonly scheduleType?: Maybe<Scalars['String']>;
  readonly bolNumber?: Maybe<Scalars['String']>;
  readonly sealNumber?: Maybe<Scalars['String']>;
  readonly distanceToNext?: Maybe<DistanceToNext>;
  readonly truckDetails?: Maybe<TruckDetails>;
};

export type RouteStopError = {
  readonly __typename?: 'RouteStopError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly serviceError?: Maybe<Scalars['JSON']>;
};

export type RouteStopInfo = {
  readonly __typename?: 'RouteStopInfo';
  readonly id: Scalars['String'];
  readonly loadStopType?: Maybe<Scalars['String']>;
  readonly address: Scalars['String'];
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly point?: Maybe<Point>;
  /** Date and timezone for route start date */
  readonly startDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `startDateZone` */
  readonly startDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `startDateZone` */
  readonly startDateTimeZone?: Maybe<Scalars['String']>;
  /** Date and timezone for route end date */
  readonly endDateZone?: Maybe<DatetimeWithTimezone>;
  /** @deprecated Use `endDateZone` */
  readonly endDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `endDateZone` */
  readonly endDateTimeZone?: Maybe<Scalars['String']>;
  readonly procurementAppointmentDate?: Maybe<AvailableDateRange>;
  readonly regionUrn?: Maybe<Scalars['String']>;
  readonly subRegionUrn?: Maybe<Scalars['String']>;
  readonly freightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly freightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly freightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly liveType?: Maybe<ScheduleDropTypeEnum>;
  readonly scheduleType?: Maybe<Scalars['String']>;
  readonly facilityId?: Maybe<Scalars['String']>;
  readonly facilityName: Scalars['String'];
  readonly appointmentStatus?: Maybe<Scalars['String']>;
  readonly totalCommodityWeight?: Maybe<Mass>;
  readonly commoditiesDescription?: Maybe<Scalars['String']>;
  readonly compositeRegionKey?: Maybe<Scalars['String']>;
  readonly stopReferences?: Maybe<ReadonlyArray<AvailableRouteReference>>;
};

export type RouteStopInput = {
  readonly routeId: Scalars['ID'];
  readonly facilityId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
  readonly isOrderStop?: InputMaybe<Scalars['Boolean']>;
  readonly type?: InputMaybe<StopTypeEnum>;
  readonly loadStopType?: InputMaybe<Scalars['String']>;
  readonly loadFromId?: InputMaybe<Scalars['ID']>;
  readonly unloadFromId?: InputMaybe<Scalars['ID']>;
  readonly requirements?: InputMaybe<ReadonlyArray<StopRequirementInput>>;
  readonly commodities?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly orderStopId?: InputMaybe<Scalars['ID']>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInputV2>>;
  readonly reqDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly bolNumber?: InputMaybe<Scalars['String']>;
  readonly sealNumber?: InputMaybe<Scalars['String']>;
  readonly schedulingContactId?: InputMaybe<Scalars['ID']>;
  readonly apptConfBy?: InputMaybe<Scalars['String']>;
  readonly apptReqWith?: InputMaybe<Scalars['String']>;
  /** Stop Unique Identifier for external system */
  readonly externalRef?: InputMaybe<Scalars['String']>;
  readonly appointmentChangeReason?: InputMaybe<Scalars['String']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  readonly isCreatedManually?: InputMaybe<Scalars['Boolean']>;
  readonly splc?: InputMaybe<Scalars['String']>;
  /** PU/Del Codes from Customer-Facility Relationship */
  readonly externalFacilityId?: InputMaybe<Scalars['String']>;
  readonly assignedShipments?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverTargetStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly driverTargetEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly driverTargetStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly driverTargetEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly orderStopDesignation?: InputMaybe<Scalars['String']>;
};

export type RouteStopPayload = {
  readonly __typename?: 'RouteStopPayload';
  readonly errors: ReadonlyArray<RouteStopError>;
  readonly routeStop?: Maybe<RouteStop>;
};

export type RouteStopPayloadV2 = {
  readonly __typename?: 'RouteStopPayloadV2';
  readonly errors: ReadonlyArray<RouteStopV2Error>;
  readonly routeStopV2?: Maybe<RouteStopV2>;
};

export type RouteStopPreviewType = {
  readonly __typename?: 'RouteStopPreviewType';
  readonly id: Scalars['String'];
  readonly routeId?: Maybe<Scalars['String']>;
  readonly facilityId?: Maybe<Scalars['String']>;
  readonly address?: Maybe<StopAddressV2>;
  /**
   * type is deprecated; use loadStopType instead
   * @deprecated type is deprecated; use loadStopType instead
   */
  readonly type?: Maybe<Scalars['String']>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly loadStopType?: Maybe<Scalars['String']>;
  readonly sequence: Scalars['Float'];
};

export type RouteStopsPayload = {
  readonly __typename?: 'RouteStopsPayload';
  readonly routeStops: ReadonlyArray<RouteStopV2>;
};

export type RouteStopsPayloadError = {
  readonly __typename?: 'RouteStopsPayloadError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type RouteStopV2 = {
  readonly __typename?: 'RouteStopV2';
  readonly id: Scalars['ID'];
  readonly autoEstimate?: Maybe<AutoEstimate>;
  readonly manualEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly manualEtdDatetime?: Maybe<Scalars['DateTime']>;
  readonly route: LoadRouteV2;
  readonly loadStopType?: Maybe<Scalars['String']>;
  readonly liveType?: Maybe<ScheduleDropTypeEnum>;
  readonly stopEventsV2: ReadonlyArray<StopEventV2>;
  readonly usableStopEvents: StopEventEngineOutput;
  readonly trackingAutoETADatetime?: Maybe<Scalars['DateTime']>;
  readonly trackingManualETADatetime?: Maybe<Scalars['DateTime']>;
  readonly trackingAutoETAUsesDataScienceFormula?: Maybe<Scalars['Boolean']>;
  readonly facilityId?: Maybe<Scalars['ID']>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly note?: Maybe<Scalars['String']>;
  readonly scheduleType?: Maybe<ScheduleAppointmentTypeEnum>;
  readonly workType?: Maybe<ScheduleWorkTypeEnum>;
  readonly isOrderStop?: Maybe<Scalars['Boolean']>;
  /** @deprecated use loadStopType as it accounts for multi-route stop types, if loadStopType is present and its value is not a StopTypeEnum, this value defaults to 'Pu' */
  readonly type: StopTypeEnum;
  readonly loadFrom?: Maybe<KeyValue>;
  readonly unloadFrom?: Maybe<KeyValue>;
  readonly reqDate?: Maybe<DatetimeWithTimezone>;
  readonly availableStart?: Maybe<DatetimeWithTimezone>;
  readonly availableStartTimes?: Maybe<ScheduleRange>;
  readonly availableEnd?: Maybe<DatetimeWithTimezone>;
  readonly availableEndTimes?: Maybe<ScheduleRange>;
  readonly requirements?: Maybe<ReadonlyArray<StopRequirement>>;
  readonly commodities?: Maybe<ReadonlyArray<CommodityV2>>;
  readonly commoditiesV2?: Maybe<ReadonlyArray<StopCommodityFromRouteStop>>;
  /** @deprecated RegionV1 is deprecated, please use freightAssignmentRegion instead */
  readonly superRegion?: Maybe<Region>;
  /** @deprecated RegionV1 is deprecated, please use freightAssignmentRegion instead */
  readonly region?: Maybe<Region>;
  /** @deprecated RegionV1 is deprecated, please use freightAssignmentRegion instead */
  readonly subRegion?: Maybe<Region>;
  readonly address?: Maybe<StopAddressV2>;
  readonly orderStopId?: Maybe<Scalars['ID']>;
  readonly refs?: Maybe<ReadonlyArray<RefV2>>;
  readonly bolNumber?: Maybe<Scalars['String']>;
  readonly appointment?: Maybe<AppointmentV2>;
  /** @deprecated Use new field schedulingLog instead */
  readonly appointments?: Maybe<ReadonlyArray<AppointmentV2>>;
  readonly schedulingLog?: Maybe<ReadonlyArray<SchedulingLog>>;
  readonly sealNumber?: Maybe<Scalars['String']>;
  readonly computedCurrentAppointment?: Maybe<AppointmentV2>;
  readonly schedulingContactId?: Maybe<Scalars['ID']>;
  readonly schedulingContact?: Maybe<Contact>;
  readonly schedulingContactV2?: Maybe<FacilityContact>;
  readonly apptConfBy?: Maybe<Scalars['String']>;
  readonly apptReqWith?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  /** Stop Unique Identifier for external system */
  readonly externalRef?: Maybe<Scalars['ID']>;
  readonly appointmentChangeReason?: Maybe<Scalars['ID']>;
  readonly freightAssignmentRegion?: Maybe<FreightAssignmentRegion>;
  readonly numberOfPallets?: Maybe<Scalars['Int']>;
  readonly isCreatedManually?: Maybe<Scalars['Boolean']>;
  readonly splc?: Maybe<Scalars['String']>;
  /** PU/Del Codes from Customer-Facility Relationship */
  readonly externalFacilityId?: Maybe<Scalars['String']>;
  readonly assignedRouteStopShipments?: Maybe<ReadonlyArray<AssignedRouteStopShipment>>;
  readonly driverTargetStart?: Maybe<DatetimeWithTimezone>;
  readonly driverTargetStartTimes?: Maybe<ScheduleRange>;
  readonly driverTargetEnd?: Maybe<DatetimeWithTimezone>;
  readonly driverTargetEndTimes?: Maybe<ScheduleRange>;
  readonly driverTargetAddedBy?: Maybe<UserV2>;
  readonly driverTargetAddedAt?: Maybe<Scalars['DateTime']>;
  readonly driverTargetLastUpdatedBy?: Maybe<UserV2>;
  readonly driverTargetLastUpdatedAt?: Maybe<Scalars['DateTime']>;
  readonly commodityAction?: Maybe<CommodityActionEnum>;
  readonly commodityPhase?: Maybe<CommodityPhaseEnum>;
  readonly isOutgoingLoaded?: Maybe<Scalars['Boolean']>;
  readonly freightAssignmentAdditionalRegion?: Maybe<ReadonlyArray<FreightAssignmentRegion>>;
  readonly orderStopDesignation?: Maybe<Scalars['String']>;
  readonly distanceToNext?: Maybe<Length>;
};


export type RouteStopV2UsableStopEventsArgs = {
  routeType: Scalars['String'];
};


export type RouteStopV2DistanceToNextArgs = {
  unit: UnitOfLengthEnum;
};

export type RouteStopV2Error = {
  readonly __typename?: 'RouteStopV2Error';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly serviceError?: Maybe<Scalars['JSON']>;
};

/** The type of route (Mastery vs External System) */
export enum RouteType {
  Mastery = 'MASTERY',
  Revenova = 'REVENOVA',
  External = 'EXTERNAL'
}

export type RouteVendor = {
  readonly __typename?: 'RouteVendor';
  readonly id: Scalars['ID'];
  readonly activeBounce?: Maybe<Bounce>;
  readonly booked: Scalars['Boolean'];
  readonly bookedBy: Employee;
  readonly bookedWith?: Maybe<Contact>;
  readonly bookingNotes?: Maybe<Scalars['String']>;
  readonly bounced: Scalars['Boolean'];
  readonly bounces?: Maybe<ReadonlyArray<Bounce>>;
  readonly bracingType?: Maybe<BracingTypeEnum>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdBy: Employee;
  readonly createdByUser?: Maybe<User>;
  readonly distance?: Maybe<UnitOfDistance>;
  readonly expectedEmptyGeography?: Maybe<Geography>;
  readonly mainVendorRep?: Maybe<RouteVendorRep>;
  readonly numberOfBraces?: Maybe<Scalars['Int']>;
  readonly numberOfPallets?: Maybe<Scalars['Int']>;
  readonly numberOfTarps?: Maybe<Scalars['Int']>;
  readonly palletType?: Maybe<Scalars['String']>;
  readonly pickupEta?: Maybe<PickupEta>;
  readonly route?: Maybe<LoadRoute>;
  readonly routeVendorCost?: Maybe<RouteVendorCost>;
  readonly routeVendorReps?: Maybe<ReadonlyArray<RouteVendorRep>>;
  readonly tarpType?: Maybe<Scalars['String']>;
  /** @deprecated Use trailer_height_v2 instead */
  readonly trailerHeight?: Maybe<UnitOfDistance>;
  readonly trailerHeightV2?: Maybe<Length>;
  /** @deprecated Use trailer_length_v2 instead */
  readonly trailerLength?: Maybe<UnitOfDistance>;
  readonly trailerLengthV2?: Maybe<Length>;
  readonly trailerType?: Maybe<KeyValue>;
  /** @deprecated Use trailer_width_v2 instead */
  readonly trailerWidth?: Maybe<UnitOfDistance>;
  readonly trailerWidthV2?: Maybe<Length>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedBy?: Maybe<Employee>;
  readonly utcExpectedEmptyDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly utcPickupEtaDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly vendor: Carrier;
  readonly vendorType: RouteVendorTypeEnum;
};


export type RouteVendorTrailerHeightV2Args = {
  unit: UnitOfLengthEnum;
};


export type RouteVendorTrailerLengthV2Args = {
  unit: UnitOfLengthEnum;
};


export type RouteVendorTrailerWidthV2Args = {
  unit: UnitOfLengthEnum;
};

/** The connection type for RouteVendor. */
export type RouteVendorConnection = {
  readonly __typename?: 'RouteVendorConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<RouteVendorEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** Represents a route / vendor pairing with associated cost details */
export type RouteVendorCost = {
  readonly __typename?: 'RouteVendorCost';
  readonly vendorId?: Maybe<Scalars['ID']>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly totalAmount?: Maybe<Scalars['Decimal']>;
  readonly invoicedAmount?: Maybe<Scalars['Decimal']>;
  readonly uninvoicedAmount?: Maybe<Scalars['Decimal']>;
  readonly totalCost?: Maybe<Scalars['Decimal']>;
  readonly voucheredAmount?: Maybe<Scalars['Decimal']>;
  readonly unvoucheredAmount?: Maybe<Scalars['Decimal']>;
  readonly variance?: Maybe<Scalars['Decimal']>;
  readonly vendorInvoiceTotal?: Maybe<Scalars['Decimal']>;
  readonly currencyVendorInvoiceTotal?: Maybe<Scalars['Decimal']>;
  readonly advanceAmount?: Maybe<Scalars['Decimal']>;
  readonly quickPay?: Maybe<Scalars['Boolean']>;
  readonly vendorInvoiceNumbers?: Maybe<Scalars['String']>;
  readonly paperWorkStatus?: Maybe<Scalars['String']>;
  readonly routeLifeCycle?: Maybe<RouteLifeCycleStatusEnum>;
  readonly routeFinancialStatus?: Maybe<VendorFinancialStatusEnum>;
  readonly minThreshold?: Maybe<Scalars['Decimal']>;
  readonly maxThreshold?: Maybe<Scalars['Decimal']>;
  readonly costDetails: ReadonlyArray<CostDetail>;
  readonly isVoucherRequirementBypassed: Scalars['Boolean'];
  readonly vendorIsAdvanceProvider: Scalars['Boolean'];
  readonly currencyTotalCost?: Maybe<Scalars['Decimal']>;
  readonly currencyVoucheredAmount?: Maybe<Scalars['Decimal']>;
  readonly currencyUnvoucheredAmount?: Maybe<Scalars['Decimal']>;
  readonly disableFinancials: Scalars['Boolean'];
  readonly hasSeerSearchLimitReached?: Maybe<Scalars['Boolean']>;
  readonly baseChargeAmount?: Maybe<Scalars['Decimal']>;
  readonly accessorialChargeAmount?: Maybe<Scalars['Decimal']>;
  readonly otherChargeAmount?: Maybe<Scalars['Decimal']>;
  readonly fuelAmount?: Maybe<Scalars['Decimal']>;
  readonly hasHoldEvent?: Maybe<Scalars['Boolean']>;
  readonly holdEventReason?: Maybe<Scalars['String']>;
  readonly holdEventDays?: Maybe<Scalars['Int']>;
  readonly holdCloseDate?: Maybe<Scalars['DateTime']>;
  readonly isApprovedForVouchering?: Maybe<Scalars['Boolean']>;
  readonly holdApprovedBy?: Maybe<Scalars['String']>;
  readonly holdApprovedOn?: Maybe<Scalars['DateTime']>;
  readonly CarrierV2?: Maybe<CarrierV2>;
  readonly Carrier?: Maybe<Carrier>;
  readonly vendorRouteFinancialStatusReasons?: Maybe<ReadonlyArray<Maybe<ExceptionReason>>>;
  readonly canDisableFinancials?: Maybe<Scalars['Boolean']>;
  readonly carrierFinalizedFuelStatus?: Maybe<Scalars['String']>;
  readonly accountingIncidentsV2: ReadonlyArray<IncidentV2>;
};

/** A connection to a list of items. */
export type RouteVendorCostConnection = {
  readonly __typename?: 'RouteVendorCostConnection';
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<RouteVendorCostEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<RouteVendorCost>>>;
};

/** An edge in a connection. */
export type RouteVendorCostEdge = {
  readonly __typename?: 'RouteVendorCostEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<RouteVendorCost>;
};

/** The input type for Route Vendors Disable Financials */
export type RouteVendorDisableFinancialsInput = {
  readonly vendorId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly disableFinancials: Scalars['Boolean'];
};

export type RouteVendorDisableFinancialsModel = {
  readonly __typename?: 'RouteVendorDisableFinancialsModel';
  readonly vendorId: Scalars['Uuid'];
  readonly routeId: Scalars['Uuid'];
  readonly disableFinancials: Scalars['Boolean'];
  readonly hasPropertyMetadata: Scalars['Boolean'];
  readonly eid?: Maybe<Scalars['Long']>;
  readonly id?: Maybe<Scalars['Uuid']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly propertyMetadata?: Maybe<ReadonlyArray<KeyValuePairOfStringAndPropertyMetadata>>;
};

/** An edge in a connection. */
export type RouteVendorEdge = {
  readonly __typename?: 'RouteVendorEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: RouteVendor;
};

/** lachesis-api: Route Vendor Financial Status Object */
export type RouteVendorFinancialStatusV3 = {
  readonly __typename?: 'RouteVendorFinancialStatusV3';
  /** lachesis-api: Load Id associated with the route vendor record. */
  readonly load_id: Scalars['ID'];
  /** lachesis-api: Vendor Id associated with the route vendor record. */
  readonly vendor_id: Scalars['ID'];
  /** lachesis-api: Route Id associated with the route vendor record. */
  readonly route_id: Scalars['ID'];
  /** lachesis-api: Indicator to determine whether or not the vendor is in a bounced status or not. */
  readonly is_bounced?: Maybe<Scalars['Boolean']>;
  /** lachesis-api: Descriptor that contains the route/vendor type. */
  readonly route_vendor_type?: Maybe<Scalars['String']>;
  /** lachesis-api: The current financial status for vendor. */
  readonly vendor_financial_status: Scalars['String'];
  /** lachesis-api: Array of strings that describes any supported reasons for the current vendor financial status. */
  readonly vendor_financial_status_reasons: ReadonlyArray<Scalars['String']>;
};

/** The input type for IsApprovedForVouchering for Route Vendor */
export type RouteVendorHoldApprovedForVoucheringInput = {
  readonly vendorId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly isApprovedForVouchering: Scalars['Boolean'];
};

export type RouteVendorHoldApprovedForVoucheringModel = {
  readonly __typename?: 'RouteVendorHoldApprovedForVoucheringModel';
  readonly vendorId: Scalars['Uuid'];
  readonly routeId: Scalars['Uuid'];
  readonly isApprovedForVouchering: Scalars['Boolean'];
  readonly hasPropertyMetadata: Scalars['Boolean'];
  readonly eid?: Maybe<Scalars['Long']>;
  readonly id?: Maybe<Scalars['Uuid']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly propertyMetadata?: Maybe<ReadonlyArray<KeyValuePairOfStringAndPropertyMetadata>>;
};

export type RouteVendorIdPairInput = {
  readonly routeId: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
};

/** Represent input type to get all routeVendorRequirements for a routeVendor. */
export type RouteVendorInput = {
  readonly routeId: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
};

export type RouteVendorMaxCostValidation = {
  readonly __typename?: 'RouteVendorMaxCostValidation';
  readonly message?: Maybe<Scalars['String']>;
  readonly isValid?: Maybe<Scalars['Boolean']>;
};

export type RouteVendorReference = {
  readonly __typename?: 'RouteVendorReference';
  readonly id: Scalars['ID'];
  /** @deprecated Use `routeVendorReferenceTypeV2`. */
  readonly routeVendorReferenceType: RouteVendorReferenceTypeEnum;
  readonly routeVendorReferenceTypeV2: Scalars['String'];
  readonly value: Scalars['String'];
  readonly ediQualifier?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated createdByUser is deprecated. Use createdByUserV2 instead. */
  readonly createdByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated updatedByUser is deprecated. Use updatedByUserV2 instead. */
  readonly updatedByUser?: Maybe<User>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type RouteVendorReferenceEntityInput = {
  readonly id: Scalars['ID'];
  readonly routeVendorReferenceTypeV2: Scalars['String'];
  readonly value: Scalars['String'];
  readonly ediQualifier?: InputMaybe<Scalars['String']>;
  readonly createdByUserV2?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly updatedByUserV2?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
};

export type RouteVendorReferenceInput = {
  readonly routeRefType: Scalars['String'];
  readonly refNumber: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isDelete?: InputMaybe<Scalars['Boolean']>;
};

export type RouteVendorReferences = {
  readonly __typename?: 'RouteVendorReferences';
  readonly id?: Maybe<Scalars['ID']>;
  readonly costQuoteId: Scalars['ID'];
  readonly routeRefType?: Maybe<TenantConfiguration>;
  readonly refNumber?: Maybe<Scalars['String']>;
};

/** Input for updating a list of Route References */
export type RouteVendorReferencesInput = {
  /** The ID of the Route Vendor which Route References are associated with */
  readonly routeVendorId?: InputMaybe<Scalars['ID']>;
  /** The ID of the User performing the Update action */
  readonly updatedByUserId: Scalars['ID'];
  /** The Array of Route References to be Created or Updated */
  readonly routeVendorReferences: ReadonlyArray<CreateOrUpdateRouteVendorReferenceInputV2>;
};

/** The response from the **updateRouteVendorReferences** Mutation */
export type RouteVendorReferencesPayload = {
  readonly __typename?: 'RouteVendorReferencesPayload';
  /** An Array of the updated References */
  readonly routeVendorReferences?: Maybe<ReadonlyArray<RouteVendorReference>>;
  /** An Array of any Errors that were encountered while updating Route References */
  readonly errors: ReadonlyArray<UserError>;
};

export enum RouteVendorReferenceTypeEnum {
  /** Carrier Pro # */
  CarrierProNumber = 'carrierProNumber',
  /** PU Ref # */
  PickupReferenceNumber = 'pickupReferenceNumber',
  /** Waybill # */
  WaybillNumber = 'waybillNumber',
  /** Rail Pricing Authority */
  RailPricingAuthority = 'railPricingAuthority',
  /** Rail Routing */
  RailRouting = 'railRouting',
  /** Other */
  Other = 'other'
}

export type RouteVendorRep = {
  readonly __typename?: 'RouteVendorRep';
  readonly createdByUser: User;
  readonly employee: Employee;
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly employeeOffice?: Maybe<KeyValue>;
  readonly id: Scalars['ID'];
  readonly main: Scalars['Boolean'];
  readonly repType: Scalars['ID'];
  readonly routeVendor: RouteVendor;
  readonly updatedByUser: User;
};

export enum RouteVendorRepTypeEnum {
  /** Accounts Payable */
  AccountsPayable = 'accountsPayable',
  /** Carrier Manager */
  CarrierManager = 'carrierManager',
  /** Carrier Ops */
  CarrierOps = 'carrierOps',
  /** Carrier Sales */
  CarrierSales = 'carrierSales',
  /** Issue Resolution Rep */
  IssueResolutionRep = 'issueResolutionRep',
  /** Other */
  Other = 'other',
  /** Tracking Specialist */
  TrackingSpecialist = 'trackingSpecialist',
  /** Sponsored Prospect */
  SponsoredProspect = 'sponsoredProspect',
  /** Driver Manager */
  DriverManager = 'driverManager',
  /** Fleet Manager */
  FleetManager = 'fleetManager',
  /** Fleet Supervisor */
  FleetSupervisor = 'fleetSupervisor',
  /** Load Planner */
  LoadPlanner = 'loadPlanner'
}

export type RouteVendorRepV2 = {
  readonly __typename?: 'RouteVendorRepV2';
  readonly id: Scalars['ID'];
  /** @deprecated repTypeId is deprecated. Use repTypeV2 instead. */
  readonly repTypeId?: Maybe<Scalars['String']>;
  /** @deprecated repType is deprecated. Use repTypeV2 instead. */
  readonly repType: RouteVendorRepTypeEnum;
  readonly repTypeV2?: Maybe<KeyValue>;
  /** @deprecated employee is deprecated. Use employeeV2 instead. */
  readonly employee: Employee;
  readonly employeeV2: EmployeeV2;
  readonly employeeDivision?: Maybe<KeyValue>;
  readonly employeeBusinessUnit?: Maybe<KeyValue>;
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly employeeOffice?: Maybe<KeyValue>;
  readonly division?: Maybe<Scalars['String']>;
  readonly businessUnit?: Maybe<Scalars['String']>;
  readonly routeVendor: RouteVendorV2;
  /** @deprecated createdByUser is deprecated. Use createdByUserV2 instead. */
  readonly createdByUser: User;
  readonly createdByUserV2: UserV2;
  readonly main: Scalars['Boolean'];
  /** @deprecated updatedByUser is deprecated. Use updatedByUserV2 instead. */
  readonly updatedByUser: User;
  readonly updatedByUserV2: UserV2;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Represents Route Vendor Requirement Type */
export type RouteVendorRequirement = {
  readonly __typename?: 'RouteVendorRequirement';
  readonly createdById?: Maybe<Scalars['ID']>;
  readonly createdByName?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedById?: Maybe<Scalars['ID']>;
  readonly updatedByName?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
  readonly requirementType?: Maybe<Scalars['String']>;
  readonly requirementStatus?: Maybe<Scalars['String']>;
  readonly bypassRequirement?: Maybe<Scalars['Boolean']>;
  readonly bypassedById?: Maybe<Scalars['ID']>;
  readonly bypassedByName?: Maybe<Scalars['String']>;
  readonly bypassedOn?: Maybe<Scalars['DateTime']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly routeVendorRequirementReasons?: Maybe<ReadonlyArray<Maybe<RouteVendorRequirementReason>>>;
};

/** Represents Route Vendor Requirement Reason Type */
export type RouteVendorRequirementReason = {
  readonly __typename?: 'RouteVendorRequirementReason';
  readonly createdById?: Maybe<Scalars['ID']>;
  readonly createdByName?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedById?: Maybe<Scalars['ID']>;
  readonly updatedByName?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly reasonCode?: Maybe<Scalars['String']>;
};

/** Represents input type to update route vendor requirement. */
export type RouteVendorRequirementUpdateInputType = {
  readonly id: Scalars['ID'];
  readonly bypassRequirement: Scalars['Boolean'];
};

export type RouteVendorsFilter = {
  readonly excludeInactive?: InputMaybe<Scalars['Boolean']>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
};

/** Represents a route / vendor financial status */
export type RouteVendorStatus = {
  readonly __typename?: 'RouteVendorStatus';
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly vendorId?: Maybe<Scalars['ID']>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly vendorFinancialStatus?: Maybe<VendorFinancialStatusEnum>;
};

/** Filter for acquiring Route Vendor information from the `routeVendorsV2` Query */
export type RouteVendorsV2Filter = {
  /** Return only Route Vendors which are not currently Bounced. */
  readonly excludeInactive?: InputMaybe<Scalars['Boolean']>;
  /** Return Route Vendors on a Route */
  readonly routeId?: InputMaybe<Scalars['ID']>;
  /** Return Route Vendors for a given Carrier / Vendor */
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  /** Return Route Vendors for a Route Reference value */
  readonly routeVendorReferenceNumbers?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Return Route Vendors containing Reps within a specific Business Unit */
  readonly routeVendorRepBusinessUnit?: InputMaybe<Scalars['String']>;
  /** Return Route Vendors which have a Rep within a specific Division */
  readonly routeVendorRepDivision?: InputMaybe<Scalars['String']>;
  /** Return Route Vendors based on a list of routeId/vendorId pairs */
  readonly routeAndVendorIdPairs?: InputMaybe<ReadonlyArray<RouteAndVendorFilter>>;
};

export enum RouteVendorTypeEnum {
  /** Primary Carrier */
  Carrier = 'carrier',
  /** Other */
  Other = 'other',
  /** Truck Ordered Not Used */
  Tonu = 'tonu'
}

export type RouteVendorV2 = {
  readonly __typename?: 'RouteVendorV2';
  readonly id: Scalars['ID'];
  readonly activeBounce?: Maybe<BounceV2>;
  readonly booked: Scalars['Boolean'];
  readonly isPreAssigned: Scalars['Boolean'];
  /** @deprecated bookedBy is deprecated. Use bookedByV2 instead. */
  readonly bookedBy: Employee;
  readonly bookedByV2: EmployeeV2;
  /** @deprecated bookedByUser is deprecated. Use bookedByUserV2 instead. */
  readonly bookedByUser: User;
  readonly bookedByUserV2: UserV2;
  readonly bookedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated bookedWith is deprecated. Use bookedWithV2 instead. */
  readonly bookedWith?: Maybe<Contact>;
  readonly bookedWithV2?: Maybe<CarrierContact>;
  readonly bookingNotes?: Maybe<Scalars['String']>;
  readonly bookingSource?: Maybe<KeyValue>;
  readonly bookingSourceId?: Maybe<Scalars['String']>;
  readonly bounced: Scalars['Boolean'];
  readonly bounces?: Maybe<ReadonlyArray<BounceV2>>;
  readonly braces?: Maybe<Braces>;
  readonly bracingType?: Maybe<BracingTypeEnum>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  /** @deprecated createdBy is deprecated. Use createdByV2 instead. */
  readonly createdBy: Employee;
  readonly createdByV2: EmployeeV2;
  /** @deprecated createdByUser is deprecated. Use createdByUserV2 instead. */
  readonly createdByUser: User;
  readonly createdByUserV2: UserV2;
  readonly distance?: Maybe<UnitOfDistance>;
  /** @deprecated expectedEmptyGeography is deprecated. Use expectedEmptyGeographyV2 instead. */
  readonly expectedEmptyGeography?: Maybe<Geography>;
  readonly expectedEmptyGeographyV2?: Maybe<GeographyV2>;
  readonly expectedEmptyLocation?: Maybe<ExpectedEmptyLocation>;
  readonly mainVendorRep?: Maybe<RouteVendorRepV2>;
  readonly numberOfBraces?: Maybe<Scalars['Int']>;
  readonly numberOfPallets?: Maybe<Scalars['Int']>;
  readonly numberOfTarps?: Maybe<Scalars['Int']>;
  /** @deprecated palletType is deprecated. Use palletTypeDDT instead. */
  readonly palletType?: Maybe<PalletTypeEnum>;
  readonly palletTypeDDT?: Maybe<KeyValue>;
  readonly pickupEtaTimezone?: Maybe<Scalars['String']>;
  readonly route?: Maybe<LoadRouteV2>;
  readonly routeVendorCost?: Maybe<RouteVendorCost>;
  readonly routeVendorReps?: Maybe<ReadonlyArray<RouteVendorRepV2>>;
  readonly routeVendorReferences?: Maybe<ReadonlyArray<RouteVendorReference>>;
  /** @deprecated tarpType is deprecated. Use palletTypeDDT instead. */
  readonly tarpType?: Maybe<TarpTypeEnum>;
  readonly tarpTypeDDT?: Maybe<KeyValue>;
  readonly trailerHeight?: Maybe<UnitOfDistance>;
  readonly trailerHeightV2?: Maybe<Length>;
  readonly trailerLength?: Maybe<UnitOfDistance>;
  readonly trailerLengthV2?: Maybe<Length>;
  readonly trailerType?: Maybe<KeyValue>;
  readonly trailerWidth?: Maybe<UnitOfDistance>;
  readonly trailerWidthV2?: Maybe<Length>;
  readonly utcExpectedEmptyDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly utcPickupEtaDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated updatedByUser is deprecated. Use updatedByUserV2 instead. */
  readonly updatedByUser?: Maybe<User>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  /** @deprecated vendor is deprecated. Use vendorV2 instead. */
  readonly vendor: Carrier;
  readonly vendorV2: CarrierV2;
  readonly vendorType: RouteVendorTypeEnum;
  readonly executingDivisionDDT?: Maybe<KeyValue>;
  readonly executingDivisionId?: Maybe<Scalars['String']>;
  /** @deprecated businessUnit is no longer used by MasterMind */
  readonly executingBusinessUnitDDT?: Maybe<KeyValue>;
  /** @deprecated businessUnit is no longer used by MasterMind */
  readonly executingBusinessUnitId?: Maybe<Scalars['String']>;
};


export type RouteVendorV2TrailerHeightV2Args = {
  unit: UnitOfLengthEnum;
};


export type RouteVendorV2TrailerLengthV2Args = {
  unit: UnitOfLengthEnum;
};


export type RouteVendorV2TrailerWidthV2Args = {
  unit: UnitOfLengthEnum;
};

/** The connection type for RouteVendorV2. */
export type RouteVendorV2Connection = {
  readonly __typename?: 'RouteVendorV2Connection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<RouteVendorV2Edge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RouteVendorV2Edge = {
  readonly __typename?: 'RouteVendorV2Edge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: RouteVendorV2;
};

export type RouteVendorVoucherHoldInput = {
  /** Division associated with the entity, must match a division DDT */
  readonly division?: InputMaybe<Scalars['String']>;
  /** Load Size associated with the entity, must match a loadSize DDT */
  readonly loadSize?: InputMaybe<Scalars['String']>;
  /** Trailer Type equipment associated with the entity, must match a trailerType DDT option ID in UUID format */
  readonly equipment?: ReadonlyArray<Scalars['String']>;
  /** Route type associated with the entity, must match a routeType DDT */
  readonly routeType?: InputMaybe<Scalars['String']>;
  /** Route Transport Mode associated with the entity, must match a routeTransportMode DDT */
  readonly routeTransportMode?: InputMaybe<Scalars['String']>;
  /** Vendor Type associated with the entity and action */
  readonly vendorType?: InputMaybe<Scalars['String']>;
};

export type RoutingGuide = {
  readonly __typename?: 'RoutingGuide';
  readonly id: Scalars['ID'];
  readonly routingGuideTypeTerm?: Maybe<Scalars['String']>;
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly isLaneDeactivated?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use createdByV2 instead */
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use updatedByV2 instead */
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly sequence: ReadonlyArray<RoutingGuideSequenceItem>;
  /** @deprecated Use customerV2 instead */
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly lane?: Maybe<LaneV2>;
  readonly duration?: Maybe<Scalars['Int']>;
  readonly code: Scalars['String'];
};

export type RoutingGuideEdge = {
  readonly __typename?: 'RoutingGuideEdge';
  readonly cursor: Scalars['String'];
  readonly node: RoutingGuide;
};

export type RoutingGuideInput = {
  readonly laneId: Scalars['ID'];
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly timezone?: InputMaybe<Scalars['String']>;
};

export type RoutingGuideOnlyEffectiveFilter = {
  readonly timezone: Scalars['String'];
};

export type RoutingGuidesByLaneFilter = {
  readonly laneId: Scalars['ID'];
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly onlyActive?: InputMaybe<Scalars['Boolean']>;
  readonly onlyEffective?: InputMaybe<RoutingGuideOnlyEffectiveFilter>;
};

export type RoutingGuidesConnection = {
  readonly __typename?: 'RoutingGuidesConnection';
  readonly edges: ReadonlyArray<Maybe<RoutingGuideEdge>>;
  readonly totalCount: Scalars['Int'];
  readonly pageInfo: PageInfo;
};

export type RoutingGuideSequenceItem = {
  readonly __typename?: 'RoutingGuideSequenceItem';
  readonly id: Scalars['ID'];
  /** @deprecated Use carrierV2 instead */
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly ordinal?: Maybe<Scalars['Int']>;
  readonly scac?: Maybe<Scalars['String']>;
  readonly tenderOptions?: Maybe<TenderOptions>;
  readonly rawTenderOptions?: Maybe<TenderOptionsV2>;
  readonly activeCostQuote?: Maybe<ActiveCostQuote>;
};

export type RoutingGuideSequenceItemsInput = {
  readonly sequenceItemIds: ReadonlyArray<Scalars['ID']>;
};

export type RoutingGuideUploadEventPayload = {
  readonly __typename?: 'RoutingGuideUploadEventPayload';
  readonly status?: Maybe<Scalars['String']>;
  readonly workbookId?: Maybe<Scalars['String']>;
};

export type RowErrorInput = {
  readonly recordNumber?: InputMaybe<Scalars['Int']>;
  readonly error?: InputMaybe<Scalars['String']>;
};

export type Rule = {
  readonly __typename?: 'Rule';
  readonly name?: Maybe<Scalars['String']>;
  readonly schemaId?: Maybe<Scalars['String']>;
  readonly conditions: ConditionProperties;
};

export type RuleAction = {
  readonly __typename?: 'RuleAction';
  readonly type: Scalars['String'];
  /** additionalData is interpreted as a JSON string and stored as the parsed object */
  readonly additionalData?: Maybe<Scalars['String']>;
};

export type RuleActionInput = {
  readonly type: Scalars['String'];
  /** additionalData is interpreted as a JSON string and stored as the parsed object */
  readonly additionalData?: InputMaybe<Scalars['String']>;
};

export type RuleInput = {
  readonly name?: InputMaybe<Scalars['String']>;
  readonly schemaId?: InputMaybe<Scalars['String']>;
  readonly conditions: ConditionPropertiesInput;
};

export type RuleSet = {
  readonly __typename?: 'RuleSet';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly eventName?: Maybe<Scalars['String']>;
  readonly eventId?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly serviceSource?: Maybe<ServiceSource>;
  readonly type: RuleSetType;
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly references?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly schemas?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly rules?: Maybe<ReadonlyArray<Maybe<RuleSetItem>>>;
};

/** RuleSet Evaluation */
export type RuleSetEvaluationRequestInput = {
  readonly eventName?: InputMaybe<Scalars['String']>;
  readonly ruleSetId?: InputMaybe<Scalars['String']>;
  readonly facts: ReadonlyArray<InputMaybe<Scalars['JSONObject']>>;
  readonly references?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly returnEvaluations?: InputMaybe<Scalars['Boolean']>;
};

export type RuleSetEvaluationResponseBody = {
  readonly __typename?: 'RuleSetEvaluationResponseBody';
  readonly passed: Scalars['Boolean'];
  readonly action?: Maybe<RuleAction>;
  readonly matched?: Maybe<ReadonlyArray<Maybe<Scalars['JSONObject']>>>;
  readonly unmatched?: Maybe<ReadonlyArray<Maybe<Scalars['JSONObject']>>>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly evaluations?: Maybe<ReadonlyArray<Maybe<Scalars['JSONObject']>>>;
  readonly references?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly name?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
};

export type RuleSetEvaluationResult = {
  readonly __typename?: 'RuleSetEvaluationResult';
  readonly evaluation?: Maybe<ReadonlyArray<RuleSetEvaluationResponseBody>>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type RuleSetInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly eventId?: InputMaybe<Scalars['String']>;
  readonly eventName?: InputMaybe<Scalars['String']>;
  readonly ruleSetId?: InputMaybe<Scalars['String']>;
  readonly serviceSource?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly type: RuleSetType;
  readonly isActive: Scalars['Boolean'];
  readonly references?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly schemas?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly rules?: InputMaybe<ReadonlyArray<InputMaybe<RuleSetItemInput>>>;
};

export type RuleSetItem = {
  readonly __typename?: 'RuleSetItem';
  readonly priority?: Maybe<Scalars['Int']>;
  readonly rule?: Maybe<Rule>;
  readonly action?: Maybe<RuleAction>;
};

export type RuleSetItemInput = {
  readonly priority?: InputMaybe<Scalars['Int']>;
  readonly rule?: InputMaybe<RuleInput>;
  readonly action?: InputMaybe<RuleActionInput>;
};

export enum RuleSetType {
  /** Conditions from each rule are run, then Actions are applied, then Action Results are sent in as Facts to the next Rule conditions and so on. Rules are run in order of "priority" from largest to smallest number value. */
  Pipeline = 'PIPELINE',
  /** Condition from each rule are run and the first rule that passes return the RuleAction. Rules are run in order of "priority" from largest to smallest number value. */
  FirstMatch = 'FIRST_MATCH'
}

export enum SafetyRatingTypeEnum {
  /** None */
  None = 'none',
  /** Satisfactory */
  Satisfactory = 'satisfactory',
  /** Conditional */
  Conditional = 'conditional',
  /** Unsatisfactory */
  Unsatisfactory = 'unsatisfactory',
  /** Insufficient Data */
  InsufficientData = 'insufficientData',
  /** NA */
  Na = 'NA'
}

export type SalesOpportunitiesNamesOutput = {
  readonly __typename?: 'SalesOpportunitiesNamesOutput';
  readonly entityId: Scalars['String'];
  readonly entityLookupId?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly opportunityId: Scalars['ID'];
};

export type SalesOpportunityBusinessUnitInput = {
  readonly Business_UnitTerm?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunityBusinessUnitInputV2 = {
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunityBusinessUnitOutput = {
  readonly __typename?: 'salesOpportunityBusinessUnitOutput';
  readonly Business_UnitTerm?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Created_By?: Maybe<User>;
  readonly Opportunity_Business_Unit_Id: Scalars['ID'];
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_By?: Maybe<User>;
};

export type SalesOpportunityBusinessUnitOutputV2 = {
  readonly __typename?: 'SalesOpportunityBusinessUnitOutputV2';
  readonly businessUnitTerm?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly opportunityBusinessUnitId: Scalars['ID'];
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
};

export type SalesOpportunityConnection = {
  readonly __typename?: 'SalesOpportunityConnection';
  readonly edges: ReadonlyArray<SalesOpportunityOutputV2Edge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
  readonly totalOpportunitiesCount: Scalars['Int'];
};

export type SalesOpportunityContactInput = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Name?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunityContactInputV2 = {
  readonly contactId?: InputMaybe<Scalars['String']>;
  readonly contactName?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunityContactOutput = {
  readonly __typename?: 'salesOpportunityContactOutput';
  readonly Contact_Id: Scalars['String'];
  readonly Contact_Name?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Created_By?: Maybe<User>;
  readonly Opportunity_Contact_Id: Scalars['ID'];
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_By?: Maybe<User>;
};

export type SalesOpportunityContactOutputV2 = {
  readonly __typename?: 'SalesOpportunityContactOutputV2';
  readonly contactId: Scalars['String'];
  /** @deprecated contactName is deprecated as we are not using */
  readonly contactName?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly opportunityContactId: Scalars['ID'];
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
};

export type SalesOpportunityDocumentInputV2 = {
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly directionTerm?: InputMaybe<Scalars['String']>;
  readonly documentId?: InputMaybe<Scalars['String']>;
  readonly documentName?: InputMaybe<Scalars['String']>;
  readonly documentPath?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly sourceTerm?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunityDocumentOutputV2 = {
  readonly __typename?: 'SalesOpportunityDocumentOutputV2';
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly details?: Maybe<Scalars['String']>;
  readonly directionTerm?: Maybe<Scalars['String']>;
  readonly documentId?: Maybe<Scalars['String']>;
  readonly documentName?: Maybe<Scalars['String']>;
  readonly documentPath?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly sourceTerm?: Maybe<Scalars['String']>;
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
};

export type SalesOpportunityEntityLookupOutputV2 = {
  readonly __typename?: 'SalesOpportunityEntityLookupOutputV2';
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly entityLookupId: Scalars['ID'];
  readonly entityName?: Maybe<Scalars['String']>;
  readonly entityType?: Maybe<Scalars['Float']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
};

export type SalesOpportunityEquipmentInput = {
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly EquipmentTerm?: InputMaybe<Scalars['String']>;
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunityEquipmentInputV2 = {
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly equipmentTerm?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunityEquipmentOutput = {
  readonly __typename?: 'salesOpportunityEquipmentOutput';
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Created_By?: Maybe<User>;
  readonly EquipmentTerm?: Maybe<Scalars['String']>;
  readonly Opportunity_Equipment_Id: Scalars['ID'];
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_By?: Maybe<User>;
};

export type SalesOpportunityEquipmentOutputV2 = {
  readonly __typename?: 'SalesOpportunityEquipmentOutputV2';
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly equipmentTerm?: Maybe<Scalars['String']>;
  readonly opportunityEquipmentId: Scalars['ID'];
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
};

export type SalesOpportunityFilter = {
  readonly awardDate?: InputMaybe<Scalars['DateTime']>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly closeReason?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly contacts?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdBy?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly documents?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly dueDate?: InputMaybe<Scalars['DateTime']>;
  readonly entityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly entityType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equipment?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly filterRepId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly filterStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly goLiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly mainAwardDate?: InputMaybe<Scalars['DateTime']>;
  readonly mainAwardDateEnd?: InputMaybe<Scalars['DateTime']>;
  readonly mainCloseReason?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainDueDate?: InputMaybe<Scalars['DateTime']>;
  readonly mainDueDateEnd?: InputMaybe<Scalars['DateTime']>;
  readonly mainEntityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainEquipment?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainGoLiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly mainGoLiveDateEnd?: InputMaybe<Scalars['DateTime']>;
  readonly mainMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainPricingStrategy?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainRevisitDate?: InputMaybe<Scalars['DateTime']>;
  readonly mainRevisitDateEnd?: InputMaybe<Scalars['DateTime']>;
  readonly mainSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainSolution?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainSolutionType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainStage?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly pricingStrategy?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly projectedMargin?: InputMaybe<Scalars['Float']>;
  readonly projectedRevenue?: InputMaybe<Scalars['Float']>;
  readonly projectedVolume?: InputMaybe<Scalars['Float']>;
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly revisitDate?: InputMaybe<Scalars['DateTime']>;
  readonly size?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly solution?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly solutionType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly source?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly stage?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly status?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly totalRevenue?: InputMaybe<Scalars['Float']>;
  readonly totalVolume?: InputMaybe<Scalars['Float']>;
  readonly type?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedBy?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type SalesOpportunityIbRegionInput = {
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly IB_Regions_Data?: InputMaybe<Scalars['String']>;
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly Region?: InputMaybe<Scalars['String']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunityIbRegionInputV2 = {
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly ibRegionsData?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunityIbRegionOutput = {
  readonly __typename?: 'salesOpportunityIBRegionOutput';
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Created_By?: Maybe<User>;
  readonly IB_Regions_Data?: Maybe<Scalars['String']>;
  readonly Opportunity_IBRegion_Id: Scalars['ID'];
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  /** @deprecated Region is deprecated, Use IB_Regions_Data instead */
  readonly Region?: Maybe<Scalars['String']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_By?: Maybe<User>;
};

export type SalesOpportunityIbRegionOutputV2 = {
  readonly __typename?: 'SalesOpportunityIBRegionOutputV2';
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly ibRegionsData?: Maybe<Scalars['String']>;
  readonly opportunityIBRegionId: Scalars['ID'];
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
};

export type SalesOpportunityInput = {
  readonly Award_Date?: InputMaybe<Scalars['DateTime']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly DivisionTerm?: InputMaybe<Scalars['String']>;
  readonly Due_Date?: InputMaybe<Scalars['DateTime']>;
  readonly Entity_Id?: InputMaybe<Scalars['String']>;
  readonly Golive_Date?: InputMaybe<Scalars['DateTime']>;
  readonly Last_Closed_Date?: InputMaybe<Scalars['DateTime']>;
  readonly Name?: InputMaybe<Scalars['String']>;
  readonly Pricing_StrategyTerm?: InputMaybe<Scalars['String']>;
  readonly Projected_Margin?: InputMaybe<Scalars['Float']>;
  readonly Projected_Revenue?: InputMaybe<Scalars['Float']>;
  readonly Projected_Volume?: InputMaybe<Scalars['Float']>;
  readonly Revisit_Date?: InputMaybe<Scalars['DateTime']>;
  readonly Solution_TypeTerm?: InputMaybe<Scalars['String']>;
  readonly StageTerm?: InputMaybe<Scalars['String']>;
  readonly StatusTerm?: InputMaybe<Scalars['String']>;
  readonly Total_Opportunity_Revenue?: InputMaybe<Scalars['Float']>;
  readonly Total_Opportunity_Volume?: InputMaybe<Scalars['Float']>;
  readonly TypeTerm?: InputMaybe<Scalars['String']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly crm_Opportunity_Business_Unit?: InputMaybe<ReadonlyArray<SalesOpportunityBusinessUnitInput>>;
  readonly crm_Opportunity_Contact?: InputMaybe<ReadonlyArray<SalesOpportunityContactInput>>;
  readonly crm_Opportunity_Equipment?: InputMaybe<ReadonlyArray<SalesOpportunityEquipmentInput>>;
  readonly crm_Opportunity_IBRegion?: InputMaybe<ReadonlyArray<SalesOpportunityIbRegionInput>>;
  readonly crm_Opportunity_Mode?: InputMaybe<ReadonlyArray<SalesOpportunityModeInput>>;
  readonly crm_Opportunity_OBRegion?: InputMaybe<ReadonlyArray<SalesOpportunityObRegionInput>>;
  readonly crm_Opportunity_Reps?: InputMaybe<ReadonlyArray<SalesOpportunityRepsInput>>;
  readonly crm_Opportunity_Size?: InputMaybe<ReadonlyArray<SalesOpportunitySizeInput>>;
  readonly crm_Opportunity_Solution?: InputMaybe<ReadonlyArray<SalesOpportunitySolutionInput>>;
};

export type SalesOpportunityInputV2 = {
  readonly awardDate?: InputMaybe<Scalars['DateTime']>;
  readonly closeReasonTerm?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly crmOpportunityBusinessUnit?: InputMaybe<ReadonlyArray<SalesOpportunityBusinessUnitInputV2>>;
  readonly crmOpportunityContact?: InputMaybe<ReadonlyArray<SalesOpportunityContactInputV2>>;
  readonly crmOpportunityDocument?: InputMaybe<ReadonlyArray<SalesOpportunityDocumentInputV2>>;
  readonly crmOpportunityEquipment?: InputMaybe<ReadonlyArray<SalesOpportunityEquipmentInputV2>>;
  readonly crmOpportunityIbRegion?: InputMaybe<ReadonlyArray<SalesOpportunityIbRegionInputV2>>;
  readonly crmOpportunityMode?: InputMaybe<ReadonlyArray<SalesOpportunityModeInputV2>>;
  readonly crmOpportunityObRegion?: InputMaybe<ReadonlyArray<SalesOpportunityObRegionInputV2>>;
  readonly crmOpportunityReps?: InputMaybe<ReadonlyArray<SalesOpportunityRepsInputV2>>;
  readonly crmOpportunitySize?: InputMaybe<ReadonlyArray<SalesOpportunitySizeInputV2>>;
  readonly crmOpportunitySolution?: InputMaybe<ReadonlyArray<SalesOpportunitySolutionInputV2>>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly dueDate?: InputMaybe<Scalars['DateTime']>;
  readonly entityId?: InputMaybe<Scalars['String']>;
  readonly entityLookupId?: InputMaybe<Scalars['String']>;
  readonly goLiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly lastClosedDate?: InputMaybe<Scalars['DateTime']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly pricingStrategyTerm?: InputMaybe<Scalars['String']>;
  readonly projectedMargin?: InputMaybe<Scalars['Float']>;
  readonly projectedRevenue?: InputMaybe<Scalars['Float']>;
  readonly projectedVolume?: InputMaybe<Scalars['Float']>;
  readonly revisitDate?: InputMaybe<Scalars['DateTime']>;
  readonly solutionTypeTerm?: InputMaybe<Scalars['String']>;
  readonly sourceTerm?: InputMaybe<Scalars['String']>;
  readonly stageTerm?: InputMaybe<Scalars['String']>;
  readonly statusTerm?: InputMaybe<Scalars['String']>;
  readonly totalOpportunityRevenue?: InputMaybe<Scalars['Float']>;
  readonly totalOpportunityVolume?: InputMaybe<Scalars['Float']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunityModeInput = {
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly ModeTerm?: InputMaybe<Scalars['String']>;
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunityModeInputV2 = {
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly modeTerm?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunityModeOutput = {
  readonly __typename?: 'salesOpportunityModeOutput';
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Created_By?: Maybe<User>;
  readonly ModeTerm?: Maybe<Scalars['String']>;
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly Opportunity_Mode_Id: Scalars['ID'];
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_By?: Maybe<User>;
};

export type SalesOpportunityModeOutputV2 = {
  readonly __typename?: 'SalesOpportunityModeOutputV2';
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly modeTerm?: Maybe<Scalars['String']>;
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly opportunityModeId: Scalars['ID'];
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
};

export type SalesOpportunityObRegionInput = {
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly OB_Regions_Data?: InputMaybe<Scalars['String']>;
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly Region?: InputMaybe<Scalars['String']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunityObRegionInputV2 = {
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly obRegionsData?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunityObRegionOutput = {
  readonly __typename?: 'salesOpportunityOBRegionOutput';
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Created_By?: Maybe<User>;
  readonly OB_Regions_Data?: Maybe<Scalars['String']>;
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly Opportunity_OBRegion_Id: Scalars['ID'];
  /** @deprecated Region is deprecated, Use OB_Regions_Data instead */
  readonly Region?: Maybe<Scalars['String']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_By?: Maybe<User>;
};

export type SalesOpportunityObRegionOutputV2 = {
  readonly __typename?: 'SalesOpportunityOBRegionOutputV2';
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly obRegionsData?: Maybe<Scalars['String']>;
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly opportunityOBRegionId: Scalars['ID'];
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
};

export type SalesOpportunityOutput = {
  readonly __typename?: 'SalesOpportunityOutput';
  readonly Award_Date?: Maybe<Scalars['DateTime']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Created_By?: Maybe<User>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly DivisionTerm?: Maybe<Scalars['String']>;
  readonly Due_Date?: Maybe<Scalars['DateTime']>;
  readonly Entity_Id: Scalars['String'];
  readonly Golive_Date?: Maybe<Scalars['DateTime']>;
  readonly Last_Closed_Date?: Maybe<Scalars['DateTime']>;
  readonly Name?: Maybe<Scalars['String']>;
  readonly Opportunity_Id: Scalars['ID'];
  readonly Pricing_StrategyTerm?: Maybe<Scalars['String']>;
  readonly Projected_Margin?: Maybe<Scalars['Float']>;
  readonly Projected_Revenue?: Maybe<Scalars['Float']>;
  readonly Projected_Volume?: Maybe<Scalars['Float']>;
  readonly Revisit_Date?: Maybe<Scalars['DateTime']>;
  readonly Solution_TypeTerm?: Maybe<Scalars['String']>;
  readonly StageTerm?: Maybe<Scalars['String']>;
  readonly StatusTerm?: Maybe<Scalars['String']>;
  readonly Total_Opportunity_Revenue?: Maybe<Scalars['Float']>;
  readonly Total_Opportunity_Volume?: Maybe<Scalars['Float']>;
  readonly TypeTerm?: Maybe<Scalars['String']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_By?: Maybe<User>;
  readonly crm_Opportunity_Business_Unit?: Maybe<ReadonlyArray<SalesOpportunityBusinessUnitOutput>>;
  readonly crm_Opportunity_Contact?: Maybe<ReadonlyArray<SalesOpportunityContactOutput>>;
  readonly crm_Opportunity_Equipment?: Maybe<ReadonlyArray<SalesOpportunityEquipmentOutput>>;
  readonly crm_Opportunity_IBRegion?: Maybe<ReadonlyArray<SalesOpportunityIbRegionOutput>>;
  readonly crm_Opportunity_Mode?: Maybe<ReadonlyArray<SalesOpportunityModeOutput>>;
  readonly crm_Opportunity_OBRegion?: Maybe<ReadonlyArray<SalesOpportunityObRegionOutput>>;
  readonly crm_Opportunity_Reps?: Maybe<ReadonlyArray<SalesOpportunityRepsOutput>>;
  readonly crm_Opportunity_Size?: Maybe<ReadonlyArray<SalesOpportunitySizeOutput>>;
  readonly crm_Opportunity_Solution?: Maybe<ReadonlyArray<SalesOpportunitySolutionOutput>>;
};

export type SalesOpportunityOutputV2 = {
  readonly __typename?: 'SalesOpportunityOutputV2';
  readonly awardDate?: Maybe<Scalars['DateTime']>;
  readonly closeReasonTerm?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly crmOpportunityBusinessUnit?: Maybe<ReadonlyArray<SalesOpportunityBusinessUnitOutputV2>>;
  readonly crmOpportunityContact?: Maybe<ReadonlyArray<SalesOpportunityContactOutputV2>>;
  readonly crmOpportunityDocument?: Maybe<ReadonlyArray<SalesOpportunityDocumentOutputV2>>;
  readonly crmOpportunityEquipment?: Maybe<ReadonlyArray<SalesOpportunityEquipmentOutputV2>>;
  readonly crmOpportunityIbRegion?: Maybe<ReadonlyArray<SalesOpportunityIbRegionOutputV2>>;
  readonly crmOpportunityMode?: Maybe<ReadonlyArray<SalesOpportunityModeOutputV2>>;
  readonly crmOpportunityObRegion?: Maybe<ReadonlyArray<SalesOpportunityObRegionOutputV2>>;
  readonly crmOpportunityReps?: Maybe<ReadonlyArray<SalesOpportunityRepsOutputV2>>;
  readonly crmOpportunitySize?: Maybe<ReadonlyArray<SalesOpportunitySizeOutputV2>>;
  readonly crmOpportunitySolution?: Maybe<ReadonlyArray<SalesOpportunitySolutionOutputV2>>;
  readonly details?: Maybe<Scalars['String']>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  readonly dueDate?: Maybe<Scalars['DateTime']>;
  readonly entityId: Scalars['String'];
  readonly entityLookupId?: Maybe<Scalars['String']>;
  readonly goLiveDate?: Maybe<Scalars['DateTime']>;
  readonly lastClosedDate?: Maybe<Scalars['DateTime']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly opportunityId: Scalars['ID'];
  readonly pricingStrategyTerm?: Maybe<Scalars['String']>;
  readonly projectedMargin?: Maybe<Scalars['Float']>;
  readonly projectedRevenue?: Maybe<Scalars['Float']>;
  readonly projectedVolume?: Maybe<Scalars['Float']>;
  readonly revisitDate?: Maybe<Scalars['DateTime']>;
  readonly solutionTypeTerm?: Maybe<Scalars['String']>;
  readonly sourceTerm?: Maybe<Scalars['String']>;
  readonly stageTerm?: Maybe<Scalars['String']>;
  readonly statusTerm?: Maybe<Scalars['String']>;
  readonly totalOpportunityRevenue?: Maybe<Scalars['Float']>;
  readonly totalOpportunityVolume?: Maybe<Scalars['Float']>;
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
};

export type SalesOpportunityOutputV2Edge = {
  readonly __typename?: 'SalesOpportunityOutputV2Edge';
  readonly cursor: Scalars['String'];
  readonly node: SalesOpportunityOutputV2;
};

export type SalesOpportunityRepsInput = {
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly Rep_Id?: InputMaybe<Scalars['String']>;
  readonly Rep_Name?: InputMaybe<Scalars['String']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunityRepsInputV2 = {
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly rep?: InputMaybe<Scalars['String']>;
  readonly repId?: InputMaybe<Scalars['String']>;
  readonly repName?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunityRepsOutput = {
  readonly __typename?: 'salesOpportunityRepsOutput';
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Created_By?: Maybe<User>;
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly Opportunity_Rep_Id: Scalars['ID'];
  readonly Rep_Id: Scalars['String'];
  readonly Rep_Name?: Maybe<Scalars['String']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_By?: Maybe<User>;
};

export type SalesOpportunityRepsOutputV2 = {
  readonly __typename?: 'SalesOpportunityRepsOutputV2';
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly opportunityRepId: Scalars['ID'];
  readonly rep?: Maybe<Employee>;
  /** @deprecated repId is deprecated, Use rep instead */
  readonly repId: Scalars['String'];
  /** @deprecated repName is deprecated as we are not using */
  readonly repName?: Maybe<Scalars['String']>;
  readonly repV2?: Maybe<EmployeeV2>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
};

export type SalesOpportunitySizeInput = {
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly SizeTerm?: InputMaybe<Scalars['String']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunitySizeInputV2 = {
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly sizeTerm?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunitySizeOutput = {
  readonly __typename?: 'salesOpportunitySizeOutput';
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Created_By?: Maybe<User>;
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly Opportunity_Size_Id: Scalars['ID'];
  readonly SizeTerm?: Maybe<Scalars['String']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_By?: Maybe<User>;
};

export type SalesOpportunitySizeOutputV2 = {
  readonly __typename?: 'SalesOpportunitySizeOutputV2';
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly opportunitySizeId: Scalars['ID'];
  readonly sizeTerm?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
};

export type SalesOpportunitySolutionInput = {
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly SolutionTerm?: InputMaybe<Scalars['String']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunitySolutionInputV2 = {
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly solutionTerm?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

export type SalesOpportunitySolutionOutput = {
  readonly __typename?: 'salesOpportunitySolutionOutput';
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Created_By?: Maybe<User>;
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly Opportunity_Solution_Id: Scalars['ID'];
  readonly SolutionTerm?: Maybe<Scalars['String']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_By?: Maybe<User>;
};

export type SalesOpportunitySolutionOutputV2 = {
  readonly __typename?: 'SalesOpportunitySolutionOutputV2';
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly opportunitySolutionId: Scalars['ID'];
  readonly solutionTerm?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
};

export type SanitizedEmailHtml = {
  readonly __typename?: 'SanitizedEmailHtml';
  readonly body: Scalars['String'];
  readonly subject: Scalars['String'];
  readonly errors: ReadonlyArray<Maybe<Scalars['String']>>;
};

export type SaveCarrierAccessorialSettingsInput = {
  readonly id: Scalars['ID'];
  readonly ruleEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type SaveCarrierAccessorialSettingsPayload = {
  readonly __typename?: 'SaveCarrierAccessorialSettingsPayload';
  readonly updateSuccessful?: Maybe<Scalars['Boolean']>;
};

export type SaveChannelLegScheduleUnitInput = {
  readonly day: LegScheduleDay;
  readonly time: Scalars['LocalTime'];
};

export type SaveChannelLegStopInput = {
  /** A valid Facility ID. This will affect the distinctiveness of the leg. */
  readonly facilityId: Scalars['ID'];
  readonly canDropTrailer: Scalars['Boolean'];
  readonly networkEntryExit: Scalars['Boolean'];
};

export type SaveCustomerAccessorialSettingsInput = {
  readonly id: Scalars['ID'];
  readonly ruleEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type SaveCustomerAccessorialSettingsPayload = {
  readonly __typename?: 'SaveCustomerAccessorialSettingsPayload';
  readonly updateSuccessful?: Maybe<Scalars['Boolean']>;
};

export type SaveCustomerRatingSourceInput = {
  readonly customerId: Scalars['ID'];
  readonly ratingSourceId: Scalars['String'];
  readonly savedByUserId: Scalars['ID'];
};

export type SaveCustomerRatingSourcePayload = {
  readonly __typename?: 'SaveCustomerRatingSourcePayload';
  readonly customerId: Scalars['ID'];
  readonly ratingSourceId?: Maybe<Scalars['String']>;
};

export type SavedFilter = {
  readonly __typename?: 'SavedFilter';
  /** Unique ID generated by database */
  readonly _id: Scalars['ID'];
  /** User defined filter name */
  readonly filterName: Scalars['String'];
  /** Short description for filter */
  readonly description?: Maybe<Scalars['String']>;
  /** JSON value of table filters */
  readonly filters: Scalars['JSON'];
  /** Represents default filter status */
  readonly isDefault: Scalars['Boolean'];
  readonly managedBy?: Maybe<SavedFilterGroupManagedBy>;
  /** Filter creation DateTime */
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Filter creation DateTime */
  readonly lastModified?: Maybe<Scalars['ISO8601DateTime']>;
  /** Email address of filter creator */
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly status?: Maybe<SavedFilterGroupStatus>;
};

export type SavedFilterEdge = {
  readonly __typename?: 'SavedFilterEdge';
  readonly cursor: Scalars['String'];
  readonly node: SavedFilter;
};

export type SavedFilterGroupInputType = {
  /** Unique ID generated by database */
  readonly _id?: InputMaybe<Scalars['ID']>;
  /** Table ID from frontend */
  readonly objectId: Scalars['ID'];
  /** User defined filter name */
  readonly filterName?: InputMaybe<Scalars['String']>;
  /** Represents default filter status */
  readonly isDefault?: InputMaybe<Scalars['Boolean']>;
  readonly status?: InputMaybe<SavedFilterGroupStatus>;
};

/** Filter group managed by */
export enum SavedFilterGroupManagedBy {
  User = 'USER'
}

export type SavedFilterGroupSortBy = {
  readonly createdAt?: InputMaybe<SavedFilterSortDirection>;
  readonly lastModified?: InputMaybe<SavedFilterSortDirection>;
};

/** Filter status for saved filter */
export enum SavedFilterGroupStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type SavedFilterInputType = {
  /** Unique ID generated by database */
  readonly _id?: InputMaybe<Scalars['ID']>;
  /** Table ID from frontend */
  readonly objectId: Scalars['ID'];
  /** User defined filter name */
  readonly filterName: Scalars['String'];
  /** Represents default filter status */
  readonly isDefault: Scalars['Boolean'];
  /** JSON value of table filters */
  readonly filters: Scalars['JSON'];
  readonly managedBy?: InputMaybe<SavedFilterGroupManagedBy>;
  readonly status?: InputMaybe<SavedFilterGroupStatus>;
};

export type SavedFilterPaginationInput = {
  readonly before?: InputMaybe<Scalars['Int']>;
  readonly after?: InputMaybe<Scalars['Int']>;
};

export type SavedFilterResult = {
  readonly __typename?: 'SavedFilterResult';
  readonly edges?: Maybe<ReadonlyArray<SavedFilterEdge>>;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

/** Sort order for seer */
export enum SavedFilterSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Creates or fully updates an AttributeDefinition */
export type SaveDriverPayAttributeDefinitionInput = {
  readonly optionId: Scalars['String'];
  readonly dataType: AttributeDataTypeEnum;
  readonly minimum?: InputMaybe<Scalars['Decimal']>;
  readonly maximum?: InputMaybe<Scalars['Decimal']>;
  readonly size?: InputMaybe<Scalars['Int']>;
  readonly hasPickList?: InputMaybe<Scalars['Boolean']>;
  readonly selections?: InputMaybe<ReadonlyArray<SaveDriverPayAttributeSelectionInput>>;
  readonly defaultValue?: InputMaybe<Scalars['String']>;
};

export type SaveDriverPayAttributeSelectionInput = {
  readonly value: Scalars['String'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly isDisabled?: InputMaybe<Scalars['Boolean']>;
};

/** The input type for new Edi Tender. */
export type SaveEdiTenderInput = {
  readonly edi_transaction_id: Scalars['ID'];
  readonly shipment_identification_number: Scalars['String'];
  readonly scac: Scalars['String'];
  readonly tender_id?: InputMaybe<Scalars['ID']>;
  readonly tender_response?: InputMaybe<Scalars['String']>;
  readonly declined_reason?: InputMaybe<Scalars['String']>;
  readonly is_alchemy_tender_request?: InputMaybe<Scalars['Boolean']>;
};

/** The input type for new EDI Transaction. */
export type SaveEdiTransactionAndJsonDataToBlobInput = {
  readonly provider_id: Scalars['String'];
  readonly edi_message_type: Scalars['String'];
  readonly shipment_id?: InputMaybe<Scalars['String']>;
  readonly route_code?: InputMaybe<Scalars['String']>;
  readonly document_id?: InputMaybe<Scalars['ID']>;
  readonly request_id?: InputMaybe<Scalars['ID']>;
  readonly load_id?: InputMaybe<Scalars['String']>;
  readonly direction?: InputMaybe<Scalars['String']>;
  readonly selective_data?: InputMaybe<Scalars['String']>;
  readonly transaction_id?: InputMaybe<Scalars['String']>;
  readonly vendor?: InputMaybe<Scalars['String']>;
  readonly tracking_update_status_datetime?: InputMaybe<Scalars['DateTime']>;
  readonly is_tender_request?: InputMaybe<Scalars['Boolean']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly created_at?: InputMaybe<Scalars['DateTime']>;
  readonly model?: InputMaybe<Scalars['String']>;
  readonly outputFormat?: InputMaybe<Scalars['String']>;
};

export type SaveExternalAvailableRouteInput = {
  readonly routeNumber: Scalars['String'];
  readonly capacityNeed?: InputMaybe<ReadonlyArray<CapacityNeed>>;
  readonly customers?: InputMaybe<ReadonlyArray<ExternalAvailableRouteCustomerInput>>;
  readonly customerReps?: InputMaybe<ReadonlyArray<ExternalAvailableRouteCustomerRepInput>>;
  readonly pickup: ExternalAvailableRouteStopInput;
  readonly delivery: ExternalAvailableRouteStopInput;
  readonly routeLength?: InputMaybe<UnitOfLengthInput>;
  readonly minimumTrailerLength?: InputMaybe<AvailableRouteLengthInput>;
  readonly trailerTypes: ReadonlyArray<Scalars['String']>;
  /** default = 2 */
  readonly stopCount?: InputMaybe<Scalars['Int']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
  /** Explicit max cost (not federated) for External routes */
  readonly maxCostValue?: InputMaybe<CurrencyInput>;
  readonly externalSourceUrl?: InputMaybe<Scalars['String']>;
  readonly requirementDdtOptionNames?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly requirements?: InputMaybe<ReadonlyArray<RouteRequirementInput>>;
};

export type SavePriorityQuoteTypePayload = {
  readonly __typename?: 'SavePriorityQuoteTypePayload';
  readonly errors?: Maybe<ReadonlyArray<AbacusError>>;
  readonly priorityQuoteType?: Maybe<Scalars['String']>;
};

export type SaveRoutingGuideInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly laneId: Scalars['ID'];
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly routingGuideTypeTerm: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly duration?: InputMaybe<Scalars['Int']>;
  readonly sequenceItems: ReadonlyArray<InputMaybe<SaveRoutingGuideSequenceItemInput>>;
  readonly userId: Scalars['ID'];
};

export type SaveRoutingGuideSequenceItemInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId: Scalars['ID'];
  readonly scac: Scalars['String'];
  readonly tenderOptions?: InputMaybe<TenderOptionsInput>;
};

export type ScalarRangeInput = {
  readonly min?: InputMaybe<Scalars['Float']>;
  readonly max?: InputMaybe<Scalars['Float']>;
};

export type Schedule = {
  readonly __typename?: 'Schedule';
  readonly appointmentType: ScheduleAppointmentTypeEnum;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly dropType: ScheduleDropTypeEnum;
  /** @deprecated use trailerType */
  readonly equipmentType: KeyValue;
  /** @deprecated use trailerTypeId */
  readonly equipmentTypeId: Scalars['ID'];
  readonly friday: ScheduleEntry;
  readonly id: Scalars['ID'];
  readonly loadSize?: Maybe<KeyValue>;
  readonly loadSizeId?: Maybe<Scalars['ID']>;
  /** @deprecated use route_transport_mode */
  readonly modeType: ScheduleModeTypeEnum;
  readonly monday: ScheduleEntry;
  readonly routeTransportMode?: Maybe<KeyValue>;
  readonly routeTransportModeId?: Maybe<Scalars['ID']>;
  readonly saturday: ScheduleEntry;
  /** @deprecated use schedule_type_nullable */
  readonly scheduleType: ScheduleTypeEnum;
  readonly scheduleTypeNullable?: Maybe<Scalars['String']>;
  /** @deprecated Use serviceType instead */
  readonly service?: Maybe<KeyValue>;
  readonly serviceType?: Maybe<ServiceEnum>;
  readonly sunday: ScheduleEntry;
  readonly thursday: ScheduleEntry;
  /** @deprecated use trailer_type_nullable */
  readonly trailerType: KeyValue;
  /** @deprecated use trailer_type_id_nullable */
  readonly trailerTypeId: Scalars['ID'];
  readonly trailerTypeIdNullable?: Maybe<Scalars['ID']>;
  readonly trailerTypeNullable?: Maybe<KeyValue>;
  readonly tuesday: ScheduleEntry;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
  readonly wednesday: ScheduleEntry;
  readonly workType: ScheduleWorkTypeEnum;
};

export enum ScheduleAppointmentTypeEnum {
  /** Open */
  Open = 'open',
  /** Appointment */
  Appt = 'appt',
  /** Notice */
  Notice = 'notice'
}

export type ScheduledJob = {
  readonly __typename?: 'ScheduledJob';
  readonly name: Scalars['String'];
  readonly data: StartTrackingNotification;
  readonly type: Scalars['String'];
  readonly priority: Scalars['Int'];
  readonly nextRunAt?: Maybe<Scalars['String']>;
  readonly repeatInterval?: Maybe<Scalars['String']>;
  readonly repeatTimezone?: Maybe<Scalars['String']>;
  readonly lastModifiedBy?: Maybe<Scalars['String']>;
  readonly lastRunAt?: Maybe<Scalars['String']>;
  readonly progress?: Maybe<Scalars['Int']>;
  readonly failReason?: Maybe<Scalars['String']>;
  readonly failCount?: Maybe<Scalars['Int']>;
  readonly failedAt?: Maybe<Scalars['String']>;
  readonly lastFinishedAt?: Maybe<Scalars['String']>;
  readonly lockedAt?: Maybe<Scalars['String']>;
};

export type ScheduledJobPayload = {
  readonly __typename?: 'ScheduledJobPayload';
  readonly data?: Maybe<ReadonlyArray<Maybe<ScheduledJob>>>;
  readonly errors: ReadonlyArray<AgendaUserError>;
};

/** The ScheduleDropType values used for liveType */
export enum ScheduleDropTypeEnum {
  /** Bobtail, Hook Empty */
  BobtailHookEmpty = 'bobtailHookEmpty',
  /** Bobtail, Hook Loaded */
  BobtailHookLoaded = 'bobtailHookLoaded',
  /** Drop Available */
  DropAvail = 'dropAvail',
  /** Drop Empty, Bobtail */
  DropEmptyBobtail = 'dropEmptyBobtail',
  /** Drop Empty, Hook Loaded */
  DropEmptyHookLoaded = 'dropEmptyHookLoaded',
  /** Drop Empty & Wait */
  DropEmptyWait = 'dropEmptyWait',
  /** Drop Loaded, Bobtail */
  DropLoadedBobtail = 'dropLoadedBobtail',
  /** Drop Loaded, Hook Empty */
  DropLoadedHookEmpty = 'dropLoadedHookEmpty',
  /** Drop Loaded & Wait */
  DropLoadedWait = 'dropLoadedWait',
  /** Drop Only */
  DropOnly = 'dropOnly',
  /** Live */
  Live = 'live'
}

export type ScheduleEntry = {
  readonly __typename?: 'ScheduleEntry';
  readonly closed: Scalars['Boolean'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly hours: ReadonlyArray<ScheduleRange>;
  readonly id: Scalars['ID'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
};

export type ScheduleEntryInput = {
  readonly closed: Scalars['Boolean'];
  readonly hours: ReadonlyArray<ScheduleRangeInput>;
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type ScheduleEntryInputV2 = {
  readonly closed: Scalars['Boolean'];
  readonly hours: ReadonlyArray<ScheduleRangeInputV2>;
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type ScheduleEntryV2 = {
  readonly __typename?: 'ScheduleEntryV2';
  readonly closed: Scalars['Boolean'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly hours: ReadonlyArray<ScheduleRangeV2>;
  readonly id: Scalars['ID'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
};

export type ScheduleInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly appointmentType: ScheduleAppointmentTypeEnum;
  readonly dropType: ScheduleDropTypeEnum;
  readonly equipmentTypeId?: InputMaybe<Scalars['ID']>;
  readonly friday: ScheduleEntryInput;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly loadSizeId?: InputMaybe<Scalars['ID']>;
  /** Deprecated, use route_transport_mode_id */
  readonly modeType: ScheduleModeTypeEnum;
  readonly monday: ScheduleEntryInput;
  readonly routeTransportModeId?: InputMaybe<Scalars['ID']>;
  readonly saturday: ScheduleEntryInput;
  /** Deprecated, use schedule_type_nullable */
  readonly scheduleType: ScheduleTypeEnum;
  readonly scheduleTypeNullable?: InputMaybe<Scalars['String']>;
  /** Deprecated, use serviceType */
  readonly service?: InputMaybe<ServiceEnum>;
  readonly serviceType?: InputMaybe<ServiceEnum>;
  readonly sunday: ScheduleEntryInput;
  readonly thursday: ScheduleEntryInput;
  /** Deprecated, use trailer_type_id_nullable */
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeIdNullable?: InputMaybe<Scalars['ID']>;
  readonly tuesday: ScheduleEntryInput;
  readonly wednesday: ScheduleEntryInput;
  readonly workType: ScheduleWorkTypeEnum;
};

export type ScheduleInputV2 = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  /** The field `appointmentType` is deprecated. Use `appointmentTypes` instead. */
  readonly appointmentType: ScheduleAppointmentTypeEnum;
  readonly appointmentTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly serviceType?: InputMaybe<Scalars['String']>;
  /** The field `dropType` is deprecated. Use `dropTypes` instead. */
  readonly dropType: ScheduleDropTypeEnum;
  readonly dropTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equipmentTypeId?: InputMaybe<Scalars['ID']>;
  readonly friday: ScheduleEntryInputV2;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly modeType: ScheduleModeTypeEnum;
  readonly monday: ScheduleEntryInputV2;
  readonly saturday: ScheduleEntryInputV2;
  readonly scheduleType: ScheduleTypeEnum;
  readonly sunday: ScheduleEntryInputV2;
  readonly thursday: ScheduleEntryInputV2;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly tuesday: ScheduleEntryInputV2;
  readonly wednesday: ScheduleEntryInputV2;
  /** The field `workType` is deprecated. Use `workTypes` instead. */
  readonly workType: ScheduleWorkTypeEnum;
  readonly workTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** The field `loadSizeId` is deprecated. Use `loadSizes` instead. */
  readonly loadSizeId?: InputMaybe<Scalars['ID']>;
  readonly routeTransportModeId?: InputMaybe<Scalars['ID']>;
  /** The field `scheduleTypeNullable` is deprecated. Use `scheduleTypes` instead. */
  readonly scheduleTypeNullable?: InputMaybe<ScheduleTypeEnum>;
  readonly scheduleTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** The field `trailerTypeIdNullable` is deprecated. Use `trailerTypes` instead. */
  readonly trailerTypeIdNullable?: InputMaybe<Scalars['ID']>;
  readonly trailerTypes?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** The field `temperature` is deprecated. Use `temperatures` instead. */
  readonly temperature?: InputMaybe<Scalars['String']>;
  readonly temperatures?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadSizes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum ScheduleModeTypeEnum {
  /** All Modes */
  AllModes = 'allModes',
  /** Intermodal */
  Imdl = 'imdl',
  /** Less Than Truck Load */
  Ltl = 'ltl',
  /** Truck Load */
  Tl = 'tl'
}

export type ScheduleRange = {
  readonly __typename?: 'ScheduleRange';
  readonly endOffsetMs: Scalars['Int'];
  readonly id: Scalars['ID'];
  readonly startOffsetMs: Scalars['Int'];
};

export type ScheduleRangeInput = {
  readonly endOffsetMs: Scalars['Int'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly startOffsetMs: Scalars['Int'];
};

export type ScheduleRangeInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly startOffsetMs: Scalars['Int'];
  readonly endOffsetMs: Scalars['Int'];
};

export type ScheduleRangeV2 = {
  readonly __typename?: 'ScheduleRangeV2';
  readonly id: Scalars['ID'];
  readonly startOffsetMs: Scalars['Int'];
  readonly endOffsetMs: Scalars['Int'];
};

export type Schedules = {
  readonly __typename?: 'Schedules';
  readonly id: Scalars['ID'];
  readonly serviceProfileId: Scalars['Float'];
  readonly gcMonTime: Scalars['DateTime'];
  readonly gcTuesTime: Scalars['DateTime'];
  readonly gcWedTime: Scalars['DateTime'];
  readonly gcThursTime: Scalars['DateTime'];
  readonly gcFriTime: Scalars['DateTime'];
  readonly gcSatTime: Scalars['DateTime'];
  readonly gcSunTime: Scalars['DateTime'];
  readonly eaDayOfMon: Scalars['String'];
  readonly eaMonTime: Scalars['DateTime'];
  readonly monIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfTues: Scalars['String'];
  readonly eaTuesTime: Scalars['DateTime'];
  readonly tuesIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfWed: Scalars['String'];
  readonly eaWedTime: Scalars['DateTime'];
  readonly wedIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfThurs: Scalars['String'];
  readonly eaThursTime: Scalars['DateTime'];
  readonly thursIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfFri: Scalars['String'];
  readonly eaFriTime: Scalars['DateTime'];
  readonly friIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfSat: Scalars['String'];
  readonly eaSatTime: Scalars['DateTime'];
  readonly satIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfSun: Scalars['String'];
  readonly eaSunTime: Scalars['DateTime'];
  readonly sunIsClosed?: Maybe<Scalars['Boolean']>;
  readonly serviceId?: Maybe<Scalars['String']>;
};

export type SchedulesV2 = {
  readonly __typename?: 'SchedulesV2';
  readonly id: Scalars['String'];
  readonly serviceProfileId: Scalars['Float'];
  readonly gcMonTime?: Maybe<Scalars['DateTime']>;
  readonly gcTuesTime?: Maybe<Scalars['DateTime']>;
  readonly gcWedTime?: Maybe<Scalars['DateTime']>;
  readonly gcThursTime?: Maybe<Scalars['DateTime']>;
  readonly gcFriTime?: Maybe<Scalars['DateTime']>;
  readonly gcSatTime?: Maybe<Scalars['DateTime']>;
  readonly gcSunTime?: Maybe<Scalars['DateTime']>;
  readonly eaDayOfMon?: Maybe<Scalars['String']>;
  readonly eaMonTime?: Maybe<Scalars['DateTime']>;
  readonly monIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfTues?: Maybe<Scalars['String']>;
  readonly eaTuesTime?: Maybe<Scalars['DateTime']>;
  readonly tuesIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfWed?: Maybe<Scalars['String']>;
  readonly eaWedTime?: Maybe<Scalars['DateTime']>;
  readonly wedIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfThurs?: Maybe<Scalars['String']>;
  readonly eaThursTime?: Maybe<Scalars['DateTime']>;
  readonly thursIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfFri?: Maybe<Scalars['String']>;
  readonly eaFriTime?: Maybe<Scalars['DateTime']>;
  readonly friIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfSat?: Maybe<Scalars['String']>;
  readonly eaSatTime?: Maybe<Scalars['DateTime']>;
  readonly satIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfSun?: Maybe<Scalars['String']>;
  readonly eaSunTime?: Maybe<Scalars['DateTime']>;
  readonly sunIsClosed?: Maybe<Scalars['Boolean']>;
};

export enum ScheduleTypeEnum {
  /** Both */
  Both = 'both',
  /** Inbound */
  In = 'in',
  /** Outbound */
  Out = 'out'
}

export type ScheduleV2 = {
  readonly __typename?: 'ScheduleV2';
  /** @deprecated Use `appointmentTypes` when ME-92845 is live. */
  readonly appointmentType: ScheduleAppointmentTypeEnum;
  readonly appointmentTypes?: Maybe<ReadonlyArray<KeyValue>>;
  readonly serviceType?: Maybe<Scalars['String']>;
  readonly facilityId: Scalars['ID'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  /** @deprecated Use `dropTypes` when ME-92845 is live. */
  readonly dropType: ScheduleDropTypeEnum;
  readonly dropTypes?: Maybe<ReadonlyArray<KeyValue>>;
  readonly friday: ScheduleEntryV2;
  readonly id: Scalars['ID'];
  readonly modeType: ScheduleModeTypeEnum;
  readonly monday: ScheduleEntryV2;
  readonly saturday: ScheduleEntryV2;
  readonly scheduleType: ScheduleTypeEnum;
  readonly sunday: ScheduleEntryV2;
  readonly thursday: ScheduleEntryV2;
  readonly trailerType: KeyValue;
  readonly trailerTypeId: Scalars['ID'];
  readonly tuesday: ScheduleEntryV2;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly wednesday: ScheduleEntryV2;
  /** @deprecated Use `workTypes` when ME-92845 is live. */
  readonly workType: ScheduleWorkTypeEnum;
  readonly workTypes?: Maybe<ReadonlyArray<KeyValue>>;
  /** @deprecated Use `loadSizes` when ME-92845 is live. */
  readonly loadSize?: Maybe<KeyValue>;
  /** @deprecated Use `loadSizes` when ME-92845 is live. */
  readonly loadSizeId?: Maybe<Scalars['ID']>;
  readonly loadSizes?: Maybe<ReadonlyArray<KeyValue>>;
  readonly equipmentType: KeyValue;
  readonly equipmentTypeId: Scalars['ID'];
  readonly routeTransportModeId?: Maybe<Scalars['ID']>;
  readonly routeTransportMode?: Maybe<KeyValue>;
  /** @deprecated Use `temperatures` instead. */
  readonly temperatureId?: Maybe<Scalars['String']>;
  /** @deprecated Use `temperatures` instead. */
  readonly temperature?: Maybe<KeyValue>;
  readonly temperatures?: Maybe<ReadonlyArray<KeyValue>>;
  /** @deprecated Use `scheduleTypes` when ME-92845 is live. */
  readonly scheduleTypeNullable?: Maybe<ScheduleTypeEnum>;
  readonly scheduleTypes?: Maybe<ReadonlyArray<KeyValue>>;
  /** @deprecated Use `trailerTypes` when ME-92845 is live. */
  readonly trailerTypeNullable?: Maybe<KeyValue>;
  readonly trailerTypes?: Maybe<ReadonlyArray<KeyValue>>;
  readonly trailerTypeIdNullable?: Maybe<Scalars['ID']>;
};

export enum ScheduleWorkTypeEnum {
  /** Driver Assist */
  DriverAssist = 'driverAssist',
  /** Lumper */
  Lumper = 'lumper',
  /** No Touch */
  NoTouch = 'noTouch'
}

export type SchedulingAddressFilter = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly stateCollection?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type SchedulingLog = {
  readonly __typename?: 'SchedulingLog';
  readonly changedBy?: Maybe<Employee>;
  readonly changedByV2?: Maybe<EmployeeV2>;
  readonly confirmed: Scalars['Boolean'];
  readonly requestedBy?: Maybe<Employee>;
  readonly requestedByV2?: Maybe<EmployeeV2>;
  /** @deprecated Marked as deprecated, refer to the RouteStopV2.apptReqWith field instead */
  readonly requestedWith?: Maybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly autoSchedule?: Maybe<Scalars['Boolean']>;
  readonly scheduleReason?: Maybe<Scalars['String']>;
  readonly serviceTolerance?: Maybe<StopServiceTolerance>;
  readonly id: Scalars['ID'];
  readonly type: Scalars['String'];
  readonly category: Scalars['String'];
  readonly routeStopId: Scalars['ID'];
  readonly appointmentId?: Maybe<Scalars['ID']>;
  readonly endDate?: Maybe<DatetimeWithTimezone>;
  readonly startDate?: Maybe<DatetimeWithTimezone>;
  readonly timeRange?: Maybe<ScheduleRange>;
  readonly reason?: Maybe<Scalars['String']>;
};

export type SchedulingOrderCount = {
  readonly __typename?: 'SchedulingOrderCount';
  readonly orderCount?: Maybe<Scalars['Float']>;
};

export type ScopeInput = {
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly routeTransportMode?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly equipments?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type SearchAssetDriverInput = {
  readonly name?: InputMaybe<Scalars['String']>;
  readonly countryCd?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly professionalTypeTerm?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly region?: InputMaybe<Scalars['String']>;
  readonly radius?: InputMaybe<Scalars['String']>;
  readonly distanceType?: InputMaybe<Scalars['String']>;
  readonly loadOrRouteNumber?: InputMaybe<Scalars['String']>;
  readonly serviceStatus?: InputMaybe<Scalars['String']>;
  readonly statusTerm?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly divisionsTerm?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly businessUnitsTerm?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly fleet?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
};

export type SearchAuditHistoryInput = {
  readonly recordId: Scalars['String'];
};

export type SearchAuditHistoryResult = {
  readonly __typename?: 'SearchAuditHistoryResult';
  readonly auditHistory?: Maybe<ReadonlyArray<Maybe<AuditHistory>>>;
};

export type SearchCanvasConfigurationInput = {
  /** Scope to which this validator belongs to such as a tenant, carrier or specific customer. Corresponds to the scopedEntityUuid */
  readonly configScope: ConfigScope;
  /** Unique Identifier of the entity this validator belongs to. Expected to be null when configScope is tenant level */
  readonly scopedEntityUuid?: InputMaybe<Scalars['String']>;
  /** Search criteria for `Order` Entity and `Invoice Generation` Action */
  readonly orderInvoiceGenerationInput?: InputMaybe<OrderInvoiceGenerationInput>;
  /** Search criteria for `Order` Entity and `Invoice Hold` Action */
  readonly orderInvoiceHoldInput?: InputMaybe<OrderInvoiceHoldInput>;
  /** Search criteria for `Vendor Assignment` Entity and `Save Input` Action */
  readonly vendorAssignmentSaveInput?: InputMaybe<VendorAssignmentSaveInput>;
  /** Search criteria for `ExpectedEmptyInformation` Entity and `Save Carrier Details` Action */
  readonly expectedEmptyInformationSaveCarrierDetailsInput?: InputMaybe<ExpectedEmptyInformationSaveCarrierDetailsInput>;
  /** Search criteria for `Route Vendor` Entity and `Voucher Hold` Action */
  readonly routeVendorVoucherHoldInput?: InputMaybe<RouteVendorVoucherHoldInput>;
  /** Search criteria for `Route` Entity and `Assign Driver` Action */
  readonly routeAssignDriverInput?: InputMaybe<RouteAssignDriverInput>;
};

export type SearchCanvasConfigurationsByKeysInput = {
  /** Unique Identifier of the entity this validator belongs to. Expected to be null when configScope is tenant level */
  readonly scopedEntityUuid?: InputMaybe<Scalars['String']>;
  /** Scope to which this validator belongs to such as a tenant, carrier or specific customer. Corresponds to the scopedEntityUuid */
  readonly configScope: ConfigScope;
  /** Type of entity this search applies to, such as an Order or Customer */
  readonly entity: Scalars['String'];
  /** Type of action this search applies to, such as an Invoice Generation or Save Input */
  readonly action: Scalars['String'];
};

export type SearchDriverInput = {
  readonly name?: InputMaybe<Scalars['String']>;
  readonly countryCd?: InputMaybe<Scalars['String']>;
  readonly primaryPhoneNumber?: InputMaybe<Scalars['String']>;
  readonly driverIdentityId?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly professionType?: InputMaybe<Scalars['String']>;
  readonly professionalTypeTerm?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly region?: InputMaybe<Scalars['String']>;
  readonly radius?: InputMaybe<Scalars['String']>;
  readonly distanceType?: InputMaybe<Scalars['String']>;
  readonly loadOrRouteNumber?: InputMaybe<Scalars['String']>;
  readonly serviceStatus?: InputMaybe<Scalars['String']>;
  readonly statusTerm?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly segment?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly fleet?: InputMaybe<Scalars['String']>;
};

export type SearchDriverPayload = {
  readonly __typename?: 'SearchDriverPayload';
  readonly edges: ReadonlyArray<AssetDriverEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type SearchFacilityAddressInput = {
  readonly addressLine1: Scalars['String'];
  readonly locality: Scalars['String'];
  readonly administrativeArea: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country?: InputMaybe<Scalars['String']>;
  readonly countryAlpha2?: InputMaybe<Scalars['String']>;
  readonly countryAlpha3?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly minimumMatchStrength?: InputMaybe<Scalars['Int']>;
};

export type SearchFacilityResult = {
  readonly __typename?: 'SearchFacilityResult';
  readonly facilities?: Maybe<ReadonlyArray<Maybe<Facility>>>;
  readonly address?: Maybe<ResultAddress>;
  readonly resultCodes?: Maybe<ReadonlyArray<SearchFacilityResultCode>>;
};

export enum SearchFacilityResultCode {
  /** The recevied address was recognized and cleansed and the ResultAddress address field is the result of cleansing */
  CleansedAddress = 'CLEANSED_ADDRESS',
  /** The recevied address was not recognized but may have been formatted and ResultAddress address field is the formatted address */
  FormattedAddress = 'FORMATTED_ADDRESS',
  /** The recevied address was attempted to be cleansed but it was not recognized or cleansed, the ResultAddress field will be the request address */
  CleanseError = 'CLEANSE_ERROR',
  /** A facility with the address field was found, the facility address is cleansed */
  MatchedFacilityCleansed = 'MATCHED_FACILITY_CLEANSED',
  /** A facility with the address field was found, the facility address is not cleansed */
  MatchedFacilityUncleansed = 'MATCHED_FACILITY_UNCLEANSED',
  /** No facility was found with the address field */
  NoMatchedFacility = 'NO_MATCHED_FACILITY'
}

export type SearchFacilityResultV2 = {
  readonly __typename?: 'SearchFacilityResultV2';
  readonly facilities?: Maybe<ReadonlyArray<Maybe<FacilityV2>>>;
  readonly address?: Maybe<ResultAddress>;
  readonly resultCodes?: Maybe<ReadonlyArray<SearchFacilityResultCode>>;
};

export type SearchHaykEventsInput = {
  readonly name?: InputMaybe<Scalars['String']>;
  readonly eventId?: InputMaybe<Scalars['String']>;
  readonly eventType?: InputMaybe<Scalars['String']>;
  readonly serviceSource?: InputMaybe<Scalars['String']>;
  readonly includes?: InputMaybe<SearchHaykEventsInputIncludes>;
  readonly page?: InputMaybe<Scalars['Int']>;
  readonly pageSize?: InputMaybe<Scalars['Int']>;
};

export type SearchHaykEventsInputIncludes = {
  readonly ruleSets?: InputMaybe<Scalars['Boolean']>;
  readonly templates?: InputMaybe<Scalars['Boolean']>;
};

export type SearchHaykEventsResult = {
  readonly __typename?: 'SearchHaykEventsResult';
  readonly events?: Maybe<ReadonlyArray<Maybe<HaykEvent>>>;
};

export type SearchHaykOperatorsInput = {
  readonly operatorType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type SearchHaykOperatorsResult = {
  readonly __typename?: 'SearchHaykOperatorsResult';
  readonly stringValue?: Maybe<ReadonlyArray<OperatorType>>;
  readonly floatValue?: Maybe<ReadonlyArray<OperatorType>>;
  readonly booleanValue?: Maybe<ReadonlyArray<OperatorType>>;
  readonly arrayValue?: Maybe<ReadonlyArray<OperatorType>>;
  readonly dateValue?: Maybe<ReadonlyArray<OperatorType>>;
  readonly nullValue?: Maybe<ReadonlyArray<OperatorType>>;
};

export type SearchHaykRedirectsInput = {
  readonly division?: InputMaybe<Scalars['String']>;
  readonly redirectId?: InputMaybe<Scalars['String']>;
};

export type SearchHaykRedirectsResult = {
  readonly __typename?: 'SearchHaykRedirectsResult';
  readonly redirects?: Maybe<ReadonlyArray<Maybe<HaykRedirectResult>>>;
};

export type SearchHdsTemplatesInput = {
  readonly eventName: Scalars['String'];
};

export type SearchInputFilters = {
  readonly objNumber?: InputMaybe<Scalars['String']>;
  readonly objType?: InputMaybe<Scalars['String']>;
  readonly objDivision?: InputMaybe<Scalars['String']>;
  readonly objDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly objSegment?: InputMaybe<Scalars['String']>;
  readonly objFleet?: InputMaybe<Scalars['String']>;
  readonly objDriverId?: InputMaybe<Scalars['String']>;
  readonly objPool?: InputMaybe<Scalars['String']>;
};

export type SearchMatchingTrailersInput = {
  readonly searchInput: Scalars['String'];
  readonly maxRecords?: InputMaybe<Scalars['Int']>;
};

export type SearchMatchingTrailersOutput = {
  readonly __typename?: 'SearchMatchingTrailersOutput';
  readonly id: Scalars['ID'];
  readonly prefixTerm?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
};

export type SearchMatchingTrailersOutputPayload = {
  readonly __typename?: 'SearchMatchingTrailersOutputPayload';
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
  readonly trailerData?: Maybe<ReadonlyArray<SearchMatchingTrailersOutput>>;
  readonly recordCount?: Maybe<Scalars['Int']>;
};

export type SearchMembershipsFilter = {
  readonly userID?: InputMaybe<Scalars['ID']>;
  readonly organizationID?: InputMaybe<Scalars['ID']>;
  readonly populate?: InputMaybe<Scalars['Boolean']>;
  readonly explicit?: InputMaybe<Scalars['Boolean']>;
  readonly dateTimeFilters?: InputMaybe<ReadonlyArray<InputMaybe<DateTimeFilter>>>;
};

export type SearchOrganizationFilter = {
  readonly name?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly hierarchyCode?: InputMaybe<Scalars['String']>;
  readonly hierarchyCodes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly parentID?: InputMaybe<Scalars['ID']>;
  readonly hierarchyCodeExact?: InputMaybe<Scalars['Boolean']>;
  readonly nameSuffix?: InputMaybe<Scalars['Boolean']>;
  readonly dateTimeFilters?: InputMaybe<ReadonlyArray<InputMaybe<DateTimeFilter>>>;
};

export type SearchRuleSetsInput = {
  readonly eventName?: InputMaybe<Scalars['String']>;
  readonly eventId?: InputMaybe<Scalars['String']>;
  readonly ruleSetId?: InputMaybe<Scalars['String']>;
  readonly references?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly serviceSource?: InputMaybe<ServiceSource>;
  readonly includes?: InputMaybe<SearchRulesInputIncludes>;
  readonly page?: InputMaybe<Scalars['Int']>;
  readonly pageSize?: InputMaybe<Scalars['Int']>;
};

export type SearchRuleSetsResult = {
  readonly __typename?: 'SearchRuleSetsResult';
  readonly eventName?: Maybe<Scalars['String']>;
  readonly ruleSets?: Maybe<ReadonlyArray<Maybe<RuleSet>>>;
};

export type SearchRulesInputIncludes = {
  readonly factTypeSchemas?: InputMaybe<Scalars['Boolean']>;
};

export type SearchTemplatesInput = {
  readonly eventName: Scalars['String'];
  readonly templateId?: InputMaybe<Scalars['String']>;
  readonly page?: InputMaybe<Scalars['Int']>;
  readonly pageSize?: InputMaybe<Scalars['Int']>;
};

export type SearchTemplatesResult = {
  readonly __typename?: 'SearchTemplatesResult';
  readonly eventName?: Maybe<Scalars['String']>;
  readonly templates?: Maybe<ReadonlyArray<Maybe<ContentTemplate>>>;
};

/** An enum representing the types of tokens that can be created. */
export enum SecureDataAllowedTokenTypeInput {
  /** A type of token that can be created for a proprietary EFS card. */
  CardEfs = 'CARD_EFS',
  /** A type of token that can be created for a ComData proprietary card. */
  CardComdataProprietary = 'CARD_COMDATA_PROPRIETARY',
  /** A type of token that can be created for a ComData Mastercard card. */
  CardComdataMastercard = 'CARD_COMDATA_MASTERCARD',
  /** A type of token that can be created for a driver's license ID. */
  DriversLicense = 'DRIVERS_LICENSE',
  /** A type of token that can be created for a tax payer entity. */
  Ein = 'EIN'
}

/** A type representing a response for an asynchronous token request. */
export type SecureDataAsyncResponse = {
  readonly __typename?: 'SecureDataAsyncResponse';
  /** The HTTP status code of the response. */
  readonly statusCode: Scalars['Int'];
  /** The message of the response. */
  readonly message: Scalars['String'];
};

/** An input type for the driver's license entity, requires a driver's license ID and an asset driver UUID. */
export type SecureDataDriversLicenseEntityInput = {
  /** The ID that associates a MasterMind driver record with a driver record in client systems. */
  readonly driverCode: Scalars['ID'];
  /** The driver's license ID, sensitive data. */
  readonly driversLicenseId: Scalars['ID'];
  /** The driver's license state, used for validation if provided. Not stored in Basis Theory. */
  readonly driversLicenseState?: InputMaybe<Scalars['String']>;
};

/** A type representing sensitive data. */
export type SecureDataIdModel = {
  readonly __typename?: 'SecureDataIdModel';
  /** The ID that associates a MasterMind driver record with a driver record in client systems. */
  readonly driverCode?: Maybe<Scalars['ID']>;
  /** The sensitive data ID stored in Basis Theory. */
  readonly id: Scalars['ID'];
  /** The Basis Theory Token UUID for the sensitive data. */
  readonly tokenId?: Maybe<Scalars['ID']>;
  /** The type of the token requested. */
  readonly tokenType?: Maybe<Scalars['String']>;
};

/** A type representing a response for a list of driver's licenses. */
export type SecureDataIdsModel = {
  readonly __typename?: 'SecureDataIdsModel';
  /** The list of sensitive data IDs, note: order not guaranteed to be the same as input due to Basis Theory behavior. */
  readonly results: ReadonlyArray<SecureDataIdModel>;
  /** The list of token IDs that were not found. */
  readonly invalidOrMissingTokenIds: ReadonlyArray<Scalars['ID']>;
};

/** An input type for the driversLicenses query, requires a list of token IDs. */
export type SecureDataTokenDataInput = {
  /** The list of token IDs to retrieve. */
  readonly tokenIds: ReadonlyArray<Scalars['ID']>;
  /** The type of token to retrieve. */
  readonly tokenType: SecureDataAllowedTokenTypeInput;
};

/** An input type for the tokenizationConfiguration query, requires a valid token type. */
export type SecureDataTokenizationConfigurationInput = {
  /** The token type to retrieve a configuration for. */
  readonly tokenType: SecureDataAllowedTokenTypeInput;
};

/** A type representing the configuration for a given token type. */
export type SecureDataTokenizationConfigurationModel = {
  readonly __typename?: 'SecureDataTokenizationConfigurationModel';
  /** The API key used to render an iframe. */
  readonly iFrameApiKey: Scalars['ID'];
  /** The configuration field values to use when sending the token request. */
  readonly tokenRequestFields?: Maybe<SecureDataTokenRequestFieldsModel>;
};

/** A type representing tokenized data. */
export type SecureDataTokenModel = {
  readonly __typename?: 'SecureDataTokenModel';
  /** The ID to associate the transaction with others in MasterMind. If none provided in input, will use a new random ID. */
  readonly correlationId?: Maybe<Scalars['ID']>;
  /** The ID that associates a MasterMind driver record with a driver record in client systems. */
  readonly driverCode?: Maybe<Scalars['ID']>;
  /** The UTC time when the token was requested. */
  readonly requestedAt?: Maybe<Scalars['Long']>;
  /** The Basis Theory Token UUID for the sensitive data. */
  readonly tokenId: Scalars['ID'];
  /** The type of token created. */
  readonly tokenType?: Maybe<Scalars['String']>;
};

/** A type representing the fields required to request a token from Basis Theory. */
export type SecureDataTokenRequestFieldsModel = {
  readonly __typename?: 'SecureDataTokenRequestFieldsModel';
  /** The token type for the configuration, typically set to 'token'. */
  readonly type: Scalars['String'];
  /** The token Id format, used for aliasing. */
  readonly id: Scalars['String'];
  /** The containers for the sensitive data to be written to. */
  readonly containers: ReadonlyArray<Scalars['String']>;
  /** The token mask format, used to reveal a portion of the data. */
  readonly mask: Scalars['String'];
  /** The sensitive data items to index for search and how its masked. */
  readonly searchIndexes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  /** The token fingerprint expression, keeps all driver record sensitive data together in Basis Theory. */
  readonly fingerprintExpression: Scalars['String'];
};

export type SeerAccountingAdvanceProviderConfig = {
  readonly __typename?: 'SeerAccountingAdvanceProviderConfig';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly advanceProviderVendorId: Scalars['String'];
  readonly advanceProviderType?: Maybe<Scalars['String']>;
  readonly isDefault?: Maybe<Scalars['Boolean']>;
  readonly paymentType?: Maybe<Scalars['String']>;
  readonly providerExpense?: Maybe<Scalars['Float']>;
  readonly isEnabled?: Maybe<Scalars['Boolean']>;
};

export type SeerAccountingAdvanceProviderConfigEdge = {
  readonly __typename?: 'SeerAccountingAdvanceProviderConfigEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerAccountingAdvanceProviderConfig;
};

export type SeerAccountingAdvanceProviderConfigFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly advanceProviderVendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly advanceProviderType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isDefault?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly paymentType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly providerExpense?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly isEnabled?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerAccountingAdvanceProviderConfigOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly advanceProviderVendorId?: InputMaybe<SeerOrderBy>;
  readonly advanceProviderType?: InputMaybe<SeerOrderBy>;
  readonly isDefault?: InputMaybe<SeerOrderBy>;
  readonly paymentType?: InputMaybe<SeerOrderBy>;
  readonly providerExpense?: InputMaybe<SeerOrderBy>;
  readonly isEnabled?: InputMaybe<SeerOrderBy>;
};

export type SeerAccountingAdvanceProviderConfigResult = {
  readonly __typename?: 'SeerAccountingAdvanceProviderConfigResult';
  readonly edges: ReadonlyArray<SeerAccountingAdvanceProviderConfigEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerAccountingAdvances = {
  readonly __typename?: 'SeerAccountingAdvances';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly vendorId: Scalars['String'];
  readonly advanceFee?: Maybe<Scalars['Float']>;
  readonly eventType?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly loadId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly assignedTo?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly requestedBy?: Maybe<Scalars['String']>;
  readonly requestedOn?: Maybe<Scalars['DateTime']>;
  readonly neededBy?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly advanceTypeid?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly isClosed?: Maybe<Scalars['Boolean']>;
  readonly closedDateTime?: Maybe<Scalars['DateTime']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly approvalStatus?: Maybe<Scalars['String']>;
  readonly advanceProvider?: Maybe<Scalars['String']>;
  readonly amountRequested?: Maybe<Scalars['Float']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly approvedAmount?: Maybe<Scalars['Float']>;
  readonly feePercent?: Maybe<Scalars['Float']>;
  readonly netpayment?: Maybe<Scalars['Float']>;
  readonly refNumber?: Maybe<Scalars['String']>;
  readonly cashedAmount?: Maybe<Scalars['Float']>;
  readonly ppwReceived?: Maybe<Scalars['Boolean']>;
  readonly feeCalculation?: Maybe<Scalars['String']>;
  readonly flatFeeAmount?: Maybe<Scalars['Float']>;
  readonly providerExpense?: Maybe<Scalars['Float']>;
  /** Column is of type UUID */
  readonly approvedByEmployeeId?: Maybe<Scalars['String']>;
  readonly approvedOn?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly denialReasonTypeId?: Maybe<Scalars['String']>;
  readonly currencyId?: Maybe<Scalars['String']>;
  readonly feeMethod?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly driverId?: Maybe<Scalars['String']>;
  readonly driverCode?: Maybe<Scalars['String']>;
};

export type SeerAccountingAdvancesEdge = {
  readonly __typename?: 'SeerAccountingAdvancesEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerAccountingAdvances;
};

export type SeerAccountingAdvancesFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly advanceFee?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly eventType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly assignedTo?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly requestedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly requestedOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly neededBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly advanceTypeid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly comments?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isClosed?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly closedDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly status?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly approvalStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly advanceProvider?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly amountRequested?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly firstName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly approvedAmount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly feePercent?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly netpayment?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly refNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly cashedAmount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly ppwReceived?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly feeCalculation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly flatFeeAmount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly providerExpense?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  /** Column is of type UUID */
  readonly approvedByEmployeeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly approvedOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly expirationDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly denialReasonTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currencyId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly feeMethod?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly driverId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerAccountingAdvancesOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly advanceFee?: InputMaybe<SeerOrderBy>;
  readonly eventType?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly assignedTo?: InputMaybe<SeerOrderBy>;
  readonly requestedBy?: InputMaybe<SeerOrderBy>;
  readonly requestedOn?: InputMaybe<SeerOrderBy>;
  readonly neededBy?: InputMaybe<SeerOrderBy>;
  readonly advanceTypeid?: InputMaybe<SeerOrderBy>;
  readonly comments?: InputMaybe<SeerOrderBy>;
  readonly isClosed?: InputMaybe<SeerOrderBy>;
  readonly closedDateTime?: InputMaybe<SeerOrderBy>;
  readonly status?: InputMaybe<SeerOrderBy>;
  readonly approvalStatus?: InputMaybe<SeerOrderBy>;
  readonly advanceProvider?: InputMaybe<SeerOrderBy>;
  readonly amountRequested?: InputMaybe<SeerOrderBy>;
  readonly firstName?: InputMaybe<SeerOrderBy>;
  readonly lastName?: InputMaybe<SeerOrderBy>;
  readonly approvedAmount?: InputMaybe<SeerOrderBy>;
  readonly feePercent?: InputMaybe<SeerOrderBy>;
  readonly netpayment?: InputMaybe<SeerOrderBy>;
  readonly refNumber?: InputMaybe<SeerOrderBy>;
  readonly cashedAmount?: InputMaybe<SeerOrderBy>;
  readonly ppwReceived?: InputMaybe<SeerOrderBy>;
  readonly feeCalculation?: InputMaybe<SeerOrderBy>;
  readonly flatFeeAmount?: InputMaybe<SeerOrderBy>;
  readonly providerExpense?: InputMaybe<SeerOrderBy>;
  readonly approvedByEmployeeId?: InputMaybe<SeerOrderBy>;
  readonly approvedOn?: InputMaybe<SeerOrderBy>;
  readonly expirationDate?: InputMaybe<SeerOrderBy>;
  readonly denialReasonTypeId?: InputMaybe<SeerOrderBy>;
  readonly currencyId?: InputMaybe<SeerOrderBy>;
  readonly feeMethod?: InputMaybe<SeerOrderBy>;
  readonly driverId?: InputMaybe<SeerOrderBy>;
  readonly driverCode?: InputMaybe<SeerOrderBy>;
};

export type SeerAccountingAdvancesResult = {
  readonly __typename?: 'SeerAccountingAdvancesResult';
  readonly edges: ReadonlyArray<SeerAccountingAdvancesEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerAccountingAdvsStatusNotification = {
  readonly __typename?: 'SeerAccountingAdvsStatusNotification';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly refNumber?: Maybe<Scalars['String']>;
  readonly amountRequested?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
};

export type SeerAccountingAdvsStatusNotificationEdge = {
  readonly __typename?: 'SeerAccountingAdvsStatusNotificationEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerAccountingAdvsStatusNotification;
};

export type SeerAccountingAdvsStatusNotificationFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly refNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly amountRequested?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerAccountingAdvsStatusNotificationOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly refNumber?: InputMaybe<SeerOrderBy>;
  readonly amountRequested?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
};

export type SeerAccountingAdvsStatusNotificationResult = {
  readonly __typename?: 'SeerAccountingAdvsStatusNotificationResult';
  readonly edges: ReadonlyArray<SeerAccountingAdvsStatusNotificationEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerAccountingCarrierFinalizedFuel = {
  readonly __typename?: 'SeerAccountingCarrierFinalizedFuel';
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly carrierId: Scalars['String'];
  readonly status?: Maybe<Scalars['String']>;
  readonly eventType?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['DateTime'];
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn: Scalars['DateTime'];
};

export type SeerAccountingCarrierFinalizedFuelEdge = {
  readonly __typename?: 'SeerAccountingCarrierFinalizedFuelEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerAccountingCarrierFinalizedFuel;
};

export type SeerAccountingCarrierFinalizedFuelFilter = {
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly status?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eventType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly updatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerAccountingCarrierFinalizedFuelOrderBy = {
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly status?: InputMaybe<SeerOrderBy>;
  readonly eventType?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly createdOn?: InputMaybe<SeerOrderBy>;
  readonly updatedBy?: InputMaybe<SeerOrderBy>;
  readonly updatedOn?: InputMaybe<SeerOrderBy>;
};

export type SeerAccountingCarrierFinalizedFuelResult = {
  readonly __typename?: 'SeerAccountingCarrierFinalizedFuelResult';
  readonly edges: ReadonlyArray<SeerAccountingCarrierFinalizedFuelEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerAccountingCostDetails = {
  readonly __typename?: 'SeerAccountingCostDetails';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly advanceEid?: Maybe<Scalars['Int']>;
  readonly chargeType?: Maybe<Scalars['String']>;
  readonly costPerUnit?: Maybe<Scalars['Float']>;
  readonly isCredit?: Maybe<Scalars['Boolean']>;
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly routeVendorType?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
  readonly vendorGlCode?: Maybe<Scalars['String']>;
  readonly isAdjustment?: Maybe<Scalars['Boolean']>;
  readonly enteredCostPer?: Maybe<Scalars['Float']>;
  readonly enteredTotalCost?: Maybe<Scalars['Float']>;
  /** Column is of type UUID */
  readonly vendorId: Scalars['String'];
  readonly voucherHeaderEid?: Maybe<Scalars['Int']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly currencyDiscountAmount?: Maybe<Scalars['Float']>;
  readonly exchangeRate?: Maybe<Scalars['Float']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly glAccountCode?: Maybe<Scalars['String']>;
  readonly segment1?: Maybe<Scalars['String']>;
  readonly segment2?: Maybe<Scalars['String']>;
  readonly segment3?: Maybe<Scalars['String']>;
  readonly segment4?: Maybe<Scalars['String']>;
  readonly segment5?: Maybe<Scalars['String']>;
  readonly segment6?: Maybe<Scalars['String']>;
  readonly segment7?: Maybe<Scalars['String']>;
  readonly segment8?: Maybe<Scalars['String']>;
  readonly segment9?: Maybe<Scalars['String']>;
  readonly segment10?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly routeVendorId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly payProgramId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly payRateTableId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly driverId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly driverVoucherId?: Maybe<Scalars['String']>;
  readonly approvalStatus?: Maybe<Scalars['String']>;
  readonly approvedBy?: Maybe<Scalars['String']>;
  readonly approvedOn?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly chargeTypeId?: Maybe<Scalars['String']>;
};

export type SeerAccountingCostDetailsEdge = {
  readonly __typename?: 'SeerAccountingCostDetailsEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerAccountingCostDetails;
};

export type SeerAccountingCostDetailsFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly advanceEid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly chargeType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly costPerUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly isCredit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeVendorType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly status?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly units?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly vendorGlCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isAdjustment?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly enteredCostPer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly enteredTotalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly voucherHeaderEid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly code?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly description?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currency?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currencyDiscountAmount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly exchangeRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly exchangeRateDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly glAccountCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment3?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment4?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment5?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment6?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment7?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment8?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment9?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment10?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly updatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly routeVendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly payProgramId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly payRateTableId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly driverId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly driverVoucherId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly approvalStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly approvedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly approvedOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly chargeTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerAccountingCostDetailsOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly advanceEid?: InputMaybe<SeerOrderBy>;
  readonly chargeType?: InputMaybe<SeerOrderBy>;
  readonly costPerUnit?: InputMaybe<SeerOrderBy>;
  readonly isCredit?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly routeVendorType?: InputMaybe<SeerOrderBy>;
  readonly status?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
  readonly units?: InputMaybe<SeerOrderBy>;
  readonly vendorGlCode?: InputMaybe<SeerOrderBy>;
  readonly isAdjustment?: InputMaybe<SeerOrderBy>;
  readonly enteredCostPer?: InputMaybe<SeerOrderBy>;
  readonly enteredTotalCost?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly voucherHeaderEid?: InputMaybe<SeerOrderBy>;
  readonly code?: InputMaybe<SeerOrderBy>;
  readonly description?: InputMaybe<SeerOrderBy>;
  readonly currency?: InputMaybe<SeerOrderBy>;
  readonly currencyDiscountAmount?: InputMaybe<SeerOrderBy>;
  readonly exchangeRate?: InputMaybe<SeerOrderBy>;
  readonly exchangeRateDate?: InputMaybe<SeerOrderBy>;
  readonly glAccountCode?: InputMaybe<SeerOrderBy>;
  readonly segment1?: InputMaybe<SeerOrderBy>;
  readonly segment2?: InputMaybe<SeerOrderBy>;
  readonly segment3?: InputMaybe<SeerOrderBy>;
  readonly segment4?: InputMaybe<SeerOrderBy>;
  readonly segment5?: InputMaybe<SeerOrderBy>;
  readonly segment6?: InputMaybe<SeerOrderBy>;
  readonly segment7?: InputMaybe<SeerOrderBy>;
  readonly segment8?: InputMaybe<SeerOrderBy>;
  readonly segment9?: InputMaybe<SeerOrderBy>;
  readonly segment10?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly createdOn?: InputMaybe<SeerOrderBy>;
  readonly updatedBy?: InputMaybe<SeerOrderBy>;
  readonly updatedOn?: InputMaybe<SeerOrderBy>;
  readonly routeVendorId?: InputMaybe<SeerOrderBy>;
  readonly payProgramId?: InputMaybe<SeerOrderBy>;
  readonly payRateTableId?: InputMaybe<SeerOrderBy>;
  readonly driverId?: InputMaybe<SeerOrderBy>;
  readonly driverVoucherId?: InputMaybe<SeerOrderBy>;
  readonly approvalStatus?: InputMaybe<SeerOrderBy>;
  readonly approvedBy?: InputMaybe<SeerOrderBy>;
  readonly approvedOn?: InputMaybe<SeerOrderBy>;
  readonly chargeTypeId?: InputMaybe<SeerOrderBy>;
};

export type SeerAccountingCostDetailsResult = {
  readonly __typename?: 'SeerAccountingCostDetailsResult';
  readonly edges: ReadonlyArray<SeerAccountingCostDetailsEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerAccountingCustomerOrder = {
  readonly __typename?: 'SeerAccountingCustomerOrder';
  /** Column is of type UUID */
  readonly orderId: Scalars['String'];
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly loadId?: Maybe<Scalars['String']>;
  readonly disableFinancials?: Maybe<Scalars['Boolean']>;
  readonly invoiceholdReason?: Maybe<Scalars['String']>;
  readonly invoiceHoldCloseDate?: Maybe<Scalars['DateTime']>;
  readonly holdApprovedBy?: Maybe<Scalars['String']>;
  readonly invoiceApprovedDate?: Maybe<Scalars['DateTime']>;
  readonly bypass?: Maybe<Scalars['Boolean']>;
  readonly nonTaxable?: Maybe<Scalars['Boolean']>;
  readonly approvedForInvoicing?: Maybe<Scalars['Boolean']>;
};

export type SeerAccountingCustomerOrderEdge = {
  readonly __typename?: 'SeerAccountingCustomerOrderEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerAccountingCustomerOrder;
};

export type SeerAccountingCustomerOrderFilter = {
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly disableFinancials?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly invoiceholdReason?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly invoiceHoldCloseDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly holdApprovedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly invoiceApprovedDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly bypass?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly nonTaxable?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly approvedForInvoicing?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerAccountingCustomerOrderOrderBy = {
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly disableFinancials?: InputMaybe<SeerOrderBy>;
  readonly invoiceholdReason?: InputMaybe<SeerOrderBy>;
  readonly invoiceHoldCloseDate?: InputMaybe<SeerOrderBy>;
  readonly holdApprovedBy?: InputMaybe<SeerOrderBy>;
  readonly invoiceApprovedDate?: InputMaybe<SeerOrderBy>;
  readonly bypass?: InputMaybe<SeerOrderBy>;
  readonly nonTaxable?: InputMaybe<SeerOrderBy>;
  readonly approvedForInvoicing?: InputMaybe<SeerOrderBy>;
};

export type SeerAccountingCustomerOrderResult = {
  readonly __typename?: 'SeerAccountingCustomerOrderResult';
  readonly edges: ReadonlyArray<SeerAccountingCustomerOrderEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerAccountingExceptions = {
  readonly __typename?: 'SeerAccountingExceptions';
  /** Column is of type UUID */
  readonly sourceId: Scalars['String'];
  readonly sourceType: Scalars['String'];
  /** Column is of type UUID */
  readonly secondarySourceId: Scalars['String'];
  readonly secondarySourceType: Scalars['String'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  readonly exceptionStatus?: Maybe<Scalars['String']>;
  readonly exceptionType?: Maybe<Scalars['String']>;
  readonly reasons?: Maybe<Scalars['String']>;
};

export type SeerAccountingExceptionsEdge = {
  readonly __typename?: 'SeerAccountingExceptionsEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerAccountingExceptions;
};

export type SeerAccountingExceptionsFilter = {
  /** Column is of type UUID */
  readonly sourceId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly sourceType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly secondarySourceId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly secondarySourceType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly exceptionStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly exceptionType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly reasons?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerAccountingExceptionsOrderBy = {
  readonly sourceId?: InputMaybe<SeerOrderBy>;
  readonly sourceType?: InputMaybe<SeerOrderBy>;
  readonly secondarySourceId?: InputMaybe<SeerOrderBy>;
  readonly secondarySourceType?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly exceptionStatus?: InputMaybe<SeerOrderBy>;
  readonly exceptionType?: InputMaybe<SeerOrderBy>;
  readonly reasons?: InputMaybe<SeerOrderBy>;
};

export type SeerAccountingExceptionsResult = {
  readonly __typename?: 'SeerAccountingExceptionsResult';
  readonly edges: ReadonlyArray<SeerAccountingExceptionsEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerAccountingFinalisedFuel = {
  readonly __typename?: 'SeerAccountingFinalisedFuel';
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly customerId: Scalars['String'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId: Scalars['String'];
  readonly status?: Maybe<Scalars['String']>;
};

export type SeerAccountingFinalisedFuelEdge = {
  readonly __typename?: 'SeerAccountingFinalisedFuelEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerAccountingFinalisedFuel;
};

export type SeerAccountingFinalisedFuelFilter = {
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly updatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly status?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerAccountingFinalisedFuelOrderBy = {
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly createdOn?: InputMaybe<SeerOrderBy>;
  readonly updatedBy?: InputMaybe<SeerOrderBy>;
  readonly updatedOn?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly status?: InputMaybe<SeerOrderBy>;
};

export type SeerAccountingFinalisedFuelResult = {
  readonly __typename?: 'SeerAccountingFinalisedFuelResult';
  readonly edges: ReadonlyArray<SeerAccountingFinalisedFuelEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerAccountingNonRouteCostDetails = {
  readonly __typename?: 'SeerAccountingNonRouteCostDetails';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly driverId: Scalars['String'];
  readonly totalCost: Scalars['Float'];
  readonly status?: Maybe<Scalars['String']>;
  readonly comment?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly chargeTypeId: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly glCode?: Maybe<Scalars['String']>;
  readonly isPreTax?: Maybe<Scalars['Boolean']>;
  readonly exchangeRate?: Maybe<Scalars['Float']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly enteredTotalCost?: Maybe<Scalars['Float']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly approvedBy?: Maybe<Scalars['String']>;
  readonly approvalStatus?: Maybe<Scalars['String']>;
  readonly approvedOn?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly assignedTo?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly assignedToGroup?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly createdBy: Scalars['String'];
  readonly createdByName?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly updatedBy: Scalars['String'];
  readonly updatedByName?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly driverVoucherHeaderEid?: Maybe<Scalars['Int']>;
};

export type SeerAccountingNonRouteCostDetailsEdge = {
  readonly __typename?: 'SeerAccountingNonRouteCostDetailsEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerAccountingNonRouteCostDetails;
};

export type SeerAccountingNonRouteCostDetailsFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly driverId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly status?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly comment?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly chargeTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly description?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly code?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly glCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isPreTax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly exchangeRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly exchangeRateDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly enteredTotalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly currency?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly effectiveDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly approvedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly approvalStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly approvedOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly assignedTo?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly assignedToGroup?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdByName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly updatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedByName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverVoucherHeaderEid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
};

export type SeerAccountingNonRouteCostDetailsOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly driverId?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
  readonly status?: InputMaybe<SeerOrderBy>;
  readonly comment?: InputMaybe<SeerOrderBy>;
  readonly chargeTypeId?: InputMaybe<SeerOrderBy>;
  readonly description?: InputMaybe<SeerOrderBy>;
  readonly code?: InputMaybe<SeerOrderBy>;
  readonly glCode?: InputMaybe<SeerOrderBy>;
  readonly isPreTax?: InputMaybe<SeerOrderBy>;
  readonly exchangeRate?: InputMaybe<SeerOrderBy>;
  readonly exchangeRateDate?: InputMaybe<SeerOrderBy>;
  readonly enteredTotalCost?: InputMaybe<SeerOrderBy>;
  readonly currency?: InputMaybe<SeerOrderBy>;
  readonly effectiveDate?: InputMaybe<SeerOrderBy>;
  readonly approvedBy?: InputMaybe<SeerOrderBy>;
  readonly approvalStatus?: InputMaybe<SeerOrderBy>;
  readonly approvedOn?: InputMaybe<SeerOrderBy>;
  readonly assignedTo?: InputMaybe<SeerOrderBy>;
  readonly assignedToGroup?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly createdByName?: InputMaybe<SeerOrderBy>;
  readonly createdOn?: InputMaybe<SeerOrderBy>;
  readonly updatedBy?: InputMaybe<SeerOrderBy>;
  readonly updatedByName?: InputMaybe<SeerOrderBy>;
  readonly updatedOn?: InputMaybe<SeerOrderBy>;
  readonly driverVoucherHeaderEid?: InputMaybe<SeerOrderBy>;
};

export type SeerAccountingNonRouteCostDetailsResult = {
  readonly __typename?: 'SeerAccountingNonRouteCostDetailsResult';
  readonly edges: ReadonlyArray<SeerAccountingNonRouteCostDetailsEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerAccountingRateLineDetail = {
  readonly __typename?: 'SeerAccountingRateLineDetail';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly chargeType?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly currencyDiscountAmount?: Maybe<Scalars['Float']>;
  readonly glAccountCode?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly jurisdictionTaxesId?: Maybe<Scalars['String']>;
  readonly ratePerUnit?: Maybe<Scalars['Float']>;
  /** Column is of type UUID */
  readonly loadId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly isCredit?: Maybe<Scalars['Boolean']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly segment1?: Maybe<Scalars['String']>;
  readonly segment2?: Maybe<Scalars['String']>;
  readonly segment3?: Maybe<Scalars['String']>;
  readonly segment4?: Maybe<Scalars['String']>;
  readonly segment5?: Maybe<Scalars['String']>;
  readonly segment6?: Maybe<Scalars['String']>;
  readonly segment7?: Maybe<Scalars['String']>;
  readonly segment8?: Maybe<Scalars['String']>;
  readonly segment9?: Maybe<Scalars['String']>;
  readonly segment10?: Maybe<Scalars['String']>;
  readonly units?: Maybe<Scalars['Float']>;
  readonly customerGlCode?: Maybe<Scalars['String']>;
  readonly invoiceHeaderEid?: Maybe<Scalars['Int']>;
  /** Column is of type UUID */
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly chargeTypeId?: Maybe<Scalars['String']>;
  readonly enteredRatePer?: Maybe<Scalars['Float']>;
  readonly enteredTotalRate?: Maybe<Scalars['Float']>;
  readonly exchangeRate?: Maybe<Scalars['Float']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly billToCode?: Maybe<Scalars['String']>;
  readonly approvalStatus?: Maybe<Scalars['String']>;
  readonly hasOpenLineItems?: Maybe<Scalars['Boolean']>;
  /** Column is of type UUID */
  readonly billToCodeId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly billToCustomer?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly billingAddress?: Maybe<Scalars['String']>;
  readonly billingEmail?: Maybe<Scalars['String']>;
  readonly billingPaymentTerms?: Maybe<Scalars['String']>;
  readonly billingPaymentMethod?: Maybe<Scalars['String']>;
  readonly billingInvoiceMethod?: Maybe<Scalars['String']>;
  readonly billingInvoiceTemplate?: Maybe<Scalars['String']>;
  readonly billingCurrency?: Maybe<Scalars['String']>;
  readonly billingInvoiceGrouping?: Maybe<Scalars['String']>;
  readonly billingDistributionFrequency?: Maybe<Scalars['String']>;
  readonly billingFrequency?: Maybe<Scalars['String']>;
  readonly approvedBy?: Maybe<Scalars['String']>;
  readonly approvedOn?: Maybe<Scalars['DateTime']>;
};

export type SeerAccountingRateLineDetailEdge = {
  readonly __typename?: 'SeerAccountingRateLineDetailEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerAccountingRateLineDetail;
};

export type SeerAccountingRateLineDetailFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly chargeType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly code?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currency?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currencyDiscountAmount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly glAccountCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly description?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly exchangeRateDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly jurisdictionTaxesId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ratePerUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly status?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isCredit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly segment1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment3?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment4?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment5?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment6?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment7?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment8?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment9?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly segment10?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly units?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly customerGlCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly invoiceHeaderEid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly updatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly chargeTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly enteredRatePer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly enteredTotalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly exchangeRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly billToCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly approvalStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly hasOpenLineItems?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  /** Column is of type UUID */
  readonly billToCodeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly billToCustomer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly billingAddress?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly billingEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly billingPaymentTerms?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly billingPaymentMethod?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly billingInvoiceMethod?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly billingInvoiceTemplate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly billingCurrency?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly billingInvoiceGrouping?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly billingDistributionFrequency?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly billingFrequency?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly approvedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly approvedOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerAccountingRateLineDetailOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly chargeType?: InputMaybe<SeerOrderBy>;
  readonly code?: InputMaybe<SeerOrderBy>;
  readonly currency?: InputMaybe<SeerOrderBy>;
  readonly currencyDiscountAmount?: InputMaybe<SeerOrderBy>;
  readonly glAccountCode?: InputMaybe<SeerOrderBy>;
  readonly description?: InputMaybe<SeerOrderBy>;
  readonly exchangeRateDate?: InputMaybe<SeerOrderBy>;
  readonly jurisdictionTaxesId?: InputMaybe<SeerOrderBy>;
  readonly ratePerUnit?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly status?: InputMaybe<SeerOrderBy>;
  readonly isCredit?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly segment1?: InputMaybe<SeerOrderBy>;
  readonly segment2?: InputMaybe<SeerOrderBy>;
  readonly segment3?: InputMaybe<SeerOrderBy>;
  readonly segment4?: InputMaybe<SeerOrderBy>;
  readonly segment5?: InputMaybe<SeerOrderBy>;
  readonly segment6?: InputMaybe<SeerOrderBy>;
  readonly segment7?: InputMaybe<SeerOrderBy>;
  readonly segment8?: InputMaybe<SeerOrderBy>;
  readonly segment9?: InputMaybe<SeerOrderBy>;
  readonly segment10?: InputMaybe<SeerOrderBy>;
  readonly units?: InputMaybe<SeerOrderBy>;
  readonly customerGlCode?: InputMaybe<SeerOrderBy>;
  readonly invoiceHeaderEid?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly createdOn?: InputMaybe<SeerOrderBy>;
  readonly updatedBy?: InputMaybe<SeerOrderBy>;
  readonly updatedOn?: InputMaybe<SeerOrderBy>;
  readonly chargeTypeId?: InputMaybe<SeerOrderBy>;
  readonly enteredRatePer?: InputMaybe<SeerOrderBy>;
  readonly enteredTotalRate?: InputMaybe<SeerOrderBy>;
  readonly exchangeRate?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly billToCode?: InputMaybe<SeerOrderBy>;
  readonly approvalStatus?: InputMaybe<SeerOrderBy>;
  readonly hasOpenLineItems?: InputMaybe<SeerOrderBy>;
  readonly billToCodeId?: InputMaybe<SeerOrderBy>;
  readonly billToCustomer?: InputMaybe<SeerOrderBy>;
  readonly billingAddress?: InputMaybe<SeerOrderBy>;
  readonly billingEmail?: InputMaybe<SeerOrderBy>;
  readonly billingPaymentTerms?: InputMaybe<SeerOrderBy>;
  readonly billingPaymentMethod?: InputMaybe<SeerOrderBy>;
  readonly billingInvoiceMethod?: InputMaybe<SeerOrderBy>;
  readonly billingInvoiceTemplate?: InputMaybe<SeerOrderBy>;
  readonly billingCurrency?: InputMaybe<SeerOrderBy>;
  readonly billingInvoiceGrouping?: InputMaybe<SeerOrderBy>;
  readonly billingDistributionFrequency?: InputMaybe<SeerOrderBy>;
  readonly billingFrequency?: InputMaybe<SeerOrderBy>;
  readonly approvedBy?: InputMaybe<SeerOrderBy>;
  readonly approvedOn?: InputMaybe<SeerOrderBy>;
};

export type SeerAccountingRateLineDetailResult = {
  readonly __typename?: 'SeerAccountingRateLineDetailResult';
  readonly edges: ReadonlyArray<SeerAccountingRateLineDetailEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerAccountingUnvoucheredVendorInvoice = {
  readonly __typename?: 'SeerAccountingUnvoucheredVendorInvoice';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly vendorInvoiceNumber?: Maybe<Scalars['String']>;
  readonly statusId?: Maybe<Scalars['String']>;
  readonly remitToName?: Maybe<Scalars['String']>;
  readonly remitToAddressLine1?: Maybe<Scalars['String']>;
  readonly remitToAddressLine2?: Maybe<Scalars['String']>;
  readonly remitToCity?: Maybe<Scalars['String']>;
  readonly remitToState?: Maybe<Scalars['String']>;
  readonly remitToCountry?: Maybe<Scalars['String']>;
  readonly remitToZipCode?: Maybe<Scalars['String']>;
  readonly amountDouble?: Maybe<Scalars['Float']>;
  readonly isQuickpay?: Maybe<Scalars['Boolean']>;
  readonly amount?: Maybe<Scalars['Float']>;
  readonly approvalDate?: Maybe<Scalars['DateTime']>;
  readonly approver?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly dateReceived?: Maybe<Scalars['DateTime']>;
  readonly dateToPay?: Maybe<Scalars['DateTime']>;
  readonly invoiceDate?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly loadId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly vendorId?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly canRerate?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly processor?: Maybe<Scalars['String']>;
};

export type SeerAccountingUnvoucheredVendorInvoiceEdge = {
  readonly __typename?: 'SeerAccountingUnvoucheredVendorInvoiceEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerAccountingUnvoucheredVendorInvoice;
};

export type SeerAccountingUnvoucheredVendorInvoiceFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly vendorInvoiceNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly statusId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly remitToName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly remitToAddressLine1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly remitToAddressLine2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly remitToCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly remitToState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly remitToCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly remitToZipCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly amountDouble?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly isQuickpay?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly amount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly approvalDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly approver?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly currency?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dateReceived?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly dateToPay?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly invoiceDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly externalId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly canRerate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly comments?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly processor?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerAccountingUnvoucheredVendorInvoiceOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly vendorInvoiceNumber?: InputMaybe<SeerOrderBy>;
  readonly statusId?: InputMaybe<SeerOrderBy>;
  readonly remitToName?: InputMaybe<SeerOrderBy>;
  readonly remitToAddressLine1?: InputMaybe<SeerOrderBy>;
  readonly remitToAddressLine2?: InputMaybe<SeerOrderBy>;
  readonly remitToCity?: InputMaybe<SeerOrderBy>;
  readonly remitToState?: InputMaybe<SeerOrderBy>;
  readonly remitToCountry?: InputMaybe<SeerOrderBy>;
  readonly remitToZipCode?: InputMaybe<SeerOrderBy>;
  readonly amountDouble?: InputMaybe<SeerOrderBy>;
  readonly isQuickpay?: InputMaybe<SeerOrderBy>;
  readonly amount?: InputMaybe<SeerOrderBy>;
  readonly approvalDate?: InputMaybe<SeerOrderBy>;
  readonly approver?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly createdOn?: InputMaybe<SeerOrderBy>;
  readonly currency?: InputMaybe<SeerOrderBy>;
  readonly dateReceived?: InputMaybe<SeerOrderBy>;
  readonly dateToPay?: InputMaybe<SeerOrderBy>;
  readonly invoiceDate?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly updatedBy?: InputMaybe<SeerOrderBy>;
  readonly updatedOn?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly externalId?: InputMaybe<SeerOrderBy>;
  readonly canRerate?: InputMaybe<SeerOrderBy>;
  readonly comments?: InputMaybe<SeerOrderBy>;
  readonly processor?: InputMaybe<SeerOrderBy>;
};

export type SeerAccountingUnvoucheredVendorInvoiceResult = {
  readonly __typename?: 'SeerAccountingUnvoucheredVendorInvoiceResult';
  readonly edges: ReadonlyArray<SeerAccountingUnvoucheredVendorInvoiceEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerAccountingVendorInvoice = {
  readonly __typename?: 'SeerAccountingVendorInvoice';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly vendorVoucherId: Scalars['String'];
  readonly amount?: Maybe<Scalars['Float']>;
  readonly approvalDate?: Maybe<Scalars['DateTime']>;
  readonly approver?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly dateReceived?: Maybe<Scalars['DateTime']>;
  readonly dateToPay?: Maybe<Scalars['DateTime']>;
  readonly invoiceDate?: Maybe<Scalars['DateTime']>;
  readonly isQuickpay?: Maybe<Scalars['Boolean']>;
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  readonly processor?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly status?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly vendorId: Scalars['String'];
  readonly vendorInvoiceNumber?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly exchangeRate?: Maybe<Scalars['Float']>;
  readonly enteredInvoiceAmount?: Maybe<Scalars['Float']>;
  readonly remitToName?: Maybe<Scalars['String']>;
  readonly remitToAddressLine1?: Maybe<Scalars['String']>;
  readonly remitToAddressLine2?: Maybe<Scalars['String']>;
  readonly remitToZipCode?: Maybe<Scalars['String']>;
  readonly remitToState?: Maybe<Scalars['String']>;
  readonly remitToCountry?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

export type SeerAccountingVendorInvoiceEdge = {
  readonly __typename?: 'SeerAccountingVendorInvoiceEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerAccountingVendorInvoice;
};

export type SeerAccountingVendorInvoiceFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorVoucherId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly amount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly approvalDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly approver?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly comments?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currency?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dateReceived?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly dateToPay?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly invoiceDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly isQuickpay?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly processor?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly status?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly vendorInvoiceNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly externalId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly exchangeRateDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly exchangeRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly enteredInvoiceAmount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly remitToName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly remitToAddressLine1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly remitToAddressLine2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly remitToZipCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly remitToState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly remitToCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly updatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerAccountingVendorInvoiceOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly vendorVoucherId?: InputMaybe<SeerOrderBy>;
  readonly amount?: InputMaybe<SeerOrderBy>;
  readonly approvalDate?: InputMaybe<SeerOrderBy>;
  readonly approver?: InputMaybe<SeerOrderBy>;
  readonly comments?: InputMaybe<SeerOrderBy>;
  readonly currency?: InputMaybe<SeerOrderBy>;
  readonly dateReceived?: InputMaybe<SeerOrderBy>;
  readonly dateToPay?: InputMaybe<SeerOrderBy>;
  readonly invoiceDate?: InputMaybe<SeerOrderBy>;
  readonly isQuickpay?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly processor?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly status?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly vendorInvoiceNumber?: InputMaybe<SeerOrderBy>;
  readonly externalId?: InputMaybe<SeerOrderBy>;
  readonly exchangeRateDate?: InputMaybe<SeerOrderBy>;
  readonly exchangeRate?: InputMaybe<SeerOrderBy>;
  readonly enteredInvoiceAmount?: InputMaybe<SeerOrderBy>;
  readonly remitToName?: InputMaybe<SeerOrderBy>;
  readonly remitToAddressLine1?: InputMaybe<SeerOrderBy>;
  readonly remitToAddressLine2?: InputMaybe<SeerOrderBy>;
  readonly remitToZipCode?: InputMaybe<SeerOrderBy>;
  readonly remitToState?: InputMaybe<SeerOrderBy>;
  readonly remitToCountry?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly createdOn?: InputMaybe<SeerOrderBy>;
  readonly updatedBy?: InputMaybe<SeerOrderBy>;
  readonly updatedOn?: InputMaybe<SeerOrderBy>;
};

export type SeerAccountingVendorInvoiceResult = {
  readonly __typename?: 'SeerAccountingVendorInvoiceResult';
  readonly edges: ReadonlyArray<SeerAccountingVendorInvoiceEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerAccountingVendorRouteReqDocCompleteStatus = {
  readonly __typename?: 'SeerAccountingVendorRouteReqDocCompleteStatus';
  /** Column is of type UUID */
  readonly vendorId: Scalars['String'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly isComplete?: Maybe<Scalars['Boolean']>;
};

export type SeerAccountingVendorRouteReqDocCompleteStatusEdge = {
  readonly __typename?: 'SeerAccountingVendorRouteReqDocCompleteStatusEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerAccountingVendorRouteReqDocCompleteStatus;
};

export type SeerAccountingVendorRouteReqDocCompleteStatusFilter = {
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isComplete?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerAccountingVendorRouteReqDocCompleteStatusOrderBy = {
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly isComplete?: InputMaybe<SeerOrderBy>;
};

export type SeerAccountingVendorRouteReqDocCompleteStatusResult = {
  readonly __typename?: 'SeerAccountingVendorRouteReqDocCompleteStatusResult';
  readonly edges: ReadonlyArray<SeerAccountingVendorRouteReqDocCompleteStatusEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerAccountingVendorRouteRequiredDocumentCompletionStatus = {
  readonly __typename?: 'SeerAccountingVendorRouteRequiredDocumentCompletionStatus';
  /** Column is of type UUID */
  readonly vendorId: Scalars['String'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly isComplete?: Maybe<Scalars['Boolean']>;
};

export type SeerAccountingVendorRouteRequiredDocumentCompletionStatusEdge = {
  readonly __typename?: 'SeerAccountingVendorRouteRequiredDocumentCompletionStatusEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerAccountingVendorRouteRequiredDocumentCompletionStatus;
};

export type SeerAccountingVendorRouteRequiredDocumentCompletionStatusFilter = {
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isComplete?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerAccountingVendorRouteRequiredDocumentCompletionStatusOrderBy = {
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly isComplete?: InputMaybe<SeerOrderBy>;
};

export type SeerAccountingVendorRouteRequiredDocumentCompletionStatusResult = {
  readonly __typename?: 'SeerAccountingVendorRouteRequiredDocumentCompletionStatusResult';
  readonly edges: ReadonlyArray<SeerAccountingVendorRouteRequiredDocumentCompletionStatusEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerAccountingVendorVoucher = {
  readonly __typename?: 'SeerAccountingVendorVoucher';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly carrierPaymentTerm?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly currentAmtDue?: Maybe<Scalars['Float']>;
  readonly dateSettled?: Maybe<Scalars['DateTime']>;
  readonly discountAmount?: Maybe<Scalars['Float']>;
  readonly discountDateCalculation?: Maybe<Scalars['Int']>;
  readonly discountPercent?: Maybe<Scalars['Float']>;
  readonly dueDate?: Maybe<Scalars['DateTime']>;
  readonly dueDateCalculation?: Maybe<Scalars['Int']>;
  readonly invoiceDistMethod?: Maybe<Scalars['String']>;
  readonly isCreditMemoed?: Maybe<Scalars['Boolean']>;
  readonly isSettled?: Maybe<Scalars['Boolean']>;
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  readonly originalAmountDue?: Maybe<Scalars['Float']>;
  readonly paymentMethod?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly status?: Maybe<Scalars['String']>;
  readonly adjustmentAmount?: Maybe<Scalars['Float']>;
  readonly voucherCurrencyOrgAmtDue?: Maybe<Scalars['Float']>;
  readonly voucherCurrencyCrntAmtDue?: Maybe<Scalars['Float']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly exchangeRate?: Maybe<Scalars['Float']>;
  readonly currencyDiscountAmount?: Maybe<Scalars['Float']>;
  readonly invoiceProcessedDateTime?: Maybe<Scalars['DateTime']>;
  readonly creditMemoProcessedDateTime?: Maybe<Scalars['DateTime']>;
  readonly pickupDate?: Maybe<Scalars['DateTime']>;
  readonly deliveryDate?: Maybe<Scalars['DateTime']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly vendorId: Scalars['String'];
  readonly voucherDate?: Maybe<Scalars['DateTime']>;
  readonly voucherNumber?: Maybe<Scalars['String']>;
  readonly voucherRootNumber?: Maybe<Scalars['String']>;
  readonly disableFinancials?: Maybe<Scalars['Boolean']>;
  readonly originalPickupDate?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly batchId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly parentVoucherBatchId?: Maybe<Scalars['String']>;
  readonly batchStatus?: Maybe<Scalars['String']>;
  readonly batchDescription?: Maybe<Scalars['String']>;
  readonly isFailedBatch?: Maybe<Scalars['Boolean']>;
  readonly batchCreatedOn?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly batchCreatedBy?: Maybe<Scalars['String']>;
  readonly batchUpdatedOn?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly batchUpdatedBy?: Maybe<Scalars['String']>;
  readonly remittanceEmail?: Maybe<Scalars['String']>;
  readonly remittanceGrouping?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly remitToAddressId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly remittanceCarrierId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly creditMemoId?: Maybe<Scalars['String']>;
  readonly creditMemoAmount?: Maybe<Scalars['Float']>;
  readonly creditMemoDate?: Maybe<Scalars['DateTime']>;
  readonly creditMemoIsProcessed?: Maybe<Scalars['Boolean']>;
  readonly creditMemoNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly creditMemoCreatedBy?: Maybe<Scalars['String']>;
  readonly creditMemoCreatedOn?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly creditMemoUpdatedBy?: Maybe<Scalars['String']>;
  readonly creditMemoUpdatedOn?: Maybe<Scalars['DateTime']>;
  readonly creditMemoVoucherCurrencyAmount?: Maybe<Scalars['Float']>;
  readonly creditMemoExchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly creditMemoExchangeRate?: Maybe<Scalars['Float']>;
  readonly creditMemoCurrency?: Maybe<Scalars['String']>;
  readonly executingDivision?: Maybe<Scalars['String']>;
};

export type SeerAccountingVendorVoucherEdge = {
  readonly __typename?: 'SeerAccountingVendorVoucherEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerAccountingVendorVoucher;
};

export type SeerAccountingVendorVoucherFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierPaymentTerm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currency?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentAmtDue?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly dateSettled?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly discountAmount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly discountDateCalculation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly discountPercent?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly dueDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly dueDateCalculation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly invoiceDistMethod?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isCreditMemoed?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly isSettled?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originalAmountDue?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly paymentMethod?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly status?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly adjustmentAmount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly voucherCurrencyOrgAmtDue?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly voucherCurrencyCrntAmtDue?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly exchangeRateDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly exchangeRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly currencyDiscountAmount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly invoiceProcessedDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly creditMemoProcessedDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly pickupDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly deliveryDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly voucherDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly voucherNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly voucherRootNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly disableFinancials?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly originalPickupDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly updatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly batchId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly parentVoucherBatchId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly batchStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly batchDescription?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isFailedBatch?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly batchCreatedOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly batchCreatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly batchUpdatedOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly batchUpdatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly remittanceEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly remittanceGrouping?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly remitToAddressId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly remittanceCarrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly creditMemoId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly creditMemoAmount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly creditMemoDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly creditMemoIsProcessed?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly creditMemoNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly creditMemoCreatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly creditMemoCreatedOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly creditMemoUpdatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly creditMemoUpdatedOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly creditMemoVoucherCurrencyAmount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly creditMemoExchangeRateDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly creditMemoExchangeRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly creditMemoCurrency?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly executingDivision?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerAccountingVendorVoucherOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly carrierPaymentTerm?: InputMaybe<SeerOrderBy>;
  readonly currency?: InputMaybe<SeerOrderBy>;
  readonly currentAmtDue?: InputMaybe<SeerOrderBy>;
  readonly dateSettled?: InputMaybe<SeerOrderBy>;
  readonly discountAmount?: InputMaybe<SeerOrderBy>;
  readonly discountDateCalculation?: InputMaybe<SeerOrderBy>;
  readonly discountPercent?: InputMaybe<SeerOrderBy>;
  readonly dueDate?: InputMaybe<SeerOrderBy>;
  readonly dueDateCalculation?: InputMaybe<SeerOrderBy>;
  readonly invoiceDistMethod?: InputMaybe<SeerOrderBy>;
  readonly isCreditMemoed?: InputMaybe<SeerOrderBy>;
  readonly isSettled?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly originalAmountDue?: InputMaybe<SeerOrderBy>;
  readonly paymentMethod?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly status?: InputMaybe<SeerOrderBy>;
  readonly adjustmentAmount?: InputMaybe<SeerOrderBy>;
  readonly voucherCurrencyOrgAmtDue?: InputMaybe<SeerOrderBy>;
  readonly voucherCurrencyCrntAmtDue?: InputMaybe<SeerOrderBy>;
  readonly exchangeRateDate?: InputMaybe<SeerOrderBy>;
  readonly exchangeRate?: InputMaybe<SeerOrderBy>;
  readonly currencyDiscountAmount?: InputMaybe<SeerOrderBy>;
  readonly invoiceProcessedDateTime?: InputMaybe<SeerOrderBy>;
  readonly creditMemoProcessedDateTime?: InputMaybe<SeerOrderBy>;
  readonly pickupDate?: InputMaybe<SeerOrderBy>;
  readonly deliveryDate?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly size?: InputMaybe<SeerOrderBy>;
  readonly division?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly voucherDate?: InputMaybe<SeerOrderBy>;
  readonly voucherNumber?: InputMaybe<SeerOrderBy>;
  readonly voucherRootNumber?: InputMaybe<SeerOrderBy>;
  readonly disableFinancials?: InputMaybe<SeerOrderBy>;
  readonly originalPickupDate?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly createdOn?: InputMaybe<SeerOrderBy>;
  readonly updatedBy?: InputMaybe<SeerOrderBy>;
  readonly updatedOn?: InputMaybe<SeerOrderBy>;
  readonly batchId?: InputMaybe<SeerOrderBy>;
  readonly parentVoucherBatchId?: InputMaybe<SeerOrderBy>;
  readonly batchStatus?: InputMaybe<SeerOrderBy>;
  readonly batchDescription?: InputMaybe<SeerOrderBy>;
  readonly isFailedBatch?: InputMaybe<SeerOrderBy>;
  readonly batchCreatedOn?: InputMaybe<SeerOrderBy>;
  readonly batchCreatedBy?: InputMaybe<SeerOrderBy>;
  readonly batchUpdatedOn?: InputMaybe<SeerOrderBy>;
  readonly batchUpdatedBy?: InputMaybe<SeerOrderBy>;
  readonly remittanceEmail?: InputMaybe<SeerOrderBy>;
  readonly remittanceGrouping?: InputMaybe<SeerOrderBy>;
  readonly remitToAddressId?: InputMaybe<SeerOrderBy>;
  readonly remittanceCarrierId?: InputMaybe<SeerOrderBy>;
  readonly creditMemoId?: InputMaybe<SeerOrderBy>;
  readonly creditMemoAmount?: InputMaybe<SeerOrderBy>;
  readonly creditMemoDate?: InputMaybe<SeerOrderBy>;
  readonly creditMemoIsProcessed?: InputMaybe<SeerOrderBy>;
  readonly creditMemoNumber?: InputMaybe<SeerOrderBy>;
  readonly creditMemoCreatedBy?: InputMaybe<SeerOrderBy>;
  readonly creditMemoCreatedOn?: InputMaybe<SeerOrderBy>;
  readonly creditMemoUpdatedBy?: InputMaybe<SeerOrderBy>;
  readonly creditMemoUpdatedOn?: InputMaybe<SeerOrderBy>;
  readonly creditMemoVoucherCurrencyAmount?: InputMaybe<SeerOrderBy>;
  readonly creditMemoExchangeRateDate?: InputMaybe<SeerOrderBy>;
  readonly creditMemoExchangeRate?: InputMaybe<SeerOrderBy>;
  readonly creditMemoCurrency?: InputMaybe<SeerOrderBy>;
  readonly executingDivision?: InputMaybe<SeerOrderBy>;
};

export type SeerAccountingVendorVoucherResult = {
  readonly __typename?: 'SeerAccountingVendorVoucherResult';
  readonly edges: ReadonlyArray<SeerAccountingVendorVoucherEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerAssignedRep = {
  readonly __typename?: 'SeerAssignedRep';
  /** Column is of type UUID */
  readonly employeeId: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly type: Scalars['String'];
  readonly employeeGroup?: Maybe<Scalars['String']>;
  readonly employeeOffice?: Maybe<Scalars['String']>;
  readonly main: Scalars['Boolean'];
  readonly name?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly showOnMprbActiveScreens: Scalars['Boolean'];
};

export type SeerAssignedRepEdge = {
  readonly __typename?: 'SeerAssignedRepEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerAssignedRep;
};

export type SeerAssignedRepFilter = {
  /** Column is of type UUID */
  readonly employeeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly type?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly employeeGroup?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly employeeOffice?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly main?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly showOnMprbActiveScreens?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerAssignedRepGroup = {
  readonly __typename?: 'SeerAssignedRepGroup';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly routeId?: Maybe<Scalars['String']>;
  readonly employeeGroup?: Maybe<Scalars['String']>;
};

export type SeerAssignedRepGroupEdge = {
  readonly __typename?: 'SeerAssignedRepGroupEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerAssignedRepGroup;
};

export type SeerAssignedRepGroupFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly employeeGroup?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerAssignedRepGroupOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly employeeGroup?: InputMaybe<SeerOrderBy>;
};

export type SeerAssignedRepGroupResult = {
  readonly __typename?: 'SeerAssignedRepGroupResult';
  readonly edges: ReadonlyArray<SeerAssignedRepGroupEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerAssignedRepOrderBy = {
  readonly employeeId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly type?: InputMaybe<SeerOrderBy>;
  readonly employeeGroup?: InputMaybe<SeerOrderBy>;
  readonly employeeOffice?: InputMaybe<SeerOrderBy>;
  readonly main?: InputMaybe<SeerOrderBy>;
  readonly name?: InputMaybe<SeerOrderBy>;
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly showOnMprbActiveScreens?: InputMaybe<SeerOrderBy>;
};

export type SeerAssignedRepResult = {
  readonly __typename?: 'SeerAssignedRepResult';
  readonly edges: ReadonlyArray<SeerAssignedRepEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerBillToCode = {
  readonly __typename?: 'SeerBillToCode';
  /** Column is of type UUID */
  readonly billToCodeId: Scalars['String'];
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly isDefault?: Maybe<Scalars['Boolean']>;
  readonly billingCode?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly billToCustomerId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly billingAddressId?: Maybe<Scalars['String']>;
  readonly billingEmail?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly paymentTerms?: Maybe<Scalars['String']>;
  readonly paymentMethod?: Maybe<Scalars['String']>;
  readonly invoiceMethod?: Maybe<Scalars['String']>;
  readonly invoiceTemplate?: Maybe<Scalars['String']>;
  readonly invoiceGrouping?: Maybe<Scalars['String']>;
};

export type SeerBillToCodeEdge = {
  readonly __typename?: 'SeerBillToCodeEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerBillToCode;
};

export type SeerBillToCodeFilter = {
  /** Column is of type UUID */
  readonly billToCodeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isDefault?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly billingCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly billToCustomerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly billingAddressId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly billingEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currency?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly paymentTerms?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly paymentMethod?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly invoiceMethod?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly invoiceTemplate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly invoiceGrouping?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerBillToCodeOrderBy = {
  readonly billToCodeId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly isDefault?: InputMaybe<SeerOrderBy>;
  readonly billingCode?: InputMaybe<SeerOrderBy>;
  readonly billToCustomerId?: InputMaybe<SeerOrderBy>;
  readonly billingAddressId?: InputMaybe<SeerOrderBy>;
  readonly billingEmail?: InputMaybe<SeerOrderBy>;
  readonly currency?: InputMaybe<SeerOrderBy>;
  readonly paymentTerms?: InputMaybe<SeerOrderBy>;
  readonly paymentMethod?: InputMaybe<SeerOrderBy>;
  readonly invoiceMethod?: InputMaybe<SeerOrderBy>;
  readonly invoiceTemplate?: InputMaybe<SeerOrderBy>;
  readonly invoiceGrouping?: InputMaybe<SeerOrderBy>;
};

export type SeerBillToCodeResult = {
  readonly __typename?: 'SeerBillToCodeResult';
  readonly edges: ReadonlyArray<SeerBillToCodeEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCarrier = {
  readonly __typename?: 'SeerCarrier';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly name?: Maybe<Scalars['String']>;
  readonly mainAddressCity?: Maybe<Scalars['String']>;
  readonly mainAddressState?: Maybe<Scalars['String']>;
  readonly mainRepName?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly mainRepId?: Maybe<Scalars['String']>;
  readonly complianceStatus?: Maybe<Scalars['String']>;
  readonly salesProgress?: Maybe<Scalars['String']>;
  readonly opportunity?: Maybe<Scalars['String']>;
  readonly level?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly standingStatus?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly mcNumber?: Maybe<Scalars['String']>;
  readonly dotNumber?: Maybe<Scalars['String']>;
  readonly scacNumber?: Maybe<Scalars['String']>;
  readonly carrierType?: Maybe<Scalars['String']>;
  readonly paymentTerm?: Maybe<Scalars['String']>;
  readonly remitToCarrierId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly organizationId?: Maybe<Scalars['String']>;
};

export type SeerCarrierAccountingStatus = {
  readonly __typename?: 'SeerCarrierAccountingStatus';
  /** Column is of type UUID */
  readonly carrierId: Scalars['String'];
  readonly accountingStatus?: Maybe<Scalars['String']>;
};

export type SeerCarrierAccountingStatusEdge = {
  readonly __typename?: 'SeerCarrierAccountingStatusEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCarrierAccountingStatus;
};

export type SeerCarrierAccountingStatusFilter = {
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly accountingStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCarrierAccountingStatusOrderBy = {
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly accountingStatus?: InputMaybe<SeerOrderBy>;
};

export type SeerCarrierAccountingStatusResult = {
  readonly __typename?: 'SeerCarrierAccountingStatusResult';
  readonly edges: ReadonlyArray<SeerCarrierAccountingStatusEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCarrierAddress = {
  readonly __typename?: 'SeerCarrierAddress';
  /** Column is of type UUID */
  readonly addressId: Scalars['String'];
  /** Column is of type UUID */
  readonly carrierId: Scalars['String'];
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly zipcode?: Maybe<Scalars['String']>;
  readonly fullAddressPoint?: Maybe<SeerGeoPoint>;
  readonly isMainAddress?: Maybe<Scalars['Boolean']>;
  readonly street1?: Maybe<Scalars['String']>;
};

export type SeerCarrierAddressEdge = {
  readonly __typename?: 'SeerCarrierAddressEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCarrierAddress;
};

export type SeerCarrierAddressFilter = {
  /** Column is of type UUID */
  readonly addressId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly city?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly state?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly country?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly zipcode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly fullAddressPoint?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly isMainAddress?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly street1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCarrierAddressOrderBy = {
  readonly addressId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly city?: InputMaybe<SeerOrderBy>;
  readonly state?: InputMaybe<SeerOrderBy>;
  readonly country?: InputMaybe<SeerOrderBy>;
  readonly zipcode?: InputMaybe<SeerOrderBy>;
  readonly isMainAddress?: InputMaybe<SeerOrderBy>;
  readonly street1?: InputMaybe<SeerOrderBy>;
};

export type SeerCarrierAddressResult = {
  readonly __typename?: 'SeerCarrierAddressResult';
  readonly edges: ReadonlyArray<SeerCarrierAddressEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCarrierCertification = {
  readonly __typename?: 'SeerCarrierCertification';
  /** Column is of type UUID */
  readonly certificationId: Scalars['String'];
  /** Column is of type UUID */
  readonly carrierId: Scalars['String'];
  readonly value?: Maybe<Scalars['String']>;
};

export type SeerCarrierCertificationEdge = {
  readonly __typename?: 'SeerCarrierCertificationEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCarrierCertification;
};

export type SeerCarrierCertificationFilter = {
  /** Column is of type UUID */
  readonly certificationId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly value?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCarrierCertificationOrderBy = {
  readonly certificationId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly value?: InputMaybe<SeerOrderBy>;
};

export type SeerCarrierCertificationResult = {
  readonly __typename?: 'SeerCarrierCertificationResult';
  readonly edges: ReadonlyArray<SeerCarrierCertificationEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCarrierCustomerRelationship = {
  readonly __typename?: 'SeerCarrierCustomerRelationship';
  /** Column is of type UUID */
  readonly customerId: Scalars['String'];
  /** Column is of type UUID */
  readonly carrierId: Scalars['String'];
};

export type SeerCarrierCustomerRelationshipEdge = {
  readonly __typename?: 'SeerCarrierCustomerRelationshipEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCarrierCustomerRelationship;
};

export type SeerCarrierCustomerRelationshipFilter = {
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCarrierCustomerRelationshipOrderBy = {
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
};

export type SeerCarrierCustomerRelationshipResult = {
  readonly __typename?: 'SeerCarrierCustomerRelationshipResult';
  readonly edges: ReadonlyArray<SeerCarrierCustomerRelationshipEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCarrierEdge = {
  readonly __typename?: 'SeerCarrierEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCarrier;
};

export type SeerCarrierFacilityRelationship = {
  readonly __typename?: 'SeerCarrierFacilityRelationship';
  /** Column is of type UUID */
  readonly facilityId: Scalars['String'];
  /** Column is of type UUID */
  readonly carrierId: Scalars['String'];
};

export type SeerCarrierFacilityRelationshipEdge = {
  readonly __typename?: 'SeerCarrierFacilityRelationshipEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCarrierFacilityRelationship;
};

export type SeerCarrierFacilityRelationshipFilter = {
  /** Column is of type UUID */
  readonly facilityId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCarrierFacilityRelationshipOrderBy = {
  readonly facilityId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
};

export type SeerCarrierFacilityRelationshipResult = {
  readonly __typename?: 'SeerCarrierFacilityRelationshipResult';
  readonly edges: ReadonlyArray<SeerCarrierFacilityRelationshipEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCarrierFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainAddressCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainAddressState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainRepName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly mainRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly complianceStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly salesProgress?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly opportunity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly level?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly code?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly standingStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly phone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mcNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dotNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly scacNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly paymentTerm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly remitToCarrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly organizationId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCarrierGroup = {
  readonly __typename?: 'SeerCarrierGroup';
  /** Column is of type UUID */
  readonly carrierId: Scalars['String'];
  readonly groupId: Scalars['String'];
};

export type SeerCarrierGroupEdge = {
  readonly __typename?: 'SeerCarrierGroupEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCarrierGroup;
};

export type SeerCarrierGroupFilter = {
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly groupId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCarrierGroupOrderBy = {
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly groupId?: InputMaybe<SeerOrderBy>;
};

export type SeerCarrierGroupResult = {
  readonly __typename?: 'SeerCarrierGroupResult';
  readonly edges: ReadonlyArray<SeerCarrierGroupEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCarrierIdentifier = {
  readonly __typename?: 'SeerCarrierIdentifier';
  /** Column is of type UUID */
  readonly identifierId: Scalars['String'];
  /** Column is of type UUID */
  readonly carrierId: Scalars['String'];
  readonly type?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
};

export type SeerCarrierIdentifierEdge = {
  readonly __typename?: 'SeerCarrierIdentifierEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCarrierIdentifier;
};

export type SeerCarrierIdentifierFilter = {
  /** Column is of type UUID */
  readonly identifierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly type?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly value?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCarrierIdentifierOrderBy = {
  readonly identifierId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly type?: InputMaybe<SeerOrderBy>;
  readonly value?: InputMaybe<SeerOrderBy>;
};

export type SeerCarrierIdentifierResult = {
  readonly __typename?: 'SeerCarrierIdentifierResult';
  readonly edges: ReadonlyArray<SeerCarrierIdentifierEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCarrierOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly name?: InputMaybe<SeerOrderBy>;
  readonly mainAddressCity?: InputMaybe<SeerOrderBy>;
  readonly mainAddressState?: InputMaybe<SeerOrderBy>;
  readonly mainRepName?: InputMaybe<SeerOrderBy>;
  readonly mainRepId?: InputMaybe<SeerOrderBy>;
  readonly complianceStatus?: InputMaybe<SeerOrderBy>;
  readonly salesProgress?: InputMaybe<SeerOrderBy>;
  readonly opportunity?: InputMaybe<SeerOrderBy>;
  readonly level?: InputMaybe<SeerOrderBy>;
  readonly code?: InputMaybe<SeerOrderBy>;
  readonly standingStatus?: InputMaybe<SeerOrderBy>;
  readonly phone?: InputMaybe<SeerOrderBy>;
  readonly mcNumber?: InputMaybe<SeerOrderBy>;
  readonly dotNumber?: InputMaybe<SeerOrderBy>;
  readonly scacNumber?: InputMaybe<SeerOrderBy>;
  readonly carrierType?: InputMaybe<SeerOrderBy>;
  readonly paymentTerm?: InputMaybe<SeerOrderBy>;
  readonly remitToCarrierId?: InputMaybe<SeerOrderBy>;
  readonly organizationId?: InputMaybe<SeerOrderBy>;
};

export type SeerCarrierRep = {
  readonly __typename?: 'SeerCarrierRep';
  /** Column is of type UUID */
  readonly carrierId: Scalars['String'];
  /** Column is of type UUID */
  readonly repId: Scalars['String'];
};

export type SeerCarrierRepEdge = {
  readonly __typename?: 'SeerCarrierRepEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCarrierRep;
};

export type SeerCarrierRepFilter = {
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly repId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCarrierRepOrderBy = {
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly repId?: InputMaybe<SeerOrderBy>;
};

export type SeerCarrierRepResult = {
  readonly __typename?: 'SeerCarrierRepResult';
  readonly edges: ReadonlyArray<SeerCarrierRepEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCarrierResult = {
  readonly __typename?: 'SeerCarrierResult';
  readonly edges: ReadonlyArray<SeerCarrierEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCarrierRoute = {
  readonly __typename?: 'SeerCarrierRoute';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly oas?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly stops?: Maybe<Scalars['Int']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly distance?: Maybe<Scalars['Float']>;
  readonly distanceUnit?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly mainCustomerRep?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly shipper?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly shipperId?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly puApptStatus?: Maybe<Scalars['String']>;
  readonly consignee?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly consigneeId?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly delApptStatus?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly overMax?: Maybe<Scalars['Float']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverNumber?: Maybe<Scalars['String']>;
};

export type SeerCarrierRouteEdge = {
  readonly __typename?: 'SeerCarrierRouteEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCarrierRoute;
};

export type SeerCarrierRouteFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oas?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly distance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly distanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCustomerRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipper?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly shipperId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly consignee?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly consigneeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly maxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly overMax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly trailerNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tractorNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCarrierRouteOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly oas?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly size?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly distance?: InputMaybe<SeerOrderBy>;
  readonly distanceUnit?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly mainCustomerRep?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly shipper?: InputMaybe<SeerOrderBy>;
  readonly shipperId?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly originState?: InputMaybe<SeerOrderBy>;
  readonly puApptStatus?: InputMaybe<SeerOrderBy>;
  readonly consignee?: InputMaybe<SeerOrderBy>;
  readonly consigneeId?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly destinationState?: InputMaybe<SeerOrderBy>;
  readonly delApptStatus?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly maxCost?: InputMaybe<SeerOrderBy>;
  readonly overMax?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber?: InputMaybe<SeerOrderBy>;
  readonly tractorNumber?: InputMaybe<SeerOrderBy>;
  readonly driverName?: InputMaybe<SeerOrderBy>;
  readonly driverNumber?: InputMaybe<SeerOrderBy>;
};

export type SeerCarrierRouteResult = {
  readonly __typename?: 'SeerCarrierRouteResult';
  readonly edges: ReadonlyArray<SeerCarrierRouteEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCarrierService = {
  readonly __typename?: 'SeerCarrierService';
  /** Column is of type UUID */
  readonly serviceId: Scalars['String'];
  /** Column is of type UUID */
  readonly carrierId: Scalars['String'];
  readonly value?: Maybe<Scalars['String']>;
};

export type SeerCarrierServiceEdge = {
  readonly __typename?: 'SeerCarrierServiceEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCarrierService;
};

export type SeerCarrierServiceFilter = {
  /** Column is of type UUID */
  readonly serviceId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly value?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCarrierServiceOrderBy = {
  readonly serviceId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly value?: InputMaybe<SeerOrderBy>;
};

export type SeerCarrierServiceResult = {
  readonly __typename?: 'SeerCarrierServiceResult';
  readonly edges: ReadonlyArray<SeerCarrierServiceEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCarrierShipment = {
  readonly __typename?: 'SeerCarrierShipment';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly ediMessageType?: Maybe<Scalars['String']>;
  readonly direction?: Maybe<Scalars['String']>;
  readonly shipmentId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly shipmentSentDatetime: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly waybillNumber?: Maybe<Scalars['String']>;
  readonly waybillStatus?: Maybe<Scalars['String']>;
};

export type SeerCarrierShipmentEdge = {
  readonly __typename?: 'SeerCarrierShipmentEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCarrierShipment;
};

export type SeerCarrierShipmentFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ediMessageType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly direction?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipmentId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipmentSentDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly waybillNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly waybillStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCarrierShipmentOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly ediMessageType?: InputMaybe<SeerOrderBy>;
  readonly direction?: InputMaybe<SeerOrderBy>;
  readonly shipmentId?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly shipmentSentDatetime?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly createdAt?: InputMaybe<SeerOrderBy>;
  readonly waybillNumber?: InputMaybe<SeerOrderBy>;
  readonly waybillStatus?: InputMaybe<SeerOrderBy>;
};

export type SeerCarrierShipmentResult = {
  readonly __typename?: 'SeerCarrierShipmentResult';
  readonly edges: ReadonlyArray<SeerCarrierShipmentEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCdcaudit = {
  readonly __typename?: 'SeerCdcaudit';
  readonly auditId: Scalars['Int'];
  readonly isEnabled: Scalars['Boolean'];
  readonly modifiedDate: Scalars['DateTime'];
  readonly modifiedBy: Scalars['String'];
  readonly tableSchema: Scalars['String'];
  readonly tableName: Scalars['String'];
  readonly reasonForModify: Scalars['String'];
};

export type SeerCdcauditEdge = {
  readonly __typename?: 'SeerCdcauditEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCdcaudit;
};

export type SeerCdcauditFilter = {
  readonly auditId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly isEnabled?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly modifiedDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly modifiedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tableSchema?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tableName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly reasonForModify?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCdcauditOrderBy = {
  readonly auditId?: InputMaybe<SeerOrderBy>;
  readonly isEnabled?: InputMaybe<SeerOrderBy>;
  readonly modifiedDate?: InputMaybe<SeerOrderBy>;
  readonly modifiedBy?: InputMaybe<SeerOrderBy>;
  readonly tableSchema?: InputMaybe<SeerOrderBy>;
  readonly tableName?: InputMaybe<SeerOrderBy>;
  readonly reasonForModify?: InputMaybe<SeerOrderBy>;
};

export type SeerCdcauditResult = {
  readonly __typename?: 'SeerCdcauditResult';
  readonly edges: ReadonlyArray<SeerCdcauditEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCommandLog = {
  readonly __typename?: 'SeerCommandLog';
  readonly id: Scalars['Int'];
  readonly databaseName?: Maybe<Scalars['String']>;
  readonly schemaName?: Maybe<Scalars['String']>;
  readonly objectName?: Maybe<Scalars['String']>;
  readonly indexName?: Maybe<Scalars['String']>;
  readonly statisticsName?: Maybe<Scalars['String']>;
  readonly partitionNumber?: Maybe<Scalars['Int']>;
  readonly command: Scalars['String'];
  readonly commandType: Scalars['String'];
  readonly startTime: Scalars['DateTime'];
  readonly endTime?: Maybe<Scalars['DateTime']>;
  readonly errorNumber?: Maybe<Scalars['Int']>;
  readonly errorMessage?: Maybe<Scalars['String']>;
};

export type SeerCommandLogEdge = {
  readonly __typename?: 'SeerCommandLogEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCommandLog;
};

export type SeerCommandLogFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly databaseName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly schemaName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly objectName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly indexName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly statisticsName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly partitionNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly command?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly commandType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly startTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly endTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly errorNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly errorMessage?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCommandLogOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly databaseName?: InputMaybe<SeerOrderBy>;
  readonly schemaName?: InputMaybe<SeerOrderBy>;
  readonly objectName?: InputMaybe<SeerOrderBy>;
  readonly indexName?: InputMaybe<SeerOrderBy>;
  readonly statisticsName?: InputMaybe<SeerOrderBy>;
  readonly partitionNumber?: InputMaybe<SeerOrderBy>;
  readonly command?: InputMaybe<SeerOrderBy>;
  readonly commandType?: InputMaybe<SeerOrderBy>;
  readonly startTime?: InputMaybe<SeerOrderBy>;
  readonly endTime?: InputMaybe<SeerOrderBy>;
  readonly errorNumber?: InputMaybe<SeerOrderBy>;
  readonly errorMessage?: InputMaybe<SeerOrderBy>;
};

export type SeerCommandLogResult = {
  readonly __typename?: 'SeerCommandLogResult';
  readonly edges: ReadonlyArray<SeerCommandLogEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCrmInteraction = {
  readonly __typename?: 'SeerCrmInteraction';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly interactionId: Scalars['String'];
  /** Column is of type UUID */
  readonly completedById?: Maybe<Scalars['String']>;
  readonly completedDatetime?: Maybe<Scalars['DateTime']>;
  readonly scheduledDatetime?: Maybe<Scalars['DateTime']>;
  readonly details?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly createdById?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly interactionFrequency?: Maybe<Scalars['String']>;
  readonly entityType?: Maybe<Scalars['String']>;
};

export type SeerCrmInteractionEdge = {
  readonly __typename?: 'SeerCrmInteractionEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCrmInteraction;
};

export type SeerCrmInteractionFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly interactionId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly completedById?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly completedDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly scheduledDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly details?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly createdById?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly updatedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly interactionFrequency?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly entityType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCrmInteractionOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly interactionId?: InputMaybe<SeerOrderBy>;
  readonly completedById?: InputMaybe<SeerOrderBy>;
  readonly completedDatetime?: InputMaybe<SeerOrderBy>;
  readonly scheduledDatetime?: InputMaybe<SeerOrderBy>;
  readonly details?: InputMaybe<SeerOrderBy>;
  readonly createdById?: InputMaybe<SeerOrderBy>;
  readonly createdAt?: InputMaybe<SeerOrderBy>;
  readonly updatedAt?: InputMaybe<SeerOrderBy>;
  readonly interactionFrequency?: InputMaybe<SeerOrderBy>;
  readonly entityType?: InputMaybe<SeerOrderBy>;
};

export type SeerCrmInteractionResult = {
  readonly __typename?: 'SeerCrmInteractionResult';
  readonly edges: ReadonlyArray<SeerCrmInteractionEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCurrentAndNextStop = {
  readonly __typename?: 'SeerCurrentAndNextStop';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly currentStopId?: Maybe<Scalars['String']>;
  readonly nextStopId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByTxId: Scalars['String'];
  readonly updatedByTxId: Scalars['String'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly eid: Scalars['Int'];
  /** Column is of type UUID */
  readonly currentStopIdUuid?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly nextStopIdUuid?: Maybe<Scalars['String']>;
};

export type SeerCurrentAndNextStopEdge = {
  readonly __typename?: 'SeerCurrentAndNextStopEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCurrentAndNextStop;
};

export type SeerCurrentAndNextStopFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly updatedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly createdByTxId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedByTxId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly currentStopIdUuid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly nextStopIdUuid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCurrentAndNextStopOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly currentStopId?: InputMaybe<SeerOrderBy>;
  readonly nextStopId?: InputMaybe<SeerOrderBy>;
  readonly createdAt?: InputMaybe<SeerOrderBy>;
  readonly updatedAt?: InputMaybe<SeerOrderBy>;
  readonly createdByTxId?: InputMaybe<SeerOrderBy>;
  readonly updatedByTxId?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly updatedBy?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
  readonly currentStopIdUuid?: InputMaybe<SeerOrderBy>;
  readonly nextStopIdUuid?: InputMaybe<SeerOrderBy>;
};

export type SeerCurrentAndNextStopResult = {
  readonly __typename?: 'SeerCurrentAndNextStopResult';
  readonly edges: ReadonlyArray<SeerCurrentAndNextStopEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCurrentStop = {
  readonly __typename?: 'SeerCurrentStop';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly stopId: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByTxId: Scalars['String'];
  readonly updatedByTxId: Scalars['String'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly eid: Scalars['Int'];
};

export type SeerCurrentStopEdge = {
  readonly __typename?: 'SeerCurrentStopEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCurrentStop;
};

export type SeerCurrentStopFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly stopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly updatedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly createdByTxId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedByTxId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
};

export type SeerCurrentStopOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly stopId?: InputMaybe<SeerOrderBy>;
  readonly createdAt?: InputMaybe<SeerOrderBy>;
  readonly updatedAt?: InputMaybe<SeerOrderBy>;
  readonly createdByTxId?: InputMaybe<SeerOrderBy>;
  readonly updatedByTxId?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly updatedBy?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
};

export type SeerCurrentStopResult = {
  readonly __typename?: 'SeerCurrentStopResult';
  readonly edges: ReadonlyArray<SeerCurrentStopEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCustomerAddress = {
  readonly __typename?: 'SeerCustomerAddress';
  /** Column is of type UUID */
  readonly addressId: Scalars['String'];
  /** Column is of type UUID */
  readonly customerId: Scalars['String'];
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly zipcode?: Maybe<Scalars['String']>;
  readonly fullAddressPoint?: Maybe<SeerGeoPoint>;
  readonly isMainAddress?: Maybe<Scalars['Boolean']>;
  readonly streetAddress?: Maybe<Scalars['String']>;
};

export type SeerCustomerAddressEdge = {
  readonly __typename?: 'SeerCustomerAddressEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCustomerAddress;
};

export type SeerCustomerAddressFilter = {
  /** Column is of type UUID */
  readonly addressId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly city?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly state?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly country?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly zipcode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly fullAddressPoint?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly isMainAddress?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly streetAddress?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCustomerAddressOrderBy = {
  readonly addressId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly city?: InputMaybe<SeerOrderBy>;
  readonly state?: InputMaybe<SeerOrderBy>;
  readonly country?: InputMaybe<SeerOrderBy>;
  readonly zipcode?: InputMaybe<SeerOrderBy>;
  readonly isMainAddress?: InputMaybe<SeerOrderBy>;
  readonly streetAddress?: InputMaybe<SeerOrderBy>;
};

export type SeerCustomerAddressResult = {
  readonly __typename?: 'SeerCustomerAddressResult';
  readonly edges: ReadonlyArray<SeerCustomerAddressEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCustomerCapacityConstraintOrder = {
  readonly __typename?: 'SeerCustomerCapacityConstraintOrder';
  /** Column is of type UUID */
  readonly capacityConstraintOrderId: Scalars['String'];
  /** Column is of type UUID */
  readonly capacityConstraintTimePeriodId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly orderId: Scalars['String'];
};

export type SeerCustomerCapacityConstraintOrderEdge = {
  readonly __typename?: 'SeerCustomerCapacityConstraintOrderEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCustomerCapacityConstraintOrder;
};

export type SeerCustomerCapacityConstraintOrderFilter = {
  /** Column is of type UUID */
  readonly capacityConstraintOrderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly capacityConstraintTimePeriodId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCustomerCapacityConstraintOrderOrderBy = {
  readonly capacityConstraintOrderId?: InputMaybe<SeerOrderBy>;
  readonly capacityConstraintTimePeriodId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
};

export type SeerCustomerCapacityConstraintOrderResult = {
  readonly __typename?: 'SeerCustomerCapacityConstraintOrderResult';
  readonly edges: ReadonlyArray<SeerCustomerCapacityConstraintOrderEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCustomerCapacityConstraintTimePeriod = {
  readonly __typename?: 'SeerCustomerCapacityConstraintTimePeriod';
  /** Column is of type UUID */
  readonly capacityConstraintTimePeriodId: Scalars['String'];
  readonly startDate: Scalars['DateTime'];
  readonly endDate: Scalars['DateTime'];
  readonly sundayConstraint?: Maybe<Scalars['Int']>;
  readonly mondayConstraint?: Maybe<Scalars['Int']>;
  readonly tuesdayConstraint?: Maybe<Scalars['Int']>;
  readonly wednesdayConstraint?: Maybe<Scalars['Int']>;
  readonly thursdayConstraint?: Maybe<Scalars['Int']>;
  readonly fridayConstraint?: Maybe<Scalars['Int']>;
  readonly saturdayConstraint?: Maybe<Scalars['Int']>;
  readonly weeklyConstraint?: Maybe<Scalars['Int']>;
  readonly sundayUtilized: Scalars['Int'];
  readonly mondayUtilized: Scalars['Int'];
  readonly tuesdayUtilized: Scalars['Int'];
  readonly wednesdayUtilized: Scalars['Int'];
  readonly thursdayUtilized: Scalars['Int'];
  readonly fridayUtilized: Scalars['Int'];
  readonly saturdayUtilized: Scalars['Int'];
  readonly weeklyUtilized: Scalars['Int'];
  readonly sundayAvailable?: Maybe<Scalars['Int']>;
  readonly mondayAvailable?: Maybe<Scalars['Int']>;
  readonly tuesdayAvailable?: Maybe<Scalars['Int']>;
  readonly wednesdayAvailable?: Maybe<Scalars['Int']>;
  readonly thursdayAvailable?: Maybe<Scalars['Int']>;
  readonly fridayAvailable?: Maybe<Scalars['Int']>;
  readonly saturdayAvailable?: Maybe<Scalars['Int']>;
  readonly weeklyAvailable?: Maybe<Scalars['Int']>;
};

export type SeerCustomerCapacityConstraintTimePeriodEdge = {
  readonly __typename?: 'SeerCustomerCapacityConstraintTimePeriodEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCustomerCapacityConstraintTimePeriod;
};

export type SeerCustomerCapacityConstraintTimePeriodFilter = {
  /** Column is of type UUID */
  readonly capacityConstraintTimePeriodId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly startDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly endDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly sundayConstraint?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly mondayConstraint?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly tuesdayConstraint?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly wednesdayConstraint?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly thursdayConstraint?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly fridayConstraint?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly saturdayConstraint?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly weeklyConstraint?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly sundayUtilized?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly mondayUtilized?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly tuesdayUtilized?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly wednesdayUtilized?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly thursdayUtilized?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly fridayUtilized?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly saturdayUtilized?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly weeklyUtilized?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly sundayAvailable?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly mondayAvailable?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly tuesdayAvailable?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly wednesdayAvailable?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly thursdayAvailable?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly fridayAvailable?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly saturdayAvailable?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly weeklyAvailable?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
};

export type SeerCustomerCapacityConstraintTimePeriodOrderBy = {
  readonly capacityConstraintTimePeriodId?: InputMaybe<SeerOrderBy>;
  readonly startDate?: InputMaybe<SeerOrderBy>;
  readonly endDate?: InputMaybe<SeerOrderBy>;
  readonly sundayConstraint?: InputMaybe<SeerOrderBy>;
  readonly mondayConstraint?: InputMaybe<SeerOrderBy>;
  readonly tuesdayConstraint?: InputMaybe<SeerOrderBy>;
  readonly wednesdayConstraint?: InputMaybe<SeerOrderBy>;
  readonly thursdayConstraint?: InputMaybe<SeerOrderBy>;
  readonly fridayConstraint?: InputMaybe<SeerOrderBy>;
  readonly saturdayConstraint?: InputMaybe<SeerOrderBy>;
  readonly weeklyConstraint?: InputMaybe<SeerOrderBy>;
  readonly sundayUtilized?: InputMaybe<SeerOrderBy>;
  readonly mondayUtilized?: InputMaybe<SeerOrderBy>;
  readonly tuesdayUtilized?: InputMaybe<SeerOrderBy>;
  readonly wednesdayUtilized?: InputMaybe<SeerOrderBy>;
  readonly thursdayUtilized?: InputMaybe<SeerOrderBy>;
  readonly fridayUtilized?: InputMaybe<SeerOrderBy>;
  readonly saturdayUtilized?: InputMaybe<SeerOrderBy>;
  readonly weeklyUtilized?: InputMaybe<SeerOrderBy>;
  readonly sundayAvailable?: InputMaybe<SeerOrderBy>;
  readonly mondayAvailable?: InputMaybe<SeerOrderBy>;
  readonly tuesdayAvailable?: InputMaybe<SeerOrderBy>;
  readonly wednesdayAvailable?: InputMaybe<SeerOrderBy>;
  readonly thursdayAvailable?: InputMaybe<SeerOrderBy>;
  readonly fridayAvailable?: InputMaybe<SeerOrderBy>;
  readonly saturdayAvailable?: InputMaybe<SeerOrderBy>;
  readonly weeklyAvailable?: InputMaybe<SeerOrderBy>;
};

export type SeerCustomerCapacityConstraintTimePeriodResult = {
  readonly __typename?: 'SeerCustomerCapacityConstraintTimePeriodResult';
  readonly edges: ReadonlyArray<SeerCustomerCapacityConstraintTimePeriodEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCustomerCommitment = {
  readonly __typename?: 'SeerCustomerCommitment';
  /** Column is of type UUID */
  readonly customerCommitmentId: Scalars['String'];
  readonly commitmentFrequencyTerm: Scalars['String'];
  /** Column is of type UUID */
  readonly customerId: Scalars['String'];
};

export type SeerCustomerCommitmentEdge = {
  readonly __typename?: 'SeerCustomerCommitmentEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCustomerCommitment;
};

export type SeerCustomerCommitmentFilter = {
  /** Column is of type UUID */
  readonly customerCommitmentId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly commitmentFrequencyTerm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCustomerCommitmentOrder = {
  readonly __typename?: 'SeerCustomerCommitmentOrder';
  /** Column is of type UUID */
  readonly customerCommitmentOrderId: Scalars['String'];
  /** Column is of type UUID */
  readonly customerCommitmentTimePeriodId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerCommitmentId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId: Scalars['String'];
  readonly orderActivationStatus: Scalars['String'];
  readonly tenderStatus?: Maybe<Scalars['String']>;
};

export type SeerCustomerCommitmentOrderBy = {
  readonly customerCommitmentId?: InputMaybe<SeerOrderBy>;
  readonly commitmentFrequencyTerm?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
};

export type SeerCustomerCommitmentOrderEdge = {
  readonly __typename?: 'SeerCustomerCommitmentOrderEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCustomerCommitmentOrder;
};

export type SeerCustomerCommitmentOrderFilter = {
  /** Column is of type UUID */
  readonly customerCommitmentOrderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerCommitmentTimePeriodId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerCommitmentId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tenderStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCustomerCommitmentOrderOrderBy = {
  readonly customerCommitmentOrderId?: InputMaybe<SeerOrderBy>;
  readonly customerCommitmentTimePeriodId?: InputMaybe<SeerOrderBy>;
  readonly customerCommitmentId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly orderActivationStatus?: InputMaybe<SeerOrderBy>;
  readonly tenderStatus?: InputMaybe<SeerOrderBy>;
};

export type SeerCustomerCommitmentOrderResult = {
  readonly __typename?: 'SeerCustomerCommitmentOrderResult';
  readonly edges: ReadonlyArray<SeerCustomerCommitmentOrderEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCustomerCommitmentResult = {
  readonly __typename?: 'SeerCustomerCommitmentResult';
  readonly edges: ReadonlyArray<SeerCustomerCommitmentEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCustomerCommitmentSettingOrderStatus = {
  readonly __typename?: 'SeerCustomerCommitmentSettingOrderStatus';
  /** Column is of type UUID */
  readonly customerCommitmentSettingOrderStatusId: Scalars['String'];
  /** Column is of type UUID */
  readonly customerCommitmentSettingId: Scalars['String'];
  /** Column is of type UUID */
  readonly customerId: Scalars['String'];
  readonly orderStatus: Scalars['String'];
};

export type SeerCustomerCommitmentSettingOrderStatusEdge = {
  readonly __typename?: 'SeerCustomerCommitmentSettingOrderStatusEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCustomerCommitmentSettingOrderStatus;
};

export type SeerCustomerCommitmentSettingOrderStatusFilter = {
  /** Column is of type UUID */
  readonly customerCommitmentSettingOrderStatusId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerCommitmentSettingId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCustomerCommitmentSettingOrderStatusOrderBy = {
  readonly customerCommitmentSettingOrderStatusId?: InputMaybe<SeerOrderBy>;
  readonly customerCommitmentSettingId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly orderStatus?: InputMaybe<SeerOrderBy>;
};

export type SeerCustomerCommitmentSettingOrderStatusResult = {
  readonly __typename?: 'SeerCustomerCommitmentSettingOrderStatusResult';
  readonly edges: ReadonlyArray<SeerCustomerCommitmentSettingOrderStatusEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCustomerCommitmentTimePeriod = {
  readonly __typename?: 'SeerCustomerCommitmentTimePeriod';
  /** Column is of type UUID */
  readonly customerCommitmentTimePeriodId: Scalars['String'];
  readonly volume: Scalars['Int'];
  readonly count: Scalars['Int'];
  readonly endDate: Scalars['DateTime'];
  readonly startDate: Scalars['DateTime'];
};

export type SeerCustomerCommitmentTimePeriodEdge = {
  readonly __typename?: 'SeerCustomerCommitmentTimePeriodEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCustomerCommitmentTimePeriod;
};

export type SeerCustomerCommitmentTimePeriodFilter = {
  /** Column is of type UUID */
  readonly customerCommitmentTimePeriodId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly volume?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly count?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly endDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly startDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerCustomerCommitmentTimePeriodOrderBy = {
  readonly customerCommitmentTimePeriodId?: InputMaybe<SeerOrderBy>;
  readonly volume?: InputMaybe<SeerOrderBy>;
  readonly count?: InputMaybe<SeerOrderBy>;
  readonly endDate?: InputMaybe<SeerOrderBy>;
  readonly startDate?: InputMaybe<SeerOrderBy>;
};

export type SeerCustomerCommitmentTimePeriodResult = {
  readonly __typename?: 'SeerCustomerCommitmentTimePeriodResult';
  readonly edges: ReadonlyArray<SeerCustomerCommitmentTimePeriodEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCustomerGroup = {
  readonly __typename?: 'SeerCustomerGroup';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId: Scalars['String'];
  /** Column is of type UUID */
  readonly customerId: Scalars['String'];
  readonly type?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly groupId?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly showOnMprbActiveScreens: Scalars['Boolean'];
};

export type SeerCustomerGroupEdge = {
  readonly __typename?: 'SeerCustomerGroupEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCustomerGroup;
};

export type SeerCustomerGroupFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly type?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly groupId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly showOnMprbActiveScreens?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerCustomerGroupOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly type?: InputMaybe<SeerOrderBy>;
  readonly groupId?: InputMaybe<SeerOrderBy>;
  readonly name?: InputMaybe<SeerOrderBy>;
  readonly showOnMprbActiveScreens?: InputMaybe<SeerOrderBy>;
};

export type SeerCustomerGroupResult = {
  readonly __typename?: 'SeerCustomerGroupResult';
  readonly edges: ReadonlyArray<SeerCustomerGroupEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCustomerIdentifier = {
  readonly __typename?: 'SeerCustomerIdentifier';
  /** Column is of type UUID */
  readonly identifierId: Scalars['String'];
  /** Column is of type UUID */
  readonly customerId: Scalars['String'];
  readonly systemId?: Maybe<Scalars['String']>;
  readonly system?: Maybe<Scalars['String']>;
};

export type SeerCustomerIdentifierEdge = {
  readonly __typename?: 'SeerCustomerIdentifierEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCustomerIdentifier;
};

export type SeerCustomerIdentifierFilter = {
  /** Column is of type UUID */
  readonly identifierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly systemId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly system?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCustomerIdentifierOrderBy = {
  readonly identifierId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly systemId?: InputMaybe<SeerOrderBy>;
  readonly system?: InputMaybe<SeerOrderBy>;
};

export type SeerCustomerIdentifierResult = {
  readonly __typename?: 'SeerCustomerIdentifierResult';
  readonly edges: ReadonlyArray<SeerCustomerIdentifierEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCustomerOrder = {
  readonly __typename?: 'SeerCustomerOrder';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly oas?: Maybe<Scalars['String']>;
  readonly ots?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly mainCustomerRep?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly shipper?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly shipperId?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly puApptStatus?: Maybe<Scalars['String']>;
  readonly distance?: Maybe<Scalars['Float']>;
  readonly distanceUnit?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly consignee?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly consigneeId?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly delApptStatus?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly overMax?: Maybe<Scalars['Float']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  readonly loadMaxCost?: Maybe<Scalars['Float']>;
  readonly loadOverMax?: Maybe<Scalars['Float']>;
  readonly loadTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalCost?: Maybe<Scalars['Float']>;
};

export type SeerCustomerOrderEdge = {
  readonly __typename?: 'SeerCustomerOrderEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCustomerOrder;
};

export type SeerCustomerOrderFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oas?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ots?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCustomerRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipper?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly shipperId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly distance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly distanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly consignee?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly consigneeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly maxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly overMax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly loadMaxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly loadOverMax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly loadTotalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly loadTotalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
};

export type SeerCustomerOrderOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly oas?: InputMaybe<SeerOrderBy>;
  readonly ots?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly size?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly mainCustomerRep?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly shipper?: InputMaybe<SeerOrderBy>;
  readonly shipperId?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly originState?: InputMaybe<SeerOrderBy>;
  readonly puApptStatus?: InputMaybe<SeerOrderBy>;
  readonly distance?: InputMaybe<SeerOrderBy>;
  readonly distanceUnit?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly consignee?: InputMaybe<SeerOrderBy>;
  readonly consigneeId?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly destinationState?: InputMaybe<SeerOrderBy>;
  readonly delApptStatus?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly maxCost?: InputMaybe<SeerOrderBy>;
  readonly overMax?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
  readonly loadMaxCost?: InputMaybe<SeerOrderBy>;
  readonly loadOverMax?: InputMaybe<SeerOrderBy>;
  readonly loadTotalRate?: InputMaybe<SeerOrderBy>;
  readonly loadTotalCost?: InputMaybe<SeerOrderBy>;
};

export type SeerCustomerOrderResult = {
  readonly __typename?: 'SeerCustomerOrderResult';
  readonly edges: ReadonlyArray<SeerCustomerOrderEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCustomerRep = {
  readonly __typename?: 'SeerCustomerRep';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId: Scalars['String'];
  /** Column is of type UUID */
  readonly customerId: Scalars['String'];
  readonly name?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly employeeId?: Maybe<Scalars['String']>;
};

export type SeerCustomerRepEdge = {
  readonly __typename?: 'SeerCustomerRepEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCustomerRep;
};

export type SeerCustomerRepFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly type?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly employeeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCustomerRepOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly name?: InputMaybe<SeerOrderBy>;
  readonly type?: InputMaybe<SeerOrderBy>;
  readonly employeeId?: InputMaybe<SeerOrderBy>;
};

export type SeerCustomerRepResult = {
  readonly __typename?: 'SeerCustomerRepResult';
  readonly edges: ReadonlyArray<SeerCustomerRepEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

/** Distance units for seer */
export enum SeerDistanceUnit {
  Miles = 'MILES',
  Feet = 'FEET',
  Meter = 'METER',
  Kilometer = 'KILOMETER'
}

export type SeerEmployee = {
  readonly __typename?: 'SeerEmployee';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly displayName: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly employeeGroup?: Maybe<Scalars['String']>;
};

export type SeerEmployeeEdge = {
  readonly __typename?: 'SeerEmployeeEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerEmployee;
};

export type SeerEmployeeFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly firstName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly displayName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly fullName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly employeeGroup?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerEmployeeOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly firstName?: InputMaybe<SeerOrderBy>;
  readonly lastName?: InputMaybe<SeerOrderBy>;
  readonly displayName?: InputMaybe<SeerOrderBy>;
  readonly fullName?: InputMaybe<SeerOrderBy>;
  readonly employeeGroup?: InputMaybe<SeerOrderBy>;
};

export type SeerEmployeeResult = {
  readonly __typename?: 'SeerEmployeeResult';
  readonly edges: ReadonlyArray<SeerEmployeeEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerExceptionFilterInput = {
  readonly exceptionTypes?: InputMaybe<ReadonlyArray<ClientExceptionRecordType>>;
  readonly reasonCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type SeerFacility = {
  readonly __typename?: 'SeerFacility';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly name?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
};

export type SeerFacilityAddress = {
  readonly __typename?: 'SeerFacilityAddress';
  /** Column is of type UUID */
  readonly addressId: Scalars['String'];
  /** Column is of type UUID */
  readonly facilityId: Scalars['String'];
  readonly street1?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly fullAddressPoint?: Maybe<SeerGeoPoint>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly zipCode?: Maybe<Scalars['String']>;
  readonly isMain?: Maybe<Scalars['Boolean']>;
};

export type SeerFacilityAddressEdge = {
  readonly __typename?: 'SeerFacilityAddressEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerFacilityAddress;
};

export type SeerFacilityAddressFilter = {
  /** Column is of type UUID */
  readonly addressId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly facilityId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly street1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly city?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly fullAddressPoint?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly state?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly country?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly zipCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isMain?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerFacilityAddressOrderBy = {
  readonly addressId?: InputMaybe<SeerOrderBy>;
  readonly facilityId?: InputMaybe<SeerOrderBy>;
  readonly street1?: InputMaybe<SeerOrderBy>;
  readonly city?: InputMaybe<SeerOrderBy>;
  readonly state?: InputMaybe<SeerOrderBy>;
  readonly country?: InputMaybe<SeerOrderBy>;
  readonly zipCode?: InputMaybe<SeerOrderBy>;
  readonly isMain?: InputMaybe<SeerOrderBy>;
};

export type SeerFacilityAddressResult = {
  readonly __typename?: 'SeerFacilityAddressResult';
  readonly edges: ReadonlyArray<SeerFacilityAddressEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerFacilityCarrier = {
  readonly __typename?: 'SeerFacilityCarrier';
  /** Column is of type UUID */
  readonly carrierId: Scalars['String'];
  /** Column is of type UUID */
  readonly facilityId: Scalars['String'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
  /** Column is of type UUID */
  readonly relationshipId: Scalars['String'];
};

export type SeerFacilityCarrierEdge = {
  readonly __typename?: 'SeerFacilityCarrierEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerFacilityCarrier;
};

export type SeerFacilityCarrierFilter = {
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly facilityId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isActive?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  /** Column is of type UUID */
  readonly relationshipId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerFacilityCarrierOrderBy = {
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly facilityId?: InputMaybe<SeerOrderBy>;
  readonly isActive?: InputMaybe<SeerOrderBy>;
  readonly relationshipId?: InputMaybe<SeerOrderBy>;
};

export type SeerFacilityCarrierResult = {
  readonly __typename?: 'SeerFacilityCarrierResult';
  readonly edges: ReadonlyArray<SeerFacilityCarrierEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerFacilityCustomer = {
  readonly __typename?: 'SeerFacilityCustomer';
  /** Column is of type UUID */
  readonly facilityId: Scalars['String'];
  /** Column is of type UUID */
  readonly customerId: Scalars['String'];
  /** Column is of type UUID */
  readonly relationshipId: Scalars['String'];
};

export type SeerFacilityCustomerEdge = {
  readonly __typename?: 'SeerFacilityCustomerEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerFacilityCustomer;
};

export type SeerFacilityCustomerFilter = {
  /** Column is of type UUID */
  readonly facilityId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly relationshipId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerFacilityCustomerOrderBy = {
  readonly facilityId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly relationshipId?: InputMaybe<SeerOrderBy>;
};

export type SeerFacilityCustomerRelationship = {
  readonly __typename?: 'SeerFacilityCustomerRelationship';
  /** Column is of type UUID */
  readonly relationshipId: Scalars['String'];
  /** Column is of type UUID */
  readonly facilityId: Scalars['String'];
  /** Column is of type UUID */
  readonly customerId: Scalars['String'];
};

export type SeerFacilityCustomerRelationshipEdge = {
  readonly __typename?: 'SeerFacilityCustomerRelationshipEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerFacilityCustomerRelationship;
};

export type SeerFacilityCustomerRelationshipFilter = {
  /** Column is of type UUID */
  readonly relationshipId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly facilityId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerFacilityCustomerRelationshipOrderBy = {
  readonly relationshipId?: InputMaybe<SeerOrderBy>;
  readonly facilityId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
};

export type SeerFacilityCustomerRelationshipResult = {
  readonly __typename?: 'SeerFacilityCustomerRelationshipResult';
  readonly edges: ReadonlyArray<SeerFacilityCustomerRelationshipEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerFacilityCustomerResult = {
  readonly __typename?: 'SeerFacilityCustomerResult';
  readonly edges: ReadonlyArray<SeerFacilityCustomerEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerFacilityEdge = {
  readonly __typename?: 'SeerFacilityEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerFacility;
};

export type SeerFacilityFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly code?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly phone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly type?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly status?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerFacilityIdentifier = {
  readonly __typename?: 'SeerFacilityIdentifier';
  /** Column is of type UUID */
  readonly identifierId: Scalars['String'];
  /** Column is of type UUID */
  readonly facilityId: Scalars['String'];
  readonly system?: Maybe<Scalars['String']>;
  readonly systemId?: Maybe<Scalars['String']>;
};

export type SeerFacilityIdentifierEdge = {
  readonly __typename?: 'SeerFacilityIdentifierEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerFacilityIdentifier;
};

export type SeerFacilityIdentifierFilter = {
  /** Column is of type UUID */
  readonly identifierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly facilityId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly system?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly systemId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerFacilityIdentifierOrderBy = {
  readonly identifierId?: InputMaybe<SeerOrderBy>;
  readonly facilityId?: InputMaybe<SeerOrderBy>;
  readonly system?: InputMaybe<SeerOrderBy>;
  readonly systemId?: InputMaybe<SeerOrderBy>;
};

export type SeerFacilityIdentifierResult = {
  readonly __typename?: 'SeerFacilityIdentifierResult';
  readonly edges: ReadonlyArray<SeerFacilityIdentifierEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerFacilityOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly name?: InputMaybe<SeerOrderBy>;
  readonly code?: InputMaybe<SeerOrderBy>;
  readonly phone?: InputMaybe<SeerOrderBy>;
  readonly type?: InputMaybe<SeerOrderBy>;
  readonly status?: InputMaybe<SeerOrderBy>;
};

export type SeerFacilityResult = {
  readonly __typename?: 'SeerFacilityResult';
  readonly edges: ReadonlyArray<SeerFacilityEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerFacilityRoute = {
  readonly __typename?: 'SeerFacilityRoute';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly oas?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly distance?: Maybe<Scalars['Float']>;
  readonly distanceUnit?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly mainCustomerRep?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly shipper?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly shipperId?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly puApptStatus?: Maybe<Scalars['String']>;
  readonly consignee?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly consigneeId?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly delApptStatus?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
};

export type SeerFacilityRouteEdge = {
  readonly __typename?: 'SeerFacilityRouteEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerFacilityRoute;
};

export type SeerFacilityRouteFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oas?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly distance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly distanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCustomerRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipper?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly shipperId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly consignee?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly consigneeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
};

export type SeerFacilityRouteOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly oas?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly size?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly distance?: InputMaybe<SeerOrderBy>;
  readonly distanceUnit?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly mainCustomerRep?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly shipper?: InputMaybe<SeerOrderBy>;
  readonly shipperId?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly originState?: InputMaybe<SeerOrderBy>;
  readonly puApptStatus?: InputMaybe<SeerOrderBy>;
  readonly consignee?: InputMaybe<SeerOrderBy>;
  readonly consigneeId?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly destinationState?: InputMaybe<SeerOrderBy>;
  readonly delApptStatus?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
};

export type SeerFacilityRouteResult = {
  readonly __typename?: 'SeerFacilityRouteResult';
  readonly edges: ReadonlyArray<SeerFacilityRouteEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerFacilityTrackingBoard = {
  readonly __typename?: 'SeerFacilityTrackingBoard';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly rlc?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly oas?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly destination?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly nxtCallBkDtTm?: Maybe<Scalars['DateTime']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverNumber?: Maybe<Scalars['String']>;
  readonly connectivity?: Maybe<Scalars['String']>;
  readonly infoFrom?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly lastTrackingNotes?: Maybe<Scalars['String']>;
  readonly createDate?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly latestElectronicLocationUpdateDatetime?: Maybe<Scalars['DateTime']>;
  readonly latestTrackingRequest?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly facilityId?: Maybe<Scalars['String']>;
  readonly autoEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly manualEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly emptyDatetime?: Maybe<Scalars['DateTime']>;
  readonly emptyDate?: Maybe<Scalars['DateTime']>;
  readonly driverElectronicTrackingTypeId?: Maybe<Scalars['String']>;
  readonly nextApptStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptStartTime?: Maybe<Scalars['Int']>;
  readonly nextApptEndTime?: Maybe<Scalars['Int']>;
  readonly nextApptStartTimeZone?: Maybe<Scalars['String']>;
  readonly nextApptEndTimeZone?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly originFacilityId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly destinationFacilityId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly bookedById?: Maybe<Scalars['String']>;
  readonly bookedByName?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly nextStopType?: Maybe<Scalars['String']>;
};

export type SeerFacilityTrackingBoardEdge = {
  readonly __typename?: 'SeerFacilityTrackingBoardEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerFacilityTrackingBoard;
};

export type SeerFacilityTrackingBoardFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oas?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destination?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nxtCallBkDtTm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly connectivity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly infoFrom?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trackingStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastTrackingNotes?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly latestTrackingRequest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly facilityId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly autoEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly manualEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly emptyDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly emptyDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly originFacilityId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly destinationFacilityId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly bookedById?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly bookedByName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerFacilityTrackingBoardOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly oas?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly destination?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly nxtCallBkDtTm?: InputMaybe<SeerOrderBy>;
  readonly driverName?: InputMaybe<SeerOrderBy>;
  readonly driverNumber?: InputMaybe<SeerOrderBy>;
  readonly connectivity?: InputMaybe<SeerOrderBy>;
  readonly infoFrom?: InputMaybe<SeerOrderBy>;
  readonly trackingStatus?: InputMaybe<SeerOrderBy>;
  readonly lastTrackingNotes?: InputMaybe<SeerOrderBy>;
  readonly createDate?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<SeerOrderBy>;
  readonly latestTrackingRequest?: InputMaybe<SeerOrderBy>;
  readonly facilityId?: InputMaybe<SeerOrderBy>;
  readonly autoEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly manualEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly emptyDatetime?: InputMaybe<SeerOrderBy>;
  readonly emptyDate?: InputMaybe<SeerOrderBy>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly routeType?: InputMaybe<SeerOrderBy>;
  readonly originFacilityId?: InputMaybe<SeerOrderBy>;
  readonly destinationFacilityId?: InputMaybe<SeerOrderBy>;
  readonly bookedById?: InputMaybe<SeerOrderBy>;
  readonly bookedByName?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber?: InputMaybe<SeerOrderBy>;
  readonly nextStopType?: InputMaybe<SeerOrderBy>;
};

export type SeerFacilityTrackingBoardResult = {
  readonly __typename?: 'SeerFacilityTrackingBoardResult';
  readonly edges: ReadonlyArray<SeerFacilityTrackingBoardEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerGenericBoolFilter = {
  readonly operator: SeerGenericStringOperator;
  /** required for all filter operators but NULL and NOT_NULL */
  readonly value?: InputMaybe<Scalars['Boolean']>;
  readonly orGroup?: InputMaybe<Scalars['String']>;
};

export type SeerGenericDateFilter = {
  readonly operator: SeerGenericFilterOperator;
  /** required for all filter operators but NULL and NOT_NULL */
  readonly value1?: InputMaybe<Scalars['DateTime']>;
  /** required only for filter operator BETWEEN as end value */
  readonly value2?: InputMaybe<Scalars['DateTime']>;
  readonly orGroup?: InputMaybe<Scalars['String']>;
};

/** Allows filtering by distance to geopoints. Sqlserver doesn't support using these filters in or clauses, so no orgroup can be defined. */
export type SeerGenericDistanceFilter = {
  readonly operator: SeerGenericFilterOperator;
  /** Latitude of reference point, required for all filter operators but NULL and NOT_NULL */
  readonly latitude?: InputMaybe<Scalars['Float']>;
  /** Longitude of reference point, required for all filter operators but NULL and NOT_NULL */
  readonly longitude?: InputMaybe<Scalars['Float']>;
  /** Unit for value1 and value2, miles is default. */
  readonly unit?: InputMaybe<SeerDistanceUnit>;
  /** required for all filter operators but NULL and NOT_NULL */
  readonly value1?: InputMaybe<Scalars['Float']>;
  /** required only for filter operator BETWEEN as end value */
  readonly value2?: InputMaybe<Scalars['Float']>;
};

/** generic filter operator for seer */
export enum SeerGenericFilterOperator {
  /** The target fields value must be greater than value1  */
  Greater = 'GREATER',
  /** The target fields value must be less than value1  */
  Less = 'LESS',
  /** The target fields value must be greater than or equal to value1  */
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  /** The target fields value must be less than or equal to value1  */
  LessOrEqual = 'LESS_OR_EQUAL',
  /** The target fields value must be equal to value1  */
  Equal = 'EQUAL',
  /** The target fields value must not be equal to value1  */
  NotEqual = 'NOT_EQUAL',
  /** The target fields value must be between value1 (inclusive) and value2 (exclusive) */
  Between = 'BETWEEN',
  /** The target field is null, no values need to be given */
  Null = 'NULL',
  /** The target field is not null, no values need to be given */
  NotNull = 'NOT_NULL'
}

export type SeerGenericFloatFilter = {
  readonly operator: SeerGenericFilterOperator;
  /** required for all filter operators but NULL and NOT_NULL */
  readonly value1?: InputMaybe<Scalars['Float']>;
  /** required only for filter operator BETWEEN as end value */
  readonly value2?: InputMaybe<Scalars['Float']>;
  readonly orGroup?: InputMaybe<Scalars['String']>;
};

export type SeerGenericIntFilter = {
  readonly operator: SeerGenericFilterOperator;
  /** required for all filter operators but NULL and NOT_NULL */
  readonly value1?: InputMaybe<Scalars['Int']>;
  /** required only for filter operator BETWEEN as end value */
  readonly value2?: InputMaybe<Scalars['Int']>;
  readonly orGroup?: InputMaybe<Scalars['String']>;
};

/** Sort order for seer */
export enum SeerGenericOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SeerGenericStringFilter = {
  readonly operator: SeerGenericStringOperator;
  /** required for all filter operators but NULL and NOT_NULL, supports sql wildcards % and _ */
  readonly value?: InputMaybe<Scalars['String']>;
  readonly orGroup?: InputMaybe<Scalars['String']>;
};

/** Filter operator for strings */
export enum SeerGenericStringOperator {
  /** The target fields value must be equal to value */
  Equal = 'EQUAL',
  /** The target fields value must be like the given value, wildcards are _ and % */
  Like = 'LIKE',
  /** The target fields value must not be equal to value */
  NotEqual = 'NOT_EQUAL',
  /** The target fields value must not be like the given value, wildcards are _ and % */
  NotLike = 'NOT_LIKE',
  /** The target field is null, no values need to be given */
  Null = 'NULL',
  /** The target field is not null, no values need to be given */
  NotNull = 'NOT_NULL'
}

export type SeerGeoPoint = {
  readonly __typename?: 'SeerGeoPoint';
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type SeerGlobalChargeTypeConfig = {
  readonly __typename?: 'SeerGlobalChargeTypeConfig';
  /** Column is of type UUID */
  readonly chargeTypeId?: Maybe<Scalars['String']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly isCredit?: Maybe<Scalars['Boolean']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly carrierGlCode?: Maybe<Scalars['String']>;
  readonly customerGlCode?: Maybe<Scalars['String']>;
  readonly eligibleForFuel?: Maybe<Scalars['Boolean']>;
  readonly excludeFromMargins?: Maybe<Scalars['Boolean']>;
  readonly isPreTax?: Maybe<Scalars['Boolean']>;
  readonly excludeFromMaxCost?: Maybe<Scalars['Boolean']>;
  readonly otherPayApprovalRequired?: Maybe<Scalars['Boolean']>;
};

export type SeerGlobalChargeTypeConfigEdge = {
  readonly __typename?: 'SeerGlobalChargeTypeConfigEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerGlobalChargeTypeConfig;
};

export type SeerGlobalChargeTypeConfigFilter = {
  /** Column is of type UUID */
  readonly chargeTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly category?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedOn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly description?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly code?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isCredit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly isActive?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly carrierGlCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerGlCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eligibleForFuel?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly excludeFromMargins?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly isPreTax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly excludeFromMaxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly otherPayApprovalRequired?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerGlobalChargeTypeConfigOrderBy = {
  readonly chargeTypeId?: InputMaybe<SeerOrderBy>;
  readonly category?: InputMaybe<SeerOrderBy>;
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly createdOn?: InputMaybe<SeerOrderBy>;
  readonly updatedBy?: InputMaybe<SeerOrderBy>;
  readonly updatedOn?: InputMaybe<SeerOrderBy>;
  readonly description?: InputMaybe<SeerOrderBy>;
  readonly code?: InputMaybe<SeerOrderBy>;
  readonly isCredit?: InputMaybe<SeerOrderBy>;
  readonly isActive?: InputMaybe<SeerOrderBy>;
  readonly carrierGlCode?: InputMaybe<SeerOrderBy>;
  readonly customerGlCode?: InputMaybe<SeerOrderBy>;
  readonly eligibleForFuel?: InputMaybe<SeerOrderBy>;
  readonly excludeFromMargins?: InputMaybe<SeerOrderBy>;
  readonly isPreTax?: InputMaybe<SeerOrderBy>;
  readonly excludeFromMaxCost?: InputMaybe<SeerOrderBy>;
  readonly otherPayApprovalRequired?: InputMaybe<SeerOrderBy>;
};

export type SeerGlobalChargeTypeConfigResult = {
  readonly __typename?: 'SeerGlobalChargeTypeConfigResult';
  readonly edges: ReadonlyArray<SeerGlobalChargeTypeConfigEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerIncidents = {
  readonly __typename?: 'SeerIncidents';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly facilityId?: Maybe<Scalars['String']>;
  readonly closed?: Maybe<Scalars['Boolean']>;
  readonly accounting?: Maybe<Scalars['Boolean']>;
};

export type SeerIncidentsEdge = {
  readonly __typename?: 'SeerIncidentsEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerIncidents;
};

export type SeerIncidentsFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly facilityId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly closed?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly accounting?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerIncidentsOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly facilityId?: InputMaybe<SeerOrderBy>;
  readonly closed?: InputMaybe<SeerOrderBy>;
  readonly accounting?: InputMaybe<SeerOrderBy>;
};

export type SeerIncidentsResult = {
  readonly __typename?: 'SeerIncidentsResult';
  readonly edges: ReadonlyArray<SeerIncidentsEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerIncidentsRoutes = {
  readonly __typename?: 'SeerIncidentsRoutes';
  /** Column is of type UUID */
  readonly incidentsId: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
};

export type SeerIncidentsRoutesEdge = {
  readonly __typename?: 'SeerIncidentsRoutesEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerIncidentsRoutes;
};

export type SeerIncidentsRoutesFilter = {
  /** Column is of type UUID */
  readonly incidentsId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerIncidentsRoutesOrderBy = {
  readonly incidentsId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
};

export type SeerIncidentsRoutesResult = {
  readonly __typename?: 'SeerIncidentsRoutesResult';
  readonly edges: ReadonlyArray<SeerIncidentsRoutesEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerInvoice = {
  readonly __typename?: 'SeerInvoice';
  /** Column is of type UUID */
  readonly invoiceId: Scalars['String'];
  readonly invoiceNumber: Scalars['String'];
  /** Column is of type UUID */
  readonly billToId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  readonly invoiceProcessedDate?: Maybe<Scalars['DateTime']>;
  readonly statusId?: Maybe<Scalars['String']>;
  readonly invoiceDistributionMethodId?: Maybe<Scalars['String']>;
  readonly statementNumber?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly invoiceHeaderEid?: Maybe<Scalars['Int']>;
};

export type SeerInvoiceEdge = {
  readonly __typename?: 'SeerInvoiceEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerInvoice;
};

export type SeerInvoiceFilter = {
  /** Column is of type UUID */
  readonly invoiceId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly invoiceNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly billToId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly invoiceProcessedDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly statusId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly invoiceDistributionMethodId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly statementNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly invoiceHeaderEid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
};

export type SeerInvoiceOrderBy = {
  readonly invoiceId?: InputMaybe<SeerOrderBy>;
  readonly invoiceNumber?: InputMaybe<SeerOrderBy>;
  readonly billToId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly invoiceProcessedDate?: InputMaybe<SeerOrderBy>;
  readonly statusId?: InputMaybe<SeerOrderBy>;
  readonly invoiceDistributionMethodId?: InputMaybe<SeerOrderBy>;
  readonly statementNumber?: InputMaybe<SeerOrderBy>;
  readonly size?: InputMaybe<SeerOrderBy>;
  readonly division?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly invoiceHeaderEid?: InputMaybe<SeerOrderBy>;
};

export type SeerInvoiceResult = {
  readonly __typename?: 'SeerInvoiceResult';
  readonly edges: ReadonlyArray<SeerInvoiceEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLinkedRoutes = {
  readonly __typename?: 'SeerLinkedRoutes';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly eid: Scalars['Int'];
  /** Column is of type UUID */
  readonly routeid: Scalars['String'];
  /** Column is of type UUID */
  readonly previousrouteid?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly nextrouteid?: Maybe<Scalars['String']>;
};

export type SeerLinkedRoutesEdge = {
  readonly __typename?: 'SeerLinkedRoutesEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLinkedRoutes;
};

export type SeerLinkedRoutesFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly routeid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly previousrouteid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly nextrouteid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerLinkedRoutesOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
  readonly routeid?: InputMaybe<SeerOrderBy>;
  readonly previousrouteid?: InputMaybe<SeerOrderBy>;
  readonly nextrouteid?: InputMaybe<SeerOrderBy>;
};

export type SeerLinkedRoutesResult = {
  readonly __typename?: 'SeerLinkedRoutesResult';
  readonly edges: ReadonlyArray<SeerLinkedRoutesEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLoad = {
  readonly __typename?: 'SeerLoad';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId?: Maybe<Scalars['String']>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly orderTenderStatus?: Maybe<Scalars['String']>;
  readonly orderMode?: Maybe<Scalars['String']>;
  readonly orderHeight?: Maybe<Scalars['Float']>;
  readonly orderWidth?: Maybe<Scalars['Float']>;
  readonly orderLength?: Maybe<Scalars['Float']>;
  readonly orderHeightUnit?: Maybe<Scalars['String']>;
  readonly orderWidthUnit?: Maybe<Scalars['String']>;
  readonly orderLengthUnit?: Maybe<Scalars['String']>;
  readonly orderDivision?: Maybe<Scalars['String']>;
  readonly orderCustomerId?: Maybe<Scalars['String']>;
  readonly orderCustomerName?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeTenderStatus?: Maybe<Scalars['String']>;
  readonly routeLifecyleStatus?: Maybe<Scalars['String']>;
  readonly totalDistance?: Maybe<Scalars['Float']>;
  readonly totalDistanceUnit?: Maybe<Scalars['String']>;
  readonly stopCount?: Maybe<Scalars['Int']>;
  readonly orderSize?: Maybe<Scalars['String']>;
  readonly customerRepIds?: Maybe<Scalars['String']>;
  readonly customerReps?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly orderLifecycleStatus?: Maybe<Scalars['String']>;
  readonly paymentTerms?: Maybe<Scalars['String']>;
  readonly scac?: Maybe<Scalars['String']>;
  readonly quoteType?: Maybe<Scalars['String']>;
  readonly relation?: Maybe<Scalars['String']>;
  readonly priceTier?: Maybe<Scalars['String']>;
  readonly totalWeight?: Maybe<Scalars['Float']>;
  readonly totalWeightUnit?: Maybe<Scalars['String']>;
  readonly cargoInsurance?: Maybe<Scalars['Float']>;
  readonly cargoInsuranceUnit?: Maybe<Scalars['String']>;
  readonly requirements?: Maybe<Scalars['String']>;
  readonly tenderedAt?: Maybe<Scalars['DateTime']>;
  readonly expectedAt?: Maybe<Scalars['DateTime']>;
  readonly timezone?: Maybe<Scalars['String']>;
  readonly lastUpdatedDateTime?: Maybe<Scalars['DateTime']>;
  readonly orderBusinessUnit?: Maybe<Scalars['String']>;
  readonly orderSource?: Maybe<Scalars['String']>;
  readonly tenderFromId?: Maybe<Scalars['String']>;
  readonly billToCodeId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly totalOrderDistance?: Maybe<Scalars['Float']>;
  readonly totalOrderDistanceUnit?: Maybe<Scalars['String']>;
  readonly orderType?: Maybe<Scalars['String']>;
  readonly activationStatusReasons?: Maybe<Scalars['String']>;
  readonly serviceLevel?: Maybe<Scalars['String']>;
  readonly showOnMprbActiveScreens: Scalars['Boolean'];
};

export type SeerLoadEdge = {
  readonly __typename?: 'SeerLoadEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLoad;
};

export type SeerLoadFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderTenderStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderHeight?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly orderWidth?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly orderLength?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly orderHeightUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderWidthUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderLengthUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderDivision?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderCustomerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderCustomerName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeTenderStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeLifecyleStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly totalDistance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalDistanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stopCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly orderSize?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerRepIds?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerReps?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly orderLifecycleStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly paymentTerms?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly scac?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly quoteType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly relation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly priceTier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly totalWeight?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalWeightUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly cargoInsurance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly cargoInsuranceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly requirements?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tenderedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly expectedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly timezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastUpdatedDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly orderBusinessUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderSource?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tenderFromId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly billToCodeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly organizationId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly totalOrderDistance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalOrderDistanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly activationStatusReasons?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly serviceLevel?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly showOnMprbActiveScreens?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerLoadOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly orderCode?: InputMaybe<SeerOrderBy>;
  readonly orderActivationStatus?: InputMaybe<SeerOrderBy>;
  readonly orderTenderStatus?: InputMaybe<SeerOrderBy>;
  readonly orderMode?: InputMaybe<SeerOrderBy>;
  readonly orderHeight?: InputMaybe<SeerOrderBy>;
  readonly orderWidth?: InputMaybe<SeerOrderBy>;
  readonly orderLength?: InputMaybe<SeerOrderBy>;
  readonly orderHeightUnit?: InputMaybe<SeerOrderBy>;
  readonly orderWidthUnit?: InputMaybe<SeerOrderBy>;
  readonly orderLengthUnit?: InputMaybe<SeerOrderBy>;
  readonly orderDivision?: InputMaybe<SeerOrderBy>;
  readonly orderCustomerId?: InputMaybe<SeerOrderBy>;
  readonly orderCustomerName?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly routeActivationStatus?: InputMaybe<SeerOrderBy>;
  readonly routeTenderStatus?: InputMaybe<SeerOrderBy>;
  readonly routeLifecyleStatus?: InputMaybe<SeerOrderBy>;
  readonly totalDistance?: InputMaybe<SeerOrderBy>;
  readonly totalDistanceUnit?: InputMaybe<SeerOrderBy>;
  readonly stopCount?: InputMaybe<SeerOrderBy>;
  readonly orderSize?: InputMaybe<SeerOrderBy>;
  readonly customerRepIds?: InputMaybe<SeerOrderBy>;
  readonly customerReps?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly orderLifecycleStatus?: InputMaybe<SeerOrderBy>;
  readonly paymentTerms?: InputMaybe<SeerOrderBy>;
  readonly scac?: InputMaybe<SeerOrderBy>;
  readonly quoteType?: InputMaybe<SeerOrderBy>;
  readonly relation?: InputMaybe<SeerOrderBy>;
  readonly priceTier?: InputMaybe<SeerOrderBy>;
  readonly totalWeight?: InputMaybe<SeerOrderBy>;
  readonly totalWeightUnit?: InputMaybe<SeerOrderBy>;
  readonly cargoInsurance?: InputMaybe<SeerOrderBy>;
  readonly cargoInsuranceUnit?: InputMaybe<SeerOrderBy>;
  readonly requirements?: InputMaybe<SeerOrderBy>;
  readonly tenderedAt?: InputMaybe<SeerOrderBy>;
  readonly expectedAt?: InputMaybe<SeerOrderBy>;
  readonly timezone?: InputMaybe<SeerOrderBy>;
  readonly lastUpdatedDateTime?: InputMaybe<SeerOrderBy>;
  readonly orderBusinessUnit?: InputMaybe<SeerOrderBy>;
  readonly orderSource?: InputMaybe<SeerOrderBy>;
  readonly tenderFromId?: InputMaybe<SeerOrderBy>;
  readonly billToCodeId?: InputMaybe<SeerOrderBy>;
  readonly organizationId?: InputMaybe<SeerOrderBy>;
  readonly totalOrderDistance?: InputMaybe<SeerOrderBy>;
  readonly totalOrderDistanceUnit?: InputMaybe<SeerOrderBy>;
  readonly orderType?: InputMaybe<SeerOrderBy>;
  readonly activationStatusReasons?: InputMaybe<SeerOrderBy>;
  readonly serviceLevel?: InputMaybe<SeerOrderBy>;
  readonly showOnMprbActiveScreens?: InputMaybe<SeerOrderBy>;
};

export type SeerLoadResult = {
  readonly __typename?: 'SeerLoadResult';
  readonly edges: ReadonlyArray<SeerLoadEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLoadSearch = {
  readonly __typename?: 'SeerLoadSearch';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly oas?: Maybe<Scalars['String']>;
  readonly ots?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly orderDivision?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerCode?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly puApptStatus?: Maybe<Scalars['String']>;
  readonly shipper?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly shipperId?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly distance?: Maybe<Scalars['Float']>;
  readonly distanceUnit?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly consignee?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly consigneeId?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly delApptStatus?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly carrierRepNames?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly carrierRepId?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly overMax?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly refNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly originRadius?: Maybe<SeerGeoPoint>;
  readonly mainCustomerRep?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly destinationRadius?: Maybe<SeerGeoPoint>;
  readonly carrierStatus: Scalars['String'];
  readonly booked: Scalars['Int'];
  readonly vendorType?: Maybe<Scalars['String']>;
  readonly repType?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly originFirstLoadStop?: Maybe<Scalars['Boolean']>;
  readonly originLastLoadStop?: Maybe<Scalars['Boolean']>;
  readonly originloadStopSequence?: Maybe<Scalars['Int']>;
  readonly destinationFirstLoadStop?: Maybe<Scalars['Boolean']>;
  readonly destinationLastLoadStop?: Maybe<Scalars['Boolean']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Int']>;
  readonly olcs?: Maybe<Scalars['String']>;
};

export type SeerLoadSearchDataAggregation = {
  readonly __typename?: 'SeerLoadSearchDataAggregation';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly overMax?: Maybe<Scalars['Float']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
};

export type SeerLoadSearchDataAggregationEdge = {
  readonly __typename?: 'SeerLoadSearchDataAggregationEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLoadSearchDataAggregation;
};

export type SeerLoadSearchDataAggregationFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly maxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly overMax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerLoadSearchDataAggregationOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly maxCost?: InputMaybe<SeerOrderBy>;
  readonly overMax?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
};

export type SeerLoadSearchDataAggregationResult = {
  readonly __typename?: 'SeerLoadSearchDataAggregationResult';
  readonly edges: ReadonlyArray<SeerLoadSearchDataAggregationEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLoadSearchEdge = {
  readonly __typename?: 'SeerLoadSearchEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLoadSearch;
};

export type SeerLoadSearchFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oas?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ots?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderDivision?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipper?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly shipperId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly distance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly distanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly consignee?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly consigneeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierRepNames?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly maxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly overMax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly originState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly refNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originRadius?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly mainCustomerRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationRadius?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly carrierStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly booked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly vendorType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly repType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly originFirstLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly originLastLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly originloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destinationFirstLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly destinationLastLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly destinationloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly olcs?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerLoadSearchOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly oas?: InputMaybe<SeerOrderBy>;
  readonly ots?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly size?: InputMaybe<SeerOrderBy>;
  readonly orderDivision?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly customerName?: InputMaybe<SeerOrderBy>;
  readonly customerCode?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puApptStatus?: InputMaybe<SeerOrderBy>;
  readonly shipper?: InputMaybe<SeerOrderBy>;
  readonly shipperId?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly distance?: InputMaybe<SeerOrderBy>;
  readonly distanceUnit?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly consignee?: InputMaybe<SeerOrderBy>;
  readonly consigneeId?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delApptStatus?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly carrierRepNames?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly carrierRepId?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber?: InputMaybe<SeerOrderBy>;
  readonly maxCost?: InputMaybe<SeerOrderBy>;
  readonly overMax?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly originState?: InputMaybe<SeerOrderBy>;
  readonly destinationState?: InputMaybe<SeerOrderBy>;
  readonly refNumber?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly mainCustomerRep?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly carrierStatus?: InputMaybe<SeerOrderBy>;
  readonly booked?: InputMaybe<SeerOrderBy>;
  readonly vendorType?: InputMaybe<SeerOrderBy>;
  readonly repType?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly originFirstLoadStop?: InputMaybe<SeerOrderBy>;
  readonly originLastLoadStop?: InputMaybe<SeerOrderBy>;
  readonly originloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly destinationFirstLoadStop?: InputMaybe<SeerOrderBy>;
  readonly destinationLastLoadStop?: InputMaybe<SeerOrderBy>;
  readonly destinationloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly olcs?: InputMaybe<SeerOrderBy>;
};

export type SeerLoadSearchResult = {
  readonly __typename?: 'SeerLoadSearchResult';
  readonly edges: ReadonlyArray<SeerLoadSearchEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLoadSearchSideBar = {
  readonly __typename?: 'SeerLoadSearchSideBar';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
};

export type SeerLoadSearchSideBarEdge = {
  readonly __typename?: 'SeerLoadSearchSideBarEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLoadSearchSideBar;
};

export type SeerLoadSearchSideBarFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerLoadSearchSideBarOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly originState?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly destinationState?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
};

export type SeerLoadSearchSideBarResult = {
  readonly __typename?: 'SeerLoadSearchSideBarResult';
  readonly edges: ReadonlyArray<SeerLoadSearchSideBarEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLoadSearchV2 = {
  readonly __typename?: 'SeerLoadSearchV2';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly oas?: Maybe<Scalars['String']>;
  readonly ots?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly orderDivision?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly customerCode?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly puApptStatus?: Maybe<Scalars['String']>;
  readonly shipper?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly shipperId?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly distance?: Maybe<Scalars['Float']>;
  readonly distanceUnit?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly consignee?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly consigneeId?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly delApptStatus?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly carrierRepNames?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly carrierRepId?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly overMax?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly refNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly originRadius?: Maybe<SeerGeoPoint>;
  readonly mainCustomerRep?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly destinationRadius?: Maybe<SeerGeoPoint>;
  readonly carrierStatus: Scalars['String'];
  readonly booked: Scalars['Int'];
  readonly vendorType?: Maybe<Scalars['String']>;
  readonly repType?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly originFirstLoadStop?: Maybe<Scalars['Boolean']>;
  readonly originLastLoadStop?: Maybe<Scalars['Boolean']>;
  readonly originloadStopSequence?: Maybe<Scalars['Int']>;
  readonly destinationFirstLoadStop?: Maybe<Scalars['Boolean']>;
  readonly destinationLastLoadStop?: Maybe<Scalars['Boolean']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Int']>;
  readonly olcs?: Maybe<Scalars['String']>;
  readonly orderType?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly organizationIdInLoadsearch?: Maybe<Scalars['String']>;
  readonly organizationIdInMasterfind?: Maybe<Scalars['Boolean']>;
};

export type SeerLoadSearchV2Edge = {
  readonly __typename?: 'SeerLoadSearchV2Edge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLoadSearchV2;
};

export type SeerLoadSearchV2Filter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oas?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ots?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderDivision?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipper?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly shipperId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly distance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly distanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly consignee?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly consigneeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierRepNames?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly maxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly overMax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly originState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly refNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originRadius?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly mainCustomerRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationRadius?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly carrierStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly booked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly vendorType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly repType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly originFirstLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly originLastLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly originloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destinationFirstLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly destinationLastLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly destinationloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly olcs?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly organizationIdInLoadsearch?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly organizationIdInMasterfind?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerLoadSearchV2OrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly oas?: InputMaybe<SeerOrderBy>;
  readonly ots?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly size?: InputMaybe<SeerOrderBy>;
  readonly orderDivision?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly customerCode?: InputMaybe<SeerOrderBy>;
  readonly customerName?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puApptStatus?: InputMaybe<SeerOrderBy>;
  readonly shipper?: InputMaybe<SeerOrderBy>;
  readonly shipperId?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly distance?: InputMaybe<SeerOrderBy>;
  readonly distanceUnit?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly consignee?: InputMaybe<SeerOrderBy>;
  readonly consigneeId?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delApptStatus?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly carrierRepNames?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly carrierRepId?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber?: InputMaybe<SeerOrderBy>;
  readonly maxCost?: InputMaybe<SeerOrderBy>;
  readonly overMax?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly originState?: InputMaybe<SeerOrderBy>;
  readonly destinationState?: InputMaybe<SeerOrderBy>;
  readonly refNumber?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly mainCustomerRep?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly carrierStatus?: InputMaybe<SeerOrderBy>;
  readonly booked?: InputMaybe<SeerOrderBy>;
  readonly vendorType?: InputMaybe<SeerOrderBy>;
  readonly repType?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly originFirstLoadStop?: InputMaybe<SeerOrderBy>;
  readonly originLastLoadStop?: InputMaybe<SeerOrderBy>;
  readonly originloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly destinationFirstLoadStop?: InputMaybe<SeerOrderBy>;
  readonly destinationLastLoadStop?: InputMaybe<SeerOrderBy>;
  readonly destinationloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly olcs?: InputMaybe<SeerOrderBy>;
  readonly orderType?: InputMaybe<SeerOrderBy>;
  readonly routeType?: InputMaybe<SeerOrderBy>;
  readonly organizationIdInLoadsearch?: InputMaybe<SeerOrderBy>;
  readonly organizationIdInMasterfind?: InputMaybe<SeerOrderBy>;
};

export type SeerLoadSearchV2Result = {
  readonly __typename?: 'SeerLoadSearchV2Result';
  readonly edges: ReadonlyArray<SeerLoadSearchV2Edge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLoadSummaryDataAggregation = {
  readonly __typename?: 'SeerLoadSummaryDataAggregation';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  readonly totalMaxCost?: Maybe<Scalars['Float']>;
  readonly totalOverMax?: Maybe<Scalars['Float']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
};

export type SeerLoadSummaryDataAggregationEdge = {
  readonly __typename?: 'SeerLoadSummaryDataAggregationEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLoadSummaryDataAggregation;
};

export type SeerLoadSummaryDataAggregationFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly totalMaxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalOverMax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
};

export type SeerLoadSummaryDataAggregationOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly totalMaxCost?: InputMaybe<SeerOrderBy>;
  readonly totalOverMax?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
};

export type SeerLoadSummaryDataAggregationResult = {
  readonly __typename?: 'SeerLoadSummaryDataAggregationResult';
  readonly edges: ReadonlyArray<SeerLoadSummaryDataAggregationEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLocks = {
  readonly __typename?: 'SeerLocks';
  /** Column is of type UUID */
  readonly objectId: Scalars['String'];
  readonly isLocked: Scalars['Boolean'];
  readonly eventType: Scalars['String'];
  readonly flavor: Scalars['String'];
  readonly userIdent: Scalars['String'];
  readonly adminIdent?: Maybe<Scalars['String']>;
  readonly expirationTimestamp: Scalars['Int'];
  readonly metadata?: Maybe<Scalars['String']>;
  readonly expirationDateTimestamp?: Maybe<Scalars['DateTime']>;
};

export type SeerLocksEdge = {
  readonly __typename?: 'SeerLocksEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLocks;
};

export type SeerLocksFilter = {
  /** Column is of type UUID */
  readonly objectId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isLocked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly eventType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly flavor?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly userIdent?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly adminIdent?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expirationTimestamp?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly metadata?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expirationDateTimestamp?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerLocksOrderBy = {
  readonly objectId?: InputMaybe<SeerOrderBy>;
  readonly isLocked?: InputMaybe<SeerOrderBy>;
  readonly eventType?: InputMaybe<SeerOrderBy>;
  readonly flavor?: InputMaybe<SeerOrderBy>;
  readonly userIdent?: InputMaybe<SeerOrderBy>;
  readonly adminIdent?: InputMaybe<SeerOrderBy>;
  readonly expirationTimestamp?: InputMaybe<SeerOrderBy>;
  readonly metadata?: InputMaybe<SeerOrderBy>;
  readonly expirationDateTimestamp?: InputMaybe<SeerOrderBy>;
};

export type SeerLocksResult = {
  readonly __typename?: 'SeerLocksResult';
  readonly edges: ReadonlyArray<SeerLocksEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLseToEqStaging = {
  readonly __typename?: 'SeerLseToEqStaging';
  readonly id: Scalars['Int'];
  readonly exceptionType?: Maybe<Scalars['String']>;
  readonly exceptionStatus?: Maybe<Scalars['String']>;
  readonly sourceType?: Maybe<Scalars['String']>;
  readonly secondarySourceType?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly sourceId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly secondarySourceId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly loadId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly vendorId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
};

export type SeerLseToEqStagingEdge = {
  readonly __typename?: 'SeerLseToEqStagingEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLseToEqStaging;
};

export type SeerLseToEqStagingFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly exceptionType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly exceptionStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly sourceType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly secondarySourceType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly sourceId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly secondarySourceId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerLseToEqStagingOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly exceptionType?: InputMaybe<SeerOrderBy>;
  readonly exceptionStatus?: InputMaybe<SeerOrderBy>;
  readonly sourceType?: InputMaybe<SeerOrderBy>;
  readonly secondarySourceType?: InputMaybe<SeerOrderBy>;
  readonly sourceId?: InputMaybe<SeerOrderBy>;
  readonly secondarySourceId?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
};

export type SeerLseToEqStagingResult = {
  readonly __typename?: 'SeerLseToEqStagingResult';
  readonly edges: ReadonlyArray<SeerLseToEqStagingEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageRouteBoard = {
  readonly __typename?: 'SeerMainPageRouteBoard';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly previousRouteId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly nextRouteId?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly originCountry?: Maybe<Scalars['String']>;
  readonly originStreet1?: Maybe<Scalars['String']>;
  readonly originStreet2?: Maybe<Scalars['String']>;
  readonly originPostalCode?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly dest?: Maybe<Scalars['String']>;
  readonly destCountry?: Maybe<Scalars['String']>;
  readonly destStreet1?: Maybe<Scalars['String']>;
  readonly destStreet2?: Maybe<Scalars['String']>;
  readonly destPostalCode?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly mTtime?: Maybe<Scalars['String']>;
  readonly mTzone?: Maybe<Scalars['String']>;
  readonly emptyLocation?: Maybe<Scalars['String']>;
  readonly emptyTimezone?: Maybe<Scalars['String']>;
  readonly emptyDate?: Maybe<Scalars['DateTime']>;
  readonly appt?: Maybe<Scalars['DateTime']>;
  readonly eta?: Maybe<Scalars['DateTime']>;
  readonly stop?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly nxtCallBkDtTm?: Maybe<Scalars['DateTime']>;
  readonly apptId?: Maybe<Scalars['String']>;
  readonly rep?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly mainRepIds?: Maybe<Scalars['String']>;
  readonly expectedEmptyLatitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyLongitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly expectedEmptyCountry?: Maybe<Scalars['String']>;
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly customerReps?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly originStopType?: Maybe<Scalars['String']>;
  readonly destinationStopType?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly originStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly destinationStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly vendorId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly ibRegionUrn?: Maybe<Scalars['String']>;
  readonly obRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsubReionUrn?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsuperReionUrn?: Maybe<Scalars['String']>;
  readonly customerGroup?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly driver1Name?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly driver1PhoneNumber?: Maybe<Scalars['String']>;
  readonly driver2PhoneNumber?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly lastTrackingNotes?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly lockId?: Maybe<Scalars['String']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierArivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierArivalTimezone?: Maybe<Scalars['String']>;
  readonly firstStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly lastStopCarrierArivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierArivalTimezone?: Maybe<Scalars['String']>;
  readonly lastStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly nextLocationCity?: Maybe<Scalars['String']>;
  readonly nextLocationState?: Maybe<Scalars['String']>;
  readonly height?: Maybe<Scalars['Float']>;
  readonly width?: Maybe<Scalars['Float']>;
  readonly length?: Maybe<Scalars['Float']>;
  readonly currentStopId?: Maybe<Scalars['String']>;
  readonly currentStopNumber?: Maybe<Scalars['Int']>;
  readonly currentStopCarrierArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopCarrierArrivalTimezone?: Maybe<Scalars['String']>;
  readonly currentStopCarrierDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly nextApptStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptStartTime?: Maybe<Scalars['Int']>;
  readonly nextApptEndTime?: Maybe<Scalars['Int']>;
  readonly nextApptStartTimeZone?: Maybe<Scalars['String']>;
  readonly nextApptEndTimeZone?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly originloadStopSequence?: Maybe<Scalars['Int']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Int']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly waybillDateTime?: Maybe<Scalars['DateTime']>;
};

export type SeerMainPageRouteBoardCountUnified = {
  readonly __typename?: 'SeerMainPageRouteBoardCountUnified';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly rlc?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeRepId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly ibRegionUrn?: Maybe<Scalars['String']>;
  readonly obRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentRegionConcatenated?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegionConcatenated?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierArrivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierArrivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly pendingCount: Scalars['Int'];
  readonly incompleteCount: Scalars['Int'];
  readonly openCount: Scalars['Int'];
  readonly coveredCount: Scalars['Int'];
  readonly dispatchedCount: Scalars['Int'];
  readonly completeCount: Scalars['Int'];
  readonly preTenderCount: Scalars['Int'];
  readonly tenderedCount: Scalars['Int'];
  readonly isAtOriginCount: Scalars['Int'];
  readonly isAtDestinationCount: Scalars['Int'];
  readonly isInTransitCount: Scalars['Int'];
};

export type SeerMainPageRouteBoardCountUnifiedEdge = {
  readonly __typename?: 'SeerMainPageRouteBoardCountUnifiedEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageRouteBoardCountUnified;
};

export type SeerMainPageRouteBoardCountUnifiedFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentRegionConcatenated?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentRegionConcatenated?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierArrivalDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierDepartureDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastStopCarrierArrivalDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastStopCarrierDepartureDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly pendingCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly incompleteCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly openCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly coveredCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly dispatchedCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly completeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly preTenderCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly tenderedCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly isAtOriginCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly isAtDestinationCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly isInTransitCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
};

export type SeerMainPageRouteBoardCountUnifiedOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly routeType?: InputMaybe<SeerOrderBy>;
  readonly routeTransportMode?: InputMaybe<SeerOrderBy>;
  readonly routeRepId?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly division?: InputMaybe<SeerOrderBy>;
  readonly ibRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly obRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegionConcatenated?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentRegionConcatenated?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierArrivalDateTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierDepartureDateTime?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierArrivalDateTime?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierDepartureDateTime?: InputMaybe<SeerOrderBy>;
  readonly pendingCount?: InputMaybe<SeerOrderBy>;
  readonly incompleteCount?: InputMaybe<SeerOrderBy>;
  readonly openCount?: InputMaybe<SeerOrderBy>;
  readonly coveredCount?: InputMaybe<SeerOrderBy>;
  readonly dispatchedCount?: InputMaybe<SeerOrderBy>;
  readonly completeCount?: InputMaybe<SeerOrderBy>;
  readonly preTenderCount?: InputMaybe<SeerOrderBy>;
  readonly tenderedCount?: InputMaybe<SeerOrderBy>;
  readonly isAtOriginCount?: InputMaybe<SeerOrderBy>;
  readonly isAtDestinationCount?: InputMaybe<SeerOrderBy>;
  readonly isInTransitCount?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageRouteBoardCountUnifiedResult = {
  readonly __typename?: 'SeerMainPageRouteBoardCountUnifiedResult';
  readonly edges: ReadonlyArray<SeerMainPageRouteBoardCountUnifiedEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageRouteBoardDetails = {
  readonly __typename?: 'SeerMainPageRouteBoardDetails';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion: Scalars['String'];
  readonly outboundFreightAssignmentRegionConcatenated?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly originStopType: Scalars['String'];
  readonly stops?: Maybe<Scalars['Int']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly height?: Maybe<Scalars['Float']>;
  readonly width?: Maybe<Scalars['Float']>;
  readonly length?: Maybe<Scalars['Float']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly mt?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly emptyTimezone?: Maybe<Scalars['String']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly dest?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly iBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegionConcatenated?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly firstStopCarrierArrivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierArrivalTimezone?: Maybe<Scalars['String']>;
  readonly firstStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly lastStopCarrierArrivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierArrivalTimezone?: Maybe<Scalars['String']>;
  readonly lastStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly driver1Name?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly nextLocationCity?: Maybe<Scalars['String']>;
  readonly nextLocationState?: Maybe<Scalars['String']>;
  readonly nxtCallBkDtTm?: Maybe<Scalars['DateTime']>;
  readonly eta?: Maybe<Scalars['DateTime']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly waybillDateTime?: Maybe<Scalars['DateTime']>;
  readonly waybillStatus?: Maybe<Scalars['String']>;
  readonly currentStopNumber?: Maybe<Scalars['Int']>;
  readonly currentStopCarrierArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopCarrierArrivalTimezone?: Maybe<Scalars['String']>;
  readonly currentStopCarrierDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly nextApptStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptStartTime?: Maybe<Scalars['Int']>;
  readonly nextApptEndTime?: Maybe<Scalars['Int']>;
  readonly nextApptStartTimeZone?: Maybe<Scalars['String']>;
  readonly nextApptEndTimeZone?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly lockId?: Maybe<Scalars['String']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
};

export type SeerMainPageRouteBoardDetailsEdge = {
  readonly __typename?: 'SeerMainPageRouteBoardDetailsEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageRouteBoardDetails;
};

export type SeerMainPageRouteBoardDetailsFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentRegionConcatenated?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly height?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly width?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly length?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentRegionConcatenated?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly firstStopCarrierArrivalDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierArrivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly firstStopCarrierDepartureDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastStopCarrierArrivalDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastStopCarrierArrivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastStopCarrierDepartureDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver1Name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver2Name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nxtCallBkDtTm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly eta?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly waybillDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly waybillStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentStopNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly currentStopCarrierArrivalDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly currentStopCarrierArrivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentStopCarrierDepartureDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly currentStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly lockId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isLocked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly lockExpirationTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerMainPageRouteBoardDetailsOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly routeTransportMode?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly oBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegionConcatenated?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly originStopType?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly height?: InputMaybe<SeerOrderBy>;
  readonly width?: InputMaybe<SeerOrderBy>;
  readonly length?: InputMaybe<SeerOrderBy>;
  readonly routeType?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly mt?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly routeRepId?: InputMaybe<SeerOrderBy>;
  readonly emptyTimezone?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCity?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyState?: InputMaybe<SeerOrderBy>;
  readonly dest?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly iBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentRegionConcatenated?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierArrivalDateTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierArrivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierDepartureDateTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierArrivalDateTime?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierArrivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierDepartureDateTime?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly driver1Name?: InputMaybe<SeerOrderBy>;
  readonly driver2Name?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCity?: InputMaybe<SeerOrderBy>;
  readonly nextLocationState?: InputMaybe<SeerOrderBy>;
  readonly nxtCallBkDtTm?: InputMaybe<SeerOrderBy>;
  readonly eta?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly waybillDateTime?: InputMaybe<SeerOrderBy>;
  readonly waybillStatus?: InputMaybe<SeerOrderBy>;
  readonly currentStopNumber?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierArrivalDatetime?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierArrivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierDepartureDatetime?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly lockId?: InputMaybe<SeerOrderBy>;
  readonly lockEmail?: InputMaybe<SeerOrderBy>;
  readonly isLocked?: InputMaybe<SeerOrderBy>;
  readonly lockExpirationTime?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageRouteBoardDetailsResult = {
  readonly __typename?: 'SeerMainPageRouteBoardDetailsResult';
  readonly edges: ReadonlyArray<SeerMainPageRouteBoardDetailsEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageRouteBoardEdge = {
  readonly __typename?: 'SeerMainPageRouteBoardEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageRouteBoard;
};

export type SeerMainPageRouteBoardFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly previousRouteId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly nextRouteId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStreet1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStreet2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originPostalCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destStreet1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destStreet2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destPostalCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mTtime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mTzone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyLocation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly appt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly eta?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly stop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nxtCallBkDtTm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly apptId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainRepIds?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerReps?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly originStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly destinationStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly employeeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubReionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsuperReionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerGroup?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver1Name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver2Name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver1PhoneNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver2PhoneNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trackingStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tractorNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastTrackingNotes?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly lockId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isLocked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly lockExpirationTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierArivalDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierArivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly firstStopCarrierDepartureDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastStopCarrierArivalDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastStopCarrierArivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastStopCarrierDepartureDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly height?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly width?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly length?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly currentStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentStopNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly currentStopCarrierArrivalDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly currentStopCarrierArrivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentStopCarrierDepartureDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly currentStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly originloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destinationloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly waybillDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerMainPageRouteBoardOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly previousRouteId?: InputMaybe<SeerOrderBy>;
  readonly nextRouteId?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly originCountry?: InputMaybe<SeerOrderBy>;
  readonly originStreet1?: InputMaybe<SeerOrderBy>;
  readonly originStreet2?: InputMaybe<SeerOrderBy>;
  readonly originPostalCode?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly dest?: InputMaybe<SeerOrderBy>;
  readonly destCountry?: InputMaybe<SeerOrderBy>;
  readonly destStreet1?: InputMaybe<SeerOrderBy>;
  readonly destStreet2?: InputMaybe<SeerOrderBy>;
  readonly destPostalCode?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly mTtime?: InputMaybe<SeerOrderBy>;
  readonly mTzone?: InputMaybe<SeerOrderBy>;
  readonly emptyLocation?: InputMaybe<SeerOrderBy>;
  readonly emptyTimezone?: InputMaybe<SeerOrderBy>;
  readonly emptyDate?: InputMaybe<SeerOrderBy>;
  readonly appt?: InputMaybe<SeerOrderBy>;
  readonly eta?: InputMaybe<SeerOrderBy>;
  readonly stop?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly nxtCallBkDtTm?: InputMaybe<SeerOrderBy>;
  readonly apptId?: InputMaybe<SeerOrderBy>;
  readonly rep?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly mainRepIds?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLatitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLongitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCity?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyState?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCountry?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly customerReps?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly division?: InputMaybe<SeerOrderBy>;
  readonly originStopType?: InputMaybe<SeerOrderBy>;
  readonly destinationStopType?: InputMaybe<SeerOrderBy>;
  readonly originStopId?: InputMaybe<SeerOrderBy>;
  readonly destinationStopId?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly employeeId?: InputMaybe<SeerOrderBy>;
  readonly ibRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly obRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubReionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsuperReionUrn?: InputMaybe<SeerOrderBy>;
  readonly customerGroup?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly customerName?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly carrierName?: InputMaybe<SeerOrderBy>;
  readonly driver1Name?: InputMaybe<SeerOrderBy>;
  readonly driver2Name?: InputMaybe<SeerOrderBy>;
  readonly driver1PhoneNumber?: InputMaybe<SeerOrderBy>;
  readonly driver2PhoneNumber?: InputMaybe<SeerOrderBy>;
  readonly trackingStatus?: InputMaybe<SeerOrderBy>;
  readonly tractorNumber?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber?: InputMaybe<SeerOrderBy>;
  readonly lastTrackingNotes?: InputMaybe<SeerOrderBy>;
  readonly lockId?: InputMaybe<SeerOrderBy>;
  readonly lockEmail?: InputMaybe<SeerOrderBy>;
  readonly isLocked?: InputMaybe<SeerOrderBy>;
  readonly lockExpirationTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierArivalDateTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierArivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierDepartureDateTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierArivalDateTime?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierArivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierDepartureDateTime?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCity?: InputMaybe<SeerOrderBy>;
  readonly nextLocationState?: InputMaybe<SeerOrderBy>;
  readonly height?: InputMaybe<SeerOrderBy>;
  readonly width?: InputMaybe<SeerOrderBy>;
  readonly length?: InputMaybe<SeerOrderBy>;
  readonly currentStopId?: InputMaybe<SeerOrderBy>;
  readonly currentStopNumber?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierArrivalDatetime?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierArrivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierDepartureDatetime?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly originloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly destinationloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly routeTransportMode?: InputMaybe<SeerOrderBy>;
  readonly waybillDateTime?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageRouteBoardPending = {
  readonly __typename?: 'SeerMainPageRouteBoardPending';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly orderNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  readonly oas?: Maybe<Scalars['String']>;
  readonly tenderedAt?: Maybe<Scalars['DateTime']>;
  readonly tenderedAtTimezone?: Maybe<Scalars['String']>;
  readonly expectedAt?: Maybe<Scalars['DateTime']>;
  readonly expectedAtTimezone?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly shipper?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly shipperId?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly requestedShipDate?: Maybe<Scalars['DateTime']>;
  readonly requestedShipDateTimezone?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puStartTimezone?: Maybe<Scalars['String']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puEndTimezone?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly obRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentRegionConcatenated?: Maybe<Scalars['String']>;
  readonly totalDistance?: Maybe<Scalars['Float']>;
  readonly totalDistanceUnit?: Maybe<Scalars['String']>;
  readonly consignee?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly consigneeId?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delStartTimezone?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delEndTimezone?: Maybe<Scalars['String']>;
  readonly ibRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegionConcatenated?: Maybe<Scalars['String']>;
  readonly reqArriveDate?: Maybe<Scalars['DateTime']>;
  readonly reqArriveDateTimezone?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly scac?: Maybe<Scalars['String']>;
  readonly quoteType?: Maybe<Scalars['String']>;
  readonly relation?: Maybe<Scalars['String']>;
  readonly tier?: Maybe<Scalars['String']>;
  readonly orderWeight?: Maybe<Scalars['Float']>;
  readonly orderWeightUnit?: Maybe<Scalars['String']>;
  readonly cargoInsurance?: Maybe<Scalars['Float']>;
  readonly cargoInsuranceUnit?: Maybe<Scalars['String']>;
  readonly requirements?: Maybe<Scalars['String']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly commitFreq?: Maybe<Scalars['String']>;
  readonly commitVol?: Maybe<Scalars['Int']>;
  readonly countAccepted?: Maybe<Scalars['Int']>;
  /** Column is of type UUID */
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly routeRep?: Maybe<Scalars['String']>;
  readonly tenderStatus?: Maybe<Scalars['String']>;
  readonly customerGroup?: Maybe<Scalars['String']>;
  readonly orderHeight?: Maybe<Scalars['Float']>;
  readonly orderWidth?: Maybe<Scalars['Float']>;
  readonly orderLength?: Maybe<Scalars['Float']>;
  readonly orderHeightUnit?: Maybe<Scalars['String']>;
  readonly orderWidthUnit?: Maybe<Scalars['String']>;
  readonly orderLengthUnit?: Maybe<Scalars['String']>;
  readonly tabName?: Maybe<Scalars['String']>;
};

export type SeerMainPageRouteBoardPendingEdge = {
  readonly __typename?: 'SeerMainPageRouteBoardPendingEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageRouteBoardPending;
};

export type SeerMainPageRouteBoardPendingFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oas?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tenderedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly tenderedAtTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly expectedAtTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipper?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly shipperId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly requestedShipDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly requestedShipDateTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentRegionConcatenated?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly totalDistance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalDistanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly consignee?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly consigneeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentRegionConcatenated?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly reqArriveDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly reqArriveDateTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly scac?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly quoteType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly relation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderWeight?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly orderWeightUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly cargoInsurance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly cargoInsuranceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly requirements?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly commitFreq?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly commitVol?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly countAccepted?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tenderStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerGroup?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderHeight?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly orderWidth?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly orderLength?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly orderHeightUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderWidthUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderLengthUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tabName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerMainPageRouteBoardPendingOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly oas?: InputMaybe<SeerOrderBy>;
  readonly tenderedAt?: InputMaybe<SeerOrderBy>;
  readonly tenderedAtTimezone?: InputMaybe<SeerOrderBy>;
  readonly expectedAt?: InputMaybe<SeerOrderBy>;
  readonly expectedAtTimezone?: InputMaybe<SeerOrderBy>;
  readonly routeTransportMode?: InputMaybe<SeerOrderBy>;
  readonly shipper?: InputMaybe<SeerOrderBy>;
  readonly shipperId?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly requestedShipDate?: InputMaybe<SeerOrderBy>;
  readonly requestedShipDateTimezone?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimezone?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTimezone?: InputMaybe<SeerOrderBy>;
  readonly oBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly obRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegionConcatenated?: InputMaybe<SeerOrderBy>;
  readonly totalDistance?: InputMaybe<SeerOrderBy>;
  readonly totalDistanceUnit?: InputMaybe<SeerOrderBy>;
  readonly consignee?: InputMaybe<SeerOrderBy>;
  readonly consigneeId?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimezone?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTimezone?: InputMaybe<SeerOrderBy>;
  readonly ibRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentRegionConcatenated?: InputMaybe<SeerOrderBy>;
  readonly reqArriveDate?: InputMaybe<SeerOrderBy>;
  readonly reqArriveDateTimezone?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly size?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly scac?: InputMaybe<SeerOrderBy>;
  readonly quoteType?: InputMaybe<SeerOrderBy>;
  readonly relation?: InputMaybe<SeerOrderBy>;
  readonly tier?: InputMaybe<SeerOrderBy>;
  readonly orderWeight?: InputMaybe<SeerOrderBy>;
  readonly orderWeightUnit?: InputMaybe<SeerOrderBy>;
  readonly cargoInsurance?: InputMaybe<SeerOrderBy>;
  readonly cargoInsuranceUnit?: InputMaybe<SeerOrderBy>;
  readonly requirements?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly commitFreq?: InputMaybe<SeerOrderBy>;
  readonly commitVol?: InputMaybe<SeerOrderBy>;
  readonly countAccepted?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly routeRepId?: InputMaybe<SeerOrderBy>;
  readonly routeRep?: InputMaybe<SeerOrderBy>;
  readonly tenderStatus?: InputMaybe<SeerOrderBy>;
  readonly customerGroup?: InputMaybe<SeerOrderBy>;
  readonly orderHeight?: InputMaybe<SeerOrderBy>;
  readonly orderWidth?: InputMaybe<SeerOrderBy>;
  readonly orderLength?: InputMaybe<SeerOrderBy>;
  readonly orderHeightUnit?: InputMaybe<SeerOrderBy>;
  readonly orderWidthUnit?: InputMaybe<SeerOrderBy>;
  readonly orderLengthUnit?: InputMaybe<SeerOrderBy>;
  readonly tabName?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageRouteBoardPendingResult = {
  readonly __typename?: 'SeerMainPageRouteBoardPendingResult';
  readonly edges: ReadonlyArray<SeerMainPageRouteBoardPendingEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageRouteBoardPreTender = {
  readonly __typename?: 'SeerMainPageRouteBoardPreTender';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puStartTimezone?: Maybe<Scalars['String']>;
  readonly puEndTimezone?: Maybe<Scalars['String']>;
  readonly obRegion?: Maybe<Scalars['String']>;
  readonly obSubRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentRegionConcatenated?: Maybe<Scalars['String']>;
  readonly dest?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly destStartDate?: Maybe<Scalars['DateTime']>;
  readonly destEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStartTime?: Maybe<Scalars['Int']>;
  readonly destEndTime?: Maybe<Scalars['Int']>;
  readonly destStartTimezone?: Maybe<Scalars['String']>;
  readonly destEndTimezone?: Maybe<Scalars['String']>;
  readonly ibRegion?: Maybe<Scalars['String']>;
  readonly ibSubRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegionConcatenated?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routingGuideId: Scalars['String'];
  /** Column is of type UUID */
  readonly tenderPlanId: Scalars['String'];
  readonly tenderPlanType: Scalars['String'];
  readonly numberOfCarriers?: Maybe<Scalars['Int']>;
  readonly equip?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerRepId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeRepId?: Maybe<Scalars['String']>;
};

export type SeerMainPageRouteBoardPreTenderEdge = {
  readonly __typename?: 'SeerMainPageRouteBoardPreTenderEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageRouteBoardPreTender;
};

export type SeerMainPageRouteBoardPreTenderFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentRegionConcatenated?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destStartTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destEndTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentRegionConcatenated?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routingGuideId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly tenderPlanId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tenderPlanType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly numberOfCarriers?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerMainPageRouteBoardPreTenderOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly routeTransportMode?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimezone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimezone?: InputMaybe<SeerOrderBy>;
  readonly obRegion?: InputMaybe<SeerOrderBy>;
  readonly obSubRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegionConcatenated?: InputMaybe<SeerOrderBy>;
  readonly dest?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly destStartDate?: InputMaybe<SeerOrderBy>;
  readonly destEndDate?: InputMaybe<SeerOrderBy>;
  readonly destStartTime?: InputMaybe<SeerOrderBy>;
  readonly destEndTime?: InputMaybe<SeerOrderBy>;
  readonly destStartTimezone?: InputMaybe<SeerOrderBy>;
  readonly destEndTimezone?: InputMaybe<SeerOrderBy>;
  readonly ibRegion?: InputMaybe<SeerOrderBy>;
  readonly ibSubRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentRegionConcatenated?: InputMaybe<SeerOrderBy>;
  readonly routingGuideId?: InputMaybe<SeerOrderBy>;
  readonly tenderPlanId?: InputMaybe<SeerOrderBy>;
  readonly tenderPlanType?: InputMaybe<SeerOrderBy>;
  readonly numberOfCarriers?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly routeRepId?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageRouteBoardPreTenderResult = {
  readonly __typename?: 'SeerMainPageRouteBoardPreTenderResult';
  readonly edges: ReadonlyArray<SeerMainPageRouteBoardPreTenderEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageRouteBoardResult = {
  readonly __typename?: 'SeerMainPageRouteBoardResult';
  readonly edges: ReadonlyArray<SeerMainPageRouteBoardEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageRouteBoardTender = {
  readonly __typename?: 'SeerMainPageRouteBoardTender';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puStartTimezone?: Maybe<Scalars['String']>;
  readonly puEndTimezone?: Maybe<Scalars['String']>;
  readonly obRegion?: Maybe<Scalars['String']>;
  readonly obSubRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentRegionConcatenated?: Maybe<Scalars['String']>;
  readonly dest?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly destStartDate?: Maybe<Scalars['DateTime']>;
  readonly destEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStartTime?: Maybe<Scalars['Int']>;
  readonly destEndTime?: Maybe<Scalars['Int']>;
  readonly destStartTimezone?: Maybe<Scalars['String']>;
  readonly destEndTimezone?: Maybe<Scalars['String']>;
  readonly ibRegion?: Maybe<Scalars['String']>;
  readonly ibSubRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegionConcatenated?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly tenderPlanId: Scalars['String'];
  readonly tenderPlanType: Scalars['String'];
  readonly tenderStartDateTime?: Maybe<Scalars['DateTime']>;
  readonly expirationTimeValue: Scalars['Float'];
  readonly expirationTimeUnit: Scalars['String'];
  readonly carrierSequence?: Maybe<Scalars['String']>;
  readonly currentTender: Scalars['String'];
  readonly equip?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerRepId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeRepId: Scalars['String'];
};

export type SeerMainPageRouteBoardTenderEdge = {
  readonly __typename?: 'SeerMainPageRouteBoardTenderEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageRouteBoardTender;
};

export type SeerMainPageRouteBoardTenderFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentRegionConcatenated?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destStartTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destEndTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentRegionConcatenated?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly tenderPlanId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tenderPlanType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tenderStartDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly expirationTimeValue?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expirationTimeUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentTender?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerMainPageRouteBoardTenderOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly routeTransportMode?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimezone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimezone?: InputMaybe<SeerOrderBy>;
  readonly obRegion?: InputMaybe<SeerOrderBy>;
  readonly obSubRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegionConcatenated?: InputMaybe<SeerOrderBy>;
  readonly dest?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly destStartDate?: InputMaybe<SeerOrderBy>;
  readonly destEndDate?: InputMaybe<SeerOrderBy>;
  readonly destStartTime?: InputMaybe<SeerOrderBy>;
  readonly destEndTime?: InputMaybe<SeerOrderBy>;
  readonly destStartTimezone?: InputMaybe<SeerOrderBy>;
  readonly destEndTimezone?: InputMaybe<SeerOrderBy>;
  readonly ibRegion?: InputMaybe<SeerOrderBy>;
  readonly ibSubRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentRegionConcatenated?: InputMaybe<SeerOrderBy>;
  readonly tenderPlanId?: InputMaybe<SeerOrderBy>;
  readonly tenderPlanType?: InputMaybe<SeerOrderBy>;
  readonly tenderStartDateTime?: InputMaybe<SeerOrderBy>;
  readonly expirationTimeValue?: InputMaybe<SeerOrderBy>;
  readonly expirationTimeUnit?: InputMaybe<SeerOrderBy>;
  readonly carrierSequence?: InputMaybe<SeerOrderBy>;
  readonly currentTender?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly routeRepId?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageRouteBoardTenderResult = {
  readonly __typename?: 'SeerMainPageRouteBoardTenderResult';
  readonly edges: ReadonlyArray<SeerMainPageRouteBoardTenderEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageTrackingBoard = {
  readonly __typename?: 'SeerMainPageTrackingBoard';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly status?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly originDate?: Maybe<Scalars['DateTime']>;
  readonly originTime?: Maybe<Scalars['Int']>;
  readonly destDate?: Maybe<Scalars['DateTime']>;
  readonly destTime?: Maybe<Scalars['Int']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly destination?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly nxtCallBkDtTm?: Maybe<Scalars['DateTime']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverNumber?: Maybe<Scalars['String']>;
  readonly lastTrackingNote?: Maybe<Scalars['String']>;
  readonly createDate?: Maybe<Scalars['DateTime']>;
  readonly rep?: Maybe<Scalars['String']>;
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly customerReps?: Maybe<Scalars['String']>;
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly expectedEmptyLatitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyLongitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly expectedEmptyCountry?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly originStopType?: Maybe<Scalars['String']>;
  readonly destinationStopType?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly originStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly destinationStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly vendorId?: Maybe<Scalars['String']>;
  readonly appt?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly ibRegionUrn?: Maybe<Scalars['String']>;
  readonly obRegionUrn?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly orderRepId?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly createdByName?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly lockId?: Maybe<Scalars['String']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly carrierArrivalDate?: Maybe<Scalars['DateTime']>;
  readonly carrierArrivalTimeZone?: Maybe<Scalars['String']>;
  readonly emptyLocation?: Maybe<Scalars['String']>;
  readonly emptyDate?: Maybe<Scalars['DateTime']>;
  readonly actualDepartureDate?: Maybe<Scalars['DateTime']>;
  readonly actualDepartureTimezone?: Maybe<Scalars['String']>;
  readonly nextLocationCity?: Maybe<Scalars['String']>;
  readonly nextLocationState?: Maybe<Scalars['String']>;
  readonly infoFrom?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly latestElectronicLocationUpdateDatetime?: Maybe<Scalars['DateTime']>;
  readonly latestTrackingRequest?: Maybe<Scalars['DateTime']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly originloadStopSequence?: Maybe<Scalars['Int']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Int']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly autoEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly manualEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly driverElectronicTrackingTypeId?: Maybe<Scalars['String']>;
  readonly nextApptStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptStartTime?: Maybe<Scalars['Int']>;
  readonly nextApptEndTime?: Maybe<Scalars['Int']>;
  readonly nextApptStartTimeZone?: Maybe<Scalars['String']>;
  readonly nextApptEndTimeZone?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly bookedById?: Maybe<Scalars['String']>;
  readonly bookedByName?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly nextStopType?: Maybe<Scalars['String']>;
};

export type SeerMainPageTrackingBoardEdge = {
  readonly __typename?: 'SeerMainPageTrackingBoardEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageTrackingBoard;
};

export type SeerMainPageTrackingBoardFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly status?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly originTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destination?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nxtCallBkDtTm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastTrackingNote?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly rep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerReps?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly originStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly destinationStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly appt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly employeeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdByName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly lockId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockExpirationTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly isLocked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly carrierArrivalDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly carrierArrivalTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyLocation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly actualDepartureDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly actualDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly infoFrom?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trackingStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly latestTrackingRequest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly originloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destinationloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly autoEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly manualEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly bookedById?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly bookedByName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerMainPageTrackingBoardOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly status?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly originDate?: InputMaybe<SeerOrderBy>;
  readonly originTime?: InputMaybe<SeerOrderBy>;
  readonly destDate?: InputMaybe<SeerOrderBy>;
  readonly destTime?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly destination?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly nxtCallBkDtTm?: InputMaybe<SeerOrderBy>;
  readonly driverName?: InputMaybe<SeerOrderBy>;
  readonly driverNumber?: InputMaybe<SeerOrderBy>;
  readonly lastTrackingNote?: InputMaybe<SeerOrderBy>;
  readonly createDate?: InputMaybe<SeerOrderBy>;
  readonly rep?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly customerReps?: InputMaybe<SeerOrderBy>;
  readonly routeRepId?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLatitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLongitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCity?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyState?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCountry?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly division?: InputMaybe<SeerOrderBy>;
  readonly originStopType?: InputMaybe<SeerOrderBy>;
  readonly destinationStopType?: InputMaybe<SeerOrderBy>;
  readonly originStopId?: InputMaybe<SeerOrderBy>;
  readonly destinationStopId?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly appt?: InputMaybe<SeerOrderBy>;
  readonly employeeId?: InputMaybe<SeerOrderBy>;
  readonly ibRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly obRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly orderRepId?: InputMaybe<SeerOrderBy>;
  readonly orderActivationStatus?: InputMaybe<SeerOrderBy>;
  readonly createdByName?: InputMaybe<SeerOrderBy>;
  readonly lockId?: InputMaybe<SeerOrderBy>;
  readonly lockEmail?: InputMaybe<SeerOrderBy>;
  readonly lockExpirationTime?: InputMaybe<SeerOrderBy>;
  readonly isLocked?: InputMaybe<SeerOrderBy>;
  readonly carrierArrivalDate?: InputMaybe<SeerOrderBy>;
  readonly carrierArrivalTimeZone?: InputMaybe<SeerOrderBy>;
  readonly emptyLocation?: InputMaybe<SeerOrderBy>;
  readonly emptyDate?: InputMaybe<SeerOrderBy>;
  readonly actualDepartureDate?: InputMaybe<SeerOrderBy>;
  readonly actualDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCity?: InputMaybe<SeerOrderBy>;
  readonly nextLocationState?: InputMaybe<SeerOrderBy>;
  readonly infoFrom?: InputMaybe<SeerOrderBy>;
  readonly trackingStatus?: InputMaybe<SeerOrderBy>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<SeerOrderBy>;
  readonly latestTrackingRequest?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly originloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly destinationloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly autoEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly manualEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly routeType?: InputMaybe<SeerOrderBy>;
  readonly bookedById?: InputMaybe<SeerOrderBy>;
  readonly bookedByName?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber?: InputMaybe<SeerOrderBy>;
  readonly nextStopType?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageTrackingBoardResult = {
  readonly __typename?: 'SeerMainPageTrackingBoardResult';
  readonly edges: ReadonlyArray<SeerMainPageTrackingBoardEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageTrackingBoardV2 = {
  readonly __typename?: 'SeerMainPageTrackingBoardV2';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  readonly routeNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly status?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly firstStopTz?: Maybe<Scalars['String']>;
  readonly ccaStartStatus?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentRegionConcatenated?: Maybe<Scalars['String']>;
  readonly originloadStopSequence?: Maybe<Scalars['Int']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly obRegionUrn?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly originStopId?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsubReionUrn?: Maybe<Scalars['String']>;
  readonly oBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly nxtCallBkDtTm?: Maybe<Scalars['DateTime']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverNumber?: Maybe<Scalars['String']>;
  readonly driverElectronicTrackingTypeId?: Maybe<Scalars['String']>;
  readonly infoFrom?: Maybe<Scalars['String']>;
  readonly lastTrackingNote?: Maybe<Scalars['String']>;
  readonly latestTrackingRequest?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly nextRoute?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly previousRoute?: Maybe<Scalars['String']>;
  readonly createDate?: Maybe<Scalars['DateTime']>;
  readonly connectivity?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly originDate?: Maybe<Scalars['DateTime']>;
  readonly originTime?: Maybe<Scalars['Int']>;
  readonly destDate?: Maybe<Scalars['DateTime']>;
  readonly destTime?: Maybe<Scalars['Int']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly destination?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly rep?: Maybe<Scalars['String']>;
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly customerReps?: Maybe<Scalars['String']>;
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly expectedEmptyLatitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyLongitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly expectedEmptyCountry?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly originStopType?: Maybe<Scalars['String']>;
  readonly destinationStopType?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly destinationStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly vendorId?: Maybe<Scalars['String']>;
  readonly appt?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly ibRegionUrn?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly orderRepId?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly createdByName?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly lockId?: Maybe<Scalars['String']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly carrierArrivalDate?: Maybe<Scalars['DateTime']>;
  readonly carrierArrivalTimeZone?: Maybe<Scalars['String']>;
  readonly emptyLocation?: Maybe<Scalars['String']>;
  readonly emptyDate?: Maybe<Scalars['DateTime']>;
  readonly actualDepartureDate?: Maybe<Scalars['DateTime']>;
  readonly actualDepartureTimezone?: Maybe<Scalars['String']>;
  readonly nextLocationCity?: Maybe<Scalars['String']>;
  readonly nextLocationState?: Maybe<Scalars['String']>;
  readonly latestElectronicLocationUpdateDatetime?: Maybe<Scalars['DateTime']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Int']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly autoEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly manualEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly nextApptStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptStartTime?: Maybe<Scalars['Int']>;
  readonly nextApptEndTime?: Maybe<Scalars['Int']>;
  readonly nextApptStartTimeZone?: Maybe<Scalars['String']>;
  readonly nextApptEndTimeZone?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly bookedById?: Maybe<Scalars['String']>;
  readonly bookedByName?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
};

export type SeerMainPageTrackingBoardV2Count = {
  readonly __typename?: 'SeerMainPageTrackingBoardV2Count';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly status?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly originDate?: Maybe<Scalars['DateTime']>;
  readonly originTime?: Maybe<Scalars['Int']>;
  readonly destDate?: Maybe<Scalars['DateTime']>;
  readonly destTime?: Maybe<Scalars['Int']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly destination?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly nxtCallBkDtTm?: Maybe<Scalars['DateTime']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverNumber?: Maybe<Scalars['String']>;
  readonly lastTrackingNote?: Maybe<Scalars['String']>;
  readonly createDate?: Maybe<Scalars['DateTime']>;
  readonly rep?: Maybe<Scalars['String']>;
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly customerReps?: Maybe<Scalars['String']>;
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly expectedEmptyLatitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyLongitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly expectedEmptyCountry?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly originStopType?: Maybe<Scalars['String']>;
  readonly destinationStopType?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly originStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly destinationStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly vendorId?: Maybe<Scalars['String']>;
  readonly appt?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly ibRegionUrn?: Maybe<Scalars['String']>;
  readonly obRegionUrn?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly orderRepId?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly createdByName?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly lockId?: Maybe<Scalars['String']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly carrierArrivalDate?: Maybe<Scalars['DateTime']>;
  readonly carrierArrivalTimeZone?: Maybe<Scalars['String']>;
  readonly emptyLocation?: Maybe<Scalars['String']>;
  readonly emptyDate?: Maybe<Scalars['DateTime']>;
  readonly actualDepartureDate?: Maybe<Scalars['DateTime']>;
  readonly actualDepartureTimezone?: Maybe<Scalars['String']>;
  readonly nextLocationCity?: Maybe<Scalars['String']>;
  readonly nextLocationState?: Maybe<Scalars['String']>;
  readonly infoFrom?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly latestElectronicLocationUpdateDatetime?: Maybe<Scalars['DateTime']>;
  readonly latestTrackingRequest?: Maybe<Scalars['DateTime']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly originloadStopSequence?: Maybe<Scalars['Int']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Int']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly autoEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly manualEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly driverElectronicTrackingTypeId?: Maybe<Scalars['String']>;
  readonly nextApptStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptStartTime?: Maybe<Scalars['Int']>;
  readonly nextApptEndTime?: Maybe<Scalars['Int']>;
  readonly nextApptStartTimeZone?: Maybe<Scalars['String']>;
  readonly nextApptEndTimeZone?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly bookedById?: Maybe<Scalars['String']>;
  readonly bookedByName?: Maybe<Scalars['String']>;
  readonly count: Scalars['Int'];
};

export type SeerMainPageTrackingBoardV2CountEdge = {
  readonly __typename?: 'SeerMainPageTrackingBoardV2CountEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageTrackingBoardV2Count;
};

export type SeerMainPageTrackingBoardV2CountFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly status?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly originTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destination?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nxtCallBkDtTm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastTrackingNote?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly rep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerReps?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly originStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly destinationStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly appt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly employeeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdByName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly lockId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockExpirationTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly isLocked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly carrierArrivalDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly carrierArrivalTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyLocation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly actualDepartureDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly actualDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly infoFrom?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trackingStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly latestTrackingRequest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly originloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destinationloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly autoEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly manualEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly bookedById?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly bookedByName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly count?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
};

export type SeerMainPageTrackingBoardV2CountOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly status?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly originDate?: InputMaybe<SeerOrderBy>;
  readonly originTime?: InputMaybe<SeerOrderBy>;
  readonly destDate?: InputMaybe<SeerOrderBy>;
  readonly destTime?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly destination?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly nxtCallBkDtTm?: InputMaybe<SeerOrderBy>;
  readonly driverName?: InputMaybe<SeerOrderBy>;
  readonly driverNumber?: InputMaybe<SeerOrderBy>;
  readonly lastTrackingNote?: InputMaybe<SeerOrderBy>;
  readonly createDate?: InputMaybe<SeerOrderBy>;
  readonly rep?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly customerReps?: InputMaybe<SeerOrderBy>;
  readonly routeRepId?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLatitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLongitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCity?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyState?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCountry?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly division?: InputMaybe<SeerOrderBy>;
  readonly originStopType?: InputMaybe<SeerOrderBy>;
  readonly destinationStopType?: InputMaybe<SeerOrderBy>;
  readonly originStopId?: InputMaybe<SeerOrderBy>;
  readonly destinationStopId?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly appt?: InputMaybe<SeerOrderBy>;
  readonly employeeId?: InputMaybe<SeerOrderBy>;
  readonly ibRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly obRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly orderRepId?: InputMaybe<SeerOrderBy>;
  readonly orderActivationStatus?: InputMaybe<SeerOrderBy>;
  readonly createdByName?: InputMaybe<SeerOrderBy>;
  readonly lockId?: InputMaybe<SeerOrderBy>;
  readonly lockEmail?: InputMaybe<SeerOrderBy>;
  readonly lockExpirationTime?: InputMaybe<SeerOrderBy>;
  readonly isLocked?: InputMaybe<SeerOrderBy>;
  readonly carrierArrivalDate?: InputMaybe<SeerOrderBy>;
  readonly carrierArrivalTimeZone?: InputMaybe<SeerOrderBy>;
  readonly emptyLocation?: InputMaybe<SeerOrderBy>;
  readonly emptyDate?: InputMaybe<SeerOrderBy>;
  readonly actualDepartureDate?: InputMaybe<SeerOrderBy>;
  readonly actualDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCity?: InputMaybe<SeerOrderBy>;
  readonly nextLocationState?: InputMaybe<SeerOrderBy>;
  readonly infoFrom?: InputMaybe<SeerOrderBy>;
  readonly trackingStatus?: InputMaybe<SeerOrderBy>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<SeerOrderBy>;
  readonly latestTrackingRequest?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly originloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly destinationloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly autoEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly manualEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly routeType?: InputMaybe<SeerOrderBy>;
  readonly bookedById?: InputMaybe<SeerOrderBy>;
  readonly bookedByName?: InputMaybe<SeerOrderBy>;
  readonly count?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageTrackingBoardV2CountResult = {
  readonly __typename?: 'SeerMainPageTrackingBoardV2CountResult';
  readonly edges: ReadonlyArray<SeerMainPageTrackingBoardV2CountEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageTrackingBoardV2Edge = {
  readonly __typename?: 'SeerMainPageTrackingBoardV2Edge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageTrackingBoardV2;
};

export type SeerMainPageTrackingBoardV2Filter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly status?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly endDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly startDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopTz?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ccaStartStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentRegionConcatenated?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly originStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubReionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nxtCallBkDtTm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly infoFrom?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastTrackingNote?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly latestTrackingRequest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly nextRoute?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly previousRoute?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly connectivity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trackingStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly originTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destination?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerReps?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly destinationStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly appt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly employeeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdByName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly lockId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockExpirationTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly isLocked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly carrierArrivalDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly carrierArrivalTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyLocation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly actualDepartureDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly actualDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destinationloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly autoEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly manualEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly bookedById?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly bookedByName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerMainPageTrackingBoardV2OrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly status?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly endDate?: InputMaybe<SeerOrderBy>;
  readonly startDate?: InputMaybe<SeerOrderBy>;
  readonly firstStopTz?: InputMaybe<SeerOrderBy>;
  readonly ccaStartStatus?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegionConcatenated?: InputMaybe<SeerOrderBy>;
  readonly originloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly obRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly originStopId?: InputMaybe<SeerOrderBy>;
  readonly oBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubReionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly routeActivationStatus?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly routeType?: InputMaybe<SeerOrderBy>;
  readonly nxtCallBkDtTm?: InputMaybe<SeerOrderBy>;
  readonly driverName?: InputMaybe<SeerOrderBy>;
  readonly driverNumber?: InputMaybe<SeerOrderBy>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<SeerOrderBy>;
  readonly infoFrom?: InputMaybe<SeerOrderBy>;
  readonly lastTrackingNote?: InputMaybe<SeerOrderBy>;
  readonly latestTrackingRequest?: InputMaybe<SeerOrderBy>;
  readonly nextRoute?: InputMaybe<SeerOrderBy>;
  readonly previousRoute?: InputMaybe<SeerOrderBy>;
  readonly createDate?: InputMaybe<SeerOrderBy>;
  readonly connectivity?: InputMaybe<SeerOrderBy>;
  readonly trackingStatus?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly originDate?: InputMaybe<SeerOrderBy>;
  readonly originTime?: InputMaybe<SeerOrderBy>;
  readonly destDate?: InputMaybe<SeerOrderBy>;
  readonly destTime?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly destination?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly rep?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly customerReps?: InputMaybe<SeerOrderBy>;
  readonly routeRepId?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLatitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLongitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCity?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyState?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCountry?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly division?: InputMaybe<SeerOrderBy>;
  readonly originStopType?: InputMaybe<SeerOrderBy>;
  readonly destinationStopType?: InputMaybe<SeerOrderBy>;
  readonly destinationStopId?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly appt?: InputMaybe<SeerOrderBy>;
  readonly employeeId?: InputMaybe<SeerOrderBy>;
  readonly ibRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly orderRepId?: InputMaybe<SeerOrderBy>;
  readonly orderActivationStatus?: InputMaybe<SeerOrderBy>;
  readonly createdByName?: InputMaybe<SeerOrderBy>;
  readonly lockId?: InputMaybe<SeerOrderBy>;
  readonly lockEmail?: InputMaybe<SeerOrderBy>;
  readonly lockExpirationTime?: InputMaybe<SeerOrderBy>;
  readonly isLocked?: InputMaybe<SeerOrderBy>;
  readonly carrierArrivalDate?: InputMaybe<SeerOrderBy>;
  readonly carrierArrivalTimeZone?: InputMaybe<SeerOrderBy>;
  readonly emptyLocation?: InputMaybe<SeerOrderBy>;
  readonly emptyDate?: InputMaybe<SeerOrderBy>;
  readonly actualDepartureDate?: InputMaybe<SeerOrderBy>;
  readonly actualDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCity?: InputMaybe<SeerOrderBy>;
  readonly nextLocationState?: InputMaybe<SeerOrderBy>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly destinationloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly autoEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly manualEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly bookedById?: InputMaybe<SeerOrderBy>;
  readonly bookedByName?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageTrackingBoardV2Result = {
  readonly __typename?: 'SeerMainPageTrackingBoardV2Result';
  readonly edges: ReadonlyArray<SeerMainPageTrackingBoardV2Edge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMasterFindLoadSearch = {
  readonly __typename?: 'SeerMasterFindLoadSearch';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly refNumber?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly organizationIdInMasterfind?: Maybe<Scalars['String']>;
  readonly organizationIdInLoadsearch?: Maybe<Scalars['Boolean']>;
};

export type SeerMasterFindLoadSearchEdge = {
  readonly __typename?: 'SeerMasterFindLoadSearchEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMasterFindLoadSearch;
};

export type SeerMasterFindLoadSearchFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly refNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly organizationName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly organizationIdInMasterfind?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly organizationIdInLoadsearch?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerMasterFindLoadSearchOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly originState?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly destinationState?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly refNumber?: InputMaybe<SeerOrderBy>;
  readonly organizationName?: InputMaybe<SeerOrderBy>;
  readonly organizationIdInMasterfind?: InputMaybe<SeerOrderBy>;
  readonly organizationIdInLoadsearch?: InputMaybe<SeerOrderBy>;
};

export type SeerMasterFindLoadSearchResult = {
  readonly __typename?: 'SeerMasterFindLoadSearchResult';
  readonly edges: ReadonlyArray<SeerMasterFindLoadSearchEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMasterSignStatus = {
  readonly __typename?: 'SeerMasterSignStatus';
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly documentId: Scalars['String'];
  readonly documentType: Scalars['String'];
  readonly documentSubType: Scalars['String'];
  readonly signStatus: Scalars['String'];
  readonly createdDate?: Maybe<Scalars['DateTime']>;
  readonly updatedDate?: Maybe<Scalars['DateTime']>;
};

export type SeerMasterSignStatusEdge = {
  readonly __typename?: 'SeerMasterSignStatusEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMasterSignStatus;
};

export type SeerMasterSignStatusFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly documentId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly documentType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly documentSubType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly signStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly updatedDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerMasterSignStatusOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly documentId?: InputMaybe<SeerOrderBy>;
  readonly documentType?: InputMaybe<SeerOrderBy>;
  readonly documentSubType?: InputMaybe<SeerOrderBy>;
  readonly signStatus?: InputMaybe<SeerOrderBy>;
  readonly createdDate?: InputMaybe<SeerOrderBy>;
  readonly updatedDate?: InputMaybe<SeerOrderBy>;
};

export type SeerMasterSignStatusResult = {
  readonly __typename?: 'SeerMasterSignStatusResult';
  readonly edges: ReadonlyArray<SeerMasterSignStatusEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMe_287848Customer = {
  readonly __typename?: 'SeerMe_287848Customer';
  /** Column is of type UUID */
  readonly id?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly repId?: Maybe<Scalars['String']>;
  readonly mainRepName?: Maybe<Scalars['String']>;
};

export type SeerMe_287848CustomerEdge = {
  readonly __typename?: 'SeerMe_287848CustomerEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMe_287848Customer;
};

export type SeerMe_287848CustomerFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly repId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainRepName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerMe_287848CustomerOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly repId?: InputMaybe<SeerOrderBy>;
  readonly mainRepName?: InputMaybe<SeerOrderBy>;
};

export type SeerMe_287848CustomerResult = {
  readonly __typename?: 'SeerMe_287848CustomerResult';
  readonly edges: ReadonlyArray<SeerMe_287848CustomerEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerOrderBy = {
  readonly order: SeerGenericOrder;
  readonly position: Scalars['Int'];
};

export type SeerOrderCommodity = {
  readonly __typename?: 'SeerOrderCommodity';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly expectedWeight?: Maybe<Scalars['Float']>;
  readonly expectedWeightUnit?: Maybe<Scalars['String']>;
  readonly expectedPieces?: Maybe<Scalars['Int']>;
  readonly expectedLength?: Maybe<Scalars['Float']>;
  readonly expectedLengthUnit?: Maybe<Scalars['String']>;
  readonly expectedWidth?: Maybe<Scalars['Float']>;
  readonly expectedWidthUnit?: Maybe<Scalars['String']>;
  readonly expectedHeight?: Maybe<Scalars['Float']>;
  readonly expectedHeightUnit?: Maybe<Scalars['String']>;
  readonly expectedLinear?: Maybe<Scalars['Float']>;
  readonly expectedLinearUnit?: Maybe<Scalars['String']>;
  readonly expectedVolume?: Maybe<Scalars['Float']>;
  readonly expectedVolumeUnit?: Maybe<Scalars['String']>;
  readonly expectedDensity?: Maybe<Scalars['Float']>;
  readonly expectedDensityUnit?: Maybe<Scalars['String']>;
  readonly expectedClass?: Maybe<Scalars['String']>;
  readonly expectedNmfcCcode?: Maybe<Scalars['String']>;
  readonly actualWeight?: Maybe<Scalars['Float']>;
  readonly actualWeightUnit?: Maybe<Scalars['String']>;
  readonly actualPieces?: Maybe<Scalars['Int']>;
  readonly actualLength?: Maybe<Scalars['Float']>;
  readonly actualLengthUnit?: Maybe<Scalars['String']>;
  readonly actualWidth?: Maybe<Scalars['Float']>;
  readonly actualWidthUnit?: Maybe<Scalars['String']>;
  readonly actualHeight?: Maybe<Scalars['Float']>;
  readonly actualHeightUnit?: Maybe<Scalars['String']>;
  readonly actualLinear?: Maybe<Scalars['Float']>;
  readonly actualLinearUnit?: Maybe<Scalars['String']>;
  readonly actualVolume?: Maybe<Scalars['Float']>;
  readonly actualVolumeUnit?: Maybe<Scalars['String']>;
  readonly actualDensity?: Maybe<Scalars['Float']>;
  readonly actualDensityUnit?: Maybe<Scalars['String']>;
  readonly actualClass?: Maybe<Scalars['String']>;
  readonly actualNmfcCode?: Maybe<Scalars['String']>;
};

export type SeerOrderCommodityEdge = {
  readonly __typename?: 'SeerOrderCommodityEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerOrderCommodity;
};

export type SeerOrderCommodityFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly description?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedWeight?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedWeightUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedPieces?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly expectedLength?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedLengthUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedWidth?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedWidthUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedHeight?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedHeightUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedLinear?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedLinearUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedVolume?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedVolumeUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedDensity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedDensityUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedClass?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedNmfcCcode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly actualWeight?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly actualWeightUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly actualPieces?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly actualLength?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly actualLengthUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly actualWidth?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly actualWidthUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly actualHeight?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly actualHeightUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly actualLinear?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly actualLinearUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly actualVolume?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly actualVolumeUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly actualDensity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly actualDensityUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly actualClass?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly actualNmfcCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerOrderCommodityOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly description?: InputMaybe<SeerOrderBy>;
  readonly expectedWeight?: InputMaybe<SeerOrderBy>;
  readonly expectedWeightUnit?: InputMaybe<SeerOrderBy>;
  readonly expectedPieces?: InputMaybe<SeerOrderBy>;
  readonly expectedLength?: InputMaybe<SeerOrderBy>;
  readonly expectedLengthUnit?: InputMaybe<SeerOrderBy>;
  readonly expectedWidth?: InputMaybe<SeerOrderBy>;
  readonly expectedWidthUnit?: InputMaybe<SeerOrderBy>;
  readonly expectedHeight?: InputMaybe<SeerOrderBy>;
  readonly expectedHeightUnit?: InputMaybe<SeerOrderBy>;
  readonly expectedLinear?: InputMaybe<SeerOrderBy>;
  readonly expectedLinearUnit?: InputMaybe<SeerOrderBy>;
  readonly expectedVolume?: InputMaybe<SeerOrderBy>;
  readonly expectedVolumeUnit?: InputMaybe<SeerOrderBy>;
  readonly expectedDensity?: InputMaybe<SeerOrderBy>;
  readonly expectedDensityUnit?: InputMaybe<SeerOrderBy>;
  readonly expectedClass?: InputMaybe<SeerOrderBy>;
  readonly expectedNmfcCcode?: InputMaybe<SeerOrderBy>;
  readonly actualWeight?: InputMaybe<SeerOrderBy>;
  readonly actualWeightUnit?: InputMaybe<SeerOrderBy>;
  readonly actualPieces?: InputMaybe<SeerOrderBy>;
  readonly actualLength?: InputMaybe<SeerOrderBy>;
  readonly actualLengthUnit?: InputMaybe<SeerOrderBy>;
  readonly actualWidth?: InputMaybe<SeerOrderBy>;
  readonly actualWidthUnit?: InputMaybe<SeerOrderBy>;
  readonly actualHeight?: InputMaybe<SeerOrderBy>;
  readonly actualHeightUnit?: InputMaybe<SeerOrderBy>;
  readonly actualLinear?: InputMaybe<SeerOrderBy>;
  readonly actualLinearUnit?: InputMaybe<SeerOrderBy>;
  readonly actualVolume?: InputMaybe<SeerOrderBy>;
  readonly actualVolumeUnit?: InputMaybe<SeerOrderBy>;
  readonly actualDensity?: InputMaybe<SeerOrderBy>;
  readonly actualDensityUnit?: InputMaybe<SeerOrderBy>;
  readonly actualClass?: InputMaybe<SeerOrderBy>;
  readonly actualNmfcCode?: InputMaybe<SeerOrderBy>;
};

export type SeerOrderCommodityResult = {
  readonly __typename?: 'SeerOrderCommodityResult';
  readonly edges: ReadonlyArray<SeerOrderCommodityEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerOrderReference = {
  readonly __typename?: 'SeerOrderReference';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId: Scalars['String'];
  readonly type?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeStopId?: Maybe<Scalars['String']>;
  readonly orderStopId?: Maybe<Scalars['String']>;
  readonly ediQualifier?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly vendorId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeVendorId?: Maybe<Scalars['String']>;
  readonly showOnMprbActiveScreens: Scalars['Boolean'];
  /** Column is of type UUID */
  readonly routeIdUuid?: Maybe<Scalars['String']>;
};

export type SeerOrderReferenceEdge = {
  readonly __typename?: 'SeerOrderReferenceEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerOrderReference;
};

export type SeerOrderReferenceFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly type?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly value?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ediQualifier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeVendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly showOnMprbActiveScreens?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  /** Column is of type UUID */
  readonly routeIdUuid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerOrderReferenceOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly type?: InputMaybe<SeerOrderBy>;
  readonly value?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly routeStopId?: InputMaybe<SeerOrderBy>;
  readonly orderStopId?: InputMaybe<SeerOrderBy>;
  readonly ediQualifier?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly routeVendorId?: InputMaybe<SeerOrderBy>;
  readonly showOnMprbActiveScreens?: InputMaybe<SeerOrderBy>;
  readonly routeIdUuid?: InputMaybe<SeerOrderBy>;
};

export type SeerOrderReferenceResult = {
  readonly __typename?: 'SeerOrderReferenceResult';
  readonly edges: ReadonlyArray<SeerOrderReferenceEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerOrderRep = {
  readonly __typename?: 'SeerOrderRep';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly orderId: Scalars['String'];
  readonly name?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly main?: Maybe<Scalars['Boolean']>;
  readonly employeeGroup?: Maybe<Scalars['String']>;
  readonly showOnMprbActiveScreens: Scalars['Boolean'];
};

export type SeerOrderRepEdge = {
  readonly __typename?: 'SeerOrderRepEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerOrderRep;
};

export type SeerOrderRepFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly type?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly employeeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly main?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly employeeGroup?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly showOnMprbActiveScreens?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerOrderRepGroup = {
  readonly __typename?: 'SeerOrderRepGroup';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  readonly employeeGroup?: Maybe<Scalars['String']>;
};

export type SeerOrderRepGroupEdge = {
  readonly __typename?: 'SeerOrderRepGroupEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerOrderRepGroup;
};

export type SeerOrderRepGroupFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly employeeGroup?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerOrderRepGroupOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly employeeGroup?: InputMaybe<SeerOrderBy>;
};

export type SeerOrderRepGroupResult = {
  readonly __typename?: 'SeerOrderRepGroupResult';
  readonly edges: ReadonlyArray<SeerOrderRepGroupEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerOrderRepOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly name?: InputMaybe<SeerOrderBy>;
  readonly type?: InputMaybe<SeerOrderBy>;
  readonly employeeId?: InputMaybe<SeerOrderBy>;
  readonly main?: InputMaybe<SeerOrderBy>;
  readonly employeeGroup?: InputMaybe<SeerOrderBy>;
  readonly showOnMprbActiveScreens?: InputMaybe<SeerOrderBy>;
};

export type SeerOrderRepResult = {
  readonly __typename?: 'SeerOrderRepResult';
  readonly edges: ReadonlyArray<SeerOrderRepEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerOrderStatus = {
  readonly __typename?: 'SeerOrderStatus';
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId: Scalars['String'];
  readonly createdAtTimestampDatetime?: Maybe<Scalars['DateTime']>;
  readonly createdAtTimestamp: Scalars['Int'];
  readonly eventTimestampDatetime?: Maybe<Scalars['DateTime']>;
  readonly eventTimestamp: Scalars['Int'];
  readonly orderLifecycleStatus?: Maybe<Scalars['String']>;
  readonly orderTenderStatus?: Maybe<Scalars['String']>;
  readonly orderFinancialStatus?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly orderActivationStatusReasons?: Maybe<Scalars['String']>;
  readonly orderFinancialStatusReasons?: Maybe<Scalars['String']>;
  readonly eid: Scalars['Int'];
};

export type SeerOrderStatusEdge = {
  readonly __typename?: 'SeerOrderStatusEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerOrderStatus;
};

export type SeerOrderStatusFilter = {
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdAtTimestampDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly createdAtTimestamp?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly eventTimestampDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly eventTimestamp?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly orderLifecycleStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderTenderStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderFinancialStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderActivationStatusReasons?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderFinancialStatusReasons?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
};

export type SeerOrderStatusOrderBy = {
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly createdAtTimestampDatetime?: InputMaybe<SeerOrderBy>;
  readonly createdAtTimestamp?: InputMaybe<SeerOrderBy>;
  readonly eventTimestampDatetime?: InputMaybe<SeerOrderBy>;
  readonly eventTimestamp?: InputMaybe<SeerOrderBy>;
  readonly orderLifecycleStatus?: InputMaybe<SeerOrderBy>;
  readonly orderTenderStatus?: InputMaybe<SeerOrderBy>;
  readonly orderFinancialStatus?: InputMaybe<SeerOrderBy>;
  readonly orderActivationStatus?: InputMaybe<SeerOrderBy>;
  readonly orderActivationStatusReasons?: InputMaybe<SeerOrderBy>;
  readonly orderFinancialStatusReasons?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
};

export type SeerOrderStatusResult = {
  readonly __typename?: 'SeerOrderStatusResult';
  readonly edges: ReadonlyArray<SeerOrderStatusEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerOrderStop = {
  readonly __typename?: 'SeerOrderStop';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly facilityId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly loadId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly employeeId?: Maybe<Scalars['String']>;
};

export type SeerOrderStopEdge = {
  readonly __typename?: 'SeerOrderStopEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerOrderStop;
};

export type SeerOrderStopFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly facilityId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly employeeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerOrderStopOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly facilityId?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly employeeId?: InputMaybe<SeerOrderBy>;
};

export type SeerOrderStopResult = {
  readonly __typename?: 'SeerOrderStopResult';
  readonly edges: ReadonlyArray<SeerOrderStopEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerOrganization = {
  readonly __typename?: 'SeerOrganization';
  /** Column is of type UUID */
  readonly organizationId: Scalars['String'];
  readonly organizationName: Scalars['String'];
  readonly hierarchyCode?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly parentId?: Maybe<Scalars['String']>;
};

export type SeerOrganizationEdge = {
  readonly __typename?: 'SeerOrganizationEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerOrganization;
};

export type SeerOrganizationFilter = {
  /** Column is of type UUID */
  readonly organizationId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly organizationName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly hierarchyCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly parentId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerOrganizationOrderBy = {
  readonly organizationId?: InputMaybe<SeerOrderBy>;
  readonly organizationName?: InputMaybe<SeerOrderBy>;
  readonly hierarchyCode?: InputMaybe<SeerOrderBy>;
  readonly parentId?: InputMaybe<SeerOrderBy>;
};

export type SeerOrganizationResult = {
  readonly __typename?: 'SeerOrganizationResult';
  readonly edges: ReadonlyArray<SeerOrganizationEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerPower = {
  readonly __typename?: 'SeerPower';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly powerCode?: Maybe<Scalars['String']>;
};

export type SeerPowerEdge = {
  readonly __typename?: 'SeerPowerEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerPower;
};

export type SeerPowerFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly powerCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerPowerOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly powerCode?: InputMaybe<SeerOrderBy>;
};

export type SeerPowerResult = {
  readonly __typename?: 'SeerPowerResult';
  readonly edges: ReadonlyArray<SeerPowerEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerPowerRouteAssignment = {
  readonly __typename?: 'SeerPowerRouteAssignment';
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly powerId: Scalars['String'];
};

export type SeerPowerRouteAssignmentEdge = {
  readonly __typename?: 'SeerPowerRouteAssignmentEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerPowerRouteAssignment;
};

export type SeerPowerRouteAssignmentFilter = {
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly powerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerPowerRouteAssignmentOrderBy = {
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly powerId?: InputMaybe<SeerOrderBy>;
};

export type SeerPowerRouteAssignmentResult = {
  readonly __typename?: 'SeerPowerRouteAssignmentResult';
  readonly edges: ReadonlyArray<SeerPowerRouteAssignmentEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerPowerRoutePing = {
  readonly __typename?: 'SeerPowerRoutePing';
  /** Column is of type UUID */
  readonly currentRouteId: Scalars['String'];
  readonly locationDescription?: Maybe<Scalars['String']>;
  readonly speedValue?: Maybe<Scalars['Float']>;
  readonly speedUnitTerm?: Maybe<Scalars['String']>;
  readonly fuelLevelPercent?: Maybe<Scalars['Float']>;
  readonly locationRelativePositionDirection?: Maybe<Scalars['String']>;
  readonly calculatedDirectionHeading?: Maybe<Scalars['String']>;
};

export type SeerPowerRoutePingEdge = {
  readonly __typename?: 'SeerPowerRoutePingEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerPowerRoutePing;
};

export type SeerPowerRoutePingFilter = {
  /** Column is of type UUID */
  readonly currentRouteId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly locationDescription?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly speedValue?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly speedUnitTerm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly fuelLevelPercent?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly locationRelativePositionDirection?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly calculatedDirectionHeading?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerPowerRoutePingOrderBy = {
  readonly currentRouteId?: InputMaybe<SeerOrderBy>;
  readonly locationDescription?: InputMaybe<SeerOrderBy>;
  readonly speedValue?: InputMaybe<SeerOrderBy>;
  readonly speedUnitTerm?: InputMaybe<SeerOrderBy>;
  readonly fuelLevelPercent?: InputMaybe<SeerOrderBy>;
  readonly locationRelativePositionDirection?: InputMaybe<SeerOrderBy>;
  readonly calculatedDirectionHeading?: InputMaybe<SeerOrderBy>;
};

export type SeerPowerRoutePingResult = {
  readonly __typename?: 'SeerPowerRoutePingResult';
  readonly edges: ReadonlyArray<SeerPowerRoutePingEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerRoute = {
  readonly __typename?: 'SeerRoute';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly loadId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly activationStatus?: Maybe<Scalars['String']>;
  readonly tenderStatus?: Maybe<Scalars['String']>;
  readonly lifecycleStatus?: Maybe<Scalars['String']>;
  readonly totalDistance?: Maybe<Scalars['Float']>;
  readonly totalDistanceUnit?: Maybe<Scalars['String']>;
  readonly stopCount?: Maybe<Scalars['Int']>;
  readonly sequence?: Maybe<Scalars['Int']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly transportMode?: Maybe<Scalars['String']>;
  readonly reqArriveDate?: Maybe<Scalars['DateTime']>;
  readonly timezone?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly businessUnit?: Maybe<Scalars['String']>;
  readonly additionalDivision?: Maybe<Scalars['String']>;
  readonly additionalBusinessUnit?: Maybe<Scalars['String']>;
  readonly priority?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly organizationId?: Maybe<Scalars['String']>;
  readonly executingDivision?: Maybe<Scalars['String']>;
  readonly showOnMprbActiveScreens: Scalars['Boolean'];
};

export type SeerRouteCapacityNeed = {
  readonly __typename?: 'SeerRouteCapacityNeed';
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly capacityNeed?: Maybe<Scalars['String']>;
  readonly showOnMprbActiveScreens: Scalars['Boolean'];
};

export type SeerRouteCapacityNeedEdge = {
  readonly __typename?: 'SeerRouteCapacityNeedEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerRouteCapacityNeed;
};

export type SeerRouteCapacityNeedFilter = {
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly capacityNeed?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly showOnMprbActiveScreens?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerRouteCapacityNeedOrderBy = {
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly capacityNeed?: InputMaybe<SeerOrderBy>;
  readonly showOnMprbActiveScreens?: InputMaybe<SeerOrderBy>;
};

export type SeerRouteCapacityNeedResult = {
  readonly __typename?: 'SeerRouteCapacityNeedResult';
  readonly edges: ReadonlyArray<SeerRouteCapacityNeedEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerRouteEdge = {
  readonly __typename?: 'SeerRouteEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerRoute;
};

export type SeerRouteFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly code?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly activationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tenderStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lifecycleStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly totalDistance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalDistanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stopCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly sequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly transportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly reqArriveDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly timezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly additionalBusinessUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly priority?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly organizationId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly executingDivision?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly showOnMprbActiveScreens?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerRouteIdsToBackfill = {
  readonly __typename?: 'SeerRouteIdsToBackfill';
  readonly workId: Scalars['Int'];
  /** Column is of type UUID */
  readonly routeId?: Maybe<Scalars['String']>;
  readonly isDone: Scalars['Boolean'];
};

export type SeerRouteIdsToBackfillEdge = {
  readonly __typename?: 'SeerRouteIdsToBackfillEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerRouteIdsToBackfill;
};

export type SeerRouteIdsToBackfillFilter = {
  readonly workId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isDone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerRouteIdsToBackfillOrderBy = {
  readonly workId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly isDone?: InputMaybe<SeerOrderBy>;
};

export type SeerRouteIdsToBackfillResult = {
  readonly __typename?: 'SeerRouteIdsToBackfillResult';
  readonly edges: ReadonlyArray<SeerRouteIdsToBackfillEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerRouteOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly code?: InputMaybe<SeerOrderBy>;
  readonly activationStatus?: InputMaybe<SeerOrderBy>;
  readonly tenderStatus?: InputMaybe<SeerOrderBy>;
  readonly lifecycleStatus?: InputMaybe<SeerOrderBy>;
  readonly totalDistance?: InputMaybe<SeerOrderBy>;
  readonly totalDistanceUnit?: InputMaybe<SeerOrderBy>;
  readonly stopCount?: InputMaybe<SeerOrderBy>;
  readonly sequence?: InputMaybe<SeerOrderBy>;
  readonly routeType?: InputMaybe<SeerOrderBy>;
  readonly transportMode?: InputMaybe<SeerOrderBy>;
  readonly reqArriveDate?: InputMaybe<SeerOrderBy>;
  readonly timezone?: InputMaybe<SeerOrderBy>;
  readonly division?: InputMaybe<SeerOrderBy>;
  readonly businessUnit?: InputMaybe<SeerOrderBy>;
  readonly additionalDivision?: InputMaybe<SeerOrderBy>;
  readonly additionalBusinessUnit?: InputMaybe<SeerOrderBy>;
  readonly priority?: InputMaybe<SeerOrderBy>;
  readonly size?: InputMaybe<SeerOrderBy>;
  readonly createdAt?: InputMaybe<SeerOrderBy>;
  readonly organizationId?: InputMaybe<SeerOrderBy>;
  readonly executingDivision?: InputMaybe<SeerOrderBy>;
  readonly showOnMprbActiveScreens?: InputMaybe<SeerOrderBy>;
};

export type SeerRoutePreplan = {
  readonly __typename?: 'SeerRoutePreplan';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId?: Maybe<Scalars['String']>;
  readonly prePlanSent?: Maybe<Scalars['DateTime']>;
  readonly eventType?: Maybe<Scalars['String']>;
  readonly createdDateTime?: Maybe<Scalars['DateTime']>;
  readonly updatedDateTime?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly loadId?: Maybe<Scalars['String']>;
  readonly routeNumber?: Maybe<Scalars['String']>;
};

export type SeerRoutePreplanEdge = {
  readonly __typename?: 'SeerRoutePreplanEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerRoutePreplan;
};

export type SeerRoutePreplanFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly prePlanSent?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly eventType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly updatedDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerRoutePreplanOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly prePlanSent?: InputMaybe<SeerOrderBy>;
  readonly eventType?: InputMaybe<SeerOrderBy>;
  readonly createdDateTime?: InputMaybe<SeerOrderBy>;
  readonly updatedDateTime?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
};

export type SeerRoutePreplanResult = {
  readonly __typename?: 'SeerRoutePreplanResult';
  readonly edges: ReadonlyArray<SeerRoutePreplanEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerRouteRateCon = {
  readonly __typename?: 'SeerRouteRateCon';
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly vendorId: Scalars['String'];
  readonly rateConSentDateTime?: Maybe<Scalars['DateTime']>;
};

export type SeerRouteRateConEdge = {
  readonly __typename?: 'SeerRouteRateConEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerRouteRateCon;
};

export type SeerRouteRateConFilter = {
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rateConSentDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerRouteRateConOrderBy = {
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly rateConSentDateTime?: InputMaybe<SeerOrderBy>;
};

export type SeerRouteRateConResult = {
  readonly __typename?: 'SeerRouteRateConResult';
  readonly edges: ReadonlyArray<SeerRouteRateConEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerRouteResult = {
  readonly __typename?: 'SeerRouteResult';
  readonly edges: ReadonlyArray<SeerRouteEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerRouteStatus = {
  readonly __typename?: 'SeerRouteStatus';
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly createdAtTimestampDatetime?: Maybe<Scalars['DateTime']>;
  readonly createdAtTimestamp: Scalars['Int'];
  readonly eventTimestampDatetime?: Maybe<Scalars['DateTime']>;
  readonly eventTimestamp: Scalars['Int'];
  readonly initiator?: Maybe<Scalars['String']>;
  readonly routeLifecycleStatus?: Maybe<Scalars['String']>;
  readonly routeTenderStatus?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly eid: Scalars['Int'];
  readonly isRouteDeleted: Scalars['Boolean'];
};

export type SeerRouteStatusEdge = {
  readonly __typename?: 'SeerRouteStatusEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerRouteStatus;
};

export type SeerRouteStatusFilter = {
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdAtTimestampDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly createdAtTimestamp?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly eventTimestampDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly eventTimestamp?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly initiator?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeLifecycleStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeTenderStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly isRouteDeleted?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerRouteStatusOrderBy = {
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly createdAtTimestampDatetime?: InputMaybe<SeerOrderBy>;
  readonly createdAtTimestamp?: InputMaybe<SeerOrderBy>;
  readonly eventTimestampDatetime?: InputMaybe<SeerOrderBy>;
  readonly eventTimestamp?: InputMaybe<SeerOrderBy>;
  readonly initiator?: InputMaybe<SeerOrderBy>;
  readonly routeLifecycleStatus?: InputMaybe<SeerOrderBy>;
  readonly routeTenderStatus?: InputMaybe<SeerOrderBy>;
  readonly routeActivationStatus?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
  readonly isRouteDeleted?: InputMaybe<SeerOrderBy>;
};

export type SeerRouteStatusResult = {
  readonly __typename?: 'SeerRouteStatusResult';
  readonly edges: ReadonlyArray<SeerRouteStatusEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerRouteStop = {
  readonly __typename?: 'SeerRouteStop';
  /** Column is of type UUID */
  readonly stopId: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly requirements?: Maybe<Scalars['String']>;
  readonly scheduleAppointmentType?: Maybe<Scalars['String']>;
  readonly scheduleDropType?: Maybe<Scalars['String']>;
  readonly scheduleWorkType?: Maybe<Scalars['String']>;
  readonly loadFromType?: Maybe<Scalars['String']>;
  readonly unloadFromType?: Maybe<Scalars['String']>;
};

export type SeerRouteStopEdge = {
  readonly __typename?: 'SeerRouteStopEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerRouteStop;
};

export type SeerRouteStopFilter = {
  /** Column is of type UUID */
  readonly stopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly requirements?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly scheduleAppointmentType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly scheduleDropType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly scheduleWorkType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadFromType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly unloadFromType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerRouteStopOrderBy = {
  readonly stopId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly requirements?: InputMaybe<SeerOrderBy>;
  readonly scheduleAppointmentType?: InputMaybe<SeerOrderBy>;
  readonly scheduleDropType?: InputMaybe<SeerOrderBy>;
  readonly scheduleWorkType?: InputMaybe<SeerOrderBy>;
  readonly loadFromType?: InputMaybe<SeerOrderBy>;
  readonly unloadFromType?: InputMaybe<SeerOrderBy>;
};

export type SeerRouteStopResult = {
  readonly __typename?: 'SeerRouteStopResult';
  readonly edges: ReadonlyArray<SeerRouteStopEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerRouteVendor = {
  readonly __typename?: 'SeerRouteVendor';
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly vendorId: Scalars['String'];
  readonly vendorName: Scalars['String'];
  readonly vendorType: Scalars['String'];
  readonly activeFlag: Scalars['Boolean'];
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly vendorRepId: Scalars['String'];
  readonly vendorRepName?: Maybe<Scalars['String']>;
  readonly vendorRepEmail?: Maybe<Scalars['String']>;
  readonly vendorRepIsMain?: Maybe<Scalars['Boolean']>;
  readonly expectedEmptySource?: Maybe<Scalars['String']>;
  readonly expectedEmptySourceId?: Maybe<Scalars['String']>;
  readonly expectedEmptyLatitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyLongitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly expectedEmptyCountry?: Maybe<Scalars['String']>;
  readonly expectedEmptyDatetime?: Maybe<Scalars['String']>;
  readonly vendorRepEmployeeGroup?: Maybe<Scalars['String']>;
  readonly expectedEmptyTimezone?: Maybe<Scalars['String']>;
  readonly expectedEmptyDatetimeDate?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly routeVendorId: Scalars['String'];
  /** Column is of type UUID */
  readonly bookedById?: Maybe<Scalars['String']>;
  readonly bookedByName?: Maybe<Scalars['String']>;
  readonly executingDivision?: Maybe<Scalars['String']>;
  readonly executingBusinessUnit?: Maybe<Scalars['String']>;
  readonly bookingSourceId?: Maybe<Scalars['String']>;
  readonly showOnMprbActiveScreens: Scalars['Boolean'];
};

export type SeerRouteVendorEdge = {
  readonly __typename?: 'SeerRouteVendorEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerRouteVendor;
};

export type SeerRouteVendorFilter = {
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly vendorName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly vendorType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly activeFlag?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly vendorRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly vendorRepName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly vendorRepEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly vendorRepIsMain?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly expectedEmptySource?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptySourceId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly vendorRepEmployeeGroup?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyDatetimeDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly routeVendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly bookedById?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly bookedByName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly executingDivision?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly executingBusinessUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly bookingSourceId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly showOnMprbActiveScreens?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerRouteVendorOrderBy = {
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly vendorName?: InputMaybe<SeerOrderBy>;
  readonly vendorType?: InputMaybe<SeerOrderBy>;
  readonly activeFlag?: InputMaybe<SeerOrderBy>;
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly vendorRepId?: InputMaybe<SeerOrderBy>;
  readonly vendorRepName?: InputMaybe<SeerOrderBy>;
  readonly vendorRepEmail?: InputMaybe<SeerOrderBy>;
  readonly vendorRepIsMain?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptySource?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptySourceId?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLatitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLongitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCity?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyState?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCountry?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyDatetime?: InputMaybe<SeerOrderBy>;
  readonly vendorRepEmployeeGroup?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyTimezone?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyDatetimeDate?: InputMaybe<SeerOrderBy>;
  readonly routeVendorId?: InputMaybe<SeerOrderBy>;
  readonly bookedById?: InputMaybe<SeerOrderBy>;
  readonly bookedByName?: InputMaybe<SeerOrderBy>;
  readonly executingDivision?: InputMaybe<SeerOrderBy>;
  readonly executingBusinessUnit?: InputMaybe<SeerOrderBy>;
  readonly bookingSourceId?: InputMaybe<SeerOrderBy>;
  readonly showOnMprbActiveScreens?: InputMaybe<SeerOrderBy>;
};

export type SeerRouteVendorReference = {
  readonly __typename?: 'SeerRouteVendorReference';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly value: Scalars['String'];
  readonly ediQualifier?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeVendorId: Scalars['String'];
  readonly routeVendorReferenceType: Scalars['String'];
  readonly eid: Scalars['Int'];
  /** Column is of type UUID */
  readonly routeVendorRouteId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeVendorVendorId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeVendorVendorRepEmployeeId?: Maybe<Scalars['String']>;
};

export type SeerRouteVendorReferenceEdge = {
  readonly __typename?: 'SeerRouteVendorReferenceEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerRouteVendorReference;
};

export type SeerRouteVendorReferenceFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly value?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ediQualifier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeVendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeVendorReferenceType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly routeVendorRouteId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeVendorVendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeVendorVendorRepEmployeeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerRouteVendorReferenceOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly value?: InputMaybe<SeerOrderBy>;
  readonly ediQualifier?: InputMaybe<SeerOrderBy>;
  readonly routeVendorId?: InputMaybe<SeerOrderBy>;
  readonly routeVendorReferenceType?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
  readonly routeVendorRouteId?: InputMaybe<SeerOrderBy>;
  readonly routeVendorVendorId?: InputMaybe<SeerOrderBy>;
  readonly routeVendorVendorRepEmployeeId?: InputMaybe<SeerOrderBy>;
};

export type SeerRouteVendorReferenceResult = {
  readonly __typename?: 'SeerRouteVendorReferenceResult';
  readonly edges: ReadonlyArray<SeerRouteVendorReferenceEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerRouteVendorResult = {
  readonly __typename?: 'SeerRouteVendorResult';
  readonly edges: ReadonlyArray<SeerRouteVendorEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerRouteVendorStatus = {
  readonly __typename?: 'SeerRouteVendorStatus';
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly vendorId: Scalars['String'];
  readonly vendorFinancialStatusReasons?: Maybe<Scalars['String']>;
  readonly vendorFinancialStatus?: Maybe<Scalars['String']>;
  readonly eid: Scalars['Int'];
};

export type SeerRouteVendorStatusEdge = {
  readonly __typename?: 'SeerRouteVendorStatusEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerRouteVendorStatus;
};

export type SeerRouteVendorStatusFilter = {
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly vendorFinancialStatusReasons?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly vendorFinancialStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
};

export type SeerRouteVendorStatusOrderBy = {
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly vendorFinancialStatusReasons?: InputMaybe<SeerOrderBy>;
  readonly vendorFinancialStatus?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
};

export type SeerRouteVendorStatusResult = {
  readonly __typename?: 'SeerRouteVendorStatusResult';
  readonly edges: ReadonlyArray<SeerRouteVendorStatusEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerSchemaversion = {
  readonly __typename?: 'SeerSchemaversion';
  readonly version: Scalars['Int'];
  readonly name?: Maybe<Scalars['String']>;
  readonly md5?: Maybe<Scalars['String']>;
  readonly runAt?: Maybe<Scalars['DateTime']>;
};

export type SeerSchemaversionEdge = {
  readonly __typename?: 'SeerSchemaversionEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerSchemaversion;
};

export type SeerSchemaversionFilter = {
  readonly version?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly md5?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly runAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerSchemaversionOrderBy = {
  readonly version?: InputMaybe<SeerOrderBy>;
  readonly name?: InputMaybe<SeerOrderBy>;
  readonly md5?: InputMaybe<SeerOrderBy>;
  readonly runAt?: InputMaybe<SeerOrderBy>;
};

export type SeerSchemaversionResult = {
  readonly __typename?: 'SeerSchemaversionResult';
  readonly edges: ReadonlyArray<SeerSchemaversionEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerSearchForLinkedRoutes = {
  readonly __typename?: 'SeerSearchForLinkedRoutes';
  /** loadId */
  readonly id: Scalars['ID'];
  readonly height?: Maybe<Scalars['String']>;
  readonly width?: Maybe<Scalars['String']>;
  readonly length?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['String']>;
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly customerReps?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly RLC?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['String']>;
  readonly transportMode?: Maybe<Scalars['String']>;
  readonly activationStatus?: Maybe<Scalars['String']>;
  readonly Stops?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly originCountry?: Maybe<Scalars['String']>;
  readonly originStreet1?: Maybe<Scalars['String']>;
  readonly originStreet2?: Maybe<Scalars['String']>;
  readonly originPostalCode?: Maybe<Scalars['String']>;
  readonly OST?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly originStopType?: Maybe<Scalars['String']>;
  readonly originStopId?: Maybe<Scalars['String']>;
  readonly oBRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly Appt?: Maybe<Scalars['DateTime']>;
  readonly ApptId?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['String']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puEndTime?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly originloadStopSequence?: Maybe<Scalars['String']>;
  readonly firstStopCarrierArivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierArivalTimezone?: Maybe<Scalars['String']>;
  readonly firstStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly destination?: Maybe<Scalars['String']>;
  readonly destinationCountry?: Maybe<Scalars['String']>;
  readonly destinationStreet1?: Maybe<Scalars['String']>;
  readonly destinationStreet2?: Maybe<Scalars['String']>;
  readonly destinationPostalCode?: Maybe<Scalars['String']>;
  readonly DST?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['String']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delEndTime?: Maybe<Scalars['String']>;
  readonly MTzone?: Maybe<Scalars['String']>;
  readonly destinationStopType?: Maybe<Scalars['String']>;
  readonly destinationStopId?: Maybe<Scalars['String']>;
  readonly iBRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['String']>;
  readonly lastStopCarrierArivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierArivalTimezone?: Maybe<Scalars['String']>;
  readonly lastStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly driver1Name?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly driver1PhoneNumber?: Maybe<Scalars['String']>;
  readonly driver2PhoneNumber?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly lastTrackingNotes?: Maybe<Scalars['String']>;
  readonly nextLocationCity?: Maybe<Scalars['String']>;
  readonly nextLocationState?: Maybe<Scalars['String']>;
  readonly emptyDate?: Maybe<Scalars['DateTime']>;
  readonly ETA?: Maybe<Scalars['DateTime']>;
  readonly NxtCallBkDtTm?: Maybe<Scalars['DateTime']>;
  readonly driver1Id?: Maybe<Scalars['String']>;
  readonly driver2Id?: Maybe<Scalars['String']>;
  readonly previousRouteId?: Maybe<Scalars['String']>;
  readonly nextRouteId?: Maybe<Scalars['String']>;
  readonly MTtime?: Maybe<Scalars['DateTime']>;
  readonly emptyLocation?: Maybe<Scalars['String']>;
  readonly emptyTimezone?: Maybe<Scalars['String']>;
  readonly rep?: Maybe<Scalars['String']>;
  readonly MainCarrierRep?: Maybe<Scalars['String']>;
  readonly MainRepIds?: Maybe<Scalars['String']>;
  readonly expectedEmptyLatitude?: Maybe<Scalars['String']>;
  readonly expectedEmptyLongitude?: Maybe<Scalars['String']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly expectedEmptyCountry?: Maybe<Scalars['String']>;
  readonly VendorId?: Maybe<Scalars['String']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly CustomerGroup?: Maybe<Scalars['String']>;
  readonly lockId?: Maybe<Scalars['String']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly isLocked?: Maybe<Scalars['String']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly currentStopId?: Maybe<Scalars['String']>;
  readonly currentStopNumber?: Maybe<Scalars['String']>;
  readonly currentStopCarrierArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopCarrierArrivalTimezone?: Maybe<Scalars['String']>;
  readonly currentStopCarrierDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly nextStopNumber?: Maybe<Scalars['String']>;
  readonly nextApptStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptStartTime?: Maybe<Scalars['String']>;
  readonly nextApptEndTime?: Maybe<Scalars['String']>;
  readonly nextApptStartTimeZone?: Maybe<Scalars['String']>;
  readonly nextApptEndTimeZone?: Maybe<Scalars['String']>;
  readonly nextStopDriverTargetStartTimeZone?: Maybe<Scalars['String']>;
  readonly nextStopDriverTargetStartTime?: Maybe<Scalars['String']>;
  readonly nextStopDriverTargetStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextStopDriverTargetEndTimeZone?: Maybe<Scalars['String']>;
  readonly nextStopDriverTargetEndTime?: Maybe<Scalars['String']>;
  readonly nextStopDriverTargetEndDate?: Maybe<Scalars['DateTime']>;
  readonly currentStopDriverTargetStartTimeZone?: Maybe<Scalars['String']>;
  readonly currentStopDriverTargetStartTime?: Maybe<Scalars['String']>;
  readonly currentStopDriverTargetStartDate?: Maybe<Scalars['DateTime']>;
  readonly currentStopDriverTargetEndTimeZone?: Maybe<Scalars['String']>;
  readonly currentStopDriverTargetEndTime?: Maybe<Scalars['String']>;
  readonly currentStopDriverTargetEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopDriverTargetStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopDriverTargetStartTime?: Maybe<Scalars['String']>;
  readonly originStopDriverTargetStartTimeZone?: Maybe<Scalars['String']>;
  readonly originStopDriverTargetEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopDriverTargetEndTime?: Maybe<Scalars['String']>;
  readonly originStopDriverTargetEndTimeZone?: Maybe<Scalars['String']>;
  readonly destinationStopDriverTargetStartDate?: Maybe<Scalars['DateTime']>;
  readonly destinationStopDriverTargetStartTime?: Maybe<Scalars['String']>;
  readonly destinationStopDriverTargetStartTimeZone?: Maybe<Scalars['String']>;
  readonly destinationStopDriverTargetEndDate?: Maybe<Scalars['DateTime']>;
  readonly destinationStopDriverTargetEndTime?: Maybe<Scalars['String']>;
  readonly destinationStopDriverTargetEndTimeZone?: Maybe<Scalars['String']>;
};

export type SeerSearchForLinkedRoutesConnection = {
  readonly __typename?: 'SeerSearchForLinkedRoutesConnection';
  readonly edges: ReadonlyArray<SeerSearchForLinkedRoutesEdge>;
  readonly pageInfo: PageInfo;
};

export type SeerSearchForLinkedRoutesEdge = {
  readonly __typename?: 'SeerSearchForLinkedRoutesEdge';
  readonly node: SeerSearchForLinkedRoutes;
  readonly cursor: Scalars['String'];
};

export type SeerSearchForLinkedRoutesFilterInput = {
  readonly isLocked?: InputMaybe<Scalars['String']>;
  readonly previousRouteId?: InputMaybe<ReadonlyArray<OperatorFilterWithEqNotEq>>;
  readonly nextRouteId?: InputMaybe<ReadonlyArray<OperatorFilterWithEqNotEq>>;
  readonly puStartDate?: InputMaybe<OperatorWithDatesFilter>;
  readonly puStartTime?: InputMaybe<Scalars['String']>;
  readonly puStartTimeZone?: InputMaybe<Scalars['String']>;
  readonly puEndDate?: InputMaybe<OperatorWithDatesFilter>;
  readonly puEndTime?: InputMaybe<Scalars['String']>;
  readonly puEndTimeZone?: InputMaybe<Scalars['String']>;
  readonly delStartDate?: InputMaybe<OperatorWithDatesFilter>;
  readonly delStartTime?: InputMaybe<Scalars['String']>;
  readonly delStartTimeZone?: InputMaybe<Scalars['String']>;
  readonly delEndDate?: InputMaybe<OperatorWithDatesFilter>;
  readonly delEndTime?: InputMaybe<Scalars['String']>;
  readonly delEndTimeZone?: InputMaybe<Scalars['String']>;
  readonly routeActivationStatus?: InputMaybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeCode?: InputMaybe<OperatorFilterWithEqLike>;
  readonly routeId?: InputMaybe<OperatorFilterWithEqNotEq>;
  readonly routeNumber?: InputMaybe<OperatorFilterWithEqNu>;
  readonly vendorId?: InputMaybe<ReadonlyArray<OperatorFilterWithEqNu>>;
  readonly routeType?: InputMaybe<OperatorFilterWithEqNotEq>;
  readonly driver1Id?: InputMaybe<ReadonlyArray<OperatorFilterWithEqNu>>;
  readonly driver2Id?: InputMaybe<ReadonlyArray<OperatorFilterWithEqNu>>;
  readonly originStopDriverTargetStartDate?: InputMaybe<ReadonlyArray<OperatorWithDatesFilterAndNull>>;
  readonly originStopDriverTargetEndDate?: InputMaybe<ReadonlyArray<OperatorWithDatesFilterAndNull>>;
  readonly destinationStopDriverTargetStartDate?: InputMaybe<ReadonlyArray<OperatorWithDatesFilterAndNull>>;
  readonly destinationStopDriverTargetEndDate?: InputMaybe<ReadonlyArray<OperatorWithDatesFilterAndNull>>;
};

export type SeerSequencedTrailerRoute = {
  readonly __typename?: 'SeerSequencedTrailerRoute';
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly inboundTrailerIdentifier1?: Maybe<Scalars['String']>;
  readonly inboundTrailerIdentifier2?: Maybe<Scalars['String']>;
  readonly inboundTrailerIdentifier3?: Maybe<Scalars['String']>;
  readonly outboundTrailerIdentifier1?: Maybe<Scalars['String']>;
  readonly outboundTrailerIdentifier2?: Maybe<Scalars['String']>;
  readonly outboundTrailerIdentifier3?: Maybe<Scalars['String']>;
  readonly loadedTrailerIdentifier1?: Maybe<Scalars['String']>;
  readonly loadedTrailerIdentifier2?: Maybe<Scalars['String']>;
  readonly loadedTrailerIdentifier3?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly inboundTrailerRecordId1?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly inboundTrailerRecordId2?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly inboundTrailerRecordId3?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly outboundTrailerRecordId1?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly outboundTrailerRecordId2?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly outboundTrailerRecordId3?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly loadedTrailerRecordId1?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly loadedTrailerRecordId2?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly loadedTrailerRecordId3?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly inboundTrailerTypeId1?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly inboundTrailerTypeId2?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly inboundTrailerTypeId3?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly outboundTrailerTypeId1?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly outboundTrailerTypeId2?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly outboundTrailerTypeId3?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly loadedTrailerTypeId1?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly loadedTrailerTypeId2?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly loadedTrailerTypeId3?: Maybe<Scalars['String']>;
  readonly inboundTrailerResourceType1?: Maybe<Scalars['String']>;
  readonly inboundTrailerResourceType2?: Maybe<Scalars['String']>;
  readonly inboundTrailerResourceType3?: Maybe<Scalars['String']>;
  readonly outboundTrailerResourceType1?: Maybe<Scalars['String']>;
  readonly outboundTrailerResourceType2?: Maybe<Scalars['String']>;
  readonly outboundTrailerResourceType3?: Maybe<Scalars['String']>;
  readonly loadedTrailerResourceType1?: Maybe<Scalars['String']>;
  readonly loadedTrailerResourceType2?: Maybe<Scalars['String']>;
  readonly loadedTrailerResourceType3?: Maybe<Scalars['String']>;
};

export type SeerSequencedTrailerRouteEdge = {
  readonly __typename?: 'SeerSequencedTrailerRouteEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerSequencedTrailerRoute;
};

export type SeerSequencedTrailerRouteFilter = {
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundTrailerIdentifier1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundTrailerIdentifier2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundTrailerIdentifier3?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundTrailerIdentifier1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundTrailerIdentifier2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundTrailerIdentifier3?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadedTrailerIdentifier1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadedTrailerIdentifier2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadedTrailerIdentifier3?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly inboundTrailerRecordId1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly inboundTrailerRecordId2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly inboundTrailerRecordId3?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly outboundTrailerRecordId1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly outboundTrailerRecordId2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly outboundTrailerRecordId3?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadedTrailerRecordId1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadedTrailerRecordId2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadedTrailerRecordId3?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly inboundTrailerTypeId1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly inboundTrailerTypeId2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly inboundTrailerTypeId3?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly outboundTrailerTypeId1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly outboundTrailerTypeId2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly outboundTrailerTypeId3?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadedTrailerTypeId1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadedTrailerTypeId2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadedTrailerTypeId3?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundTrailerResourceType1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundTrailerResourceType2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundTrailerResourceType3?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundTrailerResourceType1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundTrailerResourceType2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundTrailerResourceType3?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadedTrailerResourceType1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadedTrailerResourceType2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadedTrailerResourceType3?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerSequencedTrailerRouteOrderBy = {
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly inboundTrailerIdentifier1?: InputMaybe<SeerOrderBy>;
  readonly inboundTrailerIdentifier2?: InputMaybe<SeerOrderBy>;
  readonly inboundTrailerIdentifier3?: InputMaybe<SeerOrderBy>;
  readonly outboundTrailerIdentifier1?: InputMaybe<SeerOrderBy>;
  readonly outboundTrailerIdentifier2?: InputMaybe<SeerOrderBy>;
  readonly outboundTrailerIdentifier3?: InputMaybe<SeerOrderBy>;
  readonly loadedTrailerIdentifier1?: InputMaybe<SeerOrderBy>;
  readonly loadedTrailerIdentifier2?: InputMaybe<SeerOrderBy>;
  readonly loadedTrailerIdentifier3?: InputMaybe<SeerOrderBy>;
  readonly inboundTrailerRecordId1?: InputMaybe<SeerOrderBy>;
  readonly inboundTrailerRecordId2?: InputMaybe<SeerOrderBy>;
  readonly inboundTrailerRecordId3?: InputMaybe<SeerOrderBy>;
  readonly outboundTrailerRecordId1?: InputMaybe<SeerOrderBy>;
  readonly outboundTrailerRecordId2?: InputMaybe<SeerOrderBy>;
  readonly outboundTrailerRecordId3?: InputMaybe<SeerOrderBy>;
  readonly loadedTrailerRecordId1?: InputMaybe<SeerOrderBy>;
  readonly loadedTrailerRecordId2?: InputMaybe<SeerOrderBy>;
  readonly loadedTrailerRecordId3?: InputMaybe<SeerOrderBy>;
  readonly inboundTrailerTypeId1?: InputMaybe<SeerOrderBy>;
  readonly inboundTrailerTypeId2?: InputMaybe<SeerOrderBy>;
  readonly inboundTrailerTypeId3?: InputMaybe<SeerOrderBy>;
  readonly outboundTrailerTypeId1?: InputMaybe<SeerOrderBy>;
  readonly outboundTrailerTypeId2?: InputMaybe<SeerOrderBy>;
  readonly outboundTrailerTypeId3?: InputMaybe<SeerOrderBy>;
  readonly loadedTrailerTypeId1?: InputMaybe<SeerOrderBy>;
  readonly loadedTrailerTypeId2?: InputMaybe<SeerOrderBy>;
  readonly loadedTrailerTypeId3?: InputMaybe<SeerOrderBy>;
  readonly inboundTrailerResourceType1?: InputMaybe<SeerOrderBy>;
  readonly inboundTrailerResourceType2?: InputMaybe<SeerOrderBy>;
  readonly inboundTrailerResourceType3?: InputMaybe<SeerOrderBy>;
  readonly outboundTrailerResourceType1?: InputMaybe<SeerOrderBy>;
  readonly outboundTrailerResourceType2?: InputMaybe<SeerOrderBy>;
  readonly outboundTrailerResourceType3?: InputMaybe<SeerOrderBy>;
  readonly loadedTrailerResourceType1?: InputMaybe<SeerOrderBy>;
  readonly loadedTrailerResourceType2?: InputMaybe<SeerOrderBy>;
  readonly loadedTrailerResourceType3?: InputMaybe<SeerOrderBy>;
};

export type SeerSequencedTrailerRouteResult = {
  readonly __typename?: 'SeerSequencedTrailerRouteResult';
  readonly edges: ReadonlyArray<SeerSequencedTrailerRouteEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerStgIncidents = {
  readonly __typename?: 'SeerStgIncidents';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly facilityId?: Maybe<Scalars['String']>;
  readonly closed?: Maybe<Scalars['Boolean']>;
  readonly accounting?: Maybe<Scalars['Boolean']>;
};

export type SeerStgIncidentsEdge = {
  readonly __typename?: 'SeerStgIncidentsEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerStgIncidents;
};

export type SeerStgIncidentsFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly facilityId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly closed?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly accounting?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerStgIncidentsOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly facilityId?: InputMaybe<SeerOrderBy>;
  readonly closed?: InputMaybe<SeerOrderBy>;
  readonly accounting?: InputMaybe<SeerOrderBy>;
};

export type SeerStgIncidentsResult = {
  readonly __typename?: 'SeerStgIncidentsResult';
  readonly edges: ReadonlyArray<SeerStgIncidentsEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerStop = {
  readonly __typename?: 'SeerStop';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly stopId: Scalars['String'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly type: Scalars['String'];
  /** Column is of type UUID */
  readonly facilityId?: Maybe<Scalars['String']>;
  readonly facilityName?: Maybe<Scalars['String']>;
  readonly geocode?: Maybe<SeerGeoPoint>;
  readonly street1?: Maybe<Scalars['String']>;
  readonly street2?: Maybe<Scalars['String']>;
  readonly locality?: Maybe<Scalars['String']>;
  readonly administrativeArea1Code?: Maybe<Scalars['String']>;
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly countryAlpha2Code?: Maybe<Scalars['String']>;
  readonly ccaAvailableStartDate?: Maybe<Scalars['DateTime']>;
  readonly ccaAvailableStartTimezone?: Maybe<Scalars['String']>;
  readonly ccaAvailableStartTimeStartIn?: Maybe<Scalars['Int']>;
  readonly ccaAvailableStartTimeEndIn?: Maybe<Scalars['Int']>;
  readonly ccaAvailableEndDate?: Maybe<Scalars['DateTime']>;
  readonly ccaAvailableEndTimezone?: Maybe<Scalars['String']>;
  readonly ccaAvailableEndTimeStartIn?: Maybe<Scalars['Int']>;
  readonly ccaAvailableEndTimeEndIn?: Maybe<Scalars['Int']>;
  readonly ccaApptReqWith?: Maybe<Scalars['String']>;
  readonly ccaApptConfBy?: Maybe<Scalars['String']>;
  readonly ccaStatus?: Maybe<Scalars['String']>;
  readonly subRegionUrn?: Maybe<Scalars['String']>;
  readonly regionUrn?: Maybe<Scalars['String']>;
  readonly superRegionUrn?: Maybe<Scalars['String']>;
  readonly sequence?: Maybe<Scalars['Int']>;
  readonly isFirstStop?: Maybe<Scalars['Boolean']>;
  readonly isLastStop?: Maybe<Scalars['Boolean']>;
  readonly freightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly freightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly freightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly loadStopSequence?: Maybe<Scalars['Int']>;
  readonly isFirstLoadStop?: Maybe<Scalars['Boolean']>;
  readonly isLastLoadStop?: Maybe<Scalars['Boolean']>;
  readonly liveType?: Maybe<Scalars['String']>;
  readonly scheduleType?: Maybe<Scalars['String']>;
  readonly requestedShipDate?: Maybe<Scalars['DateTime']>;
  readonly timezone?: Maybe<Scalars['String']>;
  readonly freightAssignmentRegionConcatenated?: Maybe<Scalars['String']>;
  readonly driverTargetStartDate?: Maybe<Scalars['DateTime']>;
  readonly driverTargetStartTimeStartIn?: Maybe<Scalars['Int']>;
  readonly driverTargetStartTimeEndIn?: Maybe<Scalars['Int']>;
  readonly driverTargetEndDate?: Maybe<Scalars['DateTime']>;
  readonly driverTargetEndTimeStartIn?: Maybe<Scalars['Int']>;
  readonly driverTargetEndTimeEndIn?: Maybe<Scalars['Int']>;
  readonly driverTargetAddedBy?: Maybe<Scalars['String']>;
  readonly driverTargetAddedAt?: Maybe<Scalars['DateTime']>;
  readonly administrativeArea2Code?: Maybe<Scalars['String']>;
  readonly showOnMprbActiveScreens: Scalars['Boolean'];
};

export type SeerStopEdge = {
  readonly __typename?: 'SeerStopEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerStop;
};

export type SeerStopEventsAggregations = {
  readonly __typename?: 'SeerStopEventsAggregations';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly stopId: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  readonly driverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly driverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly driverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly driverDepartureTimezone?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByTxId: Scalars['String'];
  readonly updatedByTxId: Scalars['String'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly eid: Scalars['Int'];
  readonly trailerNumber1?: Maybe<Scalars['String']>;
  readonly trailerNumber2?: Maybe<Scalars['String']>;
  readonly trailerNumber3?: Maybe<Scalars['String']>;
  readonly showOnMprbActiveScreens: Scalars['Boolean'];
};

export type SeerStopEventsAggregationsEdge = {
  readonly __typename?: 'SeerStopEventsAggregationsEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerStopEventsAggregations;
};

export type SeerStopEventsAggregationsFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly stopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverArrivalDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverArrivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverDepartureDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly updatedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly createdByTxId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedByTxId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly trailerNumber1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerNumber2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerNumber3?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly showOnMprbActiveScreens?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerStopEventsAggregationsOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly stopId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly driverArrivalDatetime?: InputMaybe<SeerOrderBy>;
  readonly driverArrivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly driverDepartureDatetime?: InputMaybe<SeerOrderBy>;
  readonly driverDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly createdAt?: InputMaybe<SeerOrderBy>;
  readonly updatedAt?: InputMaybe<SeerOrderBy>;
  readonly createdByTxId?: InputMaybe<SeerOrderBy>;
  readonly updatedByTxId?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly updatedBy?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber1?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber2?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber3?: InputMaybe<SeerOrderBy>;
  readonly showOnMprbActiveScreens?: InputMaybe<SeerOrderBy>;
};

export type SeerStopEventsAggregationsResult = {
  readonly __typename?: 'SeerStopEventsAggregationsResult';
  readonly edges: ReadonlyArray<SeerStopEventsAggregationsEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerStopFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly stopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly type?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly facilityId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly facilityName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly geocode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly street1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly street2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly locality?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly administrativeArea1Code?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly postalCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly countryAlpha2Code?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ccaAvailableStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly ccaAvailableStartTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ccaAvailableStartTimeStartIn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly ccaAvailableStartTimeEndIn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly ccaAvailableEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly ccaAvailableEndTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ccaAvailableEndTimeStartIn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly ccaAvailableEndTimeEndIn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly ccaApptReqWith?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ccaApptConfBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ccaStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly subRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly regionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly superRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly sequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly isFirstStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly isLastStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly freightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly freightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly freightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly isFirstLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly isLastLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly liveType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly scheduleType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly requestedShipDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly timezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly freightAssignmentRegionConcatenated?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverTargetStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverTargetStartTimeStartIn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly driverTargetStartTimeEndIn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly driverTargetEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverTargetEndTimeStartIn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly driverTargetEndTimeEndIn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly driverTargetAddedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverTargetAddedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly administrativeArea2Code?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly showOnMprbActiveScreens?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerStopOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly stopId?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly type?: InputMaybe<SeerOrderBy>;
  readonly facilityId?: InputMaybe<SeerOrderBy>;
  readonly facilityName?: InputMaybe<SeerOrderBy>;
  readonly street1?: InputMaybe<SeerOrderBy>;
  readonly street2?: InputMaybe<SeerOrderBy>;
  readonly locality?: InputMaybe<SeerOrderBy>;
  readonly administrativeArea1Code?: InputMaybe<SeerOrderBy>;
  readonly postalCode?: InputMaybe<SeerOrderBy>;
  readonly countryAlpha2Code?: InputMaybe<SeerOrderBy>;
  readonly ccaAvailableStartDate?: InputMaybe<SeerOrderBy>;
  readonly ccaAvailableStartTimezone?: InputMaybe<SeerOrderBy>;
  readonly ccaAvailableStartTimeStartIn?: InputMaybe<SeerOrderBy>;
  readonly ccaAvailableStartTimeEndIn?: InputMaybe<SeerOrderBy>;
  readonly ccaAvailableEndDate?: InputMaybe<SeerOrderBy>;
  readonly ccaAvailableEndTimezone?: InputMaybe<SeerOrderBy>;
  readonly ccaAvailableEndTimeStartIn?: InputMaybe<SeerOrderBy>;
  readonly ccaAvailableEndTimeEndIn?: InputMaybe<SeerOrderBy>;
  readonly ccaApptReqWith?: InputMaybe<SeerOrderBy>;
  readonly ccaApptConfBy?: InputMaybe<SeerOrderBy>;
  readonly ccaStatus?: InputMaybe<SeerOrderBy>;
  readonly subRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly regionUrn?: InputMaybe<SeerOrderBy>;
  readonly superRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly sequence?: InputMaybe<SeerOrderBy>;
  readonly isFirstStop?: InputMaybe<SeerOrderBy>;
  readonly isLastStop?: InputMaybe<SeerOrderBy>;
  readonly freightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly freightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly freightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly loadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly isFirstLoadStop?: InputMaybe<SeerOrderBy>;
  readonly isLastLoadStop?: InputMaybe<SeerOrderBy>;
  readonly liveType?: InputMaybe<SeerOrderBy>;
  readonly scheduleType?: InputMaybe<SeerOrderBy>;
  readonly requestedShipDate?: InputMaybe<SeerOrderBy>;
  readonly timezone?: InputMaybe<SeerOrderBy>;
  readonly freightAssignmentRegionConcatenated?: InputMaybe<SeerOrderBy>;
  readonly driverTargetStartDate?: InputMaybe<SeerOrderBy>;
  readonly driverTargetStartTimeStartIn?: InputMaybe<SeerOrderBy>;
  readonly driverTargetStartTimeEndIn?: InputMaybe<SeerOrderBy>;
  readonly driverTargetEndDate?: InputMaybe<SeerOrderBy>;
  readonly driverTargetEndTimeStartIn?: InputMaybe<SeerOrderBy>;
  readonly driverTargetEndTimeEndIn?: InputMaybe<SeerOrderBy>;
  readonly driverTargetAddedBy?: InputMaybe<SeerOrderBy>;
  readonly driverTargetAddedAt?: InputMaybe<SeerOrderBy>;
  readonly administrativeArea2Code?: InputMaybe<SeerOrderBy>;
  readonly showOnMprbActiveScreens?: InputMaybe<SeerOrderBy>;
};

export type SeerStopResult = {
  readonly __typename?: 'SeerStopResult';
  readonly edges: ReadonlyArray<SeerStopEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTender = {
  readonly __typename?: 'SeerTender';
  /** Column is of type UUID */
  readonly tenderId: Scalars['String'];
  /** Column is of type UUID */
  readonly tenderPlanId: Scalars['String'];
  /** Column is of type UUID */
  readonly carrierId: Scalars['String'];
  readonly leadTimeValue: Scalars['Float'];
  readonly leadTimeUnit: Scalars['String'];
  readonly expirationTimeValue: Scalars['Float'];
  readonly expirationTimeUnit: Scalars['String'];
  readonly ordinal: Scalars['Int'];
  /** Column is of type UUID */
  readonly tenderStatusId: Scalars['String'];
  readonly carrierCost: Scalars['Float'];
  readonly tenderedAt?: Maybe<Scalars['DateTime']>;
};

export type SeerTenderEdge = {
  readonly __typename?: 'SeerTenderEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTender;
};

export type SeerTenderFilter = {
  /** Column is of type UUID */
  readonly tenderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly tenderPlanId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly leadTimeValue?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly leadTimeUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expirationTimeValue?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expirationTimeUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ordinal?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly tenderStatusId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly tenderedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerTenderOrderBy = {
  readonly tenderId?: InputMaybe<SeerOrderBy>;
  readonly tenderPlanId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly leadTimeValue?: InputMaybe<SeerOrderBy>;
  readonly leadTimeUnit?: InputMaybe<SeerOrderBy>;
  readonly expirationTimeValue?: InputMaybe<SeerOrderBy>;
  readonly expirationTimeUnit?: InputMaybe<SeerOrderBy>;
  readonly ordinal?: InputMaybe<SeerOrderBy>;
  readonly tenderStatusId?: InputMaybe<SeerOrderBy>;
  readonly carrierCost?: InputMaybe<SeerOrderBy>;
  readonly tenderedAt?: InputMaybe<SeerOrderBy>;
};

export type SeerTenderPlan = {
  readonly __typename?: 'SeerTenderPlan';
  /** Column is of type UUID */
  readonly tenderPlanId: Scalars['String'];
  /** Column is of type UUID */
  readonly routingGuideId: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly tenderPlanStatusId: Scalars['String'];
  readonly tenderPlanTypeTerm: Scalars['String'];
  readonly routingGuideCode?: Maybe<Scalars['String']>;
};

export type SeerTenderPlanEdge = {
  readonly __typename?: 'SeerTenderPlanEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTenderPlan;
};

export type SeerTenderPlanFilter = {
  /** Column is of type UUID */
  readonly tenderPlanId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routingGuideId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly tenderPlanStatusId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tenderPlanTypeTerm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routingGuideCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerTenderPlanOrderBy = {
  readonly tenderPlanId?: InputMaybe<SeerOrderBy>;
  readonly routingGuideId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly tenderPlanStatusId?: InputMaybe<SeerOrderBy>;
  readonly tenderPlanTypeTerm?: InputMaybe<SeerOrderBy>;
  readonly routingGuideCode?: InputMaybe<SeerOrderBy>;
};

export type SeerTenderPlanResult = {
  readonly __typename?: 'SeerTenderPlanResult';
  readonly edges: ReadonlyArray<SeerTenderPlanEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTenderPlanStatus = {
  readonly __typename?: 'SeerTenderPlanStatus';
  /** Column is of type UUID */
  readonly tenderPlanStatusId: Scalars['String'];
  readonly name: Scalars['String'];
};

export type SeerTenderPlanStatusEdge = {
  readonly __typename?: 'SeerTenderPlanStatusEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTenderPlanStatus;
};

export type SeerTenderPlanStatusFilter = {
  /** Column is of type UUID */
  readonly tenderPlanStatusId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerTenderPlanStatusOrderBy = {
  readonly tenderPlanStatusId?: InputMaybe<SeerOrderBy>;
  readonly name?: InputMaybe<SeerOrderBy>;
};

export type SeerTenderPlanStatusResult = {
  readonly __typename?: 'SeerTenderPlanStatusResult';
  readonly edges: ReadonlyArray<SeerTenderPlanStatusEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTenderResult = {
  readonly __typename?: 'SeerTenderResult';
  readonly edges: ReadonlyArray<SeerTenderEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTenderStatus = {
  readonly __typename?: 'SeerTenderStatus';
  /** Column is of type UUID */
  readonly tenderStatusId: Scalars['String'];
  readonly name: Scalars['String'];
};

export type SeerTenderStatusEdge = {
  readonly __typename?: 'SeerTenderStatusEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTenderStatus;
};

export type SeerTenderStatusFilter = {
  /** Column is of type UUID */
  readonly tenderStatusId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerTenderStatusOrderBy = {
  readonly tenderStatusId?: InputMaybe<SeerOrderBy>;
  readonly name?: InputMaybe<SeerOrderBy>;
};

export type SeerTenderStatusResult = {
  readonly __typename?: 'SeerTenderStatusResult';
  readonly edges: ReadonlyArray<SeerTenderStatusEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTrackingAggregations = {
  readonly __typename?: 'SeerTrackingAggregations';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly driverAssignmentId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly updateId?: Maybe<Scalars['String']>;
  readonly driver1DisplayName?: Maybe<Scalars['String']>;
  readonly driver1PhoneNumber?: Maybe<Scalars['String']>;
  readonly driver2DisplayName?: Maybe<Scalars['String']>;
  readonly driver2PhoneNumber?: Maybe<Scalars['String']>;
  readonly lastTrackingNote?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly previousRouteId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly nextRouteId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly dispatched?: Maybe<Scalars['Boolean']>;
  readonly etaDatetime?: Maybe<Scalars['DateTime']>;
  readonly etaTimezone?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
  readonly checkCallDatetime?: Maybe<Scalars['DateTime']>;
  readonly checkCallTimezone?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly sourceCreatedDatetime?: Maybe<Scalars['DateTime']>;
  readonly sourceCreatedTimezone?: Maybe<Scalars['String']>;
  readonly actionTypeId?: Maybe<Scalars['String']>;
  readonly currentLocation?: Maybe<Scalars['String']>;
  readonly currentLocationLatitude?: Maybe<Scalars['Float']>;
  readonly currentLocationLongitude?: Maybe<Scalars['Float']>;
  readonly currentLocationCity?: Maybe<Scalars['String']>;
  readonly currentLocationState?: Maybe<Scalars['String']>;
  readonly currentLocationCountry?: Maybe<Scalars['String']>;
  readonly currentLocationPostalCode?: Maybe<Scalars['String']>;
  readonly nextLocation?: Maybe<Scalars['String']>;
  readonly nextLocationLatitude?: Maybe<Scalars['Float']>;
  readonly nextLocationLongitude?: Maybe<Scalars['Float']>;
  readonly nextLocationCity?: Maybe<Scalars['String']>;
  readonly nextLocationState?: Maybe<Scalars['String']>;
  readonly nextLocationCountry?: Maybe<Scalars['String']>;
  readonly nextLocationPostalCode?: Maybe<Scalars['String']>;
  readonly integrationName?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByTxId: Scalars['String'];
  readonly updatedByTxId: Scalars['String'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly eid: Scalars['Int'];
  readonly emptyDatetime?: Maybe<Scalars['DateTime']>;
  readonly emptyTimezone?: Maybe<Scalars['String']>;
  readonly latestElectronicLocationUpdateDatetime?: Maybe<Scalars['DateTime']>;
  readonly latestTrackingRequestDatetime?: Maybe<Scalars['DateTime']>;
  readonly driverElectronicTrackingTypeId?: Maybe<Scalars['String']>;
  readonly autoEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly waterfallHash?: Maybe<Scalars['String']>;
};

export type SeerTrackingAggregationsEdge = {
  readonly __typename?: 'SeerTrackingAggregationsEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTrackingAggregations;
};

export type SeerTrackingAggregationsFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly driverAssignmentId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly updateId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver1DisplayName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver1PhoneNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver2DisplayName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver2PhoneNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastTrackingNote?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly previousRouteId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly nextRouteId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dispatched?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly etaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly etaTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tractorNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly checkCallDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly checkCallTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trackingStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly sourceCreatedDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly sourceCreatedTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly actionTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentLocation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentLocationLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly currentLocationLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly currentLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentLocationCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentLocationPostalCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly nextLocationLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly nextLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationPostalCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly integrationName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly updatedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly createdByTxId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedByTxId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly emptyDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly emptyTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly latestTrackingRequestDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly autoEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly waterfallHash?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerTrackingAggregationsOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly driverAssignmentId?: InputMaybe<SeerOrderBy>;
  readonly updateId?: InputMaybe<SeerOrderBy>;
  readonly driver1DisplayName?: InputMaybe<SeerOrderBy>;
  readonly driver1PhoneNumber?: InputMaybe<SeerOrderBy>;
  readonly driver2DisplayName?: InputMaybe<SeerOrderBy>;
  readonly driver2PhoneNumber?: InputMaybe<SeerOrderBy>;
  readonly lastTrackingNote?: InputMaybe<SeerOrderBy>;
  readonly previousRouteId?: InputMaybe<SeerOrderBy>;
  readonly nextRouteId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly carrierName?: InputMaybe<SeerOrderBy>;
  readonly dispatched?: InputMaybe<SeerOrderBy>;
  readonly etaDatetime?: InputMaybe<SeerOrderBy>;
  readonly etaTimezone?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber?: InputMaybe<SeerOrderBy>;
  readonly tractorNumber?: InputMaybe<SeerOrderBy>;
  readonly checkCallDatetime?: InputMaybe<SeerOrderBy>;
  readonly checkCallTimezone?: InputMaybe<SeerOrderBy>;
  readonly trackingStatus?: InputMaybe<SeerOrderBy>;
  readonly sourceCreatedDatetime?: InputMaybe<SeerOrderBy>;
  readonly sourceCreatedTimezone?: InputMaybe<SeerOrderBy>;
  readonly actionTypeId?: InputMaybe<SeerOrderBy>;
  readonly currentLocation?: InputMaybe<SeerOrderBy>;
  readonly currentLocationLatitude?: InputMaybe<SeerOrderBy>;
  readonly currentLocationLongitude?: InputMaybe<SeerOrderBy>;
  readonly currentLocationCity?: InputMaybe<SeerOrderBy>;
  readonly currentLocationState?: InputMaybe<SeerOrderBy>;
  readonly currentLocationCountry?: InputMaybe<SeerOrderBy>;
  readonly currentLocationPostalCode?: InputMaybe<SeerOrderBy>;
  readonly nextLocation?: InputMaybe<SeerOrderBy>;
  readonly nextLocationLatitude?: InputMaybe<SeerOrderBy>;
  readonly nextLocationLongitude?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCity?: InputMaybe<SeerOrderBy>;
  readonly nextLocationState?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCountry?: InputMaybe<SeerOrderBy>;
  readonly nextLocationPostalCode?: InputMaybe<SeerOrderBy>;
  readonly integrationName?: InputMaybe<SeerOrderBy>;
  readonly createdAt?: InputMaybe<SeerOrderBy>;
  readonly updatedAt?: InputMaybe<SeerOrderBy>;
  readonly createdByTxId?: InputMaybe<SeerOrderBy>;
  readonly updatedByTxId?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly updatedBy?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
  readonly emptyDatetime?: InputMaybe<SeerOrderBy>;
  readonly emptyTimezone?: InputMaybe<SeerOrderBy>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<SeerOrderBy>;
  readonly latestTrackingRequestDatetime?: InputMaybe<SeerOrderBy>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<SeerOrderBy>;
  readonly autoEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly waterfallHash?: InputMaybe<SeerOrderBy>;
};

export type SeerTrackingAggregationsResult = {
  readonly __typename?: 'SeerTrackingAggregationsResult';
  readonly edges: ReadonlyArray<SeerTrackingAggregationsEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTrackingAggregationsUpdate = {
  readonly __typename?: 'SeerTrackingAggregationsUpdate';
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly updateById?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly distanceToNext?: Maybe<Scalars['Float']>;
  readonly distanceToNextUnit?: Maybe<Scalars['String']>;
  readonly providerEtaDatetime?: Maybe<Scalars['DateTime']>;
};

export type SeerTrackingAggregationsUpdateEdge = {
  readonly __typename?: 'SeerTrackingAggregationsUpdateEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTrackingAggregationsUpdate;
};

export type SeerTrackingAggregationsUpdateFilter = {
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly updateById?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly displayName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly distanceToNext?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly distanceToNextUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly providerEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerTrackingAggregationsUpdateOrderBy = {
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly updateById?: InputMaybe<SeerOrderBy>;
  readonly displayName?: InputMaybe<SeerOrderBy>;
  readonly distanceToNext?: InputMaybe<SeerOrderBy>;
  readonly distanceToNextUnit?: InputMaybe<SeerOrderBy>;
  readonly providerEtaDatetime?: InputMaybe<SeerOrderBy>;
};

export type SeerTrackingAggregationsUpdateResult = {
  readonly __typename?: 'SeerTrackingAggregationsUpdateResult';
  readonly edges: ReadonlyArray<SeerTrackingAggregationsUpdateEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTrackingEtas = {
  readonly __typename?: 'SeerTrackingEtas';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly eid: Scalars['Int'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly nextStopId: Scalars['String'];
  readonly autoEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly manualEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly autoEtaSource?: Maybe<Scalars['String']>;
};

export type SeerTrackingEtasEdge = {
  readonly __typename?: 'SeerTrackingEtasEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTrackingEtas;
};

export type SeerTrackingEtasFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly nextStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly autoEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly manualEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly autoEtaSource?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerTrackingEtasOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly nextStopId?: InputMaybe<SeerOrderBy>;
  readonly autoEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly manualEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly autoEtaSource?: InputMaybe<SeerOrderBy>;
};

export type SeerTrackingEtasResult = {
  readonly __typename?: 'SeerTrackingEtasResult';
  readonly edges: ReadonlyArray<SeerTrackingEtasEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTrackingPage = {
  readonly __typename?: 'SeerTrackingPage';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly rlc?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly nextRoute?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly previousRoute?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly originDate?: Maybe<Scalars['DateTime']>;
  readonly originTime?: Maybe<Scalars['Int']>;
  readonly destDate?: Maybe<Scalars['DateTime']>;
  readonly destTime?: Maybe<Scalars['Int']>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly destTz?: Maybe<Scalars['String']>;
  readonly firstStopTz?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly destination?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly nxtCallBkDtTm?: Maybe<Scalars['DateTime']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverNumber?: Maybe<Scalars['String']>;
  readonly lastTrackingNote?: Maybe<Scalars['String']>;
  readonly createDate?: Maybe<Scalars['DateTime']>;
  readonly nextLocationCity?: Maybe<Scalars['String']>;
  readonly nextLocationState?: Maybe<Scalars['String']>;
  readonly groupId?: Maybe<Scalars['String']>;
  readonly rep?: Maybe<Scalars['String']>;
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly expectedEmptyLatitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyLongitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly expectedEmptyCountry?: Maybe<Scalars['String']>;
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly customerReps?: Maybe<Scalars['String']>;
  readonly connectivity?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly originStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly destinationStopId?: Maybe<Scalars['String']>;
  readonly ibRegionUrn?: Maybe<Scalars['String']>;
  readonly obRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsubReionUrn?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly ccaStartStatus?: Maybe<Scalars['String']>;
  readonly ccaEndStatus?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly lockId?: Maybe<Scalars['String']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  /** Column is of type UUID */
  readonly orderRepId?: Maybe<Scalars['String']>;
  readonly infoFrom?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly latestElectronicLocationUpdateDatetime?: Maybe<Scalars['DateTime']>;
  readonly emptyDate?: Maybe<Scalars['DateTime']>;
  readonly latestTrackingRequest?: Maybe<Scalars['DateTime']>;
  readonly outboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentRegionConcatenated?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegionConcatenated?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly originloadStopSequence?: Maybe<Scalars['Int']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Int']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly autoEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly manualEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly driverElectronicTrackingTypeId?: Maybe<Scalars['String']>;
  readonly nextApptStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptStartTime?: Maybe<Scalars['Int']>;
  readonly nextApptEndTime?: Maybe<Scalars['Int']>;
  readonly nextApptStartTimeZone?: Maybe<Scalars['String']>;
  readonly nextApptEndTimeZone?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly bookedById?: Maybe<Scalars['String']>;
  readonly bookedByName?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly nextStopType?: Maybe<Scalars['String']>;
};

export type SeerTrackingPageEdge = {
  readonly __typename?: 'SeerTrackingPageEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTrackingPage;
};

export type SeerTrackingPageFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly nextRoute?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly previousRoute?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly status?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly originTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly startDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly endDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destTz?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly firstStopTz?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destination?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nxtCallBkDtTm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastTrackingNote?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly groupId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerReps?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly connectivity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly originStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly destinationStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubReionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ccaStartStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ccaEndStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly lockId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockExpirationTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly isLocked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  /** Column is of type UUID */
  readonly orderRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly infoFrom?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trackingStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly emptyDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly latestTrackingRequest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentRegionConcatenated?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentRegionConcatenated?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly originloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destinationloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly autoEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly manualEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly bookedById?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly bookedByName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerTrackingPageOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly nextRoute?: InputMaybe<SeerOrderBy>;
  readonly previousRoute?: InputMaybe<SeerOrderBy>;
  readonly status?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly originDate?: InputMaybe<SeerOrderBy>;
  readonly originTime?: InputMaybe<SeerOrderBy>;
  readonly destDate?: InputMaybe<SeerOrderBy>;
  readonly destTime?: InputMaybe<SeerOrderBy>;
  readonly startDate?: InputMaybe<SeerOrderBy>;
  readonly endDate?: InputMaybe<SeerOrderBy>;
  readonly destTz?: InputMaybe<SeerOrderBy>;
  readonly firstStopTz?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly destination?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly nxtCallBkDtTm?: InputMaybe<SeerOrderBy>;
  readonly driverName?: InputMaybe<SeerOrderBy>;
  readonly driverNumber?: InputMaybe<SeerOrderBy>;
  readonly lastTrackingNote?: InputMaybe<SeerOrderBy>;
  readonly createDate?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCity?: InputMaybe<SeerOrderBy>;
  readonly nextLocationState?: InputMaybe<SeerOrderBy>;
  readonly groupId?: InputMaybe<SeerOrderBy>;
  readonly rep?: InputMaybe<SeerOrderBy>;
  readonly routeRepId?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLatitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLongitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCity?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyState?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCountry?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly customerReps?: InputMaybe<SeerOrderBy>;
  readonly connectivity?: InputMaybe<SeerOrderBy>;
  readonly originStopId?: InputMaybe<SeerOrderBy>;
  readonly destinationStopId?: InputMaybe<SeerOrderBy>;
  readonly ibRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly obRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubReionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly routeActivationStatus?: InputMaybe<SeerOrderBy>;
  readonly ccaStartStatus?: InputMaybe<SeerOrderBy>;
  readonly ccaEndStatus?: InputMaybe<SeerOrderBy>;
  readonly lockId?: InputMaybe<SeerOrderBy>;
  readonly lockEmail?: InputMaybe<SeerOrderBy>;
  readonly lockExpirationTime?: InputMaybe<SeerOrderBy>;
  readonly isLocked?: InputMaybe<SeerOrderBy>;
  readonly orderRepId?: InputMaybe<SeerOrderBy>;
  readonly infoFrom?: InputMaybe<SeerOrderBy>;
  readonly trackingStatus?: InputMaybe<SeerOrderBy>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<SeerOrderBy>;
  readonly emptyDate?: InputMaybe<SeerOrderBy>;
  readonly latestTrackingRequest?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegionConcatenated?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentRegionConcatenated?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly originloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly destinationloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly autoEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly manualEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly routeType?: InputMaybe<SeerOrderBy>;
  readonly bookedById?: InputMaybe<SeerOrderBy>;
  readonly bookedByName?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber?: InputMaybe<SeerOrderBy>;
  readonly nextStopType?: InputMaybe<SeerOrderBy>;
};

export type SeerTrackingPageResult = {
  readonly __typename?: 'SeerTrackingPageResult';
  readonly edges: ReadonlyArray<SeerTrackingPageEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTrackingPageV2 = {
  readonly __typename?: 'SeerTrackingPageV2';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  readonly routeNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly rlc?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly nextRoute?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly previousRoute?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly originDate?: Maybe<Scalars['DateTime']>;
  readonly originTime?: Maybe<Scalars['Int']>;
  readonly destDate?: Maybe<Scalars['DateTime']>;
  readonly destTime?: Maybe<Scalars['Int']>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly destTz?: Maybe<Scalars['String']>;
  readonly firstStopTz?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly destination?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly nxtCallBkDtTm?: Maybe<Scalars['DateTime']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverNumber?: Maybe<Scalars['String']>;
  readonly lastTrackingNote?: Maybe<Scalars['String']>;
  readonly createDate?: Maybe<Scalars['DateTime']>;
  readonly nextLocationCity?: Maybe<Scalars['String']>;
  readonly nextLocationState?: Maybe<Scalars['String']>;
  readonly groupId?: Maybe<Scalars['String']>;
  readonly rep?: Maybe<Scalars['String']>;
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly expectedEmptyLatitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyLongitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly expectedEmptyCountry?: Maybe<Scalars['String']>;
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly customerReps?: Maybe<Scalars['String']>;
  readonly connectivity?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly originStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly destinationStopId?: Maybe<Scalars['String']>;
  readonly ibRegionUrn?: Maybe<Scalars['String']>;
  readonly obRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsubReionUrn?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly ccaStartStatus?: Maybe<Scalars['String']>;
  readonly ccaEndStatus?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly lockId?: Maybe<Scalars['String']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  /** Column is of type UUID */
  readonly orderRepId?: Maybe<Scalars['String']>;
  readonly infoFrom?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly latestElectronicLocationUpdateDatetime?: Maybe<Scalars['DateTime']>;
  readonly emptyDate?: Maybe<Scalars['DateTime']>;
  readonly latestTrackingRequest?: Maybe<Scalars['DateTime']>;
  readonly outboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentRegionConcatenated?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegionConcatenated?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly originloadStopSequence?: Maybe<Scalars['Int']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Int']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly autoEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly manualEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly driverElectronicTrackingTypeId?: Maybe<Scalars['String']>;
  readonly nextApptStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptStartTime?: Maybe<Scalars['Int']>;
  readonly nextApptEndTime?: Maybe<Scalars['Int']>;
  readonly nextApptStartTimeZone?: Maybe<Scalars['String']>;
  readonly nextApptEndTimeZone?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly bookedById?: Maybe<Scalars['String']>;
  readonly bookedByName?: Maybe<Scalars['String']>;
};

export type SeerTrackingPageV2Edge = {
  readonly __typename?: 'SeerTrackingPageV2Edge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTrackingPageV2;
};

export type SeerTrackingPageV2Filter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly nextRoute?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly previousRoute?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly status?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly originTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly startDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly endDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destTz?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly firstStopTz?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destination?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nxtCallBkDtTm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastTrackingNote?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly groupId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerReps?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly connectivity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly originStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly destinationStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubReionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ccaStartStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ccaEndStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly lockId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockExpirationTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly isLocked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  /** Column is of type UUID */
  readonly orderRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly infoFrom?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trackingStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly emptyDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly latestTrackingRequest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentRegionConcatenated?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentRegionConcatenated?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly originloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destinationloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly autoEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly manualEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly bookedById?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly bookedByName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerTrackingPageV2OrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly orderActivationStatus?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly nextRoute?: InputMaybe<SeerOrderBy>;
  readonly previousRoute?: InputMaybe<SeerOrderBy>;
  readonly status?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly originDate?: InputMaybe<SeerOrderBy>;
  readonly originTime?: InputMaybe<SeerOrderBy>;
  readonly destDate?: InputMaybe<SeerOrderBy>;
  readonly destTime?: InputMaybe<SeerOrderBy>;
  readonly startDate?: InputMaybe<SeerOrderBy>;
  readonly endDate?: InputMaybe<SeerOrderBy>;
  readonly destTz?: InputMaybe<SeerOrderBy>;
  readonly firstStopTz?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly destination?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly nxtCallBkDtTm?: InputMaybe<SeerOrderBy>;
  readonly driverName?: InputMaybe<SeerOrderBy>;
  readonly driverNumber?: InputMaybe<SeerOrderBy>;
  readonly lastTrackingNote?: InputMaybe<SeerOrderBy>;
  readonly createDate?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCity?: InputMaybe<SeerOrderBy>;
  readonly nextLocationState?: InputMaybe<SeerOrderBy>;
  readonly groupId?: InputMaybe<SeerOrderBy>;
  readonly rep?: InputMaybe<SeerOrderBy>;
  readonly routeRepId?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLatitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLongitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCity?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyState?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCountry?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly customerReps?: InputMaybe<SeerOrderBy>;
  readonly connectivity?: InputMaybe<SeerOrderBy>;
  readonly originStopId?: InputMaybe<SeerOrderBy>;
  readonly destinationStopId?: InputMaybe<SeerOrderBy>;
  readonly ibRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly obRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubReionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly routeActivationStatus?: InputMaybe<SeerOrderBy>;
  readonly ccaStartStatus?: InputMaybe<SeerOrderBy>;
  readonly ccaEndStatus?: InputMaybe<SeerOrderBy>;
  readonly lockId?: InputMaybe<SeerOrderBy>;
  readonly lockEmail?: InputMaybe<SeerOrderBy>;
  readonly lockExpirationTime?: InputMaybe<SeerOrderBy>;
  readonly isLocked?: InputMaybe<SeerOrderBy>;
  readonly orderRepId?: InputMaybe<SeerOrderBy>;
  readonly infoFrom?: InputMaybe<SeerOrderBy>;
  readonly trackingStatus?: InputMaybe<SeerOrderBy>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<SeerOrderBy>;
  readonly emptyDate?: InputMaybe<SeerOrderBy>;
  readonly latestTrackingRequest?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegionConcatenated?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentRegionConcatenated?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly originloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly destinationloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly autoEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly manualEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly routeType?: InputMaybe<SeerOrderBy>;
  readonly bookedById?: InputMaybe<SeerOrderBy>;
  readonly bookedByName?: InputMaybe<SeerOrderBy>;
};

export type SeerTrackingPageV2Result = {
  readonly __typename?: 'SeerTrackingPageV2Result';
  readonly edges: ReadonlyArray<SeerTrackingPageV2Edge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTrackingStopExecution = {
  readonly __typename?: 'SeerTrackingStopExecution';
  /** Column is of type UUID */
  readonly actualId: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly loadId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly loadStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly actualEventType: Scalars['String'];
  /** Column is of type UUID */
  readonly stopId?: Maybe<Scalars['String']>;
  readonly lastUpdatedDateTime?: Maybe<Scalars['DateTime']>;
  readonly actualDateTime?: Maybe<Scalars['DateTime']>;
  readonly actualTimeZone?: Maybe<Scalars['String']>;
};

export type SeerTrackingStopExecutionEdge = {
  readonly __typename?: 'SeerTrackingStopExecutionEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTrackingStopExecution;
};

export type SeerTrackingStopExecutionFilter = {
  /** Column is of type UUID */
  readonly actualId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly actualEventType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly stopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastUpdatedDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly actualDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly actualTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerTrackingStopExecutionOrderBy = {
  readonly actualId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly loadStopId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly actualEventType?: InputMaybe<SeerOrderBy>;
  readonly stopId?: InputMaybe<SeerOrderBy>;
  readonly lastUpdatedDateTime?: InputMaybe<SeerOrderBy>;
  readonly actualDateTime?: InputMaybe<SeerOrderBy>;
  readonly actualTimeZone?: InputMaybe<SeerOrderBy>;
};

export type SeerTrackingStopExecutionResult = {
  readonly __typename?: 'SeerTrackingStopExecutionResult';
  readonly edges: ReadonlyArray<SeerTrackingStopExecutionEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTrackingUpdateAggregations = {
  readonly __typename?: 'SeerTrackingUpdateAggregations';
  readonly actionTypeId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly currentLocationCity?: Maybe<Scalars['String']>;
  readonly currentLocationState?: Maybe<Scalars['String']>;
  readonly currentLocationCountry?: Maybe<Scalars['String']>;
  readonly currentLocationPostalCode?: Maybe<Scalars['String']>;
  readonly currentLocationLatitude?: Maybe<Scalars['Float']>;
  readonly currentLocationLongitude?: Maybe<Scalars['Float']>;
  readonly currentLocation?: Maybe<Scalars['String']>;
  readonly etaDatetime?: Maybe<Scalars['DateTime']>;
  readonly etaTimezone?: Maybe<Scalars['String']>;
  readonly sourceCreatedDatetime?: Maybe<Scalars['DateTime']>;
  readonly sourceCreatedTimezone?: Maybe<Scalars['String']>;
  readonly integrationName?: Maybe<Scalars['String']>;
  readonly lastTrackingNote?: Maybe<Scalars['String']>;
  readonly checkCallDatetime?: Maybe<Scalars['DateTime']>;
  readonly checkCallTimezone?: Maybe<Scalars['String']>;
  readonly nextLocationCity?: Maybe<Scalars['String']>;
  readonly nextLocationState?: Maybe<Scalars['String']>;
  readonly nextLocationCountry?: Maybe<Scalars['String']>;
  readonly nextLocationPostalCode?: Maybe<Scalars['String']>;
  readonly nextLocationLatitude?: Maybe<Scalars['Float']>;
  readonly nextLocationLongitude?: Maybe<Scalars['Float']>;
  readonly nextLocation?: Maybe<Scalars['String']>;
  readonly latestElectronicLocationUpdateDatetime?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly updateId: Scalars['String'];
  readonly eid: Scalars['Int'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByTxId: Scalars['String'];
  readonly updatedByTxId: Scalars['String'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly trackingUpdateCreatedAt?: Maybe<Scalars['DateTime']>;
  readonly trackingUpdateUpdatedAt?: Maybe<Scalars['DateTime']>;
};

export type SeerTrackingUpdateAggregationsEdge = {
  readonly __typename?: 'SeerTrackingUpdateAggregationsEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTrackingUpdateAggregations;
};

export type SeerTrackingUpdateAggregationsFilter = {
  readonly actionTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentLocationCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentLocationPostalCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentLocationLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly currentLocationLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly currentLocation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly etaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly etaTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly sourceCreatedDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly sourceCreatedTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly integrationName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastTrackingNote?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly checkCallDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly checkCallTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationPostalCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly nextLocationLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly nextLocation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly updateId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly createdAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly updatedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly createdByTxId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedByTxId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trackingUpdateCreatedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly trackingUpdateUpdatedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerTrackingUpdateAggregationsOrderBy = {
  readonly actionTypeId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly currentLocationCity?: InputMaybe<SeerOrderBy>;
  readonly currentLocationState?: InputMaybe<SeerOrderBy>;
  readonly currentLocationCountry?: InputMaybe<SeerOrderBy>;
  readonly currentLocationPostalCode?: InputMaybe<SeerOrderBy>;
  readonly currentLocationLatitude?: InputMaybe<SeerOrderBy>;
  readonly currentLocationLongitude?: InputMaybe<SeerOrderBy>;
  readonly currentLocation?: InputMaybe<SeerOrderBy>;
  readonly etaDatetime?: InputMaybe<SeerOrderBy>;
  readonly etaTimezone?: InputMaybe<SeerOrderBy>;
  readonly sourceCreatedDatetime?: InputMaybe<SeerOrderBy>;
  readonly sourceCreatedTimezone?: InputMaybe<SeerOrderBy>;
  readonly integrationName?: InputMaybe<SeerOrderBy>;
  readonly lastTrackingNote?: InputMaybe<SeerOrderBy>;
  readonly checkCallDatetime?: InputMaybe<SeerOrderBy>;
  readonly checkCallTimezone?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCity?: InputMaybe<SeerOrderBy>;
  readonly nextLocationState?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCountry?: InputMaybe<SeerOrderBy>;
  readonly nextLocationPostalCode?: InputMaybe<SeerOrderBy>;
  readonly nextLocationLatitude?: InputMaybe<SeerOrderBy>;
  readonly nextLocationLongitude?: InputMaybe<SeerOrderBy>;
  readonly nextLocation?: InputMaybe<SeerOrderBy>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly updateId?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
  readonly createdAt?: InputMaybe<SeerOrderBy>;
  readonly updatedAt?: InputMaybe<SeerOrderBy>;
  readonly createdByTxId?: InputMaybe<SeerOrderBy>;
  readonly updatedByTxId?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly updatedBy?: InputMaybe<SeerOrderBy>;
  readonly trackingUpdateCreatedAt?: InputMaybe<SeerOrderBy>;
  readonly trackingUpdateUpdatedAt?: InputMaybe<SeerOrderBy>;
};

export type SeerTrackingUpdateAggregationsResult = {
  readonly __typename?: 'SeerTrackingUpdateAggregationsResult';
  readonly edges: ReadonlyArray<SeerTrackingUpdateAggregationsEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTrailer = {
  readonly __typename?: 'SeerTrailer';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly trailerCode?: Maybe<Scalars['String']>;
};

export type SeerTrailerEdge = {
  readonly __typename?: 'SeerTrailerEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTrailer;
};

export type SeerTrailerFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerTrailerOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly trailerCode?: InputMaybe<SeerOrderBy>;
};

export type SeerTrailerResult = {
  readonly __typename?: 'SeerTrailerResult';
  readonly edges: ReadonlyArray<SeerTrailerEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTrailerRouteAssignment = {
  readonly __typename?: 'SeerTrailerRouteAssignment';
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly trailerId: Scalars['String'];
};

export type SeerTrailerRouteAssignmentEdge = {
  readonly __typename?: 'SeerTrailerRouteAssignmentEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTrailerRouteAssignment;
};

export type SeerTrailerRouteAssignmentFilter = {
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly trailerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerTrailerRouteAssignmentOrderBy = {
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly trailerId?: InputMaybe<SeerOrderBy>;
};

export type SeerTrailerRouteAssignmentResult = {
  readonly __typename?: 'SeerTrailerRouteAssignmentResult';
  readonly edges: ReadonlyArray<SeerTrailerRouteAssignmentEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTrailerType = {
  readonly __typename?: 'SeerTrailerType';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly loadId?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly showOnMprbActiveScreens: Scalars['Boolean'];
};

export type SeerTrailerTypeEdge = {
  readonly __typename?: 'SeerTrailerTypeEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTrailerType;
};

export type SeerTrailerTypeFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly showOnMprbActiveScreens?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerTrailerTypeOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly name?: InputMaybe<SeerOrderBy>;
  readonly showOnMprbActiveScreens?: InputMaybe<SeerOrderBy>;
};

export type SeerTrailerTypeResult = {
  readonly __typename?: 'SeerTrailerTypeResult';
  readonly edges: ReadonlyArray<SeerTrailerTypeEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerUser = {
  readonly __typename?: 'SeerUser';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly email?: Maybe<Scalars['String']>;
  readonly isMachine?: Maybe<Scalars['Boolean']>;
};

export type SeerUserEdge = {
  readonly __typename?: 'SeerUserEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerUser;
};

export type SeerUserFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly email?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isMachine?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerUserOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly email?: InputMaybe<SeerOrderBy>;
  readonly isMachine?: InputMaybe<SeerOrderBy>;
};

export type SeerUserResult = {
  readonly __typename?: 'SeerUserResult';
  readonly edges: ReadonlyArray<SeerUserEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerVendorRouteFinancialStatusReason = {
  readonly __typename?: 'SeerVendorRouteFinancialStatusReason';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId?: Maybe<Scalars['String']>;
  readonly financialStatusReasonDescription?: Maybe<Scalars['String']>;
};

export type SeerVendorRouteFinancialStatusReasonEdge = {
  readonly __typename?: 'SeerVendorRouteFinancialStatusReasonEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerVendorRouteFinancialStatusReason;
};

export type SeerVendorRouteFinancialStatusReasonFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly financialStatusReasonDescription?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerVendorRouteFinancialStatusReasonOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly financialStatusReasonDescription?: InputMaybe<SeerOrderBy>;
};

export type SeerVendorRouteFinancialStatusReasonResult = {
  readonly __typename?: 'SeerVendorRouteFinancialStatusReasonResult';
  readonly edges: ReadonlyArray<SeerVendorRouteFinancialStatusReasonEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type Segment = {
  readonly __typename?: 'Segment';
  readonly id: Scalars['ID'];
  readonly defaultValue?: Maybe<Scalars['String']>;
  readonly isRequired: Scalars['Boolean'];
  readonly length: Scalars['Int'];
  readonly segmentNumber: Scalars['Int'];
  readonly segmentSeparator: Scalars['String'];
  readonly segmentType: Scalars['String'];
  readonly tenantDescription: Scalars['String'];
  readonly repType?: Maybe<Scalars['String']>;
};

export type SegmentationConfiguration = {
  readonly __typename?: 'SegmentationConfiguration';
  readonly id: Scalars['ID'];
  readonly segmentationConfigurationLevel: Scalars['String'];
  readonly segmentationConfigurationType: Scalars['String'];
  readonly division?: Maybe<Scalars['String']>;
  readonly isFinalized: Scalars['Boolean'];
  readonly segments?: Maybe<ReadonlyArray<Segment>>;
};

export type SegmentationConfigurationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly segmentationConfigurationLevel: Scalars['String'];
  readonly segmentationConfigurationType: Scalars['String'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly isFinalized: Scalars['Boolean'];
  readonly segments?: InputMaybe<ReadonlyArray<SegmentInput>>;
};

export type SegmentConfigurationInputType = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly order?: InputMaybe<Scalars['Int']>;
  readonly minimumLoopCount?: Scalars['Int'];
  readonly maximumLoopCount?: Scalars['Int'];
  readonly isEditable?: InputMaybe<Scalars['Boolean']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly childSegmentConfigurations?: InputMaybe<ReadonlyArray<InputMaybe<ChildSegmentConfigurationInputType>>>;
  readonly fieldConfigurations: ReadonlyArray<FieldConfigurationInputType>;
};

export type SegmentConfigurationOutputType = {
  readonly __typename?: 'SegmentConfigurationOutputType';
  readonly id?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly order?: Maybe<Scalars['Int']>;
  readonly minimumLoopCount?: Maybe<Scalars['Int']>;
  readonly maximumLoopCount?: Maybe<Scalars['Int']>;
  readonly isEditable?: Maybe<Scalars['Boolean']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly childSegmentConfigurations?: Maybe<ReadonlyArray<Maybe<ChildSegmentConfigurationOutputType>>>;
  readonly fieldConfigurations?: Maybe<ReadonlyArray<Maybe<FieldConfigurationOutputType>>>;
};

export type SegmentInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly defaultValue?: InputMaybe<Scalars['String']>;
  readonly isRequired: Scalars['Boolean'];
  readonly length: Scalars['Int'];
  readonly segmentNumber: Scalars['Int'];
  readonly segmentSeparator: Scalars['String'];
  readonly segmentType: Scalars['String'];
  readonly tenantDescription: Scalars['String'];
  readonly repType?: InputMaybe<Scalars['String']>;
};

export type SegmentValue = {
  readonly id: Scalars['String'];
  readonly value: Scalars['String'];
};

export type SegmentValueOutput = {
  readonly __typename?: 'SegmentValueOutput';
  readonly id: Scalars['ID'];
  readonly value: Scalars['String'];
  readonly dictionaryTermId: Scalars['String'];
  readonly dictionaryTermOptionId: Scalars['String'];
  readonly segmentId: Scalars['ID'];
};

export type SegmentValues = {
  readonly segmentId: Scalars['ID'];
  readonly segmentValues: ReadonlyArray<InputMaybe<SegmentValue>>;
};

export type SendAssetDriverTenderInput = {
  readonly driverTenderId: Scalars['ID'];
  readonly sentByUserId?: InputMaybe<Scalars['ID']>;
};

export type SendDrayNotificationViaEdiInput = {
  readonly tender: LoadTenderInput;
  readonly options?: InputMaybe<LoadTenderOptionsInput>;
};

export type SendDrayNotificationViaEmailInput = {
  readonly tender: LoadTenderInput;
  readonly options?: InputMaybe<LoadTenderOptionsInput>;
  readonly email: LoadTenderEmailInput;
};

export type SendDriverConversationDriverInput = {
  readonly id: Scalars['String'];
};

export type SendDriverConversationInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly subject?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<SendDriverConversationTypeInput>;
  readonly drivers?: InputMaybe<ReadonlyArray<SendDriverConversationDriverInput>>;
  readonly message: SendDriverMessageInput;
  readonly isReadOnly?: InputMaybe<Scalars['Boolean']>;
};

export type SendDriverConversationInputResponse = {
  readonly __typename?: 'SendDriverConversationInputResponse';
  /** @deprecated Field no longer supported */
  readonly conversationId: Scalars['String'];
  readonly messageId: Scalars['String'];
};

export type SendDriverConversationNrbfDriverInput = {
  readonly id: Scalars['String'];
};

export type SendDriverConversationTypeInput = {
  readonly classification: Scalars['String'];
  readonly value?: InputMaybe<Scalars['String']>;
};

export type SendDriverMessageInput = {
  readonly content: Scalars['String'];
  readonly source?: InputMaybe<Scalars['String']>;
  readonly priority?: InputMaybe<Scalars['String']>;
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly sentBy: SendDriverMessageSentByInput;
};

export type SendDriverMessageSentByInput = {
  readonly id: Scalars['String'];
};

export type SendDriverMessageV2Input = {
  readonly subject?: InputMaybe<Scalars['String']>;
  readonly type: SendDriverConversationTypeInput;
  readonly drivers: ReadonlyArray<SendDriverConversationDriverInput>;
  readonly message: SendDriverMessageInput;
  readonly isReadOnly?: InputMaybe<Scalars['Boolean']>;
};

export type SendDriverMessageV2Response = {
  readonly __typename?: 'SendDriverMessageV2Response';
  readonly messageId: Scalars['String'];
};

export type SendExternalAccessDriverMobileAuditInput = {
  readonly id: Scalars['String'];
  readonly driverId: Scalars['String'];
  readonly driverEmailAddress: Scalars['String'];
  readonly driverRole?: InputMaybe<Scalars['String']>;
  readonly driverStatus?: InputMaybe<Scalars['String']>;
  readonly driverInvitationStatus?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly entity_field_name?: InputMaybe<Scalars['String']>;
  readonly display_field_name?: InputMaybe<Scalars['String']>;
  readonly event_action?: InputMaybe<Scalars['String']>;
  readonly previous_value?: InputMaybe<Scalars['String']>;
  readonly updated_value?: InputMaybe<Scalars['String']>;
};

export type SendExternalAccessDriverMobileAuditInputV2 = {
  readonly driverId: Scalars['String'];
  readonly driverEmailAddress: Scalars['String'];
  readonly driverRole?: InputMaybe<Scalars['String']>;
  readonly driverStatus?: InputMaybe<Scalars['String']>;
  readonly driverInvitationStatus?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly entity_type?: InputMaybe<Scalars['String']>;
  readonly sub_entity_type?: InputMaybe<Scalars['String']>;
  readonly entity_field_name?: InputMaybe<Scalars['String']>;
  readonly display_field_name?: InputMaybe<Scalars['String']>;
  readonly event_action?: InputMaybe<Scalars['String']>;
  readonly previous_value?: InputMaybe<Scalars['String']>;
  readonly updated_value?: InputMaybe<Scalars['String']>;
};

export type SendExternalAccessDriverMobileAuditPayload = {
  readonly __typename?: 'SendExternalAccessDriverMobileAuditPayload';
  readonly sentSuccess: Scalars['Boolean'];
  readonly errors: ReadonlyArray<UserError>;
};

export type SendExternalAccessDriverMobileAuditPayloadV2 = {
  readonly __typename?: 'SendExternalAccessDriverMobileAuditPayloadV2';
  readonly sentSuccess: Scalars['Boolean'];
  readonly errors: ReadonlyArray<UserError>;
};

export type SendMessageResponseObj = {
  readonly __typename?: 'sendMessageResponseObj';
  readonly success: Scalars['Boolean'];
  readonly error: Scalars['String'];
};

export type SendMsgInputObj = {
  readonly userList: ReadonlyArray<Scalars['String']>;
  readonly userMessage: Scalars['String'];
  readonly message_id: Scalars['String'];
  readonly server_type?: InputMaybe<Scalars['String']>;
  readonly workspace_id?: InputMaybe<Scalars['String']>;
  readonly sender_id: Scalars['String'];
  readonly created_by_user_id?: InputMaybe<Scalars['String']>;
  readonly created_at?: InputMaybe<Scalars['Float']>;
};

export type SendMsgToSlackInputObj = {
  readonly userId: Scalars['String'];
  readonly objTitle: Scalars['String'];
  readonly objUrl: Scalars['String'];
  readonly userMessage: Scalars['String'];
};

export type SendNrbfDriverConversationInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly subject?: InputMaybe<Scalars['String']>;
  readonly type: SendNrbfDriverConversationTypeInput;
  readonly drivers: ReadonlyArray<SendDriverConversationNrbfDriverInput>;
  readonly message: SendNrbfDriverMessageInput;
  readonly isReadOnly?: InputMaybe<Scalars['Boolean']>;
};

export type SendNrbfDriverConversationTypeInput = {
  readonly classification: Scalars['String'];
  readonly value: Scalars['String'];
};

export type SendNrbfDriverMessageInput = {
  readonly content: Scalars['String'];
  readonly source?: InputMaybe<Scalars['String']>;
  readonly priority?: InputMaybe<Scalars['String']>;
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly sentBy: SendDriverMessageSentByInput;
};

export type SendRailBillingViaEdiInput = {
  readonly tender: LoadTenderInput;
  readonly paymentTermsCode: Scalars['String'];
  readonly serviceCode: Scalars['String'];
  readonly routingCode: Scalars['String'];
  readonly priceAuthority?: InputMaybe<RailBillingPriceAuthorityInput>;
  readonly priceAuthorityV2?: InputMaybe<Scalars['String']>;
  readonly notifyParty: RailBillingAccountInput;
  readonly shipper: RailBillingAccountInput;
  readonly consignee: RailBillingAccountInput;
  readonly partyToReceive: RailBillingAccountInput;
  readonly externalWayBill: Scalars['Boolean'];
};

export type SendRateConfirmationViaEdiInput = {
  readonly tender: LoadTenderInput;
  readonly options?: InputMaybe<LoadTenderOptionsInput>;
};

export type SendRateConfirmationViaEmailInput = {
  readonly tender: LoadTenderInput;
  readonly options?: InputMaybe<LoadTenderOptionsInput>;
  readonly email: LoadTenderEmailInput;
};

export type SendWorkflowError = {
  readonly __typename?: 'SendWorkflowError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type SendWorkflowJob = {
  readonly __typename?: 'SendWorkflowJob';
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  /** JSON value of mapped RouteDetails */
  readonly routeDetails: Scalars['JSON'];
};

export type SendWorkflowJobInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly routeId: Scalars['String'];
  readonly routeCode: Scalars['String'];
  readonly primaryDivision?: InputMaybe<Scalars['String']>;
  readonly primaryDivisionV2?: InputMaybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly sequence: Scalars['Float'];
  readonly isDeclinable: Scalars['Boolean'];
  readonly transmissionDetails?: InputMaybe<TransmissionDetailsInput>;
  readonly drivers?: InputMaybe<ReadonlyArray<WorkflowDriverInput>>;
  readonly trailerTypes?: InputMaybe<ReadonlyArray<WorkflowTrailerTypes>>;
  readonly load?: InputMaybe<WorkflowLoadInput>;
  readonly preplan?: InputMaybe<WorkflowPrePlanInput>;
  readonly orders?: InputMaybe<ReadonlyArray<WorkflowOrders>>;
  readonly totalDistance?: InputMaybe<ReadonlyArray<WorkflowTotalDistanceInput>>;
  readonly totalDist?: InputMaybe<WorkflowTotalDistanceInput>;
  readonly requirements?: InputMaybe<ReadonlyArray<WorkflowSpecialRequirementsInput>>;
  readonly stops?: InputMaybe<ReadonlyArray<WorkflowStopsInput>>;
  readonly jobId?: InputMaybe<Scalars['String']>;
  readonly inboundTrailerNumber?: InputMaybe<Scalars['String']>;
};

export type SendWorkflowJobPayLoad = {
  readonly __typename?: 'SendWorkflowJobPayLoad';
  readonly errors?: Maybe<ReadonlyArray<SendWorkflowError>>;
  readonly sendWorkflowEvent?: Maybe<SendWorkflowJob>;
};

export type SequenceItemTenderOptionsInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly tenderModeTerm?: InputMaybe<Scalars['String']>;
  readonly tenderContacts?: InputMaybe<ReadonlyArray<InputMaybe<TenderOptionsContactInput>>>;
  readonly businessTimeZoneTerm?: InputMaybe<Scalars['String']>;
  readonly businessDays?: InputMaybe<ReadonlyArray<InputMaybe<TenderOptionsBusinessDaysInput>>>;
  readonly tenderExpirationTimeSpan?: InputMaybe<TenderOptionsTimeSpanInput>;
  readonly tenderLeadTimeSpan?: InputMaybe<TenderOptionsTimeSpanInput>;
  readonly autoTender?: InputMaybe<Scalars['Boolean']>;
};

export enum ServiceEnum {
  Gate = 'Gate',
  RampLift = 'RampLift'
}

export type ServiceProfile = {
  readonly __typename?: 'ServiceProfile';
  readonly id: Scalars['ID'];
  readonly serviceProfileId: Scalars['Float'];
  readonly carrierId: Scalars['String'];
  readonly originRamp: Scalars['String'];
  readonly destinationRamp: Scalars['String'];
  readonly train?: Maybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly isCOFC?: Maybe<Scalars['Boolean']>;
  readonly isTOFC?: Maybe<Scalars['Boolean']>;
  /** @deprecated removed this field */
  readonly publishedTransitTime?: Maybe<Scalars['String']>;
  readonly publishedTransitDays: Scalars['Float'];
  readonly reqOrigin?: Maybe<Scalars['Boolean']>;
  readonly reqLane?: Maybe<Scalars['Boolean']>;
  readonly boxType?: Maybe<Scalars['String']>;
  readonly laneStartDate: Scalars['DateTime'];
  /** @deprecated reason: Use laneExpirationDateV2 field became nullable */
  readonly laneExpirationDate: Scalars['DateTime'];
  readonly laneExpirationDateV2?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createDate?: Maybe<Scalars['DateTime']>;
  readonly updateDate: Scalars['DateTime'];
  readonly schedules: Schedules;
  readonly originRampFacility: Facility;
  readonly destinationRampFacility: Facility;
  readonly createdByUser: User;
  readonly updatedByUser: User;
  readonly originRampFacilityV2: FacilityV2;
  readonly destinationRampFacilityV2: FacilityV2;
  readonly createdByUserV2: UserV2;
  readonly updatedByUserV2: UserV2;
  readonly carrier: Carrier;
  readonly carrierV2: CarrierV2;
};

export type ServiceProfileUploadEventPayload = {
  readonly __typename?: 'ServiceProfileUploadEventPayload';
  readonly status?: Maybe<Scalars['String']>;
  readonly workbookId?: Maybe<Scalars['String']>;
};

export type ServiceProfileV2 = {
  readonly __typename?: 'ServiceProfileV2';
  readonly id: Scalars['String'];
  readonly serviceProfileId: Scalars['Float'];
  readonly carrierId: Scalars['String'];
  readonly train?: Maybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly isCOFC?: Maybe<Scalars['Boolean']>;
  readonly isTOFC?: Maybe<Scalars['Boolean']>;
  readonly publishedTransitDays: Scalars['Float'];
  readonly reqOrigin?: Maybe<Scalars['Boolean']>;
  readonly reqLane?: Maybe<Scalars['Boolean']>;
  readonly boxType?: Maybe<Scalars['String']>;
  readonly laneStartDate: Scalars['DateTime'];
  readonly laneExpirationDate?: Maybe<Scalars['DateTime']>;
  readonly schedules?: Maybe<SchedulesV2>;
  readonly createDate?: Maybe<Scalars['DateTime']>;
  readonly updateDate?: Maybe<Scalars['DateTime']>;
  readonly originRampFacility: Facility;
  readonly destinationRampFacility: Facility;
  readonly createdByUser: User;
  readonly updatedByUser: User;
  readonly originRampFacilityV2: FacilityV2;
  readonly destinationRampFacilityV2: FacilityV2;
  readonly createdByUserV2: UserV2;
  readonly updatedByUserV2: UserV2;
  readonly carrier: Carrier;
  readonly carrierV2: CarrierV2;
};

export enum ServiceSource {
  /** Events that are Owned or Serviced through Minion Portal */
  MinionPortal = 'MINION_PORTAL',
  /** Events that are Owned or Serviced through Hayk Rules Engine */
  HaykRulesEngine = 'HAYK_RULES_ENGINE',
  /** Events that are Owned or Serviced by domain team */
  Integration = 'INTEGRATION'
}

export type ServiceStatusDetailsInput = {
  readonly serviceStatusReasonCodeTerm?: InputMaybe<Scalars['String']>;
  readonly serviceStatusFacilityId?: InputMaybe<Scalars['ID']>;
  readonly serviceStatusNotes?: InputMaybe<Scalars['String']>;
};

export type ServiceStatusDetailsOutput = {
  readonly __typename?: 'ServiceStatusDetailsOutput';
  readonly serviceStatusReasonCodeTerm?: Maybe<Scalars['String']>;
  readonly serviceStatusFacilityId?: Maybe<Scalars['ID']>;
  /** This field describes the facility to which the trailer belongs to. */
  readonly facility?: Maybe<FacilityV2>;
  readonly serviceStatusNotes?: Maybe<Scalars['String']>;
};

export type ServiceTolerance = {
  readonly __typename?: 'ServiceTolerance';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['String'];
  readonly stopType: Scalars['String'];
  readonly units: Scalars['String'];
  readonly earlyPickup?: Maybe<Scalars['Int']>;
  readonly latePickup?: Maybe<Scalars['Int']>;
  readonly earlyDelivery?: Maybe<Scalars['Int']>;
  readonly lateDelivery?: Maybe<Scalars['Int']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
};

export type ServiceToleranceConnection = {
  readonly __typename?: 'ServiceToleranceConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<ServiceToleranceEdge>;
};

export type ServiceToleranceEdge = {
  readonly __typename?: 'ServiceToleranceEdge';
  readonly node: ServiceTolerance;
  readonly cursor: Scalars['String'];
};

export type ServiceToleranceFilter = {
  /** customerId to filter by */
  readonly customerId?: InputMaybe<Scalars['ID']>;
};

export type ShipLocation = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly stateAbbreviation?: InputMaybe<Scalars['String']>;
  readonly zipCode: Scalars['String'];
  readonly country?: InputMaybe<Scalars['String']>;
};

export type Shipment = {
  readonly __typename?: 'Shipment';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly order?: Maybe<ReadonlyArray<CustomerOrderV2>>;
  readonly assignedRouteStopIds: ReadonlyArray<Scalars['String']>;
  /** @deprecated no longer supported, stopValues will be on the shipmentCommodity */
  readonly pickupStop?: Maybe<OrderStop>;
  /** @deprecated no longer supported, stopValues will be on the shipmentCommodit */
  readonly deliveryStop?: Maybe<OrderStop>;
  readonly shipmentCommodities?: Maybe<ReadonlyArray<ShipmentCommodity>>;
  /** @deprecated no longer supported, use shipmentCommodities */
  readonly commodities?: Maybe<ReadonlyArray<CommodityV2>>;
  readonly flattenedCommodities: ReadonlyArray<AssignedRouteStopShipmentFlattenedCommodity>;
};

export type ShipmentCommodity = {
  readonly __typename?: 'ShipmentCommodity';
  readonly id: Scalars['ID'];
  readonly commodity?: Maybe<CommodityV2>;
  readonly shipmentName?: Maybe<Scalars['String']>;
  readonly shipment?: Maybe<Shipment>;
  readonly packagingCount?: Maybe<Scalars['Int']>;
  readonly packagingType?: Maybe<KeyValue>;
  readonly expectedWeightV2?: Maybe<MassV2>;
  readonly pieceType?: Maybe<KeyValue>;
  readonly expectedPieces?: Maybe<Scalars['Int']>;
  readonly actualPieces?: Maybe<Scalars['Int']>;
  readonly actualWeightV2?: Maybe<MassV2>;
};


export type ShipmentCommodityExpectedWeightV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type ShipmentCommodityActualWeightV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};

export type ShipmentCommodityInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly shipmentName?: InputMaybe<Scalars['String']>;
  readonly shipmentId?: InputMaybe<Scalars['ID']>;
  readonly packagingCount?: InputMaybe<Scalars['Int']>;
  readonly packagingTypeId?: InputMaybe<Scalars['ID']>;
  /** either expectedWeight or expectedWeightV2 is required */
  readonly expectedWeightV2?: InputMaybe<UnitOfMassInputV2>;
  readonly pieceTypeId?: InputMaybe<Scalars['ID']>;
  readonly expectedPieces?: InputMaybe<Scalars['Int']>;
  readonly actualWeightV2?: InputMaybe<UnitOfMassInputV2>;
  readonly actualPieces?: InputMaybe<Scalars['Int']>;
};

export type ShipmentInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly name: Scalars['String'];
};

export type SidebarLoadSearch = {
  readonly __typename?: 'SidebarLoadSearch';
  readonly id: Scalars['ID'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
};

export type SidebarLoadSearchConnection = {
  readonly __typename?: 'SidebarLoadSearchConnection';
  readonly edges: ReadonlyArray<SidebarLoadSearchEdge>;
  readonly pageInfo: PageInfo;
};

export type SidebarLoadSearchEdge = {
  readonly __typename?: 'SidebarLoadSearchEdge';
  readonly node: SidebarLoadSearch;
  readonly cursor: Scalars['String'];
};

export type SidebarLoadSearchFilterInput = {
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly orderNumber?: InputMaybe<Scalars['String']>;
};

export type SingleBulkGeographyRequest = {
  readonly requestId: Scalars['String'];
  readonly countryCode?: InputMaybe<Scalars['String']>;
  readonly adminArea?: InputMaybe<Scalars['String']>;
  readonly adminArea2?: InputMaybe<Scalars['String']>;
  readonly locality?: InputMaybe<Scalars['String']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
};

export type SingleBulkGeographyResponse = {
  readonly __typename?: 'SingleBulkGeographyResponse';
  readonly requestId: Scalars['String'];
  readonly sourceId: Scalars['Int'];
  readonly country: Scalars['String'];
  readonly county?: Maybe<Scalars['String']>;
  readonly postalCodes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly lat?: Maybe<Scalars['Float']>;
  readonly lon?: Maybe<Scalars['Float']>;
  readonly locality?: Maybe<Scalars['String']>;
  readonly adminArea?: Maybe<Scalars['String']>;
  readonly adminArea2?: Maybe<Scalars['String']>;
  readonly abbreviatedState?: Maybe<Scalars['String']>;
};

export type SingleLineBulkRequestInput = {
  readonly requestId: Scalars['String'];
  readonly addressLine1: Scalars['String'];
  readonly country: Scalars['String'];
  readonly geocodeRequest?: InputMaybe<Scalars['Boolean']>;
};

export type SingleLineRequest = {
  readonly addressLine1: Scalars['String'];
  readonly country: Scalars['String'];
  readonly geocodeRequest?: InputMaybe<Scalars['Boolean']>;
};

export type SingleLineResult = FormattedAddress | InvalidAddress;

export type SingleUserInputObj = {
  readonly userId: Scalars['String'];
  readonly userName?: InputMaybe<Scalars['String']>;
  readonly userMessage: Scalars['String'];
};

export enum Size {
  Default = 'Default',
  Ftl = 'Ftl'
}

export type SizeR2 = {
  readonly __typename?: 'SizeR2';
  readonly id?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
};

/** DEPRECATED: Use LoadSize DDT instead. */
export enum SizeTypesEnum {
  Tl = 'TL',
  Ltl = 'LTL'
}

export type SniGenericInput = {
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly projectTerm?: InputMaybe<Scalars['String']>;
  readonly modeTerm: Scalars['String'];
  readonly sizeTerm?: InputMaybe<Scalars['String']>;
  readonly carrierScac?: InputMaybe<Scalars['String']>;
  readonly service?: InputMaybe<Scalars['String']>;
  readonly equipment?: InputMaybe<Equipment>;
  readonly requirementTerms?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly stops: ReadonlyArray<InputMaybe<SniQuoteStop>>;
};

export type SniQuoteDimensions = {
  readonly value?: InputMaybe<Scalars['Float']>;
  readonly unitTerm?: InputMaybe<Scalars['String']>;
};

export type SniQuoteOutput = {
  readonly __typename?: 'SniQuoteOutput';
  readonly correlationId: Scalars['String'];
  readonly timestamp?: Maybe<Scalars['DateTime']>;
  readonly status: Scalars['Int'];
  readonly error?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly path?: Maybe<Scalars['String']>;
  readonly sniResponse?: Maybe<SniResponse>;
};

export type SniQuoteStop = {
  readonly sequence: Scalars['Int'];
  readonly stopTypeTerm: StopTypeTerm;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country: Country;
  readonly postalCode: Scalars['String'];
  readonly region?: InputMaybe<Scalars['String']>;
  readonly timespanStart?: InputMaybe<Scalars['DateTime']>;
  readonly timespanEnd?: InputMaybe<Scalars['DateTime']>;
  readonly stopRequirementTerms?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type SniResponse = {
  readonly __typename?: 'SniResponse';
  readonly customerRequestId?: Maybe<Scalars['String']>;
  readonly solutions?: Maybe<ReadonlyArray<Maybe<Solutions>>>;
  readonly messages?: Maybe<ReadonlyArray<Maybe<Message>>>;
};

export type SoftAllocateCarrierCapacityInput = {
  readonly costQuoteId: Scalars['ID'];
  readonly pickupDate: Scalars['DateTime'];
  readonly routeId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
};

export type SoftAllocateCarrierCapacityType = {
  readonly __typename?: 'SoftAllocateCarrierCapacityType';
  readonly success?: Maybe<Scalars['Boolean']>;
  readonly carrierCapacityRemaining?: Maybe<CarrierCapacityRemaining>;
};

export type Solutions = {
  readonly __typename?: 'Solutions';
  readonly quoteId?: Maybe<Scalars['String']>;
  readonly quoteExpiration?: Maybe<Scalars['String']>;
  readonly scac?: Maybe<Scalars['String']>;
  readonly lineHaul?: Maybe<Scalars['String']>;
  readonly fuel?: Maybe<Scalars['String']>;
  readonly accessorials?: Maybe<Scalars['String']>;
  readonly totalPrice?: Maybe<Scalars['String']>;
  readonly startDateTime?: Maybe<Scalars['String']>;
  readonly endDateTime?: Maybe<Scalars['String']>;
  readonly transitDays?: Maybe<Scalars['String']>;
  readonly accessorialList?: Maybe<ReadonlyArray<Maybe<Accessorial>>>;
};

export type SortingOptions = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly orderBy?: InputMaybe<Scalars['String']>;
};

export enum SourceTypeEnum {
  /** api */
  Api = 'api',
  /** dat */
  Dat = 'dat',
  /** email */
  Email = 'email',
  /** manual */
  Manual = 'manual'
}

/** DEPRECATED: Identifies the method by which a TruckEntry was created */
export enum SourceTypesEnum {
  Api = 'api',
  AutoTruck = 'autoTruck',
  Dat = 'dat',
  Email = 'email',
  Manual = 'manual'
}

export type SpeedMapDat = {
  readonly location: Scalars['String'];
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
  readonly mph: Scalars['Float'];
};

export type SplitRouteAtStopInput = {
  readonly routeStopId: Scalars['ID'];
  readonly preview?: InputMaybe<Scalars['Boolean']>;
};

export type SplitRouteMap = {
  readonly __typename?: 'SplitRouteMap';
  readonly routes: ReadonlyArray<RoutePreviewType>;
  readonly warnings?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly changed?: Maybe<Scalars['String']>;
};

export type SplitRouteStopInput = {
  readonly routeId: Scalars['ID'];
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly address?: InputMaybe<CityStateStopLocationInput>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
  readonly isOrderStop?: InputMaybe<Scalars['Boolean']>;
  /** type is deprecated; use loadStopType instead */
  readonly type?: InputMaybe<StopTypeEnum>;
  readonly loadStopType?: InputMaybe<Scalars['String']>;
  readonly loadFromId?: InputMaybe<Scalars['ID']>;
  readonly unloadFromId?: InputMaybe<Scalars['ID']>;
  readonly requirements?: InputMaybe<ReadonlyArray<StopRequirementInput>>;
  readonly commodities?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly orderStopId?: InputMaybe<Scalars['ID']>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInputV2>>;
  readonly reqDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly bolNumber?: InputMaybe<Scalars['String']>;
  readonly sealNumber?: InputMaybe<Scalars['String']>;
  readonly schedulingContactId?: InputMaybe<Scalars['ID']>;
  readonly apptConfBy?: InputMaybe<Scalars['String']>;
  readonly apptReqWith?: InputMaybe<Scalars['String']>;
  /** Stop Unique Identifier for external system */
  readonly externalRef?: InputMaybe<Scalars['String']>;
  readonly appointmentChangeReason?: InputMaybe<Scalars['String']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  readonly isCreatedManually?: InputMaybe<Scalars['Boolean']>;
  readonly splc?: InputMaybe<Scalars['String']>;
  /** PU/Del Codes from Customer-Facility Relationship */
  readonly externalFacilityId?: InputMaybe<Scalars['String']>;
  readonly assignedShipments?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverTargetStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly driverTargetEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly driverTargetStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly driverTargetEndTimes?: InputMaybe<ScheduleRangeInput>;
};

export type SplitRouteStopMapError = {
  readonly __typename?: 'SplitRouteStopMapError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly serviceError?: Maybe<Scalars['JSON']>;
};

export type SplitRouteStopPayload = {
  readonly __typename?: 'SplitRouteStopPayload';
  readonly errors: ReadonlyArray<SplitRouteStopMapError>;
  readonly routeMaps?: Maybe<SplitRouteMap>;
};

export type SpotQuote = {
  readonly __typename?: 'SpotQuote';
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  /** @deprecated Use customerV2 instead */
  readonly customer: Customer;
  readonly customerV2: CustomerV2;
  readonly lane: LaneV2;
  readonly isLaneDeactivated?: Maybe<Scalars['Boolean']>;
  readonly stops: ReadonlyArray<SpotQuoteStop>;
  readonly loadCount: Scalars['Int'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate: Scalars['Date'];
  readonly effectiveDateTime?: Maybe<Scalars['DateTime']>;
  readonly expirationDateTime?: Maybe<Scalars['DateTime']>;
  readonly shipStartDate: Scalars['Date'];
  readonly shipEndDate: Scalars['Date'];
  readonly desiredDeliveryDate?: Maybe<Scalars['Date']>;
  readonly quotedBy?: Maybe<UserV2>;
  readonly quotedTo?: Maybe<CustomerContact>;
  /** The Minion tenantScac's OptionId */
  readonly scac?: Maybe<Scalars['String']>;
  readonly externalQuoteId?: Maybe<Scalars['String']>;
  readonly multiStop: Scalars['Boolean'];
  /** The Minion quote creation source's OptionIds */
  readonly creationSource: Scalars['String'];
  /** The Minion orderRequirement's OptionIds */
  readonly requirements: ReadonlyArray<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['DateTime'];
  readonly createdBy: UserV2;
  readonly updatedOn: Scalars['DateTime'];
  readonly updatedBy: UserV2;
  readonly baseRate: Scalars['Decimal'];
  readonly fuel: Scalars['Decimal'];
  readonly extras: Scalars['Decimal'];
  readonly totalRate: Scalars['Decimal'];
  readonly quoteDetails?: Maybe<ReadonlyArray<SpotQuoteQuoteDetail>>;
  readonly commodities?: Maybe<ReadonlyArray<SpotQuoteCommodity>>;
  readonly isVariableDistanceType: Scalars['Boolean'];
  readonly customDistance?: Maybe<Scalars['Decimal']>;
  readonly customDistanceUnit?: Maybe<Scalars['String']>;
  /** The Minion Quote Fuel Configuration's OptionIds */
  readonly quoteFuelConfiguration: Scalars['String'];
  /** The IANA timezone for the effective and expiration dates */
  readonly timezone: Scalars['String'];
  readonly awardedCount: Scalars['Int'];
};

export type SpotQuoteCarrierErrorInput = {
  readonly code: Scalars['String'];
};

export type SpotQuoteCommodity = {
  readonly __typename?: 'SpotQuoteCommodity';
  readonly id: Scalars['ID'];
  readonly description?: Maybe<Scalars['String']>;
  readonly stccCode?: Maybe<Scalars['String']>;
  readonly customerCode?: Maybe<Scalars['String']>;
  readonly doNotStack?: Maybe<Scalars['Boolean']>;
  readonly handlingUnit?: Maybe<Scalars['String']>;
  readonly packagingCount?: Maybe<Scalars['Int']>;
  readonly loadOnType?: Maybe<Scalars['String']>;
  readonly isTopLoadable?: Maybe<Scalars['Boolean']>;
  readonly isBottomLoadable?: Maybe<Scalars['Boolean']>;
  readonly expectedWeight?: Maybe<SpotQuoteMass>;
  readonly expectedPieces?: Maybe<Scalars['Int']>;
  readonly pieceType?: Maybe<Scalars['String']>;
  readonly isTemperatureControlled?: Maybe<Scalars['Boolean']>;
  readonly preCoolTo?: Maybe<SpotQuoteTemperature>;
  readonly minimumTemperature?: Maybe<SpotQuoteTemperature>;
  readonly maximumTemperature?: Maybe<SpotQuoteTemperature>;
  readonly temperatureSetting?: Maybe<Scalars['String']>;
  readonly isHazmat?: Maybe<Scalars['Boolean']>;
  readonly hazmatClass?: Maybe<Scalars['String']>;
  readonly hazmatPackagingGroup?: Maybe<Scalars['String']>;
  readonly hazmatUNNumber?: Maybe<Scalars['String']>;
  readonly hazmatPhoneNumber?: Maybe<Scalars['String']>;
  readonly isOverDimensional?: Maybe<Scalars['Boolean']>;
  readonly expectedDimensions?: Maybe<SpotQuoteDimensions>;
  readonly expectedLinear?: Maybe<SpotQuoteLength>;
  readonly expectedVolume?: Maybe<SpotQuoteVolume>;
  readonly expectedDensity?: Maybe<SpotQuoteDensity>;
  readonly expectedFreightClass?: Maybe<Scalars['String']>;
  readonly expectedNMFCCode?: Maybe<Scalars['String']>;
};

export type SpotQuoteCommodityFilterInput = {
  readonly handlingUnit?: InputMaybe<Scalars['String']>;
  readonly packagingCount?: InputMaybe<Scalars['Int']>;
  readonly expectedWeight: SpotQuoteMassInput;
  readonly expectedFreightClass: Scalars['String'];
};

export type SpotQuoteCommodityInput = {
  readonly description: Scalars['String'];
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly doNotStack?: InputMaybe<Scalars['Boolean']>;
  readonly handlingUnit?: InputMaybe<Scalars['String']>;
  readonly packagingCount?: InputMaybe<Scalars['Int']>;
  readonly loadOnType?: InputMaybe<Scalars['String']>;
  readonly isTopLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly isBottomLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly useMetric?: InputMaybe<Scalars['Boolean']>;
  readonly expectedWeight: SpotQuoteMassInput;
  readonly expectedPieces?: InputMaybe<Scalars['Int']>;
  readonly pieceType?: InputMaybe<Scalars['String']>;
  readonly isTemperatureControlled?: InputMaybe<Scalars['Boolean']>;
  readonly preCoolTo?: InputMaybe<SpotQuoteTemperatureInput>;
  readonly minimumTemperature?: InputMaybe<SpotQuoteTemperatureInput>;
  readonly maximumTemperature?: InputMaybe<SpotQuoteTemperatureInput>;
  readonly temperatureSetting?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly hazmatClass?: InputMaybe<Scalars['String']>;
  readonly hazmatPackagingGroup?: InputMaybe<Scalars['String']>;
  readonly hazmatUNNumber?: InputMaybe<Scalars['String']>;
  readonly hazmatPhoneNumber?: InputMaybe<Scalars['String']>;
  readonly isOverDimensional?: InputMaybe<Scalars['Boolean']>;
  readonly expectedDimensions?: InputMaybe<SpotQuoteDimensionsInput>;
  readonly expectedLinear?: InputMaybe<SpotQuoteLengthInput>;
  readonly expectedVolume?: InputMaybe<SpotQuoteVolumeInput>;
  readonly expectedDensity?: InputMaybe<SpotQuoteDensityInput>;
  readonly expectedFreightClass: Scalars['String'];
  readonly expectedNMFCCode?: InputMaybe<Scalars['String']>;
};

export type SpotQuoteCommonFilterInput = {
  /** The IANA timezone used for any dates. If not provided, America/Chicago will be used as a default. */
  readonly timezone?: InputMaybe<Scalars['String']>;
  /** Limits to effective, non-expired results. Uses timezone for date comparison. */
  readonly activeOnly?: InputMaybe<Scalars['Boolean']>;
  /** Filter on the creation date within the start/end range. Uses timezone for date comparison. */
  readonly createdDate?: InputMaybe<SpotQuoteDateRangeInput>;
  /** A valid User ID is expected. */
  readonly quotedById?: InputMaybe<Scalars['ID']>;
};

export type SpotQuoteCommonLaneFilterInput = {
  /** The Minion transportMode's OptionId */
  readonly transportMode?: InputMaybe<Scalars['String']>;
  /** The Minion loadSize's OptionId */
  readonly loadSize?: InputMaybe<Scalars['String']>;
  /** The Minion division's OptionId */
  readonly division?: InputMaybe<Scalars['String']>;
  /** The Minion serviceLevel's OptionId */
  readonly serviceLevel?: InputMaybe<Scalars['String']>;
  /** Length in feet */
  readonly length?: InputMaybe<Scalars['Int']>;
  /** Width in inches */
  readonly width?: InputMaybe<Scalars['Int']>;
  /** Height in inches */
  readonly height?: InputMaybe<Scalars['Int']>;
  /** The Minion trailerType's OptionIds */
  readonly equipments?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStop?: InputMaybe<SpotQuoteCommonLaneStopFilterInput>;
  readonly destinationStop?: InputMaybe<SpotQuoteCommonLaneStopFilterInput>;
};

export type SpotQuoteCommonLaneStopFilterInput = {
  /** The Minion stopType's OptionId. */
  readonly stopType?: InputMaybe<Scalars['String']>;
  readonly stopFacility?: InputMaybe<SpotQuoteFacilityStopInput>;
  readonly stopPostalCode?: InputMaybe<SpotQuotePostalCodeStopInput>;
  readonly stopCity?: InputMaybe<SpotQuoteGeographyStopInput>;
};

export type SpotQuoteConnection = {
  readonly __typename?: 'SpotQuoteConnection';
  readonly edges: ReadonlyArray<Maybe<SpotQuoteEdge>>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type SpotQuoteCostLineItem = {
  readonly __typename?: 'SpotQuoteCostLineItem';
  /** The new ChargeType entity Id or old Minion chargeType OptionId */
  readonly type: Scalars['String'];
  readonly costPerUnit: Scalars['Decimal'];
  readonly units: Scalars['Decimal'];
  readonly totalCost: Scalars['Decimal'];
  /** The Minion currency's OptionId */
  readonly currency: Scalars['String'];
  readonly sequence: Scalars['Int'];
};

export type SpotQuoteCostLineItemInput = {
  /** The new ChargeType entity Id or old Minion chargeType OptionId */
  readonly type: Scalars['String'];
  readonly costPerUnit: Scalars['Decimal'];
  readonly units: Scalars['Decimal'];
  /** The Minion currency's OptionId */
  readonly currency: Scalars['String'];
};

export type SpotQuoteCountryStopInput = {
  /** Two-character country code (e.g. US, MX, CA) */
  readonly countryCode: Scalars['String'];
};

export type SpotQuoteDateRangeInput = {
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
};

export type SpotQuoteDensity = {
  readonly __typename?: 'SpotQuoteDensity';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type SpotQuoteDensityInput = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type SpotQuoteDimensions = {
  readonly __typename?: 'SpotQuoteDimensions';
  readonly height?: Maybe<SpotQuoteLength>;
  readonly length?: Maybe<SpotQuoteLength>;
  readonly width?: Maybe<SpotQuoteLength>;
};

export type SpotQuoteDimensionsInput = {
  readonly height?: InputMaybe<SpotQuoteLengthInput>;
  readonly length?: InputMaybe<SpotQuoteLengthInput>;
  readonly width?: InputMaybe<SpotQuoteLengthInput>;
};

export type SpotQuoteDistanceInput = {
  /** Only supports [miles, kilometers] */
  readonly unit: Scalars['String'];
  readonly value: Scalars['Decimal'];
};

export type SpotQuoteEdge = {
  readonly __typename?: 'SpotQuoteEdge';
  readonly cursor: Scalars['String'];
  readonly node: SpotQuote;
};

export type SpotQuoteError = {
  readonly __typename?: 'SpotQuoteError';
  readonly message: Scalars['String'];
  readonly code: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type SpotQuoteFacilityStopInput = {
  readonly facilityId: Scalars['ID'];
};

export type SpotQuoteFilterInput = {
  /** The Minion transportMode's OptionId */
  readonly transportMode?: InputMaybe<Scalars['String']>;
  /** The Minion loadSize's OptionId */
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly stops?: InputMaybe<ReadonlyArray<SpotQuoteStopFilterInput>>;
  readonly commodities?: InputMaybe<ReadonlyArray<SpotQuoteCommodityFilterInput>>;
  /** The Minion trailerType's OptionIds */
  readonly equipments?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly effectiveDate?: InputMaybe<Scalars['Date']>;
  readonly effectiveDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly shipStartDate?: InputMaybe<Scalars['Date']>;
  readonly shipEndDate?: InputMaybe<Scalars['Date']>;
  /** The Minion serviceLevel's OptionId */
  readonly serviceLevel?: InputMaybe<Scalars['String']>;
  /** Length in feet */
  readonly length?: InputMaybe<Scalars['Int']>;
  /** Width in inches */
  readonly width?: InputMaybe<Scalars['Int']>;
  /** Height in inches */
  readonly height?: InputMaybe<Scalars['Int']>;
  /** The Minion division's OptionId */
  readonly division?: InputMaybe<Scalars['String']>;
  /** The Minion project's OptionId */
  readonly project?: InputMaybe<Scalars['String']>;
  /** The Minion businessUnit's OptionId */
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  /** The Minion tenantScac's OptionId */
  readonly scac?: InputMaybe<Scalars['String']>;
  /** The Minion orderRequirement's OptionIds */
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly laneType?: InputMaybe<Scalars['String']>;
  /** The IANA timezone for the effective and expiration dates */
  readonly timezone?: InputMaybe<Scalars['String']>;
};

export type SpotQuoteGeographyStopInput = {
  readonly geographySourceId: Scalars['Int'];
};

export type SpotQuoteHitRate = {
  readonly __typename?: 'SpotQuoteHitRate';
  readonly quotesCreated?: Maybe<Scalars['Int']>;
  readonly quotesAwarded?: Maybe<Scalars['Int']>;
  readonly averageWinRate?: Maybe<Scalars['Decimal']>;
  readonly averageMargin?: Maybe<Scalars['Decimal']>;
};

export type SpotQuoteHitRateInput = {
  /** Include to filter to a matching lane or null to return for all lanes */
  readonly spotQuoteFilter?: InputMaybe<SpotQuoteFilterInput>;
  /** Include to filter by only the specified customerId or null to return all customers */
  readonly customerId?: InputMaybe<Scalars['ID']>;
  /** Date range to filter by */
  readonly filterDate: Scalars['Date'];
};

export type SpotQuoteHitRatePayload = {
  readonly __typename?: 'SpotQuoteHitRatePayload';
  readonly data?: Maybe<SpotQuoteHitRate>;
  readonly errors?: Maybe<ReadonlyArray<SpotQuoteError>>;
};

export type SpotQuoteLength = {
  readonly __typename?: 'SpotQuoteLength';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type SpotQuoteLengthInput = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type SpotQuoteMass = {
  readonly __typename?: 'SpotQuoteMass';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type SpotQuoteMassInput = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type SpotQuotePaginatedFilterInput = {
  readonly spotQuoteFilter?: InputMaybe<SpotQuoteFilterInput>;
  /** Include to filter by only the specified customerId or null to return all customers */
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly includeExpired?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadHeadDistance?: InputMaybe<SpotQuoteDistanceInput>;
  readonly destinationDeadHeadDistance?: InputMaybe<SpotQuoteDistanceInput>;
  /** Used to determine whether spot quotes are expired */
  readonly timezone: Scalars['String'];
};

export type SpotQuotePaginatedInput = {
  readonly filter: SpotQuotePaginatedFilterInput;
  readonly after?: InputMaybe<Scalars['String']>;
  readonly first?: InputMaybe<Scalars['Int']>;
};

export type SpotQuotePostalCodeStopInput = {
  /** Two-character country code (e.g. US, MX, CA) */
  readonly countryCode: Scalars['String'];
  readonly postalCode: Scalars['String'];
};

export type SpotQuotePricingInput = {
  readonly spotQuote: SpotQuoteQuoteInput;
  readonly commodities?: InputMaybe<ReadonlyArray<SpotQuoteQuoteCommodityInput>>;
};

export type SpotQuotePricingPayload = {
  readonly __typename?: 'SpotQuotePricingPayload';
  readonly data?: Maybe<ReadonlyArray<SpotQuoteQuoteDetail>>;
  readonly errors?: Maybe<ReadonlyArray<SpotQuoteError>>;
};

export type SpotQuoteQuoteCarrierInput = {
  readonly carrierId: Scalars['ID'];
  readonly costLineItems?: InputMaybe<ReadonlyArray<SpotQuoteCostLineItemInput>>;
  readonly interlineStatus?: InputMaybe<Scalars['String']>;
  readonly transitDays?: InputMaybe<Scalars['Int']>;
  readonly pickupDate?: InputMaybe<Scalars['DateTime']>;
  readonly deliveryDate?: InputMaybe<Scalars['DateTime']>;
  readonly externalCarrierQuoteId?: InputMaybe<Scalars['String']>;
  readonly carrierMarkedDoNotUse?: InputMaybe<Scalars['Boolean']>;
  readonly interlineStatusDoesNotMatch?: InputMaybe<Scalars['Boolean']>;
  readonly errors?: InputMaybe<ReadonlyArray<SpotQuoteCarrierErrorInput>>;
};

export type SpotQuoteQuoteCommodityInput = {
  readonly description: Scalars['String'];
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly doNotStack?: InputMaybe<Scalars['Boolean']>;
  readonly handlingUnit?: InputMaybe<Scalars['String']>;
  readonly packagingCount?: InputMaybe<Scalars['Int']>;
  readonly loadOnType?: InputMaybe<Scalars['String']>;
  readonly isTopLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly isBottomLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly useMetric?: InputMaybe<Scalars['Boolean']>;
  readonly expectedWeight: SpotQuoteMassInput;
  readonly expectedPieces?: InputMaybe<Scalars['Int']>;
  readonly pieceType?: InputMaybe<Scalars['String']>;
  readonly isTemperatureControlled?: InputMaybe<Scalars['Boolean']>;
  readonly preCoolTo?: InputMaybe<SpotQuoteTemperatureInput>;
  readonly minimumTemperature?: InputMaybe<SpotQuoteTemperatureInput>;
  readonly maximumTemperature?: InputMaybe<SpotQuoteTemperatureInput>;
  readonly temperatureSetting?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly hazmatClass?: InputMaybe<Scalars['String']>;
  readonly hazmatPackagingGroup?: InputMaybe<Scalars['String']>;
  readonly hazmatUNNumber?: InputMaybe<Scalars['String']>;
  readonly isOverDimensional?: InputMaybe<Scalars['Boolean']>;
  readonly expectedDimensions?: InputMaybe<SpotQuoteDimensionsInput>;
  readonly expectedLinear?: InputMaybe<SpotQuoteLengthInput>;
  readonly expectedVolume?: InputMaybe<SpotQuoteVolumeInput>;
  readonly expectedDensity?: InputMaybe<SpotQuoteDensityInput>;
  readonly expectedFreightClass: Scalars['String'];
  readonly expectedNMFCCode?: InputMaybe<Scalars['String']>;
};

export type SpotQuoteQuoteDetail = {
  readonly __typename?: 'SpotQuoteQuoteDetail';
  readonly id?: Maybe<Scalars['ID']>;
  readonly isSelected: Scalars['Boolean'];
  readonly quoteCarriers?: Maybe<ReadonlyArray<QuoteCarrier>>;
  readonly rateLineItems?: Maybe<ReadonlyArray<SpotQuoteRateLineItem>>;
  readonly totalRate: Scalars['Decimal'];
  readonly sequence: Scalars['Int'];
};

export type SpotQuoteQuoteDetailInput = {
  /**
   * Supply id on update to preserve existing QuoteDetails.
   * This field has no effect on create.
   */
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isSelected: Scalars['Boolean'];
  /** quoteCarriers are required on create but ignored on update and can be excluded. */
  readonly quoteCarriers?: InputMaybe<ReadonlyArray<SpotQuoteQuoteCarrierInput>>;
  readonly rateLineItems: ReadonlyArray<SpotQuoteRateLineItemInput>;
};

export type SpotQuoteQuoteInput = {
  readonly customerId: Scalars['ID'];
  /** The Minion transportMode's OptionId */
  readonly transportMode: Scalars['String'];
  /** The Minion loadSize's OptionId */
  readonly loadSize: Scalars['String'];
  readonly stops: ReadonlyArray<SpotQuoteStopInput>;
  /** The Minion trailerType's OptionIds */
  readonly equipments: ReadonlyArray<Scalars['String']>;
  /** Length in feet */
  readonly length?: InputMaybe<Scalars['Int']>;
  /** Width in inches */
  readonly width?: InputMaybe<Scalars['Int']>;
  /** Height in inches */
  readonly height?: InputMaybe<Scalars['Int']>;
  readonly shipStartDate: Scalars['Date'];
  readonly shipEndDate: Scalars['Date'];
  /** The Minion division's OptionId */
  readonly division?: InputMaybe<Scalars['String']>;
  /** The Minion project's OptionId */
  readonly project?: InputMaybe<Scalars['String']>;
  /** The Minion businessUnit's OptionId */
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  /** The Minion serviceLevel's OptionId */
  readonly serviceLevel: Scalars['String'];
  readonly scac?: InputMaybe<Scalars['String']>;
  /** The Minion orderRequirement's OptionIds */
  readonly requirements: ReadonlyArray<Scalars['String']>;
};

export type SpotQuoteRateLineItem = {
  readonly __typename?: 'SpotQuoteRateLineItem';
  /** The Minion chargeType's OptionId */
  readonly type: Scalars['String'];
  readonly ratePerUnit: Scalars['Decimal'];
  readonly units: Scalars['Decimal'];
  readonly totalRate: Scalars['Decimal'];
  /** The Minion currency's OptionId */
  readonly currency: Scalars['String'];
  readonly sequence: Scalars['Int'];
  readonly isRerateable: Scalars['Boolean'];
};

export type SpotQuoteRateLineItemInput = {
  /** The Minion chargeType's OptionId */
  readonly type: Scalars['String'];
  readonly ratePerUnit: Scalars['Decimal'];
  readonly units: Scalars['Decimal'];
  /** The Minion currency's OptionId */
  readonly currency: Scalars['String'];
  readonly isRerateable?: InputMaybe<Scalars['Boolean']>;
};

export type SpotQuoteRegionStopInput = {
  readonly regionId: Scalars['ID'];
};

export type SpotQuotesByCodesInput = {
  readonly spotQuoteCodes: ReadonlyArray<Scalars['String']>;
};

export type SpotQuotesByIdsInput = {
  readonly spotQuoteIds: ReadonlyArray<Scalars['ID']>;
};

export type SpotQuotesPagedFilterInput = {
  /** Include to filter by only the specified laneId or null to return for all lanes */
  readonly laneId?: InputMaybe<Scalars['ID']>;
  /** Include to filter by only the specified customerId or null to return for all customers */
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  /** True to return only active (non-expired) or false to return only inactive (expired) */
  readonly timezone: Scalars['String'];
};

export type SpotQuotesPagedInput = {
  readonly filter: SpotQuotesPagedFilterInput;
  readonly after?: InputMaybe<Scalars['String']>;
  readonly first?: InputMaybe<Scalars['Int']>;
};

export type SpotQuotesPaginatedPayload = {
  readonly __typename?: 'SpotQuotesPaginatedPayload';
  readonly data?: Maybe<SpotQuoteConnection>;
  readonly errors?: Maybe<ReadonlyArray<SpotQuoteError>>;
};

export type SpotQuoteStop = {
  readonly __typename?: 'SpotQuoteStop';
  readonly id: Scalars['ID'];
  readonly laneStopId: Scalars['ID'];
  /** The Records ScheduleDropTypeEnum's value: https://studio.apollographql.com/graph/mastermind/schema/sdl?currentSubgraph=Records&variant=mm100dev#ScheduleDropTypeEnum */
  readonly liveType: Scalars['String'];
  readonly sequence: Scalars['Int'];
  /** The Minion stopRequirement's OptionIds */
  readonly requirements: ReadonlyArray<Scalars['String']>;
};

export type SpotQuoteStopFilterInput = {
  /** The Records ScheduleDropTypeEnum's value: https://studio.apollographql.com/graph/mastermind/schema/sdl?currentSubgraph=Records&variant=mm100dev#ScheduleDropTypeEnum */
  readonly liveType?: InputMaybe<Scalars['String']>;
  /** The Minion stopType's OptionId. If not specified, the origin will be PU and all others will be Del. */
  readonly stopType?: InputMaybe<Scalars['String']>;
  /** The Minion geographyType's OptionId. */
  readonly geographyType?: InputMaybe<Scalars['String']>;
  readonly stopFacility?: InputMaybe<SpotQuoteFacilityStopInput>;
  readonly stopPostalCode?: InputMaybe<SpotQuotePostalCodeStopInput>;
  readonly stopCity?: InputMaybe<SpotQuoteGeographyStopInput>;
  readonly stopState?: InputMaybe<SpotQuoteGeographyStopInput>;
  readonly stopCountry?: InputMaybe<SpotQuoteCountryStopInput>;
  /** The Minion stopRequirement's OptionIds */
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type SpotQuoteStopInput = {
  /** The Records ScheduleDropTypeEnum's value: https://studio.apollographql.com/graph/mastermind/schema/sdl?currentSubgraph=Records&variant=mm100dev#ScheduleDropTypeEnum */
  readonly liveType: Scalars['String'];
  /** The Minion stopType's OptionId. If not specified, the origin will be PU and all others will be Del. */
  readonly stopType?: InputMaybe<Scalars['String']>;
  /**
   * The Minion geographyType's OptionId.
   * Only supports [Facility, PostalCode, City]
   */
  readonly geographyType: Scalars['String'];
  readonly stopFacility?: InputMaybe<SpotQuoteFacilityStopInput>;
  readonly stopPostalCode?: InputMaybe<SpotQuotePostalCodeStopInput>;
  readonly stopCity?: InputMaybe<SpotQuoteGeographyStopInput>;
  readonly stopRegion?: InputMaybe<SpotQuoteRegionStopInput>;
  readonly stopState?: InputMaybe<SpotQuoteGeographyStopInput>;
  readonly stopCountry?: InputMaybe<SpotQuoteCountryStopInput>;
  /** The Minion stopRequirement's OptionIds */
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type SpotQuoteTemperature = {
  readonly __typename?: 'SpotQuoteTemperature';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type SpotQuoteTemperatureInput = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type SpotQuoteVolume = {
  readonly __typename?: 'SpotQuoteVolume';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type SpotQuoteVolumeInput = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type StartAutoRateConfirmationInput = {
  readonly sentByUserId: Scalars['ID'];
  readonly sendToEmail?: InputMaybe<Scalars['String']>;
  readonly ccEmails?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeId: Scalars['ID'];
  readonly routeVendorId: Scalars['ID'];
  readonly vendorContactId: Scalars['ID'];
  readonly hideCostDetails?: InputMaybe<Scalars['Boolean']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly contactName?: InputMaybe<Scalars['String']>;
  readonly contactPhone?: InputMaybe<Scalars['String']>;
  readonly contactMethod?: InputMaybe<Scalars['String']>;
  readonly hideReference?: InputMaybe<Scalars['Boolean']>;
  readonly taStopPending?: InputMaybe<Scalars['Boolean']>;
  readonly hideStopDetails?: InputMaybe<Scalars['Boolean']>;
  readonly hideAppointmentTimes?: InputMaybe<Scalars['Boolean']>;
};

export type StartAutoRateConfirmationPayload = {
  readonly __typename?: 'StartAutoRateConfirmationPayload';
  readonly errors?: Maybe<ReadonlyArray<UserError>>;
  readonly rateConfirmation?: Maybe<RateConfirmation>;
};

export type StartTrackingNotification = {
  readonly __typename?: 'StartTrackingNotification';
  readonly carrierCode: Scalars['String'];
  readonly driverId: Scalars['String'];
  readonly routeId: Scalars['String'];
  readonly routeNumber: Scalars['String'];
  readonly fenceData: ReadonlyArray<TrackingStop>;
  readonly endTrackingDateTime: Scalars['String'];
  readonly startTrackingDateTime: Scalars['String'];
  readonly realm: Scalars['String'];
};

export type StartTrackingNotificationV2 = {
  readonly __typename?: 'StartTrackingNotificationV2';
  readonly carrierCode: Scalars['String'];
  readonly driverId: Scalars['String'];
  readonly routeId: Scalars['String'];
  readonly routeNumber: Scalars['String'];
  readonly fenceData: ReadonlyArray<TrackingStopV2>;
  readonly endTrackingDateTime: Scalars['String'];
  readonly startTrackingDateTime: Scalars['String'];
  readonly realm?: Maybe<Scalars['String']>;
};

export type StateFilter = {
  readonly deliveryStateCollection?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly pickUpStateCollection?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum StatusEnum {
  /** Voucher / Invoice has not been generated. */
  Saved = 'Saved',
  /** Voucher / Invoice has been generated, but not processed by the GL System. */
  Pending = 'Pending',
  /** Voucher / Invoice has been generated, and processing GL System. */
  Processing = 'Processing',
  /** Voucher / Invoice has been generated, processed by the GL System, and confirmation has been received that it has posted. */
  Processed = 'Processed',
  /** During 'Processing' the Voucher / Invoice encountered an error. */
  Error = 'Error',
  /** The Voucher / Invoice has been processed, a credit memo has been created and processed by the GL System, and confirmation has been received that it has posted. */
  CreditMemoed = 'CreditMemoed',
  /** The Voucher / Invoice has been processed, a credit memo has been created, and processing GL System. */
  CreditMemoing = 'CreditMemoing',
  /** The Voucher / Invoice has been processed externally. */
  External = 'External'
}

/** Sets the status of the transaction. */
export enum StatusInput {
  InProcess = 'InProcess',
  Failed = 'Failed',
  ValidationFailed = 'ValidationFailed',
  Completed = 'Completed',
  Blocked = 'Blocked',
  Retrying = 'Retrying'
}

export enum StatusTypeEnum {
  /** unknown */
  Unknown = 'unknown',
  /** new_account */
  NewAccount = 'newAccount',
  /** pending */
  Pending = 'pending',
  /** approved */
  Approved = 'approved',
  /** denied */
  Denied = 'denied',
  /** hold */
  Hold = 'hold',
  /** new */
  New = 'new',
  /** selective */
  Selective = 'selective',
  /** not_monitored */
  NotMonitored = 'not_monitored'
}

/** A stop for a load */
export type Stop = {
  readonly __typename?: 'Stop';
  readonly address?: Maybe<Address>;
  readonly appointment?: Maybe<Appointment>;
  readonly appointments?: Maybe<ReadonlyArray<Appointment>>;
  readonly apptConfBy?: Maybe<Scalars['String']>;
  readonly apptReqWith?: Maybe<Scalars['String']>;
  readonly availableEnd?: Maybe<DatetimeWithTimezone>;
  readonly availableEndTimes?: Maybe<ScheduleRange>;
  readonly availableStart?: Maybe<DatetimeWithTimezone>;
  readonly availableStartTimes?: Maybe<ScheduleRange>;
  readonly bolNumber?: Maybe<Scalars['String']>;
  readonly carrierArrival?: Maybe<DatetimeWithTimezone>;
  readonly carrierDeparture?: Maybe<DatetimeWithTimezone>;
  readonly commodities?: Maybe<ReadonlyArray<Commodity>>;
  readonly commodityTotals?: Maybe<CommodityTotals>;
  readonly computedCurrentAppointment?: Maybe<Appointment>;
  readonly currentAppointment?: Maybe<Appointment>;
  readonly destroyed?: Maybe<Scalars['Boolean']>;
  readonly distanceToNext?: Maybe<Length>;
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly externalRef?: Maybe<Scalars['String']>;
  readonly facility?: Maybe<Facility>;
  readonly id: Scalars['ID'];
  readonly lateArrivalReasonCode?: Maybe<KeyValue>;
  readonly liveType?: Maybe<ScheduleDropTypeEnum>;
  readonly loadFrom?: Maybe<KeyValue>;
  readonly note?: Maybe<Scalars['String']>;
  readonly refs?: Maybe<ReadonlyArray<Ref>>;
  readonly region?: Maybe<Region>;
  readonly reqDate?: Maybe<DatetimeWithTimezone>;
  readonly requirements?: Maybe<ReadonlyArray<StopRequirement>>;
  readonly routes?: Maybe<ReadonlyArray<LoadRoute>>;
  readonly scheduleType?: Maybe<ScheduleAppointmentTypeEnum>;
  readonly sealNumber?: Maybe<Scalars['String']>;
  readonly signature?: Maybe<Scalars['String']>;
  readonly subRegion?: Maybe<Region>;
  readonly superRegion?: Maybe<Region>;
  readonly trailerNumberIn?: Maybe<Scalars['String']>;
  readonly trailerNumberOut?: Maybe<Scalars['String']>;
  readonly type: StopTypeEnum;
  readonly unloadFrom?: Maybe<KeyValue>;
  readonly workType?: Maybe<ScheduleWorkTypeEnum>;
};


/** A stop for a load */
export type StopDistanceToNextArgs = {
  unit?: InputMaybe<UnitOfLengthEnum>;
};

export type StopAddress = {
  readonly __typename?: 'StopAddress';
  readonly id: Scalars['String'];
  readonly geopoint?: Maybe<Geopoint>;
  readonly street1: Scalars['String'];
  readonly street2?: Maybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
  readonly county?: Maybe<Scalars['String']>;
};

export type StopAddressInput = {
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly countryArea: Scalars['String'];
  readonly main: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly state: Scalars['String'];
  readonly streetAddress1: Scalars['String'];
};

export type StopAddressInputV2 = {
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly county?: InputMaybe<Scalars['String']>;
  readonly countryArea: Scalars['String'];
  readonly main: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly state: Scalars['String'];
  readonly streetAddress1: Scalars['String'];
};

export type StopAddressV2 = {
  readonly __typename?: 'StopAddressV2';
  readonly id?: Maybe<Scalars['String']>;
  readonly geopoint?: Maybe<Geopoint>;
  readonly street1?: Maybe<Scalars['String']>;
  readonly street2?: Maybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
  readonly county?: Maybe<Scalars['String']>;
};

export type StopAppointment = {
  readonly __typename?: 'StopAppointment';
  readonly requestedStart?: Maybe<Time>;
  readonly requestedEnd?: Maybe<Time>;
  readonly requestedTimeRange?: Maybe<RequestedTimeRange>;
};

export type StopCommodity = {
  readonly __typename?: 'StopCommodity';
  readonly commodityId?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['String']>;
};

export type StopCommodityFromCommodity = {
  readonly __typename?: 'StopCommodityFromCommodity';
  readonly id: Scalars['ID'];
  readonly assignedExpectedWeight?: Maybe<MassV2>;
  readonly assignedExpectedPieces?: Maybe<Scalars['Float']>;
  readonly assignedExpectedHandlingUnits?: Maybe<Scalars['Float']>;
  readonly actualWeight?: Maybe<Scalars['Float']>;
  readonly actualPieces?: Maybe<Scalars['Float']>;
  readonly handlingUnitTerm?: Maybe<Scalars['String']>;
  readonly pieceTypeTerm?: Maybe<Scalars['String']>;
  /** @deprecated Use on RouteStop or OrderStop */
  readonly orderStopDesignation?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly updated: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<UserV2>;
  readonly routeStopId: Scalars['ID'];
};


export type StopCommodityFromCommodityAssignedExpectedWeightArgs = {
  unit?: InputMaybe<Scalars['String']>;
};

export type StopCommodityFromOrderStop = {
  readonly __typename?: 'StopCommodityFromOrderStop';
  readonly id: Scalars['ID'];
  readonly assignedExpectedWeight?: Maybe<MassV2>;
  readonly assignedExpectedPieces?: Maybe<Scalars['Float']>;
  readonly assignedExpectedHandlingUnits?: Maybe<Scalars['Float']>;
  readonly actualWeight?: Maybe<Scalars['Float']>;
  readonly actualPieces?: Maybe<Scalars['Float']>;
  readonly handlingUnitTerm?: Maybe<Scalars['String']>;
  readonly pieceTypeTerm?: Maybe<Scalars['String']>;
  /** @deprecated Use on RouteStop or OrderStop */
  readonly orderStopDesignation?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly updated: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<UserV2>;
  readonly commodityId: Scalars['ID'];
};


export type StopCommodityFromOrderStopAssignedExpectedWeightArgs = {
  unit?: InputMaybe<Scalars['String']>;
};

export type StopCommodityFromRouteStop = {
  readonly __typename?: 'StopCommodityFromRouteStop';
  readonly id: Scalars['ID'];
  readonly assignedExpectedWeight?: Maybe<MassV2>;
  readonly assignedExpectedPieces?: Maybe<Scalars['Float']>;
  readonly assignedExpectedHandlingUnits?: Maybe<Scalars['Float']>;
  readonly actualWeight?: Maybe<Scalars['Float']>;
  readonly actualPieces?: Maybe<Scalars['Float']>;
  readonly handlingUnitTerm?: Maybe<Scalars['String']>;
  readonly pieceTypeTerm?: Maybe<Scalars['String']>;
  /** @deprecated Use on RouteStop or OrderStop */
  readonly orderStopDesignation?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly updated: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<UserV2>;
  readonly commodity: CommodityV2;
};


export type StopCommodityFromRouteStopAssignedExpectedWeightArgs = {
  unit?: InputMaybe<Scalars['String']>;
};

/** The connection type for Stop. */
export type StopConnection = {
  readonly __typename?: 'StopConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<StopEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type StopDetail = {
  readonly __typename?: 'StopDetail';
  readonly id: Scalars['ID'];
  readonly address: Address;
  readonly radius: Scalars['Int'];
};

export type StopDetailV2 = {
  readonly __typename?: 'StopDetailV2';
  readonly id: Scalars['ID'];
  readonly address: Address;
  readonly radius: Scalars['Float'];
};

/** An edge in a connection. */
export type StopEdge = {
  readonly __typename?: 'StopEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: Stop;
};

export type StopEdgeV2 = {
  readonly __typename?: 'StopEdgeV2';
  readonly node: RouteStop;
  readonly cursor: Scalars['String'];
};

export type StopEvent = {
  readonly __typename?: 'StopEvent';
  readonly id: Scalars['ID'];
  readonly eventTypeID: Scalars['String'];
  readonly eventSubTypeID: Scalars['String'];
  readonly eventSubTypeDatetime?: Maybe<Scalars['DateTime']>;
  readonly eventSubTypeTimezone?: Maybe<Scalars['String']>;
  readonly eventDetail: EventDetail;
};

export type StopEventCategory = {
  readonly __typename?: 'StopEventCategory';
  readonly driverStopEventTypes: ReadonlyArray<Scalars['String']>;
  readonly trailerStopEventTypes: ReadonlyArray<Scalars['String']>;
};

export type StopEventDataInput = {
  readonly key?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly sequence?: InputMaybe<Scalars['Float']>;
};

export type StopEventEngineInput = {
  readonly routeType?: InputMaybe<Scalars['String']>;
  readonly stopType?: InputMaybe<Scalars['String']>;
  readonly liveType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly trailerAssignmentType?: InputMaybe<Scalars['String']>;
};

export type StopEventEngineOutput = {
  readonly __typename?: 'StopEventEngineOutput';
  readonly coreStopEvents: ReadonlyArray<Scalars['String']>;
  readonly prefillStopEvents: ReadonlyArray<Scalars['String']>;
  readonly optionalStopEvents: ReadonlyArray<Scalars['String']>;
  readonly arrivalDepartureEvents?: Maybe<ArrivalDepartureEvents>;
  readonly deprecatedStopEventTypes: ReadonlyArray<DeprecatedStopEventType>;
  readonly eventGroups?: Maybe<StopEventGroups>;
  readonly sharedStopEventTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly readOnlyStopEventTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly stopEventCategories: StopEventCategory;
};

export type StopEventGroups = {
  readonly __typename?: 'StopEventGroups';
  readonly hookEvents?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly dropEvents?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly loadingBeginEvents?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly loadingEndEvents?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly unloadingBeginEvents?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly unloadingEndEvents?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type StopEventInfoInput = {
  readonly loadId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly stopId: Scalars['ID'];
  readonly eventTypeId: Scalars['String'];
  readonly datetime: Scalars['String'];
  readonly timezone: Scalars['String'];
  readonly carrierId: Scalars['ID'];
  readonly tractorNumber: Scalars['String'];
  readonly trailerNumber: Scalars['String'];
  readonly trailers: ReadonlyArray<TrailerNumberInput>;
};

export type StopEventInfoInputV2 = {
  readonly loadId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly stopId: Scalars['ID'];
  readonly eventTypeId: Scalars['String'];
  readonly datetime: Scalars['String'];
  readonly timezone: Scalars['String'];
  readonly carrierId: Scalars['ID'];
  readonly tractorNumber: Scalars['String'];
  readonly trailerNumber: Scalars['String'];
  readonly trailers?: InputMaybe<ReadonlyArray<InputMaybe<TrailerNumberInput>>>;
};

export type StopEventsV2Filter = {
  readonly stopID?: InputMaybe<Scalars['String']>;
  readonly eventTypeID?: InputMaybe<Scalars['String']>;
  readonly stopEventIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type StopEventsV2WithSharedStatus = {
  readonly __typename?: 'StopEventsV2WithSharedStatus';
  readonly id: Scalars['ID'];
  readonly load: LoadV2;
  readonly route: LoadRouteV2;
  readonly stop: RouteStop;
  readonly stopV2: RouteStopV2;
  readonly loadStopID?: Maybe<Scalars['String']>;
  readonly eventTypeID: Scalars['String'];
  readonly eventSubTypeID: Scalars['String'];
  readonly eventSubTypeDatetime?: Maybe<Scalars['DateTime']>;
  readonly eventSubTypeTimezone?: Maybe<Scalars['String']>;
  readonly eventDetail: EventDetail;
  readonly isFromIngress: Scalars['Boolean'];
  readonly createdDatetime: Scalars['DateTime'];
  readonly updatedDatetime: Scalars['DateTime'];
  readonly lastUpdateSource?: Maybe<Scalars['String']>;
  readonly createdBy: EmployeeV2;
  readonly updatedBy: EmployeeV2;
  readonly stopEventsLastUpdatedSource?: Maybe<Scalars['String']>;
  readonly isSharedEvent?: Maybe<Scalars['Boolean']>;
};

export type StopEventTrailer = {
  readonly __typename?: 'StopEventTrailer';
  readonly sortIndex?: Maybe<Scalars['Int']>;
  readonly trailerID?: Maybe<Scalars['ID']>;
};

export type StopEventTrailerInput = {
  readonly trailerNumber: Scalars['String'];
};

export type StopEventV2 = {
  readonly __typename?: 'StopEventV2';
  readonly id: Scalars['ID'];
  readonly load: LoadV2;
  readonly route: LoadRouteV2;
  readonly stop: RouteStop;
  readonly stopV2: RouteStopV2;
  readonly loadStopID?: Maybe<Scalars['String']>;
  readonly eventTypeID: Scalars['String'];
  readonly eventSubTypeID: Scalars['String'];
  readonly eventSubTypeDatetime?: Maybe<Scalars['DateTime']>;
  readonly eventSubTypeTimezone?: Maybe<Scalars['String']>;
  readonly eventDetail: EventDetail;
  readonly isFromIngress: Scalars['Boolean'];
  readonly createdDatetime: Scalars['DateTime'];
  readonly updatedDatetime: Scalars['DateTime'];
  readonly lastUpdateSource?: Maybe<Scalars['String']>;
  readonly createdBy: EmployeeV2;
  readonly updatedBy: EmployeeV2;
  readonly stopEventsLastUpdatedSource?: Maybe<Scalars['String']>;
};

export type StopEventV2Connection = {
  readonly __typename?: 'StopEventV2Connection';
  readonly edges: ReadonlyArray<StopEventV2Edge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type StopEventV2DeleteInput = {
  readonly employeeID?: InputMaybe<Scalars['ID']>;
  readonly stopID: Scalars['ID'];
  readonly eventTypeID: Scalars['String'];
};

export type StopEventV2Edge = {
  readonly __typename?: 'StopEventV2Edge';
  readonly cursor: Scalars['String'];
  readonly node: StopEventV2;
};

export type StopEventV2IngressInput = {
  readonly userID?: InputMaybe<Scalars['ID']>;
  readonly loadID: Scalars['ID'];
  readonly loadStopID?: InputMaybe<Scalars['ID']>;
  readonly routeID: Scalars['ID'];
  readonly stopID: Scalars['ID'];
  readonly eventTypeID: Scalars['String'];
  readonly eventSubTypeID: Scalars['String'];
  readonly eventDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly autoExpectedDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly autoExpectedSource?: InputMaybe<Scalars['String']>;
  readonly eventTimezone?: InputMaybe<Scalars['String']>;
  readonly stopEventLastUpdatedSource?: InputMaybe<Scalars['String']>;
  readonly eventDetail?: InputMaybe<EventDetailInput>;
};

export type StopEventV2LateReasonUpdateInput = {
  readonly employeeID?: InputMaybe<Scalars['ID']>;
  readonly stopID: Scalars['ID'];
  readonly lateArrivalReasonCodeID: Scalars['String'];
};

export type StopEventV2UpsertInput = {
  readonly employeeID?: InputMaybe<Scalars['ID']>;
  readonly loadID: Scalars['ID'];
  readonly loadStopID?: InputMaybe<Scalars['ID']>;
  readonly routeID: Scalars['ID'];
  readonly stopID: Scalars['ID'];
  readonly eventTypeID: Scalars['String'];
  readonly expectedDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly expectedTimezone?: InputMaybe<Scalars['String']>;
  readonly actualDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly actualTimezone?: InputMaybe<Scalars['String']>;
  readonly lateArrivalReasonCodeID?: InputMaybe<Scalars['String']>;
  readonly stopEventLastUpdatedSource?: InputMaybe<Scalars['String']>;
  readonly trailer?: InputMaybe<TrailerInput>;
  readonly trailers?: InputMaybe<ReadonlyArray<TrailerInput>>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly tractorNumber?: InputMaybe<Scalars['String']>;
};

export type StopInfo = {
  readonly __typename?: 'StopInfo';
  readonly geoTypeRegion?: Maybe<Scalars['String']>;
  readonly geoTypePC?: Maybe<Scalars['String']>;
  readonly geoTypeCountry?: Maybe<Scalars['String']>;
  readonly geoTypeState?: Maybe<Scalars['String']>;
  readonly geoTypeCity?: Maybe<Scalars['String']>;
  readonly geoTypeFacility?: Maybe<Scalars['String']>;
  readonly stopType?: Maybe<Scalars['String']>;
  readonly sequence?: Maybe<Scalars['Int']>;
  readonly geoType?: Maybe<Scalars['String']>;
};

export type StopRef = {
  readonly __typename?: 'StopRef';
  readonly id?: Maybe<Scalars['ID']>;
  readonly typeId?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
};

export type StopRefInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeId: Scalars['String'];
  readonly value: Scalars['String'];
};

/** A requirement for a stop */
export type StopRequirement = {
  readonly __typename?: 'StopRequirement';
  readonly option: KeyValue;
};

export type StopRequirementInput = {
  readonly optionId: Scalars['ID'];
};

export type StopsConnection = {
  readonly __typename?: 'StopsConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<StopEdgeV2>;
};

/** for service Tolerance fields */
export type StopServiceTolerance = {
  readonly __typename?: 'StopServiceTolerance';
  readonly earlyCutOff?: Maybe<DatetimeWithTimezone>;
  readonly lateCutOff?: Maybe<DatetimeWithTimezone>;
};

export type StopsFilter = {
  /** Search/Filter by ComputedCurrentAppointment status */
  readonly appointmentStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by appt_req_with */
  readonly apptReqWith?: InputMaybe<Scalars['String']>;
  /** Search/Filter by array of Customer Employee groups */
  readonly customerEmployeeGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by customer ids */
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/Filter by last route stop address */
  readonly destinationStopAddress?: InputMaybe<AddressFilter>;
  /** Search/Filter by start range ending */
  readonly endDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Search/Filter by facility id */
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  /** Search/Filter by Main Customer Rep */
  readonly mainCustomerRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/Filter by Order Tender Status */
  readonly orderTenderStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by first route stop address */
  readonly originStopAddress?: InputMaybe<AddressFilter>;
  /** Search/Filter by stop requested ship date */
  readonly requestedShipDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Search/Filter by Route Activation Status */
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by code or partial route code */
  readonly routeCode?: InputMaybe<Scalars['String']>;
  /** Search/Filter by Associated Order Rep */
  readonly associatedRepIds?: InputMaybe<RepIdFilter>;
  /** Search/Filter by facility Scheduling System Type */
  readonly schedulingSystemType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by start range beginning */
  readonly startDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Search/Filter by Route Life Cycle Status */
  readonly status?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by stop address */
  readonly stopAddress?: InputMaybe<AddressFilter>;
  /** Search/Filter by computed_current_appointments requested_start_date and requested_end_date */
  readonly stopDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Search/Filter by load_stop_types */
  readonly loadStopType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly stopType?: InputMaybe<ReadonlyArray<StopTypeEnum>>;
  /** Search/Filter by schedule_appointment_types */
  readonly scheduleAppointmentType?: InputMaybe<ReadonlyArray<ScheduleAppointmentTypeEnum>>;
  /** Search/Filter by schedule_drop_types */
  readonly scheduleDropType?: InputMaybe<ReadonlyArray<ScheduleDropTypeEnum>>;
  /** Filter by load code */
  readonly loadCode?: InputMaybe<Scalars['Float']>;
  /** Filter by order code */
  readonly orderCode?: InputMaybe<Scalars['Float']>;
};

export type StopsItemRequestFilterType = {
  readonly appointmentEndDateTime?: InputMaybe<Scalars['Long']>;
  readonly appointmentStartDateTime?: InputMaybe<Scalars['Long']>;
  readonly availableEndDateTime?: InputMaybe<Scalars['Long']>;
  readonly availableStartDateTime?: InputMaybe<Scalars['Long']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly countryCode?: InputMaybe<Scalars['String']>;
  readonly facilityCode?: InputMaybe<Scalars['String']>;
  readonly geographyType?: InputMaybe<Scalars['String']>;
  readonly latitude?: InputMaybe<Scalars['Float']>;
  readonly liveDropTerm?: InputMaybe<Scalars['String']>;
  readonly loadFromTerm?: InputMaybe<Scalars['String']>;
  readonly longitude?: InputMaybe<Scalars['Float']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly region?: InputMaybe<Scalars['String']>;
  readonly sequenceNumber?: InputMaybe<Scalars['Int']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly stopRequirementTerms?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly timezone?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly unloadFromTerm?: InputMaybe<Scalars['String']>;
  readonly workTerm?: InputMaybe<Scalars['String']>;
};

export type StopsQueryInput = {
  /** Search/Filter by ComputedCurrentAppointment status */
  readonly appointmentStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by appt_req_with */
  readonly apptReqWith?: InputMaybe<Scalars['String']>;
  /** Search/Filter by Associated Order or Route Rep */
  readonly associatedRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/Filter by array of Customer Employee groups */
  readonly customerEmployeeGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by customer ids */
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/Filter by last route stop address */
  readonly destinationStopAddress?: InputMaybe<AddressesFilter>;
  /** Search/Filter by start range ending */
  readonly endDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Search/Filter by facility id */
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  /** Search/Filter by Main Customer Rep */
  readonly mainCustomerRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/Filter by Order Tender Status */
  readonly orderTenderStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by first route stop address */
  readonly originStopAddress?: InputMaybe<AddressesFilter>;
  /** Search/Filter by stop requested ship date */
  readonly requestedShipDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Search/Filter by Route Activation Status */
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by code or partial route code */
  readonly routeCode?: InputMaybe<Scalars['String']>;
  /** Search/Filter route rep OR order rep ids */
  readonly routeRepOrOrderRepIds?: InputMaybe<RepIdsFilter>;
  /** Search/Filter by schedule_appointment_type */
  readonly scheduleAppointmentType?: InputMaybe<ReadonlyArray<ScheduleAppointmentTypeEnum>>;
  /** Search/Filter by schedule_drop_type */
  readonly scheduleDropType?: InputMaybe<ReadonlyArray<ScheduleDropTypeEnum>>;
  /** Search/Filter by facility Scheduling System Type */
  readonly schedulingSystemType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by start range beginning */
  readonly startDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Search/Filter by Route Life Cycle Status */
  readonly status?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by stop address */
  readonly stopAddress?: InputMaybe<AddressesFilter>;
  /** Search/Filter by computed_current_appointments requested_start_date and requested_end_date */
  readonly stopDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Search/Filter by stop_type */
  readonly stopType?: InputMaybe<ReadonlyArray<StopTypeEnum>>;
};

export type StopsV2Connection = {
  readonly __typename?: 'StopsV2Connection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<StopV2Edge>;
};

export type StopTime = {
  readonly __typename?: 'StopTime';
  readonly value?: Maybe<Scalars['String']>;
  readonly timezone?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly trailers?: Maybe<ReadonlyArray<Maybe<StopEventTrailer>>>;
};

/** This object is not persisted. The purpose of this object is to provide information about a commodity as it pertains to a stop. */
export type StopTracking = {
  readonly __typename?: 'StopTracking';
  readonly stopId?: Maybe<Scalars['String']>;
  readonly stopSequence?: Maybe<Scalars['Float']>;
  readonly tracking?: Maybe<Scalars['String']>;
};

export enum StopTypeEnum {
  CrossDock = 'crossDock',
  OriginRamp = 'originRamp',
  DestinationRamp = 'destinationRamp',
  CustomsBroker = 'customsBroker',
  Yard = 'yard',
  Airport = 'airport',
  BorderCrossing = 'borderCrossing',
  ContainerDepot = 'containerDepot',
  Domicile = 'domicile',
  DriverComplianceTraining = 'driverComplianceTraining',
  Fuel = 'fuel',
  MaintenanceShop = 'maintenanceShop',
  Relay = 'relay',
  Scale = 'scale',
  SeaPort = 'seaPort',
  Terminal = 'terminal',
  TrailerWash = 'trailerWash',
  Transload = 'transload',
  Waypoint = 'waypoint',
  RailJunction = 'railJunction',
  /** Pick Up */
  Pu = 'PU',
  /** Delivery */
  Del = 'Del',
  /** Trailer Acquisition */
  Ta = 'TA',
  /** Trailer Disposition */
  Td = 'TD'
}

export enum StopTypeTerm {
  P = 'P',
  D = 'D'
}

export type StopV2Edge = {
  readonly __typename?: 'StopV2Edge';
  readonly node: RouteStopV2;
  readonly cursor: Scalars['String'];
};

export type StringType = {
  readonly __typename?: 'StringType';
  readonly value?: Maybe<Scalars['String']>;
};

export type SublocationUserError = {
  readonly __typename?: 'SublocationUserError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly code?: Maybe<Scalars['String']>;
};

export type SubmitRoundInput = {
  readonly bidId?: InputMaybe<Scalars['ID']>;
  readonly roundId?: InputMaybe<Scalars['ID']>;
};

export type SubmittedTimers = {
  readonly __typename?: 'SubmittedTimers';
  readonly driveClock: DriverHosClock;
  readonly shiftClock: DriverHosClock;
  readonly cycleClock: DriverHosClock;
  readonly breakClock: DriverHosClock;
};

export type SubRegionV2 = {
  readonly __typename?: 'SubRegionV2';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
};

export type SubReorderAutoApplyPriorityPayload = {
  readonly __typename?: 'SubReorderAutoApplyPriorityPayload';
  readonly autoApplyPatterns?: Maybe<ReadonlyArray<Maybe<Pattern>>>;
};

export type SuggestedCarrier = {
  readonly __typename?: 'SuggestedCarrier';
  readonly rank: Scalars['Float'];
  readonly carrierCode: Scalars['String'];
  readonly isOfferSubmitted: Scalars['Boolean'];
  readonly totalCharge: TotalCharge;
  readonly chargeDetails: ReadonlyArray<ChargeDetails>;
  readonly rankingFactors: ReadonlyArray<RankingFactors>;
  readonly carrier: CarrierV2;
};

export type SuggestedCarriersInput = {
  readonly routeId: Scalars['ID'];
};

export type SuggestedCarriersPayload = {
  readonly __typename?: 'SuggestedCarriersPayload';
  readonly carriers?: Maybe<ReadonlyArray<SuggestedCarrier>>;
  readonly errors?: Maybe<ReadonlyArray<BookingsUserError>>;
};

export type SuperRegionV2 = {
  readonly __typename?: 'SuperRegionV2';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly regions?: Maybe<ReadonlyArray<Maybe<RegionV2>>>;
  readonly regionsetname?: Maybe<Scalars['String']>;
  readonly isregionsetactive?: Maybe<Scalars['Boolean']>;
  readonly regionNames?: Maybe<Scalars['String']>;
  readonly subRegionNames?: Maybe<Scalars['String']>;
};

export type SyncHdsRuleSetInput = {
  readonly eventId?: InputMaybe<Scalars['String']>;
  readonly ruleSetId: Scalars['String'];
};

export type SyncHdsRuleSetResult = {
  readonly __typename?: 'SyncHdsRuleSetResult';
  readonly ruleSet?: Maybe<RuleSet>;
  readonly correlationId?: Maybe<Scalars['String']>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type SyncHdsTemplateInput = {
  readonly eventId: Scalars['String'];
  readonly contentType: ContentTemplateContentType;
};

export type SyncHdsTemplateResult = {
  readonly __typename?: 'SyncHdsTemplateResult';
  readonly template?: Maybe<ContentTemplate>;
  readonly correlationId?: Maybe<Scalars['String']>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type SynchronizedCustomerType = {
  readonly __typename?: 'SynchronizedCustomerType';
  readonly customerId: Scalars['ID'];
  readonly code?: Maybe<Scalars['String']>;
};

export type SynchronizedVendorType = {
  readonly __typename?: 'SynchronizedVendorType';
  readonly vendorId: Scalars['ID'];
  readonly carrierCode?: Maybe<Scalars['String']>;
};

export type SyncUsersResponseObj = {
  readonly __typename?: 'syncUsersResponseObj';
  readonly success: Scalars['Boolean'];
  readonly error: Scalars['String'];
};

export type TableFields = {
  readonly __typename?: 'TableFields';
  /** Name of the table column */
  readonly name: Scalars['String'];
  /** Unique identifier for the column */
  readonly id: Scalars['String'];
  /** Visibility status of the column */
  readonly isVisible: Scalars['Boolean'];
  /** Sort order of the column */
  readonly sort: Scalars['Float'];
  /** Width of the table column */
  readonly width?: Maybe<Scalars['Float']>;
};

export type TableFieldsInput = {
  readonly name: Scalars['String'];
  readonly id: Scalars['String'];
  readonly isVisible: Scalars['Boolean'];
  readonly sort: Scalars['Int'];
  readonly width?: InputMaybe<Scalars['Int']>;
};

export type TablePreference = {
  readonly __typename?: 'TablePreference';
  /** Unique ID generated by database */
  readonly _id: Scalars['ID'];
  /** table name */
  readonly tableName: Scalars['String'];
  /** Array of table column entries */
  readonly tableFields: ReadonlyArray<TableFields>;
  /** Preference creation DateTime */
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Preference creation DateTime */
  readonly lastModified?: Maybe<Scalars['ISO8601DateTime']>;
  /** Employee id of Preference creator */
  readonly createdBy?: Maybe<Scalars['String']>;
};

export type TablePreferenceEdge = {
  readonly __typename?: 'TablePreferenceEdge';
  readonly cursor: Scalars['String'];
  readonly node: TablePreference;
};

export type TablePreferenceGroupInput = {
  /** Unique ID generated by database */
  readonly _id?: InputMaybe<Scalars['ID']>;
  /** Table ID from frontend */
  readonly objectId: Scalars['ID'];
};

export type TablePreferenceInput = {
  /** Unique ID generated by database */
  readonly _id?: InputMaybe<Scalars['ID']>;
  /** table name */
  readonly tableName?: InputMaybe<Scalars['String']>;
  /** Table ID from frontend */
  readonly objectId: Scalars['ID'];
  /** Array of table column entries */
  readonly tableFields: ReadonlyArray<TableFieldsInput>;
};

export type TablePreferenceResponseType = {
  readonly __typename?: 'TablePreferenceResponseType';
  /** Unique ID generated by database */
  readonly _id: Scalars['ID'];
  /** table name */
  readonly tableName: Scalars['String'];
  /** Array of table column entries */
  readonly tableFields: ReadonlyArray<TableFields>;
  /** Preference creation DateTime */
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Preference creation DateTime */
  readonly lastModified?: Maybe<Scalars['ISO8601DateTime']>;
  /** Employee id of Preference creator */
  readonly createdBy?: Maybe<Scalars['String']>;
  /** rep object id */
  readonly objectId: Scalars['ID'];
};

export type Target = {
  readonly __typename?: 'Target';
  readonly days?: Maybe<Scalars['Float']>;
  readonly loadedMiles?: Maybe<Scalars['Float']>;
  readonly emptyMiles?: Maybe<Scalars['Float']>;
  readonly revenue?: Maybe<Scalars['Float']>;
};

export type TargetInput = {
  readonly days?: InputMaybe<Scalars['Float']>;
  readonly loadedMiles?: InputMaybe<Scalars['Float']>;
  readonly emptyMiles?: InputMaybe<Scalars['Float']>;
  readonly revenue?: InputMaybe<Scalars['Float']>;
};

export enum TarpTypeEnum {
  /** Lumber */
  Lumber = 'lumber',
  /** None */
  None = 'none',
  /** Steel */
  Steel = 'steel'
}

/** DEPRECATED: Please use "loadTarps" DDT */
export enum TarpTypesEnum {
  Lumber = 'lumber',
  Steel = 'steel'
}

export type TaskConnectionV2 = {
  readonly __typename?: 'TaskConnectionV2';
  readonly edges: ReadonlyArray<TaskEdgeV2>;
  readonly pageInfo: PageInfo;
};

export type TaskEdgeV2 = {
  readonly __typename?: 'TaskEdgeV2';
  readonly node: TaskV2;
  readonly cursor: Scalars['String'];
};

export type TaskNoteV2 = {
  readonly __typename?: 'TaskNoteV2';
  readonly id: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly taskStatus: Scalars['String'];
  readonly note?: Maybe<Scalars['String']>;
};

export type TaskV2 = {
  readonly __typename?: 'TaskV2';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly type: Scalars['String'];
  readonly status: Scalars['String'];
  readonly routeId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly requestedAt: Scalars['DateTime'];
  readonly requestedBy: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly neededBy?: Maybe<Scalars['DateTime']>;
  readonly assignedTo?: Maybe<Scalars['String']>;
  readonly closedAt?: Maybe<Scalars['DateTime']>;
  readonly latestNote?: Maybe<Scalars['String']>;
  readonly assignedBy?: Maybe<Scalars['ID']>;
  readonly assignedAt?: Maybe<Scalars['DateTime']>;
  readonly actionBy?: Maybe<Scalars['ID']>;
  readonly actionAt?: Maybe<Scalars['DateTime']>;
  readonly notes: ReadonlyArray<TaskNoteV2>;
  readonly groupId?: Maybe<Scalars['String']>;
  readonly assignedToGroupId?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly comment?: Maybe<Scalars['String']>;
  /** @deprecated Moving to EmployeeV2, use requestedByEmployeeV2 */
  readonly requestedByEmployee: Employee;
  /** @deprecated Moving to EmployeeV2, use requestedByEmployeeNullableV2 */
  readonly requestedByEmployeeNullable?: Maybe<Employee>;
  /** @deprecated Moving to EmployeeV2, use assignedToEmployeeV3 */
  readonly assignedToEmployeeV2?: Maybe<Employee>;
  readonly requestedByEmployeeV2: EmployeeV2;
  readonly requestedByEmployeeNullableV2?: Maybe<EmployeeV2>;
  readonly assignedToEmployeeV3?: Maybe<EmployeeV2>;
  readonly routeV2?: Maybe<LoadRouteV2>;
  /** @deprecated Moving to CarrierV2, use vendorNullableV2 */
  readonly vendorNullable?: Maybe<Carrier>;
  /** @deprecated Moving to CarrierV2, use vendorV2 */
  readonly vendor: Carrier;
  readonly vendorNullableV2?: Maybe<CarrierV2>;
  readonly vendorV2: CarrierV2;
};

/** Taxability status and related Charge Type. */
export type TaxabilityChargeType = {
  readonly id: Scalars['ID'];
  readonly country: Scalars['String'];
  readonly chargeType: Scalars['String'];
  readonly taxability: Scalars['String'];
  readonly chargeTypeId: Scalars['ID'];
  readonly defaultTaxability?: InputMaybe<Scalars['Boolean']>;
};

/** Taxability status and related Charge Type. */
export type TaxabilityChargeTypeOutput = {
  readonly __typename?: 'TaxabilityChargeTypeOutput';
  readonly id: Scalars['ID'];
  readonly country: Scalars['String'];
  readonly chargeType: Scalars['String'];
  readonly taxability: Scalars['String'];
  readonly chargeTypeId: Scalars['ID'];
  readonly defaultTaxability?: Maybe<Scalars['Boolean']>;
};

export type TaxInterfaceCreateInput = {
  readonly taxType: TaxTypeEnum;
  readonly chargeTypeId: Scalars['ID'];
};

export type TaxInterfaceModel = {
  readonly __typename?: 'TaxInterfaceModel';
  readonly taxType: TaxTypeEnum;
  readonly chargeTypeId?: Maybe<Scalars['Uuid']>;
  readonly chargeTypeExists?: Maybe<Scalars['Boolean']>;
  readonly chargeType?: Maybe<ChargeType>;
  readonly hasPropertyMetadata: Scalars['Boolean'];
  readonly eid?: Maybe<Scalars['Long']>;
  readonly id?: Maybe<Scalars['Uuid']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly propertyMetadata?: Maybe<ReadonlyArray<KeyValuePairOfStringAndPropertyMetadata>>;
};

export type TaxInterfaceUpdateInput = {
  readonly taxType: TaxTypeEnum;
  readonly chargeTypeId: Scalars['ID'];
  readonly id: Scalars['ID'];
};

export enum TaxTypeEnum {
  /** Indicates that this is a country tax. */
  CountryTax = 'CountryTax',
  /** Indicates that this is a province tax. */
  ProvinceTax = 'ProvinceTax',
  /** Indicates that this is a harmonized tax. */
  HarmonizedTax = 'HarmonizedTax'
}

export type Temperature = {
  readonly __typename?: 'Temperature';
  readonly unit: TemperatureUnitTypeEnum;
  readonly value: Scalars['Float'];
};

export type TemperatureRangeInput = {
  readonly min?: InputMaybe<TemperatureV2Input>;
  readonly max?: InputMaybe<TemperatureV2Input>;
};

export enum TemperatureUnitTypeEnum {
  F = 'f'
}

export enum TemperatureUnitTypeEnumV2 {
  F = 'f',
  C = 'c'
}

/** type for a temperature value with unit */
export type TemperatureV2 = {
  readonly __typename?: 'TemperatureV2';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type TemperatureV2Input = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type TemplateSchema = {
  readonly __typename?: 'TemplateSchema';
  readonly id?: Maybe<Scalars['String']>;
  readonly type: Scalars['String'];
  readonly required?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly properties: Scalars['String'];
};

export type TemplateSchemaInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly type: Scalars['String'];
  readonly required?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly properties: Scalars['String'];
};

export type TempLoad = {
  readonly __typename?: 'TempLoad';
  readonly cargoValue?: Maybe<Scalars['Float']>;
  readonly cargoValueCurrency: Scalars['String'];
  readonly commodities?: Maybe<Scalars['String']>;
  readonly consigneeName?: Maybe<Scalars['String']>;
  readonly customerName: Scalars['String'];
  readonly customerOperationsRep2Name?: Maybe<Scalars['String']>;
  readonly customerOperationsRepName?: Maybe<Scalars['String']>;
  readonly customerRepName?: Maybe<Scalars['String']>;
  readonly deliveryTimezone?: Maybe<Scalars['String']>;
  readonly destinationDeadheadInMiles?: Maybe<Scalars['Float']>;
  readonly destinationText?: Maybe<Scalars['String']>;
  readonly equipmentNeeded?: Maybe<Scalars['Json']>;
  readonly hazmat?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly maxPay?: Maybe<Scalars['Float']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly originDeadheadInMiles?: Maybe<Scalars['Float']>;
  readonly originText?: Maybe<Scalars['String']>;
  readonly pickupTimezone: Scalars['String'];
  readonly requiredTruckTypes?: Maybe<Scalars['String']>;
  readonly revenovaDistanceInKilometers?: Maybe<Scalars['Float']>;
  readonly revenovaDistanceInMiles?: Maybe<Scalars['Float']>;
  readonly revenovaLoadId: Scalars['String'];
  readonly revenovaLoadNumber?: Maybe<Scalars['String']>;
  readonly shipperName?: Maybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly team?: Maybe<Scalars['Boolean']>;
  readonly trailerHeight?: Maybe<Scalars['Float']>;
  readonly trailerHeightUnit: Scalars['String'];
  readonly trailerLength?: Maybe<Scalars['Float']>;
  readonly trailerLengthUnit: Scalars['String'];
  readonly trailerWidth?: Maybe<Scalars['Float']>;
  readonly trailerWidthUnit: Scalars['String'];
  readonly utcDeliveryEndDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Replaced by utc_delivery_end_date_time */
  readonly utcDeliveryEndTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly utcDeliveryStartDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Replaced by utc_delivery_start_date_time */
  readonly utcDeliveryStartTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly utcPickupEndDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Replaced by utc_pickup_end_date_time */
  readonly utcPickupEndTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly utcPickupStartDateTime: Scalars['ISO8601DateTime'];
  /** @deprecated Replaced by utc_pickup_start_date_time */
  readonly utcPickupStartTime: Scalars['ISO8601DateTime'];
  readonly weight?: Maybe<Scalars['Float']>;
  readonly weightUnit?: Maybe<Scalars['String']>;
};

/** The connection type for TempLoad. */
export type TempLoadConnection = {
  readonly __typename?: 'TempLoadConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<TempLoadEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TempLoadEdge = {
  readonly __typename?: 'TempLoadEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: TempLoad;
};

export type TempLoadsFilter = {
  /** Search/filter customer name */
  readonly customer?: InputMaybe<Scalars['String']>;
  /** Search/filter customer name */
  readonly customerRepName?: InputMaybe<Scalars['String']>;
  /** Exact match on state */
  readonly destinationState?: InputMaybe<Scalars['String']>;
  /** Search/filter destination location (matches city & state) */
  readonly destinationText?: InputMaybe<Scalars['String']>;
  readonly onlyAvailable?: InputMaybe<Scalars['Boolean']>;
  /** Exact match on state */
  readonly originState?: InputMaybe<Scalars['String']>;
  /** Search/filter origin location (matches city & state) */
  readonly originText?: InputMaybe<Scalars['String']>;
  /** Search/filter load numbers */
  readonly revenovaLoadNumber?: InputMaybe<Scalars['String']>;
  readonly sort?: InputMaybe<Scalars['String']>;
  readonly timezone?: InputMaybe<Scalars['String']>;
  readonly truckType?: InputMaybe<Scalars['String']>;
  /** Delivery start range beginning */
  readonly utcDeliveryStartDatetimeEnd?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Delivery start range beginning */
  readonly utcDeliveryStartDatetimeStart?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Pickup start range beginning */
  readonly utcPickupStartDatetimeEnd?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Pickup start range beginning */
  readonly utcPickupStartDatetimeStart?: InputMaybe<Scalars['ISO8601DateTime']>;
};

export type TempLoadsInput = {
  readonly destination?: InputMaybe<Scalars['String']>;
  readonly origin?: InputMaybe<Scalars['String']>;
  readonly pickupStartDate?: InputMaybe<Scalars['String']>;
  readonly pickupStartDateEnd?: InputMaybe<Scalars['String']>;
};

export enum TempRemittanceGroupingEnum {
  /** Route */
  Route = 'Route',
  /** Combined */
  Combined = 'Combined'
}

export type Tenant = {
  readonly __typename?: 'Tenant';
  readonly name: Scalars['String'];
  readonly code: Scalars['String'];
  readonly active: Scalars['Boolean'];
  readonly createdAt: Scalars['DateTime'];
};

export type TenantChargeTypeConfigurationCreateInput = {
  readonly carrierGlCode?: InputMaybe<Scalars['String']>;
  readonly category?: InputMaybe<Scalars['String']>;
  readonly cost?: InputMaybe<Scalars['Decimal']>;
  readonly costPerUnitType?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly customerGlCode?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly eligibleForFuel: Scalars['Boolean'];
  readonly excludeFromMargins: Scalars['Boolean'];
  readonly excludeFromMaxCost?: InputMaybe<Scalars['Boolean']>;
  readonly isActive: Scalars['Boolean'];
  readonly isCredit?: InputMaybe<Scalars['Boolean']>;
  readonly isGlobal: Scalars['Boolean'];
  readonly maximumCost?: InputMaybe<Scalars['Decimal']>;
  readonly maximumRate?: InputMaybe<Scalars['Decimal']>;
  readonly minimumCost?: InputMaybe<Scalars['Decimal']>;
  readonly minimumRate?: InputMaybe<Scalars['Decimal']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly rate?: InputMaybe<Scalars['Decimal']>;
  readonly ratePerUnitType?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly segment?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly usedOn?: InputMaybe<CtcUsedOnEnum>;
  readonly usedOns?: InputMaybe<ReadonlyArray<InputMaybe<CtcUsedOnEnumV2>>>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly passThroughType?: InputMaybe<Scalars['String']>;
  readonly passThroughAmount?: InputMaybe<Scalars['Decimal']>;
  readonly carrierApprovalRequired?: InputMaybe<Scalars['Boolean']>;
  readonly customerApprovalRequired?: InputMaybe<Scalars['Boolean']>;
  readonly isPreTax?: InputMaybe<Scalars['Boolean']>;
  readonly otherPayApprovalRequired?: InputMaybe<Scalars['Boolean']>;
  readonly ratePerConfigurations?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Guid']>>>;
  readonly costPerConfigurations?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Guid']>>>;
  readonly isTieredRatePerConfiguration?: InputMaybe<Scalars['Boolean']>;
  readonly isTieredCostPerConfiguration?: InputMaybe<Scalars['Boolean']>;
  readonly tieredRateSchedules?: InputMaybe<ReadonlyArray<TieredRateScheduleInput>>;
  readonly tieredCostSchedules?: InputMaybe<ReadonlyArray<TieredCostScheduleInput>>;
};

export type TenantChargeTypeConfigurationCreateInputV2 = {
  readonly carrierGlCode?: InputMaybe<Scalars['String']>;
  readonly category?: InputMaybe<Scalars['String']>;
  readonly customerGlCode?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly eligibleForFuel: Scalars['Boolean'];
  readonly excludeFromMargins: Scalars['Boolean'];
  readonly excludeFromMaxCost?: InputMaybe<Scalars['Boolean']>;
  readonly isActive: Scalars['Boolean'];
  readonly isCredit?: InputMaybe<Scalars['Boolean']>;
  readonly isGlobal: Scalars['Boolean'];
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly segment?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly usedOn?: InputMaybe<CtcUsedOnEnum>;
  readonly usedOns?: InputMaybe<ReadonlyArray<InputMaybe<CtcUsedOnEnumV2>>>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly isPreTax?: InputMaybe<Scalars['Boolean']>;
  readonly otherPayApprovalRequired: Scalars['Boolean'];
  readonly tenantRegionConfigurations: ReadonlyArray<TenantRegionConfigurationInput>;
};

export type TenantChargeTypeConfigurationResponse = {
  readonly __typename?: 'TenantChargeTypeConfigurationResponse';
  readonly carrierGlCode?: Maybe<Scalars['String']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly cost?: Maybe<Scalars['Decimal']>;
  readonly costPerUnitType?: Maybe<CtcPerUnitTypeEnum>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['DateTime'];
  readonly customerGlCode?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly eligibleForFuel: Scalars['Boolean'];
  readonly excludeFromMargins: Scalars['Boolean'];
  readonly excludeFromMaxCost: Scalars['Boolean'];
  readonly isActive: Scalars['Boolean'];
  readonly isCredit: Scalars['Boolean'];
  readonly isGlobal: Scalars['Boolean'];
  readonly maximumCost?: Maybe<Scalars['Decimal']>;
  readonly maximumRate?: Maybe<Scalars['Decimal']>;
  readonly minimumCost?: Maybe<Scalars['Decimal']>;
  readonly minimumRate?: Maybe<Scalars['Decimal']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly rate?: Maybe<Scalars['Decimal']>;
  readonly ratePerUnitType?: Maybe<CtcPerUnitTypeEnum>;
  readonly segment?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn: Scalars['DateTime'];
  readonly usedOn: CtcUsedOnEnum;
  readonly usedOns?: Maybe<ReadonlyArray<CtcUsedOnEnumV2>>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly passThroughType?: Maybe<Scalars['String']>;
  readonly passThroughAmount?: Maybe<Scalars['Decimal']>;
  readonly carrierApprovalRequired: Scalars['Boolean'];
  readonly customerApprovalRequired: Scalars['Boolean'];
  readonly isPreTax?: Maybe<Scalars['Boolean']>;
  readonly otherPayApprovalRequired: Scalars['Boolean'];
  readonly ratePerConfigurations?: Maybe<ReadonlyArray<Maybe<Scalars['Guid']>>>;
  readonly costPerConfigurations?: Maybe<ReadonlyArray<Maybe<Scalars['Guid']>>>;
  readonly isTieredRatePerConfiguration?: Maybe<Scalars['Boolean']>;
  readonly isTieredCostPerConfiguration?: Maybe<Scalars['Boolean']>;
  readonly tieredRateSchedules?: Maybe<ReadonlyArray<Maybe<TieredRateSchedule>>>;
  readonly tieredCostSchedules?: Maybe<ReadonlyArray<Maybe<TieredCostSchedule>>>;
};

export type TenantChargeTypeConfigurationUpdateInput = {
  readonly carrierGlCode?: InputMaybe<Scalars['String']>;
  readonly category?: InputMaybe<Scalars['String']>;
  readonly cost?: InputMaybe<Scalars['Decimal']>;
  readonly costPerUnitType?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly customerGlCode?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly eligibleForFuel: Scalars['Boolean'];
  readonly excludeFromMargins: Scalars['Boolean'];
  readonly excludeFromMaxCost?: InputMaybe<Scalars['Boolean']>;
  readonly isActive: Scalars['Boolean'];
  readonly isGlobal: Scalars['Boolean'];
  readonly maximumCost?: InputMaybe<Scalars['Decimal']>;
  readonly maximumRate?: InputMaybe<Scalars['Decimal']>;
  readonly minimumCost?: InputMaybe<Scalars['Decimal']>;
  readonly minimumRate?: InputMaybe<Scalars['Decimal']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly rate?: InputMaybe<Scalars['Decimal']>;
  readonly ratePerUnitType?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly segment?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly usedOn?: InputMaybe<CtcUsedOnEnum>;
  readonly usedOns?: InputMaybe<ReadonlyArray<InputMaybe<CtcUsedOnEnumV2>>>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly passThroughType?: InputMaybe<Scalars['String']>;
  readonly passThroughAmount?: InputMaybe<Scalars['Decimal']>;
  readonly carrierApprovalRequired?: InputMaybe<Scalars['Boolean']>;
  readonly customerApprovalRequired?: InputMaybe<Scalars['Boolean']>;
  readonly isPreTax?: InputMaybe<Scalars['Boolean']>;
  readonly otherPayApprovalRequired?: InputMaybe<Scalars['Boolean']>;
  readonly ratePerConfigurations?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Guid']>>>;
  readonly costPerConfigurations?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Guid']>>>;
  readonly isTieredRatePerConfiguration?: InputMaybe<Scalars['Boolean']>;
  readonly isTieredCostPerConfiguration?: InputMaybe<Scalars['Boolean']>;
  readonly tieredRateSchedules?: InputMaybe<ReadonlyArray<TieredRateScheduleInput>>;
  readonly tieredCostSchedules?: InputMaybe<ReadonlyArray<TieredCostScheduleInput>>;
};

export type TenantChargeTypeConfigurationUpdateInputV2 = {
  readonly id: Scalars['ID'];
  readonly carrierGlCode?: InputMaybe<Scalars['String']>;
  readonly category?: InputMaybe<Scalars['String']>;
  readonly customerGlCode?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly eligibleForFuel: Scalars['Boolean'];
  readonly excludeFromMargins: Scalars['Boolean'];
  readonly excludeFromMaxCost?: InputMaybe<Scalars['Boolean']>;
  readonly isActive: Scalars['Boolean'];
  readonly isGlobal: Scalars['Boolean'];
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly segment?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly usedOn?: InputMaybe<CtcUsedOnEnum>;
  readonly usedOns?: InputMaybe<ReadonlyArray<InputMaybe<CtcUsedOnEnumV2>>>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly isPreTax?: InputMaybe<Scalars['Boolean']>;
  readonly otherPayApprovalRequired: Scalars['Boolean'];
  readonly tenantRegionConfigurations: ReadonlyArray<TenantRegionConfigurationInput>;
};

export type TenantChargeTypeConfigurationV2Response = {
  readonly __typename?: 'TenantChargeTypeConfigurationV2Response';
  readonly carrierGlCode?: Maybe<Scalars['String']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['DateTime'];
  readonly customerGlCode?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly eligibleForFuel: Scalars['Boolean'];
  readonly excludeFromMargins: Scalars['Boolean'];
  readonly excludeFromMaxCost: Scalars['Boolean'];
  readonly isActive: Scalars['Boolean'];
  readonly isCredit: Scalars['Boolean'];
  readonly isGlobal: Scalars['Boolean'];
  readonly mode?: Maybe<Scalars['String']>;
  readonly segment?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn: Scalars['DateTime'];
  readonly usedOns?: Maybe<ReadonlyArray<CtcUsedOnEnumV2>>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isPreTax?: Maybe<Scalars['Boolean']>;
  readonly otherPayApprovalRequired: Scalars['Boolean'];
  readonly tenantRegionConfigurations?: Maybe<ReadonlyArray<TenantRegionConfiguration>>;
};

/** Tenant-specific configurations */
export type TenantConfig = {
  readonly __typename?: 'TenantConfig';
  readonly buildNumber?: Maybe<Scalars['String']>;
  /** @deprecated Refrain from using TenantConfigType to obtain the current user (unless absolutely necessary), this has been flagged as technical debt and will be moved into its own query. */
  readonly currentEmployee?: Maybe<Employee>;
  /** Data dictionary source */
  readonly dataDictionarySource?: Maybe<Scalars['String']>;
  /** A collection of tenant-configurable options, such as ContactType or AddressType. */
  readonly types: ReadonlyArray<KeyValueCollection>;
};

export type TenantConfiguration = {
  readonly __typename?: 'TenantConfiguration';
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['String']>;
  readonly metaDataJson?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly sort?: Maybe<Scalars['Int']>;
  readonly systemDefined?: Maybe<Scalars['Boolean']>;
};

export type TenantConfigurationResult = {
  readonly __typename?: 'TenantConfigurationResult';
  readonly domain: EntityConfigurationDomain;
  readonly settings: EntityConfigurationSettings;
  readonly lastUpdatedTimestamp?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
};

export type TenantConfigurationsModel = {
  readonly __typename?: 'TenantConfigurationsModel';
  readonly defaultVoucherDateOption?: Maybe<DefaultVoucherDateOptionEnum>;
  readonly createAdjustmentItemsCheckedOption?: Maybe<CreateAdjustmentItemsCheckedOptionEnum>;
  readonly calculateVatCheckedOption?: Maybe<CalculateVatCheckedOptionEnumOutputType>;
};

export type TenantRegionConfiguration = {
  readonly __typename?: 'TenantRegionConfiguration';
  readonly id: Scalars['ID'];
  readonly regionId?: Maybe<Scalars['Guid']>;
  readonly maximumCost?: Maybe<Scalars['Decimal']>;
  readonly maximumRate?: Maybe<Scalars['Decimal']>;
  readonly minimumCost?: Maybe<Scalars['Decimal']>;
  readonly minimumRate?: Maybe<Scalars['Decimal']>;
  readonly cost?: Maybe<Scalars['Decimal']>;
  readonly rate?: Maybe<Scalars['Decimal']>;
  readonly costPerUnitType?: Maybe<CtcPerUnitTypeEnum>;
  readonly ratePerUnitType?: Maybe<CtcPerUnitTypeEnum>;
  readonly passThroughType?: Maybe<Scalars['String']>;
  readonly passThroughAmount?: Maybe<Scalars['Decimal']>;
  readonly carrierApprovalRequired: Scalars['Boolean'];
  readonly customerApprovalRequired: Scalars['Boolean'];
  readonly ratePerConfigurations?: Maybe<ReadonlyArray<Maybe<Scalars['Guid']>>>;
  readonly costPerConfigurations?: Maybe<ReadonlyArray<Maybe<Scalars['Guid']>>>;
  readonly isTieredRatePerConfiguration?: Maybe<Scalars['Boolean']>;
  readonly isTieredCostPerConfiguration?: Maybe<Scalars['Boolean']>;
  readonly tieredRateSchedules?: Maybe<ReadonlyArray<Maybe<TieredRateSchedule>>>;
  readonly tieredCostSchedules?: Maybe<ReadonlyArray<Maybe<TieredCostSchedule>>>;
};

export type TenantRegionConfigurationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly regionId?: InputMaybe<Scalars['Guid']>;
  readonly maximumCost?: InputMaybe<Scalars['Decimal']>;
  readonly maximumRate?: InputMaybe<Scalars['Decimal']>;
  readonly minimumCost?: InputMaybe<Scalars['Decimal']>;
  readonly minimumRate?: InputMaybe<Scalars['Decimal']>;
  readonly cost?: InputMaybe<Scalars['Decimal']>;
  readonly rate?: InputMaybe<Scalars['Decimal']>;
  readonly costPerUnitType?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly ratePerUnitType?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly passThroughType?: InputMaybe<Scalars['String']>;
  readonly passThroughAmount?: InputMaybe<Scalars['Decimal']>;
  readonly carrierApprovalRequired: Scalars['Boolean'];
  readonly customerApprovalRequired: Scalars['Boolean'];
  readonly ratePerConfigurations?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Guid']>>>;
  readonly costPerConfigurations?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Guid']>>>;
  readonly isTieredRatePerConfiguration: Scalars['Boolean'];
  readonly isTieredCostPerConfiguration: Scalars['Boolean'];
  readonly tieredRateSchedules?: InputMaybe<ReadonlyArray<TieredRateScheduleInput>>;
  readonly tieredCostSchedules?: InputMaybe<ReadonlyArray<TieredCostScheduleInput>>;
};

export type Tender = {
  readonly __typename?: 'Tender';
  readonly id: Scalars['ID'];
  readonly tenderStatus?: Maybe<TenderStatusEnum>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly expiresAt?: Maybe<Scalars['DateTime']>;
  readonly tenderedAt?: Maybe<Scalars['DateTime']>;
  readonly respondedAt?: Maybe<Scalars['DateTime']>;
  readonly ordinal?: Maybe<Scalars['Int']>;
  readonly tenderOptions?: Maybe<TenderOptions>;
  readonly lineItems: ReadonlyArray<Maybe<TenderLineItem>>;
  readonly customerRateLineItems?: Maybe<ReadonlyArray<Maybe<TenderCustomerRateLineItem>>>;
  readonly carrierCost?: Maybe<Scalars['Decimal']>;
  readonly carrierLineHaul?: Maybe<Scalars['Decimal']>;
  readonly carrierAccessorials?: Maybe<Scalars['Decimal']>;
  readonly customerRateTotal?: Maybe<Scalars['Decimal']>;
  readonly customerLineHaul?: Maybe<Scalars['Decimal']>;
  readonly customerAccessorials?: Maybe<Scalars['Decimal']>;
  readonly tenderValidationErrors?: Maybe<ReadonlyArray<Maybe<TenderValidationError>>>;
  readonly respondedByContact?: Maybe<TenderContact>;
  readonly costQuoteId?: Maybe<Scalars['ID']>;
  readonly transitDays?: Maybe<Scalars['Int']>;
  readonly responseType?: Maybe<Scalars['String']>;
  readonly pickupDate?: Maybe<Scalars['DateTime']>;
  readonly deliveryDate?: Maybe<Scalars['DateTime']>;
  readonly routeReferences?: Maybe<ReadonlyArray<Maybe<TenderRouteReference>>>;
  readonly externalCarrierQuoteId?: Maybe<Scalars['String']>;
};

export type TenderBusinessDay = {
  readonly __typename?: 'TenderBusinessDay';
  readonly dayOfWeek?: Maybe<TenderDayOfWeekEnum>;
  readonly opensAtMilliseconds?: Maybe<Scalars['Long']>;
  readonly closesAtMilliseconds?: Maybe<Scalars['Long']>;
};

export type TenderBusinessDaysInput = {
  readonly dayOfWeek: TenderDayOfWeekEnum;
  readonly opensAtMilliseconds: Scalars['Long'];
  readonly closesAtMilliseconds: Scalars['Long'];
};

export type TenderContact = {
  readonly __typename?: 'TenderContact';
  readonly id: Scalars['ID'];
  readonly contact?: Maybe<Contact>;
  readonly contactV2?: Maybe<CarrierContact>;
  readonly contactEmailOverride?: Maybe<Scalars['String']>;
  readonly contactPhoneNumberOverride?: Maybe<Scalars['String']>;
  readonly contactFullNameOverride?: Maybe<Scalars['String']>;
  readonly isSavedInDatabase?: Maybe<Scalars['Boolean']>;
};

export type TenderContactInput = {
  readonly contactId: Scalars['ID'];
  readonly contactEmailOverride?: InputMaybe<Scalars['String']>;
  readonly contactPhoneNumberOverride?: InputMaybe<Scalars['String']>;
  readonly contactFullNameOverride?: InputMaybe<Scalars['String']>;
};

export type TenderCustomerRateLineItem = {
  readonly __typename?: 'TenderCustomerRateLineItem';
  readonly chargeTypeV2?: Maybe<ChargeTypeV2>;
  readonly units?: Maybe<Scalars['Decimal']>;
  readonly value?: Maybe<Scalars['Decimal']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly isLineHaul?: Maybe<Scalars['Boolean']>;
};

export enum TenderDayOfWeekEnum {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY'
}

export type TenderLineItem = {
  readonly __typename?: 'TenderLineItem';
  readonly chargeType?: Maybe<ChargeType>;
  readonly chargeTypeV2?: Maybe<ChargeTypeV2>;
  readonly units?: Maybe<Scalars['Decimal']>;
  readonly value?: Maybe<Scalars['Decimal']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly isLineHaul?: Maybe<Scalars['Boolean']>;
};

export type TenderOptions = {
  readonly __typename?: 'TenderOptions';
  readonly parentObjectType?: Maybe<Scalars['String']>;
  readonly parentObjectId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly tenderModeTerm?: Maybe<Scalars['String']>;
  readonly tenderContacts?: Maybe<ReadonlyArray<Maybe<TenderContact>>>;
  readonly businessTimeZoneTerm?: Maybe<Scalars['String']>;
  readonly businessDays?: Maybe<ReadonlyArray<Maybe<TenderBusinessDay>>>;
  readonly tenderExpirationTimeSpan?: Maybe<TenderTimeSpan>;
  readonly tenderLeadTimeSpan?: Maybe<TenderTimeSpan>;
  readonly createdByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedByUser?: Maybe<User>;
  readonly lastUpdatedByUserV2?: Maybe<UserV2>;
  readonly lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  readonly autoTender?: Maybe<Scalars['Boolean']>;
};

export type TenderOptionsBusinessDaysInput = {
  readonly dayOfWeek: TenderOptionsDayOfWeekEnum;
  readonly opensAtMilliseconds: Scalars['Long'];
  readonly closesAtMilliseconds: Scalars['Long'];
};

export type TenderOptionsContactInput = {
  readonly contactId?: InputMaybe<Scalars['ID']>;
  readonly contactEmailOverride?: InputMaybe<Scalars['String']>;
  readonly contactPhoneNumberOverride?: InputMaybe<Scalars['String']>;
};

export enum TenderOptionsDayOfWeekEnum {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY'
}

export type TenderOptionsInput = {
  readonly tenderModeTerm?: InputMaybe<Scalars['String']>;
  readonly tenderContacts?: InputMaybe<ReadonlyArray<InputMaybe<TenderOptionsContactInput>>>;
  readonly businessTimeZoneTerm?: InputMaybe<Scalars['String']>;
  readonly businessDays?: InputMaybe<ReadonlyArray<InputMaybe<TenderOptionsBusinessDaysInput>>>;
  readonly tenderExpirationTimeSpan?: InputMaybe<TenderOptionsTimeSpanInput>;
  readonly tenderLeadTimeSpan?: InputMaybe<TenderOptionsTimeSpanInput>;
  readonly autoTender?: InputMaybe<Scalars['Boolean']>;
};

export type TenderOptionsTimeSpanInput = {
  readonly value: Scalars['Decimal'];
  readonly units: TenderOptionsTimeSpanUnitEnum;
};

export enum TenderOptionsTimeSpanUnitEnum {
  Minutes = 'MINUTES',
  Hours = 'HOURS'
}

export type TenderOptionsV2 = {
  readonly __typename?: 'TenderOptionsV2';
  readonly parentObjectType?: Maybe<Scalars['String']>;
  readonly parentObjectId?: Maybe<Scalars['ID']>;
  readonly coalesce?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly tenderModeTerm?: Maybe<Scalars['String']>;
  readonly tenderContacts?: Maybe<ReadonlyArray<Maybe<TenderContact>>>;
  readonly businessTimeZoneTerm?: Maybe<Scalars['String']>;
  readonly businessDays?: Maybe<ReadonlyArray<Maybe<TenderBusinessDay>>>;
  readonly tenderExpirationTimeSpan?: Maybe<TenderTimeSpan>;
  readonly tenderLeadTimeSpan?: Maybe<TenderTimeSpan>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedByUserV2?: Maybe<UserV2>;
  readonly lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  readonly autoTender?: Maybe<Scalars['Boolean']>;
};

export type TenderPlan = {
  readonly __typename?: 'TenderPlan';
  readonly id: Scalars['ID'];
  readonly routingGuide?: Maybe<RoutingGuide>;
  readonly createdByUser?: Maybe<User>;
  readonly startedByUser?: Maybe<User>;
  readonly stoppedByUser?: Maybe<User>;
  readonly lastUpdatedByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly startedByUserV2?: Maybe<UserV2>;
  readonly stoppedByUserV2?: Maybe<UserV2>;
  readonly lastUpdatedByUserV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly startedAt?: Maybe<Scalars['DateTime']>;
  readonly stoppedAt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  readonly tenderPlanStatus?: Maybe<TenderPlanStatusEnum>;
  readonly tenders: ReadonlyArray<Maybe<Tender>>;
  readonly tenderPlanTypeTerm?: Maybe<Scalars['String']>;
  readonly route?: Maybe<LoadRouteV2>;
  readonly statusReasons?: Maybe<ReadonlyArray<Maybe<TenderPlanStatusReason>>>;
  readonly creationProcess?: Maybe<Scalars['String']>;
  readonly laneType?: Maybe<Scalars['String']>;
  readonly logicType?: Maybe<Scalars['String']>;
  readonly ratingSource?: Maybe<Scalars['String']>;
  readonly spotQuoteCode?: Maybe<Scalars['String']>;
  readonly spotQuoteId?: Maybe<Scalars['ID']>;
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly statusDisplayNameOverride?: Maybe<Scalars['String']>;
  readonly statusForDisplay?: Maybe<Scalars['String']>;
  readonly sentToOpen?: Maybe<Scalars['Boolean']>;
};

export enum TenderPlanStatusEnum {
  Building = 'BUILDING',
  AwaitingExecution = 'AWAITING_EXECUTION',
  Executing = 'EXECUTING',
  Stopped = 'STOPPED',
  Exhausted = 'EXHAUSTED',
  Fulfilled = 'FULFILLED',
  Invalid = 'INVALID',
  Canceled = 'CANCELED',
  Ineligible = 'INELIGIBLE',
  BookingPending = 'BOOKING_PENDING',
  UnableToBook = 'UNABLE_TO_BOOK'
}

export type TenderPlanStatusReason = {
  readonly __typename?: 'TenderPlanStatusReason';
  readonly id: Scalars['ID'];
  readonly reason?: Maybe<Scalars['String']>;
  readonly tenderPlanId: Scalars['ID'];
};

export type TenderRouteReference = {
  readonly __typename?: 'TenderRouteReference';
  readonly id?: Maybe<Scalars['ID']>;
  readonly referenceType?: Maybe<Scalars['String']>;
  readonly referenceNumber?: Maybe<Scalars['String']>;
};

export enum TenderStatusEnum {
  Created = 'CREATED',
  Ineligible = 'INELIGIBLE',
  Skipped = 'SKIPPED',
  Tendered = 'TENDERED',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  TimedOut = 'TIMED_OUT',
  UnableToTender = 'UNABLE_TO_TENDER',
  Rescinded = 'RESCINDED',
  GaveBack = 'GAVE_BACK',
  Eligible = 'ELIGIBLE',
  OverCapacity = 'OVER_CAPACITY',
  Bounced = 'BOUNCED'
}

export type TenderTimeSpan = {
  readonly __typename?: 'TenderTimeSpan';
  readonly value?: Maybe<Scalars['Decimal']>;
  readonly units?: Maybe<TenderTimeSpanUnitEnum>;
};

export type TenderTimeSpanInput = {
  readonly value: Scalars['Decimal'];
  readonly units: TenderTimeSpanUnitEnum;
};

export enum TenderTimeSpanUnitEnum {
  Minutes = 'MINUTES',
  Hours = 'HOURS'
}

export type TenderValidationError = {
  readonly __typename?: 'TenderValidationError';
  readonly message?: Maybe<Scalars['String']>;
};

/** The result of produceTestEvent mutation. For Testing Purposes Only. */
export type TestEventMetadata = {
  readonly __typename?: 'TestEventMetadata';
  /** The topic the test event was sent on. */
  readonly topic: Scalars['String'];
  readonly partition?: Maybe<Scalars['Int']>;
  readonly offset?: Maybe<Scalars['Int']>;
  readonly success: Scalars['Boolean'];
  readonly messageId?: Maybe<Scalars['ID']>;
  readonly triggeredAtUtc?: Maybe<Scalars['DateTime']>;
};

export type TieredCostSchedule = {
  readonly __typename?: 'TieredCostSchedule';
  readonly id?: Maybe<Scalars['ID']>;
  readonly beginRange?: Maybe<Scalars['Decimal']>;
  readonly endRange?: Maybe<Scalars['Decimal']>;
  readonly costPer?: Maybe<Scalars['Decimal']>;
};

export type TieredCostScheduleInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly beginRange?: InputMaybe<Scalars['Decimal']>;
  readonly endRange?: InputMaybe<Scalars['Decimal']>;
  readonly costPer?: InputMaybe<Scalars['Decimal']>;
};

export type TieredRateSchedule = {
  readonly __typename?: 'TieredRateSchedule';
  readonly id?: Maybe<Scalars['ID']>;
  readonly beginRange?: Maybe<Scalars['Decimal']>;
  readonly endRange?: Maybe<Scalars['Decimal']>;
  readonly ratePer?: Maybe<Scalars['Decimal']>;
};

export type TieredRateScheduleInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly beginRange?: InputMaybe<Scalars['Decimal']>;
  readonly endRange?: InputMaybe<Scalars['Decimal']>;
  readonly ratePer?: InputMaybe<Scalars['Decimal']>;
};

export type Time = {
  readonly __typename?: 'Time';
  readonly value?: Maybe<Scalars['String']>;
  readonly timezone?: Maybe<Scalars['String']>;
};

export type TimelineEvent = {
  readonly __typename?: 'TimelineEvent';
  readonly id: Scalars['ID'];
  readonly entryTerm?: Maybe<KeyValue>;
  readonly typeTerm?: Maybe<KeyValue>;
  readonly load: Load;
  readonly route?: Maybe<LoadRoute>;
  readonly carrier?: Maybe<Carrier>;
  readonly stop?: Maybe<Stop>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly plannedDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly plannedTimezone?: Maybe<Scalars['String']>;
  readonly actualDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly actualTimezone?: Maybe<Scalars['String']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly driverNames: ReadonlyArray<Scalars['String']>;
  readonly trailerNumbers: ReadonlyArray<Scalars['String']>;
  readonly notifyUsers: ReadonlyArray<User>;
  readonly createdDatetime: Scalars['ISO8601DateTime'];
  readonly createdBy: User;
  readonly updatedDatetime: Scalars['ISO8601DateTime'];
  readonly updatedBy: User;
};

export type TimelineEventConnection = {
  readonly __typename?: 'TimelineEventConnection';
  readonly edges: ReadonlyArray<TimelineEventEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type TimelineEventConnectionV2 = {
  readonly __typename?: 'TimelineEventConnectionV2';
  readonly edges: ReadonlyArray<TimelineEventV2Edge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type TimelineEventEdge = {
  readonly __typename?: 'TimelineEventEdge';
  readonly cursor: Scalars['String'];
  readonly node: TimelineEvent;
};

export type TimelineEventFilter = {
  readonly loadID?: InputMaybe<Scalars['ID']>;
};

export type TimelineEventFilterV2 = {
  readonly loadID?: InputMaybe<Scalars['ID']>;
};

export type TimelineEventV2 = {
  readonly __typename?: 'TimelineEventV2';
  readonly id: Scalars['ID'];
  readonly entryTerm?: Maybe<KeyValue>;
  readonly typeTerm?: Maybe<KeyValue>;
  readonly load: LoadV2;
  readonly route?: Maybe<LoadRouteV2>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly stopV2?: Maybe<RouteStopV2>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly plannedDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly plannedTimezone?: Maybe<Scalars['String']>;
  readonly actualDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly actualTimezone?: Maybe<Scalars['String']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly driverNames: ReadonlyArray<Scalars['String']>;
  readonly trailerNumbers: ReadonlyArray<Scalars['String']>;
  readonly notifyUsersV2: ReadonlyArray<UserV2>;
  readonly createdDatetime: Scalars['ISO8601DateTime'];
  readonly createdByV2: UserV2;
  readonly updatedDatetime: Scalars['ISO8601DateTime'];
  readonly updatedByV2: UserV2;
};

export type TimelineEventV2Edge = {
  readonly __typename?: 'TimelineEventV2Edge';
  readonly cursor: Scalars['String'];
  readonly node: TimelineEventV2;
};

export type TimeOffLocationInput = {
  readonly start?: InputMaybe<CityStateInput>;
  readonly end?: InputMaybe<CityStateInput>;
};

export type TimeOffLocationOutput = {
  readonly __typename?: 'TimeOffLocationOutput';
  readonly start?: Maybe<CityStateOutput>;
  readonly end?: Maybe<CityStateOutput>;
};

export type TimezoneComponent = {
  readonly __typename?: 'TimezoneComponent';
  readonly timezoneId?: Maybe<Scalars['String']>;
  readonly generic?: Maybe<Scalars['String']>;
  readonly standard?: Maybe<Scalars['String']>;
  readonly daylight?: Maybe<Scalars['String']>;
  readonly tag?: Maybe<Scalars['String']>;
  readonly standardOffset?: Maybe<Scalars['String']>;
  readonly daylightSavings?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['ID']>;
};

export type TimezoneResponse = {
  readonly __typename?: 'TimezoneResponse';
  readonly lat?: Maybe<Scalars['Float']>;
  readonly lon?: Maybe<Scalars['Float']>;
  readonly timezones?: Maybe<ReadonlyArray<TimezoneComponent>>;
};

export type TireInput = {
  readonly width: Scalars['Float'];
  readonly aspectRatio: Scalars['Float'];
  readonly rimSize: Scalars['Float'];
};

export type TireOutput = {
  readonly __typename?: 'TireOutput';
  readonly width: Scalars['Float'];
  readonly aspectRatio: Scalars['Float'];
  readonly rimSize: Scalars['Float'];
};

/** Input type for toggling trailer accessory visibility for a driver assignment */
export type ToggleDriverAssignmentTrailerAccessoriesInput = {
  readonly routeID: Scalars['ID'];
  readonly sequenceNumber: Scalars['Float'];
  readonly areAccessoriesVisible: Scalars['Boolean'];
};

export type ToggleEventEnabledInput = {
  readonly eventId?: InputMaybe<Scalars['String']>;
};

export type ToggleEventEnabledResult = {
  readonly __typename?: 'ToggleEventEnabledResult';
  readonly success?: Maybe<Scalars['Boolean']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly correlationId?: Maybe<Scalars['String']>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
};

/** Input type for toggling trailer accessory visibility for a route resource */
export type ToggleRouteResourcesTrailerAccessoriesInput = {
  readonly routeID: Scalars['ID'];
  readonly sequenceNumber: Scalars['Int'];
  readonly areAccessoriesVisible: Scalars['Boolean'];
  /** Denotes whether trailer is of type Inbound, Loaded, or Outbound. This value is defaulted to Inbound when input is null */
  readonly trailerAssignmentDesignation?: InputMaybe<TrailerAssignmentDesignationEnum>;
};

export type ToggleRuleSetIsActiveInput = {
  readonly ruleSetId: Scalars['String'];
};

export type ToggleRuleSetIsActiveResult = {
  readonly __typename?: 'ToggleRuleSetIsActiveResult';
  readonly success?: Maybe<Scalars['Boolean']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly correlationId?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type TotalCharge = {
  readonly __typename?: 'TotalCharge';
  readonly amount: Scalars['Float'];
  readonly currencyCodeTerm: Scalars['String'];
};

export type Tour = {
  readonly __typename?: 'Tour';
  readonly solutionId?: Maybe<Scalars['Int']>;
  readonly legCount?: Maybe<Scalars['Int']>;
  readonly firstStopDateRange?: Maybe<DateTimeRangeWithTimeZone>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly lastStopDateRange?: Maybe<DateTimeRangeWithTimeZone>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly loadedDistance?: Maybe<Scalars['Int']>;
  readonly totalDeadheadDistance?: Maybe<Scalars['Int']>;
  readonly originDeadheadDistance?: Maybe<Scalars['Int']>;
  readonly destinationDeadheadDistance?: Maybe<Scalars['Int']>;
  readonly isRoundTrip?: Maybe<Scalars['Boolean']>;
  readonly routes?: Maybe<ReadonlyArray<Maybe<AvailableRoute>>>;
};

export type TourSearchInput = {
  readonly truckPostingId?: InputMaybe<Scalars['String']>;
  readonly originRadius?: InputMaybe<WithinRadiusInput>;
  readonly destinationRadius?: InputMaybe<WithinRadiusInput>;
  readonly readyByDate?: InputMaybe<Scalars['DateTime']>;
  readonly loadByDate?: InputMaybe<Scalars['DateTime']>;
  readonly finalByDate?: InputMaybe<Scalars['DateTime']>;
  readonly trailerTypeName?: InputMaybe<Scalars['String']>;
  readonly trailerLength?: InputMaybe<Scalars['Int']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly Division?: InputMaybe<Scalars['String']>;
  readonly destinationStates?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly intrastateAdminAreas?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type TrackingDetailsV2 = {
  readonly __typename?: 'TrackingDetailsV2';
  /** Matches the LoadRouteV2 id */
  readonly id: Scalars['ID'];
  readonly driverAssignment?: Maybe<DriverAssignment>;
  readonly latestTrackingUpdate?: Maybe<TrackingUpdateV4>;
  /** @deprecated causing major performance trouble use trackingUpdatesV3 query and load separately */
  readonly trackingUpdates: ReadonlyArray<TrackingUpdateV4>;
};

export type TrackingDeviceInput = {
  readonly deviceTerm: Scalars['String'];
  readonly modelTerm?: InputMaybe<Scalars['String']>;
  readonly serialNumber: Scalars['String'];
};

export type TrackingDeviceOutput = {
  readonly __typename?: 'TrackingDeviceOutput';
  readonly deviceTerm: Scalars['String'];
  readonly modelTerm?: Maybe<Scalars['String']>;
  readonly serialNumber: Scalars['String'];
};

export type TrackingEta = {
  readonly __typename?: 'TrackingETA';
  readonly id: Scalars['ID'];
  readonly route: LoadRouteV2;
  readonly trackingUpdate?: Maybe<TrackingUpdateV4>;
  readonly autoETADatetime?: Maybe<Scalars['DateTime']>;
  readonly manualETADatetime?: Maybe<Scalars['DateTime']>;
  readonly nextStop: Stop;
  readonly nextRouteStop: RouteStop;
  readonly nextRouteStopV2: RouteStopV2;
  readonly nextStopTimezone?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly isDataScienceFormula?: Maybe<Scalars['Boolean']>;
};

/** Estimated Time of Arrival to next stop */
export type TrackingEtaCalculation = {
  readonly __typename?: 'TrackingETACalculation';
  readonly eta: Scalars['DateTime'];
  readonly nextStopTimezone?: Maybe<Scalars['String']>;
  readonly isDataScienceFormula?: Maybe<Scalars['Boolean']>;
};

export type TrackingEtaForStop = {
  readonly __typename?: 'TrackingETAForStop';
  readonly route: LoadRouteV2;
  readonly nextStop: RouteStop;
  readonly nextStopV2: RouteStopV2;
  readonly autoETADatetime?: Maybe<Scalars['DateTime']>;
  readonly manualETADatetime?: Maybe<Scalars['DateTime']>;
  readonly isDataScienceFormula?: Maybe<Scalars['Boolean']>;
};

export type TrackingPage = {
  readonly __typename?: 'TrackingPage';
  readonly id: Scalars['ID'];
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Float']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly routeCount?: Maybe<Scalars['Float']>;
  readonly orderTenderStatus?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly originStopId?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndTime?: Maybe<Scalars['Float']>;
  readonly originStopEndTimezone?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopStartTime?: Maybe<Scalars['Float']>;
  readonly originStopStartTimezone?: Maybe<Scalars['String']>;
  readonly originStopStatus?: Maybe<Scalars['String']>;
  readonly originStopRequirements?: Maybe<Scalars['String']>;
  readonly destStopStatus?: Maybe<Scalars['String']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndTime?: Maybe<Scalars['Float']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTime?: Maybe<Scalars['Float']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly destStopId?: Maybe<Scalars['String']>;
  readonly destStopRequirements?: Maybe<Scalars['String']>;
  readonly nextStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextStopEndTime?: Maybe<Scalars['Float']>;
  readonly nextStopStartTimezone?: Maybe<Scalars['String']>;
  readonly nextStopType?: Maybe<Scalars['String']>;
  readonly nextStopRequirements?: Maybe<Scalars['String']>;
  readonly currentStopType?: Maybe<Scalars['String']>;
  readonly currentStopRequirements?: Maybe<Scalars['String']>;
  readonly nextStopAutoEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly nextStopManualEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly lockId?: Maybe<Scalars['String']>;
  readonly routeBookedByName?: Maybe<Scalars['String']>;
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  readonly mainRouteRep?: Maybe<Scalars['String']>;
  readonly trackingAgCreateDate?: Maybe<Scalars['DateTime']>;
  readonly driverElectronicTrackingType?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverPhone?: Maybe<Scalars['String']>;
  readonly emptyDatetime?: Maybe<Scalars['DateTime']>;
  readonly lastTrackingNote?: Maybe<Scalars['String']>;
  readonly latestElectronicLocationUpdateDatetime?: Maybe<Scalars['String']>;
  readonly latestTrackingRequestDatetime?: Maybe<Scalars['DateTime']>;
  readonly nextRouteId?: Maybe<Scalars['String']>;
  readonly nextCallBackDatetime?: Maybe<Scalars['DateTime']>;
  readonly previousRouteId?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly integrationName?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly orderRequirements?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly nextStopFacilityName?: Maybe<Scalars['String']>;
  readonly nextStopCity?: Maybe<Scalars['String']>;
  readonly nextStopState?: Maybe<Scalars['String']>;
  readonly carrierExecutingDivision?: Maybe<Scalars['String']>;
  readonly trackingLocationCity?: Maybe<Scalars['String']>;
  readonly trackingLocationState?: Maybe<Scalars['String']>;
  readonly isHotRoute?: Maybe<Scalars['Boolean']>;
  readonly routeDivision?: Maybe<Scalars['String']>;
  readonly orderReferences?: Maybe<Scalars['String']>;
  readonly bookingSourceId?: Maybe<Scalars['String']>;
  readonly waybillStatus?: Maybe<Scalars['String']>;
  readonly waybillDatetime?: Maybe<Scalars['DateTime']>;
  readonly masterSignStatus?: Maybe<Scalars['String']>;
  readonly powerId?: Maybe<Scalars['String']>;
  readonly powerCode?: Maybe<Scalars['String']>;
  readonly trackingUpdateUserName?: Maybe<Scalars['String']>;
  readonly routeCapacityNeed?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly preplanSent?: Maybe<Scalars['DateTime']>;
  readonly locationDescription?: Maybe<Scalars['String']>;
  readonly speed?: Maybe<Scalars['String']>;
  readonly fuelLevelPercent?: Maybe<Scalars['Float']>;
  readonly locationRelativePositionDirection?: Maybe<Scalars['String']>;
  readonly distanceToNext?: Maybe<Scalars['Float']>;
  readonly distanceToNextUnit?: Maybe<Scalars['String']>;
  readonly nextStopFacilityId?: Maybe<Scalars['ID']>;
  readonly currentStopFacilityName?: Maybe<Scalars['String']>;
  readonly currentStopFacilityId?: Maybe<Scalars['ID']>;
  readonly serviceLevel?: Maybe<Scalars['String']>;
  readonly driver1Id?: Maybe<Scalars['ID']>;
  readonly resourceType?: Maybe<Scalars['String']>;
  readonly inboundTrailerIdentifier1?: Maybe<Scalars['String']>;
  readonly inboundTrailerTypeId1?: Maybe<Scalars['String']>;
  readonly inboundTrailerRecordId1?: Maybe<Scalars['String']>;
  readonly inboundTrailerIdentifier2?: Maybe<Scalars['String']>;
  readonly inboundTrailerTypeId2?: Maybe<Scalars['String']>;
  readonly inboundTrailerRecordId2?: Maybe<Scalars['String']>;
  readonly inboundTrailerIdentifier3?: Maybe<Scalars['String']>;
  readonly inboundTrailerTypeId3?: Maybe<Scalars['String']>;
  readonly inboundTrailerRecordId3?: Maybe<Scalars['String']>;
  readonly outboundTrailerIdentifier1?: Maybe<Scalars['String']>;
  readonly outboundTrailerTypeId1?: Maybe<Scalars['String']>;
  readonly outboundTrailerRecordId1?: Maybe<Scalars['String']>;
  readonly outboundTrailerIdentifier2?: Maybe<Scalars['String']>;
  readonly outboundTrailerTypeId2?: Maybe<Scalars['String']>;
  readonly outboundTrailerRecordId2?: Maybe<Scalars['String']>;
  readonly outboundTrailerIdentifier3?: Maybe<Scalars['String']>;
  readonly outboundTrailerTypeId3?: Maybe<Scalars['String']>;
  readonly outboundTrailerRecordId3?: Maybe<Scalars['String']>;
  readonly loadedTrailerIdentifier1?: Maybe<Scalars['String']>;
  readonly loadedTrailerTypeId1?: Maybe<Scalars['String']>;
  readonly loadedTrailerRecordId1?: Maybe<Scalars['String']>;
  readonly loadedTrailerIdentifier2?: Maybe<Scalars['String']>;
  readonly loadedTrailerTypeId2?: Maybe<Scalars['String']>;
  readonly loadedTrailerRecordId2?: Maybe<Scalars['String']>;
  readonly loadedTrailerIdentifier3?: Maybe<Scalars['String']>;
  readonly loadedTrailerTypeId3?: Maybe<Scalars['String']>;
  readonly loadedTrailerRecordId3?: Maybe<Scalars['String']>;
  readonly previousStopFacilityName?: Maybe<Scalars['String']>;
  readonly previousStopFacilityId?: Maybe<Scalars['ID']>;
  readonly previousStopState?: Maybe<Scalars['String']>;
  readonly previousStopCity?: Maybe<Scalars['String']>;
  readonly routeBookedById?: Maybe<Scalars['String']>;
  readonly reqArriveDate?: Maybe<Scalars['DateTime']>;
  readonly calculatedDirectionHeading?: Maybe<Scalars['String']>;
  readonly providerETA?: Maybe<Scalars['DateTime']>;
  readonly orderReferencesType?: Maybe<Scalars['String']>;
  readonly orderReferencesValue?: Maybe<Scalars['String']>;
  readonly routeReferencesType?: Maybe<Scalars['String']>;
  readonly routeReferencesValue?: Maybe<Scalars['String']>;
  readonly carrierRouteReferencesType?: Maybe<Scalars['String']>;
  readonly carrierRouteReferencesValue?: Maybe<Scalars['String']>;
  readonly stopReferencesType?: Maybe<Scalars['String']>;
  readonly stopReferencesValue?: Maybe<Scalars['String']>;
  readonly routeStopRequirements?: Maybe<Scalars['String']>;
};

export type TrackingPageConnection = {
  readonly __typename?: 'TrackingPageConnection';
  readonly edges: ReadonlyArray<TrackingPageEdge>;
  readonly pageInfo: PageInfo;
};

export type TrackingPageEdge = {
  readonly __typename?: 'TrackingPageEdge';
  readonly node: TrackingPage;
  readonly cursor: Scalars['String'];
};

export type TrackingPageFilterInput = {
  /** previously puStartDate value1 */
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  /** Previously puStartDate value2 */
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  /** previously delStartDate value1 */
  readonly destStopStartDate?: InputMaybe<Scalars['DateTime']>;
  /** Previously delEndDate value2 */
  readonly destStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly routeLifeCycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly destStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopRegionConcatenated?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Previously groupId */
  readonly repGroup?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerGroup?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverElectronicTrackingType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trackingStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Previously infoFrom */
  readonly integrationName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly nextCallBackDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly carrierExecutingDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly bookingSourceId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly capacityNeed?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly equip?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly powerId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly powerCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderRepId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Created for ordergroupId */
  readonly orderRepGroup?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Created for Route RepId */
  readonly routeRepId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Created for Route GroupId */
  readonly routeRepGroup?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly distanceToNext?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly distanceToNextUnit?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly stopRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly nextStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly currentStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly currentStopFacilityName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly serviceLevel?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driver1Id?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly resourceType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly inboundTrailerIdentifier1?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly inboundTrailerIdentifier2?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly inboundTrailerIdentifier3?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundTrailerIdentifier1?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundTrailerIdentifier2?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundTrailerIdentifier3?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadedTrailerIdentifier1?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadedTrailerIdentifier2?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadedTrailerIdentifier3?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverPhone?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeStopCount?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly nextStopAutoEtaDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly nextStopManualEtaDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly lastTrackingNote?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Tracking Agcreated Date */
  readonly trackingAgCreateDate?: InputMaybe<Scalars['DateTime']>;
  readonly routeBookedById?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderReferences?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trackingLocationCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trackingLocationState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly nextStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly nextStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly nextStopFacilityName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly previousStopFacilityName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly previousStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly previousStopState?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly previousStopCity?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly waybillStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly waybillDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly isHotRoute?: InputMaybe<Scalars['Boolean']>;
  readonly masterSignStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly locationDescription?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly speed?: InputMaybe<Scalars['Float']>;
  readonly fuelLevelPercent?: InputMaybe<Scalars['Float']>;
  readonly locationRelativePositionDirection?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly nextStopType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly reqArriveDate?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly calculatedDirectionHeading?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly providerETA?: InputMaybe<Scalars['DateTime']>;
  readonly orderReference?: InputMaybe<Scalars['String']>;
  readonly routeReference?: InputMaybe<Scalars['String']>;
  readonly carrierRouteReference?: InputMaybe<Scalars['String']>;
  readonly stopReference?: InputMaybe<Scalars['String']>;
  readonly routeStopRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type TrackingStop = {
  readonly __typename?: 'TrackingStop';
  readonly stopId: Scalars['ID'];
  readonly arrival: StopDetail;
  readonly departure: StopDetail;
};

export type TrackingStopV2 = {
  readonly __typename?: 'TrackingStopV2';
  readonly stopId: Scalars['ID'];
  readonly arrival: StopDetailV2;
  readonly departure: StopDetailV2;
};

export type TrackingSystem = {
  readonly __typename?: 'TrackingSystem';
  /** Corresponds to a `Driver Electronic Tracking Type` Minion DDT Option ID */
  readonly id: Scalars['ID'];
  readonly displayName: Scalars['String'];
};

export type TrackingUpdateAddressV4 = {
  readonly __typename?: 'TrackingUpdateAddressV4';
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
};

export type TrackingUpdateConfiguration = {
  readonly __typename?: 'TrackingUpdateConfiguration';
  readonly id?: Maybe<Scalars['String']>;
  readonly customer_code?: Maybe<Scalars['String']>;
  readonly x6_occurrence_type?: Maybe<Scalars['String']>;
  readonly x6_occurrence_value?: Maybe<Scalars['String']>;
};

export type TrackingUpdateInputV4 = {
  readonly employeeID: Scalars['ID'];
  readonly routeID: Scalars['ID'];
  readonly carrierID: Scalars['ID'];
  readonly actionTypeID: Scalars['ID'];
  readonly infoFromID: Scalars['ID'];
  readonly delayReasonID?: InputMaybe<Scalars['ID']>;
  readonly currentLocation?: InputMaybe<TrackingUpdateLocationInputV4>;
  readonly checkCallDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly checkCallTimezone?: InputMaybe<Scalars['String']>;
  readonly etaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly etaTimezone?: InputMaybe<Scalars['String']>;
  readonly nextLocation?: InputMaybe<TrackingUpdateLocationInputV4>;
  readonly trailerTemp?: InputMaybe<TemperatureV2Input>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly sourceCreatedDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly sourceCreatedTimezone?: InputMaybe<Scalars['String']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly nextStopID?: InputMaybe<Scalars['ID']>;
  readonly sourceFrom?: InputMaybe<Scalars['String']>;
  readonly notifyUserIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type TrackingUpdateLocationInputV4 = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly latitude?: InputMaybe<Scalars['Float']>;
  readonly longitude?: InputMaybe<Scalars['Float']>;
  readonly displayString?: InputMaybe<Scalars['String']>;
};

export type TrackingUpdatesInput = {
  readonly carrierCode: Scalars['String'];
  readonly routeNumber: Scalars['String'];
  readonly actionTypeId: Scalars['String'];
  readonly sourceCreatedDatetime?: InputMaybe<Scalars['String']>;
  readonly sourceCreatedTimezone?: InputMaybe<Scalars['String']>;
  readonly etaDatetime?: InputMaybe<Scalars['String']>;
  readonly etaTimezone?: InputMaybe<Scalars['String']>;
  readonly checkCallDatetime?: InputMaybe<Scalars['String']>;
  readonly checkCallTimezone?: InputMaybe<Scalars['String']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly currentLocation?: InputMaybe<LocationTrackingInput>;
  readonly nextLocation?: InputMaybe<LocationTrackingInput>;
};

export type TrackingUpdateV4 = {
  readonly __typename?: 'TrackingUpdateV4';
  readonly id: Scalars['ID'];
  readonly trackingAutoETADatetime?: Maybe<Scalars['DateTime']>;
  readonly trackingManualETADatetime?: Maybe<Scalars['DateTime']>;
  /** @deprecated Type federated by decommissioned Mastery API Rails service. Will always yield null */
  readonly trackingManualETACreatedBy?: Maybe<Employee>;
  readonly trackingManualETACreatedByV2?: Maybe<EmployeeV2>;
  readonly trackingAutoETASource?: Maybe<Scalars['String']>;
  readonly trackingAutoETAUsesDataScienceFormula?: Maybe<Scalars['Boolean']>;
  readonly route: LoadRouteV2;
  /** @deprecated Carrier is a deprecated type. Use carrierV2 */
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2: CarrierV2;
  readonly createdBy: Employee;
  readonly createdByV2: EmployeeV2;
  readonly createdDatetime: Scalars['ISO8601DateTime'];
  readonly sourceCreatedDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly sourceCreatedTimezone?: Maybe<Scalars['String']>;
  readonly actionType?: Maybe<KeyValue>;
  readonly infoFrom?: Maybe<KeyValue>;
  readonly delayReason?: Maybe<KeyValue>;
  readonly currentLocation?: Maybe<Location>;
  readonly currentLocationAddress?: Maybe<TrackingUpdateAddressV4>;
  readonly checkCallDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly etaDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly etaTimezone?: Maybe<Scalars['String']>;
  readonly nextLocation?: Maybe<Location>;
  /** Represents the next location address including city, state, postal code, and country information */
  readonly nextLocationAddress?: Maybe<TrackingUpdateAddressV4>;
  /** @deprecated RouteStop is a deprecated type. Use nextStopV2 */
  readonly nextStop?: Maybe<RouteStop>;
  readonly nextStopV2?: Maybe<RouteStopV2>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly trailerNumber2?: Maybe<Scalars['String']>;
  readonly trailerNumber3?: Maybe<Scalars['String']>;
  readonly trailerTemp?: Maybe<TemperatureV2>;
  readonly routeStopID?: Maybe<Scalars['ID']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly distanceToNext?: Maybe<Scalars['Float']>;
  readonly tenantID: Scalars['String'];
  /** @deprecated Removed in Rails decommission -- use notifyUsersV2 */
  readonly notifyUsers?: Maybe<ReadonlyArray<User>>;
  readonly notifyUsersV2?: Maybe<ReadonlyArray<UserV2>>;
  /** @deprecated No longer used. Please Use trackingAutoETADatetime */
  readonly autoETADatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly isETAManual: Scalars['Boolean'];
  readonly isETAVersion1: Scalars['Boolean'];
  readonly driverElectronicTrackingTypeID?: Maybe<Scalars['String']>;
  readonly driverNames?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
  /** Represents the location ping details. */
  readonly locationPing?: Maybe<LocationPing>;
};

export type TrackingUpdateV4Connection = {
  readonly __typename?: 'TrackingUpdateV4Connection';
  readonly edges: ReadonlyArray<TrackingUpdateV4Edge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type TrackingUpdateV4Edge = {
  readonly __typename?: 'TrackingUpdateV4Edge';
  readonly cursor: Scalars['String'];
  readonly node: TrackingUpdateV4;
};

export type TrackingUpdateV4Filter = {
  /** DEPRECATED: routeIDs filter takes precedence */
  readonly routeID?: InputMaybe<Scalars['ID']>;
  readonly routeIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Currently does nothing */
  readonly hideDuplicateTech?: InputMaybe<Scalars['Boolean']>;
  readonly infoFromIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** this is a temporary type until we can reference users across the graph */
export type TrackingUserMeta = {
  readonly __typename?: 'TrackingUserMeta';
  readonly displayName: Scalars['String'];
  readonly userID: Scalars['ID'];
};

/** this is a temporary type until we can reference users across the graph */
export type TrackingUserMetaInput = {
  readonly userID: Scalars['ID'];
  readonly displayName?: InputMaybe<Scalars['String']>;
};

export type Trailer = {
  readonly __typename?: 'Trailer';
  readonly id: Scalars['ID'];
  readonly createdBy: EmployeeV2;
  readonly lastUpdatedBy: EmployeeV2;
  readonly trailerID: Scalars['String'];
  readonly sortIndex: Scalars['Float'];
  /** DEPRECATED - DO NOT USE, Use assetTrailerRecord field instead, to support federated trailer data */
  readonly assetTrailer?: Maybe<AssetTrailersV2>;
  readonly trailerValidationResponse?: Maybe<Scalars['String']>;
  readonly trailerValidationMessage?: Maybe<Scalars['String']>;
  readonly trailerValidationLastUpdate?: Maybe<Scalars['DateTime']>;
  readonly assetTrailerRecord?: Maybe<AssetTrailers>;
  /** The read only status set by Trailer Assignment Orchestration. If a trailer is read only, it cannot be edited via the Stop Events Grid in Mastermind UI. */
  readonly isReadOnly?: Maybe<Scalars['Boolean']>;
};

export type TrailerActiveFleetFilter = {
  readonly carrierIds: ReadonlyArray<Scalars['ID']>;
  readonly date?: InputMaybe<Scalars['Date']>;
  readonly serviceStatus?: InputMaybe<Scalars['String']>;
};

export type TrailerActiveFleetPayload = {
  readonly __typename?: 'TrailerActiveFleetPayload';
  readonly edges: ReadonlyArray<TrailerFleetSearchOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
};

/** Denotes whether trailer is of type Inbound, Loaded, or Outbound. */
export enum TrailerAssignmentDesignationEnum {
  Inbound = 'Inbound',
  Loaded = 'Loaded',
  Outbound = 'Outbound'
}

export type TrailerAutocompleteFilter = {
  readonly searchTerm?: InputMaybe<Scalars['String']>;
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly date?: InputMaybe<Scalars['Date']>;
  readonly limit?: InputMaybe<Scalars['Int']>;
};

export type TrailerAutocompleteOutput = {
  readonly __typename?: 'TrailerAutocompleteOutput';
  readonly score: Scalars['Float'];
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly displayName?: Maybe<Scalars['String']>;
  readonly typeTerm?: Maybe<Scalars['String']>;
  /** @deprecated carrierId is deprecated. Use carrier.id instead. */
  readonly carrierId?: Maybe<Scalars['ID']>;
  /** @deprecated carrierName is deprecated. Use carrier.name instead. */
  readonly carrierName?: Maybe<Scalars['String']>;
  /** This field describes the active fleet to which the trailer belongs to. */
  readonly carrier?: Maybe<CarrierV2>;
};

export type TrailerAvailabilityFilters = {
  readonly trailerCode: Scalars['String'];
  readonly dateFrom: Scalars['DateTime'];
  readonly dateTo: Scalars['DateTime'];
  readonly timeFrom?: InputMaybe<Scalars['String']>;
  readonly timeTo?: InputMaybe<Scalars['String']>;
};

export type TrailerAvailabilityOutput = {
  readonly __typename?: 'TrailerAvailabilityOutput';
  readonly typeTerm: Scalars['String'];
  readonly expected?: Maybe<DateTimeEntryOutput>;
  readonly estimatedMaintenanceTime?: Maybe<Scalars['Int']>;
  readonly location?: Maybe<TrailerMaintenanceLocationOutput>;
};

export type TrailerAvailabilityPayload = {
  readonly __typename?: 'TrailerAvailabilityPayload';
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
  readonly trailerAvailability?: Maybe<ReadonlyArray<TrailerAvailabilityOutput>>;
};

export type TrailerConnection = {
  readonly __typename?: 'TrailerConnection';
  readonly edges: ReadonlyArray<AssetTrailersEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
};

export type TrailerCustomerFilters = {
  readonly trailerId: Scalars['String'];
  readonly isExpired?: InputMaybe<Scalars['Boolean']>;
};

export type TrailerCustomerPayload = {
  readonly __typename?: 'TrailerCustomerPayload';
  readonly edges: ReadonlyArray<TrailerCustomerRelationshipOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
};

export type TrailerCustomerRelationshipInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly customerId: Scalars['ID'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly trailerId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type TrailerCustomerRelationshipObjectInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly customerId: Scalars['ID'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly trailerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type TrailerCustomerRelationshipOutput = {
  readonly __typename?: 'TrailerCustomerRelationshipOutput';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly customer: CustomerV2;
  readonly trailerId: Scalars['ID'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type TrailerCustomerRelationshipOutputEdge = {
  readonly __typename?: 'TrailerCustomerRelationshipOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: TrailerCustomerRelationshipOutput;
};

export type TrailerCustomerRelationshipPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly effectiveDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly trailerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type TrailerDimensionInput = {
  readonly length: DimensionInput;
  readonly width: DimensionInput;
  readonly height: DimensionInput;
};

export type TrailerDimensionInputV2 = {
  readonly length?: InputMaybe<DimensionInput>;
  readonly width?: InputMaybe<DimensionInput>;
  readonly height?: InputMaybe<DimensionInput>;
};

export type TrailerDimensions = {
  readonly minimumLength?: InputMaybe<SniQuoteDimensions>;
  readonly width?: InputMaybe<SniQuoteDimensions>;
  readonly height?: InputMaybe<SniQuoteDimensions>;
  readonly length?: InputMaybe<SniQuoteDimensions>;
};

export type TrailerDisplayName = {
  readonly __typename?: 'TrailerDisplayName';
  readonly dispatch?: Maybe<Scalars['String']>;
  readonly complete?: Maybe<Scalars['String']>;
};

export type TrailerDriverOutput = {
  readonly __typename?: 'TrailerDriverOutput';
  readonly driverId: Scalars['ID'];
  /** @deprecated driverIdentityId is deprecated. Use code instead. */
  readonly driverIdentityId?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly trailerDriverMapping?: Maybe<ReadonlyArray<AssetTrailersPermanentDriverOutput>>;
};

export type TrailerEquipmentInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId?: InputMaybe<Scalars['String']>;
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly returnDate?: InputMaybe<Scalars['Date']>;
  readonly trailerId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type TrailerEquipmentObjectInput = {
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId?: InputMaybe<Scalars['String']>;
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly returnDate?: InputMaybe<Scalars['Date']>;
  readonly trailerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type TrailerEquipmentOutput = {
  readonly __typename?: 'TrailerEquipmentOutput';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId?: Maybe<Scalars['String']>;
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly returnDate?: Maybe<Scalars['Date']>;
  readonly trailerId: Scalars['ID'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type TrailerEquipmentOutputEdge = {
  readonly __typename?: 'TrailerEquipmentOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: TrailerEquipmentOutput;
};

export type TrailerEquipmentPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly count?: InputMaybe<Scalars['Int']>;
  readonly assetId?: InputMaybe<Scalars['String']>;
  readonly conditionTerm?: InputMaybe<Scalars['String']>;
  readonly issueDate?: InputMaybe<Scalars['Date']>;
  readonly returnDate?: InputMaybe<Scalars['Date']>;
  readonly trailerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type TrailerEquipmentPayload = {
  readonly __typename?: 'TrailerEquipmentPayload';
  readonly edges: ReadonlyArray<TrailerEquipmentOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
};

export enum TrailerExecutionEventType {
  AcquisitionOorCalculated = 'ACQUISITION_OOR_CALCULATED',
  DispositionOorCalculated = 'DISPOSITION_OOR_CALCULATED'
}

export type TrailerFleet = {
  readonly __typename?: 'TrailerFleet';
  readonly id: Scalars['ID'];
  /**
   * This field describes the type of relation between driver and carrier
   * @deprecated type is deprecated. Use typeTerm instead.
   */
  readonly type: Scalars['String'];
  readonly typeTerm: Scalars['String'];
  /** This field describes the effective date of the trailer's fleet assignment. */
  readonly effectiveDate: Scalars['Date'];
  /** This field describes the expiration date of trailer's fleet assignment */
  readonly expirationDate?: Maybe<Scalars['Date']>;
  /** This field describes the fleet to which the trailer belongs to. */
  readonly carrier: CarrierV2;
  readonly trailerId: Scalars['ID'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type TrailerFleetConnection = {
  readonly __typename?: 'TrailerFleetConnection';
  readonly edges: ReadonlyArray<TrailerFleetEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
};

export type TrailerFleetEdge = {
  readonly __typename?: 'TrailerFleetEdge';
  readonly cursor: Scalars['String'];
  readonly node: TrailerFleet;
};

export type TrailerFleetFilters = {
  readonly trailerId: Scalars['String'];
  readonly isExpired?: InputMaybe<Scalars['Boolean']>;
};

export type TrailerFleetInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly carrierId: Scalars['String'];
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly type: Scalars['String'];
  readonly trailerId: Scalars['String'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type TrailerFleetRelationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly carrierId: Scalars['ID'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly trailerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type TrailerFleetRelationPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly effectiveDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly trailerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type TrailerFleetSearchOutput = {
  readonly __typename?: 'TrailerFleetSearchOutput';
  readonly code: Scalars['String'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly permanentPowerUnitCode?: Maybe<Scalars['String']>;
  readonly permanentDriverCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly operatingStatusTerm?: Maybe<Scalars['String']>;
  readonly serviceStatusTerm?: Maybe<Scalars['String']>;
  readonly serviceStatusDetails?: Maybe<ServiceStatusDetailsOutput>;
  readonly year?: Maybe<Scalars['Int']>;
  readonly isCARBCompliant?: Maybe<Scalars['Boolean']>;
  readonly trailerLength?: Maybe<UnitOfLengthOutputV3>;
  readonly trailerInteriorWidth?: Maybe<UnitOfLengthOutputV3>;
  readonly trailerFrontInteriorHeight?: Maybe<UnitOfLengthOutputV2>;
  readonly trailerDoorWidth?: Maybe<UnitOfLengthOutputV3>;
  readonly fleets?: Maybe<ReadonlyArray<TrailerFleet>>;
  readonly power?: Maybe<AssetPowerOutput>;
  readonly drivers?: Maybe<ReadonlyArray<AssetDriver>>;
};

export type TrailerFleetSearchOutputEdge = {
  readonly __typename?: 'TrailerFleetSearchOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: TrailerFleetSearchOutput;
};

export type TrailerIdentifierInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly value: Scalars['String'];
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly trailerId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type TrailerIdentifierObjectInput = {
  readonly typeTerm: Scalars['String'];
  readonly value: Scalars['String'];
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly trailerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type TrailerIdentifierOutput = {
  readonly __typename?: 'TrailerIdentifierOutput';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly value: Scalars['String'];
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly trailerId: Scalars['ID'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type TrailerIdentifierOutputEdge = {
  readonly __typename?: 'TrailerIdentifierOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: TrailerIdentifierOutput;
};

export type TrailerIdentifierPatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly trailerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type TrailerIdentifierPayload = {
  readonly __typename?: 'TrailerIdentifierPayload';
  readonly edges: ReadonlyArray<TrailerIdentifierOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
};

export type TrailerInput = {
  readonly trailerID: Scalars['String'];
  readonly trailerIndex?: InputMaybe<Scalars['Float']>;
  readonly trailerValidationResponse?: InputMaybe<Scalars['String']>;
  readonly trailerValidationMessage?: InputMaybe<Scalars['String']>;
  readonly trailerRecordID?: InputMaybe<Scalars['String']>;
};

export type TrailerMaintenanceInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm: Scalars['String'];
  readonly severityTerm: Scalars['String'];
  readonly isPlanned?: InputMaybe<Scalars['Boolean']>;
  readonly location?: InputMaybe<MaintenanceLocationInput>;
  readonly estimatedMaintenanceTime?: InputMaybe<Scalars['Int']>;
  readonly actual?: InputMaybe<DateTimeEntryInput>;
  readonly expected?: InputMaybe<DateTimeEntryInput>;
  readonly workPerformedDetail?: InputMaybe<Scalars['String']>;
  readonly trailerId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type TrailerMaintenanceLocationOutput = {
  readonly __typename?: 'TrailerMaintenanceLocationOutput';
  readonly facilityCode?: Maybe<Scalars['String']>;
  readonly facility?: Maybe<FacilityV2>;
  readonly cityState?: Maybe<CityStateOutput>;
};

export type TrailerMaintenanceObjectInput = {
  readonly typeTerm: Scalars['String'];
  readonly severityTerm: Scalars['String'];
  readonly isPlanned?: InputMaybe<Scalars['Boolean']>;
  readonly location?: InputMaybe<MaintenanceLocationInput>;
  readonly estimatedMaintenanceTime?: InputMaybe<Scalars['Int']>;
  readonly actual?: InputMaybe<DateTimeEntryInput>;
  readonly expected?: InputMaybe<DateTimeEntryInput>;
  readonly workPerformedDetail?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type TrailerMaintenanceOutput = {
  readonly __typename?: 'TrailerMaintenanceOutput';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly severityTerm: Scalars['String'];
  readonly isPlanned?: Maybe<Scalars['Boolean']>;
  /** @deprecated location is deprecated. Use maintenanceLocation instead */
  readonly location?: Maybe<MaintenanceLocationOutput>;
  readonly maintenanceLocation?: Maybe<TrailerMaintenanceLocationOutput>;
  readonly estimatedMaintenanceTime?: Maybe<Scalars['Int']>;
  readonly actual?: Maybe<DateTimeEntryOutput>;
  readonly expected?: Maybe<DateTimeEntryOutput>;
  readonly workPerformedDetail?: Maybe<Scalars['String']>;
  readonly trailerId: Scalars['ID'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type TrailerMaintenanceOutputEdge = {
  readonly __typename?: 'TrailerMaintenanceOutputEdge';
  readonly cursor: Scalars['String'];
  readonly node: TrailerMaintenanceOutput;
};

export type TrailerMaintenancePatchInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly severityTerm?: InputMaybe<Scalars['String']>;
  readonly isPlanned?: InputMaybe<Scalars['Boolean']>;
  readonly location?: InputMaybe<MaintenanceLocationInput>;
  readonly estimatedMaintenanceTime?: InputMaybe<Scalars['Int']>;
  readonly actual?: InputMaybe<DateTimeEntryInput>;
  readonly expected?: InputMaybe<DateTimeEntryInput>;
  readonly workPerformedDetail?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type TrailerMaintenancePayload = {
  readonly __typename?: 'TrailerMaintenancePayload';
  readonly edges: ReadonlyArray<TrailerMaintenanceOutputEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
};

export type TrailerNumberInput = {
  readonly trailerNumber: Scalars['String'];
};

export type TrailerPaginationFilters = {
  readonly trailerId: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
};

export type TrailerRouteList = {
  readonly __typename?: 'TrailerRouteList';
  /** Route ID, says id for frontend caching purposes, used to hyperlink routeCode */
  readonly id: Scalars['ID'];
  readonly routeCode: Scalars['ID'];
  /** load ID */
  readonly loadId: Scalars['ID'];
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  /** For hyperlinking customerName */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly originStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly originStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly originStopFacilityName?: Maybe<Scalars['String']>;
  /** For hyperlinking originStopFacilityName */
  readonly originStopFacilityId?: Maybe<Scalars['String']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly routeDistance?: Maybe<Scalars['Float']>;
  readonly routeDistanceUnit?: Maybe<Scalars['String']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly destStopFacilityName?: Maybe<Scalars['String']>;
  /** For hyperlinking destStopFacilityName */
  readonly destStopFacilityId?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly routeCarrierName?: Maybe<Scalars['String']>;
  /** For hyperlinking routeCarrierName */
  readonly routeCarrierId?: Maybe<Scalars['String']>;
  readonly mainRouteRep?: Maybe<Scalars['String']>;
  /** For hyperlinking mainRouteRep */
  readonly mainRouteRepId?: Maybe<Scalars['String']>;
  readonly driver1Name?: Maybe<Scalars['String']>;
  /** For hyperlinking driver1Name */
  readonly driver1Id?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  /** For hyperlinking driver2Name */
  readonly driver2Id?: Maybe<Scalars['String']>;
  readonly trailerName?: Maybe<Scalars['String']>;
  /** For hyperlinking trailerName */
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly routeTotalCost?: Maybe<Scalars['String']>;
  readonly primaryDivision?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly additionalDivision?: Maybe<Scalars['String']>;
  readonly executingDivision?: Maybe<Scalars['String']>;
  readonly powerCode?: Maybe<Scalars['String']>;
  /** For hyperlinking powerCode */
  readonly powerId?: Maybe<Scalars['String']>;
};

export type TrailerRouteListConnection = {
  readonly __typename?: 'TrailerRouteListConnection';
  readonly edges: ReadonlyArray<TrailerRouteListEdge>;
  readonly pageInfo: PageInfo;
};

export type TrailerRouteListEdge = {
  readonly __typename?: 'TrailerRouteListEdge';
  readonly node: TrailerRouteList;
  readonly cursor: Scalars['String'];
};

export type TrailerRouteListFilterInput = {
  readonly originStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly originStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly trailerId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly primaryDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly executingDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly powerId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly powerCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type TrailerSearchInput = {
  readonly code?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly poolCarrierCode?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly divisionsTerm?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly containerProgramsTerm?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly searchTerm?: InputMaybe<Scalars['String']>;
};

export type TrailerServiceStatusDetailsInput = {
  readonly serviceStatusReasonCodeTerm: Scalars['String'];
  readonly serviceStatusFacilityId?: InputMaybe<Scalars['ID']>;
  readonly serviceStatusNotes?: InputMaybe<Scalars['String']>;
  readonly serviceStatusTerm: Scalars['String'];
  readonly trailerId: Scalars['ID'];
};

export type TrailerServiceStatusDetailsOutput = {
  readonly __typename?: 'TrailerServiceStatusDetailsOutput';
  readonly serviceStatusReasonCodeTerm?: Maybe<Scalars['String']>;
  readonly serviceStatusFacilityId?: Maybe<Scalars['ID']>;
  /** This field describes the facility to which the trailer belongs to. */
  readonly facility?: Maybe<FacilityV2>;
  readonly serviceStatusNotes?: Maybe<Scalars['String']>;
  readonly serviceStatusTerm?: Maybe<Scalars['String']>;
  readonly trailerId: Scalars['ID'];
};

export type TrailerSortingOptions = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly orderBy?: InputMaybe<Scalars['String']>;
};

export type TrailerTypeError = {
  readonly __typename?: 'TrailerTypeError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly code?: Maybe<Scalars['String']>;
};

export type TrailerValidation = {
  readonly __typename?: 'TrailerValidation';
  readonly hasTrailerNumber?: Maybe<Scalars['Boolean']>;
};

export type TrailerValidationArgumentInput = {
  readonly orderCode: Scalars['String'];
  readonly routeNumber: Scalars['String'];
  readonly activityDttm: Scalars['DateTime'];
  readonly facilityCode: Scalars['String'];
  readonly activityType: Scalars['String'];
  readonly stopSequence: Scalars['String'];
  readonly stopExternalId: Scalars['String'];
  readonly trailerNumber: Scalars['String'];
  readonly equipmentType?: InputMaybe<Scalars['String']>;
  readonly shipmentLegSequenceNumber?: InputMaybe<Scalars['String']>;
};

export type TrailerValidationResponse = {
  readonly __typename?: 'TrailerValidationResponse';
  readonly validationCode: Scalars['String'];
  readonly validationMessage: Scalars['String'];
  readonly validationResult: Scalars['String'];
};

export type TransmissionDetailsInput = {
  readonly statusCode?: InputMaybe<Scalars['String']>;
  readonly createdTimestamp?: InputMaybe<Scalars['DateTime']>;
  readonly updatedTimestamp?: InputMaybe<Scalars['DateTime']>;
  readonly errorDetails?: InputMaybe<ErrorDetailsInput>;
};

export type TripType = {
  readonly __typename?: 'TripType';
  readonly id: Scalars['ID'];
  readonly driverIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly tripNumber?: Maybe<Scalars['Long']>;
};

export enum TruckAvailableDayOffSetEnum {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY'
}

export type TruckDetails = {
  readonly __typename?: 'TruckDetails';
  readonly trailerDisplayName?: Maybe<TrailerDisplayName>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
  readonly trailerTypeId?: Maybe<Scalars['String']>;
};

export type TruckEntry = {
  readonly __typename?: 'TruckEntry';
  readonly id: Scalars['ID'];
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationDeadhead?: Maybe<UnitOfDistance>;
  readonly destinationLocation?: Maybe<TruckLocation>;
  /**
   * destinationRegionUrns is no longer supported, null will be returned until this field is removed
   * @deprecated destinationRegionUrns is no longer supported, null will be returned until this field is removed
   */
  readonly destinationRegionUrns?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  /** ID of an AssetDriver record */
  readonly driverId?: Maybe<Scalars['ID']>;
  readonly driverName?: Maybe<Scalars['String']>;
  /** ID of an AssetDriver record */
  readonly driver2Id?: Maybe<Scalars['ID']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly isHazmat?: Maybe<Scalars['Boolean']>;
  readonly isTeam?: Maybe<Scalars['Boolean']>;
  readonly originDeadhead: UnitOfDistance;
  /** If Origin Facility exists, the Origin Location address is the Facility's main address */
  readonly originLocation: TruckLocation;
  /** If Origin Facility exists, the Origin Location address is the Facility's main address */
  readonly originRampFacilityId?: Maybe<Scalars['ID']>;
  readonly ownerId?: Maybe<Scalars['ID']>;
  /** The Power (Tractor) unit ID associated with the capacity */
  readonly powerId?: Maybe<Scalars['ID']>;
  readonly powerNumber?: Maybe<Scalars['String']>;
  readonly readyTimezone?: Maybe<Scalars['String']>;
  /** Internal identifier specified by the tenant */
  readonly sourceId?: Maybe<Scalars['String']>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerSizeLength?: Maybe<UnitOfDistance>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedByUserId: Scalars['ID'];
  readonly assignedRouteId?: Maybe<Scalars['ID']>;
  readonly bracingCount?: Maybe<Scalars['Int']>;
  /** @deprecated The field "bracingTypeIds" should be used instead */
  readonly bracingType?: Maybe<BracingTypesEnum>;
  readonly bracingTypeIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** The utilization status of the capacity. Correlates to DDT id "capacity_utilization_status" */
  readonly capacityUtilizationStatusId: Scalars['String'];
  readonly breakTimeMs?: Maybe<Scalars['Int']>;
  readonly cycleTimeMs?: Maybe<Scalars['Int']>;
  readonly destinationRampFacilityId?: Maybe<Scalars['ID']>;
  /** DDT ID for the destination source of the capacity (e.g. manual, api, autotruck, dat, reload intent, etc.) */
  readonly destinationSourceId?: Maybe<Scalars['String']>;
  /** @deprecated Returns null only. Initiative to remove Driver Domicile references in Mastermind */
  readonly driverDomicile?: Maybe<Scalars['String']>;
  readonly driverPhone?: Maybe<Scalars['String']>;
  /** @deprecated Returns null only. Initiative to remove Driver Domicile references in Mastermind */
  readonly driver2Domicile?: Maybe<Scalars['String']>;
  readonly driver2Phone?: Maybe<Scalars['String']>;
  readonly driveTimeMs?: Maybe<Scalars['Int']>;
  /** Expiration time for truck entries created through external source */
  readonly expireAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Identifier from external sources such as DAT, TruckerTools, etc. Not to be confused with the creation source (i.e. sourceTypeId/sourceTypeDDT) */
  readonly externalSourceId?: Maybe<Scalars['String']>;
  /** Tracks when the entity was last updated by an external source, such as DAT or TruckerTools */
  readonly externalUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly externalUpdatedByUserId?: Maybe<Scalars['ID']>;
  readonly finalByDatetimeUtc?: Maybe<Scalars['ISO8601DateTime']>;
  readonly finalByTimezone?: Maybe<Scalars['String']>;
  /** Defaults to the end of the day (truck's local time) of the readyDatetimeUtc date. */
  readonly loadByDatetimeUtc?: Maybe<Scalars['ISO8601DateTime']>;
  readonly loadByTimezone?: Maybe<Scalars['String']>;
  /** Text information regarding the next event that informed the ICD */
  readonly nextScheduleEvent?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  /** The ID of the outbound region (Freight Assignment type/Truck mode/FTL). Determined based on origin, equipment, and ready date. */
  readonly outboundRegionV2Id?: Maybe<Scalars['String']>;
  /** The name of the outbound region (Freight Assignment type/Truck mode/FTL). Determined based on origin, equipment, and ready date. */
  readonly outboundRegionV2Name?: Maybe<Scalars['String']>;
  /** The ID of the outbound sub region (Freight Assignment type/Truck mode/FTL). Determined based on origin, equipment, and ready date. */
  readonly outboundSubRegionV2Id?: Maybe<Scalars['String']>;
  /** The name of the outbound sub region (Freight Assignment type/Truck mode/FTL). Determined based on origin, equipment, and ready date. */
  readonly outboundSubRegionV2Name?: Maybe<Scalars['String']>;
  /** The ID of the outbound super region (Freight Assignment type/Truck mode/FTL). Determined based on origin, equipment, and ready date. */
  readonly outboundSuperRegionV2Id?: Maybe<Scalars['String']>;
  /** The name of the outbound super region (Freight Assignment type/Truck mode/FTL). Determined based on origin, equipment, and ready date. */
  readonly outboundSuperRegionV2Name?: Maybe<Scalars['String']>;
  readonly palletsCount?: Maybe<Scalars['Int']>;
  readonly palletsType?: Maybe<PalletTypesEnum>;
  readonly palletTypeId?: Maybe<Scalars['String']>;
  /** DDT ID for the capacity source of the projected hours of service */
  readonly phosSourceId?: Maybe<Scalars['String']>;
  /**
   * DEPRECATED: Use previousRouteIdV2 instead
   * @deprecated Use previousRouteIdV2 instead
   */
  readonly previousRouteId?: Maybe<Scalars['ID']>;
  readonly previousRouteIdV2?: Maybe<Scalars['ID']>;
  readonly readyDatetimeUtc?: Maybe<Scalars['ISO8601DateTime']>;
  readonly shiftTimeMs?: Maybe<Scalars['Int']>;
  /**
   * DEPRECATED: Use sourceTypeId instead
   * @deprecated Use sourceTypeId instead
   */
  readonly sourceType?: Maybe<SourceTypesEnum>;
  /** DDT ID for the source of the capacity (e.g. manual, api, autotruck, dat, reload intent, etc.) */
  readonly sourceTypeId?: Maybe<Scalars['String']>;
  /** the update source of the capacity (e.g. manual, api, autotruck, dat, reload intent, etc.) */
  readonly updateSource?: Maybe<Scalars['String']>;
  readonly tarpsCount?: Maybe<Scalars['Int']>;
  /** @deprecated The field "tarpTypeId" should be used instead */
  readonly tarpsType?: Maybe<TarpTypesEnum>;
  readonly tarpTypeId?: Maybe<Scalars['String']>;
  readonly trailerId?: Maybe<Scalars['ID']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerTypeId?: Maybe<Scalars['ID']>;
  readonly truckEntryTemplateId?: Maybe<Scalars['ID']>;
  /** @deprecated Deprecated. Please use capacityUtilizationStatusId */
  readonly utilizationStatus: UtilizationStatusEnum;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly ownerV2?: Maybe<CarrierV2>;
  readonly previousRouteV2?: Maybe<LoadRouteV2>;
  /** @deprecated Old Geographies service. Please use destinationStatesV2 */
  readonly destinationStates?: Maybe<ReadonlyArray<Maybe<Geography>>>;
  readonly destinationStatesV2?: Maybe<ReadonlyArray<Maybe<GeographyV2>>>;
  readonly destinationRampFacility?: Maybe<FacilityV2>;
  readonly driver?: Maybe<AssetDriver>;
  /** Returns an asset driver record, including only the currently active fleets, schedules, and times off. */
  readonly capacityAssetDriver?: Maybe<CapacityAssetDriver>;
  readonly driver2?: Maybe<AssetDriver>;
  /** Returns an asset driver record, including only the currently active fleets, schedules, and times off. */
  readonly capacityAssetDriver2?: Maybe<CapacityAssetDriver>;
  readonly originRampFacility?: Maybe<FacilityV2>;
  readonly power?: Maybe<AssetPowerOutput>;
  /** DDT object for the source of the capacity (e.g. manual, api, autotruck, dat, reload intent, etc.) */
  readonly sourceTypeDDT?: Maybe<KeyValueType>;
  readonly trailer?: Maybe<AssetTrailers>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerType?: Maybe<KeyValueType>;
  readonly capacityUtilizationStatus?: Maybe<KeyValueType>;
  readonly assignedRoute?: Maybe<LoadRouteV2>;
  readonly driverWarnings?: Maybe<ReadonlyArray<DriverWarning>>;
};

export type TruckEntryConnection = {
  readonly __typename?: 'TruckEntryConnection';
  readonly edges?: Maybe<ReadonlyArray<Maybe<TruckEntryEdge>>>;
  readonly pageInfo?: Maybe<PageInfo>;
  readonly totalCount?: Maybe<Scalars['Int']>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<TrucksUserError>>>;
};

export type TruckEntryDateRangeFilter = {
  /** A filter for specifying the end date and time for data retrieval, used with startDatetime to set a date range. It should be a date-time string in a recognized format, such as ISO 8601. If omitted, a default value may be used. */
  readonly endDatetime?: InputMaybe<Scalars['String']>;
  /** A filter for adding a final date constraint to data retrieval, in addition to the startDatetime and endDatetime range. It should be a date-time string in a recognized format, such as ISO 8601. */
  readonly finalByDatetime?: InputMaybe<Scalars['String']>;
  /** When true, filtering uses UTC dates. If false or omitted, local dates are used. */
  readonly filterByUtcDates?: InputMaybe<Scalars['Boolean']>;
  /** The consideration of 'ready and load by dates' in filtering depends on this parameter: if true, both will be considered with 'endDatetime' and 'startDatetime'; if false or omitted, only 'ready by date' will be considered. */
  readonly filterOnReadyAndLoadByDates?: InputMaybe<Scalars['Boolean']>;
  /** A filter for specifying the start date and time for data retrieval, used with endDatetime to set a date range. It should be a date-time string in a recognized format, such as ISO 8601. If omitted, a default value may be used. */
  readonly startDatetime?: InputMaybe<Scalars['String']>;
};

export type TruckEntryEdge = {
  readonly __typename?: 'TruckEntryEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<TruckEntry>;
};

export type TruckEntryFilter = {
  readonly carrierId: Scalars['ID'];
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  /** A partial or full city name */
  readonly destinationCity?: InputMaybe<Scalars['String']>;
  readonly destinationRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** An array of abbreviated state names */
  readonly destinationStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** An array of Asset Driver IDs. Will match against driver1 and driver2 */
  readonly driverIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly endDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** An array of trailerType IDs */
  readonly equipment?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly filterOnReadyAndLoadByDates?: InputMaybe<Scalars['Boolean']>;
  readonly finalByDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** A partial or full city name */
  readonly originCity?: InputMaybe<Scalars['String']>;
  readonly originRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** An array of abbreviated state names */
  readonly originStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** An array of outbound region hierarchies, each flattened to a string with elements separated by tildes: ~super~region~subregion. All region types are optional but all three tildes (including the leading one) are required: ~super~~, ~super~region~, ~~region~, ~~~subregion, ~super~~subregion, ~~region~subregion */
  readonly outboundRegionNamesFlatArray?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  /** An array of Asset Power IDs */
  readonly powerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly startDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** An array of sourceType IDs */
  readonly source?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly truckEntryTemplateId?: InputMaybe<Scalars['ID']>;
  readonly capacityUtilizationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type TruckEntryPayloadWithErrorsAndTruckEntry = {
  readonly __typename?: 'TruckEntryPayloadWithErrorsAndTruckEntry';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly truckEntry?: Maybe<TruckEntry>;
};

export type TruckEntryPayloadWithOnlyErrors = {
  readonly __typename?: 'TruckEntryPayloadWithOnlyErrors';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
};

export type TruckEntrySort = {
  readonly key: TruckEntrySortKeysEnum;
  readonly direction: TruckEntrySortDirectionsEnum;
};

export enum TruckEntrySortDirectionsEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum TruckEntrySortKeysEnum {
  CycleTimeMs = 'cycleTimeMs',
  DestinationCity = 'destinationCity',
  DestinationDeadhead = 'destinationDeadhead',
  DestinationState = 'destinationState',
  DriveTimeMs = 'driveTimeMs',
  FinalByDatetimeUtc = 'finalByDatetimeUtc',
  LoadByDatetimeUtc = 'loadByDatetimeUtc',
  OriginCity = 'originCity',
  OriginDeadhead = 'originDeadhead',
  OriginState = 'originState',
  ReadyDatetimeUtc = 'readyDatetimeUtc',
  ShiftTimeMs = 'shiftTimeMs',
  SourceType = 'sourceType',
  CapacityUtilizationStatus = 'capacityUtilizationStatus'
}

export type TruckEntryTemplate = {
  readonly __typename?: 'TruckEntryTemplate';
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationDeadhead?: Maybe<UnitOfDistance>;
  readonly destinationLocation?: Maybe<TruckLocation>;
  /**
   * destinationRegionUrns is no longer supported, null will be returned until this field is removed
   * @deprecated destinationRegionUrns is no longer supported, null will be returned until this field is removed
   */
  readonly destinationRegionUrns?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  /** ID of an AssetDriver record */
  readonly driverId?: Maybe<Scalars['ID']>;
  readonly driverName?: Maybe<Scalars['String']>;
  /** ID of an AssetDriver record */
  readonly driver2Id?: Maybe<Scalars['ID']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isHazmat?: Maybe<Scalars['Boolean']>;
  readonly isTeam?: Maybe<Scalars['Boolean']>;
  readonly originDeadhead: UnitOfDistance;
  /** If Origin Facility exists, the Origin Location address is the Facility's main address */
  readonly originLocation: TruckLocation;
  /** If Origin Facility exists, the Origin Location address is the Facility's main address */
  readonly originRampFacilityId?: Maybe<Scalars['ID']>;
  readonly ownerId?: Maybe<Scalars['ID']>;
  /** The Power (Tractor) unit ID associated with the capacity */
  readonly powerId?: Maybe<Scalars['ID']>;
  readonly powerNumber?: Maybe<Scalars['String']>;
  readonly readyTimezone?: Maybe<Scalars['String']>;
  /** Internal identifier specified by the tenant */
  readonly sourceId?: Maybe<Scalars['String']>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerSizeLength?: Maybe<UnitOfDistance>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedByUserId: Scalars['ID'];
  readonly fridayMsOffsetFromStartOfDay?: Maybe<Scalars['Int']>;
  readonly mondayMsOffsetFromStartOfDay?: Maybe<Scalars['Int']>;
  readonly saturdayMsOffsetFromStartOfDay?: Maybe<Scalars['Int']>;
  readonly sourceType?: Maybe<TruckEntryTemplateSourceTypesEnum>;
  readonly status?: Maybe<TruckEntryTemplateStatus>;
  readonly sundayMsOffsetFromStartOfDay?: Maybe<Scalars['Int']>;
  readonly thursdayMsOffsetFromStartOfDay?: Maybe<Scalars['Int']>;
  readonly tuesdayMsOffsetFromStartOfDay?: Maybe<Scalars['Int']>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerTypeId?: Maybe<Scalars['ID']>;
  readonly wednesdayMsOffsetFromStartOfDay?: Maybe<Scalars['Int']>;
  readonly inactivationReason?: Maybe<Scalars['String']>;
  /**
   * DEPRECATED: Use carrierV2 instead
   * @deprecated Use carrierV2 instead
   */
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  /**
   * DEPRECATED: Use ownerV2 instead
   * @deprecated Use ownerV2 instead
   */
  readonly owner?: Maybe<Carrier>;
  readonly ownerV2?: Maybe<CarrierV2>;
  /**
   * DEPRECATED: Use createdByUserV2 instead
   * @deprecated Use createdByUserV2 instead
   */
  readonly createdByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  /**
   * DEPRECATED: Use updatedByUserV2 instead
   * @deprecated Use updatedByUserV2 instead
   */
  readonly updatedByUser?: Maybe<User>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  /** @deprecated Old Geographies service. Please use destinationStatesV2 */
  readonly destinationStates?: Maybe<ReadonlyArray<Maybe<Geography>>>;
  readonly destinationStatesV2?: Maybe<ReadonlyArray<Maybe<GeographyV2>>>;
  readonly driver?: Maybe<AssetDriver>;
  readonly driver2?: Maybe<AssetDriver>;
  readonly originRampFacility?: Maybe<FacilityV2>;
  readonly power?: Maybe<AssetPowerOutput>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerType?: Maybe<KeyValueType>;
};

export type TruckEntryTemplateAndFirstTrucksPayloadWithErrorsAndTruckEntries = {
  readonly __typename?: 'TruckEntryTemplateAndFirstTrucksPayloadWithErrorsAndTruckEntries';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly truckEntry?: Maybe<ReadonlyArray<TruckEntry>>;
  readonly truckEntryTemplate?: Maybe<TruckEntryTemplate>;
};

export type TruckEntryTemplateByDriverIdAndDaysPayloadWithErrors = {
  readonly __typename?: 'TruckEntryTemplateByDriverIdAndDaysPayloadWithErrors';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly truckEntryTemplate?: Maybe<TruckEntryTemplate>;
};

export type TruckEntryTemplateConnection = {
  readonly __typename?: 'TruckEntryTemplateConnection';
  readonly edges?: Maybe<ReadonlyArray<Maybe<TruckEntryTemplateEdge>>>;
  readonly pageInfo?: Maybe<PageInfo>;
  readonly totalCount?: Maybe<Scalars['Int']>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<TrucksUserError>>>;
};

export type TruckEntryTemplateEdge = {
  readonly __typename?: 'TruckEntryTemplateEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<TruckEntryTemplate>;
};

export type TruckEntryTemplateFilter = {
  readonly carrierId: Scalars['ID'];
  readonly status?: InputMaybe<ReadonlyArray<TruckEntryTemplateStatus>>;
  readonly truckAvailableDay?: InputMaybe<TruckAvailableDayOffSetEnum>;
};

export type TruckEntryTemplatePayloadWithErrorsAndTruckEntryTemplate = {
  readonly __typename?: 'TruckEntryTemplatePayloadWithErrorsAndTruckEntryTemplate';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly truckEntryTemplate?: Maybe<TruckEntryTemplate>;
};

/** Identifies the method by which a TruckEntryTemplate was created */
export enum TruckEntryTemplateSourceTypesEnum {
  Api = 'api',
  Manual = 'manual'
}

export enum TruckEntryTemplateStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Inactive = 'INACTIVE',
  Deleted = 'DELETED'
}

export type TruckLocation = {
  readonly __typename?: 'TruckLocation';
  /** Full city name, ex: Chicago */
  readonly city: Scalars['String'];
  /** The state abbreviated to 2 letters, ex: IL */
  readonly stateAlpha2Code: Scalars['String'];
  /** The country abbreviated to 2 letters, ex: US */
  readonly countryAlpha2Code: Scalars['String'];
  /** Returns Geopoint with shape { lat: _, lon: _ } */
  readonly point: Geopoint;
  /** Optional postal code, ex: 60654 */
  readonly postalCode?: Maybe<Scalars['String']>;
};

export type TruckLocationInput = {
  /** Full city name, ex: Chicago */
  readonly city: Scalars['String'];
  /** The state, ex: IL, MEX, Ontario, Florida */
  readonly stateAlpha2Code: Scalars['String'];
  /** The country, ex: US, Mexico */
  readonly countryAlpha2Code: Scalars['String'];
  /** Optional postal code, ex: 60654 */
  readonly postalCode?: InputMaybe<Scalars['String']>;
};

export type TruckPosting = {
  readonly __typename?: 'TruckPosting';
  readonly additionalEquipment?: Maybe<Scalars['Json']>;
  readonly auditData?: Maybe<Scalars['String']>;
  readonly carrier: Carrier;
  readonly carrierId: Scalars['ID'];
  readonly destinationDeadhead: UnitOfDistance;
  readonly destinationGeographies?: Maybe<ReadonlyArray<TruckPostingGeography>>;
  readonly destinationGeographySource?: Maybe<Scalars['String']>;
  readonly destinationGeographySourceId?: Maybe<Scalars['Int']>;
  readonly destinationPoint?: Maybe<Geopoint>;
  readonly destinationText?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly employee?: Maybe<Employee>;
  readonly emptyTimezone: Scalars['String'];
  readonly finalByTimezone?: Maybe<Scalars['String']>;
  readonly hazmat: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly loadByTimezone?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly originDeadhead: UnitOfDistance;
  readonly originGeographySource?: Maybe<Scalars['String']>;
  readonly originGeographySourceId?: Maybe<Scalars['Int']>;
  readonly originPoint: Geopoint;
  readonly originText: Scalars['String'];
  readonly previousRoute?: Maybe<LoadRoute>;
  readonly previousRouteId?: Maybe<Scalars['ID']>;
  /** @deprecated Replaced by source_id_as_string; this field attempts to cast any string to an integer and may be inaccurate */
  readonly sourceId?: Maybe<Scalars['Int']>;
  readonly sourceIdAsString?: Maybe<Scalars['String']>;
  readonly sourceType?: Maybe<SourceTypeEnum>;
  readonly team?: Maybe<Scalars['Boolean']>;
  readonly trailerHeight?: Maybe<Scalars['Float']>;
  readonly trailerHeightUnit: Scalars['String'];
  readonly trailerLength?: Maybe<Scalars['Float']>;
  readonly trailerLengthUnit: Scalars['String'];
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly trailerType: KeyValue;
  readonly trailerTypeId?: Maybe<Scalars['ID']>;
  readonly trailerWidth?: Maybe<Scalars['Float']>;
  readonly trailerWidthUnit: Scalars['String'];
  /** @deprecated Transitioned to trailer_type */
  readonly truckType: TruckTypeEnum;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  /** @deprecated Replaced by utc_empty_start_datetime */
  readonly utcDatetime: Scalars['ISO8601DateTime'];
  readonly utcEmptyStartDatetime: Scalars['ISO8601DateTime'];
  readonly utcFinalByDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly utcLoadByDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly verified: Scalars['Boolean'];
};

export type TruckPostingGeography = {
  readonly __typename?: 'TruckPostingGeography';
  readonly geography: Geography;
  readonly id: Scalars['ID'];
};

export type TruckPostingGeographyInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly geographyId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type TruckPostingInput = {
  readonly additionalEquipment?: InputMaybe<Scalars['Json']>;
  readonly carrierId: Scalars['ID'];
  readonly count?: InputMaybe<Scalars['Int']>;
  readonly destinationDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly destinationGeographies?: InputMaybe<ReadonlyArray<TruckPostingGeographyInput>>;
  readonly destinationGeographySource?: InputMaybe<Scalars['String']>;
  readonly destinationGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly destinationText?: InputMaybe<Scalars['String']>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly emptyTimezone?: InputMaybe<Scalars['String']>;
  readonly finalByTimezone?: InputMaybe<Scalars['String']>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly loadByTimezone?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly originDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly originGeographySource?: InputMaybe<Scalars['String']>;
  readonly originGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly originText: Scalars['String'];
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly sourceType: SourceTypeEnum;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly trailerHeight?: InputMaybe<Scalars['Float']>;
  readonly trailerLength?: InputMaybe<Scalars['Float']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly trailerWidth?: InputMaybe<Scalars['Float']>;
  readonly truckType?: InputMaybe<TruckTypeEnum>;
  readonly utcEmptyStartDatetime?: InputMaybe<Scalars['String']>;
  readonly utcFinalByDatetime?: InputMaybe<Scalars['String']>;
  readonly utcLoadByDatetime?: InputMaybe<Scalars['String']>;
};

export type TruckPostingsExternalInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
};

export type TruckPostingsInput = {
  readonly destination?: InputMaybe<Scalars['String']>;
  readonly emptyStartDate?: InputMaybe<Scalars['String']>;
  readonly emptyStartDateEnd?: InputMaybe<Scalars['String']>;
  readonly origin?: InputMaybe<Scalars['String']>;
};

export type TrucksUserError = {
  readonly __typename?: 'TrucksUserError';
  readonly code?: Maybe<Scalars['String']>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export enum TruckTypeEnum {
  /** Flatbed */
  Flatbed = 'flatbed',
  /** None Defined */
  NoneDefined = 'noneDefined',
  /** Power Only */
  PowerOnly = 'powerOnly',
  /** Reefer */
  Reefer = 'reefer',
  /** Van */
  Van = 'van'
}

export type TxIntrastateAuthority = {
  readonly __typename?: 'TXIntrastateAuthority';
  readonly tXIntraStateAuthorityCertificate?: Maybe<Scalars['String']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
};

export type TxIntrastateAuthorityInput = {
  readonly tXIntraStateAuthorityCertificate?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
};

export enum UnderwriterRatingTypeEnum {
  /** A++ */
  APlusPlus = 'APlusPlus',
  /** A+ */
  APlus = 'APlus',
  /** A */
  A = 'A',
  /** A- */
  AMinus = 'AMinus',
  /** B++ */
  BPlusPlus = 'BPlusPlus',
  /** B+ */
  BPlus = 'BPlus',
  /** B */
  B = 'B',
  /** C+ */
  CPlus = 'CPlus',
  /** C */
  C = 'C',
  /** D */
  D = 'D',
  /** E */
  E = 'E',
  /** F */
  F = 'F',
  /** S */
  S = 'S',
  /** NR */
  Nr = 'NR',
  /** NA */
  Na = 'NA'
}

export type UnitOfCapacityInput = {
  readonly value: Scalars['Int'];
  readonly unit: Scalars['String'];
};

export type UnitOfCapacityOutput = {
  readonly __typename?: 'UnitOfCapacityOutput';
  readonly value: Scalars['Int'];
  readonly unit: Scalars['String'];
};

export enum UnitOfCurrencyEnum {
  /** abbreviation for the U.S. Dollar */
  Usd = 'USD'
}

export enum UnitOfCurrencyEnumV2 {
  Usd = 'USD',
  Cad = 'CAD',
  Mxn = 'MXN'
}

export type UnitOfDensityInput = {
  readonly unit: DensityUnitTypeEnum;
  readonly value: Scalars['Float'];
};

export type UnitOfDensityInputV2 = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type UnitOfDistance = {
  readonly __typename?: 'UnitOfDistance';
  readonly asFeet?: Maybe<Scalars['Float']>;
  readonly asInches?: Maybe<Scalars['Float']>;
  readonly asKilometers?: Maybe<Scalars['Float']>;
  readonly asMeters?: Maybe<Scalars['Float']>;
  readonly asMiles?: Maybe<Scalars['Float']>;
  readonly asRoundedFeet?: Maybe<Scalars['Int']>;
  readonly asRoundedInches?: Maybe<Scalars['Int']>;
  readonly asRoundedKilometers?: Maybe<Scalars['Int']>;
  readonly asRoundedMeters?: Maybe<Scalars['Int']>;
  readonly asRoundedMiles?: Maybe<Scalars['Int']>;
};

export type UnitOfDistanceInput = {
  readonly unit?: InputMaybe<DistanceUnitEnum>;
  readonly value?: InputMaybe<Scalars['Float']>;
};

export enum UnitOfLengthEnum {
  Ft = 'ft',
  In = 'in',
  Km = 'km',
  M = 'm',
  Mi = 'mi'
}

export type UnitOfLengthInput = {
  /** Must not be greater than 9,999 miles */
  readonly value: Scalars['Float'];
  readonly unit: UnitOfLengthEnum;
};

export type UnitOfLengthInputV2 = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type UnitOfLengthInputV3 = {
  readonly value: Scalars['Float'];
  readonly unit: Scalars['String'];
};

export type UnitOfLengthInputV4 = {
  readonly value: Scalars['Int'];
  readonly unit: Scalars['String'];
};

export type UnitOfLengthOutput = {
  readonly __typename?: 'UnitOfLengthOutput';
  readonly unit: UnitOfLengthEnum;
  readonly value: Scalars['Float'];
};

export type UnitOfLengthOutputV2 = {
  readonly __typename?: 'UnitOfLengthOutputV2';
  readonly value: Scalars['Float'];
  readonly unit: Scalars['String'];
};

export type UnitOfLengthOutputV3 = {
  readonly __typename?: 'UnitOfLengthOutputV3';
  readonly value: Scalars['Int'];
  readonly unit: Scalars['String'];
};

export type UnitOfMassInput = {
  readonly unit: MassUnitTypeEnum;
  readonly value: Scalars['Float'];
};

export type UnitOfMassInputV2 = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type UnitOfTemperatureInput = {
  readonly unit: TemperatureUnitTypeEnum;
  readonly value: Scalars['Float'];
};

export type UnitOfTemperatureInputV2 = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type UnitOfVolumeInput = {
  readonly unit: VolumeUnitTypeEnum;
  readonly value: Scalars['Float'];
};

export type UnitOfVolumeInputV2 = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type UnitOfWeightInput = {
  readonly value: Scalars['Float'];
  readonly unit: Scalars['String'];
};

export type UnitOfWeightInputV2 = {
  readonly value: Scalars['Int'];
  readonly unit: Scalars['String'];
};

export type UnitOfWeightOutput = {
  readonly __typename?: 'UnitOfWeightOutput';
  readonly value: Scalars['Float'];
  readonly unit: Scalars['String'];
};

export type UnitOfWeightOutputV2 = {
  readonly __typename?: 'UnitOfWeightOutputV2';
  readonly value: Scalars['Int'];
  readonly unit: Scalars['String'];
};

export type Units = {
  readonly unit?: InputMaybe<Scalars['String']>;
  readonly index?: InputMaybe<Scalars['Int']>;
};

export type UnprocessedEdi204Transaction = {
  readonly __typename?: 'UnprocessedEdi204Transaction';
  readonly id?: Maybe<Scalars['ID']>;
  readonly edi_message_type?: Maybe<Scalars['String']>;
  readonly shipment_id?: Maybe<Scalars['String']>;
  readonly document_id?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['DateTime']>;
  readonly response?: Maybe<Scalars['String']>;
};

export type UnvoucheredDrivers = {
  readonly __typename?: 'UnvoucheredDrivers';
  readonly id: Scalars['ID'];
  /** AccountingRouteCostDetails Id */
  readonly accountingDetailsId?: Maybe<Scalars['ID']>;
  readonly costDetailsType?: Maybe<Scalars['ID']>;
  /** Driver Id */
  readonly driverId?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  /** @deprecated Use driverFirstName */
  readonly driverFirstName?: Maybe<Scalars['String']>;
  /** @deprecated Use driverLastName */
  readonly driverLastName?: Maybe<Scalars['String']>;
  readonly driverAccountingStatus?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['String']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly vendorRouteFinancialStatus?: Maybe<Scalars['String']>;
  readonly financialStatusReasons?: Maybe<Scalars['String']>;
  /** @deprecated Use carrierNameV2 */
  readonly carrierName: Scalars['String'];
  /** or "bookedFleet" */
  readonly carrierNameV2?: Maybe<Scalars['String']>;
  readonly carrierAccountingStatus?: Maybe<Scalars['String']>;
  readonly routeOtherPayType?: Maybe<Scalars['String']>;
  readonly routeCurrency?: Maybe<Scalars['String']>;
  readonly routeTotalPay?: Maybe<Scalars['Float']>;
  readonly routeAdvanceAmount?: Maybe<Scalars['Float']>;
  readonly originStopCity?: Maybe<Scalars['String']>;
  readonly originStopState?: Maybe<Scalars['String']>;
  readonly destStopCity?: Maybe<Scalars['String']>;
  readonly destStopState?: Maybe<Scalars['String']>;
  readonly destStopStartDate?: Maybe<Scalars['DateTime']>;
  readonly destStopEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStopStartTimezone?: Maybe<Scalars['String']>;
  readonly destStopEndTimezone?: Maybe<Scalars['String']>;
  readonly routeStopCount?: Maybe<Scalars['Float']>;
  readonly paperworkCompleted?: Maybe<Scalars['String']>;
  readonly routeIncidentsCount?: Maybe<Scalars['Float']>;
  readonly orderCode?: Maybe<Scalars['Float']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly mainOrderRepName?: Maybe<Scalars['String']>;
  readonly mainRouteRepName?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly routeSize?: Maybe<Scalars['String']>;
  readonly routeDivision?: Maybe<Scalars['String']>;
  readonly routeBusinessUnit?: Maybe<Scalars['String']>;
  /** @deprecated Use project */
  readonly routeProject?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  /** @deprecated Use program */
  readonly routeProgram?: Maybe<Scalars['String']>;
  readonly program?: Maybe<Scalars['String']>;
  readonly driverFleetName?: Maybe<Scalars['String']>;
  readonly driverCompanyName?: Maybe<Scalars['String']>;
  readonly driverCompanyAccountingStatus?: Maybe<Scalars['String']>;
  readonly driverCompanyRemitTo?: Maybe<Scalars['String']>;
  readonly driverCompanyPmtTerms?: Maybe<Scalars['String']>;
  readonly routeAccessorialTotal?: Maybe<Scalars['Float']>;
  readonly routeBaseCharge?: Maybe<Scalars['Float']>;
  readonly routeOtherTotal?: Maybe<Scalars['Float']>;
  readonly routeFuelTotal?: Maybe<Scalars['Float']>;
  readonly voucherMin?: Maybe<Scalars['Float']>;
};

export type UnvoucheredDriversConnection = {
  readonly __typename?: 'UnvoucheredDriversConnection';
  readonly edges: ReadonlyArray<UnvoucheredDriversEdge>;
  readonly pageInfo: PageInfo;
};

export type UnvoucheredDriversEdge = {
  readonly __typename?: 'UnvoucheredDriversEdge';
  readonly node: UnvoucheredDrivers;
  readonly cursor: Scalars['String'];
};

export type UnvoucheredDriversFilterInput = {
  readonly routeCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderReferences?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destStopStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly destStopEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly payTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly repId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly fleetCarrierId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly fleetCarrierAccountingStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLifeCycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly vendorRouteFinancialStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly exceptionReason?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDivision?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverAccountingStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverIdentifier?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverCompanyId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly fleetRepId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type UpdateBackhaulLoadRouteInput = {
  readonly routeId: Scalars['ID'];
  readonly isBackhaul: Scalars['Boolean'];
  readonly backhaulOrderId?: InputMaybe<Scalars['ID']>;
};

export type UpdateBounceInput = {
  readonly bounceReasonId?: InputMaybe<Scalars['ID']>;
  readonly bounceTypeId?: InputMaybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
};

/** Autogenerated return type of UpdateBounce */
export type UpdateBouncePayload = {
  readonly __typename?: 'UpdateBouncePayload';
  readonly bounce: Bounce;
  readonly errors: ReadonlyArray<UserError>;
};

/** Input for updating Bounce information */
export type UpdateBounceV2Input = {
  /** The ID of the Bounce */
  readonly id: Scalars['ID'];
  /** Notes to add to the Bounce */
  readonly notes?: InputMaybe<Scalars['String']>;
  /** The reason the Carrier / Vendor is being removed from teh Route -- *Requires 'Bounce Reason' DDT* */
  readonly bounceReasonId?: InputMaybe<Scalars['ID']>;
  /** The type of Bounce -- *Requires 'Bounce Type' DDT* */
  readonly bounceTypeId?: InputMaybe<Scalars['ID']>;
};

/** The response from the **updateBounceV2** Mutation */
export type UpdateBounceV2Payload = {
  readonly __typename?: 'UpdateBounceV2Payload';
  /** The updated Bounce */
  readonly bounce?: Maybe<BounceV2>;
  /** An Array of any Errors that were encountered while Bouncing */
  readonly errors: ReadonlyArray<UserError>;
};

export type UpdateCanvasConfigurationInput = {
  /** If a customer defined validation configuration is not met, this is the expected behavior */
  readonly failedValidationBehavior: Scalars['String'];
  /** GlobalDefault indicator, flags whether this validation configuration is considered a fallback option applicable for this entity and action */
  readonly globalDefault: Scalars['Boolean'];
  /** List of rules that apply for this validation configuration */
  readonly requirements: ReadonlyArray<RequirementInput>;
  readonly validatorMetadata?: InputMaybe<ValidatorMetadataInput>;
  /** Scope to which this validator belongs to such as a tenant, carrier or specific customer. Corresponds to the scopedEntityUuid */
  readonly configScope: ConfigScope;
  /** Division associated with the entity, must match a division DDT */
  readonly division?: InputMaybe<CanvasKeyOptionsInput>;
  /** Order Transport Mode associated with the entity, must match a transportMode DDT */
  readonly orderTransportMode?: InputMaybe<CanvasKeyOptionsInput>;
  /** Unique Identifier of the entity this validator belongs to. Expected to be null when configScope is tenant level */
  readonly scopedEntityUuid?: InputMaybe<Scalars['String']>;
  /** Load Size associated with the entity, must match a loadSize DDT */
  readonly loadSize?: InputMaybe<CanvasKeyOptionsInput>;
  /** Trailer Type equipment associated with the entity, must match a trailerType DDT option ID in UUID format */
  readonly equipment?: InputMaybe<CanvasKeyOptionsInput>;
  /** Type of entity this validator applies to, such as 'order' or 'customer' */
  readonly entity: Scalars['String'];
  /** Action change taking place when this validation is checked, such as invoice generation or entity creation */
  readonly action: Scalars['String'];
  /** Route type associated with the entity, must match a routeType DDT */
  readonly routeType?: InputMaybe<CanvasKeyOptionsInput>;
  /** Order type associated with the entity, must match a orderType DDT */
  readonly orderType?: InputMaybe<CanvasKeyOptionsInput>;
  /** Route Transport Mode associated with the entity, must match a routeTransportMode DDT */
  readonly routeTransportMode?: InputMaybe<CanvasKeyOptionsInput>;
  /** Bill to Code associated with the entity */
  readonly billToCode?: InputMaybe<CanvasKeyOptionsInput>;
  /** Vendor Type associated with the entity and action */
  readonly vendorType?: InputMaybe<CanvasKeyOptionsInput>;
  /** Unique identifier generated by Canvas */
  readonly uuid: Scalars['ID'];
};

export type UpdateCanvasConfigurationPrioritiesInput = {
  /** Unique Identifier of the entity this validator belongs to. Expected to be null when configScope is tenant level */
  readonly scopedEntityUuid?: InputMaybe<Scalars['String']>;
  /** Scope to which this validator belongs to such as a tenant, carrier or specific customer. Corresponds to the scopedEntityUuid */
  readonly configScope?: InputMaybe<ConfigScope>;
  /** Type of entity this search applies to, such as an Order or Customer */
  readonly entity?: InputMaybe<Scalars['String']>;
  /** Type of action this search applies to, such as an Invoice Generation or Save Input */
  readonly action?: InputMaybe<Scalars['String']>;
  /** List of uuids to update priority for. The order of the UUIDs in the array is the priority rank */
  readonly uuids: ReadonlyArray<Scalars['ID']>;
};

export type UpdateCarrierCapacityConstraintInput = {
  readonly costQuoteId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly laneId: Scalars['ID'];
  readonly weeklyCapacity?: InputMaybe<Scalars['Int']>;
  readonly mondayCapacity?: InputMaybe<Scalars['Int']>;
  readonly tuesdayCapacity?: InputMaybe<Scalars['Int']>;
  readonly wednesdayCapacity?: InputMaybe<Scalars['Int']>;
  readonly thursdayCapacity?: InputMaybe<Scalars['Int']>;
  readonly fridayCapacity?: InputMaybe<Scalars['Int']>;
  readonly saturdayCapacity?: InputMaybe<Scalars['Int']>;
  readonly sundayCapacity?: InputMaybe<Scalars['Int']>;
  readonly requestedById: Scalars['ID'];
};

export type UpdateCarrierCrmDocumentInput = {
  readonly contacts?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly direction?: InputMaybe<Scalars['String']>;
  readonly documentName: Scalars['String'];
  readonly documentType?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isDeleted: Scalars['Boolean'];
  readonly opportunities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly source?: InputMaybe<Scalars['String']>;
  readonly vendorId: Scalars['ID'];
};

export type UpdateCarrierCrmNotesInput = {
  readonly contacts?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly id: Scalars['ID'];
  readonly notes: Scalars['String'];
  readonly opportunities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly reps: ReadonlyArray<Scalars['ID']>;
  readonly type?: InputMaybe<Scalars['String']>;
  readonly vendorId: Scalars['ID'];
};

export type UpdateCarrierCrmOpportunityInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly opportunityStatusId?: InputMaybe<Scalars['ID']>;
  readonly opportunityTypeId?: InputMaybe<Scalars['ID']>;
  readonly owningContactId?: InputMaybe<Scalars['ID']>;
  readonly owningRepId: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
};

/** The input type to update Carrier EdiTransaction By Id. */
export type UpdateCarrierEdiTransactionByIdInput = {
  readonly transaction_id: Scalars['String'];
  readonly edi_transaction_id: Scalars['String'];
};

export type UpdateCarrierFacilityInput = {
  readonly relationshipType?: InputMaybe<Scalars['String']>;
  readonly canLoad?: InputMaybe<Scalars['Boolean']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly facilityId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateCarrierIntrastate */
export type UpdateCarrierIntrastatePayload = {
  readonly __typename?: 'UpdateCarrierIntrastatePayload';
  readonly carrier?: Maybe<Carrier>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of UpdateCarrierIntrastate */
export type UpdateCarrierIntrastatePayloadV2 = {
  readonly __typename?: 'UpdateCarrierIntrastatePayloadV2';
  readonly carrier?: Maybe<CarrierV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type UpdateCarrierQuoteInputType = {
  readonly costQuoteId: Scalars['ID'];
  readonly expirationDateTime?: InputMaybe<Scalars['DateTimeOffset']>;
  readonly lineItems?: InputMaybe<ReadonlyArray<InputMaybe<CostQuoteLineItemInput>>>;
  readonly routeVendorReferences?: InputMaybe<ReadonlyArray<RouteVendorReferenceInput>>;
  readonly costQuoteRouteVendorReference?: InputMaybe<ReadonlyArray<CostQuoteRouteVendorReferenceInput>>;
  readonly distanceType?: InputMaybe<Scalars['Boolean']>;
  readonly effectiveDateTime?: InputMaybe<Scalars['DateTimeOffset']>;
  readonly isContinue?: InputMaybe<Scalars['Boolean']>;
  readonly externalPricing?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateCarrierStanding */
export type UpdateCarrierStandingPayload = {
  readonly __typename?: 'UpdateCarrierStandingPayload';
  readonly carrier?: Maybe<Carrier>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of UpdateCarrierStanding */
export type UpdateCarrierStandingPayloadV2 = {
  readonly __typename?: 'UpdateCarrierStandingPayloadV2';
  readonly carrier?: Maybe<CarrierV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type UpdateChannelLegError = ConflictingChannelLegError | ConflictingNetworkChannelError | PlannedNetworkError;

export type UpdateChannelLegInput = {
  readonly channelLegId: Scalars['ID'];
  readonly networkChannelId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  /** A valid Carrier ID for the leg. */
  readonly carrierId: Scalars['ID'];
  /** Bidirectionality affects the leg's uniqueness. If true, this leg counts as two legs, including one with the origin and destination reversed. */
  readonly isBidirectional: Scalars['Boolean'];
  /** The time in hours it takes to complete the leg. Allows up to a two-decimal precision. */
  readonly turnaroundTimeHours?: InputMaybe<Scalars['Decimal']>;
  /** Valid Employee IDs for the leg. The order does not matter. */
  readonly assignedRepIds: ReadonlyArray<Scalars['ID']>;
  /** Minion division. */
  readonly divisions: ReadonlyArray<Scalars['String']>;
  /** Schedule units for the leg. The order does not matter. */
  readonly scheduleUnits: ReadonlyArray<SaveChannelLegScheduleUnitInput>;
  /** Stops with valid Facility IDs for the leg. The first stop will be the origin. Legs must be distinct by their origin and destination. */
  readonly stops: ReadonlyArray<SaveChannelLegStopInput>;
};

export type UpdateChannelLegPayload = {
  readonly __typename?: 'UpdateChannelLegPayload';
  readonly data?: Maybe<ChannelLeg>;
  readonly errors?: Maybe<ReadonlyArray<UpdateChannelLegError>>;
};

export type UpdateCityStateOnStopInput = {
  readonly loadId: Scalars['ID'];
  readonly oldStopAddress: CityStateStopLocationInput;
  readonly newStopAddress: CityStateStopLocationInput;
  readonly routeStopId?: InputMaybe<Scalars['ID']>;
};

export type UpdateCityStateOnStopPayload = {
  readonly __typename?: 'UpdateCityStateOnStopPayload';
  readonly errors: ReadonlyArray<RouteStopsPayloadError>;
  readonly updateCityStateOnStop?: Maybe<RouteStopsPayload>;
};

export type UpdateClientExceptionRecordInput = {
  readonly id: Scalars['ID'];
  readonly status?: InputMaybe<ClientExceptionRecordStatus>;
  readonly reasons?: InputMaybe<ReadonlyArray<UpdateClientExceptionRecordReasonInput>>;
  readonly metadata?: InputMaybe<ReadonlyArray<UpsertClientExceptionRecordMetadataInput>>;
  readonly assignedTo?: InputMaybe<Scalars['String']>;
  readonly assignedToGroup?: InputMaybe<Scalars['String']>;
  readonly updatedBy: Scalars['String'];
};

export type UpdateClientExceptionRecordInputV2 = {
  readonly id: Scalars['ID'];
  readonly status?: InputMaybe<ClientExceptionRecordStatus>;
  readonly reasons?: InputMaybe<ReadonlyArray<UpdateClientExceptionRecordReasonInputV2>>;
  readonly metadata?: InputMaybe<ReadonlyArray<UpsertClientExceptionRecordMetadataInput>>;
  readonly assignedTo?: InputMaybe<Scalars['String']>;
  readonly assignedToGroup?: InputMaybe<Scalars['String']>;
  readonly updatedBy: Scalars['String'];
  readonly followUpNotes?: InputMaybe<Scalars['String']>;
  readonly followUpDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateClientExceptionRecordReasonInput = {
  readonly status: ClientExceptionRecordReasonStatus;
  readonly code: ClientExceptionRecordReasonCode;
  readonly bypassed?: InputMaybe<Scalars['Boolean']>;
  readonly bypassedReason?: InputMaybe<Scalars['String']>;
  readonly assignedTo?: InputMaybe<Scalars['String']>;
  readonly assignedToGroup?: InputMaybe<Scalars['String']>;
  readonly assignedBy?: InputMaybe<Scalars['String']>;
};

export type UpdateClientExceptionRecordReasonInputV2 = {
  readonly status: ClientExceptionRecordReasonStatus;
  readonly code: Scalars['String'];
  readonly bypassed?: InputMaybe<Scalars['Boolean']>;
  readonly bypassedReason?: InputMaybe<Scalars['String']>;
  readonly assignedTo?: InputMaybe<Scalars['String']>;
  readonly assignedToGroup?: InputMaybe<Scalars['String']>;
  readonly assignedBy?: InputMaybe<Scalars['String']>;
};

export type UpdateContainerInput = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly containerLengthUnit?: InputMaybe<UnitOfLengthEnum>;
  readonly containerLengthValue?: InputMaybe<Scalars['Float']>;
  /** Must be shorter than 50 characters; required if reservationNumber is not present */
  readonly containerNumber?: InputMaybe<Scalars['String']>;
  readonly containerProgramId?: InputMaybe<Scalars['String']>;
  readonly containerTypeId?: InputMaybe<Scalars['ID']>;
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly endDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly endTimezone?: InputMaybe<Scalars['String']>;
  readonly externalSourceId?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly originLocation?: InputMaybe<CapacityLocationInput>;
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  /** Must be alphanumeric and shorter than 50 characters; required if containerNumber is not present */
  readonly reservationNumber?: InputMaybe<Scalars['String']>;
  readonly reservationTypeId?: InputMaybe<Scalars['String']>;
  readonly startDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly startTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceTypeId?: InputMaybe<Scalars['String']>;
  readonly tenantInternalId?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['ID']>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly updatedByUserId: Scalars['ID'];
};

export type UpdateCrmDocumentNameInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type UpdateCrmNamePayload = {
  readonly __typename?: 'UpdateCRMNamePayload';
  readonly crm?: Maybe<CrmDocument>;
};

export type UpdateCustomerCommodityInput = {
  readonly customerFacilityId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly facilityId?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly packagingUnit?: InputMaybe<Scalars['String']>;
  readonly loadOn?: InputMaybe<Scalars['String']>;
  readonly expWt?: InputMaybe<Scalars['Float']>;
  readonly expPieces?: InputMaybe<Scalars['String']>;
  readonly uom_temperature?: InputMaybe<Scalars['String']>;
  readonly uom_dimension?: InputMaybe<Scalars['String']>;
  readonly uom_linear?: InputMaybe<Scalars['String']>;
  readonly uom_cube?: InputMaybe<Scalars['String']>;
  readonly uom_density?: InputMaybe<Scalars['String']>;
  readonly temp?: InputMaybe<Scalars['Boolean']>;
  readonly precoolTo?: InputMaybe<Scalars['Float']>;
  readonly minTemp?: InputMaybe<Scalars['Float']>;
  readonly maxTemp?: InputMaybe<Scalars['Float']>;
  readonly tempSetting?: InputMaybe<Scalars['String']>;
  readonly wtUnit?: InputMaybe<Scalars['String']>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly hazmatClass?: InputMaybe<Scalars['String']>;
  readonly packagingGroup?: InputMaybe<Scalars['String']>;
  readonly unNaNumber?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly expDimensions?: InputMaybe<Scalars['String']>;
  readonly expDimensionsW?: InputMaybe<Scalars['Float']>;
  readonly expDimensionsH?: InputMaybe<Scalars['Float']>;
  readonly expDimensionsL?: InputMaybe<Scalars['Float']>;
  readonly expLinear?: InputMaybe<Scalars['Float']>;
  readonly expCube?: InputMaybe<Scalars['Float']>;
  readonly expDensity?: InputMaybe<Scalars['Float']>;
  readonly expClass?: InputMaybe<Scalars['String']>;
  readonly expNmfc?: InputMaybe<Scalars['String']>;
  readonly nmfcSubcode?: InputMaybe<Scalars['String']>;
  readonly unitSystem?: InputMaybe<Scalars['String']>;
  readonly canTopLoad?: InputMaybe<Scalars['Boolean']>;
  readonly canBottomLoad?: InputMaybe<Scalars['Boolean']>;
  readonly overDimensional?: InputMaybe<Scalars['Boolean']>;
  /** YYYY format */
  readonly year?: InputMaybe<Scalars['Int']>;
  readonly make?: InputMaybe<Scalars['String']>;
  readonly model?: InputMaybe<Scalars['String']>;
  readonly splitDimensions?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateCustomerCrmDocumentInput = {
  readonly contacts?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly customerId: Scalars['ID'];
  readonly details?: InputMaybe<Scalars['String']>;
  readonly direction?: InputMaybe<Scalars['String']>;
  readonly documentName: Scalars['String'];
  readonly documentType?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isDeleted: Scalars['Boolean'];
  readonly opportunities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly source?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerCrmNotesRequest = {
  readonly contacts?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly customerId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly notes: Scalars['String'];
  readonly opportunities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly reps: ReadonlyArray<Scalars['ID']>;
  readonly type?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerCrmOpportunityInput = {
  readonly customerId: Scalars['ID'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly opportunityStatusId?: InputMaybe<Scalars['ID']>;
  readonly opportunityTypeId?: InputMaybe<Scalars['ID']>;
  readonly owningContactId?: InputMaybe<Scalars['ID']>;
  readonly owningRepId: Scalars['ID'];
};

export type UpdateCustomerCustomerRelationshipInput = {
  readonly relationshipType?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly relatedCustomerId?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerFacilityInput = {
  readonly schedulingSystem?: InputMaybe<Scalars['String']>;
  readonly schedulingURL?: InputMaybe<Scalars['String']>;
  readonly username?: InputMaybe<Scalars['String']>;
  readonly password?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly isAutoScheduleEligible?: InputMaybe<Scalars['Boolean']>;
  readonly isAutoScheduleDefault?: InputMaybe<Scalars['Boolean']>;
  readonly isPickup?: InputMaybe<Scalars['Boolean']>;
  readonly pickupCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isDelivery?: InputMaybe<Scalars['Boolean']>;
  readonly deliveryCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trailPool?: InputMaybe<Scalars['String']>;
  readonly minTrailers?: InputMaybe<Scalars['Int']>;
  readonly maxTrailers?: InputMaybe<Scalars['Int']>;
  readonly nowTrailers?: InputMaybe<Scalars['Int']>;
  readonly specialRequirementsAndEquipment?: InputMaybe<Scalars['String']>;
  readonly pickupAllowableFreeTimeValue?: InputMaybe<Scalars['Int']>;
  readonly pickupAllowableFreeTimeUnit?: InputMaybe<Scalars['String']>;
  readonly deliveryAllowableFreeTimeValue?: InputMaybe<Scalars['Int']>;
  readonly deliveryAllowableFreeTimeUnit?: InputMaybe<Scalars['String']>;
  readonly drop?: InputMaybe<Scalars['String']>;
  readonly customFullAddressPoint?: InputMaybe<GeopointInput>;
  readonly customerCustomerId?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly facilityId?: InputMaybe<Scalars['String']>;
  readonly stopDetails?: InputMaybe<ReadonlyArray<CustomerFacilityStopDetailsInput>>;
};

export type UpdateCustomerOrderNumberInput = {
  readonly orderId: Scalars['ID'];
  readonly customerOrderNumber: Scalars['String'];
};

export type UpdateCustomerRegionInput = {
  readonly id: Scalars['String'];
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly region?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerStructureInput = {
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
};

export type UpdateDriverAssignmentInput = {
  readonly id: Scalars['ID'];
  readonly userID?: InputMaybe<Scalars['ID']>;
  readonly facilityID?: InputMaybe<Scalars['ID']>;
  readonly equipmentID?: InputMaybe<Scalars['ID']>;
  readonly trackingSystemID?: InputMaybe<Scalars['ID']>;
  readonly trackingEnabled?: InputMaybe<Scalars['Boolean']>;
  /** temporary field until we can resolve user info from the gateway context */
  readonly trackingEnabledBy?: InputMaybe<TrackingUserMetaInput>;
  readonly driver1?: InputMaybe<DriverInput>;
  readonly driver2?: InputMaybe<DriverInput>;
  readonly eldVehicleID?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly trailerIdentifier?: InputMaybe<Scalars['String']>;
  readonly tractorIdentifier?: InputMaybe<Scalars['String']>;
  readonly trailerLocation?: InputMaybe<LocationInput>;
  readonly emptyLocation?: InputMaybe<LocationInput>;
  readonly emptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly emptyTimezone?: InputMaybe<Scalars['String']>;
  readonly etaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly etaTimezone?: InputMaybe<Scalars['String']>;
  readonly checkCallDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly trailerLength?: InputMaybe<Scalars['Float']>;
  readonly trailerWidth?: InputMaybe<Scalars['Float']>;
  readonly trailerHeight?: InputMaybe<Scalars['Float']>;
  readonly dispatched?: InputMaybe<Scalars['Boolean']>;
  /** temporary field until we can resolve user info from the gateway context */
  readonly dispatchedBy?: InputMaybe<TrackingUserMetaInput>;
  readonly externalInfo?: InputMaybe<DriverAssignmentExternalInfoInput>;
  readonly reloadIntentID?: InputMaybe<Scalars['String']>;
};

export type UpdateDriverCommoditiesInput = {
  readonly driverId: Scalars['ID'];
  readonly commodityInfo: CommodityInfoInput;
};

export type UpdateDriverCommoditiesInputV2 = {
  readonly driverId: Scalars['ID'];
  readonly commodityInfo: CommodityInfoInputV2;
};

export type UpdateDriverCommoditiesPayload = {
  readonly __typename?: 'UpdateDriverCommoditiesPayload';
  readonly realm: Scalars['String'];
  readonly driverRoute: DriverRoute;
  readonly errors: ReadonlyArray<UserError>;
};

export type UpdateDriverCommoditiesPayloadV2 = {
  readonly __typename?: 'UpdateDriverCommoditiesPayloadV2';
  readonly realm?: Maybe<Scalars['String']>;
  readonly driverRoute?: Maybe<DriverRouteV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type UpdateDriverMobileInvitationAuditInput = {
  readonly driverId: Scalars['String'];
  readonly driverEmailAddress: Scalars['String'];
  readonly driverRole?: InputMaybe<Scalars['String']>;
  readonly driverStatus?: InputMaybe<Scalars['String']>;
  readonly driverInvitationStatus?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
};

export type UpdateDriverMobileInvitationAuditPayload = {
  readonly __typename?: 'UpdateDriverMobileInvitationAuditPayload';
  readonly updatedSuccess: Scalars['Boolean'];
  readonly errors: ReadonlyArray<UserError>;
};

export type UpdateDriverPayAttributeInput = {
  readonly id: Scalars['ID'];
  readonly effectiveDate: Scalars['Date'];
  readonly effectiveDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type UpdateDriverPayProgramCarrierAssociationInput = {
  readonly id: Scalars['ID'];
  readonly rank: PayProgramRankEnum;
  readonly effectiveDate: Scalars['Date'];
  readonly effectiveDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateDriverPayProgramDriverAssociationInput = {
  readonly id: Scalars['ID'];
  readonly rank: PayProgramRankEnum;
  readonly effectiveDate: Scalars['Date'];
  readonly effectiveDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateDriverPayProgramInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description?: InputMaybe<Scalars['String']>;
};

export type UpdateDriverPayRateTableHeaderInput = {
  readonly id: Scalars['ID'];
  readonly description: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly effectiveDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly confirmUpdate?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateDriverPayRateTableHeaderResult = {
  readonly __typename?: 'UpdateDriverPayRateTableHeaderResult';
  readonly id: Scalars['ID'];
  readonly success: Scalars['Boolean'];
  readonly message?: Maybe<Scalars['String']>;
  readonly errorCode?: Maybe<Scalars['String']>;
  readonly usedOnRoutes?: Maybe<ReadonlyArray<LoadRouteV2>>;
  readonly newlyEligibleRoutes?: Maybe<ReadonlyArray<LoadRouteV2>>;
  readonly payRateTableHeader?: Maybe<PayRateTableHeaderModel>;
};

export type UpdateDriversForWorkflowJobRecordInput = {
  readonly routeId: Scalars['String'];
  readonly driversId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type UpdateDriverStopEventInput = {
  readonly driverId: Scalars['ID'];
  readonly stopEventInfo: StopEventInfoInput;
};

export type UpdateDriverStopEventInputV2 = {
  readonly driverId: Scalars['ID'];
  readonly stopEventInfo: StopEventInfoInputV2;
};

export type UpdateDriverStopEventPayload = {
  readonly __typename?: 'UpdateDriverStopEventPayload';
  readonly realm: Scalars['String'];
  readonly driverRoute: DriverRoute;
  readonly errors: ReadonlyArray<UserError>;
};

export type UpdateDriverStopEventPayloadV2 = {
  readonly __typename?: 'UpdateDriverStopEventPayloadV2';
  readonly realm?: Maybe<Scalars['String']>;
  readonly driverRoute?: Maybe<DriverRouteV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type UpdateEdiDocumentNameInput = {
  readonly id: Scalars['ID'];
  readonly filename: Scalars['String'];
};

export type UpdateEdiDocumentNamePayload = {
  readonly __typename?: 'UpdateEDIDocumentNamePayload';
  readonly edi?: Maybe<EdiDocument>;
};

/** The input type to update Edi Outbox Message. */
export type UpdateEdiOutboxInput = {
  readonly id: Scalars['String'];
  readonly isRetryable: Scalars['Boolean'];
  readonly publishedTimestamp?: InputMaybe<Scalars['DateTime']>;
  readonly eventKey?: InputMaybe<Scalars['String']>;
  readonly eventValue?: InputMaybe<Scalars['String']>;
  readonly eventHeaders?: InputMaybe<ReadonlyArray<Scalars['Byte']>>;
  readonly kafkaTopic?: InputMaybe<Scalars['String']>;
  readonly eventType?: InputMaybe<Scalars['String']>;
  readonly hashedEvent?: InputMaybe<Scalars['String']>;
  readonly retryCount?: InputMaybe<Scalars['Int']>;
  readonly ediTransactionId?: InputMaybe<Scalars['ID']>;
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>;
};

/** The input type to update Edi Transaction. */
export type UpdateEdiTransactionInput = {
  readonly id?: InputMaybe<Scalars['Uuid']>;
  readonly document_id?: InputMaybe<Scalars['Uuid']>;
  readonly direction?: InputMaybe<Scalars['String']>;
  readonly selective_data?: InputMaybe<Scalars['String']>;
  readonly remark?: InputMaybe<Scalars['String']>;
  readonly tracking_update_status_datetime?: InputMaybe<Scalars['DateTime']>;
  readonly is_tender_request?: InputMaybe<Scalars['Boolean']>;
  readonly is_alert_sent?: InputMaybe<Scalars['Boolean']>;
  readonly route_code?: InputMaybe<Scalars['String']>;
  readonly transaction_id?: InputMaybe<Scalars['String']>;
  readonly vendor?: InputMaybe<Scalars['String']>;
  readonly is_carrier_edi_tender_request?: InputMaybe<Scalars['Boolean']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly output_format?: InputMaybe<Scalars['String']>;
  /** processing_status is deprecated, please use statusV2 instead */
  readonly processing_status?: InputMaybe<Scalars['String']>;
  readonly request_id?: InputMaybe<Scalars['Uuid']>;
  readonly shipment_id?: InputMaybe<Scalars['String']>;
  readonly ext_customer_identifier?: InputMaybe<Scalars['String']>;
  /** status is deprecated, please use statusV2 instead */
  readonly status?: InputMaybe<Scalars['String']>;
  readonly statusV2?: InputMaybe<StatusInput>;
  readonly loadId?: InputMaybe<Scalars['Uuid']>;
};

export type UpdateError = {
  readonly __typename?: 'UpdateError';
  readonly message: Scalars['String'];
  readonly stack?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
};

export type UpdateFacilityLoadDefaultsInput = {
  readonly defaultEquipments?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly length?: InputMaybe<Scalars['Float']>;
  readonly width?: InputMaybe<Scalars['Float']>;
  readonly height?: InputMaybe<Scalars['Float']>;
  readonly schedSystem?: InputMaybe<Scalars['String']>;
  readonly schedURL?: InputMaybe<Scalars['String']>;
  readonly specialRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly autoSchedEligible?: InputMaybe<Scalars['Boolean']>;
  readonly autoSchedDefault?: InputMaybe<Scalars['Boolean']>;
  readonly facilityId?: InputMaybe<Scalars['String']>;
  readonly facilityLoadDefaultsDivision?: InputMaybe<ReadonlyArray<CreateOrUpdateFacilityLoadDefaultsDivisionInput>>;
  readonly facilityLoadDefaultsBusinessUnit?: InputMaybe<ReadonlyArray<CreateOrUpdateFacilityLoadDefaultsBusinessUnitInput>>;
};

export type UpdateFacilityNoteDefaultsInput = {
  readonly facilityId?: InputMaybe<Scalars['String']>;
  readonly rateConfirmNote?: InputMaybe<Scalars['String']>;
  readonly externalFacilityNote?: InputMaybe<Scalars['String']>;
  readonly facilityLoadNote?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly noteType?: InputMaybe<Scalars['String']>;
  readonly facilityNoteDefaultsDivision?: InputMaybe<ReadonlyArray<CreateOrUpdateFacilityNoteDefaultsDivisionInput>>;
  readonly facilityNoteDefaultsBusinessUnits?: InputMaybe<ReadonlyArray<CreateOrUpdateFacilityNoteDefaultsBusinessUnitInput>>;
};

export type UpdateFirstTruckEntriesInput = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly driver2Name?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originLocation?: InputMaybe<TruckLocationInput>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  /** The Power (Tractor) unit ID associated with the capacity */
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly powerNumber?: InputMaybe<Scalars['String']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId?: InputMaybe<Scalars['ID']>;
  readonly fridayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly mondayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly saturdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly status?: InputMaybe<TruckEntryTemplateStatus>;
  readonly sourceType?: InputMaybe<TruckEntryTemplateSourceTypesEnum>;
  readonly sundayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly thursdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly tuesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly wednesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  /** Should be the ID of an AssetDriver record. */
  readonly driverId?: InputMaybe<Scalars['ID']>;
  /** Should be the ID of an AssetDriver record. */
  readonly driver2Id?: InputMaybe<Scalars['ID']>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  /** Represents new truckEntryTemplateId from which FirstTrucks will be updated */
  readonly truckEntryTemplateId: Scalars['ID'];
};

export type UpdateGateReservationInput = {
  readonly id: Scalars['ID'];
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  /** DEPRECATED: Use containerProgramId instead */
  readonly containerProgramCarrierId?: InputMaybe<Scalars['ID']>;
  readonly containerProgramId?: InputMaybe<Scalars['String']>;
  readonly endDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly startDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly startTimezone?: InputMaybe<Scalars['String']>;
  readonly endTimezone?: InputMaybe<Scalars['String']>;
  readonly containerTypeId?: InputMaybe<Scalars['ID']>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId: Scalars['ID'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly containerLengthUnit?: InputMaybe<UnitOfLengthEnum>;
  readonly containerLengthValue?: InputMaybe<Scalars['Float']>;
  readonly reservationNumber?: InputMaybe<Scalars['String']>;
  readonly sourceTypeId?: InputMaybe<Scalars['String']>;
};

export type UpdateHaykEventInput = {
  /** eventName is the registered Hayk event name. */
  readonly eventName: Scalars['String'];
  /** enabled represents whether an event has been turned on or off. */
  readonly enabled?: InputMaybe<Scalars['Boolean']>;
  /** eventMode is used in workflow processing and logging operations. Values can be "standard", "tracking", or "tracking-only". */
  readonly eventMode?: InputMaybe<Scalars['String']>;
  /** isVisible determines visibility */
  readonly isVisible?: InputMaybe<Scalars['Boolean']>;
  /** serviceSource is the source service that owns the event */
  readonly serviceSource?: InputMaybe<Scalars['String']>;
  /** eventType is http-event or kafka-event */
  readonly eventType?: InputMaybe<Scalars['String']>;
  /** displayName is what the user will see in the UI */
  readonly displayName?: InputMaybe<Scalars['String']>;
  /** description is description of event */
  readonly description?: InputMaybe<Scalars['String']>;
  /** group is how the event is sorted in MP */
  readonly group?: InputMaybe<Scalars['String']>;
  /** displayGroup -source application */
  readonly displayGroup?: InputMaybe<Scalars['String']>;
  readonly emailConfig?: InputMaybe<HaykEmailConfig>;
};

export type UpdateHaykEventResult = {
  readonly __typename?: 'UpdateHaykEventResult';
  readonly eventName: Scalars['String'];
  readonly name?: Maybe<Scalars['String']>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly eventMode?: Maybe<Scalars['String']>;
  readonly isVisible?: Maybe<Scalars['Boolean']>;
  readonly serviceSource?: Maybe<Scalars['String']>;
  readonly eventType?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly group?: Maybe<Scalars['String']>;
  readonly displayGroup?: Maybe<Scalars['String']>;
  readonly emailConfig?: Maybe<HaykEmailConfigResponse>;
};

export type UpdateHaykRedirectInput = {
  readonly redirectId: Scalars['String'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly redirects: HaykRedirectRedirectsInput;
};

export type UpdateKvtValuesResponse = {
  readonly __typename?: 'updateKvtValuesResponse';
  readonly operationStatus?: Maybe<Scalars['String']>;
};

export type UpdateLoadDefaultsInput = {
  readonly size?: InputMaybe<SizeTypesEnum>;
  readonly sizeV2?: InputMaybe<Scalars['String']>;
  readonly minLength?: InputMaybe<Scalars['Float']>;
  readonly width?: InputMaybe<Scalars['Float']>;
  readonly height?: InputMaybe<Scalars['Float']>;
  readonly cargoValue?: InputMaybe<Scalars['Float']>;
  readonly cargoUnit?: InputMaybe<Scalars['String']>;
  readonly doNotPostLoads?: InputMaybe<Scalars['Boolean']>;
  readonly disableAutoPost?: InputMaybe<Scalars['Boolean']>;
  readonly equipment?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly specialRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly divisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type UpdateLoadDefaultsInputV2 = {
  readonly size?: InputMaybe<SizeTypesEnum>;
  readonly sizeV2?: InputMaybe<Scalars['String']>;
  readonly minLength?: InputMaybe<Scalars['Float']>;
  readonly width?: InputMaybe<Scalars['Float']>;
  readonly height?: InputMaybe<Scalars['Float']>;
  readonly cargoValue?: InputMaybe<Scalars['Float']>;
  readonly cargoUnit?: InputMaybe<Scalars['String']>;
  readonly doNotPostLoads?: InputMaybe<Scalars['Boolean']>;
  readonly disableAutoPost?: InputMaybe<Scalars['Boolean']>;
  readonly equipment?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly specialRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly mode: Scalars['String'];
  readonly divisions: ReadonlyArray<Scalars['String']>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type UpdateLoadDocDetailsInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly orderId?: InputMaybe<Scalars['ID']>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly routeIds?: InputMaybe<Scalars['String']>;
  readonly stopIds?: InputMaybe<Scalars['String']>;
  readonly documentTypeId?: InputMaybe<Scalars['String']>;
};

export type UpdateLoadDocDetailsPayload = {
  readonly __typename?: 'UpdateLoadDocDetailsPayload';
  readonly load?: Maybe<LoadDocument>;
};

export type UpdateLoadInput = {
  readonly id: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
};

export type UpdateLoadInputV2 = {
  readonly id: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly massUpload?: InputMaybe<MassUploadLoadInput>;
  readonly orders?: InputMaybe<ReadonlyArray<CreateOrUpdateCustomerOrderInputV2>>;
  /** Stops created manually from UI will not be eligible for delete/update */
  readonly stops?: InputMaybe<ReadonlyArray<UpdateLoadStopInput>>;
  /** Stops created manually from UI will not be eligible for delete/update */
  readonly stopsV2?: InputMaybe<ReadonlyArray<UpdateLoadStopInput>>;
};

/** Autogenerated return type of UpdateLoad */
export type UpdateLoadPayload = {
  readonly __typename?: 'UpdateLoadPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly load?: Maybe<Load>;
};

export type UpdateLoadRouteInput = {
  readonly id: Scalars['ID'];
  readonly activationStatusId: Scalars['String'];
  readonly divisionV2?: InputMaybe<Scalars['ID']>;
  /** businessUnit is deprecated */
  readonly businessUnit?: InputMaybe<Scalars['ID']>;
  readonly projectV2?: InputMaybe<Scalars['ID']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly dimensions?: InputMaybe<DimensionsInput>;
  readonly cargoInsurance?: InputMaybe<CurrencyInput>;
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly routeType?: InputMaybe<Scalars['ID']>;
  /** added in error, use projectV2 instead */
  readonly project?: InputMaybe<Scalars['String']>;
  readonly tarpType?: InputMaybe<Scalars['ID']>;
  readonly tarpCount?: InputMaybe<Scalars['Int']>;
  readonly braceTypes?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly braceCount?: InputMaybe<Scalars['Int']>;
  readonly cargoInsuranceV2?: InputMaybe<LoadCurrencyInput>;
  readonly additionalDivision?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** additionalBusinessUnit is deprecated */
  readonly additionalBusinessUnit?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly reps?: InputMaybe<ReadonlyArray<RouteAssignedRepInput>>;
  readonly refs?: InputMaybe<ReadonlyArray<RouteReferenceInput>>;
};

export type UpdateLoadStopInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly facilityId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
  readonly isOrderStop?: InputMaybe<Scalars['Boolean']>;
  readonly type?: InputMaybe<StopTypeEnum>;
  readonly loadStopType?: InputMaybe<Scalars['String']>;
  readonly loadFromId?: InputMaybe<Scalars['ID']>;
  readonly unloadFromId?: InputMaybe<Scalars['ID']>;
  readonly requirements?: InputMaybe<ReadonlyArray<StopRequirementInput>>;
  readonly commoditiesV2?: InputMaybe<ReadonlyArray<LoadStopCommodityInput>>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInputV2>>;
  readonly reqDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly bolNumber?: InputMaybe<Scalars['String']>;
  /** Appointment fields for this stop/route */
  readonly appointment?: InputMaybe<AppointmentInputV2>;
  readonly sealNumber?: InputMaybe<Scalars['String']>;
  /** Name of the person who confirmed the appointment */
  readonly apptConfBy?: InputMaybe<Scalars['String']>;
  /** Name of the person who was requested to confirm the appointment */
  readonly apptReqWith?: InputMaybe<Scalars['String']>;
  /** stop unique identifier for external system */
  readonly externalRef?: InputMaybe<Scalars['String']>;
  /** validated against DDT stopScheduleChangeReason */
  readonly appointmentChangeReason?: InputMaybe<Scalars['String']>;
  /** Mapped to Total Handling Units on UI  */
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  /** flag for stops created from FE */
  readonly isCreatedManually?: InputMaybe<Scalars['Boolean']>;
  readonly splc?: InputMaybe<Scalars['String']>;
  /** PU/Del code(s) from customer-facility relationship */
  readonly externalFacilityId?: InputMaybe<Scalars['String']>;
  readonly assignedShipments?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverTargetStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly driverTargetEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly driverTargetStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly driverTargetEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly sequence?: InputMaybe<Scalars['Int']>;
  readonly routeStopSequence?: InputMaybe<Scalars['Int']>;
  readonly orderStopDesignation?: InputMaybe<Scalars['String']>;
};

export type UpdateMaxCostInput = {
  readonly loadId: Scalars['Uuid'];
  readonly routeId: Scalars['Uuid'];
};

export type UpdateMaxCostResponse = {
  readonly __typename?: 'UpdateMaxCostResponse';
  readonly code?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
};

export type UpdateMembershipAccessLevel = {
  readonly organizationID: Scalars['ID'];
  readonly userID: Scalars['ID'];
  readonly canEdit: Scalars['Boolean'];
  readonly requestorUserID?: InputMaybe<Scalars['ID']>;
};

export type UpdateMultipleOfferResponseInput = {
  readonly offerId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly counterPrice?: InputMaybe<CurrencyInput>;
  readonly counterPriceV2?: InputMaybe<CurrencyInputV2>;
  readonly offersDriver?: InputMaybe<OffersDriverInput>;
  readonly operationSource?: InputMaybe<OfferOperationSource>;
  readonly type: OfferResponseType;
};

export type UpdateMultipleOfferResponsePayload = {
  readonly __typename?: 'UpdateMultipleOfferResponsePayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly offerResponse: ReadonlyArray<OfferResponse>;
};

export type UpdateMultipleTenderOptionsInput = {
  readonly tenderOptions?: InputMaybe<ReadonlyArray<InputMaybe<UpdateTenderOptionsInput>>>;
  readonly updatedByUserId: Scalars['ID'];
};

export type UpdateNetworkChannelError = ConflictingPlannedNetworkError | ConflictingChannelLegError | PlannedNetworkError;

export type UpdateNetworkChannelInput = {
  readonly networkChannelId: Scalars['ID'];
  readonly plannedNetworkId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
};

export type UpdateNetworkChannelPayload = {
  readonly __typename?: 'UpdateNetworkChannelPayload';
  readonly data?: Maybe<NetworkChannel>;
  readonly errors?: Maybe<ReadonlyArray<UpdateNetworkChannelError>>;
};

export type UpdateNoteDefaultInput = {
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly customerLoadNote?: InputMaybe<Scalars['String']>;
  readonly rateConfirmNote?: InputMaybe<Scalars['String']>;
  readonly invoiceNote?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly noteType?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type UpdateOfferInput = {
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly trailerOwnerId?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly trailerDimensions?: InputMaybe<DimensionsInput>;
  readonly trailerLength?: InputMaybe<UnitOfLengthInput>;
  readonly trailerWidth?: InputMaybe<UnitOfLengthInput>;
  readonly trailerHeight?: InputMaybe<UnitOfLengthInput>;
  readonly emptyLocationId?: InputMaybe<Scalars['ID']>;
  readonly emptyLocationGeographySourceId?: InputMaybe<Scalars['Float']>;
  readonly emptyLocationGeographySource?: InputMaybe<Scalars['String']>;
  readonly emptyDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly type?: InputMaybe<OfferType>;
  readonly reason?: InputMaybe<OfferReason>;
  readonly offerReason?: InputMaybe<Scalars['String']>;
  readonly price?: InputMaybe<CurrencyInput>;
  readonly offerPrice?: InputMaybe<CurrencyInputV2>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly askPrice?: InputMaybe<CurrencyInput>;
  readonly askPriceV2?: InputMaybe<CurrencyInputV2>;
  readonly vendorValidationErrors?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly offersDriver?: InputMaybe<OffersDriverInput>;
  readonly trailerOne?: InputMaybe<Scalars['String']>;
  readonly trailerTwo?: InputMaybe<Scalars['String']>;
  readonly trailerOwnerName?: InputMaybe<Scalars['String']>;
  readonly isAssetType?: InputMaybe<Scalars['Boolean']>;
  readonly driverOneId?: InputMaybe<Scalars['String']>;
  readonly source?: InputMaybe<Scalars['String']>;
  readonly operationSource?: InputMaybe<OfferOperationSource>;
  readonly id: Scalars['ID'];
  readonly status?: InputMaybe<OfferStatus>;
};

export type UpdateOffersCarrierInput = {
  readonly carrierId: Scalars['ID'];
  readonly consumerName?: InputMaybe<Scalars['String']>;
  readonly isRetried?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateOffersCarrierResponse = {
  readonly __typename?: 'UpdateOffersCarrierResponse';
  readonly carrierId?: Maybe<Scalars['ID']>;
};

export type UpdateOffersDriverInput = {
  readonly driverId: Scalars['ID'];
  readonly consumerName?: InputMaybe<Scalars['String']>;
  readonly isRetried?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateOffersDriverResponse = {
  readonly __typename?: 'UpdateOffersDriverResponse';
  readonly driverId?: Maybe<Scalars['ID']>;
};

export type UpdateOffersRouteConsumersInput = {
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly orderId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly orderRepId?: InputMaybe<Scalars['ID']>;
  readonly eventType?: InputMaybe<Scalars['String']>;
  readonly consumerName?: InputMaybe<Scalars['String']>;
  readonly isRetried?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateOffersRouteResponse = {
  readonly __typename?: 'UpdateOffersRouteResponse';
  readonly routeIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type UpdateOrderDefaultsInput = {
  readonly billToCodeId?: InputMaybe<Scalars['ID']>;
  readonly scacId?: InputMaybe<Scalars['ID']>;
  readonly divisionId?: InputMaybe<Scalars['ID']>;
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly dimensions?: InputMaybe<OrderProfileDimensionsInput>;
  readonly cargoInsuranceV2?: InputMaybe<OrderProfileCurrencyInput>;
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly orderNote?: InputMaybe<Scalars['String']>;
  readonly invoiceNote?: InputMaybe<Scalars['String']>;
};

export type UpdateOrderExternalDistanceInput = {
  readonly orderId: Scalars['ID'];
  readonly externalDistance?: InputMaybe<UnitOfLengthInput>;
};

/** The input type for Finalized FuelIncluded Status */
export type UpdateOrderFuelIncludedStatusType = {
  readonly orderId: Scalars['ID'];
  readonly fuelIncluded?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateOrderProfileInput = {
  readonly id: Scalars['ID'];
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly profileName?: InputMaybe<Scalars['String']>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly conditions?: InputMaybe<LoadDefaultConditionsInput>;
  readonly orderDefaults?: InputMaybe<UpdateOrderDefaultsInput>;
  readonly routeDefaults?: InputMaybe<RouteDefaultsInput>;
};

export type UpdateOrganizationActivationStatus = {
  readonly organizationID: Scalars['ID'];
  readonly activationStatus: Scalars['Boolean'];
  readonly skipChildren?: InputMaybe<Scalars['Boolean']>;
  readonly requestorUserID?: InputMaybe<Scalars['ID']>;
};

export type UpdateOrganizationNameInput = {
  readonly organizationID: Scalars['ID'];
  readonly newName: Scalars['String'];
  readonly requestorUserID?: InputMaybe<Scalars['ID']>;
};

export type UpdateOrganizationParent = {
  readonly organizationID: Scalars['ID'];
  readonly newParentID: Scalars['ID'];
  readonly requestorUserID?: InputMaybe<Scalars['ID']>;
};

export type UpdateOutboundTenderSettingsInput = {
  readonly customerId: Scalars['ID'];
  readonly requestedById?: InputMaybe<Scalars['ID']>;
  readonly tenderAppointmentStatusTerm?: InputMaybe<Scalars['String']>;
  readonly automatedTendering?: InputMaybe<Scalars['Boolean']>;
  readonly rolloverTenderTime?: InputMaybe<Scalars['Boolean']>;
  readonly maximumRolloverTenderTimeInHours?: InputMaybe<Scalars['Int']>;
  readonly maximumRolloverTenderTimeInHoursV2?: InputMaybe<Scalars['Decimal']>;
};

export type UpdatePatternInput = {
  readonly id: Scalars['ID'];
  readonly laneId: Scalars['ID'];
  readonly patternName: Scalars['String'];
  readonly autoApply?: InputMaybe<Scalars['Boolean']>;
  readonly eligibleDays?: InputMaybe<ReadonlyArray<DayOfWeekEnum>>;
  readonly eligibleDaysV2?: InputMaybe<ReadonlyArray<DayEnum>>;
  readonly patternRoutes: ReadonlyArray<UpdatePatternRouteInput>;
  readonly minimumPickupLeadTime?: InputMaybe<Scalars['Int']>;
  readonly userId: Scalars['ID'];
  readonly effectiveDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
};

export type UpdatePatternPayload = {
  readonly __typename?: 'UpdatePatternPayload';
  readonly data?: Maybe<Pattern>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type UpdatePatternRouteInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly patternId: Scalars['ID'];
  readonly routeSequence: Scalars['Int'];
  readonly routeTypeId: Scalars['String'];
  readonly transportModeId: Scalars['String'];
  readonly loadSizeId: Scalars['String'];
  readonly trailerTypeId: Scalars['String'];
  readonly divisionId?: InputMaybe<Scalars['String']>;
  readonly additionalDivisionIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly maxCost?: InputMaybe<Scalars['Float']>;
  readonly routingGuideId?: InputMaybe<Scalars['ID']>;
  readonly lastRoutingGuideCheckDate?: InputMaybe<Scalars['Date']>;
  readonly routeLaneId?: InputMaybe<Scalars['ID']>;
  readonly patternStops: ReadonlyArray<UpdatePatternStopInput>;
  readonly serviceProfileId?: InputMaybe<Scalars['ID']>;
};

export type UpdatePatternStopInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly patternRouteId?: InputMaybe<Scalars['ID']>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly useOrderStopFacility?: InputMaybe<Scalars['Boolean']>;
  readonly stopSequence: Scalars['Int'];
  readonly stopType: Scalars['String'];
  readonly dropType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
  readonly requirements?: InputMaybe<ReadonlyArray<PatternStopRequirementInput>>;
  readonly daysFromPreviousStop?: InputMaybe<Scalars['Int']>;
  readonly laneStopSequence?: InputMaybe<Scalars['Int']>;
  readonly appointment?: InputMaybe<PatternStopAppointmentInput>;
};

export type UpdatePlannedNetworkError = ConflictingPlannedNetworkError | ConflictingNetworkChannelError | PlannedNetworkError;

export type UpdatePlannedNetworkInput = {
  readonly plannedNetworkId: Scalars['ID'];
  readonly name: Scalars['String'];
  /** Minion network_type. */
  readonly networkType: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
};

export type UpdatePlannedNetworkPayload = {
  readonly __typename?: 'UpdatePlannedNetworkPayload';
  readonly data?: Maybe<PlannedNetwork>;
  readonly errors?: Maybe<ReadonlyArray<UpdatePlannedNetworkError>>;
};

export type UpdatePostingInput = {
  readonly id: Scalars['ID'];
  readonly updatedByUserId: Scalars['ID'];
  readonly referenceId?: InputMaybe<Scalars['String']>;
  readonly postedRate: CurrencyInput;
  readonly bookItNowRate?: InputMaybe<CurrencyInput>;
  readonly status?: InputMaybe<PostingStatus>;
  readonly source?: InputMaybe<PostingSource>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly loadBoards?: InputMaybe<ReadonlyArray<PostingSource>>;
  readonly operationSource?: InputMaybe<PostingsOperationSource>;
};

export type UpdatePostingPayload = {
  readonly __typename?: 'UpdatePostingPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly posting?: Maybe<Posting>;
};

export type UpdatePreplanInput = {
  readonly preplanId: Scalars['ID'];
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly drivers?: InputMaybe<ReadonlyArray<PreplansDriverRecordInput>>;
  readonly lastAction?: InputMaybe<Scalars['String']>;
  readonly deadheadMiles?: InputMaybe<Scalars['String']>;
  readonly lineHaulMiles?: InputMaybe<Scalars['String']>;
  readonly firstStopCity?: InputMaybe<Scalars['String']>;
  readonly firstStopState?: InputMaybe<Scalars['String']>;
  readonly firstStopCcaStartDatetime?: InputMaybe<Scalars['String']>;
  readonly firstStopCcaEndDatetime?: InputMaybe<Scalars['String']>;
  readonly lastStopCity?: InputMaybe<Scalars['String']>;
  readonly lastStopState?: InputMaybe<Scalars['String']>;
  readonly lastStopCcaStartDatetime?: InputMaybe<Scalars['String']>;
  readonly lastStopCcaEndDatetime?: InputMaybe<Scalars['String']>;
  readonly pickUpDateTime?: InputMaybe<Scalars['String']>;
  readonly deliveryDateTime?: InputMaybe<Scalars['String']>;
  readonly pickupStartDateTime?: InputMaybe<Scalars['String']>;
  readonly pickupEndDateTime?: InputMaybe<Scalars['String']>;
  readonly deliveryStartDateTime?: InputMaybe<Scalars['String']>;
  readonly deliveryEndDateTime?: InputMaybe<Scalars['String']>;
  readonly isDriverTargetDatetime?: InputMaybe<Scalars['Boolean']>;
  readonly scheduleType?: InputMaybe<Scalars['String']>;
  readonly isAppointmentConfirmed?: InputMaybe<Scalars['Boolean']>;
  readonly isDriverTargetDateTimePickup?: InputMaybe<Scalars['Boolean']>;
  readonly scheduleTypePickup?: InputMaybe<Scalars['String']>;
  readonly isAppointmentConfirmedPickup?: InputMaybe<Scalars['Boolean']>;
  readonly isDriverTargetDateTimeDelivery?: InputMaybe<Scalars['Boolean']>;
  readonly scheduleTypeDelivery?: InputMaybe<Scalars['String']>;
  readonly isAppointmentConfirmedDelivery?: InputMaybe<Scalars['Boolean']>;
  readonly expectedWeight?: InputMaybe<Scalars['String']>;
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly powerCode?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly carrierCode?: InputMaybe<Scalars['ID']>;
  readonly tractorNumber?: InputMaybe<Scalars['ID']>;
};

export type UpdatePreplanResponse = {
  readonly __typename?: 'UpdatePreplanResponse';
  readonly preplan?: Maybe<Preplan>;
  readonly hasErrors?: Maybe<Scalars['Boolean']>;
  readonly errors?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type UpdateProviderConfigResponse = {
  readonly __typename?: 'UpdateProviderConfigResponse';
  readonly status?: Maybe<Scalars['Boolean']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly isAuthenticated?: Maybe<Scalars['Boolean']>;
};

export type UpdateProviderIdConfigResponse = {
  readonly __typename?: 'UpdateProviderIdConfigResponse';
  readonly status?: Maybe<Scalars['Float']>;
  readonly message?: Maybe<Scalars['String']>;
};

export type UpdateRateQuoteInput = {
  readonly autoAccept?: InputMaybe<Scalars['Boolean']>;
  readonly customDistanceRequest?: InputMaybe<CustomDistanceInput>;
  readonly distanceType?: InputMaybe<Scalars['Boolean']>;
  readonly effectiveDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly isContinue?: InputMaybe<Scalars['Boolean']>;
  readonly lineItems: ReadonlyArray<RateQuoteLineItemInput>;
  /** Deprecated */
  readonly orderRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly quoteFuelConfiguration?: InputMaybe<Scalars['String']>;
  readonly rateQuoteId: Scalars['ID'];
  readonly scac?: InputMaybe<Scalars['String']>;
};

export type UpdateRateQuoteInputV1 = {
  readonly autoAccept?: InputMaybe<Scalars['Boolean']>;
  readonly customDistanceRequest?: InputMaybe<CustomDistanceInput>;
  readonly distanceType?: InputMaybe<Scalars['Boolean']>;
  readonly effectiveDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly externalPricing?: InputMaybe<Scalars['Boolean']>;
  readonly isContinue?: InputMaybe<Scalars['Boolean']>;
  readonly lineItems?: InputMaybe<ReadonlyArray<InputMaybe<RateQuoteLineItemInput>>>;
  readonly orderRequirements?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly quoteFuelConfiguration?: InputMaybe<Scalars['String']>;
  readonly rateQuoteId: Scalars['ID'];
  readonly scac?: InputMaybe<Scalars['String']>;
};

export type UpdateReloadIntentCapacityReadyByDateTimeInput = {
  readonly previousRouteIdV2: Scalars['ID'];
  readonly stopId: Scalars['ID'];
};

/** The input type for Carrier Finalized Bypass Fuel Status */
export type UpdateRouteBypassFuelStatusInput = {
  readonly routeId: Scalars['ID'];
  readonly bypassFuel?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateRouteInput = {
  readonly activationStatusId: Scalars['ID'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly trailerTypeId: Scalars['ID'];
};

/** Autogenerated return type of UpdateRoute */
export type UpdateRoutePayload = {
  readonly __typename?: 'UpdateRoutePayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly route?: Maybe<LoadRoute>;
};

export type UpdateRouteStopsInput = {
  readonly routeId: Scalars['ID'];
  readonly stopIds: ReadonlyArray<Scalars['ID']>;
};

/** Autogenerated return type of UpdateRouteStops */
export type UpdateRouteStopsPayload = {
  readonly __typename?: 'UpdateRouteStopsPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly route: LoadRoute;
};

export type UpdateRouteVendorInput = {
  readonly bookedById?: InputMaybe<Scalars['ID']>;
  readonly bookedWithId?: InputMaybe<Scalars['ID']>;
  readonly bookingNotes?: InputMaybe<Scalars['String']>;
  readonly bracingType?: InputMaybe<BracingTypeEnum>;
  readonly distance?: InputMaybe<UnitOfDistanceInput>;
  readonly expectedEmptyGeographySource?: InputMaybe<Scalars['String']>;
  readonly expectedEmptyGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly numberOfBraces?: InputMaybe<Scalars['Int']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  readonly numberOfTarps?: InputMaybe<Scalars['Int']>;
  readonly palletType?: InputMaybe<Scalars['String']>;
  readonly routeVendorReps?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorRepInput>>;
  readonly tarpType?: InputMaybe<Scalars['String']>;
  readonly trailerHeight?: InputMaybe<UnitOfDistanceInput>;
  readonly trailerHeightV2?: InputMaybe<UnitOfLengthInput>;
  readonly trailerLength?: InputMaybe<UnitOfDistanceInput>;
  readonly trailerLengthV2?: InputMaybe<UnitOfLengthInput>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly trailerWidth?: InputMaybe<UnitOfDistanceInput>;
  readonly trailerWidthV2?: InputMaybe<UnitOfLengthInput>;
  readonly utcExpectedEmptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly utcPickupEtaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly vendorType?: InputMaybe<RouteVendorTypeEnum>;
};

/** Autogenerated return type of UpdateRouteVendor */
export type UpdateRouteVendorPayload = {
  readonly __typename?: 'UpdateRouteVendorPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendor?: Maybe<RouteVendor>;
};

/** Input for updating a Carrier / Vendor on a Route */
export type UpdateRouteVendorV2Input = {
  /** The ID of the Route Vendor to be updated */
  readonly id: Scalars['ID'];
  /** The ID of the Active Bounce if the Route Vendor is currently Bounced */
  readonly activeBounceId?: InputMaybe<Scalars['ID']>;
  /** The Employee ID of the Booking User */
  readonly bookedById?: InputMaybe<Scalars['ID']>;
  /** The CarrierContact ID of the Contact the Route was Booked with */
  readonly bookedWithId?: InputMaybe<Scalars['ID']>;
  /** The Booking mechanism used to Book the Route -- *Requires 'Route Booking Source Type' DDT* */
  readonly bookingSourceId?: InputMaybe<Scalars['ID']>;
  /** General Notes to include with the Booking */
  readonly bookingNotes?: InputMaybe<Scalars['String']>;
  /** Booking capacity information */
  readonly capacities?: InputMaybe<ReadonlyArray<BookingCapacityInput>>;
  /** The Distance between the Driver's Expected Ready Location and the First Stop Location on the Route. */
  readonly distance?: InputMaybe<UnitOfDistanceInput>;
  /** The Division responsible for the Booking. Include this if the Executing Division needs to change from the original Booking */
  readonly executingDivisionId?: InputMaybe<Scalars['ID']>;
  /** The source of the Geography data's ID. This is likely '*geonames*' */
  readonly expectedEmptyGeographySource?: InputMaybe<Scalars['String']>;
  /** The source ID from the Geography service */
  readonly expectedEmptyGeographySourceId?: InputMaybe<Scalars['Int']>;
  /** The Expected Ready Location Information for the Carrier */
  readonly expectedEmptyLocation?: InputMaybe<ExpectedEmptyLocationInput>;
  /** This is set to *true* when the Carrier is Pre-Assigned to the Route. Carriers cannot be marked as *booked* when they are Pre-Assigned */
  readonly isPreAssigned?: InputMaybe<Scalars['Boolean']>;
  /** Braces the carrier is expected to have.  Multi-Select DDT */
  readonly braces?: InputMaybe<BracesInput>;
  /** The type of Bracing Equipment the Carrier is expected to have */
  readonly bracingType?: InputMaybe<BracingTypeEnum>;
  /** The number of Braces the Carrier is expected to have */
  readonly numberOfBraces?: InputMaybe<Scalars['Int']>;
  /** ⚠️ **Deprecated! Use palletTypeV2** ⚠️ */
  readonly palletType?: InputMaybe<PalletTypeEnum>;
  /** The type of Pallets the Carrier is expected to have */
  readonly palletTypeV2?: InputMaybe<Scalars['String']>;
  /** The number of Pallets the Carrier is expected to have */
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  /** ⚠️ **Deprecated! Use tarpTypeV2** ⚠️ */
  readonly tarpType?: InputMaybe<TarpTypeEnum>;
  /** The type of Tarp the Carrier is expected to have */
  readonly tarpTypeV2?: InputMaybe<Scalars['String']>;
  /** The number of Tarps the Carrier is expected to have */
  readonly numberOfTarps?: InputMaybe<Scalars['Int']>;
  /** ⚠️ **Deprecated! Use routeVendorRepsV2** ⚠️ */
  readonly routeVendorReps?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorRepV2Input>>;
  /** The Array of Route Vendor Reps to be Created, Updated, or Removed */
  readonly routeVendorRepsV2?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorRepV3Input>>;
  /** ⚠️ **Deprecated! Use routeVendorReferencesV2** ⚠️ */
  readonly routeVendorReferences?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorReferenceInput>>;
  /** The Array of Route References to be Created, Updated, or Removed */
  readonly routeVendorReferencesV2?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorReferenceInputV2>>;
  /** ⚠️ **Deprecated! Use trailerHeightV2** ⚠️ */
  readonly trailerHeight?: InputMaybe<UnitOfDistanceInput>;
  /** The expected Height of the Carrier's Trailer */
  readonly trailerHeightV2?: InputMaybe<LengthInput>;
  /** ⚠️ **Deprecated! Use trailerLengthV2** ⚠️ */
  readonly trailerLength?: InputMaybe<UnitOfDistanceInput>;
  /** The expected Length of the Carrier's Trailer */
  readonly trailerLengthV2?: InputMaybe<LengthInput>;
  /** ⚠️ **Deprecated! Use trailerWidthV2** ⚠️ */
  readonly trailerWidth?: InputMaybe<UnitOfDistanceInput>;
  /** The expected Width of the Carrier's Trailer */
  readonly trailerWidthV2?: InputMaybe<LengthInput>;
  /** The expected Trailer Type of the Carrier -- *Requires 'Trailer Type' DDT* */
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  /** The ID of the User who is updating the Route Vendor */
  readonly updatedByUserId?: InputMaybe<Scalars['ID']>;
  /** UTC Timestamp representing the Date & Time the Driver is expected to be ready prior to this Route */
  readonly utcExpectedEmptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** UTC Timestamp representing the estimated Date & Time the Driver is expected to arrive at the first Stop for pickup */
  readonly utcPickupEtaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** The type of Vendor that this Route Vendor represents (ex. Carrier, TONU, etc) */
  readonly vendorType?: InputMaybe<RouteVendorTypeEnum>;
};

/** The response from the **updateRouteVendorV2** Mutation */
export type UpdateRouteVendorV2Payload = {
  readonly __typename?: 'UpdateRouteVendorV2Payload';
  /** The updated Route Vendor. The existing Route Vendor is returned when update fails */
  readonly routeVendor?: Maybe<RouteVendorV2>;
  /** An Array of any Validation or Update Errors that were encountered */
  readonly errors: ReadonlyArray<UserError>;
};

export type UpdateRoutingGuideInput = {
  readonly id: Scalars['ID'];
  readonly routingGuideTypeTerm: Scalars['String'];
  readonly duration?: InputMaybe<Scalars['Int']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive: Scalars['Boolean'];
  readonly updatedBy: Scalars['ID'];
};

export type UpdateRoutingGuideSequenceItemInput = {
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly scac: Scalars['String'];
  readonly updatedBy: Scalars['ID'];
  readonly tenderOptions?: InputMaybe<SequenceItemTenderOptionsInput>;
};

export type UpdateRuleSetsInput = {
  readonly eventName: Scalars['String'];
  readonly ruleSets: ReadonlyArray<InputMaybe<RuleSetInput>>;
};

/** As of now, this is simply a placeholder response object in case more response fields are needed in the future. */
export type UpdateRuleSetsResult = {
  readonly __typename?: 'UpdateRuleSetsResult';
  readonly eventName: Scalars['String'];
  readonly ruleSets: ReadonlyArray<Maybe<RuleSet>>;
};

export type UpdateServiceProfileInput = {
  readonly carrierId: Scalars['String'];
  readonly originRamp: Scalars['String'];
  readonly destinationRamp: Scalars['String'];
  readonly train?: InputMaybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly isCOFC?: InputMaybe<Scalars['Boolean']>;
  readonly isTOFC?: InputMaybe<Scalars['Boolean']>;
  readonly publishedTransitDays: Scalars['Float'];
  readonly reqOrigin?: InputMaybe<Scalars['Boolean']>;
  readonly reqLane?: InputMaybe<Scalars['Boolean']>;
  readonly boxType?: InputMaybe<Scalars['String']>;
  readonly laneStartDate: Scalars['DateTime'];
  readonly laneExpirationDate: Scalars['DateTime'];
  readonly laneExpirationDateV2?: InputMaybe<Scalars['DateTime']>;
  readonly schedules: BaseSchedulesInput;
};

export type UpdateServiceProfileInputV2 = {
  readonly carrierId: Scalars['String'];
  readonly originRamp: Scalars['String'];
  readonly destinationRamp: Scalars['String'];
  readonly train?: InputMaybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly isCOFC?: InputMaybe<Scalars['Boolean']>;
  readonly isTOFC?: InputMaybe<Scalars['Boolean']>;
  readonly publishedTransitDays: Scalars['Float'];
  readonly reqOrigin?: InputMaybe<Scalars['Boolean']>;
  readonly reqLane?: InputMaybe<Scalars['Boolean']>;
  readonly boxType?: InputMaybe<Scalars['String']>;
  readonly laneStartDate: Scalars['DateTime'];
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly laneExpirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly schedules?: InputMaybe<BaseSchedulesInputV2>;
  readonly serviceProfileId: Scalars['Float'];
  readonly facilityTypeForOriginRamp: Scalars['String'];
  readonly facilityTypeForDestinationRamp: Scalars['String'];
};

export type UpdateSpotQuoteInput = {
  readonly spotQuoteId: Scalars['ID'];
  readonly expirationDate: Scalars['Date'];
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  /** The user saving the spot quote */
  readonly updatedBy: Scalars['ID'];
  readonly rateLineItems?: InputMaybe<ReadonlyArray<SpotQuoteRateLineItemInput>>;
  readonly quoteDetails?: InputMaybe<ReadonlyArray<SpotQuoteQuoteDetailInput>>;
  /** The Minion Quote Fuel Configuration's OptionIds */
  readonly quoteFuelConfiguration?: InputMaybe<Scalars['String']>;
  readonly isVariableDistanceType?: InputMaybe<Scalars['Boolean']>;
  readonly customDistance?: InputMaybe<Scalars['Decimal']>;
  /** The minion distanceType's OptionId */
  readonly customDistanceUnit?: InputMaybe<Scalars['String']>;
};

export type UpdateSpotQuotePayload = {
  readonly __typename?: 'UpdateSpotQuotePayload';
  readonly data?: Maybe<SpotQuote>;
  readonly errors?: Maybe<ReadonlyArray<SpotQuoteError>>;
};

export type UpdateSpotQuoteV2Input = {
  readonly spotQuoteId: Scalars['ID'];
  readonly expirationDate: Scalars['Date'];
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  /** The user saving the spot quote */
  readonly updatedBy: Scalars['ID'];
  readonly quoteDetails?: InputMaybe<ReadonlyArray<SpotQuoteQuoteDetailInput>>;
  /** The Minion Quote Fuel Configuration's OptionIds */
  readonly quoteFuelConfiguration?: InputMaybe<Scalars['String']>;
  readonly isVariableDistanceType?: InputMaybe<Scalars['Boolean']>;
  readonly customDistance?: InputMaybe<Scalars['Decimal']>;
  /** The minion distanceType's OptionId */
  readonly customDistanceUnit?: InputMaybe<Scalars['String']>;
  /** The minion Tenant Scac's OptionId */
  readonly scac?: InputMaybe<Scalars['String']>;
  /** The Minion orderRequirement's OptionIds */
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type UpdateTaskInputV2 = {
  readonly id: Scalars['ID'];
  readonly type?: InputMaybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly noteName?: InputMaybe<Scalars['String']>;
  readonly assignedTo?: InputMaybe<Scalars['String']>;
  readonly neededBy?: InputMaybe<Scalars['DateTime']>;
  readonly routeId?: InputMaybe<Scalars['String']>;
  readonly loadId?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly requestedBy?: InputMaybe<Scalars['ID']>;
  readonly groupId?: InputMaybe<Scalars['String']>;
  readonly assignedToGroupId?: InputMaybe<Scalars['String']>;
  readonly comment?: InputMaybe<Scalars['String']>;
  readonly closedDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateTemplatesInput = {
  readonly eventName: Scalars['String'];
  readonly templates: ReadonlyArray<InputMaybe<ContentTemplateInput>>;
};

export type UpdateTemplatesResult = {
  readonly __typename?: 'UpdateTemplatesResult';
  readonly eventName: Scalars['String'];
  readonly templates: ReadonlyArray<Maybe<ContentTemplate>>;
};

export type UpdateTenderOptionsInput = {
  readonly parentObjectType: Scalars['String'];
  readonly parentObjectId: Scalars['ID'];
  readonly tenderExpirationTimeSpan?: InputMaybe<TenderTimeSpanInput>;
  readonly autoTender?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateTenderResponseInEdiTender = {
  readonly __typename?: 'UpdateTenderResponseInEdiTender';
  readonly is_updated?: Maybe<Scalars['Boolean']>;
};

export type UpdateTenderResponseInEdiTenderInput = {
  readonly route_code?: InputMaybe<Scalars['String']>;
  readonly scac?: InputMaybe<Scalars['String']>;
  readonly tender_response?: InputMaybe<Scalars['String']>;
  readonly declined_reason?: InputMaybe<Scalars['String']>;
};

export type UpdateTruckEntryByRouteIdInput = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly driver2Name?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originLocation?: InputMaybe<TruckLocationInput>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  /** The Power (Tractor) unit ID associated with the capacity */
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly powerNumber?: InputMaybe<Scalars['String']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId?: InputMaybe<Scalars['ID']>;
  readonly bracingType?: InputMaybe<BracingTypesEnum>;
  readonly bracingCount?: InputMaybe<Scalars['Float']>;
  readonly bracingTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Provide time in milliseconds. */
  readonly breakTimeMs?: InputMaybe<Scalars['Float']>;
  /** Provide time in milliseconds. Cannot exceed 70 hours */
  readonly cycleTimeMs?: InputMaybe<Scalars['Float']>;
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly driverDomicile?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly driverPhone?: InputMaybe<Scalars['String']>;
  readonly driver2Domicile?: InputMaybe<Scalars['String']>;
  readonly driver2Id?: InputMaybe<Scalars['ID']>;
  readonly driver2Phone?: InputMaybe<Scalars['String']>;
  /** Provide time in milliseconds. Cannot exceed 14 hours */
  readonly driveTimeMs?: InputMaybe<Scalars['Float']>;
  readonly externalSourceId?: InputMaybe<Scalars['String']>;
  /** Truck's finalBy datetime. Value is nullified if the finalByDatetime value is less than the loadByDatetime value. */
  readonly finalByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly finalByTimezone?: InputMaybe<Scalars['String']>;
  /** Truck's loadBy datetime, defaults to the readyDatetimeUtc (EOD) if a value is not provided or if the loadByDatetimeUtc value is less than the readyDatetimeUtc value */
  readonly loadByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly loadByTimezone?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly palletsType?: InputMaybe<PalletTypesEnum>;
  readonly palletsCount?: InputMaybe<Scalars['Float']>;
  readonly palletTypeId?: InputMaybe<Scalars['String']>;
  /** DEPRECATED: Use previousRouteIdV2 instead */
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  readonly previousRouteIdV2: Scalars['ID'];
  readonly readyDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Provide time in milliseconds. Cannot exceed 16 hours */
  readonly shiftTimeMs?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED: Use sourceTypeId instead */
  readonly sourceType?: InputMaybe<SourceTypesEnum>;
  readonly sourceTypeId?: InputMaybe<Scalars['String']>;
  readonly tarpsType?: InputMaybe<TarpTypesEnum>;
  readonly tarpsCount?: InputMaybe<Scalars['Float']>;
  readonly tarpTypeId?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly truckEntryTemplateId?: InputMaybe<Scalars['ID']>;
  /** DDT ID for the capacity source of the projected hours of service */
  readonly phosSourceId?: InputMaybe<Scalars['String']>;
  /** If Origin Facility already exists on the entity and only Origin Location is provided in the input, the Origin Location will be ignored. Set Origin Facility to `null` to set the Origin Location. */
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly updateSource?: InputMaybe<Scalars['String']>;
};

export type UpdateTruckEntryInput = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly driver2Name?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originLocation?: InputMaybe<TruckLocationInput>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  /** The Power (Tractor) unit ID associated with the capacity */
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly powerNumber?: InputMaybe<Scalars['String']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId: Scalars['ID'];
  readonly bracingType?: InputMaybe<BracingTypesEnum>;
  readonly bracingCount?: InputMaybe<Scalars['Float']>;
  readonly bracingTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Provide time in milliseconds. */
  readonly breakTimeMs?: InputMaybe<Scalars['Float']>;
  /** Provide time in milliseconds. Cannot exceed 70 hours */
  readonly cycleTimeMs?: InputMaybe<Scalars['Float']>;
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly driverDomicile?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly driverPhone?: InputMaybe<Scalars['String']>;
  readonly driver2Domicile?: InputMaybe<Scalars['String']>;
  readonly driver2Id?: InputMaybe<Scalars['ID']>;
  readonly driver2Phone?: InputMaybe<Scalars['String']>;
  /** Provide time in milliseconds. Cannot exceed 14 hours */
  readonly driveTimeMs?: InputMaybe<Scalars['Float']>;
  readonly externalSourceId?: InputMaybe<Scalars['String']>;
  /** Truck's finalBy datetime. Value is nullified if the finalByDatetime value is less than the loadByDatetime value. */
  readonly finalByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly finalByTimezone?: InputMaybe<Scalars['String']>;
  /** Truck's loadBy datetime, defaults to the readyDatetimeUtc (EOD) if a value is not provided or if the loadByDatetimeUtc value is less than the readyDatetimeUtc value */
  readonly loadByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly loadByTimezone?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly palletsType?: InputMaybe<PalletTypesEnum>;
  readonly palletsCount?: InputMaybe<Scalars['Float']>;
  readonly palletTypeId?: InputMaybe<Scalars['String']>;
  /** DEPRECATED: Use previousRouteIdV2 instead */
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  readonly previousRouteIdV2?: InputMaybe<Scalars['ID']>;
  readonly readyDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Provide time in milliseconds. Cannot exceed 16 hours */
  readonly shiftTimeMs?: InputMaybe<Scalars['Float']>;
  readonly sourceType?: InputMaybe<SourceTypesEnum>;
  readonly sourceTypeId?: InputMaybe<Scalars['String']>;
  readonly tarpsType?: InputMaybe<TarpTypesEnum>;
  readonly tarpsCount?: InputMaybe<Scalars['Float']>;
  readonly tarpTypeId?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly truckEntryTemplateId?: InputMaybe<Scalars['ID']>;
  /** DDT ID for the capacity source of the projected hours of service */
  readonly phosSourceId?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  /** If Origin Facility already exists on the entity and only Origin Location is provided in the input, the Origin Location will be ignored. Set Origin Facility to `null` to set the Origin Location. */
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly updateSource?: InputMaybe<Scalars['String']>;
};

export type UpdateTruckEntryTemplateInput = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly driver2Name?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originLocation?: InputMaybe<TruckLocationInput>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  /** The Power (Tractor) unit ID associated with the capacity */
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly powerNumber?: InputMaybe<Scalars['String']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId?: InputMaybe<Scalars['ID']>;
  readonly fridayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly mondayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly saturdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly status?: InputMaybe<TruckEntryTemplateStatus>;
  readonly sourceType?: InputMaybe<TruckEntryTemplateSourceTypesEnum>;
  readonly sundayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly thursdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly tuesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly wednesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  /** Should be the ID of an AssetDriver record. */
  readonly driverId?: InputMaybe<Scalars['ID']>;
  /** Should be the ID of an AssetDriver record. */
  readonly driver2Id?: InputMaybe<Scalars['ID']>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type UpdateTruckEntryUtilizationStatusInput = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly driver2Name?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  /** If Origin Facility exists, the Origin Location address will be overwritten with the Facility's main address */
  readonly originLocation?: InputMaybe<TruckLocationInput>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  /** The Power (Tractor) unit ID associated with the capacity */
  readonly powerId?: InputMaybe<Scalars['ID']>;
  readonly powerNumber?: InputMaybe<Scalars['String']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  /** If the trailer type is "Power Only", the trailer length will be set to null. */
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId: Scalars['ID'];
  readonly bracingType?: InputMaybe<BracingTypesEnum>;
  readonly bracingCount?: InputMaybe<Scalars['Float']>;
  readonly bracingTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Provide time in milliseconds. */
  readonly breakTimeMs?: InputMaybe<Scalars['Float']>;
  /** Provide time in milliseconds. Cannot exceed 70 hours */
  readonly cycleTimeMs?: InputMaybe<Scalars['Float']>;
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly driverDomicile?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['ID']>;
  readonly driverPhone?: InputMaybe<Scalars['String']>;
  readonly driver2Domicile?: InputMaybe<Scalars['String']>;
  readonly driver2Id?: InputMaybe<Scalars['ID']>;
  readonly driver2Phone?: InputMaybe<Scalars['String']>;
  /** Provide time in milliseconds. Cannot exceed 14 hours */
  readonly driveTimeMs?: InputMaybe<Scalars['Float']>;
  readonly externalSourceId?: InputMaybe<Scalars['String']>;
  /** Truck's finalBy datetime. Value is nullified if the finalByDatetime value is less than the loadByDatetime value. */
  readonly finalByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly finalByTimezone?: InputMaybe<Scalars['String']>;
  /** Truck's loadBy datetime, defaults to the readyDatetimeUtc (EOD) if a value is not provided or if the loadByDatetimeUtc value is less than the readyDatetimeUtc value */
  readonly loadByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly loadByTimezone?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly palletsType?: InputMaybe<PalletTypesEnum>;
  readonly palletsCount?: InputMaybe<Scalars['Float']>;
  readonly palletTypeId?: InputMaybe<Scalars['String']>;
  /** DEPRECATED: Use previousRouteIdV2 instead */
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  readonly previousRouteIdV2?: InputMaybe<Scalars['ID']>;
  readonly readyDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Provide time in milliseconds. Cannot exceed 16 hours */
  readonly shiftTimeMs?: InputMaybe<Scalars['Float']>;
  readonly sourceType?: InputMaybe<SourceTypesEnum>;
  readonly sourceTypeId?: InputMaybe<Scalars['String']>;
  readonly tarpsType?: InputMaybe<TarpTypesEnum>;
  readonly tarpsCount?: InputMaybe<Scalars['Float']>;
  readonly tarpTypeId?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly truckEntryTemplateId?: InputMaybe<Scalars['ID']>;
  /** DDT ID for the capacity source of the projected hours of service */
  readonly phosSourceId?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  /** If Origin Facility already exists on the entity and only Origin Location is provided in the input, the Origin Location will be ignored. Set Origin Facility to `null` to set the Origin Location. */
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly updateSource?: InputMaybe<Scalars['String']>;
  readonly assignedRouteId?: InputMaybe<Scalars['ID']>;
  /** Corresponds to "capacity_utilization_status" DDT id */
  readonly capacityUtilizationStatusId?: InputMaybe<Scalars['ID']>;
};

/** The input type to update unprocessed edi 204 transactions. */
export type UpdateUnprocessedEdi204TransactionsInput = {
  readonly id: Scalars['Uuid'];
};

export type UpdateValidationConfigurationInput = {
  /** A hash identifier that is generated by Canvas. Created by concatenating the validator key values and hashing them */
  readonly hashId?: InputMaybe<Scalars['String']>;
  readonly entityVersion?: InputMaybe<Scalars['Int']>;
  readonly expiredCacheBehavior: ExpiredCacheBehavior;
  /** If a customer defined validation configuration is not met, this is the expected behavior */
  readonly failedValidationBehavior?: InputMaybe<Scalars['String']>;
  /** GlobalDefault indicator, flags whether this validation configuration is considered a fallback option applicable for this entity and state */
  readonly globalDefault: Scalars['Boolean'];
  readonly outdatedFallbackBehavior: OutdatedFallbackBehavior;
  /** List of rules that apply for this validation configuration */
  readonly requirements: ReadonlyArray<RequirementInput>;
  readonly validatorMetadata?: InputMaybe<ValidatorMetadataInput>;
  /** Scope to which this validator belongs to such as a tenant, carrier or specific customer. Corresponds to the scopedEntityUuid */
  readonly configScope: ConfigScope;
  /** Division associated with the entity, must match a division DDT */
  readonly division?: InputMaybe<Scalars['String']>;
  readonly mode?: InputMaybe<Mode>;
  /** Mode associated with the entity, must match a transportMode DDT */
  readonly transportMode?: InputMaybe<Scalars['String']>;
  /** Unique Identifier of the entity this validator belongs to. Expected to be null when configScope is tenant level */
  readonly scopedEntityUuid?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Size>;
  /** Load Size associated with the entity, must match a loadSize DDT */
  readonly loadSize?: InputMaybe<Scalars['String']>;
  /** Trailer Type equipment associated with the entity, must match a trailerType DDT option ID in UUID format */
  readonly equipment?: InputMaybe<Scalars['String']>;
  /** Unit associated with the entity, must match a businessUnit DDT */
  readonly unit?: InputMaybe<Scalars['String']>;
  readonly validationEntityType?: InputMaybe<ValidationEntityType>;
  /** Type of entity this validator applies to, such as 'order' or 'customer' */
  readonly validationEntityTypeDDT?: InputMaybe<Scalars['String']>;
  readonly validationState?: InputMaybe<ValidationState>;
  /** State/Action of the entity taking place when this validation is checked, such as invoice generation or entity creation */
  readonly validationStateDDT?: InputMaybe<Scalars['String']>;
  /** Route type associated with the entity, must match a routeType DDT */
  readonly routeType?: InputMaybe<Scalars['String']>;
  /** Route Transport Mode associated with the entity, must match a routeTransportMode DDT */
  readonly routeTransportMode?: InputMaybe<Scalars['String']>;
  /** Bill to Code associated with the entity */
  readonly billToCode?: InputMaybe<Scalars['String']>;
  /** Vendor Type associated with the entity and state */
  readonly vendorType?: InputMaybe<Scalars['String']>;
  /** Unique identifier generated by Canvas */
  readonly uuid: Scalars['ID'];
};

export type UpdateWfjRecordInput = {
  readonly routeId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLifeCycleStatus?: InputMaybe<Scalars['String']>;
};

export type UpdateWorkflowError = {
  readonly __typename?: 'UpdateWorkflowError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type UpdateWorkflowJob = {
  readonly __typename?: 'UpdateWorkflowJob';
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  /** JSON value of mapped RouteDetails */
  readonly routeDetails: Scalars['JSON'];
};

export type UpdateWorkflowJobError = {
  readonly __typename?: 'UpdateWorkflowJobError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type UpdateWorkflowJobInput = {
  /** JSON value of input */
  readonly input: Scalars['JSON'];
  readonly eventType: Scalars['String'];
};

export type UpdateWorkflowJobPayLoad = {
  readonly __typename?: 'UpdateWorkflowJobPayLoad';
  readonly errors?: Maybe<ReadonlyArray<UpdateWorkflowError>>;
  readonly updateWorkFlowEvent?: Maybe<UpdateWorkflowJob>;
};

export type UpdateWorkflowJobRecord = {
  readonly __typename?: 'UpdateWorkflowJobRecord';
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  /** JSON value of mapped RouteDetails */
  readonly routeDetails: Scalars['JSON'];
};

export type UpdateWorkflowJobRecordInput = {
  readonly routeId: Scalars['String'];
  readonly carrierId: Scalars['String'];
  readonly driversId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type UpdateWorkflowJobRecordPayLoad = {
  readonly __typename?: 'UpdateWorkflowJobRecordPayLoad';
  readonly errors?: Maybe<ReadonlyArray<UpdateWorkflowJobError>>;
  readonly updateWorkFlowEvent?: Maybe<UpdateWorkflowJobRecord>;
};

export type UploadEventPayload = {
  readonly __typename?: 'UploadEventPayload';
  readonly status?: Maybe<Scalars['String']>;
  readonly workbookId?: Maybe<Scalars['String']>;
};

export type UploadRoutingGuideError = {
  readonly __typename?: 'UploadRoutingGuideError';
  readonly message: Scalars['String'];
  readonly index: Scalars['Int'];
};

export type UploadRoutingGuideErrorV2 = {
  readonly __typename?: 'UploadRoutingGuideErrorV2';
  readonly message: Scalars['String'];
  readonly recordId: Scalars['String'];
};

export type UploadRoutingGuideInput = {
  readonly recordId: Scalars['String'];
  readonly laneId: Scalars['ID'];
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly routingGuideTypeTerm: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly duration?: InputMaybe<Scalars['Int']>;
  readonly sequenceItems: ReadonlyArray<InputMaybe<UploadRoutingGuideSequenceItemInput>>;
};

export type UploadRoutingGuidePayload = {
  readonly __typename?: 'UploadRoutingGuidePayload';
  /** @deprecated The upload process is now asynchronous */
  readonly routingGuides?: Maybe<ReadonlyArray<Maybe<RoutingGuide>>>;
  readonly queuedRoutingGuidesCount: Scalars['Int'];
  readonly errors?: Maybe<ReadonlyArray<Maybe<UploadRoutingGuideError>>>;
};

export type UploadRoutingGuideSequenceItemInput = {
  readonly carrierId: Scalars['ID'];
  readonly scac: Scalars['String'];
  readonly tenderOptions?: InputMaybe<TenderOptionsInput>;
};

export type UploadRoutingGuidesInput = {
  readonly routingGuideInputs: ReadonlyArray<SaveRoutingGuideInput>;
};

export type UploadRoutingGuidesV2Input = {
  readonly documentId: Scalars['String'];
  readonly routingGuideInputs: ReadonlyArray<UploadRoutingGuideInput>;
  readonly userId: Scalars['ID'];
};

export type UploadRoutingGuidesV2Payload = {
  readonly __typename?: 'UploadRoutingGuidesV2Payload';
  readonly queuedRoutingGuidesCount: Scalars['Int'];
  readonly documentId: Scalars['String'];
  readonly batchId?: Maybe<Scalars['ID']>;
  readonly errors: ReadonlyArray<UploadRoutingGuideErrorV2>;
};

export type UploadsLaneGeographyPointInput = {
  readonly lat?: InputMaybe<Scalars['Decimal']>;
  readonly long?: InputMaybe<Scalars['Decimal']>;
};

export type UpsertAutoEtaInput = {
  readonly routeID: Scalars['ID'];
  readonly nextStopID: Scalars['ID'];
  readonly source: UpsertAutoEtaSource;
  readonly autoETADatetime?: InputMaybe<Scalars['DateTime']>;
};

/** Source for the new auto ETA value */
export enum UpsertAutoEtaSource {
  IngressApi = 'IngressAPI',
  /** @deprecated Inconsistent with trackingAutoEtaSource "Ingress API" option ID */
  Ingress = 'Ingress'
}

export type UpsertClientExceptionInput = {
  readonly exceptions: ReadonlyArray<UpsertClientExceptionRecordInput>;
};

export type UpsertClientExceptionRecordInput = {
  readonly source_id: Scalars['String'];
  readonly source_code?: InputMaybe<Scalars['String']>;
  readonly source_type: ClientExceptionRecordSourceType;
  readonly secondary_source_id?: InputMaybe<Scalars['String']>;
  readonly secondary_source_code?: InputMaybe<Scalars['String']>;
  readonly secondary_source_type?: InputMaybe<ClientExceptionRecordSourceType>;
  readonly exception_type: ClientExceptionRecordType;
  readonly reason_codes: ReadonlyArray<Scalars['String']>;
  readonly metadata: ReadonlyArray<UpsertClientExceptionRecordMetadataInput>;
  readonly assigned_to_employee_id?: InputMaybe<Scalars['String']>;
  readonly assigned_to_group?: InputMaybe<Scalars['String']>;
};

export type UpsertClientExceptionRecordMetadataInput = {
  readonly entityIdentifier: Scalars['String'];
  readonly entityType: ClientExceptionRecordMetadataType;
};

/** Input type for creating or updating a DriverAssignment of resource type `fleet` */
export type UpsertFleetResourceInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly assetPowerID?: InputMaybe<Scalars['ID']>;
  readonly checkCallDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly dispatched: Scalars['Boolean'];
  /** temporary field until we can resolve user info from the gateway context */
  readonly dispatchedBy?: InputMaybe<TrackingUserMetaInput>;
  /** supports up to 2 drivers */
  readonly drivers?: InputMaybe<ReadonlyArray<FleetDriverInput>>;
  readonly eldVehicleID?: InputMaybe<Scalars['String']>;
  readonly emptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly emptyLocation?: InputMaybe<LocationInput>;
  readonly emptyTimezone?: InputMaybe<Scalars['String']>;
  /** DEPRECATED - replaced by type inside inboundTrailers array */
  readonly equipmentID?: InputMaybe<Scalars['ID']>;
  readonly etaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly etaTimezone?: InputMaybe<Scalars['String']>;
  readonly externalInfo?: InputMaybe<DriverAssignmentExternalInfoInput>;
  readonly facilityID?: InputMaybe<Scalars['ID']>;
  readonly inboundTrailers?: InputMaybe<ReadonlyArray<RouteResourcesTrailerInput>>;
  readonly isAssetPowerIDAutofilled?: InputMaybe<Scalars['Boolean']>;
  readonly loadID?: InputMaybe<Scalars['ID']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  /** DEPRECATED - replaced by ownerID inside inboundTrailers array, ID of the Carrier who owns the truck, if any */
  readonly ownerID?: InputMaybe<Scalars['String']>;
  readonly reloadIntentID?: InputMaybe<Scalars['String']>;
  readonly routeID: Scalars['ID'];
  readonly trackingEnabled: Scalars['Boolean'];
  /** temporary field until we can resolve user info from the gateway context */
  readonly trackingEnabledBy?: InputMaybe<TrackingUserMetaInput>;
  readonly trackingSystemID?: InputMaybe<Scalars['ID']>;
  readonly tractorIdentifier?: InputMaybe<Scalars['String']>;
  /** DEPRECATED - replaced by heightInInches inside inboundTrailers array */
  readonly trailerHeight?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED - replaced by identifier inside inboundTrailers array */
  readonly trailerIdentifier?: InputMaybe<Scalars['String']>;
  /** DEPRECATED - replaced by lengthInFeet inside inboundTrailers array */
  readonly trailerLength?: InputMaybe<Scalars['Float']>;
  readonly trailerLocation?: InputMaybe<LocationInput>;
  /** DEPRECATED - replaced by widthInInches inside inboundTrailers array */
  readonly trailerWidth?: InputMaybe<Scalars['Float']>;
  readonly userID?: InputMaybe<Scalars['ID']>;
};

/** Input type for creating or updating a RouteResource */
export type UpsertRouteResourcesInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly assetPowerID?: InputMaybe<Scalars['ID']>;
  readonly checkCallDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly dispatched: Scalars['Boolean'];
  /** temporary field until we can resolve user info from the gateway context */
  readonly dispatchedBy?: InputMaybe<RouteResourcesEmployeeMetaInput>;
  /** supports up to 2 drivers */
  readonly drivers?: InputMaybe<ReadonlyArray<RouteResourcesDriverInput>>;
  readonly eldVehicleID?: InputMaybe<Scalars['String']>;
  readonly userID?: InputMaybe<Scalars['ID']>;
  readonly emptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly emptyLocation?: InputMaybe<LocationInput>;
  readonly emptyTimezone?: InputMaybe<Scalars['String']>;
  readonly dispatchedEmptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly dispatchedEmptyLocation?: InputMaybe<LocationInput>;
  readonly dispatchedEmptyTimezone?: InputMaybe<Scalars['String']>;
  readonly etaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly etaTimezone?: InputMaybe<Scalars['String']>;
  readonly externalInformation?: InputMaybe<RouteResourcesExternalInformationInput>;
  readonly inboundTrailers?: InputMaybe<ReadonlyArray<RouteResourcesTrailerV2Input>>;
  readonly loadedTrailers?: InputMaybe<ReadonlyArray<RouteResourcesTrailerV2Input>>;
  readonly outboundTrailers?: InputMaybe<ReadonlyArray<RouteResourcesTrailerV2Input>>;
  readonly isAssetPowerIDAutofilled?: InputMaybe<Scalars['Boolean']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly reloadIntentID?: InputMaybe<Scalars['String']>;
  readonly resourceType: RouteResourceTypeEnum;
  readonly routeID: Scalars['ID'];
  readonly trackingEnabled: Scalars['Boolean'];
  /** temporary field until we can resolve user info from the gateway context */
  readonly trackingEnabledBy?: InputMaybe<RouteResourcesEmployeeMetaInput>;
  readonly trackingSystemID?: InputMaybe<Scalars['ID']>;
  readonly tractorIdentifier?: InputMaybe<Scalars['String']>;
  readonly trailerLocation?: InputMaybe<LocationInput>;
  readonly trailerLocationFacilityID?: InputMaybe<Scalars['ID']>;
};

export type UpsertTenderOptionsInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly tenderModeTerm?: InputMaybe<Scalars['String']>;
  readonly tenderContacts?: InputMaybe<ReadonlyArray<InputMaybe<TenderContactInput>>>;
  readonly businessTimeZoneTerm?: InputMaybe<Scalars['String']>;
  readonly businessDays?: InputMaybe<ReadonlyArray<InputMaybe<TenderBusinessDaysInput>>>;
  readonly tenderExpirationTimeSpan?: InputMaybe<TenderTimeSpanInput>;
  readonly tenderLeadTimeSpan?: InputMaybe<TenderTimeSpanInput>;
  readonly parentObjectType: Scalars['String'];
  readonly parentObjectId: Scalars['ID'];
  readonly autoTender?: InputMaybe<Scalars['Boolean']>;
  readonly updatedByUserId: Scalars['ID'];
};

export type User = {
  readonly __typename?: 'User';
  readonly id: Scalars['ID'];
  readonly email: Scalars['String'];
  readonly employee?: Maybe<Employee>;
};

/** The connection type for User. */
export type UserConnection = {
  readonly __typename?: 'UserConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<UserEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** The connection type for User. */
export type UserConnectionV2 = {
  readonly __typename?: 'UserConnectionV2';
  /** A list of edges. */
  readonly edges: ReadonlyArray<UserEdgeV2>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type UserDetailInput = {
  readonly id: Scalars['Uuid'];
};

export type UserDetails = {
  readonly __typename?: 'UserDetails';
  readonly fullName?: Maybe<Scalars['String']>;
  readonly userId?: Maybe<Scalars['ID']>;
};

export type UserDeviceInfo = {
  readonly __typename?: 'UserDeviceInfo';
  readonly driverId: Scalars['String'];
  readonly deviceType: Scalars['String'];
  readonly deviceToken: Scalars['String'];
  readonly timestamp: Scalars['String'];
};

export type UserDevicePayload = {
  readonly __typename?: 'UserDevicePayload';
  readonly userDevices: ReadonlyArray<UserDeviceInfo>;
  readonly errors: ReadonlyArray<UserError>;
};

/** An edge in a connection. */
export type UserEdge = {
  readonly __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: User;
};

/** An edge in a connection. */
export type UserEdgeV2 = {
  readonly __typename?: 'UserEdgeV2';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: UserV2;
};

/** A user-readable error */
export type UserError = {
  readonly __typename?: 'UserError';
  /** Additional information, e.g. "code": "SCOPE_FAIL" */
  readonly extensions?: Maybe<Scalars['Json']>;
  /** A description of the error */
  readonly message: Scalars['String'];
  /** Which input value this error came from */
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type UserFilter = {
  readonly email?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['String']>;
};

export type UserFilterV2 = {
  readonly email?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['String']>;
};

export type UserIdObj = {
  readonly userId: Scalars['String'];
};

export type UserInputObj = {
  readonly userId: Scalars['String'];
  readonly userMessage: Scalars['String'];
};

export type UserPermission = {
  readonly __typename?: 'UserPermission';
  readonly resource: Scalars['String'];
  readonly scopes: ReadonlyArray<Scalars['String']>;
};

export type UserV2 = {
  readonly __typename?: 'UserV2';
  readonly id: Scalars['ID'];
  readonly email: Scalars['String'];
  readonly employee?: Maybe<EmployeeV2>;
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly isEmployeeVisible?: Maybe<Scalars['Boolean']>;
  readonly isAdminVisible?: Maybe<Scalars['Boolean']>;
  readonly isMachine?: Maybe<Scalars['Boolean']>;
  readonly externalAuthIdentifier?: Maybe<Scalars['String']>;
  readonly memberships?: Maybe<ReadonlyArray<Maybe<Membership>>>;
};

export enum UtilizationStatusEnum {
  Assigned = 'ASSIGNED',
  Available = 'AVAILABLE',
  Cancelled = 'CANCELLED',
  Deleted = 'DELETED',
  Expired = 'EXPIRED',
  Utilized = 'UTILIZED'
}

/** Input for verifying if a Carrier / Vendor can be added onto a Route */
export type ValidateBookingInput = {
  /** The ID of the Route */
  readonly routeId: Scalars['ID'];
  /** The ID of the Carrier / Vendor */
  readonly vendorId: Scalars['ID'];
  /** The type of Vendor to validate against the Route (Carrier, TONU, etc.) */
  readonly routeVendorType?: InputMaybe<RouteVendorTypeEnum>;
  /**
   * Rebooking Validations differ from those of initial assignment.
   * Set this to *true* if the Carrier / Vendor on this Route is currently Bounced
   */
  readonly isRebook?: InputMaybe<Scalars['Boolean']>;
};

export type ValidateBookingPayload = {
  readonly __typename?: 'ValidateBookingPayload';
  readonly valid: Scalars['Boolean'];
  readonly validationErrors: ReadonlyArray<Scalars['String']>;
};

/** mastery-crm: input for validateCarrierCRMOpportunityNameAvailable query */
export type ValidateCarrierCrmOpportunityNameRequest = {
  readonly name: Scalars['String'];
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly vendorId: Scalars['ID'];
};

export type ValidateCredentialsResponse = {
  readonly __typename?: 'ValidateCredentialsResponse';
  readonly error?: Maybe<Scalars['Boolean']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly errorCode?: Maybe<Scalars['Float']>;
  readonly success?: Maybe<Scalars['String']>;
  readonly statusCode?: Maybe<Scalars['Float']>;
};

/** mastery-crm: input for validateCustomerCRMOpportunityNameAvailable query */
export type ValidateCustomerCrmOpportunityNameRequest = {
  readonly customerId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
};

export type ValidateMassBookingInput = {
  /** The IDs of the Routes */
  readonly routeIds: ReadonlyArray<Scalars['String']>;
  /** The ID of the Carrier / Vendor */
  readonly vendorId: Scalars['ID'];
  /** The type of Vendor to validate against the Route (Carrier, TONU, etc.) */
  readonly routeVendorType?: InputMaybe<RouteVendorTypeEnum>;
};

export type ValidateMassBookingPayload = {
  readonly __typename?: 'ValidateMassBookingPayload';
  readonly routeId: Scalars['String'];
  readonly valid: Scalars['Boolean'];
  readonly validationErrors: ReadonlyArray<Maybe<Scalars['String']>>;
};

export type ValidateMembershipInput = {
  readonly userID: Scalars['ID'];
  readonly organizationID: Scalars['ID'];
  readonly populate?: InputMaybe<Scalars['Boolean']>;
};

export type ValidateMembershipOutput = {
  readonly __typename?: 'ValidateMembershipOutput';
  readonly valid?: Maybe<Scalars['Boolean']>;
  readonly membership?: Maybe<Membership>;
};

export type ValidateOrderProfileInput = {
  readonly customerId: Scalars['ID'];
  readonly profileName: Scalars['String'];
  readonly conditions?: InputMaybe<LoadDefaultConditionsInput>;
};

export type ValidateOrderProfilePayload = {
  readonly __typename?: 'ValidateOrderProfilePayload';
  readonly valid: Scalars['Boolean'];
  readonly validationErrors: ReadonlyArray<Maybe<Scalars['String']>>;
};

export type ValidationAuditHistory = {
  readonly __typename?: 'ValidationAuditHistory';
  readonly hashId: Scalars['String'];
  readonly validationConfigurationUuid?: Maybe<Scalars['ID']>;
  readonly version: Scalars['Int'];
  readonly field?: Maybe<Scalars['String']>;
  readonly action: Action;
  readonly previousValidation?: Maybe<Scalars['String']>;
  readonly newValidation?: Maybe<Scalars['String']>;
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['String'];
};

export type ValidationConfiguration = {
  readonly __typename?: 'ValidationConfiguration';
  /** A hash identifier that is generated by Canvas. Created by concatenating the validator key values and hashing them */
  readonly hashId: Scalars['String'];
  /** Unique identifier generated by Canvas.  Used for CRUD and audit operations on the UI. */
  readonly uuid?: Maybe<Scalars['ID']>;
  readonly entityVersion?: Maybe<Scalars['Int']>;
  readonly expiredCacheBehavior: ExpiredCacheBehavior;
  /** If a customer defined validation configuration is not met, this is the expected behavior */
  readonly failedValidationBehavior?: Maybe<Scalars['String']>;
  /** GlobalDefault indicator, flags whether this validation configuration is considered a fallback option applicable for this entity and state */
  readonly globalDefault: Scalars['Boolean'];
  readonly outdatedFallbackBehavior: OutdatedFallbackBehavior;
  /** List of rules that apply for this validation configuration */
  readonly requirements: ReadonlyArray<Requirement>;
  /** Object that contains audit fields such as createdBy and createdAt */
  readonly validatorMetadata?: Maybe<ValidatorMetadata>;
  /** Scope to which this validator belongs to such as a tenant, carrier or specific customer. Corresponds to the scopedEntityUuid */
  readonly configScope: ConfigScope;
  /** Division associated with the entity, must match a division DDT */
  readonly division?: Maybe<Scalars['String']>;
  /** @deprecated use transportMode instead  */
  readonly mode?: Maybe<Mode>;
  /** Mode associated with the entity, must match a transportMode DDT */
  readonly transportMode?: Maybe<Scalars['String']>;
  /** Unique Identifier of the entity this validator belongs to. Expected to be null when configScope is tenant level */
  readonly scopedEntityUuid?: Maybe<Scalars['String']>;
  /** @deprecated use loadSize instead  */
  readonly size?: Maybe<Size>;
  /** Load Size associated with the entity, must match a loadSize DDT */
  readonly loadSize?: Maybe<Scalars['String']>;
  /** Trailer Type equipment associated with the entity, must match a trailerType DDT option ID in UUID format */
  readonly equipment?: Maybe<Scalars['String']>;
  /** Unit associated with the entity, must match a businessUnit DDT */
  readonly unit?: Maybe<Scalars['String']>;
  /** @deprecated use validationEntityTypeDDT instead */
  readonly validationEntityType: ValidationEntityType;
  /** Type of entity this validator applies to, such as 'order' or 'customer' */
  readonly validationEntityTypeDDT: Scalars['String'];
  /** @deprecated use validationStateDDT instead */
  readonly validationState: ValidationState;
  /** State/Action of the entity taking place when this validation is checked, such as invoice generation or entity creation */
  readonly validationStateDDT: Scalars['String'];
  /** Route type associated with the entity, must match a routeType DDT */
  readonly routeType?: Maybe<Scalars['String']>;
  /** Route Transport Mode associated with the entity, must match a routeTransportMode DDT */
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  /** Bill to Code associated with the entity */
  readonly billToCode?: Maybe<Scalars['String']>;
  /** Vendor Type associated with the entity and state */
  readonly vendorType?: Maybe<Scalars['String']>;
};

export type ValidationConfigurationInput = {
  /** A hash identifier that is generated by Canvas. Created by concatenating the validator key values and hashing them */
  readonly hashId?: InputMaybe<Scalars['String']>;
  readonly entityVersion?: InputMaybe<Scalars['Int']>;
  readonly expiredCacheBehavior: ExpiredCacheBehavior;
  /** If a customer defined validation configuration is not met, this is the expected behavior */
  readonly failedValidationBehavior?: InputMaybe<Scalars['String']>;
  /** GlobalDefault indicator, flags whether this validation configuration is considered a fallback option applicable for this entity and state */
  readonly globalDefault: Scalars['Boolean'];
  readonly outdatedFallbackBehavior: OutdatedFallbackBehavior;
  /** List of rules that apply for this validation configuration */
  readonly requirements: ReadonlyArray<RequirementInput>;
  readonly validatorMetadata?: InputMaybe<ValidatorMetadataInput>;
  /** Scope to which this validator belongs to such as a tenant, carrier or specific customer. Corresponds to the scopedEntityUuid */
  readonly configScope: ConfigScope;
  /** Division associated with the entity, must match a division DDT */
  readonly division?: InputMaybe<Scalars['String']>;
  readonly mode?: InputMaybe<Mode>;
  /** Mode associated with the entity, must match a transportMode DDT */
  readonly transportMode?: InputMaybe<Scalars['String']>;
  /** Unique Identifier of the entity this validator belongs to. Expected to be null when configScope is tenant level */
  readonly scopedEntityUuid?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Size>;
  /** Load Size associated with the entity, must match a loadSize DDT */
  readonly loadSize?: InputMaybe<Scalars['String']>;
  /** Trailer Type equipment associated with the entity, must match a trailerType DDT option ID in UUID format */
  readonly equipment?: InputMaybe<Scalars['String']>;
  /** Unit associated with the entity, must match a businessUnit DDT */
  readonly unit?: InputMaybe<Scalars['String']>;
  readonly validationEntityType?: InputMaybe<ValidationEntityType>;
  /** Type of entity this validator applies to, such as 'order' or 'customer' */
  readonly validationEntityTypeDDT: Scalars['String'];
  readonly validationState?: InputMaybe<ValidationState>;
  /** State change taking place when this validation is checked, such as invoice generation or entity creation */
  readonly validationStateDDT: Scalars['String'];
  /** Route type associated with the entity, must match a routeType DDT */
  readonly routeType?: InputMaybe<Scalars['String']>;
  /** Route Transport Mode associated with the entity, must match a routeTransportMode DDT */
  readonly routeTransportMode?: InputMaybe<Scalars['String']>;
  /** Bill to Code associated with the entity */
  readonly billToCode?: InputMaybe<Scalars['String']>;
  /** Vendor Type associated with the entity and state */
  readonly vendorType?: InputMaybe<Scalars['String']>;
};

export type ValidationConfigurationKeysInput = {
  readonly configScope?: InputMaybe<ConfigScope>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly mode?: InputMaybe<Mode>;
  readonly transportMode?: InputMaybe<Scalars['String']>;
  readonly scopedEntityUuid?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Size>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly equipment?: InputMaybe<Scalars['String']>;
  readonly unit?: InputMaybe<Scalars['String']>;
  readonly validationEntityType?: InputMaybe<ValidationEntityType>;
  readonly validationEntityTypeDDT?: InputMaybe<Scalars['String']>;
  readonly validationState?: InputMaybe<ValidationState>;
  readonly validationStateDDT?: InputMaybe<Scalars['String']>;
  readonly routeType?: InputMaybe<Scalars['String']>;
  readonly routeTransportMode?: InputMaybe<Scalars['String']>;
  readonly billToCode?: InputMaybe<Scalars['String']>;
  readonly vendorType?: InputMaybe<Scalars['String']>;
};

export enum ValidationEntityType {
  Customer = 'Customer',
  DriverTab = 'DriverTab',
  ExpectedEmptyInformation = 'ExpectedEmptyInformation',
  Order = 'Order',
  RouteVendor = 'RouteVendor',
  VendorAssignment = 'VendorAssignment'
}

export type Validations = {
  readonly __typename?: 'Validations';
  readonly locationRequired: Scalars['Boolean'];
  readonly dateRequired: Scalars['Boolean'];
  readonly timeRequired: Scalars['Boolean'];
  readonly equipmentRequired: Scalars['Boolean'];
  readonly lengthRequired: Scalars['Boolean'];
  readonly costLineItemsRequired: Scalars['Boolean'];
  readonly equipmentMatchRequired: Scalars['Boolean'];
};

export enum ValidationState {
  InvoiceGeneration = 'InvoiceGeneration',
  InvoiceHold = 'InvoiceHold',
  Save = 'Save',
  SaveAndDispatch = 'SaveAndDispatch',
  SaveCarrierDetails = 'SaveCarrierDetails',
  VoucherHold = 'VoucherHold'
}

export type ValidatorMetadata = {
  readonly __typename?: 'ValidatorMetadata';
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdBy: Scalars['String'];
  readonly lastUpdate: LastUpdate;
  readonly lastUpdatedAt: Scalars['ISO8601DateTime'];
  readonly lastUpdatedBy: Scalars['String'];
  readonly validatorVersion: Scalars['Int'];
};

export type ValidatorMetadataInput = {
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdBy: Scalars['String'];
  readonly lastUpdate: LastUpdate;
  readonly lastUpdatedAt: Scalars['ISO8601DateTime'];
  readonly lastUpdatedBy: Scalars['String'];
  readonly validatorVersion: Scalars['Int'];
};

export type ValuesItemObject = {
  readonly __typename?: 'ValuesItemObject';
  readonly name?: Maybe<Scalars['String']>;
};

export type VendorAccountingQueueFilter = {
  readonly customerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly orderCode?: InputMaybe<Scalars['String']>;
  readonly refCode?: InputMaybe<Scalars['String']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly project?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly remitToVendorId?: InputMaybe<Scalars['ID']>;
  readonly carrierRepId?: InputMaybe<Scalars['ID']>;
  readonly customerRepId?: InputMaybe<Scalars['ID']>;
  readonly deliveryDateRange?: InputMaybe<DateRange>;
  readonly accountingStatuses?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly excludeVendorInvoiceMissing?: InputMaybe<Scalars['Boolean']>;
  readonly routeLifeCycleStatuses?: InputMaybe<ReadonlyArray<RouteLifeCycleStatusEnum>>;
  readonly routeFinancialStatuses?: InputMaybe<ReadonlyArray<VendorFinancialStatusEnum>>;
  readonly exceptionReasonCodes?: InputMaybe<ReadonlyArray<ExceptionReason>>;
  readonly paymentTerm?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly voucherHoldDateRange?: InputMaybe<DateRange>;
  readonly voucherHoldEventTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly carrierFinalizedFuelStatuses?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type VendorAssignmentSaveInput = {
  /** Division associated with the entity, must match a division DDT */
  readonly division?: InputMaybe<Scalars['String']>;
  /** Load Size associated with the entity, must match a loadSize DDT */
  readonly loadSize?: InputMaybe<Scalars['String']>;
  /** Trailer Type equipment associated with the entity, must match a trailerType DDT option ID in UUID format */
  readonly equipment?: ReadonlyArray<Scalars['String']>;
  /** Route Transport Mode associated with the entity, must match a routeTransportMode DDT */
  readonly routeTransportMode?: InputMaybe<Scalars['String']>;
};

export type VendorAssociatedRequiredDocument = {
  readonly __typename?: 'vendorAssociatedRequiredDocument';
  readonly id: Scalars['ID'];
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
  readonly chargeType?: Maybe<Scalars['ID']>;
  readonly isMergePod?: Maybe<Scalars['Boolean']>;
  readonly isRecalculateRequirements?: Maybe<Scalars['Boolean']>;
  readonly isDeliveryDateAfter?: Maybe<Scalars['Boolean']>;
  readonly isFinancialStatus?: Maybe<Scalars['Boolean']>;
  readonly isLifeCycleStatus?: Maybe<Scalars['Boolean']>;
  readonly deliveryDateAfter?: Maybe<Scalars['Date']>;
  readonly financialStatus?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly lifeCycleStatus?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly vendorId: Scalars['ID'];
  readonly routeVendorType?: Maybe<RouteVendorTypeEnum>;
  readonly routeType?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

/** The input type for a new vendor associated required document. */
export type VendorAssociatedRequiredDocumentCreateInput = {
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
  readonly chargeType?: InputMaybe<Scalars['ID']>;
  readonly isMergePod?: InputMaybe<Scalars['Boolean']>;
  readonly recalcOptions?: InputMaybe<RequiredDocumentRecalcOptionsInputType>;
  readonly isRecalculateRequirements?: InputMaybe<Scalars['Boolean']>;
  readonly isDeliveryDateAfter?: InputMaybe<Scalars['Boolean']>;
  readonly isFinancialStatus?: InputMaybe<Scalars['Boolean']>;
  readonly isLifeCycleStatus?: InputMaybe<Scalars['Boolean']>;
  readonly deliveryDateAfter?: InputMaybe<Scalars['Date']>;
  readonly financialStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly lifeCycleStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly vendorId: Scalars['ID'];
  readonly routeVendorType?: InputMaybe<RouteVendorTypeEnum>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

/** The input type for updating vendor associated required document. */
export type VendorAssociatedRequiredDocumentUpdateInput = {
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
  readonly chargeType?: InputMaybe<Scalars['ID']>;
  readonly isMergePod?: InputMaybe<Scalars['Boolean']>;
  readonly recalcOptions?: InputMaybe<RequiredDocumentRecalcOptionsInputType>;
  readonly isRecalculateRequirements?: InputMaybe<Scalars['Boolean']>;
  readonly isDeliveryDateAfter?: InputMaybe<Scalars['Boolean']>;
  readonly isFinancialStatus?: InputMaybe<Scalars['Boolean']>;
  readonly isLifeCycleStatus?: InputMaybe<Scalars['Boolean']>;
  readonly deliveryDateAfter?: InputMaybe<Scalars['Date']>;
  readonly financialStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly lifeCycleStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly vendorId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly routeVendorType?: InputMaybe<RouteVendorTypeEnum>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

/** Represents a auto_voucher_disable value of a particular vendor */
export type VendorAutoVoucherType = {
  readonly __typename?: 'VendorAutoVoucherType';
  readonly vendorId: Scalars['ID'];
  readonly autoVoucherDisabled: Scalars['Boolean'];
};

export type VendorCreditMemo = {
  readonly __typename?: 'VendorCreditMemo';
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Decimal'];
  readonly creditMemoDate?: Maybe<Scalars['DateTime']>;
  readonly voucherHeader: VoucherHeader;
  readonly currency?: Maybe<Scalars['String']>;
  readonly voucherCurrencyAmount?: Maybe<Scalars['Decimal']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
};

export type VendorCreditMemoInput = {
  readonly id: Scalars['ID'];
  readonly creditMemoDate: Scalars['DateTime'];
};

export enum VendorFinancialStatusEnum {
  Complete = 'COMPLETE',
  Exception = 'EXCEPTION',
  Incomplete = 'INCOMPLETE',
  None = 'NONE',
  Settled = 'SETTLED',
  /** @deprecated Field no longer supported */
  Unmapped = 'UNMAPPED',
  Vouchered = 'VOUCHERED',
  Awaitingpaperwork = 'AWAITINGPAPERWORK',
  Awaitingrequirements = 'AWAITINGREQUIREMENTS',
  External = 'EXTERNAL'
}

export type VendorInvoice = {
  readonly __typename?: 'VendorInvoice';
  readonly amount?: Maybe<Scalars['Decimal']>;
  readonly approvalDate?: Maybe<Scalars['DateTime']>;
  readonly approver?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly dateToPay?: Maybe<Scalars['DateTime']>;
  readonly dateReceived?: Maybe<Scalars['DateTime']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly invoiceDate?: Maybe<Scalars['DateTime']>;
  readonly isQuickpay?: Maybe<Scalars['Boolean']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly status: VendorInvoiceStatus;
  readonly vendorId?: Maybe<Scalars['ID']>;
  readonly vendorInvoiceNumber?: Maybe<Scalars['String']>;
  readonly isVouchered?: Maybe<Scalars['Boolean']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly enteredInvoiceAmount?: Maybe<Scalars['Decimal']>;
  readonly remitToAddressLine1?: Maybe<Scalars['String']>;
  readonly remitToAddressLine2?: Maybe<Scalars['String']>;
  readonly remitToCity?: Maybe<Scalars['String']>;
  readonly remitToCountry?: Maybe<Scalars['String']>;
  readonly remitToName?: Maybe<Scalars['String']>;
  readonly remitToState?: Maybe<Scalars['String']>;
  readonly remitToZipCode?: Maybe<Scalars['String']>;
  readonly totalCost?: Maybe<Scalars['Decimal']>;
  readonly voucheredAmount?: Maybe<Scalars['Decimal']>;
  readonly unvoucheredAmount?: Maybe<Scalars['Decimal']>;
  readonly loadV2?: Maybe<LoadV2>;
  readonly routeLifeCycleStatus?: Maybe<RouteLifeCycleStatusEnum>;
  readonly vendorRouteFinancialStatus?: Maybe<VendorFinancialStatusEnum>;
  readonly advanceAmount?: Maybe<Scalars['Decimal']>;
  readonly variance?: Maybe<Scalars['Decimal']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly invoiceCount?: Maybe<Scalars['Long']>;
  readonly vendorInvoiceExceptions?: Maybe<ReadonlyArray<Maybe<VendorInvoiceException>>>;
  readonly vendorInvoiceExternalCostDetails?: Maybe<ReadonlyArray<Maybe<VendorInvoiceExternalCostDetail>>>;
};

/** A connection to a list of items. */
export type VendorInvoiceConnection = {
  readonly __typename?: 'VendorInvoiceConnection';
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<VendorInvoiceEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<VendorInvoice>>>;
};

/** An edge in a connection. */
export type VendorInvoiceEdge = {
  readonly __typename?: 'VendorInvoiceEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<VendorInvoice>;
};

export type VendorInvoiceException = {
  readonly __typename?: 'VendorInvoiceException';
  readonly id?: Maybe<Scalars['ID']>;
  readonly vendorInvoiceId?: Maybe<Scalars['ID']>;
  readonly exceptionReason?: Maybe<Scalars['String']>;
  readonly status?: Maybe<VendorInvoiceExceptionStatus>;
  readonly closedBy?: Maybe<Scalars['String']>;
  readonly closedAt?: Maybe<Scalars['DateTime']>;
  readonly isManuallyClosed?: Maybe<Scalars['Boolean']>;
};

export enum VendorInvoiceExceptionStatus {
  /** Vendor Invoice Exception is open. */
  Open = 'Open',
  /** Vendor Invoice Exception has been closed. */
  Closed = 'Closed'
}

export type VendorInvoiceExternalCostDetail = {
  readonly __typename?: 'VendorInvoiceExternalCostDetail';
  readonly id?: Maybe<Scalars['ID']>;
  readonly vendorInvoiceId?: Maybe<Scalars['ID']>;
  readonly chargeTypeCode?: Maybe<Scalars['String']>;
  readonly chargeTypeDescription?: Maybe<Scalars['String']>;
  readonly costPer?: Maybe<Scalars['Decimal']>;
  readonly units?: Maybe<Scalars['Decimal']>;
  readonly totalCost?: Maybe<Scalars['Decimal']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly mappedChargeTypeId?: Maybe<Scalars['ID']>;
  readonly mMChargeTypeCode?: Maybe<Scalars['String']>;
  readonly mMChargeTypeDescription?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
};

export type VendorInvoiceExternalCostDetailInput = {
  readonly totalCost: Scalars['Decimal'];
  readonly costPer: Scalars['Decimal'];
  readonly units: Scalars['Decimal'];
  readonly currency?: InputMaybe<Scalars['String']>;
  readonly chargeTypeCode: Scalars['String'];
  readonly chargeTypeDescription?: InputMaybe<Scalars['String']>;
  readonly externalId?: InputMaybe<Scalars['String']>;
};

export type VendorInvoiceFilterInput = {
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly orderId?: InputMaybe<Scalars['ID']>;
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly orderCode?: InputMaybe<Scalars['String']>;
  readonly refCode?: InputMaybe<Scalars['String']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly remitToVendorId?: InputMaybe<Scalars['ID']>;
  readonly carrierRepId?: InputMaybe<Scalars['ID']>;
  readonly deliveryDateRange?: InputMaybe<DateRange>;
  readonly routeFinancialStatuses?: InputMaybe<ReadonlyArray<VendorFinancialStatusEnum>>;
  readonly routeLifeCycleStatuses?: InputMaybe<ReadonlyArray<RouteLifeCycleStatusEnum>>;
  readonly vendorInvoiceStatuses?: InputMaybe<ReadonlyArray<VendorInvoiceStatus>>;
  readonly exceptionReasons?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hasMultipleInvoices?: InputMaybe<Scalars['Boolean']>;
  readonly customerRepId?: InputMaybe<Scalars['Uuid']>;
  readonly railsLoadIds?: InputMaybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly pageSize?: InputMaybe<Scalars['Long']>;
  readonly skipRecordCount?: InputMaybe<Scalars['Long']>;
  readonly project?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly creditMemoNumber?: InputMaybe<Scalars['String']>;
};

export type VendorInvoiceFilterInputV2 = {
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly vendorInvoiceId?: InputMaybe<Scalars['ID']>;
};

export type VendorInvoiceInput = {
  /** Deprecated - Use EnteredInvoiceAmount - This will be recalculated as ExchangeRate * EnteredInvoiceAmount. */
  readonly amount?: InputMaybe<Scalars['Decimal']>;
  readonly comments?: InputMaybe<Scalars['String']>;
  readonly currency?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly invoiceDate?: InputMaybe<Scalars['DateTime']>;
  readonly dateReceived?: InputMaybe<Scalars['DateTime']>;
  readonly isQuickpay?: InputMaybe<Scalars['Boolean']>;
  readonly loadId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly status: VendorInvoiceStatus;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly vendorInvoiceNumber: Scalars['String'];
  readonly externalId?: InputMaybe<Scalars['String']>;
  readonly approver?: InputMaybe<Scalars['String']>;
  readonly approvalDate?: InputMaybe<Scalars['Date']>;
  /** Deprecated - Do not use, this will be ignored and it will get set automatically from Load Created Date. */
  readonly exchangeRateDate?: InputMaybe<Scalars['DateTime']>;
  /** Deprecated - Do not use, this will be ignored and it will get set automatically from Currency. */
  readonly exchangeRate?: InputMaybe<Scalars['Decimal']>;
  readonly enteredInvoiceAmount?: InputMaybe<Scalars['Decimal']>;
  readonly remitToAddressLine1?: InputMaybe<Scalars['String']>;
  readonly remitToAddressLine2?: InputMaybe<Scalars['String']>;
  readonly remitToCity?: InputMaybe<Scalars['String']>;
  readonly remitToCountry?: InputMaybe<Scalars['String']>;
  readonly remitToName?: InputMaybe<Scalars['String']>;
  readonly remitToState?: InputMaybe<Scalars['String']>;
  readonly remitToZipCode?: InputMaybe<Scalars['String']>;
  readonly manuallyCloseExceptions?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

/** Represents the vendor invoice object when processing a Voucher. */
export type VendorInvoiceProcessingInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
};

export enum VendorInvoiceStatus {
  /** Vendor Invoice has been approved. */
  Approved = 'Approved',
  /** Vendor Invoice has been denied. */
  Denied = 'Denied',
  /** Vendor Invoice experienced an exception during processing. */
  Exception = 'Exception',
  /** Vendor Invoice is pending. */
  Pending = 'Pending'
}

export type VendorInvoiceUpsertInput = {
  /** Deprecated - Use EnteredInvoiceAmount - This will be recalculated as ExchangeRate * EnteredInvoiceAmount. */
  readonly amount: Scalars['Decimal'];
  readonly comments?: InputMaybe<Scalars['String']>;
  readonly currency: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly invoiceDate: Scalars['Date'];
  readonly dateReceived: Scalars['Date'];
  readonly isQuickpay?: InputMaybe<Scalars['Boolean']>;
  readonly loadId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly status: VendorInvoiceStatus;
  readonly vendorId: Scalars['ID'];
  readonly vendorInvoiceNumber: Scalars['String'];
  readonly externalId: Scalars['String'];
  readonly approver?: InputMaybe<Scalars['String']>;
  readonly approvalDate?: InputMaybe<Scalars['Date']>;
  /** Deprecated - Do not use, this will be ignored and it will get set automatically from Load Created Date. */
  readonly exchangeRateDate?: InputMaybe<Scalars['DateTime']>;
  /** Deprecated - Do not use, this will be ignored and it will get set automatically from Currency. */
  readonly exchangeRate?: InputMaybe<Scalars['Decimal']>;
  readonly enteredInvoiceAmount?: InputMaybe<Scalars['Decimal']>;
  readonly remitToAddressLine1?: InputMaybe<Scalars['String']>;
  readonly remitToAddressLine2?: InputMaybe<Scalars['String']>;
  readonly remitToCity?: InputMaybe<Scalars['String']>;
  readonly remitToCountry?: InputMaybe<Scalars['String']>;
  readonly remitToName?: InputMaybe<Scalars['String']>;
  readonly remitToState?: InputMaybe<Scalars['String']>;
  readonly remitToZipCode?: InputMaybe<Scalars['String']>;
  readonly manuallyCloseExceptions?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly vendorInvoiceExternalCostDetails?: InputMaybe<ReadonlyArray<VendorInvoiceExternalCostDetailInput>>;
};

export type VendorInvoiceVoucher = {
  readonly __typename?: 'VendorInvoiceVoucher';
  readonly vendorInvoiceId?: Maybe<Scalars['ID']>;
  readonly voucherHeaderId?: Maybe<Scalars['ID']>;
  readonly id?: Maybe<Scalars['ID']>;
};

export type VendorInvoiceVoucherInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly vendorInvoiceId?: InputMaybe<Scalars['ID']>;
  readonly voucherHeaderId?: InputMaybe<Scalars['ID']>;
};

export type VendorPayment = {
  readonly __typename?: 'VendorPayment';
  readonly checkNumber?: Maybe<Scalars['String']>;
  readonly issueDate?: Maybe<Scalars['Date']>;
  readonly issueDateV2?: Maybe<Scalars['DateTime']>;
  readonly amount?: Maybe<Scalars['Decimal']>;
  readonly paymentType?: Maybe<Scalars['String']>;
  readonly paymentId?: Maybe<Scalars['ID']>;
  readonly paymentCurrencyAmount?: Maybe<Scalars['Decimal']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly exchangeRateDate?: Maybe<Scalars['Date']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly paymentCurrencyUnappliedAmount?: Maybe<Scalars['Decimal']>;
  readonly unappliedAmount?: Maybe<Scalars['Decimal']>;
  readonly currencyDiscountAmount?: Maybe<Scalars['Decimal']>;
  readonly paymentNumber?: Maybe<Scalars['String']>;
};

export type VendorPaymentApplication = {
  readonly __typename?: 'VendorPaymentApplication';
  readonly postDate?: Maybe<Scalars['DateTime']>;
  readonly applicationType?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly refNumber?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly amount?: Maybe<Scalars['Decimal']>;
  readonly amountUSD?: Maybe<Scalars['Decimal']>;
  readonly routeCode?: Maybe<Scalars['Decimal']>;
  readonly loadCode?: Maybe<Scalars['Decimal']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly voucherHeaderId?: Maybe<Scalars['ID']>;
  readonly discountAmount?: Maybe<Scalars['Decimal']>;
};

export type VendorPaymentDetails = {
  readonly __typename?: 'VendorPaymentDetails';
  readonly id?: Maybe<Scalars['ID']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly paymentMethod?: Maybe<Scalars['String']>;
  readonly paymentAmount?: Maybe<Scalars['Decimal']>;
  readonly paymentAmountUSD?: Maybe<Scalars['Decimal']>;
  readonly discountAmount?: Maybe<Scalars['Decimal']>;
  readonly discountAmountUSD?: Maybe<Scalars['Decimal']>;
  readonly checkNumber?: Maybe<Scalars['String']>;
  readonly unappliedAmount?: Maybe<Scalars['Decimal']>;
  readonly unappliedAmountUSD?: Maybe<Scalars['Decimal']>;
  readonly vendorPaymentDetailsModels?: Maybe<ReadonlyArray<Maybe<VendorPaymentApplication>>>;
};

export type VendorPaymentInput = {
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly vendorRouteId?: InputMaybe<Scalars['ID']>;
  readonly voucherId?: InputMaybe<Scalars['ID']>;
};

export type VendorRequiredDocument = {
  readonly __typename?: 'VendorRequiredDocument';
  readonly id: Scalars['ID'];
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
  readonly chargeType?: Maybe<Scalars['ID']>;
  readonly isMergePod?: Maybe<Scalars['Boolean']>;
  readonly isRecalculateRequirements?: Maybe<Scalars['Boolean']>;
  readonly isDeliveryDateAfter?: Maybe<Scalars['Boolean']>;
  readonly isFinancialStatus?: Maybe<Scalars['Boolean']>;
  readonly isLifeCycleStatus?: Maybe<Scalars['Boolean']>;
  readonly deliveryDateAfter?: Maybe<Scalars['Date']>;
  readonly financialStatus?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly lifeCycleStatus?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly routeVendorType?: Maybe<RouteVendorTypeEnum>;
  readonly routeType?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

/** The input type for a new vendor required document. */
export type VendorRequiredDocumentCreateInput = {
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
  readonly chargeType?: InputMaybe<Scalars['ID']>;
  readonly isMergePod?: InputMaybe<Scalars['Boolean']>;
  readonly recalcOptions?: InputMaybe<RequiredDocumentRecalcOptionsInputType>;
  readonly isRecalculateRequirements?: InputMaybe<Scalars['Boolean']>;
  readonly isDeliveryDateAfter?: InputMaybe<Scalars['Boolean']>;
  readonly isFinancialStatus?: InputMaybe<Scalars['Boolean']>;
  readonly isLifeCycleStatus?: InputMaybe<Scalars['Boolean']>;
  readonly deliveryDateAfter?: InputMaybe<Scalars['Date']>;
  readonly financialStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly lifeCycleStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly routeVendorType?: InputMaybe<RouteVendorTypeEnum>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

/** The input type for updating vendor required document. */
export type VendorRequiredDocumentUpdateInput = {
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
  readonly chargeType?: InputMaybe<Scalars['ID']>;
  readonly isMergePod?: InputMaybe<Scalars['Boolean']>;
  readonly recalcOptions?: InputMaybe<RequiredDocumentRecalcOptionsInputType>;
  readonly isRecalculateRequirements?: InputMaybe<Scalars['Boolean']>;
  readonly isDeliveryDateAfter?: InputMaybe<Scalars['Boolean']>;
  readonly isFinancialStatus?: InputMaybe<Scalars['Boolean']>;
  readonly isLifeCycleStatus?: InputMaybe<Scalars['Boolean']>;
  readonly deliveryDateAfter?: InputMaybe<Scalars['Date']>;
  readonly financialStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly lifeCycleStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly id: Scalars['ID'];
  readonly routeVendorType?: InputMaybe<RouteVendorTypeEnum>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

/** The input type for creating and updating vendor required document. */
export type VendorRequiredDocumentUpsertInput = {
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
  readonly chargeType?: InputMaybe<Scalars['ID']>;
  readonly isMergePod?: InputMaybe<Scalars['Boolean']>;
  readonly recalcOptions?: InputMaybe<RequiredDocumentRecalcOptionsInputType>;
  readonly isRecalculateRequirements?: InputMaybe<Scalars['Boolean']>;
  readonly isDeliveryDateAfter?: InputMaybe<Scalars['Boolean']>;
  readonly isFinancialStatus?: InputMaybe<Scalars['Boolean']>;
  readonly isLifeCycleStatus?: InputMaybe<Scalars['Boolean']>;
  readonly deliveryDateAfter?: InputMaybe<Scalars['Date']>;
  readonly financialStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly lifeCycleStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly routeVendorType?: InputMaybe<RouteVendorTypeEnum>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type VendorRouteRequiredDocument = {
  readonly __typename?: 'VendorRouteRequiredDocument';
  readonly id?: Maybe<Scalars['ID']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly vendorId?: Maybe<Scalars['ID']>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly loadDocumentId?: Maybe<Scalars['ID']>;
  readonly isWaived?: Maybe<Scalars['Boolean']>;
  readonly loadDocumentType?: Maybe<Scalars['String']>;
  readonly stopNumber?: Maybe<Scalars['Int']>;
  readonly stopFacilityName?: Maybe<Scalars['String']>;
  readonly stopAddress?: Maybe<Scalars['String']>;
  readonly isMergedPodDocument?: Maybe<Scalars['Boolean']>;
  readonly mergedPodStopNumbers?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly loadDocument?: Maybe<LoadDocument>;
};

export type VendorRouteRequiredDocumentAssociateInput = {
  readonly id: Scalars['ID'];
  readonly loadDocumentId: Scalars['ID'];
};

export type VendorRouteRequiredDocumentDisassociateInputType = {
  readonly id: Scalars['ID'];
};

export type VendorRouteRequiredDocumentUpdateInput = {
  readonly id: Scalars['ID'];
  readonly isWaived: Scalars['Boolean'];
  readonly loadDocumentId?: InputMaybe<Scalars['ID']>;
};

export type VendorRouteRequiredDocumentWaiveInput = {
  readonly id: Scalars['ID'];
  readonly isWaived: Scalars['Boolean'];
};

/** Represents the Use_Global value of particular Vendor */
export type VendorUseGlobalType = {
  readonly __typename?: 'VendorUseGlobalType';
  readonly vendorId: Scalars['ID'];
  readonly useGlobal: Scalars['Boolean'];
};

export type VendorValidation = {
  readonly __typename?: 'VendorValidation';
  readonly id: Scalars['ID'];
  readonly vendorValidationErrors?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly offer: Offer;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type VendorVoucherApplication = {
  readonly __typename?: 'VendorVoucherApplication';
  readonly voucherHeaderId?: Maybe<Scalars['ID']>;
  readonly voucherNumber?: Maybe<Scalars['String']>;
  readonly voucherDate?: Maybe<Scalars['DateTime']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly remitToName?: Maybe<Scalars['String']>;
  readonly originalAmountDue?: Maybe<Scalars['Decimal']>;
  readonly currentAmountDue?: Maybe<Scalars['Decimal']>;
  readonly originalAmountDueUSD?: Maybe<Scalars['Decimal']>;
  readonly currentAmountDueUSD?: Maybe<Scalars['Decimal']>;
  readonly dueDate?: Maybe<Scalars['DateTime']>;
  readonly vendorPayments?: Maybe<ReadonlyArray<Maybe<VendorVoucherPayment>>>;
};

export type VendorVoucherFilter = {
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly status?: InputMaybe<StatusEnum>;
  readonly statuses?: InputMaybe<ReadonlyArray<InputMaybe<StatusEnum>>>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly voucherNumber?: InputMaybe<Scalars['String']>;
  readonly dueDateRange?: InputMaybe<DateRange>;
  readonly amountRange?: InputMaybe<DecimalRange>;
  readonly balanceRange?: InputMaybe<DecimalRange>;
  readonly carrierRepId?: InputMaybe<Scalars['String']>;
  readonly remitToVendorId?: InputMaybe<Scalars['String']>;
  readonly routeLifeCycleStatuses?: InputMaybe<ReadonlyArray<InputMaybe<RouteLifeCycleStatusEnum>>>;
  readonly vendorFinancialStatuses?: InputMaybe<ReadonlyArray<InputMaybe<VendorFinancialStatusEnum>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly voucherDateRange?: InputMaybe<DateRange>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly project?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly vendorPaymentNumber?: InputMaybe<Scalars['String']>;
  readonly creditMemoNumber?: InputMaybe<Scalars['String']>;
  readonly orderCode?: InputMaybe<Scalars['String']>;
  readonly customerRepId?: InputMaybe<Scalars['Uuid']>;
  readonly railsLoadIds?: InputMaybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly pageSize?: InputMaybe<Scalars['Long']>;
  readonly skipRecordCount?: InputMaybe<Scalars['Long']>;
  readonly refCode?: InputMaybe<Scalars['String']>;
  readonly deliveryDateRange?: InputMaybe<DateRange>;
};

/** Represents Vendor Payment Information */
export type VendorVoucherPayment = {
  readonly __typename?: 'VendorVoucherPayment';
  readonly postDate?: Maybe<Scalars['DateTime']>;
  readonly applicationType?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly paymentType?: Maybe<Scalars['String']>;
  readonly refNumber?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly amount?: Maybe<Scalars['Decimal']>;
  readonly amountUSD?: Maybe<Scalars['Decimal']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly paymentId?: Maybe<Scalars['ID']>;
};

export type Volume = {
  readonly __typename?: 'Volume';
  readonly unit: VolumeUnitTypeEnum;
  readonly value: Scalars['Float'];
};

export enum VolumeUnitTypeEnum {
  Ft3 = 'ft3',
  M3 = 'm3'
}

export type VolumeV2 = {
  readonly __typename?: 'VolumeV2';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

/** The Voucher Batch.  The batch will contain 1-n Voucher Headers. */
export type VoucherBatch = {
  readonly __typename?: 'VoucherBatch';
  readonly id?: Maybe<Scalars['ID']>;
  readonly description: Scalars['String'];
  readonly voucherHeaders: ReadonlyArray<VoucherHeader>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly statusId: StatusEnum;
};

/** The Voucher batch processing input type.  Used to process Voucher batches */
export type VoucherBatchProcessingInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly voucherHeadersForProcessing: ReadonlyArray<VoucherHeaderProcessingInput>;
  readonly voucherOverrides?: InputMaybe<VoucherOverridesInput>;
  readonly voucherDateOption?: InputMaybe<DefaultVoucherDateOptionEnum>;
  /** Should adjustments be created? */
  readonly createAdjustments?: InputMaybe<Scalars['Boolean']>;
};

export type VoucherDateDefault = {
  readonly __typename?: 'VoucherDateDefault';
  readonly id: Scalars['ID'];
  readonly defaultVoucherDateOption?: Maybe<DefaultVoucherDateOptionEnum>;
};

/** The input type for Default Date for Voucher */
export type VoucherDateDefaultInput = {
  readonly id: Scalars['ID'];
};

export type VoucheredDrivers = {
  readonly __typename?: 'VoucheredDrivers';
  /** Driver Voucher Header Id */
  readonly id: Scalars['ID'];
  /** Driver Id */
  readonly driverId: Scalars['ID'];
  /** @deprecated This field is deprecated. Use driverName instead. */
  readonly driverFirstName?: Maybe<Scalars['String']>;
  /** @deprecated This field is deprecated. Use driverName instead. */
  readonly driverLastName?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly voucherWeekEndDate?: Maybe<Scalars['DateTime']>;
  readonly voucherGrossPayAmount?: Maybe<Scalars['Float']>;
  readonly voucherNetPayAmount?: Maybe<Scalars['Float']>;
  readonly voucherCurrency?: Maybe<Scalars['String']>;
  readonly voucherRoutes?: Maybe<Scalars['String']>;
  readonly voucherLoads?: Maybe<Scalars['String']>;
  readonly driverFleetNames?: Maybe<Scalars['String']>;
  /** @deprecated This field is deprecated. Use voucherNumber instead. */
  readonly voucherCode?: Maybe<Scalars['String']>;
  readonly voucherNumber?: Maybe<Scalars['String']>;
  readonly voucherDate?: Maybe<Scalars['DateTime']>;
  readonly voucherCreatedAt?: Maybe<Scalars['DateTime']>;
  readonly voucherCreatedByEmail?: Maybe<Scalars['String']>;
  readonly voucherStatus?: Maybe<Scalars['String']>;
  readonly driverCompanyName?: Maybe<Scalars['String']>;
  readonly driverCompanyRemittanceEmail?: Maybe<Scalars['String']>;
  readonly driverCompanyRemittanceAddress?: Maybe<Scalars['String']>;
  readonly driverCompanyPmtTerms?: Maybe<Scalars['String']>;
  readonly driverCompanyDueDate?: Maybe<Scalars['DateTime']>;
  readonly voucherMin?: Maybe<Scalars['Float']>;
  readonly terminationDate?: Maybe<Scalars['DateTime']>;
  readonly voucherLoadRoute?: Maybe<Scalars['String']>;
  readonly voucherDateTimezone?: Maybe<Scalars['String']>;
  readonly dueDateTimezone?: Maybe<Scalars['String']>;
  readonly routeFleetCarrierNames?: Maybe<Scalars['String']>;
};

export type VoucheredDriversConnection = {
  readonly __typename?: 'VoucheredDriversConnection';
  readonly edges: ReadonlyArray<VoucheredDriversEdge>;
  readonly pageInfo: PageInfo;
};

export type VoucheredDriversEdge = {
  readonly __typename?: 'VoucheredDriversEdge';
  readonly node: VoucheredDrivers;
  readonly cursor: Scalars['String'];
};

export type VoucheredDriversFilterInput = {
  readonly routeCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** orderCode input filter is not used */
  readonly orderCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** voucherCode input filter is not used */
  readonly voucherCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverCompanyId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly fleetCarrierId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly voucherStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly voucherWeekEndStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly voucherWeekEndEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly voucherStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly voucherEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly minimumGrossPayAmount?: InputMaybe<Scalars['Float']>;
  readonly maximumGrossPayAmount?: InputMaybe<Scalars['Float']>;
  readonly minimumNetPayAmount?: InputMaybe<Scalars['Float']>;
  readonly maximumNetPayAmount?: InputMaybe<Scalars['Float']>;
  /** originStopFacilityId input filter is not used */
  readonly originStopFacilityId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly voucherNumber?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** fleetName input filter is not used. Use fleetCarrierId filter instead */
  readonly fleetName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** driverCompany filter is deprecated. Use driverCompanyId instead */
  readonly driverCompany?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly companyCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum VoucherExchangeRateConversionDateEnumType {
  Pickupdate = 'PICKUPDATE',
  Deliverydate = 'DELIVERYDATE',
  Voucherdate = 'VOUCHERDATE'
}

/** The Voucher Header Type */
export type VoucherHeader = {
  readonly __typename?: 'VoucherHeader';
  readonly id: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
  readonly status: StatusEnum;
  readonly voucherDate?: Maybe<Scalars['DateTime']>;
  readonly dueDate?: Maybe<Scalars['DateTime']>;
  readonly dateSettled?: Maybe<Scalars['DateTime']>;
  readonly originalAmountDue?: Maybe<Scalars['Decimal']>;
  readonly currentAmountDue?: Maybe<Scalars['Decimal']>;
  readonly discountAmount?: Maybe<Scalars['Decimal']>;
  readonly adjustmentAmount?: Maybe<Scalars['Decimal']>;
  readonly carrierPaymentTerm?: Maybe<Scalars['String']>;
  readonly carrierPaymentMethod?: Maybe<Scalars['String']>;
  readonly voucherMethod: VoucherMethodEnum;
  readonly remittanceGrouping?: Maybe<VoucherMethodEnum>;
  readonly remittanceEmail?: Maybe<Scalars['String']>;
  readonly remittanceAddressStreet1?: Maybe<Scalars['String']>;
  readonly remittanceAddressCity?: Maybe<Scalars['String']>;
  readonly remittanceAddressState?: Maybe<Scalars['String']>;
  readonly remittanceAddressPostalCode?: Maybe<Scalars['String']>;
  readonly voucherNumber: Scalars['String'];
  readonly division?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly batchId?: Maybe<Scalars['ID']>;
  readonly hasSeerSearchLimitReached?: Maybe<Scalars['Boolean']>;
  readonly executingDivision?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly costDetails: ReadonlyArray<CostDetail>;
  readonly remitTo?: Maybe<Carrier>;
  readonly remitToV2?: Maybe<CarrierV2>;
  readonly voucherCurrencyCurrentAmountDue?: Maybe<Scalars['Decimal']>;
  readonly voucherCurrencyOriginalAmountDue?: Maybe<Scalars['Decimal']>;
  readonly currencyId?: Maybe<Scalars['String']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly currencyDiscountAmount?: Maybe<Scalars['Decimal']>;
  readonly divisionCurrency?: Maybe<Scalars['String']>;
  readonly divisionOriginalAmountDue?: Maybe<Scalars['Decimal']>;
  readonly divisionCurrentAmountDue?: Maybe<Scalars['Decimal']>;
  readonly divisionExchangeRate?: Maybe<Scalars['Decimal']>;
  readonly invoiceProcessedDateTime?: Maybe<Scalars['DateTime']>;
  readonly creditMemoProcessedDateTime?: Maybe<Scalars['DateTime']>;
  readonly businessUnit?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  readonly pickupDate?: Maybe<Scalars['DateTime']>;
  readonly originalPickupDate?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type VoucherHeaderConnection = {
  readonly __typename?: 'VoucherHeaderConnection';
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<VoucherHeaderEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<VoucherHeader>>>;
};

/** An edge in a connection. */
export type VoucherHeaderEdge = {
  readonly __typename?: 'VoucherHeaderEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<VoucherHeader>;
};

/** Voucher details (cost details) object when processing a voucher. */
export type VoucherHeaderProcessingInput = {
  readonly id: Scalars['ID'];
  readonly costDetailsForProcessing: ReadonlyArray<CostDetailProcessingInput>;
};

/** The input type for updating Cost Detail for vouchering service. */
export type VoucheringCostDetailUpdateInputType = {
  readonly id: Scalars['ID'];
  readonly statusId: StatusEnum;
  readonly segment1?: InputMaybe<Scalars['String']>;
  readonly segment2?: InputMaybe<Scalars['String']>;
  readonly segment3?: InputMaybe<Scalars['String']>;
  readonly segment4?: InputMaybe<Scalars['String']>;
  readonly segment5?: InputMaybe<Scalars['String']>;
  readonly segment6?: InputMaybe<Scalars['String']>;
  readonly segment7?: InputMaybe<Scalars['String']>;
  readonly segment8?: InputMaybe<Scalars['String']>;
  readonly segment9?: InputMaybe<Scalars['String']>;
  readonly segment10?: InputMaybe<Scalars['String']>;
  readonly glAccountCode?: InputMaybe<Scalars['String']>;
  readonly driverVoucherId?: InputMaybe<Scalars['ID']>;
};

/** Represents Vouchering Requirement Configuration */
export type VoucheringRequirementConfiguration = {
  readonly __typename?: 'VoucheringRequirementConfiguration';
  readonly id: Scalars['ID'];
  readonly bypassRequirement?: Maybe<Scalars['Boolean']>;
  readonly requirementType?: Maybe<Scalars['String']>;
  readonly createdById?: Maybe<Scalars['ID']>;
  readonly createdByName?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedById?: Maybe<Scalars['ID']>;
  readonly updatedByName?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

/** Represents input type to update vouchering requirement configuration. */
export type VoucheringRequirementConfigurationUpdateInput = {
  readonly id: Scalars['ID'];
  readonly bypassRequirement: Scalars['Boolean'];
};

export enum VoucherMethodEnum {
  /**
   * Invoice totals are grouped by order
   * @deprecated This is an invalid value do not use
   */
  Order = 'Order',
  /**
   * Invoice totals are grouped by customer
   * @deprecated This is an invalid value do not use.
   */
  Combined = 'Combined',
  /** The Voucher will be printed and mailed. */
  Print = 'Print',
  /** The Voucher will be electronically delivered via E-mail. */
  Email = 'Email'
}

/** Overrides for vouchers at the time of voucher processing */
export type VoucherOverridesInput = {
  readonly voucherDate?: InputMaybe<Scalars['DateTime']>;
  readonly paymentTerms?: InputMaybe<Scalars['String']>;
};

export type VoucherTolerance = {
  readonly __typename?: 'VoucherTolerance';
  readonly id: Scalars['ID'];
  readonly loadSize?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly division?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly transportMode?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly lowerPercent?: Maybe<Scalars['Decimal']>;
  readonly upperPercent?: Maybe<Scalars['Decimal']>;
  readonly lowerMax: Scalars['Decimal'];
  readonly upperMax: Scalars['Decimal'];
};

/** The input type for a new voucher tolerance. */
export type VoucherToleranceCreateInput = {
  readonly lowerPercent?: InputMaybe<Scalars['Decimal']>;
  readonly upperPercent?: InputMaybe<Scalars['Decimal']>;
  readonly lowerMax: Scalars['Decimal'];
  readonly upperMax: Scalars['Decimal'];
  readonly loadSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly division?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly transportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** The input type for updating voucher tolerance. */
export type VoucherToleranceUpdateInput = {
  readonly lowerPercent?: InputMaybe<Scalars['Decimal']>;
  readonly upperPercent?: InputMaybe<Scalars['Decimal']>;
  readonly lowerMax: Scalars['Decimal'];
  readonly upperMax: Scalars['Decimal'];
  readonly loadSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly division?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly transportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['ID'];
};

/** The Voucher Vendor Tolerance. */
export type VoucherVendorTolerance = {
  readonly __typename?: 'VoucherVendorTolerance';
  readonly id: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
  readonly loadSize?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly division?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly transportMode?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly lowerPercent?: Maybe<Scalars['Decimal']>;
  readonly upperPercent?: Maybe<Scalars['Decimal']>;
  readonly lowerMax: Scalars['Decimal'];
  readonly upperMax: Scalars['Decimal'];
};

/** The input type for a new voucher vendor tolerance. */
export type VoucherVendorToleranceCreateInput = {
  readonly vendorId: Scalars['ID'];
  readonly lowerPercent?: InputMaybe<Scalars['Decimal']>;
  readonly upperPercent?: InputMaybe<Scalars['Decimal']>;
  readonly lowerMax: Scalars['Decimal'];
  readonly upperMax: Scalars['Decimal'];
  readonly loadSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly division?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly transportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** The input type for updating voucher vendor tolerance. */
export type VoucherVendorToleranceUpdateInput = {
  readonly id: Scalars['ID'];
  readonly lowerPercent?: InputMaybe<Scalars['Decimal']>;
  readonly upperPercent?: InputMaybe<Scalars['Decimal']>;
  readonly lowerMax: Scalars['Decimal'];
  readonly upperMax: Scalars['Decimal'];
  readonly loadSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly division?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly transportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type WeebhookUrlObject = {
  readonly __typename?: 'WeebhookUrlObject';
  readonly name?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
};

export type WhatIf = {
  readonly __typename?: 'WhatIf';
  readonly loadId: Scalars['ID'];
  readonly globalConfigurationOverride?: Maybe<GlobalConfigurationOverride>;
  readonly orderOverrides?: Maybe<ReadonlyArray<OrderOverride>>;
  readonly routeOverrides?: Maybe<ReadonlyArray<RouteOverride>>;
};

export type WhatIfInput = {
  readonly loadId: Scalars['ID'];
  readonly globalConfigurationOverride?: InputMaybe<GlobalConfigurationOverrideInput>;
  readonly orderOverrides?: InputMaybe<ReadonlyArray<OrderOverrideInput>>;
  readonly routeOverrides?: InputMaybe<ReadonlyArray<RouteOverrideInput>>;
};

export type WhatIfScenario = {
  readonly __typename?: 'WhatIfScenario';
  readonly whatif?: Maybe<WhatIf>;
  readonly loadAllocatedMargins?: Maybe<LoadAllocatedMargins>;
};

export type WhoAmI = {
  readonly __typename?: 'WhoAmI';
  readonly employee?: Maybe<Employee>;
  readonly permissions: ReadonlyArray<UserPermission>;
  readonly user?: Maybe<User>;
};

export type WhoAmIv2 = {
  readonly __typename?: 'WhoAmIV2';
  readonly employee?: Maybe<EmployeeV2>;
  readonly permissions: ReadonlyArray<UserPermission>;
  readonly user?: Maybe<UserV2>;
};

/** What is a Widget? Who knows, but this is the SDL type for one! For Testing Purposes Only. */
export type Widget = {
  readonly __typename?: 'Widget';
  readonly id: Scalars['ID'];
  /** The name of the widget. */
  readonly name: Scalars['String'];
  /** The UTC date time string of when the widget was created. */
  readonly createdAtUtc: Scalars['DateTime'];
  /** The user that created the widget. */
  readonly createdBy?: Maybe<UserV2>;
};

export type WithinRadiusInput = {
  readonly withinRadius?: InputMaybe<UnitOfLengthInput>;
  readonly radiusGeoPoint?: InputMaybe<GeoPointInput>;
};

export type WorkflowAddressInput = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly latitude?: InputMaybe<Scalars['Float']>;
  readonly longitude?: InputMaybe<Scalars['Float']>;
  readonly fullText?: InputMaybe<Scalars['String']>;
};

export type WorkflowAppointmentInput = {
  readonly appointmentType?: InputMaybe<Scalars['String']>;
  readonly startTime?: InputMaybe<Scalars['DateTime']>;
  readonly endTime?: InputMaybe<Scalars['DateTime']>;
  readonly readyTime?: InputMaybe<Scalars['DateTime']>;
  readonly lateTime?: InputMaybe<Scalars['DateTime']>;
};

export type WorkflowCommoditiesInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly sequence?: InputMaybe<Scalars['Float']>;
  readonly compartmentNumber?: InputMaybe<Scalars['String']>;
  readonly loadUnloadMethod?: InputMaybe<Scalars['String']>;
  readonly hazmatUnitedNationsNumber?: InputMaybe<Scalars['String']>;
  readonly plannedVolume?: InputMaybe<Scalars['Float']>;
  readonly commodityAmount?: InputMaybe<Scalars['String']>;
  readonly totalCommodityWeight?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isHazmatPlacardRequired?: InputMaybe<Scalars['Boolean']>;
  readonly isTemperatureControlRequired?: InputMaybe<Scalars['Boolean']>;
  readonly actualWeight?: InputMaybe<Scalars['Float']>;
  readonly actWeight?: InputMaybe<Scalars['String']>;
  readonly expectedWeight?: InputMaybe<Scalars['Float']>;
  readonly expWeight?: InputMaybe<Scalars['String']>;
  readonly maximumTemperature?: InputMaybe<Scalars['Float']>;
  readonly maxTemperature?: InputMaybe<Scalars['String']>;
  readonly minimumTemperature?: InputMaybe<Scalars['Float']>;
  readonly minTemperature?: InputMaybe<Scalars['String']>;
  readonly isPlacardRequired?: InputMaybe<Scalars['Boolean']>;
  readonly packagingCount?: InputMaybe<Scalars['Float']>;
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly isDoNotStack?: InputMaybe<Scalars['Boolean']>;
  readonly packagingType?: InputMaybe<Scalars['String']>;
  readonly isTemperatureControlled?: InputMaybe<Scalars['Boolean']>;
  readonly pieceType?: InputMaybe<Scalars['String']>;
  readonly weightUOM?: InputMaybe<Scalars['String']>;
  readonly expPieces?: InputMaybe<Scalars['Float']>;
  readonly actPieces?: InputMaybe<Scalars['Float']>;
  readonly loadOn?: InputMaybe<Scalars['String']>;
  readonly canTopLoad?: InputMaybe<Scalars['Boolean']>;
  readonly canBottomLoad?: InputMaybe<Scalars['Boolean']>;
  readonly hazmatClass?: InputMaybe<Scalars['String']>;
  readonly packagingGroup?: InputMaybe<Scalars['String']>;
  readonly flashPointTemp?: InputMaybe<Scalars['String']>;
  readonly hazmatContactName?: InputMaybe<Scalars['String']>;
  readonly phone?: InputMaybe<Scalars['String']>;
  readonly preCoolTo?: InputMaybe<Scalars['String']>;
  readonly tempSetting?: InputMaybe<Scalars['String']>;
  readonly overDimensional?: InputMaybe<Scalars['Boolean']>;
  readonly singleOrMultiUnit?: InputMaybe<Scalars['Boolean']>;
  readonly expDimenLength?: InputMaybe<Scalars['String']>;
  readonly expDimenWidth?: InputMaybe<Scalars['String']>;
  readonly expDimenHeight?: InputMaybe<Scalars['String']>;
  readonly expDimenUOM?: InputMaybe<Scalars['String']>;
  readonly actDimenLength?: InputMaybe<Scalars['String']>;
  readonly actDimenWidth?: InputMaybe<Scalars['String']>;
  readonly actDimenHeight?: InputMaybe<Scalars['String']>;
  readonly actDimenUOM?: InputMaybe<Scalars['String']>;
  readonly expLinear?: InputMaybe<Scalars['String']>;
  readonly actLinear?: InputMaybe<Scalars['String']>;
  readonly linearUOM?: InputMaybe<Scalars['String']>;
  readonly expCube?: InputMaybe<Scalars['String']>;
  readonly actCube?: InputMaybe<Scalars['String']>;
  readonly cubeUOM?: InputMaybe<Scalars['String']>;
  readonly expDensity?: InputMaybe<Scalars['String']>;
  readonly actDensity?: InputMaybe<Scalars['String']>;
  readonly densityUOM?: InputMaybe<Scalars['String']>;
  readonly expClass?: InputMaybe<Scalars['String']>;
  readonly actClass?: InputMaybe<Scalars['String']>;
  readonly expNMFC?: InputMaybe<Scalars['String']>;
  readonly actNMFC?: InputMaybe<Scalars['String']>;
  readonly make?: InputMaybe<Scalars['String']>;
  readonly model?: InputMaybe<Scalars['String']>;
  readonly year?: InputMaybe<Scalars['Float']>;
  readonly serialNumber?: InputMaybe<Scalars['String']>;
};

export type WorkflowCustomerInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
};

export type WorkflowDriverInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly driverReferences?: InputMaybe<ReadonlyArray<WorkflowDriverReferenceInput>>;
};

export type WorkflowDriverReferenceInput = {
  readonly type?: InputMaybe<Scalars['String']>;
  readonly referenceNumber: Scalars['String'];
};

export type WorkflowError = {
  readonly __typename?: 'WorkflowError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type WorkflowExternalNotesInput = {
  readonly value?: InputMaybe<Scalars['String']>;
};

export type WorkflowFacilityInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly externalID?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly timezone?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly hoursOfOperations?: InputMaybe<ReadonlyArray<WorkflowHoursOfOperationsInput>>;
};

export type WorkflowFormInput = {
  readonly form_names?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly provider_type?: InputMaybe<Scalars['String']>;
  readonly include_schema?: InputMaybe<Scalars['Boolean']>;
};

export type WorkflowFormOutput = {
  readonly __typename?: 'WorkflowFormOutput';
  readonly id: Scalars['ID'];
  /** @deprecated code is deprecated. Use name instead. */
  readonly code?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly version?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  /**
   * JSON value of forms
   * @deprecated fields is deprecated. Use form_schema instead.
   */
  readonly fields: Scalars['JSON'];
  /** JSON value of forms */
  readonly form_schema: Scalars['JSON'];
};

export type WorkflowHoursOfOperationsInput = {
  readonly dayOfWeek?: InputMaybe<Scalars['String']>;
  readonly opensAt?: InputMaybe<Scalars['String']>;
  readonly closesAt?: InputMaybe<Scalars['String']>;
};

export type WorkflowJob = {
  readonly __typename?: 'WorkflowJob';
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  /** JSON value of mapped RouteDetails */
  readonly routeDetails: Scalars['JSON'];
};

export type WorkflowJobInput = {
  readonly routeId: Scalars['String'];
  readonly carrierId: Scalars['String'];
  readonly driversId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type WorkflowLoadInput = {
  readonly id?: InputMaybe<Scalars['String']>;
};

export type WorkflowLocationInput = {
  readonly facility?: InputMaybe<WorkflowFacilityInput>;
  readonly address?: InputMaybe<ReadonlyArray<WorkflowAddressInput>>;
};

export type WorkflowOrderReferenceInput = {
  readonly type?: InputMaybe<Scalars['String']>;
  readonly referenceNumber: Scalars['String'];
};

export type WorkflowOrders = {
  readonly customer?: InputMaybe<WorkflowCustomerInput>;
  readonly orderReferences?: InputMaybe<ReadonlyArray<WorkflowOrderReferenceInput>>;
  readonly specialRequirements?: InputMaybe<ReadonlyArray<WorkflowSpecialRequirementsInput>>;
  readonly commodities?: InputMaybe<ReadonlyArray<WorkflowCommoditiesInput>>;
};

export type WorkflowPrePlanInput = {
  readonly id?: InputMaybe<Scalars['String']>;
};

export type WorkflowRequirementsInput = {
  readonly value?: InputMaybe<Scalars['String']>;
};

export type WorkflowSpecialRequirementsInput = {
  readonly name?: InputMaybe<Scalars['String']>;
};

export type WorkflowStopEventsInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly externalID?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly sequence?: InputMaybe<Scalars['Float']>;
  readonly type?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<Scalars['String']>;
  readonly completedDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly completedBy?: InputMaybe<Scalars['String']>;
  readonly completionType?: InputMaybe<Scalars['String']>;
  readonly external_data?: InputMaybe<ExternalDataInput>;
  readonly stopEventData?: InputMaybe<ReadonlyArray<StopEventDataInput>>;
};

export type WorkflowStopReferencesInput = {
  readonly type?: InputMaybe<Scalars['String']>;
  readonly referenceNumber?: InputMaybe<Scalars['String']>;
};

export type WorkflowStopsInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly stopType?: InputMaybe<Scalars['String']>;
  readonly stopOrder?: InputMaybe<Scalars['Float']>;
  readonly isDisabled?: InputMaybe<Scalars['Boolean']>;
  readonly liveDrop?: InputMaybe<Scalars['String']>;
  readonly workType?: InputMaybe<Scalars['String']>;
  readonly requirements?: InputMaybe<ReadonlyArray<WorkflowRequirementsInput>>;
  readonly appointment?: InputMaybe<WorkflowAppointmentInput>;
  readonly stopEvents?: InputMaybe<ReadonlyArray<WorkflowStopEventsInput>>;
  readonly stopReferences?: InputMaybe<ReadonlyArray<WorkflowStopReferencesInput>>;
  readonly externalNotes?: InputMaybe<ReadonlyArray<WorkflowExternalNotesInput>>;
  readonly location?: InputMaybe<WorkflowLocationInput>;
  readonly commodities?: InputMaybe<ReadonlyArray<WorkflowCommoditiesInput>>;
  readonly completed?: InputMaybe<Scalars['Boolean']>;
  readonly completedDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly completedBy?: InputMaybe<Scalars['String']>;
  readonly completionType?: InputMaybe<Scalars['String']>;
  readonly bolNumber?: InputMaybe<Scalars['String']>;
  readonly sealNumber?: InputMaybe<Scalars['String']>;
};

export type WorkflowTotalDistanceInput = {
  readonly val?: InputMaybe<Scalars['Float']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly unitOfMeasurement: Scalars['String'];
};

export type WorkflowTrailerTypes = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly name: Scalars['String'];
};

export type ZipCodeFormatResult = {
  readonly __typename?: 'ZipCodeFormatResult';
  readonly locality: Scalars['String'];
  readonly dependentLocality?: Maybe<Scalars['String']>;
  readonly administrativeArea?: Maybe<Scalars['String']>;
  readonly administrativeAreaCode?: Maybe<Scalars['String']>;
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
  readonly administrativeArea2?: Maybe<Scalars['String']>;
  readonly countryCode?: Maybe<Scalars['String']>;
};

export type ZipCodeRequest = {
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
};

export type ZipCodeResult = ZipCodeFormatResult | InvalidAddress;
